<div class="body-content-area tabcontent" id="link1">
  <div class="row">
    <div class="col-lg-9">
      <div class="row">
        <div class="col-lg-1 low-padding text-center">
          <!-- <img *ngIf="this.SelectedMenu.Image!=null" src={{this.SelectedMenu.Image}} class="body-icon" alt="Icon"/> -->
        </div>
        <div class="col-lg-10 clear-padding">
          <div>
            <span class="page-title">Employee CTC Details</span>
            <small class="page-description"></small>
          </div>
        </div>
      </div>
    </div>
  </div>
    <fieldset *ngIf="StandardSalary.length > 0" class="body-form-control" >
      <legend class="legend--">(Salary BrakUp) CTC Details</legend>
      <div class="row">
        <div class="col-lg-4">
          <div class="col-body-border-right">
            <div class="form-group row">
              <label class="col-sm-4 col-form-label clear-padding-right">Emp code</label>
              <div class="col-sm-7 clear-left-padding">
                :&nbsp;&nbsp;{{this.EmployeeAttendanceModel.EmployeeCode}} 
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-4">
          <div class="form-group row">
            <label class="col-sm-4 col-form-label clear-padding-right">Employee Name</label>
            <div class="col-sm-7 clear-left-padding">
              :&nbsp;&nbsp;{{this.EmployeeAttendanceModel.EmployeeName}}
            </div>
          </div>
        </div>
      </div>
        <div class="modal-body">
          <div *ngIf="StandardSalary.length > 0" >
            <table id="tblComponent" class="table table-striped table-bordered text-left table-new row-border hover">
              <thead class="thead-blue text-center">
                <tr>
                  <th>Component</th>                        
                  <th class="text-right">Monthly Amount(Rs)</th>  
                  <th class="text-right">Yearly Amount(Rs)</th>                     
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let sc of StandardSalary, let i = index">
                  <td *ngIf="sc.IsBenefit==false">
                    <span>{{sc.SalaryComponentName }}</span>
                    <input type="text" hidden="hidden" [(ngModel)]="sc.RefId" id ="RefId" name="RefId" #RefId="ngModel">
                    <input type="text" hidden="hidden" [(ngModel)]="sc.CurrentDate" id ="CurrentDate" name="CurrentDate" #CurrentDate="ngModel">
                    <input type="text" hidden="hidden" [(ngModel)]="sc.CTCAmount" id ="CTCAmount" name="CTCAmount" #CTCAmount="ngModel">
                  </td>
                  <td *ngIf="sc.IsBenefit==false" class="text-right">
                    {{sc.Amount | number:'3.0-0'}}
                  </td>
                  <td *ngIf="sc.IsBenefit==false" class="text-right">
                      {{sc.AmountYearly | number:'3.0-0'}}
                  </td>
                </tr>
                <tr style="background-color:rgb(207, 250, 250)">
                  <td>Total Gross Salary : (A)</td>
                  <td class="text-right">{{this.ComponentSubTotal | number:'3.0-0'}} </td>
                  <td class="text-right">{{this.ComponentSubTotal * 12 | number:'3.0-0'}} </td>
                </tr>
                <tr *ngFor="let sc of StandardSalary, let i = index">
                  <td *ngIf="sc.IsBenefit==true">
                    <span>{{sc.SalaryComponentName}}</span>
                    <input type="text" hidden="hidden" [(ngModel)]="sc.RefId " id ="RefId" name="RefId" #RefId="ngModel">
                  </td>                            
                  <td *ngIf="sc.IsBenefit==true" class="text-right">
                      {{sc.Amount | number:'3.0-0'}}
                  </td>
                  <td *ngIf="sc.IsBenefit==true" class="text-right">
                      {{sc.AmountYearly | number:'3.0-0'}}
                  </td>
                </tr>
                <tr *ngIf="this.PFCont > 0">
                  <td>PF Employer's Contribution</td>
                  <td class="text-right">{{this.PFCont | number:'3.0-0'}} </td>
                  <td class="text-right">{{this.PFCont * 12 | number:'3.0-0'}} </td>
                </tr>
                <tr *ngIf="this.ESICont > 0">
                  <td>ESI Employer's Contribution</td>
                  <td class="text-right">{{this.ESICont | number:'3.0-0'}} </td>
                  <td class="text-right">{{this.ESICont | number:'3.0-0'}} </td>
                </tr>
                <tr style="background-color:rgb(207, 250, 250)">
                  <td>Long Term benefits : (B)</td>
                  <td class="text-right">{{this.Longtermbenefits + this.PFCont + this.ESICont | number:'3.0-0'}} </td>
                  <td class="text-right">{{this.Longtermbenefits * 12 + this.PFCont * 12 + this.ESICont * 12 | number:'3.0-0'}} </td>
                </tr>
                <tr style="background-color:rgb(162, 162, 235)">
                  <td>Computed Compensation CTC : (A+B)</td>
                  <td class="text-right">{{(this.Longtermbenefits + this.ComponentSubTotal + this.PFCont + this.ESICont) | number:'3.0-0'}} </td>
                  <td class="text-right">{{(this.Longtermbenefits * 12 + this.ComponentSubTotal * 12 + this.PFCont * 12 + this.ESICont * 12) | number:'3.0-0'}} </td>
                </tr>
                <!-- <tr>
                  <td>Less PF Employee's Contribution</td>
                  <td class="text-right">{{this.PFDed | number:'3.0-0'}} </td>
                </tr>
                <tr *ngIf="this.StandardSalaryModel.CTCAmount < 15000">
                  <td>Less ESI Employee's Contribution</td>
                  <td class="text-right">{{this.ESIDed | number:'3.0-0'}} </td>
                </tr>
                <tr>
                  <td>Professional Tax Amount </td>
                  <td class="text-right">{{this.tempPtaxSlabModel.TaxAmount | number:'3.0-0'}} </td>
                </tr>
                <tr style="background-color:rgb(207, 250, 250)">
                  <td>Total Deduction : (C)</td>
                  <td class="text-right">{{(this.PFDed + this.ESIDed + this.tempPtaxSlabModel.TaxAmount) | number:'3.0-0'}} </td>
                </tr>
                <tr style="background-color:rgb(162, 162, 235)">
                  <td>Take Home Before Income tax Deduction : (A-C)</td>
                  <td class="text-right">
                    {{(this.ComponentSubTotal)-(this.PFDed + this.ESIDed + this.tempPtaxSlabModel.TaxAmount) | number:'3.0-0'}} 
                  </td>
                </tr> -->
              </tbody>
            </table>
          </div>
        </div>
    </fieldset>
</div>