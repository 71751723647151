<perfect-scrollbar style="max-width: 1260px; max-height: 540px;" class="mukesh">
  <div class="body-content-area tabcontent" id="link1">
    <div class="row">
      <div class="col-lg-9">
        <div class="row">
          <div class="col-lg-1 low-padding text-center">
            <!-- <img *ngIf="this.SelectedMenu.Image!=null" src={{this.SelectedMenu.Image}} class="body-icon" alt="Icon" /> -->
          </div>
          <div class="col-lg-10 clear-padding">
            <div>
              <span class="page-title">Lead Reports</span>
              <small class="page-description"></small>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div  style=" height:100%;">
      <!-- *ngIf="!(this.CanViewState)" -->
      <fieldset class="body-form-control">
        <legend class="legend--"></legend>
        <form #EmployeeAttendanceForm="ngForm">
          <div class="row">
            <div class="col-lg-4">
              <div class="col-body-border-right">
                <div class="form-group row">
                  <label class="col-sm-4 col-form-label clear-padding-right">Seleced Employees

                  </label>
                  <div class="col-sm-8 clear-left-padding">


                    <angular2-multiselect tabindex="12" class="form-control" [data]="AssigneArray"
                      [(ngModel)]="SelectAssigneList" name="AssignedId" [settings]="AssigneSettings"
                      (onSelect)="onItemSelectAssigneTo($event)" (onDeSelect)="OnItemDeSelectAssigneTo($event)"
                      (onSelectAll)="onSelectAllAssigneTo($event)" (onDeSelectAll)="onDeSelectAllAssigneTo($event)">
                    </angular2-multiselect>


                  </div>


                </div>


              </div>
            </div>

            <div class="col-sm-7 m-t14">
              <div class="row">
                <div class="col-sm-2">
                  <label class="col-form-label clear-padding-right font-14">From Date</label>
                </div>
                <div class="col-sm-4">
                  <input type="date" name="FromDate" [ngModel]=" this.FromDate | date:'yyyy-MM-dd'"
                    (ngModelChange)="FromDateChanged($event)" #FromDate="ngModel" required />
                </div>
                <div class="col-sm-2">
                  <label class="col-form-label clear-padding-right font-14">To Date</label>
                </div>
                <div class="col-sm-4">
                  <input type="date" name="ToDate" [ngModel]="this.Todate | date:'yyyy-MM-dd'"
                    (ngModelChange)="ToDateChanged($event)" #ToDate="ngModel" required />
                </div>
              </div>
              <div class="row">

                <div class="col-sm-2">

                </div>
                <div class="col-sm-4">

                </div>
                <div class="col-sm-2">

                </div>


                <div class="col-sm-4">
                  <br />
                  <button (click)="DownloadReport()" class="btn btn-info m-tl"> Export To Excel</button>
                </div>
              </div>
            </div>


          </div>
        </form>



      </fieldset>
    </div>
  </div>
</perfect-scrollbar>