export class EmployeeLeaveBalanceModel
{
    public RefId : number;
    public EmployeeId : number;
    public EmployeeCode : string;
    public EmployeeName : string;
    public LeaveTypeId : number;
    public LeaveTypeName : string;
    public LastYearBalance : number;
    public NoOfLeave : number;
    public EarnLeave : number;
    public LeaveTaken : number;
    public BalanceLeave : number;
    public LeaveApply : number;
    public BalanceLeaveAfterApply : number;
    public Year : number;

    public CompanyId : number;
    public BranchId : number;
    public DepartmentId : number;
    public Bulk : boolean;
    public Individual : boolean;
    public Adjustmentvalue : string;
    public Credit : boolean;
    public Debit : boolean;
    public AdjustmentTypevalue : string;
    public Remarks : string;
    public EmployeeIds : string;

    public Selected : boolean;
}