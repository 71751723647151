<div class="body-content-area tabcontent" id="link1">
  <div class="row">
      <div class="col-lg-9">
          <div class="row">
              <div class="col-lg-1 low-padding text-center">
                  <!-- <img *ngIf="this.SelectedMenu.Image!=null" src={{this.SelectedMenu.Image}} class="body-icon" alt="Icon"/> -->
              </div>
              <div class="col-lg-10 clear-padding">
                  <span class="page-title">Manage District</span>
                  <small class="page-description"></small>
                  <div class="col-lg-10 clear-padding" *ngIf="!(this.CanViewState)">
                    <button *ngIf="(this.CanSaveButtonView)" (click)="ADDClick()" type="button" class="btn btn-primary Add-button">ADD New</button> 
                  </div>
              </div>
          </div>
      </div>
  </div>
  <div *ngIf="!(this.CanViewState)">
    <app-dialog [(visible)]="this.SuccessStatus">
        <span class="successmsg">{{this.SuccessMessage}}</span>
    </app-dialog>
    <fieldset *ngIf="!(this.FormViewShow)" class="body-form-control">
        <legend class="legend--">District Details</legend>
        <table id="tblDistrict" *ngIf="!(this.FormViewShow)" datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" class="table table-striped table-bordered text-center table-new row-border hover">
            <thead class="thead-blue text-center">
                <tr>
                    <th>Sl No.</th>
                    <th>District Code</th>
                    <th>District </th>
                    <th>State </th>
                    <th>Country </th>
                    <th>Action</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let District of Districties, let i = index">
                    <td scope="row">{{i+1}}</td>
                    <td>{{District.DistCode}}</td>
                    <td class="text-left">{{District.DistName}}</td>
                    <td class="text-left">{{District.StateName}}</td>
                    <td class="text-left">{{District.CountryName}}</td>
                    <td>
                        <button *ngIf="this.SelectedMenu.CanEdit" type="button"  (click)="GetDistrictByid( District.DistId )"class="table-edit-btn"><i class="fas fa-edit"></i></button> 
                        <button *ngIf="this.SelectedMenu.CanDelete" type="button" (click)="DeleteDistrictByid( District.DistId )"class="table-remove-btn"><i class="fas fa-trash-alt"></i> </button>
                        <button *ngIf="this.SelectedMenu.CanView" type="button" (click)="ViewDistrictByid( District.DistId )" class="table-view-button" type="button" ><i class="fas fa-eye"></i></button>
                    </td>
                </tr>
            </tbody>
        </table>
    </fieldset>

    <fieldset *ngIf="(this.FormViewShow)" class="body-form-control">
        <legend class="legend--">District Creation</legend>
        <form #DistrictForm="ngForm" (ngSubmit)="saveDistrict()">
            <div class="row">
                <div class="col-lg-4">
                    <div class="col-body-border-right">
                        <div class="form-group row">
                            <label class="col-sm-4 col-form-label clear-padding-right">District Code</label>
                            <div class="col-sm-7 clear-left-padding">
                                <input type="text" [(ngModel)]="DistrictModel.DistCode" tabindex="1" maxlength="40" id ="DistCode"  name="DistCode" #DistCode="ngModel" required class="form-control form-control-custom form-control-custom-new" >
                            </div>
                            
                            <div *ngIf="DistCode.invalid && (DistCode.dirty || DistCode.touched)" class="alert alert-danger">
                                <div *ngIf="DistCode.errors.required">District Code is required.</div>
                                <div *ngIf="DistCode.errors.maxlength">District Code is not more than 40 characters</div>
                            </div>
                        </div>
                        <div class="form-group row">
                            <label  class="col-sm-4 col-form-label clear-padding-right">State</label>
                            <div class="col-sm-7 clear-left-padding">
                                <select [(ngModel)]="DistrictModel.StateId" tabindex="4" id ="StateId" class="form-control" name="StateId" #StateId="ngModel" >
                                    <option [value]="0" [disabled]=true >Select State</option>
                                    <option *ngFor="let s of States" [value]="s.StateID">{{s.StateName}}</option>
                                </select>
                            </div>
                            <div class="required-validation" *ngIf="DistrictModel.StateId==0">State is required</div>
                        </div>
                    </div>
                </div>
                
                <div class="col-lg-4">
                    <div class="col-body-border-right">
                        <div class="form-group row">
                            <label  class="col-sm-4 col-form-label clear-padding-right">District Name</label>
                            <div class="col-sm-7 clear-left-padding">
                                <input type="text" [(ngModel)]="DistrictModel.DistName" tabindex="2" id ="DistName" name="DistName" #DistName="ngModel" (blur)="CheckName()" required maxlength="250" class="form-control form-control-custom form-control-custom-new" >
                            </div>
                            <div *ngIf="this.Namecheck.length > 0" class="alert alert-danger">District Name is exist</div>
                            <div *ngIf="DistName.invalid && (DistName.dirty || DistName.touched)" class="alert alert-danger">
                                <div *ngIf="DistName.errors.required">District Name  is required.</div>
                                <div *ngIf="DistName.errors.maxlength">District Name is not more than 250 characters</div>
                            </div>
                        </div>
                        <div class="form-group row">
                            
                        </div>
                    </div>
                </div>

                <div class="col-lg-4">
                    <div class="form-group row">
                        <label  class="col-sm-4 col-form-label clear-padding-right">Country</label>
                        <div class="col-sm-7 clear-left-padding">
                            <select [(ngModel)]="DistrictModel.CountryId" tabindex="3" (ngModelChange)="onChange($event)" id ="CountryId" class="form-control"  name="CountryId" #CountryId="ngModel" >
                                <option [value]="0" [disabled]=true >Select Country</option>
                                <option *ngFor="let c of Countries" [value]="c.CountryId">{{c.CountryName}}</option>
                            </select>
                        </div>
                        <div class="required-validation" *ngIf="DistrictModel.CountryId==0">Country is required</div>
                    </div>
                    <div class="form-group row">
                        <label class="col-sm-6 col-form-label text-center clear-padding-right" *ngIf="!(this.IsDataIsInViewState)">
                            <button type="submit" tabindex="4" class="btn btn-primary save-button" [disabled]="!(DistrictForm.valid && this.CheckNameExiststatus==true && DistrictModel.CountryId !=0 && this.DisableSave==true)" >Save</button>  
                        </label>
                        <div class="col-sm-6 col-form-label text-center">
                            <button type="button" tabindex="5" (click)="RefreshPage()" class="btn btn-danger cancle-button">Cancel</button>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </fieldset>
  </div>
</div>