import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from '../../shared/user.service';
import { EmployeePortalService }  from '../../shared/HR_ADMIN/EmployeePortal/EmployeePortalService.service';
import { EmployeeDetail } from '../../shared/HR_ADMIN/Employee/EmployeeDetail.Model';
import { EmployeeAttendanceModel } from '../../shared/HR_ADMIN/EmployeePortal/EmployeeAttendanceModel.Model';
import { Menu } from '../../shared/menu.model';
import { CommonMenuHandelling } from '../../shared/Menu/CommonMenuHandelling';

@Component({
  selector: 'app-employee-attendance-info',
  templateUrl: './employee-attendance-info.component.html',
  styleUrls: ['./employee-attendance-info.component.css']
})
export class EmployeeAttendanceInfoComponent implements OnInit
{
  EmployeeAttendanceModel : EmployeeAttendanceModel= new EmployeeAttendanceModel();
  EmployeeAttendance: EmployeeAttendanceModel[]=[];
  Attendance: any;
  userClaims: any;
  employee: any;
  Result : string;
  SuccessStatus:boolean=false;
  SuccessMessage: string ="";
  public tableWidget: any;
  selectedIndex : number;
  SelectedMenu:Menu;
  validdate : boolean= false;
  NoRecordMsg : boolean= false;

  constructor(private EmployeePortalService: EmployeePortalService, private rout: Router, private userService: UserService, private commonmenu:CommonMenuHandelling){ }

  ngOnInit() 
  {
    // Begning Permission Portion 
    this.SelectedMenu=this.userService.SelectedMenu;
    if(this.SelectedMenu !=undefined)
    {
      localStorage.setItem('selectedmenu',JSON.stringify(this.SelectedMenu));
      this.rout.navigate([this.SelectedMenu.RouteName]);
    }
    else
    {
      if(JSON.parse(localStorage.getItem('selectedmenu'))!=undefined)
      {
        this.SelectedMenu = JSON.parse(localStorage.getItem('selectedmenu'));
        this.rout.navigate([this.SelectedMenu.RouteName]);
      }
    }
    // End of the permission portion
    this.GetEmployeeDetails();
    this.NoRecordMsg=false;
  }

  GetEmployeeDetails()
  {
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.EmployeePortalService.GetEmployeeDetailsByEmployeeId( t).then(x => 
    {
      this.employee = x;
      Object.keys(this.employee).forEach( key => 
      {
        this.employee[key];
        let employeetemp = new EmployeeDetail();
        employeetemp = this.employee[key];
        this.EmployeeAttendanceModel.EmployeeCode=this.employee.EmployeeCode;
        this.EmployeeAttendanceModel.EmployeeName=this.employee.EmployeeName;
      });
    });
  }

  FromDateChanged( date)
  {
    this.EmployeeAttendanceModel.FromDate=date;
  }

  ToDateChanged( date)
  {
    this.EmployeeAttendanceModel.ToDate=date;
  }

  submitClick()
  {
    if(this.EmployeeAttendanceModel.FromDate!=null && this.EmployeeAttendanceModel.ToDate!=null)
    {
      if(this.EmployeeAttendanceModel.ToDate >= this.EmployeeAttendanceModel.FromDate)
      {
        this.GetEmployeeAttendanceDetails(this.EmployeeAttendanceModel.FromDate, this.EmployeeAttendanceModel.ToDate);
      }
    }
  }
  
  GetEmployeeAttendanceDetails(FromDate,ToDate)
  {
    this.NoRecordMsg=false;
    this.EmployeeAttendance=[];
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.EmployeePortalService.GetEmployeeAttendanceDetailsByUserLogingId( t,FromDate,ToDate).then(x => 
    {
      this.EmployeeAttendance=[];
      this.Attendance = x;
      Object.keys(this.Attendance).forEach( key => 
      {
        this.Attendance[key];
        let Attendancetemp = new EmployeeAttendanceModel();
        Attendancetemp = this.Attendance[key];
        this.EmployeeAttendance.push(Attendancetemp);
      });
      if(this.EmployeeAttendance.length == 0)
      {
        this.NoRecordMsg=true;
      }
    });
  }

  print(): void 
  {
    let printContents, popupWin;
    printContents = document.getElementById('divPrint').innerHTML;
    popupWin = window.open('', '_blank', 'letf=0,top=0,width=2480px,height=3508px,toolbar=0, scrollbars=0,sta­tus=0');
    popupWin.document.open();
    popupWin.document.write(`
      <html>
        <head>
          <title>Employee Daily Attendance</title>
          <style=" margin:25px 10px 15px 30px; font-family:Calibri">
          
          </style>
        </head>
      <body onload="window.print();window.close()">${printContents}</body>
      </html>`
    );
    popupWin.document.close();
  }

  export() {
    this.commonmenu.exportExcel(this.EmployeeAttendance, 'Attendences');
  }
}
