export class Model_Forex_CRM_Documents
{
    public  DocumentId : number;
    public  ForexCrmId : number;    
    public  File  : string;
    public  DocumentActualName : string;
    public  DocumentType : string;
    public  DocumentExtension : string;
    public  DocumentName  : string;
    public  DocumentSize : string;
    public  Isactive : boolean;
   
}