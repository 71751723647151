import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs/Subject';
import { DataTableDirective } from 'angular-datatables';
import { UserService } from '../../shared/user.service';
import { AdminMasterService} from '../../shared/HR_ADMIN/Admin/AdminMasterService.service';
import { LocationService} from '../../shared/HR_ADMIN/Location/location.service';
import { PTAXModel } from  '../../shared/HR_ADMIN/Admin/PTAXModel.Model';
import { State } from '../../shared/Organization/State.Model';
import { Menu } from '../../shared/menu.model';
// end of Confirmation

@Component({
  selector: 'app-manage-ptax-slab',
  templateUrl: './manage-ptax-slab.component.html',
  styleUrls: ['./manage-ptax-slab.component.css']
})
export class ManagePtaxSlabComponent implements OnInit 
{
  PTAXModel : PTAXModel= new PTAXModel();
  userClaims: any;
  PTAXes: PTAXModel[] = [];
  PTAX: any;
  States : State[]=[];
  State : any;
  Result : string;
  SuccessStatus:boolean=false;
  SuccessMessage: string ="";
  public tableWidget: any;
  RefId : any;
  base64textString : string;
  tempPTAXadd : PTAXModel =new PTAXModel();

  // Begning Permission Portion    
  SelectedMenu:Menu;
  IsDataIsInViewState : boolean=false;
  CanViewState : boolean=false;
  IsFormViewShow : boolean=false;
  DisableSave : boolean=false;
  // End of the permission portion
  // begin for Validation
  validdate : boolean= false;
  IsGridInViewState : boolean= false;
  // end of validation

  constructor(private AdminMasterService: AdminMasterService, private LocationService:LocationService, private rout: Router, private userService: UserService) { }
  
  @ViewChild(DataTableDirective, {static: false})
  setDtOptions: DataTables.Settings = {};
  cardDtOptions: DataTables.Settings = {};
  dtElement: DataTableDirective;
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();
  
  ngOnInit() 
  {
    //Begin Permission Portion
    this.SelectedMenu=this.userService.SelectedMenu;
    if(this.SelectedMenu !=undefined)
    {
      localStorage.setItem('selectedmenu',JSON.stringify(this.SelectedMenu));
      this.rout.navigate([this.SelectedMenu.RouteName]);
    }
    else
    {
      if(JSON.parse(localStorage.getItem('selectedmenu'))!=undefined)
      {
        this.SelectedMenu = JSON.parse(localStorage.getItem('selectedmenu'));
        this.rout.navigate([this.SelectedMenu.RouteName]);
      }
    }
    if(this.SelectedMenu.CanAdd)
    {
    this.IsDataIsInViewState=false;
    }
    else
    {
      this.IsDataIsInViewState=true;
    }
    if(this.SelectedMenu.CanView)
    {
    this.CanViewState=false;
    }
    else
    {
      this.CanViewState=true;
    }
    this.IsFormViewShow=false;
    //End of the peremission portion
    this.IsGridInViewState=false;
    this.DisableSave=true;
    if(localStorage.getItem('SuccessfullMessage'))
    {
      this.SuccessStatus=true;
      this.SuccessMessage =localStorage.getItem('SuccessfullMessage')
      localStorage.removeItem('SuccessfullMessage');
    }
  }

  ngAfterViewInit(): void 
  {
    this.getStates();
    this.PTAXModel.StateId=1;
    this.GetPtaxSlabByStateId(1);
  }
  
  GetPtaxSlabByStateId(StateId) 
  { 
    // Begning of the permission portion
    this.IsDataIsInViewState=false;
    this.IsFormViewShow=true;
    // End of the permision portion
    this.PTAXModel.ApplicableDate=null;
    this.validdate=false;
    this.PTAXModel.IsActive=false;
    let t = localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.AdminMasterService.GetPtaxSlabByStateId( t,StateId).then(x => 
    {
      this.IsGridInViewState=false;
      this.RefId = x;
      if(this.RefId.length > 0)
      {
        this.PTAXes=[];
      }
      Object.keys(this.RefId).forEach( key => 
      {
        // tslint:disable-next-line:no-unused-expression
        this.RefId[key];
        let PTAXtemp = new PTAXModel();
        PTAXtemp = this.RefId[key];
        this.PTAXes.push(PTAXtemp);
        this.PTAXModel.ApplicableDate=PTAXtemp.ApplicableDate;
        this.PTAXModel.ApplicableDateCheck=PTAXtemp.ApplicableDate;
        this.PTAXModel.IsActive=PTAXtemp.IsActive;
        this.validdate=true;
      });
    });
  }

  RefreshPage()
  {
    this.rout.navigateByUrl('/Admin/ManageFinancialYear', {skipLocationChange: true}).then(()=>this.rout.navigate(["/Admin/PTaxSlab"]))
  }
 
  dateChanged( date)
  {
    this.PTAXModel.ApplicableDate=date;
    var CurrentDate = new Date();
    var CurrentYear = CurrentDate.getFullYear();
    var currentMonth = CurrentDate.getMonth();
    var CurrentDay = CurrentDate.getDate();
    
    var selecteedDate = new Date(date);
    var selectedYear=selecteedDate.getFullYear();
    var selectedMonth=selecteedDate.getMonth();
    var selectedDay=selecteedDate.getDate();

    if(CurrentYear < selectedYear )
    {
      this.validdate = true;      
    }
    else if(CurrentYear > selectedYear)
    {
      this.validdate = false;
    }
    else
    {
      if (currentMonth < selectedMonth)
      {
        this.validdate = true;
      }
      else if(currentMonth > selectedMonth)
      {
        this.validdate = false;
      }
      else
      {
        if(CurrentDay <= selectedDay )
        {
          this.validdate = true;
        }
        else
        {
          this.validdate = false;
        }
      }
    }
  }

  getStates() 
  {
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t
    if(this.States.length>0)
    {
      this.States.splice(0, this.States.length);
    }
    this.LocationService.GetState( t).then(x => 
    {
      this.State = x;
      Object.keys(this.State).forEach( key => 
      {
        this.State[key];
        let Statetemp = new State();
        Statetemp = this.State[key];
        this.States.push(Statetemp);
      });     
    });      
  }

  onstatechange(stateid)
  {
    this.PTAXes=[];
    this.PTAXModel.StateId=stateid;
    this.GetPtaxSlabByStateId(stateid);
    if(this.PTAXes.length == 0 )
    {
      let ptaxList : PTAXModel[]=[];
      for( var i=0; i<3; i++ )
      {
        let tempPTAX = new PTAXModel();          
        ptaxList.push(tempPTAX);         
      }
      this.PTAXes=ptaxList;
    }
  }
  
  AddnweRow()
  {
    let tempPTAX = new PTAXModel();          
    this.PTAXes.push(tempPTAX);     
  }

  SavePTAXSlab() :void
  {
    this.DisableSave=false;
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;    
    var j=0;
    var l=this.PTAXes.length;
    this.PTAXes.forEach(item => 
    {
      j=j+1;
      if(item.ToAmount >0)
      {     
        item.ApplicableDate=this.PTAXModel.ApplicableDate;
        item.ApplicableDateCheck=this.PTAXModel.ApplicableDateCheck;
        item.StateId=this.PTAXModel.StateId; 
        if(item.PtaxRefId==null)
        {
          item.PtaxRefId=0;
        }
        if(item.PtaxRefId> 0)
        {
          this.AdminMasterService.UpdatePTAXSlab(t ,item).subscribe(res=>
          {
            this.RefId=res;        
            if(this.RefId>0)
            {
              localStorage.setItem('SuccessfullMessage', "Update Successfully");
              this.rout.navigateByUrl('Admin/ManageFinancialYear', {skipLocationChange: true}).then(()=>this.rout.navigate(["Admin/PTAXSlab"]));     
            }
          });                  
        }                             
        else
        {
          this.AdminMasterService.AddPTAXSlab(t ,item).subscribe(res=>
          {
            this.RefId=res;         
            if(this.RefId>0)
            {
              localStorage.setItem('SuccessfullMessage', "Save Successfully");
              this.rout.navigateByUrl('Admin/ManageFinancialYear', {skipLocationChange: true}).then(()=>this.rout.navigate(["Admin/PTAXSlab"]));            
            }
          }); 
        }
      }
    });   
  }
}
