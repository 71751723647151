<div class="body-content-area tabcontent" id="link1">
  <div class="row">
    <div class="col-lg-9">
      <div class="row">
        <div class="col-lg-1 low-padding text-center">
          <!-- <img *ngIf="this.SelectedMenu.Image!=null" src={{this.SelectedMenu.Image}} class="body-icon" alt="Icon"/> -->
        </div>
        <div class="col-lg-10 clear-padding">
          <div>
            <span class="page-title">Employee Leave Balance Adjustment</span>
            <small class="page-description"></small>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="!(this.CanViewState)">
    <fieldset class="body-form-control" *ngIf="EmployeeLeaveBalanceAdjustemts.length > 0"> 
      <legend class="legend--">Employee Leave Adjustment Details</legend>
        <form #EmployeePending="ngForm">
          <div lass="row" >
            <table id="tblLeavePending" class="table table-striped table-bordered text-center table-new row-border hover">
              <thead class="thead-blue text-center">
                <tr>
                  <th>Sl.No.</th>
                  <th>Date</th>
                  <th>Leave Type</th>
                  <th>Adjustment Type</th>
                  <th class="text-left">No Of Leave</th>
                  <th class="text-left">Remarks</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let app of EmployeeLeaveBalanceAdjustemts, let i = index">
                  <td scope="row">{{i+1}}</td>
                  <td>{{app.CreatedDate | date : "dd-MMM-yyyy"}}</td>
                  <td>{{app.LeaveTypeName}}</td>
                  <td *ngIf="app.Credit == true">Credit</td>
                  <td *ngIf="app.Debit == true">Debit</td>
                  <td class="text-left">{{app.NoOfLeave}}</td>
                  <td class="text-left">{{app.Remarks}}</td>
                </tr>
              </tbody>
              <tfoot class="thead-blue text-center">
                <tr>
                  <th></th>
                  <th></th>
                  <th></th>
                  <th></th>
                  <th></th>
                  <th></th>
                </tr>
              </tfoot>
            </table>
          </div>
        </form>
    </fieldset>
  </div>
</div>