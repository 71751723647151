<div>
  <app-dialog [(visible)]="this.SuccessStatus">
    <span class="successmsg">{{this.SuccessMessage}}</span>
  </app-dialog>
</div>
<perfect-scrollbar style="max-width: 1260px; max-height: 540px;">

  <div class="body-content-area tabcontent" id="link1">
    <div class="row">
      <div class="col-lg-9">
        <div class="row">
          <div class="col-lg-1 low-padding text-center">


          </div>

          <div class="col-lg-10 clear-padding">
            <span class="page-title">ADD CRM ZONE </span>
            <small class="page-description">It is a long established fact that a reader will be distracted</small>
          </div>
        </div>
      </div>
    </div>

    <div>
      <table id="tblCRMZone" datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger"
        class="table table-striped table-bordered text-center table-new row-border hover">
        <thead class="thead-blue text-center">

          <tr>
            <th>Sl No.</th>
            <th class="text-left">Country</th>
            <th class="text-left">Zone</th>
            <th>Action</th>
          </tr>

        </thead>
        <tbody>
          <tr *ngFor="let Z of ZONELIST, let i = index">
            <td scope="row">{{i+1}}</td>
            <td class="text-left">{{ Z.CountryName}}</td>
            <td class="text-left">{{Z.ZoneName}}</td>

            <td>
              <!-- <button *ngIf="this.SelectedMenu.CanEdit" type="button" (click)="GetCRMZoneById( Z.ZoneId )" class="table-edit-btn">
              <i class="fas fa-edit"></i>
            </button>
      
            <button *ngIf="this.SelectedMenu.CanDelete" type="button" (click)="DeleteZone( Z.ZoneId )" class="table-remove-btn">
              <i class="fas fa-trash-alt"></i>
            </button>
      
            <button *ngIf="this.SelectedMenu.CanView" type="button" (click)="ViewCRMZoneById( Z.ZoneId )" class="table-view-button"
              type="button">
              <i class="fas fa-eye"></i>
            </button> -->

              <button type="button" *ngIf="this.userService.selectedCrmMenu.CanEdit"
                (click)="GetCRMZoneById( Z.ZoneId )" class="table-edit-btn">
                <i class="fas fa-edit"></i>
              </button>

              <button type="button" *ngIf="this.userService.selectedCrmMenu.CanDelete" (click)="DeleteZone( Z.ZoneId )"
                class="table-remove-btn">
                <i class="fas fa-trash-alt"></i>
              </button>

              <button type="button" *ngIf="this.userService.selectedCrmMenu.CanView"
                (click)="ViewCRMZoneById( Z.ZoneId )" class="table-view-button" type="button">
                <i class="fas fa-eye"></i>
              </button>


            </td>

          </tr>

        </tbody>
      </table>


      <fieldset class="body-form-control">



        <legend class="legend--">ADD ZONE</legend>
        <form #ZoneName="ngForm" (ngSubmit)="saveCRMZone()">

          <div class="row">
            <div class="col-lg-4">
              <div class="col-body-border-right">
                <div class="form-group row">
                  <label class="col-sm-4 col-form-label clear-padding-right">ZONE NAME</label>
                  <div class="col-sm-7 clear-left-padding">
                    <input type="text" [(ngModel)]=" CRM_Zone_Model.ZoneName" tabindex="1" maxlength="250" id="ZoneName"
                      name="ZoneName" #ZoneName="ngModel" required
                      class="form-control form-control-custom form-control-custom-new">
                  </div>
                  <div *ngIf="ZoneName.invalid && (ZoneName.dirty || ZoneName.touched)" class="alert alert-danger">
                    <div *ngIf="ZoneName.errors.required">Zone Name is required.</div>
                    <div *ngIf="ZoneName.errors.maxlength">Zone Name is not more than 250 characters</div>
                  </div>
                </div>
              </div>

              <div class="form-group row" class="col-sm-6 col-form-label text-center">
                <button *ngIf="!(this.IsDataIsInViewState)" type="submit" tabindex="3"
                  class="col-sm-6  clear-padding-right  btn btn-primary save-button"
                  [disabled]="!((this.CRM_Zone_Model.CountryId!=0) && (this.CRM_Zone_Model.ZoneName.length>0))">Save
                </button>
                <button type="button" tabindex="4" (click)="RefreshPage()"
                  class=" col-sm-5  clear-padding-left btn btn-danger cancle-button">Cancel</button>
              </div>
              <!-- <div class="col-sm-5 col-form-label text-center">
               
              </div> -->


            </div>


            <div class="col-lg-4">
              <div class="form-group row">
                <label class="col-sm-4 col-form-label clear-padding-right">COUNTRY </label>
                <div class="col-sm-7 clear-left-padding">
                  <select [(ngModel)]="CRM_Zone_Model.CountryId" tabindex="3" id="CountryId" class="form-control"
                    name="CountryId" #CountyId="ngModel">
                    <option [value]="0" [disabled]=true>Select Country</option>
                    <option *ngFor="let C of COUNTRYList" [value]="C.CountryId">{{C.CountryName}}</option>
                  </select>
                </div>
                <div class="required-validation">Country Name required</div>
              </div>


            </div>
          </div>


        </form>
      </fieldset>
    </div>
  </div>
</perfect-scrollbar>