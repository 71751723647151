export class Model_CRM_LeadShared {


    public Lead_Shared_Id: number;

    public UserId: number;

    public LeadId: number;

    public IsActive: boolean;


}
