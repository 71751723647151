export class EmployeeAttendanceModel
{
    public RefId : number;
    public EmployeeId : number;
    public EmployeeCode : string;
    public EmployeeName : string;
    public AttendanceDate : Date;
    public AttendanceDay : string; 
    public InTime : Date;
    public OutTime : Date;
    public NoOfWorkingHours : number;
    public WorkingHourshow : string;
    public Status : string;
    public FromDatepass: string;
    public ToDatepass: string;
    public FromDate: Date;
    public ToDate: Date;
    //for report
    public ReportTypeId : number;
    public CompId : number;
    public BranchId : number;
    public DepartmentId: number;
    public LateBy : number;
    public EarlyBy : number;
    public EmployeeIds : string;

    public MonthId : number;
    public Year : number;

    public ModifiedStatus : string;
    public Remarks : string;
    public IsModified : boolean;
    public DepartmentIds : string;
}