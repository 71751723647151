export class HolidaysModel 
{
    public RefId: number;
    public HolidayDate :Date;
    public HolidayDay :string;
    public HolidayDescription: string;
    public Remarks: string;
    public CompanyId: number;
    public CompanyName: string;
    public BranchId : number;
    public BranchName: string;
    public Year : number;
    public LeaveDay : number;
    public IsActive : boolean;
    public FullDay : boolean;
    public HalfDay : boolean;
    public value:string;
    public FirstHalf : boolean;
    public SecondHalf : boolean;
    public HalfDayvalue:string;
}