/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./app.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./user/user.component.ngfactory";
import * as i3 from "./user/user.component";
import * as i4 from "../../node_modules/@jaspero/ng2-confirmations/ng2-confirmations.ngfactory";
import * as i5 from "@jaspero/ng2-confirmations";
import * as i6 from "../../node_modules/ng4-loading-spinner/ng4-loading-spinner.ngfactory";
import * as i7 from "ng4-loading-spinner";
import * as i8 from "./app.component";
var styles_AppComponent = [i0.styles];
var RenderType_AppComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AppComponent, data: {} });
export { RenderType_AppComponent as RenderType_AppComponent };
export function View_AppComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-user", [], null, null, null, i2.View_UserComponent_0, i2.RenderType_UserComponent)), i1.ɵdid(1, 114688, null, 0, i3.UserComponent, [], null, null), (_l()(), i1.ɵeld(2, 0, null, null, 2, "jaspero-confirmations", [], null, null, null, i4.View_ConfirmationsComponent_0, i4.RenderType_ConfirmationsComponent)), i1.ɵprd(8704, null, i1.ComponentFactoryResolver, i1.ɵCodegenComponentFactoryResolver, [[8, [i4.ConfirmationComponentNgFactory]], [3, i1.ComponentFactoryResolver], i1.NgModuleRef]), i1.ɵdid(4, 245760, null, 0, i5.ConfirmationsComponent, [i5.ConfirmationService, i1.ComponentFactoryResolver], null, null), (_l()(), i1.ɵeld(5, 0, null, null, 1, "ng4-loading-spinner", [], null, null, null, i6.View_Ng4LoadingSpinnerComponent_0, i6.RenderType_Ng4LoadingSpinnerComponent)), i1.ɵdid(6, 180224, null, 0, i7.Ng4LoadingSpinnerComponent, [i7.Ng4LoadingSpinnerService], { zIndex: [0, "zIndex"], loadingText: [1, "loadingText"], threshold: [2, "threshold"] }, null)], function (_ck, _v) { _ck(_v, 1, 0); _ck(_v, 4, 0); var currVal_0 = 9999; var currVal_1 = "Please wait..."; var currVal_2 = 2000; _ck(_v, 6, 0, currVal_0, currVal_1, currVal_2); }, null); }
export function View_AppComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-root", [], null, null, null, View_AppComponent_0, RenderType_AppComponent)), i1.ɵdid(1, 49152, null, 0, i8.AppComponent, [], null, null)], null, null); }
var AppComponentNgFactory = i1.ɵccf("app-root", i8.AppComponent, View_AppComponent_Host_0, {}, {}, []);
export { AppComponentNgFactory as AppComponentNgFactory };
