<perfect-scrollbar style="max-width: 1260px; max-height: 584px;" class="mukesh">
    <div class="body-content-area tabcontent" id="link1">
    <div class="row">
        <div class="col-lg-9">
            <div class="row">
                <div class="col-lg-1 low-padding text-center">
                    <!-- <img *ngIf="this.SelectedMenu.Image!=null" src={{this.SelectedMenu.Image}} class="body-icon" alt="Icon"/> -->
                </div>
                <div class="col-lg-10 clear-padding">
                    <span class="page-title">Manage Assets Type</span>
                    <small class="page-description"></small>
                </div>
            </div>
        </div>
    </div>

    <div *ngIf="!(this.CanViewState)">
    <table id="tblAssetstype" datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" class="table table-striped table-bordered text-center table-new row-border hover">
        <thead class="thead-blue text-center">
            <tr>
                <th>Sl No.</th>
                <th class="text-left">Assets Name</th>
                <th>Action</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let assets of AssetsTypes, let i = index">
                <td scope="row">{{i+1}}</td>
                <td class="text-left">{{assets.AssetsName}}</td>
                <td>
                    <button *ngIf="this.SelectedMenu.CanEdit"  type="button" (click)="GetAssetsTypeById( assets.RefId )" class="table-edit-btn"><i class="fas fa-edit"></i> </button> 
                    <button *ngIf="this.SelectedMenu.CanDelete" type="button" (click)="DeleteAssetsTypeByid( assets.RefId )" class="table-remove-btn"><i class="fas fa-trash-alt"></i>  </button>
                    <button *ngIf="this.SelectedMenu.CanView"  type="button" (click)="viewAssetsTypeByid( assets.RefId )" class="table-view-button" type="button" ><i class="fas fa-eye"></i></button>
                </td>
            </tr>
        </tbody>
    </table>
    <fieldset class="body-form-control">
        <app-dialog [(visible)]="this.SuccessStatus">
            <span class="successmsg">{{this.SuccessMessage}}</span>
        </app-dialog>
        
        <legend class="legend--">Assets Type Creation</legend>
        <form #AssetsTypeForm="ngForm" (ngSubmit)="saveAssetsType()">
            <div class="row">
                <div class="col-lg-4">
                    <div>
                        <div class="form-group row">
                            <label  class="col-sm-4 col-form-label clear-padding-right">Assets Name</label>
                            <div class="col-sm-7 clear-left-padding">
                                <input type="text" [(ngModel)]="AssetsTypeModel.AssetsName" tabindex="1" id ="AssetsName"  name="AssetsName" #AssetsName="ngModel" required maxlength="500" class="form-control form-control-custom form-control-custom-new" (blur)="CheckName()">
                            </div>
                            <div *ngIf="this.Namecheck.length > 0" class="alert alert-danger">Assets Name is exist</div>
                            <div *ngIf="AssetsName.invalid && (AssetsName.dirty || AssetsName.touched)" class="alert alert-danger">
                                <div *ngIf="AssetsName.errors.required">Assets Name  is required.</div>
                                <div *ngIf="AssetsName.errors.maxlength">Assets Name is not more than 250 characters</div>
                            </div>
                        </div>
                    </div>
                </div>
                
                <div class="col-lg-4">
                    
                </div>

                <div class="col-lg-4">
                    <div class="form-group row">
                        <label class="col-sm-6 col-form-label text-center clear-padding-right" *ngIf="!(this.IsDataIsInViewState)">
                            <button type="submit" tabindex="3" class="btn btn-primary save-button" [disabled]="!(AssetsTypeForm.valid && this.CheckNameExiststatus==true && this.DisableSave==true)">Save</button>  
                        </label>
                        <div class="col-sm-6 col-form-label text-center">       
                        <button type="button" tabindex="4" class="btn btn-danger cancle-button" (click)="RefreshPage()" >Cancel</button></div>
                    </div>
                </div>
            </div>
        </form>
    </fieldset>
    </div>
    </div>
</perfect-scrollbar>