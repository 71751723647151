import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AdminMasterService }  from '../../shared/HR_ADMIN/Admin/AdminMasterService.service';
import { ExpenseHeadMappingModel }  from '../../shared/HR_ADMIN/Admin/ExpenseHeadMappingModel.model';
import { UserService } from '../../shared/user.service';
import { Menu } from '../../shared/menu.model';
import { ModalOptions,BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { LevelService }  from '../../shared/HR_ADMIN/Lavels_Grades/Levels.service';
import { DecisionMaking } from  '../../shared/HR_ADMIN/Lavels_Grades/DecisionMaking.Model';
import { Level } from  '../../shared/HR_ADMIN/Lavels_Grades/Level.Model';
import { Grade } from  '../../shared/HR_ADMIN/Lavels_Grades/Grade.Model';

@Component({
  selector: 'app-manage-expense-head-mapping',
  templateUrl: './manage-expense-head-mapping.component.html',
  styleUrls: ['./manage-expense-head-mapping.component.css']
})
export class ManageExpenseHeadMappingComponent implements OnInit 
{
  ExpenseHeadMappingModel : ExpenseHeadMappingModel= new ExpenseHeadMappingModel();
  userClaims: any;
  ExpenseHeadMappings : ExpenseHeadMappingModel[] = [];
  ExpenseHeadAdd : ExpenseHeadMappingModel[] = [];
  HeadMappings:any;
  Result : string;
  SuccessStatus:boolean=false;
  SuccessMessage: string ="";
  public tableWidget: any;
  RefId : any;
  // Begning Permission Portion    
  SelectedMenu:Menu;
  IsDataIsInViewState : boolean=false;
  IsDDViewState : boolean=false;
  CanViewState : boolean=false;
  DisableSave :boolean=false;
  // End of the permission portion
  Decisions: DecisionMaking[] = [];
  Decision: any;
  Levels: Level[] = [];
  Level:any;
  Grades: Grade[] = [];
  Grade:any;
  //For Pop up
  SaveMsg : boolean= false;
  ShowPopup : boolean= false;
  public modalRef: BsModalRef;
  public modelopt:ModalOptions;

  constructor(private AdminMasterService: AdminMasterService, private LevelService:LevelService,private rout: Router, private userService: UserService,private modalService: BsModalService)
  {
    this.modelopt=new ModalOptions();
    this.modelopt.class='heavy_modal';
    this.modalService.config= this.modelopt;
    this.modelopt.ignoreBackdropClick=true;
  }

  ngOnInit() 
  {
    //Begin Permission Portion
    this.SelectedMenu=this.userService.SelectedMenu;
    if(this.SelectedMenu !=undefined)
    {
      localStorage.setItem('selectedmenu',JSON.stringify(this.SelectedMenu));
      this.rout.navigate([this.SelectedMenu.RouteName]);
    }
    else
    {
      if(JSON.parse(localStorage.getItem('selectedmenu'))!=undefined)
      {
        this.SelectedMenu = JSON.parse(localStorage.getItem('selectedmenu'));
        this.rout.navigate([this.SelectedMenu.RouteName]);
      }
    }     
    if(this.SelectedMenu.CanAdd)
    {
      this.IsDataIsInViewState=false;
    }
    else
    {
      this.IsDataIsInViewState=true;
    }
    if(this.SelectedMenu.CanView)
    {
      this.CanViewState=false;
    }
    else
    {
      this.CanViewState=true;
    }
    //End of the peremission portion
    this.SaveMsg=false;
    this.ShowPopup=false;
    this.DisableSave=true; 
    this.GetTourExpenseHeadMappingGV();   
    this.getDecisionMaking();
    this.ExpenseHeadMappingModel.DecisionId=0; 
  }

  GetTourExpenseHeadMappingGV()
  {
    this.ExpenseHeadMappings =[];
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.AdminMasterService.GetTourExpenseHeadMappingGV( t).then(x => 
    {
      this.HeadMappings = x;
      Object.keys(this.HeadMappings).forEach( key => 
      {
        this.HeadMappings[key];
        let Expensetemp = new ExpenseHeadMappingModel();
        Expensetemp = this.HeadMappings[key];
        this.ExpenseHeadMappings.push(Expensetemp);
      });
    });
  }

  GetTourExpenseHeadMappingDetails(GradeId)
  {
    this.ExpenseHeadAdd =[];
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.AdminMasterService.GetTourExpenseHeadMappingByGradeId( t,this.ExpenseHeadMappingModel.DecisionId,this.ExpenseHeadMappingModel.LevelId,GradeId).then(x => 
    {
      this.HeadMappings = x;
      Object.keys(this.HeadMappings).forEach( key => 
      {
        this.HeadMappings[key];
        let Expensetemp = new ExpenseHeadMappingModel();
        Expensetemp = this.HeadMappings[key];
        this.ExpenseHeadMappingModel.DecisionName=Expensetemp.DecisionName;
        this.ExpenseHeadMappingModel.LevelName=Expensetemp.LevelName;
        this.ExpenseHeadMappingModel.GradeName=Expensetemp.GradeName;
        this.ExpenseHeadMappingModel.DecisionId=Expensetemp.DecisionId;
        this.ExpenseHeadMappingModel.LevelId=Expensetemp.LevelId;
        this.ExpenseHeadMappingModel.GradeId=Expensetemp.GradeId;
        this.ExpenseHeadAdd.push(Expensetemp);
      });
    });
  }

  OnChangeDayWise(evt,i:number)
  {
    this.ExpenseHeadAdd[i];
    var target = evt.target;
    if(target.checked)
    {           
      this.ExpenseHeadAdd[i].DayWise=true;
      this.ExpenseHeadAdd[i].OverAll=false; 
      this.ExpenseHeadAdd[i].value="DayWise";        
    }
  }

  OnChangeOverAll(evt,i:number)
  {
    this.ExpenseHeadAdd[i];        
    var target = evt.target; 
    if(target.checked)
    { 
      this.ExpenseHeadAdd[i].OverAll=true;
      this.ExpenseHeadAdd[i].DayWise=false; 
      this.ExpenseHeadAdd[i].value="OverAll";
    }
  }

  GetExpenseHeadMappingById(DecisionId,LevelId,GradeId,template) 
  {
    // Begning of the permission portion
    this.IsDataIsInViewState=false;
    this.IsDDViewState=false;
    // End of the permision portion
    this.ExpenseHeadMappingModel.DecisionId=DecisionId;
    this.ExpenseHeadMappingModel.LevelId=LevelId;
    this.GetTourExpenseHeadMappingDetails(GradeId);
    if(this.ShowPopup==true)
    {
      this.modalRef.hide();
    }
    this.modalRef = this.modalService.show(template,this.modelopt);
    this.ShowPopup=true;
    this.SaveMsg=false;
  }

  ViewExpenseHeadMappingById(DecisionId,LevelId,GradeId,template) 
  {
    // Begning of the permission portion
    this.IsDataIsInViewState=true;
    this.IsDDViewState=false;
    // End of the permision portion
    this.ExpenseHeadMappingModel.DecisionId=DecisionId;
    this.ExpenseHeadMappingModel.LevelId=LevelId;
    this.GetTourExpenseHeadMappingDetails(GradeId);
    if(this.ShowPopup==true)
    {
      this.modalRef.hide();
    }
    this.modalRef = this.modalService.show(template,this.modelopt);
    this.ShowPopup=true;
    this.SaveMsg=false;
  }
  
  SaveExpenseHeadMapping(template) :void
  {
    this.DisableSave=false;
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.AdminMasterService.AddTourExpenseHeadMapping(t ,this.ExpenseHeadAdd).subscribe(res=>
    {
      this.RefId=res;
      this.ExpenseHeadMappingModel.RefId=0;  
      if(this.RefId>0)
      {
        this.ExpenseHeadMappingModel.DecisionId=0;
        this.Levels=[];
        this.ExpenseHeadMappingModel.LevelId=null;
        this.Grades=[];
        this.ExpenseHeadMappingModel.GradeId=null;
        this.modalRef.hide();
        this.SaveMsg=true;
        this.ShowPopup=false;
        this.modalRef = this.modalService.show(template);
        this.GetTourExpenseHeadMappingGV();
        this.DisableSave=true;
      }
    });
  }

  ADDClick(template)
  {
    this.ExpenseHeadAdd=[];
    this.ExpenseHeadMappingModel.DecisionId=0;
    this.Levels=[];
    this.ExpenseHeadMappingModel.LevelId=null;
    this.Grades=[];
    this.ExpenseHeadMappingModel.GradeId=null;
    this.IsDataIsInViewState=false;
    this.IsDDViewState=true;
    if(this.ShowPopup==true)
    {
      this.modalRef.hide();
    }
    this.modalRef = this.modalService.show(template,this.modelopt);
    this.ShowPopup=true;
    this.SaveMsg=false;
    this.DisableSave=true;
  }

  // begning of check duplicate Expense Head Name
  // checkExpenseHeadName()
  // {
  //   this.CheckExpenseHeadName ==false;
  //   let t =  localStorage.getItem('userToken');
  //   t = 'Bearer' + t;
  //   if(!this.checkstatusedit)
  //   {
  //     this.AdminMasterService.CheckTourExpenseHeadName( t, this.ExpenseHeadMappingModel.ExpenseHeadName).then(x => 
  //     {
  //       this.TourExpenseHead = x;
  //       if( this.TourExpenseHead.length >0)
  //       {
  //         this.CheckExpenseHeadName =false;
  //         this.ExpenseHeadNamecheck="Exist"
  //       }
  //       else
  //       {
  //         this.CheckExpenseHeadName =true;
  //         this.ExpenseHeadNamecheck="";
  //       }
  //     });
  //   }
  //   else
  //   {
  //     if(this.ExpenseHeadMappingModel.ExpenseHeadName !=this.checkExpenseHeadNameval )
  //     {
  //       this.AdminMasterService.CheckTourExpenseHeadName( t, this.ExpenseHeadMappingModel.ExpenseHeadName).then(x => 
  //       {
  //         this.TourExpenseHead = x;
  //         if( this.TourExpenseHead.length >0)
  //         {
  //           this.CheckExpenseHeadName =false;
  //           this.ExpenseHeadNamecheck="Exist"
  //         }
  //         else
  //         {
  //           this.CheckExpenseHeadName =true;
  //           this.ExpenseHeadNamecheck="";
  //         }
  //       });
  //     }
  //     else
  //     {
  //       this.ExpenseHeadNamecheck="";
  //       this.CheckExpenseHeadName =true;
  //     }
  //   }
  // }
  // end of duplicate Expense Head Name

  getDecisionMaking() 
  {
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.LevelService.GetDecisionMaking( t).then(x => 
    {
      this.Decision = x;
       Object.keys(this.Decision).forEach( key => 
      {
        this.Decision[key];
        let Decisiontemp = new DecisionMaking();
        Decisiontemp = this.Decision[key];
        this.Decisions.push(Decisiontemp);
      });
    });      
  }
  
  onDecisionChange(Decisionid) 
  {
    this.getLevels(Decisionid);
    this.ExpenseHeadMappingModel.LevelId=0;
    this.Grades=[];
  }

  getLevels( Decisionid) 
  {
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t
    if(this.Levels.length>0)
    {
      this.Levels.splice(0, this.Levels.length);
    }
    this.LevelService.GetLevelByDecisionid( t,Decisionid).then(x => 
    {
      this.Level = x;
      Object.keys(this.Level).forEach( key => 
      {
        this.Level[key];
        let Leveltemp = new Level();
        Leveltemp = this.Level[key];
        this.Levels.push(Leveltemp);
      });     
    });      
  }

  onLevelChange(Levelid) 
  {
    this.getGrades(Levelid);
    this.ExpenseHeadMappingModel.GradeId=0;
  }

  getGrades( Levelid) 
  {
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t
    if(this.Grades.length>0)
    {
      this.Grades.splice(0, this.Grades.length);
    }
    this.LevelService.GetGradeByLevelid( t,Levelid).then(x => 
    {
      this.Grade = x;
      Object.keys(this.Grade).forEach( key => 
      {
        this.Grade[key];
        let Gradetemp = new Grade();
        Gradetemp = this.Grade[key];
        this.Grades.push(Gradetemp);
      });     
    });      
  }

  onGradeChange(Gradeid) 
  {
    this.GetTourExpenseHeadMappingDetails(Gradeid);
  }
}