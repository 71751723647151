import { Component, OnInit, ViewChild,Input } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs/Subject';
import { DataTableDirective } from 'angular-datatables';
import { AttendanceService }  from '../../shared/HR_ADMIN/Attendance/AttendanceService.service';
import { WorkingHours }  from '../../shared/HR_ADMIN/Attendance/WorkingHours.model';
import { UserService } from '../../shared/user.service';
// for time picker
import { AmazingTimePickerService } from 'amazing-time-picker';
//for time picker
// Begning Confirmation
import { ConfirmationService } from '@jaspero/ng2-confirmations';
import { ResolveEmit } from '../../../app/resolve-emit';
import { ConfirmSettings } from '../../../app/confirm-settings';
// end of Confirmation
import { Menu } from '../../shared/menu.model';


@Component({
  selector: 'app-manage-working-hours',
  templateUrl: './manage-working-hours.component.html',
  styleUrls: ['./manage-working-hours.component.css']
})
export class ManageWorkingHoursComponent implements OnInit 
{
  WorkingHoursModel : WorkingHours= new WorkingHours();
  userClaims: any;
  Result : string;
  SuccessStatus:boolean=false;
  SuccessMessage: string ="";
  public tableWidget: any;
  WorkingHour: WorkingHours[] = [];
  workinghour:any;
  WorkingHourId : any;

  INTIME:any;
  OUTTIME : any;
  HALFDAYINTIME:any;
  HALFDAYOUTTIME : any;
  // Begning Permission Portion    
  SelectedMenu:Menu;
  IsDataIsInViewState : boolean=false;
  CanViewState:boolean=true;
  DisableSave :boolean=false;
  // End of the permission portion

  public selectedintime = undefined;
  public selectedOuttime = undefined;
  public selectedHalfDayintime : any = '00:00';
  public selectedHalfDayOuttime : any = '00:00';
  public selectedEarlyOutTime  : any= '00:00';
  public selectedHalfDayStartTime : any = '00:00';
  public selectedHalfDayEndTime : any = '00:00';

  @Input() element;
  
  tempWorkingHoursadd : WorkingHours =new WorkingHours();

  constructor(private AttendanceService: AttendanceService, private rout: Router,
    private atp: AmazingTimePickerService , private _confirmation: ConfirmationService, 
    private userService: UserService ) { }

  @ViewChild(DataTableDirective, {static: false})
  setDtOptions: DataTables.Settings = {};
  cardDtOptions: DataTables.Settings = {};
  dtElement: DataTableDirective;
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();

  ngOnInit()
  {
    // Begning Permission Portion 
    this.SelectedMenu=this.userService.SelectedMenu;
    if(this.SelectedMenu !=undefined)
    {
      localStorage.setItem('selectedmenu',JSON.stringify(this.SelectedMenu));
      this.rout.navigate([this.SelectedMenu.RouteName]);
    }
    else
    {
      if(JSON.parse(localStorage.getItem('selectedmenu'))!=undefined)
      {
        this.SelectedMenu = JSON.parse(localStorage.getItem('selectedmenu'));
        this.rout.navigate([this.SelectedMenu.RouteName]);
      }
    }
    if(this.SelectedMenu.CanAdd)
    {
      this.IsDataIsInViewState=false;
    }
    else
    {
      this.IsDataIsInViewState=true;
    }
    if(this.SelectedMenu.CanView)
    {
    this.CanViewState=false;
    }
    else
    {
      this.CanViewState=true;
    }
    // End of the permission portion
    this.DisableSave=true;
    this.WorkingHoursModel.IsGraceTimeApplicable=false;
    this.dtOptions = 
    {
      pagingType: 'full_numbers',
      pageLength: 4
    };
    if(localStorage.getItem('SuccessfullMessage'))
    {
      this.SuccessStatus=true;
      this.SuccessMessage =localStorage.getItem('SuccessfullMessage')
      localStorage.removeItem('SuccessfullMessage');
    }
  }
  settings: ConfirmSettings | any = 
  {
    overlay: true,
    overlayClickToClose: true,
    showCloseButton: true,
    confirmText: 'Yes',
    declineText: 'No'
  };

  doSomething() 
  {
    const amazingTimePicker = this.atp.open();
    amazingTimePicker.afterClose().subscribe(time => 
    {
      alert(time);
      console.log(time);
    });
  }

  ngAfterViewInit(): void 
  {
    this.getWorkingHours();
    this.dtOptions = 
    {
      pagingType: 'full_numbers',
      pageLength: 2
    };
  }

  getWorkingHours()
  {
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.AttendanceService.GetWorkingHours( t).then(x => 
    {
      this.workinghour = x;
      Object.keys(this.workinghour).forEach( key => 
      {
        this.workinghour[key];
        let workinghourtemp = new WorkingHours();
        workinghourtemp = this.workinghour[key];
        this.WorkingHour.push(workinghourtemp);
      });
      this.dtTrigger.next();
    });
  }

  GetWorkingHoursByid(id) 
  {
    // begning of the permission portion
    this.IsDataIsInViewState=false;
    // end of the permission portion
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.AttendanceService.GetWorkingHoursByid( t,id).then(x => 
    {
      this.WorkingHoursModel.WorkingDuration=0;
      this.WorkingHoursModel.HalfDayDuration=0;
      this.workinghour = x;
      Object.keys(this.workinghour).forEach( key => 
      {
        this.workinghour[key];
        let workinghourtemp = new WorkingHours();
        workinghourtemp = this.workinghour[key];
        this.WorkingHoursModel.WorkingHoursId=workinghourtemp.WorkingHoursId;
        this.WorkingHoursModel.WorkingHoursName=workinghourtemp.WorkingHoursName;
        this.WorkingHoursModel.InTime=workinghourtemp.InTime;
        this.WorkingHoursModel.OutTime=workinghourtemp.OutTime;
        this.WorkingHoursModel.WorkingDuration=workinghourtemp.WorkingDuration;
        this.WorkingHoursModel.HalfDayInTime=workinghourtemp.HalfDayInTime;
        this.WorkingHoursModel.HalfDayOutTime=workinghourtemp.HalfDayOutTime;
        this.WorkingHoursModel.IsGraceTimeApplicable=workinghourtemp.IsGraceTimeApplicable;
        this.WorkingHoursModel.GraceTime=workinghourtemp.GraceTime;
        this.WorkingHoursModel.HalfDayStartTime=workinghourtemp.HalfDayStartTime;
        this.WorkingHoursModel.HalfDayEndTime=workinghourtemp.HalfDayEndTime;
        this.WorkingHoursModel.HalfDayDuration=workinghourtemp.HalfDayDuration;
        this.WorkingHoursModel.EarlyOutTime=workinghourtemp.EarlyOutTime;
        this.WorkingHoursModel.IsFlexiable=workinghourtemp.IsFlexiable;
        this.WorkingHoursModel.EarlyMaxtime=workinghourtemp.EarlyMaxtime;
      });
    });
  }

  ViewWorkingHoursByid(id) 
  {
    // begning of the permission portion
    this.IsDataIsInViewState=true;
    // end of the permission portion
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.AttendanceService.GetWorkingHoursByid( t,id).then(x => 
    {
      this.workinghour = x;
      Object.keys(this.workinghour).forEach( key => 
      {
        this.workinghour[key];
        let workinghourtemp = new WorkingHours();
        workinghourtemp = this.workinghour[key];
        this.WorkingHoursModel.WorkingHoursId=workinghourtemp.WorkingHoursId;
        this.WorkingHoursModel.WorkingHoursName=workinghourtemp.WorkingHoursName;
        this.WorkingHoursModel.InTime=workinghourtemp.InTime;
        this.WorkingHoursModel.OutTime=workinghourtemp.OutTime;
        this.WorkingHoursModel.WorkingDuration=workinghourtemp.WorkingDuration;
        this.WorkingHoursModel.HalfDayInTime=workinghourtemp.HalfDayInTime;
        this.WorkingHoursModel.HalfDayOutTime=workinghourtemp.HalfDayOutTime;
        this.WorkingHoursModel.IsGraceTimeApplicable=workinghourtemp.IsGraceTimeApplicable;
        this.WorkingHoursModel.GraceTime=workinghourtemp.GraceTime;
        this.WorkingHoursModel.HalfDayStartTime=workinghourtemp.HalfDayStartTime;
        this.WorkingHoursModel.HalfDayEndTime=workinghourtemp.HalfDayEndTime;
        this.WorkingHoursModel.HalfDayDuration=workinghourtemp.HalfDayDuration;
        this.WorkingHoursModel.EarlyOutTime=workinghourtemp.EarlyOutTime;
        this.WorkingHoursModel.IsFlexiable=workinghourtemp.IsFlexiable;
        this.WorkingHoursModel.EarlyMaxtime=workinghourtemp.EarlyMaxtime;
      });
    });
  }
     
  saveWorkingHours() :void
  {
    this.DisableSave=false;
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.WorkingHoursModel.IsActive=true;
    if(this.WorkingHoursModel.WorkingHoursId > 0)
    {
      this.AttendanceService.UpdateWorkingHours(t ,this.WorkingHoursModel).subscribe(res=>
      {
        this.WorkingHourId=res;
        this.WorkingHoursModel.WorkingHoursId=0;  
        if(this.WorkingHourId>0)
        {
          localStorage.setItem('SuccessfullMessage', "Update Successfully");
          this.rout.navigateByUrl('/Attendence/ManageAttendenceFineRules', {skipLocationChange: true}).then(()=>this.rout.navigate(["/Attendence/ManageWorkingHours"]));
        }
      });                  
    }
    else
    {
      this.AttendanceService.AddWorkingHours(t ,this.WorkingHoursModel).subscribe(res=>
      {
        this.WorkingHourId=res;
        this.WorkingHoursModel.WorkingHoursId=0;              
        if(this.WorkingHourId>0)
        {
          localStorage.setItem('SuccessfullMessage', "Save Successfully");
          this.rout.navigateByUrl('/Attendence/ManageAttendenceFineRules', {skipLocationChange: true}).then(()=>this.rout.navigate(["/Attendence/ManageWorkingHours"]));
        }
      }); 
    }
  }

  DeleteWorkingHoursByid(WorkingHourid : number) :void
  {
    this._confirmation.create('Confirm to Deleate', 'Press Yes to Delete', this.settings)
    .subscribe((ans: ResolveEmit) => 
    {
      if (ans.resolved == true) 
      {
        let t =  localStorage.getItem('userToken');
        t = 'Bearer' + t;
        this.WorkingHoursModel.IsActive=true;
        if( WorkingHourid > 0)
        {
          this.AttendanceService.DeleteWorkingHours(t ,WorkingHourid).subscribe(res=>
          {
            this.WorkingHourId=res;
            this.WorkingHoursModel.WorkingHoursId=0;                                  
            if(this.WorkingHourId >0)
            {
              localStorage.setItem('SuccessfullMessage', "Deleted Successfully");
              this.rout.navigateByUrl('/Attendence/ManageAttendenceFineRules', {skipLocationChange: true}).then(()=>this.rout.navigate(["/Attendence/ManageWorkingHours"]));
            }
          });                  
        }
      }
    })
  }
  // Time Change
  SelectinTime()
  {
   
    const amazingTimePicker = this.atp.open({});
    amazingTimePicker.afterClose().subscribe(time => 
    {     
      
      this.selectedintime = time;
      this.WorkingHoursModel.InTime=this.selectedintime;      
      if(this.selectedOuttime != undefined)
      {
       
        var intimevar = this.selectedintime.split(':');
        var outtimevar= this.selectedOuttime.split(':');
        var totalintime= Number(intimevar[0])*60 + Number(intimevar[1]);    
        var totalouttime= Number(outtimevar[0])*60 + Number(outtimevar[1]);
        var diffIntimeOutTime = totalouttime -totalintime ;
        if(diffIntimeOutTime>=0)
        {
          this.WorkingHoursModel.WorkingDuration=diffIntimeOutTime;
        }
        else
        {
          this.WorkingHoursModel.WorkingDuration=1440 + diffIntimeOutTime;
        }
      }
    });
  }

  SelectOutTime()
  {
    const amazingTimePicker = this.atp.open({});
    amazingTimePicker.afterClose().subscribe(time => 
    {
      this.selectedOuttime = time;
      this.WorkingHoursModel.OutTime=this.selectedOuttime;
      
      if(this.selectedintime != undefined)
      {
        var intimevar = this.selectedintime.split(':');
        var outtimevar= this.selectedOuttime.split(':');
        var totalintime= Number(intimevar[0])*60 + Number(intimevar[1]);    
        var totalouttime= Number(outtimevar[0])*60 + Number(outtimevar[1]);
        var diffIntimeOutTime = totalouttime -totalintime ;
        if(diffIntimeOutTime>=0)
        {
          this.WorkingHoursModel.WorkingDuration=diffIntimeOutTime;
        }
        else
        {
          this.WorkingHoursModel.WorkingDuration=1440 + diffIntimeOutTime;
        }
      }
    });
  }
  // HalfDay Time Change
  SelectHalfDayinTime()
  {
    const amazingTimePicker = this.atp.open({});
    amazingTimePicker.afterClose().subscribe(time => 
    {
      this.selectedHalfDayintime = time;
      this.WorkingHoursModel.HalfDayInTime=this.selectedHalfDayintime;
      if(this.selectedHalfDayOuttime != undefined)
      {
        var Halfdayintimevar = this.selectedHalfDayintime.split(':');
        var Halfdayouttimevar= this.selectedHalfDayOuttime.split(':');
        var totalintime= Number(Halfdayintimevar[0])*60 + Number(Halfdayintimevar[1]);    
        var totalouttime= Number(Halfdayouttimevar[0])*60 + Number(Halfdayouttimevar[1]);
        var diffIntimeOutTime = totalouttime -totalintime ;
        this.WorkingHoursModel.HalfDayDuration=diffIntimeOutTime;
        if(diffIntimeOutTime>=0)
        {
          this.WorkingHoursModel.HalfDayDuration=diffIntimeOutTime;
        }
        else
        {
          this.WorkingHoursModel.HalfDayDuration=1440 + diffIntimeOutTime;
        }
      }
    });
  }

  SelectHalfDayOutTime()
  {
    const amazingTimePicker = this.atp.open({});
    amazingTimePicker.afterClose().subscribe(time => 
    {
      this.selectedHalfDayOuttime = time;

      this.WorkingHoursModel.HalfDayOutTime=this.selectedHalfDayOuttime;
      if(this.selectedHalfDayOuttime != undefined)
      {
        var Halfdayintimevar = this.selectedHalfDayintime.split(':');
        var Halfdayouttimevar= this.selectedHalfDayOuttime.split(':');
        var totalintime= Number(Halfdayintimevar[0])*60 + Number(Halfdayintimevar[1]);    
        var totalouttime= Number(Halfdayouttimevar[0])*60 + Number(Halfdayouttimevar[1]);
        var diffIntimeOutTime = totalouttime -totalintime ;
        this.WorkingHoursModel.HalfDayDuration=diffIntimeOutTime;
        if(diffIntimeOutTime>=0)
        {
          this.WorkingHoursModel.HalfDayDuration=diffIntimeOutTime;
        }
        else
        {
          this.WorkingHoursModel.HalfDayDuration=1440 + diffIntimeOutTime;
        }
      }
    });
  }
  // Select Early Out
  SelectEarlyOutTime()
  {
    const amazingTimePicker = this.atp.open({});
    amazingTimePicker.afterClose().subscribe(time => 
    {
      this.selectedEarlyOutTime = time;
      this.WorkingHoursModel.EarlyOutTime=this.selectedEarlyOutTime;
    });
  }
  // Select Half Day Start Time
  SelectHalfDayStartTime()
  {
    const amazingTimePicker = this.atp.open({});
    amazingTimePicker.afterClose().subscribe(time => 
    {
      this.selectedHalfDayStartTime = time;
      this.WorkingHoursModel.HalfDayStartTime=this.selectedHalfDayStartTime;
    });
  }
  // Select Half Day End Time
  SelectHalfDayEndTime()
  {
    const amazingTimePicker = this.atp.open({});
    amazingTimePicker.afterClose().subscribe(time => 
    {
      this.selectedHalfDayEndTime = time;
      this.WorkingHoursModel.HalfDayEndTime=this.selectedHalfDayEndTime;
    });
  }
  // Grace Time Change
  oncheckedChange() 
  {
    this.WorkingHoursModel.GraceTime=null;
  }

  RefreshPage()
  {
    this.rout.navigateByUrl('/Attendence/ManageAttendenceFineRules', {skipLocationChange: true}).then(()=>this.rout.navigate(["/Attendence/ManageWorkingHours"]))
  }
}
