<div class="body-content-area tabcontent" id="link1">
    <div class="row">
        <div class="col-lg-9">
            <div class="row">
                <div class="col-lg-1 low-padding text-center">
                    <!-- <img *ngIf="this.SelectedMenu.Image!=null" src={{this.SelectedMenu.Image}} class="body-icon" alt="Icon"/> -->
                </div>
                <div class="col-lg-10 clear-padding">
                    <span class="page-title">Manage Role Master</span>
                    <small class="page-description"></small>
                </div>
            </div>
        </div>
    </div>
  
    <div *ngIf="!(this.CanViewState)">
    <table id="tblRole" datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" class="table table-striped table-bordered table-new row-border hover" style=" width: 100%">
        <thead class="thead-blue text-center" style=" width: 100%">
            <tr>
                <th style=" width: 10%">Sl No.</th>
                <th style=" width: 20%" class="text-left">Role Name</th>
                <th style=" width: 60%" class="text-left">Modules Name</th>
                <th style=" width: 10%">Action</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let role of RoleMasters, let i = index">
                <td scope="row">{{i+1}}</td>
                <td class="text-left">{{role.RoleName}}</td>
                <td class="text-left">{{role.ModuleName}}</td>
                <td>
                    <button *ngIf="this.SelectedMenu.CanEdit" type="button" (click)="GetRoleMasterById( role.RoleId )" class="table-edit-btn"><i class="fas fa-edit"></i> </button> 
                    <button *ngIf="this.SelectedMenu.CanDelete" type="button" (click)="DeleteRoleMasterById( role.RoleId )" class="table-remove-btn"><i class="fas fa-trash-alt"></i>  </button>
                    <button *ngIf="this.SelectedMenu.CanView" type="button" (click)="ViewRoleMasterById( role.RoleId )" class="table-view-button" type="button" ><i class="fas fa-eye"></i></button>
                </td>
            </tr>
        </tbody>
    </table>
    <fieldset class="body-form-control">
        <app-dialog [(visible)]="this.SuccessStatus">
            <span class="successmsg">{{this.SuccessMessage}}</span>
        </app-dialog>
        
        <legend class="legend--">Role Master Creation</legend>
        <form #RoleMaster="ngForm" (ngSubmit)="SaveRoleMaster()">
            <div class="row">
                <div class="col-lg-4">
                    <div class="col-body-border-right">
                        <div class="form-group row">
                            <label  class="col-sm-4 col-form-label clear-padding-right"> Role Name</label>
                            <div class="col-sm-7 clear-left-padding">
                                <input type="text" [(ngModel)]="RoleMasterModel.RoleName" (blur)="CheckRoleNameDuplicate()" tabindex="1" id ="RoleName" name="RoleName" #RoleName="ngModel" required maxlength="250" class="form-control form-control-custom form-control-custom-new">
                            </div>
                            <div *ngIf="this.check.length > 0" class="alert alert-danger">Role Name is exist</div>
                            <div *ngIf="RoleName.invalid && (RoleName.dirty || RoleName.touched)" class="alert alert-danger">
                                <div *ngIf="RoleName.errors.required">Role Name  is required.</div>
                                <div *ngIf="RoleName.errors.maxlength">Role Name is not more than 250 characters</div>
                            </div>
                        </div>
                    </div>
                </div>
                
                <div class="col-lg-4">
                    <div class="col-body-border-right">
                        <div class="form-group row">
                        <label  class="col-sm-4 col-form-label clear-padding-right">Modules List</label>
                            <div class="col-lg-11">
                                <angular2-multiselect class="form-control" [data]="ModuleList" [(ngModel)]="ModuleselectedItems" name="ModuleselectedItems" 
                                    [settings]="ModuleSettings" (onSelect)="onItemSelect($event)" (onDeSelect)="OnItemDeSelect($event)" (onSelectAll)="onSelectAll($event)"
                                    (onDeSelectAll)="onDeSelectAll($event)">
                                </angular2-multiselect>
                            </div>
                        </div>
                    </div>
                </div>
  
                <div class="col-lg-4">
                    <div class="form-group row">
                        <label class="col-sm-6 col-form-label text-center clear-padding-right" *ngIf="!(this.IsDataIsInViewState)">
                            <button type="submit" id="submit" tabindex="4" class="btn btn-primary save-button" [disabled]="!(RoleMaster.valid && this.CheckExiststatus==true && this.ModuleselectedItems.length > 0 && this.DisableSave==true)">Save</button>  
                        </label>
                        <div class="col-sm-6 col-form-label text-center">       
                        <button  type="button" tabindex="5" class="btn btn-danger cancle-button" (click)="RefreshPage()" >Cancel</button></div>
                    </div>
                </div>
            </div>
        </form>
    </fieldset>
    </div>
  </div>  