import { Component, OnInit } from '@angular/core';
import { CrmLeadService } from '../../../shared/CRM/MICECRM/LEADCRM/CrmLeadService';
import { Router } from '@angular/router';
import { Model_CRM_Account_Info } from '../../../shared/CRM/MICECRM/LEADCRM/Model_CRM_Account_Info';
import { ConfirmSettings } from '@jaspero/ng2-confirmations/src/interfaces/confirm-settings';
import { ModalOptions,BsModalRef, BsModalService  } from 'ngx-bootstrap/modal'
import { Model_CRM_Lead_Documents } from '../../../shared/CRM/MICECRM/LEADCRM/Model_CRM_Lead_Documents';

@Component({
  selector: 'app-lead-account-info',
  templateUrl: './lead-account-info.component.html',
  styleUrls: ['./lead-account-info.component.css']
})
export class LeadAccountInfoComponent implements OnInit {

  updatedLeadId: any;

  SuccessMessage: string = "";
  SuccessStatus: boolean = false;
  // For Searchable Dropdown

  //Employee Id lIst

  Ids: number[] = [];
  Segmentobj: any;
  Imge: Model_CRM_Lead_Documents;
  //Ids:any;

  LeadAccountInfo: Model_CRM_Account_Info

  //for model 
  public modalRef: BsModalRef;
  public modelopt: ModalOptions;
  //for model

  ShowPopup: boolean = false;

  IsDataIsInViewState: boolean = false;
  ApprovedMsg: boolean = false;

  document: any;

  FileUploads: Model_CRM_Lead_Documents[] = [];

  FileUploadsDeletes: Model_CRM_Lead_Documents[] = [];
  allfiles: any = [];
  images: Model_CRM_Lead_Documents[] = [];

  settings: ConfirmSettings | any =
    {
      overlay: false,
      overlayClickToClose: true,
      showCloseButton: true,
      confirmText: 'Yes',
      declineText: 'No'
    };

  docId: any;
  constructor(private rout: Router,public CRM_LeadService: CrmLeadService, private modalService: BsModalService) {
    this.LeadAccountInfo = new Model_CRM_Account_Info();
  }

  ngOnInit() {
    if ((this.CRM_LeadService.model_CRM_Shared_lead.LeadId == undefined)) {
      this.rout.navigate(["/ManageMICEAccess/ManageLead/LeadBasicInfo"]);
      this.CRM_LeadService.SelectedCrmIndexPage = 0;
    }
    if (!(this.CRM_LeadService.model_CRM_Shared_lead.LeadId > 0)) {
      this.rout.navigate(["/ManageMICEAccess/ManageLead/LeadBasicInfo"]);
      this.CRM_LeadService.SelectedCrmIndexPage = 0;
   }
    if (this.CRM_LeadService.model_CRM_Shared_lead.LeadId > 0) {
      this.GetCRMAccountInfo(this.CRM_LeadService.model_CRM_Shared_lead.LeadId);
    }
    if (localStorage.getItem('SuccessfullMessage')) {
      this.SuccessStatus = true;
      this.SuccessMessage = localStorage.getItem('SuccessfullMessage')
      localStorage.removeItem('SuccessfullMessage');
    }
  }


  GetCRMAccountInfo(leadid) {

    let t = localStorage.getItem('userToken');
    t = 'Bearer' + t

    this.CRM_LeadService.Get_CRMLeadAccountInformation(t, leadid).then(x => {
      this.Segmentobj = x;

      Object.keys(this.Segmentobj).forEach(key => {

        switch (key) {
          case 'LeadId': {
            this.LeadAccountInfo.LeadId = this.Segmentobj[key];
            break;
          }

          case 'GstNo': {
            this.LeadAccountInfo.GstNo = this.Segmentobj[key];
            break;
          }
          case 'PanNo': {
            this.LeadAccountInfo.PanNo = this.Segmentobj[key];
            break;
          }

          default: {
            console.log("Invalid choice");
            break;
          }
        }

      });

    });
  }


  GetUplodesFileById(Id) {

    this.IsDataIsInViewState = false;
    // End of the permision portion
    let t = localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.FileUploads = [];
    this.CRM_LeadService.Get_CRMLeadGetDocument(t, Id).then(x => {
      this.document = x;

      Object.keys(this.document).forEach(key => {



        let CRMLeadtemp = new Model_CRM_Lead_Documents();
        CRMLeadtemp = this.document[key];

        this.FileUploads.push(CRMLeadtemp);

      });


    })

  }




  saveCRMAccountInfo(): void {
    let t = localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.CRM_LeadService.model_CRM_Shared_lead.IsActive = true;


    if (this.CRM_LeadService.model_CRM_Shared_lead.LeadId > 0) {
      //this.CRMLEADNOTE.LeadId= this.CRM_LeadService.model_CRM_Shared_lead.LeadId;

      this.CRM_LeadService.AddCRMAccountinformation(t, this.LeadAccountInfo).subscribe(res => {
        this.updatedLeadId = res;

        if (this.updatedLeadId > 0) {
          localStorage.setItem('SuccessfullMessage', "Successfully Updated");
          this.CRM_LeadService.SelectedCrmIndexPage = 0;
          this.rout.navigate(["/ManageMICEAccess/ManageLead/LeadBasicInfo"]);

        }
      });
    }

  }


  Uploaddocument(template) {

    this.GetUplodesFileById(this.CRM_LeadService.model_CRM_Shared_lead.LeadId);
    this.modelopt = new ModalOptions();
    this.modelopt.class = 'heavy_modal';
    this.modalService.config = this.modelopt;

    this.modalRef = this.modalService.show(template, this.modelopt);
    //this.GridShow=true;
    this.ShowPopup = true;
    //  this.ApprovedMsg=false;
    //  this.RejectedMsg=false;
    // Begning of the permission portion
    this.IsDataIsInViewState = false;
    // End of the permision portion
    this.ApprovedMsg = true;

  }








  save() {
    let t = localStorage.getItem('userToken');
    t = 'Bearer' + t;


    // if (this.CRM_LeadService.model_CRM_Shared_lead.LeadId > 0) {
    this.CRM_LeadService.AddCRMDocuments(t, this.FileUploads).subscribe(res => {

      this.docId = res;
      this.GetUplodesFileById(this.CRM_LeadService.model_CRM_Shared_lead.LeadId);

      if (this.docId > 0) {

        //localStorage.setItem('SuccessfullMessage', "Successfully Updated");          

      }
    });
    // }

  }

  DocDownLoad(DocumentId, type): void {


    let t = localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.CRM_LeadService.getDocument(t, DocumentId).subscribe(respData => {
      this.downLoadFile(respData, type);
    }, error => {

    });

  }


  /**
     * Method is use to download file from server.
     * @param data - Array Buffer data
     * @param type - type of the document.
     */
  downLoadFile(data: any, type: string) {
    var blob = new Blob([data], { type: type.toString() });
    var url = window.URL.createObjectURL(blob);
    var pwa = window.open(url);
    if (!pwa || pwa.closed || typeof pwa.closed == 'undefined') {
      alert('Please disable your Pop-up blocker and try again.');
    }
  }





  RefreshPage() {

    this.CRM_LeadService.CancelLead();
    this.CRM_LeadService.SelectedCrmIndexPage = 0
    let RoutName_more = 'ManageMICEAccess/ManageLead/LeadBasicInfo'
    this.rout.navigate([RoutName_more]);

  }

  CancleModelPopup() {

    this.modalRef.hide();
  }

  fileuploads(event) {

    const files = event.target.files;
    //console.log(files);

    if (files) {
      for (let i = 0; i < files.length; i++) {

        // const image ={

        //   name :'',
        //   type : '',
        //   size: '',
        //   url:''
        // } ;

        let image = new Model_CRM_Lead_Documents();

        this.allfiles.push(files[i]);
        image.DocumentActualName = files[i].name;
        image.DocumentType = files[i].type;

        const size = files[i].size / 1024;
        const mbc = size + '';
        const mb = mbc.split('.')[0];
        const length = mb.length;

        if (length === 4 || length === 5) {

          const mbsize = size / 1024;

          const splitdata = mbsize + '';
          const splitvalue = splitdata.split('.');
          let secondvariable = '';
          for (let j = 0; j < splitvalue.length; j++) {

            if (j === 1) {
              secondvariable = splitvalue[j].slice(0, 2);
            }
          }

          image.DocumentSize = splitvalue[0] + '.' + secondvariable + ' MB';
        }
        else {

          const splitdata = size + '';
          const splitvalue = splitdata.split('.');
          let secondvariable = '';
          for (let j = 0; j < splitvalue.length; j++) {

            if (j === 1) {
              secondvariable = splitvalue[j].slice(0, 2);
            }
          }

          image.DocumentSize = splitvalue[0] + '.' + secondvariable + ' KB';

        }

        let ObjFileUpload = new Model_CRM_Lead_Documents();

        var FileReades: FileReader = new FileReader();


        let filetyotypearray = image.DocumentSize.split("/");
        var re = /(?:\.([^.]+))?$/;


        ObjFileUpload.LeadId = this.CRM_LeadService.model_CRM_Shared_lead.LeadId;
        ObjFileUpload.DocumentId = 0;

        ObjFileUpload.DocumentName = image.DocumentActualName

        ObjFileUpload.DocumentExtension = re.exec(image.DocumentActualName)[1];

        ObjFileUpload.DocumentType = image.DocumentType;

        ObjFileUpload.DocumentSize = image.DocumentSize;




        // image.size=files[i].size /1000;




        const reader = new FileReader();
        reader.onload = (filedata) => {
          // image.url=reader.result + '';

          ObjFileUpload.File = String(reader.result);


        };

        reader.readAsDataURL(files[i]);

        // reader.readAsText(files[i]);
        this.images.push(image);

        ObjFileUpload.DocumentName = image.DocumentActualName;
        ObjFileUpload.DocumentActualName = image.DocumentActualName;

        // ObjFileUpload.File = String( reader.result);
        // alert(ObjFileUpload.File);
        this.FileUploads.push(ObjFileUpload);


      }
      event.srcElement.value = null;
    }

  }


  DeleteFile(Filex: any) {

    // const index= this.images.indexOf(image);
    // this.images.splice(index,1);
    // this.allfiles.splice(index,1);
    // this.FilesToUpload.splice(index,1);

    // const  indexF = this.FilesToUpload.indexOf(index,1)

    //this._confirmation.create('Confirm to Delete', 'Press Yes to Delete', this.settings)



    const index = this.FileUploads.indexOf(Filex);
    let tempfile = this.FileUploads[index];

    if (tempfile.LeadId > 0) {

      tempfile.Isactive = false;
      this.FileUploadsDeletes.push(tempfile);
      this.FileUploads.splice(index, 1);
    }
    else {

      this.FileUploads.splice(index, 1);

      this.Imge = this.images.find(x => x.DocumentActualName == tempfile.DocumentActualName);

      const indexofimg = this.images.indexOf(this.Imge);
      this.images.splice(indexofimg, 1);
    }

  }





}
