import { Component, OnInit, ViewChild } from '@angular/core';
import { CrmOppertunityService } from '../../../shared/CRM/MICECRM/Oppertunity/CrmOppertunityService';
import { Router } from '@angular/router';
import { Model_CRM_DomesticInternational } from '../../../shared/CRM/MICECRM/Oppertunity/Model_CRM_DomesticInternational';
import { Model_CRM_FitMice } from '../../../shared/CRM/MICECRM/Oppertunity/Model_CRM_FitMice';
import { Model_CRM_MoreInformation } from '../../../shared/CRM/MICECRM/Oppertunity/Model_CRM_MoreInformation';
import { DatePicker } from '../../../../../src/app/for-all/myDatepicker';
import { Model_CRM_AdvancePayment } from '../../../shared/CRM/MICECRM/Oppertunity/Model_CRM_AdvancePayment';
import { Model_CRM_SourceOf_Query } from '../../../shared/CRM/MICECRM/Oppertunity/Model_CRM_SourceOf_Query ';

@Component({
  selector: 'app-crm-opportunity-more-info',
  templateUrl: './crm-opportunity-more-info.component.html',
  styleUrls: ['./crm-opportunity-more-info.component.css']
})
export class CrmOpportunityMoreInfoComponent implements OnInit {
  @ViewChild(DatePicker, {static: false}) private myDatePicker: DatePicker;
  // Begin success message popup
  SuccessStatus: boolean;
  SuccessMessage: string;
  IsSavevisible: boolean = true;
  // end success message popup

  // Domestic International Drop Down 
  domesticinternationallist: Model_CRM_DomesticInternational[] = [];
  DomesticInternational: any;



  // For FIT/MICE Drop down :- 
  fitmicelist: Model_CRM_FitMice[] = [];
  FitMice: any;


  CRM_DomesticInternational_Model: Model_CRM_DomesticInternational;


  //  PaxId: number;
  OppertunityMoreInformationId: number;


  AdvancePaymentList: Model_CRM_AdvancePayment[] = [];
  AdvancePaymetName: any;

  SourceQuery: any;
  sourcequerylist: Model_CRM_SourceOf_Query[] = [];


  constructor(public CRM_OppertunityService: CrmOppertunityService, private rout: Router) {
    this.CRM_DomesticInternational_Model = new Model_CRM_DomesticInternational();
    this.CRM_OppertunityService.CRM_MoreInformation_ModelShare.IsCloseDateValid = true;
    this.CRM_OppertunityService.CRM_MoreInformation_ModelShare.IsDepertureDateValid = true;
  }

  ngOnInit() {

    if (localStorage.getItem('SuccessfullMessage')) {
      this.SuccessStatus = true;
      this.SuccessMessage = localStorage.getItem('SuccessfullMessage')
      localStorage.removeItem('SuccessfullMessage');

    }


    if ((this.CRM_OppertunityService.CrmCommonUseOportunityDetails.OppertunityId == undefined)) {
      this.CRM_OppertunityService.SelectedCrmIndexPage = 0;
      this.rout.navigate(["/ManageMICEAccess/CrmOppertunity/OportunityDetails"]);
    }

    if ((this.CRM_OppertunityService.CrmCommonUseOportunityDetails.OppertunityId == 0)) {
      this.CRM_OppertunityService.SelectedCrmIndexPage = 0;
      this.rout.navigate(["/ManageMICEAccess/CrmOppertunity/OportunityDetails"]);
    }

    // this.CRM_OppertunityService.CRM_MoreInformation_ModelShare.OppertunityMoreInformationId
    if (this.CRM_OppertunityService.CRM_MoreInformation_ModelShare.FitMiceId == undefined) {

      this.CRM_OppertunityService.CRM_MoreInformation_ModelShare.FitMiceId = 0;

    }
    if (this.CRM_OppertunityService.CRM_MoreInformation_ModelShare.DomesticInternationalId == undefined) {

      this.CRM_OppertunityService.CRM_MoreInformation_ModelShare.DomesticInternationalId = 0;

    }

    if (this.CRM_OppertunityService.CRM_MoreInformation_ModelShare == undefined) {
      this.CRM_OppertunityService.CRM_MoreInformation_ModelShare = new Model_CRM_MoreInformation();

      this.CRM_OppertunityService.CRM_MoreInformation_ModelShare.OppertunityId = this.CRM_OppertunityService.CrmCommonUseOportunityDetails.OppertunityId;
      this.CRM_OppertunityService.CRM_MoreInformation_ModelShare.OppertunityMoreInformationId = 0;
      this.CRM_OppertunityService.CRM_MoreInformation_ModelShare.NumberOfPax = 0;
      this.CRM_OppertunityService.CRM_MoreInformation_ModelShare.Retention = 0;
      this.CRM_OppertunityService.CRM_MoreInformation_ModelShare.CreditTime = '';
      //this.CRM_OppertunityService.CRM_MoreInformation_ModelShare.CloseDateval = '';
      this.CRM_OppertunityService.CRM_MoreInformation_ModelShare.QueryNumber = '';
      this.CRM_OppertunityService.CRM_MoreInformation_ModelShare.AdvancePayment = 0;
      this.CRM_OppertunityService.CRM_MoreInformation_ModelShare.DomesticInternationalId = 0;
      this.CRM_OppertunityService.CRM_MoreInformation_ModelShare.GPFigure = 0;
      this.CRM_OppertunityService.CRM_MoreInformation_ModelShare.Reason = '';
      this.CRM_OppertunityService.CRM_MoreInformation_ModelShare.FitMiceId = 0;
      this.CRM_OppertunityService.CRM_MoreInformation_ModelShare.TimePeriod = '';
      this.CRM_OppertunityService.CRM_MoreInformation_ModelShare.Source = '';
      this.CRM_OppertunityService.CRM_MoreInformation_ModelShare.DepertureDateval = '';
      this.CRM_OppertunityService.CRM_MoreInformation_ModelShare.AdvanceId = 0;
      this.CRM_OppertunityService.CRM_MoreInformation_ModelShare.SourceQueryId = 0;
      this.myDatePicker.refreshDatePicker();

    }

    // if (this.CRM_OppertunityService.CRM_MoreInformation_ModelShare.OppertunityMoreInformationId == 0) {
    //   this.CRM_OppertunityService.CRM_MoreInformation_ModelShare.CloseDateval = '';


    // }

    if (this.CRM_OppertunityService.CRM_MoreInformation_ModelShare.DepertureDateval == undefined) {
      this.CRM_OppertunityService.CRM_MoreInformation_ModelShare.DepertureDateval = '';


    }


    this.GetDomesticInternationalGroup();

    //this.CRM_OppertunityService.CRM_MoreInformation_ModelShare.DomesticInternationalId;

    this.GetFitMiceGroup();
    this.GetAdvancePayment();
    this.GetSourceQuery();
  }





  // Populate DomesticInternational Group  Drop  Down  

  GetDomesticInternationalGroup() {
    let t = localStorage.getItem('userToken');
    t = 'Bearer' + t
    this.domesticinternationallist = [];
    this.CRM_OppertunityService.Get_DomesticInternational(t).then(x => {
      this.DomesticInternational = x;
      Object.keys(this.DomesticInternational).forEach(key => {
        let DomesticInternationaltemp = new Model_CRM_DomesticInternational();
        DomesticInternationaltemp = this.DomesticInternational[key];
        this.domesticinternationallist.push(DomesticInternationaltemp);



      });

    });
  }

  //Populate DomesticInternational Group  Drop  Down



  OnChangeCreditTime(evt) {

    this.CRM_OppertunityService.CRM_MoreInformation_ModelShare.CreditTime = evt;


  }

  // Populate FIT/MICE Group  Drop  Down  

  GetFitMiceGroup() {
    let t = localStorage.getItem('userToken');
    t = 'Bearer' + t
    this.fitmicelist = [];
    this.CRM_OppertunityService.GetCrmFitMice(t).then(x => {
      this.FitMice = x;
      Object.keys(this.FitMice).forEach(key => {
        let FitMicetemp = new Model_CRM_FitMice();
        FitMicetemp = this.FitMice[key];
        this.fitmicelist.push(FitMicetemp);


      });

    });
  }

  //Populate FIT/MICE Group  Drop  Down  




  //    Date Vallidation Starts

  DepertureDates(DepartureDate): void {

    var DepartureDatearr = DepartureDate.split("/");
    this.CRM_OppertunityService.CRM_MoreInformation_ModelShare.DepertureDate = new Date(+DepartureDatearr[2], DepartureDatearr[1], +DepartureDatearr[0]);
    this.CRM_OppertunityService.CRM_MoreInformation_ModelShare.DepertureDateval = DepartureDate;

    // this.CRM_OppertunityService.CRM_MoreInformation_ModelShare.IsDepertureDateValid = this.CRM_OppertunityService.Created_Date(date);
    // this.CRM_OppertunityService.CRM_MoreInformation_ModelShare.DepertureDate = date;
  }

  //    Date Vallidation Starts


  CloseDates(Closedate): void {
    // this.CRM_OppertunityService.CRM_MoreInformation_ModelShare.IsCloseDateValid = this.CRM_OppertunityService.Closing_Date(date);
    // this.CRM_OppertunityService.CRM_MoreInformation_ModelShare.CloseDate = date;
    var dateParts = Closedate.split("/");
    this.CRM_OppertunityService.CRM_MoreInformation_ModelShare.CloseDate = new Date(+dateParts[2], dateParts[1], +dateParts[0]);
    this.CRM_OppertunityService.CRM_MoreInformation_ModelShare.CloseDateval = Closedate;

  }

  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;

  }


  saveCRMMoreInformation(): void {
    let t = localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.CRM_OppertunityService.CRM_MoreInformation_ModelShare.IsActive = true;

    if (this.CRM_OppertunityService.CRM_MoreInformation_ModelShare.OppertunityMoreInformationId > 0) {
      this.CRM_OppertunityService.UpdateCrmMoreInformation(t, this.CRM_OppertunityService.CRM_MoreInformation_ModelShare).subscribe(res => {
        this.OppertunityMoreInformationId = Number(res);
        this.CRM_OppertunityService.CRM_MoreInformation_ModelShare.OppertunityMoreInformationId = this.OppertunityMoreInformationId;
        if (this.OppertunityMoreInformationId > 0) {

          localStorage.setItem('SuccessfullMessage', "Successfully Updated");
          this.CRM_OppertunityService.SelectedCrmIndexPage = 2;
          this.rout.navigate(["/ManageMICEAccess/CrmOppertunity/FollowUp"]);

        }
      });
    }
    else {
      this.CRM_OppertunityService.AddCrmMoreInformation(t, this.CRM_OppertunityService.CRM_MoreInformation_ModelShare).subscribe(res => {
        this.OppertunityMoreInformationId = Number(res);
        this.CRM_OppertunityService.CRM_MoreInformation_ModelShare.OppertunityMoreInformationId = this.OppertunityMoreInformationId;


        if (this.OppertunityMoreInformationId > 0) {
          localStorage.setItem('SuccessfullMessage', "Successfully Updated");
          this.CRM_OppertunityService.SelectedCrmIndexPage = 2;
          this.rout.navigate(["/ManageMICEAccess/CrmOppertunity/FollowUp"]);

        }
      });
    }
  }

  GetAdvancePayment() {

    let t = localStorage.getItem('userToken');
    t = 'Bearer' + t
    this.AdvancePaymentList = [];
    this.CRM_OppertunityService.GetOpportunityAdvancePayment(t).then(x => {
      this.AdvancePaymetName = x;
      Object.keys(this.AdvancePaymetName).forEach(key => {
        let AdvancePayment = new Model_CRM_AdvancePayment();
        AdvancePayment = this.AdvancePaymetName[key];
        this.AdvancePaymentList.push(AdvancePayment);
      });

    });
  }

  // Populate Source- OF - Query Group  Drop  Down  

  GetSourceQuery() {
    let t = localStorage.getItem('userToken');
    t = 'Bearer' + t
    this.sourcequerylist = [];
    this.CRM_OppertunityService.GetSourceofQuery(t).then(x => {
      this.SourceQuery = x;
      Object.keys(this.SourceQuery).forEach(key => {
        let SourceQueryTemp = new Model_CRM_SourceOf_Query();
        SourceQueryTemp = this.SourceQuery[key];
        this.sourcequerylist.push(SourceQueryTemp);

      });

    });
  }

  // Populate Source- OF - Query Group  Drop  Down




  RefreshPage(): void {

    this.CRM_OppertunityService.ResetOportunity();

    this.CRM_OppertunityService.SelectedCrmIndexPage = 0;

    this.rout.navigate(["/ManageMICEAccess/CrmOppertunity/OportunityDetails"]);


  }


}


