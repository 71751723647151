import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { OrganizationService}  from '../shared/Organization/organization.service';
import { RegionForHR } from  '../shared/Organization/RegionForHRModel.Model';
import { UserService } from '../shared/user.service';
// Begning Confirmation
import { ConfirmationService } from '@jaspero/ng2-confirmations';
import { ResolveEmit } from '../../app/resolve-emit';
import { ConfirmSettings } from '../../app/confirm-settings';
// end of Confirmation
import { Menu } from '../shared/menu.model';
import { ModalOptions,BsModalRef, BsModalService } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-region-for-hr',
  templateUrl: './region-for-hr.component.html',
  styleUrls: ['./region-for-hr.component.css']
})
export class RegionForHrComponent implements OnInit 
{
  RegionForHRModel : RegionForHR= new RegionForHR();
  userClaims: any;
  RegionForHRs: RegionForHR[] = [];
  RegionForHR:any;
  Result : string;
  SuccessStatus:boolean=false;
  SuccessMessage: string ="";
  public tableWidget: any;
  RefId : any;
  checkRegionNameval : string ="";
  //for HR Dropdown list
  HRdropdownList = [];
  HRselectedItems = [];
  dropdownSettings = {};
  HRdropdownSettings = {};
  HRIds :any;
  //for HR Dropdown list
  tempRegionForHRadd : RegionForHR =new RegionForHR();
  //check for duplicate RegionForHR code
  CheckRegionForHRCode : boolean=null;  
  checkstatusedit : boolean =false;
  RegionForHRCodecheck : string ="";
  //check for duplicate RegionForHR code
  // Begning Permission Portion    
  SelectedMenu:Menu;
  IsDataIsInViewState : boolean=false;
  CanViewState : boolean=false;
  DisableSave :boolean=false;
  // End of the permission portion
  //For Pop up
  SaveMsg : boolean= false;
  UpdateMsg : boolean= false;
  DeleteMsg : boolean=false;
  ShowPopup : boolean= false;
  public modalRef: BsModalRef;
  public modelopt:ModalOptions;

  constructor(private organizationService: OrganizationService, private rout: Router, private _confirmation: ConfirmationService, private userService: UserService,private modalService: BsModalService)
  {
    this.modelopt=new ModalOptions();
    this.modelopt.class='heavy_modal';
    this.modalService.config= this.modelopt;
    this.modelopt.ignoreBackdropClick=true;
  }

  ngOnInit() 
  {
    //Begin Permission Portion
    this.SelectedMenu=this.userService.SelectedMenu;
    if(this.SelectedMenu !=undefined)
    {
      localStorage.setItem('selectedmenu',JSON.stringify(this.SelectedMenu));
      this.rout.navigate([this.SelectedMenu.RouteName]);
    }
    else
    {
      if(JSON.parse(localStorage.getItem('selectedmenu'))!=undefined)
      {
        this.SelectedMenu = JSON.parse(localStorage.getItem('selectedmenu'));
        this.rout.navigate([this.SelectedMenu.RouteName]);
      }
    }     
    if(this.SelectedMenu.CanAdd)
    {
      this.IsDataIsInViewState=false;
    }
    else
    {
      this.IsDataIsInViewState=true;
    }
    if(this.SelectedMenu.CanView)
    {
      this.CanViewState=false;
    }
    else
    {
      this.CanViewState=true;
    }
    //End of the peremission portion
    this.SaveMsg=false;
    this.UpdateMsg=false;
    this.DeleteMsg=false;
    this.ShowPopup=false;
    this.DisableSave=true;
    this.HRselectedItems =  [];
    this.HRdropdownList = [];
    this.HRdropdownSettings = 
    { 
      text:"Select HR Name",
      selectAllText:'Select All',
      unSelectAllText:'UnSelect All',           
      classes:"myclass custom-class",
      enableSearchFilter: true,
    };  
    this.getRegionForHRs();
    this.getHRNames();     
  }

  settings: ConfirmSettings | any = 
  {
    overlay: true,
    overlayClickToClose: true,
    showCloseButton: true,
    confirmText: 'Yes',
    declineText: 'No'
  };

  getRegionForHRs()
  {
    this.RegionForHRs =[];
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.organizationService.GetRegionForHR( t).then(x => 
    {
      this.RegionForHR = x;
      Object.keys(this.RegionForHR).forEach( key => 
      {
        this.RegionForHR[key];
        let RegionForHRtemp = new RegionForHR();
        RegionForHRtemp = this.RegionForHR[key];
        this.RegionForHRs.push(RegionForHRtemp);
      });
    });
  }

  GetRegionForHRByid(id,template) 
  {
    // Begning of the permission portion
    this.IsDataIsInViewState=false;
    // End of the permision portion
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.organizationService.GetRegionForHRById( t,id).then(x => 
    {
      if(this.ShowPopup==true)
      {
        this.modalRef.hide();
      }
      this.modalRef = this.modalService.show(template,this.modelopt);
      this.ShowPopup=true;
      this.SaveMsg=false;
      this.UpdateMsg=false;
      this.DeleteMsg=false;
      this.RegionForHR = x;
      Object.keys(this.RegionForHR).forEach( key => 
      {
        this.RegionForHR[key];
        let RegionForHRtemp = new RegionForHR();
        RegionForHRtemp = this.RegionForHR[key];
        this.RegionForHRModel.RefId=RegionForHRtemp.RefId;
        this.RegionForHRModel.RegionName=RegionForHRtemp.RegionName;
        this.RegionForHRModel.HRIds=RegionForHRtemp.HRIds;
      });
      this.getHRNames();
      // check for duplicate Region Name 
      this.checkstatusedit=true;
      this.checkRegionNameval = this.RegionForHRModel.RegionName;
      this.CheckRegionForHRCode=true;      
      this.RegionForHRCodecheck="";
      // check for duplicate Region Name 
    });
  }

  ViewRegionForHRByid(id,template) 
  {
    //begin permission
    this.IsDataIsInViewState=true;
    //end permission
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.organizationService.GetRegionForHRById( t,id).then(x => 
    {
      if(this.ShowPopup==true)
      {
        this.modalRef.hide();
      }
      this.modalRef = this.modalService.show(template,this.modelopt);
      this.ShowPopup=true;
      this.SaveMsg=false;
      this.UpdateMsg=false;
      this.DeleteMsg=false;
      this.RegionForHR = x;
      Object.keys(this.RegionForHR).forEach( key => 
      {
        this.RegionForHR[key];
        let RegionForHRtemp = new RegionForHR();
        RegionForHRtemp = this.RegionForHR[key];
        this.RegionForHRModel.RefId=RegionForHRtemp.RefId;
        this.RegionForHRModel.RegionName=RegionForHRtemp.RegionName;
        this.RegionForHRModel.HRIds=RegionForHRtemp.HRIds;
      });
      this.getHRNames();
    });
  }

  saveRegionForHR(template) :void
  {
    this.DisableSave=false;
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.RegionForHRModel.IsActive=true;
    if(this.RegionForHRModel.RefId > 0)
    {
      if(this.HRselectedItems.length > 0)  
      {
        this.RegionForHRModel.HRIds='';
        this.HRselectedItems .forEach(element => 
        {
          this.RegionForHRModel.HRIds= this.RegionForHRModel.HRIds + element.id + ",";
        });
        var l = this.RegionForHRModel.HRIds.length
        this.RegionForHRModel.HRIds = this.RegionForHRModel.HRIds.substr(0,l-1);
        this.organizationService.UpdateRegionForHR(t ,this.RegionForHRModel).subscribe(res=>
        {
          this.RefId=res;
          this.RegionForHRModel.RefId=0;  
          if(this.RefId>0)
          {
            this.RegionForHRModel.RegionName=null;
            this.modalRef.hide();
            this.SaveMsg=false;
            this.UpdateMsg=true;
            this.DeleteMsg=false;
            this.ShowPopup=false;
            this.modalRef = this.modalService.show(template);
            this.getRegionForHRs();
            this.getHRNames();
            this.DisableSave=true;
          }
        });
      }
    }
    else
    {
      if(this.HRselectedItems.length > 0)  
      {
        this.RegionForHRModel.HRIds='';
        this.HRselectedItems .forEach(element => 
        {
          this.RegionForHRModel.HRIds= this.RegionForHRModel.HRIds + element.id + ",";
        });
        var l = this.RegionForHRModel.HRIds.length
        this.RegionForHRModel.HRIds = this.RegionForHRModel.HRIds.substr(0,l-1);
        this.organizationService.AddRegionForHR(t ,this.RegionForHRModel).subscribe(res=>
        {
          this.RefId=res;
          this.RegionForHRModel.RefId=0;              
          if(this.RefId>0)
          {
            this.RegionForHRModel.RegionName=null;
            this.modalRef.hide();
            this.SaveMsg=true;
            this.UpdateMsg=false;
            this.DeleteMsg=false;
            this.ShowPopup=false;
            this.modalRef = this.modalService.show(template);
            this.getRegionForHRs();
            this.getHRNames();
            this.DisableSave=true;
          }
        }); 
      }
    }
  }

  DeleteRegionForHRByid(RegionForHRId : number,template) :void
  {
    this._confirmation.create('Confirm to Deleate', 'Press Yes to Delete', this.settings)
    .subscribe((ans: ResolveEmit) => 
    {
      if (ans.resolved == true) 
      {
        let t =  localStorage.getItem('userToken');
        t = 'Bearer' + t;
        this.RegionForHRModel.IsActive=true;
        if( RegionForHRId > 0)
        {
          this.organizationService.DeleteRegionForHR(t ,RegionForHRId).subscribe(res=>
          {
            this.RefId=res;
            this.RegionForHRModel.RefId=0;                                  
            if(this.RefId >0)
            {
              this.RegionForHRModel.RegionName=null;
              this.modalRef.hide();
              this.SaveMsg=false;
              this.UpdateMsg=false;
              this.DeleteMsg=true;
              this.ShowPopup=false;
              this.modalRef = this.modalService.show(template);
              this.getRegionForHRs();
              this.getHRNames();
              this.DisableSave=true;
            }
          });                  
        }
      }
    })
  }

  getHRNames() 
  {
    let t = localStorage.getItem('userToken');
    t = 'Bearer' + t
    this.organizationService.GetHRNameLists( t).then(x => 
    {
      this.HRselectedItems = [];
      this.HRdropdownList = [];
      this.HRIds = x;
      Object.keys(this.HRIds).forEach( key => 
      {
        // tslint:disable-next-line:no-unused-expression
        this.HRIds[key];
        let HRtemp= new RegionForHR();
        HRtemp = this.HRIds[key];
        this.HRdropdownList.push({"id":HRtemp.HRId,"itemName":HRtemp.HRName},);
      });  
      if(this.HRdropdownList.length > 0)  
      {
        var selected_br = this.RegionForHRModel.HRIds.split(',');
        for (var _i = 0; _i < selected_br.length; _i++) 
        {
          var item = this.HRdropdownList.find(x=>x.id==selected_br[_i])
          this.HRselectedItems.push(item);
        }
      }
    });      
  }

  onItemSelect(item:any){ }
  OnItemDeSelect(item:any){ }
  onSelectAll(items:any){ }
  onDeSelectAll(items:any){ }

  RefreshPage()
  {
    this.rout.navigateByUrl('/Organization/ManageRegionForHR', {skipLocationChange: true}).then(()=>this.rout.navigate(["/Organization/RegionForHR"]))
  }

  ADDClick(template)
  {
    if(this.ShowPopup==true)
    {
      this.modalRef.hide();
    }
    this.RegionForHRModel.RegionName=null;
    this.IsDataIsInViewState=false;
    this.RegionForHRModel.HRIds=null;
    this.modalRef = this.modalService.show(template,this.modelopt);
    this.ShowPopup=true;
    this.SaveMsg=false;
    this.UpdateMsg=false;
    this.DeleteMsg=false;
    this.DisableSave=true;
    this.HRselectedItems = [];
    this.HRdropdownList = [];
    this.getHRNames();
  }

  // begning of check duplicate RegionForHRcode
  checkRegionName()
  {
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    if(!this.checkstatusedit)
    {
      this.organizationService.CheckRegionForHR( t, this.RegionForHRModel.RegionName).then(x => 
      {
        this.RegionForHR = x;
        if( this.RegionForHR.length >0)
        {
          this.CheckRegionForHRCode =false;
          this.RegionForHRCodecheck="Exist"
        }
        else
        {
          this.CheckRegionForHRCode =true;
          this.RegionForHRCodecheck="";
        }
      });
    }
    else
    {
      if(this.RegionForHRModel.RegionName !=this.checkRegionNameval )
      {
        this.organizationService.CheckRegionForHR( t, this.RegionForHRModel.RegionName).then(x => 
        {
          this.RegionForHR = x;
          if( this.RegionForHR.length >0)
          {
            this.CheckRegionForHRCode =false;
            this.RegionForHRCodecheck="Exist"
          }
          else
          {
            this.CheckRegionForHRCode =true;
            this.RegionForHRCodecheck="";
          }
        });
      }
      else
      {
        this.RegionForHRCodecheck="";
        this.CheckRegionForHRCode =true;
      }
    }
  }
  // end of duplicate vettical code
}