export class Model_CrmCountry
    {      
  
            public CountryName : string;
            public CountryId : number;
            public  IsActive : boolean;
            public CountryCode :string; 
                     //  public  CountryCode:string;

   

    
        
    }       