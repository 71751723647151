<div class="top-header"></div>
<div class="container-fluid">
</div>
<div class="after-header">
    <div class="container">
        <div class="row">
            <div class="offset-md-3 col-md-6 col-sm-12">
                <div class="top-shadow"></div>
                <div class="login-form ">
                    <div class="login-for-header-icon">
                        <i class="far fa-user"></i>
                    </div>
                    <h2 class="signin-text">Sign In</h2>
                    <div *ngIf="isLoginError" style=" color:red; font-weight:bold;">
                        Incorrect username or password
                    </div>
                    <form #loginForm="ngForm"  (ngSubmit)="OnSubmit()">
                        <div class="col-auto">
                            <label class="sr-only" for="inlineFormInputGroup">Username</label>
                            <div class="input-group mb-4 input-large">
                                <div class="input-group-prepend">
                                    <div class="input-group-text input-group-text-custom"> <i class="fas fa-user"></i> </div>
                                </div>
                                <input type="text" style="color: blue; font-weight:bold; height: auto;" class="form-control form-control-custom" #UserName="ngModel" name="UserName" placeholder="Username" required [(ngModel)]="user.UserId">
                            </div>
                        </div>
                        <div class="col-auto">
                            <label class="sr-only" for="inlineFormInputGroup">Password</label>
                            <div class="input-group mb-4 input-large">
                                <div class="input-group-prepend">
                                    <div class="input-group-text input-group-text-custom"> <i class="fas fa-lock"></i> </div>
                                </div>
                                <input type="text" type="password" style="color: blue; font-weight:bold; height: auto;" class="form-control form-control-custom" #Password="ngModel" name="Password" id="inlineFormInputGroup" [(ngModel)]="user.Password" placeholder="Password" required>
                            </div>
                        </div>
                        <div class="col-auto mb-3">
                            <button [disabled]="!loginForm.valid" class="btn btn-primary btn-block btn-lg btn-color-custom" type="submit">Login</button>
                        </div>
                        <div class="col-auto mb-4">
                            <div class="row">
                                <div class="col-md-7">
                                    <div class="custom-control custom-checkbox">
                                        <input type="checkbox" class="custom-control-input"  name="Rememberme" #Rememberme="ngModel" [(ngModel)]="user.Rememberme" id="customCheck1">
                                        <label class="custom-control-label custom-control-new" for="customCheck1">Remember Me</label>
                                    </div>
                                </div>
                                <div class="col-md-5">
                                    <label class="custom-control-new" (click)="ViewPasswordReset(template)">Forgot password</label>
                                    <!-- <span class="custom-control-new">forgot password</span> -->
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
                <div class="bottom-shadow"></div>
                <br><br>
            </div>
        </div>
    </div>
</div>

<div style=" box-align: center; width: 1500px;">
    <ng-template #template>
        <div class="modal-header" style="background: #2a9bd7; max-width: 1500px;"> 
        <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()" style="color:#fff;">
            <span aria-hidden="true">&times;</span>
        </button>
        </div>
        <div  style="text-align: center; max-width: 1500px;">
        <div *ngIf="MsgSent" style="color: #dc3545; text-align: center;font-size: 15px; font-weight: bold;">Password Sent to Your Email Id Successfully</div>
        <div *ngIf="USerNotExists" style="color: #dc3545; text-align: center;font-size: 15px; font-weight: bold;">User Not Exists.</div>
        <div style=" width: 100%;" *ngIf="ShowPopup">
        <fieldset class="body-form-control">
            <legend class="legend--">User Details</legend>
            <form #PasswordForm="ngForm" (ngSubmit)="PasswordResetMailFarward(template)">
                <div class="row">
                    <div class="col-lg-4">
                    <div class="col-body-border-right">
                        <div class="form-group row">
                            <label  class="col-sm-4 col-form-label clear-padding-right">User Id</label>
                            <div class="col-sm-7 clear-left-padding">
                                <input type="text" [(ngModel)]="this.user.UserId" id ="UserId" pattern="[0-9]+" name="UserId" #UserId="ngModel" required minlength="3" maxlength="15" class="form-control form-control-custom form-control-custom-new">
                            </div>
                            <div *ngIf="UserId.invalid && (UserId.dirty || UserId.touched)" class="alert alert-danger">
                                <div *ngIf="UserId.errors.required">UserId is required.</div>
                                <div *ngIf="UserId.errors.maxlength">UserId is not more than 15 characters</div>
                                <div *ngIf="UserId.errors.minlength">UserId is not less than 3 characters</div>
                            </div>
                        </div>
                    </div>
                    </div>
                    <div class="col-lg-4">
                        <div class="form-group row">
                            <label class="col-sm-4 col-form-label clear-padding-right">&nbsp;</label>
                            <label class="col-sm-7 col-form-label text-center clear-padding-right">
                                <button type="submit" class="btn btn-primary save-button" [disabled]="!(PasswordForm.valid && this.DisableSave==true)">Reset Password</button>  
                            </label>
                        </div>
                    </div>
                    <div class="col-lg-4">
                        
                    </div>
                </div>
            </form>
        </fieldset>
        </div>
        <button type="button" aria-label="Close" (click)="modalRef.hide()" style="background: #2a9bd7; outline: 0px none; border: 0px none; color: #fff; padding: 5px 10px; border-radius: 3px;">
        <span>Close</span>
        </button>             
    </div>
    </ng-template>
</div>