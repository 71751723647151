import { Component, OnInit } from '@angular/core';
import { DatePipe } from '@angular/common';
import { Subject } from 'rxjs/Subject';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { SalesCallService } from '../../shared/CRM/MICECRM/SalceCall/SalesCallService';
import { Model_CRM_TeleCall_FollowUp } from '../../shared/CRM/MICECRM/SalceCall/Model_CRM_TeleCall_FollowUp';
import { Model_CRM_Telecall_Next_Followup } from '../../shared/CRM/MICECRM/SalceCall/Model_CRM_Telecall_Next_Followup';

declare var $: any;

@Component({
  selector: 'app-follow-up-model-popup',
  templateUrl: './follow-up-model-popup.component.html',
  styleUrls: ['./follow-up-model-popup.component.css']
})
export class FollowUpModelPopupComponent implements OnInit {

  public onClose: Subject<boolean>;

  GetFollowUps: Model_CRM_TeleCall_FollowUp[];

  Followups: any;

  FollowupId: any;

  FollowId: number;

  userdate: any;

  NextFolloupTime: string;
  NextFollowUpdate: string;

  CurrentDate: Date;

  Houres: any;
  Minutes: any;


  NextFollowUpDate: string;


  ModelNextFollowUp: Model_CRM_Telecall_Next_Followup;
  NextFollowUp: any;

  constructor(public _bsModalRef: BsModalRef, private SalesSrvice: SalesCallService) {

  }



  public ngOnInit(): void {
    this.onClose = new Subject();

    this.GetTeleCalls();




    this.CurrentDate = new Date();

    this.NextFolloupTime = '';

    this.Houres = this.CurrentDate.getHours();

    this.Minutes = this.CurrentDate.getMinutes();
    if (this.Houres < 10) {
      this.NextFolloupTime = '0' + this.Houres;
    }
    else {
      this.NextFolloupTime = this.Houres;
    }

    if (this.Minutes < 10) {

      this.NextFolloupTime = this.NextFolloupTime + ':' + '0' + this.Minutes;
    }
    else {

      this.NextFolloupTime = this.NextFolloupTime + ':' + this.Minutes;
    }
    var datePipe = new DatePipe("en-US");
    this.userdate = datePipe.transform(this.CurrentDate, 'dd/MM/yyyy');
    this.NextFollowUpdate = this.userdate;
    $("#NextFolloupTime").timepicker()

    $("#NextFolloupDate").datepicker({ dateFormat: "dd/mm/yy", minDate: this.CurrentDate });

    this.GetTeleCallNextFollowUp();

  }

  public onConfirm(): void {
    this.onClose.next(true);
    this._bsModalRef.hide();
  }

  public onCancel(): void {
    this.onClose.next(false);
    this._bsModalRef.hide();
  }

  public ButtonClick() {

    this.NextFolloupTime = $("#NextFolloupTime").val();
    this.NextFollowUpdate = $("#NextFolloupDate").val();
    this.ModelNextFollowUp = new Model_CRM_Telecall_Next_Followup();
    this.ModelNextFollowUp.Tele_Call_Id = this.SalesSrvice.Telecallid;
    this.ModelNextFollowUp.TeleCallNext_Time = this.NextFolloupTime;
    this.ModelNextFollowUp.TeleCall_NextFollowUp_Date = this.NextFollowUpdate;

    let t = localStorage.getItem('userToken');
    t = 'Bearer' + t;

    this.SalesSrvice.AddUpdateFollowUpsNext(t, this.ModelNextFollowUp).subscribe(res => {
      this.FollowupId = res;
      this.FollowId = this.FollowupId;
      //this.GetFollowUps.find(x => x.FollowUpId == folloupId).FollowUpId = this.FollowupId;

    });

  }


  GetTeleCalls() {

    let t = localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.GetFollowUps = [];
    //this.DesignationArray=[];
    this.SalesSrvice.GetTeleFollowups(t, this.SalesSrvice.Telecallid).then(x => {
      this.Followups = x;
      Object.keys(this.Followups).forEach(key => {
        let Crm_Telecall_Temp = new Model_CRM_TeleCall_FollowUp();
        Crm_Telecall_Temp = this.Followups[key];
        this.GetFollowUps.push(Crm_Telecall_Temp);
      })
    }).then(x => {

      let Crm_Telecall_FollowsUp = new Model_CRM_TeleCall_FollowUp()
      Crm_Telecall_FollowsUp.Tele_Call_Id = this.SalesSrvice.Telecallid;
      Crm_Telecall_FollowsUp.FollowUpId = 0;
      Crm_Telecall_FollowsUp.FolloWUpText = '';

      this.userdate = new Date()
      var datePipe = new DatePipe("en-US");
      this.userdate = datePipe.transform(this.userdate, 'dd/MM/yyyy');

      Crm_Telecall_FollowsUp.FollowUpdate = this.userdate;
      this.GetFollowUps.push(Crm_Telecall_FollowsUp);

    })

  }


  GetTeleCallNextFollowUp() {

    let t = localStorage.getItem('userToken');
    t = 'Bearer' + t;

    //this.DesignationArray=[];
    this.SalesSrvice.GetTelecallsFollowUpNext(t, this.SalesSrvice.Telecallid).then(x => {
      this.NextFollowUp = x;
      Object.keys(this.NextFollowUp).forEach(key => {

        this.ModelNextFollowUp = this.NextFollowUp[key];

      })
    }).then(x => {
      if (this.ModelNextFollowUp != null) {
        if (this.ModelNextFollowUp.TeleCallNext_Time != "") {
          this.NextFolloupTime = this.ModelNextFollowUp.TeleCallNext_Time;
        }
        else {
          this.NextFolloupTime = '';

          this.Houres = this.CurrentDate.getHours();

          this.Minutes = this.CurrentDate.getMinutes();
          if (this.Houres < 10) {
            this.NextFolloupTime = '0' + this.Houres;
          }
          else {
            this.NextFolloupTime = this.Houres;
          }

          if (this.Minutes < 10) {

            this.NextFolloupTime = this.NextFolloupTime + ':' + '0' + this.Minutes;
          }
          else {

            this.NextFolloupTime = this.NextFolloupTime + ':' + this.Minutes;
          }

        }
        if (this.ModelNextFollowUp.TeleCall_NextFollowUp_Date != "") {
          this.NextFollowUpdate = this.ModelNextFollowUp.TeleCall_NextFollowUp_Date;

        }
        else {

          var datePipe = new DatePipe("en-US");
          this.userdate = datePipe.transform(this.CurrentDate, 'dd/MM/yyyy');
          this.NextFollowUpdate = this.userdate;

        }

      }
      else {

        this.NextFolloupTime = '';

        this.Houres = this.CurrentDate.getHours();

        this.Minutes = this.CurrentDate.getMinutes();
        if (this.Houres < 10) {
          this.NextFolloupTime = '0' + this.Houres;
        }
        else {
          this.NextFolloupTime = this.Houres;
        }

        if (this.Minutes < 10) {

          this.NextFolloupTime = this.NextFolloupTime + ':' + '0' + this.Minutes;
        }
        else {

          this.NextFolloupTime = this.NextFolloupTime + ':' + this.Minutes;
        }
        var datePipe = new DatePipe("en-US");
        this.userdate = datePipe.transform(this.CurrentDate, 'dd/MM/yyyy');
        this.NextFollowUpdate = this.userdate;

      }
    })

  }


  OportunityDesription(ev, id: number) {
    try {

      this.FollowId = 0;

      this.GetFollowUps.find(x => x.FollowUpId == id).FolloWUpText = ev.target.value;
    } catch (e) {
      console.info('could not set textarea-value');
    }
  }

  SaveButton(Text: string, folloupId: number) {
    try {


      let t = localStorage.getItem('userToken');
      t = 'Bearer' + t;

      if (this.SalesSrvice.Telecallid > 0) {

        this.SalesSrvice.AddUpdateFollowUps(t, folloupId, this.SalesSrvice.Telecallid, Text).subscribe(res => {
          this.FollowupId = res;
          this.FollowId = this.FollowupId;
          this.GetFollowUps.find(x => x.FollowUpId == folloupId).FollowUpId = this.FollowupId;

        });
      }



    }
    catch (e) {
      console.info('could not set textarea-value');
    }


  }



}
