import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AdminMasterService }  from '../../shared/HR_ADMIN/Admin/AdminMasterService.service';
import { EventMessageModel }  from '../../shared/HR_ADMIN/Admin/EventMessageModel.Model';
import { UserService } from '../../shared/user.service';
// Begning Confirmation
import { ConfirmationService } from '@jaspero/ng2-confirmations';
import { ResolveEmit } from '../../../app/resolve-emit';
import { ConfirmSettings } from '../../../app/confirm-settings';
// end of Confirmation
import { Menu } from '../../shared/menu.model';
import { ModalOptions,BsModalRef, BsModalService } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-manage-event-message',
  templateUrl: './manage-event-message.component.html',
  styleUrls: ['./manage-event-message.component.css']
})
export class ManageEventMessageComponent implements OnInit 
{
  EventMessageModel : EventMessageModel= new EventMessageModel();
  userClaims: any;
  EventMessages: EventMessageModel[] = [];
  EventMessage:any;
  Result : string;
  SuccessStatus:boolean=false;
  SuccessMessage: string ="";
  public tableWidget: any;
  RefId : any;
  // Begning Permission Portion    
  SelectedMenu:Menu;
  IsDataIsInViewState : boolean=false;
  CanViewState : boolean=false;
  DisableSave :boolean=false;
  // End of the permission portion
  //For Pop up
  SaveMsg : boolean= false;
  UpdateMsg : boolean= false;
  DeleteMsg : boolean=false;
  ShowPopup : boolean= false;
  public modalRef: BsModalRef;
  public modelopt:ModalOptions;

  constructor(private AdminMasterService: AdminMasterService, private rout: Router, private _confirmation: ConfirmationService, private userService: UserService,private modalService: BsModalService)
  {
    this.modelopt=new ModalOptions();
    this.modelopt.class='heavy_modal';
    this.modalService.config= this.modelopt;
    this.modelopt.ignoreBackdropClick=true;
  }

  ngOnInit() 
  {
    //Begin Permission Portion
    this.SelectedMenu=this.userService.SelectedMenu;
    if(this.SelectedMenu !=undefined)
    {
      localStorage.setItem('selectedmenu',JSON.stringify(this.SelectedMenu));
      this.rout.navigate([this.SelectedMenu.RouteName]);
    }
    else
    {
      if(JSON.parse(localStorage.getItem('selectedmenu'))!=undefined)
      {
        this.SelectedMenu = JSON.parse(localStorage.getItem('selectedmenu'));
        this.rout.navigate([this.SelectedMenu.RouteName]);
      }
    }     
    if(this.SelectedMenu.CanAdd)
    {
      this.IsDataIsInViewState=false;
    }
    else
    {
      this.IsDataIsInViewState=true;
    }
    if(this.SelectedMenu.CanView)
    {
      this.CanViewState=false;
    }
    else
    {
      this.CanViewState=true;
    }
    //End of the peremission portion
    this.SaveMsg=false;
    this.UpdateMsg=false;
    this.DeleteMsg=false;
    this.ShowPopup=false;
    this.DisableSave=true; 
    this.GETEventMessage();    
  }

  settings: ConfirmSettings | any = 
  {
    overlay: true,
    overlayClickToClose: true,
    showCloseButton: true,
    confirmText: 'Yes',
    declineText: 'No'
  };

  GETEventMessage()
  {
    this.EventMessages =[];
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.AdminMasterService.GetEventMessageGV( t).then(x => 
    {
      this.EventMessages =[];
      this.EventMessage = x;
      Object.keys(this.EventMessage).forEach( key => 
      {
        this.EventMessage[key];
        let Eventtemp = new EventMessageModel();
        Eventtemp = this.EventMessage[key];
        this.EventMessages.push(Eventtemp);
      });
    });
  }

  GetEventMessageByRefId(id,template) 
  {
    // Begning of the permission portion
    this.IsDataIsInViewState=false;
    // End of the permision portion
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.AdminMasterService.GetEventMessageByRefId( t,id).then(x => 
    {
      if(this.ShowPopup==true)
      {
        this.modalRef.hide();
      }
      this.modalRef = this.modalService.show(template,this.modelopt);
      this.ShowPopup=true;
      this.SaveMsg=false;
      this.UpdateMsg=false;
      this.DeleteMsg=false;
      this.EventMessage = x;
      Object.keys(this.EventMessage).forEach( key => 
      {
        this.EventMessage[key];
        let Eventtemp = new EventMessageModel();
        Eventtemp = this.EventMessage[key];
        this.EventMessageModel.RefId=Eventtemp.RefId;
        this.EventMessageModel.Message=Eventtemp.Message;
        this.EventMessageModel.MessageType=Eventtemp.MessageType;
        if(this.EventMessageModel.MessageType=='Event')
        {
          this.EventMessageModel.TypeId=1;
        }
        else if(this.EventMessageModel.MessageType=='MD Message')
        {
          this.EventMessageModel.TypeId=2;
        }
        else
        {
          this.EventMessageModel.TypeId=0;
        }
        this.EventMessageModel.FromDate=Eventtemp.FromDate;
        this.EventMessageModel.ToDate=Eventtemp.ToDate;
      });
    });
  }

  ViewEventMessageById(id,template) 
  {
    //begin permission
    this.IsDataIsInViewState=true;
    //end permission
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.AdminMasterService.GetEventMessageByRefId( t,id).then(x => 
    {
      if(this.ShowPopup==true)
      {
        this.modalRef.hide();
      }
      this.modalRef = this.modalService.show(template,this.modelopt);
      this.ShowPopup=true;
      this.SaveMsg=false;
      this.UpdateMsg=false;
      this.DeleteMsg=false;
      this.EventMessage = x;
      Object.keys(this.EventMessage).forEach( key => 
      {
        this.EventMessage[key];
        let Eventtemp = new EventMessageModel();
        Eventtemp = this.EventMessage[key];
        this.EventMessageModel.RefId=Eventtemp.RefId;
        this.EventMessageModel.Message=Eventtemp.Message;
        this.EventMessageModel.MessageType=Eventtemp.MessageType;
        this.EventMessageModel.FromDate=Eventtemp.FromDate;
        this.EventMessageModel.ToDate=Eventtemp.ToDate;
      });
    });
  }

  SaveEventMessage(template) :void
  {
    this.DisableSave=false;
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    if(this.EventMessageModel.TypeId==1)
    {
      this.EventMessageModel.MessageType='Event';
    }
    else if(this.EventMessageModel.TypeId==2)
    {
      this.EventMessageModel.MessageType='MD Message';
    }
    if(this.EventMessageModel.RefId > 0)
    {
      this.AdminMasterService.UpdateEventMessage(t ,this.EventMessageModel).subscribe(res=>
      {
        this.RefId=res;  
        if(this.RefId>0)
        {
          this.EventMessageModel.RefId=0;
          this.EventMessageModel.TypeId=0;
          this.EventMessageModel.Message=null;
          this.EventMessageModel.MessageType=null;
          this.EventMessageModel.FromDate=null;
          this.EventMessageModel.ToDate=null;
          this.modalRef.hide();
          this.SaveMsg=false;
          this.UpdateMsg=true;
          this.DeleteMsg=false;
          this.ShowPopup=false;
          this.modalRef = this.modalService.show(template);
          this.GETEventMessage();
          this.DisableSave=true;
        }
      });
    }
    else
    {
      this.AdminMasterService.AddEventMessage(t ,this.EventMessageModel).subscribe(res=>
      {
        this.RefId=res;            
        if(this.RefId>0)
        {
          this.EventMessageModel.RefId=0;
          this.EventMessageModel.TypeId=0;
          this.EventMessageModel.Message=null;
          this.EventMessageModel.MessageType=null;
          this.EventMessageModel.FromDate=null;
          this.EventMessageModel.ToDate=null;
          this.modalRef.hide();
          this.SaveMsg=true;
          this.UpdateMsg=false;
          this.DeleteMsg=false;
          this.ShowPopup=false;
          this.modalRef = this.modalService.show(template);
          this.GETEventMessage();
          this.DisableSave=true;
        }
      }); 
    }
  }

  DeleteEventMessageById(RefId : number,template) :void
  {
    this._confirmation.create('Confirm to Deleate', 'Press Yes to Delete', this.settings)
    .subscribe((ans: ResolveEmit) => 
    {
      if (ans.resolved == true) 
      {
        let t =  localStorage.getItem('userToken');
        t = 'Bearer' + t;
        if( RefId > 0)
        {
          this.AdminMasterService.DeleteEventMessage(t ,RefId).subscribe(res=>
          {
            this.RefId=res;                                
            if(this.RefId >0)
            {
              this.EventMessageModel.RefId=0;
              this.EventMessageModel.Message=null;
              this.EventMessageModel.MessageType=null;
              this.EventMessageModel.FromDate=null;
              this.EventMessageModel.ToDate=null;
              if(this.ShowPopup==true)
              {
                this.modalRef.hide();
              }
              this.SaveMsg=false;
              this.UpdateMsg=false;
              this.DeleteMsg=true;
              this.ShowPopup=false;
              this.modalRef = this.modalService.show(template);
              this.GETEventMessage();
              this.DisableSave=true;
            }
          });                  
        }
      }
    })
  }

  ADDClick(template)
  {
    this.EventMessageModel.RefId=0;
    this.EventMessageModel.TypeId=0;
    this.EventMessageModel.Message=null;
    this.EventMessageModel.MessageType=null;
    this.EventMessageModel.FromDate=null;
    this.EventMessageModel.ToDate=null;
    this.IsDataIsInViewState=false;
    if(this.ShowPopup==true)
    {
      this.modalRef.hide();
    }
    this.modalRef = this.modalService.show(template,this.modelopt);
    this.ShowPopup=true;
    this.SaveMsg=false;
    this.UpdateMsg=false;
    this.DeleteMsg=false;
    this.DisableSave=true;
    this.IsDataIsInViewState=false;
  }
  FromDateChanged( date)
  {
    this.EventMessageModel.FromDate=date;
  }

  ToDateChanged( date)
  {
    this.EventMessageModel.ToDate=date;
  }
}