import { Injectable,Inject } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';  
import { Http, Headers, RequestOptions, Response } from '@angular/http';  
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/toPromise';
import { Subscriber } from 'rxjs';
import { GlobalVariable  } from '../../../app.global';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { KRAMasterModel } from   '../KRA/KRAMasterModel.Model';
import { KRAMappingModel } from   '../KRA/KRAMappingModel.Model';
import { KRAApplicationModel } from   '../KRA/KRAApplicationModel.Model';
import { KRAMappingWithEmployeeModel } from   '../KRA/KRAMappingWithEmployeeModel.model';

@Injectable()

export class KRAService
{
    constructor(private http: HttpClient,private spinnerService: Ng4LoadingSpinnerService) 
    {
    }
    // Begin KRA Template
    ModificationKRATemplateMaster( token ,KRAMaster :KRAMasterModel) 
    {
        const authHeader = new HttpHeaders();         
        authHeader.append('Content-Type', 'application/json');
        authHeader.append('Authorization', token);
        authHeader.append('Accept', 'application/json');
        return this.http.put(GlobalVariable.BASE_API_URL +'/api/KRATemplate/ModificationKRATemplateMaster', KRAMaster ,{headers: authHeader} )
        .map(this.extractData)
        .catch(this.handleErrorObservable);
    }

    ModificationKRATemplateDetails( token ,KRAMaster :KRAMasterModel) 
    {
        const authHeader = new HttpHeaders();         
        authHeader.append('Content-Type', 'application/json');
        authHeader.append('Authorization', token);
        authHeader.append('Accept', 'application/json');
        return this.http.put(GlobalVariable.BASE_API_URL +'/api/KRATemplate/ModificationKRATemplateDetails', KRAMaster ,{headers: authHeader} )
        .map(this.extractData)
        .catch(this.handleErrorObservable);
    }

    GetKRATemplateMaster(token,CompanyId : number)
    {
        this.spinnerService.show();
        const authHeader = new HttpHeaders();     
        authHeader.append('Content-Type', 'application/json');
        authHeader.append('Authorization', token);
        return new Promise((resolve, reject) =>
        {
            setTimeout(()=>
            {
            this.http.get(GlobalVariable.BASE_API_URL +'/api/KRATemplate/GetKRATemplateMaster?CompanyId='+CompanyId,{headers: authHeader})
            .subscribe(data => 
            {
                this.spinnerService.hide();
                resolve(data)
            },
            error => 
            {
                this.spinnerService.hide();
                reject(error);
            },);

        },3000);
      });
    }

    GetKRATemplateDetails(token, id:number)
    {
        this.spinnerService.show();
        const authHeader = new HttpHeaders();     
        authHeader.append('Content-Type', 'application/json');
        authHeader.append('Authorization', token);
        return new Promise((resolve, reject) => 
        {
            setTimeout(()=>{
            this.http.get(GlobalVariable.BASE_API_URL +'/api/KRATemplate/GetKRATemplateDetails?TemplateRefId='+id,{headers: authHeader})
            .subscribe(data => 
            {
                this.spinnerService.hide();
                resolve(data)
            },
            error => 
            {
                this.spinnerService.hide();
                reject(error);
            },);


        },3000);
        });
    }

    CheckKRATemplateMasterName(token, TemplateName:string,CompanyId :number ) 
    {
        this.spinnerService.show();
        const authHeader = new HttpHeaders();     
        authHeader.append('Content-Type', 'application/json');
        authHeader.append('Authorization', token);
        return new Promise((resolve, reject) => 
        {
            setTimeout(()=>{
            this.http.get(GlobalVariable.BASE_API_URL +'/api/KRATemplate/CheckKRATemplateMasterName?TemplateName='+ TemplateName+'&CompanyId='+CompanyId,{headers: authHeader})
            .subscribe(data => 
            {
                this.spinnerService.hide();
                resolve(data)
            },
            error => 
            {
                this.spinnerService.hide();
                reject(error);
            },);
        },3000);
        });
    }

    DeleteKRATemplateMaster( token ,Refid : number) 
    {
        const authHeader = new HttpHeaders();         
        authHeader.append('Content-Type', 'application/json');
        authHeader.append('Authorization', token);
        authHeader.append('Accept', 'application/json');
        return this.http.put(GlobalVariable.BASE_API_URL +'/api/KRATemplate/DeleteKRATemplateMaster', Refid ,{headers: authHeader} )
        .map(this.extractData)
        .catch(this.handleErrorObservable);
    }

    DeleteKRATemplateDetails( token ,Refid : number) 
    {
        const authHeader = new HttpHeaders();         
        authHeader.append('Content-Type', 'application/json');
        authHeader.append('Authorization', token);
        authHeader.append('Accept', 'application/json');
        return this.http.put(GlobalVariable.BASE_API_URL +'/api/KRATemplate/DeleteKRATemplateDetails', Refid ,{headers: authHeader} )
        .map(this.extractData)
        .catch(this.handleErrorObservable);
    }

    // End KRA Template
    // Begning of the KRA Master
    GetKRAMaster(token )
    {
        this.spinnerService.show();
        const authHeader = new HttpHeaders();     
        authHeader.append('Content-Type', 'application/json');
        authHeader.append('Authorization', token);
        return new Promise((resolve, reject) =>
        {
            setTimeout(()=>
            {
            this.http.get(GlobalVariable.BASE_API_URL +'/api/KRAMaster/GetKRAMaster',{headers: authHeader})
            .subscribe(data => 
            {
                this.spinnerService.hide();
                resolve(data)
            },
            error => 
            {
                this.spinnerService.hide();
                reject(error);
            },);

        },3000);
      });
    }
    GetKRAMasterById(token, id:number ) 
    {
        this.spinnerService.show();
        const authHeader = new HttpHeaders();     
        authHeader.append('Content-Type', 'application/json');
        authHeader.append('Authorization', token);
        return new Promise((resolve, reject) => 
        {
            setTimeout(()=>{
            this.http.get(GlobalVariable.BASE_API_URL +'/api/KRAMaster/GetKRAMasterById?RefId='+id,{headers: authHeader})
            .subscribe(data => 
            {
                this.spinnerService.hide();
                resolve(data)
            },
            error => 
            {
                this.spinnerService.hide();
                reject(error);
            },);


        },3000);
        });
    }
    AddKRAMaster( token ,KRAMaster :KRAMasterModel) 
    {
        const authHeader = new HttpHeaders();         
        authHeader.append('Content-Type', 'application/json');
        authHeader.append('Authorization', token);
        authHeader.append('Accept', 'application/json');
        return this.http.post(GlobalVariable.BASE_API_URL +'/api/KRAMaster/Add', KRAMaster ,{headers: authHeader} )
        .map(this.extractData)
        .catch(this.handleErrorObservable);
    }
    DeleteKRAMaster( token ,Refid : number) 
    {
        const authHeader = new HttpHeaders();         
        authHeader.append('Content-Type', 'application/json');
        authHeader.append('Authorization', token);
        authHeader.append('Accept', 'application/json');
        return this.http.put(GlobalVariable.BASE_API_URL +'/api/KRAMaster/Delete', Refid ,{headers: authHeader} )
        .map(this.extractData)
        .catch(this.handleErrorObservable);
    }
    UpdateKRAMaster( token ,KRAMaster :KRAMasterModel) 
    {
        const authHeader = new HttpHeaders();         
        authHeader.append('Content-Type', 'application/json');
        authHeader.append('Authorization', token);
        authHeader.append('Accept', 'application/json');
        return this.http.put(GlobalVariable.BASE_API_URL +'/api/KRAMaster/Update', KRAMaster ,{headers: authHeader} )
        .map(this.extractData)
        .catch(this.handleErrorObservable);
    }
    CheckKRAMasterName(token, KRAMasterName:string ) 
    {
        this.spinnerService.show();
        const authHeader = new HttpHeaders();     
        authHeader.append('Content-Type', 'application/json');
        authHeader.append('Authorization', token);
        return new Promise((resolve, reject) => 
        {
            setTimeout(()=>{
            this.http.get(GlobalVariable.BASE_API_URL +'/api/KRAMaster/CheckKRAMasterName?KRAMasterName='+KRAMasterName,{headers: authHeader})
            .subscribe(data => 
            {
                this.spinnerService.hide();
                resolve(data)
            },
            error => 
            {
                this.spinnerService.hide();
                reject(error);
            },);
        },3000);
        });
    }
    // End of the KRA Master
    //Start KRA Mapping
    GetKRAMapping(token)
    {
        const authHeader = new HttpHeaders();     
        authHeader.append('Content-Type', 'application/json');
        authHeader.append('Authorization', token);
        return new Promise((resolve, reject) =>
        {       
        setTimeout(()=>
        {
            this.http.get(GlobalVariable.BASE_API_URL +'/api/KRAMapping/GetKRAMapping',{headers: authHeader})
            .subscribe(data => 
            {
            resolve(data)
            },
            error => 
            {
            reject(error);
            },);
        },3000);
        });
    }
    GetKRAMappingById(token, id:number ) 
    {
        this.spinnerService.show();

        const authHeader = new HttpHeaders();     
        authHeader.append('Content-Type', 'application/json');
        authHeader.append('Authorization', token);
        return new Promise((resolve, reject) => 
        {
        setTimeout(()=>{
        this.http.get(GlobalVariable.BASE_API_URL +'/api/KRAMapping/GetKRAMappingById?RefId='+id,{headers: authHeader})
        .subscribe(data => 
        {
            this.spinnerService.hide();
            resolve(data)
        },
        error => 
        {
            this.spinnerService.hide();
            reject(error);
        },);
        },3000);
        });
    }
    AddKRAMapping( token ,KRAMapping :KRAMappingModel ) 
    {
        const authHeader = new HttpHeaders();         
        authHeader.append('Content-Type', 'application/json');
        authHeader.append('Authorization', token);
        authHeader.append('Accept', 'application/json');
        return this.http.post(GlobalVariable.BASE_API_URL +'/api/KRAMapping/Add', KRAMapping ,{headers: authHeader} )
        .map(this.extractData)
        .catch(this.handleErrorObservable);
    }
    DeleteKRAMapping( token ,RefId : number) 
    {
        const authHeader = new HttpHeaders();         
        authHeader.append('Content-Type', 'application/json');
        authHeader.append('Authorization', token);
        authHeader.append('Accept', 'application/json');
        return this.http.put(GlobalVariable.BASE_API_URL +'/api/KRAMapping/Delete', RefId ,{headers: authHeader} )
        .map(this.extractData)
        .catch(this.handleErrorObservable);
    }
    UpdateKRAMapping( token ,KRAMapping :KRAMappingModel ) 
    {
        const authHeader = new HttpHeaders();         
        authHeader.append('Content-Type', 'application/json');
        authHeader.append('Authorization', token);
        authHeader.append('Accept', 'application/json');
        return this.http.put(GlobalVariable.BASE_API_URL +'/api/KRAMapping/Update', KRAMapping ,{headers: authHeader} )
        .map(this.extractData)
        .catch(this.handleErrorObservable);
    }
    CheckKRAMapping(token, CompanyId:number, DesignationId:number ) 
    {
        this.spinnerService.show();

        const authHeader = new HttpHeaders();     
        authHeader.append('Content-Type', 'application/json');
        authHeader.append('Authorization', token);
        return new Promise((resolve, reject) => 
        {
        setTimeout(()=>{
        this.http.get(GlobalVariable.BASE_API_URL +'/api/KRAMapping/CheckKRAMapping?CompanyId='+ CompanyId +' &DesignationId='+DesignationId,{headers: authHeader})
        .subscribe(data => 
        {
            this.spinnerService.hide();
            resolve(data)
        },
        error => 
        {
            this.spinnerService.hide();
            reject(error);
        },);
        },3000);
        });
    }
    //End KRA Mapping
    //Start KRA Employee Mapping
    GetKRATemplateMappingwithEmployee(token) 
    {
        this.spinnerService.show();

        const authHeader = new HttpHeaders();     
        authHeader.append('Content-Type', 'application/json');
        authHeader.append('Authorization', token);
        return new Promise((resolve, reject) => 
        {
        setTimeout(()=>{
        this.http.get(GlobalVariable.BASE_API_URL +'/api/KRAMappingWithEmployee/GetKRATemplateMappingwithEmployee',{headers: authHeader})
        .subscribe(data => 
        {
            this.spinnerService.hide();
            resolve(data)
        },
        error => 
        {
            this.spinnerService.hide();
            reject(error);
        },);
        },3000);
        });
    }
    EmployeeDetailsByLocation(token, CompanyId:number, BranchId:number, DepartmentId:number)
    {
        this.spinnerService.show();
        const authHeader = new HttpHeaders();     
        authHeader.append('Content-Type', 'application/json');
        authHeader.append('Authorization', token);
        return new Promise((resolve, reject) => 
        {
            setTimeout(()=>{
            this.http.get(GlobalVariable.BASE_API_URL +'/api/KRATemplate/EmployeeDetailsByLocation?CompanyId='+CompanyId+'&BranchId='+BranchId+'&DepartmentId='+DepartmentId,{headers: authHeader})
            .subscribe(data => 
            {
                this.spinnerService.hide();
                resolve(data)
            },
            error => 
            {
                this.spinnerService.hide();
                reject(error);
            },);


        },3000);
        });
    }

    GetKRATemplateDetailsById(token, id:number)
    {
        this.spinnerService.show();
        const authHeader = new HttpHeaders();     
        authHeader.append('Content-Type', 'application/json');
        authHeader.append('Authorization', token);
        return new Promise((resolve, reject) => 
        {
        setTimeout(()=>{
        this.http.get(GlobalVariable.BASE_API_URL +'/api/KRATemplate/GetKRATemplateDetailsById?TemplateRefId='+id,{headers: authHeader})
        .subscribe(data => 
        {
            this.spinnerService.hide();
            resolve(data)
        },
        error => 
        {
            this.spinnerService.hide();
            reject(error);
        },);
        },3000);
        });
    }

    GetKRAMappingWithEmployeeBYEmployeeId(token,EmployeeId,TemplateMasterRefId) 
    {
        this.spinnerService.show();

        const authHeader = new HttpHeaders();     
        authHeader.append('Content-Type', 'application/json');
        authHeader.append('Authorization', token);
        return new Promise((resolve, reject) => 
        {
        setTimeout(()=>{
        this.http.get(GlobalVariable.BASE_API_URL +'/api/KRAMappingWithEmployee/GetKRAMappingWithEmployeeBYEmployeeId?EmployeeId='+EmployeeId +'&TemplateMasterRefId='+ TemplateMasterRefId,{headers: authHeader})
        .subscribe(data => 
        {
            this.spinnerService.hide();
            resolve(data)
        },
        error => 
        {
            this.spinnerService.hide();
            reject(error);
        },);
        },3000);
        });
    }
    AddKRAMappingWithEmployee( token ,KRAMappingWithEmployee :KRAMappingWithEmployeeModel ) 
    {
        const authHeader = new HttpHeaders();         
        authHeader.append('Content-Type', 'application/json');
        authHeader.append('Authorization', token);
        authHeader.append('Accept', 'application/json');
        return this.http.post(GlobalVariable.BASE_API_URL +'/api/KRAMappingWithEmployee/Add', KRAMappingWithEmployee ,{headers: authHeader} )
        .map(this.extractData)
        .catch(this.handleErrorObservable);
    }
    UpdateKRAMappingWithEmployee( token ,KRAMappingWithEmployee :KRAMappingWithEmployeeModel ) 
    {
        const authHeader = new HttpHeaders();         
        authHeader.append('Content-Type', 'application/json');
        authHeader.append('Authorization', token);
        authHeader.append('Accept', 'application/json');
        return this.http.put(GlobalVariable.BASE_API_URL +'/api/KRAMappingWithEmployee/Update', KRAMappingWithEmployee ,{headers: authHeader} )
        .map(this.extractData)
        .catch(this.handleErrorObservable);
    }
    DeActiveKRAMappingWithEmployee( token ,EmployeeId,TemplateMasterRefId ) 
    {
        
        const authHeader = new HttpHeaders();         
        authHeader.append('Content-Type', 'application/json');
        authHeader.append('Authorization', token);
        authHeader.append('Accept', 'application/json');
        return this.http.put(GlobalVariable.BASE_API_URL +'/api/KRAMappingWithEmployee/DeActiveKRAMappingWithEmployee?EmployeeId='+ EmployeeId +'&TemplateMasterRefId='+ TemplateMasterRefId ,{headers: authHeader} )
        .map(this.extractData)
        .catch(this.handleErrorObservable);
    }
    //End KRA Employee Mapping
    //Begin KRA Application
    GetEmployeeDetailsBYLoginId(token) 
    {
        this.spinnerService.show();

        const authHeader = new HttpHeaders();     
        authHeader.append('Content-Type', 'application/json');
        authHeader.append('Authorization', token);
        return new Promise((resolve, reject) => 
        {
        setTimeout(()=>{
        this.http.get(GlobalVariable.BASE_API_URL +'/api/EmployeeKRAApplication/GetEmployeeDetailsBYLoginId',{headers: authHeader})
        .subscribe(data => 
        {
            this.spinnerService.hide();
            resolve(data)
        },
        error => 
        {
            this.spinnerService.hide();
            reject(error);
        },);
        },3000);
        });
    }
    GetKRAApplicationBYLoginId(token) 
    {
        this.spinnerService.show();

        const authHeader = new HttpHeaders();     
        authHeader.append('Content-Type', 'application/json');
        authHeader.append('Authorization', token);
        return new Promise((resolve, reject) => 
        {
        setTimeout(()=>{
        this.http.get(GlobalVariable.BASE_API_URL +'/api/EmployeeKRAApplication/GetKRAApplicationBYLoginId',{headers: authHeader})
        .subscribe(data => 
        {
            this.spinnerService.hide();
            resolve(data)
        },
        error => 
        {
            this.spinnerService.hide();
            reject(error);
        },);
        },3000);
        });
    }
    GetEmployeeDetailsBYEmployeeId(token,EmployeeId) 
    {
        this.spinnerService.show();

        const authHeader = new HttpHeaders();     
        authHeader.append('Content-Type', 'application/json');
        authHeader.append('Authorization', token);
        return new Promise((resolve, reject) => 
        {
        setTimeout(()=>{
        this.http.get(GlobalVariable.BASE_API_URL +'/api/EmployeeKRAApplication/GetEmployeeDetailsBYEmployeeId?EmployeeId='+EmployeeId,{headers: authHeader})
        .subscribe(data => 
        {
            this.spinnerService.hide();
            resolve(data)
        },
        error => 
        {
            this.spinnerService.hide();
            reject(error);
        },);
        },3000);
        });
    }
    GetKRAApplicationBYEmployeeId(token,EmployeeId,CompanyId) 
    {
        this.spinnerService.show();

        const authHeader = new HttpHeaders();     
        authHeader.append('Content-Type', 'application/json');
        authHeader.append('Authorization', token);
        return new Promise((resolve, reject) => 
        {
        setTimeout(()=>{
        this.http.get(GlobalVariable.BASE_API_URL +'/api/EmployeeKRAApplication/GetKRAApplicationBYEmployeeId?EmployeeId='+EmployeeId+'&CompanyId='+CompanyId,{headers: authHeader})
        .subscribe(data => 
        {
            this.spinnerService.hide();
            resolve(data)
        },
        error => 
        {
            this.spinnerService.hide();
            reject(error);
        },);
        },3000);
        });
    }
    AddKRAApplication( token ,KRAApplication :KRAApplicationModel ) 
    {
        const authHeader = new HttpHeaders();         
        authHeader.append('Content-Type', 'application/json');
        authHeader.append('Authorization', token);
        authHeader.append('Accept', 'application/json');
        return this.http.post(GlobalVariable.BASE_API_URL +'/api/EmployeeKRAApplication/Add', KRAApplication ,{headers: authHeader} )
        .map(this.extractData)
        .catch(this.handleErrorObservable);
    }
    UpdateKRAApplication( token ,KRAApplication :KRAApplicationModel ) 
    {
        const authHeader = new HttpHeaders();         
        authHeader.append('Content-Type', 'application/json');
        authHeader.append('Authorization', token);
        authHeader.append('Accept', 'application/json');
        return this.http.put(GlobalVariable.BASE_API_URL +'/api/EmployeeKRAApplication/Update', KRAApplication ,{headers: authHeader} )
        .map(this.extractData)
        .catch(this.handleErrorObservable);
    }
    DeActiveKRAApplicationlist( token ,MasterRefId ) 
    {
        const authHeader = new HttpHeaders();         
        authHeader.append('Content-Type', 'application/json');
        authHeader.append('Authorization', token);
        authHeader.append('Accept', 'application/json');
        return this.http.put(GlobalVariable.BASE_API_URL +'/api/EmployeeKRAApplication/DeActiveKRAApplicationlist', MasterRefId ,{headers: authHeader} )
        .map(this.extractData)
        .catch(this.handleErrorObservable);
    }
    AddKRAApplicationMaster( token ,KRAApplication :KRAApplicationModel ) 
    {
        const authHeader = new HttpHeaders();         
        authHeader.append('Content-Type', 'application/json');
        authHeader.append('Authorization', token);
        authHeader.append('Accept', 'application/json');
        return this.http.post(GlobalVariable.BASE_API_URL +'/api/EmployeeKRAApplication/AddMaster', KRAApplication ,{headers: authHeader} )
        .map(this.extractData)
        .catch(this.handleErrorObservable);
    }
    UpdateKRAApplicationMaster( token ,KRAApplication :KRAApplicationModel ) 
    {
        const authHeader = new HttpHeaders();         
        authHeader.append('Content-Type', 'application/json');
        authHeader.append('Authorization', token);
        authHeader.append('Accept', 'application/json');
        return this.http.put(GlobalVariable.BASE_API_URL +'/api/EmployeeKRAApplication/UpdateMaster', KRAApplication ,{headers: authHeader} )
        .map(this.extractData)
        .catch(this.handleErrorObservable);
    }
    GetEmployeeDetailsForDDL(token )
    {
        this.spinnerService.show();
        const authHeader = new HttpHeaders();     
        authHeader.append('Content-Type', 'application/json');
        authHeader.append('Authorization', token);
        return new Promise((resolve, reject) =>
        {
            setTimeout(()=>
            {
                this.http.get(GlobalVariable.BASE_API_URL +'/api/EmployeeKRAApplication/GetEmployeeDetailsForDDL',{headers: authHeader})
                .subscribe(data => 
                {
                    this.spinnerService.hide();
                    resolve(data)
                },
                error => 
                {
                    this.spinnerService.hide();
                    reject(error);
                },);
            },3000);
        });
    }
    GetEmployeeDetailsForDDLForHeads(token )
    {
        this.spinnerService.show();
        const authHeader = new HttpHeaders();     
        authHeader.append('Content-Type', 'application/json');
        authHeader.append('Authorization', token);
        return new Promise((resolve, reject) =>
        {
            setTimeout(()=>
            {
                this.http.get(GlobalVariable.BASE_API_URL +'/api/EmployeeKRAApplication/GetEmployeeDetailsForDDLForHeads',{headers: authHeader})
                .subscribe(data => 
                {
                    this.spinnerService.hide();
                    resolve(data)
                },
                error => 
                {
                    this.spinnerService.hide();
                    reject(error);
                },);
            },3000);
        });
    }
    //End KRA Application
    //Begin KRA RH Approval
    GetKRAEmployeeDetailsForRHApprovalFORRH(token) 
    {
        this.spinnerService.show();

        const authHeader = new HttpHeaders();     
        authHeader.append('Content-Type', 'application/json');
        authHeader.append('Authorization', token);
        return new Promise((resolve, reject) => 
        {
        setTimeout(()=>{
        this.http.get(GlobalVariable.BASE_API_URL +'/api/EmployeeKRAApplication/GetKRAEmployeeDetailsForRHApprovalFORRH',{headers: authHeader})
        .subscribe(data => 
        {
            this.spinnerService.hide();
            resolve(data)
        },
        error => 
        {
            this.spinnerService.hide();
            reject(error);
        },);
        },3000);
        });
    }
    GetKRAEmployeeDetailsForRHApproval(token) 
    {
        this.spinnerService.show();

        const authHeader = new HttpHeaders();     
        authHeader.append('Content-Type', 'application/json');
        authHeader.append('Authorization', token);
        return new Promise((resolve, reject) => 
        {
        setTimeout(()=>{
        this.http.get(GlobalVariable.BASE_API_URL +'/api/EmployeeKRAApplication/GetKRAApplicationForRHApproval',{headers: authHeader})
        .subscribe(data => 
        {
            this.spinnerService.hide();
            resolve(data)
        },
        error => 
        {
            this.spinnerService.hide();
            reject(error);
        },);
        },3000);
        });
    }
    GetKRAApplicationForRHApprovalByMasterRefId(token,MasterRefId) 
    {
        this.spinnerService.show();

        const authHeader = new HttpHeaders();     
        authHeader.append('Content-Type', 'application/json');
        authHeader.append('Authorization', token);
        return new Promise((resolve, reject) => 
        {
        setTimeout(()=>{
        this.http.get(GlobalVariable.BASE_API_URL +'/api/EmployeeKRAApplication/GetKRAApplicationForRHApprovalByMasterRefId?MasterRefId='+MasterRefId,{headers: authHeader})
        .subscribe(data => 
        {
            this.spinnerService.hide();
            resolve(data)
        },
        error => 
        {
            this.spinnerService.hide();
            reject(error);
        },);
        },3000);
        });
    }
    RHApprovedKRAApplicationMaster( token ,KRAApplication :KRAApplicationModel ) 
    {
        const authHeader = new HttpHeaders();         
        authHeader.append('Content-Type', 'application/json');
        authHeader.append('Authorization', token);
        authHeader.append('Accept', 'application/json');
        return this.http.put(GlobalVariable.BASE_API_URL +'/api/EmployeeKRAApplication/RHApprovedKRAApplicationMaster', KRAApplication ,{headers: authHeader} )
        .map(this.extractData)
        .catch(this.handleErrorObservable);
    }
    RHApprovedKRAApplication( token ,KRAApplication :KRAApplicationModel ) 
    {
        const authHeader = new HttpHeaders();         
        authHeader.append('Content-Type', 'application/json');
        authHeader.append('Authorization', token);
        authHeader.append('Accept', 'application/json');
        return this.http.put(GlobalVariable.BASE_API_URL +'/api/EmployeeKRAApplication/RHApprovedKRAApplication', KRAApplication ,{headers: authHeader} )
        .map(this.extractData)
        .catch(this.handleErrorObservable);
    }
    //End KRA RH Approval
    //Begin KRA FH Approval
    GetKRAApplicationForFHApprovalFORFH(token) 
    {
        this.spinnerService.show();

        const authHeader = new HttpHeaders();     
        authHeader.append('Content-Type', 'application/json');
        authHeader.append('Authorization', token);
        return new Promise((resolve, reject) => 
        {
        setTimeout(()=>{
        this.http.get(GlobalVariable.BASE_API_URL +'/api/EmployeeKRAApplication/GetKRAApplicationForFHApprovalFORFH',{headers: authHeader})
        .subscribe(data => 
        {
            this.spinnerService.hide();
            resolve(data)
        },
        error => 
        {
            this.spinnerService.hide();
            reject(error);
        },);
        },3000);
        });
    }
    GetKRAEmployeeDetailsForFHApproval(token) 
    {
        this.spinnerService.show();

        const authHeader = new HttpHeaders();     
        authHeader.append('Content-Type', 'application/json');
        authHeader.append('Authorization', token);
        return new Promise((resolve, reject) => 
        {
        setTimeout(()=>{
        this.http.get(GlobalVariable.BASE_API_URL +'/api/EmployeeKRAApplication/GetKRAApplicationForFHApproval',{headers: authHeader})
        .subscribe(data => 
        {
            this.spinnerService.hide();
            resolve(data)
        },
        error => 
        {
            this.spinnerService.hide();
            reject(error);
        },);
        },3000);
        });
    }
    GetKRAApplicationForFHApprovalByMasterRefId(token,MasterRefId) 
    {
        this.spinnerService.show();

        const authHeader = new HttpHeaders();     
        authHeader.append('Content-Type', 'application/json');
        authHeader.append('Authorization', token);
        return new Promise((resolve, reject) => 
        {
        setTimeout(()=>{
        this.http.get(GlobalVariable.BASE_API_URL +'/api/EmployeeKRAApplication/GetKRAApplicationForFHApprovalByMasterRefId?MasterRefId='+MasterRefId,{headers: authHeader})
        .subscribe(data => 
        {
            this.spinnerService.hide();
            resolve(data)
        },
        error => 
        {
            this.spinnerService.hide();
            reject(error);
        },);
        },3000);
        });
    }
    FHApprovedKRAApplicationMaster( token ,KRAApplication :KRAApplicationModel ) 
    {
        const authHeader = new HttpHeaders();         
        authHeader.append('Content-Type', 'application/json');
        authHeader.append('Authorization', token);
        authHeader.append('Accept', 'application/json');
        return this.http.put(GlobalVariable.BASE_API_URL +'/api/EmployeeKRAApplication/FHApprovedKRAApplicationMaster', KRAApplication ,{headers: authHeader} )
        .map(this.extractData)
        .catch(this.handleErrorObservable);
    }
    FHApprovedKRAApplication( token ,KRAApplication :KRAApplicationModel ) 
    {
        const authHeader = new HttpHeaders();         
        authHeader.append('Content-Type', 'application/json');
        authHeader.append('Authorization', token);
        authHeader.append('Accept', 'application/json');
        return this.http.put(GlobalVariable.BASE_API_URL +'/api/EmployeeKRAApplication/FHApprovedKRAApplication', KRAApplication ,{headers: authHeader} )
        .map(this.extractData)
        .catch(this.handleErrorObservable);
    }
    //Reports
    GetKRAReportsSessionDisplayByEmployeeId(token,EmployeeId) 
    {
        this.spinnerService.show();

        const authHeader = new HttpHeaders();     
        authHeader.append('Content-Type', 'application/json');
        authHeader.append('Authorization', token);
        return new Promise((resolve, reject) => 
        {
        setTimeout(()=>{
        this.http.get(GlobalVariable.BASE_API_URL +'/api/EmployeeKRAApplication/GetKRAReportsSessionDisplayByEmployeeId?EmployeeId='+EmployeeId,{headers: authHeader})
        .subscribe(data => 
        {
            this.spinnerService.hide();
            resolve(data)
        },
        error => 
        {
            this.spinnerService.hide();
            reject(error);
        },);
        },3000);
        });
    }
    GetKRAReportsSessionDisplayByUserId(token) 
    {
        this.spinnerService.show();

        const authHeader = new HttpHeaders();     
        authHeader.append('Content-Type', 'application/json');
        authHeader.append('Authorization', token);
        return new Promise((resolve, reject) => 
        {
        setTimeout(()=>{
        this.http.get(GlobalVariable.BASE_API_URL +'/api/EmployeeKRAApplication/GetKRAReportsSessionDisplayByUserId',{headers: authHeader})
        .subscribe(data => 
        {
            this.spinnerService.hide();
            resolve(data)
        },
        error => 
        {
            this.spinnerService.hide();
            reject(error);
        },);
        },3000);
        });
    }

    GetKRAReportsByEmployeeId(token,EmployeeId,MasterRefId) 
    {
        this.spinnerService.show();

        const authHeader = new HttpHeaders();     
        authHeader.append('Content-Type', 'application/json');
        authHeader.append('Authorization', token);
        return new Promise((resolve, reject) => 
        {
        setTimeout(()=>{
        this.http.get(GlobalVariable.BASE_API_URL +'/api/EmployeeKRAApplication/GetKRAReportsByEmployeeId?EmployeeId='+EmployeeId+'&MasterRefId='+MasterRefId,{headers: authHeader})
        .subscribe(data => 
        {
            this.spinnerService.hide();
            resolve(data)
        },
        error => 
        {
            this.spinnerService.hide();
            reject(error);
        },);
        },3000);
        });
    }
    GetKRAReportsByUserId(token,MasterRefId) 
    {
        this.spinnerService.show();

        const authHeader = new HttpHeaders();     
        authHeader.append('Content-Type', 'application/json');
        authHeader.append('Authorization', token);
        return new Promise((resolve, reject) => 
        {
        setTimeout(()=>{
        this.http.get(GlobalVariable.BASE_API_URL +'/api/EmployeeKRAApplication/GetKRAReportsByUserId?MasterRefId='+MasterRefId,{headers: authHeader})
        .subscribe(data => 
        {
            this.spinnerService.hide();
            resolve(data)
        },
        error => 
        {
            this.spinnerService.hide();
            reject(error);
        },);
        },3000);
        });
    }
    //End KRA RH Approval
    
    private extractData(res: Response) 
    {
        let body = res;
        return body || {};
    }
    private handleErrorObservable (error: Response | any) 
    {
        console.error(error.message || error);
        return Observable.throw(error.message || error);
    }
}