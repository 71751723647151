import { Component, OnInit,Renderer  } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from '../../shared/user.service';
import { User } from '../../shared/user.model';
import { Menu }  from '../../shared/menu.model';
import { CommonMenuHandelling } from '../../shared/Menu/CommonMenuHandelling';
import { NotificationModel } from '../../shared/NotificationModel.Model';

@Component({
  selector: 'app-assets-master',
  templateUrl: './assets-master.component.html',
  styleUrls: ['./assets-master.component.css']
})
export class AssetsMasterComponent implements OnInit 
{
  userClaims: any;
  SubMenues: Menu[] = [];
  MainMenu: Menu[] = [];
  Mod: any;
  check: number;
  selected :any;
  selectedIndex: number;
  selecteModule : number;

  tempmenu : Menu;
  SubMenuestemp: Menu[] = [];

  UserModel : User= new User();
  Users: User[] = [];
  User: any;
  SelectedMenu:Menu;
  Notifications : NotificationModel[]=[];
  Noti : any;
  SubmenuCount : number=0;

  constructor(private router: Router, private userService: UserService,private render: Renderer, private commonmenu:CommonMenuHandelling) 
  {
    if(userService.selectdmoduleid !=undefined)
    {
      localStorage.setItem("moduleidstor", userService.selectdmoduleid.toString());
      localStorage.setItem("menuidstor", userService.selectedmenuid.toString());
    }
    let mindex=localStorage.getItem("selectedmodule")
    let Submindex=localStorage.getItem("selectedSubMenu")
    this.selectedIndex=Number(Submindex);
    this.selecteModule=Number(mindex);
  }
   
  isActive(item) 
  {
    if(item === this.selected)
    {
      this.selected = null;
    }
    else
    {
      this.selected = item;
    }
  };

  ngOnInit() 
  {
    this.GetLoginDetails();
    this.getMenues();
    this.getSubmenues();
    this.GetNotificationALL();
    this.UserModel.TotalAllApplication=0;
  }

  getMenues()
  {
    this.MainMenu=[];
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    let moduleid=localStorage.getItem("moduleidstor")
    this.userService.MainMenuList( t, moduleid).then(x => 
    {
      this.Mod = x;
      Object.keys(this.Mod).forEach( key =>
      {
        // tslint:disable-next-line:no-unused-expression
        this.Mod[key];
        let menutemp = new Menu();
        menutemp = this.Mod[key];
        this.MainMenu.push(menutemp);
      });
    });
  }

  public listClick(event: any)
  {
    event.preventDefault();
    this.render.setElementClass(event.target, "current", true);
    // How to remove 'active' from siblings ?
  }
   
  getSubmenues()
  {
    this.SubMenues=[];
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    let moduleid=localStorage.getItem("moduleidstor");
    let menuid = localStorage.getItem("menuidstor");
    this.userService.GetSubMenuList( t, menuid).then(x => 
    {
      this.Mod = x;
      Object.keys(this.Mod).forEach( key => 
      {
        // tslint:disable-next-line:no-unused-expression
        this.Mod[key];
        let menutemp = new Menu();
        menutemp = this.Mod[key];
        this.SubMenues.push(menutemp);
      });
      this.userService.SelectedMenu= this.SubMenues[0];
    });
  }
   
  LoadComponents(routname, i, menuid)
  {
    this.selectedIndex = i;
    this.router.navigate([routname]);
    this.userService.SelectedMenu= this.SubMenues.find(x=>x.MenuId==parseInt(menuid));  
  }

  MainPages(menuid, moduleid,RouteName,j) 
  {
    this.userService.selectdmoduleid = moduleid;
    this.userService.selectedmenuid= menuid;
    localStorage.setItem("selectedmodule", j);
    this.router.navigate([RouteName]);        
  }

  GetLoginDetails() 
  {
    this.UserModel=this.commonmenu.GetLoginDetails();
  }
  
  Logout()
  { 
    this.commonmenu.LogOut();
  }

  OnDashboard()
  {
    this.router.navigate(['/DashBoard']);
  }

  PageRedirectClick(moduleId,MainMenu,MenuId)
  {
    this.userService.selectdmoduleid = moduleId;
    this.userService.selectedmenuid = MainMenu;
    let t = localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.userService.GetSubMenuListForPageChange(t,moduleId,MainMenu,MenuId).then(x => 
    {
      this.Mod = x;
      this.SubMenues = [];
      Object.keys(this.Mod).forEach(key =>
      {
        this.Mod[key];
        let menutemp = new Menu();
        menutemp = this.Mod[key];
        localStorage.setItem("selectedmodule", menutemp.SelectedMenu.toString());
        if(menutemp.MenuId==MenuId)
        {
          localStorage.setItem("selectedSubMenu", menutemp.SelectedSubMenu.toString());
          this.SubmenuCount=menutemp.SelectedSubMenu;
        }
        this.userService.SelectedMenuIndex = menutemp.SelectedMenu;
        this.SubMenues.push(menutemp);
      });
      this.tempmenu = this.SubMenues[this.SubmenuCount];
      this.userService.SelectedMenu = this.tempmenu;
      this.userService.IsFormMainMenu = true;
      this.userService.MainMenuUrl = this.tempmenu.RouteName;
      this.router.navigate([this.tempmenu.RouteName]);
    });
  }

  GetNotificationALL()
  {  
    this.Notifications=[]
    let t =  localStorage.getItem('userToken');
    t = 'Bearer ' + t;
    this.userService.GetNotificationALL(t).then(x => 
    {
      this.Noti = x;
      Object.keys(this.Noti).forEach( key => 
      {
        this.Noti[key];
        let Notitemp = new NotificationModel();
        Notitemp = this.Noti[key];
        this.UserModel.TotalAllApplication=Notitemp.TotalAllApplication;
        if(Notitemp.TotalApplication > 0 && Notitemp.ApplicationType != 'Total Application')
        {
          this.Notifications.push(Notitemp);
        }
      });
    })
  }
}