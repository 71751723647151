<perfect-scrollbar style="max-width: 1260px; max-height: 584px;" class="mukesh">
  <div class="body-content-area tabcontent" id="link1">
    <div class="row">
      <div class="col-lg-9">
        <div class="row">
          <div class="col-lg-1 low-padding text-center">
            <!-- <img *ngIf="this.SelectedMenu.Image!=null" src={{this.SelectedMenu.Image}} class="body-icon" alt="Icon"/> -->
          </div>
          <div class="col-lg-10 clear-padding">
            <div>
              <span class="page-title">Employee CompOff Application Status</span>
              <small class="page-description"></small>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div *ngIf="!(this.CanViewState)">
      <fieldset class="body-form-control" > 
        <legend class="legend--">Employee Details</legend>
        <form #SearchForm="ngForm">
          <div class="row">
            <div class="col-lg-4">
              <div class="col-body-border-right">
                <div class="form-group row">
                  <label class="col-sm-2 col-form-label clear-padding-right">Employee</label>
                  <div class="col-sm-9 clear-left-padding">
                    <angular2-multiselect class="form-control" [data]="emp" [(ngModel)]="selectedItems" name="EmpId" [settings]="EmployeeSettings"
                      (onSelect)="onItemSelect($event)" (onDeSelect)="OnItemDeSelect($event)" (onSelectAll)="onSelectAll($event)" (onDeSelectAll)="onDeSelectAll($event)">
                    </angular2-multiselect>
                  </div>
                  <div class="required-validation" *ngIf="ModelListApplication.EmployeeId == 0">Employee is required</div>
                </div>
                <div class="form-group row">
                  <label class="col-sm-4 col-form-label clear-padding-right">Status</label>
                  <div class="col-sm-7 clear-left-padding">
                    <select [(ngModel)]="this.ModelListApplication.ApplicationStatus" id ="ApplicationStatus" (ngModelChange)="ApplicationStatusChanged()" class="form-control" name="ApplicationStatus" required>
                      <option [value]="0" [disabled]=true >Select Status</option>
                      <option [value]="1">Pending</option>
                      <option [value]="2">Approved</option>
                      <option [value]="3">Rejected</option>
                    </select>
                  </div>
                  <div class="required-validation" *ngIf="this.ModelListApplication.ApplicationStatus == 0">Status is required</div>
                </div>
              </div>
            </div>
            <div class="col-lg-4">
              <div class="col-body-border-right">
                <div class="form-group row">
                  <label class="col-sm-4 col-form-label clear-padding-right">From Date</label>
                  <div class="col-sm-7 clear-left-padding">
                    <input type="date" name="SearchFromDate" [ngModel]="ModelListApplication.SearchFromDate | date:'yyyy-MM-dd'" (ngModelChange)="SearchFromDateChanged($event)" #SearchFromDate="ngModel" required/>
                  </div>
                </div>
              </div>
              <div class="form-group row">
                <div class="col-sm-7 clear-left-padding">
                  <button type="submit" (click)="submitClick()" class="btn btn-primary save-button" [disabled]="!(SearchForm.valid && this.ModelListApplication.ApplicationStatus > 0 && this.DisableSave == true && this.validdate == false && this.validTodate == false)">Generate</button>   
                </div>
              </div>
            </div>
            <div class="col-lg-4">
              <div class="form-group row">
                <label class="col-sm-4 col-form-label clear-padding-right">To Date</label>
                <div class="col-sm-7 clear-left-padding">
                  <input type="date" name="SearchToDate" [ngModel]="ModelListApplication.SearchToDate | date:'yyyy-MM-dd'" (ngModelChange)="SearchToDateChanged($event)" #SearchToDate="ngModel" required/>
                </div>
              </div>
            </div>
          </div>
        </form>  
      </fieldset>

      <div *ngIf="PendingMsg" style="color: #dc3545; text-align: center;font-size: 15px; font-weight: bold;">No Pending Application is Found.</div>
      <div *ngIf="ApprovedMsg" style="color: #dc3545; text-align: center;font-size: 15px; font-weight: bold;">No Approved Application is Found.</div>
      <div *ngIf="RejectedMsg" style="color: #dc3545; text-align: center;font-size: 15px; font-weight: bold;">No Rejected Application is Found.</div>
      <div *ngIf="validdate" style="color: #dc3545; text-align: center;font-size: 15px; font-weight: bold;">Date From is not Valid Date.</div>
      <div *ngIf="validTodate" style="color: #dc3545; text-align: center;font-size: 15px; font-weight: bold;">Date To is not Valid Date.</div>

      <fieldset class="body-form-control" *ngIf="CompoffPending.length > 0"> 
        <legend class="legend--">Employee CompOff Pending</legend>
          <form #EmployeeAttendanceCompoffPending="ngForm">
            <div lass="row" >
              <table id="tblCompOffPending" class="table table-striped table-bordered text-center table-new row-border hover">
                <thead class="thead-blue text-center">
                  <tr>
                    <th>Sl.No.</th>
                    <th>Attendance Date</th>
                    <th>Attendance Day</th>
                    <th>In-Time</th>
                    <th>Out-Time</th>
                    <th>Duration</th>
                    <th>Status</th>
                    <th>CompOff Duration</th>
                    <th>Reason</th>
                    <th>Reporting Head</th>
                    <th>Actgion</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let CompoffPending of CompoffPending, let i = index">
                    <td scope="row">{{i+1}}</td>
                    <td>{{CompoffPending.AttendanceDate | date : "dd-MMM-yyyy"}}</td>
                    <td>{{CompoffPending.AttendanceDay}}</td>
                    <td>{{CompoffPending.ActualInTime}}</td>
                    <td>{{CompoffPending.ActualOutTime}}</td>
                    <td>{{CompoffPending.ActualWorkingDuration}}</td>
                    <td>{{CompoffPending.Status}}</td>
                    <td *ngIf="CompoffPending.HalfDay==true">HalfDay</td>
                    <td *ngIf="CompoffPending.FullDay==true">FullDay</td>
                    <td style=" text-align: left;">{{CompoffPending.Reason}}</td>
                    <td style=" text-align: left;">{{CompoffPending.RHName}}</td>
                    <td>
                      <button type="button" (click)="viewAppliedEmployeeCompoff(CompoffPending.RefId,template)" class="table-remove-btn" type="button"><i class="fas fa-trash-alt"></i></button>
                    </td>
                  </tr>
                </tbody>
                <tfoot class="thead-blue text-center">
                  <tr>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                  </tr>
                </tfoot>
              </table>
            </div>
          </form>
      </fieldset>

      <fieldset class="body-form-control" *ngIf="CompoffApproved.length > 0"> 
        <legend class="legend--">Employee CompOff Approved</legend>
        <table id="tblCompOffPending" class="table table-striped table-bordered text-center table-new row-border hover">
          <thead class="thead-blue text-center">
            <tr>
              <th>Sl.No.</th>
              <th>Attendance Date</th>
              <th>Attendance Day</th>
              <th>In-Time</th>
              <th>Out-Time</th>
              <th>Duration</th>
              <th>Status</th>
              <th>CompOff Duration</th>
              <th>Reason</th>
              <th>Remarks</th>
              <th>Approver</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let CompoffApproved of CompoffApproved, let i = index">
              <td scope="row">{{i+1}}</td>
              <td>{{CompoffApproved.AttendanceDate | date : "dd-MMM-yyyy"}}</td>
              <td>{{CompoffApproved.AttendanceDay}}</td>
              <td>{{CompoffApproved.ActualInTime}}</td>
              <td>{{CompoffApproved.ActualOutTime}}</td>
              <td>{{CompoffApproved.ActualWorkingDuration}}</td>
              <td>{{CompoffApproved.Status}}</td>
              <td *ngIf="CompoffApproved.HalfDay==true">HalfDay</td>
              <td *ngIf="CompoffApproved.FullDay==true">FullDay</td>
              <td style=" text-align: left;">{{CompoffApproved.Reason}}</td>
              <td style=" text-align: left;">{{CompoffApproved.Remarks}}</td>
              <td style=" text-align: left;">{{CompoffApproved.RHName}}</td>
            </tr>
          </tbody>
          <tfoot class="thead-blue text-center">
            <tr>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
            </tr>
          </tfoot>
        </table>
      </fieldset>

      <fieldset class="body-form-control" *ngIf="CompoffRejected.length > 0"> 
        <legend class="legend--">Employee CompOff Rejected</legend>
          <form #EmployeeAttendanceCompoffPending="ngForm">
            <div lass="row" >
              <table id="tblCompOffRejected" class="table table-striped table-bordered text-center table-new row-border hover">
                <thead class="thead-blue text-center">
                  <tr>
                    <th>Sl.No.</th>
                    <th>Attendance Date</th>
                    <th>Attendance Day</th>
                    <th>In-Time</th>
                    <th>Out-Time</th>
                    <th>Duration</th>
                    <th>Status</th>
                    <th>CompOff Duration</th>
                    <th>Reason</th>
                    <th>Remarks</th>
                    <th>Rejecter</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let CompoffRejected of CompoffRejected, let i = index">
                    <td scope="row">{{i+1}}</td>
                    <td>{{CompoffRejected.AttendanceDate | date : "dd-MMM-yyyy"}}</td>
                    <td>{{CompoffRejected.AttendanceDay}}</td>
                    <td>{{CompoffRejected.ActualInTime}}</td>
                    <td>{{CompoffRejected.ActualOutTime}}</td>
                    <td>{{CompoffRejected.ActualWorkingDuration}}</td>
                    <td>{{CompoffRejected.Status}}</td>
                    <td *ngIf="CompoffRejected.HalfDay==true">HalfDay</td>
                    <td *ngIf="CompoffRejected.FullDay==true">FullDay</td>
                    <td style=" text-align: left;">{{CompoffRejected.Reason}}</td>
                    <td style=" text-align: left;">{{CompoffRejected.Remarks}}</td>
                    <td style=" text-align: left;">{{CompoffRejected.RHName}}</td>
                  </tr>
                </tbody>
                <tfoot class="thead-blue text-center">
                  <tr>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                  </tr>
                </tfoot>
              </table>
            </div>
          </form>
      </fieldset>

      <div style=" box-align: center; width: 1500px;" >
        <ng-template #template>
          <div class="modal-header" style="background: #2a9bd7; max-width: 1500px;"> 
            <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()" style="color:#fff;">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div  style="text-align: center; max-width: 1500px;">
            <div *ngIf="CancelMsg" style="color: #dc3545; text-align: center;font-size: 15px; font-weight: bold;">Application Cancel Successfully</div>
            <div style=" width: 100%;" *ngIf="ShowPopup">

              <fieldset class="body-form-control" style=" text-align: left;"> 
                <legend class="legend--">CompOff Approval</legend>
                <form #EmployeeCompOffForm="ngForm">
                  <div class="row"> 
                    <div class="col-lg-4">
                      <div class="col-body-border-right">
                        <div class="form-group row">
                          <label  class="col-sm-4 col-form-label clear-padding-right">Code</label>
                          <div class="col-sm-7 clear-left-padding">
                            :&nbsp;&nbsp;{{this.ModelListApplication.EmployeeCode}}
                          </div>
                        </div>
                        <div class="form-group row">
                          <label  class="col-sm-4 col-form-label clear-padding-right">Date</label>
                          <div class="col-sm-7 clear-left-padding">
                            :&nbsp;&nbsp;{{ModelListApplication.AttendanceDate  | date : "dd-MMM-yyyy"}}
                          </div>
                        </div>
                        <div class="form-group row">
                          <label class="col-sm-4 col-form-label clear-padding-right">In-Time</label>
                          <div class="col-sm-7 clear-left-padding">
                            :&nbsp;&nbsp;{{ModelListApplication.ActualInTime}}
                          </div>
                        </div>  
                        <div class="form-group row">
                          <label class="col-sm-4 col-form-label clear-padding-right">CompOff</label>
                          <div *ngIf="this.ModelListApplication.HalfDay==true" class="col-sm-4 col-form-label clear-padding-right">
                              :&nbsp;&nbsp;HalfDay
                          </div>
                          <div *ngIf="this.ModelListApplication.FullDay==true" class="col-sm-4 col-form-label clear-padding-right">
                              :&nbsp;&nbsp;FullDay
                          </div>
                        </div>      
                      </div>  
                    </div>
              
                    <div class="col-lg-4">
                      <div class="col-body-border-right">
                        <div class="form-group row">
                          <label class="col-sm-4 col-form-label clear-padding-right">Name</label>
                          <div class="col-sm-7 clear-left-padding">
                            :&nbsp;&nbsp;{{this.ModelListApplication.EmployeeName}}
                          </div>
                        </div>
                        <div class="form-group row">
                          <label  class="col-sm-4 col-form-label clear-padding-right">Status</label>
                          <div class="col-sm-4 col-form-label clear-padding-right">
                            :&nbsp;&nbsp;{{this.ModelListApplication.Status}}
                          </div>
                        </div>
                        <div class="form-group row">
                          <label class="col-sm-4 col-form-label clear-padding-right">Out-Time</label>
                          <div class="col-sm-7 clear-left-padding">
                            :&nbsp;&nbsp;{{this.ModelListApplication.ActualOutTime}}
                          </div>
                        </div>
                        <div class="form-group row">
                          <label class="col-sm-4 col-form-label clear-padding-right">Reason</label>
                          <div class="col-sm-4 col-form-label clear-padding-right">
                            :&nbsp;&nbsp;{{this.ModelListApplication.Reason}}
                          </div>
                        </div>
                      </div> 
                    </div>
              
                    <div class="col-lg-4">
                      <div class="form-group row">
                        <label  class="col-sm-4 col-form-label clear-padding-right">Approver</label>
                        <div class="col-sm-7 clear-left-padding">
                          :&nbsp;&nbsp;{{this.ModelListApplication.RHName}} 
                        </div>
                      </div>
                      <div class="form-group row">
                        <label class="col-sm-4 col-form-label clear-padding-right">RH EMailId</label>
                        <div class="col-sm-7 clear-left-padding">
                          :&nbsp;&nbsp;{{this.ModelListApplication.RHEmailId}} 
                        </div>
                      </div>
                      <div class="form-group row">
                        <label class="col-sm-4 col-form-label clear-padding-right">Duration</label>
                        <div class="col-sm-7 clear-left-padding">
                          :&nbsp;&nbsp;{{this.ModelListApplication.ActualWorkingDuration}} 
                        </div>
                      </div>
                      <div class="form-group row">
                        <label class="col-sm-4 col-form-label clear-padding-right">Remarks</label>
                        <div class="col-sm-7 clear-left-padding">
                          <input type="text" [(ngModel)]="this.ModelListApplication.Remarks" tabindex="1" maxlength="500" id ="Remarks" name="Remarks" #Remarks="ngModel" required class="form-control form-control-custom form-control-custom-new" >
                        </div>
                        <div *ngIf="Remarks.invalid && (Remarks.dirty || Remarks.touched)" class="alert alert-danger">
                          <div *ngIf="Remarks.errors.required">Remarks is required.</div>
                          <div *ngIf="Remarks.errors.maxlength">Remarks is not more than 500 characters</div>
                        </div> 
                      </div>
                      <div class="form-group row">
                        <label class="col-sm-6 col-form-label text-center clear-padding-right">
                          <button type="submit" tabindex="2" (click)="EmployeeCompoffCancel(template)" class="btn btn-primary save-button" [disabled]="!(EmployeeCompOffForm.valid && this.DisableSave==true)">Cancel Application</button>  
                        </label>
                      </div>
                    </div> 
                  </div> 
                </form>
              </fieldset>
            </div>

            <button type="button" aria-label="Close" (click)="modalRef.hide()" style="background: #2a9bd7; outline: 0px none; border: 0px none; color: #fff; padding: 5px 10px; border-radius: 3px;">
              <span>Close</span>
            </button>             
          </div>
        </ng-template>
      </div>
    </div>
  </div>
</perfect-scrollbar>