import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ReportType } from '../../../shared/HR_ADMIN/Report/ReportType'
import { UserService } from '../../../shared/user.service';
import { EmployeePortalService } from '../../../shared/HR_ADMIN/EmployeePortal/EmployeePortalService.service';
import { EmployeeDetail } from '../../../shared/HR_ADMIN/Employee/EmployeeDetail.Model';
import { EmployeeAttendanceModel } from '../../../shared/HR_ADMIN/EmployeePortal/EmployeeAttendanceModel.Model';
import { OrganizationService } from '../../../shared/Organization/organization.service';
import { Company } from '../../../shared/Organization/Company.Model';
import { Department } from '../../../shared/Organization/Department.Model';
import { Branch } from '../../../shared/Organization/Branch.Model';
import { FinancialYear } from '../../../shared/Organization/FinancialYear.Model';
import { Menu } from '../../../shared/menu.model'

@Component({
  selector: 'app-salary-sheet',
  templateUrl: './salary-sheet.component.html',
  styleUrls: ['./salary-sheet.component.css']
})
export class SalarySheetComponent implements OnInit {
  EmployeeAttendanceModel: EmployeeAttendanceModel = new EmployeeAttendanceModel();
  EmployeeDetailModel: EmployeeDetail = new EmployeeDetail();
  userClaims: any;
  Employees: EmployeeDetail[] = [];
  employee: any;
  //Reports Start
  ReportTypeList: ReportType[] = [];
  ReportExecl: ReportType;
  ReportPdf: ReportType;
  //Reports End
  empcode: string;
  EmployeeAttendance: EmployeeAttendanceModel[] = [];
  Attendance: any;
  SubMenues: Menu[] = [];
  Mod: any;
  Result: string;
  SuccessStatus: boolean = false;
  SuccessMessage: string = "";
  public tableWidget: any;
  EmployeeId: any;
  selectedIndex: number;
  // Begning Permission Portion    
  SelectedMenu: Menu;
  IsDataIsInViewState: boolean = false;
  CanViewState: boolean = true;
  DisableSave: boolean = false;
  // End of the permission portion
  company: any;
  Companies: Company[] = [];
  Branch: any;
  Branches: Branch[] = [];
  Departments: Department[] = [];
  Department: any;
  DepartmentModel: Department = new Department();
  // begin for Validation
  validdate: boolean = false;
  // end of validation
  ReportData: any;
  //for Employee Dropdown list
  //EmployeeList = [];
  EmployeeselectedItems = [];
  dropdownSettings = {};
  EmployeeSettings = {};
  EmployeeMasters: any;
  //for Employee Dropdown list
  FinancialYears: FinancialYear[] = [];
  FinYear: any;
  Months: Company[] = [];
  Month: any;

  constructor(private rout: Router, private userService: UserService, private EmployeePortalService: EmployeePortalService, private organizationService: OrganizationService) { }

  ngOnInit() {
    // Begning Permission Portion 
    this.SubMenues = [];
    let t = localStorage.getItem('userToken');
    t = 'Bearer' + t;
    let menuid = localStorage.getItem("menuidstor");

    this.userService.GetSubMenuList(t, menuid).then(x => {
      this.Mod = x;
      Object.keys(this.Mod).forEach(key => {
        // tslint:disable-next-line:no-unused-expression
        this.Mod[key];
        let menutemp = new Menu();
        menutemp = this.Mod[key];
        this.SubMenues.push(menutemp);
      });
      this.userService.SelectedMenu = this.SubMenues[3];
      this.rout.navigate([this.userService.SelectedMenu.RouteName]);
      this.SelectedMenu = this.userService.SelectedMenu;
      if (this.SelectedMenu.CanAdd) {
        this.IsDataIsInViewState = false;
      }
      else {
        this.IsDataIsInViewState = true;
      }
      if (this.SelectedMenu.CanView) {
        this.CanViewState = false;
      }
      else {
        this.CanViewState = true;
      }
    });
    // End of the permission portion
    this.DisableSave = true;
    this.getCompanies();
    //this.GetEmployeeDetailByLocation(0, 0, 0);
    this.getreportTypes();
    this.getMonths();
    this.GetYear();
    this.EmployeeAttendanceModel.Year = 0;
    this.EmployeeAttendanceModel.MonthId = 0;
    this.EmployeeAttendanceModel.EmployeeId = 0;
    this.EmployeeAttendanceModel.CompId = 0;
    this.EmployeeAttendanceModel.BranchId = 0;
    this.EmployeeAttendanceModel.DepartmentId = 0;
    this.EmployeeselectedItems = [];
    //this.EmployeeList = [];
    this.EmployeeSettings =
      {
        text: "Select Employee",
        selectAllText: 'Select All',
        unSelectAllText: 'UnSelect All',
        classes: "myclass custom-class",
        enableSearchFilter: true,
      };
  }

  getMonths() {
    let t = localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.Months = [];
    this.organizationService.GetMonthName(t).then(x => {
      this.Month = x;
      Object.keys(this.Month).forEach(key => {
        this.Month[key];
        let Monthtemp = new Company();
        Monthtemp = this.Month[key];
        this.Months.push(Monthtemp);
      });
    });
  }

  GetYear() {
    let t = localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.FinancialYears = [];
    this.organizationService.GetYear(t).then(x => {
      this.FinYear = x;
      Object.keys(this.FinYear).forEach(key => {
        // tslint:disable-next-line:no-unused-expression
        this.FinYear[key];
        let FinYeartemp = new FinancialYear();
        FinYeartemp = this.FinYear[key];
        this.FinancialYears.push(FinYeartemp);
      });
    });
  }

  // GetEmployeeDetailByLocation(CompanyId, BranchId, DepartmentId) {
  //   let t = localStorage.getItem('userToken');
  //   t = 'Bearer' + t
  //   this.EmployeeselectedItems = [];
  //   this.EmployeeList = [];
  //   this.EmployeeMasterService.EmployeeDetailsByLocation(t, CompanyId, BranchId, DepartmentId).then(x => {
  //     this.EmployeeMasters = x;
  //     Object.keys(this.EmployeeMasters).forEach(key => {
  //       // tslint:disable-next-line:no-unused-expression
  //       this.EmployeeMasters[key];
  //       let Employeetemp = new EmployeeDetail();
  //       Employeetemp = this.EmployeeMasters[key];
  //       this.EmployeeList.push({ "id": Employeetemp.EmployeeId, "itemName": Employeetemp.EmployeeCode + '-' + Employeetemp.EmployeeName });
  //     });
  //   });
  // }
  getCompanies() {
    this.Companies = [];
    let t = localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.organizationService.GetCompany(t).then(x => {
      this.company = x;
      Object.keys(this.company).forEach(key => {
        // tslint:disable-next-line:no-unused-expression
        this.company[key];
        let Companytemp = new Company();
        Companytemp = this.company[key];
        this.Companies.push(Companytemp);
      });
    });
  }


  onChangeCompany(Companyid) {
    if (Companyid > 0) {
      this.getBranches(Companyid);
      this.EmployeeAttendanceModel.BranchId = 0;
      this.EmployeeAttendanceModel.DepartmentId = 0;
      this.getDepartments(0);

    }
    else {
      this.getBranches(0);
      this.getDepartments(0);
      this.EmployeeAttendanceModel.BranchId = 0;
      this.EmployeeAttendanceModel.DepartmentId = 0;

    }
  }

  onchangeBranch(Branchid) {
    if (Branchid > 0) {
      this.getDepartments(Branchid);
      this.EmployeeAttendanceModel.DepartmentId = 0;

    }
    else {
      this.getDepartments(0);
      this.EmployeeAttendanceModel.DepartmentId = 0;

    }
  }

  getBranches(companyid) {
    this.Branches = [];
    let t = localStorage.getItem('userToken');
    t = 'Bearer' + t
    if (this.Branches.length > 0) {
      this.Branches.splice(0, this.Branches.length);
    }
    this.organizationService.GetBranchesByComp(t, companyid).then(x => {
      this.Branch = x;
      Object.keys(this.Branch).forEach(key => {
        // tslint:disable-next-line:no-unused-expression
        this.Branch[key];
        let Branchtemp = new Branch();
        Branchtemp = this.Branch[key];
        this.Branches.push(Branchtemp);
      });
    });
  }

  getDepartments(Branchid) {
    this.Departments = [];
    let t = localStorage.getItem('userToken');
    t = 'Bearer' + t
    if (this.Departments.length > 0) {
      this.Departments.splice(0, this.Departments.length)
    }
    this.organizationService.GetDepartmentByBranchid(t, Branchid).then(x => {
      this.Department = x;
      Object.keys(this.Department).forEach(key => {
        this.Department[key];
        let Departmenttemp = new Department();
        Departmenttemp = this.Department[key];
        this.Departments.push(Departmenttemp);
      });
    });
  }

  onchangeDepartment(Departmentid) {
    if (Departmentid > 0) {

    }
    else {

    }
  }

  submitReport() {
    // if (this.EmployeeselectedItems.length > 0) {
    //   this.EmployeeAttendanceModel.EmployeeIds = '';
    //   this.EmployeeselectedItems.forEach(element => {
    //     this.EmployeeAttendanceModel.EmployeeIds = this.EmployeeAttendanceModel.EmployeeIds + element.id + ",";
    //   });
    //   var l = this.EmployeeAttendanceModel.EmployeeIds.length
    //   this.EmployeeAttendanceModel.EmployeeIds = this.EmployeeAttendanceModel.EmployeeIds.substr(0, l - 1);
    // }
    // else {
    //   this.EmployeeAttendanceModel.EmployeeIds = "";
    // }

    if (this.EmployeeAttendanceModel.Year > 0 && this.EmployeeAttendanceModel.MonthId > 0 && this.EmployeeAttendanceModel.CompId > 0) {
      this.GetEmployeeAttendanceDetailsReports(this.EmployeeAttendanceModel.Year, this.EmployeeAttendanceModel.MonthId, this.EmployeeAttendanceModel.CompId, this.EmployeeAttendanceModel.BranchId, this.EmployeeAttendanceModel.DepartmentId);
    }
  }

  getreportTypes() {
    this.ReportExecl = new ReportType();
    this.ReportPdf = new ReportType();

    this.ReportPdf.ReportId = 1;
    this.ReportPdf.ReportName = 'Export to Pdf';

    this.ReportExecl.ReportId = 2;
    this.ReportExecl.ReportName = 'Export to Excel';

    this.ReportTypeList.push(this.ReportPdf);
    this.ReportTypeList.push(this.ReportExecl);
  }

  GetEmployeeAttendanceDetailsReports(Year, MonthId, companyid, branchid, departmentid) {
    this.EmployeeAttendance = [];
    let t = localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.EmployeePortalService.GetEmployeeSalaysheetReports(t, Year, MonthId, companyid, branchid, departmentid, 1);
  }

  RefreshPage() {
    this.rout.navigateByUrl('/AttendenceReports/DD_Tour_Leave_Report', { skipLocationChange: true }).then(() => this.rout.navigate(["/AttendenceReports/EmployeeSalarySheetMonthWise"]));
  }


}
