import { Component, OnInit, ViewChild, ContentChild } from '@angular/core';
import { Menu } from '../../../shared/menu.model';
import { UserService } from '../../../shared/user.service';
import { CrmLeadService } from '../../../shared/CRM/MICECRM/LEADCRM/CrmLeadService';
import { Router } from '@angular/router';
import { Model_CRM_Lead } from '../../../shared/CRM/MICECRM/LEADCRM/Model_CRM_LEAD';
import { Model_CrmCountry } from '../../../shared/CRM/MICECRM/Model_CrmCountry';
import { Model_CRM_State } from '../../../shared/CRM/MICECRM/Model_CRM_State';
import { Model_CRM_City } from '../../../shared/CRM/MICECRM/Model_CRM_City';
import { Model_Crm_List } from '../../../shared/CRM/MICECRM/LEADCRM/Model_Crm_List';
import { Model_CRM_LeadShared } from '../../../shared/CRM/MICECRM/LEADCRM/Model_CRM_LeadShared';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { ConfirmationService } from '@jaspero/ng2-confirmations';
import { ConfirmSettings } from '@jaspero/ng2-confirmations/src/interfaces/confirm-settings';
import { ResolveEmit } from '@jaspero/ng2-confirmations/src/interfaces/resolve-emit';
import { ModalOptions, BsModalRef, BsModalService } from 'ngx-bootstrap/modal'
import { DialogComponent } from '../../../../app/dialog/dialog.component';
import { MiceCrmBasicLeadInfoComponent } from './mice-crm-basic-lead-info.component'
//import { AsyncAction } from 'rxjs/scheduler/AsyncAction';
import { CrmOppertunityService } from '../../../shared/CRM/MICECRM/Oppertunity/CrmOppertunityService';
import { Model_CRM_Assigne } from '../../../shared/CRM/MICECRM/Model_CRM_Assigne';
import { ConfirmationDialogService } from '../../../shared/CRM/MICECRM/confirmation-dialog/confirmation-dialog.service'
import { PagerService } from '../../../shared/CRM/MICECRM/LEADCRM/PagerService'
//import { stringify } from '@angular/core/src/render3/util';

@Component({
  selector: 'app-crm-manage-lead',
  templateUrl: './crm-manage-lead.component.html',
  styleUrls: ['./crm-manage-lead.component.css'],
  providers: [MiceCrmBasicLeadInfoComponent, PagerService]
})
export class CrmManageLeadComponent implements OnInit {
  //for model 
  public modalRef: BsModalRef;
  public modelopt: ModalOptions;
  //for model
  public IsSavevisible: boolean;
  public ListId: any;
  public DialogComponenttest: DialogComponent;

  @ContentChild(MiceCrmBasicLeadInfoComponent, { static: false })
  private BasicComponent: MiceCrmBasicLeadInfoComponent;
  //  View  Field Decleration Starts
  @ViewChild(DataTableDirective, { static: false })
  setDtOptions: DataTables.Settings = {};
  cardDtOptions: DataTables.Settings = {};
  dtElement: DataTableDirective;
  dtOptions: DataTables.Settings = {
  };
  dtTrigger: Subject<any> = new Subject();
  //  View  Field Decleration Starts
  setDtOptionsList: DataTables.Settings = {};
  dtOptionsList: DataTables.Settings = {};
  dtTriggerList: Subject<any> = new Subject();
  check: string;
  CheckExiststatus: boolean;
  Checkeditstatus: boolean;
  checkdataval: any;
  // Grid Fetch
  CRMLead: any;
  //leadlist: Model_CRM_Lead[]=[];
  SuccessFullMessageString: string;
  // For Populate Country Drop Down 
  CRM_Lead_Model: Model_CRM_Lead;
  CountryName: any;
  countrylist: Model_CrmCountry[] = [];
  Crm_Country: Model_CrmCountry;
  Crm_model_shared: Model_CRM_LeadShared[] = [];
  // For Populate State Drop Down 
  StateName: any;
  statelist: Model_CRM_State[] = [];
  // For Populate City Drop Down   
  citylist: Model_CRM_City[] = [];
  CityName: any;
  // Populate List Name Drop Down 
  listname: Model_Crm_List[] = [];
  ListName: any;
  templateAlert: any;
  // Begning Permission Portion    
  SelectedMenu: Menu;
  IsDataIsInViewState: boolean = false;
  CanViewState: boolean = true;
  selectedIndex: number;
  // End of the permission portion
  SelectedIndexPage: number = 0;
  //Begin For Least
  ListList: Model_Crm_List[] = [];
  Listobj: any;
  LeadName: String;
  ShowPopup: boolean = false;
  CRM_List_Model: Model_Crm_List;
  ApprovedMsg: boolean = false;
  LeadId: any;
  // Begin success message popup
  SuccessStatus: boolean;
  SuccessMessage: string;
  // end success message popup
  //Begning of Company list
  CompanySettings = {}
  CompanyArray: any[] = [];
  ompanyName: any;
  LeadSharedList: Model_CRM_LeadShared[] = [];
  companylist: Model_CRM_Lead[] = [];
  SelectedCompany: any[] = [];
  CompanyName: any;
  SelectedLead: number = 0;
  // for Paging
  pager: any = {};
  //for paging
  //End of Company List
  //For Share lead
  AssigneArray: any[] = [];
  SelectAssigneList: any[] = [];
  AssigneSettings = {};
  AssigneList: Model_CRM_Assigne[] = [];
  SelectedleadId: number = 0;
  SelectedUserId: number = 0;
  // for shared lead 
  searchstatus: boolean = false;

  constructor(private rout: Router, public userService: UserService, private CRM_OppertunityService: CrmOppertunityService,
    private CRM_LeadService: CrmLeadService, private _confirmation: ConfirmationService, private modalService: BsModalService,
    private confirmationser: ConfirmationDialogService, private pagerService: PagerService) { }

  @ViewChild(DialogComponent, { static: false }) private myChild: DialogComponent;

  ngOnInit() {
    this.CRM_LeadService.SearchText = '';
    this.CRM_LeadService.CurretPageNumber = 1;
    this.CRM_LeadService.CancelLead();
    this.CRM_LeadService.leadlist = [];
    this.CRM_LeadService.PageListList = [];
    //Begin For Least
    this.GetCRMLead();
    this.GetListName();
    this.modelopt = new ModalOptions();
    this.modelopt.class = 'heavy_modal';
    this.modalService.config = this.modelopt;
    //End For Least
    //this.rout.navigate([this.userService.selectedCrmMenu.RouteName]);
    //this.CRM_LeadService.SelectedCrmIndexPage = 0;
    // this.rout.navigate(['ManageMICEAccess/ManageLead/LeadBasicInfo']);
    // ManageMICEAccess/ManageLead/LeadBasicInfo
    // this.notificationsService.emitChange.subscribe(slectedtabindex => {
    //   if ((slectedtabindex != null) || (slectedtabindex == undefined)) {
    //     this.selectedIndex = slectedtabindex;
    //   }
    // });

    if (this.CRM_LeadService.model_CRM_Shared_lead.LeadId > 0) {
      this.CRM_LeadService.CancelLead();
    }
    //this.GetListName();
    this.LeadName = new String()
    this.CRM_List_Model = new Model_Crm_List();
    this.CRMSubPages(0, 'ManageMICEAccess/ManageLead/LeadBasicInfo')
    this.AssigneSettings = {
      text: 'Select User',
      enableSearchFilter: true,
      showCheckbox: true
    }
  }
  get Pagelist(): Model_CRM_Lead[] {
    return this.CRM_LeadService.PageListList;
  }

  get UserServiceData(): UserService {
    return this.userService;
  }

  get SelectedPageIndex(): any {
    return this.CRM_LeadService.SelectedCrmIndexPage
  }
  settings: ConfirmSettings | any =
    {
      overlay: true,
      overlayClickToClose: true,
      showCloseButton: true,
      confirmText: 'Yes',
      declineText: 'No'
    };

  ngAfterViewInit(): void {
    this.dtOptions =
      {
        paging: false, searching: false, info: false
      };
    this.dtOptionsList = {
      // pagingType: 'full_numbers',
      // pageLength: 3,
    };
  }

  GetCRMLead() {
    let t = localStorage.getItem('userToken');
    t = 'Bearer' + t;
    $('#tblCRMLead').DataTable().destroy();
    this.CRM_LeadService.Get_CRMLead(t, this.CRM_LeadService.CurretPageNumber, this.CRM_LeadService.SearchText).then(x => {
      this.CRMLead = x;
      this.CRM_LeadService.leadlist = [];
      this.CRM_LeadService.PageListList = [];
      this.CRM_LeadService.tempLeadList = [];
      Object.keys(this.CRMLead).forEach(key => {
        this.CRMLead[key];
        let CRMLeadtemp = new Model_CRM_Lead();
        CRMLeadtemp = this.CRMLead[key];
        this.CRM_LeadService.leadlist.push(CRMLeadtemp);
      });
    }).then(x => {
      this.CRM_LeadService.PageListList = this.CRM_LeadService.leadlist
      this.setPage(1);
    })
  }

  GetCRMLeadFrompage(pagenumner: number) {
    this.CRM_LeadService.CurretPageNumber = pagenumner;
    let t = localStorage.getItem('userToken');
    t = 'Bearer' + t;
    $('#tblCRMLead').DataTable().destroy();
    this.CRM_LeadService.Get_CRMLead(t, this.CRM_LeadService.CurretPageNumber, this.CRM_LeadService.SearchText).then(x => {
      this.CRMLead = x;
      this.CRM_LeadService.leadlist = [];
      this.CRM_LeadService.PageListList = [];
      this.CRM_LeadService.tempLeadList = [];
      Object.keys(this.CRMLead).forEach(key => {
        this.CRMLead[key];
        let CRMLeadtemp = new Model_CRM_Lead();
        CRMLeadtemp = this.CRMLead[key];
        this.CRM_LeadService.leadlist.push(CRMLeadtemp);
      });
    }).then(x => {
      this.setPage(this.CRM_LeadService.CurretPageNumber);
      this.CRM_LeadService.PageListList = this.CRM_LeadService.leadlist
    })
  }

  GetListName() {
    let t = localStorage.getItem('userToken');
    t = 'Bearer' + t
    this.ListList = [];
    this.CRM_LeadService.GetListName(t).then(x => {
      this.Listobj = x;
      Object.keys(this.Listobj).forEach(key => {
        let ListNametemp = new Model_Crm_List();
        ListNametemp = this.Listobj[key];
        this.ListList.push(ListNametemp);
      });
      this.dtTriggerList.next();
    })
  }

  setPage(page: number) {
    // get pager object from service
    if (this.CRM_LeadService.leadlist[0].TotalPages > 20) {
      this.pager = this.pagerService.getPager(this.CRM_LeadService.leadlist[0].TotalPages, page, 20);
    }
    else {
      this.pager = {};
    }

    // get current page of items
    //alert(this.pager.startIndex);
    //this.CRM_LeadService.PageListList = this.CRM_LeadService.leadlist.slice(this.pager.startIndex, this.pager.endIndex + 1);
    $('#tblCRMLead').DataTable().destroy();
    this.dtTrigger.next();
  }

  onSearchChange(searchValue: string): void {
    if ((searchValue == "") || (searchValue == undefined)) {
      if (this.CRM_LeadService.SearchText == '') {
        this.searchstatus = false;
      }
      else {
        this.searchstatus = true;
      }
    }
    else {
      if (this.CRM_LeadService.SearchText == searchValue) {
        this.searchstatus = false;
      }
      else {
        this.searchstatus = true;
      }
    }

    if (this.searchstatus) {
      this.CRM_LeadService.SearchText = searchValue;
      this.GetCRMLead();
    }
  }

  GetListById(listId: any, template) {
    this.CRM_List_Model = this.ListList.find(x => x.ListId == listId);
    this.modelopt = new ModalOptions();
    this.modelopt.class = 'heavy_modal';
    this.modalService.config = this.modelopt;
    this.modalRef = this.modalService.show(template, this.modelopt);
    //this.GridShow=true;
    this.ShowPopup = true;
    //  this.ApprovedMsg=false;
    //  this.RejectedMsg=false;
    // Begning of the permission portion
    this.IsDataIsInViewState = false;
    // End of the permision portion
    this.ApprovedMsg = true;
  }

  GetDDlList() {
    let t = localStorage.getItem('userToken');
    t = 'Bearer' + t
    this.CRM_LeadService.listname = [];
    // this.CRM_LeadService.GetListName(t).then(x => {
    //   this.ListName = x;
    //   Object.keys(this.ListName).forEach(key => {
    //     let ListNametemp = new Model_Crm_List();
    //     ListNametemp = this.ListName[key];
    //     this.CRM_LeadService.listname.push(ListNametemp);
    //   });
    // });
  }


  GetCRMLeadById(Id) {
    this.CRM_LeadService.IsinViewState = false;
    // End of the permision portion
    let t = localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.CRM_LeadService.GetCRM_Lead_By_id(t, Id).then(x => {
      this.CRMLead = x;
      Object.keys(this.CRMLead).forEach(key => {
        let CRMLeadtemp = new Model_CRM_Lead();
        CRMLeadtemp = this.CRMLead[key];
        this.CRM_LeadService.PreviousLeadName = CRMLeadtemp.LeadName;
        this.CRM_LeadService.model_CRM_Shared_lead.LeadId = CRMLeadtemp.LeadId;
        this.CRM_LeadService.model_CRM_Shared_lead.LeadName = CRMLeadtemp.LeadName;
        this.CRM_LeadService.model_CRM_Shared_lead.Mr = CRMLeadtemp.Mr;
        this.CRM_LeadService.model_CRM_Shared_lead.FirstName = CRMLeadtemp.FirstName;
        this.CRM_LeadService.model_CRM_Shared_lead.MiddleName = CRMLeadtemp.MiddleName;
        this.CRM_LeadService.model_CRM_Shared_lead.LastName = CRMLeadtemp.LastName;
        this.CRM_LeadService.model_CRM_Shared_lead.CountryId = CRMLeadtemp.CountryId;
        this.CRM_LeadService.model_CRM_Shared_lead.CountryName = CRMLeadtemp.CountryName;
        this.CRM_LeadService.model_CRM_Shared_lead.StateId = CRMLeadtemp.StateId;
        this.CRM_LeadService.model_CRM_Shared_lead.StateName = CRMLeadtemp.StateName;
        this.CRM_LeadService.model_CRM_Shared_lead.CityId = CRMLeadtemp.CityId;
        this.CRM_LeadService.model_CRM_Shared_lead.CityName = CRMLeadtemp.CityName;
        this.CRM_LeadService.model_CRM_Shared_lead.PhoneNo = CRMLeadtemp.PhoneNo;
        this.CRM_LeadService.model_CRM_Shared_lead.Extension = CRMLeadtemp.Extension;
        this.CRM_LeadService.model_CRM_Shared_lead.EmailId = CRMLeadtemp.EmailId;
        this.CRM_LeadService.model_CRM_Shared_lead.IsActive = CRMLeadtemp.IsActive;
        this.CRM_LeadService.model_CRM_Shared_lead.AnniversaryDate = CRMLeadtemp.AnniversaryDate;
        this.CRM_LeadService.model_CRM_Shared_lead.DOB = CRMLeadtemp.DOB;
        this.CRM_LeadService.model_CRM_Shared_lead.DOBMoreinfo = CRMLeadtemp.DOBMoreinfo;
        this.CRM_LeadService.model_CRM_Shared_lead.AnniversaryDateMoreinfo = CRMLeadtemp.AnniversaryDateMoreinfo;
        this.CRM_LeadService.model_CRM_Shared_lead.ListId = CRMLeadtemp.ListId;
        this.CRM_LeadService.model_CRM_Shared_lead.ListName = CRMLeadtemp.ListName;
        this.CRM_LeadService.model_CRM_Shared_lead.SegmentIDs = [];
        this.CRM_LeadService.model_CRM_Shared_lead.SegmentIDs = CRMLeadtemp.SegmentIDs;
        this.CRM_LeadService.model_CRM_Shared_lead.DesignationID = CRMLeadtemp.DesignationID;
        this.CRM_LeadService.model_CRM_Shared_lead.AssigneTo = CRMLeadtemp.AssigneTo;
        this.CRM_LeadService.model_CRM_Shared_lead.ContactsList = [];
        this.CRM_LeadService.model_CRM_Shared_lead.ContactsList = CRMLeadtemp.ContactsList;
        this.CRM_LeadService.model_CRM_Shared_lead.Address = CRMLeadtemp.Address;
      });
    }).then(y => {
      this.CRM_LeadService.SelectedCrmIndexPage = 0;
      let RoutName_Basic = 'ManageMICEAccess/ManageLead/LeadMoreInfo';
      let RoutName_more = 'ManageMICEAccess/ManageLead/LeadBasicInfo'
      this.rout.navigate([RoutName_more]);
      // this.CRM_LeadService.SelectedCrmIndexPage=0;
      this.BasicComponent.PutPreviousdata();
      //this.rout.navigate([RoutName_Basic]); 
      // this.BasicComponent.ngOnInit()
    })
  }

  ViewCRMLeadById(Id) {
    this.CRM_LeadService.IsinViewState = true;
    // End of the permision portion
    let t = localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.CRM_LeadService.GetCRM_Lead_By_id(t, Id).then(x => {
      this.CRMLead = x;
      Object.keys(this.CRMLead).forEach(key => {
        let CRMLeadtemp = new Model_CRM_Lead();
        CRMLeadtemp = this.CRMLead[key];
        this.CRM_LeadService.PreviousLeadName = CRMLeadtemp.LeadName;
        this.CRM_LeadService.model_CRM_Shared_lead.LeadId = CRMLeadtemp.LeadId;
        this.CRM_LeadService.model_CRM_Shared_lead.LeadName = CRMLeadtemp.LeadName;
        this.CRM_LeadService.model_CRM_Shared_lead.Mr = CRMLeadtemp.Mr;
        this.CRM_LeadService.model_CRM_Shared_lead.FirstName = CRMLeadtemp.FirstName;
        this.CRM_LeadService.model_CRM_Shared_lead.MiddleName = CRMLeadtemp.MiddleName;
        this.CRM_LeadService.model_CRM_Shared_lead.LastName = CRMLeadtemp.LastName;
        this.CRM_LeadService.model_CRM_Shared_lead.CountryId = CRMLeadtemp.CountryId;
        this.CRM_LeadService.model_CRM_Shared_lead.CountryName = CRMLeadtemp.CountryName;
        this.CRM_LeadService.model_CRM_Shared_lead.StateId = CRMLeadtemp.StateId;
        this.CRM_LeadService.model_CRM_Shared_lead.StateName = CRMLeadtemp.StateName;
        this.CRM_LeadService.model_CRM_Shared_lead.CityId = CRMLeadtemp.CityId;
        this.CRM_LeadService.model_CRM_Shared_lead.CityName = CRMLeadtemp.CityName;
        this.CRM_LeadService.model_CRM_Shared_lead.PhoneNo = CRMLeadtemp.PhoneNo;
        this.CRM_LeadService.model_CRM_Shared_lead.Extension = CRMLeadtemp.Extension;
        this.CRM_LeadService.model_CRM_Shared_lead.EmailId = CRMLeadtemp.EmailId;
        this.CRM_LeadService.model_CRM_Shared_lead.IsActive = CRMLeadtemp.IsActive;
        this.CRM_LeadService.model_CRM_Shared_lead.AnniversaryDate = CRMLeadtemp.AnniversaryDate;
        this.CRM_LeadService.model_CRM_Shared_lead.AnniversaryDateMoreinfo = CRMLeadtemp.AnniversaryDateMoreinfo;
        this.CRM_LeadService.model_CRM_Shared_lead.DOB = CRMLeadtemp.DOB;
        this.CRM_LeadService.model_CRM_Shared_lead.DOBMoreinfo = CRMLeadtemp.DOBMoreinfo;
        this.CRM_LeadService.model_CRM_Shared_lead.ListId = CRMLeadtemp.ListId;
        this.CRM_LeadService.model_CRM_Shared_lead.ListName = CRMLeadtemp.ListName;
        this.CRM_LeadService.model_CRM_Shared_lead.SegmentIDs = [];
        this.CRM_LeadService.model_CRM_Shared_lead.SegmentIDs = CRMLeadtemp.SegmentIDs;
        this.CRM_LeadService.model_CRM_Shared_lead.DesignationID = CRMLeadtemp.DesignationID;
        this.CRM_LeadService.model_CRM_Shared_lead.AssigneTo = CRMLeadtemp.AssigneTo;
        this.CRM_LeadService.model_CRM_Shared_lead.ContactsList = [];
        this.CRM_LeadService.model_CRM_Shared_lead.ContactsList = CRMLeadtemp.ContactsList;
        this.CRM_LeadService.model_CRM_Shared_lead.Address = CRMLeadtemp.Address;
      });
    }).then(y => {
      this.CRM_LeadService.IsinViewState = true;
      this.CRM_LeadService.SelectedCrmIndexPage = 0;
      let RoutName_Basic = '/ManageMICEAccess/ManageLead/LeadBasicInfo';
      let RoutName_more = '/ManageMICEAccess/ManageLead/LeadBasicInfo'
      this.rout.navigateByUrl('/ManageMICEAccess/ManageLead/LeadMoreInfo', { skipLocationChange: true }).then(() => this.rout.navigate([RoutName_more]))
      //this.rout.navigate([RoutName_more]);
      // this.CRM_LeadService.SelectedCrmIndexPage=0;
      this.BasicComponent.PutPreviousdata();
      //this.rout.navigate([RoutName_Basic]); 
      // this.BasicComponent.ngOnInit()
    })
  }


  CRMSubPages(selectitem, RouteName) {
    // this.router.navigate(['Organization']);
    //this.SelectedIndexPage=selectitem;
    this.CRM_LeadService.SelectedCrmIndexPage = selectitem
    this.rout.navigate([RouteName]);
  }


  CrmContacts(selectitem, RouteName) {
    // this.router.navigate(['Organization']);
    //this.SelectedIndexPage=selectitem;
    this.CRM_LeadService.SelectedCrmIndexPage = selectitem
    this.rout.navigate([RouteName]);
  }

  Relode() {
    $('#tblCRMLead').DataTable().destroy();
    this.GetCRMLead()
  }
  onActivate(componentReference) {
    //Below will subscribe to the searchItem emitter
    componentReference.searchItem.subscribe((data) => {
      this.Relode();
    });
  }
  // Save Region  Starts
  //
  saveList(template): void {
    this.IsSavevisible = false;
    let t = localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.CRM_List_Model.IsActive = true;
    if (this.CRM_List_Model.ListId > 0) {
      this.CRM_LeadService.AddList(t, this.CRM_List_Model).subscribe(res => {
        this.ListId = res;
        this.CRM_List_Model.ListId = 0;
        this.CRM_List_Model.ListName = '';
        if (this.ListId > 0) {
          // localStorage.setItem('SuccessfullMessage', "Successfully Updated");
          //   this.rout.navigateByUrl('ManageMICEAccess/CrmUserRoll', { skipLocationChange: true }).then(() => this.rout.navigate(["ManageMICEAccess/ManageList"]));
          $('#tblCRMList').DataTable().destroy();
          this.GetListName();
          this.CRM_List_Model = new Model_Crm_List();
          this.modalRef.hide();
          // this.modelopt = new ModalOptions();
          // this.modelopt.class = 'small_modal';
          // this.modalService.config = this.modelopt;
          // this.modalRef = this.modalService.show(template, this.modelopt);
          // this.SuccessFullMessageString = "Updated Successfully" 
          localStorage.setItem('SuccessfullMessage', "Successfully Updated");
          this.showAlertMessage();
        }
      });
    }
    else {
      this.CRM_LeadService.AddList(t, this.CRM_List_Model).subscribe(res => {
        this.ListId = res;
        this.CRM_List_Model.ListId = 0;
        if (this.ListId > 0) {
          $('#tblCRMList').DataTable().destroy();
          this.GetListName();
          this.CRM_List_Model = new Model_Crm_List();
          this.modalRef.hide();
          // this.GetDDlList();
          // this.modelopt = new ModalOptions();
          // this.modelopt.class = 'small_modal';
          // this.modalService.config = this.modelopt;
          // this.modalRef = this.modalService.show(template, this.modelopt);
          // this.SuccessFullMessageString = "Added Successfully"
          //this.CRMSubPages(0, 'ManageMICEAccess/ManageLead/LeadBasicInfo')
          localStorage.setItem('SuccessfullMessage', "Successfully Added");
          this.showAlertMessage();
        }
      });
    }
  }

  // Save Region  Starts
  viewAppliedLeave(template) {
    this.CRM_List_Model = new Model_Crm_List();
    this.modelopt = new ModalOptions();
    this.modelopt.class = 'heavy_modal';
    this.modalService.config = this.modelopt;
    this.modalRef = this.modalService.show(template, this.modelopt);
    //this.GridShow=true;
    this.ShowPopup = true;
    //  this.ApprovedMsg=false;
    //  this.RejectedMsg=false;
    // Begning of the permission portion
    this.IsDataIsInViewState = false;
    // End of the permision portion
    this.ApprovedMsg = true;
  }
  viewApplicationSharedtemplate(template) {
    this.AssigneList = [];
    this.AssigneArray = [];
    this.SelectAssigneList = [];
    this.CompanySettings = {
      singleSelection: true,
      text: 'Select Lead',
      enableSearchFilter: true,
      showCheckbox: false
    }

    this.SelectedCompany = [];

    this.GetCrmCompany();
    this.CRM_List_Model = new Model_Crm_List();
    this.modelopt = new ModalOptions();
    this.modelopt.class = 'heavy_modal';
    this.modalService.config = this.modelopt;

    this.modalRef = this.modalService.show(template, this.modelopt);
    //this.GridShow=true;
    this.ShowPopup = true;
    //  this.ApprovedMsg=false;
    //  this.RejectedMsg=false;
    // Begning of the permission portion
    this.IsDataIsInViewState = false;
    // End of the permision portion
    this.ApprovedMsg = true;
  }

  // Begning of the delete lead

  DeleteCrmLead(LeadId: number): void {
    // alert(ID);
    this._confirmation.create('Confirm to Delete', 'Press Yes to Delete', this.settings)
      .subscribe((ans: ResolveEmit) => {
        if (ans.resolved == true) {

          let t = localStorage.getItem('userToken');
          t = 'Bearer' + t;

          if (LeadId > 0) {
            this.CRM_LeadService.DeleteLead(t, LeadId).subscribe(res => {
              this.LeadId = res;

              if (this.LeadId > 0) {
                $('#tblCRMLead').DataTable().destroy();
                this.GetCRMLead()
                localStorage.setItem('SuccessfullMessage', "Successfully Deleted");
                this.showAlertMessage();

              }
            })
          }

        }
      })
  }


  // End of the dletete lead


  //begning of save 

  SaveCrmLeadShared(): void {


    this.confirmationser.confirm('Please confirm..', 'Do you really want to save ?')
      .then((confirmed) => {
        if (confirmed == true) {

          let t = localStorage.getItem('userToken');
          t = 'Bearer' + t;

          if (this.LeadSharedList.length > 0) {
            this.CRM_LeadService.AddUpdateCrmLeadAssigned(t, this.LeadSharedList).subscribe(res => {
              this.LeadId = res;

              if (this.LeadId == true) {
                this.modalRef.hide();
                localStorage.setItem('SuccessfullMessage', "Successfully Update");
                this.showAlertMessage();

              }
            })
          }

        }
      }
      )
      .catch(() => console.log('User dismissed the dialog (e.g., by using ESC, clicking the cross icon, or clicking outside the dialog)'));

  }

  //End of save

  showAlertMessage() {

    if (localStorage.getItem('SuccessfullMessage')) {
      this.SuccessStatus = true;
      this.SuccessMessage = localStorage.getItem('SuccessfullMessage')
      localStorage.removeItem('SuccessfullMessage');
      this.myChild.setvisible();

    }


  }


  //Begning of the select company 

  GetCrmCompany() {

    let t = localStorage.getItem('userToken');
    t = 'Bearer' + t
    this.companylist = [];
    this.CompanyArray = [];
    this.CRM_OppertunityService.Get_CrmCompany(t).then(x => {
      this.CompanyName = x;
      Object.keys(this.CompanyName).forEach(key => {
        let CompanyNametemp = new Model_CRM_Lead();
        CompanyNametemp = this.CompanyName[key];
        this.companylist.push(CompanyNametemp);
        this.CompanyArray.push({ "id": CompanyNametemp.LeadId, "itemName": CompanyNametemp.LeadName });
      });

    })
    //   .then(y => {

    //   if (this.CRM_OppertunityService.CrmCommonUseOportunityDetails.CompanyId > 0) {
    //     let OpportunityCompany = this.companylist.find(x => x.LeadId == this.CRM_OppertunityService.CrmCommonUseOportunityDetails.CompanyId);
    //     this.SelectedCompany = [];
    //     this.SelectedCompany.push({ "id": OpportunityCompany.LeadId, "itemName": OpportunityCompany.LeadName });
    //     this.GetLeadContact();
    //   }
    // });
  }

  //End of fthe select  company

  onItemSelectCompany(item: any) {

    this.SelectedleadId = item['id'];
    this.SelectedLead = this.SelectedleadId;
    this.SelectAssigneList = [];
    this.GetCrmAssignetoList();

    // if (this.CRM_OppertunityService.CrmCommonUseOportunityDetails.CompanyId > 0) {
    //   this.GetLeadContact();
    // }
  }
  OnItemDeSelectCompany(item: any) { }
  onSelectAllCompany(items: any) { }
  onDeSelectAllCompany(items: any) { }

  GetCrmAssignetoList() {

    let t = localStorage.getItem('userToken');
    t = 'Bearer' + t

    this.AssigneList = [];
    this.AssigneArray = [];
    this.CRM_LeadService.GetAssignetoList(t).then(x => {
      this.Listobj = x;
      Object.keys(this.Listobj).forEach(key => {
        let AssigneTotemp = new Model_CRM_Assigne();
        AssigneTotemp = this.Listobj[key];

        this.AssigneList.push(AssigneTotemp);
        this.AssigneArray.push({ "id": AssigneTotemp.EmployeeId, "itemName": AssigneTotemp.EmployeeName });
      });
    }).then(z => {


      this.GetCRMSharedLead(this.SelectedleadId);

    })
  }

  GetCRMSharedLead(leadid: number) {

    this.SelectAssigneList = [];
    let t = localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.LeadSharedList = [];
    this.CRM_LeadService.GetCrmLeadShared(t, leadid).then(x => {
      this.CRMLead = x;
      Object.keys(this.CRMLead).forEach(key => {
        let CRMLeadtemp = new Model_CRM_LeadShared();
        CRMLeadtemp = this.CRMLead[key];
        this.LeadSharedList.push(CRMLeadtemp);
      });
    }).then(y => {

      if (this.LeadSharedList.length > 0) {

        this.LeadSharedList.forEach(x => {

          let tempshared = this.AssigneList.find(y => y.EmployeeId == x.UserId);
          this.SelectAssigneList.push({ "id": tempshared.EmployeeId, "itemName": tempshared.EmployeeName });

        })
      }

    })
  }


  onItemSelectAssigneTo(item: any) {

    this.SelectedUserId = item['id'];

    let tempshared = this.AssigneList.find(y => y.EmployeeId == this.SelectedUserId);


    let tempharedObj = new Model_CRM_LeadShared();
    tempharedObj.UserId = tempshared.EmployeeId;
    tempharedObj.LeadId = this.SelectedleadId;
    tempharedObj.IsActive = true;
    this.LeadSharedList.push(tempharedObj);
  }
  OnItemDeSelectAssigneTo(item: any) {
    this.SelectedUserId = item['id'];
    let tempshared = this.AssigneList.find(y => y.EmployeeId == this.SelectedUserId);
    let indexofshare = this.LeadSharedList.findIndex(x => x.UserId == tempshared.EmployeeId);

    if (!(indexofshare == -1)) {
      this.LeadSharedList = this.LeadSharedList.filter(x => x.UserId !== tempshared.EmployeeId);

    }

  }

  onSelectAllAssigneTo(items: any) {

    this.LeadSharedList = [];
    this.AssigneList.forEach(x => {
      let tempharedObj = new Model_CRM_LeadShared();
      tempharedObj.UserId = x.EmployeeId;
      tempharedObj.LeadId = this.SelectedleadId;
      tempharedObj.IsActive = true;
      let indexofshare = this.LeadSharedList.findIndex(x => x.UserId == tempharedObj.UserId);
      if (indexofshare < 0) {
        this.LeadSharedList.push(tempharedObj);
      }
    });
  }


  onDeSelectAllAssigneTo(items: any) {
    this.LeadSharedList = [];
    // this.AssigneList.forEach(x => {

    //   let tempharedObj = new Model_CRM_LeadShared();
    //   tempharedObj.UserId = x.EmployeeId;
    //   tempharedObj.LeadId = this.SelectedleadId;
    //   tempharedObj.IsActive = false;
    //   this.LeadSharedList.push(tempharedObj);
    // });
  }


}
