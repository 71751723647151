import { INgxMyDpOptions, IMyDateModel } from 'ngx-mydatepicker';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { EmployeeMasterService }  from '../../../shared/HR_ADMIN/Employee/EmployeeMaster.Service';
import { EmployeeDetail } from '../../../shared/HR_ADMIN/Employee/EmployeeDetail.Model';
import { EmployeeRelationModel } from '../../../shared/HR_ADMIN/Employee/EmployeeRelation.Model';
import { EmployeeNomineeDetailsModel } from '../../../shared/HR_ADMIN/Employee/EmployeeNomineeDetailsModel';
//Begin For Notification service
import { NotificationsService } from '../../../shared/notifications.service';

@Component({
  selector: 'app-employeey-nominee-details',
  templateUrl: './employeey-nominee-details.component.html',
  styleUrls: ['./employeey-nominee-details.component.css']
})

export class EmployeeyNomineeDetailsComponent implements OnInit 
{
  AddressRefID:number;
  SuccessStatus:boolean=false;
  SuccessMessage: string ="";
  Tabindex:number=0;
  ExpRefId:number =0;
  NominiBirthDate :string;
  myOptions: INgxMyDpOptions = 
  {
    // other options...
    dateFormat: 'dd.mm.yyyy',
  };
  Relations : EmployeeRelationModel[]=[];
  Realation : any;
  DisableSave : boolean=false;
  // Initialized to specific date (09.10.2018)
  model: any = { date: { year: 2018, month: 10, day: 9 } };

  constructor(public EmployeeMasterService: EmployeeMasterService, private rout: Router, private notificationsService: NotificationsService) {  }
  // optional date changed callback
  OnChangeDOBNominee(event: IMyDateModel,i: number): void
  {
    const monthNames = [
      'January', 'February', 'March',
      'April', 'May', 'June', 'July',
      'August', 'September', 'October',
      'November', 'December'
      ];
    var m= event.jsdate.getMonth();
    m=m+1;
    var d= event.jsdate.getDate();
    var y= event.jsdate.getFullYear();
    var fulldate="";
    if(m>=10)
    {
      if(d < 10)
      {
        fulldate= m + '/' + '0'+ d + '/' + y;
      }
      else
      {
        fulldate= m + '/' + d + '/' + y; 
      }
    }
    else
    {
      if(d < 10)
      {
        fulldate= '0' + m + '/' + '0'+ d + '/' + y;
      }
      else
      {
        fulldate= '0' + m + '/' + d + '/' + y; 
      } 
    }
    this.EmployeeMasterService.SharedEmployees.EmployeeNomineeDetailModel[i].NomineeDOBPass=fulldate;
  }

  ngOnInit() 
  {
    this.getRelation();
    if(this.EmployeeMasterService.SharedEmployees.EmployeeNomineeDetailModel==null)
    {
      let EmployeeNominyList : EmployeeNomineeDetailsModel[]=[];
      for( var i=0; i<4; i++ )
      {
        let tempNomini = new EmployeeNomineeDetailsModel(); 
        tempNomini.NomineeRelationId=0; 
        EmployeeNominyList.push(tempNomini);         
      }
      this.EmployeeMasterService.SharedEmployees.EmployeeNomineeDetailModel=EmployeeNominyList;
    }
    if(this.EmployeeMasterService.SharedEmployees.EmployeeId > 0)
    {
      if(this.EmployeeMasterService.SharedEmployees.EmployeeNomineeDetailModel.length==0)
      {
        let EmployeeNominyList : EmployeeNomineeDetailsModel[]=[];
        for( var i=0; i<4; i++ )
        {
          let tempNomini = new EmployeeNomineeDetailsModel(); 
          tempNomini.NomineeRelationId=0; 
          EmployeeNominyList.push(tempNomini);         
        }
        this.EmployeeMasterService.SharedEmployees.EmployeeNomineeDetailModel=EmployeeNominyList;
      }
      else
      {
        this.changetdateformat();
      }
    }
    if( this.EmployeeMasterService.SaveOrUpdate==1)
    {
      localStorage.setItem('SuccessfullMessage', "Save Successfully");
      this.EmployeeMasterService.SaveOrUpdate=0;
    }
    if( this.EmployeeMasterService.SaveOrUpdate==2)
    {
      localStorage.setItem('SuccessfullMessage', "Update Successfully");
      this.EmployeeMasterService.SaveOrUpdate=0;
    }
    if(localStorage.getItem('SuccessfullMessage'))
    {
      this.SuccessStatus=true;
      this.SuccessMessage =localStorage.getItem('SuccessfullMessage')
      localStorage.removeItem('SuccessfullMessage');
    } 
    this.DisableSave=true;
  }

  AddnweRow()
  {
    let tempN = new EmployeeNomineeDetailsModel();  
    tempN.NomineeRelationId=0; 
    this.EmployeeMasterService.SharedEmployees.EmployeeNomineeDetailModel.push(tempN);     
  }  

  changetdateformat()
  {
    let objemp = new EmployeeDetail();
    this.EmployeeMasterService.SharedEmployees.EmployeeNomineeDetailModel.forEach(temp=>
    {
      temp.NomineeDOB=objemp.ReturnFormatdate(temp.NomineeDOBPass)
    })
  }

  SaveEmpolyeeNomineeDetails() :void
  {
    this.DisableSave=false;
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;    
    var j=0;
    var l=this.EmployeeMasterService.SharedEmployees.EmployeeNomineeDetailModel.length;
    this.EmployeeMasterService.SharedEmployees.EmployeeNomineeDetailModel.forEach(item=>
    {    
      j=j+1;
      if(item.NomineeRelationId >0)
      { 
        if(item.NomineeDOBPass != null)
        {
          item.EmployeeId= this.EmployeeMasterService.SharedEmployees.EmployeeId;
          if(item.NomineeRefId==null)
          {
            item.NomineeRefId=0;
          }
          if(item.NomineeRefId> 0)
          {
            this.EmployeeMasterService.UpdateEmployeeNomineeDetails(t ,item).subscribe(res=>
            {
              this.ExpRefId=Number(res);        
              if(this.ExpRefId>0)
              {
                this.EmployeeMasterService.SaveOrUpdate=2;
                this.Tabindex=0 ;        
                this.notificationsService.emit(this.Tabindex);  
                if(j==l)
                {
                  this.rout.navigateByUrl('/Employee/EmployeeDetails/PersonalDetails');
                }
              }
            });                  
          }                             
          else
          {         
            this.EmployeeMasterService.AddEmployeeNomineeDetails(t ,item).subscribe(res=>
            {
              this.ExpRefId=Number(res);         
              if(this.ExpRefId>0)
              {
                this.EmployeeMasterService.SaveOrUpdate=1;          
                item.NomineeRefId=Number(this.ExpRefId); 
                this.Tabindex=0;         
                this.notificationsService.emit(this.Tabindex);
                if(j==l)
                {
                  this.rout.navigateByUrl('/Employee/EmployeeDetails/PersonalDetails');
                }
              }
            }); 
          }
        }
      }
    });
  }

  getRelation() 
  {
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.EmployeeMasterService.GetRelation( t).then(x => 
    {
      this.Realation = x;
      Object.keys(this.Realation).forEach( key => 
      {
        let Relationtemp = new EmployeeRelationModel();
        Relationtemp = this.Realation[key];
        this.Relations.push(Relationtemp);
      });     
    });      
  }
  
  RefreshPage()
  {
    this.rout.navigateByUrl('/Employee/EmployeeOffer', {skipLocationChange: true}).then(()=>this.rout.navigate(["/Employee/EmployeeDetails"]));
  }
}