export class TDSSlabModel 
{
    public RefId: number;
    public GenderId :number;
    public GenderName :string;
    public FromAge: number;
    public ToAge: number;
    public AgeStatus : string;
    public HealthEducationCess: number;
    public ApplicableFromDate: Date;
    public DetailsRefId : number;
    public SlabMasterId: number;
    public FromAmount : number;
    public ToAmount : number;
    public IsActive : boolean;
    public Percentage : number;
    public ModificationDate: Date;
    public TDSAmount : number;
    public TDSType : string;
}