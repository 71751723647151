<div class="body-content-area tabcontent" id="link1">
  <div class="row">
    <div class="col-lg-12">
      <div class="row">
        <div class="col-lg-1 low-padding text-center pad_0_mar_0">
          <span class="rounded-circle"><i class="fas fa-users" style="font-size:15px;color: #ffffff;"></i></span>
        </div>
        <div class="col-lg-10 clear-padding">
          <div>
            <span class="page-title">Employee Attendance Modification</span>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div>
    <fieldset class="body-form-control" > 
      <legend class="legend--">Employee Details</legend>
      <form #EmployeeAttendanceForm="ngForm">
        <div class="row">
          <div class="col-lg-4 col-body-border-right">
            <div class="form-group row">
              <label class="col-sm-3 col-form-label clear-padding-right">Employee</label>
              <div class="col-sm-9 clear-left-padding">
                <angular2-multiselect class="form-control" [(ngModel)]="selectedItems" [data]="emp" name="EmployeeId" 
                  [settings]="EmployeeSettings" (onSelect)="onItemSelect($event)" (onDeSelect)="OnItemDeSelect($event)" (onSelectAll)="onSelectAll($event)"
                  (onDeSelectAll)="onDeSelectAll($event)">
                </angular2-multiselect>
              </div>
              <div class="required-validation" *ngIf="EmployeeAttendanceModel.EmployeeId == 0">Employee is required</div>
            </div>
          </div>
          <div class="col-lg-4 col-body-border-right">
            <div class="form-group row">
              <label class="col-sm-4 col-form-label clear-padding-right">Year</label>
              <div class="col-sm-7 clear-left-padding">
                <select [(ngModel)]="EmployeeAttendanceModel.Year" id ="Year" class="form-control" name="Year" #Year="ngModel" (ngModelChange)="onchangeFinYear($event)" required>
                  <option [value]="0" [disabled]=true >Select Year</option>
                  <option *ngFor="let FY of FinancialYears" [value]="FY.Year">{{FY.Year}}</option>
                </select>
              </div>
              <div class="required-validation" *ngIf="EmployeeAttendanceModel.Year==0">Year is required</div>
            </div>
          </div>
          <div class="col-lg-4">
            <div class="form-group row">
              <label class="col-sm-4 col-form-label clear-padding-right">Month</label>
              <div class="col-sm-7 clear-left-padding">
                <select [(ngModel)]="EmployeeAttendanceModel.MonthId" (ngModelChange)="onchangeMonth($event)" id ="MonthId" class="form-control" name="MonthId" #MonthId="ngModel" required>
                  <option [value]="0" [disabled]=true >Select Month</option>
                  <option *ngFor="let Mo of Months" [value]="Mo.MonthId">{{Mo.MonthName}}</option>
                </select>
              </div>
              <div class="required-validation" *ngIf="EmployeeAttendanceModel.MonthId==0">Month is required</div>
            </div>

            <div class="form-group row">
              <label class="col-sm-5 col-form-label clear-padding-right">
                <button type="submit" (click)="submitClick()" class="btn btn-primary save-button pull-right" [disabled]="!(EmployeeAttendanceForm.valid && this.DisableGenerate== true && EmployeeAttendanceModel.EmployeeId > 0 && EmployeeAttendanceModel.MonthId > 0 && EmployeeAttendanceModel.Year > 0)">Generate</button>
              </label>
            </div>           
          </div>            
        </div>
        
      </form> 

      <div *ngIf="NoRecordMsg" style="color: #dc3545; text-align: center;font-size: 15px; font-weight: bold;">No Record Found.</div>
      <div *ngIf="MonAttMsg" style="color: #dc3545; text-align: center;font-size: 15px; font-weight: bold;">Monthly Attendance is Already Done.</div>

      <fieldset class="body-form-control" *ngIf="EmployeeLeaveBalance.length > 0"> 
        <legend class="legend--">Leave Balance Details</legend>
        <table id="tblEmployeeLeaveBalanceDetails" class="table table-striped table-bordered text-center table-new row-border hover">
          <thead class="thead-blue text-center">
            <tr>
              <th>Sl.No.</th>
              <th>Leave Type</th>
              <th>Leave Entitlement</th>
              <th>Leave Taken During the Year</th>
              <th>Opening Balance as of Today</th>
              <th>Leave Applied or pending for Approval</th>
              <th>Available Balance as of Today</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let EmpLeaveBal of EmployeeLeaveBalance, let i = index">
              <td scope="row">{{i+1}}</td>
              <td style=" text-align: left;">{{EmpLeaveBal.LeaveTypeName}}</td>
              <td>{{EmpLeaveBal.NoOfLeave}}</td>
              <td>{{EmpLeaveBal.LeaveTaken}}</td>
              <td>{{EmpLeaveBal.BalanceLeave}}</td>
              <td>{{EmpLeaveBal.LeaveApply}}</td>
              <td>{{EmpLeaveBal.BalanceLeaveAfterApply}}</td>
            </tr>
          </tbody>
          <tfoot class="thead-blue text-center">
            <tr>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
            </tr>
          </tfoot>
        </table>
      </fieldset>

      <form #EmployeeAttendanceDailyDetails="ngForm" *ngIf="EmployeeAttendance.length > 0">
        <table id="tblEmployeeAttendanceDailyDetails" class="table table-striped table-bordered text-center table-new row-border hover">
          <thead class="thead-blue text-center">
            <tr>
              <th style=" width: 5%; text-align: left;">Sl.No.</th>
              <th style=" width: 9%;">Date</th>
              <th style=" width: 8%; text-align: left;">Day</th>
              <th style=" width: 8%;">LogIn Time</th>
              <th style=" width: 8%;">LogOut Time</th>
              <th style=" width: 8%;">Duration</th>
              <th style=" width: 10%;">Status</th>
              <th style=" width: 10%;">Late By (InTime)</th>
              <th style=" width: 10%;">Early By (OutTime)</th>
              <th style=" width: 12%;">Status Modified</th>
              <th style=" width: 12%;">Remarks</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let EmpAtt of EmployeeAttendance, let i = index">
              <td scope="row">{{i+1}}</td>
              <td style=" text-align: center;">
                <input type="text" hidden="hidden" [(ngModel)]="EmpAtt.RefId" id ="RefId_{{i}}" name="RefId_{{i}}" #RefId="ngModel">
                {{EmpAtt.AttendanceDate | date : "dd-MMM-yyyy"}}
              </td>
              <td style=" text-align: left;">{{EmpAtt.AttendanceDay}}</td>
              <td style=" text-align: center;">{{EmpAtt.InTime | date : "HH:mm:ss"}}</td>
              <td style=" text-align: center;">{{EmpAtt.OutTime | date : "HH:mm:ss"}}</td>
              <td style=" text-align: center;">{{EmpAtt.WorkingHourshow}}</td>
              <td style=" text-align: center;">{{EmpAtt.Status}}</td>
              <td style=" text-align: center;">{{EmpAtt.LateBy}}</td>
              <td style=" text-align: center;">{{EmpAtt.EarlyBy}}</td>
              <td>
                <!-- <angular2-multiselect class="form-control" [data]="ModifiedStatus" [(ngModel)]="selectedModifiedStatusItems" name="ModifiedStatus" 
                  [settings]="ModifiedStatusSettings" (onSelect)="onModifiedStatusSelect($event)" (onDeSelect)="OnModifiedStatusDeSelect($event)" (onSelectAll)="onSelectModifiedStatusAll($event)"
                  (onDeSelectAll)="onDeSelectAllModifiedStatus($event)">
                </angular2-multiselect> -->

                <select [(ngModel)]="EmpAtt.ModifiedStatus" id="ModifiedStatus_{{i}}" class="form-control" name="ModifiedStatus_{{i}}" #ModifiedStatus="ngModel">
                  <option [value]="0" [disabled]=true >Select Status</option>
                  <option *ngFor="let ATTS of AttendanceStatus" [value]="ATTS.StatusCode">{{ATTS.StatusCode}}</option>
                </select>
              </td>
              <td><input type="text" [(ngModel)]="EmpAtt.Remarks" id ="Remarks_{{i}}" name="Remarks_{{i}}" #Remarks="ngModel" class="form-control form-control-custom form-control-custom-new"></td>
            </tr>
          </tbody>
          <tfoot class="thead-blue text-center">
            <tr>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
            </tr>
          </tfoot>
        </table>
        <div class="row">
          <div class="col-lg-4">
          </div>
          <div class="col-lg-4">
            <div class="form-group row">
              <label class="col-sm-6 col-form-label text-center clear-padding-right">
                <!-- *ngIf="!(this.IsDataIsInViewState)" -->
                <button type="submit" class="btn btn-primary save-button" [disabled]="!(EmployeeAttendanceDailyDetails.valid && this.EmployeeAttendance.length > 0 && this.DisableSave==true && this.MonAttMsg == false)" (click)="UpdateEmployeeAttendance(template)">Save</button>  
              </label>
            </div>
          </div>
          <div class="col-lg-4">
            <div class="form-group row">
              <div class="col-sm-6 col-form-label text-center clear-padding-left">       
                <!-- <button type="button" tabindex="14" class="btn btn-danger cancle-button" (click)="RefreshPage()">Refresh Page</button> -->
              </div>
            </div>
          </div>
        </div>
      </form>
    </fieldset>

    <div style=" box-align: center;" class="new-modal">
      <ng-template #template>
        <div class="modal-header" style="background: #2a9bd7;"> 
          <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()" style="color:#fff;">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body" style="text-align: center;">
          <div style="color: #dc3545; text-align: center;font-size: 15px; font-weight: bold;">Attendance Modified Successfully</div>
            <button type="button" aria-label="Close" (click)="modalRef.hide()" style="background: #2a9bd7; outline: 0px none; border: 0px none; color: #fff; padding: 5px 10px; border-radius: 3px;">
              <span >OK</span>
            </button>             
        </div>
      </ng-template>
    </div>
  </div>
</div>