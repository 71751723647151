<div class="body-content-area tabcontent" id="link1">
  <div class="row">
    <div class="col-lg-9">
      <div class="row">
        <div class="col-lg-1 low-padding text-center">
          <!-- <img *ngIf="this.SelectedMenu.Image!=null" src={{this.SelectedMenu.Image}} class="body-icon" alt="Icon"/> -->
        </div>
        <div class="col-lg-10 clear-padding">
          <div>
            <span class="page-title">Employee Direct Duty</span>
            <small class="page-description"></small>
          </div>
        </div>
      </div>
    </div>
  </div>

  <fieldset class="body-form-control" *ngIf="this.DirectDutyList.length > 0"> 
    <legend class="legend--">Direct Duty Status Details</legend>
    <form #EmployeeDirectDutyDetails="ngForm">
      <div lass="row" >
        <table id="tblDirecetDutyDetails" class="table table-striped table-bordered text-left table-new row-border hover">
          <thead class="thead-blue text-center">
            <tr>
              <th>Sl.No.</th>
              <th>Date</th>
              <th>Direct Duty Type</th>
              <th>Status</th>
              <th>Reason</th>
              <th>Place</th>
              <th>Remarks</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let EmpDirectDuty of DirectDutyList, let i = index">
              <td scope="row">{{i+1}}</td>
              <td class="text-center">{{EmpDirectDuty.DateOfDirectDuty| date : "dd-MMM-yyyy"}}</td>
              <td *ngIf="EmpDirectDuty.IsMorningDirectduty==true">First Half Direct Duty</td>
              <td *ngIf="EmpDirectDuty.IsSecondHalfDirectDuty==true">Second Half Direct Duty</td>
              <td *ngIf="EmpDirectDuty.IsFullDayDirectDuty==true">FullDay Direct Duty</td>
              <td *ngIf="EmpDirectDuty.IsApproved==true">Approved</td>
              <td *ngIf="EmpDirectDuty.IsRejected==true">Rejected</td>
              <td *ngIf="EmpDirectDuty.IsApproved==false && EmpDirectDuty.IsRejected==false">Pending</td>
              <td>{{EmpDirectDuty.Reason}}</td>
              <td>{{EmpDirectDuty.Place}}</td>
              <td>{{EmpDirectDuty.Remarks}}</td>
            </tr>
          </tbody>
        </table>
    </div>
    </form>
  </fieldset>

  <fieldset class="body-form-control" > 
    <app-dialog [(visible)]="this.SuccessStatus">
      <span class="successmsg">{{this.SuccessMessage}}</span>
    </app-dialog>
    <legend class="legend--">Direct Duty Application</legend>
    <form #EmployeeDiredeuty="ngForm" (ngSubmit)="SaveEmployeeDirectDuty()">
      <div class="row"> 
        <div class="col-lg-4">
          <div class="col-body-border-right">
            <div class="form-group row">
              <label  class="col-sm-4 col-form-label clear-padding-right">Employee Code</label>
              <div class="col-sm-7 clear-left-padding">
                :&nbsp;&nbsp;{{this.DirectDutyModel.EmployeeCode}}
              </div>
            </div>
            <div class="form-group row">
              <label  class="col-sm-4 col-form-label clear-padding-right">Apply Date</label>
              <div class="col-sm-7 clear-left-padding">
                :&nbsp;&nbsp;<input type="date" tabindex="2" style="width:90%;" name="DateOfDirectDuty" [ngModel]="DirectDutyModel.DateOfDirectDuty | date:'yyyy-MM-dd'" (ngModelChange)="ApplydateChanged($event)" #FromDate="ngModel" required/>
              </div>
              <div class="required-validation" *ngIf="ShowMessage">Please select Valid Date.</div>
            </div>
            <div class="form-group row">
              <label class="col-sm-4 col-form-label clear-padding-right">Place</label>
              <div class="col-sm-7 clear-left-padding">
                  <input type="text" [(ngModel)]="DirectDutyModel.Place" id ="Place" name="Place" #Place="ngModel" required class="form-control form-control-custom form-control-custom-new">
              </div>
              <div *ngIf="Place.invalid && (Place.dirty || Place.touched)" class="required-validation">
                <div *ngIf="Place.errors.required">Place is required.</div>
                <div *ngIf="Place.errors.maxlength">Place is not more than 500 characters</div>
              </div>
            </div>         
          </div>  
        </div>

        <div class="col-lg-4">
          <div class="col-body-border-right">
            <div class="form-group row">
              <label  class="col-sm-4 col-form-label clear-padding-right">Name</label>
              <div class="col-sm-7 clear-left-padding">
                :&nbsp;&nbsp;{{this.DirectDutyModel.EmployeeName}}
              </div>
            </div>
            <div class="form-group row">
              <div class="col-sm-7 clear-left-padding">
                <input type="radio" tabindex="4" [checked]="this.DirectDutyModel.Fullday=='Fullday'" (click)="OnChangeFullday($event)" id ="Fullday" name="leaveType" >
                Full Day
                <div class="required-validation" *ngIf="FullDayShowMessage">You have not Authority to Apply, Please check with Other Date.</div>
              </div>
            </div>
            <div class="form-group row">
              <label class="col-sm-4 col-form-label clear-padding-right">Reason</label>
              <div class="col-sm-7 clear-left-padding">
                <input type="text" [(ngModel)]="this.DirectDutyModel.Reason" tabindex="12" maxlength="500" id ="Reason" name="Reason" #Reason="ngModel" required class="form-control form-control-custom form-control-custom-new" >
              </div>
              <div *ngIf="Reason.invalid && (Reason.dirty || Reason.touched)" class="required-validation">
                <div *ngIf="Reason.errors.required">Reason is required.</div>
                <div *ngIf="Reason.errors.maxlength">Reason is not more than 500 characters</div>
              </div> 
            </div>
          </div> 
        </div>

        <div class="col-lg-4">
          <div class="form-group row">
            <label  class="col-sm-4 col-form-label clear-padding-right">Approver</label>
            <div class="col-sm-7 clear-left-padding">
              :&nbsp;&nbsp;{{this.DirectDutyModel.RHCode}}-{{this.DirectDutyModel.RHName}} 
            </div>
          </div>
          <div class="form-group row" *ngIf="this.DirectDutyModel.WorkingDuration > 300">
            <div class="col-sm-4 col-form-label clear-padding-right">
              <input type="radio" tabindex="3" [checked]="this.DirectDutyModel.FirstHalf=='FirstHalf'" (click)="OnChangeFromFirstHalf($event)" id ="FirstHalf" name="leaveType" >
                FirstHalf &nbsp; &nbsp;
              <div class="required-validation" *ngIf="FirstHalfShowMessage">Please check with Other Date.</div>
              <div class="required-validation" *ngIf="SecondHalfShowMessage">Please check with Other Date.</div>
            </div>
            <div class="col-sm-7 clear-left-padding">
              <input type="radio" tabindex="4" [checked]="this.DirectDutyModel.SecondHalf=='SecondHalf'" (click)="OnChangeFromSecondHalf($event)" id ="SecondHalf" name="leaveType" >
                SecondHalf
            </div>
          </div>
          <div class="form-group row">
            <label class="col-sm-4 col-form-label clear-padding-right">Approver EMail</label>
            <div class="col-sm-7 clear-left-padding">
              :&nbsp;&nbsp;{{this.DirectDutyModel.RHEmailId}} 
            </div>
          </div>
          <div class="form-group row">
            <label class="col-sm-6 col-form-label text-center clear-padding-right">
            <button type="submit" tabindex="13" class="btn btn-primary save-button" [disabled]="!((this.validdate==true) 
            && (this.DirectDutyModel.Reason.length > 0) 
            && this.ShowMessage == false
            && this.FullDayShowMessage == false
            && this.FirstHalfShowMessage ==false
            && this.SecondHalfShowMessage ==false && this.CanApply==true
            && this.DisableSave == true && this.DirectDutyModel.AppliedIsApproved == false
            && (this.DirectDutyModel.IsMorningDirectduty==true || this.DirectDutyModel.IsSecondHalfDirectDuty==true || this.DirectDutyModel.IsFullDayDirectDuty==true) 
            && (this.DirectDutyModel.Place.length > 0 )  )">Save</button>  
            </label>
            <div class="col-sm-6 col-form-label text-center">
              <button type="button" tabindex="14" (click)="RefreshPage()" class="btn btn-danger cancle-button">Cancel</button>
            </div>
          </div>
          <div class="form-group row">
            <div class="required-validation" *ngIf="this.DirectDutyModel.AppliedStatus">{{this.AppliedMessage}}</div>
          </div>
        </div> 
      </div> 
    </form>
  </fieldset>
</div>