<div class="body-content-area tabcontent" id="link1">
<div class="row">
    <div class="col-lg-9">
        <div class="row">
            <div class="col-lg-1 low-padding text-center">
                <!-- <img *ngIf="this.SelectedMenu.Image!=null" src={{this.SelectedMenu.Image}} class="body-icon" alt="Icon"/> -->
            </div>
            <div class="col-lg-10 clear-padding">
                <span class="page-title">Manage Level</span>
                <small class="page-description"></small>
            </div>
        </div>
    </div>
</div>

<div *ngIf="!(this.CanViewState)">  
<table id="tblLevel" datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" class="table table-striped table-bordered text-center table-new row-border hover">
    <thead class="thead-blue text-center">
        <tr>
            <th>Sl No.</th>
            <th>Level Code</th>
            <th class="text-left">Level Name</th>
            <th class="text-left">Decision Making</th>
            <th>Action</th>
        </tr>
    </thead>
    <tbody>
        <tr *ngFor="let L of Levels, let i = index">
            <td scope="row">{{i+1}}</td>
            <td>{{L.LevelCode}}</td>
            <td class="text-left">{{L.LevelName}}</td>
            <td class="text-left">{{L.DecisionName}}</td>
            <td>
                <button type="button"  *ngIf="this.SelectedMenu.CanEdit" (click)="GetLevelByid( L.LevelId )"class="table-edit-btn"><i class="fas fa-edit"></i> </button> 
                <button type="button" *ngIf="this.SelectedMenu.CanDelete"  (click)="DeleteLevelByid( L.LevelId )"class="table-remove-btn"><i class="fas fa-trash-alt"></i></button>
                <button *ngIf="this.SelectedMenu.CanView"   type="button" (click)="ViewLevelByid( L.LevelId )" class="table-view-button"  ><i class="fas fa-eye"></i></button>
                </td>
        </tr>
    </tbody>
</table>

<fieldset class="body-form-control">
    <app-dialog [(visible)]="this.SuccessStatus">
        <span class="successmsg">{{this.SuccessMessage}}</span>
    </app-dialog>
    
    <legend class="legend--">Level Creation</legend>
    <form #LevelForm="ngForm" (ngSubmit)="saveLevel()">
    <div class="row">
        <div class="col-lg-4">
            <div  class="col-body-border-right">
            <div class="form-group row" >
                <label class="col-sm-4 col-form-label clear-padding-right">Level Code</label>
                <div class="col-sm-7 clear-left-padding">
                <input type="text" [(ngModel)]="LevelModel.LevelCode" tabindex="1" maxlength="50" id ="LevelCode"  name="LevelCode" #LevelCode="ngModel" required class="form-control form-control-custom form-control-custom-new" >
                </div>
                <div *ngIf="LevelCode.invalid && (LevelCode.dirty || LevelCode.touched)" class="alert alert-danger">
                <div *ngIf="LevelCode.errors.required">Level Code is required.</div>
                <div *ngIf="LevelCode.errors.maxlength">Level Code is not more than 50 characters</div>
                </div> 
            </div>
            <div class="form-group row">
                    
            </div>
            </div>  
        </div>
        <div  class="col-lg-4">
            <div  class="col-body-border-right">
            <div class="form-group row">
                <label class="col-sm-4 col-form-label clear-padding-right">Level Name</label>
                <div class="col-sm-7 clear-left-padding">
                <input type="text" [(ngModel)]="LevelModel.LevelName" tabindex="2" maxlength="250" id ="LevelName" (blur)="CheckName()" name="LevelName" #LevelName="ngModel" required class="form-control form-control-custom form-control-custom-new" >
                </div>
                <div *ngIf="this.Namecheck.length > 0" class="alert alert-danger">Level Name is exist</div>
                <div *ngIf="LevelName.invalid && (LevelName.dirty || LevelName.touched)" class="alert alert-danger">
                <div *ngIf="LevelName.errors.required">Level Name is required.</div>
                <div *ngIf="LevelName.errors.maxlength">Level Name is not more than 250 characters</div>
                </div> 
            </div>
            <div class="form-group row">
                    
            </div>
            </div>
        </div>
        <div  class="col-lg-4">
            <div class="form-group row">
                <label  class="col-sm-4 col-form-label clear-padding-right">Decision Making</label>
                <div class="col-sm-7 clear-left-padding">
                    <select [(ngModel)]="LevelModel.DecisionId" tabindex="3" id ="DecisionId" class="form-control" (blur)="CheckName()" name="DecisionId" #DecisionId="ngModel" >
                        <option [value]="0" [disabled]=true >Select Decision Making</option>
                        <option *ngFor="let DM of Decisions" [value]="DM.DecisionId">{{DM.DecisionName}}</option>
                    </select>
                </div>
                <div class="required-validation" *ngIf="LevelModel.DecisionId==0">Decision Making is required</div>
            </div>
            <div class="form-group row">
            <label class="col-sm-6 col-form-label text-center clear-padding-right" *ngIf="!(this.IsDataIsInViewState)" >
                <button type="submit" tabindex="4" class="btn btn-primary save-button" [disabled]="!(LevelForm.valid && this.CheckNameExiststatus==true && LevelModel.DecisionId !=0 && this.DisableSave==true)" >Save</button>  
            </label>
            <div class="col-sm-6 col-form-label text-center">
                <button type="button" tabindex="5" (click)="RefreshPage()" class="btn btn-danger cancle-button">Cancel</button>
            </div>
            </div>
        </div>
    </div>
</form>
</fieldset>.
</div>
</div>