import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from '../../shared/user.service';
import { EmployeeMasterService }  from '../../shared/HR_ADMIN/Employee/EmployeeMaster.Service';
import { EmployeePortalService }  from '../../shared/HR_ADMIN/EmployeePortal/EmployeePortalService.service';
import { SalaryMasterService }  from '../../shared/HR_ADMIN/Salary/SalaryMaster.service';
import { EmployeeDetail } from '../../shared/HR_ADMIN/Employee/EmployeeDetail.Model';
import { StandardSalaryModel } from '../../shared/HR_ADMIN/Employee/StandardSalaryModel.Model'
import { EmployeeAttendanceModel } from '../../shared/HR_ADMIN/EmployeePortal/EmployeeAttendanceModel.Model';
import { PtaxSlabModel } from '../../shared/HR_ADMIN/Salary/PtaxSlabModel'
import { EPFESIModel } from '../../shared/HR_ADMIN/Admin/EPFESIModel.Model'
import { Menu } from '../../shared/menu.model';

@Component({
  selector: 'app-employee-ctcview',
  templateUrl: './employee-ctcview.component.html',
  styleUrls: ['./employee-ctcview.component.css']
})
export class EmployeeCtcviewComponent implements OnInit 
{
  EmployeeAttendanceModel : EmployeeAttendanceModel= new EmployeeAttendanceModel();
  StandardSalaryModel : StandardSalaryModel= new StandardSalaryModel();
  EmployeeAttendance: EmployeeAttendanceModel[]=[];
  
  StandardSalarys : StandardSalaryModel[]=[];
  StandardSalary: StandardSalaryModel[] = [];

  Attendance: any;
  userClaims: any;

  employee: any;

  Result : string;
  SuccessStatus:boolean=false;
  SuccessMessage: string ="";
  public tableWidget: any;
  selectedIndex : number;

  // Begning Permission Portion    
  SelectedMenu:Menu;
    // End of the permission portion

  // begin for Validation
  validdate : boolean= false;
  // end of validation

  tempEPFESIModel : EPFESIModel = new EPFESIModel();
  tempEPFESIModels : any;
  tempPtaxSlabModel : PtaxSlabModel = new PtaxSlabModel();
  tempPtaxSlabModels : any;
  ComponentTotal : number;
  ComponentSubTotal : number;
  Longtermbenefits : number;
  PFDed :number=0;
  PFCont :number=0;
  ESIDed :number=0;
  ESICont :number=0;
  MinBasicSalary : number=0;
  P_Tax : number;

  constructor(private EmployeePortalService: EmployeePortalService, private rout: Router, 
     private userService: UserService,private EmployeeMasterService: EmployeeMasterService,private EmpSalary: SalaryMasterService){ }

  ngOnInit() 
  {
    // Begning Permission Portion 
    this.SelectedMenu=this.userService.SelectedMenu;
    if(this.SelectedMenu !=undefined)
    {
      localStorage.setItem('selectedmenu',JSON.stringify(this.SelectedMenu));
      this.rout.navigate([this.SelectedMenu.RouteName]);
    }
    else
    {
      if(JSON.parse(localStorage.getItem('selectedmenu'))!=undefined)
      {
        this.SelectedMenu = JSON.parse(localStorage.getItem('selectedmenu'));
        this.rout.navigate([this.SelectedMenu.RouteName]);
      }
    }
    // End of the permission portion
    if(localStorage.getItem('SuccessfullMessage'))
    {
      this.SuccessStatus=true;
      this.SuccessMessage =localStorage.getItem('SuccessfullMessage')
      localStorage.removeItem('SuccessfullMessage');
    }
    this.GetEmployeeDetails();
    this.GetEpfEsi();
    this.GetEmployeeStandardSalaryByEmployeeId();
  }

  GetEmployeeDetails()
  {
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.EmployeePortalService.GetEmployeeDetailsByEmployeeId( t).then(x => 
    {
      this.employee = x;
      Object.keys(this.employee).forEach( key => 
      {
        this.employee[key];
        let employeetemp = new EmployeeDetail();
        employeetemp = this.employee[key];
        this.EmployeeAttendanceModel.EmployeeCode=this.employee.EmployeeCode;
        this.EmployeeAttendanceModel.EmployeeName=this.employee.EmployeeName;
      });
    });
  }
  
  GetEmployeeStandardSalaryByEmployeeId()
  {
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.EmployeeMasterService.GetEmployeeStandardSalaryByLoginuser( t).then(x => 
    {
      this.employee = x;
      {
        if(this.StandardSalary.length >0)
        {
          this.StandardSalary=[];
        } 
        this.ComponentTotal=0;
        this.ComponentSubTotal=0;
        this.Longtermbenefits = 0;
        Object.keys(this.employee).forEach( key => 
        {
          this.employee[key];
          let employeetemp = new StandardSalaryModel();
          employeetemp = this.employee[key];
          this.StandardSalaryModel.ESIApplicable=employeetemp.ESIApplicable;
          this.StandardSalaryModel.EPFApplicable=employeetemp.EPFApplicable;
          let BAmount = this.employee.find(x=>x.SalaryComponentCode=='BS').Amount;
          this.GetEPFCalculation(BAmount);
          if(employeetemp.IsBenefit==false)
          {              
            //this.ComponentSubTotal = this.ComponentSubTotal + employeetemp.Amount;  
            var tempvaluef =
            {
              a: Number(this.ComponentSubTotal),
              b: Number(employeetemp.Amount)  
            }
            this.ComponentSubTotal = tempvaluef.a + tempvaluef.b; 
          }
          else
          {
          // this.Longtermbenefits = this.Longtermbenefits +  employeetemp.Amount;
            var tempvaluef = 
            {
              a: Number(this.Longtermbenefits),
              b: Number(employeetemp.Amount)  
            }
            this.Longtermbenefits = tempvaluef.a + tempvaluef.b; 
          }
          this.StandardSalary.push(employeetemp);            
        });          
      }
      this.GetESICalculation(this.ComponentSubTotal);
    });
  }

  GetEPFCalculation( BAmount)
  {
    if(this.StandardSalaryModel.EPFApplicable==true)
    {
      this.MinBasicSalary=this.tempEPFESIModel.HighestBasicSalary;
      if(BAmount >= this.MinBasicSalary)
      {
        this.PFDed=(this.MinBasicSalary*this.tempEPFESIModel.EPFDed)/100;
        this.PFCont=(this.MinBasicSalary*this.tempEPFESIModel.EPFCont)/100;
      }
      else
      {
        this.PFDed=(BAmount * this.tempEPFESIModel.EPFDed)/100;
        this.PFCont=(BAmount * this.tempEPFESIModel.EPFCont)/100;
      }
    }
    else
    {
      this.PFDed=0.00;
      this.PFCont=0.00;
    }
  }

  GetESICalculation( GrossSalary)
  {
    if(this.StandardSalaryModel.ESIApplicable==true)
    {
      this.MinBasicSalary=this.tempEPFESIModel.HighestBasicSalary;
      if( GrossSalary >= this.MinBasicSalary)
      {
        this.ESIDed=0.00;
        this.ESICont=0.00;
      }
      else
      {
        this.ESIDed=(GrossSalary * this.tempEPFESIModel.ESIDed)/100;
        this.ESICont=(GrossSalary * this.tempEPFESIModel.ESICont)/100;
      } 
    }
    else
    {
      this.ESIDed=0.00;
      this.ESICont=0.00;
    }
  }

  GetEpfEsi()
  {
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.EmpSalary.GetEPFESISlabforOffer(t).then(y=>
    {
      this.tempEPFESIModels=y;
      Object.keys(this.tempEPFESIModels).forEach( key => 
      {
        this.tempEPFESIModel=this.tempEPFESIModels[key]; 
        this.tempEPFESIModel.EPFDed=this.tempEPFESIModel.EPFDed;
        this.tempEPFESIModel.EPFCont=this.tempEPFESIModel.EPFCont;
        this.tempEPFESIModel.ESIDed=this.tempEPFESIModel.ESIDed;
        this.tempEPFESIModel.ESICont=this.tempEPFESIModel.ESICont;
        this.tempEPFESIModel.HighestBasicSalary=this.tempEPFESIModel.HighestBasicSalary;                    
      }); 
    });
  }
}
