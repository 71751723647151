import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';  
import { Http, Headers, RequestOptions, Response } from '@angular/http';  
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import 'rxjs/add/operator/toPromise';
import { Subscriber } from 'rxjs';
import { GlobalVariable  } from '../../../app.global';


import { Model_CrmCountry } from './Model_CrmCountry';

    


@Injectable()   

export class CrmCountryService {    

  constructor(private http: HttpClient,private spinnerService: Ng4LoadingSpinnerService) 
  {
  }

    Shared_CountryName: Model_CrmCountry ;
     
    SaveOrUpdate : number =0;



//  
    AddCrmCountry(token, Add_Crm_Country: Model_CrmCountry) {
        const authHeader = new HttpHeaders();
        authHeader.append('Content-Type', 'application/json');
        authHeader.append('Authorization', token);
        authHeader.append('Accept', 'application/json');
        return this.http.post(GlobalVariable.BASE_API_URL + '/api/CrmCountry/Add', Add_Crm_Country, { headers: authHeader })
            .map(this.extractData)
            .catch(this.handleErrorObservable);
    }


    GetCRMCountry(token )
    {
        
        this.spinnerService.show();
        const authHeader = new HttpHeaders();     
        authHeader.append('Content-Type', 'application/json');
        authHeader.append('Authorization', token);
        return new Promise((resolve, reject) =>
        {
            setTimeout(()=>
            {
                
                this.http.get(GlobalVariable.BASE_API_URL +'/api/CRMCountry/GetCRMCountry',{headers: authHeader}).subscribe(data => 
                {
                    
                    this.spinnerService.hide();
                    resolve(data)
                },
                error => 
                {
                   // alert();
                    this.spinnerService.hide();
                    reject(error);
                },);
            },3000);
        });
    }


    UpdateCrmCountry(token, Add_Crm_Country: Model_CrmCountry) 
    {
        
       
        const authHeader = new HttpHeaders();         
        authHeader.append('Content-Type', 'application/json');
        authHeader.append('Authorization', token);
        authHeader.append('Accept', 'application/json');
       
        return this.http.post(GlobalVariable.BASE_API_URL + '/api/CrmCountry/Update', Add_Crm_Country ,{headers: authHeader} )
        .map(this.extractData)
        .catch(this.handleErrorObservable);
     }

    

    GetCRM_Country_By_id(token, id:number ) 
    {
        this.spinnerService.show(); 
        const authHeader = new HttpHeaders();     
        authHeader.append('Content-Type', 'application/json');
        authHeader.append('Authorization', token);
        return new Promise((resolve, reject) => 
        {   
            setTimeout(()=>
            {
                this.http.get(GlobalVariable.BASE_API_URL + '/api/CrmCountry/GetCrmCountryById?CountryId=' + id, { headers: authHeader })                               
                .subscribe(data => 
                {
                    this.spinnerService.hide();
                    resolve(data)
                },
                error => 
                {
                    this.spinnerService.hide();
                    reject(error);
                },);
            },3000);
        });
    }



   



    



//  Delete  a   Record  Starts

    Delete_CrmCountry( token ,ID : number) 
    {
        //alert();
        const authHeader = new HttpHeaders();         
        authHeader.append('Content-Type', 'application/json');
        authHeader.append('Authorization', token);
        authHeader.append('Accept', 'application/json');
        return this.http.put(GlobalVariable.BASE_API_URL +'/api/CrmCountry/Delete', ID ,{headers: authHeader} )
        .map(this.extractData)
        .catch(this.handleErrorObservable);
    }    

    //  Delete  a  record   Ends    






    

    // GetCRMType(token) {
    //     this.spinnerService.show();
    //     const authHeader = new HttpHeaders();
    //     authHeader.append('Content-Type', 'application/json');
    //     authHeader.append('Authorization', token);
    //     return new Promise((resolve, reject) => {
    //         setTimeout(() => {

    //             this.http.get(GlobalVariable.BASE_API_URL + '/api/CRMType/GetCRMType', { headers: authHeader })
    //                 .subscribe(data => {
    //                     this.spinnerService.hide();
    //                     resolve(data)
    //                 },
    //                     error => {
    //                         this.spinnerService.hide();
    //                         reject(error);
    //                     }, );
    //         }, 3000);
    //     });
    // }



    private extractData(res: Response) 
    {
        let body = res;
        return body || {};
    }
    private handleErrorObservable (error: Response | any) 
    {
        console.error(error.message || error);
        return Observable.throw(error.message || error);
    }


}
