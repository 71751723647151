import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';  
import { Http, Headers, RequestOptions, Response } from '@angular/http';  
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner'; 
import 'rxjs/add/operator/toPromise';
import { Subscriber } from 'rxjs';
import { GlobalVariable  } from '../../../app.global';


//import { Model_CRM_Role } from './ManageRole_Model';
import { Model_CRM_Zone } from './Model_CRM_Zone';

    


@Injectable()

export class CrmZoneService {

  constructor(private http: HttpClient,private spinnerService: Ng4LoadingSpinnerService) 
  {
  }


  //    Populate Drop  Down Starts

    GetCountryType(token) {
        this.spinnerService.show();
        const authHeader = new HttpHeaders();
        authHeader.append('Content-Type', 'application/json');
        authHeader.append('Authorization', token);
        return new Promise((resolve, reject) => {
            setTimeout(() => {

                this.http.get(GlobalVariable.BASE_API_URL + '/api/CRMCountry/GetCountryName', { headers: authHeader })
                    .subscribe(data => {
                        this.spinnerService.hide();
                        resolve(data)
                    },
                        error => {
                            this.spinnerService.hide();
                            reject(error);
                        }, );
            }, 3000);
        });
    }

  //  Populate Drop  Down Ends


    // Grid Data Fetch  

    Get_CRMZone(token) {
        this.spinnerService.show();
        const authHeader = new HttpHeaders();
        authHeader.append('Content-Type', 'application/json');
        authHeader.append('Authorization', token);
        return new Promise((resolve, reject) => {
            setTimeout(() => {
                this.http.get(GlobalVariable.BASE_API_URL + '/api/CRMZone/GetCRMZone',
                    { headers: authHeader }).subscribe(data => {
                        this.spinnerService.hide();
                        resolve(data)
                    },
                        error => {
                            this.spinnerService.hide();
                            reject(error);
                        }, );
            }, 3000);
        });
    }


    private extractData(res: Response) {
        let body = res;
        return body || {};
    }
    private handleErrorObservable(error: Response | any) {
        console.error(error.message || error);
        return Observable.throw(error.message || error);
    }



    //  Update  Section Starts

    GetCRM_Zone_By_id(token, id:number ) 
    {
        this.spinnerService.show(); 
        const authHeader = new HttpHeaders();     
        authHeader.append('Content-Type', 'application/json');
        authHeader.append('Authorization', token);
        return new Promise((resolve, reject) => 
        {   
            setTimeout(()=>
            {
                this.http.get(GlobalVariable.BASE_API_URL +'/api/CRMZone/GetCRMZoneById?ZoneId='+id,{headers: authHeader})
                .subscribe(data => 
                {
                    this.spinnerService.hide();
                    resolve(data)
                },
                error => 
                {
                    this.spinnerService.hide();
                    reject(error);
                },);
            },3000);
        });
    }

    //  Update  Section Ends    


    AddCrmZone(token, CRMZone: Model_CRM_Zone) 
    {
        const authHeader = new HttpHeaders();         
        authHeader.append('Content-Type', 'application/json');
        authHeader.append('Authorization', token);  
        authHeader.append('Accept', 'application/json');
        return this.http.post(GlobalVariable.BASE_API_URL + '/api/CrmZone/Add', CRMZone ,{headers: authHeader} )
        .map(this.extractData)
        .catch(this.handleErrorObservable);
    }



    UpdateCrmZone(token, CRMZone: Model_CRM_Zone) {

        const authHeader = new HttpHeaders();
        authHeader.append('Content-Type', 'application/json');
        authHeader.append('Authorization', token);
        authHeader.append('Accept', 'application/json');

        return this.http.post(GlobalVariable.BASE_API_URL + '/api/CrmZone/Update', CRMZone, { headers: authHeader })
            .map(this.extractData)
            .catch(this.handleErrorObservable);
    }



    

     

//  Delete  a   Record  Starts

    DeleteCRMZone( token ,ID : number) 
    {
   
        
        const authHeader = new HttpHeaders();         
        authHeader.append('Content-Type', 'application/json');
        authHeader.append('Authorization', token);
        authHeader.append('Accept', 'application/json');
        return this.http.put(GlobalVariable.BASE_API_URL +'/api/CrmZone/Delete', ID ,{headers: authHeader} )
        .map(this.extractData)
        .catch(this.handleErrorObservable);
    } 

    //  Delete  a  record   Ends    


    

   

 }
