import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Http, Headers, RequestOptions, Response } from '@angular/http';
//import { Observable } from 'rxjs/observable';
import { CrmMenuAccessUsers } from './Menu/CrmMenuAccessUsers'
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import 'rxjs/add/observable/forkjoin';
import 'rxjs/add/observable/throw';
import { User } from './user.model';
import { Menu } from './menu.model';
import 'rxjs/add/operator/toPromise';
import { Subscriber } from 'rxjs';
import { GlobalVariable } from '../app.global';

@Injectable()
export class UserService {
  readonly rootUrl = 'http://localhost:11678';
  moduls: any;

  selectdmoduleid: number;
  selectedmenuid: number;
  SelectedMenu: Menu;
  SelectedMenuIndex: number;

  //  for crm
  selectedCrmMenu: CrmMenuAccessUsers;
  SubMenuesCRM: CrmMenuAccessUsers[] = [];
  MainMenuCRM: CrmMenuAccessUsers[] = [];
  IsFormMainMenu: boolean;
  MainMenuUrl: string;
  //For Crm

  constructor(private http: HttpClient) { }

  userAuthentication(userName, password) {
    var data = "username=" + userName + "&password=" + password + "&grant_type=password";
    var reqHeader = new HttpHeaders({ 'Content-Type': 'application/x-www-urlencoded', 'No-Auth': 'True' });
    return this.http.post(GlobalVariable.BASE_API_URL + '/token', data, { headers: reqHeader });
  }

  getUserClaims() {
    return this.http.get(GlobalVariable.BASE_API_URL + '/api/GetUserClaims');
  }

  Modulelist(token) 
  {
    const authHeader = new HttpHeaders();
    authHeader.append('Content-Type', 'application/json');
    authHeader.append('Authorization', token);
    return new Promise((resolve, reject) => {
      this.http.get(GlobalVariable.BASE_API_URL + '/api/Nevigation/GetModules', { headers: authHeader })
      .subscribe(
        data => {
          resolve(data)
        },
        error => {
          reject(error);
        });

      // setTimeout(() => {
        
      // }, 1000);
    });
  }

  SubModulelist(token, moduleid) {
    const authHeader = new HttpHeaders();
    authHeader.append('Content-Type', 'application/json');
    authHeader.append('Authorization', token);
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        this.http.get(GlobalVariable.BASE_API_URL + '/api/Nevigation/GetSubModules?moduleid=' + moduleid, { headers: authHeader })
          .subscribe(
            data => {
              resolve(data)
            },
            error => {
              reject(error);
            });
      }, 1000);
    });
  }

  MainMenuList(token, moduleid) {
    const authHeader = new HttpHeaders();
    authHeader.append('Content-Type', 'application/json');
    authHeader.append('Authorization', token);
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        this.http.get(GlobalVariable.BASE_API_URL + '/api/Nevigation/GetMaimMenu?moduleid=' + moduleid, { headers: authHeader })
          .subscribe(
            data => {
              resolve(data)
            },
            error => {
              reject(error);
            });
      }, 1000);
    });
  }

  CrmMainMenuList(token, moduleid) {
    const authHeader = new HttpHeaders();
    authHeader.append('Content-Type', 'application/json');
    authHeader.append('Authorization', token);
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        this.http.get(GlobalVariable.BASE_API_URL + '/api/Nevigation/GeCrmtMaimMenu?moduleid=' + moduleid, { headers: authHeader })
          .subscribe(
            data => {
              resolve(data)
            },
            error => {
              reject(error);
            });
      }, 1000);
    });
  }

  GetCrmSubMenuList(token, menuid) {
    const authHeader = new HttpHeaders();
    authHeader.append('Content-Type', 'application/json');
    authHeader.append('Authorization', token);
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        this.http.get(GlobalVariable.BASE_API_URL + '/api/Nevigation/GetCrmSubMenu?menuid=' + menuid, { headers: authHeader })
          .subscribe(
            data => {
              resolve(data)
            },
            error => {
              reject(error);
            });
      }, 1000);
    });
  }

  GetForexMainMenuList(token, moduleid) {
    const authHeader = new HttpHeaders();
    authHeader.append('Content-Type', 'application/json');
    authHeader.append('Authorization', token);
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        this.http.get(GlobalVariable.BASE_API_URL + '/api/Nevigation/GetForexMaimMenu?moduleid=' + moduleid, { headers: authHeader })
          .subscribe(
            data => {
              resolve(data)
            },
            error => {
              reject(error);
            });
      }, 1000);
    });
  }

  GetForexSubMenuList(token, menuid) {
    const authHeader = new HttpHeaders();
    authHeader.append('Content-Type', 'application/json');
    authHeader.append('Authorization', token);
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        this.http.get(GlobalVariable.BASE_API_URL + '/api/Nevigation/GetForexSubMenu?menuid=' + menuid, { headers: authHeader })
          .subscribe(
            data => {
              resolve(data)
            },
            error => {
              reject(error);
            });
      }, 1000);
    });
  }
  
  GetSubMenuList(token, menuid) 
  {
    const authHeader = new HttpHeaders();
    authHeader.append('Content-Type', 'application/json');
    authHeader.append('Authorization', token);
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        this.http.get(GlobalVariable.BASE_API_URL + '/api/Nevigation/GetSubMenu?menuid=' + menuid, { headers: authHeader })
          .subscribe(
            data => {
              resolve(data)
            },
            error => {
              reject(error);
            });
      }, 1000);
    });
  }

  LoginDetails(token) {
    const authHeader = new HttpHeaders();
    authHeader.append('Content-Type', 'application/json');
    authHeader.append('Authorization', token);
    return new Promise((resolve, reject) => {
      //setTimeout(() => {
        this.http.get(GlobalVariable.BASE_API_URL + '/api/Nevigation/GetLoginDetails', { headers: authHeader })
          .subscribe(data => {
            resolve(data)
          },
            error => {
              reject(error);
            });
      //}, 1000);
    });
  }

  UpdateLogOutTime(token, RefId: Number) {
    const authHeader = new HttpHeaders();
    authHeader.append('Content-Type', 'application/json');
    authHeader.append('Authorization', token);
    authHeader.append('Accept', 'application/json');
    return this.http.put(GlobalVariable.BASE_API_URL + '/api/Nevigation/UpdateLogOutTime', RefId, { headers: authHeader })
      .map(this.extractData)
      //.catch(this.handleErrorObservable);
  }

  PasswordResetMailFarward(username)
  {
    var data = "username=" + username  + "&grant_type=password";
    var reqHeader = new HttpHeaders({ 'Content-Type': 'application/x-www-urlencoded','No-Auth':'True' });
    return this.http.post(GlobalVariable.BASE_API_URL + '/token', data, { headers: reqHeader });
  }

  //Begin New Dashboard
  GetSubMenuListForPageChange(token,moduleid, menuid,Submenuid) 
  {
    const authHeader = new HttpHeaders();
    authHeader.append('Content-Type', 'application/json');
    authHeader.append('Authorization', token);
    return new Promise((resolve, reject) => 
    {
      this.http.get(GlobalVariable.BASE_API_URL + '/api/Nevigation/GetSubMenuForDashboard?moduleid=' + moduleid +'&menuid='+menuid+'&Submenuid='+Submenuid, { headers: authHeader })
      .subscribe(data => 
      {
        resolve(data)
      },
      error => {
        reject(error);
      });
    });
  }
  GetNotificationALL(token) 
  {
    const authHeader = new HttpHeaders();
    authHeader.append('Content-Type', 'application/json');
    authHeader.append('Authorization', token);
    return new Promise((resolve, reject) => {
     // setTimeout(() => {
        this.http.get(GlobalVariable.BASE_API_URL + '/api/Nevigation/GetApplicationNotification', { headers: authHeader })
          .subscribe(
            data => {
              resolve(data)
            },
            error => {
              reject(error);
            });
      //}, 1000);
    });
  }
  GetEventMDMessageForDashBoard(token) 
  {
    const authHeader = new HttpHeaders();
    authHeader.append('Content-Type', 'application/json');
    authHeader.append('Authorization', token);
    return new Promise((resolve, reject) => {
      //setTimeout(() => {
        this.http.get(GlobalVariable.BASE_API_URL + '/api/EventMessage/GetEventMDMessageForDashBoard', { headers: authHeader })
          .subscribe(
            data => {
              resolve(data)
            },
            error => {
              reject(error);
            });
      //}, 1000);
    });
  }
  GETNotificationForFormRaise(token) 
  {
    const authHeader = new HttpHeaders();
    authHeader.append('Content-Type', 'application/json');
    authHeader.append('Authorization', token);
    return new Promise((resolve, reject) => {
      //setTimeout(() => {
        this.http.get(GlobalVariable.BASE_API_URL + '/api/Nevigation/GETNotificationForFormRaise', { headers: authHeader })
          .subscribe(
            data => {
              resolve(data)
            },
            error => {
              reject(error);
            });
      //}, 1000);
    });
  }
  GetEmployeeBirtDayForDashBoard(token) 
  {
    const authHeader = new HttpHeaders();
    authHeader.append('Content-Type', 'application/json');
    authHeader.append('Authorization', token);
    return new Promise((resolve, reject) => {
      //setTimeout(() => {
        this.http.get(GlobalVariable.BASE_API_URL + '/api/BirthdayAnniversary/GetEmployeeBirtDayForDashBoard', { headers: authHeader })
          .subscribe(
            data => {
              resolve(data)
            },
            error => {
              reject(error);
            });
      //}, 1000);
    });
  }
  GetEmployeeMarriageAnniversaryForDashBoard(token) 
  {
    const authHeader = new HttpHeaders();
    authHeader.append('Content-Type', 'application/json');
    authHeader.append('Authorization', token);
    return new Promise((resolve, reject) => {
      //setTimeout(() => {
        this.http.get(GlobalVariable.BASE_API_URL + '/api/BirthdayAnniversary/GetEmployeeMarriageAnniversaryForDashBoard', { headers: authHeader })
          .subscribe(
            data => {
              resolve(data)
            },
            error => {
              reject(error);
            });
      //}, 1000);
    });
  }
  GetEmployeeWorkAnniversaryForDashBoard(token) 
  {
    const authHeader = new HttpHeaders();
    authHeader.append('Content-Type', 'application/json');
    authHeader.append('Authorization', token);
    return new Promise((resolve, reject) => {
      //setTimeout(() => {
        this.http.get(GlobalVariable.BASE_API_URL + '/api/BirthdayAnniversary/GetEmployeeWorkAnniversaryForDashBoard', { headers: authHeader })
          .subscribe(
            data => {
              resolve(data)
            },
            error => {
              reject(error);
            });
     // }, 1000);
    });
  }
  //End New Dashboard
  private extractData(res: Response) {
    let body = res;
    return body || {};
  }

  // private handleErrorObservable(error: Response | any) {
  //   console.error(error.message || error);
  //   return Observable.throw(error.message || error);
  // }
}