<div class="body-content-area tabcontent" id="link1">
  <div class="row">
    <div class="col-lg-9">
      <div class="row">
        <div class="col-lg-1 low-padding text-center">
          <!-- <img *ngIf="this.SelectedMenu.Image!=null" src={{this.SelectedMenu.Image}} class="body-icon" alt="Icon"/> -->
        </div>
        <div class="col-lg-10 clear-padding">
          <div>
            <span class="page-title">Employee KRA Reports</span>
            <small class="page-description"></small>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="!(this.CanViewState)">
    <fieldset class="body-form-control" > 
      <legend class="legend--">Employee Details</legend>
      <form #EmployeeAttendanceForm="ngForm">
        <div class="row">
          <div class="col-lg-4">
            <div class="col-body-border-right">
              <div class="form-group row">
                <label class="col-sm-4 col-form-label clear-padding-right">Code</label>
                <div class="col-sm-7 clear-left-padding">
                  :&nbsp;&nbsp;{{this.KRAApplicationModel.EmployeeCode}}
                </div>
              </div>
              <div class="form-group row">
                <label class="col-sm-4 col-form-label clear-padding-right">Reporting</label>
                <div class="col-sm-7 clear-left-padding">
                  :&nbsp;&nbsp;{{this.KRAApplicationModel.RHName}}
                </div>
              </div>
            </div>
          </div>

          <div class="col-lg-4">
            <div class="col-body-border-right">
              <div class="form-group row">
                <label class="col-sm-4 col-form-label clear-padding-right">Name</label>
                <div class="col-sm-7 clear-left-padding">
                  :&nbsp;&nbsp;{{this.KRAApplicationModel.EmployeeName}}
                </div>
              </div>
              <div class="form-group row">
                <label class="col-sm-4 col-form-label clear-padding-right">Functional</label>
                <div class="col-sm-7 clear-left-padding">
                  :&nbsp;&nbsp;{{this.KRAApplicationModel.FHName}}
                </div>
              </div>
            </div>
          </div>

          <div class="col-lg-4">
            <div class="form-group row">
              <label class="col-sm-4 col-form-label clear-padding-right">Designation</label>
              <div class="col-sm-7 clear-left-padding">
                :&nbsp;&nbsp;{{this.KRAApplicationModel.DesignationName}}
              </div>
            </div>
          </div>
        </div>
      </form>

      <div *ngIf="NoRecordMsg" style="color: #dc3545; text-align: center;font-size: 15px; font-weight: bold;">KRA Reports is not Exists.</div>

      <table id="tblSession" *ngIf="(this.KRAApplicationSession.length > 0)" class="table table-striped table-bordered text-center table-new row-border hover">
        <thead class="thead-blue text-center">
          <tr>
            <th>Sl No.</th>
            <th class="text-center">Financial Year</th>
            <th class="text-center">Session</th>
            <th class="text-center">Submit Date</th>
            <th class="text-center">Self Rating(%)</th>
            <th class="text-center">RH Rating(%)</th>
            <th class="text-center">FH Rating(%)</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let appl of KRAApplicationSession, let i = index">
            <td scope="row">{{i+1}}</td>
            <td class="text-center">{{appl.FinYear}}</td>
            <td class="text-center">{{appl.KRASession}}</td>
            <td class="text-center">{{appl.SubmitDate | date : "dd-MMM-yyyy"}}</td>
            <td class="text-center">{{appl.SelfRating}}</td>
            <td class="text-center">{{appl.RHRating}}</td>
            <td class="text-center">{{appl.FHRating}}</td>
            <td>
              <button *ngIf="this.SelectedMenu.CanView" type="button" (click)="GetKRAReports(appl.MasterRefId,template)" class="table-view-button" type="button" ><i class="fas fa-eye"></i></button>
            </td>
          </tr>
        </tbody>
        <tfoot class="thead-blue text-center">
          <tr>
            <th></th>
            <th></th>
            <th></th>
            <th></th>
            <th></th>
            <th></th>
            <th></th>
            <th></th>
          </tr>
        </tfoot>
      </table>
    </fieldset>

    <div style=" box-align: center; width: 1500px;" >
      <ng-template #template>
        <div class="modal-header" style="background: #2a9bd7; max-width: 1500px;"> 
          <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()" style="color:#fff;">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div style="text-align: center; max-width: 1500px;">
          <div style=" width: 100%;" *ngIf="ShowPopup">
            <fieldset class="body-form-control" > 
              <legend class="legend--">KRA Details</legend>
              <form #KRADetailForm="ngForm">
                  <div lass="row" >
                    <table id="tblkra" class="table table-striped table-bordered text-left table-new row-border hover" style="width: 100%;">
                      <thead class="thead-blue text-center"s tyle="width: 100%;">
                        <tr>
                          <th style="width: 16%; text-align: left;">Question</th>
                          <th style="width: 8%; text-align: center;">Weighted(%)</th>  
                          <th style="width: 14%; text-align: left;">Rating Guideline</th>
                          <th style="width: 10%; text-align: left;">Achievement</th>
                          <th style="width: 8%; text-align: left;">Self Rating</th>
                          <th style="width: 10%; text-align: left;">Self Remarks</th>
                          <th *ngIf="(this.KRAApplicationModel.FinalRHRating > 0)" style="width: 8%; text-align: left;">RH Rating</th>
                          <th *ngIf="(this.KRAApplicationModel.FinalRHRating > 0)" style="width: 10%; text-align: left;">RH Remarks</th>
                          <th *ngIf="(this.KRAApplicationModel.FinalFHRating > 0)" style="width: 8%; text-align: left;">FH Rating</th>
                          <th *ngIf="(this.KRAApplicationModel.FinalFHRating > 0)" style="width: 8%; text-align: left;">FH Remarks</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr *ngFor="let kra of KRAApplications, let i = index">
                          <td>{{kra.KRAName}}</td>
                          <td style="text-align: center;">{{kra.Weighted}}</td>
                          <td>{{kra.RatingCriteria}}</td> 
                          <td>{{kra.Achievement}}</td> 
                          <td>{{kra.SelfRating}}</td>
                          <td>{{kra.SelfRemarks}}</td>
                          <td *ngIf="(this.KRAApplicationModel.FinalRHRating > 0)">{{kra.RHRating}}</td>
                          <td *ngIf="(this.KRAApplicationModel.FinalRHRating > 0)">{{kra.RHRemarks}}</td>
                          <td *ngIf="(this.KRAApplicationModel.FinalFHRating > 0)">{{kra.FHRating}}</td>
                          <td *ngIf="(this.KRAApplicationModel.FinalFHRating > 0)">{{kra.FHRemarks}}</td>
                        </tr>
                      </tbody>
                      <tfoot class="thead-blue text-center">
                          <tr>
                            <th style="text-align: right;">Total Wts(%)</th>
                            <th style="text-align: center;">{{this.KRAApplicationModel.TotalWateges}}</th>  
                            <th style="text-align: left;"></th>
                            <th style="text-align: right;">Self Rating(%)</th>
                            <th style="text-align: left;">{{this.KRAApplicationModel.FinalSelfRating}}</th>
                            <th style="text-align: right;">
                              <div *ngIf="(this.KRAApplicationModel.FinalRHRating > 0)">RH Rating(%)</div>
                              <div *ngIf="(this.KRAApplicationModel.FinalRHRating == 0)">FH Rating(%)</div>
                            </th>
                            <th *ngIf="(this.KRAApplicationModel.FinalRHRating > 0)" style="text-align: left;">{{this.KRAApplicationModel.FinalRHRating}}</th>
                            <th *ngIf="(this.KRAApplicationModel.FinalRHRating > 0)" style="text-align: right;">FH Rating(%)</th>
                            <th *ngIf="(this.KRAApplicationModel.FinalFHRating > 0)" style="text-align: left;">{{this.KRAApplicationModel.FinalFHRating}}</th>
                            <th *ngIf="(this.KRAApplicationModel.FinalFHRating > 0)" style="text-align: left;"></th>
                          </tr>
                        </tfoot>
                    </table>
                  </div>
                </form>
              </fieldset>
          </div>

          <button type="button" aria-label="Close" (click)="modalRef.hide()" style="background: #2a9bd7; outline: 0px none; border: 0px none; color: #fff; padding: 5px 10px; border-radius: 3px;">
            <span>Close</span>
          </button>             
        </div>
      </ng-template>
    </div>
  </div>
</div>