<div class="body-content-area tabcontent" id="link1">
  <div class="row">
      <div class="col-lg-9">
          <div class="row">
              <div class="col-lg-1 low-padding text-center">
                  <!-- <img *ngIf="this.SelectedMenu.Image!=null" src={{this.SelectedMenu.Image}} class="body-icon" alt="Icon"/> -->
              </div>
              <div class="col-lg-10 clear-padding">
                  <span class="page-title">Holidays Calendar Master</span>
                  <small class="page-description"></small>
              </div>
          </div>
      </div>
  </div>

  <div *ngIf="!(this.CanViewState)"> 
    <fieldset class="body-form-control">
      <legend class="legend--">Holidays Master</legend>
      <div>
        <div class="row">
        <div class="col-lg-4">
          <div  class="col-body-border-right">
            <div class="form-group row">
              <label  class="col-sm-4 col-form-label clear-padding-right">Company</label>
              <div class="col-sm-7 clear-left-padding">
                {{this.HolidaysModel.CompanyName}}
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-4">
          <div class="form-group row">
            <label  class="col-sm-4 col-form-label clear-padding-right">Branch</label>
            <div class="col-sm-7 clear-left-padding">
              {{this.HolidaysModel.BranchName}}
            </div>
          </div>
        </div>
      </div>
  
        <table id="tblHolidaysMaster" class="table table-striped table-bordered text-center table-new row-border hover">
            <thead class="thead-blue text-center">
                <tr style=" width: 100%;">
                    <th style=" width: 15%;">Sl No.</th>
                    <th style=" width: 15%;">Date</th>
                    <th style=" width: 15%;">Day</th>
                    <th style=" width: 40%;" class="text-left">Description</th>
                    <th style=" width: 15%;">Holiday Day</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let HM of HolidaysMaster, let i = index">
                    <td scope="row">{{i+1}}</td>
                    <td>{{HM.HolidayDate| date : "dd-MMM-yyyy"}}</td>
                    <td class="text-left">{{HM.HolidayDate| date : "EEEE"}}</td>
                    <td class="text-left">{{HM.HolidayDescription}}</td>
                    <td>{{HM.LeaveDay}}</td>
                </tr>
            </tbody>
        </table>
      </div>
    </fieldset>
  </div>
</div>