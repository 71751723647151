import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from '../../shared/user.service';
import { EmployeePortalService }  from '../../shared/HR_ADMIN/EmployeePortal/EmployeePortalService.service';
import { TourModel } from '../../shared/HR_ADMIN/EmployeePortal/TourModel.Model';
import { Menu } from '../../shared/menu.model';

@Component({
  selector: 'app-employee-tour-application',
  templateUrl: './employee-tour-application.component.html',
  styleUrls: ['./employee-tour-application.component.css']
})

export class EmployeeTourApplicationComponent implements OnInit 
{
  TourModel : TourModel= new TourModel();
  TourApplication: TourModel[]=[];
  Application: any;
  StatusValue : any;
  RefId : any;
  userClaims: any;
  Result : string;
  SuccessStatus:boolean=false;
  SuccessMessage: string ="";
  public tableWidget: any;
  selectedIndex : number;
  // Begning Permission Portion    
  SelectedMenu:Menu;
  // End of the permission portion
  // begin for Validation
  validFromdate : boolean= false;
  validTodate : boolean= false;
  validdate : boolean= false;
  DisableSave : boolean=false;
  // end of validation
  ShowMessage : boolean=false;

  constructor(private EmployeePortalService: EmployeePortalService, private rout: Router, private userService: UserService){ }

  ngOnInit() 
  {
    // Begning Permission Portion 
    this.SelectedMenu=this.userService.SelectedMenu;
    if(this.SelectedMenu !=undefined)
    {
      localStorage.setItem('selectedmenu',JSON.stringify(this.SelectedMenu));
      this.rout.navigate([this.SelectedMenu.RouteName]);
    }
    else
    {
      if(JSON.parse(localStorage.getItem('selectedmenu'))!=undefined)
      {
        this.SelectedMenu = JSON.parse(localStorage.getItem('selectedmenu'));
        localStorage.setItem('selectedmenu',JSON.stringify(this.SelectedMenu));
        this.rout.navigate([this.SelectedMenu.RouteName]);
      }
    }
    // End of the permission portion
    this.DisableSave=true;
    if(localStorage.getItem('SuccessfullMessage'))
    {
      this.SuccessStatus=true;
      this.SuccessMessage =localStorage.getItem('SuccessfullMessage')
      localStorage.removeItem('SuccessfullMessage');
    }
    this.GetEmployeeDetails();
    this.GetEmployeeTourStatus();
  }

  GetEmployeeTourStatus()
  {
    this.TourApplication=[];
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.EmployeePortalService.GetEmployeeTourStatus( t).then(x => 
    {
      this.Application = x;
      Object.keys(this.Application).forEach( key => 
      {
        this.Application[key];
        let Applicationtemp = new TourModel();
        Applicationtemp = this.Application[key];
        this.TourApplication.push(Applicationtemp);
      });
    });
  }

  GetEmployeeDetails()
  {
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.EmployeePortalService.GetEmployeeDetailsByUserLogingIdLeaveApply( t).then(x => 
    {
      this.Application = x;
      Object.keys(this.Application).forEach( key => 
      {
        this.Application[key];
        let Applicationtemp = new TourModel();
        Applicationtemp = this.Application[key];
        this.TourModel.EmployeeId=this.Application.EmployeeId;
        this.TourModel.EmployeeCode=this.Application.EmployeeCode;
        this.TourModel.EmployeeName=this.Application.EmployeeName;
        this.TourModel.EmployeeEmailId=this.Application.EmployeeEmailId;
        this.TourModel.RHId=this.Application.RHId;
        this.TourModel.RHCode=this.Application.RHCode;
        this.TourModel.RHName=this.Application.RHName;
        this.TourModel.RHEmailId=this.Application.RHEmailId;
        this.TourModel.CompanyId=this.Application.CompanyId;
      });
    });
  }

  FromDateChanged( date)
  {
    this.TourModel.FromDate=date;
    if(this.TourModel.FromDate > this.TourModel.ToDate)
    {
      this.validdate=true;
    }
    else
    {
      var CurrentDate = new Date();
      var currentMonth = CurrentDate.getMonth();
      var CurrentDay = CurrentDate.getDate();
      
      var selectedDate = new Date(date);
      var selectedMonth=selectedDate.getMonth();
      var selectedDay=selectedDate.getDate();

      let time = new Date().getHours() + ':' + new Date().getMinutes() + ':'+  new Date().getSeconds();

      let selecteddateString = selectedDate.toDateString();
      let newselectedDate = new Date(selecteddateString);
      let CurrentdateString = CurrentDate.toDateString(); 
      let newCurrentDate = new Date(CurrentdateString);
      if (newselectedDate >= newCurrentDate)
      {
        this.validFromdate = false;
      }
      else if (currentMonth == selectedMonth)
      {
        if(CurrentDay == 26)
        {
          if('12:00:59' <= time)
          {
            this.validFromdate = true;
          }
          else
          {
            this.validFromdate = false;
          }
        }
        else if(CurrentDay <=  25)
        {
          this.validFromdate = false;
        }
        else
        {
          if(selectedDay > 25)
          {
            this.validFromdate = false;
          }
          else
          {
            this.validFromdate = true;
          }
        }
      }
      else if (currentMonth == 0 && selectedMonth == 11)
      {
        if(selectedDay >= 26 )
        {
          this.validFromdate = false;
        }
        else
        {
          this.validFromdate = true;
        }
      }
      else if ((currentMonth-1) == selectedMonth)
      {
        if(selectedDay >= 26 )
        {
          this.validFromdate = false;
        }
        else
        {
          this.validFromdate = true;
        }
      }
      else
      {
        this.validFromdate = true;
      }
      
      if(this.TourModel.FromDate != null && this.TourModel.ToDate != null && this.validFromdate ==false)
      {
        this.CheckTourApplicationDateexists(this.TourModel.FromDate,this.TourModel.ToDate);
      }
    }
  }

  ToDateChanged( date)
  {
    this.TourModel.ToDate=date;
    var CurrentDate = new Date();
    var currentMonth = CurrentDate.getMonth();
    var CurrentDay = CurrentDate.getDate();
    
    var selectedDate = new Date(date);
    var selectedMonth=selectedDate.getMonth();
    var selectedDay=selectedDate.getDate();

    let selecteddateString = selectedDate.toDateString();
    let newselectedDate = new Date(selecteddateString);
    let CurrentdateString = CurrentDate.toDateString(); 
    let newCurrentDate = new Date(CurrentdateString);

    let time = new Date().getHours() + ':' + new Date().getMinutes() + ':'+  new Date().getSeconds();

    if (newselectedDate >= newCurrentDate)
    {
      this.validTodate = false;
    }
    else if(CurrentDay == 26)
    {
      if('12:00:59' <= time)
      {
        this.validTodate = true;
      }
      else
      {
        this.validTodate = false;
      }
    }
    else if (currentMonth == selectedMonth)
    {
      if(CurrentDay <=  25)
      {
        this.validTodate = false;
      }
      else
      {
        if(selectedDay > 25)
        {
          this.validTodate = false;
        }
        else
        {
          this.validTodate = true;
        }
      }
    }
    else if (currentMonth == 0 && selectedMonth == 11)
    {
      if(selectedDay >= 26 )
      {
        this.validTodate = false;
      }
      else
      {
        this.validTodate = true;
      }
    }
    else if ((currentMonth-1) == selectedMonth)
    {
      if(selectedDay >= 26 )
      {
        this.validTodate = false;
      }
      else
      {
        this.validTodate = true;
      }
    }
    else
    {
      this.validTodate = true;
    }

    if(this.TourModel.FromDate > this.TourModel.ToDate)
    {
      this.validdate=true;
    }
    else
    {
      this.validdate=false;
      if(this.TourModel.FromDate != null && this.TourModel.ToDate != null && this.validTodate == false)
      {
        this.CheckTourApplicationDateexists(this.TourModel.FromDate,this.TourModel.ToDate);
      }
    }
  }

  SaveEmployeeTour() :void
  {
    if(this.TourModel.TourPlace.length > 0 && this.validFromdate == false && this.validTodate == false && this.ShowMessage== false && this.validdate==false && this.DisableSave ==true)
    {
      this.DisableSave=false;
      let t =  localStorage.getItem('userToken');
      t = 'Bearer' + t;
      this.EmployeePortalService.AddEmployeeTour(t ,this.TourModel).subscribe(res=>
      {
        this.RefId=res;
        this.TourModel.TourId=0;              
        if(this.RefId>0)
        {
          localStorage.setItem('SuccessfullMessage', "Save Successfully");
          this.rout.navigateByUrl('/EmployeePortal/PersonalInformation', {skipLocationChange: true}).then(()=>this.rout.navigate(["/EmployeePortal/TourApplication"]));
        }
      }); 
    }
  }

  RefreshPage()
  {
    this.rout.navigateByUrl('/EmployeePortal/PersonalInformation', {skipLocationChange: true}).then(()=>this.rout.navigate(["/EmployeePortal/TourApplication"]));
  }

  CheckTourApplicationDateexists(FromDate,ToDate)
  {
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.EmployeePortalService.CheckTourApplicationDateexists( t,FromDate,ToDate,this.TourModel.EmployeeId).then(res => 
    {
      this.StatusValue = res;
      if(this.StatusValue == 1)
      {
        this.ShowMessage=true;
      }
      else
      {
        this.ShowMessage = false;
      }
    });
  }
}
