export class EmployeeBudgetModel 
{
    public RefId : number;
    public EmployeeId : number;
    public EmployeeCode :string;
    public EmployeeName :string;
    public OpeningAmount : number;
    public CreditAmount : number;
    public DebitAmount : number;
    public ClosingAmount : number;
    public TourAdvanceAmount : number;
    public TourFinalAmount : number;
    public AvailableAmount : number;
    public NetAvailableAmount : number;
    public Remarks : string;
}