<div class="body-content-area tabcontent" id="link1">
  <div class="row">
    <div class="col-lg-9">
      <div class="row">
        <div class="col-lg-1 low-padding text-center">
          <!-- <img *ngIf="this.SelectedMenu.Image!=null" src={{this.SelectedMenu.Image}} class="body-icon" alt="Icon" /> -->
        </div>
        <div class="col-lg-10 clear-padding">
          <div>
            <span class="page-title">Salary Sheet Detail Monthly Wise</span>
            <small class="page-description"></small>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="!(this.CanViewState)" style=" height:100%;">
    <fieldset class="body-form-control">
      <legend class="legend--"></legend>
      <form #EmployeeAttendanceForm="ngForm">
        <div class="row">
          <div class="col-lg-4">
            <div class="col-body-border-right">
              <div class="form-group row">
                <label class="col-sm-4 col-form-label clear-padding-right">Company</label>
                <div class="col-sm-7 clear-left-padding">
                  <select [(ngModel)]="EmployeeAttendanceModel.CompId" (ngModelChange)="onChangeCompany($event)"
                    tabindex="1" id="CompId" class="form-control" name="CompId" #CompId="ngModel">
                    <option [value]="0">Select Company</option>
                    <option *ngFor="let comp of Companies" [value]="comp.CompId">{{comp.CompName}}</option>
                  </select>
                </div>
              </div>
              <!-- <div class="form-group row">
                <label class="col-sm-4 col-form-label clear-padding-right">Employee List</label>
                <div class="col-lg-11">
                  <angular2-multiselect class="form-control" [data]="EmployeeList" [(ngModel)]="EmployeeselectedItems"
                    name="EmployeeselectedItems" [settings]="EmployeeSettings" (onSelect)="onItemSelect($event)"
                    (onDeSelect)="OnItemDeSelect($event)" (onSelectAll)="onSelectAll($event)"
                    (onDeSelectAll)="onDeSelectAll($event)">
                  </angular2-multiselect>
                </div>
              </div> -->
              <div class="form-group row">
                <label class="col-sm-4 col-form-label clear-padding-right">Year</label>
                <div class="col-sm-7 clear-left-padding">
                  <select [(ngModel)]="EmployeeAttendanceModel.Year" id="Year" class="form-control" name="Year"
                    #Year="ngModel">
                    <option [value]="0" [disabled]=true>Select Year</option>
                    <option *ngFor="let FY of FinancialYears" [value]="FY.Year">{{FY.Year}}</option>
                  </select>
                </div>
                <div class="required-validation" *ngIf="EmployeeAttendanceModel.Year==0">Year is required</div>
              </div>
            </div>
          </div>
          <div class="col-lg-4">
            <div class="col-body-border-right">
              <div class="form-group row">
                <label class="col-sm-4 col-form-label clear-padding-right">Branch</label>
                <div class="col-sm-7 clear-left-padding">
                  <select [(ngModel)]="EmployeeAttendanceModel.BranchId" (ngModelChange)="onchangeBranch($event)"
                    tabindex="2" id="BranchId" class="form-control" name="BranchId" #BranchId="ngModel">
                    <option [value]="0">Select Branch</option>
                    <option *ngFor="let Bran of Branches" [value]="Bran.BranchId">{{Bran.BranchName}}</option>
                  </select>
                </div>
              </div>
              <div class="form-group row">
                <label class="col-sm-4 col-form-label clear-padding-right">Month</label>
                <div class="col-sm-7 clear-left-padding">
                  <select [(ngModel)]="EmployeeAttendanceModel.MonthId" id="MonthId" class="form-control"
                    name="MonthId" #MonthId="ngModel">
                    <option [value]="0" [disabled]=true>Select Month</option>
                    <option *ngFor="let Mo of Months" [value]="Mo.MonthId">{{Mo.MonthName}}</option>
                  </select>
                </div>
                <div class="required-validation" *ngIf="EmployeeAttendanceModel.MonthId==0">Month is required</div>
              </div>


            </div>
          </div>
          <div class="col-lg-4">
            <div class="form-group row">
              <label class="col-sm-4 col-form-label clear-padding-right">Department</label>
              <div class="col-sm-7 clear-left-padding">
                <select [(ngModel)]="EmployeeAttendanceModel.DepartmentId" tabindex="3"
                  (ngModelChange)="onchangeDepartment($event)" id="DepartmentId" class="form-control"
                  name="DepartmentId" #DepartmentId="ngModel">
                  <option [value]="0">Select Department</option>
                  <option *ngFor="let d of Departments" [value]="d.DepartmentId">{{d.DepartmentName}}</option>
                </select>
              </div>
            </div>


            <div class="form-group row">
              <label class="col-sm-6 col-form-label text-center clear-padding-right"
                *ngIf="!(this.IsDataIsInViewState)">
                <button type="button" (click)="submitReport()" class="btn btn-primary save-button"
                  [disabled]="!(EmployeeAttendanceForm.valid)">Generate Reports</button>
              </label>
              <div class="col-sm-6 col-form-label text-center">
                <button type="button" class="btn btn-danger cancle-button" (click)="RefreshPage()">Cancel</button>
              </div>
            </div>
          </div>
        </div>
      </form>

      <!-- <div *ngIf="NoRecordOfferMsg" style="color: #dc3545; text-align: center;font-size: 15px; font-weight: bold;">No
        Attendance Record Found.</div> -->

    </fieldset>
  </div>
</div>