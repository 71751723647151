import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs/Subject';
import { DataTableDirective } from 'angular-datatables';
import { UserService } from '../../shared/user.service';
import { AssetsMasterService} from '../../shared/HR_ADMIN/Assets/AssetsMaster.service';
import { AssetsTypeModel } from  '../../shared/HR_ADMIN/Assets/AssetsType.model';
// Begning Confirmation
import { ConfirmationService } from '@jaspero/ng2-confirmations';
import { ResolveEmit } from '../../../app/resolve-emit';
import { ConfirmSettings } from '../../../app/confirm-settings';
import { Menu } from '../../shared/menu.model';
// end of Confirmation

@Component({
  selector: 'app-assets-type',
  templateUrl: './assets-type.component.html',
  styleUrls: ['./assets-type.component.css']
})
export class AssetsTypeComponent implements OnInit 
{
  AssetsTypeModel : AssetsTypeModel= new AssetsTypeModel();
  userClaims: any;
  AssetsTypes: AssetsTypeModel[] = [];
  AssetsType: any;
  Result : string;
  SubMenues : Menu[]=[];
  Mod: any;
  SuccessStatus:boolean=false;
  SuccessMessage: string ="";
  public tableWidget: any;
  AssetsTypeId : any;
  base64textString : string;
  tempAssetsTypeadd : AssetsTypeModel =new AssetsTypeModel();
  // Begning Permission Portion    
  SelectedMenu:Menu;
  IsDataIsInViewState : boolean=false;
  CanViewState : boolean=false;
  DisableSave : boolean=false;
  // End of the permission portion
  //check for duplicate component Name
  CheckNameExiststatus : boolean=null;  
  CheckNamestatusedit : boolean =false;
  Namecheck : string ="";
  checkName : any;
  checkNameval : string ="";
  //check for duplicate component Name

  constructor(private AssetsMasterService: AssetsMasterService, private rout: Router,  private _confirmation: ConfirmationService, private userService: UserService) { }
  
  @ViewChild(DataTableDirective, {static: false})
  setDtOptions: DataTables.Settings = {};
  cardDtOptions: DataTables.Settings = {};
  dtElement: DataTableDirective;
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();
  
  ngOnInit() 
  {
    // Begning Permission Portion 
    this.SubMenues=[];
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    let menuid = localStorage.getItem("menuidstor");
    this.userService.GetSubMenuList( t, menuid).then(x => 
    {
      this.Mod = x;
      Object.keys(this.Mod).forEach( key => 
      {
        // tslint:disable-next-line:no-unused-expression
        this.Mod[key];
        let menutemp = new Menu();
        menutemp = this.Mod[key];
        this.SubMenues.push(menutemp);
      });
      this.userService.SelectedMenu= this.SubMenues[0];
      this.rout.navigate([this.userService.SelectedMenu.RouteName]);
      this.SelectedMenu=this.userService.SelectedMenu;
      if(this.SelectedMenu.CanAdd)
      {
        this.IsDataIsInViewState=false;
      }
      else
      {
        this.IsDataIsInViewState=true;
      }
      if(this.SelectedMenu.CanView)
      {
        this.CanViewState=false;
      }
      else
      {
        this.CanViewState=true;
      }
    });
    // End of the permission portion
    this.DisableSave=true;
    this.dtOptions = 
    {
      pagingType: 'full_numbers',
      pageLength: 6
    };
    if(localStorage.getItem('SuccessfullMessage'))
    {
      this.SuccessStatus=true;
      this.SuccessMessage =localStorage.getItem('SuccessfullMessage')
      localStorage.removeItem('SuccessfullMessage');
    }  
  }

  ngAfterViewInit(): void 
  {
    this.GetAssetsType();
    this.dtOptions = 
    {
      pagingType: 'full_numbers',
      pageLength: 6
    };
  }

  settings: ConfirmSettings | any = 
  {
    overlay: true,
    overlayClickToClose: true,
    showCloseButton: true,
    confirmText: 'Yes',
    declineText: 'No'
  };
  
  GetAssetsType()
  {
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.AssetsMasterService.GetAssetsType( t).then(x => 
    {
      this.AssetsType = x;
      Object.keys(this.AssetsType).forEach( key => 
      {
        this.AssetsType[key];
        let AssetsTypetemp = new AssetsTypeModel();
        AssetsTypetemp = this.AssetsType[key];
        this.AssetsTypes.push(AssetsTypetemp);
      });
      this.dtTrigger.next();
    });
  }
  
  GetAssetsTypeById(id) 
  { 
    // Begning of the permission portion
    this.IsDataIsInViewState=false;
    // End of the permision portion
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.AssetsMasterService.GetAssetsTypeById( t,id).then(x => 
    {
      this.AssetsTypeId = x;
      Object.keys(this.AssetsTypeId).forEach( key => 
      {
        // tslint:disable-next-line:no-unused-expression
        this.AssetsTypeId[key];
        let AssetsTypetemp = new AssetsTypeModel();
        AssetsTypetemp = this.AssetsTypeId[key];
        this.AssetsTypeModel.RefId=AssetsTypetemp.RefId;
        this.AssetsTypeModel.AssetsName=AssetsTypetemp.AssetsName;
      });
      // Begin for check Name duplication
      this.checkNameval= this.AssetsTypeModel.AssetsName;
      this.CheckNamestatusedit=true;
      this.CheckNameExiststatus =true;
      this.Namecheck=""
      // End for check Name duplication
    });
  }

  viewAssetsTypeByid(id) 
  {
    //begin permission
    this.IsDataIsInViewState=true;
    //end permission
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.AssetsMasterService.GetAssetsTypeById( t,id).then(x => 
    {
      this.AssetsTypeId = x;
      Object.keys(this.AssetsTypeId).forEach( key => 
      {
        // tslint:disable-next-line:no-unused-expression
        this.AssetsTypeId[key];
        let AssetsTypetemp = new AssetsTypeModel();
        AssetsTypetemp = this.AssetsTypeId[key];
        this.AssetsTypeModel.RefId=AssetsTypetemp.RefId;
        this.AssetsTypeModel.AssetsName=AssetsTypetemp.AssetsName;
      });
      // Begin for check Name duplication
      this.checkNameval= this.AssetsTypeModel.AssetsName;
      this.CheckNamestatusedit=true;
      this.CheckNameExiststatus =true;
      this.Namecheck=""
      // End for check Name duplication
    });
  }

  saveAssetsType() :void
  {
    this.DisableSave=false;
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.AssetsTypeModel.IsActive=true;
    if(this.AssetsTypeModel.RefId > 0)
    {
      this.AssetsMasterService.UpdateAssetsType(t ,this.AssetsTypeModel).subscribe(res=>
      {
        this.AssetsTypeId=res;
        this.AssetsTypeModel.RefId=0;  
        if(this.AssetsTypeId>0)
        {
          localStorage.setItem('SuccessfullMessage', "Update Successfully");
          this.rout.navigateByUrl('/AssetsMaster/EmployeeAssets', {skipLocationChange: true}).then(()=>this.rout.navigate(["/AssetsMaster/AssetsType"]));
        }
      });                  
    }
    else
    {
      this.AssetsMasterService.AddAssetsType(t ,this.AssetsTypeModel).subscribe(res=>
      {
        this.AssetsTypeId=res;
        this.AssetsTypeModel.RefId=0;              
        if(this.AssetsTypeId>0)
        {
          localStorage.setItem('SuccessfullMessage', "Save Successfully");
          this.rout.navigateByUrl('/AssetsMaster/EmployeeAssets', {skipLocationChange: true}).then(()=>this.rout.navigate(["/AssetsMaster/AssetsType"]));
        }
      }); 
    }
  }

  DeleteAssetsTypeByid(AssetsTypeid : number) :void
  {
    if(this.SelectedMenu.CanAdd)
    {
     this.IsDataIsInViewState=false;
    }
    else
    {
      this.IsDataIsInViewState=true;
    }
    this._confirmation.create('Confirm to Deleate', 'Press Yes to Delete', this.settings)
      .subscribe((ans: ResolveEmit) => 
      {
        if (ans.resolved == true) 
        {
          let t =  localStorage.getItem('userToken');
          t = 'Bearer' + t;
          this.AssetsTypeModel.IsActive=true;
          if( AssetsTypeid > 0)
          {
            this.AssetsMasterService.DeleteAssetsType(t ,AssetsTypeid).subscribe(res=>
            {
              this.AssetsTypeId=res;
              this.AssetsTypeModel.RefId=0;                                  
              if(this.AssetsTypeId >0)
              {
                localStorage.setItem('SuccessfullMessage', "Deleted Successfully");
                this.rout.navigateByUrl('/AssetsMaster/EmployeeAssets', {skipLocationChange: true}).then(()=>this.rout.navigate(["/AssetsMaster/AssetsType"]));
              }   
            });                  
          }
        }
      })
  }
 
  // begning of check duplicate componentcode
  CheckName()
  {
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    if(!this.CheckNamestatusedit)
    {
      this.AssetsMasterService.CheckAssetName( t, this.AssetsTypeModel.AssetsName).then(x => 
      {
        this.checkName = x;
        if( this.checkName.length >0)
        {
          this.CheckNameExiststatus =false;
          this.Namecheck="Exist"
        }
        else
        {
          this.CheckNameExiststatus =true;
          this.Namecheck="";
        }
     });
    }
    else
    {
      if(this.AssetsTypeModel.AssetsName !=this.checkNameval )
      {
        this.AssetsMasterService.CheckAssetName( t, this.AssetsTypeModel.AssetsName).then(x => 
        {
          this.checkName = x;
          if( this.checkName.length >0)
          {
            this.CheckNameExiststatus =false;
            this.Namecheck="Exist"
          }
          else
          {
            this.CheckNameExiststatus =true;
            this.Namecheck="";
          }
        });
      }
      else
      {
        this.CheckNameExiststatus =true;
        this.Namecheck="";
      }
    }
  }
  // end of duplicate Name

  RefreshPage()
  {
    this.rout.navigateByUrl('/AssetsMaster/EmployeeAssets', {skipLocationChange: true}).then(()=>this.rout.navigate(["/AssetsMaster/AssetsType"]))
  }
}
