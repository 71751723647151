
import { Component, OnInit, ViewChild, ContentChild } from '@angular/core';
import { Menu } from '../../../../shared/menu.model';
import { UserService } from '../../../../shared/user.service';
import { CrmLeadService } from '../../../../shared/CRM/MICECRM/LEADCRM/CrmLeadService';
import { Router } from '@angular/router';
import { Model_CRM_Lead } from '../../../../shared/CRM/MICECRM/LEADCRM/Model_CRM_LEAD';
import { Model_CrmCountry } from '../../../../shared/CRM/MICECRM/Model_CrmCountry';
import { Model_CRM_State } from '../../../../shared/CRM/MICECRM/Model_CRM_State';
import { Model_CRM_City } from '../../../../shared/CRM/MICECRM/Model_CRM_City';
import { Model_Crm_List } from '../../../../shared/CRM/MICECRM/LEADCRM/Model_Crm_List';
import { Model_CRM_LeadShared } from '../../../../shared/CRM/MICECRM/LEADCRM/Model_CRM_LeadShared';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { ConfirmationService } from '@jaspero/ng2-confirmations';
import { ConfirmSettings } from '@jaspero/ng2-confirmations/src/interfaces/confirm-settings';
import { ResolveEmit } from '@jaspero/ng2-confirmations/src/interfaces/resolve-emit';
import { ModalOptions, BsModalRef, BsModalService } from 'ngx-bootstrap/modal'
import { DialogComponent } from '../../../../../app/dialog/dialog.component';
//import { MiceCrmBasicLeadInfoComponent } from './mice-crm-basic-lead-info.component'
//import { AsyncAction } from 'rxjs/scheduler/AsyncAction';
import { CrmOppertunityService } from '../../../../shared/CRM/MICECRM/Oppertunity/CrmOppertunityService';
import { Model_CRM_Assigne } from '../../../../shared/CRM/MICECRM/Model_CRM_Assigne';
import { ConfirmationDialogService } from '../../../../shared/CRM/MICECRM/confirmation-dialog/confirmation-dialog.service'
import { PagerService } from '../../../../shared/CRM/MICECRM/LEADCRM/PagerService'
//import { stringify } from '@angular/core/src/render3/util';

@Component({
  selector: 'app-mail-to-client',
  templateUrl: './mail-to-client.component.html',
  styleUrls: ['./mail-to-client.component.css']
})
export class MailToClientComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
