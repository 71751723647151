export class UserModel
{
    public Id:number;
    public UserId:string;
    public Password:string;
    public EmployeeId:number;
    public RoleId :number;
    public RoleName:string;
    public RoleNames:string;
    public RoleIds:string;
    public IsActive:boolean;
    public RePassword:string;
    public CompId :number;
    public CompName :string;
    public BranchId :number;
    public BranchName:string;
    public BranchNames:string;
    public BranchIds:string;
    public CompanyIds:string;
    public MainPassword : string;
    public EmailId : string;
    public EmployeeName : string;
    public EmployeeCode : string;
    public CurrentPassword : string;
}