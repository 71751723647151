import { Injectable,Inject } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';  
import { Http, Headers, RequestOptions, Response } from '@angular/http';  
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import 'rxjs/add/operator/toPromise';
import { Subscriber } from 'rxjs';
import { GlobalVariable  } from '../../../app.global';
import { HolidaysModel } from   '../Holidays/HolidaysModel.Model';

@Injectable()
export class HolidaysMasterService 
{
    constructor(private http: HttpClient,private spinnerService: Ng4LoadingSpinnerService) 
    {
    }
    // Begning of the Holidays Master
    GetHolidaysMaster(token, CompanyId:number, BranchId:number )
    {
        this.spinnerService.show();
        const authHeader = new HttpHeaders();     
        authHeader.append('Content-Type', 'application/json');
        authHeader.append('Authorization', token);
        return new Promise((resolve, reject) =>
        {
            setTimeout(()=>{
            this.http.get(GlobalVariable.BASE_API_URL +'/api/HolidaysMaster/GetHolidaysMaster?CompanyId='+CompanyId + '&BranchId='+BranchId,{headers: authHeader})
            .subscribe(data => 
            {
                this.spinnerService.hide();
                resolve(data)
            },
            error => 
            {
                this.spinnerService.hide();
                reject(error);
            },);

        },3000);


        });
    }
    GetHolidaysMasterByid(token, id:number ) 
    {
        this.spinnerService.show();
        const authHeader = new HttpHeaders();     
        authHeader.append('Content-Type', 'application/json');
        authHeader.append('Authorization', token);
        return new Promise((resolve, reject) => 
        {
            setTimeout(()=>{
            this.http.get(GlobalVariable.BASE_API_URL +'/api/HolidaysMaster/GetHolidaysMasterByRefId?RefId='+id,{headers: authHeader})
            .subscribe(data => 
            {
                this.spinnerService.hide();
                resolve(data)
            },
            error => 
            {
                this.spinnerService.hide();
                reject(error);
            },);
        },3000);
        });
    }
    AddHolidaysMaster( token ,HolidaysMaster :HolidaysModel) 
    {
        const authHeader = new HttpHeaders();         
        authHeader.append('Content-Type', 'application/json');
        authHeader.append('Authorization', token);
        authHeader.append('Accept', 'application/json');
        return this.http.post(GlobalVariable.BASE_API_URL +'/api/HolidaysMaster/Add', HolidaysMaster ,{headers: authHeader} )
        .map(this.extractData)
        .catch(this.handleErrorObservable);
    }
    DeleteHolidaysMaster( token ,RefId : number) 
    {
        const authHeader = new HttpHeaders();         
        authHeader.append('Content-Type', 'application/json');
        authHeader.append('Authorization', token);
        authHeader.append('Accept', 'application/json');
        return this.http.put(GlobalVariable.BASE_API_URL +'/api/HolidaysMaster/Delete', RefId ,{headers: authHeader} )
        .map(this.extractData)
        .catch(this.handleErrorObservable);
    }
    UpdateHolidaysMaster( token ,HolidaysMaster :HolidaysModel) 
    {
        const authHeader = new HttpHeaders();         
        authHeader.append('Content-Type', 'application/json');
        authHeader.append('Authorization', token);
        authHeader.append('Accept', 'application/json');
        return this.http.put(GlobalVariable.BASE_API_URL +'/api/HolidaysMaster/Update', HolidaysMaster ,{headers: authHeader} )
        .map(this.extractData)
        .catch(this.handleErrorObservable);
    }
    CheckDuplicateData(token, HolidayDate: Date, CompanyId:number,BranchId:number ) 
    {
        this.spinnerService.show();
        const authHeader = new HttpHeaders();     
        authHeader.append('Content-Type', 'application/json');
        authHeader.append('Authorization', token);
        return new Promise((resolve, reject) => 
        {
            setTimeout(()=>{
            this.http.get(GlobalVariable.BASE_API_URL +'/api/HolidaysMaster/CheckDuplicateData?HolidayDate='+HolidayDate+'&CompanyId='+CompanyId+'&BranchId='+BranchId,{headers: authHeader})
            .subscribe(data => 
            {
                this.spinnerService.hide();
                resolve(data)
            },
            error => 
            {
                this.spinnerService.hide();
                reject(error);
            },);


        },3000);
        });
    }
    // End of the Holidays Master
    //Begin Holidays Calendar For Employee
    GetHolidaysCalendarForEmployee(token)
    {
        this.spinnerService.show();
        const authHeader = new HttpHeaders();     
        authHeader.append('Content-Type', 'application/json');
        authHeader.append('Authorization', token);
        return new Promise((resolve, reject) =>
        {
            setTimeout(()=>{
            this.http.get(GlobalVariable.BASE_API_URL +'/api/HolidaysMaster/GetHolidaysCalendarForEmployee',{headers: authHeader})
            .subscribe(data => 
            {
                this.spinnerService.hide();
                resolve(data)
            },
            error => 
            {
                this.spinnerService.hide();
                reject(error);
            },);
        },3000);
        });
    }
    //End Holidays Calendar For Employee

    private extractData(res: Response) 
    {
        let body = res;
        return body || {};
    }
    private handleErrorObservable (error: Response | any) 
    {
        console.error(error.message || error);
        return Observable.throw(error.message || error);
    }
}
