<div class="body-content-area tabcontent" id="link1">
  <div class="row">
      <div class="col-lg-9">
          <div class="row">
              <div class="col-lg-1 low-padding text-center">
                  <!-- <img *ngIf="this.SelectedMenu.Image!=null" src={{this.SelectedMenu.Image}} class="body-icon" alt="Icon"/> -->
              </div>
              <div class="col-lg-10 clear-padding">
                  <span class="page-title">Manage Module Master</span>
                  <small class="page-description"></small>
              </div>
          </div>
      </div>
  </div>

  <div *ngIf="!(this.CanViewState)">
  <table id="tblModuleMaster" datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" class="table table-striped table-bordered text-center table-new row-border hover">
      <thead class="thead-blue text-center">
          <tr>
              <th>Sl No.</th>
              <th class="text-left">Module Name</th>
              <th>Action</th>
          </tr>
      </thead>
      <tbody>
          <tr *ngFor="let Module of ModuleMasters, let i = index">
              <td scope="row">{{i+1}}</td>
              <td class="text-left">{{Module.ModuleName}}</td>
              <td>
                  <button *ngIf="this.SelectedMenu.CanEdit"  type="button" (click)="GetModuleMasterById( Module.ModuleId )" class="table-edit-btn"><i class="fas fa-edit"></i> </button> 
                  <button *ngIf="this.SelectedMenu.CanDelete" type="button" (click)="DeleteModuleMasterById( Module.ModuleId )" class="table-remove-btn"><i class="fas fa-trash-alt"></i>  </button>
                  <button *ngIf="this.SelectedMenu.CanView"  type="button" (click)="ViewModuleMasterById( Module.ModuleId )" class="table-view-button" type="button" ><i class="fas fa-eye"></i></button>
              </td>
          </tr>
      </tbody>
  </table>
  <fieldset class="body-form-control">
      <app-dialog [(visible)]="this.SuccessStatus">
          <span class="successmsg">{{this.SuccessMessage}}</span>
      </app-dialog>
      
      <legend class="legend--">Module Master Creation</legend>
      <form #ModuleMasterForm="ngForm" (ngSubmit)="saveModuleMaster()">
          <div class="row">
              <div class="col-lg-4">
                <div class="col-body-border-right">
                      <div class="form-group row">
                        <label  class="col-sm-4 col-form-label clear-padding-right">Module Name</label>
                        <div class="col-sm-7 clear-left-padding">
                            <input type="text" [(ngModel)]="ModuleModel.ModuleName" tabindex="1" id ="ModuleName"  name="ModuleName" #ModuleName="ngModel" required maxlength="250" class="form-control form-control-custom form-control-custom-new" (blur)="CheckName()">
                        </div>
                        <div *ngIf="this.Namecheck.length > 0" class="alert alert-danger">Module Name is exist</div>
                        <div *ngIf="ModuleName.invalid && (ModuleName.dirty || ModuleName.touched)" class="alert alert-danger">
                            <div *ngIf="ModuleName.errors.required">Module Name  is required.</div>
                            <div *ngIf="ModuleName.errors.maxlength">Module Name is not more than 250 characters</div>
                        </div>
                      </div>
                  </div>
              </div>
              
              <div class="col-lg-4">
                    <div class="col-body-border-right">
                    <div class="form-group row">
                    <label  class="col-sm-4 col-form-label clear-padding-right">Logo</label>
                    <div class="col-sm-7 clear-left-padding">
                        <img *ngIf="this.ModuleModel.IconUrlImage!=null" [src]="ModuleModel.IconUrlImage" style="width:125px; height: 90px;" >
                        <input type="file" tabindex="2" style="width:150px;" (change)="handleFileinput($event.target.files)" #Image accept="image/*" >
                    </div>
                    <label *ngIf="!ModuleModel.IconUrlvalidfile" class="col-sm-4 col-form-label clear-padding-right">Invalid File, will not be uploaded </label>
                    </div>
                    </div>
              </div>

              <div class="col-lg-4">
                  <div class="form-group row">
                      <label  class="col-sm-4 col-form-label clear-padding-right">Small Logo</label>
                      <div class="col-sm-7 clear-left-padding">
                          <img *ngIf="this.ModuleModel.SmallIconUrlImage!=null" [src]="ModuleModel.SmallIconUrlImage" style="width:125px; height: 90px;" >
                          <input type="file" tabindex="3" style="width:150px;" (change)="SmallUrlFileinput($event.target.files)" #Image accept="image/*" >
                      </div>
                      <label *ngIf="!ModuleModel.SmallIconUrlvalidfile"  class="col-sm-4 col-form-label clear-padding-right">Invalid File, will not be uploaded </label>
                  </div>
                  <div class="form-group row">
                      <label class="col-sm-6 col-form-label text-center clear-padding-right" *ngIf="!(this.IsDataIsInViewState)">
                          <button type="submit" tabindex="4" class="btn btn-primary save-button" [disabled]="!(ModuleMasterForm.valid && this.CheckNameExiststatus==true && this.DisableSave==true)">Save</button>  
                      </label>
                      <div class="col-sm-6 col-form-label text-center">       
                      <button type="button" tabindex="5" class="btn btn-danger cancle-button" (click)="RefreshPage()" >Cancel</button></div>
                  </div>
              </div>
          </div>
      </form>
  </fieldset>
  </div>
</div>