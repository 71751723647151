import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from '../../shared/user.service';
import { EmployeePortalService }  from '../../shared/HR_ADMIN/EmployeePortal/EmployeePortalService.service';
import { EmployeeMasterService }  from '../../shared/HR_ADMIN/Employee/EmployeeMaster.Service';
import { EmployeeDetail } from '../../shared/HR_ADMIN/Employee/EmployeeDetail.Model';
import { EmployeeCompoffModel } from '../../shared/HR_ADMIN/EmployeePortal/EmployeeCompoffModel.model';
import { Menu } from '../../shared/menu.model';
import { ModalOptions,BsModalRef, BsModalService } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-all-employee-compoff-status',
  templateUrl: './all-employee-compoff-status.component.html',
  styleUrls: ['./all-employee-compoff-status.component.css']
})
export class AllEmployeeCompoffStatusComponent implements OnInit 
{
  EmployeeCompoffModel : EmployeeCompoffModel= new EmployeeCompoffModel();
  CompoffPending : EmployeeCompoffModel[]=[];
  CompoffApproved : EmployeeCompoffModel[]=[];
  CompoffRejected : EmployeeCompoffModel[]=[];
  Compoff: any;

  Employees: EmployeeDetail[] = [];
  employee: any;
  userClaims: any;
  
  public tableWidget: any;
  selectedIndex : number;
  // Begning Permission Portion    
  SelectedMenu:Menu;
  IsDataIsInViewState : boolean=false;
  CanViewState : boolean=true;
  IsApplicationView : boolean=false;
  // End of the permission portion
  EmployeeSettings = {};
  selectedItems = [];
  emp : any [] =[]
  //For Pop up
  ModelListApplication: EmployeeCompoffModel;
  CancelMsg : boolean= false;
  ShowPopup : boolean= false;
  public modalRef: BsModalRef;
  public modelopt:ModalOptions;
  Approver : any;
  RefId : any;
  //New
  PendingMsg : boolean= false;
  ApprovedMsg : boolean= false;
  RejectedMsg : boolean= false;
  validdate : boolean= false;
  validTodate : boolean= false;
  DisableSave : boolean;

  constructor(private EmployeeMasterService: EmployeeMasterService, private rout: Router, private userService: UserService, private EmployeePortalService: EmployeePortalService,private modalService: BsModalService) 
  { 
    this.ModelListApplication = new EmployeeCompoffModel();
    this.ModelListApplication.HalfDay=false;
    this.ModelListApplication.FullDay=false;
    this.modelopt=new ModalOptions();
    this.modelopt.class='heavy_modal';
    this.modalService.config= this.modelopt;
    this.modelopt.ignoreBackdropClick=true;
  }

  ngOnInit() 
  {
    // Begning Permission Portion 
    this.SelectedMenu=this.userService.SelectedMenu;
    if(this.SelectedMenu !=undefined)
    {
      localStorage.setItem('selectedmenu',JSON.stringify(this.SelectedMenu));
      this.rout.navigate([this.SelectedMenu.RouteName]);
    }
    else
    {
      if(JSON.parse(localStorage.getItem('selectedmenu'))!=undefined)
      {
        this.SelectedMenu = JSON.parse(localStorage.getItem('selectedmenu'));
        this.rout.navigate([this.SelectedMenu.RouteName]);
      }
    }
    if(this.SelectedMenu.CanAdd)
    {
      this.IsDataIsInViewState=false;
    }
    else
    {
      this.IsDataIsInViewState=true;
    }
    if(this.SelectedMenu.CanView)
    {
      this.CanViewState=false;
    }
    else
    {
      this.CanViewState=true;
    }
    // End of the permission portion
    this.GetEmployeeDetailsDDL();
    this.ModelListApplication.EmployeeId=0;
    this.DisableSave=true;
    this.ModelListApplication.ApplicationStatus=0;
    this.validdate=false;
    this.validTodate=false;
    this.DisableSave=true
    this.EmployeeSettings =
    {
      singleSelection:true,
      text:'Select Employee',
      enableSearchFilter:true,
      showCheckbox:false
    }
  }

  GetEmployeeDetailsDDL() 
  {
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.EmployeeMasterService.GetEmployeeDetailsForDDL( t).then(x => 
    {
      this.employee = x;
      Object.keys(this.employee).forEach( key => 
      {
        let emptemp = new EmployeeDetail();
        emptemp = this.employee[key];
        this.Employees.push(emptemp);
        this.emp.push({"id":emptemp.EmployeeId,"itemName":emptemp.EmployeeCode + '-' +emptemp.EmployeeName},);
      });     
    });      
  }

  onItemSelect(item:any)
  {
    this.DisableSave = true;
    this.PendingMsg = false;
    this.ApprovedMsg = false;
    this.RejectedMsg = false;
    this.CompoffPending=[];
    this.CompoffApproved=[];
    this.CompoffRejected=[];
    let EmployeeId= item['id'];
    if(EmployeeId>0)
    {
      this.ModelListApplication.EmployeeId=EmployeeId;
    }     
  }

  OnItemDeSelect(item:any){ }
  onSelectAll(items: any){ }
  onDeSelectAll(items: any){ }

  GetEmployeeCompoffPendingDetails(EmployeeId,FromDate,ToDate)
  {
    this.CompoffPending=[];
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.EmployeePortalService.GetEmployeeCompoffStatusPendingByEmployeeId( t,EmployeeId,FromDate,ToDate).then(x => 
    {
      this.Compoff = x;
      Object.keys(this.Compoff).forEach( key => 
      {
        this.Compoff[key];
        let Compofftemp = new EmployeeCompoffModel();
        Compofftemp = this.Compoff[key];
        this.CompoffPending.push(Compofftemp);
      });
      if(this.CompoffPending.length == 0)
      {
        this.PendingMsg = true;
        this.ApprovedMsg = false;
        this.RejectedMsg = false;
      }
    });
  }

  GetEmployeeCompoffApprovedDetails(EmployeeId,FromDate,ToDate)
  {
    this.CompoffApproved=[];
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.EmployeePortalService.GetEmployeeCompoffStatusApprovedByEmployeeId( t,EmployeeId,FromDate,ToDate).then(x => 
    {
      this.Compoff = x;
      Object.keys(this.Compoff).forEach( key => 
      {
        this.Compoff[key];
        let Compofftemp = new EmployeeCompoffModel();
        Compofftemp = this.Compoff[key];
        this.CompoffApproved.push(Compofftemp);
      });
      if(this.CompoffApproved.length == 0)
      {
        this.PendingMsg = false;
        this.ApprovedMsg = true;
        this.RejectedMsg = false;
      }
    });
  }

  GetEmployeeCompoffRejectedDetails(EmployeeId,FromDate,ToDate)
  {
    this.CompoffRejected=[];
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.EmployeePortalService.GetEmployeeCompoffStatusRejectedByEmployeeId( t,EmployeeId,FromDate,ToDate).then(x => 
    {
      this.Compoff = x;
      Object.keys(this.Compoff).forEach( key => 
      {
        this.Compoff[key];
        let Compofftemp = new EmployeeCompoffModel();
        Compofftemp = this.Compoff[key];
        this.CompoffRejected.push(Compofftemp);
      });
      if(this.CompoffRejected.length == 0)
      {
        this.PendingMsg = false;
        this.ApprovedMsg = false;
        this.RejectedMsg = true;
      }
    });
  }

  RefreshPage()
  {
    this.rout.navigateByUrl('/Employee/EmployeeOffer', {skipLocationChange: true}).then(()=>this.rout.navigate(["/Employee/AllRegularizationStatus"]));
  }

  viewAppliedEmployeeCompoff(id,template) 
  {
    this.DisableSave=true;
    this.ModelListApplication.Remarks="";
    // Begning of the permission portion
    this.IsDataIsInViewState=false;
    // End of the permision portion
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.EmployeePortalService.GetEmployeeCompoffApplyByRefId( t,id).then(x => 
    {
      if(this.ShowPopup==true)
      {
        this.modalRef.hide();
      }
      this.modalRef = this.modalService.show(template,this.modelopt);
      this.ShowPopup=true;
      this.CancelMsg=false;
      this.Approver = x;
      Object.keys(this.Approver).forEach( key => 
      {
        // tslint:disable-next-line:no-unused-expression
        this.Approver[key];
        let Approvetemp = new EmployeeCompoffModel();
        Approvetemp = this.Approver[key];
        this.ModelListApplication.RefId=Approvetemp.RefId;
        this.ModelListApplication.EmployeeId=Approvetemp.EmployeeId;
        this.ModelListApplication.EmployeeCode=Approvetemp.EmployeeCode;
        this.ModelListApplication.EmployeeName=Approvetemp.EmployeeName; 
        this.ModelListApplication.EmployeeEmailId=Approvetemp.EmployeeEmailId; 
        this.ModelListApplication.RHEmailId=Approvetemp.RHEmailId; 
        this.ModelListApplication.RHName=Approvetemp.RHName; 
        this.ModelListApplication.RHCode=Approvetemp.RHCode; 
        this.ModelListApplication.AttendanceDate=Approvetemp.AttendanceDate; 
        this.ModelListApplication.Status=Approvetemp.Status;
        this.ModelListApplication.HalfDay=Approvetemp.HalfDay;
        this.ModelListApplication.FullDay=Approvetemp.FullDay;
        if(Approvetemp.HalfDay==true)
        {
          this.ModelListApplication.Fromvalue="HalfDay";
        }
        if(Approvetemp.FullDay==true)
        {
          this.ModelListApplication.Fromvalue="FullDay";
        }
        this.ModelListApplication.ActualInTime=Approvetemp.ActualInTime;
        this.ModelListApplication.ActualOutTime=Approvetemp.ActualOutTime;
        this.ModelListApplication.ActualWorkingDuration=Approvetemp.ActualWorkingDuration; 
        this.ModelListApplication.Reason=Approvetemp.Reason;
        this.ModelListApplication.CompanyId=Approvetemp.CompanyId;  
      });
      // End for check company code duplication
    });
  }
  
  EmployeeCompoffCancel(template) :void
  {
    if(confirm("Are you sure to Cancel "))
    {
      this.DisableSave=false;
      let t =  localStorage.getItem('userToken');
      t = 'Bearer' + t;
      if(this.ModelListApplication.RefId > 0)
      {
        this.ModelListApplication.IsRejected=true;
        this.EmployeePortalService.EmployeeCompoffCancel(t ,this.ModelListApplication).subscribe(res=>
        {
          this.RefId=res;
          this.ModelListApplication.RefId=0;  
          if(this.RefId>0)
          {
            this.ModelListApplication.Remarks="";
            this.modalRef.hide();
            this.CancelMsg=true;
            this.ShowPopup=false;
            this.modalRef = this.modalService.show(template);
            this.GetEmployeeCompoffPendingDetails(this.ModelListApplication.EmployeeId,this.ModelListApplication.SearchFromDate,this.ModelListApplication.SearchToDate);
            this.GetEmployeeCompoffRejectedDetails(this.ModelListApplication.EmployeeId,this.ModelListApplication.SearchFromDate,this.ModelListApplication.SearchToDate);
            this.DisableSave=true;
          }
        });                  
      }
    }
  }

  SearchFromDateChanged( date)
  {
    this.DisableSave = true;
    this.PendingMsg = false;
    this.ApprovedMsg = false;
    this.RejectedMsg = false;
    this.CompoffPending=[];
    this.CompoffApproved=[];
    this.CompoffRejected=[];
    
    this.ModelListApplication.SearchFromDate=date;
  }

  SearchToDateChanged( date)
  {
    this.DisableSave = true;
    this.PendingMsg = false;
    this.ApprovedMsg = false;
    this.RejectedMsg = false;
    this.CompoffPending=[];
    this.CompoffApproved=[];
    this.CompoffRejected=[];

    this.ModelListApplication.SearchToDate=date;
  }

  ApplicationStatusChanged()
  {
    this.DisableSave = true;
    this.PendingMsg = false;
    this.ApprovedMsg = false;
    this.RejectedMsg = false;
    this.CompoffPending=[];
    this.CompoffApproved=[];
    this.CompoffRejected=[];
  }

  submitClick()
  {
    this.DisableSave = false;
    this.validdate = false;
    this.validTodate=false;
    this.PendingMsg = false;
    this.ApprovedMsg = false;
    this.RejectedMsg = false;
    this.CompoffPending=[];
    this.CompoffApproved=[];
    this.CompoffRejected=[];
    if(this.ModelListApplication.SearchFromDate!=null && this.ModelListApplication.SearchToDate!=null && this.ModelListApplication.ApplicationStatus > 0)
    {
      if(this.ModelListApplication.SearchToDate >= this.ModelListApplication.SearchFromDate)
      {
        if(this.ModelListApplication.ApplicationStatus == 1)
        {
          this.GetEmployeeCompoffPendingDetails(this.ModelListApplication.EmployeeId,this.ModelListApplication.SearchFromDate, this.ModelListApplication.SearchToDate);
        }
        else if(this.ModelListApplication.ApplicationStatus == 2)
        {
          this.GetEmployeeCompoffApprovedDetails(this.ModelListApplication.EmployeeId,this.ModelListApplication.SearchFromDate, this.ModelListApplication.SearchToDate);
        }
        else
        {
          this.GetEmployeeCompoffRejectedDetails(this.ModelListApplication.EmployeeId,this.ModelListApplication.SearchFromDate, this.ModelListApplication.SearchToDate);
        }
      }
      else
      {
        this.validTodate = true;
      }
    }
  }
}