export class NotificationModel 
{
    public TotalApplication : number;
    public ApplicationType : string;
    public ModuleId : number;
    public MainMenuId : number;
    public MenuId : number;
    public TotalAllApplication : number;
    public PendingAppRaisedBYMe : number;
    public MonthlyCTC : number;
    public AnnualCTC : number;
}
