<div class="body-content-area tabcontent" id="link1">
  <div class="row">
    <div class="col-lg-9">
      <div class="row">
        <div class="col-lg-1 low-padding text-center">
          <!-- <img *ngIf="this.SelectedMenu.Image!=null" src={{this.SelectedMenu.Image}} class="body-icon" alt="Icon"/> -->
        </div>
        <div class="col-lg-10 clear-padding">
          <div>
            <span class="page-title">Manage User Password</span>
            <small class="page-description"></small>
          </div>
        </div>
      </div>
    </div>
  </div>
  
  <fieldset class="body-form-control">
    <app-dialog [(visible)]="this.SuccessStatus">
        <span class="successmsg">{{this.SuccessMessage}}</span>
    </app-dialog>
    <legend class="legend--">Manage User Password</legend>
    <form #UserMaster="ngForm" (ngSubmit)="UpdateUserIdPassword()">
      <div class="row">
        <div class="col-lg-4">
          <div class="col-body-border-right">
            <div class="form-group row">
              <label class="col-sm-4 col-form-label clear-padding-right">Emp code</label>
              <div class="col-sm-7 clear-left-padding">
                :&nbsp;&nbsp;{{this.UserModel.EmployeeCode}} 
              </div>
            </div>
            <div class="form-group row">
              <label  class="col-sm-4 col-form-label clear-padding-right"> Current Password</label>
              <div class="col-sm-7 clear-left-padding">
                <input type="text" [(ngModel)]="UserModel.CurrentPassword" (blur)="CheckCurrentPassword()" tabindex="4" id ="CurrentPassword" name="CurrentPassword" #CurrentPassword="ngModel" required maxlength="200" class="form-control form-control-custom form-control-custom-new">
              </div>
              <div *ngIf="(this.WrongPassword)" class="alert alert-danger">Current Password is wrong</div>
            </div>
          </div>
        </div>
        <div class="col-lg-4">
          <div class="col-body-border-right">
            <div class="form-group row">
              <label class="col-sm-4 col-form-label clear-padding-right">Employee Name</label>
              <div class="col-sm-7 clear-left-padding">
                :&nbsp;&nbsp;{{this.UserModel.EmployeeName}}
              </div>
            </div>
            <div class="form-group row">
              <label  class="col-sm-4 col-form-label clear-padding-right">Change Password</label>
              <div class="col-sm-7 clear-left-padding">
                <input type="text" [(ngModel)]="UserModel.Password" (blur)="CheckPassword()" type="password" tabindex="5" id ="Password" name="Password" #Password="ngModel" required minlength="4" maxlength="200" class="form-control form-control-custom form-control-custom-new">
              </div>
              <div *ngIf="(this.PasswordExists)" class="alert alert-danger">Current Password is Same as Change Password.</div>
              <div *ngIf="Password.invalid && (Password.dirty || Password.touched)" class="alert alert-danger">
                <div *ngIf="Password.errors.required">Password  is required.</div>
                <div *ngIf="Password.errors.maxlength">Password is not more than 200 characters</div>
                <div *ngIf="Password.errors.minlength">Password is not less than 4 characters</div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-4">
          <div class="form-group row">
            <label class="col-sm-4 col-form-label clear-padding-right">User Id</label>
            <div class="col-sm-7 clear-left-padding">
              :&nbsp;&nbsp;{{this.UserModel.UserId}}
            </div>
          </div>
          <div class="form-group row">
            <label  class="col-sm-4 col-form-label clear-padding-right">Re-Enter Password</label>
            <div class="col-sm-7 clear-left-padding">
              <input type="text" [(ngModel)]="UserModel.RePassword" (blur)="CheckPassword()" type="password" tabindex="6" id ="Password" name="RePassword" class="form-control form-control-custom form-control-custom-new">
            </div>
            <div *ngIf="this.PasswordMisMatch == true" class="alert alert-danger">Password Mis-Match</div>
          </div>
          <div class="form-group row">
            <label class="col-sm-6 col-form-label text-center clear-padding-right" *ngIf="!(this.IsDataIsInViewState)">
              <button type="submit" tabindex="6" class="btn btn-primary save-button" [disabled]="!(UserMaster.valid && this.PasswordExists==false && this.WrongPassword==false && this.PasswordMisMatch==false && this.DisableSave==true)">Save</button>  
            </label>
            <div class="col-sm-6 col-form-label text-center">       
              <button type="button" tabindex="7" class="btn btn-danger cancle-button" (click)="RefreshPage()" >Cancel</button>
            </div>
          </div>
        </div>
      </div>
    </form>
  </fieldset>
</div>