import { ReimbursementFileUploadModel }  from '../EmployeePortal/ReimbursementFileUploadModel.Model';

export class TourReimbursementDetailsModel
{
    public RefIdTRD : number;
    public RefIdTRM : number;
    public ExpenseHeadId : number;
    public ExpenseHeadName : string;
    public ExpenseAmount : number;
    public ApprovedExpenseAmount : number;
    public FinalAmount :number;
    public FinalExpenseAmount : number;
    public FinalApprovedAmount : number;

    public DayWise : boolean;
    public OverAll : boolean;
    public BudgetAmount : number;

    public ReimbursementFileUploadModel  :  ReimbursementFileUploadModel [];
}