import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs/Subject';
import { DataTableDirective } from 'angular-datatables';
import { UserService } from '../../shared/user.service';
import { UserMasterService } from '../../shared/HR_ADMIN/UserMaster/UserMasterService.service';
import { RoleMasterModel } from  '../../shared/HR_ADMIN/UserMaster/RoleMasterModel.Model';
import { MenuUserMappingModel } from  '../../shared/HR_ADMIN/UserMaster/MenuUserMappingModel.Model';
import { MenuMasterModel } from  '../../shared/HR_ADMIN/UserMaster/MenuMasterModel.Model';
import { Menu } from '../../shared/menu.model';
// end of Confirmation

@Component({
  selector: 'app-main-menu-user-mapping',
  templateUrl: './main-menu-user-mapping.component.html',
  styleUrls: ['./main-menu-user-mapping.component.css']
})
export class MainMenuUserMappingComponent implements OnInit 
{
  MenuUserMappingModel : MenuUserMappingModel= new MenuUserMappingModel();
  userClaims: any;
  MenuUserMappings: MenuUserMappingModel[] = [];
  MenuUserMappingmains: MenuUserMappingModel[] = [];
  Result : string;
  SuccessStatus:boolean=false;
  SuccessMessage: string ="";
  public tableWidget: any;

  RefId : any;
  MenuUserMapping: any;
  base64textString : string;

  RoleMasters: RoleMasterModel[] = [];
  RoleMaster: any;
  
  MenuMasters: MenuMasterModel[] = [];
  MenuMaster: any;

  ModuleMasters: MenuMasterModel[] = [];
  ModuleMaster: any;

  tempMenuUserMappingadd : MenuUserMappingModel =new MenuUserMappingModel();
  MenuIsValid : boolean =false;  
  // Begning Permission Portion    
  SelectedMenu:Menu;
  IsDataIsInViewState : boolean=false;
  CanViewState : boolean=false;
  DisableSave : boolean=true
  // End of the permission portion

  constructor(private UserMasterService: UserMasterService, private rout: Router, private userService: UserService) { }
  
  @ViewChild(DataTableDirective, {static: false})
  setDtOptions: DataTables.Settings = {};
  cardDtOptions: DataTables.Settings = {};
  dtElement: DataTableDirective;
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();
  
  ngOnInit() 
  {
    //Begin Permission Portion
    this.SelectedMenu=this.userService.SelectedMenu;
    if(this.SelectedMenu !=undefined)
    {
      localStorage.setItem('selectedmenu',JSON.stringify(this.SelectedMenu));
      this.rout.navigate([this.SelectedMenu.RouteName]);
    }
    else
    {
      if(JSON.parse(localStorage.getItem('selectedmenu'))!=undefined)
      {
        this.SelectedMenu = JSON.parse(localStorage.getItem('selectedmenu'));
        this.rout.navigate([this.SelectedMenu.RouteName]);
      }
    }      
    if(this.SelectedMenu.CanAdd)
    {
      this.IsDataIsInViewState=false;
    }
    else
    {
      this.IsDataIsInViewState=true;
    }
    if(this.SelectedMenu.CanView)
    {
      this.CanViewState=false;
    }
    else
    {
      this.CanViewState=true;
    }
    //End of the peremission portion
    this.DisableSave=true;
    this.GetRoleMasters();
    //this.GetModuleMaster();
    this.MenuUserMappingModel.RoleId=0;
    this.MenuUserMappingModel.ModuleId=0;
    this.dtOptions = 
    {
      pagingType: 'full_numbers',
      pageLength: 2
    };
    if(localStorage.getItem('SuccessfullMessage'))
    {
      this.SuccessStatus=true;
      this.SuccessMessage =localStorage.getItem('SuccessfullMessage')
      localStorage.removeItem('SuccessfullMessage');
    }  
  }

  ngAfterViewInit(): void 
  {
    this.dtOptions = 
    {
      pagingType: 'full_numbers',
      pageLength: 2
    };
  }
 
  GetModuleMaster(RoleId)
  {
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.ModuleMasters=[];
    this.UserMasterService.GetModuleMasterByRoleId( t,RoleId).then(x => 
    {
      this.ModuleMaster = x;
      Object.keys(this.ModuleMaster).forEach( key => 
      {
        this.ModuleMaster[key];
        let MenuMastertemp = new MenuMasterModel();
        MenuMastertemp = this.ModuleMaster[key];
        this.ModuleMasters.push(MenuMastertemp);
      });
    });
  }

  GetRoleMasters() 
  {
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.UserMasterService.GetRoleMaster( t).then(x => 
    {
      this.RoleMaster = x;
      Object.keys(this.RoleMaster).forEach( key => 
      {
        // tslint:disable-next-line:no-unused-expression
        this.RoleMaster[key];
        let RoleMastertemp = new RoleMasterModel();
        RoleMastertemp = this.RoleMaster[key];
        this.RoleMasters.push(RoleMastertemp);
      });     
    });      
  }

  GetGridViewData(RoleId,ModuleId)
  {
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.UserMasterService.GetMainMenuUserMappingByRoleId( t,RoleId,ModuleId).then(x => 
    {
      this.RefId = x;
      this.MenuUserMappingmains=  [];
      Object.keys(this.RefId).forEach( key => 
      {
        // tslint:disable-next-line:no-unused-expression
        this.RefId[key];
        var MenuUserMappingtemp = new MenuUserMappingModel();
        MenuUserMappingtemp = this.RefId[key];  
        if(MenuUserMappingtemp.CanView==true)
        {
           MenuUserMappingtemp.valueView="CanView";
        }
        if(MenuUserMappingtemp.MobileView==true)
        {
           MenuUserMappingtemp.valueMobileView="MobileView";
        }
        this.MenuUserMappingmains.push(MenuUserMappingtemp);
      });
    });
  }
  
  onRolechange(Roleid) 
  {
    if(Roleid > 0)
    {
      this.MenuMasters=[];
      this.MenuUserMappingmains=[];
      this.GetModuleMaster(Roleid)
      this.MenuUserMappingModel.ModuleId=0;
    }
  }

  onModulechange(ModuleId) 
  {
    if(this.MenuUserMappingModel.RoleId > 0)
    {
      this.MenuMasters=[];
      this.MenuUserMappingmains=[];
      this.GetGridViewData(this.MenuUserMappingModel.RoleId,ModuleId);
    }
  }

  OnChangeCanView(evt,i:number)
  {
    this.MenuUserMappingmains[i];
    var target = evt.target;
    if(target.checked)
    {           
      this.MenuUserMappingmains[i].CanView=true;
      this.MenuUserMappingmains[i].valueView="CanView";        
    }
    else
    {
      this.MenuUserMappingmains[i].CanView=false;
      this.MenuUserMappingmains[i].valueView="CanView"; 
    }
  }

  OnChangeMobileView(evt,i:number)
  {
    this.MenuUserMappingmains[i];
    var target = evt.target;
    if(target.checked)
    {           
      this.MenuUserMappingmains[i].MobileView=true;
      this.MenuUserMappingmains[i].valueMobileView="MobileView";        
    }
    else
    {
      this.MenuUserMappingmains[i].MobileView=false;
      this.MenuUserMappingmains[i].valueMobileView="MobileView"; 
    }
  }

  RefreshPage()
  {
    this.rout.navigateByUrl('/ManageUserMaster/ModuleMaster', {skipLocationChange: true}).then(()=>this.rout.navigate(["/ManageUserMaster/MenuMapping"]))
  }

  UpdateMenuMapping() :void
  {
    this.DisableSave=false;
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.UserMasterService.UpdateMenuUserMapping(t ,this.MenuUserMappingmains).subscribe(res=>
    {
      this.RefId=res;
      if(this.RefId>0)
      {
        localStorage.setItem('SuccessfullMessage', "Modified Successfully");
        this.MenuUserMappingmains=[];
        this.rout.navigateByUrl('/ManageUserMaster/ModuleMaster', {skipLocationChange: true}).then(()=>this.rout.navigate(["/ManageUserMaster/MenuMapping"]));
      }
    });                  
  }
}
