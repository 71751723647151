
export class RegionForHR
{
    public  RefId : number;
    public  RegionName : string;
    public  HRIds : string;
    public  HRNames : string;
    public  IsActive : boolean;
    public  HRId : number;
    public  HRCode : string;
    public  HRName : string;
}