<div class="body-content-area tabcontent" id="link1">
    <div class="row">
        <div class="col-lg-9">
            <div class="row">
                <div class="col-lg-1 low-padding text-center">
                    <!-- <img *ngIf="this.SelectedMenu.Image!=null" src={{this.SelectedMenu.Image}} class="body-icon" alt="Icon"/> -->
                </div>
                <div class="col-lg-10 clear-padding">
                    <span class="page-title">Manage Salary Component</span>
                    <small class="page-description"></small>
                </div>
            </div>
        </div>
    </div>

    <div *ngIf="!(this.CanViewState)">
        <div style=" text-align: right;">
            <button (click)="ADDClick(template)" type="button" class="btn btn-primary Add-button">Add New</button> 
        </div>
        <br>
        <table id="tblSalaryComponentMaster" class="table table-striped table-bordered text-center table-new row-border hover">
            <thead class="thead-blue text-center">
                <tr>
                    <th>Sl No.</th>
                    <th>Code</th>
                    <th>Description</th>
                    <th>Action</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let Sal of SalaryComponents, let i = index">
                    <td scope="row">{{i+1}}</td>
                    <td>{{Sal.ComponentCode}}</td>
                    <td class="text-left">{{Sal.ComponentName}}</td>
                    <td>
                        <button *ngIf="this.SelectedMenu.CanEdit" type="button" (click)="GetSalaryComponentMasterByid(Sal.ComponentId,template)" class="table-edit-btn"><i class="fas fa-edit"></i></button> 
                        <button *ngIf="this.SelectedMenu.CanDelete" type="button" (click)="DeleteSalaryComponentMasterByid(Sal.ComponentId,template)" class="table-remove-btn"><i class="fas fa-trash-alt"></i></button>
                        <button *ngIf="this.SelectedMenu.CanView" type="button" (click)="viewSalaryComponentMasteByid(Sal.ComponentId,template)" class="table-view-button" type="button"><i class="fas fa-eye"></i></button>
                    </td>
                </tr>
            </tbody>
        </table>
        <div style=" box-align: center; width: 1500px;">
            <ng-template #template>
                <div class="modal-header" style="background: #2a9bd7; max-width: 1500px;"> 
                    <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()" style="color:#fff;">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div style="text-align: center; max-width: 1500px;">
                    <div *ngIf="SaveMsg" style="color: #dc3545; text-align: center;font-size: 15px; font-weight: bold;">Save Successfully</div>
                    <div *ngIf="UpdateMsg" style="color: #dc3545; text-align: center;font-size: 15px; font-weight: bold;">Modified Successfully</div>
                    <div *ngIf="DeleteMsg" style="color: #dc3545; text-align: center;font-size: 15px; font-weight: bold;">Deleted Successfully</div>
                    <div style=" width: 100%; text-align: left;" *ngIf="ShowPopup">
                        <fieldset class="body-form-control">
                            <legend class="legend--">Salary Component Creation</legend>
                            <form #SalaryComponentMasterForm="ngForm" (ngSubmit)="saveSalaryComponentMaster(template)">
                                <div class="row">
                                    <div class="col-lg-4">
                                        <div class="col-body-border-right">
                                            <div class="form-group row">
                                                <label  class="col-sm-4 col-form-label clear-padding-right">Code</label>
                                                <div class="col-sm-7 clear-left-padding">
                                                    <input type="text" [(ngModel)]="SalaryComponentModel.ComponentCode" maxlength="40" id ="ComponentCode" name="ComponentCode" #ComponentCode="ngModel" required class="form-control form-control-custom form-control-custom-new" (blur)="CheckSalarycomponentcode()">
                                                </div>
                                                <div *ngIf="this.componentCodecheck.length > 0" class="alert alert-danger">Component Code is exist</div>
                                                <div *ngIf="ComponentCode.invalid && (ComponentCode.dirty || ComponentCode.touched)" class="alert alert-danger">
                                                    <div *ngIf="ComponentCode.errors.required">Component Code is required.</div>
                                                    <div *ngIf="ComponentCode.errors.maxlength">Component Code is not more than 40 characters</div>
                                                </div>
                                            </div>
                                            <div class="form-group row">                        
                                                <label class="col-sm-4 col-form-label clear-padding-right">Is Taxable</label>
                                                <div class="col-sm-7 clear-left-padding">
                                                    <input [checked]="SalaryComponentModel.Value=='Taxable'" class="form-control form-control-custom form-control-custom-new" type="checkbox" id="chk_IsTaxable" name="IsTaxable" (click)="IsTaxableChange($event)">
                                                    <!-- [value]="Taxable" -->
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    
                                    <div class="col-lg-4">
                                        <div class="col-body-border-right">
                                            <div class="form-group row">
                                                <label  class="col-sm-4 col-form-label clear-padding-right">Description</label>
                                                <div class="col-sm-7 clear-left-padding">
                                                    <input type="text" [(ngModel)]="SalaryComponentModel.ComponentName" id ="ComponentName" name="ComponentName" #ComponentName="ngModel" required maxlength="250" class="form-control form-control-custom form-control-custom-new" (blur)="CheckName()">
                                                </div>
                                                <div *ngIf="this.componentNamecheck.length > 0" class="alert alert-danger">Component Name is exist</div>
                                                <div *ngIf="ComponentName.invalid && (ComponentName.dirty || ComponentName.touched)" class="alert alert-danger">
                                                    <div *ngIf="ComponentName.errors.required">Component Name  is required.</div>
                                                    <div *ngIf="ComponentName.errors.maxlength">Component Name is not more than 250 characters</div>
                                                </div>
                                            </div>
                                            <div class="form-group row">                        
                                                <label class="col-sm-4 col-form-label clear-padding-right">IsExemptedFull</label>
                                                <div class="col-sm-7 clear-left-padding">
                                                    <input type="checkbox" id="chk_IsExemptedFull" [checked]="SalaryComponentModel.Value=='ExemptedFull'" name="IsExemptedFull" (click)="IsExemptedFullchange($event)" class="form-control form-control-custom form-control-custom-new">
                                                    <!-- [value]="ExemptedFull" -->
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                
                                    <div class="col-lg-4">
                                        <div class="form-group row">                        
                                            <label class="col-sm-4 col-form-label clear-padding-right">LongTerm Benefits</label>
                                            <div class="col-sm-7 clear-left-padding">
                                                <input class="form-control form-control-custom form-control-custom-new" type="checkbox" id="chk_IsBenefit" name="Benefits" [checked]="SalaryComponentModel.IsBenefit" (click)="IsBenefitchange($event)">
                                            </div>
                                        </div>
                                        <div *ngIf="SalaryComponentModel.IsExemptedFull == false" class="form-group row">                        
                                            <label class="col-sm-4 col-form-label clear-padding-right">Exempted Amount</label>
                                            <div class="col-sm-7 clear-left-padding">
                                                <input type="text" [(ngModel)]="SalaryComponentModel.ExemptedAmount" pattern="[0-9]+" id ="ExemptedAmount" name="ExemptedAmount" #ExemptedAmount="ngModel" class="form-control form-control-custom form-control-custom-new">
                                            </div>
                                        </div>
                                        <div class="form-group row">
                                            <label class="col-sm-6 col-form-label text-center clear-padding-right" *ngIf="!(this.IsDataIsInViewState)">
                                                <button type="submit" class="btn btn-primary save-button" [disabled]="!(SalaryComponentMasterForm.valid  && this.componentCodecheck.length == 0 && this.componentNamecheck.length == 0 && this.DisableSave == true)">Save</button>  
                                            </label>
                                            <div class="col-sm-6 col-form-label text-center">       
                                            <button type="button" class="btn btn-danger cancle-button" (click)="RefreshPage()">Cancel</button></div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </fieldset>
                    </div>
                    <button type="button" aria-label="Close" (click)="modalRef.hide()" style="background: #2a9bd7; outline: 0px none; border: 0px none; color: #fff; padding: 5px 10px; border-radius: 3px;">
                        <span>Close</span>
                    </button>             
                </div>
            </ng-template>
        </div>
    </div>
</div>