import { ReplaySubject } from "rxjs/ReplaySubject";
import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs/Subject';
import { DataTableDirective } from 'angular-datatables';
import { UserService } from '../../shared/user.service';
import { OrganizationService }  from '../../shared/Organization/organization.service';
import { Country } from '../../shared/Organization/Country.Model';
import { State } from '../../shared/Organization/State.Model';
import { District } from '../../shared/HR_ADMIN/Location/District.Model';
import { City } from '../../shared/HR_ADMIN/Location/City.Model';
import { Model_CRM_B2B } from '../../shared/CRM/B2B/B2B_Model';
import { Model_ClientType } from '../../shared/CRM/B2B/Cilent_Type'
import { B2cService } from '../../shared/CRM/B2B/b2c.service';
// Begning Confirmation
import { ConfirmationService } from '@jaspero/ng2-confirmations';
import { ResolveEmit } from '../../../app/resolve-emit';
import { ConfirmSettings } from '../../../app/confirm-settings';
// end of Confirmation
import { Menu } from '../../shared/menu.model';
//Begning boot strap
import { ValidationClass } from '../../shared/Validate/ValidationForAll'

//End of boot strap;

//import { NG_VALIDATORS } from '@angular/forms';

@Component({
  selector: 'app-b2-c',
  templateUrl: './b2-c.component.html',
  styleUrls: ['./b2-c.component.css']
})
export class B2CComponent implements OnInit {

  CRM_B2C_Model : Model_CRM_B2B ;
  CRM_B2C_models : Model_CRM_B2B[]=[];
  ComponentTotal : number;
  ComponentSubTotal : number;
  Longtermbenefits : number;
  TotalOffer :any;
  B2C_temp_Any : any;
  userClaims: any;
  SalOffer:any;
  SubMenues : Menu[]=[];
  Mod : any;
  
  //Location
  Countries : Country[]=[];
  country : any;
  States : State[]=[];
  State : any;
  Districts : District[]=[];
  District : any;
  Cities : City[]=[];
  City : any;
  //Grade & Level
    
  Result : string;
  SuccessStatus:boolean=false;
  SuccessMessage: string ="";
  public tableWidget: any;
  OfferId : any;
  IsInView : boolean = false ; 
  // Begning Permission Portion 
 
  SelectedMenu:Menu;
  IsDataIsInViewState : boolean=false;
  CanViewState : boolean=true;
  SalaryRuleId : any;
  PFAmount : number;
  
  // End of the permission portion

  // begin for Validation
  validdate : boolean= false;
  vaildaniversarydate : boolean=true;
  valdpassportDate: boolean=true;
  DeletedB2cID: number =0;
  IsNotBusy:boolean=false;
  ValidationGlobaly :ValidationClass  // Validation Class.
  // end of validation
  //Client Type
   ClientType : Model_ClientType[]=[];
  //ClientType

  //fileuload 
  filetouploadDocument: File=null;
  fileDocumentUrl : string;

  //file upload 

   //fileuload 
    filetouploadPassport: File=null;
   filePassportUrl : string;
 
   //file upload 

   // string Mr Mrs

     


   //
  

  constructor(private organizationService: OrganizationService,private rout: Router,
    private _confirmation: ConfirmationService,  private userService: UserService,
     private B2cservice: B2cService )
    {
      this.ValidationGlobaly = new ValidationClass();
      this.CRM_B2C_Model = new Model_CRM_B2B();
      this.CRM_B2C_Model.MR='0';
      this.IsNotBusy=false;


       this.CRM_B2C_Model.IsEmailValid = true;     // For Email Validation 

       this.CRM_B2C_Model.IsPhoneNoValid = true;  //  For Phone Number Validation 

       this.CRM_B2C_Model.IsDOBValid = true;     //  For Date Of Birth Validation 

       this.CRM_B2C_Model.IsAnniversaryDateValid = true;  // Aniversary date Validation

       this.CRM_B2C_Model.IsPassportDateValid = true;   //  Passport Date Validation

      // Added On 05.12.2018
      this.CRM_B2C_Model.IsNextFollowUpDateValid = true;   // Next Follow Up Date Validation 
    
           
     }

  @ViewChild(DataTableDirective, {static: false})
  setDtOptions: DataTables.Settings = {};
  cardDtOptions: DataTables.Settings = {};
  dtElement: DataTableDirective;
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();

  ngOnInit() 
  { 
    // Begning Permission Portion 
    this.SelectedMenu=this.userService.SelectedMenu;
    if(this.SelectedMenu !=undefined)
    {
      localStorage.setItem('selectedmenu',JSON.stringify(this.SelectedMenu));
      this.rout.navigate([this.SelectedMenu.RouteName]);
    }
    else
    {
      if(JSON.parse(localStorage.getItem('selectedmenu'))!=undefined)
      {
        this.SelectedMenu = JSON.parse(localStorage.getItem('selectedmenu'));
        this.rout.navigate([this.SelectedMenu.RouteName]);
      }
    }
    if(this.SelectedMenu.CanAdd)
    {
      this.IsDataIsInViewState=false;
    }
    else
    {
      this.IsDataIsInViewState=true;
    }
    if(this.SelectedMenu.CanView)
    {
      this.CanViewState=false;
    }
    else
    {
      this.CanViewState=true;
    }
    // End of the permission portion
   
    this.getCountries();
    this.getclientType();
    
    this.dtOptions = 
    {
      pagingType: 'full_numbers',
      pageLength: 2
    };
    this.CRM_B2C_Model.CountryId=0;
    this.CRM_B2C_Model.StateId=0;
    this.CRM_B2C_Model.CityId=0;
    this.CRM_B2C_Model.Client_Past_New='0';
    
   
    if(localStorage.getItem('SuccessfullMessage'))
    {
      this.SuccessStatus=true;
      this.SuccessMessage =localStorage.getItem('SuccessfullMessage')
      localStorage.removeItem('SuccessfullMessage');
    }
  }

  settings: ConfirmSettings | any = 
  {
    overlay: true,
    overlayClickToClose: true,
    showCloseButton: true,
    confirmText: 'Yes',
    declineText: 'No'
  };

  ngAfterViewInit(): void 
  {
    this.GetAll_CRM_B2C();
    this.dtOptions = 
    {
      pagingType: 'full_numbers',
      pageLength: 2
    };
  }


  DeleteB2C(ID : number) :void
  {    
    this._confirmation.create('Confirm to Deleate', 'Press Yes to Delete', this.settings)
    .subscribe((ans: ResolveEmit) => {
      if (ans.resolved == true) {

        let t =  localStorage.getItem('userToken');
        t = 'Bearer' + t; 
       
        if( ID > 0)
        {
          this.B2cservice.DeleteB2C(t ,ID).subscribe(res=>
          {
            this.DeletedB2cID=Number(res);
                                         
            if(this.DeletedB2cID >0)
            {
              localStorage.setItem('SuccessfullMessage', "Delete Successfully");
              this.rout.navigateByUrl('/CRM/B2B/', {skipLocationChange: true}).then(()=>this.rout.navigate(["/CRM/B2C/"]));
            }
          });                  
        }
         
      }
    })
  }

  GetAll_CRM_B2C()
  {
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.B2cservice.GetB2CDetails(t).then(x => 
    {
      this.B2C_temp_Any = x;
      Object.keys(this.B2C_temp_Any).forEach( key => 
      {        
        let B2Btemp = new Model_CRM_B2B();
        B2Btemp = this.B2C_temp_Any[key];
        this.CRM_B2C_models.push(B2Btemp);
      });
      this.dtTrigger.next();
    });
  }

  GetB2CDetailsByid(id) 
  {
    this.IsInView=false;
     // begning of the permission portion
    this.IsDataIsInViewState=false;
    this.validdate=true;
     // end of the permission portion
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.B2cservice.GetbB2CByid( t,id).then(x => 
    {
      this.B2C_temp_Any = x;
      Object.keys(this.B2C_temp_Any).forEach( key => 
      {
       // this.B2B_temp_Any[key];
        let b2btemp = new Model_CRM_B2B();
        //b2btemp = this.B2B_temp_Any[key];
        b2btemp = this.B2C_temp_Any;
        this.CRM_B2C_Model.ID=b2btemp.ID;
        this.CRM_B2C_Model.CompanyName=b2btemp.CompanyName;
        this.CRM_B2C_Model.FirstName=b2btemp.FirstName;
        this.CRM_B2C_Model.MiddleName=b2btemp.MiddleName;
        this.CRM_B2C_Model.LastName=b2btemp.LastName;          
        this.CRM_B2C_Model.PanNo=b2btemp.PanNo;
        this.CRM_B2C_Model.PhoneNo=b2btemp.PhoneNo;
        this.CRM_B2C_Model.EmailId=b2btemp.EmailId;

        this.CRM_B2C_Model.CountryId=b2btemp.CountryId;
        this.CRM_B2C_Model.StateId=b2btemp.StateId;
        this.CRM_B2C_Model.CityId=b2btemp.CityId;
        this.CRM_B2C_Model.Pin=b2btemp.Pin;
        this.CRM_B2C_Model.Address=b2btemp.Address;
        this.CRM_B2C_Model.Client_Past_New=b2btemp.Client_Past_New;
        this.CRM_B2C_Model.Title=b2btemp.Title;;
        this.CRM_B2C_Model.DOB=b2btemp.DOB;
      
        //this.CRM_B2C_Model.DOB = this.ValidationGlobaly.FormatDate(b2btemp.DOB); 
        this.CRM_B2C_Model.AnniverSaryDate=b2btemp.AnniverSaryDate;
        // Begnimg other document immage
        this.CRM_B2C_Model.OtherIdNo=b2btemp.OtherIdNo;
        this.CRM_B2C_Model.OtherDocumentType=b2btemp.OtherDocumentType;
        this.CRM_B2C_Model.OtherDocumentName=b2btemp.OtherDocumentName;
        this.CRM_B2C_Model.OtherDocumentTypeimage=b2btemp.OtherDocumentType;
        this.CRM_B2C_Model.DocumentImage=b2btemp.DocumentImage;
        this.CRM_B2C_Model.validDocumentImage=b2btemp.validDocumentImage;
        // End othe document immage other document immage

        //begning passport immage
        this.CRM_B2C_Model.PassPortNo=b2btemp.PassPortNo;
        this.CRM_B2C_Model.PassportType=b2btemp.PassportType;
        this.CRM_B2C_Model.PassPorteName=b2btemp.PassPorteName;
        
        this.CRM_B2C_Model.PassportImage=b2btemp.PassportImage;
       
        this.CRM_B2C_Model.validPassportImage=b2btemp.validPassportImage;
        this.CRM_B2C_Model.PassportTypeimage=b2btemp.PassportTypeimage;
        
        this.CRM_B2C_Model.PassPortValidityDate=b2btemp.PassPortValidityDate;
        
        //End of passport immage


        this.CRM_B2C_Model.Extension = b2btemp.Extension;
        this.CRM_B2C_Model.Role = b2btemp.Role;
        this.CRM_B2C_Model.IsEmialOffer = b2btemp.IsEmialOffer;
        this.CRM_B2C_Model.GSTNO = b2btemp.GSTNO;
        this.CRM_B2C_Model.SACNO = b2btemp.SACNO;
        this.CRM_B2C_Model.IsSmsOffer = b2btemp.IsSmsOffer;


        this.CRM_B2C_Model.ContactPerson = b2btemp.ContactPerson;  // Added on 05.12.2018 
        this.CRM_B2C_Model.CurrentVendor = b2btemp.CurrentVendor;  // Added On 05.12.2018
        this.CRM_B2C_Model.TotalAnnualVol = b2btemp.TotalAnnualVol;        //Added On 05.12.2018 
        this.CRM_B2C_Model.CNRatio = b2btemp.CNRatio;                // Added On 05.12.2018 
        this.CRM_B2C_Model.CardRatio = b2btemp.CardRatio;                // Added On 05.12.2018
        this.CRM_B2C_Model.FeedbackProposalDetails = b2btemp.FeedbackProposalDetails;  //Added On 05.12.2018
        this.CRM_B2C_Model.NextFollowUp = b2btemp.NextFollowUp;                     //Added On 05.12.2018 

        
      });
      this.getStates(this.CRM_B2C_Model.CountryId);      
      this.getCities(this.CRM_B2C_Model.StateId);      
     
    });
  }


 ViewB2CDetailsByid(id) 
  {
    this.IsInView=true;
     // begning of the permission portion
    this.IsDataIsInViewState=false;
    this.validdate=true;
     // end of the permission portion
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.B2cservice.GetbB2CByid( t,id).then(x => 
    {
      this.B2C_temp_Any = x;
      Object.keys(this.B2C_temp_Any).forEach( key => 
      {
       // this.B2B_temp_Any[key];
        let b2btemp = new Model_CRM_B2B();
        //b2btemp = this.B2B_temp_Any[key];
        b2btemp = this.B2C_temp_Any;
        this.CRM_B2C_Model.ID=b2btemp.ID;
        this.CRM_B2C_Model.CompanyName=b2btemp.CompanyName;
        this.CRM_B2C_Model.FirstName=b2btemp.FirstName;
        this.CRM_B2C_Model.MiddleName=b2btemp.MiddleName;
        this.CRM_B2C_Model.LastName=b2btemp.LastName;          
        this.CRM_B2C_Model.PanNo=b2btemp.PanNo;
        this.CRM_B2C_Model.PhoneNo=b2btemp.PhoneNo;
        this.CRM_B2C_Model.EmailId=b2btemp.EmailId;  /** */
        this.CRM_B2C_Model.CountryId=b2btemp.CountryId;
        this.CRM_B2C_Model.StateId=b2btemp.StateId;
        this.CRM_B2C_Model.CityId=b2btemp.CityId;
        this.CRM_B2C_Model.Pin=b2btemp.Pin;
        this.CRM_B2C_Model.Address=b2btemp.Address;
        this.CRM_B2C_Model.Client_Past_New=b2btemp.Client_Past_New;
        this.CRM_B2C_Model.Title=b2btemp.Title;;
        this.CRM_B2C_Model.DOB=b2btemp.DOB;
        this.CRM_B2C_Model.AnniverSaryDate=b2btemp.AnniverSaryDate;
        // Begnimg other document immage
        this.CRM_B2C_Model.OtherIdNo=b2btemp.OtherIdNo;
        this.CRM_B2C_Model.OtherDocumentType=b2btemp.OtherDocumentType;
        this.CRM_B2C_Model.OtherDocumentName=b2btemp.OtherDocumentName;
        this.CRM_B2C_Model.OtherDocumentTypeimage=b2btemp.OtherDocumentType;
        this.CRM_B2C_Model.DocumentImage=b2btemp.DocumentImage;
        this.CRM_B2C_Model.validDocumentImage=b2btemp.validDocumentImage;
        // End othe document immage other document immage

        //begning passport immage
        this.CRM_B2C_Model.PassPortNo=b2btemp.PassPortNo;
        this.CRM_B2C_Model.PassportType=b2btemp.PassportType;
        this.CRM_B2C_Model.PassPorteName=b2btemp.PassPorteName;
        
        this.CRM_B2C_Model.PassportImage=b2btemp.PassportImage;
       
        this.CRM_B2C_Model.validPassportImage=b2btemp.validPassportImage;
        this.CRM_B2C_Model.PassportTypeimage=b2btemp.PassportTypeimage;
        
        this.CRM_B2C_Model.PassPortValidityDate=b2btemp.PassPortValidityDate;
        
        //End of passport immage
        this.CRM_B2C_Model.Extension = b2btemp.Extension;
        this.CRM_B2C_Model.Role = b2btemp.Role;
        this.CRM_B2C_Model.IsEmialOffer = b2btemp.IsEmialOffer;
        this.CRM_B2C_Model.GSTNO = b2btemp.GSTNO;
        this.CRM_B2C_Model.SACNO = b2btemp.SACNO;
        this.CRM_B2C_Model.IsSmsOffer = b2btemp.IsSmsOffer;



        this.CRM_B2C_Model.ContactPerson = b2btemp.ContactPerson;  // Added on 05.12.2018 
        this.CRM_B2C_Model.CurrentVendor = b2btemp.CurrentVendor;  // Added On 05.12.2018
        this.CRM_B2C_Model.TotalAnnualVol = b2btemp.TotalAnnualVol;        //Added On 05.12.2018 
        this.CRM_B2C_Model.CNRatio = b2btemp.CNRatio;                // Added On 05.12.2018 
        this.CRM_B2C_Model.CardRatio = b2btemp.CardRatio;                // Added On 05.12.2018
        this.CRM_B2C_Model.FeedbackProposalDetails = b2btemp.FeedbackProposalDetails;  //Added On 05.12.2018
        this.CRM_B2C_Model.NextFollowUp = b2btemp.NextFollowUp;                     //Added On 05.12.2018 

        
      });
      this.getStates(this.CRM_B2C_Model.CountryId);      
      this.getCities(this.CRM_B2C_Model.StateId);      
     
    });
  }
                                //Email validation start
  //               EmailIdCheck()
  //               {

  //                 if (this.CRM_B2C_Model.EmailId !=undefined)
  //                 {
  //                    let email = this.CRM_B2C_Model.EmailId;
                
  //                     if (email!="")
  //                       { 
  //                    let EMAIL_REGEXP = /^[a-z0-9!#$%&'*+\/=?^_`{|}~.-]+@[a-z0-9]([a-z0-9-]*[a-z0-9])?(\.[a-z0-9]([a-z0-9-]*[a-z0-9])?)*$/i;
  //                     if (email != "" && (email.length <= 5 || !EMAIL_REGEXP.test(email))) {
  //                      this.CRM_B2C_Model.IsEmailValid=false;
  //                       }
  //                    else
  //                         {
  //                             this.CRM_B2C_Model.IsEmailValid = true;
        
  //                         }
  
  //                         }

  //               else{
  //                      this.CRM_B2C_Model.IsEmailValid = true;

  //                   }
  //                 }                  //Email validation End


  //                   // Phone Number Validation 
                    
  //                 let phone = this.CRM_B2C_Model.PhoneNo;

  //                     if (phone!= "")
  //                     {
                        
  //                       let phone_regex = /^\+(?:[0-9] ?){6,14}[0-9]$/;

  //                if (phone != "" && (phone.length <= 10 || phone.length >= 14 ) || !phone_regex.test(phone)) {
  //                         this.CRM_B2C_Model.IsPhoneNoValid = false;
  //                     }
  //                   }
  //                       else
  //                        {
  //                         this.CRM_B2C_Model.IsPhoneNoValid = true;

  //                 }   // Phone Number Validation Ends. 

         
  // }               
  
  
                  // DATE OF BIRTH VALIDATION STARTS // 

                //       DateOfBirth(date)
                //   {
                //         this.CRM_B2C_Model.DOB = date;
                //     if (this.CRM_B2C_Model.DOB != undefined)
                //     {
                //       let DOBirth = this.CRM_B2C_Model.DOB;

                //       if (DOBirth.toString() != '') {

                //         let Datepattern = /^(((0[1-9]|[12]\d|3[01])\/(0[13578]|1[02])\/((19|[2-9]\d)\d{2}))|((0[1-9]|[12]\d|30)\/(0[13456789]|1[012])\/((19|[2-9]\d)\d{2}))|((0[1-9]|1\d|2[0-8])\/02\/((19|[2-9]\d)\d{2}))|(29\/02\/((1[6-9]|[2-9]\d)(0[48]|[2468][048]|[13579][26])|((16|[2468][048]|[3579][26])00))))$/g;

                //         // if (DOBirth.toString() != '' && (DOBirth <= 10 || DOBirth.length >= 14) || !DOBirth_regex.test(DOBirth)) {
                //         //   alert("BAD");

                //         //   this.CRM_B2C_Model.IsDOBActive = false;
                //         // }
                         
                //         if (Datepattern.test(this.CRM_B2C_Model.DOB.toString())){
                //           let yeartest = new Date().getFullYear()
                //           let year_DOB = this.CRM_B2C_Model.DOB.getFullYear();
                //           if (year_DOB < yeartest){
                //             this.CRM_B2C_Model.IsDOBValid = true;

                //           }

                //         }

                //       }
                //     }
                //       else {
                //         this.CRM_B2C_Model.IsDOBValid = false;


                //     }
                  
                // }
                       // DATE OF BIRTH VALIDATION ENDS //



    
    // if(this.CRM_B2C_Model.EmailId.length > 0)
    //     {
    //         this.CRM_B2C_Model.IsEmailValid=true;
      //       this.email = new FormControl('', [
      //       Validators.required,
      //       Validators.pattern("[^ @]*@[^ @]*"),
      //      emailDomainValidator    
    // if(this.CRM_B2C_Model.EmailId.length > 0)
    //     {
    //         this.CRM_B2C_Model.IsEmailValid=true;
      //       this.email = new FormControl('', [
      //       Validators.required,
      //       Validators.pattern("[^ @]*@[^ @]*"),
      //      emailDomainValidator
      // ]);


              
            // Date OF Birth Validation Starts
 
             DateOfBirth(date)   // Date of Birth Validation Method B2C 
                  {
                 this.CRM_B2C_Model.IsDOBValid = this.ValidationGlobaly.DateOfBirthValidation(date); 
                this.CRM_B2C_Model.DOB = date;  
                 }


              AniversaryDate(annivdate)  // Date of Anniversary Date Validation Method B2C 
                   {
               
                this.CRM_B2C_Model.AnniverSaryDate = annivdate;
                this.CRM_B2C_Model.IsAnniversaryDateValid = this.ValidationGlobaly.AniversaryDateValidation(this.CRM_B2C_Model.AnniverSaryDate);
          
                   }


              PassPortDate(date)   // Date of Passport Date Validation Method B2C
                   {      
           this.CRM_B2C_Model.IsPassportDateValid = this.ValidationGlobaly.PassportDateValidation(date);
           this.CRM_B2C_Model.PassPortValidityDate = date;
                   }

               PhoneNumberCheck(PhoneNo)     // Phone Number Validation Method B2C 
                  {
                this.CRM_B2C_Model.IsPhoneNoValid = this.ValidationGlobaly.PhoneNoCheck(PhoneNo);
                this.CRM_B2C_Model.PhoneNo = PhoneNo;
                  }

               EmailIDCheck(Email)     // Email ID Validation Method B2C 
                  {
                this.CRM_B2C_Model.IsEmailValid = this.ValidationGlobaly.EmailIdCheck(Email);
                 this.CRM_B2C_Model.EmailId= Email;
                  }

                  NextFollowUpB2C(date)         // Next Follow Up Date Validation Method B2C 
                  {
                  this.CRM_B2C_Model.IsNextFollowUpDateValid = this.ValidationGlobaly.NextFollowUpDate(date);
                    this.CRM_B2C_Model.NextFollowUp = date;    // Added On 05.12.2018
                  }





  //  

  // Anivarschange( date)
  // {
  //   this.CRM_B2C_Model.AnniverSaryDate=date;
    
  //   var CurrentDate = new Date();
  //   var CurrentYear = CurrentDate.getFullYear();
  //   var currentMonth = CurrentDate.getMonth();
  //   var CurrentDay = CurrentDate.getDate();
  //   this.vaildaniversarydate=true;
  //   var selecteedDate = new Date(date);
  //   var selectedYear=selecteedDate.getFullYear();
  //   var selectedMonth=selecteedDate.getMonth();
  //   var selectedDay=selecteedDate.getDate();

  //   if(CurrentYear > selectedYear )
  //   {
  //    this.vaildaniversarydate = false;      
  //   }
  //   else
  //    {
  //      if (currentMonth > selectedMonth)
  //      {
  //        this.vaildaniversarydate = false;
  //      }
  //      else
  //      {
  //        if(CurrentDay >= selectedDay )
  //        {
  //          this.vaildaniversarydate = false;
  //        }
  //        else
  //        {
  //          this.vaildaniversarydate = true;
  //        }
  //      }
  //    }
    
  // }

  // PassportDatechange( date)
  // {
  //   this.CRM_B2C_Model.PassPortValidityDate=date;
  //   var CurrentDate = new Date();
  //   var CurrentYear = CurrentDate.getFullYear();
  //   var currentMonth = CurrentDate.getMonth();
  //   var CurrentDay = CurrentDate.getDate();
  //   this.vaildaniversarydate=true;
  //   var selecteedDate = new Date(date);
  //   var selectedYear=selecteedDate.getFullYear();
  //   var selectedMonth=selecteedDate.getMonth();
  //   var selectedDay=selecteedDate.getDate();

  //   if(CurrentYear > selectedYear )
  //   {
  //    this.valdpassportDate = false;      
  //   }
  //   else
  //    {
  //      if (currentMonth > selectedMonth)
  //      {
  //        this.valdpassportDate = false;
  //      }
  //      else
  //      {
  //        if(CurrentDay >= selectedDay )
  //        {
  //          this.valdpassportDate = false;
  //        }
  //        else
  //        {
  //          this.valdpassportDate = true;
  //        }
  //      }
  //    }
    
  // }

  getclientType()
  {    
   let client1 : Model_ClientType ;
    client1=new Model_ClientType();
    client1.ClientId=1;
    client1.ClientTypeval="Past Client";
    let client2 : Model_ClientType ;
    client2=new Model_ClientType();
    client2.ClientId=2;
    client2.ClientTypeval="New Client";
    this.ClientType.push(client1);
    this.ClientType.push(client2);
  }


  handleFileinputDocument(files : FileList)
  {
    this.filetouploadDocument=files.item(0);
    //this.CompanyModel.LogoType= this.filetoupload.type
    var file = files[0];
    let filesize = this.filetouploadDocument.size; 
    let filetyotypearray = this.filetouploadDocument.type.split("/");
    this.CRM_B2C_Model.OtherDocumentTypeimage= filetyotypearray[1];
    this.CRM_B2C_Model.OtherDocumentType= filetyotypearray[0];
    if(this.CRM_B2C_Model.OtherDocumentType=="image")
    {
      this.CRM_B2C_Model.validDocumentImage=true; 
    }
    else
    {
      this.CRM_B2C_Model.validDocumentImage=false; 
    }
    if(filesize > 500000)
    {
      this.CRM_B2C_Model.validDocumentImage=false; 
    }
    if(!((this.CRM_B2C_Model.OtherDocumentTypeimage=='jpeg') || (this.CRM_B2C_Model.OtherDocumentTypeimage=='png') || (this.CRM_B2C_Model.OtherDocumentTypeimage=='svg')|| (this.CRM_B2C_Model.OtherDocumentTypeimage=='gif') || (this.CRM_B2C_Model.OtherDocumentTypeimage=='wbmp')))
    {
      this.CRM_B2C_Model.validDocumentImage=false; 
    }
    var reader : FileReader= new FileReader();
    reader.onload=(event:any)=>
    {
      this.fileDocumentUrl=event.target.result;
    } 
    reader.onloadend = (e) => 
    {
      this.CRM_B2C_Model.IsOtherDocumentChenge=true;
      this.CRM_B2C_Model.DocumentImage = String( reader.result);
    }
    reader.readAsDataURL(file);   
  }


  handleFileinputPassport(files : FileList)
  {
    this.filetouploadPassport=files.item(0);
    //this.CompanyModel.LogoType= this.filetoupload.type
    var file = files[0];
    let filesize = this.filetouploadPassport.size; 
    let filetyotypearray = this.filetouploadPassport.type.split("/");
    this.CRM_B2C_Model.PassportTypeimage= filetyotypearray[1];
    this.CRM_B2C_Model.PassportType= filetyotypearray[0];
    if(this.CRM_B2C_Model.PassportType=="image")
    {
      this.CRM_B2C_Model.validPassportImage=true; 
    }
    else
    {
      this.CRM_B2C_Model.validPassportImage=false; 
    }
    if(filesize > 500000)
    {
      this.CRM_B2C_Model.validPassportImage=false; 
    }
    if(!((this.CRM_B2C_Model.PassportTypeimage=='jpeg') || (this.CRM_B2C_Model.PassportTypeimage=='png') || (this.CRM_B2C_Model.PassportTypeimage=='svg')|| (this.CRM_B2C_Model.PassportTypeimage=='gif') || (this.CRM_B2C_Model.PassportTypeimage=='wbmp')))
    {
      this.CRM_B2C_Model.validPassportImage=false; 
    }
    var reader : FileReader= new FileReader();
    reader.onload=(event:any)=>
    {
      this.filePassportUrl=event.target.result;
    } 
    reader.onloadend = (e) => 
    {
      this.CRM_B2C_Model.IsPasportChenge=true;
      this.CRM_B2C_Model.PassportImage = String( reader.result);
    }
    reader.readAsDataURL(file);     
  }




public readFile(fileToRead: File)
  {
    let base64Observable = new ReplaySubject(1);
    let fileReader = new FileReader();
    fileReader.onload = event => 
    {
      base64Observable.next(fileReader.result);
    };
    fileReader.readAsDataURL(fileToRead);
    return base64Observable;
  }


  saveB2CDetails() :void
  {
    this.IsNotBusy=true;
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.CRM_B2C_Model.Active=true;
  
    if(this.CRM_B2C_Model.ID > 0)
    {
      this.B2cservice.UpdateB2CDetails(t ,this.CRM_B2C_Model).subscribe(res=>
      {
        this.OfferId=res;
        this.CRM_B2C_Model.ID=0;  
       
        if(this.OfferId>0)
        {
          localStorage.setItem('SuccessfullMessage', "Update Successfully");
          this.rout.navigateByUrl('CRM/B2B', {skipLocationChange: true}).then(()=>this.rout.navigate(["CRM/B2C"]));
          
        }
      });                  
    }                             
    else
    {
      this.B2cservice.AddB2CDetails(t ,this.CRM_B2C_Model).subscribe(res=>
      {
        this.OfferId=res;
        this.CRM_B2C_Model.ID=0;  
        
        if(this.OfferId>0)
        {
          localStorage.setItem('SuccessfullMessage', "Save Successfully");
          this.rout.navigateByUrl('CRM/B2B', {skipLocationChange: true}).then(()=>this.rout.navigate(["CRM/B2C"]));
         
        }
      }); 
    }
  }

  getCountries() 
  {
    let t = localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.organizationService.GetCountry( t).then(x => 
    {
      this.country = x;
      Object.keys(this.country).forEach( key => 
      {
        this.country[key];
        let Countrytemp = new Country();
        Countrytemp = this.country[key];
        this.Countries.push(Countrytemp);
      });     
    });      
  }

  getStates( countryid) 
  {
    this.States=[];
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t
    if(this.States.length>0)
    {
      this.States.splice(0, this.States.length);
    }
    this.organizationService.GetState( t,countryid).then(x => 
    {
      this.State = x;
      Object.keys(this.State).forEach( key => 
      {
        this.State[key];
        let Statetemp = new State();
        Statetemp = this.State[key];
        this.States.push(Statetemp);
      });     
    });      
  }

  

 

  onChangeCountry(countryid) 
  {
    if(countryid!=0)
    {
      this.getStates(countryid);
      this.CRM_B2C_Model.StateId=0;
    }
    else
    {
      this.getStates(countryid);
      this.CRM_B2C_Model.StateId=0;
     
      this.CRM_B2C_Model.CityId=0;      
      this.getCities(0);
    }
  }

  

  onChangeMister(Mr) 
  {
    
      this.CRM_B2C_Model.MR='Mr';       
    
  }


  onstatechange(stateid)
  {
    this.getCities(stateid) ;
    
    this.CRM_B2C_Model.CityId=0;
  }


  

  getCities(stateid) 
  {
    
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t
    if(this.Cities.length>0)
    {
      this.Cities.splice(0,this.Cities.length )
    }
    this.organizationService.GetCity( t,stateid).then(x => 
    {
      this.City = x;
      Object.keys(this.City).forEach( key => 
      {
        // tslint:disable-next-line:no-unused-expression
        this.City[key];
        let Citytemp = new City();
        Citytemp = this.City[key];
        this.Cities.push(Citytemp);
      });     
    });      
  }

 
  
  
 
  
  
  
  
  
  //End Organisation
  
  
  RefreshPage()
  {
   

    this.rout.navigateByUrl('/CRM/B2B/', {skipLocationChange: true}).then(()=>this.rout.navigate(["/CRM/B2C/"]));
  }

  // offered Salary
 

 

  

  
  
  

  

  

 
  

  // oncheckedEPFApplicableChange()
  // {
  //   if(this.OfferModel.OfferId==0)
  //   {
  //     this.SalaryBreakupCalculation();
  //   }
  // }

  // oncheckedESIApplicableChange() 
  // {
  //   if(this.OfferModel.OfferId==0)
  //   {
  //     this.SalaryBreakupCalculation();
  //   }
  // }

  // oncheckedPTAXApplicableChange() 
  // {
  //   if(this.OfferModel.OfferId==0)
  //   {
  //     this.SalaryBreakupCalculation();
  //   }
  // }
}
