<div class="body-content-area tabcontent" id="link1">
<div class="row">
    <div class="col-lg-9">
        <div class="row">
            <div class="col-lg-1 low-padding text-center">
                
                <!-- <img *ngIf="this.SelectedMenu.Image!=null" src={{this.SelectedMenu.Image}} class="body-icon" alt="Icon"/> -->
            </div>
            <div class="col-lg-10 clear-padding">
                <span class="page-title">Manage Zone</span>
                <small class="page-description"></small>
                <div class="col-lg-10 clear-padding" *ngIf="!(this.CanViewState)">
                    <button *ngIf="(this.CanSaveButtonView)" (click)="ADDClick()" type="button" class="btn btn-primary Add-button">ADD New</button> 
                </div>
            </div>
        </div>
    </div>
</div>
<div *ngIf="!(this.CanViewState)"> 
    <app-dialog [(visible)]="this.SuccessStatus">
        <span class="successmsg">{{this.SuccessMessage}}</span>
    </app-dialog>
    <fieldset *ngIf="!(this.FormViewShow)" class="body-form-control">
        <legend class="legend--">Zone Details</legend>
        <table id="tblZone" *ngIf="!(this.FormViewShow)" datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" class="table table-striped table-bordered text-center table-new row-border hover">
        <thead class="thead-blue text-center">
            <tr>
                <th>Sl No.</th>
                <th>Zone Code</th>
                <th>Zone Name</th>
                <th>Country Name</th>
                <th>Action</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let Zone of Zones, let i = index">
                <td scope="row">{{i+1}}</td>
                <td>{{Zone.ZoneCode}}</td>
                <td class="text-left">{{Zone.ZoneName}}</td>
                <td class="text-left">{{Zone.CountryName}}</td>
                <td>
                    <button *ngIf="this.SelectedMenu.CanEdit" type="button"  (click)="GetZoneByid(Zone.ZoneID)"class="table-edit-btn"><i class="fas fa-edit"></i> </button> 
                    <button *ngIf="this.SelectedMenu.CanDelete" type="button" (click)="DeleteZoneByid(Zone.ZoneID)"class="table-remove-btn"><i class="fas fa-trash-alt"></i></button>
                    <button *ngIf="this.SelectedMenu.CanView" type="button" (click)="ViewZoneByid(Zone.ZoneID)" class="table-view-button" type="button" ><i class="fas fa-eye"></i></button>
                </td>
            </tr>
        </tbody>
        </table>
    </fieldset>

    <fieldset *ngIf="(this.FormViewShow)" class="body-form-control">
        <legend class="legend--">Zone Creation</legend>
        <form #ZoneForm="ngForm" (ngSubmit)="saveZone()">
            <div class="row">
                <div class="col-lg-4">
                    <div class="col-body-border-right">
                        <div class="form-group row">
                            <label class="col-sm-4 col-form-label clear-padding-right">Zone Code</label>
                            <div class="col-sm-7 clear-left-padding">
                                <input type="text" [(ngModel)]="ZoneModel.ZoneCode" tabindex="1" maxlength="40" id ="ZoneCode"  name="ZoneCode" #ZoneCode="ngModel" required class="form-control form-control-custom form-control-custom-new" >
                            </div>
                            <div *ngIf="ZoneCode.invalid && (ZoneCode.dirty || ZoneCode.touched)" class="alert alert-danger">
                                <div *ngIf="ZoneCode.errors.required">Zone Code is required.</div>
                                <div *ngIf="ZoneCode.errors.maxlength">Zone Code is not more than 40 characters</div>
                            </div>
                        </div>
                        <div class="form-group row">
                            
                        </div>
                    </div>
                </div>
                
                <div class="col-lg-4">
                    <div class="col-body-border-right">
                        <div class="form-group row">
                            <label  class="col-sm-4 col-form-label clear-padding-right">Zone Name</label>
                            <div class="col-sm-7 clear-left-padding">
                                <input type="text" [(ngModel)]="ZoneModel.ZoneName" tabindex="2" id ="ZoneName" name="ZoneName" #ZoneName="ngModel" required maxlength="250" (blur)="CheckName()" class="form-control form-control-custom form-control-custom-new" >
                            </div>
                            <div *ngIf="this.Namecheck.length > 0" class="alert alert-danger">Zone Name is exist</div>
                            <div *ngIf="ZoneName.invalid && (ZoneName.dirty || ZoneName.touched)" class="alert alert-danger">
                                <div *ngIf="ZoneName.errors.required">Zone Name  is required.</div>
                                <div *ngIf="ZoneName.errors.maxlength">Zone Name is not more than 250 characters</div>
                            </div>
                        </div>
                        <div class="form-group row">
                            
                        </div>
                    </div>
                </div>

                <div class="col-lg-4">
                    <div class="form-group row">
                        <label  class="col-sm-4 col-form-label clear-padding-right">Country Name</label>
                        <div class="col-sm-7 clear-left-padding">
                            <select [(ngModel)]="ZoneModel.CountryID" tabindex="3" id ="CountryID" class="form-control" name="CountryID" #CountryID="ngModel" (blur)="CheckName()">
                                <option [value]="0" [disabled]=true >Select Country Name</option>
                                <option *ngFor="let C of Countries" [value]="C.CountryId">{{C.CountryName}}</option>
                            </select>
                        </div>
                        <div class="required-validation"  *ngIf="ZoneModel.CountryID==0">Country is required</div>
                    </div>
                    <div class="form-group row">
                        <label class="col-sm-6 col-form-label text-center clear-padding-right" *ngIf="!(this.IsDataIsInViewState)" >
                            <button type="submit" tabindex="4" class="btn btn-primary save-button" [disabled]="!(ZoneForm.valid && this.CheckNameExiststatus==true && ZoneModel.CountryID !=0 && this.DisableSave==true)" >Save</button>  
                        </label>
                        <div class="col-sm-6 col-form-label text-center">
                        <button type="button" tabindex="5" (click)="RefreshPage()" class="btn btn-danger cancle-button">Cancel</button>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </fieldset>
</div>
</div>