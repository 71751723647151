import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs/Subject';
import { OrganizationService}  from '../shared/Organization/organization.service';
import { Vertical } from  '../shared/Organization/Vertical.Model';
import { DataTableDirective } from 'angular-datatables';
import { Company } from '../shared/Organization/Company.Model';
import { Branch } from '../shared/Organization/Branch.Model';
import { UserService } from '../shared/user.service';
// Begning Confirmation
import { ConfirmationService } from '@jaspero/ng2-confirmations';
import { ResolveEmit } from '../../app/resolve-emit';
import { ConfirmSettings } from '../../app/confirm-settings';
// end of Confirmation
import { Menu } from '../shared/menu.model';

@Component({
  selector: 'app-manage-Vertical',
  templateUrl: './manage-Vertical.component.html',
  styleUrls: ['./manage-Vertical.component.css']
})
export class ManageVerticalComponent implements OnInit 
{
  VerticalModel : Vertical= new Vertical();
  userClaims: any;
  Verticals: Vertical[] = [];
  Companies : Company[]=[];
  Vertical:any;
  company : any;
  Result : string;
  SuccessStatus:boolean=false;
  SuccessMessage: string ="";
  public tableWidget: any;
  VerticalId : any;
  chechVerticalcodeval : string ="";
  //for branch Dropdown list
  BranchesdropdownList = [];
  BranchselectedItems = [];
  dropdownSettings = {};
  BranchdropdownSettings = {};
  Branches :any;
  //for branch Dropdown list
  tempVerticaladd : Vertical =new Vertical();
  //check for duplicate vertical code
  CheckVerticalCode : boolean=null;  
  checkststusedit : boolean =false;
  verticalCodecheck : string ="";
  //check for duplicate vertical code
  // Begning Permission Portion    
  SelectedMenu:Menu;
  IsDataIsInViewState : boolean=false;
  CanViewState : boolean=false;
  DisableSave :boolean=false;
  // End of the permission portion

  constructor(private organizationService: OrganizationService, private rout: Router, 
    private _confirmation: ConfirmationService, private userService: UserService) { }

  @ViewChild(DataTableDirective, {static: false})
  setDtOptions: DataTables.Settings = {};
  cardDtOptions: DataTables.Settings = {};
  dtElement: DataTableDirective;
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();

  ngOnInit() 
  {
    //Begin Permission Portion
    this.SelectedMenu=this.userService.SelectedMenu;
    if(this.SelectedMenu !=undefined)
    {
      localStorage.setItem('selectedmenu',JSON.stringify(this.SelectedMenu));
      this.rout.navigate([this.SelectedMenu.RouteName]);
    }
    else
    {
      if(JSON.parse(localStorage.getItem('selectedmenu'))!=undefined)
      {
        this.SelectedMenu = JSON.parse(localStorage.getItem('selectedmenu'));
        this.rout.navigate([this.SelectedMenu.RouteName]);
      }
    }     
    if(this.SelectedMenu.CanAdd)
    {
      this.IsDataIsInViewState=false;
    }
    else
    {
      this.IsDataIsInViewState=true;
    }
    if(this.SelectedMenu.CanView)
    {
      this.CanViewState=false;
    }
    else
    {
      this.CanViewState=true;
    }
    //End of the peremission portion
    this.DisableSave=true;
    this.BranchselectedItems =  [];
    this.BranchesdropdownList = [];
    this.BranchdropdownSettings = 
    { 
      text:"Select Branches",
      selectAllText:'Select All',
      unSelectAllText:'UnSelect All',           
      classes:"myclass custom-class",
      enableSearchFilter: true,
    };            
    this.getCompanies();
    this.dtOptions = 
    {
      pagingType: 'full_numbers',
      pageLength: 2
    };
    this.VerticalModel.CompId=0;
    if(localStorage.getItem('SuccessfullMessage'))
    {
      this.SuccessStatus=true;
      this.SuccessMessage =localStorage.getItem('SuccessfullMessage')
      localStorage.removeItem('SuccessfullMessage');
    }
  }

  settings: ConfirmSettings | any = 
  {
    overlay: true,
    overlayClickToClose: true,
    showCloseButton: true,
    confirmText: 'Yes',
    declineText: 'No'
  };

   // begning of check duplicate verticalcode
  checkVerticalcode()
  {
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    if(!this.checkststusedit)
    {
      this.organizationService.GetVerticalBycode( t, this.VerticalModel.VerticalCode).then(x => 
      {
        this.Vertical = x;
        if( this.Vertical.length >0)
        {
          this.CheckVerticalCode =false;
          this.verticalCodecheck="Exist"
        }
        else
        {
          this.CheckVerticalCode =true;
          this.verticalCodecheck="";
        }
      });
    }
    else
    {
      if(this.VerticalModel.VerticalCode !=this.chechVerticalcodeval )
      {
        this.organizationService.GetVerticalBycode( t, this.VerticalModel.VerticalCode).then(x => 
        {
          this.Vertical = x;
          if( this.Vertical.length >0)
          {
            this.CheckVerticalCode =false;
            this.verticalCodecheck="Exist"
          }
          else
          {
            this.CheckVerticalCode =true;
            this.verticalCodecheck="";
          }
        });
      }
      else
      {
        this.verticalCodecheck="";
        this.CheckVerticalCode =true;
      }
    }
  }
  // end of duplicate vettical code

  ngAfterViewInit(): void 
  {
    this.getVerticals();
    this.dtOptions = 
    {
      pagingType: 'full_numbers',
      pageLength: 4
    };
  }

  getVerticals()
  {
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.organizationService.GetVertical( t).then(x => 
    {
      this.Vertical = x;
      Object.keys(this.Vertical).forEach( key => 
      {
        this.Vertical[key];
        let Verticaltemp = new Vertical();
        Verticaltemp = this.Vertical[key];
        this.Verticals.push(Verticaltemp);
      });
      this.dtTrigger.next();
    });
  }

  GetVerticalByid(id) 
  {
        // Begning of the permission portion
      this.IsDataIsInViewState=false;
      // End of the permision portion

    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.organizationService.GetVerticalByid( t,id).then(x => 
    {
      this.Vertical = x;
      Object.keys(this.Vertical).forEach( key => 
      {
        this.Vertical[key];
        let Verticaltemp = new Vertical();
        Verticaltemp = this.Vertical[key];
        this.VerticalModel.VerticalId=Verticaltemp.VerticalId;
        this.VerticalModel.VerticalCode=Verticaltemp.VerticalCode;
        this.VerticalModel.VerticalName=Verticaltemp.VerticalName;
        this.VerticalModel.CompId=Verticaltemp.CompId;
        this.VerticalModel.branches= Verticaltemp.branches;
      });

      this.getBranches(this.VerticalModel.CompId);

   // check for duplicate vertical code 
      this.checkststusedit=true;
      this.chechVerticalcodeval = this.VerticalModel.VerticalCode;
      this.CheckVerticalCode=true;      
      this.verticalCodecheck="";
 // check for duplicate vertical code 
    });
  }

  ViewVerticalByid(id) 
  {
    //begin permission
    this.IsDataIsInViewState=true;
    //end permission
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.organizationService.GetVerticalByid( t,id).then(x => 
    {
      this.Vertical = x;
      Object.keys(this.Vertical).forEach( key => 
      {
        this.Vertical[key];
        let Verticaltemp = new Vertical();
        Verticaltemp = this.Vertical[key];
        this.VerticalModel.VerticalId=Verticaltemp.VerticalId;
        this.VerticalModel.VerticalCode=Verticaltemp.VerticalCode;
        this.VerticalModel.VerticalName=Verticaltemp.VerticalName;
        this.VerticalModel.CompId=Verticaltemp.CompId;
        this.VerticalModel.branches= Verticaltemp.branches;
      });
      this.getBranches(this.VerticalModel.CompId);
    });
  }
    
  getCompanies() 
  {
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.organizationService.GetCompany( t).then(x => 
    {
      this.company = x;
      Object.keys(this.company).forEach( key => 
      {
        // tslint:disable-next-line:no-unused-expression
        this.company[key];
        let Companytemp = new Company();
        Companytemp = this.company[key];
        this.Companies.push(Companytemp);
      });     
    });      
  }
   
  saveVertical() :void
  {
    this.DisableSave=false;
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.VerticalModel.IsActive=true;
    if(this.VerticalModel.VerticalId > 0)
    {
      if(this.BranchselectedItems.length > 0)  
      {
        this.VerticalModel.branches='';
        this.BranchselectedItems .forEach(element => 
        {
          this.VerticalModel.branches= this.VerticalModel.branches + element.id + ",";
        });
        var l = this.VerticalModel.branches.length
        this.VerticalModel.branches = this.VerticalModel.branches.substr(0,l-1);
        this.organizationService.UpdateVertical(t ,this.VerticalModel).subscribe(res=>
        {
          this.VerticalId=res;
          this.VerticalModel.VerticalId=0;  
          if(this.VerticalId>0)
          {
            localStorage.setItem('SuccessfullMessage', "Update Successfully");
            this.rout.navigateByUrl('/Organization/ManageBranch', {skipLocationChange: true}).then(()=>this.rout.navigate(["/Organization/ManageVertical"]));
          }
        });
      }
    }
    else
    {
      if(this.BranchselectedItems.length > 0)  
      {
        this.VerticalModel.branches='';
        this.BranchselectedItems .forEach(element => 
        {
          this.VerticalModel.branches= this.VerticalModel.branches + element.id + ",";
        });
        var l = this.VerticalModel.branches.length
        this.VerticalModel.branches = this.VerticalModel.branches.substr(0,l-1);
        this.organizationService.AddVertical(t ,this.VerticalModel).subscribe(res=>
        {
          this.VerticalId=res;
          this.VerticalModel.VerticalId=0;              
          if(this.VerticalId>0)
          {
            localStorage.setItem('SuccessfullMessage', "Save Successfully");
            this.rout.navigateByUrl('/Organization/ManageBranch', {skipLocationChange: true}).then(()=>this.rout.navigate(["/Organization/ManageVertical"]));
          }
        }); 
      }
    }
  }

  DeleteVerticalByid(VerticalId : number) :void
  {
    this._confirmation.create('Confirm to Deleate', 'Press Yes to Delete', this.settings)
    .subscribe((ans: ResolveEmit) => 
    {
      if (ans.resolved == true) 
      {
        let t =  localStorage.getItem('userToken');
        t = 'Bearer' + t;
        this.VerticalModel.IsActive=true;
        if( VerticalId > 0)
        {
          this.organizationService.DeleteVertical(t ,VerticalId).subscribe(res=>
          {
            this.VerticalId=res;
            this.VerticalModel.VerticalId=0;                                  
            if(this.VerticalId >0)
            {
              localStorage.setItem('SuccessfullMessage', "Delete Successfully");
              this.rout.navigateByUrl('/Organization/ManageBranch', {skipLocationChange: true}).then(()=>this.rout.navigate(["/Organization/ManageVertical"]));
            }
          });                  
        }
      }
    })
  }

  getBranches(companyid) 
  {
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t
    this.BranchselectedItems = [];
    this.BranchesdropdownList = [];
    this.organizationService.GetBranchByCompanyId( t,companyid).then(x => 
    {
      this.Branches = x;
      Object.keys(this.Branches).forEach( key => 
      {
        // tslint:disable-next-line:no-unused-expression
        this.Branches[key];
        let  Branchtemp= new Branch();
        Branchtemp = this.Branches[key];
        this.BranchesdropdownList.push({"id":Branchtemp.BranchId,"itemName":Branchtemp.BranchName},);
      });  
      if(this.BranchesdropdownList.length > 0)  
      {
        var selected_br = this.VerticalModel.branches.split(',');
        for (var _i = 0; _i < selected_br.length; _i++) 
        {
          var item = this.BranchesdropdownList.find(x=>x.id==selected_br[_i])
          this.BranchselectedItems.push(item);
        }
      }
    });      
  }

  onItemSelect(item:any){ }
  OnItemDeSelect(item:any){ }
  onSelectAll(items:any){ }
  onDeSelectAll(items:any){ }

  RefreshPage()
  {
    this.rout.navigateByUrl('/Organization/ManageBranch', {skipLocationChange: true}).then(()=>this.rout.navigate(["/Organization/ManageVertical"]))
  }
}
