export class TourModel
{
    public TourId : number;
    public EmployeeId : number;
    public EmployeeCode : string;
    public EmployeeName : string;
    public EmployeeEmailId : string;
    public RHId :number;
    public RHCode : string;
    public RHName : string;
    public RHEmailId : string;
    public FromDate : Date;
    public ToDate : Date;
    public IsApproved : boolean;
    public IsRejected : boolean;
    public TourPlace : string;
    public Remarks :string;
    public CompanyId : number;
    public Selected : boolean;
    public RemarksAll : string;
    public SentMailid : string;
    public SentMailPassword : string;
    public HRISURL : string;
    public Status : number;
    public SearchFromDate : Date;
    public SearchToDate : Date;
}