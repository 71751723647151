export class AttendanceFineRule 
{
    public AttFineRuleId : number;
    public FineRuleName : string;
    public FineNameId : number;
    public MinDays : number;
    public MaxDays : number;
    public AttFineInTermId : number;
    public FineDays : number;
    public IsBasic : boolean;
    public IsActive : boolean;
    public FineInTermId : number;
}