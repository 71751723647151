import { Component, OnInit, TemplateRef } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from '../../shared/user.service';
import { KRAService } from '../../shared/HR_ADMIN/KRA/KRAService.service';
import { OrganizationService }  from '../../shared/Organization/organization.service';
import { KRAMasterModel } from  '../../shared/HR_ADMIN/KRA/KRAMasterModel.Model';
// Begning Confirmation
import { ConfirmationService } from '@jaspero/ng2-confirmations';
import { ResolveEmit } from '../../../app/resolve-emit';
import { ConfirmSettings } from '../../../app/confirm-settings';
import { Menu } from '../../shared/menu.model';
// end of Confirmation
import { Company } from '../../shared/Organization/Company.Model';
import { ModalOptions,BsModalRef, BsModalService } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-manage-kratemplate',
  templateUrl: './manage-kratemplate.component.html',
  styleUrls: ['./manage-kratemplate.component.css']
})
export class ManageKratemplateComponent implements OnInit 
{
  KRAMasterModel : KRAMasterModel= new KRAMasterModel();
  userClaims: any;
  Companiesshow : Company[]=[];
  Companies : Company[]=[];
  Company : any;
  KRATemplates: KRAMasterModel[] = [];
  KRAMasters: KRAMasterModel[] = [];
  KRAMaster: any;
  Result : string;
  SubMenues : Menu[]=[];
  Mod: any;
  SuccessStatus:boolean=false;
  SuccessMessage: string ="";
  public tableWidget: any;
  RefId : any;
  base64textString : string;
  tempKRAMasteradd : KRAMasterModel =new KRAMasterModel();
  // Begning Permission Portion    
  SelectedMenu:Menu;
  CanEditButtonView : boolean=false;
  CanDeleteButtonView : boolean=false;
  CanViewState : boolean=false;
  CanSaveButtonView : boolean=false;
  DisableSave : boolean=false;
  // End of the permission portion
  //check for duplicate component Name
  CheckNameExiststatus : boolean=null;  
  CheckNamestatusedit : boolean =false;
  Namecheck : string ="";
  checkName : any;
  checkNameval : string ="";
  MasterTemplateRefId : any;
  //check for duplicate component Name
  TotalKRAwts : number;
  public modalRef : BsModalRef;
  public modelopt : ModalOptions;
  NoRecordMsg : boolean;
  DeleteTemplateMsg : boolean;
  DeleteDetailsMsg : boolean;
  SaveMsg : boolean;
  ShowPopup : boolean;
  dtOptions: DataTables.Settings = {};
  
  constructor(private KRAService: KRAService, private rout: Router,private OrganizationService: OrganizationService,  private _confirmation: ConfirmationService, private userService: UserService, private modalService: BsModalService)
  {
    this.modelopt=new ModalOptions();
    this.modelopt.class='heavy_modal';
    this.modalService.config= this.modelopt;
    this.modelopt.ignoreBackdropClick=true;
  }
   
  ngOnInit() 
  {
    // Begning Permission Portion 
    this.SubMenues=[];
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    let menuid = localStorage.getItem("menuidstor");
    this.userService.GetSubMenuList( t, menuid).then(x => 
    {
      this.Mod = x;
      Object.keys(this.Mod).forEach( key => 
      {
        // tslint:disable-next-line:no-unused-expression
        this.Mod[key];
        let menutemp = new Menu();
        menutemp = this.Mod[key];
        this.SubMenues.push(menutemp);
      });
      this.userService.SelectedMenu= this.SubMenues[0];
      this.rout.navigate([this.userService.SelectedMenu.RouteName]);
      this.SelectedMenu=this.userService.SelectedMenu;
      if(this.SelectedMenu.CanAdd)
      {
        this.CanSaveButtonView=true;
      }
      if(this.SelectedMenu.CanEdit)
      {
        this.CanEditButtonView=true;
      }
      if(this.SelectedMenu.CanView)
      {
        this.CanViewState=true;
      }
      if(this.SelectedMenu.CanDelete)
      {
        this.CanDeleteButtonView=true;
      }
    });
    // End of the permission portion
    this.DisableSave=true;
    this.KRAMasterModel.TotalKRAWeighted=0;
    this.KRAMasterModel.CompanyId=0;
    this.KRAMasterModel.CompanyIdShow=0;
    this.GetCompany();
    this.getCompanies();
    this.NoRecordMsg = false;
    this.DeleteDetailsMsg = false;
    this.DeleteTemplateMsg=false;
    this.SaveMsg = false;
    this.ShowPopup = false;
  }

  settings: ConfirmSettings | any = 
  {
    overlay: true,
    overlayClickToClose: true,
    showCloseButton: true,
    confirmText: 'Yes',
    declineText: 'No'
  };
  
  getCompanies() 
  {
    this.Companiesshow=[];
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.OrganizationService.GetCompanyForDDL( t).then(x => 
    {
      this.Company = x;
      Object.keys(this.Company).forEach( key => 
      {
        this.Company[key];
        let Companytemp = new Company();
        Companytemp = this.Company[key];
        this.Companiesshow.push(Companytemp);
      });     
    });      
  }

  onChangeCompanyShow(Companyid) 
  {
    if(Companyid > 0)
    {
      this.GetKRATemplateMaster(Companyid);
    }
  }

  ADDClick(template)
  {
    this.CanSaveButtonView = true;
    this.KRATemplates=[];
    if(this.KRATemplates.length == 0 )
    {
      let TempList : KRAMasterModel[]=[];
      for( var i=0; i<3; i++ )
      {
        let temptemplate = new KRAMasterModel();          
        TempList.push(temptemplate);         
      }
      this.KRATemplates=TempList;
    }
    if(this.ShowPopup==true)
    {
      this.modalRef.hide();
    }
    this.modalRef = this.modalService.show(template,this.modelopt);
    this.ShowPopup=true;
    this.SaveMsg=false;
    this.DeleteDetailsMsg=false;
    this.DeleteTemplateMsg=false;
    this.DisableSave=true;
    this.KRAMasterModel.TemplateName=null;
    this.KRAMasterModel.CompanyId=0;
  }

  GetKRATemplateMaster(Companyid)
  {
    this.KRAMasters=[];
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.KRAService.GetKRATemplateMaster( t,Companyid).then(x => 
    {
      this.NoRecordMsg = false;
      this.KRAMasters=[];
      this.KRAMaster = x;
      Object.keys(this.KRAMaster).forEach( key => 
      {
        this.KRAMaster[key];
        let KRAMastertemp = new KRAMasterModel();
        KRAMastertemp = this.KRAMaster[key];
        this.KRAMasters.push(KRAMastertemp);
      });
      if(this.KRAMasters.length == 0)
      {
        this.NoRecordMsg = true;
      }
    });
  }
  
  GetKRATemplateDetails(id,template) 
  { 
    this.TotalKRAwts=0;
    this.KRATemplates=[];
    // Begning of the permission portion
    this.CanSaveButtonView = true;
    // End of the permision portion
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.KRAService.GetKRATemplateDetails( t,id).then(x => 
    {
      if(this.ShowPopup==true)
      {
        this.modalRef.hide();
      }
      this.modalRef = this.modalService.show(template,this.modelopt);
      this.ShowPopup=true;
      this.SaveMsg=false;
      this.DeleteDetailsMsg=false;
      this.DeleteTemplateMsg=false;
      this.DisableSave=true;
      this.RefId = x;
      if(this.RefId.length > 0)
      {
        this.KRATemplates=[];
      }
      Object.keys(this.RefId).forEach( key => 
      {
        // tslint:disable-next-line:no-unused-expression
        this.RefId[key];
        let KRAMastertemp = new KRAMasterModel();
        KRAMastertemp = this.RefId[key];
        this.TotalKRAwts=this.TotalKRAwts + KRAMastertemp.KRAWeighted;
        if(KRAMastertemp.KRAWeighted == 0)
        {
          KRAMastertemp.KRAWeighted = null;
        }
        this.KRATemplates.push(KRAMastertemp);
        this.KRAMasterModel.TemplateName=KRAMastertemp.TemplateName;
        this.KRAMasterModel.TemplateRefId=KRAMastertemp.TemplateMasterRefId;
        this.KRAMasterModel.TotalKRAWeighted=this.TotalKRAwts;
        this.KRAMasterModel.CompanyId=KRAMastertemp.CompanyId;
      });
      // Begin for check Name duplication
      this.checkNameval= this.KRAMasterModel.KRAName;
      this.CheckNamestatusedit=true;
      this.CheckNameExiststatus =true;
      this.Namecheck="";
      // End for check Name duplication
    });
  }

  viewKRATemplateDetails(id,template) 
  {
    this.KRATemplates=[];
    //begin permission
    this.CanSaveButtonView = false;
    //end permission
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.KRAService.GetKRATemplateDetails( t,id).then(x => 
    {
      if(this.ShowPopup==true)
      {
        this.modalRef.hide();
      }
      this.modalRef = this.modalService.show(template,this.modelopt);
      this.ShowPopup=true;
      this.SaveMsg=false;
      this.DeleteDetailsMsg=false;
      this.DeleteTemplateMsg=false;
      this.DisableSave=true;
      this.RefId = x;
      if(this.RefId.length > 0)
      {
        this.KRATemplates=[];
      }
      Object.keys(this.RefId).forEach( key => 
      {
        // tslint:disable-next-line:no-unused-expression
        this.RefId[key];
        let KRAMastertemp = new KRAMasterModel();
        KRAMastertemp = this.RefId[key];
        this.KRATemplates.push(KRAMastertemp);
        this.KRAMasterModel.TemplateName=KRAMastertemp.TemplateName;
        this.KRAMasterModel.CompanyId=KRAMastertemp.CompanyId;
      });
      // Begin for check Name duplication
      this.checkNameval= this.KRAMasterModel.KRAName;
      this.CheckNamestatusedit=true;
      this.CheckNameExiststatus =true;
      this.Namecheck=""
      // End for check Name duplication
    });
  }

  SaveKRATemplate(template) :void
  {
    this.DisableSave=false;
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t; 
    this.KRAService.ModificationKRATemplateMaster(t,this.KRAMasterModel).subscribe(res=>
    {
      this.MasterTemplateRefId=res;
      if(this.MasterTemplateRefId > 0)
      {
        let t =  localStorage.getItem('userToken');
        t = 'Bearer' + t;
        var j=0;
        var l=this.KRATemplates.length;
        this.KRATemplates.forEach(item => 
        {
          j=j+1;
          item.TemplateMasterRefId=this.MasterTemplateRefId;
          if(item.KRAName !='' && item.KRAName!= null)
          {
            this.KRAService.ModificationKRATemplateDetails(t,item).subscribe(res=>
            {
              this.RefId=res;
              this.KRAMasterModel.RefId=0;  
            });
          }
          if(j==l)
          {
            this.KRAMasterModel.CompanyIdShow=this.KRAMasterModel.CompanyId;
            this.GetKRATemplateMaster(this.KRAMasterModel.CompanyIdShow);
            this.modalRef.hide();
            this.ShowPopup = false;
            this.SaveMsg = true;
            this.DeleteDetailsMsg=false;
            this.DeleteTemplateMsg=false;
            this.modalRef = this.modalService.show(template,this.modelopt);
            this.DisableSave=true;
          }
        });
      }
    });
  }

  DeleteKRATemplateMaster(RefId : number,template: TemplateRef<any>) :void
  {
    this._confirmation.create('Confirm to Deleate', 'Press Yes to Delete', this.settings)
      .subscribe((ans: ResolveEmit) => 
      {
        if (ans.resolved == true) 
        {
          let t =  localStorage.getItem('userToken');
          t = 'Bearer' + t;
          this.KRAMasterModel.IsActive=true;
          if( RefId > 0)
          {
            this.KRAService.DeleteKRATemplateMaster(t ,RefId).subscribe(res=>
            {
              this.RefId=res;
              this.KRAMasterModel.RefId=0;                                  
              if(this.RefId >0)
              {
                if(this.ShowPopup==true)
                {
                  this.modalRef.hide();
                }
                this.GetKRATemplateMaster(this.KRAMasterModel.CompanyId);
                this.ShowPopup = false;
                this.SaveMsg = false;
                this.DeleteDetailsMsg = false;
                this.DeleteTemplateMsg = true;
                this.modalRef = this.modalService.show(template,this.modelopt);
                this.DisableSave = true;
              }   
            });                  
          }
        }
      })
  }
 
  DeleteKRATemplateDetail(RefId : number,template: TemplateRef<any>) :void
  {
    if(confirm("Are you sure Want To Delete"))
    {
      let t =  localStorage.getItem('userToken');
      t = 'Bearer' + t;
      if( RefId > 0)
      {
        this.KRAService.DeleteKRATemplateDetails(t ,RefId).subscribe(res=>
        {
          this.MasterTemplateRefId=res;
          this.KRAMasterModel.RefId=0;                                  
          if(this.MasterTemplateRefId >0)
          {
            this.GetKRATemplateDetails(this.MasterTemplateRefId,template);
            this.DisableSave = true;
          }  
        }); 
      }
    }
  }

  AddnewRow()
  {
    let tempKRA = new KRAMasterModel();          
    this.KRATemplates.push(tempKRA);     
  }
  // begning of check duplicate componentcode
  CheckName()
  {
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    if(!this.CheckNamestatusedit)
    {
      this.KRAService.CheckKRATemplateMasterName( t, this.KRAMasterModel.TemplateName,this.KRAMasterModel.CompanyId).then(x => 
      {
        this.checkName = x;
        if( this.checkName.length >0)
        {
          this.CheckNameExiststatus =false;
          this.Namecheck="Exist"
        }
        else
        {
          this.CheckNameExiststatus =true;
          this.Namecheck="";
        }
     });
    }
    else
    {
      if(this.KRAMasterModel.KRAName !=this.checkNameval )
      {
        this.KRAService.CheckKRATemplateMasterName( t, this.KRAMasterModel.TemplateName,this.KRAMasterModel.CompanyId).then(x => 
        {
          this.checkName = x;
          if( this.checkName.length >0)
          {
            this.CheckNameExiststatus =false;
            this.Namecheck="Exist"
          }
          else
          {
            this.CheckNameExiststatus =true;
            this.Namecheck="";
          }
        });
      }
      else
      {
        this.CheckNameExiststatus =true;
        this.Namecheck="";
      }
    }
  }
  // end of duplicate Name
  WategesChanged()
  {
    this.TotalKRAwts=0;
    var j=0;
    var l=this.KRATemplates.length;
    this.KRATemplates.forEach(item => 
    {
      j=j+1;
      if(item.KRAWeighted > 0)
      { 
        this.TotalKRAwts=  Number(this.TotalKRAwts) + Number(item.KRAWeighted);
      }
      this.KRAMasterModel.TotalKRAWeighted=this.TotalKRAwts;
    });
  }

  GetCompany() 
  {
    this.Companies=[];
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.OrganizationService.GetCompanyForDDL( t).then(x => 
    {
      this.Company = x;
      Object.keys(this.Company).forEach( key => 
      {
        this.Company[key];
        let Companytemp = new Company();
        Companytemp = this.Company[key];
        this.Companies.push(Companytemp);
      });     
    });      
  }
}