import { Component, OnInit, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Router } from '@angular/router';
import { UserService } from '../../shared/user.service';
import { Form12BBModel } from '../../shared/HR_ADMIN/Salary/Form12BBModel.model';
import { SalaryMasterService }  from '../../shared/HR_ADMIN/Salary/SalaryMaster.service';
//End of notification service
import { Menu } from '../../shared/menu.model';
// Begning Confirmation
import { ConfirmationService } from '@jaspero/ng2-confirmations';
import { ResolveEmit } from '../../../app/resolve-emit';
import { ConfirmSettings } from '../../../app/confirm-settings';
// end of Confirmation

@Component({
  selector: 'app-declaration-form12-bb',
  templateUrl: './declaration-form12-bb.component.html',
  styleUrls: ['./declaration-form12-bb.component.css']
})

export class DeclarationForm12BbComponent implements OnInit 
{
  Form12BBModel: Form12BBModel = new Form12BBModel();
  userClaims: any;
  SubMenues: Menu[] = [];
  Mod: any;
  initialrow : number=0;
  Result: string;
  SuccessStatus: boolean = false;
  SuccessMessage: string = "";
  public tableWidget: any;
  Employee: any;
  RefId: any;
  selectedIndex: number;
  // Begning Permission Portion    
  SelectedMenu: Menu;
  IsDataIsInViewState: boolean = false;
  CanViewState: boolean = true;
  DisableSave: boolean = false;
  // End of the permission portion
  OldType : boolean;
  NewType : boolean;
  TdsTypeValue: string;

  constructor(private rout: Router, private userService: UserService,private _confirmation: ConfirmationService, private SalaryService : SalaryMasterService, @Inject(DOCUMENT) document) { }

  ngOnInit()
  {
    // Begning Permission Portion 
    this.SelectedMenu=this.userService.SelectedMenu;
    if(this.SelectedMenu !=undefined)
    {
      localStorage.setItem('selectedmenu',JSON.stringify(this.SelectedMenu));
      this.rout.navigate([this.SelectedMenu.RouteName]);
    }
    else
    {
      if(JSON.parse(localStorage.getItem('selectedmenu'))!=undefined)
      {
        this.SelectedMenu = JSON.parse(localStorage.getItem('selectedmenu'));
        this.rout.navigate([this.SelectedMenu.RouteName]);
      }
    }
    if(this.SelectedMenu.CanAdd)
    {
      this.IsDataIsInViewState=false;
    }
    else
    {
      this.IsDataIsInViewState=true;
    }
    if(this.SelectedMenu.CanView)
    {
      this.CanViewState=false;
    }
    else
    {
      this.CanViewState=true;
    }
    // End of the permission portion
    this.DisableSave = true;
    this.GetEmployeeDetails();
    this.GetEmployeeFORM12BBByEmployeeId();
    if (localStorage.getItem('SuccessfullMessage')) 
    {
      this.SuccessStatus = true;
      this.SuccessMessage = localStorage.getItem('SuccessfullMessage')
      localStorage.removeItem('SuccessfullMessage');
    }
  }
  
  settings: ConfirmSettings | any = 
  {
    overlay: true,
    overlayClickToClose: true,
    showCloseButton: true,
    confirmText: 'Yes',
    declineText: 'No'
  };
  OnChangeOldType(evt)
  {
    var target = evt.target;
    if(target.checked)
    {           
      this.TdsTypeValue="OldType";
      this.OldType=true;
      this.NewType=false;
    }
  }

  OnChangeNewType(evt)
  {
    var target = evt.target;
    if(target.checked)
    {           
      this.TdsTypeValue="NewType";
    }
    this.OldType=false;
    this.NewType=true;
  }
  
  GetEmployeeDetails()
  {
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.SalaryService.GetEmployeeDetailsForForm12BBByUserId( t).then(x => 
    {
      this.Employee = x;
      this.Form12BBModel.EmployeeCode = this.Employee.EmployeeCode;
      this.Form12BBModel.Financialyear = this.Employee.Financialyear;
      this.Form12BBModel.EmployeeCode = this.Employee.EmployeeCode;
      this.Form12BBModel.EmployeeName = this.Employee.EmployeeName;
      this.Form12BBModel.EmployeeAddress = this.Employee.EmployeeAddress;
      this.Form12BBModel.ContactNo = this.Employee.ContactNo;
      this.Form12BBModel.DepartmentName = this.Employee.DepartmentName;
      this.Form12BBModel.EmployeePANNo = this.Employee.EmployeePANNo;
      this.Form12BBModel.DesignationName = this.Employee.DesignationName;
      this.Form12BBModel.FathersName = this.Employee.FathersName;
      this.Form12BBModel.EmployeeId = this.Employee.EmployeeId;
      this.Form12BBModel.FinYear = this.Employee.FinYear;
    });
  }

  GetEmployeeFORM12BBByEmployeeId()
  {
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.SalaryService.GetEmployeeFORM12BBByEmployeeId( t).then(x => 
    {
      this.Employee = x;
      this.Form12BBModel.RefId = this.Employee.RefId;
      this.Form12BBModel.HRA_Rent = this.Employee.HRA_Rent;
      if(this.Form12BBModel.HRA_Rent == 0)
      {
        this.Form12BBModel.HRA_Rent=null;
      }
      this.Form12BBModel.HRA_LandLordName = this.Employee.HRA_LandLordName;
      this.Form12BBModel.HRA_LandLordAddress = this.Employee.HRA_LandLordAddress;
      this.Form12BBModel.HRA_LandLordPANNo = this.Employee.HRA_LandLordPANNo;
      this.Form12BBModel.LeaveTravelConcessions = this.Employee.LeaveTravelConcessions;
      if(this.Form12BBModel.LeaveTravelConcessions == 0)
      {
        this.Form12BBModel.LeaveTravelConcessions=null;
      }
      this.Form12BBModel.DIB_LenderPaidAmount = this.Employee.DIB_LenderPaidAmount;
      if(this.Form12BBModel.DIB_LenderPaidAmount == 0)
      {
        this.Form12BBModel.DIB_LenderPaidAmount=null;
      }
      this.Form12BBModel.DIB_LenderName = this.Employee.DIB_LenderName;
      this.Form12BBModel.DIB_LenderAddress = this.Employee.DIB_LenderAddress;
      this.Form12BBModel.DIB_LenderPANNo = this.Employee.DIB_LenderPANNo;
      this.Form12BBModel.FinancialInstitutions = this.Employee.FinancialInstitutions;
      if(this.Form12BBModel.FinancialInstitutions == 0)
      {
        this.Form12BBModel.FinancialInstitutions=null;
      }
      this.Form12BBModel.Employer = this.Employee.Employer;
      if(this.Form12BBModel.Employer == 0)
      {
        this.Form12BBModel.Employer=null;
      }
      this.Form12BBModel.Other = this.Employee.Other;
      if(this.Form12BBModel.Other == 0)
      {
        this.Form12BBModel.Other=null;
      }
      this.Form12BBModel.TotalDedUnderVIA = this.Employee.TotalDedUnderVIA;
      this.Form12BBModel.TotalSEC80C_80CCC_80CCD = this.Employee.TotalSEC80C_80CCC_80CCD;
      this.Form12BBModel.TotalSEC80C = this.Employee.TotalSEC80C;
      this.Form12BBModel.Insurance_80C = this.Employee.Insurance_80C;
      if(this.Form12BBModel.Insurance_80C == 0)
      {
        this.Form12BBModel.Insurance_80C=null;
      }
      this.Form12BBModel.PF_PPF_80C = this.Employee.PF_PPF_80C;
      if(this.Form12BBModel.PF_PPF_80C == 0)
      {
        this.Form12BBModel.PF_PPF_80C=null;
      }
      this.Form12BBModel.NPS_80C = this.Employee.NPS_80C;
      if(this.Form12BBModel.NPS_80C == 0)
      {
        this.Form12BBModel.NPS_80C=null;
      }
      this.Form12BBModel.ELSS_80C = this.Employee.ELSS_80C;
      if(this.Form12BBModel.ELSS_80C == 0)
      {
        this.Form12BBModel.ELSS_80C=null;
      }
      this.Form12BBModel.HousingLoan_80C = this.Employee.HousingLoan_80C;
      if(this.Form12BBModel.HousingLoan_80C == 0)
      {
        this.Form12BBModel.HousingLoan_80C=null;
      }
      this.Form12BBModel.FD_80C = this.Employee.FD_80C;
      if(this.Form12BBModel.FD_80C == 0)
      {
        this.Form12BBModel.FD_80C=null;
      }
      this.Form12BBModel.SukanayaSamriddhi_80C = this.Employee.SukanayaSamriddhi_80C;
      if(this.Form12BBModel.SukanayaSamriddhi_80C == 0)
      {
        this.Form12BBModel.SukanayaSamriddhi_80C=null;
      }
      this.Form12BBModel.SchoolTution_80C = this.Employee.SchoolTution_80C;
      this.Form12BBModel.SchoolTutuion_C1 = this.Employee.SchoolTutuion_C1;
      if(this.Form12BBModel.SchoolTutuion_C1 == 0)
      {
        this.Form12BBModel.SchoolTutuion_C1=null;
      }
      this.Form12BBModel.SchoolTutuion_C2 = this.Employee.SchoolTutuion_C2;
      if(this.Form12BBModel.SchoolTutuion_C2 == 0)
      {
        this.Form12BBModel.SchoolTutuion_C2=null;
      }
      this.Form12BBModel.MutaulFund_80CCC = this.Employee.MutaulFund_80CCC;
      if(this.Form12BBModel.MutaulFund_80CCC == 0)
      {
        this.Form12BBModel.MutaulFund_80CCC=null;
      }
      this.Form12BBModel.EPP_80CCD = this.Employee.EPP_80CCD;
      if(this.Form12BBModel.EPP_80CCD == 0)
      {
        this.Form12BBModel.EPP_80CCD=null;
      }
      this.Form12BBModel.OtherSection_80E_80G_80TTA = this.Employee.OtherSection_80E_80G_80TTA;
      this.Form12BBModel.MIParents_80D = this.Employee.MIParents_80D;
      if(this.Form12BBModel.MIParents_80D == 0)
      {
        this.Form12BBModel.MIParents_80D=null;
      }
      this.Form12BBModel.IPEL_80E = this.Employee.IPEL_80E;
      if(this.Form12BBModel.IPEL_80E == 0)
      {
        this.Form12BBModel.IPEL_80E=null;
      }
      this.Form12BBModel.Donation_80G = this.Employee.Donation_80G;
      if(this.Form12BBModel.Donation_80G == 0)
      {
        this.Form12BBModel.Donation_80G=null;
      }
      this.Form12BBModel.SEC80TTA_IRSBAc = this.Employee.SEC80TTA_IRSBAc;
      if(this.Form12BBModel.SEC80TTA_IRSBAc == 0)
      {
        this.Form12BBModel.SEC80TTA_IRSBAc=null;
      }
      this.Form12BBModel.SEC24_IPHL = this.Employee.SEC24_IPHL;
      if(this.Form12BBModel.SEC24_IPHL == 0)
      {
        this.Form12BBModel.SEC24_IPHL=null;
      }
      this.Form12BBModel.StartDate = this.Employee.StartDate;
      this.Form12BBModel.EndDate = this.Employee.EndDate;
      this.Form12BBModel.FinalSubmit=this.Employee.FinalSubmit;
      this.Form12BBModel.SaveButtonView = this.Employee.SaveButtonView;
      this.Form12BBModel.ShowTextMessage=this.Employee.ShowTextMessage;
      this.Form12BBModel.TDSType=this.Employee.TDSType;
      if(this.Form12BBModel.TDSType=='Old')
      {
        this.OldType = true;
        this.NewType = false;
        this.TdsTypeValue = 'OldType';
      }
      else
      {
        this.OldType = false;
        this.NewType = true;
        this.TdsTypeValue = 'NewType';
      }
    });
  }

  print(): void
  {
    let printContents, popupWin;
    printContents = document.getElementById('Form12BB').innerHTML;
    popupWin = window.open('', '_blank', 'letf=0,top=0,width=2480px,height=3508px,toolbar=0, scrollbars=0,sta­tus=0');
    popupWin.document.open();
    popupWin.document.write(`
      <html>
        <head>
          <title>Form-12BB</title>
          <style=" margin:25px 10px 15px 30px; font-family:Calibri;">
          
          </style>
        </head>
      <body onload="window.print();window.close()">${printContents}</body>
      </html>`
    );
    popupWin.document.close();
  }

  SaveForm12BB() :void
  {
    this.Form12BBModel.FinalSubmit=false;
    this.DisableSave=false;
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    if(this.NewType == true)
    {
      this.Form12BBModel.HRA_Rent = 0;
      this.Form12BBModel.HRA_LandLordName = "";
      this.Form12BBModel.HRA_LandLordAddress = "";
      this.Form12BBModel.HRA_LandLordPANNo = "";
      this.Form12BBModel.LeaveTravelConcessions = 0;
      this.Form12BBModel.DIB_LenderPaidAmount = 0;
      this.Form12BBModel.DIB_LenderName = "";
      this.Form12BBModel.DIB_LenderAddress = "";
      this.Form12BBModel.DIB_LenderPANNo = "";
      this.Form12BBModel.FinancialInstitutions = 0;
      this.Form12BBModel.Employer = 0;
      this.Form12BBModel.Other = 0;
      this.Form12BBModel.TotalDedUnderVIA = 0;
      this.Form12BBModel.TotalSEC80C_80CCC_80CCD = 0;
      this.Form12BBModel.TotalSEC80C = 0;
      this.Form12BBModel.Insurance_80C = 0;
      this.Form12BBModel.PF_PPF_80C = 0;
      this.Form12BBModel.NPS_80C = 0;
      this.Form12BBModel.ELSS_80C = 0;
      this.Form12BBModel.HousingLoan_80C = 0;
      this.Form12BBModel.FD_80C = 0;
      this.Form12BBModel.SukanayaSamriddhi_80C = 0;
      this.Form12BBModel.SchoolTution_80C = 0;
      this.Form12BBModel.SchoolTutuion_C1 = 0;
      this.Form12BBModel.SchoolTutuion_C2 = 0;
      this.Form12BBModel.MutaulFund_80CCC = 0;
      this.Form12BBModel.EPP_80CCD = 0;
      this.Form12BBModel.OtherSection_80E_80G_80TTA = 0;
      this.Form12BBModel.MIParents_80D = 0;
      this.Form12BBModel.IPEL_80E = 0;
      this.Form12BBModel.Donation_80G = 0;
      this.Form12BBModel.SEC80TTA_IRSBAc = 0;
      this.Form12BBModel.SEC24_IPHL = 0;
      this.Form12BBModel.TDSType="New";
    }
    else
    {
      this.Form12BBModel.TDSType="Old"
    }
    this.SalaryService.AddFORM12BB(t ,this.Form12BBModel).subscribe(res=>
    {
      this.RefId=res;
      this.Form12BBModel.RefId=0;              
      if(this.RefId>0)
      {
        localStorage.setItem('SuccessfullMessage', "Save Successfully");
        this.rout.navigateByUrl('/EmployeePortal/ManageEmployeeModification', {skipLocationChange: true}).then(()=>this.rout.navigate(["/EmployeePortal/Form12BB"]));
      }
    });
  }

  FinalSaveForm12BB() :void
  {
    this._confirmation.create('Confirm to Final Submit', 'Press Yes to Final Submit', this.settings)
    .subscribe((ans: ResolveEmit) => 
    {
      if (ans.resolved == true) 
      {
        this.Form12BBModel.FinalSubmit=true;
        this.DisableSave=false;
        let t =  localStorage.getItem('userToken');
        t = 'Bearer' + t;
        if(this.NewType == true)
        {
          this.Form12BBModel.HRA_Rent = 0;
          this.Form12BBModel.HRA_LandLordName = "";
          this.Form12BBModel.HRA_LandLordAddress = "";
          this.Form12BBModel.HRA_LandLordPANNo = "";
          this.Form12BBModel.LeaveTravelConcessions = 0;
          this.Form12BBModel.DIB_LenderPaidAmount = 0;
          this.Form12BBModel.DIB_LenderName = "";
          this.Form12BBModel.DIB_LenderAddress = "";
          this.Form12BBModel.DIB_LenderPANNo = "";
          this.Form12BBModel.FinancialInstitutions = 0;
          this.Form12BBModel.Employer = 0;
          this.Form12BBModel.Other = 0;
          this.Form12BBModel.TotalDedUnderVIA = 0;
          this.Form12BBModel.TotalSEC80C_80CCC_80CCD = 0;
          this.Form12BBModel.TotalSEC80C = 0;
          this.Form12BBModel.Insurance_80C = 0;
          this.Form12BBModel.PF_PPF_80C = 0;
          this.Form12BBModel.NPS_80C = 0;
          this.Form12BBModel.ELSS_80C = 0;
          this.Form12BBModel.HousingLoan_80C = 0;
          this.Form12BBModel.FD_80C = 0;
          this.Form12BBModel.SukanayaSamriddhi_80C = 0;
          this.Form12BBModel.SchoolTution_80C = 0;
          this.Form12BBModel.SchoolTutuion_C1 = 0;
          this.Form12BBModel.SchoolTutuion_C2 = 0;
          this.Form12BBModel.MutaulFund_80CCC = 0;
          this.Form12BBModel.EPP_80CCD = 0;
          this.Form12BBModel.OtherSection_80E_80G_80TTA = 0;
          this.Form12BBModel.MIParents_80D = 0;
          this.Form12BBModel.IPEL_80E = 0;
          this.Form12BBModel.Donation_80G = 0;
          this.Form12BBModel.SEC80TTA_IRSBAc = 0;
          this.Form12BBModel.SEC24_IPHL = 0;
          this.Form12BBModel.TDSType="New";
        }
        else
        {
          this.Form12BBModel.TDSType="Old"
        }
        this.SalaryService.AddFORM12BB(t ,this.Form12BBModel).subscribe(res=>
        {
          this.RefId=res;
          this.Form12BBModel.RefId=0;              
          if(this.RefId>0)
          {
            localStorage.setItem('SuccessfullMessage', "Final Submitted Successfully");
            this.rout.navigateByUrl('/EmployeePortal/ManageEmployeeModification', {skipLocationChange: true}).then(()=>this.rout.navigate(["/EmployeePortal/Form12BB"]));
          }
        });
      }
    })
  }

  InsuranceCalculation()
  {
    if((Number(this.Form12BBModel.TotalSEC80C) + Number(this.Form12BBModel.Insurance_80C)) > 150000)
    {
      this.Form12BBModel.Insurance_80C=0;
    }
    this.SEC80CCalculation();
  }

  PFEPFCalculation()
  {
    if((Number(this.Form12BBModel.TotalSEC80C) + Number(this.Form12BBModel.PF_PPF_80C)) > 150000)
    {
      this.Form12BBModel.PF_PPF_80C=0;
    }
    this.SEC80CCalculation();
  }

  NPSCalculation()
  {
    if((Number(this.Form12BBModel.TotalSEC80C) + Number(this.Form12BBModel.NPS_80C)) > 150000)
    {
      this.Form12BBModel.NPS_80C=0;
    }
    this.SEC80CCalculation();
  }

  ELSSCalculation()
  {
    if((Number(this.Form12BBModel.TotalSEC80C) + Number(this.Form12BBModel.ELSS_80C)) > 150000)
    {
      this.Form12BBModel.ELSS_80C=0;
    }
    this.SEC80CCalculation();
  }

  HousingLoanCalculation()
  {
    if(this.Form12BBModel.HousingLoan_80C > 150000)
    {
      this.Form12BBModel.HousingLoan_80C=0;
    }
    else if((Number(this.Form12BBModel.TotalSEC80C) + Number(this.Form12BBModel.HousingLoan_80C)) > 150000)
    {
      this.Form12BBModel.HousingLoan_80C=0;
    }
    this.SEC80CCalculation();
  }
  
  FDCalculation()
  {
    if((Number(this.Form12BBModel.TotalSEC80C) + Number(this.Form12BBModel.FD_80C)) > 150000)
    {
      this.Form12BBModel.FD_80C=0;
    }
    this.SEC80CCalculation();
  }

  SchoolTutuionCalculation()
  {
    this.Form12BBModel.SchoolTution_80C = Number(this.Form12BBModel.SchoolTutuion_C1) + Number(this.Form12BBModel.SchoolTutuion_C2);
    if((Number(this.Form12BBModel.TotalSEC80C) + Number(this.Form12BBModel.SchoolTution_80C)) > 150000)
    {
      this.Form12BBModel.SchoolTution_80C=0;
      this.Form12BBModel.SchoolTutuion_C1=0;
      this.Form12BBModel.SchoolTutuion_C2=0;
    }
    this.SEC80CCalculation();
  }
    
  SukanayaSamriddhiCalculation()
  {
    if((Number(this.Form12BBModel.TotalSEC80C) + Number(this.Form12BBModel.SukanayaSamriddhi_80C)) > 150000)
    {
      this.Form12BBModel.SukanayaSamriddhi_80C=0;
    }
    this.SEC80CCalculation();
  }

  SEC80CCalculation()
  {
    this.Form12BBModel.TotalSEC80C=(
      Number(this.Form12BBModel.Insurance_80C) +
      Number(this.Form12BBModel.PF_PPF_80C) +
      Number(this.Form12BBModel.NPS_80C) +
      Number(this.Form12BBModel.ELSS_80C) +
      Number(this.Form12BBModel.HousingLoan_80C) +
      Number(this.Form12BBModel.FD_80C) +
      Number(this.Form12BBModel.SchoolTution_80C) +
      Number(this.Form12BBModel.SukanayaSamriddhi_80C) 
      );
      this.SEC80C_80CCC_80CCDCalculation();
  }

  SEC80C_80CCC_80CCDCalculation()
  {
    this.Form12BBModel.TotalSEC80C_80CCC_80CCD = 
    Number(this.Form12BBModel.TotalSEC80C) + 
    Number(this.Form12BBModel.MutaulFund_80CCC) +
    Number(this.Form12BBModel.EPP_80CCD);

    this.Form12BBModel.TotalDedUnderVIA = Number(this.Form12BBModel.TotalSEC80C_80CCC_80CCD) + Number(this.Form12BBModel.OtherSection_80E_80G_80TTA);
  }
  
  SEC80CCCCalculation()
  {
    if(this.Form12BBModel.MutaulFund_80CCC > 50000)
    {
      this.Form12BBModel.MutaulFund_80CCC=0;
    }
    this.SEC80C_80CCC_80CCDCalculation();
  }
  
  SEC80CCDCalculation()
  {
    if(this.Form12BBModel.EPP_80CCD > 50000)
    {
      this.Form12BBModel.EPP_80CCD=0;
    }
    this.SEC80C_80CCC_80CCDCalculation();
  }
  
  OtherSection_80E_80G_80TTACalculation()
  {
    this.Form12BBModel.OtherSection_80E_80G_80TTA=
      Number(this.Form12BBModel.MIParents_80D) +
      Number(this.Form12BBModel.IPEL_80E) +
      Number(this.Form12BBModel.Donation_80G) +
      Number(this.Form12BBModel.SEC80TTA_IRSBAc) +
      Number(this.Form12BBModel.SEC24_IPHL);

    this.Form12BBModel.TotalDedUnderVIA = Number(this.Form12BBModel.TotalSEC80C_80CCC_80CCD) + Number(this.Form12BBModel.OtherSection_80E_80G_80TTA);
  }

  Sec80DCalculation()
  {
    if(this.Form12BBModel.MIParents_80D > 50000)
    {
      this.Form12BBModel.MIParents_80D=0;
    }
    this.OtherSection_80E_80G_80TTACalculation();
  }

  Sec80GCalculation()
  {
    if(this.Form12BBModel.Donation_80G > 50000)
    {
      this.Form12BBModel.Donation_80G=0;
    }
    this.OtherSection_80E_80G_80TTACalculation();
  }

  Sec80TTACalculation()
  {
    if(this.Form12BBModel.SEC80TTA_IRSBAc > 10000)
    {
      this.Form12BBModel.SEC80TTA_IRSBAc=0;
    }
    this.OtherSection_80E_80G_80TTACalculation();
  }

  Sec24IPHLGCalculation()
  {
    if(this.Form12BBModel.SEC24_IPHL > 200000)
    {
      this.Form12BBModel.SEC24_IPHL=0;
    }
    this.OtherSection_80E_80G_80TTACalculation();
  }
}
