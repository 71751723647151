<div class="body-content-area tabcontent" id="link1">
  <div class="row">
      <div class="col-lg-9">
          <div class="row">
              <div class="col-lg-1 low-padding text-center">
                  <!-- <img *ngIf="this.SelectedMenu.Image!=null" src={{this.SelectedMenu.Image}} class="body-icon" alt="Icon"/> -->
              </div>
              <div class="col-lg-10 clear-padding">
                  <span class="page-title">Manage Employee Mapping With Working Group</span>
                  <small class="page-description"></small>
              </div>
          </div>
      </div>
  </div>

  <div *ngIf="!(this.CanViewState)"> 
  <table id="tblAttendanceEmployeeWorkingGroup" datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" class="table table-striped table-bordered text-center table-new row-border hover">
      <thead class="thead-blue text-center">
          <tr>
              <th>Sl No.</th>
              <th>Code</th>
              <th class="text-left">Name</th>
              <th class="text-left">Working Group</th>
              <th>From Date</th>
              <!-- <th>To Date</th> -->
              <th>Action</th>
          </tr>
      </thead>
      <tbody>
          <tr *ngFor="let EMWG of MapWorkingGroups, let i = index">
              <td scope="row">{{i+1}}</td>
              <td>{{EMWG.EmployeeCode}}</td>
              <td class="text-left">{{EMWG.EmployeeName}}</td>
              <td class="text-left">{{EMWG.WorkingGroupName}}</td>
              <td>{{EMWG.FromDate | date : "dd-MMM-yyyy"}}</td>
              <!-- <td>{{EMWG.ToDate | date : "dd-MMM-yyyy"}}</td> -->
              <td>
                  <button *ngIf="this.SelectedMenu.CanEdit" type="button" (click)="GetMapWorkingGroupByid( EMWG.EmpWorkingGroupId )" class="table-edit-btn"><i class="fas fa-edit"></i> </button>
                  <button *ngIf="this.SelectedMenu.CanView" type="button" (click)="ViewMapWorkingGroupByid( EMWG.EmpWorkingGroupId )" class="table-view-button" type="button" ><i class="fas fa-eye"></i></button>
                  <!-- <button type="button" (click)="DeleteMapWorkingGroupByid( EMWG.EmpWorkingGroupId )" class="table-remove-btn"><i class="fas fa-trash-alt"></i> </button> -->
              </td>
          </tr>
      </tbody>
  </table>
  
  <fieldset class="body-form-control">
    <app-dialog [(visible)]="this.SuccessStatus">
      <span class="successmsg">{{this.SuccessMessage}}</span>
    </app-dialog>
      
    <legend class="legend--">Employee Working Group Creation</legend>
    <form #MapWorkingGroupForm="ngForm" (ngSubmit)="saveMapWorkingGroup()">
      <div class="row">
        <div class="col-lg-4">
          <div  class="col-body-border-right">
            <div class="form-group row" >
              <label class="col-sm-4 col-form-label clear-padding-right">Employee Code</label>
              <div class="col-sm-7 clear-left-padding">
                <input type="text" disabled="disabled" name="EmployeeCode" [ngModel]="MapWorkingGroupModel.EmployeeCode" #EmployeeCode="ngModel" class="form-control form-control-custom form-control-custom-new"/>  
              </div>
            </div>
            <div class="form-group row">
              <label  class="col-sm-4 col-form-label clear-padding-right" *ngIf="validGroupid" >From Date</label>
              <div class="col-sm-7 clear-left-padding">
                <input type="date" *ngIf="validGroupid"  name="FromDate"  [ngModel]="MapWorkingGroupModel.FromDate |  date:'yyyy-MM-dd'" (ngModelChange)="dateChanged($event)" #FromDate="ngModel" required />
                <label  *ngIf="(!validdate) && validGroupid" >Select Valid Date</label>
              </div>
            </div>
            <div class="form-group row">
            </div>
          </div>  
        </div>
        <div class="col-lg-4">
          <div  class="col-body-border-right">
            <div class="form-group row">
              <label class="col-sm-4 col-form-label clear-padding-right">Employee Name</label>
              <div class="col-sm-7 clear-left-padding">
                <input type="text" disabled="disabled" name="EmployeeName" [ngModel]="MapWorkingGroupModel.EmployeeName" class="form-control form-control-custom form-control-custom-new"/>  
                <input type="text" hidden="hidden" name="EmployeeId" [ngModel]="MapWorkingGroupModel.EmployeeId" #EmployeeId="ngModel"/>  
              </div>
            </div>  
            <div class="form-group row">
            </div>
            <div class="form-group row">
            </div>
          </div>
        </div>
        <div  class="col-lg-4">
          <div class="form-group row">
            <label class="col-sm-4 col-form-label clear-padding-right">Group Name</label>
            <div class="col-sm-7 clear-left-padding">
              <select [(ngModel)]="MapWorkingGroupModel.WorkingGroupId" tabindex="3" id ="WorkingGroupId" class="form-control" name="WorkingGroupId"   (ngModelChange)="onWorkingGroupChange($event)"  #WorkingGroupId="ngModel">
                <option [value]="0" [disabled]=true >Select Working Group Name</option>
                <option *ngFor="let WG of WorkingGroupHour" [value]="WG.WorkingGroupId">{{WG.WorkingGroupName}}</option>
              </select>
            </div>
            <div class="required-validation" *ngIf="MapWorkingGroupModel.WorkingGroupId == 0">Working Group is required</div>
          </div>
          <div class="form-group row">
            <label *ngIf="!(this.IsDataIsInViewState)" class="col-sm-6 col-form-label text-center clear-padding-right">
              <button type="submit" tabindex="7" class="btn btn-primary save-button" [disabled]="!(MapWorkingGroupForm.valid && this.DisableSave==true && MapWorkingGroupModel.EmployeeCode && MapWorkingGroupModel.WorkingGroupId && validdate && validGroupid)" >Save</button>  
            </label>
            <div class="col-sm-6 col-form-label text-center">
              <button type="button" tabindex="8" (click)="RefreshPage()" class="btn btn-danger cancle-button">Cancel</button>
            </div>
          </div>
        </div>
      </div>
    </form>
  </fieldset>
  </div>
</div>