import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs/Subject';
import { DataTableDirective } from 'angular-datatables';
import { UserService } from '../../../shared/user.service';
import { EmployeeMasterService }  from '../../../shared/HR_ADMIN/Employee/EmployeeMaster.Service';
import { EmployeeDetail } from '../../../shared/HR_ADMIN/Employee/EmployeeDetail.Model';
import { JoiningDetails } from '../../../shared/HR_ADMIN/Employee/JoiningDetails.Model';
import { ModalOptions,BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Menu } from '../../../shared/menu.model';

@Component({
  selector: 'app-employee-details',
  templateUrl: './employee-details.component.html',
  styleUrls: ['./employee-details.component.css']
})

export class EmployeeDetailsComponent implements OnInit 
{
  EmployeeModel : EmployeeDetail= new EmployeeDetail();
  userClaims: any;
  Employees: EmployeeDetail[] = [];
  employee: any;
  Joinings: JoiningDetails[] = [];
  joining: any;
  Result : string;
  SuccessStatus:boolean=false;
  SuccessMessage: string ="";
  public tableWidget: any;
  EmployeeId : any;
  selectedIndex : number;

  tempEmployeeadd : EmployeeDetail =new EmployeeDetail();
  // Begning Permission Portion    
  SelectedMenu:Menu;
  IsDataIsInViewState : boolean=false;
  IsButtonIsInViewState : boolean=false;
  IsEmployeeInViewState : boolean=false;
  IsFormView : boolean=false;
  IsjoiningInViewState:boolean=true;
  CanViewState:boolean=true;
  // End of the permission portion
  // begin for Validation
  validdate : boolean= false;

  // end of validation
  NoRecordJoinigMsg : boolean;
  public modelopt:ModalOptions;
  public modalRef: BsModalRef;

  constructor(public EmployeeMasterService: EmployeeMasterService,private modalService: BsModalService, private rout: Router, private userService: UserService) 
  {
    this.modelopt=new ModalOptions();
    this.modelopt.class='heavy_modal';
    this.modalService.config= this.modelopt;
    this.modelopt.ignoreBackdropClick=true;
  }

  @ViewChild(DataTableDirective, {static: false})
  setDtOptions: DataTables.Settings = {};
  cardDtOptions: DataTables.Settings = {};
  dtElement: DataTableDirective;
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();

  ngOnInit() 
  {
    this.dtOptions = 
    {
      pagingType: 'full_numbers',
      pageLength: 3
    };

    if(localStorage.getItem('SuccessfullMessage'))
    {
      this.SuccessStatus=true;
      this.SuccessMessage =localStorage.getItem('SuccessfullMessage')
      localStorage.removeItem('SuccessfullMessage');
    }
    // Begning Permission Portion 
    this.SelectedMenu=this.userService.SelectedMenu;
    if(this.SelectedMenu !=undefined)
    {
      localStorage.setItem('selectedmenu',JSON.stringify(this.SelectedMenu));
      this.rout.navigate([this.SelectedMenu.RouteName]);
    }
    else
    {
      if(JSON.parse(localStorage.getItem('selectedmenu'))!=undefined)
      {
        this.SelectedMenu = JSON.parse(localStorage.getItem('selectedmenu'));
        this.rout.navigate([this.SelectedMenu.RouteName]);
      }
    }
    if(this.SelectedMenu.CanAdd)
    {
      this.IsDataIsInViewState=false;
    }
    else
    {
      this.IsDataIsInViewState=true;
    }
    if(this.SelectedMenu.CanView)
    {
    this.CanViewState=false;
    }
    else
    {
      this.CanViewState=true;
    }
    this.IsFormView=false;
    // End of the permission portion
    this.GetEmployees();
    this.NoRecordJoinigMsg= false;
  }
  
  AddNewJoiningClick()
  {
    this.IsButtonIsInViewState = true;
    this.IsEmployeeInViewState = true;
    this.IsjoiningInViewState = false;
    this.IsFormView=false;
    this.NoRecordJoinigMsg= false;
    this.GetJoiningDetails();
  }

  DirectlyAddJoiningClick()
  {
    this.IsFormView=true;
    this.IsButtonIsInViewState = true;
    this.IsEmployeeInViewState = true;
    this.IsjoiningInViewState = true;
    this.GetjoiningDetailsByid(0);
    this.EmployeePersonalDetailsPages(0,'Employee/EmployeeDetails/PersonalDetails')
  }

  GetjoiningDetailsByid(joiningId)
  {
    this.IsFormView=true;
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.EmployeeMasterService.GetJoiningDetailsByJoiningID( t,joiningId).then(x => 
    {
      this.IsjoiningInViewState = true;
      this.employee = x;
      Object.keys(this.employee).forEach( key => 
      {
        this.employee[key];
        let employeetemp = new EmployeeDetail();
        employeetemp = this.employee[key];
        this.EmployeeMasterService.SharedEmployees=this.employee;
      });
      this.EmployeePersonalDetailsPages(0,'Employee/EmployeeDetails/PersonalDetails')
    });
  }

  GetEmpDetailsByid(EmpId)
  {
    this.IsFormView=true;
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.EmployeeMasterService.GetEmployeedetailsByEmployeeID( t,EmpId).then(x => 
    {
      this.employee = x;
      Object.keys(this.employee).forEach( key => 
      {
        this.employee[key];
        let employeetemp = new EmployeeDetail();
        employeetemp = this.employee[key];
        this.EmployeeMasterService.SharedEmployees=this.employee;
      });
      this.EmployeePersonalDetailsPages(0,'Employee/EmployeeDetails/PersonalDetails')
    });
  }

  ngAfterViewInit(): void 
  {
    this.dtOptions = 
    {
      pagingType: 'full_numbers',
      pageLength: 3
    };
  }
  
  GetEmployees()
  {
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.EmployeeMasterService.GetEmployeeDetails( t).then(x => 
    {
      this.employee = x;
      Object.keys(this.employee).forEach( key => 
      {
        this.employee[key];
        let employeetemp = new EmployeeDetail();
        employeetemp = this.employee[key];
        this.Employees.push(employeetemp);
      });
      this.dtTrigger.next();
    });
  }

  GetJoiningDetails()
  {
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.EmployeeMasterService.GetJoiningDetailsForNew( t).then(x => 
    {
      this.joining = x;
      Object.keys(this.joining).forEach( key => 
      {
        this.joining[key];
        let Joiningtemp = new JoiningDetails();
        Joiningtemp = this.joining[key];
        this.Joinings.push(Joiningtemp);
      });
      if(this.Joinings.length== 0)
      {
        this.NoRecordJoinigMsg= true;
      }
      this.dtTrigger.next();
    });
  }

  RefreshPage()
  {
    this.rout.navigateByUrl('/Employee/EmployeeOffer', {skipLocationChange: true}).then(()=>this.rout.navigate(["/Employee/EmployeeDetails"]));
  }

  EmployeeSubPages(selectitem,RouteName) 
  {
    // this.router.navigate(['Organization']);
    this.selectedIndex=selectitem;
    this.rout.navigate([RouteName]);  
  } 

  EmployeePersonalDetailsPages(selectitem,RouteName) 
  {
    // this.router.navigate(['Organization']);
    this.selectedIndex=selectitem;
    this.rout.navigateByUrl('/Employee/EmployeeDetails', {skipLocationChange: true}).then(()=>this.rout.navigate([RouteName]));
  } 
}
