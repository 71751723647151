import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Http, Headers, RequestOptions, Response } from '@angular/http';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import 'rxjs/add/operator/toPromise';
import { Subscriber } from 'rxjs';
import { GlobalVariable } from '../../../../app.global';
import { Model_CRM_Lead } from './Model_CRM_LEAD';
import { Model_Crm_List } from './Model_Crm_List';
import { Model_CRM_FilesUpload } from '../Model_CRM_FilesUpLoad';
import { Model_CRM_LEAD_Note } from '../LEADCRM/Model_CRM_LEAD_Note';
import { Model_CRM_Lead_Documents } from '../LEADCRM/Model_CRM_Lead_Documents';
import { Model_CRM_Account_Info } from '../LEADCRM/Model_CRM_Account_Info';

import { Model_CRM_LeadShared } from '../LEADCRM/Model_CRM_LeadShared';
import { Model_CRM_ScheduleCall } from '../LEADCRM/Model_CRM_ScheduleCall';
import { Model_CRM_ScheduleCall_Documents } from '../LEADCRM/Model_CRM_ScheduleCall_Documents';
// import { Model_CRM_State } from './Model_CRM_State';
// import { Model_CRM_City } from './Model_CRM_City';
// import { Model_CRM_Branch } from './Model_CRM_Branch';




@Injectable()

export class CrmLeadService {

    model_CRM_Shared_lead: Model_CRM_Lead;
    SelectedCrmIndexPage: number = 0;
    listname: Model_Crm_List[] = [];

    PreviousLeadName: string = "";

    leadlist: Model_CRM_Lead[] = [];
    PageListList: Model_CRM_Lead[] = [];
    tempLeadList: Model_CRM_Lead[] = [];



    List1: number[] = [];

    IsinViewState: boolean = false;


    CurretPageNumber: number;
    SearchText: string;


    constructor(private http: HttpClient, private spinnerService: Ng4LoadingSpinnerService) {
        this.model_CRM_Shared_lead = new Model_CRM_Lead();
        this.model_CRM_Shared_lead.LeadId = 0;
        this.model_CRM_Shared_lead.ListId = 0;

    }


    //    Populate Country Drop  Down Starts

    GetCountryType(token) {

        //this.spinnerService.show();
        const authHeader = new HttpHeaders();
        authHeader.append('Content-Type', 'application/json');
        authHeader.append('Authorization', token);
        return new Promise((resolve, reject) => {
            setTimeout(() => {


                this.http.get(GlobalVariable.BASE_API_URL + '/api/CRMCountry/GetCountryName', { headers: authHeader })
                    .subscribe(data => {
                        this.spinnerService.hide();
                        resolve(data)
                    },
                        error => {
                            this.spinnerService.hide();
                            reject(error);
                        });
            }, 3000);
        });
    }

    //  Populate  Country Drop  Down Ends



    //     //    Populate Zone Drop  Down Starts

    //     GetZoneType(token) {
    //         this.spinnerService.show();
    //         const authHeader = new HttpHeaders();
    //         authHeader.append('Content-Type', 'application/json');
    //         authHeader.append('Authorization', token);
    //         return new Promise((resolve, reject) => {
    //             setTimeout(() => {

    //                 this.http.get(GlobalVariable.BASE_API_URL + '/api/CRMBranch/GetZoneName', { headers: authHeader })
    //                     .subscribe(data => {
    //                         this.spinnerService.hide();
    //                         resolve(data)
    //                     },
    //                         error => {
    //                             this.spinnerService.hide();
    //                             reject(error);
    //                         }, );
    //             }, 3000);
    //         });
    //     }

    // //   //  Populate Zone Drop  Down Ends




    //    Populate State Drop  Down Starts

    GetStateType(token, countryId: number) {
        this.spinnerService.show();
        const authHeader = new HttpHeaders();
        authHeader.append('Content-Type', 'application/json');
        authHeader.append('Authorization', token);
        return new Promise((resolve, reject) => {
            setTimeout(() => {

                this.http.get(GlobalVariable.BASE_API_URL + '/api/CRMLead/GetStateNameById?CountryId=' + countryId, { headers: authHeader })
                    .subscribe(data => {
                        this.spinnerService.hide();
                        resolve(data)
                    },
                        error => {
                            this.spinnerService.hide();
                            reject(error);
                        });
            }, 3000);
        });
    }

    //  Populate State Drop  Down Ends






    //    Populate City Drop  Down Starts

    // GetCityType(token) {
    //     this.spinnerService.show();
    //     const authHeader = new HttpHeaders();
    //     authHeader.append('Content-Type', 'application/json');
    //     authHeader.append('Authorization', token);
    //     return new Promise((resolve, reject) => {
    //         setTimeout(() => {

    //             this.http.get(GlobalVariable.BASE_API_URL + '/api/CRMLead/GetCityName', { headers: authHeader })
    //                 .subscribe(data => {
    //                     this.spinnerService.hide();
    //                     resolve(data)
    //                 },
    //                     error => {
    //                         this.spinnerService.hide();
    //                         reject(error);
    //                     }, );
    //         }, 3000);
    //     });
    // }


    GetCityType(token, stateid: number) {
        this.spinnerService.show();
        const authHeader = new HttpHeaders();
        authHeader.append('Content-Type', 'application/json');
        authHeader.append('Authorization', token);
        return new Promise((resolve, reject) => {
            setTimeout(() => {

                this.http.get(GlobalVariable.BASE_API_URL + '/api/CRMBranch/GetCityName?StateId=' + stateid, { headers: authHeader })
                    .subscribe(data => {
                        this.spinnerService.hide();

                        resolve(data)
                    },
                        error => {
                            this.spinnerService.hide();
                            reject(error);
                        });
            }, 3000);
        });
    }



    //  Populate City  Drop  Down Ends



    //    Populate List Name  Drop  Down Starts

    GetListName(token) {
        this.spinnerService.show();
        const authHeader = new HttpHeaders();
        authHeader.append('Content-Type', 'application/json');
        authHeader.append('Authorization', token);
        return new Promise((resolve, reject) => {
            setTimeout(() => {

                this.http.get(GlobalVariable.BASE_API_URL + '/api/CRMLead/GetListName', { headers: authHeader })
                    .subscribe(data => {

                        resolve(data)
                        this.spinnerService.hide();
                    },
                        error => {
                            this.spinnerService.hide();
                            reject(error);
                        });
            }, 3000);
        });
    }

    //  Populate List Name  Drop  Down Ends



    //    Populate Country Drop  Down Starts

    GetCRMLeadAssigned(token, LeadId: number) {

        this.spinnerService.show();
        const authHeader = new HttpHeaders();
        authHeader.append('Content-Type', 'application/json');
        authHeader.append('Authorization', token);
        return new Promise((resolve, reject) => {
            setTimeout(() => {


                this.http.get(GlobalVariable.BASE_API_URL + '/api/CRMCountry/GetCountryName', { headers: authHeader })
                    .subscribe(data => {
                        this.spinnerService.hide();
                        resolve(data)
                    },
                        error => {
                            this.spinnerService.hide();
                            reject(error);
                        });
            }, 3000);
        });
    }



    AddUpdateCrmLeadAssigned(token, CRMLeadlist: Model_CRM_LeadShared[]) {
        const authHeader = new HttpHeaders();
        authHeader.append('Content-Type', 'application/json');
        authHeader.append('Authorization', token);
        authHeader.append('Accept', 'application/json');

        return this.http.post(GlobalVariable.BASE_API_URL + '/api/CRMLead/UpdateLeadAsssigned', CRMLeadlist, { headers: authHeader })
            .map(this.extractData)
            .catch(this.handleErrorObservable);
    }

    //  Populate  Country Drop  Down Ends

    AddCrmSchedulecall(token, CRMSchedule: Model_CRM_ScheduleCall) {
        const authHeader = new HttpHeaders();
        authHeader.append('Content-Type', 'application/json');
        authHeader.append('Authorization', token);
        authHeader.append('Accept', 'application/json');

        return this.http.post(GlobalVariable.BASE_API_URL + '/api/CRMLead/CRMScheduleCallAdd', CRMSchedule, { headers: authHeader })
            .map(this.extractData)
            .catch(this.handleErrorObservable);
    }








    AddCrmLead(token, CRMLead: Model_CRM_Lead) {
        const authHeader = new HttpHeaders();
        authHeader.append('Content-Type', 'application/json');
        authHeader.append('Authorization', token);
        authHeader.append('Accept', 'application/json');

        return this.http.post(GlobalVariable.BASE_API_URL + '/api/CrmLead/Add', CRMLead, { headers: authHeader })
            .map(this.extractData)
            .catch(this.handleErrorObservable);
    }




    // Grid Data Fetch  

    // Grid Data Fetch  

    Get_CRMLead(token, pagenumber: number, searchtext: string) {

        const authHeader = new HttpHeaders();
        authHeader.append('Content-Type', 'application/json');
        authHeader.append('Authorization', token);
        this.spinnerService.show();

        return new Promise((resolve, reject) => {
            setTimeout(() => {

                this.http.get(GlobalVariable.BASE_API_URL + '/api/CRMLead/GetCRMLead?pagenumber=' + pagenumber + '&Serachtext=' + searchtext, { headers: authHeader }).subscribe(data => {

                    resolve(data)
                    this.spinnerService.hide();

                },
                    error => {
                        this.spinnerService.hide();
                        reject(error);
                    });
            }, 3000);
        });
    }





    //  View  Section Starts

    GetCRM_Lead_By_id(token, id: number) {
        this.spinnerService.show();
        const authHeader = new HttpHeaders();
        authHeader.append('Content-Type', 'application/json');
        authHeader.append('Authorization', token);
        return new Promise((resolve, reject) => {
            setTimeout(() => {
                this.http.get(GlobalVariable.BASE_API_URL + '/api/CRMLead/GetCRMLeadById?LeadId=' + id, { headers: authHeader })
                    .subscribe(data => {
                        this.spinnerService.hide();
                        resolve(data)
                    },
                        error => {
                            this.spinnerService.hide();
                            reject(error);
                        });
            }, 3000);
        });
    }

    //  View  Section Ends   


    // Begin Get CRM Employee ID

    GetCRM_EmployeeId(token) {
        this.spinnerService.show();
        const authHeader = new HttpHeaders();
        authHeader.append('Content-Type', 'application/json');
        authHeader.append('Authorization', token);
        return new Promise((resolve, reject) => {
            setTimeout(() => {
                this.http.get(GlobalVariable.BASE_API_URL + '/api/CRMLead/GetEmpID', { headers: authHeader })
                    .subscribe(data => {
                        this.spinnerService.hide();
                        resolve(data)
                    },
                        error => {
                            this.spinnerService.hide();
                            reject(error);
                        });
            }, 3000);
        });
    }


    //End  Get CRM Employee ID  


    //   Begning Get Assigne to list

    GetAssignetoList(token) {

        this.spinnerService.show();
        const authHeader = new HttpHeaders();
        authHeader.append('Content-Type', 'application/json');
        authHeader.append('Authorization', token);
        return new Promise((resolve, reject) => {
            setTimeout(() => {


                this.http.get(GlobalVariable.BASE_API_URL + '/api/CRMLead/GetCRMAssigneToList', { headers: authHeader })
                    .subscribe(data => {
                        this.spinnerService.hide();
                        resolve(data)
                    },
                        error => {
                            this.spinnerService.hide();
                            reject(error);
                        });
            }, 3000);
        });
    }

    //  End Get Assigne to list


    GetParentChildList(token) {

        this.spinnerService.show();
        const authHeader = new HttpHeaders();
        authHeader.append('Content-Type', 'application/json');
        authHeader.append('Authorization', token);
        return new Promise((resolve, reject) => {
            setTimeout(() => {


                this.http.get(GlobalVariable.BASE_API_URL + '/api/CRMLead/GetCrmParentChildList', { headers: authHeader })
                    .subscribe(data => {
                        this.spinnerService.hide();
                        resolve(data)
                    },
                        error => {
                            this.spinnerService.hide();
                            reject(error);
                        });
            }, 3000);
        });
    }


    //   Begning Get Assigne to list

    CheckDuplicateLead(token, LeadName: string) {

        this.spinnerService.show();
        const authHeader = new HttpHeaders();
        authHeader.append('Content-Type', 'application/json');
        authHeader.append('Authorization', token);
        return new Promise((resolve, reject) => {
            setTimeout(() => {


                this.http.get(GlobalVariable.BASE_API_URL + '/api/CRMLead/CheckDuplicate?LeadName=' + LeadName, { headers: authHeader })
                    .subscribe(data => {
                        this.spinnerService.hide();
                        resolve(data)
                    },
                        error => {
                            this.spinnerService.hide();
                            reject(error);
                        });
            }, 3000);
        });
    }

    //  End Get Assigne to list            


    UpdateCrmLead(token, CRMLead: Model_CRM_Lead) {
        const authHeader = new HttpHeaders();
        authHeader.append('Content-Type', 'application/json');
        authHeader.append('Authorization', token);
        authHeader.append('Accept', 'application/json');

        return this.http.post(GlobalVariable.BASE_API_URL + '/api/CrmLead/Update', CRMLead, { headers: authHeader })
            .map(this.extractData)
            .catch(this.handleErrorObservable);
    }

    UpdateCrmLeadMoreInFo(token, CRMLead: Model_CRM_Lead) {
        const authHeader = new HttpHeaders();
        authHeader.append('Content-Type', 'application/json');
        authHeader.append('Authorization', token);
        authHeader.append('Accept', 'application/json');

        return this.http.post(GlobalVariable.BASE_API_URL + '/api/CrmLead/AddUpDateMoreInfo', CRMLead, { headers: authHeader })
            .map(this.extractData)
            .catch(this.handleErrorObservable);
    }

    //  Delete  a   Record  Starts


    //Begin Updates CrmContacts

    AddUpdateCrmContactsInfo(token, CRMLead: Model_CRM_Lead) {
        const authHeader = new HttpHeaders();
        authHeader.append('Content-Type', 'application/json');
        authHeader.append('Authorization', token);
        authHeader.append('Accept', 'application/json');

        return this.http.post(GlobalVariable.BASE_API_URL + '/api/CrmLead/AddUpDateLeadContacts', CRMLead, { headers: authHeader })
            .map(this.extractData)
            //.map(this.extractDataarray)
            .catch(this.handleErrorObservable);
    }

    //End Update CrmContacts





    //Begning of the delete lead

    DeleteLead(token, LeadId: number) {

        const authHeader = new HttpHeaders();
        authHeader.append('Content-Type', 'application/json');
        authHeader.append('Authorization', token);
        authHeader.append('Accept', 'application/json');

        return this.http.put(GlobalVariable.BASE_API_URL + '/api/CrmLead/DeleteLead', LeadId, { headers: authHeader })
            .map(this.extractData)
            .catch(this.handleErrorObservable);
    }

    //end of the delete lead   

    // Begin CRM designation
    GetCRM_designation(token) {
        this.spinnerService.show();
        const authHeader = new HttpHeaders();
        authHeader.append('Content-Type', 'application/json');
        authHeader.append('Authorization', token);
        return new Promise((resolve, reject) => {
            setTimeout(() => {
                this.http.get(GlobalVariable.BASE_API_URL + '/api/CRMLead/GetCrmDesignationList', { headers: authHeader })
                    .subscribe(data => {

                        this.spinnerService.hide();

                        resolve(data)
                    },
                        error => {
                            this.spinnerService.hide();
                            reject(error);
                        });
            }, 3000);
        });
    }
    // End CRM Designation       




    // Begin CRM Segments
    GetCRM_Segments(token) {
        this.spinnerService.show();
        const authHeader = new HttpHeaders();
        authHeader.append('Content-Type', 'application/json');
        authHeader.append('Authorization', token);
        return new Promise((resolve, reject) => {
            setTimeout(() => {
                this.http.get(GlobalVariable.BASE_API_URL + '/api/CRMLead/GetCRMSegments', { headers: authHeader })
                    .subscribe(data => {
                        this.spinnerService.hide();
                        resolve(data)
                    },
                        error => {
                            this.spinnerService.hide();
                            reject(error);
                        });
            }, 3000);
        });
    }
    // End CRM Segments     


    // begin Refresh Leads
    CancelLead() {

        this.model_CRM_Shared_lead = new Model_CRM_Lead();
        this.model_CRM_Shared_lead.Mr = '0';
        this.model_CRM_Shared_lead.LeadId = 0;
        this.SelectedCrmIndexPage = 0;
        this.model_CRM_Shared_lead.ListId = 0;
        this.model_CRM_Shared_lead.CountryId = 0;
        this.model_CRM_Shared_lead.StateId = 0;
        this.model_CRM_Shared_lead.CityId = 0;
        this.model_CRM_Shared_lead.AssigneTo = 0;


        //this.leadlist
        this.model_CRM_Shared_lead.DOB = new Date();
        this.model_CRM_Shared_lead.AnniversaryDate = new Date();
        this.model_CRM_Shared_lead.FirstName = '';
        this.model_CRM_Shared_lead.MiddleName = '';
        this.model_CRM_Shared_lead.LastName = '';
        this.model_CRM_Shared_lead.EmailId = '';
        this.model_CRM_Shared_lead.PhoneNo = '';
        this.model_CRM_Shared_lead.Extension = '';
        this.model_CRM_Shared_lead.DesignationID = 0;
        this.model_CRM_Shared_lead.SegmentIDs = [];
        this.model_CRM_Shared_lead.ContactsList = [];
        this.IsinViewState = false;

    }

    // Dnd of the 

    // Begning Of the add documents

    AddDocuments(token, Fileupload: Model_CRM_FilesUpload[]) {
        const authHeader = new HttpHeaders();
        authHeader.append('Content-Type', 'application/json');
        authHeader.append('Authorization', token);
        authHeader.append('Accept', 'application/json');

        return this.http.post(GlobalVariable.BASE_API_URL + '/api/CrmLead/AddDocument', Fileupload, { headers: authHeader })
            .map(this.extractData)
            .catch(this.handleErrorObservable);
    }

    //End of the add documents 

    // getPdf(token) {


    //     const authHeader = new HttpHeaders();  
    //     let xx='pdf' ;      
    //                 authHeader.append('Content-Type', 'application/json');
    //                 authHeader.append('Authorization', token);  
    //                 authHeader.append('Accept', 'application/json');

    //                 let headers = new HttpHeaders();
    //                 let url=GlobalVariable.BASE_API_URL + '/api/CrmLead/GetDocument?format='+xx
    // headers = headers.set('Accept', 'application/pdf');
    //  this.http.get(url, { headers: headers, responseType: 'blob' });              



    //     }


    getPdf(token) {
        let xx = 'pdf';
        let url = GlobalVariable.BASE_API_URL + '/api/CrmLead/GetDocument?format=' + xx
        //   this.getFile(url)
        //   .subscribe(fileData => 
        //     {
        //     let b:any = new Blob([fileData], { type: 'application/zip' });
        //     var url= window.URL.createObjectURL(b);
        //       window.open(url);
        //     }
        //   );


        // let headers = new HttpHeaders();
        // headers = headers.set('Accept', 'application/pdf');
        // return this.http.get(url, { headers: headers, responseType: 'blob' });

        let headers = new HttpHeaders();
        //headers.append('Authorization', token); 
        //headers = headers.set('Accept', 'application/pdf');
        //return this.http.get(url, { headers: headers });
        // var params = {
        //     access_token: token

        // };

        //Add authentication headers in URL
        // var urlx = [url, $.param(params)].join('?');

        // //Open window
        // window.open(url);

        //window.open(url, "_blank");

        var params = {

            Authorization: token

        };

        //Add authentication headers in URL
        var urlx = [url, $.param(params)].join('?');

        //Open window
        window.open(url);
    }


    // Begning Get Lead Reports
    GetCrmLeadReports(token, employees: number[], Fromdate: Date, ToDate: Date) {


        const autheader = new HttpHeaders();
        autheader.append('Content-Type', 'application/jason');
        autheader.append('Authorization', token);

        return new Promise((resolve, reject) => {

            setTimeout(() => {
                this.http.get(GlobalVariable.BASE_API_URL + '/api/CRMOpportunity/GetLeadReport?employeeIds=' + employees + '&Fromdate=' + Fromdate + '&Todate=' + ToDate, { headers: autheader, responseType: 'blob' as 'json' })
                    .subscribe((res) => {
                        var file = new Blob(<any>[res], { type: 'application/vnd.ms-excel' });
                        var fileURL = URL.createObjectURL(file);
                        window.open(fileURL);
                    });
            }, 3000);




        });
    }




    // Enet Get Lead Reports

    //-------------------------------::::   // LIST SECTION STARTS    :::::-------- :::---------------- 


    // Add New List Text Box Starts :- 

    AddList(token, ManageList: Model_Crm_List) {
        const authHeader = new HttpHeaders();
        authHeader.append('Content-Type', 'application/json');
        authHeader.append('Authorization', token);
        authHeader.append('Accept', 'application/json');
        //  alert();
        return this.http.post(GlobalVariable.BASE_API_URL + '/api/CrmLead/AddCRMList', ManageList, { headers: authHeader })
            .map(this.extractData)
            .catch(this.handleErrorObservable);
    }

    // Add New List Text Box Ends :- 




    //  Grid Fetch For List Page Starts

    Get_CRMList(token) {
        this.spinnerService.show();
        const authHeader = new HttpHeaders();
        authHeader.append('Content-Type', 'application/json');
        authHeader.append('Authorization', token);
        return new Promise((resolve, reject) => {
            setTimeout(() => {
                // alert('14');
                this.http.get(GlobalVariable.BASE_API_URL + '/api/CRMLead/GetListName', { headers: authHeader }).subscribe(data => {
                    this.spinnerService.hide();
                    resolve(data)
                },
                    error => {
                        this.spinnerService.hide();
                        reject(error);
                    });
            }, 3000);
        });
    }

    // Grid Fetch For List Page Ends

    // Fetch  Lead Note

    Get_CRMLeadNote(token, LeadId) {
        this.spinnerService.show();
        const authHeader = new HttpHeaders();
        authHeader.append('Content-Type', 'application/json');
        authHeader.append('Authorization', token);
        return new Promise((resolve, reject) => {
            setTimeout(() => {
                // alert('14');
                this.http.get(GlobalVariable.BASE_API_URL + '/api/CRMLead/GetLeadNote?LeadId=' + LeadId, { headers: authHeader }).subscribe(data => {
                    this.spinnerService.hide();
                    resolve(data)
                },
                    error => {
                        this.spinnerService.hide();
                        reject(error);
                    });
            }, 3000);
        });
    }
    // Fetch  Lead Note


    // Add  Update CrmLead note

    AddupdateCrmLeadNote(token, ModelLead: Model_CRM_LEAD_Note) {
        const authHeader = new HttpHeaders();
        authHeader.append('Content-Type', 'application/json');
        authHeader.append('Authorization', token);
        authHeader.append('Accept', 'application/json');
        //  alert();
        return this.http.post(GlobalVariable.BASE_API_URL + '/api/CrmLead/AddUpdateLeadNot', ModelLead, { headers: authHeader })
            .map(this.extractData)
            .catch(this.handleErrorObservable);
    }

    // Add  Update CrmLead note



    //Fetch CRM Lead To Get Document

    Get_CRMLeadGetDocument(token, LeadId) {
        this.spinnerService.show();
        const authHeader = new HttpHeaders();
        authHeader.append('Content-Type', 'application/json');
        authHeader.append('Authorization', token);
        return new Promise((resolve, reject) => {
            setTimeout(() => {
                // alert('14');
                this.http.get(GlobalVariable.BASE_API_URL + '/api/CRMLead/GetLeadDocuments?LeadId=' + LeadId, { headers: authHeader }).subscribe(data => {
                    this.spinnerService.hide();
                    resolve(data)
                },
                    error => {
                        this.spinnerService.hide();
                        reject(error);
                    });
            }, 3000);
        });
    }
    //Fetch CRM Lead To Get Document






    // Update List Section Starts

    UpdateCrmList(token, CRMList: Model_Crm_List) {
        const authHeader = new HttpHeaders();
        authHeader.append('Content-Type', 'application/json');
        authHeader.append('Authorization', token);
        authHeader.append('Accept', 'application/json');
        //  alert();
        return this.http.post(GlobalVariable.BASE_API_URL + '/api/CrmList/Update', CRMList, { headers: authHeader })
            .map(this.extractData)
            .catch(this.handleErrorObservable);
    }

    // Update List Section Ends



    //  View  Section Starts

    GetCRM_List_By_id(token, id: number) {
        this.spinnerService.show();
        const authHeader = new HttpHeaders();
        authHeader.append('Content-Type', 'application/json');
        authHeader.append('Authorization', token);
        return new Promise((resolve, reject) => {
            setTimeout(() => {
                this.http.get(GlobalVariable.BASE_API_URL + '/api/CrmList/GetCrmListById?ListId=' + id, { headers: authHeader })
                    .subscribe(data => {
                        this.spinnerService.hide();
                        resolve(data)
                    },
                        error => {
                            this.spinnerService.hide();
                            reject(error);
                        });
            }, 3000);
        });
    }

    //  View  Section Ends    


    // Delete List Section Starts 


    DeleteList(token, ID: number) {

        const authHeader = new HttpHeaders();
        authHeader.append('Content-Type', 'application/json');
        authHeader.append('Authorization', token);
        authHeader.append('Accept', 'application/json');
        return this.http.put(GlobalVariable.BASE_API_URL + '/api/CrmList/Delete', ID, { headers: authHeader })
            .map(this.extractData)
            .catch(this.handleErrorObservable);
    }

    // Delete List Section Ends 




    //Begning Of  add Lead  CRM Documents

    AddCRMDocuments(token, Fileupload: Model_CRM_Lead_Documents[]) {
        const authHeader = new HttpHeaders();
        authHeader.append('Content-Type', 'application/json');
        authHeader.append('Authorization', token);
        authHeader.append('Accept', 'application/json');
        return this.http.post(GlobalVariable.BASE_API_URL + '/api/CrmLead/AddDocument', Fileupload, { headers: authHeader })
            .map(this.extractData)
            .catch(this.handleErrorObservable);
    }


    //Begning of the add Lead CRM Documents



    // Begning of add CRMLead Account informations


    AddCRMAccountinformation(token, AccountInFo: Model_CRM_Account_Info) {
        const authHeader = new HttpHeaders();
        authHeader.append('Content-Type', 'application/json');
        authHeader.append('Authorization', token);
        authHeader.append('Accept', 'application/json');
        return this.http.post(GlobalVariable.BASE_API_URL + '/api/CrmLead/AddUpdateAccountInfo', AccountInFo, { headers: authHeader })
            .map(this.extractData)
            .catch(this.handleErrorObservable);
    }


    //end of the add CRMLead account informations



    // Begning of  Fetch  Account information

    Get_CRMLeadAccountInformation(token, LeadId) {
        this.spinnerService.show();
        const authHeader = new HttpHeaders();
        authHeader.append('Content-Type', 'application/json');
        authHeader.append('Authorization', token);
        return new Promise((resolve, reject) => {
            setTimeout(() => {
                // alert('14');
                this.http.get(GlobalVariable.BASE_API_URL + '/api/CRMLead/GetLeadAccountInFo?LeadId=' + LeadId, { headers: authHeader }).subscribe(data => {
                    this.spinnerService.hide();
                    resolve(data)
                },
                    error => {
                        this.spinnerService.hide();
                        reject(error);
                    });
            }, 3000);
        });
    }
    //End of  Fetch  Account information



    //Begning Of the down load  docement

    // Get_CRMLeadDownloadDocument(token, extension : string, DocumentId:number) {

    public getDocument(token, docid: number) {
        let url = GlobalVariable.BASE_API_URL + '/api/CRMLead/GetDocument?DocumentId=' + docid;
        const authHeader = new HttpHeaders();
        authHeader.append('Content-Type', 'application/json');
        authHeader.append('Authorization', token);
        // var params = {
        //     'Authorization': token,
        //     'Content-type': 'application/json',

        // };

        //var urlR = [url, $.param(params)].join('?');
        //    this.http.get(url);

        //    const file =  await this.http.get<Blob>(
        //     url,
        //       {responseType: 'blob' as 'json'}).toPromise();
        //   return file;


        //   const blob = await this.service.downloadResource(this.id);
        //   const url = window.URL.createObjectURL(blob);

        //   const link = this.downloadZipLink.nativeElement;
        //   link.href = url;
        //   link.download = 'archive.zip';
        //   link.click();

        // window.open(urlR);


        //       var request =  new XMLHttpRequest();

        // request.open("GET",url);

        // request.setRequestHeader("Authorization",token);

        // request.responseType='arraybuffer';

        // request.onload = function(e) { 

        // var file = new Blob([this.response],{type:'application/vnd.ms-excel'});

        // var fileurl = window.URL.createObjectURL(file);

        // window.open(fileurl);

        //  this.http.get(url, { headers:authHeader, responseType:"blob"});
        //this.http.get(url);


        let headers = new HttpHeaders().append("Authorization", token)
        return this.http.get(url, { responseType: 'arraybuffer', headers: headers });

    }

    //Begning Of The Get Lead Shared

    GetCrmLeadShared(token, LeadId: Number) {

        this.spinnerService.show();
        const authHeader = new HttpHeaders();
        authHeader.append('Content-Type', 'application/json');
        authHeader.append('Authorization', token);
        return new Promise((resolve, reject) => {
            setTimeout(() => {


                this.http.get(GlobalVariable.BASE_API_URL + '/api/CRMLead/GetUserAssigned?LeadId=' + LeadId, { headers: authHeader })
                    .subscribe(data => {
                        this.spinnerService.hide();
                        resolve(data)
                    },
                        error => {
                            this.spinnerService.hide();
                            reject(error);
                        });
            }, 3000);
        });
    }

    //End Of The Get Lead Shared




    //Begning Of The Get  Time

    GetCrmLGettime(token) {

        this.spinnerService.show();
        const authHeader = new HttpHeaders();
        authHeader.append('Content-Type', 'application/json');
        authHeader.append('Authorization', token);
        return new Promise((resolve, reject) => {
            setTimeout(() => {
                this.http.get(GlobalVariable.BASE_API_URL + '/api/CRMLead/CRM_Time', { headers: authHeader })
                    .subscribe(data => {
                        this.spinnerService.hide();
                        resolve(data)
                    },
                        error => {
                            this.spinnerService.hide();
                            reject(error);
                        });
            }, 3000);
        });
    }

    //End Of The Get Time



    //Begning Of The Get  Disposition Statue

    GetCrmGetDispositionStatus(token) {

        this.spinnerService.show();
        const authHeader = new HttpHeaders();
        authHeader.append('Content-Type', 'application/json');
        authHeader.append('Authorization', token);
        return new Promise((resolve, reject) => {
            setTimeout(() => {
                this.http.get(GlobalVariable.BASE_API_URL + '/api/CRMLead/Get_CRM_Disposition_Status', { headers: authHeader })
                    .subscribe(data => {
                        this.spinnerService.hide();
                        resolve(data)
                    },
                        error => {
                            this.spinnerService.hide();
                            reject(error);
                        });
            }, 3000);
        });
    }

    //End Of The Get  Disposition Statue



    //Begning Of The Get CRM Call Type

    GetCrmGetCallType(token) {

        this.spinnerService.show();
        const authHeader = new HttpHeaders();
        authHeader.append('Content-Type', 'application/json');
        authHeader.append('Authorization', token);
        return new Promise((resolve, reject) => {
            setTimeout(() => {
                this.http.get(GlobalVariable.BASE_API_URL + '/api/CRMLead/CallType', { headers: authHeader })
                    .subscribe(data => {
                        this.spinnerService.hide();
                        resolve(data)
                    },
                        error => {
                            this.spinnerService.hide();
                            reject(error);
                        });
            }, 3000);
        });
    }

    //End Of The Get CRM Call Type





    //Ene of the down load document



    // Begning of add CRM Schedule Call

    AddCRMSchedulecall(token, ScheduleCall: Model_CRM_ScheduleCall) {

        const authHeader = new HttpHeaders();
        authHeader.append('Content-Type', 'application/json');
        authHeader.append('Authorization', token);
        authHeader.append('Accept', 'application/json');
        return this.http.post(GlobalVariable.BASE_API_URL + '/api/CRMLead/CRMScheduleCallAdd', ScheduleCall, { headers: authHeader })
            .map(this.extractData)
            .catch(this.handleErrorObservable);
    }

    //end of the add CRM Schedule Call

    //Begning Of add CRM Schedule Call Documents

    AddCRMScheduleCallDocuments(token, ScheduleCallDoc: Model_CRM_ScheduleCall_Documents) {
        const authHeader = new HttpHeaders();
        authHeader.append('Content-Type', 'application/json');
        authHeader.append('Authorization', token);
        authHeader.append('Accept', 'application/json');
        return this.http.post(GlobalVariable.BASE_API_URL + '/api/CrmLead/AddScheduleDocument', ScheduleCallDoc, { headers: authHeader })
            .map(this.extractData)
            .catch(this.handleErrorObservable);
    }


    //End Of add CRM Schedule Call Documents


    //Begning of the Get Crm Schedule Call

    GetSchedulecall(token, CompanyId, OpportunityId, FromDate: string, Todate: string) {

        this.spinnerService.show();
        const authHeader = new HttpHeaders();
        authHeader.append('Content-Type', 'application/json');
        authHeader.append('Authorization', token);
        return new Promise((resolve, reject) => {
            setTimeout(() => {
                this.http.get(GlobalVariable.BASE_API_URL + '/api/CRMLead/GetCRMScheduleCall?companyId=' + CompanyId + '&opprtunityid=' + OpportunityId + '&FromDate=' + FromDate + '&ToDate=' + Todate,
                    { headers: authHeader })
                    .subscribe(data => {

                        this.spinnerService.hide();

                        resolve(data)
                    },
                        error => {
                            this.spinnerService.hide();
                            reject(error);
                        });
            }, 3000);
        });
    }

    //End  of the Get Crm Schedule Call



    //Begning Of the down load Schedule Call docement    

    public getScheduleCallDocument(token, DocumentName: String) {
        let url = GlobalVariable.BASE_API_URL + '/api/CRMLead/GetScheduleCallDocument?Filename=' + DocumentName;
        const authHeader = new HttpHeaders();
        authHeader.append('Content-Type', 'application/json');
        authHeader.append('Authorization', token);
        let headers = new HttpHeaders().append("Authorization", token)
        return this.http.get(url, { responseType: 'arraybuffer', headers: headers });
    }

    //End Of the down load Schedule Call docement 





    //End of the add Lead CRM Documents
    private extractData(res: Response) {
        let body = res;
        return body || {};
    }
    private handleErrorObservable(error: Response | any) {
        console.error(error.message || error);
        return Observable.throw(error.message || error);
    }

    private extractDataarray(res: Response) {
        let body = res.json;

        return body;

    }


}

enum ResponseContentType {
    Text,
    Json,
    ArrayBuffer,
    Blob
}