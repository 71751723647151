import { Component, OnInit, ViewChild } from '@angular/core';
import { DatePipe } from '@angular/common'
import { Model_CRM_Assigne } from '../../../../shared/CRM/MICECRM/Model_CRM_Assigne'
import { CrmOppertunityService } from '../../../../shared/CRM/MICECRM/Oppertunity/CrmOppertunityService';
import { CrmLeadService } from '../../../../shared/CRM/MICECRM/LEADCRM/CrmLeadService';
import { Router } from '@angular/router';
import { Model_CRM_Lead_Contacts } from '../../../../shared/CRM/MICECRM/Oppertunity/Model_CRM_Lead_Contacts';
import { Model_CRM_Lead } from '../../../../shared/CRM/MICECRM/LEADCRM/Model_CRM_LEAD';
import { Model_CRM_Time } from '../../../../shared/CRM/MICECRM/LEADCRM/Model_CRM_Time';
import { Model_CRM_Disposition_Status } from '../../../../shared/CRM/MICECRM/LEADCRM/Model_CRM_Disposition_Status';
import { Model_CRM_CallType } from '../../../../shared/CRM/MICECRM/LEADCRM/Model_CRM_CallType';
import { DialogComponent } from '../../../../../app/dialog/dialog.component';
import { Molel_CRM_Opportunity_List } from '../../../../shared/CRM/MICECRM/Oppertunity/Molel_CRM_Opportunity_List';
import { Model_CRM_ScheduleCall } from '../../../../../app/shared/CRM/MICECRM/LEADCRM/Model_CRM_ScheduleCall'
import { Model_CRM_ScheduleCall_Documents } from '../../../../../app/shared/CRM/MICECRM/LEADCRM/Model_CRM_ScheduleCall_Documents'
//import { a } from '@angular/core/src/render3';

@Component({
  selector: 'app-schedule-call',
  templateUrl: './schedule-call.component.html',
  styleUrls: ['./schedule-call.component.css']
})
export class ScheduleCallComponent implements OnInit {

  CRM_ScheduleCall: Model_CRM_ScheduleCall;

  SubmitEnable: boolean;


  //Begning of Company list
  CompanySettings = {}
  CompanyArray: any[] = [];

  Companylist: Model_CRM_Lead[] = [];
  SelectedCompany: any[] = [];
  CompanyName: any;
  companylist: Model_CRM_Lead[] = [];
  //End of Company List

  //Begning of Contacts list
  ContactsSettings = {}
  ContactsArray: any[] = [];
  ContactName: any;
  Contactlist: Model_CRM_Lead_Contacts[] = [];
  SelectedContacts: any[] = [];

  SelectedContactId: number = 0;


  // companylist: Model_CRM_Lead[] = [];
  //End of Contact List


  //Begning of Selected Attendees
  AttendenceSettings = {}
  AttendesArray: any[] = [];
  AttendencetName: any;
  AttendeesList: Model_CRM_Assigne[] = [];
  SelectedAttendees: any[] = [];

  SelectedAttendeesId: number;
  //End of Selected Attendees

  //Begning of Selected opportunity
  OpportunitySettings = {}
  OpportunityArray: any[] = [];
  OpportunityName: any;
  OpportunityList: Molel_CRM_Opportunity_List[] = [];
  Selectedopportunities: any[] = [];

  SelectedOpportunitiesId: number;
  //End of Selected Opportunity

  // begning of the Time drop down list

  StartimeList: Model_CRM_Time[] = [];
  EndtimeList: Model_CRM_Time[] = [];
  Timeobject: any;


  // end of the  time drop down List 

  // begning of the Disposition Status

  DispositionList: Model_CRM_Disposition_Status[] = [];
  DispositionObject: any;

  // end of the  Disposition Status

  // begning of the CRM Call type
  CRM_Call_Types: Model_CRM_CallType[] = []
  CallTypeObject: any;
  // end of the CRM Call Type

  Documents: Model_CRM_ScheduleCall_Documents;

  Uploadfile: any;

  Noteid: any


  // Begin success message popup
  SuccessStatus: boolean;
  SuccessMessage: string;
  // end success message popup

  constructor(private CRM_OppertunityService: CrmOppertunityService, private rout: Router, private LeadSrevice: CrmLeadService) { }
 @ViewChild(DialogComponent, {static: false}) private myChild: DialogComponent;

  ngOnInit() {

    this.SubmitEnable = true;

    if (localStorage.getItem('SuccessfullMessage')) {
      this.SuccessStatus = true;
      this.SuccessMessage = localStorage.getItem('SuccessfullMessage')
      localStorage.removeItem('SuccessfullMessage');
    }

    this.CRM_ScheduleCall = new Model_CRM_ScheduleCall();



    if (this.CRM_ScheduleCall.SheduleCall_Id == undefined) {
      this.CRM_ScheduleCall.SheduleCall_Id = 0;
      this.CRM_ScheduleCall.Company_Id = 0;
      this.CRM_ScheduleCall.IsComplete = false;
      this.CRM_ScheduleCall.OpportunityId = 0;
      this.CRM_ScheduleCall.CallType = 0;
      this.CRM_ScheduleCall.startTme = 0;
      this.CRM_ScheduleCall.Endtime = 0;
      this.CRM_ScheduleCall.IsAllDay = false;
      this.CRM_ScheduleCall.IsPriorityCall = false;
      this.CRM_ScheduleCall.ContactIds = [];
      this.CRM_ScheduleCall.Invited_attendees = [];
      this.CRM_ScheduleCall.DispositionStatus = 0;

      this.CRM_ScheduleCall.Calldate = new Date();

      let pipe = new DatePipe('en-US');
      const now = Date.now();
      const myFormattedDate = pipe.transform(now, 'dd/MM/yyyy');
      this.CRM_ScheduleCall.CallDateVal = myFormattedDate;
      this.CRM_ScheduleCall.Calldate = new Date();
      this.Documents = new Model_CRM_ScheduleCall_Documents();
      this.Documents.SheduleCall_Id = 0;
      this.Documents.DocumentName = '';
      this.Documents.DocumentExtension = '';
      this.Documents.DocumentType = '';
      this.Documents.DocumentSize = '';
      this.Documents.DocumentActualName = '';

      this.CRM_ScheduleCall.Document = new Model_CRM_ScheduleCall_Documents();
      this.CRM_ScheduleCall.Document.SheduleCall_Id = 0;
      this.CRM_ScheduleCall.Document.DocumentName = '';
      this.CRM_ScheduleCall.Document.DocumentExtension = '';
      this.CRM_ScheduleCall.Document.DocumentType = '';
      this.CRM_ScheduleCall.Document.DocumentSize = '';
      this.CRM_ScheduleCall.Document.DocumentActualName = '';

    }


    this.CompanySettings = {
      singleSelection: true,
      text: 'Select Company',
      enableSearchFilter: true,
      showCheckbox: false
    }

    this.ContactsSettings = {
      singleSelection: false,
      text: 'Select Contacts',
      enableSearchFilter: true,
      showCheckbox: true
    }

    this.AttendenceSettings = {

      singleSelection: false,
      text: 'Select Attendees',
      enableSearchFilter: true,
      showCheckbox: true


    }

    this.OpportunitySettings = {

      singleSelection: true,
      text: 'Select Opportunity',
      enableSearchFilter: true,
      showCheckbox: false
    }
    this.GetDipositionStatus();
    this.GetCrmCompany();
    this.GetCrmAttendeestoList();
    this.GetStartTime()

    this.GetCRMCallType()

  }


  GetCrmCompany() {

    let t = localStorage.getItem('userToken');
    t = 'Bearer' + t
    this.companylist = [];
    this.CompanyArray = [];
    this.CRM_OppertunityService.Get_CrmCompany(t).then(x => {
      this.CompanyName = x;
      Object.keys(this.CompanyName).forEach(key => {
        let CompanyNametemp = new Model_CRM_Lead();
        CompanyNametemp = this.CompanyName[key];
        this.companylist.push(CompanyNametemp);
        this.CompanyArray.push({ "id": CompanyNametemp.LeadId, "itemName": CompanyNametemp.LeadName });
      });

    })
    //   .then(y => {

    //   if (this.CRM_OppertunityService.CrmCommonUseOportunityDetails.CompanyId > 0) {
    //     let OpportunityCompany = this.companylist.find(x => x.LeadId == this.CRM_OppertunityService.CrmCommonUseOportunityDetails.CompanyId);
    //     this.SelectedCompany = [];
    //     this.SelectedCompany.push({ "id": OpportunityCompany.LeadId, "itemName": OpportunityCompany.LeadName });
    //     this.GetLeadContact();
    //   }
    // });
  }




  GetLeadContact() {
    let t = localStorage.getItem('userToken');
    t = 'Bearer' + t
    this.Contactlist = [];
    this.ContactsArray = [];

    this.CRM_OppertunityService.GetCrmLeadContact(t, this.CRM_ScheduleCall.Company_Id).then(x => {
      this.ContactName = x;
      Object.keys(this.ContactName).forEach(key => {
        let ContactNametemp = new Model_CRM_Lead_Contacts();
        ContactNametemp = this.ContactName[key];
        this.Contactlist.push(ContactNametemp);
        this.ContactsArray.push({ "id": ContactNametemp.ContactID, "itemName": ContactNametemp.FirstName });
      });
    });
  }

  onItemSelectCompany(item: any) {

    let CompanyId = item['id'];
    this.CRM_ScheduleCall.Company_Id = CompanyId;

    this.CRM_ScheduleCall.ContactIds = [];

    if (this.CRM_ScheduleCall.Company_Id > 0) {
      this.GetLeadContact();
      this.GetOpportunityList();
      this.GetStartTime();
    }
  }
  OnItemDeSelectCompany(item: any) { }
  onSelectAllCompany(items: any) { }
  onDeSelectAllCompany(items: any) { }





  onItemSelectContacts(item: any) {

    let ContactId = item['id'];

    this.CRM_ScheduleCall.ContactIds.push(ContactId);


  }
  OnItemDeSelectContacts(item: any) {
    this.SelectedAttendeesId = item['id'];

    let tempshared = this.Contactlist.find(y => y.ContactID == this.SelectedContactId);
    //  let indexofshare = this.LeadSharedList.findIndex(x => x.UserId == tempshared.EmployeeId);

    // // if (!(indexofshare == -1)) {
    // //   this.LeadSharedList = this.LeadSharedList.filter(x => x.UserId !== tempshared.EmployeeId);

    // // }
    let indexofshare = this.CRM_ScheduleCall.ContactIds.findIndex(x => x == tempshared.ContactID);
    if (!(indexofshare == -1)) {

      this.CRM_ScheduleCall.ContactIds = this.CRM_ScheduleCall.ContactIds.filter(x => x != tempshared.ContactID)

    }


  }
  onSelectAllContacts(items: any) {

    this.CRM_ScheduleCall.ContactIds = [];

    this.Contactlist.forEach(x => {

      this.CRM_ScheduleCall.ContactIds.push(x.ContactID);


    })


  }
  onDeSelectAllContacts(items: any) {

    this.CRM_ScheduleCall.ContactIds = [];


  }




  onItemSelectAttendees(item: any) {

    let ContactId = item['id'];
    this.CRM_ScheduleCall.Invited_attendees.push(ContactId);

  }


  OnItemDeSelectAttendees(item: any) {
    this.SelectedContactId = item['id'];

    let tempshared = this.AttendeesList.find(y => y.EmployeeId == this.SelectedContactId);

    let indexofshare = this.CRM_ScheduleCall.Invited_attendees.findIndex(x => x == tempshared.EmployeeId);
    if (!(indexofshare == -1)) {

      this.CRM_ScheduleCall.Invited_attendees = this.CRM_ScheduleCall.Invited_attendees.filter(x => x != tempshared.EmployeeId)

    }


  }
  onSelectAllAttendees(items: any) {

    this.CRM_ScheduleCall.Invited_attendees = [];

    this.AttendeesList.forEach(x => {

      this.CRM_ScheduleCall.Invited_attendees.push(x.EmployeeId);


    })


  }
  onDeSelectAllAttendees(items: any) {

    this.CRM_ScheduleCall.Invited_attendees = [];

  }


  GetCrmAttendeestoList() {


    let t = localStorage.getItem('userToken');
    t = 'Bearer' + t
    this.AttendeesList = [];
    this.AttendesArray = [];

    this.LeadSrevice.GetAssignetoList(t).then(x => {
      this.AttendencetName = x;
      Object.keys(this.AttendencetName).forEach(key => {
        let AssigneTotemp = new Model_CRM_Assigne();
        AssigneTotemp = this.AttendencetName[key];

        this.AttendeesList.push(AssigneTotemp);
        this.AttendesArray.push({ "id": AssigneTotemp.EmployeeId, "itemName": AssigneTotemp.EmployeeName });
      });

    });
  }



  CallDates(Calldate): void {

    var dateParts = Calldate.split("/");
    this.CRM_ScheduleCall.Calldate = new Date(+dateParts[2], dateParts[1], +dateParts[0]);
    this.CRM_ScheduleCall.CallDateVal = Calldate;
  }



  GetOpportunityList() {
    let t = localStorage.getItem('userToken');
    t = 'Bearer' + t
    this.OpportunityList = [];
    this.OpportunityArray = [];

    this.CRM_OppertunityService.Get_CRMOppertunityList(t, this.CRM_ScheduleCall.Company_Id).then(x => {
      this.OpportunityName = x;
      Object.keys(this.ContactName).forEach(key => {
        let OpportunityTemp = new Molel_CRM_Opportunity_List();
        OpportunityTemp = this.OpportunityName[key];
        this.OpportunityList.push(OpportunityTemp);
        this.OpportunityArray.push({ "id": OpportunityTemp.OpportunityId, "itemName": OpportunityTemp.OpportunityName });
      });
    });
  }

  onItemSelectOpportunity(item: any) {

    let OpportunityId = item['id'];
    this.CRM_ScheduleCall.OpportunityId = OpportunityId;

  }
  OnItemDeSelectOpportunity(item: any) { }
  onSelectAllOpportunity(items: any) { }
  onDeSelectAllOpportunity(items: any) { }





  //Begning of  Time drop down List
  GetStartTime() {

    //this.CRM_ScheduleCall.DispositionStatus
    let t = localStorage.getItem('userToken');
    t = 'Bearer' + t
    this.StartimeList = [];
    //this.DesignationArray=[];
    this.LeadSrevice.GetCrmLGettime(t).then(x => {
      this.Timeobject = x;
      Object.keys(this.Timeobject).forEach(key => {
        let Timetemp = new Model_CRM_Time();
        Timetemp = this.Timeobject[key];
        this.StartimeList.push(Timetemp);
      })

    })
  }
  // end of the Time drop down list



  //Begning of  Disposition status List
  GetDipositionStatus() {
    let t = localStorage.getItem('userToken');
    t = 'Bearer' + t
    this.DispositionList = [];
    //this.DesignationArray=[];
    this.LeadSrevice.GetCrmGetDispositionStatus(t).then(x => {
      this.DispositionObject = x;
      Object.keys(this.DispositionObject).forEach(key => {
        let Dispositiotemp = new Model_CRM_Disposition_Status();
        Dispositiotemp = this.DispositionObject[key];
        this.DispositionList.push(Dispositiotemp);
      })

    })
  }
  //end of the Disposition status list



  //Begning of Call type  List
  GetCRMCallType() {

    let t = localStorage.getItem('userToken');
    t = 'Bearer' + t
    this.CRM_Call_Types = [];
    //this.DesignationArray=[];
    this.LeadSrevice.GetCrmGetCallType(t).then(x => {
      this.CallTypeObject = x;
      Object.keys(this.CallTypeObject).forEach(key => {
        let Calltypetemp = new Model_CRM_CallType();
        Calltypetemp = this.CallTypeObject[key];
        this.CRM_Call_Types.push(Calltypetemp);
      });
    });
  }
  //end of the Call Type List

  SubjectChange(ev) {
    try {

      this.CRM_ScheduleCall.Subject = ev.target.value;
    } catch (e) {
      console.info('could not set textarea-value');
    }
  }


  NotechangeChange(ev) {
    try {

      this.CRM_ScheduleCall.Notes = ev.target.value;
    } catch (e) {
      console.info('could not set textarea-value');
    }
  }

  OnChangeAlldayevent(evt) {

    var target = evt.target;
    if (target.checked) {
      this.CRM_ScheduleCall.IsAllDay = true;

    }
    else {
      this.CRM_ScheduleCall.IsAllDay = false;

    }


  }


  OnChangePriorityCall(evt) {

    var target = evt.target;
    if (target.checked) {
      this.CRM_ScheduleCall.IsPriorityCall = true;
    }
    else {
      this.CRM_ScheduleCall.IsPriorityCall = false;
    }

  }


  OnChangeComplete(evt) {

    var target = evt.target;
    if (target.checked) {
      this.CRM_ScheduleCall.IsComplete = true;
    }
    else {
      this.CRM_ScheduleCall.IsComplete = false;
    }

  }




  fileuploads(event) {

    const files = event.target.files;
    //console.log(files);

    if (files) {
      for (let i = 0; i < files.length; i++) {

        // const image ={

        //   name :'',
        //   type : '',
        //   size: '',
        //   url:''
        // } ;

        let image = new Model_CRM_ScheduleCall_Documents();

        // this.allfiles.push(files[i]);

        this.Uploadfile = files[i];

        image.DocumentActualName = files[i].name;
        image.DocumentType = files[i].type;

        image.DocumentName = image.DocumentActualName;

        const size = files[i].size / 1024;
        const mbc = size + '';
        const mb = mbc.split('.')[0];
        const length = mb.length;

        if (length === 4 || length === 5) {

          const mbsize = size / 1024;

          const splitdata = mbsize + '';
          const splitvalue = splitdata.split('.');
          let secondvariable = '';
          for (let j = 0; j < splitvalue.length; j++) {

            if (j === 1) {
              secondvariable = splitvalue[j].slice(0, 2);
            }
          }

          image.DocumentSize = splitvalue[0] + '.' + secondvariable + ' MB';
        }
        else {

          const splitdata = size + '';
          const splitvalue = splitdata.split('.');
          let secondvariable = '';
          for (let j = 0; j < splitvalue.length; j++) {

            if (j === 1) {
              secondvariable = splitvalue[j].slice(0, 2);
            }
          }

          image.DocumentSize = splitvalue[0] + '.' + secondvariable + ' KB';

        }



        var FileReades: FileReader = new FileReader();


        let filetyotypearray = image.DocumentSize.split("/");
        var re = /(?:\.([^.]+))?$/;

        this.Documents.DocumentName = image.DocumentName
        this.Documents.DocumentExtension = re.exec(image.DocumentName)[1];
        this.Documents.DocumentType = image.DocumentType;
        this.Documents.DocumentSize = image.DocumentSize;


        // image.size=files[i].size /1000;

        const reader = new FileReader();
        reader.onload = (filedata) => {
          // image.url=reader.result + '';
          this.Documents.File = String(reader.result);
        };
        reader.readAsDataURL(files[i]);
        // reader.readAsText(files[i]);
        // this.images.push(image);
        this.Documents.DocumentName = image.DocumentName;
        this.Documents.DocumentActualName = image.DocumentActualName;
        // ObjFileUpload.File = String( reader.result);
        // alert(ObjFileUpload.File);

      }
      event.srcElement.value = null;
      this.CRM_ScheduleCall.Document = this.Documents;

    }



  }

  DeleteFile() {

    this.Documents.DocumentName = '';
    this.Documents.DocumentExtension = '';
    this.Documents.DocumentType = '';
    this.Documents.DocumentSize = '';
    this.Documents.DocumentActualName = '';
  }



  saveCRMScheduleCall(): void {


    let t = localStorage.getItem('userToken');
    t = 'Bearer' + t;


    if ((this.CRM_ScheduleCall.Company_Id > 0) && (this.CRM_ScheduleCall.CallDateVal != '') && (this.CRM_ScheduleCall.CallType > 0)
      && (this.CRM_ScheduleCall.startTme > 0) && (this.CRM_ScheduleCall.Endtime > 0)) {

      this.SubmitEnable = false;

      this.LeadSrevice.AddCRMSchedulecall(t, this.CRM_ScheduleCall).subscribe(res => {
        this.Noteid = res;


        if (this.Noteid > 0) {

          this.SubmitEnable = true;

          this.CRM_ScheduleCall.SheduleCall_Id = 0;

          this.CRM_ScheduleCall.Company_Id = 0;
          this.CRM_ScheduleCall.IsComplete = false;
          this.CRM_ScheduleCall.OpportunityId = 0;
          this.CRM_ScheduleCall.CallType = 0;
          this.CRM_ScheduleCall.startTme = 0;
          this.CRM_ScheduleCall.Endtime = 0;
          this.CRM_ScheduleCall.IsAllDay = false;
          this.CRM_ScheduleCall.IsPriorityCall = false;
          this.CRM_ScheduleCall.ContactIds = [];
          this.CRM_ScheduleCall.Invited_attendees = [];
          this.CRM_ScheduleCall.DispositionStatus = 0;

          this.CRM_ScheduleCall.Calldate = new Date();

          let pipe = new DatePipe('en-US');
          const now = Date.now();
          const myFormattedDate = pipe.transform(now, 'dd/MM/yyyy');
          this.CRM_ScheduleCall.CallDateVal = myFormattedDate;
          this.CRM_ScheduleCall.Calldate = new Date();
          this.Documents = new Model_CRM_ScheduleCall_Documents();
          this.Documents.SheduleCall_Id = 0;
          this.Documents.DocumentName = '';
          this.Documents.DocumentExtension = '';
          this.Documents.DocumentType = '';
          this.Documents.DocumentSize = '';
          this.Documents.DocumentActualName = '';

          this.CRM_ScheduleCall.Document = new Model_CRM_ScheduleCall_Documents();
          this.CRM_ScheduleCall.Document.SheduleCall_Id = 0;
          this.CRM_ScheduleCall.Document.DocumentName = '';
          this.CRM_ScheduleCall.Document.DocumentExtension = '';
          this.CRM_ScheduleCall.Document.DocumentType = '';
          this.CRM_ScheduleCall.Document.DocumentSize = '';
          this.CRM_ScheduleCall.Document.DocumentActualName = '';

          localStorage.setItem('SuccessfullMessage', "Successfully Updated");
          this.rout.navigateByUrl('/ManageMICEAccess/ManageLead/LeadBasicInfo', { skipLocationChange: true }).then(() => this.rout.navigate(["/ManageMICEAccess/ScheduleCall"]));

        }

      });



    }

  }


  showAlertMessage() {

    if (localStorage.getItem('SuccessfullMessage')) {
      this.SuccessStatus = true;
      this.SuccessMessage = localStorage.getItem('SuccessfullMessage')
      localStorage.removeItem('SuccessfullMessage');
      this.myChild.setvisible();

    }


  }

  CancleScheduleCall() {
    this.rout.navigateByUrl('/ManageMICEAccess/ManageLead/LeadBasicInfo', { skipLocationChange: true }).then(() => this.rout.navigate(["/ManageMICEAccess/ScheduleCall"]));

  }

}
