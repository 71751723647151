import { Model_Crm_ListName } from './Model_Crm_ListName'
import { Model_CRM_TeleCall_FollowUp } from './Model_CRM_TeleCall_FollowUp'

// import { } from '../LEADCRM/Model_Crm_List'
export class Model_CRM_Telecall {
    public Tele_Call_Id: number
    public CalldateVal: string;

    public CreatedBy: string;
    public Time: string;
    public Calldate: Date
    public CorPorateName: string;
    public ClietName: string;
    public PhoneNo: string;
    public EmailId: string;
    public CallType: string;
    public CallTypeName: string;
    public Remarks: string;
    public ListId: number[];
    public FollowUp: Model_CRM_TeleCall_FollowUp[];
    public ListNames: Model_Crm_ListName[]

    constructor() {
        this.ListId = [];
        this.FollowUp = [];
        this.ListNames = [];
    }

}