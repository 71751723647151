import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs/Subject';
import { DataTableDirective } from 'angular-datatables';
import { LevelService }  from '../../shared/HR_ADMIN/Lavels_Grades/Levels.service';
import { DecisionMaking } from  '../../shared/HR_ADMIN/Lavels_Grades/DecisionMaking.Model';
import { Level } from  '../../shared/HR_ADMIN/Lavels_Grades/Level.Model';
import { Grade } from  '../../shared/HR_ADMIN/Lavels_Grades/Grade.Model';
import { Designation } from  '../../shared/HR_ADMIN/Lavels_Grades/Designation.Model';
import { UserService } from '../../shared/user.service';
// Begning Confirmation
import { ConfirmationService } from '@jaspero/ng2-confirmations';
import { ResolveEmit } from '../../../app/resolve-emit';
import { ConfirmSettings } from '../../../app/confirm-settings';
// end of Confirmation
import { Menu } from '../../shared/menu.model';

@Component({
  selector: 'app-manage-designation',
  templateUrl: './manage-designation.component.html',
  styleUrls: ['./manage-designation.component.css']
})
export class ManageDesignationComponent implements OnInit 
{
  DesignationModel : Designation= new Designation();
  userClaims: any;
  Result : string;
  SuccessStatus:boolean=false;
  SuccessMessage: string ="";
  public tableWidget: any;
  Designations: Designation[] = [];
  Designation:any;
  DesignationId : any;
  Decisions: DecisionMaking[] = [];
  Decision: any;
  Levels: Level[] = [];
  Level:any;
  Grades: Grade[] = [];
  Grade:any;
  tempDesignationadd : Designation =new Designation();
  // Begning Permission Portion    
  SelectedMenu:Menu;
  IsDataIsInViewState : boolean=false;
  CanViewState : boolean=false;
  DisableSave : boolean=false;
  // End of the permission portion
  //check for duplicate Name
  CheckNameExiststatus : boolean=null;  
  CheckNamestatusedit : boolean =false;
  Namecheck : string ="";
  checkName : any;
  checkNameval : string ="";
  //check for duplicate Name

  constructor(private LevelService: LevelService, private rout: Router,
    private _confirmation: ConfirmationService, private userService: UserService) { }

  @ViewChild(DataTableDirective, {static: false})
  setDtOptions: DataTables.Settings = {};
  cardDtOptions: DataTables.Settings = {};
  dtElement: DataTableDirective;
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();

  ngOnInit()
  {
    // Begning Permission Portion 
    this.SelectedMenu=this.userService.SelectedMenu;
    if(this.SelectedMenu !=undefined)
    {
      localStorage.setItem('selectedmenu',JSON.stringify(this.SelectedMenu));
      this.rout.navigate([this.SelectedMenu.RouteName]);
    }
    else
    {
      if(JSON.parse(localStorage.getItem('selectedmenu'))!=undefined)
      {
        this.SelectedMenu = JSON.parse(localStorage.getItem('selectedmenu'));
        this.rout.navigate([this.SelectedMenu.RouteName]);
      }
    }
    if(this.SelectedMenu.CanAdd)
    {
      this.IsDataIsInViewState=false;
    }
    else
    {
      this.IsDataIsInViewState=true;
    }
    if(this.SelectedMenu.CanView)
    {
      this.CanViewState=false;
    }
    else
    {
      this.CanViewState=true;
    }
    // End of the permission portion
    this.DisableSave=true;
    this.getDecisionMaking();
    this.DesignationModel.DecisionId=0;
    if(localStorage.getItem('SuccessfullMessage'))
    {
      this.SuccessStatus=true;
      this.SuccessMessage =localStorage.getItem('SuccessfullMessage')
      localStorage.removeItem('SuccessfullMessage');
    }
  }

  settings: ConfirmSettings | any = {
    overlay: true,
    overlayClickToClose: true,
    showCloseButton: true,
    confirmText: 'Yes',
    declineText: 'No'
  };

  ngAfterViewInit(): void 
  {
    this.getDesignations();
    this.dtOptions = 
    {
      pagingType: 'full_numbers',
      pageLength: 2
    };
  }

  getDecisionMaking() 
  {
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.LevelService.GetDecisionMaking( t).then(x => 
    {
      this.Decision = x;
       Object.keys(this.Decision).forEach( key => 
      {
        this.Decision[key];
        let Decisiontemp = new DecisionMaking();
        Decisiontemp = this.Decision[key];
        this.Decisions.push(Decisiontemp);
      });
    });      
  }
  
  getDesignations()
  {
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.LevelService.GetDesignation( t).then(x => 
    {
      this.Designation = x;
      Object.keys(this.Designation).forEach( key => 
      {
        this.Designation[key];
        let Designationtemp = new Designation();
        Designationtemp = this.Designation[key];
        this.Designations.push(Designationtemp);
      });
      this.dtTrigger.next();
    });
  }

  onDecisionChange(Decisionid) 
  {
    if(Decisionid!=0)
    {
      this.getLevels(Decisionid);
      this.DesignationModel.LevelId=0;
    }
    else
    {
      this.getLevels(Decisionid);
      this.DesignationModel.LevelId=0;
    }
  }

  getLevels( Decisionid) 
  {
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t
    if(this.Levels.length>0)
    {
      this.Levels.splice(0, this.Levels.length);
    }
    this.LevelService.GetLevelByDecisionid( t,Decisionid).then(x => 
    {
      this.Level = x;
      Object.keys(this.Level).forEach( key => 
      {
        this.Level[key];
        let Leveltemp = new Level();
        Leveltemp = this.Level[key];
        this.Levels.push(Leveltemp);
      });     
    });      
  }

  onLevelChange(Levelid) 
  {
    if(Levelid!=0)
    {
      this.getGrades(Levelid);
      this.DesignationModel.GradeId=0;
    }
    else
    {
      this.getGrades(Levelid);
      this.DesignationModel.GradeId=0;
    }
  }

  getGrades( Levelid) 
  {
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t
    if(this.Grades.length>0)
    {
      this.Grades.splice(0, this.Grades.length);
    }
    this.LevelService.GetGradeByLevelid( t,Levelid).then(x => 
    {
      this.Grade = x;
      Object.keys(this.Grade).forEach( key => 
      {
        this.Grade[key];
        let Gradetemp = new Grade();
        Gradetemp = this.Grade[key];
        this.Grades.push(Gradetemp);
      });     
    });      
  }

  GetDesignationByid(id) 
  {
    // begning of the permission portion
    this.IsDataIsInViewState=false;
    // end of the permission portion
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.LevelService.GetDesignationByid( t,id).then(x => 
    {
      this.Designation = x;
      Object.keys(this.Designation).forEach( key => 
      {
        this.Designation[key];
        let Designationtemp = new Designation();
        Designationtemp = this.Designation[key];
        this.DesignationModel.DesignationId=Designationtemp.DesignationId;
        this.DesignationModel.DesignationCode=Designationtemp.DesignationCode;
        this.DesignationModel.DesignationName=Designationtemp.DesignationName;
        this.DesignationModel.DecisionId=Designationtemp.DecisionId;
        this.DesignationModel.LevelId=Designationtemp.LevelId;
        this.DesignationModel.GradeId=Designationtemp.GradeId
      });
      this.getLevels(this.DesignationModel.DecisionId);
      this.getGrades(this.DesignationModel.LevelId);
      // Begin for check Name duplication
      this.checkNameval= this.DesignationModel.DesignationName;
      this.CheckNamestatusedit=true;
      this.CheckNameExiststatus =true;
      this.Namecheck=""
      // End for check Name duplication
    });
  }

  ViewDesignationByid(id) 
  {
 // begning of the permission portion
 this.IsDataIsInViewState=true;
 // end of the permission portion

    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.LevelService.GetDesignationByid( t,id).then(x => 
    {
      this.Designation = x;
      Object.keys(this.Designation).forEach( key => 
      {
        this.Designation[key];
        let Designationtemp = new Designation();
        Designationtemp = this.Designation[key];
        this.DesignationModel.DesignationId=Designationtemp.DesignationId;
        this.DesignationModel.DesignationCode=Designationtemp.DesignationCode;
        this.DesignationModel.DesignationName=Designationtemp.DesignationName;
        this.DesignationModel.DecisionId=Designationtemp.DecisionId;
        this.DesignationModel.LevelId=Designationtemp.LevelId;
        this.DesignationModel.GradeId=Designationtemp.GradeId
      });
      this.getLevels(this.DesignationModel.DecisionId);
      this.getGrades(this.DesignationModel.LevelId);
      // Begin for check Name duplication
      this.checkNameval= this.DesignationModel.DesignationName;
      this.CheckNamestatusedit=true;
      this.CheckNameExiststatus =true;
      this.Namecheck=""
      // End for check Name duplication
    });
  }
     
  saveDesignation() :void
  {
    this.DisableSave=false;
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.DesignationModel.IsActive=true;
    if(this.DesignationModel.DesignationId > 0)
    {
      this.LevelService.UpdateDesignation(t ,this.DesignationModel).subscribe(res=>
      {
        this.DesignationId=res;
        this.DesignationModel.GradeId=0;  
        if(this.DesignationId>0)
        {
          localStorage.setItem('SuccessfullMessage', "Update Successfully");
          this.rout.navigateByUrl('/Levels/ManageDescitionMakingLevels', {skipLocationChange: true}).then(()=>this.rout.navigate(["/Levels/ManageDesignation"]));
        }
      });                  
    }
    else
    {
      this.LevelService.AddDesignation(t ,this.DesignationModel).subscribe(res=>
      {
        this.DesignationId=res;
        this.DesignationModel.DesignationId=0;              
        if(this.DesignationId>0)
        {
          localStorage.setItem('SuccessfullMessage', "Save Successfully");
          this.rout.navigateByUrl('/Levels/ManageDescitionMakingLevels', {skipLocationChange: true}).then(()=>this.rout.navigate(["/Levels/ManageDesignation"]));
        }
      }); 
    }
  }

  DeleteDesignationByid(DesignationId : number) :void
  {   
    this._confirmation.create('Confirm to Deleate', 'Press Yes to Delete', this.settings)
    .subscribe((ans: ResolveEmit) => {
      if (ans.resolved == true) {

        let t =  localStorage.getItem('userToken');
      t = 'Bearer' + t;
      this.DesignationModel.IsActive=true;
      if( DesignationId > 0)
      {
        this.LevelService.DeleteDesignation(t ,DesignationId).subscribe(res=>
        {
          this.DesignationId=res;
          this.DesignationModel.GradeId=0;                                  
          if(this.DesignationId >0)
          {
            localStorage.setItem('SuccessfullMessage', "Deleted Successfully");
            this.rout.navigateByUrl('/Levels/ManageDescitionMakingLevels', {skipLocationChange: true}).then(()=>this.rout.navigate(["/Levels/ManageDesignation"]));
          }
        });                  
      }
      }
    })


  }

  RefreshPage()
  {
    this.rout.navigateByUrl('/Levels/ManageDescitionMakingLevels', {skipLocationChange: true}).then(()=>this.rout.navigate(["/Levels/ManageDesignation"]));
  }

  // begning of check duplicate Name
  CheckName()
  {
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    if(!this.CheckNamestatusedit)
    {
      this.LevelService.CheckDesignationName( t, this.DesignationModel.DesignationName,this.DesignationModel.GradeId).then(x => 
      {
        this.checkName = x;
        if( this.checkName.length >0)
        {
          this.CheckNameExiststatus =false;
          this.Namecheck="Exist"
        }
        else
        {
          this.CheckNameExiststatus =true;
          this.Namecheck="";
        }
     });
    }
    else
    {
      if(this.DesignationModel.DesignationName !=this.checkNameval )
      {
        this.LevelService.CheckDesignationName( t, this.DesignationModel.DesignationName, this.DesignationModel.GradeId).then(x => 
        {
          this.checkName = x;
          if( this.checkName.length >0)
          {
            this.CheckNameExiststatus =false;
            this.Namecheck="Exist"
          }
          else
          {
            this.CheckNameExiststatus =true;
            this.Namecheck="";
          }
        });
      }
      else
      {
        this.CheckNameExiststatus =true;
        this.Namecheck="";
      }
    }
  }
  // end of duplicate Name
}
