import { Component, OnInit,Renderer  } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from '../../shared/user.service';
import { User } from '../../shared/user.model';
import { Menu }  from '../../shared/menu.model';
import { ApproveLeaveService } from '../../shared/HR_ADMIN/Approve/ApproveLeave.service'
import { ConfirmationService } from '@jaspero/ng2-confirmations';
import { ResolveEmit } from '../../../app/resolve-emit';
import { ConfirmSettings } from '../../../app/confirm-settings';

@Component({
  selector: 'app-crm-master',
  templateUrl: './crm-master.component.html',
  styleUrls: ['./crm-master.component.css']
})
export class CrmMasterComponent implements OnInit 
{
  userClaims: any;
  SubMenues: Menu[] = [];
  MainMenu: Menu[] = [];
  Mod: any;
  check: number;
  selected :any;
  selectedIndex: number;
  selecteModule : number;

  tempmenu : Menu;
  SubMenuestemp: Menu[] = [];

  UserModel : User= new User();
  Users: User[] = [];
  User: any;

  settings: ConfirmSettings | any = 
  {
    overlay: true,
    overlayClickToClose: true,
    showCloseButton: true,
    confirmText: 'Yes',
    declineText: 'No'
  };

  constructor(private router: Router, private userService: UserService, private _confirmation: ConfirmationService,private render: Renderer, public leaveservice : ApproveLeaveService) 
  {
    this.getLeaveNotification();
    if(userService.selectdmoduleid !=undefined)
    {
      localStorage.setItem("moduleidstor", userService.selectdmoduleid.toString());
      localStorage.setItem("menuidstor", userService.selectedmenuid.toString());
    }
    let mindex=localStorage.getItem("selectedmodule")
    this.selectedIndex=0;
    this.selecteModule=Number(mindex);
  }
   
  isActive(item) 
  {
    if(item === this.selected)
    {
      this.selected = null;
    }
    else
    {
      this.selected = item;
    }
  };

  ngOnInit() 
  {
    this.GetLoginDetails();
    this.getMenues();
    this.getSubmenues();
  }

  getMenues() 
  {
    this.MainMenu=[];
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    let moduleid=localStorage.getItem("moduleidstor")
    this.userService.MainMenuList( t, moduleid).then(x => 
    {
      this.Mod = x;
      Object.keys(this.Mod).forEach( key =>
      {
        // tslint:disable-next-line:no-unused-expression
        this.Mod[key];
        let menutemp = new Menu();
        menutemp = this.Mod[key];
        this.MainMenu.push(menutemp);
      });
    });
  }

  public listClick(event: any)
  {
    event.preventDefault();
    this.render.setElementClass(event.target, "current", true);
    // How to remove 'active' from siblings ?
  }
   
  getSubmenues() 
  {
    this.SubMenues=[];
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    let moduleid=localStorage.getItem("moduleidstor");
    let menuid = localStorage.getItem("menuidstor");
    this.userService.GetSubMenuList( t, menuid).then(x => 
    {
      this.Mod = x;
      Object.keys(this.Mod).forEach( key => 
      {
        // tslint:disable-next-line:no-unused-expression
        this.Mod[key];
        let menutemp = new Menu();
        menutemp = this.Mod[key];
        this.SubMenues.push(menutemp);
      });
      this.userService.SelectedMenu= this.SubMenues[0];
    });
  }
   
  LoadComponents(routname, i, menuid)
  {
    this.selectedIndex = i;
    this.router.navigate([routname]);
    this.userService.SelectedMenu= this.SubMenues.find(x=>x.MenuId==parseInt(menuid));  
  }

  MainPages(menuid, moduleid,RouteName,j) 
  {
    this.userService.selectdmoduleid = moduleid;
    this.userService.selectedmenuid= menuid;
    localStorage.setItem("selectedmodule", j);
    this.router.navigate([RouteName]);        
  }

  GetLoginDetails() 
  {
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.userService.LoginDetails( t).then(x => 
    {
      this.User = x;
      this.UserModel.EmployeeName=this.User.EmployeeName;
      this.UserModel.EmpPhotoURL=this.User.EmpPhotoURL;
      this.UserModel.DesignationName=this.User.DesignationName;
      this.UserModel.LastLoginTime=this.User.LastLoginTime;          
      this.UserModel.IPAddress=this.User.IPAddress;
      this.UserModel.Id=this.User.Id;
      this.UserModel.CurrentTime=this.User.CurrentTime;
      this.UserModel.IconUrlLogoType=this.User.IconUrlLogoType;
      this.UserModel.IconUrlImage=this.User.IconUrlImage;
      this.UserModel.CompPhotoURL=this.User.CompPhotoURL;
      this.UserModel.CompIconUrlLogoType=this.User.CompIconUrlLogoType;
      this.UserModel.CompIconUrlImage=this.User.CompIconUrlImage;
    });
  }
  
  Logout() 
  {
    this._confirmation.create('Confirm to LogOut', 'Press Yes to LogOut', this.settings)
    .subscribe((ans: ResolveEmit) => 
    {
      if (ans.resolved == true) 
      {
        let t =  localStorage.getItem('userToken');
        t = 'Bearer' + t;
        this.userService.UpdateLogOutTime(t ,this.UserModel.Id).subscribe(res=>
        {
          this.UserModel.Id=0;  
        });
        localStorage.removeItem('userToken');
        this.router.navigate(['/login']);
      }
    })
  }

  Approvalpage() 
  {
    if(this.leaveservice.TotalNoofLeaveNotification > 0)
    {
      this.router.navigate(['/ApproveOrReject/Leave']);
    }    
  }

  getLeaveNotification()
  {
    let t =  localStorage.getItem('userToken');
    t = 'Bearer ' + t;
    this.leaveservice.GetLeaveApplicationsNotification(t).then(x => 
    {
      this.Mod = x;
      this.leaveservice.TotalNoofLeaveNotification=this.Mod;
    });
  }

  OnDashboard()
  {
    this.router.navigate(['/DashBoard']);
  }
}