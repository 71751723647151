<div id="wrapper">
  <!-- Sidebar -->
  <ul class="navbar-nav bg-gradient-primary sidebar sidebar-dark accordion" id="accordionSidebar">
    <!-- Sidebar - Brand -->
    <a class="sidebar-brand d-flex align-items-center justify-content-center bg-white" href="javascript:void(0);" (click)="OnDashboard()">
      <div class="sidebar-brand-icon">
      <img *ngIf="this.UserModel.CompIconUrlImage!=null" [src]="UserModel.CompIconUrlImage" alt="Logo" class="logo logo-Dashbord">
      </div>
      <!-- <div class="sidebar-brand-text mx-3">HRIS</div> -->
    </a>
    <!-- Divider -->
    <hr class="sidebar-divider my-0">
    <!-- Nav Item - Dashboard -->
    <li class="nav-item active">
      <a class="nav-link" href="javascript:void(0);" (click)="OnDashboard()">
        <i class="fas fa-fw fa-tachometer-alt"></i>
        <span>Dashboard</span></a>
    </li>
    <!-- Divider -->
    <hr class="sidebar-divider">
    <!-- Heading -->
    <!-- <div class="sidebar-heading">Interface</div> -->
    <li class="nav-item" *ngFor="let user of Modules">
      <a class="nav-link" href="javascript:void(0);" (click)="Menues(user.ModuleId)">
        {{user.MenuTitle}}
      </a>
      <hr class="sidebar-divider d-none d-md-block">
    </li>
    <!-- Divider -->
    <!-- Sidebar Toggler (Sidebar) -->
    <div class="text-center d-none d-md-inline">
      <button class="rounded-circle border-0" id="sidebarToggle"></button>
    </div>
  </ul>
  <!-- End of Sidebar -->
  <!-- Content Wrapper -->
  <div id="content-wrapper" class="d-flex flex-column">
    <!-- Main Content -->
    <div id="content">
      <!-- Topbar -->
      <nav class="navbar navbar-expand navbar-light bg-white topbar mb-4 static-top shadow">
        <!-- Sidebar Toggle (Topbar) -->
        <button id="sidebarToggleTop" class="btn btn-link d-md-none rounded-circle mr-3">
          <i class="fa fa-bars"></i>
        </button>

        <div class="offset-lg-5 col-lg-4">
          <div class="text-right header-text">
              <span>
                  Last Login Time: {{UserModel.LastLoginTime | date : "dd-MMM-yyyy hh:mm:ss a"}} 
              </span>
              <span>/</span>
              <span> IP: {{UserModel.IPAddress}}</span>
          </div>
        </div>
        <!-- Topbar Navbar -->
        <ul class="navbar-nav ml-auto">

          <!-- Nav Item - Search Dropdown (Visible Only XS) -->
          <li class="nav-item dropdown no-arrow d-sm-none">
            <a class="nav-link dropdown-toggle" href="javascript:void(0);" id="searchDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              <i class="fas fa-search fa-fw"></i>
            </a>
            <!-- Dropdown - Messages -->
            <div class="dropdown-menu dropdown-menu-right p-3 shadow animated--grow-in" aria-labelledby="searchDropdown">
            </div>
          </li>

          <!-- Notification - Alerts -->
          <li class="nav-item dropdown no-arrow mx-1">
            <a class="nav-link dropdown-toggle" href="javascript:void(0);" id="alertsDropdown" role="button" data-toggle="dropdown"
              aria-haspopup="true" aria-expanded="false">
              <i class="fas fa-bell fa-fw"></i>
              <!-- Counter - Alerts -->
              <span class="badge badge-danger badge-counter">{{this.UserModel.TotalAllApplication}}</span>
            </a>
            <!-- Dropdown - Alerts -->
            <div *ngIf="this.Notifications.length > 0" class="dropdown-list dropdown-menu dropdown-menu-right shadow animated--grow-in" aria-labelledby="alertsDropdown">
              <h6 class="dropdown-header">Notification Alerts</h6>
              <li class="nav-item" *ngFor="let Noti of Notifications">
                <a style="color: red;" class="nav-link" href="javascript:void(0);" (click)="PageRedirectClick(Noti.ModuleId,Noti.MainMenuId,Noti.MenuId)">
                 {{Noti.ApplicationType}} - {{Noti.TotalApplication}}
                </a>
              </li>
            </div>
          </li>

          <!-- HR Policy & Forms -->
          <li class="nav-item dropdown no-arrow mx-1">
            <a class="nav-link dropdown-toggle" href="javascript:void(0);" id="messagesDropdown" role="button" data-toggle="dropdown"
              aria-haspopup="true" aria-expanded="false">
              <i class="fas fa-envelope fa-fw"></i>
              <span class="badge badge-danger badge-counter">{{this.PolicyCount}}</span>
            </a>
            <div *ngIf="this.HRPolicies.length > 0"
              class="dropdown-list dropdown-menu dropdown-menu-right shadow animated--grow-in"
              aria-labelledby="alertsDropdown">
              <h6 class="dropdown-header">HR Policies & Forms</h6>
              <li class="nav-item" *ngFor="let HRPF of HRPolicies">
                <a style="color:black;" class="nav-link" href="javascript:void(0);" (click)="download(HRPF.FileName)">
                  {{HRPF.PolicyName}}
                </a>
              </li>
            </div>
          </li>
          
          <div class="topbar-divider d-none d-sm-block"></div>

          <!-- Nav Item - User Information -->
          <li class="nav-item dropdown no-arrow">
            <a class="nav-link dropdown-toggle" href="javascript:void(0);" id="userDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              <span class="mr-2 d-none d-lg-inline text-gray-600 small">{{this.UserModel.EmployeeName}}</span>
              <img *ngIf="this.UserModel.ImageType!=null" [src]="this.UserModel.ImageFile" class="img-fluid" style="height: 80%;">
              <!-- <img *ngIf="this.UserModel.ImageType=null" class="img-profile rounded-circle" src="https://source.unsplash.com/QAB-WJcbgJk/60x60"> -->
            </a>
            
            <!-- Dropdown - User Information -->
            <div class="dropdown-menu dropdown-menu-right shadow animated--grow-in" aria-labelledby="userDropdown">
              <a class="dropdown-item" href="javascript:void(0);" (click)="PageRedirectClick(10,66,67)">
                <i class="fas fa-user fa-sm fa-fw mr-2 text-gray-400"></i>
                Profile
              </a>
              <a class="dropdown-item" href="javascript:void(0);" (click)="PageRedirectClick(10,13,10134)">
                <i class="fas fa-cogs fa-sm fa-fw mr-2 text-gray-400"></i>
                Password Settings
              </a>
              <!-- <a class="dropdown-item" href="javascript:void(0);">
                <i class="fas fa-list fa-sm fa-fw mr-2 text-gray-400"></i>
                Activity Log
              </a> -->
              <div class="dropdown-divider"></div>
              
              <!-- <button class="header-icons power_off" title="Logout" (click)="Logout()">
                <i class="fas fa-power-off"></i>
              </button> -->
              <a class="header-icons power_off" href="javascript:void(0);" data-toggle="modal" (click)="Logout()">
                <i class="fas fa-power-off"></i>
                Logout
              </a>
            </div>
          </li>
        </ul>
      </nav>
      <!-- End of Topbar -->

      <!-- Begin Page Content -->
      <div class="container-fluid">
        <!-- Page Heading -->
        <div class="d-sm-flex align-items-center justify-content-between mb-3">
          <h1 class="h3 mb-0 text-gray-800">HRIS</h1>
        </div>
        <!-- 1st Row -->
        <div class="row">
          <!-- Welcome Board -->
          <div class="col-xl-8 col-md-8 mb-4">
            <div class="card">
              <div class="card-header">
                <h5 class="mb-0">Welcome</h5>
              </div>
              <div class="card-body">
                <table class="table mb-0">
                  <tr>
                    <td width="100" class="border-0"><img [src]="this.UserModel.ImageFile" class="" width="100"
                        height="100"></td>
                    <td class="border-0">
                      <p>{{this.UserModel.EmployeeCode}}</p>
                      <p>{{this.UserModel.EmployeeName}}</p>
                      <p>{{this.UserModel.DesignationName}}</p>
                      <p>{{this.UserModel.Department}}</p>
                    </td>
                    <td class="border-0">
                      <p>Date Of Birth: {{this.UserModel.DOB | date : "dd-MMMM-yyyy"}}</p>
                      <p>Joining Date: {{this.UserModel.JoiningDate | date : "dd-MMMM-yyyy"}}</p>
                      <p>Blood Group: {{this.UserModel.BloodGroup}}</p>
                      <p>Reporting Manager: {{this.UserModel.RHName}}</p>
                    </td>
                  </tr>
                </table>
              </div>
            </div>
          </div>
    
          <div class="col-xl-4 col-md-4 mb-4">
            <!-- Events -->
            <div class="card h-100">
              <!-- Card Header - Dropdown -->
              <div class="card-header">
                <h5 class="mb-0">Director's Message</h5>
                <div class="dropdown no-arrow">
                  <div class="dropdown-menu dropdown-menu-right shadow animated--fade-in"
                    aria-labelledby="dropdownMenuLink">
                  </div>
                </div>
              </div>
              <!-- Card Body -->
              <div style="padding: 0.25rem;" class="card-body">
                <perfect-scrollbar class="mukesh">
                  <div style="line-height: normal;" class="media pt-3" *ngFor="let MDM of MDMessages">
                    {{MDM.Message}}
                  </div>
                </perfect-scrollbar>
              </div>
              <div class="mt-4 text-center small">
                <a href="javascript:void(0);" style=" color:blue; text-decoration: underline; font-weight: bold;"
                (click)="download('assets/img/DirectorsMessage.pdf')">Click Here to View Complete Message</a>
              </div>
            </div>
          </div>
    
          <!-- 2 Row -->
          <div class="col-12">
            <div class="row">
              <!-- Attendance Calendar -->
              <div class="col-xl-8 col-lg-8">
                <div class="card mb-4">
                  <!-- Card Header - Dropdown -->
                  <div class="card-header">
                    <h5 class="mb-0">Attendance Calendar</h5>
                    <div class="dropdown no-arrow">
                    </div>
                  </div>
                  <!-- Card Body -->
                  <div class="card-body">
                    <ng-container *ngIf="options">
                      <ng-fullcalendar #fullcalendar 
                         [eventsModel]="eventsModel" [options]="options"
                       ></ng-fullcalendar>
                    </ng-container>
                    <!-- (dateClick)="dateClick($event)" (clickButton)="clickButton($event)" (eventClick)="eventClick($event)" (eventDragStop)="eventDragStop($event)"-->
                  </div>
                </div>
              </div>
    
    
              <div class="col-xl-4 col-lg-4">
                <!-- FORMS PENDING For Approval -->
                <div class="card mb-4">
                  <div class="card-header">
                    <h5 class="mb-0">Forms Pending For Your Approval</h5>
                  </div>
                  <div class="card-body">
                    <div style="padding: 0.25rem;" *ngIf="(this.Notifications.length > 0)">
                      <table id="tblNotification" style="padding: 0.25rem" class="table table-striped table-bordered text-center table-new row-border hover">
                        <thead class="thead-blue text-center">
                          <tr>
                            <th>Forms Type</th>
                            <th>Pending</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr *ngFor="let Noti of Notifications, let i = index">
                            <td style="text-align: left;">
                              <a class="nav-link" href="javascript:void(0);" (click)="PageRedirectClick(Noti.ModuleId,Noti.MainMenuId,Noti.MenuId)">
                              <strong><u>{{Noti.ApplicationType}}</u></strong>
                              </a>
                            </td>
                            <td style="color: red;"><strong>{{Noti.TotalApplication}}</strong></td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                   <div *ngIf="(this.Notifications.length == 0)" style="font-weight: bold; color: red; text-align: center;">
                    <strong> 0 </strong>
                   </div>
                  </div>
                </div>
    
                <!-- FORMS RAISED BY ME PENDING -->
                <div class="card mb-4">
                  <div class="card-header">
                    <h5 class="mb-0">Forms Raised by Me</h5>
                  </div>
                  <div class="card-body" style="padding: 0.25rem;">
                    <table id="tblFormsRaised" class="table table-striped table-bordered text-center table-new row-border hover">
                      <thead class="thead-blue text-center">
                        <tr>
                          <th>Forms Type</th>
                          <th>Pending</th>
                          <th>Approved</th>
                          <th>Rejected</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr *ngFor="let FRR of FormsRaised, let i = index">
                          <td style="text-align: left;">
                            <a class="nav-link" href="javascript:void(0);"
                            (click)="PageRedirectClick(FRR.ModuleId,FRR.MainMenuId,FRR.MenuId)">
                            <strong><u>{{FRR.ApplicationType}}</u></strong>
                            </a>
                          </td>
                          <td>{{FRR.PendingCount}}</td>
                          <td>{{FRR.ApprovedCount}}</td>
                          <td>{{FRR.RejectedCount}}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <!-- Events Messages -->
                <div class="card mb-4">
                  <!-- Card Header - Dropdown -->
                  <div class="card-header">
                    <h5 class="mb-0">Events</h5>
                    <div class="dropdown no-arrow">
                      <div class="dropdown-menu dropdown-menu-right shadow animated--fade-in"
                        aria-labelledby="dropdownMenuLink">
                      </div>
                    </div>
                  </div>
                  <!-- Card Body -->
                  <div class="card-body">
                    <perfect-scrollbar class="mukesh">
                      <div class="list-unstyled" *ngFor="let EV of Events">
                        <div style="line-height: normal;" class="media pb-3">{{EV.Message}}</div>
                      </div>
                    </perfect-scrollbar>
                    <!-- <div class="mt-4 text-center small">
                      <a href="javascript:void(0);" style=" color:blue; text-decoration: underline; font-weight: bold;"
                        (click)="download('WelcomeLetterForNewJoininee.pdf')">Click Here to View Complete Message</a>
                    </div> -->
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- 3 Row -->
          <div class="col-12">
            <div class="row">
              <!-- Birthday Wishes -->
              <div class="col-xl-4 col-lg-4">
                <div class="card mb-4">
                  <!-- Header-->
                  <div class="card-header">
                    <h5 class="mb-0">Birthdays</h5>
                    <!-- <img [src]="BD.jpg" class="img-fluid" width="38" height="38"> -->
                  </div>
                  <!-- Body -->
                  <div class="card-body">
                    <perfect-scrollbar class="mukesh">
                      <ul class="chart-pie pl-0 mb-0 list-unstyled">
                        <li class="pb-2 mb-2 border-bottom" *ngFor="let BD of Birthdays">
                          <div class="media">
                            <img [src]="BD.ImageFile" class="mr-3" width="50" height="50"/>
                            <div class="media-body">
                              <strong>{{BD.EmployeeName}}</strong><br/><small>({{BD.DepartmentName}}-{{BD.BranchName}})</small>
                              <p class="mt-1 mb-0"><i class="fa fa-birthday-cake text-success"></i> @ {{BD.EventDate | date : "MMMM"}}
                                {{BD.EventDate | date : "dd"}}</p>
                            </div>
                          </div>
                        </li>
                      </ul>
                    </perfect-scrollbar>
                  </div>
                </div>
              </div>
              <!-- Marriage Anniversary -->
              <div class="col-xl-4 col-lg-4">
                <div class="card mb-4">
                  <!-- Card Header - Dropdown -->
                  <div class="card-header">
                    <h5 class="mb-0">Marriage Anniversary</h5>
                    <!-- <img [src]="MA.jpg" class="img-fluid" width="38" height="38"> -->
                  </div>
                  <!-- Card Body -->
                  <div class="card-body">
                    <perfect-scrollbar class="mukesh">
                      <ul class="chart-pie pl-0 mb-0 list-unstyled">
                        <li class="pb-2 mb-2 border-bottom" *ngFor="let MA of MarriageAnniversarys">
                          <div class="media">
                            <img [src]="MA.ImageFile" class="mr-3" width="50" height="50"/>
                            <div class="media-body">
                              <strong>{{MA.EmployeeName}}</strong><br/><small>({{MA.DepartmentName}}-{{MA.BranchName}})</small>
                              <p class="mt-1 mb-0"><i class="fa fa-heartbeat text-danger"></i> @ {{MA.EventDate | date : "MMMM"}} {{MA.EventDate | date : "dd"}}</p>
                            </div>
                          </div>
                        </li>
                      </ul>
                    </perfect-scrollbar>
                  </div>
                </div>
              </div>
              <!-- Work Anniversary -->
              <div class="col-xl-4 col-lg-4">
                <div class="card mb-4">
                  <!-- Card Header - Dropdown -->
                  <div class="card-header">
                    <h5 class="mb-0">Work Anniversary</h5>
                    <!-- <img [src]="WA.jpg" class="img-fluid" width="38" height="38"> -->
                  </div>
                  <!-- Card Body -->
                  <div class="card-body">
                    <perfect-scrollbar class="mukesh">
                      <ul class="chart-pie pl-0 mb-0 list-unstyled">
                        <li class="pb-2 mb-2 border-bottom" *ngFor="let WA of WorkAnniversarys">
                            <div class="media">
                                <img [src]="WA.ImageFile" class="mr-3" width="50" height="50"/>
                                <div class="media-body">
                                  <strong>{{WA.EmployeeName}}</strong><br/><small>({{WA.DepartmentName}}-{{WA.BranchName}})</small>
                                  <p class="mt-1 mb-0"><i class="fa fa-handshake text-primary"></i> {{WA.EventYear}} Years completed on {{WA.EventDate | date : "MMMM"}} {{WA.EventDate | date : "dd"}}</p>
                                </div>
                              </div>
                        </li>
                      </ul>
                    </perfect-scrollbar>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- /.container-fluid -->
      </div>
      <!-- /.container-fluid -->

    </div>
    <!-- End of Main Content -->

    <!-- Footer -->
    <footer class="sticky-footer">
      <div class="container my-auto">
        <div class="copyright text-center my-auto">
          <span>Copyright © 1997-2018. All Rights Reserved</span>
        </div>
      </div>
    </footer>
    <!-- End of Footer -->

  </div>
  <!-- End of Content Wrapper -->
</div>