import { AfterViewInit, Component, OnInit, ViewChild,TemplateRef } from '@angular/core';
import { Router } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs/Rx';
import { templateJitUrl } from '@angular/compiler';
import { Subject } from 'rxjs/Subject';
import { empty } from 'rxjs/observable/empty';
import { DataTablesModule,DataTableDirective } from 'angular-datatables';
import { CommonModule , DatePipe } from '@angular/common';
import { DialogComponent } from '../../dialog/dialog.component';
import { UserService } from '../../shared/user.service';
import { Menu } from '../../shared/menu.model';
import { OrganizationService }  from '../../shared/Organization/organization.service';
import { LocationService }  from '../../shared/HR_ADMIN/Location/location.service';
import { HolidaysMasterService } from '../../shared/HR_ADMIN/Holidays/HolidaysServices.service';
import { Company } from '../../shared/Organization/Company.Model';
import { Branch } from  '../../shared/Organization/Branch.Model';
import { HolidaysModel } from '../../shared/HR_ADMIN/Holidays/HolidaysModel.Model';
// Begning Confirmation
import { ConfirmationService } from '@jaspero/ng2-confirmations';
import { ResolveEmit } from '../../../app/resolve-emit';
import { ConfirmSettings } from '../../../app/confirm-settings';
// end of Confirmation

@Component({
  selector: 'app-manage-holidays-calendar',
  templateUrl: './manage-holidays-calendar.component.html',
  styleUrls: ['./manage-holidays-calendar.component.css']
})
export class ManageHolidaysCalendarComponent implements OnInit 
{
  HolidaysModel : HolidaysModel= new HolidaysModel();
  HolidaysMaster : HolidaysModel[]=[];
  Holidays : any;
  userClaims: any;
 
  // Begning Permission Portion    
  SelectedMenu:Menu;
  IsDataIsInViewState : boolean=false;
  CanViewState : boolean=true;
  // End of the permission portion
  constructor(private HolidaysMasterService: HolidaysMasterService,
    private rout: Router,  private userService: UserService )  { }

  ngOnInit() 
  {
    // Begning Permission Portion 
    this.SelectedMenu=this.userService.SelectedMenu;
    if(this.SelectedMenu !=undefined)
    {
      localStorage.setItem('selectedmenu',JSON.stringify(this.SelectedMenu));
      this.rout.navigate([this.SelectedMenu.RouteName]);
    }
    else
    {
      if(JSON.parse(localStorage.getItem('selectedmenu'))!=undefined)
      {
        this.SelectedMenu = JSON.parse(localStorage.getItem('selectedmenu'));
        this.rout.navigate([this.SelectedMenu.RouteName]);
      }
    }
    if(this.SelectedMenu.CanAdd)
    {
      this.IsDataIsInViewState=false;
    }
    else
    {
      this.IsDataIsInViewState=true;
    }
    if(this.SelectedMenu.CanView)
    {
      this.CanViewState=false;
    }
    else
    {
      this.CanViewState=true;
    }
    // End of the permission portion
    this.GetHolidaysMaster();
  }

  ngAfterViewInit(): void 
  {
    
  }

  GetHolidaysMaster()
  {
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.HolidaysMasterService.GetHolidaysCalendarForEmployee( t).then(x => 
    {
      this.Holidays = x;
      Object.keys(this.Holidays).forEach( key => 
      {
        this.Holidays[key];
        let Holidaystemp = new HolidaysModel();
        Holidaystemp = this.Holidays[key];
        this.HolidaysModel.CompanyName=Holidaystemp.CompanyName;
        this.HolidaysModel.BranchName=Holidaystemp.BranchName;
        this.HolidaysMaster.push(Holidaystemp);
      });
    });
  }
}