import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';  
import { Http, Headers, RequestOptions, Response } from '@angular/http';  
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import 'rxjs/add/operator/toPromise';
import { Subscriber } from 'rxjs';
import { GlobalVariable  } from '../../../app.global';
import { Model_CRM_Roll_Menus } from './Crm_Roll_Menu';

@Injectable()
export class CrmRollMenuService {

  constructor(private http: HttpClient,private spinnerService: Ng4LoadingSpinnerService) { }
  Shared_Roll_Menu: Model_CRM_Roll_Menus ;     
    SaveOrUpdate : number =0;


    AddCrmRollMenu(token, Add_Crm_MenurRoll: Model_CRM_Roll_Menus[]) {
     
      const authHeader = new HttpHeaders();
      authHeader.append('Content-Type', 'application/json');
      authHeader.append('Authorization', token);
      authHeader.append('Accept', 'application/json');
      return this.http.put(GlobalVariable.BASE_API_URL + '/api/Crm_Roll_Menu/Add', Add_Crm_MenurRoll, { headers: authHeader })
          .map(this.extractData)
          .catch(this.handleErrorObservable);
  }


  AddCrmUserRollMainMenu(token, Add_Crm_MenurRoll: Model_CRM_Roll_Menus[]) {
   
    const authHeader = new HttpHeaders();
    authHeader.append('Content-Type', 'application/json');
    authHeader.append('Authorization', token);
    authHeader.append('Accept', 'application/json');
    return this.http.put(GlobalVariable.BASE_API_URL + '/api/Crm_Roll_Menu/AddMainMenu', Add_Crm_MenurRoll, { headers: authHeader })
        .map(this.extractData)
        .catch(this.handleErrorObservable);
}



  GetCRMChildMenuRoll(token, RollId:number, PerentMenuId : number)
    {
        
        this.spinnerService.show();
        const authHeader = new HttpHeaders();     
        authHeader.append('Content-Type', 'application/json');
        authHeader.append('Authorization', token);
        return new Promise((resolve, reject) =>
        {
            setTimeout(()=>
            {
                this.http.get(GlobalVariable.BASE_API_URL +'/api/Crm_Roll_Menu/GetCrmRollMenu?RollId='+RollId+'&ParemtmenuId=' + PerentMenuId,
                {headers: authHeader}).subscribe(data => 
                {
                    this.spinnerService.hide();
                    resolve(data)
                },
                error => 
                {
                    this.spinnerService.hide();
                    reject(error);
                },);
            },3000);
        });
    }


    GetCRMMainMenuRoll(token, RollId:number, ModuleId : number)
    {
        
        this.spinnerService.show();
        const authHeader = new HttpHeaders();     
        authHeader.append('Content-Type', 'application/json');
        authHeader.append('Authorization', token);
        return new Promise((resolve, reject) =>
        {
            setTimeout(()=>
            {
                this.http.get(GlobalVariable.BASE_API_URL +'/api/Crm_Roll_Menu/GetCrmParentRollMenu?RollId='+RollId+'&ModuleId=' + ModuleId,
                {headers: authHeader}).subscribe(data => 
                {
                    this.spinnerService.hide();
                    resolve(data)
                },
                error => 
                {
                    this.spinnerService.hide();
                    reject(error);
                },);
            },3000);
        });
    }




  private extractData(res: Response) 
    {
        let body = res;
        return body || {};
    }
    private handleErrorObservable (error: Response | any) 
    {
        console.error(error.message || error);
        return Observable.throw(error.message || error);
    }



}
