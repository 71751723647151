import { Model_CRM_Lead_Contacts } from './Model_CRM_Lead_Contacts'

export class Model_CRM_Lead {


    public LeadName: string;
    public Mr: string;
    public MR: string;
    public FirstName: string;
    public MiddleName: string;
    public LastName: string;
    public StateName: string;
    public CountryName: string;
    public CityName: string;
    public ListName: string;
    public EmailId: string;
    public CountryId: number;
    public StateId: number;
    public CityId: number;
    public ListId: number;
    public LeadId: number;
    public PhoneNo: string;
    public Extension: string;
    public IsActive: boolean;
    public AssigneTo: number;
    public DOB: Date;
    public AnniversaryDate: Date;
    public DesignationID: number;
    public SegmentIDs: number[];
    public ContactsList: Model_CRM_Lead_Contacts[];

    public LastActivity: string;

    // 

    public DOBMoreinfo: string;
    public AnniversaryDateMoreinfo: string;

    public EmployeeName: string;


    public Address: string;



    //Begning of Paging

    public TotalPages: number;
    public CurrentPageNumber: number;
    public NextPageNumber: number;
    public searchtext: string;

    //End of  Paging


}       