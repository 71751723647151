<div class="body-content-area tabcontent" id="link1">
  <div class="row">
    <div class="col-lg-9">
      <div class="row">
        <div class="col-lg-1 low-padding text-center">
          <!-- <img *ngIf="this.SelectedMenu.Image!=null" src={{this.SelectedMenu.Image}} class="body-icon" alt="Icon"/> -->
        </div>
        <div class="col-lg-10 clear-padding">
          <span class="page-title">Manage Holidays Master</span>
          <small class="page-description"></small>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="!(this.CanViewState)"> 
    <fieldset class="body-form-control">
      <legend class="legend--">Holidays Master Details</legend>
      <div class="row">
        <div class="col-lg-4">
          <div  class="col-body-border-right">
            <div class="form-group row">
              <label  class="col-sm-4 col-form-label clear-padding-right">Company</label>
              <div class="col-sm-7 clear-left-padding">
                <select [(ngModel)]="HolidaysModel.CompanyId" tabindex="1" (ngModelChange)="onChangeCompany($event)" id ="CompanyId" class="form-control"  name="CompanyId"> 
                  <option [value]="0" [disabled]=true >Select Company</option>
                  <option *ngFor="let c of Companies" [value]="c.CompId">{{c.CompName}}</option>
                </select>
              </div>
              <div class="required-validation" *ngIf="HolidaysModel.CompanyId==0">Company is required</div>
            </div>
          </div>
        </div>
        <div class="col-lg-4">
          <div class="form-group row">
            <label  class="col-sm-4 col-form-label clear-padding-right">Branch</label>
            <div class="col-sm-7 clear-left-padding">
              <select [(ngModel)]="HolidaysModel.BranchId" tabindex="2" (ngModelChange)="onchangeBranch($event)" id ="BranchId" class="form-control" name="BranchId">
                <option [value]="0" [disabled]=true >Select Branch</option>
                <option *ngFor="let b of Branches" [value]="b.BranchId">{{b.BranchName}}</option>
              </select>
            </div>
            <div class="required-validation" *ngIf="HolidaysModel.BranchId==0">Branch is required</div>  
          </div>
        </div>
      </div>
    </fieldset>

    <fieldset class="body-form-control" *ngIf="(HolidaysModel.CompanyId > 0 && HolidaysModel.BranchId > 0)">
        <legend class="legend--">Holiday List Creation</legend>
        <form #HolidayForm="ngForm" (ngSubmit)="SaveHolidaysMaster(template)">
          <table id="tblHoliday" class="table table-striped table-bordered text-left table-new row-border hover">
            <thead class="thead-blue text-center">
              <tr>
                <th style="width: 10%;">Sl.No.</th>
                <th style="width: 10%;">Date</th>
                <th style="width: 10%;">Day</th>
                <th style="width: 40%;">Description</th>
                <th style="width: 10%;">Holiday Day</th>
                <th style="width: 10%;">Action</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let HM of HolidaysMaster, let i = index">
                <td scope="row">
                  {{i+1}}
                </td>
                <td>
                  <input type="date" id ="HolidayDate_{{i}}" name="HolidayDate_{{i}}" tabindex="1" [ngModel]="HM.HolidayDate | date:'yyyy-MM-dd'" (ngModelChange)="dateChanged($event,i)" #HolidayDate="ngModel" required />
                </td>
                <td>
                  {{HM.HolidayDate| date : "EEEE"}}
                </td>
                <td>
                  <input type="text" [(ngModel)]="HM.HolidayDescription" id ="HolidayDescription_{{i}}" name="HolidayDescription_{{i}}" #HolidayDescription="ngModel" class="form-control form-control-custom form-control-custom-new">
                </td>
                <td style=" text-align: center;">1</td>
                <td style=" text-align: center;">
                  <button *ngIf="this.SelectedMenu.CanDelete && HM.RefId > 0" type="button" (click)="DeleteHolidaysModelById(HM.RefId,HM.HolidayDate,template)" class="table-remove-btn"><i class="fas fa-trash-alt"></i></button>
                </td>
              </tr>
            </tbody>
            <tfoot class="thead-blue text-center">
              <tr>
                <th></th>
                <th></th>
                <th></th>
                <th></th>
                <th></th>
                <th></th>
              </tr>
            </tfoot>
          </table>
        
          <div class="row float-right">
            <div class="form-group row">
              &nbsp;
            </div>
            <div class="col-auto text-center">
              <!-- *ngIf="!(this.CanSaveButtonView)" -->
              <button type="submit" tabindex="3" class="btn btn-primary save-button" [disabled]="!( this.DisableSave == true)">Save</button>
            </div>
            <div class="col-auto text-center">
              <button type="button" tabindex="4" class="btn btn-danger cancle-button" (click)="RefreshPage()">Refresh Page</button>
            </div>
            <div class="col-auto text-center">
              <!-- *ngIf="!(this.CanSaveButtonView)" -->
              <button type="button" tabindex="5" class="btn btn-danger cancle-button" (click)="AddnewRow()">Add Row </button>
            </div>
          </div>
          
        </form>
    </fieldset>

  </div>

  <div style=" box-align: center; width: 1500px;">
      <ng-template #template>
        <div class="modal-header" style="background: #2a9bd7; max-width: 1500px;"> 
          <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()" style="color:#fff;">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div  style="text-align: center; max-width: 1500px;">
        <div *ngIf="SaveMsg" style="color: #dc3545; text-align: center;font-size: 15px; font-weight: bold;">Holidays Modified Successfully</div>
        <div *ngIf="DeleteErrorMsg" style="color: #dc3545; text-align: center;font-size: 15px; font-weight: bold;">Holidays Date not Valid to Delete</div>
        <div *ngIf="DeleteMsg" style="color: #dc3545; text-align: center;font-size: 15px; font-weight: bold;">Holidays Deleted Successfully</div>
        <button type="button" aria-label="Close" (click)="modalRef.hide()" style="background: #2a9bd7; outline: 0px none; border: 0px none; color: #fff; padding: 5px 10px; border-radius: 3px;">
        <span>Close</span>
        </button>             
      </div>
    </ng-template>
  </div>

</div>