import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from '../../shared/user.service';
import { KRAService }  from '../../shared/HR_ADMIN/KRA/KRAService.service';
import { KRAMasterModel } from '../../shared/HR_ADMIN/KRA/KRAMasterModel.Model';
import { KRAApplicationModel } from '../../shared/HR_ADMIN/KRA/KRAApplicationModel.Model';
import { EmployeeDetail } from '../../shared/HR_ADMIN/Employee/EmployeeDetail.Model';
import { ModalOptions,BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Menu } from '../../shared/menu.model';

@Component({
  selector: 'app-krareports-for-all',
  templateUrl: './krareports-for-all.component.html',
  styleUrls: ['./krareports-for-all.component.css']
})
export class KrareportsForAllComponent implements OnInit 
{
  KRAMasterModel : KRAMasterModel= new KRAMasterModel();
  KRAApplicationModel : KRAApplicationModel= new KRAApplicationModel();
  EmployeeDetails: KRAApplicationModel[] = [];
  Employees: EmployeeDetail[] = [];
  employee: any;
  KRAApplications: KRAApplicationModel[] = [];
  kraapplication: any;
  KRAApplicationSession: KRAApplicationModel[] = [];
  
  RefId : any;
  MasterRefId : any;
  userClaims: any;

  Result : string;
  SuccessStatus:boolean=false;
  SuccessMessage: string ="";
  public tableWidget: any;
  selectedIndex : number;
  // Begning Permission Portion    
  SelectedMenu:Menu;
  IsDataIsInViewState : boolean=false;
  CanViewState : boolean=true;
  IsApplicationView : boolean=false;
  DisableSave :boolean=false;
  Totalwts : number=0;
  FormViewState : boolean=true;
  // End of the permission portion
  EmployeeSettings = {};
  selectedItems = [];
  emp : any [] =[];
  //For Pop up
  ShowPopup : boolean= false;
  public modalRef: BsModalRef;
  public modelopt:ModalOptions;

  constructor(private KRAService: KRAService, private rout: Router, private userService: UserService, private modalService: BsModalService)
  {
    this.modelopt=new ModalOptions();
    this.modelopt.class='heavy_modal';
    this.modalService.config= this.modelopt;
    this.modelopt.ignoreBackdropClick=true;
  }

  ngOnInit() 
  {
    // Begning Permission Portion 
    this.SelectedMenu=this.userService.SelectedMenu;
    if(this.SelectedMenu !=undefined)
    {
      localStorage.setItem('selectedmenu',JSON.stringify(this.SelectedMenu));
      this.rout.navigate([this.SelectedMenu.RouteName]);
    }
    else
    {
      if(JSON.parse(localStorage.getItem('selectedmenu'))!=undefined)
      {
        this.SelectedMenu = JSON.parse(localStorage.getItem('selectedmenu'));
        this.rout.navigate([this.SelectedMenu.RouteName]);
      }
    }
    if(this.SelectedMenu.CanAdd)
    {
      this.IsDataIsInViewState=false;
    }
    else
    {
      this.IsDataIsInViewState=true;
    }
    if(this.SelectedMenu.CanView)
    {
      this.CanViewState=false;
    }
    else
    {
      this.CanViewState=true;
    }
    // End of the permission portion
    this.Totalwts=0;
    this.GetEmployeeDetailsDDL();
    this.KRAApplicationModel.EmployeeId=0;
    this.FormViewState=false;
    this.EmployeeSettings =
    {
      singleSelection:true,
      text:'Select Employee',
      enableSearchFilter:true,
      showCheckbox:false
    }
  }

  GetEmployeeDetailsDDL() 
  {
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.KRAService.GetEmployeeDetailsForDDL( t).then(x => 
    {
      this.employee = x;
      Object.keys(this.employee).forEach( key => 
      {
        let Emptemp = new EmployeeDetail();
        Emptemp = this.employee[key];
        this.Employees.push(Emptemp);
        this.emp.push({"id":Emptemp.EmployeeId,"itemName":Emptemp.EmployeeCode + '-' +Emptemp.EmployeeName},);
      });     
    });      
  }

  GetEmployeeDetails(EmployeeId)
  {
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.KRAService.GetEmployeeDetailsBYEmployeeId( t,EmployeeId).then(x => 
    {
      this.employee = x;
      Object.keys(this.employee).forEach( key => 
      {
        this.employee[key];
        let KRAApplicationtemp = new KRAApplicationModel();
        KRAApplicationtemp = this.employee[key];
        this.KRAApplicationModel.EmployeeId=KRAApplicationtemp.EmployeeId;
        this.KRAApplicationModel.EmployeeCode=KRAApplicationtemp.EmployeeCode;
        this.KRAApplicationModel.EmployeeName=KRAApplicationtemp.EmployeeName;
        this.KRAApplicationModel.EmployeeEmailId=KRAApplicationtemp.EmployeeEmailId;
        this.KRAApplicationModel.DesignationName=KRAApplicationtemp.DesignationName;
        this.KRAApplicationModel.RHId=KRAApplicationtemp.RHId;
        this.KRAApplicationModel.RHCode=KRAApplicationtemp.RHCode;
        this.KRAApplicationModel.RHName=KRAApplicationtemp.RHName;
        this.KRAApplicationModel.RHEmailId=KRAApplicationtemp.RHEmailId;
        this.KRAApplicationModel.FHId=KRAApplicationtemp.FHId;
        this.KRAApplicationModel.FHCode=KRAApplicationtemp.FHCode;
        this.KRAApplicationModel.FHName=KRAApplicationtemp.FHName;
        this.KRAApplicationModel.FHEmailId=KRAApplicationtemp.FHEmailId;
        this.EmployeeDetails.push(KRAApplicationtemp);
      });
    });
  }
  
  GetKRAReportSession(EmployeeId) 
  { 
    this.KRAApplicationSession=[];
    let t = localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.KRAService.GetKRAReportsSessionDisplayByEmployeeId( t,EmployeeId).then(x => 
    {
      this.RefId = x;
      if(this.RefId.length > 0)
      {
        this.KRAApplicationSession=[];
      }
      Object.keys(this.RefId).forEach( key => 
      {
        // tslint:disable-next-line:no-unused-expression
        this.RefId[key];
        let KRAApplicationtemp = new KRAApplicationModel();
        KRAApplicationtemp = this.RefId[key];
        this.KRAApplicationSession.push(KRAApplicationtemp);
      });
    });
  }
  
  GetKRAReports(MasterRefId,template) 
  { 
    this.KRAApplications=[];
    // Begning of the permission portion
    this.IsDataIsInViewState=false;
    // End of the permision portion
    this.KRAApplicationModel.IsActive=false;
    let t = localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.KRAService.GetKRAReportsByEmployeeId( t,this.KRAApplicationModel.EmployeeId,MasterRefId).then(x => 
    {
      if(this.ShowPopup==true)
      {
        this.modalRef.hide();
      }
      this.KRAApplicationModel.TotalWateges=0;
      this.Totalwts=0;
      this.modalRef = this.modalService.show(template,this.modelopt);
      this.ShowPopup=true;
      this.RefId = x;
      if(this.RefId.length > 0)
      {
        this.KRAApplications=[];
      }
      Object.keys(this.RefId).forEach( key => 
      {
        // tslint:disable-next-line:no-unused-expression
        this.RefId[key];
        let KRAApplicationtemp = new KRAApplicationModel();
        KRAApplicationtemp = this.RefId[key];

        this.Totalwts=this.Totalwts + KRAApplicationtemp.Weighted;
        this.KRAApplicationModel.FinalSelfRating=KRAApplicationtemp.FinalSelfRating;
        this.KRAApplicationModel.FinalRHRating=KRAApplicationtemp.FinalRHRating;
        this.KRAApplicationModel.FinalFHRating=KRAApplicationtemp.FinalFHRating;
        if(KRAApplicationtemp.SelfRating==0)
        {
          KRAApplicationtemp.SelfRating=null;
        }
        this.KRAApplications.push(KRAApplicationtemp);
      });
      this.KRAApplicationModel.TotalWateges=this.Totalwts;
    });
  }

  RefreshPage()
  {
    this.rout.navigateByUrl('/Appraisal/KRAApplication', {skipLocationChange: true}).then(()=>this.rout.navigate(["/Appraisal/KRAReportsForAll"]));
  }

  onItemSelect(item:any)
  {
    let empid= item['id'];
    this.KRAApplicationModel.EmployeeId=empid; 
    if(this.KRAApplicationModel.EmployeeId>0)
    {
      this.FormViewState=true;
      this.GetEmployeeDetails(this.KRAApplicationModel.EmployeeId);
      this.GetKRAReportSession(this.KRAApplicationModel.EmployeeId);
    }     
  }
  OnItemDeSelect(item:any){ }
  onSelectAll(items: any){ }
  onDeSelectAll(items: any){ }
}