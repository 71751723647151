export class EmployeeCompoffModel
{
    public RefId : number;
    public EmployeeId : number;
    public EmployeeCode : string;
    public EmployeeName : string;
    public EmployeeEmailId : string;
    public AttendanceDate : Date;
    public AttendanceDay : string; 
    public ActualInTime : Date;
    public ActualOutTime : Date;
    public ActualWorkingDuration : number;
    public Status : string;
    public HalfDay : boolean;
    public FullDay : boolean;
    public Reason: string;
    public Remarks: string;
    public ReportingHeadName : string;
    public Fromvalue : string;
    public RHId : number;
    public RHName : string;
    public RHCode : string;
    public RHEmailId : string;
    public IsApproved : boolean;
    public IsRejected : boolean;
    public CompanyId : number;
    public IsOffDay : boolean;
    public Selected : boolean;
    public RemarksAll: string;
    public SentMailid : string;
    public SentMailPassword : string;
    public HRISURL : string;
    public ApplicationStatus : number;
    public SearchFromDate : Date;
    public SearchToDate : Date;
}