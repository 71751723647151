export class Model_CRM_Role
    {
        
  
        public RoleName : string;
        public CRMType : number;
        public Role_ID  :   number;
        public IsActive : boolean;  
         public CRMTypeName : string;
        
    }       