export class WorkingGroupHours 
{
    public WorkingGroupId : number;
    public WorkingGroupName : string;
    public Sunday : number;
    public Monday : number;
    public Tuesday : number;
    public Wednesday : number;
    public Thursday : number;
    public Friday : number;
    public Saturday : number;
    public IsAlternate : boolean;
    public AlternateDay : number;
    public Week1 : boolean;
    public Week2 : boolean;
    public Week3 : boolean;
    public Week4 : boolean;
    public Week5 : boolean;
    public IsActive : boolean;
    public AdjustPenaltyWithLeave : boolean;
}