<!-- <app-dialog [(visible)]="this.SuccessStatus">
  <span class="successmsg">{{this.SuccessMessage}}</span>
</app-dialog> -->






  <div class="body-content-area tabcontent" id="link1">
    <div class="row">
      <div class="col-lg-9">
        <div class="row">
          <div class="col-lg-1 low-padding text-center">
            <img src="assets/img/icon.png" class="body-icon" alt="Icon" />
          </div>
          <div class="col-lg-10 clear-padding">
            <span class="page-title"> View Schedule Calls</span>
            <small class="page-description">It is a long established fact that a reader will be distracted</small>
          </div>

        </div>
      </div>



    </div>


    <fieldset class="body-form-control">

      <legend class="legend--"> view Schedule Schedule Calls</legend>

      <div>

      </div>
      <div class="row">

        <div class="col-lg-4">
          <div class="form-group row">

            <label class="col-sm-4 col-form-label clear-padding-right">Company Name
              <span class="required-validation">*</span>
            </label>
            <div class="col-sm-8 clear-left-padding select_dropdown">

              <angular2-multiselect tabindex="5" class="form-control" [data]="CompanyArray"
                [(ngModel)]="SelectedCompany" name="CompanyId" [settings]="this.CompanySettings"
                (onSelect)="onItemSelectCompany($event)" (onDeSelect)="OnItemDeSelectCompany($event)"
                (onSelectAll)="onSelectAllCompany($event)" (onDeSelectAll)="onDeSelectAllCompany($event)">
              </angular2-multiselect>

            </div>


          </div>

          <div class="form-group row">

            <label class="col-sm-4 col-form-label clear-padding-right">From Date

            </label>
            <div class="col-sm-7 clear-left-padding">
              <input type="text" [(value)]="this.FromDateVal" readonly="readonly" [minimumDate]="false"
                [onlyDatePicker]="true" id="CreatedDate_id" myDatepicker (dateChange)="CreateDate($event)"
                class="form-control form-control-custom form-control-custom-new ng-pristine ng-valid ng-touched" />
            </div>

          </div>
        </div>
        <div class="col-lg-4">
          <div class="col-body-border-right">

            <div class="form-group row">
              <label class="col-sm-4 col-form-label clear-padding-right">Opportunity Name</label>
              <div class="col-sm-7 clear-left-padding">

                <angular2-multiselect tabindex="5" class="form-control" [data]="OpportunityArray"
                  [(ngModel)]="Selectedopportunities" name="OpportunityId" [settings]="this.OpportunitySettings"
                  (onSelect)="onItemSelectOpportunity($event)" (onDeSelect)="OnItemDeSelectOpportunity($event)"
                  (onSelectAll)="onSelectAllOpportunity($event)" (onDeSelectAll)="onDeSelectAllOpportunity($event)">
                </angular2-multiselect>

              </div>
            </div>


            <div class="form-group row">
              <label class="col-sm-4 col-form-label clear-padding-right">To Date</label>
              <div class="col-sm-7 clear-left-padding">

                <input type="text" [(value)]="this.ToDateval" readonly="readonly" [minimumDate]="true"
                  [onlyDatePicker]="true" id="ClosedDate_id" myChangableDatepicker (dateChange)="Closedate($event)"
                  [(mindate)]="this.FromDateVal"
                  class="form-control form-control-custom form-control-custom-new ng-pristine ng-valid ng-touched" />

              </div>
            </div>
          </div>
        </div>

        <div class="col-lg-4">
          <div class="col-body-border-right">

            <div class="form-group row">

              <div class="col-sm-8 col-form-label text-center">

                <button type="button" class="btn btn-primary save-button" *ngIf="this.ViewButtonVisibility"
                  (click)="ViewScheduleCall()">View
                  Schedule Calls</button>

              </div>


            </div>
          </div>
        </div>
      </div>
    </fieldset>

    <div class="row">

      <!-- <div style="text-align: center; max-width: 900px;padding-left: 10%"> -->
      <div style="text-align: center; width: 100%">

        <fieldset class="body-form-control">

          <legend class="legend--">Shedule Calls</legend>
          <form #ApprovedApplicationForm="ngForm">

            <div lass="row">
              <!-- <table id="tblDocument" class="table table-striped table-bordered text-left table-new row-border hover"> -->
              <table id="tblDocument" style="text-align: center; width:  100%" datatable [dtOptions]="dtOptions"
                [dtTrigger]="dtTrigger">
                <thead class="thead-blue text-center">
                  <tr>
                    <th style="width: 10%;">Company</th>
                    <th style="width: 10%;">OpportunityTitle</th>
                    <th style="width: 10%;">CallTypeDescription</th>
                    <th style="width: 10%;">Subject</th>
                    <th style="width: 10%;">CallDateVal</th>
                    <th style="width: 10%;">ContactsName</th>
                    <th style="width: 10%;">DispositionStatusDesc</th>
                    <th style="width: 10%;">Action</th>

                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let Schedule of  this.ModelScheduleCall, let i = index">
                    <td>

                      {{Schedule.Company}}

                    </td>
                    <td>
                      {{Schedule.OpportunityTitle}}
                    </td>
                    <td>
                      {{Schedule.CallTypeDescription}}
                    </td>
                    <td>
                      {{Schedule.Subject}}
                    </td>

                    <td>
                      {{Schedule.CallDateVal}}
                    </td>
                    <td>
                      {{Schedule.ContactsName}}
                    </td>
                    <td>
                      {{Schedule.DispositionStatusDesc}}
                    </td>


                    <td>

                      <button *ngIf="Schedule.Document.DocumentName !=''" type="button"
                        (click)="DocDownLoad(Schedule.Document.DocumentName, Schedule.Document.DocumentType)"
                        class="table-remove-btn">
                        <!-- <i class="download"></i> -->
                        <i class="fa fa-download"></i>
                      </button>


                      <button type="button" (click)="ViewCRMScheduleCall(Schedule.SheduleCall_Id,template)"
                        class="table-view-button" type="button">
                        <i class="fas fa-eye"></i>
                      </button>
                    </td>



                  </tr>
                </tbody>
              </table>
              <!-- <pagination-controls (pageChange)="p = $event" style="float:right"></pagination-controls> -->


            </div>

          </form>
        </fieldset>
      </div>


    </div>





  </div>



<div style=" box-align: center; width: 1000px;">


  <ng-template #template>

    


    <div class="no-border-fieldset">

      <fieldset class="body-form-control">
        <div class="container">
        <div class="modal-header">
          <h3 class="text-white mb-0"><legend> Schedule Call View</legend></h3>
          <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()" style="color:#fff;">
            <span aria-hidden="true">X</span>
          </button>
        </div>

        <form #ApprovedApplicationForm="ngForm">
          <div class="row no-gutters row-cols-1 row-cols-lg-3">
            <div class="col-lg-4 col-xl-4">
              <div class="card text-white bg-1 pt-4">
                <div class="form-group">
                  <div class="row">
                    <div class="col-sm-5">
                      <label class="col-form-label">Company Name :</label>
                    </div>
                    <div class="col-sm-7">
                      <label class="col-form-label"> {{this.View_ScheduleCall.Company}}</label>
                    </div>
                  </div>
                </div>
                <div class="form-group">
                  <div class="row">
                    <div class="col-sm-5">
                      <label class="col-form-label">Invited Attendance</label>
                    </div>
                    <div class="col-sm-7">
                    <table>
                      <tr *ngFor="let attendees of  this.View_ScheduleCall.Invited_Attendees, let i = index">
                        <td>
                          {{attendees}}

                        </td>
                      </tr>
                    </table>
                    </div>
                  </div>
                </div>

                <div class="form-group">
                  <div class="row">
                    <div class="col-sm-5">
                      <label class="col-form-label">Select Status</label>
                    </div>
                    <div class="col-sm-7">
                      <label class="col-form-label">{{this.View_ScheduleCall.DispositionStatusDesc}}</label>
                    </div>
                  </div>
                </div>

                <div class="form-group">
                  <div class="row">
                    <div class="col-sm-5">
                      <label class="col-form-label">Select Contacts</label>
                    </div>
                    <div class="col-sm-7">
                    <table>
                      <tr *ngFor="let Contact of  this.View_ScheduleCall.ContactsName, let i = index">
                        <td>
                          {{Contact}}
                        </td>
                      </tr>
                    </table>
                    </div>
                  </div>
                </div>

                <div class="form-group">
                  <div class="row">
                    <div class="col-sm-5">
                      <label class="col-form-label">Call Type</label>
                    </div>
                  <div class="col-sm-7">
                    <label class="col-form-label">
                      {{this.View_ScheduleCall.CallTypeDescription}}</label>
                  </div>
                  </div>
                </div>


                <div class="form-group">
                  <div class="row">
                    <div class="col-sm-5">
                      <label class="col-form-label">Is Call Complete</label>
                    </div>
                    <div class="col-sm-7">
                    <div class="row">
                      <div class="col-sm-6">
                        <label class="col-form-label"
                          *ngIf="this.View_ScheduleCall.IsComplete">Yes
                        </label>
                      </div>
                      <div class="col-sm-6">
                        <label class="col-form-label"
                          *ngIf="!this.View_ScheduleCall.IsComplete">No
                        </label>
                      </div>
                    </div>
                  </div>
                  </div>
                </div>
              </div>
            </div>




            <div class="col-lg-4 col-xl-4">
                <div class="card text-white bg-2 pt-4">
                <div class="form-group">
                  <div class="row">
                    <div class="col-sm-5">
                      <label class="col-form-label">Opportunity</label>
                    </div>
                    <div class="col-sm-7">
                      {{this.View_ScheduleCall.OpportunityTitle}}
                    </div>
                  </div>
                </div>

                <div class="form-group">
                  <div class="row">
                    <div class="col-sm-5">
                      <label class="col-form-label">Start Time</label>
                    </div>
                  <div class="col-sm-7">
                    <label class="col-form-label">{{this.View_ScheduleCall.Starttimeval}}</label>
                  </div>
                  </div>
                </div>

                <div class="form-group">
                  <div class="row">
                    <div class="col-sm-5">
                      <label class="col-form-label">Subject</label>
                    </div>
                    <div class="col-sm-7">
                      <label class="col-form-label">{{this.View_ScheduleCall.Subject}}</label>
                    </div>
                  </div>
                </div>
                <div class="form-group">
                  <div class="row">
                    <div class="col-sm-5">
                      <label class="col-form-label">Note</label>
                    </div>
                    <div class="col-sm-7">
                      {{this.View_ScheduleCall.Notes}}
                    </div>
                  </div>
                </div>
             </div>
            </div>



            <div class="col-lg-4 col-xl-4">
                <div class="card text-white bg-3 pt-4">
                <div class="form-group">
                  <div class="row">
                    <div class="col-sm-5">
                      <label class="col-form-label">Date</label>
                    </div>
                  <div class="col-sm-7">
                    {{this.View_ScheduleCall.CallDateVal}}
                  </div>
                  </div>
                </div>

                <div class="form-group">
                  <div class="row">
                    <div class="col-sm-5">
                      <label class="col-form-label">End Time</label>
                    </div>
                  <div class="col-sm-7">
                    <div class="row">
                      <table>
                        <tr>
                          <td>
                            <label
                              class="col-form-label">{{this.View_ScheduleCall.endtimeval}}</label>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <label class="col-form-label"
                              *ngIf="this.View_ScheduleCall.IsAllDay">All Day Event</label>

                          </td>
                        </tr>
                        <tr>
                          <td>
                            <label class="col-form-label"  *ngIf="this.View_ScheduleCall.IsPriorityCall">Priority Call</label>
                          </td>
                        </tr>
                      </table>
                     </div>
                    </div>
                  </div>
                </div>
           
            </div>
           </div>
          </div>
        
      
        </form>
       </div>
      </fieldset>
    </div>

    <!-- <button type="button" aria-label="Close" (click)="modalRef.hide()" style="background: #2a9bd7; outline: 0px none; border: 0px none; color: #fff; padding: 5px 10px; border-radius: 3px;">
        <span >OK</span>
      </button>             
     -->

  </ng-template>
</div>
