export class Menu 
{
    MenuId:number;
    RollName:string;
    MenuTitle:string;
    RouteName:string;
    ModuleId :number;
    IconUrl : string;
    Image:string;
    LogoType:string;
    SmallIconUrl : string;
    UserId :number;
    CanAdd : boolean;
    CanEdit : boolean;
    CanDelete : boolean;
    CanApprove :boolean;
    IsDefaultPage:boolean; 
    CanView :boolean;
    MobileView :boolean;

    TotalNotification :number;
    SelectedMenu : number;
    SelectedSubMenu : number;
}