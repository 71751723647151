<div class="body-content-area tabcontent" id="link1">
  <div class="row">
      <div class="col-lg-9">
          <div class="row">
              <div class="col-lg-1 low-padding text-center">
                  <!-- <img *ngIf="this.SelectedMenu.Image!=null" src={{this.SelectedMenu.Image}} class="body-icon" alt="Icon"/> -->
              </div>
              <div class="col-lg-10 clear-padding">
                  <span class="page-title">Manage City</span>
                  <small class="page-description"></small>
                  <div class="col-lg-10 clear-padding" *ngIf="!(this.CanViewState)">
                    <button *ngIf="(this.CanSaveButtonView)" (click)="ADDClick()" type="button" class="btn btn-primary Add-button">ADD New</button> 
                  </div>
              </div>
          </div>
      </div>
  </div>
<div *ngIf="!(this.CanViewState)">
    <app-dialog [(visible)]="this.SuccessStatus">
        <span class="successmsg">{{this.SuccessMessage}}</span>
    </app-dialog>
    <fieldset *ngIf="!(this.FormViewShow)" class="body-form-control">
        <legend class="legend--">Country Details</legend>
        <table id="tblCity" *ngIf="!(this.FormViewShow)" datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger"
         class="table table-striped table-bordered text-center table-new row-border hover">
            <thead class="thead-blue text-center">
                <tr>
                    <th>Sl No.</th>
                    <th>City Code</th>
                    <th>City </th>
                    <th>District </th>
                    <th>State </th>
                    <th>Country </th>
                    <th>Action</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let City of Cities, let i = index">
                    <td scope="row">{{i+1}}</td>
                    <td>{{City.CityCode}}</td>
                    <td class="text-left">{{City.CityName}}</td>
                    <td class="text-left">{{City.DistName}}</td>
                    <td class="text-left">{{City.StateName}}</td>
                    <td class="text-left">{{City.CountryName}}</td>
                    <td>
                        <button *ngIf="this.SelectedMenu.CanEdit" type="button"  (click)="GetCityByid( City.CityId )"class="table-edit-btn"><i class="fas fa-edit"></i></button> 
                        <button *ngIf="this.SelectedMenu.CanDelete" type="button"  (click)="DeleteCityByid( City.CityId )"class="table-remove-btn"><i class="fas fa-trash-alt"></i> </button>
                        <button *ngIf="this.SelectedMenu.CanView"   type="button" (click)="ViewCityByid( City.CityId )" class="table-view-button" type="button" ><i class="fas fa-eye"></i></button>
                    </td>
                </tr>
            </tbody>
        </table>
    </fieldset>
    <fieldset *ngIf="(this.FormViewShow)" class="body-form-control">
      <legend class="legend--">City Creation</legend>
      <form #CityForm="ngForm" (ngSubmit)="saveCity()">
          <div class="row">
              <div class="col-lg-4">
                  <div class="col-body-border-right">
                      <div class="form-group row">
                          <label class="col-sm-4 col-form-label clear-padding-right">City Code</label>
                          <div class="col-sm-7 clear-left-padding">
                              <input type="text" [(ngModel)]="CityModel.CityCode" tabindex="1" maxlength="40" id ="CityCode"  name="CityCode" #CityCode="ngModel" required class="form-control form-control-custom form-control-custom-new" >
                          </div>
                          <div *ngIf="CityCode.invalid && (CityCode.dirty || CityCode.touched)" class="alert alert-danger">
                              <div *ngIf="CityCode.errors.required">City Code is required.</div>
                              <div *ngIf="CityCode.errors.maxlength">City Code is not more than 40 characters</div>
                          </div>
                      </div>
                      <div class="form-group row">
                        <label  class="col-sm-4 col-form-label clear-padding-right">State</label>
                        <div class="col-sm-7 clear-left-padding">
                            <select [(ngModel)]="CityModel.StateId" tabindex="4" id ="StateId" (ngModelChange)="onStateChange($event)" class="form-control" name="StateId" #StateId="ngModel" >
                                <option [value]="0" [disabled]=true >Select State</option>
                                <option *ngFor="let s of States" [value]="s.StateID">{{s.StateName}}</option>
                            </select>
                        </div>
                        <div class="required-validation" *ngIf="CityModel.StateId==0">State is required</div>
                      </div>
                  </div>
              </div>
              
              <div class="col-lg-4">
                  <div class="col-body-border-right">
                      <div class="form-group row">
                          <label  class="col-sm-4 col-form-label clear-padding-right">City Name</label>
                          <div class="col-sm-7 clear-left-padding">
                              <input type="text" [(ngModel)]="CityModel.CityName" tabindex="2" id ="CityName" name="CityName" #CityName="ngModel" (blur)="CheckName()" required maxlength="250" class="form-control form-control-custom form-control-custom-new" >
                          </div>
                          <div *ngIf="this.Namecheck.length > 0" class="alert alert-danger">City Name is exist</div>
                          <div *ngIf="CityName.invalid && (CityName.dirty || CityName.touched)" class="alert alert-danger">
                              <div *ngIf="CityName.errors.required">City Name  is required.</div>
                              <div *ngIf="CityName.errors.maxlength">City Name is not more than 250 characters</div>
                          </div>
                      </div>
                      <div class="form-group row">
                        <label  class="col-sm-4 col-form-label clear-padding-right">District</label>
                        <div class="col-sm-7 clear-left-padding">
                            <select [(ngModel)]="CityModel.DistId" tabindex="5" id ="DistId" class="form-control" name="DistId" #DistId="ngModel" (blur)="CheckName()" required>
                                <option [value]="0" [disabled]=true >Select District</option>
                                <option *ngFor="let ds of Districts" [value]="ds.DistId">{{ds.DistName}}</option>
                            </select>
                        </div>
                        <div class="required-validation" *ngIf="CityModel.DistId==0">District is required</div>   
                      </div>
                  </div>
              </div>

              <div class="col-lg-4">
                  <div class="form-group row">
                    <label  class="col-sm-4 col-form-label clear-padding-right">Country</label>
                    <div class="col-sm-7 clear-left-padding">
                        <select [(ngModel)]="CityModel.CountryId" tabindex="3" (ngModelChange)="onChange($event)" id ="CountryId" class="form-control"  name="CountryId" #CountryId="ngModel" required>
                            <option [value]="0" [disabled]=true >Select Country</option>
                            <option *ngFor="let c of Countries" [value]="c.CountryId">{{c.CountryName}}</option>
                        </select>
                    </div>
                    <div class="required-validation" *ngIf="CityModel.CountryId==0">Country is required</div>
                  </div>
                  <div class="form-group row">
                      <label class="col-sm-6 col-form-label text-center clear-padding-right" *ngIf="!(this.IsDataIsInViewState)">
                          <button type="submit" tabindex="6" class="btn btn-primary save-button" [disabled]="!(CityForm.valid  && this.CheckNameExiststatus==true && CityModel.CountryId !=0 && CityModel.DistId !=0 && this.DisableSave==true)" >Save</button>  
                      </label>
                      <div class="col-sm-6 col-form-label text-center">
                        <button type="button" tabindex="7" (click)="RefreshPage()" class="btn btn-danger cancle-button">Cancel</button>
                      </div>
                  </div>
              </div>
          </div>
      </form>
  </fieldset>
</div>
</div>