import { Component, OnInit, TemplateRef } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from '../../shared/user.service';
import { OrganizationService } from '../../shared/Organization/organization.service';
import { UserMasterService } from '../../shared/HR_ADMIN/UserMaster/UserMasterService.service';
import { EmployeeMasterService } from '../../shared/HR_ADMIN/Employee/EmployeeMaster.Service';
import { RoleMasterModel } from  '../../shared/HR_ADMIN/UserMaster/RoleMasterModel.Model';
import { UserModel } from  '../../shared/HR_ADMIN/UserMaster/UserModel.Model';
import { EmployeeDetail} from '../../shared/HR_ADMIN/Employee/EmployeeDetail.Model';
import { Company } from '../../shared/Organization/Company.Model';
import { Branch } from '../../shared/Organization/Branch.Model';
// Begning Confirmation
import { ConfirmationService } from '@jaspero/ng2-confirmations';
import { ResolveEmit } from '../../../app/resolve-emit';
import { ConfirmSettings } from '../../../app/confirm-settings';
import { Menu } from '../../shared/menu.model';
// end of Confirmation
import { ModalOptions,BsModalRef, BsModalService } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-user-master',
  templateUrl: './user-master.component.html',
  styleUrls: ['./user-master.component.css']
})

export class UserMasterComponent implements OnInit 
{
  UserModel : UserModel= new UserModel();
  RoleMasterModel : RoleMasterModel= new RoleMasterModel();
  userClaims: any;
  UserMasters: UserModel[] = [];
  Result : string;
  SuccessStatus:boolean=false;
  SuccessMessage: string ="";
  public tableWidget: any;
  UserMaster: any;
  Id : any;
  EmployeeDetails: EmployeeDetail[] = [];
  EmployeeDetail: any;
  base64textString : string;

  Companies : Company[]=[];
  company : any;
  Branches : Branch[]=[];
  Branch : any;
  RoleMaster : RoleMasterModel[]=[];
  tempUserMasterModeladd : UserModel =new UserModel();
  // Begning Permission Portion    
  SelectedMenu:Menu;
  IsDataIsInViewState : boolean=false;
  CanViewState : boolean=false;
  DisableSave :boolean=false;
  //for Company Dropdown list
  CompanyList = [];
  CompanyselectedItems = [];
  CompanydropdownSettings = {};
  CompanySettings = {};
  CompanyMasters :any;
  //for Branch Dropdown list
  BranchList = [];
  BranchselectedItems = [];
  dropdownSettings = {};
  BranchSettings = {};
  BranchMasters :any;
  //for branch Dropdown list
  PasswordMisMatch: boolean =false;
  EmpDDLView: boolean =false;
  public modalRef : BsModalRef;
  public modelopt : ModalOptions;
  ShowPopup : boolean;
  DeleteMsg : boolean;
  UpdateMsg : boolean;
  SaveMsg : boolean;
  NoRecordMsg : boolean;

  dtOptions: DataTables.Settings = {};

  constructor(private UserMasterService: UserMasterService, private EmployeeMasterService:EmployeeMasterService,private OrganizationService: OrganizationService, private rout: Router,  private _confirmation: ConfirmationService, private userService: UserService, private modalService: BsModalService)
  {
    this.modelopt=new ModalOptions();
    this.modelopt.class='heavy_modal';
    this.modalService.config= this.modelopt;
    this.modelopt.ignoreBackdropClick=true;
  }
  
  ngOnInit() 
  {
    //Begin Permission Portion
    this.SelectedMenu=this.userService.SelectedMenu;
    if(this.SelectedMenu !=undefined)
    {
      localStorage.setItem('selectedmenu',JSON.stringify(this.SelectedMenu));
      this.rout.navigate([this.SelectedMenu.RouteName]);
    }
    else
    {
      if(JSON.parse(localStorage.getItem('selectedmenu'))!=undefined)
      {
        this.SelectedMenu = JSON.parse(localStorage.getItem('selectedmenu'));
        this.rout.navigate([this.SelectedMenu.RouteName]);
      }
    }
    if(this.SelectedMenu.CanAdd)
    {
      this.IsDataIsInViewState=false;
    }
    else
    {
      this.IsDataIsInViewState=true;
    }
    if(this.SelectedMenu.CanView)
    {
      this.CanViewState=false;
    }
    else
    {
      this.CanViewState=true;
    }
    //End of the peremission portion
    this.DisableSave=true;
    //this.CheckExiststatus=true;
    this.CompanyselectedItems =  [];
    this.CompanyList = [];
    this.CompanydropdownSettings = 
    { 
      text:"Select Company",
      selectAllText:'Select All',
      unSelectAllText:'UnSelect All',           
      classes:"myclass custom-class",
      enableSearchFilter: true,
    };  

    this.GetEmployeeDetails();
    //this.GetBranchMasters();
    this.getCompanies();
    this.getRoles();
    this.UserModel.EmployeeId=0;
    this.UserModel.RoleId=0;
    this.UserModel.CompId=0;
    
    this.DeleteMsg = false;
    this.UpdateMsg =false;
    this.SaveMsg = false;
    this.ShowPopup = false;
    this.GetUserMaster();
  }

  settings: ConfirmSettings | any = 
  {
    overlay: true,
    overlayClickToClose: true,
    showCloseButton: true,
    confirmText: 'Yes',
    declineText: 'No'
  };
  
  ADDClick(template)
  {
    if(this.ShowPopup==true)
    {
      this.modalRef.hide();
    }
    this.modalRef = this.modalService.show(template,this.modelopt);
    this.ShowPopup = true;
    this.SaveMsg = false;
    this.DeleteMsg = false;
    this.UpdateMsg = false;
    this.DisableSave = true;
    this.EmpDDLView = false;
    this.CompanyselectedItems = [];
    this.BranchList = [];
    this.BranchselectedItems = [];
    this.UserModel.EmployeeId =0;
    this.UserModel.RoleId =0;
    this.UserModel.CompId =0;
  }

  GetEmployeeDetails()
  {
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.UserMasterService.GetEmployeeDetailsForDDLUserCreation( t).then(x => 
    {
      this.EmployeeDetails=[];
      this.EmployeeDetail = x;
      Object.keys(this.EmployeeDetail).forEach( key => 
      {
        this.EmployeeDetail[key];
        let EmployeeDetailtemp = new EmployeeDetail();
        EmployeeDetailtemp = this.EmployeeDetail[key];
        this.EmployeeDetails.push(EmployeeDetailtemp);
      });
    });
  }

  GetUserMaster()
  {
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.UserMasterService.GetUserMaster( t).then(x => 
    {
      this.UserMasters=[];
      this.UserMaster = x;
      Object.keys(this.UserMaster).forEach( key => 
      {
        this.UserMaster[key];
        let UserMastertemp = new UserModel();
        UserMastertemp = this.UserMaster[key];
        this.UserMasters.push(UserMastertemp);
      });
      if(this.UserMasters.length == 0)
      {
        this.NoRecordMsg = true;
      }
    });
  }
  
  GetUserMasterById(Id,template) 
  { 
    // Begning of the permission portion
    this.IsDataIsInViewState=false;
    this.EmpDDLView=true;
    // End of the permision portion
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.UserMasterService.GetUserMasterById( t,Id).then(x => 
    {
      if(this.ShowPopup==true)
      {
        this.modalRef.hide();
      }
      this.modalRef = this.modalService.show(template,this.modelopt);
      this.ShowPopup=true;
      this.SaveMsg=false;
      this.DeleteMsg=false;
      this.UpdateMsg=false;
      this.DisableSave=true;
      this.Id = x;
      Object.keys(this.Id).forEach( key => 
      {
        // tslint:disable-next-line:no-unused-expression
        this.Id[key];
        let UserMastertemp = new UserModel();
        UserMastertemp = this.Id[key];
        this.UserModel.Id=UserMastertemp.Id;
        this.UserModel.EmployeeId=UserMastertemp.EmployeeId;
        this.UserModel.UserId=UserMastertemp.UserId;
        this.UserModel.Password=UserMastertemp.Password;
        this.UserModel.RePassword=UserMastertemp.Password;
        this.UserModel.RoleId=UserMastertemp.RoleId;
        this.UserModel.CompanyIds=UserMastertemp.CompanyIds;
        this.UserModel.BranchIds=UserMastertemp.BranchIds;
        this.UserModel.EmployeeCode=UserMastertemp.EmployeeCode;
        this.UserModel.EmployeeName=UserMastertemp.EmployeeName;
      });
      this.getCompanies();
      this.GetBranches(this.UserModel.CompanyIds);
    });
  }

  ViewUserMasterById(Id,template) 
  {
    //begin permission
    this.IsDataIsInViewState=true;
    this.EmpDDLView=true;
    //end permission
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.UserMasterService.GetUserMasterById( t,Id).then(x => 
    {
      if(this.ShowPopup==true)
      {
        this.modalRef.hide();
      }
      this.modalRef = this.modalService.show(template,this.modelopt);
      this.ShowPopup=true;
      this.SaveMsg=false;
      this.DeleteMsg=false;
      this.UpdateMsg=false;
      this.Id = x;
      Object.keys(this.Id).forEach( key => 
      {
        // tslint:disable-next-line:no-unused-expression
        this.Id[key];
        let UserMastertemp = new UserModel();
        UserMastertemp = this.Id[key];
        this.UserModel.Id=UserMastertemp.Id;
        this.UserModel.EmployeeId=UserMastertemp.EmployeeId;
        this.UserModel.UserId=UserMastertemp.UserId;
        this.UserModel.Password=UserMastertemp.Password;
        this.UserModel.RoleId=UserMastertemp.RoleId;
        this.UserModel.CompanyIds=UserMastertemp.CompanyIds;
        this.UserModel.BranchIds=UserMastertemp.BranchIds;
        this.UserModel.EmployeeCode=UserMastertemp.EmployeeCode;
        this.UserModel.EmployeeName=UserMastertemp.EmployeeName;
      });
      this.getCompanies();
      this.GetBranches(this.UserModel.CompanyIds);
    });
  }

  DeleteUserMasterById(Id : number,template: TemplateRef<any>) :void
  {
    if(this.SelectedMenu.CanAdd)
    {
     this.IsDataIsInViewState=false;
    }
    else
    {
      this.IsDataIsInViewState=true;
    }
    this._confirmation.create('Confirm to Delete', 'Press Yes to Delete', this.settings).subscribe((ans: ResolveEmit) => 
    {
      if (ans.resolved == true) 
      {
        let t =  localStorage.getItem('userToken');
        t = 'Bearer' + t;
        this.UserModel.IsActive=true;
        if( Id > 0)
        {
          this.UserMasterService.DeleteUserMaster(t ,Id).subscribe(res=>
          {
            this.UserMasters=[];
            this.Id=res;
            if(this.Id >0)
            {
              if(this.ShowPopup==true)
              {
                this.modalRef.hide();
              }
              this.GetUserMaster();
              this.ShowPopup = false;
              this.SaveMsg = false;
              this.UpdateMsg = false;
              this.DeleteMsg = true;
              this.modalRef = this.modalService.show(template,this.modelopt);
              this.DisableSave = true;
            }   
          });                  
        }
      }
    })
  }
  
  GetBranches(CompanyIds)
  {
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t
    this.BranchselectedItems = [];
    this.BranchList = [];
    this.BranchMasters= [];
    this.OrganizationService.GetBranchByCompanyIds( t,CompanyIds).then(x => 
    {
      this.BranchMasters= [];
      this.BranchList=[];
      this.BranchselectedItems = [];
      this.BranchMasters = x;
      Object.keys(this.BranchMasters).forEach( key => 
      {
        // tslint:disable-next-line:no-unused-expression
        this.BranchMasters[key];
        let Branchtemp= new Branch();
        Branchtemp = this.BranchMasters[key];
        this.BranchList.push({"id":Branchtemp.BranchId,"itemName":Branchtemp.BranchName},);
      });  
      if(this.BranchList.length > 0)  
      {
        if(this.UserModel.BranchIds!="")
        {
          var selected_br = this.UserModel.BranchIds.split(',');
          for (var _i = 0; _i < selected_br.length; _i++) 
          {
            var item = this.BranchList.find(x=>x.id==selected_br[_i])
            this.BranchselectedItems.push(item);
          }
        }
      }
    });      
  }

  onItemSelect(item:any){ }
  OnItemDeSelect(item:any){ }
  onSelectAll(items: any){ }
  onDeSelectAll(items: any){ }
  //02 august 2018
  getCompanies() 
  {
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.CompanyselectedItems = [];
    this.CompanyList = [];
    this.CompanyMasters= [];
    this.OrganizationService.GetCompanyForDDL( t).then(x => 
    {

      this.CompanyMasters = x;
      Object.keys(this.CompanyMasters).forEach( key => 
      {
        // tslint:disable-next-line:no-unused-expression
        this.CompanyMasters[key];
        let Companytemp= new Company();
        Companytemp = this.CompanyMasters[key];
        this.CompanyList.push({"id":Companytemp.CompId,"itemName":Companytemp.CompName},);
      });  
      if(this.CompanyList.length > 0)  
      {
        if(this.UserModel.CompanyIds!="")
        {
          var selected_br = this.UserModel.CompanyIds.split(',');
          for (var _i = 0; _i < selected_br.length; _i++) 
          {
            var item = this.CompanyList.find(x=>x.id==selected_br[_i])
            this.CompanyselectedItems.push(item);
          }
        }
      }   
    });      
  }

  onCompanyItemSelect(item:any){ }
  OnCompanyItemDeSelect(item:any){ }
  onCompanySelectAll(items: any){ }
  onCompanyDeSelectAll(items: any){ }

  getRoles() 
  {
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.UserMasterService.GetRoleMaster( t).then(x => 
    {
      this.BranchMasters = x;
      Object.keys(this.BranchMasters).forEach( key => 
      {
        // tslint:disable-next-line:no-unused-expression
        this.BranchMasters[key];
        let BranchMasterstemp = new RoleMasterModel();
        BranchMasterstemp = this.BranchMasters[key];
        this.RoleMaster.push(BranchMasterstemp);
      });     
    });      
  }

  SaveUserMaster(template) :void
  {
    this.DisableSave=false;
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.UserModel.IsActive=true;
    if(this.UserModel.Id > 0)
    {
      if(this.CompanyselectedItems.length > 0)  
      {
        this.UserModel.CompanyIds='';
        this.CompanyselectedItems.forEach(element => 
        {
          this.UserModel.CompanyIds= this.UserModel.CompanyIds + element.id + ",";
        });
        var l = this.UserModel.CompanyIds.length
        this.UserModel.CompanyIds = this.UserModel.CompanyIds.substr(0,l-1);
      }
      else
      {
        this.UserModel.CompanyIds ="";
      }

      if(this.BranchselectedItems.length > 0)  
      {
        this.UserModel.BranchIds='';
        this.BranchselectedItems.forEach(element => 
        {
          this.UserModel.BranchIds= this.UserModel.BranchIds + element.id + ",";
        });
        var l = this.UserModel.BranchIds.length
        this.UserModel.BranchIds = this.UserModel.BranchIds.substr(0,l-1);
      }
      else
      {
        this.UserModel.BranchIds ="";
      }
      this.UserMasterService.UpdateUserMaster(t ,this.UserModel).subscribe(res=>
      {
        this.UserMasters=[];
        this.Id=res;
        if(this.Id>0)
        {
          if(this.ShowPopup==true)
          {
            this.modalRef.hide();
          }
          this.GetUserMaster();
          this.modalRef.hide();
          this.ShowPopup = false;
          this.DeleteMsg = false;
          this.SaveMsg = false;
          this.UpdateMsg = true;
          this.modalRef = this.modalService.show(template,this.modelopt);
          this.DisableSave=true;
        }
      });
    }
    else
    {
      if(this.CompanyselectedItems.length > 0)  
      {
        this.UserModel.CompanyIds='';
        this.CompanyselectedItems.forEach(element => 
        {
          this.UserModel.CompanyIds= this.UserModel.CompanyIds + element.id + ",";
        });
        var l = this.UserModel.CompanyIds.length
        this.UserModel.CompanyIds = this.UserModel.CompanyIds.substr(0,l-1);
      }
      else
      {
        this.UserModel.CompanyIds ="";
      }
      
      if(this.BranchselectedItems.length > 0)  
      {
        this.UserModel.BranchIds='';
        this.BranchselectedItems .forEach(element => 
        {
          this.UserModel.BranchIds= this.UserModel.BranchIds + element.id + ",";
        });
        var l = this.UserModel.BranchIds.length
        this.UserModel.BranchIds = this.UserModel.BranchIds.substr(0,l-1);
      }
      else
      {
        this.UserModel.BranchIds ="";
      }
      this.UserMasterService.AddUserMaster(t ,this.UserModel).subscribe(res=>
      {
        this.UserMasters=[];
        this.EmployeeDetails=[];
        this.Id=res;
        this.UserModel.Id=0;              
        if(this.Id>0)
        {
          this.GetUserMaster();
          this.GetEmployeeDetails();
          this.modalRef.hide();
          this.ShowPopup = false;
          this.DeleteMsg = false;
          this.SaveMsg = true;
          this.UpdateMsg=false;
          this.modalRef = this.modalService.show(template,this.modelopt);
          this.DisableSave=true;
        }
      }); 
    }
  }

  onCompanychange(CompanyIds)
  {
    if(CompanyIds!='')
    {
      this.BranchselectedItems =  [];
      this.BranchList = [];
      this.dropdownSettings = 
      { 
        text:"Select Branch",
        selectAllText:'Select All',
        unSelectAllText:'UnSelect All',           
        classes:"myclass custom-class",
        enableSearchFilter: true,
      };

      if(this.CompanyselectedItems.length > 0)  
      {
        this.BranchList=[];
        this.BranchselectedItems=[];
        this.UserModel.CompanyIds='';
        this.CompanyselectedItems.forEach(element => 
        {
          this.UserModel.CompanyIds= this.UserModel.CompanyIds + element.id + ",";
        });
        var l = this.UserModel.CompanyIds.length
        this.UserModel.CompanyIds = this.UserModel.CompanyIds.substr(0,l-1);
      }
      this.GetBranches(this.UserModel.CompanyIds);
    }
  }

  onRoleChange()
  {
    this.CompanyselectedItems=[];
    //this.CompanyList=[];
    this.BranchselectedItems=[];
    this.BranchList=[];
  }
}
