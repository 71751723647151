import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-all-employee-tour-reimbursement-status',
  templateUrl: './all-employee-tour-reimbursement-status.component.html',
  styleUrls: ['./all-employee-tour-reimbursement-status.component.css']
})
export class AllEmployeeTourReimbursementStatusComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
