export class SalaryModel
{
    public EmployeeCode : string;
    public EmployeeName : string;
    public CompanyId :number;
    public CompanyName : string;
    public BranchId :number;
    public BranchName : string;
    public DepartmentId :number;
    public DepartmentName : string;
    public JoiningDate :Date;
    public GrossSalary : number;
    public BasicSalary : number;
    public PFAmount : number;
    public EPFCont : number;
    public Year : number;
    public MonthId : number;
    public MonthName : string;
    public Address1 : string;
    public Address2 : string;
    public City : string;
    public PinCode : string;
}