<app-dialog [(visible)]="this.SuccessStatus">
  <span class="successmsg">{{this.SuccessMessage}}</span>
</app-dialog>


<!-- <perfect-scrollbar style="max-width: 1260px; max-height: 540px;" class="mukesh"> -->
<div class="body-content-area tabcontent" id="link1">
  <div class="row">
    <div class="col-lg-9">
      <div class="row">
        <div class="col-lg-1 low-padding text-center">
          <!-- <img *ngIf="this.SelectedMenu.Image!=null" src={{this.SelectedMenu.Image}} class="body-icon" alt="Icon"/> -->
        </div>
        <div class="col-lg-10 clear-padding">
          <span class="page-title">Roll Mapping with Child Menu</span>
          <!-- <small class="page-description">It is a long established fact that a reader will be distracted</small> -->
        </div>
      </div>
    </div>
  </div>




  <fieldset class="body-form-control">


    <legend class="legend--">Crm Role Menu </legend>
    <form #MenuUserMapping="ngForm" (ngSubmit)="addCrmRollMapping()">
      <div class="row">

        <div class="col-lg-4">
          <div class="col-body-border-right">
            <div class="form-group row">
              <label class="col-sm-4 col-form-label clear-padding-right"> Select Role</label>
              <div class="col-sm-7 clear-left-padding">

                <angular2-multiselect tabindex="5" class="form-control" [data]="Rolls" [(ngModel)]="selectedRoles"
                  name="Rollid" [settings]="RollSettings" (onSelect)="onItemSelectRoll($event)"
                  (onDeSelect)="OnItemDeSelectRoll($event)" (onSelectAll)="onSelectAllRoll($event)"
                  (onDeSelectAll)="onDeSelectAllRoll($event)"></angular2-multiselect>

              </div>
              <!-- <div class="required-validation" *ngIf="this.CrmuserRoll.Roll_Id == 0">Roll is required</div>  -->
            </div>
          </div>
        </div>

        <div class="col-lg-4">
          <div class="col-body-border-right">
            <div class="form-group row">
              <label class="col-sm-4 col-form-label clear-padding-right"> Select Menu</label>
              <div class="col-sm-7 clear-left-padding">

                <angular2-multiselect tabindex="5" class="form-control" [data]="Menues" [(ngModel)]="selectedMenuRoll"
                  name="MenuId" [settings]="Menuettings" (onSelect)="onItemSelectMenu($event)"
                  (onDeSelect)="OnItemDeSelectMenu($event)" (onSelectAll)="onSelectAllMenu($event)"
                  (onDeSelectAll)="onDeSelectAllMenu($event)"></angular2-multiselect>

              </div>
              <!-- <div class="required-validation" *ngIf="this.CrmuserRoll.Roll_Id == 0">Roll is required</div>  -->
            </div>
          </div>
        </div>



      </div>

      <div *ngIf="this.Crm_Menu_Roll.length > 0">
        <table id="tblComponent" class="table table-striped table-bordered text-left table-new row-border hover">
          <thead class="thead-blue text-center">
            <tr>
              <th>Sl No.</th>
              <th>Menu</th>
              <th>Can Add</th>
              <th>Can Edit</th>
              <th>Can Delete</th>
              <th>Can View</th>
              <th>Can Approve</th>
              <th>Can Reject</th>
              <th>Can Transfer</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let mum of Crm_Menu_Roll, let i = index">
              <td scope="row">{{i+1}}</td>
              <td>
                {{Crm_Menu_Roll[i].MenuName}}
              </td>
              <td>
                <!-- [value]="CanAdd" -->
                <input type="checkbox" (click)="OnChangeCanAdd($event,i)"
                  [checked]="Crm_Menu_Roll[i].ViewCanAdd=='CanAdd'" id="CanAdd{{i}}" name="CanAdd{{i}}"
                  class="form-control form-control-custom form-control-custom-new">
              </td>
              <td>
                <!-- [value]="CanEdit" -->
                <input type="checkbox" (click)="OnChangeCanEdit($event,i)"
                  [checked]="Crm_Menu_Roll[i].ViewCanEdit=='CanEdit'" id="CanEdit{{i}}" name="CanEdit{{i}}"
                  class="form-control form-control-custom form-control-custom-new">
              </td>
              <td>
                <!-- [value]="CanDelete" -->
                <input type="checkbox" (click)="OnChangeCanDelete($event,i)"
                  [checked]="Crm_Menu_Roll[i].ViewCanDelete=='CanDelete'" id="CanDelete{{i}}" name="CanDelete{{i}}"
                  class="form-control form-control-custom form-control-custom-new">
              </td>
              <td>
                <!-- [value]="CanView" -->
                <input type="checkbox" (click)="OnChangeCanView($event,i)"
                  [checked]="Crm_Menu_Roll[i].ViewCanView=='CanView'" id="CanView{{i}}" name="CanView{{i}}"
                  class="form-control form-control-custom form-control-custom-new">
              </td>
              <td>
                <!-- [value]="CanApprove"  -->
                <input type="checkbox" (click)="OnChangeCanApprove($event,i)"
                  [checked]="Crm_Menu_Roll[i].ViewCanApprove=='CanApprove'" id="CanApprove{{i}}" name="CanApprove{{i}}"
                  class="form-control form-control-custom form-control-custom-new">
              </td>
              <td>
                <!-- [value]="CanReject" -->
                <input type="checkbox" (click)="OnChangeCanReject($event,i)"
                  [checked]="Crm_Menu_Roll[i].ViewCanReject=='CanReject'" id="CanReject{{i}}" name="CanReject{{i}}"
                  class="form-control form-control-custom form-control-custom-new">
              </td>
              <td>
                <!-- [value]="CanTransfer" -->
                <input type="checkbox" (click)="OnChangeCanTransfer($event,i)"
                  [checked]="Crm_Menu_Roll[i].ViewCanTransfer=='CanTransfer'" id="CanTransfer{{i}}"
                  name="CanTransfer{{i}}" class="form-control form-control-custom form-control-custom-new">
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div class="row">
        <div class="col-lg-4">

        </div>
        <div class="col-lg-4">

        </div>
        <div class="col-lg-4" *ngIf="this.Crm_Menu_Roll.length > 0">
          <div class="form-group row">
            <label class="col-sm-6 col-form-label text-center clear-padding-right">
              <button type="submit" tabindex="4" [disabled]="!( this.DisableSave==true)"
                class="btn btn-primary save-button">Save</button>
            </label>
            <div class="col-sm-6 col-form-label text-center">
              <!-- <button type="button" tabindex="5" class="btn btn-danger cancle-button"
                (click)="RefreshPage()">Cancel</button> -->
              </div>
          </div>
        </div>
      </div>
    </form>
  </fieldset>
</div>
<!-- </perfect-scrollbar> -->