import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from '../../shared/user.service';
import { EmployeePortalService }  from '../../shared/HR_ADMIN/EmployeePortal/EmployeePortalService.service';
import { TourReimbursementMasterModel } from '../../shared/HR_ADMIN/EmployeePortal/TourReimbursementMasterModel.Model';
import { Menu } from '../../shared/menu.model';
import { TourReimbursementDetailsModel } from '../../shared/HR_ADMIN/EmployeePortal/TourReimbursementDetailsModel.Model';

@Component({
  selector: 'app-tour-reimbursement-application',
  templateUrl: './tour-reimbursement-application.component.html',
  styleUrls: ['./tour-reimbursement-application.component.css']
})

export class TourReimbursementApplicationComponent implements OnInit 
{
  TourReimbursementModel : TourReimbursementMasterModel= new TourReimbursementMasterModel();
  TourExpenseHeads: TourReimbursementDetailsModel[]=[];
  Application: any;
  StatusValue : any;
  RefId : any;
  userClaims: any;
  Result : string;
  SuccessStatus:boolean=false;
  SuccessMessage: string ="";
  public tableWidget: any;
  selectedIndex : number;
  // Begning Permission Portion    
  SelectedMenu:Menu;
  // End of the permission portion
  // begin for Validation
  validFromdate : boolean= false;
  validTodate : boolean= false;
  validdate : boolean= false;
  DisableSave : boolean=false;
  // end of validation
  ShowMessage : boolean=false;
  TotalExpectedAmount : number=null;
  Noofdays : any;

  constructor(private EmployeePortalService: EmployeePortalService, private rout: Router, private userService: UserService){ }

  ngOnInit() 
  {
    // Begning Permission Portion 
    this.SelectedMenu=this.userService.SelectedMenu;
    if(this.SelectedMenu !=undefined)
    {
      localStorage.setItem('selectedmenu',JSON.stringify(this.SelectedMenu));
      this.rout.navigate([this.SelectedMenu.RouteName]);
    }
    else
    {
      if(JSON.parse(localStorage.getItem('selectedmenu'))!=undefined)
      {
        this.SelectedMenu = JSON.parse(localStorage.getItem('selectedmenu'));
        localStorage.setItem('selectedmenu',JSON.stringify(this.SelectedMenu));
        this.rout.navigate([this.SelectedMenu.RouteName]);
      }
    }
    // End of the permission portion
    this.DisableSave=true;
    if(localStorage.getItem('SuccessfullMessage'))
    {
      this.SuccessStatus=true;
      this.SuccessMessage =localStorage.getItem('SuccessfullMessage')
      localStorage.removeItem('SuccessfullMessage');
    }
    this.GetEmployeeDetailsForReibursement();
    this.TourReimbursementModel.NOofTrip=0;
  }

  GetExpenseHeadForApplication(DecisionId,LevelId,GradeId)
  {
    this.TourExpenseHeads=[];
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.EmployeePortalService.GetExpenseHeadForApplication( t,DecisionId,LevelId,GradeId).then(x => 
    {
      this.Application = x;
      Object.keys(this.Application).forEach( key => 
      {
        this.Application[key];
        let Applicationtemp = new TourReimbursementDetailsModel();
        Applicationtemp = this.Application[key];
        if(Applicationtemp.ExpenseAmount=0)
        {
          Applicationtemp.ExpenseAmount=null;
        }
        if(Applicationtemp.DayWise == true)
        {
          Applicationtemp.BudgetAmount=Applicationtemp.BudgetAmount * this.TourReimbursementModel.NoOfDays;
        }
        else if(Applicationtemp.OverAll == true)
        {
          Applicationtemp.BudgetAmount=Applicationtemp.BudgetAmount * this.TourReimbursementModel.NOofTrip;
        }
        this.TourExpenseHeads.push(Applicationtemp);
      });
    });
  }

  GetEmployeeDetailsForReibursement()
  {
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.EmployeePortalService.GetEmployeeDetailsForReibursementByUserId( t).then(x => 
    {
      this.Application = x;
      Object.keys(this.Application).forEach( key => 
      {
        this.Application[key];
        let Applicationtemp = new TourReimbursementMasterModel();
        Applicationtemp = this.Application[key];
        this.TourReimbursementModel.EmployeeId=this.Application.EmployeeId;
        this.TourReimbursementModel.EmployeeCode=this.Application.EmployeeCode;
        this.TourReimbursementModel.EmployeeName=this.Application.EmployeeName;
        this.TourReimbursementModel.EmployeeEmailId=this.Application.EmployeeEmailId;
        this.TourReimbursementModel.RHId=this.Application.RHId;
        this.TourReimbursementModel.RHCode=this.Application.RHCode;
        this.TourReimbursementModel.RHName=this.Application.RHName;
        this.TourReimbursementModel.RHEmailId=this.Application.RHEmailId;
        this.TourReimbursementModel.FHId=this.Application.FHId;
        this.TourReimbursementModel.FHCode=this.Application.FHCode;
        this.TourReimbursementModel.FHName=this.Application.FHName;
        this.TourReimbursementModel.FHEmailId=this.Application.FHEmailId;
        this.TourReimbursementModel.HRNames=this.Application.HRNames;
        this.TourReimbursementModel.HREmailIds=this.Application.HREmailIds;
        this.TourReimbursementModel.DecisionId=this.Application.DecisionId;
        this.TourReimbursementModel.LevelId=this.Application.LevelId;
        this.TourReimbursementModel.GradeId=this.Application.GradeId;
        this.TourReimbursementModel.NetAvailableAmount=this.Application.NetAvailableAmount;
      });
    });
  }

  FromDateChanged( date)
  {
    var oneDay = 24*60*60*1000; 
    this.TourReimbursementModel.FromDate=date;

    if(this.TourReimbursementModel.FromDate != null && this.TourReimbursementModel.ToDate != null)
    {
      if(this.TourReimbursementModel.ToDate >= this.TourReimbursementModel.FromDate)
      {
        this.validdate=false;
        this.GetNofoDays(this.TourReimbursementModel.FromDate,this.TourReimbursementModel.ToDate);
        this.CheckTourReimbursementApplicationDateexists(this.TourReimbursementModel.FromDate,this.TourReimbursementModel.ToDate);
      }
      else
      {
        this.validdate=true;
      }
    }
  }

  ToDateChanged( date)
  {
    var oneDay = 24*60*60*1000; 
    this.TourReimbursementModel.ToDate=date;
    if(this.TourReimbursementModel.FromDate != null && this.TourReimbursementModel.ToDate != null)
    {
      if(this.TourReimbursementModel.ToDate >= this.TourReimbursementModel.FromDate)
      {
        this.validdate=false;
        this.GetNofoDays(this.TourReimbursementModel.FromDate,this.TourReimbursementModel.ToDate);
        this.CheckTourReimbursementApplicationDateexists(this.TourReimbursementModel.FromDate,this.TourReimbursementModel.ToDate);
      }
      else
      {
        this.validdate=true;
      }
    }
  }

  AdvancePaidDateChanged( date)
  {
    this.TourReimbursementModel.AdvancePaidDate=date;
  }

  NoofTourChanged( NoofTour)
  {
    this.TotalExpectedAmount=0;
    this.TourReimbursementModel.ExpectedExpenseAmount=0;
    if(this.TourReimbursementModel.NoOfDays > 0 && NoofTour > 0)
    {
      this.GetExpenseHeadForApplication(this.TourReimbursementModel.DecisionId,this.TourReimbursementModel.LevelId,this.TourReimbursementModel.GradeId);
    }
    else
    {
      this.TourExpenseHeads=[];
    }
  }

  SaveAEmployeeTourReimbursementApplication() :void
  {
    if(this.TourReimbursementModel.ExpectedExpenseAmount >0 && this.ShowMessage == false && this.TourReimbursementModel.NoOfNights > 0 && this.validdate == false)
    {
      this.DisableSave=false;
      let t =  localStorage.getItem('userToken');
      t = 'Bearer' + t;
      this.TourReimbursementModel.TourReimbursementDetails=this.TourExpenseHeads;
      this.EmployeePortalService.AddEmployeeTourReimbursementApplication(t ,this.TourReimbursementModel).subscribe(res=>
      {
        this.RefId=res;
        this.TourReimbursementModel.RefIdTR=0;              
        if(this.RefId>0)
        {
          localStorage.setItem('SuccessfullMessage', "Application Forward to Reporting Head Successfully");
          this.rout.navigateByUrl('/EmployeePortal/PersonalInformation', {skipLocationChange: true}).then(()=>this.rout.navigate(["/EmployeePortal/TourReimbursementApplication"]));
        }
      }); 
    }
  }

  RefreshPage()
  {
    this.rout.navigateByUrl('/EmployeePortal/PersonalInformation', {skipLocationChange: true}).then(()=>this.rout.navigate(["/EmployeePortal/TourReimbursementApplication"]));
  }

  CheckTourReimbursementApplicationDateexists(FromDate,ToDate)
  {
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.EmployeePortalService.CheckTourReimbursementApplicationDateexists( t,FromDate,ToDate,this.TourReimbursementModel.EmployeeId).then(res => 
    {
      this.StatusValue = res;
      if(this.StatusValue == 1)
      {
        this.ShowMessage = true;
      }
      else
      {
        this.ShowMessage = false;
      }
    });
  }

  GetNofoDays(FromDate,ToDate)
  {
    this.TotalExpectedAmount=0;
    this.TourReimbursementModel.ExpectedExpenseAmount=0;
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.EmployeePortalService.GetNofoDays( t,FromDate,ToDate).then(res => 
    {
      this.Noofdays = res;
      this.TourReimbursementModel.NoOfDays=this.Noofdays;
    });
    if(this.TourReimbursementModel.NoOfDays > 0 && this.TourReimbursementModel.NOofTrip > 0)
    {
      this.GetExpenseHeadForApplication(this.TourReimbursementModel.DecisionId,this.TourReimbursementModel.LevelId,this.TourReimbursementModel.GradeId);
    }
    else
    {
      this.TourExpenseHeads=[];
    }
  }

  ExpenseAmountChanged()
  {
    this.TotalExpectedAmount=0;
    this.TourReimbursementModel.ExpectedExpenseAmount=0;
    var j=0;
    var l=this.TourExpenseHeads.length;
    this.TourExpenseHeads.forEach(item => 
    {
      j=j+1;
      if(item.BudgetAmount >= item.ExpenseAmount)
      { 
        this.TotalExpectedAmount =  Number(this.TotalExpectedAmount) + (Number(item.ExpenseAmount));
        this.TourReimbursementModel.ExpectedExpenseAmount = this.TotalExpectedAmount;
        this.TourReimbursementModel.AdvanceAmount = Number(this.TourReimbursementModel.ExpectedExpenseAmount)/2;
      }
      else
      {
        item.ExpenseAmount=0;
      }
    });
  }
}
