import { Component, OnInit } from '@angular/core';
import { CrmLeadService } from '../../../shared/CRM/MICECRM/LEADCRM/CrmLeadService';
import { Router } from '@angular/router';
import { Model_CRM_Designation } from '../../../shared/CRM/MICECRM/Model_CRM_Designation';
import { Model_CRM_Lead_Contacts } from '../../../shared/CRM/MICECRM/LEADCRM/Model_CRM_Lead_Contacts';
//import { forEach } from '@angular/router/src/utils/collection';

@Component({
  selector: 'app-mice-crm-contacts',
  templateUrl: './mice-crm-contacts.component.html',
  styleUrls: ['./mice-crm-contacts.component.css']
})
export class MiceCrmContactsComponent implements OnInit {


  updatedLeadId: any;

  SuccessMessage: string = "";
  SuccessStatus: boolean = false;
  // For Searchable Dropdown
  DesignationList: Model_CRM_Designation[] = [];
  CRM_Obj_Designation: any;

  //Employee Id lIst

  Ids: number[] = [];
  //Ids:any;

  constructor(private rout: Router, private CRM_LeadService: CrmLeadService) { }

  ngOnInit() {
    this.GetDesignation();
    if ((this.CRM_LeadService.model_CRM_Shared_lead.LeadId == undefined)) {
      this.rout.navigate(["/ManageMICEAccess/ManageLead/LeadBasicInfo"]);
      this.CRM_LeadService.SelectedCrmIndexPage = 0;
    }


    if (!(this.CRM_LeadService.model_CRM_Shared_lead.LeadId > 0)) {
      this.rout.navigate(["/ManageMICEAccess/ManageLead/LeadBasicInfo"]);
      this.CRM_LeadService.SelectedCrmIndexPage = 0;
    }


    if (localStorage.getItem('SuccessfullMessage')) {
      this.SuccessStatus = true;
      this.SuccessMessage = localStorage.getItem('SuccessfullMessage')
      localStorage.removeItem('SuccessfullMessage');
    }



  }





  get IsinViewState(): boolean {

    if (this.CRM_LeadService.IsinViewState == undefined || this.CRM_LeadService.IsinViewState != null) {

      this.CRM_LeadService.IsinViewState = false;
      return this.CRM_LeadService.IsinViewState;
    }
    else {
      return this.CRM_LeadService.IsinViewState;;
    }
  }


  get ContactsList(): Model_CRM_Lead_Contacts[] {

    if (this.CRM_LeadService.model_CRM_Shared_lead.ContactsList == undefined || this.CRM_LeadService.model_CRM_Shared_lead.ContactsList != null) {

      this.AssigneContactListIfUndefine();
      return this.CRM_LeadService.model_CRM_Shared_lead.ContactsList;
    }
    else {
      return this.CRM_LeadService.model_CRM_Shared_lead.ContactsList;
    }
  }

  AssigneContactListIfUndefine() {



    if (this.CRM_LeadService.model_CRM_Shared_lead.ContactsList == undefined) {
      this.CRM_LeadService.model_CRM_Shared_lead.ContactsList = [];

      for (let k = 0; k < 5; k++) {
        var tempcontact = new Model_CRM_Lead_Contacts();
        tempcontact.MR = "Mr";
        tempcontact.MRSelect = 0;
        tempcontact.FirstName = '';
        tempcontact.MiddleName = '';
        tempcontact.LastName = '';
        tempcontact.PhoneNo = '';
        tempcontact.Title = '';
        tempcontact.RollID = 1;
        tempcontact.LeadId = this.CRM_LeadService.model_CRM_Shared_lead.LeadId;
        tempcontact.ContactID = 0;
        this.CRM_LeadService.model_CRM_Shared_lead.ContactsList.push(tempcontact);

      }

    }
    else {
      if (this.CRM_LeadService.model_CRM_Shared_lead.ContactsList.length == 0) {


        for (let k = 0; k < 5; k++) {
          var tempcontact = new Model_CRM_Lead_Contacts();
          tempcontact.MR = "Mr";
          tempcontact.MRSelect = 0;
          tempcontact.FirstName = '';
          tempcontact.MiddleName = '';
          tempcontact.LastName = '';
          tempcontact.PhoneNo = '';
          tempcontact.Title = '';
          tempcontact.RollID = 1;
          tempcontact.LeadId = this.CRM_LeadService.model_CRM_Shared_lead.LeadId;
          tempcontact.ContactID = 0;
          this.CRM_LeadService.model_CRM_Shared_lead.ContactsList.push(tempcontact);

        }


      }
    }

  }


  GetDesignation() {

    let t = localStorage.getItem('userToken');
    t = 'Bearer' + t

    this.DesignationList = [];
    //this.DesignationArray=[];
    this.CRM_LeadService.GetCRM_designation(t).then(x => {
      this.CRM_Obj_Designation = x;
      Object.keys(this.CRM_Obj_Designation).forEach(key => {
        let designationtemp = new Model_CRM_Designation();
        designationtemp = this.CRM_Obj_Designation[key];

        this.DesignationList.push(designationtemp);
        //this.DesignationArray.push({ "id": designationtemp.DesigID, "itemName": designationtemp.Designation }, );
      })

    }).then(x => {

      this.AssigneContactListIfUndefine();

    });
  }



  SaveContactdetails(): void {
    let t = localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.CRM_LeadService.model_CRM_Shared_lead.IsActive = true;

    if (this.CRM_LeadService.model_CRM_Shared_lead.LeadId > 0) {
      this.CRM_LeadService.AddUpdateCrmContactsInfo(t, this.CRM_LeadService.model_CRM_Shared_lead).subscribe(res => {


        for (let i = 0; i < length; i++) {
          if (this.Ids[i] > 0) {
            // this.CRM_LeadService.model_CRM_Shared_lead.ContactsList.find(x=>x.ContactID==i).ContactID=Number(this.Ids[i])
            this.CRM_LeadService.model_CRM_Shared_lead.ContactsList[i].ContactID = Number(this.Ids[i])
            this.updatedLeadId = Number(this.Ids[i]);
          }
        }

        if (this.updatedLeadId > 0) {
          localStorage.setItem('SuccessfullMessage', "Successfully Updated");
          this.CRM_LeadService.SelectedCrmIndexPage = 3;
          this.rout.navigate(["/ManageMICEAccess/ManageLead/AccountInfo"]);
        }
      });
    }

  }


  onChangeMister(item, i) {
    this.CRM_LeadService.model_CRM_Shared_lead.ContactsList[i].MR = item;
  }

  RefreshPage() {

    this.CRM_LeadService.CancelLead();
    this.CRM_LeadService.SelectedCrmIndexPage = 0
    let RoutName_more = 'ManageMICEAccess/ManageLead/LeadBasicInfo'
    this.rout.navigate([RoutName_more]);


  }






}
