import {  Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs/Subject';
import { DataTableDirective } from 'angular-datatables';
import { AttendanceService }  from '../../shared/HR_ADMIN/Attendance/AttendanceService.service';
import { AttendanceFineRule }  from '../../shared/HR_ADMIN/Attendance/AttendanceFineRule.model';
import { UserService } from '../../shared/user.service';
// Begning Confirmation
import { ConfirmationService } from '@jaspero/ng2-confirmations';
import { ResolveEmit } from '../../../app/resolve-emit';
import { ConfirmSettings } from '../../../app/confirm-settings';
// end of Confirmation
import { Menu } from '../../shared/menu.model';

@Component({
  selector: 'app-manage-attendence-fine-rules',
  templateUrl: './manage-attendence-fine-rules.component.html',
  styleUrls: ['./manage-attendence-fine-rules.component.css']
})
export class ManageAttendenceFineRulesComponent implements OnInit 
{
  AttendanceFineRuleModel : AttendanceFineRule= new AttendanceFineRule();
  userClaims: any;
  Result : string;
  SuccessStatus:boolean=false;
  SuccessMessage: string ="";
  public tableWidget: any;
  AttendanceFineRules: AttendanceFineRule[] = [];
  AttendanceFineRule:any;
  AttendanceFineRuleId : any;

  FineNames: AttendanceFineRule[] = [];
  FineName:any;

  FineInTerms: AttendanceFineRule[] = [];
  FineInTerm:any;

  SubMenues : Menu[]=[];
  Mod: any;
  // Begning Permission Portion    
  SelectedMenu:Menu;
  IsDataIsInViewState : boolean=false;
  CanViewState : boolean=false;
  CanViewFineDay : boolean=false;
  DisableSave : boolean =false;
  // End of the permission portion

  tempAttendanceFineRuleadd : AttendanceFineRule =new AttendanceFineRule();

  constructor(private AttendanceService: AttendanceService, private rout: Router, private _confirmation: ConfirmationService,private userService: UserService ) { }

  @ViewChild(DataTableDirective, {static: false})
  setDtOptions: DataTables.Settings = {};
  cardDtOptions: DataTables.Settings = {};
  dtElement: DataTableDirective;
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();

  ngOnInit()
  {
    // Begning Permission Portion 
    this.SubMenues=[];
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    let menuid = localStorage.getItem("menuidstor");
    this.userService.GetSubMenuList( t, menuid).then(x => 
    {
      this.Mod = x;
      Object.keys(this.Mod).forEach( key => 
      {
        // tslint:disable-next-line:no-unused-expression
        this.Mod[key];
        let menutemp = new Menu();
        menutemp = this.Mod[key];
        this.SubMenues.push(menutemp);
      });
      this.userService.SelectedMenu= this.SubMenues[0];
      this.rout.navigate([this.userService.SelectedMenu.RouteName]);
      this.SelectedMenu=this.userService.SelectedMenu;
      if(this.SelectedMenu.CanAdd)
      {
        this.IsDataIsInViewState=false;
      }
      else
      {
        this.IsDataIsInViewState=true;
      }
      if(this.SelectedMenu.CanView)
      {
        this.CanViewState=false;
      }
      else
      {
        this.CanViewState=true;
      }
    });
    this.CanViewFineDay=false;
    // End of the permission portion
    this.DisableSave=true;
    this.getFineName();
    this.getFineInTerm();
    this.AttendanceFineRuleModel.FineNameId=0;
    this.AttendanceFineRuleModel.AttFineInTermId=0;
    this.AttendanceFineRuleModel.IsBasic=false;
    this.dtOptions = 
    {
      pagingType: 'full_numbers',
      pageLength: 4
    };
    if(localStorage.getItem('SuccessfullMessage'))
    {
      this.SuccessStatus=true;
      this.SuccessMessage =localStorage.getItem('SuccessfullMessage')
      localStorage.removeItem('SuccessfullMessage');
    }
  }

  ngAfterViewInit(): void 
  {
    this.getAttendanceFineRule();
    this.dtOptions = 
    {
      pagingType: 'full_numbers',
      pageLength: 4
    };
  }

  getAttendanceFineRule()
  {
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.AttendanceService.GetAttendanceFineRule( t).then(x => 
    {
      this.AttendanceFineRule = x;
      Object.keys(this.AttendanceFineRule).forEach( key => 
      {
        this.AttendanceFineRule[key];
        let AttendanceFineRuletemp = new AttendanceFineRule();
        AttendanceFineRuletemp = this.AttendanceFineRule[key];
        this.AttendanceFineRules.push(AttendanceFineRuletemp);
      });
      this.dtTrigger.next();
    });
  }

  GetManageAttendenceRulesByid(id) 
  {
    // begning of the permission portion
    this.IsDataIsInViewState=false;
    // end of the permission portion
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.AttendanceService.GetAttendanceFineRuleByid( t,id).then(x => 
    {
      this.AttendanceFineRule = x;
      Object.keys(this.AttendanceFineRule).forEach( key => 
      {
        this.AttendanceFineRule[key];
        let AttendanceFineRuletemp = new AttendanceFineRule();
        AttendanceFineRuletemp = this.AttendanceFineRule[key];
        this.AttendanceFineRuleModel.AttFineRuleId=AttendanceFineRuletemp.AttFineRuleId;
        this.AttendanceFineRuleModel.FineRuleName=AttendanceFineRuletemp.FineRuleName;
        this.AttendanceFineRuleModel.FineNameId=AttendanceFineRuletemp.FineNameId;
        this.AttendanceFineRuleModel.MinDays=AttendanceFineRuletemp.MinDays;
        this.AttendanceFineRuleModel.MaxDays=AttendanceFineRuletemp.MaxDays;
        this.AttendanceFineRuleModel.AttFineInTermId=AttendanceFineRuletemp.AttFineInTermId;
        if(AttendanceFineRuletemp.AttFineInTermId==1)
        {
          this.CanViewFineDay=true;
        }
        else
        {
          this.CanViewFineDay=false;
        }
        this.AttendanceFineRuleModel.FineDays=AttendanceFineRuletemp.FineDays;
      });
    });
  }

  ViewManageAttendenceRulesByid(id) 
  {
    // begning of the permission portion
    this.IsDataIsInViewState=true;
    // end of the permission portion
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.AttendanceService.GetAttendanceFineRuleByid( t,id).then(x => 
    {
      this.AttendanceFineRule = x;
      Object.keys(this.AttendanceFineRule).forEach( key => 
      {
        this.AttendanceFineRule[key];
        let AttendanceFineRuletemp = new AttendanceFineRule();
        AttendanceFineRuletemp = this.AttendanceFineRule[key];
        this.AttendanceFineRuleModel.AttFineRuleId=AttendanceFineRuletemp.AttFineRuleId;
        this.AttendanceFineRuleModel.FineRuleName=AttendanceFineRuletemp.FineRuleName;
        this.AttendanceFineRuleModel.FineNameId=AttendanceFineRuletemp.FineNameId;
        this.AttendanceFineRuleModel.MinDays=AttendanceFineRuletemp.MinDays;
        this.AttendanceFineRuleModel.MaxDays=AttendanceFineRuletemp.MaxDays;
        this.AttendanceFineRuleModel.AttFineInTermId=AttendanceFineRuletemp.AttFineInTermId;
        if(AttendanceFineRuletemp.AttFineInTermId==1)
        {
          this.CanViewFineDay=true;
        }
        else
        {
          this.CanViewFineDay=false;
        }
        this.AttendanceFineRuleModel.FineDays=AttendanceFineRuletemp.FineDays;
      });
    });
  }
     
  saveAttendanceFineRule() :void
  {
    this.DisableSave=false;
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.AttendanceFineRuleModel.IsActive=true;
    if(this.AttendanceFineRuleModel.AttFineRuleId > 0)
    {
      this.AttendanceService.UpdateAttendanceFineRule(t ,this.AttendanceFineRuleModel).subscribe(res=>
      {
        this.AttendanceFineRuleId=res;
        this.AttendanceFineRuleModel.AttFineRuleId=0;  
        if(this.AttendanceFineRuleId>0)
        {
          localStorage.setItem('SuccessfullMessage', "Update Successfully");
          this.rout.navigateByUrl('/Attendence/ManageWorkingHours', {skipLocationChange: true}).then(()=>this.rout.navigate(["/Attendence/ManageAttendenceFineRules"]));
        }
      });                  
    }
    else
    {
      this.AttendanceService.AddAttendanceFineRule(t ,this.AttendanceFineRuleModel).subscribe(res=>
      {
        this.AttendanceFineRuleId=res;
        this.AttendanceFineRuleModel.AttFineRuleId=0;              
        if(this.AttendanceFineRuleId>0)
        {
          localStorage.setItem('SuccessfullMessage', "Save Successfully");
          this.rout.navigateByUrl('/Attendence/ManageWorkingHours', {skipLocationChange: true}).then(()=>this.rout.navigate(["/Attendence/ManageAttendenceFineRules"]));
        }
      }); 
    }
  }
  
  DeleteAttendanceFineRuleByid(AttendanceFineRuleId : number) :void
  {
    this._confirmation.create('Confirm to Deleate', 'Press Yes to Delete', this.settings)
    .subscribe((ans: ResolveEmit) => 
    {
      if (ans.resolved == true) 
      {
        this.DisableSave=false;
        let t =  localStorage.getItem('userToken');
        t = 'Bearer' + t;
        this.AttendanceFineRuleModel.IsActive=true;
        if( AttendanceFineRuleId > 0)
        {
          this.AttendanceService.DeleteAttendanceFineRule(t ,AttendanceFineRuleId).subscribe(res=>
          {
            this.AttendanceFineRuleId=res;
            this.AttendanceFineRuleModel.AttFineRuleId=0;                                  
            if(this.AttendanceFineRuleId >0)
            {
              localStorage.setItem('SuccessfullMessage', "Deleted Successfully");
              this.rout.navigateByUrl('/Attendence/ManageWorkingHours', {skipLocationChange: true}).then(()=>this.rout.navigate(["/Attendence/ManageAttendenceFineRules"]));
            }
          });                  
        }
      }
    })
  }

  settings: ConfirmSettings | any = 
  {
    overlay: true,
    overlayClickToClose: true,
    showCloseButton: true,
    confirmText: 'Yes',
    declineText: 'No'
  };

  getFineName()
  {
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.AttendanceService.GetFineName( t).then(x => 
    {
      this.FineName = x;
      Object.keys(this.FineName).forEach( key => 
      {
        this.FineName[key];
        let FineNametemp = new AttendanceFineRule();
        FineNametemp = this.FineName[key];
        this.FineNames.push(FineNametemp);
      });
    });
  }

  getFineInTerm()
  {
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.AttendanceService.GetFineInTerm( t).then(x => 
    {
      this.FineInTerm = x;
      Object.keys(this.FineInTerm).forEach( key => 
      {
        this.FineInTerm[key];
        let FineInTermtemp = new AttendanceFineRule();
        FineInTermtemp = this.FineInTerm[key];
        this.FineInTerms.push(FineInTermtemp);
      });
    });
  }

  onChangeFineInterm(FineIntermid) 
  {
    if(FineIntermid==1)
    {
      this.CanViewFineDay=true;
    }
    else
    {
      this.CanViewFineDay=false;
    }
  }

  RefreshPage()
  {
    this.rout.navigateByUrl('/Attendence/ManageWorkingHours', {skipLocationChange: true}).then(()=>this.rout.navigate(["/Attendence/ManageAttendenceFineRules"]))
  }
}
