export class Zone {
    
        public  ZoneID :number;
        public  CountryID : number;
        public ZoneName : string;
        public  ZoneCode : string;
        public  CountryName : string;
        public  CreatedBy: number;
        public  IsActive : boolean;
    
}