<!-- <perfect-scrollbar style="max-width: 1260px; max-height: 540px;" class="mukesh"> -->

<div>
  <app-dialog [(visible)]="this.SuccessStatus">
    <span class="successmsg">{{this.SuccessMessage}}</span>
  </app-dialog>
</div>
<div class="body-content-area tabcontent" id="link1">
  <div class="row">
    <div class="col-lg-9">
      <div class="row">
        <div class="col-lg-1 low-padding text-center">


        </div>

        <div class="col-lg-10 clear-padding">
          <span class="page-title">ADD CRM BRANCH </span>
          <small class="page-description">It is a long established fact that a reader will be distracted</small>
        </div>
      </div>
    </div>
  </div>

  <div>
    <table id="tblCRMBranch" datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger"
      class="table table-striped table-bordered text-center table-new row-border hover">
      <thead class="thead-blue text-center">

        <tr>
          <th>Sl No.</th>
          <th class="text-left">Branch</th>
          <th class="text-left">Country</th>
          <th class="text-left">Zone</th>
          <th class="text-left">State</th>
          <th class="text-left">City</th>

          <th>Action</th>
        </tr>

      </thead>

      <tbody>

        <tr *ngFor="let b of branchlist, let i = index">
          <td scope="row">{{i+1}}</td>
          <td class="text-left">{{b.BranchName}}</td>
          <td class="text-left">{{b.CountryName}}</td>
          <td class="text-left">{{b.ZoneName}}</td>
          <td class="text-left">{{b.StateName}}</td>
          <td class="text-left">{{b.CityName}}</td>



          <td>
            <!-- <button *ngIf="this.SelectedMenu.CanEdit" type="button" (click)="GetCRMBranchById( b.BranchId )" class="table-edit-btn"> 
        <i class="fas fa-edit"></i>
        </button>
      
        <button *ngIf="this.SelectedMenu.CanDelete" type="button" (click)="DeleteCrmBranch( b.BranchId )" class="table-remove-btn">
          <i class="fas fa-trash-alt"></i>
        </button>
      
        <button *ngIf="this.SelectedMenu.CanView" type="button" (click)="ViewCRMBranchById( b.BranchId )" class="table-view-button" type="button">
          <i class="fas fa-eye"></i>
        </button> -->

            <button type="button" (click)="GetCRMBranchById( b.BranchId )"
              *ngIf="this.userService.selectedCrmMenu.CanEdit" class="table-edit-btn">
              <i class="fas fa-edit"></i>
            </button>

            <button type="button" (click)="DeleteCrmBranch( b.BranchId )"
              *ngIf="this.userService.selectedCrmMenu.CanDelete" class="table-remove-btn">
              <i class="fas fa-trash-alt"></i>
            </button>

            <button type="button" (click)="ViewCRMBranchById( b.BranchId )"
              *ngIf="this.userService.selectedCrmMenu.CanView" class="table-view-button" type="button">
              <i class="fas fa-eye"></i>
            </button>
          </td>




      </tbody>
    </table>
    <div>
      <app-dialog [(visible)]="this.SuccessStatus">
        <span class="successmsg">{{this.SuccessMessage}}</span>
      </app-dialog>
    </div>

    <fieldset class="body-form-control">




      <legend class="legend--">ADD BRANCH</legend>
      <form #BranchName="ngForm" (ngSubmit)="saveCRMBranch()">

        <div class="row">
          <div class="col-lg-4">
            <div class="col-body-border-right">
              <div class="form-group row">
                <label class="col-sm-4 col-form-label clear-padding-right">BRANCH NAME</label>
                <div class="col-sm-7 clear-left-padding">
                  <input type="text" [(ngModel)]="CRM_Branch_Model.BranchName" tabindex="1" maxlength="250"
                    id="BranchName" name="BranchName" #BranchName="ngModel" required
                    class="form-control form-control-custom form-control-custom-new">

                  <div *ngIf="BranchName.invalid && (BranchName.dirty || BranchName.touched)"
                    class="alert alert-danger">
                    <div *ngIf="BranchName.errors.required">Branch Name is required.</div>
                    <div *ngIf="BranchName.errors.maxlength">Branch Name is not more than 250 characters</div>
                  </div>

                </div>
              </div>

              <div class="form-group row">
                <label class="col-sm-4 col-form-label clear-padding-right">STATE
                  <div class="required-validation" *ngIf="CRM_Branch_Model.StateId==0">*</div>
                </label>
                <div class="col-sm-7 clear-left-padding">
                  <!-- <select [(ngModel)]="CRM_Branch_Model.StateId" tabindex="3" (ngModelChange)="onModuleChange($event)"
                      id="StateId" class="form-control" name="StateId" #StateId="ngModel">
                      <option [value]="0" [disabled]=true>Select State</option>
                      <option *ngFor="let s of statelist" [value]="s.StateId">{{s.StateName}}</option>
                    </select> -->

                  <angular2-multiselect tabindex="5" class="form-control" [data]="StateArray"
                    [(ngModel)]="SelectedState" name="StateId" [settings]="StateSettings"
                    (onSelect)="onItemSelectState($event)" (onDeSelect)="OnItemDeSelectState($event)"
                    (onSelectAll)="onSelectAllState($event)" (onDeSelectAll)="onDeSelectAllState($event)">
                  </angular2-multiselect>

                </div>

              </div>
            </div>
          </div>


          <div class="col-lg-4">
            <div class="form-group row">
              <label class="col-sm-4 col-form-label clear-padding-right">
                COUNTRY<div class="required-validation" *ngIf="CRM_Branch_Model.CountryId==0">*</div>
              </label>
              <div class="col-sm-7 clear-left-padding">

                <!-- <select [(ngModel)]="CRM_Branch_Model.CountryId" tabindex="3" (ngModelChange)="onCountryChange($event)"
                    id="CountryId" class="form-control" name="CountryId" #CountryId="ngModel">
                    <option [value]="0" [disabled]=true>Select Country</option>
                    <option *ngFor="let c of countrylist" [value]="c.CountryId">{{c.CountryName}}</option>
                  </select> -->

                <angular2-multiselect tabindex="5" class="form-control" [data]="CountryArray"
                  [(ngModel)]="SelectedCountry" name="CountryId" [settings]="CountrySttings"
                  (onSelect)="onItemSelectCountry($event)" (onDeSelect)="OnItemDeSelectCountry($event)"
                  (onSelectAll)="onSelectAllCountry($event)" (onDeSelectAll)="onDeSelectAllCountry($event)">
                </angular2-multiselect>
              </div>

            </div>
            <div class="form-group row">
              <label class="col-sm-4 col-form-label clear-padding-right">CITY
                <div class="required-validation" *ngIf="CRM_Branch_Model.CityId==0">*</div>
              </label>
              <div class="col-sm-7 clear-left-padding">
                <!-- <select [(ngModel)]="CRM_Branch_Model.CityId" tabindex="3" (ngModelChange)="onModuleChange($event)"
                    id="CityId" class="form-control" name="CityId" #CityId="ngModel">
                    <option [value]="0" [disabled]=true>Select City</option>
                    <option *ngFor="let cl of citylist" [value]="cl.CityId">{{cl.CityName}}</option>
                  </select> -->

                <angular2-multiselect tabindex="5" class="form-control" [data]="CityArray"
                  [(ngModel)]="this.SelectedCity" name="CityId" [settings]="CitySettings"
                  (onSelect)="onItemSelectCity($event)" (onDeSelect)="OnItemDeSelectCity($event)"
                  (onSelectAll)="onSelectAllCity($event)" (onDeSelectAll)="onDeSelectAllCity($event)">
                </angular2-multiselect>
              </div>

            </div>
          </div>



          <div class="col-lg-4">
            <div class="form-group row">
              <label class="col-sm-4 col-form-label clear-padding-right"> ZONE
                <div class="required-validation" *ngIf="CRM_Branch_Model.ZoneId==0">*</div> </label>
              <div class="col-sm-7 clear-left-padding">
                <!-- <select [(ngModel)]="CRM_Branch_Model.ZoneId" tabindex="3" (ngModelChange)="onModuleChange($event)"
                    id="ZoneId" class="form-control" name="ZoneId" #ZoneId="ngModel">
                    <option [value]="0" [disabled]=true> Select Zone </option>
                    <option *ngFor="let z of zonelist" [value]="z.ZoneId">{{z.ZoneName}}</option>
                  </select> -->

                <angular2-multiselect tabindex="5" class="form-control" [data]="ZoneArray" [(ngModel)]="SelectedZone"
                  name="ZoneId" [settings]="this.ZoneSettings" (onSelect)="onItemSelectZone($event)"
                  (onDeSelect)="OnItemDeSelectZone($event)" (onSelectAll)="onSelectAllZone($event)"
                  (onDeSelectAll)="onDeSelectAllZone($event)"></angular2-multiselect>
              </div>

            </div>

            <div class="form-group row">
              <div class="col-sm-5 col-form-label text-center">
                <button type="submit" tabindex="3" class="btn btn-primary save-button"
                  *ngIf="!(this.IsDataIsInViewState)"
                  [disabled]="(((this.CRM_Branch_Model.BranchName=='') ||(  this.CRM_Branch_Model.BranchName==undefined) )
                  ||(this.CRM_Branch_Model.CountryId==0) 
                  || (this.CRM_Branch_Model.ZoneId==0) 
                  || (this.CRM_Branch_Model.StateId==0) 
                  ||(this.CRM_Branch_Model.CityId==0) 
                  ||(this.CRM_Branch_Model.BranchName=='') )">Save</button>
              </div>
              <div class="col-sm-5 col-form-label text-center">
                <button type="button" tabindex="4" (click)="RefreshPage()"
                  class="btn btn-danger cancle-button">Cancel</button>
              </div>
            </div>



          </div>


        </div>



        <!-- <div class="col-lg-4">
            
          </div> -->



        <!-- <div class="col-lg-4">
            
          </div> -->


      </form>
    </fieldset>
  </div>
</div>
<!-- </perfect-scrollbar> -->