<div class="body-content-area tabcontent" id="link1">
  <div class="row">
    <div class="col-lg-9">
      <div class="row">
        <div class="col-lg-1 low-padding text-center">
          <!-- <img *ngIf="this.SelectedMenu.Image!=null" src={{this.SelectedMenu.Image}} class="body-icon" alt="Icon"/> -->
        </div>
        <div class="col-lg-10 clear-padding">
          <span class="page-title">Manage TDS Slab Master</span>
          <small class="page-description"></small>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="!(this.CanViewState)">
    <fieldset class="body-form-control">
      <legend class="legend--">TDS Slab Master</legend>
      <table id="tblTDSSlab" class="table table-striped table-bordered text-center table-new row-border hover">
        <thead class="thead-blue text-center">
          <tr>
            <th>Sl No.</th>
            <th>Gender</th>
            <th>Age</th>
            <th>Type</th>
            <th>Cess</th>
            <th>Applicable Date</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let TDS of TDSSlabModels, let i = index">
            <td scope="row">{{i+1}}</td>
            <td class="text-left">{{TDS.GenderName}}</td>
            <td class="text-left">{{TDS.AgeStatus}}</td>
            <td class="text-left">{{TDS.TDSType}}</td>
            <td class="text-center">{{TDS.HealthEducationCess}}</td>
            <td class="text-center">{{TDS.ApplicableFromDate | date : "dd-MMM-yyyy"}}</td>
            <td>
              <button *ngIf="this.SelectedMenu.CanEdit" type="button" (click)="GetTDSSlabDetailsByRefId(TDS.RefId,TDS.GenderId,TDS.GenderName,TDS.AgeStatus,TDS.HealthEducationCess,TDS.ApplicableFromDate,TDS.TDSType,template)" class="table-edit-btn"><i class="fas fa-edit"></i></button> 
              <button *ngIf="this.SelectedMenu.CanView" type="button" (click)="ViewTDSSlabDetailsByRefId(TDS.RefId,TDS.GenderName,TDS.AgeStatus,TDS.HealthEducationCess,TDS.ApplicableFromDate,TDS.TDSType,template)" class="table-view-button" type="button"><i class="fas fa-eye"></i></button>
            </td>
          </tr>
        </tbody>
        <tfoot class="thead-blue text-center">
          <tr>
            <th></th>
            <th></th>
            <th></th>
            <th></th>
            <th></th>
            <th></th>
            <th></th>
          </tr>
        </tfoot>
      </table>
    </fieldset>
    <div style=" box-align: center; width: 1500px;">
      <ng-template #template>
        <div class="modal-header" style="background: #2a9bd7; max-width: 1500px;"> 
          <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()" style="color:#fff;">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div style="text-align: center; max-width: 1500px;">
        <div *ngIf="UpdateMsg" style="color: #dc3545; text-align: center;font-size: 15px; font-weight: bold;">Update Successfully</div>
        <div style=" width: 100%; text-align: left;" *ngIf="ShowPopup">
          <fieldset class="body-form-control">
            <legend class="legend--">TDS Slab Details</legend>
            <form #TDSForm="ngForm" (ngSubmit)="UpdateTDSDetails(template)">
              <div class="row">
                <div class="col-lg-4">
                  <div class="col-body-border-right">
                    <div class="form-group row">
                      <label class="col-sm-4 col-form-label clear-padding-right">Gender</label>
                      <div class="col-sm-7 clear-left-padding">
                        {{this.TDSSlabModel.GenderName}}
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-lg-4">
                  <div class="col-body-border-right">
                    <div class="form-group row">
                      <label class="col-sm-4 col-form-label clear-padding-right">Age</label>
                      <div class="col-sm-7 clear-left-padding">
                        {{this.TDSSlabModel.AgeStatus}}
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-lg-4">
                  <div class="form-group row">
                    <label class="col-sm-4 col-form-label clear-padding-right">TDS Type</label>
                    <div class="col-sm-7 clear-left-padding">
                      {{this.TDSSlabModel.TDSType}}
                    </div>
                  </div>
                </div>
              </div>
              <div *ngIf="this.TDSSlabDetails.length > 0" style="text-align: center;">
                <table id="tbltdsdetails" class="table table-striped table-bordered text-center table-new row-border hover" style="width: 80%; margin-left: 10%;">
                  <thead class="thead-blue text-center"s tyle="width: 80%;">
                    <tr>
                      <th style=" text-align: center;">From Amount</th>
                      <th style=" text-align: center;">To Amount</th>
                      <th style=" text-align: center;">Percentage</th>
                      <th style=" text-align: center;">Amount</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let TDSD of TDSSlabDetails, let i = index">
                      <td *ngIf="!(this.IsDataIsInViewState)">
                        <input type="text" [(ngModel)]="TDSD.FromAmount" name="FromAmount_{{i}}" #FromAmount="ngModel" pattern="[0-9]+" class="form-control form-control-custom form-control-custom-new">
                        <div *ngIf="FromAmount.invalid && (FromAmount.dirty || FromAmount.touched)" class="alert alert-danger">
                          <div *ngIf="FromAmount.errors?.pattern">Amount should be valid.</div>
                        </div>
                      </td>
                      <td *ngIf="(this.IsDataIsInViewState)" style="text-align: right;">
                        {{TDSD.FromAmount}}
                      </td>
                      <td *ngIf="!(this.IsDataIsInViewState)">
                        <input type="text" [(ngModel)]="TDSD.ToAmount" name="ToAmount_{{i}}" #ToAmount="ngModel" pattern="[0-9]+" class="form-control form-control-custom form-control-custom-new">
                        <div *ngIf="ToAmount.invalid && (ToAmount.dirty || ToAmount.touched)" class="alert alert-danger">
                          <div *ngIf="ToAmount.errors?.pattern">Amount should be valid.</div>
                        </div>
                      </td>
                      <td *ngIf="(this.IsDataIsInViewState)" style="text-align: right;">
                        {{TDSD.ToAmount}}
                      </td>
                      <td *ngIf="!(this.IsDataIsInViewState)">
                        <input type="text" [(ngModel)]="TDSD.Percentage" name="Percentage_{{i}}" #Percentage="ngModel" pattern="[0-9]+" class="form-control form-control-custom form-control-custom-new">
                        <div *ngIf="Percentage.invalid && (Percentage.dirty || Percentage.touched)" class="alert alert-danger">
                          <div *ngIf="Percentage.errors?.pattern">Percentage should be valid.</div>
                        </div>
                      </td>
                      <td *ngIf="(this.IsDataIsInViewState)" style="text-align: right;">
                        {{TDSD.Percentage}}
                      </td>

                      <td *ngIf="!(this.IsDataIsInViewState)">
                        <input type="text" [(ngModel)]="TDSD.TDSAmount" name="TDSAmount_{{i}}" #TDSAmount="ngModel" pattern="[0-9]+" class="form-control form-control-custom form-control-custom-new">
                        <div *ngIf="TDSAmount.invalid && (TDSAmount.dirty || TDSAmount.touched)" class="alert alert-danger">
                          <div *ngIf="TDSAmount.errors?.pattern">TDSAmount should be valid.</div>
                        </div>
                      </td>
                      <td *ngIf="(this.IsDataIsInViewState)" style="text-align: right;">
                        {{TDSD.TDSAmount}}
                      </td>
                    </tr>
                  </tbody>
                  <tfoot class="thead-blue text-center">
                    <tr>
                      <th></th>
                      <th></th>
                      <th></th>
                      <th>
                        <button *ngIf="!(this.IsDataIsInViewState)" type="button" class="btn btn-danger cancle-button" (click)="AddnweRow()">Add Row</button>
                      </th>
                    </tr>
                  </tfoot>
                </table>
              </div>
              <div class="row">
                <div class="col-lg-4">
                    <div class="col-body-border-right">
                      <div class="form-group row">
                        <label class="col-sm-4 col-form-label clear-padding-right">Cess</label>
                        <div *ngIf="!(this.IsDataIsInViewState)" class="col-sm-7 clear-left-padding">
                          <input type="text" width="20px" [(ngModel)]="TDSSlabModel.HealthEducationCess" pattern="[0-9]+" tabindex="1" id ="HealthEducationCess" name="HealthEducationCess" #HealthEducationCess="ngModel" class="form-control form-control-custom form-control-custom-new">
                          <div *ngIf="HealthEducationCess.invalid && (HealthEducationCess.dirty || HealthEducationCess.touched)" class="alert alert-danger">
                            <div *ngIf="HealthEducationCess.errors?.pattern">Cess should be valid.</div>
                          </div>
                        </div>
                        <div *ngIf="(this.IsDataIsInViewState)" class="col-sm-7 clear-left-padding">
                          {{TDSSlabModel.HealthEducationCess}}
                        </div>
                      </div>
                    </div>
                </div>
                <div class="col-lg-4">
                  <div class="col-body-border-right">
                    <div class="form-group row">
                      <label class="col-sm-4 col-form-label clear-padding-right">Applicable Date</label>
                      <div *ngIf="!(this.IsDataIsInViewState)" class="col-sm-7 clear-left-padding">
                        <input type="date" name="ModificationDate" tabindex="1" [ngModel]="TDSSlabModel.ModificationDate | date:'yyyy-MM-dd'" (ngModelChange)="dateChanged($event)" #ModificationDate="ngModel" required/>
                        <label *ngIf="(!validdate)" class="required-validation">Select Valid Date</label>
                      </div>
                      <div *ngIf="(this.IsDataIsInViewState)" class="col-sm-7 clear-left-padding">
                        {{this.TDSSlabModel.ModificationDate | date : "dd-MMM-yyyy"}}
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-lg-4">
                  <div class="form-group row">
                    <label *ngIf="!(this.IsDataIsInViewState)" class="col-sm-6 col-form-label text-center clear-padding-right">
                      <button type="submit" tabindex="4" class="btn btn-primary save-button" [disabled]="!(TDSForm.valid && this.DisableSave==true && this.TDSSlabModel.HealthEducationCess != 0 && this.TDSSlabModel.HealthEducationCess != undefined && this.validdate == true)" >Save</button>  
                    </label>
                  </div>
                </div>
              </div>
            </form>
          </fieldset>
        </div>
        <button type="button" aria-label="Close" (click)="modalRef.hide()" style="background: #2a9bd7; outline: 0px none; border: 0px none; color: #fff; padding: 5px 10px; border-radius: 3px;">
          <span>Close</span>
        </button>             
      </div>
    </ng-template>
    </div>
  </div>
</div>