export class Country {
    
    public  CountryId : number;
    public  CountryName : string;
    public  CountryCode :string;
    public  LanguageCode : string;
    public LanguageName  : string;
    public  CurrencyCode : string;
    public  CurrencyName : string;
    public  CreatedBy :number;
    public  IsActive : boolean;
    
}