<div class="body-content-area tabcontent" id="link1">
  <div class="row">
    <div class="col-lg-9">
      <div class="row">
        <div class="col-lg-1 low-padding text-center">
          <!-- <img *ngIf="this.SelectedMenu.Image!=null" src={{this.SelectedMenu.Image}} class="body-icon" alt="Icon"/> -->
        </div>
        <div class="col-lg-10 clear-padding">
          <div>
            <span class="page-title">DD Tour Leave Report</span>
            <small class="page-description"></small>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="!(this.CanViewState)">
    <fieldset class="body-form-control" > 
      <legend class="legend--">Employee DD Tour Leave Details</legend>
      <form #EmployeeAttendanceForm="ngForm">
        <div class="row">
          <div class="col-lg-4">
            <div class="col-body-border-right">
              <div class="form-group row">
                <label class="col-sm-4 col-form-label clear-padding-right">Company</label>
                <div class="col-sm-7 clear-left-padding">
                  <select [(ngModel)]="EmployeeAttendanceModel.CompId" (ngModelChange)="onChangeCompany($event)" id ="CompId" class="form-control" name="CompId" #CompId="ngModel">
                    <option [value]="0">Select Company</option>
                    <option *ngFor="let comp of Companies" [value]="comp.CompId">{{comp.CompName}}</option>
                  </select>
                </div>
              </div>
              <div class="form-group row">
                <label class="col-sm-4 col-form-label clear-padding-right">From Date</label>
                <div class="col-sm-7 clear-left-padding">
                  <input type="date" name="FromDate" [ngModel]="EmployeeAttendanceModel.FromDate | date:'yyyy-MM-dd'" (ngModelChange)="FromDateChanged($event)" #FromDate="ngModel" required/>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-4">
            <div class="col-body-border-right">
              <div class="form-group row">
                <label class="col-sm-4 col-form-label clear-padding-right">Branch</label>
                <div class="col-sm-7 clear-left-padding">
                  <select [(ngModel)]="EmployeeAttendanceModel.BranchId" id ="BranchId" class="form-control" name="BranchId" #BranchId="ngModel" >
                      <option [value]="0">Select Branch</option>
                      <option *ngFor="let Bran of Branches" [value]="Bran.BranchId">{{Bran.BranchName}}</option>
                  </select>
                </div>
              </div>
              <div class="form-group row">
                <label class="col-sm-4 col-form-label clear-padding-right">To Date</label>
                <div class="col-sm-7 clear-left-padding">
                  <input type="date" name="ToDate" [ngModel]="EmployeeAttendanceModel.ToDate | date:'yyyy-MM-dd'" (ngModelChange)="ToDateChanged($event)" #ToDate="ngModel" required/>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-4">
            <div class="form-group row">
              <label class="col-sm-6 col-form-label text-center clear-padding-right" *ngIf="!(this.IsDataIsInViewState)">
                <button type="button" (click)="submitReport()" class="btn btn-primary save-button" [disabled]="!(EmployeeAttendanceForm.valid)">Generate Reports</button>   
              </label>
              <div class="col-sm-6 col-form-label text-center">       
                <button type="button" tabindex="7" class="btn btn-danger cancle-button" (click)="RefreshPage()" >Cancel</button>
              </div>
            </div>
            </div>
          </div>
      </form>    
    </fieldset>
  </div>
</div>
 


