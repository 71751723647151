import { Component, OnInit, ViewChild } from '@angular/core';
import { Model_CRM_Role } from '../../../shared/CRM/MICECRM/Model_CRM_Role'
import { Model_CrmType } from '../../../shared/CRM/MICECRM/Model_CrmType'
import { RoleService } from '../../../shared/CRM/MICECRM/Role_service';
import { Router } from '@angular/router';
import { ConfirmationService } from '@jaspero/ng2-confirmations';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { UserService } from '../../../shared/user.service';
import { Menu } from '../../../shared/menu.model';
import { ConfirmSettings } from '@jaspero/ng2-confirmations/src/interfaces/confirm-settings';
import { ResolveEmit } from '@jaspero/ng2-confirmations/src/interfaces/resolve-emit';
import { Observable } from 'rxjs/Observable';

@Component({
  selector: 'app-manage-role',
  templateUrl: './manage-role.component.html',
  styleUrls: ['./manage-role.component.css']
})
export class ManageRollComponent implements OnInit {

  // validdate: boolean; 
  //IsInView: boolean;
  DeleteRoleNameByID: number = 0;
  CRMRole: any;
  CRMType: any;
  check: string;
  CheckExiststatus: boolean;
  checkdataval: any;
  Checkeditstatus: boolean;
  RoleName: any;
  CrmType: Model_CrmType[] = [];
  UserMasterService: any;
  SuccessMessage: string;
  SuccessStatus: boolean;
  DisableSave: boolean;
  CanViewState: boolean;
  IsDataIsInViewState: boolean;
  SelectedMenu: Menu;
  Role_Manage_Model: Model_CRM_Role;
  crmrole: Model_CRM_Role[] = [];

  Model_Crm_Type: Model_CrmType;
  push: any;
  RoleId: number;


  constructor(private ManageRoleService: RoleService, private rout: Router, private _confirmation: ConfirmationService, public userService: UserService) {

    this.Role_Manage_Model = new Model_CRM_Role();
    this.Role_Manage_Model.CRMType = 0;


  }

  @ViewChild(DataTableDirective, {static: false})
  setDtOptions: DataTables.Settings = {};
  cardDtOptions: DataTables.Settings = {};
  dtElement: DataTableDirective;
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();



  ngOnInit() {
    this.rout.navigate([this.userService.selectedCrmMenu.RouteName]);
    
    // //Begin Permission Portion
    // this.SelectedMenu = this.userService.SelectedMenu;
    // if (this.SelectedMenu != undefined) {
    //   localStorage.setItem('selectedmenu', JSON.stringify(this.SelectedMenu));
    //   this.rout.navigate([this.SelectedMenu.RouteName]);
    // }
    // else {
    //   if (JSON.parse(localStorage.getItem('selectedmenu')) != undefined) {
    //     this.SelectedMenu = JSON.parse(localStorage.getItem('selectedmenu'));
    //     this.rout.navigate([this.SelectedMenu.RouteName]);
    //   }
    // } 
    // if (this.SelectedMenu.CanAdd) {
    //   this.IsDataIsInViewState = false;
    // }
    // else {
    //   this.IsDataIsInViewState = true;
    // }
    // if (this.SelectedMenu.CanView) {
    //   this.CanViewState = false;
    // }
    // else {
    //   this.CanViewState = true;
    // }
    //End of the peremission portion
    this.DisableSave = true;
    // this.dtOptions =
    //   {
    //     pagingType: 'full_numbers',
    //     pageLength: 4
    //   };
    if (localStorage.getItem('SuccessfullMessage')) {
      this.SuccessStatus = true;
      this.SuccessMessage = localStorage.getItem('SuccessfullMessage')
      localStorage.removeItem('SuccessfullMessage');
    }

  }

  ngAfterViewInit(): void {


    this.GetCRMRole();
    this.GetCRMType();



    this.dtOptions =
      {
        pagingType: 'full_numbers',
        pageLength: 4
      };
  }

  settings: ConfirmSettings | any =
    {
      overlay: true,
      overlayClickToClose: true,
      showCloseButton: true,
      confirmText: 'Yes',
      declineText: 'No'
    };




  //  For The Drop  down Starts

  GetCRMType() {
    //alert();
    this.Role_Manage_Model.CRMType = 0;
    let t = localStorage.getItem('userToken');
    t = 'Bearer' + t
    this.CrmType = [];

    this.ManageRoleService.GetCRMType(t).then(x => {
      this.CRMType = x;
      Object.keys(this.CRMType).forEach(key => {

        let Crmtypetemp = new Model_CrmType();
        Crmtypetemp = this.CRMType[key];
        this.CrmType.push(Crmtypetemp);

      });

    });
  }

  //  For The Drop-down  Ends





  GetCRMRole() {
    // alert();
    let t = localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.ManageRoleService.GetCRMRoles(t).then(x => {
      this.CRMRole = x;
      Object.keys(this.CRMRole).forEach(key => {
        this.CRMRole[key];
        let CRMRoletemp = new Model_CRM_Role();
        CRMRoletemp = this.CRMRole[key];
        this.crmrole.push(CRMRoletemp);
      });

      this.dtTrigger.next();
    });
  }



  saveCRMRole(): void {
    //this.IsNotBusy=true;
    let t = localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.Role_Manage_Model.IsActive = true;

    if (this.Role_Manage_Model.Role_ID > 0) {

      this.ManageRoleService.UpdateRoleNameDetails(t, this.Role_Manage_Model).subscribe(res => {

        this.RoleId = Number(res);
        this.Role_Manage_Model.Role_ID = 0;

        if (this.RoleId > 0) {
          localStorage.setItem('SuccessfullMessage', "Successfully Updated");
          this.rout.navigateByUrl('ManageMICEAccess/ManageMICEUser', { skipLocationChange: true }).then(() => this.rout.navigate(["ManageMICEAccess/ManageRoll"]));

        }
      });
    }
    else {
      this.ManageRoleService.AddRoleName(t, this.Role_Manage_Model).subscribe(res => {
        this.RoleId = Number(res);
        this.Role_Manage_Model.Role_ID = 0;

        if (this.RoleId > 0) {
          localStorage.setItem('SuccessfullMessage', "Successfully Added");
          this.rout.navigateByUrl('ManageMICEAccess/ManageMICEUser', { skipLocationChange: true }).then(() => this.rout.navigate(["ManageMICEAccess/ManageRoll"]));

        }
      });
    }
  }




  onCRMTypeChange(CRMTypeid) {

    this.Role_Manage_Model.CRMType = CRMTypeid;

  }

  GetCRMRoleById(Id) {
    //  alert('ok');
    // Begning of the permission portion
    this.IsDataIsInViewState = false;
    // End of the permision portion
    let t = localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.ManageRoleService.GetCRM_Role_By_id(t, Id).then(x => {
      this.CRMRole = x;
      Object.keys(this.CRMRole).forEach(key => {
        // tslint:disable-next-line:no-unused-expression

        let CRMRoletemp = new Model_CRM_Role();
        CRMRoletemp = this.CRMRole[key];


        this.Role_Manage_Model.Role_ID = CRMRoletemp.Role_ID;
        this.Role_Manage_Model.RoleName = CRMRoletemp.RoleName;
        this.Role_Manage_Model.CRMType = CRMRoletemp.CRMType;
        this.Role_Manage_Model.IsActive = CRMRoletemp.IsActive;
        this.Role_Manage_Model.CRMTypeName = CRMRoletemp.CRMTypeName;
      });

    });
  }


  ViewCRMRoleById(Id) {

    //this.IsInView = true;

    //begin permission
    this.IsDataIsInViewState = true;
    // this.validdate = true;


    //end permission
    let t = localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.ManageRoleService.GetCRM_Role_By_id(t, Id).then(x => {
      this.CRMRole = x;
      Object.keys(this.CRMRole).forEach(key => {

        let CRMRoletemp = new Model_CRM_Role();
        CRMRoletemp = this.CRMRole[key];

        this.Role_Manage_Model.Role_ID = CRMRoletemp.Role_ID;
        this.Role_Manage_Model.RoleName = CRMRoletemp.RoleName;
        this.Role_Manage_Model.CRMType = CRMRoletemp.CRMType;
        this.Role_Manage_Model.IsActive = CRMRoletemp.IsActive;
        this.Role_Manage_Model.CRMTypeName = CRMRoletemp.CRMTypeName;

      });

      //this.GetCRMType();      
      this.checkdataval = this.Role_Manage_Model.CRMType;
      this.Checkeditstatus = true;
      this.CheckExiststatus = true;
      this.check = ""
    });
  }


  DeleteManageRole(ID: number): void {
    // alert(ID);
    this._confirmation.create('Confirm to Delete', 'Press Yes to Delete', this.settings)
      .subscribe((ans: ResolveEmit) => {
        if (ans.resolved == true) {

          let t = localStorage.getItem('userToken');
          t = 'Bearer' + t;

          if (ID > 0) {
            this.ManageRoleService.DeleteRoleName(t, ID).subscribe(res => {
              this.DeleteRoleNameByID = Number(res);

              if (this.DeleteRoleNameByID > 0) {
                localStorage.setItem('SuccessfullMessage', "Successfully Deleted");
                this.rout.navigateByUrl('ManageMICEAccess/ManageMICEUser', { skipLocationChange: true }).then(() => this.rout.navigate(["ManageMICEAccess/ManageRoll"]));
              }
            });
          }

        }
      })
  }

  RefreshPage() {

    this.Role_Manage_Model.Role_ID = 0;
    this.Role_Manage_Model.RoleName = '';
    this.Role_Manage_Model.CRMType = 0;
    this.Role_Manage_Model.IsActive = false;
    this.Role_Manage_Model.CRMTypeName = '';

    //begin permission
    this.IsDataIsInViewState = false;
    // this.validdate = true;


  }


  private extractData(res: Response) {
    let body = res;
    return body || {};
  }
  private handleErrorObservable(error: Response | any) {
    console.error(error.message || error);
    return Observable.throw(error.message || error);
  }

}




