export class EmployeeNomineeDetailsModel{

    public NomineeRefId: Number;
    public  EmployeeId : Number;
    public NomineeName: string;
    public NomineeRelationId: Number;
    public NomineeRelation: string;
    public NomineeDOB: any;
    public  NomineeDOBPass : string;
    public NomineeAddress: string;
    public NomineeCountryId: Number;
    public NomineeCountry: string;
    public NomineeStateId: Number;
    public NomineeState: string;
    public NomineeCityId: Number;
    public NomineeCity: string;
    public PFSharePercentage: Number;
    public GratuitySharePercentage: Number;
    public PensionSharePercentage: Number;
    public NomineeGuardianName: string;
    public GuardianRelationId: Number;
    public GuardianRelation: string;
    public GuardianAddress: string;
    public Isminor: boolean;

}