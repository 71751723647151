export class MonthlyAttendanceModel
{
    public RefIdmonthly : number;
    public EmployeeId : number;
    public EmployeeCode : string;
    public EmployeeName : string;
    public MonthId : number;
    public AttendanceMonth : string;
    public AttendanceFromDate : Date;
    public AttendanceToDate : Date;
    public FinYear : number;
    public NoDaysinMonth : number;
    public TotalPresent : number;
    public TotalWeeklyOff : number;
    public TotalHolidays : number;
    public TotalDirectDuty : number;
    public TotalTour : number;
    public TotalLeave : number;
    public TotalAbsent : number;
    public TotalLateAttendance : number;
    public TotalEarlyAttendance : number;
    public PenaltyAttendance : number;
    public MainAttendance : number;
    public AddPenalty : number;
    public DeductPenalty : number;
    public AdjustWithLeave : number;
    public OtherAttendance : number;
    public FinalAttendance : number;
    public FinalLOP : number;
    public AbsentAdjustWithLeave : number;
    public Remarks : string;
    public CompanyId : number;
    public BranchId : number;
    public DepartmentId : number;
    public DecisionId : number;
    public LevelId : number;
    public GradeId : number;
    public DesignationId : number;
    public CreatedBy : number;
    public CreatedDate : Date;
    public UpdatedBy : number;
    public UpdatedDate : Date;
    public FinalSubmit : boolean;
    //for salary
    public CompanyName : string;
    public BranchName : string;
    public DepartmentName : string;
    public SalaryId :number;
    public EmpMonthSalID : number;
    //end for salary
    public Selected : boolean;
    public DepartmentIds : string;
}