<div class="body-content-area tabcontent" id="link1">
  <div class="row">
      <div class="col-lg-9">
          <div class="row">
              <div class="col-lg-1 low-padding text-center">
                <!-- <img *ngIf="this.SelectedMenu.Image!=null" src={{this.SelectedMenu.Image}} class="body-icon" alt="Icon"/> -->
              </div>
              <div class="col-lg-10 clear-padding">
                  <span class="page-title">Manage Working Hours</span>
                  <small class="page-description"></small>
              </div>
          </div>
      </div>
  </div>
  <div *ngIf="!(this.CanViewState)">
  <table id="tblWorkingHours" datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" class="table table-striped table-bordered text-center table-new row-border hover">
      <thead class="thead-blue text-center">
          <tr>
              <th>Sl No.</th>
              <th class="text-left">Name</th>
              <th>In - Time</th>
              <th>Out - Time</th>
              <th>Halfday In - Time</th>
              <th>Halfday Out - Time</th>
              <th>Early Out - Time</th>
              <th>Action</th>
          </tr>
      </thead>
      <tbody>
          <tr *ngFor="let WH of WorkingHour, let i = index">
              <td scope="row">{{i+1}}</td>
              <td class="text-left">{{WH.WorkingHoursName}}</td>
              <td>{{WH.InTime}}</td>
              <td>{{WH.OutTime}}</td>
              <td>{{WH.HalfDayInTime}}</td>
              <td>{{WH.HalfDayOutTime}}</td>
              <td>{{WH.EarlyOutTime}}</td>
              <td>
                  <button *ngIf="this.SelectedMenu.CanEdit" type="button"  (click)="GetWorkingHoursByid( WH.WorkingHoursId )" class="table-edit-btn"><i class="fas fa-edit"></i> </button>
                  <button *ngIf="this.SelectedMenu.CanDelete" type="button"  (click)="DeleteWorkingHoursByid( WH.WorkingHoursId )" class="table-remove-btn"><i class="fas fa-trash-alt"></i> </button>
                  <button *ngIf="this.SelectedMenu.CanView"   type="button" (click)="ViewWorkingHoursByid( WH.WorkingHoursId )" class="table-view-button" type="button" ><i class="fas fa-eye"></i></button>
                </td>
          </tr>
      </tbody>
  </table>
  
  <fieldset class="body-form-control">
    <app-dialog [(visible)]="this.SuccessStatus">
      <span class="successmsg">{{this.SuccessMessage}}</span>
    </app-dialog>
      
    <legend class="legend--">Working Hours Creation</legend>
    <form #WorkingHoursForm="ngForm" (ngSubmit)="saveWorkingHours()">
      <div class="row">
        <div class="col-lg-4">
          <div  class="col-body-border-right">
            <div class="form-group row" >
              <label class="col-sm-4 col-form-label clear-padding-right">Working Hours</label>
              <div class="col-sm-7 clear-left-padding">
                <input type="text" [(ngModel)]="WorkingHoursModel.WorkingHoursName" tabindex="1" maxlength="250" id ="WorkingHoursName"  name="WorkingHoursName" #WorkingHoursName="ngModel" required class="form-control form-control-custom form-control-custom-new" >
              </div>
              <div *ngIf="WorkingHoursName.invalid && (WorkingHoursName.dirty || WorkingHoursName.touched)" class="alert alert-danger">
                <div *ngIf="WorkingHoursName.errors.required">Working Hours is required.</div>
                <div *ngIf="WorkingHoursName.errors.maxlength">Working Hours is not more than 250 characters</div>
              </div> 
            </div>
            <div class="form-group row">
              <label  class="col-sm-4 col-form-label clear-padding-right">Shift Duration</label>
              <div class="col-sm-7 clear-left-padding">
                <input type="text" disabled="disabled" [(ngModel)]="WorkingHoursModel.WorkingDuration" id ="WorkingDuration" name="WorkingDuration"  #WorkingDuration="ngModel" class="form-control form-control-custom form-control-custom-new" >
              </div>
            </div>
            <div class="form-group row">
              <label  class="col-sm-4 col-form-label clear-padding-right">HalfDay Duration</label>
              <div class="col-sm-7 clear-left-padding">
                <input type="text" disabled="disabled" [(ngModel)]="WorkingHoursModel.HalfDayDuration" id ="HalfDayDuration"  name="HalfDayDuration" #HalfDayDuration="ngModel" class="form-control form-control-custom form-control-custom-new" >       
              </div>
            </div>
            <div class="form-group row" *ngIf="!this.WorkingHoursModel.IsFlexiable">
              <label class="col-sm-4 col-form-label clear-padding-right">Early Out-Time</label>
              <div class="col-sm-7 clear-left-padding">
                <input type="time" id="EarlyOutTime" (click)="SelectEarlyOutTime()" value="{{selectedEarlyOutTime}}" tabindex="8" name="EarlyOutTime" [(ngModel)]="WorkingHoursModel.EarlyOutTime" #EarlyOutTime="ngModel" placeholder="HH:mm:ss" />
              </div>
              </div>
              <div class="form-group row" *ngIf="this.WorkingHoursModel.IsFlexiable">
                <label class="col-sm-4 col-form-label clear-padding-right">Early out By Minutes</label>
                <div class="col-sm-7 clear-left-padding">
                  <div class="col-sm-7 clear-left-padding">
                    <input type="number"  [(ngModel)]="WorkingHoursModel.EarlyMaxtime" id ="EarlyMaxtime" name="EarlyMaxtime" #EarlyMaxtime="ngModel" class="form-control form-control-custom form-control-custom-new" >    
                  </div>
                </div>
                </div>
              <div class="form-group row">
                <label class="col-sm-4 col-form-label clear-padding-right">Is Flexiable</label>
                <div class="col-sm-7 clear-left-padding">
                  <input type="checkbox" [(ngModel)]="WorkingHoursModel.IsFlexiable" tabindex="11" id ="IsFlexiable" name="IsFlexiable" #IsFlexiable="ngModel" class="form-control form-control-custom form-control-custom-new">
                </div>
              </div>
          </div>  
        </div>
        <div class="col-lg-4">
          <div  class="col-body-border-right">
            <div class="form-group row">
              <label class="col-sm-4 col-form-label clear-padding-right">First Half InTime(In)</label>
              <div class="col-sm-7 clear-left-padding">
                <input type="time" id="InTime" readonly="readonly" tabindex="2" (click)="SelectinTime()" value="{{selectedintime}}" name="InTime" [(ngModel)]="WorkingHoursModel.InTime" placeholder="HH:mm:ss" />
              </div>
            </div>  
            <div class="form-group row" >
              <label class="col-sm-4 col-form-label clear-padding-right">Second Half Last InTime(In)</label>
              <div class="col-sm-7 clear-left-padding">
                <input type="time" id="HalfDayInTime" tabindex="4" readonly="readonly" (click)="SelectHalfDayinTime()" value="{{selectedHalfDayintime}}" name="HalfDayInTime" [(ngModel)]="WorkingHoursModel.HalfDayInTime" placeholder="HH:mm:ss"/>
              </div>
            </div>
            <div class="form-group row" >
              <label class="col-sm-4 col-form-label clear-padding-right">First Half End InTime(In)</label>
              <div class="col-sm-7 clear-left-padding">
                <input type="time" id="HalfDayStartTime" (click)="SelectHalfDayStartTime()" value="{{selectedHalfDayStartTime}}" tabindex="6" name="HalfDayStartTime" [(ngModel)]="WorkingHoursModel.HalfDayStartTime" placeholder="HH:mm:ss"/>        
              </div>
            </div>
            <div class="form-group row">
              <label class="col-sm-4 col-form-label clear-padding-right">Is Grace</label>
              <div class="col-sm-7 clear-left-padding">
                <input type="checkbox" [(ngModel)]="WorkingHoursModel.IsGraceTimeApplicable" tabindex="9" id ="IsGraceTimeApplicable" name="IsGraceTimeApplicable" #IsGraceTimeApplicable="ngModel" (ngModelChange)="oncheckedChange()" class="form-control form-control-custom form-control-custom-new">
              </div>
            </div>
            <div class="form-group row">
            </div>
          </div>
        </div>
        <div  class="col-lg-4">
          <div class="form-group row">
            <label class="col-sm-4 col-form-label clear-padding-right">Out-Time(Out)</label>
            <div class="col-sm-7 clear-left-padding">
              <input type="time" readonly="readonly" (click)="SelectOutTime()" value="{{selectedOuttime}}" tabindex="3" id="OutTime" name="OutTime" [(ngModel)]="WorkingHoursModel.OutTime" placeholder="HH:mm:ss"/> 
            </div>
          </div>
          <div class="form-group row">
            <label  class="col-sm-4 col-form-label clear-padding-right">Second Half OutTime(Out)</label>
            <div class="col-sm-7 clear-left-padding">
                <input type="time" readonly="readonly" (click)="SelectHalfDayOutTime()" value="{{selectedHalfDayOuttime}}" tabindex="5" id="HalfDayOutTime" name="HalfDayOutTime" [(ngModel)]="WorkingHoursModel.HalfDayOutTime" placeholder="HH:mm:ss"/>     
            </div>
          </div>
          <div class="form-group row">
            <label class="col-sm-4 col-form-label clear-padding-right">First Half OutTime(Out)</label>
            <div class="col-sm-7 clear-left-padding">
              <input type="time" id="HalfDayEndTime" (click)="SelectHalfDayEndTime()" value="{{selectedHalfDayEndTime}}" tabindex="7" name="HalfDayEndTime" [(ngModel)]="WorkingHoursModel.HalfDayEndTime" placeholder="HH:mm:ss"/>       
            </div>
          </div>
          <div class="form-group row">
            <label  class="col-sm-4 col-form-label clear-padding-right">Grace Time</label>
            <div class="col-sm-7 clear-left-padding">
              <input type="number" [readonly]="!WorkingHoursModel.IsGraceTimeApplicable" [(ngModel)]="WorkingHoursModel.GraceTime" id ="GraceTime" name="GraceTime" #GraceTime="ngModel" class="form-control form-control-custom form-control-custom-new" >    
            </div>
          </div>
          <div class="form-group row">
            <label *ngIf="!(this.IsDataIsInViewState)" class="col-sm-6 col-form-label text-center clear-padding-right">
              <button type="submit" tabindex="12" class="btn btn-primary save-button" [disabled]="!(WorkingHoursForm.valid && this.DisableSave==true)" >Save</button>  
            </label>
            <div class="col-sm-6 col-form-label text-center">
              <button type="button" tabindex="13" (click)="RefreshPage()" class="btn btn-danger cancle-button">Cancel</button>
            </div>
          </div>
        </div>
      </div>
    </form>
  </fieldset>
  </div>
</div>