import { Component, OnInit, ViewChild } from '@angular/core';
import { Model_CRM_Role}  from  '../../../shared/CRM/MICECRM/Model_CRM_Role';
import { Router } from '@angular/router';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { UserService } from '../../../shared/user.service';
import { Menu } from '../../../shared/menu.model';
import { Model_Crm_UserRoll } from '../../../shared/CRM/MICECRM/Model_Crm_User_Roll';
import {CrmUserRollService} from '../../../shared/CRM/MICECRM/crm-user-roll.service';
import {Model_Crm_Employees} from '../../../shared/CRM/MICECRM/Model_Crm_Employee';

@Component({
  selector: 'app-crm-user-roll',
  templateUrl: './crm-user-roll.component.html',
  styleUrls: ['./crm-user-roll.component.css']
})
export class CrmUserRollComponent implements OnInit{


  CrmuserRoll : Model_Crm_UserRoll= new Model_Crm_UserRoll();
  
  userClaims: any;
 Crm_UserRolls: Model_Crm_UserRoll[] = [];
  CrmEmp: Model_Crm_Employees[]=[];
  CrmRoles: Model_CRM_Role[]=[];
  
   
  SelectedRoll:Model_CRM_Role;
  SelecteUser: Model_Crm_Employees;
  Result : string;
  SuccessStatus:boolean=false;
  SuccessMessage: string ="";
  public tableWidget: any;
  RoleMaster: any;
  RoleMasterId : any; 
  base64textString : string;

  UserRoleId : any;

  // Begning Permission Portion    
  SelectedMenu:Menu;
  IsDataIsInViewState : boolean=false;
  CanViewState : boolean=false;
  DisableSave :boolean=false;
  // End of the permission portion
  //check for duplicate component code
  CheckExiststatus : boolean=null;  
  Checkeditstatus : boolean =false;
  check : string ="";
  checkdata : any;
  checkdataval : string ="";
  //check for duplicate component code
  //for Salary Component Dropdown list
  ModuleList = [];
  ModuleselectedItems = [];
  dropdownSettings = {};
  ModuleSettings = {};
  ModuleMasters :any;
  //for branch Dropdown list

  //Multi select 

  selectedItems = [];

  selectedRoles = [];

  UserSettings = {};
  RollSettings = {};
  emp : any [] =[];
  Rolls: any [] =[];
  //end multi select

  IsdisableUser:boolean=false;

  constructor(private CrmURS: CrmUserRollService , private rout: Router,public userService: UserService) { 

    this.SelectedRoll= new Model_CRM_Role();
    this.SelecteUser =  new Model_Crm_Employees()

  }

  @ViewChild(DataTableDirective, {static: false})
  setDtOptions: DataTables.Settings = {};
  cardDtOptions: DataTables.Settings = {};
  dtElement: DataTableDirective;
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();

  ngOnInit() {

    this.rout.navigate([this.userService.selectedCrmMenu.RouteName]);


    this.CrmuserRoll.EmpId=0;
    this.CrmuserRoll.Roll_Id=0;

    this.UserSettings={
      singleSelection:true,
      text:'Select User',
      enableSearchFilter:true,
      showCheckbox:false,
      disabled:false


    }

    this.RollSettings={
      singleSelection:true,
      text:'Select Rolls',
      enableSearchFilter:true,
      showCheckbox:false

    }

    
    
   
    if(localStorage.getItem('SuccessfullMessage'))
    {
      this.SuccessStatus=true;
      this.SuccessMessage =localStorage.getItem('SuccessfullMessage')
      localStorage.removeItem('SuccessfullMessage');
    }  
    

  }
  ngAfterViewInit(): void {
   // alert();
    this.GetcrmUser();
     this.GetcrmRolls();
     this.GetcrmUserRolls();
    
      
   
    // this.dtOptions =
    //   {
    //     pagingType: 'full_numbers',
    //     pageLength: 1
    //   };
  }


  onItemSelect(item:any){
    
    let empid= item['id'];
     
    this.CrmuserRoll.EmpId=empid ;      
    }
   OnItemDeSelect(item:any){ }
   onSelectAll(items: any){ }
   onDeSelectAll(items: any){ }



   onItemSelectRoll(item:any){
    
    let Rollid= item['id'];
     
    this.CrmuserRoll.Roll_Id=Rollid ; 

    this.CrmuserRoll.CrmTypeId= this.CrmRoles.find(x=>x.Role_ID==this.CrmuserRoll.Roll_Id).CRMType;
    
    
    }
   OnItemDeSelectRoll(item:any){ }
   onSelectAllRoll(items: any){ }
   onDeSelectAllRoll(items: any){ }


   GetcrmUser()
   {
     
  
     let t =  localStorage.getItem('userToken');
     t = 'Bearer' + t;
     this.emp=[];
     this.CrmEmp=[];
  
     this.CrmURS.GetCRMUser( t).then(x => 
     {
       this.RoleMaster = x;
       Object.keys(this.RoleMaster).forEach( key => 
       {
         this.RoleMaster[key];
         let crmemptemp = new Model_Crm_Employees();
         crmemptemp = this.RoleMaster[key];
         //this.RoleMasterModel.ModuleIds=SalMaptemp.ModuleIds;
         this.CrmEmp.push(crmemptemp);
          
         this.emp.push({"id":crmemptemp.EmployeeId,"itemName":crmemptemp.EmployeeCode + '-' +crmemptemp.FullName},);
       });
       
     });
   }


   GetcrmRolls()
  {
   
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.emp=[];
    this.CrmURS.GetCRMRoles( t).then(x => 
    {
      this.RoleMaster = x;
      Object.keys(this.RoleMaster).forEach( key => 
      {
        this.RoleMaster[key];
        let crmemptemp = new Model_CRM_Role();
        crmemptemp = this.RoleMaster[key];
        //this.RoleMasterModel.ModuleIds=SalMaptemp.ModuleIds;
        //this.CrmEmp.push(crmemptemp);
        this.CrmRoles.push(crmemptemp);
        this.Rolls.push({"id":crmemptemp.Role_ID,"itemName":crmemptemp.RoleName  },);
      });
      
    });
  }


  GetcrmUserRolls()
  {
    this.Crm_UserRolls=[];
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    
    this.CrmURS.GetCRMUserRoles( t).then(x => 
    {
      this.RoleMaster = x;
      Object.keys(this.RoleMaster).forEach( key => 
      {
        this.RoleMaster[key];
        let crmUsertemp = new Model_Crm_UserRoll();
        crmUsertemp = this.RoleMaster[key];
        //this.RoleMasterModel.ModuleIds=SalMaptemp.ModuleIds;
        //this.CrmEmp.push(crmemptemp);
        
        this.Crm_UserRolls.push(crmUsertemp);
       
      });

      this.dtTrigger.next();
      
    });
  }



   GetUserRollById(id :number)
  {
    this.DisableSave=true;
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.selectedRoles=[] ;
    
    this.emp=[];
    this.CrmEmp=[];
    this.CrmURS.GetAllCRMEmployee( t).then(x => 
      {
        this.RoleMaster = x;
      Object.keys(this.RoleMaster).forEach( key => 
      {
        this.RoleMaster[key];
        let crmemptemp = new Model_Crm_Employees();
        crmemptemp = this.RoleMaster[key];
        //this.RoleMasterModel.ModuleIds=SalMaptemp.ModuleIds;
        this.CrmEmp.push(crmemptemp);
        
        this.emp.push({"id":crmemptemp.EmployeeId,"itemName":crmemptemp.EmployeeCode + '-' +crmemptemp.FullName},);
      })
         
        
        
      }).then(k=>{
    
    this.CrmURS.GetCRM_User_Role_By_id( t, id).then(x => 
    {
      this.RoleMaster = x;
      this.selectedRoles=[];
      this.selectedItems=[];

      let crmUsertemp = new Model_Crm_UserRoll();
      crmUsertemp=this.RoleMaster;
      var selectItem= this.CrmRoles.find(x=>x.Role_ID==crmUsertemp.Roll_Id);

      this.selectedRoles.push({"id":selectItem.Role_ID,"itemName":selectItem.RoleName  },);
      this.CrmuserRoll.Roll_Id=crmUsertemp.Roll_Id ;

      var selectedUserItem= this.CrmEmp.find(x=>x.EmployeeId==crmUsertemp.EmpId);

      this.selectedItems.push({"id":selectedUserItem.EmployeeId,"itemName":selectedUserItem.EmployeeCode + '-' +selectedUserItem.FullName  },)

      this.CrmuserRoll.EmpId=selectedUserItem.EmployeeId;
      
      this.CrmuserRoll.UserRollId=id;
  //this.IsdisableUser=true;
  this.DisableSave=false;
  
  this.UserSettings={
    singleSelection:true,
    text:'Select User',
    enableSearchFilter:true,
    showCheckbox:false,
    disabled:true
  }
  
  
    })});
  }

  ViewUserRoleById(id :number)
  {
    this.DisableSave=true;
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.selectedRoles=[] ;
    
    this.emp=[];
    this.CrmEmp=[];
    this.CrmURS.GetAllCRMEmployee( t).then(x => 
      {
        this.RoleMaster = x;
      Object.keys(this.RoleMaster).forEach( key => 
      {
        this.RoleMaster[key];
        let crmemptemp = new Model_Crm_Employees();
        crmemptemp = this.RoleMaster[key];
        //this.RoleMasterModel.ModuleIds=SalMaptemp.ModuleIds;
        this.CrmEmp.push(crmemptemp);
        
        this.emp.push({"id":crmemptemp.EmployeeId,"itemName":crmemptemp.EmployeeCode + '-' +crmemptemp.FullName},);
      })
         
        
        
      }).then(k=>{
    
    this.CrmURS.GetCRM_User_Role_By_id( t, id).then(x => 
    {
      this.RoleMaster = x;
      this.selectedRoles=[];
      this.selectedItems=[];

      let crmUsertemp = new Model_Crm_UserRoll();
      crmUsertemp=this.RoleMaster;
      var selectItem= this.CrmRoles.find(x=>x.Role_ID==crmUsertemp.Roll_Id);

      this.selectedRoles.push({"id":selectItem.Role_ID,"itemName":selectItem.RoleName  },);
      this.CrmuserRoll.Roll_Id=crmUsertemp.Roll_Id ;

      var selectedUserItem= this.CrmEmp.find(x=>x.EmployeeId==crmUsertemp.EmpId);

      this.selectedItems.push({"id":selectedUserItem.EmployeeId,"itemName":selectedUserItem.EmployeeCode + '-' +selectedUserItem.FullName  },)

      this.CrmuserRoll.EmpId=selectedUserItem.EmployeeId;
      
      this.CrmuserRoll.UserRollId=id;
  //this.IsdisableUser=true;
  
  this.UserSettings={
    singleSelection:true,
    text:'Select User',
    enableSearchFilter:true,
    showCheckbox:false,
    disabled:true
  }
  
  
    })});
  }

  addCrmRollMapping(): void {
    //this.IsNotBusy=true;
    this.DisableSave=true;
    
    let t = localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.CrmuserRoll.IsActive = true;
     
    if (this.CrmuserRoll.UserRollId > 0) {

      this.CrmURS.UpdateCrmuserRoll(t, this.CrmuserRoll).subscribe(res => {
        
        this.UserRoleId = res;
        this.CrmuserRoll.UserRollId = 0;

        if (this.UserRoleId > 0) {
          this.DisableSave=false;
          localStorage.setItem('SuccessfullMessage', "Successfully Updated");
          this.rout.navigateByUrl('ManageMICEAccess/ManageRoll', { skipLocationChange: true }).then(() => this.rout.navigate(["ManageMICEAccess/CrmUserRoll"]));

        }
      });
    }

   
    else {
     
      this.CrmURS.AddCrmUserRoll(t, this.CrmuserRoll).subscribe(res => {
        this.UserRoleId = res;
        this.CrmuserRoll.UserRollId = 0;
        
        if (this.UserRoleId > 0) {
          this.DisableSave=false;
          localStorage.setItem('SuccessfullMessage', "Successfully Added");
          this.rout.navigateByUrl('ManageMICEAccess/ManageRoll', { skipLocationChange: true }).then(() => this.rout.navigate(["ManageMICEAccess/CrmUserRoll"]));

        }
      });
    }
  }

  RefreshPage(): void {    
    
    this.rout.navigateByUrl('ManageMICEAccess/ManageRoll', { skipLocationChange: true }).then(() => this.rout.navigate(["ManageMICEAccess/CrmUserRoll"]));

  }



}
