import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs/Subject';
import { DataTableDirective } from 'angular-datatables';
import { LevelService }  from '../../shared/HR_ADMIN/Lavels_Grades/Levels.service';
import { DecisionMaking } from  '../../shared/HR_ADMIN/Lavels_Grades/DecisionMaking.Model';
import { UserService } from '../../shared/user.service';
// Begning Confirmation
import { ConfirmationService } from '@jaspero/ng2-confirmations';
import { ResolveEmit } from '../../../app/resolve-emit';
import { ConfirmSettings } from '../../../app/confirm-settings';
// end of Confirmation
import { Menu } from '../../shared/menu.model';

@Component({
  selector: 'app-manage-decision-making-levels',
  templateUrl: './manage-decision-making-levels.component.html',
  styleUrls: ['./manage-decision-making-levels.component.css']
})

export class ManageDecisionMakingLevelsComponent implements OnInit 
{
  DecisionModel : DecisionMaking= new DecisionMaking();
  userClaims: any;
  Result : string;
  SuccessStatus:boolean=false;
  SuccessMessage: string ="";
  public tableWidget: any;
  DecisionMakings: DecisionMaking[] = [];
  DecisionMaking:any;
  DecisionId : any;
  SubMenues: Menu[] = [];
  Mod: any; 
  // Begning Permission Portion    
  SelectedMenu:Menu;
  IsDataIsInViewState : boolean=false;
  CanViewState : boolean = false;
  DisableSave : boolean=false;
  // End of the permission portion
  //check for duplicate Name
  CheckNameExiststatus : boolean=null;  
  CheckNamestatusedit : boolean =false;
  Namecheck : string ="";
  checkName : any;
  checkNameval : string ="";
  //check for duplicate Name
  tempDecisionadd : DecisionMaking =new DecisionMaking();
 
  constructor(private LevelService: LevelService, private rout: Router,
     private _confirmation: ConfirmationService, private userService: UserService) { }

  @ViewChild(DataTableDirective, {static: false})
  setDtOptions: DataTables.Settings = {};
  cardDtOptions: DataTables.Settings = {};
  dtElement: DataTableDirective;
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();

  ngOnInit() 
  {
    // Begning Permission Portion 
    this.SubMenues=[];
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    let menuid = localStorage.getItem("menuidstor");
    this.userService.GetSubMenuList( t, menuid).then(x => 
    {
      this.Mod = x;
      Object.keys(this.Mod).forEach( key => 
      {
        // tslint:disable-next-line:no-unused-expression
        this.Mod[key];
        let menutemp = new Menu();
        menutemp = this.Mod[key];
        this.SubMenues.push(menutemp);
      });
      this.userService.SelectedMenu= this.SubMenues[0];
      this.rout.navigate([this.userService.SelectedMenu.RouteName]);
      this.SelectedMenu=this.userService.SelectedMenu;
      if(this.SelectedMenu.CanAdd)
      {
        this.IsDataIsInViewState=false;
      }
      else
      {
        this.IsDataIsInViewState=true;
      }
      if(this.SelectedMenu.CanView)
      {
        this.CanViewState=false;
      }
      else
      {
        this.CanViewState=true;
      }
    })
    // End of the permission portion
    this.DisableSave=true;
    this.dtOptions = 
    {
      pagingType: 'full_numbers',
      pageLength: 4
    };
    if(localStorage.getItem('SuccessfullMessage'))
    {
      this.SuccessStatus=true;
      this.SuccessMessage =localStorage.getItem('SuccessfullMessage')
      localStorage.removeItem('SuccessfullMessage');
    }
  }

  settings: ConfirmSettings | any = {
    overlay: true,
    overlayClickToClose: true,
    showCloseButton: true,
    confirmText: 'Yes',
    declineText: 'No'
  };

  ngAfterViewInit(): void 
  {
    this.getDecisionMakings();
    this.dtOptions = 
    {
      pagingType: 'full_numbers',
      pageLength: 4
    };
  }

  getDecisionMakings()
  {
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.LevelService.GetDecisionMaking( t).then(x => 
    {
      this.DecisionMaking = x;
      Object.keys(this.DecisionMaking).forEach( key => 
      {
        this.DecisionMaking[key];
        let DecisionMakingtemp = new DecisionMaking();
        DecisionMakingtemp = this.DecisionMaking[key];
        this.DecisionMakings.push(DecisionMakingtemp);
      });
      this.dtTrigger.next();
    });
  }

  GetDecisionMakingByid(id) 
  {
     // begning of the permission portion
     this.IsDataIsInViewState=false;
     // end of the permission portion
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.LevelService.GetDecisionMakingByid( t,id).then(x => 
    {
      this.DecisionMaking = x;
      Object.keys(this.DecisionMaking).forEach( key => 
      {
        this.DecisionMaking[key];
        let DecisionMakingtemp = new DecisionMaking();
        DecisionMakingtemp = this.DecisionMaking[key];
        this.DecisionModel.DecisionId=DecisionMakingtemp.DecisionId;
        this.DecisionModel.DecisionName=DecisionMakingtemp.DecisionName;
      });
      // Begin for check Name duplication
      this.checkNameval= this.DecisionModel.DecisionName;
      this.CheckNamestatusedit=true;
      this.CheckNameExiststatus =true;
      this.Namecheck=""
      // End for check Name duplication
    });
  }

  ViewDecisionMakingByid(id) 
  {
     // begning of the permission portion
     this.IsDataIsInViewState=true;
     // end of the permission portion
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.LevelService.GetDecisionMakingByid( t,id).then(x => 
    {
      this.DecisionMaking = x;
      Object.keys(this.DecisionMaking).forEach( key => 
      {
        this.DecisionMaking[key];
        let DecisionMakingtemp = new DecisionMaking();
        DecisionMakingtemp = this.DecisionMaking[key];
        this.DecisionModel.DecisionId=DecisionMakingtemp.DecisionId;
        this.DecisionModel.DecisionName=DecisionMakingtemp.DecisionName;
      });
      // Begin for check Name duplication
      this.checkNameval= this.DecisionModel.DecisionName;
      this.CheckNamestatusedit=true;
      this.CheckNameExiststatus =true;
      this.Namecheck=""
      // End for check Name duplication
    });
  }
     
  saveDecisionMaking() :void
  {
    this.DisableSave=false;
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.DecisionModel.IsActive=true;
    if(this.DecisionModel.DecisionId > 0)
    {
      this.LevelService.UpdateDecisionMaking(t ,this.DecisionModel).subscribe(res=>
      {
        this.DecisionId=res;
        this.DecisionModel.DecisionId=0;  
        if(this.DecisionId>0)
        {
          localStorage.setItem('SuccessfullMessage', "Update Successfully");
          this.rout.navigateByUrl('/Levels/ManageLevels', {skipLocationChange: true}).then(()=>this.rout.navigate(["/Levels/ManageDescitionMakingLevels"]));
        }
      });                  
    }
    else
    {
      this.LevelService.AddDecisionMaking(t ,this.DecisionModel).subscribe(res=>
      {
        this.DecisionId=res;
        this.DecisionModel.DecisionId=0;              
        if(this.DecisionId>0)
        {
          localStorage.setItem('SuccessfullMessage', "Save Successfully");
          this.rout.navigateByUrl('/Levels/ManageLevels', {skipLocationChange: true}).then(()=>this.rout.navigate(["/Levels/ManageDescitionMakingLevels"]));
        }
      }); 
    }
  }

  DeleteDecisionMakingByid(DecisionId : number) :void
  {
    this._confirmation.create('Confirm to Deleate', 'Press Yes to Delete', this.settings)
    .subscribe((ans: ResolveEmit) => {
      if (ans.resolved == true) {

        let t =  localStorage.getItem('userToken');
        t = 'Bearer' + t;
        this.DecisionModel.IsActive=true;
        if( DecisionId > 0)
        {
          this.LevelService.DeleteDecisionMaking(t ,DecisionId).subscribe(res=>
          {
            this.DecisionId=res;
            this.DecisionModel.DecisionId=0;                                  
            if(this.DecisionId >0)
            {
              localStorage.setItem('SuccessfullMessage', "Deleted Successfully");
              this.rout.navigateByUrl('/Levels/ManageLevels', {skipLocationChange: true}).then(()=>this.rout.navigate(["/Levels/ManageDescitionMakingLevels"]));
            }
          });                  
        }
         
      }
    })




  }

  RefreshPage()
  {
    this.rout.navigateByUrl('/Levels/ManageLevels', {skipLocationChange: true}).then(()=>this.rout.navigate(["/Levels/ManageDescitionMakingLevels"]))
  }

  // begning of check duplicate Name
  CheckName()
  {
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    if(!this.CheckNamestatusedit)
    {
      this.LevelService.CheckDecisionMakingName( t, this.DecisionModel.DecisionName).then(x => 
      {
        this.checkName = x;
        if( this.checkName.length >0)
        {
          this.CheckNameExiststatus =false;
          this.Namecheck="Exist"
        }
        else
        {
          this.CheckNameExiststatus =true;
          this.Namecheck="";
        }
     });
    }
    else
    {
      if(this.DecisionModel.DecisionName !=this.checkNameval )
      {
        this.LevelService.CheckDecisionMakingName( t, this.DecisionModel.DecisionName).then(x => 
        {
          this.checkName = x;
          if( this.checkName.length >0)
          {
            this.CheckNameExiststatus =false;
            this.Namecheck="Exist"
          }
          else
          {
            this.CheckNameExiststatus =true;
            this.Namecheck="";
          }
        });
      }
      else
      {
        this.CheckNameExiststatus =true;
        this.Namecheck="";
      }
    }
  }
  // end of duplicate Name
}
