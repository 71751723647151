import { Model_CRM_ScheduleCall_Documents } from '../LEADCRM/Model_CRM_ScheduleCall_Documents'

export class Model_CRM_ScheduleCall {
    public SheduleCall_Id: number;
    public Company_Id: number;
    public Company: string;
    public OpportunityId: number;
    public OpportunityTitle: string;
    public Subject: string;
    public CallType: number;
    public CallTypeDescription: string;
    public Calldate: Date;
    public CallDateVal: string;
    public startTme: number;
    public Starttimeval: string;
    public Endtime: number;
    public endtimeval: string;
    public IsAllDay: boolean;
    public AllDay: string;
    public IsPriorityCall: boolean;
    public priorityCall: string;
    public ContactIds: number[];
    public ContactsName: string[];
    public Invited_attendees: number[];
    public Invited_Attendees: string[];
    public DispositionStatus: number;
    public DispositionStatusDesc: string;

    public Notes: string;
    public IsComplete: boolean;
    public Complete: string;
    public CreatedById: number;
    public CeratedByName: string;

    public Document: Model_CRM_ScheduleCall_Documents;

}