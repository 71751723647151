import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-manage-fine-name',
  templateUrl: './manage-fine-name.component.html',
  styleUrls: ['./manage-fine-name.component.css']
})
export class ManageFineNameComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
