import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs/Subject';
import { DataTableDirective } from 'angular-datatables';
import { ReplaySubject } from "rxjs/ReplaySubject";
import { UserService } from '../../shared/user.service';
import { UserMasterService } from '../../shared/HR_ADMIN/UserMaster/UserMasterService.service';
import { MenuMasterModel } from  '../../shared/HR_ADMIN/UserMaster/MenuMasterModel.Model';
// Begning Confirmation
import { ConfirmationService } from '@jaspero/ng2-confirmations';
import { ResolveEmit } from '../../../app/resolve-emit';
import { ConfirmSettings } from '../../../app/confirm-settings';
import { Menu } from '../../shared/menu.model';
// end of Confirmation


@Component({
  selector: 'app-menu-master',
  templateUrl: './menu-master.component.html',
  styleUrls: ['./menu-master.component.css']
})
export class MenuMasterComponent implements OnInit 
{
  MenuMasterModel : MenuMasterModel= new MenuMasterModel();
  userClaims: any;
  MenuMasters: MenuMasterModel[] = [];
  MenuMaster: any;

  MenuMasterDDLs: MenuMasterModel[] = [];
  MenuMasterDDL: any;

  ModuleMasters: MenuMasterModel[] = [];
  ModuleMaster: any;

  Result : string;
  SuccessStatus:boolean=false;
  SuccessMessage: string ="";
  public tableWidget: any;
  MenuId : any;
  base64textString : string;
  tempMenuMasteradd : MenuMasterModel =new MenuMasterModel();

  // Begning Permission Portion    
  SelectedMenu:Menu;
  IsDataIsInViewState : boolean=false;
  CanViewState : boolean= false;
  DisableSave :boolean=false;
  // End of the permission portion
  //check for duplicate component Name
  CheckNameExiststatus : boolean=null;  
  CheckNamestatusedit : boolean =false;
  Namecheck : string ="";
  checkName : any;
  checkNameval : string ="";
  IsHaveChild: boolean=false;
  //check for duplicate component Name
  //image
  filetoupload: File=null;

  constructor(private UserMasterService: UserMasterService, private rout: Router,  private _confirmation: ConfirmationService, private userService: UserService) { }
  
  @ViewChild(DataTableDirective, {static: false})
  setDtOptions: DataTables.Settings = {};
  cardDtOptions: DataTables.Settings = {};
  dtElement: DataTableDirective;
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();

  ngOnInit() 
  {
    //Begin Permission Portion
    this.SelectedMenu=this.userService.SelectedMenu;
    if(this.SelectedMenu !=undefined)
    {
      localStorage.setItem('selectedmenu',JSON.stringify(this.SelectedMenu));
      this.rout.navigate([this.SelectedMenu.RouteName]);
    }
    else
    {
      if(JSON.parse(localStorage.getItem('selectedmenu'))!=undefined)
      {
        this.SelectedMenu = JSON.parse(localStorage.getItem('selectedmenu'));
        this.rout.navigate([this.SelectedMenu.RouteName]);
      }
    }    
    if(this.SelectedMenu.CanAdd)
    {
    this.IsDataIsInViewState=false;
    }
    else
    {
      this.IsDataIsInViewState=true;
    }
    if(this.SelectedMenu.CanView)
    {
    this.CanViewState=false;
    }
    else
    {
      this.CanViewState=true;
    }
    //End of the peremission portion
    this.DisableSave=true;
    this.dtOptions = 
    {
      pagingType: 'full_numbers',
      pageLength: 4
    };
    if(localStorage.getItem('SuccessfullMessage'))
    {
      this.SuccessStatus=true;
      this.SuccessMessage =localStorage.getItem('SuccessfullMessage')
      localStorage.removeItem('SuccessfullMessage');
    }  
  }

  ngAfterViewInit(): void 
  {
    this.GetMenuMaster();
    this.GetModuleMaster();
    this.MenuMasterModel.ParentId=0;
    this.MenuMasterModel.ModuleId=0;
    this.IsHaveChild=false;
    this.MenuMasterModel.IconUrlvalidfile=true;
    this.MenuMasterModel.SmallIconUrlvalidfile=true;
    this.dtOptions = 
    {
      pagingType: 'full_numbers',
      pageLength: 4
    };
  }

  settings: ConfirmSettings | any = 
  {
    overlay: true,
    overlayClickToClose: true,
    showCloseButton: true,
    confirmText: 'Yes',
    declineText: 'No'
  };
  
  HaveChildChange()
  {
    if(this.MenuMasterModel.HaveChild==true)
    {
      this.MenuMasterModel.HaveChild =true;
      this.MenuMasterModel.ParentId=1;
    }
    else
    {
      this.MenuMasterModel.HaveChild =false;
      this.MenuMasterModel.ParentId=0;
    }
  }

  GetMenuMaster()
  {
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.UserMasterService.GetMenuMaster( t).then(x => 
    {
      this.MenuMaster = x;
      Object.keys(this.MenuMaster).forEach( key => 
      {
        this.MenuMaster[key];
        let MenuMastertemp = new MenuMasterModel();
        MenuMastertemp = this.MenuMaster[key];
        this.MenuMasters.push(MenuMastertemp);
      });
      this.dtTrigger.next();
    });
  }

  onModuleChange(ModuleId) 
  {
    this.MenuMasterDDLs=[];
    if(ModuleId!=0)
    {
      this.GetMenuMasterForDDL(ModuleId);
      if(this.MenuMasterModel.HaveChild==false)
      {
        this.MenuMasterModel.ParentId=0;
      }
    }
    else
    {
      this.GetMenuMasterForDDL(ModuleId);
      if(this.MenuMasterModel.HaveChild==false)
      {
        this.MenuMasterModel.ParentId=0;
      }
    }
  }

  GetMenuMasterForDDL(ModuleId)
  {
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.UserMasterService.GetMenuMasterForDDLByModuleId( t,ModuleId).then(x => 
    {
      this.MenuMasterDDL = x;
      Object.keys(this.MenuMasterDDL).forEach( key => 
      {
        this.MenuMasterDDL[key];
        let MenuMasterDDLtemp = new MenuMasterModel();
        MenuMasterDDLtemp = this.MenuMasterDDL[key];
        this.MenuMasterDDLs.push(MenuMasterDDLtemp);
      });
    });
  }

  GetModuleMaster()
  {
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.UserMasterService.GetModuleMaster( t).then(x => 
    {
      this.ModuleMaster = x;
      Object.keys(this.ModuleMaster).forEach( key => 
      {
        this.ModuleMaster[key];
        let MenuMastertemp = new MenuMasterModel();
        MenuMastertemp = this.ModuleMaster[key];
        this.ModuleMasters.push(MenuMastertemp);
      });
    });
  }

  GetMenuMasterById(id) 
  { 
    // Begning of the permission portion
    this.IsDataIsInViewState=false;
    // End of the permision portion
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.UserMasterService.GetMenuMasterById( t,id).then(x => 
    {
      this.MenuId = x;
      Object.keys(this.MenuId).forEach( key => 
      {
        // tslint:disable-next-line:no-unused-expression
        this.MenuId[key];
        let MenuMastertemp = new MenuMasterModel();
        MenuMastertemp = this.MenuId[key];
        this.MenuMasterModel.MenuID=MenuMastertemp.MenuID;
        this.MenuMasterModel.ModuleId=MenuMastertemp.ModuleId;
        this.MenuMasterModel.MenuTitle=MenuMastertemp.MenuTitle;
        this.MenuMasterModel.HaveChild=MenuMastertemp.HaveChild;
        this.MenuMasterModel.ParentId=MenuMastertemp.ParentId;
        this.MenuMasterModel.RouteName=MenuMastertemp.RouteName;
        this.MenuMasterModel.IsActive=MenuMastertemp.IsActive;
        this.MenuMasterModel.DefaultPage=MenuMastertemp.DefaultPage;
        this.MenuMasterModel.IconUrl=MenuMastertemp.IconUrl;
        this.MenuMasterModel.IconUrlLogoType=MenuMastertemp.IconUrlLogoType;
        this.MenuMasterModel.IconUrlImage=MenuMastertemp.IconUrlImage;
        this.MenuMasterModel.SmallIconUrl=MenuMastertemp.SmallIconUrl;
        this.MenuMasterModel.SmallIconUrlLogoType=MenuMastertemp.SmallIconUrlLogoType;
        this.MenuMasterModel.SmallIconUrlImage=MenuMastertemp.SmallIconUrlImage;
      });
      this.GetMenuMasterForDDL(this.MenuMasterModel.ModuleId);
      // Begin for check Name duplication
      this.checkNameval= this.MenuMasterModel.MenuTitle;
      this.CheckNamestatusedit=true;
      this.CheckNameExiststatus =true;
      this.Namecheck=""
      // End for check Name duplication
    });
  }

  ViewMenuMasterById(id) 
  {
    //begin permission
    this.IsDataIsInViewState=true;
    //end permission
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.UserMasterService.GetMenuMasterById( t,id).then(x => 
    {
      this.MenuId = x;
      Object.keys(this.MenuId).forEach( key => 
      {
        this.MenuId[key];
        let MenuMastertemp = new MenuMasterModel();
        MenuMastertemp = this.MenuId[key];
        this.MenuMasterModel.MenuID=MenuMastertemp.MenuID;
        this.MenuMasterModel.ModuleId=MenuMastertemp.ModuleId;
        this.MenuMasterModel.MenuTitle=MenuMastertemp.MenuTitle;
        this.MenuMasterModel.HaveChild=MenuMastertemp.HaveChild;
        this.MenuMasterModel.ParentId=MenuMastertemp.ParentId;
        this.MenuMasterModel.RouteName=MenuMastertemp.RouteName;
        this.MenuMasterModel.IsActive=MenuMastertemp.IsActive;
        this.MenuMasterModel.DefaultPage=MenuMastertemp.DefaultPage;

        this.MenuMasterModel.IconUrl=MenuMastertemp.IconUrl;
        this.MenuMasterModel.IconUrlLogoType=MenuMastertemp.IconUrlLogoType;
        this.MenuMasterModel.IconUrlImage=MenuMastertemp.IconUrlImage;
        this.MenuMasterModel.SmallIconUrl=MenuMastertemp.SmallIconUrl;
        this.MenuMasterModel.SmallIconUrlLogoType=MenuMastertemp.SmallIconUrlLogoType;
        this.MenuMasterModel.SmallIconUrlImage=MenuMastertemp.SmallIconUrlImage;
      });
      // Begin for check Name duplication
      this.checkNameval= this.MenuMasterModel.MenuTitle;
      this.CheckNamestatusedit=true;
      this.CheckNameExiststatus =true;
      this.Namecheck=""
      // End for check Name duplication
    });
  }

  saveMenuMaster() :void
  {
    this.DisableSave=false;
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.MenuMasterModel.IsActive=true;
    if(this.MenuMasterModel.MenuID > 0)
    {
      this.UserMasterService.UpdateMenuMaster(t ,this.MenuMasterModel).subscribe(res=>
      {
        this.MenuId=res;
        this.MenuMasterModel.MenuID=0;  
        if(this.MenuId>0)
        {
          localStorage.setItem('SuccessfullMessage', "Update Successfully");
          this.rout.navigateByUrl('/ManageUserMaster/ModuleMaster', {skipLocationChange: true}).then(()=>this.rout.navigate(["/ManageUserMaster/MenuMaster"]));
        }
      });                  
    }
    else
    {
      this.UserMasterService.AddMenuMaster(t ,this.MenuMasterModel).subscribe(res=>
      {
        this.MenuId=res;
        this.MenuMasterModel.MenuID=0;              
        if(this.MenuId>0)
        {
          localStorage.setItem('SuccessfullMessage', "Save Successfully");
          this.rout.navigateByUrl('/ManageUserMaster/ModuleMaster', {skipLocationChange: true}).then(()=>this.rout.navigate(["/ManageUserMaster/MenuMaster"]));
        }
      }); 
    }
  }

  DeleteMenuMasterById(MenuId : number) :void
  {
    if(this.SelectedMenu.CanAdd)
    {
     this.IsDataIsInViewState=false;
    }
    else
    {
      this.IsDataIsInViewState=true;
    }
    this._confirmation.create('Confirm to Deleate', 'Press Yes to Delete', this.settings)
      .subscribe((ans: ResolveEmit) => 
      {
        if (ans.resolved == true) 
        {
          let t =  localStorage.getItem('userToken');
          t = 'Bearer' + t;
          this.MenuMasterModel.IsActive=true;
          if( MenuId > 0)
          {
            this.UserMasterService.DeleteMenuMaster(t ,MenuId).subscribe(res=>
            {
              this.MenuId=res;
              this.MenuMasterModel.MenuID=0;                                  
              if(this.MenuId >0)
              {
                localStorage.setItem('SuccessfullMessage', "Deleted Successfully");
                this.rout.navigateByUrl('/ManageUserMaster/ModuleMaster', {skipLocationChange: true}).then(()=>this.rout.navigate(["/ManageUserMaster/MenuMaster"]));
              }   
            });                  
          }
        }
      })
  }
 
  // begning of check duplicate componentcode
  CheckName()
  {
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    if(!this.CheckNamestatusedit)
    {
      this.UserMasterService.CheckMenuName( t, this.MenuMasterModel.ModuleName).then(x => 
      {
        this.checkName = x;
        if( this.checkName.length >0)
        {
          this.CheckNameExiststatus =false;
          this.Namecheck="Exist"
        }
        else
        {
          this.CheckNameExiststatus =true;
          this.Namecheck="";
        }
     });
    }
    else
    {
      if(this.MenuMasterModel.ModuleName !=this.checkNameval )
      {
        this.UserMasterService.CheckMenuName( t, this.MenuMasterModel.ModuleName).then(x => 
        {
          this.checkName = x;
          if( this.checkName.length >0)
          {
            this.CheckNameExiststatus =false;
            this.Namecheck="Exist"
          }
          else
          {
            this.CheckNameExiststatus =true;
            this.Namecheck="";
          }
        });
      }
      else
      {
        this.CheckNameExiststatus =true;
        this.Namecheck="";
      }
    }
  }
  // end of duplicate Name

  RefreshPage()
  {
    this.rout.navigateByUrl('/ManageUserMaster/ModuleMaster', {skipLocationChange: true}).then(()=>this.rout.navigate(["/ManageUserMaster/MenuMaster"]))
  }

  //Image
  handleFileinput(files : FileList)
  {
    if(files.length>0)
    {
      this.filetoupload=files.item(0);
      var file = files[0];
      let filesize = this.filetoupload.size; 
      let filetyotypearray = this.filetoupload.type.split("/");
      this.MenuMasterModel.IconUrlLogoType= filetyotypearray[1];
      this.MenuMasterModel.Logodoctype= filetyotypearray[0];
      if(this.MenuMasterModel.Logodoctype=="image")
      {
        this.MenuMasterModel.IconUrlvalidfile=true; 
      }
      else
      {
        this.MenuMasterModel.IconUrlvalidfile=false; 
      }
      if(filesize > 500000)
      {
        this.MenuMasterModel.IconUrlvalidfile=false; 
      }
      if(!((this.MenuMasterModel.IconUrlLogoType=='jpeg') || (this.MenuMasterModel.IconUrlLogoType=='png') || (this.MenuMasterModel.IconUrlLogoType=='svg')|| (this.MenuMasterModel.IconUrlLogoType=='gif') || (this.MenuMasterModel.IconUrlLogoType=='wbmp')))
      {
        this.MenuMasterModel.IconUrlvalidfile=false; 
      }
      var reader : FileReader= new FileReader();
      reader.onload=(event:any)=>
      {
        this.MenuMasterModel.IconUrl=event.target.result;
      } 
      reader.onloadend = (e) => 
      {
        this.MenuMasterModel.Islogochange=true;
        this.MenuMasterModel.IconUrlImage = String(reader.result);
      }
      reader.readAsDataURL(file);   
    }
    else
    {
      this.MenuMasterModel.IconUrl=null;
    }
  }

  SmallUrlFileinput(files : FileList)
  {
    if(files.length>0)
    {
      this.filetoupload=files.item(0);
      var file = files[0];
      let filesize = this.filetoupload.size; 
      let filetyotypearray = this.filetoupload.type.split("/");
      this.MenuMasterModel.SmallIconUrlLogoType= filetyotypearray[1];
      this.MenuMasterModel.Logodoctype= filetyotypearray[0];
      if(this.MenuMasterModel.Logodoctype=="image")
      {
        this.MenuMasterModel.SmallIconUrlvalidfile=true; 
      }
      else
      {
        this.MenuMasterModel.SmallIconUrlvalidfile=false; 
      }
      if(filesize > 500000)
      {
        this.MenuMasterModel.SmallIconUrlvalidfile=false; 
      }
      if(!((this.MenuMasterModel.SmallIconUrlLogoType=='jpeg') || (this.MenuMasterModel.SmallIconUrlLogoType=='png') || (this.MenuMasterModel.SmallIconUrlLogoType=='svg')|| (this.MenuMasterModel.SmallIconUrlLogoType=='gif') || (this.MenuMasterModel.SmallIconUrlLogoType=='wbmp')))
      {
        this.MenuMasterModel.SmallIconUrlvalidfile=false; 
      }
      var reader : FileReader= new FileReader();
      reader.onload=(event:any)=>
      {
        this.MenuMasterModel.SmallIconUrl=event.target.result;
      } 
      reader.onloadend = (e) => 
      {
        this.MenuMasterModel.IsSmalllogochange=true;
        this.MenuMasterModel.SmallIconUrlImage = String(reader.result);
      }
      reader.readAsDataURL(file);   
    }
    else
    {
      this.MenuMasterModel.SmallIconUrl=null;
    }
  }

  public readFile(fileToRead: File)
  {
    let base64Observable = new ReplaySubject(1);
    let fileReader = new FileReader();
    fileReader.onload = event => 
    {
      base64Observable.next(fileReader.result);
    };
    fileReader.readAsDataURL(fileToRead);
    return base64Observable;
  }
}
