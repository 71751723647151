

export class Model_CRM_MoreInformation {

    public OppertunityId: number;
    public OppertunityMoreInformationId: number;
    public NumberOfPax: number;
    public Retention: number;
    public CreditTime: string;
    public CloseDate: Date;
    public CloseDateval: string;
    public QueryNumber: string;
    public AdvancePayment: number;
    public DomesticInternational: string;
    public DomesticInternationalId: number;
    public GPFigure: number;
    public Reason: string;
    public FitMiceName: string;
    public FitMiceId: number;
    public Destination: string;
    public DepertureDate: Date
    public DepertureDateval: string;
    public TimePeriod: string;
    public Source: string;
    public CreatedDate: Date;
    public IsActive: boolean
    public CreatedBy: number;
    public UpdateDate: Date;
    public UpdatedBy: number;
    public IsDepertureDateValid: boolean;
    public IsCloseDateValid: boolean;
    public AdvanceId: number;
    public SourceQueryId: number;



}       