<div class="row">
    <div class="col-lg-4">
        <div class="col-body-border-right">
            <div class="form-group row">
                <label  class="col-sm-4 col-form-label clear-padding-right">code</label>
                <div class="col-sm-7 clear-left-padding">
                    <input type="text" disabled="disabled" [(ngModel)]="EmployeeMasterService.SharedEmployees.EmployeeCode" id ="EmployeeCode" name="EmployeeCode" class="form-control form-control-custom form-control-custom-new">
                </div>
            </div>
        </div>
    </div>
    <div class="col-lg-4">
        <div class="form-group row">
            <label  class="col-sm-4 col-form-label clear-padding-right">Name</label>
            <div class="col-sm-7 clear-left-padding">
                <input type="text" disabled="disabled" [(ngModel)]="EmployeeMasterService.SharedEmployees.FullName" id ="FullName" name="FullName" class="form-control form-control-custom form-control-custom-new">
            </div>
        </div>
        </div>
</div>

<fieldset class="body-form-control">
    <app-dialog [(visible)]="this.SuccessStatus">
        <span class="successmsg">{{this.SuccessMessage}}</span>
    </app-dialog>
  
    <legend class="legend--">Employee Nominee Details</legend>
    <form #EmployeeNomineeDetailForm="ngForm" (ngSubmit)="SaveEmpolyeeNomineeDetails()">
    <div lass="row" >
      <table id="tblComponent" class="table table-striped table-bordered text-left table-new row-border hover">
          <thead class="thead-blue text-center">
              <tr>
                  <th style="width: 10%;">Relation </th>                 
                  <th style="width: 15%;">Name</th>  
                  <th style="width: 15%;">Address</th>
                  <th style="width: 10%;">Date Of Birth</th> 
                  <th style="width: 10%;">PF Share</th>   
                  <th style="width: 10%;">Gratuity Share</th>
                  <th style="width: 10%;">Pesion Share</th>
              </tr>
          </thead>
          <tbody>
              <tr *ngFor="let Nomini of this.EmployeeMasterService.SharedEmployees.EmployeeNomineeDetailModel, let i = index">
                  <td >
                      <select  [(ngModel)]="Nomini.NomineeRelationId" tabindex="15" id ="NomineeRelationId_{{i}}" class="form-control" name="NomineeRelationId_{{i}}" #NomineeRelationId="ngModel">
                          <option [value]="0" [disabled]=true >Select Relation</option>
                          <option *ngFor="let f of Relations" [value]="f.RelationshipId">{{f.RelationshipName}}</option>
                        </select>
                  </td>
                  <td><input type="text" [(ngModel)]="Nomini.NomineeName" maxlength="100"   id ="NomineeName_{{i}}"  name="NomineeName_{{i}}" #NomineeName="ngModel"  class="form-control form-control-custom form-control-custom-new"  >                    
                </td>
                  <td >
                      <input type="text" [(ngModel)]="Nomini.NomineeAddress" maxlength="500"   id ="NomineeAddress_{{i}}"  name="NomineeAddress_{{i}}" #NomineeAddress="ngModel"  class="form-control form-control-custom form-control-custom-new"  >                   
                  </td>
                  <td >                   
                    <input class="form-control"  style="float:none" placeholder="Select date" ngx-mydatepicker name="NomineeDOB_{{i}}"
                    [(ngModel)]="Nomini.NomineeDOB" [options]="myOptions" #dp="ngx-mydatepicker" (dateChanged)="OnChangeDOBNominee($event,i)"/>
                    <span class="mt-2">
                          <a class="btn btn-primary pl-10 pr-10 pt-1 pb-1" (click)="dp.toggleCalendar()">
                              <i style="color:#fff" class="fas fa-calendar-alt fa-lg"></i>
                          </a>
                          <a class="btn btn-danger pl-10 pr-10 pt-1 pb-1" (click)="dp.clearDate()">
                              <i style="color:#fff" class="far fa-calendar-times fa-lg"></i>
                          </a>
                      </span>
                  </td>
                  <td >
                      <input type="text" [(ngModel)]="Nomini.PFSharePercentage" id ="PFSharePercentage_{{i}}" name="PFSharePercentage_{{i}}" #PFSharePercentage="ngModel" class="form-control form-control-custom form-control-custom-new"  >                   
                  </td>
                  <td >
                      <input type="text" [(ngModel)]="Nomini.GratuitySharePercentage" id ="GratuitySharePercentage_{{i}}" name="GratuitySharePercentage_{{i}}" #GratuitySharePercentage="ngModel" class="form-control form-control-custom form-control-custom-new"  >                   
                  </td>
                  <td >
                      <input type="text" [(ngModel)]="Nomini.PensionSharePercentage" id ="PensionSharePercentage_{{i}}" name="PensionSharePercentage_{{i}}" #PensionSharePercentage="ngModel" class="form-control form-control-custom form-control-custom-new"  >                   
                  </td>
              </tr>
          </tbody>
      </table>
        <div class="row float-right">
            <div  class=" col-auto text-center" *ngIf="this.EmployeeMasterService.SharedEmployees.EmployeeId>0" >
                <button type="submit" tabindex="22" class="btn btn-primary save-button" [disabled]="!(EmployeeNomineeDetailForm.valid && this.DisableSave==true)" >Save & Next</button>  
            </div>
            <div class=" col-auto text-center">
                <button type="button" tabindex="23" class="btn btn-danger cancle-button" (click)="RefreshPage()">Cancel</button>
            </div>
            <div class=" col-auto text-center">
                <button type="button" tabindex="23" class="btn btn-primary pl-10 pr-10 pt-1 pb-1" (click)="AddnweRow()">Add Row </button>
            </div>
        </div>
    </div>
  </form>
</fieldset>