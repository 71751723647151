export class Model_CRM_Opportunity_Document {
    public DocumentId: number;
    public OpportunityId: number;
    public File: string;
    public DocumentActualName: string;
    public DocumentType: string;
    public DocumentExtension: string;
    public DocumentName: string;
    public DocumentSize: string;
    public IsActive: boolean;

}