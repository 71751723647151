<div class="body-content-area tabcontent" id="link1">
  <div class="row">
    <div class="col-lg-9">
      <div class="row">
        <div class="col-lg-1 low-padding text-center">
          <!-- <img *ngIf="this.SelectedMenu.Image!=null" src={{this.SelectedMenu.Image}} class="body-icon" alt="Icon"/> -->
        </div>
        <div class="col-lg-10 clear-padding">
          <span class="page-title">Manage Expense Head</span>
          <small class="page-description"></small>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="!(this.CanViewState)">
    <div style=" text-align: right;">
      <button (click)="ADDClick(template)" type="button" class="btn btn-primary Add-button">Add New</button> 
    </div>
    <fieldset class="body-form-control">
      <legend class="legend--">Expense Details</legend>
      <table id="tblExpenseHead" class="table table-striped table-bordered text-center table-new row-border hover">
        <thead class="thead-blue text-center">
          <tr>
            <th>Sl No.</th>
            <th>Expense Head Code</th>
            <th>Expense Head Name</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let EH of TourExpenseHeads, let i = index">
            <td scope="row">{{i+1}}</td>
            <td class="text-left">{{EH.ExpenseHeadCode}}</td>
            <td class="text-left">{{EH.ExpenseHeadName}}</td>
            <td>
              <button *ngIf="this.SelectedMenu.CanEdit" type="button" (click)="GetExpenseHeadById(EH.ExpenseHeadId,template)" class="table-edit-btn"><i class="fas fa-edit"></i> </button> 
              <button *ngIf="this.SelectedMenu.CanDelete" type="button" (click)="DeleteExpenseHeadById(EH.ExpenseHeadId,template)"class="table-remove-btn"><i class="fas fa-trash-alt"></i></button>
              <button *ngIf="this.SelectedMenu.CanView" type="button" (click)="ViewExpenseHeadById(EH.ExpenseHeadId,template)" class="table-view-button" type="button" ><i class="fas fa-eye"></i></button>
            </td>
          </tr>
        </tbody>
        <tfoot class="thead-blue text-center">
          <tr>
            <th></th>
            <th></th>
            <th></th>
            <th></th>
          </tr>
        </tfoot>
      </table>
    </fieldset>
  
    <div style=" -moz-box-align: center; width: 1500px;">
      <ng-template #template>
        <div class="modal-header" style="background: #2a9bd7; max-width: 1500px;"> 
          <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()" style="color:#fff;">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div  style="text-align: center; max-width: 1500px;">
          <div *ngIf="SaveMsg" style="color: #dc3545; text-align: center;font-size: 15px; font-weight: bold;">Save Successfully</div>
          <div *ngIf="UpdateMsg" style="color: #dc3545; text-align: center;font-size: 15px; font-weight: bold;">Update Successfully</div>
          <div *ngIf="DeleteMsg" style="color: #dc3545; text-align: center;font-size: 15px; font-weight: bold;">Deleted Successfully</div>
          <div style=" width: 100%;" *ngIf="ShowPopup">
            <fieldset class="body-form-control">
              <legend class="legend--">Expense Head Master</legend>
              <form #ExpenseHeadForm="ngForm" (ngSubmit)="saveExpenseHead(template)">
                <div class="row">
                  <div class="col-lg-4">
                    <div class="col-body-border-right">
                      <div class="form-group row">
                        <label class="col-sm-4 col-form-label clear-padding-right">Head Code</label>
                        <div class="col-sm-7 clear-left-padding">
                          <input type="text" [(ngModel)]="this.TourExpenseHeadModel.ExpenseHeadCode" tabindex="1" maxlength="50" id ="ExpenseHeadCode" name="ExpenseHeadCode" #ExpenseHeadCode="ngModel" required class="form-control form-control-custom form-control-custom-new">
                        </div>
                        <div *ngIf="ExpenseHeadCode.invalid && (ExpenseHeadCode.dirty || ExpenseHeadCode.touched)" class="alert alert-danger">
                          <div *ngIf="ExpenseHeadCode.errors.required">Expense Head Code is required.</div>
                          <div *ngIf="ExpenseHeadCode.errors.maxlength">Expense Head Code not more than 50 characters</div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-4">
                    <div class="form-group row">
                      <label class="col-sm-4 col-form-label clear-padding-right">Head Name</label>
                      <div class="col-sm-7 clear-left-padding">
                        <input type="text" [(ngModel)]="this.TourExpenseHeadModel.ExpenseHeadName" tabindex="2" (blur)="checkExpenseHeadName()" maxlength="1000" id ="ExpenseHeadName" name="ExpenseHeadName" #ExpenseHeadName="ngModel" required class="form-control form-control-custom form-control-custom-new">
                      </div>
                      <div *ngIf="this.ExpenseHeadNamecheck.length > 0" class="alert alert-danger">Expense Head Name is exist</div>
                      <div *ngIf="ExpenseHeadName.invalid && (ExpenseHeadName.dirty || ExpenseHeadName.touched)" class="alert alert-danger">
                        <div *ngIf="ExpenseHeadName.errors.required">Expense Head Name is required.</div>
                        <div *ngIf="ExpenseHeadName.errors.maxlength">Expense Head Name is not more than 1000 characters</div>
                      </div>
                    </div>
                    <div class="form-group row">
                      <label *ngIf="!(this.IsDataIsInViewState)" class="col-sm-6 col-form-label text-center clear-padding-right">
                        <button type="submit" tabindex="4" class="btn btn-primary save-button" [disabled]="!(ExpenseHeadForm.valid && this.DisableSave==true && this.TourExpenseHeadModel.ExpenseHeadCode != '' && this.TourExpenseHeadModel.ExpenseHeadName != ''&& this.CheckExpenseHeadName ==true)" >Save</button>  
                      </label>
                    </div>
                  </div>
                </div>
              </form>
            </fieldset>
          </div>
          <button type="button" aria-label="Close" (click)="modalRef.hide()" style="background: #2a9bd7; outline: 0px none; border: 0px none; color: #fff; padding: 5px 10px; border-radius: 3px;">
            <span>Close</span>
          </button>             
        </div>
      </ng-template>
    </div>
  </div>
</div>