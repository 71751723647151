import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs/Subject'
import { Menu } from '../../shared/menu.model';
import { ForexCrmService } from '../../shared/CRM/FOREX CRM/ForexCrmService';
import { Model_Forex_CRM_CompanyType } from '../../shared/CRM/FOREX CRM/Model_Forex_CRM_CompanyType';
import { UserService } from '../../shared/user.service';
import { Model_Forex_CRM_OrganisationType } from '../../shared/CRM/FOREX CRM/Model_Forex_CRM_OrganisationType';
import { Model_ForexCRM_CorporateDetails } from '../../shared/CRM/FOREX CRM/Model_ForexCRM_CorporateDetails';
import { DataTableDirective } from 'angular-datatables';
import { ConfirmSettings } from '@jaspero/ng2-confirmations/src/interfaces/confirm-settings';
import { ConfirmationService } from '@jaspero/ng2-confirmations';
import { ResolveEmit } from '@jaspero/ng2-confirmations/src/interfaces/resolve-emit';
import { Model_ForexCRM_LocationServed } from '../../shared/CRM/FOREX CRM/Model_Forex_CRM_LocationServed';
import { Model_Forex_CRM_Documents } from 'src/app/shared/CRM/FOREX CRM/Model_Forex_CRM_Documents';
import { ModalOptions,BsModalRef, BsModalService  } from 'ngx-bootstrap/modal'

@Component({
  selector: 'app-forex-crm',
  templateUrl: './forex-crm.component.html',
  styleUrls: ['./forex-crm.component.css']
})

export class ForexCrmComponent implements OnInit {


  
  //ValidationGlobaly :ValidationClass  // Validation Class.;
  FormViewShow: boolean = false;
  DisableSave: boolean = false;
  CanViewState: boolean = false;
  CanSaveButtonView: boolean = false;
  SelectedMenu: Menu;
  ForexCRMId: any;
  IsDataIsInViewState: boolean;

  DeleteForexCRMByID: number = 0;
  //grid
  forexcrmlist: Model_ForexCRM_CorporateDetails[] = [];
  ForexCRM: any;
  // ForexCRMCorporate(arg0: any): any {
  //   throw new Error("Method not implemented.");
  // }

  locationlist: Model_ForexCRM_LocationServed[] = [];
  Forex_Location: any;
  SelectedLocation: any[] = [];
  //locationList: Model_ForexCRM_LocationServed[] = [];
  //LocationName: any;
  CRMLocation: {};
  LocationArray: any[] = [];
  LocationList: any[];
  LocationSettings = {};

  Forex_CRM_Location_Served_Model: Model_ForexCRM_LocationServed;


  //Anniversary date
  validdate: boolean;


  // Begin success message popup
  SuccessStatus: boolean;
  SuccessMessage: string;
  StringTodate: string;
  // end success message popup


  OrganisationType: any;
  organisationtypelist: Model_Forex_CRM_OrganisationType[] = [];
  CRM_Forex_Organisation_Type: Model_Forex_CRM_OrganisationType;

  @ViewChild(DataTableDirective, {static: false})
  setDtOptions: DataTables.Settings = {};
  cardDtOptions: DataTables.Settings = {};
  dtElement: DataTableDirective;
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();


  // For Company Type Dropdown
  CompanyType: any;
  companytypelist: Model_Forex_CRM_CompanyType[] = [];
  CRM_Forex_Company_Type: Model_Forex_CRM_CompanyType;
  // Save Region Model Decleration 
  Forex_CRM_CorporateDetails_Model: Model_ForexCRM_CorporateDetails = new Model_ForexCRM_CorporateDetails();
  // ForexCRMID: number;
  ForexCrmId: number;
  

  // File Upload 

  // Uploadfile: any;
  // Documents:Model_ForexCRM_CorporateDetails;
    FileUploads : Model_Forex_CRM_Documents[] = [];
    Forex_CRM_Documents_Model : Model_Forex_CRM_Documents;
   document: any;
   public modalRef: BsModalRef;
   public modelopt: ModalOptions;
   ShowPopup: boolean = false;
   ApprovedMsg: boolean = false;
   docId: any;
   allfiles: any = [];
   images: Model_Forex_CRM_Documents[] = [];
  FileUploadsDeletes: Model_Forex_CRM_Documents[] = [];
  Imge: Model_Forex_CRM_Documents;
 
  //Forex_CRM_CorporateDetails_Model:Model_ForexCRM_CorporateDetails;


  constructor(private Forex_CRM_Service: ForexCrmService, private rout: Router, private userService: UserService, private _confirmation: ConfirmationService,private modalService: BsModalService)
  
  {

    this.CRM_Forex_Company_Type = new Model_Forex_CRM_CompanyType();
    // this.Forex_CRM_CorporateDetails_Model.IsAnniversaryDateValid = true;
    this.Forex_CRM_CorporateDetails_Model.IsAnniversaryDateMoreinfo = true;
    this.Forex_CRM_CorporateDetails_Model.IsDOBMoreinfo = true;
    this.Forex_CRM_CorporateDetails_Model.IsNextMeetingDate = true;
  }

  ngOnInit() 
  {
    // Begning Permission Portion 
    this.SelectedMenu = this.userService.SelectedMenu;
    if (this.SelectedMenu != undefined) {
      localStorage.setItem('selectedmenu', JSON.stringify(this.SelectedMenu));
      this.rout.navigate([this.SelectedMenu.RouteName]);
    }
    else {
      if (JSON.parse(localStorage.getItem('selectedmenu')) != undefined) {
        this.SelectedMenu = JSON.parse(localStorage.getItem('selectedmenu'));
        this.rout.navigate([this.SelectedMenu.RouteName]);
      }
    }
    if (this.SelectedMenu.CanAdd) {
      this.IsDataIsInViewState = false;
      this.CanSaveButtonView = true;
    }
    else {
      this.IsDataIsInViewState = true;
    }
    if (this.SelectedMenu.CanView) {
      this.CanViewState = false;
    }
    else {
      this.CanViewState = true;
    }

    this.DisableSave = true;
    this.FormViewShow = false;
    // this.Forex_CRM_CorporateDetails_Model = new Model_ForexCRM_CorporateDetails();
    this.CRM_Forex_Organisation_Type = new Model_Forex_CRM_OrganisationType();
    this.Forex_CRM_CorporateDetails_Model = new Model_ForexCRM_CorporateDetails();
    this.Forex_CRM_CorporateDetails_Model.ForexCrmId = 0;
    if (this.Forex_CRM_CorporateDetails_Model.ForexCrmId == 0)
    {
      this.Forex_CRM_CorporateDetails_Model.OrganizationTypeId = 0;
      this.Forex_CRM_CorporateDetails_Model.CompanyTypeId = 0;
      this.Forex_CRM_CorporateDetails_Model.IsMeetingDetailsPositive = false;
      this.Forex_CRM_CorporateDetails_Model.IsMeetingDetailsHODSupportRequired = false;
      this.Forex_CRM_CorporateDetails_Model.IsMeetingDetailsIsNegetive = false;
    }
    
    this.LocationSettings =
    {
      singleSelection: false,
      text: 'Select Location',
      enableSearchFilter: true,
      showCheckbox: true
    }

  }

  ngAfterViewInit(): void {

    this.GetForexCrmCompanyType();
    this.GetForexCrmOrganisationType();
    this.GetForexCrmGrid();
    this.GetLocation();
   
    this.dtOptions =
      {
        pagingType: 'full_numbers',
        pageLength: 4
      };

    if (localStorage.getItem('SuccessfullMessage')) {
      this.SuccessStatus = true;
      this.SuccessMessage = localStorage.getItem('SuccessfullMessage')
      localStorage.removeItem('SuccessfullMessage');
    }
    else {
      this.SuccessStatus = false;
    }
  }

  settings: ConfirmSettings | any =
    {
      overlay: true,
      overlayClickToClose: true,
      showCloseButton: true,
      confirmText: 'Yes',
      declineText: 'No'
    };


  //Populate Forex-CRM-Company  Drop  Down  

  GetForexCrmCompanyType() {

    let t = localStorage.getItem('userToken');
    t = 'Bearer' + t

    this.companytypelist = [];
    this.Forex_CRM_Service.GetForexCompanyType(t).then(x => {
      this.CompanyType = x;
      Object.keys(this.CompanyType).forEach(key => {
        let CompanyTypetemp = new Model_Forex_CRM_CompanyType();
        CompanyTypetemp = this.CompanyType[key];

        this.companytypelist.push(CompanyTypetemp);

      });

    });
  }



  onItemSelecLocation(item: any) {

    if (this.Forex_CRM_CorporateDetails_Model.LocationServedId == undefined) {
      this.Forex_CRM_CorporateDetails_Model.LocationServedId = [];
    }

    let LocationId = item['id'];
    this.Forex_CRM_CorporateDetails_Model.LocationServedId.push(LocationId);


  }
  OnItemDeSelectLocation(itemv: any) {

    this.Forex_CRM_CorporateDetails_Model.LocationServedId.forEach((item, index) => {

      if (item === itemv['id']) this.Forex_CRM_CorporateDetails_Model.LocationServedId.splice(index, 1);
    });

  }
  onSelectAllLocation(items: any) {
    this.Forex_CRM_CorporateDetails_Model.LocationServedId = [];
    items.forEach(x => {

      this.Forex_CRM_CorporateDetails_Model.LocationServedId.push(x['id']);

    })



  }
  onDeSelectAllLocation(items: any) {

    this.Forex_CRM_CorporateDetails_Model.LocationServedId = [];
  }


  //Populate  Forex-CRM-Company  Drop  Down 



  //Populate Forex-CRM-Organisation  Drop  Down  

  GetForexCrmOrganisationType() {
    let t = localStorage.getItem('userToken');
    t = 'Bearer' + t
    this.organisationtypelist = [];
    this.Forex_CRM_Service.GetForexOrganisationType(t).then(x => {
      this.OrganisationType = x;
      Object.keys(this.OrganisationType).forEach(key => {
        let OrganisationTypetemp = new Model_Forex_CRM_OrganisationType();
        OrganisationTypetemp = this.OrganisationType[key];
        this.organisationtypelist.push(OrganisationTypetemp);

      });

    });
  }

  //Populate  Forex-CRM-Organisation  Drop  Down



  // Save Region  Starts

  saveForexCRMCorporateDetails(): void {


    let t = localStorage.getItem('userToken');
    t = 'Bearer' + t;

    this.Forex_CRM_CorporateDetails_Model.IsActive = true;

    if (this.Forex_CRM_CorporateDetails_Model.ForexCrmId > 0) {
      this.Forex_CRM_Service.UpdateCrmForex(t, this.Forex_CRM_CorporateDetails_Model).subscribe(res => {
        this.ForexCrmId = Number(res);
        this.Forex_CRM_CorporateDetails_Model.ForexCrmId = 0;
        if (this.ForexCrmId > 0) {
          localStorage.setItem('SuccessfullMessage', "Successfully Updated");
          this.rout.navigateByUrl('MainMenu', { skipLocationChange: true }).then(() => this.rout.navigate(["ForexCRM/ManageForex"]));
        }
      });
    }
    else {
      this.Forex_CRM_Service.AddForexCRMCorporateDetails(t, this.Forex_CRM_CorporateDetails_Model).subscribe(res => {
        this.ForexCrmId = Number(res);
        this.Forex_CRM_CorporateDetails_Model.ForexCrmId = 0;
        if (this.ForexCrmId > 0) {
          localStorage.setItem('SuccessfullMessage', "Successfully Added");
          this.rout.navigateByUrl('MainMenu', { skipLocationChange: true }).then(() => this.rout.navigate(["ForexCRM/ManageForex"]));

        }
      });
    }
  }

  // Save Region  

  // For Check Box
  MeetingDetailsStatusChamge(chebaxvalue: Number) {



    if (chebaxvalue == 1) {

      this.Forex_CRM_CorporateDetails_Model.IsMeetingDetailsHODSupportRequired = false;
      this.Forex_CRM_CorporateDetails_Model.IsMeetingDetailsIsNegetive = false;

    }
    if (chebaxvalue == 2) {

      this.Forex_CRM_CorporateDetails_Model.IsMeetingDetailsPositive = false;

      this.Forex_CRM_CorporateDetails_Model.IsMeetingDetailsIsNegetive = false;

    }

    if (chebaxvalue == 3) {

      this.Forex_CRM_CorporateDetails_Model.IsMeetingDetailsPositive = false;
      this.Forex_CRM_CorporateDetails_Model.IsMeetingDetailsHODSupportRequired = false;


    }


  }


  AniversaryDate(date)
  {
    this.Forex_CRM_CorporateDetails_Model.CustomerAniversaryDate = date;
  }

  DOB(date)
  {
    this.Forex_CRM_CorporateDetails_Model.CustomerDOB = date;
  }

  NextMeetingDate(date) 
  {
    this.Forex_CRM_CorporateDetails_Model.NextMeetingDate = date
  }

  OnProposalStatus(evt)
  {
    this.Forex_CRM_CorporateDetails_Model.ProposalStatus = evt;
  }
  // Begning Of Location Served 
  GetLocation() {

    let t = localStorage.getItem('userToken');
    t = 'Bearer' + t
    this.locationlist = [];
    this.LocationArray = [];
    this.Forex_CRM_Service.GetForexCRM_Location(t).then(x => {
      this.Forex_Location = x;
      Object.keys(this.Forex_Location).forEach(key => {
        let Locationtemp = new Model_ForexCRM_LocationServed();
        Locationtemp = this.Forex_Location[key];
        this.locationlist.push(Locationtemp);

        this.LocationArray.push({ "id": Locationtemp.LocationServedId, "itemName": Locationtemp.LocationName });
      });

    })

  }

  //For The Grid
  GetForexCrmGrid(): void {
    let t = localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.Forex_CRM_Service.Get_ForexCRM_Grid(t).then(x => {
      this.ForexCRM = x;
      Object.keys(this.ForexCRM).forEach(key => {
        this.ForexCRM[key];
        let ForexCRMtemp = new Model_ForexCRM_CorporateDetails();
        ForexCRMtemp = this.ForexCRM[key];

        this.forexcrmlist.push(ForexCRMtemp);
      });
      this.dtTrigger.next();
    });
  }




  // Update and Get Forex CRM By ID

  GetForexCRMById(Id): void {

    // Begning of the permission portion
    this.IsDataIsInViewState = false;
    // End of the permision portion
    let t = localStorage.getItem('userToken');



    t = 'Bearer' + t;
    this.Forex_CRM_Service.GetForex_CRM_Details_By_id(t, Id).then(x => {
      this.ForexCRMId = x;
      Object.keys(this.ForexCRMId).forEach(key => {
        // tslint:disable-next-line:no-unused-expression

        let ForexCrmtemp = new Model_ForexCRM_CorporateDetails();
        ForexCrmtemp = this.ForexCRMId[key];
        this.Forex_CRM_CorporateDetails_Model.ForexCrmId = ForexCrmtemp.ForexCrmId;
        this.Forex_CRM_CorporateDetails_Model.CompanyName = ForexCrmtemp.CompanyName;
        this.Forex_CRM_CorporateDetails_Model.CompanyTypeId = ForexCrmtemp.CompanyTypeId;
        this.Forex_CRM_CorporateDetails_Model.CustomerName = ForexCrmtemp.CustomerName;
        this.Forex_CRM_CorporateDetails_Model.CustomerContactNo = ForexCrmtemp.CustomerContactNo;
        this.Forex_CRM_CorporateDetails_Model.CustomerEmailId = ForexCrmtemp.CustomerEmailId;
        this.Forex_CRM_CorporateDetails_Model.CustomerAniversaryDate = ForexCrmtemp.CustomerAniversaryDate;
        this.Forex_CRM_CorporateDetails_Model.CustomerDOB=ForexCrmtemp.CustomerDOB;
        this.Forex_CRM_CorporateDetails_Model.OrganizationName = ForexCrmtemp.OrganizationName;
        this.Forex_CRM_CorporateDetails_Model.OrganizationTypeId = ForexCrmtemp.OrganizationTypeId;
        this.Forex_CRM_CorporateDetails_Model.IsMeetingDetailsPositive = ForexCrmtemp.IsMeetingDetailsPositive;
        this.Forex_CRM_CorporateDetails_Model.IsMeetingDetailsIsNegetive = ForexCrmtemp.IsMeetingDetailsIsNegetive;
        this.Forex_CRM_CorporateDetails_Model.IsMeetingDetailsHODSupportRequired = ForexCrmtemp.IsMeetingDetailsHODSupportRequired;
        this.Forex_CRM_CorporateDetails_Model.OtherDetails=ForexCrmtemp.OtherDetails;
        this.Forex_CRM_CorporateDetails_Model.CurrencyVolume = ForexCrmtemp.CurrencyVolume;
        this.Forex_CRM_CorporateDetails_Model.CardVolume = ForexCrmtemp.CardVolume;
        this.Forex_CRM_CorporateDetails_Model.NextMeetingDate = ForexCrmtemp.NextMeetingDate;
        this.Forex_CRM_CorporateDetails_Model.LocationServedId = ForexCrmtemp.LocationServedId;
        this.Forex_CRM_CorporateDetails_Model.TimelineForConversation = ForexCrmtemp.TimelineForConversation;
        this.Forex_CRM_CorporateDetails_Model.ProposalStatus = ForexCrmtemp.ProposalStatus;
        this.Forex_CRM_CorporateDetails_Model.IsActive = ForexCrmtemp.IsActive;

        if (this.Forex_CRM_CorporateDetails_Model.LocationServedId != null) {
          if (this.Forex_CRM_CorporateDetails_Model.LocationServedId.length > 0) {

            this.SelectedLocation = [];
            for (let y of this.Forex_CRM_CorporateDetails_Model.LocationServedId) {
              let location = this.locationlist.find(x => x.LocationServedId == y);

              this.SelectedLocation.push({ "id": location.LocationServedId, "itemName": location.LocationName });

            }

          }
          else {
            this.SelectedLocation = [];

          }
        }
        else {
          this.SelectedLocation = [];

        }

      });

    });
  }


  // Delete Forex CRM Details 


  DeleteForexCRM(ID: number): void {
    this._confirmation.create('Confirm to Delete', 'Press Yes to Delete', this.settings)
      .subscribe((ans: ResolveEmit) => {
        if (ans.resolved == true) {
          let t = localStorage.getItem('userToken');
          t = 'Bearer' + t;
          if (ID > 0) {
            this.Forex_CRM_Service.Delete_ForexCRM(t, ID).subscribe(res => {
              this.DeleteForexCRMByID = Number(res);
              if (this.DeleteForexCRMByID > 0) {
                localStorage.setItem('SuccessfullMessage', "Successfully Deleted");
                this.rout.navigateByUrl('MainMenu', { skipLocationChange: true })
                .then(() => this.rout.navigate(["ForexCRM/ManageForex"]));
              }
            });
          }
 
        }
      })
  }


  // Get Forex Documents By ID 

  GetUplodesFileById(Id) {

   // this.IsDataIsInViewState = false;
    // End of the permision portion
    let t = localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.FileUploads = [];
    this.Forex_CRM_Service.Get_ForexDocument(t, Id).then(x => {
      this.document = x;

      Object.keys(this.document).forEach(key => {



        let ForexCrmtemp = new Model_Forex_CRM_Documents();
        ForexCrmtemp = this.document[key];

        this.FileUploads.push(ForexCrmtemp);

      });


    })

  }



  Uploaddocument(template) {

    

    this.modelopt = new ModalOptions();
    this.modelopt.class = 'heavy_modal';
    this.modalService.config = this.modelopt;

    this.modalRef = this.modalService.show(template, this.modelopt);
    //this.GridShow=true;
    this.ShowPopup = true;
    //  this.ApprovedMsg=false;
    //  this.RejectedMsg=false;
    // Begning of the permission portion
    this.IsDataIsInViewState = false;
    // End of the permision portion
    this.ApprovedMsg = true;

  }




  save() {
    let t = localStorage.getItem('userToken');
    t = 'Bearer' + t;

    this.Forex_CRM_Service.AddForexCRMDocuments(t, this.FileUploads).subscribe(res => {
      this.docId = res;
      
      this.GetUplodesFileById(this.Forex_CRM_CorporateDetails_Model.ForexCrmId);
      if (this.docId > 0) {        
      }
    });

  }

  DocDownLoad(DocumentId, type): void {


    let t = localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.Forex_CRM_Service.getDocument(t, DocumentId).subscribe(respData => {
      this.downLoadFile(respData, type);
    }, error => {

    });

  }


  /**
     * Method is use to download file from server.
     * @param data - Array Buffer data
     * @param type - type of the document.
     */
  downLoadFile(data: any, type: string) {
    var blob = new Blob([data], { type: type.toString() });
    var url = window.URL.createObjectURL(blob);
    var pwa = window.open(url);
    if (!pwa || pwa.closed || typeof pwa.closed == 'undefined') {
      alert('Please disable your Pop-up blocker and try again.');
    }
  }






  CancleModelPopup() {

    this.modalRef.hide();
  }

  fileuploads(event) {
  // alert("1");

    const files = event.target.files;
    //console.log(files);

    if (files) {
      for (let i = 0; i < files.length; i++) {
        let image = new Model_Forex_CRM_Documents();

        this.allfiles.push(files[i]);
        image.DocumentActualName = files[i].name;
        image.DocumentType = files[i].type;

        const size = files[i].size / 1024;
        const mbc = size + '';
        const mb = mbc.split('.')[0];
        const length = mb.length;

        if (length === 4 || length === 5) {

          const mbsize = size / 1024;

          const splitdata = mbsize + '';
          const splitvalue = splitdata.split('.');
          let secondvariable = '';
          for (let j = 0; j < splitvalue.length; j++) {

            if (j === 1) {
              secondvariable = splitvalue[j].slice(0, 2);
            }
          }

          image.DocumentSize = splitvalue[0] + '.' + secondvariable + ' MB';
        }
        else {

          const splitdata = size + '';
          const splitvalue = splitdata.split('.');
          let secondvariable = '';
          for (let j = 0; j < splitvalue.length; j++) {

            if (j === 1) {
              secondvariable = splitvalue[j].slice(0, 2);
            }
          }

          image.DocumentSize = splitvalue[0] + '.' + secondvariable + ' KB';

        }

        let ObjFileUpload = new Model_Forex_CRM_Documents();

        var FileReades: FileReader = new FileReader();


        let filetyotypearray = image.DocumentSize.split("/");
        var re = /(?:\.([^.]+))?$/;


        ObjFileUpload.ForexCrmId = this.Forex_CRM_CorporateDetails_Model.ForexCrmId;
        ObjFileUpload.DocumentId = 0;

        ObjFileUpload.DocumentName = image.DocumentActualName

        ObjFileUpload.DocumentExtension = re.exec(image.DocumentActualName)[1];

        ObjFileUpload.DocumentType = image.DocumentType;

        ObjFileUpload.DocumentSize = image.DocumentSize;

        const reader = new FileReader();
        reader.onload = (filedata) => {
          ObjFileUpload.File = String(reader.result);


        };

        reader.readAsDataURL(files[i]);

        // reader.readAsText(files[i]);
        this.images.push(image);

        ObjFileUpload.DocumentName = image.DocumentActualName;
        ObjFileUpload.DocumentActualName = image.DocumentActualName;
        this.FileUploads.push(ObjFileUpload);


      }
      event.srcElement.value = null;
    }

  }


  DeleteFile(Filex: any) {

    // const index= this.images.indexOf(image);
    // this.images.splice(index,1);
    // this.allfiles.splice(index,1);
    // this.FilesToUpload.splice(index,1);

    // const  indexF = this.FilesToUpload.indexOf(index,1)

    //this._confirmation.create('Confirm to Delete', 'Press Yes to Delete', this.settings)



    const index = this.FileUploads.indexOf(Filex);
    let tempfile = this.FileUploads[index];

    if (tempfile.ForexCrmId > 0) {

      tempfile.Isactive = false;
      this.FileUploadsDeletes.push(tempfile);
      this.FileUploads.splice(index, 1);
    }
    else {

      this.FileUploads.splice(index, 1);

      this.Imge = this.images.find(x => x.DocumentActualName == tempfile.DocumentActualName);

      const indexofimg = this.images.indexOf(this.Imge);
      this.images.splice(indexofimg, 1);
    }

  }


  RefreshPage(): void {     

    this.rout.navigateByUrl('MainMenu', { skipLocationChange: true }).then(() => this.rout.navigate(["ForexCRM/ManageForex"]));

  }
 




}





