import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from '../../shared/user.service';
import { KRAService }  from '../../shared/HR_ADMIN/KRA/KRAService.service';
import { EmployeeDetail } from '../../shared/HR_ADMIN/Employee/EmployeeDetail.Model';
import { KRAMasterModel } from '../../shared/HR_ADMIN/KRA/KRAMasterModel.Model';
import { KRAMappingWithEmployeeModel } from '../../shared/HR_ADMIN/KRA/KRAMappingWithEmployeeModel.model';
import { OrganizationService }  from '../../shared/Organization/organization.service';
import { Company } from '../../shared/Organization/Company.Model';
import { Branch } from  '../../shared/Organization/Branch.Model';
import { Department } from  '../../shared/Organization/Department.Model';
// Begning Confirmation
import { ConfirmationService } from '@jaspero/ng2-confirmations';
import { ResolveEmit } from '../../../app/resolve-emit';
import { ConfirmSettings } from '../../../app/confirm-settings';
// end of Confirmation
import { Menu } from '../../shared/menu.model';
import { ModalOptions,BsModalRef, BsModalService } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-kramapping-with-employee',
  templateUrl: './kramapping-with-employee.component.html',
  styleUrls: ['./kramapping-with-employee.component.css']
})

export class KramappingWithEmployeeComponent implements OnInit 
{
  KRAMasterModel : KRAMasterModel= new KRAMasterModel();
  Templates: KRAMasterModel[] = [];
  template: any;

  KRAMappingWithEmployeeModel : KRAMappingWithEmployeeModel= new KRAMappingWithEmployeeModel();
  EmployeeDetails: KRAMappingWithEmployeeModel[] = [];
  Employees: EmployeeDetail[] = [];
  employee: any;
  KRAMappingWithEmployees: KRAMappingWithEmployeeModel[] = [];
  kramapping: any;
  //Organisation
  Companies : Company[]=[];
  Company : any;
  Branches: Branch[] = [];
  Branch: any;
  Departments: Department[] = [];
  Department: any;

  RefId : any;
  userClaims: any;

  Result : string;
  SuccessStatus:boolean=false;
  SuccessMessage: string ="";
  public tableWidget: any;
  selectedIndex : number;
  // Begning Permission Portion    
  SelectedMenu:Menu;
  IsDataIsInViewState : boolean=false;
  CanViewState : boolean=true;
  IsApplicationView : boolean=false;
  DisableSave : boolean=false;
  Totalwts : number=0;
  FormViewState : boolean=true;
  ForUpdateView : boolean=false;
  ForAddView : boolean=false;
  // End of the permission portion
  //for Employee Dropdown list
  EmployeeList = [];
  EmployeeselectedItems = [];
  dropdownSettings = {};
  EmployeeSettings = {};
  EmployeeMasters :any;
  //for Employee Dropdown list
  public modalRef : BsModalRef;
  public modelopt : ModalOptions;
  NoRecordMsg : boolean;
  SaveMappingMsg : boolean;
  DeleteMappingMsg : boolean;
  UpdateMappingMsg : boolean;
  ShowPopup : boolean;
  dtOptions: DataTables.Settings = {};
  
  constructor(private KRAService: KRAService,private OrganizationService :OrganizationService, private rout: Router, private userService: UserService,private _confirmation: ConfirmationService, private modalService: BsModalService) 
  { 
    this.modelopt=new ModalOptions();
    this.modelopt.class='heavy_modal';
    this.modalService.config= this.modelopt;
    this.modelopt.ignoreBackdropClick=true;
  }

  ngOnInit() 
  {
    // Begning Permission Portion 
    this.SelectedMenu=this.userService.SelectedMenu;
    if(this.SelectedMenu !=undefined)
    {
      localStorage.setItem('selectedmenu',JSON.stringify(this.SelectedMenu));
      this.rout.navigate([this.SelectedMenu.RouteName]);
    }
    else
    {
      if(JSON.parse(localStorage.getItem('selectedmenu'))!=undefined)
      {
        this.SelectedMenu = JSON.parse(localStorage.getItem('selectedmenu'));
        this.rout.navigate([this.SelectedMenu.RouteName]);
      }
    }
    if(this.SelectedMenu.CanAdd)
    {
      this.IsDataIsInViewState=false;
    }
    else
    {
      this.IsDataIsInViewState=true;
    }
    if(this.SelectedMenu.CanView)
    {
      this.CanViewState=true;
    }
    else
    {
      this.CanViewState=false;
    }
    // End of the permission portion
    this.DisableSave=true;
    this.Totalwts=0;
    this.GetKRATemplateMappingwithEmployee();
    this.getCompanies();
    this.KRAMappingWithEmployeeModel.CompanyId=0;
    this.KRAMappingWithEmployeeModel.TemplateMasterRefId=0;
    this.FormViewState=false;
    this.NoRecordMsg = false;
    this.SaveMappingMsg = false;
    this.DeleteMappingMsg=false;
    this.UpdateMappingMsg = false;
    this.ShowPopup = false;
    this.EmployeeSettings = 
    { 
      text:"Select Employee",
      selectAllText:'Select All',
      unSelectAllText:'UnSelect All',           
      classes:"myclass custom-class",
      enableSearchFilter: true,
    }; 
  }

  settings: ConfirmSettings | any = 
  {
    overlay: true,
    overlayClickToClose: true,
    showCloseButton: true,
    confirmText: 'Yes',
    declineText: 'No'
  };

  GetKRATemplateMappingwithEmployee() 
  {
    this.EmployeeDetails=[];
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.KRAService.GetKRATemplateMappingwithEmployee( t).then(x => 
    {
      this.EmployeeDetails=[];
      this.NoRecordMsg = false;
      this.template = x;
      Object.keys(this.template).forEach( key => 
      {
        let templatetemp = new KRAMappingWithEmployeeModel();
        templatetemp = this.template[key];
        this.EmployeeDetails.push(templatetemp);
      }); 
      if(this.EmployeeDetails.length == 0)
      {
        this.NoRecordMsg = true;
      }
    });      
  }

  GetKRATemplateMaster(CompanyId) 
  {
    this.Templates=[];
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.KRAService.GetKRATemplateMaster( t,CompanyId).then(x => 
    {
      this.template = x;
      Object.keys(this.template).forEach( key => 
      {
        let templatetemp = new KRAMasterModel();
        templatetemp = this.template[key];
        this.Templates.push(templatetemp);
      });     
    });      
  }

  GetEmployeeDetails(EmployeeId)
  {
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.KRAService.GetEmployeeDetailsBYEmployeeId( t,EmployeeId).then(x => 
    {
      this.employee = x;
      Object.keys(this.employee).forEach( key => 
      {
        this.employee[key];
        let KRAApplicationtemp = new KRAMappingWithEmployeeModel();
        KRAApplicationtemp = this.employee[key];
        this.KRAMappingWithEmployeeModel.EmployeeId=KRAApplicationtemp.EmployeeId;
        this.KRAMappingWithEmployeeModel.EmployeeCode=KRAApplicationtemp.EmployeeCode;
        this.KRAMappingWithEmployeeModel.EmployeeName=KRAApplicationtemp.EmployeeName;
        this.KRAMappingWithEmployeeModel.DesignationName=KRAApplicationtemp.DesignationName;
        this.KRAMappingWithEmployeeModel.RHId=KRAApplicationtemp.RHId;
        this.KRAMappingWithEmployeeModel.RHCode=KRAApplicationtemp.RHCode;
        this.KRAMappingWithEmployeeModel.RHName=KRAApplicationtemp.RHName;
        this.KRAMappingWithEmployeeModel.FHId=KRAApplicationtemp.FHId;
        this.KRAMappingWithEmployeeModel.FHCode=KRAApplicationtemp.FHCode;
        this.KRAMappingWithEmployeeModel.FHName=KRAApplicationtemp.FHName;
        this.KRAMappingWithEmployeeModel.CompanyId=KRAApplicationtemp.CompanyId;
        this.EmployeeDetails.push(KRAApplicationtemp);
      });
    });
  }
  
  GetKRAMappingWithEmployee(EmployeeId,TemplateMasterRefId,template) 
  { 
    this.GetEmployeeDetails(EmployeeId);
    this.KRAMappingWithEmployeeModel.TotalWateges=0;
    this.KRAMappingWithEmployees=[];
    this.KRAMappingWithEmployeeModel.IsActive=false;
    let t = localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.KRAService.GetKRAMappingWithEmployeeBYEmployeeId( t,EmployeeId,TemplateMasterRefId).then(x => 
    {
      if(this.ShowPopup==true)
      {
        this.modalRef.hide();
      }
      this.modalRef = this.modalService.show(template,this.modelopt);
      this.ShowPopup=true;
      this.SaveMappingMsg=false;
      this.DeleteMappingMsg=false;
      this.UpdateMappingMsg=false;
      this.DisableSave=true;

      this.ForUpdateView=true;
      this.FormViewState= true;
      this.ForAddView= false;
      this.Totalwts=0;
      this.RefId = x;
      if(this.RefId.length > 0)
      {
        this.KRAMappingWithEmployees=[];
      }
      Object.keys(this.RefId).forEach( key => 
      {
        // tslint:disable-next-line:no-unused-expression
        this.RefId[key];
        let KRAApplicationtemp = new KRAMappingWithEmployeeModel();
        KRAApplicationtemp = this.RefId[key];
        this.KRAMappingWithEmployeeModel.TemplateMasterRefId=KRAApplicationtemp.TemplateMasterRefId;
        this.KRAMappingWithEmployeeModel.OldTemplateMasterRefId=KRAApplicationtemp.TemplateMasterRefId;
        this.Totalwts=this.Totalwts + KRAApplicationtemp.Weighted;
        if(KRAApplicationtemp.Weighted==0)
        {
          KRAApplicationtemp.Weighted=null;
        }
        this.KRAMappingWithEmployees.push(KRAApplicationtemp);
      });
      this.KRAMappingWithEmployeeModel.TotalWateges=this.Totalwts;
      this.GetKRATemplateMaster(this.KRAMappingWithEmployeeModel.CompanyId);
    });
  }

  WategesChanged()
  {
    this.Totalwts=0;
    var j=0;
    var l=this.KRAMappingWithEmployees.length;
    this.KRAMappingWithEmployees.forEach(item => 
    {
      j=j+1;
      if(item.Weighted > 0)
      { 
        this.Totalwts=  Number(this.Totalwts) + Number(item.Weighted);
      }
      this.KRAMappingWithEmployeeModel.TotalWateges=this.Totalwts;
    });
  }

  // RefreshPage()
  // {
  //   this.rout.navigateByUrl('/Appraisal/AllEmployeeKRAApplication', {skipLocationChange: true}).then(()=>this.rout.navigate(["/Appraisal/KRAMappingwithEmployee"]));
  // }

  ADDClick(template)
  {
    if(this.ShowPopup==true)
    {
      this.modalRef.hide();
    }
    this.modalRef = this.modalService.show(template,this.modelopt);
    this.ShowPopup=true;
    this.SaveMappingMsg=false;
    this.DeleteMappingMsg=false;
    this.UpdateMappingMsg=false;
    this.DisableSave=true;
    this.FormViewState=true;
    this.ForAddView=true;
    this.ForUpdateView=false;
    this.KRAMappingWithEmployeeModel.CompanyId=0;
    this.KRAMappingWithEmployeeModel.BranchId=0;
    this.KRAMappingWithEmployeeModel.DepartmentId=0;
    this.KRAMappingWithEmployeeModel.TemplateMasterRefId=0;
    this.EmployeeselectedItems = [];
    this.EmployeeList = [];
    this.KRAMappingWithEmployees=[];
    //this.GetEmployeeDetailByLocation(0,0,0);
  }
  //Begin Organisation
  getCompanies() 
  {
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.OrganizationService.GetCompanyForDDL( t).then(x => 
    {
      this.Company = x;
      Object.keys(this.Company).forEach( key => 
      {
        this.Company[key];
        let Companytemp = new Company();
        Companytemp = this.Company[key];
        this.Companies.push(Companytemp);
      });     
    });      
  }
  
  getBranches( Companyid) 
  {
    this.Branches=[];
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t
    this.OrganizationService.GetBranchByCompanyId( t,Companyid).then(x => 
    {
      this.Branch = x;
      Object.keys(this.Branch).forEach( key => 
      {
        this.Branch[key];
        let Branchtemp = new Branch();
        Branchtemp = this.Branch[key];
        this.Branches.push(Branchtemp);
      });     
    });      
  }
  
  getDepartments(Branchid) 
  {
    this.Departments=[];
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t
    this.OrganizationService.GetDepartmentByBranchid( t,Branchid).then(x => 
    {
      this.Department = x;
      Object.keys(this.Department).forEach( key => 
      {
        this.Department[key];
        let Departmenttemp = new Department();
        Departmenttemp = this.Department[key];
        this.Departments.push(Departmenttemp);
      });     
    });      
  }
  
  onChangeCompany(Companyid) 
  {
    if(Companyid > 0)
    {
      this.getBranches(Companyid);
      this.getDepartments(0);
      this.KRAMappingWithEmployeeModel.BranchId=0;
      this.Departments=[];
      this.KRAMappingWithEmployeeModel.DepartmentId=0;
      this.GetEmployeeDetailByLocation(Companyid,this.KRAMappingWithEmployeeModel.BranchId,this.KRAMappingWithEmployeeModel.DepartmentId);
      this.KRAMappingWithEmployeeModel.EmployeeId=0;
      this.GetKRATemplateMaster(Companyid);
      this.KRAMappingWithEmployees=[];
    }
  }
  
  onchangeBranch(Branchid)
  {
    if(Branchid > 0)
    {
      this.Departments=[];
      this.getDepartments(Branchid);
      this.KRAMappingWithEmployeeModel.DepartmentId=0;
      this.GetEmployeeDetailByLocation(this.KRAMappingWithEmployeeModel.CompanyId,Branchid,this.KRAMappingWithEmployeeModel.DepartmentId);
      this.KRAMappingWithEmployeeModel.EmployeeId=0;
    }
    else
    {
      this.Departments=[];
      this.getDepartments(0);
      this.KRAMappingWithEmployeeModel.DepartmentId=0;
      this.GetEmployeeDetailByLocation(this.KRAMappingWithEmployeeModel.CompanyId,0,0);
      this.KRAMappingWithEmployeeModel.EmployeeId=0;
    }
  }

  onchangeDepartment(DepartmentId)
  {
    if(DepartmentId > 0)
    {
      this.GetEmployeeDetailByLocation(this.KRAMappingWithEmployeeModel.CompanyId,this.KRAMappingWithEmployeeModel.BranchId,DepartmentId);
      this.KRAMappingWithEmployeeModel.EmployeeId=0;
    }
    else
    {
      this.GetEmployeeDetailByLocation(this.KRAMappingWithEmployeeModel.CompanyId,this.KRAMappingWithEmployeeModel.BranchId,0);
      this.KRAMappingWithEmployeeModel.EmployeeId=0;
    }
  }

  onChangeTemplate(TemplateRefId)
  {
    if(TemplateRefId > 0)
    {
      this.GetKRATemplateDetailsById(TemplateRefId);
    }
    else
    {
      this.KRAMappingWithEmployees=[];
    }
  }

  GetEmployeeDetailByLocation(CompanyId,BranchId,DepartmentId)
  {
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t
    this.EmployeeselectedItems = [];
    this.EmployeeList = [];
    this.KRAService.EmployeeDetailsByLocation( t,CompanyId,BranchId,DepartmentId).then(x => 
    {
      this.EmployeeMasters = x;
      Object.keys(this.EmployeeMasters).forEach( key => 
      {
        // tslint:disable-next-line:no-unused-expression
        this.EmployeeMasters[key];
        let Employeetemp= new EmployeeDetail();
        Employeetemp = this.EmployeeMasters[key];
        this.EmployeeList.push({"id":Employeetemp.EmployeeId,"itemName":Employeetemp.EmployeeCode+'-'+Employeetemp.EmployeeName},);
      });  
      if(this.EmployeeList.length > 0)
      {
        var selected_br = this.KRAMappingWithEmployeeModel.EmployeeIds.split(',');
        for (var _i = 0; _i < selected_br.length; _i++) 
        {
          var item = this.EmployeeList.find(x=>x.id==selected_br[_i])
          this.EmployeeselectedItems.push(item);
        }
      }
    });      
  }
  
  onItemSelect(item:any){ }
  OnItemDeSelect(item:any){ }
  onSelectAll(items: any){ }
  onDeSelectAll(items: any){ }

  GetKRATemplateDetailsById(TemplateRefId)
  { 
    this.KRAMappingWithEmployeeModel.TotalWateges=0;
    this.KRAMappingWithEmployees=[];
    this.KRAMappingWithEmployeeModel.IsActive=false;
    let t = localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.KRAService.GetKRATemplateDetailsById( t,TemplateRefId).then(x => 
    {
      this.Totalwts=0;
      this.RefId = x;
      if(this.RefId.length > 0)
      {
        this.KRAMappingWithEmployees=[];
      }
      Object.keys(this.RefId).forEach( key => 
      {
        // tslint:disable-next-line:no-unused-expression
        this.RefId[key];
        let KRAApplicationtemp = new KRAMappingWithEmployeeModel();
        KRAApplicationtemp = this.RefId[key];
        this.Totalwts=this.Totalwts + KRAApplicationtemp.Weighted;
        if(KRAApplicationtemp.Weighted==0)
        {
          KRAApplicationtemp.Weighted=null;
        }
        this.KRAMappingWithEmployees.push(KRAApplicationtemp);
      });
      this.KRAMappingWithEmployeeModel.TotalWateges=this.Totalwts;
    });
  }

  UpdateKRAApplication(template):void
  {
    this.DisableSave=false;
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t; 
    this.KRAService.DeActiveKRAMappingWithEmployee(t ,this.KRAMappingWithEmployeeModel.EmployeeId,this.KRAMappingWithEmployeeModel.OldTemplateMasterRefId).subscribe(res=>
    {
      let t =  localStorage.getItem('userToken');
      t = 'Bearer' + t; 
      var j=0;
      var l=this.KRAMappingWithEmployees.length;
      this.KRAMappingWithEmployees.forEach(item => 
      {
        j=j+1;
        item.TemplateMasterRefId=this.KRAMappingWithEmployeeModel.TemplateMasterRefId;
        if(item.Weighted >0)
        {  
          item.EmployeeId=this.KRAMappingWithEmployeeModel.EmployeeId;
          item.RHId=this.KRAMappingWithEmployeeModel.RHId;
          item.FHId=this.KRAMappingWithEmployeeModel.FHId;   
          if(item.RefId==null)
          {
            item.RefId=0;
          }
          if(item.RefId > 0)
          {
            this.KRAService.UpdateKRAMappingWithEmployee(t ,item).subscribe(res=>
            {
              this.RefId=res;        
            });                  
          }                             
          else
          {
            this.KRAService.AddKRAMappingWithEmployee(t ,item).subscribe(res=>
            {
              this.RefId=res;         
            }); 
          }
        }
        if(j==l)
        {
          this.GetKRATemplateMappingwithEmployee();
          this.modalRef.hide();
          this.ShowPopup = false;
          this.SaveMappingMsg = false;
          this.DeleteMappingMsg=false;
          this.UpdateMappingMsg=true;
          this.modalRef = this.modalService.show(template,this.modelopt);
          this.DisableSave=true;
        }
      });
    });   
  }

  SaveKRAApplication(template):void
  {
    this.DisableSave=false;
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t; 
    this.EmployeeselectedItems.forEach(element =>
    {
      var employeeid=element.id;
      var j=0;
      var l=this.KRAMappingWithEmployees.length;
      this.KRAMappingWithEmployees.forEach(item => 
      {
        j=j+1;
        item.EmployeeId=employeeid;
        if(item.Weighted > 0)
        {
          this.KRAService.AddKRAMappingWithEmployee(t ,item).subscribe(res=>
          {
            this.RefId=res;        
          });                  
        }
        if(j==l)
        {
          this.GetKRATemplateMappingwithEmployee();
          this.modalRef.hide();
          this.ShowPopup = false;
          this.SaveMappingMsg = true;
          this.DeleteMappingMsg=false;
          this.UpdateMappingMsg=false;
          this.modalRef = this.modalService.show(template,this.modelopt);
          this.DisableSave=true;
        }
      });
    });
  }

  DeleteKRATemplateMappingWithEmployee(EmployeeId,TemplateMasterRefId,template)
  {
    this._confirmation.create('Confirm to Final Submit', 'Press Yes to Final Submit', this.settings)
    .subscribe((ans: ResolveEmit) => 
    {
      if (ans.resolved == true) 
      {
        let t =  localStorage.getItem('userToken');
        t = 'Bearer' + t; 
        this.KRAService.DeActiveKRAMappingWithEmployee(t ,EmployeeId,TemplateMasterRefId).subscribe(res=>
        {
          this.RefId=res;        
          if(this.RefId >0)
          {
            this.GetKRATemplateMappingwithEmployee();
            if(this.ShowPopup==true)
            {
              this.modalRef.hide();
            }
            this.ShowPopup = false;
            this.SaveMappingMsg = false;
            this.UpdateMappingMsg = false;
            this.DeleteMappingMsg = true;
            this.modalRef = this.modalService.show(template,this.modelopt);
            this.DisableSave = true;
          } 
        });
      }
    }) 
  }
}