import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from '../../shared/user.service';
import { EmployeePortalService }  from '../../shared/HR_ADMIN/EmployeePortal/EmployeePortalService.service';
import { EmployeeMasterService }  from '../../shared/HR_ADMIN/Employee/EmployeeMaster.Service';
import { EmployeeDetail } from '../../shared/HR_ADMIN/Employee/EmployeeDetail.Model';
import { LeaveApplicationModel } from '../../shared/HR_ADMIN/EmployeePortal/LeaveApplicationModel.Model';
import { Menu } from '../../shared/menu.model';
import { ModalOptions,BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { LeaveApprovalModel } from '../../shared/HR_ADMIN/Approve/ApproveLeave.Model'
import { ApproveLeaveService }  from '../../shared/HR_ADMIN/Approve/ApproveLeave.service';

@Component({
  selector: 'app-all-employee-leave-status',
  templateUrl: './all-employee-leave-status.component.html',
  styleUrls: ['./all-employee-leave-status.component.css']
})
export class AllEmployeeLeaveStatusComponent implements OnInit 
{
  LeaveApplicationModel : LeaveApplicationModel= new LeaveApplicationModel();
  LeavePending : LeaveApplicationModel[]=[];
  LeaveApproved : LeaveApplicationModel[]=[];
  LeaveRejected : LeaveApplicationModel[]=[];
  Leave : any;
  
  Employees: EmployeeDetail[] = [];
  employee: any;

  userClaims: any;
  
  public tableWidget: any;
  selectedIndex : number;
  // Begning Permission Portion    
  SelectedMenu:Menu;
  IsDataIsInViewState : boolean=false;
  CanViewState : boolean=true;
  // End of the permission portion
  EmployeeSettings = {};
  selectedItems = [];
  emp : any [] =[]
  //For Pop up
  ListApplication: LeaveApprovalModel[] = [];
  TempApplication : LeaveApplicationModel;
  CancelMsg : boolean= false;
  ShowPopup : boolean= false;
  public modalRef: BsModalRef;
  public modelopt:ModalOptions;
  ModelListApplication: LeaveApprovalModel;
  Approver: any;
  RefId: any;
  //New
  PendingMsg : boolean= false;
  ApprovedMsg : boolean= false;
  RejectedMsg : boolean= false;
  validdate : boolean= false;
  validTodate : boolean= false;
  DisableSave : boolean;

  constructor(private EmployeeMasterService: EmployeeMasterService, private rout: Router, 
    private ApproveLeaveService: ApproveLeaveService,private userService: UserService,private EmployeePortalService: EmployeePortalService,private modalService: BsModalService) 
  {
    this.ModelListApplication = new LeaveApprovalModel();
    this.ModelListApplication.FromFirstHalf=false;
    this.ModelListApplication.FromSecondHalf=false;
    this.ModelListApplication.ToFirstHalf=false;
    this.ModelListApplication.ToSecondHalf=false;
    this.modelopt=new ModalOptions();
    this.modelopt.class='heavy_modal';
    this.modalService.config= this.modelopt;
    this.modelopt.ignoreBackdropClick=true;
  }

  ngOnInit() 
  {
    // Begning Permission Portion 
    this.SelectedMenu=this.userService.SelectedMenu;
    if(this.SelectedMenu !=undefined)
    {
      localStorage.setItem('selectedmenu',JSON.stringify(this.SelectedMenu));
      this.rout.navigate([this.SelectedMenu.RouteName]);
    }
    else
    {
      if(JSON.parse(localStorage.getItem('selectedmenu'))!=undefined)
      {
        this.SelectedMenu = JSON.parse(localStorage.getItem('selectedmenu'));
        this.rout.navigate([this.SelectedMenu.RouteName]);
      }
    }
    if(this.SelectedMenu.CanAdd)
    {
      this.IsDataIsInViewState=false;
    }
    else
    {
      this.IsDataIsInViewState=true;
    }
    if(this.SelectedMenu.CanView)
    {
      this.CanViewState=false;
    }
    else
    {
      this.CanViewState=true;
    }
    // End of the permission portion
    this.GetEmployeeDetailsDDL();
    this.ModelListApplication.EmployeeId=0;
    this.DisableSave=true;
    this.ModelListApplication.Status=0;
    this.validdate=false;
    this.validTodate=false;
    this.EmployeeSettings =
    {
      singleSelection:true,
      text:'Select Employee',
      enableSearchFilter:true,
      showCheckbox:false
    }
  }

  ngAfterViewInit(): void 
  {

  }

  GetEmployeeDetailsDDL() 
  {
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.EmployeeMasterService.GetEmployeeDetailsForDDL( t).then(x => 
    {
      this.employee = x;
      Object.keys(this.employee).forEach( key => 
      {
        let RHtemp = new EmployeeDetail();
        RHtemp = this.employee[key];
        this.Employees.push(RHtemp);
        this.emp.push({"id":RHtemp.EmployeeId,"itemName":RHtemp.EmployeeCode + '-' +RHtemp.EmployeeName},);
      });     
    });      
  }

  onItemSelect(item:any)
  {
    this.DisableSave = true;
    this.PendingMsg = false;
    this.ApprovedMsg = false;
    this.RejectedMsg = false;
    this.LeavePending=[];
    this.LeaveApproved=[];
    this.LeaveRejected=[];
    let EmployeeId= item['id'];
    if(EmployeeId>0)
    {
      this.ModelListApplication.EmployeeId=EmployeeId;
    }     
  }
  OnItemDeSelect(item:any){ }
  onSelectAll(items: any){ }
  onDeSelectAll(items: any){ }
  
  GetEmployeeLeavePendingDetails(EmployeeId,FromDate,ToDate)
  {
    this.LeavePending=[];
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.EmployeePortalService.GetLeaveStatusPendingByEmployeeId( t,EmployeeId,FromDate,ToDate).then(x => 
    {
      this.Leave = x;
      Object.keys(this.Leave).forEach( key => 
      {
        this.Leave[key];
        let Leavetemp = new LeaveApplicationModel();
        Leavetemp = this.Leave[key];
        this.LeavePending.push(Leavetemp);
      });
      if(this.LeavePending.length == 0)
      {
        this.PendingMsg = true;
        this.ApprovedMsg = false;
        this.RejectedMsg = false;
      }
    });
  }

  GetEmployeeLeaveApprovedDetails(EmployeeId,FromDate,ToDate)
  {
    this.LeaveApproved=[];
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.EmployeePortalService.GetLeaveStatusApprovedByEmployeeId( t,EmployeeId,FromDate,ToDate).then(x => 
    {
      this.Leave = x;
      Object.keys(this.Leave).forEach( key => 
      {
        this.Leave[key];
        let Leavetemp = new LeaveApplicationModel();
        Leavetemp = this.Leave[key];
        this.LeaveApproved.push(Leavetemp);
      });
      if(this.LeaveApproved.length == 0)
      {
        this.PendingMsg = false;
        this.ApprovedMsg = true;
        this.RejectedMsg = false;
      }
    });
  }

  GetEmployeeLeaveRejectedDetails(EmployeeId,FromDate,ToDate)
  {
    this.LeaveRejected=[];
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.EmployeePortalService.GetLeaveStatusRejectedByEmployeeId( t,EmployeeId,FromDate,ToDate).then(x => 
    {
      this.Leave = x;
      Object.keys(this.Leave).forEach( key => 
      {
        this.Leave[key];
        let Leavetemp = new LeaveApplicationModel();
        Leavetemp = this.Leave[key];
        this.LeaveRejected.push(Leavetemp);
      });
      if(this.LeaveRejected.length == 0)
      {
        this.PendingMsg = false;
        this.ApprovedMsg = false;
        this.RejectedMsg = true;
      }
    });
  }

  RefreshPage()
  {
    this.rout.navigateByUrl('/Employee/EmployeeOffer', {skipLocationChange: true}).then(()=>this.rout.navigate(["/Employee/AllLeaveStatus"]));
  }

  CancelLeaveApplication(id,template) 
  {
    this.DisableSave = true;
    this.ModelListApplication.ApprovedRejectedRemarks="";
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.ApproveLeaveService.GetLeavedetailsByID( t,id).then(x => 
    {
      if(this.ShowPopup==true)
      {
        this.modalRef.hide();
      }
      this.modalRef = this.modalService.show(template,this.modelopt);
      this.ShowPopup=true;
      this.CancelMsg=false;
      this.Approver = x;
      Object.keys(this.Approver).forEach( key => 
      {
        // tslint:disable-next-line:no-unused-expression
        this.Approver[key];
        let LeaveApprovetemp = new LeaveApprovalModel();
        LeaveApprovetemp = this.Approver[key];
        this.ModelListApplication.RefId=LeaveApprovetemp.RefId;
        this.ModelListApplication.EmployeeId=LeaveApprovetemp.EmployeeId;
        this.ModelListApplication.EmployeeCode=LeaveApprovetemp.EmployeeCode;
        this.ModelListApplication.EmployeeName=LeaveApprovetemp.EmployeeName; 
        this.ModelListApplication.EmployeeEmailId=LeaveApprovetemp.EmployeeEmailId; 
        this.ModelListApplication.LeaveTypeName=LeaveApprovetemp.LeaveTypeName; 
        this.ModelListApplication.LeaveTypeId=LeaveApprovetemp.LeaveTypeId; 
        this.ModelListApplication.NoDaysLeave=LeaveApprovetemp.NoDaysLeave; 
        this.ModelListApplication.FromDate=LeaveApprovetemp.FromDate; 
        this.ModelListApplication.FromFirstHalf=LeaveApprovetemp.FromFirstHalf; 
        this.ModelListApplication.FromSecondHalf=LeaveApprovetemp.FromSecondHalf;
        if(LeaveApprovetemp.FromFirstHalf==true)
        {
          this.ModelListApplication.Fromvalue="FirstHalf";
        }
        if(LeaveApprovetemp.FromSecondHalf==true)
        {
          this.ModelListApplication.Fromvalue="SecondHalf";
        } 
        this.ModelListApplication.ToDate=LeaveApprovetemp.ToDate; 
        this.ModelListApplication.ToFirstHalf=LeaveApprovetemp.ToFirstHalf; 
        this.ModelListApplication.ToSecondHalf=LeaveApprovetemp.ToSecondHalf; 
        if(LeaveApprovetemp.ToFirstHalf==true)
        {
          this.ModelListApplication.Tovalue="FirstHalf";
        }
        if(LeaveApprovetemp.ToSecondHalf==true)
        {
          this.ModelListApplication.Tovalue="SecondHalf";
        } 
        this.ModelListApplication.ReasonsLeave=LeaveApprovetemp.ReasonsLeave; 
        this.ModelListApplication.ApplicationDate=LeaveApprovetemp.ApplicationDate; 
        this.ModelListApplication.RemarksLeave=LeaveApprovetemp.RemarksLeave;
        this.ModelListApplication.ContactNoDuringLeave=LeaveApprovetemp.ContactNoDuringLeave;
        this.ModelListApplication.AddressDuringLeave=LeaveApprovetemp.AddressDuringLeave;
        this.ModelListApplication.Approved=LeaveApprovetemp.Approved;
        this.ModelListApplication.Rejected=LeaveApprovetemp.Rejected;
        this.ModelListApplication.PersonToContact=LeaveApprovetemp.PersonToContact;
        this.ModelListApplication.RHId=LeaveApprovetemp.RHId;
        this.ModelListApplication.RHCode=LeaveApprovetemp.RHCode;
        this.ModelListApplication.RHName=LeaveApprovetemp.RHName;
        this.ModelListApplication.RHEmailId=LeaveApprovetemp.RHEmailId;
        this.ModelListApplication.CompanyId=LeaveApprovetemp.CompanyId;
      });
      // End for check company code duplication
    });
  }

  LeaveCancel(template) :void
  {
    if(confirm("Are you sure to Cancel "))
    {
      this.DisableSave=false;
      let t =  localStorage.getItem('userToken');
      t = 'Bearer' + t;
      if(this.ModelListApplication.RefId > 0)
      {
        this.ModelListApplication.Rejected=true;
        this.ApproveLeaveService.LeaveCancel(t ,this.ModelListApplication).subscribe(res=>
        {
          this.RefId=res;
          this.ModelListApplication.RefId=0;  
          if(this.RefId>0)
          {
            this.ModelListApplication.ApprovedRejectedRemarks="";
            this.modalRef.hide();
            this.CancelMsg=true;
            this.ShowPopup=false;
            this.modalRef = this.modalService.show(template);
            this.GetEmployeeLeavePendingDetails(this.ModelListApplication.EmployeeId,this.ModelListApplication.SearchFromDate,this.ModelListApplication.SearchToDate);
            this.GetEmployeeLeaveRejectedDetails(this.ModelListApplication.EmployeeId,this.ModelListApplication.SearchFromDate,this.ModelListApplication.SearchToDate);
            this.DisableSave=true;
          }
        });                  
      }
    }
  }

  SearchFromDateChanged( date)
  {
    this.DisableSave = true;
    this.PendingMsg = false;
    this.ApprovedMsg = false;
    this.RejectedMsg = false;
    this.LeavePending=[];
    this.LeaveApproved=[];
    this.LeaveRejected=[];
    
    this.ModelListApplication.SearchFromDate=date;
    var CurrentDate = new Date();
    var CurrentYear = CurrentDate.getFullYear();
    var currentMonth = CurrentDate.getMonth();
    var CurrentDay = CurrentDate.getDate();
    
    var selecteedDate = new Date(date);
    var selectedYear=selecteedDate.getFullYear();
    var selectedMonth=selecteedDate.getMonth();
    var selectedDay=selecteedDate.getDate();

    if(CurrentYear > selectedYear )
    {
     this.validdate = false;      
    }
    else
    {
      if (currentMonth > selectedMonth)
      {
        this.validdate = false;
      }
      else
      {
        if(CurrentDay >= selectedDay )
        {
          this.validdate = false;
        }
        else
        {
          this.validdate = true;
        }
      }
    }
  }

  SearchToDateChanged( date)
  {
    this.DisableSave = true;
    this.PendingMsg = false;
    this.ApprovedMsg = false;
    this.RejectedMsg = false;
    this.LeavePending=[];
    this.LeaveApproved=[];
    this.LeaveRejected=[];

    this.ModelListApplication.SearchToDate=date;
    var CurrentDate = new Date();
    var CurrentYear = CurrentDate.getFullYear();
    var currentMonth = CurrentDate.getMonth();
    var CurrentDay = CurrentDate.getDate();
    
    var selecteedDate = new Date(date);
    var selectedYear=selecteedDate.getFullYear();
    var selectedMonth=selecteedDate.getMonth();
    var selectedDay=selecteedDate.getDate();

    if(CurrentYear > selectedYear )
    {
     this.validTodate= false;      
    }
    else
    {
      if (currentMonth > selectedMonth)
      {
        this.validTodate = false;
      }
      else
      {
        if(CurrentDay >= selectedDay )
        {
          this.validTodate = false;
        }
        else
        {
          this.validTodate = true;
        }
      }
    }
  }

  StatusChanged()
  {
    this.DisableSave = true;
    this.PendingMsg = false;
    this.ApprovedMsg = false;
    this.RejectedMsg = false;
    this.LeavePending=[];
    this.LeaveApproved=[];
    this.LeaveRejected=[];
  }

  submitClick()
  {
    this.DisableSave = false;
    this.validdate = false;
    this.validTodate=false;
    this.PendingMsg = false;
    this.ApprovedMsg = false;
    this.RejectedMsg = false;
    this.LeavePending=[];
    this.LeaveApproved=[];
    this.LeaveRejected=[];
    if(this.ModelListApplication.SearchFromDate!=null && this.ModelListApplication.SearchToDate!=null && this.ModelListApplication.Status > 0)
    {
      if(this.ModelListApplication.SearchToDate >= this.ModelListApplication.SearchFromDate)
      {
        if(this.ModelListApplication.Status == 1)
        {
          this.GetEmployeeLeavePendingDetails(this.ModelListApplication.EmployeeId,this.ModelListApplication.SearchFromDate, this.ModelListApplication.SearchToDate);
        }
        else if(this.ModelListApplication.Status == 2)
        {
          this.GetEmployeeLeaveApprovedDetails(this.ModelListApplication.EmployeeId,this.ModelListApplication.SearchFromDate, this.ModelListApplication.SearchToDate);
        }
        else
        {
          this.GetEmployeeLeaveRejectedDetails(this.ModelListApplication.EmployeeId,this.ModelListApplication.SearchFromDate, this.ModelListApplication.SearchToDate);
        }
      }
      else
      {
        this.validTodate = true;
      }
    }
  }
}

