import { IMyDateModel} from 'ngx-mydatepicker';
import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs/Subject';
import { DataTableDirective } from 'angular-datatables';
import { UserService } from '../../shared/user.service';
import { OrganizationService } from '../../shared/Organization/organization.service';
import { LeaveMasterService } from '../../shared/HR_ADMIN/Leave/LeaveMaster.service';
import { LeaveType } from  '../../shared/HR_ADMIN/Leave/LeaveType.Model';
import { LeaveMasterModel } from  '../../shared/HR_ADMIN/Leave/LeaveMaster.Model';
import { Company } from  '../../shared/Organization/Company.Model';
import { FinancialYear } from  '../../shared/Organization/FinancialYear.Model';
import { Branch } from  '../../shared/Organization/Branch.Model';
import { EmployeeDetail } from '../../shared/HR_ADMIN/Employee/EmployeeDetail.Model';
// Begning Confirmation
import { ConfirmationService } from '@jaspero/ng2-confirmations';
import { ResolveEmit } from '../../../app/resolve-emit';
import { ConfirmSettings } from '../../../app/confirm-settings';
import { Menu } from '../../shared/menu.model';

@Component({
  selector: 'app-leave-master',
  templateUrl: './leave-master.component.html',
  styleUrls: ['./leave-master.component.css']
})
export class LeaveMasterComponent implements OnInit {

  LeaveMastermodel : LeaveMasterModel = new LeaveMasterModel();
  userClaims: any;
  LeaveMasters: LeaveMasterModel[] = [];
  LeavecompMasters: LeaveMasterModel[] = [];
  Result : string;
  SuccessStatus:boolean=false;
  SuccessMessage: string ="";
  public tableWidget: any;
  LeaveMasterdata : any;
  LeaveMaster: any;
  base64textString : string;

  Companies: Company[] = [];
  company: any;
  Branches: Branch[] = [];
  Branch: any;
  Years:FinancialYear[]=[];
  LeaveTypes : LeaveType[]=[];
  Year:any;
  tempLeaveMasteradd : LeaveMasterModel =new LeaveMasterModel();
  LeavesIsValid : boolean =false;  

  LeaveIsExist : boolean=false;
  // Begning Permission Portion    
  SelectedMenu:Menu;
  IsDataIsInViewState : boolean=false;
  CanViewState : boolean=false;
  // End of the permission portion
  //check for duplicate component code
  CheckExiststatus : boolean=null;  
  Checkeditstatus : boolean =false;
  check : string ="";
  checkdata : any;
  checkdataval : string ="";
  //check for duplicate component code
  //Begin for disable
  IsInEditstate : boolean = false;
  IsInViewState : boolean = false;
  

  LeaveType : any;
  //End for disable
  
  constructor(private LeaveMasterService: LeaveMasterService, private organizationService: OrganizationService, private rout: Router,  private _confirmation: ConfirmationService, private userService: UserService) { }
  
  @ViewChild(DataTableDirective, {static: false})
  setDtOptions: DataTables.Settings = {};
  cardDtOptions: DataTables.Settings = {};
  dtElement: DataTableDirective;
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();
  
      // optional date changed callback
  onDateChangedFrom(event: IMyDateModel): void
  {
    const monthNames = [
      'January', 'February', 'March',
      'April', 'May', 'June', 'July',
      'August', 'September', 'October',
      'November', 'December'
    ];
    var m= event.jsdate.getMonth();
    m=m+1;
    var d= event.jsdate.getDate();
    var y= event.jsdate.getFullYear();
    var fulldate= d + '/' + m + '/' + y;
    this.LeaveMastermodel.FromdatePass=fulldate;
    //event.formatted = event.jsdate.getDate() + ' ' +     monthNames[event.jsdate.getMonth()] + ', ' + event.jsdate.getFullYear();
  }

  ngOnInit() 
  {
    //Begin Permission Portion
    this.SelectedMenu=this.userService.SelectedMenu;
    if(this.SelectedMenu !=undefined)
    {
      localStorage.setItem('selectedmenu',JSON.stringify(this.SelectedMenu));
      this.rout.navigate([this.SelectedMenu.RouteName]);
    }
    else
    {
      if(JSON.parse(localStorage.getItem('selectedmenu'))!=undefined)
      {
        this.SelectedMenu = JSON.parse(localStorage.getItem('selectedmenu'));
        this.rout.navigate([this.SelectedMenu.RouteName]);
      }
    }
    if(this.SelectedMenu.CanAdd)
    {
    this.IsDataIsInViewState=false;
    }
    else
    {
      this.IsDataIsInViewState=true;
    }
    if(this.SelectedMenu.CanView)
    {
    this.CanViewState=false;
    }
    else
    {
      this.CanViewState=true;
    }
    //End of the peremission portion
    this.getCompanies();
    this.GetLeaveType();
    this.LeaveMastermodel.CompanyId=0;
    this.LeaveMastermodel.LeaveType=0;
    this.LeaveMastermodel.TotalLeave=0;
    this.dtOptions = 
    {
      pagingType: 'full_numbers',
      pageLength: 2
    };
    if(localStorage.getItem('SuccessfullMessage'))
    {
      this.SuccessStatus=true;
      this.SuccessMessage =localStorage.getItem('SuccessfullMessage')
      localStorage.removeItem('SuccessfullMessage');
    }  
  }
  

  changetdateformat()
  {
    let objemp = new EmployeeDetail();   
    this.LeaveMastermodel.Fromdate=objemp.ReturnFormatdate(this.LeaveMastermodel.FromdatePass);
    //this.LeaveMastermodel.Fromdate=objemp.ReturnFormatdate(this.LeaveMastermodel.ToDatePass);
  
  }
  ngAfterViewInit(): void 
  {
    this.GetLeaveMaster();
    this.dtOptions = 
    {
      pagingType: 'full_numbers',
      pageLength: 2
    };
  }

  settings: ConfirmSettings | any = 
  {
    overlay: true,
    overlayClickToClose: true,
    showCloseButton: true,
    confirmText: 'Yes',
    declineText: 'No'
  };

  getCompanies() 
  {
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.organizationService.GetCompany( t).then(x => 
    {
      this.company = x;
      Object.keys(this.company).forEach( key => 
      {
        // tslint:disable-next-line:no-unused-expression
        this.company[key];
        let Companytemp = new Company();
        Companytemp = this.company[key];
        this.Companies.push(Companytemp);
      });     
    });      
  }

  

  GetLeaveMaster()
  {
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.LeaveMasterService.GetLeaveMaster( t).then(x => 
    {
      this.LeaveMaster = x;
      Object.keys(this.LeaveMaster).forEach( key => 
      {
        
        let LeaveRuletemp = new LeaveMasterModel();
        LeaveRuletemp = this.LeaveMaster[key];
        this.LeaveMasters.push(LeaveRuletemp);
      });
      this.dtTrigger.next();
    });
  }


  GetLeaveType()
  {
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.LeaveMasterService.GetLeaveType( t).then(x => 
    {
      this.LeaveType = x;
      Object.keys(this.LeaveType).forEach( key => 
      {
        this.LeaveType[key];
        let LeaveTypetemp = new LeaveType();
        LeaveTypetemp = this.LeaveType[key];
        this.LeaveTypes.push(LeaveTypetemp);
      });
      //this.dtTrigger.next();
    });
  }

  

  GetLeaveMasterByIdyid(LeaveMasterId) 
  { 
    this.LeavesIsValid=true;
    //Begin for disable   
   this.IsInEditstate=true;
   this.IsInViewState=false;
    //End for disable
    // Begning of the permission portion
    this.IsDataIsInViewState=false;
    // End of the permision portion
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.LeaveMasterService.GetLeaveMasterById( t,LeaveMasterId).then(x => 
    {
      this.LeaveMasterdata = x;
      this.LeavecompMasters=  [];
      Object.keys(this.LeaveMasterdata).forEach( key => 
      {
        // tslint:disable-next-line:no-unused-expression
        this.LeaveMasterdata[key];
        var leavetemp = new LeaveMasterModel();
        leavetemp = this.LeaveMasterdata[key];  
        
        this.LeaveMastermodel.LeaveMasterId=leavetemp.LeaveMasterId;
        this.LeaveMastermodel.CompanyId=leavetemp.CompanyId;
       // this.LeaveMastermodel.Fromdate=leavetemp.Fromdate;
       
        this.LeaveMastermodel.LeaveType=leavetemp.LeaveType;
        this.LeaveMastermodel.TotalLeave=leavetemp.TotalLeave;
        this.LeaveMastermodel.FromdatePass=leavetemp.FromdatePass;
        this.changetdateformat();
        this.LeaveMastermodel.Todate=leavetemp.Todate;

        
      });
      
      // End for check company code duplication
    });
  }

  viewLeaveMasterByid(LeaveMasterId) 
  {
    //Begin for disable   
    this.IsInEditstate=true;
    this.IsInViewState=true;
    //End for disable
    //begin permission
    this.IsDataIsInViewState=true;
    //end permission
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.LeaveMasterService.GetLeaveMasterById( t,LeaveMasterId).then(x => 
    {
      this.LeaveMasterdata = x;
      
      Object.keys(this.LeaveMasterdata).forEach( key => 
      {
        // tslint:disable-next-line:no-unused-expression
        
        var leavetemp = new LeaveMasterModel();
        leavetemp=this.LeaveMasterdata[key];
        this.LeaveMastermodel.LeaveMasterId=leavetemp.LeaveMasterId;
        this.LeaveMastermodel.CompanyId=leavetemp.CompanyId;
       // this.LeaveMastermodel.Fromdate=leavetemp.Fromdate;
       
        this.LeaveMastermodel.LeaveType=leavetemp.LeaveType;
        this.LeaveMastermodel.TotalLeave=leavetemp.TotalLeave;
        this.LeaveMastermodel.FromdatePass=leavetemp.FromdatePass;
        this.changetdateformat();
        this.LeaveMastermodel.Todate=leavetemp.Todate;
      });
    
      
      // Begin for check company code duplication
     
      this.Checkeditstatus=true;
      this.CheckExiststatus =true;
      this.check=""
      // End for check company code duplication
    });
  }

  CheckLeaveExist() 
  {
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.LeaveMasterService.CheckLeaveMasterByCompanyIdAndLeavetype( t, this.LeaveMastermodel.LeaveType,this.LeaveMastermodel.CompanyId).then(x => 
    {
      this.company = x;
      if(x==true)
      {
        this.LeaveIsExist=true;        
      }
      else
      {
        this.LeaveIsExist=false;
      }
    });      
  }

  DeleteLeaveRuleByid(Refid : number) :void
  {
    if(this.SelectedMenu.CanAdd)
    {
     this.IsDataIsInViewState=false;
    }
    else
    {
      this.IsDataIsInViewState=true;
    }
    this._confirmation.create('Confirm to Deleate', 'Press Yes to Delete', this.settings)
      .subscribe((ans: ResolveEmit) => 
      {
        if (ans.resolved == true) 
        {
          let t =  localStorage.getItem('userToken');
          t = 'Bearer' + t;
          //this.LeaveMastermodel.IsActive=true;
          if( Refid > 0)
          {
            this.LeaveMasterService.DeleteLeaveRule(t ,Refid).subscribe(res=>
            {
              this.LeaveMasterdata=res;
              this.LeaveMastermodel.LeaveMasterId=0;                                  
              if(this.LeaveMasterdata >0)
              {
                localStorage.setItem('SuccessfullMessage', "Deleted Successfully");
                this.rout.navigateByUrl('/LeaveMaster/LeaveType', {skipLocationChange: true}).then(()=>this.rout.navigate(["/LeaveMaster/ManageLeaveMaster"]));
              }   
            });                  
          }
        }
      })
  }

  RefreshPage()
  {
    this.rout.navigateByUrl('/LeaveMaster/LeaveType', {skipLocationChange: true}).then(()=>this.rout.navigate(["/LeaveMaster/ManageLeaveMaster"]))
  }

  saveLeaveMaster() :void
  {
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    if(this.LeaveMastermodel.LeaveMasterId >0)
    {
      this.LeaveMasterService.UpdateLeaveMaster(t ,this.LeaveMastermodel).subscribe(res=>
        {
          this.LeaveMasterdata=res;
          if(this.LeaveMasterdata>0)
          {
            localStorage.setItem('SuccessfullMessage', "Update Successfully");
            this.rout.navigateByUrl('/LeaveMaster/LeaveType', {skipLocationChange: true}).then(()=>this.rout.navigate(["/LeaveMaster/ManageLeaveMaster"]));
          }
        });                  
    }
    else{

      this.LeaveMasterService.AddLeaveaster(t ,this.LeaveMastermodel).subscribe(res=>
        {
          this.LeaveMasterdata=res;
          if(this.LeaveMasterdata>0)
          {
            localStorage.setItem('SuccessfullMessage', "Save Successfully");
            this.rout.navigateByUrl('/LeaveMaster/LeaveType', {skipLocationChange: true}).then(()=>this.rout.navigate(["/LeaveMaster/ManageLeaveMaster"]));
          }
        });                  

    }
    
  }

  onCcompanyandLeavetyprchange(id) 
  {
     if((this.LeaveMastermodel.LeaveType>0)&&(this.LeaveMastermodel.CompanyId>0))
     {
       this.CheckLeaveExist();

     }
    //this.LeaveRuleModel.BranchId=0;
  }

  
  
 

}
