<div class="row">
    <div class="col-lg-4">
        <div class="col-body-border-right">
            <div class="form-group row">
                <label class="col-sm-4 col-form-label clear-padding-right" style="font-weight: bold;">Code</label>
                <div class="col-sm-7 clear-left-padding" style="font-weight: bold; padding-top: 7px;">
                    :&nbsp;&nbsp;{{EmployeeMasterService.SharedEmployees.EmployeeCode}}
                </div>
            </div>
        </div>
    </div>
    <div class="col-lg-4">
        <div class="form-group row">
            <label class="col-sm-4 col-form-label clear-padding-right" style="font-weight: bold;">Name</label>
            <div class="col-sm-7 clear-left-padding" style="font-weight: bold; padding-top: 7px;">
                :&nbsp;&nbsp;{{EmployeeMasterService.SharedEmployees.FullName}}
            </div>
        </div>
    </div>
</div>

<fieldset class="body-form-control">
    <app-dialog [(visible)]="this.SuccessStatus">
        <span class="successmsg">{{this.SuccessMessage}}</span>
    </app-dialog>
    <legend class="legend--">Employee Address Details</legend>
    <form #EmployeeAddressDetailForm="ngForm" (ngSubmit)="SaveEmpolyeeAddressDetails()">
        <div class="row">
            <table class="table table-striped table-bordered text-center table-new row-border hover">
                <thead class="thead-blue text-center">
                    <tr style="width: 60%;">
                        <td style="width: 30%;">
                            Same Address<input type="checkbox" [(ngModel)]="this.EmployeeMasterService.SharedEmployees.SameAddress" id ="SameAddress" name="SameAddress" #SameAddress="ngModel" (ngModelChange)="SameAddressChange()" class="form-control form-control-custom form-control-custom-new">
                        </td>
                        <td style="width: 35%;">Present Address</td>
                        <td style="width: 35%;">Permanent Address</td>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td style="text-align:left">&nbsp;&nbsp;House No/ Street</td>
                        <td>
                            <input type="text" [(ngModel)]="this.EmployeeMasterService.SharedEmployees.CAddress1" tabindex="1" maxlength="500" id ="CAddress1"  name="CAddress1" #CAddress1="ngModel" required class="form-control form-control-custom form-control-custom-new"  >
                            <div *ngIf="CAddress1.invalid && (CAddress1.dirty || CAddress1.touched)" class="required-validation2">
                                <div *ngIf="CAddress1.errors.required">House No/ Street is required.</div>
                            </div>
                        </td>
                        <td>
                            <input type="text" [(ngModel)]="this.EmployeeMasterService.SharedEmployees.PAddress1" tabindex="9" maxlength="500" id ="PAddress1"  name="PAddress1" #PAddress1="ngModel" required class="form-control form-control-custom form-control-custom-new"    >
                            <div *ngIf="PAddress1.invalid && (PAddress1.dirty || PAddress1.touched)" class="required-validation2">
                                <div *ngIf="PAddress1.errors.required">House No/ Street is required.</div>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td style="text-align:left">&nbsp;&nbsp;Address2</td>
                        <td>
                            <input type="text" [(ngModel)]="this.EmployeeMasterService.SharedEmployees.CAddress2" tabindex="2" maxlength="500" id ="CAddress2"  name="CAddress2" #CAddress2="ngModel" required class="form-control form-control-custom form-control-custom-new">
                            <div *ngIf="CAddress2.invalid && (CAddress2.dirty || CAddress2.touched)" class="required-validation2">
                                <div *ngIf="CAddress2.errors.required">Address2 is required.</div>
                            </div>
                        </td>
                        <td>
                            <input type="text" [(ngModel)]="this.EmployeeMasterService.SharedEmployees.PAddress2" tabindex="10" maxlength="500" id ="PAddress2"  name="PAddress2" #PAddress2="ngModel" required class="form-control form-control-custom form-control-custom-new">
                            <div *ngIf="PAddress2.invalid && (PAddress2.dirty || PAddress2.touched)" class="required-validation2">
                                <div *ngIf="PAddress2.errors.required">Address2 is required.</div>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td style="text-align:left">&nbsp;&nbsp;Contact No</td>
                        <td>
                            <input type="text" [(ngModel)]="this.EmployeeMasterService.SharedEmployees.CContactNo" tabindex="3" maxlength="10" minlength="10" pattern="[0-9]+" id ="CContactNo"  name="CContactNo" #CContactNo="ngModel" required class="form-control form-control-custom form-control-custom-new"  >
                            <div *ngIf="CContactNo.invalid && (CContactNo.dirty || CContactNo.touched)" class="required-validation2">
                                <div *ngIf="CContactNo.errors.minlength">Contact No is not less than 10 dogit.</div>
                                <div *ngIf="CContactNo.errors?.pattern">Contact No should be valid.</div>
                                <div *ngIf="CContactNo.errors.required">Contact No required.</div>
                            </div>
                        </td>
                        <td>
                            <input type="text" [(ngModel)]="this.EmployeeMasterService.SharedEmployees.PContactNo" tabindex="11" maxlength="10" minlength="10" pattern="[0-9]+" id ="PContactNo" name="PContactNo" #PContactNo="ngModel" required class="form-control form-control-custom form-control-custom-new">
                            <div *ngIf="PContactNo.invalid && (PContactNo.dirty || PContactNo.touched)" class="required-validation2">
                                <div *ngIf="PContactNo.errors.minlength">Contact No is not less than 10 dogit.</div>
                                <div *ngIf="PContactNo.errors?.pattern">PContactNo No should be valid.</div>
                                <div *ngIf="PContactNo.errors.required">Contact No required.</div>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td style="text-align:left">
                            &nbsp;&nbsp;Country &nbsp;&nbsp;<button type="button" id="AddCountry" (click)="openModalCountry(template)">Add Country</button>
                        </td>
                        <td>
                            <select  [(ngModel)]="this.EmployeeMasterService.SharedEmployees.CCountryId" tabindex="4" (ngModelChange)="onCountryChange_C($event)" class="form-control" name="CCountryId" #CCountryId="ngModel">
                                <option [value]="0" [disabled]=true >Select Country</option>
                                <option *ngFor="let c of Countries_C" [value]="c.CountryId">{{c.CountryName}}</option>
                            </select>
                            <div class="required-validation2" *ngIf="EmployeeMasterService.SharedEmployees.CCountryId==0">Country is required</div>
                        </td>
                        <td>
                            <select  [(ngModel)]="this.EmployeeMasterService.SharedEmployees.PCountryId" tabindex="12" (ngModelChange)="onCountryChange_P($event)" id ="PCountryId" class="form-control" name="PCountryId" #PCountryId="ngModel">
                                <option [value]="0" [disabled]=true >Select Country</option>
                                <option *ngFor="let c of Countries_P" [value]="c.CountryId">{{c.CountryName}}</option>
                            </select>
                            <div class="required-validation2" *ngIf="EmployeeMasterService.SharedEmployees.PCountryId==0">Country is required</div>
                        </td>
                    </tr>
                    <tr>
                        <td style="text-align:left">
                            &nbsp;&nbsp;State &nbsp;&nbsp;<button type="button" id="AddState" (click)="openModalState(template)">Add State</button>
                        </td>
                        <td>
                            <select [(ngModel)]="this.EmployeeMasterService.SharedEmployees.CStateId" tabindex="5" (ngModelChange)="onstatechange_c($event)"  id ="CStateId" class="form-control" name="CStateId" #CStateId="ngModel" >
                                <option [value]="0" [disabled]=true >Select State</option>
                                <option *ngFor="let s of States_C" [value]="s.StateID">{{s.StateName}}</option>
                            </select>
                            <div class="required-validation2" *ngIf="EmployeeMasterService.SharedEmployees.CStateId==0">State is required</div>
                        </td>
                        <td>
                            <select [(ngModel)]="this.EmployeeMasterService.SharedEmployees.PStateId" tabindex="13" (ngModelChange)="onstatechange_p($event)"  id ="PStateId" class="form-control" name="PStateId" #PStateId="ngModel" >
                                <option [value]="0" [disabled]=true >Select State</option>
                                <option *ngFor="let s of States_P" [value]="s.StateID">{{s.StateName}}</option>
                            </select>
                            <div class="required-validation2" *ngIf="EmployeeMasterService.SharedEmployees.PStateId==0">State is required</div>
                        </td>
                    </tr>
                    <tr>
                        <td style="text-align:left">
                            &nbsp;&nbsp;District &nbsp;&nbsp;<button type="button" id="AddDistrict" (click)="openModalDistrict(template)">Add District</button>
                        </td>
                        <td>
                            <select [(ngModel)]="this.EmployeeMasterService.SharedEmployees.CDistrictId" tabindex="6" (ngModelChange)="onDistrictchange_C($event)" class="form-control" id ="CDistrictId" name="CDistrictId" #CDistrictId="ngModel">
                                <option [value]="0" [disabled]=true >Select District</option>
                                <option *ngFor="let D of Dists_C" [value]="D.DistId">{{D.DistName}}</option>
                            </select>
                            <div class="required-validation2" *ngIf="this.EmployeeMasterService.SharedEmployees.CDistrictId==0">District is required</div>
                        </td>
                        <td>
                            <select  [(ngModel)]="this.EmployeeMasterService.SharedEmployees.PDistrictId" tabindex="14" (ngModelChange)="onDistrictchange_P($event)" id ="PDistrictId" class="form-control" name="PDistrictId" #PDistrictId="ngModel">
                                <option [value]="0" [disabled]=true >Select District</option>
                                <option *ngFor="let D of Dists_P" [value]="D.DistId">{{D.DistName}}</option>
                            </select>
                            <div class="required-validation2" *ngIf="this.EmployeeMasterService.SharedEmployees.PDistrictId==0">District is required</div>
                        </td>
                    </tr>
                    <tr>
                        <td style="text-align:left">
                            &nbsp;&nbsp;City &nbsp;&nbsp;<button type="button" id="AddCity" (click)="openModalCity(template)">Add City</button>
                        </td>
                        <td>
                            <select [(ngModel)]="this.EmployeeMasterService.SharedEmployees.CCityId" tabindex="7" id ="CCityId" class="form-control" name="CCityId" #CCityId="ngModel">
                                <option [value]="0" [disabled]=true >Select City</option>
                                <option *ngFor="let c of Cities_C" [value]="c.CityId">{{c.CityName}}</option>
                            </select>
                            <div class="required-validation2" *ngIf="EmployeeMasterService.SharedEmployees.CCityId==0">City is required</div>
                        </td>
                        <td>
                            <select [(ngModel)]="this.EmployeeMasterService.SharedEmployees.PCityId" tabindex="15" id ="PCityId" class="form-control" name="PCityId" #PCityId="ngModel">
                                <option [value]="0" [disabled]=true >Select City</option>
                                <option *ngFor="let c of Cities_P" [value]="c.CityId">{{c.CityName}}</option>
                            </select>
                            <div class="required-validation2" *ngIf="EmployeeMasterService.SharedEmployees.PCityId==0">City is required</div>
                        </td>
                    </tr>
                    <tr>
                        <td style="text-align:left">&nbsp;&nbsp;Pin Code</td>
                        <td>
                            <input type="text" tabindex="8" [(ngModel)]="this.EmployeeMasterService.SharedEmployees.CPincode" maxlength="6" minlength="6" id ="CPincode"  name="CPincode" #CPincode="ngModel"  pattern="[0-9]+"  required class="form-control form-control-custom form-control-custom-new"     >
                            <div *ngIf="CPincode.invalid && (CPincode.dirty || CPincode.touched)" class="required-validation2">
                                <div *ngIf="CPincode.errors.required">Pin Code is required.</div>
                                <div *ngIf="CPincode.errors.minlength">Pin Code not more than 6 digits.</div>
                                <div *ngIf="CPincode.errors?.pattern">Pin Code should be valid</div>
                            </div>
                        </td>
                        <td>
                            <input type="text" [(ngModel)]="this.EmployeeMasterService.SharedEmployees.PPincode" tabindex="16" maxlength="6" minlength="6" id ="PPincode"  name="PPincode" #PPincode="ngModel" pattern="[0-9]+" required class="form-control form-control-custom form-control-custom-new" >
                            <div *ngIf="PPincode.invalid && (PPincode.dirty || PPincode.touched)" class="required-validation2">
                                <div *ngIf="PPincode.errors.required">Pin Code is required.</div>
                                <div *ngIf="PPincode.errors.minlength">Pin Code not more than 6 digits.</div>
                                <div *ngIf="PPincode.errors?.pattern">Pin Code should be valid</div>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
            <div class="col-lg-4">
                <div class="form-group row">
                    <label  class="col-sm-6 col-form-label text-center clear-padding-right" *ngIf="this.EmployeeMasterService.SharedEmployees.EmployeeId>0">
                        <button type="submit" tabindex="17" class="btn btn-primary save-button" [disabled]="!(EmployeeAddressDetailForm.valid && this.EmployeeMasterService.SharedEmployees.CCountryId!=0 && this.EmployeeMasterService.SharedEmployees.PCountryId!=0 && this.EmployeeMasterService.SharedEmployees.CStateId!=0 &&this.EmployeeMasterService.SharedEmployees.PStateId!=0 && EmployeeMasterService.SharedEmployees.CDistrictId!=0  && this.EmployeeMasterService.SharedEmployees.PDistrictId!=0 && this.EmployeeMasterService.SharedEmployees.CCityId!=0 && this.EmployeeMasterService.SharedEmployees.PCityId!=0 && this.DisableSave==true)" >Save & Next</button>  
                    </label>
                    <div class="col-sm-6 col-form-label text-center">
                        <button type="button" tabindex="18" class="btn btn-danger cancle-button" (click)="RefreshPage()">Cancel</button>
                    </div>
                </div>
            </div>
        </div>

        
    </form>
</fieldset>
<div style=" box-align: center; width: 1500px;">
    <ng-template #template>
        <div class="modal-header" style="background: #2a9bd7; max-width: 1500px;"> 
            <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()" style="color:#fff;">
            <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div  style="text-align: center; max-width: 1500px;">
            <div *ngIf="(this.ShowCountry)">
                <fieldset class="body-form-control">
                    <legend class="legend--">Country Creation</legend>
                    <form  #CounrtyForm="ngForm" (ngSubmit)="saveCountry()">
                        <div class="row">
                            <div class="col-lg-4">
                                <div class="col-body-border-right">
                                    <div class="form-group row">
                                        <label  class="col-sm-4 col-form-label clear-padding-right">Country Code</label>
                                        <div class="col-sm-7 clear-left-padding">
                                            <input type="text" [(ngModel)]="CountryModel.CountryCode" tabindex="1" maxlength="20" id ="CountryCode"  name="CountryCode" #CountryCode="ngModel" required class="form-control form-control-custom form-control-custom-new" >
                                        </div>
                                        <div *ngIf="CountryCode.invalid && (CountryCode.dirty || CountryCode.touched)" class="required-validation2">
                                            <div *ngIf="CountryCode.errors.required">Country Code is required.</div>
                                        </div>
                                    </div>
                                    <div class="form-group row">
                                        <label  class="col-sm-4 col-form-label clear-padding-right">Language Name</label>
                                        <div class="col-sm-7 clear-left-padding">
                                            <input type="text" [(ngModel)]="CountryModel.LanguageName" tabindex="4" id ="LanguageName"  name="LanguageName" #LanguageName="ngModel"  maxlength="200" class="form-control form-control-custom form-control-custom-new" >
                                        </div>                          
                                        <div class="required-validation" *ngIf="LanguageName.errors?.maxlength">LanguageName is not more than 200 characters</div>
                                    </div>
                                </div>
                            </div>
                            
                            <div class="col-lg-4">
                                <div class="col-body-border-right">
                                    <div class="form-group row">
                                        <label  class="col-sm-4 col-form-label clear-padding-right">Country Name </label>
                                        <div class="col-sm-7 clear-left-padding">
                                            <input type="text" [(ngModel)]="CountryModel.CountryName" tabindex="2" id ="CountryName" name="CountryName" (blur)="CheckCountryName()" #CountryName="ngModel" required maxlength="250" class="form-control form-control-custom form-control-custom-new" >
                                        </div>
                                        <div *ngIf="this.CountryNamecheck.length > 0" class="required-validation2">Country Name is exist</div>
                                        <div *ngIf="CountryName.invalid && (CountryName.dirty || CountryName.touched)" class="required-validation2">
                                            <div *ngIf="CountryName.errors.required">Country Name is required.</div>
                                        </div>
                                    </div>
                                    <div class="form-group row">
                                        <label  class="col-sm-4 col-form-label clear-padding-right">Currency Code</label>
                                        <div class="col-sm-7 clear-left-padding">
                                            <input type="text" [(ngModel)]="CountryModel.CurrencyCode" tabindex="5" id ="CurrencyCode"  name="CurrencyCode" #CurrencyCode="ngModel"  maxlength="50" class="form-control form-control-custom form-control-custom-new" >
                                        </div>
                                        <div class="required-validation2" *ngIf="CurrencyCode.errors?.maxlength">CurrencyCode is not more than 50 characters</div>
                                    </div>                      
                                </div>
                            </div>
        
                            <div class="col-lg-4">
                                <div class="form-group row">
                                    <label  class="col-sm-4 col-form-label clear-padding-right">Language Code</label>
                                    <div class="col-sm-7 clear-left-padding">
                                        <input type="text" [(ngModel)]="CountryModel.LanguageCode" tabindex="3" id ="LanguageCode"  name="LanguageCode" #LanguageCode="ngModel"  maxlength="20" class="form-control form-control-custom form-control-custom-new" >
                                    </div>
                                    <div class="required-validation2" *ngIf="LanguageCode.errors?.maxlength">Language Code is not more than 20 characters</div>
                                </div>
                                
                                <div class="form-group row">
                                    <label  class="col-sm-4 col-form-label clear-padding-right">Currency Name</label>
                                    <div class="col-sm-7 clear-left-padding">
                                        <input type="text" [(ngModel)]="CountryModel.CurrencyName" id ="CurrencyName" tabindex="15" #CurrencyName="ngModel" maxlength="100"  name="CurrencyName" class="form-control form-control-custom form-control-custom-new" >
                                    </div>
                                    <div class="required-validation2" *ngIf="CurrencyName.errors?.maxlength">Currency Name is not more than 100 characters</div>
                                </div>
                                <div class="form-group row">
                                    <label  class="col-sm-6 col-form-label text-center clear-padding-right">
                                        <!-- *ngIf="!(this.IsDataIsInViewState)" -->
                                        <button type="submit" tabindex="18" class="btn btn-primary save-button" [disabled]="!(CounrtyForm.valid && this.CountryCheckNameExiststatus==true && this.DisableSave==true)" (click)="modalRef.hide()">Save</button>  
                                    </label>
                                    <div class="col-sm-6 col-form-label text-center">  
                                    <button type="button"  tabindex="19" class="btn btn-danger cancle-button" (click)="modalRef.hide()" >Close</button></div>
                                </div>
                            </div>
                        </div>
                    </form>
                </fieldset>
            </div>

            <div *ngIf="(this.ShowState)">
                <fieldset class="body-form-control">
                    <legend class="legend--">State Creation</legend>
                    <form #StateForm="ngForm" (ngSubmit)="saveState()">
                        <div class="row">
                            <div class="col-lg-4">
                                <div class="col-body-border-right">
                                    <div class="form-group row">
                                        <label class="col-sm-4 col-form-label clear-padding-right">State Code</label>
                                        <div class="col-sm-7 clear-left-padding">
                                            <input type="text" [(ngModel)]="StateModel.StateCode" tabindex="1" maxlength="40" id ="StateCode"  name="StateCode" #StateCode="ngModel" required class="form-control form-control-custom form-control-custom-new" >
                                        </div>
                                        <div *ngIf="StateCode.invalid && (StateCode.dirty || StateCode.touched)" class="required-validation2">
                                            <div *ngIf="StateCode.errors.required">State Code is required.</div>
                                        </div>
                                    </div>
                                    <div class="form-group row">
                                        <label  class="col-sm-4 col-form-label clear-padding-right">Zone Name</label>
                                        <div class="col-sm-7 clear-left-padding">
                                            <select [(ngModel)]="StateModel.ZoneId" tabindex="4" id ="ZoneId" class="form-control"  name="ZoneId" #ZoneId="ngModel" >
                                            <option [value]="0" [disabled]=true >Select Zone Name</option>
                                            <option *ngFor="let Z of Zones" [value]="Z.ZoneID">{{Z.ZoneName}}</option>
                                            </select>
                                        </div>
                                        <div class="required-validation2"  *ngIf="StateModel.ZoneId==0">Zone is required</div>
                                    </div>
                                </div>
                            </div>
                            
                            <div class="col-lg-4">
                                <div class="col-body-border-right">
                                    <div class="form-group row">
                                        <label  class="col-sm-4 col-form-label clear-padding-right">State Name</label>
                                        <div class="col-sm-7 clear-left-padding">
                                            <input type="text" [(ngModel)]="StateModel.StateName" (blur)="CheckStateName()" tabindex="2" id ="StateName" name="StateName" #StateName="ngModel" required maxlength="250" class="form-control form-control-custom form-control-custom-new" >
                                        </div>
                                        <div *ngIf="this.StateNamecheck.length > 0" class="required-validation2">State Name is exist</div>
                                        <div *ngIf="StateName.invalid && (StateName.dirty || StateName.touched)" class="required-validation2">
                                            <div *ngIf="StateName.errors.required">State Name is required.</div>
                                        </div>
                                    </div>
                                    <div class="form-group row">
                                        
                                    </div>
                                </div>
                            </div>
        
                            <div class="col-lg-4">
                                <div class="form-group row">
                                    <label  class="col-sm-4 col-form-label clear-padding-right">Country Name</label>
                                    <div class="col-sm-7 clear-left-padding">
                                        <select  (ngModelChange)="onCountryChange($event)" [(ngModel)]="StateModel.CountryId" tabindex="3" id ="CountryId" class="form-control"  name="CountryId" #CountryId="ngModel" (blur)="CheckStateName()">
                                            <option [value]="0" [disabled]=true >Select Country Name</option>
                                            <option *ngFor="let C of Countries" [value]="C.CountryId">{{C.CountryName}}</option>
                                        </select>
                                    </div>
                                    <div class="required-validation2"  *ngIf="StateModel.CountryId==0">Country is required</div>
                                </div>
                                <div class="form-group row">
                                    <label class="col-sm-6 col-form-label text-center clear-padding-right" >
                                        <!-- *ngIf="!(this.IsDataIsInViewState)" -->
                                        <button type="submit" tabindex="5" class="btn btn-primary save-button" [disabled]="!(StateForm.valid && this.StateCheckNameExiststatus==true && StateModel.CountryId !=0 && StateModel.ZoneId !=0 && this.DisableSave==true)" (click)="modalRef.hide()">Save</button>  
                                    </label>
                                    <div class="col-sm-6 col-form-label text-center">
                                    <button type="button" tabindex="6" (click)="modalRef.hide()" class="btn btn-danger cancle-button">Close</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </fieldset>
            </div>

            <div *ngIf="(this.ShowDistrict)">
                <fieldset class="body-form-control">
                    <legend class="legend--">District Creation</legend>
                    <form #DistrictForm="ngForm" (ngSubmit)="saveDistrict()">
                        <div class="row">
                            <div class="col-lg-4">
                                <div class="col-body-border-right">
                                    <div class="form-group row">
                                        <label class="col-sm-4 col-form-label clear-padding-right">District Code</label>
                                        <div class="col-sm-7 clear-left-padding">
                                            <input type="text" [(ngModel)]="DistrictModel.DistCode" tabindex="1" maxlength="40" id ="DistCode"  name="DistCode" #DistCode="ngModel" required class="form-control form-control-custom form-control-custom-new" >
                                        </div>
                                        
                                        <div *ngIf="DistCode.invalid && (DistCode.dirty || DistCode.touched)" class="required-validation2">
                                            <div *ngIf="DistCode.errors.required">District Code is required.</div>
                                        </div>
                                    </div>
                                    <div class="form-group row">
                                        <label  class="col-sm-4 col-form-label clear-padding-right">State</label>
                                        <div class="col-sm-7 clear-left-padding">
                                            <select [(ngModel)]="DistrictModel.StateId" tabindex="4" id ="StateId" class="form-control" name="StateId" #StateId="ngModel" >
                                                <option [value]="0" [disabled]=true >Select State</option>
                                                <option *ngFor="let s of States" [value]="s.StateID">{{s.StateName}}</option>
                                            </select>
                                        </div>
                                        <div class="required-validation2" *ngIf="DistrictModel.StateId==0">State is required</div>
                                    </div>
                                </div>
                            </div>
                            
                            <div class="col-lg-4">
                                <div class="col-body-border-right">
                                    <div class="form-group row">
                                        <label  class="col-sm-4 col-form-label clear-padding-right">District Name</label>
                                        <div class="col-sm-7 clear-left-padding">
                                            <input type="text" [(ngModel)]="DistrictModel.DistName" tabindex="2" id ="DistName" name="DistName" #DistName="ngModel" (blur)="CheckName()" required maxlength="250" class="form-control form-control-custom form-control-custom-new" >
                                        </div>
                                        <div *ngIf="this.Namecheck.length > 0" class="required-validation2">District Name is exist</div>
                                        <div *ngIf="DistName.invalid && (DistName.dirty || DistName.touched)" class="required-validation2">
                                            <div *ngIf="DistName.errors.required">District Name is required.</div>
                                        </div>
                                    </div>
                                    <div class="form-group row">
                                        
                                    </div>
                                </div>
                            </div>
            
                            <div class="col-lg-4">
                                <div class="form-group row">
                                    <label  class="col-sm-4 col-form-label clear-padding-right">Country</label>
                                    <div class="col-sm-7 clear-left-padding">
                                        <select [(ngModel)]="DistrictModel.CountryId" tabindex="3" (ngModelChange)="onChange($event)" id ="CountryId" class="form-control"  name="CountryId" #CountryId="ngModel" >
                                            <option [value]="0" [disabled]=true >Select Country</option>
                                            <option *ngFor="let c of Countries" [value]="c.CountryId">{{c.CountryName}}</option>
                                        </select>
                                    </div>
                                    <div class="required-validation2" *ngIf="DistrictModel.CountryId==0">Country is required</div>
                                </div>
                                <div class="form-group row">
                                    <label class="col-sm-6 col-form-label text-center clear-padding-right">
                                        <!-- *ngIf="!(this.IsDataIsInViewState)" -->
                                        <button type="submit" tabindex="4" class="btn btn-primary save-button" [disabled]="!(DistrictForm.valid && this.CheckNameExiststatus==true && DistrictModel.CountryId !=0 && this.DisableSave==true)" (click)="modalRef.hide()">Save</button>  
                                    </label>
                                    <div class="col-sm-6 col-form-label text-center">
                                        <button type="button" tabindex="5" class="btn btn-danger cancle-button" (click)="modalRef.hide()">Close</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </fieldset>
            </div>

            <div *ngIf="(this.ShowCity)">
                <fieldset class="body-form-control">
                    <legend class="legend--">City Creation</legend>
                    <form #CityForm="ngForm" (ngSubmit)="saveCity()">
                        <div class="row">
                            <div class="col-lg-4">
                                <div class="col-body-border-right">
                                    <div class="form-group row">
                                        <label class="col-sm-4 col-form-label clear-padding-right">City Code</label>
                                        <div class="col-sm-7 clear-left-padding">
                                            <input type="text" [(ngModel)]="CityModel.CityCode" tabindex="1" maxlength="40" id ="CityCode"  name="CityCode" #CityCode="ngModel" required class="form-control form-control-custom form-control-custom-new" >
                                        </div>
                                        <div *ngIf="CityCode.invalid && (CityCode.dirty || CityCode.touched)" class="required-validation2">
                                            <div *ngIf="CityCode.errors.required">City Code is required.</div>
                                        </div>
                                    </div>
                                    <div class="form-group row">
                                    <label  class="col-sm-4 col-form-label clear-padding-right">State</label>
                                    <div class="col-sm-7 clear-left-padding">
                                        <select [(ngModel)]="CityModel.StateId" tabindex="4" id ="StateId" (ngModelChange)="onStateChange($event)" class="form-control" name="StateId" #StateId="ngModel" >
                                            <option [value]="0" [disabled]=true >Select State</option>
                                            <option *ngFor="let s of States" [value]="s.StateID">{{s.StateName}}</option>
                                        </select>
                                    </div>
                                    <div class="required-validation2" *ngIf="CityModel.StateId==0">State is required</div>
                                    </div>
                                </div>
                            </div>
                            
                            <div class="col-lg-4">
                                <div class="col-body-border-right">
                                    <div class="form-group row">
                                        <label  class="col-sm-4 col-form-label clear-padding-right">City Name</label>
                                        <div class="col-sm-7 clear-left-padding">
                                            <input type="text" [(ngModel)]="CityModel.CityName" (blur)="CheckCityName()" tabindex="2" id ="CityName" name="CityName" #CityName="ngModel" required maxlength="250" class="form-control form-control-custom form-control-custom-new" >
                                        </div>
                                        <div *ngIf="this.CityNamecheck.length > 0" class="required-validation2">City Name is exist</div>
                                        <div *ngIf="CityName.invalid && (CityName.dirty || CityName.touched)" class="required-validation2">
                                            <div *ngIf="CityName.errors.required">City Name is required.</div>
                                        </div>
                                    </div>
                                    <div class="form-group row">
                                    <label  class="col-sm-4 col-form-label clear-padding-right">District</label>
                                    <div class="col-sm-7 clear-left-padding">
                                        <select [(ngModel)]="CityModel.DistId" tabindex="5" id ="DistId" class="form-control" name="DistId" #DistId="ngModel" (blur)="CheckCityName()">
                                            <option [value]="0" [disabled]=true >Select District</option>
                                            <option *ngFor="let ds of Districts" [value]="ds.DistId">{{ds.DistName}}</option>
                                        </select>
                                    </div>
                                    <div class="required-validation" *ngIf="CityModel.DistId==0">District is required</div>   
                                    </div>
                                </div>
                            </div>
            
                            <div class="col-lg-4">
                                <div class="form-group row">
                                <label  class="col-sm-4 col-form-label clear-padding-right">Country</label>
                                <div class="col-sm-7 clear-left-padding">
                                    <select [(ngModel)]="CityModel.CountryId" tabindex="3" (ngModelChange)="oncountryChange($event)" id ="CountryId" class="form-control"  name="CountryId" #CountryId="ngModel" >
                                        <option [value]="0" [disabled]=true >Select Country</option>
                                        <option *ngFor="let c of Countries" [value]="c.CountryId">{{c.CountryName}}</option>
                                    </select>
                                </div>
                                <div class="required-validation" *ngIf="CityModel.CountryId==0">Country is required</div>
                                </div>
                                <div class="form-group row">
                                    <label class="col-sm-6 col-form-label text-center clear-padding-right">
                                        <!-- *ngIf="!(this.IsDataIsInViewState)" -->
                                        <button type="submit" tabindex="6" class="btn btn-primary save-button" [disabled]="!(CityForm.valid && this.CityCheckNameExiststatus==true && CityModel.DistId !=0 && this.DisableSave==true)" (click)="modalRef.hide()">Save</button>  
                                    </label>
                                    <div class="col-sm-6 col-form-label text-center">
                                    <button type="button" tabindex="7" (click)="modalRef.hide()" class="btn btn-danger cancle-button">Close</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </fieldset>
            </div>
        </div>
    </ng-template>
</div>