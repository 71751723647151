<div style=" width: 100%;" class="body-content-area tabcontent exact-modal" id="link1">
  
  <div style=" width: 100%;" class="row">
      <div class="col-lg-9">
          <div class="row">
              <div class="col-lg-1 low-padding text-center">
                <!-- <img *ngIf="this.SelectedMenu.Image!=null" src={{this.SelectedMenu.Image}} class="body-icon" alt="Icon"/> -->
              </div>
              <div class="col-lg-10 clear-padding">
                <span class="page-title">Approve/Reject Employee Personal Information (Admin/HR)</span>
                <small class="page-description"></small>
              </div>
          </div>
      </div>
  </div>

  <div style=" width: 100%;" *ngIf="!(this.CanViewState)">
    
    <div *ngIf="NoRecordMsg" style="color: #dc3545; text-align: center;font-size: 15px; font-weight: bold;">No Record Found.</div>

    <table id="tblApplication" *ngIf="(this.ListApplication.length > 0)" class="table table-striped table-bordered text-center table-new row-border hover">
      <thead class="thead-blue text-center">
        <tr style=" width: 100%;">
          <th style=" width: 8%;">Sl No.</th>
          <th style=" width: 8%;">Code</th>
          <th style=" width: 20%;" class="text-left">Name</th>
          <th style=" width: 8%;">Personal</th>
          <th style=" width: 8%;">Address</th>
          <th style=" width: 8%;">Other</th>
          <th style=" width: 8%;">Qualification</th>
          <th style=" width: 8%;">Experience</th>
          <th style=" width: 8%;">Family</th>
          <th style=" width: 18%;">Reason</th>
          <th style=" width: 8%;">View</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let appl of ListApplication, let i = index">
          <td scope="row">{{i+1}}</td>
          <td>{{appl.EmployeeCode}}</td>
          <td class="text-left">{{appl.EmployeeName}}</td>
          <td>{{appl.PersonalDetails}}</td>
          <td>{{appl.AddressDetails}}</td>
          <td>{{appl.OtherDetails}}</td>
          <td>{{appl.QualificationDetails}}</td>
          <td>{{appl.ExperienceDetails}}</td>
          <td>{{appl.FamilyDetails}}</td>
          <td class="text-left">{{appl.Reason}}</td>
          <td><button *ngIf="this.SelectedMenu.CanView" type="button" (click)="viewAppliedApplicationByRefId(appl.RefIdApplication,appl.PersonalDetails,appl.AddressDetails,appl.OtherDetails,appl.QualificationDetails,appl.ExperienceDetails,appl.FamilyDetails,template)" class="table-view-button" type="button" ><i class="fas fa-eye"></i></button></td>
        </tr>
      </tbody>
      <tfoot class="thead-blue text-center">
        <tr>
          <th></th>
          <th></th>
          <th></th>
          <th></th>
          <th></th>
          <th></th>
          <th></th>
          <th></th>
          <th></th>
          <th></th>
          <th></th>
        </tr>
      </tfoot>
    </table>

    <div style=" box-align: center; width: 1500px;" >
      <ng-template #template>
        <div class="modal-header" style="background: #2a9bd7; max-width: 1500px;"> 
          <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()" style="color:#fff;">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div  style="text-align: center; max-width: 1500px;">
          <div *ngIf="ApprovedMsg" style="color: #dc3545; text-align: center;font-size: 15px; font-weight: bold;">Application Approved Successfully</div>
          <div *ngIf="RejectedMsg" style="color: #dc3545; text-align: center;font-size: 15px; font-weight: bold;">Application Rejected Successfully</div>
          <div style=" width: 100%;" *ngIf="ShowPopup">
            <div class="row">
              <div class="col-lg-4">
                <div class="col-body-border-right">
                  <div class="form-group row">
                    <label  class="col-sm-4 col-form-label clear-padding-right">Employee Code</label>
                    <div class="col-sm-7 clear-left-padding">
                      :&nbsp;&nbsp;{{this.EmployeeMasterService.SharedEmployees.EmployeeCode}}
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-4">
                <div class="col-body-border-right">
                  <div class="form-group row">
                    <label  class="col-sm-4 col-form-label clear-padding-right">Name</label>
                    <div class="col-sm-7 clear-left-padding">
                      :&nbsp;&nbsp;{{this.EmployeeMasterService.SharedEmployees.FullName}}
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-4">
                <div class="form-group row">
                    
                </div>
              </div>
            </div>
            
            <fieldset class="body-form-control" *ngIf="(this.EmployeeMasterService.SharedEmployees.PersonalDetails)"> 
              <legend class="legend--">Employee Personal Details</legend>
              <form #EmployeeDetailForm="ngForm" style=" text-align: left;">
                <div class="row">
                  <div class="col-lg-4">
                    <div class="col-body-border-right">
                      <div class="form-group row">
                        <label  class="col-sm-4 col-form-label clear-padding-right">First Name</label>
                        <div class="col-sm-7 clear-left-padding">
                          :&nbsp;&nbsp;{{this.EmployeeMasterService.SharedEmployees.FirstName}}
                        </div>
                      </div>
                      <div class="form-group row">
                        <label  class="col-sm-4 col-form-label clear-padding-right">DOB</label>
                        <div class="col-sm-7 clear-left-padding">
                          :&nbsp;&nbsp;{{this.EmployeeMasterService.SharedEmployees.DOB | date : "dd-MMM-yyyy"}}
                        </div>
                      </div>
                      <div class="form-group row">
                        <label  class="col-sm-4 col-form-label clear-padding-right">Mother Tongue</label>
                        <div class="col-sm-7 clear-left-padding">
                          :&nbsp;&nbsp;{{this.EmployeeMasterService.SharedEmployees.MotherTongue}}
                        </div>
                      </div>
                      <div class="form-group row">
                        <label  class="col-sm-4 col-form-label clear-padding-right">Email Id</label>
                        <div class="col-sm-7 clear-left-padding">
                          :&nbsp;&nbsp;{{this.EmployeeMasterService.SharedEmployees.EmailId}}
                        </div>
                      </div>
                      <div class="form-group row">
                        <label  class="col-sm-4 col-form-label clear-padding-right">Marital Status</label>
                        <div class="col-sm-7 clear-left-padding">
                          :&nbsp;&nbsp;{{this.EmployeeMasterService.SharedEmployees.MaritalName}}
                        </div>
                      </div>
                      <div class="form-group row">
                          <label class="col-sm-4 col-form-label clear-padding-right">Mobile No</label>
                          <div class="col-sm-7 clear-left-padding">
                            :&nbsp;&nbsp;{{this.EmployeeMasterService.SharedEmployees.ContactNumber}}
                          </div>
                        </div>
                    </div>
                  </div>
        
                  <div class="col-lg-4">
                    <div class="col-body-border-right">
                      <div class="form-group row">
                        <label  class="col-sm-4 col-form-label clear-padding-right" >Middle Name</label>
                        <div class="col-sm-7 clear-left-padding">
                          :&nbsp;&nbsp;{{this.EmployeeMasterService.SharedEmployees.MiddleName}}
                        </div>
                      </div>
                      <div class="form-group row">
                        <label  class="col-sm-4 col-form-label clear-padding-right">Father's Name</label>
                        <div class="col-sm-7 clear-left-padding">
                          :&nbsp;&nbsp;{{this.EmployeeMasterService.SharedEmployees.FathersName}}
                        </div>
                      </div>
                      <div class="form-group row">
                        <label  class="col-sm-4 col-form-label clear-padding-right">Gender</label>
                        <div class="col-sm-7 clear-left-padding">
                          :&nbsp;&nbsp;{{this.EmployeeMasterService.SharedEmployees.GenderName}}
                        </div>
                      </div>
                      <div class="form-group row">
                        <label  class="col-sm-4 col-form-label clear-padding-right">Blood Group</label>
                        <div class="col-sm-7 clear-left-padding">
                          :&nbsp;&nbsp;{{this.EmployeeMasterService.SharedEmployees.BloodGroup}}
                        </div>
                      </div>
                      <div *ngIf="this.EmployeeMasterService.SharedEmployees.MaritalId == 1" class="form-group row">
                        <label class="col-sm-4 col-form-label clear-padding-right">Anniversary Date</label>
                        <div class="col-sm-7 clear-left-padding">
                          :&nbsp;&nbsp;{{this.EmployeeMasterService.SharedEmployees.AnniversaryDate | date : "dd-MMM-yyyy"}}
                        </div>
                      </div>
                    </div>
                  </div>
        
                  <div class="col-lg-4">
                    <div class="form-group row">
                      <label  class="col-sm-4 col-form-label clear-padding-right">Last Name</label>
                      <div class="col-sm-7 clear-left-padding">
                        :&nbsp;&nbsp;{{this.EmployeeMasterService.SharedEmployees.LastName}}
                      </div>
                    </div>
                    <div class="form-group row">
                      <label  class="col-sm-4 col-form-label clear-padding-right">Mother's Name</label>
                      <div class="col-sm-7 clear-left-padding">
                        :&nbsp;&nbsp;{{this.EmployeeMasterService.SharedEmployees.MothersName}}
                      </div>
                    </div>
                    <div class="form-group row">
                      <label class="col-sm-4 col-form-label clear-padding-right">Religion</label>
                      <div class="col-sm-7 clear-left-padding">
                        :&nbsp;&nbsp;{{this.EmployeeMasterService.SharedEmployees.ReligionName}}
                      </div>
                    </div>
                    <div class="form-group row">
                      <label  class="col-sm-4 col-form-label clear-padding-right">Photo</label>
                      <div class="col-sm-7 clear-left-padding">
                        <img *ngIf="this.EmployeeMasterService.SharedEmployees.ImageFile!=null" [src]="EmployeeMasterService.SharedEmployees.ImageFile" style="width:175px; height: 100px;">
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </fieldset>
      
            <fieldset class="body-form-control" *ngIf="(this.EmployeeMasterService.SharedEmployees.AddressDetails)"> 
              <legend class="legend--">Employee Address Details</legend>
              <form #AddressForm="ngForm" style=" text-align: left;">
                <div class="row">
                  <div class="col-lg-4">
                    <div class="col-body-border-right">
                      <div class="form-group row">
                        <label  class="col-sm-4 col-form-label clear-padding-right">House No/ Street</label>
                        <div class="col-sm-7 clear-left-padding">
                          :&nbsp;&nbsp;{{this.EmployeeMasterService.SharedEmployees.CAddress1}}
                        </div>
                      </div>
                      <div class="form-group row">
                        <label  class="col-sm-4 col-form-label clear-padding-right">Country</label>
                        <div class="col-sm-7 clear-left-padding">
                          :&nbsp;&nbsp;{{this.EmployeeMasterService.SharedEmployees.CCountry}}
                        </div>
                      </div>
                      <div class="form-group row">
                        <label  class="col-sm-4 col-form-label clear-padding-right">City</label>
                        <div class="col-sm-7 clear-left-padding">
                          :&nbsp;&nbsp;{{this.EmployeeMasterService.SharedEmployees.CCity}}
                        </div>
                      </div>
                    </div>
                  </div>
                  
                  <div class="col-lg-4">
                    <div class="col-body-border-right">
                      <div class="form-group row">
                        <label class="col-sm-4 col-form-label clear-padding-right">Address</label>
                        <div class="col-sm-7 clear-left-padding">
                          :&nbsp;&nbsp;{{this.EmployeeMasterService.SharedEmployees.CAddress2}}
                        </div>
                      </div>
                      <div class="form-group row">
                        <label  class="col-sm-4 col-form-label clear-padding-right">State</label>
                        <div class="col-sm-7 clear-left-padding">
                          :&nbsp;&nbsp;{{this.EmployeeMasterService.SharedEmployees.CState}}
                        </div>
                      </div>
                      <div class="form-group row">
                        <label  class="col-sm-4 col-form-label clear-padding-right">Pin Code</label>
                        <div class="col-sm-7 clear-left-padding">
                          :&nbsp;&nbsp;{{this.EmployeeMasterService.SharedEmployees.CPincode}}
                        </div>
                      </div>
                    </div>
                  </div>
      
                  <div class="col-lg-4">
                    <div class="form-group row">
                      <label  class="col-sm-4 col-form-label clear-padding-right">Contact No</label>
                      <div class="col-sm-7 clear-left-padding">
                        :&nbsp;&nbsp;{{this.EmployeeMasterService.SharedEmployees.CContactNo}}
                      </div>
                    </div>
                    <div class="form-group row">
                      <label class="col-sm-4 col-form-label clear-padding-right">District</label>
                      <div class="col-sm-7 clear-left-padding">
                        :&nbsp;&nbsp;{{this.EmployeeMasterService.SharedEmployees.CDistrict}}
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </fieldset>
      
            <fieldset class="body-form-control" *ngIf="(this.EmployeeMasterService.SharedEmployees.OtherDetails)"> 
              <legend class="legend--">Employee Other Details</legend>
              <form #EmployeeDetailForm="ngForm" style=" text-align: left;">
                <div class="row">
                  <div class="col-lg-4">
                    <div class="col-body-border-right">
                      <div class="form-group row">
                        <label  class="col-sm-4 col-form-label clear-padding-right">PAN No</label>
                        <div class="col-sm-7 clear-left-padding">
                          :&nbsp;&nbsp;{{this.EmployeeMasterService.SharedEmployees.PANNo}}
                        </div>
                      </div>
                      <div class="form-group row">
                        <label  class="col-sm-4 col-form-label clear-padding-right">Voter Id</label>
                        <div class="col-sm-7 clear-left-padding">
                          :&nbsp;&nbsp;{{this.EmployeeMasterService.SharedEmployees.VoterId}}
                        </div>
                      </div>
                      <div class="form-group row">
                        <label  class="col-sm-4 col-form-label clear-padding-right">Aadhaar No</label>
                        <div class="col-sm-7 clear-left-padding">
                          :&nbsp;&nbsp;{{this.EmployeeMasterService.SharedEmployees.AdhaarNo}}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-4">
                    <div class="col-body-border-right">
                      <div class="form-group row">
                        <label class="col-sm-4 col-form-label clear-padding-right">EPF No</label>
                        <div class="col-sm-7 clear-left-padding">
                          :&nbsp;&nbsp;{{this.EmployeeMasterService.SharedEmployees.EPFNo}}
                        </div>                    
                      </div>
                      <div class="form-group row">
                        <label  class="col-sm-4 col-form-label clear-padding-right">ESI No</label>
                        <div class="col-sm-7 clear-left-padding">
                          :&nbsp;&nbsp;{{this.EmployeeMasterService.SharedEmployees.ESINo}}
                        </div>
                      </div>
                      <div class="form-group row">
                        <label  class="col-sm-4 col-form-label clear-padding-right">Gratuity No</label>
                        <div class="col-sm-7 clear-left-padding">
                          :&nbsp;&nbsp;{{this.EmployeeMasterService.SharedEmployees.GratuityNo}}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-4">
                    <div class="form-group row">
                      <label  class="col-sm-4 col-form-label clear-padding-right">Pension No</label>
                      <div class="col-sm-7 clear-left-padding">
                        :&nbsp;&nbsp;{{this.EmployeeMasterService.SharedEmployees.PensionNo}}
                      </div>
                    </div>
                    <div class="form-group row">
                      <label  class="col-sm-4 col-form-label clear-padding-right">Superannuation No</label>
                      <div class="col-sm-7 clear-left-padding">
                        :&nbsp;&nbsp;{{this.EmployeeMasterService.SharedEmployees.SuperannuationNo}}
                      </div>
                    </div>
                    <div class="form-group row">
                      <label  class="col-sm-4 col-form-label clear-padding-right">UAN No</label>
                      <div class="col-sm-7 clear-left-padding">
                        :&nbsp;&nbsp;{{this.EmployeeMasterService.SharedEmployees.UANNo}}
                      </div>
                    </div>  
                  </div>
                </div>
              </form>
            </fieldset>
      
            <fieldset class="body-form-control" *ngIf="(this.EmployeeMasterService.SharedEmployees.QualificationDetails)"> 
              <legend class="legend--">Employee Qualification Details</legend>
              <form #EmployeeEducationDetailForm="ngForm">
                <div lass="row" >
                  <table id="tblComponent" class="table table-striped table-bordered text-left table-new row-border hover">
                    <thead class="thead-blue text-center">
                      <tr>
                        <th style="width: 15%;">Degree </th>
                        <th style="width: 15%;">Qualification</th>
                        <th style="width: 15%;">InstituteName</th>
                        <th style="width: 10%;">University/Board</th>
                        <th style="width: 5%;">Marks/Grade(%)</th>
                        <th style="width: 10%;">Division</th>
                        <th style="width: 15%;">From Date</th>
                        <th style="width: 15%;">To Date</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let Edu of this.EmployeeMasterService.SharedEmployees.EmployeeEducation, let i = index">
                        <td >
                          {{Edu.DegreeName}}
                        </td>
                        <td >
                          {{Edu.DegreeCode}}
                        </td>
                        <td>
                          {{Edu.Institute}}
                        </td>
                        <td >
                          {{Edu.BoardUniversity}}
                        </td>
                        <td >
                          {{Edu.MarksPercentage}}
                        </td>
                        <td >
                          {{Edu.Division}}
                        </td>
                        <td >
                          {{Edu.FromDate | date : "dd-MMM-yyyy"}}
                        </td>
                        <td >
                          {{Edu.ToDate | date : "dd-MMM-yyyy"}}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </form>
            </fieldset>
      
            <fieldset class="body-form-control" *ngIf="(this.EmployeeMasterService.SharedEmployees.ExperienceDetails)"> 
              <legend class="legend--">Employee Experience Details</legend>
              <form #EmployeeExperienceDetailForm="ngForm">
                <div lass="row" >
                  <table id="tblComponent" class="table table-striped table-bordered text-left table-new row-border hover">
                    <thead class="thead-blue text-center">
                      <tr>
                        <th style="width: 25%;">Name Of Employeer</th>
                        <th style="width: 25%;">Designation</th>  
                        <th style="width: 25%;">Form Date</th>
                        <th style="width: 25%;">To Date</th>       
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let Exp of this.EmployeeMasterService.SharedEmployees.EmployeeExprienceModel, let i = index">
                        <td >
                          {{Exp.ExperinceCompany}}
                        </td>
                        <td >
                          {{Exp.ExperinceDesignation}}
                        </td>  
                        <td >
                          {{Exp.ExperinceFromDate | date : "dd-MMM-yyyy"}}
                        </td>
                        <td >
                          {{Exp.ExperinceToDate | date : "dd-MMM-yyyy"}}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </form>
            </fieldset>
      
            <fieldset class="body-form-control" *ngIf="(this.EmployeeMasterService.SharedEmployees.FamilyDetails)"> 
              <legend class="legend--">Employee Family Details</legend>
              <form #EmployeeFamilyDetailForm="ngForm">
                <div lass="row" >
                  <table id="tblComponent" class="table table-striped table-bordered text-left table-new row-border hover">
                    <thead class="thead-blue text-center">
                      <tr>
                        <th style="width: 25%;">Relation </th>
                        <th style="width: 25%;">Name</th>  
                        <th style="width: 25%;">Gender</th>
                        <th style="width: 25%;">Date Of Birth</th>       
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let Fam of this.EmployeeMasterService.SharedEmployees.EmployeeFamilyDetailsModel, let i = index">
                        <td>
                          {{Fam.FamilyRelation}}
                        </td>
                        <td>
                          {{Fam.FamilyMemberName}}
                        </td>
                        <td>
                          {{Fam.FamilyGender}}
                        </td>
                        <td >                   
                          {{Fam.FamilyDOB | date : "dd-MMM-yyyy"}}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </form>
            </fieldset>

            <fieldset class="body-form-control" *ngIf="(this.EmployeeMasterService.SharedEmployees.PersonalDetails || this.EmployeeMasterService.SharedEmployees.AddressDetails || this.EmployeeMasterService.SharedEmployees.OtherDetails || this.EmployeeMasterService.SharedEmployees.QualificationDetails || this.EmployeeMasterService.SharedEmployees.ExperienceDetails || this.EmployeeMasterService.SharedEmployees.FamilyDetails)">
                <legend class="legend--"></legend>
                <form #ApprovedApplicationForm="ngForm" style=" text-align: left;">
                  <div class="row">
                    <div class="col-lg-4">
                      <div class="col-body-border-right">
                        <div class="form-group row">
                          <label  class="col-sm-4 col-form-label clear-padding-right">Remarks</label>
                          <div class="col-sm-7 clear-left-padding">
                            <input type="text" [(ngModel)]="this.EmployeeMasterService.SharedEmployees.Remarks" tabindex="1" maxlength="500" id ="Remarks" name="Remarks" #Remarks="ngModel" required class="form-control form-control-custom form-control-custom-new">
                          </div>
                          <div *ngIf="Remarks.invalid && (Remarks.dirty || Remarks.touched)" class="alert alert-danger">
                            <div *ngIf="Remarks.errors.required">Remarks is required.</div>
                            <div *ngIf="Remarks.errors.maxlength">Remarks is not more than 500 characters</div>
                          </div> 
                        </div>
                      </div>
                    </div>
                    
                    <div class="col-lg-4">
                      <div class="col-body-border-right">
                        <div class="form-group row">
                          <label *ngIf="!(this.CanApproveState)" class="col-sm-6 col-form-label text-center clear-padding-right">
                            <button type="submit" tabindex="2" (click)="PersonalInfoApproved(template)" class="btn btn-primary save-button" [disabled]="!(ApprovedApplicationForm.valid && this.DisableSave==true)">Approved</button>  
                          </label>
                          <div *ngIf="!(this.CanApproveState)" class="col-sm-6 col-form-label text-center">
                            <button type="submit" tabindex="3" (click)="PersonalInfoRejected(template)" class="btn btn-danger cancle-button" [disabled]="!(ApprovedApplicationForm.valid && this.DisableSave==true)">Rejected</button>  
                          </div>
                        </div>
                      </div>
                    </div>
        
                    <div class="col-lg-4">

                    </div>
                  </div>
                </form>
              </fieldset>
          </div>

          <button type="button" aria-label="Close" (click)="modalRef.hide()" style="background: #2a9bd7; outline: 0px none; border: 0px none; color: #fff; padding: 5px 10px; border-radius: 3px;">
            <span>Close</span>
          </button>             
        </div>
      </ng-template>
    </div>
  </div>
</div>