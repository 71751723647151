export class StandardSalaryModel
{
    public EmployeeId: number;
    public EmployeeCode: string;
    public EmployeeName: string;
    public SalaryComponentId: number;  
    public SalaryComponentName: string;
    public SalaryComponentCode : string;
    public Amount: number;
    public AmountYearly: number;
    public IsBenefit: boolean;
    public IsActive : boolean;
    public CurrentDate : Date;
    public CTCAmount : number;
    public EPFApplicable : boolean;
    public ESIApplicable : boolean;
    public PTAXApplicable : boolean;
    public PtaxStateId : number;
    //public BranchStateId : number;
    public NetTakeHome : number;
    public GrossSalary : number;

    public RefIdSSM: number;
    public ModificationIsBenefit: boolean;
    public ModificationAmount: number;
    public ApplicableFrom: Date;
    public ModificationApplicableFrom : Date;
    public ModificationNetTakeHome : number;
    public ModificationGrossSalary : number;
    public ModificationCTCAmount : number;
    public ModificationEPFApplicable : boolean;
    public ModificationESIApplicable : boolean;
    public ModificationPTAXApplicable : boolean;
}