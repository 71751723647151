<div class="body-content-area tabcontent" id="link1">
  <div class="row">
      <div class="col-lg-9">
          <div class="row">
              <div class="col-lg-1 low-padding text-center">
                  <!-- <img *ngIf="this.SelectedMenu.Image!=null" src={{this.SelectedMenu.Image}} class="body-icon" alt="Icon"/> -->
              </div>
              <div class="col-lg-10 clear-padding">
                  <span class="page-title">Approve/Reject Attendance Regularization (Admin/HR)</span>
                  <small class="page-description"></small>
              </div>
          </div>
      </div>
  </div>

  <div *ngIf="!(this.CanViewState)">  
    
    <div *ngIf="NoRecordMsg" style="color: #dc3545; text-align: center;font-size: 15px; font-weight: bold;">No Record Found.</div>

    <table id="tblApplication" *ngIf="(this.ListApplication.length > 0)" class="table table-striped table-bordered text-center table-new row-border hover">
      <thead class="thead-blue text-center">
        <tr>
          <th style=" width: 10%;">Sl No.</th>
          <th style=" width: 10%;">Code</th>
          <th style=" width: 30%;" class="text-left">Name</th>
          <th style=" width: 10%;">Date</th>
          <th style=" width: 10%;">In-Time</th>
          <th style=" width: 10%;">Out-Time</th>
          <th style=" width: 10%;">Action</th>
          <th style=" width: 10%;">
            <input type="checkbox" name="all" (change)="checkAll($event)"/>Select All
          </th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let appl of ListApplication, let i = index">
          <td scope="row">{{i+1}}</td>
          <td>{{appl.EmployeeCode}}</td>
          <td class="text-left">{{appl.EmployeeName}}</td>
          <td>{{appl.AttendanceDate | date : "dd-MMM-yyyy"}}</td>
          <td>{{appl.StartTime }}</td>
          <td>{{appl.EndTime }}</td>
          <td>
            <button *ngIf="this.SelectedMenu.CanView" type="button" (click)="viewAppliedRegularization(appl.RefId,template)" class="table-view-button" type="button" ><i class="fas fa-eye"></i></button>
          </td>
          <td><input type="checkbox" [checked]="appl.Selected" id ="Selected_{{i}}" name="Selected_{{i}}" (change)="SelectedChange(i,$event)"></td>
        </tr>
      </tbody>
    </table>
    <form #RemarksForm="ngForm" *ngIf="(this.ListApplication.length > 0)">
      <div class="row">
        <div class="col-lg-4">
          <div class="col-body-border-right">
            <div class="form-group row">
              <label  class="col-sm-4 col-form-label clear-padding-right">Remarks</label>
              <div class="col-sm-7 clear-left-padding">
                <input type="text" [(ngModel)]="ModelListApplication.RemarksAll" tabindex="1" maxlength="500" id ="RemarksAll" name="RemarksAll" #RemarksAll="ngModel" required class="form-control form-control-custom form-control-custom-new" >
              </div>
            </div>
          </div> 
        </div>

        <div class="col-lg-4">
          <div class="col-body-border-right">
            <div class="form-group row">
              <label *ngIf="!(this.CanApproveState)" class="col-sm-6 col-form-label text-center clear-padding-right">
                <button type="submit" (click)="AttendanceRegularationApprovedAll(template)" class="btn btn-primary save-button" [disabled]="!(RemarksForm.valid && this.DisableSave==true)">Approved All</button>  
              </label>
              <div *ngIf="!(this.CanApproveState)" class="col-sm-6 col-form-label text-center">
                <button type="submit" (click)="AttendanceRegularationRejectedAll(template)" class="btn btn-danger cancle-button" [disabled]="!(RemarksForm.valid && this.DisableSave==true)">Rejected All</button>  
              </div>
            </div>
          </div> 
        </div>

        <div class="col-lg-4">
          <div class="form-group row">
            <div class="col-sm-6 col-form-label text-center">
              <button type="button" (click)="RefreshPage()" class="btn btn-danger cancle-button">Refresh Page</button>
            </div>
          </div>
        </div>
      </div>
    </form>

    <div style=" box-align: center; width: 1500px;" >
      <ng-template #template>
        <div class="modal-header" style="background: #2a9bd7; max-width: 1500px;"> 
          <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()" style="color:#fff;">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div  style="text-align: center; max-width: 1500px;">
          <div *ngIf="ApprovedMsg" style="color: #dc3545; text-align: center;font-size: 15px; font-weight: bold;">Application Approved Successfully</div>
          <div *ngIf="RejectedMsg" style="color: #dc3545; text-align: center;font-size: 15px; font-weight: bold;">Application Rejected Successfully</div>
          <div style=" width: 100%;" *ngIf="ShowPopup">

            <fieldset class="body-form-control" > 
              <legend class="legend--">Attendance Regularization Details</legend>
              <form #ApprovedApplicationForm="ngForm" style=" text-align: left;">
                <div class="row">
                  <div class="col-lg-4">
                    <div class="col-body-border-right">
                      <div class="form-group row">
                        <label class="col-sm-4 col-form-label clear-padding-right">code</label>
                        <div class="col-sm-7 clear-left-padding">
                          :&nbsp;&nbsp;{{this.ModelListApplication.EmployeeCode}} 
                        </div>
                      </div>
                      <div class="form-group row">
                        <label  class="col-sm-4 col-form-label clear-padding-right">Date</label>
                        <div class="col-sm-7 clear-left-padding">
                          :&nbsp;&nbsp;{{this.ModelListApplication.AttendanceDate| date:'yyyy-MM-dd'}}
                        </div>
                      </div>
                      
                      <div class="form-group row">
                        <label  class="col-sm-4 col-form-label clear-padding-right">InTime</label>
                        <div class="col-sm-7 clear-left-padding">
                          :&nbsp;&nbsp;{{this.ModelListApplication.ActualInTime}}
                        </div>
                      </div>
                      <div class="form-group row">
                        <label  class="col-sm-4 col-form-label clear-padding-right">Start Time</label>
                        <div class="col-sm-7 clear-left-padding">
                          :&nbsp;&nbsp;{{this.ModelListApplication.StartTime}}
                        </div>
                      </div>
                      <div class="form-group row">
                        <label class="col-sm-4 col-form-label clear-padding-right">Regularized Hrs.</label>
                        <div class="col-sm-7 clear-left-padding">
                          :&nbsp;&nbsp;{{this.ModelListApplication.RegularationDuration}} 
                        </div>
                      </div>
                    </div> 
                  </div>
          
                  <div class="col-lg-4">
                    <div class="col-body-border-right">
                      <div class="form-group row">
                        <label class="col-sm-4 col-form-label clear-padding-right">Name</label>
                        <div class="col-sm-7 clear-left-padding">
                          :&nbsp;&nbsp;{{this.ModelListApplication.EmployeeName}} 
                        </div>
                      </div>
                      <div class="form-group row">
                        <label  class="col-sm-4 col-form-label clear-padding-right">Day</label>
                        <div class="col-sm-7 clear-left-padding">
                          :&nbsp;&nbsp;{{this.ModelListApplication.AttendanceDay}}
                        </div>
                      </div>
                      <div class="form-group row">
                        <label  class="col-sm-4 col-form-label clear-padding-right">OutTime</label>
                        <div class="col-sm-7 clear-left-padding">
                            :&nbsp;&nbsp;{{this.ModelListApplication.ActualOutTime}}
                        </div>
                      </div>
                      <div class="form-group row">
                        <label  class="col-sm-4 col-form-label clear-padding-right">End Time</label>
                        <div class="col-sm-7 clear-left-padding">
                          :&nbsp;&nbsp;{{this.ModelListApplication.EndTime}}
                        </div>
                      </div>
                      <div class="form-group row">
                        <label  class="col-sm-4 col-form-label clear-padding-right">Reason</label>
                        <div class="col-sm-7 clear-left-padding">
                          :&nbsp;&nbsp;{{this.ModelListApplication.Reason}}
                        </div>
                      </div>
                    </div> 
                  </div>
          
                  <div class="col-lg-4">
                    <div class="form-group row">
                      <label  class="col-sm-4 col-form-label clear-padding-right">Approver</label>
                      <div class="col-sm-7 clear-left-padding">
                        :&nbsp;&nbsp;{{this.ModelListApplication.RHCode}}-{{this.ModelListApplication.RHName}}
                      </div>
                    </div>
                    <div class="form-group row">
                      <label  class="col-sm-4 col-form-label clear-padding-right">RH EmailId</label>
                      <div class="col-sm-7 clear-left-padding">
                        :&nbsp;&nbsp;{{this.ModelListApplication.RHEmailId}}
                      </div>
                    </div>
                    <div class="form-group row">
                      <label  class="col-sm-4 col-form-label clear-padding-right">Duration</label>
                      <div class="col-sm-7 clear-left-padding">
                        :&nbsp;&nbsp;{{this.ModelListApplication.ActualWorkingDuration}}
                      </div>
                    </div>  
                    <div class="form-group row">
                      <label class="col-sm-4 col-form-label clear-padding-right">Working Hrs.</label>
                      <div class="col-sm-7 clear-left-padding">
                        :&nbsp;&nbsp;{{this.ModelListApplication.TotalWorkingHours}}
                      </div>
                    </div>
                    <div class="form-group row">
                      <label  class="col-sm-4 col-form-label clear-padding-right">Remarks</label>
                      <div class="col-sm-7 clear-left-padding">
                        <input type="text" [(ngModel)]="ModelListApplication.Remarks" tabindex="1" maxlength="500" id ="Remarks" name="Remarks" #Remarks="ngModel" required class="form-control form-control-custom form-control-custom-new" >
                      </div>
                      <div *ngIf="Remarks.invalid && (Remarks.dirty || Remarks.touched)" class="alert alert-danger">
                        <div *ngIf="Remarks.errors.required">Remarks is required.</div>
                        <div *ngIf="Remarks.errors.maxlength">Remarks is not more than 500 characters</div>
                      </div> 
                    </div>
                    <div class="form-group row">
                      <label *ngIf="!(this.CanApproveState)" class="col-sm-6 col-form-label text-center clear-padding-right">
                        <button type="submit" tabindex="2" (click)="AttendanceRegularationApproved(template)" class="btn btn-primary save-button" [disabled]="!(ApprovedApplicationForm.valid && this.DisableSave==true)">Approved</button>  
                      </label>
                      <div *ngIf="!(this.CanApproveState)" class="col-sm-6 col-form-label text-center">
                        <button type="submit" tabindex="3" (click)="AttendanceRegularationRejected(template)" class="btn btn-danger cancle-button" [disabled]="!(ApprovedApplicationForm.valid && this.DisableSave==true)">Rejected</button>  
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </fieldset>
          </div>

          <button type="button" aria-label="Close" (click)="modalRef.hide()" style="background: #2a9bd7; outline: 0px none; border: 0px none; color: #fff; padding: 5px 10px; border-radius: 3px;">
            <span>Close</span>
          </button>             
        </div>
      </ng-template>
    </div>

  </div>
</div>