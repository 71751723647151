import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs/Subject';
import { DataTableDirective } from 'angular-datatables';
import { UserService } from '../../shared/user.service';
import { LeaveMasterService} from '../../shared/HR_ADMIN/Leave/LeaveMaster.service';
import { LeaveType } from  '../../shared/HR_ADMIN/Leave/LeaveType.Model';
// Begning Confirmation
import { ConfirmationService } from '@jaspero/ng2-confirmations';
import { ResolveEmit } from '../../../app/resolve-emit';
import { ConfirmSettings } from '../../../app/confirm-settings';
import { Menu } from '../../shared/menu.model';
// end of Confirmation

@Component({
  selector: 'app-leave-type',
  templateUrl: './leave-type.component.html',
  styleUrls: ['./leave-type.component.css']
})
export class LeaveTypeComponent implements OnInit 
{
  LeaveTypeModel : LeaveType= new LeaveType();
  userClaims: any;
  LeaveTypes: LeaveType[] = [];
  LeaveType: any;
  Result : string;
  SuccessStatus:boolean=false;
  SuccessMessage: string ="";
  public tableWidget: any;
  LeaveTypeId : any;
  base64textString : string;
  tempLeaveTypeadd : LeaveType =new LeaveType();

  SubMenues : Menu[]=[];
  Mod: any;

  // Begning Permission Portion    
  SelectedMenu:Menu;
  IsDataIsInViewState : boolean=false;
  CanViewState : boolean=false;
  DisableSave : boolean=false;
  // End of the permission portion
  //check for duplicate component code
  CheckCodeExiststatus : boolean=null;  
  Checkstatusedit : boolean =false;
  Codecheck : string ="";
  checkCode : any;
  checkCodeval : string ="";
  //check for duplicate component code
  //check for duplicate component Name
  CheckNameExiststatus : boolean=null;  
  CheckNamestatusedit : boolean =false;
  Namecheck : string ="";
  checkName : any;
  checkNameval : string ="";
  //check for duplicate component Name

  constructor(private LeaveMasterService: LeaveMasterService, private rout: Router,  private _confirmation: ConfirmationService, private userService: UserService) { }
  
  @ViewChild(DataTableDirective, {static: false})
  setDtOptions: DataTables.Settings = {};
  cardDtOptions: DataTables.Settings = {};
  dtElement: DataTableDirective;
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();
  
  ngOnInit() 
  {
    // Begning Permission Portion 
    this.SubMenues=[];
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    let menuid = localStorage.getItem("menuidstor");
    this.userService.GetSubMenuList( t, menuid).then(x => 
    {
      this.Mod = x;
      Object.keys(this.Mod).forEach( key => 
      {
        // tslint:disable-next-line:no-unused-expression
        this.Mod[key];
        let menutemp = new Menu();
        menutemp = this.Mod[key];
        this.SubMenues.push(menutemp);
      });
      this.userService.SelectedMenu= this.SubMenues[0];
      this.rout.navigate([this.userService.SelectedMenu.RouteName]);
      this.SelectedMenu=this.userService.SelectedMenu;
      if(this.SelectedMenu.CanAdd)
      {
        this.IsDataIsInViewState=false;
      }
      else
      {
        this.IsDataIsInViewState=true;
      }
      if(this.SelectedMenu.CanView)
      {
        this.CanViewState=false;
      }
      else
      {
        this.CanViewState=true;
      }
    });
    // End of the permission portion
    this.DisableSave=true;
    this.dtOptions = 
    {
      pagingType: 'full_numbers',
      pageLength: 6
    };
    if(localStorage.getItem('SuccessfullMessage'))
    {
      this.SuccessStatus=true;
      this.SuccessMessage =localStorage.getItem('SuccessfullMessage')
      localStorage.removeItem('SuccessfullMessage');
    }  
  }

  ngAfterViewInit(): void 
  {
    this.GetLeaveType();
    this.dtOptions = 
    {
      pagingType: 'full_numbers',
      pageLength: 6
    };
  }

  settings: ConfirmSettings | any = 
  {
    overlay: true,
    overlayClickToClose: true,
    showCloseButton: true,
    confirmText: 'Yes',
    declineText: 'No'
  };
  
  GetLeaveType()
  {
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.LeaveMasterService.GetLeaveType( t).then(x => 
    {
      this.LeaveType = x;
      Object.keys(this.LeaveType).forEach( key => 
      {
        this.LeaveType[key];
        let LeaveTypetemp = new LeaveType();
        LeaveTypetemp = this.LeaveType[key];
        this.LeaveTypes.push(LeaveTypetemp);
      });
      this.dtTrigger.next();
    });
  }
  
  GetLeaveTypeByid(id) 
  { 
    // Begning of the permission portion
    this.IsDataIsInViewState=false;
    // End of the permision portion
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.LeaveMasterService.GetLeaveTypeById( t,id).then(x => 
    {
      this.LeaveTypeId = x;
      Object.keys(this.LeaveTypeId).forEach( key => 
      {
        // tslint:disable-next-line:no-unused-expression
        this.LeaveTypeId[key];
        let LeaveTypetemp = new LeaveType();
        LeaveTypetemp = this.LeaveTypeId[key];
        this.LeaveTypeModel.LeaveTypeId=LeaveTypetemp.LeaveTypeId;
        this.LeaveTypeModel.LeaveTypeCode=LeaveTypetemp.LeaveTypeCode;
        this.LeaveTypeModel.LeaveTypeName=LeaveTypetemp.LeaveTypeName;
      });
      // Begin for check code duplication
      this.checkCodeval= this.LeaveTypeModel.LeaveTypeCode;
      this.Checkstatusedit=true;
      this.CheckCodeExiststatus =true;
      this.Codecheck=""
      // End for check code duplication
      // Begin for check Name duplication
      this.checkNameval= this.LeaveTypeModel.LeaveTypeName;
      this.CheckNamestatusedit=true;
      this.CheckNameExiststatus =true;
      this.Namecheck=""
      // End for check Name duplication
    });
  }

  viewLeaveTypeByid(id) 
  {
    //begin permission
    this.IsDataIsInViewState=true;
    //end permission
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.LeaveMasterService.GetLeaveTypeById( t,id).then(x => 
    {
      this.LeaveTypeId = x;
      Object.keys(this.LeaveTypeId).forEach( key => 
      {
        // tslint:disable-next-line:no-unused-expression
        this.LeaveTypeId[key];
        let LeaveTypetemp = new LeaveType();
        LeaveTypetemp = this.LeaveTypeId[key];
        this.LeaveTypeModel.LeaveTypeId=LeaveTypetemp.LeaveTypeId;
        this.LeaveTypeModel.LeaveTypeCode=LeaveTypetemp.LeaveTypeCode;
        this.LeaveTypeModel.LeaveTypeName=LeaveTypetemp.LeaveTypeName;
      });
      // Begin for check code duplication
      this.checkCodeval= this.LeaveTypeModel.LeaveTypeCode;
      this.Checkstatusedit=true;
      this.CheckCodeExiststatus =true;
      this.Codecheck=""
      // End for check code duplication
      // Begin for check Name duplication
      this.checkNameval= this.LeaveTypeModel.LeaveTypeName;
      this.CheckNamestatusedit=true;
      this.CheckNameExiststatus =true;
      this.Namecheck=""
      // End for check Name duplication
    });
  }

  saveLeaveType() :void
  {
    this.DisableSave=false;
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.LeaveTypeModel.IsActive=true;
    if(this.LeaveTypeModel.LeaveTypeId > 0)
    {
      this.LeaveMasterService.UpdateLeaveType(t ,this.LeaveTypeModel).subscribe(res=>
      {
        this.LeaveTypeId=res;
        this.LeaveTypeModel.LeaveTypeId=0;  
        if(this.LeaveTypeId>0)
        {
          localStorage.setItem('SuccessfullMessage', "Update Successfully");
          this.rout.navigateByUrl('/LeaveMaster/LeaveMapping', {skipLocationChange: true}).then(()=>this.rout.navigate(["/LeaveMaster/LeaveType"]));
        }
      });                  
    }
    else
    {
      this.LeaveMasterService.AddLeaveType(t ,this.LeaveTypeModel).subscribe(res=>
      {
        this.LeaveTypeId=res;
        this.LeaveTypeModel.LeaveTypeId=0;              
        if(this.LeaveTypeId>0)
        {
          localStorage.setItem('SuccessfullMessage', "Save Successfully");
          this.rout.navigateByUrl('/LeaveMaster/LeaveMapping', {skipLocationChange: true}).then(()=>this.rout.navigate(["/LeaveMaster/LeaveType"]));
        }
      }); 
    }
  }

  DeleteLeaveTypeByid(LeaveTypeid : number) :void
  {
    if(this.SelectedMenu.CanAdd)
    {
     this.IsDataIsInViewState=false;
    }
    else
    {
      this.IsDataIsInViewState=true;
    }
    this._confirmation.create('Confirm to Deleate', 'Press Yes to Delete', this.settings)
      .subscribe((ans: ResolveEmit) => 
      {
        if (ans.resolved == true) 
        {
          let t =  localStorage.getItem('userToken');
          t = 'Bearer' + t;
          this.LeaveTypeModel.IsActive=true;
          if( LeaveTypeid > 0)
          {
            this.LeaveMasterService.DeleteLeaveType(t ,LeaveTypeid).subscribe(res=>
            {
              this.LeaveTypeId=res;
              this.LeaveTypeModel.LeaveTypeId=0;                                  
              if(this.LeaveTypeId >0)
              {
                localStorage.setItem('SuccessfullMessage', "Deleted Successfully");
                this.rout.navigateByUrl('/LeaveMaster/LeaveMapping', {skipLocationChange: true}).then(()=>this.rout.navigate(["/LeaveMaster/LeaveType"]));
              }   
            });                  
          }
        }
      })
  }
  // begning of check duplicate code
  Checkcode()
  {
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    if(!this.Checkstatusedit)
    {
      this.LeaveMasterService.CheckLeaveTypeByCode( t, this.LeaveTypeModel.LeaveTypeCode).then(x => 
      {
        this.checkCode = x;
        if( this.checkCode.length >0)
        {
          this.CheckCodeExiststatus =false;
          this.Codecheck="Exist"
        }
        else
        {
          this.CheckCodeExiststatus =true;
          this.Codecheck="";
        }
     });
    }
    else
    {
      if(this.LeaveTypeModel.LeaveTypeCode !=this.checkCodeval )
      {
        this.LeaveMasterService.CheckLeaveTypeByCode( t, this.LeaveTypeModel.LeaveTypeCode).then(x => 
        {
          this.checkCode = x;
          if( this.checkCode.length >0)
          {
            this.CheckCodeExiststatus =false;
            this.Codecheck="Exist"
          }
          else
          {
            this.CheckCodeExiststatus =true;
            this.Codecheck="";
          }
        });
      }
      else
      {
        this.CheckCodeExiststatus =true;
        this.Codecheck="";
      }
    }
  }
  // end of duplicate code

  // begning of check duplicate componentcode
  CheckName()
  {
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    if(!this.CheckNamestatusedit)
    {
      this.LeaveMasterService.CheckLeaveTypeByName( t, this.LeaveTypeModel.LeaveTypeName).then(x => 
      {
        this.checkName = x;
        if( this.checkName.length >0)
        {
          this.CheckNameExiststatus =false;
          this.Namecheck="Exist"
        }
        else
        {
          this.CheckNameExiststatus =true;
          this.Namecheck="";
        }
     });
    }
    else
    {
      if(this.LeaveTypeModel.LeaveTypeName !=this.checkNameval )
      {
        this.LeaveMasterService.CheckLeaveTypeByName( t, this.LeaveTypeModel.LeaveTypeName).then(x => 
        {
          this.checkName = x;
          if( this.checkName.length >0)
          {
            this.CheckNameExiststatus =false;
            this.Namecheck="Exist"
          }
          else
          {
            this.CheckNameExiststatus =true;
            this.Namecheck="";
          }
        });
      }
      else
      {
        this.CheckNameExiststatus =true;
        this.Namecheck="";
      }
    }
  }
  // end of duplicate Name

  RefreshPage()
  {
    this.rout.navigateByUrl('/LeaveMaster/LeaveMapping', {skipLocationChange: true}).then(()=>this.rout.navigate(["/LeaveMaster/LeaveType"]))
  }
}
