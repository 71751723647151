// import { Directive, HostListener } from '@angular/core';
// import { NG_VALIDATORS, Validator, AbstractControl, NgControl } from '@angular/forms';

import { Directive, HostListener, ElementRef } from "@angular/core";


@Directive({
  selector: '[Emailcheck]'

})

export class EmailValidatorDirective {


  // constructor(private ngControl: NgControl) {

  // }

  constructor(private el: ElementRef) { }



  // get ctrl() {
  //   return this.ngControl.control;
  // }


  @HostListener("blur")
  onBlur() {

    let emailRegEx = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i;
    let valid = emailRegEx.test(this.el.nativeElement.value);
    if (valid == false) {
      // this.ctrl.setValue('')
      this.el.nativeElement.value = '';
    }
  }




}
