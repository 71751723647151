import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs/Subject';
import { DataTableDirective } from 'angular-datatables';
import { UserService } from '../../shared/user.service';
import { AssetsMasterService } from '../../shared/HR_ADMIN/Assets/AssetsMaster.service';
import { EmployeeMasterService } from '../../shared/HR_ADMIN/Employee/EmployeeMaster.Service';
import { AssetsTypeModel } from  '../../shared/HR_ADMIN/Assets/AssetsType.model';
import { EmployeeAssetsModel } from  '../../shared/HR_ADMIN/Assets/EmployeeAssetsModel.model';
import { OrganizationService } from '../../shared/Organization/organization.service';
import { EmployeeDetail} from '../../shared/HR_ADMIN/Employee/EmployeeDetail.Model';
// Begning Confirmation
import { ConfirmationService } from '@jaspero/ng2-confirmations';
import { ResolveEmit } from '../../../app/resolve-emit';
import { ConfirmSettings } from '../../../app/confirm-settings';
import { Menu } from '../../shared/menu.model';
// end of Confirmation

@Component({
  selector: 'app-employee-asset-details',
  templateUrl: './employee-asset-details.component.html',
  styleUrls: ['./employee-asset-details.component.css']
})
export class EmployeeAssetDetailsComponent implements OnInit 
{
  EmployeeAssetsModel : EmployeeAssetsModel= new EmployeeAssetsModel();
  userClaims: any;
  EmployeeAssets:EmployeeAssetsModel[]=[];
  AssetDetails:any;
  RefId : any;
  AssetsTypes: AssetsTypeModel[] = [];
  AssetsType: any;
  EmployeeDetails: EmployeeDetail[] = [];
  EmployeeDetail: any;
  Result : string;
  SuccessStatus:boolean=false;
  SuccessMessage: string ="";
  public tableWidget: any;
  
  base64textString : string;
  tempEmployeeAssetsadd : EmployeeAssetsModel =new EmployeeAssetsModel();

  // Begning Permission Portion    
  SelectedMenu:Menu;
  IsDataIsInViewState : boolean=false;
  CanViewState : boolean=false;
  DisableSave : boolean=false;
  // End of the permission portion
  EmployeeSettings = {};
  selectedItems = [];
  emp : any [] =[];

  constructor(private AssetsMasterService: AssetsMasterService, private EmployeeMasterService:EmployeeMasterService,private OrganizationService: OrganizationService, private rout: Router,  private _confirmation: ConfirmationService, private userService: UserService) { }
  
  @ViewChild(DataTableDirective, {static: false})
  setDtOptions: DataTables.Settings = {};
  cardDtOptions: DataTables.Settings = {};
  dtElement: DataTableDirective;
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();
  
  ngOnInit() 
  {
    //Begin Permission Portion
    this.SelectedMenu=this.userService.SelectedMenu;
    if(this.SelectedMenu !=undefined)
    {
      localStorage.setItem('selectedmenu',JSON.stringify(this.SelectedMenu));
      this.rout.navigate([this.SelectedMenu.RouteName]);
    }
    else
    {
      if(JSON.parse(localStorage.getItem('selectedmenu'))!=undefined)
      {
        this.SelectedMenu = JSON.parse(localStorage.getItem('selectedmenu'));
        this.rout.navigate([this.SelectedMenu.RouteName]);
      }
    }
    if(this.SelectedMenu.CanAdd)
    {
      this.IsDataIsInViewState=false;
    }
    else
    {
      this.IsDataIsInViewState=true;
    }
    if(this.SelectedMenu.CanView)
    {
      this.CanViewState=false;
    }
    else
    {
      this.CanViewState=true;
    }
    //End of the peremission portion
    this.DisableSave=true;
    this.dtOptions = 
    {
      pagingType: 'full_numbers',
      pageLength: 6
    };
    if(localStorage.getItem('SuccessfullMessage'))
    {
      this.SuccessStatus=true;
      this.SuccessMessage =localStorage.getItem('SuccessfullMessage')
      localStorage.removeItem('SuccessfullMessage');
    }
    this.GetEmployeeDetails();
    this.EmployeeAssetsModel.EmployeeId=0;
    this.EmployeeSettings =
    {
      singleSelection:true,
      text:'Select Employee',
      enableSearchFilter:true,
      showCheckbox:false
    }
  }

  ngAfterViewInit(): void 
  {
    this.GetEmployeeAssetsDetails();
    this.GetAssetsType();
    this.EmployeeAssetsModel.AssetsId=0;
    this.dtOptions = 
    {
      pagingType: 'full_numbers',
      pageLength: 6
    };
  }

  settings: ConfirmSettings | any = 
  {
    overlay: true,
    overlayClickToClose: true,
    showCloseButton: true,
    confirmText: 'Yes',
    declineText: 'No'
  };
  
  GetAssetsType()
  {
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.AssetsMasterService.GetAssetsType( t).then(x => 
    {
      this.AssetsType = x;
      Object.keys(this.AssetsType).forEach( key => 
      {
        this.AssetsType[key];
        let AssetsTypetemp = new AssetsTypeModel();
        AssetsTypetemp = this.AssetsType[key];
        this.AssetsTypes.push(AssetsTypetemp);
      });
    });
  }

  GetEmployeeDetails()
  {
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.EmployeeMasterService.GetEmployeeDetailsForDDL( t).then(x => 
    {
      this.EmployeeDetail = x;
      Object.keys(this.EmployeeDetail).forEach( key => 
      {
        this.EmployeeDetail[key];
        let Emptemp = new EmployeeDetail();
        Emptemp = this.EmployeeDetail[key];
        this.EmployeeDetails.push(Emptemp);
        this.emp.push({"id":Emptemp.EmployeeId,"itemName":Emptemp.EmployeeCode + '-' +Emptemp.EmployeeName},);
      });
    });
  }

  GetEmployeeAssetsDetails()
  {
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.AssetsMasterService.GetEmployeeAssetDetails( t).then(x => 
    {
      this.AssetDetails = x;
      Object.keys(this.AssetDetails).forEach( key => 
      {
        this.AssetDetails[key];
        let AssetDetailstemp = new EmployeeAssetsModel();
        AssetDetailstemp = this.AssetDetails[key];
        this.EmployeeAssets.push(AssetDetailstemp);
      });
      this.dtTrigger.next();
    });
  }

  GetEmployeeAssetsDetailsById(id) 
  { 
    // Begning of the permission portion
    this.IsDataIsInViewState=false;
    // End of the permision portion
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.AssetsMasterService.GetEmployeeAssetDetailsById( t,id).then(x => 
    {
      this.RefId = x;
      Object.keys(this.RefId).forEach( key => 
      {
        // tslint:disable-next-line:no-unused-expression
        this.RefId[key];
        let AssetsDetailtemp = new EmployeeAssetsModel();
        AssetsDetailtemp = this.RefId[key];
        this.EmployeeAssetsModel.RefId=AssetsDetailtemp.RefId;
        this.EmployeeAssetsModel.EmployeeId=AssetsDetailtemp.EmployeeId;
        this.EmployeeAssetsModel.AssetsId=AssetsDetailtemp.AssetsId;
        this.EmployeeAssetsModel.Particulars=AssetsDetailtemp.Particulars;
        this.EmployeeAssetsModel.SerialNumber=AssetsDetailtemp.SerialNumber;
        this.EmployeeAssetsModel.Amount=AssetsDetailtemp.Amount;
      });
    });
  }

  viewEmployeeAssetsDetailsById(id) 
  {
    //begin permission
    this.IsDataIsInViewState=true;
    //end permission
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.AssetsMasterService.GetEmployeeAssetDetailsById( t,id).then(x => 
    {
      this.RefId = x;
      Object.keys(this.RefId).forEach( key => 
      {
        // tslint:disable-next-line:no-unused-expression
        this.RefId[key];
        let AssetsDetailtemp = new EmployeeAssetsModel();
        AssetsDetailtemp = this.RefId[key];
        this.EmployeeAssetsModel.RefId=AssetsDetailtemp.RefId;
        this.EmployeeAssetsModel.EmployeeId=AssetsDetailtemp.EmployeeId;
        this.EmployeeAssetsModel.AssetsId=AssetsDetailtemp.AssetsId;
        this.EmployeeAssetsModel.Particulars=AssetsDetailtemp.Particulars;
        this.EmployeeAssetsModel.SerialNumber=AssetsDetailtemp.SerialNumber;
        this.EmployeeAssetsModel.Amount=AssetsDetailtemp.Amount;
      });
    });
  }

  saveEmployeeAssetsDetails() :void
  {
    this.DisableSave=false;
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.EmployeeAssetsModel.IsActive=true;
    if(this.EmployeeAssetsModel.RefId > 0)
    {
      this.AssetsMasterService.UpdateEmployeeAssetDetails(t ,this.EmployeeAssetsModel).subscribe(res=>
      {
        this.RefId=res;
        this.EmployeeAssetsModel.RefId=0;  
        if(this.RefId>0)
        {
          localStorage.setItem('SuccessfullMessage', "Update Successfully");
          this.rout.navigateByUrl('/AssetsMaster/AssetsType', {skipLocationChange: true}).then(()=>this.rout.navigate(["/AssetsMaster/EmployeeAssets"]))
        }
      });                  
    }
    else
    {
      this.AssetsMasterService.AddEmployeeAssetDetails(t ,this.EmployeeAssetsModel).subscribe(res=>
      {
        this.RefId=res;
        this.EmployeeAssetsModel.RefId=0;              
        if(this.RefId>0)
        {
          localStorage.setItem('SuccessfullMessage', "Save Successfully");
          this.rout.navigateByUrl('/AssetsMaster/AssetsType', {skipLocationChange: true}).then(()=>this.rout.navigate(["/AssetsMaster/EmployeeAssets"]))
        }
      }); 
    }
  }

  DeleteEmployeeAssetDetailsByid(AssetsDetailid : number) :void
  {
    if(this.SelectedMenu.CanAdd)
    {
     this.IsDataIsInViewState=false;
    }
    else
    {
      this.IsDataIsInViewState=true;
    }
    this._confirmation.create('Confirm to Deleate', 'Press Yes to Delete', this.settings)
      .subscribe((ans: ResolveEmit) => 
      {
        if (ans.resolved == true) 
        {
          let t =  localStorage.getItem('userToken');
          t = 'Bearer' + t;
          this.EmployeeAssetsModel.IsActive=true;
          if( AssetsDetailid > 0)
          {
            this.AssetsMasterService.DeleteEmployeeAssetDetails(t ,AssetsDetailid).subscribe(res=>
            {
              this.RefId=res;
              this.EmployeeAssetsModel.RefId=0;                                  
              if(this.RefId >0)
              {
                localStorage.setItem('SuccessfullMessage', "Deleted Successfully");
                this.rout.navigateByUrl('/AssetsMaster/AssetsType', {skipLocationChange: true}).then(()=>this.rout.navigate(["/AssetsMaster/EmployeeAssets"]))
              }   
            });                  
          }
        }
      })
  }
 
  RefreshPage()
  {
    this.rout.navigateByUrl('/AssetsMaster/AssetsType', {skipLocationChange: true}).then(()=>this.rout.navigate(["/AssetsMaster/EmployeeAssets"]))
  }

  onItemSelect(item:any)
  {
    let empid= item['id'];
    this.EmployeeAssetsModel.EmployeeId=empid ;      
  }
  OnItemDeSelect(item:any){ }
  onSelectAll(items: any){ }
  onDeSelectAll(items: any){ }
}
