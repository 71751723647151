<div class="body-content-area tabcontent" id="link1">
  <div class="row">
    <div class="col-lg-9">
      <div class="row">
        <div class="col-lg-1 low-padding text-center">
          <!-- <img *ngIf="this.SelectedMenu.Image!=null" src={{this.SelectedMenu.Image}} class="body-icon" alt="Icon"/> -->
        </div>
        <div class="col-lg-10 clear-padding">
          <span class="page-title">Manage Monthly Salary</span>
          <small class="page-description"></small>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="!(this.CanViewState)">
    <fieldset class="body-form-control">
      <legend class="legend--"></legend>
      <form #EmployeeAttendanceForm="ngForm">
        <div class="row">
          <div class="col-lg-4">
            <div class="col-body-border-right">
              <div class="form-group row">
                <label class="col-sm-4 col-form-label clear-padding-right">Company</label>
                <div class="col-sm-7 clear-left-padding">
                  <select [(ngModel)]="EmployeeMonthelyAttendenceModel.CompanyId"
                    (ngModelChange)="onChangeCompany($event)" id="CompanyId" class="form-control" name="CompanyId"
                    #CompanyId="ngModel">
                    <option [value]="0" [disabled]=true>Select Company</option>
                    <option *ngFor="let c of Companies" [value]="c.CompId">{{c.CompName}}</option>
                  </select>
                </div>
                <div class="required-validation" *ngIf="EmployeeMonthelyAttendenceModel.CompanyId==0">Company is
                  required</div>
              </div>
              <div class="form-group row">
                <label class="col-sm-4 col-form-label clear-padding-right">Financial Year</label>
                <div class="col-sm-7 clear-left-padding">
                  <select [(ngModel)]="EmployeeMonthelyAttendenceModel.FinYear" (ngModelChange)="onchangeYear()"
                    id="FinancialYear" class="form-control" name="FinancialYear" #FinYear="ngModel">
                    <option [value]="0" [disabled]=true>Select Financial Year</option>
                    <option *ngFor="let FY of FinancialYears" [value]="FY.FinancialyearId">{{FY.FinancialYear}}</option>
                  </select>
                </div>
                <div class="required-validation" *ngIf="EmployeeMonthelyAttendenceModel.FinYear==0">Financial Year is
                  required</div>
              </div>
            </div>
          </div>
          <div class="col-lg-4">
            <div class="col-body-border-right">
              <div class="form-group row">
                <label class="col-sm-4 col-form-label clear-padding-right">Branch</label>
                <div class="col-sm-7 clear-left-padding">
                  <select [(ngModel)]="EmployeeMonthelyAttendenceModel.BranchId"
                    (ngModelChange)="onchangeBranch($event)" id="BranchId" class="form-control" name="BranchId"
                    #BranchId="ngModel">
                    <option [value]="0" [disabled]=true>Select Branch</option>
                    <option *ngFor="let b of Branches" [value]="b.BranchId">{{b.BranchName}}</option>
                  </select>
                </div>
                <div class="required-validation" *ngIf="EmployeeMonthelyAttendenceModel.BranchId==0">Branch is required
                </div>
              </div>

              <div class="form-group row">
                <label class="col-sm-4 col-form-label clear-padding-right">Month</label>
                <div class="col-sm-7 clear-left-padding">
                  <select [(ngModel)]="EmployeeMonthelyAttendenceModel.MonthId" (ngModelChange)="onchangeMonth()"
                    id="MonthId" class="form-control" name="MonthId" #MonthId="ngModel">
                    <option [value]="0" [disabled]=true>Select Month</option>
                    <option *ngFor="let Mo of Months" [value]="Mo.MonthId">{{Mo.MonthName}}</option>
                  </select>
                </div>
                <div class="required-validation" *ngIf="EmployeeMonthelyAttendenceModel.MonthId==0">Month is required
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-4">
            <div class="form-group row">
              <label class="col-sm-4 col-form-label clear-padding-right">Department</label>
              <div class="col-sm-7 clear-left-padding">
                <select [(ngModel)]="EmployeeMonthelyAttendenceModel.DepartmentId"
                  (ngModelChange)="onchangeDepartments()" id="DepartmentId" class="form-control" name="DepartmentId"
                  #DepartmentId="ngModel">
                  <option [value]="0">Select Department</option>
                  <option *ngFor="let d of Departments" [value]="d.DepartmentId">{{d.DepartmentName}}</option>
                </select>
              </div>
            </div>
            <div class="form-group row">
              <div class="col-sm-7 clear-left-padding">
                <button type="submit" (click)="submitClick()" class="btn btn-primary save-button"
                  [disabled]="!( EmployeeMonthelyAttendenceModel.MonthId > 0 && EmployeeMonthelyAttendenceModel.CompanyId > 0 &&  EmployeeMonthelyAttendenceModel.FinYear > 0 && EmployeeMonthelyAttendenceModel.BranchId > 0 && this.DisableSave==true)">Get
                  Attendence</button>
              </div>
            </div>
          </div>
        </div>
      </form>
    </fieldset>

    <div *ngIf="NoRecordMsg" style="color: #dc3545; text-align: center;font-size: 15px; font-weight: bold;">No Record
      Found.</div>

    <fieldset class="body-form-control" *ngIf="EmployeeMonthelyAttendenceModelList.length > 0">
      <legend class="legend--">Employee Details</legend>
      <form #MonthlyAttendance="ngForm">
        <div class="tablediv">
          <table id="tblMonthlyAttendance"
            class="table table-striped table-bordered text-center table-new row-border hover" fixed-header>
            <thead class="thead-blue text-center">
              <tr class="table-head" fixed-header>
                <th>Sl No.</th>
                <th>Code</th>
                <th>Name</th>
                <!-- <th style="width: 18%;">Company</th> -->
                <th>Branch</th>
                <th>Department</th>
                <th>No. of Days</th>
                <th>Attendance</th>
                <th>LOP</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let att of EmployeeMonthelyAttendenceModelList, let i = index">
                <td scope="row">{{i+1}}</td>
                <td style=" text-align: center;"><span>{{att.EmployeeCode}}</span></td>
                <td><span>{{att.EmployeeName}}</span></td>
                <!-- <td><span>{{att.CompanyName}}</span></td> -->
                <td><span>{{att.BranchName}}</span></td>
                <td><span>{{att.DepartmentName}}</span></td>
                <td style=" text-align: center;"><span>{{att.NoDaysinMonth}}</span></td>
                <td style=" text-align: center;"><span>{{att.FinalAttendance}}</span></td>
                <td style=" text-align: center;"><span>{{att.FinalLOP}}</span></td>
                <td>
                  <button *ngIf="this.att.SalaryId==0 " type="button" (click)="Getsalary(this.att.EmployeeId,template)"
                    class="table-edit-btn"><i class="fas fa-edit"></i> </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </form>
    </fieldset>

    <div style=" box-align: center;">
      <ng-template #template>
        <div class="modal-header" style="background: #2a9bd7; max-width: 1500px;">
          <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()"
            style="color:#fff;">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div style="text-align: center;">
          <div *ngIf="SaveMsg" style="color: #dc3545; text-align: center;font-size: 15px; font-weight: bold;">Salary
            Generated Successfully</div>
          <div style=" width: 100%;" *ngIf="ShowPopup">
            <fieldset class="body-form-control">
              <legend class="legend--">Salary BreakUp</legend>
              <form #SalaryBreakUpForm="ngForm">
                <div>
                  <div class="modal-title pull-left" style="font-weight: bold;"> Name:
                    {{this.EmployeeSalaryComponentAttendenceModel.EmployeeName}}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Employee
                    Code: {{this.EmployeeSalaryComponentAttendenceModel.EmployeeCode}}</div>
                </div>
                <div class="modal-body">
                  <div>
                    <table id="tblComponent"
                      class="table table-striped table-bordered text-left table-new row-border hover">
                      <thead class="thead-blue text-center">
                        <tr>
                          <th style="width: 70%;">Component</th>
                          <th style="width: 30%; text-align: right;">Amount (Rs)</th>
                        </tr>
                      </thead>
                      <tbody *ngIf="this.EmployeeSalaryComponentAttendenceModel.SalaryCompList.length > 0">
                        <tr
                          *ngFor="let sc of this.EmployeeSalaryComponentAttendenceModel.SalaryCompList, let i = index">
                          <td><span>{{sc.SalaryComponentName }}</span></td>
                          <td class="text-right">{{sc.Amount}}</td>
                        </tr>
                        <tr style="background-color:rgb(207, 250, 250)">
                          <td>Total Gross Salary : (A)</td>
                          <td class="text-right">
                            {{this.EmployeeSalaryComponentAttendenceModel.GrossSalary | number:'3.0-0'}}</td>
                        </tr>
                        <tr *ngIf="this.EmployeeSalaryComponentAttendenceModel.PF > 0">
                          <td>PF Employee's Contribution</td>
                          <td class="text-right">{{this.EmployeeSalaryComponentAttendenceModel.PF | number:'3.0-0'}}
                          </td>
                        </tr>
                        <tr *ngIf="this.EmployeeSalaryComponentAttendenceModel.Ptax> 0">
                          <td>Professional Tax Amount </td>
                          <td class="text-right">{{this.EmployeeSalaryComponentAttendenceModel.Ptax | number:'3.0-0'}}
                          </td>
                        </tr>
                        <tr *ngIf="this.EmployeeSalaryComponentAttendenceModel.LOPAmount> 0">
                          <td>Lop Amount</td>
                          <td class="text-right">
                            {{this.EmployeeSalaryComponentAttendenceModel.LOPAmount | number:'3.0-0'}}</td>
                        </tr>
                        <tr>
                          <td><span>Advance Amount</span></td>
                          <td class="text-right">
                            <input type="text" (blur)="onSalarychangehange()"
                              [(ngModel)]=" this.EmployeeSalaryComponentAttendenceModel.Advance" name="Advance"
                              #Advance="ngModel" class="form-control form-control-custom form-control-custom-new">
                          </td>
                        </tr>
                        <tr>
                          <td><span>Loan Amount</span></td>
                          <td class="text-right">
                            <input type="text" (blur)="onSalarychangehange()"
                              [(ngModel)]="this.EmployeeSalaryComponentAttendenceModel.Loan" name="Loan" #Loan="ngModel"
                              class="form-control form-control-custom form-control-custom-new">
                          </td>
                        </tr>
                        <tr>
                          <td><span>TDS</span></td>
                          <td class="text-right">
                            <input type="text" (blur)="onSalarychangehange()"
                              [(ngModel)]="this.EmployeeSalaryComponentAttendenceModel.TDS" name="TDS" #TDS="ngModel"
                              class="form-control form-control-custom form-control-custom-new">
                          </td>
                        </tr>
                        <tr>
                          <td><span>Mediclaim</span></td>
                          <td class="text-right">
                            <input type="text" (blur)="onSalarychangehange()"
                              [(ngModel)]="this.EmployeeSalaryComponentAttendenceModel.MediClame" name="MediClame"
                              #MediClame="ngModel" class="form-control form-control-custom form-control-custom-new">
                          </td>
                        </tr>
                        <tr>
                          <td><span>Donation</span></td>
                          <td class="text-right">
                            <input type="text" (blur)="onSalarychangehange()"
                              [(ngModel)]="this.EmployeeSalaryComponentAttendenceModel.Donation" name="Donation"
                              #Donation="ngModel" class="form-control form-control-custom form-control-custom-new">
                          </td>
                        </tr>
                        <tr>
                          <td><span>Other Deduction</span></td>
                          <td class="text-right">
                            <input type="text" (blur)="onSalarychangehange()"
                              [(ngModel)]="this.EmployeeSalaryComponentAttendenceModel.OtherDeduction"
                              name="OtherDeduction" #OtherDeduction="ngModel"
                              class="form-control form-control-custom form-control-custom-new">
                          </td>
                        </tr>

                        <!-- //10042020 Begin Change by Mukesh -->
                        <tr>
                          <td><span>Hold Amount</span></td>
                          <td class="text-right">
                            <input type="text" (blur)="onSalarychangehange()"
                              [(ngModel)]="this.EmployeeSalaryComponentAttendenceModel.HoldAmount"
                              name="HoldAmount" #HoldAmount="ngModel"
                              class="form-control form-control-custom form-control-custom-new">
                          </td>
                        </tr>

                        <tr>
                          <td><span>LWP Amount</span></td>
                          <td class="text-right">
                            <input type="text" (blur)="onSalarychangehange()"
                              [(ngModel)]="this.EmployeeSalaryComponentAttendenceModel.LWPAmount"
                              name="LWPAmount" #LWPAmount="ngModel"
                              class="form-control form-control-custom form-control-custom-new">
                          </td>
                        </tr>
                        <!-- //10042020 End Change by Mukesh -->
                        <tr>
                          <td><span>Arrears</span></td>
                          <td class="text-right">
                            <input type="text" (blur)="onSalarychangehange()"
                              [(ngModel)]="this.EmployeeSalaryComponentAttendenceModel.Arears" name="Arears"
                              #Arears="ngModel" class="form-control form-control-custom form-control-custom-new">
                          </td>
                        </tr>
                        <tr>
                          <td><span>Other Income</span></td>
                          <td class="text-right">
                            <input type="text" (blur)="onSalarychangehange()"
                              [(ngModel)]="this.EmployeeSalaryComponentAttendenceModel.OtherIncome" name="OtherIncome"
                              #OtherIncome="ngModel" class="form-control form-control-custom form-control-custom-new">
                          </td>
                        </tr>
                        <tr *ngIf="ShowAdjustment == true" hidden="true">
                          <td><span>Adjustment Addition</span></td>
                          <td class="text-right">
                            <input type="text" (blur)="onSalarychangehange()"
                              [(ngModel)]="this.EmployeeSalaryComponentAttendenceModel.AdjustmentAddition"
                              name="AdjustmentAddition" #AdjustmentAddition="ngModel"
                              class="form-control form-control-custom form-control-custom-new">
                          </td>
                        </tr>
                        <tr *ngIf="ShowAdjustment == true" hidden="true">
                          <td><span>Adjustment Deduction</span></td>
                          <td class="text-right">
                            <input type="text" (blur)="onSalarychangehange()"
                              [(ngModel)]="this.EmployeeSalaryComponentAttendenceModel.AdjustmentSubtraction"
                              name="AdjustmentSubtraction" #AdjustmentSubtraction="ngModel"
                              class="form-control form-control-custom form-control-custom-new">
                          </td>
                        </tr>
                        <tr>
                          <td>Net Takehome</td>
                          <td class="text-right">
                            {{this.EmployeeSalaryComponentAttendenceModel.NetTakeHome | number:'3.0-0'}}</td>
                        </tr>
                        <tr>
                          <td></td>
                          <td *ngIf="this.EmployeeSalaryComponentAttendenceModel.salaryId==0">
                            <button type="submit" class="btn btn-primary save-button"
                              (click)="saveSalaryDeatils(template)">Save</button>
                          </td>
                        </tr>
                        <!-- <tr *ngIf="this.ESICont > 0">
                              <td>ESI Employer's Contribution</td>
                              <td class="text-right">{{this.ESICont | number:'3.0-0'}} </td>
                          </tr> -->
                        <!-- <tr style="background-color:rgb(207, 250, 250)">
                              <td>Long Term benefits : (B)</td>
                              <td class="text-right">{{this.Longtermbenefits + this.PFCont + this.ESICont | number:'3.0-0'}} </td>
                          </tr> -->
                        <!-- <tr style="background-color:rgb(162, 162, 235)">
                              <td>Computed Compensation CTC : (A+B)</td>
                              <td class="text-right">{{(this.Longtermbenefits + this.ComponentSubTotal + this.PFCont + this.ESICont) | number:'3.0-0'}} </td>
                          </tr> -->
                        <!-- <tr *ngIf="this.PFDed > 0">
                              <td>Less PF Employee's Contribution</td>
                              <td class="text-right">{{this.PFDed | number:'3.0-0'}} </td>
                          </tr> -->
                        <!-- <tr *ngIf="this.ESIDed > 0">
                              <td>Less ESI Employee's Contribution</td>
                              <td class="text-right">{{this.ESIDed | number:'3.0-0'}} </td>
                          </tr> -->
                        <!-- <tr *ngIf="this.P_Tax > 0">
                              <td>Professional Tax Amount </td>
                              <td class="text-right">{{this.P_Tax | number:'3.0-0'}} </td>
                          </tr> -->
                        <!-- <tr style="background-color:rgb(207, 250, 250)">
                              <td>Total Deduction : (C)</td>
                              <td class="text-right">{{(this.PFDed + this.ESIDed + this.P_Tax) | number:'3.0-0'}} </td>
                          </tr> -->
                        <!-- <tr style="background-color:rgb(162, 162, 235)">
                              <td>Take Home Before Income tax Deduction : (A-C)</td>
                              <td class="text-right">
                                  {{(this.ComponentSubTotal)-(this.PFDed + this.ESIDed + this.P_Tax) | number:'3.0-0'}} 
                              </td>
                          </tr> -->
                      </tbody>
                    </table>
                  </div>
                </div>
              </form>
            </fieldset>
          </div>
          <button type="button" aria-label="Close" (click)="modalRef.hide()"
            style="background: #2a9bd7; outline: 0px none; border: 0px none; color: #fff; padding: 5px 10px; border-radius: 3px;">
            <span>Close</span>
          </button>
        </div>
      </ng-template>
    </div>
  </div>
</div>