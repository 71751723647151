<div class="body-content-area tabcontent" id="link1">
  <div class="row">
      <div class="col-lg-9">
          <div class="row">
              <div class="col-lg-1 low-padding text-center">
                  <!-- <img *ngIf="this.SelectedMenu.Image!=null" src={{this.SelectedMenu.Image}} class="body-icon" alt="Icon"/> -->
              </div>
              <div class="col-lg-10 clear-padding">
                  <span class="page-title">Manage KRA Mapping</span>
                  <small class="page-description"></small>
              </div>
          </div>
      </div>
  </div>

  <div *ngIf="!(this.CanViewState)">
    <table id="tblKRAMapping" datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" class="table table-striped table-bordered text-center table-new row-border hover" style="width: 100%;">
      <thead class="thead-blue text-center" style="width: 100%;">
        <tr style="width: 100%;">
          <th style="width: 10%;">Sl No.</th>
          <th style="width: 20%;" class="text-left">Company</th>
          <th style="width: 20%;" class="text-left">Designation</th>
          <th style="width: 40%;" class="text-left">KRA Names</th>
          <th style="width: 10%;">Action</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let kra of KRAMapping, let i = index">
          <td scope="row">{{i+1}}</td>
          <td class="text-left">{{kra.CompanyName}}</td>
          <td class="text-left">{{kra.DesignationName}}</td>
          <td class="text-left">{{kra.KRAMasterName}}</td>
          <td>
              <button *ngIf="this.SelectedMenu.CanEdit" type="button" (click)="GetKRAMasterMappingByid(kra.RefId)" class="table-edit-btn"><i class="fas fa-edit"></i></button> 
              <button *ngIf="this.SelectedMenu.CanDelete" type="button" (click)="DeleteKRAMappingByid(kra.RefId)" class="table-remove-btn"><i class="fas fa-trash-alt"></i> </button>
              <button *ngIf="this.SelectedMenu.CanView" type="button" (click)="viewKRAMasterMappingByid(kra.RefId)" class="table-view-button" type="button"><i class="fas fa-eye"></i></button>
          </td>
        </tr>
      </tbody>
    </table>

    <fieldset class="body-form-control">
      <app-dialog [(visible)]="this.SuccessStatus">
        <span class="successmsg">{{this.SuccessMessage}}</span>
      </app-dialog>
      <legend class="legend--">Mapping KRA Master Creation</legend>
      <form #KRAMasterMapping="ngForm" (ngSubmit)="saveKRAMapping()">
        <div class="row">
          <div class="col-lg-4">
            <div class="col-body-border-right">
              <div class="form-group row">
                <label  class="col-sm-4 col-form-label clear-padding-right"> Company</label>
                <div class="col-sm-7 clear-left-padding">
                  <select [(ngModel)]="KRAMappingModel.CompanyId" tabindex="1" id ="CompanyId" [disabled]="IsCompanyEnable" class="form-control"  name="CompanyId" #CompanyId="ngModel" (blur)="CheckKRAMAPPING()">
                    <option [value]="0" [disabled]=true >Select Company</option>
                    <option *ngFor="let comp of Companies" [value]="comp.CompId">{{comp.CompName}}</option>
                  </select>
                </div>
                <div *ngIf="this.check.length > 0" class="alert alert-danger">Component is already exist for selected Company</div>
                <div class="required-validation" *ngIf="KRAMappingModel.CompanyId==0">Company is required</div>
              </div>
              <div class="form-group row">
                <label  class="col-sm-4 col-form-label clear-padding-right"> Grade</label>
                <div class="col-sm-7 clear-left-padding">
                  <select [(ngModel)]="KRAMappingModel.GradeId" tabindex="4" id ="GradeId" class="form-control" [disabled]="IsDesignationEnable" name="GradeId" #GradeId="ngModel" (ngModelChange)="onGradechange($event)">
                    <option [value]="0" [disabled]=true >Select Grade</option>
                    <option *ngFor="let g of Grades" [value]="g.GradeId">{{g.GradeName}}</option>
                  </select>
                </div>
                <div class="required-validation" *ngIf="KRAMappingModel.GradeId==0">Grade is required</div>
              </div>
              <div class="form-group row">
                <label class="col-sm-6 col-form-label text-center clear-padding-right" *ngIf="!(this.IsDataIsInViewState)">
                  <button type="submit" tabindex="7" class="btn btn-primary save-button" [disabled]="!(KRAMasterMapping.valid && this.CheckExiststatus==true && this.ComponentselectedItems.length > 0 && this.KRAMappingModel.CompanyId!=0 && this.KRAMappingModel.DesignationId!=0 && this.DisableSave==true)">Save</button>  
                </label>
                <div class="col-sm-6 col-form-label text-center">       
                <button  type="button" tabindex="8" class="btn btn-danger cancle-button" (click)="RefreshPage()" >Cancel</button></div>
              </div>
            </div>
          </div>
            
          <div class="col-lg-4">
            <div class="col-body-border-right">
              <div class="form-group row">
                <label  class="col-sm-4 col-form-label clear-padding-right"> Decision Making</label>
                <div class="col-sm-7 clear-left-padding">
                  <select [(ngModel)]="KRAMappingModel.DecisionId" tabindex="2" id ="DecisionId" class="form-control" [disabled]="IsDesignationEnable" name="DecisionId" #DecisionId="ngModel" (ngModelChange)="onDecisionChange($event)">
                    <option [value]="0" [disabled]=true >Select Decision</option>
                    <option *ngFor="let d of DecisionMakings" [value]="d.DecisionId">{{d.DecisionName}}</option>
                  </select>
                </div>
                <div class="required-validation" *ngIf="KRAMappingModel.DecisionId==0">Decision Making is required</div>
              </div>
              <div class="form-group row">
                <label  class="col-sm-4 col-form-label clear-padding-right">Designation</label>
                <div class="col-sm-7 clear-left-padding">
                  <select [(ngModel)]="KRAMappingModel.DesignationId" tabindex="5" id ="DesignationId" class="form-control" [disabled]="IsDesignationEnable"  name="DesignationId" #DesignationId="ngModel"  (blur)="CheckKRAMAPPING()">
                    <option [value]="0" [disabled]=true >Select Designation</option>
                    <option *ngFor="let des of Designations" [value]="des.DesignationId">{{des.DesignationName}}</option>
                  </select>
                </div>
                <div *ngIf="this.check.length > 0" class="alert alert-danger">Component is already exist for selected Designation</div>
                <div class="required-validation"  *ngIf="KRAMappingModel.DesignationId==0">Designation is required</div>
              </div>
            </div>
          </div>

          <div class="col-lg-4">
            <div class="form-group row">
              <label  class="col-sm-4 col-form-label clear-padding-right"> Level</label>
              <div class="col-sm-7 clear-left-padding">
                <select [(ngModel)]="KRAMappingModel.LevelId" tabindex="3" id ="LevelId" class="form-control" [disabled]="IsDesignationEnable" name="LevelId" #LevelId="ngModel" (ngModelChange)="onLevelChange($event)">
                  <option [value]="0" [disabled]=true >Select Level</option>
                  <option *ngFor="let l of Levels" [value]="l.LevelId">{{l.LevelName}}</option>
                </select>
              </div>
              <div class="required-validation" *ngIf="KRAMappingModel.LevelId==0">Level is required</div>
            </div>
            <div class="form-group row">
              <label  class="col-sm-4 col-form-label clear-padding-right"> KRA Component</label>
              <div class="col-lg-11">
                <angular2-multiselect class="form-control" [data]="ComponentList" [(ngModel)]="ComponentselectedItems" name="ComponentselectedItems" 
                    (onSelect)="onItemSelect($event)" (onDeSelect)="OnItemDeSelect($event)" (onSelectAll)="onSelectAll($event)"
                  (onDeSelectAll)="onDeSelectAll($event)">
                  <!-- [settings]="SalaryComponentSettings" -->
                </angular2-multiselect>
              </div>
            </div>
          </div>
        </div>
      </form>
    </fieldset>
  </div>
</div>