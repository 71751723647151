import { Component, OnInit} from '@angular/core';
import { CrmLeadService } from '../../../shared/CRM/MICECRM/LEADCRM/CrmLeadService';
import { Model_Crm_List } from '../../../shared/CRM/MICECRM/LEADCRM/Model_Crm_List';
import { Model_CRM_Assigne } from '../../../shared/CRM/MICECRM/Model_CRM_Assigne';
import { CrmOppertunityService } from '../../../shared/CRM/MICECRM/Oppertunity/CrmOppertunityService';
declare var $: any;

@Component({
  selector: 'app-opportunity-report',
  templateUrl: './opportunity-report.component.html',
  styleUrls: ['./opportunity-report.component.css']
})
export class OpportunityReportComponent implements OnInit {


  ListList: Model_Crm_List[] = [];
  Listobj: any;
  ListArray: any[] = [];
  ListSettings = {};
  SelectedListForList: any[] = [];
  AssigneList: Model_CRM_Assigne[] = [];
  Assigneobj: any;
  AssigneArray: any[] = [];
  AssigneSettings = {};
  SelectAssigneList: any[] = [];
  EmployeeIds: number[] = [];
  FromDate: Date
  Todate: Date
  validdate: boolean
  constructor(private CRM_LeadService: CrmLeadService, private CRM_OppertunityService: CrmOppertunityService) {
  }

  ngOnInit() {

    this.AssigneSettings = {
      singleSelection: false,
      text: 'Select List',
      enableSearchFilter: true,
      showCheckbox: true
    }
    this.GetCrmAssignetoList();

    this.EmployeeIds = [];
  }


  onItemSelectAssigneTo(item: any) {

    if (this.EmployeeIds == undefined) {
      this.EmployeeIds = [];
    }
    let Employeeid = item['id'];

    this.EmployeeIds.push(Employeeid);

  }
  OnItemDeSelectAssigneTo(itemv: any) {

    this.EmployeeIds.forEach((item, index) => {
      if (item === itemv['id']) this.EmployeeIds.splice(index, 1);
    });
  }
  onSelectAllAssigneTo(items: any) {

    this.EmployeeIds = [];
    items.forEach(x => {
      this.EmployeeIds.push(x['id']);
    })
  }
  onDeSelectAllAssigneTo(items: any) {

    this.EmployeeIds = [];


  }



  GetCrmAssignetoList() {


    let t = localStorage.getItem('userToken');
    t = 'Bearer' + t
    this.ListList = [];
    this.CRM_LeadService.GetParentChildList(t).then(x => {
      this.Listobj = x;
      Object.keys(this.Listobj).forEach(key => {
        let AssigneTotemp = new Model_CRM_Assigne();
        AssigneTotemp = this.Listobj[key];

        this.AssigneList.push(AssigneTotemp);
        this.AssigneArray.push({ "id": AssigneTotemp.EmployeeId, "itemName": AssigneTotemp.EmployeeName });
      });

    })
  }


  FromDateChanged(date) {
    this.FromDate = date;
    var CurrentDate = new Date();
    var CurrentYear = CurrentDate.getFullYear();
    var currentMonth = CurrentDate.getMonth();
    var CurrentDay = CurrentDate.getDate();

    var selecteedDate = new Date(date);
    var selectedYear = selecteedDate.getFullYear();
    var selectedMonth = selecteedDate.getMonth();
    var selectedDay = selecteedDate.getDate();

    if (CurrentYear > selectedYear) {
      this.validdate = false;
    }
    else {
      if (currentMonth > selectedMonth) {
        this.validdate = false;
      }
      else {
        if (CurrentDay >= selectedDay) {
          this.validdate = false;
        }
        else {
          this.validdate = true;
        }
      }
    }
  }

  ToDateChanged(date) {
    this.Todate = date;
    var CurrentDate = new Date();
    var CurrentYear = CurrentDate.getFullYear();
    var currentMonth = CurrentDate.getMonth();
    var CurrentDay = CurrentDate.getDate();

    var selecteedDate = new Date(date);
    var selectedYear = selecteedDate.getFullYear();
    var selectedMonth = selecteedDate.getMonth();
    var selectedDay = selecteedDate.getDate();

    if (CurrentYear > selectedYear) {
      this.validdate = false;
    }
    else {
      if (currentMonth > selectedMonth) {
        this.validdate = false;
      }
      else {
        if (CurrentDay >= selectedDay) {
          this.validdate = false;
        }
        else {
          this.validdate = true;
        }
      }
    }
  }

  DownloadReport() {

    let t = localStorage.getItem('userToken');
    t = 'Bearer' + t;


    if (this.FromDate != null && this.Todate != null) {
      if (this.Todate >= this.FromDate) {

        this.CRM_OppertunityService.GetCrmOpportunityReports(t, this.EmployeeIds, this.FromDate, this.Todate);
      }
    }

  }




}
