    <div class="row">
        <div class="col-lg-4">
            <div class="col-body-border-right">
                <div class="form-group row">
                    <label  class="col-sm-4 col-form-label clear-padding-right">code</label>
                    <div class="col-sm-7 clear-left-padding">
                        <input type="text" disabled="disabled" [(ngModel)]="EmployeeMasterService.SharedEmployees.EmployeeCode" id ="EmployeeCode" name="EmployeeCode" class="form-control form-control-custom form-control-custom-new">
                    </div>
                </div>
            </div>
        </div>
        <div class="col-lg-4">
            <div class="form-group row">
                <label  class="col-sm-4 col-form-label clear-padding-right">Name</label>
                <div class="col-sm-7 clear-left-padding">
                    <input type="text" disabled="disabled" [(ngModel)]="EmployeeMasterService.SharedEmployees.FullName" id ="FullName" name="FullName" class="form-control form-control-custom form-control-custom-new">
                </div>
            </div>
         </div>
    </div>

    <fieldset class="body-form-control">
    <app-dialog [(visible)]="this.SuccessStatus">
        <span class="successmsg">{{this.SuccessMessage}}</span>
    </app-dialog>
  
    <legend class="legend--">Employee Family Details</legend>
    <form #EmployeeFamilyDetailForm="ngForm" (ngSubmit)="SaveEmpolyeeFamilydetails()">
        <div lass="row" >
            <table id="tblComponent" class="table table-striped table-bordered text-left table-new row-border hover">
                <thead class="thead-blue text-center">
                    <tr>
                        <th style="width: 25%;">Relation </th>
                        <th style="width: 25%;">Name</th>  
                        <th style="width: 25%;">Gender</th>
                        <th style="width: 25%;">Date Of Birth</th>       
                        
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let Fam of this.EmployeeMasterService.SharedEmployees.EmployeeFamilyDetailsModel, let i = index">
                        <td >
                            <select  [(ngModel)]="Fam.FamilyRelationId" tabindex="15" id ="FamilyRelationId_{{i}}" class="form-control" name="FamilyRelationId_{{i}}" #FamilyRelationId="ngModel">
                                <option [value]="0" [disabled]=true >Select Relation</option>
                                <option *ngFor="let f of Relations" [value]="f.RelationshipId">{{f.RelationshipName}}</option>
                            </select>
                        </td>
                        <td>
                            <input type="text" [(ngModel)]="Fam.FamilyMemberName" maxlength="500" id ="FamilyMemberName_{{i}}" name="FamilyMemberName_{{i}}" #FamilyMemberName="ngModel" class="form-control form-control-custom form-control-custom-new"  >
                        </td>
                        <td >
                            <select  [(ngModel)]="Fam.FamilyGenderId" tabindex="15" id ="FamilyGenderId_{{i}}" class="form-control" name="FamilyGenderId_{{i}}" #FamilyGenderId="ngModel">
                                <option [value]="0" [disabled]=true >Select Gender</option>
                                <option *ngFor="let G of Ganders" [value]="G.GenderId">{{G.GenderName}}</option>
                            </select>
                        </td>
                        <td >                   
                            <input class="form-control"  style="float:none" placeholder="Select date" ngx-mydatepicker name="FamilyDOB_{{i}}"
                            [(ngModel)]="Fam.FamilyDOB" [options]="myOptions" #dp="ngx-mydatepicker" (dateChanged)="OnChangeDOBF($event,i)"/>
                            <span class="mt-2">
                                <a class="btn btn-primary pl-10 pr-10 pt-1 pb-1" (click)="dp.toggleCalendar()">
                                    <i style="color:#fff" class="fas fa-calendar-alt fa-lg"></i>
                                </a>
                                <a class="btn btn-danger pl-10 pr-10 pt-1 pb-1" (click)="dp.clearDate()">
                                    <i style="color:#fff" class="far fa-calendar-times fa-lg"></i>
                                </a>
                            </span>
                        </td>
                    </tr>
                </tbody>
            </table>
  
            <div class="row float-right">
                <div  class=" col-auto text-center" *ngIf="this.EmployeeMasterService.SharedEmployees.EmployeeId>0" >
                    <button  type="submit" tabindex="22" class="btn btn-primary save-button" [disabled]="!(EmployeeFamilyDetailForm.valid && this.DisableSave==true)" >Save & Next</button>  
                </div>
                <div class=" col-auto text-center">
                    <button  type="button"  tabindex="23" class="btn btn-danger cancle-button" (click)="RefreshPage()">Cancel</button>
                </div>
                <div class=" col-auto text-center">
                    <button  type="button"  tabindex="23" class="btn btn-primary pl-10 pr-10 pt-1 pb-1" (click)="AddnweRow()">Add Row </button>
                </div>
            </div>
        </div>
    </form>
</fieldset>