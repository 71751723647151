import { Component, OnInit} from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from '../../shared/user.service';
import { EmployeePortalService }  from '../../shared/HR_ADMIN/EmployeePortal/EmployeePortalService.service';
import { EmployeeDetail } from '../../shared/HR_ADMIN/Employee/EmployeeDetail.Model';
import { AttendanceRegularationModel } from '../../shared/HR_ADMIN/Approve/AttendanceRegularationModel.Model'
import { Menu } from '../../shared/menu.model';
import { ModalOptions,BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ApproveLeaveService }  from '../../shared/HR_ADMIN/Approve/ApproveLeave.service';

@Component({
  selector: 'app-attendance-regularaisation-status',
  templateUrl: './attendance-regularaisation-status.component.html',
  styleUrls: ['./attendance-regularaisation-status.component.css']
})
export class AttendanceRegularaisationStatusComponent implements OnInit 
{
  AttendanceRegularationModel : AttendanceRegularationModel= new AttendanceRegularationModel();
  RegularationPending : AttendanceRegularationModel[]=[];
  RegularationApproved : AttendanceRegularationModel[]=[];
  RegularationRejected : AttendanceRegularationModel[]=[];
  Regularation : any;
  userClaims : any;
  employee : any;
  Result : string;
  SuccessStatus :boolean=false;
  SuccessMessage : string ="";
  public tableWidget : any;
  selectedIndex : number;
  SelectedMenu : Menu;
  ModelListApplication: AttendanceRegularationModel;
  CancelMsg : boolean= false;
  ShowPopup : boolean= false;
  public modalRef: BsModalRef;
  public modelopt:ModalOptions;
  Approver : any;
  RefId : any;
  //New
  PendingMsg : boolean= false;
  ApprovedMsg : boolean= false;
  RejectedMsg : boolean= false;
  validdate : boolean= false;
  validTodate : boolean= false;
  DisableSave : boolean;

  constructor(private EmployeePortalService: EmployeePortalService, private rout: Router, private ApproveLeaveService:ApproveLeaveService, private userService: UserService, private modalService: BsModalService)
  { 
    this.ModelListApplication = new AttendanceRegularationModel();
    this.modelopt=new ModalOptions();
    this.modelopt.class='heavy_modal';
    this.modalService.config= this.modelopt;
    this.modelopt.ignoreBackdropClick=true;
  }

  ngOnInit() 
  {
    // Begning Permission Portion 
    this.SelectedMenu=this.userService.SelectedMenu;
    if(this.SelectedMenu !=undefined)
    {
      localStorage.setItem('selectedmenu',JSON.stringify(this.SelectedMenu));
      this.rout.navigate([this.SelectedMenu.RouteName]);
    }
    else
    {
      if(JSON.parse(localStorage.getItem('selectedmenu'))!=undefined)
      {
        this.SelectedMenu = JSON.parse(localStorage.getItem('selectedmenu'));
        this.rout.navigate([this.SelectedMenu.RouteName]);
      }
    }
    // End of the permission portion
    this.GetEmployeeDetails();
    this.DisableSave=true;
    this.ModelListApplication.Status=0;
    this.validdate=false;
    this.validTodate=false;
    this.DisableSave=true;
  }

  GetEmployeeDetails()
  {
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.EmployeePortalService.GetEmployeeDetailsByEmployeeId( t).then(x => 
    {
      this.employee = x;
      Object.keys(this.employee).forEach( key => 
      {
        this.employee[key];
        let employeetemp = new EmployeeDetail();
        employeetemp = this.employee[key];
        this.AttendanceRegularationModel.EmployeeCode=this.employee.EmployeeCode;
        this.AttendanceRegularationModel.EmployeeName=this.employee.EmployeeName;
      });
    });
  }

  GetEmployeeRegularationPendingDetails(FromDate,ToDate)
  {
    this.RegularationPending=[];
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.EmployeePortalService.GetAttendanceRegularisationStatusPending( t,FromDate,ToDate).then(x => 
    {
      this.Regularation = x;
      Object.keys(this.Regularation).forEach( key => 
      {
        this.Regularation[key];
        let Regularationtemp = new AttendanceRegularationModel();
        Regularationtemp = this.Regularation[key];
        this.RegularationPending.push(Regularationtemp);
      });
      if(this.RegularationPending.length == 0)
      {
        this.PendingMsg = true;
        this.ApprovedMsg = false;
        this.RejectedMsg = false;
      }
    });
  }

  GetEmployeeRegularationApprovedDetails(FromDate,ToDate)
  {
    this.RegularationApproved=[];
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.EmployeePortalService.GetAttendanceRegularisationStatusApproved( t,FromDate,ToDate).then(x => 
    {
      this.Regularation = x;
      Object.keys(this.Regularation).forEach( key => 
      {
        this.Regularation[key];
        let Regularationtemp = new AttendanceRegularationModel();
        Regularationtemp = this.Regularation[key];
        this.RegularationApproved.push(Regularationtemp);
      });
      if(this.RegularationApproved.length == 0)
      {
        this.PendingMsg = false;
        this.ApprovedMsg = true;
        this.RejectedMsg = false;
      }
    });
  }

  GetEmployeeRegularationRejectedDetails(FromDate,ToDate)
  {
    this.RegularationRejected=[];
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.EmployeePortalService.GetAttendanceRegularisationStatusRejected( t,FromDate,ToDate).then(x => 
    {
      this.Regularation = x;
      Object.keys(this.Regularation).forEach( key => 
      {
        this.Regularation[key];
        let Regularationtemp = new AttendanceRegularationModel();
        Regularationtemp = this.Regularation[key];
        this.RegularationRejected.push(Regularationtemp);
      });
      if(this.RegularationRejected.length == 0)
      {
        this.PendingMsg = false;
        this.ApprovedMsg = false;
        this.RejectedMsg = true;
      }
    });
  }

  viewAppliedRegularization(id,template) 
  {
    this.DisableSave=true;
    this.ModelListApplication.Remarks="";
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.ApproveLeaveService.GetAttendanceRegularationdetailsByID( t,id).then(x => 
    {
      if(this.ShowPopup==true)
      {
        this.modalRef.hide();
      }
      this.modalRef = this.modalService.show(template,this.modelopt);
      this.ShowPopup=true;
      this.CancelMsg=false;
      this.Approver = x;
      Object.keys(this.Approver).forEach( key => 
      {
        // tslint:disable-next-line:no-unused-expression
        this.Approver[key];
        let Approvaltemp = new AttendanceRegularationModel();
        Approvaltemp = this.Approver[key];
        this.ModelListApplication.RefId=Approvaltemp.RefId;
        this.ModelListApplication.EmployeeId=Approvaltemp.EmployeeId;
        this.ModelListApplication.EmployeeCode=Approvaltemp.EmployeeCode;
        this.ModelListApplication.EmployeeName=Approvaltemp.EmployeeName; 
        this.ModelListApplication.EmployeeEmailId=Approvaltemp.EmployeeEmailId; 
        this.ModelListApplication.AttendanceDate=Approvaltemp.AttendanceDate; 
        this.ModelListApplication.AttendanceDay=Approvaltemp.AttendanceDay; 
        this.ModelListApplication.ActualInTime=Approvaltemp.ActualInTime; 
        this.ModelListApplication.ActualOutTime=Approvaltemp.ActualOutTime; 
        this.ModelListApplication.ActualWorkingDuration=Approvaltemp.ActualWorkingDuration; 
        this.ModelListApplication.StartTime=Approvaltemp.StartTime;
        this.ModelListApplication.EndTime=Approvaltemp.EndTime; 
        this.ModelListApplication.TotalWorkingHours=Approvaltemp.TotalWorkingHours; 
        this.ModelListApplication.RegularationDuration=Approvaltemp.RegularationDuration; 
        this.ModelListApplication.Reason=Approvaltemp.Reason; 
        this.ModelListApplication.RHCode=Approvaltemp.RHCode;
        this.ModelListApplication.RHName=Approvaltemp.RHName;
        this.ModelListApplication.RHEmailId=Approvaltemp.RHEmailId;
        this.ModelListApplication.CompanyId=Approvaltemp.CompanyId;
      });
    });
  }

  AttendanceRegularationCancel(template) :void
  {
    if(confirm("Are you sure to Cancel "))
    {
      this.DisableSave=false;
      let t =  localStorage.getItem('userToken');
      t = 'Bearer' + t;
      if(this.ModelListApplication.RefId > 0)
      {
        this.ModelListApplication.IsRejected=true;
        this.ApproveLeaveService.AttendanceRegularationCancel(t ,this.ModelListApplication).subscribe(res=>
        {
          this.RefId=res;
          this.ModelListApplication.RefId=0;  
          if(this.RefId>0)
          {
            this.ModelListApplication.Remarks="";
            this.modalRef.hide();
            this.CancelMsg=true;
            this.ShowPopup=false;
            this.modalRef = this.modalService.show(template);
            // this.GetEmployeeRegularationPendingDetails();
            // this.GetEmployeeRegularationRejectedDetails();
            this.DisableSave=true;
          }
        });                  
      }
    }
  }

  SearchFromDateChanged( date)
  {
    this.DisableSave = true;
    this.PendingMsg = false;
    this.ApprovedMsg = false;
    this.RejectedMsg = false;
    this.RegularationPending=[];
    this.RegularationApproved=[];
    this.RegularationRejected=[];
    this.ModelListApplication.SearchFromDate=date;
  }

  SearchToDateChanged( date)
  {
    this.DisableSave = true;
    this.PendingMsg = false;
    this.ApprovedMsg = false;
    this.RejectedMsg = false;
    this.RegularationPending=[];
    this.RegularationApproved=[];
    this.RegularationRejected=[];
    this.ModelListApplication.SearchToDate=date;
  }

  StatusChanged()
  {
    this.DisableSave = true;
    this.PendingMsg = false;
    this.ApprovedMsg = false;
    this.RejectedMsg = false;
    this.RegularationPending=[];
    this.RegularationApproved=[];
    this.RegularationRejected=[];
  }

  submitClick()
  {
    this.DisableSave = false;
    this.validdate = false;
    this.validTodate=false;
    this.PendingMsg = false;
    this.ApprovedMsg = false;
    this.RejectedMsg = false;
    this.RegularationPending=[];
    this.RegularationApproved=[];
    this.RegularationRejected=[];
    if(this.ModelListApplication.SearchFromDate!=null && this.ModelListApplication.SearchToDate!=null && this.ModelListApplication.Status > 0)
    {
      if(this.ModelListApplication.SearchToDate >= this.ModelListApplication.SearchFromDate)
      {
        if(this.ModelListApplication.Status == 1)
        {
          this.GetEmployeeRegularationPendingDetails(this.ModelListApplication.SearchFromDate, this.ModelListApplication.SearchToDate);
        }
        else if(this.ModelListApplication.Status == 2)
        {
          this.GetEmployeeRegularationApprovedDetails(this.ModelListApplication.SearchFromDate, this.ModelListApplication.SearchToDate);
        }
        else
        {
          this.GetEmployeeRegularationRejectedDetails(this.ModelListApplication.SearchFromDate, this.ModelListApplication.SearchToDate);
        }
      }
      else
      {
        this.validTodate = true;
      }
    }
  }
}
