import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs/Subject';
import { DataTableDirective } from 'angular-datatables';
import { UserService } from '../../shared/user.service';
import { UserMasterService } from '../../shared/HR_ADMIN/UserMaster/UserMasterService.service';
import { UserModel } from  '../../shared/HR_ADMIN/UserMaster/UserModel.Model';
// Begning Confirmation
import { ConfirmationService } from '@jaspero/ng2-confirmations';
import { ResolveEmit } from '../../../app/resolve-emit';
import { ConfirmSettings } from '../../../app/confirm-settings';
import { Menu } from '../../shared/menu.model';
// end of Confirmation

@Component({
  selector: 'app-manage-change-password',
  templateUrl: './manage-change-password.component.html',
  styleUrls: ['./manage-change-password.component.css']
})

export class ManageChangePasswordComponent implements OnInit 
{
  UserModel : UserModel= new UserModel();
  userClaims: any;
  UserMasters: UserModel[] = [];
  Result : string;
  SuccessStatus:boolean=false;
  SuccessMessage: string ="";
  public tableWidget: any;
  UserMaster: any;
  Id : any;
  base64textString : string;
  tempUserMasterModeladd : UserModel =new UserModel();

  // Begning Permission Portion    
  SelectedMenu:Menu;
  IsDataIsInViewState : boolean=false;
  CanViewState : boolean=false;
  DisableSave :boolean=false;
  // End of the permission portion
  //check for duplicate User Id
  CheckExiststatus : boolean=null;  
  Checkeditstatus : boolean =false;
  check : string ="";
  checkdata : any;
  checkdataval : string ="";
  //End check for duplicate User Id
  PasswordMisMatch: boolean =false;
  WrongPassword: boolean =false;
  PasswordExists: boolean =false;

  constructor(private router: Router,private UserMasterService: UserMasterService, private rout: Router,  private _confirmation: ConfirmationService, private userService: UserService) { }
  
  @ViewChild(DataTableDirective, {static: false})
  setDtOptions: DataTables.Settings = {};
  cardDtOptions: DataTables.Settings = {};
  dtElement: DataTableDirective;
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();
  
  ngOnInit() 
  {
    //Begin Permission Portion
    this.SelectedMenu=this.userService.SelectedMenu;
    if(this.SelectedMenu !=undefined)
    {
      localStorage.setItem('selectedmenu',JSON.stringify(this.SelectedMenu));
      this.rout.navigate([this.SelectedMenu.RouteName]);
    }
    else
    {
      if(JSON.parse(localStorage.getItem('selectedmenu'))!=undefined)
      {
        this.SelectedMenu = JSON.parse(localStorage.getItem('selectedmenu'));
        this.rout.navigate([this.SelectedMenu.RouteName]);
      }
    }
    if(this.SelectedMenu.CanAdd)
    {
      this.IsDataIsInViewState=false;
    }
    else
    {
      this.IsDataIsInViewState=true;
    }
    if(this.SelectedMenu.CanView)
    {
      this.CanViewState=false;
    }
    else
    {
      this.CanViewState=true;
    }
    //End of the peremission portion
    this.GetUserDetails();
    this.DisableSave=true;
    if(localStorage.getItem('SuccessfullMessage'))
    {
      this.SuccessStatus=true;
      this.SuccessMessage =localStorage.getItem('SuccessfullMessage')
      localStorage.removeItem('SuccessfullMessage');
    }  
  }

  ngAfterViewInit(): void 
  {

  }

  settings: ConfirmSettings | any = 
  {
    overlay: true,
    overlayClickToClose: true,
    showCloseButton: true,
    confirmText: 'Yes',
    declineText: 'No'
  };
 
  GetUserDetails() 
  { 
    // Begning of the permission portion
    this.IsDataIsInViewState=false;
    // End of the permision portion
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.UserMasterService.GetUserDetailsforPasswordChange( t).then(x => 
    {
      this.Id = x;
      Object.keys(this.Id).forEach( key => 
      {
        // tslint:disable-next-line:no-unused-expression
        this.Id[key];
        let UserMastertemp = new UserModel();
        UserMastertemp = this.Id[key];
        this.UserModel.Id=UserMastertemp.Id;
        this.UserModel.UserId=UserMastertemp.UserId;
        this.UserModel.EmployeeId=UserMastertemp.EmployeeId;
        // this.UserModel.Password=UserMastertemp.Password;
        // this.UserModel.RePassword=UserMastertemp.Password;
        this.UserModel.MainPassword=UserMastertemp.Password;
        this.UserModel.EmployeeName=UserMastertemp.EmployeeName;
        this.UserModel.EmployeeCode=UserMastertemp.EmployeeCode;
        this.UserModel.EmailId=UserMastertemp.EmailId;
      });
    });
  }

  UpdateUserIdPassword() :void
  {
    this._confirmation.create('Confirm to Change Password', 'Press Yes to Change', this.settings)
    .subscribe((ans: ResolveEmit) => 
    {
      if (ans.resolved == true) 
      {
        this.DisableSave=false;
        let t =  localStorage.getItem('userToken');
        t = 'Bearer' + t;
        this.UserModel.IsActive=true;
        if(this.UserModel.Id > 0)
        {
          this.UserMasterService.UpdateUserIdPassword(t ,this.UserModel).subscribe(res=>
          {
            this.Id=res;
            this.UserModel.Id=0;  
            // if(this.Id>0)
            // {
            //   // localStorage.setItem('SuccessfullMessage', "Password Change Successfully");
            //   // this.rout.navigateByUrl('/ManageUserMaster/MenuMaster', {skipLocationChange: true}).then(()=>this.rout.navigate(["/ManageUserMaster/ChangePassword"]));
            // }
            let t =  localStorage.getItem('userToken');
            t = 'Bearer' + t;
            this.userService.UpdateLogOutTime(t ,this.UserModel.Id).subscribe(res=>
            {
              this.UserModel.Id=0;  
            });
            localStorage.removeItem('userToken');
            this.router.navigate(['/login']);
          });
        }
      }
    })
  }

  RefreshPage()
  {
    this.rout.navigateByUrl('/ManageUserMaster/MenuMaster', {skipLocationChange: true}).then(()=>this.rout.navigate(["/ManageUserMaster/ChangePassword"]))
  }

   // begning of check duplicate User Id & Employee Id
   
  CheckPassword()
  {
    if(this.UserModel.Password != null)
    {
      if(this.UserModel.Password != this.UserModel.RePassword)
      {
        this.PasswordMisMatch = true;
      }
      else
      {
        this.PasswordMisMatch = false;
      }
      if(this.UserModel.Password == this.UserModel.CurrentPassword)
      {
        this.PasswordExists=true;
      }
      else
      {
        this.PasswordExists=false;
      }
    }
  }

  CheckCurrentPassword()
  {
    if(this.UserModel.CurrentPassword != null)
    {
      if(this.UserModel.MainPassword != this.UserModel.CurrentPassword)
      {
        this.WrongPassword = true;
      }
      else
      {
        this.WrongPassword = false;
      }
      if(this.UserModel.Password == this.UserModel.CurrentPassword)
      {
        this.PasswordExists=true;
      }
      else
      {
        this.PasswordExists=false;
      }
    }
    else
    {
      this.WrongPassword = true;
    }
  }
}