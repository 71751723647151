import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs/Subject';
import { DataTableDirective } from 'angular-datatables';
import { UserService } from '../../shared/user.service';
// Begning Confirmation
import { ConfirmationService } from '@jaspero/ng2-confirmations';
//import { ResolveEmit } from '../../../app/resolve-emit';
import { ConfirmSettings } from '../../../app/confirm-settings';
// end of Confirmation
import { Menu } from '../../shared/menu.model';
import { INgxMyDpOptions, IMyDateModel } from 'ngx-mydatepicker';
import { ApproveLeaveService }  from '../../shared/HR_ADMIN/Approve/ApproveLeave.service'
import { EmployeeConfirmationModel }  from '../../shared/HR_ADMIN/Approve/EmployeeConfirmationModel.Model';

@Component({
  selector: 'app-confirmation-approval',
  templateUrl: './confirmation-approval.component.html',
  styleUrls: ['./confirmation-approval.component.css']
})
export class ConfirmationApprovalComponent implements OnInit 
{
  EmployeeConfirmationModel : EmployeeConfirmationModel= new EmployeeConfirmationModel();
  EmployeeDetails: EmployeeConfirmationModel[] = [];
  EmployeeDetail: any;
  userClaims: any;
  SubMenues : Menu[]=[];
  Mod : any;
  Approver: any;
  Result : string;
  SuccessStatus:boolean=false;
  SuccessMessage: string ="";
  public tableWidget: any;
  RefId : any;
  // Begning Permission Portion    
  SelectedMenu:Menu;
  IsDataIsInViewState : boolean=false;
  CanViewState : boolean=false;  
  CanApproveState : boolean=false; 
  GridShow : boolean=false; 
  DisableSave : boolean=false;
  // End of the permission portion
  // begin for Validation
  validdate : boolean= false;
  // end of validation
  myOptions: INgxMyDpOptions = 
  {
    // other options...
    dateFormat: 'dd.mm.yyyy',
  };
  constructor(private ApproveLeaveService:ApproveLeaveService, private rout: Router, private _confirmation: ConfirmationService,  private userService: UserService)
  { 

  }

  ngOnInit() 
  {
    //Begin Permission Portion
    this.SelectedMenu=this.userService.SelectedMenu;
    if(this.SelectedMenu !=undefined)
    {
      localStorage.setItem('selectedmenu',JSON.stringify(this.SelectedMenu));
      this.rout.navigate([this.SelectedMenu.RouteName]);
    }
    else
    {
      if(JSON.parse(localStorage.getItem('selectedmenu'))!=undefined)
      {
        this.SelectedMenu = JSON.parse(localStorage.getItem('selectedmenu'));
        this.rout.navigate([this.SelectedMenu.RouteName]);
      }
    }
    if(this.SelectedMenu.CanAdd)
    {
      this.IsDataIsInViewState=false;
    }
    else
    {
      this.IsDataIsInViewState=true;
    }
    if(this.SelectedMenu.CanView)
    {
      this.CanViewState=false;
    }
    else
    {
      this.CanViewState=true;
    }
    if(this.SelectedMenu.CanApprove)
    {
      this.CanApproveState=false;
    }
    else
    {
      this.CanApproveState=true;
    }
    //End of the peremission portion
    if(localStorage.getItem('SuccessfullMessage'))
    {
      this.SuccessStatus=true;
      this.SuccessMessage =localStorage.getItem('SuccessfullMessage')
      localStorage.removeItem('SuccessfullMessage');
    }
    this.DisableSave=true;
    this.GetEmployeeDetails();
    this.EmployeeConfirmationModel.EmployeeId=0;
  }

  settings: ConfirmSettings | any = 
  {
    overlay: true,
    overlayClickToClose: true,
    showCloseButton: true,
    confirmText: 'Yes',
    declineText: 'No'
  };

  onDateChanged(event: IMyDateModel): void
  {
    const monthNames = [
    'January', 'February', 'March',
    'April', 'May', 'June', 'July',
    'August', 'September', 'October',
    'November', 'December'
    ];
    var m= event.jsdate.getMonth();
    m=m+1;
    var d= event.jsdate.getDate();
    var y= event.jsdate.getFullYear();
    var fulldate="";
    if(m>=10)
    {
      if(d < 10)
      {
        fulldate= m + '/' + '0'+ d + '/' + y;
      }
      else
      {
        fulldate= m + '/' + d + '/' + y; 
      }
    }
    else
    {
      if(d < 10)
      {
        fulldate= '0' + m + '/' + '0'+ d + '/' + y;
      }
      else
      {
        fulldate= '0' + m + '/' + d + '/' + y; 
      } 
    }
    this.EmployeeConfirmationModel.ConfirmedDatePass=fulldate;
  }

  GetEmployeeDetails()
  {
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.ApproveLeaveService.GetEmployeeForConfirmation( t).then(x => 
    {
      this.EmployeeDetail = x;
      Object.keys(this.EmployeeDetail).forEach( key => 
      {
        this.EmployeeDetail[key];
        let EmployeeDetailtemp = new EmployeeConfirmationModel();
        EmployeeDetailtemp = this.EmployeeDetail[key];
        this.EmployeeDetails.push(EmployeeDetailtemp);
      });
    });
  }

  OnEmployeeddlChange(EmployeeId)
  {
    let objemp = new EmployeeConfirmationModel();
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.ApproveLeaveService.GetEmployeeByEmpIdForConfirmation( t,EmployeeId).then(x => 
    {
      this.EmployeeDetail = x;
      Object.keys(this.EmployeeDetail).forEach( key => 
      {
        this.EmployeeDetail[key];
        let EmployeeDetailtemp = new EmployeeConfirmationModel();
        EmployeeDetailtemp = this.EmployeeDetail[key];
        this.EmployeeConfirmationModel.JoingingDate=EmployeeDetailtemp.JoingingDate;
        this.EmployeeConfirmationModel.ConfirmationDate=EmployeeDetailtemp.ConfirmationDate;
        this.EmployeeConfirmationModel.EmployeeName=EmployeeDetailtemp.EmployeeName;
        this.EmployeeConfirmationModel.EmployeeEmailId=EmployeeDetailtemp.EmployeeEmailId;
        this.EmployeeConfirmationModel.Designation=EmployeeDetailtemp.Designation;
        this.EmployeeConfirmationModel.Department=EmployeeDetailtemp.Department;
        this.EmployeeConfirmationModel.Branch=EmployeeDetailtemp.Branch;
        this.EmployeeConfirmationModel.FHId=EmployeeDetailtemp.FHId;
        this.EmployeeConfirmationModel.FHCode=EmployeeDetailtemp.FHCode;
        this.EmployeeConfirmationModel.FHName=EmployeeDetailtemp.FHName;
        this.EmployeeConfirmationModel.FHEmailId=EmployeeDetailtemp.FHEmailId;
        this.EmployeeConfirmationModel.RHId=EmployeeDetailtemp.RHId;
        this.EmployeeConfirmationModel.RHCode=EmployeeDetailtemp.RHCode;
        this.EmployeeConfirmationModel.RHName=EmployeeDetailtemp.RHName;
        this.EmployeeConfirmationModel.RHEmailId=EmployeeDetailtemp.RHEmailId;
        this.EmployeeConfirmationModel.CompanyId=EmployeeDetailtemp.CompanyId;
        this.EmployeeConfirmationModel.ConfirmedDatePass=EmployeeDetailtemp.ConfirmedDatePass;
        if(this.EmployeeConfirmationModel.ConfirmedDatePass != null)
        {
          this.EmployeeConfirmationModel.ConfirmedDate=objemp.ReturnFormatdate(this.EmployeeConfirmationModel.ConfirmedDatePass)
        }
        else
        {
          this.EmployeeConfirmationModel.ConfirmedDate=null;
        }
        this.EmployeeConfirmationModel.SentMailid=EmployeeDetailtemp.SentMailid;
        this.EmployeeConfirmationModel.SentMailPassword=EmployeeDetailtemp.SentMailPassword;
        this.EmployeeConfirmationModel.HRISURL=EmployeeDetailtemp.HRISURL;
      });
    });
  }

  ConfirmationApproved() :void
  {
    this.DisableSave=false;
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    if(this.EmployeeConfirmationModel.EmployeeId > 0)
    {
      this.EmployeeConfirmationModel.IsApproved=true;
      this.EmployeeConfirmationModel.IsRejected=false;
      this.ApproveLeaveService.ConfirmationApprovedOrRejected(t ,this.EmployeeConfirmationModel).subscribe(res=>
      {
        this.RefId=res;
        if(this.RefId>0)
        {
          localStorage.setItem('SuccessfullMessage', "Confirmed Successfully");
          this.rout.navigateByUrl('/AdminApproveOrReject/KRAREPORTING', {skipLocationChange: true}).then(()=>this.rout.navigate(["/AdminApproveOrReject/Confirmation"]));
        }
      });                  
    }
  }

  RefreshPage()
  {
    this.rout.navigateByUrl('/AdminApproveOrReject/KRAREPORTING', {skipLocationChange: true}).then(()=>this.rout.navigate(["/AdminApproveOrReject/Confirmation"]));
  }
}