export class FinancialYear
{
    public  FinancialyearId : number;
    public  FinancialYear : string;
    public  Year : string;
    public  FromDate : Date;
    public  ToDate : Date;
    public  StatusId : number;
    public  Status : string;
    public  IsActive : boolean;
    public  CreatedBy : number;
}