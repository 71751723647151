<perfect-scrollbar style="max-width: 1260px; max-height: 584px;" class="mukesh">
  <div class="body-content-area tabcontent" id="link1">
    <div class="row">
        <div class="col-lg-9">
            <div class="row">
                <div class="col-lg-1 low-padding text-center">
                    <!-- <img *ngIf="this.SelectedMenu.Image!=null" src={{this.SelectedMenu.Image}} class="body-icon" alt="Icon"/> -->
                </div>
                <div class="col-lg-10 clear-padding">
                    <span class="page-title">Manage Employee Assets Details</span>
                    <small class="page-description"></small>
                </div>
            </div>
        </div>
    </div>

    <div *ngIf="!(this.CanViewState)" style=" height:100%;">
    <table id="tblEmployeeAssetDetails" datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" class="table table-striped table-bordered text-center table-new row-border hover">
        <thead class="thead-blue text-center">
            <tr>
                <th>Sl No.</th>
                <th>Employee</th>
                <th>Asset</th>
                <th>Particulars</th>
                <th>Serial Number</th>
                <th>Amount</th>
                <th>Action</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let ESD of EmployeeAssets, let i = index">
                <td scope="row">{{i+1}}</td>
                <td class="text-left">{{ESD.EmployeeCode}} - {{ESD.EmployeeName}}</td>
                <td class="text-left">{{ESD.AssetsName}}</td>
                <td class="text-left">{{ESD.Particulars}}</td>
                <td class="text-left">{{ESD.SerialNumber}}</td>
                <td class="text-right">{{ESD.Amount}}</td>
                <td>
                    <button *ngIf="this.SelectedMenu.CanEdit"  type="button" (click)="GetEmployeeAssetsDetailsById( ESD.RefId )" class="table-edit-btn"><i class="fas fa-edit"></i> </button> 
                    <button *ngIf="this.SelectedMenu.CanDelete" type="button" (click)="DeleteEmployeeAssetDetailsByid( ESD.RefId )" class="table-remove-btn"><i class="fas fa-trash-alt"></i>  </button>
                    <button *ngIf="this.SelectedMenu.CanView"  type="button" (click)="viewEmployeeAssetsDetailsById( ESD.RefId )" class="table-view-button" type="button" ><i class="fas fa-eye"></i></button>
                </td>
            </tr>
        </tbody>
    </table>
    <fieldset class="body-form-control">
        <app-dialog [(visible)]="this.SuccessStatus">
            <span class="successmsg">{{this.SuccessMessage}}</span>
        </app-dialog>
        
        <legend class="legend--">Employee Assets Creation</legend>
        <form #EmployeeAssetDetailsForm="ngForm" (ngSubmit)="saveEmployeeAssetsDetails()">
          <div class="row">
            <div class="col-lg-4">
              <div  class="col-body-border-right">
                <div class="form-group row" >
                  <label class="col-sm-2 col-form-label clear-padding-right">Employee</label>
                  <div class="col-sm-9 clear-left-padding">
                    <angular2-multiselect tabindex="1" class="form-control" [data]="emp" [(ngModel)]="selectedItems" name="EmployeeId" 
                      [settings]="EmployeeSettings" (onSelect)="onItemSelect($event)" (onDeSelect)="OnItemDeSelect($event)" (onSelectAll)="onSelectAll($event)"
                      (onDeSelectAll)="onDeSelectAll($event)">
                    </angular2-multiselect>
                </div>
                <div class="required-validation" *ngIf="EmployeeAssetsModel.EmployeeId==0">Employee is required</div>
                </div>
                <div class="form-group row">
                  <label class="col-sm-4 col-form-label clear-padding-right">Serial Number</label>
                  <div class="col-sm-7 clear-left-padding">
                    <input type="text" [(ngModel)]="EmployeeAssetsModel.SerialNumber" tabindex="4" maxlength="500" id ="SerialNumber" name="SerialNumber" #SerialNumber="ngModel" required class="form-control form-control-custom form-control-custom-new" >
                  </div>
                  <div *ngIf="SerialNumber.invalid && (SerialNumber.dirty || SerialNumber.touched)" class="alert alert-danger">
                    <div *ngIf="SerialNumber.errors.required">Serial Number is required.</div>
                    <div *ngIf="SerialNumber.errors.maxlength">Serial Number is not more than 500 characters</div>
                  </div>      
                </div>
              </div>  
            </div>
            <div  class="col-lg-4">
              <div  class="col-body-border-right">
                <div class="form-group row">
                  <label class="col-sm-4 col-form-label clear-padding-right">Assets Type</label>
                  <div class="col-sm-7 clear-left-padding">
                    <select [(ngModel)]="EmployeeAssetsModel.AssetsId" tabindex="2" id ="AssetsId" class="form-control" name="AssetsId" #AssetsId="ngModel" >
                        <option [value]="0" [disabled]=true >Select Asset Type</option>
                        <option *ngFor="let AT of AssetsTypes" [value]="AT.RefId">{{AT.AssetsName}}</option>
                    </select>
                </div>
                <div class="required-validation" *ngIf="EmployeeAssetsModel.AssetsId==0">Assets is required</div> 
                </div>
                <div class="form-group row">
                  <label class="col-sm-4 col-form-label clear-padding-right">Amount</label>
                  <div class="col-sm-7 clear-left-padding">
                    <input type="text" [(ngModel)]="EmployeeAssetsModel.Amount" tabindex="5" maxlength="10" minlength="3" pattern="[0-9]+" id ="Amount"  name="Amount" #Amount="ngModel" required class="form-control form-control-custom form-control-custom-new" >
                  </div>
                  <div *ngIf="Amount.invalid && (Amount.dirty || Amount.touched)" class="alert alert-danger">
                      <div *ngIf="Amount.errors.maxlength">Amount is not more than 10 digit.</div>
                      <div *ngIf="Amount.errors.minlength">Amount is not less than 3 dogit.</div>
                      <div *ngIf="Amount.errors?.pattern">Amount should be valid(Round Figure).</div>
                  </div>
                </div>
              </div>
            </div>
            <div  class="col-lg-4">
              <div class="form-group row">
                  <label  class="col-sm-4 col-form-label clear-padding-right">Particulars</label>
                  <div class="col-sm-7 clear-left-padding">
                    <input type="text" [(ngModel)]="EmployeeAssetsModel.Particulars" tabindex="3" maxlength="500" id ="Particulars" name="Particulars" #Particulars="ngModel" required class="form-control form-control-custom form-control-custom-new" >
                  </div>
                  <div *ngIf="Particulars.invalid && (Particulars.dirty || Particulars.touched)" class="alert alert-danger">
                    <div *ngIf="Particulars.errors.required">Particulars is required.</div>
                    <div *ngIf="Particulars.errors.maxlength">Particulars is not more than 500 characters</div>
                  </div> 
              </div>
              <div class="form-group row">
                <label class="col-sm-6 col-form-label text-center clear-padding-right" *ngIf="!(this.IsDataIsInViewState)" >
                  <button type="submit" tabindex="6" class="btn btn-primary save-button" [disabled]="!(EmployeeAssetDetailsForm.valid && EmployeeAssetsModel.EmployeeId !=0 && this.DisableSave==true)" >Save</button>  
                </label>
                <div class="col-sm-6 col-form-label text-center">
                  <button type="button" tabindex="7" (click)="RefreshPage()" class="btn btn-danger cancle-button">Cancel</button>
                </div>
              </div>
            </div>
        </div>
        </form>
    </fieldset>
    </div>
  </div>
</perfect-scrollbar>