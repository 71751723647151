export class State 
{
    public  StateID : number;
    public  CountryId : number;
    public  ZoneId : number;
    public  StateName : string;
    public  StateCode : string;
    public  ZoneName : string;
    public  CountryName : number;
    public  CreatedBy : number;
    public  IsActive  : boolean;  
}