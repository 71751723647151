
export class Model_Crm_UserRoll
{

     public  UserRollId :number;
     public  UserId :number;
     public  Roll_Id : number;
     public Rollname : string;
     public  CrmTypeId : number;
     public  EmpId : number;
     public EmpCode : string;
     public EmployeeName : string; 
     public  IsActive :boolean;   
       
    
}       