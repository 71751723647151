<div class="body-content-area tabcontent" id="link1">
<div class="row">
    <div class="col-lg-9">
        <div class="row">
            <div class="col-lg-1 low-padding text-center">
                <!-- <img *ngIf="this.SelectedMenu.Image!=null" src={{this.SelectedMenu.Image}} class="body-icon" alt="Icon"/> -->
            </div>
            <div class="col-lg-10 clear-padding">
                <span class="page-title">Manage Decision Making</span>
                <small class="page-description"></small>
            </div>
        </div>
    </div>
</div>

<div *ngIf="!(this.CanViewState)">  
<table id="tblDecision" datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" class="table table-striped table-bordered text-center table-new row-border hover">
    <thead class="thead-blue text-center">
        <tr>
            <th>Sl No.</th>
            <th class="text-left">Decision Making</th>
            <th>Action</th>
        </tr>
    </thead>
    <tbody>
        <tr *ngFor="let DM of DecisionMakings, let i = index">
            <td scope="row">{{i+1}}</td>
            <td class="text-left">{{DM.DecisionName}}</td>
            <td>
                <button type="button"  *ngIf="this.SelectedMenu.CanEdit" (click)="GetDecisionMakingByid( DM.DecisionId )"class="table-edit-btn"><i class="fas fa-edit"></i> </button> 
                <button type="button"  *ngIf="this.SelectedMenu.CanDelete" (click)="DeleteDecisionMakingByid( DM.DecisionId )"class="table-remove-btn"><i class="fas fa-trash-alt"></i> </button>
                <button *ngIf="this.SelectedMenu.CanView"   type="button" (click)="ViewDecisionMakingByid( DM.DecisionId)" class="table-view-button"  ><i class="fas fa-eye"></i></button>
            </td>
        </tr>
    </tbody>
</table>

<fieldset class="body-form-control">
    <app-dialog [(visible)]="this.SuccessStatus">
        <span class="successmsg">{{this.SuccessMessage}}</span>
    </app-dialog>
    
    <legend class="legend--">Decision Making Creation</legend>
    <form #DecisionMakingForm="ngForm" (ngSubmit)="saveDecisionMaking()">
    <div class="row">
            <div class="col-lg-4" layout-align="center center">
                <div class="form-group row" layout-align="center center">
                    <label class="col-sm-4 col-form-label clear-padding-right">Decision Making</label>
                    <div class="col-sm-7 clear-left-padding">
                        <input type="text" [(ngModel)]="DecisionModel.DecisionName" tabindex="1" maxlength="250" id ="DecisionName"  name="DecisionName" (blur)="CheckName()" #DecisionName="ngModel" required class="form-control form-control-custom form-control-custom-new" >
                    </div>
                    <div *ngIf="this.Namecheck.length > 0" class="alert alert-danger">Decision Making is exist</div>
                    <div *ngIf="DecisionName.invalid && (DecisionName.dirty || DecisionName.touched)" class="alert alert-danger">
                        <div *ngIf="DecisionName.errors.required">Decision Making Name is required.</div>
                        <div *ngIf="DecisionName.errors.maxlength">Decision Making Name is not more than 250 characters</div>
                    </div> 
                </div>
                
                <div class="form-group row">
                    <label class="col-sm-6 col-form-label text-center clear-padding-right" *ngIf="!(this.IsDataIsInViewState)" >
                        <button type="submit" tabindex="2" class="btn btn-primary save-button" [disabled]="!(DecisionMakingForm.valid && this.CheckNameExiststatus==true && this.DisableSave==true)" >Save</button>  
                    </label>
                    <div class="col-sm-6 col-form-label text-center">
                    <button type="button" tabindex="3" (click)="RefreshPage()" class="btn btn-danger cancle-button">Cancel</button>
                    </div>
                </div>
        </div>
    </div>
</form>
</fieldset>
</div>
</div>