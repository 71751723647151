<div class="body-content-area tabcontent" id="link1">
<div class="row">
    <div class="col-lg-9">
        <div class="row">
            <div class="col-lg-1 low-padding text-center">
                <!-- <img *ngIf="this.SelectedMenu.Image!=null" src={{this.SelectedMenu.Image}} class="body-icon" alt="Icon"/> -->
            </div>
            <div class="col-lg-10 clear-padding">
                <span class="page-title">Mapping Salary Component</span>
                <small class="page-description"></small>
            </div>
        </div>
    </div>
</div>

<div *ngIf="!(this.CanViewState)">
<table id="tblSalaryComponentMapping" datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" class="table table-striped table-bordered table-new row-border hover">
    <thead class="thead-blue text-center" style="width: 100%;">
        <tr>
            <th style="width: 5%;">Sl No.</th>
            <th style="width: 15%;">Company</th>
            <th style="width: 15%;">Decision Making</th>
            <th style="width: 15%;">Level</th>
            <th style="width: 15%;">Grade</th>
            <th style="width: 30%;">Salary Component</th>
            <th style="width: 5%;">Action</th>
        </tr>
    </thead>
    <tbody>
        <tr *ngFor="let Sal of SalaryMappings, let i = index">
            <td scope="row">{{i+1}}</td>
            <td class="text-left">{{Sal.CompanyName}}</td>
            <td class="text-left">{{Sal.DecisionName}}</td>
            <td class="text-left">{{Sal.LevelName}}</td>
            <td class="text-left">{{Sal.GradeName}}</td>
            <td class="text-left">{{Sal.SalaryComponentName}}</td>
            <td>
                <button *ngIf="this.SelectedMenu.CanEdit" type="button" (click)="GetSalaryComponentMappingByid( Sal.RefId )" class="table-edit-btn"><i class="fas fa-edit"></i> </button> 
                <button *ngIf="this.SelectedMenu.CanDelete" hidden="hidden" type="button" (click)="DeleteSalaryComponentMappingByid( Sal.RefId )" class="table-remove-btn"><i class="fas fa-trash-alt"></i>  </button>
                <button *ngIf="this.SelectedMenu.CanView" type="button" (click)="viewSalaryComponentMappingByid( Sal.RefId )" class="table-view-button" type="button" ><i class="fas fa-eye"></i></button>
            </td>
        </tr>
    </tbody>
</table>
<fieldset class="body-form-control">
    <app-dialog [(visible)]="this.SuccessStatus">
        <span class="successmsg">{{this.SuccessMessage}}</span>
    </app-dialog>
    
    <legend class="legend--">Mapping Salary Component Creation</legend>
    <form #SalaryComponentMapping="ngForm" (ngSubmit)="saveSalaryComponentMapping()">
        <div class="row">
            <div class="col-lg-4">
                <div class="col-body-border-right">
                    <div class="form-group row">
                        <label  class="col-sm-4 col-form-label clear-padding-right"> Company</label>
                        <div class="col-sm-7 clear-left-padding">
                            <select [(ngModel)]="SalaryMappingModel.CompanyId" tabindex="1" id ="CompanyId" [disabled]="IsCompanyEnable" class="form-control"  name="CompanyId" #CompanyId="ngModel" (blur)="CheckSalarycomponentcode()">
                                <option [value]="0" [disabled]=true >Select Company</option>
                                <option *ngFor="let comp of Companies" [value]="comp.CompId">{{comp.CompName}}</option>
                            </select>
                        </div>
                        <div *ngIf="this.check.length > 0" class="alert alert-danger">Component is already exist for selected Company</div>
                        <div class="required-validation" *ngIf="SalaryMappingModel.CompanyId==0">Company is required</div>
                    </div>
                        <div class="form-group row">
                                <label  class="col-sm-4 col-form-label clear-padding-right">Grade</label>
                                <div class="col-sm-7 clear-left-padding">
                                <select [(ngModel)]="SalaryMappingModel.GradeId" tabindex="4" id ="GradeId" class="form-control" [disabled]="IsGradeEnable"  name="GradeId" #GradeId="ngModel"  (blur)="CheckSalarycomponentcode()">
                                    <option [value]="0" [disabled]=true >Select Grade</option>
                                    <option *ngFor="let Gr of Grades" [value]="Gr.GradeId">{{Gr.GradeName}}</option>
                                </select>
                            </div>
                            <div *ngIf="this.check.length > 0" class="alert alert-danger">Component is already exist for selected Grade</div>
                            <div class="required-validation"  *ngIf="SalaryMappingModel.GradeId==0">Grade is required</div>
                        </div>
                </div>
            </div>
            
            <div class="col-lg-4">
                <div class="col-body-border-right">
                        <div class="form-group row">
                            <label  class="col-sm-4 col-form-label clear-padding-right"> Decision Making</label>
                            <div class="col-sm-7 clear-left-padding">
                                <select [(ngModel)]="SalaryMappingModel.DecisionId" tabindex="2" id ="DecisionId" class="form-control" [disabled]="IsGradeEnable" name="DecisionId" #DecisionId="ngModel" (ngModelChange)="onDecisionChange($event)">
                                <option [value]="0" [disabled]=true >Select Decision</option>
                                <option *ngFor="let d of DecisionMakings" [value]="d.DecisionId">{{d.DecisionName}}</option>
                                </select>
                            </div>
                            <div class="required-validation" *ngIf="SalaryMappingModel.DecisionId==0">Decision Making is required</div>
                        </div>
                </div>
                <div class="form-group row">
                    <label  class="col-sm-4 col-form-label clear-padding-right"> Component</label>
                    <div class="col-lg-11">
                        <angular2-multiselect class="form-control" [data]="ComponentList" [(ngModel)]="ComponentselectedItems" name="ComponentselectedItems" 
                        [settings]="SalaryComponentSettings" (onSelect)="onItemSelect($event)" (onDeSelect)="OnItemDeSelect($event)" (onSelectAll)="onSelectAll($event)"
                        (onDeSelectAll)="onDeSelectAll($event)">
                        </angular2-multiselect>
                    </div>
                </div>
            </div>

            <div class="col-lg-4">
                    <div class="form-group row">
                        <label  class="col-sm-4 col-form-label clear-padding-right"> Level</label>
                        <div class="col-sm-7 clear-left-padding">
                            <select [(ngModel)]="SalaryMappingModel.LevelId" tabindex="3" id ="LevelId" class="form-control" [disabled]="IsGradeEnable" name="LevelId" #LevelId="ngModel" (ngModelChange)="onLevelChange($event)">
                            <option [value]="0" [disabled]=true >Select Level</option>
                            <option *ngFor="let l of Levels" [value]="l.LevelId">{{l.LevelName}}</option>
                            </select>
                        </div>
                        <div class="required-validation" *ngIf="SalaryMappingModel.LevelId==0">Level is required</div>
                    </div>
                    <div class="form-group row">
                        <label class="col-sm-6 col-form-label text-center clear-padding-right" *ngIf="!(this.IsDataIsInViewState)">
                            <button type="submit" tabindex="6" class="btn btn-primary save-button" [disabled]="!(SalaryComponentMapping.valid && this.CheckExiststatus==true && this.ComponentselectedItems.length > 0 && SalaryMappingModel.CompanyId!=0 && SalaryMappingModel.GradeId !=0 && this.DisableSave==true)">Save</button>  
                        </label>
                        <div class="col-sm-6 col-form-label text-center">       
                        <button type="button" tabindex="7" class="btn btn-danger cancle-button" (click)="RefreshPage()" >Cancel</button></div>
                    </div>
            </div>
        </div>
    </form>
</fieldset>
</div>
</div>