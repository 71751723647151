import { AngularFontAwesomeModule } from 'angular-font-awesome';
import { PerfectScrollbarModule,PERFECT_SCROLLBAR_CONFIG,PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
// import { AngularDateTimePickerModule } from 'angular2-datetimepicker';
// begin imports modules
//Reports
import { AttendenceReportMasterComponent } from './HR_Admin/Reports/Attendence_Reports/attendence-report-master.component';
import { MonthelyAttendenceReportsComponent } from './HR_Admin/Reports/Attendence_Reports/monthely-attendence-reports.component';
import { DdTourLeaveReportsComponent } from './HR_Admin/Reports/Attendence_Reports/dd-tour-leave-reports.component';
import { EmployeeAttendanceForRhfhComponent } from './HR_Admin/Reports/Attendence_Reports/employee-attendance-for-rhfh.component';
//Reports 
import { BrowserModule } from '@angular/platform-browser';
import { CommonModule } from '@angular/common';
import { Ng2CarouselamosModule } from 'ng2-carouselamos';
import { NgxMyDatePickerModule } from 'ngx-mydatepicker';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { DataTablesModule } from 'angular-datatables';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { Http, HttpModule } from '@angular/http';
import { JasperoConfirmationsModule } from '@jaspero/ng2-confirmations';
import { AmazingTimePickerModule } from 'amazing-time-picker';
import { AngularMultiSelectModule } from 'angular2-multiselect-dropdown';
import { Ng4LoadingSpinnerModule } from 'ng4-loading-spinner';
import { DateValueAccessorModule } from 'angular-date-value-accessor';
import { AgGridModule } from '@ag-grid-community/angular';
//bootstrap Modules
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { ModalModule } from 'ngx-bootstrap/modal';
//end bootstraps modules
//End import modules 
import { AppComponent } from './app.component';
//Begin HR services
import { UserService } from './shared/user.service';
import { OrganizationService } from './shared/Organization/organization.service';
import { LocationService } from './shared/HR_ADMIN/Location/location.service';
import { LevelService } from './shared/HR_ADMIN/Lavels_Grades/Levels.service';
import { AttendanceService } from './shared/HR_ADMIN/Attendance/AttendanceService.service';
import { SalaryMasterService } from './shared/HR_ADMIN/Salary/SalaryMaster.service';
import { EmployeeMasterService } from './shared/HR_ADMIN/Employee/EmployeeMaster.Service';
import { LeaveMasterService } from './shared/HR_ADMIN/Leave/LeaveMaster.service';
import { AssetsMasterService } from './shared/HR_ADMIN/Assets/AssetsMaster.service';
import { UserMasterService } from './shared/HR_ADMIN/UserMaster/UserMasterService.service';
import { HolidaysMasterService } from './shared/HR_ADMIN/Holidays/HolidaysServices.service';
import { EmployeePortalService } from './shared/HR_ADMIN/EmployeePortal/EmployeePortalService.service';
import { AdminMasterService } from './shared/HR_ADMIN/Admin/AdminMasterService.service';
import { ApproveLeaveService } from './shared/HR_ADMIN/Approve/ApproveLeave.service';
import { KRAService } from './shared/HR_ADMIN/KRA/KRAService.service';
import { CommonMenuHandelling } from './shared/Menu/CommonMenuHandelling';
//End HR Services
import { NotificationsService } from './shared/notifications.service';
//End HR Services
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ToastrModule } from 'ngx-toastr';
import { UserComponent } from './user/user.component';
import { SignInComponent } from './user/sign-in/sign-in.component';
import { DashBoardComponent } from './DashBoard/DashBoard.component';
// import { SignUpComponent } from './user/sign-up/sign-up.component';
import { appRoutes } from './routes';
import { AuthGuard } from './auth/auth.guard';
import { AuthInterceptor } from './auth/auth.interceptor';
import { MainMenuComponent } from './DashBoard/main-menu.component';
//Dialog components
//Begin of Manage Organization
import { OrganizationComponent } from './ManageOrganization/organization.component';
import { ManageBranchComponent } from './ManageOrganization/manage-branch.component';
import { ManageCompanyComponent } from './ManageOrganization/manage-company.component';
import { ManageDepartmentsComponent } from './ManageOrganization/manage-departments.component';
import { ManageVerticalComponent } from './ManageOrganization/manage-Vertical.component';
import { RegionForHrComponent } from './ManageOrganization/region-for-hr.component';
//end of Manage Organization
//Begning of manage location
import { DialogComponent } from './dialog/dialog.component';
import { LocationComponent } from './ManageLocation/location.component';
import { ManageCountryComponent } from './ManageLocation/manage-country.component';
import { ManageZoneComponent } from './ManageLocation/manage-zone.component';
import { ManageStateComponent } from './ManageLocation/manage-state.component';
import { ManageDistrictComponent } from './ManageLocation/manage-district.component';
import { ManageCityComponent } from './ManageLocation/manage-city.component';
//end of Manage location
//Begning of manage Grade
import { ManageDesignationComponent } from './HR_Admin/ManageLavel_Grades/manage-designation.component';
import { ManageDecisionMakingLevelsComponent } from './HR_Admin/ManageLavel_Grades/manage-decision-making-levels.component';
import { ManageLevelsComponent } from './HR_Admin/ManageLavel_Grades/manage-levels.component';
import { ManageGradesComponent } from './HR_Admin/ManageLavel_Grades/manage-grades.component';
import { LavelsComponent } from './HR_Admin/ManageLavel_Grades/lavels.component';
//End of manage Grade
//Begning of attendence system
import { ManageAttendenceFineRulesComponent } from './HR_Admin/Manage_Attendence_Master/manage-attendence-fine-rules.component';
import { ManageWorkingHoursComponent } from './HR_Admin/Manage_Attendence_Master/manage-working-hours.component';
import { ManageWeeklyWorkingGroupComponent } from './HR_Admin/Manage_Attendence_Master/manage-working-group.component';
import { ManageMapFineRuleComponent } from './HR_Admin/Manage_Attendence_Master/Manage-Map-Fine-Rule.component';
import { ManageEmployeeWithWeeklyWorkingGroupComponent } from './HR_Admin/Manage_Attendence_Master/manage-Map-working-group.component';
import { AtteendenceSystemComponent } from './HR_Admin/Manage_Attendence_Master/atteendence-system.component';
import { ManageFineTypeComponent } from './HR_Admin/Manage_Attendence_Master/manage-fine-type.component';
import { ManageFineNameComponent } from './HR_Admin/Manage_Attendence_Master/manage-fine-name.component';
//End of attendence system
//Begning of Salary
import { ManageSalaryComponentComponent } from './HR_Admin/Manage_Salary_Component/manage-salary-component.component';
import { ManageSalaryComponentRulesComponent } from './HR_Admin/Manage_Salary_Component/manage-salary-component-rules.component';
import { MapSalaryComponentsWithGradesComponent } from './HR_Admin/Manage_Salary_Component/map-salary-components-with-grades.component';
import { SalaryComponentsComponent } from './HR_Admin/Manage_Salary_Component/salary-components.component';
import { ManageAttendanceModificationHrComponent } from './HR_Admin/Manage_Salary_Component/manage-attendance-modification-hr.component';
import { ManageAttendanceModificationDatewiseComponent } from './HR_Admin/Manage_Salary_Component/manage-attendance-modification-datewise.component';
import { MonthlyAttendanceFinalDetailsComponent } from './HR_Admin/Manage_Salary_Component/monthly-attendance-final-details.component';
import { GenerateSalaryComponent } from './HR_Admin/Manage_Salary_Component/generate-salary.component';
import { GeneratePayslipComponent } from './HR_Admin/Manage_Salary_Component/generate-payslip.component';
import { GeneratePayslipNewComponent } from './HR_Admin/Manage_Salary_Component/generate-payslip-new.component';
import { PfreportComponent } from './HR_Admin/Manage_Salary_Component/pfreport.component';
//End of Salary
//Begning of employee 
import { EmployeesMasterComponent } from './HR_Admin/Manage_Employees/employees-master.component';
import { EmployeeOfferComponent } from './HR_Admin/Manage_Employees/employee-offer.component';
import { EmployeeJoiningComponent } from './HR_Admin/Manage_Employees/employee-joining.component';
import { EmployeeBankDetailsComponent } from './HR_Admin/Manage_Employees/employee-bank-details.component';
import { EmployeeCtcComponent } from './HR_Admin/Manage_Employees/employee-ctc.component';
import { AllEmployeeAttendanceDetailsComponent } from './HR_Admin/Manage_Employees/all-employee-attendance-details.component';
import { EmployeeDetailsComponent } from './HR_Admin/Manage_Employees/EmployeeDetails/employee-details.component';
import { EmployeePersonalDetailsComponent } from './HR_Admin/Manage_Employees/EmployeeDetails/employee-personal-details.component';
import { EmployeeAddressDetailsComponent } from './HR_Admin/Manage_Employees/EmployeeDetails/employee-address-details.component';
import { EmployeeOtherDetailsComponent } from './HR_Admin/Manage_Employees/EmployeeDetails/employee-other-details.component';
import { EmployeelQualificationComponent } from './HR_Admin/Manage_Employees/EmployeeDetails/employeel-qualification.component';
import { EmployeeExprienceComponent } from './HR_Admin/Manage_Employees/EmployeeDetails/employee-exprience.component';
import { EmployeeFamilyDetailsComponent } from './HR_Admin/Manage_Employees/EmployeeDetails/employee-family-details.component';
import { EmployeeyNomineeDetailsComponent } from './HR_Admin/Manage_Employees/EmployeeDetails/employeey-nominee-details.component';
import { AllEmployeeLeaveApplyComponent } from './HR_Admin/Manage_Employees/all-employee-leave-apply.component';
import { AllEmployeeRegularisationApplyComponent } from './HR_Admin/Manage_Employees/all-employee-regularisation-apply.component';
import { AllEmployeeAttendanceRegularisationStatusComponent } from './HR_Admin/Manage_Employees/all-employee-attendance-regularisation-status.component';
import { AllEmployeeLeaveStatusComponent } from './HR_Admin/Manage_Employees/all-employee-leave-status.component';
import { AllEmployeeDirectDutyApplicationComponent } from './HR_Admin/Manage_Employees/all-employee-direct-duty-application.component';
import { AllEmployeeDirectDutyStatusComponent } from './HR_Admin/Manage_Employees/all-employee-direct-duty-status.component';
import { AllEmployeeTourApplicationComponent } from './HR_Admin/Manage_Employees/all-employee-tour-application.component';
import { AllEmployeeTourStatusComponent } from './HR_Admin/Manage_Employees/all-employee-tour-status.component';
import { AllEmployeeCompoffApplicationComponent } from './HR_Admin/Manage_Employees/all-employee-compoff-application.component';
import { AllEmployeeCompoffStatusComponent } from './HR_Admin/Manage_Employees/all-employee-compoff-status.component';
import { EmployeeLeaveBalanceAdjustmentDetailsComponent } from './HR_Admin/Manage_Employees/employee-leave-balance-adjustment-details.component';
import { AllEmployeeTourReimbursementApplicationComponent } from './HR_Admin/Manage_Employees/all-employee-tour-reimbursement-application.component';
import { AllEmployeeTourReimbursementStatusComponent } from './HR_Admin/Manage_Employees/all-employee-tour-reimbursement-status.component';
//end of employee System
//Begin of employee portal
import { EmployeePersonalInfoComponent } from './HR_Admin/EmployeePortal/employee-personal-info.component';
import { EmployeeAttendanceInfoComponent } from './HR_Admin/EmployeePortal/employee-attendance-info.component';
import { EmployeeLeaveBalanceComponent } from './HR_Admin/EmployeePortal/employee-leave-balance.component';
import { EmployeeLeaveApplicationComponent } from './HR_Admin/EmployeePortal/employee-leave-application.component';
import { EmployeeAttendanceRegularisationComponent } from './HR_Admin/EmployeePortal/employee-attendance-regularisation.component';
import { EmployeePortalMasterComponent } from './HR_Admin/EmployeePortal/employee-portal-master.component';
import { EmployeeCtcviewComponent } from './HR_Admin/EmployeePortal/employee-ctcview.component';
import { AttendanceRegularaisationStatusComponent } from './HR_Admin/EmployeePortal/attendance-regularaisation-status.component';
import { EmployeeLeaveStatusComponent } from './HR_Admin/EmployeePortal/employee-leave-status.component';
import { EmployeeDirectDutyComponent } from './HR_Admin/EmployeePortal/employee-direct-duty.component';
import { EmployeeDirectDutyStatusComponent } from './HR_Admin/EmployeePortal/employee-direct-duty-status.component';
import { EmployeeTourApplicationComponent } from './HR_Admin/EmployeePortal/employee-tour-application.component';
import { EmployeeTourStatusComponent } from './HR_Admin/EmployeePortal/employee-tour-status.component';
import { EmployeeCompoffApplicationComponent } from './HR_Admin/EmployeePortal/employee-compoff-application.component';
import { EmployeeCompoffStatusComponent } from './HR_Admin/EmployeePortal/employee-compoff-status.component';
import { EmployeePayslipComponent } from './HR_Admin/EmployeePortal/employee-payslip.component';
import { LeaveBalanceAdjustmentDetailsComponent } from './HR_Admin/EmployeePortal/leave-balance-adjustment-details.component';
import { ManageEmployeeDetailsModificationComponent } from './HR_Admin/EmployeePortal/manage-employee-details-modification.component';
import { TourReimbursementApplicationComponent } from './HR_Admin/EmployeePortal/tour-reimbursement-application.component';
import { TourReimbursementStatusComponent } from './HR_Admin/EmployeePortal/tour-reimbursement-status.component';
import { DeclarationForm12BbComponent } from './HR_Admin/Manage_Salary_Component/declaration-form12-bb.component';
import { EmployeePayslipNewComponent } from './HR_Admin/EmployeePortal/employee-payslip-new.component';
import { ExitApplicationComponent } from './HR_Admin/EmployeePortal/exit-application.component';
//end of employee Portal
//Begin of Leave Master
import { LeavemanagementComponent } from './HR_Admin/Manage_Leave/leavemanagement.component';
import { LeaveTypeComponent } from './HR_Admin/Manage_Leave/leave-type.component';
import { LeaveMappingComponent } from './HR_Admin/Manage_Leave/leave-mapping.component';
import { LeaveRuleComponent } from './HR_Admin/Manage_Leave/leave-rule.component';
import { LeaveBalanceComponent } from './HR_Admin/Manage_Leave/leave-balance.component';
import { LeaveMasterComponent } from './HR_Admin/Manage_Leave/leave-master.component';
import { ManageLeaveBalanceAdjustmentComponent } from './HR_Admin/Manage_Leave/manage-leave-balance-adjustment.component';
//end of employee System
//Begin of Assets Master
import { AssetsMasterComponent } from './HR_Admin/ManageAssets/assets-master.component';
import { AssetsTypeComponent } from './HR_Admin/ManageAssets/assets-type.component';
import { EmployeeAssetDetailsComponent } from './HR_Admin/ManageAssets/employee-asset-details.component';
//end of Assets Master
//Begin of User Master
import { ManageUserMasterComponent } from './HR_Admin/ManageUser/manage-user-master.component';
import { ModuleMasterComponent } from './HR_Admin/ManageUser/module-master.component';
import { MenuMasterComponent } from './HR_Admin/ManageUser/menu-master.component';
import { RoleMasterComponent } from './HR_Admin/ManageUser/role-master.component';
import { UserMasterComponent } from './HR_Admin/ManageUser/user-master.component';
import { MainMenuUserMappingComponent } from './HR_Admin/ManageUser/main-menu-user-mapping.component';
import { UserMappingComponent } from './HR_Admin/ManageUser/user-mapping.component';
import { ManageChangePasswordComponent } from './HR_Admin/ManageUser/manage-change-password.component';
//end of User Master
//Begin of Holidays Master
import { ManageHolidaysMasterComponent } from './HR_Admin/Manage_Holidays/manage-holidays-master.component';
import { ManageHolidaysComponent } from './HR_Admin/Manage_Holidays/manage-holidays.component';
import { ManageCalendarComponent } from './HR_Admin/Manage_Holidays/manage-calendar.component';
import { ManageHolidaysCalendarComponent } from './HR_Admin/Manage_Holidays/manage-holidays-calendar.component';
//end of Holidays Master
//Begin of Admin Master
import { AdminMasterComponent } from './HR_Admin/ManageAdmin/admin-master.component';
import { ManageFinancialYearComponent } from './ManageOrganization/manage-financial-year.component';
import { BankMasterComponent } from './ManageOrganization/bank-master.component';
import { ManageEpfesislabComponent } from './HR_Admin/ManageAdmin/manage-epfesislab.component';
import { ManagePtaxSlabComponent } from './HR_Admin/ManageAdmin/manage-ptax-slab.component';
import { ManageTdsslabComponent } from './HR_Admin/ManageAdmin/manage-tdsslab.component';
import { ManageTdssurchargeSlabComponent } from './HR_Admin/ManageAdmin/manage-tdssurcharge-slab.component';
import { ManageExpenseHeadComponent } from './HR_Admin/ManageAdmin/manage-expense-head.component';
import { ManageExpenseHeadMappingComponent } from './HR_Admin/ManageAdmin/manage-expense-head-mapping.component';
import { ManageEmployeeTourBudgetComponent } from './HR_Admin/ManageAdmin/manage-employee-tour-budget.component';
import { ManageEventMessageComponent } from './HR_Admin/ManageAdmin/manage-event-message.component';
import { HrpoliciesFormComponent } from './HR_Admin/ManageAdmin/hrpolicies-form.component';
//end of Admin Master
//Begning Of ApprovePortal
import { ApprovlMasterMasterComponent } from './HR_Admin/ApproveReject/approvl-master-master.component';
import { LeaveApprovalComponent } from './HR_Admin/ApproveReject/leave-approval.component';
import { AttendanceRegularizationApprovalComponent } from './HR_Admin/ApproveReject/attendance-regularization-approval.component';
import { DirecetDutyApprovalComponent } from './HR_Admin/ApproveReject/direcet-duty-approval.component';
import { TourApprovalComponent } from './HR_Admin/ApproveReject/tour-approval.component';
import { KrareportingHeadApprovalComponent } from './HR_Admin/ApproveReject/krareporting-head-approval.component';
import { KrafunctionalHeadApprovalComponent } from './HR_Admin/ApproveReject/krafunctional-head-approval.component';
import { EmployeeConfirmationApprovalComponent } from './HR_Admin/ApproveReject/employee-confirmation-approval.component';
import { CompoffApprovalComponent } from './HR_Admin/ApproveReject/compoff-approval.component';
import { EmployeePersonalInfoApplicationComponent } from './HR_Admin/ApproveReject/employee-personal-info-application.component';
import { TourReimbursementApprovalForRhComponent } from './HR_Admin/ApproveReject/tour-reimbursement-approval-for-rh.component';
import { TourReimbursementApprovalForHrComponent } from './HR_Admin/ApproveReject/tour-reimbursement-approval-for-hr.component';
import { TourReimbursementApprovalforFinanceComponent } from './HR_Admin/ApproveReject/tour-reimbursement-approvalfor-finance.component';
import { TourReimbursementApprovalFinalForHrComponent } from './HR_Admin/ApproveReject/tour-reimbursement-approval-final-for-hr.component';
import { TourReimbursementApprovalFinalForFinanceComponent } from './HR_Admin/ApproveReject/tour-reimbursement-approval-final-for-finance.component';
import { Form12BbapprovalComponent } from './HR_Admin/ApproveReject/form12-bbapproval.component';
//End Of ApprovePortal
//Begning Of KRA
import { KramasterComponent } from './HR_Admin/ManageKRA/kramaster.component';
import { ManageKratemplateComponent } from './HR_Admin/ManageKRA/manage-kratemplate.component';
import { ManageKramasterComponent } from './HR_Admin/ManageKRA/manage-kramaster.component';
import { ManageKramappingComponent } from './HR_Admin/ManageKRA/manage-kramapping.component';
import { KramappingWithEmployeeComponent } from './HR_Admin/ManageKRA/kramapping-with-employee.component';
import { ManageKrascheduleComponent } from './HR_Admin/ManageKRA/manage-kraschedule.component';
import { ManageKrauserApplicationComponent } from './HR_Admin/ManageKRA/manage-krauser-application.component';
import { ManageKraallEmployeeApplicationComponent } from './HR_Admin/ManageKRA/manage-kraall-employee-application.component';
import { KrareportsComponent } from './HR_Admin/ManageKRA/krareports.component';
import { KrareportsForAllComponent } from './HR_Admin/ManageKRA/krareports-for-all.component';
import { KrareportsForHeadsComponent } from './HR_Admin/ManageKRA/krareports-for-heads.component';
//End Of KRA
//Begin Admin Approval
import { AdminApprovalMasterComponent } from './HR_Admin/AdminApproval/admin-approval-master.component';
import { AdminLeaveApprovalComponent } from './HR_Admin/AdminApproval/admin-leave-approval.component';
import { DirectDutyApprovalComponent } from './HR_Admin/AdminApproval/direct-duty-approval.component';
import { AdminTourApprovalComponent } from './HR_Admin/AdminApproval/admin-tour-approval.component';
import { RegularizationApprovalComponent } from './HR_Admin/AdminApproval/regularization-approval.component';
import { CompOffApprovalComponent } from './HR_Admin/AdminApproval/comp-off-approval.component';
import { KrafunctionalApprovalComponent } from './HR_Admin/AdminApproval/krafunctional-approval.component';
import { KrareportingApprovalComponent } from './HR_Admin/AdminApproval/krareporting-approval.component';
import { ConfirmationApprovalComponent } from './HR_Admin/AdminApproval/confirmation-approval.component';
import { AllEmployeePerosnalInfoApplicationComponent } from './HR_Admin/AdminApproval/all-employee-perosnal-info-application.component';
import { TourReimbursementApprovalForHrallEmployeeComponent } from './HR_Admin/AdminApproval/tour-reimbursement-approval-for-hrall-employee.component';
import { TourReimbursementApprovalForRhallEmployeeComponent } from './HR_Admin/AdminApproval/tour-reimbursement-approval-for-rhall-employee.component';
//End Admin Approval
//Begin BirthDay Anniversary
import { BirthdayAnniversaryMasterComponent } from './HR_Admin/ManageBirthDay_Anniversary/birthday-anniversary-master.component';
import { ManageBirthdayComponent } from './HR_Admin/ManageBirthDay_Anniversary/manage-birthday.component';
import { ManageMarriageAnniversaryComponent } from './HR_Admin/ManageBirthDay_Anniversary/manage-marriage-anniversary.component';
import { ManageWorkAnniversaryComponent } from './HR_Admin/ManageBirthDay_Anniversary/manage-work-anniversary.component';
//End BirthDay Anniversary
//Begin Resign Employee Details
import { ResignEmployeeDetailsComponent } from './HR_Admin/ManageResignEmployee/resign-employee-details.component';
import { ResignEmployeeLeaveBalanceComponent } from './HR_Admin/ManageResignEmployee/resign-employee-leave-balance.component';
import { ResignEmployeePersonalDetailsComponent } from './HR_Admin/ManageResignEmployee/resign-employee-personal-details.component';
import { ResignEmployeeAttendanceDetailsComponent } from './HR_Admin/ManageResignEmployee/resign-employee-attendance-details.component';
import { ResignEmployeePayslipDetailsComponent } from './HR_Admin/ManageResignEmployee/resign-employee-payslip-details.component';
//End Resign Employee Details
//Fileupload
//import { FileUploadComponent } from './HR_Admin/UploadFile/file-upload.component';
//FileUpload
//Begning CRM
import { B2BComponent } from './CRM/AddCRM/b2-b.component';
import { CrmMasterComponent } from './CRM/AddCRM/crm-master.component';
import { B2bService } from './shared/CRM/B2B/b2b.service';
import { B2CComponent } from './CRM/AddCRM/b2-c.component';
import { B2cService } from './shared/CRM/B2B/b2c.service';
import { SubModuleComponent } from './DashBoard/sub-module.component';
import { MiceCrmMasterComponent } from './CRM/MICECRM/mice-crm-master.component';
// CRM Component
import { ManageRollComponent } from './CRM/MICECRM/ManageAccess/manage-role.component';
import { AddCrmCountryComponent } from './CRM/MICECRM/ManageAccess/crmcountry.component';
import { CrmZoneComponent } from './CRM/MICECRM/ManageAccess/crm-zone.component';
import { CrmStateComponent } from './CRM/MICECRM/ManageAccess/crm-state.component';
import { CrmCityComponent } from './CRM/MICECRM/ManageAccess/crm-city.component';
import { CrmBranchComponent } from './CRM/MICECRM/ManageAccess/crm-branch.component';
import { CrmUserRollComponent } from './CRM/MICECRM/ManageAccess/crm-user-roll.component';
import { CrmParentMenuRollComponent } from './CRM/MICECRM/ManageAccess/crm-Parentmenu-roll.component';
import { CrmChildmenuRollComponent } from './CRM/MICECRM/ManageAccess/crm-childmenu-roll.component';
import { CrmQuickLeadComponent } from './CRM/MICECRM/ManageLead/crm-quick-lead.component';
import { MiceCrmLeadMasterComponent } from './CRM/MICECRM/ManageLead/mice-crm-lead-master.component';
import { TestComponent } from './CRM/MICECRM/ManageAccess/test.component';
//CRM Component
// Begning of the CRM Lead component
import { CrmManageLeadComponent } from './CRM/MICECRM/ManageLead/crm-manage-lead.component';
// end of the Crm lead component
//  CRM Services
import { RoleService } from './shared/CRM/MICECRM/Role_service';
import { CrmCountryService } from './shared/CRM/MICECRM/CrmCountryservice';
import { CrmZoneService } from './shared/CRM/MICECRM/CrmZoneService';
import { CrmStateService } from './shared/CRM/MICECRM/CrmStateService';
import { CrmUserRollService } from './shared/CRM/MICECRM/crm-user-roll.service';
import { CrmRollMenuService } from './shared/CRM/MICECRM/crm-roll-menu.service';
import { CrmCityService } from './shared/CRM/MICECRM/CrmCityService';
import { CrmBranchService } from './shared/CRM/MICECRM/CrmBranchService';
import { CrmLeadService } from './shared/CRM/MICECRM/LEADCRM/CrmLeadService';
import { MiceCrmBasicLeadInfoComponent } from './CRM/MICECRM/ManageLead/mice-crm-basic-lead-info.component';
import { MicCrmMoreInfoComponent } from './CRM/MICECRM/ManageLead/mic-crm-more-info.component';
import { MiceCrmContactsComponent } from './CRM/MICECRM/ManageLead/mice-crm-contacts.component';
import { CrmOppertunityService } from './shared/CRM/MICECRM/Oppertunity/CrmOppertunityService';
import { SalesCallService } from './shared/CRM/MICECRM/SalceCall/SalesCallService'
//CRM Services
import { NgxPaginationModule } from "ngx-pagination";
import { LeadAccountInfoComponent } from './CRM/MICECRM/ManageLead/lead-account-info.component';
import { CrmOppertunityComponent } from './CRM/MICECRM/ManageOportunity/crm-oppertunity.component';
import { CrmOportunityDetailsComponent } from './CRM/MICECRM/ManageOportunity/crm-oportunity-details.component';
import { CrmOpportunityMoreInfoComponent } from './CRM/MICECRM/ManageOportunity/crm-opportunity-more-info.component';
//End CRM
import { NumberOnlyDirective } from './shared/CRM/MICECRM/Directives/NumberOnlyDirective';
import { CrmManageNotesComponent } from './CRM/MICECRM/ManageOportunity/crm-manage-notes.component';
import { NumberDirective } from '../app/for-all/NumberDirective';
//import { EmailValidatorDirective } from '../app/for-all/email-validator.directive';
// For all module
import { ForAllModule } from '../app/for-all/for-all.module';
//Forall Module
import { ConfirmationDialogComponent } from './shared/CRM/MICECRM/confirmation-dialog/confirmation-dialog.component';
import { ConfirmationDialogService } from './shared/CRM/MICECRM/confirmation-dialog/confirmation-dialog.service';
import { ScheduleCallComponent } from './CRM/MICECRM/ManageLead/schedule-call/schedule-call.component';
import { ViewScheduleCallComponent } from './CRM/MICECRM/ManageLead/schedule-call/view-schedule-call.component';
import { SalesComponentComponent } from './CRM/SalesCall/sales-component.component';
import { SalarySheetComponent } from './HR_Admin/Reports/salary-sheet/salary-sheet.component';
import { SalarySheetDetailComponent } from './HR_Admin/Reports/salary-sheet/salary-sheet-detail.component';
import { SaveButtonComponent } from './CRM/SalesCall/save-button.component';
import { FollouUpButtonComponent } from './CRM/SalesCall/follou-up-button.component';
import { FollowUpModelPopupComponent } from './CRM/SalesCall/follow-up-model-popup.component';
//confirmation service
import { FullCalendarModule } from 'ng-fullcalendar';
//  CRM Services
import { OpportunityReportComponent } from './CRM/MICECRM/ManageOportunity/opportunity-report.component';
//CRM Services
import { ForexCrmComponent } from './CRM/ForexCRM/forex-crm.component';
import { ForexCrmMasterComponent } from './CRM/ForexCRM/forex-crm-master.component';
//End CRM
//Forall Module
import { MiceCrmleadReoprtComponent } from './CRM/MICECRM/ManageLead/mice-crmlead-reoprt.component';
import { ForexCrmService } from './shared/CRM/FOREX CRM/ForexCrmService';
//confirmation service
import { DatePipe } from '@angular/common';
import { MailToClientComponent } from './CRM/MICECRM/ManageLead/mail-to-client/mail-to-client.component';

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true
};
@NgModule({
  declarations: [

    OpportunityReportComponent,ForexCrmComponent,ForexCrmMasterComponent,MiceCrmleadReoprtComponent,
    AppComponent,
    SignInComponent,
    DashBoardComponent,
    UserComponent,
    MainMenuComponent,
    OrganizationComponent,
    ManageCompanyComponent,
    ManageDepartmentsComponent,
    ManageBranchComponent,
    DialogComponent,
    LocationComponent,
    ManageCountryComponent,
    ManageZoneComponent,
    ManageStateComponent,
    ManageCityComponent,
    ManageVerticalComponent,
    ManageDesignationComponent,
    ManageDecisionMakingLevelsComponent,
    ManageLevelsComponent,
    ManageGradesComponent,
    LavelsComponent,
    ManageAttendenceFineRulesComponent,
    ManageWorkingHoursComponent,
    ManageWeeklyWorkingGroupComponent,
    ManageMapFineRuleComponent,
    ManageEmployeeWithWeeklyWorkingGroupComponent,
    ManageFineTypeComponent,
    ManageFineNameComponent,
    AtteendenceSystemComponent,
    ManageSalaryComponentComponent,
    ManageSalaryComponentRulesComponent,
    MapSalaryComponentsWithGradesComponent,
    SalaryComponentsComponent,
    EmployeesMasterComponent,
    EmployeeOfferComponent,
    EmployeeJoiningComponent,
    EmployeeBankDetailsComponent,
    EmployeeCtcComponent,
    EmployeeDetailsComponent,
    EmployeePersonalDetailsComponent,
    EmployeeAddressDetailsComponent,
    EmployeeOtherDetailsComponent,
    EmployeelQualificationComponent,
    EmployeeExprienceComponent,
    EmployeeFamilyDetailsComponent,
    EmployeeyNomineeDetailsComponent,
    BankMasterComponent,
    EmployeePersonalInfoComponent,
    EmployeeAttendanceInfoComponent,
    EmployeeLeaveBalanceComponent,
    EmployeeLeaveApplicationComponent,
    EmployeeAttendanceRegularisationComponent,
    EmployeePortalMasterComponent,
    LeavemanagementComponent,
    LeaveTypeComponent,
    LeaveMappingComponent,
    ManageFinancialYearComponent,
    LeaveRuleComponent,
    LeaveBalanceComponent,
    LeaveMasterComponent,
    ManageLeaveBalanceAdjustmentComponent,
    AssetsMasterComponent,
    AssetsTypeComponent,
    EmployeeAssetDetailsComponent,
    ManageUserMasterComponent,
    ModuleMasterComponent,
    MenuMasterComponent,
    RoleMasterComponent,
    UserMasterComponent,
    UserMappingComponent,
    ManageDistrictComponent,
    ManageHolidaysMasterComponent,
    ManageHolidaysComponent,
    ManageCalendarComponent,
    ManageEpfesislabComponent,
    ManagePtaxSlabComponent,
    ManageTdsslabComponent,
    ManageTdssurchargeSlabComponent,
    ManageExpenseHeadComponent,
    ManageExpenseHeadMappingComponent,
    ManageEmployeeTourBudgetComponent,
    AdminMasterComponent,
    EmployeeCtcviewComponent,
    ApprovlMasterMasterComponent,
    LeaveApprovalComponent,
    AllEmployeeAttendanceDetailsComponent,
    AttendanceRegularizationApprovalComponent,
    AllEmployeeLeaveApplyComponent,
    AllEmployeeRegularisationApplyComponent,
    MainMenuUserMappingComponent,
    AttendanceRegularaisationStatusComponent,
    EmployeeLeaveStatusComponent,
    AllEmployeeLeaveStatusComponent,
    AllEmployeeAttendanceRegularisationStatusComponent,
    EmployeeDirectDutyComponent,
    DirecetDutyApprovalComponent,
    TourApprovalComponent,
    AllEmployeeDirectDutyApplicationComponent,
    AllEmployeeDirectDutyStatusComponent,
    AllEmployeeTourApplicationComponent,
    AllEmployeeTourStatusComponent,
    EmployeeDirectDutyStatusComponent,
    EmployeeTourApplicationComponent,
    EmployeeTourStatusComponent,
    ManageKramasterComponent,
    KramasterComponent,
    ManageKratemplateComponent,
    ManageKramappingComponent,
    ManageKrascheduleComponent,
    ManageKrauserApplicationComponent,
    ManageKraallEmployeeApplicationComponent,
    KrareportsComponent,
    KrareportsForAllComponent,
    KrareportsForHeadsComponent,
    KramappingWithEmployeeComponent,
    KrareportingHeadApprovalComponent,
    KrafunctionalHeadApprovalComponent,
    AttendenceReportMasterComponent,
    MonthelyAttendenceReportsComponent,
    B2BComponent,
    CrmMasterComponent,
    ManageChangePasswordComponent,
    EmployeeConfirmationApprovalComponent,
    B2CComponent,
    MonthlyAttendanceFinalDetailsComponent,
    GenerateSalaryComponent,
    AllEmployeeCompoffApplicationComponent,
    AllEmployeeCompoffStatusComponent,
    EmployeeLeaveBalanceAdjustmentDetailsComponent,
    AllEmployeeTourReimbursementApplicationComponent,
    AllEmployeeTourReimbursementStatusComponent,
    EmployeeCompoffApplicationComponent,
    EmployeeCompoffStatusComponent,
    CompoffApprovalComponent,
    EmployeePersonalInfoApplicationComponent,
    TourReimbursementApprovalForRhComponent,
    TourReimbursementApprovalForHrComponent,
    TourReimbursementApprovalforFinanceComponent,
    TourReimbursementApprovalFinalForHrComponent,
    TourReimbursementApprovalFinalForFinanceComponent,
    GeneratePayslipComponent,
    EmployeePayslipComponent,
    LeaveBalanceAdjustmentDetailsComponent,
    ManageEmployeeDetailsModificationComponent,
    TourReimbursementApplicationComponent,
    TourReimbursementStatusComponent,
    EmployeeAttendanceForRhfhComponent,
    DdTourLeaveReportsComponent,
    ManageHolidaysCalendarComponent,
    AdminApprovalMasterComponent,
    DirectDutyApprovalComponent,
    RegularizationApprovalComponent,
    CompOffApprovalComponent,
    KrafunctionalApprovalComponent,
    KrareportingApprovalComponent,
    ConfirmationApprovalComponent,
    AllEmployeePerosnalInfoApplicationComponent,
    TourReimbursementApprovalForHrallEmployeeComponent,
    TourReimbursementApprovalForRhallEmployeeComponent,
    ManageAttendanceModificationHrComponent,
    ManageAttendanceModificationDatewiseComponent,
    AdminLeaveApprovalComponent,
    AdminTourApprovalComponent,
    BirthdayAnniversaryMasterComponent,
    ManageBirthdayComponent,
    ManageMarriageAnniversaryComponent,
    ManageWorkAnniversaryComponent,
    PfreportComponent,
    DeclarationForm12BbComponent,
    EmployeePayslipNewComponent,
    RegionForHrComponent,
    SubModuleComponent,
    MiceCrmMasterComponent,
    ManageRollComponent,
    // ManageUserComponent,
    // ManageMenuComponent,
    AddCrmCountryComponent,
    CrmZoneComponent,
    CrmStateComponent,
    CrmCityComponent,
    CrmBranchComponent,
    CrmUserRollComponent,
    CrmParentMenuRollComponent,
    CrmChildmenuRollComponent,
    CrmQuickLeadComponent,
    MiceCrmLeadMasterComponent,
    TestComponent,
    CrmManageLeadComponent,
    MiceCrmBasicLeadInfoComponent,
    MicCrmMoreInfoComponent,
    MiceCrmContactsComponent,
    LeadAccountInfoComponent,
    CrmOppertunityComponent,
    CrmOportunityDetailsComponent,
    CrmOpportunityMoreInfoComponent,
 
    NumberOnlyDirective,
    NumberDirective,
    CrmManageNotesComponent,
    //EmailValidatorDirective
    // directives end 
    ConfirmationDialogComponent,
    ScheduleCallComponent,
    ViewScheduleCallComponent,
    ResignEmployeeDetailsComponent,
    ResignEmployeeLeaveBalanceComponent,
    ResignEmployeePersonalDetailsComponent,
    ResignEmployeeAttendanceDetailsComponent,
    ResignEmployeePayslipDetailsComponent,
    SalesComponentComponent,
    GeneratePayslipNewComponent,
    SalarySheetComponent,
    SalarySheetDetailComponent,
    SaveButtonComponent,
    FollouUpButtonComponent,
    FollowUpModelPopupComponent,
    ManageEventMessageComponent,
    HrpoliciesFormComponent,
    Form12BbapprovalComponent,
    ExitApplicationComponent,
    MailToClientComponent

  ],
  imports: [
    FullCalendarModule,
    ForAllModule,
    NgxPaginationModule,
    PerfectScrollbarModule,
    Ng2CarouselamosModule,
    BrowserModule,
    CommonModule,
    JasperoConfirmationsModule,
    DataTablesModule,
    FormsModule,
    HttpClientModule,
    HttpModule,
    ToastrModule.forRoot(),
    BrowserAnimationsModule,
    AmazingTimePickerModule,
    AngularMultiSelectModule,
    RouterModule.forRoot(appRoutes, { enableTracing: false, useHash: true }),
    DateValueAccessorModule,
    Ng4LoadingSpinnerModule.forRoot(),
    //boot strap
    BsDropdownModule.forRoot(),
    TooltipModule.forRoot(),
    ModalModule.forRoot(),
    NgxMyDatePickerModule.forRoot(),
    AngularFontAwesomeModule,
   // NgbModule.forRoot(),
    AgGridModule.withComponents([SaveButtonComponent, FollouUpButtonComponent])
    //end bootstarp
  ],

  providers: [DatePipe,UserService, AuthGuard, OrganizationService, LocationService, LevelService, AttendanceService, SalaryMasterService, EmployeeMasterService, LeaveMasterService, AssetsMasterService, UserMasterService, CrmUserRollService, CommonMenuHandelling,
     B2bService, B2cService, CrmRollMenuService, SalesCallService, HolidaysMasterService, EmployeePortalService, AdminMasterService, ApproveLeaveService, KRAService, NotificationsService, Http,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    },
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
    },
    RoleService,
    CrmCountryService,
    CrmZoneService,
    CrmStateService,
    CrmCityService,
    CrmBranchService,
    CrmLeadService,
    CrmOppertunityService,
    ForexCrmService,
    ConfirmationDialogService
  ],
  bootstrap: [AppComponent],
  entryComponents: [ConfirmationDialogComponent, FollowUpModelPopupComponent]
})
export class AppModule { }  