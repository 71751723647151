<div class="body-content-area tabcontent" id="link1">
  <div class="row">
    <div class="col-lg-9">
      <div class="row">
        <div class="col-lg-1 low-padding text-center">
          <!-- <img *ngIf="this.SelectedMenu.Image!=null" src={{this.SelectedMenu.Image}} class="body-icon" alt="Icon"/> -->
        </div>
        <div class="col-lg-10 clear-padding">
          <div>
            <span class="page-title">Employee Attendance Regularisation Application</span>
            <small class="page-description"></small>
          </div>
        </div>
      </div>
    </div>
  </div>

  <fieldset class="body-form-control" > 
    <app-dialog [(visible)]="this.SuccessStatus">
      <span class="successmsg">{{this.SuccessMessage}}</span>
    </app-dialog>
    <legend class="legend--">Attendance Regularisation Application</legend>
    <form #EmployeeRegularisationApplicationDetails="ngForm" (ngSubmit)="SaveRegularationApply()">
      <div class="row">
        <div class="col-lg-4">
          <div class="col-body-border-right">
            <div class="form-group row">
              <label class="col-sm-4 col-form-label clear-padding-right">Emp code</label>
              <div class="col-sm-7 clear-left-padding">
                :&nbsp;&nbsp;{{this.AttendanceRegularationModel.EmployeeCode}} 
              </div>
            </div>
            <div class="form-group row">
              <label  class="col-sm-4 col-form-label clear-padding-right">Attendance Date</label>
              <div class="col-sm-7 clear-left-padding">
                :&nbsp;&nbsp;<input type="date" tabindex="1" style=" width: 90%;" name="FromDate" [ngModel]="AttendanceRegularationModel.AttendanceDate | date:'yyyy-MM-dd'" (ngModelChange)="AttendanceDateChanged($event)" #FromDate="ngModel" required/>
              </div>
              <div class="required-validation" *ngIf="(this.validdate)">Please select Valid Date.</div>
            </div>
            
            <div class="form-group row">
              <label  class="col-sm-4 col-form-label clear-padding-right">Login InTime</label>
              <div class="col-sm-7 clear-left-padding">
                :&nbsp;&nbsp;{{this.AttendanceRegularationModel.ActualInTime}}
              </div>
            </div>
            <div class="form-group row">
              <label  class="col-sm-4 col-form-label clear-padding-right">Start Time</label>
              <div class="col-sm-7 clear-left-padding">
                <input type="time" id="InTime" readonly="readonly" tabindex="2" (click)="SelectinTime()" value="{{selectedintime}}" name="InTime" [(ngModel)]="AttendanceRegularationModel.StartTime" placeholder="HH:mm:ss" />
              </div>
            </div>
            <div class="form-group row">
              <label class="col-sm-4 col-form-label clear-padding-right">Regularized Hrs.</label>
              <div class="col-sm-7 clear-left-padding">
                :&nbsp;&nbsp;{{this.AttendanceRegularationModel.RegularationDuration}} 
              </div>
            </div>
          </div> 
        </div>

        <div class="col-lg-4">
          <div class="col-body-border-right">
            <div class="form-group row">
              <label class="col-sm-4 col-form-label clear-padding-right">Employee Name</label>
              <div class="col-sm-7 clear-left-padding">
                :&nbsp;&nbsp;{{this.AttendanceRegularationModel.EmployeeName}} 
              </div>
            </div>
            <div class="form-group row">
              <label  class="col-sm-4 col-form-label clear-padding-right">Day</label>
              <div class="col-sm-7 clear-left-padding">
                :&nbsp;&nbsp;{{this.AttendanceRegularationModel.AttendanceDay}}
              </div>
            </div>
            <div class="form-group row">
              <label  class="col-sm-4 col-form-label clear-padding-right">LogOut Time</label>
              <div class="col-sm-7 clear-left-padding">
                  :&nbsp;&nbsp;{{this.AttendanceRegularationModel.ActualOutTime}}
              </div>
            </div>
            <div class="form-group row">
              <label  class="col-sm-4 col-form-label clear-padding-right">End Time</label>
              <div class="col-sm-7 clear-left-padding">
                <input type="time" readonly="readonly" tabindex="3" (click)="SelectOutTime()" value="{{selectedOuttime}}" tabindex="3" id="OutTime" name="OutTime" [(ngModel)]="AttendanceRegularationModel.EndTime" placeholder="HH:mm:ss"/> 
              </div>
            </div>
            <div class="form-group row">
              <label  class="col-sm-4 col-form-label clear-padding-right">Reason</label>
              <div class="col-sm-7 clear-left-padding">
                <input type="text" [(ngModel)]="AttendanceRegularationModel.Reason" tabindex="4" maxlength="500" id ="Reason" name="Reason" #Reason="ngModel" required class="form-control form-control-custom form-control-custom-new" >
              </div>
              <div *ngIf="Reason.invalid && (Reason.dirty || Reason.touched)" class="required-validation">
                <div *ngIf="Reason.errors.required">Reasons is required.</div>
                <div *ngIf="Reason.errors.maxlength">Reasons is not more than 500 characters</div>
              </div> 
            </div>
          </div> 
        </div>

        <div class="col-lg-4">
          <div class="form-group row">
            <label class="col-sm-4 col-form-label clear-padding-right">Approver Name</label>
            <div class="col-sm-7 clear-left-padding">
              :&nbsp;&nbsp;{{this.AttendanceRegularationModel.RHName}}
            </div>
          </div>
          <div class="form-group row">
            <label class="col-sm-4 col-form-label clear-padding-right">Status</label>
            <div class="col-sm-7 clear-left-padding">
              :&nbsp;&nbsp;{{this.AttendanceRegularationModel.AttendanceStatus}}
            </div>
          </div>
          <div class="form-group row">
            <label  class="col-sm-4 col-form-label clear-padding-right">No. Of Works Hrs</label>
            <div class="col-sm-7 clear-left-padding">
                :&nbsp;&nbsp;{{this.AttendanceRegularationModel.ActualWorkingDuration}}
            </div>
          </div>
          <div class="form-group row">
            <label  class="col-sm-4 col-form-label clear-padding-right">Total Working Hrs.</label>
            <div class="col-sm-7 clear-left-padding">
              :&nbsp;&nbsp;{{this.AttendanceRegularationModel.TotalWorkingHours}}
            </div>
          </div>  
          <div class="form-group row">
            <label class="col-sm-6 col-form-label text-center clear-padding-right">
              <button type="submit" tabindex="13" class="btn btn-primary save-button" [disabled]="!(EmployeeRegularisationApplicationDetails.valid && AttendanceRegularationModel.StartTime!=null && AttendanceRegularationModel.EndTime!=null && this.DisableSave==true && AttendanceRegularationModel.AttendanceStatus != '' && this.validdate == false && this.ShowMessage == false  && this.AttendanceRegularationModel.RegularationDuration > 0)">Save</button>  
            </label>
            <div class="col-sm-6 col-form-label text-center">
              <button type="button" tabindex="14" (click)="RefreshPage()" class="btn btn-danger cancle-button">Cancel</button>
            </div>
          </div>
          <div class="form-group row">
            <div class="required-validation" *ngIf="ShowMessage">Application has been Already Applied.</div>
          </div>
        </div>
      </div>
    </form>
  </fieldset>
</div>