<div class="body-content-area tabcontent" id="link1">
  <div class="row">
    <div class="col-lg-9">
      <div class="row">
        <div class="col-lg-1 low-padding text-center">
          <!-- <img *ngIf="this.SelectedMenu.Image!=null" src={{this.SelectedMenu.Image}} class="body-icon" alt="Icon"/> -->
        </div>
        <div class="col-lg-10 clear-padding">
          <span class="page-title">Manage Fine Mapping with Working Group </span>
          <small class="page-description"></small>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="!(this.CanViewState)"> 
    <table id="tblAttendanceCompanyFineRule" datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" class="table table-striped table-bordered text-center table-new row-border hover">
        <thead class="thead-blue text-center">
            <tr>
                <th>Sl No.</th>
                <th class="text-left">Map Rule Name</th>
                <th class="text-left">Working Group</th>
                <th class="text-left">Rule</th>
                <th>Status</th>
                <th>Action</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let MFR of MapFineRules, let i = index">
                <td scope="row">{{i+1}}</td>
                <td class="text-left">{{MFR.FineRuleName}}</td>
                <td class="text-left">{{MFR.WorkingGroupName}}</td>
                <td class="text-left">{{MFR.AttFineRuleName}}</td>
                <td>{{MFR.Status}}</td>
                <td>
                    <button type="button" *ngIf="this.SelectedMenu.CanEdit"  (click)="GetMapFineRuleByid( MFR.RefId )" class="table-edit-btn"><i class="fas fa-edit"></i> </button>
                    <button type="button" *ngIf="this.SelectedMenu.CanDelete"  (click)="DeleteMapFineRuleByid( MFR.RefId )" class="table-remove-btn"><i class="fas fa-trash-alt"></i> </button>
                    <button *ngIf="this.SelectedMenu.CanView"   type="button" (click)="ViewMapFineRuleByid( MFR.RefId )" class="table-view-button" type="button" ><i class="fas fa-eye"></i></button>  </td>
                  
            </tr>
        </tbody>
    </table>
    
    <fieldset class="body-form-control">
      <app-dialog [(visible)]="this.SuccessStatus">
        <span class="successmsg">{{this.SuccessMessage}}</span>
      </app-dialog>
        
      <legend class="legend--">Map Fine Rule Creation</legend>
      <form #MapFineRuleForm="ngForm" (ngSubmit)="saveMapFineRule()">
        <div class="row">
          <div class="col-lg-4">
            <div  class="col-body-border-right">
              <div class="form-group row" >
                <label class="col-sm-4 col-form-label clear-padding-right">Map Rule Name</label>
                <div class="col-sm-7 clear-left-padding">
                  <input type="text" [(ngModel)]="MapFineRuleModel.FineRuleName" tabindex="1" maxlength="250" id ="MapFineRuleName"  name="MapFineRuleName" #MapFineRuleName="ngModel" required class="form-control form-control-custom form-control-custom-new" >
                </div>
                <div *ngIf="MapFineRuleName.invalid && (MapFineRuleName.dirty || MapFineRuleName.touched)" class="alert alert-danger">
                  <div *ngIf="MapFineRuleName.errors.required">Map Fine Rule Name is required.</div>
                  <div *ngIf="MapFineRuleName.errors.maxlength">Map Fine Rule Name is not more than 250 characters</div>
                </div> 
              </div>
              <div class="form-group row">
                  
              </div>
            </div>  
          </div>
          <div class="col-lg-4">
            <div  class="col-body-border-right">
              <div class="form-group row">
                <label class="col-sm-4 col-form-label clear-padding-right">Working Group</label>
                <div class="col-sm-7 clear-left-padding">
                  <select [(ngModel)]="MapFineRuleModel.WorkingGroupId" tabindex="2" id ="WorkingGroupId" class="form-control" name="WorkingGroupId" #WorkingGroupId="ngModel">
                    <option [value]="0" [disabled]=true >Select Working Group</option>
                    <option *ngFor="let WG of WorkingGroupHour" [value]="WG.WorkingGroupId">{{WG.WorkingGroupName}}</option>
                  </select>
                </div>
                <div class="required-validation" *ngIf="MapFineRuleModel.WorkingGroupId == 0">Working Group is required</div>
              </div>
              <div class="form-group row">
              </div>
            </div>
          </div>
          <div  class="col-lg-4">
            <div class="form-group row">
                <label class="col-sm-4 col-form-label clear-padding-right">Fine Rule</label>
                <div class="col-sm-7 clear-left-padding">
                  <select [(ngModel)]="MapFineRuleModel.AttFineRuleId" tabindex="3" id ="AttFineRuleId" class="form-control" name="AttFineRuleId" #AttFineRuleId="ngModel">
                      <option [value]="0" [disabled]=true >Select Fine Rule</option>
                      <option *ngFor="let F of FineRules" [value]="F.AttFineRuleId">{{F.FineRuleName}}</option>
                  </select>
              </div>
              <div class="required-validation" *ngIf="MapFineRuleModel.AttFineRuleId == 0">Fine Rule Name is required</div>
              </div>
            <div class="form-group row">
              <label *ngIf="!(this.IsDataIsInViewState)" class="col-sm-6 col-form-label text-center clear-padding-right">
                <button type="submit" tabindex="4" class="btn btn-primary save-button" [disabled]="!(MapFineRuleForm.valid && MapFineRuleModel.AttFineRuleId > 0 && MapFineRuleModel.WorkingGroupId > 0 && this.DisableSave==true)" >Save</button>  
              </label>
              <div class="col-sm-6 col-form-label text-center">
                <button type="button" tabindex="5" (click)="RefreshPage()" class="btn btn-danger cancle-button">Cancel</button>
              </div>
            </div>
          </div>
        </div>
      </form>
    </fieldset>
  </div>
</div> 