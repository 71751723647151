export class SalaryRule
{
    public RefId : number;
    public CompanyId : number;
    public GradeId : number;
    public ComponentId :number;
    public Amount :number;
    public Percentage :number;
    public IsBasic :boolean;
    public IsCTC :boolean;
    public IsActive : boolean;
    public ComponentName :string;
    public IsValid : boolean; 
    public isenable : boolean;
    public value:string;
}