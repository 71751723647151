import { Component, OnInit, Renderer } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from '../../shared/user.service';
import { User } from '../../shared/user.model';
import { CrmMenuAccessUsers } from '../../shared/Menu/CrmMenuAccessUsers'
import { ApproveLeaveService } from '../../shared/HR_ADMIN/Approve/ApproveLeave.service'
import { ConfirmSettings } from '../../../app/confirm-settings';
// Using CommonMenuHandelling Class 
import { CommonMenuHandelling } from '../../shared/Menu/CommonMenuHandelling';


@Component({
  selector: 'app-mice-crm-master',
  templateUrl: './mice-crm-master.component.html',
  styleUrls: ['./mice-crm-master.component.css']
})
export class MiceCrmMasterComponent implements OnInit {
  leaveService: void;
  userClaims: any;
  SubMenues: CrmMenuAccessUsers[] = [];
  MainMenu: CrmMenuAccessUsers[] = [];
  Mod: any;
  check: number;
  selected: any;
  selectedIndex: number;
  selecteModule: number;

  tempmenu: CrmMenuAccessUsers;
  SubMenuestemp: CrmMenuAccessUsers[] = [];

  UserModel: User = new User();
  Users: User[] = [];
  User: any;
  DefaultPage: CrmMenuAccessUsers;

  disableFlag: boolean = false;





  settings: ConfirmSettings | any =
    {
      overlay: true,
      overlayClickToClose: true,
      showCloseButton: true,
      confirmText: 'Yes',
      declineText: 'No'
    };

  constructor(private router: Router, private userService: UserService, private render: Renderer, private leaveservice: ApproveLeaveService, public commonmenu: CommonMenuHandelling) {
    this.getLeaveNotification();
    if (userService.selectdmoduleid != undefined) {
      localStorage.setItem("moduleidstor", userService.selectdmoduleid.toString());
      localStorage.setItem("menuidstor", userService.selectedmenuid.toString());
    }

    //this.commonmenu.SelectedMainMenu = this.userService.selectedmenuid;
    this.commonmenu.SelectedMainMenu = this.userService.SelectedMenuIndex;
    let mindex = localStorage.getItem("selectedmodule")
    this.selectedIndex = 0;
    this.selecteModule = Number(mindex);

    this.DefaultPage = new CrmMenuAccessUsers();

  }

  isActive(item) {
    if (item === this.selected) {
      this.selected = null;
    }
    else {
      this.selected = item;
    }
  };

  ngOnInit() {
    this.Mainmenues();
    this.GetLoginDetails();

    // this.getSubmenues();
  }
  get TotalNoofLeaveNotifi() {
    return this.leaveservice.TotalNoofLeaveNotification

  }
  get SubMenuesCRM() {
    return this.userService.SubMenuesCRM;
  }
  get MainMenuCRM() {
    return this.userService.MainMenuCRM;
  }

  Mainmenues() {


    this.userService.MainMenuCRM = [];
    let t = localStorage.getItem('userToken');
    t = 'Bearer' + t;
    let moduleid = localStorage.getItem("moduleidstor")
    this.userService.CrmMainMenuList(t, moduleid).then(x => {
      this.Mod = x;
      Object.keys(this.Mod).forEach(key => {
        this.Mod[key];
        let menutemp = new CrmMenuAccessUsers();
        menutemp = this.Mod[key];
        this.userService.MainMenuCRM.push(menutemp);
      })

    }).then(y => {

      this.getSubmenues();

    }).catch(k => {
      this.getSubmenues();
    });



  }

  public listClick(event: any) {
    event.preventDefault();
    this.render.setElementClass(event.target, "current", true);
    // How to remove 'active' from siblings ?
  }



  getSubmenues() {

    //this.SubMenues = [];


    let t = localStorage.getItem('userToken');
    t = 'Bearer' + t;
    let moduleid = localStorage.getItem("moduleidstor");
    let menuid = localStorage.getItem("menuidstor");
    this.userService.GetCrmSubMenuList(t, menuid).then(x => {
      this.Mod = x;
      this.userService.SubMenuesCRM = [];
      Object.keys(this.Mod).forEach(key => {
        this.Mod[key];
        let SubMenutemp = new CrmMenuAccessUsers();
        SubMenutemp = this.Mod[key];

        this.userService.SubMenuesCRM.push(SubMenutemp);
      })
    }).then(y => {
      this.DefaultPage = this.userService.SubMenuesCRM.find(x => x.IsDefaultPage == true);
      this.userService.selectedCrmMenu = this.DefaultPage;
      this.commonmenu.SubMenu = this.userService.SubMenuesCRM;


      if ((this.userService.IsFormMainMenu == false) || (this.userService.IsFormMainMenu == undefined)) {

        this.router.navigate([this.DefaultPage.RouteName]);
      }
      else {

        this.userService.IsFormMainMenu = false;
        this.router.navigate([this.userService.MainMenuUrl]);

      }


    }).catch(k => {
      this.userService.SubMenuesCRM.find(x => x.IsDefaultPage == true);
      if ((this.userService.IsFormMainMenu == false) || (this.userService.IsFormMainMenu == undefined)) {
        this.router.navigate([this.DefaultPage.RouteName]);
      }
      else {

        this.userService.IsFormMainMenu = false;

      }

      return this.userService.SubMenuesCRM;
    });

  }

  LoadComponents(routname, i, menuid): void {
    // this.userService.SelectedMenu= this.SubMenues.find(x=>x.MenuId==parseInt(menuid));       
    //   alert(menuid) ;

    // if(menuid !==this.userService.SelectedMenu.MenuId){

    this.selectedIndex = i;
    // this.router.navigate([this.userService.SelectedMenu.RouteName]);
    //}
    this.userService.selectedCrmMenu = this.userService.SubMenuesCRM.find(x => x.MenuId == parseInt(menuid));
    this.router.navigate([this.userService.selectedCrmMenu.RouteName]);
    //this.router.navigate([routname]);
  }

  MainPages(menuid, moduleid, RouteName, j): void {
    this.disableFlag = true;
    setTimeout(() => {
      this.disableFlag = false;
    }, 3000);

    this.userService.selectdmoduleid = moduleid;
    localStorage.setItem("menuidstor", menuid.toString());
    this.commonmenu.SelectedMainMenu = j;

    if (this.userService.selectedmenuid == undefined) {

      this.userService.selectedmenuid = menuid;
      localStorage.setItem("selectedmodule", moduleid);
      // this.router.navigate([RouteName]);
      this.getSubmenues();

    }
    else (menuid != this.userService.selectedmenuid)
    {

      this.userService.selectedmenuid = menuid;

      localStorage.setItem("selectedmodule", moduleid);
      this.getSubmenues();
      //this.router.navigate([RouteName]);  
    }



  }

  GetLoginDetails() {

    this.UserModel = this.commonmenu.GetLoginDetails();
  }

  // GetLoginDetails() 
  // {
  //   let t =  localStorage.getItem('userToken');
  //   t = 'Bearer' + t;
  //   this.userService.LoginDetails( t).then(x => 
  //   {
  //     this.User = x;
  //     this.UserModel.EmployeeName=this.User.EmployeeName;
  //     this.UserModel.EmpPhotoURL=this.User.EmpPhotoURL;
  //     this.UserModel.DesignationName=this.User.DesignationName;
  //     this.UserModel.LastLoginTime=this.User.LastLoginTime;          
  //     this.UserModel.IPAddress=this.User.IPAddress;
  //     this.UserModel.Id=this.User.Id;
  //     this.UserModel.CurrentTime=this.User.CurrentTime;
  //     this.UserModel.IconUrlLogoType=this.User.IconUrlLogoType;
  //     this.UserModel.IconUrlImage=this.User.IconUrlImage;
  //     this.UserModel.CompPhotoURL=this.User.CompPhotoURL;
  //     this.UserModel.CompIconUrlLogoType=this.User.CompIconUrlLogoType;
  //     this.UserModel.CompIconUrlImage=this.User.CompIconUrlImage;
  //   });
  // }

  // Logout() 
  // {
  //   this._confirmation.create('Confirm to LogOut', 'Press Yes to LogOut', this.settings)
  //   .subscribe((ans: ResolveEmit) => 
  //   {
  //     if (ans.resolved == true) 
  //     {
  //       let t =  localStorage.getItem('userToken');
  //       t = 'Bearer' + t;
  //       this.userService.UpdateLogOutTime(t ,this.UserModel.Id).subscribe(res=>
  //       {
  //         this.UserModel.Id=0;  
  //       });
  //       localStorage.removeItem('userToken');
  //       this.router.navigate(['/login']);
  //     }
  //   })
  // }

  Logout() {
    this.commonmenu.LogOut(); // Using Te CommonMenuHandelling Class
  }

  Approvalpage() {
    if (this.leaveservice.TotalNoofLeaveNotification > 0) {
      this.router.navigate(['/ApproveOrReject/Leave']);
    }
  }

  getLeaveNotification() {
    let t = localStorage.getItem('userToken');
    t = 'Bearer ' + t;
    this.leaveservice.GetLeaveApplicationsNotification(t).then(x => {
      this.Mod = x;
      this.leaveservice.TotalNoofLeaveNotification = this.Mod;
    });
  }





  OnDashboard() {
    this.router.navigate(['/DashBoard']);
  }
}