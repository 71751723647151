<div class="body-content-area tabcontent" id="link1">
<div class="row">
    <div class="col-lg-9">
        <div class="row">
            <div class="col-lg-1 low-padding text-center">
                <!-- <img *ngIf="this.SelectedMenu.Image!=null" src={{this.SelectedMenu.Image}} class="body-icon" alt="Icon"/> -->
            </div>
            <div class="col-lg-10 clear-padding">
                <span class="page-title">Manage Leave Type</span>
                <small class="page-description"></small>
            </div>
        </div>
    </div>
</div>

<div *ngIf="!(this.CanViewState)">
<table id="tblleavetype" datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" class="table table-striped table-bordered text-center table-new row-border hover">
    <thead class="thead-blue text-center">
        <tr>
            <th>Sl No.</th>
            <th>Code</th>
            <th class="text-left">Leave Type</th>
            <th>Action</th>
        </tr>
    </thead>
    <tbody>
        <tr *ngFor="let leave of LeaveTypes, let i = index">
            <td scope="row">{{i+1}}</td>
            <td>{{leave.LeaveTypeCode}}</td>
            <td class="text-left">{{leave.LeaveTypeName}}</td>
            <td>
                <button *ngIf="this.SelectedMenu.CanEdit"  type="button" (click)="GetLeaveTypeByid( leave.LeaveTypeId )" class="table-edit-btn"><i class="fas fa-edit"></i> </button> 
                <button *ngIf="this.SelectedMenu.CanDelete" type="button" (click)="DeleteLeaveTypeByid( leave.LeaveTypeId )" class="table-remove-btn"><i class="fas fa-trash-alt"></i>  </button>
                <button *ngIf="this.SelectedMenu.CanView"  type="button" (click)="viewLeaveTypeByid( leave.LeaveTypeId )" class="table-view-button" type="button" ><i class="fas fa-eye"></i></button>
            </td>
        </tr>
    </tbody>
</table>
<fieldset class="body-form-control">
    <app-dialog [(visible)]="this.SuccessStatus">
        <span class="successmsg">{{this.SuccessMessage}}</span>
    </app-dialog>
    
    <legend class="legend--">Leave Type Creation</legend>
    <form #LeaveTypeForm="ngForm" (ngSubmit)="saveLeaveType()">
        <div class="row">
            <div class="col-lg-4">
                <div class="col-body-border-right">
                    <div class="form-group row">
                        <label  class="col-sm-4 col-form-label clear-padding-right"> Code</label>
                        <div class="col-sm-7 clear-left-padding">
                            <input type="text" [(ngModel)]="LeaveTypeModel.LeaveTypeCode" tabindex="1" maxlength="40" id ="LeaveTypeCode"  name="LeaveTypeCode" #LeaveTypeCode="ngModel" required class="form-control form-control-custom form-control-custom-new" (blur)="Checkcode()" >
                        </div>
                        <div *ngIf="this.Codecheck.length > 0" class="alert alert-danger">Leave Type Code is exist</div>
                        <div *ngIf="LeaveTypeCode.invalid && (LeaveTypeCode.dirty || LeaveTypeCode.touched)" class="alert alert-danger">
                            <div *ngIf="LeaveTypeCode.errors.required">Leave Type Code is required.</div>
                            <div *ngIf="LeaveTypeCode.errors.maxlength">Leave Type Code is not more than 40 characters</div>
                        </div>
                    </div>
                </div>
            </div>
            
            <div class="col-lg-4">
                <div class="col-body-border-right">
                    <div class="form-group row">
                        <label  class="col-sm-4 col-form-label clear-padding-right">Leave Type</label>
                        <div class="col-sm-7 clear-left-padding">
                            <input type="text" [(ngModel)]="LeaveTypeModel.LeaveTypeName" tabindex="2" id ="LeaveTypeName"  name="LeaveTypeName" #LeaveTypeName="ngModel" required maxlength="250" class="form-control form-control-custom form-control-custom-new" (blur)="CheckName()">
                        </div>
                        <div *ngIf="this.Namecheck.length > 0" class="alert alert-danger">Leave Type Name is exist</div>
                        <div *ngIf="LeaveTypeName.invalid && (LeaveTypeName.dirty || LeaveTypeName.touched)" class="alert alert-danger">
                            <div *ngIf="LeaveTypeName.errors.required">Leave Type Name  is required.</div>
                            <div *ngIf="LeaveTypeName.errors.maxlength">Leave Type Name is not more than 250 characters</div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-4">
                <div class="form-group row">
                    <label class="col-sm-6 col-form-label text-center clear-padding-right" *ngIf="!(this.IsDataIsInViewState)">
                        <button type="submit" tabindex="3" class="btn btn-primary save-button" [disabled]="!(LeaveTypeForm.valid && this.CheckCodeExiststatus==true && this.CheckNameExiststatus==true && this.DisableSave==true)">Save</button>  
                    </label>
                    <div class="col-sm-6 col-form-label text-center">       
                    <button  type="button"  tabindex="4" class="btn btn-danger cancle-button" (click)="RefreshPage()" >Cancel</button></div>
                </div>
            </div>
        </div>
    </form>
</fieldset>
</div>
</div>