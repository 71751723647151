<div class="body-content-area tabcontent" id="link1">
  <div class="row">
    <div class="col-lg-9">
      <div class="row">
        <div class="col-lg-1 low-padding text-center">
          <!-- <img *ngIf="this.SelectedMenu.Image!=null" src={{this.SelectedMenu.Image}} class="body-icon" alt="Icon"/> -->
        </div>
        <div class="col-lg-10 clear-padding">
          <div>
            <span class="page-title">Employee Leave Application Status</span>
            <small class="page-description"></small>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="!(this.CanViewState)">
    <fieldset class="body-form-control" > 
      <legend class="legend--">Employee Details</legend>
      <form #SearchForm="ngForm">
        <div class="row">
          <div class="col-lg-4">
            <div class="col-body-border-right">
              <div class="form-group row">
                <label class="col-sm-2 col-form-label clear-padding-right">Employee</label>
                <div class="col-sm-9 clear-left-padding">
                  <angular2-multiselect tabindex="1" class="form-control" [data]="emp" [(ngModel)]="selectedItems" name="EmployeeId" [settings]="EmployeeSettings"
                    (onSelect)="onItemSelect($event)" (onDeSelect)="OnItemDeSelect($event)" (onSelectAll)="onSelectAll($event)" (onDeSelectAll)="onDeSelectAll($event)">
                  </angular2-multiselect>
                </div>
                <div class="required-validation" *ngIf="ModelListApplication.EmployeeId == 0">Employee is required</div>
              </div>
              <div class="form-group row">
                <label class="col-sm-4 col-form-label clear-padding-right">Status</label>
                <div class="col-sm-7 clear-left-padding">
                  <select [(ngModel)]="ModelListApplication.Status" tabindex="3" id ="Status" (ngModelChange)="StatusChanged()" class="form-control" name="Status" required>
                    <option [value]="0" [disabled]=true >Select Status</option>
                    <option [value]="1">Pending</option>
                    <option [value]="2">Approved</option>
                    <option [value]="3">Rejected</option>
                  </select>
                </div>
                <div class="required-validation" *ngIf="ModelListApplication.Status == 0">Status is required</div>
              </div>
            </div>
          </div>
          <div class="col-lg-4">
            <div class="col-body-border-right">
              <div class="form-group row">
                <label class="col-sm-4 col-form-label clear-padding-right">From Date</label>
                <div class="col-sm-7 clear-left-padding">
                  <input type="date" name="SearchFromDate" [ngModel]="ModelListApplication.SearchFromDate | date:'yyyy-MM-dd'" (ngModelChange)="SearchFromDateChanged($event)" #SearchFromDate="ngModel" required/>
                </div>
              </div>
            </div>
            <div class="form-group row">
              <div class="col-sm-7 clear-left-padding">
                <button type="submit" (click)="submitClick()" class="btn btn-primary save-button" [disabled]="!(SearchForm.valid && this.ModelListApplication.Status > 0 && this.DisableSave == true && this.validdate == false && this.validTodate == false)">Generate</button>   
              </div>
            </div>
          </div>
          <div class="col-lg-4">
            <div class="form-group row">
              <label class="col-sm-4 col-form-label clear-padding-right">To Date</label>
              <div class="col-sm-7 clear-left-padding">
                <input type="date" name="SearchToDate" [ngModel]="ModelListApplication.SearchToDate | date:'yyyy-MM-dd'" (ngModelChange)="SearchToDateChanged($event)" #SearchToDate="ngModel" required/>
              </div>
            </div>
          </div>
        </div>
      </form>    
    </fieldset>

    <div *ngIf="PendingMsg" style="color: #dc3545; text-align: center;font-size: 15px; font-weight: bold;">No Pending Application is Found.</div>
    <div *ngIf="ApprovedMsg" style="color: #dc3545; text-align: center;font-size: 15px; font-weight: bold;">No Approved Application is Found.</div>
    <div *ngIf="RejectedMsg" style="color: #dc3545; text-align: center;font-size: 15px; font-weight: bold;">No Rejected Application is Found.</div>
    <div *ngIf="validdate" style="color: #dc3545; text-align: center;font-size: 15px; font-weight: bold;">Date From is not Valid Date.</div>
    <div *ngIf="validTodate" style="color: #dc3545; text-align: center;font-size: 15px; font-weight: bold;">Date To is not Valid Date.</div>

    <fieldset class="body-form-control" *ngIf="LeavePending.length > 0"> 
      <legend class="legend--">Employee Leave Pending</legend>
        <form #EmployeeLeavePending="ngForm">
          <div lass="row" >
            <table id="tblLeavePending" class="table table-striped table-bordered text-center table-new row-border hover">
              <thead class="thead-blue text-center">
                <tr>
                  <th>Sl.No.</th>
                  <th>Application Date</th>
                  <th>FromDate</th>
                  <th>ToDate</th>
                  <th>NoDaysLeave</th>
                  <th>Reason</th>
                  <th>Reporting Head</th>
                  <th>Cancel</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let LeavePending of LeavePending, let i = index">
                  <td scope="row">{{i+1}}</td>
                  <td>{{LeavePending.ApplicationDate | date : "dd-MMM-yyyy"}}</td>
                  <td>{{LeavePending.FromDate | date : "dd-MMM-yyyy"}}</td>
                  <td>{{LeavePending.ToDate | date : "dd-MMM-yyyy"}}</td>
                  <td>{{LeavePending.NoDaysLeave}}</td>
                  <td style=" text-align: left;">{{LeavePending.ReasonsLeave}}</td>
                  <td style=" text-align: left;">{{LeavePending.RHName}}</td>
                  <td>
                    <button type="button" (click)="CancelLeaveApplication(LeavePending.RefId,template)" class="table-remove-btn" type="button"><i class="fas fa-trash-alt"></i></button>
                  </td>
                </tr>
              </tbody>
              <tfoot class="thead-blue text-center">
                <tr>
                  <th></th>
                  <th></th>
                  <th></th>
                  <th></th>
                  <th></th>
                  <th></th>
                  <th></th>
                  <th></th>
                </tr>
              </tfoot>
            </table>
          </div>
        </form>
    </fieldset>

    <fieldset class="body-form-control" *ngIf="LeaveApproved.length > 0"> 
      <legend class="legend--">Employee Leave Approved</legend>
      <table id="tblLeaveApproved" class="table table-striped table-bordered text-center table-new row-border hover">
        <thead class="thead-blue text-center">
          <tr>
            <th>Sl.No.</th>
            <th>Application Date</th>
            <th>From-Date</th>
            <th>To-Date</th>
            <th>NoDaysLeave</th>
            <th>Remarks</th>
            <th>Approver Name</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let LeaveApproved of LeaveApproved, let i = index">
            <td scope="row">{{i+1}}</td>
            <td>{{LeaveApproved.ApplicationDate | date : "dd-MMM-yyyy"}}</td>
            <td>{{LeaveApproved.FromDate | date : "dd-MMM-yyyy"}}</td>
            <td>{{LeaveApproved.ToDate | date : "dd-MMM-yyyy"}}</td>
            <td>{{LeaveApproved.NoDaysLeave}}</td>
            <td style=" text-align: left;">{{LeaveApproved.Approved_RejectedRemarks}}</td>
            <td style=" text-align: left;">{{LeaveApproved.RHName}}</td>
          </tr>
        </tbody>
        <tfoot class="thead-blue text-center">
          <tr>
            <th></th>
            <th></th>
            <th></th>
            <th></th>
            <th></th>
            <th></th>
            <th></th>
          </tr>
        </tfoot>
      </table>
    </fieldset>

    <fieldset class="body-form-control" *ngIf="LeaveRejected.length > 0"> 
      <legend class="legend--">Employee Leave Rejected</legend>
        <form #EmployeeLeaveRejected="ngForm">
          <div lass="row" >
            <table id="tblLeaveRejected" class="table table-striped table-bordered text-center table-new row-border hover">
              <thead class="thead-blue text-center">
                <tr>
                  <th>Sl.No.</th>
                  <th>Application Date</th>
                  <th>From-Date</th>
                  <th>To-Date</th>
                  <th>NoDaysLeave</th>
                  <th>Remarks</th>
                  <th>Rejecter Name</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let LeaveRejected of LeaveRejected, let i = index">
                  <td scope="row">{{i+1}}</td>
                  <td>{{LeaveRejected.ApplicationDate | date : "dd-MMM-yyyy"}}</td>
                  <td>{{LeaveRejected.FromDate | date : "dd-MMM-yyyy"}}</td>
                  <td>{{LeaveRejected.ToDate | date : "dd-MMM-yyyy"}}</td>
                  <td>{{LeaveRejected.NoDaysLeave}}</td>
                  <td style=" text-align: left;">{{LeaveRejected.Approved_RejectedRemarks}}</td>
                  <td style=" text-align: left;">{{LeaveRejected.RHName}}</td>
                </tr>
              </tbody>
              <tfoot class="thead-blue text-center">
                <tr>
                  <th></th>
                  <th></th>
                  <th></th>
                  <th></th>
                  <th></th>
                  <th></th>
                  <th></th>
                </tr>
              </tfoot>
            </table>
          </div>
        </form>
    </fieldset>
    
    <div style=" box-align: center; width: 1500px;" >
      <ng-template #template>
        <div class="modal-header" style="background: #2a9bd7; max-width: 1500px;"> 
          <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()" style="color:#fff;">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div  style="text-align: center; max-width: 1500px;">
          <div *ngIf="CancelMsg" style="color: #dc3545; text-align: center;font-size: 15px; font-weight: bold;">Application Cancel Successfully</div>
          <div style=" width: 100%;" *ngIf="ShowPopup">
              <fieldset class="body-form-control" style=" text-align: left;">
                <legend class="legend--">Leave Details</legend>
                <form #CancelApplicationForm="ngForm">
                  <div class="row">
                    <div class="col-lg-4">
                      <div class="col-body-border-right">
                        <div class="form-group row">
                          <label  class="col-sm-4 col-form-label clear-padding-right">Leave Type</label>
                          <div class="col-sm-7 clear-left-padding">
                            :&nbsp;&nbsp;{{this.ModelListApplication.LeaveTypeName}}
                          </div>
                        </div>
                        <div class="form-group row">
                          <label  class="col-sm-4 col-form-label clear-padding-right">Date From</label>
                          <div class="col-sm-7 clear-left-padding">
                            :&nbsp;&nbsp;{{this.ModelListApplication.FromDate | date : "dd-MMM-yyyy"}}
                          </div>
                        </div>
                        <div class="form-group row">
                          <div class="col-sm-4 col-form-label clear-padding-right">
                            <input type="radio" disabled="disabled" [checked]="this.ModelListApplication.Fromvalue=='FirstHalf'" id ="FromFirstHalf" name="FromFirstHalf" >
                            FirstHalf &nbsp; &nbsp;
                          </div>
                          <div class="col-sm-7 clear-left-padding">
                            <input type="radio" disabled="disabled" [checked]="this.ModelListApplication.Fromvalue=='SecondHalf'" id ="FromSecondHalf" name="FromSecondHalf" >
                            SecondHalf
                          </div>
                        </div>
                        <div class="form-group row">
                          <label  class="col-sm-4 col-form-label clear-padding-right">Approver</label>
                          <div class="col-sm-7 clear-left-padding">
                            :&nbsp;&nbsp;{{this.ModelListApplication.RHCode}}-{{this.ModelListApplication.RHName}} 
                          </div>
                        </div>
                        <div class="form-group row">
                          <label class="col-sm-4 col-form-label clear-padding-right">ApproverMailId</label>
                          <div class="col-sm-7 clear-left-padding">
                            :&nbsp;&nbsp;{{this.ModelListApplication.RHEmailId}} 
                          </div>
                        </div>
                        <!-- <div class="form-group row">
                          <label class="col-sm-4 col-form-label clear-padding-right">Remarks</label>
                          <div class="col-sm-7 clear-left-padding">
                            :&nbsp;&nbsp;{{this.ModelListApplication.RemarksLeave}} 
                          </div>
                        </div> -->
                      </div> 
                    </div>
            
                    <div class="col-lg-4">
                      <div class="col-body-border-right">
                        <div class="form-group row">
                          <label  class="col-sm-4 col-form-label clear-padding-right">Code</label>
                          <div class="col-sm-7 clear-left-padding">
                            :&nbsp;&nbsp;{{this.ModelListApplication.EmployeeCode}}
                          </div>
                        </div>
                        <div class="form-group row">
                          <label  class="col-sm-4 col-form-label clear-padding-right">Date To</label>
                          <div class="col-sm-7 clear-left-padding">
                            :&nbsp;&nbsp;{{this.ModelListApplication.ToDate | date : "dd-MMM-yyyy"}} 
                          </div>
                        </div>
                        <div class="form-group row">
                          <div class="col-sm-4 col-form-label clear-padding-right">
                            <input type="radio" disabled="disabled" [checked]="this.ModelListApplication.Tovalue=='FirstHalf'" id ="ToFirstHalf" name="ToFirstHalf">
                            FirstHalf &nbsp; &nbsp;
                          </div>
                          <div class="col-sm-7 clear-left-padding">
                            <input type="radio" disabled="disabled" [checked]="this.ModelListApplication.Tovalue=='SecondHalf'" id ="ToSecondHalf" name="ToSecondHalf">
                            SecondHalf
                          </div>
                        </div>
                        <div class="form-group row">
                          <label  class="col-sm-4 col-form-label clear-padding-right">DOA</label>
                          <div class="col-sm-7 clear-left-padding">
                            :&nbsp;&nbsp;{{this.ModelListApplication.ApplicationDate | date : "dd-MMM-yyyy"}}
                          </div>
                        </div>
                        <div class="form-group row">
                          <label class="col-sm-4 col-form-label clear-padding-right">Alternate No</label>
                          <div class="col-sm-7 clear-left-padding">
                            :&nbsp;&nbsp;{{this.ModelListApplication.ContactNoDuringLeave}}
                          </div>
                        </div>
                      </div> 
                    </div>
            
                    <div class="col-lg-4">
                      <div class="form-group row">
                        <label  class="col-sm-4 col-form-label clear-padding-right">Name</label>
                        <div class="col-sm-7 clear-left-padding">
                          :&nbsp;&nbsp;{{this.ModelListApplication.EmployeeName}}
                        </div>
                      </div>
                      <div class="form-group row">
                        <label class="col-sm-4 col-form-label clear-padding-right">No Of Days</label>
                        <div class="col-sm-7 clear-left-padding">
                          :&nbsp;&nbsp;{{this.ModelListApplication.NoDaysLeave}}
                        </div>
                      </div>
                      <div class="form-group row">
                        <label  class="col-sm-4 col-form-label clear-padding-right">Reason</label>
                        <div class="col-sm-7 clear-left-padding">
                            :&nbsp;&nbsp;{{this.ModelListApplication.ReasonsLeave}}
                        </div>
                      </div>
                      <div class="form-group row">
                        <label  class="col-sm-4 col-form-label clear-padding-right">Contact Person</label>
                        <div class="col-sm-7 clear-left-padding">
                            :&nbsp;&nbsp;{{this.ModelListApplication.PersonToContact}}
                        </div>
                      </div>  
                      <!-- <div class="form-group row">
                        <label class="col-sm-4 col-form-label clear-padding-right">Address During Leave</label>
                        <div class="col-sm-7 clear-left-padding">
                            :&nbsp;&nbsp;{{this.ModelListApplication.AddressDuringLeave}}
                        </div>
                      </div> -->
                      <div class="form-group row">
                        <label  class="col-sm-4 col-form-label clear-padding-right">Remarks</label>
                        <div class="col-sm-7 clear-left-padding">
                          <input type="text" [(ngModel)]="ModelListApplication.ApprovedRejectedRemarks" tabindex="1" maxlength="500" id ="ApprovedRejectedRemarks" name="ApprovedRejectedRemarks" #ApprovedRejectedRemarks="ngModel" required class="form-control form-control-custom form-control-custom-new" >
                        </div>
                        <div *ngIf="ApprovedRejectedRemarks.invalid && (ApprovedRejectedRemarks.dirty || ApprovedRejectedRemarks.touched)" class="alert alert-danger">
                          <div *ngIf="ApprovedRejectedRemarks.errors.required">Remarks is required.</div>
                          <div *ngIf="ApprovedRejectedRemarks.errors.maxlength">Remarks is not more than 500 characters</div>
                        </div> 
                      </div>
                      <div class="form-group row">
                        <label class="col-sm-6 col-form-label text-center clear-padding-right">
                          <button type="submit" tabindex="2" (click)="LeaveCancel(template)" class="btn btn-primary save-button" [disabled]="!(CancelApplicationForm.valid && this.DisableSave==true)">Cancel Leave</button>  
                        </label>
                      </div>
                    </div>
                  </div>
                </form>
            </fieldset>
          </div>

          <button type="button" aria-label="Close" (click)="modalRef.hide()" style="background: #2a9bd7; outline: 0px none; border: 0px none; color: #fff; padding: 5px 10px; border-radius: 3px;">
            <span>Close</span>
          </button>             
        </div>
      </ng-template>
    </div>

  </div>
</div>