
import { Component, OnInit, ViewChild } from '@angular/core';
import { CrmLeadService } from '../../shared/CRM/MICECRM/LEADCRM/CrmLeadService';
import { SalesCallService } from '../../shared/CRM/MICECRM/SalceCall/SalesCallService';
import { Model_Crm_ListName } from '../../shared/CRM/MICECRM/SalceCall/Model_Crm_ListName';
import { Model_CRM_Telecall } from '../../shared/CRM/MICECRM/SalceCall/Model_CRM_Telecall';
import { Model_CRM_TeleCall_FollowUp } from '../../shared/CRM/MICECRM/SalceCall/Model_CRM_TeleCall_FollowUp';
import { PagerService } from '../../shared/CRM/MICECRM/LEADCRM/PagerService';
import { Model_CRM_CallType } from '../../shared/CRM/MICECRM/LEADCRM/Model_CRM_CallType';
import { AllCommunityModules, Module } from '@ag-grid-community/all-modules';
import { AgGridAngular } from '@ag-grid-community/angular';
import { Time } from '@angular/common';
import { SaveButtonComponent } from '../SalesCall/save-button.component';
import { FollouUpButtonComponent } from '../SalesCall/follou-up-button.component';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { FollowUpModelPopupComponent } from '../SalesCall/follow-up-model-popup.component'


declare var $: any;
// datepickerFactory($);
// datepickerJAFactory($);

@Component({
  selector: 'app-sales-component',
  templateUrl: './sales-component.component.html',
  styleUrls: ['./sales-component.component.css'],
  providers: [PagerService]
})
export class SalesComponentComponent implements OnInit {
  datex: Time;
  private gridApi;
  private gridColumnApi;
  public modules: Module[] = AllCommunityModules;
  public columnDefs;
  public defaultColDef;
  private defaultColGroupDef;
  private columnTypes;
  public rowData: any[];
  private TempRow: any[];
  public components;
  salesCallId: number;
  template1: any;

  @ViewChild('agGrid', { static: false }) agGrid: AgGridAngular;
  result: any;
  private xid;
  telecalldata: any;
  SuccessMessage: string = "";
  SuccessStatus: boolean = false;
  public selectedintime = undefined;
  private sideBar;
  // For Searchable Dropdown
  // DesignationList: Model_CRM_Designation[] = [];
  CRM_Obj_Telecall: any;
  CRM_Call_Types: Model_CRM_CallType[];
  CallTypeObject: any;
  //Employee Id lIst
  // for Paging
  pager: any = {};
  //for paging
  Ids: number[] = [];
  //Ids:any;
  ListNames: Model_Crm_ListName[] = [];
  TelecallsFollowUp: Model_CRM_TeleCall_FollowUp[] = [];
  Telecalls: Model_CRM_Telecall[] = [];
  TempModel_Telecall: Model_CRM_Telecall
  //begnig of the page list
  Pagelist: Model_CRM_Telecall[] = [];
  PageListList: Model_CRM_Telecall[] = [];
  tempPageList: Model_CRM_Telecall[] = [];
  //end of the page list
  frameworkComponents: any;
  rowDataClicked1 = {};
  rowDataClicked2 = {};
  X: any;
  y: any;
  SelectedRow: any[];
  CurrentDate: Date;
  Houres: any;
  Minutes: any;
  Day: any;
  Month: any;
  year: any;
  TempDate: string;
  Time: string;
  // //for model 
  // public modalRef: BsModalRef;
  // public modelopt: ModalOptions;
  // ShowPopup: boolean = false;
  // ApprovedMsg: boolean = false;
  public modalRef: BsModalRef;
  // //for model
  FromDate: Date
  Todate: Date
  validdate: boolean

  constructor(private CRM_LeadService: CrmLeadService, private SalesSrvice: SalesCallService, private modalService: BsModalService) {
    this.frameworkComponents = {
      buttonRenderer: SaveButtonComponent,
      FollwUpRender: FollouUpButtonComponent
    }

    this.columnDefs = [
      {
        headerName: "CallId",
        field: "Tele_Call_Id",
        editable: false,
        hide: true,
      },

      {
        headerName: "Date",
        field: "Calldate",
        type: ["dateColumn"],
        editable: true,
        cellEditor: "datePicker",
        width: 100,
      },
      {
        headerName: "Time",
        field: "Time",
        cellEditor: 'timepicker',
        width: 80
      },
      {
        headerName: " Corporate Name",
        field: "CorPorateName",
        width: 120
      },
      {
        headerName: "Client Name",
        field: "ClietName",
        width: 130
      },
      {
        headerName: "Phone",
        field: "PhoneNo",
        width: 130
      },
      {
        headerName: "Email",
        field: "EmailId",
        width: 130
      },
      {
        headerName: "Type of Work",
        field: "CallType",
        width: 110
      },
      {
        headerName: "Description",
        field: "Remarks",
      },
      {
        headerName: "User Name",
        field: "CreatedBy",
        editable: false,

      }
      ,
      {
        headerName: '',
        filter: false,
        cellRenderer: 'buttonRenderer',
        cellRendererParams: {
          onClick: this.onBtnClickSave.bind(this),
          label: 'Save'
        },
        width: 80
      },
      {
        headerName: '',
        filter: false,
        cellRenderer: 'FollwUpRender',
        cellRendererParams: {
          onClick: this.onBtnClickFollowUp.bind(this),
          label: 'Follow Up'
        },
        width: 120
      }
    ];
    this.sideBar = "filters";
    this.components = { datePicker: getDatePicker(), timepicker: getTimePicker() };
    this.defaultColDef = {
      width: 150,
      editable: true,
      enableRowGroup: true,
      enablePivot: true,
      enableValue: true,
      sortable: true,
      resizable: true,
      filter: "agTextColumnFilter"
    };
    //this.defaultColGroupDef = { marryChildren: true };
    this.columnTypes = {
      dateColumn: {
        filter: "agDateColumnFilter",
        filterParams: {
          comparator: function (filterLocalDateAtMidnight, cellValue) {
            var dateParts = cellValue.split("/");
            var day = Number(dateParts[0]);
            var month = Number(dateParts[1]) - 1;
            var year = Number(dateParts[2]);
            var cellDate = new Date(year, month, day);
            if (cellDate < filterLocalDateAtMidnight) {
              return -1;
            } else if (cellDate > filterLocalDateAtMidnight) {
              return 1;
            } else {
              return 0;
            }
          }
        }
      },
      numberColumn: {
        width: 83,
        filter: "agNumberColumnFilter"
      },
      medalColumn: {
        width: 100,
        columnGroupShow: "open",
        filter: false
      },
      nonEditableColumn: { editable: false },
    };
  }

  onGridReady(params) {
    this.CurrentDate = new Date();
    this.Time = '';
    this.Houres = this.CurrentDate.getHours();
    this.Minutes = this.CurrentDate.getMinutes();
    if (this.Houres < 10) {
      this.Time = '0' + this.Houres
    }
    else {
      this.Time = this.Houres
    }
    if (this.Minutes < 10) {
      this.Time = this.Time + ':' + '0' + this.Minutes;
    }
    else {
      this.Time = this.Time + ':' + this.Minutes;
    }
    this.TempDate = "";
    this.Day = this.CurrentDate.getDate();
    this.Month = this.CurrentDate.getMonth() + 1;
    this.year = this.CurrentDate.getFullYear();
    if (this.Day < 10) {
      this.TempDate = '0' + this.Day;
    }
    else {
      this.TempDate = this.Day;
    }

    if (this.Month < 10) {
      this.Month = '0' + this.Month;
      this.TempDate = this.TempDate + "/" + this.Month;
    }
    else {
      this.TempDate = this.TempDate + "/" + this.Month;
    }
    this.TempDate = this.TempDate + "/" + this.year;
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    let t = localStorage.getItem('userToken');
    t = 'Bearer' + t;
    //this.Telecalls = [];
    //this.DesignationArray=[];
    this.rowData = [];
    this.SalesSrvice.GetTelecalls(t).then(x => {
      this.CRM_Obj_Telecall = x;
      Object.keys(this.CRM_Obj_Telecall).forEach(key => {
        let Crm_Telecall_Temp = new Model_CRM_Telecall();
        Crm_Telecall_Temp = this.CRM_Obj_Telecall[key];
        // this.Telecalls.push(Crm_Telecall_Temp);
        this.y = {
          "Tele_Call_Id": Crm_Telecall_Temp.Tele_Call_Id,

          "Calldate": Crm_Telecall_Temp.CalldateVal,
          "Time": Crm_Telecall_Temp.Time,
          "CorPorateName": Crm_Telecall_Temp.CorPorateName,
          "ClietName": Crm_Telecall_Temp.ClietName,
          "PhoneNo": Crm_Telecall_Temp.PhoneNo,
          "EmailId": Crm_Telecall_Temp.EmailId,
          "CallType": Crm_Telecall_Temp.CallType,
          "Remarks": Crm_Telecall_Temp.Remarks,
          "CreatedBy": Crm_Telecall_Temp.CreatedBy,
        }
        this.rowData.push(this.y);
      })
    }).then(w => {
      if (this.rowData.length == 0) {
        this.CurrentDate = new Date();
        this.y = {
          "Tele_Call_Id": 0,

          "Calldate": this.TempDate,
          "Time": this.Time,
          "CorPorateName": '',
          "ClietName": '',
          "PhoneNo": '',
          "EmailId": '',
          "CallType": 'Tele Call',
          "Remarks": '',
          "CreatedBy": '',
        }
        this.rowData.push(this.y);
        this.gridApi.updateRowData({ add: this.rowData })
      }
      else {
        this.gridApi.updateRowData({ add: this.rowData })
      }
    });
  }

  ngOnInit() {
    this.validdate = false;
  }

  ngAfterViewInit(): void {

  }

  updateRows() {
    let t = localStorage.getItem('userToken');
    t = 'Bearer' + t;
    //this.Telecalls = [];
    //this.DesignationArray=[];
    this.rowData = [];
    this.SalesSrvice.GetTelecalls(t).then(x => {
      this.CRM_Obj_Telecall = x;
      Object.keys(this.CRM_Obj_Telecall).forEach(key => {
        let Crm_Telecall_Temp = new Model_CRM_Telecall();
        Crm_Telecall_Temp = this.CRM_Obj_Telecall[key];
        // this.Telecalls.push(Crm_Telecall_Temp);
        this.y = {
          "Tele_Call_Id": Crm_Telecall_Temp.Tele_Call_Id,

          "Calldate": Crm_Telecall_Temp.CalldateVal,
          "Time": Crm_Telecall_Temp.Time,
          "CorPorateName": Crm_Telecall_Temp.CorPorateName,
          "ClietName": Crm_Telecall_Temp.ClietName,
          "PhoneNo": Crm_Telecall_Temp.PhoneNo,
          "EmailId": Crm_Telecall_Temp.EmailId,
          "CallType": Crm_Telecall_Temp.CallType,
          "Remarks": Crm_Telecall_Temp.Remarks,
          "CreatedBy": Crm_Telecall_Temp.CreatedBy,
        }
        this.rowData.push(this.y);
      })
    }).then(w => {
      if (this.rowData.length == 0) {
        this.CurrentDate = new Date();
        this.y = {
          "Tele_Call_Id": 0,

          "Calldate": this.TempDate,
          "Time": this.Time,
          "CorPorateName": '',
          "ClietName": '',
          "PhoneNo": '',
          "EmailId": '',
          "CallType": 'Tele Call',
          "Remarks": '',
          "CreatedBy": '',
        }
        this.rowData.push(this.y);
      }
      this.gridApi.updateRowData({ add: this.rowData })
    });
  }

  onBtnClickSave(e) {
    this.rowDataClicked1 = e.rowData;
    this.SelectedRow = [];
    this.SelectedRow = Object.values(e.rowData)
    // var arraydata = [];
    // arraydata = this.vv.split(',');
    //alert(this.vv[1]);
    this.TempModel_Telecall = new Model_CRM_Telecall();
    this.TempModel_Telecall.Tele_Call_Id = this.SelectedRow[0];
    this.TempModel_Telecall.Calldate = this.SelectedRow[1];
    this.TempModel_Telecall.CalldateVal = this.SelectedRow[1];
    
    this.TempModel_Telecall.Time = this.SelectedRow[2];
    this.TempModel_Telecall.CorPorateName = this.SelectedRow[3];
    this.TempModel_Telecall.ClietName = this.SelectedRow[4];
    this.TempModel_Telecall.PhoneNo = this.SelectedRow[5];
    this.TempModel_Telecall.EmailId = this.SelectedRow[6];
    this.TempModel_Telecall.CallType = this.SelectedRow[7];
    this.TempModel_Telecall.Remarks = this.SelectedRow[8];
    let t = localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.CRM_LeadService.model_CRM_Shared_lead.IsActive = true;
    this.SalesSrvice.AddUpdateTeleCall(t, this.TempModel_Telecall).then(x => {
      this.telecalldata = x;
      this.TempModel_Telecall = this.telecalldata;
      this.SelectedRow[0] = this.TempModel_Telecall.Tele_Call_Id;
      if (this.TempModel_Telecall.Tele_Call_Id > 0) {
        this.updateRows()
      }
    });
  }

  onBtnClickFollowUp(e, template) {
    this.rowDataClicked1 = e.rowData;
    this.SelectedRow = [];
    this.SelectedRow = Object.values(e.rowData)
    // var arraydata = [];
    // arraydata = this.vv.split(',');
    //alert(this.vv[1]);
    this.TempModel_Telecall = new Model_CRM_Telecall();
    this.salesCallId = this.SelectedRow[0];
    this.SalesSrvice.Telecallid = this.salesCallId;
    if (this.salesCallId) {
      this.modalRef = this.modalService.show(FollowUpModelPopupComponent);
    }
    // this.modalRef.content.onClose.subscribe(result => {
    //   console.log('results', result);
    // })
  }
  // Open(template) {
  //   this.TempModel_Telecall = new Model_CRM_Telecall();
  //   this.salesCallId = this.SelectedRow[0];
  //   this.modelopt = new ModalOptions();
  //   this.modelopt.class = 'heavy_modal';
  //   this.modalService.config = this.modelopt;
  //   this.modalRef = this.modalService.show(template, this.modelopt);
  //   //this.GridShow=true;
  //   this.ShowPopup = true;
  //   //  this.ApprovedMsg=false;
  //   //  this.RejectedMsg=false;
  //   // Begning of the permission portion
  //   //this.IsDataIsInViewState = false;
  //   // End of the permision portion
  //   this.ApprovedMsg = true;
  // }

  AddRows() {
    this.TempRow = [];
    this.CurrentDate = new Date();
    this.Time = '';
    this.Houres = this.CurrentDate.getHours();
    this.Minutes = this.CurrentDate.getMinutes();
    if (this.Houres < 10) {
      this.Time = '0' + this.Houres;
    }
    else {
      this.Time = this.Houres;
    }
    if (this.Minutes < 10) {
      this.Time = this.Time + ':' + '0' + this.Minutes;
    }
    else {
      this.Time = this.Time + ':' + this.Minutes;
    }
    this.TempDate = "";
    this.Day = this.CurrentDate.getDate();
    this.Month = this.CurrentDate.getMonth() + 1;
    this.year = this.CurrentDate.getFullYear();
    if (this.Day < 10) {
      this.TempDate = '0' + this.Day;
    }
    else {
      this.TempDate = this.Day;
    }

    if (this.Month < 10) {
      this.Month = '0' + this.Month;
      this.TempDate = this.TempDate + "/" + this.Month
    }
    else {
      this.TempDate = this.TempDate + "/" + this.Month
    }
    this.TempDate = this.TempDate + "/" + this.year;
    this.y = {
      "Tele_Call_Id": 0,
      "Calldate": this.TempDate,
      "Time": this.Time,
      "CorPorateName": '',
      "ClietName": '',
      "PhoneNo": '',
      "EmailId": '',
      "CallType": 'Tele Call',
      "Remarks": '',
      "CreatedBy": '',
    }
    //this.rowData.push(this.y);
    this.TempRow.push(this.y);
    this.gridApi.updateRowData({ add: this.TempRow })
  }

  GetTeleCalls() {
    let t = localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.Telecalls = [];
    //this.DesignationArray=[];
    this.SalesSrvice.GetTelecalls(t).then(x => {
      this.CRM_Obj_Telecall = x;
      Object.keys(this.CRM_Obj_Telecall).forEach(key => {
        let Crm_Telecall_Temp = new Model_CRM_Telecall();
        Crm_Telecall_Temp = this.CRM_Obj_Telecall[key];
        this.Telecalls.push(Crm_Telecall_Temp);
      })
    })
  }

  FromDateChanged(date) {
    this.FromDate = date;
    var CurrentDate = new Date();
    var CurrentYear = CurrentDate.getFullYear();
    var currentMonth = CurrentDate.getMonth();
    var CurrentDay = CurrentDate.getDate();

    var selecteedDate = new Date(date);
    var selectedYear = selecteedDate.getFullYear();
    var selectedMonth = selecteedDate.getMonth();
    var selectedDay = selecteedDate.getDate();

    if (CurrentYear > selectedYear) {
      this.validdate = false;
    }
    else {
      if (currentMonth > selectedMonth) {
        this.validdate = false;
      }
      else {
        if (CurrentDay >= selectedDay) {
          this.validdate = false;
        }
        else {
          this.validdate = true;
        }
      }
    }
  }

  ToDateChanged(date) {
    this.Todate = date;
    var CurrentDate = new Date();
    var CurrentYear = CurrentDate.getFullYear();
    var currentMonth = CurrentDate.getMonth();
    var CurrentDay = CurrentDate.getDate();

    var selecteedDate = new Date(date);
    var selectedYear = selecteedDate.getFullYear();
    var selectedMonth = selecteedDate.getMonth();
    var selectedDay = selecteedDate.getDate();

    if (CurrentYear > selectedYear) {
      this.validdate = false;
    }
    else {
      if (currentMonth > selectedMonth) {
        this.validdate = false;
      }
      else {
        if (CurrentDay >= selectedDay) {
          this.validdate = false;
        }
        else {
          this.validdate = true;
        }
      }
    }
  }

  DownloadReport() {
    let t = localStorage.getItem('userToken');
    t = 'Bearer' + t;
    if (this.FromDate != null && this.Todate != null) {
      if (this.Todate >= this.FromDate) {
        this.SalesSrvice.GetTelecallReports(t, this.FromDate, this.Todate);
      }
    }
  }
}

function getDatePicker() {
  function Datepicker() { }
  Datepicker.prototype.init = function (params) {
    this.eInput = document.createElement("input");
    this.eInput.value = params.value;
    $(this.eInput).datepicker({ dateFormat: "dd/mm/yy" });
    //$(this.eInput).datetimepicker({ dateFormat: "dd/mm/yy" })
  };
  Datepicker.prototype.getGui = function () {
    return this.eInput;
  };
  Datepicker.prototype.afterGuiAttached = function () {
    this.eInput.focus();
    this.eInput.select();
  };
  Datepicker.prototype.getValue = function () {
    return this.eInput.value;
  };
  Datepicker.prototype.destroy = function () { };
  Datepicker.prototype.isPopup = function () {
    return false;
  };
  return Datepicker;
}

function getTimePicker() {
  function Timepicker() { }
  Timepicker.prototype.init = function (params) {
    this.eInput = document.createElement("input");
    this.eInput.value = params.value;
    $(this.eInput).timepicker();
  };
  Timepicker.prototype.getGui = function () {
    return this.eInput;
  };
  Timepicker.prototype.afterGuiAttached = function () {
    this.eInput.focus();
    this.eInput.select();
  };
  Timepicker.prototype.getValue = function () {
    return this.eInput.value;
  };
  Timepicker.prototype.destroy = function () { };
  Timepicker.prototype.isPopup = function () {
    return false;
  };
  return Timepicker;
}