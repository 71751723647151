import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';  
import { Http, Headers, RequestOptions, Response } from '@angular/http';  
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner'; 
import 'rxjs/add/operator/toPromise';
import { Subscriber } from 'rxjs';
import { GlobalVariable  } from '../../../app.global';


import { Model_CRM_Role } from './Model_CRM_Role';

    


@Injectable()

export class RoleService {

  constructor(private http: HttpClient,private spinnerService: Ng4LoadingSpinnerService) 
  {
  }

  
     
  //  SaveOrUpdate : number =0;


    GetCRMRoles(token )
    {
      //  alert();
        this.spinnerService.show();
        const authHeader = new HttpHeaders();     
        authHeader.append('Content-Type', 'application/json');  
        authHeader.append('Authorization', token);
        return new Promise((resolve, reject) =>
        {
            setTimeout(()=>
            {
                this.http.get(GlobalVariable.BASE_API_URL +'/api/CRMRole/GetCRMRole',
                {headers: authHeader}).subscribe(data => 
                {
                    this.spinnerService.hide();
                    resolve(data)
                },
                error => 
                {
                    this.spinnerService.hide();
                    reject(error);
                },);
            },3000);
        });
    }


    

    GetCRM_Role_By_id(token, id:number ) 
    {
        this.spinnerService.show(); 
        const authHeader = new HttpHeaders();     
        authHeader.append('Content-Type', 'application/json');
        authHeader.append('Authorization', token);
        return new Promise((resolve, reject) => 
        {   
            setTimeout(()=>
            {
                this.http.get(GlobalVariable.BASE_API_URL +'/api/CRMRole/GetCRMRoleById?Role_ID='+id,{headers: authHeader})
                .subscribe(data => 
                {
                    this.spinnerService.hide();
                    resolve(data)
                },
                error => 
                {
                    this.spinnerService.hide();
                    reject(error);
                },);
            },3000);
        });
    }



    AddRoleName(token, ManageRole: Model_CRM_Role) 
    {
        const authHeader = new HttpHeaders();         
        authHeader.append('Content-Type', 'application/json');
        authHeader.append('Authorization', token);  
        authHeader.append('Accept', 'application/json');
        return this.http.post(GlobalVariable.BASE_API_URL +'/api/CrmRole/Add', ManageRole ,{headers: authHeader} )
        .map(this.extractData)
        .catch(this.handleErrorObservable);
    }



    UpdateRoleNameDetails(token, ManageRole: Model_CRM_Role) 
    {
       
        const authHeader = new HttpHeaders();         
        authHeader.append('Content-Type', 'application/json');
        authHeader.append('Authorization', token);
        authHeader.append('Accept', 'application/json');
       
        return this.http.post(GlobalVariable.BASE_API_URL + '/api/CrmRole/Update', ManageRole ,{headers: authHeader} )
        .map(this.extractData)
        .catch(this.handleErrorObservable);
     }

     

//  Delete  a   Record  Starts

    DeleteRoleName( token ,ID : number) 
    {
        //alert();
        
        const authHeader = new HttpHeaders();         
        authHeader.append('Content-Type', 'application/json');
        authHeader.append('Authorization', token);
        authHeader.append('Accept', 'application/json');
        return this.http.put(GlobalVariable.BASE_API_URL +'/api/CRMRole/Delete', ID ,{headers: authHeader} )
        .map(this.extractData)
        .catch(this.handleErrorObservable);
    } 

    

    //  Delete  a  record   Ends    


    

    GetCRMType(token) {
       // alert('OK');
        this.spinnerService.show();
        const authHeader = new HttpHeaders();
        authHeader.append('Content-Type', 'application/json');
        authHeader.append('Authorization', token);
        return new Promise((resolve, reject) => {
            setTimeout(() => {

                this.http.get(GlobalVariable.BASE_API_URL + '/api/CRMType/GetCRMType', { headers: authHeader })
                    .subscribe(data => {
                        this.spinnerService.hide();
                        resolve(data)
                    },
                        error => {
                            this.spinnerService.hide();
                            reject(error);
                        }, );
            }, 3000);
        });
    }



    private extractData(res: Response) 
    {
        let body = res;
        return body || {};
    }
    private handleErrorObservable (error: Response | any) 
    {
        console.error(error.message || error);
        return Observable.throw(error.message || error);
    }


}
