export class EmployeeExprienceModel{

    public ExperienceRefId: Number;
    public ExperinceCompany: string;
    public ExperinceDesignation: string;
    public ExperinceFromDate: any;
    public ExperinceToDate: any;
    public  EmployeeId : Number;
    public  ExperinceFromDatePass :string;
    public  ExperinceToDatePass : string;
}