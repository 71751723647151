export class MenuUserMappingModel
{
    public RefId : number;
    public UserId : number;
    public UserName : string;
    public MenuId : number;
    public MenuName : string;
    public CanAdd : boolean;
    public CanEdit : boolean;
    public CanDelete : boolean;
    public CanApprove : boolean;
    public CanView : boolean;
    public MobileView : boolean;
    public valueAdd:string;
    public valueEdit:string;
    public valueDelete:string;
    public valueApprove:string;
    public valueView:string;
    public valueMobileView:string;
    public RoleId : number;
    public ModuleId : number;
}