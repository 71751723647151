<div class="body-content-area tabcontent" id="link1">
  <div class="row">
    <div class="col-lg-9">
      <div class="row">
        <div class="col-lg-1 low-padding text-center">
          <!-- <img *ngIf="this.SelectedMenu.Image!=null" src={{this.SelectedMenu.Image}} class="body-icon" alt="Icon"/> -->
        </div>
        <div class="col-lg-10 clear-padding">
          <div>
            <span class="page-title">Employee Leave Balance Master</span>
            <small class="page-description"></small>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="!(this.CanViewState)">
    <fieldset class="body-form-control"> 
      <legend class="legend--">Employee Details</legend>
      <form #EmployeeAttendanceForm="ngForm">
        <div class="row">
          <div class="col-lg-4">
            <div class="form-group row">
              <label class="col-sm-2 col-form-label clear-padding-right">Employee</label>
              <div class="col-sm-9 clear-left-padding">
                <angular2-multiselect tabindex="1" class="form-control" [data]="emp" [(ngModel)]="selectedItems" name="EmployeeId"
                  [settings]="EmployeeSettings" (onSelect)="onItemSelect($event)" (onDeSelect)="OnItemDeSelect($event)" (onSelectAll)="onSelectAll($event)"
                  (onDeSelectAll)="onDeSelectAll($event)">
                </angular2-multiselect>
              </div>
              <div class="required-validation" *ngIf="EmployeeModel.EmployeeId == 0">Employee is required</div>
            </div>
          </div>
        </div>
      </form>    
    </fieldset>

    <div *ngIf="NoRecordMsg" style="color: #dc3545; text-align: center;font-size: 15px; font-weight: bold;">No Record Found.</div>
    
    <fieldset class="body-form-control" *ngIf="EmployeeLeaveBalance.length > 0"> 
      <legend class="legend--">Leave Balance Details</legend>
      <table id="tblEmployeeLeaveBalanceDetails" class="table table-striped table-bordered text-center table-new row-border hover">
        <thead class="thead-blue text-center">
          <tr>
            <th>Sl.No.</th>
            <th>Leave Type</th>
            <th>Leave Entitlement</th>
            <th>Leave Taken During the Year</th>
            <th>Opening Balance as of Today</th>
            <th>Leave Applied or pending for Approval</th>
            <th>Available Balance as of Today</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let EmpLeaveBal of EmployeeLeaveBalance, let i = index">
            <td scope="row">{{i+1}}</td>
            <td style=" text-align: left;">{{EmpLeaveBal.LeaveTypeName}}</td>
            <td>{{EmpLeaveBal.NoOfLeave}}</td>
            <td>{{EmpLeaveBal.LeaveTaken}}</td>
            <td>{{EmpLeaveBal.BalanceLeave}}</td>
            <td>{{EmpLeaveBal.LeaveApply}}</td>
            <td>{{EmpLeaveBal.BalanceLeaveAfterApply}}</td>
          </tr>
        </tbody>
        <tfoot class="thead-blue text-center">
          <tr>
            <th></th>
            <th></th>
            <th></th>
            <th></th>
            <th></th>
            <th></th>
            <th></th>
          </tr>
        </tfoot>
      </table>
    </fieldset>

  </div>
</div>