<div class="body-content-area tabcontent" id="link1">
  <div class="row">
    <div class="col-lg-9">
        <div class="row">
            <div class="col-lg-1 low-padding text-center">
                <!-- <img *ngIf="this.SelectedMenu.Image!=null" src={{this.SelectedMenu.Image}} class="body-icon" alt="Icon"/> -->
            </div>
            <div class="col-lg-10 clear-padding">
                <span class="page-title">Generate Tax Slip</span>
                <small class="page-description"></small>
            </div>
        </div>
    </div>
  </div>
  <div *ngIf="!(this.CanViewState)">
    <fieldset class="body-form-control" > 
    <legend class="legend--"></legend>
      <form #EmployeepayslipForm="ngForm">
        <div class="row">
          <div class="col-lg-4">
            <div class="col-body-border-right">
              <div class="form-group row">
                <label class="col-sm-4 col-form-label clear-padding-right">Year</label>
                <div class="col-sm-7 clear-left-padding">
                  <select [(ngModel)]="EmployeeMonthelyAttendenceModel.Year" id ="Year" class="form-control" name="Year" #Year="ngModel" (ngModelChange)="onchangeFinYear($event)">
                    <option [value]="0" [disabled]=true >Select Year</option>
                    <option *ngFor="let FY of FinancialYears" [value]="FY.Year">{{FY.Year}}</option>
                  </select>
                </div>
                <div class="required-validation" *ngIf="EmployeeMonthelyAttendenceModel.Year==0">Year is required</div>
              </div>
            </div>
          </div>
          <div class="col-lg-4">
            <div class="col-body-border-right">
              <div class="form-group row">
                <label class="col-sm-4 col-form-label clear-padding-right">Month</label>
                <div class="col-sm-7 clear-left-padding">
                  <select [(ngModel)]="EmployeeMonthelyAttendenceModel.MonthId" (ngModelChange)="onchangeMonth($event)" id ="MonthId" class="form-control" name="MonthId" #MonthId="ngModel" >
                    <option [value]="0" [disabled]=true >Select Month</option>
                    <option *ngFor="let Mo of Months" [value]="Mo.MonthId">{{Mo.MonthName}}</option>
                  </select>
                </div>
                <div class="required-validation" *ngIf="EmployeeMonthelyAttendenceModel.MonthId==0">Month is required</div>
              </div>
            </div>
          </div>
          <div class="col-lg-4">
            <div class="form-group row" >
              <label class="col-sm-2 col-form-label clear-padding-right">Employee</label>
              <div class="col-sm-9 clear-left-padding">
                <angular2-multiselect tabindex="1" class="form-control" [data]="emp" [(ngModel)]="selectedItems" name="EmployeeId" 
                  [settings]="EmployeeSettings" (onSelect)="onItemSelect($event)" (onDeSelect)="OnItemDeSelect($event)" (onSelectAll)="onSelectAll($event)"
                  (onDeSelectAll)="onDeSelectAll($event)">
                </angular2-multiselect>
              </div>
              <div class="required-validation" *ngIf="EmployeeMonthelyAttendenceModel.EmployeeId == 0">Employee is required</div>
            </div>  
            <div class="form-group row">
              <label class="col-sm-5 col-form-label clear-padding-right">
                <button type="submit" tabindex="5" (click)="GetEmployeeAttendanceDetails()" class="btn btn-primary save-button" [disabled]="!(EmployeepayslipForm.valid && EmployeeMonthelyAttendenceModel.MonthId > 0 && EmployeeMonthelyAttendenceModel.Year > 0 && EmployeeMonthelyAttendenceModel.EmployeeId > 0 && this.DisableSave == true)">Generate Payslip</button>
              </label>
              <div class="col-sm-5 clear-left-padding" *ngIf="this.EmployeeMonthelyAttendenceModelResult.GrossSalary > 0">
                <button type="submit" class="btn btn-danger cancle-button" (click)="print()">Print Payslip</button>
              </div>
            </div>
          </div>
        </div>
      </form>    
    </fieldset>
    <div *ngIf="NoRecordMsg" style="color: #dc3545; text-align: center;font-size: 15px; font-weight: bold;">No Record Found.</div>
    <fieldset class="body-form-control" *ngIf="this.EmployeeMonthelyAttendenceModelResult.GrossSalary > 0">
      <legend class="legend--"></legend>
      <div style="padding-left:15%;padding-right:15%" Id="Payslip">
        <table border="1px" style="width:100%; font-size: 9px;">
          <tr>
            <td style="width:100%;">
              <table border="0" style="width:100%; font-size: 10px;">
                <tr>
                  <td style="width:80%; vertical-align: top;" >
                    <span style="font-weight: bold; font-size: 10px;">&nbsp;{{this.EmployeeMonthelyAttendenceModelResult.CompanyName}}</span><br>
                    <span style="font-size: 10px;">&nbsp;{{this.EmployeeMonthelyAttendenceModelResult.Address1}}, {{this.EmployeeMonthelyAttendenceModelResult.Address2}},</span><br>
                    <span style="font-size: 10px;">&nbsp;{{this.EmployeeMonthelyAttendenceModelResult.City}} - {{this.EmployeeMonthelyAttendenceModelResult.PinCode}}</span>
                  </td>
                  <td style="padding-left:10%; width:20%;">
                    <img *ngIf="this.EmployeeMonthelyAttendenceModelResult.LogoImage!=null" [src]="this.EmployeeMonthelyAttendenceModelResult.LogoImage" style="width:100%;">&nbsp;
                  </td>
                </tr>
              </table>
            </td>
          </tr>
  
          <tr>
            <td>
              <table border="0" style="font-weight: bold; font-size: 10px;">
                <tr>
                  <td>
                    &nbsp;TAX SLIP FOR THE MONTH OF {{this.EmployeeMonthelyAttendenceModelResult.MonthName}}&nbsp;{{this.EmployeeMonthelyAttendenceModelResult.CurrentYear}}
                  </td>
                </tr>
              </table>
            </td>
          </tr>
          
          <tr>
            <td>
              <table border="1" style="width:100%; font-size: 9px;">
                <tr style="border-left: 0%;">
                  <td style="width:15%;border-left: 0%;">&nbsp;EMPLOYEE ID</td>
                  <td style="width:50%;">&nbsp;{{this.EmployeeMonthelyAttendenceModelResult.EmployeeCode}}</td>
                  <td style="width:15%;">&nbsp;PF No</td>
                  <td style="width:20%;">&nbsp;{{this.EmployeeMonthelyAttendenceModelResult.EPFNO}}</td>
                </tr>
                <tr style="border-left: 0px;">
                  <td style="border-left: 0%;">&nbsp;EMPLOYEE NAME</td>
                  <td>&nbsp;{{this.EmployeeMonthelyAttendenceModelResult.EmployeeName}}</td>
                  <td>&nbsp;UAN No</td>
                  <td>&nbsp;{{this.EmployeeMonthelyAttendenceModelResult.UANNo}}</td>
                </tr>
                <tr style="border-left: 0px;">
                  <td>&nbsp;DATE OF JOINING</td>
                  <td>&nbsp;{{this.EmployeeMonthelyAttendenceModelResult.JoiningDate | date : "dd-MMM-yyyy"}}</td>
                  <td>&nbsp;DATE OF BIRTH</td>
                  <td>&nbsp;{{this.EmployeeMonthelyAttendenceModelResult.DOB | date : "dd-MMM-yyyy"}}</td>
                </tr>
                <tr style="border-left: 0px;">
                  <td>&nbsp;DESIGNATION</td>
                  <td>&nbsp;{{this.EmployeeMonthelyAttendenceModelResult.DesignationName}}</td>
                  <td>&nbsp;BANK NAME</td>
                  <td>&nbsp;{{this.EmployeeMonthelyAttendenceModelResult.BankName}}</td>
                </tr>
                <tr style="border-left: 0px;">
                  <td>&nbsp;LOCATION</td>
                  <td>&nbsp;{{this.EmployeeMonthelyAttendenceModelResult.Branchname}}</td>
                  <td>&nbsp;ACCOUNT NO</td>
                  <td>&nbsp;{{this.EmployeeMonthelyAttendenceModelResult.AccountNo}}</td>
                </tr>
                <tr style="border-left: 0px;">
                  <td>&nbsp;DEPARTMENT</td>
                  <td>&nbsp;{{this.EmployeeMonthelyAttendenceModelResult.DepartmentName}}</td>
                  <td>&nbsp;PAYABLE DAYS</td>
                  <td>&nbsp;{{this.EmployeeMonthelyAttendenceModelResult.FinalAttendence}}</td>
                </tr>
                <tr style="border-left: 0px;">
                  <td>&nbsp;PAN</td>
                  <td>&nbsp;{{this.EmployeeMonthelyAttendenceModelResult.PANNo}}</td>
                  <td>&nbsp;LOP DAYS</td>
                  <td>&nbsp;{{this.EmployeeMonthelyAttendenceModelResult.FinalLopNo}}</td>
                </tr>
                <tr style="border-left: 0px;">
                  <td>&nbsp;</td>
                  <td></td>
                  <td>ATTENDANCE CYCLE</td>
                  <td>&nbsp;{{this.EmployeeMonthelyAttendenceModelResult.FromDate}}&nbsp;to&nbsp;{{this.EmployeeMonthelyAttendenceModelResult.ToDate}}</td>
                </tr>
              </table>
            </td>
          </tr>
  
          <tr>
            <td>
              <table border="1" style="width:100%; font-size: 9px;">
                <tr border="1" style="border-left: 0%;">
                  <td style="width:65%; border-left: 0%; vertical-align: top;"> 
                    <table style="width:100%; vertical-align: top; font-size: 9px;">
                      <tr>
                        <td style="width:50%; border-left: 0%; font-weight: bold;">&nbsp;<u>DESCRIPTION</u></td>
                        <td style="width:25%; border-left: 0%; text-align: right; font-weight: bold;"><u>MONTHLY</u>&nbsp;</td>
                        <td style="width:25%; border-left: 0%; text-align: right; font-weight: bold;"><u>YEARLY</u>&nbsp;</td>
                      </tr>
  
                      <tr *ngFor="let Earnings of this.EmployeeMonthelyAttendenceModelResult.SalaryCompList">
                        <td style="width:50%; border-left: 0%; font-size: 8px;">&nbsp;{{Earnings.SalaryComponentName}}</td>
                        <td style="width:25%; border-left: 0%; text-align: right;">{{Earnings.Amount | number:'2.0-0'}}.00&nbsp;</td>
                        <td style="width:25%; border-left: 0%; text-align: right;">{{Earnings.YearlyAmount | number:'2.0-0'}}.00&nbsp;</td>
                      </tr>
                    </table>
                  </td>
                
                  <td style="width:35%; border-left: 0%; vertical-align: text-top;">
                    <table style="width:100%; font-size: 9px;">
                      <tr border="1" style="border-left: 0%;">
                        <td>
                          <table style="width:100%; vertical-align:top; font-size: 9px;">
                            <tr>
                              <td style="width:50%;border-left: 0%; font-weight: bold;">&nbsp;<u>DESCRIPTIONS</u></td>
                              <td style="width:50%;border-left: 0%; text-align: right; font-weight: bold;"><u>AMOUNT(INR)</u>&nbsp;</td>
                            </tr>
                            <tr *ngIf="this.EmployeeMonthelyAttendenceModelResult.PF > 0">
                              <td style="width:50%;border-left: 0%;">&nbsp;EPF DEDUCTION</td>
                              <td style="width:50%;border-left: 0%; text-align: right;">{{this.EmployeeMonthelyAttendenceModelResult.PF | number:'2.0-0'}}.00&nbsp;</td>
                            </tr>
                            <tr *ngIf="this.EmployeeMonthelyAttendenceModelResult.Ptax > 0">
                              <td style="width:50%;border-left: 0%;">&nbsp;PROFESSION TAX</td>
                              <td style="width:50%;border-left: 0%; text-align: right;">{{this.EmployeeMonthelyAttendenceModelResult.Ptax | number:'2.0-0'}}.00&nbsp;</td>
                            </tr>
                            <tr *ngIf="this.EmployeeMonthelyAttendenceModelResult.TDS > 0">
                              <td style="width:50%;border-left: 0%;">&nbsp;TDS</td>
                              <td style="width:50%;border-left: 0%; text-align: right;">{{this.EmployeeMonthelyAttendenceModelResult.TDS | number:'2.0-0'}}.00&nbsp;</td>
                            </tr>
                            <tr *ngIf="this.EmployeeMonthelyAttendenceModelResult.Advance > 0">
                              <td style="width:50%;border-left: 0%;">&nbsp;ADVANCE SALARY</td>
                              <td style="width:50%;border-left: 0%; text-align: right;">{{this.EmployeeMonthelyAttendenceModelResult.Advance | number:'2.0-0'}}.00&nbsp;</td>
                            </tr>
                            <tr *ngIf="this.EmployeeMonthelyAttendenceModelResult.Loan > 0">
                              <td style="width:50%;border-left: 0%;">&nbsp;LOAN AMOUNT</td>
                              <td style="width:50%;border-left: 0%; text-align: right;">{{this.EmployeeMonthelyAttendenceModelResult.Loan | number:'2.0-0'}}.00&nbsp;</td>
                            </tr>
                            <tr *ngIf="this.EmployeeMonthelyAttendenceModelResult.MediClame > 0">
                              <td style="width:50%;border-left: 0%;">&nbsp;MEDICLAIM</td>
                              <td style="width:50%;border-left: 0%; text-align: right;">{{this.EmployeeMonthelyAttendenceModelResult.MediClame | number:'2.0-0'}}.00&nbsp;</td>
                            </tr>
                            <tr *ngIf="this.EmployeeMonthelyAttendenceModelResult.OtherDeduction > 0">
                              <td style="width:50%;border-left: 0%;">&nbsp;OTHER DEDUCTION</td>
                              <td style="width:50%;border-left: 0%; text-align: right;">{{this.EmployeeMonthelyAttendenceModelResult.OtherDeduction | number:'2.0-0'}}.00&nbsp;</td>
                            </tr>
                            <tr *ngIf="this.EmployeeMonthelyAttendenceModelResult.Donation > 0">
                              <td style="width:50%;border-left: 0%;">&nbsp;DONATION</td>
                              <td style="width:50%;border-left: 0%; text-align: right;">{{this.EmployeeMonthelyAttendenceModelResult.Donation | number:'2.0-0'}}.00&nbsp;</td>
                            </tr>
                            <tr *ngIf="this.EmployeeMonthelyAttendenceModelResult.LOPAmount > 0" >
                              <td style="width:50%;border-left: 0%;">&nbsp;LOP AMOUNT</td>
                              <td style="width:50%;border-left: 0%; text-align: right;">{{this.EmployeeMonthelyAttendenceModelResult.LOPAmount | number:'2.0-0'}}.00&nbsp;</td>
                            </tr>
                          </table>
                        </td>
                      </tr>
                    </table>
                  </td> 
                </tr>
              </table>
            </td>
          </tr>
  
          <tr>
            <td>
              <table border="1" style="width:100%; font-size: 9px;">
                <tr>
                  <td style="width:65%; border-left: 0%;">
                    <table style="width:100%; font-size: 9px;">
                      <tr>
                        <td style="width:50%;border-left: 0%; font-weight: bold;">&nbsp;GROSS SALARY</td>
                        <td style="width:25%;border-left: 0%; text-align: right; font-weight: bold;">{{this.EmployeeMonthelyAttendenceModelResult.TotalIncome| number:'2.0-0'}}.00&nbsp;</td>
                        <td style="width:25%;border-left: 0%; text-align: right; font-weight: bold;">{{this.EmployeeMonthelyAttendenceModelResult.TotalYearlyIncome| number:'2.0-0'}}.00&nbsp;</td>
                      </tr>
                    </table>
                  </td>
                  <td style="width:35%; border-left: 0%;">
                    <table style="width:100%; font-size: 9px;">
                      <tr>
                        <td style="width:50%;border-left: 0%; font-weight: bold;">&nbsp;TOTAL DEDUCTION</td>
                        <td style="width:50%;border-left: 0%; text-align: right; font-weight: bold;">{{this.EmployeeMonthelyAttendenceModelResult.TotalDeduction| number:'2.0-0'}}.00&nbsp;</td>
                      </tr>
                    </table>
                  </td>
                </tr>
              </table>
            </td>
          </tr>
  
          <tr>
            <table border="1" style="width:100%; font-size: 9px;">
              <tr>
                <td style="font-weight: bold;font-size: 9px;">
                    &nbsp;NET PAY&nbsp;&nbsp;&nbsp;:&nbsp;&nbsp;Rs.&nbsp;{{this.EmployeeMonthelyAttendenceModelResult.NetTakeHome | number:'2.0-0'}}.00
                  <!-- <br> -->
                </td>
                <td style="font-weight: bold;font-size: 8px;">
                  &nbsp;IN WORDS :&nbsp;&nbsp;{{this.EmployeeMonthelyAttendenceModelResult.NetTakeHomeINWORD}}
                </td>
              </tr>
            </table>
          </tr>
  
          <tr>
            <table border="1" style="width:100%; font-weight: bold;font-size: 10px;">
              <tr>
                <td style="text-align: center">
                  YEARLY CALCULATION WITH TAX LIABILITY
                </td>
              </tr>
            </table>
          </tr>

          <tr>
            <td>
              <table border="1" style="width:100%; font-size: 9px;">
                <tr border="1" style="border-left: 0%;">
                  <td style="width:65%; border-left: 0%; vertical-align: top;"> 
                    <table style="width:100%; vertical-align: top; font-size: 9px;">
                      <tr>
                        <td style="width:40%; border-left: 0%; font-weight: bold;">&nbsp;<u>DESCRIPTIONS</u></td>
                        <td style="width:20%; border-left: 0%; text-align: right; font-weight: bold;"><u>YEARLY</u>&nbsp;</td>
                        <td style="width:20%; border-left: 0%; text-align: right; font-weight: bold;"><u>EXEMPTION</u>&nbsp;</td>
                        <td style="width:20%; border-left: 0%; text-align: right; font-weight: bold;"><u>TAXABLE</u>&nbsp;</td>
                      </tr>
  
                      <tr *ngFor="let Taxable of this.EmployeeMonthelyAttendenceModelResult.TaxableComponentList">
                        <td style="border-left: 0%; font-size: 8px;">&nbsp;{{Taxable.SalaryComponentName}}</td>
                        <td style="border-left: 0%; text-align: right;">{{Taxable.YearlyAmount | number:'2.0-0'}}.00&nbsp;</td>
                        <td style="border-left: 0%; text-align: right;">{{Taxable.ExemptedAmount | number:'2.0-0'}}.00&nbsp;</td>
                        <td style="border-left: 0%; text-align: right;">{{Taxable.TaxableAmount | number:'2.0-0'}}.00&nbsp;</td>
                      </tr>
                    </table>
                  </td>
                
                  <td style="width:35%; border-left: 0%; vertical-align: text-top;">
                    <!-- <table border="1" style="width:100%; font-size: 9px;">
                      <tr>
                        <td colspan="2" style="font-weight: bold; text-align: center;">DEDUCTION UNDER CHAPTER VI-A</td>
                      </tr>
                      <tr>
                        <td style="width: 60%;">U/S 80C,80CCC & 80CCD</td>
                        <td style="text-align: right; width: 40%;">{{this.EmployeeMonthelyAttendenceModelResult.SEC80C_80CCC_80CCD| number:'2.0-0'}}.00&nbsp;</td>
                      </tr>
                      <tr>
                        <td style="font-weight: bold;">TOTAL</td>
                        <td style="text-align: right; font-weight: bold;">{{this.EmployeeMonthelyAttendenceModelResult.SEC80C_80CCC_80CCD| number:'2.0-0'}}.00&nbsp;</td>
                      </tr>
                    </table> -->

                    <table *ngIf="this.EmployeeMonthelyAttendenceModelResult.Under80CList.length > 0" border="1" style="width:100%; font-size: 9px;">
                      <tr>
                        <td colspan="2" style="font-weight: bold; text-align: center;">INVESTMENT U/S 80C</td>
                      </tr>
                      <tr *ngFor="let US80C of this.EmployeeMonthelyAttendenceModelResult.Under80CList">
                        <td style="width: 60%; border-left: 0%; font-size: 8px;">{{US80C.SalaryComponentName}}</td>
                        <td style="width: 40%; border-left: 0%; text-align: right;">{{US80C.Amount | number:'2.0-0'}}.00&nbsp;</td>
                      </tr>
                    </table>

                    <table border="1" style="width:100%; font-size: 9px;">
                      <tr>
                        <td colspan="2" style="font-weight: bold; text-align: center;">HRA CALCULATION</td>
                      </tr>
                      <tr>
                        <td style="width: 60%;">RENT PAID FOR THE YEAR</td>
                        <td style="text-align: right; width: 40%;">{{this.EmployeeMonthelyAttendenceModelResult.RentPaid| number:'2.0-0'}}.00&nbsp;</td>
                      </tr>
                      <tr>
                        <td>HRA SALARY (BASIC)</td>
                        <td style="text-align: right;">{{this.EmployeeMonthelyAttendenceModelResult.BasicYearly| number:'2.0-0'}}.00&nbsp;</td>
                      </tr>
                      <tr>
                        <td>1. ACTUAL HRA ALLOWANCE</td>
                        <td style="text-align: right;">{{this.EmployeeMonthelyAttendenceModelResult.HRAYearly| number:'2.0-0'}}.00&nbsp;</td>
                      </tr>
                      <tr>
                        <td>2. RENT > 10% OF BASIC</td>
                        <td style="text-align: right;">{{this.EmployeeMonthelyAttendenceModelResult.RENTBASIC| number:'2.0-0'}}.00&nbsp;</td>
                      </tr>
                      <tr>
                        <td>3. 50% OF BASIC SALARY</td>
                        <td style="text-align: right;">{{this.EmployeeMonthelyAttendenceModelResult.HRAFIFTYPER| number:'2.0-0'}}.00&nbsp;</td>
                      </tr>
                      <tr>
                        <td style="font-weight: bold;">LEAST IN ABOVE FOR EXEMPT</td>
                        <td style="font-weight: bold; text-align: right;">{{this.EmployeeMonthelyAttendenceModelResult.LEASTEXEMPT| number:'2.0-0'}}.00&nbsp;</td>
                      </tr>
                    </table>

                  </td> 
                </tr>
              </table>
            </td>
          </tr>

          <tr>
            <td>
              <table border="1" style="width:100%; font-size: 9px;">
                <tr>
                  <td style="width:65%; border-left: 0%;">
                    <table style="width:100%; font-size: 9px;">
                      <tr>
                        <td style="width:40%;border-left: 0%; font-weight: bold;">&nbsp;TOTAL</td>
                        <td style="width:20%;border-left: 0%; text-align: right; font-weight: bold;">{{this.EmployeeMonthelyAttendenceModelResult.TotalYearlyIncome| number:'2.0-0'}}.00&nbsp;</td>
                        <td style="width:20%;border-left: 0%; text-align: right; font-weight: bold;">{{this.EmployeeMonthelyAttendenceModelResult.TotalExemptedAmount| number:'2.0-0'}}.00&nbsp;</td>
                        <td style="width:20%;border-left: 0%; text-align: right; font-weight: bold;">{{this.EmployeeMonthelyAttendenceModelResult.TotalTaxableAmount| number:'2.0-0'}}.00&nbsp;</td>
                      </tr>
                    </table>
                  </td>
                  <td style="width:35%; border-left: 0%;">
                    <table style="width:100%;">
                      <tr>
                        <td>&nbsp;</td>
                      </tr>
                    </table>
                  </td>
                </tr>
              </table>
            </td>
          </tr>

          <tr>
            <table border="1" style="width:100%; font-size: 9px;">
              <tr>
                <td colspan="2" style="border-left: 0%; font-weight: bold; text-align: center;">DEDUCTION & INVESTMENT REIMBURSEMENT</td>
                <td colspan="2" style="border-left: 0%; font-weight: bold; text-align: center;">INCOME TAX SLAB</td>
              </tr>
              <tr>
                <td style="width: 50%">DEDUCTION UNDER CHAPTER VI-A (80C,INSURANCE,PF,ELSS,ETC)</td>
                <td style="width: 15%; text-align: right;">{{this.EmployeeMonthelyAttendenceModelResult.TotalSEC80C| number:'2.0-0'}}.00</td>
                <td rowspan="6" colspan="2" style="width: 35%;">
                  <table style="width:100%; font-size: 9px;">
                    <tr *ngFor="let tdsslab of this.EmployeeMonthelyAttendenceModelResult.TDSSlab">
                      <td style="width: 60%; border-left: 0%; font-size: 8px;">{{tdsslab.SalaryComponentName}}</td>
                      <td style="width: 40%; border-left: 0%; text-align: right;">{{tdsslab.SalaryComponentCode}}&nbsp;</td>
                    </tr>
                  </table>
                </td>
              </tr>
              <tr>
                <td>TAX REBATE UNDER SCTION 80CCC</td>
                <td style="text-align: right;">{{this.EmployeeMonthelyAttendenceModelResult.MutaulFund_80CCC| number:'2.0-0'}}.00</td>
              </tr>
              <tr>
                <td>TAX REBATE UNDER SCTION 80CCD</td>
                <td style="text-align: right;">{{this.EmployeeMonthelyAttendenceModelResult.EPP_80CCD| number:'2.0-0'}}.00</td>
              </tr>
              <tr>
                <td>TAX REBATE UNDER SCTION 80 D</td>
                <td style="text-align: right;">{{this.EmployeeMonthelyAttendenceModelResult.MIParents_80D| number:'2.0-0'}}.00</td>
              </tr>
              <tr>
                <td>TAX REBATE UNDER SCTION 88 E</td>
                <td style="text-align: right;">{{this.EmployeeMonthelyAttendenceModelResult.IPEL_80E| number:'2.0-0'}}.00</td>
              </tr>
              <tr>
                <td>TAX REBATE UNDER SCTION 88 G</td>
                <td style="text-align: right;">{{this.EmployeeMonthelyAttendenceModelResult.Donation_80G| number:'2.0-0'}}.00</td>
              </tr>
              <tr>
                <td style="width: 50%">TAX REBATE UNDER SCTION 88TTA</td>
                <td style="width: 15%; text-align: right;">{{this.EmployeeMonthelyAttendenceModelResult.SEC80TTA_IRSBAc| number:'2.0-0'}}.00</td>
                <td style="width: 20%;">EDUCATION CESS</td>
                <td style="width: 15%; text-align: right;">{{this.EmployeeMonthelyAttendenceModelResult.EducationCess}} %</td>
              </tr>
              <tr>
                <td style="width: 50%">TAX REBATE UNDER SECTION 24</td>
                <td style="width: 15%; text-align: right;">{{this.EmployeeMonthelyAttendenceModelResult.SEC24_IPHL| number:'2.0-0'}}.00</td>
                <td>SURCHARGE & EDUCATION CESS</td>
                <td style="text-align: right;">{{this.EmployeeMonthelyAttendenceModelResult.EducationCessAmount| number:'2.0-0'}}.00</td>
              </tr>
              <tr>
                <td style="width: 50%">TAX REBATE FOR PROFESSIONAL TAX</td>
                <td style="width: 15%; text-align: right;">{{this.EmployeeMonthelyAttendenceModelResult.TotalPtaxAmount| number:'2.0-0'}}.00</td>
                <td>TAX LIABILITY</td>
                <td style="text-align: right;">{{this.EmployeeMonthelyAttendenceModelResult.TAXAmount| number:'2.0-0'}}.00</td>
              </tr>
              <tr>
                <td>TOTAL TAXABLE INCOME WITHOUT VARIABLE</td>
                <td style="text-align: right;">{{this.EmployeeMonthelyAttendenceModelResult.ActualTaxableAmount| number:'2.0-0'}}.00</td>
                <td>ROUND OFF TAX PAYABLE</td>
                <td style="text-align: right;">{{this.EmployeeMonthelyAttendenceModelResult.TaxPayableAmount| number:'2.0-0'}}.00</td>
              </tr>
              <tr>
                <td>CURRENT MONTH TAX PAID</td>
                <td style="text-align: right;">{{this.EmployeeMonthelyAttendenceModelResult.TDSCurrentMonth| number:'2.0-0'}}.00</td>
                <td>TOTAL TAX PAID</td>
                <td style="text-align: right;">{{this.EmployeeMonthelyAttendenceModelResult.TotalTDSPaid| number:'2.0-0'}}.00</td>
              </tr>
              <tr>
                <td>BACK MONTH TAX PAID</td>
                <td style="text-align: right;">{{this.EmployeeMonthelyAttendenceModelResult.TDSPaid| number:'2.0-0'}}.00</td>
                <td>REMAINING TAX </td>
                <td style="text-align: right;">{{this.EmployeeMonthelyAttendenceModelResult.TDSRemaining| number:'2.0-0'}}.00</td>
              </tr>
            </table>
          </tr>

          <tr>
            <table border="1" style="width:100%; font-size: 9px;">
              <tr>
                <td style="text-align: center">* COMPUTER GENERATED SALARY SLIP. SIGNATURE NOT REQUIRED. *</td>
              </tr>
            </table>
          </tr>
        </table>
      </div>
    </fieldset>
    <br>
  </div>
</div>