import { Data } from "@angular/router";

export class  EmployeeEducationalModel     {

    public EducationRefId: Number;
    
   public  EmployeeId :Number;
    public DegreeName: string;
    public DegreeCode: string;
    public Institute: string;
    public BoardUniversity: string;
    public FromDate: Date;
    public ToDate: Date;
    public FromDatepass: string;
    public ToDatepass: string;
    public MarksPercentage: string;
    public Division: string;

}