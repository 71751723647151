import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs/Subject';
import { OrganizationService}  from '../shared/Organization/organization.service';
import { Branch } from  '../shared/Organization/Branch.Model';
import { Country } from '../shared/Organization/Country.Model';
import { State } from '../shared/Organization/State.Model';
import { City } from '../shared/HR_ADMIN/Location/City.Model';
import { Zone } from '../shared/Organization/Zone.Model';
import { DataTableDirective } from 'angular-datatables';
import { Company } from '../shared/Organization/Company.Model';
import { UserService } from '../shared/user.service';
// Begning Confirmation
import { ConfirmationService } from '@jaspero/ng2-confirmations';
import { ResolveEmit } from '../../app/resolve-emit';
import { ConfirmSettings } from '../../app/confirm-settings';
// end of Confirmation
import { Menu } from '../shared/menu.model';

@Component({
  selector: 'app-manage-branch',
  templateUrl: './manage-branch.component.html',
  styleUrls: ['./manage-branch.component.css']
})
export class ManageBranchComponent implements OnInit 
{
  BranchModel : Branch= new Branch();
  userClaims: any;
  Branches: Branch[] = [];
  Cities : City[]=[];
  Companies : Company[]=[];
  Countries : Country[]=[];
  States : State[]=[];
  Zones : Zone[] = [];
  Bran: any;
  City : any;
  State : any;
  Zone : any;
  country : any;
  company : any;
  Result : string;
  SuccessStatus:boolean=false;
  SuccessMessage: string ="";
  public tableWidget: any;
  BranchId : any;
  tempBranchadd : Branch =new Branch();

  // Begning Permission Portion    
  SelectedMenu:Menu;
  IsDataIsInViewState : boolean=false;
  CanViewState : boolean = false;
  DisableSave : boolean=false;
  // End of the permission portion
  //check for duplicate Name
  CheckNameExiststatus : boolean=null;  
  CheckNamestatusedit : boolean =false;
  Namecheck : string ="";
  checkName : any;
  checkNameval : string ="";
  //check for duplicate Name
  FormViewShow : boolean =false;
  CanSaveButtonView : boolean =false;

  constructor(private organizationService: OrganizationService, private rout: Router,
    private _confirmation: ConfirmationService, private userService: UserService)  { }

  @ViewChild(DataTableDirective, {static: false})
  setDtOptions: DataTables.Settings = {};
  cardDtOptions: DataTables.Settings = {};
  dtElement: DataTableDirective;
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();

  ngOnInit() 
  {
    // Begning Permission Portion 
    this.SelectedMenu=this.userService.SelectedMenu;
    if(this.SelectedMenu !=undefined)
    {
      localStorage.setItem('selectedmenu',JSON.stringify(this.SelectedMenu));
      this.rout.navigate([this.SelectedMenu.RouteName]);
    }
    else
    {
      if(JSON.parse(localStorage.getItem('selectedmenu'))!=undefined)
      {
        this.SelectedMenu = JSON.parse(localStorage.getItem('selectedmenu'));
        this.rout.navigate([this.SelectedMenu.RouteName]);
      }
    }
    if(this.SelectedMenu.CanAdd)
    {
      this.IsDataIsInViewState=false;
      this.CanSaveButtonView=true;
    }
    else
    {
      this.IsDataIsInViewState=true;
    }
    if(this.SelectedMenu.CanView)
    {
      this.CanViewState=false;
    }
    else
    {
      this.CanViewState=true;
    }
    // End of the permission portion
    this.DisableSave=true;
    this.getCompanies();
    this.getCountries();
    this.dtOptions = 
    {
      pagingType: 'full_numbers',
      pageLength: 7
    };
    this.BranchModel.CountryId=0;
    this.BranchModel.CompId=0;
    if(localStorage.getItem('SuccessfullMessage'))
    {
      this.SuccessStatus=true;
      this.SuccessMessage =localStorage.getItem('SuccessfullMessage')
      localStorage.removeItem('SuccessfullMessage');
    }
    this.FormViewShow=false;
  }

  settings: ConfirmSettings | any = 
  {
    overlay: true,
    overlayClickToClose: true,
    showCloseButton: true,
    confirmText: 'Yes',
    declineText: 'No'
  };

  ngAfterViewInit(): void 
  {
    this.getBranches();
    this.dtOptions = 
    {
      pagingType: 'full_numbers',
      pageLength: 7
    };
  }

  getBranches()
  {
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.organizationService.GetBranch( t).then(x => 
    {
      this.Bran = x;
      Object.keys(this.Bran).forEach( key => 
      {
        // tslint:disable-next-line:no-unused-expression
        this.Bran[key];
        let Branchtemp = new Branch();
        Branchtemp = this.Bran[key];
        this.Branches.push(Branchtemp);
      });
      this.dtTrigger.next();
    });
  }

  GetBranchByid(id) 
  {
     // begning of the permission portion
    this.IsDataIsInViewState=false;
     // end of the permission portion
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.organizationService.GetBranchByid( t,id).then(x => 
    {
      this.FormViewShow=true;
      this.CanSaveButtonView=false;
      this.Bran = x;
      Object.keys(this.Bran).forEach( key => 
      {
        // tslint:disable-next-line:no-unused-expression
        this.Bran[key];
        let Branchtemp = new Branch();
        Branchtemp = this.Bran[key];
        this.BranchModel.BranchId=Branchtemp.BranchId;
        this.BranchModel.BranchCode=Branchtemp.BranchCode;
        this.BranchModel.BranchName=Branchtemp.BranchName;
        this.BranchModel.CompId=Branchtemp.CompId;
        this.BranchModel.Address1=Branchtemp.Address1;          
        this.BranchModel.Address2=Branchtemp.Address2;
        this.BranchModel.CountryId=Branchtemp.CountryId;
        this.BranchModel.StateId=Branchtemp.StateId;
        this.BranchModel.CityId=Branchtemp.CityId;
        this.BranchModel.PostCode=Branchtemp.PostCode;
        this.BranchModel.PhoneNo1=Branchtemp.PhoneNo1;
        this.BranchModel.PhoneNo2=Branchtemp.PhoneNo2;
        this.BranchModel.Website=Branchtemp.Website;
        this.BranchModel.Fax=Branchtemp.Fax;
        this.BranchModel.PANNumber=Branchtemp.PANNumber;
        this.BranchModel.Email=Branchtemp.Email;
        this.BranchModel.RegistrationNumber=Branchtemp.RegistrationNumber;
        this.BranchModel.LogoPath=Branchtemp.LogoPath;
      });
      this.getStates(this.BranchModel.CountryId);
      this.getCities(this.BranchModel.StateId); 
      // Begin for check Name duplication
      this.checkNameval= this.BranchModel.BranchName;
      this.CheckNamestatusedit=true;
      this.CheckNameExiststatus =true;
      this.Namecheck=""
      // End for check Name duplication
    });
  }
  
  viewCompanByid(id) 
  {
    this.IsDataIsInViewState=true;
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.organizationService.GetBranchByid( t,id).then(x => 
    {
      this.FormViewShow=true;
      this.CanSaveButtonView=false;
      this.Bran = x;
      Object.keys(this.Bran).forEach( key => 
      {
        // tslint:disable-next-line:no-unused-expression
        this.Bran[key];
        let Branchtemp = new Branch();
        Branchtemp = this.Bran[key];
        this.BranchModel.BranchId=Branchtemp.BranchId;
        this.BranchModel.BranchCode=Branchtemp.BranchCode;
        this.BranchModel.BranchName=Branchtemp.BranchName;
        this.BranchModel.CompId=Branchtemp.CompId;
        this.BranchModel.Address1=Branchtemp.Address1;          
        this.BranchModel.Address2=Branchtemp.Address2;
        this.BranchModel.CountryId=Branchtemp.CountryId;
        this.BranchModel.StateId=Branchtemp.StateId;
        this.BranchModel.CityId=Branchtemp.CityId;
        this.BranchModel.PostCode=Branchtemp.PostCode;
        this.BranchModel.PhoneNo1=Branchtemp.PhoneNo1;
        this.BranchModel.PhoneNo2=Branchtemp.PhoneNo2;
        this.BranchModel.Website=Branchtemp.Website;
        this.BranchModel.Fax=Branchtemp.Fax;
        this.BranchModel.PANNumber=Branchtemp.PANNumber;
        this.BranchModel.Email=Branchtemp.Email;
        this.BranchModel.RegistrationNumber=Branchtemp.RegistrationNumber;
        this.BranchModel.LogoPath=Branchtemp.LogoPath;
      });
      this.getStates(this.BranchModel.CountryId);
      this.getCities(this.BranchModel.StateId);
      // Begin for check Name duplication
      this.checkNameval= this.BranchModel.BranchName;
      this.CheckNamestatusedit=true;
      this.CheckNameExiststatus =true;
      this.Namecheck=""
      // End for check Name duplication 
    });
  }

  getCountries() 
  {
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.organizationService.GetCountry( t).then(x => 
    {
      this.country = x;
      Object.keys(this.country).forEach( key => 
      {
        // tslint:disable-next-line:no-unused-expression
        this.country[key];
        let Countrytemp = new Country();
        Countrytemp = this.country[key];
        this.Countries.push(Countrytemp);
      });     
    });      
  }

  getStates( countryid) 
  {
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t
    if(this.States.length>0)
    {
      this.States.splice(0, this.States.length);
    }
    this.organizationService.GetState( t,countryid).then(x => 
    {
      this.State = x;
      Object.keys(this.State).forEach( key => 
      {
        // tslint:disable-next-line:no-unused-expression
        this.State[key];
        let Statetemp = new State();
        Statetemp = this.State[key];
        this.States.push(Statetemp);
      });     
    });      
  }

  getCities(stateid) 
  {
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t
    if(this.Cities.length>0)
    {
      this.Cities.splice(0,this.Cities.length )
    }
    this.organizationService.GetCity( t,stateid).then(x => 
    {
      this.City = x;
      Object.keys(this.City).forEach( key => 
      {
          // tslint:disable-next-line:no-unused-expression
        this.City[key];
        let Citytemp = new City();
        Citytemp = this.City[key];
        this.Cities.push(Citytemp);
      });     
    });      
  }

  onChange(countryid) 
  {
    if(countryid!=0)
    {
      this.getStates(countryid);
      this.BranchModel.StateId=0;
    }
    else
    {
      this.getStates(countryid);
      this.BranchModel.StateId=0;
      this.getCities(0);
    }
  }

  onstatechange(stateid)
  {
    if(stateid!=0)
    {
      this.getCities(stateid) ;
      this.BranchModel.CityId=0;
    }
    else
    {
      this.getCities(stateid) ;
      this.BranchModel.CityId=0;
    }      
  }

  getCompanies() 
  {
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.organizationService.GetCompany( t).then(x => 
    {
      this.company = x;
      Object.keys(this.company).forEach( key => 
      {
        // tslint:disable-next-line:no-unused-expression
        this.company[key];
        let Companytemp = new Company();
        Companytemp = this.company[key];
        this.Companies.push(Companytemp);
      });     
    });      
  }
  
  saveBranch() :void
  {
    this.DisableSave=false;
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.BranchModel.IsActive=true;
    if(this.BranchModel.BranchId > 0)
    {
      this.organizationService.UpdateBranch(t ,this.BranchModel).subscribe(res=>
      {
        this.BranchId=res;
        this.BranchModel.BranchId=0;  
        if(this.Cities.length>0)
        {
          this.Cities.splice(0,this.Cities.length )
        }                 
        if(this.BranchId>0)
        {
          localStorage.setItem('SuccessfullMessage', "Update Successfully");
          this.rout.navigateByUrl('/Organization/ManageCompany', {skipLocationChange: true}).then(()=>this.rout.navigate(["/Organization/ManageBranch"]));
        }
      });                  
    }
    else
    {
      this.organizationService.AddBranch(t ,this.BranchModel).subscribe(res=>
      {
        this.BranchId=res;
        this.BranchModel.BranchId=0;              
        if(this.Cities.length>0)
        {
          this.Cities.splice(0,this.Cities.length )
        }                 
        if(this.BranchId>0)
        {
          

          localStorage.setItem('SuccessfullMessage', "Save Successfully");
            this.rout.navigateByUrl('/Organization/ManageCompany', {skipLocationChange: true}).
            then(()=>this.rout.navigate(["/Organization/ManageBranch"]));
        }
      }); 
    }
  }

  DeleteBranchByid(branchid : number) :void
  {    
    this._confirmation.create('Confirm to Deleate', 'Press Yes to Delete', this.settings)
    .subscribe((ans: ResolveEmit) => 
    {
      if (ans.resolved == true) 
      {
        let t =  localStorage.getItem('userToken');
        t = 'Bearer' + t;
        this.BranchModel.IsActive=true;
        if( branchid > 0)
        {
          this.organizationService.DeleteBranch(t ,branchid).subscribe(res=>
          {
            this.BranchId=res;
            this.BranchModel.BranchId=0;                                  
            if(this.BranchId >0)
            {
              localStorage.setItem('SuccessfullMessage', "Deleted Successfully");
              this.rout.navigateByUrl('/Organization/ManageCompany', {skipLocationChange: true}).then(()=>this.rout.navigate(["/Organization/ManageBranch"]));
            }
          });                  
        }
      } 
    })
  }

  RefreshPage() 
  {
    this.rout.navigateByUrl('/Organization/ManageCompany', {skipLocationChange: true}).then(()=>this.rout.navigate(["/Organization/ManageBranch"]));
  }

  ADDClick()
  {
    this.FormViewShow=true;
    this.CanSaveButtonView=false;
  }

  // begning of check duplicate Name
  CheckName()
  {
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    if(!this.CheckNamestatusedit)
    {
      this.organizationService.CheckBranchName( t, this.BranchModel.BranchName,this.BranchModel.CompId).then(x => 
      {
        this.checkName = x;
        if( this.checkName.length >0)
        {
          this.CheckNameExiststatus =false;
          this.Namecheck="Exist"
        }
        else
        {
          this.CheckNameExiststatus =true;
          this.Namecheck="";
        }
     });
    }
    else
    {
      if(this.BranchModel.BranchName !=this.checkNameval )
      {
        this.organizationService.CheckBranchName( t, this.BranchModel.BranchName,this.BranchModel.CompId).then(x => 
        {
          this.checkName = x;
          if( this.checkName.length >0)
          {
            this.CheckNameExiststatus =false;
            this.Namecheck="Exist"
          }
          else
          {
            this.CheckNameExiststatus =true;
            this.Namecheck="";
          }
        });
      }
      else
      {
        this.CheckNameExiststatus =true;
        this.Namecheck="";
      }
    }
  }
  // end of duplicate Name
}
