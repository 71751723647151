import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs/Subject';
import { DataTableDirective } from 'angular-datatables';
import { UserService } from '../../shared/user.service';
import { AdminMasterService} from '../../shared/HR_ADMIN/Admin/AdminMasterService.service';
import { EPFESIModel } from  '../../shared/HR_ADMIN/Admin/EPFESIModel.Model';
import { Menu } from '../../shared/menu.model';

@Component({
  selector: 'app-manage-epfesislab',
  templateUrl: './manage-epfesislab.component.html',
  styleUrls: ['./manage-epfesislab.component.css']
})
export class ManageEpfesislabComponent implements OnInit 
{
  EPFESIModel : EPFESIModel= new EPFESIModel();
  userClaims: any;
  EPFESIes: EPFESIModel[] = [];
  EPFESI: any;
  Result : string;
  SuccessStatus:boolean=false;
  SuccessMessage: string ="";
  public tableWidget: any;
  RefId : any;
  base64textString : string;
  tempEPFESIadd : EPFESIModel =new EPFESIModel();
  // Begning Permission Portion    
  SelectedMenu:Menu;
  IsDataIsInViewState : boolean=false;
  CanViewState : boolean=false;
  DisableSave : boolean=false;
  // End of the permission portion
  // begin for Validation
  validdate : boolean= false;
  IsGridInViewState : boolean= false;
  // end of validation

  constructor(private AdminMasterService: AdminMasterService, private rout: Router, private userService: UserService) { }
  
  @ViewChild(DataTableDirective, {static: false})
  setDtOptions: DataTables.Settings = {};
  cardDtOptions: DataTables.Settings = {};
  dtElement: DataTableDirective;
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();
  
  ngOnInit() 
  {
    //Begin Permission Portion
    this.SelectedMenu=this.userService.SelectedMenu;
    if(this.SelectedMenu !=undefined)
    {
      localStorage.setItem('selectedmenu',JSON.stringify(this.SelectedMenu));
      this.rout.navigate([this.SelectedMenu.RouteName]);
    }
    else
    {
      if(JSON.parse(localStorage.getItem('selectedmenu'))!=undefined)
      {
        this.SelectedMenu = JSON.parse(localStorage.getItem('selectedmenu'));
        this.rout.navigate([this.SelectedMenu.RouteName]);
      }
    }
    if(this.SelectedMenu.CanAdd)
    {
    this.IsDataIsInViewState=false;
    }
    else
    {
      this.IsDataIsInViewState=true;
    }
    if(this.SelectedMenu.CanView)
    {
    this.CanViewState=false;
    }
    else
    {
      this.CanViewState=true;
    }
    //End of the peremission portion
    this.DisableSave=true;
    this.IsGridInViewState=false;
    if(localStorage.getItem('SuccessfullMessage'))
    {
      this.SuccessStatus=true;
      this.SuccessMessage =localStorage.getItem('SuccessfullMessage')
      localStorage.removeItem('SuccessfullMessage');
    }  
  }

  ngAfterViewInit(): void 
  {
    this.GetEPFESISlabDetails();
  }
  
  GetEPFESISlabDetails()
  {
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.AdminMasterService.GetEPFESISlab(t).then(x => 
    {
      this.EPFESIes=[];
      this.EPFESI = x;
      Object.keys(this.EPFESI).forEach( key => 
      {
        this.EPFESI[key];
        let EPFESItemp = new EPFESIModel();
        EPFESItemp = this.EPFESI[key];
        this.EPFESIes.push(EPFESItemp);
        if(this.EPFESIes.length>0)
        {
          this.IsGridInViewState=true;
        }
      });
      this.dtTrigger.next();
    });
  }
  
  GetEPFESISlabById(id) 
  { 
    // Begning of the permission portion
    this.IsDataIsInViewState=false;
    this.validdate=true;
    // End of the permision portion
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.AdminMasterService.GetEPFESISlabById( t,id).then(x => 
    {
      this.IsGridInViewState=false;
      this.RefId = x;
      Object.keys(this.RefId).forEach( key => 
      {
        // tslint:disable-next-line:no-unused-expression
        this.RefId[key];
        let EPFESItemp = new EPFESIModel();
        EPFESItemp = this.RefId[key];
        this.EPFESIModel.RefId=EPFESItemp.RefId;
        this.EPFESIModel.EPFCont=EPFESItemp.EPFCont;
        this.EPFESIModel.EPFDed=EPFESItemp.EPFDed;
        this.EPFESIModel.ESICont=EPFESItemp.ESICont;
        this.EPFESIModel.ESIDed=EPFESItemp.ESIDed;
        this.EPFESIModel.EducationCess=EPFESItemp.EducationCess;
        this.EPFESIModel.HigherEducationCess=EPFESItemp.HigherEducationCess;
        this.EPFESIModel.HighestBasicSalary=EPFESItemp.HighestBasicSalary;
        this.EPFESIModel.ApplicableDate=EPFESItemp.ApplicableDate;
        this.EPFESIModel.ApplicableDateCheck=EPFESItemp.ApplicableDate;
        this.EPFESIModel.IsActive=EPFESItemp.IsActive;
      });
    });
  }

  viewEPFESISlabById(id) 
  {
    //begin permission
    this.IsDataIsInViewState=true;
    this.validdate=true;
    //end permission
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.AdminMasterService.GetEPFESISlabById( t,id).then(x => 
    {
      this.IsGridInViewState=false;
      this.RefId = x;
      Object.keys(this.RefId).forEach( key => 
      {
        // tslint:disable-next-line:no-unused-expression
        this.RefId[key];
        let EPFESItemp = new EPFESIModel();
        EPFESItemp = this.RefId[key];
        this.EPFESIModel.RefId=EPFESItemp.RefId;
        this.EPFESIModel.EPFCont=EPFESItemp.EPFCont;
        this.EPFESIModel.EPFDed=EPFESItemp.EPFDed;
        this.EPFESIModel.ESICont=EPFESItemp.ESICont;
        this.EPFESIModel.ESIDed=EPFESItemp.ESIDed;
        this.EPFESIModel.EducationCess=EPFESItemp.EducationCess;
        this.EPFESIModel.HigherEducationCess=EPFESItemp.HigherEducationCess;
        this.EPFESIModel.HighestBasicSalary=EPFESItemp.HighestBasicSalary;
        this.EPFESIModel.ApplicableDate=EPFESItemp.ApplicableDate;
        this.EPFESIModel.IsActive=EPFESItemp.IsActive;
      });
    });
  }

  SaveEPFESISlab() :void
  {
    this.DisableSave=false;
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.EPFESIModel.IsActive=true;
    if(this.EPFESIModel.RefId > 0)
    {
      this.AdminMasterService.UpdateEPFESISlab(t ,this.EPFESIModel).subscribe(res=>
      {
        this.RefId=res;
        this.EPFESIModel.RefId=0;  
        if(this.RefId>0)
        {
          localStorage.setItem('SuccessfullMessage', "Update Successfully");
          this.rout.navigateByUrl('/Admin/ManageFinancialYear', {skipLocationChange: true}).then(()=>this.rout.navigate(["/Admin/EPFESISlab"]));
        }
      });  
    }
    else
    {
      this.AdminMasterService.AddEPFESISlab(t ,this.EPFESIModel).subscribe(res=>
      {
        this.RefId=res;
        this.EPFESIModel.RefId=0;              
        if(this.RefId>0)
        {
          localStorage.setItem('SuccessfullMessage', "Save Successfully");
          this.rout.navigateByUrl('/Admin/ManageFinancialYear', {skipLocationChange: true}).then(()=>this.rout.navigate(["/Admin/EPFESISlab"]));
        }
      }); 
    }
  }

  RefreshPage()
  {
    this.rout.navigateByUrl('/Admin/ManageFinancialYear', {skipLocationChange: true}).then(()=>this.rout.navigate(["/Admin/EPFESISlab"]))
  }
 
  dateChanged( date)
  {
    this.EPFESIModel.ApplicableDate=date;
    var CurrentDate = new Date();
    var CurrentYear = CurrentDate.getFullYear();
    var currentMonth = CurrentDate.getMonth();
    var CurrentDay = CurrentDate.getDate();
    
    var selecteedDate = new Date(date);
    var selectedYear=selecteedDate.getFullYear();
    var selectedMonth=selecteedDate.getMonth();
    var selectedDay=selecteedDate.getDate();

    if(CurrentYear < selectedYear )
    {
      this.validdate = true;      
    }
    else if(CurrentYear > selectedYear)
    {
      this.validdate = false;
    }
    else
    {
      if (currentMonth < selectedMonth)
      {
        this.validdate = true;
      }
      else if(currentMonth > selectedMonth)
      {
        this.validdate = false;
      }
      else
      {
        if(CurrentDay <= selectedDay )
        {
          this.validdate = true;
        }
        else
        {
          this.validdate = false;
        }
      }
    }
  }
}
