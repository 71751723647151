export class JoiningDetails
{
    public JoiningId : Number;
    public OfferRefid : Number;
    public ReportingDate : Date;
    public JoiningDate : Date;
    public InHandSalary : Number;
    public CTCSalary : Number;
    public Assets : string;
    public Records : string;
    public JoiningApproved : boolean;
    public Joiningtatus : string;
    public MobileApply : boolean;
    public MobileAmount : Number;
    public IsActive : boolean;
    //Offer Letter
    public FullName : string;
    public OfferDate : Date;
    public Address1 : string;
    public Address2 : string;
    public District : string;
    public CountryName : Number;
    public StateName : Number;
    public CityName : Number;
    public PinCode : Number;
    public ContactNumber : Number;
    public EmailId : string;
    public CompanyName : Number;
    public BranchName : Number;
    public DepartmentName : Number;
    public DecisionName : Number;
    public LevelName : Number;
    public GradeName : Number;
    public DesignationName : Number;
}