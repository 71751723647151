import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from '../../shared/user.service';
import { OrganizationService } from '../../shared/Organization/organization.service';
import { SalaryMasterService } from '../../shared/HR_ADMIN/Salary/SalaryMaster.service';
import { MonthlyAttendanceModel } from  '../../shared/HR_ADMIN/Salary/MonthlyAttendanceModel';
import { FinancialYear } from  '../../shared/Organization/FinancialYear.Model';
import { Company } from  '../../shared/Organization/Company.Model';
import { Branch } from  '../../shared/Organization/Branch.Model';
import { Department } from  '../../shared/Organization/Department.Model';
// Begning Confirmation
import { ConfirmationService } from '@jaspero/ng2-confirmations';
import { ResolveEmit } from '../../../app/resolve-emit';
import { ConfirmSettings } from '../../../app/confirm-settings';
import { Menu } from '../../shared/menu.model';
// end of Confirmation
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-monthly-attendance-final-details',
  templateUrl: './monthly-attendance-final-details.component.html',
  styleUrls: ['./monthly-attendance-final-details.component.css']
})
export class MonthlyAttendanceFinalDetailsComponent implements OnInit 
{
  MonthlyAttendanceModel : MonthlyAttendanceModel= new MonthlyAttendanceModel();
  MonthlyAttendances: MonthlyAttendanceModel[]=[];
  Att: any;
  RefId: any;
  Companies: Company[] = [];
  company: any;
  FinancialYears: FinancialYear[] = [];
  FinYear:any;
  Months: Company[] = [];
  Month: any;
  Branches: Branch[] = [];
  Branch: any;
  userClaims: any;
  Result : string;
  SuccessStatus:boolean=false;
  SuccessMessage: string ="";
  public tableWidget: any;
  SalaryMappingId : any;
  SalMap: any;
  base64textString : string;
  //For Pop up
  public modalRef: BsModalRef;
  // Begning Permission Portion    
  SelectedMenu:Menu;
  IsDataIsInViewState : boolean=false;
  CanViewState : boolean=false;
  DisableSave : boolean=false;
  // End of the permission portion
  TempSalcomp : MonthlyAttendanceModel;
  //for Department Dropdown list
  DepartmentList = [];
  DepartmentselectedItems = [];
  dropdownSettings = {};
  DepartmentSettings = {};
  DepartmentMasters :any;
  //for Department Dropdown list
  
  constructor(private OrganizationService: OrganizationService,private SalaryMasterService: SalaryMasterService,private rout: Router,  private _confirmation: ConfirmationService, private userService: UserService, private modalService: BsModalService)
  { }
  
  ngOnInit() 
  {
    //Begin Permission Portion
    this.SelectedMenu=this.userService.SelectedMenu;
    if(this.SelectedMenu !=undefined)
    {
      localStorage.setItem('selectedmenu',JSON.stringify(this.SelectedMenu));
      this.rout.navigate([this.SelectedMenu.RouteName]);
    }
    else
    {
      if(JSON.parse(localStorage.getItem('selectedmenu'))!=undefined)
      {
        this.SelectedMenu = JSON.parse(localStorage.getItem('selectedmenu'));
        this.rout.navigate([this.SelectedMenu.RouteName]);
      }
    }     
    if(this.SelectedMenu.CanAdd)
    {
      this.IsDataIsInViewState=false;
    }
    else
    {
      this.IsDataIsInViewState=true;
    }
    if(this.SelectedMenu.CanView)
    {
      this.CanViewState=false;
    }
    else
    {
      this.CanViewState=true;
    }
    this.DisableSave=true;
    if(localStorage.getItem('SuccessfullMessage'))
    {
      this.SuccessStatus=true;
      this.SuccessMessage =localStorage.getItem('SuccessfullMessage')
      localStorage.removeItem('SuccessfullMessage');
    } 

    this.MonthlyAttendanceModel.FinYear=0;
    this.MonthlyAttendanceModel.MonthId=0;
    this.MonthlyAttendanceModel.CompanyId=0;
    this.MonthlyAttendanceModel.BranchId=0;
    this.getCompanies(); 
    this.getMonths();
    this.GetFinancialYear();
    this.DepartmentSettings =
    {
      singleSelection:false,
      text:'Select Department',
      enableSearchFilter:true,
      showCheckbox:true
    }
  }

  settings: ConfirmSettings | any = 
  {
    overlay: true,
    overlayClickToClose: true,
    showCloseButton: true,
    confirmText: 'Yes',
    declineText: 'No'
  };

  getMonths() 
  {
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.Months=[];
    this.OrganizationService.GetMonthName( t).then(x => 
    {
      this.Month = x;
      Object.keys(this.Month).forEach( key => 
      {
        // tslint:disable-next-line:no-unused-expression
        this.Month[key];
        let Monthtemp = new Company();
        Monthtemp = this.Month[key];
        this.Months.push(Monthtemp);
      });     
    });      
  }

  GetFinancialYear() 
  {
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.FinancialYears=[];
    this.OrganizationService.GetFinancialYear( t).then(x => 
    {
      this.FinYear = x;
      Object.keys(this.FinYear).forEach( key => 
      {
        // tslint:disable-next-line:no-unused-expression
        this.FinYear[key];
        let FinYeartemp = new FinancialYear();
        FinYeartemp = this.FinYear[key];
        this.FinancialYears.push(FinYeartemp);
      });     
    });      
  }

  getCompanies() 
  {
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.Companies=[];
    this.OrganizationService.GetCompany( t).then(x => 
    {
      this.company = x;
      Object.keys(this.company).forEach( key => 
      {
        // tslint:disable-next-line:no-unused-expression
        this.company[key];
        let Companytemp = new Company();
        Companytemp = this.company[key];
        this.Companies.push(Companytemp);
      });     
    });      
  }

  onChangeCompany(Companyid) 
  {
    this.getBranches(Companyid);
    this.MonthlyAttendanceModel.BranchId=0
  }

  getBranches( Companyid) 
  {
    this.Branches=[];
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t
    this.OrganizationService.GetBranchByCompanyId( t,Companyid).then(x => 
    {
      this.Branch = x;
      Object.keys(this.Branch).forEach( key => 
      {
        this.Branch[key];
        let Branchtemp = new Branch();
        Branchtemp = this.Branch[key];
        this.Branches.push(Branchtemp);
      });     
    });      
  }

  RefreshPage()
  {
    this.rout.navigateByUrl('/SalaryComponent/ManageSalaryComponentComponent', {skipLocationChange: true}).then(()=>this.rout.navigate(["/SalaryComponent/MonthlyAttendanceDetails"]))
  }

  submitClick()
  {
    if(this.DepartmentselectedItems.length > 0)  
    {
      this.MonthlyAttendanceModel.DepartmentIds='';
      this.DepartmentselectedItems.forEach(element => 
      {
        this.MonthlyAttendanceModel.DepartmentIds= this.MonthlyAttendanceModel.DepartmentIds + element.id + ",";
      });
      var l = this.MonthlyAttendanceModel.DepartmentIds.length
      this.MonthlyAttendanceModel.DepartmentIds = this.MonthlyAttendanceModel.DepartmentIds.substr(0,l-1);
    }
    else
    {
      this.MonthlyAttendanceModel.DepartmentIds ="";
    }

    this.MonthlyAttendances=[];
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.SalaryMasterService.GetMonthlyAttendanceDetails( t,this.MonthlyAttendanceModel.MonthId,this.MonthlyAttendanceModel.FinYear,this.MonthlyAttendanceModel.CompanyId,this.MonthlyAttendanceModel.BranchId,this.MonthlyAttendanceModel.DepartmentIds).then(x => 
    {
      this.MonthlyAttendances=[];
      this.Att = x;
      Object.keys(this.Att).forEach( key => 
      {
        this.DisableSave=true;
        //this.Att[key];
        let Attendancetemp = new MonthlyAttendanceModel();
        Attendancetemp = this.Att[key];
        this.MonthlyAttendanceModel.AttendanceFromDate=Attendancetemp.AttendanceFromDate;
        this.MonthlyAttendanceModel.AttendanceToDate=Attendancetemp.AttendanceToDate;
        this.MonthlyAttendanceModel.NoDaysinMonth=Attendancetemp.NoDaysinMonth;
        this.MonthlyAttendanceModel.Selected=false;
        if(Attendancetemp.AddPenalty==0)
        {
          Attendancetemp.AddPenalty=null;
        }
        if(Attendancetemp.DeductPenalty==0)
        {
          Attendancetemp.DeductPenalty=null;
        }
        if(Attendancetemp.OtherAttendance==0)
        {
          Attendancetemp.OtherAttendance=null;
        }
        this.MonthlyAttendances.push(Attendancetemp);
      });
    });
  }

  SaveMonthlyAttendance(template)
  {
    if(this.MonthlyAttendances.length > 0)
    {
      for(var item of this.MonthlyAttendances)
      {
        if(item.EmployeeId > 0 && (item.Selected == true))
        {
          this.DisableSave=false;
          break;
        }
      }
      if(this.DisableSave == false)
      {
        let t =  localStorage.getItem('userToken');
        t = 'Bearer' + t;    
        var j=0;
        var l=this.MonthlyAttendances.length;
        this.MonthlyAttendances.forEach(item => 
        {
          j=j+1;
          if(item.EmployeeId >0 && item.Selected == true && item.FinalSubmit==false)
          {      
            if(item.RefIdmonthly == null)
            {
              item.RefIdmonthly = 0;
            }
            item.FinalSubmit = false;
            if(item.RefIdmonthly> 0)
            {
              this.SalaryMasterService.UpdateMonthlyAttendanceetails(t ,item).subscribe(res=>
              {
                this.RefId=res;        
                if(this.RefId>0)
                {
                  if(j==this.MonthlyAttendances.length)
                  {
                    this.modalRef = this.modalService.show(template);
                    this.MonthlyAttendances=[];
                  }        
                }
              });                  
            }                             
            else
            {
              this.SalaryMasterService.AddMonthlyAttendanceDetails(t ,item).subscribe(res=>
              {
                this.RefId=res;         
                if(this.RefId>0)
                {
                  if(j==this.MonthlyAttendances.length)
                  {
                    this.modalRef = this.modalService.show(template);
                    this.MonthlyAttendances=[];
                  }        
                }
              }); 
            }
          }
        });
      }
    }
  }

  FinalSaveMonthlyAttendance(template)
  {
    if(this.MonthlyAttendances.length > 0)
    {
      for(var item of this.MonthlyAttendances)
      {
        if(item.EmployeeId > 0 && (item.Selected == true))
        {
          this.DisableSave=false;
          break;
        }
      }
    } 

    if(this.MonthlyAttendances.length > 0 && this.DisableSave == false)
    {
      this._confirmation.create('Confirm to Final Submit', 'Press Yes to Final Submit', this.settings)
      .subscribe((ans: ResolveEmit) => 
      {
        if (ans.resolved == true) 
        {
          //this.DisableSave=false;
          let t =  localStorage.getItem('userToken');
          t = 'Bearer' + t;    
          var j=0;
          var l=this.MonthlyAttendances.length;
          this.MonthlyAttendances.forEach(item => 
          {
            j=j+1;
            if(item.EmployeeId > 0 && item.Selected == true && item.FinalSubmit==false)
            {      
              if(item.RefIdmonthly==null)
              {
                item.RefIdmonthly=0;
              }
              item.FinalSubmit=true;
              if(item.RefIdmonthly> 0)
              {
                this.SalaryMasterService.UpdateMonthlyAttendanceetails(t ,item).subscribe(res=>
                {
                  this.RefId=res;        
                  if(this.RefId>0)
                  {
                    if(j==this.MonthlyAttendances.length)
                    {
                      this.modalRef = this.modalService.show(template);
                      this.MonthlyAttendances=[];
                    }        
                  }
                });                  
              }                             
              else
              {
                this.SalaryMasterService.AddMonthlyAttendanceDetails(t ,item).subscribe(res=>
                {
                  this.RefId=res;         
                  if(this.RefId>0)
                  {
                    if(j==this.MonthlyAttendances.length)
                    {
                      this.modalRef = this.modalService.show(template);
                      this.MonthlyAttendances=[];
                    }        
                  }
                }); 
              }
            }
          });
        }
        else
        {
          this.DisableSave=true;
        }
      })
    }
  }

  AddPenaltyChanged( rowid)
  {
    this.TempSalcomp =this.MonthlyAttendances[rowid];
    if(this.TempSalcomp.EmployeeId>0)
    {
      let FA=(Number(this.TempSalcomp.MainAttendance) + Number(this.TempSalcomp.DeductPenalty)) - Number(this.TempSalcomp.AddPenalty);
      let LOP=(Number(this.TempSalcomp.NoDaysinMonth)-Number(this.TempSalcomp.FinalAttendance));
      if(FA < 0 || LOP < 0)
      {
        this.TempSalcomp.AddPenalty=null;
      }
      
      this.TempSalcomp.FinalAttendance=Number(this.TempSalcomp.MainAttendance) + Number(this.TempSalcomp.DeductPenalty) + Number(this.TempSalcomp.OtherAttendance) - Number(this.TempSalcomp.AddPenalty);
      this.TempSalcomp.FinalLOP=Number(this.TempSalcomp.NoDaysinMonth)-Number(this.TempSalcomp.FinalAttendance);
    }
  }

  DeductPenaltyChanged(rowid)
  {
    this.TempSalcomp =this.MonthlyAttendances[rowid];
    if(this.TempSalcomp.EmployeeId>0)
    {
      let LOP=(Number(this.TempSalcomp.NoDaysinMonth)-Number(this.TempSalcomp.FinalAttendance));
      let FA=(Number(this.TempSalcomp.MainAttendance) + Number(this.TempSalcomp.DeductPenalty)) - Number(this.TempSalcomp.AddPenalty);
      if(LOP < 0 || FA > this.TempSalcomp.NoDaysinMonth)
      {
        this.TempSalcomp.DeductPenalty=null;
      }
      
      this.TempSalcomp.FinalAttendance=Number(this.TempSalcomp.MainAttendance) + Number(this.TempSalcomp.DeductPenalty) + Number(this.TempSalcomp.OtherAttendance) - Number(this.TempSalcomp.AddPenalty);
      this.TempSalcomp.FinalLOP=Number(this.TempSalcomp.NoDaysinMonth)-Number(this.TempSalcomp.FinalAttendance);
    }
  }

  OtherAttendanceChanged(rowid)
  {
    this.TempSalcomp =this.MonthlyAttendances[rowid];
    if(this.TempSalcomp.EmployeeId>0)
    {
      this.TempSalcomp.FinalAttendance=Number(this.TempSalcomp.MainAttendance) + Number(this.TempSalcomp.DeductPenalty) + Number(this.TempSalcomp.OtherAttendance) - Number(this.TempSalcomp.AddPenalty);
      this.TempSalcomp.FinalLOP=Number(this.TempSalcomp.NoDaysinMonth)-Number(this.TempSalcomp.FinalAttendance);
      if(this.TempSalcomp.FinalLOP<0)
      {
        this.TempSalcomp.FinalLOP=0
      }
    }
  }

  SelectedChange(rowid,ev)
  {
    this.TempSalcomp =this.MonthlyAttendances[rowid];
    if(ev.target.checked==true)
    {
      this.TempSalcomp.Selected=true;
    }
    else
    {
      this.TempSalcomp.Selected=false;
    }
  }

  checkAll(ev) 
  {
    if(ev.target.checked == true)
    {
      var J=this.MonthlyAttendances.length;
      for(var i=0;i<J;i++) 
      {
        this.MonthlyAttendances[i].Selected=true;
      }
    }
    else
    {
      var J=this.MonthlyAttendances.length;
      for(var i=0; i<J; i++)
      {
        this.MonthlyAttendances[i].Selected=false;
      }
    }
  }

  //Department
  onchangeBranch(Branchid)
  {
    if(Branchid >= 0)
    {
      this.DepartmentList=[];
      this.MonthlyAttendances=[];
      this.GetDepartment(Branchid);
      //this.MonthlyAttendanceModel.DepartmentId=0;
    }
  }

  GetDepartment(BranchId) 
  {
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t
    this.DepartmentselectedItems = [];
    this.DepartmentList = [];
    this.OrganizationService.GetDepartmentByBranchid( t,BranchId).then(x => 
    {
      this.DepartmentMasters = x;
      Object.keys(this.DepartmentMasters).forEach( key => 
      {
        // tslint:disable-next-line:no-unused-expression
        this.DepartmentMasters[key];
        let Departmenttemp= new Department();
        Departmenttemp = this.DepartmentMasters[key];
        this.DepartmentList.push({"id":Departmenttemp.DepartmentId,"itemName":Departmenttemp.DepartmentName},);
      });
    });      
  }
  
  onItemSelect(item:any){ }
  OnItemDeSelect(item:any){ }
  onSelectAll(items: any){ }
  onDeSelectAll(items: any){ }
}
