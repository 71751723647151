import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Response } from '@angular/http';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import 'rxjs/add/operator/toPromise';
import { GlobalVariable } from '../../../../app.global';
import { Model_CRM_Oppertunity } from './Model_CRM_Oppertunity';
import { Model_CRM_MoreInformation } from './Model_CRM_MoreInformation';
import { Model_CRM_Opportunity_Document } from './Model_CRM_Opportunity_Documents';
import { Model_CRM_OppotunityNotes } from './Model_CRM_Opportunity_Notes';
import { Model_CRM_Opportunity_Shared } from './Model_CRM_Opportunity_Shared';
import { DatePipe } from '@angular/common';

@Injectable()

export class CrmOppertunityService 
{
    date: any;
    IsOppinViewstate: boolean = false;
    SelectedCrmIndexPage: number = 0;
    oppertunitylist: Model_CRM_Oppertunity[] = [];
    OpportunitytempList: Model_CRM_Oppertunity[] = [];
    OpportunityActualList: Model_CRM_Oppertunity[] = [];
    CrmCommonUseOportunityDetails: Model_CRM_Oppertunity;
    CRM_MoreInformation_ModelShare: Model_CRM_MoreInformation;
    CRm_Opportunity_Notes: Model_CRM_OppotunityNotes;
    CurretPageNumber: number;
    SearchText: string;
    
    constructor(private http: HttpClient, private spinnerService: Ng4LoadingSpinnerService, public datepipe: DatePipe)
    {
        this.CrmCommonUseOportunityDetails = new Model_CRM_Oppertunity();
        this.CRM_MoreInformation_ModelShare = new Model_CRM_MoreInformation();
        this.CrmCommonUseOportunityDetails.OppertunityId = 0;
        this.CRM_MoreInformation_ModelShare.DomesticInternationalId = 0;
        this.CRM_MoreInformation_ModelShare.OppertunityMoreInformationId = 0;
        this.CRM_MoreInformation_ModelShare.OppertunityId = 0;
        this.CRM_MoreInformation_ModelShare.FitMiceId = 0;
        this.CRM_MoreInformation_ModelShare.AdvanceId = 0;
        this.CRm_Opportunity_Notes = new Model_CRM_OppotunityNotes();
        this.CRm_Opportunity_Notes.NoteId = 0;
        this.CRm_Opportunity_Notes.NoteDate = new Date();
        this.CRm_Opportunity_Notes.NoteTime = '';
        this.CRm_Opportunity_Notes.Note = '';
        this.CRm_Opportunity_Notes.Important = false;
        this.CRm_Opportunity_Notes.File = '';
        this.CRm_Opportunity_Notes.FileName = '';
        this.CRm_Opportunity_Notes.FileSize = '';
        this.CRm_Opportunity_Notes.FileType = '';
        this.CRm_Opportunity_Notes.UploadedFilename = '';
        this.CRm_Opportunity_Notes.ImportantLink = '';
        // this.CRm_Opportunity_Notes.ContactId = 0;
        this.CRm_Opportunity_Notes.Salution = 0;
        this.CRm_Opportunity_Notes.FileName = '';
        this.CRm_Opportunity_Notes.MiddleName = '';
        this.CRm_Opportunity_Notes.LastName = '';
        this.CRm_Opportunity_Notes.Title = '';
        this.CRm_Opportunity_Notes.WorkerPhone = '';
        this.CRm_Opportunity_Notes.Email = '';
        this.CRm_Opportunity_Notes.Roll = 0;
        // this.CRm_Opportunity_Notes.Importantcheck = 'Importantcheck';
    }

    ResetOportunity()
    {
        this.IsOppinViewstate = false;
        //Oppertunity Details Begin
        this.CrmCommonUseOportunityDetails.OppertunityId = 0;
        this.CrmCommonUseOportunityDetails.OppertunityTitle = '';
        this.CrmCommonUseOportunityDetails.IsCreatedDateValid = true;
        this.CrmCommonUseOportunityDetails.IsClosingDateValid = true;
        this.CrmCommonUseOportunityDetails.Amount = 0;
        this.CrmCommonUseOportunityDetails.OppertunityDescription = '';
        this.CrmCommonUseOportunityDetails.Probability = '0';
        this.CrmCommonUseOportunityDetails.Tag = '';
        this.CrmCommonUseOportunityDetails.PipeLineStatus = '';
        this.CrmCommonUseOportunityDetails.PipeLineId = 0;
        this.CrmCommonUseOportunityDetails.CompanyName = '';
        this.CrmCommonUseOportunityDetails.CompanyId = 0;
        this.CrmCommonUseOportunityDetails.MonthId = 0;
        this.CrmCommonUseOportunityDetails.TypeOfBusiness = 0;
        // this.CrmCommonUseOportunityDetails.ContactName = '';
        // this.CrmCommonUseOportunityDetails.ContactId = 0;
        this.CrmCommonUseOportunityDetails.ChooseFile = '';
        this.CrmCommonUseOportunityDetails.CreatedDate = new Date();
        this.CrmCommonUseOportunityDetails.CreatedDateval = '';
        this.CrmCommonUseOportunityDetails.CloseDate = new Date();
        this.CrmCommonUseOportunityDetails.CloseDateval = '';
        this.CrmCommonUseOportunityDetails.IsActive = true;
        this.CrmCommonUseOportunityDetails.ListOpportunityDocs = [];
        //Oppertunity Details End
        //Oppertunity MoreInfo begin
        this.CRM_MoreInformation_ModelShare.OppertunityId = 0;
        this.CRM_MoreInformation_ModelShare.OppertunityMoreInformationId = 0;
        this.CRM_MoreInformation_ModelShare.NumberOfPax = 0;
        this.CRM_MoreInformation_ModelShare.Retention = 0;
        this.CRM_MoreInformation_ModelShare.CreditTime = '';
        this.CRM_MoreInformation_ModelShare.CloseDate = new Date();
        this.CRM_MoreInformation_ModelShare.CloseDateval = ''
        this.CRM_MoreInformation_ModelShare.QueryNumber = '';
        this.CRM_MoreInformation_ModelShare.AdvancePayment = 0;
        this.CRM_MoreInformation_ModelShare.DomesticInternational = "";
        this.CRM_MoreInformation_ModelShare.DomesticInternationalId = 0;
        this.CRM_MoreInformation_ModelShare.GPFigure = 0;
        this.CRM_MoreInformation_ModelShare.Reason = '';
        this.CRM_MoreInformation_ModelShare.FitMiceName = '';
        this.CRM_MoreInformation_ModelShare.FitMiceId = 0;
        this.CRM_MoreInformation_ModelShare.Destination = '';
        this.CRM_MoreInformation_ModelShare.DepertureDate = new Date();
        this.CRM_MoreInformation_ModelShare.DepertureDateval = ''
        this.CRM_MoreInformation_ModelShare.TimePeriod = '';
        this.CRM_MoreInformation_ModelShare.Source = '';
        this.CRM_MoreInformation_ModelShare.CreatedDate = new Date();
        this.CRM_MoreInformation_ModelShare.IsActive = true;
        this.CRM_MoreInformation_ModelShare.IsDepertureDateValid = true;
        this.CRM_MoreInformation_ModelShare.IsCloseDateValid = true;
        this.CRM_MoreInformation_ModelShare.AdvanceId = 0;
        this.CRM_MoreInformation_ModelShare.SourceQueryId = 0;
        //Begning opportunity note refresh
        this.CRm_Opportunity_Notes.NoteId = 0;
        this.CRm_Opportunity_Notes.NoteDate = new Date();
        this.CRm_Opportunity_Notes.NoteTime = '';
        this.CRm_Opportunity_Notes.Note = '';
        this.CRm_Opportunity_Notes.Important = false;
        this.CRm_Opportunity_Notes.File = '';
        this.CRm_Opportunity_Notes.FileName = '';
        this.CRm_Opportunity_Notes.FileSize = '';
        this.CRm_Opportunity_Notes.FileType = '';
        this.CRm_Opportunity_Notes.UploadedFilename = '';
        this.CRm_Opportunity_Notes.ImportantLink = '';
        // this.CRm_Opportunity_Notes.ContactId = 0;
        this.CRm_Opportunity_Notes.Salution = 0;
        this.CRm_Opportunity_Notes.FileName = '';
        this.CRm_Opportunity_Notes.MiddleName = '';
        this.CRm_Opportunity_Notes.LastName = '';
        this.CRm_Opportunity_Notes.Title = '';
        this.CRm_Opportunity_Notes.WorkerPhone = '';
        this.CRm_Opportunity_Notes.Email = '';
        this.CRm_Opportunity_Notes.Roll = 0;
        //End of opportunity note refresh
    }
    //    Populate Pipeline Status

    GetPipeLineStatus(token)
    {
        this.spinnerService.show();
        const authHeader = new HttpHeaders();
        authHeader.append('Content-Type', 'application/json');
        authHeader.append('Authorization', token);
        return new Promise((resolve, reject) => {
            setTimeout(() => {
                // alert('');

                this.http.get(GlobalVariable.BASE_API_URL + '/api/CRMPipeLine/GetPipeLineStatus', { headers: authHeader })
                    .subscribe(data => {
                        this.spinnerService.hide();
                        resolve(data)
                    },
                        error => {
                            this.spinnerService.hide();
                            reject(error);
                        });
            }, 3000);
        });
    }

    //  Populate Pipeline Status



    //   //    Populate CRM LEAD Contact Drop  Down Starts

    GetCrmLeadContact(token, companyId: number) {
        this.spinnerService.show();
        const authHeader = new HttpHeaders();
        authHeader.append('Content-Type', 'application/json');
        authHeader.append('Authorization', token);
        return new Promise((resolve, reject) => {
            setTimeout(() => {
                this.http.get(GlobalVariable.BASE_API_URL + '/api/CRMContact/GetCRMLeadContact?ComanyId=' + companyId, { headers: authHeader })
                    .subscribe(data => {
                        this.spinnerService.hide();
                        resolve(data)
                    },
                        error => {
                            this.spinnerService.hide();
                            reject(error);
                        });
            }, 3000);
        });
    }

    //     //  Populate  CRM LEAD Contact Drop  Down Ends


    // //    Populate CRM Company Drop  Down Starts

    Get_CrmCompany(token) {
        this.spinnerService.show();
        const authHeader = new HttpHeaders();
        authHeader.append('Content-Type', 'application/json');
        authHeader.append('Authorization', token);
        return new Promise((resolve, reject) => {
            setTimeout(() => {
                this.http.get(GlobalVariable.BASE_API_URL + '/api/CRMCompany/GetCRMCompany', { headers: authHeader })
                    .subscribe(data => {
                        this.spinnerService.hide();
                        resolve(data)
                    },
                        error => {
                            this.spinnerService.hide();
                            reject(error);
                        });
            }, 3000);
        });
    }

    //         //  Populate  CRM Company Drop  Down Ends


    // ADD PART - CRM OPPERTUNITY 

    AddCrmOppertunity(token, CRMOppertunity: Model_CRM_Oppertunity) {
        const authHeader = new HttpHeaders();
        authHeader.append('Content-Type', 'application/json');
        authHeader.append('Authorization', token);
        authHeader.append('Accept', 'application/json');
        //  alert();
        return this.http.post(GlobalVariable.BASE_API_URL + '/api/CrmOppertunity/AddCrmOppertunity', CRMOppertunity, { headers: authHeader })
            .map(this.extractData)
            .catch(this.handleErrorObservable);
    }

    // ADD PART - CRM OPPERTUNITY 

    //For The Grid Statrs

    Get_CRMOppertunity(token, pagenumber: number, searchtext: string) {

        this.spinnerService.show();
        const authHeader = new HttpHeaders();
        authHeader.append('Content-Type', 'application/json');
        authHeader.append('Authorization', token);
        return new Promise((resolve, reject) => {
            setTimeout(() => {
                this.http.get(GlobalVariable.BASE_API_URL + '/api/CRMOppertunity/GetCRMOppertunity?pagenumber=' + pagenumber + '&Serachtext=' + searchtext, { headers: authHeader }).subscribe(data => {
                    this.spinnerService.hide();
                    //  alert("1");
                    resolve(data)
                },
                    error => {
                        this.spinnerService.hide();
                        reject(error);
                    });
            }, 3000);
        });
    }


    Get_CRMOppertunityForDropdown(token) {

        this.spinnerService.show();
        const authHeader = new HttpHeaders();
        authHeader.append('Content-Type', 'application/json');
        authHeader.append('Authorization', token);
        return new Promise((resolve, reject) => {
            setTimeout(() => {
                this.http.get(GlobalVariable.BASE_API_URL + '/api/CRMOppertunity/GetCRMOppertunityDrpdown', { headers: authHeader }).subscribe(data => {
                    this.spinnerService.hide();
                    //  alert("1");
                    resolve(data)
                },
                    error => {
                        this.spinnerService.hide();
                        reject(error);
                    });
            }, 3000);
        });
    }


    //For dropdown list Opportunit By comany

    Get_CRMOppertunityList(token, companyId: number) {

        this.spinnerService.show();
        const authHeader = new HttpHeaders();
        authHeader.append('Content-Type', 'application/json');
        authHeader.append('Authorization', token);

        return new Promise((resolve, reject) => {
            setTimeout(() => {
                this.http.get(GlobalVariable.BASE_API_URL + '/api/CRMOppertunity/GetCRMOppertunityList?CompanyId=' + companyId, { headers: authHeader }).subscribe(data => {
                    this.spinnerService.hide();
                    resolve(data)
                },
                    error => {
                        this.spinnerService.hide();
                        reject(error);
                    });
            }, 3000);
        });
    }



    //For Update Section Starts 

    UpdateCrmOppertunity(token, CRMOppertunity: Model_CRM_Oppertunity) {

        const authHeader = new HttpHeaders();
        authHeader.append('Content-Type', 'application/json');
        authHeader.append('Authorization', token);
        authHeader.append('Accept', 'application/json');
        return this.http.post(GlobalVariable.BASE_API_URL + '/api/CRMOppertunity/UpdateCRMOppertunity', CRMOppertunity, { headers: authHeader })
            .map(this.extractData)
            .catch(this.handleErrorObservable);
    }


    //                           // For Update Section Ends 




    GetCRM_Oppertunity_By_id(token, id: number) {
        this.spinnerService.show();
        const authHeader = new HttpHeaders();
        authHeader.append('Content-Type', 'application/json');
        authHeader.append('Authorization', token);
        return new Promise((resolve, reject) => {
            setTimeout(() => {
                this.http.get(GlobalVariable.BASE_API_URL + '/api/CRMOppertunity/GetCRMOppertunityById?OppertunityId=' + id, { headers: authHeader })
                    .subscribe(data => {
                        this.spinnerService.hide();
                        resolve(data)
                    },
                        error => {
                            this.spinnerService.hide();
                            reject(error);
                        });
            }, 3000);
        });
    }

    //                 //  View & UPdate  Section Ends 


    //                 //  Delete  a   Record  Starts

    DeleteOppertunity(token, ID: number) {

        const authHeader = new HttpHeaders();
        authHeader.append('Content-Type', 'application/json');
        authHeader.append('Authorization', token);
        authHeader.append('Accept', 'application/json');
        return this.http.put(GlobalVariable.BASE_API_URL + '/api/CRMOppertunity/DeleteOppertunity', ID, { headers: authHeader })
            .map(this.extractData)
            .catch(this.handleErrorObservable);
    }

    //                      //  Delete  a  record   Ends   


    //----------------------------- ::::: VALIDATION PART Starts ::::----------------------


    // Created Date Starts


    //    Populate Source Query  Down Starts

    GetSourceofQuery(token) {
        this.spinnerService.show();
        const authHeader = new HttpHeaders();
        authHeader.append('Content-Type', 'application/json');
        authHeader.append('Authorization', token);
        return new Promise((resolve, reject) => {
            setTimeout(() => {
                //  alert('');

                this.http.get(GlobalVariable.BASE_API_URL + '/api/CRMSourceOfQuery/GetCRMSourceOfQuery', { headers: authHeader })
                    .subscribe(data => {
                        this.spinnerService.hide();
                        resolve(data)
                    },
                        error => {
                            this.spinnerService.hide();
                            reject(error);
                        });
            }, 3000);
        });
    }

    //  Populate  Source Query Drop  Down Ends


    Created_Date(date): boolean {
        //     alert (date);

        let CurrentDate = new Date();
        let CurrentYear = CurrentDate.getFullYear();
        let currentMonth = CurrentDate.getMonth();
        let CurrentDay = CurrentDate.getDate();

        let selecteedDate = new Date(date);

        let selectedYear = selecteedDate.getFullYear();
        let selectedMonth = selecteedDate.getMonth()
        let selectedDay = selecteedDate.getDate();

        if (selectedYear < CurrentYear) {
            return false;
        }
        else {
            if (selectedYear == CurrentYear) {
                if (selectedMonth < currentMonth) {
                    return false;

                }
                else if (selectedMonth == currentMonth) {
                    if (selectedDay <= CurrentDay) {
                        return false;

                    }
                    else {
                        return true;
                    }

                }
                else {
                    return true;

                }

            }
            else {
                return true;
            }



        }



    }


    //  Created Date  Validation Ends.


    // begning of Get Oppertunity

    GetCRM_MoreInfo_ByOpportunityId(token, OppertunityId: number) {
        this.spinnerService.show();
        const authHeader = new HttpHeaders();
        authHeader.append('Content-Type', 'application/json');
        authHeader.append('Authorization', token);
        return new Promise((resolve, reject) => {
            setTimeout(() => {
                this.http.get(GlobalVariable.BASE_API_URL + '/api/CRMMoreInformation/GetCRMoreInformationByOppId?OpprId=' + OppertunityId, { headers: authHeader })
                    .subscribe(data => {
                        this.spinnerService.hide();
                        resolve(data)
                    },
                        error => {
                            this.spinnerService.hide();
                            reject(error);
                        });
            }, 3000);
        });
    }


    //end of the Get Oppertunity


    // Begning Get Opportunity Reports
    GetCrmOpportunityReports(token, employees: number[], Fromdate: Date, ToDate: Date) {


        const autheader = new HttpHeaders();
        autheader.append('Content-Type', 'application/jason');
        autheader.append('Authorization', token);

        return new Promise((resolve, reject) => {

            setTimeout(() => {
                this.http.get(GlobalVariable.BASE_API_URL + '/api/CRMOpportunity/GetOpportunityReport?employeeIds=' + employees + '&Fromdate=' + Fromdate + '&Todate=' + ToDate, { headers: autheader, responseType: 'blob' as 'json' })
                    .subscribe((res) => {
                        var file = new Blob(<any>[res], { type: 'application/vnd.ms-excel' });
                        var fileURL = URL.createObjectURL(file);
                        window.open(fileURL);
                    });
            }, 3000);




        });
    }




    // Enet Get Opportunity Reports





    // Closing  Date Starts

    Closing_Date(date): boolean {


        let CurrentDate = new Date();
        let CurrentYear = CurrentDate.getFullYear();
        let currentMonth = CurrentDate.getMonth();
        let CurrentDay = CurrentDate.getDate();

        let selecteedDate = new Date(date);

        let selectedYear = selecteedDate.getFullYear();
        let selectedMonth = selecteedDate.getMonth()
        let selectedDay = selecteedDate.getDate();

        if (selectedYear > CurrentYear) {
            return true;

        }

        else {
            // alert('1')
            if (selectedYear == CurrentYear) {
                //  alert('jkk1')
                if (selectedMonth > currentMonth) {
                    true;
                }
                else {
                    //  alert("OK")
                    if (selectedMonth == currentMonth) {
                        if (selectedDay > CurrentDay) {
                            return true;
                        }
                        else {
                            return false;
                        }
                    }
                    else {
                        return false;
                    }
                }

            }
            else {
                return false;
            }

        }


    }



    // Closing Date Validation 



    //    Populate FITMICE Drop  Down Starts

    GetCrmFitMice(token) {
        this.spinnerService.show();
        const authHeader = new HttpHeaders();
        authHeader.append('Content-Type', 'application/json');
        authHeader.append('Authorization', token);
        return new Promise((resolve, reject) => {
            setTimeout(() => {
                // alert('');

                this.http.get(GlobalVariable.BASE_API_URL + '/api/CRMOppertunity/GetFitMiceName', { headers: authHeader })
                    .subscribe(data => {
                        this.spinnerService.hide();
                        resolve(data)
                    },
                        error => {
                            this.spinnerService.hide();
                            reject(error);
                        });
            }, 3000);
        });
    }

    //  Populate  FITMICE Drop  Down Ends



    //    Populate DomesticInternational Drop  Down Starts

    Get_DomesticInternational(token) {
        this.spinnerService.show();
        const authHeader = new HttpHeaders();
        authHeader.append('Content-Type', 'application/json');
        authHeader.append('Authorization', token);
        return new Promise((resolve, reject) => {
            setTimeout(() => {
                // alert('');

                this.http.get(GlobalVariable.BASE_API_URL + '/api/CRMOppertunity/GetDomesticInternational', { headers: authHeader })
                    .subscribe(data => {
                        this.spinnerService.hide();
                        resolve(data)
                    },
                        error => {
                            this.spinnerService.hide();
                            reject(error);
                        });
            }, 3000);
        });
    }

    //  Populate  DomesticInternational Drop  Down Ends



    // ADD PART - CRM MoreInformation  

    AddCrmMoreInformation(token, CRMMoreInformation: Model_CRM_MoreInformation) {
        const authHeader = new HttpHeaders();
        authHeader.append('Content-Type', 'application/json');
        authHeader.append('Authorization', token);
        authHeader.append('Accept', 'application/json');
        return this.http.post(GlobalVariable.BASE_API_URL + '/api/CrmOppertunity/AddCrmMoreInformation', CRMMoreInformation, { headers: authHeader })
            .map(this.extractData)
            .catch(this.handleErrorObservable);
    }

    // ADD PART - CRM MoreInformation



    //  //For The Grid For More Information  Statrs

    // Get_CRMMoreInformation(token) {
    //     this.spinnerService.show();
    //     const authHeader = new HttpHeaders();
    //     authHeader.append('Content-Type', 'application/json');
    //     authHeader.append('Authorization', token);
    //     return new Promise((resolve, reject) => {
    //         setTimeout(() => {
    //             this.http.get(GlobalVariable.BASE_API_URL + '/api/CRMMoreInformation/GetCRMoreInformation', { headers: authHeader }).subscribe(data => {
    //                 this.spinnerService.hide();
    //                 //  alert("1");
    //                 resolve(data)
    //             },
    //                 error => {
    //                     this.spinnerService.hide();
    //                     reject(error);
    //                 }, );
    //         }, 3000);
    //     });
    // }

    //       //For The Grid For More Information Ends



    // //For Update Section For More Information Starts 

    UpdateCrmMoreInformation(token, CRMMoreInformation: Model_CRM_MoreInformation) {


        const authHeader = new HttpHeaders();
        authHeader.append('Content-Type', 'application/json');
        authHeader.append('Authorization', token);
        authHeader.append('Accept', 'application/json');
        return this.http.post(GlobalVariable.BASE_API_URL + '/api/CRMMoreInformation/UpdateCRMMoreInformation', CRMMoreInformation, { headers: authHeader })
            .map(this.extractData)
            .catch(this.handleErrorObservable);
        //  alert(this.Closing_Date);
    }


    // For Update Section for More Information Ends



    // //  View & Update Section Starts


    // GetCRM_MoreInformation_By_id(token, id: number) {
    //     this.spinnerService.show();
    //     const authHeader = new HttpHeaders();
    //     authHeader.append('Content-Type', 'application/json');
    //     authHeader.append('Authorization', token);
    //     return new Promise((resolve, reject) => {
    //         setTimeout(() => {
    //     this.http.get(GlobalVariable.BASE_API_URL + '/api/CRMMoreInformation/GetCRMMoreInformationById?OppertunityMoreInformationId=' + id, { headers: authHeader })
    //                 .subscribe(data => {
    //                     this.spinnerService.hide();
    //                     resolve(data)
    //                 },
    //                     error => {
    //                         this.spinnerService.hide();
    //                         reject(error);
    //                     }, );
    //         }, 3000);
    //     });
    // }

    // //  View & UPdate  Section Ends 


    //   //  Delete  a   Record  For More Information Starts

    // DeleteMoreInformation(token, ID: number) {

    //     const authHeader = new HttpHeaders();
    //     authHeader.append('Content-Type', 'application/json');
    //     authHeader.append('Authorization', token);
    //     authHeader.append('Accept', 'application/json');
    //     return this.http.put(GlobalVariable.BASE_API_URL + '/api/CRMMoreInformation/DeleteMoreInformation', ID, { headers: authHeader })
    //         .map(this.extractData)
    //         .catch(this.handleErrorObservable);
    // }

    //      //  Delete  a  record   Ends   


    //Fetch CRM Lead To Get Document

    Get_CRMOpportunityGetDocument(token, OpportunityId) {
        this.spinnerService.show();
        const authHeader = new HttpHeaders();
        authHeader.append('Content-Type', 'application/json');
        authHeader.append('Authorization', token);
        return new Promise((resolve, reject) => {
            setTimeout(() => {
                // alert('14');
                this.http.get(GlobalVariable.BASE_API_URL + '/api/CRMMoreInformation/GetOpportunitiesDocuments?OpportunityId=' + OpportunityId, { headers: authHeader }).subscribe(data => {
                    this.spinnerService.hide();
                    resolve(data)
                },
                    error => {
                        this.spinnerService.hide();
                        reject(error);
                    });
            }, 3000);
        });
    }
    //Fetch CRM Lead To Get Document

    public getDocument(token, docid: number) {
        let url = GlobalVariable.BASE_API_URL + '/api/Opportunity/GetDocument?DocumentId=' + docid;
        const authHeader = new HttpHeaders();
        authHeader.append('Content-Type', 'application/json');
        authHeader.append('Authorization', token);

        let headers = new HttpHeaders().append("Authorization", token)
        return this.http.get(url, { responseType: 'arraybuffer', headers: headers });

    }

    AddCRMDocuments(token, Fileupload: Model_CRM_Opportunity_Document[]) {
        const authHeader = new HttpHeaders();
        authHeader.append('Content-Type', 'application/json');
        authHeader.append('Authorization', token);
        authHeader.append('Accept', 'application/json');
        return this.http.post(GlobalVariable.BASE_API_URL + '/api/CRMMoreInformation/AddDocument', Fileupload, { headers: authHeader })
            .map(this.extractData)
            .catch(this.handleErrorObservable);
    }

    AddCRMOpportunityNotes(token, OpportunityNotes: Model_CRM_OppotunityNotes) {
        const authHeader = new HttpHeaders();
        authHeader.append('Content-Type', 'application/json');
        authHeader.append('Authorization', token);
        authHeader.append('Accept', 'application/json');
        return this.http.post(GlobalVariable.BASE_API_URL + '/api/CRMOpportunity/AddNotes', OpportunityNotes, { headers: authHeader })
            .map(this.extractData)
            .catch(this.handleErrorObservable);
    }


    //   //   CRM opportunity rolls

    GetCrmOpportunityRoles(token) {
        this.spinnerService.show();
        const authHeader = new HttpHeaders();
        authHeader.append('Content-Type', 'application/json');
        authHeader.append('Authorization', token);
        return new Promise((resolve, reject) => {
            setTimeout(() => {
                this.http.get(GlobalVariable.BASE_API_URL + '/api/CRMMoreInformation/GetOpportunityRoles', { headers: authHeader })
                    .subscribe(data => {
                        this.spinnerService.hide();
                        resolve(data)
                    },
                        error => {
                            this.spinnerService.hide();
                            reject(error);
                        });
            }, 3000);
        });
    }

    //End CRM opportunity rolls


    GetCrmOpportunityNotesByOpportuityId(token, opportunityId: number) {
        this.spinnerService.show();
        const autheader = new HttpHeaders();
        autheader.append('Content-Type', 'application/jason');
        autheader.append('Authorization', token);
        return new Promise((resolve, reject) => {

            setTimeout(() => {
                this.http.get(GlobalVariable.BASE_API_URL + '/api/CRMOpportunityNotes/GetOpportunitiesNotes?OpoortunityId=' + opportunityId, { headers: autheader })
                    .subscribe(data => {
                        this.spinnerService.hide();
                        resolve(data)
                    },
                        error => {
                            this.spinnerService.hide();
                            reject(error);
                        });
            }, 3000);

        });
    }




    // Begning Get Opportunity Shared
    GetCrmOpportunitySharedById(token, opportunityId: number) {

        this.spinnerService.show();
        const autheader = new HttpHeaders();
        autheader.append('Content-Type', 'application/jason');
        autheader.append('Authorization', token);

        return new Promise((resolve, reject) => {

            setTimeout(() => {
                this.http.get(GlobalVariable.BASE_API_URL + '/api/CRMOpportunity/GetOpportunitiesSharedUsers?opportunityId=' + opportunityId, { headers: autheader })
                    .subscribe(data => {
                        this.spinnerService.hide();
                        resolve(data)
                    },
                        error => {
                            this.spinnerService.hide();
                            reject(error);
                        });
            }, 3000);

        });
    }

    // Enet Get Opportunity Shared

    //begning of the shared opportunity
    AddUpdateCrmOpportunityAssigned(token, CRMOpportunitylist: Model_CRM_Opportunity_Shared[]) {
        const authHeader = new HttpHeaders();
        authHeader.append('Content-Type', 'application/json');
        authHeader.append('Authorization', token);
        authHeader.append('Accept', 'application/json');
        return this.http.post(GlobalVariable.BASE_API_URL + '/api/CRMOpportunity/UpdateCRMOpportunityAssigned', CRMOpportunitylist, { headers: authHeader })
            .map(this.extractData)
            .catch(this.handleErrorObservable);
    }

    //end of the shared opportunity

    //    Populate Business Type DropDown

    GetBusinessType(token) {

        this.spinnerService.show();
        const authHeader = new HttpHeaders();
        authHeader.append('Content-Type', 'application/json');
        authHeader.append('Authorization', token);
        return new Promise((resolve, reject) => {
            setTimeout(() => {
                // alert('');

                this.http.get(GlobalVariable.BASE_API_URL + '/api/CRMBusinessType/GetCRMBusinessType', { headers: authHeader })
                    .subscribe(data => {
                        this.spinnerService.hide();
                        resolve(data)
                    },
                        error => {
                            this.spinnerService.hide();
                            reject(error);
                        });
            }, 3000);
        });
    }

    //  Populate Business Type DropDown



    //    Populate Months Of Bussiness 

    GetMonthOfBusiness(token) {
        this.spinnerService.show();
        const authHeader = new HttpHeaders();
        authHeader.append('Content-Type', 'application/json');
        authHeader.append('Authorization', token);
        return new Promise((resolve, reject) => {
            setTimeout(() => {
                // alert('');

                this.http.get(GlobalVariable.BASE_API_URL + '/api/MonthsOfBusiness/GetCRMMonthsOfBusiness', { headers: authHeader })
                    .subscribe(data => {
                        this.spinnerService.hide();
                        resolve(data)
                    },
                        error => {
                            this.spinnerService.hide();
                            reject(error);
                        });
            }, 3000);
        });
    }

    //  Populate Months Of Bussiness


    //    Populate Advance Payment

    GetOpportunityAdvancePayment(token) {
        this.spinnerService.show();
        const authHeader = new HttpHeaders();
        authHeader.append('Content-Type', 'application/json');
        authHeader.append('Authorization', token);
        return new Promise((resolve, reject) => {
            setTimeout(() => {
                // alert('');

                this.http.get(GlobalVariable.BASE_API_URL + '/api/CrmOpportunity/GetCRMAdvancePayment', { headers: authHeader })
                    .subscribe(data => {
                        this.spinnerService.hide();
                        resolve(data)
                    },
                        error => {
                            this.spinnerService.hide();
                            reject(error);
                        });
            }, 3000);
        });
    }

    //  Populate Advance Payment




    private extractData(res: Response) {
        let body = res;
        return body || {};
    }
    private handleErrorObservable(error: Response | any) {
        console.error(error.message || error);
        return Observable.throw(error.message || error);
    }


}
