export class PtaxSlabModel
{
    public PtaxRefId :number;
    public  FromAmount :number;
    public  ToAmount :number;
    public  TaxAmount :number;
    public  Finyear :string;
    public  StartDate :Date;
    public  EndDate :Date;
    public  StateId :number;
    public  StateName :number;
    public  IsActive :number;
    public  CreatedBy : number;
    public  CreatedDate :Date
    public  UpdatedBy :number;
    public  UpdatedDate :Date;
}