<perfect-scrollbar style="max-width: 1260px; max-height: 540px;">

  <div class="body-content-area tabcontent" id="link1">
    <div class="row">
      <div class="col-lg-9">
        <div class="row">
          <div class="col-lg-1 low-padding text-center">


          </div>
          <div class="col-lg-10 clear-padding">
            <span class="page-title">ADD CRM-Country</span>
            <small class="page-description">It is a long established fact that a reader will be distracted</small>
          </div>
        </div>
      </div>
    </div>


    <div>

      <table id="tblcrmCountry" datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger"
        class="table table-striped table-bordered text-center table-new row-border hover">
        <thead class="thead-blue text-center">

          <tr>
            <th>Sl No.</th>
            <th class="text-left"> COUNTRY </th>
            <th>Action</th>
          </tr>

        </thead>
        <tbody>
          <tr *ngFor="let CRM of crmcountry, let i = index">
            <td scope="row">{{i+1}}</td>
            <td class="text-left">{{ CRM.CountryName}}</td>


            <td>
              <!-- <button *ngIf="this.SelectedMenu.CanEdit" type="button" (click)="GetCRMCountryById( CRM.CountryId )" class="table-edit-btn">
              <i class="fas fa-edit"></i>
            </button>
    
            <button *ngIf="this.SelectedMenu.CanDelete" type="button" (click)="DeleteCrmCountry( CRM.CountryId )" class="table-remove-btn">
              <i class="fas fa-trash-alt"></i>
            </button>
    
            <button *ngIf="this.SelectedMenu.CanView" type="button" (click)="ViewCRMCountryById( CRM.CountryId )" class="table-view-button"
              type="button">
              <i class="fas fa-eye"></i>
            </button> -->

              <button type="button" *ngIf="this.userService.selectedCrmMenu.CanEdit"
                (click)="GetCRMCountryById( CRM.CountryId )" class="table-edit-btn">
                <i class="fas fa-edit"></i>
              </button>

              <button type="button" *ngIf="this.userService.selectedCrmMenu.CanDelete"
                (click)="DeleteCrmCountry( CRM.CountryId )" class="table-remove-btn">
                <i class="fas fa-trash-alt"></i>
              </button>

              <button type="button" *ngIf="this.userService.selectedCrmMenu.CanView"
                (click)="ViewCRMCountryById( CRM.CountryId )" class="table-view-button" type="button">
                <i class="fas fa-eye"></i>
              </button>


            </td>

          </tr>

        </tbody>
      </table>



      <fieldset class="body-form-control">
        <app-dialog [(visible)]="this.SuccessStatus">
          <span class="successmsg">{{this.SuccessMessage}}</span>
        </app-dialog>


        <legend class="legend--">ADD COUNTRY</legend>

        <form #CrmCountry="ngForm" (ngSubmit)="SaveCrmCountry()">


          <div class="row">
            <div class="col-lg-4">
              <div class="col-body-border-right">
                <div class="form-group row">
                  <label class="col-sm-4 col-form-label clear-padding-right">Country</label>
                  <div class="col-sm-7 clear-left-padding">
                    <input type="text" [(ngModel)]="CRM_Country_Model.CountryName" tabindex="1" maxlength="250"
                      id="CountryName" name="CountryName" #CountryName="ngModel" required
                      class="form-control form-control-custom form-control-custom-new">
                  </div>
                </div>
              </div>
            </div>

            <div class="col-lg-4">
              <div class="form-group row">
                <div class="col-sm-5 col-form-label clear-padding-right" *ngIf="!(this.IsDataIsInViewState)">
                  <button type="submit" id="submit" tabindex="4" class="btn btn-primary save-button"
                    [disabled]="!(this.CRM_Country_Model.CountryName.length>0)">Save</button>
                </div>

                <div class="col-sm-5 col-form-label text-center">
                  <button type="button" tabindex="5" class="btn btn-danger cancle-button"
                    (click)="RefreshPage()">Cancel</button>
                </div>

              </div>
            </div>

          </div>

        </form>
      </fieldset>
    </div>
  </div>
</perfect-scrollbar>