import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from '../../shared/user.service';
import { EmployeePortalService }  from '../../shared/HR_ADMIN/EmployeePortal/EmployeePortalService.service';
import { EmployeeMasterService }  from '../../shared/HR_ADMIN/Employee/EmployeeMaster.Service';
import { EmployeeDetail } from '../../shared/HR_ADMIN/Employee/EmployeeDetail.Model';
import { Menu } from '../../shared/menu.model';
import { TourReimbursementMasterModel } from '../../shared/HR_ADMIN/EmployeePortal/TourReimbursementMasterModel.Model';
import { TourReimbursementDetailsModel } from '../../shared/HR_ADMIN/EmployeePortal/TourReimbursementDetailsModel.Model';

@Component({
  selector: 'app-all-employee-tour-reimbursement-application',
  templateUrl: './all-employee-tour-reimbursement-application.component.html',
  styleUrls: ['./all-employee-tour-reimbursement-application.component.css']
})
export class AllEmployeeTourReimbursementApplicationComponent implements OnInit 
{
  TourReimbursementModel : TourReimbursementMasterModel= new TourReimbursementMasterModel();
  TourExpenseHeads: TourReimbursementDetailsModel[]=[];
  Application: any;
  TourId : any;
  StatusValue : any;
  userClaims: any;
  Result : string;
  SuccessStatus:boolean=false;
  SuccessMessage: string ="";
  public tableWidget: any;
  selectedIndex : number;
  // Begning Permission Portion    
  SelectedMenu:Menu;
  // End of the permission portion
  // begin for Validation
  validFromdate : boolean= false;
  validTodate : boolean= false;
  validdate : boolean= false;
  DisableSave :boolean=false;
  ShowMessage :boolean;
  // end of validation
  Employees: EmployeeDetail[] = [];
  employee: any;
  // Begning Permission Portion    
  IsDataIsInViewState : boolean=false;
  CanViewState : boolean=true;
  IsApplicationView : boolean=false;
  // End of the permission portion
  EmployeeSettings = {};
  selectedItems = [];
  emp : any [] =[]
  TotalExpectedAmount : number=null;
  Noofdays : any;
  RefId : any;

  constructor(private EmployeeMasterService: EmployeeMasterService, private rout: Router, private userService: UserService,private EmployeePortalService: EmployeePortalService) 
  {  }

  ngOnInit() 
  {
    // Begning Permission Portion 
    this.SelectedMenu=this.userService.SelectedMenu;
    if(this.SelectedMenu !=undefined)
    {
      localStorage.setItem('selectedmenu',JSON.stringify(this.SelectedMenu));
      this.rout.navigate([this.SelectedMenu.RouteName]);
    }
    else
    {
      if(JSON.parse(localStorage.getItem('selectedmenu'))!=undefined)
      {
        this.SelectedMenu = JSON.parse(localStorage.getItem('selectedmenu'));
        this.rout.navigate([this.SelectedMenu.RouteName]);
      }
    }
    if(this.SelectedMenu.CanAdd)
    {
      this.IsDataIsInViewState=false;
    }
    else
    {
      this.IsDataIsInViewState=true;
    }
    if(this.SelectedMenu.CanView)
    {
      this.CanViewState=false;
    }
    else
    {
      this.CanViewState=true;
    }
    // End of the permission portion
    if(localStorage.getItem('SuccessfullMessage'))
    {
      this.SuccessStatus=true;
      this.SuccessMessage =localStorage.getItem('SuccessfullMessage')
      localStorage.removeItem('SuccessfullMessage');
    }
    this.GetEmployeeDetailsDDL();
    this.TourReimbursementModel.EmployeeId=0;
    this.DisableSave=true;
    this.EmployeeSettings =
    {
      singleSelection:true,
      text:'Select Employee',
      enableSearchFilter:true,
      showCheckbox:false
    }
  }

  ngAfterViewInit(): void 
  {

  }
  
  GetEmployeeDetailsDDL() 
  {
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.EmployeeMasterService.GetEmployeeDetailsForDDL( t).then(x => 
    {
      this.employee = x;
      Object.keys(this.employee).forEach( key => 
      {
        let emptemp = new EmployeeDetail();
        emptemp = this.employee[key];
        this.Employees.push(emptemp);
        this.emp.push({"id":emptemp.EmployeeId,"itemName":emptemp.EmployeeCode + '-' +emptemp.EmployeeName},);
      });     
    });      
  }

  onItemSelect(item:any)
  {
    let EmployeeId= item['id'];
    if(EmployeeId>0)
    {
      this.TourReimbursementModel.EmployeeId=EmployeeId;
      this.IsApplicationView=true;
      this.GetEmployeeDetailsForReibursement(this.TourReimbursementModel.EmployeeId);
      this.GetExpenseHeadForApplication(1,1,1);
    }     
  }
  OnItemDeSelect(item:any){ }
  onSelectAll(items: any){ }
  onDeSelectAll(items: any){ }

  GetExpenseHeadForApplication(DecisionId,LevelId,GradeId)
  {
    this.TourExpenseHeads=[];
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.EmployeePortalService.GetExpenseHeadForApplication( t,DecisionId,LevelId,GradeId).then(x => 
    {
      this.Application = x;
      Object.keys(this.Application).forEach( key => 
      {
        this.Application[key];
        let Applicationtemp = new TourReimbursementDetailsModel();
        Applicationtemp = this.Application[key];
        if(Applicationtemp.ExpenseAmount=0)
        {
          Applicationtemp.ExpenseAmount=null;
        }
        this.TourExpenseHeads.push(Applicationtemp);
      });
    });
  }

  GetEmployeeDetailsForReibursement(EmployeeId)
  {
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.EmployeePortalService.GetEmployeeDetailsForReibursementByEmployeeId( t,EmployeeId).then(x => 
    {
      this.Application = x;
      Object.keys(this.Application).forEach( key => 
      {
        this.Application[key];
        let Applicationtemp = new TourReimbursementMasterModel();
        Applicationtemp = this.Application[key];
        this.TourReimbursementModel.EmployeeId=this.Application.EmployeeId;
        this.TourReimbursementModel.EmployeeCode=this.Application.EmployeeCode;
        this.TourReimbursementModel.EmployeeName=this.Application.EmployeeName;
        this.TourReimbursementModel.EmployeeEmailId=this.Application.EmployeeEmailId;
        this.TourReimbursementModel.RHId=this.Application.RHId;
        this.TourReimbursementModel.RHCode=this.Application.RHCode;
        this.TourReimbursementModel.RHName=this.Application.RHName;
        this.TourReimbursementModel.RHEmailId=this.Application.RHEmailId;
        this.TourReimbursementModel.FHId=this.Application.FHId;
        this.TourReimbursementModel.FHCode=this.Application.FHCode;
        this.TourReimbursementModel.FHName=this.Application.FHName;
        this.TourReimbursementModel.FHEmailId=this.Application.FHEmailId;
        this.TourReimbursementModel.HRNames=this.Application.HRNames;
        this.TourReimbursementModel.HREmailIds=this.Application.HREmailIds;
      });
    });
  }
  
  FromDateChanged( date)
  {
    var oneDay = 24*60*60*1000; 
    this.TourReimbursementModel.FromDate=date;

    if(this.TourReimbursementModel.FromDate != null && this.TourReimbursementModel.ToDate != null)
    {
      if(this.TourReimbursementModel.ToDate >= this.TourReimbursementModel.FromDate)
      {
        this.validdate=false;
        this.GetNofoDays(this.TourReimbursementModel.FromDate,this.TourReimbursementModel.ToDate);
        this.CheckTourReimbursementApplicationDateexists(this.TourReimbursementModel.FromDate,this.TourReimbursementModel.ToDate);
      }
      else
      {
        this.validdate=true;
      }
    }
  }

  ToDateChanged( date)
  {
    var oneDay = 24*60*60*1000; 
    this.TourReimbursementModel.ToDate=date;
    if(this.TourReimbursementModel.FromDate != null && this.TourReimbursementModel.ToDate != null)
    {
      if(this.TourReimbursementModel.ToDate >= this.TourReimbursementModel.FromDate)
      {
        this.validdate=false;
        this.GetNofoDays(this.TourReimbursementModel.FromDate,this.TourReimbursementModel.ToDate);
        this.CheckTourReimbursementApplicationDateexists(this.TourReimbursementModel.FromDate,this.TourReimbursementModel.ToDate);
      }
      else
      {
        this.validdate=true;
      }
    }
  }

  AdvancePaidDateChanged( date)
  {
    this.TourReimbursementModel.AdvancePaidDate=date;
  }

  SaveAEmployeeTourReimbursementApplication() :void
  {
    this.DisableSave=false;
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.TourReimbursementModel.TourReimbursementDetails=this.TourExpenseHeads;
    this.EmployeePortalService.AddEmployeeTourReimbursementApplication(t ,this.TourReimbursementModel).subscribe(res=>
    {
      this.RefId=res;
      this.TourReimbursementModel.RefIdTR=0;              
      if(this.RefId>0)
      {
        localStorage.setItem('SuccessfullMessage', "Application Forward to Reporting Head Successfully");
        this.rout.navigateByUrl('/Employee/EmployeeOffer', {skipLocationChange: true}).then(()=>this.rout.navigate(["/Employee/AllEmployeeTourReimbursementApplication"]));
      }
    }); 
  }

  CheckTourReimbursementApplicationDateexists(FromDate,ToDate)
  {
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.EmployeePortalService.CheckTourReimbursementApplicationDateexists( t,FromDate,ToDate,this.TourReimbursementModel.EmployeeId).then(res => 
    {
      this.StatusValue = res;
      if(this.StatusValue == 1)
      {
        this.ShowMessage = true;
      }
      else
      {
        this.ShowMessage = false;
      }
    });
  }

  GetNofoDays(FromDate,ToDate)
  {
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.EmployeePortalService.GetNofoDays( t,FromDate,ToDate).then(res => 
    {
      this.Noofdays = res;
      this.TourReimbursementModel.NoOfDays=this.Noofdays;
    });
  }

  ExpenseAmountChanged()
  {
    this.TotalExpectedAmount=0;
    var j=0;
    var l=this.TourExpenseHeads.length;
    this.TourExpenseHeads.forEach(item => 
    {
      j=j+1;
      if(item.ExpenseAmount > 0)
      { 
        this.TotalExpectedAmount=  Number(this.TotalExpectedAmount) + (Number(item.ExpenseAmount));
      }
      this.TourReimbursementModel.ExpectedExpenseAmount=this.TotalExpectedAmount;
    });
  }

  RefreshPage()
  {
    this.rout.navigateByUrl('/Employee/EmployeeOffer', {skipLocationChange: true}).then(()=>this.rout.navigate(["/Employee/AllEmployeeTourReimbursementApplication"]));
  }
}