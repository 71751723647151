import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DatePipe } from '@angular/common';
import { UserService } from '../../shared/user.service';
import { EmployeePortalService }  from '../../shared/HR_ADMIN/EmployeePortal/EmployeePortalService.service';
import { EmployeeMasterService }  from '../../shared/HR_ADMIN/Employee/EmployeeMaster.Service';
import { EmployeeDetail } from '../../shared/HR_ADMIN/Employee/EmployeeDetail.Model';
import { TourModel }  from '../../shared/HR_ADMIN/EmployeePortal/TourModel.Model';
import { Menu } from '../../shared/menu.model';

@Component({
  selector: 'app-all-employee-tour-application',
  templateUrl: './all-employee-tour-application.component.html',
  styleUrls: ['./all-employee-tour-application.component.css']
})

export class AllEmployeeTourApplicationComponent implements OnInit 
{
  TourModel : TourModel= new TourModel();
  TourApplication: TourModel[]=[];
  Application: any;
  TourId : any;
  StatusValue : any;
  userClaims: any;
  Result : string;
  SuccessStatus:boolean=false;
  SuccessMessage: string ="";
  public tableWidget: any;
  selectedIndex : number;
  // Begning Permission Portion    
  SelectedMenu:Menu;
  // End of the permission portion
  // begin for Validation
  validFromdate : boolean= false;
  validTodate : boolean= false;
  validdate : boolean= false;
  DisableSave :boolean=false;
  ShowMessage :boolean;
  // end of validation
  Employees: EmployeeDetail[] = [];
  employee: any;
  // Begning Permission Portion    
  IsDataIsInViewState : boolean=false;
  CanViewState : boolean=true;
  IsApplicationView : boolean=false;
  // End of the permission portion
  EmployeeSettings = {};
  selectedItems = [];
  emp : any [] =[]

  constructor(private EmployeeMasterService: EmployeeMasterService, private rout: Router, private userService: UserService,private EmployeePortalService: EmployeePortalService) 
  {  }

  ngOnInit() 
  {
    // Begning Permission Portion 
    this.SelectedMenu=this.userService.SelectedMenu;
    if(this.SelectedMenu !=undefined)
    {
      localStorage.setItem('selectedmenu',JSON.stringify(this.SelectedMenu));
      this.rout.navigate([this.SelectedMenu.RouteName]);
    }
    else
    {
      if(JSON.parse(localStorage.getItem('selectedmenu'))!=undefined)
      {
        this.SelectedMenu = JSON.parse(localStorage.getItem('selectedmenu'));
        this.rout.navigate([this.SelectedMenu.RouteName]);
      }
    }
    if(this.SelectedMenu.CanAdd)
    {
      this.IsDataIsInViewState=false;
    }
    else
    {
      this.IsDataIsInViewState=true;
    }
    if(this.SelectedMenu.CanView)
    {
      this.CanViewState=false;
    }
    else
    {
      this.CanViewState=true;
    }
    // End of the permission portion
    if(localStorage.getItem('SuccessfullMessage'))
    {
      this.SuccessStatus=true;
      this.SuccessMessage =localStorage.getItem('SuccessfullMessage')
      localStorage.removeItem('SuccessfullMessage');
    }
    this.GetEmployeeDetailsDDL();
    this.TourModel.EmployeeId=0;
    this.DisableSave=true;
    this.EmployeeSettings =
    {
      singleSelection:true,
      text:'Select Employee',
      enableSearchFilter:true,
      showCheckbox:false
    }
  }

  GetEmployeeDetailsDDL() 
  {
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.EmployeeMasterService.GetEmployeeDetailsForDDL( t).then(x => 
    {
      this.employee = x;
      Object.keys(this.employee).forEach( key => 
      {
        let emptemp = new EmployeeDetail();
        emptemp = this.employee[key];
        this.Employees.push(emptemp);
        this.emp.push({"id":emptemp.EmployeeId,"itemName":emptemp.EmployeeCode + '-' +emptemp.EmployeeName},);
      });     
    });      
  }

  onItemSelect(item:any)
  {
    let EmployeeId= item['id'];
    if(EmployeeId>0)
    {
      this.TourModel.EmployeeId=EmployeeId;
      this.IsApplicationView=true;
      this.GetEmployeeTourStatus(EmployeeId);
      this.GetEmployeeDetails(EmployeeId);
    }     
  }
  OnItemDeSelect(item:any){ }
  onSelectAll(items: any){ }
  onDeSelectAll(items: any){ }

  GetEmployeeTourStatus(EmployeeId)
  {
    this.TourApplication=[];
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.EmployeePortalService.GetEmployeeTourStatusByEmployeeId( t,EmployeeId).then(x => 
    {
      this.Application = x;
      Object.keys(this.Application).forEach( key => 
      {
        this.Application[key];
        let Applicationtemp = new TourModel();
        Applicationtemp = this.Application[key];
        this.TourApplication.push(Applicationtemp);
      });
    });
  }

  GetEmployeeDetails(EmployeeId)
  {
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.EmployeePortalService.GetEmployeeDetailsByEmployeeIdLeaveApply( t,EmployeeId).then(x => 
    {
      this.Application = x;
      Object.keys(this.Application).forEach( key => 
      {
        this.Application[key];
        let Applicationtemp = new TourModel();
        Applicationtemp = this.Application[key];
        this.TourModel.EmployeeId=this.Application.EmployeeId;
        this.TourModel.EmployeeCode=this.Application.EmployeeCode;
        this.TourModel.EmployeeName=this.Application.EmployeeName;
        this.TourModel.EmployeeEmailId=this.Application.EmployeeEmailId;
        this.TourModel.RHId=this.Application.RHId;
        this.TourModel.RHCode=this.Application.RHCode;
        this.TourModel.RHName=this.Application.RHName;
        this.TourModel.RHEmailId=this.Application.RHEmailId;
        this.TourModel.CompanyId=this.Application.CompanyId;
      });
    });
  }

  FromDateChanged( date)
  {
    this.TourModel.FromDate=date;
    var CurrentDate = new Date();
    var CurrentYear = CurrentDate.getFullYear();
    var currentMonth = CurrentDate.getMonth();
    var CurrentDay = CurrentDate.getDate();
    
    var selectedDate = new Date(date);
    var selectedYear=selectedDate.getFullYear();
    var selectedMonth=selectedDate.getMonth();
    var selectedDay=selectedDate.getDate();

    if(CurrentYear != selectedYear)
    {
      this.validFromdate = true;      
    }
    else
    {
      let selecteddateString = selectedDate.toDateString();
      let newselectedDate = new Date(selecteddateString);
      let CurrentdateString = CurrentDate.toDateString(); 
      let newCurrentDate = new Date(CurrentdateString);
      if (newselectedDate >= newCurrentDate)
      {
        this.validFromdate = false;
      }
      else if (currentMonth == selectedMonth)
      {
        if(CurrentDay <=  25)
        {
          this.validFromdate = false;
        }
        else
        {
          if(selectedDay > 25)
          {
            this.validFromdate = false;
          }
          else
          {
            this.validFromdate = true;
          }
        }
      }
      else if (currentMonth == 0 && selectedMonth == 11)
      {
        if(selectedDay >= 26 )
        {
          this.validFromdate = false;
        }
        else
        {
          this.validFromdate = true;
        }
      }
      else if ((currentMonth-1) == selectedMonth)
      {
        if(selectedDay >= 26 )
        {
          this.validFromdate = false;
        }
        else
        {
          this.validFromdate = true;
        }
      }
      else
      {
        this.validFromdate = true;
      }
    }

    if(this.TourModel.FromDate > this.TourModel.ToDate)
    {
      this.validdate=true;
    }
    else
    {
      this.validdate=false;
      if(this.TourModel.FromDate != null && this.TourModel.ToDate != null)
      {
        this.CheckTourApplicationDateexists(this.TourModel.FromDate,this.TourModel.ToDate);
      }
    }
  }

  ToDateChanged( date)
  {
    this.TourModel.ToDate=date;
    var CurrentDate = new Date();
    var CurrentYear = CurrentDate.getFullYear();
    var currentMonth = CurrentDate.getMonth();
    var CurrentDay = CurrentDate.getDate();
    
    var selectedDate = new Date(date);
    var selectedYear=selectedDate.getFullYear();
    var selectedMonth=selectedDate.getMonth();
    var selectedDay=selectedDate.getDate();

    if(CurrentYear != selectedYear)
    {
      this.validTodate = true;      
    }
    else
    {
      let selecteddateString = selectedDate.toDateString();
      let newselectedDate = new Date(selecteddateString);
      let CurrentdateString = CurrentDate.toDateString(); 
      let newCurrentDate = new Date(CurrentdateString);
      if (newselectedDate >= newCurrentDate)
      {
        this.validTodate = false;
      }
      else if (currentMonth == selectedMonth)
      {
        if(CurrentDay <=  25)
        {
          this.validTodate = false;
        }
        else
        {
          if(selectedDay > 25)
          {
            this.validTodate = false;
          }
          else
          {
            this.validTodate = true;
          }
        }
      }
      else if (currentMonth == 0 && selectedMonth == 11)
      {
        if(selectedDay >= 26 )
        {
          this.validTodate = false;
        }
        else
        {
          this.validTodate = true;
        }
      }
      else if ((currentMonth-1) == selectedMonth)
      {
        if(selectedDay >= 26 )
        {
          this.validTodate = false;
        }
        else
        {
          this.validTodate = true;
        }
      }
      else
      {
        this.validTodate = true;
      }
    }

    if(this.TourModel.FromDate > this.TourModel.ToDate)
    {
      this.validdate=true;
    }
    else
    {
      this.validdate=false;
      if(this.TourModel.FromDate != null && this.TourModel.ToDate != null)
      {
        this.CheckTourApplicationDateexists(this.TourModel.FromDate,this.TourModel.ToDate);
      }
    }
  }

  SaveEmployeeTour() :void
  {
    this.DisableSave=false;
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.EmployeePortalService.AddEmployeeTour(t ,this.TourModel).subscribe(res=>
    {
      this.TourId=res;
      this.TourModel.TourId=0;              
      if(this.TourId>0)
      {
        localStorage.setItem('SuccessfullMessage', "Save Successfully");
        this.rout.navigateByUrl('/EmployeePortal/PersonalInformation', {skipLocationChange: true}).then(()=>this.rout.navigate(["/EmployeePortal/TourApplication"]));
      }
    }); 
  }
  
  RefreshPage()
  {
    this.rout.navigateByUrl('/Employee/EmployeeOffer', {skipLocationChange: true}).then(()=>this.rout.navigate(["/Employee/AllEmployeeTourApplication"]));
  }

  CheckTourApplicationDateexists(FromDate,ToDate)
  {
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.EmployeePortalService.CheckTourApplicationDateexists( t,FromDate,ToDate,this.TourModel.EmployeeId).then(res => 
    {
      this.StatusValue = res;
      if(this.StatusValue == 1)
      {
        this.ShowMessage=true;
      }
      else
      {
        this.ShowMessage = false;
      }
    });
  }
}