import { Injectable,Inject } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';  
import { Http, Headers, RequestOptions, Response } from '@angular/http';  
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import 'rxjs/add/operator/toPromise';
import { Subscriber } from 'rxjs';
import { GlobalVariable  } from '../../../app.global';
import { LeaveApprovalModel } from '../../../shared/HR_ADMIN/Approve/ApproveLeave.Model'
import { AttendanceRegularationModel } from '../../../shared/HR_ADMIN/Approve/AttendanceRegularationModel.Model'
import { EmployeeConfirmationModel } from '../../../shared/HR_ADMIN/Approve/EmployeeConfirmationModel.Model'

@Injectable()
export class ApproveLeaveService 
{
    totalnotification : Number=0;
    TotalNoofLeaveNotification : Number=0;
    constructor(private http: HttpClient,private spinnerService: Ng4LoadingSpinnerService) 
    {

    }        
    // Begning of the Leave Approval
    GetEmployeeListleaveApprovalForRH(token)
    {
        const authHeader = new HttpHeaders();     
        authHeader.append('Content-Type', 'application/json');
        authHeader.append('Authorization', token);
        return new Promise((resolve, reject) =>
        {
            setTimeout(()=>
            {
                this.http.get(GlobalVariable.BASE_API_URL +'/api/LeaveApprove/GetEmployeeListleaveApprovalForRH',{headers: authHeader})
                .subscribe(data => 
                {
                    resolve(data)
                },
                error => 
                {
                    reject(error);
                },);
            },3000);
        });
    }
    GetLeaveApplications(token)
    {
        const authHeader = new HttpHeaders();     
        authHeader.append('Content-Type', 'application/json');
        authHeader.append('Authorization', token);
        return new Promise((resolve, reject) =>
        {
            setTimeout(()=>
            {
                this.http.get(GlobalVariable.BASE_API_URL +'/api/LeaveApprove/GetEmployeeApplyleave',{headers: authHeader})
                .subscribe(data => 
                {
                    resolve(data)
                },
                error => 
                {
                    reject(error);
                },);
            },3000);
        });
    }
    GetLeavedetailsByID(token, id:number ) 
    {
        this.spinnerService.show();
        const authHeader = new HttpHeaders();     
        authHeader.append('Content-Type', 'application/json');
        authHeader.append('Authorization', token);
        return new Promise((resolve, reject) => 
        {
            setTimeout(()=>
            {
                this.http.get(GlobalVariable.BASE_API_URL +'/api/LeaveApprove/GetEmployeeApplyleaveBtRefId?Refid='+id,{headers: authHeader})
                .subscribe(data => 
                {
                    this.spinnerService.hide();
                    resolve(data)
                },
                error => 
                {
                    this.spinnerService.hide();
                    reject(error);
                },);
            },3000);
        });
    }
    GetLeaveApplicationsNotification(token )
    {
        const authHeader = new HttpHeaders();     
        authHeader.append('Content-Type', 'application/json');
        authHeader.append('Authorization', token);
        return new Promise((resolve, reject) =>
        {
            setTimeout(()=>
            {
                this.http.get(GlobalVariable.BASE_API_URL +'/api/LeaveApprove/GetEmployeeApplyLeaveNotification',{headers: authHeader})
                .subscribe(data => 
                {
                   
                    resolve(data)
                },
                error => 
                {
                    
                    reject(error);
                },);
            },3000);
        });
    }
    LeaveApprovedOrRejected( token ,LeaveApprove :LeaveApprovalModel) 
    {
        const authHeader = new HttpHeaders();         
        authHeader.append('Content-Type', 'application/json');
        authHeader.append('Authorization', token);
        authHeader.append('Accept', 'application/json');
        return this.http.put(GlobalVariable.BASE_API_URL +'/api/LeaveApprove/LeaveApprovedOrRejected', LeaveApprove ,{headers: authHeader} )
        .map(this.extractData)
        .catch(this.handleErrorObservable);
    }
    LeaveCancel( token ,LeaveApprove :LeaveApprovalModel) 
    {
        const authHeader = new HttpHeaders();         
        authHeader.append('Content-Type', 'application/json');
        authHeader.append('Authorization', token);
        authHeader.append('Accept', 'application/json');
        return this.http.put(GlobalVariable.BASE_API_URL +'/api/LeaveApprove/LeaveCancel', LeaveApprove ,{headers: authHeader} )
        .map(this.extractData)
        .catch(this.handleErrorObservable);
    }
    //Notification
    GetApplicationsNotification(token )
    {
        const authHeader = new HttpHeaders();     
        authHeader.append('Content-Type', 'application/json');
        authHeader.append('Authorization', token);
        return new Promise((resolve, reject) =>
        {
            setTimeout(()=>
            {
                this.http.get(GlobalVariable.BASE_API_URL +'/api/LeaveApprove/GetApplicationNotification',{headers: authHeader})
                .subscribe(data => 
                {
                   
                    resolve(data)
                },
                error => 
                {
                    
                    reject(error);
                },);
            },3000);
        });
    }
    // End of the Leave Approval
    // Begning of the Regularation Approval
    GetEmployeeAttendanceRegularisationAppliesForRH(token )
    {
        const authHeader = new HttpHeaders();     
        authHeader.append('Content-Type', 'application/json');
        authHeader.append('Authorization', token);
        return new Promise((resolve, reject) =>
        {
            setTimeout(()=>
            {
                this.http.get(GlobalVariable.BASE_API_URL +'/api/RegularisationApproval/GetEmployeeAttendanceRegularisationAppliesForRH',{headers: authHeader})
                .subscribe(data => 
                {
                    resolve(data)
                },
                error => 
                {
                    reject(error);
                },);
            },3000);
        });
    }
    GetAttendanceRegularationApplications(token )
    {
        const authHeader = new HttpHeaders();     
        authHeader.append('Content-Type', 'application/json');
        authHeader.append('Authorization', token);
        return new Promise((resolve, reject) =>
        {
            setTimeout(()=>
            {
                this.http.get(GlobalVariable.BASE_API_URL +'/api/RegularisationApproval/GetEmployeeAttendanceRegularisationApplies',{headers: authHeader})
                .subscribe(data => 
                {
                    resolve(data)
                },
                error => 
                {
                    reject(error);
                },);
            },3000);
        });
    }
    GetAttendanceRegularationdetailsByID(token, id:number ) 
    {
        this.spinnerService.show();
        const authHeader = new HttpHeaders();     
        authHeader.append('Content-Type', 'application/json');
        authHeader.append('Authorization', token);
        return new Promise((resolve, reject) => 
        {
            setTimeout(()=>
            {
                this.http.get(GlobalVariable.BASE_API_URL +'/api/RegularisationApproval/GetEmployeeAttendanceRegularisationApplyByRefId?Refid='+id,{headers: authHeader})
                .subscribe(data => 
                {
                    this.spinnerService.hide();
                    resolve(data)
                },
                error => 
                {
                    this.spinnerService.hide();
                    reject(error);
                },);
            },3000);
        });
    }
    AttendanceRegularationApprovedOrRejected( token ,AttendanceRegularation :AttendanceRegularationModel) 
    {
        const authHeader = new HttpHeaders();         
        authHeader.append('Content-Type', 'application/json');
        authHeader.append('Authorization', token);
        authHeader.append('Accept', 'application/json');
        return this.http.put(GlobalVariable.BASE_API_URL +'/api/RegularisationApproval/AttendanceRegularationApprovedOrRejected', AttendanceRegularation ,{headers: authHeader} )
        .map(this.extractData)
        .catch(this.handleErrorObservable);
    }
    AttendanceRegularationCancel( token ,AttendanceRegularation : AttendanceRegularationModel) 
    {
        const authHeader = new HttpHeaders();         
        authHeader.append('Content-Type', 'application/json');
        authHeader.append('Authorization', token);
        authHeader.append('Accept', 'application/json');
        return this.http.put(GlobalVariable.BASE_API_URL +'/api/RegularisationApproval/AttendanceRegularationCancel', AttendanceRegularation ,{headers: authHeader} )
        .map(this.extractData)
        .catch(this.handleErrorObservable);
    }
    // End of the Regularation Approval
    // Begning of the Employee Confirmation Approval
    GetEmployeeForConfirmationlistForRH(token)
    {
        const authHeader = new HttpHeaders();     
        authHeader.append('Content-Type', 'application/json');
        authHeader.append('Authorization', token);
        return new Promise((resolve, reject) =>
        {
            setTimeout(()=>
            {
                this.http.get(GlobalVariable.BASE_API_URL +'/api/ConfirmationApproval/GetEmployeeForConfirmationlistForRH',{headers: authHeader})
                .subscribe(data => 
                {
                    resolve(data)
                },
                error => 
                {
                    reject(error);
                },);
            },3000);
        });
    }
    GetEmployeeForConfirmation(token)
    {
        const authHeader = new HttpHeaders();     
        authHeader.append('Content-Type', 'application/json');
        authHeader.append('Authorization', token);
        return new Promise((resolve, reject) =>
        {
            setTimeout(()=>
            {
                this.http.get(GlobalVariable.BASE_API_URL +'/api/ConfirmationApproval/GetEmployeeForConfirmation',{headers: authHeader})
                .subscribe(data => 
                {
                    resolve(data)
                },
                error => 
                {
                    reject(error);
                },);
            },3000);
        });
    }
    GetEmployeeByEmpIdForConfirmation(token, id:number ) 
    {
        this.spinnerService.show();
        const authHeader = new HttpHeaders();     
        authHeader.append('Content-Type', 'application/json');
        authHeader.append('Authorization', token);
        return new Promise((resolve, reject) => 
        {
            setTimeout(()=>
            {
                this.http.get(GlobalVariable.BASE_API_URL +'/api/ConfirmationApproval/GetEmployeeByEmpIdForConfirmation?EmployeeId='+id,{headers: authHeader})
                .subscribe(data => 
                {
                    this.spinnerService.hide();
                    resolve(data)
                },
                error => 
                {
                    this.spinnerService.hide();
                    reject(error);
                },);
            },3000);
        });
    }
    ConfirmationApprovedOrRejected( token ,EmployeeConfirmation :EmployeeConfirmationModel) 
    {
        const authHeader = new HttpHeaders();         
        authHeader.append('Content-Type', 'application/json');
        authHeader.append('Authorization', token);
        authHeader.append('Accept', 'application/json');
        return this.http.put(GlobalVariable.BASE_API_URL +'/api/ConfirmationApproval/ConfirmationApprovedOrRejected', EmployeeConfirmation ,{headers: authHeader} )
        .map(this.extractData)
        .catch(this.handleErrorObservable);
    }
    // End of the Employee Confirmation Approval
    private extractData(res: Response) 
    {
        let body = res;
        return body || {};
    }
    private handleErrorObservable (error: Response | any) 
    {
        console.error(error.message || error);
        return Observable.throw(error.message || error);
    }
}
