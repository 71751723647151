import { CrmMenuAccessUsers } from "./CrmMenuAccessUsers"
import { Router } from '@angular/router';
import { UserService } from '../../shared/user.service';

import { Injectable } from '@angular/core';
import { ConfirmationService } from '@jaspero/ng2-confirmations';
import { ResolveEmit } from '../../../app/resolve-emit';
import { ConfirmSettings } from '../../../app/confirm-settings';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import 'rxjs/add/observable/forkjoin';
import 'rxjs/add/observable/throw';
import 'rxjs/add/operator/toPromise';
import { Subscriber } from 'rxjs';
import { User } from '../user.model';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';

@Injectable()
export class CommonMenuHandelling 
{
  // For LogIn Method.    
  UserModel: User = new User();
  Users: User[] = [];
  User: any;
  ShowCrawsal: boolean;
  SelectedMainMenu: number;
  // Log Out Method 
  settings: ConfirmSettings | any =
  {
    overlay: true,
    overlayClickToClose: true,
    showCloseButton: true,
    confirmText: 'Yes',
    declineText: 'No'
  };

  SubMenu: CrmMenuAccessUsers[] = [];  // For Submenu Method.
  Mod: any;

  MainMenu: CrmMenuAccessUsers[] = []   // For Menu Method.

  Modules: CrmMenuAccessUsers[] = [];   // For All Modules
  constructor(private userService: UserService, private _confirmation: ConfirmationService, private router: Router)
  {
    this.SelectedMainMenu = 0;
  }

  getModule(): CrmMenuAccessUsers[] 
  {
    this.Modules = []
    let t = localStorage.getItem('userToken');
    t = 'Bearer ' + t;
    this.userService.Modulelist(t).then(x => 
    {
      this.Mod = x;
      Object.keys(this.Mod).forEach(key => 
      {
        this.Mod[key];
        let menutemp = new CrmMenuAccessUsers();
        menutemp = this.Mod[key];
        this.Modules.push(menutemp);
      });
    }).then(y => {
      return this.Modules;
    }).catch(k => {
      return this.Modules
    });
    return this.Modules;
  }

  SubModulelist(): CrmMenuAccessUsers[] 
  {
    this.Modules = []
    let t = localStorage.getItem('userToken');
    t = 'Bearer ' + t;
    let moduleid = localStorage.getItem("moduleidstor")
    this.userService.SubModulelist(t, moduleid).then(x => 
    {
      this.Mod = x;
      Object.keys(this.Mod).forEach(key => 
      {
        this.Mod[key];
        let menutemp = new CrmMenuAccessUsers();
        menutemp = this.Mod[key];
        this.Modules.push(menutemp);
      });
    }).then(y => {
      return this.Modules;
    }).catch(k => {
      return this.Modules
    });
    return this.Modules;
  }

  getMenus(): CrmMenuAccessUsers[] 
  {
    this.MainMenu = [];
    let t = localStorage.getItem('userToken');
    t = 'Bearer' + t;
    let moduleid = localStorage.getItem("moduleidstor")
    this.userService.CrmMainMenuList(t, moduleid).then(x => 
    {
      this.Mod = x;
      Object.keys(this.Mod).forEach(key => 
      {
        this.Mod[key];
        let menutemp = new CrmMenuAccessUsers();
        menutemp = this.Mod[key];
        this.MainMenu.push(menutemp);
      })
    }).then(y => {
      return this.MainMenu;
    }).catch(k => {
      return this.MainMenu
    });
    return this.MainMenu;
  }

  getSubmenu(): CrmMenuAccessUsers[] 
  {
    this.SubMenu = [];
    let t = localStorage.getItem('userToken');
    t = 'Bearer' + t;
    let moduleid = localStorage.getItem("moduleidstor");
    let menuid = localStorage.getItem("menuidstor");
    this.userService.GetCrmSubMenuList(t, menuid).then(x => 
    {
      this.Mod = x;
      Object.keys(this.Mod).forEach(key => 
      {
        this.Mod[key];
        let SubMenutemp = new CrmMenuAccessUsers();
        SubMenutemp = this.Mod[key];
        this.SubMenu.push(SubMenutemp);
      })
    }).then(y => {
      return this.SubMenu;
    }).catch(k => {
      return this.SubMenu
    });
    return this.SubMenu;
  }

  GetLoginDetails(): User 
  {
    let t = localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.userService.LoginDetails(t).then(x =>
    {
      this.User = x;
      this.UserModel.EmployeeCode = this.User.EmployeeCode;
      this.UserModel.EmployeeName = this.User.EmployeeName;
      this.UserModel.ImageFile = this.User.ImageFile;
      this.UserModel.ImageType = this.User.ImageType;
      this.UserModel.EmpPhotoURL = this.User.EmpPhotoURL;
      this.UserModel.DesignationName = this.User.DesignationName;
      this.UserModel.LastLoginTime = this.User.LastLoginTime;
      this.UserModel.IPAddress = this.User.IPAddress;
      this.UserModel.Id = this.User.Id;
      this.UserModel.CurrentTime = this.User.CurrentTime;
      this.UserModel.IconUrlLogoType = this.User.IconUrlLogoType;
      this.UserModel.IconUrlImage = this.User.IconUrlImage;
      this.UserModel.CompPhotoURL = this.User.CompPhotoURL;
      this.UserModel.CompIconUrlLogoType = this.User.CompIconUrlLogoType;
      this.UserModel.CompIconUrlImage = this.User.CompIconUrlImage;

      this.UserModel.DOB = this.User.DOB;
      this.UserModel.JoiningDate = this.User.JoiningDate;
      this.UserModel.BloodGroup = this.User.BloodGroup;
      this.UserModel.Branch = this.User.Branch;
      this.UserModel.Department = this.User.Department;
      this.UserModel.RHName = this.User.RHName;
      //alert("OK");
    }).then(y => 
    {
      return this.UserModel;
    }).catch(k => 
    {
      return this.UserModel
    });
    return this.UserModel;
  }

  LogOut()
  {
    this._confirmation.create('Confirm to LogOut', 'Press Yes to LogOut', this.settings)
    .subscribe((ans: ResolveEmit) => 
    {
      if (ans.resolved == true) 
      {
        let t = localStorage.getItem('userToken');
        t = 'Bearer' + t;
        this.userService.UpdateLogOutTime(t, this.UserModel.Id).subscribe(res => 
        {
          this.UserModel.Id = 0;
        });
        localStorage.removeItem('userToken');
        this.router.navigate(['/login']);
      }
      // alert("OK");
    })
  }

  // getLeaveNotification() 
  // {
  //   let t = localStorage.getItem('userToken');
  //   t = 'Bearer ' + t;
  //   this.leaveservice.GetLeaveApplicationsNotification(t).then(x => 
  //   {
  //     this.Mod = x;
  //     this.leaveservice.TotalNoofLeaveNotification = this.Mod;
  //   });
  // }

  fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  fileExtension = '.xlsx';

  public exportExcel(jsonData: any[], fileName: string): void {

    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(jsonData);
    const wb: XLSX.WorkBook = { Sheets: { 'data': ws }, SheetNames: ['data'] };
    const excelBuffer: any = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    this.saveExcelFile(excelBuffer, fileName);
  }

  private saveExcelFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], {type: this.fileType});
    FileSaver.saveAs(data, fileName + this.fileExtension);
  }

  

}