<div class="body-content-area tabcontent" id="link1">
  <div class="row">
      <div class="col-lg-9">
          <div class="row">
              <div class="col-lg-1 low-padding text-center">
                  <!-- <img *ngIf="this.SelectedMenu.Image!=null" src={{this.SelectedMenu.Image}} class="body-icon" alt="Icon"/> -->
              </div>
              <div class="col-lg-10 clear-padding">
                  <span class="page-title">Approve/Reject Tour</span>
                  <small class="page-description"></small>
              </div>
          </div>
      </div>
  </div>
  
  <div *ngIf="!(this.CanViewState)">
    <fieldset class="body-form-control">
      <legend class="legend--">Tour Details</legend>

      <div *ngIf="NoRecordMsg" style="color: #dc3545; text-align: center;font-size: 15px; font-weight: bold;">No Record Found for Approval.</div>

      <table id="tblApplication" *ngIf="(this.TourApplication.length > 0)" class="table table-striped table-bordered text-center table-new row-border hover">
        <thead class="thead-blue text-center">
          <tr style=" width: 100%;">
            <th style=" width: 8%;">Sl No.</th>
            <th style=" width: 8%;">Code</th>
            <th style=" width: 18%;" class="text-left">Name</th>
            <th style=" width: 10%;">Date</th>
            <th style=" width: 9%;">From Date</th>
            <th style=" width: 9%;">To Date</th>
            <th style=" width: 20%;" class="text-left">Tour Place</th>
            <th style=" width: 8%;">Action</th>
            <th style=" width: 10%;">
              <input type="checkbox" name="all" (change)="checkAll($event)"/>Select All
            </th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let appl of TourApplication, let i = index">
            <td scope="row">{{i+1}}</td>
            <td>{{appl.EmployeeCode}}</td>
            <td class="text-left">{{appl.EmployeeName}}</td>
            <td class="text-center">{{appl.ApplyDate| date : "dd-MMM-yyyy"}}</td>
            <td class="text-center">{{appl.FromDate| date : "dd-MMM-yyyy"}}</td>
            <td class="text-center">{{appl.ToDate| date : "dd-MMM-yyyy"}}</td>
            <td class="text-left">{{appl.TourPlace}}</td>
            <td>
              <button *ngIf="this.SelectedMenu.CanView" type="button" (click)="viewAppliedTour(appl.TourId,template)" class="table-view-button" type="button" ><i class="fas fa-eye"></i></button>
            </td>
            <td><input type="checkbox" [checked]="appl.Selected" id ="Selected_{{i}}" name="Selected_{{i}}" (change)="SelectedChange(i,$event)"></td>
          </tr>
        </tbody>
      </table>
      <form #RemarksForm="ngForm" *ngIf="(this.TourApplication.length > 0)">
        <div class="row">
          <div class="col-lg-4">
            <div class="col-body-border-right">
              <div class="form-group row">
                <label  class="col-sm-4 col-form-label clear-padding-right">Remarks</label>
                <div class="col-sm-7 clear-left-padding">
                  <input type="text" [(ngModel)]="ModelListApplication.RemarksAll" tabindex="1" maxlength="500" id ="RemarksAll" name="RemarksAll" #RemarksAll="ngModel" required class="form-control form-control-custom form-control-custom-new" >
                </div>
              </div>
            </div> 
          </div>
  
          <div class="col-lg-4">
            <div class="col-body-border-right">
              <div class="form-group row">
                <label *ngIf="!(this.CanApproveState)" class="col-sm-6 col-form-label text-center clear-padding-right">
                  <button type="submit" (click)="TourApprovedAll(template)" class="btn btn-primary save-button" [disabled]="!(RemarksForm.valid && this.DisableSave==true)">Approved All</button>  
                </label>
                <div *ngIf="!(this.CanApproveState)" class="col-sm-6 col-form-label text-center">
                  <button type="submit" (click)="TourRejectedAll(template)" class="btn btn-danger cancle-button" [disabled]="!(RemarksForm.valid && this.DisableSave==true)">Rejected All</button>  
                </div>
              </div>
            </div> 
          </div>
  
          <div class="col-lg-4">
            <div class="form-group row">
              <div class="col-sm-6 col-form-label text-center">
                <button type="button" (click)="RefreshPage()" class="btn btn-danger cancle-button">Refresh Page</button>
              </div>
            </div>
          </div>
        </div>
      </form>
    </fieldset>

    <div style=" box-align: center; width: 1500px;" >
      <ng-template #template>
        <div class="modal-header" style="background: #2a9bd7; max-width: 1500px;"> 
          <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()" style="color:#fff;">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div  style="text-align: center; max-width: 1500px;">
          <div *ngIf="ApprovedMsg" style="color: #dc3545; text-align: center;font-size: 15px; font-weight: bold;">Application Approved Successfully</div>
          <div *ngIf="RejectedMsg" style="color: #dc3545; text-align: center;font-size: 15px; font-weight: bold;">Application Rejected Successfully</div>
          <div style=" width: 100%;" *ngIf="ShowPopup">

            <fieldset *ngIf="GridShow" class="body-form-control" style=" text-align:left;"> 
              <legend class="legend--">Tour Approval</legend>
              <form #EmployeeTour="ngForm">
                <div class="row"> 
                  <div class="col-lg-4">
                    <div class="col-body-border-right">
                      <div class="form-group row">
                        <label  class="col-sm-4 col-form-label clear-padding-right">Code</label>
                        <div class="col-sm-7 clear-left-padding">
                          :&nbsp;&nbsp;{{this.ModelListApplication.EmployeeCode}}
                        </div>
                      </div>
                      <div class="form-group row">
                        <label  class="col-sm-4 col-form-label clear-padding-right">From Date</label>
                        <div class="col-sm-7 clear-left-padding">
                          :&nbsp;&nbsp;{{this.ModelListApplication.FromDate| date : "dd-MMM-yyyy"}}
                        </div>
                      </div>
                      <div class="form-group row">
                        <label class="col-sm-4 col-form-label clear-padding-right">Tour Place</label>
                        <div class="col-sm-7 clear-left-padding">
                          :&nbsp;&nbsp;{{this.ModelListApplication.TourPlace}}
                        </div>
                      </div>         
                    </div>  
                  </div>
          
                  <div class="col-lg-4">
                    <div class="col-body-border-right">
                      <div class="form-group row">
                        <label  class="col-sm-4 col-form-label clear-padding-right">Name</label>
                        <div class="col-sm-7 clear-left-padding">
                          :&nbsp;&nbsp;{{this.ModelListApplication.EmployeeName}}
                        </div>
                      </div>
                      <div class="form-group row">
                        <label  class="col-sm-4 col-form-label clear-padding-right">To Date</label>
                        <div class="col-sm-7 clear-left-padding">
                          :&nbsp;&nbsp;{{this.ModelListApplication.ToDate| date : "dd-MMM-yyyy"}}
                        </div>
                      </div>
                      <div class="form-group row">
                          <label class="col-sm-4 col-form-label clear-padding-right">Remarks</label>
                          <div class="col-sm-7 clear-left-padding">
                              <input type="text" tabindex="3" [(ngModel)]="ModelListApplication.Remarks" maxlength="500" id ="Remarks" name="Remarks" #Remarks="ngModel" required class="form-control form-control-custom form-control-custom-new">
                          </div>
                          <div *ngIf="Remarks.invalid && (Remarks.dirty || Remarks.touched)" class="alert alert-danger">
                            <div *ngIf="Remarks.errors.required">Remarks is required.</div>
                            <div *ngIf="Remarks.errors.maxlength">Remarks is not more than 500 characters</div>
                          </div>
                        </div>
                    </div> 
                  </div>
          
                  <div class="col-lg-4">
                    <div class="form-group row">
                      <label  class="col-sm-4 col-form-label clear-padding-right">Approver</label>
                      <div class="col-sm-7 clear-left-padding">
                        :&nbsp;&nbsp;{{this.ModelListApplication.RHName}} 
                      </div>
                    </div>
                    <div class="form-group row">
                      <label class="col-sm-4 col-form-label clear-padding-right">RH EMail</label>
                      <div class="col-sm-7 clear-left-padding">
                        :&nbsp;&nbsp;{{this.ModelListApplication.RHEmailId}} 
                      </div>
                    </div>
                    <div class="form-group row">
                      <label *ngIf="!(this.CanApproveState)" class="col-sm-6 col-form-label text-center clear-padding-right">
                        <button type="submit" tabindex="2" (click)="TourApproved(template)" class="btn btn-primary save-button" [disabled]="!(EmployeeTour.valid && this.DisableSave==true)">Approved</button>  
                      </label>
                      <div *ngIf="!(this.CanApproveState)" class="col-sm-6 col-form-label text-center">
                        <button type="submit" tabindex="3" (click)="TourRejected(template)" class="btn btn-danger cancle-button" [disabled]="!(EmployeeTour.valid && this.DisableSave==true)">Rejected</button>  
                      </div>
                    </div>
                  </div> 
                </div> 
              </form>
            </fieldset>
          </div>

          <button type="button" aria-label="Close" (click)="modalRef.hide()" style="background: #2a9bd7; outline: 0px none; border: 0px none; color: #fff; padding: 5px 10px; border-radius: 3px;">
            <span>Close</span>
          </button>             
        </div>
      </ng-template>
    </div>

  </div>
</div>