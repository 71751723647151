import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from '../../shared/user.service';
import { EmployeePortalService }  from '../../shared/HR_ADMIN/EmployeePortal/EmployeePortalService.service';
import { TourReimbursementMasterModel } from '../../shared/HR_ADMIN/EmployeePortal/TourReimbursementMasterModel.Model';
import { TourReimbursementDetailsModel } from '../../shared/HR_ADMIN/EmployeePortal/TourReimbursementDetailsModel.Model';
import { ReimbursementFileUploadModel } from '../../shared/HR_ADMIN/EmployeePortal/ReimbursementFileUploadModel.Model';
import { Menu } from '../../shared/menu.model';
import { ModalOptions,BsModalRef, BsModalService } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-tour-reimbursement-status',
  templateUrl: './tour-reimbursement-status.component.html',
  styleUrls: ['./tour-reimbursement-status.component.css']
})
export class TourReimbursementStatusComponent implements OnInit 
{
  TourReimbursementMasterModel : TourReimbursementMasterModel= new TourReimbursementMasterModel();
  ApplicationStatus: TourReimbursementMasterModel[]=[];
  TourExpenseHeads: TourReimbursementDetailsModel[]=[];
  Application: any;
  userClaims: any;
  Result : string;
  SuccessStatus:boolean=false;
  SuccessMessage: string ="";
  public tableWidget: any;
  selectedIndex : number;
  // Begning Permission Portion    
  SelectedMenu:Menu;
  // End of the permission portion
  //For Pop up
  ModelListApplication: TourReimbursementMasterModel;
  ShowMsg : boolean= false;
  ShowPopup : boolean= false;
  public modalRef: BsModalRef;
  public modelopt:ModalOptions;
  Approver : any;
  DisableSave : boolean;
  RefId : any;
  TotalExceptedApprovedAmount : number=null;
  //file Upload
  images:any=[];
  allfiles:any=[];
  FilesToUpload : ReimbursementFileUploadModel[]=[];

  constructor(private EmployeePortalService: EmployeePortalService, private rout: Router,private userService: UserService,private modalService: BsModalService)
  {
    this.ModelListApplication = new TourReimbursementMasterModel();
    this.modelopt=new ModalOptions();
    this.modelopt.class='heavy_modal';
    this.modalService.config= this.modelopt;
    this.modelopt.ignoreBackdropClick=true;
   }

  ngOnInit() 
  {
    // Begning Permission Portion 
    this.SelectedMenu=this.userService.SelectedMenu;
    if(this.SelectedMenu !=undefined)
    {
      localStorage.setItem('selectedmenu',JSON.stringify(this.SelectedMenu));
      this.rout.navigate([this.SelectedMenu.RouteName]);
    }
    else
    {
      if(JSON.parse(localStorage.getItem('selectedmenu'))!=undefined)
      {
        this.SelectedMenu = JSON.parse(localStorage.getItem('selectedmenu'));
        this.rout.navigate([this.SelectedMenu.RouteName]);
      }
    }
    // End of the permission portion
    if(localStorage.getItem('SuccessfullMessage'))
    {
      this.SuccessStatus=true;
      this.SuccessMessage =localStorage.getItem('SuccessfullMessage')
      localStorage.removeItem('SuccessfullMessage');
    }
    this.GetEmployeeDetails();
    this.GetExpenseDetailsStatusGV();
    this.DisableSave=true;
  }

  GetEmployeeDetails()
  {
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.EmployeePortalService.GetEmployeeDetailsByUserLogingIdLeaveApply( t).then(x => 
    {
      this.Application = x;
      Object.keys(this.Application).forEach( key => 
      {
        this.Application[key];
        let Applicationtemp = new TourReimbursementMasterModel();
        Applicationtemp = this.Application[key];
        this.TourReimbursementMasterModel.EmployeeId=this.Application.EmployeeId;
        this.TourReimbursementMasterModel.EmployeeCode=this.Application.EmployeeCode;
        this.TourReimbursementMasterModel.EmployeeName=this.Application.EmployeeName;
        this.TourReimbursementMasterModel.RHId=this.Application.ReportingHeadId;
        this.TourReimbursementMasterModel.RHName=this.Application.ReportingHeadName;
        this.TourReimbursementMasterModel.RHEmailId=this.Application.RHEmailId;
      });
    });
  }

  GetExpenseDetailsStatusGV()
  {
    this.ApplicationStatus=[];
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.EmployeePortalService.GetExpenseDetailsStatusGVByUserId( t).then(x => 
    {
      this.Application = x;
      Object.keys(this.Application).forEach( key => 
      {
        this.Application[key];
        let Applicationtemp = new TourReimbursementMasterModel();
        Applicationtemp = this.Application[key];
        this.ApplicationStatus.push(Applicationtemp);
      });
    });
  }

  viewTourReimbursementDetails(id,template) 
  {
    this.DisableSave=true;
    this.images=[];
    this.FilesToUpload=[];
    this.ModelListApplication.FinalExpecetdAmount=0;
    this.ModelListApplication.FinalRemarks="";
    this.GetExpenseDetailsByRefIdTR(id);
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.EmployeePortalService.GetReibursementDetailsByRefId( t,id).then(x => 
    {
      if(this.ShowPopup==true)
      {
        this.modalRef.hide();
      }
      this.modalRef = this.modalService.show(template,this.modelopt);
      this.ShowPopup=true;
      this.ShowMsg=false;
      this.Approver = x;
      Object.keys(this.Approver).forEach( key => 
      {
        this.Approver[key];
        let Approvetemp = new TourReimbursementMasterModel();
        Approvetemp = this.Approver;
        this.ModelListApplication.RefIdTR=Approvetemp.RefIdTR;
        this.ModelListApplication.EmployeeId=Approvetemp.EmployeeId;
        this.ModelListApplication.EmployeeCode=Approvetemp.EmployeeCode;
        this.ModelListApplication.EmployeeName=Approvetemp.EmployeeName; 
        this.ModelListApplication.EmployeeEmailId=Approvetemp.EmployeeEmailId; 
        this.ModelListApplication.RHId=Approvetemp.RHId; 
        this.ModelListApplication.RHName=Approvetemp.RHName; 
        this.ModelListApplication.FHName=Approvetemp.FHName; 
        this.ModelListApplication.RHCode=Approvetemp.RHCode; 
        this.ModelListApplication.RHEmailId=Approvetemp.RHEmailId; 
        this.ModelListApplication.HREmailIds=Approvetemp.HREmailIds; 
        this.ModelListApplication.HRNames=Approvetemp.HRNames; 
        this.ModelListApplication.ExpenseName=Approvetemp.ExpenseName; 
        this.ModelListApplication.NoOfDays=Approvetemp.NoOfDays; 
        this.ModelListApplication.NOofTrip=Approvetemp.NOofTrip;
        this.ModelListApplication.NoOfNights=Approvetemp.NoOfNights;
        this.ModelListApplication.FromDate=Approvetemp.FromDate; 
        this.ModelListApplication.ToDate=Approvetemp.ToDate;
        this.ModelListApplication.TourPlace=Approvetemp.TourPlace; 
        this.ModelListApplication.AdvanceAmount=Approvetemp.AdvanceAmount;
        this.ModelListApplication.AdvancePaidDate=Approvetemp.AdvancePaidDate;
        this.ModelListApplication.ExpectedExpenseAmount=Approvetemp.ExpectedExpenseAmount;
        this.ModelListApplication.RHRemarks=Approvetemp.RHRemarks;
        this.ModelListApplication.HRRemarks=Approvetemp.HRRemarks;
        this.ModelListApplication.FHRemarks=Approvetemp.FHRemarks;
        this.ModelListApplication.FileName=null;
        this.ModelListApplication.NetAvailableAmount=Approvetemp.NetAvailableAmount;
      });
    });
  }
  
  GetExpenseDetailsByRefIdTR(RefIdTR)
  {
    this.TotalExceptedApprovedAmount=0;
    this.TourExpenseHeads=[];
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.EmployeePortalService.GetExpenseDetailsForFinalSubmissionByRefIdTR( t,RefIdTR).then(x => 
    {
      this.Application = x;
      Object.keys(this.Application).forEach( key => 
      {
        this.Application[key];
        let Applicationtemp = new TourReimbursementDetailsModel();
        Applicationtemp = this.Application[key];
        this.TotalExceptedApprovedAmount =  Number(this.TotalExceptedApprovedAmount) + (Number(Applicationtemp.ApprovedExpenseAmount));

        if(Applicationtemp.DayWise == true && Applicationtemp.RefIdTRD == 0)
        {
          Applicationtemp.BudgetAmount=Applicationtemp.BudgetAmount * this.ModelListApplication.NoOfDays;
        }
        else if(Applicationtemp.OverAll == true && Applicationtemp.RefIdTRD == 0)
        {
          Applicationtemp.BudgetAmount=Applicationtemp.BudgetAmount * this.ModelListApplication.NOofTrip;
        }

        if(Applicationtemp.FinalExpenseAmount==0)
        {
          Applicationtemp.FinalExpenseAmount=null;
        }
        this.TourExpenseHeads.push(Applicationtemp);
      });
      this.ModelListApplication.ApprovedExpenseAmount=this.TotalExceptedApprovedAmount;
    });
  }

  FinalExpenseAmountChanged()
  {
    this.TotalExceptedApprovedAmount=0;
    var j=0;
    var l=this.TourExpenseHeads.length;
    this.TourExpenseHeads.forEach(item => 
    {
      j=j+1;
      if(item.BudgetAmount >= item.FinalExpenseAmount)
      { 
        this.TotalExceptedApprovedAmount=  Number(this.TotalExceptedApprovedAmount) + (Number(item.FinalExpenseAmount));
        this.ModelListApplication.FinalExpecetdAmount=this.TotalExceptedApprovedAmount;
      }
      else
      {
        item.FinalExpenseAmount=null;
      }
    });
  }

  fileuploads(event,refid,ExpenseHeadId)
  {
    const files=event.target.files;
    //console.log(files);
    if(files)
    {
      for(let i=0; i<files.length; i++){
        const image =
        {
          name :'',
          type : '',
          size: '',
          url:'',
          TRDRefId:'',
          ExpenseHeadId:''
        } ;
        this.allfiles.push(files[i]);
        image.name=files[i].name;
        image.type=files[i].type;
        image.TRDRefId=refid;
        image.ExpenseHeadId=ExpenseHeadId;
        const size= files[i].size /1000;
        const mbc =  size + '';
        const mb = mbc.split('.')[0];
        const length = mb.length;

        if(length===4 || length===5)
        {
          const mbsize= size /1000;
          const splitdata = mbsize + '';
          const splitvalue= splitdata.split('.');
          let secondvariable ='';
          for(let j=0; j<splitvalue.length; j++)
          {
            if(j===1)
            {
              secondvariable= splitvalue[j].slice(0,2);
            }
          }
          image.size=splitvalue[0]  + '.' + secondvariable + ' MB';
        }
        else
        {         
          const splitdata = size + '';
          const splitvalue= splitdata.split('.');
          let secondvariable ='';
          for(let j=0; j<splitvalue.length; j++)
          {
            if(j===1)
            {
              secondvariable= splitvalue[j].slice(0,2);
            }
          }
          image.size=splitvalue[0]  + '.' + secondvariable + ' KB';
        }
        let ObjFileUpload = new ReimbursementFileUploadModel();
        var FileReades : FileReader= new FileReader();
        let filetyotypearray = image.type.split("/");
        var re = /(?:\.([^.]+))?$/;
        ObjFileUpload.FileExtension = re.exec(image.name)[1];  
                 
        ObjFileUpload.FileType= filetyotypearray[0];
        ObjFileUpload.Filesize= image.size;
        // image.size=files[i].size /1000;
        const reader= new FileReader();
        reader.onload=(filedata)=>
        {
          image.url=reader.result + '';
          ObjFileUpload.File = String( reader.result);
        };
        reader.readAsDataURL(files[i]);
        // reader.readAsText(files[i]);
        this.images.push(image);
        ObjFileUpload.Filename=image.name;
        ObjFileUpload.TRDRefId=refid;
        ObjFileUpload.ExpenseHeadId=ExpenseHeadId;
        this.FilesToUpload.push(ObjFileUpload);
      }
      event.srcElement.value=null;
    }
  }

  deleteImage(image:any)
  {
    const index= this.images.indexOf(image);
    this.images.splice(index,1);
    this.allfiles.splice(index,1);
    this.FilesToUpload.splice(index,1);
    const indexF = this.FilesToUpload.indexOf(index,1)
  }

  ReSubmitTourReimbursement(template):void
  {
    this.DisableSave=false;
    let t = localStorage.getItem('userToken');
    t = 'Bearer' + t;
    if(this.ModelListApplication.RefIdTR > 0)
    {
      this.ModelListApplication.TourReimbursementDetails=this.TourExpenseHeads;
      this.ModelListApplication.TourReimbursementDetails.forEach(element => 
      {
        if(this.FilesToUpload.find(x=>x.ExpenseHeadId==element.ExpenseHeadId) !=null)
        { 
          let test =this.ModelListApplication.TourReimbursementDetails.find(x=>x.ExpenseHeadId==element.ExpenseHeadId);
          if(test.ReimbursementFileUploadModel == null)
          {
            this.ModelListApplication.TourReimbursementDetails.find(x=>x.ExpenseHeadId==element.ExpenseHeadId).ReimbursementFileUploadModel=[];
          }
          this.FilesToUpload.filter(x=>x.ExpenseHeadId==element.ExpenseHeadId).forEach(x=>
          {
            this.ModelListApplication.TourReimbursementDetails.find(x=>x.ExpenseHeadId==element.ExpenseHeadId).ReimbursementFileUploadModel.push( x);
          });
        }
      });
      this.EmployeePortalService.FinalSubmitEmployeeTourReimbursementApplication(t ,this.ModelListApplication).subscribe(res=>
      {
        this.RefId=res;
        this.ModelListApplication.RefIdTR=0; 
        if(this.RefId>0)
        {
          this.ModelListApplication.FinalRemarks="";
          this.modalRef.hide();
          this.ShowPopup=false;
          this.ShowMsg=true;
          this.modalRef = this.modalService.show(template);
          this.GetExpenseDetailsStatusGV();
          this.DisableSave=true;
          this.images=[];
          this.FilesToUpload=[];
          this.ModelListApplication.FinalExpecetdAmount=0;
        }
      }); 
    }
  }

  IntelligenceReportChange(event)
  {
    this.ModelListApplication.FileName=null;
    const files=event.target.files;
    //console.log(files);
    if(files)
    {
      for(let i=0; i<files.length; i++)
      {
        const image =
        {
          name :'',
          type : '',
          size: '',
          url:'',
          TRDRefId:'',
          ExpenseHeadId:''
        } ;
        this.allfiles.push(files[i]);
        image.name=files[i].name;
        image.type=files[i].type;
        const size= files[i].size /1000;
        const mbc =  size + '';
        const mb = mbc.split('.')[0];
        const length = mb.length;

        if(length===4 || length===5)
        {
          const mbsize= size /1000;
          const splitdata = mbsize + '';
          const splitvalue= splitdata.split('.');
          let secondvariable ='';
          for(let j=0; j<splitvalue.length; j++)
          {
            if(j===1)
            {
              secondvariable= splitvalue[j].slice(0,2);
            }
          }
          image.size=splitvalue[0]  + '.' + secondvariable + ' MB';
        }
        else
        {         
          const splitdata = size + '';
          const splitvalue= splitdata.split('.');
          let secondvariable ='';
          for(let j=0; j<splitvalue.length; j++)
          {
            if(j===1)
            {
              secondvariable= splitvalue[j].slice(0,2);
            }
          }
          image.size=splitvalue[0]  + '.' + secondvariable + ' KB';
        }

        var FileReades : FileReader= new FileReader();
        let filetyotypearray = image.type.split("/");
        var re = /(?:\.([^.]+))?$/;
        this.ModelListApplication.FileExtension = re.exec(image.name)[1];  
        this.ModelListApplication.FileType= filetyotypearray[0];
        this.ModelListApplication.Filesize= image.size;
        // image.size=files[i].size /1000;
        const reader= new FileReader();
        reader.onload=(filedata)=>
        {
          image.url=reader.result + '';
          this.ModelListApplication.File = String( reader.result);
        };
        reader.readAsDataURL(files[i]);
        this.ModelListApplication.FileName=image.name;
      }
      event.srcElement.value=null;
    }
  }

  download()
  {
    this.EmployeePortalService.getFile("MARKET_VISIT_REPORT_Blank.docx");  
  }
}