export class MenuMasterModel
{
    public MenuID : number;
    public MenuTitle : string;
    public ModuleId : number;
    public ModuleName : string;
    public HaveChild : boolean;
    public ParentId :number;
    public RouteName : string;
    public IsActive : boolean;
    public IconUrl : string;
    public SmallIconUrl :string;
    public DefaultPage: boolean;
    
    public Logodoctype:string;
    public IconUrlvalidfile:boolean;
    public IconUrlLogoType:string;
    public Islogochange: boolean;
    public IconUrlImage :string;
    public SmallIconUrlvalidfile:boolean;
    public SmallIconUrlLogoType:string;
    public IsSmalllogochange: boolean;
    public SmallIconUrlImage :string;
}