import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs/Subject';
import { Menu } from '../../../shared/menu.model';
import { UserService } from '../../../shared/user.service';
import { CrmStateService } from '../../../shared/CRM/MICECRM/CrmStateService';
import { Model_CRM_State } from '../../../shared/CRM/MICECRM/Model_CRM_State';
//Begin For Notification service
import { NotificationsService } from '../../../shared/notifications.service';
import { DataTableDirective } from 'angular-datatables';

import { ConfirmationService } from '@jaspero/ng2-confirmations';
import { ResolveEmit } from '@jaspero/ng2-confirmations/src/interfaces/resolve-emit';

// DropDown 
import { Model_CrmCountry } from '../../../shared/CRM/MICECRM/Model_CrmCountry';
import { Model_CRM_Zone } from '../../../shared/CRM/MICECRM/Model_CRM_Zone';
import { ConfirmSettings } from '@jaspero/ng2-confirmations/src/interfaces/confirm-settings';

//End of notification service

@Component({
  selector: 'app-crm-state',
  templateUrl: './crm-state.component.html',
  styleUrls: ['./crm-state.component.css']
})
export class CrmStateComponent implements OnInit {

  
  
    // For Grid Fetch
    CRMState:any;
    statelist: Model_CRM_State[] = [];


    ZoneArray : any [] =[];
    CountryArray: any [] =[];


    ZoneSettings={}
    CountrySttings ={}

  // For Save- UPdate - Delete .

      StateId: number;
      DeleteStateNameByID: any;
      

      SelectedCountry=[];
      SelectedZone=[];

     SuccessStatus : boolean;
     SuccessMessage : string;


      // For Populate Country Dropdown
      CRM_State_Model:Model_CRM_State;
      CountryName: any;
      countrylist: Model_CrmCountry[] = [];
      Crm_Country: Model_CrmCountry;


      // For Populate Zone Dropdown
        ZoneName: any;
        zonelist: Model_CRM_Zone[] = [];


          //  View  Field Decleration Starts
          check: string;
          CheckExiststatus: boolean;
          Checkeditstatus: boolean;
          checkdataval: any;

          //  View  Field Decleration Starts



  @ViewChild(DataTableDirective, {static: false})
  setDtOptions: DataTables.Settings = {};
  cardDtOptions: DataTables.Settings = {};
  dtElement: DataTableDirective;
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();



  // Begning Permission Portion    
  SelectedMenu: Menu;
  IsDataIsInViewState: boolean = false;
  CanViewState: boolean = true;
  selectedIndex: number;
  // End of the permission portion

  constructor(private CRM_StateService: CrmStateService, private rout: Router, public userService: UserService, private notificationsService: NotificationsService, private _confirmation: ConfirmationService  ) {
    this.CRM_State_Model = new Model_CRM_State();
    this.CRM_State_Model.CountryId = 0;
    notificationsService.emitChange.subscribe(slectedtabindex => {
      if (slectedtabindex != null) {
        this.selectedIndex = slectedtabindex;
      }
    });
  }

  

  settings: ConfirmSettings | any =
    {
      overlay: true,
      overlayClickToClose: true,
      showCloseButton: true,
      confirmText: 'Yes',
      declineText: 'No'
    };

  ngOnInit() {

    this.rout.navigate([this.userService.selectedCrmMenu.RouteName]);
     
    if(localStorage.getItem('SuccessfullMessage'))
    {
      this.SuccessStatus=true;
      this.SuccessMessage =localStorage.getItem('SuccessfullMessage')
      localStorage.removeItem('SuccessfullMessage');
    }  

    
    this.ZoneSettings={
      singleSelection:true,
      text:'Select Zone',
      enableSearchFilter:true,
      showCheckbox:false
    }
    
    this.CountrySttings={
      singleSelection:true,
      text:'Select Country',
      enableSearchFilter:true,
      showCheckbox:false
    }



    this.CRM_State_Model.CountryId = 0;
    this.CRM_State_Model.ZoneId=0;
    this.CountryArray=[];
    this.ZoneArray  =[];
    this.GetCountry();
   
    this.GetCRMState();
    this.SelectedCountry=[{"id":0,"itemName":'Select Country'  }];
  }


  ngAfterViewInit(): void {

    this.dtOptions =
      {
        pagingType: 'full_numbers',
        pageLength: 4
      };


      

  }

  

          //Populate  Country Drop  Down  

          GetCountry()
           {
            let t = localStorage.getItem('userToken');
            t = 'Bearer' + t
            this.countrylist = [];
            this.CRM_StateService.GetCountryType(t).then(x => {
              this.CountryName = x;
              Object.keys(this.CountryName).forEach(key => {
                let CountryNametemp = new Model_CrmCountry();
                CountryNametemp = this.CountryName[key];
                this.countrylist.push(CountryNametemp);

                this.CountryArray.push({"id":CountryNametemp.CountryId,"itemName":CountryNametemp.CountryName  },);


              });
              this.SelectedZone=[{"id":0,"itemName":'Select Zone'  }];

            });
          }

          //Populate  Country Drop  Down  ENDS


  //Populate  ZOne  Drop  Down  

  GetZone(Countryid : number) {
    let t = localStorage.getItem('userToken');
    t = 'Bearer' + t
    this.zonelist = [];
    this.CRM_StateService.GetZoneType(t,Countryid).then(x => {
      this.ZoneName = x;
      Object.keys(this.ZoneName).forEach(key => {
        let ZoneNametemp = new Model_CRM_Zone();
        ZoneNametemp = this.ZoneName[key];
        this.zonelist.push(ZoneNametemp);
        this.ZoneArray.push({"id":ZoneNametemp.ZoneId,"itemName":ZoneNametemp.ZoneName  },);
      });

    }).then(t=>{
      
      if(this.CRM_State_Model.ZoneId > 0)
      {  
        this.SelectedZone=[]; 
        let Zone = this.zonelist.find(x=>x.ZoneId==this.CRM_State_Model.ZoneId);  
            
        this.SelectedZone.push({"id":Zone.ZoneId,"itemName":Zone.ZoneName  },);
      }    
      
    });
    
    
  }

          //Populate  Country Drop  Down  ENDS


          onItemSelectCountry(item:any){    
    
            let CountryId= item['id'];       
            this.CRM_State_Model.CountryId=CountryId;
              
            this.ZoneArray  =[];
            if(this.CRM_State_Model.CountryId > 0){
              this.GetZone(this.CRM_State_Model.CountryId);

            }
            

            //this.DisableSave=true;
            //this.GetCRMRollMainMenu(this.ModelCrmMenu.Role_Id);  
            //this.GetCRMRollChildMenu(this.ModelCrmMenu.Role_Id,this.ModelCrmMenu.ParentId);
            }
            OnItemDeSelectCountry(item:any){ }
            onSelectAllCountry(items: any){ }
            onDeSelectAllCountry(items: any){ }



            onItemSelecZone(item:any){    
    
              let ZoneId= item['id'];       
              this.CRM_State_Model.ZoneId=ZoneId;
              //this.DisableSave=true;
              //this.GetCRMRollMainMenu(this.ModelCrmMenu.Role_Id);  
              //this.GetCRMRollChildMenu(this.ModelCrmMenu.Role_Id,this.ModelCrmMenu.ParentId);
              }
              OnItemDeSelectZone(item:any){ }
              onSelectAllZone(items: any){ }
              onDeSelectAllZone(items: any){ }
      


  // Save Region  Starts

  saveCRMState(): void {
    this.IsDataIsInViewState = false;
    let t = localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.CRM_State_Model.IsActive = true;

    if (this.CRM_State_Model.StateId > 0) {
      this.CRM_StateService.UpdateCrmState(t, this.CRM_State_Model).subscribe(res => {
        this.StateId = Number(res);
        this.CRM_State_Model.StateId = 0;
        this.CRM_State_Model.ZoneId = 0;
        this.CRM_State_Model.CountryId = 0;
       
        this.CRM_State_Model.CountryId = 0;
        if (this.StateId > 0) {
          
          localStorage.setItem('SuccessfullMessage', "Successfully Updated");
          this.rout.navigateByUrl('ManageMICEAccess/CrmZone', { skipLocationChange: true }).then(() => this.rout.navigate(["ManageMICEAccess/CrmState"]));

        }
      });
    }
    else {
      this.CRM_StateService.AddCrmState(t, this.CRM_State_Model).subscribe(res => {
        this.StateId = Number(res);
        this.CRM_State_Model.StateId = 0;

        if (this.StateId > 0) {
          localStorage.setItem('SuccessfullMessage', "Successfully Added");
          this.rout.navigateByUrl('ManageMICEAccess/CrmZone', { skipLocationChange: true }).then(() => this.rout.navigate(["ManageMICEAccess/CrmState"]));

        }
      });
    }
  }


  //  Update  Section Starts  

          //  Grid  Data  Fetch 

          GetCRMState() {

            let t = localStorage.getItem('userToken');
            t = 'Bearer' + t;
            this.statelist = [];
            this.CRM_StateService.Get_CRMState(t).then(x => {
              this.CRMState = x;
              Object.keys(this.CRMState).forEach(key => {
                this.CRMState[key];
                let CRMStatetemp = new Model_CRM_State();
                CRMStatetemp = this.CRMState[key];

                this.statelist.push(CRMStatetemp);
              });
              this.dtTrigger.next();
            });
          }

  //  Grid  Data  Fetch Ends


  //  Delete  Part  Starts  

  DeleteState(ID: number): void {
    // alert(ID);
    this._confirmation.create('Confirm to Delete', 'Press Yes to Delete', this.settings)
      .subscribe((ans: ResolveEmit) => {
        if (ans.resolved == true) {

          let t = localStorage.getItem('userToken');
          t = 'Bearer' + t;

          if (ID > 0) {
            this.CRM_StateService.DeleteCRMState(t, ID).subscribe(res => {
              this.DeleteStateNameByID = res;

              if (this.DeleteStateNameByID > 0) {
                localStorage.setItem('SuccessfullMessage', "Successfully Deleted");
                this.rout.navigateByUrl('ManageMICEAccess/ManageMICEUser', { skipLocationChange: true }).then(() => this.rout.navigate(["ManageMICEAccess/CrmState"]));
              }
            });
          }

        }
      })
  }

        //  Delete  Part  Starts

        //  View  Section Starts  


        ViewCRMStateById(Id) {

          this.IsDataIsInViewState = true;
          let t = localStorage.getItem('userToken');
          t = 'Bearer' + t;
          this.CRM_StateService.GetCRM_State_By_id(t, Id).then(x => {
            this.CRMState = x;
            Object.keys(this.CRMState).forEach(key => {

              let CRMStatetemp = new Model_CRM_State();
              CRMStatetemp = this.CRMState[key];

              this.CRM_State_Model.StateId = CRMStatetemp.StateId;
              this.CRM_State_Model.StateName = CRMStatetemp.StateName;
              this.CRM_State_Model.ZoneId=CRMStatetemp.ZoneId;
              this.CRM_State_Model.ZoneName = CRMStatetemp.ZoneName;
              this.CRM_State_Model.CountryId=CRMStatetemp.CountryId;
              this.CRM_State_Model.CountryName = CRMStatetemp.CountryName;
              this.CRM_State_Model.IsActive = CRMStatetemp.IsActive;

            });


            this.checkdataval = this.CRM_State_Model.StateId;
            this.Checkeditstatus = false;
            this.CheckExiststatus = false;
            this.check = ""
          });
        }

        // View Section Ends 




        //  Update Section  Strats

         GetCRMStateById(Id) {

            this.IsDataIsInViewState = false;
          // End of the permision portion
          let t = localStorage.getItem('userToken');
          t = 'Bearer' + t;
           this.CRM_StateService.GetCRM_State_By_id(t, Id).then(x => {
            this.CRMState = x;
             Object.keys(this.CRMState).forEach(key => {

               let CRMStatetemp = new Model_CRM_State();
               CRMStatetemp = this.CRMState[key];


               this.CRM_State_Model.StateId = CRMStatetemp.StateId;
               this.CRM_State_Model.StateName = CRMStatetemp.StateName;
               this.CRM_State_Model.ZoneId = CRMStatetemp.ZoneId;
               this.CRM_State_Model.ZoneName = CRMStatetemp.ZoneName;
               this.CRM_State_Model.CountryId = CRMStatetemp.CountryId;
               this.CRM_State_Model.CountryName = CRMStatetemp.CountryName;
               this.CRM_State_Model.IsActive = CRMStatetemp.IsActive;

      })

    }).then(y=>{

     if(this.CRM_State_Model.CountryId > 0){
   let Country = this.countrylist.find(x=>x.CountryId==this.CRM_State_Model.CountryId);
   this.SelectedCountry=[];
   this.SelectedCountry.push({"id":Country.CountryId,"itemName":Country.CountryName  },);

     this.GetZone(this.CRM_State_Model.CountryId);
     }


    })
  }

        //  Update Section Ends


        RefreshPage()
        {
        
          this.rout.navigateByUrl('ManageMICEAccess/CrmZone', { skipLocationChange: true }).then(() => this.rout.navigate(["ManageMICEAccess/CrmState"]));
        }

 
}
