<div class="body-content-area tabcontent" id="link1">
  <div class="row">
    <div class="col-lg-9">
      <div class="row">
        <div class="col-lg-1 low-padding text-center">
          <!-- <img *ngIf="this.SelectedMenu.Image!=null" src={{this.SelectedMenu.Image}} class="body-icon" alt="Icon"/> -->
          <i class="fas fa-address-book"></i>
        </div>
        <div class="col-lg-10 clear-padding">
          <span class="page-title">Manage Bank Master</span>
          <small class="page-description"></small>
        </div>
      </div>
    </div>
  </div>
  
  <div *ngIf="!(this.CanViewState)">  
    <table id="tblBankMaster" datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" class="table table-striped table-bordered text-center table-new row-border hover">
      <thead class="thead-blue text-center">
        <tr>
          <th>Sl No.</th>
          <th>Code</th>
          <th>Bank</th>
          <th>Address</th>
          <th>IFSC</th>
          <th>Action</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let bank of Banks, let i = index">
          <td scope="row">{{i+1}}</td>
          <td>{{bank.BankCode}}</td>
          <td>{{bank.BankName}}</td>
          <td>{{bank.BankAddress}}</td>
          <td>{{bank.IFSCCode}}</td>
          <td>
            <button type="button" *ngIf="this.SelectedMenu.CanEdit"  (click)="GetBankMasterByid( bank.BankId )"class="table-edit-btn"><i class="fas fa-edit"></i>  </button> 
            <button type="button" *ngIf="this.SelectedMenu.CanDelete" (click)="DeleteBankMasterByid( bank.BankId )" class="table-remove-btn"><i class="fas fa-trash-alt"></i></button>
            <button *ngIf="this.SelectedMenu.CanView"  type="button" (click)="viewBankMasterByid( bank.BankId )" class="table-view-button" type="button" ><i class="fas fa-eye"></i></button>
          </td>
        </tr>
      </tbody>
    </table>

    <fieldset class="body-form-control">
      <app-dialog [(visible)]="this.SuccessStatus">
        <span class="successmsg">{{this.SuccessMessage}}</span>
      </app-dialog>
      <legend class="legend--">Bank Master Creation</legend>
        <form #BankForm="ngForm" (ngSubmit)="saveBankMaster()">
          <div class="row">
            <div class="col-lg-4">
              <div class="col-body-border-right">
                <div class="form-group row">
                  <label  class="col-sm-4 col-form-label clear-padding-right">Bank Code</label>
                  <div class="col-sm-7 clear-left-padding">
                    <input type="text" [(ngModel)]="BankModel.BankCode" tabindex="1" maxlength="40" id ="BankCode"  name="BankCode" #BankCode="ngModel" required class="form-control form-control-custom form-control-custom-new"  >
                  </div>
                  <div *ngIf="BankCode.invalid && (BankCode.dirty || BankCode.touched)" class="required-validation">
                    <div *ngIf="BankCode.errors.required">Bank Code is required.</div>
                    <div *ngIf="BankCode.errors.maxlength">Bank Code is not more than 40 characters</div>
                  </div>
                </div>
                <div class="form-group row">
                  <label  class="col-sm-4 col-form-label clear-padding-right">Addres</label>
                  <div class="col-sm-7 clear-left-padding">
                    <input type="text" [(ngModel)]="BankModel.BankAddress" tabindex="4" id ="BankAddress"  name="BankAddress" #BankAddress="ngModel" required maxlength="350" class="form-control form-control-custom form-control-custom-new" >
                  </div>
                  <div *ngIf="BankAddress.invalid && (BankAddress.dirty || BankAddress.touched)" class="required-validation">
                    <div *ngIf="BankAddress.errors.required">Address is required.</div>
                    <div *ngIf="BankAddress.errors.maxlength">Address is not more than 350 characters</div>
                  </div>
                </div>
                <div class="form-group row">
                  <label  class="col-sm-4 col-form-label clear-padding-right">State</label>
                  <div class="col-sm-7 clear-left-padding">
                    <select [(ngModel)]="BankModel.StateId" tabindex="7" (ngModelChange)="onstatechange($event)" id ="StateId" class="form-control" name="StateId" #StateId="ngModel" >
                      <option [value]="0" [disabled]=true >Select State</option>
                      <option *ngFor="let s of States" [value]="s.StateID">{{s.StateName}}</option>
                    </select>
                  </div>
                  <div class="required-validation" *ngIf="BankModel.StateId==0">State is required</div>
                </div>
              </div>
            </div>

            <div class="col-lg-4">
              <div class="col-body-border-right">
                <div class="form-group row">
                  <label  class="col-sm-4 col-form-label clear-padding-right">Bank Name</label>
                  <div class="col-sm-7 clear-left-padding">
                    <input type="text" [(ngModel)]="BankModel.BankName" tabindex="2" id ="BankName" name="BankName" #BankName="ngModel" required maxlength="150" class="form-control form-control-custom form-control-custom-new" >
                  </div>
                    <div *ngIf="BankName.invalid && (BankName.dirty || BankName.touched)" class="required-validation">
                    <div *ngIf="BankName.errors.required">Bank Name  is required.</div>
                    <div *ngIf="BankName.errors.maxlength">Bank Name is not more than 150 characters</div>
                  </div>
                </div>
                <div class="form-group row">
                  <label  class="col-sm-4 col-form-label clear-padding-right">IFSC Code</label>
                  <div class="col-sm-7 clear-left-padding">
                    <input type="text" [(ngModel)]="BankModel.IFSCCode" tabindex="5" id ="IFSCCode"  name="IFSCCode" #IFSCCode="ngModel" required maxlength="20" class="form-control form-control-custom form-control-custom-new" >
                  </div>
                  <div *ngIf="IFSCCode.invalid && (IFSCCode.dirty || IFSCCode.touched)" class="required-validation">
                    <div *ngIf="IFSCCode.errors.required">IFSC Code  is required.</div>
                    <div *ngIf="IFSCCode.errors.maxlength">IFSC Code is not more than 20 characters</div>
                  </div>
                </div>
                <div class="form-group row">
                  <label  class="col-sm-4 col-form-label clear-padding-right">City</label>
                  <div class="col-sm-7 clear-left-padding">
                    <select  [(ngModel)]="BankModel.CityId" tabindex="8" id ="CityId" class="form-control" name="CityId" #CityId="ngModel">
                      <option [value]="0" [disabled]=true >Select City</option>
                      <option *ngFor="let c of Cities" [value]="c.CityId">{{c.CityName}}</option>
                    </select>
                  </div>
                  <div class="required-validation" *ngIf="BankModel.CityId==0">City is required</div>
                </div>
              </div>
            </div>

            <div class="col-lg-4">
              <div class="form-group row">
                <label  class="col-sm-4 col-form-label clear-padding-right">Branch</label>
                <div class="col-sm-7 clear-left-padding">
                  <input type="text" [(ngModel)]="BankModel.BranchName" tabindex="3" id ="BranchName" name="BranchName" #BranchName="ngModel" required maxlength="150" class="form-control form-control-custom form-control-custom-new" >
                </div>
                <div *ngIf="BranchName.invalid && (BranchName.dirty || BranchName.touched)" class="required-validation">
                  <div *ngIf="BranchName.errors.required">Branch Name  is required.</div>
                  <div *ngIf="BranchName.errors.maxlength">Branch Name is not more than 150 characters</div>
                </div>
              </div>
              <div class="form-group row">
                <label  class="col-sm-4 col-form-label clear-padding-right">Country</label>
                <div class="col-sm-7 clear-left-padding">
                  <select [(ngModel)]="BankModel.CountryId" tabindex="6" (ngModelChange)="onChange($event)" id ="CountryId" class="form-control"  name="CountryId" #CountryId="ngModel" >
                    <option [value]="0" [disabled]=true >Select Country</option>
                    <option *ngFor="let c of Countries" [value]="c.CountryId">{{c.CountryName}}</option>
                  </select>
                </div>
                <div class="required-validation" *ngIf="BankModel.CountryId==0">Country is required</div>
              </div>
              <div class="form-group row">
                <label  class="col-sm-4 col-form-label clear-padding-right">Pin Code</label>
                <div class="col-sm-7 clear-left-padding">
                  <input type="text" [(ngModel)]="BankModel.PostCode" tabindex="9" id ="PostCode" maxlength="6" minlength="6"  name="PostCode" #PostCode="ngModel" pattern="[0-9]+" required class="form-control form-control-custom form-control-custom-new" >
                </div>
                <div *ngIf="PostCode.invalid && (PostCode.dirty || PostCode.touched)" class="required-validation">
                  <div *ngIf="PostCode.errors.required">PostCode is required.</div>
                  <div *ngIf="PostCode.errors.maxlength">PostCode not more than 6 digits.</div>
                  <div *ngIf="PostCode.errors.minlength">PostCode not more than 6 digits.</div>
                  <div *ngIf="PostCode.errors?.pattern">PostCode should be valid</div>
                </div>
              </div>
              <div class="form-group row">
                <label class="col-sm-6 col-form-label text-center clear-padding-right" *ngIf="!(this.IsDataIsInViewState)">
                  <button type="submit" tabindex="10" class="btn btn-primary save-button" [disabled]="!(BankForm.valid && BankModel.StateId !=0 && BankModel.CountryId!=0 && BankModel.CityId!=0 && this.DisableSave==true)" >Save</button>  
                </label>
                <div class="col-sm-6 col-form-label text-center">
                  <button type="button" tabindex="11" class="btn btn-danger cancle-button" (click)="RefreshPage()">Cancel</button>
                </div>
              </div>
            </div>
          </div>
        </form>
    </fieldset>
  </div>
</div>