import { Injectable,Inject } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';  
import { Http, Headers, RequestOptions, Response } from '@angular/http';  
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import 'rxjs/add/operator/toPromise';
import { Subscriber } from 'rxjs';
import { GlobalVariable  } from '../../../app.global';
import { EPFESIModel } from   '../Admin/EPFESIModel.Model';
import { PTAXModel } from   '../Admin/PTAXModel.Model';
import { TDSSlabModel } from   '../Admin/TDSSlabModel.Model';
import { TDSSurchargeModel } from   '../Admin/TDSSurchargeModel.Model';
import { TourExpenseHeadModel } from   '../Admin/TourExpenseHeadModel.Model';
import { ExpenseHeadMappingModel } from   '../Admin/ExpenseHeadMappingModel.model';
import { EmployeeBudgetModel }  from '../Admin/EmployeeBudgetModel';
import { EventMessageModel }  from '../Admin/EventMessageModel.Model';
import { HRPoliciesFormModel }  from '../Admin/HRPoliciesFormModel.Model';

@Injectable()
export class AdminMasterService 
{
    constructor(private http: HttpClient,private spinnerService: Ng4LoadingSpinnerService) 
    {
    }
    SharedPtaxes: PTAXModel ;
    // Begining TDS Slab
    GetTDSSlabMaster(token)
    {
        this.spinnerService.show();
        const authHeader = new HttpHeaders();     
        authHeader.append('Content-Type', 'application/json');
        authHeader.append('Authorization', token);
        return new Promise((resolve, reject) =>
        {
            setTimeout(()=>{
            this.http.get(GlobalVariable.BASE_API_URL +'/api/TDSSlab/GetTDSSlab',{headers: authHeader})
            .subscribe(data => 
            {
                this.spinnerService.hide();
                resolve(data)
            },
            error => 
            {
                this.spinnerService.hide();
                reject(error);
            },);

        },3000);
        });
    }
    GetTDSSlabDetailsByRefId(token, RefId:number ) 
    {
        this.spinnerService.show();
        const authHeader = new HttpHeaders();     
        authHeader.append('Content-Type', 'application/json');
        authHeader.append('Authorization', token);
        return new Promise((resolve, reject) => 
        {
            setTimeout(()=>{
            this.http.get(GlobalVariable.BASE_API_URL +'/api/TDSSlab/GetTDSSlabDetailsByRefId?RefId=' + RefId,{headers: authHeader})
            .subscribe(data => 
            {
                this.spinnerService.hide();
                resolve(data)
            },
            error => 
            {
                this.spinnerService.hide();
                reject(error);
            },);
        },3000);
        });
    }
    UpdateTDSSlabMaster( token ,SlabMaster :TDSSlabModel) 
    {
        const authHeader = new HttpHeaders();         
        authHeader.append('Content-Type', 'application/json');
        authHeader.append('Authorization', token);
        authHeader.append('Accept', 'application/json');
        return this.http.put(GlobalVariable.BASE_API_URL +'/api/TDSSlab/UpdateTDSSlabMaster', SlabMaster ,{headers: authHeader} )
        .map(this.extractData)
        .catch(this.handleErrorObservable);
    }
    UpdateTDSSlabDetails( token ,SlabDetails :TDSSlabModel) 
    {
        const authHeader = new HttpHeaders();         
        authHeader.append('Content-Type', 'application/json');
        authHeader.append('Authorization', token);
        authHeader.append('Accept', 'application/json');
        return this.http.put(GlobalVariable.BASE_API_URL +'/api/TDSSlab/UpdateTDSSlabDetails', SlabDetails ,{headers: authHeader} )
        .map(this.extractData)
        .catch(this.handleErrorObservable);
    }
    // End TDS Slab
    // Begning of the TDSSurcharge Master
    GetTDSSurchargeDetails(token) 
    {
        this.spinnerService.show();
        const authHeader = new HttpHeaders();     
        authHeader.append('Content-Type', 'application/json');
        authHeader.append('Authorization', token);
        return new Promise((resolve, reject) => 
        {
            setTimeout(()=>{
            this.http.get(GlobalVariable.BASE_API_URL +'/api/TDSSurcharge/GetTDSSurcharge',{headers: authHeader})
            .subscribe(data => 
            {
                this.spinnerService.hide();
                resolve(data)
            },
            error => 
            {
                this.spinnerService.hide();
                reject(error);
            },);
        },3000);
        });
    }
    AddTDSSurcharge( token ,TDSSurcharge :TDSSurchargeModel) 
    {
        const authHeader = new HttpHeaders();         
        authHeader.append('Content-Type', 'application/json');
        authHeader.append('Authorization', token);
        authHeader.append('Accept', 'application/json');
        return this.http.post(GlobalVariable.BASE_API_URL +'/api/TDSSurcharge/Add', TDSSurcharge ,{headers: authHeader} )
        .map(this.extractData)
        .catch(this.handleErrorObservable);
    }
    // End of the TDSSurcharge Master
    // Begining of the EPFESI Master
    GetEPFESISlab(token)
    {
        this.spinnerService.show();
        const authHeader = new HttpHeaders();     
        authHeader.append('Content-Type', 'application/json');
        authHeader.append('Authorization', token);
        return new Promise((resolve, reject) =>
        {
            setTimeout(()=>{
            this.http.get(GlobalVariable.BASE_API_URL +'/api/EPFESISlab/GetEPFESISlab',{headers: authHeader})
            .subscribe(data => 
            {
                this.spinnerService.hide();
                resolve(data)
            },
            error => 
            {
                this.spinnerService.hide();
                reject(error);
            },);

        },3000);


        });
    }
    GetEPFESISlabById(token, id:number ) 
    {
        this.spinnerService.show();
        const authHeader = new HttpHeaders();     
        authHeader.append('Content-Type', 'application/json');
        authHeader.append('Authorization', token);
        return new Promise((resolve, reject) => 
        {
            setTimeout(()=>{
            this.http.get(GlobalVariable.BASE_API_URL +'/api/EPFESISlab/GetEPFESISlabByRefId?RefId='+id,{headers: authHeader})
            .subscribe(data => 
            {
                this.spinnerService.hide();
                resolve(data)
            },
            error => 
            {
                this.spinnerService.hide();
                reject(error);
            },);
        },3000);
        });
    }
    AddEPFESISlab( token ,EPFESI :EPFESIModel) 
    {
        const authHeader = new HttpHeaders();         
        authHeader.append('Content-Type', 'application/json');
        authHeader.append('Authorization', token);
        authHeader.append('Accept', 'application/json');
        return this.http.post(GlobalVariable.BASE_API_URL +'/api/EPFESISlab/Add', EPFESI ,{headers: authHeader} )
        .map(this.extractData)
        .catch(this.handleErrorObservable);
    }
    UpdateEPFESISlab( token ,EPFESI :EPFESIModel) 
    {
        const authHeader = new HttpHeaders();         
        authHeader.append('Content-Type', 'application/json');
        authHeader.append('Authorization', token);
        authHeader.append('Accept', 'application/json');
        return this.http.put(GlobalVariable.BASE_API_URL +'/api/EPFESISlab/Update', EPFESI ,{headers: authHeader} )
        .map(this.extractData)
        .catch(this.handleErrorObservable);
    }
    // End of the EPFESI Master
    // Begning of the PTAX Master
    GetPtaxSlabByBasicSalary(token, StateId:number, BasicSalary:number)
    {
        this.spinnerService.show();
        const authHeader = new HttpHeaders();     
        authHeader.append('Content-Type', 'application/json');
        authHeader.append('Authorization', token);
        return new Promise((resolve, reject) =>
        {
            setTimeout(()=>{
            this.http.get(GlobalVariable.BASE_API_URL +'/api/PtaxSlab/GetPtaxSlabByBasicSalary?StateId='+StateId+'&BasicSalary='+BasicSalary,{headers: authHeader})
            .subscribe(data => 
            {
                this.spinnerService.hide();
                resolve(data)
            },
            error => 
            {
                this.spinnerService.hide();
                reject(error);
            },);

        },3000);
       });
    }
    GetPtaxSlabByStateId(token, StateId:number ) 
    {
        this.spinnerService.show();
        const authHeader = new HttpHeaders();     
        authHeader.append('Content-Type', 'application/json');
        authHeader.append('Authorization', token);
        return new Promise((resolve, reject) => 
        {
            setTimeout(()=>{
            this.http.get(GlobalVariable.BASE_API_URL +'/api/PtaxSlab/GetPtaxSlabByStateId?StateId='+StateId,{headers: authHeader})
            .subscribe(data => 
            {
                this.spinnerService.hide();
                resolve(data)
            },
            error => 
            {
                this.spinnerService.hide();
                reject(error);
            },);
        },3000);
        });
    }
    AddPTAXSlab( token ,Ptax :PTAXModel) 
    {
        const authHeader = new HttpHeaders();         
        authHeader.append('Content-Type', 'application/json');
        authHeader.append('Authorization', token);
        authHeader.append('Accept', 'application/json');
        return this.http.post(GlobalVariable.BASE_API_URL +'/api/PtaxSlab/Add', Ptax ,{headers: authHeader} )
        .map(this.extractData)
        .catch(this.handleErrorObservable);
    }
    UpdatePTAXSlab( token ,Ptax :PTAXModel) 
    {
        const authHeader = new HttpHeaders();         
        authHeader.append('Content-Type', 'application/json');
        authHeader.append('Authorization', token);
        authHeader.append('Accept', 'application/json');
        return this.http.put(GlobalVariable.BASE_API_URL +'/api/PtaxSlab/Update', Ptax ,{headers: authHeader} )
        .map(this.extractData)
        .catch(this.handleErrorObservable);
    }
    // End of the PTAX Master
    // Begning Tour Expense Head
    GetTourExpenseHead(token)
    {
        this.spinnerService.show();
        const authHeader = new HttpHeaders();     
        authHeader.append('Content-Type', 'application/json');
        authHeader.append('Authorization', token);
        return new Promise((resolve, reject) =>
        {
            setTimeout(()=>{
            this.http.get(GlobalVariable.BASE_API_URL +'/api/TourExpenseHead/GetTourExpenseHead',{headers: authHeader})
            .subscribe(data => 
            {
                this.spinnerService.hide();
                resolve(data)
            },
            error => 
            {
                this.spinnerService.hide();
                reject(error);
            },);

        },3000);
       });
    }
    GetTourExpenseHeadByExpenseHeadId(token, ExpenseHeadId:number ) 
    {
        this.spinnerService.show();
        const authHeader = new HttpHeaders();     
        authHeader.append('Content-Type', 'application/json');
        authHeader.append('Authorization', token);
        return new Promise((resolve, reject) => 
        {
            setTimeout(()=>{
            this.http.get(GlobalVariable.BASE_API_URL +'/api/TourExpenseHead/GetTourExpenseHeadByExpenseHeadId?ExpenseHeadId='+ExpenseHeadId,{headers: authHeader})
            .subscribe(data => 
            {
                this.spinnerService.hide();
                resolve(data)
            },
            error => 
            {
                this.spinnerService.hide();
                reject(error);
            },);
        },3000);
        });
    }
    AddTourExpenseHead( token ,TourExpenseHead :TourExpenseHeadModel) 
    {
        const authHeader = new HttpHeaders();         
        authHeader.append('Content-Type', 'application/json');
        authHeader.append('Authorization', token);
        authHeader.append('Accept', 'application/json');
        return this.http.post(GlobalVariable.BASE_API_URL +'/api/TourExpenseHead/Add', TourExpenseHead ,{headers: authHeader} )
        .map(this.extractData)
        .catch(this.handleErrorObservable);
    }
    UpdateTourExpenseHead( token ,TourExpenseHead :TourExpenseHeadModel) 
    {
        const authHeader = new HttpHeaders();         
        authHeader.append('Content-Type', 'application/json');
        authHeader.append('Authorization', token);
        authHeader.append('Accept', 'application/json');
        return this.http.put(GlobalVariable.BASE_API_URL +'/api/TourExpenseHead/Update', TourExpenseHead ,{headers: authHeader} )
        .map(this.extractData)
        .catch(this.handleErrorObservable);
    }
    CheckTourExpenseHeadName(token, ExpenseHeadName:string)
    {
        this.spinnerService.show();
        const authHeader = new HttpHeaders();     
        authHeader.append('Content-Type', 'application/json');
        authHeader.append('Authorization', token);
        return new Promise((resolve, reject) => 
        {
        setTimeout(()=>{
        this.http.get(GlobalVariable.BASE_API_URL +'/api/TourExpenseHead/CheckExpenseHeadName?ExpenseHeadName='+ExpenseHeadName,{headers: authHeader})
        .subscribe(data => 
        {
            this.spinnerService.hide();
            resolve(data)
        },
        error => 
        {
            this.spinnerService.hide();
            reject(error);
        },);
        },3000);
        });
    }
    DeleteTourExpenseHeadName( token ,ExpenseHeadId : number)
    {
      const authHeader = new HttpHeaders();         
      authHeader.append('Content-Type', 'application/json');
      authHeader.append('Authorization', token);
      authHeader.append('Accept', 'application/json');
      return this.http.put(GlobalVariable.BASE_API_URL +'/api/TourExpenseHead/Delete', ExpenseHeadId ,{headers: authHeader} )
      .map(this.extractData)
      .catch(this.handleErrorObservable);
    }
    // End Tour Expense Head
    // Begning Tour Expense Head Mapping
    GetTourExpenseHeadMappingGV(token)
    {
        this.spinnerService.show();
        const authHeader = new HttpHeaders();     
        authHeader.append('Content-Type', 'application/json');
        authHeader.append('Authorization', token);
        return new Promise((resolve, reject) =>
        {
            setTimeout(()=>{
            this.http.get(GlobalVariable.BASE_API_URL +'/api/TourExpenseHeadMapping/GetTourExpenseHeadMappingGV',{headers: authHeader})
            .subscribe(data => 
            {
                this.spinnerService.hide();
                resolve(data)
            },
            error => 
            {
                this.spinnerService.hide();
                reject(error);
            },);

        },3000);
       });
    }
    GetTourExpenseHeadMappingByGradeId(token, DecisionId:number, LevelId:number, GradeId:number ) 
    {
        this.spinnerService.show();
        const authHeader = new HttpHeaders();     
        authHeader.append('Content-Type', 'application/json');
        authHeader.append('Authorization', token);
        return new Promise((resolve, reject) => 
        {
            setTimeout(()=>{
            this.http.get(GlobalVariable.BASE_API_URL +'/api/TourExpenseHeadMapping/GetTourExpenseHeadMappingByGradeId?DecisionId='+ DecisionId +'&LevelId='+LevelId+'&GradeId='+GradeId,{headers: authHeader})
            .subscribe(data => 
            {
                this.spinnerService.hide();
                resolve(data)
            },
            error => 
            {
                this.spinnerService.hide();
                reject(error);
            },);
        },3000);
        });
    }
    AddTourExpenseHeadMapping( token ,TourExpenseHead :ExpenseHeadMappingModel[]) 
    {
        const authHeader = new HttpHeaders();         
        authHeader.append('Content-Type', 'application/json');
        authHeader.append('Authorization', token);
        authHeader.append('Accept', 'application/json');
        return this.http.post(GlobalVariable.BASE_API_URL +'/api/TourExpenseHeadMapping/Add', TourExpenseHead ,{headers: authHeader} )
        .map(this.extractData)
        .catch(this.handleErrorObservable);
    }
    // End Tour Expense Head
    // Begin Employee Budget
    AddEmployeeTourBudget( token ,EmployeeBudget :EmployeeBudgetModel) 
    {
        const authHeader = new HttpHeaders();         
        authHeader.append('Content-Type', 'application/json');
        authHeader.append('Authorization', token);
        authHeader.append('Accept', 'application/json');
        return this.http.post(GlobalVariable.BASE_API_URL +'/api/EmployeeTourBudget/AddEmployeeTourBudget', EmployeeBudget ,{headers: authHeader} )
        .map(this.extractData)
        .catch(this.handleErrorObservable);
    }
    UpdateEmployeeTourBudget( token ,EmployeeBudget :EmployeeBudgetModel) 
    {
        const authHeader = new HttpHeaders();         
        authHeader.append('Content-Type', 'application/json');
        authHeader.append('Authorization', token);
        authHeader.append('Accept', 'application/json');
        return this.http.put(GlobalVariable.BASE_API_URL +'/api/EmployeeTourBudget/UpdateEmployeeTourBudget', EmployeeBudget ,{headers: authHeader} )
        .map(this.extractData)
        .catch(this.handleErrorObservable);
    }
    GetEmployeeTourBudgetById(token, RefId:number ) 
    {
        this.spinnerService.show();
        const authHeader = new HttpHeaders();     
        authHeader.append('Content-Type', 'application/json');
        authHeader.append('Authorization', token);
        return new Promise((resolve, reject) => 
        {
            setTimeout(()=>{
            this.http.get(GlobalVariable.BASE_API_URL +'/api/EmployeeTourBudget/GetEmployeeTourBudgetById?RefId='+RefId,{headers: authHeader})
            .subscribe(data => 
            {
                this.spinnerService.hide();
                resolve(data)
            },
            error => 
            {
                this.spinnerService.hide();
                reject(error);
            },);
        },3000);
        });
    }
    GetEmployeesNotExistsinBudgetForDDL(token)
    {
        this.spinnerService.show();
        const authHeader = new HttpHeaders();     
        authHeader.append('Content-Type', 'application/json');
        authHeader.append('Authorization', token);
        return new Promise((resolve, reject) =>
        {
            setTimeout(()=>{
            this.http.get(GlobalVariable.BASE_API_URL +'/api/EmployeeTourBudget/GetEmployeesNotExistsinBudgetForDDL',{headers: authHeader})
            .subscribe(data => 
            {
                this.spinnerService.hide();
                resolve(data)
            },
            error => 
            {
                this.spinnerService.hide();
                reject(error);
            },);

        },3000);
       });
    }
    GetEmployeeBudgetFORGV(token)
    {
        this.spinnerService.show();
        const authHeader = new HttpHeaders();     
        authHeader.append('Content-Type', 'application/json');
        authHeader.append('Authorization', token);
        return new Promise((resolve, reject) =>
        {
            setTimeout(()=>{
            this.http.get(GlobalVariable.BASE_API_URL +'/api/EmployeeTourBudget/GetEmployeeBudgetFORGV',{headers: authHeader})
            .subscribe(data => 
            {
                this.spinnerService.hide();
                resolve(data)
            },
            error => 
            {
                this.spinnerService.hide();
                reject(error);
            },);

        },3000);
       });
    }
    // End Employee Budget
    // Begin Event Message
    AddEventMessage( token ,EventMessage :EventMessageModel) 
    {
        const authHeader = new HttpHeaders();         
        authHeader.append('Content-Type', 'application/json');
        authHeader.append('Authorization', token);
        authHeader.append('Accept', 'application/json');
        return this.http.post(GlobalVariable.BASE_API_URL +'/api/EventMessage/Add', EventMessage ,{headers: authHeader} )
        .map(this.extractData)
        .catch(this.handleErrorObservable);
    }
    UpdateEventMessage( token ,EventMessage :EventMessageModel) 
    {
        const authHeader = new HttpHeaders();         
        authHeader.append('Content-Type', 'application/json');
        authHeader.append('Authorization', token);
        authHeader.append('Accept', 'application/json');
        return this.http.put(GlobalVariable.BASE_API_URL +'/api/EventMessage/Update', EventMessage ,{headers: authHeader} )
        .map(this.extractData)
        .catch(this.handleErrorObservable);
    }
    GetEventMessageByRefId(token, RefId:number ) 
    {
        this.spinnerService.show();
        const authHeader = new HttpHeaders();     
        authHeader.append('Content-Type', 'application/json');
        authHeader.append('Authorization', token);
        return new Promise((resolve, reject) => 
        {
            setTimeout(()=>{
            this.http.get(GlobalVariable.BASE_API_URL +'/api/EventMessage/GetEventMessageByRefId?RefId='+RefId,{headers: authHeader})
            .subscribe(data => 
            {
                this.spinnerService.hide();
                resolve(data)
            },
            error => 
            {
                this.spinnerService.hide();
                reject(error);
            },);
        },3000);
        });
    }
    GetEventMessageGV(token)
    {
        this.spinnerService.show();
        const authHeader = new HttpHeaders();     
        authHeader.append('Content-Type', 'application/json');
        authHeader.append('Authorization', token);
        return new Promise((resolve, reject) =>
        {
            setTimeout(()=>{
            this.http.get(GlobalVariable.BASE_API_URL +'/api/EventMessage/GetEventMessageGV',{headers: authHeader})
            .subscribe(data => 
            {
                this.spinnerService.hide();
                resolve(data)
            },
            error => 
            {
                this.spinnerService.hide();
                reject(error);
            },);

        },3000);
       });
    }
    GetEventByDate(token)
    {
        this.spinnerService.show();
        const authHeader = new HttpHeaders();     
        authHeader.append('Content-Type', 'application/json');
        authHeader.append('Authorization', token);
        return new Promise((resolve, reject) =>
        {
            setTimeout(()=>{
            this.http.get(GlobalVariable.BASE_API_URL +'/api/EventMessage/GetEventByDate',{headers: authHeader})
            .subscribe(data => 
            {
                this.spinnerService.hide();
                resolve(data)
            },
            error => 
            {
                this.spinnerService.hide();
                reject(error);
            },);

        },3000);
       });
    }
    GetMessageByDate(token)
    {
        this.spinnerService.show();
        const authHeader = new HttpHeaders();     
        authHeader.append('Content-Type', 'application/json');
        authHeader.append('Authorization', token);
        return new Promise((resolve, reject) =>
        {
            setTimeout(()=>{
            this.http.get(GlobalVariable.BASE_API_URL +'/api/EventMessage/GetMessageByDate',{headers: authHeader})
            .subscribe(data => 
            {
                this.spinnerService.hide();
                resolve(data)
            },
            error => 
            {
                this.spinnerService.hide();
                reject(error);
            },);

        },3000);
       });
    }
    DeleteEventMessage( token ,RefId : number)
    {
      const authHeader = new HttpHeaders();         
      authHeader.append('Content-Type', 'application/json');
      authHeader.append('Authorization', token);
      authHeader.append('Accept', 'application/json');
      return this.http.put(GlobalVariable.BASE_API_URL +'/api/EventMessage/Delete', RefId ,{headers: authHeader} )
      .map(this.extractData)
      .catch(this.handleErrorObservable);
    }
    // End Event & MD Message

    //Begin HR Policies & Forms
    AddHRPoliciesForm( token ,HRPolicies :HRPoliciesFormModel) 
    {
        const authHeader = new HttpHeaders();         
        authHeader.append('Content-Type', 'application/json');
        authHeader.append('Authorization', token);
        authHeader.append('Accept', 'application/json');
        return this.http.post(GlobalVariable.BASE_API_URL +'/api/HRPoliciesForm/Add', HRPolicies ,{headers: authHeader} )
        .map(this.extractData)
        .catch(this.handleErrorObservable);
    }
    UpdateHRPoliciesForm( token ,HRPolicies :HRPoliciesFormModel) 
    {
        const authHeader = new HttpHeaders();         
        authHeader.append('Content-Type', 'application/json');
        authHeader.append('Authorization', token);
        authHeader.append('Accept', 'application/json');
        return this.http.put(GlobalVariable.BASE_API_URL +'/api/HRPoliciesForm/Update', HRPolicies ,{headers: authHeader} )
        .map(this.extractData)
        .catch(this.handleErrorObservable);
    }
    GetHRPoliciesFormByRefId(token, RefId:number ) 
    {
        this.spinnerService.show();
        const authHeader = new HttpHeaders();     
        authHeader.append('Content-Type', 'application/json');
        authHeader.append('Authorization', token);
        return new Promise((resolve, reject) => 
        {
            setTimeout(()=>{
            this.http.get(GlobalVariable.BASE_API_URL +'/api/HRPoliciesForm/GetHRPolicysFormsByRefId?RefId='+RefId,{headers: authHeader})
            .subscribe(data => 
            {
                this.spinnerService.hide();
                resolve(data)
            },
            error => 
            {
                this.spinnerService.hide();
                reject(error);
            },);
        },3000);
        });
    }
    GetHRPoliciesFormGV(token)
    {
        this.spinnerService.show();
        const authHeader = new HttpHeaders();     
        authHeader.append('Content-Type', 'application/json');
        authHeader.append('Authorization', token);
        return new Promise((resolve, reject) =>
        {
            //setTimeout(()=>{
            this.http.get(GlobalVariable.BASE_API_URL +'/api/HRPoliciesForm/GetHRPolicysFormsGV',{headers: authHeader})
            .subscribe(data => 
            {
                this.spinnerService.hide();
                resolve(data)
            },
            error => 
            {
                this.spinnerService.hide();
                reject(error);
            },);

       // },3000);
       });
    }
    DeleteHRPoliciesForm( token ,RefId : number)
    {
      const authHeader = new HttpHeaders();         
      authHeader.append('Content-Type', 'application/json');
      authHeader.append('Authorization', token);
      authHeader.append('Accept', 'application/json');
      return this.http.put(GlobalVariable.BASE_API_URL +'/api/HRPoliciesForm/Delete', RefId ,{headers: authHeader} )
      .map(this.extractData)
      .catch(this.handleErrorObservable);
    }
    getFile(FileName: string)
    {
        let url = GlobalVariable.BASE_API_URL + '/api/HRPoliciesForm/GetDocument?Filename=' + FileName;
        let headers = new HttpHeaders();
        window.open(url);
    }
    //End HR Policies & Forms
    // Attendance Details for Dashboard
    GetAttendanceDetailsForDashboard(token, AttendanceDate:Date ) 
    {
        this.spinnerService.show();
        const authHeader = new HttpHeaders();     
        authHeader.append('Content-Type', 'application/json');
        authHeader.append('Authorization', token);
        return new Promise((resolve, reject) => 
        {
            //setTimeout(()=>{
            this.http.get(GlobalVariable.BASE_API_URL +'/api/BirthdayAnniversary/GetAttendanceDetailsForDashboard?AttendanceDate='+AttendanceDate,{headers: authHeader})
            .subscribe(data => 
            {
                this.spinnerService.hide();
                resolve(data)
            },
            error => 
            {
                this.spinnerService.hide();
                reject(error);
            },);
        //},3000);
        });
    }

    private extractData(res: Response) 
    {
        let body = res;
        return body || {};
    }

    private handleErrorObservable (error: Response | any) 
    {
        console.error(error.message || error);
        return Observable.throw(error.message || error);
    }
}
