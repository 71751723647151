import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs/Subject';
import { DataTableDirective } from 'angular-datatables';
import { AttendanceService }  from '../../shared/HR_ADMIN/Attendance/AttendanceService.service';
import { OrganizationService }  from '../../shared/Organization/organization.service';
import { MapFineRule }  from '../../shared/HR_ADMIN/Attendance/MapFineRule.model';
import { WorkingGroupHours }  from '../../shared/HR_ADMIN/Attendance/WorkingGroupHours.model';
import { AttendanceFineRule }  from '../../shared/HR_ADMIN/Attendance/AttendanceFineRule.model';
import { UserService } from '../../shared/user.service';
// Begning Confirmation
import { ConfirmationService } from '@jaspero/ng2-confirmations';
import { ResolveEmit } from '../../../app/resolve-emit';
import { ConfirmSettings } from '../../../app/confirm-settings';
// end of Confirmation
import { Menu } from '../../shared/menu.model';

@Component({
  selector: 'app-Manage-Map-Fine-Rule',
  templateUrl: './Manage-Map-Fine-Rule.component.html',
  styleUrls: ['./Manage-Map-Fine-Rule.component.css']
})
export class ManageMapFineRuleComponent implements OnInit 
{
  MapFineRuleModel : MapFineRule= new MapFineRule();
  userClaims: any;
  Result : string;
  SuccessStatus:boolean=false;
  SuccessMessage: string ="";
  public tableWidget: any;
  MapFineRules: MapFineRule[] = [];
  MapFineRule:any;
  MapFineRuleId : any;
  
  WorkingGroupHour: WorkingGroupHours[] = [];
  workinggrouphour:any;
  
  FineRules: AttendanceFineRule[] = [];
  FineRule:any;
  // Begning Permission Portion    
  SelectedMenu:Menu;
  IsDataIsInViewState : boolean=false;
  CanViewState : boolean=false;
  DisableSave : boolean=false;
  // End of the permission portion
  
  tempMapFineRuleadd : MapFineRule =new MapFineRule();
  
  constructor(private AttendanceService: AttendanceService,private OrganizationService: OrganizationService, private rout: Router ,
      private userService: UserService, private _confirmation: ConfirmationService) { }

  @ViewChild(DataTableDirective, {static: false})
  setDtOptions: DataTables.Settings = {};
  cardDtOptions: DataTables.Settings = {};
  dtElement: DataTableDirective;
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();

  ngOnInit()
  {
    // Begning Permission Portion 
    this.SelectedMenu=this.userService.SelectedMenu;
    if(this.SelectedMenu !=undefined)
    {
      localStorage.setItem('selectedmenu',JSON.stringify(this.SelectedMenu));
      this.rout.navigate([this.SelectedMenu.RouteName]);
    }
    else
    {
      if(JSON.parse(localStorage.getItem('selectedmenu'))!=undefined)
      {
        this.SelectedMenu = JSON.parse(localStorage.getItem('selectedmenu'));
        this.rout.navigate([this.SelectedMenu.RouteName]);
      }
    }
    if(this.SelectedMenu.CanAdd)
    {
      this.IsDataIsInViewState=false;
    }
    else
    {
      this.IsDataIsInViewState=true;
    }
    if(this.SelectedMenu.CanView)
    {
      this.CanViewState=false;
    }
    else
    {
      this.CanViewState=true;
    }
    // End of the permission portion
    this.DisableSave=true;
    this.getWorkingHour();
    this.getFineRule();
    this.MapFineRuleModel.WorkingGroupId=0;
    this.MapFineRuleModel.AttFineRuleId=0;
    this.dtOptions = 
    {
      pagingType: 'full_numbers',
      pageLength: 4
    };
    if(localStorage.getItem('SuccessfullMessage'))
    {
      this.SuccessStatus=true;
      this.SuccessMessage =localStorage.getItem('SuccessfullMessage')
      localStorage.removeItem('SuccessfullMessage');
    }
  }
  
  settings: ConfirmSettings | any = 
  {
    overlay: true,
    overlayClickToClose: true,
    showCloseButton: true,
    confirmText: 'Yes',
    declineText: 'No'
  };
  
  ngAfterViewInit(): void 
  {
    this.getMapFineRule();
    this.dtOptions = 
    {
      pagingType: 'full_numbers',
      pageLength: 4
    };
  }

  getMapFineRule()
  {
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.AttendanceService.GetMapFineRule( t).then(x => 
    {
      this.MapFineRule = x;
      Object.keys(this.MapFineRule).forEach( key => 
      {
        this.MapFineRule[key];
        let MapFineRuletemp = new MapFineRule();
        MapFineRuletemp = this.MapFineRule[key];
        this.MapFineRules.push(MapFineRuletemp);
      });
      this.dtTrigger.next();
    });
  }

  getWorkingHour() 
  {
    this.WorkingGroupHour=[];
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.AttendanceService.GetWorkingGroupHours( t).then(x => 
    {
      this.workinggrouphour = x;
      Object.keys(this.workinggrouphour).forEach( key => 
      {
        this.workinggrouphour[key];
        let workinggrouphourtemp = new WorkingGroupHours();
        workinggrouphourtemp = this.workinggrouphour[key];
        this.WorkingGroupHour.push(workinggrouphourtemp);
      });     
    });      
  }

  getFineRule()
  {
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.AttendanceService.GetAttendanceFineRule( t).then(x => 
    {
      this.FineRule = x;
      Object.keys(this.FineRule).forEach( key => 
      {
        this.FineRule[key];
        let FineRuletemp = new AttendanceFineRule();
        FineRuletemp = this.FineRule[key];
        this.FineRules.push(FineRuletemp);
      });
    });
  }

  GetMapFineRuleByid(id) 
  {
    // begning of the permission portion
    this.IsDataIsInViewState=false;
    // end of the permission portion
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.AttendanceService.GetMapFineRuleByid( t,id).then(x => 
    {
      this.MapFineRule = x;
      Object.keys(this.MapFineRule).forEach( key => 
      {
        this.MapFineRule[key];
        let MapFineRuletemp = new MapFineRule();
        MapFineRuletemp = this.MapFineRule[key];
        this.MapFineRuleModel.RefId=MapFineRuletemp.RefId;
        this.MapFineRuleModel.FineRuleName=MapFineRuletemp.FineRuleName;
        this.MapFineRuleModel.WorkingGroupId=MapFineRuletemp.WorkingGroupId;
        this.MapFineRuleModel.AttFineRuleId=MapFineRuletemp.AttFineRuleId;
        this.MapFineRuleModel.IsActive=MapFineRuletemp.IsActive;
      });
    });
  }

  ViewMapFineRuleByid(id) 
  {
    // begning of the permission portion
    this.IsDataIsInViewState=true;
    // end of the permission portion
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.AttendanceService.GetMapFineRuleByid( t,id).then(x => 
    {
      this.MapFineRule = x;
      Object.keys(this.MapFineRule).forEach( key => 
      {
        this.MapFineRule[key];
        let MapFineRuletemp = new MapFineRule();
        MapFineRuletemp = this.MapFineRule[key];
        this.MapFineRuleModel.RefId=MapFineRuletemp.RefId;
        this.MapFineRuleModel.FineRuleName=MapFineRuletemp.FineRuleName;
        this.MapFineRuleModel.WorkingGroupId=MapFineRuletemp.WorkingGroupId;
        this.MapFineRuleModel.AttFineRuleId=MapFineRuletemp.AttFineRuleId;
        this.MapFineRuleModel.IsActive=MapFineRuletemp.IsActive;
      });
    });
  }
     
  saveMapFineRule() :void
  {
    this.DisableSave=false;
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.MapFineRuleModel.IsActive=true;
    if(this.MapFineRuleModel.RefId > 0)
    {
      this.AttendanceService.UpdateMapFineRule(t ,this.MapFineRuleModel).subscribe(res=>
      {
        this.MapFineRuleId=res;
        this.MapFineRuleModel.RefId=0;  
        if(this.MapFineRuleId>0)
        {
          localStorage.setItem('SuccessfullMessage', "Update Successfully");
          this.rout.navigateByUrl('/Attendence/ManageAttendenceFineRules', {skipLocationChange: true}).then(()=>this.rout.navigate(["/Attendence/ManageMapFineRule"]));
        }
      });                  
    }
    else
    {
      this.AttendanceService.AddMapFineRule(t ,this.MapFineRuleModel).subscribe(res=>
      {
        this.MapFineRuleId=res;
        this.MapFineRuleModel.RefId=0;              
        if(this.MapFineRuleId>0)
        {
          localStorage.setItem('SuccessfullMessage', "Save Successfully");
          this.rout.navigateByUrl('/Attendence/ManageAttendenceFineRules', {skipLocationChange: true}).then(()=>this.rout.navigate(["/Attendence/ManageMapFineRule"]));
        }
      }); 
    }
  }

  DeleteMapFineRuleByid(RefId : number) :void
  {
    this._confirmation.create('Confirm to Deleate', 'Press Yes to Delete', this.settings)
    .subscribe((ans: ResolveEmit) => 
    {
      if (ans.resolved == true) 
      {
        this.DisableSave=false;
        let t =  localStorage.getItem('userToken');
        t = 'Bearer' + t;
        this.MapFineRuleModel.IsActive=true;
        if( RefId > 0)
        {
          this.AttendanceService.DeleteMapFineRule(t ,RefId).subscribe(res=>
          {
            this.MapFineRuleId=res;
            this.MapFineRuleModel.RefId=0;                                  
            if(this.MapFineRuleId >0)
            {
              localStorage.setItem('SuccessfullMessage', "Deleted Successfully");
              this.rout.navigateByUrl('/Attendence/ManageAttendenceFineRules', {skipLocationChange: true}).then(()=>this.rout.navigate(["/Attendence/ManageMapFineRule"]));
            }
          });                  
        }
      }
    })
  }
  
  RefreshPage()
  {
    this.rout.navigateByUrl('/Attendence/ManageAttendenceFineRules', {skipLocationChange: true}).then(()=>this.rout.navigate(["/Attendence/ManageMapFineRule"]))
  }
}
