import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs/Subject';
import { DataTableDirective } from 'angular-datatables';
import { AttendanceService }  from '../../shared/HR_ADMIN/Attendance/AttendanceService.service';
import { WorkingGroupHours }  from '../../shared/HR_ADMIN/Attendance/WorkingGroupHours.model';
import { WorkingHours }  from '../../shared/HR_ADMIN/Attendance/WorkingHours.model';
import { UserService } from '../../shared/user.service';
// Begning Confirmation
import { ConfirmationService } from '@jaspero/ng2-confirmations';
import { ResolveEmit } from '../../../app/resolve-emit';
import { ConfirmSettings } from '../../../app/confirm-settings';
// end of Confirmation
import { Menu } from '../../shared/menu.model';

@Component({
  selector: 'app-manage-working-group',
  templateUrl: './manage-working-group.component.html',
  styleUrls: ['./manage-working-group.component.css']
})
export class ManageWeeklyWorkingGroupComponent implements OnInit 
{
  WorkingGroupModel : WorkingGroupHours= new WorkingGroupHours();
  userClaims: any;
  Result : string;
  SuccessStatus:boolean=false;
  SuccessMessage: string ="";
  public tableWidget: any;
  WorkingGroupHour: WorkingGroupHours[] = [];
  workinggroup:any;
  WorkingGroupId : any;

  WorkingHours: WorkingHours[] = [];
  Workinghour:any;

  Days: WorkingGroupHours[] = [];
  Day:any;
  // Begning Permission Portion    
  SelectedMenu:Menu;
  IsDataIsInViewState : boolean=false;
  CanViewState : boolean=false;
  DisableSave : boolean=false;
  // End of the permission portion
  
  tempWorkingGroupadd : WorkingGroupHours =new WorkingGroupHours();
  constructor(private AttendanceService: AttendanceService, private rout: Router,
     private _confirmation: ConfirmationService,private userService: UserService ) { }

  @ViewChild(DataTableDirective, {static: false})
  setDtOptions: DataTables.Settings = {};
  cardDtOptions: DataTables.Settings = {};
  dtElement: DataTableDirective;
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();

  ngOnInit()
  {
    // Begning Permission Portion 
    this.SelectedMenu=this.userService.SelectedMenu;
    if(this.SelectedMenu !=undefined)
    {
      localStorage.setItem('selectedmenu',JSON.stringify(this.SelectedMenu));
      this.rout.navigate([this.SelectedMenu.RouteName]);
    }
    else
    {
      if(JSON.parse(localStorage.getItem('selectedmenu'))!=undefined)
      {
        this.SelectedMenu = JSON.parse(localStorage.getItem('selectedmenu'));
        this.rout.navigate([this.SelectedMenu.RouteName]);
      }
    }
    if(this.SelectedMenu.CanAdd)
    {
      this.IsDataIsInViewState=false;
    }
    else
    {
      this.IsDataIsInViewState=true;
    }
    if(this.SelectedMenu.CanView)
    {
      this.CanViewState=false;
    }
    else
    {
      this.CanViewState=true;
    }
    // End of the permission portion
    this.DisableSave=true;
    this.getWorkingHours();
    this.getDay();
    this.WorkingGroupModel.Sunday=0;
    this.WorkingGroupModel.Monday=0;
    this.WorkingGroupModel.Tuesday=0;
    this.WorkingGroupModel.Wednesday=0;
    this.WorkingGroupModel.Thursday=0;
    this.WorkingGroupModel.Friday=0;
    this.WorkingGroupModel.Saturday=0;
    this.WorkingGroupModel.AlternateDay=0;
    this.WorkingGroupModel.IsAlternate=false;
    this.WorkingGroupModel.Week1=false;
    this.WorkingGroupModel.Week2=false;
    this.WorkingGroupModel.Week3=false;
    this.WorkingGroupModel.Week4=false;
    this.WorkingGroupModel.Week5=false;
    this.WorkingGroupModel.AdjustPenaltyWithLeave=false;
    this.dtOptions = 
    {
      pagingType: 'full_numbers',
      pageLength: 4
    };
    if(localStorage.getItem('SuccessfullMessage'))
    {
      this.SuccessStatus=true;
      this.SuccessMessage =localStorage.getItem('SuccessfullMessage')
      localStorage.removeItem('SuccessfullMessage');
    }
  }
  
  ngAfterViewInit(): void 
  {
    this.getWorkingGroup();
    this.dtOptions = 
    {
      pagingType: 'full_numbers',
      pageLength: 2
    };
  }
  
  settings: ConfirmSettings | any = 
  {
    overlay: true,
    overlayClickToClose: true,
    showCloseButton: true,
    confirmText: 'Yes',
    declineText: 'No'
  };

  getWorkingGroup()
  {
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.AttendanceService.GetWorkingGroupHours( t).then(x => 
    {
      this.workinggroup = x;
      Object.keys(this.workinggroup).forEach( key => 
      {
        this.workinggroup[key];
        let workinggrouptemp = new WorkingGroupHours();
        workinggrouptemp = this.workinggroup[key];
        this.WorkingGroupHour.push(workinggrouptemp);
      });
      this.dtTrigger.next();
    });
  }

  GetWorkingGroupHoursByid(id) 
  {
    // begning of the permission portion
    this.IsDataIsInViewState=false;
    // end of the permission portion
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.AttendanceService.GetWorkingGroupHoursByid( t,id).then(x => 
    {
      this.workinggroup = x;
      Object.keys(this.workinggroup).forEach( key => 
      {
        this.workinggroup[key];
        let workinggrouptemp = new WorkingGroupHours();
        workinggrouptemp = this.workinggroup[key];
        this.WorkingGroupModel.WorkingGroupId=workinggrouptemp.WorkingGroupId;
        this.WorkingGroupModel.WorkingGroupName=workinggrouptemp.WorkingGroupName;
        this.WorkingGroupModel.Sunday=workinggrouptemp.Sunday;
        this.WorkingGroupModel.Monday=workinggrouptemp.Monday;
        this.WorkingGroupModel.Tuesday=workinggrouptemp.Tuesday;
        this.WorkingGroupModel.Wednesday=workinggrouptemp.Wednesday;
        this.WorkingGroupModel.Thursday=workinggrouptemp.Thursday;
        this.WorkingGroupModel.Friday=workinggrouptemp.Friday;
        this.WorkingGroupModel.Saturday=workinggrouptemp.Saturday;
        this.WorkingGroupModel.IsAlternate=workinggrouptemp.IsAlternate;
        this.WorkingGroupModel.AlternateDay=workinggrouptemp.AlternateDay;
        this.WorkingGroupModel.Week1=workinggrouptemp.Week1;
        this.WorkingGroupModel.Week2=workinggrouptemp.Week2;
        this.WorkingGroupModel.Week3=workinggrouptemp.Week3;
        this.WorkingGroupModel.Week4=workinggrouptemp.Week4;
        this.WorkingGroupModel.Week5=workinggrouptemp.Week5;
        this.WorkingGroupModel.IsActive=workinggrouptemp.IsActive;
        this.WorkingGroupModel.AdjustPenaltyWithLeave=workinggrouptemp.AdjustPenaltyWithLeave;
      });
    });
  }

  ViewWorkingGroupHoursByid(id) 
  {
    // begning of the permission portion
    this.IsDataIsInViewState=true;
    // end of the permission portion
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.AttendanceService.GetWorkingGroupHoursByid( t,id).then(x => 
    {
      this.workinggroup = x;
      Object.keys(this.workinggroup).forEach( key => 
      {
        this.workinggroup[key];
        let workinggrouptemp = new WorkingGroupHours();
        workinggrouptemp = this.workinggroup[key];
        this.WorkingGroupModel.WorkingGroupId=workinggrouptemp.WorkingGroupId;
        this.WorkingGroupModel.WorkingGroupName=workinggrouptemp.WorkingGroupName;
        this.WorkingGroupModel.Sunday=workinggrouptemp.Sunday;
        this.WorkingGroupModel.Monday=workinggrouptemp.Monday;
        this.WorkingGroupModel.Tuesday=workinggrouptemp.Tuesday;
        this.WorkingGroupModel.Wednesday=workinggrouptemp.Wednesday;
        this.WorkingGroupModel.Thursday=workinggrouptemp.Thursday;
        this.WorkingGroupModel.Friday=workinggrouptemp.Friday;
        this.WorkingGroupModel.Saturday=workinggrouptemp.Saturday;
        this.WorkingGroupModel.IsAlternate=workinggrouptemp.IsAlternate;
        this.WorkingGroupModel.AlternateDay=workinggrouptemp.AlternateDay;
        this.WorkingGroupModel.Week1=workinggrouptemp.Week1;
        this.WorkingGroupModel.Week2=workinggrouptemp.Week2;
        this.WorkingGroupModel.Week3=workinggrouptemp.Week3;
        this.WorkingGroupModel.Week4=workinggrouptemp.Week4;
        this.WorkingGroupModel.Week5=workinggrouptemp.Week5;
        this.WorkingGroupModel.IsActive=workinggrouptemp.IsActive;
        this.WorkingGroupModel.AdjustPenaltyWithLeave=workinggrouptemp.AdjustPenaltyWithLeave;
      });
    });
  }
     
  saveWorkingGroupHours() :void
  {
    this.DisableSave=false;
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.WorkingGroupModel.IsActive=true;
    if(this.WorkingGroupModel.WorkingGroupId > 0)
    {
      this.AttendanceService.UpdateWorkingGroupHours(t ,this.WorkingGroupModel).subscribe(res=>
      {
        this.WorkingGroupId=res;
        this.WorkingGroupModel.WorkingGroupId=0;  
        if(this.WorkingGroupId>0)
        {
          localStorage.setItem('SuccessfullMessage', "Update Successfully");
          this.rout.navigateByUrl('/Attendence/ManageAttendenceFineRules', {skipLocationChange: true}).then(()=>this.rout.navigate(["/Attendence/ManageWorkingGroup"]));
        }
      });                  
    }
    else
    {
      this.AttendanceService.AddWorkingGroupHours(t ,this.WorkingGroupModel).subscribe(res=>
      {
        this.WorkingGroupId=res;
        this.WorkingGroupModel.WorkingGroupId=0;              
        if(this.WorkingGroupId>0)
        {
          localStorage.setItem('SuccessfullMessage', "Save Successfully");
          this.rout.navigateByUrl('/Attendence/ManageAttendenceFineRules', {skipLocationChange: true}).then(()=>this.rout.navigate(["/Attendence/ManageWorkingGroup"]));
        }
      }); 
    }
  }

  DeleteWorkingGroupHoursByid(WorkingGroupid : number) :void
  {
    this._confirmation.create('Confirm to Deleate', 'Press Yes to Delete', this.settings)
    .subscribe((ans: ResolveEmit) => 
    {
      if (ans.resolved == true) 
      {
        let t =  localStorage.getItem('userToken');
        t = 'Bearer' + t;
        this.WorkingGroupModel.IsActive=true;
        if( WorkingGroupid > 0)
        {
          this.AttendanceService.DeleteWorkingGroupHours(t ,WorkingGroupid).subscribe(res=>
          {
            this.WorkingGroupId=res;
            this.WorkingGroupModel.WorkingGroupId=0;                                  
            if(this.WorkingGroupId >0)
            {
              localStorage.setItem('SuccessfullMessage', "Deleted Successfully");
              this.rout.navigateByUrl('/Attendence/ManageAttendenceFineRules', {skipLocationChange: true}).then(()=>this.rout.navigate(["/Attendence/ManageWorkingGroup"]));
            }
          });                  
        }
      }
    })
  }

  getWorkingHours()
  {
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.AttendanceService.GetWorkingHours( t).then(x => 
    {
      this.Workinghour = x;
      Object.keys(this.Workinghour).forEach( key => 
      {
        this.Workinghour[key];
        let workinghourtemp = new WorkingHours();
        workinghourtemp = this.Workinghour[key];
        this.WorkingHours.push(workinghourtemp);
      });
    });
  }

  getDay()
  {
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.AttendanceService.GetDays( t).then(x => 
    {
      this.Day = x;
      Object.keys(this.Day).forEach( key => 
      {
        this.Day[key];
        let Daytemp = new WorkingGroupHours();
        Daytemp = this.Day[key];
        this.Days.push(Daytemp);
      });
    });
  }
// Alternate day Change
  oncheckedChange() 
  {
    this.WorkingGroupModel.Week1=false;
    this.WorkingGroupModel.Week2=false;
    this.WorkingGroupModel.Week3=false;
    this.WorkingGroupModel.Week4=false;
    this.WorkingGroupModel.Week5=false;
    this.WorkingGroupModel.AlternateDay=0;
  }

  RefreshPage()
  {
    this.rout.navigateByUrl('/Attendence/ManageAttendenceFineRules', {skipLocationChange: true}).then(()=>this.rout.navigate(["/Attendence/ManageWorkingGroup"]))
  }
}