

export class LeaveMasterModel
{
    public  LeaveMasterId : Number;
    public  LeaveType : Number;
    public  LiveTypeName : string;
    public  CompanyId :number;
    public CompanyName : string;
    public  TotalLeave : number;
    public  Fromdate : Date;
    public  Todate :Date;
    public  FromdatePass : string;

    public  ToDatePass : string;


}