import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AdminMasterService }  from '../../shared/HR_ADMIN/Admin/AdminMasterService.service';
import { EmployeeBudgetModel }  from '../../shared/HR_ADMIN/Admin/EmployeeBudgetModel';
import { UserService } from '../../shared/user.service';
import { Menu } from '../../shared/menu.model';
import { ModalOptions,BsModalRef, BsModalService } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-manage-employee-tour-budget',
  templateUrl: './manage-employee-tour-budget.component.html',
  styleUrls: ['./manage-employee-tour-budget.component.css']
})
export class ManageEmployeeTourBudgetComponent implements OnInit 
{
  EmployeeBudgetModel : EmployeeBudgetModel= new EmployeeBudgetModel();
  userClaims: any;
  EmployeeBudgets: EmployeeBudgetModel[] = [];
  EmployeeBudget:any;
  Result : string;
  SuccessStatus:boolean=false;
  SuccessMessage: string ="";
  public tableWidget: any;
  RefId : any;
  // Begning Permission Portion    
  SelectedMenu:Menu;
  IsDataIsInViewState : boolean=false;
  CanViewState : boolean=false;
  DisableSave :boolean=false;
  // End of the permission portion
  //For Pop up
  SaveMsg : boolean= false;
  UpdateMsg : boolean= false;
  Insert : boolean=false;
  Updated : boolean= false;
  public modalRef: BsModalRef;
  public modelopt:ModalOptions;

  EmployeeSettings = {};
  selectedItems = [];
  emp : any [] =[];
  Employees: EmployeeBudgetModel[] = [];

  constructor(private AdminMasterService: AdminMasterService, private rout: Router, private userService: UserService,private modalService: BsModalService)
  {
    this.modelopt=new ModalOptions();
    this.modelopt.class='heavy_modal';
    this.modalService.config= this.modelopt;
    this.modelopt.ignoreBackdropClick=true;
  }

  ngOnInit() 
  {
    //Begin Permission Portion
    this.SelectedMenu=this.userService.SelectedMenu;
    if(this.SelectedMenu !=undefined)
    {
      localStorage.setItem('selectedmenu',JSON.stringify(this.SelectedMenu));
      this.rout.navigate([this.SelectedMenu.RouteName]);
    }
    else
    {
      if(JSON.parse(localStorage.getItem('selectedmenu'))!=undefined)
      {
        this.SelectedMenu = JSON.parse(localStorage.getItem('selectedmenu'));
        this.rout.navigate([this.SelectedMenu.RouteName]);
      }
    }     
    if(this.SelectedMenu.CanAdd)
    {
      this.IsDataIsInViewState=false;
    }
    else
    {
      this.IsDataIsInViewState=true;
    }
    if(this.SelectedMenu.CanView)
    {
      this.CanViewState=false;
    }
    else
    {
      this.CanViewState=true;
    }
    //End of the peremission portion
    this.SaveMsg=false;
    this.UpdateMsg=false;
    this.Insert=false;
    this.Updated=false;
    this.DisableSave=true; 
    this.GETEmployeeBudget(); 
    this.EmployeeSettings =
    {
      singleSelection:true,
      text:'Select Employee',
      enableSearchFilter:true,
      showCheckbox:false
    }   
  }

  GETEmployeeBudget()
  {
    this.EmployeeBudgets =[];
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.AdminMasterService.GetEmployeeBudgetFORGV( t).then(x => 
    {
      this.EmployeeBudget = x;
      Object.keys(this.EmployeeBudget).forEach( key => 
      {
        this.EmployeeBudget[key];
        let Expensetemp = new EmployeeBudgetModel();
        Expensetemp = this.EmployeeBudget[key];
        this.EmployeeBudgets.push(Expensetemp);
      });
    });
  }

  GetEmployeeTourBudgetById(id,template) 
  {
    this.EmployeeBudgetModel.EmployeeId = null;
    this.EmployeeBudgetModel.EmployeeCode = null;
    this.EmployeeBudgetModel.EmployeeName = null;
    this.EmployeeBudgetModel.NetAvailableAmount = null;
    this.EmployeeBudgetModel.CreditAmount=null;
    this.EmployeeBudgetModel.DebitAmount=null;
    this.EmployeeBudgetModel.RefId = null;
    this.EmployeeBudgetModel.Remarks=null;
    // Begning of the permission portion
    this.IsDataIsInViewState=false;
    // End of the permision portion
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.AdminMasterService.GetEmployeeTourBudgetById( t,id).then(x => 
    {
      this.modalRef = this.modalService.show(template,this.modelopt);
      this.Updated=true;
      this.SaveMsg=false;
      this.UpdateMsg=false;
      this.Insert=false;
      this.EmployeeBudget = x;
      Object.keys(this.EmployeeBudget).forEach( key => 
      {
        this.EmployeeBudget[key];
        let Expensetemp = new EmployeeBudgetModel();
        Expensetemp = this.EmployeeBudget[key];
        this.EmployeeBudgetModel.EmployeeId=Expensetemp.EmployeeId;
        this.EmployeeBudgetModel.RefId=Expensetemp.RefId;
        this.EmployeeBudgetModel.EmployeeCode=Expensetemp.EmployeeCode;
        this.EmployeeBudgetModel.EmployeeName=Expensetemp.EmployeeName;
        this.EmployeeBudgetModel.NetAvailableAmount=Expensetemp.NetAvailableAmount;
      });
    });
  }

  saveEmployeeBudget(template) :void
  {
    this.DisableSave=false;
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    if(this.EmployeeBudgetModel.RefId > 0)
    {
      this.AdminMasterService.UpdateEmployeeTourBudget(t ,this.EmployeeBudgetModel).subscribe(res=>
      {
        this.RefId=res;
        this.EmployeeBudgetModel.RefId=0;  
        if(this.RefId>0)
        {
          this.Employees=[];
          this.emp=[];
          this.selectedItems = [];
          this.EmployeeBudgetModel.OpeningAmount=null;
          this.modalRef.hide();
          this.SaveMsg=false;
          this.UpdateMsg=true;
          this.Insert=false;
          this.Updated=false;
          this.modalRef = this.modalService.show(template);
          this.GETEmployeeBudget();
          this.DisableSave=true;
        }
      });
    }
    else
    {
      this.AdminMasterService.AddEmployeeTourBudget(t ,this.EmployeeBudgetModel).subscribe(res=>
      {
        this.RefId=res;
        this.EmployeeBudgetModel.RefId=0;              
        if(this.RefId>0)
        {
          this.Employees=[];
          this.emp=[];
          this.selectedItems = [];
          this.EmployeeBudgetModel.EmployeeId = null;
          this.EmployeeBudgetModel.EmployeeCode = null;
          this.EmployeeBudgetModel.EmployeeName = null;
          this.EmployeeBudgetModel.NetAvailableAmount = null;
          this.EmployeeBudgetModel.CreditAmount=null;
          this.EmployeeBudgetModel.DebitAmount=null;
          this.EmployeeBudgetModel.RefId = null;
          this.EmployeeBudgetModel.Remarks=null;
          this.modalRef.hide();
          this.SaveMsg=true;
          this.UpdateMsg=false;
          this.Insert=false;
          this.Updated=false;
          this.modalRef = this.modalService.show(template);
          this.GETEmployeeBudget();
          this.DisableSave=true;
        }
      }); 
    }
  }

  ADDClick(template)
  {
    this.EmployeeBudgetModel.EmployeeId = null;
    this.EmployeeBudgetModel.EmployeeCode = null;
    this.EmployeeBudgetModel.EmployeeName = null;
    this.EmployeeBudgetModel.NetAvailableAmount = null;
    this.EmployeeBudgetModel.CreditAmount=null;
    this.EmployeeBudgetModel.DebitAmount=null;
    this.EmployeeBudgetModel.RefId = null;
    this.EmployeeBudgetModel.Remarks=null;
    this.selectedItems = [];
    this.Employees=[];
    this.emp=[];
    this.GetEmployeeDetailsDDL();
    this.EmployeeBudgetModel.OpeningAmount=null;
    this.IsDataIsInViewState=false;
    this.modalRef = this.modalService.show(template,this.modelopt);
    this.Insert=true;
    this.SaveMsg=false;
    this.UpdateMsg=false;
    this.Updated=false;
    this.DisableSave=true;
  }

  GetEmployeeDetailsDDL() 
  {
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.AdminMasterService.GetEmployeesNotExistsinBudgetForDDL( t).then(x => 
    {
      this.Employees=[];
      this.emp=[];
      this.EmployeeBudget = x;
      Object.keys(this.EmployeeBudget).forEach( key => 
      {
        let Emptemp = new EmployeeBudgetModel();
        Emptemp = this.EmployeeBudget[key];
        this.Employees.push(Emptemp);
        this.emp.push({"id":Emptemp.EmployeeId,"itemName":Emptemp.EmployeeCode + '-' +Emptemp.EmployeeName},);
      });     
    });      
  }

  onItemSelect(item:any)
  {
    let empid= item['id'];
    this.EmployeeBudgetModel.EmployeeId=empid;  
    this.DisableSave=true;    
  }
  OnItemDeSelect(item:any){ }
  onSelectAll(items: any){ }
  onDeSelectAll(items: any){ }

  onchangeDebitAmount(Debitamount)
  {
    if(Debitamount > 0 && this.EmployeeBudgetModel.NetAvailableAmount >= Debitamount)
    {
      this.EmployeeBudgetModel.DebitAmount=Debitamount;
      this.EmployeeBudgetModel.CreditAmount=null;
    }
    else
    {
      this.EmployeeBudgetModel.DebitAmount=null;
    }
  }

  onchangeCreditAmount(CreditAmount)
  {
    if(CreditAmount > 0)
    {
      this.EmployeeBudgetModel.DebitAmount=null;
      this.EmployeeBudgetModel.CreditAmount=CreditAmount;
    }
    else
    {
      this.EmployeeBudgetModel.CreditAmount=null;
    }
  }
}