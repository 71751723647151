import { Component, OnInit, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Router } from '@angular/router';
import { ReportType } from '../../shared/HR_ADMIN/Report/ReportType'
import { UserService } from '../../shared/user.service';
import { EmployeeMasterService } from '../../shared/HR_ADMIN/Employee/EmployeeMaster.Service';
import { EmployeeDetail } from '../../shared/HR_ADMIN/Employee/EmployeeDetail.Model';
import { MonthlyAttendanceModel } from '../../shared/HR_ADMIN/Salary/MonthlyAttendanceModel';
import { EmployeeSalaryComponentAttendence } from '../../shared/HR_ADMIN/Salary/EmployeeSalaryComponentAttendence';
import { OrganizationService } from '../../shared/Organization/organization.service';
import { SalaryMasterService }  from '../../shared/HR_ADMIN/Salary/SalaryMaster.service';
import { Company } from '../../shared/Organization/Company.Model';
import { FinancialYear } from  '../../shared/Organization/FinancialYear.Model';
//End of notification service
import { Menu } from '../../shared/menu.model';

@Component({
  selector: 'app-employee-payslip',
  templateUrl: './employee-payslip.component.html',
  styleUrls: ['./employee-payslip.component.css']
})

export class EmployeePayslipComponent implements OnInit 
{
  EmployeeMonthelyAttendenceModel: EmployeeSalaryComponentAttendence = new EmployeeSalaryComponentAttendence();
  EmployeeMonthelyAttendenceModelResult: EmployeeSalaryComponentAttendence = new EmployeeSalaryComponentAttendence();
  EmployeeDetailModel: EmployeeDetail = new EmployeeDetail();
  userClaims: any;
  Employees: EmployeeDetail[] = [];
  employee: any;
  //Reports Start
  ReportTypeList: ReportType[] = [];
  ReportExecl: ReportType;
  ReportPdf: ReportType;
  //Reports End
  //
  empcode: string;
  EmployeeMonthelyAttendenceModelList: MonthlyAttendanceModel[] = [];
  Attendance: any;
  SubMenues: Menu[] = [];
  Mod: any;
  initialrow : number=0;
  Result: string;
  SuccessStatus: boolean = false;
  SuccessMessage: string = "";
  public tableWidget: any;
  EmployeeId: any;
  selectedIndex: number;
  // Begning Permission Portion    
  SelectedMenu: Menu;
  IsDataIsInViewState: boolean = false;
  CanViewState: boolean = true;
  DisableSave: boolean = false;
  // End of the permission portion
   // begin for Validation
  validdate: boolean = false;
  // end of validation
  Att : any;
  FinancialYears: FinancialYear[] = [];
  FinYear:any;
  Months: Company[] = [];
  Month: any;
  TotalaEarnings : number=0;
  TotalDeduction : number=0;
  LopAmount : number =0;
  NoRecordMsg : boolean= false;

  constructor(private EmployeeMasterService: EmployeeMasterService, private rout: Router, private userService: UserService, private OrganizationService: OrganizationService, private SalaryService : SalaryMasterService, @Inject(DOCUMENT) document) { }

  ngOnInit() {

    // Begning Permission Portion 
    this.SelectedMenu=this.userService.SelectedMenu;
    if(this.SelectedMenu !=undefined)
    {
      localStorage.setItem('selectedmenu',JSON.stringify(this.SelectedMenu));
      this.rout.navigate([this.SelectedMenu.RouteName]);
    }
    else
    {
      if(JSON.parse(localStorage.getItem('selectedmenu'))!=undefined)
      {
        this.SelectedMenu = JSON.parse(localStorage.getItem('selectedmenu'));
        this.rout.navigate([this.SelectedMenu.RouteName]);
      }
    }
    if(this.SelectedMenu.CanAdd)
    {
      this.IsDataIsInViewState=false;
    }
    else
    {
      this.IsDataIsInViewState=true;
    }
    if(this.SelectedMenu.CanView)
    {
      this.CanViewState=false;
    }
    else
    {
      this.CanViewState=true;
    }
    // End of the permission portion
    this.DisableSave = true;
    this.EmployeeMonthelyAttendenceModel.EmployeeId = 0;
    this.EmployeeMonthelyAttendenceModel.Year=0;
    this.EmployeeMonthelyAttendenceModel.MonthId=0;    
    
    this.getMonths();
    this.GetYear();
    this.GetEmployeeDetailsDDL();
    this.NoRecordMsg = false;
  }

  getMonths() 
  {
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.Months=[];
    this.OrganizationService.GetMonthName( t).then(x => 
    {
      this.Month = x;
      Object.keys(this.Month).forEach( key => 
      {
        this.Month[key];
        let Monthtemp = new Company();
        Monthtemp = this.Month[key];
        this.Months.push(Monthtemp);
      });     
    });      
  }

  GetYear()
  {
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.FinancialYears=[];
    this.OrganizationService.GetYear( t).then(x => 
    {
      this.FinYear = x;
      Object.keys(this.FinYear).forEach( key => 
      {
        this.FinYear[key];
        let FinYeartemp = new FinancialYear();
        FinYeartemp = this.FinYear[key];
        this.FinancialYears.push(FinYeartemp);
      });     
    });      
  }

  GetEmployeeDetailsDDL()
  {
    let t = localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.EmployeeMasterService.GetEmployeeDetailsForDDL(t).then(x => 
    {
      this.employee = x;
      Object.keys(this.employee).forEach(key => 
      {
        let RHtemp = new EmployeeDetail();
        RHtemp = this.employee[key];
        this.Employees.push(RHtemp);
      });
    });
  }

  GetEmployeeSalaryDetails()
  {
    this.NoRecordMsg = false;
    let t = localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.SalaryService.GetEmployeesEmployeePayslip(t, this.EmployeeMonthelyAttendenceModel.MonthId, this.EmployeeMonthelyAttendenceModel.Year).then(x => 
    {
      this.NoRecordMsg = false;
      this.Attendance = x;
      this.EmployeeMonthelyAttendenceModelResult=this.Attendance;
      if(this.EmployeeMonthelyAttendenceModelResult.AdjustmentAddition > 0)
      {
        this.EmployeeMonthelyAttendenceModelResult.SalaryCompList.find(x=>x.SalaryComponentId==2).Amount =
        (this.EmployeeMonthelyAttendenceModelResult.SalaryCompList.find(x=>x.SalaryComponentId==2).Amount) + (this.EmployeeMonthelyAttendenceModelResult.AdjustmentAddition);
      }
      if(this.EmployeeMonthelyAttendenceModelResult.AdjustmentSubtraction > 0)
      {
        this.EmployeeMonthelyAttendenceModelResult.SalaryCompList.find(x=>x.SalaryComponentId==2).Amount=
        (this.EmployeeMonthelyAttendenceModelResult.SalaryCompList.find(x=>x.SalaryComponentId==2).Amount) - (this.EmployeeMonthelyAttendenceModelResult.AdjustmentSubtraction);
      }

      if(this.EmployeeMonthelyAttendenceModelResult.GrossSalary == 0 || this.EmployeeMonthelyAttendenceModelResult.GrossSalary == null)
      {
        this.NoRecordMsg = true;
      }
    });
  }

  print(): void 
  {
    let printContents, popupWin;
    printContents = document.getElementById('Payslip').innerHTML;
    popupWin = window.open('', '_blank', 'letf=0,top=0,width=2480px,height=3508px,toolbar=0, scrollbars=0,sta­tus=0');
    popupWin.document.open();
    popupWin.document.write(`
      <html>
        <head>
        <title>Employee PaySlip</title>
          <style=" margin:25px 10px 15px 30px; font-family:Calibri">
          
          </style>
        </head>
      <body onload="window.print();window.close()">${printContents}</body>
      </html>`
    );
    popupWin.document.close();
  }
}
