export class MapWorkingGroup
{
    public EmpWorkingGroupId : number;
    public EmployeeId : number;
    public EmployeeCode : number;
    public EmployeeName: string;
    public WorkingGroupId : number;
    public FromDate : Date;
    public ToDate : Date;
    public IsActive : boolean;
}