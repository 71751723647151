<div class="container-fluid" id="link1">
  <div class="row">
    <div class="col-lg-9">
      <div class="row">
        <div class="col-lg-1 low-padding text-center">
          <img *ngIf="this.SelectedMenu.Image!=null" src={{this.SelectedMenu.Image}} class="body-icon" alt="Icon" />
        </div>
        <div class="col-lg-10 clear-padding">
          <span class="page-title">Manage Monthly Attendance Details</span>
          <small class="page-description"></small>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="!(this.CanViewState)">
    <fieldset class="body-form-control">
      <legend class="legend--">Monthly Attendance Details</legend>
      <form #EmployeeAttendanceForm="ngForm">
        <div class="row">
          <div class="col-lg-4">
            <div class="col-body-border-right">
              <div class="form-group row">
                <label class="col-sm-4 col-form-label clear-padding-right">Company</label>
                <div class="col-sm-7 clear-left-padding">
                  <select [(ngModel)]="MonthlyAttendanceModel.CompanyId"
                    (ngModelChange)="onChangeCompany($event)" id="CompanyId" class="form-control" name="CompanyId"
                    #CompanyId="ngModel">
                    <option [value]="0" [disabled]=true>Select Company</option>
                    <option *ngFor="let c of Companies" [value]="c.CompId">{{c.CompName}}</option>
                  </select>
                </div>
                <div class="required-validation" *ngIf="MonthlyAttendanceModel.CompanyId==0">Company is required</div>
              </div>
              <div class="form-group row">
                <label class="col-sm-4 col-form-label clear-padding-right">Financial Year</label>
                <div class="col-sm-7 clear-left-padding">
                  <select [(ngModel)]="MonthlyAttendanceModel.FinYear" id="FinancialYear"
                    class="form-control" name="FinancialYear" #FinYear="ngModel">
                    <option [value]="0" [disabled]=true>Select Financial Year</option>
                    <option *ngFor="let FY of FinancialYears" [value]="FY.FinancialyearId">{{FY.FinancialYear}}</option>
                  </select>
                </div>
                <div class="required-validation" *ngIf="MonthlyAttendanceModel.FinYear==0">Financial Year is required
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-4">
            <div class="col-body-border-right">
              <div class="form-group row">
                <label class="col-sm-4 col-form-label clear-padding-right">Branch</label>
                <div class="col-sm-7 clear-left-padding">
                  <select [(ngModel)]="MonthlyAttendanceModel.BranchId" id="BranchId" class="form-control"
                    name="BranchId" #BranchId="ngModel" (ngModelChange)="onchangeBranch($event)">
                    <option [value]="0" [disabled]=true>Select Branch</option>
                    <option *ngFor="let b of Branches" [value]="b.BranchId">{{b.BranchName}}</option>
                  </select>
                </div>
                <div class="required-validation" *ngIf="MonthlyAttendanceModel.BranchId==0">Branch is required</div>
              </div>
              <div class="form-group row">
                <label class="col-sm-4 col-form-label clear-padding-right">Month</label>
                <div class="col-sm-7 clear-left-padding">
                  <select [(ngModel)]="MonthlyAttendanceModel.MonthId" id="MonthId" class="form-control"
                    name="MonthId" #MonthId="ngModel">
                    <option [value]="0" [disabled]=true>Select Month</option>
                    <option *ngFor="let Mo of Months" [value]="Mo.MonthId">{{Mo.MonthName}}</option>
                  </select>
                </div>
                <div class="required-validation" *ngIf="MonthlyAttendanceModel.MonthId==0">Month is required</div>
              </div>
            </div>
          </div>
          <div class="col-lg-4">
            <div class="form-group row">
              <label class="col-sm-3 col-form-label clear-padding-right">Department</label>
              <div class="col-lg-8">
                <angular2-multiselect class="form-control" [data]="DepartmentList" [(ngModel)]="DepartmentselectedItems"
                  name="DepartmentselectedItems" [settings]="DepartmentSettings" (onSelect)="onItemSelect($event)"
                  (onDeSelect)="OnItemDeSelect($event)" (onSelectAll)="onSelectAll($event)"
                  (onDeSelectAll)="onDeSelectAll($event)">
                </angular2-multiselect>
              </div>
            </div>
            <div class="form-group row">
              <div class="col-sm-7 clear-left-padding">
                <button type="submit" (click)="submitClick()" class="btn btn-primary save-button"
                  [disabled]="!(EmployeeAttendanceForm.valid && MonthlyAttendanceModel.MonthId > 0 && MonthlyAttendanceModel.CompanyId > 0 && MonthlyAttendanceModel.BranchId > 0 && MonthlyAttendanceModel.FinYear > 0)">Generate</button>
              </div>
            </div>
          </div>
        </div>
      </form>
    </fieldset>

    <app-dialog [(visible)]="this.SuccessStatus">
      <span class="successmsg">{{this.SuccessMessage}}</span>
    </app-dialog>
    <fieldset class="body-form-control" *ngIf="MonthlyAttendances.length > 0">
      <legend class="legend--">Monthly Attendance</legend>
      <form #MonthlyAttendance="ngForm">
        <div>
          <div class="row">
            <div class="col-lg-4">
              <div class="col-body-border-right">
                <div class="form-group row">
                  <label class="col-sm-4 col-form-label clear-padding-right"> From Date</label>
                  <div class="col-sm-7 clear-left-padding">
                    {{this.MonthlyAttendanceModel.AttendanceFromDate | date : "dd-MMM-yyyy"}}
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-4">
              <div class="col-body-border-right">
                <div class="form-group row">
                  <label class="col-sm-4 col-form-label clear-padding-right">To Date</label>
                  <div class="col-sm-7 clear-left-padding">
                    {{this.MonthlyAttendanceModel.AttendanceToDate | date : "dd-MMM-yyyy"}}
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-4">
              <div class="form-group row">
                <label class="col-sm-4 col-form-label clear-padding-right">No Of Days</label>
                <div class="col-sm-7 clear-left-padding">
                  {{this.MonthlyAttendanceModel.NoDaysinMonth}}
                </div>
              </div>
            </div>
          </div>

          <table id="tblMonthlyAttendance" class="table table-striped table-bordered table-new row-border hover"
            fixed-header>
            <thead class="thead-blue text-center">
              <tr style="width: 100%;" class="table-head" fixed-header>
                <th style="width: 4%;">Sl No.</th>
                <th style="width: 8%;">Code</th>
                <th style="width: 15%;">Name</th>
                <th style="width: 4%;">P</th>
                <th style="width: 4%;">WO</th>
                <th style="width: 4%;">HO</th>
                <th style="width: 4%;">DD</th>
                <th style="width: 4%;">TR</th>
                <th style="width: 4%;">L</th>
                <th style="width: 4%;">A</th>
                <th style="width: 4%;">LOP</th>
                <th style="width: 4%;">EA</th>
                <!-- <th>PA</th>
                <th>Adjust Leave</th> -->
                <th style="width: 4%;">MA</th>
                <th style="width: 6%;">Add LOP</th>
                <th style="width: 6%;">Deduct LOP</th>
                <!-- <th style="width: 4%;">Other Att</th> -->
                <th style="width: 4%;">FA</th>
                <th style="width: 4%;">LOP</th>
                <th style="width: 9%;">Remarks</th>
                <th style="width: 4%;">
                  <input type="checkbox" name="all" (change)="checkAll($event)" />
                </th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let att of MonthlyAttendances, let i = index" class="text-center">
                <td style="width: 4%;" class="text-left" scope="row">{{i+1}}</td>
                <td style="width: 8%;" class="text-center">
                  <span>{{att.EmployeeCode}}</span>
                  <input type="text" hidden="hidden" [(ngModel)]="att.EmployeeId" id="EmployeeId" name="EmployeeId"
                    #EmployeeId="ngModel">
                  <input type="text" hidden="hidden" [(ngModel)]="att.AbsentAdjustWithLeave" id="AbsentAdjustWithLeave"
                    name="AbsentAdjustWithLeave" #AbsentAdjustWithLeave="ngModel">
                </td>
                <td style="width: 15%; text-align: left;">
                  <span>{{att.EmployeeName}}</span>
                </td>
                <td style="width: 4%;">
                  <span>{{att.TotalPresent}}</span>
                </td>
                <td style="width: 4%;">
                  <span>{{att.TotalWeeklyOff}}</span>
                </td>
                <td style="width: 4%;">
                  <span>{{att.TotalHolidays}}</span>
                </td>
                <td style="width: 4%;">
                  <span>{{att.TotalDirectDuty}}</span>
                </td>
                <td style="width: 4%;">
                  <span>{{att.TotalTour}}</span>
                </td>
                <td style="width: 4%;">
                  <span>{{att.TotalLeave}}</span>
                </td>
                <td style="width: 4%;">
                  <span>{{att.TotalAbsent}}</span>
                </td>
                <td style="width: 4%;">
                  <span>{{att.TotalLateAttendance}}</span>
                </td>
                <td style="width: 4%;">
                  <span>{{att.TotalEarlyAttendance}}</span>
                </td>
                <!-- <td style=" background-color: rgb(243, 213, 247);">
                  <span>{{att.PenaltyAttendance}}</span>
                </td>
                <td>
                  <span>{{att.AdjustWithLeave}}</span>
                </td> -->
                <td style=" background-color: chartreuse; width: 4%;">
                  <span>{{att.MainAttendance}}</span>
                </td>
                <td style="width: 6%;">
                  <input type="text" [(ngModel)]="att.AddPenalty" id="AddPenalty_{{i}}"
                    name="AddPenalty_{{i}}" (ngModelChange)="AddPenaltyChanged(i)" #AddPenalty="ngModel"
                    class="form-control form-control-custom form-control-custom-new">
                </td>
                <td style="width: 6%;">
                  <input type="text" [(ngModel)]="att.DeductPenalty" id="DeductPenalty_{{i}}"
                    name="DeductPenalty_{{i}}" (ngModelChange)="DeductPenaltyChanged(i)" #DeductPenalty="ngModel"
                    class="form-control form-control-custom form-control-custom-new">
                </td>
                <!-- <td style="width: 4%;">
                  <input type="text" [(ngModel)]="att.OtherAttendance" tabindex="9" id="OtherAttendance_{{i}}"
                    name="OtherAttendance_{{i}}" (ngModelChange)="OtherAttendanceChanged(i)" #OtherAttendance="ngModel"
                    class="form-control form-control-custom form-control-custom-new">
                </td> -->
                <td style=" background-color: chartreuse; width: 4%;">
                  <span>{{att.FinalAttendance}}</span>
                </td>
                <td style=" background-color: rgb(243, 213, 247); width: 4%;">
                  <span>{{att.FinalLOP}}</span>
                </td>
                <td style="width: 9%;">
                  <input type="text" [(ngModel)]="att.Remarks" id="Remarks_{{i}}" name="Remarks_{{i}}"
                    #Remarks="ngModel" class="form-control form-control-custom form-control-custom-new">
                </td>
                <td style=" text-align: center; width: 4%;">
                  <input type="checkbox" [checked]="att.Selected" *ngIf="!(att.FinalSubmit)"
                    id="Selected_{{i}}" name="Selected_{{i}}" (change)="SelectedChange(i,$event)">
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="row">
          <div class="col-lg-4">
          </div>
          <div class="col-lg-4">
            <div class="form-group row">
              <label class="col-sm-6 col-form-label text-center clear-padding-right"
                *ngIf="!(this.IsDataIsInViewState)">
                <button type="submit" class="btn btn-primary save-button"
                  [disabled]="!(MonthlyAttendance.valid && this.MonthlyAttendances.length > 0 && this.DisableSave==true)"
                  (click)="SaveMonthlyAttendance(template)">Save</button>
              </label>
              <div class="col-sm-6 col-form-label text-center" *ngIf="!(this.IsDataIsInViewState)">
                <button type="submit" class="btn btn-primary save-button"
                  [disabled]="!(MonthlyAttendance.valid && this.MonthlyAttendances.length > 0 && this.DisableSave==true)"
                  (click)="FinalSaveMonthlyAttendance(template)"> Final Submit</button>
              </div>
            </div>
          </div>
          <div class="col-lg-4">
            <div class="form-group row">
              <div class="col-sm-6 col-form-label text-center clear-padding-left">
                <button type="button" class="btn btn-danger cancle-button" (click)="RefreshPage()">Refresh
                  Page</button>
              </div>
            </div>
          </div>
        </div>
      </form>
    </fieldset>

    <div style=" box-align: center;" class="new-modal">
      <ng-template #template>
        <div class="modal-header" style="background: #2a9bd7;">
          <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()"
            style="color:#fff;">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body" style="text-align: center;">
          <div style="color: #dc3545; text-align: center;font-size: 15px; font-weight: bold;">Attendance Modified
            Successfully</div>
          <button type="button" aria-label="Close" (click)="modalRef.hide()"
            style="background: #2a9bd7; outline: 0px none; border: 0px none; color: #fff; padding: 5px 10px; border-radius: 3px;">
            <span>OK</span>
          </button>
        </div>
      </ng-template>
    </div>

  </div>
</div>