export class Model_CRM_B2B
    {
        public  ID : number;
        public  CompanyName : string;
        public  CountryId : number;
        public  StateId : number;
        public  CityId :number;
        public  Address : string;
        public  Pin : string;
        public  Client_Past_New : string;
        public  Clienttype : string;


         public ContactPerson :string;            // Added On 05.12.2018 
         public CurrentVendor :string;           // Added On 05.12.2018
         public TotalAnnualVol : string;        //Added On 05.12.2018 
         public CNRatio :string;                // Added On 05.12.2018 
         public CardRatio: string;                // Added On 05.12.2018
         public FeedbackProposalDetails: string;  //Added On 05.12.2018 


         public NextFollowUp: Date;                     //Added On 05.12.2018 
         public IsNextFollowUpDateValid: boolean;    // Next Follow Up Date  validation



        public  MR : string;
        public  FirstName : string;
        public  MiddleName : string;
        public  LastName : string;
        public  FullName : string;
        public  Title : string;


        public  DOB :Date;
        public  IsDOBValid : boolean;    // Date Of Birth  validation



        public  AnniverSaryDate :Date;   // Anniversary Date Validation
        public IsAnniversaryDateValid: boolean;    // Aniversary Date Validation



        public  OtherIdNo :string;
        public  DocumentPhotoUpload	 : String;
        public  DocumentImage :string;
        public  IsOtherDocumentChenge : boolean;
        public  OtherDocumentName : string;
        public  OtherDocumentType : string;
        public  OtherDocumentTypeimage : string;
        public  validDocumentImage : boolean;   
        public  PassPortNo : string;


        public  PassPortValidityDate : Date;    // Passport Date 
        public IsPassportDateValid: boolean;   // Pasport Date Validation 
        

        public  PassportPhotoUpload :string;
        public  PassportImage : string;        
        public  IsPasportChenge :boolean;
        public  PassPorteName :string;
        public  PassPorteImageName :string;
        public  PassportType : string;
        public  PassportTypeimage : string;
        public  validPassportImage : boolean;
       

        public  PhoneNo :string;
        public  IsPhoneNoValid : boolean;  // For Phone Number Validation 



        public  EmailId :string;
        public  IsEmailValid :boolean;    // For Email Validation



        public  Extension :string;
        public  Role :string;
        public  IsEmialOffer :boolean;
        public  IsSmsOffer : boolean;
        public  PanNo : string
        public  GSTNO : string
        public  SACNO : string;
        public  Active : boolean;

        public  CountryName : string;       
        public  State : string; 
        public  City  : string;


    }