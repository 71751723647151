import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms'
import { RouterModule } from '@angular/router'
import { DatePicker } from './myDatepicker';
import { EmailValidatorDirective } from './email-validator.directive'
import { ChangableDatePicker } from './myDatePickerChangable'
//import { NumberDirective } from './NumberDirective'

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    RouterModule

  ],
  declarations: [
    DatePicker,
    EmailValidatorDirective,
    ChangableDatePicker
    // NumberDirective
  ],
  providers: [],
  exports: [DatePicker, EmailValidatorDirective, ChangableDatePicker]
})
export class ForAllModule { }
