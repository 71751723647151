export class KRAMasterModel
{
    public RefId : number;
    public KRAName : string;
    public RatingCriteria : string;
    public IsActive : boolean;
    public TemplateName : string;
    public TemplateMasterRefId : number;
    public TemplateRefId : number;
    public RownumberKR : number;
    public TotalKRAWeighted : number;
    public KRAWeighted : number;
    public CompanyId : number;
    public CompanyIdShow : number;
}