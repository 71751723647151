import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from '../../shared/user.service';
import { EmployeeMasterService }  from '../../shared/HR_ADMIN/Employee/EmployeeMaster.Service';
import { EmployeeDetail } from '../../shared/HR_ADMIN/Employee/EmployeeDetail.Model';
import { EmployeeAttendanceModel } from '../../shared/HR_ADMIN/EmployeePortal/EmployeeAttendanceModel.Model';
import { Menu } from '../../shared/menu.model';
import { FinancialYear } from  '../../shared/Organization/FinancialYear.Model';
import { Company } from '../../shared/Organization/Company.Model';
import { OrganizationService } from '../../shared/Organization/organization.service';
import { EmployeePortalService } from '../../shared/HR_ADMIN/EmployeePortal/EmployeePortalService.service'
import { EmployeeLeaveBalanceModel } from '../../shared/HR_ADMIN/EmployeePortal/EmployeeLeaveBalanceModel.Model';
import { ConfirmationService } from '@jaspero/ng2-confirmations';
import { ResolveEmit } from '../../../app/resolve-emit';
import { ConfirmSettings } from '../../../app/confirm-settings';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-manage-attendance-modification-hr',
  templateUrl: './manage-attendance-modification-hr.component.html',
  styleUrls: ['./manage-attendance-modification-hr.component.css']
})
export class ManageAttendanceModificationHrComponent implements OnInit 
{
  EmployeeAttendanceModel : EmployeeAttendanceModel= new EmployeeAttendanceModel();
  EmployeeAttendance: EmployeeAttendanceModel[]=[];
  Attendance: any;
  employee: any;
  RefId: any;
  SelectedMenu:Menu;
  NoRecordMsg : boolean= false;
  Employees: EmployeeDetail[] = [];
  emp : any [] =[];
  FinancialYears: FinancialYear[] = [];
  FinYear:any;
  Months: Company[] = [];
  Month: any;
  EmployeeSettings = {};
  DisableSave : boolean;
  DisableGenerate : boolean;
  AttendanceStatus: EmployeeAttendanceModel[] = [];
  Status:any;
  public modalRef: BsModalRef;
  MonAttMsg : boolean;
  EmployeeLeaveBalance: EmployeeLeaveBalanceModel[]=[];
  LeaveBalance: any;
  selectedItems : any;

  constructor(private EmployeePortalService: EmployeePortalService,private OrganizationService: OrganizationService,private EmployeeMasterService: EmployeeMasterService, private rout: Router, private userService: UserService,private _confirmation: ConfirmationService,private modalService: BsModalService)
  { }

  ngOnInit() 
  {
    // Begning Permission Portion 
    this.SelectedMenu=this.userService.SelectedMenu;
    if(this.SelectedMenu !=undefined)
    {
      localStorage.setItem('selectedmenu',JSON.stringify(this.SelectedMenu));
      this.rout.navigate([this.SelectedMenu.RouteName]);
    }
    else
    {
      if(JSON.parse(localStorage.getItem('selectedmenu'))!=undefined)
      {
        this.SelectedMenu = JSON.parse(localStorage.getItem('selectedmenu'));
        this.rout.navigate([this.SelectedMenu.RouteName]);
      }
    }
    // End of the permission portion
    this.NoRecordMsg=false;
    this.MonAttMsg=false;
    this.GetEmployeeDetailsDDL();
    this.GetYear();
    this.getMonths();
    this.EmployeeAttendanceModel.Year=0;
    this.EmployeeAttendanceModel.MonthId=0;
    this.DisableSave=true;
    this.DisableGenerate=true;
    this.EmployeeSettings =
    {
      singleSelection:true,
      text:'Select Employee',
      enableSearchFilter:true,
      showCheckbox:false
    }
  }

  settings: ConfirmSettings | any = 
  {
    overlay: true,
    overlayClickToClose: true,
    showCloseButton: true,
    confirmText: 'Yes',
    declineText: 'No'
  };

  GetEmployeeDetailsDDL() 
  {
    this.Employees=[];
    this.emp=[];
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.EmployeeMasterService.GetEmployeeDetailsForDDL( t).then(x => 
    {
      this.employee = x;
      Object.keys(this.employee).forEach( key => 
      {
        let RHtemp = new EmployeeDetail();
        RHtemp = this.employee[key];
        this.Employees.push(RHtemp);
        this.emp.push({"id":RHtemp.EmployeeId,"itemName":RHtemp.EmployeeCode + '-' +RHtemp.EmployeeName},);
      });     
    });      
  }

  onItemSelect(item:any)
  {
    let empid= item['id'];
    this.EmployeeAttendanceModel.EmployeeId=empid;
    this.DisableGenerate=true;
    this.EmployeeAttendance=[];
    this.NoRecordMsg= false;
    this.MonAttMsg=false;
    this.GetEmployeeLeaveBalanceDetails(this.EmployeeAttendanceModel.EmployeeId);
  }
  OnItemDeSelect(item:any){ }
  onSelectAll(items: any){ }
  onDeSelectAll(items: any){ }
 
  getMonths() 
  {
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.Months=[];
    this.OrganizationService.GetMonthName( t).then(x => 
    {
      this.Month = x;
      Object.keys(this.Month).forEach( key => 
      {
        // tslint:disable-next-line:no-unused-expression
        this.Month[key];
        let Monthtemp = new Company();
        Monthtemp = this.Month[key];
        this.Months.push(Monthtemp);
      });     
    });      
  }

  GetYear()
  {
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.FinancialYears=[];
    this.OrganizationService.GetYear( t).then(x => 
    {
      this.FinYear = x;
      Object.keys(this.FinYear).forEach( key => 
      {
        // tslint:disable-next-line:no-unused-expression
        this.FinYear[key];
        let FinYeartemp = new FinancialYear();
        FinYeartemp = this.FinYear[key];
        this.FinancialYears.push(FinYeartemp);
      });     
    });      
  }

  onchangeFinYear(Year)
  {
    this.NoRecordMsg= false;
    this.MonAttMsg=false;
    this.DisableGenerate=true;
    this.EmployeeAttendance=[];
    this.EmployeeAttendanceModel.Year=Year;
  }

  onchangeMonth(Monthid)
  {
    this.NoRecordMsg= false;
    this.MonAttMsg=false;
    this.DisableGenerate=true;
    this.EmployeeAttendance=[];
    this.EmployeeAttendanceModel.MonthId=Monthid
  }

  submitClick()
  {
    this.DisableGenerate=false;
    this.EmployeeAttendance=[];
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.EmployeePortalService.GETEmployeeAttendanceDateWiseDetails( t,this.EmployeeAttendanceModel.EmployeeId,this.EmployeeAttendanceModel.Year,this.EmployeeAttendanceModel.MonthId).then(x => 
    {
      this.GetAttendanceStatus();
      this.DisableSave=true;
      this.NoRecordMsg = false;
      this.MonAttMsg=false;
      this.EmployeeAttendance=[];
      this.Attendance = x;
      Object.keys(this.Attendance).forEach( key => 
      {
        this.Attendance[key];
        let Attendancetemp = new EmployeeAttendanceModel();
        Attendancetemp = this.Attendance[key];
        if(Attendancetemp.IsModified == true)
        {
          this.MonAttMsg=true;
        }
        this.EmployeeAttendance.push(Attendancetemp);
      });
      if(this.EmployeeAttendance.length == 0)
      {
        this.NoRecordMsg = true;
      }
    });
  }

  GetAttendanceStatus()
  {
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.AttendanceStatus=[];
    this.EmployeePortalService.GETAttendanceStatus( t).then(x => 
    {
      this.Status = x;
      Object.keys(this.Status).forEach( key => 
      {
        this.Status[key];
        let Statustemp = new EmployeeAttendanceModel();
        Statustemp = this.Status[key];
        this.AttendanceStatus.push(Statustemp);
      });     
    });      
  }

  UpdateEmployeeAttendance(template)
  {
    if(this.EmployeeAttendance.length > 0)
    {
      for(var item of this.EmployeeAttendance)
      {
        if(item.Status != item.ModifiedStatus && item.ModifiedStatus != null)
        {
          this.DisableSave=false;
          break;
        }
      }
    } 

    if(this.EmployeeAttendance.length > 0 && this.DisableSave == false)
    {
      this._confirmation.create('Confirm to Attendance Modification', 'Press Yes to Change', this.settings)
      .subscribe((ans: ResolveEmit) => 
      {
        if (ans.resolved == true) 
        {
          //this.DisableSave=false;
          let t =  localStorage.getItem('userToken');
          t = 'Bearer' + t;    
          var j=0;
          var l=this.EmployeeAttendance.length;
          this.EmployeeAttendance.forEach(item => 
          {
            j=j+1;
            if(item.Status != item.ModifiedStatus && item.ModifiedStatus != null)
            {      
              this.EmployeePortalService.UpdateEmployeeAttendanceDetails(t ,item).subscribe(res=>
              {
                this.RefId=res;        
              });                              
            }
            if(j == this.EmployeeAttendance.length)
            {
              this.DisableGenerate=true;
              this.modalRef = this.modalService.show(template);
              this.EmployeeAttendance=[];
              this.GetEmployeeLeaveBalanceDetails(this.EmployeeAttendanceModel.EmployeeId);
            } 
          });
        }
        else
        {
          this.DisableSave=true;
        }
      })
    }
  }

  GetEmployeeLeaveBalanceDetails(EmployeeId)
  {
    this.EmployeeLeaveBalance=[];
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.EmployeePortalService.GetEmployeeLeaveBalanceByEmployeeId( t,EmployeeId).then(x => 
    {
      this.EmployeeLeaveBalance=[];
      this.LeaveBalance = x;
      Object.keys(this.LeaveBalance).forEach( key => 
      {
        this.LeaveBalance[key];
        let LeaveBalancetemp = new EmployeeLeaveBalanceModel();
        LeaveBalancetemp = this.LeaveBalance[key];
        this.EmployeeLeaveBalance.push(LeaveBalancetemp);
      });
    });
  }
}