import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs/Subject';
import { DataTableDirective } from 'angular-datatables';
import { UserService } from '../../shared/user.service';
import { OrganizationService}  from '../../shared/Organization/organization.service';
import { EmployeeMasterService}  from '../../shared/HR_ADMIN/Employee/EmployeeMaster.Service';
import { EmployeeBank } from '../../shared/HR_ADMIN/Employee/EmployeeBank.Model';
import { BankMaster } from '../../shared/Organization/BankMaster.Model';
// Begning Confirmation
import { ConfirmationService } from '@jaspero/ng2-confirmations';
import { ResolveEmit } from '../../../app/resolve-emit';
import { ConfirmSettings } from '../../../app/confirm-settings';
// end of Confirmation
import { Menu } from '../../shared/menu.model';


@Component({
  selector: 'app-employee-bank-details',
  templateUrl: './employee-bank-details.component.html',
  styleUrls: ['./employee-bank-details.component.css']
})
export class EmployeeBankDetailsComponent implements OnInit 
{
  EmpBankModel : EmployeeBank= new EmployeeBank();
  userClaims: any;
  EmployeeBanks: EmployeeBank[] = [];
  BankMasters : BankMaster[]=[];
  Employees: EmployeeBank[] = [];
  emp:any;
  EmpBank : any;
  bank : any;
  Result : string;
  SuccessStatus:boolean=false;
  SuccessMessage: string ="";
  public tableWidget: any;
  RefId : any;

  tempEmployeeBankadd : EmployeeBank =new EmployeeBank();

  // Begning Permission Portion    
  SelectedMenu:Menu;
  IsDataIsInViewState : boolean=false;
  CanViewState : boolean=true;
  IsFormView : boolean=false;
  IsEmployeeViewTEXT : boolean=true;
  IsEmployeeViewDDL : boolean=false;
  DisableSave : boolean=false;
  // End of the permission portion

  constructor(private EmployeeMasterService: EmployeeMasterService,private organizationService: OrganizationService, private rout: Router,
    private _confirmation: ConfirmationService,  private userService: UserService)  { }

  @ViewChild(DataTableDirective, {static: false})
  setDtOptions: DataTables.Settings = {};
  cardDtOptions: DataTables.Settings = {};
  dtElement: DataTableDirective;
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();

  ngOnInit() 
  {
    // Begning Permission Portion 
    this.SelectedMenu=this.userService.SelectedMenu;
    if(this.SelectedMenu !=undefined)
    {
      localStorage.setItem('selectedmenu',JSON.stringify(this.SelectedMenu));
      this.rout.navigate([this.SelectedMenu.RouteName]);
    }
    else
    {
      if(JSON.parse(localStorage.getItem('selectedmenu'))!=undefined)
      {
        this.SelectedMenu = JSON.parse(localStorage.getItem('selectedmenu'));
        this.rout.navigate([this.SelectedMenu.RouteName]);
      }
    }
    if(this.SelectedMenu.CanAdd)
    {
      this.IsDataIsInViewState=false;
    }
    else
    {
      this.IsDataIsInViewState=true;
    }
    if(this.SelectedMenu.CanView)
    {
    this.CanViewState=false;
    }
    else
    {
      this.CanViewState=true;
    }
    this.IsFormView=false;
    // End of the permission portion
    this.getBankMasters();
    this.getEmployeeBankDetails();
    //this.getEmployeeMasters();
    this.DisableSave=true;
    this.dtOptions = 
    {
      pagingType: 'full_numbers',
      pageLength: 3
    };
    this.EmpBankModel.BankId=0;
    this.EmpBankModel.EmployeeId=0;
    if(localStorage.getItem('SuccessfullMessage'))
    {
      this.SuccessStatus=true;
      this.SuccessMessage =localStorage.getItem('SuccessfullMessage')
      localStorage.removeItem('SuccessfullMessage');
    }
  }

  settings: ConfirmSettings | any = 
  {
    overlay: true,
    overlayClickToClose: true,
    showCloseButton: true,
    confirmText: 'Yes',
    declineText: 'No'
  };

  ngAfterViewInit(): void 
  {
    
    this.dtOptions = 
    {
      pagingType: 'full_numbers',
      pageLength: 3
    };
  }

  getEmployeeBankDetails()
  {
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.EmployeeMasterService.GetEmployeeBankDetails( t).then(x => 
    {
      this.EmpBank = x;
      Object.keys(this.EmpBank).forEach( key => 
      {
        // tslint:disable-next-line:no-unused-expression
        this.EmpBank[key];
        let EmpBanktemp = new EmployeeBank();
        EmpBanktemp = this.EmpBank[key];
        this.EmployeeBanks.push(EmpBanktemp);
      });
      this.dtTrigger.next();
    });
  }

  GetEmployeeBankDetailsByid(id) 
  {
     // begning of the permission portion
    this.IsDataIsInViewState=false;
    this.IsEmployeeViewDDL=true;
    this.IsEmployeeViewTEXT=false;
    this.IsFormView=true;
     // end of the permission portion
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.EmployeeMasterService.GetEmployeeBankDetailsByid( t,id).then(x => 
    {
      this.EmpBank = x;
      Object.keys(this.EmpBank).forEach( key => 
      {
        // tslint:disable-next-line:no-unused-expression
        this.EmpBank[key];
        let EmpBanktemp = new EmployeeBank();
        EmpBanktemp = this.EmpBank[key];
        this.EmpBankModel.RefId=EmpBanktemp.RefId;
        this.EmpBankModel.EmployeeId=EmpBanktemp.EmployeeId;
        this.EmpBankModel.EmployeeCode=EmpBanktemp.EmployeeCode;
        this.EmpBankModel.EmployeeName=EmpBanktemp.EmployeeName;
        this.EmpBankModel.BankId=EmpBanktemp.BankId;
        this.EmpBankModel.OldBankId=EmpBanktemp.BankId;
        this.EmpBankModel.BranchName=EmpBanktemp.BranchName;
        this.EmpBankModel.AccountNumber=EmpBanktemp.AccountNumber;          
        this.EmpBankModel.IFSCCode=EmpBanktemp.IFSCCode;
        this.EmpBankModel.IsActive=EmpBanktemp.IsActive;
      });
    });
  }

  viewEmployeeBankDetailsByid(id) 
  {
    this.IsDataIsInViewState=true;
    this.IsEmployeeViewDDL=true;
    this.IsEmployeeViewTEXT=false;
    this.IsFormView=true;
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.EmployeeMasterService.GetEmployeeBankDetailsByid( t,id).then(x => 
      {
        this.EmpBank = x;
        Object.keys(this.EmpBank).forEach( key => 
        {
          // tslint:disable-next-line:no-unused-expression
          this.EmpBank[key];
          let EmpBanktemp = new EmployeeBank();
          EmpBanktemp = this.EmpBank[key];
          this.EmpBankModel.RefId=EmpBanktemp.RefId;
          this.EmpBankModel.EmployeeId=EmpBanktemp.EmployeeId;
          this.EmpBankModel.EmployeeCode=EmpBanktemp.EmployeeCode;
          this.EmpBankModel.EmployeeName=EmpBanktemp.EmployeeName;
          this.EmpBankModel.BankId=EmpBanktemp.BankId;
          this.EmpBankModel.OldBankId=EmpBanktemp.BankId;
          this.EmpBankModel.BranchName=EmpBanktemp.BranchName;
          this.EmpBankModel.AccountNumber=EmpBanktemp.AccountNumber;          
          this.EmpBankModel.IFSCCode=EmpBanktemp.IFSCCode;
          this.EmpBankModel.IsActive=EmpBanktemp.IsActive;
        });
    });
  }

  getBankMasters() 
  {
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.organizationService.GetBankMaster( t).then(x => 
    {
      this.bank = x;
      Object.keys(this.bank).forEach( key => 
      {
        // tslint:disable-next-line:no-unused-expression
        this.bank[key];
        let banktemp = new BankMaster();
        banktemp = this.bank[key];
        this.BankMasters.push(banktemp);
      });     
    });      
  }
  
  getEmployeeMasters() 
  {
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.EmployeeMasterService.GetNewEmployeeBankDetailsForDDL( t).then(x => 
    {
      this.emp = x;
      Object.keys(this.emp).forEach( key => 
      {
        // tslint:disable-next-line:no-unused-expression
        this.emp[key];
        let emptemp = new EmployeeBank();
        emptemp = this.emp[key];
        this.Employees.push(emptemp);
      });     
    });      
  }

  saveEmployeeBankDetails() :void
  {
    this.DisableSave=false;
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.EmpBankModel.IsActive=true;
    if(this.EmpBankModel.RefId > 0)
    {
      this.EmployeeMasterService.UpdateEmployeeBankDetails(t ,this.EmpBankModel).subscribe(res=>
      {
        this.RefId=res;
        this.EmpBankModel.RefId=0;  
        if(this.RefId>0)
        {
          localStorage.setItem('SuccessfullMessage', "Update Successfully");
          this.rout.navigateByUrl('/Employee/EmployeeOffer', {skipLocationChange: true}).then(()=>this.rout.navigate(["/Employee/EmployeeBankDetails"]));
        }
      });                  
    }
    else
    {
      this.EmployeeMasterService.AddEmployeeBankDetails(t ,this.EmpBankModel).subscribe(res=>
      {
        this.RefId=res;
        this.EmpBankModel.RefId=0;              
        if(this.RefId>0)
        {
          localStorage.setItem('SuccessfullMessage', "Save Successfully");
          this.rout.navigateByUrl('/Employee/EmployeeOffer', {skipLocationChange: true}).then(()=>this.rout.navigate(["/Employee/EmployeeBankDetails"]));
        }
      }); 
    }
  }

  DeleteEmployeeBankDetailsByid(Refid : number) :void
  {    
    this._confirmation.create('Confirm to Deleate', 'Press Yes to Delete', this.settings)
    .subscribe((ans: ResolveEmit) => 
    {
      if (ans.resolved == true) 
      {
        let t =  localStorage.getItem('userToken');
        t = 'Bearer' + t;
        this.EmpBankModel.IsActive=true;
        if( Refid > 0)
        {
          this.EmployeeMasterService.DeleteEmployeeBankDetails(t ,Refid).subscribe(res=>
          {
            this.RefId=res;
            this.EmpBankModel.RefId=0;                                  
            if(this.RefId >0)
            {
              localStorage.setItem('SuccessfullMessage', "Deleted Successfully");
              this.rout.navigateByUrl('/Employee/EmployeeOffer', {skipLocationChange: true}).then(()=>this.rout.navigate(["/Employee/EmployeeBankDetails"]));
            }
          });                  
        }
      } 
    })
  }

  onChangeBank(BankId)
  {
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.organizationService.GetBankMasterByid( t,BankId).then(x => 
    {
      this.bank = x;
      Object.keys(this.bank).forEach( key => 
      {
        // tslint:disable-next-line:no-unused-expression
        this.bank[key];
        let banktemp = new BankMaster();
        banktemp = this.bank[key];
        this.EmpBankModel.BranchName=banktemp.BranchName;
        this.EmpBankModel.IFSCCode=banktemp.IFSCCode;
      });
    });
  }

  RefreshPage() 
  {
    this.rout.navigateByUrl('/Employee/EmployeeOffer', {skipLocationChange: true}).then(()=>this.rout.navigate(["/Employee/EmployeeBankDetails"]));
  }
}
