import { Component, OnInit } from '@angular/core';
import { UserService } from '../../shared/user.service';
import { HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { User } from '../../shared/user.model';
import { UserMasterService } from '../../shared/HR_ADMIN/UserMaster/UserMasterService.service';
import { ModalOptions,BsModalRef, BsModalService } from 'ngx-bootstrap/modal';

@Component
({
  selector: 'app-sign-in',
  templateUrl: './sign-in.component.html',
  styleUrls: ['./sign-in.component.css']
})

export class SignInComponent implements OnInit 
{
  isLoginError = false;
  user: User;

  //For Pop up
  ShowPopup : boolean= false;
  MsgSent : boolean= false;
  USerNotExists : boolean = false;
  public modalRef: BsModalRef;
  public modelopt:ModalOptions;
  RefId: any;
  DisableSave :boolean=false;

  constructor(private userService: UserService, private router: Router,private modalService: BsModalService,private UserMasterService: UserMasterService ) 
  {
    this.user = new User(); 
    this.modelopt=new ModalOptions();
    this.modelopt.class='heavy_modal';
    this.modalService.config= this.modelopt;
  }

  ngOnInit() 
  {
    const userid: string = localStorage.getItem('usreid');
    const password: string = localStorage.getItem('password');
    if (userid !== undefined && userid !== null && userid && password !== undefined && password !== null && password )
    {
      this.user.UserId = userid;
      this.user.Password = password;
    }
  }

  OnSubmit() 
  {
    if (this.user.Rememberme) 
    {
      this.rememberMe();
    }
    this.userService.userAuthentication(this.user.UserId, this.user.Password).subscribe((data: any) =>
    {
      localStorage.setItem('userToken', data.access_token);
      this.router.navigate(['/DashBoard']);
    },
    (err: HttpErrorResponse) => 
    {
      this.isLoginError = true;
    });
  }

  rememberMe(): void 
  {
    localStorage.setItem('usreid', this.user.UserId);
    localStorage.setItem('password', this.user.Password);
  }

  ViewPasswordReset(template) 
  {
    this.DisableSave=true;
    this.modalRef = this.modalService.show(template,this.modelopt);
    this.ShowPopup=true;
    this.MsgSent=false;
    this.USerNotExists=false;
  }

  PasswordResetMailFarward(template)
  {
    this.DisableSave=false;
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.userService.PasswordResetMailFarward(this.user.UserId).subscribe((data: any) =>
    {
      this.modalRef.hide();
      this.ShowPopup=false;
      this.MsgSent=true;
      this.USerNotExists=false;
      this.modalRef = this.modalService.show(template);
    },
    (err: HttpErrorResponse) => 
    {
      this.modalRef.hide();
        this.ShowPopup=false;
        this.MsgSent=false;
        this.USerNotExists=true;
        this.modalRef = this.modalService.show(template);
    });
  }
}