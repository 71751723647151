import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { CrmLeadService } from '../../../shared/CRM/MICECRM/LEADCRM/CrmLeadService';
import { Router } from '@angular/router';
import { Model_CrmCountry } from '../../../shared/CRM/MICECRM/Model_CrmCountry';
import { Model_CRM_State } from '../../../shared/CRM/MICECRM/Model_CRM_State';
import { Model_CRM_City } from '../../../shared/CRM/MICECRM/Model_CRM_City';
import { Model_Crm_List } from '../../../shared/CRM/MICECRM/LEADCRM/Model_Crm_List';
import { ConfirmSettings } from '@jaspero/ng2-confirmations/src/interfaces/confirm-settings';
import { Model_CRM_Assigne } from '../../../shared/CRM/MICECRM/Model_CRM_Assigne';
import { ValidationClass } from '../../../shared/Validate/ValidationForAll'
import { Model_CRM_Designation } from '../../../shared/CRM/MICECRM/Model_CRM_Designation';

@Component({
  selector: 'app-mice-crm-basic-lead-info',
  templateUrl: './mice-crm-basic-lead-info.component.html',
  styleUrls: ['./mice-crm-basic-lead-info.component.css']
})
export class MiceCrmBasicLeadInfoComponent implements OnInit {




  updatedLeadId: any;



  // For Searchable Dropdown
  CountrySettings = {}
  CountryArray: any[] = [];
  CountryName: any;
  countrylist: Model_CrmCountry[] = [];
  SelectedCountry: any[] = [];



  StateSettings = {};
  StateArray: any[] = [];
  SelectedState: any[] = []
  statelist: Model_CRM_State[] = [];
  StateName: any;



  CitySettings = {};
  CityName: any;
  CityArray: any[] = [];
  citylist: Model_CRM_City[] = [];
  SelectedCity: any[] = []



  ListList: Model_Crm_List[] = [];
  Listobj: any;
  ListArray: any[] = [];
  ListSettings = {};
  SelectedListForList: any[] = [];


  AssigneList: Model_CRM_Assigne[] = [];
  Assigneobj: any;
  AssigneArray: any[] = [];
  AssigneSettings = {};
  SelectAssigneList: any[] = [];

  EmployeeIdx: number = 0;
  IsDuplicateLead: boolean;



  SuccessMessage: string = "";
  SuccessStatus: boolean = false;
  ObjectValidate: ValidationClass;

  //Begin For validation

  Emailvalidation: boolean = true;
  Phonevalidation: boolean = true;
  DuplicateLeadValidation: boolean = true;

  ValidationComplete: boolean = false;

  LeadNameValidation: boolean = false;

  FirstNameValidation: boolean = false;

  LastNameValidation: boolean = false;

  ListValidation: boolean = false;



  //End for validation


  DesignationList: Model_CRM_Designation[] = [];
  Designationobj: any;
  DesignationArray: any[] = [];
  DesignationSettings = {};
  SelectedDesignation: any[] = [];


  CRM_Obj_Designation: any;

  constructor(private rout: Router, private CRM_LeadService: CrmLeadService) {
    this.ObjectValidate = new ValidationClass();
  }
  @Output() searchItem: EventEmitter<any> = new EventEmitter();
  OutputDate: string = "";
  ngOnInit() {



    // if (this.CRM_LeadService.model_CRM_Shared_lead.LeadId > 0) {
    //   this.ValidationComplete = true;

    // }


    this.EmployeeIdx = 0;
    if (this.CRM_LeadService.model_CRM_Shared_lead.AssigneTo == undefined) {
      this.CRM_LeadService.model_CRM_Shared_lead.AssigneTo = 0;

    }

    if (localStorage.getItem('SuccessfullMessage')) {
      this.SuccessStatus = true;
      this.SuccessMessage = localStorage.getItem('SuccessfullMessage')
      localStorage.removeItem('SuccessfullMessage');
    }


    if (this.CRM_LeadService.model_CRM_Shared_lead.Mr == undefined) {
      this.CRM_LeadService.model_CRM_Shared_lead.Mr = "0";
    }

    this.CountrySettings = {
      singleSelection: true,
      text: 'Select Country',
      enableSearchFilter: true,
      showCheckbox: false,
      position: 'top'
    }

    this.StateSettings = {
      singleSelection: true,
      text: 'Select State',
      enableSearchFilter: true,
      showCheckbox: false,
      position: 'top'
    }

    this.CitySettings = {

      singleSelection: true,
      text: 'Select City',
      enableSearchFilter: true,
      showCheckbox: false,
      classes: '',
      position: 'top'

    }

    this.ListSettings = {
      singleSelection: true,
      text: 'Select List',
      enableSearchFilter: true,
      showCheckbox: false,
      position: 'top'

    }


    this.AssigneSettings = {
      singleSelection: true,
      text: 'Select List',
      enableSearchFilter: true,
      showCheckbox: false,
      position: 'top'

    }


    this.GetCountry();

    // if(this.CRM_LeadService.model_CRM_Shared_lead.CountryId >0)
    // {
    //   this.GetState(this.CRM_LeadService.model_CRM_Shared_lead.CountryId);
    // }

    // if(this.CRM_LeadService.model_CRM_Shared_lead.StateId >0)
    // {
    //   this.GetCity(this.CRM_LeadService.model_CRM_Shared_lead.StateId);
    // }
    this.GetListName();

    // this.GetState();
    // this.GetCity();

    this.GetCrmAssignetoList();





    if (this.CRM_LeadService.model_CRM_Shared_lead.ListId > 0) {
      this.ListValidation = true;

    }


    if (this.CRM_LeadService.model_CRM_Shared_lead.LeadName == undefined) {
      this.LeadNameValidation = false;
    }
    else if (this.CRM_LeadService.model_CRM_Shared_lead.LeadName == "") {
      this.LeadNameValidation = false;

    }
    else {
      this.LeadNameValidation = true;

    }


    //this.ValidationAll();

    if (this.CRM_LeadService.model_CRM_Shared_lead.LeadId > 0) {
      this.ValidationComplete = true;

    }

    this.DesignationSettings = {
      singleSelection: true,
      text: 'Select Designation',
      enableSearchFilter: true,
      showCheckbox: false,
      position: 'top'
    }

    this.GetDesignation();
  }


  settings: ConfirmSettings | any =
    {
      overlay: true,
      overlayClickToClose: true,
      showCloseButton: true,
      confirmText: 'Yes',
      declineText: 'No'
    };



  LeadAddressChange(ev) {
    try {



      this.CRM_LeadService.model_CRM_Shared_lead.Address = ev.target.value;

    } catch (e) {
      console.info('could not set textarea-value');
    }
  }

  GetDesignation() {

    let t = localStorage.getItem('userToken');
    t = 'Bearer' + t
    this.DesignationList = [];
    this.DesignationArray = [];
    this.CRM_LeadService.GetCRM_designation(t).then(x => {
      this.CRM_Obj_Designation = x;
      Object.keys(this.CRM_Obj_Designation).forEach(key => {
        let designationtemp = new Model_CRM_Designation();
        designationtemp = this.CRM_Obj_Designation[key];

        this.DesignationList.push(designationtemp);
        this.DesignationArray.push({ "id": designationtemp.DesigID, "itemName": designationtemp.Designation });
      });

    }).then(y => {


      if (this.CRM_LeadService.model_CRM_Shared_lead.DesignationID > 0) {
        let Designation = this.DesignationList.find(x => x.DesigID == this.CRM_LeadService.model_CRM_Shared_lead.DesignationID);
        this.SelectedDesignation = [];

        this.SelectedDesignation.push({ "id": Designation.DesigID, "itemName": Designation.Designation });


      }


    });
  }




  GetCountry() {

    let t = localStorage.getItem('userToken');
    t = 'Bearer' + t
    this.countrylist = [];
    this.CountryArray = [];
    this.CRM_LeadService.GetCountryType(t).then(x => {
      this.CountryName = x;
      Object.keys(this.CountryName).forEach(key => {
        let CountryNametemp = new Model_CrmCountry();
        CountryNametemp = this.CountryName[key];

        this.countrylist.push(CountryNametemp);
        this.CountryArray.push({ "id": CountryNametemp.CountryId, "itemName": CountryNametemp.CountryName });
      });

    }).then(y => {


      if (this.CRM_LeadService.model_CRM_Shared_lead.CountryId > 0) {
        let Country = this.countrylist.find(x => x.CountryId == this.CRM_LeadService.model_CRM_Shared_lead.CountryId);
        this.SelectedCountry = [];

        this.SelectedCountry.push({ "id": Country.CountryId, "itemName": Country.CountryName });
        this.GetState(this.CRM_LeadService.model_CRM_Shared_lead.CountryId);

      }
      else {

        this.CRM_LeadService.model_CRM_Shared_lead.CountryId = 1;

        let Country = this.countrylist.find(x => x.CountryId == this.CRM_LeadService.model_CRM_Shared_lead.CountryId);
        this.SelectedCountry = [];
        this.SelectedCountry.push({ "id": Country.CountryId, "itemName": Country.CountryName });
        this.GetState(this.CRM_LeadService.model_CRM_Shared_lead.CountryId);



      }


    });
  }

  //Populate  Country Drop  Down  ENDS


  //Populate  State  Drop  Down  

  GetState(Countryid: number) {
    let t = localStorage.getItem('userToken');
    t = 'Bearer' + t
    this.statelist = [];
    this.StateArray = [];
    this.CRM_LeadService.GetStateType(t, Countryid).then(x => {
      this.StateName = x;
      Object.keys(this.StateName).forEach(key => {
        let StateNametemp = new Model_CRM_State();
        StateNametemp = this.StateName[key];
        this.statelist.push(StateNametemp);
        this.StateArray.push({ "id": StateNametemp.StateId, "itemName": StateNametemp.StateName });

      });

    }).then(y => {

      if (this.CRM_LeadService.model_CRM_Shared_lead.StateId > 0) {
        let State = this.statelist.find(x => x.StateId == this.CRM_LeadService.model_CRM_Shared_lead.StateId);
        this.SelectedState = [];

        this.SelectedState.push({ "id": State.StateId, "itemName": State.StateName });
        this.GetCity(this.CRM_LeadService.model_CRM_Shared_lead.StateId);

      }


    });
  }

  //Populate State Drop  Down  ENDS

  //Populate  City  Drop  Down  

  GetCity(StateId: number) {

    let t = localStorage.getItem('userToken');
    t = 'Bearer' + t
    this.citylist = [];
    this.CRM_LeadService.GetCityType(t, StateId).then(x => {
      this.CityName = x;
      Object.keys(this.CityName).forEach(key => {
        let CityNametemp = new Model_CRM_City();
        CityNametemp = this.CityName[key];
        this.citylist.push(CityNametemp);
        this.CityArray.push({ "id": CityNametemp.CityId, "itemName": CityNametemp.CityName });
      });

    }).then(y => {

      if (this.CRM_LeadService.model_CRM_Shared_lead.CityId > 0) {
        let City = this.citylist.find(x => x.CityId == this.CRM_LeadService.model_CRM_Shared_lead.CityId);
        this.SelectedCity = [];

        this.SelectedCity.push({ "id": City.CityId, "itemName": City.CityName });


      }


    });
  }


  get Extension() {
    return this.CRM_LeadService.model_CRM_Shared_lead.Extension
  }


  get LastName(): string {
    if (this.CRM_LeadService.model_CRM_Shared_lead.LastName == undefined || this.CRM_LeadService.model_CRM_Shared_lead.LastName == null) {

      this.CRM_LeadService.model_CRM_Shared_lead.LastName = "";
      this.LastNameValidation = false;
      this.ValidationAll()
      return this.CRM_LeadService.model_CRM_Shared_lead.LastName;
    }
    else {
      this.LastNameValidation = true;
      this.ValidationAll()
      return this.CRM_LeadService.model_CRM_Shared_lead.LastName;
    }
  }

  @Input('LastName')
  set LastName(value: string) {

    this.CRM_LeadService.model_CRM_Shared_lead.LastName = value;

    if (this.CRM_LeadService.model_CRM_Shared_lead.LastName == undefined || this.CRM_LeadService.model_CRM_Shared_lead.LastName == null) {

      this.CRM_LeadService.model_CRM_Shared_lead.LastName = "";
      this.LastNameValidation = false;
      this.ValidationAll()
    }

    else {
      this.LastNameValidation = true;
      this.ValidationAll()


    }
  }

  //this.CRM_LeadService.model_CRM_Shared_lead.LeadName

  get LeadNameM(): string {
    if (this.CRM_LeadService.model_CRM_Shared_lead.LeadName == undefined || this.CRM_LeadService.model_CRM_Shared_lead.LeadName == null) {

      this.CRM_LeadService.model_CRM_Shared_lead.LeadName = "";

      this.LeadNameValidation = false;
      this.ValidationAll();
      return this.CRM_LeadService.model_CRM_Shared_lead.LeadName;
    }
    else {
      this.LeadNameValidation = true;
      this.ValidationAll();
      return this.CRM_LeadService.model_CRM_Shared_lead.LeadName;
    }
  }

  @Input('LeadNameM')
  set LeadNameM(value: string) {
    this.CRM_LeadService.model_CRM_Shared_lead.LeadName = value;

    if (this.CRM_LeadService.model_CRM_Shared_lead.LeadName == undefined || this.CRM_LeadService.model_CRM_Shared_lead.LeadName == null) {

      this.CRM_LeadService.model_CRM_Shared_lead.LeadName = "";
      this.LeadNameValidation = false;
      this.ValidationAll()
    }

    else {
      this.LeadNameValidation = true;
      this.ValidationAll()


    }

  }


  get MiddleNameM(): string {
    if (this.CRM_LeadService.model_CRM_Shared_lead.MiddleName == undefined || this.CRM_LeadService.model_CRM_Shared_lead.MiddleName == null) {

      this.CRM_LeadService.model_CRM_Shared_lead.MiddleName = "";
      return this.CRM_LeadService.model_CRM_Shared_lead.MiddleName;
    }
    else {
      return this.CRM_LeadService.model_CRM_Shared_lead.MiddleName;
    }
  }

  @Input('MiddleNameM')
  set MiddleNameM(value: string) {
    this.CRM_LeadService.model_CRM_Shared_lead.MiddleName = value;
  }

  get PhoneNoM(): string {
    if (this.CRM_LeadService.model_CRM_Shared_lead.PhoneNo == undefined || this.CRM_LeadService.model_CRM_Shared_lead.PhoneNo == null) {

      this.CRM_LeadService.model_CRM_Shared_lead.PhoneNo = "";

      this.ValidatephoneNo()
      return this.CRM_LeadService.model_CRM_Shared_lead.PhoneNo;
    }
    else {
      this.ValidatephoneNo();
      return this.CRM_LeadService.model_CRM_Shared_lead.PhoneNo;
    }
  }

  @Input('PhoneNoM')
  set PhoneNoM(value: string) {
    this.CRM_LeadService.model_CRM_Shared_lead.PhoneNo = value;

    this.ValidatephoneNo();

  }

  @Input('AssigneToM')
  set AssigneToM(value: number) {
    this.CRM_LeadService.model_CRM_Shared_lead.AssigneTo = value;
  }


  get AssigneToM(): number {
    if (this.CRM_LeadService.model_CRM_Shared_lead.AssigneTo == undefined || this.CRM_LeadService.model_CRM_Shared_lead.AssigneTo == 0) {

      this.CRM_LeadService.model_CRM_Shared_lead.AssigneTo = 0;
      return this.CRM_LeadService.model_CRM_Shared_lead.AssigneTo;
    }
    else {
      return this.CRM_LeadService.model_CRM_Shared_lead.AssigneTo;
    }
  }





  //this.CRM_LeadService.model_CRM_Shared_lead.PhoneNo


  get FirstName(): string {


    if (this.CRM_LeadService.model_CRM_Shared_lead.FirstName == undefined || this.CRM_LeadService.model_CRM_Shared_lead.FirstName == null) {

      this.CRM_LeadService.model_CRM_Shared_lead.FirstName = "";
      this.FirstNameValidation = false;
      this.ValidationAll()

      return this.CRM_LeadService.model_CRM_Shared_lead.FirstName;
    }
    else {
      this.FirstNameValidation = true;
      this.ValidationAll()
      return this.CRM_LeadService.model_CRM_Shared_lead.FirstName;
    }
  }

  @Input('FirstName')
  set FirstName(value: string) {
    this.CRM_LeadService.model_CRM_Shared_lead.FirstName = value;

    if (this.CRM_LeadService.model_CRM_Shared_lead.FirstName == undefined || this.CRM_LeadService.model_CRM_Shared_lead.FirstName == null) {

      this.CRM_LeadService.model_CRM_Shared_lead.FirstName = "";
      this.FirstNameValidation = false;
      this.ValidationAll()
    }

    else {
      this.FirstNameValidation = true;
      this.ValidationAll()


    }

  }


  get EmailIdM(): string {


    if (this.CRM_LeadService.model_CRM_Shared_lead.EmailId == undefined || this.CRM_LeadService.model_CRM_Shared_lead.EmailId == null) {

      this.CRM_LeadService.model_CRM_Shared_lead.EmailId = "";
      this.ValidateEmail();
      return this.CRM_LeadService.model_CRM_Shared_lead.EmailId;
    }
    else {
      return this.CRM_LeadService.model_CRM_Shared_lead.EmailId;
    }
  }

  @Input('EmailIdM')
  set EmailIdM(value: string) {
    this.CRM_LeadService.model_CRM_Shared_lead.EmailId = value;
    this.ValidateEmail();

  }


  get AddressM(): string {


    if (this.CRM_LeadService.model_CRM_Shared_lead.Address == undefined || this.CRM_LeadService.model_CRM_Shared_lead.Address == null) {

      this.CRM_LeadService.model_CRM_Shared_lead.Address = "";
      return this.CRM_LeadService.model_CRM_Shared_lead.Address;
    }
    else {
      return this.CRM_LeadService.model_CRM_Shared_lead.Address;
    }
  }

  @Input('AddressM')
  set AddressM(value: string) {
    this.CRM_LeadService.model_CRM_Shared_lead.Address = value;

  }


  // this.CRM_LeadService.model_CRM_Shared_lead.EmailId


  get Mr(): string {
    if (this.CRM_LeadService.model_CRM_Shared_lead.Mr == undefined || this.CRM_LeadService.model_CRM_Shared_lead.Mr == null) {

      this.CRM_LeadService.model_CRM_Shared_lead.Mr = "";
      return this.CRM_LeadService.model_CRM_Shared_lead.Mr;
    }
    else {
      return this.CRM_LeadService.model_CRM_Shared_lead.Mr;
    }
  }

  @Input('Mr')
  set Mr(value: string) {
    this.CRM_LeadService.model_CRM_Shared_lead.Mr = value;

  }


  //this.CRM_LeadService.model_CRM_Shared_lead.StateId


  get StateIdM(): number {
    if (this.CRM_LeadService.model_CRM_Shared_lead.StateId == undefined || this.CRM_LeadService.model_CRM_Shared_lead.StateId == 0) {

      this.CRM_LeadService.model_CRM_Shared_lead.StateId = 0;
      return this.CRM_LeadService.model_CRM_Shared_lead.StateId;
    }
    else {
      return this.CRM_LeadService.model_CRM_Shared_lead.StateId;
    }
  }

  @Input('StateIdM')
  set StateIdM(value: number) {
    this.CRM_LeadService.model_CRM_Shared_lead.StateId = value;

  }

  //this.CRM_LeadService.model_CRM_Shared_lead.Extension


  get ExtensionM(): string {
    if (this.CRM_LeadService.model_CRM_Shared_lead.Extension == undefined || this.CRM_LeadService.model_CRM_Shared_lead.Extension == '') {

      this.CRM_LeadService.model_CRM_Shared_lead.Extension = '';
      return this.CRM_LeadService.model_CRM_Shared_lead.Extension;
    }
    else {
      return this.CRM_LeadService.model_CRM_Shared_lead.Extension;
    }
  }

  @Input('ExtensionM')
  set ExtensionM(value: string) {
    this.CRM_LeadService.model_CRM_Shared_lead.Extension = value;

  }

  // this.CRM_LeadService.model_CRM_Shared_lead.ListId


  get ListIdM(): number {
    if (this.CRM_LeadService.model_CRM_Shared_lead.ListId == undefined || this.CRM_LeadService.model_CRM_Shared_lead.ListId == 0) {

      this.CRM_LeadService.model_CRM_Shared_lead.ListId = 0;
      return this.CRM_LeadService.model_CRM_Shared_lead.ListId;
    }
    else {
      return this.CRM_LeadService.model_CRM_Shared_lead.ListId;
    }
  }

  @Input('ListIdM')
  set ListIdM(value: number) {
    this.CRM_LeadService.model_CRM_Shared_lead.ListId = value;

  }

  //this.CRM_LeadService.IsinViewState

  get IsinViewStateM(): boolean {
    if (this.CRM_LeadService.IsinViewState == undefined) {

      this.CRM_LeadService.IsinViewState = true;
      return this.CRM_LeadService.IsinViewState;
    }
    else {
      return this.CRM_LeadService.IsinViewState;
    }
  }

  @Input('IsinViewStateM')
  set IsinViewStateM(value: boolean) {
    this.CRM_LeadService.IsinViewState = value;

  }


  onItemSelectCountry(item: any) {

    let CountryId = item['id'];
    this.CRM_LeadService.model_CRM_Shared_lead.CountryId = CountryId;
    this.CRM_LeadService.model_CRM_Shared_lead.StateId = 0;
    this.CRM_LeadService.model_CRM_Shared_lead.CityId = 0;


    //  this.CRM_City_Model.ZoneId=ZoneId;    
    this.StateArray = [];
    this.statelist = [];

    this.CityArray = [];
    this.citylist = [];

    this.SelectedState = [];
    this.SelectedState.push({ "id": 0, "itemName": "Select State" });

    this.SelectedCity = [];
    this.SelectedCity.push({ "id": 0, "itemName": "Select City" });


    if (this.CRM_LeadService.model_CRM_Shared_lead.CountryId > 0) {
      this.GetState(this.CRM_LeadService.model_CRM_Shared_lead.CountryId);
    }

  }
  OnItemDeSelectCountry(item: any) { }
  onSelectAllCountry(items: any) { }
  onDeSelectAllCountry(items: any) { }


  onItemSelecState(item: any) {

    let StateId = item['id'];
    this.CRM_LeadService.model_CRM_Shared_lead.StateId = StateId;
    this.CRM_LeadService.model_CRM_Shared_lead.CityId = 0;
    //  this.CRM_City_Model.ZoneId=ZoneId;    
    this.CityArray = [];
    this.citylist = [];

    this.SelectedCity = [];
    this.SelectedCity.push({ "id": 0, "itemName": "Select City" });
    if (this.CRM_LeadService.model_CRM_Shared_lead.StateId > 0) {
      this.GetCity(this.CRM_LeadService.model_CRM_Shared_lead.StateId);
    }

  }
  OnItemDeSelectState(item: any) { }
  onSelectAllState(items: any) { }
  onDeSelectAllState(items: any) { }



  onItemSelectCity(item: any) {

    let CityId = item['id'];
    this.CRM_LeadService.model_CRM_Shared_lead.CityId = CityId;

  }
  OnItemDeSelectCity(item: any) { }
  onSelectAllCity(items: any) { }
  onDeSelectAllCity(items: any) { }



  onItemSelectAssigneTo(item: any) {

    let AssigneTo = item['id'];
    this.CRM_LeadService.model_CRM_Shared_lead.AssigneTo = AssigneTo;

  }
  OnItemDeSelectAssigneTo(item: any) { }
  onSelectAllAssigneTo(items: any) { }
  onDeSelectAllAssigneTo(items: any) { }



  onChangeMister(Mister) {

    this.CRM_LeadService.model_CRM_Shared_lead.Mr = Mister;

  }

  // Begin Get Login User Employee ID

  GetLoginEmployeeID() {
    let t = localStorage.getItem('userToken');
    t = 'Bearer' + t
    this.ListList = [];
    this.CRM_LeadService.GetCRM_EmployeeId(t).then(x => {
      this.Listobj = x;


      this.EmployeeIdx = this.Listobj;

    });
  }

  // End Get Login User Employee ID






  GetListName() {



    let t = localStorage.getItem('userToken');
    t = 'Bearer' + t
    this.ListList = [];
    this.ListArray = [];
    this.CRM_LeadService.GetListName(t).then(x => {
      this.Listobj = x;
      Object.keys(this.Listobj).forEach(key => {
        let ListNametemp = new Model_Crm_List();
        ListNametemp = this.Listobj[key];
        this.ListList.push(ListNametemp);
        this.ListArray.push({ "id": ListNametemp.ListId, "itemName": ListNametemp.ListName });
      });

    }).then(y => {


      if (this.CRM_LeadService.model_CRM_Shared_lead.ListId > 0) {
        let Listy = this.ListList.find(x => x.ListId == this.CRM_LeadService.model_CRM_Shared_lead.ListId);

        this.SelectedListForList = [];
        this.ListValidation = true;
        this.SelectedListForList.push({ "id": Listy.ListId, "itemName": Listy.ListName });

        this.ValidationAll()
      }
      else {
        this.ListValidation = false;
        this.ValidationAll()

      }


    })
  }


  onItemSelectList(item: any) {

    let ListId = item['id'];
    this.CRM_LeadService.model_CRM_Shared_lead.ListId = ListId;

    this.ListValidation = true;

    this.ValidationAll();
  }
  OnItemDeSelectList(item: any) {

    this.ListValidation = false;
    this.CRM_LeadService.model_CRM_Shared_lead.ListId = 0;

    this.ValidationAll();

  }
  onSelectAllList(items: any) { }
  onDeSelectAllList(items: any) { }


  AniversaryDate(dateanniver) {
    this.CRM_LeadService.model_CRM_Shared_lead.AnniversaryDate = dateanniver;

  }
  DateOfBirth(birthdate) {
    this.CRM_LeadService.model_CRM_Shared_lead.DOB = birthdate;

  }


  onItemSelecDesignation(item: any) {

    let DesignationId = item['id'];
    this.CRM_LeadService.model_CRM_Shared_lead.DesignationID = DesignationId;
  }
  OnItemDeSelectDesignation(item: any) { }
  onSelectAllDesignation(items: any) { }
  onDeSelectAlldesignation(items: any) { }



  saveCRMLead(): void {
    let t = localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.CRM_LeadService.model_CRM_Shared_lead.IsActive = true;

    if (this.CRM_LeadService.model_CRM_Shared_lead.LeadId > 0) {
      this.CRM_LeadService.UpdateCrmLead(t, this.CRM_LeadService.model_CRM_Shared_lead).subscribe(res => {
        this.updatedLeadId = res;

        if (this.updatedLeadId > 0) {
          localStorage.setItem('SuccessfullMessage', "Successfully Updated");
          //this.rout.navigateByUrl('ManageMICEAccess/ManageMICEUser', { skipLocationChange: true }).then(() => this.rout.navigate(["ManageMICEAccess/CrmBranch"]));

          this.OutputDate = "cccc";
          this.searchItem.emit(this.OutputDate);
          this.CRM_LeadService.SelectedCrmIndexPage = 1;

          this.rout.navigate(["/ManageMICEAccess/ManageLead/LeadMoreInfo"]);
        }
      });
    }
    else {
      this.CRM_LeadService.AddCrmLead(t, this.CRM_LeadService.model_CRM_Shared_lead).subscribe(res => {
        this.updatedLeadId = res;


        if (this.updatedLeadId > 0) {
          this.CRM_LeadService.model_CRM_Shared_lead.LeadId = this.updatedLeadId;

          this.CRM_LeadService.leadlist.push(this.CRM_LeadService.model_CRM_Shared_lead);
          localStorage.setItem('SuccessfullMessage', "Successfully Added");
          //this.rout.navigateByUrl('ManageMICEAccess/ManageMICEUser', { skipLocationChange: true }).then(() => this.rout.navigate(["ManageMICEAccess/CrmBranch"]));
          this.OutputDate = "cccc";
          this.searchItem.emit(this.OutputDate);
          this.CRM_LeadService.SelectedCrmIndexPage = 1;

          this.rout.navigate(["/ManageMICEAccess/ManageLead/LeadMoreInfo"]);

        }
      })

    }
  }



  //Begning Of The CRM Assigne To DropDown List

  GetCrmAssignetoList() {
    this.GetLoginEmployeeID();

    let t = localStorage.getItem('userToken');
    t = 'Bearer' + t
    this.ListList = [];
    this.CRM_LeadService.GetAssignetoList(t).then(x => {
      this.Listobj = x;
      Object.keys(this.Listobj).forEach(key => {
        let AssigneTotemp = new Model_CRM_Assigne();
        AssigneTotemp = this.Listobj[key];

        this.AssigneList.push(AssigneTotemp);
        this.AssigneArray.push({ "id": AssigneTotemp.EmployeeId, "itemName": AssigneTotemp.EmployeeName });
      });



    }).then(y => {



      if ((this.CRM_LeadService.model_CRM_Shared_lead.AssigneTo != this.EmployeeIdx) && ((this.CRM_LeadService.model_CRM_Shared_lead.AssigneTo) != 0)) {
        let List = this.AssigneList.find(x => x.EmployeeId == this.CRM_LeadService.model_CRM_Shared_lead.AssigneTo);
        this.SelectAssigneList = [];

        this.SelectAssigneList.push({ "id": List.EmployeeId, "itemName": List.EmployeeName });


      }
      else {


        let List = this.AssigneList.find(x => x.EmployeeId == this.EmployeeIdx);
        this.SelectAssigneList = [];
        this.CRM_LeadService.model_CRM_Shared_lead.AssigneTo = this.EmployeeIdx;

        this.SelectAssigneList.push({ "id": List.EmployeeId, "itemName": List.EmployeeName });



      }


    });
  }


  //End Of The CRM Assigne To Dropdown List




  // Begin Check Lead Name
  CheckDuplicateLead() {


    let t = localStorage.getItem('userToken');
    t = 'Bearer' + t
    this.ListList = [];
    if ((this.CRM_LeadService.model_CRM_Shared_lead.LeadId == 0) || (this.CRM_LeadService.model_CRM_Shared_lead.LeadId == undefined)) {
      this.CRM_LeadService.CheckDuplicateLead(t, this.CRM_LeadService.model_CRM_Shared_lead.LeadName).then(x => {
        this.Listobj = x;

        this.IsDuplicateLead = this.Listobj;

        if (this.IsDuplicateLead == true) {
          this.DuplicateLeadValidation = false;

          this.LeadNameValidation = false;

          this.ValidationAll();

        }
        else {
          this.DuplicateLeadValidation = true;
          this.LeadNameValidation = true;
          this.ValidationAll();

        }



      });
    }
    else {
      if (this.CRM_LeadService.PreviousLeadName != this.CRM_LeadService.model_CRM_Shared_lead.LeadName) {
        this.CRM_LeadService.CheckDuplicateLead(t, this.CRM_LeadService.model_CRM_Shared_lead.LeadName).then(x => {
          this.Listobj = x;


          this.IsDuplicateLead = this.Listobj;

          if (this.IsDuplicateLead == true) {
            this.DuplicateLeadValidation = false;
            this.LeadNameValidation = false;
            this.ValidationAll();
          }
          else {
            this.DuplicateLeadValidation = true;
            this.LeadNameValidation = true;
            this.ValidationAll();

          }


        });

      }


    }
  }


  //Begning of the validation Part

  // Begning FirstName Validation Part
  ValidateFirstName() {
    //alert(this.CRM_LeadService.model_CRM_Shared_lead.PhoneNo);
    if (this.CRM_LeadService.model_CRM_Shared_lead.FirstName == 'undefined') {
      this.FirstNameValidation = false;
      this.ValidationAll();
    }

    else if (this.CRM_LeadService.model_CRM_Shared_lead.FirstName == "") {
      this.FirstNameValidation = false;
      this.ValidationAll();

    }
    else {
      this.FirstNameValidation = true;
      this.ValidationAll();
    }

  }
  // End of FirstName Validation Part

  // Begning LastName Validation Part
  ValidateLastName() {
    //alert(this.CRM_LeadService.model_CRM_Shared_lead.PhoneNo);
    if (this.CRM_LeadService.model_CRM_Shared_lead.LastName == 'undefined') {
      this.LastNameValidation = false;
      this.ValidationAll();
    }

    else if (this.CRM_LeadService.model_CRM_Shared_lead.LastName == "") {
      this.LastNameValidation = false;
      this.ValidationAll();

    }
    else {
      this.LastNameValidation = true;
      this.ValidationAll();
    }



  }
  // End of LastName Validation Part


  // Begning Phone Validation Part
  ValidatephoneNo() {

    if (this.CRM_LeadService.model_CRM_Shared_lead.PhoneNo == undefined) {
      this.Phonevalidation = true;
      this.ValidationAll();
    }

    else if (this.CRM_LeadService.model_CRM_Shared_lead.PhoneNo == "") {
      this.Phonevalidation = true;
      this.ValidationAll();

    }
    else {
      this.Phonevalidation = this.ObjectValidate.PhoneNoCheck(this.CRM_LeadService.model_CRM_Shared_lead.PhoneNo);
      this.ValidationAll();
    }

  }

  // End of Phone Validation Part


  // Begning of Email validation part
  ValidateEmail() {



    if (this.CRM_LeadService.model_CRM_Shared_lead.EmailId == undefined) {
      this.Emailvalidation = true;
      this.ValidationAll();
    }
    else if (this.CRM_LeadService.model_CRM_Shared_lead.EmailId == "") {
      this.Emailvalidation = true;
      this.ValidationAll();
    }
    else {

      this.Emailvalidation = this.ObjectValidate.EmailIdCheck(this.CRM_LeadService.model_CRM_Shared_lead.EmailId);
      this.ValidationAll();

    }

  }

  //End of the email validation part


  ValidationAll() {
    //alert(this.Emailvalidation);
    // alert(this.Phonevalidation);

    //alert(this.FirstNameValidation);
    //alert(this.LastNameValidation);
    //alert(this.ListValidation);
    //alert(this.LeadNameValidation);


    this.ValidationComplete = ((this.Emailvalidation) && (this.Phonevalidation) && (this.FirstNameValidation)

      && (this.LastNameValidation) && (this.ListValidation) && (this.LeadNameValidation));


    console.log(this.ValidationComplete);


  }


  //End of the  Validation part


  RefreshPage() {
    this.CRM_LeadService.CancelLead();
    this.SelectedListForList = [];
    this.SelectAssigneList = [];
    this.SelectedState = [];
    this.SelectedCity = [];
    this.SelectAssigneList = [];
    this.SelectedDesignation = [];
    this.CRM_LeadService.SelectedCrmIndexPage = 0;
    this.ValidateFirstName();
    this.ValidateLastName();
    this.ValidateLastName();


    this.CRM_LeadService.model_CRM_Shared_lead.CountryId = 1;

    let Country = this.countrylist.find(x => x.CountryId == this.CRM_LeadService.model_CRM_Shared_lead.CountryId);
    this.SelectedCountry = [];
    this.SelectedCountry.push({ "id": Country.CountryId, "itemName": Country.CountryName });
    this.GetState(this.CRM_LeadService.model_CRM_Shared_lead.CountryId);


    let List = this.AssigneList.find(x => x.EmployeeId == this.EmployeeIdx);
    this.SelectAssigneList = [];
    this.CRM_LeadService.model_CRM_Shared_lead.AssigneTo = this.EmployeeIdx;

    this.SelectAssigneList.push({ "id": List.EmployeeId, "itemName": List.EmployeeName });


  }

  PutPreviousdata() {

    this.EmployeeIdx = 0;
    this.GetCountry();
    this.GetCrmAssignetoList();
    this.GetListName();


    // this.CountrySettings = {
    //   singleSelection: true,
    //   text: 'Select Country',
    //   enableSearchFilter: true,
    //   showCheckbox: false
    // }

    // this.StateSettings = {
    //   singleSelection: true,
    //   text: 'Select State',
    //   enableSearchFilter: true,
    //   showCheckbox: false
    // }

    // this.CitySettings={

    //   singleSelection: true,
    //   text: 'Select City',
    //   enableSearchFilter: true,
    //   showCheckbox: false

    // }

    // this.ListSettings={
    //   singleSelection: true,
    //   text: 'Select List',
    //   enableSearchFilter: true,
    //   showCheckbox: false

    // }


    // this.AssigneSettings={
    //   singleSelection: true,
    //   text: 'Select List',
    //   enableSearchFilter: true,
    //   showCheckbox: false

    // }



    this.ValidationAll();
    // this.rout.navigateByUrl('/ManageMICEAccess/ManageLead/LeadBasicInfo', { skipLocationChange: true }).then(() => this.rout.navigate(["/ManageMICEAccess/ManageLead/LeadMoreInfo"]))

  }







}
