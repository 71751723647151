    <div class="row">
        <div class="col-lg-4">
            <div class="col-body-border-right">
                <div class="form-group row">
                    <label  class="col-sm-4 col-form-label clear-padding-right">code</label>
                    <div class="col-sm-7 clear-left-padding">
                        <input type="text" disabled="disabled" [(ngModel)]="EmployeeMasterService.SharedEmployees.EmployeeCode" id ="EmployeeCode" name="EmployeeCode" class="form-control form-control-custom form-control-custom-new">
                    </div>
                </div>
            </div>
        </div>
        <div class="col-lg-4">
            <div class="form-group row">
                <label  class="col-sm-4 col-form-label clear-padding-right">Name</label>
                <div class="col-sm-7 clear-left-padding">
                    <input type="text" disabled="disabled" [(ngModel)]="EmployeeMasterService.SharedEmployees.FullName" id ="FullName" name="FullName" class="form-control form-control-custom form-control-custom-new">
                </div>
            </div>
         </div>
    </div>

<fieldset class="body-form-control">
    <app-dialog [(visible)]="this.SuccessStatus">
        <span class="successmsg">{{this.SuccessMessage}}</span>
    </app-dialog>
  
  <legend class="legend--">Employee Other Details</legend>
  <form #EmployeeDetailForm="ngForm" (ngSubmit)="SaveEmpolyeeOtherDetails()">
      <div class="row">
          <div class="col-lg-4">
              <div class="col-body-border-right">
                  <div class="form-group row">
                      <label  class="col-sm-4 col-form-label clear-padding-right">Pan No</label>
                      <div class="col-sm-7 clear-left-padding">
                          <input type="text" [(ngModel)]="EmployeeMasterService.SharedEmployees.PANNo" id ="PANNo" maxlength="20" name="PANNo" #PANNo="ngModel" class="form-control form-control-custom form-control-custom-new"  >
                            <div *ngIf="PANNo.invalid && (PANNo.dirty || PANNo.touched)" class="required-validation">
                                <div *ngIf="PANNo.errors.maxlength">Employee PanNo is not more than 20 characters</div>
                            </div>
                      </div>
                  </div>
                  <div class="form-group row">
                      <label  class="col-sm-4 col-form-label clear-padding-right">Voter Id</label>
                      <div class="col-sm-7 clear-left-padding">
                        <input type="text" [(ngModel)]="EmployeeMasterService.SharedEmployees.VoterId" id ="VoterId" maxlength="20" #VoterId="ngModel" name="VoterId" class="form-control form-control-custom form-control-custom-new"  >
                        <div *ngIf="VoterId.invalid && (VoterId.dirty || VoterId.touched)" class="required-validation">
                            <div *ngIf="VoterId.errors.maxlength">Voter Id is not more than 20 characters</div>
                        </div>
                      </div>
                  </div>
                  <div class="form-group row">
                      <label  class="col-sm-4 col-form-label clear-padding-right">Aadhaar No</label>
                      <div class="col-sm-7 clear-left-padding">
                        <input type="text" [(ngModel)]="EmployeeMasterService.SharedEmployees.AdhaarNo" id ="AdhaarNo" maxlength="20" #AdhaarNo="ngModel" name="AdhaarNo" class="form-control form-control-custom form-control-custom-new"  >
                        <div *ngIf="AdhaarNo.invalid && (AdhaarNo.dirty || AdhaarNo.touched)" class="required-validation">
                            <div *ngIf="AdhaarNo.errors.maxlength">Aadhaar No is not more than 20 characters</div>
                        </div>
                      </div>
                  </div>
                  <div class="form-group row">
                    <label  class="col-sm-4 col-form-label clear-padding-right">Payment Mode</label>
                    <div class="col-sm-7 clear-left-padding">
                        <select (ngModelChange)="PaymentModeChanged($event)" [(ngModel)]="EmployeeMasterService.SharedEmployees.PaymentModeId" tabindex="10" id ="PaymentModeId" class="form-control" name="PaymentModeId" #PaymentModeId="ngModel">
                          <option [value]="0" [disabled]=true >Select Payment Mode</option>
                          <option *ngFor="let PM of PaymentModes" [value]="PM.ModeId">{{PM.ModeName}}</option>
                        </select>
                    </div>
                    <div class="required-validation" *ngIf="EmployeeMasterService.SharedEmployees.PaymentModeId==0">Payment Mode is required</div>
                  </div>
                  <div *ngIf="IsBankShow" class="form-group row">
                    <label  class="col-sm-4 col-form-label clear-padding-right">IFSC Code</label>
                    <div class="col-sm-7 clear-left-padding">
                        <input type="text" [(ngModel)]="EmployeeMasterService.SharedEmployees.IFSCCode" id ="IFSCCode"  name="IFSCCode" #IFSCCode="ngModel" class="form-control form-control-custom form-control-custom-new" >
                    </div>
                </div>
              </div>
          </div>
          <div class="col-lg-4">
              <div class="col-body-border-right">
                  <div class="form-group row">
                      <label class="col-sm-4 col-form-label clear-padding-right">EPF No</label>
                      <div class="col-sm-7 clear-left-padding">
                        <input type="text" [(ngModel)]="EmployeeMasterService.SharedEmployees.EPFNo" id ="EPFNo" maxlength="25" #EPFNo="ngModel" name="EPFNo" class="form-control form-control-custom form-control-custom-new"  >
                          <div *ngIf="EPFNo.invalid && (EPFNo.dirty || EPFNo.touched)" class="required-validation">
                              <div *ngIf="EPFNo.errors.maxlength">EPFNo is not more than 25 characters</div>
                          </div>
                      </div>                    
                    </div>
                    <div class="form-group row">
                      <label  class="col-sm-4 col-form-label clear-padding-right">ESI No</label>
                      <div class="col-sm-7 clear-left-padding">
                        <input type="text" [(ngModel)]="EmployeeMasterService.SharedEmployees.ESINo" id ="ESINo" maxlength="25" #ESINo="ngModel" name="ESINo" class="form-control form-control-custom form-control-custom-new"  >
                        <div *ngIf="ESINo.invalid && (ESINo.dirty || ESINo.touched)" class="required-validation">
                            <div *ngIf="ESINo.errors.maxlength">ESINo is not more than 25 characters</div>
                        </div>
                      </div>
                    </div>
                    <div class="form-group row">
                      <label  class="col-sm-4 col-form-label clear-padding-right">Gratuity No</label>
                      <div class="col-sm-7 clear-left-padding">
                        <input type="text" [(ngModel)]="EmployeeMasterService.SharedEmployees.GratuityNo" id ="GratuityNo" maxlength="50" #GratuityNo="ngModel" name="GratuityNo" class="form-control form-control-custom form-control-custom-new"  >
                        <div *ngIf="GratuityNo.invalid && (GratuityNo.dirty || GratuityNo.touched)" class="required-validation">
                            <div *ngIf="GratuityNo.errors.maxlength">ESINo is not more than 50 characters</div>
                        </div>
                      </div>
                    </div>
                    <div *ngIf="IsBankShow" class="form-group row">
                        <label  class="col-sm-4 col-form-label clear-padding-right">Account No</label>
                        <div class="col-sm-7 clear-left-padding">
                            <input type="text" [(ngModel)]="EmployeeMasterService.SharedEmployees.AccountNumber" minlength="10" maxlength="50" pattern="[0-9]+" id ="AccountNumber" name="AccountNumber" #AccountNumber="ngModel" required class="form-control form-control-custom form-control-custom-new" >
                        </div>
                        <div *ngIf="AccountNumber.invalid && (AccountNumber.dirty || AccountNumber.touched)" class="required-validation">
                          <div *ngIf="AccountNumber.errors.maxlength">Account Number is not more than 50 characters</div>
                          <div *ngIf="AccountNumber.errors.minlength">Account Number is not less than 10 characters</div>
                          <div *ngIf="AccountNumber.errors?.pattern">Account Number should be valid.</div>
                      </div>
                    </div>
                    <div *ngIf="IsBankShow" class="form-group row">
                        <label  class="col-sm-4 col-form-label clear-padding-right">Branch Name</label>
                        <div class="col-sm-7 clear-left-padding">
                          <input type="text" [(ngModel)]="EmployeeMasterService.SharedEmployees.BranchName" id ="BranchName"  name="BranchName" #BranchName="ngModel" class="form-control form-control-custom form-control-custom-new" >
                      </div>
                    </div>
              </div>
          </div>
          <div class="col-lg-4">
              <div class="form-group row">
                  <label  class="col-sm-4 col-form-label clear-padding-right">Pension No</label>
                  <div class="col-sm-7 clear-left-padding">
                    <input type="text" [(ngModel)]="this.EmployeeMasterService.SharedEmployees.PensionNo" id ="PensionNo" maxlength="50" #PensionNo="ngModel" name="PensionNo" class="form-control form-control-custom form-control-custom-new"  >
                    <div *ngIf="PensionNo.invalid && (PensionNo.dirty || PensionNo.touched)" class="required-validation">
                        <div *ngIf="PensionNo.errors.maxlength">PensionNo is not more than 50 characters</div>
                    </div>
                  </div>
                </div>
            <div class="form-group row">
              <label  class="col-sm-4 col-form-label clear-padding-right">Superannuation No</label>
              <div class="col-sm-7 clear-left-padding">
                <input type="text" [(ngModel)]="this.EmployeeMasterService.SharedEmployees.SuperannuationNo" id ="SuperannuationNo" maxlength="50" #SuperannuationNo="ngModel" name="SuperannuationNo" class="form-control form-control-custom form-control-custom-new"  >
                <div *ngIf="SuperannuationNo.invalid && (SuperannuationNo.dirty || SuperannuationNo.touched)" class="required-validation">
                    <div *ngIf="SuperannuationNo.errors.maxlength">SuperannuationNo is not more than 50 characters</div>
                </div>
              </div>
            </div>
            <div class="form-group row">
              <label  class="col-sm-4 col-form-label clear-padding-right">UAN No</label>
              <div class="col-sm-7 clear-left-padding">
                <input type="text" [(ngModel)]="this.EmployeeMasterService.SharedEmployees.UANNo" id ="UANNo" maxlength="50" #UANNo="ngModel" name="UANNo" class="form-control form-control-custom form-control-custom-new"  >
                <div *ngIf="UANNo.invalid && (UANNo.dirty || UANNo.touched)" class="required-validation">
                    <div *ngIf="UANNo.errors.maxlength">UANNo is not more than 50 characters</div>
                </div>
              </div>
            </div>  
            <div *ngIf="IsBankShow" class="form-group row">
                <label  class="col-sm-4 col-form-label clear-padding-right">Bank</label>
                <div class="col-sm-7 clear-left-padding">
                    <input type="text" hidden="hidden" [(ngModel)]="this.EmployeeMasterService.SharedEmployees.EmpBankRefId" id ="EmpBankRefId" #EmpBankRefId="ngModel" name="EmpBankRefId">
                    <input type="text" hidden="hidden" [(ngModel)]="this.EmployeeMasterService.SharedEmployees.ExistsBankId" id ="ExistsBankId" #ExistsBankId="ngModel" name="ExistsBankId">
                  <select [(ngModel)]="EmployeeMasterService.SharedEmployees.BankId" tabindex="3" (ngModelChange)="onChangeBank($event)" id ="BankId" class="form-control"  name="BankId" #BankId="ngModel" >
                    <option [value]="0" [disabled]=true >Select Bank</option>
                    <option *ngFor="let bank of BankMasters" [value]="bank.BankId">{{bank.BankName}}</option>
                  </select>
                </div>
                <div class="required-validation" *ngIf="EmployeeMasterService.SharedEmployees.BankId==0">Bank is required</div>
            </div> 
              <div class="form-group row">
                  <label  class="col-sm-6 col-form-label text-center clear-padding-right" *ngIf="EmployeeMasterService.SharedEmployees.EmployeeId >0">
                      <button  type="submit" tabindex="22" class="btn btn-primary save-button" [disabled]="!(EmployeeDetailForm.valid && EmployeeMasterService.SharedEmployees.PaymentModeId !=0 && this.DisableSave==true)" >Save & Next</button>  
                  </label>
                  <div class="col-sm-6 col-form-label text-center">
                  <button type="button" tabindex="23" class="btn btn-danger cancle-button" (click)="RefreshPage()">Cancel</button></div>
              </div>
          </div>
      </div>
  </form>
</fieldset>

