export class RoleMasterModel
{
    public RoleId:number;
    public RoleName:string;
    public OldRoleName:string;
    public ModuleId :number;
    public ModuleName:string;
    public ModuleNames:string;
    public ModuleIds:string;
    public IsActive:boolean;
}