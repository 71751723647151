import {  Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs/Subject';
import { DataTableDirective } from 'angular-datatables';
import { UserService } from '../../shared/user.service';
import { SalaryMasterService } from '../../shared/HR_ADMIN/Salary/SalaryMaster.service';
import { LevelService } from '../../shared/HR_ADMIN/Lavels_Grades/Levels.service';
import { OrganizationService } from '../../shared/Organization/organization.service';
import { Company } from  '../../shared/Organization/Company.Model';
import { Grade } from  '../../shared/HR_ADMIN/Lavels_Grades/Grade.Model';
import { SalaryRule } from  '../../shared/HR_ADMIN/Salary/SalaryRuleModel';
// Begning Confirmation
import { ConfirmationService } from '@jaspero/ng2-confirmations';
import { ResolveEmit } from '../../../app/resolve-emit';
import { ConfirmSettings } from '../../../app/confirm-settings';
import { Menu } from '../../shared/menu.model';
// end of Confirmation

@Component({
  selector: 'app-manage-salary-component-rules',
  templateUrl: './manage-salary-component-rules.component.html',
  styleUrls: ['./manage-salary-component-rules.component.css']
})
export class ManageSalaryComponentRulesComponent implements OnInit 
{
  SalaryRuleModel : SalaryRule= new SalaryRule();
  userClaims: any;
  SalaryRules: SalaryRule[] = [];
  SalaryComponentRules: SalaryRule[] = [];
  Result : string;
  SuccessStatus:boolean=false;
  SuccessMessage: string ="";
  public tableWidget: any;
  SalaryRuleId : any;
  SalaryRule: any;
  base64textString : string;

  Companies: Company[] = [];
  company: any;
  Grades: Grade[] = [];
  grade: any;
  tempSalaryRuleadd : SalaryRule =new SalaryRule();
  SalaryComponentsIsValid : boolean =false;  
  // Begning Permission Portion    
  SelectedMenu:Menu;
  IsDataIsInViewState : boolean=false;
  CanViewState : boolean=false;
  DisableSave : boolean=false;
  // End of the permission portion
  //check for duplicate component code
  CheckExiststatus : boolean=null;  
  Checkeditstatus : boolean =false;
  check : string ="";
  checkdata : any;
  checkdataval : string ="";
  //check for duplicate component code
  //Begin for disable
  IsCompanyEnable : boolean = false;
  IsGradeEnable : boolean = false; 
  IsGridEnable : boolean = false;
  //End for disable

  Salarycomponentid : any;
  
  constructor(private SalaryMasterService: SalaryMasterService, private organizationService: OrganizationService, private LevelService: LevelService, private rout: Router,  private _confirmation: ConfirmationService, private userService: UserService) { }
  
  @ViewChild(DataTableDirective, {static: false})
  setDtOptions: DataTables.Settings = {};
  cardDtOptions: DataTables.Settings = {};
  dtElement: DataTableDirective;
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();
  
  ngOnInit() 
  {
    //Begin Permission Portion
    this.SelectedMenu=this.userService.SelectedMenu;
    if(this.SelectedMenu !=undefined)
    {
      localStorage.setItem('selectedmenu',JSON.stringify(this.SelectedMenu));
      this.rout.navigate([this.SelectedMenu.RouteName]);
    }
    else
    {
      if(JSON.parse(localStorage.getItem('selectedmenu'))!=undefined)
      {
        this.SelectedMenu = JSON.parse(localStorage.getItem('selectedmenu'));
        this.rout.navigate([this.SelectedMenu.RouteName]);
      }
    }     
    if(this.SelectedMenu.CanAdd)
    {
      this.IsDataIsInViewState=false;
    }
    else
    {
      this.IsDataIsInViewState=true;
    }
    if(this.SelectedMenu.CanView)
    {
    this.CanViewState=false;
    }
    else
    {
      this.CanViewState=true;
    }
    //End of the peremission portion
    this.getCompanies();
    this.getGrades();
    this.SalaryRuleModel.CompanyId=0;
    this.SalaryRuleModel.GradeId=0;
    this.DisableSave=true;
    if(localStorage.getItem('SuccessfullMessage'))
    {
      this.SuccessStatus=true;
      this.SuccessMessage =localStorage.getItem('SuccessfullMessage')
      localStorage.removeItem('SuccessfullMessage');
    } 
    this.dtOptions = 
    {
      pagingType: 'full_numbers',
      pageLength: 2
    };
  }

  ngAfterViewInit(): void 
  {
    this.GetSalaryRule();
    this.dtOptions = 
    {
      pagingType: 'full_numbers',
      pageLength: 2
    };
  }

  settings: ConfirmSettings | any = 
  {
    overlay: true,
    overlayClickToClose: true,
    showCloseButton: true,
    confirmText: 'Yes',
    declineText: 'No'
  };

  getCompanies() 
  {
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.organizationService.GetCompany( t).then(x => 
    {
      this.company = x;
      Object.keys(this.company).forEach( key => 
      {
        // tslint:disable-next-line:no-unused-expression
        this.company[key];
        let Companytemp = new Company();
        Companytemp = this.company[key];
        this.Companies.push(Companytemp);
      });     
    });      
  }

  getGrades() 
  {
    let t = localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.LevelService.GetGrade( t).then(x => 
    {
      this.grade = x;
      Object.keys(this.grade).forEach( key => 
      {
        // tslint:disable-next-line:no-unused-expression
        this.grade[key];
        let gradetemp = new Grade();
        gradetemp = this.grade[key];
        this.Grades.push(gradetemp);
      });     
    });      
  }

  GetSalaryRule()
  {
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.SalaryMasterService.GetSalaryRule( t).then(x => 
    {
      this.SalaryRule = x;
      Object.keys(this.SalaryRule).forEach( key => 
      {
        this.SalaryRule[key];
        let SalaryRuletemp = new SalaryRule();
        SalaryRuletemp = this.SalaryRule[key];
        this.SalaryRules.push(SalaryRuletemp);
      });
      this.dtTrigger.next();
    });
  }
  
  GetSalaryRuleByid(CompanyId, GradeId) 
  { 
    this.SalaryComponentsIsValid=true;
    //Begin for disable   
    this.IsCompanyEnable = true;
    this.IsGradeEnable = true; 
    this.IsGridEnable = true;      
    //End for disable
    // Begning of the permission portion
    this.IsDataIsInViewState=false;
    // End of the permision portion
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.SalaryMasterService.GetSalaryRuleById( t,CompanyId, GradeId).then(x => 
    {
      this.SalaryRuleId = x;
      this.SalaryComponentRules=  [];
      Object.keys(this.SalaryRuleId).forEach( key => 
      {
        // tslint:disable-next-line:no-unused-expression
        this.SalaryRuleId[key];
        var SalaryRuletemp = new SalaryRule();
        SalaryRuletemp = this.SalaryRuleId[key];  
        if(SalaryRuletemp.Percentage == 0 )
        {
          SalaryRuletemp.isenable=false;
        }
        else
        {
          if(SalaryRuletemp.IsBasic==true)
          {
            SalaryRuletemp.value="Basic";
          }
          if(SalaryRuletemp.IsCTC==true)
          {
            SalaryRuletemp.value="CTC";
          }
          SalaryRuletemp.isenable=true;
        }
        this.SalaryComponentRules.push(SalaryRuletemp);
      });
      this.SalaryRuleModel.CompanyId=CompanyId;
      this.SalaryRuleModel.GradeId=GradeId;
      // Begin for check company code duplication
      this.checkdataval= this.SalaryRuleModel.RefId.toString();
      this.Checkeditstatus=true;
      this.CheckExiststatus =true;
      this.check=""
      // End for check company code duplication
    });
  }

  viewSalaryRuleByid(CompanyId, GradeId) 
  {
    //Begin for disable   
    this.IsCompanyEnable = true;
    this.IsGradeEnable = true; 
    this.IsGridEnable = false;     
    //End for disable
    //begin permission
    this.IsDataIsInViewState=true;
    //end permission
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.SalaryMasterService.GetSalaryRuleById( t,CompanyId, GradeId).then(x => 
    {
      this.SalaryRuleId = x;
      this.SalaryComponentRules=  [];
      Object.keys(this.SalaryRuleId).forEach( key => 
      {
        // tslint:disable-next-line:no-unused-expression
        this.SalaryRuleId[key];
        let componenttemp = new SalaryRule();
        componenttemp = this.SalaryRuleId[key];
        if(componenttemp.Percentage == 0 )
        {
          componenttemp.isenable=false;
        }
        else
        {
          if(componenttemp.IsBasic==true)
          {
            componenttemp.value="Basic";
          }
          if(componenttemp.IsCTC==true)
          {
            componenttemp.value="CTC";
          }
          componenttemp.isenable=true;
        }
        this.SalaryComponentRules.push(componenttemp);
      });
      this.SalaryRuleModel.CompanyId=CompanyId;
      this.SalaryRuleModel.GradeId=GradeId;
      // Begin for check company code duplication
      this.checkdataval= this.SalaryRuleModel.RefId.toString();
      this.Checkeditstatus=true;
      this.CheckExiststatus =true;
      this.check=""
      // End for check company code duplication
    });
  }

  DeleteSalaryRuleByid(Refid : number) :void
  {
    if(this.SelectedMenu.CanAdd)
    {
     this.IsDataIsInViewState=false;
    }
    else
    {
      this.IsDataIsInViewState=true;
    }
    this._confirmation.create('Confirm to Deleate', 'Press Yes to Delete', this.settings)
      .subscribe((ans: ResolveEmit) => 
      {
        if (ans.resolved == true) 
        {
          let t =  localStorage.getItem('userToken');
          t = 'Bearer' + t;
          this.SalaryRuleModel.IsActive=true;
          if( Refid > 0)
          {
            this.SalaryMasterService.DeleteRule(t ,Refid).subscribe(res=>
            {
              this.SalaryRuleId=res;
              this.SalaryRuleModel.RefId=0;                                  
              if(this.SalaryRuleId >0)
              {
                localStorage.setItem('SuccessfullMessage', "Deleted Successfully");
                this.rout.navigateByUrl('/SalaryComponent/ManageSalaryComponentComponent', {skipLocationChange: true}).then(()=>this.rout.navigate(["/SalaryComponent/SalaryComponentsRules"]));
              }   
            });                  
          }
        }
      })
  }
  // begning of check duplicate Salary Mapping
  CheckSalarycomponentcode()
  {
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    if(!this.Checkeditstatus)
    {
      this.SalaryMasterService.CheckSalaryRule( t, this.SalaryRuleModel.CompanyId, this.SalaryRuleModel.GradeId).then(x => 
      {
        this.checkdata = x;
        if( this.checkdata.length >0)
        {
          this.CheckExiststatus =false;
          this.check="Exist"
        }
        else
        {
          this.CheckExiststatus =true;
          this.check="";
        }
     });
    }
    else
    {
      if(this.SalaryRuleModel.RefId.toString() !=this.checkdataval )
      {
        this.SalaryMasterService.CheckSalaryRule( t, this.SalaryRuleModel.CompanyId,this.SalaryRuleModel.GradeId).then(x => 
        {
          this.checkdata = x;
          if( this.checkdata.length >0)
          {
            this.CheckExiststatus =false;
            this.check="Exist"
          }
          else
          {
            this.CheckExiststatus =true;
            this.check="";
          }
        });
      }
      else
      {
        this.CheckExiststatus =true;
        this.check="";
      }
    }
  }
  // end of duplicate Salary Mapping

  RefreshPage()
  {
    this.rout.navigateByUrl('/SalaryComponent/ManageSalaryComponentComponent', {skipLocationChange: true}).then(()=>this.rout.navigate(["/SalaryComponent/SalaryComponentsRules"]))
  }

  saveSalaryComponentMaster() :void
  {
    this.DisableSave=false;
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.SalaryMasterService.UpdateSalarybulk(t ,this.SalaryComponentRules).subscribe(res=>
    {
      this.Salarycomponentid=res;
      if(this.Salarycomponentid>0)
      {
        localStorage.setItem('SuccessfullMessage', "Modified Successfully");
        this.rout.navigateByUrl('/SalaryComponent/ManageSalaryComponentComponent', {skipLocationChange: true}).then(()=>this.rout.navigate(["/SalaryComponent/SalaryComponentsRules"]));
      }
    });                  
  }

  PercentageChange( i:number) 
  {   
    this.ChecksalaryComopnentValidity();
    this.SalaryComponentRules[i]
    if(Number(this.SalaryComponentRules[i].Percentage)!=NaN)
    {
      if(this.SalaryComponentRules[i].Percentage >0)
      {
        if(this.SalaryComponentRules[i].Percentage>=100)
        {
          this.SalaryComponentRules[i].Percentage=0;  
        }
        else
        {
          this.SalaryComponentRules[i].Amount=0;
          this.SalaryComponentRules[i].isenable=true;  
        }
      }
      else
      {
        this.SalaryComponentRules[i].Percentage=0
      }
    }
    else
    {
      this.SalaryComponentRules[i].Percentage=0
    }   
  }

  AmountageChange( i:number) 
  {     
    this.ChecksalaryComopnentValidity();
    if(Number(this.SalaryComponentRules[i].Amount)!=NaN)
    {    
      if(this.SalaryComponentRules[i].Amount >0)
      {      
        this.SalaryComponentRules[i].Percentage=0;
        this.SalaryComponentRules[i].IsBasic=false;
        this.SalaryComponentRules[i].IsCTC=false;
        this.SalaryComponentRules[i].isenable=false;  
      }
      else
      {
        this.SalaryComponentRules[i].Amount=0;
      } 
    }
    else
    {
      this.SalaryComponentRules[i].Amount=0; 
    }   
  }

  OnChangeBasic(evt,i:number)
  {
    this.ChecksalaryComopnentValidity();
    this.SalaryComponentRules[i];
    var target = evt.target;
    if(target.checked)
    {           
      this.SalaryComponentRules[i].IsBasic=true;
      this.SalaryComponentRules[i].IsCTC=false; 
      this.SalaryComponentRules[i].value="Basic";        
    }
  }

  OnChangeCTC(evt,i:number)
  {
    this.ChecksalaryComopnentValidity();
    this.SalaryComponentRules[i];        
    var target = evt.target; 
    if(target.checked)
    { 
      this.SalaryComponentRules[i].IsCTC=true;
      this.SalaryComponentRules[i].IsBasic=false; 
      this.SalaryComponentRules[i].value="CTC";
    }
  }

  ChecksalaryComopnentValidity()
  {
    this.SalaryComponentRules.forEach(item=>
    {
      if(item.Percentage > 0)
      { 
        if(item.IsCTC==false)
        {
          if(item.IsBasic==true)
          {
            this.SalaryComponentsIsValid== true;
          }
          else
          {
            this.SalaryComponentsIsValid== false;
          }   
        }
        else
        {
          this.SalaryComponentsIsValid== true;
        }
      }
      if(item.Amount > 0)
      {
        item.IsCTC=false;
        item.IsBasic=false;
      }
    })
  }
}
