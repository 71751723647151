<div class="body-content-area tabcontent" id="link1">
  <div class="row">
      <div class="col-lg-9">
          <div class="row">
              <div class="col-lg-1 low-padding text-center">
                  <!-- <img *ngIf="this.SelectedMenu.Image!=null" src={{this.SelectedMenu.Image}} class="body-icon" alt="Icon"/> -->
              </div>
              <div class="col-lg-10 clear-padding">
                  <span class="page-title">Approve/Reject CompOff</span>
                  <small class="page-description"></small>
              </div>
          </div>
      </div>
  </div>
  
  <div *ngIf="!(this.CanViewState)">  
    
    <div *ngIf="NoRecordMsg" style="color: #dc3545; text-align: center;font-size: 15px; font-weight: bold;">No Record Found.</div>

    <table id="tblApplication" *ngIf="(this.CompOffApplication.length > 0)" class="table table-striped table-bordered text-center table-new row-border hover">
      <thead class="thead-blue text-center">
        <tr style=" width: 100%;">
          <th style=" width: 8%;">Sl No.</th>
          <th style=" width: 8%;">Code</th>
          <th style=" width: 20%;" class="text-left">Name</th>
          <th style=" width: 10%;">Date</th>
          <th style=" width: 9%;">In-Time</th>
          <th style=" width: 9%;">Out-Time</th>
          <th style=" width: 8%;">Duration</th>
          <th style=" width: 10%;">CompOff</th>
          <!-- <th style=" width: 14%;" class="text-left">Reason</th> -->
          <th style=" width: 8%;">Action</th>
          <th style=" width: 10%;">
            <input type="checkbox" name="all" (change)="checkAll($event)"/>Select All
          </th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let appl of CompOffApplication, let i = index">
          <td scope="row">{{i+1}}</td>
          <td>{{appl.EmployeeCode}}</td>
          <td class="text-left">{{appl.EmployeeName}}</td>
          <td>{{appl.AttendanceDate | date : "dd-MMM-yyyy"}}</td>
          <td>{{appl.ActualInTime}}</td>
          <td>{{appl.ActualOutTime}}</td>
          <td>{{appl.ActualWorkingDuration}}</td>
          <td class="text-left" *ngIf="appl.HalfDay==true">HalfDay</td>
          <td class="text-left" *ngIf="appl.FullDay==true">FullDay</td>
          <!-- <td class="text-left">{{appl.Reason}}</td> -->
          <td>
            <button *ngIf="this.SelectedMenu.CanView" type="button" (click)="viewAppliedEmployeeCompoff(appl.RefId,template)" class="table-view-button" type="button" ><i class="fas fa-eye"></i></button>
          </td>
          <td><input type="checkbox" [checked]="appl.Selected" id ="Selected_{{i}}" name="Selected_{{i}}" (change)="SelectedChange(i,$event)"></td>
        </tr>
      </tbody>
    </table>
    <form #RemarksForm="ngForm" *ngIf="(this.CompOffApplication.length > 0)">
      <div class="row">
        <div class="col-lg-4">
          <div class="col-body-border-right">
            <div class="form-group row">
              <label  class="col-sm-4 col-form-label clear-padding-right">Remarks</label>
              <div class="col-sm-7 clear-left-padding">
                <input type="text" [(ngModel)]="ModelListApplication.RemarksAll" tabindex="1" maxlength="500" id ="RemarksAll" name="RemarksAll" #RemarksAll="ngModel" required class="form-control form-control-custom form-control-custom-new" >
              </div>
            </div>
          </div> 
        </div>

        <div class="col-lg-4">
          <div class="col-body-border-right">
            <div class="form-group row">
              <label *ngIf="!(this.CanApproveState)" class="col-sm-6 col-form-label text-center clear-padding-right">
                <button type="submit" (click)="CompOffApplicationApprovedAll(template)" class="btn btn-primary save-button" [disabled]="!(RemarksForm.valid && this.DisableSave==true)">Approved All</button>  
              </label>
              <div *ngIf="!(this.CanApproveState)" class="col-sm-6 col-form-label text-center">
                <button type="submit" (click)="CompOffApplicationRejectedAll(template)" class="btn btn-danger cancle-button" [disabled]="!(RemarksForm.valid && this.DisableSave==true)">Rejected All</button>  
              </div>
            </div>
          </div> 
        </div>

        <div class="col-lg-4">
          <div class="form-group row">
            <div class="col-sm-6 col-form-label text-center">
              <button type="button" (click)="RefreshPage()" class="btn btn-danger cancle-button">Refresh Page</button>
            </div>
          </div>
        </div>
      </div>
    </form>

    <div style=" box-align: center; width: 1500px;" >
      <ng-template #template>
        <div class="modal-header" style="background: #2a9bd7; max-width: 1500px;"> 
          <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()" style="color:#fff;">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div  style="text-align: center; max-width: 1500px;">
          <div *ngIf="ApprovedMsg" style="color: #dc3545; text-align: center;font-size: 15px; font-weight: bold;">Application Approved Successfully</div>
          <div *ngIf="RejectedMsg" style="color: #dc3545; text-align: center;font-size: 15px; font-weight: bold;">Application Rejected Successfully</div>
          <div style=" width: 100%;" *ngIf="ShowPopup">

            <fieldset class="body-form-control" style=" text-align: left;"> 
                <legend class="legend--">CompOff Approval</legend>
              <form #EmployeeCompOffForm="ngForm">
                <div class="row"> 
                  <div class="col-lg-4">
                    <div class="col-body-border-right">
                      <div class="form-group row">
                        <label  class="col-sm-4 col-form-label clear-padding-right">Code</label>
                        <div class="col-sm-7 clear-left-padding">
                          :&nbsp;&nbsp;{{this.ModelListApplication.EmployeeCode}}
                        </div>
                      </div>
                      <div class="form-group row">
                        <label  class="col-sm-4 col-form-label clear-padding-right">Date</label>
                        <div class="col-sm-7 clear-left-padding">
                          :&nbsp;&nbsp;{{ModelListApplication.AttendanceDate  | date : "dd-MMM-yyyy"}}
                        </div>
                      </div>
                      <div class="form-group row">
                        <label class="col-sm-4 col-form-label clear-padding-right">In-Time</label>
                        <div class="col-sm-7 clear-left-padding">
                          :&nbsp;&nbsp;{{ModelListApplication.ActualInTime}}
                        </div>
                      </div>  
                      <div class="form-group row">
                        <label class="col-sm-4 col-form-label clear-padding-right">CompOff</label>
                        <div *ngIf="this.ModelListApplication.HalfDay==true" class="col-sm-4 col-form-label clear-padding-right">
                            :&nbsp;&nbsp;HalfDay
                        </div>
                        <div *ngIf="this.ModelListApplication.FullDay==true" class="col-sm-4 col-form-label clear-padding-right">
                            :&nbsp;&nbsp;FullDay
                        </div>
                      </div>      
                    </div>  
                  </div>
            
                  <div class="col-lg-4">
                    <div class="col-body-border-right">
                      <div class="form-group row">
                        <label class="col-sm-4 col-form-label clear-padding-right">Name</label>
                        <div class="col-sm-7 clear-left-padding">
                          :&nbsp;&nbsp;{{this.ModelListApplication.EmployeeName}}
                        </div>
                      </div>
                      <div class="form-group row">
                        <label  class="col-sm-4 col-form-label clear-padding-right">Status</label>
                        <div class="col-sm-4 col-form-label clear-padding-right">
                          :&nbsp;&nbsp;{{this.ModelListApplication.Status}}
                        </div>
                      </div>
                      <div class="form-group row">
                        <label class="col-sm-4 col-form-label clear-padding-right">Out-Time</label>
                        <div class="col-sm-7 clear-left-padding">
                          :&nbsp;&nbsp;{{this.ModelListApplication.ActualOutTime}}
                        </div>
                      </div>
                      <div class="form-group row">
                        <label class="col-sm-4 col-form-label clear-padding-right">Reason</label>
                        <div class="col-sm-4 col-form-label clear-padding-right">
                          :&nbsp;&nbsp;{{this.ModelListApplication.Reason}}
                        </div>
                      </div>
                    </div> 
                  </div>
            
                  <div class="col-lg-4">
                    <div class="form-group row">
                      <label  class="col-sm-4 col-form-label clear-padding-right">Approver</label>
                      <div class="col-sm-7 clear-left-padding">
                        :&nbsp;&nbsp;{{this.ModelListApplication.RHName}} 
                      </div>
                    </div>
                    <div class="form-group row">
                      <label class="col-sm-4 col-form-label clear-padding-right">RH EMailId</label>
                      <div class="col-sm-7 clear-left-padding">
                        :&nbsp;&nbsp;{{this.ModelListApplication.RHEmailId}} 
                      </div>
                    </div>
                    <div class="form-group row">
                      <label class="col-sm-4 col-form-label clear-padding-right">Duration</label>
                      <div class="col-sm-7 clear-left-padding">
                        :&nbsp;&nbsp;{{this.ModelListApplication.ActualWorkingDuration}} 
                      </div>
                    </div>
                    <div class="form-group row">
                      <label class="col-sm-4 col-form-label clear-padding-right">Remarks</label>
                      <div class="col-sm-7 clear-left-padding">
                        <input type="text" [(ngModel)]="this.ModelListApplication.Remarks" tabindex="1" maxlength="500" id ="Remarks" name="Remarks" #Remarks="ngModel" required class="form-control form-control-custom form-control-custom-new" >
                      </div>
                      <div *ngIf="Remarks.invalid && (Remarks.dirty || Remarks.touched)" class="alert alert-danger">
                        <div *ngIf="Remarks.errors.required">Remarks is required.</div>
                        <div *ngIf="Remarks.errors.maxlength">Remarks is not more than 500 characters</div>
                      </div> 
                    </div>
                    <div class="form-group row">
                      <label *ngIf="!(this.CanApproveState)" class="col-sm-6 col-form-label text-center clear-padding-right">
                        <button type="submit" tabindex="2" (click)="EmployeeCompoffApproved(template)" class="btn btn-primary save-button" [disabled]="!(EmployeeCompOffForm.valid && this.DisableSave==true)">Approved</button>  
                      </label>
                      <div *ngIf="!(this.CanApproveState)" class="col-sm-6 col-form-label text-center">
                        <button type="submit" tabindex="3" (click)="EmployeeCompoffRejected(template)" class="btn btn-danger cancle-button" [disabled]="!(EmployeeCompOffForm.valid && this.DisableSave==true)">Rejected</button>  
                      </div>
                    </div>
                  </div> 
                </div> 
              </form>
            </fieldset>
          </div>

          <button type="button" aria-label="Close" (click)="modalRef.hide()" style="background: #2a9bd7; outline: 0px none; border: 0px none; color: #fff; padding: 5px 10px; border-radius: 3px;">
            <span>Close</span>
          </button>             
        </div>
      </ng-template>
    </div>

  </div>
</div>