import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from '../../shared/user.service';
import { KRAService }  from '../../shared/HR_ADMIN/KRA/KRAService.service';
import { KRAMasterModel } from '../../shared/HR_ADMIN/KRA/KRAMasterModel.Model';
import { KRAApplicationModel } from '../../shared/HR_ADMIN/KRA/KRAApplicationModel.Model';
import { Menu } from '../../shared/menu.model';
import { ModalOptions,BsModalRef, BsModalService } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-krareporting-approval',
  templateUrl: './krareporting-approval.component.html',
  styleUrls: ['./krareporting-approval.component.css']
})
export class KrareportingApprovalComponent implements OnInit 
{
  KRAMasterModel : KRAMasterModel= new KRAMasterModel();
  KRAApplicationModel : KRAApplicationModel= new KRAApplicationModel();
  EmployeeDetails: KRAApplicationModel[] = [];
  employee: any;
  KRAApplications: KRAApplicationModel[] = [];
  kraapplication: any;
  
  RefId : any;
  MasterRefId : any;
  userClaims: any;

  Result : string;
  SuccessStatus:boolean=false;
  SuccessMessage: string ="";
  public tableWidget: any;
  selectedIndex : number;
  // Begning Permission Portion    
  SelectedMenu:Menu;
  IsDataIsInViewState : boolean=false;
  CanViewState : boolean=true;
  IsApplicationView : boolean=false;
  DisableSave : boolean=false;
  Totalwts : number=0;
  TotalSelfRating : number=0;
  TotalRHRating : number=0;
  // End of the permission portion
  //For Pop up
  ApprovedMsg : boolean= false;
  ShowPopup : boolean= false;
  public modalRef: BsModalRef;
  public modelopt:ModalOptions;
  NoRecordMsg : boolean;

  constructor(private KRAService: KRAService, private rout: Router, private userService: UserService,private modalService: BsModalService) 
  {  
    this.modelopt=new ModalOptions();
    this.modelopt.class='heavy_modal';
    this.modalService.config= this.modelopt;
    this.modelopt.ignoreBackdropClick=true;
  }

  ngOnInit() 
  {
    // Begning Permission Portion 
    this.SelectedMenu=this.userService.SelectedMenu;
    if(this.SelectedMenu !=undefined)
    {
      localStorage.setItem('selectedmenu',JSON.stringify(this.SelectedMenu));
      this.rout.navigate([this.SelectedMenu.RouteName]);
    }
    else
    {
      if(JSON.parse(localStorage.getItem('selectedmenu'))!=undefined)
      {
        this.SelectedMenu = JSON.parse(localStorage.getItem('selectedmenu'));
        this.rout.navigate([this.SelectedMenu.RouteName]);
      }
    }
    if(this.SelectedMenu.CanAdd)
    {
      this.IsDataIsInViewState=false;
    }
    else
    {
      this.IsDataIsInViewState=true;
    }
    if(this.SelectedMenu.CanView)
    {
      this.CanViewState=false;
    }
    else
    {
      this.CanViewState=true;
    }
    // End of the permission portion
    this.DisableSave=true;
    this.ApprovedMsg=false;
    this.ShowPopup=false;
    this.Totalwts=0;
    this.GetEmployeeDetailsForApproval();
    this.NoRecordMsg= false;
  }

  GetEmployeeDetailsForApproval()
  {
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.KRAService.GetKRAEmployeeDetailsForRHApproval( t).then(x => 
    {
      this.EmployeeDetails=[];
      this.NoRecordMsg= false;
      this.employee = x;
      Object.keys(this.employee).forEach( key => 
      {
        this.employee[key];
        let KRAApplicationtemp = new KRAApplicationModel();
        KRAApplicationtemp = this.employee[key];
        this.EmployeeDetails.push(KRAApplicationtemp);
      });
      if(this.EmployeeDetails.length==0)
      {
        this.NoRecordMsg= true;
      }
    });
  }
  
  GetEmployeeDetails(EmployeeId)
  {
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.KRAService.GetEmployeeDetailsBYEmployeeId( t,EmployeeId).then(x => 
    {
      this.employee = x;
      Object.keys(this.employee).forEach( key => 
      {
        this.employee[key];
        let KRAApplicationtemp = new KRAApplicationModel();
        KRAApplicationtemp = this.employee[key];
        this.KRAApplicationModel.EmployeeId=KRAApplicationtemp.EmployeeId;
        this.KRAApplicationModel.EmployeeCode=KRAApplicationtemp.EmployeeCode;
        this.KRAApplicationModel.EmployeeName=KRAApplicationtemp.EmployeeName;
        this.KRAApplicationModel.EmployeeEmailId=KRAApplicationtemp.EmployeeEmailId;
        this.KRAApplicationModel.DesignationName=KRAApplicationtemp.DesignationName;
        this.KRAApplicationModel.RHId=KRAApplicationtemp.RHId;
        this.KRAApplicationModel.RHCode=KRAApplicationtemp.RHCode;
        this.KRAApplicationModel.RHName=KRAApplicationtemp.RHName;
        this.KRAApplicationModel.RHEmailId=KRAApplicationtemp.RHEmailId;
        this.KRAApplicationModel.FHId=KRAApplicationtemp.FHId;
        this.KRAApplicationModel.FHCode=KRAApplicationtemp.FHCode;
        this.KRAApplicationModel.FHName=KRAApplicationtemp.FHName;
        this.KRAApplicationModel.FHEmailId=KRAApplicationtemp.FHEmailId;
        this.KRAApplicationModel.CompanyId=KRAApplicationtemp.CompanyId;
        this.KRAApplicationModel.SentMailid=KRAApplicationtemp.SentMailid;
        this.KRAApplicationModel.SentMailPassword=KRAApplicationtemp.SentMailPassword;
        this.KRAApplicationModel.HRISURL=KRAApplicationtemp.HRISURL;
      });
    });
  }

  GetKRAApplication(EmployeeId,MasterRefId,template) 
  { 
    this.GetEmployeeDetails(EmployeeId);
    this.KRAApplications=[];
    // Begning of the permission portion
    this.IsDataIsInViewState=false;
    // End of the permision portion
    this.KRAApplicationModel.IsActive=false;
    let t = localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.KRAService.GetKRAApplicationForRHApprovalByMasterRefId( t,MasterRefId).then(x => 
    {
      if(this.ShowPopup==true)
      {
        this.modalRef.hide();
      }
      this.modalRef = this.modalService.show(template,this.modelopt);
      this.ShowPopup=true;
      this.ApprovedMsg=false;

      this.Totalwts=0;
      this.TotalSelfRating= 0;
      this.TotalRHRating= 0;
      this.RefId = x;
      if(this.RefId.length > 0)
      {
        this.KRAApplications=[];
      }
      Object.keys(this.RefId).forEach( key => 
      {
        // tslint:disable-next-line:no-unused-expression
        this.RefId[key];
        let KRAApplicationtemp = new KRAApplicationModel();
        KRAApplicationtemp = this.RefId[key];
        this.KRAApplicationModel.MasterRefId=MasterRefId;
        this.Totalwts=this.Totalwts + KRAApplicationtemp.Weighted;
        this.TotalSelfRating=  Number(this.TotalSelfRating) + (Number(KRAApplicationtemp.SelfRating/5) * (KRAApplicationtemp.Weighted));
        this.TotalRHRating=  Number(this.TotalRHRating) + (Number(KRAApplicationtemp.RHRating/5) * (KRAApplicationtemp.Weighted));
        if(KRAApplicationtemp.RHRating==0)
        {
          KRAApplicationtemp.RHRating=null;
        }
        this.KRAApplications.push(KRAApplicationtemp);
      });
      this.KRAApplicationModel.TotalWateges=this.Totalwts;
      this.KRAApplicationModel.FinalSelfRating=Math.round(this.TotalSelfRating);
      this.KRAApplicationModel.FinalRHRating=Math.round(this.TotalRHRating);
    });
  }

  SaveKRAApplication(template) :void
  {
    this.DisableSave=false;
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.KRAService.RHApprovedKRAApplicationMaster(t,this.KRAApplicationModel).subscribe(res=>
    {
      this.MasterRefId=res;
      let t =  localStorage.getItem('userToken');
      t = 'Bearer' + t;    
      var j=0;
      var l=this.KRAApplications.length;
      this.KRAApplications.forEach(item => 
      {
        j=j+1; 
        if(item.RefId==null)
        {
          item.RefId=0;
        }
        if(item.RefId > 0)
        {
          this.KRAService.RHApprovedKRAApplication(t ,item).subscribe(res=>
          {
            this.RefId=res;
            this.EmployeeDetails=[];     
          });                  
        } 
        if(l == j)
        {
          this.modalRef.hide();
          this.ApprovedMsg=true;
          this.ShowPopup=false;
          this.modalRef = this.modalService.show(template);
          this.GetEmployeeDetailsForApproval();
          this.DisableSave=true;
        }                             
      });
    });
  }

  RefreshPage()
  {
    this.rout.navigateByUrl('/AdminApproveOrReject/KRAFUNCTIONAL', {skipLocationChange: true}).then(()=>this.rout.navigate(["/AdminApproveOrReject/KRAREPORTING"]));
  }

  RHRatingChanged()
  {
    this.TotalRHRating=0;
    var j=0;
    var l=this.KRAApplications.length;
    this.KRAApplications.forEach(item => 
    {
      j=j+1;
      if(item.RHRating > 0)
      { 
        this.TotalRHRating=  Number(this.TotalRHRating) + (Number(item.RHRating/5) * (item.Weighted));
      }
      this.KRAApplicationModel.FinalRHRating=Math.round(this.TotalRHRating);
    });
  }
}