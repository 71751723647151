import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs/Subject';
import { DataTableDirective } from 'angular-datatables';
import { LevelService }  from '../../shared/HR_ADMIN/Lavels_Grades/Levels.service';
import { DecisionMaking } from  '../../shared/HR_ADMIN/Lavels_Grades/DecisionMaking.Model';
import { Level } from  '../../shared/HR_ADMIN/Lavels_Grades/Level.Model';
import { UserService } from '../../shared/user.service';
// Begning Confirmation
import { ConfirmationService } from '@jaspero/ng2-confirmations';
import { ResolveEmit } from '../../../app/resolve-emit';
import { ConfirmSettings } from '../../../app/confirm-settings';
// end of Confirmation
import { Menu } from '../../shared/menu.model';

@Component({
  selector: 'app-manage-levels',
  templateUrl: './manage-levels.component.html',
  styleUrls: ['./manage-levels.component.css']
})
export class ManageLevelsComponent implements OnInit
{
  LevelModel : Level= new Level();
  userClaims: any;
  Result : string;
  SuccessStatus:boolean=false;
  SuccessMessage: string ="";
  public tableWidget: any;
  Levels: Level[] = [];
  Level:any;
  LevelId : any;
  Decisions: DecisionMaking[] = [];
  Decision: any;
  tempLeveladd : Level =new Level();
  // Begning Permission Portion    
  SelectedMenu:Menu;
  IsDataIsInViewState : boolean=false;
  CanViewState : boolean=false;
  DisableSave : boolean=false;
  // End of the permission portion
  //check for duplicate Name
  CheckNameExiststatus : boolean=null;  
  CheckNamestatusedit : boolean =false;
  Namecheck : string ="";
  checkName : any;
  checkNameval : string ="";
  //check for duplicate Name

  constructor(private LevelService: LevelService, private rout: Router,
     private _confirmation: ConfirmationService, private userService: UserService) { }

  @ViewChild(DataTableDirective, {static: false})
  setDtOptions: DataTables.Settings = {};
  cardDtOptions: DataTables.Settings = {};
  dtElement: DataTableDirective;
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();

  ngOnInit() 
  {
    // Begning Permission Portion 
    this.SelectedMenu=this.userService.SelectedMenu;
    if(this.SelectedMenu !=undefined)
    {
      localStorage.setItem('selectedmenu',JSON.stringify(this.SelectedMenu));
      this.rout.navigate([this.SelectedMenu.RouteName]);
    }
    else
    {
      if(JSON.parse(localStorage.getItem('selectedmenu'))!=undefined)
      {
        this.SelectedMenu = JSON.parse(localStorage.getItem('selectedmenu'));
        this.rout.navigate([this.SelectedMenu.RouteName]);
      }
    }
    if(this.SelectedMenu.CanAdd)
    {
       this.IsDataIsInViewState=false;
    }
    else
    {
      this.IsDataIsInViewState=true;
    }
    if(this.SelectedMenu.CanView)
    {
      this.CanViewState=false;
    }
    else
    {
      this.CanViewState=true;
    }
    // End of the permission portion
    this.DisableSave=true;
    this.getDecisionMaking();
    this.LevelModel.DecisionId=0;
    if(localStorage.getItem('SuccessfullMessage'))
    {
      this.SuccessStatus=true;
      this.SuccessMessage =localStorage.getItem('SuccessfullMessage')
      localStorage.removeItem('SuccessfullMessage');
    }
  }

  settings: ConfirmSettings | any = 
  {
    overlay: true,
    overlayClickToClose: true,
    showCloseButton: true,
    confirmText: 'Yes',
    declineText: 'No'
  };
  
  ngAfterViewInit(): void 
  {
    this.getLevels();
    this.dtOptions = 
    {
      pagingType: 'full_numbers',
      pageLength: 4
    };
  }

  getDecisionMaking() 
  {
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.LevelService.GetDecisionMaking( t).then(x => 
    {
      this.Decision = x;
      Object.keys(this.Decision).forEach( key => 
      {
        // tslint:disable-next-line:no-unused-expression
        this.Decision[key];
        let Decisiontemp = new DecisionMaking();
        Decisiontemp = this.Decision[key];
        this.Decisions.push(Decisiontemp);
      });
    });      
  }

  getLevels()
  {
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.LevelService.GetLevel( t).then(x => 
    {
      this.Level = x;
      Object.keys(this.Level).forEach( key => 
      {
        this.Level[key];
        let Leveltemp = new Level();
        Leveltemp = this.Level[key];
        this.Levels.push(Leveltemp);
      });
      this.dtTrigger.next();
    });
  }

  GetLevelByid(id) 
  {
    // begning of the permission portion
    this.IsDataIsInViewState=false;
    // end of the permission portion
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.LevelService.GetLevelByid( t,id).then(x => 
    {
      this.Level = x;
      Object.keys(this.Level).forEach( key => 
      {
        this.Level[key];
        let Leveltemp = new Level();
        Leveltemp = this.Level[key];
        this.LevelModel.LevelId=Leveltemp.LevelId;
        this.LevelModel.LevelCode=Leveltemp.LevelCode;
        this.LevelModel.LevelName=Leveltemp.LevelName;
        this.LevelModel.DecisionId=Leveltemp.DecisionId;
      });
      // Begin for check Name duplication
      this.checkNameval= this.LevelModel.LevelName;
      this.CheckNamestatusedit=true;
      this.CheckNameExiststatus =true;
      this.Namecheck=""
      // End for check Name duplication
    });
  }

  ViewLevelByid(id) 
  {
    // begning of the permission portion
    this.IsDataIsInViewState=true;
    // end of the permission portion
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.LevelService.GetLevelByid( t,id).then(x => 
    {
      this.Level = x;
      Object.keys(this.Level).forEach( key => 
      {
        this.Level[key];
        let Leveltemp = new Level();
        Leveltemp = this.Level[key];
        this.LevelModel.LevelId=Leveltemp.LevelId;
        this.LevelModel.LevelCode=Leveltemp.LevelCode;
        this.LevelModel.LevelName=Leveltemp.LevelName;
        this.LevelModel.DecisionId=Leveltemp.DecisionId;
      });
      // Begin for check Name duplication
      this.checkNameval= this.LevelModel.LevelName;
      this.CheckNamestatusedit=true;
      this.CheckNameExiststatus =true;
      this.Namecheck=""
      // End for check Name duplication
    });
  }
     
  saveLevel() :void
  {
    this.DisableSave=false;
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.LevelModel.IsActive=true;
    if(this.LevelModel.LevelId > 0)
    {
      this.LevelService.UpdateLevel(t ,this.LevelModel).subscribe(res=>
      {
        this.LevelId=res;
        this.LevelModel.LevelId=0;  
        if(this.LevelId>0)
        {
          localStorage.setItem('SuccessfullMessage', "Update Successfully");
          this.rout.navigateByUrl('/Levels/ManageDescitionMakingLevels', {skipLocationChange: true}).then(()=>this.rout.navigate(["/Levels/ManageLevels"]));
        }
      });                  
    }
    else
    {
      this.LevelService.AddLevel(t ,this.LevelModel).subscribe(res=>
      {
        this.LevelId=res;
        this.LevelModel.LevelId=0;              
        if(this.LevelId>0)
        {
          localStorage.setItem('SuccessfullMessage', "Save Successfully");
          this.rout.navigateByUrl('/Levels/ManageDescitionMakingLevels', {skipLocationChange: true}).then(()=>this.rout.navigate(["/Levels/ManageLevels"]));
        }
      }); 
    }
  }

  DeleteLevelByid(LevelId : number) :void
  {
    this._confirmation.create('Confirm to Deleate', 'Press Yes to Delete', this.settings)
    .subscribe((ans: ResolveEmit) => {
      if (ans.resolved == true) {

        let t =  localStorage.getItem('userToken');
      t = 'Bearer' + t;
      this.LevelModel.IsActive=true;
      if( LevelId > 0)
      {
        this.LevelService.DeleteLevel(t ,LevelId).subscribe(res=>
        {
          this.LevelId=res;
          this.LevelModel.LevelId=0;                                  
          if(this.LevelId >0)
          {
            localStorage.setItem('SuccessfullMessage', "Deleted Successfully");
            this.rout.navigateByUrl('/Levels/ManageDescitionMakingLevels', {skipLocationChange: true}).then(()=>this.rout.navigate(["/Levels/ManageLevels"]));
          }
        });                  
      }
         
      }
    })



  }

  RefreshPage()
  {
    this.rout.navigateByUrl('/Levels/ManageDescitionMakingLevels', {skipLocationChange: true}).then(()=>this.rout.navigate(["/Levels/ManageLevels"]));
  }

  // begning of check duplicate Name
  CheckName()
  {
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    if(!this.CheckNamestatusedit)
    {
      this.LevelService.CheckLevelName( t, this.LevelModel.LevelName, this.LevelModel.DecisionId).then(x => 
      {
        this.checkName = x;
        if( this.checkName.length >0)
        {
          this.CheckNameExiststatus =false;
          this.Namecheck="Exist"
        }
        else
        {
          this.CheckNameExiststatus =true;
          this.Namecheck="";
        }
     });
    }
    else
    {
      if(this.LevelModel.LevelName !=this.checkNameval )
      {
        this.LevelService.CheckLevelName( t, this.LevelModel.LevelName, this.LevelModel.DecisionId).then(x => 
        {
          this.checkName = x;
          if( this.checkName.length >0)
          {
            this.CheckNameExiststatus =false;
            this.Namecheck="Exist"
          }
          else
          {
            this.CheckNameExiststatus =true;
            this.Namecheck="";
          }
        });
      }
      else
      {
        this.CheckNameExiststatus =true;
        this.Namecheck="";
      }
    }
  }
  // end of duplicate Name
}
