<div class="body-content-area tabcontent" id="link1">
  <div class="row">
    <div class="col-lg-9">
      <div class="row">
        <div class="col-lg-1 low-padding text-center">
          <!-- <img *ngIf="this.SelectedMenu.Image!=null" src={{this.SelectedMenu.Image}} class="body-icon" alt="Icon"/> -->
        </div>
        <div class="col-lg-10 clear-padding">
          <span class="page-title">TDS Surcharge Slab</span>
          <small class="page-description"></small>
        </div>
      </div>
    </div>
  </div>
  
  <div *ngIf="!(this.CanViewState)">
    <div>
      <app-dialog [(visible)]="this.SuccessStatus">
        <span class="successmsg">{{this.SuccessMessage}}</span>
      </app-dialog>
    </div>

    <fieldset class="body-form-control" *ngIf="(this.IsFormViewShow)">
      <legend class="legend--">TDS Surcharge Slab</legend>
      <form #tdssurchargeSlabForm="ngForm" (ngSubmit)="SaveTDSSurchargeSlab()">
        <div lass="row" >
          <table id="tblTDSSurcharge" class="table table-striped table-bordered text-left table-new row-border hover">
              <thead class="thead-blue text-center">
                  <tr>
                    <th style="width: 15%;">From Amount </th>
                    <th style="width: 15%;">To Amount</th>
                    <th style="width: 20%;">Percentage</th>
                  </tr>
              </thead>
              <tbody>
                <tr *ngFor="let Surcharge of TDSSurcharges, let i = index">
                <td>
                    <input type="text" [(ngModel)]="Surcharge.FromAmount" id ="FromAmount_{{i}}" name="FromAmount_{{i}}" #FromAmount="ngModel" class="form-control form-control-custom form-control-custom-new">
                </td>
                <td>
                  <input type="text" [(ngModel)]="Surcharge.ToAmount" id ="ToAmount_{{i}}" name="ToAmount_{{i}}" #ToAmount="ngModel" class="form-control form-control-custom form-control-custom-new">
                </td>
                <td>
                  <input type="text" [(ngModel)]="Surcharge.Percentage" id ="Percentage_{{i}}" name="Percentage_{{i}}" #Percentage="ngModel" class="form-control form-control-custom form-control-custom-new">
                </td>
              </tr>
            </tbody>
          </table>
          
          <div class="row float-right">
            <div class="form-group row">
              <label>Applicable Date :</label>
              <div class="col-sm-7 clear-left-padding">
                <input type="date" name="ApplicableFromDate" [ngModel]="TDSSurchargeModel.ApplicableFromDate | date:'yyyy-MM-dd'" (ngModelChange)="dateChanged($event)" #ApplicableFromDate="ngModel" required/>
                <label *ngIf="(!validdate)" class="required-validation">Select Valid Date</label>
              </div>
            </div>
            <div  class=" col-auto text-center">
              <button type="submit" class="btn btn-primary save-button" [disabled]="!(tdssurchargeSlabForm.valid && this.validdate == true && this.DisableSave==true)">Save</button>  
            </div>
            <div class=" col-auto text-center">
              <button type="button" class="btn btn-danger cancle-button" (click)="RefreshPage()">Cancel</button>
            </div>
            <div class=" col-auto text-center">
              <button type="button" class="btn btn-danger cancle-button" (click)="AddnweRow()">Add Row </button>
            </div>
          </div>
        </div>
      </form>
      <br>
    </fieldset>
  </div>
</div>