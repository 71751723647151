<div class="body-content-area tabcontent" id="link1">
  <div class="row">
    <div class="col-lg-9">
      <div class="row">
        <div class="col-lg-1 low-padding text-center">
          <!-- <img *ngIf="this.SelectedMenu.Image!=null" src={{this.SelectedMenu.Image}} class="body-icon" alt="Icon"/> -->
        </div>
        <div class="col-lg-10 clear-padding">
          <div>
            <span class="page-title">Employee Tour Application</span>
            <small class="page-description"></small>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="!(this.CanViewState)">
    <app-dialog [(visible)]="this.SuccessStatus">
      <span class="successmsg">{{this.SuccessMessage}}</span>
    </app-dialog>
    <fieldset class="body-form-control" > 
      <legend class="legend--">Employee Details</legend>
      <form #EmployeeDirectDutyForm="ngForm">
        <div class="row">
          <div class="col-lg-4">
            <div class="form-group row">
              <label class="col-sm-2 col-form-label clear-padding-right">Employee</label>
              <div class="col-sm-9 clear-left-padding">
                <angular2-multiselect tabindex="1" class="form-control" [data]="emp" [(ngModel)]="selectedItems" name="EmployeeId" [settings]="EmployeeSettings"
                  (onSelect)="onItemSelect($event)" (onDeSelect)="OnItemDeSelect($event)" (onSelectAll)="onSelectAll($event)" (onDeSelectAll)="onDeSelectAll($event)">
                </angular2-multiselect>
              </div>
              <div class="required-validation" *ngIf="TourModel.EmployeeId == 0">Employee is required</div>
            </div>
          </div>
        </div>
      </form>    
    </fieldset>

    <fieldset *ngIf="(IsApplicationView)" class="body-form-control" > 
      <legend class="legend--">Tour Application</legend>
      <form #EmployeeTourDetails="ngForm">
        <div lass="row" >
          <table id="tblTourDetails" class="table table-striped table-bordered text-left table-new row-border hover">
            <thead class="thead-blue text-center">
              <tr>
                <th>Sl.No.</th>
                <th>Application Date</th>
                <th>From Date</th>
                <th>To Date</th>
                <th class="text-left">Tour Place</th>
                <th class="text-left">Remarks</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let EmpTour of TourApplication, let i = index">
                <td scope="row">{{i+1}}</td>
                <td class="text-center">{{EmpTour.ApplyDate| date : "dd-MMM-yyyy"}}</td>
                <td class="text-center">{{EmpTour.FromDate| date : "dd-MMM-yyyy"}}</td>
                <td class="text-center">{{EmpTour.ToDate| date : "dd-MMM-yyyy"}}</td>
                <td class="text-left">{{EmpTour.TourPlace}}</td>
                <td class="text-left">{{EmpTour.Remarks}}</td>
                <td *ngIf="EmpTour.IsApproved==true">Approved</td>
                <td *ngIf="EmpTour.IsRejected==true">Rejected</td>
                <td *ngIf="EmpTour.IsApproved==false && EmpTour.IsRejected==false">Pending</td>
              </tr>
            </tbody>
          </table>
        </div>
      </form>
      <form #EmployeeTour="ngForm" (ngSubmit)="SaveEmployeeTour()">
        <div class="row"> 
          <div class="col-lg-4">
            <div class="col-body-border-right">
              <div class="form-group row">
                <label  class="col-sm-4 col-form-label clear-padding-right">Employee Code</label>
                <div class="col-sm-7 clear-left-padding">
                  :&nbsp;&nbsp;{{this.TourModel.EmployeeCode}}
                </div>
              </div>
              <div class="form-group row">
                <label  class="col-sm-4 col-form-label clear-padding-right">From Date</label>
                <div class="col-sm-7 clear-left-padding">
                  :&nbsp;&nbsp;<input type="date" tabindex="1" style="width:90%;" name="FromDate" [ngModel]="this.TourModel.FromDate | date:'yyyy-MM-dd'" (ngModelChange)="FromDateChanged($event)" #FromDate="ngModel" required/>
                </div>
                <div class="required-validation" *ngIf="validFromdate">From Date is not Valid Date.</div>
                <div class="required-validation" *ngIf="validdate">Please select Valid Date.</div>
              </div>
              <div class="form-group row">
                <label class="col-sm-4 col-form-label clear-padding-right">Tour Place</label>
                <div class="col-sm-7 clear-left-padding">
                    <input type="text" tabindex="3" [(ngModel)]="TourModel.TourPlace" id ="TourPlace" name="TourPlace" #TourPlace="ngModel" required class="form-control form-control-custom form-control-custom-new">
                </div>
                <div *ngIf="TourPlace.invalid && (TourPlace.dirty || TourPlace.touched)" class="alert alert-danger">
                  <div *ngIf="TourPlace.errors.required">Place is required.</div>
                  <div *ngIf="TourPlace.errors.maxlength">Place is not more than 500 characters</div>
                </div>
              </div>         
            </div>  
          </div>
  
          <div class="col-lg-4">
            <div class="col-body-border-right">
              <div class="form-group row">
                <label  class="col-sm-4 col-form-label clear-padding-right">Name</label>
                <div class="col-sm-7 clear-left-padding">
                  :&nbsp;&nbsp;{{this.TourModel.EmployeeName}}
                </div>
              </div>
              <div class="form-group row">
                <label  class="col-sm-4 col-form-label clear-padding-right">To Date</label>
                <div class="col-sm-7 clear-left-padding">
                  :&nbsp;&nbsp;<input type="date" tabindex="2" style="width:90%;" name="ToDate" [ngModel]="this.TourModel.ToDate | date:'yyyy-MM-dd'" (ngModelChange)="ToDateChanged($event)" #ToDate="ngModel" required/>
                </div>
                <div class="required-validation" *ngIf="validTodate">To Date is not Valid Date.</div>
                <div class="required-validation" *ngIf="validdate">Please select Valid Date.</div>
              </div>
            </div> 
          </div>
  
          <div class="col-lg-4">
            <div class="form-group row">
              <label  class="col-sm-4 col-form-label clear-padding-right">Approver</label>
              <div class="col-sm-7 clear-left-padding">
                :&nbsp;&nbsp;{{this.TourModel.RHCode}}-{{this.TourModel.RHName}} 
              </div>
            </div>
            <div class="form-group row">
              <label class="col-sm-4 col-form-label clear-padding-right">Approver EMail</label>
              <div class="col-sm-7 clear-left-padding">
                :&nbsp;&nbsp;{{this.TourModel.RHEmailId}} 
              </div>
            </div>
            <div class="form-group row">
              <label class="col-sm-6 col-form-label text-center clear-padding-right">
                <button type="submit" tabindex="4" class="btn btn-primary save-button" [disabled]="!(EmployeeTour.valid && TourModel.TourPlace.length >0 
                && this.validFromdate == false && this.validTodate == false && this.ShowMessage== false
                && this.validdate==false && this.DisableSave==true)">Save</button>  
              </label>
              <div class="col-sm-6 col-form-label text-center">
                <button type="button" tabindex="5" (click)="RefreshPage()" class="btn btn-danger cancle-button">Cancel</button>
              </div>
            </div>
            <div class="form-group row">
              <div class="alert alert-danger" *ngIf="ShowMessage">Application has been Already Applied.</div>
            </div>
          </div> 
        </div> 
      </form>
    </fieldset>
  </div>
</div>