import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs/Subject';
import { DataTableDirective } from 'angular-datatables';
import { ReplaySubject } from "rxjs/ReplaySubject";
import { UserService } from '../../shared/user.service';
import { UserMasterService } from '../../shared/HR_ADMIN/UserMaster/UserMasterService.service';
import { ModuleModel } from  '../../shared/HR_ADMIN/UserMaster/ModuleMasterModel.Model';
// Begning Confirmation
import { ConfirmationService } from '@jaspero/ng2-confirmations';
import { ResolveEmit } from '../../../app/resolve-emit';
import { ConfirmSettings } from '../../../app/confirm-settings';
import { Menu } from '../../shared/menu.model';
// end of Confirmation

@Component({
  selector: 'app-module-master',
  templateUrl: './module-master.component.html',
  styleUrls: ['./module-master.component.css']
})

export class ModuleMasterComponent implements OnInit
{
  ModuleModel : ModuleModel= new ModuleModel();
  userClaims: any;
  ModuleMasters: ModuleModel[] = [];
  ModuleMaster: any;
  Result : string;
  SuccessStatus:boolean=false;
  SuccessMessage: string ="";
  public tableWidget: any;
  ModuleId : any;
  SubMenues : Menu[]=[];
  Mod : any;
  base64textString : string;
  tempModuleMasteradd : ModuleModel =new ModuleModel();

  // Begning Permission Portion    
  SelectedMenu:Menu;
  IsDataIsInViewState : boolean=false;
  CanViewState : boolean= false;
  DisableSave :boolean=false;
  // End of the permission portion
  //check for duplicate component Name
  CheckNameExiststatus : boolean=null;  
  CheckNamestatusedit : boolean =false;
  Namecheck : string ="";
  checkName : any;
  checkNameval : string ="";
  //check for duplicate component Name
  //image
  filetoupload: File=null;

  constructor(private UserMasterService: UserMasterService, private rout: Router,  private _confirmation: ConfirmationService, private userService: UserService) { }
  
  @ViewChild(DataTableDirective, {static: false})
  setDtOptions: DataTables.Settings = {};
  cardDtOptions: DataTables.Settings = {};
  dtElement: DataTableDirective;
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();
  
  ngOnInit() 
  {
    // Begning Permission Portion 
    this.SubMenues=[];
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    let menuid = localStorage.getItem("menuidstor");
    this.userService.GetSubMenuList( t, menuid).then(x => 
    {
      this.Mod = x;
      Object.keys(this.Mod).forEach( key => 
      {
        // tslint:disable-next-line:no-unused-expression
        this.Mod[key];
        let menutemp = new Menu();
        menutemp = this.Mod[key];
        this.SubMenues.push(menutemp);
      });
      this.userService.SelectedMenu= this.SubMenues[0];
      this.rout.navigate([this.userService.SelectedMenu.RouteName]);
      this.SelectedMenu=this.userService.SelectedMenu;
      if(this.SelectedMenu.CanAdd)
      {
        this.IsDataIsInViewState=false;
      }
      else
      {
        this.IsDataIsInViewState=true;
      }
      if(this.SelectedMenu.CanView)
      {
        this.CanViewState=false;
      }
      else
      {
        this.CanViewState=true;
      }
    });
    // End of the permission portion
    this.DisableSave=true;
    this.dtOptions = 
    {
      pagingType: 'full_numbers',
      pageLength: 5
    };
    if(localStorage.getItem('SuccessfullMessage'))
    {
      this.SuccessStatus=true;
      this.SuccessMessage =localStorage.getItem('SuccessfullMessage')
      localStorage.removeItem('SuccessfullMessage');
    }  
  }

  ngAfterViewInit(): void 
  {
    this.GetModuleMaster();
    this.ModuleModel.IconUrlvalidfile=true;
    this.ModuleModel.SmallIconUrlvalidfile=true;
    this.dtOptions = 
    {
      pagingType: 'full_numbers',
      pageLength: 5
    };
  }

  settings: ConfirmSettings | any = 
  {
    overlay: true,
    overlayClickToClose: true,
    showCloseButton: true,
    confirmText: 'Yes',
    declineText: 'No'
  };
  
  GetModuleMaster()
  {
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.UserMasterService.GetModuleMaster( t).then(x => 
    {
      this.ModuleMaster = x;
      Object.keys(this.ModuleMaster).forEach( key => 
      {
        this.ModuleMaster[key];
        let ModuleMastertemp = new ModuleModel();
        ModuleMastertemp = this.ModuleMaster[key];
        this.ModuleMasters.push(ModuleMastertemp);
      });
      this.dtTrigger.next();
    });
  }
  
  GetModuleMasterById(id) 
  { 
    // Begning of the permission portion
    this.IsDataIsInViewState=false;
    // End of the permision portion
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.UserMasterService.GetModuleMasterById( t,id).then(x => 
    {
      this.ModuleId = x;
      Object.keys(this.ModuleId).forEach( key => 
      {
        // tslint:disable-next-line:no-unused-expression
        this.ModuleId[key];
        let ModuleMastertemp = new ModuleModel();
        ModuleMastertemp = this.ModuleId[key];
        this.ModuleModel.ModuleId=ModuleMastertemp.ModuleId;
        this.ModuleModel.ModuleName=ModuleMastertemp.ModuleName;
        this.ModuleModel.IconUrl=ModuleMastertemp.IconUrl;
        this.ModuleModel.IconUrlLogoType=ModuleMastertemp.IconUrlLogoType;
        this.ModuleModel.IconUrlImage=ModuleMastertemp.IconUrlImage;
        this.ModuleModel.SmallIconUrl=ModuleMastertemp.SmallIconUrl;
        this.ModuleModel.SmallIconUrlLogoType=ModuleMastertemp.SmallIconUrlLogoType;
        this.ModuleModel.SmallIconUrlImage=ModuleMastertemp.SmallIconUrlImage;
      });
      // Begin for check Name duplication
      this.checkNameval= this.ModuleModel.ModuleName;
      this.CheckNamestatusedit=true;
      this.CheckNameExiststatus =true;
      this.Namecheck=""
      // End for check Name duplication
    });
  }

  ViewModuleMasterById(id) 
  {
    //begin permission
    this.IsDataIsInViewState=true;
    //end permission
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.UserMasterService.GetModuleMasterById( t,id).then(x => 
    {
      this.ModuleId = x;
      Object.keys(this.ModuleId).forEach( key => 
      {
        this.ModuleId[key];
        let ModuleMastertemp = new ModuleModel();
        ModuleMastertemp = this.ModuleId[key];
        this.ModuleModel.ModuleId=ModuleMastertemp.ModuleId;
        this.ModuleModel.ModuleName=ModuleMastertemp.ModuleName;
        this.ModuleModel.IconUrl=ModuleMastertemp.IconUrl;
        this.ModuleModel.IconUrlLogoType=ModuleMastertemp.IconUrlLogoType;
        this.ModuleModel.IconUrlImage=ModuleMastertemp.IconUrlImage;
        this.ModuleModel.SmallIconUrl=ModuleMastertemp.SmallIconUrl;
        this.ModuleModel.SmallIconUrlLogoType=ModuleMastertemp.SmallIconUrlLogoType;
        this.ModuleModel.SmallIconUrlImage=ModuleMastertemp.SmallIconUrlImage;
      });
      // Begin for check Name duplication
      this.checkNameval= this.ModuleModel.ModuleName;
      this.CheckNamestatusedit=true;
      this.CheckNameExiststatus =true;
      this.Namecheck=""
      // End for check Name duplication
    });
  }

  saveModuleMaster() :void
  {
    this.DisableSave=false;
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.ModuleModel.IsActive=true;
    if(this.ModuleModel.ModuleId > 0)
    {
      this.UserMasterService.UpdateModuleMaster(t ,this.ModuleModel).subscribe(res=>
      {
        this.ModuleId=res;
        this.ModuleModel.ModuleId=0;  
        if(this.ModuleId>0)
        {
          localStorage.setItem('SuccessfullMessage', "Update Successfully");
          this.rout.navigateByUrl('/ManageUserMaster/MenuMaster', {skipLocationChange: true}).then(()=>this.rout.navigate(["/ManageUserMaster/ModuleMaster"]));
        }
      });                  
    }
    else
    {
      this.UserMasterService.AddModuleMaster(t ,this.ModuleModel).subscribe(res=>
      {
        this.ModuleId=res;
        this.ModuleModel.ModuleId=0;              
        if(this.ModuleId>0)
        {
          localStorage.setItem('SuccessfullMessage', "Save Successfully");
          this.rout.navigateByUrl('/ManageUserMaster/MenuMaster', {skipLocationChange: true}).then(()=>this.rout.navigate(["/ManageUserMaster/ModuleMaster"]));
        }
      }); 
    }
  }

  DeleteModuleMasterById(ModuleId : number) :void
  {
    if(this.SelectedMenu.CanAdd)
    {
     this.IsDataIsInViewState=false;
    }
    else
    {
      this.IsDataIsInViewState=true;
    }
    this._confirmation.create('Confirm to Deleate', 'Press Yes to Delete', this.settings)
      .subscribe((ans: ResolveEmit) => 
      {
        if (ans.resolved == true) 
        {
          let t =  localStorage.getItem('userToken');
          t = 'Bearer' + t;
          this.ModuleModel.IsActive=true;
          if( ModuleId > 0)
          {
            this.UserMasterService.DeleteModuleMaster(t ,ModuleId).subscribe(res=>
            {
              this.ModuleId=res;
              this.ModuleModel.ModuleId=0;                                  
              if(this.ModuleId >0)
              {
                localStorage.setItem('SuccessfullMessage', "Deleted Successfully");
                this.rout.navigateByUrl('/ManageUserMaster/MenuMaster', {skipLocationChange: true}).then(()=>this.rout.navigate(["/ManageUserMaster/ModuleMaster"]));
              }   
            });                  
          }
        }
      })
  }
 
  // begning of check duplicate componentcode
  CheckName()
  {
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    if(!this.CheckNamestatusedit)
    {
      this.UserMasterService.CheckModuleName( t, this.ModuleModel.ModuleName).then(x => 
      {
        this.checkName = x;
        if( this.checkName.length >0)
        {
          this.CheckNameExiststatus =false;
          this.Namecheck="Exist"
        }
        else
        {
          this.CheckNameExiststatus =true;
          this.Namecheck="";
        }
     });
    }
    else
    {
      if(this.ModuleModel.ModuleName !=this.checkNameval )
      {
        this.UserMasterService.CheckModuleName( t, this.ModuleModel.ModuleName).then(x => 
        {
          this.checkName = x;
          if( this.checkName.length >0)
          {
            this.CheckNameExiststatus =false;
            this.Namecheck="Exist"
          }
          else
          {
            this.CheckNameExiststatus =true;
            this.Namecheck="";
          }
        });
      }
      else
      {
        this.CheckNameExiststatus =true;
        this.Namecheck="";
      }
    }
  }
  // end of duplicate Name

  RefreshPage()
  {
    this.rout.navigateByUrl('/ManageUserMaster/MenuMaster', {skipLocationChange: true}).then(()=>this.rout.navigate(["/ManageUserMaster/ModuleMaster"]))
  }

  //Image
  handleFileinput(files : FileList)
  {
    if(files.length>0)
    {
      this.filetoupload=files.item(0);
      var file = files[0];
      let filesize = this.filetoupload.size; 
      let filetyotypearray = this.filetoupload.type.split("/");
      this.ModuleModel.IconUrlLogoType= filetyotypearray[1];
      this.ModuleModel.Logodoctype= filetyotypearray[0];
      if(this.ModuleModel.Logodoctype=="image")
      {
        this.ModuleModel.IconUrlvalidfile=true; 
      }
      else
      {
        this.ModuleModel.IconUrlvalidfile=false; 
      }
      if(filesize > 500000)
      {
        this.ModuleModel.IconUrlvalidfile=false; 
      }
      if(!((this.ModuleModel.IconUrlLogoType=='jpeg') || (this.ModuleModel.IconUrlLogoType=='png') || (this.ModuleModel.IconUrlLogoType=='svg')|| (this.ModuleModel.IconUrlLogoType=='gif') || (this.ModuleModel.IconUrlLogoType=='wbmp')))
      {
        this.ModuleModel.IconUrlvalidfile=false; 
      }
      var reader : FileReader= new FileReader();
      reader.onload=(event:any)=>
      {
        this.ModuleModel.IconUrl=event.target.result;
      } 
      reader.onloadend = (e) => 
      {
        this.ModuleModel.Islogochange=true;
        this.ModuleModel.IconUrlImage = String(reader.result);
      }
      reader.readAsDataURL(file);   
    }
    else
    {
      this.ModuleModel.IconUrl=null;
    }
  }

  SmallUrlFileinput(files : FileList)
  {
    if(files.length>0)
    {
      this.filetoupload=files.item(0);
      var file = files[0];
      let filesize = this.filetoupload.size; 
      let filetyotypearray = this.filetoupload.type.split("/");
      this.ModuleModel.SmallIconUrlLogoType= filetyotypearray[1];
      this.ModuleModel.Logodoctype= filetyotypearray[0];
      if(this.ModuleModel.Logodoctype=="image")
      {
        this.ModuleModel.SmallIconUrlvalidfile=true; 
      }
      else
      {
        this.ModuleModel.SmallIconUrlvalidfile=false; 
      }
      if(filesize > 500000)
      {
        this.ModuleModel.SmallIconUrlvalidfile=false; 
      }
      if(!((this.ModuleModel.SmallIconUrlLogoType=='jpeg') || (this.ModuleModel.SmallIconUrlLogoType=='png') || (this.ModuleModel.SmallIconUrlLogoType=='svg')|| (this.ModuleModel.SmallIconUrlLogoType=='gif') || (this.ModuleModel.SmallIconUrlLogoType=='wbmp')))
      {
        this.ModuleModel.SmallIconUrlvalidfile=false; 
      }
      var reader : FileReader= new FileReader();
      reader.onload=(event:any)=>
      {
        this.ModuleModel.SmallIconUrl=event.target.result;
      } 
      reader.onloadend = (e) => 
      {
        this.ModuleModel.IsSmalllogochange=true;
        this.ModuleModel.SmallIconUrlImage = String(reader.result);
      }
      reader.readAsDataURL(file);   
    }
    else
    {
      this.ModuleModel.SmallIconUrl=null;
    }
  }

  public readFile(fileToRead: File)
  {
    let base64Observable = new ReplaySubject(1);
    let fileReader = new FileReader();
    fileReader.onload = event => 
    {
      base64Observable.next(fileReader.result);
    };
    fileReader.readAsDataURL(fileToRead);
    return base64Observable;
  }
}
