import { NumberFormatStyle } from "@angular/common";

export class LeaveApplicationModel
{
    public RefId : number;
    public LeaveId : number;
    public EmployeeId : number;
    public EmployeeCode : string;
    public EmployeeName : string;
    public EmployeeEmailId : string;
    public LeaveTypeId : number;
    public LeaveTypeName : number;
    public ApplicationDate : Date;
    public FromDate : Date;
    public FromFirstHalf : boolean;
    public FromSecondHalf : boolean;
    public ToDate : Date;
    public ToFirstHalf : boolean;
    public ToSecondHalf : boolean;
    public NoDaysLeave : number;
    public ReasonsLeave : string;
    public RemarksLeave : string;
    public ContactNoDuringLeave : number;
    public AddressDuringLeave : string;
    public Approved : boolean;
    public Rejected : boolean;
    public Approved_RejectedRemarks : string;
    public IsAttach : boolean;
    public AttachmentURL : string;
    public RHId : number;
    public RHCode : string;
    public RHName : string;
    public RHEmailId : string;
    public LeaveBalance :number;
    public PersonToContact : string;
    public Fromvalue : string;
    public Tovalue : string;

    public FromOffday : boolean;
    public FromHalfday : boolean;
    public ToOffday : boolean;
    public ToHalfday : boolean;
    public IsWorkingday : boolean;

    public IsOffday: boolean;
    public IsHalfday: boolean;
    public CompanyId : number;
    public LeaveModeId : number;

    public FirstHour : boolean;
    public SencondHalf : boolean;
    public FULLDay : boolean;
    public ExistsLeave : boolean;
}