import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs/Subject';
import { DataTableDirective } from 'angular-datatables';
import { OrganizationService } from '../shared/Organization/organization.service';
import { LocationService } from '../shared/HR_ADMIN/Location/location.service';
import { Country } from '../shared/Organization/Country.Model';
import { State } from '../shared/Organization/State.Model';
import { District } from '../shared/HR_ADMIN/Location/District.Model';
import { UserService } from '../shared/user.service';
// Begning Confirmation
import { ConfirmationService } from '@jaspero/ng2-confirmations';
import { ResolveEmit } from '../../app/resolve-emit';
import { ConfirmSettings } from '../../app/confirm-settings';
// end of Confirmation
import { Menu } from '../shared/menu.model';

@Component({
  selector: 'app-manage-district',
  templateUrl: './manage-district.component.html',
  styleUrls: ['./manage-district.component.css']
})

export class ManageDistrictComponent implements OnInit 
{
  DistrictModel : District= new District();
  userClaims: any;
  Countries: Country[] = [];
  Districties: District[] = []; 
  States: State[] = []; 
  Result : string;
  SuccessStatus:boolean=false;
  SuccessMessage: string ="";
  public tableWidget: any;
  countryId : any;
  country : any;
  DistId : any;
  District: any;
  state: any;
  tempDistrictadd : District =new District();
  // Begning Permission Portion    
  SelectedMenu:Menu;
  IsDataIsInViewState : boolean=false;
  CanViewState :boolean=false;
  DisableSave : boolean=false;
  FormViewShow : boolean =false;
  CanSaveButtonView : boolean =false;
  // End of the permission portion

  //check for duplicate Name
  CheckNameExiststatus : boolean=null;  
  CheckNamestatusedit : boolean =false;
  Namecheck : string ="";
  checkName : any;
  checkNameval : string ="";
  //check for duplicate Name

  constructor(private organizationService: OrganizationService, private rout: Router,
    private LocationService : LocationService, private _confirmation: ConfirmationService,
     private userService: UserService ){}

  @ViewChild(DataTableDirective, {static: false})
  setDtOptions: DataTables.Settings = {};
  cardDtOptions: DataTables.Settings = {};
  dtElement: DataTableDirective;
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();

  ngOnInit() 
  {
    // Begning Permission Portion 
    this.SelectedMenu=this.userService.SelectedMenu;
    if(this.SelectedMenu !=undefined)
    {
      localStorage.setItem('selectedmenu',JSON.stringify(this.SelectedMenu));
      this.rout.navigate([this.SelectedMenu.RouteName]);
    }
    else
    {
      if(JSON.parse(localStorage.getItem('selectedmenu'))!=undefined)
      {
        this.SelectedMenu = JSON.parse(localStorage.getItem('selectedmenu'));
        this.rout.navigate([this.SelectedMenu.RouteName]);
      }
    }
    if(this.SelectedMenu.CanAdd)
    {
      this.IsDataIsInViewState=false;
      this.CanSaveButtonView=true;
    }
    else
    {
      this.IsDataIsInViewState=true;
    }
    if(this.SelectedMenu.CanView)
    {
      this.CanViewState=false;
    }
    else
    {
      this.CanViewState=true;
    }
    // End of the permission portion
    this.DisableSave=true;
    this.FormViewShow=false;
    this.getCountries();
    this.dtOptions = 
    {
      pagingType: 'full_numbers',
      pageLength: 8
    };
    this.DistrictModel.CountryId=0;
    if(localStorage.getItem('SuccessfullMessage'))
    {
      this.SuccessStatus=true;
      this.SuccessMessage =localStorage.getItem('SuccessfullMessage')
      localStorage.removeItem('SuccessfullMessage');
    }
  }

  settings: ConfirmSettings | any = 
  {
    overlay: true,
    overlayClickToClose: true,
    showCloseButton: true,
    confirmText: 'Yes',
    declineText: 'No'
  };

  ngAfterViewInit(): void 
  {
    this.GetDistricts();
    this.dtOptions = 
    {
      pagingType: 'full_numbers',
      pageLength: 8
    };
  }

  GetDistricts()
  {
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.LocationService.GetDistrict( t).then(x => 
    {
      this.Districties=[];
      this.District = x;
      Object.keys(this.District).forEach( key => 
      {
        this.District[key];
        let Districttemp = new District();
        Districttemp = this.District[key];
        this.Districties.push(Districttemp);
      });
      this.dtTrigger.next();
    });
  }

  ADDClick()
  {
    this.FormViewShow=true;
    this.CanSaveButtonView=false;
  }

  GetDistrictByid(id) 
  {
     // begning of the permission portion
     this.IsDataIsInViewState=false;
     // end of the permission portion
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.LocationService.GetDistrictByid( t,id).then(x => 
    {
      this.FormViewShow=true;
      this.CanSaveButtonView=false;
      this.District = x;
      Object.keys(this.District).forEach( key => 
      {
        this.District[key];
        let Districttemp = new District();
        Districttemp = this.District[key];
        this.DistrictModel.DistId=Districttemp.DistId;
        this.DistrictModel.CountryId=Districttemp.CountryId;
        this.DistrictModel.DistCode=Districttemp.DistCode;
        this.DistrictModel.DistName=Districttemp.DistName;
        this.DistrictModel.StateId=Districttemp.StateId; 
      });
      this.getStates(this.DistrictModel.CountryId);
      // Begin for check Name duplication
      this.checkNameval= this.DistrictModel.DistName;
      this.CheckNamestatusedit=true;
      this.CheckNameExiststatus =true;
      this.Namecheck=""
      // End for check Name duplication
    });
  }

  ViewDistrictByid(id) 
  {
     // begning of the permission portion
     this.IsDataIsInViewState=true;
     // end of the permission portion
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.LocationService.GetDistrictByid( t,id).then(x => 
    {
      this.FormViewShow=true;
      this.CanSaveButtonView=false;
      this.District = x;
      Object.keys(this.District).forEach( key => 
      {
        this.District[key];
        let Districttemp = new District();
        Districttemp = this.District[key];
        this.DistrictModel.DistId=Districttemp.DistId;
        this.DistrictModel.CountryId=Districttemp.CountryId;
        this.DistrictModel.DistCode=Districttemp.DistCode;
        this.DistrictModel.DistName=Districttemp.DistName;
        this.DistrictModel.StateId=Districttemp.StateId; 
      });
      this.getStates(this.DistrictModel.CountryId);
      // Begin for check Name duplication
      this.checkNameval= this.DistrictModel.DistName;
      this.CheckNamestatusedit=true;
      this.CheckNameExiststatus =true;
      this.Namecheck=""
      // End for check Name duplication
    });
  }

  saveDistrict() :void
  {
    this.DisableSave=false;
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.DistrictModel.IsActive=true;
    if(this.DistrictModel.DistId > 0)
    {
      this.LocationService.UpdateDistrict(t ,this.DistrictModel).subscribe(res=>
      {
        this.DistId=res;
        this.DistrictModel.DistId=0;  
        if(this.DistId>0)
        {
          localStorage.setItem('SuccessfullMessage', "Update Successfully");
          this.rout.navigateByUrl('/Location/ManageCountry', {skipLocationChange: true}).then(()=>this.rout.navigate(["/Location/ManageDistrict"]));
        }
      }); 
    }
    else
    {
      this.LocationService.AddDistrict(t ,this.DistrictModel).subscribe(res=>
      {
        this.DistId=res;
        this.DistrictModel.DistId=0;
        if(this.DistId>0)
        {
          localStorage.setItem('SuccessfullMessage', "Save Successfully");
          this.rout.navigateByUrl('/Location/ManageCountry', {skipLocationChange: true}).then(()=>this.rout.navigate(["/Location/ManageDistrict"]));
        }
     }); 
    }
  } 

  DeleteDistrictByid(DistId : number) :void
  {    
    this._confirmation.create('Confirm to Deleate', 'Press Yes to Delete', this.settings)
    .subscribe((ans: ResolveEmit) => {
      if (ans.resolved == true) {

        let t =  localStorage.getItem('userToken');
        t = 'Bearer' + t;
        this.DistrictModel.IsActive=true;
        if( DistId > 0)
        {
          this.LocationService.DeleteDistrict(t ,DistId).subscribe(res=>
          {
            this.DistId=res;
            this.DistrictModel.DistId=0;                                  
            if(this.DistId >0)
            {
              localStorage.setItem('SuccessfullMessage', "Deleted Successfully");
              this.rout.navigateByUrl('/Location/ManageCountry', {skipLocationChange: true}).then(()=>this.rout.navigate(["/Location/ManageDistrict"]));
            }
          });                  
        }
         
      }
    })
  }

  getCountries() 
  {
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.organizationService.GetCountry( t).then(x => 
    {
      this.country = x;
      Object.keys(this.country).forEach( key => 
      {
        this.country[key];
        let Countrytemp = new Country();
        Countrytemp = this.country[key];
        this.Countries.push(Countrytemp);
      });     
    });      
  }

  getStates( countryid) 
  {
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t
    if(this.States.length>0)
    {
      this.States.splice(0, this.States.length);
    }
    this.organizationService.GetState( t,countryid).then(x => 
    {
      this.state = x;
      Object.keys(this.state).forEach( key => 
      {
        this.state[key];
        let Statetemp = new State();
        Statetemp = this.state[key];
        this.States.push(Statetemp);
      });     
    });      
  }

  onChange(countryid) 
  {
    if(countryid!=0)
    {
      this.getStates(countryid);
      this.DistrictModel.StateId=0;
    }
    else
    {
      this.getStates(countryid);
      this.DistrictModel.StateId=0;
    }
  }

  RefreshPage() 
  {       
    this.rout.navigateByUrl('/Location/ManageCountry', {skipLocationChange: true}).then(()=>this.rout.navigate(["/Location/ManageDistrict"]));
  }

  // begning of check duplicate Name
  CheckName()
  {
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    if(!this.CheckNamestatusedit)
    {
      this.LocationService.CheckDistrictName( t, this.DistrictModel.DistName).then(x => 
      {
        this.checkName = x;
        if( this.checkName.length >0)
        {
          this.CheckNameExiststatus =false;
          this.Namecheck="Exist"
        }
        else
        {
          this.CheckNameExiststatus =true;
          this.Namecheck="";
        }
     });
    }
    else
    {
      if(this.DistrictModel.DistName !=this.checkNameval )
      {
        this.LocationService.CheckDistrictName( t, this.DistrictModel.DistName).then(x => 
        {
          this.checkName = x;
          if( this.checkName.length >0)
          {
            this.CheckNameExiststatus =false;
            this.Namecheck="Exist"
          }
          else
          {
            this.CheckNameExiststatus =true;
            this.Namecheck="";
          }
        });
      }
      else
      {
        this.CheckNameExiststatus =true;
        this.Namecheck="";
      }
    }
  }
  // end of duplicate Name
}
