export class BirthDayAnniversaryModel
{
    public EmployeeId : Number;
    public EmployeeCode : string;
    public EmployeeName : string;
    public EmployeeEmailId : string;
    public MobileNumber : Number;
    public CompanyName : string;
    public BranchName : string;
    public DepartmentName : string;
    public DesignationName : string;
    public DOB : Date;
    public MarriageAnniversary : Date;
    public WorkAnniversary : Date;
    public AgeYears : Number;
    public WorkingYears : Number;
    public MarriageAnniversaryYears : Number;
    public Message : string;
    public FileName : string;
    public File :string;
    public FileExtension : string;
    public ImageFile : string;
    public ImageType : string;

    public EventMessage : string;
    public EventDate : Date;
    public EventType : string;
    public EventYear : number;
}