<!-- <perfect-scrollbar style="max-width: 1260px; max-height: 540px;" class="mukesh"> -->
<div>
  <fieldset class="body-form-control" style="    border: none;">
    <app-dialog [(visible)]="this.SuccessStatus">
      <span class="successmsg">{{this.SuccessMessage}}</span>
    </app-dialog>



    <legend class="legend--">Basic Info</legend>
    <form #LeadName="ngForm" (ngSubmit)="saveCRMLead()">


      <div class="row">
        <div class="col-lg-4">
          <div class="col-body-border-right">
            <div class="form-group row">

              <label class="col-sm-3 col-form-label clear-padding-right">First Name
                <span class="required-validation" *ngIf="(FirstName==null) ||
                              (FirstName.length==0)  ">*</span>
              </label>



              <div class="col-sm-3 clear-left-padding padL7">
                <select [(ngModel)]="Mr" tabindex="1" (ngModelChange)="onChangeMister($event)" id="MR"
                  class="form-control" name="MR" #MRModel="ngModel">
                  <option [selected]="true" [value]="0">Mr</option>
                  <option [value]="1">Mrs</option>
                  <option [value]="2">Ms</option>
                  <option [value]="3">Dr</option>
                  <option [value]="4">M/S</option>
                </select>
              </div>

              <div class="col-sm-6 clear-left-padding">
                <input type="text" [(ngModel)]="FirstName" maxlength="100" tabindex="2" id="FirstName" name="FirstName"
                  #FirstNameModel="ngModel" (blur)="ValidateFirstName()" required
                  class="form-control form-control-custom form-control-custom-new">


                <div *ngIf="FirstNameModel.invalid && (FirstNameModel.dirty || FirstNameModel.touched)"
                  class="alert alert-danger">
                  <div *ngIf="FirstNameModel.errors.required"> First Name is required</div>
                </div>


              </div>
            </div>


            <div class="form-group row">
              <label class="col-sm-4 col-form-label clear-padding-right"> Lead Name
                <span class="required-validation" *ngIf="(LeadNameM==null) ||
                    (LeadNameM.length==0)">*</span>
              </label>
              <div class="col-sm-8 clear-left-padding">
                <input type="text" [(ngModel)]="LeadNameM" maxlength="100" tabindex="5" id="LeadName" name="LeadName"
                  #LeadName="ngModel" required class="form-control form-control-custom form-control-custom-new"
                  (blur)="CheckDuplicateLead()">

              </div>
              <div class="required-validation" *ngIf="this.IsDuplicateLead"> <br>Lead Name Already Exist </div>

              <!-- <div *ngIf="LeadName.invalid && (LeadName.dirty || LeadName.touched)" class="alert alert-danger">
                  <div *ngIf="LeadName.errors.required"> Lead Name is required</div>
                </div> -->
            </div>



            <div class="form-group row">
              <label class="col-sm-4 col-form-label clear-padding-right">Email Id</label>
              <div class="col-sm-8 clear-left-padding">
                <input type="text" [(ngModel)]="EmailIdM" tabindex="8" id="EmailId" name="EmailId" #EmailId="ngModel"
                  required class="form-control form-control-custom form-control-custom-new" (blur)="ValidateEmail()">
                <!-- <div *ngIf="EmailId.invalid && (EmailId.dirty || EmailId.touched)" class="alert alert-danger">
                         <div *ngIf="EmailId.errors.required">  Email Id required</div>
                       </div> -->

              </div>
            </div>



            <div class="form-group row">
              <label class="col-sm-4 col-form-label clear-padding-right">City
                <!-- <span class="required-validation" *ngIf="this.CRM_LeadService.model_CRM_Shared_lead.CityId==0">*</span> -->
              </label>
              <div class="col-sm-8 clear-left-padding">

                <angular2-multiselect tabindex="11" style="box-shadow: 0;" [data]="CityArray" [(ngModel)]="SelectedCity"
                  name="CityId" [settings]="CitySettings" (onSelect)="onItemSelectCity($event)"
                  (onDeSelect)="OnItemDeSelectCity($event)" (onSelectAll)="onSelectAllCity($event)"
                  (onDeSelectAll)="onDeSelectAllCity($event)">
                </angular2-multiselect>

              </div>
            </div>

            <div class="form-group row">
              <label class="col-sm-4 col-form-label clear-padding-right">Address</label>
              <div class="col-sm-8 clear-left-padding">
                <!-- <input type="textarea" rows="4" cols="15"
                    [(ngModel)]="this.CRM_LeadService.model_CRM_Shared_lead.Address" tabindex="3" maxlength="2000"
                    id="Address" name="Address" #Address="ngModel"
                    class="form-control form-control-custom form-control-custom-new"> -->

                <textarea id="LeadAddress" style="width:100%; height:50px;"
                  (change)="LeadAddressChange($event)">{{AddressM}}</textarea>


              </div>
            </div>











          </div>
        </div>

        <div class="col-lg-4">
          <div class="col-body-border-right">


            <div class="form-group row">
              <label class="col-sm-4 col-form-label clear-padding-right">Middle Name</label>
              <div class="col-sm-8 clear-left-padding">
                <input type="text" [(ngModel)]="MiddleNameM" tabindex="3" maxlength="100" id="MiddleName"
                  name="MiddleName" #MiddleName="ngModel"
                  class="form-control form-control-custom form-control-custom-new">
              </div>
            </div>



            <div class="form-group row">
              <label class="col-sm-4 col-form-label clear-padding-right">Phone No</label>
              <div class="col-sm-8 clear-left-padding">
                <!-- <input type="text" [(ngModel)]="this.CRM_LeadService.model_CRM_Shared_lead.PhoneNo" tabindex="18"
                    id="PhoneNo" name="PhoneNo" #PhoneNo="ngModel" required
                    class="form-control form-control-custom form-control-custom-new" (blur)="ValidatephoneNo()"> -->
                <input NumberOn placeholder="Enter PhoneNo" maxlength="10" tabindex="6" [(ngModel)]="PhoneNoM"
                  id="PhoneNo" name="PhoneNo" #PhoneNo="ngModel" required class="form-control form-control-custom
                                      form-control-custom-new">

                <div *ngIf="!this.Phonevalidation" class="alert alert-danger">
                  <div *ngIf="!this.Phonevalidation"> Enter Valid Phone No</div>
                </div>

              </div>
            </div>

            <div class="form-group row">
              <label class="col-sm-4 col-form-label clear-padding-right">Country
                <!-- <span class="required-validation" *ngIf="this.CRM_LeadService.model_CRM_Shared_lead.CountryId==0">*</span> -->
              </label>
              <div class="col-sm-8 clear-left-padding">


                <angular2-multiselect tabindex="9" class="form-control" [data]="CountryArray"
                  [(ngModel)]="SelectedCountry" name="CountryId" [settings]="CountrySettings"
                  (onSelect)="onItemSelectCountry($event)" (onDeSelect)="OnItemDeSelectCountry($event)"
                  (onSelectAll)="onSelectAllCountry($event)" (onDeSelectAll)="onDeSelectAllCountry($event)">
                </angular2-multiselect>


              </div>
            </div>

            <div class="form-group row">
              <label class="col-sm-4 col-form-label clear-padding-right">Assigne To
                <span class="required-validation" *ngIf="AssigneToM==0">*</span>
              </label>
              <div class="col-sm-8 clear-left-padding">


                <angular2-multiselect tabindex="12" class="form-control" [data]="AssigneArray"
                  [(ngModel)]="SelectAssigneList" name="AssignedId" [settings]="AssigneSettings"
                  (onSelect)="onItemSelectAssigneTo($event)" (onDeSelect)="OnItemDeSelectAssigneTo($event)"
                  (onSelectAll)="onSelectAllAssigneTo($event)" (onDeSelectAll)="onDeSelectAllAssigneTo($event)">
                </angular2-multiselect>


              </div>


            </div>

            <div class="form-group row">
              <label class="col-sm-4 col-form-label clear-padding-right">Designation
                <span class="required-validation" *ngIf="StateIdM==0">*</span>
              </label>
              <div class="col-sm-8 clear-left-padding">



                <angular2-multiselect tabindex="3" class="form-control" [data]="DesignationArray"
                  [(ngModel)]="SelectedDesignation" name="DesignationId" [settings]="DesignationSettings"
                  (onSelect)="onItemSelecDesignation($event)" (onDeSelect)="OnItemDeSelectDesignation($event)"
                  (onSelectAll)="onSelectAllDesignation($event)" (onDeSelectAll)="onDeSelectAlldesignation($event)">
                </angular2-multiselect>

              </div>
            </div>







            <!-- <div class="form-group row">
               <label class="col-sm-5 col-form-label clear-padding-right"> Anniversary Date</label>
               <div class="col-sm-7 clear-left-padding">
                 <input type="date" name="AnniverSaryDate" tabindex="9" [ngModel]="this.CRM_LeadService.model_CRM_Shared_lead.AnniversaryDate | date:'yyyy-MM-dd'" (ngModelChange)="AniversaryDate($event)"
                   #AnniverSaryDate="ngModel" >
               </div>
             </div>     -->






          </div>
        </div>




        <div class="col-lg-4">
          <div class="form-group row">
            <label class="col-sm-4 col-form-label clear-padding-right">Last Name
              <span class="required-validation" *ngIf="(LastName==null) ||
              (LastName.length==0)  "> *</span>
            </label>
            <div class="col-sm-8 clear-left-padding">
              <input type="text" [(ngModel)]="LastName" maxlength="100" tabindex="4" id="LastName" name="LastName"
                #LastNameModel="ngModel" (blur)="ValidateLastName()" required
                class="form-control form-control-custom form-control-custom-new">


              <!-- <div *ngIf="LastName.invalid && (LastName.dirty || LastName.touched)" class="alert alert-danger">
                    <div *ngIf="LastName.errors.required"> Last Name is required</div>
                  </div> -->


            </div>
          </div>

          <div class="form-group row">
            <label class="col-sm-4 col-form-label clear-padding-right"> Landline </label>
            <div class="col-sm-8 clear-left-padding">
              <!-- <input type="text" [(ngModel)]="this.CRM_LeadService.model_CRM_Shared_lead.Extension" maxlength="100"
                  tabindex="10" id="Title" name="Extension" #Extension="ngModel"
                  class="form-control form-control-custom form-control-custom-new"> -->
              <input NumberOn [(ngModel)]="ExtensionM" maxlength="16" tabindex="7" id="Title" name="Extension"
                #Extension="ngModel" class="form-control form-control-custom form-control-custom-new">

            </div>

          </div>

          <div class="form-group row">
            <label class="col-sm-4 col-form-label clear-padding-right">State
              <!-- <span class="required-validation" *ngIf="this.CRM_LeadService.model_CRM_Shared_lead.StateId==0">*</span> -->
            </label>
            <div class="col-sm-8 clear-left-padding">
              <!-- <select [(ngModel)]="this.CRM_LeadService.model_CRM_Shared_lead.StateId" tabindex="11" (ngModelChange)="onChangeCountry($event)" id="StateId" class="form-control"
                 name="StateId" #StateId="ngModel">
                 <option [value]="0" [disabled]=true>Select State</option>
                 <option *ngFor="let s of statelist" [value]="s.StateId">{{s.StateName}}
                 </option>
               </select> -->


              <angular2-multiselect class="form-control" [data]="StateArray" [(ngModel)]="SelectedState" name="StateId"
                [settings]="StateSettings" (onSelect)="onItemSelecState($event)"
                (onDeSelect)="OnItemDeSelectState($event)" (onSelectAll)="onSelectAllState($event)"
                (onDeSelectAll)="onDeSelectAllState($event)"></angular2-multiselect>

            </div>
          </div>



          <!-- <div class="form-group row">
             <label class="col-sm-5 col-form-label clear-padding-right"> Date Of Birth</label>
             <div class="col-sm-7 clear-left-padding">
               <input type="date" name="DOB" tabindex="8" [ngModel]="this.CRM_LeadService.model_CRM_Shared_lead.DOB | date:'yyyy-MM-dd'"      (ngModelChange)="DateOfBirth($event)"
                 #DOB="ngModel">
             </div>
              <div *ngIf="!this.CRM_Lead_Model.IsDOBValid" class="alert alert-danger">
               <div *ngIf="!this.CRM_Lead_Model.IsDOBValid">Date Of Birth should be valid</div>
             </div> 
           </div> -->

          <div class="form-group row">
            <label class="col-sm-4 col-form-label clear-padding-right">List Name
              <span class="required-validation" *ngIf="ListIdM==0" tabindex="13">*</span>
            </label>
            <div class="col-sm-8 clear-left-padding">
              <!-- <select [(ngModel)]="this.CRM_LeadService.model_CRM_Shared_lead.ListId" tabindex="13" id="ListId" class="form-control" name="ListId" #ListId="ngModel">
                <option [value]="0" [disabled]=true>Select List Name </option>
                <option *ngFor="let l of listname" [value]="l.ListId">{{l.ListName}}</option>
              </select> -->




              <angular2-multiselect class="form-control" [data]="ListArray" [(ngModel)]="SelectedListForList"
                name="selectListId" [settings]="ListSettings" (onSelect)="onItemSelectList($event)"
                (onDeSelect)="OnItemDeSelectList($event)" (onSelectAll)="onSelectAllList($event)"
                (onDeSelectAll)="onDeSelectAllList($event)"></angular2-multiselect>
            </div>
          </div>

          <div class="form-group row">

            <label class="col-sm-6 col-form-label text-center clear-padding-right">
              <button type="submit" tabindex="11" required class="btn btn-primary save-button"
                *ngIf="this.ValidationComplete"> Save Next</button>

            </label>

            <div class="col-sm-6 col-form-label text-center">
              <button type="button" tabindex="12" (click)="RefreshPage()"
                class="btn btn-danger cancle-button">Cancel</button>
            </div>
          </div>
        </div>
      </div>
    </form>
  </fieldset>


</div>
<!-- </perfect-scrollbar> -->