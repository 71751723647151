import { Model_CRM_Opportunity_Document } from './Model_CRM_Opportunity_Documents';

export class Model_CRM_Oppertunity {




    //Filename: string;
    public IsCreatedDateValid: boolean;
    public IsClosingDateValid: boolean;
    public OppertunityTitle: string;
    public OppertunityId: number;
    public Amount: number;
    public OppertunityDescription: string;
    public Probability: string;
    public Tag: string;
    public PipeLineStatus: string;
    public PipeLineId: number;
    public CompanyName: string;
    public CompanyId: number;
    public ContactName: string;
    public ContactId: number;
    public ChooseFile: string;
    public CreatedDate: Date;
    public CreatedDateval: string;
    public CloseDate: Date;
    public CloseDateval: string;
    public IsActive: boolean;
    public ListOpportunityDocs: Model_CRM_Opportunity_Document[]
    public EmployeeName: string;

    public MonthName: string;
    public MonthId: number;

    public Destination: string;
    public NumberOfPax: number;

    public TypeOfBusiness: number;

    public SourceQueryId: number;


    //Begning of Paging
    public TotalPages: number;
    public CurrentPageNumber: number;
    public NextPageNumber: number;
    public searchtext: string;
    //End of  Paging




}       