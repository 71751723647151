<div class="body-content-area tabcontent" id="link1">
  <div class="row">
    <div class="col-lg-9">
      <div class="row">
        <div class="col-lg-1 low-padding text-center">
          <!-- <img *ngIf="this.SelectedMenu.Image!=null" src={{this.SelectedMenu.Image}} class="body-icon" alt="Icon"/> -->
        </div>
        <div class="col-lg-10 clear-padding">
          <span class="page-title">EPF & ESI Tax Slab</span>
          <small class="page-description"></small>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="!(this.CanViewState)">
  <table id="tblEPFESIMaster" class="table table-striped table-bordered text-center table-new row-border hover">
    <thead class="thead-blue text-center">
      <tr>
        <th>Sl No.</th>
        <th>EPF Contribution</th>
        <th>EPF Deducation</th>
        <th>ESI Contribution</th>
        <th>ESI Deducation</th>
        <th>Applicable Date</th>
        <th>Action</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let PFESI of EPFESIes, let i = index">
        <td scope="row">{{i+1}}</td>
        <td>{{PFESI.EPFCont}}</td>
        <td>{{PFESI.EPFDed}}</td>
        <td>{{PFESI.ESICont}}</td>
        <td>{{PFESI.ESIDed}}</td>
        <td>{{PFESI.ApplicableDate| date : "dd-MMM-yyyy"}}</td>
        <td>
          <button *ngIf="this.SelectedMenu.CanEdit"  type="button" (click)="GetEPFESISlabById( PFESI.RefId )" class="table-edit-btn"><i class="fas fa-edit"></i> </button> 
          <button *ngIf="this.SelectedMenu.CanView"  type="button" (click)="viewEPFESISlabById( PFESI.RefId )" class="table-view-button" type="button" ><i class="fas fa-eye"></i></button>
        </td>
      </tr>
    </tbody>
  </table>
  
  <fieldset class="body-form-control" *ngIf="!(this.IsGridInViewState)">
    <app-dialog [(visible)]="this.SuccessStatus">
      <span class="successmsg">{{this.SuccessMessage}}</span>
    </app-dialog>
    
    <legend class="legend--">EPF & ESI Tax Slab Creation</legend>
    <form #EPFESIForm="ngForm" (ngSubmit)="SaveEPFESISlab()">
      <div class="row">
        <div class="col-lg-4">
          <div class="col-body-border-right">
            <div class="form-group row">
              <label  class="col-sm-4 col-form-label clear-padding-right">EPFContribution</label>
              <div class="col-sm-7 clear-left-padding">
                <input type="text" [(ngModel)]="EPFESIModel.EPFCont" tabindex="1" id ="EPFCont"  name="EPFCont" #EPFCont="ngModel" required class="form-control form-control-custom form-control-custom-new">
              </div>
              <div *ngIf="EPFCont.invalid && (EPFCont.dirty || EPFCont.touched)" class="alert alert-danger">
                <div *ngIf="EPFCont.errors.required">EPF Contribution is required.</div>
              </div>
            </div>
            <div class="form-group row">
              <label  class="col-sm-4 col-form-label clear-padding-right">ESIContribution</label>
              <div class="col-sm-7 clear-left-padding">
                <input type="text" [(ngModel)]="EPFESIModel.ESICont" tabindex="4" id ="ESICont"  name="ESICont" #ESICont="ngModel" required class="form-control form-control-custom form-control-custom-new">
              </div>
              <div *ngIf="ESICont.invalid && (ESICont.dirty || ESICont.touched)" class="alert alert-danger">
                <div *ngIf="ESICont.errors.required">ESI Contribution is required.</div>
              </div>
            </div>
            <div class="form-group row">
              <label  class="col-sm-4 col-form-label clear-padding-right">Education Cess</label>
              <div class="col-sm-7 clear-left-padding">
                <input type="text" [(ngModel)]="EPFESIModel.EducationCess" tabindex="7" id ="EducationCess" name="EducationCess" #EducationCess="ngModel" required class="form-control form-control-custom form-control-custom-new">
              </div>
              <div *ngIf="EducationCess.invalid && (EducationCess.dirty || EducationCess.touched)" class="alert alert-danger">
                <div *ngIf="EducationCess.errors.required">Education Cess is required.</div>
              </div>
            </div>
          </div>
        </div>
              
        <div class="col-lg-4">
          <div class="col-body-border-right">
            <div class="form-group row">
              <label  class="col-sm-4 col-form-label clear-padding-right">EPF Deduction</label>
              <div class="col-sm-7 clear-left-padding">
                <input type="text" [(ngModel)]="EPFESIModel.EPFDed" tabindex="2" id ="EPFDed"  name="EPFDed" #EPFDed="ngModel" required class="form-control form-control-custom form-control-custom-new">
              </div>
              <div *ngIf="EPFDed.invalid && (EPFDed.dirty || EPFDed.touched)" class="alert alert-danger">
                <div *ngIf="EPFDed.errors.required">EPF Deduction is required.</div>
              </div>
            </div>
            <div class="form-group row">
                <label  class="col-sm-4 col-form-label clear-padding-right">ESI Deduction</label>
                <div class="col-sm-7 clear-left-padding">
                  <input type="text" [(ngModel)]="EPFESIModel.ESIDed" tabindex="5" id ="ESIDed"  name="ESIDed" #ESIDed="ngModel" required class="form-control form-control-custom form-control-custom-new">
                </div>
                <div *ngIf="ESIDed.invalid && (ESIDed.dirty || ESIDed.touched)" class="alert alert-danger">
                  <div *ngIf="ESIDed.errors.required">ESI Deduction is required.</div>
                </div>
              </div>
              <div class="form-group row">
              <label  class="col-sm-4 col-form-label clear-padding-right">Higher Education Cess</label>
              <div class="col-sm-7 clear-left-padding">
                <input type="text" [(ngModel)]="EPFESIModel.HigherEducationCess" tabindex="8" id ="HigherEducationCess"  name="HigherEducationCess" #HigherEducationCess="ngModel" required class="form-control form-control-custom form-control-custom-new">
              </div>
              <div *ngIf="HigherEducationCess.invalid && (HigherEducationCess.dirty || HigherEducationCess.touched)" class="alert alert-danger">
                <div *ngIf="HigherEducationCess.errors.required">Higher Education Cess is required.</div>
              </div>
            </div>
          </div>         
        </div>

        <div class="col-lg-4">
          <div class="form-group row">
            <label  class="col-sm-4 col-form-label clear-padding-right">Basic Slary</label>
            <div class="col-sm-7 clear-left-padding">
              <input type="text" [(ngModel)]="EPFESIModel.HighestBasicSalary" tabindex="3" id ="HighestBasicSalary" name="HighestBasicSalary" #HighestBasicSalary="ngModel" required class="form-control form-control-custom form-control-custom-new">
            </div>
            <div *ngIf="HighestBasicSalary.invalid && (HighestBasicSalary.dirty || HighestBasicSalary.touched)" class="alert alert-danger">
              <div *ngIf="HighestBasicSalary.errors.required">Basic Salary is required.</div>
            </div>
          </div>
          <div class="form-group row">
            <label  class="col-sm-4 col-form-label clear-padding-right">Applicable Date</label>
            <div class="col-sm-7 clear-left-padding">
              <input type="date"  name="ApplicableDate" tabindex="6" [ngModel]="EPFESIModel.ApplicableDate | date:'yyyy-MM-dd'" (ngModelChange)="dateChanged($event)" #ApplicableDate="ngModel" required />
              <label *ngIf="(!validdate)" class="required-validation">Select Valid Date</label>
            </div>
          </div>
            
          <div class="form-group row">
          <label class="col-sm-6 col-form-label text-center clear-padding-right" *ngIf="!(this.IsDataIsInViewState)">
            <button type="submit" tabindex="9" class="btn btn-primary save-button" [disabled]="!(EPFESIForm.valid && this.validdate == true && this.DisableSave==true)">Save</button>  
          </label>
          <div class="col-sm-6 col-form-label text-center">       
            <button type="button" tabindex="10" class="btn btn-danger cancle-button" (click)="RefreshPage()" >Cancel</button></div>
          </div>
        </div>
      </div>
    </form>
  </fieldset>
</div>
</div>