import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from '../../shared/user.service';
// Begning Confirmation
import { ConfirmationService } from '@jaspero/ng2-confirmations';
import { ResolveEmit } from '../../../app/resolve-emit';
import { ConfirmSettings } from '../../../app/confirm-settings';
// end of Confirmation
import { Menu } from '../../shared/menu.model';
import { ApproveLeaveService }  from '../../shared/HR_ADMIN/Approve/ApproveLeave.service';
import { AttendanceRegularationModel } from '../../shared/HR_ADMIN/Approve/AttendanceRegularationModel.Model'
import { ModalOptions,BsModalRef, BsModalService } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-attendance-regularization-approval',
  templateUrl: './attendance-regularization-approval.component.html',
  styleUrls: ['./attendance-regularization-approval.component.css']
})
export class AttendanceRegularizationApprovalComponent implements OnInit 
{
  userClaims: any;
  ListApplication: AttendanceRegularationModel[] = [];
  SubMenues : Menu[]=[];
  Mod : any;
  ModelListApplication: AttendanceRegularationModel;
  Approver: any;
  Result : string;
  SuccessStatus:boolean=false;
  SuccessMessage: string ="";
  public tableWidget: any;
  RefId : any;
  // Begning Permission Portion    
  SelectedMenu:Menu;
  IsDataIsInViewState : boolean=false;
  CanViewState : boolean=false;  
  CanApproveState : boolean=false; 
  GridShow : boolean=false; 
  DisableSave : boolean=false;
  // End of the permission portion
  // begin for Validation
  validdate : boolean= false;
  // end of validation
  //For Pop up
  TempRegularation : AttendanceRegularationModel;
  ApprovedMsg : boolean= false;
  RejectedMsg : boolean= false;
  ShowPopup : boolean= false;
  public modalRef: BsModalRef;
  public modelopt:ModalOptions;
  NoRecordMsg : boolean;

  constructor(private ApproveLeaveSrevice: ApproveLeaveService, private rout: Router, private _confirmation: ConfirmationService, private userService: UserService,private modalService: BsModalService)
  { 
    this.ModelListApplication = new AttendanceRegularationModel();
    this.modelopt=new ModalOptions();
    this.modelopt.class='heavy_modal';
    this.modalService.config= this.modelopt;
    this.modelopt.ignoreBackdropClick=true;
  }

  ngOnInit() 
  {
    //Begin Permission Portion
    this.SelectedMenu=this.userService.SelectedMenu;
    if(this.SelectedMenu !=undefined)
    {
      localStorage.setItem('selectedmenu',JSON.stringify(this.SelectedMenu));
      this.rout.navigate([this.SelectedMenu.RouteName]);
    }
    else
    {
      if(JSON.parse(localStorage.getItem('selectedmenu'))!=undefined)
      {
        this.SelectedMenu = JSON.parse(localStorage.getItem('selectedmenu'));
        this.rout.navigate([this.SelectedMenu.RouteName]);
      }
    }
    if(this.SelectedMenu.CanAdd)
    {
      this.IsDataIsInViewState=false;
    }
    else
    {
      this.IsDataIsInViewState=true;
    }
    if(this.SelectedMenu.CanView)
    {
      this.CanViewState=false;
    }
    else
    {
      this.CanViewState=true;
    }
    if(this.SelectedMenu.CanApprove)
    {
      this.CanApproveState=false;
    }
    else
    {
      this.CanApproveState=true;
    }
    //End of the peremission portion
    this.DisableSave=true;
    this.GetAllApplication();
    this.ApprovedMsg=false;
    this.RejectedMsg=false;
    this.ShowPopup=false;
    this.NoRecordMsg =false;
  }

  settings: ConfirmSettings | any = 
  {
    overlay: true,
    overlayClickToClose: true,
    showCloseButton: true,
    confirmText: 'Yes',
    declineText: 'No'
  };

  GetAllApplication()
  {
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.ApproveLeaveSrevice.GetEmployeeAttendanceRegularisationAppliesForRH( t).then(x => 
    {
      this.NoRecordMsg =false;
      this.Approver = x;
      Object.keys(this.Approver).forEach( key => 
      {
        this.Approver[key];
        let ApproveRegularation = new AttendanceRegularationModel();
        ApproveRegularation = this.Approver[key];
        this.ListApplication.push(ApproveRegularation);
      });
      if(this.ListApplication.length == 0)
      {
        this.NoRecordMsg =true;
      }
    });
  }

  viewAppliedRegularization(id,template) 
  {
    this.ModelListApplication.Remarks="";
    this.GridShow=true;
    // Begning of the permission portion
    this.IsDataIsInViewState=false;
    // End of the permision portion
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.ApproveLeaveSrevice.GetAttendanceRegularationdetailsByID( t,id).then(x => 
    {
      if(this.ShowPopup==true)
      {
        this.modalRef.hide();
      }
      this.modalRef = this.modalService.show(template,this.modelopt);
      this.ShowPopup=true;
      this.ApprovedMsg=false;
      this.RejectedMsg=false;
      this.Approver = x;
      Object.keys(this.Approver).forEach( key => 
      {
        // tslint:disable-next-line:no-unused-expression
        this.Approver[key];
        let Approvaltemp = new AttendanceRegularationModel();
        Approvaltemp = this.Approver[key];
        this.ModelListApplication.RefId=Approvaltemp.RefId;
        this.ModelListApplication.EmployeeId=Approvaltemp.EmployeeId;
        this.ModelListApplication.EmployeeCode=Approvaltemp.EmployeeCode;
        this.ModelListApplication.EmployeeName=Approvaltemp.EmployeeName; 
        this.ModelListApplication.EmployeeEmailId=Approvaltemp.EmployeeEmailId; 
        this.ModelListApplication.AttendanceDate=Approvaltemp.AttendanceDate; 
        this.ModelListApplication.AttendanceDay=Approvaltemp.AttendanceDay; 
        this.ModelListApplication.ActualInTime=Approvaltemp.ActualInTime; 
        this.ModelListApplication.ActualOutTime=Approvaltemp.ActualOutTime; 
        this.ModelListApplication.ActualWorkingDuration=Approvaltemp.ActualWorkingDuration; 
        this.ModelListApplication.StartTime=Approvaltemp.StartTime;
        this.ModelListApplication.EndTime=Approvaltemp.EndTime; 
        this.ModelListApplication.TotalWorkingHours=Approvaltemp.TotalWorkingHours; 
        this.ModelListApplication.RegularationDuration=Approvaltemp.RegularationDuration; 
        this.ModelListApplication.Reason=Approvaltemp.Reason; 
        this.ModelListApplication.RHCode=Approvaltemp.RHCode;
        this.ModelListApplication.RHName=Approvaltemp.RHName;
        this.ModelListApplication.RHEmailId=Approvaltemp.RHEmailId;
        this.ModelListApplication.CompanyId=Approvaltemp.CompanyId;
        this.ModelListApplication.SentMailid=Approvaltemp.SentMailid;
        this.ModelListApplication.SentMailPassword=Approvaltemp.SentMailPassword;
        this.ModelListApplication.HRISURL=Approvaltemp.HRISURL;
      });
    });
  }

  AttendanceRegularationApproved(template) :void
  {
    this.DisableSave=false;
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    if(this.ModelListApplication.RefId > 0)
    {
      this.ModelListApplication.IsApproved=true;
      this.ModelListApplication.IsRejected=false;
      this.ApproveLeaveSrevice.AttendanceRegularationApprovedOrRejected(t ,this.ModelListApplication).subscribe(res=>
      {
        this.RefId=res;
        this.ModelListApplication.RefId=0;  
        if(this.RefId>0)
        {
          this.ModelListApplication.Remarks="";
          this.ModelListApplication.RemarksAll="";
          this.modalRef.hide();
          this.ApprovedMsg=true;
          this.RejectedMsg=false;
          this.ShowPopup=false;
          this.modalRef = this.modalService.show(template);
          this.ListApplication=[];
          this.GetAllApplication();
          this.DisableSave=true;
          // localStorage.setItem('SuccessfullMessage', "Approved Successfully");
          // this.rout.navigateByUrl('/ApproveOrReject/Leave', {skipLocationChange: true}).then(()=>this.rout.navigate(["/ApproveOrReject/RegularizationApproval"]));
        }
      });                  
    }
  }

  AttendanceRegularationRejected(template) :void
  {
    this.DisableSave=false;
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    if(this.ModelListApplication.RefId > 0)
    {
      this.ModelListApplication.IsApproved=false;
      this.ModelListApplication.IsRejected=true;
      this.ApproveLeaveSrevice.AttendanceRegularationApprovedOrRejected(t ,this.ModelListApplication).subscribe(res=>
      {
        this.RefId=res;
        this.ModelListApplication.RefId=0;  
        if(this.RefId>0)
        {
          this.ModelListApplication.Remarks="";
          this.ModelListApplication.RemarksAll="";
          this.modalRef.hide();
          this.ApprovedMsg=false;
          this.RejectedMsg=true;
          this.ShowPopup=false;
          this.modalRef = this.modalService.show(template);
          this.ListApplication=[];
          this.GetAllApplication();
          this.DisableSave=true;
          // localStorage.setItem('SuccessfullMessage', "Rejected Successfully");
          // this.rout.navigateByUrl('/ApproveOrReject/Leave', {skipLocationChange: true}).then(()=>this.rout.navigate(["/ApproveOrReject/RegularizationApproval"]));
        }
      });                  
    }
  }

  RefreshPage()
  {
    this.rout.navigateByUrl('/ApproveOrReject/Leave', {skipLocationChange: true}).then(()=>this.rout.navigate(["/ApproveOrReject/RegularizationApproval"]));
  }

  SelectedChange(rowid,ev)
  {
    this.TempRegularation =this.ListApplication[rowid];
    if(ev.target.checked==true)
    {
      this.TempRegularation.Selected=true;
    }
    else
    {
      this.TempRegularation.Selected=false;
    }
  }

  checkAll(ev) 
  {
    if(ev.target.checked == true)
    {
      var J=this.ListApplication.length;
      for(var i=0;i<J;i++) 
      {
        this.ListApplication[i].Selected=true;
      }
    }
    else
    {
      var J=this.ListApplication.length;
      for(var i=0; i<J; i++)
      {
        this.ListApplication[i].Selected=false;
      }
    }
  }

  AttendanceRegularationApprovedAll(template) :void
  {
    if(this.ListApplication.length > 0)
    {
      this._confirmation.create('Confirm to Approve', 'Press Yes to Approve', this.settings)
      .subscribe((ans: ResolveEmit) => 
      {
        if (ans.resolved == true) 
        {
          this.DisableSave=false;
          let t =  localStorage.getItem('userToken');
          t = 'Bearer' + t;
          var j=0;
          var l=this.ListApplication.length;
          this.ListApplication.forEach(item => 
          {
            j=j+1;
            if(item.RefId > 0 && item.Selected == true)
            {
              item.Remarks=this.ModelListApplication.RemarksAll;
              item.IsApproved=true;
              item.IsRejected=false;
              this.ApproveLeaveSrevice.AttendanceRegularationApprovedOrRejected(t ,item).subscribe(res=>
              {
                this.RefId=res;
                item.RefId=0;  
                if(j == this.ListApplication.length)
                {
                  if(this.ShowPopup==true)
                  {
                    this.modalRef.hide();
                  }
                  this.ModelListApplication.Remarks="";
                  this.ModelListApplication.RemarksAll="";
                  this.ApprovedMsg=true;
                  this.RejectedMsg=false;
                  this.ShowPopup=false;
                  this.modalRef = this.modalService.show(template);
                  this.ListApplication=[];
                  this.GetAllApplication();
                  this.DisableSave=true;
                }
              });                  
            }
          })
        }
      })
    }
  }

  AttendanceRegularationRejectedAll(template) :void
  {
    if(this.ListApplication.length > 0)
    {
      this._confirmation.create('Confirm to Reject', 'Press Yes to Reject', this.settings)
      .subscribe((ans: ResolveEmit) => 
      {
        if (ans.resolved == true) 
        {
          this.DisableSave=false;
          let t =  localStorage.getItem('userToken');
          t = 'Bearer' + t;
          var j=0;
          var l=this.ListApplication.length;
          this.ListApplication.forEach(item => 
          {
            j=j+1;
            if(item.RefId > 0 && item.Selected == true)
            {
              item.Remarks=this.ModelListApplication.RemarksAll;
              item.IsApproved=false;
              item.IsRejected=true;
              this.ApproveLeaveSrevice.AttendanceRegularationApprovedOrRejected(t ,item).subscribe(res=>
              {
                this.RefId=res;
                item.RefId=0;  
                if(j == this.ListApplication.length)
                {
                  if(this.ShowPopup==true)
                  {
                    this.modalRef.hide();
                  }
                  this.ModelListApplication.Remarks="";
                  this.ModelListApplication.RemarksAll="";
                  this.ApprovedMsg=false;
                  this.RejectedMsg=true;
                  this.ShowPopup=false;
                  this.modalRef = this.modalService.show(template);
                  this.ListApplication=[];
                  this.GetAllApplication();
                  this.DisableSave=true;
                }
              });                  
            }
          })
        }
      })
    }
  }
}
