import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from '../shared/user.service';
import { User } from '../shared/user.model';
import { Menu }  from '../shared/menu.model';
import { CommonMenuHandelling } from '../shared/Menu/CommonMenuHandelling';
import { NotificationModel } from '../shared/NotificationModel.Model';
import { EventMessageModel } from '../shared/HR_ADMIN/Admin/EventMessageModel.Model';
import { BirthDayAnniversaryModel } from '../shared/HR_ADMIN/Employee/BirthDayAnniversaryModel.Model';
import { AdminMasterService }  from '../shared/HR_ADMIN/Admin/AdminMasterService.service';
import { AttedanceCalendarModel } from '../shared/HR_ADMIN/Employee/AttedanceCalendarModel.model';
import { HRPoliciesFormModel } from '../shared/HR_ADMIN/Admin/HRPoliciesFormModel.Model';
import { OptionsInput } from '@fullcalendar/core';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import { CalendarComponent, FullCalendarModule } from 'ng-fullcalendar';

@Component({
  selector: 'app-sub-module',
  templateUrl: './sub-module.component.html',
  styleUrls: ['./sub-module.component.css']
})
export class SubModuleComponent implements OnInit 
{
  userClaims: any;
  Modules: Menu[] = [];
  Mod: any;

  SubMenues: Menu[] = [];
  tempmenu: Menu;

  UserModel : User= new User();
  Users: User[] = [];
  User: any;

  HRPolicies : HRPoliciesFormModel[]=[];

  check: number;
  SubmenuCount : number=0;
  TotalALLApplications : number=0;
  Notifications : NotificationModel[]=[];
  FormsRaised : NotificationModel[]=[];
  Noti : any;
  Events:EventMessageModel[]=[];
  MDMessages:EventMessageModel[]=[];
  Birthdays:BirthDayAnniversaryModel[]=[];
  MarriageAnniversarys:BirthDayAnniversaryModel[]=[];
  WorkAnniversarys:BirthDayAnniversaryModel[]=[];
  Attendances:AttedanceCalendarModel[]=[];
  PolicyCount :number=0;

  options: OptionsInput;
  eventsModel: any;
 @ViewChild(FullCalendarModule, {static: false}) fullcalendar: CalendarComponent;

  constructor(private AdminMasterService:AdminMasterService,private router: Router, private userService: UserService,  private commonmenu:CommonMenuHandelling) 
  {
    if(userService.selectdmoduleid !=undefined)
    {
      localStorage.setItem("moduleidstor", userService.selectdmoduleid.toString());
    }
  }

  ngOnInit() 
  {
    this.SubModulelist();
    this.GetLoginDetails();
    this.GetNotificationALL();
    this.GetEventMDMessageForDashBoard();
    this.GetEmployeeBirtDayForDashBoard();
    this.GetEmployeeMarriageAnniversaryForDashBoard();
    this.GetEmployeeWorkAnniversaryForDashBoard();
    this.UserModel.TotalAllApplication=0;
    this.GetHRPoliciesForms();
    var CurrentDate = new Date();
    let CurrentDateString = CurrentDate.toDateString();
    this.GetAttendanceDetailsForDashboard(CurrentDateString);
    this.GETNotificationForFormRaise();
  }

  SubModulelist()
  { 
    this.Modules=[]
    let t =  localStorage.getItem('userToken');
    t = 'Bearer ' + t;
    let moduleid=localStorage.getItem("moduleidstor")
    this.userService.SubModulelist(t, moduleid).then(x => 
    {
      this.Mod = x;
      Object.keys(this.Mod).forEach( key => 
      {
        this.Mod[key];
        let menutemp = new Menu();
        menutemp = this.Mod[key];
        this.Modules.push(menutemp);
      });
    })
  }

  GetLoginDetails() 
  {
    this.UserModel=this.commonmenu.GetLoginDetails();
  }
 
  Logout()
  { 
    this.commonmenu.LogOut();
  }
  
  Menues(menuid) 
  {
    this.userService.selectdmoduleid = menuid;
    this.router.navigate(['MainMenu']);
  }
  
  PageRedirectClick(moduleId,MainMenu,MenuId)
  {
    this.userService.selectdmoduleid = moduleId;
    this.userService.selectedmenuid = MainMenu;
    let t = localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.userService.GetSubMenuListForPageChange(t,moduleId,MainMenu,MenuId).then(x => 
    {
      this.Mod = x;
      this.SubMenues = [];
      Object.keys(this.Mod).forEach(key =>
      {
        this.Mod[key];
        let menutemp = new Menu();
        menutemp = this.Mod[key];
        localStorage.setItem("selectedmodule", menutemp.SelectedMenu.toString());
        if(menutemp.MenuId==MenuId)
        {
          localStorage.setItem("selectedSubMenu", menutemp.SelectedSubMenu.toString());
          this.SubmenuCount=menutemp.SelectedSubMenu;
        }
        this.userService.SelectedMenuIndex = menutemp.SelectedMenu;
        this.SubMenues.push(menutemp);
      });
      this.tempmenu = this.SubMenues[this.SubmenuCount];
      this.userService.SelectedMenu = this.tempmenu;
      this.userService.IsFormMainMenu = true;
      this.userService.MainMenuUrl = this.tempmenu.RouteName;
      this.router.navigate([this.tempmenu.RouteName]);
    });
  }

  GetNotificationALL()
  {  
    this.Notifications=[]
    let t =  localStorage.getItem('userToken');
    t = 'Bearer ' + t;
    this.userService.GetNotificationALL(t).then(x => 
    {
      this.Noti = x;
      Object.keys(this.Noti).forEach( key => 
      {
        this.Noti[key];
        let Notitemp = new NotificationModel();
        Notitemp = this.Noti[key];
        this.UserModel.TotalAllApplication=Notitemp.TotalAllApplication;
        if(Notitemp.TotalApplication > 0 && Notitemp.ApplicationType != 'Total Application')
        {
          this.Notifications.push(Notitemp);
        }
      });
    })
  }

  OnDashboard() 
  {
    this.router.navigate(['/DashBoard']);
  }

  GetEventMDMessageForDashBoard()
  {  
    this.MDMessages=[];
    this.Events=[];
    let t =  localStorage.getItem('userToken');
    t = 'Bearer ' + t;
    this.userService.GetEventMDMessageForDashBoard(t).then(x => 
    {
      this.Events=[];
      this.MDMessages=[];
      this.Noti = x;
      Object.keys(this.Noti).forEach( key => 
      {
        this.Noti[key];
        let Notitemp = new EventMessageModel();
        Notitemp = this.Noti[key];
        if(Notitemp.MessageType=='Event')
        {
          this.Events.push(Notitemp);
        }
        else if(Notitemp.MessageType=='MD Message')
        {
          this.MDMessages.push(Notitemp);
        }
      });
    })
  }

  GetEmployeeBirtDayForDashBoard()
  {  
    this.Birthdays=[];
    let t =  localStorage.getItem('userToken');
    t = 'Bearer ' + t;
    this.userService.GetEmployeeBirtDayForDashBoard(t).then(x => 
    {
      this.Birthdays=[];
      this.Noti = x;
      Object.keys(this.Noti).forEach( key => 
      {
        this.Noti[key];
        let Notitemp = new BirthDayAnniversaryModel();
        Notitemp = this.Noti[key];
        this.Birthdays.push(Notitemp);
      });
    })
  }
  GetEmployeeMarriageAnniversaryForDashBoard()
  {  
    this.MarriageAnniversarys=[];
    let t =  localStorage.getItem('userToken');
    t = 'Bearer ' + t;
    this.userService.GetEmployeeMarriageAnniversaryForDashBoard(t).then(x => 
    {
      this.MarriageAnniversarys=[];
      this.Noti = x;
      Object.keys(this.Noti).forEach( key => 
      {
        this.Noti[key];
        let Notitemp = new BirthDayAnniversaryModel();
        Notitemp = this.Noti[key];
        this.MarriageAnniversarys.push(Notitemp);
      });
    })
  }
  GetEmployeeWorkAnniversaryForDashBoard()
  {  
    this.WorkAnniversarys=[];
    let t =  localStorage.getItem('userToken');
    t = 'Bearer ' + t;
    this.userService.GetEmployeeWorkAnniversaryForDashBoard(t).then(x => 
    {
      this.WorkAnniversarys=[];
      this.Noti = x;
      Object.keys(this.Noti).forEach( key => 
      {
        this.Noti[key];
        let Notitemp = new BirthDayAnniversaryModel();
        Notitemp = this.Noti[key];
        this.WorkAnniversarys.push(Notitemp);
      });
    })
  }

  download(fileName)
  {
    //this.AdminMasterService.getFile(fileName);  
    window.open(fileName, '_blank');
  }

  GetHRPoliciesForms()
  {  
    this.HRPolicies=[]
    let t =  localStorage.getItem('userToken');
    t = 'Bearer ' + t;
    this.AdminMasterService.GetHRPoliciesFormGV(t).then(x => 
    {
      this.Noti = x;
      Object.keys(this.Noti).forEach( key => 
      {
        this.PolicyCount=this.PolicyCount+1;
        this.Noti[key];
        let Notitemp = new HRPoliciesFormModel();
        Notitemp = this.Noti[key];
        this.HRPolicies.push(Notitemp);
      });
    })
  }

  getFormattedDate(date)
  {
    let newDate = new Date(date);
    return  newDate.getFullYear() + '-'+ ((newDate.getMonth() > 8) ? (newDate.getMonth() + 1) : ('0' + (newDate.getMonth() + 1))) + '-' + ((newDate.getDate() > 9) ? newDate.getDate() : ('0' + newDate.getDate()));
  }
  
  GetAttendanceDetailsForDashboard(AttendanceDate)
  {  
    this.Attendances=[];
    let t =  localStorage.getItem('userToken');
    t = 'Bearer ' + t;
    this.AdminMasterService.GetAttendanceDetailsForDashboard(t,AttendanceDate).then(x => 
    {
      this.Attendances=[];
      this.Noti = x;
      Object.keys(this.Noti).forEach( key => 
      {
        this.Noti[key];
        let Notitemp = new AttedanceCalendarModel();
        Notitemp = this.Noti[key];
        this.Attendances.push(Notitemp);
      });
      let itemList = []
      this.Attendances.forEach((key) => 
      {
        itemList.push({
        title: key.AttendanceStatus,
        description:key.InTime,
        start: this.getFormattedDate(key.AttendanceDate),
        end: this.getFormattedDate(key.AttendanceDate),
        textColor: key.AttendanceColor,
        // borderColor: 'orange',
        color: 'white',
      });
    })
    //console.log(itemList);
      this.options = 
      {
        fixedWeekCount : false,
        editable: false,
        events: itemList,
        header: {
          left: 'title',
          //center: 'title',
          right: 'prev today myCustomButton next'
        },
        plugins: [dayGridPlugin, interactionPlugin]
      };
    })
  }

  GETNotificationForFormRaise()
  {  
    this.FormsRaised=[];
    let t =  localStorage.getItem('userToken');
    t = 'Bearer ' + t;
    this.userService.GETNotificationForFormRaise(t).then(x => 
    {
      this.FormsRaised=[];
      this.Noti = x;
      Object.keys(this.Noti).forEach( key => 
      {
        this.Noti[key];
        let Notitemp = new NotificationModel();
        Notitemp = this.Noti[key];
        this.FormsRaised.push(Notitemp);
      });
    })
  }
}
