import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs/Subject';
import { DataTableDirective } from 'angular-datatables';
import { OrganizationService } from '../shared/Organization/organization.service';
import { LocationService } from '../shared/HR_ADMIN/Location/location.service';
import { Country } from '../shared/Organization/Country.Model';
import { Zone } from '../shared/Organization/Zone.Model';
import { State } from '../shared/Organization/State.Model';
import { UserService } from '../shared/user.service';
// Begning Confirmation
import { ConfirmationService } from '@jaspero/ng2-confirmations';
import { ResolveEmit } from '../../app/resolve-emit';
import { ConfirmSettings } from '../../app/confirm-settings';
// end of Confirmation
import { Menu } from '../shared/menu.model';

@Component
({
  selector: 'app-manage-state',
  templateUrl: './manage-state.component.html',
  styleUrls: ['./manage-state.component.css']
})

export class ManageStateComponent implements OnInit 
{
  StateModel : State= new State();
  userClaims: any;
  Countries: Country[] = [];
  Zones: Zone[] = [];   
  States: State[] = []; 
  Result : string;
  SuccessStatus:boolean=false;
  SuccessMessage: string ="";
  public tableWidget: any;
  countryId : any;
  country : any;
  StateId : any;
  state: any;
  zone : any;
  tempStateadd : State =new State();
  // Begning Permission Portion    
  SelectedMenu:Menu;
  IsDataIsInViewState : boolean=false;
  CanViewState :boolean=false;
  DisableSave : boolean=false;
  FormViewShow : boolean =false;
  CanSaveButtonView : boolean =false;
  // End of the permission portion
  //check for duplicate Name
  CheckNameExiststatus : boolean=null;  
  CheckNamestatusedit : boolean =false;
  Namecheck : string ="";
  checkName : any;
  checkNameval : string ="";
  //check for duplicate Name

  constructor(private organizationService: OrganizationService, private rout: Router,
    private LocationService : LocationService,  private _confirmation: ConfirmationService, private userService: UserService  ){}

  @ViewChild(DataTableDirective, {static: false})
  setDtOptions: DataTables.Settings = {};
  cardDtOptions: DataTables.Settings = {};
  dtElement: DataTableDirective;
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();

  ngOnInit() 
  {
    // Begning Permission Portion 
    this.SelectedMenu=this.userService.SelectedMenu;
    if(this.SelectedMenu !=undefined)
    {
      localStorage.setItem('selectedmenu',JSON.stringify(this.SelectedMenu));
      this.rout.navigate([this.SelectedMenu.RouteName]);
    }
    else
    {
      if(JSON.parse(localStorage.getItem('selectedmenu'))!=undefined)
      {
        this.SelectedMenu = JSON.parse(localStorage.getItem('selectedmenu'));
        this.rout.navigate([this.SelectedMenu.RouteName]);
      }
    }
    if(this.SelectedMenu.CanAdd)
    {
      this.IsDataIsInViewState=false;
      this.CanSaveButtonView=true;
    }
    else
    {
      this.IsDataIsInViewState=true;
    }
    if(this.SelectedMenu.CanView)
    {
      this.CanViewState=false;
    }
    else
    {
      this.CanViewState=true;
    }
    // End of the permission portion
    this.DisableSave=true;
    this.FormViewShow=false;
    this.getCountry();
    this.dtOptions = 
    {
      pagingType: 'full_numbers',
      pageLength: 8
    };
    this.StateModel.CountryId=0;
    if(localStorage.getItem('SuccessfullMessage'))
    {
      this.SuccessStatus=true;
      this.SuccessMessage =localStorage.getItem('SuccessfullMessage')
      localStorage.removeItem('SuccessfullMessage');
    }
  }

  settings: ConfirmSettings | any = 
  {
    overlay: true,
    overlayClickToClose: true,
    showCloseButton: true,
    confirmText: 'Yes',
    declineText: 'No'
  };

  ngAfterViewInit(): void 
  {
    this.getStates();
    this.dtOptions = 
    {
      pagingType: 'full_numbers',
      pageLength: 8
    };
  }

  getStates()
  {
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.LocationService.GetState( t).then(x => 
    {
      this.state = x;
      Object.keys(this.state).forEach( key => 
      {
        this.state[key];
        let statetemp = new State();
        statetemp = this.state[key];
        this.States.push(statetemp);
      });
      this.dtTrigger.next();
    });
  }

  getCountry() 
  {
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.organizationService.GetCountry( t).then(x => 
    {
      this.country = x;
      Object.keys(this.country).forEach( key => 
      {
        this.country[key];
        let countrytemp = new Country();
        countrytemp = this.country[key];
        this.Countries.push(countrytemp);
      });
    });      
  } 

  onCountryChange(countryid) 
  {
    if(countryid!=0)
    {
      this.getZones(countryid);
      this.StateModel.ZoneId=0;
    }
    else
    {
      this.getZones(countryid);
      this.StateModel.ZoneId=0;
    }
  }

  getZones( countryid) 
  {
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t
    if(this.Zones.length>0)
    {
      this.Zones.splice(0, this.Zones.length);
    }
    this.LocationService.GetZoneByCountryId( t,countryid).then(x => 
    {
      this.zone = x;
      Object.keys(this.zone).forEach( key => 
      {
        this.zone[key];
        let zonetemp = new Zone();
        zonetemp = this.zone[key];
        this.Zones.push(zonetemp);
      });     
    });      
  }

  ADDClick()
  {
    this.FormViewShow=true;
    this.CanSaveButtonView=false;
  }

  GetStateByid(id) 
  {
    // begning of the permission portion
    this.IsDataIsInViewState=false;
    // end of the permission portion
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.LocationService.GetStateByid( t,id).then(x => 
    {
      this.FormViewShow=true;
      this.CanSaveButtonView=false;
      this.state = x;
      Object.keys(this.state).forEach( key => 
      {
        this.state[key];
        let Statetemp = new State();
        Statetemp = this.state[key];
        this.StateModel.StateID=Statetemp.StateID;
        this.StateModel.StateCode=Statetemp.StateCode;
        this.StateModel.StateName=Statetemp.StateName;
        this.StateModel.CountryId=Statetemp.CountryId;
        this.StateModel.ZoneId=Statetemp.ZoneId; 
        this.StateModel.ZoneName=Statetemp.ZoneName; 
        this.getZones(this.StateModel.CountryId);
      });
      // Begin for check Name duplication
      this.checkNameval= this.StateModel.ZoneName;
      this.CheckNamestatusedit=true;
      this.CheckNameExiststatus =true;
      this.Namecheck=""
      // End for check Name duplication 
    });
  }

  ViewStateByid(id) 
  {
    // begning of the permission portion
    this.IsDataIsInViewState=true;
    // end of the permission portion
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.LocationService.GetStateByid( t,id).then(x => 
    {
      this.FormViewShow=true;
      this.CanSaveButtonView=false;
      this.state = x;
      Object.keys(this.state).forEach( key => 
      {
        this.state[key];
        let Statetemp = new State();
        Statetemp = this.state[key];
        this.StateModel.StateID=Statetemp.StateID;
        this.StateModel.StateCode=Statetemp.StateCode;
        this.StateModel.StateName=Statetemp.StateName;
        this.StateModel.CountryId=Statetemp.CountryId;
        this.StateModel.ZoneId=Statetemp.ZoneId; 
        this.StateModel.ZoneName=Statetemp.ZoneName; 
        this.getZones(this.StateModel.CountryId);
      }); 
      // Begin for check Name duplication
      this.checkNameval= this.StateModel.ZoneName;
      this.CheckNamestatusedit=true;
      this.CheckNameExiststatus =true;
      this.Namecheck=""
      // End for check Name duplication 
    });
  }

  saveState() :void
  {
    this.DisableSave=false;
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.StateModel.IsActive=true;
    if(this.StateModel.StateID > 0)
    {
      this.LocationService.UpdateState(t ,this.StateModel).subscribe(res=>
      {
        this.StateId=res;
        this.StateModel.StateID=0;  
        if(this.StateId>0)
        {
          localStorage.setItem('SuccessfullMessage', "Update Successfully");
          this.rout.navigateByUrl('/Location/ManageCountry', {skipLocationChange: true}).then(()=>this.rout.navigate(["/Location/ManageState"]));
        }
      });                  
    }
    else
    {
      this.LocationService.AddState(t ,this.StateModel).subscribe(res=>
      {
        this.StateId=res;
        this.StateModel.StateID=0;                
        if(this.StateId>0)
        {
          localStorage.setItem('SuccessfullMessage', "Save Successfully");
          this.rout.navigateByUrl('/Location/ManageCountry', {skipLocationChange: true}).then(()=>this.rout.navigate(["/Location/ManageState"]));
        }
      }); 
    }
  }

  DeleteStateByid(Stateid : number) :void
  {  
    this._confirmation.create('Confirm to Deleate', 'Press Yes to Delete', this.settings)
    .subscribe((ans: ResolveEmit) => 
    {
      if (ans.resolved == true)
      {
        let t =  localStorage.getItem('userToken');
        t = 'Bearer' + t;
        this.StateModel.IsActive=true;
        if( Stateid > 0)
        {
          this.LocationService.DeleteState(t ,Stateid).subscribe(res=>
          {
            this.StateId=res;
            this.StateModel.StateID=0;                                  
            if(this.StateId >0)
            {
              localStorage.setItem('SuccessfullMessage', "Deleted Successfully");
              this.rout.navigateByUrl('/Location/ManageCountry', {skipLocationChange: true}).then(()=>this.rout.navigate(["/Location/ManageState"]));
            }
          });                  
        }
      }
    })
  }
  
  RefreshPage() 
  {       
    this.rout.navigateByUrl('/Location/ManageCountry', {skipLocationChange: true}).then(()=>this.rout.navigate(["/Location/ManageState"]));
  }
  // begning of check duplicate Name
  CheckName()
  {
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    if(!this.CheckNamestatusedit)
    {
      this.LocationService.CheckStateName( t, this.StateModel.StateName,this.StateModel.CountryId).then(x => 
      {
        this.checkName = x;
        if( this.checkName.length >0)
        {
          this.CheckNameExiststatus =false;
          this.Namecheck="Exist"
        }
        else
        {
          this.CheckNameExiststatus =true;
          this.Namecheck="";
        }
     });
    }
    else
    {
      if(this.StateModel.StateName !=this.checkNameval )
      {
        this.LocationService.CheckStateName( t, this.StateModel.StateName,this.StateModel.CountryId).then(x => 
        {
          this.checkName = x;
          if( this.checkName.length >0)
          {
            this.CheckNameExiststatus =false;
            this.Namecheck="Exist"
          }
          else
          {
            this.CheckNameExiststatus =true;
            this.Namecheck="";
          }
        });
      }
      else
      {
        this.CheckNameExiststatus =true;
        this.Namecheck="";
      }
    }
  }
  // end of duplicate Name
}

