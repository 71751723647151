import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from '../../shared/user.service';
import { EmployeePortalService }  from '../../shared/HR_ADMIN/EmployeePortal/EmployeePortalService.service';
import { EmployeeMasterService }  from '../../shared/HR_ADMIN/Employee/EmployeeMaster.Service';
import { EmployeeDetail } from '../../shared/HR_ADMIN/Employee/EmployeeDetail.Model';
import { EmployeeCompoffModel } from '../../shared/HR_ADMIN/EmployeePortal/EmployeeCompoffModel.model';
import { AmazingTimePickerService } from 'amazing-time-picker';
import { Menu } from '../../shared/menu.model';

@Component({
  selector: 'app-all-employee-compoff-application',
  templateUrl: './all-employee-compoff-application.component.html',
  styleUrls: ['./all-employee-compoff-application.component.css']
})
export class AllEmployeeCompoffApplicationComponent implements OnInit 
{
  EmployeeCompoffModel : EmployeeCompoffModel= new EmployeeCompoffModel();
 
  EmployeeCompoff: EmployeeCompoffModel[]=[];
  Compoff: any;
  RefId : any;

  Employees: EmployeeDetail[] = [];
  employee: any;
  userClaims: any;

  Result : string;
  SuccessStatus:boolean=false;
  SuccessMessage: string ="";
  public tableWidget: any;
  selectedIndex : number;
  // Begning Permission Portion    
  SelectedMenu:Menu;
  IsDataIsInViewState : boolean=false;
  CanViewState : boolean=true;
  IsApplicationView : boolean=false;
  // End of the permission portion
  // begin for Validation
  validdate : boolean= false;
  DisableSave: boolean=false;
  // end of validation
  HalfDay : boolean= false;
  FullDay : boolean= true;
  FromValue:string='FullDay';
  ShowMessage : boolean = false;
  IsOffDay : boolean= true;

  EmployeeSettings = {};
  selectedItems = [];
  emp : any [] =[]

  constructor(private EmployeeMasterService: EmployeeMasterService, private rout: Router, private userService: UserService, private atp: AmazingTimePickerService, private EmployeePortalService: EmployeePortalService) 
  {  }

  ngOnInit() 
  {
    // Begning Permission Portion 
    this.SelectedMenu=this.userService.SelectedMenu;
    if(this.SelectedMenu !=undefined)
    {
      localStorage.setItem('selectedmenu',JSON.stringify(this.SelectedMenu));
      this.rout.navigate([this.SelectedMenu.RouteName]);
    }
    else
    {
      if(JSON.parse(localStorage.getItem('selectedmenu'))!=undefined)
      {
        this.SelectedMenu = JSON.parse(localStorage.getItem('selectedmenu'));
        this.rout.navigate([this.SelectedMenu.RouteName]);
      }
    }
    if(this.SelectedMenu.CanAdd)
    {
      this.IsDataIsInViewState=false;
    }
    else
    {
      this.IsDataIsInViewState=true;
    }
    if(this.SelectedMenu.CanView)
    {
      this.CanViewState=false;
    }
    else
    {
      this.CanViewState=true;
    }
    // End of the permission portion
    if(localStorage.getItem('SuccessfullMessage'))
    {
      this.SuccessStatus=true;
      this.SuccessMessage =localStorage.getItem('SuccessfullMessage')
      localStorage.removeItem('SuccessfullMessage');
    }
    this.GetEmployeeDetailsDDL();
    this.EmployeeCompoffModel.EmployeeId=0;
    this.EmployeeCompoffModel.Fromvalue="FullDay";
    this.DisableSave=true;
    this.EmployeeSettings =
    {
      singleSelection:true,
      text:'Select Employee',
      enableSearchFilter:true,
      showCheckbox:false
    }
  }

  GetEmployeeDetailsDDL() 
  {
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.EmployeeMasterService.GetEmployeeDetailsForDDL( t).then(x => 
    {
      this.employee = x;
      Object.keys(this.employee).forEach( key => 
      {
        let emptemp = new EmployeeDetail();
        emptemp = this.employee[key];
        this.Employees.push(emptemp);
        this.emp.push({"id":emptemp.EmployeeId,"itemName":emptemp.EmployeeCode + '-' +emptemp.EmployeeName},);
      });     
    });      
  }

  onItemSelect(item:any)
  {
    let EmployeeId= item['id'];
    if(EmployeeId>0)
    {
      this.IsApplicationView=true;
      this.ClearText();
      this.GetEmployeeDetails(EmployeeId);
      this.EmployeeCompoffModel.EmployeeId=EmployeeId;
    }     
  }
  OnItemDeSelect(item:any){ }
  onSelectAll(items: any){ }
  onDeSelectAll(items: any){ }

  GetEmployeeAttendanceDetails(AttendanceDate)
  {
    this.DisableSave=false;
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.EmployeePortalService.GetEmployeeDetailsByEmployeeIdCompoff( t,this.EmployeeCompoffModel.EmployeeId,AttendanceDate).then(x => 
    {
      this.Compoff = x;
      Object.keys(this.Compoff).forEach( key => 
      {
        this.Compoff[key];
        let Compofftemp = new EmployeeCompoffModel();
        Compofftemp = this.Compoff[key];
        this.EmployeeCompoffModel.AttendanceDay=this.Compoff.AttendanceDay;
        this.EmployeeCompoffModel.ActualInTime=this.Compoff.ActualInTime;
        this.EmployeeCompoffModel.ActualOutTime=this.Compoff.ActualOutTime;
        this.EmployeeCompoffModel.ActualWorkingDuration=this.Compoff.ActualWorkingDuration;
        this.EmployeeCompoffModel.Status=this.Compoff.Status;
        this.ShowMessage=this.Compoff.AppliedStatus;
      });
    });
    this.DisableSave=true;
  }

  GetEmployeeDetails(EmployeeId)
  {
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.EmployeePortalService.GetEmployeeDetailsByEmployeeIdLeaveApply( t,EmployeeId).then(x => 
    {
      this.Compoff = x;
      Object.keys(this.Compoff).forEach( key => 
      {
        this.Compoff[key];
        let Compofftemp = new EmployeeCompoffModel();
        Compofftemp = this.Compoff[key];
        this.EmployeeCompoffModel.EmployeeId=this.Compoff.EmployeeId;
        this.EmployeeCompoffModel.EmployeeCode=this.Compoff.EmployeeCode;
        this.EmployeeCompoffModel.EmployeeName=this.Compoff.EmployeeName;
        this.EmployeeCompoffModel.EmployeeEmailId=this.Compoff.EmployeeEmailId;
        this.EmployeeCompoffModel.RHId=this.Compoff.RHId;
        this.EmployeeCompoffModel.RHCode=this.Compoff.RHCode;
        this.EmployeeCompoffModel.RHName=this.Compoff.RHName;
        this.EmployeeCompoffModel.RHEmailId=this.Compoff.RHEmailId;
        this.EmployeeCompoffModel.CompanyId=this.Compoff.CompanyId;
      });
    });
  }

  AttendanceDateChanged( date)
  {
    var CurrentDate = new Date();
    var selectedDate = new Date(date);
    let selecteddateString = selectedDate.toDateString();
    let newselectedDate = new Date(selecteddateString);
    let CurrentdateString = CurrentDate.toDateString(); 
    let newCurrentDate = new Date(CurrentdateString);
    if (newselectedDate > newCurrentDate)
    {
      this.validdate = true;
    }
    else
    {
      this.DisableSave=false;
      let t =  localStorage.getItem('userToken');
      t = 'Bearer' + t;
      this.EmployeePortalService.EmployeeWorkingDayStatus( t,this.EmployeeCompoffModel.EmployeeId,date).then(x => 
      {
        this.Compoff = x;
        Object.keys(this.Compoff).forEach( key => 
        {
          this.IsOffDay = this.Compoff[key].IsOffday;
          if(this.IsOffDay == true)
          {
            this.ClearText();
            this.ShowMessage=false;
            this.EmployeeCompoffModel.AttendanceDate=date;
            this.GetEmployeeAttendanceDetails(date);
            this.DisableSave=true;
          }
          else
          {
            this.EmployeeCompoffModel.AttendanceDay='';
            this.EmployeeCompoffModel.Status='';
            this.EmployeeCompoffModel.ActualInTime=null;
            this.EmployeeCompoffModel.ActualOutTime=null;
            this.EmployeeCompoffModel.ActualWorkingDuration=null;
            this.DisableSave=true;
          }
        });
      });
    }
  }

  // AttendanceDateChanged( date)
  // {
  //   this.DisableSave=false;
  //   let t =  localStorage.getItem('userToken');
  //   t = 'Bearer' + t;
  //   this.EmployeePortalService.EmployeeWorkingDayStatus( t,this.EmployeeCompoffModel.EmployeeId,date).then(x => 
  //   {
  //     this.Compoff = x;
  //     Object.keys(this.Compoff).forEach( key => 
  //     {
  //       this.IsOffDay = this.Compoff[key].IsOffday;
  //       if(this.IsOffDay == true)
  //       {
  //         this.ClearText();
  //         this.ShowMessage=false;
  //         this.EmployeeCompoffModel.AttendanceDate=date;
  //         var CurrentDate = new Date();
  //         var CurrentYear = CurrentDate.getFullYear();
  //         var currentMonth = CurrentDate.getMonth();
  //         var CurrentDay = CurrentDate.getDate();
          
  //         var selectedDate = new Date(date);
  //         var selectedYear=selectedDate.getFullYear();
  //         var selectedMonth=selectedDate.getMonth();
  //         var selectedDay=selectedDate.getDate();
      
  //         let selecteddateString = selectedDate.toDateString();
  //         let newselectedDate = new Date(selecteddateString);
  //         let CurrentdateString = CurrentDate.toDateString(); 
  //         let newCurrentDate = new Date(CurrentdateString);
  //         if (newselectedDate >= newCurrentDate)
  //         {
  //           this.validdate = true;
  //         }
  //         else if(CurrentYear != selectedYear )
  //         {
  //         this.validdate = true;      
  //         }
  //         else
  //         {
  //           if (currentMonth > selectedMonth)
  //           {
  //             this.validdate = false;
  //           }
  //           else
  //           {
  //             if(CurrentDay >= selectedDay )
  //             {
  //               this.validdate = false;
  //             }
  //             else
  //             {
  //               this.validdate = true;
  //             }
  //           }
  //         }
  //         if(this.validdate == false)
  //         {
  //           this.GetEmployeeAttendanceDetails(date);
  //         }
  //         this.DisableSave=true;
  //       }
  //       else
  //       {
  //         this.EmployeeCompoffModel.AttendanceDay='';
  //         this.EmployeeCompoffModel.Status='';
  //         this.EmployeeCompoffModel.ActualInTime=null;
  //         this.EmployeeCompoffModel.ActualOutTime=null;
  //         this.EmployeeCompoffModel.ActualWorkingDuration=null;
  //         this.DisableSave=true;
  //       }
  //     });
  //   });
  // }

  SaveAttendanceCompOffApply() :void
  {
    this.DisableSave=false;
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.EmployeePortalService.AddEmployeeCompoff(t ,this.EmployeeCompoffModel).subscribe(res=>
    {
      this.RefId=res;
      this.EmployeeCompoffModel.RefId=0;              
      if(this.RefId>0)
      {
        localStorage.setItem('SuccessfullMessage', "Compoff Applied Successfully");
        this.rout.navigateByUrl('/Employee/EmployeeOffer', {skipLocationChange: true}).then(()=>this.rout.navigate(["/Employee/AllEmployeeCompoffApplication"]));
      }
      else
      {
        this.ShowMessage=true;
        this.DisableSave=true;
      }
    }); 
  }

  OnChangeHalfDay(evt)
  {
    var target = evt.target;
    if(target.checked)
    {           
      this.EmployeeCompoffModel.HalfDay=true;
      this.EmployeeCompoffModel.FullDay=false; 
      this.EmployeeCompoffModel.Fromvalue="HalfDay";
    }
  }

  OnChangeFullDay(evt)
  {
    var target = evt.target;
    if(target.checked)
    {           
      this.EmployeeCompoffModel.HalfDay=false;
      this.EmployeeCompoffModel.FullDay=true; 
      this.EmployeeCompoffModel.Fromvalue="FullDay";
    }
  }
  
  RefreshPage()
  {
    this.rout.navigateByUrl('/Employee/EmployeeOffer', {skipLocationChange: true}).then(()=>this.rout.navigate(["/Employee/AllEmployeeCompoffApplication"]));
  }

  ClearText()
  {
    this.EmployeeCompoffModel.AttendanceDate=null;
    this.EmployeeCompoffModel.AttendanceDay="";
    this.EmployeeCompoffModel.Status="";
    this.EmployeeCompoffModel.ActualInTime=null;
    this.EmployeeCompoffModel.ActualOutTime=null;
    this.EmployeeCompoffModel.ActualWorkingDuration=0;
  }
}