import { Injectable,Inject } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';  
import { Http, Headers, RequestOptions, Response } from '@angular/http';  
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/toPromise';
import { Subscriber } from 'rxjs';
import { GlobalVariable  } from '../../../app.global';
import { WorkingHours } from   '../Attendance/WorkingHours.model';
import { WorkingGroupHours } from   '../Attendance/WorkingGroupHours.model';
import { AttendanceFineRule } from   '../Attendance/AttendanceFineRule.model';
import { MapFineRule } from   '../Attendance/MapFineRule.model';
import { MapWorkingGroup } from   '../Attendance/MapWorkingGroup.model';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';

@Injectable()

export class AttendanceService 
{
    constructor(private http: HttpClient,private spinnerService: Ng4LoadingSpinnerService) 
    {
    }
    // Begning of the Working Hours
    GetWorkingHours(token )
    {
        this.spinnerService.show();
        const authHeader = new HttpHeaders();     
        authHeader.append('Content-Type', 'application/json');
        authHeader.append('Authorization', token);
        return new Promise((resolve, reject) =>
        {
            setTimeout(()=>{

            this.http.get(GlobalVariable.BASE_API_URL +'/api/WorkingHours/GetWorkingHours',{headers: authHeader})
            .subscribe(data => 
            {
                this.spinnerService.hide();
                resolve(data)
            },
            error => 
            {
                this.spinnerService.hide();
                reject(error);
            },);

        },3000);

        });
    }
    GetWorkingHoursByid(token, id:number ) 
    {
        this.spinnerService.show();
        const authHeader = new HttpHeaders();     
        authHeader.append('Content-Type', 'application/json');
        authHeader.append('Authorization', token);
        return new Promise((resolve, reject) => 
        {
            setTimeout(()=>{
            this.http.get(GlobalVariable.BASE_API_URL +'/api/WorkingHours/GetWorkingHoursById?WorkingHoursId='+id,{headers: authHeader})
            .subscribe(data => 
            {
                this.spinnerService.hide();
                resolve(data)
            },
            error => 
            {
                this.spinnerService.hide();
                reject(error);
            },);


        },3000);
        });
    }
    AddWorkingHours( token ,WorkingHours :WorkingHours) 
    {
        const authHeader = new HttpHeaders();         
        authHeader.append('Content-Type', 'application/json');
        authHeader.append('Authorization', token);
        authHeader.append('Accept', 'application/json');
        return this.http.post(GlobalVariable.BASE_API_URL +'/api/WorkingHours/Add', WorkingHours ,{headers: authHeader} )
        .map(this.extractData)
        .catch(this.handleErrorObservable);
    }
    DeleteWorkingHours( token ,WorkingHoursid : number) 
    {
        const authHeader = new HttpHeaders();         
        authHeader.append('Content-Type', 'application/json');
        authHeader.append('Authorization', token);
        authHeader.append('Accept', 'application/json');
        return this.http.put(GlobalVariable.BASE_API_URL +'/api/WorkingHours/Delete', WorkingHoursid ,{headers: authHeader} )
        .map(this.extractData)
        .catch(this.handleErrorObservable);
    }
    UpdateWorkingHours( token ,WorkingHours :WorkingHours) 
    {
        const authHeader = new HttpHeaders();         
        authHeader.append('Content-Type', 'application/json');
        authHeader.append('Authorization', token);
        authHeader.append('Accept', 'application/json');
        return this.http.put(GlobalVariable.BASE_API_URL +'/api/WorkingHours/Update', WorkingHours ,{headers: authHeader} )
        .map(this.extractData)
        .catch(this.handleErrorObservable);
    }
    // End of the Working Hours
    
    // Begning of the Working Group Hours
    GetWorkingGroupHours(token )
    {
        this.spinnerService.show();
        const authHeader = new HttpHeaders();     
        authHeader.append('Content-Type', 'application/json');
        authHeader.append('Authorization', token);
        return new Promise((resolve, reject) =>
        {
            setTimeout(()=>{
            this.http.get(GlobalVariable.BASE_API_URL +'/api/WorkingGroupHours/GetWorkingGroupHours',{headers: authHeader})
            .subscribe(data => 
            {
                this.spinnerService.hide();
                resolve(data)
            },
            error => 
            {
                this.spinnerService.hide();
                reject(error);
            },);
        },3000);
        });
    }
    GetWorkingGroupHoursByid(token, id:number ) 
    {
        this.spinnerService.show();
        const authHeader = new HttpHeaders();     
        authHeader.append('Content-Type', 'application/json');
        authHeader.append('Authorization', token);
        return new Promise((resolve, reject) => 
        {
            setTimeout(()=>{
            this.http.get(GlobalVariable.BASE_API_URL +'/api/WorkingGroupHours/GetWorkingGroupHoursById?WorkingGroupId='+id,{headers: authHeader})
            .subscribe(data => 
            {
                this.spinnerService.hide();
                resolve(data)
            },
            error => 
            {
                this.spinnerService.hide();
                reject(error);
            },);
        },3000);
        });
    }
    AddWorkingGroupHours( token ,WorkingGroupHours :WorkingGroupHours) 
    {
        const authHeader = new HttpHeaders();         
        authHeader.append('Content-Type', 'application/json');
        authHeader.append('Authorization', token);
        authHeader.append('Accept', 'application/json');
        return this.http.post(GlobalVariable.BASE_API_URL +'/api/WorkingGroupHours/Add', WorkingGroupHours ,{headers: authHeader} )
        .map(this.extractData)
        .catch(this.handleErrorObservable);
    }
    DeleteWorkingGroupHours( token ,WorkingGroupHoursid : number) 
    {
        const authHeader = new HttpHeaders();         
        authHeader.append('Content-Type', 'application/json');
        authHeader.append('Authorization', token);
        authHeader.append('Accept', 'application/json');
        return this.http.put(GlobalVariable.BASE_API_URL +'/api/WorkingGroupHours/Delete', WorkingGroupHoursid ,{headers: authHeader} )
        .map(this.extractData)
        .catch(this.handleErrorObservable);
    }
    UpdateWorkingGroupHours( token ,WorkingGroupHours :WorkingGroupHours) 
    {
        const authHeader = new HttpHeaders();         
        authHeader.append('Content-Type', 'application/json');
        authHeader.append('Authorization', token);
        authHeader.append('Accept', 'application/json');
        return this.http.put(GlobalVariable.BASE_API_URL +'/api/WorkingGroupHours/Update', WorkingGroupHours ,{headers: authHeader} )
        .map(this.extractData)
        .catch(this.handleErrorObservable);
    }
    GetDays(token )
    {
        const authHeader = new HttpHeaders();     
        authHeader.append('Content-Type', 'application/json');
        authHeader.append('Authorization', token);
        return new Promise((resolve, reject) =>
        {
            setTimeout(()=>{
            this.http.get(GlobalVariable.BASE_API_URL +'/api/WorkingGroupHours/GetDays',{headers: authHeader})
            .subscribe(data => 
            {
                resolve(data)
            },
            error => 
            {
                reject(error);
            },);
        },3000);
        });
    }
    // End of the Working Group Hours

    // Begning of the AttendanceFineRule
    GetAttendanceFineRule(token )
    {
        this.spinnerService.show();
        const authHeader = new HttpHeaders();     
        authHeader.append('Content-Type', 'application/json');
        authHeader.append('Authorization', token);
        return new Promise((resolve, reject) =>
        {  setTimeout(()=>{
            this.http.get(GlobalVariable.BASE_API_URL +'/api/AttendanceFineRule/GetAttendanceFineRule',{headers: authHeader})
            .subscribe(data => 
            {
                this.spinnerService.hide();
                resolve(data)
            },
            error => 
            {
                this.spinnerService.hide();
                reject(error);
            },);
        },3000);
        });
    }
    GetAttendanceFineRuleByid(token, id:number ) 
    {
        this.spinnerService.show();
        const authHeader = new HttpHeaders();     
        authHeader.append('Content-Type', 'application/json');
        authHeader.append('Authorization', token);
        return new Promise((resolve, reject) => 
        {
            setTimeout(()=>{
            this.http.get(GlobalVariable.BASE_API_URL +'/api/AttendanceFineRule/GetAttendanceFineRuleById?AttFineRuleId='+id,{headers: authHeader})
            .subscribe(data => 
            {
                this.spinnerService.hide();
                resolve(data)
            },
            error => 
            {
                this.spinnerService.hide();
                reject(error);
            },);

        },3000);

        });
    }
    AddAttendanceFineRule( token ,AttendanceFineRule :AttendanceFineRule) 
    {
        const authHeader = new HttpHeaders();         
        authHeader.append('Content-Type', 'application/json');
        authHeader.append('Authorization', token);
        authHeader.append('Accept', 'application/json');
        return this.http.post(GlobalVariable.BASE_API_URL +'/api/AttendanceFineRule/Add', AttendanceFineRule ,{headers: authHeader} )
        .map(this.extractData)
        .catch(this.handleErrorObservable);
    }
    DeleteAttendanceFineRule( token ,AttendanceFineRuleid : number) 
    {
        const authHeader = new HttpHeaders();         
        authHeader.append('Content-Type', 'application/json');
        authHeader.append('Authorization', token);
        authHeader.append('Accept', 'application/json');
        return this.http.put(GlobalVariable.BASE_API_URL +'/api/AttendanceFineRule/Delete', AttendanceFineRuleid ,{headers: authHeader} )
        .map(this.extractData)
        .catch(this.handleErrorObservable);
    }
    UpdateAttendanceFineRule( token ,AttendanceFineRule :AttendanceFineRule) 
    {
        const authHeader = new HttpHeaders();         
        authHeader.append('Content-Type', 'application/json');
        authHeader.append('Authorization', token);
        authHeader.append('Accept', 'application/json');
        return this.http.put(GlobalVariable.BASE_API_URL +'/api/AttendanceFineRule/Update', AttendanceFineRule ,{headers: authHeader} )
        .map(this.extractData)
        .catch(this.handleErrorObservable);
    }
    GetFineName(token )
    {
        const authHeader = new HttpHeaders();     
        authHeader.append('Content-Type', 'application/json');
        authHeader.append('Authorization', token);
        return new Promise((resolve, reject) =>
        {
            setTimeout(()=>{
            this.http.get(GlobalVariable.BASE_API_URL +'/api/AttendanceFineInTerm/GetFineName',{headers: authHeader})
            .subscribe(data => 
            {
                resolve(data)
            },
            error => 
            {
                reject(error);
            },);
        },3000);
        });
    }
    GetFineInTerm(token )
    {
        const authHeader = new HttpHeaders();     
        authHeader.append('Content-Type', 'application/json');
        authHeader.append('Authorization', token);
        return new Promise((resolve, reject) =>
        {
            setTimeout(()=>{
            this.http.get(GlobalVariable.BASE_API_URL +'/api/AttendanceFineInTerm/GetAttendanceFineInTerm',{headers: authHeader})
            .subscribe(data => 
            {
                resolve(data)
            },
            error => 
            {
                reject(error);
            },);
        },3000);
        });
    }
    // End of the Attendance Fine Rule

    // Begning of the MapFineRule
    GetMapFineRule(token )
    {    this.spinnerService.show();
        const authHeader = new HttpHeaders();     
        authHeader.append('Content-Type', 'application/json');
        authHeader.append('Authorization', token);
        return new Promise((resolve, reject) =>
        {
            setTimeout(()=>{
            this.http.get(GlobalVariable.BASE_API_URL +'/api/AttendanceCompanyFineRule/GetAttendanceCompanyFineRule',{headers: authHeader})
            .subscribe(data => 
            {
                this.spinnerService.hide();
                resolve(data)
            },
            error => 
            {
                this.spinnerService.hide();
                reject(error);
            },);
        },3000);
        });
    }
    GetMapFineRuleByid(token, id:number ) 
    {
        this.spinnerService.show();
        const authHeader = new HttpHeaders();     
        authHeader.append('Content-Type', 'application/json');
        authHeader.append('Authorization', token);
        return new Promise((resolve, reject) => 
        {
            setTimeout(()=>{
            this.http.get(GlobalVariable.BASE_API_URL +'/api/AttendanceCompanyFineRule/GetAttendanceCompanyFineRuleById?RefId='+id,{headers: authHeader})
            .subscribe(data => 
            {
                this.spinnerService.hide();
                resolve(data)
            },
            error => 
            {
                this.spinnerService.hide();
                reject(error);
            },);
        },3000);
        });
    }
    AddMapFineRule( token ,MapFineRule :MapFineRule) 
    {
        const authHeader = new HttpHeaders();         
        authHeader.append('Content-Type', 'application/json');
        authHeader.append('Authorization', token);
        authHeader.append('Accept', 'application/json');
        return this.http.post(GlobalVariable.BASE_API_URL +'/api/AttendanceCompanyFineRule/Add', MapFineRule ,{headers: authHeader} )
        .map(this.extractData)
        .catch(this.handleErrorObservable);
    }
    DeleteMapFineRule( token ,Refid : number) 
    {
        const authHeader = new HttpHeaders();         
        authHeader.append('Content-Type', 'application/json');
        authHeader.append('Authorization', token);
        authHeader.append('Accept', 'application/json');
        return this.http.put(GlobalVariable.BASE_API_URL +'/api/AttendanceCompanyFineRule/Delete', Refid ,{headers: authHeader} )
        .map(this.extractData)
        .catch(this.handleErrorObservable);
    }
    UpdateMapFineRule( token ,MapFineRule :MapFineRule) 
    {
        const authHeader = new HttpHeaders();         
        authHeader.append('Content-Type', 'application/json');
        authHeader.append('Authorization', token);
        authHeader.append('Accept', 'application/json');
        return this.http.put(GlobalVariable.BASE_API_URL +'/api/AttendanceCompanyFineRule/Update', MapFineRule ,{headers: authHeader} )
        .map(this.extractData)
        .catch(this.handleErrorObservable);
    }
    // End of the MapFineRule
    // Begning of the MapWorkingGroup
    GetMapWorkingGroup(token )
    {
        this.spinnerService.show();
        const authHeader = new HttpHeaders();     
        authHeader.append('Content-Type', 'application/json');
        authHeader.append('Authorization', token);
        return new Promise((resolve, reject) =>
        {
            setTimeout(()=>{
            this.http.get(GlobalVariable.BASE_API_URL +'/api/AttendanceEmployeeWorkingGroup/GetAttendanceEmployeeWorkingGroup',{headers: authHeader})
            .subscribe(data => 
            {
                this.spinnerService.hide();
                resolve(data)
            },
            error => 
            {
                this.spinnerService.hide();
                reject(error);
            },);
        },3000);

        });
    }
    GetMapWorkingGroupByid(token, id:number ) 
    {
        this.spinnerService.show();
        const authHeader = new HttpHeaders();     
        authHeader.append('Content-Type', 'application/json');
        authHeader.append('Authorization', token);
        return new Promise((resolve, reject) => 
        {
            setTimeout(()=>{
            this.http.get(GlobalVariable.BASE_API_URL +'/api/AttendanceEmployeeWorkingGroup/GetAttendanceEmployeeWorkingGroupById?EmpWorkingGroupId='+id,{headers: authHeader})
            .subscribe(data => 
            {
                this.spinnerService.hide();
                resolve(data)
            },
            error => 
            {
                this.spinnerService.hide();
                reject(error);
            },);
        },3000);

        });
    }
    AddMapWorkingGroup( token ,MapWorkingGroup :MapWorkingGroup) 
    {
        const authHeader = new HttpHeaders();         
        authHeader.append('Content-Type', 'application/json');
        authHeader.append('Authorization', token);
        authHeader.append('Accept', 'application/json');
        return this.http.post(GlobalVariable.BASE_API_URL +'/api/AttendanceEmployeeWorkingGroup/Add', MapWorkingGroup ,{headers: authHeader} )
        .map(this.extractData)
        .catch(this.handleErrorObservable);
    }
    DeleteMapWorkingGroup( token ,Refid : number) 
    {
        const authHeader = new HttpHeaders();         
        authHeader.append('Content-Type', 'application/json');
        authHeader.append('Authorization', token);
        authHeader.append('Accept', 'application/json');
        return this.http.put(GlobalVariable.BASE_API_URL +'/api/AttendanceEmployeeWorkingGroup/Delete', Refid ,{headers: authHeader} )
        .map(this.extractData)
        .catch(this.handleErrorObservable);
    }
    UpdateMapWorkingGroup( token ,MapWorkingGroup :MapWorkingGroup) 
    {
        const authHeader = new HttpHeaders();         
        authHeader.append('Content-Type', 'application/json');
        authHeader.append('Authorization', token);
        authHeader.append('Accept', 'application/json');
        return this.http.put(GlobalVariable.BASE_API_URL +'/api/AttendanceEmployeeWorkingGroup/Update', MapWorkingGroup ,{headers: authHeader} )
        .map(this.extractData)
        .catch(this.handleErrorObservable);
    }
    // End of the MapWorkingGroup

    private extractData(res: Response) 
    {
    let body = res;
    return body || {};
    }
    private handleErrorObservable (error: Response | any) 
    {
    console.error(error.message || error);
    return Observable.throw(error.message || error);
    }
}