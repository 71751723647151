export class DirectDuty
{
    public DirectId : number;
    public EmpId : number;
    public EmpIdChk : number;
    public EmployeeCode : string;
    public EmployeeName : string;
    public EmployeeEmailId : string;
    public RHId :number;
    public RHCode : string;
    public RHName : string;
    public RHEmailId : string;
    public DateOfDirectDuty : Date;
    public IsMorningDirectduty :boolean;
    public IsSecondHalfDirectDuty : boolean;
    public IsFullDayDirectDuty :boolean;
    public IsApproved : boolean;
    public IsRejected : boolean;
    public Place : string;
    public Reason :string;
    public Remarks :string;
    public DateOfApply : Date;
    public FirstHalf :string;
    public SecondHalf : string;
    public Fullday : string;
    public CompanyId : number;
    public SentMailid : string;
    public SentMailPassword : string;
    public HRISURL : string;
    // public FromDate :Date;
    // public ToDate : Date;
    public Selected : boolean;
    public RemarksAll: string;
    public SearchFromDate : Date;
    public SearchToDate : Date;
    public Status : number;
    public AppliedStatus : boolean;
    public AppliedFirstHalf: boolean;
    public AppliedSecondHalf: boolean;
    public AppliedFullDay: boolean;
    public AppliedIsApproved: boolean;
    public WorkingDuration : number;
    public IsOffday: boolean;
}