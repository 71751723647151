<app-dialog [(visible)]="this.SuccessStatus">
  <span class="successmsg">{{this.SuccessMessage}}</span>
</app-dialog>

<fieldset>

  <form (ngSubmit)="saveCRMMoreInformation()">
    <div class="row" style="padding: 20px;">
      <div class="col-lg-4">







        <!-- <div class="form-group row">
          <label class="col-sm-4 col-form-label clear-padding-right"> CLose Date</label>
          <div class="col-sm-8 clear-left-padding">
           

            <input type="text" [(value)]=" this.CRM_OppertunityService.CRM_MoreInformation_ModelShare.CloseDateval"
              readonly="readonly" [minimumDate]="false" [onlyDatePicker]="true" id="Close_Date_id" myDatepicker
              (dateChange)="CloseDates($event)"
              class="form-control form-control-custom form-control-custom-new ng-pristine ng-valid ng-touched" />


           
          </div>
        </div>
 -->


        <!-- <div class="form-group row">
          <label class="col-sm-4 col-form-label clear-padding-right"> Advance Payment </label>
          <div class="col-sm-8 clear-left-padding">
            <input myNumberOnly name="min" placeholder="Enter Advance Payment" maxlength="10" tabindex="4"
              [(ngModel)]="this.CRM_OppertunityService.CRM_MoreInformation_ModelShare.AdvancePayment"
              id="AdvancePayment" name="AdvancePayment" #AdvancePayment="ngModel" required class="form-control form-control-custom
                  form-control-custom-new"> -->
        <!-- <input type="text" [(ngModel)]="this.CRM_OppertunityService.CRM_MoreInformation_ModelShare.AdvancePayment "
          maxlength="500" tabindex="1" id="AdvancePayment" name="AdvancePayment" #AdvancePayment="ngModel" required
          class="form-control form-control-custom
                  form-control-custom-new"> -->


        <!-- </div>
        </div> -->

        <div class="form-group row">
          <label class="col-sm-4 col-form-label clear-padding-right">Advance <br /> Payment %
          </label>
          <div class="col-sm-8 clear-left-padding">
            <select [(ngModel)]="this.CRM_OppertunityService.CRM_MoreInformation_ModelShare.AdvanceId" tabindex="2"
              id="AdvanceId" class="form-control" name="AdvanceId" #AdvanceId="ngModel">
              <option [value]="0" [disabled]=true>Select Advance Payment %</option>
              <option *ngFor="let bm of this.AdvancePaymentList" [value]="bm.AdvanceId">{{bm.AdvancePayment}}</option>
            </select>
          </div>
        </div>

        <!-- <div class="form-group row">
          <label class="col-sm-4 col-form-label clear-padding-right"> Reason For Lost </label>
          <div class="col-sm-8 clear-left-padding">
            <input type="text" [(ngModel)]="this.CRM_OppertunityService.CRM_MoreInformation_ModelShare.Reason"
              maxlength="2500" tabindex="6" id="Reason" name="Reason" #Reason="ngModel" class="form-control form-control-custom
                  form-control-custom-new">

          </div>
        </div> -->

        <!-- <div class="form-group row">
          <label class="col-sm-4 col-form-label clear-padding-right"> Time Period </label>
          <div class="col-sm-8 clear-left-padding">
            <input type="text" [(ngModel)]="this.CRM_OppertunityService.CRM_MoreInformation_ModelShare.TimePeriod"
              maxlength="500" tabindex="8" id="TimePeriod" name="TimePeriod" #TimePeriod="ngModel" required class="form-control form-control-custom
                  form-control-custom-new">
          </div>
        </div>
        <div class="form-group row">
          <label class="col-sm-4 col-form-label clear-padding-right"> Source/<br>Contact Person<br> For Oppertunity
          </label>
          <div class="col-sm-8 clear-left-padding">
            <input type="text" [(ngModel)]="this.CRM_OppertunityService.CRM_MoreInformation_ModelShare.Source"
              maxlength="500" tabindex="11" id="Source" name="Source" #Source="ngModel" required class="form-control form-control-custom
                              form-control-custom-new">


            <div *ngIf="Source.invalid && (Source.dirty || Source.touched)" class="alert alert-danger">
              <div *ngIf="Source.errors.required">Enter Source Name</div>
              <div *ngIf="Source.errors.maxlength"> Source Name is not more than 250
                characters
              </div>
            </div>
          </div>
        </div> -->


      </div>



      <div class="col-lg-4">

        <!-- <div class="form-group row">
          <label class="col-sm-4 col-form-label clear-padding-right"> Retention </label>
          <div class="col-sm-8 clear-left-padding">
            <input type="text" maxlength="6"
              [(ngModel)]="this.CRM_OppertunityService.CRM_MoreInformation_ModelShare.Retention" tabindex="2"
              id="Retention" name="Retention" #Retention="ngModel" NumberOn class="form-control form-control-custom
                      form-control-custom-new">

          </div>
        </div> -->


        <!-- <div class="form-group row">
          <label class="col-sm-4 col-form-label clear-padding-right">Query Number </label>
          <div class="col-sm-8 clear-left-padding">
            <input type="text" [(ngModel)]="this.CRM_OppertunityService.CRM_MoreInformation_ModelShare.QueryNumber"
              maxlength="50" tabindex="5" id="QueryNumber" name="QueryNumber" #QueryNumber="ngModel" required class="form-control form-control-custom
                      form-control-custom-new">

            <div *ngIf="QueryNumber.invalid && (QueryNumber.dirty || QueryNumber.touched)" class="alert alert-danger">
              <div *ngIf="QueryNumber.errors.required">Enter a Query number</div>
              <div *ngIf="QueryNumber.errors.maxlength"> Query Number is not more than 50 characters
              </div>
            </div>


          </div>
        </div> -->


        <!-- <div class="form-group row">
          <label class="col-sm-4 col-form-label clear-padding-right"> Domestic/<br>International
            <span *ngIf="this.CRM_OppertunityService.CRM_MoreInformation_ModelShare.DomesticInternationalId == 0"
              class="required-validation">*</span>
          </label>
          <div class="col-sm-8 clear-left-padding">
            <select [(ngModel)]="this.CRM_OppertunityService.CRM_MoreInformation_ModelShare.DomesticInternationalId"
              tabindex="7" id="DomesticInternationalId" class="form-control" name="DomesticInternationalId"
              #DomesticInternationalId="ngModel">
              <option [value]="0" [disabled]=true>Select Domestic/International</option>
              <option *ngFor="let di of domesticinternationallist" [value]="di.DomesticInternationalId">
                {{di.DomesticInternational}}
              </option>
            </select>

            
          </div>
        </div> -->



        <!-- <div class="form-group row">
          <label class="col-sm-4 col-form-label clear-padding-right">FIT/MICE
            <span *ngIf="this.CRM_OppertunityService.CRM_MoreInformation_ModelShare.FitMiceId == 0"
              class="required-validation">*</span>
          </label>
          <div class="col-sm-8 clear-left-padding">
            <select [(ngModel)]="this.CRM_OppertunityService.CRM_MoreInformation_ModelShare.FitMiceId" tabindex="9"
              id="FitMiceId" class="form-control" name="FitMiceId" #FitMiceId="ngModel">
              <option [value]="0" [disabled]=true>Select FIT/MICE Group</option>
              <option *ngFor="let fm of fitmicelist" [value]="fm.FitMiceId">{{fm.FitMiceName}}</option>
            </select>

            
          </div>
        </div> -->





        <div class="form-group row">
          <label class="col-sm-4 col-form-label clear-padding-right"> Date Of<br />Departure </label>
          <div class="col-sm-8 clear-left-padding">

            <!-- <input type="date" name="DepertureDate"
                      [ngModel]="this.CRM_OppertunityService.CRM_MoreInformation_ModelShare.DepertureDate | date:'yyyy-MM-dd'"
                      (ngModelChange)="DepertureDates($event)" #DepertureDate="ngModel" /> -->

            <input type="text" [(value)]="this.CRM_OppertunityService.CRM_MoreInformation_ModelShare.DepertureDateval"
              readonly="readonly" [minimumDate]="false" [onlyDatePicker]="true" id="DepartureDate_id" myDatepicker
              (dateChange)="DepertureDates($event)" tabindex="12"
              class="form-control form-control-custom form-control-custom-new ng-pristine ng-valid ng-touched" />


            <div *ngIf="!this.CRM_OppertunityService.CRM_MoreInformation_ModelShare.IsDepertureDateValid"
              class="alert alert-danger">
              Provide A Valid Date
            </div>

          </div>
        </div>




        <!-- <div class="form-group row">
          <label class="col-sm-4 col-form-label clear-padding-right"> Date Of Deperture </label>
          <div class="col-sm-8 clear-left-padding">

          

            <input type="text" [(value)]="this.CRM_OppertunityService.CRM_MoreInformation_ModelShare.DepertureDateval"
              readonly="readonly" [minimumDate]="false" [onlyDatePicker]="true" id="DepartureDate_id" myDatepicker
              (dateChange)="DepertureDates($event)" tabindex="12"
              class="form-control form-control-custom form-control-custom-new ng-pristine ng-valid ng-touched" />


            <div *ngIf="!this.CRM_OppertunityService.CRM_MoreInformation_ModelShare.IsDepertureDateValid"
              class="alert alert-danger">
              Provide A Valid Date
            </div>

          </div>
        </div> -->








      </div>

      <div class="col-lg-4">

        <div class="form-group row">
          <label class="col-sm-4 col-form-label clear-padding-right">Credit Time </label>
          <!-- <div class="col-sm-7 clear-left-padding">
        <select [(ngModel)]="CRM_MoreInformation_Model.CreditTime" tabindex="3" id="CreditTime" class="form-control"
          name="CreditTime" #CreditTime="ngModel">
          <option [value]="0" [disabled]=true>Select Credit Time</option>

          <option [value]="1">1 Month</option>
          <option [value]="2">2 Months</option>
          <option [value]="3">3 Months</option>
          <option [value]="4">4 Months</option>
          <option [value]="5">5 Months</option>
          <option [value]="6">6 Months</option>
          <option [value]="7">7 Months</option>
          <option [value]="8">8 Months</option>
          <option [value]="9">9 Months</option>
          <option [value]="10">10 Months</option>
          <option [value]="11">11 Months</option>
          <option [value]="12">12 Months</option>


          <option *ngFor="let C of credittime" [value]="C.CreditTime">{{C.CreditTime}}</option>
        </select>
      </div> -->
          <div class="col-sm-8 col-form-label clear-padding-right">
            <div>

              <input type="radio" [value]="1" tabindex="3"
                [checked]="this.CRM_OppertunityService.CRM_MoreInformation_ModelShare.CreditTime=='1'"
                (click)="OnChangeCreditTime(1)" id="CreditTime02" name="CreditTime02" class="m-r8">
              <label for="CreditTime02" class="m-r8">
                1 Week
              </label>

              <input type="radio" [value]="2" tabindex="3"
                [checked]="this.CRM_OppertunityService.CRM_MoreInformation_ModelShare.CreditTime=='2'"
                (click)="OnChangeCreditTime(2)" id="CreditTime03" name="CreditTime03" class="m-r8">
              <label for="CreditTime03" class="m-r8">
                2 Week

              </label>

              <input type="radio" [value]="3" tabindex="3"
                [checked]="this.CRM_OppertunityService.CRM_MoreInformation_ModelShare.CreditTime=='3'"
                (click)="OnChangeCreditTime(3)" id="CreditTime04" name="CreditTime04" class="m-r8">
              <label for="CreditTime04" class="m-r8">
                3 Week
              </label>
            </div>



            <!-- <div>
          2 Month
          <input type="radio" [value]="2" tabindex="3"
            [checked]="this.CRM_OppertunityService.CRM_MoreInformation_ModelShare.CreditTime=='2'"
            (click)="OnChangeCreditTime(2)" id="SecondMonth" name="SecondMonth">
        </div> -->
            <div>

              <input type="radio" [value]="4" tabindex="3"
                [checked]="this.CRM_OppertunityService.CRM_MoreInformation_ModelShare.CreditTime=='4'"
                (click)="OnChangeCreditTime(4)" id="CreditTime05" name="CreditTime05" class="m-r8">
              <label for="CreditTime05" class="m-r8">
                4 Week

              </label>

              <input type="radio" [value]="5" tabindex="3"
                [checked]="this.CRM_OppertunityService.CRM_MoreInformation_ModelShare.CreditTime=='5'"
                (click)="OnChangeCreditTime(5)" id="CreditTime06" name="CreditTime06" class="m-r8">
              <label for="CreditTime06" class="m-r8">
                4 Week+

              </label>

              <!-- <input type="radio" [value]="6" tabindex="3"
                [checked]="this.CRM_OppertunityService.CRM_MoreInformation_ModelShare.CreditTime=='6'"
                (click)="OnChangeCreditTime(6)" id="CreditTime07" name="CreditTime07">
              <label for="CreditTime07">
                6 Month

              </label> -->
            </div>
            <!-- <div>

              <input type="radio" [value]="7" tabindex="3"
                [checked]="this.CRM_OppertunityService.CRM_MoreInformation_ModelShare.CreditTime=='7'"
                (click)="OnChangeCreditTime(7)" id="CreditTime08" name="CreditTime08">
              <label for="CreditTime08">
                7 Month

              </label>

              <input type="radio" [value]="8" tabindex="3"
                [checked]="this.CRM_OppertunityService.CRM_MoreInformation_ModelShare.CreditTime=='8'"
                (click)="OnChangeCreditTime(8)" id="CreditTime09" name="CreditTime09">
              <label for="CreditTime09">
                8 Month

              </label>





              <input type="radio" [value]="9" tabindex="3"
                [checked]="this.CRM_OppertunityService.CRM_MoreInformation_ModelShare.CreditTime=='9'"
                (click)="OnChangeCreditTime(9)" id="CreditTime10" name="CreditTime10">
              <label for="CreditTime10">
                9 Month

              </label>
            </div> -->
            <!-- <div>

              <input type="radio" [value]="10" tabindex="3"
                [checked]="this.CRM_OppertunityService.CRM_MoreInformation_ModelShare.CreditTime=='10'"
                (click)="OnChangeCreditTime(10)" id="CreditTime11" name="CreditTime11">
              <label for="CreditTime11">
                10 Month

              </label>

              <input type="radio" [value]="11" tabindex="3"
                [checked]="this.CRM_OppertunityService.CRM_MoreInformation_ModelShare.CreditTime=='11'"
                (click)="OnChangeCreditTime(11)" id="CreditTime12" name="CreditTime12">
              <label for="CreditTime12">
                11 Month

              </label>

              <input type="radio" [value]="12" tabindex="3"
                [checked]="this.CRM_OppertunityService.CRM_MoreInformation_ModelShare.CreditTime=='12'"
                (click)="OnChangeCreditTime(12)" id="CreditTime13" name="CreditTime13">
              <label for="CreditTime13">

                12 Month
              </label>
            </div> -->

          </div>
          <!-- <div class="col-sm-7 clear-left-padding">
        
      </div> -->
          <!-- <div class="required-validation">Select Credit Time</div> -->
        </div>


        <!-- <div class="form-group row">
          <label class="col-sm-4 col-form-label clear-padding-right"> GP Figure </label>
          <div class="col-sm-8 clear-left-padding"> -->
        <!-- <input type="text" [(ngModel)]="this.CRM_OppertunityService.CRM_MoreInformation_ModelShare.GPFigure"
          maxlength="500" tabindex="1" id="GPFigure" name="GPFigure" #GPFigure="ngModel" required class="form-control form-control-custom
                      form-control-custom-new"> -->

        <!-- <input myNumberOnly placeholder="In Percent" maxlength="5" tabindex="10"
              [(ngModel)]="this.CRM_OppertunityService.CRM_MoreInformation_ModelShare.GPFigure" id="GPFigure"
              name="GPFigure" #GPFigure="ngModel" required class="form-control form-control-custom
                                        form-control-custom-new">

          </div>
        </div> -->

        <!-- <div class="form-group row">
          <label class="col-sm-4 col-form-label clear-padding-right"> Destination </label>
          <div class="col-sm-8 clear-left-padding">
            <input type="text" [(ngModel)]="this.CRM_OppertunityService.CRM_MoreInformation_ModelShare.Destination"
              maxlength="500" tabindex="13" id="Destination" name="Destination" #Destination="ngModel" required class="form-control form-control-custom
                      form-control-custom-new">

            <div *ngIf="Destination.invalid && (Destination.dirty || Destination.touched)" class="alert alert-danger">
              <div *ngIf="Destination.errors.required">Enter Destination</div>
              <div *ngIf="Destination.errors.maxlength"> Destination is not more than 150
                characters
              </div>
            </div>

          </div>
        </div> -->




        <div class="form-group row">
          <label class="col-sm-4 col-form-label clear-padding-right">Source Of
            Query
            <!-- {{this.CRM_OppertunityService.CRM_MoreInformation_ModelShare.SourceQueryId}} -->
          </label>
          <div class="col-sm-8 clear-left-padding">
            <select [(ngModel)]="this.CRM_OppertunityService.CRM_MoreInformation_ModelShare.SourceQueryId" tabindex="9"
              id="SourceQueryId" class="form-control" name="SourceQueryId" #SourceQueryId="ngModel">
              <option [value]="0" [disabled]=true>Select Query Source</option>
              <option *ngFor="let s of sourcequerylist" [value]="s.SourceQueryId">{{s.SourceQuery}}</option>
            </select>
          </div>
        </div>







      </div>

      <div class="col-lg-4">
        <div class="form-group row">
          <!-- <div *ngIf="this.IsSavevisible" class="col-sm-5 col-form-label text-center"> -->
          <div class="col-sm-6 col-form-label text-center" *ngIf="!this.CRM_OppertunityService.IsOppinViewstate">
            <!-- <button type="submit" id="submit" tabindex="4" class="btn btn-primary save-button"
          *ngIf="!(this.IsDataIsInViewState)" [disabled]="!((this.CRM_MoreInformation_Model.FitMiceId!=0)
                      &&(this.CRM_MoreInformation_Model.DomesticInternationalId!=0))">Save</button> -->

            <!-- <button type="submit" id="submit" tabindex="14" class="btn btn-primary save-button"
              [disabled]="!((this.CRM_OppertunityService.CRM_MoreInformation_ModelShare.FitMiceId!=0))">Save</button> -->
            <button type="submit" id="submit" tabindex="14" class="btn btn-primary save-button">Save</button>
          </div>
          <div class="col-sm-6 col-form-label text-center"> <button type="button" tabindex="15" (click)="RefreshPage()"
              class="btn btn-danger cancle-button">Cancel</button>
          </div>
        </div>
      </div>
    </div>
  </form>
</fieldset>