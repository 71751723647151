<div><app-dialog [(visible)]="this.SuccessStatus">
        <span class="successmsg">{{this.SuccessMessage}}</span>
    </app-dialog>
</div>

<div class="body-content-area tabcontent" id="link1">
  <div> 
   
<fieldset class="body-form-control" id="OfferFieldet">
  
  
  <legend class="legend--">Add Client</legend>
  <form #OfferForm="ngForm" (ngSubmit)="saveB2CDetails()">

          <div lass="row" >
                  <table id="tblB2CDetails" datatable [dtOptions]="dtOptions"
                   [dtTrigger]="dtTrigger" 
                   class="table table-striped table-bordered text-left table-new row-border hover">
                    <thead class="thead-blue text-center">
                      <tr>
                        <th>Sl.No.</th>
                        <th>Company Name</th>
                        <th> Name </th>
                        <th>Country</th>
                        <th>State</th>
                        <th>City</th>
                        <th>Client Type</th>
                        <th>Action</th>
                      
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let CRM of CRM_B2C_models, let i = index">
                        <td scope="row">{{i+1}}</td>
                        <td>{{CRM.CompanyName }}</td>
                        <td>{{CRM.FullName}}</td>
                        <td>{{CRM.CountryName }}</td>
                        <td>{{CRM.State}}</td>
                        <td>{{CRM.City}}</td>
                        <td>{{CRM.Clienttype}}</td>


                        <td>
                              <button type="button" *ngIf="this.SelectedMenu.CanEdit"
                               (click)="GetB2CDetailsByid( CRM.ID )"
                                class="table-edit-btn"><i class="fas fa-edit"></i> </button> 
                              <button type="button"  
                              *ngIf="this.SelectedMenu.CanDelete" 
                              (click)="DeleteB2C( CRM.ID )" class="table-remove-btn"><i
                               class="fas fa-trash-alt"></i></button>


                              <button *ngIf="this.SelectedMenu.CanView" type="button" 
                              (click)="ViewB2CDetailsByid( CRM.ID )" class="table-view-button" 
                              type="button" ><i class="fas fa-eye"></i></button>

                              
                          </td>
                       
                      </tr>
                    </tbody>
                  </table>
                </div>



    <div class="row">
    <div class="col-lg-4">
    <div class="col-body-border-right">





                    <div class="form-group row">                        
                     <label class="col-sm-4 col-form-label clear-padding-right">Company Name  </label>
                      <div class="col-sm-7 clear-left-padding">
                          <input type="text" [(ngModel)]="CRM_B2C_Model.CompanyName" maxlength="500" 
                          tabindex="1" id ="CompanyName" name="CompanyName" 
                          #CompanyName="ngModel"  
                           class="form-control form-control-custom form-control-custom-new">
                      </div>
                  </div>




                  <div class="form-group row">
                      <label  class="col-sm-4 col-form-label clear-padding-right">Middle Name</label>
                      <div class="col-sm-7 clear-left-padding">
                          <input type="text"
                           [(ngModel)]="CRM_B2C_Model.MiddleName" tabindex="5" maxlength="100" 
                           id ="MiddleName"  name="MiddleName"
                            #MiddleName="ngModel" 
                             class="form-control form-control-custom form-control-custom-new" >
                      </div>
                  </div>




                  <div class="form-group row">
                    <label  class="col-sm-5 col-form-label clear-padding-right" > Date Of Birth</label>
                    <div class="col-sm-7 clear-left-padding">
                <input type="date" name="DOB" tabindex="8" [ngModel]="CRM_B2C_Model.DOB | date:'yyyy-MM-dd'" 
                    (ngModelChange)="DateOfBirth($event)" #DOB="ngModel" >
                    </div>
                    <div *ngIf="!this.CRM_B2C_Model.IsDOBValid" class="alert alert-danger">
                    <div *ngIf="!this.CRM_B2C_Model.IsDOBValid">Date Of Birth should be valid</div>
                    </div>
                    </div>
                     

                    <div class="form-group row">
                      <label class="col-sm-4 col-form-label clear-padding-right">Country
                        <span class="required-validation" *ngIf="CRM_B2C_Model.CountryId==0">*</span>
                      </label>
                      <div class="col-sm-7 clear-left-padding">
                        <select [(ngModel)]="CRM_B2C_Model.CountryId" tabindex="11" (ngModelChange)="onChangeCountry($event)" id="CountryId" class="form-control"
                          name="CountryId" #CountryId="ngModel">
                          <option [value]="0" [disabled]=true>Select Country</option>
                          <option *ngFor="let c of Countries" [value]="c.CountryId">{{c.CountryName}}
                          </option>
                        </select>
                      </div>
                    </div>
                    

                  




                  



                  <div class="form-group row">
                    <label class="col-sm-4 col-form-label clear-padding-right">Current Vendor</label>
                    <div class="col-sm-7 clear-left-padding">
                      <input type="text" [(ngModel)]="CRM_B2C_Model.CurrentVendor" maxlength="100" tabindex="14" id="CurrentVendor" name="CurrentVendor"
                        #CurrentVendor="ngModel" class="form-control form-control-custom form-control-custom-new">
                    </div>
                  </div>



                  <div class="form-group row">
                    <label class="col-sm-4 col-form-label clear-padding-right">Card Ratio</label>
                    <div class="col-sm-7 clear-left-padding">
                      <input type="text" [(ngModel)]="CRM_B2C_Model.CardRatio" maxlength="100" tabindex="17" id="CardRatio" name="CardRatio" #CardRatio="ngModel"
                        class="form-control form-control-custom form-control-custom-new">
                    </div>
                  </div>


                <div class="form-group row">
                  <label class="col-sm-4 col-form-label clear-padding-right">Other ID</label>
                  <div class="col-sm-7 clear-left-padding">
                    <input type="text" [(ngModel)]="CRM_B2C_Model.OtherIdNo" maxlength="20" tabindex="20" id="OtherIdNo" name="OtherIdNo" #OtherIdNo="ngModel"
                      required class="form-control form-control-custom form-control-custom-new">
                  </div>
                  <div *ngIf="OtherIdNo.invalid && (OtherIdNo.dirty)" class="alert alert-danger">
                    <div *ngIf="OtherIdNo.errors.required">Enter Valid Details OF ID</div>
                    <div *ngIf="OtherIdNo.errors.maxlength">OtherIdNo is not more than 20 characters</div>
                  </div>
                </div>





                  <div class="form-group row">
                      <label  class="col-sm-4 col-form-label clear-padding-right">Address</label>
                      <div class="col-sm-7 clear-left-padding">
                          <input type="text"
                           [(ngModel)]="CRM_B2C_Model.Address" tabindex="25" id ="Address"
                            maxlength="1000"  name="Address" #Address="ngModel"
                              required class="form-control form-control-custom form-control-custom-new" >
                      </div>
                     
                    </div>
    



                   <div class="form-group row">
                    <label  class="col-sm-4 col-form-label clear-padding-right">Pin Code</label>
                          <div
                     class="col-sm-7 clear-left-padding">
                        <input type="text"
                         [(ngModel)]="CRM_B2C_Model.Pin" tabindex="26" id ="Pin" maxlength="6"
                          minlength="6"  name="Pin" #Pin="ngModel" pattern="[0-9]+"
                           required class="form-control form-control-custom form-control-custom-new" >
                         </div>


                    <div *ngIf="Pin.invalid && (Pin.dirty || Pin.touched)" class="alert alert-danger">
                        <div *ngIf="Pin.errors.required">PostCode is required.</div>
                        <div *ngIf="Pin.errors.maxlength">PostCode not more than 6 digits.</div>
                        <div *ngIf="Pin.errors.minlength">PostCode not more than 6 digits.</div>
                        <div *ngIf="Pin.errors?.pattern">PostCode should be valid</div>
                    </div>
                  </div>



                  <div class="form-group row">                        
                      <label class="col-sm-4 col-form-label clear-padding-right">Pan No</label>

                      <div
                       class="col-sm-7 clear-left-padding">
                          <input type="text"
                           [(ngModel)]="CRM_B2C_Model.PanNo" 
                             maxlength="20"tabindex="27" id ="PanNo" name="PanNo" #PanNo="ngModel"  
                              class="form-control form-control-custom form-control-custom-new">
                      </div>
                      <div *ngIf="PanNo.invalid && (PanNo.dirty )" class="alert alert-danger">                          
                        <div *ngIf="PanNo.errors.maxlength">PanNo is not more than 20 characters</div>
                    </div>
                  </div>



                  <div class="form-group row">
                      <label class="col-sm-4 col-form-label clear-padding-right">Is Sms Offer</label>
                      <div class="col-sm-7 clear-left-padding">
                        <input type="checkbox" 
                        [(ngModel)]="CRM_B2C_Model.IsSmsOffer" tabindex="30" id ="IsSmsOffer"
                         name="IsSmsOffer" #IsSmsOffer="ngModel"
                          class="form-control form-control-custom form-control-custom-new">
                      </div>
                    </div>


                         <div class="form-group row">
    
                          <label *ngIf="!(CRM_B2C_Model.IsSmsOffer || CRM_B2C_Model.IsEmialOffer)"
                           class="required-validation" > Check Email or Phone Number Or Both 
                           </label>

                       

                                      <label 
                           *ngIf="(CRM_B2C_Model.IsSmsOffer && CRM_B2C_Model.IsEmialOffer)"
                            class="required-validation"> ** Enter Both Email & Phone Number **
                                    </label>



                                 <label
                         *ngIf="((this.CRM_B2C_Model.IsSmsOffer==true && !(CRM_B2C_Model.IsEmialOffer)) &&  
                          ((this.CRM_B2C_Model.PhoneNo == null)||(this.CRM_B2C_Model.PhoneNo.length==0)))" 
                          class="required-validation"> Phone Number Is Mandatory
                              </label>



                              <label
                    *ngIf="((this.CRM_B2C_Model.IsEmialOffer==true && !(CRM_B2C_Model.IsSmsOffer)) &&   
                         ((this.CRM_B2C_Model.EmailId == null)||(this.CRM_B2C_Model.EmailId.length==0)))"
                          class="required-validation"> Email ID Is Mandatory  
                            </label> 


                                 <label 
                  *ngIf="((this.CRM_B2C_Model.IsEmialOffer==true && (CRM_B2C_Model.IsSmsOffer)) &&    
                        (!(this.CRM_B2C_Model.EmailId == null)||!(this.CRM_B2C_Model.EmailId.length==0))&&
                        (!(this.CRM_B2C_Model.PhoneNo == null)||!(this.CRM_B2C_Model.PhoneNo.length==0)))"
                            class="required-validation"> <font color="Green"> Both Email ID & SMS is Checked and Entered.  </font>
                                    </label>

                             
                  
    </div>
    </div>
    </div>
         
                    <div class="col-lg-4">
                    <div class="col-body-border-right">
                      
                         <div class="form-group row">                        
                      <label class="col-sm-4 col-form-label clear-padding-right">Client Type
                           <span class="required-validation"
                            *ngIf="CRM_B2C_Model.Client_Past_New=='0'">*</span></label>
                      <div class="col-sm-7 clear-left-padding">
                      <select
                       [(ngModel)]="CRM_B2C_Model.Client_Past_New" tabindex="2"   id ="Client_Past_New" 
                       class="form-control" name="Client_Past_New" #BranchId="ngModel" >
                        <option [value]="0" [disabled]=true >Select ClientType</option>
                        <option *ngFor="let b of ClientType"
                         [value]="b.ClientId">{{b.ClientTypeval}}</option>
                      </select>
                  </div>
                  </div>




                  <div class="form-group row">                        
                    <label class="col-sm-4 col-form-label clear-padding-right">Last Name</label>
                    <div class="col-sm-7 clear-left-padding">
                        <input type="text"
                         [(ngModel)]="CRM_B2C_Model.LastName" 
                           maxlength="100"tabindex="6" id ="LastName" name="LastName" 
                           #LastName="ngModel"  
                            class="form-control form-control-custom form-control-custom-new">
                    </div>
                   </div>





                   <div class="form-group row">
                    <label  class="col-sm-5 col-form-label clear-padding-right" > Anniversary Date</label>
                <div class="col-sm-7 clear-left-padding">
                <input type="date" name="AnniverSaryDate" tabindex="9"
                [ngModel]="CRM_B2C_Model.AnniverSaryDate | date:'yyyy-MM-dd'"
                (ngModelChange)="AniversaryDate($event)" #AnniverSaryDate="ngModel"  />

            <div *ngIf="!this.CRM_B2C_Model.IsAnniversaryDateValid" class="alert alert-danger">
            <div *ngIf="!this.CRM_B2C_Model.IsAnniversaryDateValid">Anniversary Date should be valid</div>
            
                    </div>
                    </div>                  
                  </div>


                  <div class="form-group row">
                    <label class="col-sm-4 col-form-label clear-padding-right">State
                      <span class="required-validation" *ngIf="CRM_B2C_Model.StateId==0">*</span>
                    </label>
                  
                  
                    <div class="col-sm-7 clear-left-padding">
                      <select [(ngModel)]="CRM_B2C_Model.StateId" tabindex="12" (ngModelChange)="onstatechange($event)" id="StateId" class="form-control"
                        name="StateId" #StateId="ngModel">
                        <option [value]="0" [disabled]=true>Select State</option>
                        <option *ngFor="let s of States" [value]="s.StateID">{{s.StateName}}</option>
                      </select>
                    </div>
                  </div>





                 






                  <div class="form-group row">
                  <label class="col-sm-4 col-form-label clear-padding-right">Total Annual Vol. In USD</label>
                    <div class="col-sm-7 clear-left-padding">
                      <input type="text" [(ngModel)]="CRM_B2C_Model.TotalAnnualVol" maxlength="100" tabindex="15" id="TotalAnnualVol" name="TotalAnnualVol"
                        #TotalAnnualVol="ngModel" class="form-control form-control-custom form-control-custom-new">
                    </div>
                  </div>

                 
    

                        
                        <div class="form-group row">
                      <label  class="col-sm-4 col-form-label clear-padding-right">Phone No</label>
                      <div class="col-sm-7 clear-left-padding">
                    <input type="text" [(ngModel)]="CRM_B2C_Model.PhoneNo" tabindex="18"  id="PhoneNo"
                    name="PhoneNo" #PhoneNo="ngModel"  
                    class="form-control form-control-custom form-control-custom-new"
                    (ngModelChange)="PhoneNumberCheck($event)">
                      </div>
                       <div *ngIf="!this.CRM_B2C_Model.IsPhoneNoValid" class="alert alert-danger">
                        <div *ngIf="!this.CRM_B2C_Model.IsPhoneNoValid">Phone Number should be valid.</div>
                        
                        </div>
                        </div>


                        <div class="form-group row">
                          <label class="col-sm-5 col-form-label clear-padding-right">Upload Document</label>
                          <div class="col-sm-7 clear-left-padding">
                            <img *ngIf="this.CRM_B2C_Model.DocumentImage!=null" [src]="this.CRM_B2C_Model.DocumentImage" style="width:175px;">
                            <input type="file" (change)="handleFileinputDocument($event.target.files)" tabindex="21" #Image accept="image/*">
                          </div>
                        </div>





                        <div class="form-group row">
                          <label class="col-sm-5 col-form-label clear-padding-right"> Next Follow Up </label>
                          <div class="col-sm-7 clear-left-padding">
                            <input type="date" name="NextFollowUp" tabindex="28" [ngModel]="CRM_B2C_Model.NextFollowUp | date:'yyyy-MM-dd'" (ngModelChange)="NextFollowUpB2C($event)"
                              #NextFollowUp="ngModel">
                          </div>
                        
                          <div *ngIf="!this.CRM_B2C_Model.IsNextFollowUpDateValid" class="alert alert-danger">
                            <div *ngIf="!this.CRM_B2C_Model.IsNextFollowUpDateValid">Provide A Valid Follow Up Date </div>
                          </div>
                        </div>




                        
                        <div class="form-group row">
                          <label class="col-sm-4 col-form-label clear-padding-right">Feedback & Proposal Details </label>
                          <div class="col-sm-7 clear-left-padding">
                            <input type="text" [(ngModel)]="CRM_B2C_Model.FeedbackProposalDetails" maxlength="1000" tabindex="29" id="FeedbackProposalDetails"
                              name="FeedbackProposalDetails" #FeedbackProposalDetails="ngModel" class="form-control form-control-custom form-control-custom-new">
                          </div>
                        </div>



                        <div class="form-group row">
                          <label class="col-sm-4 col-form-label clear-padding-right">Is Email Offer</label>
                          <div class="col-sm-7 clear-left-padding">
                            <input type="checkbox" [(ngModel)]="CRM_B2C_Model.IsEmialOffer" tabindex="31 " id="IsEmialOffer" name="IsEmialOffer" #IsEmialOffer="ngModel"
                              class="form-control form-control-custom form-control-custom-new">
                          </div>
                        </div>



                  


    </div>
    </div> 




    <style>
            .clear-right-padding{padding-right: 0;}
            .clear-left-padding{padding-left: 7px;}
            .padL7{padding-left: 7px;}
            .red{color:red;}
    </style>


    <div class="col-lg-4">
    <div class="form-group row">     
    
    
                  <label class="col-sm-3 col-form-label clear-padding-right">First Name<span
                      class="required-validation" *ngIf="(this.CRM_B2C_Model.FirstName==null) ||
                       (this.CRM_B2C_Model.FirstName.length==0)  ">*</span></label>



                  <div class="col-sm-2 clear-left-padding padL7">
                      <select  [(ngModel)]="this.CRM_B2C_Model.MR" tabindex="3" 
                      (ngModelChange)="onChangeMister($event)" 
                      id ="MR1" class="form-control" name="MR1" #MR1="ngModel">
                        <option [value]="0" >Mr</option>
                        <option  [value]="1">Mrs</option>
                        <option  [value]="2">Ms</option>
                      </select>
                    </div>



                  <div class="col-sm-6 clear-left-padding">
                      <input type="text" [(ngModel)]="CRM_B2C_Model.FirstName" 
                        maxlength="100"tabindex="4" id ="FirstName" name="FirstName" #FirstName="ngModel" 
                         required class="form-control form-control-custom form-control-custom-new">
                  </div>



                  
    </div>


              <div class="form-group row">
                <label class="col-sm-4 col-form-label clear-padding-right">Contact Person</label>
                <div class="col-sm-7 clear-left-padding">
                  <input type="text" [(ngModel)]="CRM_B2C_Model.ContactPerson" maxlength="100" tabindex="7" id="ContactPerson" name="ContactPerson"
                    #ContactPerson="ngModel" class="form-control form-control-custom form-control-custom-new">
                </div>
              </div>





               <div class="form-group row">                        
                <label class="col-sm-4 col-form-label clear-padding-right">Title</label>
                <div class="col-sm-7 clear-left-padding">
                    <input type="text" [(ngModel)]="CRM_B2C_Model.Title" 
                      maxlength="100"tabindex="10" id ="Title" name="Title" #Title="ngModel" 
                        class="form-control form-control-custom form-control-custom-new">
                </div>
               </div>



                <div class="form-group row">
                  <label class="col-sm-4 col-form-label clear-padding-right">City
                    <span class="required-validation" *ngIf="CRM_B2C_Model.CityId==0">*</span>
                  </label>
                  <div class="col-sm-7 clear-left-padding">
                    <select [(ngModel)]="this.CRM_B2C_Model.CityId" tabindex="13" id="CityId" class="form-control" name="CityId" #CityId="ngModel">
                      <option [value]="0" [disabled]=true>Select City</option>
                      <option *ngFor="let c of Cities" [value]="c.CityId">{{c.CityName}}</option>
                    </select>
                  </div>
                </div>

              




              <div class="form-group row">
                <label class="col-sm-4 col-form-label clear-padding-right">CN Ratio</label>
                <div class="col-sm-7 clear-left-padding">
                  <input type="text" [(ngModel)]="CRM_B2C_Model.CNRatio" maxlength="100" tabindex="16" id="CNRatio" name="CNRatio" #CNRatio="ngModel"
                    class="form-control form-control-custom form-control-custom-new">
                </div>
              </div>



              


                <div class="form-group row">
                  <label class="col-sm-4 col-form-label clear-padding-right">Email Id</label>
                  <div class="col-sm-7 clear-left-padding">
                    <input type="text" [(ngModel)]="CRM_B2C_Model.EmailId" tabindex="19" id="EmailId" name="EmailId" #EmailId="ngModel" class="form-control form-control-custom form-control-custom-new"
                      (ngModelChange)="EmailIDCheck($event)">
                  </div>
                
                  <div *ngIf="!this.CRM_B2C_Model.IsEmailValid" class="alert alert-danger">
                    <div *ngIf="!this.CRM_B2C_Model.IsEmailValid">Email should be valid.</div>
                  </div>
                </div>



                <div class="form-group row">
                  <label class="col-sm-4 col-form-label clear-padding-right">Passport No</label>
                  <div class="col-sm-7 clear-left-padding">
                    <input type="text" [(ngModel)]="CRM_B2C_Model.PassPortNo" maxlength="50" tabindex="22" id="PassPortNo" name="PassPortNo"
                      #PassPortNo="ngModel" class="form-control form-control-custom form-control-custom-new">
                  </div>
                </div>
              
             
            
      
                

              <div class="form-group row">
                  <label  class="col-sm-5 col-form-label clear-padding-right">Upload Passport </label>
                  <div class="col-sm-7 clear-left-padding">
                    <img *ngIf="this.CRM_B2C_Model.PassportTypeimage!=null" 
                     [src]="this.CRM_B2C_Model.PassportImage" style="width:175px;" >
                    <input type="file" (change)="handleFileinputPassport($event.target.files)" 
                    tabindex="23" #Image accept="image/*" >
                </div>
              </div>  


                    


   
   
                <div class="form-group row">   
                <label class="col-sm-5 col-form-label clear-padding-right">Passport Validity Date </label>
                <div class="col-sm-7 clear-left-padding"><input type="date" name=" PassPortValidityDate"
                     tabindex="24" [ngModel]="CRM_B2C_Model.PassPortValidityDate| date:'yyyy-MM-dd'"
                (ngModelChange)="PassPortDate($event)" #PassPortValidityDate="ngModel" />
                <div *ngIf="!this.CRM_B2C_Model.IsPassportDateValid" class="alert alert-danger">
                <div *ngIf="!this.CRM_B2C_Model.IsPassportDateValid"> Select A Valid Passport Validity Date
                </div>
                </div>
                 </div>        
                </div>

                




              <div class="form-group row" >
                  <label class="col-sm-6 col-form-label text-center clear-padding-right" 
                  *ngIf="this.IsInView==false;">
                  <button type="submit" tabindex="32" class="btn btn-primary save-button" 
                  [disabled]="!((this.CRM_B2C_Model.CountryId >0) && 
                   (this.CRM_B2C_Model.StateId >0) && (this.CRM_B2C_Model.CityId > 0) &&
                    (this.CRM_B2C_Model.Client_Past_New !='0') && 
                    (this.CRM_B2C_Model.FirstName!=null && this.CRM_B2C_Model.FirstName.length >0) &&
                (this.CRM_B2C_Model.LastName!=null && this.CRM_B2C_Model.LastName.length >0) &&
                (this.CRM_B2C_Model.IsEmialOffer==true && (this.CRM_B2C_Model.EmailId!=null &&
                 this.CRM_B2C_Model.EmailId.length >0 ) || (this.CRM_B2C_Model.IsSmsOffer==true &&
                  (this.CRM_B2C_Model.PhoneNo!=null && this.CRM_B2C_Model.PhoneNo.length>0)) &&
                   (this.IsNotBusy==false)  )   )" >
                    Save</button>  
                  </label>


                  
                  <div class="col-sm-6 col-form-label text-center">
                    <button type="button" tabindex="33" (click)="RefreshPage()"
                     class="btn btn-danger cancle-button">Cancel</button>
                  </div>
                </div>




    </div>
    </div>
         


    </form>
    </fieldset>
    </div>
    </div>

