import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import {LocationService} from '../shared/HR_ADMIN/Location/location.service';
import { Subject } from 'rxjs/Subject';
import {Country } from '../shared/Organization/Country.Model';
import { DataTableDirective } from 'angular-datatables';
import { UserService } from '../shared/user.service';
// Begning Confirmation
import { ConfirmationService } from '@jaspero/ng2-confirmations';
import { ResolveEmit } from '../../app/resolve-emit';
import { ConfirmSettings } from '../../app/confirm-settings';
// end of Confirmation
import { Menu } from '../shared/menu.model';

@Component({
  selector: 'app-manage-country',
  templateUrl: './manage-country.component.html',
  styleUrls: ['./manage-country.component.css']
})

export class ManageCountryComponent implements OnInit 
{              
  CountryModel : Country= new Country();
  userClaims: any;
  Countries: Country[] = [];   
  SubMenues: Menu[] = [];
  Mod: any; 
  Result : string;
  SuccessStatus:boolean=false;
  SuccessMessage: string ="";
  public tableWidget: any;
  countryId : any;
  country : any;
  tempcountryadd : Country =new Country();
   // Begning Permission Portion    
  SelectedMenu:Menu;
  IsDataIsInViewState : boolean=false;
  CanViewState :boolean=false;
  DisableSave :boolean=false;
  FormViewShow : boolean =false;
  CanSaveButtonView : boolean =false;
  // End of the permission portion
  //check for duplicate Name
  CheckNameExiststatus : boolean=null;  
  CheckNamestatusedit : boolean =false;
  Namecheck : string ="";
  checkName : any;
  checkNameval : string ="";
  //check for duplicate Name
 
  constructor( private rout: Router, private LocationService : LocationService, private _confirmation: ConfirmationService, 
     private userService: UserService ) { }

  @ViewChild(DataTableDirective, {static: false})
  setDtOptions: DataTables.Settings = {};
  cardDtOptions: DataTables.Settings = {};
  dtElement: DataTableDirective;
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();

  ngOnInit()
  {
    // Begning Permission Portion 
    this.SubMenues=[];
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    let menuid = localStorage.getItem("menuidstor");
    this.userService.GetSubMenuList( t, menuid).then(x => 
    {
      this.Mod = x;
      Object.keys(this.Mod).forEach( key => 
      {
        // tslint:disable-next-line:no-unused-expression
        this.Mod[key];
        let menutemp = new Menu();
        menutemp = this.Mod[key];
        this.SubMenues.push(menutemp);
      });
      this.userService.SelectedMenu= this.SubMenues[0];
      this.rout.navigate([this.userService.SelectedMenu.RouteName]);
      this.SelectedMenu=this.userService.SelectedMenu;
      if(this.SelectedMenu.CanAdd)
      {
        this.IsDataIsInViewState=false;
        this.CanSaveButtonView=true;
      }
      else
      {
        this.IsDataIsInViewState=true;
      }
      if(this.SelectedMenu.CanView)
      {
        this.CanViewState=false;
      }
      else
      {
        this.CanViewState=true;
      }
    });
    // End of the permission portion
    this.DisableSave=true;
    this.FormViewShow=false;
    this.dtOptions = 
    {
      pagingType: 'full_numbers',
      pageLength: 8
    };
    if(localStorage.getItem('SuccessfullMessage'))
    {
      this.SuccessStatus=true;
      this.SuccessMessage =localStorage.getItem('SuccessfullMessage')
      localStorage.removeItem('SuccessfullMessage');
    }
  }

  settings: ConfirmSettings | any = 
  {
    overlay: true,
    overlayClickToClose: true,
    showCloseButton: true,
    confirmText: 'Yes',
    declineText: 'No'
  };
  
  ngAfterViewInit(): void 
  {
    this.getCountries();
    this.dtOptions = 
    {
      pagingType: 'full_numbers',
      pageLength: 8
    };
  }

  getCountries() 
  {
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.LocationService.GetCountry( t).then(x => 
    {
      this.country = x;
      Object.keys(this.country).forEach( key => 
      {
        // tslint:disable-next-line:no-unused-expression
        this.country[key];
        let Countrytemp = new Country();
        Countrytemp = this.country[key];
        this.Countries.push(Countrytemp);
      });
      this.dtTrigger.next();     
    });      
  }

  ADDClick()
  {
    this.FormViewShow=true;
    this.CanSaveButtonView=false;
  }

  GetCountryByid(id) 
  {
    // begning of the permission portion
    this.IsDataIsInViewState=false;
    // end of the permission portion
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.LocationService.GetCountryByid( t,id).then(x => 
    {
      this.FormViewShow=true;
      this.CanSaveButtonView=false;
      this.country = x;
      Object.keys(this.country).forEach( key => 
      {
        // tslint:disable-next-line:no-unused-expression
        this.country[key];
        let Countrytemp = new Country();
        Countrytemp = this.country[key];
        this.CountryModel.CountryId=Countrytemp.CountryId;
        this.CountryModel.CountryCode=Countrytemp.CountryCode;
        this.CountryModel.CountryName=Countrytemp.CountryName;
        this.CountryModel.CurrencyCode=Countrytemp.CurrencyCode; 
        this.CountryModel.CurrencyName=Countrytemp.CurrencyName;          
        this.CountryModel.LanguageCode=Countrytemp.LanguageCode; 
        this.CountryModel.LanguageName=Countrytemp.LanguageName;          
      });
      // Begin for check Name duplication
      this.checkNameval= this.CountryModel.CountryName;
      this.CheckNamestatusedit=true;
      this.CheckNameExiststatus =true;
      this.Namecheck=""
      // End for check Name duplication
    });
  }

  ViewCountryByid(id) 
  {
    // begning of the permission portion
    this.IsDataIsInViewState=true;
    // end of the permission portion
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.LocationService.GetCountryByid( t,id).then(x => 
    {
      this.FormViewShow=true;
      this.CanSaveButtonView=false;
      this.country = x;
      Object.keys(this.country).forEach( key => 
      {
        // tslint:disable-next-line:no-unused-expression
        this.country[key];
        let Countrytemp = new Country();
        Countrytemp = this.country[key];
        this.CountryModel.CountryId=Countrytemp.CountryId;
        this.CountryModel.CountryCode=Countrytemp.CountryCode;
        this.CountryModel.CountryName=Countrytemp.CountryName;
        this.CountryModel.CurrencyCode=Countrytemp.CurrencyCode; 
        this.CountryModel.CurrencyName=Countrytemp.CurrencyName;          
        this.CountryModel.LanguageCode=Countrytemp.LanguageCode; 
        this.CountryModel.LanguageName=Countrytemp.LanguageName;          
      });
      // Begin for check Name duplication
      this.checkNameval= this.CountryModel.CountryName;
      this.CheckNamestatusedit=true;
      this.CheckNameExiststatus =true;
      this.Namecheck=""
      // End for check Name duplication
    });
  }

  saveCountry() :void
  {
    this.DisableSave=false;
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.CountryModel.IsActive=true;
    if(this.CountryModel.CountryId > 0)
    {
      this.LocationService.UpdateCountry(t ,this.CountryModel).subscribe(res=>
      {
        this.countryId=res;
        this.CountryModel.CountryId=0;  
        if(this.countryId>0)
        {
          localStorage.setItem('SuccessfullMessage', "Update Successfully");
          this.rout.navigateByUrl('/Location/ManageZone', {skipLocationChange: true}).then(()=>this.rout.navigate(["/Location/ManageCountry"]));
        }
      });                  
    }
    else
    {
      this.LocationService.AddCountry(t ,this.CountryModel).subscribe(res=>
      {
        this.countryId=res;
        this.CountryModel.CountryId=0;                
        if(this.countryId>0)
        {
          localStorage.setItem('SuccessfullMessage', "Save Successfully");
          this.rout.navigateByUrl('/Location/ManageZone', {skipLocationChange: true}).then(()=>this.rout.navigate(["/Location/ManageCountry"]));
        }
      }); 
    }
  }

  DeleteCountryByid(Countryid : number) :void
  {
    this._confirmation.create('Confirm to Deleate', 'Press Yes to Delete', this.settings)
    .subscribe((ans: ResolveEmit) => 
    {
      if (ans.resolved == true) 
      {
        let t =  localStorage.getItem('userToken');
        t = 'Bearer' + t;
        this.CountryModel.IsActive=true;
        if( Countryid > 0)
        {
          this.LocationService.DeleteCountry(t ,Countryid).subscribe(res=>
          {
            this.countryId=res;
            this.CountryModel.CountryId=0;                                  
            if(this.countryId >0)
            {
              localStorage.setItem('SuccessfullMessage', "Deleted Successfully");
              this.rout.navigateByUrl('/Location/ManageZone', {skipLocationChange: true}).then(()=>this.rout.navigate(["/Location/ManageCountry"]));
            }
          });                  
        }
      } 
    })
  }
    
  RefreshPage() 
  {    
    // this.FormViewShow=false;
    // this.CanSaveButtonView=true; 
    // this.CountryModel.CountryCode="";
    // this.CountryModel.CountryName="";
    // this.CountryModel.LanguageCode="";
    // this.CountryModel.LanguageName="";
    // this.CountryModel.CurrencyCode="";
    // this.CountryModel.CurrencyName="";
    this.rout.navigateByUrl('/Location/ManageZone', {skipLocationChange: true}).then(()=>this.rout.navigate(["/Location/ManageCountry"]));
  }

  CheckName()
  {
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    if(!this.CheckNamestatusedit)
    {
      this.LocationService.CheckCountryName( t, this.CountryModel.CountryName).then(x => 
      {
        this.checkName = x;
        if( this.checkName.length >0)
        {
          this.CheckNameExiststatus =false;
          this.Namecheck="Exist"
        }
        else
        {
          this.CheckNameExiststatus =true;
          this.Namecheck="";
        }
     });
    }
    else
    {
      if(this.CountryModel.CountryName !=this.checkNameval )
      {
        this.LocationService.CheckCountryName( t, this.CountryModel.CountryName).then(x => 
        {
          this.checkName = x;
          if( this.checkName.length >0)
          {
            this.CheckNameExiststatus =false;
            this.Namecheck="Exist"
          }
          else
          {
            this.CheckNameExiststatus =true;
            this.Namecheck="";
          }
        });
      }
      else
      {
        this.CheckNameExiststatus =true;
        this.Namecheck="";
      }
    }
  }
}
