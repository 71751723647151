<div class="body-content-area tabcontent" id="link1">
  <div class="row">
    <div class="col-lg-9">
      <div class="row">
        <div class="col-lg-1 low-padding text-center">
          <!-- <img *ngIf="this.SelectedMenu.Image!=null" src={{this.SelectedMenu.Image}} class="body-icon" alt="Icon"/> -->
        </div>
        <div class="col-lg-10 clear-padding">
          <div>
            <span class="page-title">Employee KRA Application</span>
            <small class="page-description"></small>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="!(this.CanViewState)">
    <fieldset class="body-form-control" > 
      <legend class="legend--">Employee Details</legend>
      <form #EmployeeAttendanceForm="ngForm">
        <div class="row">
          <div class="col-lg-4">
            <div class="col-body-border-right">
              <div class="form-group row">
                <label class="col-sm-4 col-form-label clear-padding-right">Code</label>
                <div class="col-sm-7 clear-left-padding">
                  :&nbsp;&nbsp;{{this.KRAApplicationModel.EmployeeCode}}
                </div>
              </div>
              <div class="form-group row">
                <label class="col-sm-4 col-form-label clear-padding-right">Reporting</label>
                <div class="col-sm-7 clear-left-padding">
                  :&nbsp;&nbsp;{{this.KRAApplicationModel.RHName}}
                </div>
              </div>
            </div>
          </div>

          <div class="col-lg-4">
            <div class="col-body-border-right">
              <div class="form-group row">
                <label class="col-sm-4 col-form-label clear-padding-right">Name</label>
                <div class="col-sm-7 clear-left-padding">
                  :&nbsp;&nbsp;{{this.KRAApplicationModel.EmployeeName}}
                </div>
              </div>
              <div class="form-group row">
                <label class="col-sm-4 col-form-label clear-padding-right">Functional</label>
                <div class="col-sm-7 clear-left-padding">
                  :&nbsp;&nbsp;{{this.KRAApplicationModel.FHName}}
                </div>
              </div>
            </div>
          </div>

          <div class="col-lg-4">
            <div class="form-group row">
              <label class="col-sm-4 col-form-label clear-padding-right">Designation</label>
              <div class="col-sm-7 clear-left-padding">
                :&nbsp;&nbsp;{{this.KRAApplicationModel.DesignationName}}
              </div>
            </div>
          </div>
        </div>
      </form>    
    </fieldset>

    <div *ngIf="NoRecordMsg" style="color: #dc3545; text-align: center;font-size: 15px; font-weight: bold;">KRA Application is not Exists.</div>

    <fieldset *ngIf="(this.KRAApplicationModel.TotalWateges ==100)" class="body-form-control"> 
      <app-dialog [(visible)]="this.SuccessStatus">
        <span class="successmsg">{{this.SuccessMessage}}</span>
      </app-dialog>
      <legend class="legend--">KRA Details</legend>
      <form #KRADetailForm="ngForm">
        <div lass="row" >
          <table id="tblkra" class="table table-striped table-bordered text-left table-new row-border hover" style="width: 100%;">
            <thead class="thead-blue text-center"s tyle="width: 100%;">
              <tr>
                <th style="width: 20%; text-align: left;">Question</th>
                <th style="width: 10%; text-align: center;">Weighted(%)</th>  
                <th style="width: 20%; text-align: left;">Rating Guideline</th>
                <th style="width: 20%; text-align: left;">Achievement</th>
                <th style="width: 10%; text-align: left;">Self Rating</th>
                <th style="width: 20%; text-align: left;">Self Remarks</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let kra of KRAApplications, let i = index">
                <td>
                  {{kra.KRAName}}
                  <input type="text" hidden="hidden" [(ngModel)]="kra.KRAId" name="KRAId_{{i}}" #KRAId="ngModel">
                  <input type="text" hidden="hidden" [(ngModel)]="kra.RefId" name="RefId_{{i}}" #RefId="ngModel">
                  <input type="text" hidden="hidden" [(ngModel)]="kra.KRAMappingId" name="KRAMappingId_{{i}}" #KRAMappingId="ngModel">
                </td>
                <td style="text-align: center;">{{kra.Weighted}}</td>
                <td>
                  {{kra.RatingCriteria}}
                  <input type="text" hidden="hidden" [(ngModel)]="kra.RatingCriteria" name="RatingCriteria_{{i}}" #RatingCriteria="ngModel">
                </td> 
                <td>
                  {{kra.Achievement}}
                  <input type="text" hidden="hidden" [(ngModel)]="kra.Achievement" name="Achievement_{{i}}" #Achievement="ngModel">
                </td> 
                <td>
                  <input type="text" [(ngModel)]="kra.SelfRating" name="SelfRating_{{i}}" #SelfRating="ngModel" maxlength="1" pattern="[1-5]+" (ngModelChange)="RatingChanged()" class="form-control form-control-custom form-control-custom-new" required>
                  <div *ngIf="SelfRating.invalid && (SelfRating.dirty || SelfRating.touched)" class="alert alert-danger">
                    <div *ngIf="SelfRating.errors?.pattern">SelfRating should be valid.</div>
                    <div *ngIf="SelfRating.errors.maxlength">SelfRating is not more than 1 digit.</div>
                  </div>
                </td>
                <td>
                  <input type="text" [(ngModel)]="kra.SelfRemarks" name="SelfRemarks_{{i}}" #SelfRemarks="ngModel" minlength="1" class="form-control form-control-custom form-control-custom-new" required>
                  <div *ngIf="SelfRemarks.invalid && (SelfRemarks.dirty || SelfRemarks.touched)" class="alert alert-danger">
                    <div *ngIf="SelfRemarks.errors.minlength">Please fill Remarks.</div>
                  </div>
                </td>  
              </tr>
            </tbody>
            <tfoot class="thead-blue text-center">
                <tr>
                  <th style="width: 20%; text-align: right;">Total Wts(%)</th>
                  <th style="width: 10%; text-align: center;">{{this.KRAApplicationModel.TotalWateges}}</th>  
                  <th style="width: 20%; text-align: left;"></th>
                  <th style="width: 20%; text-align: right;">Final Rating(%)</th>
                  <th style="width: 10%; text-align: left;">{{this.KRAApplicationModel.FinalSelfRating}}</th>
                  <th style="width: 20%; text-align: left;"></th>
                </tr>
              </tfoot>
          </table>
          <div class="row float-right">
            <div class=" col-auto text-center" *ngIf="!(this.KRAApplicationModel.FinalSubmit)">
              <button type="submit" tabindex="22" class="btn btn-primary save-button" [disabled]="!(this.DisableSave==true)" (click)="SaveKRAApplication()">Save</button>  
            </div>
            <div class=" col-auto text-center" *ngIf="!(this.KRAApplicationModel.FinalSubmit)">
              <button type="submit" tabindex="22" class="btn btn-primary save-button" [disabled]="!(KRADetailForm.valid && this.KRAApplicationModel.TotalWateges==100 && this.DisableSave==true)" (click)="FinalSubmitKRAApplication()">Final Submit</button>  
            </div>
            <div class=" col-auto text-center">
              <button type="button" tabindex="23" class="btn btn-danger cancle-button" (click)="RefreshPage()">Cancel</button>
            </div>
          </div>
        </div>
      </form>
    </fieldset>
  </div>
</div>