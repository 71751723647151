import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from '../../shared/user.service';
import { EmployeePortalService }  from '../../shared/HR_ADMIN/EmployeePortal/EmployeePortalService.service';
import { EmployeeMasterService }  from '../../shared/HR_ADMIN/Employee/EmployeeMaster.Service';
import { EmployeeDetail } from '../../shared/HR_ADMIN/Employee/EmployeeDetail.Model';
import { AttendanceRegularationModel } from '../../shared/HR_ADMIN/Approve/AttendanceRegularationModel.Model'
import { Menu } from '../../shared/menu.model';
import { ModalOptions,BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ApproveLeaveService }  from '../../shared/HR_ADMIN/Approve/ApproveLeave.service';

@Component({
  selector: 'app-all-employee-attendance-regularisation-status',
  templateUrl: './all-employee-attendance-regularisation-status.component.html',
  styleUrls: ['./all-employee-attendance-regularisation-status.component.css']
})
export class AllEmployeeAttendanceRegularisationStatusComponent implements OnInit 
{
  AttendanceRegularationModel : AttendanceRegularationModel= new AttendanceRegularationModel();
  RegularationPending : AttendanceRegularationModel[]=[];
  RegularationApproved : AttendanceRegularationModel[]=[];
  RegularationRejected : AttendanceRegularationModel[]=[];
  Regularation: any;

  Employees: EmployeeDetail[] = [];
  employee: any;
  userClaims: any;
  
  public tableWidget: any;
  selectedIndex : number;
  // Begning Permission Portion    
  SelectedMenu:Menu;
  IsDataIsInViewState : boolean=false;
  CanViewState : boolean=true;
  IsApplicationView : boolean=false;
  // End of the permission portion
  EmployeeSettings = {};
  selectedItems = [];
  emp : any [] =[]
  //For Pop up
  ModelListApplication: AttendanceRegularationModel;
  CancelMsg : boolean= false;
  ShowPopup : boolean= false;
  public modalRef: BsModalRef;
  public modelopt:ModalOptions;
   Approver : any;
  RefId : any;
  //New
  PendingMsg : boolean= false;
  ApprovedMsg : boolean= false;
  RejectedMsg : boolean= false;
  validdate : boolean= false;
  validTodate : boolean= false;
  DisableSave : boolean;

  constructor(private EmployeeMasterService: EmployeeMasterService, private rout: Router, private ApproveLeaveService:ApproveLeaveService, private userService: UserService, private EmployeePortalService: EmployeePortalService,private modalService: BsModalService) 
  { 
    this.ModelListApplication = new AttendanceRegularationModel();
    this.modelopt=new ModalOptions();
    this.modelopt.class='heavy_modal';
    this.modalService.config= this.modelopt;
    this.modelopt.ignoreBackdropClick=true;
  }

  ngOnInit() 
  {
    // Begning Permission Portion 
    this.SelectedMenu=this.userService.SelectedMenu;
    if(this.SelectedMenu !=undefined)
    {
      localStorage.setItem('selectedmenu',JSON.stringify(this.SelectedMenu));
      this.rout.navigate([this.SelectedMenu.RouteName]);
    }
    else
    {
      if(JSON.parse(localStorage.getItem('selectedmenu'))!=undefined)
      {
        this.SelectedMenu = JSON.parse(localStorage.getItem('selectedmenu'));
        this.rout.navigate([this.SelectedMenu.RouteName]);
      }
    }
    if(this.SelectedMenu.CanAdd)
    {
      this.IsDataIsInViewState=false;
    }
    else
    {
      this.IsDataIsInViewState=true;
    }
    if(this.SelectedMenu.CanView)
    {
      this.CanViewState=false;
    }
    else
    {
      this.CanViewState=true;
    }
    // End of the permission portion
    this.GetEmployeeDetailsDDL();
    this.ModelListApplication.EmployeeId=0;
    this.DisableSave=true;
    this.ModelListApplication.Status=0;
    this.validdate=false;
    this.validTodate=false;
    this.EmployeeSettings =
    {
      singleSelection:true,
      text:'Select Employee',
      enableSearchFilter:true,
      showCheckbox:false
    }
    this.DisableSave=true;
  }

  GetEmployeeDetailsDDL() 
  {
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.EmployeeMasterService.GetEmployeeDetailsForDDL( t).then(x => 
    {
      this.employee = x;
      Object.keys(this.employee).forEach( key => 
      {
        let Emptemp = new EmployeeDetail();
        Emptemp = this.employee[key];
        this.Employees.push(Emptemp);
        this.emp.push({"id":Emptemp.EmployeeId,"itemName":Emptemp.EmployeeCode + '-' +Emptemp.EmployeeName},);
      });     
    });      
  }

  onItemSelect(item:any)
  {
    this.DisableSave = true;
    this.PendingMsg = false;
    this.ApprovedMsg = false;
    this.RejectedMsg = false;
    this.RegularationPending=[];
    this.RegularationApproved=[];
    this.RegularationRejected=[];
    let EmployeeId= item['id'];
    if(EmployeeId>0)
    {
      this.ModelListApplication.EmployeeId=EmployeeId;
    }     
  }
  OnItemDeSelect(item:any){ }
  onSelectAll(items: any){ }
  onDeSelectAll(items: any){ }


  GetEmployeeRegularationPendingDetails(EmployeeId,FromDate,ToDate)
  {
    this.RegularationPending=[];
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.EmployeePortalService.GetAttendanceRegularisationStatusPendingByEmployeeId( t,EmployeeId,FromDate,ToDate).then(x => 
    {
      this.Regularation = x;
      Object.keys(this.Regularation).forEach( key => 
      {
        this.Regularation[key];
        let Regularationtemp = new AttendanceRegularationModel();
        Regularationtemp = this.Regularation[key];
        this.RegularationPending.push(Regularationtemp);
      });
      if(this.RegularationPending.length == 0)
      {
        this.PendingMsg = true;
        this.ApprovedMsg = false;
        this.RejectedMsg = false;
      }
    });
  }

  GetEmployeeRegularationApprovedDetails(EmployeeId,FromDate,ToDate)
  {
    this.RegularationApproved=[];
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.EmployeePortalService.GetAttendanceRegularisationStatusApprovedByEmployeeId( t,EmployeeId,FromDate,ToDate).then(x => 
    {
      this.Regularation = x;
      Object.keys(this.Regularation).forEach( key => 
      {
        this.Regularation[key];
        let Regularationtemp = new AttendanceRegularationModel();
        Regularationtemp = this.Regularation[key];
        this.RegularationApproved.push(Regularationtemp);
      });
      if(this.RegularationApproved.length == 0)
      {
        this.PendingMsg = false;
        this.ApprovedMsg = true;
        this.RejectedMsg = false;
      }
    });
  }

  GetEmployeeRegularationRejectedDetails(EmployeeId,FromDate,ToDate)
  {
    this.RegularationRejected=[];
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.EmployeePortalService.GetAttendanceRegularisationStatusRejectedByEmployeeId( t,EmployeeId,FromDate,ToDate).then(x => 
    {
      this.Regularation = x;
      Object.keys(this.Regularation).forEach( key => 
      {
        this.Regularation[key];
        let Regularationtemp = new AttendanceRegularationModel();
        Regularationtemp = this.Regularation[key];
        this.RegularationRejected.push(Regularationtemp);
      });
      if(this.RegularationRejected.length == 0)
      {
        this.PendingMsg = false;
        this.ApprovedMsg = false;
        this.RejectedMsg = true;
      }
    });
  }

  RefreshPage()
  {
    this.rout.navigateByUrl('/Employee/EmployeeOffer', {skipLocationChange: true}).then(()=>this.rout.navigate(["/Employee/AllRegularizationStatus"]));
  }

  viewAppliedRegularization(id,template) 
  {
    this.DisableSave = true;
    this.ModelListApplication.Remarks="";
    // Begning of the permission portion
    this.IsDataIsInViewState=false;
    // End of the permision portion
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.ApproveLeaveService.GetAttendanceRegularationdetailsByID( t,id).then(x => 
    {
      if(this.ShowPopup==true)
      {
        this.modalRef.hide();
      }
      this.modalRef = this.modalService.show(template,this.modelopt);
      this.ShowPopup=true;
      this.CancelMsg=false;
      this.Approver = x;
      Object.keys(this.Approver).forEach( key => 
      {
        // tslint:disable-next-line:no-unused-expression
        this.Approver[key];
        let Approvaltemp = new AttendanceRegularationModel();
        Approvaltemp = this.Approver[key];
        this.ModelListApplication.RefId=Approvaltemp.RefId;
        this.ModelListApplication.EmployeeId=Approvaltemp.EmployeeId;
        this.ModelListApplication.EmployeeCode=Approvaltemp.EmployeeCode;
        this.ModelListApplication.EmployeeName=Approvaltemp.EmployeeName; 
        this.ModelListApplication.EmployeeEmailId=Approvaltemp.EmployeeEmailId; 
        this.ModelListApplication.AttendanceDate=Approvaltemp.AttendanceDate; 
        this.ModelListApplication.AttendanceDay=Approvaltemp.AttendanceDay; 
        this.ModelListApplication.ActualInTime=Approvaltemp.ActualInTime; 
        this.ModelListApplication.ActualOutTime=Approvaltemp.ActualOutTime; 
        this.ModelListApplication.ActualWorkingDuration=Approvaltemp.ActualWorkingDuration; 
        this.ModelListApplication.StartTime=Approvaltemp.StartTime;
        this.ModelListApplication.EndTime=Approvaltemp.EndTime; 
        this.ModelListApplication.TotalWorkingHours=Approvaltemp.TotalWorkingHours; 
        this.ModelListApplication.RegularationDuration=Approvaltemp.RegularationDuration; 
        this.ModelListApplication.Reason=Approvaltemp.Reason; 
        this.ModelListApplication.RHCode=Approvaltemp.RHCode;
        this.ModelListApplication.RHName=Approvaltemp.RHName;
        this.ModelListApplication.RHEmailId=Approvaltemp.RHEmailId;
        this.ModelListApplication.CompanyId=Approvaltemp.CompanyId;
      });
    });
  }

  AttendanceRegularationCancel(template) :void
  {
    if(confirm("Are you sure to Cancel "))
    {
      this.DisableSave=false;
      let t =  localStorage.getItem('userToken');
      t = 'Bearer' + t;
      if(this.ModelListApplication.RefId > 0)
      {
        this.ModelListApplication.IsRejected=true;
        this.ApproveLeaveService.AttendanceRegularationCancel(t ,this.ModelListApplication).subscribe(res=>
        {
          this.RefId=res;
          this.ModelListApplication.RefId=0;  
          if(this.RefId>0)
          {
            this.ModelListApplication.Remarks="";
            this.modalRef.hide();
            this.CancelMsg=true;
            this.ShowPopup=false;
            this.modalRef = this.modalService.show(template);
            this.GetEmployeeRegularationPendingDetails(this.ModelListApplication.EmployeeId,this.ModelListApplication.SearchFromDate,this.ModelListApplication.SearchToDate);
            this.GetEmployeeRegularationRejectedDetails(this.ModelListApplication.EmployeeId,this.ModelListApplication.SearchFromDate,this.ModelListApplication.SearchToDate);
            this.DisableSave=true;
          }
        });                  
      }
    }
  }

  SearchFromDateChanged( date)
  {
    this.DisableSave = true;
    this.PendingMsg = false;
    this.ApprovedMsg = false;
    this.RejectedMsg = false;
    this.RegularationPending=[];
    this.RegularationApproved=[];
    this.RegularationRejected=[];
    
    this.ModelListApplication.SearchFromDate=date;
  }

  SearchToDateChanged( date)
  {
    this.DisableSave = true;
    this.PendingMsg = false;
    this.ApprovedMsg = false;
    this.RejectedMsg = false;
    this.RegularationPending=[];
    this.RegularationApproved=[];
    this.RegularationRejected=[];

    this.ModelListApplication.SearchToDate=date;
  }

  StatusChanged()
  {
    this.DisableSave = true;
    this.PendingMsg = false;
    this.ApprovedMsg = false;
    this.RejectedMsg = false;
    this.RegularationPending=[];
    this.RegularationApproved=[];
    this.RegularationRejected=[];
  }

  submitClick()
  {
    this.DisableSave = false;
    this.validdate = false;
    this.validTodate=false;
    this.PendingMsg = false;
    this.ApprovedMsg = false;
    this.RejectedMsg = false;
    this.RegularationPending=[];
    this.RegularationApproved=[];
    this.RegularationRejected=[];
    if(this.ModelListApplication.SearchFromDate!=null && this.ModelListApplication.SearchToDate!=null && this.ModelListApplication.Status > 0)
    {
      if(this.ModelListApplication.SearchToDate >= this.ModelListApplication.SearchFromDate)
      {
        if(this.ModelListApplication.Status == 1)
        {
          this.GetEmployeeRegularationPendingDetails(this.ModelListApplication.EmployeeId,this.ModelListApplication.SearchFromDate, this.ModelListApplication.SearchToDate);
        }
        else if(this.ModelListApplication.Status == 2)
        {
          this.GetEmployeeRegularationApprovedDetails(this.ModelListApplication.EmployeeId,this.ModelListApplication.SearchFromDate, this.ModelListApplication.SearchToDate);
        }
        else
        {
          this.GetEmployeeRegularationRejectedDetails(this.ModelListApplication.EmployeeId,this.ModelListApplication.SearchFromDate, this.ModelListApplication.SearchToDate);
        }
      }
      else
      {
        this.validTodate = true;
      }
    }
  }
}