<div class="sales-call alert-box">
  <div class="modal-header">
    <h4 class="modal-title">Follow-up</h4>
    <button type="button" class="close" aria-label="Close" (click)="_bsModalRef.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>


  <div class="modal-body">
    <div class="container">
      <div class="follow-up-border">
        <h5> Next Follow up </h5>
        <div class="row">
          <div class="col-sm-6">
            <label class="f-w6 mr-1"> Date</label>
            <input [(ngModel)]="this.NextFollowUpdate" readonly="true" id="NextFolloupDate" />
          </div>
          <div class="col-sm-6">
            <label class="f-w6 mr-1"> Time</label>
            <input [(ngModel)]="this.NextFolloupTime" id="NextFolloupTime" />
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12">
            <button type="button" (click)="ButtonClick()" class="btn btn-info save-btn-popup mb-2">
              Save
            </button>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-sm-12">
          <form #ApprovedApplicationForm="ngForm">
            <div *ngIf="this.FollowId >0"> saved successfully</div>

            <table id="tblDocument" class="table table-striped table-bordered text-left table-new row-border hover alert-box-style">
              <thead class="thead-blue ">
                <tr>
                  <th style="width: 80%;">Follows up</th>
                  <th>Date </th>
                  <th>Time</th>
                  <th style="width: 20%;">Action</th>
                </tr>

              </thead>
              <tbody>
                <tr *ngFor="let Follows of this.GetFollowUps, let i = index">
                  <td>

                    <textarea id="OppertunityDescription" style="width:100%; height:50px;" (change)="OportunityDesription($event,Follows.FollowUpId)">{{Follows.FolloWUpText}}</textarea>

                  </td>
                  <td>

                    <label>{{Follows.FollowUpdate}}</label>

                  </td>
                  <td *ngIf="Follows.FollowUpId >0">
                    {{Follows.FollowUpTime}}
                  </td>
                  <td *ngIf="Follows.FollowUpId ==0">

                  </td>


                  <td>
                    <button type="button" (click)="SaveButton(Follows.FolloWUpText, Follows.FollowUpId)" class="btn btn-info save-btn-popup ml-2">

                      Save
                    </button>


                    <!-- <button *ngIf="Files.DocumentId == 0" type="button" (click)="DeleteFile(Files)"
                      class="table-remove-btn">
                      <i class="fas fa-trash-alt"></i>
                    </button> -->


                  </td>



                </tr>

              </tbody>
            </table>
            <!-- <pagination-controls (pageChange)="p = $event" style="float:right"></pagination-controls> -->

          </form>
        </div>
      </div>
    </div>
  </div>
</div>



<!-- <div class="modal-footer">
    <button type="button" class="btn btn-secondary" (click)="onConfirm()">Yes</button>
    <button type="button" class="btn btn-secondary" (click)="onCancel()">No</button>
  </div> -->