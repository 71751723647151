import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from '../../shared/user.service';
import { EmployeePortalService } from '../../shared/HR_ADMIN/EmployeePortal/EmployeePortalService.service';
import { EmployeeDetail } from '../../shared/HR_ADMIN/Employee/EmployeeDetail.Model';
import { EmployeeLeaveBalanceModel } from '../../shared/HR_ADMIN/EmployeePortal/EmployeeLeaveBalanceModel.Model';
import { OrganizationService } from '../../shared/Organization/organization.service';
import { LeaveMasterService } from '../../shared/HR_ADMIN/Leave/LeaveMaster.service';
import { Company } from '../../shared/Organization/Company.Model';
import { Department } from '../../shared/Organization/Department.Model';
import { Branch } from '../../shared/Organization/Branch.Model';
import { Menu } from '../../shared/menu.model';
import { LeaveType } from '../../shared/HR_ADMIN/Leave/LeaveType.Model';
// Begning Confirmation
import { ConfirmationService } from '@jaspero/ng2-confirmations';
import { ResolveEmit } from '../../../app/resolve-emit';
import { ConfirmSettings } from '../../../app/confirm-settings';
// end of Confirmation
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-manage-leave-balance-adjustment',
  templateUrl: './manage-leave-balance-adjustment.component.html',
  styleUrls: ['./manage-leave-balance-adjustment.component.css']
})
export class ManageLeaveBalanceAdjustmentComponent implements OnInit 
{
  EmployeeLeaveBalanceModel: EmployeeLeaveBalanceModel = new EmployeeLeaveBalanceModel();
  EmployeeDetailModel: EmployeeDetail = new EmployeeDetail();
  SuccessStatus: boolean = false;
  SuccessMessage: string = "";
  SelectedMenu: Menu;
  IsDataIsInViewState: boolean = false;
  CanViewState: boolean = true;
  DisableSave: boolean = false;
  LeaveTypeMain: LeaveType[]=[];
  LeaveType: any;
  company: any;
  Companies: Company[] = [];
  Branch: any;
  Branches: Branch[] = [];
  Departments: Department[] = [];
  Department: any;
  LeaveBalanceAdjust : any;
  EmployeeLeaveDetails : EmployeeLeaveBalanceModel[]=[];
  EmployeeLeave : any;
  TempLeave : EmployeeLeaveBalanceModel;
  //For Pop up
  public modalRef: BsModalRef;

  constructor(private LeaveMasterService: LeaveMasterService, private rout: Router, private userService: UserService, private EmployeePortalService: EmployeePortalService, private organizationService: OrganizationService,  private _confirmation: ConfirmationService,private modalService: BsModalService)
  { }

  ngOnInit()
  {
    //Begin Permission Portion
    this.SelectedMenu=this.userService.SelectedMenu;
    if(this.SelectedMenu !=undefined)
    {
      localStorage.setItem('selectedmenu',JSON.stringify(this.SelectedMenu));
      this.rout.navigate([this.SelectedMenu.RouteName]);
    }
    else
    {
      if(JSON.parse(localStorage.getItem('selectedmenu'))!=undefined)
      {
        this.SelectedMenu = JSON.parse(localStorage.getItem('selectedmenu'));
        this.rout.navigate([this.SelectedMenu.RouteName]);
      }
    }
    if(this.SelectedMenu.CanAdd)
    {
    this.IsDataIsInViewState=false;
    }
    else
    {
      this.IsDataIsInViewState=true;
    }
    if(this.SelectedMenu.CanView)
    {
    this.CanViewState=false;
    }
    else
    {
      this.CanViewState=true;
    }
    //End of the peremission portion
    if(localStorage.getItem('SuccessfullMessage'))
    {
      this.SuccessStatus=true;
      this.SuccessMessage =localStorage.getItem('SuccessfullMessage')
      localStorage.removeItem('SuccessfullMessage');
    }
    this.DisableSave = true;
    this.getCompanies();
    this.GetLeaveType();
    this.EmployeeLeaveBalanceModel.EmployeeId = 0;
    this.EmployeeLeaveBalanceModel.CompanyId = 0;
    this.EmployeeLeaveBalanceModel.BranchId = 0;
    this.EmployeeLeaveBalanceModel.DepartmentId = 0;
    this.EmployeeLeaveBalanceModel.LeaveTypeId = 0;
    this.EmployeeLeaveBalanceModel.Adjustmentvalue="Bulk";
  }

  settings: ConfirmSettings | any = 
  {
    overlay: true,
    overlayClickToClose: true,
    showCloseButton: true,
    confirmText: 'Yes',
    declineText: 'No'
  };

  getCompanies()
  {
    this.Companies=[];
    let t = localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.organizationService.GetCompany(t).then(x => 
    {
      this.company = x;
      Object.keys(this.company).forEach(key => 
      {
        // tslint:disable-next-line:no-unused-expression
        this.company[key];
        let Companytemp = new Company();
        Companytemp = this.company[key];
        this.Companies.push(Companytemp);
      });
    });
  }

  onChangeCompany(Companyid) 
  {
    if (Companyid > 0) 
    {
      this.getBranches(Companyid);
      this.EmployeeLeaveBalanceModel.BranchId = 0;
      this.EmployeeLeaveBalanceModel.DepartmentId = 0;
      this.getDepartments(0);
      this.EmployeeLeaveDetails=[];
    }
  }

  onchangeBranch(Branchid) 
  {
    if (Branchid > 0) 
    {
      this.getDepartments(Branchid);
      this.EmployeeLeaveBalanceModel.DepartmentId = 0;
      this.EmployeeLeaveDetails=[];
    }
    else
    {
      this.getDepartments(0);
      this.EmployeeLeaveBalanceModel.DepartmentId = 0;
      this.EmployeeLeaveDetails=[];
    }
  }

  onchangeDepartment() 
  {
    this.EmployeeLeaveDetails=[];
  }

  getBranches(companyid)
  {
    this.Branches=[];
    let t = localStorage.getItem('userToken');
    t = 'Bearer' + t
    if (this.Branches.length > 0) 
    {
      this.Branches.splice(0, this.Branches.length);
    }
    this.organizationService.GetBranchesByComp(t, companyid).then(x => 
      {
      this.Branch = x;
      Object.keys(this.Branch).forEach(key => 
      {
        // tslint:disable-next-line:no-unused-expression
        this.Branch[key];
        let Branchtemp = new Branch();
        Branchtemp = this.Branch[key];
        this.Branches.push(Branchtemp);
      });
    });
  }

  getDepartments(Branchid)
  {
    this.Departments = [];
    let t = localStorage.getItem('userToken');
    t = 'Bearer' + t
    if (this.Departments.length > 0) 
    {
      this.Departments.splice(0, this.Departments.length)
    }
    this.organizationService.GetDepartmentByBranchid(t, Branchid).then(x => {
      this.Department = x;
      Object.keys(this.Department).forEach(key => {
        this.Department[key];
        let Departmenttemp = new Department();
        Departmenttemp = this.Department[key];
        this.Departments.push(Departmenttemp);
      });
    });
  }

  GetLeaveType()
  {
    this.LeaveTypeMain=[];
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.EmployeePortalService.GetLeaveTypeForAdjustment( t).then(x => 
    {
      this.LeaveType = x;
      Object.keys(this.LeaveType).forEach( key => 
      {
        this.LeaveType[key];
        let LeaveTypetemp = new LeaveType();
        LeaveTypetemp = this.LeaveType[key];
        this.LeaveTypeMain.push(LeaveTypetemp);
      });
    });
  }

  OnChangeCredit(evt,i:number)
  {
    this.EmployeeLeaveDetails[i];
    var target = evt.target;
    if(target.checked)
    {           
      this.EmployeeLeaveDetails[i].Credit=true;
      this.EmployeeLeaveDetails[i].Debit=false;
      this.EmployeeLeaveDetails[i].AdjustmentTypevalue="Credit";
    }
  }

  OnChangeDebit(evt,i:number)
  {
    this.EmployeeLeaveDetails[i];
    var target = evt.target;
    if(target.checked)
    {           
      this.EmployeeLeaveDetails[i].Credit=false;
      this.EmployeeLeaveDetails[i].Debit=true;
      this.EmployeeLeaveDetails[i].AdjustmentTypevalue="Debit";
    }
  }

  onChangeLeaveTypeId()
  {
    this.EmployeeLeaveDetails=[];
  }

  saveLeaveBalanceAdjustment(template)
  {
    if(this.EmployeeLeaveDetails.length > 0)
    {
      for(var item of this.EmployeeLeaveDetails)
      {
        if(item.EmployeeId > 0 && (item.Selected == true))
        {
          this.DisableSave=false;
          break;
        }
      }
    } 

    if(this.EmployeeLeaveDetails.length > 0 && this.DisableSave == false)
    {
      this._confirmation.create('Confirm to Submit', 'Press Yes to Submit', this.settings)
      .subscribe((ans: ResolveEmit) => 
      {
        if (ans.resolved == true) 
        {
          //this.DisableSave=false;
          let t =  localStorage.getItem('userToken');
          t = 'Bearer' + t;    
          var j=0;
          var l=this.EmployeeLeaveDetails.length;
          this.EmployeeLeaveDetails.forEach(item => 
          {
            j=j+1;
            if(item.EmployeeId > 0 && item.Selected == true && item.NoOfLeave > 0)
            {      
              if(item.RefId==null)
              {
                item.RefId=0;
              }
              // if(item.AdjustmentTypevalue=='Credit')
              // {
              //   item.Credit=true;
              //   item.Debit=false;
              // }
              // else
              // {
              //   item.Credit=false;
              //   item.Debit=true;
              // }

              this.LeaveMasterService.EmployeeLeaveBalanceAdjustment(t ,item).subscribe(res=>
              {
                this.LeaveBalanceAdjust=res;        
                if(this.LeaveBalanceAdjust>0)
                {
                  if(j==this.EmployeeLeaveDetails.length)
                  {
                    this.modalRef = this.modalService.show(template);
                    this.EmployeeLeaveDetails=[];
                    this.DisableSave=true;
                  }        
                }
              }); 
            }
          });
        }
        else
        {
          this.DisableSave=true;
        }
      })
    }
  }
  
  GetEmployeeLeaveBalanceDetailsForAdjustment()
  {
    this.EmployeeLeaveDetails=[];
    let t = localStorage.getItem('userToken');
    t = 'Bearer' + t
    this.LeaveMasterService.GetEmployeeLeaveBalanceDetailsForAdjustment(t,this.EmployeeLeaveBalanceModel.LeaveTypeId,this.EmployeeLeaveBalanceModel.CompanyId,this.EmployeeLeaveBalanceModel.BranchId,this.EmployeeLeaveBalanceModel.DepartmentId).then(x => 
    {
      this.EmployeeLeaveDetails=[];
      this.EmployeeLeave = x;
      Object.keys(this.EmployeeLeave).forEach(key => 
      {
        // tslint:disable-next-line:no-unused-expression
        this.EmployeeLeave[key];
        let Adjusttemp = new EmployeeLeaveBalanceModel();
        Adjusttemp = this.EmployeeLeave[key];
        if(Adjusttemp.NoOfLeave==0)
        {
          Adjusttemp.NoOfLeave=null;
        }
        Adjusttemp.Credit=true;
        Adjusttemp.Debit=false;
        Adjusttemp.AdjustmentTypevalue='Credit';
        this.EmployeeLeaveDetails.push(Adjusttemp);
      });
    });
  }

  SelectedChange(rowid,ev)
  {
    this.TempLeave =this.EmployeeLeaveDetails[rowid];
    if(ev.target.checked==true)
    {
      this.TempLeave.Selected=true;
    }
    else
    {
      this.TempLeave.Selected=false;
    }
  }

  checkAll(ev) 
  {
    if(ev.target.checked == true)
    {
      var J=this.EmployeeLeaveDetails.length;
      for(var i=0;i<J;i++) 
      {
        this.EmployeeLeaveDetails[i].Selected=true;
      }
    }
    else
    {
      var J=this.EmployeeLeaveDetails.length;
      for(var i=0; i<J; i++)
      {
        this.EmployeeLeaveDetails[i].Selected=false;
      }
    }
  }

  RefreshPage()
  {
    this.rout.navigateByUrl('/LeaveMaster/EmployeeLeaveBalance', {skipLocationChange: true}).then(()=>this.rout.navigate(["/LeaveMaster/EmployeeLeaveBalanceAdjustment"]));
  }
}