<app-dialog [(visible)]="this.SuccessStatus">
  <span class="successmsg">{{this.SuccessMessage}}</span>
</app-dialog>

<div *ngIf="!(OpportunityNoteFileName =='')">

  <table id="tblDocument" class="table table-striped table-bordered text-left table-new row-border">
    <thead class="thead-blue text-center">
      <tr>
        <th style="width: 25%;">File Name</th>

        <th style="width: 10%;">Size</th>
        <th style="width: 10%;">Extension</th>
        <th style="width: 14%;">Action</th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td>

          {{OpportunityNoteFileName}}

        </td>

        <td>
          {{OpportunityNoteFileSize}}
        </td>
        <td>
          {{OpportunityNoteFileExtension}}
        </td>

        <td>
          <button type="button" (click)="DeleteFile()" class="table-remove-btn">
            <i class="fas fa-trash-alt"></i>
          </button>
        </td>



      </tr>
    </tbody>
  </table>
</div>

<fieldset>

  <form>
    <div class=" row" style="padding: 20px;">

      <div class="col-lg-4">
        <div class="col-body-border-right">

          <div class="form-group row">
            <label class="col-sm-4 col-form-label clear-padding-right"> Reason For Lost </label>
            <div class="col-sm-8 clear-left-padding">
              <input type="text" [(ngModel)]="OpportunityNoteReason"
                maxlength="2500" tabindex="6" id="Reason" name="Reason" #Reason="ngModel" class="form-control form-control-custom
                                      form-control-custom-new">

            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-4">
        <div class="form-group row">
          <label class="col-sm-4 col-form-label clear-padding-right"> Retention In % </label>
          <div class="col-sm-8 clear-left-padding">
            <input type="text" maxlength="2"
              [(ngModel)]="OpportunityNoteRetention" tabindex="2"
              id="Retention" name="Retention" #Retention="ngModel" placeholder="In Percent" NumberOn class="form-control form-control-custom 
                            form-control-custom-new">

          </div>
        </div>
      </div>
      <div class="col-lg-4">
        <div class="form-group row">
          <label class="col-sm-4 col-form-label clear-padding-right"> GP Figure </label>
          <div class="col-sm-8 clear-left-padding">
            <!-- <input type="text" [(ngModel)]="this.CRM_OppertunityService.CRM_MoreInformation_ModelShare.GPFigure"
                maxlength="500" tabindex="1" id="GPFigure" name="GPFigure" #GPFigure="ngModel" required class="form-control form-control-custom
                            form-control-custom-new"> -->

            <input myNumberOnly placeholder="In Percent" maxlength="3" tabindex="10"
              [(ngModel)]="OpportunityNoteGPFigure" id="GPFigure"
              name="GPFigure" #GPFigure="ngModel" required class="form-control form-control-custom
                                              form-control-custom-new">

          </div>
        </div>
      </div>



      <!-- <div class="col-lg-4">
        <div class="col-body-border-right">

          <div class="form-group row">
            <label class="col-sm-4 col-form-label clear-padding-right">Note Date </label>
            <div class="col-sm-8 clear-left-padding">

              <input type="text" value="this.CRM_OppertunityService.CRm_Opportunity_Notes.Propernotedate"
                readonly="readonly" [minimumDate]="false" [onlyDatePicker]="true" id="startDate_id" myDatepicker
                (dateChange)="onStartedateChange($event)"  tabindex="1"
                class="form-control form-control-custom form-control-custom-new ng-pristine ng-valid ng-touched" />


            </div>
          </div>




          <div class="form-group row">
            <label class="col-sm-4 col-form-label clear-padding-right">Is Important
             
            </label>
            <div class="col-sm-8 clear-left-padding"  tabindex="4" >
              
              <input type="checkbox" [value]="CanAdd" (click)="OnChangeImportant($event)"
                [checked]="this.CRM_OppertunityService.CRm_Opportunity_Notes.Importantcheck=='Importantcheck'"
                id="Importantcheck" name="Importantcheck" class="">
            </div>
          </div>



          <div class="form-group row">
            <label class="col-sm-4 col-form-label clear-padding-right">First Name
             
            </label>
            <div class="col-sm-3 clear-left-padding padL7 clear-padding-right pad-l-r_0">
              <select [(ngModel)]="this.CRM_OppertunityService.CRm_Opportunity_Notes.Salution" tabindex="6"
                (ngModelChange)="onChangeMister($event)" id="MR" class="form-control" name="MR" #MR="ngModel">
                <option [selected]="true" [value]="0">Dr</option>
                <option [value]="1">Mr</option>
                <option [value]="2">Mrs</option>
                <option [value]="3">Ms</option>
              </select>

            </div>
            <div class="col-sm-5 clear-left-padding">
              <input type="text" [(ngModel)]="this.CRM_OppertunityService.CRm_Opportunity_Notes.FirstName"
                maxlength="100" tabindex="7" id="FirstName" name="FirstName" #FirstName="ngModel"
                class="form-control form-control-custom form-control-custom-new">
            </div>
          </div>

          <div class="form-group row">
            <label class="col-sm-4 col-form-label clear-padding-right">Title
            </label>
            <div class="col-sm-8 clear-left-padding">
              <input type="text" [(ngModel)]="this.CRM_OppertunityService.CRm_Opportunity_Notes.Title" maxlength="100"
                tabindex="10" id="Title" name="Title" #Title="ngModel"
                class="form-control form-control-custom form-control-custom-new">
            </div>
          </div>

          <div class="form-group row">
            <label class="col-sm-4 col-form-label clear-padding-right">Role
            </label>
            <div class="col-sm-8 clear-left-padding">
              <select [(ngModel)]="this.CRM_OppertunityService.CRm_Opportunity_Notes.Roll" tabindex="13" id="RoleId"
                class="form-control form-control-custom form-control-custom-new ng-pristine ng-valid ng-touched"
                name="RoleId" #RoleId="ngModel">
                <option [value]="0" [disabled]=true> Select Role </option>
                <option *ngFor="let c of this.OpportunityRollList" [value]="c.RollId">{{c.RollName}} </option>
              </select>
            </div>




          </div>
        </div>


      </div> -->

      <!-- <div class="col-lg-4">
        <div class="col-body-border-right">

          <div class="form-group row">
            <label class="col-sm-4 col-form-label clear-padding-right">Time
             

            </label>
            <div class="col-sm-8 clear-left-padding">
              

              <input type="text" [(ngModel)]="this.CRM_OppertunityService.CRm_Opportunity_Notes.NoteTime" maxlength="5"
                tabindex="2" id="NoteTime" name="NoteTime" #NoteTime="ngModel"
                class="form-control form-control-custom form-control-custom-new">

            </div>
          </div>



          <div class="form-group row">
            <label class="col-sm-4 col-form-label clear-padding-right">Person spoken to
             
            </label>
            <div class="col-sm-8 clear-left-padding">
              <select [(ngModel)]="this.CRM_OppertunityService.CRm_Opportunity_Notes.ContactId" tabindex="5" id="ContactId" class="form-control"
                name="ContactId" #ContactId="ngModel">
                <option [value]="0" [disabled]=true>Select Contact Name</option>
                <option *ngFor="let c of this.contactlist" [value]="c.ContactID">{{c.FirstName}}</option>
              </select>
            </div>
          </div>





          <div class="form-group row">
            <label class="col-sm-4 col-form-label clear-padding-right">Middle Name
            </label>
            <div class="col-sm-8 clear-left-padding">

              <input type="text" [(ngModel)]="this.CRM_OppertunityService.CRm_Opportunity_Notes.MiddleName"
                maxlength="100" tabindex="8" id="MiddleName" name="MiddleName" #MiddleName="ngModel"
                class="form-control form-control-custom form-control-custom-new">

            </div>
          </div>

          <div class="form-group row">
            <label class="col-sm-4 col-form-label clear-padding-right">Work phone
            </label>
            <div class="col-sm-8 clear-left-padding">
              <input type="text" [(ngModel)]="this.CRM_OppertunityService.CRm_Opportunity_Notes.WorkerPhone"
                maxlength="12" tabindex="11" id="WorkerPhone" name="WorkerPhone" #WorkerPhone="ngModel"
                class="form-control form-control-custom form-control-custom-new">
            </div>
          </div>


          <div class="form-group row">
            <label class="col-sm-4 col-form-label clear-padding-right"
              *ngIf="(this.CRM_OppertunityService.CRm_Opportunity_Notes.FileName =='')"> Attachment</label>
            <div *ngIf="(this.CRM_OppertunityService.CRm_Opportunity_Notes.FileName =='')" class="col-sm-8 form-group"  tabindex="14" >
              <label>File Uploads</label>
              <input type="file" (change)="fileuploads($event)" multiple class="form-control" style="height:100%">
            </div>
          </div>


        </div>
      </div> -->
      <!-- 
     
      <div class="col-lg-4">
        <div class="form-group row">
          <label class="col-sm-4 col-form-label clear-padding-right">Note
            <span class="required-validation">*</span>
          </label>
          <div class="col-sm-8 clear-left-padding"  tabindex="3" >   
            <textarea id="some-value" style="width:200px; height:50px;"
              (change)="doTextareaValueChange($event)">{{this.CRM_OppertunityService.CRm_Opportunity_Notes.Note}}</textarea>
          </div>
          </div>


        <div class="form-group row">
          <label class="col-sm-4 col-form-label clear-padding-right">Last Name
          </label>
          <div class="col-sm-8 clear-left-padding">
        
            <input type="text" [(ngModel)]="this.CRM_OppertunityService.CRm_Opportunity_Notes.LastName" maxlength="100" tabindex="9"
              id="LastName" name="LastName" #LastName="ngModel" class="form-control form-control-custom form-control-custom-new">
        
          </div>
        </div>


        <div class="form-group row">
          <label class="col-sm-4 col-form-label clear-padding-right">Email
          </label>
          <div class="col-sm-8 clear-left-padding">
            <input type="text" [(ngModel)]="this.CRM_OppertunityService.CRm_Opportunity_Notes.Email" maxlength="300" tabindex="12" id="Email"
              name="Email" #Email="ngModel" class="form-control form-control-custom form-control-custom-new">
          </div>
        </div>


        <div class="form-group row">
         
          <label class="col-sm-4 col-form-label clear-left-padding">Links</label>
          <div class="col-sm-8 clear-left-padding">
            <textarea id="Imp-Links" style="width:200px; height:50px;"  tabindex="15"
              (change)="doImpLinksValueChange($event)">{{this.CRM_OppertunityService.CRm_Opportunity_Notes.ImportantLink}}</textarea>
          </div>



        </div>
      </div> -->



      <div class="col-lg-4">
        <div class="form-group row">
          <div *ngIf="!OpportunityNoteIsViewstate" class="col-sm-6 col-form-label text-center">
            <!-- <button *ngIf="(this.CRM_OppertunityService.CRm_Opportunity_Notes.Note.length > 0)" type="submit"
              id="submit" tabindex="16" class="btn btn-primary save-button">Save</button> -->
            <button type="button" id="submit" tabindex="14" class="btn btn-primary save-button"
              (click)="saveCRMMoreInformation()"
              [disabled]="!((OpportunityNoteReason !='')
      || (OpportunityNoteGPFigure !=0) || (OpportunityNoteRetention !=0) )">Save</button>

          </div>
          <div class="col-sm-6 col-form-label text-center">
            <!-- <button type="button" tabindex="17"
              (click)="RefreshOpportunity()" class="btn btn-danger cancle-button">Cancel</button> -->

            <button type="button" tabindex="17" (click)="RefreshPage()"
              class="btn btn-danger cancle-button">Cancel</button>

          </div>
        </div>
      </div>

    </div>


  </form>
</fieldset>