import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from '../../shared/user.service';
import { EmployeeDetail } from '../../shared/HR_ADMIN/Employee/EmployeeDetail.Model';
import { EmployeeAttendanceModel } from '../../shared/HR_ADMIN/EmployeePortal/EmployeeAttendanceModel.Model';
import { Menu } from '../../shared/menu.model';
import { FinancialYear } from  '../../shared/Organization/FinancialYear.Model';
import { Company } from  '../../shared/Organization/Company.Model';
import { Branch } from  '../../shared/Organization/Branch.Model';
import { Department } from  '../../shared/Organization/Department.Model';
import { OrganizationService } from '../../shared/Organization/organization.service';
import { EmployeePortalService } from '../../shared/HR_ADMIN/EmployeePortal/EmployeePortalService.service'
import { ConfirmationService } from '@jaspero/ng2-confirmations';
import { ResolveEmit } from '../../../app/resolve-emit';
import { ConfirmSettings } from '../../../app/confirm-settings';
import { EmployeeLeaveBalanceModel } from '../../shared/HR_ADMIN/EmployeePortal/EmployeeLeaveBalanceModel.Model';
import { ModalOptions,BsModalRef, BsModalService } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-manage-attendance-modification-datewise',
  templateUrl: './manage-attendance-modification-datewise.component.html',
  styleUrls: ['./manage-attendance-modification-datewise.component.css']
})
export class ManageAttendanceModificationDatewiseComponent implements OnInit 
{
  EmployeeAttendanceModel : EmployeeAttendanceModel= new EmployeeAttendanceModel();
  EmployeeAttendance: EmployeeAttendanceModel[]=[];
  Attendance: any;
  employee: any;
  RefId: any;
  NoRecordMsg : boolean= false;
  Employees: EmployeeDetail[] = [];
  emp : any [] =[];
  FinancialYears: FinancialYear[] = [];
  FinYear:any;
  Months: Company[] = [];
  Month: any;
  EmployeeSettings = {};
  DisableGenerate : boolean;
  AttendanceStatus: EmployeeAttendanceModel[] = [];
  Status:any;
  public modalRef: BsModalRef;
  Companies: Company[] = [];
  company: any;
  Branches: Branch[] = [];
  Branch: any;
  //for Department Dropdown list
  DepartmentList = [];
  DepartmentselectedItems = [];
  dropdownSettings = {};
  DepartmentSettings = {};
  DepartmentMasters :any;
  //for Department Dropdown list
  // Begning Permission Portion    
  SelectedMenu:Menu;
  IsDataIsInViewState : boolean=false;
  CanViewState : boolean=false;
  DisableSave : boolean=false;
  // End of the permission portion
  ValidDate : boolean= false;
  EmployeeLeaveBalance: EmployeeLeaveBalanceModel[]=[];
  LeaveBalance: any;
  SaveMsg : boolean=false;
  ShowPopUp : boolean=false;
  public modelopt:ModalOptions;

  constructor(private EmployeePortalService: EmployeePortalService,private OrganizationService: OrganizationService, private rout: Router, private userService: UserService,private _confirmation: ConfirmationService,private modalService: BsModalService)
  { 
    this.modelopt=new ModalOptions();
    this.modelopt.class='heavy_modal';
    this.modalService.config= this.modelopt;
    this.modelopt.ignoreBackdropClick=true;
  }
  
  ngOnInit() 
  {
    //Begin Permission Portion
    this.SelectedMenu=this.userService.SelectedMenu;
    if(this.SelectedMenu !=undefined)
    {
      localStorage.setItem('selectedmenu',JSON.stringify(this.SelectedMenu));
      this.rout.navigate([this.SelectedMenu.RouteName]);
    }
    else
    {
      if(JSON.parse(localStorage.getItem('selectedmenu'))!=undefined)
      {
        this.SelectedMenu = JSON.parse(localStorage.getItem('selectedmenu'));
        this.rout.navigate([this.SelectedMenu.RouteName]);
      }
    }     
    if(this.SelectedMenu.CanAdd)
    {
      this.IsDataIsInViewState=false;
    }
    else
    {
      this.IsDataIsInViewState=true;
    }
    if(this.SelectedMenu.CanView)
    {
      this.CanViewState=false;
    }
    else
    {
      this.CanViewState=true;
    }
    this.DisableSave=true;

    this.EmployeeAttendanceModel.MonthId=0;
    this.EmployeeAttendanceModel.CompId=0;
    this.EmployeeAttendanceModel.BranchId=0;
    this.getCompanies(); 
    this.DepartmentSettings =
    {
      singleSelection:false,
      text:'Select Department',
      enableSearchFilter:true,
      showCheckbox:true
    }
    this.ShowPopUp=false;
    this.SaveMsg=false;
  }

  settings: ConfirmSettings | any = 
  {
    overlay: true,
    overlayClickToClose: true,
    showCloseButton: true,
    confirmText: 'Yes',
    declineText: 'No'
  };

  getCompanies() 
  {
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.Companies=[];
    this.OrganizationService.GetCompany( t).then(x => 
    {
      this.company = x;
      Object.keys(this.company).forEach( key => 
      {
        this.company[key];
        let Companytemp = new Company();
        Companytemp = this.company[key];
        this.Companies.push(Companytemp);
      });     
    });      
  }

  onChangeCompany(CompId) 
  {
    this.NoRecordMsg= false;
    this.DisableGenerate=true;
    this.EmployeeAttendance=[];
    this.DepartmentList=[];
    this.EmployeeAttendanceModel.BranchId=0;
    this.getBranches(CompId);
    this.EmployeeAttendanceModel.CompId=CompId;
  }

  getBranches( CompId) 
  {
    this.Branches=[];
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t
    this.OrganizationService.GetBranchByCompanyId( t,CompId).then(x => 
    {
      this.Branch = x;
      Object.keys(this.Branch).forEach( key => 
      {
        this.Branch[key];
        let Branchtemp = new Branch();
        Branchtemp = this.Branch[key];
        this.Branches.push(Branchtemp);
      });     
    });      
  }

  submitClick()
  {
    this.DisableGenerate=false;
    this.EmployeeAttendance=[];
    if(this.DepartmentselectedItems.length > 0)  
    {
      this.EmployeeAttendanceModel.DepartmentIds='';
      this.DepartmentselectedItems.forEach(element => 
      {
        this.EmployeeAttendanceModel.DepartmentIds= this.EmployeeAttendanceModel.DepartmentIds + element.id + ",";
      });
      var l = this.EmployeeAttendanceModel.DepartmentIds.length
      this.EmployeeAttendanceModel.DepartmentIds = this.EmployeeAttendanceModel.DepartmentIds.substr(0,l-1);
    }
    else
    {
      this.EmployeeAttendanceModel.DepartmentIds ="";
    }

    this.EmployeeAttendance=[];
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.EmployeePortalService.GETEmployeeAttendanceLocationWise( t,this.EmployeeAttendanceModel.CompId,this.EmployeeAttendanceModel.BranchId,this.EmployeeAttendanceModel.DepartmentIds,this.EmployeeAttendanceModel.AttendanceDate).then(x => 
    {
      this.GetAttendanceStatus();
      this.DisableSave=true;
      this.NoRecordMsg = false;
      this.EmployeeAttendance=[];
      this.Attendance = x;
      Object.keys(this.Attendance).forEach( key => 
      {
        this.DisableSave=true;
        //this.Att[key];
        let Attendancetemp = new EmployeeAttendanceModel();
        Attendancetemp = this.Attendance[key];
        this.EmployeeAttendance.push(Attendancetemp);
      });
      if(this.EmployeeAttendance.length == 0)
      {
        this.NoRecordMsg = true;
      }
    });
  }

  //Department
  onchangeBranch(Branchid)
  {
    this.NoRecordMsg= false;
    this.DisableGenerate=true;
    this.EmployeeAttendance=[];
    this.DepartmentList=[];
    this.EmployeeAttendanceModel.BranchId=Branchid
    this.GetDepartment(Branchid);
  }

  GetDepartment(BranchId) 
  {
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t
    this.DepartmentselectedItems = [];
    this.DepartmentList = [];
    this.OrganizationService.GetDepartmentByBranchid( t,BranchId).then(x => 
    {
      this.DepartmentMasters = x;
      Object.keys(this.DepartmentMasters).forEach( key => 
      {
        this.DepartmentMasters[key];
        let Departmenttemp= new Department();
        Departmenttemp = this.DepartmentMasters[key];
        this.DepartmentList.push({"id":Departmenttemp.DepartmentId,"itemName":Departmenttemp.DepartmentName},);
      });
    });      
  }
  
  onItemSelect(item:any)
  {
    this.NoRecordMsg= false;
    this.DisableGenerate=true;
    this.EmployeeAttendance=[];
  }
  OnItemDeSelect(item:any){ }
  onSelectAll(items: any){ }
  onDeSelectAll(items: any){ }

  AttendanceDateChanged( date)
  {
    this.NoRecordMsg= false;
    this.DisableGenerate=true;
    this.EmployeeAttendance=[];
    this.EmployeeAttendanceModel.AttendanceDate=date;
  }

  GetAttendanceStatus()
  {
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.AttendanceStatus=[];
    this.EmployeePortalService.GETAttendanceStatus( t).then(x => 
    {
      this.Status = x;
      Object.keys(this.Status).forEach( key => 
      {
        this.Status[key];
        let Statustemp = new EmployeeAttendanceModel();
        Statustemp = this.Status[key];
        this.AttendanceStatus.push(Statustemp);
      });     
    });      
  }

  UpdateEmployeeAttendance(template)
  {
    if(this.EmployeeAttendance.length > 0)
    {
      for(var item of this.EmployeeAttendance)
      {
        if(item.Status != item.ModifiedStatus && item.ModifiedStatus != null)
        {
          this.DisableSave=false;
          break;
        }
      }
    } 

    if(this.EmployeeAttendance.length > 0 && this.DisableSave == false)
    {
      this._confirmation.create('Confirm to Attendance Modification', 'Press Yes to Change', this.settings)
      .subscribe((ans: ResolveEmit) => 
      {
        if (ans.resolved == true) 
        {
          //this.DisableSave=false;
          let t =  localStorage.getItem('userToken');
          t = 'Bearer' + t;    
          var j=0;
          var l=this.EmployeeAttendance.length;
          this.EmployeeAttendance.forEach(item => 
          {
            j=j+1;
            if(item.Status != item.ModifiedStatus && item.ModifiedStatus != null)
            {      
              this.EmployeePortalService.UpdateEmployeeAttendanceDetailsDateWise(t ,item).subscribe(res=>
              {
                this.RefId=res;        
              });                              
            }
            if(j == this.EmployeeAttendance.length)
            {
              this.DisableGenerate=true;
              this.modalRef = this.modalService.show(template);
              this.EmployeeAttendance=[];
            } 
          });
        }
        else
        {
          this.DisableSave=true;
        }
      })
    }
  }

  GetEmployeeLeaveBalanceDetails(EmployeeId,EmployeeCode,EmployeeName,template)
  {
    this.EmployeeLeaveBalance=[];
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.EmployeePortalService.GetEmployeeLeaveBalanceByEmployeeId( t,EmployeeId).then(x => 
    {
      if(this.ShowPopUp==true)
      {
        this.modalRef.hide();
      }
      this.EmployeeLeaveBalance=[];
      this.EmployeeAttendanceModel.EmployeeCode=EmployeeCode;
      this.EmployeeAttendanceModel.EmployeeName=EmployeeName;
      this.modalRef = this.modalService.show(template,this.modelopt);
      this.ShowPopUp=true;
      this.SaveMsg=false;
      this.LeaveBalance = x;
      Object.keys(this.LeaveBalance).forEach( key => 
      {
        this.LeaveBalance[key];
        let LeaveBalancetemp = new EmployeeLeaveBalanceModel();
        LeaveBalancetemp = this.LeaveBalance[key];
        this.EmployeeLeaveBalance.push(LeaveBalancetemp);
      });
    });
  }
}
