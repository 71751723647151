import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from '../../../shared/user.service';
import { EmployeePortalService }  from '../../../shared/HR_ADMIN/EmployeePortal/EmployeePortalService.service';
import { EmployeeMasterService }  from '../../../shared/HR_ADMIN/Employee/EmployeeMaster.Service';
import { EmployeeDetail } from '../../../shared/HR_ADMIN/Employee/EmployeeDetail.Model';
import { EmployeeAttendanceModel } from '../../../shared/HR_ADMIN/EmployeePortal/EmployeeAttendanceModel.Model';
import { Menu } from '../../../shared/menu.model';

@Component({
  selector: 'app-employee-attendance-for-rhfh',
  templateUrl: './employee-attendance-for-rhfh.component.html',
  styleUrls: ['./employee-attendance-for-rhfh.component.css']
})
export class EmployeeAttendanceForRhfhComponent implements OnInit 
{
  EmployeeAttendanceModel : EmployeeAttendanceModel= new EmployeeAttendanceModel();
  EmployeeDetailModel : EmployeeDetail= new EmployeeDetail();
  userClaims: any;
  Employees: EmployeeDetail[] = [];
  employee: any;

  EmployeeAttendance: EmployeeAttendanceModel[]=[];
  Attendance: any;

  Result : string;
  SuccessStatus:boolean=false;
  SuccessMessage: string ="";
  public tableWidget: any;
  EmployeeId : any;
  selectedIndex : number;

  // Begning Permission Portion    
  SelectedMenu:Menu;
  IsDataIsInViewState : boolean=false;
  CanViewState : boolean=true;
  // End of the permission portion

  // begin for Validation
  validdate : boolean= false;
  // end of validation
  EmployeeSettings = {};
  selectedItems = [];
  emp : any [] =[]
  DisableSave : boolean=false;

  constructor(private EmployeeMasterService: EmployeeMasterService, private rout: Router, 
     private userService: UserService,private EmployeePortalService: EmployeePortalService) 
  {  }

  ngOnInit() 
  {
    // Begning Permission Portion 
    this.SelectedMenu=this.userService.SelectedMenu;
    if(this.SelectedMenu !=undefined)
    {
      localStorage.setItem('selectedmenu',JSON.stringify(this.SelectedMenu));
      this.rout.navigate([this.SelectedMenu.RouteName]);
    }
    else
    {
      if(JSON.parse(localStorage.getItem('selectedmenu'))!=undefined)
      {
        this.SelectedMenu = JSON.parse(localStorage.getItem('selectedmenu'));
        this.rout.navigate([this.SelectedMenu.RouteName]);
      }
    }
    if(this.SelectedMenu.CanAdd)
    {
      this.IsDataIsInViewState=false;
    }
    else
    {
      this.IsDataIsInViewState=true;
    }
    if(this.SelectedMenu.CanView)
    {
      this.CanViewState=false;
    }
    else
    {
      this.CanViewState=true;
    }
    // End of the permission portion
    if(localStorage.getItem('SuccessfullMessage'))
    {
      this.SuccessStatus=true;
      this.SuccessMessage =localStorage.getItem('SuccessfullMessage')
      localStorage.removeItem('SuccessfullMessage');
    }
    this.GetEmployeeDetailsDDL();
    this.DisableSave=true;
    this.EmployeeAttendanceModel.EmployeeId=0;
    this.EmployeeSettings =
    {
      singleSelection:true,
      text:'Select Employee',
      enableSearchFilter:true,
      showCheckbox:false
    }
  }

  ngAfterViewInit(): void 
  {

  }
  
  GetEmployeeDetailsDDL() 
  {
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.EmployeeMasterService.GetEmployeeNameForRHandFH( t).then(x => 
    {
      this.employee = x;
      Object.keys(this.employee).forEach( key => 
      {
        let RHtemp = new EmployeeDetail();
        RHtemp = this.employee[key];
        this.Employees.push(RHtemp);
        this.emp.push({"id":RHtemp.EmployeeId,"itemName":RHtemp.EmployeeCode + '-' +RHtemp.EmployeeName},);
      });     
    });      
  }

  FromDateChanged( date)
  {
    this.EmployeeAttendanceModel.FromDate=date;
    this.DisableSave=true;
  }

  ToDateChanged( date)
  {
    this.EmployeeAttendanceModel.ToDate=date;
    this.DisableSave=true;
  }

  submitClick()
  {
    if(this.EmployeeAttendanceModel.FromDate!=null && this.EmployeeAttendanceModel.ToDate!=null)
    {
      if(this.EmployeeAttendanceModel.ToDate >= this.EmployeeAttendanceModel.FromDate && this.EmployeeAttendanceModel.EmployeeId!=0) 
      {
        this.DisableSave=false;
        this.GetEmployeeAttendanceDetails(this.EmployeeAttendanceModel.EmployeeId,this.EmployeeAttendanceModel.FromDate, this.EmployeeAttendanceModel.ToDate);
      }
    }
  }
  
  GetEmployeeAttendanceDetails(EmployeeId,FromDate,ToDate)
  {
    this.EmployeeAttendance=[];
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.EmployeePortalService.GetEmployeeAttendanceDetailsByEmployeeId( t,EmployeeId,FromDate,ToDate).then(x => 
    {
      this.Attendance = x;
      Object.keys(this.Attendance).forEach( key => 
      {
        this.Attendance[key];
        let Attendancetemp = new EmployeeAttendanceModel();
        Attendancetemp = this.Attendance[key];
        this.EmployeeAttendanceModel.EmployeeCode=Attendancetemp.EmployeeCode;
        this.EmployeeAttendanceModel.EmployeeName=Attendancetemp.EmployeeName;
        this.EmployeeAttendance.push(Attendancetemp);
      });
    });
  }

  RefreshPage()
  {
    this.rout.navigateByUrl('/Employee/EmployeeOffer', {skipLocationChange: true}).then(()=>this.rout.navigate(["/Employee/EmployeeDetails"]));
  }

  print(): void 
  {
    let printContents, popupWin;
    printContents = document.getElementById('divPrint').innerHTML;
    popupWin = window.open('', '_blank', 'letf=0,top=0,width=2480px,height=3508px,toolbar=0, scrollbars=0,sta­tus=0');
    popupWin.document.open();
    popupWin.document.write(`
      <html>
        <head>
          <title>Employee Daily Attendance</title>
          <style=" margin:25px 10px 15px 30px; font-family:Calibri">
          
          </style>
        </head>
      <body onload="window.print();window.close()">${printContents}</body>
      </html>`
    );
    popupWin.document.close();
  }

  onItemSelect(item:any)
  {
    let empid= item['id'];
    this.EmployeeAttendanceModel.EmployeeId=empid ;
    this.DisableSave=true;      
  }
  OnItemDeSelect(item:any){ }
  onSelectAll(items: any){ }
  onDeSelectAll(items: any){ }
}