<div class="body-content-area tabcontent" id="link1">
  <div class="row">
      <div class="col-lg-9">
          <div class="row">
              <div class="col-lg-1 low-padding text-center">
                  <!-- <img *ngIf="this.SelectedMenu.Image!=null" src={{this.SelectedMenu.Image}} class="body-icon" alt="Icon"/> -->
              </div>
              <div class="col-lg-10 clear-padding">
                  <span class="page-title">Manage Menu Master</span>
                  <small class="page-description"></small>
              </div>
          </div>
      </div>
  </div>

  <div *ngIf="!(this.CanViewState)">
  <table id="tblMenuMaster" datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" class="table table-striped table-bordered table-new row-border hover">
      <thead class="thead-blue text-center">
          <tr>
              <th>Sl No.</th>
              <th class="text-left">Menu Name</th>
              <th class="text-left">Route Name</th>
              <th>Have Child</th>
              <th>Default</th>
              <th>Action</th>
          </tr>
      </thead>
      <tbody>
          <tr *ngFor="let Menu of MenuMasters, let i = index">
              <td scope="row">{{i+1}}</td>
              <td class="text-left">{{Menu.MenuTitle}}</td>
              <td class="text-left">{{Menu.RouteName}}</td>
              <td>{{Menu.HaveChild}}</td>
              <td>{{Menu.DefaultPage}}</td>
              <td>
                  <button *ngIf="this.SelectedMenu.CanEdit"  type="button" (click)="GetMenuMasterById( Menu.MenuID )" class="table-edit-btn"><i class="fas fa-edit"></i> </button> 
                  <button *ngIf="this.SelectedMenu.CanDelete" type="button" (click)="DeleteMenuMasterById( Menu.MenuID )" class="table-remove-btn"><i class="fas fa-trash-alt"></i>  </button>
                  <button *ngIf="this.SelectedMenu.CanView"  type="button" (click)="ViewMenuMasterById( Menu.MenuID )" class="table-view-button" type="button" ><i class="fas fa-eye"></i></button>
              </td>
          </tr>
      </tbody>
  </table>
  <fieldset class="body-form-control">
    <app-dialog [(visible)]="this.SuccessStatus">
      <span class="successmsg">{{this.SuccessMessage}}</span>
    </app-dialog>
      
    <legend class="legend--">Menu Master Creation</legend>
    <form #MenuMasterForm="ngForm" (ngSubmit)="saveMenuMaster()">
      <div class="row">
        <div class="col-lg-4">
          <div class="col-body-border-right">
            <div class="form-group row">
              <label  class="col-sm-4 col-form-label clear-padding-right">Menu Name</label>
              <div class="col-sm-7 clear-left-padding">
                <input type="text" [(ngModel)]="MenuMasterModel.MenuTitle" tabindex="1" id ="MenuTitle"  name="MenuTitle" #MenuTitle="ngModel" required maxlength="250" class="form-control form-control-custom form-control-custom-new" (blur)="CheckName()">
              </div>
              <div *ngIf="this.Namecheck.length > 0" class="alert alert-danger">Menu Name is exist</div>
              <div *ngIf="MenuTitle.invalid && (MenuTitle.dirty || MenuTitle.touched)" class="alert alert-danger">
                <div *ngIf="MenuTitle.errors.required">Menu Name  is required.</div>
                <div *ngIf="MenuTitle.errors.maxlength">Menu Name is not more than 250 characters</div>
              </div>
            </div>
            <div class="form-group row">
              <label  class="col-sm-4 col-form-label clear-padding-right">Route Name</label>
              <div class="col-sm-7 clear-left-padding">
                <input type="text" [(ngModel)]="MenuMasterModel.RouteName" tabindex="4" id ="RouteName" name="RouteName" #RouteName="ngModel" required maxlength="250" class="form-control form-control-custom form-control-custom-new">
              </div>
              <div *ngIf="this.Namecheck.length > 0" class="alert alert-danger">Route Name is exist</div>
              <div *ngIf="RouteName.invalid && (RouteName.dirty || RouteName.touched)" class="alert alert-danger">
                <div *ngIf="RouteName.errors.required">Route Name  is required.</div>
                <div *ngIf="RouteName.errors.maxlength">Route Name is not more than 250 characters</div>
              </div>
            </div>
            <div class="form-group row">
              <label class="col-sm-4 col-form-label clear-padding-right">Defult Page</label>
              <div class="col-sm-7 clear-left-padding">
                <input type="checkbox" [(ngModel)]="MenuMasterModel.DefaultPage" tabindex="7" id ="DefaultPage" name="DefaultPage" #DefaultPage="ngModel" class="form-control form-control-custom form-control-custom-new">
              </div>
            </div>
          </div>
        </div>
              
        <div class="col-lg-4">
          <div class="col-body-border-right">
            <div class="form-group row">
              <label class="col-sm-4 col-form-label clear-padding-right">Have Child</label>
              <div class="col-sm-7 clear-left-padding">
                <input type="checkbox" [(ngModel)]="MenuMasterModel.HaveChild" tabindex="2" id ="HaveChild" name="HaveChild" #HaveChild="ngModel" (ngModelChange)="HaveChildChange()" class="form-control form-control-custom form-control-custom-new">
              </div>
            </div>
            <div class="form-group row">
              <label class="col-sm-4 col-form-label clear-padding-right">Logo</label>
              <div class="col-sm-7 clear-left-padding">
                <img *ngIf="this.MenuMasterModel.IconUrlImage!=null" [src]="MenuMasterModel.IconUrlImage" style="width:125px; height: 90px;" >
                  <input type="file" tabindex="5" style="width:150px;" (change)="handleFileinput($event.target.files)" #Image accept="image/*" >
              </div>
              <label *ngIf="!MenuMasterModel.IconUrlvalidfile" class="col-sm-4 col-form-label clear-padding-right">Invalid File, will not be uploaded </label>
            </div>
          </div>
        </div>

        <div class="col-lg-4">
          <div class="form-group row">
            <label class="col-sm-4 col-form-label clear-padding-right">Module</label>
            <div class="col-sm-7 clear-left-padding">
              <select [(ngModel)]="MenuMasterModel.ModuleId" tabindex="3" (ngModelChange)="onModuleChange($event)" id ="ModuleId" class="form-control" name="ModuleId" #ModuleId="ngModel">
                <option [value]="0" [disabled]=true >Select Module</option>
                <option *ngFor="let module of ModuleMasters" [value]="module.ModuleId">{{module.ModuleName}}</option>
              </select>
            </div>
            <div class="required-validation" *ngIf="MenuMasterModel.ModuleId==0">Module is required</div>
          </div>
          <div class="form-group row" [hidden]="MenuMasterModel.HaveChild">
            <label  class="col-sm-4 col-form-label clear-padding-right">Parent Menu</label>
            <div class="col-sm-7 clear-left-padding">
              <select [hidden]="MenuMasterModel.HaveChild" [(ngModel)]="MenuMasterModel.ParentId" tabindex="3" id ="ParentId" class="form-control" name="ParentId" #ParentId="ngModel">
                <option [value]="0" [disabled]=true >Select Menu</option>
                <option *ngFor="let menu of MenuMasterDDLs" [value]="menu.MenuID">{{menu.MenuTitle}}</option>
              </select>
            </div>
            <div class="required-validation" *ngIf="MenuMasterModel.ParentId==0">Menu is required</div>
          </div>
          <div class="form-group row">
            <label class="col-sm-4 col-form-label clear-padding-right">Small Logo</label>
            <div class="col-sm-7 clear-left-padding">
              <img *ngIf="this.MenuMasterModel.SmallIconUrlImage!=null" [src]="MenuMasterModel.SmallIconUrlImage" style="width:125px; height: 90px;" >
              <input type="file" tabindex="6" style="width:150px;" (change)="SmallUrlFileinput($event.target.files)" #Image accept="image/*" >
            </div>
            <label *ngIf="!MenuMasterModel.SmallIconUrlvalidfile" class="col-sm-4 col-form-label clear-padding-right">Invalid File, will not be uploaded </label>
          </div>
          <div class="form-group row">
          <label class="col-sm-6 col-form-label text-center clear-padding-right" *ngIf="!(this.IsDataIsInViewState)">
            <button type="submit" tabindex="8" class="btn btn-primary save-button" [disabled]="!(MenuMasterForm.valid && this.MenuMasterModel.ParentId !=0 && this.MenuMasterModel.ModuleId !=0 && this.CheckNameExiststatus==true && this.DisableSave==true)">Save</button>  
          </label>
          <div class="col-sm-6 col-form-label text-center">       
            <button type="button" tabindex="9" class="btn btn-danger cancle-button" (click)="RefreshPage()" >Cancel</button></div>
          </div>
        </div>
      </div>
    </form>
  </fieldset>
  </div>
</div>