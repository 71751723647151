
import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';

@Component({
  selector: 'app-save-button',
  templateUrl: './save-button.component.html',
  styleUrls: ['./save-button.component.css']
})
export class SaveButtonComponent implements ICellRendererAngularComp {

  params;
  label: string;

  agInit(params): void {
    this.params = params;

    this.label = this.params.label || null;
  }

  refresh(params?: any): boolean {
    return true;
  }


  onClick($event) {
    if (this.params.onClick instanceof Function) {
      // put anything into params u want pass into parents component


      const params = {
        event: $event,
        rowData: this.params.node.data,
        index: this.params.index





        // ...something
      }

      this.params.onClick(params);

    }
  }



}
