<div class="body-content-area tabcontent" id="link1">
  <div class="row">
    <div class="col-lg-9">
      <div class="row">
        <div class="col-lg-1 low-padding text-center">
          <!-- <img *ngIf="this.SelectedMenu.Image!=null" src={{this.SelectedMenu.Image}} class="body-icon" alt="Icon"/> -->
        </div>
        <div class="col-lg-10 clear-padding">
          <span class="page-title">Manage RayRoll Zone</span>
          <small class="page-description"></small>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="!(this.CanViewState)">
    <div style=" text-align: right;">
      <button (click)="ADDClick(template)" type="button" class="btn btn-primary Add-button">Add New</button> 
    </div>
    <fieldset class="body-form-control">
      <legend class="legend--">PayRoll Zone Details</legend>
      <table id="tblRegionForHR" class="table table-striped table-bordered text-center table-new row-border hover">
        <thead class="thead-blue text-center">
          <tr>
            <th>Sl No.</th>
            <th>Region</th>
            <th>HRs Name</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let RFH of RegionForHRs, let i = index">
            <td scope="row">{{i+1}}</td>
            <td class="text-left">{{RFH.RegionName}}</td>
            <td class="text-left">{{RFH.HRNames}}</td>
            <td>
              <button *ngIf="this.SelectedMenu.CanEdit" type="button" (click)="GetRegionForHRByid(RFH.RefId,template)" class="table-edit-btn"><i class="fas fa-edit"></i> </button> 
              <button *ngIf="this.SelectedMenu.CanDelete" type="button" (click)="DeleteRegionForHRByid(RFH.RefId,template)"class="table-remove-btn"><i class="fas fa-trash-alt"></i></button>
              <button *ngIf="this.SelectedMenu.CanView" type="button" (click)="ViewRegionForHRByid(RFH.RefId,template)" class="table-view-button" type="button" ><i class="fas fa-eye"></i></button>
            </td>
          </tr>
        </tbody>
        <tfoot class="thead-blue text-center">
          <tr>
            <th></th>
            <th></th>
            <th></th>
            <th></th>
          </tr>
        </tfoot>
      </table>
    </fieldset>
  
    <div style=" box-align: center; width: 1500px;">
      <ng-template #template>
        <div class="modal-header" style="background: #2a9bd7; max-width: 1500px;"> 
          <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()" style="color:#fff;">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div  style="text-align: center; max-width: 1500px;">
        <div *ngIf="SaveMsg" style="color: #dc3545; text-align: center;font-size: 15px; font-weight: bold;">Save Successfully</div>
        <div *ngIf="UpdateMsg" style="color: #dc3545; text-align: center;font-size: 15px; font-weight: bold;">Update Successfully</div>
        <div *ngIf="DeleteMsg" style="color: #dc3545; text-align: center;font-size: 15px; font-weight: bold;">Deleted Successfully</div>
        <div style=" width: 100%;" *ngIf="ShowPopup">
          <fieldset class="body-form-control">
            <legend class="legend--">PayRoll Region For HR</legend>
            <form #RegionForHRForm="ngForm" (ngSubmit)="saveRegionForHR(template)">
              <div class="row">
                <div class="col-lg-4">
                  <div class="col-body-border-right">
                    <div class="form-group row">
                      <label class="col-sm-4 col-form-label clear-padding-right">Region</label>
                      <div class="col-sm-7 clear-left-padding">
                        <input type="text" [(ngModel)]="RegionForHRModel.RegionName" tabindex="1" maxlength="250" id ="RegionName" name="RegionName" #RegionName="ngModel" required class="form-control form-control-custom form-control-custom-new" (blur)="checkRegionName()">
                      </div>
                      <div *ngIf="this.RegionForHRCodecheck.length > 0" class="alert alert-danger">Region Name is exist</div>
                      <div *ngIf="RegionName.invalid && (RegionName.dirty || RegionName.touched)" class="alert alert-danger">
                        <div *ngIf="RegionName.errors.required">Region Name is required.</div>
                        <div *ngIf="RegionName.errors.maxlength">Region Name is not more than 250 characters</div>
                      </div>
                    </div>
                  </div>
                </div>
                
                <div class="col-lg-4">
                  <label style="text-align: right;">HR Name :</label>
                </div>

                <div class="col-lg-4">
                  <div class="form-group row">
                    <div class="col-lg-11">
                      <angular2-multiselect class="form-control" [data]="HRdropdownList" [(ngModel)]="HRselectedItems" name="HRselectedItems" 
                        [settings]="HRdropdownSettings" (onSelect)="onItemSelect($event)" (onDeSelect)="OnItemDeSelect($event)" (onSelectAll)="onSelectAll($event)" (onDeSelectAll)="onDeSelectAll($event)">
                      </angular2-multiselect>
                    </div>
                  </div>
                  <div class="form-group row">
                    <label *ngIf="!(this.IsDataIsInViewState)" class="col-sm-6 col-form-label text-center clear-padding-right">
                      <button type="submit" tabindex="4" class="btn btn-primary save-button" [disabled]="!(RegionForHRForm.valid && this.DisableSave==true && this.RegionForHRModel.RegionName != '' && this.HRselectedItems.length > 0 && this.CheckRegionForHRCode ==true)" >Save</button>  
                    </label>
                    <!-- <div class="col-sm-6 col-form-label text-center">
                      <button type="button" tabindex="5" (click)="RefreshPage()" class="btn btn-danger cancle-button">Cancel</button>
                    </div> -->
                  </div>
                </div>
              </div>
            </form>
          </fieldset>
        </div>
        <button type="button" aria-label="Close" (click)="modalRef.hide()" style="background: #2a9bd7; outline: 0px none; border: 0px none; color: #fff; padding: 5px 10px; border-radius: 3px;">
          <span>Close</span>
        </button>             
      </div>
    </ng-template>
  </div>
</div>
</div>