import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from '../../shared/user.service';
import { EmployeePortalService }  from '../../shared/HR_ADMIN/EmployeePortal/EmployeePortalService.service';
import { LeaveApplicationModel } from '../../shared/HR_ADMIN/EmployeePortal/LeaveApplicationModel.Model';
import { Menu } from '../../shared/menu.model';
import { ModalOptions,BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { LeaveApprovalModel } from '../../shared/HR_ADMIN/Approve/ApproveLeave.Model'
import { ApproveLeaveService }  from '../../shared/HR_ADMIN/Approve/ApproveLeave.service';

@Component({
  selector: 'app-employee-leave-status',
  templateUrl: './employee-leave-status.component.html',
  styleUrls: ['./employee-leave-status.component.css']
})
export class EmployeeLeaveStatusComponent implements OnInit 
{
  LeaveApplicationModel : LeaveApplicationModel= new LeaveApplicationModel();
  LeavePending : LeaveApplicationModel[]=[];
  LeaveApproved : LeaveApplicationModel[]=[];
  LeaveRejected : LeaveApplicationModel[]=[];
  Leave : any;
  userClaims : any;
  employee : any;
  Result : string;
  SuccessStatus :boolean=false;
  SuccessMessage : string ="";
  public tableWidget : any;
  selectedIndex : number;
  SelectedMenu : Menu;
  validdate : boolean= false;
  validTodate : boolean= false;
  CancelMsg : boolean= false;
  ShowPopup : boolean= false;
  PendingMsg : boolean= false;
  ApprovedMsg : boolean= false;
  RejectedMsg : boolean= false;
  public modalRef: BsModalRef;
  public modelopt:ModalOptions;
  ModelListApplication: LeaveApprovalModel;
  Approver: any;
  DisableSave : boolean;
  RefId: any;

  constructor(private EmployeePortalService: EmployeePortalService, private rout: Router, private ApproveLeaveService: ApproveLeaveService,private userService: UserService,private modalService: BsModalService)
  { 
    this.ModelListApplication = new LeaveApprovalModel();
    this.ModelListApplication.FromFirstHalf=false;
    this.ModelListApplication.FromSecondHalf=false;
    this.ModelListApplication.ToFirstHalf=false;
    this.ModelListApplication.ToSecondHalf=false;
    this.modelopt=new ModalOptions();
    this.modelopt.class='heavy_modal';
    this.modalService.config= this.modelopt;
    this.modelopt.ignoreBackdropClick=true;
  }

  ngOnInit() 
  {
    // Begning Permission Portion 
    this.SelectedMenu=this.userService.SelectedMenu;
    if(this.SelectedMenu !=undefined)
    {
      localStorage.setItem('selectedmenu',JSON.stringify(this.SelectedMenu));
      this.rout.navigate([this.SelectedMenu.RouteName]);
    }
    else
    {
      if(JSON.parse(localStorage.getItem('selectedmenu'))!=undefined)
      {
        this.SelectedMenu = JSON.parse(localStorage.getItem('selectedmenu'));
        this.rout.navigate([this.SelectedMenu.RouteName]);
      }
    }
    // End of the permission portion
    this.GetEmployeeDetails();
    this.DisableSave = true
    this.ModelListApplication.Status=0;
    this.validdate=false;
    this.validTodate=false;
  }

  GetEmployeeDetails()
  {
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.EmployeePortalService.GetEmployeeDetailsByEmployeeId( t).then(x => 
    {
      this.employee = x;
      Object.keys(this.employee).forEach( key => 
      {
        this.employee[key];
        this.LeaveApplicationModel.EmployeeCode=this.employee.EmployeeCode;
        this.LeaveApplicationModel.EmployeeName=this.employee.EmployeeName;
      });
    });
  }

  GetEmployeeLeavePendingDetails(FromDate,ToDate)
  {
    this.LeavePending=[];
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.EmployeePortalService.GetLeaveStatusPending( t,FromDate,ToDate).then(x => 
    {
      this.Leave = x;
      Object.keys(this.Leave).forEach( key => 
      {
        this.Leave[key];
        let Leavetemp = new LeaveApplicationModel();
        Leavetemp = this.Leave[key];
        this.LeavePending.push(Leavetemp);
      });
      if(this.LeavePending.length == 0)
      {
        this.PendingMsg = true;
        this.ApprovedMsg = false;
        this.RejectedMsg = false;
      }
    });
  }

  GetEmployeeLeaveApprovedDetails(FromDate,ToDate)
  {
    this.LeaveApproved=[];
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.EmployeePortalService.GetLeaveStatusApproved( t,FromDate,ToDate).then(x => 
    {
      this.Leave = x;
      Object.keys(this.Leave).forEach( key => 
      {
        this.Leave[key];
        let Leavetemp = new LeaveApplicationModel();
        Leavetemp = this.Leave[key];
        this.LeaveApproved.push(Leavetemp);
      });
      if(this.LeaveApproved.length == 0)
      {
        this.PendingMsg = false;
        this.ApprovedMsg = true;
        this.RejectedMsg = false;
      }
    });
  }

  GetEmployeeLeaveRejectedDetails(FromDate,ToDate)
  {
    this.LeaveRejected=[];
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.EmployeePortalService.GetLeaveStatusRejected( t,FromDate,ToDate).then(x => 
    {
      this.Leave = x;
      Object.keys(this.Leave).forEach( key => 
      {
        this.Leave[key];
        let Leavetemp = new LeaveApplicationModel();
        Leavetemp = this.Leave[key];
        this.LeaveRejected.push(Leavetemp);
      });
      if(this.LeaveRejected.length == 0)
      {
        this.PendingMsg = false;
        this.ApprovedMsg = false;
        this.RejectedMsg = true;
      }
    });
  }

  CancelLeaveApplication(id,template) 
  {
    this.DisableSave=true;
    this.ModelListApplication.ApprovedRejectedRemarks="";
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.ApproveLeaveService.GetLeavedetailsByID( t,id).then(x => 
    {
      if(this.ShowPopup==true)
      {
        this.modalRef.hide();
      }
      this.modalRef = this.modalService.show(template,this.modelopt);
      this.ShowPopup=true;
      this.CancelMsg=false;
      this.Approver = x;
      Object.keys(this.Approver).forEach( key => 
      {
        // tslint:disable-next-line:no-unused-expression
        this.Approver[key];
        let LeaveApprovetemp = new LeaveApprovalModel();
        LeaveApprovetemp = this.Approver[key];
        this.ModelListApplication.RefId=LeaveApprovetemp.RefId;
        this.ModelListApplication.EmployeeId=LeaveApprovetemp.EmployeeId;
        this.ModelListApplication.EmployeeCode=LeaveApprovetemp.EmployeeCode;
        this.ModelListApplication.EmployeeName=LeaveApprovetemp.EmployeeName; 
        this.ModelListApplication.EmployeeEmailId=LeaveApprovetemp.EmployeeEmailId; 
        this.ModelListApplication.LeaveTypeName=LeaveApprovetemp.LeaveTypeName; 
        this.ModelListApplication.LeaveTypeId=LeaveApprovetemp.LeaveTypeId; 
        this.ModelListApplication.NoDaysLeave=LeaveApprovetemp.NoDaysLeave; 
        this.ModelListApplication.FromDate=LeaveApprovetemp.FromDate; 
        this.ModelListApplication.FromFirstHalf=LeaveApprovetemp.FromFirstHalf; 
        this.ModelListApplication.FromSecondHalf=LeaveApprovetemp.FromSecondHalf;
        if(LeaveApprovetemp.FromFirstHalf==true)
        {
          this.ModelListApplication.Fromvalue="FirstHalf";
        }
        if(LeaveApprovetemp.FromSecondHalf==true)
        {
          this.ModelListApplication.Fromvalue="SecondHalf";
        } 
        this.ModelListApplication.ToDate=LeaveApprovetemp.ToDate; 
        this.ModelListApplication.ToFirstHalf=LeaveApprovetemp.ToFirstHalf; 
        this.ModelListApplication.ToSecondHalf=LeaveApprovetemp.ToSecondHalf; 
        if(LeaveApprovetemp.ToFirstHalf==true)
        {
          this.ModelListApplication.Tovalue="FirstHalf";
        }
        if(LeaveApprovetemp.ToSecondHalf==true)
        {
          this.ModelListApplication.Tovalue="SecondHalf";
        } 
        this.ModelListApplication.ReasonsLeave=LeaveApprovetemp.ReasonsLeave; 
        this.ModelListApplication.ApplicationDate=LeaveApprovetemp.ApplicationDate; 
        this.ModelListApplication.RemarksLeave=LeaveApprovetemp.RemarksLeave;
        this.ModelListApplication.ContactNoDuringLeave=LeaveApprovetemp.ContactNoDuringLeave;
        this.ModelListApplication.AddressDuringLeave=LeaveApprovetemp.AddressDuringLeave;
        this.ModelListApplication.Approved=LeaveApprovetemp.Approved;
        this.ModelListApplication.Rejected=LeaveApprovetemp.Rejected;
        this.ModelListApplication.PersonToContact=LeaveApprovetemp.PersonToContact;
        this.ModelListApplication.RHId=LeaveApprovetemp.RHId;
        this.ModelListApplication.RHCode=LeaveApprovetemp.RHCode;
        this.ModelListApplication.RHName=LeaveApprovetemp.RHName;
        this.ModelListApplication.RHEmailId=LeaveApprovetemp.RHEmailId;
        this.ModelListApplication.CompanyId=LeaveApprovetemp.CompanyId;
      });
      // End for check company code duplication
    });
  }

  LeaveCancel(template) :void
  {
    if(confirm("Are you sure to Cancel "))
    {
      this.DisableSave=false;
      let t =  localStorage.getItem('userToken');
      t = 'Bearer' + t;
      if(this.ModelListApplication.RefId > 0)
      {
        this.ModelListApplication.Rejected=true;
        this.ApproveLeaveService.LeaveCancel(t ,this.ModelListApplication).subscribe(res=>
        {
          this.RefId=res;
          this.ModelListApplication.RefId=0;  
          if(this.RefId>0)
          {
            this.ModelListApplication.ApprovedRejectedRemarks="";
            this.modalRef.hide();
            this.CancelMsg=true;
            this.ShowPopup=false;
            this.modalRef = this.modalService.show(template);
            this.GetEmployeeLeavePendingDetails(this.ModelListApplication.SearchFromDate,this.ModelListApplication.SearchToDate);
            this.GetEmployeeLeaveRejectedDetails(this.ModelListApplication.SearchFromDate,this.ModelListApplication.SearchToDate);
            this.DisableSave=true;
          }
        });                  
      }
    }
  }

  SearchFromDateChanged( date)
  {
    this.DisableSave = true;
    this.PendingMsg = false;
    this.ApprovedMsg = false;
    this.RejectedMsg = false;
    this.LeavePending=[];
    this.LeaveApproved=[];
    this.LeaveRejected=[];
    
    this.ModelListApplication.SearchFromDate=date;
  }

  SearchToDateChanged( date)
  {
    this.DisableSave = true;
    this.PendingMsg = false;
    this.ApprovedMsg = false;
    this.RejectedMsg = false;
    this.LeavePending=[];
    this.LeaveApproved=[];
    this.LeaveRejected=[];

    this.ModelListApplication.SearchToDate=date;
  }

  StatusChanged()
  {
    this.DisableSave = true;
    this.PendingMsg = false;
    this.ApprovedMsg = false;
    this.RejectedMsg = false;
    this.LeavePending=[];
    this.LeaveApproved=[];
    this.LeaveRejected=[];
  }

  submitClick()
  {
    this.DisableSave = false;
    this.validdate = false;
    this.validTodate=false;
    this.PendingMsg = false;
    this.ApprovedMsg = false;
    this.RejectedMsg = false;
    this.LeavePending=[];
    this.LeaveApproved=[];
    this.LeaveRejected=[];
    if(this.ModelListApplication.SearchFromDate!=null && this.ModelListApplication.SearchToDate!=null && this.ModelListApplication.Status > 0)
    {
      if(this.ModelListApplication.SearchToDate >= this.ModelListApplication.SearchFromDate)
      {
        if(this.ModelListApplication.Status == 1)
        {
          this.GetEmployeeLeavePendingDetails(this.ModelListApplication.SearchFromDate, this.ModelListApplication.SearchToDate);
        }
        else if(this.ModelListApplication.Status == 2)
        {
          this.GetEmployeeLeaveApprovedDetails(this.ModelListApplication.SearchFromDate, this.ModelListApplication.SearchToDate);
        }
        else
        {
          this.GetEmployeeLeaveRejectedDetails(this.ModelListApplication.SearchFromDate, this.ModelListApplication.SearchToDate);
        }
      }
      else
      {
        this.validTodate = true;
      }
    }
  }
}
