export class HRPoliciesFormModel 
{
    public RefId: number;
    public PolicyName :string;
    public FromDate: string;
    public ToDate: string;
    public FileName: string;
    public FileExtension: string;
    public Filesize : string;
    public File: string;
    public FileType : string;
}