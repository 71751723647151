import {INgxMyDpOptions, IMyDateModel} from 'ngx-mydatepicker';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { EmployeeMasterService }  from '../../../shared/HR_ADMIN/Employee/EmployeeMaster.Service';
import { EmployeeDetail } from '../../../shared/HR_ADMIN/Employee/EmployeeDetail.Model';
import { EmployeeExprienceModel} from '../../../shared/HR_ADMIN/Employee/EmployeeExprienceModel';
import { NotificationsService} from '../../../shared/notifications.service';

@Component({
  selector: 'app-employee-exprience',
  templateUrl: './employee-exprience.component.html',
  styleUrls: ['./employee-exprience.component.css']
})
export class EmployeeExprienceComponent implements OnInit
{
  AddressRefID:number;
  SuccessStatus:boolean=false;
  SuccessMessage: string ="";
  Tabindex:number=0;
  ExpRefId:number =0;
  Formdate :string;
  Todate:string;
  DisableSave : boolean=false;
  myOptions: INgxMyDpOptions = 
  {
    // other options...
    dateFormat: 'dd.mm.yyyy',
  };
  // Initialized to specific date (09.10.2018)
  model: any = { date: { year: 2018, month: 10, day: 9 } };

  constructor(public EmployeeMasterService: EmployeeMasterService, private rout: Router, private notificationsService: NotificationsService) { }

  // optional date changed callback
  onDateChangedFrom(event: IMyDateModel,i: number): void
  {
    const monthNames = [
      'January', 'February', 'March',
      'April', 'May', 'June', 'July',
      'August', 'September', 'October',
      'November', 'December'
      ];
    var m= event.jsdate.getMonth();
    m=m+1;
    var d= event.jsdate.getDate();
    var y= event.jsdate.getFullYear();
    var fulldate="";
    if(m>=10)
    {
      if(d < 10)
      {
        fulldate= m + '/' + '0'+ d + '/' + y;
      }
      else
      {
        fulldate= m + '/' + d + '/' + y; 
      }
    }
    else
    {
      if(d < 10)
      {
        fulldate= '0' + m + '/' + '0'+ d + '/' + y;
      }
      else
      {
        fulldate= '0' + m + '/' + d + '/' + y; 
      } 
    }
    this.EmployeeMasterService.SharedEmployees.EmployeeExprienceModel[i].ExperinceFromDatePass=fulldate;
    //event.formatted = event.jsdate.getDate() + ' ' +     monthNames[event.jsdate.getMonth()] + ', ' + event.jsdate.getFullYear();
  }

  onDateChangedTo(event: IMyDateModel,i: number): void
  {
    const monthNames = [
      'January', 'February', 'March',
      'April', 'May', 'June', 'July',
      'August', 'September', 'October',
      'November', 'December'
      ];
    var m= event.jsdate.getMonth();
    m=m+1;
    var d= event.jsdate.getDate();
    var y= event.jsdate.getFullYear();
    var fulldate="";
    if(m>=10)
    {
      if(d < 10)
      {
        fulldate= m + '/' + '0'+ d + '/' + y;
      }
      else
      {
        fulldate= m + '/' + d + '/' + y; 
      }
    }
    else
    {
      if(d < 10)
      {
        fulldate= '0' + m + '/' + '0'+ d + '/' + y;
      }
      else
      {
        fulldate= '0' + m + '/' + d + '/' + y; 
      } 
    }
    this.EmployeeMasterService.SharedEmployees.EmployeeExprienceModel[i].ExperinceToDatePass=fulldate;
    //event.formatted = event.jsdate.getDate() + ' ' +     monthNames[event.jsdate.getMonth()] + ', ' + event.jsdate.getFullYear();
  }
  
  ngOnInit() 
  {
    if(this.EmployeeMasterService.SharedEmployees.EmployeeExprienceModel==null)
    {
      let EmployeeExprienceList : EmployeeExprienceModel[]=[];
      for( var i=0; i<3; i++ )
      {
        let tempexprience = new EmployeeExprienceModel();          
        EmployeeExprienceList.push(tempexprience);         
      }
      this.EmployeeMasterService.SharedEmployees.EmployeeExprienceModel=EmployeeExprienceList;
    }
    if(this.EmployeeMasterService.SharedEmployees.EmployeeId > 0)
    {
      if(this.EmployeeMasterService.SharedEmployees.EmployeeExprienceModel.length==0)
      {
        let EmployeeExprienceList : EmployeeExprienceModel[]=[];
        for( var i=0; i<3; i++ )
        {
          let tempexprience = new EmployeeExprienceModel();          
          EmployeeExprienceList.push(tempexprience);         
        }
        this.EmployeeMasterService.SharedEmployees.EmployeeExprienceModel=EmployeeExprienceList;
      }
      else
      {
        this.changetdateformat();
      }
    }
    if( this.EmployeeMasterService.SaveOrUpdate==1)
    {
      localStorage.setItem('SuccessfullMessage', "Save Successfully");
      this.EmployeeMasterService.SaveOrUpdate=0;
    }
    if( this.EmployeeMasterService.SaveOrUpdate==2)
    {
      localStorage.setItem('SuccessfullMessage', "Update Successfully");
      this.EmployeeMasterService.SaveOrUpdate=0;
    }
    if(localStorage.getItem('SuccessfullMessage'))
    {
      this.SuccessStatus=true;
      this.SuccessMessage =localStorage.getItem('SuccessfullMessage')
      localStorage.removeItem('SuccessfullMessage');
    } 
    this.DisableSave=true;
  }

  AddnweRow()
  {
    let tempeducation = new EmployeeExprienceModel();          
    this.EmployeeMasterService.SharedEmployees.EmployeeExprienceModel.push(tempeducation);     
  }  

  changetdateformat()
  {
    let objemp = new EmployeeDetail();
    this.EmployeeMasterService.SharedEmployees.EmployeeExprienceModel.forEach(temp=>
    {
      temp.ExperinceFromDate=objemp.ReturnFormatdate(temp.ExperinceFromDatePass);
      temp.ExperinceToDate=objemp.ReturnFormatdate(temp.ExperinceToDatePass);
    })
  }

  SaveEmpolyeeExpriences() :void
  {
    this.DisableSave=false;
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;    
    var j=0;
    var l=this.EmployeeMasterService.SharedEmployees.EmployeeExprienceModel.length;
    this.EmployeeMasterService.SharedEmployees.EmployeeExprienceModel.forEach(item =>
    {
      j=j+1;
      item.EmployeeId= this.EmployeeMasterService.SharedEmployees.EmployeeId;      
      //let toArray =  tempedumodel.FromDate.getDay();
      if(item.ExperinceFromDatePass !=null && item.ExperinceToDatePass != null)
      {
        if(item.ExperienceRefId==null)
        {
          item.ExperienceRefId=0;
        }
        if(item.ExperienceRefId> 0)
        {
          this.EmployeeMasterService.UpdateEmployeeExperienceDetails(t ,item).subscribe(res=>
          {
            this.ExpRefId=Number(res);        
            if(this.ExpRefId>0)
            {
              this.EmployeeMasterService.SaveOrUpdate=2;
              this.Tabindex=5         
              this.notificationsService.emit(this.Tabindex); 
              if(j==l)
              {
                this.rout.navigateByUrl('/Employee/EmployeeDetails/FamilyDetails');   
              }
            }
          });                  
        }                             
        else
        {
          this.EmployeeMasterService.AddEmployeeExperienceDetails(t ,item).subscribe(res=>
          {        
            this.ExpRefId=Number(res);           
            if(this.ExpRefId>0)
            {
              this.EmployeeMasterService.SaveOrUpdate=1;          
              item.ExperienceRefId=Number(this.ExpRefId); 
              this.Tabindex=5         
              this.notificationsService.emit(this.Tabindex);
              if(j==l)
              {
                this.rout.navigateByUrl('/Employee/EmployeeDetails/FamilyDetails');   
              }    
            }
          }); 
        }
      }
    });    
  }

  RefreshPage()
  {
    this.rout.navigateByUrl('/Employee/EmployeeOffer', {skipLocationChange: true}).then(()=>this.rout.navigate(["/Employee/EmployeeDetails"]));
  }
}