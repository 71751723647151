export class OfferStandardSalary
{
    public  OfferSalaryId : number;
    public  OfferId :number;
    public  SalaryComponentId : number;
    public  SalaryComponentName : string;
    public  SalaryComponentCode : string;
    public  Amount :number;
    public  IsBenefit  :boolean;
    public  IsLongTermBenefit : boolean;
    public  Percentage :number;        
    public  IsBasic :boolean;
    public  IsCTC :boolean;
    public  IsActive : boolean; 
    public  JoiningDate : Date; 
}