import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';  
import { Response } from '@angular/http';  
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import 'rxjs/add/operator/toPromise';
import { GlobalVariable  } from '../../../app.global';
import { SalaryComponent } from './SalaryComponentModel';
import { SalaryMapping } from './SalaryMappingModel';
import { SalaryRule } from './SalaryRuleModel';
import { MonthlyAttendanceModel } from './MonthlyAttendanceModel';
import { EmployeeSalaryComponentAttendence} from './EmployeeSalaryComponentAttendence';
import { Form12BBModel } from './Form12BBModel.model';

@Injectable()
export class SalaryMasterService 
{
  constructor(private http: HttpClient, private spinnerService: Ng4LoadingSpinnerService) 
  { }

  //Start Salary Component Master
  GetSalaryComponentMaster(token)
  {
    const authHeader = new HttpHeaders();     
    authHeader.append('Content-Type', 'application/json');
    authHeader.append('Authorization', token);
    return new Promise((resolve, reject) =>
    {       setTimeout(()=>{

      this.http.get(GlobalVariable.BASE_API_URL +'/api/SalaryComponentMaster/GetSalaryComponentMaster',{headers: authHeader})
      .subscribe(data => 
      {
        resolve(data)
      },
      error => 
      {
        reject(error);
      },);
    },3000);

    });
  }

  GetSalaryComponentMasterByid(token, id:number ) 
  {
    this.spinnerService.show();

    const authHeader = new HttpHeaders();     
    authHeader.append('Content-Type', 'application/json');
    authHeader.append('Authorization', token);
    return new Promise((resolve, reject) => 
    {
      setTimeout(()=>{
      this.http.get(GlobalVariable.BASE_API_URL +'/api/SalaryComponentMaster/GetSalaryComponentMasterById?ComponentId='+id,{headers: authHeader})
      .subscribe(data => 
      {
        this.spinnerService.hide();
        resolve(data)
      },
      error => 
      {
        this.spinnerService.hide();
        reject(error);
      },);
    },3000);
    });
  }

  AddSalaryComponentMaster( token ,Salary :SalaryComponent ) 
  {
    const authHeader = new HttpHeaders();         
    authHeader.append('Content-Type', 'application/json');
    authHeader.append('Authorization', token);
    authHeader.append('Accept', 'application/json');
     return this.http.post(GlobalVariable.BASE_API_URL +'/api/SalaryComponentMaster/Add', Salary ,{headers: authHeader} )
    .map(this.extractData)
    .catch(this.handleErrorObservable);
  }

  DeleteSalaryComponentMaster( token ,Componentid : number) 
  {
    const authHeader = new HttpHeaders();         
    authHeader.append('Content-Type', 'application/json');
    authHeader.append('Authorization', token);
    authHeader.append('Accept', 'application/json');
    return this.http.put(GlobalVariable.BASE_API_URL +'/api/SalaryComponentMaster/Delete', Componentid ,{headers: authHeader} )
    .map(this.extractData)
    .catch(this.handleErrorObservable);
  }

  UpdateSalaryComponentMaster( token ,Salary :SalaryComponent ) 
  {
    const authHeader = new HttpHeaders();         
    authHeader.append('Content-Type', 'application/json');
    authHeader.append('Authorization', token);
    authHeader.append('Accept', 'application/json');
    return this.http.put(GlobalVariable.BASE_API_URL +'/api/SalaryComponentMaster/Update', Salary ,{headers: authHeader} )
    .map(this.extractData)
    .catch(this.handleErrorObservable);
  }

  CheckSalaryComponentcode(token, Code:string ) 
  {
    this.spinnerService.show();

    const authHeader = new HttpHeaders();     
    authHeader.append('Content-Type', 'application/json');
    authHeader.append('Authorization', token);
    return new Promise((resolve, reject) => 
    {
      setTimeout(()=>{
      this.http.get(GlobalVariable.BASE_API_URL +'/api/SalaryComponentMaster/CheckSalaryComponentMasterByCode?ComponentCode='+Code,{headers: authHeader})
      .subscribe(data => 
      {
        this.spinnerService.hide();
        resolve(data)
      },
      error => 
      {
        this.spinnerService.hide();
        reject(error);
      },);
    },3000);
    });
  }
  
  CheckSalaryComponentName(token, Name:string ) 
  {
    this.spinnerService.show();

    const authHeader = new HttpHeaders();     
    authHeader.append('Content-Type', 'application/json');
    authHeader.append('Authorization', token);
    return new Promise((resolve, reject) => 
    {
      setTimeout(()=>{
      this.http.get(GlobalVariable.BASE_API_URL +'/api/SalaryComponentMaster/CheckSalaryComponentMasterByName?ComponentName='+Name,{headers: authHeader})
      .subscribe(data => 
      {
        this.spinnerService.hide();
        resolve(data)
      },
      error => 
      {
        this.spinnerService.hide();
        reject(error);
      },);
    },3000);
    });
  }
  //End Salary Component Master
  //Start Salary Mapping
  GetSalaryMapping(token)
  {
    const authHeader = new HttpHeaders();     
    authHeader.append('Content-Type', 'application/json');
    authHeader.append('Authorization', token);
    return new Promise((resolve, reject) =>
    {       
      setTimeout(()=>
      {
        this.http.get(GlobalVariable.BASE_API_URL +'/api/SalaryMapping/GetSalaryMapping',{headers: authHeader})
        .subscribe(data => 
        {
          resolve(data)
        },
        error => 
        {
          reject(error);
        },);
      },3000);
    });
  }

  GetSalaryMappingById(token, id:number ) 
  {
    this.spinnerService.show();

    const authHeader = new HttpHeaders();     
    authHeader.append('Content-Type', 'application/json');
    authHeader.append('Authorization', token);
    return new Promise((resolve, reject) => 
    {
      setTimeout(()=>{
      this.http.get(GlobalVariable.BASE_API_URL +'/api/SalaryMapping/GetSalaryMappingById?Refid='+id,{headers: authHeader})
      .subscribe(data => 
      {
        this.spinnerService.hide();
        resolve(data)
      },
      error => 
      {
        this.spinnerService.hide();
        reject(error);
      },);
    },3000);
    });
  }

  AddSalaryMapping( token ,Salary :SalaryMapping ) 
  {
    const authHeader = new HttpHeaders();         
    authHeader.append('Content-Type', 'application/json');
    authHeader.append('Authorization', token);
    authHeader.append('Accept', 'application/json');
     return this.http.post(GlobalVariable.BASE_API_URL +'/api/SalaryMapping/Add', Salary ,{headers: authHeader} )
    .map(this.extractData)
    .catch(this.handleErrorObservable);
  }

  DeleteSalaryMapping( token ,RefId : number) 
  {
    const authHeader = new HttpHeaders();         
    authHeader.append('Content-Type', 'application/json');
    authHeader.append('Authorization', token);
    authHeader.append('Accept', 'application/json');
    return this.http.put(GlobalVariable.BASE_API_URL +'/api/SalaryMapping/Delete', RefId ,{headers: authHeader} )
    .map(this.extractData)
    .catch(this.handleErrorObservable);
  }

  UpdateSalaryMapping( token ,Salary :SalaryMapping ) 
  {
    const authHeader = new HttpHeaders();         
    authHeader.append('Content-Type', 'application/json');
    authHeader.append('Authorization', token);
    authHeader.append('Accept', 'application/json');
    return this.http.put(GlobalVariable.BASE_API_URL +'/api/SalaryMapping/Update', Salary ,{headers: authHeader} )
    .map(this.extractData)
    .catch(this.handleErrorObservable);
  }

  CheckSalaryMapping(token, CompanyId:number, GradeId:number ) 
  {
    this.spinnerService.show();

    const authHeader = new HttpHeaders();     
    authHeader.append('Content-Type', 'application/json');
    authHeader.append('Authorization', token);
    return new Promise((resolve, reject) => 
    {
      setTimeout(()=>{
      this.http.get(GlobalVariable.BASE_API_URL +'/api/SalaryMapping/CheckSalaryMapping?CompanyId='+ CompanyId +' &GradeId='+GradeId,{headers: authHeader})
      .subscribe(data => 
      {
        this.spinnerService.hide();
        resolve(data)
      },
      error => 
      {
        this.spinnerService.hide();
        reject(error);
      },);
    },3000);
    });
  }
  //End Salary Mapping
  //Start Salary Rule
  GetSalaryRule(token)
  {
    const authHeader = new HttpHeaders();     
    authHeader.append('Content-Type', 'application/json');
    authHeader.append('Authorization', token);
    return new Promise((resolve, reject) =>
    {       
      setTimeout(()=>
      {
        this.http.get(GlobalVariable.BASE_API_URL +'/api/SalaryRule/GetSalaryRule',{headers: authHeader})
        .subscribe(data => 
        {
          resolve(data)
        },
        error => 
        {
          reject(error);
        },);
      },3000);
    });
  }

  GetSalaryRuleById(token, CompanyId:number, GradeId:number ) 
  {
    this.spinnerService.show();

    const authHeader = new HttpHeaders();     
    authHeader.append('Content-Type', 'application/json');
    authHeader.append('Authorization', token);
    return new Promise((resolve, reject) => 
    {
      setTimeout(()=>{
      this.http.get(GlobalVariable.BASE_API_URL +'/api/SalaryRule/GetSalaryRuleById?CompanyId='+ CompanyId +' &GradeId='+ GradeId,{headers: authHeader})
      .subscribe(data => 
      {
        this.spinnerService.hide();
        resolve(data)
      },
      error => 
      {
        this.spinnerService.hide();
        reject(error);
      },);
    },3000);
    });
  }

  AddSalaryRule( token ,Salary :SalaryRule ) 
  {
    const authHeader = new HttpHeaders();         
    authHeader.append('Content-Type', 'application/json');
    authHeader.append('Authorization', token);
    authHeader.append('Accept', 'application/json');
      return this.http.post(GlobalVariable.BASE_API_URL +'/api/SalaryRule/Add', Salary ,{headers: authHeader} )
    .map(this.extractData)
    .catch(this.handleErrorObservable);
  }

  DeleteRule( token ,RefId : number) 
  {
    const authHeader = new HttpHeaders();         
    authHeader.append('Content-Type', 'application/json');
    authHeader.append('Authorization', token);
    authHeader.append('Accept', 'application/json');
    return this.http.put(GlobalVariable.BASE_API_URL +'/api/SalaryRule/Delete', RefId ,{headers: authHeader} )
    .map(this.extractData)
    .catch(this.handleErrorObservable);
  }

  UpdateSalaryRule( token ,Salary :SalaryRule ) 
  {
    const authHeader = new HttpHeaders();         
    authHeader.append('Content-Type', 'application/json');
    authHeader.append('Authorization', token);
    authHeader.append('Accept', 'application/json');
    return this.http.put(GlobalVariable.BASE_API_URL +'/api/SalaryRule/Update', Salary ,{headers: authHeader} )
    .map(this.extractData)
    .catch(this.handleErrorObservable);
  }

  UpdateSalarybulk( token ,Salary  : SalaryRule[] ) 
  {
    const authHeader = new HttpHeaders();         
    authHeader.append('Content-Type', 'application/json');
    authHeader.append('Authorization', token);
    authHeader.append('Accept', 'application/json');
    return this.http.put(GlobalVariable.BASE_API_URL +'/api/SalaryRule/Update', Salary ,{headers: authHeader} )
    .map(this.extractData)
    .catch(this.handleErrorObservable);
  }

  CheckSalaryRule(token, CompanyId:number, GradeId:number ) 
  {
    this.spinnerService.show();

    const authHeader = new HttpHeaders();     
    authHeader.append('Content-Type', 'application/json');
    authHeader.append('Authorization', token);
    return new Promise((resolve, reject) => 
    {
      setTimeout(()=>{
      this.http.get(GlobalVariable.BASE_API_URL +'/api/SalaryRule/CheckSalaryRule?CompanyId='+ CompanyId +' &GradeId='+GradeId,{headers: authHeader})
      .subscribe(data => 
      {
        this.spinnerService.hide();
        resolve(data)
      },
      error => 
      {
        this.spinnerService.hide();
        reject(error);
      },);
    },3000);
    });
  }
  //End Salary Rule

  //Begning of the Salary Offer
  GetOfferedSalary(token, CompanyId:Number, GradeId:Number, CTC : Number ) 
  {
    this.spinnerService.show();

    const authHeader = new HttpHeaders();     
    authHeader.append('Content-Type', 'application/json');
    authHeader.append('Authorization', token);
    return new Promise((resolve, reject) => 
    {
      setTimeout(()=>{
      this.http.get(GlobalVariable.BASE_API_URL +'/api/SalaryRule/GenerateOfferSalary?CompanyId='+ CompanyId +' &GradeId='+ GradeId+' &CTC='+ CTC,{headers: authHeader})
      .subscribe(data => 
      {
        this.spinnerService.hide();
        resolve(data)
      },
      error => 
      {
        this.spinnerService.hide();
        reject(error);
      },);
    },1000);
    });
  }

  GetPtaxSlabByBasicSalary(token, StateId:Number, BasicSalary:Number ,CurrentDate:Date) 
  {
    this.spinnerService.show();

    const authHeader = new HttpHeaders();     
    authHeader.append('Content-Type', 'application/json');
    authHeader.append('Authorization', token);
    return new Promise((resolve, reject) => 
    {
      setTimeout(()=>
      {
        this.http.get(GlobalVariable.BASE_API_URL +'/api/PtaxSlab/GetPtaxSlabByBasicSalary?StateId='+ StateId +' &BasicSalary='+ BasicSalary+'&date='+CurrentDate,{headers: authHeader})
        .subscribe(data => 
        {
          this.spinnerService.hide();
          resolve(data)
        },
        error => 
        {
          this.spinnerService.hide();
          reject(error);
        },);
      },1000);
    });
  }

  GetPtaxSlabByBasicSalaryForOffer(token, StateId:Number, BasicSalary:Number) 
  {
    this.spinnerService.show();

    const authHeader = new HttpHeaders();     
    authHeader.append('Content-Type', 'application/json');
    authHeader.append('Authorization', token);
    return new Promise((resolve, reject) => 
    {
      setTimeout(()=>
      {
        this.http.get(GlobalVariable.BASE_API_URL +'/api/PtaxSlab/GetPtaxSlabByBasicSalaryForOffer?StateId='+ StateId +' &BasicSalary='+ BasicSalary,{headers: authHeader})
        .subscribe(data => 
        {
          this.spinnerService.hide();
          resolve(data)
        },
        error => 
        {
          this.spinnerService.hide();
          reject(error);
        },);
      },1000);
    });
  }

  GetEPFESISlabByDate(token, CurrentDate:Date ) 
  {
    this.spinnerService.show();
    const authHeader = new HttpHeaders();     
    authHeader.append('Content-Type', 'application/json');
    authHeader.append('Authorization', token);
    return new Promise((resolve, reject) => 
    {
      setTimeout(()=>{
      this.http.get(GlobalVariable.BASE_API_URL +'/api/EPFESISlab/GetEPFESISlabByDate?Date='+ CurrentDate ,{headers: authHeader})
      .subscribe(data => 
      {
        this.spinnerService.hide();
        resolve(data)
      },
      error => 
      {
        this.spinnerService.hide();
        reject(error);
      },);
    },1000);
    });
  }
    
  GetEPFESISlabforOffer(token) 
  {
    this.spinnerService.show();
    const authHeader = new HttpHeaders();     
    authHeader.append('Content-Type', 'application/json');
    authHeader.append('Authorization', token);
    return new Promise((resolve, reject) => 
    {
      setTimeout(()=>{
      this.http.get(GlobalVariable.BASE_API_URL +'/api/EPFESISlab/GetEPFESISlabforOffer',{headers: authHeader})
      .subscribe(data => 
      {
        this.spinnerService.hide();
        resolve(data)
      },
      error => 
      {
        this.spinnerService.hide();
        reject(error);
      },);
    },1000);
    });
  }

  GetOfferSalaryComponentDetailsByid(token, id:number,CompanyId:number,GradeId:number ) 
  {
    this.spinnerService.show();
    const authHeader = new HttpHeaders();     
    authHeader.append('Content-Type', 'application/json');
    authHeader.append('Authorization', token);
    return new Promise((resolve, reject) => 
    {
      setTimeout(()=>
      {
        this.http.get(GlobalVariable.BASE_API_URL +'/api/OfferLetterDetails/GetOfferSalarydetailsDetailsById?OfferId='+id + '&CompanyId='+CompanyId+'&GradeId='+GradeId,{headers: authHeader})
        .subscribe(data => 
        {
          this.spinnerService.hide();
          resolve(data)
        },
        error => 
        {
          this.spinnerService.hide();
          reject(error);
        },);
      },3000);
    });
  }
  //End of the salary Offer
  //Begin Monthly Attendance Details
  GetMonthlyAttendanceDetails(token, Month:number, FinYear:number, CompanyId : number ,BranchId : number,DepartmentIds: string) 
  {
    this.spinnerService.show();
    const authHeader = new HttpHeaders();     
    authHeader.append('Content-Type', 'application/json');
    authHeader.append('Authorization', token);
    return new Promise((resolve, reject) => 
    {
      setTimeout(()=>{
      this.http.get(GlobalVariable.BASE_API_URL +'/api/EmployeeAttendance/GetMonthlyAttendanceDetails?Month='+ Month +'&FinYear='+ FinYear +'&CompanyId='+ CompanyId +'&BranchId='+ BranchId +'&DepartmentIds='+DepartmentIds,{headers: authHeader})
      .subscribe(data => 
      {
        this.spinnerService.hide();
        resolve(data)
      },
      error => 
      {
        this.spinnerService.hide();
        reject(error);
      },);
    },1000);
    });
  }

  AddMonthlyAttendanceDetails( token ,Attendance :MonthlyAttendanceModel) 
  {
    const authHeader = new HttpHeaders();         
    authHeader.append('Content-Type', 'application/json');
    authHeader.append('Authorization', token);
    authHeader.append('Accept', 'application/json');
    return this.http.post(GlobalVariable.BASE_API_URL +'/api/EmployeeAttendance/AddMonthlyAttendance', Attendance ,{headers: authHeader} )
    .map(this.extractData)
    .catch(this.handleErrorObservable);
  }

  UpdateMonthlyAttendanceetails( token ,Attendance :MonthlyAttendanceModel) 
  {
    const authHeader = new HttpHeaders();         
    authHeader.append('Content-Type', 'application/json');
    authHeader.append('Authorization', token);
    authHeader.append('Accept', 'application/json');
    return this.http.put(GlobalVariable.BASE_API_URL +'/api/EmployeeAttendance/UpdateMonthlyAttendance', Attendance ,{headers: authHeader} )
    .map(this.extractData)
    .catch(this.handleErrorObservable);
  }
  //End Monthly Attendance Details
  //Begning Employee Salary
  GetEmployeesAttendForSalary(token, Finyear:number,month: number,comapnyid: number,branchid: number, departmentid: number ) 
  {
    const authHeader = new HttpHeaders();     
    authHeader.append('Content-Type', 'application/json');
    authHeader.append('Authorization', token);
    return new Promise((resolve, reject) => 
    {
      setTimeout(()=>
      {
        this.http.get(GlobalVariable.BASE_API_URL +'/api/SalaryPaySlip/GetEmpAttResult?Finyear='+Finyear+'&month='+ month+  '&companyid='+ comapnyid + ' &branchid='+branchid + ' &departmentid='+ departmentid , {headers: authHeader})
        .subscribe(data => 
        {
          this.spinnerService.hide();
          resolve(data)
        },
        error => 
        {
          this.spinnerService.hide();
          reject(error);
        },);
      },1000);
    });
  }

  GetEmployeesSalarycompDetails(token, Empid:number,month: number, Finyear: number ) 
  {
    const authHeader = new HttpHeaders();     
    authHeader.append('Content-Type', 'application/json');
    authHeader.append('Authorization', token);
    return new Promise((resolve, reject) => 
    {
      setTimeout(()=>
      {
        this.http.get(GlobalVariable.BASE_API_URL +'/api/SalaryPaySlip/GetEmpSalCompDetails?EmployeeId='+Empid+'&month='+month+  '&FinYear='+ Finyear  , {headers: authHeader})
        .subscribe(data => 
        {
          this.spinnerService.hide();
          resolve(data)
        },
        error => 
        {
          this.spinnerService.hide();
          reject(error);
        },);
      },1000);
    });
  }

  GetEmployeesSalaryPayslip(token, Empid:number,month: number, Finyear: number ) 
  {
    const authHeader = new HttpHeaders();     
    authHeader.append('Content-Type', 'application/json');
    authHeader.append('Authorization', token);
    return new Promise((resolve, reject) => 
    {
      setTimeout(()=>
      {
        this.http.get(GlobalVariable.BASE_API_URL +'/api/SalaryPaySlip/GetPayslipDetails?EmployeeId='+Empid+'&FinYear='+Finyear+'&month='+ month  , {headers: authHeader})
        .subscribe(data => 
        {
          this.spinnerService.hide();
          resolve(data)
        },
        error => 
        {
          this.spinnerService.hide();
          reject(error);
        },);
      },1000);
    });
  }

  GetEmployeesEmployeePayslip(token, month: number, Finyear: number ) 
  {
    const authHeader = new HttpHeaders();     
    authHeader.append('Content-Type', 'application/json');
    authHeader.append('Authorization', token);
    return new Promise((resolve, reject) => 
    {
      setTimeout(()=>
      {
        this.http.get(GlobalVariable.BASE_API_URL +'/api/SalaryPaySlip/GetEmployeePayslip?FinYear='+Finyear+'&month='+ month  , {headers: authHeader})
        .subscribe(data => 
        {
          this.spinnerService.hide();
          resolve(data)
        },
        error => 
        {
          this.spinnerService.hide();
          reject(error);
        },);
      },1000);
    });
  }

  GetEmployeesEmployeePayslipNew(token, month: number, Finyear: number ) 
  {
    const authHeader = new HttpHeaders();     
    authHeader.append('Content-Type', 'application/json');
    authHeader.append('Authorization', token);
    return new Promise((resolve, reject) => 
    {
      setTimeout(()=>
      {
        this.http.get(GlobalVariable.BASE_API_URL +'/api/SalaryPaySlip/GetEmployeePayslipNew?FinYear='+Finyear+'&month='+ month  , {headers: authHeader})
        .subscribe(data => 
        {
          this.spinnerService.hide();
          resolve(data)
        },
        error => 
        {
          this.spinnerService.hide();
          reject(error);
        },);
      },1000);
    });
  }

  GetEmployeesSalaryPayslipNew(token, Empid:number,month: number, Finyear: number ) 
  {
    const authHeader = new HttpHeaders();     
    authHeader.append('Content-Type', 'application/json');
    authHeader.append('Authorization', token);
    return new Promise((resolve, reject) => 
    {
      setTimeout(()=>
      {
        this.http.get(GlobalVariable.BASE_API_URL +'/api/SalaryPaySlip/GetPayslipDetailsNew?EmployeeId='+Empid+'&FinYear='+Finyear+'&month='+ month  , {headers: authHeader})
        .subscribe(data => 
        {
          this.spinnerService.hide();
          resolve(data)
        },
        error => 
        {
          this.spinnerService.hide();
          reject(error);
        },);
      },1000);
    });
  }

  GETEmployeeDDLForPayslip(token, month: number, Finyear: number ) 
  {
    const authHeader = new HttpHeaders();     
    authHeader.append('Content-Type', 'application/json');
    authHeader.append('Authorization', token);
    return new Promise((resolve, reject) => 
    {
      setTimeout(()=>
      {
        this.http.get(GlobalVariable.BASE_API_URL +'/api/SalaryPaySlip/GETEmployeeDDLForPayslip?FinYear='+Finyear+'&month='+ month  , {headers: authHeader})
        .subscribe(data => 
        {
          this.spinnerService.hide();
          resolve(data)
        },
        error => 
        {
          this.spinnerService.hide();
          reject(error);
        },);
      },1000);
    });
  }

  AddEmployeeSalaryDetails( token ,SalaryDetails :EmployeeSalaryComponentAttendence) 
  {
    const authHeader = new HttpHeaders();         
    authHeader.append('Content-Type', 'application/json');
    authHeader.append('Authorization', token);
    authHeader.append('Accept', 'application/json');
    return this.http.post(GlobalVariable.BASE_API_URL +'/api/SalaryPaySlip/AddSalaryDetails', SalaryDetails ,{headers: authHeader} )
    .map(this.extractData)
    .catch(this.handleErrorObservable);
  }
  // PF Report
  GetEPFReport(token, Year:number,MonthId: number,CompanyId: number,BranchId: number, DepartmentId: number ) 
  {
    const authHeader = new HttpHeaders();     
    authHeader.append('Content-Type', 'application/json');
    authHeader.append('Authorization', token);
    return new Promise((resolve, reject) => 
    {
      setTimeout(()=>
      {
        this.http.get(GlobalVariable.BASE_API_URL +'/api/SalaryPaySlip/GetEPFReport?Year='+ Year +'&MonthId='+ MonthId +  '&CompanyId='+ CompanyId + ' &BranchId='+ BranchId + ' &DepartmentId='+ DepartmentId , {headers: authHeader})
        .subscribe(data => 
        {
          this.spinnerService.hide();
          resolve(data)
        },
        error => 
        {
          this.spinnerService.hide();
          reject(error);
        },);
      },1000);
    });
  }

  //From12BB
  GetEmployeeDetailsForForm12BBByUserId(token)
  { 
    this.spinnerService.show();
    const authHeader = new HttpHeaders();     
    authHeader.append('Content-Type', 'application/json');
    authHeader.append('Authorization', token);
    return new Promise((resolve, reject) =>
    {
      setTimeout(()=>
      {
        this.http.get(GlobalVariable.BASE_API_URL +'/api/FORM12BB/GetEmployeeDetailsForForm12BBByUserId',{headers: authHeader})
        .subscribe(data => 
        {
          this.spinnerService.hide();
          resolve(data)
        },
        error => 
        {
          this.spinnerService.hide();
          reject(error);
        },);
      },3000);
    });
  }

  AddFORM12BB( token ,Form12BBDetails :Form12BBModel) 
  {
    const authHeader = new HttpHeaders();         
    authHeader.append('Content-Type', 'application/json');
    authHeader.append('Authorization', token);
    authHeader.append('Accept', 'application/json');
    return this.http.post(GlobalVariable.BASE_API_URL +'/api/FORM12BB/AddFORM12BB', Form12BBDetails ,{headers: authHeader} )
    .map(this.extractData)
    .catch(this.handleErrorObservable);
  }

  GetEmployeeFORM12BBByEmployeeId(token)
  { 
    this.spinnerService.show();
    const authHeader = new HttpHeaders();     
    authHeader.append('Content-Type', 'application/json');
    authHeader.append('Authorization', token);
    return new Promise((resolve, reject) =>
    {
      setTimeout(()=>
      {
        this.http.get(GlobalVariable.BASE_API_URL +'/api/FORM12BB/GetEmployeeFORM12BBByEmployeeId',{headers: authHeader})
        .subscribe(data => 
        {
          this.spinnerService.hide();
          resolve(data)
        },
        error => 
        {
          this.spinnerService.hide();
          reject(error);
        },);
      },3000);
    });
  }

  GetEmployeeForm12BBApprovalForHRApproval(token)
  { 
    this.spinnerService.show();
    const authHeader = new HttpHeaders();     
    authHeader.append('Content-Type', 'application/json');
    authHeader.append('Authorization', token);
    return new Promise((resolve, reject) =>
    {
      setTimeout(()=>
      {
        this.http.get(GlobalVariable.BASE_API_URL +'/api/FORM12BB/GetEmployeeForm12BBApprovalForHRApproval',{headers: authHeader})
        .subscribe(data => 
        {
          this.spinnerService.hide();
          resolve(data)
        },
        error => 
        {
          this.spinnerService.hide();
          reject(error);
        },);
      },3000);
    });
  }

  GetEmployeeFORM12BBForApprovalByRefId(token,RefId)
  { 
    this.spinnerService.show();
    const authHeader = new HttpHeaders();     
    authHeader.append('Content-Type', 'application/json');
    authHeader.append('Authorization', token);
    return new Promise((resolve, reject) =>
    {
      setTimeout(()=>
      {
        this.http.get(GlobalVariable.BASE_API_URL +'/api/FORM12BB/GetEmployeeFORM12BBForApprovalByRefId?RefId='+RefId,{headers: authHeader})
        .subscribe(data => 
        {
          this.spinnerService.hide();
          resolve(data)
        },
        error => 
        {
          this.spinnerService.hide();
          reject(error);
        },);
      },3000);
    });
  }

  GetEmployeeDetailsForForm12BBByEmployeeId(token,EmployeeId)
  { 
    this.spinnerService.show();
    const authHeader = new HttpHeaders();     
    authHeader.append('Content-Type', 'application/json');
    authHeader.append('Authorization', token);
    return new Promise((resolve, reject) =>
    {
      setTimeout(()=>
      {
        this.http.get(GlobalVariable.BASE_API_URL +'/api/FORM12BB/GetEmployeeDetailsForForm12BBByEmployeeId?EmployeeId='+EmployeeId,{headers: authHeader})
        .subscribe(data => 
        {
          this.spinnerService.hide();
          resolve(data)
        },
        error => 
        {
          this.spinnerService.hide();
          reject(error);
        },);
      },3000);
    });
  }

  HRApprovedFORM12BB( token ,Form12BBDetails :Form12BBModel) 
  {
    const authHeader = new HttpHeaders();         
    authHeader.append('Content-Type', 'application/json');
    authHeader.append('Authorization', token);
    authHeader.append('Accept', 'application/json');
    return this.http.post(GlobalVariable.BASE_API_URL +'/api/FORM12BB/ApprovedHRFORM12BB', Form12BBDetails ,{headers: authHeader} )
    .map(this.extractData)
    .catch(this.handleErrorObservable);
  }
  //end Employee salary
  //Resign Employee Payslip
  GETResignEmployeeDDLForPayslip(token, month: number, Finyear: number ) 
  {
    const authHeader = new HttpHeaders();     
    authHeader.append('Content-Type', 'application/json');
    authHeader.append('Authorization', token);
    return new Promise((resolve, reject) => 
    {
      setTimeout(()=>
      {
        this.http.get(GlobalVariable.BASE_API_URL +'/api/SalaryPaySlip/GETResignEmployeeDDLForPayslip?FinYear='+Finyear+'&month='+ month  , {headers: authHeader})
        .subscribe(data => 
        {
          this.spinnerService.hide();
          resolve(data)
        },
        error => 
        {
          this.spinnerService.hide();
          reject(error);
        },);
      },1000);
    });
  }
  //End
  private extractData(res: Response) 
  {
    let body = res;
    return body || {};
  }

  private handleErrorObservable (error: Response | any) 
  {
    console.error(error.message || error);
    return Observable.throw(error.message || error);
  }
}
