<!-- <perfect-scrollbar style="max-width: 1560px; max-height: 540px;" class="mukesh"> -->
  <div style=" width: 100%;" class="body-content-area tabcontent exact-modal" id="link1">
    
    <div style=" width: 100%;" class="row">
        <div class="col-lg-9">
            <div class="row">
                <div class="col-lg-1 low-padding text-center">
                    <!-- <img *ngIf="this.SelectedMenu.Image!=null" src={{this.SelectedMenu.Image}} class="body-icon" alt="Icon"/> -->
                </div>
                <div class="col-lg-10 clear-padding">
                    <span class="page-title">Approve/Reject Leave (Admin/HR)</span>
                    <small class="page-description"></small>
                </div>
            </div>
        </div>
    </div>

    <div style=" width: 100%;" *ngIf="!(this.CanViewState)">
      
      <div *ngIf="NoRecordMsg" style="color: #dc3545; text-align: center;font-size: 15px; font-weight: bold;">No Record Found.</div>

      <table id="tblApplication" *ngIf="(this.ListApplication.length > 0)" class="table table-striped table-bordered text-center table-new row-border hover">
        <thead class="thead-blue text-center">
          <tr style=" width: 100%;">
            <th style=" width: 8%;">Sl No.</th>
            <th style=" width: 8%;">Code</th>
            <th style=" width: 20%;" class="text-left">Name</th>
            <th style=" width: 9%;">Fromdate</th>
            <th style=" width: 9%;">Todate</th>
            <th style=" width: 8%;">No Of Days</th>
            <th style=" width: 20%;">Reason</th>
            <th style=" width: 8%;">View</th>
            <th style=" width: 10%;">
              <input type="checkbox" name="all" (change)="checkAll($event)"/>Select All
            </th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let appl of ListApplication, let i = index">
            <td scope="row">{{i+1}}</td>
            <td>{{appl.EmployeeCode}}</td>
            <td class="text-left">{{appl.EmployeeName}}</td>
            <td>{{appl.FromDate | date : "dd-MMM-yyyy"}}</td>
            <td>{{appl.ToDate | date : "dd-MMM-yyyy" }}</td>
            <td>{{appl.NoDaysLeave}}</td>
            <td class="text-left">{{appl.ReasonsLeave}}</td>
            <td><button *ngIf="this.SelectedMenu.CanView" type="button" (click)="viewAppliedLeave(appl.RefId,template)" class="table-view-button" type="button" ><i class="fas fa-eye"></i></button></td>
            <td><input type="checkbox" [checked]="appl.Selected" id ="Selected_{{i}}" name="Selected_{{i}}" (change)="SelectedChange(i,$event)"></td>
          </tr>
        </tbody>
      </table>
      <form #RemarksForm="ngForm" *ngIf="(this.ListApplication.length > 0)">
        <div class="row">
          <div class="col-lg-4">
            <div class="col-body-border-right">
              <div class="form-group row">
                <label  class="col-sm-4 col-form-label clear-padding-right">Remarks</label>
                <div class="col-sm-7 clear-left-padding">
                  <input type="text" [(ngModel)]="ModelListApplication.RemarksAll" tabindex="1" maxlength="500" id ="RemarksAll" name="RemarksAll" #RemarksAll="ngModel" required class="form-control form-control-custom form-control-custom-new" >
                </div>
              </div>
            </div> 
          </div>
  
          <div class="col-lg-4">
            <div class="col-body-border-right">
              <div class="form-group row">
                <label *ngIf="!(this.CanApproveState)" class="col-sm-6 col-form-label text-center clear-padding-right">
                  <button type="submit" (click)="LeaveApprovedAll(template)" class="btn btn-primary save-button" [disabled]="!(RemarksForm.valid && this.DisableSave==true)">Approved All</button>  
                </label>
                <div *ngIf="!(this.CanApproveState)" class="col-sm-6 col-form-label text-center">
                  <button type="submit" (click)="LeaveRejectedAll(template)" class="btn btn-danger cancle-button" [disabled]="!(RemarksForm.valid && this.DisableSave==true)">Rejected All</button>  
                </div>
              </div>
            </div> 
          </div>
  
          <div class="col-lg-4">
            <div class="form-group row">
              <div class="col-sm-6 col-form-label text-center">
                <button type="button" (click)="RefreshPage()" class="btn btn-danger cancle-button">Refresh Page</button>
              </div>
            </div>
          </div>
        </div>
      </form>

      <div style=" box-align: center; width: 1500px;" >
        <ng-template #template>
          <div class="modal-header" style="background: #2a9bd7; max-width: 1500px;"> 
            <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()" style="color:#fff;">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div  style="text-align: center; max-width: 1500px;">
            <div *ngIf="ApprovedMsg" style="color: #dc3545; text-align: center;font-size: 15px; font-weight: bold;">Application Approved Successfully</div>
            <div *ngIf="RejectedMsg" style="color: #dc3545; text-align: center;font-size: 15px; font-weight: bold;">Application Rejected Successfully</div>
            <div style=" width: 100%;" *ngIf="ShowPopup">
              <fieldset class="body-form-control">
                  <legend class="legend--">Leave Balance Details</legend>
                  <form #EmployeeLeaveBalanceDetails="ngForm">
                    <div lass="row" >
                      <table id="tblEmployeeLeaveBalanceDetails" class="table table-striped table-bordered text-left table-new row-border hover">
                        <thead class="thead-blue text-center">
                          <tr style=" width: 100%;">
                            <th style=" width: 8%;">Sl.No.</th>
                            <th style=" width: 12%;">Leave Type</th>
                            <th style=" width: 15%;">Entitlement</th>
                            <th style=" width: 15%;">Leave Taken</th>
                            <th style=" width: 15%;">Opening Balance</th>
                            <th style=" width: 20%;">Leave Applied or pending</th>
                            <th style=" width: 15%;">Available Balance</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr *ngFor="let EmpLeaveBal of EmployeeLeaveBalance, let i = index">
                            <td scope="row">{{i+1}}</td>
                            <td>{{EmpLeaveBal.LeaveTypeName}}</td>
                            <td style=" text-align: center;">{{EmpLeaveBal.NoOfLeave}}</td>
                            <td style=" text-align: center;">{{EmpLeaveBal.LeaveTaken}}</td>
                            <td style=" text-align: center;">{{EmpLeaveBal.BalanceLeave}}</td>
                            <td style=" text-align: center;">{{EmpLeaveBal.LeaveApply}}</td>
                            <td style=" text-align: center;">{{EmpLeaveBal.BalanceLeaveAfterApply}}</td>
                          </tr>
                        </tbody>
                      </table>
                  </div>
                  </form>
                </fieldset>
              
                <fieldset style=" text-align: left;" class="body-form-control">
                  <legend class="legend--">Leave Details</legend>
                  <form #ApprovedApplicationForm="ngForm">
                    <div class="row">
                      <div class="col-lg-4">
                        <div class="col-body-border-right">
                          <div class="form-group row">
                            <label  class="col-sm-4 col-form-label clear-padding-right">Leave Type</label>
                            <div class="col-sm-7 clear-left-padding">
                              :&nbsp;&nbsp;{{this.ModelListApplication.LeaveTypeName}}
                            </div>
                          </div>
                          <div class="form-group row">
                            <label  class="col-sm-4 col-form-label clear-padding-right">Date From</label>
                            <div class="col-sm-7 clear-left-padding">
                              :&nbsp;&nbsp;{{this.ModelListApplication.FromDate | date : "dd-MMM-yyyy"}}
                            </div>
                          </div>
                          <div class="form-group row">
                            <div class="col-sm-4 col-form-label clear-padding-right">
                              <input type="radio" disabled="disabled" [checked]="this.ModelListApplication.Fromvalue=='FirstHalf'" id ="FromFirstHalf" name="FromFirstHalf" >
                              FirstHalf &nbsp; &nbsp;
                            </div>
                            <div class="col-sm-7 clear-left-padding">
                              <input type="radio" disabled="disabled" [checked]="this.ModelListApplication.Fromvalue=='SecondHalf'" id ="FromSecondHalf" name="FromSecondHalf" >
                              SecondHalf
                            </div>
                          </div>
                          <div class="form-group row">
                            <label  class="col-sm-4 col-form-label clear-padding-right">Approver</label>
                            <div class="col-sm-7 clear-left-padding">
                              :&nbsp;&nbsp;{{this.ModelListApplication.RHCode}}-{{this.ModelListApplication.RHName}} 
                            </div>
                          </div>
                          <div class="form-group row">
                            <label class="col-sm-4 col-form-label clear-padding-right">ApproverMailId</label>
                            <div class="col-sm-7 clear-left-padding">
                              :&nbsp;&nbsp;{{this.ModelListApplication.RHEmailId}} 
                            </div>
                          </div>
                          <!-- <div class="form-group row">
                            <label class="col-sm-4 col-form-label clear-padding-right">Remarks</label>
                            <div class="col-sm-7 clear-left-padding">
                              :&nbsp;&nbsp;{{this.ModelListApplication.RemarksLeave}} 
                            </div>
                          </div> -->
                        </div> 
                      </div>
              
                      <div class="col-lg-4">
                        <div class="col-body-border-right">
                          <div class="form-group row">
                            <label  class="col-sm-4 col-form-label clear-padding-right">Code</label>
                            <div class="col-sm-7 clear-left-padding">
                              :&nbsp;&nbsp;{{this.ModelListApplication.EmployeeCode}}
                            </div>
                          </div>
                          <div class="form-group row">
                            <label  class="col-sm-4 col-form-label clear-padding-right">Date To</label>
                            <div class="col-sm-7 clear-left-padding">
                              :&nbsp;&nbsp;{{this.ModelListApplication.ToDate | date : "dd-MMM-yyyy"}} 
                            </div>
                          </div>
                          <div class="form-group row">
                            <div class="col-sm-4 col-form-label clear-padding-right">
                              <input type="radio" disabled="disabled" [checked]="this.ModelListApplication.Tovalue=='FirstHalf'" id ="ToFirstHalf" name="ToFirstHalf">
                              FirstHalf &nbsp; &nbsp;
                            </div>
                            <div class="col-sm-7 clear-left-padding">
                              <input type="radio" disabled="disabled" [checked]="this.ModelListApplication.Tovalue=='SecondHalf'" id ="ToSecondHalf" name="ToSecondHalf">
                              SecondHalf
                            </div>
                          </div>
                          <div class="form-group row">
                            <label  class="col-sm-4 col-form-label clear-padding-right">DOA</label>
                            <div class="col-sm-7 clear-left-padding">
                              :&nbsp;&nbsp;{{this.ModelListApplication.ApplicationDate | date : "dd-MMM-yyyy"}}
                            </div>
                          </div>
                          <div class="form-group row">
                            <label class="col-sm-4 col-form-label clear-padding-right">No Of Days</label>
                            <div class="col-sm-7 clear-left-padding">
                              :&nbsp;&nbsp;{{this.ModelListApplication.NoDaysLeave}}
                            </div>
                          </div>
                        </div> 
                      </div>
              
                      <div class="col-lg-4">
                        <div class="form-group row">
                          <label  class="col-sm-4 col-form-label clear-padding-right">Name</label>
                          <div class="col-sm-7 clear-left-padding">
                            :&nbsp;&nbsp;{{this.ModelListApplication.EmployeeName}}
                          </div>
                        </div>
                        <div class="form-group row">
                          <label  class="col-sm-4 col-form-label clear-padding-right">Reason</label>
                          <div class="col-sm-7 clear-left-padding">
                              :&nbsp;&nbsp;{{this.ModelListApplication.ReasonsLeave}}
                          </div>
                        </div>
                        <div class="form-group row">
                          <label  class="col-sm-4 col-form-label clear-padding-right">Contact Person</label>
                          <div class="col-sm-7 clear-left-padding">
                              :&nbsp;&nbsp;{{this.ModelListApplication.PersonToContact}}
                          </div>
                        </div>  
                        <div class="form-group row">
                          <label class="col-sm-4 col-form-label clear-padding-right">Alternate No</label>
                          <div class="col-sm-7 clear-left-padding">
                            :&nbsp;&nbsp;{{this.ModelListApplication.ContactNoDuringLeave}}
                          </div>
                        </div>
                        <div class="form-group row">
                          <label  class="col-sm-4 col-form-label clear-padding-right">Remarks</label>
                          <div class="col-sm-7 clear-left-padding">
                            <input type="text" [(ngModel)]="ModelListApplication.ApprovedRejectedRemarks" tabindex="1" maxlength="500" id ="ApprovedRejectedRemarks" name="ApprovedRejectedRemarks" #ApprovedRejectedRemarks="ngModel" required class="form-control form-control-custom form-control-custom-new" >
                          </div>
                          <div *ngIf="ApprovedRejectedRemarks.invalid && (ApprovedRejectedRemarks.dirty || ApprovedRejectedRemarks.touched)" class="alert alert-danger">
                            <div *ngIf="ApprovedRejectedRemarks.errors.required">Remarks is required.</div>
                            <div *ngIf="ApprovedRejectedRemarks.errors.maxlength">Remarks is not more than 500 characters</div>
                          </div> 
                        </div>
                        <div class="form-group row">
                          <label *ngIf="!(this.CanApproveState)" class="col-sm-6 col-form-label text-center clear-padding-right">
                            <button type="submit" tabindex="2" (click)="LeaveApproved(template)" class="btn btn-primary save-button" [disabled]="!(ApprovedApplicationForm.valid && this.DisableSave==true)">Approved</button>  
                          </label>
                          <div *ngIf="!(this.CanApproveState)" class="col-sm-6 col-form-label text-center">
                            <button type="submit" tabindex="3" (click)="LeaveRejected(template)" class="btn btn-danger cancle-button" [disabled]="!(ApprovedApplicationForm.valid && this.DisableSave==true)">Rejected</button>  
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
              </fieldset>
            </div>
  
            <button type="button" aria-label="Close" (click)="modalRef.hide()" style="background: #2a9bd7; outline: 0px none; border: 0px none; color: #fff; padding: 5px 10px; border-radius: 3px;">
              <span>Close</span>
            </button>             
          </div>
        </ng-template>
      </div>
    </div>
  </div>
<!-- </perfect-scrollbar> -->