import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Http, Headers, RequestOptions, Response } from '@angular/http';
import { Observable } from "rxjs/Observable";
import { Ng4LoadingSpinnerService } from "ng4-loading-spinner";
import { GlobalVariable } from "../../../app.global";
import { Model_Forex_CRM_CompanyType } from "./Model_Forex_CRM_CompanyType";
import { Model_Forex_CRM_OrganisationType } from "./Model_Forex_CRM_OrganisationType";
import { Model_ForexCRM_CorporateDetails } from "./Model_ForexCRM_CorporateDetails";
//import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/toPromise';
import { Model_Forex_CRM_Documents } from './Model_Forex_CRM_Documents';



 

@Injectable()

export class ForexCrmService {

    Forex_CRM_CorporateDetails_Model: Model_ForexCRM_CorporateDetails;


    constructor(private http: HttpClient, private spinnerService: Ng4LoadingSpinnerService) {
    }

    //    Populate Forex-CRM Company  Drop  Down Starts

    GetForexCompanyType(token) {
        this.spinnerService.show();
        const authHeader = new HttpHeaders();
        authHeader.append('Content-Type', 'application/json');
        authHeader.append('Authorization', token);
        return new Promise((resolve, reject) => {
            setTimeout(() => {

                this.http.get(GlobalVariable.BASE_API_URL + '/api/ForexCRMCompanyType/GetForexCRMCompanyType', { headers: authHeader })
                    .subscribe(data => {
                        this.spinnerService.hide();
                        resolve(data)
                    },
                        error => {
                            this.spinnerService.hide();
                            reject(error);
                        });
            }, 3000);
        });
    }

    //  Populate  Forex-CRM Company Drop  Down Ends


    //    Populate Forex-CRM Organisation  Drop  Down Starts

    GetForexOrganisationType(token) {
        this.spinnerService.show();
        const authHeader = new HttpHeaders();
        authHeader.append('Content-Type', 'application/json');
        authHeader.append('Authorization', token);
        return new Promise((resolve, reject) => {
            setTimeout(() => {

                this.http.get(GlobalVariable.BASE_API_URL + '/api/ForexCRMOrganisationType/GetForexCRMOrganisationType', { headers: authHeader })
                    .subscribe(data => {
                        this.spinnerService.hide();
                        resolve(data)
                    },
                        error => {
                            this.spinnerService.hide();
                            reject(error);
                        });
            }, 3000);
        });
    }

    //  Populate  Forex-CRM Organisation Drop  Down Ends


    // ADD-Forex-CRM- Corporate Details
    AddForexCRMCorporateDetails(token, Forex_CRM_CorporateDetails_Model: Model_ForexCRM_CorporateDetails) {

        const authHeader = new HttpHeaders();
        authHeader.append('Content-Type', 'application/json');
        authHeader.append('Authorization', token);
        authHeader.append('Accept', 'application/json');
        return this.http.post(GlobalVariable.BASE_API_URL + '/api/ForexCRMCorporateDetails/AddForexCRMCorporateDetails', Forex_CRM_CorporateDetails_Model, { headers: authHeader })
            .map(this.extractData)
            .catch(this.handleErrorObservable);
    }


    // Searchable Drop Down For Location


    GetForexCRM_Location(token) {

        this.spinnerService.show();
        const authHeader = new HttpHeaders();
        authHeader.append('Content-Type', 'application/json');
        authHeader.append('Authorization', token);
        return new Promise((resolve, reject) => {
            setTimeout(() => {
                this.http.get(GlobalVariable.BASE_API_URL + '/api/CRMLocationServed/GetCRMLocationServed', { headers: authHeader })
                    .subscribe(data => {

                        this.spinnerService.hide();

                        resolve(data)
                    },
                        error => {
                            this.spinnerService.hide();
                            reject(error);
                        });
            }, 3000);
        });
    }
    // End Searchable Drop Down For Location


    //For The Grid Statrs

    Get_ForexCRM_Grid(token) {

        this.spinnerService.show();
        const authHeader = new HttpHeaders();
        authHeader.append('Content-Type', 'application/json');
        authHeader.append('Authorization', token);
        return new Promise((resolve, reject) => {
            setTimeout(() => {
                this.http.get(GlobalVariable.BASE_API_URL + '/api/ForexCrmGrid/GetForexCrmGrid', { headers: authHeader }).subscribe(data => {
                    this.spinnerService.hide();
                    resolve(data)
                },
                    error => {
                        this.spinnerService.hide();
                        reject(error);
                    });
            }, 3000);
        });
    }

    // Grid Ends




    // Update FOREX CRM Corporate Details 

    UpdateCrmForex(token, Forex_CRM_CorporateDetails_Model: Model_ForexCRM_CorporateDetails) {


        const authHeader = new HttpHeaders();
        authHeader.append('Content-Type', 'application/json');
        authHeader.append('Authorization', token);
        authHeader.append('Accept', 'application/json');

        return this.http.post(GlobalVariable.BASE_API_URL + '/api/ForexCRMCorporateDetails/UpdateForexCRMCorporateDetails',
            Forex_CRM_CorporateDetails_Model, { headers: authHeader })
            .map(this.extractData)
            .catch(this.handleErrorObservable);
    }

    // Update FOREX CRM Corporate Details



    GetForex_CRM_Details_By_id(token, id: number) {

        this.spinnerService.show();
        const authHeader = new HttpHeaders();
        authHeader.append('Content-Type', 'application/json');
        authHeader.append('Authorization', token);

        return new Promise((resolve, reject) => {
            setTimeout(() => {
                // alert(id);

                this.http.get(GlobalVariable.BASE_API_URL + '/api/ForexCRMUpdateByID/GetForexCRMUpdateByID?ForexCrmId=' + id, { headers: authHeader })
                    .subscribe(data => {
                        //  alert(3);
                        this.spinnerService.hide();
                        resolve(data)
                    },
                        error => {
                            this.spinnerService.hide();
                            reject(error);
                        });
            }, 3000);
        });
    }


    //  Delete  a   Record  Starts

    Delete_ForexCRM( token ,ID : number) 
    {
        const authHeader = new HttpHeaders();         
        authHeader.append('Content-Type', 'application/json');
        authHeader.append('Authorization', token);
        authHeader.append('Accept', 'application/json');
        return this.http.put(GlobalVariable.BASE_API_URL +'/api/ForexCrmDetails/DeleteForexCrmDetails', ID ,{headers: authHeader} )
        .map(this.extractData)
        .catch(this.handleErrorObservable);
    }    

    //  Delete  a  record   Ends   
    


    //Fetch Forex CRM To Get Document

    Get_ForexDocument(token, ForexCrmId) {
        this.spinnerService.show();
        const authHeader = new HttpHeaders();
        authHeader.append('Content-Type', 'application/json');
        authHeader.append('Authorization', token);
        return new Promise((resolve, reject) => {
            setTimeout(() => {
                // alert('14');
                this.http.get(GlobalVariable.BASE_API_URL + '/api/ForexCRM/GetForexCRMDocuments?ForexCrmId=' + ForexCrmId, { headers: authHeader }).subscribe(data => {
                    this.spinnerService.hide();
                    resolve(data)
                },
                    error => {
                        this.spinnerService.hide();
                        reject(error);
                    });
            }, 3000);
        });
    }
    //Fetch Forex CRM To Get Document



       //Begning Of  add Lead  CRM Documents

       AddForexCRMDocuments(token, Upoladdocuments: Model_Forex_CRM_Documents[]) {
        const authHeader = new HttpHeaders();
        authHeader.append('Content-Type', 'application/json');
        authHeader.append('Authorization', token);
        authHeader.append('Accept', 'application/json');
        return this.http.post(GlobalVariable.BASE_API_URL + '/api/ForexcCrm/AddForexcCrmDocument', Upoladdocuments, { headers: authHeader })
            .map(this.extractData)
            .catch(this.handleErrorObservable);
    }


    //Begning of the add Lead CRM Documents



     // Get Forex CRm Document

        public getDocument(token, docid: number) {
            let url = GlobalVariable.BASE_API_URL + '/api/ForexCRM/GetForexCRMDocument?DocumentId=' + docid;
            const authHeader = new HttpHeaders();
            authHeader.append('Content-Type', 'application/json');
            authHeader.append('Authorization', token);
            let headers = new HttpHeaders().append("Authorization", token)
            return this.http.get(url, { responseType: 'arraybuffer', headers: headers });
    
        }





    private extractData(res: Response) {
        let body = res;
        return body || {};
    }
    private handleErrorObservable(error: Response | any) {
        console.error(error.message || error);
        return Observable.throw(error.message || error);
    }

      





}