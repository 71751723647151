<div class="body-content-area tabcontent" id="link1">
    <div class="row">
        <div class="col-lg-9">
            <div class="row">
                <div class="col-lg-1 low-padding text-center">
                    <!-- <img *ngIf="this.SelectedMenu.Image!=null" src={{this.SelectedMenu.Image}} class="body-icon" alt="Icon"/> -->
                </div>
                <div class="col-lg-10 clear-padding">
                    <div>
                        <span class="page-title">Employee Offer Letter</span>
                        <small class="page-description"></small>
                    </div>
                    <div *ngIf="!(this.CanViewState)">
                        <button *ngIf="(this.IsButtonIsInViewState)" (click)="ADDClick()" type="button" class="btn btn-primary Add-button">ADD New</button> 
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div *ngIf="!(this.CanViewState)">
        
        <div *ngIf="NoRecordMsg" style="color: #dc3545; text-align: center;font-size: 15px; font-weight: bold;">No Record Found.</div>

        <table *ngIf="(this.IsButtonIsInViewState && this.Offers.length > 0)" id="tblOfferLetter" datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" class="table table-striped table-bordered text-center table-new row-border hover">
            <thead class="thead-blue text-center">
                <tr>
                    <th>Sl No.</th>
                    <th class="text-left">Name</th>
                    <th>Contact No</th>
                    <th class="text-left">Designation</th>
                    <th>Offer Date</th>
                    <th>Joining Date</th>
                    <th>Status</th>
                    <th>Action</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let Offer of Offers, let i = index">
                    <td scope="row">{{i+1}}</td>
                    <td class="text-left">{{Offer.FullName}}</td>
                    <td>{{Offer.ContactNumber}}</td>
                    <td class="text-left">{{Offer.DesignationName}}</td>
                    <td>{{Offer.OfferDate | date : "dd-MMM-yyyy"}}</td>
                    <td>{{Offer.joiningDate | date : "dd-MMM-yyyy"}}</td>
                    <td>{{Offer.Status}}</td>
                    <td>
                        <button *ngIf="this.SelectedMenu.CanEdit" type="button" (click)="GetOfferDetailsByid(Offer.OfferId)" class="table-edit-btn"><i class="fas fa-edit"></i> </button> 
                        <button hidden="hidden" *ngIf="this.SelectedMenu.CanDelete" type="button" (click)="DeleteOfferDetailsByid( Offer.OfferId )" class="table-remove-btn"><i class="fas fa-trash-alt"></i>  </button>
                        <button *ngIf="this.SelectedMenu.CanView" type="button" (click)="viewOfferDetailsByid( Offer.OfferId)" class="table-view-button" type="button" ><i class="fas fa-eye"></i></button>
                    </td>
                </tr>
            </tbody>
        </table>
        <app-dialog [(visible)]="this.SuccessStatus">
            <span class="successmsg">{{this.SuccessMessage}}</span>
        </app-dialog>

        <fieldset class="body-form-control" id="OfferFieldet" *ngIf="(this.IsFormViewInViewState)">
            <legend class="legend--">Employee Offer Letter Creation</legend>
            <form #OfferForm="ngForm" (ngSubmit)="saveOfferDetails()">
                <div class="row">
                    <div class="col-lg-4">
                        <div class="col-body-border-right">
                            <div class="form-group row">                        
                                <label class="col-sm-4 col-form-label clear-padding-right">EPF Applicable</label>
                                <div class="col-sm-7 clear-left-padding">
                                    <input type="checkbox" [(ngModel)]="OfferModel.EPFApplicable" tabindex="1" id ="EPFApplicable" name="EPFApplicable" #EPFApplicable="ngModel" (ngModelChange)="oncheckedEPFApplicableChange()" class="form-control form-control-custom form-control-custom-new">
                                </div>
                            </div>
                            <div class="form-group row">
                                <label  class="col-sm-4 col-form-label clear-padding-right">First Name</label>
                                <div class="col-sm-7 clear-left-padding">
                                    <input type="text" [(ngModel)]="OfferModel.FirstName" tabindex="4" maxlength="100" id ="FirstName"  name="FirstName" #FirstName="ngModel" required class="form-control form-control-custom form-control-custom-new" >
                                </div>
                                <div *ngIf="FirstName.invalid && (FirstName.dirty || FirstName.touched)" class="alert alert-danger">
                                    <div *ngIf="FirstName.errors.required">First Name is required.</div>
                                    <div *ngIf="FirstName.errors.maxlength">First Name is not more than 100 characters</div>
                                </div>
                            </div>
                            <div class="form-group row">
                            <label  class="col-sm-4 col-form-label clear-padding-right">Addres 1</label>
                            <div class="col-sm-7 clear-left-padding">
                                <input type="text" [(ngModel)]="OfferModel.Address1" tabindex="7" id ="Address1"  name="Address1" #Address1="ngModel" required maxlength="500" class="form-control form-control-custom form-control-custom-new" >
                            </div>
                            <div *ngIf="Address1.invalid && (Address1.dirty || Address1.touched)" class="alert alert-danger">
                                <div *ngIf="Address1.errors.required">Address1  is required.</div>
                                <div *ngIf="Address1.errors.maxlength">Address1 is not more than 500 characters</div>
                            </div>
                            </div>
                            <div class="form-group row">
                                <label  class="col-sm-4 col-form-label clear-padding-right">State</label>
                                <div class="col-sm-7 clear-left-padding">
                                    <select [(ngModel)]="OfferModel.StateId" tabindex="10" (ngModelChange)="onstatechange($event)"  id ="StateId" class="form-control" name="StateId" #StateId="ngModel" >
                                        <option [value]="0" [disabled]=true >Select State</option>
                                        <option *ngFor="let s of States" [value]="s.StateID">{{s.StateName}}</option>
                                    </select>
                                </div>
                                <div class="required-validation" *ngIf="OfferModel.StateId==0">State is required</div>
                            </div>
                            <div class="form-group row">
                            <label  class="col-sm-4 col-form-label clear-padding-right">Pin Code</label>
                            <div class="col-sm-7 clear-left-padding">
                                <input type="text" [(ngModel)]="OfferModel.PinCode" tabindex="13" id ="PostCode" maxlength="6" minlength="6"  name="PostCode" #PostCode="ngModel" pattern="[0-9]+" required class="form-control form-control-custom form-control-custom-new" >
                            </div>
                            <div *ngIf="PostCode.invalid && (PostCode.dirty || PostCode.touched)" class="alert alert-danger">
                                <div *ngIf="PostCode.errors.required">PostCode is required.</div>
                                <div *ngIf="PostCode.errors.maxlength">PostCode not more than 6 digits.</div>
                                <div *ngIf="PostCode.errors.minlength">PostCode not more than 6 digits.</div>
                                <div *ngIf="PostCode.errors?.pattern">PostCode should be valid</div>
                            </div>
                            </div>
                            <div class="form-group row">
                            <label  class="col-sm-4 col-form-label clear-padding-right">Company</label>
                            <div class="col-sm-7 clear-left-padding">
                                <select [(ngModel)]="OfferModel.CompanyId" tabindex="16" (ngModelChange)="onChangeCompany($event)" id ="CompanyId" class="form-control"  name="CompanyId" #CompanyId="ngModel" >
                                <option [value]="0" [disabled]=true >Select Company</option>
                                <option *ngFor="let c of Companies" [value]="c.CompId">{{c.CompName}}</option>
                                </select>
                            </div>
                            <div class="required-validation" *ngIf="OfferModel.CompanyId==0">Company is required</div>
                            </div>
                            <div class="form-group row">
                                <label  class="col-sm-4 col-form-label clear-padding-right">Decision Making</label>
                                <div class="col-sm-7 clear-left-padding">
                                    <select (ngModelChange)="onDecisionChange($event)" [(ngModel)]="OfferModel.DecisionId" tabindex="19" id ="DecisionId" class="form-control"  name="DecisionId" #DecisionId="ngModel" >
                                        <option [value]="0" [disabled]=true >Select Decision Making</option>
                                        <option *ngFor="let DM of DecisionMakings" [value]="DM.DecisionId">{{DM.DecisionName}}</option>
                                    </select>
                                </div>
                                <div class="required-validation" *ngIf="OfferModel.DecisionId==0">Decision Making is required</div>
                            </div>
                            <div class="form-group row">
                                <label  class="col-sm-4 col-form-label clear-padding-right">Designation</label>
                                <div class="col-sm-7 clear-left-padding">
                                    <select [(ngModel)]="OfferModel.DesignationId" tabindex="22" id ="DesignationId" class="form-control"  name="DesignationId" #DesignationId="ngModel" >
                                        <option [value]="0" [disabled]=true >Select Designation</option>
                                        <option *ngFor="let G of Designations" [value]="G.DesignationId">{{G.DesignationName}}</option>
                                    </select>
                                </div>
                                <div class="required-validation" *ngIf="OfferModel.DesignationId==0">Designation is required</div> 
                            </div>
                            <div class="form-group row">
                                <label  class="col-sm-4 col-form-label clear-padding-right" >CTC Salary(Rs)</label>
                                <div class="col-sm-7 clear-left-padding">
                                <input type="text" readonly="readonly" name="CTCAmount" [ngModel]="OfferModel.CTCAmount | number:'3.0-0'" #CTCAmount="ngModel" class="form-control form-control-custom form-control-custom-new"/>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    <div class="col-lg-4">
                        <div class="col-body-border-right">
                            <div class="form-group row">                        
                                <label class="col-sm-4 col-form-label clear-padding-right">ESI Applicable</label>
                                <div class="col-sm-7 clear-left-padding">
                                    <input type="checkbox" [(ngModel)]="OfferModel.ESIApplicable" tabindex="2" id ="ESIApplicable" name="ESIApplicable" #ESIApplicable="ngModel" (ngModelChange)="oncheckedESIApplicableChange()" class="form-control form-control-custom form-control-custom-new">
                                </div>
                            </div>
                            <div class="form-group row">
                                <label  class="col-sm-4 col-form-label clear-padding-right">Middle Name</label>
                                <div class="col-sm-7 clear-left-padding">
                                    <input type="text" [(ngModel)]="OfferModel.MiddleName" tabindex="5" maxlength="100" id ="MiddleName"  name="MiddleName" #MiddleName="ngModel" class="form-control form-control-custom form-control-custom-new" >
                                </div>
                            </div>
                            <div class="form-group row">
                                <label  class="col-sm-4 col-form-label clear-padding-right">Address 2</label>
                                <div class="col-sm-7 clear-left-padding">
                                    <input type="text" [(ngModel)]="OfferModel.Address2" tabindex="8" id ="Address2"  name="Address2" #Address2="ngModel" maxlength="500" class="form-control form-control-custom form-control-custom-new" >
                                </div>
                                <div *ngIf="Address2.invalid && (Address2.dirty || Address2.touched)" class="alert alert-danger">
                                    <div *ngIf="Address2.errors.required">Address2  is required.</div>
                                    <div *ngIf="Address2.errors.maxlength">Address2 is not more than 500 characters</div>
                                </div>
                            </div>
                            <div class="form-group row">
                                <label  class="col-sm-4 col-form-label clear-padding-right">District</label>
                                <div class="col-sm-7 clear-left-padding">
                                    <select [(ngModel)]="OfferModel.DistrictId" tabindex="11" (ngModelChange)="onDistrictchange($event)" id ="DistrictId" class="form-control" name="DistrictId" #DistrictId="ngModel" >
                                        <option [value]="0" [disabled]=true >Select District</option>
                                        <option *ngFor="let dist of Districts" [value]="dist.DistId">{{dist.DistName}}</option>
                                    </select>
                                </div>
                                <div class="required-validation" *ngIf="OfferModel.DistrictId==0">District is required</div>
                            </div>
                            <div class="form-group row">
                                <label  class="col-sm-4 col-form-label clear-padding-right">Mobile No</label>
                                <div class="col-sm-7 clear-left-padding">
                                    <input type="text" [(ngModel)]="OfferModel.ContactNumber" tabindex="14" maxlength="10" minlength="10" pattern="[0-9]+" id ="ContactNumber" name="ContactNumber" #ContactNumber="ngModel" required class="form-control form-control-custom form-control-custom-new" >
                                </div>
                                <div *ngIf="ContactNumber.invalid && (ContactNumber.dirty || ContactNumber.touched)" class="alert alert-danger">
                                    <div *ngIf="ContactNumber.errors.maxlength">Mobile No is not more than 10 digit.</div>
                                    <div *ngIf="ContactNumber.errors.minlength">Mobile No is not less than 10 digit.</div>
                                    <div *ngIf="ContactNumber.errors?.pattern">Mobile No should be valid.</div>
                                </div>
                            </div>
                            <div class="form-group row">
                            <label  class="col-sm-4 col-form-label clear-padding-right">Branch</label>
                            <div class="col-sm-7 clear-left-padding">
                                <select [(ngModel)]="OfferModel.BranchId" tabindex="17" (ngModelChange)="onchangeBranch($event)"  id ="BranchId" class="form-control" name="BranchId" #BranchId="ngModel" >
                                <option [value]="0" [disabled]=true >Select Branch</option>
                                <option *ngFor="let b of Branches" [value]="b.BranchId">{{b.BranchName}}</option>
                                </select>
                            </div>
                            <div class="required-validation" *ngIf="OfferModel.BranchId==0">Branch is required</div>  
                            </div>
                            <div class="form-group row">
                            <label  class="col-sm-4 col-form-label clear-padding-right">Level</label>
                            <div class="col-sm-7 clear-left-padding">
                                <select (ngModelChange)="onLevelChange($event)" [(ngModel)]="OfferModel.LevelId" tabindex="20" id ="LevelId" class="form-control"  name="LevelId" #LevelId="ngModel" >
                                    <option [value]="0" [disabled]=true >Select Level</option>
                                    <option *ngFor="let L of Levels" [value]="L.LevelId">{{L.LevelName}}</option>
                                </select>
                            </div>
                            <div class="required-validation" *ngIf="OfferModel.LevelId==0">Level is required</div>  
                            </div>
                            <div class="form-group row">
                                <label  class="col-sm-4 col-form-label clear-padding-right" >Joining Date</label>
                                <div class="col-sm-7 clear-left-padding">
                                    <input type="date" name="joiningDate" tabindex="23" [ngModel]="OfferModel.joiningDate | date:'yyyy-MM-dd'" (ngModelChange)="dateChanged($event)" #joiningDate="ngModel" required />
                                </div>
                                <div class="required-validation" *ngIf="(!validdate)">Select Valid Date</div>
                            </div>
                            <div class="form-group row">
                                <label  class="col-sm-4 col-form-label clear-padding-right" >Net Take Salary</label>
                                <div class="col-sm-7 clear-left-padding">
                                <input type="text" readonly="readonly" name="NetTakeHome" [ngModel]="OfferModel.NetTakeHome | number:'3.0-0'" #NetTakeHome="ngModel" class="form-control form-control-custom form-control-custom-new"/>
                                </div>
                            </div>
                            <div class="form-group row">
                                <div class="col-sm-7 clear-left-padding">
                                    <button type="button" class="btn btn-info" (click)="openModal(template)" [disabled]="!(this.OfferedSalary.length > 0)">Salary BreakUp</button>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-4">
                        <div class="form-group row">                        
                            <label class="col-sm-4 col-form-label clear-padding-right">PTAX Applicable</label>
                            <div class="col-sm-7 clear-left-padding">
                                <input type="checkbox" [(ngModel)]="OfferModel.PTAXApplicable" tabindex="3" id ="PTAXApplicable" name="PTAXApplicable" #PTAXApplicable="ngModel" (ngModelChange)="oncheckedPTAXApplicableChange()" class="form-control form-control-custom form-control-custom-new">
                            </div>
                        </div>
                        <div class="form-group row">
                            <label  class="col-sm-4 col-form-label clear-padding-right">Last Name</label>
                            <div class="col-sm-7 clear-left-padding">
                                <input type="text" [(ngModel)]="OfferModel.LastName" tabindex="6" maxlength="100" id ="LastName"  name="LastName" #LastName="ngModel" class="form-control form-control-custom form-control-custom-new" >
                            </div>
                        </div>
                        <div class="form-group row">
                            <label  class="col-sm-4 col-form-label clear-padding-right">Country</label>
                            <div class="col-sm-7 clear-left-padding">
                                <select [(ngModel)]="OfferModel.CountryId"  tabindex="9" (ngModelChange)="onChangeCountry($event)" id ="CountryId" class="form-control"  name="CountryId" #CountryId="ngModel" >
                                    <option [value]="0" [disabled]=true >Select Country</option>
                                    <option *ngFor="let c of Countries" [value]="c.CountryId">{{c.CountryName}}</option>
                                </select>
                            </div>
                            <div class="required-validation"  *ngIf="OfferModel.CountryId==0">Country is required</div>
                        </div>
                        <div class="form-group row">
                            <label  class="col-sm-4 col-form-label clear-padding-right">City</label>
                            <div class="col-sm-7 clear-left-padding">
                            <select  [(ngModel)]="OfferModel.CityId" tabindex="12" id ="CityId" class="form-control" name="CityId" #CityId="ngModel">
                                <option [value]="0" [disabled]=true >Select City</option>
                                <option *ngFor="let c of Cities" [value]="c.CityId">{{c.CityName}}</option>
                            </select>
                            </div>
                            <div class="required-validation" *ngIf="OfferModel.CityId==0">City is required</div>
                        </div>
                        <div class="form-group row">
                        <label  class="col-sm-4 col-form-label clear-padding-right">Email Id</label>
                        <div class="col-sm-7 clear-left-padding">
                            <input type="text" [(ngModel)]="OfferModel.EmailId" tabindex="15" required id ="EmailId"  name="EmailId"  #EmailId="ngModel" pattern="^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$" class="form-control form-control-custom form-control-custom-new" >
                        </div>
                        <div *ngIf="EmailId.invalid && (EmailId.dirty || EmailId.touched)" class="alert alert-danger">
                            <div *ngIf="EmailId.errors.required">Email is required.</div>
                            <div *ngIf="EmailId.errors?.pattern">Email should be valid.</div>
                        </div>
                        </div>
                        <div class="form-group row">
                        <label class="col-sm-4 col-form-label clear-padding-right">Department</label>
                        <div class="col-sm-7 clear-left-padding">
                            <select  [(ngModel)]="OfferModel.DepartmentId" tabindex="18" id ="DepartmentId" class="form-control" name="DepartmentId" #DepartmentId="ngModel">
                            <option [value]="0" [disabled]=true >Select Department</option>
                            <option *ngFor="let d of Departments" [value]="d.DepartmentId">{{d.DepartmentName}}</option>
                            </select>
                        </div>
                        <div class="required-validation" *ngIf="OfferModel.DepartmentId==0">Department is required</div>
                        </div>
                        
                        <div class="form-group row">
                            <label class="col-sm-4 col-form-label clear-padding-right">Grade</label>
                            <div class="col-sm-7 clear-left-padding">
                                <select [(ngModel)]="OfferModel.GradeId" tabindex="21" id ="GradeId" (ngModelChange)="onGradechange($event)" class="form-control"  name="GradeId" #GradeId="ngModel" >
                                    <option [value]="0" [disabled]=true >Select Grade</option>
                                    <option *ngFor="let G of Grades" [value]="G.GradeId">{{G.GradeName}}</option>
                                </select>
                            </div>
                            <div class="required-validation" *ngIf="OfferModel.GradeId==0">Grade is required</div>
                        </div>
                        <div class="form-group row">
                            <label  class="col-sm-4 col-form-label clear-padding-right">Gross Monthly</label>
                            <div class="col-sm-7 clear-left-padding">
                            <input type="text" [(ngModel)]="OfferModel.GrossSalary" (blur)="GetOfferdetails_Salary()" tabindex="24" maxlength="10" minlength="4" pattern="[0-9]+" id ="GrossSalary" name="GrossSalary" #GrossSalary="ngModel" required class="form-control form-control-custom form-control-custom-new">
                            </div>
                            <div *ngIf="GrossSalary.invalid && (GrossSalary.dirty || GrossSalary.touched)" class="alert alert-danger">
                                <div *ngIf="GrossSalary.errors.maxlength">GrossSalary is not more than 10 digit.</div>
                                <div *ngIf="GrossSalary.errors.minlength">GrossSalary is not less than 4 dogit.</div>
                                <div *ngIf="GrossSalary.errors?.pattern">GrossSalary should be valid.</div>
                            </div>
                        </div>
                        <div class="form-group row">
                            <label class="col-sm-4 col-form-label clear-padding-right">Pay Zone</label>
                            <div class="col-sm-7 clear-left-padding">
                                <select [(ngModel)]="OfferModel.RegionHRId" tabindex="22" id ="RegionHRId" class="form-control" name="RegionHRId" #RegionHRId="ngModel">
                                    <option [value]="0" [disabled]=true >Select Pay Zone</option>
                                    <option *ngFor="let PZ of RegionForHR" [value]="PZ.RefId">{{PZ.RegionName}}</option>
                                </select>
                            </div>
                            <div class="required-validation" *ngIf="OfferModel.RegionHRId==0">Pay Zone is required</div>
                        </div>
                        <div class="form-group row">
                            <label  class="col-sm-6 col-form-label text-center clear-padding-right" *ngIf="!(this.IsDataIsInViewState)">
                                <button type="submit" tabindex="26" class="btn btn-primary save-button" [disabled]="!(OfferForm.valid && OfferModel.CountryId!=0 && OfferModel.StateId !=0 && OfferModel.CityId!=0 && OfferModel.CompanyId!=0 && OfferModel.BranchId !=0 && OfferModel.DepartmentId!=0 && OfferModel.DecisionId!=0 && OfferModel.LevelId!=0 && OfferModel.GradeId !=0 && OfferModel.DesignationId!=0 && OfferModel.Approved !=true && this.validdate == true && this.OfferModel.RegionHRId > 0 && this.DisableSave==true)" >Save</button>  
                            </label>
                            <div class="col-sm-6 col-form-label text-center">
                            <button type="button" tabindex="27" class="btn btn-danger cancle-button" (click)="RefreshPage()">Cancel</button></div>
                        </div>
                    </div>
                </div>

                <ng-template #template>
                <div class="modal-header">
                    <h4 class="modal-title pull-left">Salary BrakUp</h4>
                    <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <div *ngIf="OfferedSalary.length > 0" >
                        <table id="tblComponent" class="table table-striped table-bordered text-left table-new row-border hover">
                            <thead class="thead-blue text-center">
                                <tr>
                                    <th>Component</th>                        
                                    <th class="text-right">Amount(Rs)</th>                       
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let sc of OfferedSalary, let i = index">
                                    <td *ngIf="sc.IsBenefit==false">
                                        <span>{{sc.SalaryComponentName }}</span>
                                        <input type="text" hidden="hidden" [(ngModel)]="sc.OfferSalaryId " id ="RefId" name="RefId" #RefId="ngModel">
                                    </td>
                                    <td *ngIf="sc.IsBenefit==false" class="text-right">
                                        <input type="text" (blur)="onBasicChangevaluechange()" [(ngModel)]="sc.Amount" name="Amount_{{i}}" #Amount="ngModel" class="form-control form-control-custom form-control-custom-new">
                                    </td>
                                </tr>
                                <tr style="background-color:rgb(207, 250, 250)">
                                    <td>Total Gross Salary : (A)</td>
                                    <td class="text-right">{{this.ComponentSubTotal | number:'3.0-0'}} </td>
                                </tr>
                                <tr *ngFor="let sc of OfferedSalary, let i = index">
                                    <td *ngIf="sc.IsBenefit==true">
                                        <span>{{sc.SalaryComponentName}}</span>
                                        <input type="text" hidden="hidden" [(ngModel)]="sc.OfferSalaryId " id ="RefId" name="RefId" #RefId="ngModel">
                                    </td>                            
                                    <td *ngIf="sc.IsBenefit==true" class="text-right">
                                        <input type="text" (blur)="onBasicChangevaluechange()" [(ngModel)]="sc.Amount" name="Amount_{{i}}" #Amount="ngModel" class="form-control form-control-custom form-control-custom-new">
                                    </td>
                                </tr>
                                <tr *ngIf="this.PFCont > 0">
                                    <td>PF Employer's Contribution</td>
                                    <td class="text-right">{{this.PFCont | number:'3.0-0'}} </td>
                                </tr>
                                <tr *ngIf="this.ESICont > 0">
                                    <td>ESI Employer's Contribution</td>
                                    <td class="text-right">{{this.ESICont | number:'3.0-0'}} </td>
                                </tr>
                                <tr style="background-color:rgb(207, 250, 250)">
                                    <td>Long Term benefits : (B)</td>
                                    <td class="text-right">{{this.Longtermbenefits + this.PFCont + this.ESICont | number:'3.0-0'}} </td>
                                </tr>
                                <tr style="background-color:rgb(162, 162, 235)">
                                    <td>Computed Compensation CTC : (A+B)</td>
                                    <td class="text-right">{{(this.Longtermbenefits + this.ComponentSubTotal + this.PFCont + this.ESICont) | number:'3.0-0'}} </td>
                                </tr>
                                <tr *ngIf="this.PFDed > 0">
                                    <td>Less PF Employee's Contribution</td>
                                    <td class="text-right">{{this.PFDed | number:'3.0-0'}} </td>
                                </tr>
                                <tr *ngIf="this.ESIDed > 0">
                                    <td>Less ESI Employee's Contribution</td>
                                    <td class="text-right">{{this.ESIDed | number:'3.0-0'}} </td>
                                </tr>
                                <tr *ngIf="this.P_Tax > 0">
                                    <td>Professional Tax Amount </td>
                                    <td class="text-right">{{this.P_Tax | number:'3.0-0'}} </td>
                                </tr>
                                <tr style="background-color:rgb(207, 250, 250)">
                                    <td>Total Deduction : (C)</td>
                                    <td class="text-right">{{(this.PFDed + this.ESIDed + this.P_Tax) | number:'3.0-0'}} </td>
                                </tr>
                                <tr style="background-color:rgb(162, 162, 235)">
                                    <td>Take Home Before Income tax Deduction : (A-C)</td>
                                    <td class="text-right">
                                        {{(this.ComponentSubTotal)-(this.PFDed + this.ESIDed + this.P_Tax) | number:'3.0-0'}} 
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </ng-template>
            </form>
        </fieldset>
    </div>
</div>