import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Http, Headers, RequestOptions, Response } from '@angular/http';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import 'rxjs/add/operator/toPromise';
import { Subscriber } from 'rxjs';
import { GlobalVariable } from '../../../app.global';
import { Model_CrmCountry } from './Model_CrmCountry';
import { Model_Crm_UserRoll } from './Model_Crm_User_Roll';
import { AlertPromise } from 'selenium-webdriver';


@Injectable()
export class CrmUserRollService {

    constructor(private http: HttpClient, private spinnerService: Ng4LoadingSpinnerService) { }


    AddCrmUserRoll(token, Add_Crm_UserRoll: Model_Crm_UserRoll) {

        const authHeader = new HttpHeaders();
        authHeader.append('Content-Type', 'application/json');
        authHeader.append('Authorization', token);
        authHeader.append('Accept', 'application/json');
        return this.http.post(GlobalVariable.BASE_API_URL + '/api/Crm_User_Roll/AddCrmUserRoll', Add_Crm_UserRoll, { headers: authHeader })
            .map(this.extractData)
            .catch(this.handleErrorObservable);
    }


    GetCRMUser(token) {

        this.spinnerService.show();
        const authHeader = new HttpHeaders();
        authHeader.append('Content-Type', 'application/json');
        authHeader.append('Authorization', token);
        return new Promise((resolve, reject) => {
            setTimeout(() => {
                this.http.get(GlobalVariable.BASE_API_URL + '/api/Crm_User_Roll/GetCrmEmployee',
                    { headers: authHeader }).subscribe(data => {
                        this.spinnerService.hide();
                        resolve(data)
                    },
                        error => {
                            this.spinnerService.hide();
                            reject(error);
                        });
            }, 3000);
        });
    }

    GetAllCRMUser(token) {

        this.spinnerService.show();
        const authHeader = new HttpHeaders();
        authHeader.append('Content-Type', 'application/json');
        authHeader.append('Authorization', token);
        return new Promise((resolve, reject) => {
            setTimeout(() => {
                this.http.get(GlobalVariable.BASE_API_URL + '/api/Crm_User_Roll/GetAllCrmEmployee',
                    { headers: authHeader }).subscribe(data => {
                        this.spinnerService.hide();
                        resolve(data)
                    },
                        error => {
                            this.spinnerService.hide();
                            reject(error);
                        });
            }, 1000);
        });
    }

    GetAllCRMEmployee(token) {

        this.spinnerService.show();
        const authHeader = new HttpHeaders();
        authHeader.append('Content-Type', 'application/json');
        authHeader.append('Authorization', token);
        return new Promise((resolve, reject) => {
            setTimeout(() => {
                this.http.get(GlobalVariable.BASE_API_URL + '/api/Crm_User_Roll/GetAllCrmEmployee',
                    { headers: authHeader }).subscribe(data => {
                        this.spinnerService.hide();
                        resolve(data)
                    },
                        error => {
                            this.spinnerService.hide();
                            reject(error);
                        });
            }, 1000);
        });
    }




    GetCRMRoles(token) {

        this.spinnerService.show();
        const authHeader = new HttpHeaders();
        authHeader.append('Content-Type', 'application/json');
        authHeader.append('Authorization', token);
        return new Promise((resolve, reject) => {
            setTimeout(() => {
                this.http.get(GlobalVariable.BASE_API_URL + '/api/Crm_Roll/GetCrmRolls?Rollid=' + 1,
                    { headers: authHeader }).subscribe(data => {
                        this.spinnerService.hide();
                        resolve(data)
                    },
                        error => {
                            this.spinnerService.hide();
                            reject(error);
                        });
            }, 3000);
        });
    }

    GetCRMUserRoles(token) {
        this.spinnerService.show();
        const authHeader = new HttpHeaders();
        authHeader.append('Content-Type', 'application/json');
        authHeader.append('Authorization', token);
        return new Promise((resolve, reject) => {
            setTimeout(() => {
                this.http.get(GlobalVariable.BASE_API_URL + '/api/Crm_User_Roll/GetCrmUserRoll',
                    { headers: authHeader }).subscribe(data => {
                        this.spinnerService.hide();
                        resolve(data)
                    },
                        error => {
                            this.spinnerService.hide();
                            reject(error);
                        });
            }, 3000);
        });
    }



    GetCRM_User_Role_By_id(token, id: number) {

        this.spinnerService.show();
        const authHeader = new HttpHeaders();
        authHeader.append('Content-Type', 'application/json');
        authHeader.append('Authorization', token);
        return new Promise((resolve, reject) => {
            setTimeout(() => {
                this.http.get(GlobalVariable.BASE_API_URL + '/api/Crm_User_Roll/GetCrmUserRollById?UserRollId=' + id, { headers: authHeader })
                    .subscribe(data => {
                        this.spinnerService.hide();
                        resolve(data)

                    },
                        error => {
                            this.spinnerService.hide();
                            reject(error);
                        });
            }, 3000);
        });
    }




    UpdateCrmuserRoll(token, CrmUserRole: Model_Crm_UserRoll) {

        const authHeader = new HttpHeaders();
        authHeader.append('Content-Type', 'application/json');
        authHeader.append('Authorization', token);
        authHeader.append('Accept', 'application/json');

        return this.http.post(GlobalVariable.BASE_API_URL + '/api/Crm_User_Roll/Update', CrmUserRole, { headers: authHeader })
            .map(this.extractData)
            .catch(this.handleErrorObservable);
    }


    //   DeleteCrmUserRoll( token ,ID : number) 
    // {

    //     const authHeader = new HttpHeaders();         
    //     authHeader.append('Content-Type', 'application/json');
    //     authHeader.append('Authorization', token);
    //     authHeader.append('Accept', 'application/json');
    //     return this.http.put(GlobalVariable.BASE_API_URL +'/api/Crm_User_Roll/Delete', ID ,{headers: authHeader} )
    //     .map(this.extractData)
    //     .catch(this.handleErrorObservable);
    // } 

    GetMenuByRoleId(token, RoleId: number, ModuleId: number) {
        this.spinnerService.show();
        const authHeader = new HttpHeaders();
        authHeader.append('Content-Type', 'application/json');
        authHeader.append('Authorization', token);
        return new Promise((resolve, reject) => {
            setTimeout(() => {
                this.http.get(GlobalVariable.BASE_API_URL + '/api/Crm_Roll_Menu/GetCrmParentdMenusByRoll?RollId=' + RoleId + '&ModuleId=' + ModuleId, { headers: authHeader })
                    .subscribe(data => {
                        this.spinnerService.hide();
                        resolve(data)
                    },
                        error => {
                            this.spinnerService.hide();
                            reject(error);
                        });
            }, 3000);
        });
    }



    private extractData(res: Response) {
        let body = res;
        return body || {};
    }
    private handleErrorObservable(error: Response | any) {
        console.error(error.message || error);
        return Observable.throw(error.message || error);
    }


}
