import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from '../../shared/user.service';
import { Menu } from '../../shared/menu.model';
import { OrganizationService }  from '../../shared/Organization/organization.service';
import { HolidaysMasterService } from '../../shared/HR_ADMIN/Holidays/HolidaysServices.service';
import { Company } from '../../shared/Organization/Company.Model';
import { Branch } from  '../../shared/Organization/Branch.Model';
import { HolidaysModel } from '../../shared/HR_ADMIN/Holidays/HolidaysModel.Model';
// Begning Confirmation
import { ConfirmationService } from '@jaspero/ng2-confirmations';
import { ResolveEmit } from '../../../app/resolve-emit';
import { ConfirmSettings } from '../../../app/confirm-settings';
// end of Confirmation
import { ModalOptions,BsModalRef, BsModalService } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-manage-holidays',
  templateUrl: './manage-holidays.component.html',
  styleUrls: ['./manage-holidays.component.css']
})

export class ManageHolidaysComponent implements OnInit 
{
  HolidaysModel : HolidaysModel= new HolidaysModel();
  HolidaysMaster : HolidaysModel[]=[];
  Holidays : any;
  userClaims: any;
  //Organisation
  Companies : Company[]=[];
  Company : any;
  Branches: Branch[] = [];
  Branch: any;

  Result : string;
  SuccessStatus:boolean=false;
  SuccessMessage: string ="";
  public tableWidget: any;
  RefId : any;

  tempHolidaysadd : HolidaysModel =new HolidaysModel();
  // Begning Permission Portion    
  SelectedMenu:Menu;
  IsDataIsInViewState : boolean=false;
  CanViewState : boolean=true;
  // End of the permission portion
  
  // begin for Validation
  validdate : boolean= false;
  DisableSave : boolean=false;
  // end of validation
  //check for duplicate data
  CheckdataExiststatus : boolean=null;  
  Checkdatastatusedit : boolean =false;
  datacheck : string ="";
  checkdata : any;
  checkdataval : string ="";
  //check for duplicate dat
  Mod: any;
  SubMenues : Menu[]=[];
  //For Pop up
  SaveMsg : boolean= false;
  DeleteErrorMsg : boolean= false;
  DeleteMsg : boolean=false;
  public modalRef : BsModalRef;
  public modelopt : ModalOptions;

  constructor(private organizationService: OrganizationService,private HolidaysMasterService: HolidaysMasterService, private rout: Router, private _confirmation: ConfirmationService, private userService: UserService ,private modalService: BsModalService)
  { 
    this.modelopt=new ModalOptions();
    this.modelopt.class='heavy_modal';
    this.modalService.config= this.modelopt;
    this.modelopt.ignoreBackdropClick=true;
  }

  ngOnInit() 
  {
    // Begning Permission Portion 
    this.SubMenues=[];
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    let menuid = localStorage.getItem("menuidstor");
    this.userService.GetSubMenuList( t, menuid).then(x => 
    {
      this.Mod = x;
      Object.keys(this.Mod).forEach( key => 
      {
        // tslint:disable-next-line:no-unused-expression
        this.Mod[key];
        let menutemp = new Menu();
        menutemp = this.Mod[key];
        this.SubMenues.push(menutemp);
      });
      this.userService.SelectedMenu= this.SubMenues[0];
      this.rout.navigate([this.userService.SelectedMenu.RouteName]);
      this.SelectedMenu=this.userService.SelectedMenu;
      if(this.SelectedMenu.CanAdd)
      {
        this.IsDataIsInViewState=false;
      }
      else
      {
        this.IsDataIsInViewState=true;
      }
      if(this.SelectedMenu.CanView)
      {
        this.CanViewState=false;
      }
      else
      {
        this.CanViewState=true;
      }
    });
      // End of the permission portion
    this.getCompanies();
    this.HolidaysModel.CompanyId=0;
    this.HolidaysModel.BranchId=0;
    this.DisableSave=true;
    this.SaveMsg=false;
    this.DeleteErrorMsg=false;
    this.DeleteMsg=false;
  }

  settings: ConfirmSettings | any = 
  {
    overlay: true,
    overlayClickToClose: true,
    showCloseButton: true,
    confirmText: 'Yes',
    declineText: 'No'
  };

  ngAfterViewInit(): void 
  {
    
  }

  GetHolidaysMaster(CompanyId,BranchId)
  {
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.HolidaysMasterService.GetHolidaysMaster( t,CompanyId,BranchId).then(x => 
    {
      this.HolidaysMaster=[];
      this.Holidays = x;
      Object.keys(this.Holidays).forEach( key => 
      {
        this.Holidays[key];
        let Holidaystemp = new HolidaysModel();
        Holidaystemp = this.Holidays[key];
        this.HolidaysMaster.push(Holidaystemp);
      });
      if(this.HolidaysMaster.length == 0 )
      {
        let TempList : HolidaysModel[]=[];
        for( var i=0; i<3; i++ )
        {
          let tempHoliday = new HolidaysModel();          
          TempList.push(tempHoliday);         
        }
        this.HolidaysMaster=TempList;
      }
    });
  }

  DeleteHolidaysModelById(RefId : number,HolidayDate : Date,template) :void
  {    
    this._confirmation.create('Confirm to Deleate', 'Press Yes to Delete', this.settings)
    .subscribe((ans: ResolveEmit) => 
    {
      if (ans.resolved == true) 
      {
        var CurrentDate = new Date();
        var DateHolidays = new Date(HolidayDate);
        if(DateHolidays >= CurrentDate)
        {
          let t =  localStorage.getItem('userToken');
          t = 'Bearer' + t;
          this.HolidaysModel.IsActive=true;
          if( RefId > 0)
          {
            this.HolidaysMasterService.DeleteHolidaysMaster(t ,RefId).subscribe(res=>
            {
              this.RefId=res;
              this.HolidaysModel.RefId=0;                                  
              if(this.RefId >0)
              {
                this.SaveMsg=false;
                this.DeleteErrorMsg=false;
                this.DeleteMsg=true;
                this.modalRef = this.modalService.show(template);
                this.GetHolidaysMaster(this.HolidaysModel.CompanyId,this.HolidaysModel.BranchId);
                this.DisableSave=true;
              }
            });                  
          }
        }
        else
        {
          this.SaveMsg=false;
          this.DeleteErrorMsg=true;
          this.DeleteMsg=false;
          this.modalRef = this.modalService.show(template);
        }
      } 
    })
  }

  dateChanged( date,i: number)
  {
    this.HolidaysMaster[i].HolidayDate=date;
    var CurrentDate = new Date();
    var CurrentYear = CurrentDate.getFullYear();
    var currentMonth = CurrentDate.getMonth();
    var CurrentDay = CurrentDate.getDate();
    
    var selecteedDate = new Date(date);
    var selectedYear=selecteedDate.getFullYear();
    var selectedMonth=selecteedDate.getMonth();
    var selectedDay=selecteedDate.getDate();

    if(CurrentYear > selectedYear )
    {
     this.validdate = false;      
    }
    else
     {
       if (currentMonth > selectedMonth)
       {
         this.validdate = false;
       }
       else
       {
         if(CurrentDay >= selectedDay )
         {
           this.validdate = false;
         }
         else
         {
           this.validdate = true;
         }
       }
     }
    this.CheckDuplicatedata(this.HolidaysModel.CompanyId,this.HolidaysModel.BranchId,date);
  }
  //Begin Dropdownlist
  getCompanies() 
  {
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.organizationService.GetCompanyForDDL( t).then(x => 
    {
      this.Company = x;
      Object.keys(this.Company).forEach( key => 
      {
        this.Company[key];
        let Companytemp = new Company();
        Companytemp = this.Company[key];
        this.Companies.push(Companytemp);
      });     
    });      
  }

  getBranches( Companyid) 
  {
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t
    if(this.Branches.length>0)
    {
      this.Branches.splice(0, this.Branches.length);
    }
    this.organizationService.GetBranchByCompanyId( t,Companyid).then(x => 
    {
      this.Branch = x;
      Object.keys(this.Branch).forEach( key => 
      {
        this.Branch[key];
        let Branchtemp = new Branch();
        Branchtemp = this.Branch[key];
        this.Branches.push(Branchtemp);
      });     
    });      
  }

  onChangeCompany(Companyid) 
  {
    if(Companyid!=0)
    {
      this.Branches=[];
      this.getBranches(Companyid);
      this.HolidaysModel.BranchId=0;
    }
    else
    {
      this.Branches=[];
      this.getBranches(Companyid);
      this.HolidaysModel.BranchId=0;
    }
  }

  onchangeBranch(Branchid) 
  {
    if(Branchid > 0 && this.HolidaysModel.CompanyId > 0)
    {
      this.GetHolidaysMaster(this.HolidaysModel.CompanyId,Branchid);
    }
  }
//End Dropdownlist
  
  RefreshPage()
  {
    this.rout.navigateByUrl('/ManageHolidaysMaster/ManageCalendar', {skipLocationChange: true}).then(()=>this.rout.navigate(["/ManageHolidaysMaster/ManageHolidays"]));
  }
  // begning of check duplicate data
  CheckDuplicatedata(CompanyId,BranchId,HolidayDate)
  {
    if(CompanyId > 0 && BranchId > 0 && HolidayDate!=undefined)
    {
      let t =  localStorage.getItem('userToken');
      t = 'Bearer' + t;
      if(!this.Checkdatastatusedit)
      {
        this.HolidaysMasterService.CheckDuplicateData( t, HolidayDate,CompanyId,BranchId).then(x => 
        {
          this.checkdata = x;
          if(this.checkdata.length >0)
          {
            this.CheckdataExiststatus =false;
            this.datacheck="Exist"
          }
          else
          {
            this.CheckdataExiststatus =true;
            this.datacheck="";
          }
      });
      }
      else
      {
        this.HolidaysMasterService.CheckDuplicateData( t, this.HolidaysModel.HolidayDate,this.HolidaysModel.CompanyId,this.HolidaysModel.BranchId).then(x => 
        {
          this.checkdata = x;
          if( this.checkdata.length >0)
          {
            this.CheckdataExiststatus =false;
            this.datacheck="Exist"
          }
          else
          {
            this.CheckdataExiststatus =true;
            this.datacheck="";
          }
        });
      }
    }
  }
  // end of duplicate Name

  AddnewRow()
  {
    let tempHoliday = new HolidaysModel();          
    this.HolidaysMaster.push(tempHoliday);     
  }

  SaveHolidaysMaster(template) :void
  {
    //this.DisableSave=false;
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;    
    var j=0;
    var l=this.HolidaysMaster.length;
    this.HolidaysMaster.forEach(item => 
    {
      item.CompanyId=this.HolidaysModel.CompanyId;
      item.BranchId=this.HolidaysModel.BranchId;
      j=j+1;
      if(item.HolidayDate != undefined)
      {     
        if(item.RefId> 0)
        {
          this.HolidaysMasterService.UpdateHolidaysMaster(t ,item).subscribe(res=>
          {
            this.RefId=res;        
            // if(this.RefId>0)
            // {
            //   localStorage.setItem('SuccessfullMessage', "Update Successfully");
            //   this.rout.navigateByUrl('/ManageHolidaysMaster/ManageCalendar', {skipLocationChange: true}).then(()=>this.rout.navigate(["/ManageHolidaysMaster/ManageHolidays"]));
            // }
          });                  
        }                             
        else
        {
          this.HolidaysMasterService.AddHolidaysMaster(t ,item).subscribe(res=>
          {
            this.RefId=res;         
            // if(this.RefId>0)
            // {
            //   localStorage.setItem('SuccessfullMessage', "Save Successfully");
            //   this.rout.navigateByUrl('/ManageHolidaysMaster/ManageCalendar', {skipLocationChange: true}).then(()=>this.rout.navigate(["/ManageHolidaysMaster/ManageHolidays"]));
            // }
          }); 
        }
      }
    });   

    this.SaveMsg=true;
    this.DeleteErrorMsg=false;
    this.DeleteMsg=false;
    this.modalRef = this.modalService.show(template);
    this.GetHolidaysMaster(this.HolidaysModel.CompanyId,this.HolidaysModel.BranchId);
    this.DisableSave=true;
  }
}