// import { Injectable } from '@angular/core';

// @Injectable()
// export class AppGlobals {    
//     readonly baseAPIUrl: string = 'http://localhost:11678';
// }
export const GlobalVariable = Object.freeze({
    //BASE_API_URL: 'http://192.168.0.169:11678'
   // BASE_API_URL: 'http://localhost:11678'

    //BASE_API_URL: 'http://localhost:92'

    //... more of your variables
   BASE_API_URL: 'https://erpapi.zenithholidays.com'
});