<!-- <perfect-scrollbar style="max-width: 1260px; max-height: 540px;" class="mukesh"> -->
  <div class="body-content-area tabcontent" id="link1">
    <div class="row">
      <div class="col-lg-9">
        <div class="row">
          <div class="col-lg-1 low-padding text-center">
            <!-- <img *ngIf="this.SelectedMenu.Image!=null" src={{this.SelectedMenu.Image}} class="body-icon" alt="Icon"/> -->
          </div>
          <div class="col-lg-10 clear-padding">
            <div>
              <span class="page-title">Employee Attendance Information</span>
              <small class="page-description"></small>
            </div>
          </div>
        </div>
      </div>
    </div>
    
    <fieldset class="body-form-control" > 
    <legend class="legend--">Personal Details</legend>
      <form #EmployeeAttendanceForm="ngForm">
        <div class="row">
          <div class="col-lg-4">
            <div class="col-body-border-right">
              <div class="form-group row">
                <label class="col-sm-4 col-form-label clear-padding-right">From Date</label>
                <div class="col-sm-7 clear-left-padding">
                  <input type="date" name="FromDate" [ngModel]="EmployeeAttendanceModel.FromDate | date:'yyyy-MM-dd'" (ngModelChange)="FromDateChanged($event)" #FromDate="ngModel" required/>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-4">
            <div class="col-body-border-right">
              <div class="form-group row">
                <label class="col-sm-4 col-form-label clear-padding-right">To Date</label>
                <div class="col-sm-7 clear-left-padding">
                  <input type="date" name="ToDate" [ngModel]="EmployeeAttendanceModel.ToDate | date:'yyyy-MM-dd'" (ngModelChange)="ToDateChanged($event)" #ToDate="ngModel" required/>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-4">
            <div class="form-group row">
              <div class="col-sm-7 clear-left-padding">
                <button type="submit" (click)="submitClick()" class="btn btn-primary save-button" [disabled]="!(EmployeeAttendanceForm.valid)">Generate</button>   
              </div>
            </div>
            <div class="form-group row">
              <div class="col-sm-7 clear-left-padding" *ngIf="EmployeeAttendance.length > 0">
                <button type="submit" class="btn btn-danger cancle-button" (click)="print()">Print</button>   
              </div>
            </div>
          </div>
        </div>
      </form>    
    </fieldset>
    
    <div *ngIf="NoRecordMsg" style="color: #dc3545; text-align: center;font-size: 15px; font-weight: bold;">No Record Found.</div>

    <div class="row" id="divPrint" style=" width: 100%;" *ngIf="EmployeeAttendance.length > 0">
      <fieldset class="body-form-control" style=" width: 95%; font-size: small; margin-left: 16px;"> 
        <legend class="legend--"></legend>
        <table style=" width: 100%; font-size: small;">
          <tr>
            <td colspan="8">&nbsp;&nbsp;</td>
          </tr>
          <tr>
            <td colspan="8" style=" font-size: medium; text-align: center; font-weight: bolder;">Employee Daily Attendance Information</td>
          </tr>
          <tr>
            <td style=" width: 8%;">Id</td>
            <td style=" width: 10%;">:&nbsp;&nbsp;{{this.EmployeeAttendanceModel.EmployeeCode}}</td>
            <td style=" width: 8%;">Name</td>
            <td style=" width: 30%;">:&nbsp;&nbsp;{{this.EmployeeAttendanceModel.EmployeeName}}</td>
            <td style=" width: 10%;">From Date</td>
            <td style=" width: 12%;">:&nbsp;&nbsp;{{EmployeeAttendanceModel.FromDate | date:'dd-MM-yyyy'}}</td>
            <td style=" width: 10%;">To Date</td>
            <td style=" width: 12%;">:&nbsp;&nbsp;{{EmployeeAttendanceModel.ToDate | date:'dd-MM-yyyy'}}</td>
          </tr>
          <tr>
            <td colspan="8">&nbsp;&nbsp;</td>
          </tr>
        </table>
      </fieldset>

      <fieldset class="body-form-control" style=" width: 95%; font-size: small; margin-left: 16px;"> 
        <legend class="legend--"></legend>
          <form #EmployeeAttendanceDailyDetails="ngForm">
            <div class="row">
              <table id="tblEmployeeAttendanceDailyDetails" style=" font-size: small; width: 97%; margin: auto; padding: 5px;" class="table table-striped table-bordered text-left table-new row-border hover">
                <thead class="thead-blue text-center" style=" font-size: small; width: 100%;">
                  <tr>
                    <th style=" width: 11%; text-align: left;">Sl.No.</th>
                    <th style=" width: 11%;">Date</th>
                    <th style=" width: 12%; text-align: left;">Day</th>
                    <th style=" width: 11%;">LogIn Time</th>
                    <th style=" width: 11%;">LogOut Time</th>
                    <th style=" width: 11%;">Working hrs</th>
                    <th style=" width: 11%;">Status</th>
                    <th style=" width: 11%;">Late By(InTime)</th>
                    <th style=" width: 11%;">Early By(OutTime)</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let EmpAtt of EmployeeAttendance, let i = index">
                    <td scope="row">{{i+1}}</td>
                    <td style=" text-align: center;">{{EmpAtt.AttendanceDate | date : "dd-MMM-yyyy"}}</td>
                    <td>{{EmpAtt.AttendanceDay}}</td>
                    <td style=" text-align: center;">{{EmpAtt.InTime | date : "HH:mm:ss"}}</td>
                    <td style=" text-align: center;">{{EmpAtt.OutTime | date : "HH:mm:ss"}}</td>
                    <td style=" text-align: center;">{{EmpAtt.WorkingHourshow}}</td>
                    <td style=" text-align: center;">{{EmpAtt.Status}}</td>
                    <td style=" text-align: center;">{{EmpAtt.LateBy}}</td>
                    <td style=" text-align: center;">{{EmpAtt.EarlyBy}}</td>
                  </tr>
                </tbody>
              </table>
              <!-- <button type="button" class="btn btn-danger cancle-button" (click)="export()">Print</button> -->
            </div>
          </form>
      </fieldset>
    </div>
    <br>
  </div>  
<!-- </perfect-scrollbar> -->