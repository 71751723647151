import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Http, Headers, RequestOptions, Response } from '@angular/http';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import 'rxjs/add/operator/toPromise';
import { Subscriber } from 'rxjs';
import { GlobalVariable } from '../../../app.global';


import { Model_CRM_State } from './Model_CRM_State';
import { Model_CRM_City } from './Model_CRM_City';




@Injectable()

export class CrmCityService {

    constructor(private http: HttpClient, private spinnerService: Ng4LoadingSpinnerService) {
    }


    //    Populate Country Drop  Down Starts

    GetCountryType(token) {
        this.spinnerService.show();
        const authHeader = new HttpHeaders();
        authHeader.append('Content-Type', 'application/json');
        authHeader.append('Authorization', token);
        return new Promise((resolve, reject) => {
            setTimeout(() => {
                // alert('');

                this.http.get(GlobalVariable.BASE_API_URL + '/api/CRMCity/GetCountryName', { headers: authHeader })
                    .subscribe(data => {
                        this.spinnerService.hide();
                        resolve(data)
                    },
                        error => {
                            this.spinnerService.hide();
                            reject(error);
                        });
            }, 3000);
        });
    }

    //  Populate  Country Drop  Down Ends



    //    Populate Zone Drop  Down Starts

    GetZoneType(token, countryId: number) {
        this.spinnerService.show();
        const authHeader = new HttpHeaders();
        authHeader.append('Content-Type', 'application/json');
        authHeader.append('Authorization', token);
        return new Promise((resolve, reject) => {
            setTimeout(() => {

                this.http.get(GlobalVariable.BASE_API_URL + '/api/CRMCity/GetZoneName?CountryId=' + countryId, { headers: authHeader })
                    .subscribe(data => {
                        this.spinnerService.hide();
                        resolve(data)
                    },
                        error => {
                            this.spinnerService.hide();
                            reject(error);
                        });
            }, 3000);
        });
    }

    //  Populate Zone Drop  Down Ends


    //    Populate State Drop  Down Starts

    GetStateType(token, zoneid: number) {
        this.spinnerService.show();
        const authHeader = new HttpHeaders();
        authHeader.append('Content-Type', 'application/json');
        authHeader.append('Authorization', token);
        return new Promise((resolve, reject) => {
            setTimeout(() => {

                this.http.get(GlobalVariable.BASE_API_URL + '/api/CRMCity/GetStateName?ZoneId=' + zoneid, { headers: authHeader })
                    .subscribe(data => {
                        this.spinnerService.hide();
                        resolve(data)
                    },
                        error => {
                            this.spinnerService.hide();
                            reject(error);
                        });
            }, 3000);
        });
    }

    //  Populate State Drop  Down Ends


    //    Populate State Drop  Down Starts

    GetCity(token, StateId: number) {
        this.spinnerService.show();
        const authHeader = new HttpHeaders();
        authHeader.append('Content-Type', 'application/json');
        authHeader.append('Authorization', token);
        return new Promise((resolve, reject) => {
            setTimeout(() => {

                this.http.get(GlobalVariable.BASE_API_URL + '/api/CRMBranch/GetCityName?StateId=' + StateId, { headers: authHeader })
                    .subscribe(data => {
                        this.spinnerService.hide();
                        resolve(data)
                    },
                        error => {
                            this.spinnerService.hide();
                            reject(error);
                        });
            }, 3000);
        });
    }

    //  Populate State Drop  Down Ends




    AddCrmCity(token, CRMCity: Model_CRM_City) {
        const authHeader = new HttpHeaders();
        authHeader.append('Content-Type', 'application/json');
        authHeader.append('Authorization', token);
        authHeader.append('Accept', 'application/json');
        //  alert();
        return this.http.post(GlobalVariable.BASE_API_URL + '/api/CrmCity/Add', CRMCity, { headers: authHeader })
            .map(this.extractData)
            .catch(this.handleErrorObservable);
    }




    // Grid Data Fetch  

    Get_CRMCity(token) {
        this.spinnerService.show();
        const authHeader = new HttpHeaders();
        authHeader.append('Content-Type', 'application/json');
        authHeader.append('Authorization', token);
        return new Promise((resolve, reject) => {
            setTimeout(() => {
                this.http.get(GlobalVariable.BASE_API_URL + '/api/CRMCity/GetCRMCity',
                    { headers: authHeader }).subscribe(data => {
                        this.spinnerService.hide();
                        resolve(data)
                    },
                        error => {
                            this.spinnerService.hide();
                            reject(error);
                        });
            }, 3000);
        });
    }





    //     //  View  Section Starts

    GetCRM_City_By_id(token, id: number) {
        this.spinnerService.show();
        const authHeader = new HttpHeaders();
        authHeader.append('Content-Type', 'application/json');
        authHeader.append('Authorization', token);
        return new Promise((resolve, reject) => {
            setTimeout(() => {
                this.http.get(GlobalVariable.BASE_API_URL + '/api/CRMCity/GetCRMCityById?CityId=' + id, { headers: authHeader })
                    .subscribe(data => {
                        this.spinnerService.hide();
                        resolve(data)
                    },
                        error => {
                            this.spinnerService.hide();
                            reject(error);
                        });
            }, 3000);
        });
    }

    //     //  View  Section Ends    





    UpdateCrmCity(token, CRMCity: Model_CRM_City) {

        const authHeader = new HttpHeaders();
        authHeader.append('Content-Type', 'application/json');
        authHeader.append('Authorization', token);
        authHeader.append('Accept', 'application/json');

        return this.http.post(GlobalVariable.BASE_API_URL + '/api/CrmCity/Update', CRMCity, { headers: authHeader })
            .map(this.extractData)
            .catch(this.handleErrorObservable);
    }







    //  Delete  a   Record  Starts

    DeleteCity(token, ID: number) {

        const authHeader = new HttpHeaders();
        authHeader.append('Content-Type', 'application/json');
        authHeader.append('Authorization', token);
        authHeader.append('Accept', 'application/json');
        return this.http.put(GlobalVariable.BASE_API_URL + '/api/CrmCity/Delete', ID, { headers: authHeader })
            .map(this.extractData)
            .catch(this.handleErrorObservable);
    }

    //  Delete  a  record   Ends    



    private extractData(res: Response) {
        let body = res;
        return body || {};
    }
    private handleErrorObservable(error: Response | any) {
        console.error(error.message || error);
        return Observable.throw(error.message || error);
    }


}
