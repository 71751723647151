/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./save-button.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./save-button.component";
var styles_SaveButtonComponent = [i0.styles];
var RenderType_SaveButtonComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_SaveButtonComponent, data: {} });
export { RenderType_SaveButtonComponent as RenderType_SaveButtonComponent };
export function View_SaveButtonComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "button", [["class", "btn btn-info save-btn"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onClick($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.label; _ck(_v, 1, 0, currVal_0); }); }
export function View_SaveButtonComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-save-button", [], null, null, null, View_SaveButtonComponent_0, RenderType_SaveButtonComponent)), i1.ɵdid(1, 49152, null, 0, i2.SaveButtonComponent, [], null, null)], null, null); }
var SaveButtonComponentNgFactory = i1.ɵccf("app-save-button", i2.SaveButtonComponent, View_SaveButtonComponent_Host_0, {}, {}, []);
export { SaveButtonComponentNgFactory as SaveButtonComponentNgFactory };
