<div class="body-content-area tabcontent" id="link1">
  <div class="row">
    <div class="col-lg-9">
      <div class="row">
        <div class="col-lg-1 low-padding text-center">
          <!-- <img *ngIf="this.SelectedMenu.Image!=null" src={{this.SelectedMenu.Image}} class="body-icon" alt="Icon"/> -->
        </div>
        <div class="col-lg-10 clear-padding">
          <div>
            <span class="page-title">Employee Tour Reibursement Application</span>
            <small class="page-description"></small>
          </div>
        </div>
      </div>
    </div>
  </div>

  <fieldset class="body-form-control" > 
    <div>
      <app-dialog [(visible)]="this.SuccessStatus">
        <span class="successmsg">{{this.SuccessMessage}}</span>
      </app-dialog>
    </div>
    <legend class="legend--">Tour Reibursement Application</legend>
    <form #TourReibursementForm="ngForm" (ngSubmit)="SaveAEmployeeTourReimbursementApplication()">
      <div class="row"> 
        <div class="col-lg-4">
          <div class="col-body-border-right">
            <div class="form-group row">
              <label  class="col-sm-4 col-form-label clear-padding-right">Employee Code</label>
              <div class="col-sm-7 clear-left-padding">
                :&nbsp;&nbsp;{{this.TourReimbursementModel.EmployeeCode}}
              </div>
            </div>
            <div class="form-group row">
              <label class="col-sm-4 col-form-label clear-padding-right">Expense Name</label>
              <div class="col-sm-7 clear-left-padding">
                  <input type="text" tabindex="1" [(ngModel)]="TourReimbursementModel.ExpenseName" id ="ExpenseName" name="ExpenseName" #ExpenseName="ngModel" required class="form-control form-control-custom form-control-custom-new">
              </div>
              <div *ngIf="ExpenseName.invalid && (ExpenseName.dirty || ExpenseName.touched)" class="required-validation">
                <div *ngIf="ExpenseName.errors.required">Expense Name is required.</div>
                <div *ngIf="ExpenseName.errors.maxlength">Expense Name is not more than 500 characters</div>
              </div>
            </div>  
            <div class="form-group row">
              <label  class="col-sm-4 col-form-label clear-padding-right">From Date</label>
              <div class="col-sm-7 clear-left-padding">
                :&nbsp;&nbsp;<input type="date" tabindex="3" style="width:90%;" name="FromDate" [ngModel]="this.TourReimbursementModel.FromDate | date:'yyyy-MM-dd'" (ngModelChange)="FromDateChanged($event)" #FromDate="ngModel" required/>
              </div>
              <div class="required-validation" *ngIf="validFromdate">From Date is not Valid Date.</div>
              <div class="required-validation" *ngIf="validdate">Please select Valid Date.</div>
            </div>
            <div class="form-group row">
              <label  class="col-sm-4 col-form-label clear-padding-right">No Of Days</label>
              <div class="col-sm-7 clear-left-padding">
                :&nbsp;&nbsp;{{this.TourReimbursementModel.NoOfDays}} 
              </div>
            </div>      
          </div>  
        </div>

        <div class="col-lg-4">
          <div class="col-body-border-right">
            <div class="form-group row">
              <label  class="col-sm-4 col-form-label clear-padding-right">Name</label>
              <div class="col-sm-7 clear-left-padding">
                :&nbsp;&nbsp;{{this.TourReimbursementModel.EmployeeName}}
              </div>
            </div>
            <div class="form-group row">
              <label class="col-sm-4 col-form-label clear-padding-right">Tour Place</label>
              <div class="col-sm-7 clear-left-padding">
                  <input type="text" tabindex="2" [(ngModel)]="TourReimbursementModel.TourPlace" id ="TourPlace" name="TourPlace" #TourPlace="ngModel" required class="form-control form-control-custom form-control-custom-new">
              </div>
              <div *ngIf="TourPlace.invalid && (TourPlace.dirty || TourPlace.touched)" class="required-validation">
                <div *ngIf="TourPlace.errors.required">Place is required.</div>
                <div *ngIf="TourPlace.errors.maxlength">Place is not more than 500 characters</div>
              </div>
            </div> 
            <div class="form-group row">
              <label  class="col-sm-4 col-form-label clear-padding-right">To Date</label>
              <div class="col-sm-7 clear-left-padding">
                :&nbsp;&nbsp;<input type="date" tabindex="4" style="width:90%;" name="ToDate" [ngModel]="this.TourReimbursementModel.ToDate | date:'yyyy-MM-dd'" (ngModelChange)="ToDateChanged($event)" #ToDate="ngModel" required/>
              </div>
              <div class="required-validation" *ngIf="validTodate">To Date is not Valid Date.</div>
              <div class="required-validation" *ngIf="validdate">Please select Valid Date.</div>
            </div>
            <div class="form-group row">
              <label class="col-sm-4 col-form-label clear-padding-right">No Of Trip</label>
              <div class="col-sm-7 clear-left-padding">
                <select  (ngModelChange)="NoofTourChanged($event)" [(ngModel)]="this.TourReimbursementModel.NOofTrip" id ="NOofTrip" class="form-control" name="NOofTrip" #NOofTrip="ngModel">
                <option [value]="0" [disabled]=true >No Of Tour</option>
                <option [value]="1">1</option>
                <option [value]="2">2</option>
                <option [value]="3">3</option>
                <option [value]="4">4</option>
                <option [value]="5">5</option>
                </select>
              </div>
              <div class="required-validation" *ngIf="this.TourReimbursementModel.NOofTrip==0">No Of Tour required</div>
            </div>
          </div> 
        </div>

        <div class="col-lg-4">
          <div class="form-group row">
            <label  class="col-sm-4 col-form-label clear-padding-right">Available Amount</label>
            <div class="col-sm-7 clear-left-padding">
              :&nbsp;&nbsp;{{this.TourReimbursementModel.NetAvailableAmount}} 
            </div>
          </div>
          <div class="form-group row">
            <label class="col-sm-4 col-form-label clear-padding-right">Advance Amount</label>
            <div class="col-sm-7 clear-left-padding">
              <input type="text" disabled="true" [(ngModel)]="TourReimbursementModel.AdvanceAmount" id ="AdvanceAmount" name="AdvanceAmount" pattern="[0-9]+" #AdvanceAmount="ngModel" required class="form-control form-control-custom form-control-custom-new">
            </div>
            <!-- <div *ngIf="AdvanceAmount.invalid && (AdvanceAmount.dirty || AdvanceAmount.touched)" class="required-validation">
              <div *ngIf="AdvanceAmount.errors.required">Advance Amount is required.</div>
              <div *ngIf="AdvanceAmount.errors?.pattern">Advance Amount should be valid.</div>
            </div> -->
          </div>
          <!-- <div class="form-group row">
            <label class="col-sm-4 col-form-label clear-padding-right">HR</label>
            <div class="col-sm-7 clear-left-padding">
              :&nbsp;&nbsp;{{this.TourReimbursementModel.HRNames}} 
            </div>
          </div> -->
          <div class="form-group row">
            <label  class="col-sm-4 col-form-label clear-padding-right">Advance Date</label>
            <div class="col-sm-7 clear-left-padding">
              :&nbsp;&nbsp;<input type="date" tabindex="5" style="width:90%;" name="AdvancePaidDate" [ngModel]="this.TourReimbursementModel.AdvancePaidDate | date:'yyyy-MM-dd'" (ngModelChange)="AdvancePaidDateChanged($event)" #AdvancePaidDate="ngModel" required/>
            </div>
            <!-- <div class="required-validation" *ngIf="validAdvancePaidDate">Advance Date is not Valid Date.</div>
            <div class="required-validation" *ngIf="validAdvancePaidDate">Please select Valid Date.</div> -->
          </div>
          <div class="form-group row">
            <label  class="col-sm-4 col-form-label clear-padding-right">No of Nights </label>
            <div class="col-sm-7 clear-left-padding">
              <input type="text" tabindex="7" [(ngModel)]="TourReimbursementModel.NoOfNights" id ="NoOfNights" name="NoOfNights" pattern="[0-9]+" #NoOfNights="ngModel" required class="form-control form-control-custom form-control-custom-new">
            </div>
            <div *ngIf="NoOfNights.invalid && (NoOfNights.dirty || NoOfNights.touched)" class="required-validation">
              <div *ngIf="NoOfNights.errors.required">No Of Nights is required.</div>
              <div *ngIf="NoOfNights.errors?.pattern">No Of Nights should be valid.</div>
            </div>
            <div *ngIf="TourReimbursementModel.NoOfNights < 1" class="required-validation">No Of Nights should be more than 1.</div>
          </div> 
        </div> 
      </div> 

      <div lass="row" >
        <table id="tblExpense" class="table table-striped table-bordered text-left table-new row-border hover" style="width: 100%;">
          <thead class="thead-blue text-center"s tyle="width: 100%;">
            <tr>
              <th style=" text-align: center;">Expense Head</th>
              <th style=" text-align: center;">Budget Amount</th>
              <th style=" text-align: left;">Expected Expense Amount</th>  
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let TEH of TourExpenseHeads, let i = index">
              <td>
                {{TEH.ExpenseHeadName}}
                <input type="text" hidden="hidden" [(ngModel)]="TEH.ExpenseHeadId" name="ExpenseHeadId_{{i}}" #ExpenseHeadId="ngModel">
              </td>
              <td>{{TEH.BudgetAmount}}</td>
              <td>
                <input type="text" [(ngModel)]="TEH.ExpenseAmount" name="ExpenseAmount_{{i}}" #ExpenseAmount="ngModel" pattern="[0-9]+" (ngModelChange)="ExpenseAmountChanged()" class="form-control form-control-custom form-control-custom-new" required>
                <div *ngIf="ExpenseAmount.invalid && (ExpenseAmount.dirty || ExpenseAmount.touched)" class="required-validation">
                  <div *ngIf="ExpenseAmount.errors?.pattern">Amount should be valid.</div>
                </div>
              </td>
            </tr>
          </tbody>
          <tfoot class="thead-blue text-center">
              <tr>
                <th style="width: 30%; text-align: right;"></th>
                <th style="width: 30%; text-align: right;">Total Expected Expense Amount</th>
                <th style="width: 35%; text-align: left;">: &nbsp;&nbsp;{{this.TourReimbursementModel.ExpectedExpenseAmount}}</th>  
              </tr>
            </tfoot>
        </table>
      </div>

      <div class="form-group row">
        <label class="col-sm-6 col-form-label text-center clear-padding-right">
          <button type="submit" class="btn btn-primary save-button" style=" width: 50%;" [disabled]="!(TourReibursementForm.valid && this.TourReimbursementModel.ExpectedExpenseAmount >0 
          && this.DisableSave==true && this.ShowMessage == false && TourReimbursementModel.NoOfNights > 0 && this.validdate == false && (this.TourReimbursementModel.NetAvailableAmount - this.TourReimbursementModel.ExpectedExpenseAmount)>=0 )">Save</button>  
        </label>
        <div class="col-sm-6 col-form-label text-center">
          <button type="button" style=" width: 50%;" (click)="RefreshPage()" class="btn btn-danger cancle-button">Cancel</button>
        </div>
      </div>
      <div class="form-group row">
        <div class="required-validation" *ngIf="ShowMessage">Application has been Already Applied.</div>
        <div class="required-validation" *ngIf="(this.TourReimbursementModel.NetAvailableAmount - this.TourReimbursementModel.ExpectedExpenseAmount) < 0 ">You Have Insufficant Amount To Applied.</div>
      </div>


    </form>
  </fieldset>
</div>