import { Input, Output, EventEmitter, ElementRef, Directive, Renderer } from '@angular/core';
declare var jQuery: any;
@Directive({
    selector: '[myDatepicker]'
})

export class DatePicker {
    @Input() value = '';
    @Input() minimumDate: boolean = false;
    @Input() onlyDatePicker: boolean = true;
    @Output() dateChange = new EventEmitter();
    @Input() mindate: Date;
    private $el: any;
    constructor(public el: ElementRef, public renderer: Renderer) {

        this.$el = $(el.nativeElement);
    }
    ngOnInit() {




        if (this.onlyDatePicker == true) {
            jQuery(this.el.nativeElement).datepicker({
                controlType: 'select'
                , oneLine: true,
                dateFormat: 'dd/mm/yy',
                changeMonth: true,
                changeYear: true,
                minDate: this.minimumDate == false ? '' : new Date(),

                onSelect: (value) => {
                    this.value = value;
                    this.dateChange.next(value);
                }
            })
                .datepicker('setDate', this.value);
        }
        else {
            jQuery(this.el.nativeElement).datetimepicker({
                controlType: 'select',
                oneLine: true,
                timeFormat: 'hh: mm tt',
                dateFormat: 'dd/mm/yy',
                changeMonth: true,
                changeYear: true,

                minDate: this.minimumDate === false ? '' : new Date(),
                onSelect: (value) => {
                    this.value = value;
                    this.dateChange.next(value);
                }
            })
                .datepicker('setDate', this.value);
        }
    }

    changedatewithvalue(prevoiusdate: string) {

        this.value = prevoiusdate;

        if (this.onlyDatePicker === true) {

            jQuery(this.el.nativeElement).datepicker({
                controlType: 'select'
                , oneLine: true,
                dateFormat: 'dd/mm/yy',
                changeMonth: true,
                changeYear: true,
                minDate: this.minimumDate === false ? '' : this.mindate,

                onSelect: (value) => {
                    this.value = value;
                    this.dateChange.next(value);
                }
            })
                .datepicker('setDate', this.value);
        }
        else {
            jQuery(this.el.nativeElement).datetimepicker({
                controlType: 'select',
                oneLine: true,
                timeFormat: 'hh: mm tt',
                dateFormat: 'dd/mm/yy',
                changeMonth: true,
                changeYear: true,

                minDate: this.minimumDate === false ? '' : new Date(),
                onSelect: (value) => {
                    this.value = value;
                    this.dateChange.next(value);
                }
            })
                .datepicker('setDate', this.value);
        }

    }


    Refreshdate() {


        this.value = '';
        if (this.onlyDatePicker === true) {
            jQuery(this.el.nativeElement).datepicker({
                controlType: 'select'
                , oneLine: true,
                dateFormat: 'dd/mm/yy',
                changeMonth: true,
                changeYear: true,
                minDate: this.minimumDate === false ? '' : new Date(),

                onSelect: (value) => {
                    this.value = value;
                    this.dateChange.next(value);
                }
            })
                .datepicker('setDate', this.value);
        }
        else {
            jQuery(this.el.nativeElement).datetimepicker({
                controlType: 'select',
                oneLine: true,
                timeFormat: 'hh: mm tt',
                dateFormat: 'dd/mm/yy',
                changeMonth: true,
                changeYear: true,

                minDate: this.minimumDate === false ? '' : new Date(),
                onSelect: (value) => {
                    this.value = value;
                    this.dateChange.next(value);
                }
            })
                .datepicker('setDate', this.value);
        }
    }


    refreshDatePicker() {



        this.$el.datepicker("destroy");

        this.Refreshdate();

    }
}