<div class="body-content-area tabcontent" id="link1">
  <div class="row">
      <div class="col-lg-9">
          <div class="row">
              <div class="col-lg-1 low-padding text-center">
                  <!-- <img *ngIf="this.SelectedMenu.Image!=null" src={{this.SelectedMenu.Image}} class="body-icon" alt="Icon"/> -->
              </div>
              <div class="col-lg-10 clear-padding">
                  <span class="page-title">Manage Attendance Modification Details Datewise</span>
                  <small class="page-description"></small>
              </div>
          </div>
      </div>
  </div>
  <div *ngIf="!(this.CanViewState)">
    <fieldset class="body-form-control" > 
    <legend class="legend--">Employee Attendance Details</legend>
      <form #EmployeeAttendanceForm="ngForm">
        <div class="row">
          <div class="col-lg-4">
            <div class="col-body-border-right">
              <div class="form-group row">
                <label class="col-sm-4 col-form-label clear-padding-right">Company</label>
                <div class="col-sm-7 clear-left-padding">
                  <select [(ngModel)]="EmployeeAttendanceModel.CompId" (ngModelChange)="onChangeCompany($event)" id ="CompanyId" class="form-control" name="CompanyId" #CompanyId="ngModel" >
                    <option [value]="0" [disabled]=true >Select Company</option>
                    <option *ngFor="let c of Companies" [value]="c.CompId">{{c.CompName}}</option>
                  </select>
                </div>
                <div class="required-validation" *ngIf="EmployeeAttendanceModel.CompId==0">Company is required</div>
              </div>
              <div class="form-group row">
                <label  class="col-sm-4 col-form-label clear-padding-right">Attendance Date</label>
                <div class="col-sm-7 clear-left-padding">
                  :&nbsp;&nbsp;<input type="date" style="width:90%;" name="AttendanceDate" [ngModel]="this.EmployeeAttendanceModel.AttendanceDate | date:'yyyy-MM-dd'" (ngModelChange)="AttendanceDateChanged($event)" #AttendanceDate="ngModel" required/>
                </div>
                <div class="required-validation" *ngIf="ValidDate">Please select Valid Date.</div>
              </div>
            </div>
          </div>
          <div class="col-lg-4">
            <div class="col-body-border-right">
              <div class="form-group row">
                <label class="col-sm-4 col-form-label clear-padding-right">Branch</label>
                <div class="col-sm-7 clear-left-padding">
                  <select [(ngModel)]="EmployeeAttendanceModel.BranchId" id ="BranchId" class="form-control" name="BranchId" #BranchId="ngModel" (ngModelChange)="onchangeBranch($event)">
                    <option [value]="0" [disabled]=true >Select Branch</option>
                    <option *ngFor="let b of Branches" [value]="b.BranchId">{{b.BranchName}}</option>
                  </select>
                </div>
                <div class="required-validation" *ngIf="EmployeeAttendanceModel.BranchId==0">Branch is required</div>  
              </div>
              <div class="form-group row">
              </div>
            </div>
          </div>
          <div class="col-lg-4">
            <div class="form-group row">
              <label class="col-sm-3 col-form-label clear-padding-right">Department</label>
              <div class="col-lg-8">
                <angular2-multiselect class="form-control" [data]="DepartmentList" [(ngModel)]="DepartmentselectedItems" name="DepartmentselectedItems" 
                  [settings]="DepartmentSettings" (onSelect)="onItemSelect($event)" (onDeSelect)="OnItemDeSelect($event)" (onSelectAll)="onSelectAll($event)"
                  (onDeSelectAll)="onDeSelectAll($event)">
                </angular2-multiselect>
              </div>
            </div>
            <div class="form-group row">
              <div class="col-sm-7 clear-left-padding">
                  <button type="submit" (click)="submitClick()" class="btn btn-primary save-button" [disabled]="!(EmployeeAttendanceForm.valid && this.DisableGenerate== true && EmployeeAttendanceModel.CompId > 0 && EmployeeAttendanceModel.BranchId > 0 && EmployeeAttendanceModel.AttendanceDate != null)">Generate</button>   
              </div>
            </div>
          </div>
        </div>
      </form>    
    </fieldset>

    <div *ngIf="NoRecordMsg" style="color: #dc3545; text-align: center;font-size: 15px; font-weight: bold;">No Record Found for Selected Date.</div>

    <fieldset class="body-form-control" *ngIf="EmployeeAttendance.length > 0"> 
      <legend class="legend--"></legend>
      <form #EmployeeAttendanceDailyDetails="ngForm">
        <table id="tblEmployeeAttendanceDailyDetails" class="table table-striped table-bordered text-center table-new row-border hover">
          <thead class="thead-blue text-center">
            <tr>
              <th style=" width: 6%; text-align: left;">Sl.No.</th>
              <th style=" width: 8%; text-align: left;">Code</th>
              <th style=" width: 16%; text-align: left;">Name</th>
              <th style=" width: 10%;">Date</th>
              <th style=" width: 8%; text-align: left;">Day</th>
              <th style=" width: 8%;">LogIn Time</th>
              <th style=" width: 8%;">LogOut Time</th>
              <th style=" width: 8%;">Working hrs</th>
              <th style=" width: 8%;">Status</th>
              <th style=" width: 10%;">Status Modified</th>
              <th style=" width: 10%;">Remarks</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let EmpAtt of EmployeeAttendance, let i = index">
              <td scope="row">{{i+1}}</td>
              <td style=" text-align: center;">{{EmpAtt.EmployeeCode}}</td>
              <td style=" text-align: left;">
                <a class="nav-link" href="javascript:void(0);" (click)="GetEmployeeLeaveBalanceDetails(EmpAtt.EmployeeId,EmpAtt.EmployeeCode,EmpAtt.EmployeeName,template)">
                  {{EmpAtt.EmployeeName}}
                </a>
              </td>
              <td style=" text-align: center;">
                <input type="text" hidden="hidden" [(ngModel)]="EmpAtt.RefId" id ="RefId_{{i}}" name="RefId_{{i}}" #RefId="ngModel">
                {{EmpAtt.AttendanceDate | date : "dd-MMM-yyyy"}}
              </td>
              <td style=" text-align: left;">{{EmpAtt.AttendanceDay}}</td>
              <td style=" text-align: center;">{{EmpAtt.InTime | date : "HH:mm:ss"}}</td>
              <td style=" text-align: center;">{{EmpAtt.OutTime | date : "HH:mm:ss"}}</td>
              <td style=" text-align: center;">{{EmpAtt.WorkingHourshow}}</td>
              <td style=" text-align: center;">{{EmpAtt.Status}}</td>
              <td>
                <select [(ngModel)]="EmpAtt.ModifiedStatus" id="ModifiedStatus_{{i}}" class="form-control" name="ModifiedStatus_{{i}}" #ModifiedStatus="ngModel">
                  <option [value]="0" [disabled]=true >Select Status</option>
                  <option *ngFor="let ATTS of AttendanceStatus" [value]="ATTS.StatusCode">{{ATTS.StatusCode}}</option>
                </select>
              </td>
              <td><input type="text" [(ngModel)]="EmpAtt.Remarks" id ="Remarks_{{i}}" name="Remarks_{{i}}" #Remarks="ngModel" class="form-control form-control-custom form-control-custom-new"></td>
            </tr>
          </tbody>
          <tfoot class="thead-blue text-center">
            <tr>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
            </tr>
          </tfoot>
        </table>
        <div class="row">
          <div class="col-lg-4">
          </div>
          <div class="col-lg-4">
            <div class="form-group row">
              <label class="col-sm-6 col-form-label text-center clear-padding-right" *ngIf="!(this.IsDataIsInViewState)">
                <button type="submit" class="btn btn-primary save-button" [disabled]="!(EmployeeAttendanceDailyDetails.valid && this.EmployeeAttendance.length > 0 && this.DisableSave==true)" (click)="UpdateEmployeeAttendance(template)">Save</button>  
              </label>
            </div>
          </div>
          <div class="col-lg-4">
            <div class="form-group row">
              <div class="col-sm-6 col-form-label text-center clear-padding-left">       
                <!-- <button type="button" tabindex="14" class="btn btn-danger cancle-button" (click)="RefreshPage()">Refresh Page</button> -->
              </div>
            </div>
          </div>
        </div>
      </form>
    </fieldset>

    <div style=" width: 1500px;">
      <ng-template #template>
        <div class="modal-header" style="background: #2a9bd7; max-width: 1500px;"> 
          <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()" style="color:#fff;">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div style="text-align: center; max-width: 1500px;">
          <div *ngIf="SaveMsg" style="color: #dc3545; text-align: center;font-size: 15px; font-weight: bold;">Attendance Modified Successfully</div>
          <div style=" width: 100%;" *ngIf="ShowPopUp">
            <fieldset class="body-form-control" *ngIf="EmployeeLeaveBalance.length > 0"> 
              <legend class="legend--">Leave Balance Details</legend>
              <div>
                <table>
                  <tr style="width: 100%;">
                    <td style="width: 10%;">Id :</td>
                    <td style="width: 20%; font-weight: bold;">{{this.EmployeeAttendanceModel.EmployeeCode}}</td>
                    <td style="width: 10%;">Name :</td>
                    <td style="width: 60%; font-weight: bold;">{{this.EmployeeAttendanceModel.EmployeeName}}</td>
                  </tr>
                  <tr>
                    <td colspan="4">&nbsp;</td>
                  </tr>
                </table>
              </div>
              <table id="tblEmployeeLeaveBalanceDetails" class="table table-striped table-bordered text-center table-new row-border hover">
                <thead class="thead-blue text-center">
                  <tr>
                    <th>Sl.No.</th>
                    <th>Leave Type</th>
                    <th>Leave Entitlement</th>
                    <th>Leave Taken During the Year</th>
                    <th>Opening Balance as of Today</th>
                    <th>Leave Applied or pending for Approval</th>
                    <th>Available Balance as of Today</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let EmpLeaveBal of EmployeeLeaveBalance, let i = index">
                    <td scope="row">{{i+1}}</td>
                    <td style=" text-align: left;">{{EmpLeaveBal.LeaveTypeName}}</td>
                    <td>{{EmpLeaveBal.NoOfLeave}}</td>
                    <td>{{EmpLeaveBal.LeaveTaken}}</td>
                    <td>{{EmpLeaveBal.BalanceLeave}}</td>
                    <td>{{EmpLeaveBal.LeaveApply}}</td>
                    <td>{{EmpLeaveBal.BalanceLeaveAfterApply}}</td>
                  </tr>
                </tbody>
                <tfoot class="thead-blue text-center">
                  <tr>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                  </tr>
                </tfoot>
              </table>
            </fieldset>
          </div>
          <button type="button" aria-label="Close" (click)="modalRef.hide()" style="background: #2a9bd7; outline: 0px none; border: 0px none; color: #fff; padding: 5px 10px; border-radius: 3px;">
            <span>Close</span>
          </button>             
        </div>
      </ng-template>
    </div>
  </div>
</div>