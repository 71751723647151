export class SalaryMapping 
{
    public RefId : number;
    public CompanyId : number;
    public GradeId : number;
    public SalaryComponentIds :string;
    public ComponentName :string;
    public IsActive : boolean;
    public DecisionId : number;
    public LevelId : number;
}