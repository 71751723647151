<div class="body-content-area tabcontent" id="link1">
  <div class="row">
      <div class="col-lg-9">
          <div class="row">
              <div class="col-lg-1 low-padding text-center">
                  <!-- <img *ngIf="this.SelectedMenu.Image!=null" src={{this.SelectedMenu.Image}} class="body-icon" alt="Icon"/> -->
              </div>
              <div class="col-lg-10 clear-padding">
                  <span class="page-title">Monthly EPF Report</span>
                  <small class="page-description"></small>
              </div>
          </div>
      </div>
  </div>
  <div *ngIf="!(this.CanViewState)">
    <fieldset class="body-form-control" > 
    <legend class="legend--">EPF Report</legend>
      <form #EPFForm="ngForm">
        <div class="row">
          <div class="col-lg-4">
            <div class="col-body-border-right">
              <div class="form-group row">
                <label class="col-sm-4 col-form-label clear-padding-right">Company</label>
                <div class="col-sm-7 clear-left-padding">
                  <select [(ngModel)]="SalaryModel.CompanyId" tabindex="1" (ngModelChange)="onChangeCompany($event)" id ="CompanyId" class="form-control" name="CompanyId" #CompanyId="ngModel" >
                    <option [value]="0" [disabled]=true >Select Company</option>
                    <option *ngFor="let c of Companies" [value]="c.CompId">{{c.CompName}}</option>
                  </select>
                </div>
                <div class="required-validation" *ngIf="SalaryModel.CompanyId==0">Company is required</div>
              </div>
              <div class="form-group row">
                <label class="col-sm-4 col-form-label clear-padding-right">Year</label>
                <div class="col-sm-7 clear-left-padding">
                  <select [(ngModel)]="SalaryModel.Year" tabindex="3" id ="Year" class="form-control" name="Year" #Year="ngModel">
                    <option [value]="0" [disabled]=true >Select Year</option>
                    <option *ngFor="let FY of FinancialYears" [value]="FY.Year">{{FY.Year}}</option>
                  </select>
                </div>
                <div class="required-validation" *ngIf="SalaryModel.Year==0">Year is required</div>
              </div>
            </div>
          </div>
          <div class="col-lg-4">
            <div class="col-body-border-right">
              <div class="form-group row">
                <label class="col-sm-4 col-form-label clear-padding-right">Branch</label>
                <div class="col-sm-7 clear-left-padding">
                  <select [(ngModel)]="SalaryModel.BranchId" tabindex="2" id ="BranchId" class="form-control" name="BranchId" #BranchId="ngModel" (ngModelChange)="onchangeBranch($event)">
                    <option [value]="0">Select Branch</option>
                    <option *ngFor="let b of Branches" [value]="b.BranchId">{{b.BranchName}}</option>
                  </select>
                </div>
              </div>
              <div class="form-group row">
                <label class="col-sm-4 col-form-label clear-padding-right">Month</label>
                <div class="col-sm-7 clear-left-padding">
                  <select [(ngModel)]="SalaryModel.MonthId" tabindex="4" id ="MonthId" class="form-control" name="MonthId" #MonthId="ngModel" >
                    <option [value]="0" [disabled]=true >Select Month</option>
                    <option *ngFor="let Mo of Months" [value]="Mo.MonthId">{{Mo.MonthName}}</option>
                  </select>
                </div>
                <div class="required-validation" *ngIf="SalaryModel.MonthId==0">Month is required</div>
              </div>
            </div>
          </div>
          <div class="col-lg-4">
            <div class="form-group row">
              <label class="col-sm-3 col-form-label clear-padding-right">Department</label>
              <div class="col-sm-7 clear-left-padding">
                <select [(ngModel)]="SalaryModel.DepartmentId" tabindex="15" id ="DepartmentId" class="form-control" name="DepartmentId" #DepartmentId="ngModel"    >
                <option [value]="0">Select Department</option>
                <option *ngFor="let d of Departments" [value]="d.DepartmentId">{{d.DepartmentName}}</option>
                </select>
              </div>
            </div>
            <div class="form-group row">
              <label class="col-sm-5 col-form-label clear-padding-right">
                <button type="submit" tabindex="6" (click)="GenerateReport()" class="btn btn-primary save-button" [disabled]="!(EPFForm.valid && SalaryModel.MonthId > 0 && SalaryModel.CompanyId > 0 && SalaryModel.Year > 0)">Generate</button>
              </label>
              <div class="col-sm-5 clear-left-padding" *ngIf="PFReports.length > 0">
                <button type="submit" class="btn btn-danger cancle-button" (click)="print()">Print Report</button>
              </div>
            </div>

            <!-- <div class="form-group row">
              <div class="col-sm-7 clear-left-padding">
                  <button type="submit" tabindex="6" (click)="GenerateReport()" class="btn btn-primary save-button" [disabled]="!(EPFForm.valid && SalaryModel.MonthId > 0 && SalaryModel.CompanyId > 0 && SalaryModel.Year > 0)">Generate</button>   
              </div>
            </div> -->
          </div>
        </div>
      </form>    
    </fieldset>

    <fieldset class="body-form-control" *ngIf="PFReports.length > 0">
      <legend class="legend--">EPF Report</legend>
      <div style="padding-left:5%; padding-right:5%" Id="EPFReport">
        <table border="1px" style="width:100%; font-size: 11px;">
          <tr>
            <td style="width:100%;">
              <table border="0" style="width:100%; font-size: 11px;">
                <tr>
                  <td style="width:80%; vertical-align: top;" >
                    <span style="font-weight: bold;font-size: 11px;">&nbsp;{{this.SalaryModel.CompanyName}}</span><br>
                    <span style="font-size: 10px;">&nbsp;{{this.SalaryModel.Address1}}, {{this.SalaryModel.Address2}},</span><br>
                    <span style="font-size: 10px;">&nbsp;{{this.SalaryModel.City}} - {{this.SalaryModel.PinCode}}</span>
                  </td>
                  <td style="padding-left:10%; width:20%;">
                    <!-- <img *ngIf="this.SalaryModel.LogoImage!=null" [src]="this.SalaryModel.LogoImage" style="width:100%;">&nbsp; -->
                  </td>
                </tr>
              </table>
            </td>
          </tr>
  
          <tr>
            <td>
              <table border="0">
                <tr>
                  <td style="font-weight: bold;font-size: 10px;">
                      &nbsp;EPF Statement for the month of {{this.SalaryModel.MonthName}},&nbsp;{{this.SalaryModel.Year}}
                  </td>
                </tr>
              </table>
            </td>
          </tr>

          <tr>
            <table border="1" id="tblEPDetails" style="width: 100%; font-size: 10px;">
              <thead>
                <tr>
                  <th style="width: 5%; text-align: center;">Sl No.</th>
                  <th style="width: 10%; text-align: center;">Code</th>
                  <th style="width: 20%; text-align: center;">Name</th>
                  <th style="width: 10%; text-align: center;">DOJ</th>
                  <th style="width: 20%; text-align: center;">Location</th>
                  <th style="width: 10%; text-align: center;">Gross Salary</th>
                  <th style="width: 15%; text-align: center;">Basic Salary</th>
                  <th style="width: 10%; text-align: center;">EPF Amount</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let PF of PFReports, let i = index">
                  <td scope="row">&nbsp;&nbsp;{{i+1}}</td>
                  <td style=" text-align: center;">&nbsp;&nbsp;<span>{{PF.EmployeeCode}}</span></td>
                  <td>&nbsp;&nbsp;<span>{{PF.EmployeeName}}</span></td>
                  <td style=" text-align: center;"><span>{{PF.JoingingDate | date : "dd-MMM-yyyy"}}</span></td>
                  <td>&nbsp;&nbsp;<span>{{PF.BranchName}}</span></td>
                  <td style=" text-align: right;"><span>{{PF.GrossSalary | number:'2.0-0'}}.00&nbsp;&nbsp;</span></td>
                  <td style=" text-align: right;"><span>{{PF.BasicAmount| number:'2.0-0'}}.00&nbsp;&nbsp;</span></td>
                  <td style=" text-align: right;"><span>{{PF.PFAmount| number:'2.0-0'}}.00&nbsp;&nbsp;</span></td>
                </tr>
              </tbody>
              <tfoot>
                <tr>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td style=" text-align: right; font-weight: bold;">Total Contribution :</td>
                  <td style=" text-align: right; font-weight: bold;">{{this.TotalEPFAmount| number:'2.0-0'}}.00&nbsp;&nbsp;</td>
                </tr>
              </tfoot>
            </table>
          </tr>
      
          <tr>
            <table border="1" style="width:100%;">
              <tr>
                <td style="text-align: center">
                  * Computer generated EPF Report.
                </td>
              </tr>
            </table>
          </tr>
        </table>
      </div>

    </fieldset>
  </div>
</div>