<perfect-scrollbar style="max-width: 1260px; max-height: 540px;" class="mukesh">

  <link rel="stylesheet" href="https://stackpath.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css">
  <div>
    <fieldset class="body-form-control" style="    border: none;">
      <app-dialog [(visible)]="this.SuccessStatus">
        <span class="successmsg">{{this.SuccessMessage}}</span>
      </app-dialog>



      <legend class="legend--">Lead Account Info</legend>
      <form #LeadName="ngForm" (ngSubmit)="saveCRMAccountInfo()">


        <div class="row">
          <div class="col-lg-4">
            <div class="col-body-border-right">
              <div class="form-group row">
                <label class="col-sm-5 col-form-label clear-padding-right">Gst No</label>

                <div class="col-sm-7 clear-left-padding">

                  <input type="text" [(ngModel)]="this.LeadAccountInfo.GstNo" tabindex="5" maxlength="15" id="GstNo"
                    name="GstNo" #GstNo="ngModel" class="form-control form-control-custom form-control-custom-new">
                </div>
              </div>

              <div class="form-group row">

              </div>
            </div>
          </div>

          <div class="col-lg-4">
            <div class="col-body-border-right">

              <div class="form-group row">
                <label class="col-sm-5 col-form-label clear-padding-right">Pan No.</label>
                <div class="col-sm-7 clear-left-padding">


                  <input type="text" [(ngModel)]="this.LeadAccountInfo.PanNo" tabindex="5" maxlength="10" id="GstNo"
                    name="PanNo" #PanNo="ngModel" class="form-control form-control-custom form-control-custom-new">
                </div>
              </div>

              <div class="form-group row">

              </div>


            </div>
          </div>




          <div class="col-lg-4">

            <div class="form-group row">


              <div class="col-sm-7 clear-left-padding">

                <button type="button" (click)="Uploaddocument(template)" class="btn btn-primary add_new_btn">
                  <span>Upload Documents</span>
                </button>

              </div>
            </div>




            <div class="form-group row">

              <label class="col-sm-6 col-form-label text-center clear-padding-right"
                *ngIf="this.CRM_LeadService.IsinViewState==false">
                <button type="submit" tabindex="32" required class="btn btn-primary save-button"> Save Next</button>
              </label>

              <div class="col-sm-6 col-form-label text-center">
                <button type="button" tabindex="33" (click)="RefreshPage()"
                  class="btn btn-danger cancle-button">Cancel</button>
              </div>
            </div>
          </div>
        </div>
      </form>
    </fieldset>


  </div>
</perfect-scrollbar>


<div style=" box-align: center; width: 1000px;">


  <ng-template #template>

    <div class="modal-header" style="background: #2a9bd7; max-width: 1000px;">

      <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()" style="color:#fff;">
        <span aria-hidden="true">X</span>
      </button>
    </div>
    <div style="text-align: center; max-width: 900px;padding-left: 10%">



      <fieldset class="body-form-control">

        <legend class="legend--">Add / Update Documents</legend>
        <form #ApprovedApplicationForm="ngForm">
          <div class="form-group">
            <label>File Uploads</label>
            <input type="file" (change)="fileuploads($event)" multiple class="form-control" style="height:100%">

          </div>
          <div lass="row">
            <table id="tblDocument" class="table table-striped table-bordered text-left table-new row-border hover">
              <thead class="thead-blue text-center">
                <tr>
                  <th style="width: 48%;">File Name</th>
                  <th style="width: 10%;">Document Type</th>
                  <th style="width: 10%;">Size</th>
                  <th style="width: 10%;">Extension</th>
                  <th style="width: 14%;">Action</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let Files of this.FileUploads, let i = index">
                  <td>

                    {{Files.DocumentActualName}}

                  </td>
                  <td>
                    {{Files.DocumentType}}
                  </td>
                  <td>
                    {{Files.DocumentSize}}
                  </td>
                  <td>
                    {{Files.DocumentExtension}}
                  </td>

                  <td>

                    <button *ngIf="Files.DocumentId > 0" type="button"
                      (click)="DocDownLoad(Files.DocumentId,Files.DocumentType)" class="table-remove-btn">
                      <!-- <i class="download"></i> -->
                      <i class="fa fa-download"></i>
                    </button>


                    <button *ngIf="Files.DocumentId == 0" type="button" (click)="DeleteFile(Files)"
                      class="table-remove-btn">
                      <i class="fas fa-trash-alt"></i>
                    </button>


                  </td>



                </tr>
              </tbody>
            </table>
            <!-- <pagination-controls (pageChange)="p = $event" style="float:right"></pagination-controls> -->

            <div class="row float-right">


              <div class=" col-auto text-center" *ngIf="this.CRM_LeadService.IsinViewState==false">
                <!-- <button  type="submit" tabindex="22" class="btn btn-primary save-button" [disabled]="!(EmployeeFamilyDetailForm.valid && this.DisableSave==true)" >Save & Next</button>   -->

                <button type="button" tabindex="22" class="btn btn-primary save-button" (click)="save()">Save </button>
              </div>
              <div class=" col-auto text-center">
                <button type="button" tabindex="23" class="btn btn-danger cancle-button"
                  (click)="CancleModelPopup()">Cancel</button>
              </div>
              <!-- <div class=" col-auto text-center">
                      <button  type="button"  tabindex="23" class="btn btn-primary pl-10 pr-10 pt-1 pb-1" (click)="AddnweRow()">Add Row </button>
                  </div> -->
            </div>
          </div>

        </form>
      </fieldset>
    </div>



  </ng-template>
</div>