import { Component, OnInit } from '@angular/core';
import { Model_CRM_Role } from '../../../shared/CRM/MICECRM/Model_CRM_Role';
import { Router } from '@angular/router';
import { Menu } from '../../../shared/menu.model';
import { CrmUserRollService } from '../../../shared/CRM/MICECRM/crm-user-roll.service';
import { Model_CRM_Roll_Menus } from '../../../shared/CRM/MICECRM/Crm_Roll_Menu';
import { CrmRollMenuService } from '../../../shared/CRM/MICECRM/crm-roll-menu.service';

@Component({
  selector: 'app-crm-menu-roll',
  templateUrl: './crm-Parentmenu-roll.component.html',
  styleUrls: ['./crm-Parentmenu-roll.component.css']
})
export class CrmParentMenuRollComponent implements OnInit {

  CrmRoles: Model_CRM_Role[] = [];
  SelectedRoll: Model_CRM_Role;

  Crm_Menu_Roll: Model_CRM_Roll_Menus[] = [];

  // Begning Permission Portion    
  SelectedMenu: Menu;
  IsDataIsInViewState: boolean = false;
  CanViewState: boolean = false;
  DisableSave: boolean = false;
  // End of the permission portion

  SuccessMessage: string = "";
  SuccessStatus: boolean = false;


  emp: any[] = [];
  Rolls: any[] = [];

  RoleMaster: any;
  RoleMasterId: any;

  moduleid: number;


  ModelCrmRollMenues: Model_CRM_Roll_Menus[];

  ModelCrmMenu: Model_CRM_Roll_Menus;

  UserRoleId: any;

  selectedRoles = [];

  RollSettings = {}
  selectedMenuRoll = {}

  constructor(private CrmURS: CrmUserRollService, private rout: Router, private CrmRMS: CrmRollMenuService
  ) {
    this.SelectedRoll = new Model_CRM_Role();
    this.ModelCrmMenu = new Model_CRM_Roll_Menus();
  }

  ngOnInit() {
    // this.rout.navigate([this.userService.selectedCrmMenu.RouteName]);
    //     //Begin Permission Portion
    // this.SelectedMenu=this.userService.SelectedMenu;
    // if(this.SelectedMenu !=undefined)
    // {
    //   localStorage.setItem('selectedmenu',JSON.stringify(this.SelectedMenu));
    //   this.rout.navigate([this.SelectedMenu.RouteName]);
    // }
    // else
    // {
    //   if(JSON.parse(localStorage.getItem('selectedmenu'))!=undefined)
    //   {
    //     this.SelectedMenu = JSON.parse(localStorage.getItem('selectedmenu'));
    //     this.rout.navigate([this.SelectedMenu.RouteName]);
    //   }
    // }
    // if(this.SelectedMenu.CanAdd)
    // {
    //   this.IsDataIsInViewState=false;
    // }
    // else
    // {
    //   this.IsDataIsInViewState=true;
    // }
    // if(this.SelectedMenu.CanView)
    // {
    //   this.CanViewState=false;
    // }
    // else
    // {
    //   this.CanViewState=true;
    // }
    //End of the peremission portion


    if (localStorage.getItem('SuccessfullMessage')) {
      this.SuccessStatus = true;
      this.SuccessMessage = localStorage.getItem('SuccessfullMessage')
      localStorage.removeItem('SuccessfullMessage');
    }

    this.GetcrmRolls();


    this.RollSettings = {
      singleSelection: true,
      text: 'Select Roles',
      enableSearchFilter: true,
      showCheckbox: false

    }



  }

  onItemSelectRoll(item: any) {

    let Rollid = item['id'];
    this.ModelCrmMenu.Role_Id = Rollid;
    this.DisableSave = true;
    this.GetCRMRollMainMenu(this.ModelCrmMenu.Role_Id);

  }
  OnItemDeSelectRoll(item: any) { }
  onSelectAllRoll(items: any) { }
  onDeSelectAllRoll(items: any) { }





  GetcrmRolls() {


    let t = localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.emp = [];
    this.CrmURS.GetCRMRoles(t).then(x => {
      this.RoleMaster = x;
      Object.keys(this.RoleMaster).forEach(key => {
        this.RoleMaster[key];
        let crmemptemp = new Model_CRM_Role();
        crmemptemp = this.RoleMaster[key];
        //this.RoleMasterModel.ModuleIds=SalMaptemp.ModuleIds;
        //this.CrmEmp.push(crmemptemp);
        this.CrmRoles.push(crmemptemp);
        this.Rolls.push({ "id": crmemptemp.Role_ID, "itemName": crmemptemp.RoleName });
      });

    });
  }


  GetCRMRollMainMenu(rollid: number) {


    this.moduleid = Number(localStorage.getItem("moduleidstor"));
    let t = localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.Crm_Menu_Roll = [];
    this.ModelCrmMenu.Role_Id = 0;
    this.CrmRMS.GetCRMMainMenuRoll(t, rollid, this.moduleid).then(x => {
      this.RoleMaster = x;

      Object.keys(this.RoleMaster).forEach(key => {

        this.RoleMaster[key];
        let crmemptemp = new Model_CRM_Roll_Menus();
        crmemptemp = this.RoleMaster[key];
        if (crmemptemp.CanAccess == true) {

          crmemptemp.ViewCanAccess = 'CanAccess';

        }

        this.Crm_Menu_Roll.push(crmemptemp);

      });

    });



  }


  OnChangeCanAccess(evt, i: number) {
    this.Crm_Menu_Roll[i];
    var target = evt.target;
    if (target.checked) {
      this.Crm_Menu_Roll[i].CanAccess = true;
      this.Crm_Menu_Roll[i].ViewCanAccess = "CanAccess";
    }
    else {
      this.Crm_Menu_Roll[i].CanAccess = false;
      this.Crm_Menu_Roll[i].ViewCanAccess = "CanAccess";
    }
  }



  addCrmRollMapping(): void {
    //this.IsNotBusy=true;
    this.DisableSave = true;

    let t = localStorage.getItem('userToken');
    t = 'Bearer' + t;


    if (this.Crm_Menu_Roll.length > 0) {

      this.CrmRMS.AddCrmUserRollMainMenu(t, this.Crm_Menu_Roll).subscribe(res => {

        this.UserRoleId = res;
        this.Crm_Menu_Roll = [];

        if (this.UserRoleId > 0) {
          this.DisableSave = false;
          localStorage.setItem('SuccessfullMessage', "Successfully Change");
          this.rout.navigateByUrl('ManageMICEAccess/ManageRoll', { skipLocationChange: true }).then(() => this.rout.navigate(["ManageMICEAccess/CrmParentMenuRoll"]));

        }
      });
    }

  }






}
