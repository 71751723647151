export class Model_CRM_Zone
    {
        
  
         
        public ZoneName : string;
        public CountryName  :string;
        public ZoneId:  number;
        public CountryId: number;  
        public IsActive :boolean;
        //public CountryCode:number;

    


        
    }       