<div class="body-content-area tabcontent" id="link1">
    <div class="row">
        <div class="col-lg-9">
            <div class="row">
                <div class="col-lg-1 low-padding text-center">
                    <!-- <img *ngIf="this.SelectedMenu.Image!=null" src={{this.SelectedMenu.Image}} class="body-icon" alt="Icon"/> -->
                </div>
                <div class="col-lg-10 clear-padding">
                    <span class="page-title">Manage Grade</span>
                    <small class="page-description"></small>
                </div>
            </div>
        </div>
    </div>

    <div *ngIf="!(this.CanViewState)">  
        <table id="tblGrade" datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" class="table table-striped table-bordered text-center table-new row-border hover">
            <thead class="thead-blue text-center">
                <tr>
                    <th>Sl No.</th>
                    <th>Grade Code</th>
                    <th class="text-left">Grade Name</th>
                    <th class="text-left">Level Name</th>
                    <th class="text-left">Decision Making</th>
                    <th class="text-left">Notice Period</th>
                    <th>Action</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let G of Grades, let i = index">
                    <td scope="row">{{i+1}}</td>
                    <td>{{G.GradeCode}}</td>
                    <td class="text-left">{{G.GradeName}}</td>
                    <td class="text-left">{{G.LevelName}}</td>
                    <td class="text-left">{{G.DecisionName}}</td>
                    <td class="text-left">{{G.NoticePeriod}} &nbsp; Days</td>
                    <td>
                        <button *ngIf="this.SelectedMenu.CanEdit" type="button"  (click)="GetGradeByid( G.GradeId )"class="table-edit-btn"><i class="fas fa-edit"></i> </button> 
                        <button *ngIf="this.SelectedMenu.CanDelete" type="button"  (click)="DeleteGradeByid( G.GradeId )"class="table-remove-btn"><i class="fas fa-trash-alt"></i></button>
                        <button *ngIf="this.SelectedMenu.CanView"   type="button" (click)="ViewGradeByid( G.GradeId  )" class="table-view-button"  ><i class="fas fa-eye"></i></button>
                    </td>
                </tr>
            </tbody>
        </table>
        
        <fieldset class="body-form-control">
            <app-dialog [(visible)]="this.SuccessStatus">
                <span class="successmsg">{{this.SuccessMessage}}</span>
            </app-dialog>
            
            <legend class="legend--">Grade Creation</legend>
            <form #GradeForm="ngForm" (ngSubmit)="saveGrade()">
            <div class="row">
                <div class="col-lg-4">
                <div  class="col-body-border-right">
                    <div class="form-group row" >
                    <label class="col-sm-4 col-form-label clear-padding-right">Grade Code</label>
                    <div class="col-sm-7 clear-left-padding">
                        <input type="text" [(ngModel)]="GradeModel.GradeCode" tabindex="1" maxlength="50" id ="GradeCode"  name="GradeCode" #GradeCode="ngModel" required class="form-control form-control-custom form-control-custom-new" >
                    </div>
                    <div *ngIf="GradeCode.invalid && (GradeCode.dirty || GradeCode.touched)" class="alert alert-danger">
                        <div *ngIf="GradeCode.errors.required">Grade Code is required.</div>
                        <div *ngIf="GradeCode.errors.maxlength">Grade Code is not more than 50 characters</div>
                    </div> 
                    </div>
                    <div class="form-group row">
                    <label  class="col-sm-4 col-form-label clear-padding-right">Level</label>
                    <div class="col-sm-7 clear-left-padding">
                        <select [(ngModel)]="GradeModel.LevelId" tabindex="4" id ="LevelId" class="form-control" (blur)="CheckName()" name="LevelId" #LevelId="ngModel" >
                            <option [value]="0" [disabled]=true >Select Level</option>
                            <option *ngFor="let L of Levels" [value]="L.LevelId">{{L.LevelName}}</option>
                        </select>
                    </div>
                    <div class="required-validation" *ngIf="GradeModel.LevelId==0">Level is required</div>  
                    </div>
                </div>  
                </div>
                <div  class="col-lg-4">
                <div  class="col-body-border-right">
                    <div class="form-group row">
                    <label class="col-sm-4 col-form-label clear-padding-right">Grade Name</label>
                    <div class="col-sm-7 clear-left-padding">
                        <input type="text" [(ngModel)]="GradeModel.GradeName" tabindex="2" maxlength="250" id ="GradeName" (blur)="CheckName()" name="GradeName" #GradeName="ngModel" required class="form-control form-control-custom form-control-custom-new" >
                    </div>
                    <div *ngIf="this.Namecheck.length > 0" class="alert alert-danger">Grade Name is exist</div>
                    <div *ngIf="GradeName.invalid && (GradeName.dirty || GradeName.touched)" class="alert alert-danger">
                        <div *ngIf="GradeName.errors.required">Grade Name is required.</div>
                        <div *ngIf="GradeName.errors.maxlength">Grade Name is not more than 250 characters</div>
                    </div> 
                    </div>
                    <div class="form-group row">
                        <label class="col-sm-4 col-form-label clear-padding-right">Notice Period</label>
                        <div class="col-sm-7 clear-left-padding">
                            <input type="text" [(ngModel)]="GradeModel.NoticePeriod" tabindex="5" pattern="[0-9]+" maxlength="4" id ="NoticePeriod" name="NoticePeriod" #NoticePeriod="ngModel" required class="form-control form-control-custom form-control-custom-new" >
                        </div>
                        <div *ngIf="NoticePeriod.invalid && (NoticePeriod.dirty || NoticePeriod.touched)" class="alert alert-danger">
                            <div *ngIf="NoticePeriod.errors.required">Notice Period is required.</div>
                            <div *ngIf="NoticePeriod.errors.maxlength">Notice Period is not more than 4 Digit</div>
                        </div> 
                    </div>
                </div>
                </div>
                <div  class="col-lg-4">
                <div class="form-group row">
                    <label  class="col-sm-4 col-form-label clear-padding-right">Decision Making</label>
                    <div class="col-sm-7 clear-left-padding">
                        <select  (ngModelChange)="onDecisionChange($event)" [(ngModel)]="GradeModel.DecisionId" tabindex="3" id ="DecisionId" class="form-control"  name="DecisionId" #DecisionId="ngModel" >
                            <option [value]="0" [disabled]=true >Select Decision Making</option>
                            <option *ngFor="let DM of Decisions" [value]="DM.DecisionId">{{DM.DecisionName}}</option>
                        </select>
                    </div>
                    <div class="required-validation"  *ngIf="GradeModel.DecisionId==0">Decision Making is required</div>
                </div>
                <div class="form-group row">
                    <label class="col-sm-6 col-form-label text-center clear-padding-right" *ngIf="!(this.IsDataIsInViewState)" >
                    <button type="submit" tabindex="6" class="btn btn-primary save-button" [disabled]="!(GradeForm.valid && this.CheckNameExiststatus==true && GradeModel.DecisionId !=0 && GradeModel.LevelId && this.DisableSave==true)" >Save</button>  
                    </label>
                    <div class="col-sm-6 col-form-label text-center">
                    <button type="button" tabindex="7" (click)="RefreshPage()" class="btn btn-danger cancle-button">Cancel</button>
                    </div>
                </div>
                </div>
            </div>
        </form>
        </fieldset>
    </div>
</div>