export class ModuleModel
{
    public ModuleId : number;
    public ModuleName : string;
    public IconUrl : string;
    public SmallIconUrl :string;
    public IsActive : boolean;
    

    public Logodoctype:string;

    public SmallIconUrlvalidfile:boolean;
    public IconUrlvalidfile:boolean;
    public SmallIconUrlLogoType:string;
    public IconUrlLogoType:string;
    public Islogochange: boolean;
    public IsSmalllogochange: boolean;

    public IconUrlImage :string;
    public SmallIconUrlImage :string;

}