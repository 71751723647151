export class KRAApplicationModel
{
    public RefId : number;
    public EmployeeId : number;
    public EmployeeCode : string;
    public EmployeeName : string;
    public EmployeeEmailId : string;
    public DesignationId : number;
    public DesignationName : string;
    public CompanyId : number;
    public CompanyName : string;
    public KRAMappingId : number;
    public KRAId : number;
    public KRAName : string;
    public RatingCriteria : string;
    public Achievement : string;
    public FinYear : string;
    public Weighted : number;
    public SelfRating : number;
    public RHRating : number;
    public FHRating : number;
    public RHId : number;
    public RHCode : string;
    public RHName : string;
    public RHEmailId : string;
    public FHId : number;
    public FHCode : string;
    public FHName : string;
    public FHEmailId : string;
    public KRASession : number;
    public KRAMasterIds : string;
    public IsActive : boolean;
    public FinalSubmit : boolean;
    public TotalWateges : number;
    public SelfRemarks : string;
    public RHRemarks : string;
    public FHRemarks : string;
    public SubmitDate : Date;
    public MasterRefId : number;
    public SessionId : number;

    public StartApplicationDate : Date;
    public EndApplicationDate : Date;
    public StartApprovalDate : Date;
    public EndApprovalDate : Date;
    public CurrentDate : Date;

    public FinalSelfRating : number;
    public FinalRHRating : number;
    public FinalFHRating : number;

    public SentMailid : string;
    public SentMailPassword : string;
    public HRISURL : string;
}