
import { ReturnStatement } from "@angular/compiler";
import { Model_CRM_B2B } from '../../shared/CRM/B2B/B2B_Model';
import { SELECTOR } from "ngx-bootstrap/modal/modal-options.class";



export class ValidationClass{

   // CRM_B2C_Model: Model_CRM_B2B; // Model Description.

    validdate: boolean = false;   // For Any Validation If Required. 



                  //Email Validation Starts 

        EmailIdCheck( Emailid): boolean
            {

                  
                 if (Emailid != undefined) 
                    {
                 let email = Emailid;
                
                 if (email != "") 
                      {
                         let EMAIL_REGEXP = /^[a-z0-9!#$%&'*+\/=?^_`{|}~.-]+@[a-z0-9]([a-z0-9-]*[a-z0-9])?(\.[a-z0-9]([a-z0-9-]*[a-z0-9])?)*$/i;

                         if (email != "" && (email.length < 5 || !EMAIL_REGEXP.test(email))) 

                         {
                          return false;
                         }

                else
                         {
                    return true;

                         }

                    }
        }   
    }

        
        //Email validation End





        // Phone Number Validation Starts

        PhoneNoCheck( PhoneNo ): boolean 
                {
                   // alert("OK");

            if(PhoneNo !=undefined)
            {
                let phone = PhoneNo;

        if (phone != "")
             {
              
            //let phone_regex = /^(?:(?:\+|0{0,2})91(\s*[\-]\s*)?|[0]?)?[789]\d{9}$/;
            let phone_regex = /^(0|[+91]{3})?[6-9][0-9]{9}$/;

       
            
            if (phone.length < 10)
            {
               return false;

            }
            else{
                if (phone.length >= 14)
                {
                    return false;

                }
                else
                {
                    
                    if (phone_regex.test(phone))
                    {
                        return true;
                    }
                    else
                    {
                        false;
                    }

                }


            }




        }
        else {
            return false;

             }   


         }  
         else{
             return false;
         }
    }

    // Phone Number Validation Ends. 




         // Date Of Birth Validation Starts.
    
        DateOfBirthValidation(date) :boolean
         {

                 var CurrentDate = new Date();
                 var CurrentYear = CurrentDate.getFullYear();
                 var currentMonth = CurrentDate.getMonth();
                 var CurrentDay = CurrentDate.getDate();

               var selecteedDate = new Date(date);
              var selectedYear = selecteedDate.getFullYear();
            var selectedMonth = selecteedDate.getMonth();
              var selectedDay = selecteedDate.getDate();
                 if (CurrentYear > selectedYear) {
                    return true;
                         }
                         else {
                                return false;
                                }

      

    }

                // Date Of Birth Validation Ends 

                // Date Change 

         dateChanged(date) :boolean {


        var CurrentDate = new Date();
        var CurrentYear = CurrentDate.getFullYear();
        var currentMonth = CurrentDate.getMonth();
        var CurrentDay = CurrentDate.getDate();

        var selecteedDate = new Date(date);
        var selectedYear = selecteedDate.getFullYear();
        var selectedMonth = selecteedDate.getMonth();
        var selectedDay = selecteedDate.getDate();
        alert(selectedYear);
        if (CurrentYear > selectedYear) {
            return  false;
        }
        else {
            if (currentMonth > selectedMonth) {
                return  false;
            }
            else {
                if (CurrentDay >= selectedDay) {
                    return false;
                }
                else {
                    return true;
                }
            }
        }
    }

                // Aniversary Date Validation Starts 

               AniversaryDateValidation(date1): boolean
                {          
                    

                  
                    //alert ("OK");
                     let CurrentDate = new Date();
                  let CurrentYear = CurrentDate.getFullYear();
                 let currentMonth = CurrentDate.getMonth();
                     let CurrentDay = CurrentDate.getDate();
                  
                     let selecteedDate = new Date(date1);
                  
                  let selectedYear = selecteedDate.getFullYear();
                     let selectedMonth = selecteedDate.getMonth()
                       let selectedDay = selecteedDate.getDate();
                   
                   if (CurrentYear == selectedYear) 
                             {   
                       
                       if (currentMonth > selectedMonth) 
                                    { 

                                        return true; 

                                    }
                                    else if (currentMonth = selectedMonth)
                                      {
                                    if (CurrentDay > selectedDay) 
                                          {
                                              return true; 

                                          }
                                          else
                                          {
                                              return false;

                                          }


                                      }
                                      else
                                      {
                                            return false;


                                      }    
                                    }
                   else if (CurrentYear> selectedYear)
                   {
                       return true;

                   }
                   else
                   {
                       return false;


                   }
                }

                // Aniversary Date Validation Ends
          
                


          
          // Pasport Validation Starts

                        PassportDateValidation(date): boolean
            {


                         var CurrentDate = new Date();
                       var CurrentYear = CurrentDate.getFullYear();
                         var currentMonth = CurrentDate.getMonth();
                              var CurrentDay = CurrentDate.getDate();

                              var selecteedDate = new Date(date);
                           var selectedYear = selecteedDate.getFullYear();
                               var selectedMonth = selecteedDate.getMonth();
                                   var selectedDay = selecteedDate.getDate();

                    if (((selectedYear - CurrentYear) <= 10) && (CurrentYear - selectedYear )<=0)  
                           {
                                        if (selectedYear == CurrentYear)
                                        {
                                             if(selectedMonth >=currentMonth )
                                             {
                                                if(selectedDay >=CurrentDay){

                                                    return true;
                                                }
                                                else
                                                {
                                                    return false;
                                                }


                                             }
                                             else
                                             {
                                                 return false;

                                             }
                                        }
                                        
                                            else
                                            {
                                               if(selectedYear == (CurrentYear+10))
                                               {
                                                    if(selectedMonth <= currentMonth)
                                                    {

                                                        return true;

                                                    }

                                                    else 
                                                    { 
                                                        return false;
                                                    }
    
                                               }

                                               else if (CurrentYear < selectedYear)
                                               {
                                                  
                                                        return true;
                                                   

                                                  
                                               
                                                }
            
                                               else
                                                {
                                                       if (CurrentYear = selectedYear) 
                                                       {
                                                           if(selectedMonth > currentMonth)
                                                           {
                                                               if(selectedDay > CurrentDay)
                                                               {
                                                                   return true;
                                                               }

                                                               else 
                                                               {
                                                                   return false;
                                                               }
                                                           }

                                                           else 
                                                           {
                                                               return false;
                                                           }
                                                           
                                                        }
                                                        

                                                else
                                                     {
                                                        return false;
                                                     }
                                               }
                                               
                                            }
                                        
                                        }
                                    }
    
                              // Pasport Validation Ends.



    // Next Follow Up Date Validation Starts

    NextFollowUpDate(date): boolean {


        var CurrentDate = new Date();
        var CurrentYear = CurrentDate.getFullYear();
        var currentMonth = CurrentDate.getMonth();
        var CurrentDay = CurrentDate.getDate();

        var selecteedDate = new Date(date);
        var selectedYear = selecteedDate.getFullYear();
        var selectedMonth = selecteedDate.getMonth();
        var selectedDay = selecteedDate.getDate();

        if (((selectedYear - CurrentYear) <= 10) && (CurrentYear - selectedYear) <= 0) {
            if (selectedYear == CurrentYear) {
                if (selectedMonth >= currentMonth) {
                    if (selectedDay >= CurrentDay) {

                        return true;
                    }
                    else {
                        return false;
                    }


                }
                else {
                    return false;

                }
            }

            else {
                if (selectedYear == (CurrentYear + 10)) {
                    if (selectedMonth <= currentMonth) {

                        return true;

                    }

                    else {
                        return false;
                    }

                }

                else if (CurrentYear < selectedYear) {

                    return true;




                }

                else {
                    if (CurrentYear = selectedYear) {
                        if (selectedMonth > currentMonth) {
                            if (selectedDay > CurrentDay) {
                                return true;
                            }

                            else {
                                return false;
                            }
                        }

                        else {
                            return false;
                        }

                    }


                    else {
                        return false;
                    }
                }

            }

        }
    }

                              // Next Follow Up Date Validation Ends.


   // Format date

       FormatDate(dateToFormat : Date)  : Date{
          
           let dateString: string = dateToFormat.toString();
           let days: number = parseInt(dateString.substring(8, 10));
           let months: number = parseInt(dateString.substring(5, 7));
           let years: number = parseInt(dateString.substring(0, 5));
           let goodDate: Date = new Date(months + "/" + days + "/" + years);
           goodDate.setDate(goodDate.getDate());
          
           return goodDate;



       }   

  //Format date
        }

