import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from '../../shared/user.service';
import { EmployeePortalService }  from '../../shared/HR_ADMIN/EmployeePortal/EmployeePortalService.service';
import { EmployeeLeaveBalanceModel } from '../../shared/HR_ADMIN/EmployeePortal/EmployeeLeaveBalanceModel.Model';
import { Menu } from '../../shared/menu.model';

@Component({
  selector: 'app-employee-leave-balance',
  templateUrl: './employee-leave-balance.component.html',
  styleUrls: ['./employee-leave-balance.component.css']
})
export class EmployeeLeaveBalanceComponent implements OnInit 
{
  EmployeeLeaveBalanceModel : EmployeeLeaveBalanceModel= new EmployeeLeaveBalanceModel();
  EmployeeLeaveBalance: EmployeeLeaveBalanceModel[]=[];
  LeaveBalance: any;
  userClaims: any;

  employee: any;

  Result : string;
  SuccessStatus:boolean=false;
  SuccessMessage: string ="";
  public tableWidget: any;
  selectedIndex : number;

  // Begning Permission Portion    
  SelectedMenu:Menu;
   validdate : boolean= false;
  // end of validation

  constructor(private EmployeePortalService: EmployeePortalService, private rout: Router, private userService: UserService){ }

  ngOnInit() 
  {
    // Begning Permission Portion 
    this.SelectedMenu=this.userService.SelectedMenu;
    if(this.SelectedMenu !=undefined)
    {
      localStorage.setItem('selectedmenu',JSON.stringify(this.SelectedMenu));
      this.rout.navigate([this.SelectedMenu.RouteName]);
    }
    else
    {
      if(JSON.parse(localStorage.getItem('selectedmenu'))!=undefined)
      {
        this.SelectedMenu = JSON.parse(localStorage.getItem('selectedmenu'));
        this.rout.navigate([this.SelectedMenu.RouteName]);
      }
    }
    this.GetEmployeeLeaveBalanceDetails()
  }

  GetEmployeeLeaveBalanceDetails()
  {
    this.EmployeeLeaveBalance=[];
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.EmployeePortalService.GetEmployeeLeaveBalanceByUserLogingId( t).then(x => 
    {
      this.LeaveBalance = x;
      Object.keys(this.LeaveBalance).forEach( key => 
      {
        this.LeaveBalance[key];
        let LeaveBalancetemp = new EmployeeLeaveBalanceModel();
        LeaveBalancetemp = this.LeaveBalance[key];
        LeaveBalancetemp = this.LeaveBalance[key];
        this.EmployeeLeaveBalanceModel.EmployeeCode=LeaveBalancetemp.EmployeeCode;
        this.EmployeeLeaveBalanceModel.EmployeeName=LeaveBalancetemp.EmployeeName;
        this.EmployeeLeaveBalance.push(LeaveBalancetemp);
      });
    });
  }
}
