import { Time } from "@angular/common";

export class WorkingHours 
{
    public WorkingHoursId : Number;
    public WorkingHoursName : string;
    public InTime : TimeRanges;
    public OutTime : TimeRanges;
    public WorkingDuration : number;
    public IsGraceTimeApplicable : boolean;
    public GraceTime : number;
    public HalfDayInTime : TimeRanges;
    public HalfDayOutTime : TimeRanges;
    public HalfDayStartTime : TimeRanges;
    public HalfDayEndTime : TimeRanges;
    public HalfDayDuration : number;
    public EarlyOutTime : TimeRanges;
    public IsActive : boolean;
    public IsFlexiable : boolean;
    public  EarlyMaxtime : number;
}