<app-dialog [(visible)]="this.SuccessStatus">
  <span class="successmsg">{{this.SuccessMessage}}</span>
</app-dialog>


<fieldset>



  <form>
    <div class=" row" style="padding:20px;">
      <div class="col-lg-4">
        <div class="col-body-border-right">

          <div class="form-group row">
            <label class="col-sm-4 col-form-label clear-padding-right">Opportunity Title </label>
            <div class="col-sm-8 clear-left-padding">
              <input type="text"
                [(ngModel)]="CRMOppertunityTitle" maxlength="500"
                tabindex="1" id="OppertunityTitle" name="OppertunityTitle" #OppertunityTitle="ngModel" required
                class="form-control form-control-custom form-control-custom-new">
              <div *ngIf="OppertunityTitle.invalid && (OppertunityTitle.dirty || OppertunityTitle.touched)"
                class="alert alert-danger">
                <div *ngIf="OppertunityTitle.errors.required">Enter Opportunity Title</div>
                <div *ngIf="OppertunityTitle.errors.maxlength"> Opportunity Title is not more than 500 characters
                </div>
              </div>

            </div>
          </div>




          <!-- <div class="form-group row">
            <label class="col-sm-4 col-form-label clear-padding-right">Contact Name
              <span class="required-validation">*</span>
            </label>
            <div class="col-sm-8 clear-left-padding">
              <select [(ngModel)]="this.CRM_OppertunityService.CrmCommonUseOportunityDetails.ContactId" tabindex="2"
                id="ContactId" class="form-control" name="ContactId" #ContactId="ngModel">
                <option [value]="0" [disabled]=true>Select Contact Name</option>
                <option *ngFor="let c of contactlist" [value]="c.ContactID">{{c.FirstName}}</option>
              </select>
            </div>
          </div> -->









          <div class="form-group row">
            <label class="col-sm-4 col-form-label clear-padding-right">Probability <br /> of Confirmation</label>
            <div class="col-sm-8 clear-left-padding">
              <input [(ngModel)]="CRMOportunityProbability" maxlength="5"
                tabindex="4" id="Probability" myNumberOnly name="Probability" #Probability="ngModel"
                class="form-control form-control-custom form-control-custom-new">



            </div><span class="required-validation">%</span>
          </div>


          <div class="form-group row">
            <label class="col-sm-4 col-form-label clear-padding-right"> Turn Over </label>
            <div class="col-sm-8 clear-left-padding">

              <input [(ngModel)]="CRMOpportunityAmount" maxlength="14"
                tabindex="7" id="Amount" name="Amount" #Amount="ngModel" myNumberOnly
                class="form-control form-control-custom form-control-custom-new">

            </div>
          </div>
          <div class="form-group row">
            <label class="col-sm-4 col-form-label clear-padding-right"> Destination </label>
            <div class="col-sm-8 clear-left-padding">
              <input type="text" [(ngModel)]="CRMOportunityDestination"
                maxlength="500" tabindex="13" id="Destination" name="Destination" #Destination="ngModel" required class="form-control form-control-custom
                                form-control-custom-new">

              <!-- <div *ngIf="Destination.invalid && (Destination.dirty || Destination.touched)" class="alert alert-danger">
                <div *ngIf="Destination.errors.required">Enter Destination</div>
                <div *ngIf="Destination.errors.maxlength"> Destination is not more than 150
                  characters
                </div>
              </div>
           -->
            </div>
          </div>



        </div>
      </div>

      <div class="col-lg-4">
        <div class="col-body-border-right">

          <div class="form-group row">
            <label class="col-sm-4 col-form-label clear-padding-right">Pipeline Status
              <span class="required-validation-star">*</span>

            </label>
            <div class="col-sm-8 clear-left-padding">
              <select [(ngModel)]="CRMOpportunityPipeLineId" tabindex="2"
                id="PipelineId" class="form-control" name="PipelineId" #PipelineId="ngModel">
                <option [value]="0" [disabled]=true>Select A Pipeline Status</option>
                <option *ngFor="let p of pipelinelist" [value]="p.PipelineId">{{p.PipeLineStatus}}</option>
              </select>

            </div>
          </div>



          <div class="form-group row">
            <label class="col-sm-4 col-form-label clear-padding-right"> Created Date</label>
            <div class="col-sm-8 clear-left-padding" tabindex="5">
              <!-- <input type="date" name="CreatedDate"
                [ngModel]="this.CRM_OppertunityService.CrmCommonUseOportunityDetails.CreatedDate | date:'yyyy-MM-dd'"
                (ngModelChange)="CreatedDates($event)" #CreatedDate="ngModel" /> -->

              <input type="text" [(value)]="CRMOportunityCreatedDateval"
                readonly="readonly" [minimumDate]="false" [onlyDatePicker]="true" id="CreatedDate_id" myDatepicker
                (dateChange)="CreateDate($event)"
                class="form-control form-control-custom form-control-custom-new ng-pristine ng-valid ng-touched" />



              <div *ngIf="!CRMOportunityCreatedDateIsValid"
                class="alert alert-danger">
                <div *ngIf="!CRMOportunityCreatedDateIsValid">Provide A
                  Valid Date </div>
              </div>

            </div>
          </div>

          <div class="form-group row">
            <label class="col-sm-4 col-form-label clear-padding-right">Types Of <br /> Business
              <!-- <span class="required-validation">*</span> -->

            </label>
            <div class="col-sm-8 clear-left-padding">
              <select [(ngModel)]="CRMOpportunityTypeOfBusiness"
                tabindex="2" id="BusinessId" class="form-control" name="BusinessId" #BusinessId="ngModel">
                <option [value]="0" [disabled]=true>Select The types Of Business</option>
                <option *ngFor="let b of businesstypelist" [value]="b.BusinessId">{{b.BusinessTypeName}}</option>
              </select>

            </div>
          </div>




          <div class="form-group row">
            <label class="col-sm-4 col-form-label clear-padding-right">Month Of<br />Business
              <!-- <span class="required-validation">*</span> -->

            </label>
            <div class="col-sm-8 clear-left-padding">
              <select [(ngModel)]="CRMOpportunityMonthId" tabindex="2"
                id="MonthId" class="form-control" name="MonthId" #MonthId="ngModel">
                <option [value]="0" [disabled]=true>Select the Month of business</option>
                <option *ngFor="let bm of businessmontlist" [value]="bm.MonthId">{{bm.MonthName}}</option>
              </select>

            </div>
          </div>



          <div class="form-group row">
            <label class="col-sm-4 col-form-label clear-padding-right">Choose File</label>
            <div class="col-sm-8 clear-left-padding" tabindex="8">
              <!-- <img *ngIf="this.CRM_OppertunityService.CrmCommonUseOportunityDetails.ChooseFile!=null"
                        [src]="this.CRM_OppertunityService.CrmCommonUseOportunityDetails.ChooseFile" style="width:175px;">
                      <input type="file" (change)="fileuploads($event.target.files)" tabindex="21" #Image accept="image/*"> -->
              <button type="button" (click)="Uploaddocument(template)" class="btn btn-primary add_new_btn">
                <span>Upload Documents</span>
              </button>
            </div>
          </div>

        </div>
      </div>


      <div class="col-lg-4">
        <div class="form-group row">
          <label class="col-sm-4 col-form-label clear-padding-right">Company Name
            <span class="required-validation-star">*</span>
          </label>
          <div class="col-sm-8 clear-left-padding select_dropdown">
            <!-- <select [(ngModel)]="this.CRM_OppertunityService.CrmCommonUseOportunityDetails.CompanyId" tabindex="3"
              id="CompanyId" class="form-control" name="CompanyId" #CompanyId="ngModel"
              (ngModelChange)="onCompanyChange($event)">
              <option [value]="0" [disabled]=true> Select Company </option>
              <option *ngFor="let c of companylist" [value]="c.LeadId">{{c.LeadName}} </option>
            </select> -->

            <angular2-multiselect tabindex="3" class="form-control" [data]="CompanyArray" [(ngModel)]="SelectedCompany"
              name="CompanyId" [settings]="this.CompanySettings" (onSelect)="onItemSelectCompany($event)"
              (onDeSelect)="OnItemDeSelectCompany($event)" (onSelectAll)="onSelectAllCompany($event)"
              (onDeSelectAll)="onDeSelectAllCompany($event)">
            </angular2-multiselect>

          </div>
        </div>


        <div class="form-group row">
          <label class="col-sm-4 col-form-label clear-padding-right"> Exp. Closure Date</label>
          <div class="col-sm-8 clear-left-padding" tabindex="6">
            <!-- <input type="date" name="CloseDate"
              [ngModel]="this.CRM_OppertunityService.CrmCommonUseOportunityDetails.CloseDate | date:'yyyy-MM-dd'"
              (ngModelChange)="ClosingDates($event)" #CloseDate="ngModel" /> -->

            <input type="text" [(value)]="CRMOportunityCloseDateval"
              readonly="readonly" [minimumDate]="true" [onlyDatePicker]="true" id="ClosedDate_id" myChangableDatepicker
              (dateChange)="Closedate($event)"
              [(mindate)]="CRMOportunityCreatedDateval"
              class="form-control form-control-custom form-control-custom-new ng-pristine ng-valid ng-touched" />



            <div *ngIf="!CRMOportunityClosDateIsValid"
              class="alert alert-danger"> Provide A Valid Date

            </div>
          </div>
        </div>

        <div class="form-group row">
          <label class="col-sm-4 col-form-label clear-padding-right">Number Of Pax
            <span class="required-validation"
              *ngIf="((CRMOpportunityNumberOfPax==undefined) ||(CRMOpportunityNumberOfPax==0))">
            </span>
          </label>

          <div class="col-sm-8 clear-left-padding">
            <!-- <input type="text" (keypress)="numberOnly($event)"
                      [(ngModel)]="this.CRM_OppertunityService.CRM_MoreInformation_ModelShare.NumberOfPax" minlength="1"
                      maxlength="4" tabindex="1" id="NumberOfPax" name="NumberOfPax" #NumberOfPax="ngModel" required class="form-control form-control-custom
                          form-control-custom-new"> -->
            <input [(ngModel)]="CRMOpportunityNumberOfPax" maxlength="9"
              tabindex="1" id="NumberOfPax" myNumberOnly name="NumberOfPax" #NumberOfPax="ngModel" NumberOn
              class="form-control form-control-custom form-control-custom-new">
            <!-- <Label
                      *ngIf="((this.CRM_OppertunityService.CRM_MoreInformation_ModelShare.NumberOfPax==undefined) ||(this.CRM_OppertunityService.CRM_MoreInformation_ModelShare.NumberOfPax==0))"
                      class="alert alert-danger">*</Label> -->


            <!-- <div *ngIf="NumberOfPax.invalid && (NumberOfPax.dirty || NumberOfPax.touched)" class="alert alert-danger">
                      <div *ngIf="NumberOfPax.errors.required">Enter Number Of Pax</div>
        
                      Enter Number Of Pax
                    </div> -->


          </div>
        </div>

        <div class="form-group row">
          <label class="col-sm-4 col-form-label clear-padding-right"> Description </label>
          <div class="col-sm-8 clear-left-padding" tabindex="9">
            <!-- <input type="text" [(ngModel)]="CRM_Oppertynity_Model.OppertunityDescription" maxlength="2500"
                        tabindex="29" id="OppertunityDescription" name="OppertunityDescription"
                        #OppertunityDescription="ngModel" class="form-control form-control-custom
                              form-control-custom-new"> -->

            <textarea id="OppertunityDescription" style="width:100%; height:50px;"
              (change)="OportunityDesription($event)">{{CRMOppertunityDescription}}</textarea>
          </div>
        </div>

        <div class="form-group row">
          <div class="col-sm-12 float_right">
            <div *ngIf="!CRMIsOppinViewstat" class="col-sm-5" style="display: inline-block;"
              tabindex="10">

              <button type="submit" id="submit" class="btn btn-primary save-button"
                [disabled]="!((CRMOpportunityPipeLineId!=0)&&(CRMOpportunityCompanyId!=0)&&(CRMOppertunityTitle.length>0))"
                (click)="saveCRMOppertunity()">Save</button>


            </div>
            <div class="col-sm-5" style="display: inline-block;">
              <button type="button" tabindex="11" (click)="RefreshPage()" class="btn btn-danger cancle-button">
                Cancel</button>
            </div>
          </div>
        </div>
      </div>



    </div>
  </form>
</fieldset>

<div style=" box-align: center; width: 1000px;">


  <ng-template #template>

    <div class="modal-header" style="background: #2a9bd7; max-width: 1000px;">

      <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()" style="color:#fff;">
        <span aria-hidden="true">X</span>
      </button>
    </div>
    <div style="text-align: center; max-width: 900px;padding-left: 10%">



      <fieldset class="body-form-control">

        <legend class="legend--">Add / Update Documents</legend>
        <div class="row">
          <div class="col-sm-12">
            <div class="form-group">

              <label>File Uploads</label>
              <input type="file" (change)="fileuploads($event)" multiple class="form-control" style="height:100%">
            </div>
          </div>
        </div>

        <!-- <div class=" col-auto text-center">
                        <button type="button" tabindex="23" class="btn btn-danger cancle-button"
                          (click)="RefreshPage()">Cancel</button>
                      </div> -->
        <!-- <div class=" col-auto text-center">
                              <button  type="button"  tabindex="23" class="btn btn-primary pl-10 pr-10 pt-1 pb-1" (click)="AddnweRow()">Add Row </button>
                          </div> -->

        <form #ApprovedApplicationForm="ngForm">
          <div lass="row">
            <table id="tblDocument" class="table table-striped table-bordered text-left table-new row-border hover">
              <thead class="thead-blue text-center">
                <tr>
                  <th style="width: 48%;">File Name</th>
                  <th style="width: 10%;">Document Type</th>
                  <th style="width: 10%;">Size</th>
                  <th style="width: 10%;">Extension</th>
                  <th style="width: 14%;">Action</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  *ngFor="let Files of CRMListOpportunityDocs, let i = index">
                  <td>

                    {{Files.DocumentActualName}}

                  </td>
                  <td>
                    {{Files.DocumentType}}
                  </td>
                  <td>
                    {{Files.DocumentSize}}
                  </td>
                  <td>
                    {{Files.DocumentExtension}}
                  </td>

                  <td>

                    <button *ngIf="Files.DocumentId > 0" type="button"
                      (click)="DocDownLoad(Files.DocumentId,Files.DocumentType)" class="table-remove-btn">
                      <!-- <i class="download"></i> -->
                      <i class="fa fa-download"></i>
                    </button>


                    <button *ngIf="Files.DocumentId == 0" type="button" (click)="DeleteFile(Files)"
                      class="table-remove-btn">
                      <i class="fas fa-trash-alt"></i>
                    </button>


                  </td>



                </tr>
                <tr>
                  <td colspan="5">

                    <div class=" col-sm-12 text-center" *ngIf="!CRMIsOppinViewstat">
                      <!-- <button  type="submit" tabindex="22" class="btn btn-primary save-button" [disabled]="!(EmployeeFamilyDetailForm.valid && this.DisableSave==true)" >Save & Next</button>   -->

                      <button *ngIf="CRMOppertunityId > 0"
                        type="button" tabindex="10" class="btn btn-primary save-button" (click)="save()">Save </button>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
            <!-- <pagination-controls (pageChange)="p = $event" style="float:right"></pagination-controls> -->


          </div>

        </form>
      </fieldset>
    </div>



  </ng-template>
</div>