import { Injectable,Inject } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';  
import { Http, Headers, RequestOptions, Response } from '@angular/http';  
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import 'rxjs/add/operator/toPromise';
import { Subscriber } from 'rxjs';
import { GlobalVariable  } from '../../../app.global';
import { ModuleModel } from './ModuleMasterModel.Model';
import { MenuMasterModel } from './MenuMasterModel.Model';
import { RoleMasterModel } from './RoleMasterModel.Model';
import { UserModel } from './UserModel.Model';
import { MenuUserMappingModel } from './MenuUserMappingModel.Model';

@Injectable()
export class UserMasterService 
{
  constructor(private http: HttpClient, private spinnerService: Ng4LoadingSpinnerService) 
  { }

  //Start Module Master
  GetModuleMaster(token)
  {
    const authHeader = new HttpHeaders();     
    authHeader.append('Content-Type', 'application/json');
    authHeader.append('Authorization', token);
    return new Promise((resolve, reject) =>
    {       
        setTimeout(()=>
        {
            this.http.get(GlobalVariable.BASE_API_URL +'/api/ModuleMaster/GetModuleMaster',{headers: authHeader})
            .subscribe(data => 
            {
                resolve(data)
            },
            error => 
            {
                reject(error);
            },);
        },3000);
    });
  }
  GetModuleMasterByRoleId(token, id:number)
  {
    const authHeader = new HttpHeaders();     
    authHeader.append('Content-Type', 'application/json');
    authHeader.append('Authorization', token);
    return new Promise((resolve, reject) =>
    {       
        setTimeout(()=>
        {
            this.http.get(GlobalVariable.BASE_API_URL +'/api/MenuUserMapping/GetModuleMenuByRoleId?RoleId='+id,{headers: authHeader})
            .subscribe(data => 
            {
                resolve(data)
            },
            error => 
            {
                reject(error);
            },);
        },3000);
    });
  }
  GetModuleMasterById(token, id:number ) 
  {
    this.spinnerService.show();
    const authHeader = new HttpHeaders();     
    authHeader.append('Content-Type', 'application/json');
    authHeader.append('Authorization', token);
    return new Promise((resolve, reject) => 
    {
      setTimeout(()=>
      {
        this.http.get(GlobalVariable.BASE_API_URL +'/api/ModuleMaster/GetModuleMasterById?ModuleId='+id,{headers: authHeader})
        .subscribe(data => 
        {
            this.spinnerService.hide();
            resolve(data)
        },
        error => 
        {
            this.spinnerService.hide();
            reject(error);
        },);
        },3000);
    });
  }

  AddModuleMaster( token ,ModuleMaster :ModuleModel ) 
  {
    const authHeader = new HttpHeaders();         
    authHeader.append('Content-Type', 'application/json');
    authHeader.append('Authorization', token);
    authHeader.append('Accept', 'application/json');
     return this.http.post(GlobalVariable.BASE_API_URL +'/api/ModuleMaster/Add', ModuleMaster ,{headers: authHeader} )
    .map(this.extractData)
    .catch(this.handleErrorObservable);
  }

  DeleteModuleMaster( token ,Moduleid : number) 
  {
    const authHeader = new HttpHeaders();         
    authHeader.append('Content-Type', 'application/json');
    authHeader.append('Authorization', token);
    authHeader.append('Accept', 'application/json');
    return this.http.put(GlobalVariable.BASE_API_URL +'/api/ModuleMaster/Delete', Moduleid ,{headers: authHeader} )
    .map(this.extractData)
    .catch(this.handleErrorObservable);
  }

  UpdateModuleMaster( token ,ModuleMaster :ModuleModel ) 
  {
    const authHeader = new HttpHeaders();         
    authHeader.append('Content-Type', 'application/json');
    authHeader.append('Authorization', token);
    authHeader.append('Accept', 'application/json');
    return this.http.put(GlobalVariable.BASE_API_URL +'/api/ModuleMaster/Update', ModuleMaster ,{headers: authHeader} )
    .map(this.extractData)
    .catch(this.handleErrorObservable);
  }

  CheckModuleName(token, ModuleName:string ) 
  {
    this.spinnerService.show();
    const authHeader = new HttpHeaders();     
    authHeader.append('Content-Type', 'application/json');
    authHeader.append('Authorization', token);
    return new Promise((resolve, reject) => 
    {
        setTimeout(()=>
        {
            this.http.get(GlobalVariable.BASE_API_URL +'/api/ModuleMaster/CheckModuleName?ModuleName='+ ModuleName,{headers: authHeader})
            .subscribe(data => 
            {
                this.spinnerService.hide();
                resolve(data)
            },
            error => 
            {
                this.spinnerService.hide();
                reject(error);
            },);
        },3000);
    });
  }
  //End Module Master
  //Start Menu Master
  GetMenuMaster(token)
  {
    const authHeader = new HttpHeaders();     
    authHeader.append('Content-Type', 'application/json');
    authHeader.append('Authorization', token);
    return new Promise((resolve, reject) =>
    {       
        setTimeout(()=>
        {
            this.http.get(GlobalVariable.BASE_API_URL +'/api/MenuMaster/GetMenuMaster',{headers: authHeader})
            .subscribe(data => 
            {
                resolve(data)
            },
            error => 
            {
                reject(error);
            },);
        },3000);
    });
  }

  GetMenuMasterForDDL(token)
  {
    const authHeader = new HttpHeaders();     
    authHeader.append('Content-Type', 'application/json');
    authHeader.append('Authorization', token);
    return new Promise((resolve, reject) =>
    {       
        setTimeout(()=>
        {
            this.http.get(GlobalVariable.BASE_API_URL +'/api/MenuMaster/GetMenuMasterForDDLParent',{headers: authHeader})
            .subscribe(data => 
            {
                resolve(data)
            },
            error => 
            {
                reject(error);
            },);
        },3000);
    });
  }

  GetMenuMasterById(token, id:number ) 
  {
    this.spinnerService.show();
    const authHeader = new HttpHeaders();     
    authHeader.append('Content-Type', 'application/json');
    authHeader.append('Authorization', token);
    return new Promise((resolve, reject) => 
    {
      setTimeout(()=>
      {
        this.http.get(GlobalVariable.BASE_API_URL +'/api/MenuMaster/GetMenuMasterById?MenuId='+id,{headers: authHeader})
        .subscribe(data => 
        {
            this.spinnerService.hide();
            resolve(data)
        },
        error => 
        {
            this.spinnerService.hide();
            reject(error);
        },);
        },3000);
    });
  }

  AddMenuMaster( token ,MenuMaster :MenuMasterModel ) 
  {
    const authHeader = new HttpHeaders();         
    authHeader.append('Content-Type', 'application/json');
    authHeader.append('Authorization', token);
    authHeader.append('Accept', 'application/json');
     return this.http.post(GlobalVariable.BASE_API_URL +'/api/MenuMaster/Add', MenuMaster ,{headers: authHeader} )
    .map(this.extractData)
    .catch(this.handleErrorObservable);
  }

  DeleteMenuMaster( token ,Menuid : number) 
  {
    const authHeader = new HttpHeaders();         
    authHeader.append('Content-Type', 'application/json');
    authHeader.append('Authorization', token);
    authHeader.append('Accept', 'application/json');
    return this.http.put(GlobalVariable.BASE_API_URL +'/api/MenuMaster/Delete', Menuid ,{headers: authHeader} )
    .map(this.extractData)
    .catch(this.handleErrorObservable);
  }

  UpdateMenuMaster( token ,MenuMaster :MenuMasterModel ) 
  {
    const authHeader = new HttpHeaders();         
    authHeader.append('Content-Type', 'application/json');
    authHeader.append('Authorization', token);
    authHeader.append('Accept', 'application/json');
    return this.http.put(GlobalVariable.BASE_API_URL +'/api/MenuMaster/Update', MenuMaster ,{headers: authHeader} )
    .map(this.extractData)
    .catch(this.handleErrorObservable);
  }

  CheckMenuName(token, MenuName:string ) 
  {
    this.spinnerService.show();
    const authHeader = new HttpHeaders();     
    authHeader.append('Content-Type', 'application/json');
    authHeader.append('Authorization', token);
    return new Promise((resolve, reject) => 
    {
        setTimeout(()=>
        {
            this.http.get(GlobalVariable.BASE_API_URL +'/api/MenuMaster/CheckMenuName?MenuName='+ MenuName,{headers: authHeader})
            .subscribe(data => 
            {
                this.spinnerService.hide();
                resolve(data)
            },
            error => 
            {
                this.spinnerService.hide();
                reject(error);
            },);
        },3000);
    });
  }

  GetMenuMasterForDDLByModuleId(token, id:number)
  {
    const authHeader = new HttpHeaders();     
    authHeader.append('Content-Type', 'application/json');
    authHeader.append('Authorization', token);
    return new Promise((resolve, reject) =>
    {       
        setTimeout(()=>
        {
            this.http.get(GlobalVariable.BASE_API_URL +'/api/MenuMaster/GetMenuMasterForDDLByModuleId?ModuleId='+id,{headers: authHeader})
            .subscribe(data => 
            {
                resolve(data)
            },
            error => 
            {
                reject(error);
            },);
        },3000);
    });
  }
  //End Module Master
  //Begin Role Master
  GetRoleMaster(token)
  {
    const authHeader = new HttpHeaders();     
    authHeader.append('Content-Type', 'application/json');
    authHeader.append('Authorization', token);
    return new Promise((resolve, reject) =>
    {       
      setTimeout(()=>
      {
        this.http.get(GlobalVariable.BASE_API_URL +'/api/RoleMaster/GetRoleMaster',{headers: authHeader})
        .subscribe(data => 
        {
          resolve(data)
        },
        error => 
        {
          reject(error);
        },);
      },3000);
    });
  }

  GetRoleMasterById(token, id:number ) 
  {
    this.spinnerService.show();
    const authHeader = new HttpHeaders();     
    authHeader.append('Content-Type', 'application/json');
    authHeader.append('Authorization', token);
    return new Promise((resolve, reject) => 
    {
      setTimeout(()=>{
      this.http.get(GlobalVariable.BASE_API_URL +'/api/RoleMaster/GetRoleMasterById?RoleId='+id,{headers: authHeader})
      .subscribe(data => 
      {
        this.spinnerService.hide();
        resolve(data)
      },
      error => 
      {
        this.spinnerService.hide();
        reject(error);
      },);
    },3000);
    });
  }

  AddRoleMaster( token ,RoleMaster :RoleMasterModel ) 
  {
    const authHeader = new HttpHeaders();         
    authHeader.append('Content-Type', 'application/json');
    authHeader.append('Authorization', token);
    authHeader.append('Accept', 'application/json');
     return this.http.post(GlobalVariable.BASE_API_URL +'/api/RoleMaster/Add', RoleMaster ,{headers: authHeader} )
    .map(this.extractData)
    .catch(this.handleErrorObservable);
  }

  DeleteRoleMaster( token ,RoleId : number) 
  {
    const authHeader = new HttpHeaders();         
    authHeader.append('Content-Type', 'application/json');
    authHeader.append('Authorization', token);
    authHeader.append('Accept', 'application/json');
    return this.http.put(GlobalVariable.BASE_API_URL +'/api/RoleMaster/Delete', RoleId ,{headers: authHeader} )
    .map(this.extractData)
    .catch(this.handleErrorObservable);
  }

  UpdateRoleMaster( token ,RoleMaster :RoleMasterModel ) 
  {
    const authHeader = new HttpHeaders();         
    authHeader.append('Content-Type', 'application/json');
    authHeader.append('Authorization', token);
    authHeader.append('Accept', 'application/json');
    return this.http.put(GlobalVariable.BASE_API_URL +'/api/RoleMaster/Update', RoleMaster ,{headers: authHeader} )
    .map(this.extractData)
    .catch(this.handleErrorObservable);
  }

  CheckRoleNameDuplicate(token, RoleName:string ) 
  {
    this.spinnerService.show();

    const authHeader = new HttpHeaders();     
    authHeader.append('Content-Type', 'application/json');
    authHeader.append('Authorization', token);
    return new Promise((resolve, reject) => 
    {
      setTimeout(()=>{
      this.http.get(GlobalVariable.BASE_API_URL +'/api/RoleMaster/CheckRoleMaster?RoleName='+ RoleName,{headers: authHeader})
      .subscribe(data => 
      {
        this.spinnerService.hide();
        resolve(data)
      },
      error => 
      {
        this.spinnerService.hide();
        reject(error);
      },);
    },3000);
    });
  }
  //End Role Master
  //Begin User Master

  //EmployeeDetails for ddl
  GetEmployeeDetailsForDDLUserCreation(token )
  {
    this.spinnerService.show();
    const authHeader = new HttpHeaders();     
    authHeader.append('Content-Type', 'application/json');
    authHeader.append('Authorization', token);
    return new Promise((resolve, reject) =>
    {
        setTimeout(()=>
        {
            this.http.get(GlobalVariable.BASE_API_URL +'/api/UserMaster/GetEmployeeDetailsForDDLUserCreation',{headers: authHeader})
            .subscribe(data => 
            {
                this.spinnerService.hide();
                resolve(data)
            },
            error => 
            {
                this.spinnerService.hide();
                reject(error);
            },);
        },3000);
    });
  }
  //end

  GetUserMaster(token)
  {
    const authHeader = new HttpHeaders();     
    authHeader.append('Content-Type', 'application/json');
    authHeader.append('Authorization', token);
    return new Promise((resolve, reject) =>
    {       
      setTimeout(()=>
      {
        this.http.get(GlobalVariable.BASE_API_URL +'/api/UserMaster/GetUserMaster',{headers: authHeader})
        .subscribe(data => 
        {
          resolve(data)
        },
        error => 
        {
          reject(error);
        },);
      },3000);
    });
  }

  GetUserMasterById(token, id:number ) 
  {
    this.spinnerService.show();

    const authHeader = new HttpHeaders();     
    authHeader.append('Content-Type', 'application/json');
    authHeader.append('Authorization', token);
    return new Promise((resolve, reject) => 
    {
      setTimeout(()=>{
      this.http.get(GlobalVariable.BASE_API_URL +'/api/UserMaster/GetUserMasterById?Id='+id,{headers: authHeader})
      .subscribe(data => 
      {
        this.spinnerService.hide();
        resolve(data)
      },
      error => 
      {
        this.spinnerService.hide();
        reject(error);
      },);
    },3000);
    });
  }

  AddUserMaster( token ,UserMaster :UserModel ) 
  {
    const authHeader = new HttpHeaders();         
    authHeader.append('Content-Type', 'application/json');
    authHeader.append('Authorization', token);
    authHeader.append('Accept', 'application/json');
     return this.http.post(GlobalVariable.BASE_API_URL +'/api/UserMaster/Add', UserMaster ,{headers: authHeader} )
    .map(this.extractData)
    .catch(this.handleErrorObservable);
  }

  DeleteUserMaster( token ,Id : number) 
  {
    const authHeader = new HttpHeaders();         
    authHeader.append('Content-Type', 'application/json');
    authHeader.append('Authorization', token);
    authHeader.append('Accept', 'application/json');
    return this.http.put(GlobalVariable.BASE_API_URL +'/api/UserMaster/Delete', Id ,{headers: authHeader} )
    .map(this.extractData)
    .catch(this.handleErrorObservable);
  }

  UpdateUserMaster( token ,UserMaster :UserModel ) 
  {
    const authHeader = new HttpHeaders();         
    authHeader.append('Content-Type', 'application/json');
    authHeader.append('Authorization', token);
    authHeader.append('Accept', 'application/json');
    return this.http.put(GlobalVariable.BASE_API_URL +'/api/UserMaster/Update', UserMaster ,{headers: authHeader} )
    .map(this.extractData)
    .catch(this.handleErrorObservable);
  }

  CheckUserIdDuplicate(token, UserId:string ) 
  {
    this.spinnerService.show();

    const authHeader = new HttpHeaders();     
    authHeader.append('Content-Type', 'application/json');
    authHeader.append('Authorization', token);
    return new Promise((resolve, reject) => 
    {
      setTimeout(()=>{
      this.http.get(GlobalVariable.BASE_API_URL +'/api/UserMaster/CheckUserMaster?UserId='+ UserId,{headers: authHeader})
      .subscribe(data => 
      {
        this.spinnerService.hide();
        resolve(data)
      },
      error => 
      {
        this.spinnerService.hide();
        reject(error);
      },);
    },3000);
    });
  }

  CheckEmployeeExists(token, EmployeeId:number ) 
  {
    this.spinnerService.show();

    const authHeader = new HttpHeaders();     
    authHeader.append('Content-Type', 'application/json');
    authHeader.append('Authorization', token);
    return new Promise((resolve, reject) => 
    {
      setTimeout(()=>{
      this.http.get(GlobalVariable.BASE_API_URL +'/api/UserMaster/CheckEmployeeExists?EmployeeId='+ EmployeeId,{headers: authHeader})
      .subscribe(data => 
      {
        this.spinnerService.hide();
        resolve(data)
      },
      error => 
      {
        this.spinnerService.hide();
        reject(error);
      },);
    },3000);
    });
  }
  //Password Change
  GetUserDetailsforPasswordChange(token)
  {
    const authHeader = new HttpHeaders();     
    authHeader.append('Content-Type', 'application/json');
    authHeader.append('Authorization', token);
    return new Promise((resolve, reject) =>
    {       
      setTimeout(()=>
      {
        this.http.get(GlobalVariable.BASE_API_URL +'/api/UserMaster/GetUserDetailsforPasswordChange',{headers: authHeader})
        .subscribe(data => 
        {
          resolve(data)
        },
        error => 
        {
          reject(error);
        },);
      },3000);
    });
  }
  
  UpdateUserIdPassword( token ,UserMaster :UserModel ) 
  {
    const authHeader = new HttpHeaders();         
    authHeader.append('Content-Type', 'application/json');
    authHeader.append('Authorization', token);
    authHeader.append('Accept', 'application/json');
    return this.http.put(GlobalVariable.BASE_API_URL +'/api/UserMaster/UpdatePassword', UserMaster ,{headers: authHeader} )
    .map(this.extractData)
    .catch(this.handleErrorObservable);
  }

  // PasswordResetMailFarward( token ,UserMaster :UserModel) 
  // {
  //     const authHeader = new HttpHeaders();         
  //     authHeader.append('Content-Type', 'application/json');
  //     authHeader.append('Authorization', token);
  //     authHeader.append('Accept', 'application/json');
  //     return this.http.post(GlobalVariable.BASE_API_URL +'/api/UserMaster/PasswordResetMailFarward', UserMaster ,{headers: authHeader} )
  //     .map(this.extractData)
  //     .catch(this.handleErrorObservable);
  // }

  

  //End User Master

  //Begin Menu User Mapping Model
  GetMenuUserMapping(token)
  {
    const authHeader = new HttpHeaders();     
    authHeader.append('Content-Type', 'application/json');
    authHeader.append('Authorization', token);
    return new Promise((resolve, reject) =>
    {       
      setTimeout(()=>
      {
        this.http.get(GlobalVariable.BASE_API_URL +'/api/MenuUserMapping/GetMenuUserMapping',{headers: authHeader})
        .subscribe(data => 
        {
          resolve(data)
        },
        error => 
        {
          reject(error);
        },);
      },3000);
    });
  }

  GetMenuUserMappingByRoleId(token, RoleId:number, MenuId:number ) 
  {
    //this.spinnerService.show();
    const authHeader = new HttpHeaders();     
    authHeader.append('Content-Type', 'application/json');
    authHeader.append('Authorization', token);
    return new Promise((resolve, reject) => 
    {
      setTimeout(()=>{
      this.http.get(GlobalVariable.BASE_API_URL +'/api/MenuUserMapping/GetMenuUserMappingByRoleId?RoleId='+ RoleId + '&MenuId=' + MenuId,{headers: authHeader})
      .subscribe(data => 
      {
        this.spinnerService.hide();
        resolve(data)
      },
      error => 
      {
        this.spinnerService.hide();
        reject(error);
      },);
    },3000);
    });
  }

  UpdateMenuUserMapping( token ,MenuUserMapping :MenuUserMappingModel[] ) 
  {
    const authHeader = new HttpHeaders();         
    authHeader.append('Content-Type', 'application/json');
    authHeader.append('Authorization', token);
    authHeader.append('Accept', 'application/json');
    return this.http.put(GlobalVariable.BASE_API_URL +'/api/MenuUserMapping/Update', MenuUserMapping ,{headers: authHeader} )
    .map(this.extractData)
    .catch(this.handleErrorObservable);
  }

  GetMenuByRoleId(token, id:number ,ModuleId : number) 
  {
    this.spinnerService.show();
    const authHeader = new HttpHeaders();     
    authHeader.append('Content-Type', 'application/json');
    authHeader.append('Authorization', token);
    return new Promise((resolve, reject) => 
    {
      setTimeout(()=>{
      this.http.get(GlobalVariable.BASE_API_URL +'/api/MenuUserMapping/GetMenuByRoleId?RoleId='+id +'&ModuleId='+ModuleId,{headers: authHeader})
      .subscribe(data => 
      {
        this.spinnerService.hide();
        resolve(data)
      },
      error => 
      {
        this.spinnerService.hide();
        reject(error);
      },);
    },3000);
    });
  }
  //End Menu User Mapping Model

  // Begin Main Menu User Mapping
  GetMainMenuUserMappingByRoleId(token, RoleId:number ,ModuleId : number) 
  {
    //this.spinnerService.show();
    const authHeader = new HttpHeaders();     
    authHeader.append('Content-Type', 'application/json');
    authHeader.append('Authorization', token);
    return new Promise((resolve, reject) => 
    {
      setTimeout(()=>{
      this.http.get(GlobalVariable.BASE_API_URL +'/api/MenuUserMapping/GetMainMenuUserMappingByRoleId?RoleId='+ RoleId + '&ModuleId=' + ModuleId ,{headers: authHeader})
      .subscribe(data => 
      {
        this.spinnerService.hide();
        resolve(data)
      },
      error => 
      {
        this.spinnerService.hide();
        reject(error);
      },);
    },3000);
    });
  }
  // End Main Menu User Mapping
  
  private extractData(res: Response) 
  {
    let body = res;
    return body || {};
  }

  private handleErrorObservable (error: Response | any) 
  {
    console.error(error.message || error);
    return Observable.throw(error.message || error);
  }
}
