<div class="body-content-area tabcontent" id="link1">
  <div class="row">
      <div class="col-lg-9">
          <div class="row">
              <div class="col-lg-1 low-padding text-center">
                  <!-- <img *ngIf="this.SelectedMenu.Image!=null" src={{this.SelectedMenu.Image}} class="body-icon" alt="Icon"/> -->
              </div>
              <div class="col-lg-10 clear-padding">
                  <span class="page-title">Manage Attendance Fine Rule</span>
                  <small class="page-description"></small>
              </div>
          </div>
      </div>
  </div>

  <div *ngIf="!(this.CanViewState)"> 
  <table id="tblAttendanceFineRule" datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" class="table table-striped table-bordered text-center table-new row-border hover">
      <thead class="thead-blue text-center">
          <tr>
              <th>Sl No.</th>
              <th class="text-left">Rule Name</th>
              <th class="text-left">Fine Name</th>
              <th>Min Days</th>
              <th>Max Days</th>
              <th>Fine Days</th>
              <th>Action</th>
          </tr>
      </thead>
      <tbody>
          <tr *ngFor="let AFR of AttendanceFineRules, let i = index">
              <td scope="row">{{i+1}}</td>
              <td class="text-left">{{AFR.FineRuleName}}</td>
              <td class="text-left">{{AFR.FineName}}</td>
              <td>{{AFR.MinDays}}</td>
              <td>{{AFR.MaxDays}}</td>
              <td>{{AFR.FineDays}}</td>
              <td>
                  <button type="button" *ngIf="this.SelectedMenu.CanEdit" (click)="GetManageAttendenceRulesByid( AFR.AttFineRuleId )" class="table-edit-btn"><i class="fas fa-edit"></i> </button>
                  <button type="button" *ngIf="this.SelectedMenu.CanDelete" (click)="DeleteAttendanceFineRuleByid( AFR.AttFineRuleId )" class="table-remove-btn"><i class="fas fa-trash-alt"></i> </button>
                  <button *ngIf="this.SelectedMenu.CanView" type="button" (click)="ViewManageAttendenceRulesByid( AFR.AttFineRuleId )" class="table-view-button" type="button" ><i class="fas fa-eye"></i></button>
                </td>
          </tr>
      </tbody>
  </table>
  
  <fieldset class="body-form-control">
    <app-dialog [(visible)]="this.SuccessStatus">
      <span class="successmsg">{{this.SuccessMessage}}</span>
    </app-dialog>
      
    <legend class="legend--">Attendance Fine Rule Creation</legend>
    <form #AttendanceFineRuleForm="ngForm" (ngSubmit)="saveAttendanceFineRule()">
      <div class="row">
        <div class="col-lg-4">
          <div  class="col-body-border-right">
            <div class="form-group row" >
              <label class="col-sm-4 col-form-label clear-padding-right">Rule Name</label>
              <div class="col-sm-7 clear-left-padding">
                <input type="text" [(ngModel)]="AttendanceFineRuleModel.FineRuleName" tabindex="1" maxlength="250" id ="FineRuleName"  name="FineRuleName" #FineRuleName="ngModel" required class="form-control form-control-custom form-control-custom-new" >
              </div>
              <div *ngIf="FineRuleName.invalid && (FineRuleName.dirty || FineRuleName.touched)" class="alert alert-danger">
                <div *ngIf="FineRuleName.errors.required">Fine Rule Name is required.</div>
                <div *ngIf="FineRuleName.errors.maxlength">Fine Rule Name is not more than 250 characters</div>
              </div> 
            </div>
            <div class="form-group row">
              <label  class="col-sm-4 col-form-label clear-padding-right">Max Days</label>
              <div class="col-sm-7 clear-left-padding">
                <input type="text" [(ngModel)]="AttendanceFineRuleModel.MaxDays" tabindex="4" maxlength="2" minlength="1" pattern="[0-9]+" id ="MaxDays" name="MaxDays"  #MaxDays="ngModel" required class="form-control form-control-custom form-control-custom-new" >
              </div>
              <div *ngIf="MaxDays.invalid && (MaxDays.dirty || MaxDays.touched)" class="alert alert-danger">
                <div *ngIf="MaxDays.errors.maxlength">Max Days is not more than 2 digit.</div>
                <div *ngIf="MaxDays.errors.minlength">Max Days is not less than 1 dogit.</div>
                <div *ngIf="MaxDays.errors?.pattern">Max Days should be valid.</div>
                </div>
            </div>
            <div class="form-group row">
              
            </div>
          </div>  
        </div>
        <div class="col-lg-4">
          <div  class="col-body-border-right">
            <div class="form-group row">
              <label class="col-sm-4 col-form-label clear-padding-right">Fine Name</label>
              <div class="col-sm-7 clear-left-padding">
                <select [(ngModel)]="AttendanceFineRuleModel.FineNameId" tabindex="2" id ="FineNameId" class="form-control" name="FineNameId" #FineNameId="ngModel">
                    <option [value]="0" [disabled]=true >Select Fine Name</option>
                    <option *ngFor="let F of FineNames" [value]="F.FineId">{{F.FineName}}</option>
                </select>
            </div>
            <div class="required-validation" *ngIf="AttendanceFineRuleModel.FineNameId == 0">Fine Name is required</div>
            </div>  
            <div class="form-group row" >
              <label class="col-sm-4 col-form-label clear-padding-right">Fine In Term</label>
              <div class="col-sm-7 clear-left-padding">
                <select [(ngModel)]="AttendanceFineRuleModel.AttFineInTermId" tabindex="5" (ngModelChange)="onChangeFineInterm($event)" id ="AttFineInTermId" class="form-control" name="AttFineInTermId" #AttFineInTermId="ngModel">
                    <option [value]="0" [disabled]=true >Select Fine In Term</option>
                    <option *ngFor="let AFIT of FineInTerms" [value]="AFIT.FineInTermId">{{AFIT.FineInTermName}}</option>
                </select>
            </div>
            <div class="required-validation" *ngIf="AttendanceFineRuleModel.AttFineInTermId == 0">Fine In Term is required</div>
            </div>
            <div class="form-group row" >
              
            </div>
          </div>
        </div>
        <div  class="col-lg-4">
          <div class="form-group row">
            <label class="col-sm-4 col-form-label clear-padding-right">Min Days</label>
            <div class="col-sm-7 clear-left-padding">
              <input type="text" [(ngModel)]="AttendanceFineRuleModel.MinDays" tabindex="3" maxlength="2" minlength="1" pattern="[0-9]+" id ="MinDays" name="MinDays"  #MinDays="ngModel" required class="form-control form-control-custom form-control-custom-new" > 
            </div>
            <div *ngIf="MinDays.invalid && (MinDays.dirty || MinDays.touched)" class="alert alert-danger">
              <div *ngIf="MinDays.errors.maxlength">Minimum Days is not more than 2 digit.</div>
              <div *ngIf="MinDays.errors.minlength">Minimum Days is not less than 1 dogit.</div>
              <div *ngIf="MinDays.errors?.pattern">Minimum Days should be valid.</div>
              </div>
          </div>
          <div *ngIf="(this.CanViewFineDay)" class="form-group row">
            <label  class="col-sm-4 col-form-label clear-padding-right">Fine Days</label>
            <div class="col-sm-7 clear-left-padding">
              <input type="text" [(ngModel)]="AttendanceFineRuleModel.FineDays" tabindex="6" id ="FineDays" maxlength="4" minlength="1" name="FineDays"  #FineDays="ngModel" required class="form-control form-control-custom form-control-custom-new" >  
            </div>
            <div *ngIf="FineDays.invalid && (FineDays.dirty || FineDays.touched)" class="alert alert-danger">
              <div *ngIf="FineDays.errors.maxlength">Fine Days is not more than 4 digit.</div>
              <div *ngIf="FineDays.errors.minlength">Fine Days is not less than 1 dogit.</div>
              <div *ngIf="FineDays.errors?.pattern">Fine Days should be valid.</div>
            </div>
          </div>
          <div class="form-group row">
            <label *ngIf="!(this.IsDataIsInViewState)" class="col-sm-6 col-form-label text-center clear-padding-right">
              <button type="submit" tabindex="8" class="btn btn-primary save-button" [disabled]="!(AttendanceFineRuleForm.valid && AttendanceFineRuleModel.AttFineInTermId!=0 && AttendanceFineRuleModel.FineInTermId!=0 && this.DisableSave==true)">Save</button>  
            </label>
            <div class="col-sm-6 col-form-label text-center">
              <button type="button" tabindex="9" (click)="RefreshPage()" class="btn btn-danger cancle-button">Cancel</button>
            </div>
          </div>
        </div>
      </div>
    </form>
  </fieldset>
  </div>
</div>