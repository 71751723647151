import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from '../../shared/user.service';
import { OrganizationService } from '../../shared/Organization/organization.service';
import { SalaryMasterService } from '../../shared/HR_ADMIN/Salary/SalaryMaster.service';
import { SalaryModel } from  '../../shared/HR_ADMIN/Salary/SalaryModel.Model';
import { FinancialYear } from  '../../shared/Organization/FinancialYear.Model';
import { Company } from  '../../shared/Organization/Company.Model';
import { Branch } from  '../../shared/Organization/Branch.Model';
import { Department } from  '../../shared/Organization/Department.Model';
import { Menu } from '../../shared/menu.model';

@Component({
  selector: 'app-pfreport',
  templateUrl: './pfreport.component.html',
  styleUrls: ['./pfreport.component.css']
})

export class PfreportComponent implements OnInit 
{
  SalaryModel : SalaryModel= new SalaryModel();
  PFReports: SalaryModel[]=[];
  report : any;
  Companies: Company[] = [];
  company: any;
  Branches: Branch[] = [];
  Branch: any;
  Departments: Department[] = [];
  Department: any;

  FinancialYears: FinancialYear[] = [];
  FinYear:any;
  Months: Company[] = [];
  Month: any;

  userClaims: any;
  Result : string;
  SuccessStatus:boolean=false;
  SuccessMessage: string ="";
  public tableWidget: any;
  SalaryMappingId : any;
  SalMap: any;
  base64textString : string;

  // Begning Permission Portion    
  SelectedMenu:Menu;
  IsDataIsInViewState : boolean=false;
  CanViewState : boolean=false;
  DisableSave : boolean=false;
  // End of the permission portion
  TotalEPFAmount : number=0;
  
  constructor(private OrganizationService: OrganizationService,private SalaryMasterService: SalaryMasterService,private rout: Router, private userService: UserService) { }
  
  ngOnInit() 
  {
    //Begin Permission Portion
    this.SelectedMenu=this.userService.SelectedMenu;
    if(this.SelectedMenu !=undefined)
    {
      localStorage.setItem('selectedmenu',JSON.stringify(this.SelectedMenu));
      this.rout.navigate([this.SelectedMenu.RouteName]);
    }
    else
    {
      if(JSON.parse(localStorage.getItem('selectedmenu'))!=undefined)
      {
        this.SelectedMenu = JSON.parse(localStorage.getItem('selectedmenu'));
        this.rout.navigate([this.SelectedMenu.RouteName]);
      }
    }     
    if(this.SelectedMenu.CanAdd)
    {
      this.IsDataIsInViewState=false;
    }
    else
    {
      this.IsDataIsInViewState=true;
    }
    if(this.SelectedMenu.CanView)
    {
      this.CanViewState=false;
    }
    else
    {
      this.CanViewState=true;
    }
    this.DisableSave=true;
    
    this.SalaryModel.Year=0;
    this.SalaryModel.MonthId=0;
    this.SalaryModel.CompanyId=0;
    this.SalaryModel.BranchId=0;
    this.SalaryModel.DepartmentId=0;
    this.getCompanies(); 
    this.getMonths();
    this.GetYear();
  }

  ngAfterViewInit(): void 
  {
    
  }

  getMonths() 
  {
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.Months=[];
    this.OrganizationService.GetMonthName( t).then(x => 
    {
      this.Month = x;
      Object.keys(this.Month).forEach( key => 
      {
        this.Month[key];
        let Monthtemp = new Company();
        Monthtemp = this.Month[key];
        this.Months.push(Monthtemp);
      });     
    });      
  }

  GetYear()
  {
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.FinancialYears=[];
    this.OrganizationService.GetYear( t).then(x => 
    {
      this.FinYear = x;
      Object.keys(this.FinYear).forEach( key => 
      {
        // tslint:disable-next-line:no-unused-expression
        this.FinYear[key];
        let FinYeartemp = new FinancialYear();
        FinYeartemp = this.FinYear[key];
        this.FinancialYears.push(FinYeartemp);
      });     
    });      
  }

  getCompanies()
  {
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.Companies=[];
    this.OrganizationService.GetCompany( t).then(x => 
    {
      this.company = x;
      Object.keys(this.company).forEach( key => 
      {
        // tslint:disable-next-line:no-unused-expression
        this.company[key];
        let Companytemp = new Company();
        Companytemp = this.company[key];
        this.Companies.push(Companytemp);
      });     
    });      
  }

  onChangeCompany(Companyid)
  {
    this.getBranches(Companyid);
    this.SalaryModel.BranchId=0
    this.SalaryModel.DepartmentId=0
  }

  getBranches(Companyid)
  {
    this.Branches=[];
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t
    this.OrganizationService.GetBranchByCompanyId( t,Companyid).then(x => 
    {
      this.Branch = x;
      Object.keys(this.Branch).forEach( key => 
      {
        this.Branch[key];
        let Branchtemp = new Branch();
        Branchtemp = this.Branch[key];
        this.Branches.push(Branchtemp);
      });     
    });      
  }

  onchangeBranch(Branchid)
  {
    if(Branchid >= 0)
    {
      this.GetDepartment(Branchid);
      this.SalaryModel.DepartmentId=0;
    }
  }
  
  GetDepartment(Branchid)
  {
    this.Departments = [];
    let t = localStorage.getItem('userToken');
    t = 'Bearer' + t
    if (this.Departments.length > 0) 
    {
      this.Departments.splice(0, this.Departments.length)
    }
    this.OrganizationService.GetDepartmentByBranchid(t, Branchid).then(x => 
    {
      this.Department = x;
      Object.keys(this.Department).forEach(key => 
      {
        this.Department[key];
        let Departmenttemp = new Department();
        Departmenttemp = this.Department[key];
        this.Departments.push(Departmenttemp);
      });
    });
  }

  GenerateReport()
  {
    this.TotalEPFAmount =0;
    this.PFReports=[];
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.SalaryMasterService.GetEPFReport( t,this.SalaryModel.Year,this.SalaryModel.MonthId,this.SalaryModel.CompanyId,this.SalaryModel.BranchId,this.SalaryModel.DepartmentId).then(x => 
    {
      this.PFReports=[];
      this.report = x;
      Object.keys(this.report).forEach( key => 
      {
        let PFtemp = new SalaryModel();
        PFtemp = this.report[key];    
        this.PFReports.push(PFtemp);
        this.SalaryModel.CompanyName=PFtemp.CompanyName;
        this.SalaryModel.Address1=PFtemp.Address1;
        this.SalaryModel.Address2=PFtemp.Address2;
        this.SalaryModel.City=PFtemp.City;
        this.SalaryModel.PinCode=PFtemp.PinCode;
        this.SalaryModel.Year=PFtemp.Year;
        this.SalaryModel.MonthName=PFtemp.MonthName;
        this.TotalEPFAmount =  Number(this.TotalEPFAmount) + (Number(PFtemp.PFAmount));
      });
    });
  }

  print(): void 
  {
    let printContents, popupWin;
    printContents = document.getElementById('EPFReport').innerHTML;
    popupWin = window.open('', '_blank', 'letf=0,top=0,width=2480px,height=3508px,toolbar=0, scrollbars=0,sta­tus=0');
    popupWin.document.open();
    popupWin.document.write(`
      <html>
        <head>
          <title>EPF Report</title>
          <style=" margin:25px 10px 15px 30px; font-family:Calibri;">
          
          </style>
        </head>
      <body onload="window.print();window.close()">${printContents}</body>
      </html>`
    );
    popupWin.document.close();
  }
}