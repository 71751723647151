<div class="body-content-area tabcontent" id="link1">
  <div class="row">
    <div class="col-lg-9">
      <div class="row">
        <div class="col-lg-1 low-padding text-center">
          <img *ngIf="this.SelectedMenu.Image!=null" src={{this.SelectedMenu.Image}} class="body-icon" alt="Icon" />
        </div>
        <div class="col-lg-10 clear-padding">
          <div>
            <span class="page-title">Employee Leave Balance Adjustment</span>
            <small class="page-description"></small>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="!(this.CanViewState)" style=" height:100%;">
    <app-dialog [(visible)]="this.SuccessStatus">
      <span class="successmsg">{{this.SuccessMessage}}</span>
    </app-dialog>
    <fieldset class="body-form-control">
      <legend class="legend--">Employee Details</legend>
      <form #EmployeeLeaveAdjustmentForm="ngForm">
        <div class="row">
          <div class="col-lg-4">
            <div class="col-body-border-right">
              <div class="form-group row">
                <label class="col-sm-4 col-form-label clear-padding-right">Company</label>
                <div class="col-sm-7 clear-left-padding">
                  <select [(ngModel)]="EmployeeLeaveBalanceModel.CompanyId" (ngModelChange)="onChangeCompany($event)"
                    tabindex="1" id="CompanyId" class="form-control" name="CompanyId" #CompanyId="ngModel">
                    <option [value]="0" [disabled]=true>Select Company</option>
                    <option *ngFor="let comp of Companies" [value]="comp.CompId">{{comp.CompName}}</option>
                  </select>
                </div>
                <div class="required-validation" *ngIf="EmployeeLeaveBalanceModel.CompanyId == 0">Company is required
                </div>
              </div>
              <div class="form-group row">
                <label class="col-sm-4 col-form-label clear-padding-right">Leave Type</label>
                <div class="col-sm-7 clear-left-padding">
                  <select [(ngModel)]="EmployeeLeaveBalanceModel.LeaveTypeId" (ngModelChange)="onChangeLeaveTypeId()"
                    id="LeaveTypeId" class="form-control" name="LeaveTypeId" #LeaveTypeId="ngModel">
                    <option [value]="0" [disabled]=true>Select Leave Type</option>
                    <option *ngFor="let LT of LeaveTypeMain" [value]="LT.LeaveTypeId">{{LT.LeaveTypeName}}</option>
                  </select>
                </div>
                <div class="required-validation" *ngIf="EmployeeLeaveBalanceModel.LeaveTypeId==0">Leave Type is required
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-4">
            <div class="col-body-border-right">
              <div class="form-group row">
                <label class="col-sm-4 col-form-label clear-padding-right">Branch</label>
                <div class="col-sm-7 clear-left-padding">
                  <select [(ngModel)]="EmployeeLeaveBalanceModel.BranchId" (ngModelChange)="onchangeBranch($event)"
                    tabindex="2" id="BranchId" class="form-control" name="BranchId" #BranchId="ngModel">
                    <option [value]="0">Select Branch</option>
                    <option *ngFor="let Bran of Branches" [value]="Bran.BranchId">{{Bran.BranchName}}</option>
                  </select>
                </div>
              </div>
              <!-- <div class="form-group row">
                  <label  class="col-sm-4 col-form-label clear-padding-right">Adjustment Type</label>
                  <div class="col-sm-4 col-form-label clear-padding-right">
                    <input type="radio" [value]="Credit" [checked]="EmployeeLeaveBalanceModel.AdjustmentTypevalue=='Credit'" (click)="OnChangeCredit($event)" id ="Credit" name="Credit">
                    Credit &nbsp; &nbsp;
                  </div>
                  <div class="col-sm-4 clear-left-padding">
                    <input type="radio" [value]="Debit" [checked]="EmployeeLeaveBalanceModel.AdjustmentTypevalue=='Debit'" (click)="OnChangeDebit($event)" id ="Debit" name="Debit">
                    Debit
                  </div>
                </div> -->
            </div>
          </div>
          <div class="col-lg-4">
            <div class="form-group row">
              <label class="col-sm-4 col-form-label clear-padding-right">Department</label>
              <div class="col-sm-7 clear-left-padding">
                <select [(ngModel)]="EmployeeLeaveBalanceModel.DepartmentId" tabindex="3"
                  (ngModelChange)="onchangeDepartment()" id="DepartmentId" class="form-control" name="DepartmentId"
                  #DepartmentId="ngModel">
                  <option [value]="0">Select Department</option>
                  <option *ngFor="let d of Departments" [value]="d.DepartmentId">{{d.DepartmentName}}</option>
                </select>
              </div>
            </div>
            <div class="form-group row" *ngIf="EmployeeLeaveDetails.length == 0">
              <label class="col-sm-6 col-form-label text-center clear-padding-right">
                <button type="submit" (click)="GetEmployeeLeaveBalanceDetailsForAdjustment()"
                  class="btn btn-primary save-button"
                  [disabled]="!(EmployeeLeaveAdjustmentForm.valid && EmployeeLeaveBalanceModel.LeaveTypeId != 0 && EmployeeLeaveBalanceModel.CompanyId != 0)">Generate</button>
              </label>
              <div class="col-sm-6 col-form-label text-center">
                <button type="button" (click)="RefreshPage()" class="btn btn-danger cancle-button">Cancel</button>
              </div>
            </div>
          </div>
        </div>
      </form>
    </fieldset>

    <fieldset class="body-form-control" *ngIf="EmployeeLeaveDetails.length > 0">
      <legend class="legend--">Employee Leave Balance Details</legend>
      <form #EmployeeLeaveBalanceFrom="ngForm">
        <div class="tablediv">
          <table id="EmployeeLeaveBalance"
            class="table table-striped table-bordered text-center table-new row-border hover" fixed-header>
            <thead class="thead-blue text-center" style="width: 100%; size: 10px;">
              <tr class="table-head" fixed-header>
                <thead>
                  <th style="width: 6%;">Sl No.</th>
                  <th style="width: 8%;">Code</th>
                  <th style="width: 15%;">Name</th>
                  <th style="width: 10%;">Leave Type</th>
                  <th style="width: 8%;">Leave Balance</th>
                  <th style="width: 8%;">Leave Apply</th>
                  <th style="width: 8%;">Actual Leave</th>
                  <th style="width: 13%;">Adjustment Type</th>
                  <th style="width: 8%;">No Of Leave</th>
                  <th style="width: 10%;">Remarks</th>
                  <th style="width: 6%;">
                    <input type="checkbox" name="all" (change)="checkAll($event)" />
                  </th>
                </thead>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let LDB of EmployeeLeaveDetails, let i = index">
                <td style="width: 6%;" scope="row">{{i+1}}</td>
                <td style="width: 8%;" class="text-left">
                  <span>{{LDB.EmployeeCode}}</span>
                  <input type="text" hidden="hidden" [(ngModel)]="LDB.EmployeeId" id="EmployeeId" name="EmployeeId"
                    #EmployeeId="ngModel">
                  <input type="text" hidden="hidden" [(ngModel)]="LDB.RefId" id="RefId" name="RefId" #RefId="ngModel">
                </td>
                <td style="width: 15%; text-align: left;">
                  <span>{{LDB.EmployeeName}}</span>
                </td>
                <td style="width: 10%;">
                  <span>{{LDB.LeaveTypeName}}</span>
                  <input type="text" hidden="hidden" [(ngModel)]="LDB.LeaveTypeId" id="LeaveTypeId" name="LeaveTypeId"
                    #LeaveTypeId="ngModel">
                </td>
                <td style="width: 8%;">
                  <span>{{LDB.BalanceLeave}}</span>
                </td>
                <td style="width: 8%;">
                  <span>{{LDB.LeaveApply}}</span>
                </td>
                <td style="width: 8%;" style=" background-color: rgb(243, 213, 247);">
                  <span>{{LDB.BalanceLeaveAfterApply}}</span>
                </td>
                <td style="width: 13%;">
                  Credit <input type="radio"
                    [checked]="EmployeeLeaveDetails[i].AdjustmentTypevalue=='Credit'" (click)="OnChangeCredit($event,i)"
                    id="Credit_{{i}}" name="Credit_{{i}}">
                    <!-- [value]="Credit" -->
                  &nbsp;&nbsp;&nbsp;&nbsp;
                  Debit <input type="radio"
                    [checked]="EmployeeLeaveDetails[i].AdjustmentTypevalue=='Debit'" (click)="OnChangeDebit($event,i)"
                    id="Debit_{{i}}" name="Debit_{{i}}">
                    <!-- [value]="Debit" -->
                </td>
                <td style="width: 8%;">
                  <input type="text" [(ngModel)]="LDB.NoOfLeave" id="NoOfLeave_{{i}}" name="NoOfLeave_{{i}}"
                    #NoOfLeave="ngModel" class="form-control form-control-custom form-control-custom-new">
                </td>
                <td style="width: 10%;">
                  <input type="text" [(ngModel)]="LDB.Remarks" id="Remarks_{{i}}" name="Remarks_{{i}}"
                    #Remarks="ngModel" class="form-control form-control-custom form-control-custom-new">
                </td>
                <td style=" text-align: center; width: 6%;">
                  <input type="checkbox" [checked]="LDB.Selected" id="Selected_{{i}}" name="Selected_{{i}}"
                    (change)="SelectedChange(i,$event)">
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="row">
          <div class="col-lg-4">
          </div>
          <div class="col-lg-4">
            <div class="form-group row">
              <label class="col-sm-6 col-form-label text-center clear-padding-right"
                *ngIf="!(this.IsDataIsInViewState)">
                <button type="submit" tabindex="12" class="btn btn-primary save-button"
                  [disabled]="!(EmployeeLeaveBalanceFrom.valid && this.EmployeeLeaveDetails.length > 0 && this.DisableSave==true)"
                  (click)="saveLeaveBalanceAdjustment(template)">Save</button>
              </label>
            </div>
          </div>
          <div class="col-lg-4">
            <div class="form-group row">
              <div class="col-sm-6 col-form-label text-center clear-padding-left">
                <button type="button" tabindex="14" class="btn btn-danger cancle-button" (click)="RefreshPage()">Refresh
                  Page</button>
              </div>
            </div>
          </div>
        </div>
      </form>
    </fieldset>

    <div style=" box-align: center;" class="new-modal">
      <ng-template #template>
        <div class="modal-header" style="background: #2a9bd7;">
          <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()"
            style="color:#fff;">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body" style="text-align: center;">
          <div style="color: #dc3545; text-align: center;font-size: 15px; font-weight: bold;">Leave Balance Adjustment
            Successfully</div>
          <button type="button" aria-label="Close" (click)="modalRef.hide()"
            style="background: #2a9bd7; outline: 0px none; border: 0px none; color: #fff; padding: 5px 10px; border-radius: 3px;">
            <span>OK</span>
          </button>
        </div>
      </ng-template>
    </div>

  </div>
</div>