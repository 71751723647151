<div class="body-content-area tabcontent" id="link1">
  <div class="row">
    <div class="col-lg-9">
      <div class="row">
        <div class="col-lg-1 low-padding text-center">


        </div>

        <div class="col-lg-10 clear-padding">
          <span class="page-title">ADD CRM CITY </span>
          <small class="page-description">It is a long established fact that a reader will be distracted</small>
        </div>
      </div>
    </div>
  </div>

  <div>
    <table id="tblCRMCity" datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger"
      class="table table-striped table-bordered text-center table-new row-border hover">
      <thead class="thead-blue text-center">

        <tr>
          <th>Sl No.</th>
          <th class="text-left">City</th>
          <th class="text-left">State</th>
          <th class="text-left">Zone</th>
          <th class="text-left">Country</th>


          <th>Action</th>
        </tr>

      </thead>

      <tbody>

        <tr *ngFor="let c of citylist, let i = index">
          <td scope="row">{{i+1}}</td>
          <td class="text-left">{{c.CityName}}</td>
          <td class="text-left">{{c.StateName}}</td>
          <td class="text-left">{{c.ZoneName}}</td>
          <td class="text-left">{{ c.CountryName}}</td>




          <td>
            <!-- <button *ngIf="this.SelectedMenu.CanEdit" type="button" (click)="GetCRMCityById( c.CityId )"
           class="table-edit-btn">
            <i class="fas fa-edit"></i>
          </button>
        
          <button *ngIf="this.SelectedMenu.CanDelete" type="button" (click)="DeleteCrmCity( c.CityId )" class="table-remove-btn">
            <i class="fas fa-trash-alt"></i>
          </button>
        
          <button *ngIf="this.SelectedMenu.CanView" type="button" (click)="ViewCRMCityById( c.CityId )" class="table-view-button"
            type="button">
            <i class="fas fa-eye"></i>
          </button> -->


            <button type="button" (click)="GetCRMCityById( c.CityId )" *ngIf="this.userService.selectedCrmMenu.CanEdit"
              class="table-edit-btn">
              <i class="fas fa-edit"></i>
            </button>

            <button type="button" (click)="DeleteCrmCity( c.CityId )" *ngIf="this.userService.selectedCrmMenu.CanDelete"
              class="table-remove-btn"> <i class="fas fa-trash-alt"></i> </button>

            <button type="button" (click)="ViewCRMCityById( c.CityId )" *ngIf="this.userService.selectedCrmMenu.CanView"
              class="table-view-button" type="button"> <i class="fas fa-eye"></i>
            </button>
          </td>


      </tbody>
    </table>

    <fieldset class="body-form-control">
      <app-dialog [(visible)]="this.SuccessStatus">
        <span class="successmsg">{{this.SuccessMessage}}</span>
      </app-dialog>



      <legend class="legend--">ADD CITY</legend>
      <form #CityName="ngForm" (ngSubmit)="saveCRMCity()">




        <div class="row">
          <div class="col-lg-4">
            <div class="col-body-border-right">
              <div class="form-group row">
                <label class="col-sm-4 col-form-label clear-padding-right"> COUNTRY </label>
                <div class="col-sm-7 clear-left-padding">


                  <angular2-multiselect tabindex="5" class="form-control" [data]="CountryArray"
                    [(ngModel)]="SelectedCountry" name="CountryId" [settings]="CountrySttings"
                    (onSelect)="onItemSelectCountry($event)" (onDeSelect)="OnItemDeSelectCountry($event)"
                    (onSelectAll)="onSelectAllCountry($event)" (onDeSelectAll)="onDeSelectAllCountry($event)">
                  </angular2-multiselect>


                </div>
                <div *ngIf="this.CRM_City_Model.CountryId==0" class="required-validation">Country Name required</div>
              </div>



            </div>
          </div>


          <div class="col-lg-4">


            <div class="form-group row">
              <label class="col-sm-4 col-form-label clear-padding-right"> ZONE </label>
              <div class="col-sm-7 clear-left-padding">


                <angular2-multiselect tabindex="5" class="form-control" [data]="ZoneArray" [(ngModel)]="SelectedZone"
                  name="ZoneId" [settings]="ZoneSettings" (onSelect)="onItemSelecZone($event)"
                  (onDeSelect)="OnItemDeSelectZone($event)" (onSelectAll)="onSelectAllZone($event)"
                  (onDeSelectAll)="onDeSelectAllZone($event)"></angular2-multiselect>


              </div>
              <div class="required-validation" *ngIf="CRM_City_Model.ZoneId==0">Zone Name required</div>
            </div>








          </div>



          <div class="col-lg-4">

            <div class="form-group row">
              <label class="col-sm-4 col-form-label clear-padding-right"> STATE </label>
              <div class="col-sm-7 clear-left-padding">

                <angular2-multiselect tabindex="5" class="form-control" [data]="StateArray"
                  [(ngModel)]="this.SelectedState" name="StateId" [settings]="this.StateSettings"
                  (onSelect)="onItemSelecState($event)" (onDeSelect)="OnItemDeSelectState($event)"
                  (onSelectAll)="onSelectAllState($event)" (onDeSelectAll)="onDeSelectAllState($event)">
                </angular2-multiselect>

              </div>
              <div class="required-validation" *ngIf="CRM_City_Model.StateId==0"> State Name required</div>
            </div>




          </div>
        </div>

        <div class="row">
          <div class="col-lg-4">
            <div class="form-group row">
              <label class="col-sm-4 col-form-label clear-padding-right">CITY NAME</label>
              <div class="col-sm-7 clear-left-padding">
                <input type="text" [(ngModel)]=" CRM_City_Model.CityName" tabindex="1" maxlength="250" id="CityName"
                  name="CityName" #CityName="ngModel" required
                  class="form-control form-control-custom form-control-custom-new">
              </div>

              <div *ngIf="CityName.invalid && (CityName.dirty || CityName.touched)" class="alert alert-danger">
                <div *ngIf="CityName.errors.required">City Name is required.</div>
                <div *ngIf="CityName.errors.maxlength">City Name is not more than 250 characters</div>
              </div>

            </div>
          </div>

          <div class="col-lg-4">
            <div class="form-group row">
            </div>
          </div>

          <div class="col-lg-4">
            <div class="form-group row">
              <div *ngIf="this.IsSavevisible" class="col-sm-5 col-form-label text-center">
                <button type="submit" id="submit" tabindex="4" class="btn btn-primary save-button"
                  *ngIf="!(this.IsDataIsInViewState)" [disabled]="!
                        (    (this.CRM_City_Model.CountryId!=0) &&
                        (this.CRM_City_Model.StateId!=0)&&
                       (this.CRM_City_Model.ZoneId!=0)&&
                       (this.CRM_City_Model.CityName.length>0)
                      )">Save</button>
              </div>
              <div class="col-sm-5 col-form-label text-center">
                <button type="button" tabindex="4" (click)="RefreshPage()"
                  class="btn btn-danger cancle-button">Cancel</button>
              </div>
            </div>
          </div>



        </div>


      </form>
    </fieldset>
  </div>
</div>