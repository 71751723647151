import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs/Subject';
import { DataTableDirective } from 'angular-datatables';
import { LevelService }  from '../../shared/HR_ADMIN/Lavels_Grades/Levels.service';
import { DecisionMaking } from  '../../shared/HR_ADMIN/Lavels_Grades/DecisionMaking.Model';
import { Level } from  '../../shared/HR_ADMIN/Lavels_Grades/Level.Model';
import { Grade } from  '../../shared/HR_ADMIN/Lavels_Grades/Grade.Model';
import { UserService } from '../../shared/user.service';
// Begning Confirmation
import { ConfirmationService } from '@jaspero/ng2-confirmations';
import { ResolveEmit } from '../../../app/resolve-emit';
import { ConfirmSettings } from '../../../app/confirm-settings';
// end of Confirmation
import { Menu } from '../../shared/menu.model';

@Component({
  selector: 'app-manage-grades',
  templateUrl: './manage-grades.component.html',
  styleUrls: ['./manage-grades.component.css']
})
export class ManageGradesComponent implements OnInit 
{
  GradeModel : Grade= new Grade();
  userClaims: any;
  Result : string;
  SuccessStatus:boolean=false;
  SuccessMessage: string ="";
  public tableWidget: any;
  Grades: Grade[] = [];
  Grade:any;
  GradeId : any;
  Decisions: DecisionMaking[] = [];
  Decision: any;
  Levels: Level[] = [];
  Level:any;
  tempGradeadd : Grade =new Grade();
  // Begning Permission Portion    
  SelectedMenu:Menu;
  IsDataIsInViewState : boolean=false;
  CanViewState : boolean=false;
  DisableSave : boolean=false;
  // End of the permission portion
  //check for duplicate Name
  CheckNameExiststatus : boolean=null;  
  CheckNamestatusedit : boolean =false;
  Namecheck : string ="";
  checkName : any;
  checkNameval : string ="";
  //check for duplicate Name

  constructor(private LevelService: LevelService, private rout: Router,
     private _confirmation: ConfirmationService,  private userService: UserService) { }

  @ViewChild(DataTableDirective, {static: false})
  setDtOptions: DataTables.Settings = {};
  cardDtOptions: DataTables.Settings = {};
  dtElement: DataTableDirective;
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();

  ngOnInit() 
  {
    // Begning Permission Portion 
    this.SelectedMenu=this.userService.SelectedMenu;
    if(this.SelectedMenu !=undefined)
    {
      localStorage.setItem('selectedmenu',JSON.stringify(this.SelectedMenu));
      this.rout.navigate([this.SelectedMenu.RouteName]);
    }
    else
    {
      if(JSON.parse(localStorage.getItem('selectedmenu'))!=undefined)
      {
        this.SelectedMenu = JSON.parse(localStorage.getItem('selectedmenu'));
        this.rout.navigate([this.SelectedMenu.RouteName]);
      }
    }
    if(this.SelectedMenu.CanAdd)
    {
     this.IsDataIsInViewState=false;
    }
    else
    {
      this.IsDataIsInViewState=true;
    }
    if(this.SelectedMenu.CanView)
    {
      this.CanViewState=false;
    }
    else
    {
      this.CanViewState=true;
    }
    // End of the permission portion
    this.DisableSave=true;
    this.getDecisionMaking();
    this.GradeModel.DecisionId=0;
    if(localStorage.getItem('SuccessfullMessage'))
    {
      this.SuccessStatus=true;
      this.SuccessMessage =localStorage.getItem('SuccessfullMessage')
      localStorage.removeItem('SuccessfullMessage');
    }
  }

  settings: ConfirmSettings | any = 
  {
    overlay: true,
    overlayClickToClose: true,
    showCloseButton: true,
    confirmText: 'Yes',
    declineText: 'No'
  };
  
  ngAfterViewInit(): void 
  {
    this.getGrades();
    this.dtOptions = 
    {
      pagingType: 'full_numbers',
      pageLength: 3
    };
  }

  getDecisionMaking() 
  {
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.LevelService.GetDecisionMaking( t).then(x => 
    {
      this.Decision = x;
       Object.keys(this.Decision).forEach( key => 
      {
        this.Decision[key];
        let Decisiontemp = new DecisionMaking();
        Decisiontemp = this.Decision[key];
        this.Decisions.push(Decisiontemp);
      });
    });      
  }

  getGrades()
  {
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.LevelService.GetGrade( t).then(x => 
    {
      this.Grade = x;
      Object.keys(this.Grade).forEach( key => 
      {
        this.Grade[key];
        let Gradetemp = new Grade();
        Gradetemp = this.Grade[key];
        this.Grades.push(Gradetemp);
      });
      this.dtTrigger.next();
    });
  }

  onDecisionChange(Decisionid) 
  {
    if(Decisionid!=0)
    {
      this.getLevels(Decisionid);
      this.GradeModel.LevelId=0;
    }
    else
    {
      this.getLevels(Decisionid);
      this.GradeModel.LevelId=0;
    }
  }

  getLevels( Decisionid) 
  {
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t
    if(this.Levels.length>0)
    {
      this.Levels.splice(0, this.Levels.length);
    }
    this.LevelService.GetLevelByDecisionid( t,Decisionid).then(x => 
    {
      this.Level = x;
      Object.keys(this.Level).forEach( key => 
      {
        this.Level[key];
        let Leveltemp = new Level();
        Leveltemp = this.Level[key];
        this.Levels.push(Leveltemp);
      });     
    });      
  }

  GetGradeByid(id) 
  {
    // begning of the permission portion
    this.IsDataIsInViewState=false;
    // end of the permission portion
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.LevelService.GetGradeByid( t,id).then(x => 
    {
      this.Grade = x;
      Object.keys(this.Grade).forEach( key => 
      {
        this.Grade[key];
        let Gradetemp = new Grade();
        Gradetemp = this.Grade[key];
        this.GradeModel.GradeId=Gradetemp.GradeId;
        this.GradeModel.GradeCode=Gradetemp.GradeCode;
        this.GradeModel.GradeName=Gradetemp.GradeName;
        this.GradeModel.DecisionId=Gradetemp.DecisionId;
        this.GradeModel.LevelId=Gradetemp.LevelId;
        this.GradeModel.NoticePeriod=Gradetemp.NoticePeriod;
      });
      this.getLevels(this.GradeModel.DecisionId);
      // Begin for check Name duplication
      this.checkNameval= this.GradeModel.GradeName;
      this.CheckNamestatusedit=true;
      this.CheckNameExiststatus =true;
      this.Namecheck=""
      // End for check Name duplication
    });
  }

  ViewGradeByid(id) 
  {
    // begning of the permission portion
    this.IsDataIsInViewState=true;
    // end of the permission portion
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.LevelService.GetGradeByid( t,id).then(x => 
    {
      this.Grade = x;
      Object.keys(this.Grade).forEach( key => 
      {
        this.Grade[key];
        let Gradetemp = new Grade();
        Gradetemp = this.Grade[key];
        this.GradeModel.GradeId=Gradetemp.GradeId;
        this.GradeModel.GradeCode=Gradetemp.GradeCode;
        this.GradeModel.GradeName=Gradetemp.GradeName;
        this.GradeModel.DecisionId=Gradetemp.DecisionId;
        this.GradeModel.LevelId=Gradetemp.LevelId;
        this.GradeModel.NoticePeriod=Gradetemp.NoticePeriod;
      });
      this.getLevels(this.GradeModel.DecisionId);
      // Begin for check Name duplication
      this.checkNameval= this.GradeModel.GradeName;
      this.CheckNamestatusedit=true;
      this.CheckNameExiststatus =true;
      this.Namecheck=""
      // End for check Name duplication
    });
  }
     
  saveGrade() :void
  {
    this.DisableSave=false;
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.GradeModel.IsActive=true;
    if(this.GradeModel.GradeId > 0)
    {
      this.LevelService.UpdateGrade(t ,this.GradeModel).subscribe(res=>
      {
        this.GradeId=res;
        this.GradeModel.GradeId=0;  
        if(this.GradeId>0)
        {
          localStorage.setItem('SuccessfullMessage', "Update Successfully");
          this.rout.navigateByUrl('/Levels/ManageDescitionMakingLevels', {skipLocationChange: true}).then(()=>this.rout.navigate(["/Levels/ManageGrades"]));
        }
      });                  
    }
    else
    {
      this.LevelService.AddGrade(t ,this.GradeModel).subscribe(res=>
      {
        this.GradeId=res;
        this.GradeModel.GradeId=0;              
        if(this.GradeId>0)
        {
          localStorage.setItem('SuccessfullMessage', "Save Successfully");
          this.rout.navigateByUrl('/Levels/ManageDescitionMakingLevels', {skipLocationChange: true}).then(()=>this.rout.navigate(["/Levels/ManageGrades"]));
        }
      }); 
    }
  }

  DeleteGradeByid(GradeId : number) :void
  {    
    this._confirmation.create('Confirm to Deleate', 'Press Yes to Delete', this.settings)
    .subscribe((ans: ResolveEmit) => {
      if (ans.resolved == true) {

        let t =  localStorage.getItem('userToken');
      t = 'Bearer' + t;
      this.GradeModel.IsActive=true;
      if( GradeId > 0)
      {
        this.LevelService.DeleteGrade(t ,GradeId).subscribe(res=>
        {
          this.GradeId=res;
          this.GradeModel.GradeId=0;                                  
          if(this.GradeId >0)
          {
            localStorage.setItem('SuccessfullMessage', "Deleted Successfully");
            this.rout.navigateByUrl('/Levels/ManageDescitionMakingLevels', {skipLocationChange: true}).then(()=>this.rout.navigate(["/Levels/ManageGrades"]));
          }
        });                  
      }
         
      }
    })

  }

  RefreshPage()
  {
    this.rout.navigateByUrl('/Levels/ManageDescitionMakingLevels', {skipLocationChange: true}).then(()=>this.rout.navigate(["/Levels/ManageGrades"]));
  }

  // begning of check duplicate Name
  CheckName()
  {
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    if(!this.CheckNamestatusedit)
    {
      this.LevelService.CheckGradeName( t, this.GradeModel.GradeName,this.GradeModel.LevelId).then(x => 
      {
        this.checkName = x;
        if( this.checkName.length >0)
        {
          this.CheckNameExiststatus =false;
          this.Namecheck="Exist"
        }
        else
        {
          this.CheckNameExiststatus =true;
          this.Namecheck="";
        }
     });
    }
    else
    {
      if(this.GradeModel.GradeName !=this.checkNameval )
      {
        this.LevelService.CheckGradeName( t, this.GradeModel.GradeName, this.GradeModel.LevelId).then(x => 
        {
          this.checkName = x;
          if( this.checkName.length >0)
          {
            this.CheckNameExiststatus =false;
            this.Namecheck="Exist"
          }
          else
          {
            this.CheckNameExiststatus =true;
            this.Namecheck="";
          }
        });
      }
      else
      {
        this.CheckNameExiststatus =true;
        this.Namecheck="";
      }
    }
  }
  // end of duplicate Name
}
