import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs/Subject';
import { DataTableDirective } from 'angular-datatables';
import { UserService } from '../../shared/user.service';
import { LeaveMasterService } from '../../shared/HR_ADMIN/Leave/LeaveMaster.service';
import { OrganizationService } from '../../shared/Organization/organization.service';
import { LeaveType } from  '../../shared/HR_ADMIN/Leave/LeaveType.Model';
import { LeaveMapping } from  '../../shared/HR_ADMIN/Leave/LeaveMapping.Model';
import { Company } from  '../../shared/Organization/Company.Model';
import { FinancialYear } from  '../../shared/Organization/FinancialYear.Model';
// Begning Confirmation
import { ConfirmationService } from '@jaspero/ng2-confirmations';
import { ResolveEmit } from '../../../app/resolve-emit';
import { ConfirmSettings } from '../../../app/confirm-settings';
import { Menu } from '../../shared/menu.model';
// end of Confirmation

@Component({
  selector: 'app-leave-mapping',
  templateUrl: './leave-mapping.component.html',
  styleUrls: ['./leave-mapping.component.css']
})
export class LeaveMappingComponent implements OnInit 
{
  LeaveMappingModel : LeaveMapping= new LeaveMapping();
  userClaims: any;
  LeaveMappings: LeaveMapping[] = [];
  Result : string;
  SuccessStatus:boolean=false;
  SuccessMessage: string ="";
  public tableWidget: any;
  LeaveMappingId : any;
  LeaveMapping: any;
  base64textString : string;

  LeaveTypes: LeaveType[] = [];
  LeaveType: any;
  Companies: Company[] = [];
  company: any;
  FinancialYears: FinancialYear[] = [];
  FinancialYear: any;
  tempLeaveMappingadd : LeaveMapping =new LeaveMapping();
  // Begning Permission Portion    
  SelectedMenu:Menu;
  IsDataIsInViewState : boolean=false;
  CanViewState : boolean=false;
  DisableSave : boolean=false;
  // End of the permission portion
  //check for duplicate component code
  CheckExiststatus : boolean=null;  
  Checkeditstatus : boolean =false;
  check : string ="";
  checkdata : any;
  checkdataval : string ="";
  //check for duplicate component code
  //Begin for disable
  IsCompanyEnable : boolean = false;
  IsBranchEnable : boolean = false; 
  //End for disable
  //for Leave Type Dropdown list
  LeaveTypeList = [];
  LeaveTypeselectedItems = [];
  dropdownSettings = {};
  LeaveTypeSettings = {};
  LeaveTypeComponents :any;
  //for Leave Type Dropdown list

  
  constructor(private LeaveMasterService: LeaveMasterService, private organizationService: OrganizationService, private rout: Router,  private _confirmation: ConfirmationService, private userService: UserService) { }
  
  @ViewChild(DataTableDirective, {static: false})
  setDtOptions: DataTables.Settings = {};
  cardDtOptions: DataTables.Settings = {};
  dtElement: DataTableDirective;
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();
  ngOnInit() 
  {
    //Begin Permission Portion
    this.SelectedMenu=this.userService.SelectedMenu;
    if(this.SelectedMenu !=undefined)
    {
      localStorage.setItem('selectedmenu',JSON.stringify(this.SelectedMenu));
      this.rout.navigate([this.SelectedMenu.RouteName]);
    }
    else
    {
      if(JSON.parse(localStorage.getItem('selectedmenu'))!=undefined)
      {
        this.SelectedMenu = JSON.parse(localStorage.getItem('selectedmenu'));
        this.rout.navigate([this.SelectedMenu.RouteName]);
      }
    }
    if(this.SelectedMenu.CanAdd)
    {
      this.IsDataIsInViewState=false;
    }
    else
    {
      this.IsDataIsInViewState=true;
    }
    if(this.SelectedMenu.CanView)
    {
      this.CanViewState=false;
    }
    else
    {
      this.CanViewState=true;
    }
    //End of the peremission portion
    this.getCompanies();
    //this.getBranches();
    this.getLeaveTypes();
    this.getFinYears();
    this.LeaveMappingModel.Year=0;
    this.LeaveMappingModel.CompanyId=0;
    //this.LeaveMappingModel.BranchId=0;
    this.dtOptions = 
    {
      pagingType: 'full_numbers',
      pageLength: 4
    };
    if(localStorage.getItem('SuccessfullMessage'))
    {
      this.SuccessStatus=true;
      this.SuccessMessage =localStorage.getItem('SuccessfullMessage')
      localStorage.removeItem('SuccessfullMessage');
    }  
    this.DisableSave=true;
  }

  ngAfterViewInit(): void 
  {
    this.GetLeaveMapping();
    this.dtOptions = 
    {
      pagingType: 'full_numbers',
      pageLength: 4
    };
  }

  settings: ConfirmSettings | any = 
  {
    overlay: true,
    overlayClickToClose: true,
    showCloseButton: true,
    confirmText: 'Yes',
    declineText: 'No'
  };

  getCompanies() 
  {
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.organizationService.GetCompanyForDDL( t).then(x => 
    {
      this.company = x;
      Object.keys(this.company).forEach( key => 
      {
        // tslint:disable-next-line:no-unused-expression
        this.company[key];
        let Companytemp = new Company();
        Companytemp = this.company[key];
        this.Companies.push(Companytemp);
      });     
    });      
  }

  getFinYears() 
  {
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.organizationService.GetYearOnly( t).then(x => 
    {
      this.company = x;
      Object.keys(this.company).forEach( key => 
      {
        // tslint:disable-next-line:no-unused-expression
        this.company[key];
        let Finyear = new FinancialYear();
        Finyear = this.company[key];
        this.FinancialYears.push(Finyear);
      });     
    });      
  }

  GetLeaveMapping()
  {
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.LeaveMasterService.GetLeaveMapping( t).then(x => 
    {
      this.LeaveMapping = x;
      Object.keys(this.LeaveMapping).forEach( key => 
      {
        this.LeaveMapping[key];
        let LeaveMappingtemp = new LeaveMapping();
        LeaveMappingtemp = this.LeaveMapping[key];
        this.LeaveMappings.push(LeaveMappingtemp);
      });
      this.dtTrigger.next();
    });
  }
  
  GetLeaveMappingByid(id) 
  { 
    //Begin for disable   
    this.IsCompanyEnable  = true;
    this.IsBranchEnable  = true;      
    //End for disable
    // Begning of the permission portion
    this.IsDataIsInViewState=false;
    // End of the permision portion
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.LeaveMasterService.GetLeaveMappingByid( t,id).then(x => 
    {
      this.LeaveMappingId = x;
      Object.keys(this.LeaveMappingId).forEach( key => 
      {
        // tslint:disable-next-line:no-unused-expression
        this.LeaveMappingId[key];
        let LeaveMappingtemp = new LeaveMapping();
        LeaveMappingtemp = this.LeaveMappingId[key];
        this.LeaveMappingModel.LeaveMappingId=LeaveMappingtemp.LeaveMappingId;
        this.LeaveMappingModel.CompanyId=LeaveMappingtemp.CompanyId;
        this.LeaveMappingModel.LeaveTypeIdS=LeaveMappingtemp.LeaveTypeIdS;
        this.LeaveMappingModel.Year=LeaveMappingtemp.Year;
      });
      this.getLeaveTypes();
      // Begin for check company code duplication
      this.checkdataval= this.LeaveMappingModel.LeaveTypeIdS;
      this.Checkeditstatus=true;
      this.CheckExiststatus =true;
      this.check=""
      // End for check company code duplication
    });
  }

  viewLeaveMappingByid(id) 
  {
    //Begin for disable   
    this.IsCompanyEnable  = true;
    this.IsBranchEnable  = true;      
    //End for disable
    //begin permission
    this.IsDataIsInViewState=true;
    //end permission
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.LeaveMasterService.GetLeaveMappingByid( t,id).then(x => 
      {
        this.LeaveMappingId = x;
        Object.keys(this.LeaveMappingId).forEach( key => 
        {
          // tslint:disable-next-line:no-unused-expression
          this.LeaveMappingId[key];
          let LeaveMappingtemp = new LeaveMapping();
          LeaveMappingtemp = this.LeaveMappingId[key];
          this.LeaveMappingModel.LeaveMappingId=LeaveMappingtemp.LeaveMappingId;
          this.LeaveMappingModel.CompanyId=LeaveMappingtemp.CompanyId;
          this.LeaveMappingModel.LeaveTypeIdS=LeaveMappingtemp.LeaveTypeIdS;
        });
        this.getLeaveTypes();
        // Begin for check company code duplication
        this.checkdataval= this.LeaveMappingModel.LeaveTypeIdS;
        this.Checkeditstatus=true;
        this.CheckExiststatus =true;
        this.check=""
        // End for check company code duplication
      });
  }

  saveLeaveMapping() :void
  {
    this.DisableSave=false;
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.LeaveMappingModel.IsActive=true;
    if(this.LeaveMappingModel.LeaveMappingId > 0)
    {
      if(this.LeaveTypeselectedItems.length > 0)  
      {
        this.LeaveMappingModel.LeaveTypeIdS='';
        this.LeaveTypeselectedItems.forEach(element => 
        {
          this.LeaveMappingModel.LeaveTypeIdS= this.LeaveMappingModel.LeaveTypeIdS + element.id + ",";
        });
        var l = this.LeaveMappingModel.LeaveTypeIdS.length
        this.LeaveMappingModel.LeaveTypeIdS = this.LeaveMappingModel.LeaveTypeIdS.substr(0,l-1);
        this.LeaveMasterService.UpdateLeaveMapping(t ,this.LeaveMappingModel).subscribe(res=>
        {
          this.LeaveMappingId=res;
          this.LeaveMappingModel.LeaveMappingId=0;  
          if(this.LeaveMappingId>0)
          {
            localStorage.setItem('SuccessfullMessage', "Update Successfully");
            this.rout.navigateByUrl('/LeaveMaster/LeaveType', {skipLocationChange: true}).then(()=>this.rout.navigate(["/LeaveMaster/LeaveMapping"]));
          }
        });
      }
    }
    else
    {
      if(this.LeaveTypeselectedItems.length > 0)  
      {
        this.LeaveMappingModel.LeaveTypeIdS='';
        this.LeaveTypeselectedItems .forEach(element => 
        {
          this.LeaveMappingModel.LeaveTypeIdS= this.LeaveMappingModel.LeaveTypeIdS + element.id + ",";         
        });
        var l = this.LeaveMappingModel.LeaveTypeIdS.length
        this.LeaveMappingModel.LeaveTypeIdS = this.LeaveMappingModel.LeaveTypeIdS.substr(0,l-1);
        this.LeaveMasterService.AddLeaveMapping(t ,this.LeaveMappingModel).subscribe(res=>
        {
          this.LeaveMappingId=res;
          this.LeaveMappingModel.LeaveMappingId=0;              
          if(this.LeaveMappingId>0)
          {
            localStorage.setItem('SuccessfullMessage', "Save Successfully");
            this.rout.navigateByUrl('/LeaveMaster/LeaveType', {skipLocationChange: true}).then(()=>this.rout.navigate(["/LeaveMaster/LeaveMapping"]));
          }
        }); 
      }
    }
  }

  DeleteLeaveMappingByid(Refid : number) :void
  {
    if(this.SelectedMenu.CanAdd)
    {
     this.IsDataIsInViewState=false;
    }
    else
    {
      this.IsDataIsInViewState=true;
    }
    this._confirmation.create('Confirm to Deleate', 'Press Yes to Delete', this.settings)
      .subscribe((ans: ResolveEmit) => 
      {
        if (ans.resolved == true) 
        {
          let t =  localStorage.getItem('userToken');
          t = 'Bearer' + t;
          this.LeaveMappingModel.IsActive=true;
          if( Refid > 0)
          {
            this.LeaveMasterService.DeleteLeaveMapping(t ,Refid).subscribe(res=>
            {
              this.LeaveMappingId=res;
              this.LeaveMappingModel.LeaveMappingId=0;                                  
              if(this.LeaveMappingId >0)
              {
                localStorage.setItem('SuccessfullMessage', "Deleted Successfully");
                this.rout.navigateByUrl('/LeaveMaster/LeaveType', {skipLocationChange: true}).then(()=>this.rout.navigate(["/LeaveMaster/LeaveMapping"]));
              }   
            });                  
          }
        }
      })
  }
  // begning of check duplicate Salary Mapping
  CheckLeaveMapping()
  {
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    if(this.LeaveMappingModel.CompanyId > 0 &&  this.LeaveMappingModel.Year > 0)
    {
    if(!this.Checkeditstatus)
    {
      this.LeaveMasterService.CheckLeaveMappping( t, this.LeaveMappingModel.CompanyId,this.LeaveMappingModel.Year).then(x => 
      {
        this.checkdata = x;
        if( this.checkdata.length >0)
        {
          this.CheckExiststatus =false;
          this.check="Exist"
        }
        else
        {
          this.CheckExiststatus =true;
          this.check="";
        }
     });
    }
    else
    {
      if(this.LeaveMappingModel.LeaveTypeIdS !=this.checkdataval )
      {
        this.LeaveMasterService.CheckLeaveMappping( t, this.LeaveMappingModel.CompanyId,this.LeaveMappingModel.Year).then(x => 
        {
          this.checkdata = x;
          if( this.checkdata.length >0)
          {
            this.CheckExiststatus =false;
            this.check="Exist"
          }
          else
          {
            this.CheckExiststatus =true;
            this.check="";
          }
        });
      }
      else
      {
        this.CheckExiststatus =true;
        this.check="";
      }
    }
  }
  }
  // end of duplicate Salary Mapping
  
  getLeaveTypes() 
  {
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t
    this.LeaveTypeselectedItems = [];
    this.LeaveTypeList = [];
    this.LeaveMasterService.GetLeaveType( t).then(x => 
    {
      this.LeaveTypeComponents = x;
      Object.keys(this.LeaveTypeComponents).forEach( key => 
      {
        // tslint:disable-next-line:no-unused-expression
        this.LeaveTypeComponents[key];
        let  LeaveTypetemp= new LeaveType();
        LeaveTypetemp = this.LeaveTypeComponents[key];
        this.LeaveTypeList.push({"id":LeaveTypetemp.LeaveTypeId,"itemName":LeaveTypetemp.LeaveTypeName},);
      });  
      if(this.LeaveTypeList.length > 0)  
      {
        var selected_br = this.LeaveMappingModel.LeaveTypeIdS.split(',');
        for (var _i = 0; _i < selected_br.length; _i++) 
        {
          var item = this.LeaveTypeList.find(x=>x.id==selected_br[_i])
          this.LeaveTypeselectedItems.push(item);
        }
      }
    });      
  }
  onItemSelect(item:any){ }

  OnItemDeSelect(item:any)
  {    
    this.LeaveTypeList.push({"id":item.id,"itemName":item.itemName});
    var itemx = this.LeaveTypeList.find(x=>x.id==item.id)
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.LeaveMasterService.ChecklivemapingIsinUsedOrNot( t,item.id,this.LeaveMappingModel.CompanyId,this.LeaveMappingModel.Year).then(x => 
    {
      if(x==true)
      {
       this.LeaveTypeselectedItems.push(itemx);
      }
    });      
  }

  onSelectAll(items: any){  }

  onDeSelectAll(items: any){ }

  RefreshPage()
  {
    this.rout.navigateByUrl('/LeaveMaster/LeaveType', {skipLocationChange: true}).then(()=>this.rout.navigate(["/LeaveMaster/LeaveMapping"]))
  }
}