import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';  
import { Http, Headers, RequestOptions, Response } from '@angular/http';  
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner'; 
import 'rxjs/add/operator/toPromise';
import { Subscriber } from 'rxjs';
import { GlobalVariable  } from '../../../app.global';


import { Model_CRM_State } from './Model_CRM_State';

    


@Injectable()

export class CrmStateService {

  constructor (private http: HttpClient,private spinnerService: Ng4LoadingSpinnerService) 
  {
  }


  //    Populate Country Drop  Down Starts

    GetCountryType(token) {
        this.spinnerService.show();
        const authHeader = new HttpHeaders();
        authHeader.append('Content-Type', 'application/json');
        authHeader.append('Authorization', token);
        return new Promise((resolve, reject) => {
            setTimeout(() => {

                this.http.get(GlobalVariable.BASE_API_URL + '/api/CRMState/GetCountryName', { headers: authHeader })
                    .subscribe(data => {
                        this.spinnerService.hide();
                        resolve(data)
                    },
                        error => {
                            this.spinnerService.hide();
                            reject(error);
                        }, );
            }, 3000);
        });
    }

  //  Populate  Country Drop  Down Ends


    //    Populate Zone Drop  Down Starts

    GetZoneType(token, CountryId: number) {
        this.spinnerService.show();
        const authHeader = new HttpHeaders();
        authHeader.append('Content-Type', 'application/json');
        authHeader.append('Authorization', token);
        return new Promise((resolve, reject) => {
            setTimeout(() => {

                this.http.get(GlobalVariable.BASE_API_URL + '/api/CRMState/GetZoneName?CountryId='+CountryId, { headers: authHeader })
                    .subscribe(data => {
                        this.spinnerService.hide();
                        resolve(data)
                    },
                        error => {
                            this.spinnerService.hide();
                            reject(error);
                        }, );
            }, 3000);
        });
    }

  //  Populate Zone Drop  Down Ends


            AddCrmState(token, CRMState: Model_CRM_State) 
                     {
                const authHeader = new HttpHeaders();         
                authHeader.append('Content-Type', 'application/json');
                authHeader.append('Authorization', token);  
                authHeader.append('Accept', 'application/json');
                return this.http.post(GlobalVariable.BASE_API_URL + '/api/CrmState/Add', CRMState ,{headers: authHeader} )
                .map(this.extractData)
                .catch(this.handleErrorObservable);
                   }




    // Grid Data Fetch  

    Get_CRMState(token) {
        this.spinnerService.show();
        const authHeader = new HttpHeaders();
        authHeader.append('Content-Type', 'application/json');
        authHeader.append('Authorization', token);
        return new Promise((resolve, reject) => {
            setTimeout(() => {
                this.http.get(GlobalVariable.BASE_API_URL + '/api/CRMState/GetCRMState',
                    { headers: authHeader }).subscribe(data => {
                        this.spinnerService.hide();
                        resolve(data)
                    },
                        error => {
                            this.spinnerService.hide();
                            reject(error);
                        }, );
            }, 3000);
        });
    }





    //  View  Section Starts

    GetCRM_State_By_id(token, id:number ) 
    {
        this.spinnerService.show(); 
        const authHeader = new HttpHeaders();     
        authHeader.append('Content-Type', 'application/json');
        authHeader.append('Authorization', token);
        return new Promise((resolve, reject) => 
        {   
            setTimeout(()=>
            {
                this.http.get(GlobalVariable.BASE_API_URL +'/api/CRMZone/GetCRMStateById?StateId='+id,{headers: authHeader})
                .subscribe(data => 
                {
                    this.spinnerService.hide();
                    resolve(data)
                },
                error => 
                {
                    this.spinnerService.hide();
                    reject(error);
                },);
            },3000);
        });
    }

    //  View  Section Ends    


            


    UpdateCrmState(token, CRMState: Model_CRM_State) {

        const authHeader = new HttpHeaders();
        authHeader.append('Content-Type', 'application/json');
        authHeader.append('Authorization', token);
        authHeader.append('Accept', 'application/json');

        return this.http.post(GlobalVariable.BASE_API_URL + '/api/CrmState/Update', CRMState, { headers: authHeader })
            .map(this.extractData)
            .catch(this.handleErrorObservable);
    }



    

     

// //  Delete  a   Record  Starts

    DeleteCRMState( token ,ID : number) 
    {

        const authHeader = new HttpHeaders();         
        authHeader.append('Content-Type', 'application/json');
        authHeader.append('Authorization', token);
        authHeader.append('Accept', 'application/json');
        return this.http.put(GlobalVariable.BASE_API_URL +'/api/CrmState/Delete', ID ,{headers: authHeader} )
        .map(this.extractData)
        .catch(this.handleErrorObservable);
    } 

//     //  Delete  a  record   Ends    



    private extractData(res: Response) {
        let body = res;
        return body || {};
    }
    private handleErrorObservable(error: Response | any) {
        console.error(error.message || error);
        return Observable.throw(error.message || error);
    }


 }
