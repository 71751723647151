import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
    // selector: 'input[NumberOn]'

    selector: '[NumberOn]'
})
export class NumberDirective {

    private specialKeys: Array<string> = ['Backspace', 'Tab', 'End', 'Home', '-'];
    //private regex: RegExp = new RegExp(/[^0-9]*/g);

    private regex: RegExp = new RegExp(/^[0-9]+$/);

    constructor(private _el: ElementRef) { }

    // @HostListener('input', ['$event'])
    // onInputChange(event) {
    //     const initalValue = this._el.nativeElement.value;
    //     this._el.nativeElement.value = initalValue.replace(/[^0-9]*/g, '');
    //     if (initalValue !== this._el.nativeElement.value) {
    //         event.stopPropagation();
    //     }
    // }
    @HostListener('keydown', ['$event'])
    onKeyDown(event: KeyboardEvent) {
        // Allow Backspace, tab, end, and home keys
        if (this.specialKeys.indexOf(event.key) !== -1) {
            return;
        }
        let current: string = this._el.nativeElement.value;

        let next: string = current.concat(event.key);
        if (next && !String(next).match(this.regex)) {
            event.preventDefault();
        }
    }

}