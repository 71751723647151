<div class="body-content-area tabcontent" id="link1">
    <div class="row">
        <div class="col-lg-9">
            <div class="row">
                <div class="col-lg-1 low-padding text-center">
                    <!-- <img *ngIf="this.SelectedMenu.Image!=null" src={{this.SelectedMenu.Image}} class="body-icon" alt="Icon"/> -->
                </div>
                <div class="col-lg-10 clear-padding">
                    <span class="page-title">Manage Branch</span>
                    <small class="page-description"></small>
                    <div class="col-lg-10 clear-padding" *ngIf="!(this.CanViewState)">
                        <button *ngIf="(this.CanSaveButtonView)" (click)="ADDClick()" type="button" class="btn btn-primary Add-button">ADD New</button> 
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div *ngIf="!(this.CanViewState)">
    <app-dialog [(visible)]="this.SuccessStatus">
        <span class="successmsg">{{this.SuccessMessage}}</span>
    </app-dialog>
    <fieldset *ngIf="!(this.FormViewShow)" class="body-form-control">
        <legend class="legend--">Branch Details</legend>
        <table id="tblbranch" *ngIf="!(this.FormViewShow)" datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" class="table table-striped table-bordered text-center table-new row-border hover">
            <thead class="thead-blue text-center">
                <tr>
                    <th>Sl No.</th>
                    <th>Branch Code</th>
                    <th>Branch Name</th>
                    <th>Company Name</th>
                    <th>Location</th>
                    <th>Contact Number</th>
                    <th>Action</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let branch of Branches, let i = index">
                    <td scope="row">{{i+1}}</td>
                    <td>{{branch.BranchCode}}</td>
                    <td class="text-left">{{branch.BranchName}}</td>
                    <td class="text-left">{{branch.CompName}}</td>
                    <td class="text-left">{{branch.ZoneName}}</td>
                    <td>{{branch.PhoneNo1}}</td>
                    <td>
                        <button type="button" *ngIf="this.SelectedMenu.CanEdit"  (click)="GetBranchByid( branch.BranchId )"class="table-edit-btn"><i class="fas fa-edit"></i>  </button> 
                        <button type="button"  *ngIf="this.SelectedMenu.CanDelete" (click)="DeleteBranchByid( branch.BranchId )" class="table-remove-btn"><i class="fas fa-trash-alt"></i></button>
                        <button  *ngIf="this.SelectedMenu.CanView"  type="button" (click)="viewCompanByid( branch.BranchId )" class="table-view-button" type="button" ><i class="fas fa-eye"></i></button>
                    </td>
                </tr>
            </tbody>
            <tfoot class="thead-blue text-center">
                <tr>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                </tr>
            </tfoot>
        </table>
    </fieldset>

    <fieldset class="body-form-control" *ngIf="(this.FormViewShow)">
        <legend class="legend--">Branch Creation</legend>
        <form #BranchForm="ngForm" (ngSubmit)="saveBranch()">
                <!-- <div class="row" *ngIf="this.SuccessStatus==true">{{this.SuccessMessage}} </div>  -->
            <div class="row">
                <div class="col-lg-4">
                    <div class="col-body-border-right">
                        <div class="form-group row">
                            <label  class="col-sm-4 col-form-label clear-padding-right">Branch Code</label>
                            <div class="col-sm-7 clear-left-padding">
                                <input type="text" [(ngModel)]="BranchModel.BranchCode" tabindex="1" maxlength="40" id ="BranchCode"  name="BranchCode" #BranchCode="ngModel" required class="form-control form-control-custom form-control-custom-new"  >
                            </div>
                            
                            <div *ngIf="BranchCode.invalid && (BranchCode.dirty || BranchCode.touched)" class="alert alert-danger">
                                <div *ngIf="BranchCode.errors.required">Branch Code is required.</div>
                                <div *ngIf="BranchCode.errors.maxlength">Branch Code is not more than 40 characters</div>
                            </div>
                        </div>
                        <div class="form-group row">
                            <label  class="col-sm-4 col-form-label clear-padding-right">Addres 1</label>
                            <div class="col-sm-7 clear-left-padding">
                                <input type="text" [(ngModel)]="BranchModel.Address1" tabindex="4" id ="Address1"  name="Address1" #Address1="ngModel" required maxlength="500" class="form-control form-control-custom form-control-custom-new" >
                            </div>
                            <div *ngIf="Address1.invalid && (Address1.dirty || Address1.touched)" class="alert alert-danger">
                                <div *ngIf="Address1.errors.required">Address1  is required.</div>
                                <div *ngIf="Address1.errors.maxlength">Address1 is not more than 500 characters</div>
                            </div>
                        </div>
                        <div class="form-group row">
                            <label  class="col-sm-4 col-form-label clear-padding-right">State</label>
                            <div class="col-sm-7 clear-left-padding">
                                <select [(ngModel)]="BranchModel.StateId" tabindex="7" (ngModelChange)="onstatechange($event)" id ="StateId" class="form-control" name="StateId" #StateId="ngModel" >
                                    <option [value]="0" [disabled]=true >Select State</option>
                                    <option *ngFor="let s of States" [value]="s.StateID">{{s.StateName}}</option>
                                </select>
                            </div>
                            <div class="required-validation" *ngIf="BranchModel.StateId==0">State is required</div>
                        </div>
                        <div class="form-group row">
                            <label  class="col-sm-4 col-form-label clear-padding-right">Contact No</label>
                            <div class="col-sm-7 clear-left-padding">
                                <input type="number" [(ngModel)]="BranchModel.PhoneNo1" tabindex="10" required maxlength="10" minlength="10"  pattern="[0-9]+" id ="PhoneNo1"  name="PhoneNo1" #PhoneNo1="ngModel" class="form-control form-control-custom form-control-custom-new" >
                            </div>
                            <div *ngIf="PhoneNo1.invalid && (PhoneNo1.dirty || PhoneNo1.touched)" class="alert alert-danger">
                                <div *ngIf="PhoneNo1.errors.required">Contact No  is required.</div>
                                <div *ngIf="PhoneNo1.errors.maxlength">Contact No is not more than 10 digit.</div>
                                <div *ngIf="PhoneNo1.errors.minlength">Contact No is not less than 10 dogit.</div>
                                <div *ngIf="PhoneNo1.errors?.pattern">Contact No should be valid.</div>
                            </div>
                        </div>
                        <div class="form-group row">
                            <label  class="col-sm-4 col-form-label clear-padding-right">Fax No</label>
                            <div class="col-sm-7 clear-left-padding">
                                <input type="text" [(ngModel)]="BranchModel.Fax" tabindex="13" maxlength="10" minlength="10" pattern="[0-9]+" id ="Fax"  name="Fax" #Fax="ngModel" class="form-control form-control-custom form-control-custom-new" >
                            </div>
                            <div *ngIf="Fax.invalid && (Fax.dirty || Fax.touched)" class="alert alert-danger">
                                <div *ngIf="Fax.errors.maxlength">Fax No is not more than 10 digit.</div>
                                <div *ngIf="Fax.errors.minlength">Fax No is not less than 10 dogit.</div>
                                <div *ngIf="Fax.errors?.pattern">Fax No should be valid.</div>
                            </div>
                        </div>
                        <div class="form-group row">
                            <label  class="col-sm-4 col-form-label clear-padding-right">Registration No</label>
                            <div class="col-sm-7 clear-left-padding">
                                <input type="text" [(ngModel)]="BranchModel.RegistrationNumber" id ="RegistrationNo"  tabindex="16" name="RegistrationNo" class="form-control form-control-custom form-control-custom-new" >
                            </div>
                            <!-- status: {{BranchForm.valid && BranchModel.StateId !=0 && BranchModel.CountryId!=0 && BranchModel.CountryId!=0}} -->
                        </div>
                    </div>
                </div>
                
                <div class="col-lg-4">
                    <div class="col-body-border-right">
                        <div class="form-group row">
                            <label  class="col-sm-4 col-form-label clear-padding-right">Branch Name</label>
                            <div class="col-sm-7 clear-left-padding">
                                <input type="text" [(ngModel)]="BranchModel.BranchName" tabindex="2" id ="BranchName" name="BranchName" #BranchName="ngModel" (blur)="CheckName()" required maxlength="250" class="form-control form-control-custom form-control-custom-new" >
                            </div>
                            <div *ngIf="this.Namecheck.length > 0" class="alert alert-danger">Branch Name is exist</div>
                            <div *ngIf="BranchName.invalid && (BranchName.dirty || BranchName.touched)" class="alert alert-danger">
                                <div *ngIf="BranchName.errors.required">Branch Name  is required.</div>
                                <div *ngIf="BranchName.errors.maxlength">Branch Name is not more than 250 characters</div>
                            </div>
                        </div>
                        <div class="form-group row">
                            <label  class="col-sm-4 col-form-label clear-padding-right">Address 2</label>
                            <div class="col-sm-7 clear-left-padding">
                                <input type="text" [(ngModel)]="BranchModel.Address2" tabindex="5" id ="Address2"  name="Address2" #Address2="ngModel" required maxlength="500" class="form-control form-control-custom form-control-custom-new" >
                            </div>
                            <div *ngIf="Address2.invalid && (Address2.dirty || Address2.touched)" class="alert alert-danger">
                                <div *ngIf="Address2.errors.required">Address2  is required.</div>
                                <div *ngIf="Address2.errors.maxlength">Address2 is not more than 500 characters</div>
                            </div>
                        </div>
                        <div class="form-group row">
                            <label  class="col-sm-4 col-form-label clear-padding-right">City</label>
                            <div class="col-sm-7 clear-left-padding">
                                <select  [(ngModel)]="BranchModel.CityId" tabindex="8" id ="CityId" class="form-control" name="CityId" #CityId="ngModel">
                                    <option [value]="0" [disabled]=true >Select City</option>
                                    <option *ngFor="let c of Cities" [value]="c.CityId">{{c.CityName}}</option>
                                </select>
                            </div>
                            <div class="required-validation" *ngIf="BranchModel.CityId==0">City is required</div>
                        </div>
                        <div class="form-group row">
                            <label  class="col-sm-4 col-form-label clear-padding-right">Mobile No</label>
                            <div class="col-sm-7 clear-left-padding">
                                <input type="text" [(ngModel)]="BranchModel.PhoneNo2" tabindex="10" maxlength="10" minlength="10" pattern="[0-9]+" id ="PhoneNo2"  name="PhoneNo2" #PhoneNo2="ngModel" class="form-control form-control-custom form-control-custom-new" >
                            </div>
                            <div *ngIf="PhoneNo2.invalid && (PhoneNo2.dirty || PhoneNo2.touched)" class="alert alert-danger">
                                <div *ngIf="PhoneNo2.errors.maxlength">Mobile No is not more than 10 digit.</div>
                                <div *ngIf="PhoneNo2.errors.minlength">Mobile No is not less than 10 dogit.</div>
                                <div *ngIf="PhoneNo2.errors?.pattern">Mobile No should be valid.</div>
                            </div>
                        </div>
                        <div class="form-group row">
                            <label  class="col-sm-4 col-form-label clear-padding-right">PAN No</label>
                            <div class="col-sm-7 clear-left-padding">
                                <input type="text" [(ngModel)]="BranchModel.PANNumber" id ="panNo" tabindex="14" name="panNo" class="form-control form-control-custom form-control-custom-new" >
                            </div>
                            <!-- status: {{BranchForm.valid && BranchModel.StateId !=0 && BranchModel.CountryId!=0 && BranchModel.CountryId!=0}} -->
                        </div>
                        <div class="form-group row">
                            <!-- <label  class="col-sm-4 col-form-label clear-padding-right">Logo</label>
                            <div class="col-sm-7 clear-left-padding">
                                <input type="file" [(ngModel)]="BranchModel.Logo" id ="Logo" tabindex="17" name="Logo" class="form-control " >
                            </div> -->
                            <!-- status: {{BranchForm.valid && BranchModel.StateId !=0 && BranchModel.CountryId!=0 && BranchModel.CountryId!=0}} -->
                        </div>
                    </div>
                </div>

                <div class="col-lg-4">
                    <div class="form-group row">
                        <label  class="col-sm-4 col-form-label clear-padding-right">Company Name</label>
                        <div class="col-sm-7 clear-left-padding">
                            <select [(ngModel)]="BranchModel.CompId" tabindex="3" id ="CompId" class="form-control" (blur)="CheckName()" name="CompId" #CompId="ngModel" >
                                <option [value]="0" [disabled]=true >Select Company</option>
                                <option *ngFor="let comp of Companies" [value]="comp.CompId">{{comp.CompName}}</option>
                            </select>
                        </div>
                        <div class="required-validation"  *ngIf="BranchModel.CompId==0">Company is required</div>
                    </div>
                    <div class="form-group row">
                        <label  class="col-sm-4 col-form-label clear-padding-right">Country</label>
                        <div class="col-sm-7 clear-left-padding">
                            <select [(ngModel)]="BranchModel.CountryId" tabindex="6" (ngModelChange)="onChange($event)" id ="CountryId" class="form-control"  name="CountryId" #CountryId="ngModel" >
                                <option [value]="0" [disabled]=true >Select Country</option>
                                <option *ngFor="let c of Countries" [value]="c.CountryId">{{c.CountryName}}</option>
                            </select>
                        </div>
                        <div class="required-validation" *ngIf="BranchModel.CountryId==0">Country is required</div>
                    </div>
                    <div class="form-group row">
                        <label  class="col-sm-4 col-form-label clear-padding-right">Pin Code</label>
                        <div class="col-sm-7 clear-left-padding">
                            <input type="text" [(ngModel)]="BranchModel.PostCode" tabindex="9" id ="PostCode" maxlength="6" minlength="6"  name="PostCode" #PostCode="ngModel" pattern="[0-9]+" required class="form-control form-control-custom form-control-custom-new" >
                        </div>
                        <div *ngIf="PostCode.invalid && (PostCode.dirty || PostCode.touched)" class="alert alert-danger">
                            <div *ngIf="PostCode.errors.required">PostCode is required.</div>
                            <div *ngIf="PostCode.errors.maxlength">PostCode not more than 6 digits.</div>
                            <div *ngIf="PostCode.errors.minlength">PostCode not more than 6 digits.</div>
                            <div *ngIf="PostCode.errors?.pattern">PostCode should be valid</div>
                        </div>

                    </div>
                    <div class="form-group row">
                        <label class="col-sm-4 col-form-label clear-padding-right">Website</label>
                        <div class="col-sm-7 clear-left-padding">
                            <input type="text" [(ngModel)]="BranchModel.Website" tabindex="12" id ="Website"  name="Website" #Website="ngModel" class="form-control form-control-custom form-control-custom-new" >
                        </div>
                            <!-- status: {{BranchForm.valid && BranchModel.StateId !=0 && BranchModel.CountryId!=0 && BranchModel.CountryId!=0}} -->
                    </div>
                    <div class="form-group row">
                        <label  class="col-sm-4 col-form-label clear-padding-right">Email Id</label>
                        <div class="col-sm-7 clear-left-padding">
                            <input type="text" [(ngModel)]="BranchModel.Email" required id ="Email" tabindex="15" name="Email"  #Email="ngModel" pattern="^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$" class="form-control form-control-custom form-control-custom-new" >
                        </div>
                        <div *ngIf="Email.invalid && (Email.dirty || Email.touched)" class="alert alert-danger">
                            <div *ngIf="Email.errors.required">Email is required.</div>
                            <div *ngIf="Email.errors?.pattern">Email should be valid.</div>
                        </div>
                    </div>
                    <div class="form-group row">
                        <label class="col-sm-6 col-form-label text-center clear-padding-right" *ngIf="!(this.IsDataIsInViewState)">
                            <button type="submit" tabindex="18" class="btn btn-primary save-button" [disabled]="!(BranchForm.valid && BranchModel.StateId !=0 && BranchModel.CountryId!=0 && BranchModel.CityId!=0 && BranchModel.CompId!=0 && this.DisableSave==true)" >Save</button>  
                        </label>
                        <div class="col-sm-6 col-form-label text-center">
                            <button type="button" tabindex="19"  (click)="RefreshPage()" class="btn btn-danger cancle-button">Cancel</button>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </fieldset>
</div>
</div> 
