import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from '../../shared/user.service';
import { EmployeePortalService }  from '../../shared/HR_ADMIN/EmployeePortal/EmployeePortalService.service';
import { TourModel } from '../../shared/HR_ADMIN/EmployeePortal/TourModel.Model';
import { Menu } from '../../shared/menu.model';
import { ModalOptions,BsModalRef, BsModalService } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-employee-tour-status',
  templateUrl: './employee-tour-status.component.html',
  styleUrls: ['./employee-tour-status.component.css']
})

export class EmployeeTourStatusComponent implements OnInit 
{
  TourModel : TourModel= new TourModel();
 
  ApplicationPending: TourModel[]=[];
  ApplicationApproved: TourModel[]=[];
  ApplicationRejected: TourModel[]=[];
  Application: any;

  userClaims: any;

  Result : string;
  SuccessStatus:boolean=false;
  SuccessMessage: string ="";
  public tableWidget: any;
  selectedIndex : number;
  // Begning Permission Portion    
  SelectedMenu:Menu;
  // End of the permission portion
  //For Pop up
  ModelListApplication: TourModel;
  CancelMsg : boolean= false;
  ShowPopup : boolean= false;
  public modalRef: BsModalRef;
  public modelopt:ModalOptions;
  TempTour : TourModel;
  Approver : any;
  RefId : any;
  //New
  PendingMsg : boolean= false;
  ApprovedMsg : boolean= false;
  RejectedMsg : boolean= false;
  validdate : boolean= false;
  validTodate : boolean= false;
  DisableSave : boolean;

  constructor(private EmployeePortalService: EmployeePortalService, private rout: Router,private userService: UserService,private modalService: BsModalService)
  {
    this.ModelListApplication = new TourModel();
    this.modelopt=new ModalOptions();
    this.modelopt.class='heavy_modal';
    this.modalService.config= this.modelopt;
    this.modelopt.ignoreBackdropClick=true;
   }

  ngOnInit() 
  {
    // Begning Permission Portion 
    this.SelectedMenu=this.userService.SelectedMenu;
    if(this.SelectedMenu !=undefined)
    {
      localStorage.setItem('selectedmenu',JSON.stringify(this.SelectedMenu));
      this.rout.navigate([this.SelectedMenu.RouteName]);
    }
    else
    {
      if(JSON.parse(localStorage.getItem('selectedmenu'))!=undefined)
      {
        this.SelectedMenu = JSON.parse(localStorage.getItem('selectedmenu'));
        this.rout.navigate([this.SelectedMenu.RouteName]);
      }
    }
    // End of the permission portion
    if(localStorage.getItem('SuccessfullMessage'))
    {
      this.SuccessStatus=true;
      this.SuccessMessage =localStorage.getItem('SuccessfullMessage')
      localStorage.removeItem('SuccessfullMessage');
    }
    this.GetEmployeeDetails();
    this.DisableSave=true;
    this.ModelListApplication.Status=0;
    this.validdate=false;
    this.validTodate=false;
    this.DisableSave=true;
  }

  GetEmployeeDetails()
  {
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.EmployeePortalService.GetEmployeeDetailsByUserLogingIdLeaveApply( t).then(x => 
    {
      this.Application = x;
      Object.keys(this.Application).forEach( key => 
      {
        this.Application[key];
        let Applicationtemp = new TourModel();
        Applicationtemp = this.Application[key];
        this.TourModel.EmployeeId=this.Application.EmployeeId;
        this.TourModel.EmployeeCode=this.Application.EmployeeCode;
        this.TourModel.EmployeeName=this.Application.EmployeeName;
        this.TourModel.RHId=this.Application.ReportingHeadId;
        this.TourModel.RHName=this.Application.ReportingHeadName;
        this.TourModel.RHEmailId=this.Application.RHEmailId;
      });
    });
  }

  GetEmployeeTourStatusPending(FromDate,ToDate)
  {
    this.ApplicationPending=[];
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.EmployeePortalService.GetTourStatusPending( t,FromDate,ToDate).then(x => 
    {
      this.Application = x;
      Object.keys(this.Application).forEach( key => 
      {
        this.Application[key];
        let Applicationtemp = new TourModel();
        Applicationtemp = this.Application[key];
        this.ApplicationPending.push(Applicationtemp);
      });
      if(this.ApplicationPending.length == 0)
      {
        this.PendingMsg = true;
        this.ApprovedMsg = false;
        this.RejectedMsg = false;
      }
    });
  }

  GetEmployeeTourStatusApproved(FromDate,ToDate)
  {
    this.ApplicationApproved=[];
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.EmployeePortalService.GetTourStatusApproved( t,FromDate,ToDate).then(x => 
    {
      this.Application = x;
      Object.keys(this.Application).forEach( key => 
      {
        this.Application[key];
        let Applicationtemp = new TourModel();
        Applicationtemp = this.Application[key];
        this.ApplicationApproved.push(Applicationtemp);
      });
      if(this.ApplicationApproved.length == 0)
      {
        this.PendingMsg = false;
        this.ApprovedMsg = true;
        this.RejectedMsg = false;
      }
    });
  }

  GetEmployeeTourStatusRejected(FromDate,ToDate)
  {
    this.ApplicationRejected=[];
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.EmployeePortalService.GetTourStatusRejected( t,FromDate,ToDate).then(x => 
    {
      this.Application = x;
      Object.keys(this.Application).forEach( key => 
      {
        this.Application[key];
        let Applicationtemp = new TourModel();
        Applicationtemp = this.Application[key];
        this.ApplicationRejected.push(Applicationtemp);
      });
      if(this.ApplicationRejected.length == 0)
      {
        this.PendingMsg = false;
        this.ApprovedMsg = false;
        this.RejectedMsg = true;
      }
    });
  }

  RefreshPage()
  {
    this.rout.navigateByUrl('/EmployeePortal/PersonalInformation', {skipLocationChange: true}).then(()=>this.rout.navigate(["/EmployeePortal/TourStatus"]));
  }

  viewAppliedTour(id,template) 
  {
    this.DisableSave=true;
    this.ModelListApplication.Remarks="";
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.EmployeePortalService.GetEmployeeTourForApprovalById( t,id).then(x => 
    {
      if(this.ShowPopup==true)
      {
        this.modalRef.hide();
      }
      this.modalRef = this.modalService.show(template,this.modelopt);
      this.ShowPopup=true;
      this.CancelMsg=false;
      this.Approver = x;
      Object.keys(this.Approver).forEach( key => 
      {
        // tslint:disable-next-line:no-unused-expression
        this.Approver[key];
        let Approvetemp = new TourModel();
        Approvetemp = this.Approver[key];
        this.ModelListApplication.TourId=Approvetemp.TourId;
        this.ModelListApplication.EmployeeId=Approvetemp.EmployeeId;
        this.ModelListApplication.EmployeeCode=Approvetemp.EmployeeCode;
        this.ModelListApplication.EmployeeName=Approvetemp.EmployeeName; 
        this.ModelListApplication.EmployeeEmailId=Approvetemp.EmployeeEmailId; 
        this.ModelListApplication.RHId=Approvetemp.RHId; 
        this.ModelListApplication.RHName=Approvetemp.RHName; 
        this.ModelListApplication.RHCode=Approvetemp.RHCode; 
        this.ModelListApplication.RHEmailId=Approvetemp.RHEmailId; 
        this.ModelListApplication.FromDate=Approvetemp.FromDate; 
        this.ModelListApplication.ToDate=Approvetemp.ToDate;
        this.ModelListApplication.TourPlace=Approvetemp.TourPlace; 
        this.ModelListApplication.CompanyId=Approvetemp.CompanyId;
      });
      // End for check company code duplication
    });
  }
  
  TourCancel(template) :void
  {
    if(confirm("Are you sure to Cancel "))
    {
      this.DisableSave=false;
      let t =  localStorage.getItem('userToken');
      t = 'Bearer' + t;
      if(this.ModelListApplication.TourId > 0)
      {
        this.ModelListApplication.IsRejected=true;
        this.EmployeePortalService.CancelEmployeeTour(t ,this.ModelListApplication).subscribe(res=>
        {
          this.RefId=res;
          this.ModelListApplication.TourId=0;  
          if(this.RefId>0)
          {
            this.ModelListApplication.Remarks="";
            this.ModelListApplication.RemarksAll="";
            this.modalRef.hide();
            this.CancelMsg=true;
            this.ShowPopup=false;
            this.modalRef = this.modalService.show(template);
            this.GetEmployeeTourStatusPending(this.ModelListApplication.SearchFromDate, this.ModelListApplication.SearchToDate);
            // this.GetEmployeeTourStatusPending();
            // this.GetEmployeeTourStatusRejected();
            this.DisableSave=true;
          }
        });                  
      }
    }
  }

  SearchFromDateChanged( date)
  {
    this.DisableSave = true;
    this.PendingMsg = false;
    this.ApprovedMsg = false;
    this.RejectedMsg = false;
    this.ApplicationPending=[];
    this.ApplicationApproved=[];
    this.ApplicationRejected=[];
    
    this.ModelListApplication.SearchFromDate=date;
  }

  SearchToDateChanged( date)
  {
    this.DisableSave = true;
    this.PendingMsg = false;
    this.ApprovedMsg = false;
    this.RejectedMsg = false;
    this.ApplicationPending=[];
    this.ApplicationApproved=[];
    this.ApplicationRejected=[];

    this.ModelListApplication.SearchToDate=date;
  }

  StatusChanged()
  {
    this.DisableSave = true;
    this.PendingMsg = false;
    this.ApprovedMsg = false;
    this.RejectedMsg = false;
    this.ApplicationPending=[];
    this.ApplicationApproved=[];
    this.ApplicationRejected=[];
  }

  submitClick()
  {
    this.DisableSave = false;
    this.validdate = false;
    this.validTodate=false;
    this.PendingMsg = false;
    this.ApprovedMsg = false;
    this.RejectedMsg = false;
    this.ApplicationPending=[];
    this.ApplicationApproved=[];
    this.ApplicationRejected=[];
    if(this.ModelListApplication.SearchFromDate!=null && this.ModelListApplication.SearchToDate!=null && this.ModelListApplication.Status > 0)
    {
      if(this.ModelListApplication.SearchToDate >= this.ModelListApplication.SearchFromDate)
      {
        if(this.ModelListApplication.Status == 1)
        {
          this.GetEmployeeTourStatusPending(this.ModelListApplication.SearchFromDate, this.ModelListApplication.SearchToDate);
        }
        else if(this.ModelListApplication.Status == 2)
        {
          this.GetEmployeeTourStatusApproved(this.ModelListApplication.SearchFromDate, this.ModelListApplication.SearchToDate);
        }
        else
        {
          this.GetEmployeeTourStatusRejected(this.ModelListApplication.SearchFromDate, this.ModelListApplication.SearchToDate);
        }
      }
      else
      {
        this.validTodate = true;
      }
    }
  }
}
