import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from '../../shared/user.service';
import { Menu } from '../../shared/menu.model';
import { EmployeePortalService }  from '../../shared/HR_ADMIN/EmployeePortal/EmployeePortalService.service';
import { TourReimbursementMasterModel }  from '../../shared/HR_ADMIN/EmployeePortal/TourReimbursementMasterModel.Model';
import { TourReimbursementDetailsModel }  from '../../shared/HR_ADMIN/EmployeePortal/TourReimbursementDetailsModel.Model';
import { ModalOptions,BsModalRef, BsModalService } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-tour-reimbursement-approval-for-hr',
  templateUrl: './tour-reimbursement-approval-for-hr.component.html',
  styleUrls: ['./tour-reimbursement-approval-for-hr.component.css']
})

export class TourReimbursementApprovalForHrComponent implements OnInit 
{
  ReimbursementApplications: TourReimbursementMasterModel[]=[];
  TourExpenseHeads: TourReimbursementDetailsModel[]=[];
  Application: any;
  Tour: any;
  ModelListApplication: TourReimbursementMasterModel;
  userClaims: any;
  SubMenues : Menu[]=[];
  Mod : any;
  Approver: any;
  Result : string;
  SuccessStatus:boolean=false;
  SuccessMessage: string ="";
  public tableWidget: any;
  RefId : any;
  // Begning Permission Portion    
  SelectedMenu:Menu;
  IsDataIsInViewState : boolean=false;
  CanViewState : boolean=false;  
  CanApproveState : boolean=false; 
  GridShow : boolean=false;
  DisableSave : boolean=true; 
  // End of the permission portion
  // begin for Validation
  validdate : boolean= false;
  // end of validation
  //For Pop up
  ApprovedMsg : boolean= false;
  RejectedMsg : boolean= false;
  ShowPopup : boolean= false;
  public modalRef: BsModalRef;
  public modelopt:ModalOptions;
  TotalExceptedApprovedAmount : number=null;
  NoRecordMsg : boolean;

  constructor(private EmployeePortalService:EmployeePortalService, private rout: Router, private userService: UserService,private modalService: BsModalService)
  { 
    this.ModelListApplication = new TourReimbursementMasterModel();
    this.modelopt=new ModalOptions();
    this.modelopt.class='heavy_modal';
    this.modalService.config= this.modelopt;
    this.modelopt.ignoreBackdropClick=true;
  }

  ngOnInit() 
  {
    //Begin Permission Portion
    this.SelectedMenu=this.userService.SelectedMenu;
    if(this.SelectedMenu !=undefined)
    {
      localStorage.setItem('selectedmenu',JSON.stringify(this.SelectedMenu));
      this.rout.navigate([this.SelectedMenu.RouteName]);
    }
    else
    {
      if(JSON.parse(localStorage.getItem('selectedmenu'))!=undefined)
      {
        this.SelectedMenu = JSON.parse(localStorage.getItem('selectedmenu'));
        this.rout.navigate([this.SelectedMenu.RouteName]);
      }
    }
    if(this.SelectedMenu.CanAdd)
    {
      this.IsDataIsInViewState=false;
    }
    else
    {
      this.IsDataIsInViewState=true;
    }
    if(this.SelectedMenu.CanView)
    {
      this.CanViewState=false;
    }
    else
    {
      this.CanViewState=true;
    }
    if(this.SelectedMenu.CanApprove)
    {
      this.CanApproveState=false;
    }
    else
    {
      this.CanApproveState=true;
    }
    //End of the peremission portion
    this.DisableSave=true;
    this.GetAllApplication();
    this.ApprovedMsg=false;
    this.RejectedMsg=false;
    this.ShowPopup=false;
    this.NoRecordMsg= false;
  }

  GetAllApplication()
  {
    this.ReimbursementApplications=[];
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.EmployeePortalService.GetReibursementApplicationForHR( t).then(x => 
    {
      this.NoRecordMsg= false;
      this.Approver = x;
      Object.keys(this.Approver).forEach( key => 
      {
        this.Approver[key];
        let ApproveEepense = new TourReimbursementMasterModel();
        ApproveEepense = this.Approver[key];
        this.ReimbursementApplications.push(ApproveEepense);
      });
      if(this.ReimbursementApplications.length==0)
      {
        this.NoRecordMsg= true;
      }
    });
  }

  viewTourReimbursementDetails(id,template) 
  {
    this.GetExpenseDetailsByRefIdTR(id);
    this.ModelListApplication.HRRemarks="";
    this.GridShow=true;
    this.IsDataIsInViewState=false;
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.EmployeePortalService.GetReibursementDetailsByRefId( t,id).then(x => 
    {
      if(this.ShowPopup==true)
      {
        this.modalRef.hide();
      }
      this.modalRef = this.modalService.show(template,this.modelopt);
      this.ShowPopup=true;
      this.ApprovedMsg=false;
      this.RejectedMsg=false;
      this.Approver = x;
      Object.keys(this.Approver).forEach( key => 
      {
        this.Approver[key];
        let Approvetemp = new TourReimbursementMasterModel();
        Approvetemp = this.Approver;
        this.ModelListApplication.RefIdTR=Approvetemp.RefIdTR;
        this.ModelListApplication.EmployeeId=Approvetemp.EmployeeId;
        this.ModelListApplication.EmployeeCode=Approvetemp.EmployeeCode;
        this.ModelListApplication.EmployeeName=Approvetemp.EmployeeName; 
        this.ModelListApplication.EmployeeEmailId=Approvetemp.EmployeeEmailId; 
        this.ModelListApplication.RHId=Approvetemp.RHId; 
        this.ModelListApplication.RHName=Approvetemp.RHName; 
        this.ModelListApplication.RHCode=Approvetemp.RHCode; 
        this.ModelListApplication.RHEmailId=Approvetemp.RHEmailId; 
        this.ModelListApplication.HREmailIds=Approvetemp.HREmailIds; 
        this.ModelListApplication.HRNames=Approvetemp.HRNames; 
        this.ModelListApplication.FHId=Approvetemp.FHId; 
        this.ModelListApplication.FHName=Approvetemp.FHName; 
        this.ModelListApplication.FHCode=Approvetemp.FHCode; 
        this.ModelListApplication.FHEmailId=Approvetemp.FHEmailId; 
        this.ModelListApplication.ExpenseName=Approvetemp.ExpenseName; 
        this.ModelListApplication.NoOfDays=Approvetemp.NoOfDays; 
        this.ModelListApplication.NoOfNights=Approvetemp.NoOfNights;
        this.ModelListApplication.NOofTrip=Approvetemp.NOofTrip;
        this.ModelListApplication.FromDate=Approvetemp.FromDate; 
        this.ModelListApplication.ToDate=Approvetemp.ToDate;
        this.ModelListApplication.TourPlace=Approvetemp.TourPlace; 
        this.ModelListApplication.AdvanceAmount=Approvetemp.AdvanceAmount;
        this.ModelListApplication.AdvancePaidDate=Approvetemp.AdvancePaidDate;
        this.ModelListApplication.ExpectedExpenseAmount=Approvetemp.ExpectedExpenseAmount;
        this.ModelListApplication.ApprovedExpenseAmount=Approvetemp.ApprovedExpenseAmount;
        this.ModelListApplication.RHRemarks=Approvetemp.RHRemarks;
        this.ModelListApplication.SentMailid=Approvetemp.SentMailid;
        this.ModelListApplication.SentMailPassword=Approvetemp.SentMailPassword;
        this.ModelListApplication.HRISURL=Approvetemp.HRISURL;
      });
    });
  }
  
  GetExpenseDetailsByRefIdTR(RefIdTR)
  {
    this.TourExpenseHeads=[];
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.EmployeePortalService.GetExpenseDetailsByRefIdTR( t,RefIdTR).then(x => 
    {
      this.Application = x;
      Object.keys(this.Application).forEach( key => 
      {
        this.Application[key];
        let Applicationtemp = new TourReimbursementDetailsModel();
        Applicationtemp = this.Application[key];
        this.TourExpenseHeads.push(Applicationtemp);
      });
    });
  }

  ApprovedTourReimbursementHR(template) :void
  {
    this.DisableSave=false;
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    if(this.ModelListApplication.RefIdTR > 0)
    {
      this.EmployeePortalService.ApprovedEmployeeTourReimbursementApplicationByHR(t ,this.ModelListApplication).subscribe(res=>
      {
        this.RefId=res;
        this.ModelListApplication.RefIdTR=0;  
        if(this.RefId>0)
        {
          this.ModelListApplication.RHRemarks="";
          this.modalRef.hide();
          this.ApprovedMsg=true;
          this.RejectedMsg=false;
          this.ShowPopup=false;
          this.modalRef = this.modalService.show(template);
          this.ReimbursementApplications=[];
          this.GetAllApplication();
          this.DisableSave=true;
        }
      });                  
    }
  }
}