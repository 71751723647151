<div class="body-content-area tabcontent" id="link1">
    <div class="row">
        <div class="col-lg-9">
            <div class="row">
                <div class="col-lg-1 low-padding text-center">
                    <!-- <img *ngIf="this.SelectedMenu.Image!=null" src={{this.SelectedMenu.Image}} class="body-icon" alt="Icon"/> -->
                </div>
                <div class="col-lg-10 clear-padding">
                    <div>
                    <span class="page-title">Employee Joining Letter</span>
                    <small class="page-description"></small>
                    </div>
                    <div *ngIf="!(this.CanViewState)">
                        <button *ngIf="!(this.IsButtonIsInViewState)" (click)="ADDClick()" type="button" class="btn btn-primary Add-button">ADD New</button> 
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div *ngIf="!(this.CanViewState)">
        <app-dialog [(visible)]="this.SuccessStatus">
            <span class="successmsg">{{this.SuccessMessage}}</span>
        </app-dialog>
        
        <fieldset class="body-form-control" *ngIf="!(this.IsOfferInViewState)">
            <legend class="legend--">Employee Offer Details</legend>

            <div *ngIf="NoRecordOfferMsg" style="color: #dc3545; text-align: center;font-size: 15px; font-weight: bold;">No Offer Record Found.</div>
            
            <div *ngIf="NoRecordOfferMsg">
                <button  type="button" style="width: 10%;" class="btn btn-danger cancle-button" (click)="RefreshPage()">Cancel</button>
            </div>
            <table id="tblOfferLetter" *ngIf="(this.IsOfferInViewState == false && this.Offers.length > 0)" datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" class="table table-striped table-bordered text-center table-new row-border hover">
                <thead class="thead-blue text-center">
                    <tr>
                        <th>Sl No.</th>
                        <th class="text-left">Name</th>
                        <th>Contact No</th>
                        <th class="text-left">Designation</th>
                        <th>Offer Date</th>
                        <th>Joining Date</th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let Offer of Offers, let i = index">
                        <td scope="row">{{i+1}}</td>
                        <td class="text-left">{{Offer.FullName}}</td>
                        <td>{{Offer.ContactNumber}}</td>
                        <td class="text-left">{{Offer.DesignationName}}</td>
                        <td>{{Offer.OfferDate | date : "dd-MMM-yyyy"}}</td>
                        <td>{{Offer.joiningDate | date : "dd-MMM-yyyy"}}</td>
                        <td>
                            <button *ngIf="this.SelectedMenu.CanEdit" type="button" (click)="GetOfferDetailsByid(Offer.OfferId)" class="table-edit-btn"><i class="fas fa-edit"></i> </button> 
                            <button (click)="RefreshPage()" type="button">Cancel</button> 
                        </td>
                    </tr>
                </tbody>
            </table>
        </fieldset>

        <fieldset class="body-form-control" *ngIf="!(this.IsjoiningInViewState)">
            <legend class="legend--">Employee Joining Details</legend>
            
            <div *ngIf="NoRecordMsg" style="color: #dc3545; text-align: center;font-size: 15px; font-weight: bold;">No Joining Record Found.</div>

            <table id="tblJoiningLetter" *ngIf="(this.IsjoiningInViewState == false && this.Joinings.length > 0)" datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" class="table table-striped table-bordered text-center table-new row-border hover">
                <thead class="thead-blue text-center">
                    <tr>
                        <th>Sl No.</th>
                        <th class="text-left">Name</th>
                        <th>Contact No</th>
                        <th class="text-left">Designation</th>
                        <th>Offer Date</th>
                        <th>Joining Date</th>
                        <th>Status</th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let jo of Joinings, let i = index">
                        <td scope="row">{{i+1}}</td>
                        <td class="text-left">{{jo.FullName}}</td>
                        <td>{{jo.ContactNumber}}</td>
                        <td class="text-left">{{jo.DesignationName}}</td>
                        <td>{{jo.OfferDate | date : "dd-MMM-yyyy"}}</td>
                        <td>{{jo.JoiningDate | date : "dd-MMM-yyyy"}}</td>
                        <td>{{jo.Joiningtatus}}</td>
                        <td>
                            <button *ngIf="this.SelectedMenu.CanEdit" type="button" (click)="GetJoiningDetailsByid(jo.JoiningId)" class="table-edit-btn"><i class="fas fa-edit"></i> </button> 
                            <button hidden="hidden" *ngIf="this.SelectedMenu.CanDelete" type="button" (click)="DeleteJoiningDetailsByid(jo.JoiningId )" class="table-remove-btn"><i class="fas fa-trash-alt"></i></button>
                            <button *ngIf="this.SelectedMenu.CanView" type="button" (click)="viewJoiningDetailsByid( jo.JoiningId)" class="table-view-button" type="button" ><i class="fas fa-eye"></i></button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </fieldset>

        <fieldset class="body-form-control" *ngIf="(this.IsFormView)">
            <legend class="legend--">Employee Joining Letter Modification</legend>
            <form #JoiningForm="ngForm" (ngSubmit)="saveJoiningDetails()">
                <div class="row">
                    <div class="col-lg-4">
                        <div class="col-body-border-right">
                            <div class="form-group row">
                                <label  class="col-sm-4 col-form-label clear-padding-right">Name</label>
                                <div class="col-sm-7 clear-left-padding">
                                    :&nbsp;&nbsp;{{JoiningModel.FullName}}
                                    <input type="text" hidden="hidden" [(ngModel)]="JoiningModel.JoiningId" id ="JoiningId" name="JoiningId" #JoiningId="ngModel">
                                    <input type="text" hidden="hidden" [(ngModel)]="JoiningModel.OfferRefid" id ="OfferRefid" name="OfferRefid" #OfferRefid="ngModel">
                                </div>
                            </div>
                            <div class="form-group row">
                                <label  class="col-sm-4 col-form-label clear-padding-right">Assets</label>
                                <div class="col-sm-7 clear-left-padding">
                                    <input type="text" [(ngModel)]="JoiningModel.Assets" tabindex="2" maxlength="250" id ="Assets"  name="Assets" #Assets="ngModel" class="form-control form-control-custom form-control-custom-new" >
                                </div>
                                <div *ngIf="Assets.invalid && (Assets.dirty || Assets.touched)" class="alert alert-danger">
                                    <div *ngIf="Assets.errors.maxlength">Assets is not more than 250 characters</div>
                                </div>
                            </div>
                            <div class="form-group row">
                                <label  class="col-sm-4 col-form-label clear-padding-right">InHandSalary</label>
                                <div class="col-sm-7 clear-left-padding">
                                    :&nbsp;&nbsp;{{JoiningModel.InHandSalary| number:'3.0-0'}}
                                </div>
                            </div>
                            <div class="form-group row">
                                <label class="col-sm-4 col-form-label clear-padding-right">Department</label>
                                <div class="col-sm-7 clear-left-padding">
                                    :&nbsp;&nbsp;{{JoiningModel.DepartmentName}}
                                </div>
                            </div>
                            <div class="form-group row">
                                <label  class="col-sm-4 col-form-label clear-padding-right">Grade</label>
                                <div class="col-sm-7 clear-left-padding">
                                    :&nbsp;&nbsp;{{JoiningModel.GradeName}}
                                </div>
                            </div>
                            <div class="form-group row">
                            <label  class="col-sm-4 col-form-label clear-padding-right">Addres 1</label>
                            <div class="col-sm-7 clear-left-padding">
                                :&nbsp;&nbsp;{{JoiningModel.Address1}}
                            </div>
                            </div>
                            <div class="form-group row">
                                <label  class="col-sm-4 col-form-label clear-padding-right">City</label>
                                <div class="col-sm-7 clear-left-padding">
                                    :&nbsp;&nbsp;{{JoiningModel.CityName}}
                                </div>
                            </div>
                            <div class="form-group row">
                            <label  class="col-sm-4 col-form-label clear-padding-right">Pin Code</label>
                            <div class="col-sm-7 clear-left-padding">
                                :&nbsp;&nbsp;{{JoiningModel.PinCode}}
                            </div>
                            </div>
                        </div>
                    </div>
                    
                    <div class="col-lg-4">
                        <div class="col-body-border-right">
                            <div class="form-group row">
                                <label  class="col-sm-4 col-form-label clear-padding-right">Reporting Date</label>
                                <div class="col-sm-7 clear-left-padding">
                                    :&nbsp;&nbsp;{{JoiningModel.ReportingDate| date : "dd-MMM-yyyy"}}
                                </div>
                            </div>
                            <div class="form-group row">
                                <label  class="col-sm-4 col-form-label clear-padding-right">Records</label>
                                <div class="col-sm-7 clear-left-padding">
                                    <input type="text" [(ngModel)]="JoiningModel.Records" tabindex="3" maxlength="250" id ="Records"  name="Records" #Records="ngModel" class="form-control form-control-custom form-control-custom-new" >
                                </div>
                                <div *ngIf="Records.invalid && (Records.dirty || Records.touched)" class="alert alert-danger">
                                    <div *ngIf="Records.errors.maxlength">Records is not more than 250 characters</div>
                                </div>
                            </div>
                            <div class="form-group row">
                                <label  class="col-sm-4 col-form-label clear-padding-right">Company</label>
                                <div class="col-sm-7 clear-left-padding">
                                    :&nbsp;&nbsp;{{JoiningModel.CompanyName}}
                                </div>
                            </div>
                            <div class="form-group row">
                                <label  class="col-sm-4 col-form-label clear-padding-right">Decision Making</label>
                                <div class="col-sm-7 clear-left-padding">
                                    :&nbsp;&nbsp;{{JoiningModel.DecisionName}}
                                </div>
                            </div>
                            <div class="form-group row">
                                <label  class="col-sm-4 col-form-label clear-padding-right">Designation</label>
                                <div class="col-sm-7 clear-left-padding">
                                    :&nbsp;&nbsp;{{JoiningModel.DesignationName}}
                                </div>
                            </div>
                            <div class="form-group row">
                                <label  class="col-sm-4 col-form-label clear-padding-right">Address 2</label>
                                <div class="col-sm-7 clear-left-padding">
                                    :&nbsp;&nbsp;{{JoiningModel.Address2}}
                                </div>
                            </div>
                            <div class="form-group row">
                                <label  class="col-sm-4 col-form-label clear-padding-right">State</label>
                                <div class="col-sm-7 clear-left-padding">
                                    :&nbsp;&nbsp;{{JoiningModel.StateName}}
                                </div>
                            </div>
                            <div class="form-group row">
                                <label  class="col-sm-4 col-form-label clear-padding-right">Mobile No</label>
                                <div class="col-sm-7 clear-left-padding">
                                    :&nbsp;&nbsp;{{JoiningModel.ContactNumber}}
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-4">
                        <div class="form-group row">
                            <label  class="col-sm-4 col-form-label clear-padding-right" >Joining Date</label>
                            <div class="col-sm-7 clear-left-padding">
                                <input type="date" name="JoiningDate" tabindex="1" [ngModel]="JoiningModel.JoiningDate| date:'yyyy-MM-dd'" (ngModelChange)="dateChanged($event)" #JoiningDate="ngModel" required />
                                <label *ngIf="(!validdate)" class="required-validation">Select Valid Date</label>
                            </div>
                        </div>
                        <div class="form-group row">
                            <label  class="col-sm-4 col-form-label clear-padding-right">CTC</label>
                            <div class="col-sm-7 clear-left-padding">
                                :&nbsp;&nbsp;{{JoiningModel.CTCSalary| number:'3.0-0'}}
                            </div>
                        </div>
                        <div class="form-group row">
                            <label  class="col-sm-4 col-form-label clear-padding-right">Branch</label>
                            <div class="col-sm-7 clear-left-padding">
                                :&nbsp;&nbsp;{{JoiningModel.BranchName}}
                            </div>  
                        </div>
                        <div class="form-group row">
                            <label  class="col-sm-4 col-form-label clear-padding-right">Level</label>
                            <div class="col-sm-7 clear-left-padding">
                                :&nbsp;&nbsp;{{JoiningModel.LevelName}}
                            </div>  
                        </div>
                        <div class="form-group row">
                            <label  class="col-sm-4 col-form-label clear-padding-right">Offer Date</label>
                            <div class="col-sm-7 clear-left-padding">
                                :&nbsp;&nbsp;{{JoiningModel.OfferDate| date : "dd-MMM-yyyy"}}
                            </div>
                        </div>
                        <div class="form-group row">
                            <label  class="col-sm-4 col-form-label clear-padding-right">District</label>
                            <div class="col-sm-7 clear-left-padding">
                                :&nbsp;&nbsp;{{JoiningModel.District}}
                            </div>
                        </div>
                        <div class="form-group row">
                            <label  class="col-sm-4 col-form-label clear-padding-right">Country</label>
                            <div class="col-sm-7 clear-left-padding">
                                :&nbsp;&nbsp;{{JoiningModel.CountryName}}
                            </div>
                        </div>
                        <div class="form-group row">
                        <label  class="col-sm-4 col-form-label clear-padding-right">Email Id</label>
                        <div class="col-sm-7 clear-left-padding">
                            :&nbsp;&nbsp;{{JoiningModel.EmailId}}
                        </div>
                        </div>
                        <div class="form-group row">
                            <label  class="col-sm-6 col-form-label text-center clear-padding-right" *ngIf="!(this.IsDataIsInViewState)">
                                <button type="submit" tabindex="4" class="btn btn-primary save-button" [disabled]="!(JoiningForm.valid && JoiningModel.JoiningApproved!=true && this.validdate == true && this.DisableSave==true)" >Save</button>  
                            </label>
                            <div class="col-sm-6 col-form-label text-center">
                                <button  type="button" tabindex="5" class="btn btn-danger cancle-button" (click)="RefreshPage()">Cancel</button>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </fieldset>
    </div>
</div>