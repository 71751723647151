<div class="body-content-area tabcontent" id="link1">
    <div class="row">
        <div class="col-lg-9">
            <div class="row">
                <div class="col-lg-1 low-padding text-center">
                    <!-- <img *ngIf="this.SelectedMenu.Image!=null" src={{this.SelectedMenu.Image}} class="body-icon" alt="Icon"/> -->
                </div>
                <div class="col-lg-10 clear-padding">
                    <span class="page-title">Leave Rule</span>
                    <small class="page-description"></small>
                </div>
            </div>
        </div>

        <app-dialog [(visible)]="this.SuccessStatus">
            <span class="successmsg">{{this.SuccessMessage}}</span>
        </app-dialog>
    </div>

    <div *ngIf="!(this.CanViewState)">
    <table id="tblLeaveRule" datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" class="table table-striped table-bordered text-center table-new row-border hover">
        <thead class="thead-blue text-center">
            <tr>
                <th>Sl No.</th>
                <th class="text-left">Company</th>
                <th>Year</th>
                <th>Action</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let lr of LeaveRules, let i = index">
                <td scope="row">{{i+1}}</td>
                <td class="text-left">{{lr.CompanyName}}</td>             
                <td>{{lr.Year}}</td>
                <td>
                    <button *ngIf="this.SelectedMenu.CanEdit" type="button" (click)="GetLeaveRuleByid(lr.CompanyId, lr.Year)" class="table-edit-btn"><i class="fas fa-edit"></i> </button> 
                    <button *ngIf="this.SelectedMenu.CanDelete" hidden="hidden" type="button" (click)="DeleteLeaveRuleByid( lr.LeaveRuleId )" class="table-remove-btn"><i class="fas fa-trash-alt"></i>  </button>
                    <button *ngIf="this.SelectedMenu.CanView" type="button" (click)="viewLeaveRuleByid(lr.CompanyId,  lr.Year)" class="table-view-button" type="button" ><i class="fas fa-eye"></i></button>
                </td>
            </tr>
        </tbody>
    </table>
    <fieldset class="body-form-control" *ngIf="LeavecompRules.length > 0" >
        <legend class="legend--">Leave Rule Creation</legend>
        <form #LeaveRule="ngForm" (ngSubmit)="saveLeaveRule()">
            <div class="row">
                <div class="col-lg-4">
                    <div class="col-body-border-right">
                        <div class="form-group row">
                            <label  class="col-sm-4 col-form-label clear-padding-right"> Company</label>
                            <div class="col-sm-7 clear-left-padding">
                                <select disabled="disabled" [(ngModel)]="LeaveRuleModel.CompanyId" tabindex="1" id ="CompanyId" [disabled]="IsCompanyEnable" class="form-control"  name="CompanyId" #CompanyId="ngModel"  (ngModelChange)="onCompanychange($event)">
                                    <option [value]="0" [disabled]=true >Select Company</option>
                                    <option *ngFor="let comp of Companies" [value]="comp.CompId">{{comp.CompName}}</option>
                                </select>
                            </div>
                            <div *ngIf="this.check.length > 0" class="alert alert-danger">Leave is already exist for selected Company</div>
                        </div>
                    </div>
                </div>
                
                <div class="col-lg-4">
                    <div class="form-group row">
                        <label  class="col-sm-4 col-form-label clear-padding-right">Year</label>
                        <div class="col-sm-7 clear-left-padding">
                        <select disabled="disabled" [(ngModel)]="LeaveRuleModel.Year" tabindex="3" id ="Year" class="form-control" [disabled]="IsYearEnable"  name="Year" #Year="ngModel">
                            <option [value]="0" [disabled]=true >Select Year</option>
                            <option *ngFor="let yr of Years" [value]="yr.Year">{{yr.Year}}</option>
                        </select>
                    </div>
                    <div *ngIf="this.check.length > 0" class="alert alert-danger">Leave is already exist for selected Branch</div>
                    </div>
                </div>
            </div>
            <div>
                <table id="tblLeave" class="table table-striped table-bordered text-left table-new row-border hover">
                    <thead class="thead-blue text-center">
                        <tr>
                            <th>Sl No.</th>
                            <th>Leave Type</th>
                            <th>No Of Leave</th>
                            <th>Is Transferable</th>
                            <th>Is AfterConfirmation</th>
                            <th>Effected after no of months</th>
                            <th>IsMonthly</th>
                            <th>IsYearly</th>
                            <th>IsAllTime</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let lv of LeavecompRules, let i = index">
                            <td scope="row">{{i+1}}</td>
                            <td>
                                <span>{{lv.LeaveTypeName}}</span>
                                <!-- <input type="text" hidden="hidden" [(ngModel)]="lv.LeaveRuleId" id ="LeaveRuleId" name="LeaveRuleId" #LeaveRuleId="ngModel"> -->
                            </td>
                            <td>
                                <input type="number" [(ngModel)]="lv.NoOfLeave" min="0" name="NoOfLeave_{{i}}" #NoOfLeave="ngModel" class="form-control form-control-custom form-control-custom-new">
                            </td>
                            <td>
                                <input type="radio" [checked]="LeavecompRules[i].Istransfirm=='IsTransferable'" (click)="OnChangeIsTransfer($event,i)" id ="IsTransferable_{{i}}" name="IsTransferable_{{i}}" class="form-control form-control-custom form-control-custom-new">
                                <!-- [value]="IsTransferable" -->
                            </td>
                            <td>
                                <input type="radio" [checked]="LeavecompRules[i].IsConfirm=='IsAfterConfirmation'" (click)="OnChangeIsConfirmation($event,i)" id ="IsAfterConfirmation_{{i}}" name="IsAfterConfirmation_{{i}}" class="form-control form-control-custom form-control-custom-new">
                                <!-- [value]="IsAfterConfirmation" -->
                            </td>
                            <td>
                                <input type="text" [(ngModel)]="lv.AvaleafterNoMonths" name="AvaleafterNoMonths_{{i}}" #AvaleafterNoMonths="ngModel" class="form-control form-control-custom form-control-custom-new" (blur)="onMonthchangechange(i)"  >
                            </td>
                            <td>
                                <input type="radio" [checked]="LeavecompRules[i].value=='IsMonthly'" (click)="OnChangeIsMonthly($event,i)" id ="IsMonthly_{{i}}" name="IsMonthly_{{i}}" class="form-control form-control-custom form-control-custom-new">
                                <!-- [value]="IsMonthly"  -->
                            </td>
                            <td>
                                <input type="radio" [checked]="LeavecompRules[i].value=='IsYearly'" (click)="OnChangeIsYearly($event,i)" id ="IsYearly_{{i}}" name="IsYearly_{{i}}" class="form-control form-control-custom form-control-custom-new">
                                <!-- [value]="IsYearly" -->
                            </td>
                            <td>
                                <input type="radio" [checked]="LeavecompRules[i].value=='IsAllTime'" (click)="OnChangeIsAllTime($event,i)" id ="IsAllTime_{{i}}" name="IsAllTime_{{i}}" class="form-control form-control-custom form-control-custom-new">
                                <!-- [value]="IsAllTime" -->
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="row">
                <div class="col-lg-4">

            </div>
            <div class="col-lg-4">

            </div>
            <div class="col-lg-4">
                <div class="form-group row">
                    <label class="col-sm-6 col-form-label text-center clear-padding-right" *ngIf="!(this.IsDataIsInViewState)">
                        <button type="submit" tabindex="4" class="btn btn-primary save-button" [disabled]="!(this.LeavesIsValid==true && this.DisableSave==true)">Modified</button>  
                    </label>
                    <div class="col-sm-6 col-form-label text-center">       
                    <button type="button" tabindex="5" class="btn btn-danger cancle-button" (click)="RefreshPage()">Cancel</button></div>
                </div>
                </div>
            </div>
        </form>
    </fieldset>
    </div>
</div>