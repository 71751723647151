import { Injectable,Inject } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';  
import { Http, Headers, RequestOptions, Response } from '@angular/http';  
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import 'rxjs/add/operator/toPromise';
import { Subscriber } from 'rxjs';
import { GlobalVariable  } from '../../../app.global';
import { Country } from   '../../Organization/Country.Model';
import { Zone } from   '../../Organization/Zone.Model';
import { State } from   '../../Organization/State.Model';
import { District } from   '../Location/District.Model';
import { City } from   '../Location/City.Model';

@Injectable()
export class LocationService {

// Begning of the country
  constructor(private http: HttpClient, private spinnerService: Ng4LoadingSpinnerService) 
  {

  }

  GetCountry(token )
  {
    this.spinnerService.show();
    const authHeader = new HttpHeaders();     
    authHeader.append('Content-Type', 'application/json');
    authHeader.append('Authorization', token);
    return new Promise((resolve, reject) => 
    {
      setTimeout(()=>{

      this.http.get(GlobalVariable.BASE_API_URL +'/api/Country/GetCountry',{headers: authHeader})
      .subscribe(data => 
      {
        this.spinnerService.hide();
        resolve(data)
      },
      error => 
      {
        this.spinnerService.hide();
        reject(error);
      },);
    },3000);
    });
  }

  GetCountryByid(token, id:number ) 
  {
    this.spinnerService.show();
    const authHeader = new HttpHeaders();     
    authHeader.append('Content-Type', 'application/json');
    authHeader.append('Authorization', token);
    return new Promise((resolve, reject) => 
    {
      setTimeout(()=>{
      this.http.get(GlobalVariable.BASE_API_URL +'/api/Country/GetCountryById?Countryid='+id,{headers: authHeader})
      .subscribe(data => 
      {
        this.spinnerService.hide();
        resolve(data)
      },
      error => 
      {
        this.spinnerService.hide();
        reject(error);
      },);
      },3000);
    });
  }

  AddCountry( token ,country :Country) 
  {
    const authHeader = new HttpHeaders();         
    authHeader.append('Content-Type', 'application/json');
    authHeader.append('Authorization', token);
    authHeader.append('Accept', 'application/json');
     return this.http.post(GlobalVariable.BASE_API_URL +'/api/Country/Add', country ,{headers: authHeader} )
    .map(this.extractData)
    .catch(this.handleErrorObservable);
  }

  DeleteCountry( token ,countryid : number) 
  {
    const authHeader = new HttpHeaders();         
    authHeader.append('Content-Type', 'application/json');
    authHeader.append('Authorization', token);
    authHeader.append('Accept', 'application/json');
    return this.http.put(GlobalVariable.BASE_API_URL +'/api/Country/Delete', countryid ,{headers: authHeader} )
    .map(this.extractData)
    .catch(this.handleErrorObservable);
  }
 
  UpdateCountry( token ,country :Country) 
  {
    const authHeader = new HttpHeaders();         
    authHeader.append('Content-Type', 'application/json');
    authHeader.append('Authorization', token);
    authHeader.append('Accept', 'application/json');
    return this.http.put(GlobalVariable.BASE_API_URL +'/api/Country/Update', country ,{headers: authHeader} )
    .map(this.extractData)
    .catch(this.handleErrorObservable);
  }

  CheckCountryName(token, Name:string ) 
  {
    this.spinnerService.show();
    const authHeader = new HttpHeaders();     
    authHeader.append('Content-Type', 'application/json');
    authHeader.append('Authorization', token);
    return new Promise((resolve, reject) => 
    {
      setTimeout(()=>{
      this.http.get(GlobalVariable.BASE_API_URL +'/api/Country/CheckCountryName?CountryName='+Name,{headers: authHeader})
      .subscribe(data => 
      {
        this.spinnerService.hide();
        resolve(data)
      },
      error => 
      {
        this.spinnerService.hide();
        reject(error);
      },);
    },3000);
    });
  }
//end of country

  // Begin of the Zone
  GetZone(token )
  {
    this.spinnerService.show();
    const authHeader = new HttpHeaders();     
    authHeader.append('Content-Type', 'application/json');
    authHeader.append('Authorization', token);
    return new Promise((resolve, reject) =>
    {
      setTimeout(()=>{
      this.http.get(GlobalVariable.BASE_API_URL +'/api/Zone/GetZone',{headers: authHeader})
      .subscribe(data => 
      {
        this.spinnerService.hide();
        resolve(data)
      },
      error => 
      {
        this.spinnerService.hide();
        reject(error);
      },);
    },3000);

    });
  }
  GetZoneByCountryId(token , Countryid)
  {
    
    const authHeader = new HttpHeaders();     
    authHeader.append('Content-Type', 'application/json');
    authHeader.append('Authorization', token);
    
    return new Promise((resolve, reject) =>
    {
      setTimeout(()=>{
      this.http.get(GlobalVariable.BASE_API_URL +'/api/Zone/GetZoneByCountryId?CountryId='+Countryid,{headers: authHeader})
      .subscribe(data => 
      {
        
        resolve(data)
      },
      error => 
      {
        
        reject(error);
      },);
    },3000);
    });
  }
  GetZoneByid(token, id:number ) 
  {
    this.spinnerService.show();
    const authHeader = new HttpHeaders();     
    authHeader.append('Content-Type', 'application/json');
    authHeader.append('Authorization', token);
    return new Promise((resolve, reject) => 
    {
      setTimeout(()=>{
      this.http.get(GlobalVariable.BASE_API_URL +'/api/Zone/GetZoneById?Zoneid='+id,{headers: authHeader})
      .subscribe(data => 
      {
        this.spinnerService.hide();
        resolve(data)
      },
      error => 
      {
        this.spinnerService.hide();
        reject(error);
      },);
    },3000);
    });
  }
  AddZone( token ,zone :Zone) 
  {
    const authHeader = new HttpHeaders();         
    authHeader.append('Content-Type', 'application/json');
    authHeader.append('Authorization', token);
    authHeader.append('Accept', 'application/json');
    return this.http.post(GlobalVariable.BASE_API_URL +'/api/Zone/Add', zone ,{headers: authHeader} )
    .map(this.extractData)
    .catch(this.handleErrorObservable);
  }
  DeleteZone( token ,zoneid : number) 
  {
    const authHeader = new HttpHeaders();         
    authHeader.append('Content-Type', 'application/json');
    authHeader.append('Authorization', token);
    authHeader.append('Accept', 'application/json');
    return this.http.put(GlobalVariable.BASE_API_URL +'/api/Zone/Delete', zoneid ,{headers: authHeader} )
    .map(this.extractData)
    .catch(this.handleErrorObservable);
  }
  UpdateZone( token ,zone :Zone) 
  {
    const authHeader = new HttpHeaders();         
    authHeader.append('Content-Type', 'application/json');
    authHeader.append('Authorization', token);
    authHeader.append('Accept', 'application/json');
    return this.http.put(GlobalVariable.BASE_API_URL +'/api/Zone/Update', zone ,{headers: authHeader} )
    .map(this.extractData)
    .catch(this.handleErrorObservable);
  }
  CheckZoneName(token, Name:string, CountryId:number ) 
  {
    this.spinnerService.show();
    const authHeader = new HttpHeaders();     
    authHeader.append('Content-Type', 'application/json');
    authHeader.append('Authorization', token);
    return new Promise((resolve, reject) => 
    {
      setTimeout(()=>{
      this.http.get(GlobalVariable.BASE_API_URL +'/api/Zone/CheckZoneName?ZoneName='+Name+'&CountryId='+CountryId,{headers: authHeader})
      .subscribe(data => 
      {
        this.spinnerService.hide();
        resolve(data)
      },
      error => 
      {
        this.spinnerService.hide();
        reject(error);
      },);
    },3000);
    });
  }
  // End of the Zone

  // Begin of the State
  GetState(token )
  { this.spinnerService.show();
    const authHeader = new HttpHeaders();     
    authHeader.append('Content-Type', 'application/json');
    authHeader.append('Authorization', token);
    return new Promise((resolve, reject) =>
    {
      setTimeout(()=>{
      this.http.get(GlobalVariable.BASE_API_URL +'/api/State/GetState',{headers: authHeader})
      .subscribe(data => 
      {
        this.spinnerService.hide();
        resolve(data)
      },
      error => 
      {
        this.spinnerService.hide();
        reject(error);
      },);
    },3000);
    });
  }
  GetStateByid(token, id:number ) 
  {
    this.spinnerService.show();
    const authHeader = new HttpHeaders();     
    authHeader.append('Content-Type', 'application/json');
    authHeader.append('Authorization', token);
    return new Promise((resolve, reject) => 
    {
      setTimeout(()=>{
      this.http.get(GlobalVariable.BASE_API_URL +'/api/State/GetStateById?Stateid='+id,{headers: authHeader})
      .subscribe(data => 
      {
        this.spinnerService.hide();
        resolve(data)
      },
      error => 
      {
        this.spinnerService.hide();
        reject(error);
      },);
    },3000);
    });
  }
  AddState( token ,State :State) 
  {
    const authHeader = new HttpHeaders();         
    authHeader.append('Content-Type', 'application/json');
    authHeader.append('Authorization', token);
    authHeader.append('Accept', 'application/json');
    return this.http.post(GlobalVariable.BASE_API_URL +'/api/State/Add', State ,{headers: authHeader} )
    .map(this.extractData)
    .catch(this.handleErrorObservable);
  }
  DeleteState( token ,Stateid : number) 
  {
    const authHeader = new HttpHeaders();         
    authHeader.append('Content-Type', 'application/json');
    authHeader.append('Authorization', token);
    authHeader.append('Accept', 'application/json');
    return this.http.put(GlobalVariable.BASE_API_URL +'/api/State/Delete', Stateid ,{headers: authHeader} )
    .map(this.extractData)
    .catch(this.handleErrorObservable);
  }
  UpdateState( token ,State :State) 
  {
    const authHeader = new HttpHeaders();         
    authHeader.append('Content-Type', 'application/json');
    authHeader.append('Authorization', token);
    authHeader.append('Accept', 'application/json');
    return this.http.put(GlobalVariable.BASE_API_URL +'/api/State/Update', State ,{headers: authHeader} )
    .map(this.extractData)
    .catch(this.handleErrorObservable);
  }
  CheckStateName(token, Name:string, CountryId:number ) 
  {
    this.spinnerService.show();
    const authHeader = new HttpHeaders();     
    authHeader.append('Content-Type', 'application/json');
    authHeader.append('Authorization', token);
    return new Promise((resolve, reject) => 
    {
      setTimeout(()=>{
      this.http.get(GlobalVariable.BASE_API_URL +'/api/State/CheckStateName?StateName='+Name+'&CountryId='+CountryId,{headers: authHeader})
      .subscribe(data => 
      {
        this.spinnerService.hide();
        resolve(data)
      },
      error => 
      {
        this.spinnerService.hide();
        reject(error);
      },);
    },3000);
    });
  }
  // End of the State
  // Begin of the District
  GetDistrict(token )
  { 
    this.spinnerService.show();
    const authHeader = new HttpHeaders();     
    authHeader.append('Content-Type', 'application/json');
    authHeader.append('Authorization', token);
    return new Promise((resolve, reject) =>
    {
      setTimeout(()=>{
      this.http.get(GlobalVariable.BASE_API_URL +'/api/District/GetDistrict',{headers: authHeader})
      .subscribe(data => 
      {
        this.spinnerService.hide();
        resolve(data)
      },
      error => 
      {
        this.spinnerService.hide();
        reject(error);
      },);
    },3000);
    });
  }
  GetDistrictByid(token, id:number ) 
  {
    this.spinnerService.show();
    const authHeader = new HttpHeaders();     
    authHeader.append('Content-Type', 'application/json');
    authHeader.append('Authorization', token);
    return new Promise((resolve, reject) => 
    {
      setTimeout(()=>{
      this.http.get(GlobalVariable.BASE_API_URL +'/api/District/GetDistrictById?Districtid='+id,{headers: authHeader})
      .subscribe(data => 
      {
        this.spinnerService.hide();
        resolve(data)
      },
      error => 
      {
        this.spinnerService.hide();
        reject(error);
      },);
    },3000);
    });
  }
  GetDistrictByStateid(token, Stateid:number ) 
  {
    this.spinnerService.show();
    const authHeader = new HttpHeaders();     
    authHeader.append('Content-Type', 'application/json');
    authHeader.append('Authorization', token);
    return new Promise((resolve, reject) => 
    {
      setTimeout(()=>{
      this.http.get(GlobalVariable.BASE_API_URL +'/api/District/GetDistrictByStateId?Stateid='+Stateid,{headers: authHeader})
      .subscribe(data => 
      {
        this.spinnerService.hide();
        resolve(data)
      },
      error => 
      {
        this.spinnerService.hide();
        reject(error);
      },);
    },3000);
    });
  }
  AddDistrict( token ,District :District) 
  {
    const authHeader = new HttpHeaders();         
    authHeader.append('Content-Type', 'application/json');
    authHeader.append('Authorization', token);
    authHeader.append('Accept', 'application/json');
    return this.http.post(GlobalVariable.BASE_API_URL +'/api/District/Add', District ,{headers: authHeader} )
    .map(this.extractData)
    .catch(this.handleErrorObservable);
  }
  UpdateDistrict( token ,District :District) 
  {
    const authHeader = new HttpHeaders();         
    authHeader.append('Content-Type', 'application/json');
    authHeader.append('Authorization', token);
    authHeader.append('Accept', 'application/json');
    return this.http.put(GlobalVariable.BASE_API_URL +'/api/District/Update', District ,{headers: authHeader} )
    .map(this.extractData)
    .catch(this.handleErrorObservable);
  }
  DeleteDistrict( token ,Distid : number) 
  {
    const authHeader = new HttpHeaders();         
    authHeader.append('Content-Type', 'application/json');
    authHeader.append('Authorization', token);
    authHeader.append('Accept', 'application/json');
    return this.http.put(GlobalVariable.BASE_API_URL +'/api/District/Delete', Distid ,{headers: authHeader} )
    .map(this.extractData)
    .catch(this.handleErrorObservable);
  }
  CheckDistrictName(token, Name:string ) 
  {
    this.spinnerService.show();
    const authHeader = new HttpHeaders();     
    authHeader.append('Content-Type', 'application/json');
    authHeader.append('Authorization', token);
    return new Promise((resolve, reject) => 
    {
      setTimeout(()=>{
      this.http.get(GlobalVariable.BASE_API_URL +'/api/District/CheckDistrictName?DistName='+Name,{headers: authHeader})
      .subscribe(data => 
      {
        this.spinnerService.hide();
        resolve(data)
      },
      error => 
      {
        this.spinnerService.hide();
        reject(error);
      },);
    },3000);
    });
  }
  CheckDistrictCode(token, Code:string ) 
  {
    this.spinnerService.show();
    const authHeader = new HttpHeaders();     
    authHeader.append('Content-Type', 'application/json');
    authHeader.append('Authorization', token);
    return new Promise((resolve, reject) => 
    {
      setTimeout(()=>{
      this.http.get(GlobalVariable.BASE_API_URL +'/api/District/CheckDistrictCode?DistCode='+Code,{headers: authHeader})
      .subscribe(data => 
      {
        this.spinnerService.hide();
        resolve(data)
      },
      error => 
      {
        this.spinnerService.hide();
        reject(error);
      },);
    },3000);
    });
  }
  // End of the District
  // Begin of the Dist.

  GetDist(token,  stateid )
  { 
    this.spinnerService.show();
    const authHeader = new HttpHeaders();     
    authHeader.append('Content-Type', 'application/json');
    authHeader.append('Authorization', token);
    return new Promise((resolve, reject) =>
    {
      setTimeout(()=>{
      this.http.get(GlobalVariable.BASE_API_URL +'/api/District/GetDistrictByStateId?Stateid='+stateid,{headers: authHeader})
      .subscribe(data => 
      {
        this.spinnerService.hide();
        resolve(data)
      },
      error => 
      {
        this.spinnerService.hide();
        reject(error);
      },);
    },3000);
    });
  }
  //End of the Dist
  // Begin of the City
  GetCity(token )
  { 
    this.spinnerService.show();
    const authHeader = new HttpHeaders();     
    authHeader.append('Content-Type', 'application/json');
    authHeader.append('Authorization', token);
    return new Promise((resolve, reject) =>
    {
      setTimeout(()=>{
      this.http.get(GlobalVariable.BASE_API_URL +'/api/City/GetCity',{headers: authHeader})
      .subscribe(data => 
      {
        this.spinnerService.hide();
        resolve(data)
      },
      error => 
      {
        this.spinnerService.hide();
        reject(error);
      },);
    },3000);
    });
  }
  GetCityByid(token, id:number ) 
  {
    this.spinnerService.show();
    const authHeader = new HttpHeaders();     
    authHeader.append('Content-Type', 'application/json');
    authHeader.append('Authorization', token);
    return new Promise((resolve, reject) => 
    {
      setTimeout(()=>{
      this.http.get(GlobalVariable.BASE_API_URL +'/api/City/GetCityById?Cityid='+id,{headers: authHeader})
      .subscribe(data => 
      {
        this.spinnerService.hide();
        resolve(data)
      },
      error => 
      {
        this.spinnerService.hide();
        reject(error);
      },);
    },3000);
    });
  }
  GetCityByDistrictid(token, id:number ) 
  {
    this.spinnerService.show();
    const authHeader = new HttpHeaders();     
    authHeader.append('Content-Type', 'application/json');
    authHeader.append('Authorization', token);
    return new Promise((resolve, reject) => 
    {
      setTimeout(()=>{
      this.http.get(GlobalVariable.BASE_API_URL +'/api/City/GetCityByDistId?Distid='+id,{headers: authHeader})
      .subscribe(data => 
      {
        this.spinnerService.hide();
        resolve(data)
      },
      error => 
      {
        this.spinnerService.hide();
        reject(error);
      },);
    },3000);
    });
  }
  AddCity( token ,City :City) 
  {
    const authHeader = new HttpHeaders();         
    authHeader.append('Content-Type', 'application/json');
    authHeader.append('Authorization', token);
    authHeader.append('Accept', 'application/json');
    return this.http.post(GlobalVariable.BASE_API_URL +'/api/City/Add', City ,{headers: authHeader} )
    .map(this.extractData)
    .catch(this.handleErrorObservable);
  }
  DeleteCity( token ,Cityid : number) 
  {
    const authHeader = new HttpHeaders();         
    authHeader.append('Content-Type', 'application/json');
    authHeader.append('Authorization', token);
    authHeader.append('Accept', 'application/json');
    return this.http.put(GlobalVariable.BASE_API_URL +'/api/City/Delete', Cityid ,{headers: authHeader} )
    .map(this.extractData)
    .catch(this.handleErrorObservable);
  }
  UpdateCity( token ,City :City) 
  {
    const authHeader = new HttpHeaders();         
    authHeader.append('Content-Type', 'application/json');
    authHeader.append('Authorization', token);
    authHeader.append('Accept', 'application/json');
    return this.http.put(GlobalVariable.BASE_API_URL +'/api/City/Update', City ,{headers: authHeader} )
    .map(this.extractData)
    .catch(this.handleErrorObservable);
  }
  CheckCityName(token, Name:string, DistId:number ) 
    {
      this.spinnerService.show();
      const authHeader = new HttpHeaders();     
      authHeader.append('Content-Type', 'application/json');
      authHeader.append('Authorization', token);
      return new Promise((resolve, reject) => 
      {
        setTimeout(()=>{
        this.http.get(GlobalVariable.BASE_API_URL +'/api/City/CheckCityName?CityName='+Name+'&DistId='+DistId,{headers: authHeader})
        .subscribe(data => 
        {
          this.spinnerService.hide();
          resolve(data)
        },
        error => 
        {
          this.spinnerService.hide();
          reject(error);
        },);
      },3000);
      });
    }
  // End of the City

  private extractData(res: Response) 
  {
    let body = res;
    return body || {};
  }

  private handleErrorObservable (error: Response | any) 
  {
    console.error(error.message || error);
    return Observable.throw(error.message || error);
  }

}
