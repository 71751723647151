import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from '../../shared/user.service';
import { KRAService }  from '../../shared/HR_ADMIN/KRA/KRAService.service';
import { KRAMasterModel } from '../../shared/HR_ADMIN/KRA/KRAMasterModel.Model';
import { KRAApplicationModel } from '../../shared/HR_ADMIN/KRA/KRAApplicationModel.Model';
//Begin For Notification service
import { NotificationsService} from '../../shared/notifications.service';
//End of notification service
// Begning Confirmation
import { ConfirmationService } from '@jaspero/ng2-confirmations';
import { ResolveEmit } from '../../../app/resolve-emit';
import { ConfirmSettings } from '../../../app/confirm-settings';
// end of Confirmation
import { Menu } from '../../shared/menu.model';

@Component({
  selector: 'app-manage-krauser-application',
  templateUrl: './manage-krauser-application.component.html',
  styleUrls: ['./manage-krauser-application.component.css']
})
export class ManageKrauserApplicationComponent implements OnInit 
{
  KRAMasterModel : KRAMasterModel= new KRAMasterModel();
  KRAApplicationModel : KRAApplicationModel= new KRAApplicationModel();
  EmployeeDetails: KRAApplicationModel[] = [];
  employee: any;
  KRAApplications: KRAApplicationModel[] = [];
  kraapplication: any;
   RefId : any;
  MasterRefId : any;
  userClaims: any;
  Result : string;
  SuccessStatus:boolean=false;
  SuccessMessage: string ="";
  public tableWidget: any;
  selectedIndex : number;
  // Begning Permission Portion    
  SelectedMenu:Menu;
  IsDataIsInViewState : boolean=false;
  CanViewState : boolean=true;
  IsApplicationView : boolean=false;
  DisableSave : boolean=false;
  Totalwts : number=0;
  TotalRating : number=null;
  // End of the permission portion
  NoRecordMsg : boolean;

  constructor(private KRAService: KRAService, private rout: Router, private userService: UserService, private _confirmation: ConfirmationService, private notificationsService: NotificationsService) 
  { 

  }

  ngOnInit() 
  {
    // Begning Permission Portion 
    this.SelectedMenu=this.userService.SelectedMenu;
    if(this.SelectedMenu !=undefined)
    {
      localStorage.setItem('selectedmenu',JSON.stringify(this.SelectedMenu));
      this.rout.navigate([this.SelectedMenu.RouteName]);
    }
    else
    {
      if(JSON.parse(localStorage.getItem('selectedmenu'))!=undefined)
      {
        this.SelectedMenu = JSON.parse(localStorage.getItem('selectedmenu'));
        this.rout.navigate([this.SelectedMenu.RouteName]);
      }
    }
    if(this.SelectedMenu.CanAdd)
    {
      this.IsDataIsInViewState=false;
    }
    else
    {
      this.IsDataIsInViewState=true;
    }
    if(this.SelectedMenu.CanView)
    {
      this.CanViewState=false;
    }
    else
    {
      this.CanViewState=true;
    }
    // End of the permission portion
    this.DisableSave=true;
    if(localStorage.getItem('SuccessfullMessage'))
    {
      this.SuccessStatus=true;
      this.SuccessMessage =localStorage.getItem('SuccessfullMessage')
      localStorage.removeItem('SuccessfullMessage');
    }
    this.Totalwts=0;
    this.GetEmployeeDetails();
    this.GetKRAApplication();
    this.NoRecordMsg = false;
  }

  settings: ConfirmSettings | any = 
  {
    overlay: true,
    overlayClickToClose: true,
    showCloseButton: true,
    confirmText: 'Yes',
    declineText: 'No'
  };
  
  GetEmployeeDetails()
  {
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.KRAService.GetEmployeeDetailsBYLoginId( t).then(x => 
    {
      this.employee = x;
      Object.keys(this.employee).forEach( key => 
      {
        this.employee[key];
        let KRAApplicationtemp = new KRAApplicationModel();
        KRAApplicationtemp = this.employee[key];
        this.KRAApplicationModel.EmployeeId=KRAApplicationtemp.EmployeeId;
        this.KRAApplicationModel.EmployeeCode=KRAApplicationtemp.EmployeeCode;
        this.KRAApplicationModel.EmployeeName=KRAApplicationtemp.EmployeeName;
        this.KRAApplicationModel.EmployeeEmailId=KRAApplicationtemp.EmployeeEmailId;
        this.KRAApplicationModel.DesignationName=KRAApplicationtemp.DesignationName;
        this.KRAApplicationModel.RHId=KRAApplicationtemp.RHId;
        this.KRAApplicationModel.RHCode=KRAApplicationtemp.RHCode;
        this.KRAApplicationModel.RHName=KRAApplicationtemp.RHName;
        this.KRAApplicationModel.RHEmailId=KRAApplicationtemp.RHEmailId;
        this.KRAApplicationModel.FHId=KRAApplicationtemp.FHId;
        this.KRAApplicationModel.FHCode=KRAApplicationtemp.FHCode;
        this.KRAApplicationModel.FHName=KRAApplicationtemp.FHName;
        this.KRAApplicationModel.FHEmailId=KRAApplicationtemp.FHEmailId;
        this.KRAApplicationModel.CompanyId=KRAApplicationtemp.CompanyId;
        this.KRAApplicationModel.CompanyName=KRAApplicationtemp.CompanyName;
        this.EmployeeDetails.push(KRAApplicationtemp);
      });
    });
  }
  
  GetKRAApplication() 
  { 
    this.KRAApplications=[];
    this.IsDataIsInViewState=false;
    this.KRAApplicationModel.IsActive=false;
    let t = localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.KRAService.GetKRAApplicationBYLoginId( t).then(x => 
    {
      this.Totalwts=0;
      this.RefId = x;
      if(this.RefId.length > 0)
      {
        this.KRAApplications=[];
      }
      Object.keys(this.RefId).forEach( key => 
      {
        this.NoRecordMsg = false;
        // tslint:disable-next-line:no-unused-expression
        this.RefId[key];
        let KRAApplicationtemp = new KRAApplicationModel();
        KRAApplicationtemp = this.RefId[key];
        if(KRAApplicationtemp.CurrentDate >= KRAApplicationtemp.StartApplicationDate && KRAApplicationtemp.CurrentDate <= KRAApplicationtemp.EndApplicationDate)
        {
          this.KRAApplicationModel.FinalSubmit=KRAApplicationtemp.FinalSubmit;
          this.KRAApplicationModel.MasterRefId=KRAApplicationtemp.MasterRefId;
          this.KRAApplicationModel.SessionId=KRAApplicationtemp.SessionId;
          this.KRAApplicationModel.FinYear=KRAApplicationtemp.FinYear;
          this.Totalwts=this.Totalwts + KRAApplicationtemp.Weighted;
          this.TotalRating=  Number(this.TotalRating) + (Number(KRAApplicationtemp.SelfRating/5) * (KRAApplicationtemp.Weighted));
          if(KRAApplicationtemp.SelfRating==0)
          {
            KRAApplicationtemp.SelfRating=null;
          }
          this.KRAApplications.push(KRAApplicationtemp);
        }
      });
      this.KRAApplicationModel.TotalWateges=this.Totalwts;
      this.KRAApplicationModel.FinalSelfRating=Math.round(this.TotalRating);
      if(this.KRAApplications.length == 0)
      {
        this.NoRecordMsg = true;
      }
    });
  }

  SaveKRAApplication() :void
  {
    this.DisableSave=false;
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t; 
    this.KRAService.DeActiveKRAApplicationlist(t ,this.KRAApplicationModel.MasterRefId).subscribe(res=>
    {
      if(this.KRAApplicationModel.MasterRefId > 0)
      {
        let t =  localStorage.getItem('userToken');
        t = 'Bearer' + t;
        this.KRAApplicationModel.FinalSubmit=false;
        this.KRAService.UpdateKRAApplicationMaster(t,this.KRAApplicationModel).subscribe(res=>
        {
          this.MasterRefId=res;
          let t = localStorage.getItem('userToken');
          t = 'Bearer' + t;    
          var j=0;
          var l=this.KRAApplications.length;
          this.KRAApplications.forEach(item => 
          {
            j=j+1;
            item.MasterRefId=this.MasterRefId;
            if(item.RefId==null)
            {
              item.RefId=0;
            }
            if(item.RefId > 0)
            {
              this.KRAService.UpdateKRAApplication(t ,item).subscribe(res=>
              {
                this.RefId=res;        
              });                  
            }                             
            else
            {
              this.KRAService.AddKRAApplication(t ,item).subscribe(res=>
              {
                this.RefId=res;         
              }); 
            }
          });
          if(this.MasterRefId>0)
          {
            localStorage.setItem('SuccessfullMessage', "KRA Modified Successfully");
            this.rout.navigateByUrl('/Appraisal/KRAMappingwithEmployee', {skipLocationChange: true}).then(()=>this.rout.navigate(["/Appraisal/KRAApplication"]));   
          }
        });
      }
      else
      {
        let t =  localStorage.getItem('userToken');
        t = 'Bearer' + t;
        this.KRAApplicationModel.FinalSubmit=false;
        this.KRAService.AddKRAApplicationMaster(t,this.KRAApplicationModel).subscribe(res=>
        {
          this.MasterRefId=res;
          let t = localStorage.getItem('userToken');
          t = 'Bearer' + t;    
          var j=0;
          var l=this.KRAApplications.length;
          this.KRAApplications.forEach(item => 
          {
            j=j+1;
            item.MasterRefId=this.MasterRefId;
            if(item.RefId==null)
            {
              item.RefId=0;
            }
            if(item.RefId > 0)
            {
              this.KRAService.UpdateKRAApplication(t ,item).subscribe(res=>
              {
                this.RefId=res;        
              });                  
            }                             
            else
            {
              this.KRAService.AddKRAApplication(t ,item).subscribe(res=>
              {
                this.RefId=res;         
              }); 
            }
          });
          if(this.MasterRefId>0)
          {
            localStorage.setItem('SuccessfullMessage', "KRA Modified Successfully");
            this.rout.navigateByUrl('/Appraisal/KRAMappingwithEmployee', {skipLocationChange: true}).then(()=>this.rout.navigate(["/Appraisal/KRAApplication"]));   
          }
        });
      }
    });  
  }

  FinalSubmitKRAApplication()
  {
    this._confirmation.create('Confirm to Final Submit', 'Press Yes to Final Submit', this.settings)
    .subscribe((ans: ResolveEmit) => 
    {
      if (ans.resolved == true) 
      {
        this.DisableSave=false;
        let t =  localStorage.getItem('userToken');
        t = 'Bearer' + t; 
        this.KRAService.DeActiveKRAApplicationlist(t ,this.KRAApplicationModel.MasterRefId).subscribe(res=>
        {
          if(this.KRAApplicationModel.MasterRefId > 0)
          {
            let t =  localStorage.getItem('userToken');
            t = 'Bearer' + t;
            this.KRAApplicationModel.FinalSubmit=true;
            this.KRAService.UpdateKRAApplicationMaster(t,this.KRAApplicationModel).subscribe(res=>
            {
              this.MasterRefId=res;
              let t = localStorage.getItem('userToken');
              t = 'Bearer' + t;    
              var j=0;
              var l=this.KRAApplications.length;
              this.KRAApplications.forEach(item => 
              {
                j=j+1;
                item.MasterRefId=this.MasterRefId;
                if(item.RefId==null)
                {
                  item.RefId=0;
                }
                if(item.RefId > 0)
                {
                  this.KRAService.UpdateKRAApplication(t ,item).subscribe(res=>
                  {
                    this.RefId=res;        
                  });                  
                }                             
                else
                {
                  this.KRAService.AddKRAApplication(t ,item).subscribe(res=>
                  {
                    this.RefId=res;         
                  }); 
                }
              });
              if(this.MasterRefId>0)
              {
                localStorage.setItem('SuccessfullMessage', "KRA Submmit Successfully");
                this.rout.navigateByUrl('/Appraisal/KRAMappingwithEmployee', {skipLocationChange: true}).then(()=>this.rout.navigate(["/Appraisal/KRAApplication"]));   
              }
            });
          }
          else
          {
            let t =  localStorage.getItem('userToken');
            t = 'Bearer' + t;
            this.KRAApplicationModel.FinalSubmit=true;
            this.KRAService.AddKRAApplicationMaster(t,this.KRAApplicationModel).subscribe(res=>
            {
              this.MasterRefId=res;
              let t = localStorage.getItem('userToken');
              t = 'Bearer' + t;    
              var j=0;
              var l=this.KRAApplications.length;
              this.KRAApplications.forEach(item => 
              {
                j=j+1;
                item.MasterRefId=this.MasterRefId;
                if(item.RefId==null)
                {
                  item.RefId=0;
                }
                if(item.RefId > 0)
                {
                  this.KRAService.UpdateKRAApplication(t ,item).subscribe(res=>
                  {
                    this.RefId=res;        
                  });                  
                }                             
                else
                {
                  this.KRAService.AddKRAApplication(t ,item).subscribe(res=>
                  {
                    this.RefId=res;         
                  }); 
                }
              });
              if(this.MasterRefId>0)
              {
                localStorage.setItem('SuccessfullMessage', "KRA Submmit Successfully");
                this.rout.navigateByUrl('/Appraisal/KRAMappingwithEmployee', {skipLocationChange: true}).then(()=>this.rout.navigate(["/Appraisal/KRAApplication"]));   
              }
            });
          }
        }); 
      }
    }) 
  }

  RefreshPage()
  {
    this.rout.navigateByUrl('/Appraisal/KRAMappingwithEmployee', {skipLocationChange: true}).then(()=>this.rout.navigate(["/Appraisal/KRAApplication"]));
  }

  RatingChanged()
  {
    this.TotalRating=0;
    var j=0;
    var l=this.KRAApplications.length;
    this.KRAApplications.forEach(item => 
    {
      j=j+1;
      if(item.SelfRating > 0)
      { 
        this.TotalRating=  Number(this.TotalRating) + (Number(item.SelfRating/5) * (item.Weighted));
      }
      this.KRAApplicationModel.FinalSelfRating=Math.round(this.TotalRating);
    });
  }
}