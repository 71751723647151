
export class City {    
    public  CityId :number;
    public  DistId : number;
    public  StateId : number;
    public  CountryId : number;
    public  CityName : string;
    public  CityCode : string;
    public  StateName : string;
    public  CreatedBy : string;
    public  IsActive : boolean;
    
    
}