<div class="body-content-area tabcontent" id="link1">
  <div class="row">
      <div class="col-lg-9">
          <div class="row">
              <div class="col-lg-1 low-padding text-center">
                <!-- <img *ngIf="this.SelectedMenu.Image!=null" src={{this.SelectedMenu.Image}} class="body-icon" alt="Icon"/> -->
              </div>
              <div class="col-lg-10 clear-padding">
                  <span class="page-title">Manage Working Group Hours</span>
                  <small class="page-description"></small>
              </div>
          </div>
      </div>
  </div>

  <div *ngIf="!(this.CanViewState)"> 
  <table id="tblWorkingGroupHours" datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" class="table table-striped table-bordered text-center table-new row-border hover">
      <thead class="thead-blue text-center">
          <tr>
              <th>Sl No.</th>
              <th class="text-left">Name</th>
              <th>Is Alternate</th>
              <th class="text-left">Alternate Day</th>
              <th>Action</th>
          </tr>
      </thead>
      <tbody>
          <tr *ngFor="let WGH of WorkingGroupHour, let i = index">
              <td scope="row">{{i+1}}</td>
              <td class="text-left">{{WGH.WorkingGroupName}}</td>
              <td>{{WGH.IsAlternate}}</td>
              <td class="text-left">{{WGH.DayName}}</td>
              <td>
                  <button *ngIf="this.SelectedMenu.CanEdit" type="button" (click)="GetWorkingGroupHoursByid( WGH.WorkingGroupId )" class="table-edit-btn"><i class="fas fa-edit"></i> </button>
                  <button *ngIf="this.SelectedMenu.CanDelete" type="button" (click)="DeleteWorkingGroupHoursByid( WGH.WorkingGroupId )" class="table-remove-btn"><i class="fas fa-trash-alt"></i> </button>              
                  <button *ngIf="this.SelectedMenu.CanView"   type="button" (click)="ViewWorkingGroupHoursByid( WGH.WorkingGroupId )" class="table-view-button" type="button" ><i class="fas fa-eye"></i></button>  </td>
          </tr>
      </tbody>
  </table>
  
  <fieldset class="body-form-control">
    <app-dialog [(visible)]="this.SuccessStatus">
      <span class="successmsg">{{this.SuccessMessage}}</span>
    </app-dialog>
      
    <legend class="legend--">Working Group Hours Creation</legend>
    <form #WorkingGroupForm="ngForm" (ngSubmit)="saveWorkingGroupHours()">
      <div class="row">
        <div class="col-lg-4">
          <div  class="col-body-border-right">
            <div class="form-group row" >
              <label class="col-sm-4 col-form-label clear-padding-right">Working Group</label>
              <div class="col-sm-7 clear-left-padding">
                <input type="text" [(ngModel)]="WorkingGroupModel.WorkingGroupName" tabindex="1" maxlength="250" id ="WorkingGroupName"  name="WorkingGroupName" #WorkingGroupName="ngModel" required class="form-control form-control-custom form-control-custom-new" >
              </div>
              <div *ngIf="WorkingGroupName.invalid && (WorkingGroupName.dirty || WorkingGroupName.touched)" class="alert alert-danger">
                <div *ngIf="WorkingGroupName.errors.required">Working Group is required.</div>
                <div *ngIf="WorkingGroupName.errors.maxlength">Working Group is not more than 250 characters</div>
              </div> 
            </div>
            <div class="form-group row">
              <label  class="col-sm-4 col-form-label clear-padding-right">Tuesday</label>
              <div class="col-sm-7 clear-left-padding">
                <select [(ngModel)]="WorkingGroupModel.Tuesday" tabindex="4" id ="Tuesday" class="form-control" name="Tuesday" #Tuesday="ngModel" required>
                    <option [value]="0" [disabled]=true >Select Working Hours</option>
                    <option *ngFor="let WH of WorkingHours" [value]="WH.WorkingHoursId">{{WH.WorkingHoursName}}</option>
                </select>
              </div>
              <div class="required-validation" *ngIf="WorkingGroupModel.Tuesday == 0">Working Hours is required</div>
            </div>
            <div class="form-group row">
              <label  class="col-sm-4 col-form-label clear-padding-right">Friday</label>
              <div class="col-sm-7 clear-left-padding">
                <select [(ngModel)]="WorkingGroupModel.Friday" tabindex="7" id ="Friday" class="form-control" name="Friday" #Friday="ngModel">
                    <option [value]="0" [disabled]=true >Select Working Hours</option>
                    <option *ngFor="let WH of WorkingHours" [value]="WH.WorkingHoursId">{{WH.WorkingHoursName}}</option>
                </select>
              </div>
              <div class="required-validation" *ngIf="WorkingGroupModel.Friday == 0">Working Hours is required</div>
            </div>
            <div class="form-group row" >
              <label class="col-sm-4 col-form-label clear-padding-right">Alternate Day</label>
              <div class="col-sm-7 clear-left-padding">
                <select [(ngModel)]="WorkingGroupModel.AlternateDay" [disabled]="!WorkingGroupModel.IsAlternate" tabindex="10" id ="AlternateDay" class="form-control" name="AlternateDay" #AlternateDay="ngModel">
                    <option [value]="0" [disabled]=true >Select Weekly Day</option>
                    <option *ngFor="let day of Days" [value]="day.DayId">{{day.DayName}}</option>
                </select>
              </div>
            </div>
          </div>  
        </div>
        <div class="col-lg-4">
          <div  class="col-body-border-right">
            <div class="form-group row">
              <label class="col-sm-4 col-form-label clear-padding-right">Sunday</label>
              <div class="col-sm-7 clear-left-padding">
                <select [(ngModel)]="WorkingGroupModel.Sunday" tabindex="2" id ="Sunday" class="form-control" name="Sunday" #Sunday="ngModel">
                    <option [value]="0" [disabled]=true >Select Working Hours</option>
                    <option *ngFor="let WH of WorkingHours" [value]="WH.WorkingHoursId">{{WH.WorkingHoursName}}</option>
                </select>
              </div>
              <div class="required-validation" *ngIf="WorkingGroupModel.Sunday == 0">Working Hours is required</div>
            </div>  
            <div class="form-group row" >
              <label class="col-sm-4 col-form-label clear-padding-right">Wednesday</label>
              <div class="col-sm-7 clear-left-padding">
                <select [(ngModel)]="WorkingGroupModel.Wednesday" tabindex="5" id ="Wednesday" class="form-control" name="Wednesday" #Wednesday="ngModel">
                    <option [value]="0" [disabled]=true >Select Working Hours</option>
                    <option *ngFor="let WH of WorkingHours" [value]="WH.WorkingHoursId">{{WH.WorkingHoursName}}</option>
                </select>
              </div>
              <div class="required-validation" *ngIf="WorkingGroupModel.Wednesday == 0">Working Hours is required</div>
            </div>
            <div class="form-group row" >
              <label class="col-sm-4 col-form-label clear-padding-right">Saturday</label>
              <div class="col-sm-7 clear-left-padding">
                <select [(ngModel)]="WorkingGroupModel.Saturday" tabindex="8" id ="Saturday" class="form-control" name="Saturday" #Saturday="ngModel">
                    <option [value]="0" [disabled]=true >Select Working Hours</option>
                    <option *ngFor="let WH of WorkingHours" [value]="WH.WorkingHoursId">{{WH.WorkingHoursName}}</option>
                </select>
              </div>
              <div class="required-validation" *ngIf="WorkingGroupModel.Saturday == 0">Working Hours is required</div>
            </div>
            <div class="form-control form-control-custom form-control-custom-new">
                <input type="checkbox" [disabled]="!WorkingGroupModel.IsAlternate" [(ngModel)]="WorkingGroupModel.Week1" tabindex="11" id ="Week1" name="Week1" #Week1="ngModel">Week1
                <input type="checkbox" [disabled]="!WorkingGroupModel.IsAlternate" [(ngModel)]="WorkingGroupModel.Week2" tabindex="12" id ="Week2" name="Week2" #Week2="ngModel">Week2 
                <input type="checkbox" [disabled]="!WorkingGroupModel.IsAlternate" [(ngModel)]="WorkingGroupModel.Week3" tabindex="13" id ="Week3" name="Week3" #Week3="ngModel">Week3 
                <input type="checkbox" [disabled]="!WorkingGroupModel.IsAlternate" [(ngModel)]="WorkingGroupModel.Week4" tabindex="14" id ="Week4" name="Week4" #Week4="ngModel">Week4 
                <input type="checkbox" [disabled]="!WorkingGroupModel.IsAlternate" [(ngModel)]="WorkingGroupModel.Week5" tabindex="15" id ="Week5" name="Week5" #Week5="ngModel">Week5
            </div>
          </div>
        </div>
        <div  class="col-lg-4">
          <div class="form-group row">
            <label class="col-sm-4 col-form-label clear-padding-right">Monday</label>
            <div class="col-sm-7 clear-left-padding">
              <select [(ngModel)]="WorkingGroupModel.Monday" tabindex="3" id ="Monday" class="form-control" name="Monday" #Monday="ngModel">
                  <option [value]="0" [disabled]=true >Select Working Hours</option>
                  <option *ngFor="let WH of WorkingHours" [value]="WH.WorkingHoursId">{{WH.WorkingHoursName}}</option>
              </select>
            </div>
            <div class="required-validation" *ngIf="WorkingGroupModel.Monday == 0">Working Hours is required</div>
          </div>
          <div class="form-group row">
            <label  class="col-sm-4 col-form-label clear-padding-right">Thursday</label>
            <div class="col-sm-7 clear-left-padding">
              <select [(ngModel)]="WorkingGroupModel.Thursday" tabindex="6" id ="Thursday" class="form-control" name="Thursday" #Thursday="ngModel">
                  <option [value]="0" [disabled]=true >Select Working Hours</option>
                  <option *ngFor="let WH of WorkingHours" [value]="WH.WorkingHoursId">{{WH.WorkingHoursName}}</option>
              </select>
            </div>
            <div class="required-validation" *ngIf="WorkingGroupModel.Thursday == 0">Working Hours is required</div>
          </div>
          <div class="form-group row">
            <label class="col-sm-4 col-form-label clear-padding-right">IsAlternative</label>
            <div class="col-sm-7 clear-left-padding">
              <input type="checkbox" [(ngModel)]="WorkingGroupModel.IsAlternate" tabindex="9" id ="IsAlternate" name="IsAlternate" #IsAlternate="ngModel" (ngModelChange)="oncheckedChange()">
            </div>
          </div>
          <div class="form-group row">
            <label class="col-sm-4 col-form-label clear-padding-right">Adjust Penalty WithLeave</label>
            <div class="col-sm-7 clear-left-padding">
              <input type="checkbox" [(ngModel)]="WorkingGroupModel.AdjustPenaltyWithLeave" tabindex="15" id ="AdjustPenaltyWithLeave" name="AdjustPenaltyWithLeave" #AdjustPenaltyWithLeave="ngModel">
            </div>
          </div>
          <div class="form-group row">
            <label *ngIf="!(this.IsDataIsInViewState)" class="col-sm-6 col-form-label text-center clear-padding-right">
              <button type="submit" tabindex="16" class="btn btn-primary save-button" [disabled]="!(WorkingGroupForm.valid && WorkingGroupModel.Sunday !=0 && WorkingGroupModel.Monday!=0 && WorkingGroupModel.Tuesday!=0 && WorkingGroupModel.Wednesday !=0 && WorkingGroupModel.Thursday!=0 && WorkingGroupModel.Friday!=0 && WorkingGroupModel.Saturday!=0 && this.DisableSave==true)" >Save</button>  
            </label>
            <div class="col-sm-6 col-form-label text-center">
              <button type="button" tabindex="17" (click)="RefreshPage()" class="btn btn-danger cancle-button">Cancel</button>
            </div>
          </div>
        </div>
      </div>
    </form>
  </fieldset>
  </div>
</div>