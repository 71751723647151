import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs/Subject';
import { DataTableDirective } from 'angular-datatables';
import { UserService } from '../../shared/user.service';
import { SalaryMasterService } from '../../shared/HR_ADMIN/Salary/SalaryMaster.service';
import { LevelService } from '../../shared/HR_ADMIN/Lavels_Grades/Levels.service';
import { OrganizationService } from '../../shared/Organization/organization.service';
import { SalaryComponent } from  '../../shared/HR_ADMIN/Salary/SalaryComponentModel';
import { SalaryMapping } from  '../../shared/HR_ADMIN/Salary/SalaryMappingModel';
import { Company } from  '../../shared/Organization/Company.Model';
import { DecisionMaking } from '../../shared/HR_ADMIN/Lavels_Grades/DecisionMaking.Model';
import { Level } from '../../shared/HR_ADMIN/Lavels_Grades/Level.Model';
import { Grade } from  '../../shared/HR_ADMIN/Lavels_Grades/Grade.Model';
import { SalaryRule } from  '../../shared/HR_ADMIN/Salary/SalaryRuleModel';
// Begning Confirmation
import { ConfirmationService } from '@jaspero/ng2-confirmations';
import { ResolveEmit } from '../../../app/resolve-emit';
import { ConfirmSettings } from '../../../app/confirm-settings';
import { Menu } from '../../shared/menu.model';
// end of Confirmation

@Component({
  selector: 'app-map-salary-components-with-grades',
  templateUrl: './map-salary-components-with-grades.component.html',
  styleUrls: ['./map-salary-components-with-grades.component.css']
})
export class MapSalaryComponentsWithGradesComponent implements OnInit 
{
  SalaryMappingModel : SalaryMapping= new SalaryMapping();
  SalaryRuleModel : SalaryRule= new SalaryRule();
  userClaims: any;
  SalaryMappings: SalaryMapping[] = [];
  Result : string;
  SuccessStatus:boolean=false;
  SuccessMessage: string ="";
  public tableWidget: any;
  SalaryMappingId : any;
  SalMap: any;
  base64textString : string;

  Companies: Company[] = [];
  company: any;
  //Grade & Level
  DecisionMakings : DecisionMaking[]=[];
  DecisionMaking : any;
  Levels: Level[] = [];
  Level: any;
  Grades: Grade[] = [];
  grade: any;
  tempSalaryMappingadd : SalaryMapping =new SalaryMapping();

  // Begning Permission Portion    
  SelectedMenu:Menu;
  IsDataIsInViewState : boolean=false;
  CanViewState : boolean=false;
  DisableSave : boolean=false;
  // End of the permission portion
  //check for duplicate component code
  CheckExiststatus : boolean=null;  
  Checkeditstatus : boolean =false;
  check : string ="";
  checkdata : any;
  checkdataval : string ="";
  //check for duplicate component code
  //for Salary Component Dropdown list
  ComponentList = [];
  ComponentselectedItems = [];
  dropdownSettings = {};
  SalaryComponentSettings = {};
  SalaryComponents :any;
  //for branch Dropdown list

  //Begin for disable
  IsCompanyEnable : boolean = false;
  IsGradeEnable : boolean = false; 
  //End for disable
  
  constructor(private SalaryMasterService: SalaryMasterService, private organizationService: OrganizationService, private LevelService: LevelService, private rout: Router,  private _confirmation: ConfirmationService, private userService: UserService) { }
  
  @ViewChild(DataTableDirective, {static: false})
  setDtOptions: DataTables.Settings = {};
  cardDtOptions: DataTables.Settings = {};
  dtElement: DataTableDirective;
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();
  
  ngOnInit() 
  {
    //Begin Permission Portion
    this.SelectedMenu=this.userService.SelectedMenu;
    if(this.SelectedMenu !=undefined)
    {
      localStorage.setItem('selectedmenu',JSON.stringify(this.SelectedMenu));
      this.rout.navigate([this.SelectedMenu.RouteName]);
    }
    else
    {
      if(JSON.parse(localStorage.getItem('selectedmenu'))!=undefined)
      {
        this.SelectedMenu = JSON.parse(localStorage.getItem('selectedmenu'));
        this.rout.navigate([this.SelectedMenu.RouteName]);
      }
    }     
    if(this.SelectedMenu.CanAdd)
    {
      this.IsDataIsInViewState=false;
    }
    else
    {
      this.IsDataIsInViewState=true;
    }
    if(this.SelectedMenu.CanView)
    {
      this.CanViewState=false;
    }
    else
    {
      this.CanViewState=true;
    }
    //End of the peremission portion
    this.getCompanies();
    this.getDecisionMaking();
    this.getSalaryComponents();
    this.SalaryMappingModel.CompanyId=0;
    this.SalaryMappingModel.DecisionId=0;
    this.DisableSave=true;
    this.dtOptions = 
    {
      pagingType: 'full_numbers',
      pageLength: 2
    };
    if(localStorage.getItem('SuccessfullMessage'))
    {
      this.SuccessStatus=true;
      this.SuccessMessage =localStorage.getItem('SuccessfullMessage')
      localStorage.removeItem('SuccessfullMessage');
    } 
    this.SalaryComponentSettings =
    {
      singleSelection:false,
      text:'Select Component',
      enableSearchFilter:true,
      showCheckbox:true
    }
  }

  ngAfterViewInit(): void 
  {
    this.GetSalaryMapping();
    this.dtOptions = 
    {
      pagingType: 'full_numbers',
      pageLength: 2
    };
  }

  settings: ConfirmSettings | any = 
  {
    overlay: true,
    overlayClickToClose: true,
    showCloseButton: true,
    confirmText: 'Yes',
    declineText: 'No'
  };

  getCompanies() 
  {
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.organizationService.GetCompanyForDDL( t).then(x => 
    {
      this.company = x;
      Object.keys(this.company).forEach( key => 
      {
        // tslint:disable-next-line:no-unused-expression
        this.company[key];
        let Companytemp = new Company();
        Companytemp = this.company[key];
        this.Companies.push(Companytemp);
      });     
    });      
  }

  // getGrades() 
  // {
  //   let t = localStorage.getItem('userToken');
  //   t = 'Bearer' + t;
  //   this.LevelService.GetGrade( t).then(x => 
  //   {
  //     this.grade = x;
  //     Object.keys(this.grade).forEach( key => 
  //     {
  //       // tslint:disable-next-line:no-unused-expression
  //       this.grade[key];
  //       let gradetemp = new Grade();
  //       gradetemp = this.grade[key];
  //       this.Grades.push(gradetemp);
  //     });     
  //   });      
  // }

  GetSalaryMapping()
  {
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.SalaryMasterService.GetSalaryMapping( t).then(x => 
    {
      this.SalMap = x;
      Object.keys(this.SalMap).forEach( key => 
      {
        this.SalMap[key];
        let SalMaptemp = new SalaryMapping();
        SalMaptemp = this.SalMap[key];
        //this.SalaryMappingModel.SalaryComponentIds=SalMaptemp.SalaryComponentIds;
        this.SalaryMappings.push(SalMaptemp);
      });
      this.dtTrigger.next();
    });
  }
  
  GetSalaryComponentMappingByid(id) 
  { 
    //Begin for disable   
    this.IsCompanyEnable  = true;
    this.IsGradeEnable  = true;      
    //End for disable
    // Begning of the permission portion
    this.IsDataIsInViewState=false;
    // End of the permision portion
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.SalaryMasterService.GetSalaryMappingById( t,id).then(x => 
    {
      this.SalaryMappingId = x;
      Object.keys(this.SalaryMappingId).forEach( key => 
      {
        // tslint:disable-next-line:no-unused-expression
        this.SalaryMappingId[key];
        let componenttemp = new SalaryMapping();
        componenttemp = this.SalaryMappingId[key];
        this.SalaryMappingModel.RefId=componenttemp.RefId;
        this.SalaryMappingModel.CompanyId=componenttemp.CompanyId;
        this.SalaryMappingModel.DecisionId=componenttemp.DecisionId;
        this.SalaryMappingModel.LevelId=componenttemp.LevelId;
        this.SalaryMappingModel.GradeId=componenttemp.GradeId;
        this.SalaryMappingModel.SalaryComponentIds=componenttemp.SalaryComponentIds;
      });
      this.getSalaryComponents();
      this.getLevel(this.SalaryMappingModel.DecisionId);
      this.getGrade(this.SalaryMappingModel.LevelId);
      // Begin for check company code duplication
      this.checkdataval= this.SalaryMappingModel.SalaryComponentIds;
      this.Checkeditstatus=true;
      this.CheckExiststatus =true;
      this.check=""
      // End for check company code duplication
    });
  }

  viewSalaryComponentMappingByid(id) 
  {
    //Begin for disable   
    this.IsCompanyEnable  = true;
    this.IsGradeEnable  = true;      
    //End for disable
    //begin permission
    this.IsDataIsInViewState=true;
    //end permission
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.SalaryMasterService.GetSalaryMappingById( t,id).then(x => 
      {
        this.SalaryMappingId = x;
        Object.keys(this.SalaryMappingId).forEach( key => 
        {
          // tslint:disable-next-line:no-unused-expression
          this.SalaryMappingId[key];
          let componenttemp = new SalaryMapping();
          componenttemp = this.SalaryMappingId[key];
          this.SalaryMappingModel.RefId=componenttemp.RefId;
          this.SalaryMappingModel.CompanyId=componenttemp.CompanyId;
          this.SalaryMappingModel.DecisionId=componenttemp.DecisionId;
          this.SalaryMappingModel.LevelId=componenttemp.LevelId;
          this.SalaryMappingModel.GradeId=componenttemp.GradeId;
          this.SalaryMappingModel.SalaryComponentIds=componenttemp.SalaryComponentIds;
        });
        this.getSalaryComponents();
        this.getLevel(this.SalaryMappingModel.DecisionId);
        this.getGrade(this.SalaryMappingModel.LevelId);
        // Begin for check company code duplication
        this.checkdataval= this.SalaryMappingModel.SalaryComponentIds;
        this.Checkeditstatus=true;
        this.CheckExiststatus =true;
        this.check=""
        // End for check company code duplication
      });
  }

  saveSalaryComponentMapping() :void
  {
    this.DisableSave=false;
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.SalaryMappingModel.IsActive=true;
    if(this.SalaryMappingModel.RefId > 0)
    {
      if(this.ComponentselectedItems.length > 0)  
      {
        this.SalaryMappingModel.SalaryComponentIds='';
        this.ComponentselectedItems.forEach(element => 
        {
          this.SalaryMappingModel.SalaryComponentIds= this.SalaryMappingModel.SalaryComponentIds + element.id + ",";
        });
        var l = this.SalaryMappingModel.SalaryComponentIds.length
        this.SalaryMappingModel.SalaryComponentIds = this.SalaryMappingModel.SalaryComponentIds.substr(0,l-1);
        this.SalaryMasterService.UpdateSalaryMapping(t ,this.SalaryMappingModel).subscribe(res=>
        {
          this.SalaryMappingId=res;
          this.SalaryMappingModel.RefId=0;  
          if(this.SalaryMappingId>0)
          {
            localStorage.setItem('SuccessfullMessage', "Update Successfully");
            this.rout.navigateByUrl('/SalaryComponent/ManageSalaryComponentComponent', {skipLocationChange: true}).then(()=>this.rout.navigate(["/SalaryComponent/MapSalaryComponents"]));
          }
        });
      }
    }
    else
    {
      if(this.ComponentselectedItems.length > 0)  
      {
        this.SalaryMappingModel.SalaryComponentIds='';
        this.ComponentselectedItems .forEach(element => 
        {
          this.SalaryMappingModel.SalaryComponentIds= this.SalaryMappingModel.SalaryComponentIds + element.id + ",";         
        });
        var l = this.SalaryMappingModel.SalaryComponentIds.length
        this.SalaryMappingModel.SalaryComponentIds = this.SalaryMappingModel.SalaryComponentIds.substr(0,l-1);
        this.SalaryMasterService.AddSalaryMapping(t ,this.SalaryMappingModel).subscribe(res=>
        {
          this.SalaryMappingId=res;
          this.SalaryMappingModel.RefId=0;              
          if(this.SalaryMappingId>0)
          {
            localStorage.setItem('SuccessfullMessage', "Save Successfully");
            this.rout.navigateByUrl('/SalaryComponent/ManageSalaryComponentComponent', {skipLocationChange: true}).then(()=>this.rout.navigate(["/SalaryComponent/MapSalaryComponents"]));
          }
        }); 
      }
    }
  }

  DeleteSalaryComponentMappingByid(Refid : number) :void
  {
    if(this.SelectedMenu.CanAdd)
    {
     this.IsDataIsInViewState=false;
    }
    else
    {
      this.IsDataIsInViewState=true;
    }
    this._confirmation.create('Confirm to Deleate', 'Press Yes to Delete', this.settings)
      .subscribe((ans: ResolveEmit) => 
      {
        if (ans.resolved == true) 
        {
          let t =  localStorage.getItem('userToken');
          t = 'Bearer' + t;
          this.SalaryMappingModel.IsActive=true;
          if( Refid > 0)
          {
            this.SalaryMasterService.DeleteSalaryMapping(t ,Refid).subscribe(res=>
            {
              this.SalaryMappingId=res;
              this.SalaryMappingModel.RefId=0;                                  
              if(this.SalaryMappingId >0)
              {
                localStorage.setItem('SuccessfullMessage', "Deleted Successfully");
                this.rout.navigateByUrl('/SalaryComponent/ManageSalaryComponentComponent', {skipLocationChange: true}).then(()=>this.rout.navigate(["/SalaryComponent/MapSalaryComponents"]));
              }   
            });                  
          }
        }
      })
  }
  // begning of check duplicate Salary Mapping
  CheckSalarycomponentcode()
  {
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    if(!this.Checkeditstatus)
    {
      this.SalaryMasterService.CheckSalaryMapping( t, this.SalaryMappingModel.CompanyId, this.SalaryMappingModel.GradeId).then(x => 
      {
        this.checkdata = x;
        if( this.checkdata.length >0)
        {
          this.CheckExiststatus =false;
          this.check="Exist"
        }
        else
        {
          this.CheckExiststatus =true;
          this.check="";
        }
     });
    }
    else
    {
      if(this.SalaryMappingModel.SalaryComponentIds !=this.checkdataval )
      {
        this.SalaryMasterService.CheckSalaryMapping( t, this.SalaryMappingModel.CompanyId,this.SalaryMappingModel.GradeId).then(x => 
        {
          this.checkdata = x;
          if( this.checkdata.length >0)
          {
            this.CheckExiststatus =false;
            this.check="Exist"
          }
          else
          {
            this.CheckExiststatus =true;
            this.check="";
          }
        });
      }
      else
      {
        this.CheckExiststatus =true;
        this.check="";
      }
    }
  }
  // end of duplicate Salary Mapping
  
  getSalaryComponents() 
  {
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t
    this.ComponentselectedItems = [];
    this.ComponentList = [];
    this.SalaryMasterService.GetSalaryComponentMaster( t).then(x => 
    {
      this.SalaryComponents = x;
      Object.keys(this.SalaryComponents).forEach( key => 
      {
        // tslint:disable-next-line:no-unused-expression
        this.SalaryComponents[key];
        let  SalaryComponenttemp= new SalaryComponent();
        SalaryComponenttemp = this.SalaryComponents[key];
        this.ComponentList.push({"id":SalaryComponenttemp.ComponentId,"itemName":SalaryComponenttemp.ComponentName},);
      });  
      if(this.ComponentList.length > 0)  
      {
        var selected_br = this.SalaryMappingModel.SalaryComponentIds.split(',');
        for (var _i = 0; _i < selected_br.length; _i++) 
        {
          var item = this.ComponentList.find(x=>x.id==selected_br[_i])
          this.ComponentselectedItems.push(item);
        }
      }
    });      
  }
  
  onItemSelect(item:any){ }
  OnItemDeSelect(item:any){ }
  onSelectAll(items: any){ }
  onDeSelectAll(items: any){ }

  RefreshPage()
  {
    this.rout.navigateByUrl('/SalaryComponent/ManageSalaryComponentComponent', {skipLocationChange: true}).then(()=>this.rout.navigate(["/SalaryComponent/MapSalaryComponents"]))
  }

  //Begin Grades & Level
  getDecisionMaking() 
  {
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.LevelService.GetDecisionMaking( t).then(x => 
    {
      this.DecisionMaking = x;
      Object.keys(this.DecisionMaking).forEach( key => 
      {
        this.DecisionMaking[key];
        let DecisionMakingtemp = new DecisionMaking();
        DecisionMakingtemp = this.DecisionMaking[key];
        this.DecisionMakings.push(DecisionMakingtemp);
      });     
    });      
  }
  
  getLevel( DecisionMakingid) 
  {
    this.Levels=[];
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t
    if(this.Levels.length>0)
    {
      this.Level.splice(0, this.Level.length);
    }
    this.LevelService.GetLevelByDecisionid( t,DecisionMakingid).then(x => 
    {
      this.Level = x;
      Object.keys(this.Level).forEach( key => 
      {
        this.Level[key];
        let Leveltemp = new Level();
        Leveltemp = this.Level[key];
        this.Levels.push(Leveltemp);
      });     
    });      
  }
  
  getGrade(Levelid) 
  {
    this.Grades=[];
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t
    if(this.Grades.length>0)
    {
      this.Grades.splice(0,this.Grades.length )
    }
    this.LevelService.GetGradeByLevelid( t,Levelid).then(x => 
    {
      this.grade = x;
      Object.keys(this.grade).forEach( key => 
      {
        this.grade[key];
        let Gradetemp = new Grade();
        Gradetemp = this.grade[key];
        this.Grades.push(Gradetemp);
      });     
    });      
  }
  
  onDecisionChange(Decisionid) 
  {
    if(Decisionid!=0)
    {
      this.getLevel(Decisionid);
      this.SalaryMappingModel.LevelId=0;
    }
    else
    {
      this.getLevel(Decisionid);
      this.SalaryMappingModel.LevelId=0;
      this.getGrade(0);
    }
    this.SalaryMappingModel.GradeId=0;
  }
  
  onLevelChange(Levelid)
  {
    if(Levelid!=0)
    {
      this.getGrade(Levelid);
      this.SalaryMappingModel.GradeId=0;
    }
    else
    {
      this.getGrade(Levelid);
      this.SalaryMappingModel.GradeId=0;
    } 
  }
  
  //End Grades & Level
}
