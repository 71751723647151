export class LeaveApprovalModel
{
    public RefId : number;
    public EmployeeId : number;
    public EmployeeCode : string;
    public EmployeeName : string;
    public EmployeeEmailId : string;
    public LeaveTypeId : number;
    public LeaveTypeName : number;
    public ApplicationDate : Date;
    public FromDate : Date;
    public FromFirstHalf : boolean;
    public FromSecondHalf : boolean;
    public ToDate : Date;
    public ToFirstHalf : boolean;
    public ToSecondHalf : boolean;
    public NoDaysLeave : number;
    public ReasonsLeave : string;
    public RemarksLeave : string;
    public ContactNoDuringLeave : number;
    public AddressDuringLeave : string;
    public Approved : boolean;
    public Rejected : boolean;
    public ApprovedRejectedRemarks : string;
    public RemarksAll : string;
    public IsAttach : boolean;
    public AttachmentURL : string;
    public RHId : number;
    public RHCode : string;
    public RHName : string;
    public RHEmailId : string;
    public LeaveBalance :number;
    public PersonToContact : string;
    public Fromvalue : string;
    public Tovalue : string;
    public CompanyId : number;
    public Selected : boolean;
    public SentMailid : string;
    public SentMailPassword : string;
    public HRISURL : string;
    public SearchFromDate : Date;
    public SearchToDate : Date;
    public Status : number;
}
