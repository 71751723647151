import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Response } from '@angular/http';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import 'rxjs/add/operator/toPromise';
import { GlobalVariable } from '../../../app.global';
import { LeaveApplicationModel } from '../EmployeePortal/LeaveApplicationModel.Model';
import { AttendanceRegularationModel } from '../EmployeePortal/AttendanceRegularationModel.Model';
import { DirectDuty } from '../EmployeePortal/DirectDuty.Model';
import { TourModel } from '../EmployeePortal/TourModel.Model';
import { EmployeeCompoffModel } from '../EmployeePortal/EmployeeCompoffModel.model';
import { TourReimbursementMasterModel } from '../EmployeePortal/TourReimbursementMasterModel.Model';
import { EmployeeAttendanceModel } from '../../../shared/HR_ADMIN/EmployeePortal/EmployeeAttendanceModel.Model';
import { ResignApplicationModel } from '../../../shared/HR_ADMIN/EmployeePortal/ResignApplicationModel.Model';

@Injectable()
export class EmployeePortalService {
    constructor(private http: HttpClient, private spinnerService: Ng4LoadingSpinnerService) {
    }
    // Begning of the Current Employee Details
    GetEmployeeDetailsByEmployeeId(token) {
        this.spinnerService.show();
        const authHeader = new HttpHeaders();
        authHeader.append('Content-Type', 'application/json');
        authHeader.append('Authorization', token);
        return new Promise((resolve, reject) => {
        this.http.get(GlobalVariable.BASE_API_URL + '/api/EmployeeAttendance/GetEmployeeDetailsByEmployeeId', { headers: authHeader })
            .subscribe(data => {
                this.spinnerService.hide();
                resolve(data)
            },
            error => {
                this.spinnerService.hide();
                reject(error);
            });
        });
    }
    // Begning of Employee Attendance Details
    GetEmployeeAttendanceDetailsByUserLogingId(token, FromDate: Date, ToDate: Date) {
        this.spinnerService.show();
        const authHeader = new HttpHeaders();
        authHeader.append('Content-Type', 'application/json');
        authHeader.append('Authorization', token);
        return new Promise((resolve, reject) => {
            setTimeout(() => {
                this.http.get(GlobalVariable.BASE_API_URL + '/api/EmployeeAttendance/GetEmployeeAttendanceDetailsByUserLogingId?FromDate=' + FromDate + '&ToDate=' + ToDate, { headers: authHeader })
                    .subscribe(data => {
                        this.spinnerService.hide();
                        resolve(data)
                    },
                        error => {
                            this.spinnerService.hide();
                            reject(error);
                        });
            }, 3000);
        });
    }
    GetEmployeeAttendanceDetailsByEmployeeId(token, EmployeeId: number, FromDate: Date, ToDate: Date) {
        this.spinnerService.show();
        const authHeader = new HttpHeaders();
        authHeader.append('Content-Type', 'application/json');
        authHeader.append('Authorization', token);
        return new Promise((resolve, reject) => {
            setTimeout(() => {
                this.http.get(GlobalVariable.BASE_API_URL + '/api/EmployeeAttendance/GetEmployeeAttendanceDetailsByEmployeeId?EmployeeId=' + EmployeeId + '&FromDate=' + FromDate + '&ToDate=' + ToDate, { headers: authHeader })
                    .subscribe(data => {
                        this.spinnerService.hide();
                        resolve(data)
                    },
                        error => {
                            this.spinnerService.hide();
                            reject(error);
                        });
            }, 3000);
        });
    }
    // End of Employee Attendance Details
    // Begning of Employee Leave Balance
    GetEmployeeLeaveBalanceByUserLogingId(token) {
        this.spinnerService.show();
        const authHeader = new HttpHeaders();
        authHeader.append('Content-Type', 'application/json');
        authHeader.append('Authorization', token);
        return new Promise((resolve, reject) => {
            setTimeout(() => {
                this.http.get(GlobalVariable.BASE_API_URL + '/api/EmployeeLeaveBalance/GetEmployeeLeaveBalanceDetailsByUserLogingId', { headers: authHeader })
                    .subscribe(data => {
                        this.spinnerService.hide();
                        resolve(data)
                    },
                        error => {
                            this.spinnerService.hide();
                            reject(error);
                        });
            }, 3000);
        });
    }
    GetEmployeeLeaveBalanceByEmployeeId(token, EmployeeId: number) {
        this.spinnerService.show();
        const authHeader = new HttpHeaders();
        authHeader.append('Content-Type', 'application/json');
        authHeader.append('Authorization', token);
        return new Promise((resolve, reject) => {
            setTimeout(() => {
                this.http.get(GlobalVariable.BASE_API_URL + '/api/EmployeeLeaveBalance/GetEmployeeLeaveBalanceDetailsByEmployeeId?EmployeeId=' + EmployeeId, { headers: authHeader })
                    .subscribe(data => {
                        this.spinnerService.hide();
                        resolve(data)
                    },
                        error => {
                            this.spinnerService.hide();
                            reject(error);
                        });
            }, 3000);
        });
    }
    GetResignEmployeeLeaveBalanceDetails(token, EmployeeId: number) {
        this.spinnerService.show();
        const authHeader = new HttpHeaders();
        authHeader.append('Content-Type', 'application/json');
        authHeader.append('Authorization', token);
        return new Promise((resolve, reject) => {
            setTimeout(() => {
                this.http.get(GlobalVariable.BASE_API_URL + '/api/EmployeeLeaveBalance/GetResignEmployeeLeaveBalanceDetails?EmployeeId=' + EmployeeId, { headers: authHeader })
                    .subscribe(data => {
                        this.spinnerService.hide();
                        resolve(data)
                    },
                        error => {
                            this.spinnerService.hide();
                            reject(error);
                        });
            }, 3000);
        });
    }
    GetEmployeeLeaveBalanceAdjustmentDetailsByLogingId(token) {
        this.spinnerService.show();
        const authHeader = new HttpHeaders();
        authHeader.append('Content-Type', 'application/json');
        authHeader.append('Authorization', token);
        return new Promise((resolve, reject) => {
            setTimeout(() => {
                this.http.get(GlobalVariable.BASE_API_URL + '/api/EmployeeLeaveBalance/GetEmployeeLeaveBalanceAdjustmentDetailsByLogingId', { headers: authHeader })
                    .subscribe(data => {
                        this.spinnerService.hide();
                        resolve(data)
                    },
                        error => {
                            this.spinnerService.hide();
                            reject(error);
                        });
            }, 3000);
        });
    }
    GetEmployeeLeaveBalanceAdjustmentDetailsByEmployeeId(token, EmployeeId: number) {
        this.spinnerService.show();
        const authHeader = new HttpHeaders();
        authHeader.append('Content-Type', 'application/json');
        authHeader.append('Authorization', token);
        return new Promise((resolve, reject) => {
            setTimeout(() => {
                this.http.get(GlobalVariable.BASE_API_URL + '/api/EmployeeLeaveBalance/GetEmployeeLeaveBalanceAdjustmentDetailsByEmployeeId?EmployeeId=' + EmployeeId, { headers: authHeader })
                    .subscribe(data => {
                        this.spinnerService.hide();
                        resolve(data)
                    },
                        error => {
                            this.spinnerService.hide();
                            reject(error);
                        });
            }, 3000);
        });
    }
    // End of Employee Leave Balance
    // Begning of Employee Leave Application Details 
    GetEmployeeLeaveApplicationByUserLogingId(token) {
        this.spinnerService.show();
        const authHeader = new HttpHeaders();
        authHeader.append('Content-Type', 'application/json');
        authHeader.append('Authorization', token);
        return new Promise((resolve, reject) => {
            setTimeout(() => {
                this.http.get(GlobalVariable.BASE_API_URL + '/api/EmployeeLeave/GetPreviousLeaveApplicationDetailsByUserId', { headers: authHeader })
                    .subscribe(data => {
                        this.spinnerService.hide();
                        resolve(data)
                    },
                        error => {
                            this.spinnerService.hide();
                            reject(error);
                        });
            }, 3000);
        });
    }
    GetEmployeeLeaveApplicationByEmployeeId(token, EmployeeId: number) {
        this.spinnerService.show();
        const authHeader = new HttpHeaders();
        authHeader.append('Content-Type', 'application/json');
        authHeader.append('Authorization', token);
        return new Promise((resolve, reject) => {
            setTimeout(() => {
                this.http.get(GlobalVariable.BASE_API_URL + '/api/EmployeeLeave/GetPreviousLeaveApplicationDetails?EmployeeId=' + EmployeeId, { headers: authHeader })
                    .subscribe(data => {
                        this.spinnerService.hide();
                        resolve(data)
                    },
                        error => {
                            this.spinnerService.hide();
                            reject(error);
                        });
            }, 3000);
        });
    }
    // End of Employee Leave Balance
    // BEGIN of Employee Attendance Report
    GetEmployeeAttendanceDetailsReports(token, EmployeeIds: string, Year: number, MonthId: number, companyid: number, branchid: number, depertmentid: number) {
        const authHeader = new HttpHeaders();
        authHeader.append('Content-Type', 'application/json');
        authHeader.append('Authorization', token);
        setTimeout(() => {
            this.http.get(GlobalVariable.BASE_API_URL + '/api/EmployeeAttendance/GetEmpAttDetails?EmployeeIds=' + EmployeeIds + '&Year=' + Year + '&MonthId=' + MonthId + '&companyid=' + companyid + '&branchid=' + branchid + '&departmentid=' + depertmentid, { headers: authHeader, responseType: 'blob' as 'json' }).subscribe((res) => {
                var file = new Blob(<any>[res], { type: 'application/vnd.ms-excel' });
                var fileURL = URL.createObjectURL(file);
                window.open(fileURL);
            });
        }, 3000);
    }
    // End of Employee Attendance Details Report


    // BEGIN of Employee Salary Sheet Report
    GetEmployeeSalaysheetReports(token, Year: number, MonthId: number, companyid: number, branchid: number, depertmentid: number, RepotyType: number) {

        const authHeader = new HttpHeaders();
        authHeader.append('Content-Type', 'application/json');
        authHeader.append('Authorization', token);
        setTimeout(() => {
            this.http.get(GlobalVariable.BASE_API_URL + '/api/SalaryPaySlip/GetEmployeeSalaryDetails?Year=' + Year + '&Month=' + MonthId +
                '&companyid=' + companyid + '&branchid=' + branchid + '&departmentid=' + depertmentid + '&RepotyType=' + RepotyType, { headers: authHeader, responseType: 'blob' as 'json' }).subscribe((res) => {
                    var file = new Blob(<any>[res], { type: 'application/vnd.ms-excel' });
                    var fileURL = URL.createObjectURL(file);
                    window.open(fileURL);
                });
        }, 3000);
    }
    // End of Employee Salary sheet Report
    // Begning of Employee Leave Apply
    GetEmployeeDetailsByUserLogingIdLeaveApply(token) {
        this.spinnerService.show();
        const authHeader = new HttpHeaders();
        authHeader.append('Content-Type', 'application/json');
        authHeader.append('Authorization', token);
        return new Promise((resolve, reject) => {
        this.http.get(GlobalVariable.BASE_API_URL + '/api/EmployeeLeaveApply/GetEmployeeDetailsByUserLogingIdLeaveApply', { headers: authHeader })
            .subscribe(data => {
                this.spinnerService.hide();
                resolve(data)
            },
            error => {
                this.spinnerService.hide();
                reject(error);
            });
        });
    }
    GetEmployeeDetailsByEmployeeIdLeaveApply(token, EmployeeId: number) {
        this.spinnerService.show();
        const authHeader = new HttpHeaders();
        authHeader.append('Content-Type', 'application/json');
        authHeader.append('Authorization', token);
        return new Promise((resolve, reject) => {
        this.http.get(GlobalVariable.BASE_API_URL + '/api/EmployeeLeaveApply/GetEmployeeDetailsByEmployeeIdLeaveApply?EmployeeId=' + EmployeeId, { headers: authHeader })
            .subscribe(data => {
                this.spinnerService.hide();
                resolve(data)
            },
            error => {
                this.spinnerService.hide();
                reject(error);
            });
        });
    }
    GetLeaveType(token) {
        this.spinnerService.show();
        const authHeader = new HttpHeaders();
        authHeader.append('Content-Type', 'application/json');
        authHeader.append('Authorization', token);
        return new Promise((resolve, reject) => {
            setTimeout(() => {
                this.http.get(GlobalVariable.BASE_API_URL + '/api/EmployeeLeaveApply/GetLeaveType', { headers: authHeader })
                    .subscribe(data => {
                        this.spinnerService.hide();
                        resolve(data)
                    },
                        error => {
                            this.spinnerService.hide();
                            reject(error);
                        });
            }, 3000);
        });
    }
    GetLeaveTypeForAdjustment(token) {
        this.spinnerService.show();
        const authHeader = new HttpHeaders();
        authHeader.append('Content-Type', 'application/json');
        authHeader.append('Authorization', token);
        return new Promise((resolve, reject) => {
            setTimeout(() => {
                this.http.get(GlobalVariable.BASE_API_URL + '/api/EmployeeLeaveApply/GetLeaveTypeForAdjustment', { headers: authHeader })
                    .subscribe(data => {
                        this.spinnerService.hide();
                        resolve(data)
                    },
                        error => {
                            this.spinnerService.hide();
                            reject(error);
                        });
            }, 3000);
        });
    }
    GetLeaveTypeByEmployeeId(token, EmployeeId: number) {
        this.spinnerService.show();
        const authHeader = new HttpHeaders();
        authHeader.append('Content-Type', 'application/json');
        authHeader.append('Authorization', token);
        return new Promise((resolve, reject) => {
            setTimeout(() => {
                this.http.get(GlobalVariable.BASE_API_URL + '/api/EmployeeLeaveApply/GetLeaveTypeByEmployeeId?EmployeeId=' + EmployeeId, { headers: authHeader })
                    .subscribe(data => {
                        this.spinnerService.hide();
                        resolve(data)
                    },
                        error => {
                            this.spinnerService.hide();
                            reject(error);
                        });
            }, 3000);
        });
    }
    GetLeaveTypeByUserLogingId(token) {
        this.spinnerService.show();
        const authHeader = new HttpHeaders();
        authHeader.append('Content-Type', 'application/json');
        authHeader.append('Authorization', token);
        return new Promise((resolve, reject) => {
            setTimeout(() => {
                this.http.get(GlobalVariable.BASE_API_URL + '/api/EmployeeLeaveApply/GetLeaveTypeByUserLogingId', { headers: authHeader })
                    .subscribe(data => {
                        this.spinnerService.hide();
                        resolve(data)
                    },
                        error => {
                            this.spinnerService.hide();
                            reject(error);
                        });
            }, 3000);
        });
    }
    GetEmployeeLeaveBalanceByLeaveType(token, LeaveTypeId: number, EmployeeId: number) {
        this.spinnerService.show();
        const authHeader = new HttpHeaders();
        authHeader.append('Content-Type', 'application/json');
        authHeader.append('Authorization', token);
        return new Promise((resolve, reject) => {
            setTimeout(() => {
                this.http.get(GlobalVariable.BASE_API_URL + '/api/EmployeeLeaveApply/GetEmployeeLeaveBalanceByLeaveType?LeaveTypeId=' + LeaveTypeId + '&EmployeeId=' + EmployeeId, { headers: authHeader })
                    .subscribe(data => {
                        this.spinnerService.hide();
                        resolve(data)
                    },
                        error => {
                            this.spinnerService.hide();
                            reject(error);
                        });
            }, 3000);
        });
    }
    AddEmployeeLeaveApply(token, LeaveApplication: LeaveApplicationModel) {
        const authHeader = new HttpHeaders();
        authHeader.append('Content-Type', 'application/json');
        authHeader.append('Authorization', token);
        authHeader.append('Accept', 'application/json');
        return this.http.post(GlobalVariable.BASE_API_URL + '/api/EmployeeLeaveApply/AddEmployeeLeaveApplication', LeaveApplication, { headers: authHeader })
            .map(this.extractData)
            .catch(this.handleErrorObservable);
    }
    DateDifference(token, FromDate: Date, ToDate: Date, EmployeeId: number) {
        this.spinnerService.show();
        const authHeader = new HttpHeaders();
        authHeader.append('Content-Type', 'application/json');
        authHeader.append('Authorization', token);
        return new Promise((resolve, reject) => {
            setTimeout(() => {
                this.http.get(GlobalVariable.BASE_API_URL + '/api/EmployeeLeaveApply/DateDifference?FromDate=' + FromDate + '&ToDate=' + ToDate + '&EmployeeId=' + EmployeeId, { headers: authHeader })
                    .subscribe(data => {
                        this.spinnerService.hide();
                        resolve(data)
                    },
                        error => {
                            this.spinnerService.hide();
                            reject(error);
                        });
            }, 3000);
        });
    }
    LeaveDateExistsChecking(token, FromDate: Date, ToDate: Date, EmployeeId: number) {
        this.spinnerService.show();
        const authHeader = new HttpHeaders();
        authHeader.append('Content-Type', 'application/json');
        authHeader.append('Authorization', token);
        return new Promise((resolve, reject) => {
            setTimeout(() => {
                this.http.get(GlobalVariable.BASE_API_URL + '/api/EmployeeLeaveApply/LeaveDateExistsChecking?FromDate=' + FromDate + '&ToDate=' + ToDate + '&EmployeeId=' + EmployeeId, { headers: authHeader })
                    .subscribe(data => {
                        this.spinnerService.hide();
                        resolve(data)
                    },
                        error => {
                            this.spinnerService.hide();
                            reject(error);
                        });
            }, 3000);
        });
    }
    GetLeaveStatusApproved(token, FromDate, ToDate) {
        this.spinnerService.show();
        const authHeader = new HttpHeaders();
        authHeader.append('Content-Type', 'application/json');
        authHeader.append('Authorization', token);
        return new Promise((resolve, reject) => {
            setTimeout(() => {
                this.http.get(GlobalVariable.BASE_API_URL + '/api/EmployeeLeave/GetLeaveStatusApproved?FromDate=' + FromDate + '&ToDate=' + ToDate, { headers: authHeader })
                    .subscribe(data => {
                        this.spinnerService.hide();
                        resolve(data)
                    },
                        error => {
                            this.spinnerService.hide();
                            reject(error);
                        });
            }, 3000);
        });
    }
    GetLeaveStatusRejected(token, FromDate, ToDate) {
        this.spinnerService.show();
        const authHeader = new HttpHeaders();
        authHeader.append('Content-Type', 'application/json');
        authHeader.append('Authorization', token);
        return new Promise((resolve, reject) => {
            setTimeout(() => {
                this.http.get(GlobalVariable.BASE_API_URL + '/api/EmployeeLeave/GetLeaveStatusRejected?FromDate=' + FromDate + '&ToDate=' + ToDate, { headers: authHeader })
                    .subscribe(data => {
                        this.spinnerService.hide();
                        resolve(data)
                    },
                        error => {
                            this.spinnerService.hide();
                            reject(error);
                        });
            }, 3000);
        });
    }
    GetLeaveStatusPending(token, FromDate, ToDate) {
        this.spinnerService.show();
        const authHeader = new HttpHeaders();
        authHeader.append('Content-Type', 'application/json');
        authHeader.append('Authorization', token);
        return new Promise((resolve, reject) => {
            setTimeout(() => {
                this.http.get(GlobalVariable.BASE_API_URL + '/api/EmployeeLeave/GetLeaveStatusPending?FromDate=' + FromDate + '&ToDate=' + ToDate, { headers: authHeader })
                    .subscribe(data => {
                        this.spinnerService.hide();
                        resolve(data)
                    },
                        error => {
                            this.spinnerService.hide();
                            reject(error);
                        });
            }, 3000);
        });
    }
    GetLeaveStatusApprovedByEmployeeId(token, EmployeeId, FromDate, ToDate) {
        this.spinnerService.show();
        const authHeader = new HttpHeaders();
        authHeader.append('Content-Type', 'application/json');
        authHeader.append('Authorization', token);
        return new Promise((resolve, reject) => {
            setTimeout(() => {
                this.http.get(GlobalVariable.BASE_API_URL + '/api/EmployeeLeave/GetLeaveStatusApprovedByEmployeeId?EmployeeId=' + EmployeeId + '&FromDate=' + FromDate + '&ToDate=' + ToDate, { headers: authHeader })
                    .subscribe(data => {
                        this.spinnerService.hide();
                        resolve(data)
                    },
                        error => {
                            this.spinnerService.hide();
                            reject(error);
                        });
            }, 3000);
        });
    }
    GetLeaveStatusRejectedByEmployeeId(token, EmployeeId, FromDate, ToDate) {
        this.spinnerService.show();
        const authHeader = new HttpHeaders();
        authHeader.append('Content-Type', 'application/json');
        authHeader.append('Authorization', token);
        return new Promise((resolve, reject) => {
            setTimeout(() => {
                this.http.get(GlobalVariable.BASE_API_URL + '/api/EmployeeLeave/GetLeaveStatusRejectedByEmployeeId?EmployeeId=' + EmployeeId + '&FromDate=' + FromDate + '&ToDate=' + ToDate, { headers: authHeader })
                    .subscribe(data => {
                        this.spinnerService.hide();
                        resolve(data)
                    },
                        error => {
                            this.spinnerService.hide();
                            reject(error);
                        });
            }, 3000);
        });
    }
    GetLeaveStatusPendingByEmployeeId(token, EmployeeId, FromDate, ToDate) {
        this.spinnerService.show();
        const authHeader = new HttpHeaders();
        authHeader.append('Content-Type', 'application/json');
        authHeader.append('Authorization', token);
        return new Promise((resolve, reject) => {
            setTimeout(() => {
                this.http.get(GlobalVariable.BASE_API_URL + '/api/EmployeeLeave/GetLeaveStatusPendingByEmployeeId?EmployeeId=' + EmployeeId + '&FromDate=' + FromDate + '&ToDate=' + ToDate, { headers: authHeader })
                    .subscribe(data => {
                        this.spinnerService.hide();
                        resolve(data)
                    },
                        error => {
                            this.spinnerService.hide();
                            reject(error);
                        });
            }, 3000);
        });
    }
    //Working day Status
    EmployeeWorkingDayStatus(token, EmployeeId: number, ApplyDate: Date) {
        this.spinnerService.show();
        const authHeader = new HttpHeaders();
        authHeader.append('Content-Type', 'application/json');
        authHeader.append('Authorization', token);
        return new Promise((resolve, reject) => {
            setTimeout(() => {
                this.http.get(GlobalVariable.BASE_API_URL + '/api/EmployeeLeave/EmployeeWorkingDayStatus?EmployeeId=' + EmployeeId + '&ApplyDate=' + ApplyDate, { headers: authHeader })
                    .subscribe(data => {
                        this.spinnerService.hide();
                        resolve(data)
                    },
                        error => {
                            this.spinnerService.hide();
                            reject(error);
                        });
            }, 3000);
        });
    }
    LeaveDayCheckForSingleDay(token, EmployeeId: number, ApplicationDate: Date) {
        this.spinnerService.show();
        const authHeader = new HttpHeaders();
        authHeader.append('Content-Type', 'application/json');
        authHeader.append('Authorization', token);
        return new Promise((resolve, reject) => {
            setTimeout(() => {
                this.http.get(GlobalVariable.BASE_API_URL + '/api/EmployeeLeave/LeaveDayCheckForSingleDay?EmployeeId='+EmployeeId+'&ApplicationDate=' + ApplicationDate, { headers: authHeader })
                    .subscribe(data => {
                        this.spinnerService.hide();
                        resolve(data)
                    },
                        error => {
                            this.spinnerService.hide();
                            reject(error);
                        });
            }, 3000);
        });
    }
    // End of Employee Leave Apply
    // Begin EmployeeAttendanceRegularation
    GetEmployeeDetailsByUserLoginIdRegularation(token, ApplicationDate: Date) {
        this.spinnerService.show();
        const authHeader = new HttpHeaders();
        authHeader.append('Content-Type', 'application/json');
        authHeader.append('Authorization', token);
        return new Promise((resolve, reject) => {
        this.http.get(GlobalVariable.BASE_API_URL + '/api/EmployeeAttendanceRegularation/GetEmployeeDetailsByUserLoginIdRegularation?ApplicationDate=' + ApplicationDate, { headers: authHeader })
            .subscribe(data => {
                this.spinnerService.hide();
                resolve(data)
            },
            error => {
                this.spinnerService.hide();
                reject(error);
            });
        });
    }
    GetEmployeeDetailsByEmployeeIdRegularation(token, EmployeeId: number, ApplicationDate: Date) {
        this.spinnerService.show();
        const authHeader = new HttpHeaders();
        authHeader.append('Content-Type', 'application/json');
        authHeader.append('Authorization', token);
        return new Promise((resolve, reject) => {
            this.http.get(GlobalVariable.BASE_API_URL + '/api/EmployeeAttendanceRegularation/GetEmployeeDetailsByEmployeeIdRegularation?EmployeeId=' + EmployeeId + '&ApplicationDate=' + ApplicationDate, { headers: authHeader })
            .subscribe(data => {
                this.spinnerService.hide();
                resolve(data)
            },
            error => {
                this.spinnerService.hide();
                reject(error);
            });
        });
    }
    AddEmployeeAttendanceRegularation(token, AttendanceRegularation: AttendanceRegularationModel) {
        const authHeader = new HttpHeaders();
        authHeader.append('Content-Type', 'application/json');
        authHeader.append('Authorization', token);
        authHeader.append('Accept', 'application/json');
        return this.http.post(GlobalVariable.BASE_API_URL + '/api/EmployeeAttendanceRegularation/AddEmployeeAttendanceRegularation', AttendanceRegularation, { headers: authHeader })
            .map(this.extractData)
            .catch(this.handleErrorObservable);
    }
    GetAttendanceRegularisationStatusApproved(token, FromDate, ToDate) {
        this.spinnerService.show();
        const authHeader = new HttpHeaders();
        authHeader.append('Content-Type', 'application/json');
        authHeader.append('Authorization', token);
        return new Promise((resolve, reject) => {
            setTimeout(() => {
                this.http.get(GlobalVariable.BASE_API_URL + '/api/RegularisationApproval/GetAttendanceRegularisationStatusApproved?FromDate=' + FromDate + '&ToDate=' + ToDate, { headers: authHeader })
                    .subscribe(data => {
                        this.spinnerService.hide();
                        resolve(data)
                    },
                        error => {
                            this.spinnerService.hide();
                            reject(error);
                        });
            }, 3000);
        });
    }
    GetAttendanceRegularisationStatusRejected(token, FromDate, ToDate) {
        this.spinnerService.show();
        const authHeader = new HttpHeaders();
        authHeader.append('Content-Type', 'application/json');
        authHeader.append('Authorization', token);
        return new Promise((resolve, reject) => {
            setTimeout(() => {
                this.http.get(GlobalVariable.BASE_API_URL + '/api/RegularisationApproval/GetAttendanceRegularisationStatusRejected?FromDate=' + FromDate + '&ToDate=' + ToDate, { headers: authHeader })
                    .subscribe(data => {
                        this.spinnerService.hide();
                        resolve(data)
                    },
                        error => {
                            this.spinnerService.hide();
                            reject(error);
                        });
            }, 3000);
        });
    }
    GetAttendanceRegularisationStatusPending(token, FromDate, ToDate) {
        this.spinnerService.show();
        const authHeader = new HttpHeaders();
        authHeader.append('Content-Type', 'application/json');
        authHeader.append('Authorization', token);
        return new Promise((resolve, reject) => {
            setTimeout(() => {
                this.http.get(GlobalVariable.BASE_API_URL + '/api/RegularisationApproval/GetAttendanceRegularisationStatusPending?FromDate=' + FromDate + '&ToDate=' + ToDate, { headers: authHeader })
                    .subscribe(data => {
                        this.spinnerService.hide();
                        resolve(data)
                    },
                        error => {
                            this.spinnerService.hide();
                            reject(error);
                        });
            }, 3000);
        });
    }
    GetAttendanceRegularisationStatusApprovedByEmployeeId(token, EmployeeId, FromDate, ToDate) {
        this.spinnerService.show();
        const authHeader = new HttpHeaders();
        authHeader.append('Content-Type', 'application/json');
        authHeader.append('Authorization', token);
        return new Promise((resolve, reject) => {
            setTimeout(() => {
                this.http.get(GlobalVariable.BASE_API_URL + '/api/RegularisationApproval/GetAttendanceRegularisationStatusApprovedByEmployeeId?EmployeeId=' + EmployeeId + '&FromDate=' + FromDate + '&ToDate=' + ToDate, { headers: authHeader })
                    .subscribe(data => {
                        this.spinnerService.hide();
                        resolve(data)
                    },
                        error => {
                            this.spinnerService.hide();
                            reject(error);
                        });
            }, 3000);
        });
    }
    GetAttendanceRegularisationStatusRejectedByEmployeeId(token, EmployeeId, FromDate, ToDate) {
        this.spinnerService.show();
        const authHeader = new HttpHeaders();
        authHeader.append('Content-Type', 'application/json');
        authHeader.append('Authorization', token);
        return new Promise((resolve, reject) => {
            setTimeout(() => {
                this.http.get(GlobalVariable.BASE_API_URL + '/api/RegularisationApproval/GetAttendanceRegularisationStatusRejectedByEmployeeId?EmployeeId=' + EmployeeId + '&FromDate=' + FromDate + '&ToDate=' + ToDate, { headers: authHeader })
                    .subscribe(data => {
                        this.spinnerService.hide();
                        resolve(data)
                    },
                        error => {
                            this.spinnerService.hide();
                            reject(error);
                        });
            }, 3000);
        });
    }
    GetAttendanceRegularisationStatusPendingByEmployeeId(token, EmployeeId, FromDate, ToDate) {
        this.spinnerService.show();
        const authHeader = new HttpHeaders();
        authHeader.append('Content-Type', 'application/json');
        authHeader.append('Authorization', token);
        return new Promise((resolve, reject) => {
            setTimeout(() => {
                this.http.get(GlobalVariable.BASE_API_URL + '/api/RegularisationApproval/GetAttendanceRegularisationStatusPendingByEmployeeId?EmployeeId=' + EmployeeId + '&FromDate=' + FromDate + '&ToDate=' + ToDate, { headers: authHeader })
                    .subscribe(data => {
                        this.spinnerService.hide();
                        resolve(data)
                    },
                        error => {
                            this.spinnerService.hide();
                            reject(error);
                        });
            }, 3000);
        });
    }
    // End EmployeeAttendanceRegularation
    //Begning Of Direct Duty
    GetEmployeeDirectDutyStatus(token) {
        this.spinnerService.show();
        const authHeader = new HttpHeaders();
        authHeader.append('Content-Type', 'application/json');
        authHeader.append('Authorization', token);
        return new Promise((resolve, reject) => {
            setTimeout(() => {
                this.http.get(GlobalVariable.BASE_API_URL + '/api/EmployeeDirectDuty/GetEmployeeDirectStatus', { headers: authHeader })
                    .subscribe(data => {
                        this.spinnerService.hide();
                        resolve(data)
                    },
                        error => {
                            this.spinnerService.hide();
                            reject(error);
                        });
            }, 3000);
        });
    }
    GetEmployeeDirectDutyStatusByEmployeeId(token, EmployeeId: number) {
        this.spinnerService.show();
        const authHeader = new HttpHeaders();
        authHeader.append('Content-Type', 'application/json');
        authHeader.append('Authorization', token);
        return new Promise((resolve, reject) => {
            setTimeout(() => {
                this.http.get(GlobalVariable.BASE_API_URL + '/api/EmployeeDirectDuty/GetEmployeeDirectDutyStatusByEmployeeId?EmployeeId=' + EmployeeId, { headers: authHeader })
                    .subscribe(data => {
                        this.spinnerService.hide();
                        resolve(data)
                    },
                        error => {
                            this.spinnerService.hide();
                            reject(error);
                        });
            }, 3000);
        });
    }
    AddEmployeeDirectDuty(token, DirectDutyEmp: DirectDuty) {
        const authHeader = new HttpHeaders();
        authHeader.append('Content-Type', 'application/json');
        authHeader.append('Authorization', token);
        authHeader.append('Accept', 'application/json');
        return this.http.post(GlobalVariable.BASE_API_URL + '/api/EmployeeDirectDuty/AddDirectDuty', DirectDutyEmp, { headers: authHeader })
            .map(this.extractData)
            .catch(this.handleErrorObservable);
    }
    UpdateEmployeeDirectDuty(token, DirectDutyEmp: DirectDuty) {
        const authHeader = new HttpHeaders();
        authHeader.append('Content-Type', 'application/json');
        authHeader.append('Authorization', token);
        authHeader.append('Accept', 'application/json');
        return this.http.put(GlobalVariable.BASE_API_URL + '/api/EmployeeDirectDuty/UpdateDirectDuty', DirectDutyEmp, { headers: authHeader })
            .map(this.extractData)
            .catch(this.handleErrorObservable);
    }
    CancelEmployeeDirectDuty(token, DirectDutyEmp: DirectDuty) {
        const authHeader = new HttpHeaders();
        authHeader.append('Content-Type', 'application/json');
        authHeader.append('Authorization', token);
        authHeader.append('Accept', 'application/json');
        return this.http.put(GlobalVariable.BASE_API_URL + '/api/EmployeeDirectDuty/CancelDirectDuty', DirectDutyEmp, { headers: authHeader })
            .map(this.extractData)
            .catch(this.handleErrorObservable);
    }
    GetEmployeeDirectDutyForApprovalForRH(token) {
        this.spinnerService.show();
        const authHeader1 = new HttpHeaders();
        authHeader1.append('Content-Type', 'application/json');
        authHeader1.append('Authorization', token);
        return new Promise((resolve, reject) => {
            setTimeout(() => {
                this.http.get(GlobalVariable.BASE_API_URL + '/api/EmployeeDirectDuty/GetEmployeeDirectDutyForApprovalForRH', { headers: authHeader1 })
                    .subscribe(data => {
                        this.spinnerService.hide();
                        resolve(data)
                    },
                        error => {
                            this.spinnerService.hide();
                            reject(error);
                        });
            }, 3000);
        });
    }
    GetEmployeeDirectDutyForApproval(token) {
        this.spinnerService.show();
        const authHeader1 = new HttpHeaders();
        authHeader1.append('Content-Type', 'application/json');
        authHeader1.append('Authorization', token);
        return new Promise((resolve, reject) => {
            setTimeout(() => {
                this.http.get(GlobalVariable.BASE_API_URL + '/api/EmployeeDirectDuty/GetEmployeeDirectDutyForApproval', { headers: authHeader1 })
                    .subscribe(data => {
                        this.spinnerService.hide();
                        resolve(data)
                    },
                        error => {
                            this.spinnerService.hide();
                            reject(error);
                        });
            }, 3000);
        });
    }
    GetEmployeeDirectDutyForApprovalById(token, DirectId: number) {
        this.spinnerService.show();
        const authHeader = new HttpHeaders();
        authHeader.append('Content-Type', 'application/json');
        authHeader.append('Authorization', token);
        return new Promise((resolve, reject) => {
            setTimeout(() => {
                this.http.get(GlobalVariable.BASE_API_URL + '/api/EmployeeDirectDuty/GetEmployeeDirectDutyForApprovalById?DirectId=' + DirectId, { headers: authHeader })
                    .subscribe(data => {
                        this.spinnerService.hide();
                        resolve(data)
                    },
                        error => {
                            this.spinnerService.hide();
                            reject(error);
                        });
            }, 3000);
        });
    }
    GetDirectDutyStatusApproved(token, FromDate, ToDate) {
        this.spinnerService.show();
        const authHeader = new HttpHeaders();
        authHeader.append('Content-Type', 'application/json');
        authHeader.append('Authorization', token);
        return new Promise((resolve, reject) => {
            setTimeout(() => {
                this.http.get(GlobalVariable.BASE_API_URL + '/api/EmployeeDirectDuty/GetEmployeeDirectDutyApproved?FromDate=' + FromDate + '&ToDate=' + ToDate, { headers: authHeader })
                    .subscribe(data => {
                        this.spinnerService.hide();
                        resolve(data)
                    },
                        error => {
                            this.spinnerService.hide();
                            reject(error);
                        });
            }, 3000);
        });
    }
    GetDirectDutyStatusRejected(token, FromDate, ToDate) {
        this.spinnerService.show();
        const authHeader = new HttpHeaders();
        authHeader.append('Content-Type', 'application/json');
        authHeader.append('Authorization', token);
        return new Promise((resolve, reject) => {
            setTimeout(() => {
                this.http.get(GlobalVariable.BASE_API_URL + '/api/EmployeeDirectDuty/GetEmployeeDirectDutyRejected?FromDate=' + FromDate + '&ToDate=' + ToDate, { headers: authHeader })
                    .subscribe(data => {
                        this.spinnerService.hide();
                        resolve(data)
                    },
                        error => {
                            this.spinnerService.hide();
                            reject(error);
                        });
            }, 3000);
        });
    }
    GetDirectDutyStatusPending(token, FromDate, ToDate) {
        this.spinnerService.show();
        const authHeader = new HttpHeaders();
        authHeader.append('Content-Type', 'application/json');
        authHeader.append('Authorization', token);
        return new Promise((resolve, reject) => {
            setTimeout(() => {
                this.http.get(GlobalVariable.BASE_API_URL + '/api/EmployeeDirectDuty/GetEmployeeDirectDutyPending?FromDate=' + FromDate + '&ToDate=' + ToDate, { headers: authHeader })
                    .subscribe(data => {
                        this.spinnerService.hide();
                        resolve(data)
                    },
                        error => {
                            this.spinnerService.hide();
                            reject(error);
                        });
            }, 3000);
        });
    }
    GetDirectDutyStatusApprovedByEmployeeId(token, EmployeeId, FromDate, ToDate) {
        this.spinnerService.show();
        const authHeader = new HttpHeaders();
        authHeader.append('Content-Type', 'application/json');
        authHeader.append('Authorization', token);
        return new Promise((resolve, reject) => {
            setTimeout(() => {
                this.http.get(GlobalVariable.BASE_API_URL + '/api/EmployeeDirectDuty/GetEmployeeDirectDutyApprovedByEmployeeId?EmployeeId=' + EmployeeId + '&FromDate=' + FromDate + '&ToDate=' + ToDate, { headers: authHeader })
                    .subscribe(data => {
                        this.spinnerService.hide();
                        resolve(data)
                    },
                        error => {
                            this.spinnerService.hide();
                            reject(error);
                        });
            }, 3000);
        });
    }
    GetDirectDutyStatusRejectedByEmployeeId(token, EmployeeId, FromDate, ToDate) {
        this.spinnerService.show();
        const authHeader = new HttpHeaders();
        authHeader.append('Content-Type', 'application/json');
        authHeader.append('Authorization', token);
        return new Promise((resolve, reject) => {
            setTimeout(() => {
                this.http.get(GlobalVariable.BASE_API_URL + '/api/EmployeeDirectDuty/GetEmployeeDirectDutyRejectedByEmployeeId?EmployeeId=' + EmployeeId + '&FromDate=' + FromDate + '&ToDate=' + ToDate, { headers: authHeader })
                    .subscribe(data => {
                        this.spinnerService.hide();
                        resolve(data)
                    },
                        error => {
                            this.spinnerService.hide();
                            reject(error);
                        });
            }, 3000);
        });
    }
    GetDirectDutyStatusPendingByEmployeeId(token, EmployeeId, FromDate, ToDate) {
        this.spinnerService.show();
        const authHeader = new HttpHeaders();
        authHeader.append('Content-Type', 'application/json');
        authHeader.append('Authorization', token);
        return new Promise((resolve, reject) => {
            setTimeout(() => {
                this.http.get(GlobalVariable.BASE_API_URL + '/api/EmployeeDirectDuty/GetEmployeeDirectDutyPendingByEmployeeId?EmployeeId=' + EmployeeId + '&FromDate=' + FromDate + '&ToDate=' + ToDate, { headers: authHeader })
                    .subscribe(data => {
                        this.spinnerService.hide();
                        resolve(data)
                    },
                        error => {
                            this.spinnerService.hide();
                            reject(error);
                        });
            }, 3000);
        });
    }
    CheckDirectDutyExistsByUserId(token, ApplicationDate: Date) {
        this.spinnerService.show();
        const authHeader = new HttpHeaders();
        authHeader.append('Content-Type', 'application/json');
        authHeader.append('Authorization', token);
        return new Promise((resolve, reject) => {
            setTimeout(() => {
                this.http.get(GlobalVariable.BASE_API_URL + '/api/EmployeeDirectDuty/CheckDirectDutyExistsByUserId?ApplicationDate=' + ApplicationDate, { headers: authHeader })
                    .subscribe(data => {
                        this.spinnerService.hide();
                        resolve(data)
                    },
                        error => {
                            this.spinnerService.hide();
                            reject(error);
                        });
            }, 3000);
        });
    }
    CheckDirectDutyExistsByEmployeeId(token, EmployeeId: number, ApplicationDate: Date) {
        this.spinnerService.show();
        const authHeader = new HttpHeaders();
        authHeader.append('Content-Type', 'application/json');
        authHeader.append('Authorization', token);
        return new Promise((resolve, reject) => {
            setTimeout(() => {
                this.http.get(GlobalVariable.BASE_API_URL + '/api/EmployeeDirectDuty/CheckDirectDutyExistsByEmployeeId?EmployeeId=' + EmployeeId + '&ApplicationDate=' + ApplicationDate, { headers: authHeader })
                    .subscribe(data => {
                        this.spinnerService.hide();
                        resolve(data)
                    },
                        error => {
                            this.spinnerService.hide();
                            reject(error);
                        });
            }, 3000);
        });
    }
    //End Of Direct Duty
    //Begning Of Tour
    GetEmployeeTourStatus(token) {
        this.spinnerService.show();
        const authHeader = new HttpHeaders();
        authHeader.append('Content-Type', 'application/json');
        authHeader.append('Authorization', token);
        return new Promise((resolve, reject) => {
            setTimeout(() => {
                this.http.get(GlobalVariable.BASE_API_URL + '/api/EmployeeTour/GetEmployeeTourStatus', { headers: authHeader })
                    .subscribe(data => {
                        this.spinnerService.hide();
                        resolve(data)
                    },
                        error => {
                            this.spinnerService.hide();
                            reject(error);
                        });
            }, 3000);
        });
    }
    GetEmployeeTourStatusByEmployeeId(token, EmployeeId: number) {
        this.spinnerService.show();
        const authHeader = new HttpHeaders();
        authHeader.append('Content-Type', 'application/json');
        authHeader.append('Authorization', token);
        return new Promise((resolve, reject) => {
            setTimeout(() => {
                this.http.get(GlobalVariable.BASE_API_URL + '/api/EmployeeTour/GetEmployeeTourStatusByEmployeeId?EmployeeId=' + EmployeeId, { headers: authHeader })
                    .subscribe(data => {
                        this.spinnerService.hide();
                        resolve(data)
                    },
                        error => {
                            this.spinnerService.hide();
                            reject(error);
                        });
            }, 3000);
        });
    }
    AddEmployeeTour(token, Tour: TourModel) {
        const authHeader = new HttpHeaders();
        authHeader.append('Content-Type', 'application/json');
        authHeader.append('Authorization', token);
        authHeader.append('Accept', 'application/json');
        return this.http.post(GlobalVariable.BASE_API_URL + '/api/EmployeeTour/AddTour', Tour, { headers: authHeader })
            .map(this.extractData)
            .catch(this.handleErrorObservable);
    }
    UpdateEmployeeTour(token, Tour: TourModel) {
        const authHeader = new HttpHeaders();
        authHeader.append('Content-Type', 'application/json');
        authHeader.append('Authorization', token);
        authHeader.append('Accept', 'application/json');
        return this.http.put(GlobalVariable.BASE_API_URL + '/api/EmployeeTour/UpdateTour', Tour, { headers: authHeader })
            .map(this.extractData)
            .catch(this.handleErrorObservable);
    }
    CancelEmployeeTour(token, Tour: TourModel) {
        const authHeader = new HttpHeaders();
        authHeader.append('Content-Type', 'application/json');
        authHeader.append('Authorization', token);
        authHeader.append('Accept', 'application/json');
        return this.http.put(GlobalVariable.BASE_API_URL + '/api/EmployeeTour/CancelTour', Tour, { headers: authHeader })
            .map(this.extractData)
            .catch(this.handleErrorObservable);
    }
    GetEmployeeTourForApprovallistForRH(token) {
        this.spinnerService.show();
        const authHeader1 = new HttpHeaders();
        authHeader1.append('Content-Type', 'application/json');
        authHeader1.append('Authorization', token);
        return new Promise((resolve, reject) => {
            setTimeout(() => {
                this.http.get(GlobalVariable.BASE_API_URL + '/api/EmployeeTour/GetEmployeeTourForApprovallistForRH', { headers: authHeader1 })
                    .subscribe(data => {
                        this.spinnerService.hide();
                        resolve(data)
                    },
                        error => {
                            this.spinnerService.hide();
                            reject(error);
                        });
            }, 3000);
        });
    }
    GetEmployeeTourForApproval(token) {
        this.spinnerService.show();
        const authHeader1 = new HttpHeaders();
        authHeader1.append('Content-Type', 'application/json');
        authHeader1.append('Authorization', token);
        return new Promise((resolve, reject) => {
            setTimeout(() => {
                this.http.get(GlobalVariable.BASE_API_URL + '/api/EmployeeTour/GetEmployeeTourForApproval', { headers: authHeader1 })
                    .subscribe(data => {
                        this.spinnerService.hide();
                        resolve(data)
                    },
                        error => {
                            this.spinnerService.hide();
                            reject(error);
                        });
            }, 3000);
        });
    }
    GetEmployeeTourForApprovalById(token, TourId: number) {
        this.spinnerService.show();
        const authHeader = new HttpHeaders();
        authHeader.append('Content-Type', 'application/json');
        authHeader.append('Authorization', token);
        return new Promise((resolve, reject) => {
            setTimeout(() => {
                this.http.get(GlobalVariable.BASE_API_URL + '/api/EmployeeTour/GetEmployeeTourForApprovalById?TourId=' + TourId, { headers: authHeader })
                    .subscribe(data => {
                        this.spinnerService.hide();
                        resolve(data)
                    },
                        error => {
                            this.spinnerService.hide();
                            reject(error);
                        });
            }, 3000);
        });
    }
    GetTourStatusApproved(token, FromDate, ToDate) {
        this.spinnerService.show();
        const authHeader = new HttpHeaders();
        authHeader.append('Content-Type', 'application/json');
        authHeader.append('Authorization', token);
        return new Promise((resolve, reject) => {
            setTimeout(() => {
                this.http.get(GlobalVariable.BASE_API_URL + '/api/EmployeeTour/GetEmployeeTourApproved?FromDate=' + FromDate + '&ToDate=' + ToDate, { headers: authHeader })
                    .subscribe(data => {
                        this.spinnerService.hide();
                        resolve(data)
                    },
                        error => {
                            this.spinnerService.hide();
                            reject(error);
                        });
            }, 3000);
        });
    }
    GetTourStatusRejected(token, FromDate, ToDate) {
        this.spinnerService.show();
        const authHeader = new HttpHeaders();
        authHeader.append('Content-Type', 'application/json');
        authHeader.append('Authorization', token);
        return new Promise((resolve, reject) => {
            setTimeout(() => {
                this.http.get(GlobalVariable.BASE_API_URL + '/api/EmployeeTour/GetEmployeeTourRejected?FromDate=' + FromDate + '&ToDate=' + ToDate, { headers: authHeader })
                    .subscribe(data => {
                        this.spinnerService.hide();
                        resolve(data)
                    },
                        error => {
                            this.spinnerService.hide();
                            reject(error);
                        });
            }, 3000);
        });
    }
    GetTourStatusPending(token, FromDate, ToDate) {
        this.spinnerService.show();
        const authHeader = new HttpHeaders();
        authHeader.append('Content-Type', 'application/json');
        authHeader.append('Authorization', token);
        return new Promise((resolve, reject) => {
            setTimeout(() => {
                this.http.get(GlobalVariable.BASE_API_URL + '/api/EmployeeTour/GetEmployeeTourPending?FromDate=' + FromDate + '&ToDate=' + ToDate, { headers: authHeader })
                    .subscribe(data => {
                        this.spinnerService.hide();
                        resolve(data)
                    },
                        error => {
                            this.spinnerService.hide();
                            reject(error);
                        });
            }, 3000);
        });
    }
    GetTourStatusApprovedByEmployeeId(token, EmployeeId, FromDate, ToDate) {
        this.spinnerService.show();
        const authHeader = new HttpHeaders();
        authHeader.append('Content-Type', 'application/json');
        authHeader.append('Authorization', token);
        return new Promise((resolve, reject) => {
            setTimeout(() => {
                this.http.get(GlobalVariable.BASE_API_URL + '/api/EmployeeTour/GetEmployeeTourApprovedByEmployeeId?EmployeeId=' + EmployeeId + '&FromDate=' + FromDate + '&ToDate=' + ToDate, { headers: authHeader })
                    .subscribe(data => {
                        this.spinnerService.hide();
                        resolve(data)
                    },
                        error => {
                            this.spinnerService.hide();
                            reject(error);
                        });
            }, 3000);
        });
    }
    GetTourStatusRejectedByEmployeeId(token, EmployeeId, FromDate, ToDate) {
        this.spinnerService.show();
        const authHeader = new HttpHeaders();
        authHeader.append('Content-Type', 'application/json');
        authHeader.append('Authorization', token);
        return new Promise((resolve, reject) => {
            setTimeout(() => {
                this.http.get(GlobalVariable.BASE_API_URL + '/api/EmployeeTour/GetEmployeeTourRejectedByEmployeeId?EmployeeId=' + EmployeeId + '&FromDate=' + FromDate + '&ToDate=' + ToDate, { headers: authHeader })
                    .subscribe(data => {
                        this.spinnerService.hide();
                        resolve(data)
                    },
                        error => {
                            this.spinnerService.hide();
                            reject(error);
                        });
            }, 3000);
        });
    }
    GetTourStatusPendingByEmployeeId(token, EmployeeId, FromDate, ToDate) {
        this.spinnerService.show();
        const authHeader = new HttpHeaders();
        authHeader.append('Content-Type', 'application/json');
        authHeader.append('Authorization', token);
        return new Promise((resolve, reject) => {
            setTimeout(() => {
                this.http.get(GlobalVariable.BASE_API_URL + '/api/EmployeeTour/GetEmployeeTourPendingByEmployeeId?EmployeeId=' + EmployeeId + '&FromDate=' + FromDate + '&ToDate=' + ToDate, { headers: authHeader })
                    .subscribe(data => {
                        this.spinnerService.hide();
                        resolve(data)
                    },
                        error => {
                            this.spinnerService.hide();
                            reject(error);
                        });
            }, 3000);
        });
    }
    CheckTourApplicationDateexists(token, FromDate: Date, ToDate: Date, EmployeeId: number) {
        this.spinnerService.show();
        const authHeader = new HttpHeaders();
        authHeader.append('Content-Type', 'application/json');
        authHeader.append('Authorization', token);
        return new Promise((resolve, reject) => {
            setTimeout(() => {
                this.http.get(GlobalVariable.BASE_API_URL + '/api/EmployeeTour/CheckTourApplicationDateexists?FromDate=' + FromDate + '&ToDate=' + ToDate + '&EmployeeId=' + EmployeeId, { headers: authHeader })
                    .subscribe(data => {
                        this.spinnerService.hide();
                        resolve(data)
                    },
                        error => {
                            this.spinnerService.hide();
                            reject(error);
                        });
            }, 3000);
        });
    }
    //End Of Tour
    // Begin EmployeeCompoff
    GetEmployeeDetailsByUserLoginIdCompoff(token, ApplicationDate: Date) {
        this.spinnerService.show();
        const authHeader = new HttpHeaders();
        authHeader.append('Content-Type', 'application/json');
        authHeader.append('Authorization', token);
        return new Promise((resolve, reject) => {
            setTimeout(() => {
                this.http.get(GlobalVariable.BASE_API_URL + '/api/EmployeeCompoff/GetEmployeeDetailsByUserLoginIdCompoff?ApplicationDate=' + ApplicationDate, { headers: authHeader })
                    .subscribe(data => {
                        this.spinnerService.hide();
                        resolve(data)
                    },
                        error => {
                            this.spinnerService.hide();
                            reject(error);
                        });
            }, 3000);
        });
    }
    GetEmployeeDetailsByEmployeeIdCompoff(token, EmployeeId: number, ApplicationDate: Date) {
        this.spinnerService.show();
        const authHeader = new HttpHeaders();
        authHeader.append('Content-Type', 'application/json');
        authHeader.append('Authorization', token);
        return new Promise((resolve, reject) => {
            setTimeout(() => {
                this.http.get(GlobalVariable.BASE_API_URL + '/api/EmployeeCompoff/GetEmployeeDetailsByEmployeeIdCompoff?EmployeeId=' + EmployeeId + '&ApplicationDate=' + ApplicationDate, { headers: authHeader })
                    .subscribe(data => {
                        this.spinnerService.hide();
                        resolve(data)
                    },
                        error => {
                            this.spinnerService.hide();
                            reject(error);
                        });
            }, 3000);
        });
    }
    AddEmployeeCompoff(token, Compoff: EmployeeCompoffModel) {
        const authHeader = new HttpHeaders();
        authHeader.append('Content-Type', 'application/json');
        authHeader.append('Authorization', token);
        authHeader.append('Accept', 'application/json');
        return this.http.post(GlobalVariable.BASE_API_URL + '/api/EmployeeCompoff/AddEmployeeCompoff', Compoff, { headers: authHeader })
            .map(this.extractData)
            .catch(this.handleErrorObservable);
    }
    GetEmployeeCompoffStatusApproved(token, FromDate, ToDate) {
        this.spinnerService.show();
        const authHeader = new HttpHeaders();
        authHeader.append('Content-Type', 'application/json');
        authHeader.append('Authorization', token);
        return new Promise((resolve, reject) => {
            setTimeout(() => {
                this.http.get(GlobalVariable.BASE_API_URL + '/api/EmployeeCompoff/GetEmployeeCompoffStatusApproved?FromDate=' + FromDate + '&ToDate=' + ToDate, { headers: authHeader })
                    .subscribe(data => {
                        this.spinnerService.hide();
                        resolve(data)
                    },
                        error => {
                            this.spinnerService.hide();
                            reject(error);
                        });
            }, 3000);
        });
    }
    GetEmployeeCompoffStatusRejected(token, FromDate, ToDate) {
        this.spinnerService.show();
        const authHeader = new HttpHeaders();
        authHeader.append('Content-Type', 'application/json');
        authHeader.append('Authorization', token);
        return new Promise((resolve, reject) => {
            setTimeout(() => {
                this.http.get(GlobalVariable.BASE_API_URL + '/api/EmployeeCompoff/GetEmployeeCompoffStatusRejected?FromDate=' + FromDate + '&ToDate=' + ToDate, { headers: authHeader })
                    .subscribe(data => {
                        this.spinnerService.hide();
                        resolve(data)
                    },
                        error => {
                            this.spinnerService.hide();
                            reject(error);
                        });
            }, 3000);
        });
    }
    GetEmployeeCompoffStatusPending(token, FromDate, ToDate) {
        this.spinnerService.show();
        const authHeader = new HttpHeaders();
        authHeader.append('Content-Type', 'application/json');
        authHeader.append('Authorization', token);
        return new Promise((resolve, reject) => {
            setTimeout(() => {
                this.http.get(GlobalVariable.BASE_API_URL + '/api/EmployeeCompoff/GetEmployeeCompoffStatusPending?FromDate=' + FromDate + '&ToDate=' + ToDate, { headers: authHeader })
                    .subscribe(data => {
                        this.spinnerService.hide();
                        resolve(data)
                    },
                        error => {
                            this.spinnerService.hide();
                            reject(error);
                        });
            }, 3000);
        });
    }
    GetEmployeeCompoffStatusApprovedByEmployeeId(token, EmployeeId, FromDate, ToDate) {
        this.spinnerService.show();
        const authHeader = new HttpHeaders();
        authHeader.append('Content-Type', 'application/json');
        authHeader.append('Authorization', token);
        return new Promise((resolve, reject) => {
            setTimeout(() => {
                this.http.get(GlobalVariable.BASE_API_URL + '/api/EmployeeCompoff/GetEmployeeCompoffStatusApprovedByEmployeeId?EmployeeId=' + EmployeeId + '&FromDate=' + FromDate + '&ToDate=' + ToDate, { headers: authHeader })
                    .subscribe(data => {
                        this.spinnerService.hide();
                        resolve(data)
                    },
                        error => {
                            this.spinnerService.hide();
                            reject(error);
                        });
            }, 3000);
        });
    }
    GetEmployeeCompoffStatusRejectedByEmployeeId(token, EmployeeId, FromDate, ToDate) {
        this.spinnerService.show();
        const authHeader = new HttpHeaders();
        authHeader.append('Content-Type', 'application/json');
        authHeader.append('Authorization', token);
        return new Promise((resolve, reject) => {
            setTimeout(() => {
                this.http.get(GlobalVariable.BASE_API_URL + '/api/EmployeeCompoff/GetEmployeeCompoffStatusRejectedByEmployeeId?EmployeeId=' + EmployeeId + '&FromDate=' + FromDate + '&ToDate=' + ToDate, { headers: authHeader })
                    .subscribe(data => {
                        this.spinnerService.hide();
                        resolve(data)
                    },
                        error => {
                            this.spinnerService.hide();
                            reject(error);
                        });
            }, 3000);
        });
    }
    GetEmployeeCompoffStatusPendingByEmployeeId(token, EmployeeId, FromDate, ToDate) {
        this.spinnerService.show();
        const authHeader = new HttpHeaders();
        authHeader.append('Content-Type', 'application/json');
        authHeader.append('Authorization', token);
        return new Promise((resolve, reject) => {
            setTimeout(() => {
                this.http.get(GlobalVariable.BASE_API_URL + '/api/EmployeeCompoff/GetEmployeeCompoffStatusPendingByEmployeeId?EmployeeId=' + EmployeeId + '&FromDate=' + FromDate + '&ToDate=' + ToDate, { headers: authHeader })
                    .subscribe(data => {
                        this.spinnerService.hide();
                        resolve(data)
                    },
                        error => {
                            this.spinnerService.hide();
                            reject(error);
                        });
            }, 3000);
        });
    }
    //Approval
    GetEmployeeCompoffApplieslistForRH(token) {
        this.spinnerService.show();
        const authHeader1 = new HttpHeaders();
        authHeader1.append('Content-Type', 'application/json');
        authHeader1.append('Authorization', token);
        return new Promise((resolve, reject) => {
            setTimeout(() => {
                this.http.get(GlobalVariable.BASE_API_URL + '/api/EmployeeCompoff/GetEmployeeCompoffApplieslistForRH', { headers: authHeader1 })
                    .subscribe(data => {
                        this.spinnerService.hide();
                        resolve(data)
                    },
                        error => {
                            this.spinnerService.hide();
                            reject(error);
                        });
            }, 3000);
        });
    }
    GetEmployeeCompoffApplies(token) {
        this.spinnerService.show();
        const authHeader1 = new HttpHeaders();
        authHeader1.append('Content-Type', 'application/json');
        authHeader1.append('Authorization', token);
        return new Promise((resolve, reject) => {
            setTimeout(() => {
                this.http.get(GlobalVariable.BASE_API_URL + '/api/EmployeeCompoff/GetEmployeeCompoffApplies', { headers: authHeader1 })
                    .subscribe(data => {
                        this.spinnerService.hide();
                        resolve(data)
                    },
                        error => {
                            this.spinnerService.hide();
                            reject(error);
                        });
            }, 3000);
        });
    }
    GetEmployeeCompoffApplyByRefId(token, RefId: number) {
        this.spinnerService.show();
        const authHeader = new HttpHeaders();
        authHeader.append('Content-Type', 'application/json');
        authHeader.append('Authorization', token);
        return new Promise((resolve, reject) => {
            setTimeout(() => {
                this.http.get(GlobalVariable.BASE_API_URL + '/api/EmployeeCompoff/GetEmployeeCompoffApplyByRefId?RefId=' + RefId, { headers: authHeader })
                    .subscribe(data => {
                        this.spinnerService.hide();
                        resolve(data)
                    },
                        error => {
                            this.spinnerService.hide();
                            reject(error);
                        });
            }, 3000);
        });
    }
    EmployeeCompoffApprovedOrRejected(token, Compoff: EmployeeCompoffModel) {
        const authHeader = new HttpHeaders();
        authHeader.append('Content-Type', 'application/json');
        authHeader.append('Authorization', token);
        authHeader.append('Accept', 'application/json');
        return this.http.put(GlobalVariable.BASE_API_URL + '/api/EmployeeCompoff/EmployeeCompoffApprovedOrRejected', Compoff, { headers: authHeader })
            .map(this.extractData)
            .catch(this.handleErrorObservable);
    }
    EmployeeCompoffCancel(token, Compoff: EmployeeCompoffModel) {
        const authHeader = new HttpHeaders();
        authHeader.append('Content-Type', 'application/json');
        authHeader.append('Authorization', token);
        authHeader.append('Accept', 'application/json');
        return this.http.put(GlobalVariable.BASE_API_URL + '/api/EmployeeCompoff/EmployeeCompoffCancel', Compoff, { headers: authHeader })
            .map(this.extractData)
            .catch(this.handleErrorObservable);
    }
    // End EmployeeCompoff
    //Begning Of DD-Tour-Leave-Reports
    GetEmployeeDD_Tour_Leave_Reports(token, FromDate: Date, ToDate: Date, companyid: number, branchid: number) {
        const authHeader = new HttpHeaders();
        authHeader.append('Content-Type', 'application/json');
        authHeader.append('Authorization', token);
        setTimeout(() => {
            this.http.get(GlobalVariable.BASE_API_URL + '/api/EmployeeAttendance/GetEmp_DD_Tour_Leave?FromDate=' + FromDate + '&ToDate=' + ToDate + '&companyid=' + companyid + '&branchid=' + branchid, { headers: authHeader, responseType: 'blob' as 'json' }).subscribe((res) => {

                var file = new Blob(<any>[res], { type: 'application/vnd.ms-excel' });
                var fileURL = URL.createObjectURL(file);

                window.open(fileURL);

            });
        }, 3000);
    }

    GetEmployeeLeaveDDTourRegReport(token, FromDate: Date, ToDate: Date, companyid: number, branchid: number) {
        this.spinnerService.show();
        const authHeader = new HttpHeaders();
        authHeader.append('Content-Type', 'application/json');
        authHeader.append('Authorization', token);
        return new Promise((resolve, reject) => {
            setTimeout(() => {
                this.http.get(GlobalVariable.BASE_API_URL + '/api/EmployeeAttendance/GetEmployeeLeaveDDTourRegReport?FromDate=' + FromDate + '&ToDate=' + ToDate+ '&companyid=' + companyid+ '&branchid=' + branchid, { headers: authHeader })
                    .subscribe(data => {
                        this.spinnerService.hide();
                        resolve(data)
                    },
                        error => {
                            this.spinnerService.hide();
                            reject(error);
                        });
            }, 3000);
        });
    }
    // End Of DD-Tour-Leave-Reports
    // Begin Employee Tour Reimbursement
    GetEmployeeDetailsForReibursementByUserId(token) {
        this.spinnerService.show();
        const authHeader1 = new HttpHeaders();
        authHeader1.append('Content-Type', 'application/json');
        authHeader1.append('Authorization', token);
        return new Promise((resolve, reject) => {
            setTimeout(() => {
                this.http.get(GlobalVariable.BASE_API_URL + '/api/TourReimbursement/GetEmployeeDetailsForReibursementByUserId', { headers: authHeader1 })
                    .subscribe(data => {
                        this.spinnerService.hide();
                        resolve(data)
                    },
                        error => {
                            this.spinnerService.hide();
                            reject(error);
                        });
            }, 3000);
        });
    }
    GetEmployeeDetailsForReibursementByEmployeeId(token, EmployeeId: number) {
        this.spinnerService.show();
        const authHeader1 = new HttpHeaders();
        authHeader1.append('Content-Type', 'application/json');
        authHeader1.append('Authorization', token);
        return new Promise((resolve, reject) => {
            setTimeout(() => {
                this.http.get(GlobalVariable.BASE_API_URL + '/api/TourReimbursement/GetEmployeeDetailsForReibursementByEmployeeId?EmployeeId=' + EmployeeId, { headers: authHeader1 })
                    .subscribe(data => {
                        this.spinnerService.hide();
                        resolve(data)
                    },
                        error => {
                            this.spinnerService.hide();
                            reject(error);
                        });
            }, 3000);
        });
    }
    GetExpenseHeadForApplication(token, DecisionId: number, LevelId: number, GradeId: number) {
        this.spinnerService.show();
        const authHeader = new HttpHeaders();
        authHeader.append('Content-Type', 'application/json');
        authHeader.append('Authorization', token);
        return new Promise((resolve, reject) => {
            setTimeout(() => {
                this.http.get(GlobalVariable.BASE_API_URL + '/api/TourReimbursement/GetExpenseHeadForApplication?DecisionId=' + DecisionId + '&LevelId=' + LevelId + '&GradeId=' + GradeId, { headers: authHeader })
                    .subscribe(data => {
                        this.spinnerService.hide();
                        resolve(data)
                    },
                        error => {
                            this.spinnerService.hide();
                            reject(error);
                        });
            }, 3000);
        });
    }
    AddEmployeeTourReimbursementApplication(token, TourReimbursement: TourReimbursementMasterModel) {
        const authHeader = new HttpHeaders();
        authHeader.append('Content-Type', 'application/json');
        authHeader.append('Authorization', token);
        authHeader.append('Accept', 'application/json');
        return this.http.post(GlobalVariable.BASE_API_URL + '/api/TourReimbursement/AddEmployeeTourReimbursementApplication', TourReimbursement, { headers: authHeader })
            .map(this.extractData)
            .catch(this.handleErrorObservable);
    }
    CheckTourReimbursementApplicationDateexists(token, FromDate: Date, ToDate: Date, EmployeeId: number) {
        this.spinnerService.show();
        const authHeader = new HttpHeaders();
        authHeader.append('Content-Type', 'application/json');
        authHeader.append('Authorization', token);
        return new Promise((resolve, reject) => {
            setTimeout(() => {
                this.http.get(GlobalVariable.BASE_API_URL + '/api/TourReimbursement/CheckTourReimbursementApplicationDateexists?FromDate=' + FromDate + '&ToDate=' + ToDate + '&EmployeeId=' + EmployeeId, { headers: authHeader })
                    .subscribe(data => {
                        this.spinnerService.hide();
                        resolve(data)
                    },
                        error => {
                            this.spinnerService.hide();
                            reject(error);
                        });
            }, 3000);
        });
    }
    GetNofoDays(token, FromDate: Date, ToDate: Date) {
        this.spinnerService.show();
        const authHeader = new HttpHeaders();
        authHeader.append('Content-Type', 'application/json');
        authHeader.append('Authorization', token);
        return new Promise((resolve, reject) => {
            setTimeout(() => {
                this.http.get(GlobalVariable.BASE_API_URL + '/api/TourReimbursement/GetNofoDays?FromDate=' + FromDate + '&ToDate=' + ToDate, { headers: authHeader })
                    .subscribe(data => {
                        this.spinnerService.hide();
                        resolve(data)
                    },
                        error => {
                            this.spinnerService.hide();
                            reject(error);
                        });
            }, 3000);
        });
    }
    //Approval RH
    GetReibursementApplicationForRH(token) {
        this.spinnerService.show();
        const authHeader1 = new HttpHeaders();
        authHeader1.append('Content-Type', 'application/json');
        authHeader1.append('Authorization', token);
        return new Promise((resolve, reject) => {
            setTimeout(() => {
                this.http.get(GlobalVariable.BASE_API_URL + '/api/TourReimbursement/GetReibursementApplicationForRH', { headers: authHeader1 })
                    .subscribe(data => {
                        this.spinnerService.hide();
                        resolve(data)
                    },
                        error => {
                            this.spinnerService.hide();
                            reject(error);
                        });
            }, 3000);
        });
    }
    GetReibursementDetailsByRefId(token, RefIdTR) {
        this.spinnerService.show();
        const authHeader1 = new HttpHeaders();
        authHeader1.append('Content-Type', 'application/json');
        authHeader1.append('Authorization', token);
        return new Promise((resolve, reject) => {
            setTimeout(() => {
                this.http.get(GlobalVariable.BASE_API_URL + '/api/TourReimbursement/GetEmployeeDetailsForReibursementByRefIdTRForRH?RefIdTR=' + RefIdTR, { headers: authHeader1 })
                    .subscribe(data => {
                        this.spinnerService.hide();
                        resolve(data)
                    },
                        error => {
                            this.spinnerService.hide();
                            reject(error);
                        });
            }, 3000);
        });
    }
    GetExpenseDetailsByRefIdTR(token, RefIdTR) {
        this.spinnerService.show();
        const authHeader = new HttpHeaders();
        authHeader.append('Content-Type', 'application/json');
        authHeader.append('Authorization', token);
        return new Promise((resolve, reject) => {
            setTimeout(() => {
                this.http.get(GlobalVariable.BASE_API_URL + '/api/TourReimbursement/GetExpenseDetailsByRefIdTR?RefIdTR=' + RefIdTR, { headers: authHeader })
                    .subscribe(data => {
                        this.spinnerService.hide();
                        resolve(data)
                    },
                        error => {
                            this.spinnerService.hide();
                            reject(error);
                        });
            }, 3000);
        });
    }
    ApproveddEmployeeTourReimbursementApplicationByRH(token, TourReimbursement: TourReimbursementMasterModel) {
        const authHeader = new HttpHeaders();
        authHeader.append('Content-Type', 'application/json');
        authHeader.append('Authorization', token);
        authHeader.append('Accept', 'application/json');
        return this.http.put(GlobalVariable.BASE_API_URL + '/api/TourReimbursement/ApproveddEmployeeTourReimbursementApplicationByRH', TourReimbursement, { headers: authHeader })
            .map(this.extractData)
            .catch(this.handleErrorObservable);
    }
    //Approval ForHR
    GetReibursementApplicationForHR(token) {
        this.spinnerService.show();
        const authHeader1 = new HttpHeaders();
        authHeader1.append('Content-Type', 'application/json');
        authHeader1.append('Authorization', token);
        return new Promise((resolve, reject) => {
            setTimeout(() => {
                this.http.get(GlobalVariable.BASE_API_URL + '/api/TourReimbursement/GetEmployeeExpenseReimbursementDetailsForHR', { headers: authHeader1 })
                    .subscribe(data => {
                        this.spinnerService.hide();
                        resolve(data)
                    },
                        error => {
                            this.spinnerService.hide();
                            reject(error);
                        });
            }, 3000);
        });
    }
    ApprovedEmployeeTourReimbursementApplicationByHR(token, TourReimbursement: TourReimbursementMasterModel) {
        const authHeader = new HttpHeaders();
        authHeader.append('Content-Type', 'application/json');
        authHeader.append('Authorization', token);
        authHeader.append('Accept', 'application/json');
        return this.http.put(GlobalVariable.BASE_API_URL + '/api/TourReimbursement/ApproveddEmployeeTourReimbursementApplicationByHR', TourReimbursement, { headers: authHeader })
            .map(this.extractData)
            .catch(this.handleErrorObservable);
    }
    //Status
    GetExpenseDetailsStatusGVByUserId(token) {
        this.spinnerService.show();
        const authHeader = new HttpHeaders();
        authHeader.append('Content-Type', 'application/json');
        authHeader.append('Authorization', token);
        return new Promise((resolve, reject) => {
            setTimeout(() => {
                this.http.get(GlobalVariable.BASE_API_URL + '/api/TourReimbursement/GetExpenseDetailsStatusGVByUserId', { headers: authHeader })
                    .subscribe(data => {
                        this.spinnerService.hide();
                        resolve(data)
                    },
                        error => {
                            this.spinnerService.hide();
                            reject(error);
                        });
            }, 3000);
        });
    }
    GetExpenseDetailsForFinalSubmissionByRefIdTR(token, RefIdTR) {
        this.spinnerService.show();
        const authHeader = new HttpHeaders();
        authHeader.append('Content-Type', 'application/json');
        authHeader.append('Authorization', token);
        return new Promise((resolve, reject) => {
            setTimeout(() => {
                this.http.get(GlobalVariable.BASE_API_URL + '/api/TourReimbursement/GetExpenseDetailsForFinalSubmissionByRefIdTR?RefIdTR=' + RefIdTR, { headers: authHeader })
                    .subscribe(data => {
                        this.spinnerService.hide();
                        resolve(data)
                    },
                        error => {
                            this.spinnerService.hide();
                            reject(error);
                        });
            }, 3000);
        });
    }
    // Approval Finance
    GetReibursementApplicationForFinance(token) {
        this.spinnerService.show();
        const authHeader1 = new HttpHeaders();
        authHeader1.append('Content-Type', 'application/json');
        authHeader1.append('Authorization', token);
        return new Promise((resolve, reject) => {
            setTimeout(() => {
                this.http.get(GlobalVariable.BASE_API_URL + '/api/TourReimbursement/GetEmployeeExpenseReimbursementDetailsForFinance', { headers: authHeader1 })
                    .subscribe(data => {
                        this.spinnerService.hide();
                        resolve(data)
                    },
                        error => {
                            this.spinnerService.hide();
                            reject(error);
                        });
            }, 3000);
        });
    }
    ApprovedEmployeeTourReimbursementApplicationByFinance(token, TourReimbursement: TourReimbursementMasterModel) {
        const authHeader = new HttpHeaders();
        authHeader.append('Content-Type', 'application/json');
        authHeader.append('Authorization', token);
        authHeader.append('Accept', 'application/json');
        return this.http.put(GlobalVariable.BASE_API_URL + '/api/TourReimbursement/ApproveddEmployeeTourReimbursementApplicationByFinance', TourReimbursement, { headers: authHeader })
            .map(this.extractData)
            .catch(this.handleErrorObservable);
    }
    // final submit
    FinalSubmitEmployeeTourReimbursementApplication(token, TourReimbursement: TourReimbursementMasterModel) {
        const authHeader = new HttpHeaders();
        authHeader.append('Content-Type', 'application/json');
        authHeader.append('Authorization', token);
        authHeader.append('Accept', 'application/json');
        return this.http.put(GlobalVariable.BASE_API_URL + '/api/TourReimbursement/FinalSubmitEmployeeTourReimbursementApplication', TourReimbursement, { headers: authHeader })
            .map(this.extractData)
            .catch(this.handleErrorObservable);
    }
    // Final Approval HR
    GetEmployeeFinalExpenseReimbursementDetailsForHR(token) {
        this.spinnerService.show();
        const authHeader = new HttpHeaders();
        authHeader.append('Content-Type', 'application/json');
        authHeader.append('Authorization', token);
        return new Promise((resolve, reject) => {
            setTimeout(() => {
                this.http.get(GlobalVariable.BASE_API_URL + '/api/TourReimbursement/GetEmployeeFinalExpenseReimbursementDetailsForHR', { headers: authHeader })
                    .subscribe(data => {
                        this.spinnerService.hide();
                        resolve(data)
                    },
                        error => {
                            this.spinnerService.hide();
                            reject(error);
                        });
            }, 3000);
        });
    }
    GetFinalExpenseDetailsByRefIdTRforHR(token, RefIdTR) {
        this.spinnerService.show();
        const authHeader = new HttpHeaders();
        authHeader.append('Content-Type', 'application/json');
        authHeader.append('Authorization', token);
        return new Promise((resolve, reject) => {
            setTimeout(() => {
                this.http.get(GlobalVariable.BASE_API_URL + '/api/TourReimbursement/GetFinalExpenseDetailsByRefIdTRforHR?RefIdTR=' + RefIdTR, { headers: authHeader })
                    .subscribe(data => {
                        this.spinnerService.hide();
                        resolve(data)
                    },
                        error => {
                            this.spinnerService.hide();
                            reject(error);
                        });
            }, 3000);
        });
    }
    ApproveddEmployeeTourReimbursementApplicationByHRFinal(token, TourReimbursement: TourReimbursementMasterModel) {
        const authHeader = new HttpHeaders();
        authHeader.append('Content-Type', 'application/json');
        authHeader.append('Authorization', token);
        authHeader.append('Accept', 'application/json');
        return this.http.put(GlobalVariable.BASE_API_URL + '/api/TourReimbursement/ApproveddEmployeeTourReimbursementApplicationByHRFinal', TourReimbursement, { headers: authHeader })
            .map(this.extractData)
            .catch(this.handleErrorObservable);
    }
    // Final Approval Finance
    GetEmployeeFinalExpenseReimbursementDetailsForFinance(token) {
        this.spinnerService.show();
        const authHeader = new HttpHeaders();
        authHeader.append('Content-Type', 'application/json');
        authHeader.append('Authorization', token);
        return new Promise((resolve, reject) => {
            setTimeout(() => {
                this.http.get(GlobalVariable.BASE_API_URL + '/api/TourReimbursement/GetEmployeeFinalExpenseReimbursementDetailsForFinance', { headers: authHeader })
                    .subscribe(data => {
                        this.spinnerService.hide();
                        resolve(data)
                    },
                        error => {
                            this.spinnerService.hide();
                            reject(error);
                        });
            }, 3000);
        });
    }
    GetFinalExpenseDetailsByRefIdTRforFinance(token, RefIdTR) {
        this.spinnerService.show();
        const authHeader = new HttpHeaders();
        authHeader.append('Content-Type', 'application/json');
        authHeader.append('Authorization', token);
        return new Promise((resolve, reject) => {
            setTimeout(() => {
                this.http.get(GlobalVariable.BASE_API_URL + '/api/TourReimbursement/GetFinalExpenseDetailsByRefIdTRforFinance?RefIdTR=' + RefIdTR, { headers: authHeader })
                    .subscribe(data => {
                        this.spinnerService.hide();
                        resolve(data)
                    },
                        error => {
                            this.spinnerService.hide();
                            reject(error);
                        });
            }, 3000);
        });
    }
    UpdateTourReimbursementMasterForFinanceFinalSubmit(token, TourReimbursement: TourReimbursementMasterModel) {
        const authHeader = new HttpHeaders();
        authHeader.append('Content-Type', 'application/json');
        authHeader.append('Authorization', token);
        authHeader.append('Accept', 'application/json');
        return this.http.put(GlobalVariable.BASE_API_URL + '/api/TourReimbursement/UpdateTourReimbursementMasterForFinanceFinalSubmit', TourReimbursement, { headers: authHeader })
            .map(this.extractData)
            .catch(this.handleErrorObservable);
    }
    //End Employee Tour Reimbursement

    //Begin Employee Attendance Details Monthly
    GETEmployeeAttendanceDateWiseDetails(token, EmployeeId: number, Year: number, MonthId: number) {
        this.spinnerService.show();
        const authHeader = new HttpHeaders();
        authHeader.append('Content-Type', 'application/json');
        authHeader.append('Authorization', token);
        return new Promise((resolve, reject) => {
            setTimeout(() => {
                this.http.get(GlobalVariable.BASE_API_URL + '/api/EmployeeAttendance/GETEmployeeAttendanceDATEWise?EmployeeId=' + EmployeeId + '&Year=' + Year + '&MonthId=' + MonthId, { headers: authHeader })
                    .subscribe(data => {
                        this.spinnerService.hide();
                        resolve(data)
                    },
                        error => {
                            this.spinnerService.hide();
                            reject(error);
                        });
            }, 3000);
        });
    }
    GETAttendanceStatus(token) {
        this.spinnerService.show();
        const authHeader = new HttpHeaders();
        authHeader.append('Content-Type', 'application/json');
        authHeader.append('Authorization', token);
        return new Promise((resolve, reject) => {
            setTimeout(() => {
                this.http.get(GlobalVariable.BASE_API_URL + '/api/EmployeeAttendance/GETAttendanceStatus', { headers: authHeader })
                    .subscribe(data => {
                        this.spinnerService.hide();
                        resolve(data)
                    },
                        error => {
                            this.spinnerService.hide();
                            reject(error);
                        });
            }, 3000);
        });
    }
    UpdateEmployeeAttendanceDetails(token, Attendance: EmployeeAttendanceModel) {
        const authHeader = new HttpHeaders();
        authHeader.append('Content-Type', 'application/json');
        authHeader.append('Authorization', token);
        authHeader.append('Accept', 'application/json');
        return this.http.put(GlobalVariable.BASE_API_URL + '/api/EmployeeAttendance/UpdateEmployeeAttendanceDetails', Attendance, { headers: authHeader })
            .map(this.extractData)
            .catch(this.handleErrorObservable);
    }
    GETEmployeeAttendanceLocationWise(token, CompanyId: number, BranchId: number, DepartmentIds: string, Attendancedate: Date) {
        this.spinnerService.show();
        const authHeader = new HttpHeaders();
        authHeader.append('Content-Type', 'application/json');
        authHeader.append('Authorization', token);
        return new Promise((resolve, reject) => {
            setTimeout(() => {
                this.http.get(GlobalVariable.BASE_API_URL + '/api/EmployeeAttendance/GETEmployeeAttendanceLocationWise?CompanyId=' + CompanyId + '&BranchId=' + BranchId + '&DepartmentIds=' + DepartmentIds + '&Attendancedate=' + Attendancedate, { headers: authHeader })
                    .subscribe(data => {
                        this.spinnerService.hide();
                        resolve(data)
                    },
                        error => {
                            this.spinnerService.hide();
                            reject(error);
                        });
            }, 3000);
        });
    }
    UpdateEmployeeAttendanceDetailsDateWise(token, Attendance: EmployeeAttendanceModel) {
        const authHeader = new HttpHeaders();
        authHeader.append('Content-Type', 'application/json');
        authHeader.append('Authorization', token);
        authHeader.append('Accept', 'application/json');
        return this.http.put(GlobalVariable.BASE_API_URL + '/api/EmployeeAttendance/UpdateEmployeeAttendanceDetailsDateWise', Attendance, { headers: authHeader })
            .map(this.extractData)
            .catch(this.handleErrorObservable);
    }
    // End Employee Attendance Details Monthly
    getFile(FileName: string) {
        let url = GlobalVariable.BASE_API_URL + '/api/TourReimbursement/GetDocument?Filename=' + FileName;
        let headers = new HttpHeaders();
        window.open(url);
    }
    //Begin Employee Resign Process
    GetEmployeeDetailsForResignation(token) 
    {
        this.spinnerService.show();
        const authHeader = new HttpHeaders();
        authHeader.append('Content-Type', 'application/json');
        authHeader.append('Authorization', token);
        return new Promise((resolve, reject) => {
        this.http.get(GlobalVariable.BASE_API_URL + '/api/ResignApplication/GetEmployeeDetailsForResignation', { headers: authHeader })
            .subscribe(data => 
            {
                this.spinnerService.hide();
                resolve(data)
            },
            error => {
                this.spinnerService.hide();
                reject(error);
            });
        });
    }
    ResignApplicationApply(token, ResignApplication: ResignApplicationModel) {
        const authHeader = new HttpHeaders();
        authHeader.append('Content-Type', 'application/json');
        authHeader.append('Authorization', token);
        authHeader.append('Accept', 'application/json');
        return this.http.post(GlobalVariable.BASE_API_URL + '/api/ResignApplication/ResignApplicationApply', ResignApplication, { headers: authHeader })
            .map(this.extractData)
            .catch(this.handleErrorObservable);
    }

    private extractData(res: Response) {
        let body = res;
        return body || {};
    }
    private handleErrorObservable(error: Response | any) {
        console.error(error.message || error);
        return Observable.throw(error.message || error);
    }
}
