//import { ClusterSetupMasterSettings } from "cluster";

export class Model_CRM_OppotunityNotes {
    public NoteId: number;
    public OportunityId: number;
    public Note: string;
    public NoteDate: Date
    public NoteDateval: string;
    public NoteTime: string
    public ContactId: number;
    public Important: boolean;
    public ImportantLink: string;
    public FileName: string;
    public UploadedFilename: string;
    public File: string;
    public FileType: string;
    public FileExtension: string;
    public FileSize: string;
    public Salution: number;
    public FirstName: string;
    public MiddleName: string;
    public LastName: string;
    public Title: string;
    public WorkerPhone: string;
    public Email: string;
    public Roll: number;
    public CreatedDate: Date;
    public CreatedBy: number;
    public UpdateDate: Date;
    public UpdatedBy: number;
    public IsActive: boolean;
    //
    public UserName: string;
    public ConcatName: string;
    public IsImportant: boolean;
    public Importantcheck: string

    public Propernotedate: string;

}