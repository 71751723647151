<div class="modal-header" style="background: #2a9bd7;">
    <h4 class="modal-title" style="color: #fff !important;">{{ title }}</h4>
    <button type="button" class="close" aria-label="Close" (click)="dismiss()">
        <span aria-hidden="true" style="color: #fff !important;">&times;</span>
    </button>
</div>
<div class="modal-body">
    {{ message }}
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-danger" (click)="decline()">{{ btnCancelText }}</button>
    <button type="button" class="btn btn-primary" (click)="accept()">{{ btnOkText }}</button>
</div>