    <div class="row">
        <div class="col-lg-4">
            <div class="col-body-border-right">
                <div class="form-group row">
                    <label  class="col-sm-4 col-form-label clear-padding-right">code</label>
                    <div class="col-sm-7 clear-left-padding">
                        <input type="text" disabled="disabled" [(ngModel)]="EmployeeMasterService.SharedEmployees.EmployeeCode" id ="EmployeeCode" name="EmployeeCode" class="form-control form-control-custom form-control-custom-new">
                    </div>
                </div>
            </div>
        </div>
        <div class="col-lg-4">
            <div class="form-group row">
                <label  class="col-sm-4 col-form-label clear-padding-right">Name</label>
                <div class="col-sm-7 clear-left-padding">
                    <input type="text" disabled="disabled" [(ngModel)]="EmployeeMasterService.SharedEmployees.FullName" id ="FullName" name="FullName" class="form-control form-control-custom form-control-custom-new">
                </div>
            </div>
         </div>
    </div>

<fieldset class="body-form-control">
  <app-dialog [(visible)]="this.SuccessStatus">
      <span class="successmsg">{{this.SuccessMessage}}</span>
  </app-dialog>

  <legend class="legend--">Employee Experience Details</legend>
  <form #EmployeeExperienceDetailForm="ngForm" (ngSubmit)="SaveEmpolyeeExpriences()">
  <div lass="row" >
    <table id="tblComponent" class="table table-striped table-bordered text-left table-new row-border hover">
        <thead class="thead-blue text-center">
            <tr>
                <th style="width: 25%;">Name Of Employeer</th>
                <th style="width: 25%;">Designation</th>  
                <th style="width: 25%;">Form Date</th>
                <th style="width: 25%;">To Date</th>       
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let Exp of this.EmployeeMasterService.SharedEmployees.EmployeeExprienceModel, let i = index">
                <td >
                  <input type="text" [(ngModel)]="Exp.ExperinceCompany" name="ExperinceCompany_{{i}}" #ExperinceCompany="ngModel" class="form-control form-control-custom form-control-custom-new">
                </td>
                <td >
                    <input type="text" [(ngModel)]="Exp.ExperinceDesignation" name="ExperinceDesignation_{{i}}" #ExperinceDesignation="ngModel" class="form-control form-control-custom form-control-custom-new">
                </td>  
                <td >
                  <input class="form-control" style="float:none" placeholder="Select date" ngx-mydatepicker name="ExperinceFromDate_{{i}}"
                  [(ngModel)]="Exp.ExperinceFromDate" [options]="myOptions" #dpx="ngx-mydatepicker" (dateChanged)="onDateChangedFrom($event,i)"/>
                  <span class="mt-2">
                        <a class="btn btn-primary pl-10 pr-10 pt-1 pb-1" (click)="dpx.toggleCalendar()">
                            <i style="color:#fff" class="fas fa-calendar-alt fa-lg"></i>
                        </a>
                        <a class="btn btn-danger pl-10 pr-10 pt-1 pb-1" (click)="dpx.clearDate()">
                            <i style="color:#fff" class="far fa-calendar-times fa-lg"></i>
                        </a>
                    </span>
                </td>
                <td >
                  <input class="form-control" style="float:none" placeholder="Select date" ngx-mydatepicker name="ToDate_{{i}}"
                  [(ngModel)]="Exp.ExperinceToDate" [options]="myOptions" #dp="ngx-mydatepicker" (dateChanged)="onDateChangedTo($event,i)"/>
                  <span class="mt-2">
                        <a class="btn btn-primary pl-10 pr-10 pt-1 pb-1" (click)="dp.toggleCalendar()">
                            <i style="color:#fff" class="fas fa-calendar-alt fa-lg"></i>
                        </a>
    
                        <a class="btn btn-danger pl-10 pr-10 pt-1 pb-1" (click)="dp.clearDate()">
                            <i style="color:#fff" class="far fa-calendar-times fa-lg"></i>
                        </a>
                    </span>
                </td>
               
            </tr>
        </tbody>
    </table>
    <div class="row float-right">
        <div  class=" col-auto text-center" *ngIf="this.EmployeeMasterService.SharedEmployees.EmployeeId>0" >
            <button  type="submit" tabindex="22" class="btn btn-primary save-button" [disabled]="!(EmployeeExperienceDetailForm.valid && this.DisableSave==true)" >Save & Next</button>  
        </div>
        <div class=" col-auto text-center">
            <button  type="button"  tabindex="23" class="btn btn-danger cancle-button" (click)="RefreshPage()">Cancel</button>
        </div>
        <div class=" col-auto text-center">
            <button  type="button"  tabindex="23" class="btn btn-primary pl-10 pr-10 pt-1 pb-1" (click)="AddnweRow()">Add Row </button>
        </div>
    </div>
  </div>
  </form>

</fieldset>
