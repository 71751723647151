import { Component, OnInit } from '@angular/core';
import { ReplaySubject } from "rxjs/ReplaySubject";
import { Router } from '@angular/router';
import { ReportType } from '../../shared/HR_ADMIN/Report/ReportType'
import { UserService } from '../../shared/user.service';
import { EmployeeDetail } from '../../shared/HR_ADMIN/Employee/EmployeeDetail.Model';
import { MonthlyAttendanceModel } from '../../shared/HR_ADMIN/Salary/MonthlyAttendanceModel';
import { EmployeeSalaryComponentAttendence } from '../../shared/HR_ADMIN/Salary/EmployeeSalaryComponentAttendence';
import { OfferStandardSalary}  from '../../shared/HR_ADMIN/Salary/OfferStandardSalary'
import { OrganizationService } from '../../shared/Organization/organization.service';
import { SalaryMasterService }  from '../../shared/HR_ADMIN/Salary/SalaryMaster.service';
import { Company } from '../../shared/Organization/Company.Model';
import { Department } from '../../shared/Organization/Department.Model';
import { Branch } from '../../shared/Organization/Branch.Model';
import { FinancialYear } from  '../../shared/Organization/FinancialYear.Model';
//End of notification service
import { Menu } from '../../shared/menu.model';
import { ModalOptions,BsModalRef, BsModalService } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-generate-salary',
  templateUrl: './generate-salary.component.html',
  styleUrls: ['./generate-salary.component.css']
})
export class GenerateSalaryComponent implements OnInit {
  EmployeeMonthelyAttendenceModel: MonthlyAttendanceModel = new MonthlyAttendanceModel();
  EmployeeDetailModel: EmployeeDetail = new EmployeeDetail();
  userClaims: any;
  Employees: EmployeeDetail[] = [];
  employee: any;
  //Reports Start
  ReportTypeList: ReportType[] = [];
  ReportExecl: ReportType;
  ReportPdf: ReportType;
  //Reports End
  //
  empcode: string;
  EmployeeMonthelyAttendenceModelList: MonthlyAttendanceModel[] = [];
  Attendance: any;
  SubMenues: Menu[] = [];
  Mod: any;
  initialrow : number=0;
  Result: string;
  SuccessStatus: boolean = false;
  SuccessMessage: string = "";
  public tableWidget: any;
  EmployeeId: any;
  selectedIndex: number;
  // Begning Permission Portion    
  SelectedMenu: Menu;
  IsDataIsInViewState: boolean = false;
  CanViewState: boolean = true;
  DisableSave: boolean = false;
  ShowAdjustment : boolean = false;
  // End of the permission portion
  company: any;
  Companies: Company[] = [];
  Branch: any;
  Branches: Branch[] = [];
  Departments: Department[] = [];
  Department: any;
  DepartmentModel: Department = new Department();
  // begin for Validation
  validdate: boolean = false;
  // end of validation
  Att : any;
  FinancialYears: FinancialYear[] = [];
  FinYear:any;
  Months: Company[] = [];
  Month: any;
  NoRecordMsg : boolean;
  public modelopt:ModalOptions;
  SaveMsg : boolean= false;
  ShowPopup : boolean= false;

  EmployeeSalaryComponentAttendenceModel : EmployeeSalaryComponentAttendence = new EmployeeSalaryComponentAttendence();
  public modalRef: BsModalRef;
  public teststreing : string ="abc";
  public OfferedSalaryCOMP:  OfferStandardSalary[]=[];
  public currentMontheloattendence : MonthlyAttendanceModel= new MonthlyAttendanceModel();
  public NetTakeHomeTemp : number=0;
  public TotalDeduction: number=0

  constructor(private rout: Router,private userService: UserService, private OrganizationService: OrganizationService, private SalaryService : SalaryMasterService
    , private modalService: BsModalService
    // , @Inject(DOCUMENT) document
    )
  { 
    this.modelopt=new ModalOptions();
    //this.modelopt.class='heavy_modal';
    this.modalService.config= this.modelopt;
    this.modelopt.ignoreBackdropClick=true;
  }

  ngOnInit() 
  {
    // Begning Permission Portion 
    this.SelectedMenu=this.userService.SelectedMenu;
    if(this.SelectedMenu !=undefined)
    {
      localStorage.setItem('selectedmenu',JSON.stringify(this.SelectedMenu));
      this.rout.navigate([this.SelectedMenu.RouteName]);
    }
    else
    {
      if(JSON.parse(localStorage.getItem('selectedmenu'))!=undefined)
      {
        this.SelectedMenu = JSON.parse(localStorage.getItem('selectedmenu'));
        this.rout.navigate([this.SelectedMenu.RouteName]);
      }
    }
    if(this.SelectedMenu.CanAdd)
    {
      this.IsDataIsInViewState=false;
    }
    else
    {
      this.IsDataIsInViewState=true;
    }
    if(this.SelectedMenu.CanView)
    {
      this.CanViewState=false;
    }
    else
    {
      this.CanViewState=true;
    }
    // End of the permission portion
    this.DisableSave = true;
    if (localStorage.getItem('SuccessfullMessage')) 
    {
      this.SuccessStatus = true;
      this.SuccessMessage = localStorage.getItem('SuccessfullMessage')
      localStorage.removeItem('SuccessfullMessage');
    }
    this.EmployeeMonthelyAttendenceModel.EmployeeId = 0;
    this.EmployeeMonthelyAttendenceModel.FinYear=0;
    this.EmployeeMonthelyAttendenceModel.MonthId=0;
    this.EmployeeMonthelyAttendenceModel.CompanyId = 0;
    this.EmployeeMonthelyAttendenceModel.BranchId = 0;
    this.EmployeeMonthelyAttendenceModel.DepartmentId = 0;
    this.getMonths();
    this.GetFinancialYear();
    this.getCompanies();
    this.NoRecordMsg= false;
    this.SaveMsg=false;
    this.ShowPopup=false;
  }

  public readFile(fileToRead: File)
  {
    let base64Observable = new ReplaySubject(1);
    let fileReader = new FileReader();
    fileReader.onload = event => 
    {
      base64Observable.next(fileReader.result);
    };
    fileReader.readAsDataURL(fileToRead);
    return base64Observable;
  }

  getMonths() 
  {
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.Months=[];
    this.OrganizationService.GetMonthName( t).then(x => 
    {
      this.Month = x;
      Object.keys(this.Month).forEach( key => 
      {
        // tslint:disable-next-line:no-unused-expression
        this.Month[key];
        let Monthtemp = new Company();
        Monthtemp = this.Month[key];
        this.Months.push(Monthtemp);
      });     
    });      
  }

  GetFinancialYear() 
  {
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.FinancialYears=[];
    this.OrganizationService.GetFinancialYear( t).then(x => 
    {
      this.FinYear = x;
      Object.keys(this.FinYear).forEach( key => 
      {
        // tslint:disable-next-line:no-unused-expression
        this.FinYear[key];
        let FinYeartemp = new FinancialYear();
        FinYeartemp = this.FinYear[key];
        this.FinancialYears.push(FinYeartemp);
      });     
    });      
  }

  getCompanies() 
  {
    let t = localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.OrganizationService.GetCompany(t).then(x => 
    {
      this.company = x;
      Object.keys(this.company).forEach(key => 
      {
        // tslint:disable-next-line:no-unused-expression
        this.company[key];
        let Companytemp = new Company();
        Companytemp = this.company[key];
        this.Companies.push(Companytemp);
      });
    });
  }

  onChangeCompany(Companyid)
  {
    this.DisableSave=true;
    this.getBranches(Companyid);
    this.EmployeeMonthelyAttendenceModel.BranchId = 0;
    this.getDepartments(0);
    this.Departments = [];
    this.EmployeeMonthelyAttendenceModel.DepartmentId = 0;
    this.NoRecordMsg= false;
    this.EmployeeMonthelyAttendenceModelList=[];
  }

  onchangeBranch(Branchid)
  {
    this.DisableSave=true;
    this.getDepartments(Branchid);
    this.EmployeeMonthelyAttendenceModel.DepartmentId = 0;
    this.NoRecordMsg= false;
    this.EmployeeMonthelyAttendenceModelList=[];
  }

  getBranches(companyid)
  {
    let t = localStorage.getItem('userToken');
    t = 'Bearer' + t
    this.Branches=[];
    this.OrganizationService.GetBranchesByComp(t, companyid).then(x =>
    {
      this.Branch = x;
      Object.keys(this.Branch).forEach(key =>
      {
        // tslint:disable-next-line:no-unused-expression
        this.Branch[key];
        let Branchtemp = new Branch();
        Branchtemp = this.Branch[key];
        this.Branches.push(Branchtemp);
      });
    });
  }

  getDepartments(Branchid)
  {
    this.Departments = [];
    let t = localStorage.getItem('userToken');
    t = 'Bearer' + t
    this.OrganizationService.GetDepartmentByBranchid(t, Branchid).then(x => 
    {
      this.Departments = [];
      this.Department = x;
      Object.keys(this.Department).forEach(key => 
      {
        this.Department[key];
        let Departmenttemp = new Department();
        Departmenttemp = this.Department[key];
        this.Departments.push(Departmenttemp);
      });
    });
  }

  onchangeDepartments()
  {
    this.DisableSave=true;
    this.NoRecordMsg= false;
    this.EmployeeMonthelyAttendenceModelList=[];
  }

  onchangeYear()
  {
    this.DisableSave=true;
    this.NoRecordMsg= false;
    this.EmployeeMonthelyAttendenceModelList=[];
  }

  onchangeMonth()
  {
    this.DisableSave=true;
    this.NoRecordMsg= false;
    this.EmployeeMonthelyAttendenceModelList=[];
  }

  submitClick()
  {
    this.DisableSave=false;
    this.EmployeeMonthelyAttendenceModelList=[];
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.SalaryService.GetEmployeesAttendForSalary( t,this.EmployeeMonthelyAttendenceModel.FinYear,this.EmployeeMonthelyAttendenceModel.MonthId,this.EmployeeMonthelyAttendenceModel.CompanyId,this.EmployeeMonthelyAttendenceModel.BranchId,this.EmployeeMonthelyAttendenceModel.DepartmentId).then(x => 
    {
      this.NoRecordMsg= false;
      this.EmployeeMonthelyAttendenceModelList=[];
      this.Att = x;
      Object.keys(this.Att).forEach( key => 
      {
        //this.Att[key];
        let Attendancetemp = new MonthlyAttendanceModel();
        Attendancetemp = this.Att[key];    
        this.EmployeeMonthelyAttendenceModelList.push(Attendancetemp);
      });
      if(this.EmployeeMonthelyAttendenceModelList.length==0)
      {
        this.NoRecordMsg= true;
      }
    });
  }

  Getsalary(empid : number, template)
  {
    this.ShowAdjustment = false;
    this.OfferedSalaryCOMP=[];
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.currentMontheloattendence=this.EmployeeMonthelyAttendenceModelList.find(x=>x.EmployeeId==empid);
    this.EmployeeSalaryComponentAttendenceModel.salaryId=0 ;  
    this.SalaryService.GetEmployeesSalarycompDetails( t,empid,this.EmployeeMonthelyAttendenceModel.MonthId,this.EmployeeMonthelyAttendenceModel.FinYear).then(x => 
    {
      this.Att = x;
      this.EmployeeSalaryComponentAttendenceModel = this.Att; 

      let Perdaysalary = (this.EmployeeSalaryComponentAttendenceModel.GrossSalary)/( this.currentMontheloattendence.NoDaysinMonth);
      let Noofpresentday = this.currentMontheloattendence.NoDaysinMonth - this.currentMontheloattendence.FinalLOP;
      let NetAmount = Math.round(Perdaysalary * Noofpresentday);
      this.EmployeeSalaryComponentAttendenceModel.salaryId = 0;
      this.EmployeeSalaryComponentAttendenceModel.TDS = 0;
      this.EmployeeSalaryComponentAttendenceModel.Advance = 0;
      this.EmployeeSalaryComponentAttendenceModel.MediClame = 0;
      this.EmployeeSalaryComponentAttendenceModel.Donation = 0;
      this.EmployeeSalaryComponentAttendenceModel.OtherDeduction = 0;
      this.EmployeeSalaryComponentAttendenceModel.Arears = 0;
      this.EmployeeSalaryComponentAttendenceModel.Loan = 0;
      this.EmployeeSalaryComponentAttendenceModel.AdjustmentAddition = 0;
      this.EmployeeSalaryComponentAttendenceModel.AdjustmentSubtraction = 0;
      this.EmployeeSalaryComponentAttendenceModel.OtherIncome = 0;
      //10042020 Begin Change by Mukesh
      this.EmployeeSalaryComponentAttendenceModel.HoldAmount = 0;
      this.EmployeeSalaryComponentAttendenceModel.LWPAmount = 0;
      //10042020 End Change by Mukesh
      this.EmployeeSalaryComponentAttendenceModel.LOPAmount = Math.round((this.currentMontheloattendence.FinalLOP * Perdaysalary));

      this.EmployeeSalaryComponentAttendenceModel.NetTakeHome = Math.round(this.EmployeeSalaryComponentAttendenceModel.GrossSalary) - Math.round(this.EmployeeSalaryComponentAttendenceModel.PF + this.EmployeeSalaryComponentAttendenceModel.Ptax + Math.round(this.EmployeeSalaryComponentAttendenceModel.LOPAmount));
      
      this.NetTakeHomeTemp = this.EmployeeSalaryComponentAttendenceModel.NetTakeHome;

      this.EmployeeSalaryComponentAttendenceModel.TotalDeduction = Math.round(this.EmployeeSalaryComponentAttendenceModel.PF) + Math.round(this.EmployeeSalaryComponentAttendenceModel.Ptax) + Math.round(this.EmployeeSalaryComponentAttendenceModel.LOPAmount);
      this.EmployeeSalaryComponentAttendenceModel.TotalIncome = Math.round(this.EmployeeSalaryComponentAttendenceModel.GrossSalary);

      this.EmployeeSalaryComponentAttendenceModel.EmployeeCode= this.currentMontheloattendence.EmployeeCode;
      this.EmployeeSalaryComponentAttendenceModel.EmployeeId=this.currentMontheloattendence.EmployeeId;
      this.EmployeeSalaryComponentAttendenceModel.EmployeeName=this.currentMontheloattendence.EmployeeName;
      this.EmployeeSalaryComponentAttendenceModel.YearId=this.currentMontheloattendence.FinYear;
      this.EmployeeSalaryComponentAttendenceModel.MonthId=this.currentMontheloattendence.MonthId;
      this.EmployeeSalaryComponentAttendenceModel.ComanyId=this.currentMontheloattendence.CompanyId;
      this.EmployeeSalaryComponentAttendenceModel.BranchId=this.currentMontheloattendence.BranchId;
      this.EmployeeSalaryComponentAttendenceModel.DepartmentId=this.currentMontheloattendence.DepartmentId;
      this.EmployeeSalaryComponentAttendenceModel.RefIdEmpAttMast=this.currentMontheloattendence.RefIdmonthly;
      this.EmployeeSalaryComponentAttendenceModel.SalaryCompList.forEach(y=>
      {
        if(y.SalaryComponentId == 2)
        {
          this.ShowAdjustment = true;
        }
        this.OfferedSalaryCOMP.push(y);
      })
    }).then(x=>
    {
      if(this.ShowPopup==true)
      {
        this.modalRef.hide();
      }
      this.modalRef = this.modalService.show(template,this.modelopt);
      this.ShowPopup=true;
      this.SaveMsg=false;
      // this.modalRef = this.modalService.show(template);
    });
  }

  saveSalaryDeatils(template) :void
  {
    if(confirm("Are you sure to Lock "))
    {
      this.DisableSave=false;
      let t =  localStorage.getItem('userToken');
      t = 'Bearer' + t;
      this.EmployeeSalaryComponentAttendenceModel.salaryId = 1;
      this.SalaryService.AddEmployeeSalaryDetails(t ,this.EmployeeSalaryComponentAttendenceModel).subscribe(res=>
      {
        let SalId =Number(res);
        this.EmployeeMonthelyAttendenceModelList.find(x=>x.EmployeeId == this.EmployeeSalaryComponentAttendenceModel.EmployeeId ).SalaryId=SalId;  
        this.modalRef.hide();
        this.SaveMsg=true;
        this.ShowPopup=false;
        this.modalRef = this.modalService.show(template);
        this.DisableSave=true;
      });             
    }
  }

  onSalarychangehange()
  {
    this.TotalDeduction = (Math.round(this.EmployeeSalaryComponentAttendenceModel.Ptax) 
      + Math.round(this.EmployeeSalaryComponentAttendenceModel.PF) 
      + Math.round(this.EmployeeSalaryComponentAttendenceModel.LOPAmount) 
      + Math.round(this.EmployeeSalaryComponentAttendenceModel.Advance) 
      + Math.round(this.EmployeeSalaryComponentAttendenceModel.TDS) 
      + Math.round(this.EmployeeSalaryComponentAttendenceModel.MediClame) 
      + Math.round(this.EmployeeSalaryComponentAttendenceModel.Donation) 
      + Math.round(this.EmployeeSalaryComponentAttendenceModel.OtherDeduction) 
      + Math.round(this.EmployeeSalaryComponentAttendenceModel.Loan) 
      + Math.round(this.EmployeeSalaryComponentAttendenceModel.AdjustmentSubtraction)
      //10042020 Begin Change by Mukesh
      + Math.round(this.EmployeeSalaryComponentAttendenceModel.HoldAmount)
      + Math.round(this.EmployeeSalaryComponentAttendenceModel.LWPAmount)
      //10042020 End Change by Mukesh
      );
    this.EmployeeSalaryComponentAttendenceModel.TotalDeduction = this.TotalDeduction;
    this.EmployeeSalaryComponentAttendenceModel.TotalIncome = (Math.round(this.EmployeeSalaryComponentAttendenceModel.GrossSalary) + Math.round(this.EmployeeSalaryComponentAttendenceModel.Arears) + Math.round(this.EmployeeSalaryComponentAttendenceModel.AdjustmentAddition) + Math.round(this.EmployeeSalaryComponentAttendenceModel.OtherIncome));
    this.EmployeeSalaryComponentAttendenceModel.NetTakeHome = Math.round(this.EmployeeSalaryComponentAttendenceModel.TotalIncome) - Math.round(this.TotalDeduction);
  }
}