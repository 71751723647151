import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Company} from  '../shared/Organization/Company.Model';
import { OrganizationService} from '../shared/Organization/organization.service';
import { State } from '../shared/Organization/State.Model';
import { City } from '../shared/HR_ADMIN/Location/City.Model';
import { Zone } from '../shared/Organization/Zone.Model';
import { Country } from '../shared/Organization/Country.Model';
import { ReplaySubject } from "rxjs/ReplaySubject";
import { UserService } from '../shared/user.service';
// Begning Confirmation
import { ConfirmationService } from '@jaspero/ng2-confirmations';
import { ResolveEmit } from '../../app/resolve-emit';
import { ConfirmSettings } from '../../app/confirm-settings';
import { Menu } from '../shared/menu.model';
// end of Confirmation
import { ModalOptions,BsModalRef, BsModalService } from 'ngx-bootstrap/modal';

@Component(
{
  selector: 'app-manage-company',
  templateUrl: './manage-company.component.html',
  styleUrls: ['./manage-company.component.css']
})
export class ManageCompanyComponent implements OnInit 
{
  CompanyModel : Company= new Company();
  SubMenues: Menu[] = [];
  Mod: any; 
  userClaims: any;
  Companies: Company[] = [];
  Cities : City[]=[];
  Counries : Country[]=[];
  States : State[]=[];
  Zones : Zone[] = [];
  Comp: any;
  City : any;
  State : any;
  Zone : any;
  country : any;
  Result : string;
  SuccessStatus:boolean=false;
  SuccessMessage: string ="";
  public tableWidget: any;
  companyId : any;
  base64textString : string;
  tempcompanyadd : Company =new Company();
  imageUrl: string="/assets/img/cream-tulip-in-white-room_925x.jpg";
  filetoupload: File=null;
  // Begning Permission Portion    
  SelectedMenu:Menu;
  IsDataIsInViewState : boolean=false;
  CanViewState : boolean=false;  
  DisableSave : boolean=false;
  // End of the permission portion
  //check for duplicate company code
  CheckcompanyCodeExiststatus : boolean=null;  
  Checkststusedit : boolean =false;
  companyCodecheck : string ="";
  checkCompany : any;
  chechCompanyCodeval : string ="";
  //check for duplicate company code
  //check for duplicate Name
  CheckNameExiststatus : boolean=null;  
  CheckNamestatusedit : boolean =false;
  Namecheck : string ="";
  checkName : any;
  checkNameval : string ="";
  //check for duplicate Name
  CanSaveButtonView : boolean=false;
  //For Pop up
  TempCompany : Company;
  SaveMsg : boolean= false;
  UpdateMsg : boolean= false;
  DeleteMsg : boolean=false;
  ShowPopup : boolean= false;
  public modalRef: BsModalRef;
  public modelopt:ModalOptions;

  constructor(private organizationService: OrganizationService, private rout: Router, private _confirmation: ConfirmationService, private userService: UserService,private modalService: BsModalService)
  {
    this.modelopt=new ModalOptions();
    this.modelopt.class='heavy_modal';
    this.modalService.config= this.modelopt;
    this.modelopt.ignoreBackdropClick=true;
  }
    
  ngOnInit() 
  {
    this.SubMenues=[];
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    let menuid = localStorage.getItem("menuidstor");
    this.userService.GetSubMenuList( t, menuid).then(x => 
    {
      this.Mod = x;
      Object.keys(this.Mod).forEach( key => 
      {
        // tslint:disable-next-line:no-unused-expression
        this.Mod[key];
        let menutemp = new Menu();
        menutemp = this.Mod[key];
        this.SubMenues.push(menutemp);
      });
      this.userService.SelectedMenu= this.SubMenues[0];
      this.rout.navigate([this.userService.SelectedMenu.RouteName]);
      this.SelectedMenu=this.userService.SelectedMenu;
      if(this.SelectedMenu.CanAdd)
      {
        this.IsDataIsInViewState=false;
        this.CanSaveButtonView=true;
      }
      else
      {
        this.IsDataIsInViewState=true;
      }
      if(this.SelectedMenu.CanView)
      {
        this.CanViewState=false;
      }
      else
      {
        this.CanViewState=true;
      }
    });
     //End of the peremission portion
    this.SaveMsg=false;
    this.UpdateMsg=false;
    this.DeleteMsg=false;
    this.ShowPopup=false;
    this.DisableSave=true;
    this.getCountries() 
    //this.getCompanies();
    this.CompanyModel.validfile=true;
    this.CompanyModel.CountryId=0;
  }

  ngAfterViewInit(): void 
  {
    this.getCompanies();
    this.CompanyModel.validfile=true;
  }

  settings: ConfirmSettings | any = 
  {
    overlay: true,
    overlayClickToClose: true,
    showCloseButton: true,
    confirmText: 'Yes',
    declineText: 'No'
  };

  getCompanies()
  {
    this.Companies=[];
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.organizationService.GetCompany( t).then(x => 
    {
      this.Comp = x;
      Object.keys(this.Comp).forEach( key => 
      {
        // tslint:disable-next-line:no-unused-expression
        this.Comp[key];
        let Companytemp = new Company();
        Companytemp = this.Comp[key];
        this.Companies.push(Companytemp);
      });
    });
  }

  handleFileinput(files : FileList)
  {
    this.filetoupload=files.item(0);
    //this.CompanyModel.LogoType= this.filetoupload.type
    var file = files[0];
    let filesize = this.filetoupload.size; 
    let filetyotypearray = this.filetoupload.type.split("/");
    this.CompanyModel.LogoType= filetyotypearray[1];
    this.CompanyModel.Logodoctype= filetyotypearray[0];
    if(this.CompanyModel.Logodoctype=="image")
    {
      this.CompanyModel.validfile=true; 
    }
    else
    {
      this.CompanyModel.validfile=false; 
    }
    if(filesize > 500000)
    {
      this.CompanyModel.validfile=false; 
    }
    if(!((this.CompanyModel.LogoType=='jpeg') || (this.CompanyModel.LogoType=='png') || (this.CompanyModel.LogoType=='svg')|| (this.CompanyModel.LogoType=='gif') || (this.CompanyModel.LogoType=='wbmp')))
    {
      this.CompanyModel.validfile=false; 
    }
    var reader : FileReader= new FileReader();
    reader.onload=(event:any)=>
    {
      this.imageUrl=event.target.result;
    } 
    reader.onloadend = (e) => 
    {
      this.CompanyModel.Islogochange=true;
      this.CompanyModel.Immage = String(reader.result);
    }
    reader.readAsDataURL(file);   
  }

  public readFile(fileToRead: File)
  {
    let base64Observable = new ReplaySubject(1);
    let fileReader = new FileReader();
    fileReader.onload = event => 
    {
      base64Observable.next(fileReader.result);
    };
    fileReader.readAsDataURL(fileToRead);
    return base64Observable;
  }

  GetCompanByid(id,template) 
  {
    // Begning of the permission portion
    this.IsDataIsInViewState=false;
    // End of the permision portion
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.organizationService.GetCompanByid( t,id).then(x => 
    {
      if(this.ShowPopup==true)
      {
        this.modalRef.hide();
      }
      this.modalRef = this.modalService.show(template,this.modelopt);
      this.ShowPopup=true;
      this.SaveMsg=false;
      this.UpdateMsg=false;
      this.DeleteMsg=false;
      this.Comp = x;
      Object.keys(this.Comp).forEach( key => 
      {
        this.CanSaveButtonView=false;
        // tslint:disable-next-line:no-unused-expression
        this.Comp[key];
        let Companytemp = new Company();
        Companytemp = this.Comp[key];
        this.CompanyModel.CompId=Companytemp.CompId;
        this.CompanyModel.CompCode=Companytemp.CompCode;
        this.CompanyModel.CompName=Companytemp.CompName;
        this.CompanyModel.Address1=Companytemp.Address1;          
        this.CompanyModel.Address2=Companytemp.Address2;
        this.CompanyModel.CountryId=Companytemp.CountryId;
        this.CompanyModel.StateId=Companytemp.StateId;
        this.CompanyModel.CityId=Companytemp.CityId;
        this.CompanyModel.PostCode=Companytemp.PostCode;
        this.CompanyModel.Website=Companytemp.Website;
        this.CompanyModel.LogoPath=Companytemp.LogoPath;
        this.CompanyModel.LogoType=Companytemp.LogoType;
        this.CompanyModel.Immage=Companytemp.Immage;
        this.CompanyModel.PhoneNo1=Companytemp.PhoneNo1;
        this.CompanyModel.PhoneNo2=Companytemp.PhoneNo2;
        this.CompanyModel.Fax=Companytemp.Fax;
        this.CompanyModel.Email=Companytemp.Email;
        this.CompanyModel.PANNumber=Companytemp.PANNumber;
        this.CompanyModel.TANNumber=Companytemp.TANNumber;
        this.CompanyModel.RegistrationNumber=Companytemp.RegistrationNumber;
      });
      this.getStates(this.CompanyModel.CountryId);
      this.getCities(this.CompanyModel.StateId);
      // Begin for check company code duplication
      this.chechCompanyCodeval= this.CompanyModel.CompCode;
      this.Checkststusedit=true;
      this.CheckcompanyCodeExiststatus =true;
      this.companyCodecheck=""
      // End for check company code duplication
      // Begin for check Name duplication
      this.checkNameval= this.CompanyModel.CompName;
      this.CheckNamestatusedit=true;
      this.CheckNameExiststatus =true;
      this.Namecheck=""
      // End for check Name duplication
    });
  }

  viewCompanByid(id,template) 
  {
    //begin permission
    this.IsDataIsInViewState=true;
    //end permission
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.organizationService.GetCompanByid( t,id).then(x => 
    {
      if(this.ShowPopup==true)
      {
        this.modalRef.hide();
      }
      this.modalRef = this.modalService.show(template,this.modelopt);
      this.ShowPopup=true;
      this.SaveMsg=false;
      this.UpdateMsg=false;
      this.DeleteMsg=false;
      this.CanSaveButtonView=false;
      this.Comp = x;
      Object.keys(this.Comp).forEach( key => 
      {
        // tslint:disable-next-line:no-unused-expression
        this.Comp[key];
        let Companytemp = new Company();
        Companytemp = this.Comp[key];
        this.CompanyModel.CompId=Companytemp.CompId;
        this.CompanyModel.CompCode=Companytemp.CompCode;
        this.CompanyModel.CompName=Companytemp.CompName;
        this.CompanyModel.Address1=Companytemp.Address1;          
        this.CompanyModel.Address2=Companytemp.Address2;
        this.CompanyModel.CountryId=Companytemp.CountryId;
        this.CompanyModel.StateId=Companytemp.StateId;
        this.CompanyModel.CityId=Companytemp.CityId;
        this.CompanyModel.PostCode=Companytemp.PostCode;
        this.CompanyModel.Website=Companytemp.Website;
        this.CompanyModel.LogoPath=Companytemp.LogoPath;
        this.CompanyModel.LogoType=Companytemp.LogoType;
        this.CompanyModel.Immage=Companytemp.Immage;
        this.CompanyModel.PhoneNo1=Companytemp.PhoneNo1;
        this.CompanyModel.PhoneNo2=Companytemp.PhoneNo2;
        this.CompanyModel.Fax=Companytemp.Fax;
        this.CompanyModel.Email=Companytemp.Email;
        this.CompanyModel.PANNumber=Companytemp.PANNumber;
        this.CompanyModel.TANNumber=Companytemp.TANNumber;
        this.CompanyModel.RegistrationNumber=Companytemp.RegistrationNumber;
      });
      this.getStates(this.CompanyModel.CountryId);
      this.getCities(this.CompanyModel.StateId);
      // Begin for check company code duplication
      this.chechCompanyCodeval= this.CompanyModel.CompCode;
      this.Checkststusedit=true;
      this.CheckcompanyCodeExiststatus =true;
      this.companyCodecheck=""
      // End for check company code duplication
      // Begin for check Name duplication
      this.checkNameval= this.CompanyModel.CompName;
      this.CheckNamestatusedit=true;
      this.CheckNameExiststatus =true;
      this.Namecheck=""
      // End for check Name duplication
    });
  }

  onChange(countryid) 
  {
    if(countryid!=0)
    {
      this.getStates(countryid);
      this.CompanyModel.StateId=0;
    }
    else
    {
      this.getStates(countryid);
      this.CompanyModel.StateId=0;
      this.getCities(0);
    }
  }

  onstatechange(stateid)
  {
    if(stateid!=0)
    {
      this.getCities(stateid) ;
      this.CompanyModel.CityId=0;
    }
    else
    {
      this.getCities(stateid) ;
      this.CompanyModel.CityId=0;
    }      
  }
    
  getCities(stateid) 
  {
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t
    if(this.Cities.length>0)
    {
      this.Cities.splice(0,this.Cities.length )
    }
    this.organizationService.GetCity( t,stateid).then(x => 
    {
      this.City = x;
      Object.keys(this.City).forEach( key => 
      {
        // tslint:disable-next-line:no-unused-expression
        this.City[key];
        let Citytemp = new City();
        Citytemp = this.City[key];
        this.Cities.push(Citytemp);
      });     
    });      
  }
 
  getCountries() 
  {
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.organizationService.GetCountry( t).then(x => 
    {
      this.country = x;
      Object.keys(this.country).forEach( key => 
      {
        // tslint:disable-next-line:no-unused-expression
        this.country[key];
        let Countrytemp = new Country();
        Countrytemp = this.country[key];
        this.Counries.push(Countrytemp);
      });     
    });      
  }
  
  getStates( countryid) 
  {
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t
    if(this.States.length>0)
    {
      this.States.splice(0, this.States.length);
    }
    this.organizationService.GetState( t,countryid).then(x => 
    {
      this.State = x;
      Object.keys(this.State).forEach( key => 
      {
        // tslint:disable-next-line:no-unused-expression
        this.State[key];
        let Statetemp = new State();
        Statetemp = this.State[key];
        this.States.push(Statetemp);
      });     
    });      
  }

  getZones() 
  {
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.organizationService.GetZone( t).then(x => 
    {
      this.country = x;
      Object.keys(this.country).forEach( key => 
      {
        // tslint:disable-next-line:no-unused-expression
        this.country[key];
        let Countrytemp = new Country();
        Countrytemp = this.country[key];
        this.Counries.push(Countrytemp);
      });     
    });      
  }

  saveCompany(template) :void
  {
    this.DisableSave=false;
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.CompanyModel.IsActive=true;
    if(this.CompanyModel.CompId > 0)
    {
      this.organizationService.UpdateCompany(t ,this.CompanyModel).subscribe(res=>
      {
        this.companyId=res;
        this.CompanyModel.CompId=0;  
        if(this.Cities.length>0)
        {
          this.Cities.splice(0,this.Cities.length )
        }                 
        if(this.companyId>0)
        {
          this.ClearData();
          this.modalRef.hide();
          this.SaveMsg=false;
          this.UpdateMsg=true;
          this.DeleteMsg=false;
          this.ShowPopup=false;
          this.modalRef = this.modalService.show(template);
          this.Companies=[];
          this.getCompanies();
          this.DisableSave=true;
        }
      });                  
    }
    else
    {
      this.organizationService.AddCompany(t ,this.CompanyModel).subscribe(res=>
      {
        this.companyId=res;
        this.CompanyModel.CompId=0;              
        if(this.Cities.length>0)
        {
          this.Cities.splice(0,this.Cities.length )
        }                 
        if(this.companyId>0)
        {
          this.ClearData();
          this.modalRef.hide();
          this.SaveMsg=true;
          this.UpdateMsg=false;
          this.DeleteMsg=false;
          this.ShowPopup=false;
          this.modalRef = this.modalService.show(template);
          this.Companies=[];
          this.getCompanies();
          this.DisableSave=true;
        }
      }); 
    }
  }

  DeleteCompanByid(companyid : number,template) :void
  {
    if(this.SelectedMenu.CanAdd)
    {
      this.IsDataIsInViewState=false;
    }
    else
    {
      this.IsDataIsInViewState=true;
    }
    this._confirmation.create('Confirm to Deleate', 'Press Yes to Delete', this.settings)
      .subscribe((ans: ResolveEmit) => 
      {
        if (ans.resolved == true) 
        {
          let t =  localStorage.getItem('userToken');
          t = 'Bearer' + t;
          this.CompanyModel.IsActive=true;
          if( companyid > 0)
          {
            this.organizationService.DeleteCompany(t ,companyid).subscribe(res=>
            {
              this.companyId=res;
              this.CompanyModel.CompId=0;                                  
              if(this.companyId >0)
              {
                this.ClearData();
                this.modalRef.hide();
                this.SaveMsg=false;
                this.UpdateMsg=false;
                this.DeleteMsg=true;
                this.ShowPopup=false;
                this.modalRef = this.modalService.show(template);
                this.Companies=[];
                this.getCompanies();
                this.DisableSave=true;
              }
            });                  
          }
        }
      })
  }
  
  // begning of check duplicate companycode
  GetCompanyByCompanycode()
  {
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    if(!this.Checkststusedit)
    {
      this.organizationService.GetCompanyByCompanycode( t, this.CompanyModel.CompCode).then(x => 
      {
        this.checkCompany = x;
        if( this.checkCompany.length >0)
        {
          this.CheckcompanyCodeExiststatus =false;
          this.companyCodecheck="Exist"
        }
        else
        {
          this.CheckcompanyCodeExiststatus =true;
          this.companyCodecheck="";
        }
      });
    }
    else
    {
      if(this.CompanyModel.CompCode !=this.chechCompanyCodeval )
      {
        this.organizationService.GetCompanyByCompanycode( t, this.CompanyModel.CompCode).then(x => 
        {
          this.checkCompany = x;
          if( this.checkCompany.length >0)
          {
            this.CheckcompanyCodeExiststatus =false;
            this.companyCodecheck="Exist"
          }
          else
          {
            this.CheckcompanyCodeExiststatus =true;
            this.companyCodecheck="";
          }
        });
      }
      else
      {
        this.CheckcompanyCodeExiststatus =true;
        this.companyCodecheck="";
      }
    }
  }
  // begning of check duplicate Name
  CheckName()
  {
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    if(!this.CheckNamestatusedit)
    {
      this.organizationService.CheckCompanyName( t, this.CompanyModel.CompName).then(x => 
      {
        this.checkName = x;
        if( this.checkName.length >0)
        {
          this.CheckNameExiststatus =false;
          this.Namecheck="Exist"
        }
        else
        {
          this.CheckNameExiststatus =true;
          this.Namecheck="";
        }
    });
    }
    else
    {
      if(this.CompanyModel.CompName !=this.checkNameval )
      {
        this.organizationService.CheckCompanyName( t, this.CompanyModel.CompName).then(x => 
        {
          this.checkName = x;
          if( this.checkName.length >0)
          {
            this.CheckNameExiststatus =false;
            this.Namecheck="Exist"
          }
          else
          {
            this.CheckNameExiststatus =true;
            this.Namecheck="";
          }
        });
      }
      else
      {
        this.CheckNameExiststatus =true;
        this.Namecheck="";
      }
    }
  }
  // end of duplicate Name
  // end of duplicate company code
  RefreshPage() 
  {
    this.rout.navigateByUrl('/Organization/ManageBranch', {skipLocationChange: true}).then(()=>this.rout.navigate(["/Organization/ManageCompany"]))
  }

  ADDClick(template)
  {
    if(this.ShowPopup==true)
    {
      this.modalRef.hide();
    }
    this.ClearData();
    this.IsDataIsInViewState=false;
    this.modalRef = this.modalService.show(template,this.modelopt);
    this.ShowPopup=true;
    this.SaveMsg=false;
    this.UpdateMsg=false;
    this.DeleteMsg=false;
    this.DisableSave=true;
    this.getCountries() 
    this.CompanyModel.validfile=true;
    this.CompanyModel.CountryId=0;
    this.CanSaveButtonView=false;
  }

  ClearData()
  {
    this.CompanyModel.CompId=null;
    this.CompanyModel.CompCode=null;
    this.CompanyModel.CompName=null;
    this.CompanyModel.Address1=null;          
    this.CompanyModel.Address2=null;
    this.CompanyModel.CountryId=null;
    this.CompanyModel.StateId=null;
    this.CompanyModel.CityId=null;
    this.CompanyModel.PostCode=null;
    this.CompanyModel.Website=null;
    this.CompanyModel.LogoPath=null;
    this.CompanyModel.LogoType=null;
    this.CompanyModel.Immage=null;
    this.CompanyModel.PhoneNo1=null;
    this.CompanyModel.PhoneNo2=null;
    this.CompanyModel.Fax=null;
    this.CompanyModel.Email=null;
    this.CompanyModel.PANNumber=null;
    this.CompanyModel.TANNumber=null;
    this.CompanyModel.RegistrationNumber=null;
  }
}