export class EmployeeConfirmationModel
{
    public RefId : number;
    public EmployeeId : number;
    public EmployeeName : string;
    public EmployeeEmailId : string;
    public Designation : string;
    public Department : string;
    public Remarks : string;

    public ConfirmationDate : Date;
    public Branch : string;
    public IsApproved : boolean;
    public IsRejected : boolean;
    public ConfirmedDate : Date;
    public ConfirmedDatePass : string;
    public UpdatedBy : number;
    public UpdatedDate : Date;
    public RHId : number;
    public RHCode : string;
    public RHName : string;
    public RHEmailId : string;
    public RHRemarks : string;
    public RHUpdatedBy : number;
    public RHUpdatedDate : Date;
    public FHId : number;
    public FHCode : string;
    public FHName : string;
    public FHEmailId : string;
    public FHRemarks : string;
    public FHUpdatedBy : number;
    public FHUpdatedDate : Date;
    public JoingingDate : Date;
    public CompanyId : number;

    public SentMailid : string;
    public SentMailPassword : string;
    public HRISURL : string;
    
    ReturnFormatdate( datetochange : string ) : any
    {
        let toArray =datetochange.split("/");
        var year = Number(toArray[0]);
        var day = Number(toArray[1]);
        let date1 = { date: { year: toArray[2], month:year, day: day } };
        return date1;
    }


}
