import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs/Subject';
import { Menu } from '../../../shared/menu.model';
import { UserService } from '../../../shared/user.service';
//Begin For Notification service
import { NotificationsService } from '../../../shared/notifications.service';
//End of notification service
import { CrmBranchService } from '../../../shared/CRM/MICECRM/CrmBranchService';
import { Model_CrmCountry } from '../../../shared/CRM/MICECRM/Model_CrmCountry';
import { Model_CRM_Branch } from '../../../shared/CRM/MICECRM/Model_CRM_Branch';
import { Model_CRM_Zone } from '../../../shared/CRM/MICECRM/Model_CRM_Zone';
import { Model_CRM_State } from '../../../shared/CRM/MICECRM/Model_CRM_State';
import { Model_CRM_City } from '../../../shared/CRM/MICECRM/Model_CRM_City';
import { DataTableDirective } from 'angular-datatables';
import { ConfirmSettings } from '@jaspero/ng2-confirmations/src/interfaces/confirm-settings';
import { ConfirmationService } from '@jaspero/ng2-confirmations';
import { ResolveEmit } from '@jaspero/ng2-confirmations/src/interfaces/resolve-emit';
import { CrmCityService } from '../../../shared/CRM/MICECRM/CrmCityService';

@Component({
  selector: 'app-crm-branch',
  templateUrl: './crm-branch.component.html',
  styleUrls: ['./crm-branch.component.css']
})
export class CrmBranchComponent implements OnInit {
  //  View  Field Decleration Starts
  check: string;
  CheckExiststatus: boolean;
  Checkeditstatus: boolean;
  checkdataval: any;
  //  View  Field Decleration Starts
  //  View  Field Decleration Starts
  @ViewChild(DataTableDirective, {static: false})
  setDtOptions: DataTables.Settings = {};
  cardDtOptions: DataTables.Settings = {};
  dtElement: DataTableDirective;
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();
  //searchable Dropdown
  ZoneArray: any[] = [];
  CountryArray: any[] = [];
  ZoneSettings = {}
  CountrySttings = {}
  StateArray: any[] = [];
  StateSettings = {};
  CityArray: any[] = [];
  CitySettings = {};
  SelectedCountry = [];
  SelectedZone = [];
  SelectedState = [];
  SelectedCity = [];
  // For Populate Country Dropdown
  CRM_City_Model: Model_CRM_City;
  CountryName: any;
  countrylist: Model_CrmCountry[] = [];
  Crm_Country: Model_CrmCountry;
 // For Populate Zone Dropdown
  ZoneName: any;
  zonelist: Model_CRM_Zone[] = [];
  Crm_Zone: Model_CRM_Zone;
  IsSavevisible: boolean = true;

  // Begin success message popup
  SuccessStatus: boolean;
  SuccessMessage: string;
  // end success message popup

  // For Populate State Drop Down 
  StateName: any;
  statelist: Model_CRM_State[] = [];
  Crm_State: Model_CRM_State;

  // For Populate State Drop Down 
  CityName: any;
  citylist: Model_CRM_City[] = [];
  Crm_City: Model_CRM_City;


  // for City Drop Down
  // Add-View-Delete  City Field 

  CityId: number;
  DeleteCityNameByID: any;





  // Add-View-Delete  City Field 
  BranchId: number;
  DeleteBranchNameByID: any;


  // For Populate Country Dropdown

  CRM_Branch_Model: Model_CRM_Branch;



  // For Populate Zone Dropdown

  // For Populate State Drop Down 

  // For Populate City Drop Down   




  // Grid Fetch 

  branchlist: Model_CRM_Branch[] = [];
  CRMBranch: any;



  // Begning Permission Portion    
  SelectedMenu: Menu;
  IsDataIsInViewState: boolean = false;
  CanViewState: boolean = true;
  selectedIndex: number;
  // End of the permission portion

  constructor( private rout: Router, public userService: UserService,
    private notificationsService: NotificationsService, private CRM_BranchService: CrmBranchService, private _confirmation: ConfirmationService,
    private CRM_CityService: CrmCityService) {

    this.CRM_Branch_Model = new Model_CRM_Branch();

    notificationsService.emitChange.subscribe(slectedtabindex => {
      if (slectedtabindex != null) {
        this.selectedIndex = slectedtabindex;
      }
    });


  }






  ngOnInit() {

    this.rout.navigate([this.userService.selectedCrmMenu.RouteName]);


    this.CRM_Branch_Model.CountryId = 0;
    this.CRM_Branch_Model.ZoneId = 0;
    this.CRM_Branch_Model.StateId = 0;
    this.CRM_Branch_Model.CityId = 0;

    this.ZoneSettings = {
      singleSelection: true,
      text: 'Select Zone',
      enableSearchFilter: true,
      showCheckbox: false
    }

    this.CountrySttings = {
      singleSelection: true,
      text: 'Select Country',
      enableSearchFilter: true,
      showCheckbox: false
    }


    this.StateSettings = {
      singleSelection: true,
      text: 'Select State',
      enableSearchFilter: true,
      showCheckbox: false
    }

    this.CitySettings = {
      singleSelection: true,
      text: 'Select City',
      enableSearchFilter: true,
      showCheckbox: false
    }


    this.GetCountry();
    this.SelectedCountry = [{ "id": 0, "itemName": 'Select Country' }];
    // this.GetZone();
    // this.GetState();
    // this.GetCity();
    this.GetCRMBranch();

  }

  ngAfterViewInit(): void {

    this.dtOptions =
    {
      pagingType: 'full_numbers',
      pageLength: 4
    };

    if (localStorage.getItem('SuccessfullMessage')) {
      this.SuccessStatus = true;
      this.SuccessMessage = localStorage.getItem('SuccessfullMessage')
      localStorage.removeItem('SuccessfullMessage');
    }
    else {
      this.SuccessStatus = false;
    }
  }

  settings: ConfirmSettings | any =
    {
      overlay: true,
      overlayClickToClose: true,
      showCloseButton: true,
      confirmText: 'Yes',
      declineText: 'No'
    };

  //Populate  Country Drop  Down  

  GetCountry() {
    let t = localStorage.getItem('userToken');
    t = 'Bearer' + t
    this.countrylist = [];
    this.CRM_CityService.GetCountryType(t).then(x => {
      this.CountryName = x;
      Object.keys(this.CountryName).forEach(key => {
        let CountryNametemp = new Model_CrmCountry();
        CountryNametemp = this.CountryName[key];
        // alert('1');
        this.countrylist.push(CountryNametemp);
        this.CountryArray.push({ "id": CountryNametemp.CountryId, "itemName": CountryNametemp.CountryName });
      });

    }).then(x => {
      if (this.CRM_Branch_Model.CountryId > 0) {
        this.SelectedCountry = [];
        let country = this.countrylist.find(x => x.CountryId == this.CRM_Branch_Model.CountryId);

        this.SelectedCountry.push({ "id": country.CountryId, "itemName": country.CountryName });
        if (this.CRM_Branch_Model.ZoneId > 0) {
          this.GetZone(this.CRM_Branch_Model.CountryId)
        }

      }

    });
  }

  //Populate  Country Drop  Down  ENDS



  //Populate  Country Drop  Down  ENDS

  //Populate  ZOne  Drop  Down  

  //Populate  ZOne  Drop  Down  

  GetZone(Countryid: number) {
    let t = localStorage.getItem('userToken');
    t = 'Bearer' + t
    this.zonelist = [];
    this.CRM_CityService.GetZoneType(t, Countryid).then(x => {
      this.ZoneName = x;
      Object.keys(this.ZoneName).forEach(key => {
        let ZoneNametemp = new Model_CRM_Zone();
        ZoneNametemp = this.ZoneName[key];
        this.zonelist.push(ZoneNametemp);
        this.ZoneArray.push({ "id": ZoneNametemp.ZoneId, "itemName": ZoneNametemp.ZoneName });
      });

    }).then(t => {


      if (this.CRM_Branch_Model.ZoneId > 0) {
        this.SelectedZone = [];
        let Zone = this.zonelist.find(x => x.ZoneId == this.CRM_Branch_Model.ZoneId);

        this.SelectedZone.push({ "id": Zone.ZoneId, "itemName": Zone.ZoneName });
        if (this.CRM_Branch_Model.ZoneId > 0) {
          this.GetState(this.CRM_Branch_Model.ZoneId)
        }

      }

    });
  }

  //Populate  Zone  Down  ENDS

  //Populate  Zone  Down  ENDS

  //Populate  State  Drop  Down  
  GetState(Zoneid: number) {
    let t = localStorage.getItem('userToken');
    t = 'Bearer' + t
    this.statelist = [];
    this.CRM_CityService.GetStateType(t, Zoneid).then(x => {
      this.StateName = x;
      Object.keys(this.StateName).forEach(key => {
        let StateNametemp = new Model_CRM_State();
        StateNametemp = this.StateName[key];
        this.statelist.push(StateNametemp);
        this.StateArray.push({ "id": StateNametemp.StateId, "itemName": StateNametemp.StateName });
      });

    }).then(t => {

      if (this.CRM_Branch_Model.StateId > 0) {
        this.SelectedState = [];
        let State = this.statelist.find(x => x.StateId == this.CRM_Branch_Model.StateId);
        this.SelectedState.push({ "id": State.StateId, "itemName": State.StateName });
        this.GetCity(this.CRM_Branch_Model.StateId);

      }

    });
  }
  //Populate State Drop  Down  ENDS


  //Populate  City  Drop  Down  

  GetCity(StateId: number) {
    let t = localStorage.getItem('userToken');
    t = 'Bearer' + t
    this.citylist = [];
    this.CRM_CityService.GetCity(t, StateId).then(x => {
      this.CityName = x;
      Object.keys(this.CityName).forEach(key => {
        let CityNametemp = new Model_CRM_City();
        CityNametemp = this.CityName[key];
        this.citylist.push(CityNametemp);
        this.CityArray.push({ "id": CityNametemp.CityId, "itemName": CityNametemp.CityName });
      });

    }).then(t => {

      if (this.CRM_Branch_Model.CityId > 0) {
        this.SelectedCity = [];
        let City = this.citylist.find(x => x.CityId == this.CRM_Branch_Model.CityId);
        this.SelectedCity.push({ "id": City.CityId, "itemName": City.CityName });

      }

    });
  }

  //Populate City  Drop  Down  ENDS


  // Save Region  Starts

  saveCRMBranch(): void {
    let t = localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.CRM_Branch_Model.IsActive = true;

    if (this.CRM_Branch_Model.BranchId > 0) {
      this.CRM_BranchService.UpdateCrmBranch(t, this.CRM_Branch_Model).subscribe(res => {
        this.BranchId = Number(res);
        this.CRM_Branch_Model.BranchId = 0;
        if (this.BranchId > 0) {
          localStorage.setItem('SuccessfullMessage', "Successfully Updated");
          this.rout.navigateByUrl('ManageMICEAccess/ManageMICEUser', { skipLocationChange: true }).then(() => this.rout.navigate(["ManageMICEAccess/CrmBranch"]));
        }
      });
    }
    else {
      this.CRM_BranchService.AddCrmBranch(t, this.CRM_Branch_Model).subscribe(res => {
        this.BranchId = Number(res);
        this.CRM_Branch_Model.BranchId = 0;


        if (this.BranchId > 0) {
          localStorage.setItem('SuccessfullMessage', "Successfully Added");
          this.rout.navigateByUrl('ManageMICEAccess/ManageMICEUser', { skipLocationChange: true }).then(() => this.rout.navigate(["ManageMICEAccess/CrmBranch"]));

        }
      });
    }
  }


  RefreshPage(): void {


    this.CRM_Branch_Model.BranchName = "";

    this.CRM_Branch_Model.CountryId = 0;
    this.CRM_Branch_Model.ZoneId = 0;
    this.CRM_Branch_Model.StateId = 0;
    this.CRM_Branch_Model.CityId = 0;
    this.SelectedCountry = [];
    this.SelectedCity = [];
    this.CityArray = [];

    this.ZoneArray = [];
    this.SelectedZone = [];

    this.StateArray = [];
    this.SelectedState = [];



  }




  //  Grid  Data  Fetch 

  GetCRMBranch() {

    let t = localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.branchlist = [];
    this.CRM_BranchService.Get_CRMBranch(t).then(x => {
      this.CRMBranch = x;
      Object.keys(this.CRMBranch).forEach(key => {
        this.CRMBranch[key];
        let CRMBranchtemp = new Model_CRM_Branch();
        CRMBranchtemp = this.CRMBranch[key];

        this.branchlist.push(CRMBranchtemp);
        //alert();
      });
      this.dtTrigger.next();
    });
  }

  //  Grid  Data  Fetch Ends


  //  Update Section  Strats

  GetCRMBranchById(Id) {

    this.IsDataIsInViewState = false;
    // End of the permision portion
    let t = localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.CRM_BranchService.GetCRM_Branch_By_id(t, Id).then(x => {
      this.CRMBranch = x;
      Object.keys(this.CRMBranch).forEach(key => {

        let CRMBranchtemp = new Model_CRM_Branch();
        CRMBranchtemp = this.CRMBranch[key];



        this.CRM_Branch_Model.BranchId = CRMBranchtemp.BranchId;
        this.CRM_Branch_Model.BranchName = CRMBranchtemp.BranchName;
        this.CRM_Branch_Model.CityId = CRMBranchtemp.CityId;
        this.CRM_Branch_Model.CityName = CRMBranchtemp.CityName;
        this.CRM_Branch_Model.StateId = CRMBranchtemp.StateId;
        this.CRM_Branch_Model.StateName = CRMBranchtemp.StateName;
        this.CRM_Branch_Model.ZoneId = CRMBranchtemp.ZoneId;
        this.CRM_Branch_Model.ZoneName = CRMBranchtemp.ZoneName;
        this.CRM_Branch_Model.CountryId = CRMBranchtemp.CountryId;
        this.CRM_Branch_Model.CountryName = CRMBranchtemp.CountryName;
        this.CRM_Branch_Model.IsActive = CRMBranchtemp.IsActive;

      });

    }).then(x => {
      this.GetCountry();

    });
  }

  //  Delete  Part  Starts  

  DeleteCrmBranch(ID: number): void {
    // alert(ID);
    this._confirmation.create('Confirm to Delete', 'Press Yes to Delete', this.settings)
      .subscribe((ans: ResolveEmit) => {
        if (ans.resolved == true) {

          let t = localStorage.getItem('userToken');
          t = 'Bearer' + t;

          if (ID > 0) {
            this.CRM_BranchService.DeleteBranch(t, ID).subscribe(res => {
              this.DeleteBranchNameByID = res;

              if (this.DeleteBranchNameByID > 0) {
                localStorage.setItem('SuccessfullMessage', "Successfully Deleted");
                this.rout.navigateByUrl('ManageMICEAccess/ManageMICEUser', { skipLocationChange: true }).then(() => this.rout.navigate(["ManageMICEAccess/CrmBranch"]));
              }
            });
          }

        }
      })
  }

  //  Delete  Part  Ends 


  //  View  Section Starts  


  ViewCRMBranchById(Id) {

    this.IsDataIsInViewState = true;
    let t = localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.CRM_BranchService.GetCRM_Branch_By_id(t, Id).then(x => {
      this.CRMBranch = x;
      Object.keys(this.CRMBranch).forEach(key => {

        let CRMBranchtemp = new Model_CRM_Branch();
        CRMBranchtemp = this.CRMBranch[key];

        this.CRM_Branch_Model.BranchId = CRMBranchtemp.BranchId;
        this.CRM_Branch_Model.BranchName = CRMBranchtemp.BranchName;
        this.CRM_Branch_Model.CityId = CRMBranchtemp.CityId;
        this.CRM_Branch_Model.CityName = CRMBranchtemp.CityName;
        this.CRM_Branch_Model.StateId = CRMBranchtemp.StateId;
        this.CRM_Branch_Model.StateName = CRMBranchtemp.StateName;
        this.CRM_Branch_Model.ZoneId = CRMBranchtemp.ZoneId;
        this.CRM_Branch_Model.ZoneName = CRMBranchtemp.ZoneName;
        this.CRM_Branch_Model.CountryId = CRMBranchtemp.CountryId;
        this.CRM_Branch_Model.CountryName = CRMBranchtemp.CountryName;
        this.CRM_Branch_Model.IsActive = CRMBranchtemp.IsActive;


      });


      this.checkdataval = this.CRM_Branch_Model.BranchId;
      this.Checkeditstatus = false;
      this.CheckExiststatus = false;
      this.check = ""
    });
  }

  // View Section Ends


  // Get Zone By Country starts
  onItemSelectCountry(item: any) {

    let CountryId = item['id'];
    this.CRM_Branch_Model.CountryId = CountryId;
    //  this.CRM_City_Model.ZoneId=ZoneId;


    this.ZoneArray = [];
    this.StateArray = [];
    if (this.CRM_Branch_Model.CountryId > 0) {
      this.GetZone(this.CRM_Branch_Model.CountryId);
    }
  }

  OnItemDeSelectCountry(item: any) { }
  onSelectAllCountry(items: any) { }
  onDeSelectAllCountry(items: any) { }


  // Get State By Zone starts
  onItemSelectZone(item: any) {

    let ZoneId = item['id'];
    this.CRM_Branch_Model.ZoneId = ZoneId;
    //  this.CRM_City_Model.ZoneId=ZoneId;


    this.CityArray = [];
    this.StateArray = [];
    this.SelectedCity = [];
    this.SelectedState = [];
    if (this.CRM_Branch_Model.CountryId > 0) {
      this.GetState(this.CRM_Branch_Model.ZoneId);
    }
  }

  OnItemDeSelectZone(item: any) { }
  onSelectAllZone(items: any) { }
  onDeSelectAllZone(items: any) { }



  // Get State By Zone starts
  onItemSelectState(item: any) {

    let stateId = item['id'];
    this.CRM_Branch_Model.StateId = stateId;
    //  this.CRM_City_Model.ZoneId=ZoneId;


    this.CityArray = [];
    this.SelectedCity = [];

    if (this.CRM_Branch_Model.StateId > 0) {
      this.GetCity(this.CRM_Branch_Model.StateId);
    }
  }

  OnItemDeSelectState(item: any) { }
  onSelectAllState(items: any) { }
  onDeSelectAllState(items: any) { }


  onItemSelectCity(item: any) {


    let cityId = item['id'];
    this.CRM_Branch_Model.CityId = cityId;
    //  this.CRM_City_Model.ZoneId=ZoneId;

  }

  OnItemDeSelectCity(item: any) { }
  onSelectAllCity(items: any) { }
  onDeSelectAllCity(items: any) { }





}
