
export class Model_CRM_ScheduleCall_Documents {
    public SheduleCall_Id: number;
    public File: string;
    public DocumentActualName: string;
    public DocumentType: string;
    public DocumentExtension: string;
    public DocumentName: string;
    public DocumentSize: string;

}