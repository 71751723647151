import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AdminMasterService }  from '../../shared/HR_ADMIN/Admin/AdminMasterService.service';
import { TDSSlabModel }  from '../../shared/HR_ADMIN/Admin/TDSSlabModel.Model';
import { UserService } from '../../shared/user.service';
import { Menu } from '../../shared/menu.model';
import { ModalOptions,BsModalRef, BsModalService } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-manage-tdsslab',
  templateUrl: './manage-tdsslab.component.html',
  styleUrls: ['./manage-tdsslab.component.css']
})
export class ManageTdsslabComponent implements OnInit 
{
  TDSSlabModel : TDSSlabModel= new TDSSlabModel();
  userClaims: any;
  TDSSlabModels: TDSSlabModel[] = [];
  TDSSlabDetails: TDSSlabModel[] = [];
  TDSSlab:any;
  Result : string;
  SuccessStatus:boolean=false;
  SuccessMessage: string ="";
  public tableWidget: any;
  RefId : any;
  // Begning Permission Portion    
  SelectedMenu:Menu;
  IsDataIsInViewState : boolean=false;
  CanViewState : boolean=false;
  DisableSave :boolean=false;
  validdate : boolean= false;
  // End of the permission portion
  //For Pop up
  UpdateMsg : boolean= false;
  ShowPopup : boolean= false;
  public modalRef: BsModalRef;
  public modelopt:ModalOptions;

  constructor(private AdminMasterService: AdminMasterService, private rout: Router, private userService: UserService,private modalService: BsModalService)
  {
    this.modelopt=new ModalOptions();
    this.modelopt.class='heavy_modal';
    this.modalService.config= this.modelopt;
    this.modelopt.ignoreBackdropClick=true;
  }

  ngOnInit() 
  {
    //Begin Permission Portion
    this.SelectedMenu=this.userService.SelectedMenu;
    if(this.SelectedMenu !=undefined)
    {
      localStorage.setItem('selectedmenu',JSON.stringify(this.SelectedMenu));
      this.rout.navigate([this.SelectedMenu.RouteName]);
    }
    else
    {
      if(JSON.parse(localStorage.getItem('selectedmenu'))!=undefined)
      {
        this.SelectedMenu = JSON.parse(localStorage.getItem('selectedmenu'));
        this.rout.navigate([this.SelectedMenu.RouteName]);
      }
    }     
    if(this.SelectedMenu.CanAdd)
    {
      this.IsDataIsInViewState=false;
    }
    else
    {
      this.IsDataIsInViewState=true;
    }
    if(this.SelectedMenu.CanView)
    {
      this.CanViewState=false;
    }
    else
    {
      this.CanViewState=true;
    }
    //End of the peremission portion
    this.UpdateMsg=false;
    this.ShowPopup=false;
    this.DisableSave=true; 
    this.GetTDSSlabMaster();    
  }

  GetTDSSlabMaster()
  {
    this.TDSSlabModels =[];
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.AdminMasterService.GetTDSSlabMaster( t).then(x => 
    {
      this.TDSSlab = x;
      Object.keys(this.TDSSlab).forEach( key => 
      {
        this.TDSSlab[key];
        let TDSSlabtemp = new TDSSlabModel();
        TDSSlabtemp = this.TDSSlab[key];
        this.TDSSlabModels.push(TDSSlabtemp);
      });
    });
  }

  GetTDSSlabDetailsByRefId(id,GenderId,GenderName,AgeStatus,HealthEducationCess,ApplicableFromDate,TDSType,template) 
  {
    // Begning of the permission portion
    this.IsDataIsInViewState=false;
    this.validdate=true;
    this.TDSSlabDetails=[];
    // End of the permision portion
    this.TDSSlabModel.GenderId=GenderId;
    this.TDSSlabModel.GenderName=GenderName;
    this.TDSSlabModel.AgeStatus=AgeStatus;
    this.TDSSlabModel.HealthEducationCess=HealthEducationCess;
    this.TDSSlabModel.ApplicableFromDate=ApplicableFromDate;
    this.TDSSlabModel.ModificationDate=ApplicableFromDate;
    this.TDSSlabModel.TDSType=TDSType;
    this.TDSSlabModel.RefId=id;
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.AdminMasterService.GetTDSSlabDetailsByRefId( t,id).then(x => 
    {
      this.TDSSlab = x;
      Object.keys(this.TDSSlab).forEach( key => 
      {
        this.TDSSlab[key];
        let TDSSlabtemp = new TDSSlabModel();
        TDSSlabtemp = this.TDSSlab[key];
        this.TDSSlabDetails.push(TDSSlabtemp);
      });
      if(this.TDSSlabDetails.length == 0 )
      {
        let tdsList : TDSSlabModel[]=[];
        for( var i=0; i<3; i++ )
        {
          let tempTDS = new TDSSlabModel();          
          tdsList.push(tempTDS);         
        }
        this.TDSSlabDetails=tdsList;
      }
    });
    if(this.ShowPopup==true)
    {
      this.modalRef.hide();
    }
    this.modalRef = this.modalService.show(template,this.modelopt);
    this.ShowPopup=true;
    this.UpdateMsg=false;
  }

  ViewTDSSlabDetailsByRefId(id,GenderName,AgeStatus,HealthEducationCess,ApplicableFromDate,TDSType,template) 
  {
    this.TDSSlabDetails=[];
    // Begning of the permission portion
    this.IsDataIsInViewState = true;
    // End of the permision portion
    this.TDSSlabModel.GenderName=GenderName;
    this.TDSSlabModel.AgeStatus=AgeStatus;
    this.TDSSlabModel.HealthEducationCess=HealthEducationCess;
    this.TDSSlabModel.ApplicableFromDate=ApplicableFromDate;
    this.TDSSlabModel.TDSType=TDSType;
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.AdminMasterService.GetTDSSlabDetailsByRefId( t,id).then(x => 
    {
      this.TDSSlab = x;
      Object.keys(this.TDSSlab).forEach( key => 
      {
        this.TDSSlab[key];
        let TDSSlabtemp = new TDSSlabModel();
        TDSSlabtemp = this.TDSSlab[key];
        this.TDSSlabDetails.push(TDSSlabtemp);
      });
    });
    if(this.ShowPopup==true)
    {
      this.modalRef.hide();
    }
    this.modalRef = this.modalService.show(template,this.modelopt);
    this.ShowPopup=true;
    this.UpdateMsg=false;
  }

  UpdateTDSDetails(template) :void
  {
    this.UpdateMsg=false;
    this.DisableSave=false;
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.TDSSlabModel.ApplicableFromDate=this.TDSSlabModel.ApplicableFromDate;
    this.TDSSlabModel.ModificationDate=this.TDSSlabModel.ApplicableFromDate;
    this.AdminMasterService.UpdateTDSSlabMaster(t ,this.TDSSlabModel).subscribe(res=>
    {
      this.TDSSlabModel.RefId=Number(res);
      var j=0;
      var l=this.TDSSlabDetails.length;
      this.TDSSlabDetails.forEach(item =>
      {
        j=j+1;
        if(item.Percentage != null && item.FromAmount != null && item.ToAmount != null)
        {
          item.SlabMasterId=this.TDSSlabModel.RefId;
          this.AdminMasterService.UpdateTDSSlabDetails(t ,item).subscribe(res=>
          {
            this.RefId=res;        
          });                  
        }   
      }); 
      if(this.TDSSlabModel.RefId>0)
      {
        this.validdate = true;
        if(this.TDSSlabDetails.length >0)
        {
          this.TDSSlabDetails=[];
        } 
        this.TDSSlabModel.GenderName=null;
        this.TDSSlabModel.AgeStatus=null;
        this.TDSSlabModel.HealthEducationCess=null;
        this.TDSSlabModel.ApplicableFromDate=null;
        this.TDSSlabModel.ModificationDate=null;
        this.TDSSlabModel.RefId=null;
        this.TDSSlabModel.GenderId=null;
        this.TDSSlabModel.TDSType=null;
        this.modalRef.hide();
        this.ShowPopup=false;
        this.UpdateMsg = true;
        this.modalRef = this.modalService.show(template,this.modelopt);
        this.DisableSave=true;
        this.GetTDSSlabMaster();
      }  
    });
  }

  dateChanged( date)
  {
    this.TDSSlabModel.ApplicableFromDate=date;
    var CurrentDate = new Date();
    var CurrentYear = CurrentDate.getFullYear();
    var currentMonth = CurrentDate.getMonth();
    var CurrentDay = CurrentDate.getDate();
    
    var selecteedDate = new Date(date);
    var selectedYear=selecteedDate.getFullYear();
    var selectedMonth=selecteedDate.getMonth();
    var selectedDay=selecteedDate.getDate();

    if(CurrentYear < selectedYear )
    {
      this.validdate = true;      
    }
    else if(CurrentYear > selectedYear)
    {
      this.validdate = false;
    }
    else
    {
      if (currentMonth < selectedMonth)
      {
        this.validdate = true;
      }
      else if(currentMonth > selectedMonth)
      {
        this.validdate = false;
      }
      else
      {
        if(CurrentDay <= selectedDay )
        {
          this.validdate = true;
        }
        else
        {
          this.validdate = false;
        }
      }
    }
  }

  AddnweRow()
  {
    let temptds = new TDSSlabModel();          
    this.TDSSlabDetails.push(temptds);     
  }
}