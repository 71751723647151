import {  Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs/Subject';
import { DataTableDirective } from 'angular-datatables';
import { AttendanceService }  from '../../shared/HR_ADMIN/Attendance/AttendanceService.service';
import { MapWorkingGroup }  from '../../shared/HR_ADMIN/Attendance/MapWorkingGroup.model';
import { WorkingGroupHours }  from '../../shared/HR_ADMIN/Attendance/WorkingGroupHours.model';
import { UserService } from '../../shared/user.service';
// Begning Confirmation
import { ConfirmationService } from '@jaspero/ng2-confirmations';
import { ResolveEmit } from '../../../app/resolve-emit';
import { ConfirmSettings } from '../../../app/confirm-settings';
// end of Confirmation
import { Menu } from '../../shared/menu.model';
@Component({
  selector: 'app-manage-Map-working-group',
  templateUrl: './manage-Map-working-group.component.html',
  styleUrls: ['./manage-Map-working-group.component.css']
})
export class ManageEmployeeWithWeeklyWorkingGroupComponent implements OnInit
{
  MapWorkingGroupModel : MapWorkingGroup= new MapWorkingGroup();
  userClaims: any;
  Result : string;
  SuccessStatus:boolean=false;
  SuccessMessage: string ="";
  public tableWidget: any;
  MapWorkingGroups: MapWorkingGroup[] = [];
  MapWorkingGroup:any;
  MapWorkingGroupId : any;
  Employees: MapWorkingGroup[] = [];
  Employee:any;
  WorkingGroupHour: WorkingGroupHours[] = [];
  workinggrouphour:any;
  // begin for Validation
  OldWorkingGroupId : number ;
  validdate : boolean= false;
  validGroupid:boolean = false;
  // end of validation
  // Begning Permission Portion    
  SelectedMenu:Menu;
  IsDataIsInViewState : boolean=false;
  CanViewState : boolean=false;
  DisableSave :boolean=false;
  // End of the permission portion

  tempMapWorkingGroupadd : MapWorkingGroup =new MapWorkingGroup();
  
  constructor(private AttendanceService: AttendanceService,
     private rout: Router , private _confirmation: ConfirmationService, private userService: UserService) { }
  
  @ViewChild(DataTableDirective, {static: false})
  setDtOptions: DataTables.Settings = {};
  cardDtOptions: DataTables.Settings = {};
  dtElement: DataTableDirective;
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();

  ngOnInit()
  {    
    // Begning Permission Portion 
    this.SelectedMenu=this.userService.SelectedMenu;
    if(this.SelectedMenu !=undefined)
    {
      localStorage.setItem('selectedmenu',JSON.stringify(this.SelectedMenu));
      this.rout.navigate([this.SelectedMenu.RouteName]);
    }
    else
    {
      if(JSON.parse(localStorage.getItem('selectedmenu'))!=undefined)
      {
        this.SelectedMenu = JSON.parse(localStorage.getItem('selectedmenu'));
        this.rout.navigate([this.SelectedMenu.RouteName]);
      }
    }
    if(this.SelectedMenu.CanAdd)
    {
      this.IsDataIsInViewState=false;
    }
    else
    {
      this.IsDataIsInViewState=true;
    }
    if(this.SelectedMenu.CanView)
    {
      this.CanViewState=false;
    }
    else
    {
      this.CanViewState=true;
    }
    // End of the permission portion
    this.DisableSave=true;
    this.getWorkingHour();
    this.MapWorkingGroupModel.EmployeeId=0;   
    this.MapWorkingGroupModel.WorkingGroupId=0;    
    this.dtOptions = 
    {
      pagingType: 'full_numbers',
      pageLength: 4
    };
    if(localStorage.getItem('SuccessfullMessage'))
    {
      this.SuccessStatus=true;
      this.SuccessMessage =localStorage.getItem('SuccessfullMessage')
      localStorage.removeItem('SuccessfullMessage');
    }
    this.OldWorkingGroupId=0;
  }

  settings: ConfirmSettings | any = 
  {
    overlay: true,
    overlayClickToClose: true,
    showCloseButton: true,
    confirmText: 'Yes',
    declineText: 'No'
  };
 
  ngAfterViewInit(): void 
  {
    this.getMapWorkingGroup();
    this.dtOptions = 
    {
      pagingType: 'full_numbers',
      pageLength: 4
    };
  }
  getMapWorkingGroup()
  {
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.AttendanceService.GetMapWorkingGroup( t).then(x => 
    {
      this.MapWorkingGroup = x;
      Object.keys(this.MapWorkingGroup).forEach( key => 
      {
        this.MapWorkingGroup[key];
        let MapWorkingGrouptemp = new MapWorkingGroup();
        MapWorkingGrouptemp = this.MapWorkingGroup[key];
        this.MapWorkingGroups.push(MapWorkingGrouptemp);
      });
      this.dtTrigger.next();
    });
  }
    
  getWorkingHour() 
  {
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.AttendanceService.GetWorkingGroupHours( t).then(x => 
    {
      this.workinggrouphour = x;
      Object.keys(this.workinggrouphour).forEach( key => 
      {
        this.workinggrouphour[key];
        let workinggrouphourtemp = new WorkingGroupHours();
        workinggrouphourtemp = this.workinggrouphour[key];
        this.WorkingGroupHour.push(workinggrouphourtemp);
      });     
    });      
  }
  
  GetMapWorkingGroupByid(id) 
  {
    this.validGroupid = false;
    // begning of the permission portion
    this.IsDataIsInViewState=false;
    // end of the permission portion
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.AttendanceService.GetMapWorkingGroupByid( t,id).then(x => 
    {
      this.MapWorkingGroup = x;
      Object.keys(this.MapWorkingGroup).forEach( key => 
      {
        this.MapWorkingGroup[key];
        let MapWorkingGrouptemp = new MapWorkingGroup();
        MapWorkingGrouptemp = this.MapWorkingGroup[key];
        this.MapWorkingGroupModel.EmpWorkingGroupId=MapWorkingGrouptemp.EmpWorkingGroupId;
        this.MapWorkingGroupModel.EmployeeId=MapWorkingGrouptemp.EmployeeId;
        this.MapWorkingGroupModel.EmployeeCode=MapWorkingGrouptemp.EmployeeCode;
        this.MapWorkingGroupModel.EmployeeName=MapWorkingGrouptemp.EmployeeName;
        this.MapWorkingGroupModel.WorkingGroupId=MapWorkingGrouptemp.WorkingGroupId;
        let dateString : string = MapWorkingGrouptemp.FromDate.toString();
        let days : number = parseInt(dateString.substring(8, 10));
        let months : number = parseInt(dateString.substring(5, 7));
        let years : number = parseInt(dateString.substring(0, 5));
        let goodDate : Date = new Date(months + "/" + days + "/" + years);
        goodDate.setDate(goodDate.getDate() );
        this.MapWorkingGroupModel.FromDate= goodDate;
        this.MapWorkingGroupModel.ToDate=MapWorkingGrouptemp.ToDate;
        this.MapWorkingGroupModel.IsActive=MapWorkingGrouptemp.IsActive;
      });
      this.OldWorkingGroupId=this.MapWorkingGroupModel.WorkingGroupId;
    });
  }
  
  ViewMapWorkingGroupByid(id) 
  {   
    this.validGroupid = false;
    // begning of the permission portion
    this.IsDataIsInViewState=true;
    // end of the permission portion
    this.validGroupid=false;
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.AttendanceService.GetMapWorkingGroupByid( t,id).then(x => 
    {
      this.MapWorkingGroup = x;
      Object.keys(this.MapWorkingGroup).forEach( key => 
      {
        this.MapWorkingGroup[key];
        let MapWorkingGrouptemp = new MapWorkingGroup();
        MapWorkingGrouptemp = this.MapWorkingGroup[key];
        this.MapWorkingGroupModel.EmpWorkingGroupId=MapWorkingGrouptemp.EmpWorkingGroupId;
        this.MapWorkingGroupModel.EmployeeId=MapWorkingGrouptemp.EmployeeId;
        this.MapWorkingGroupModel.EmployeeCode=MapWorkingGrouptemp.EmployeeCode;
        this.MapWorkingGroupModel.EmployeeName=MapWorkingGrouptemp.EmployeeName;
        this.MapWorkingGroupModel.WorkingGroupId=MapWorkingGrouptemp.WorkingGroupId;
        let dateString : string = MapWorkingGrouptemp.FromDate.toString();
        let days : number = parseInt(dateString.substring(8, 10));
        let months : number = parseInt(dateString.substring(5, 7));
        let years : number = parseInt(dateString.substring(0, 5));
        let goodDate : Date = new Date(months + "/" + days + "/" + years);
        goodDate.setDate(goodDate.getDate() );
        this.MapWorkingGroupModel.FromDate= goodDate;
        this.MapWorkingGroupModel.ToDate=MapWorkingGrouptemp.ToDate;
        this.MapWorkingGroupModel.IsActive=MapWorkingGrouptemp.IsActive;
      });
      
    });
  }

  saveMapWorkingGroup() :void
  {
    this._confirmation.create('Confirm to Save', 'Press Yes to Save', this.settings)
    .subscribe((ans: ResolveEmit) => 
    {
      if (ans.resolved == true) 
      {
        this.DisableSave=false;
        let t =  localStorage.getItem('userToken');
        t = 'Bearer' + t;
        this.MapWorkingGroupModel.IsActive=true;
        if(this.MapWorkingGroupModel.EmpWorkingGroupId > 0)
        {
          this.AttendanceService.UpdateMapWorkingGroup(t ,this.MapWorkingGroupModel).subscribe(res=>
          {
            this.MapWorkingGroupId=res;
            this.MapWorkingGroupModel.EmpWorkingGroupId=0;  
            if(this.MapWorkingGroupId>0)
            {
              localStorage.setItem('SuccessfullMessage', "Update Successfully");
              this.rout.navigateByUrl('/Attendence/ManageAttendenceFineRules', {skipLocationChange: true}).then(()=>this.rout.navigate(["/Attendence/ManageMapWorkingGroup"]));
            }
          });                  
        }
      }
    });
  }
  
  RefreshPage()
  {
    this.rout.navigateByUrl('/Attendence/ManageAttendenceFineRules', {skipLocationChange: true}).then(()=>this.rout.navigate(["/Attendence/ManageMapWorkingGroup"]))
  }

  dateChanged( date)
  {
    this.MapWorkingGroupModel.FromDate=date;
    if(this.workinggrouphour.EmpWorkingGroupId != this.OldWorkingGroupId)
    {
      this.validGroupid =true; 
    }
    var CurrentDate = new Date();
    var CurrentYear = CurrentDate.getFullYear();
    var currentMonth = CurrentDate.getMonth();
    var CurrentDay = CurrentDate.getDate();
    
    var selecteedDate = new Date(date);
    var selectedYear=selecteedDate.getFullYear();
    var selectedMonth=selecteedDate.getMonth();
    var selectedDay=selecteedDate.getDate();
    if(CurrentYear < selectedYear )
    {
      this.validdate = true;      
    }
    else if(CurrentYear > selectedYear)
    {
      this.validdate = false;
    }
    else
    {
      if (currentMonth < selectedMonth)
      {
        this.validdate = true;
      }
      else if(currentMonth > selectedMonth)
      {
        this.validdate = false;
      }
      else
      {
        if(CurrentDay <= selectedDay )
        {
          this.validdate = true;
        }
        else
        {
          this.validdate = false;
        }
      }
    }
  }

  onWorkingGroupChange(WorkingGroupId) 
  {
    if(this.OldWorkingGroupId !=0)
    {
      if(WorkingGroupId != this.OldWorkingGroupId)
      {
        this.validGroupid = true;
      }  
      else     
      {
        this.validGroupid = false;
      }
    }
  }    
}
