<div class="body-content-area tabcontent" id="link1">
  <div class="row">
    <div class="col-lg-9">
      <div class="row">
        <div class="col-lg-1 low-padding text-center">
          <!-- <img *ngIf="this.SelectedMenu.Image!=null" src={{this.SelectedMenu.Image}} class="body-icon" alt="Icon"/> -->
        </div>
        <div class="col-lg-10 clear-padding">
          <div>
            <span class="page-title">Employee KRA Mapping</span>
            <small class="page-description"></small>
            <!-- <div class="col-lg-10 clear-padding" *ngIf="(this.CanViewState)">
              <button *ngIf="!(this.FormViewState)" (click)="ADDClick(template)" type="button" class="btn btn-primary Add-button">ADD New</button> 
            </div> -->
          </div>
        </div>
      </div>
    </div>
  </div>
  
  <div *ngIf="(this.CanViewState)">
    <div style=" text-align: right;">
      <button (click)="ADDClick(template)" type="button" class="btn btn-primary Add-button">ADD New</button> 
    </div>

    <table id="tblKRAMaster" *ngIf="(this.EmployeeDetails.length > 0)" datatable [dtOptions]="dtOptions" class="table table-striped table-bordered text-center table-new row-border hover">
      <thead class="thead-blue text-center" style="width: 100%;">
        <tr>
          <th style="width: 10%;">Sl. No.</th>
          <th style="width: 10%;" class="text-left">Code</th>
          <th style="width: 30%;" class="text-left">Name</th>
          <th style="width: 40%;" class="text-left">Template</th>
          <th style="width: 10%;">Action</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let kra of EmployeeDetails, let i = index">
          <td scope="row">{{i+1}}</td>
          <td class="text-left">{{kra.EmployeeCode}}</td>
          <td class="text-left">{{kra.EmployeeName}}</td>
          <td class="text-left">{{kra.TemplateName}}</td>
          <td>
            <button *ngIf="this.SelectedMenu.CanEdit" type="button" (click)="GetKRAMappingWithEmployee(kra.EmployeeId,kra.TemplateMasterRefId,template)" class="table-edit-btn"><i class="fas fa-edit"></i></button> 
            <button *ngIf="this.SelectedMenu.CanDelete" type="button" (click)="DeleteKRATemplateMappingWithEmployee(kra.EmployeeId,kra.TemplateMasterRefId,template)" class="table-remove-btn"><i class="fas fa-trash-alt"></i></button>
          </td>
        </tr>
      </tbody>
      <tfoot class="thead-blue text-center" style="width: 100%;">
        <tr>
          <th></th>
          <th></th>
          <th></th>
          <th></th>
          <th></th>
        </tr>
      </tfoot>
    </table>
    <div *ngIf="NoRecordMsg" style="color: #dc3545; text-align: center;font-size: 15px; font-weight: bold;">No Records Found</div>
    <div style=" box-align: center; width: 1500px;" >
      <ng-template #template>
        <div class="modal-header" style="background: #2a9bd7; max-width: 1500px;"> 
          <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()" style="color:#fff;">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div  style="text-align: center; max-width: 1500px;">
          <div *ngIf="SaveMappingMsg" style="color: #dc3545; text-align: center;font-size: 15px; font-weight: bold;">Employee Mapping with Template Saved Successfully</div>
          <div *ngIf="DeleteMappingMsg" style="color: #dc3545; text-align: center;font-size: 15px; font-weight: bold;">Employee Mapping with Template Deleted Successfully</div>
          <div *ngIf="UpdateMappingMsg" style="color: #dc3545; text-align: center;font-size: 15px; font-weight: bold;">Employee Mapping with Template Modified Successfully</div>
          <div style=" width: 100%;" *ngIf="ShowPopup">
            <div *ngIf="(this.ForAddView)">
              <fieldset class="body-form-control"> 
                <legend class="legend--">Employee Details</legend>
                <form #EmployeeForm="ngForm">
                  <div class="row">
                    <div class="col-lg-4">
                      <div class="col-body-border-right">
                        <div class="form-group row">
                          <label  class="col-sm-4 col-form-label clear-padding-right">Company</label>
                          <div class="col-sm-7 clear-left-padding">
                            <select [(ngModel)]="KRAMappingWithEmployeeModel.CompanyId" (ngModelChange)="onChangeCompany($event)" id ="CompanyId" class="form-control"  name="CompanyId" #CompanyId="ngModel" >
                              <option [value]="0" [disabled]=true>Select Company</option>
                              <option *ngFor="let c of Companies" [value]="c.CompId">{{c.CompName}}</option>
                            </select>
                          </div>
                          <div class="required-validation" *ngIf="this.KRAMappingWithEmployeeModel.CompanyId == 0">Company is required</div>
                        </div>
                        <div class="form-group row">
                          <label class="col-sm-2 col-form-label clear-padding-right">Employee</label>
                          <div class="col-sm-9 clear-left-padding">
                            <angular2-multiselect class="form-control" [data]="EmployeeList" [(ngModel)]="EmployeeselectedItems" name="EmployeeselectedItems" 
                              [settings]="EmployeeSettings" (onSelect)="onItemSelect($event)" (onDeSelect)="OnItemDeSelect($event)" (onSelectAll)="onSelectAll($event)"
                              (onDeSelectAll)="onDeSelectAll($event)">
                            </angular2-multiselect>
                          </div>
                          <div class="required-validation" *ngIf="this.EmployeeselectedItems.length == 0">Employee is required</div>
                        </div>
                      </div>
                    </div>

                    <div class="col-lg-4">
                      <div class="col-body-border-right">
                        <div class="form-group row">
                          <label  class="col-sm-4 col-form-label clear-padding-right">Branch</label>
                          <div class="col-sm-7 clear-left-padding">
                            <select [(ngModel)]="KRAMappingWithEmployeeModel.BranchId" (ngModelChange)="onchangeBranch($event)"  id ="BranchId" class="form-control" name="BranchId" #BranchId="ngModel" >
                              <option [value]="0">Select Branch</option>
                              <option *ngFor="let b of Branches" [value]="b.BranchId">{{b.BranchName}}</option>
                            </select>
                          </div>
                        </div>
                        <div class="form-group row">
                          <label class="col-sm-4 col-form-label clear-padding-right">KRA Template</label>
                          <div class="col-sm-7 clear-left-padding">
                            <select [(ngModel)]="KRAMappingWithEmployeeModel.TemplateMasterRefId" id ="TemplateMasterRefId" (ngModelChange)="onChangeTemplate($event)" class="form-control" name="TemplateMasterRefId" #TemplateMasterRefId="ngModel">
                              <option [value]="0" [disabled]=true >Select KRA Template</option>
                              <option *ngFor="let template of Templates" [value]="template.TemplateRefId">{{template.TemplateName}}</option>
                            </select>
                          </div>
                          <div class="required-validation" *ngIf="KRAMappingWithEmployeeModel.TemplateMasterRefId == 0">Template is required</div>
                        </div>
                      </div>
                    </div>

                    <div class="col-lg-4">
                      <div class="form-group row">
                        <label class="col-sm-4 col-form-label clear-padding-right">Department</label>
                        <div class="col-sm-7 clear-left-padding">
                          <select [(ngModel)]="KRAMappingWithEmployeeModel.DepartmentId" id ="DepartmentId" (ngModelChange)="onchangeDepartment($event)" class="form-control" name="DepartmentId" #DepartmentId="ngModel">
                            <option [value]="0">Select Department</option>
                            <option *ngFor="let d of Departments" [value]="d.DepartmentId">{{d.DepartmentName}}</option>
                          </select>
                        </div>
                      </div>
                      <div class="form-group row">
                        &nbsp;
                        <div class="col-sm-7 clear-left-padding">
                          <!-- <button type="button" class="btn btn-danger cancle-button" (click)="RefreshPage()">Refresh</button> -->
                        </div>
                      </div>
                    </div>
                  </div>
                </form>    
              </fieldset>
              <fieldset class="body-form-control" *ngIf="(this.KRAMappingWithEmployees.length > 0)"> 
                <legend class="legend--">KRA Details</legend>
                <form #KRADetailForm="ngForm">
                  <div lass="row">
                    <table id="tblkra" class="table table-striped table-bordered text-left table-new row-border hover" style="width: 100%;">
                      <thead class="thead-blue text-center"s tyle="width: 100%;">
                        <tr>
                          <th style="width: 25%; text-align: left;">Question</th>
                          <th style="width: 5%;">Weighted(%)</th>  
                          <th style="width: 35%; text-align: left;">Rating Guideline</th>
                          <th style="width: 35%; text-align: left;">Achievement</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr *ngFor="let kra of KRAMappingWithEmployees, let i = index">
                          <td>
                            {{kra.KRAName}}
                            <input type="text" hidden="hidden" [(ngModel)]="kra.TemplateMasterRefId" name="TemplateMasterRefId_{{i}}" #TemplateMasterRefId="ngModel" class="form-control form-control-custom form-control-custom-new">
                            <input type="text" hidden="hidden" [(ngModel)]="kra.KRAId" name="KRAId_{{i}}" #KRAId="ngModel" class="form-control form-control-custom form-control-custom-new">
                          </td>
                          <td style="text-align: center;">
                            {{kra.Weighted}}
                            <input type="text" hidden="hidden" [(ngModel)]="kra.Weighted" name="Weighted_{{i}}" #Weighted="ngModel" maxlength="2" (ngModelChange)="WategesChanged()" pattern="[0-9]+" class="form-control form-control-custom form-control-custom-new">
                            <div *ngIf="Weighted.invalid && (Weighted.dirty || Weighted.touched)" class="alert alert-danger">
                              <div *ngIf="Weighted.errors?.pattern">Weighted should be valid.</div>
                              <div *ngIf="Weighted.errors.maxlength">Weighted is not more than 2 digit.</div>
                            </div>
                          </td> 
                          <td>
                            {{kra.RatingCriteria}}
                            <input type="text" hidden="hidden" width="95%" [(ngModel)]="kra.RatingCriteria" name="RatingCriteria_{{i}}" #RatingCriteria="ngModel" class="form-control form-control-custom form-control-custom-new">
                          </td> 
                          <td>
                              <input type="text" width="95%" [(ngModel)]="kra.Achievement" name="Achievement_{{i}}" #Achievement="ngModel" class="form-control form-control-custom form-control-custom-new">
                          </td>
                        </tr>
                      </tbody>
                      <tfoot class="thead-blue text-center">
                          <tr>
                            <th style="width: 30%; text-align: right;">Total Wts(%)</th>
                            <th style="width: 5%; text-align: center;">:&nbsp;&nbsp;{{this.KRAMappingWithEmployeeModel.TotalWateges}}</th>  
                            <th style="width: 40%; text-align: left;"></th>
                            <th style="width: 40%; text-align: left;"></th>
                          </tr>
                        </tfoot>
                    </table>
                    <div class="row float-right">
                      <div class=" col-auto text-center"  *ngIf="!(this.IsDataIsInViewState)">
                        <button type="submit" class="btn btn-primary save-button" [disabled]="!(KRADetailForm.valid && this.KRAMappingWithEmployeeModel.TotalWateges==100 && this.DisableSave==true && this.EmployeeselectedItems.length > 0 && this.KRAMappingWithEmployeeModel.TemplateMasterRefId > 0)" (click)="SaveKRAApplication(template)">Save</button>  
                      </div>
                      <div class=" col-auto text-center">
                        <!-- <button type="button" class="btn btn-danger cancle-button" (click)="RefreshPage()">Refresh</button> -->
                      </div>
                    </div>
                  </div>
                </form>
              </fieldset>
            </div>

            <fieldset class="body-form-control" *ngIf="(this.ForUpdateView)"> 
              <legend class="legend--">KRA Modification Details</legend>
              <form #KRAupdateForm="ngForm">
                <div class="row">
                  <div class="col-lg-4">
                    <div class="col-body-border-right">
                      <div class="form-group row">
                        <label class="col-sm-4 col-form-label clear-padding-right">Code</label>
                        <div class="col-sm-7 clear-left-padding">
                          :&nbsp;&nbsp;{{this.KRAMappingWithEmployeeModel.EmployeeCode}}
                        </div>
                      </div>
                      <div class="form-group row">
                        <label class="col-sm-4 col-form-label clear-padding-right">Reporting</label>
                        <div class="col-sm-7 clear-left-padding">
                          :&nbsp;&nbsp;{{this.KRAMappingWithEmployeeModel.RHName}}
                        </div>
                      </div>
                    </div>
                  </div>
              
                  <div class="col-lg-4">
                    <div class="col-body-border-right">
                      <div class="form-group row">
                        <label class="col-sm-4 col-form-label clear-padding-right">Name</label>
                        <div class="col-sm-7 clear-left-padding">
                          :&nbsp;&nbsp;{{this.KRAMappingWithEmployeeModel.EmployeeName}}
                        </div>
                      </div>
                      <div class="form-group row">
                        <label class="col-sm-4 col-form-label clear-padding-right">Functional</label>
                        <div class="col-sm-7 clear-left-padding">
                          :&nbsp;&nbsp;{{this.KRAMappingWithEmployeeModel.FHName}}
                        </div>
                      </div>
                    </div>
                  </div>
              
                  <div class="col-lg-4">
                    <div class="form-group row">
                      <label class="col-sm-4 col-form-label clear-padding-right">Designation</label>
                      <div class="col-sm-7 clear-left-padding">
                        :&nbsp;&nbsp;{{this.KRAMappingWithEmployeeModel.DesignationName}}
                      </div>
                    </div>
                    <div class="form-group row">
                      <label class="col-sm-4 col-form-label clear-padding-right">KRA Template</label>
                      <div class="col-sm-7 clear-left-padding">
                        <select [(ngModel)]="KRAMappingWithEmployeeModel.TemplateMasterRefId" id ="TemplateMasterRefId" (ngModelChange)="onChangeTemplate($event)" class="form-control" name="TemplateMasterRefId" #TemplateMasterRefId="ngModel">
                          <option [value]="0" [disabled]=true >Select KRA Template</option>
                          <option *ngFor="let template of Templates" [value]="template.TemplateRefId">{{template.TemplateName}}</option>
                        </select>
                      </div>
                      <div class="required-validation" *ngIf="KRAMappingWithEmployeeModel.TemplateMasterRefId == 0">Template is required</div>
                    </div>
                  </div>
                </div>
          
                <div lass="row">
                  <table id="tblkra" class="table table-striped table-bordered text-left table-new row-border hover" style="width: 100%;">
                    <thead class="thead-blue text-center"s tyle="width: 100%;">
                      <tr>
                        <th style="width: 25%; text-align: left;">Question</th>
                        <th style="width: 5%;">Weighted(%)</th>  
                        <th style="width: 35%; text-align: left;">Rating Guideline</th>
                        <th style="width: 35%; text-align: left;">Achievement</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let kra of KRAMappingWithEmployees, let i = index">
                        <td>
                          {{kra.KRAName}}
                          <input type="text" hidden="hidden" [(ngModel)]="kra.KRAId" name="KRAId_{{i}}" #KRAId="ngModel">
                          <input type="text" hidden="hidden" [(ngModel)]="kra.RefId" name="RefId_{{i}}" #RefId="ngModel">
                          <input type="text" hidden="hidden" [(ngModel)]="kra.TemplateMasterRefId" name="TemplateMasterRefId_{{i}}" #TemplateMasterRefId="ngModel">
                        </td>
                        <td style="text-align: center;">
                          {{kra.Weighted}}
                          <input type="text" hidden="hidden" [(ngModel)]="kra.Weighted" name="Weighted_{{i}}" #Weighted="ngModel" maxlength="2" (ngModelChange)="WategesChanged()" pattern="[0-9]+" class="form-control form-control-custom form-control-custom-new">
                          <div *ngIf="Weighted.invalid && (Weighted.dirty || Weighted.touched)" class="alert alert-danger">
                            <div *ngIf="Weighted.errors?.pattern">Weighted should be valid.</div>
                            <div *ngIf="Weighted.errors.maxlength">Weighted is not more than 2 digit.</div>
                          </div>
                        </td> 
                        <td>
                          {{kra.RatingCriteria}}
                          <input type="text" hidden="hidden" width="95%" [(ngModel)]="kra.RatingCriteria" name="RatingCriteria_{{i}}" #RatingCriteria="ngModel" class="form-control form-control-custom form-control-custom-new">
                        </td> 
                        <td>
                            <input type="text" width="95%" [(ngModel)]="kra.Achievement" name="Achievement_{{i}}" #Achievement="ngModel" class="form-control form-control-custom form-control-custom-new">
                        </td>
                      </tr>
                    </tbody>
                    <tfoot class="thead-blue text-center">
                        <tr>
                          <th style="width: 30%; text-align: right;">Total Wts(%)</th>
                          <th style="width: 5%; text-align: center;">:&nbsp;&nbsp;{{this.KRAMappingWithEmployeeModel.TotalWateges}}</th>  
                          <th style="width: 40%; text-align: left;"></th>
                          <th style="width: 40%; text-align: left;"></th>
                        </tr>
                      </tfoot>
                  </table>
                  <div class="row float-right">
                    <div class=" col-auto text-center"  *ngIf="!(this.IsDataIsInViewState)">
                      <button type="submit" class="btn btn-primary save-button" [disabled]="!(KRAupdateForm.valid && this.KRAMappingWithEmployeeModel.TotalWateges==100 && this.DisableSave==true && this.KRAMappingWithEmployeeModel.TemplateMasterRefId > 0)" (click)="UpdateKRAApplication(template)">Modifiy</button>  
                    </div>
                    <div class=" col-auto text-center">
                      <!-- <button type="button" class="btn btn-danger cancle-button" (click)="RefreshPage()">Cancel</button> -->
                    </div>
                  </div>
                </div>
              </form>
            </fieldset>
          </div>
          <button type="button" aria-label="Close" (click)="modalRef.hide()" style="background: #2a9bd7; outline: 0px none; border: 0px none; color: #fff; padding: 5px 10px; border-radius: 3px;">
            <span>Close</span>
          </button>             
        </div>
      </ng-template>
    </div>
  </div>
</div>