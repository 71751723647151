export class Form12BBModel
{
    public RefId : number;
    public EmployeeId : number;
    public EmployeeCode : string;
    public EmployeeName : string;
    public FathersName : string;
    public EmployeeAddress : string;
    public ContactNo : string;
    public DepartmentName : string;
    public DesignationName : string;
    public EmployeePANNo : string;
    public Financialyear : string;
    public FinYear : number;
    public PANNO : string;
    public HRA_Rent : number;
    public HRA_LandLordName : string;
    public HRA_LandLordAddress : string;
    public HRA_LandLordPANNo : string;
    public LeaveTravelConcessions : number;
    public DIB_LenderPaidAmount : number;
    public DIB_LenderName : string;
    public DIB_LenderAddress : string;
    public DIB_LenderPANNo : string;
    public FinancialInstitutions : number;
    public Employer : number;
    public Other : number;
    public TotalDedUnderVIA : number;
    public TotalSEC80C_80CCC_80CCD : number;
    public TotalSEC80C : number;
    public Insurance_80C : number;
    public PF_PPF_80C : number;
    public NPS_80C : number;
    public ELSS_80C : number;
    public HousingLoan_80C : number;
    public FD_80C : number;
    public SukanayaSamriddhi_80C : number;
    public SchoolTution_80C : number;
    public SchoolTutuion_C1 : number;
    public SchoolTutuion_C2 : number;
    public MutaulFund_80CCC : number;
    public EPP_80CCD : number;
    public OtherSection_80E_80G_80TTA  : number;
    public MIParents_80D : number;
    public IPEL_80E : number;
    public Donation_80G : number;
    public SEC80TTA_IRSBAc : number;
    public SEC24_IPHL : number;
    public FinalSubmit : boolean;
    public StartDate : Date;
    public EndDate : Date;
    public ShowTextMessage : boolean;
    public SaveButtonView : boolean;
    public TDSType : string;
    public Remarks : string;
}