import { Component, OnInit, ViewChild } from '@angular/core';
import { CrmOppertunityService } from '../../../shared/CRM/MICECRM/Oppertunity/CrmOppertunityService';
import { Router } from '@angular/router';
import { Model_CRM_Oppertunity } from '../../../shared/CRM/MICECRM/Oppertunity/Model_CRM_Oppertunity';
import { Model_CRM_PipeLine } from '../../../shared/CRM/MICECRM/Oppertunity/Model_CRM_PipeLine';
import { Model_CRM_Lead_Contacts } from '../../../shared/CRM/MICECRM/Oppertunity/Model_CRM_Lead_Contacts';
import { Model_CRM_Company } from '../../../shared/CRM/MICECRM/Oppertunity/Model_CRM_Company';
import { ConfirmSettings } from '@jaspero/ng2-confirmations/src/interfaces/confirm-settings';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { ResolveEmit } from '@jaspero/ng2-confirmations/src/interfaces/resolve-emit';
import { ConfirmationService } from '@jaspero/ng2-confirmations';
import { NotificationsService } from '../../../shared/notifications.service';
import { Model_CRM_MoreInformation } from '../../../shared/CRM/MICECRM/Oppertunity/Model_CRM_MoreInformation';
import { Model_CRM_OppotunityNotes } from '../../../shared/CRM/MICECRM/Oppertunity/Model_CRM_Opportunity_Notes';
import { Model_CRM_Opportunity_Shared } from '../../../shared/CRM/MICECRM/Oppertunity/Model_CRM_Opportunity_Shared';
import { CrmLeadService } from '../../../shared/CRM/MICECRM/LEADCRM/CrmLeadService';
import { ModalOptions,BsModalRef, BsModalService  } from 'ngx-bootstrap/modal'
import { DialogComponent } from '../../../../app/dialog/dialog.component';
import { Model_CRM_Assigne } from '../../../shared/CRM/MICECRM/Model_CRM_Assigne';
import { Model_CRM_Opportunity_Shared_Assigned } from '../../../shared/CRM/MICECRM/Oppertunity/Model_CRM_Opportunity_Shared_Assigned'
import { ConfirmationDialogService } from '../../../shared/CRM/MICECRM/confirmation-dialog/confirmation-dialog.service'
import { PagerService } from '../../../shared/CRM/MICECRM/LEADCRM/PagerService'

@Component({
  selector: 'app-crm-oppertunity',
  templateUrl: './crm-oppertunity.component.html',
  styleUrls: ['./crm-oppertunity.component.css'],
  providers: [PagerService]
})
export class CrmOppertunityComponent implements OnInit {


  //  View  Field Decleration Starts For CRM-Oppertunity..

  @ViewChild(DataTableDirective, {static: false})
  setDtOptions: DataTables.Settings = {};
  cardDtOptions: DataTables.Settings = {};
  dtElement: DataTableDirective;
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();



  //  View  Field Decleration Starts For CRM-MoreInformation ..


  setDtOption: DataTables.Settings = {};
  cardDtOption: DataTables.Settings = {};
  dtElements: DataTableDirective;
  dtOption: DataTables.Settings = {};
  dtTriggers: Subject<any> = new Subject();




  // Begin success message popup
  SuccessStatus: boolean;
  SuccessMessage: string;
  IsSavevisible: boolean = true;
  // end success message popup


  // Model Decleration 
  CRM_Oppertynity_Model: Model_CRM_Oppertunity;

  // For Email Validation


  // CRM - Lead Contact Drop Down   
  ContactName: any;
  contactlist: Model_CRM_Lead_Contacts[] = [];


  // For Pipeline DropDown 
  PipeLineName: any;
  pipelinelist: Model_CRM_PipeLine[] = [];


  // For Company Drop Down 
  CompanyName: any;
  companylist: Model_CRM_Company[] = [];


  // ADD-UPDATE-DELETE PART 
  OppertunityId: number;
  DeleteOppertunityNameByID: any;
  IsDataIsInViewState: boolean;


  // Grid Fetch Decleration
  CRMOppertunity: any;



  // For FIT/MICE Drop down :- 
  //    fitmicelist: Model_CRM_FitMice[]=[];
  FitMice: any;

  // For DomesticIntern ational:-
  DomesticInternational: any;
  // domesticinternationallist: Model_CRM_DomesticInternational[] = [];



  //  View  Field Decleration Starts
  check: string;
  CheckExiststatus: boolean;
  Checkeditstatus: boolean;
  checkdataval: any;


  // MOre Information Save Section Decleration 

  //  PaxId: number;
  OppertunityMoreInformationId: number;


  // More Information Grid Fetch

  CRMMoreInformation: any;
  //  moreinformationlist: Model_CRM_MoreInformation[]=[];


  // Delete more Information 
  DeleteMoreInformationNameByID: any;
  selectedIndex: number;
  //Opportunity More Info
  OppertunityObjMoreInfo: any;
  OppertunityMoreInfo: Model_CRM_MoreInformation;


  //Inview state
  IsOppInviewstate: boolean;


  //Opportunity More Info


  //Begning of Opportunity list
  OpportunitySettings = {}
  OpportunityArray: any[] = [];
  CRMOppName: any;
  OpportunitySharedList: Model_CRM_Opportunity_Shared[] = [];
  OpportunitySharedAssigned: Model_CRM_Opportunity_Shared_Assigned;
  SelectedOpportunity: any[] = [];

  SelectedLead: number = 0;
  ListOpportunityforShare: Model_CRM_Oppertunity[] = [];

  //End of opportunity List

  //For Share User
  AssigneArray: any[] = [];
  SelectAssigneList: any[] = [];
  AssigneSettings = {};
  AssigneList: Model_CRM_Assigne[] = [];

  SelectedleadId: number = 0;

  // for shared User

  //for model 
  public modalRef: BsModalRef;
  public modelopt: ModalOptions;


  ShowPopup: boolean = false;
  ApprovedMsg: boolean = false;


  SelectedOpportunityId: number = 0;
  SelectedUserId: number = 0;

  Listobj: any;

  OppoetunitySharedStatus: any;


  //for Paging
  pager: any = {};
  //for paging

  searchstatus: boolean = false;

  constructor(private CRM_OppertunityService: CrmOppertunityService, private rout: Router,
    private _confirmation: ConfirmationService, private notificationsService: NotificationsService, private modalService: BsModalService,
    private CRM_LeadService: CrmLeadService, private confirmationser: ConfirmationDialogService, private pagerService: PagerService) {

    // this.CRM_Oppertynity_Model = new Model_CRM_Oppertunity();




  }
  @ViewChild(DialogComponent, {static: false}) private myChild: DialogComponent;

  ngOnInit() {

    this.CRM_OppertunityService.SearchText = '';
    this.CRM_OppertunityService.CurretPageNumber = 1;

    this.CRM_OppertunityService.IsOppinViewstate = false;
    this.CRM_OppertunityService.ResetOportunity();

    //this.rout.navigate([this.userService.selectedCrmMenu.RouteName]);

    // For Popup Massage :---- 


    // ManageMICEAccess/ManageLead/LeadBasicInfo
    this.notificationsService.emitChange.subscribe(slectedtabindex => {
      if (slectedtabindex != null) {
        this.selectedIndex = slectedtabindex;
      }
    });




    this.GetCRMOppertunity();

    this.CRM_OppertunityService.SelectedCrmIndexPage = 0;

    this.AssigneArray = [];

    // this.CreatedDate(Date);
  }

  settings: ConfirmSettings | any =
    {
      overlay: true,
      overlayClickToClose: true,
      showCloseButton: true,
      confirmText: 'Yes',
      declineText: 'No'
    };


  ngAfterViewInit(): void {

    // this.dtOptions =
    //   {
    //     pagingType: 'full_numbers',
    //     pageLength: 4
    //   };


    this.dtOptions =
      {
        paging: false, searching: false, info: false


      };
  }


  // //Populate Pipeline  Drop  Down  

  // GetPipeLine() {
  //   let t = localStorage.getItem('userToken');
  //   t = 'Bearer' + t
  //   this.pipelinelist = [];
  //   this.CRM_OppertunityService.GetPipeLineStatus(t).then(x => {
  //     this.PipeLineName = x;
  //     Object.keys(this.PipeLineName).forEach(key => {
  //       let PipeLineNametemp = new Model_CRM_PipeLine();
  //       PipeLineNametemp = this.PipeLineName[key];
  //       this.pipelinelist.push(PipeLineNametemp);
  //     });

  //   });
  // }

  //         //Populate  Pipeline  Drop  Down  ENDS

  // //Populate Lead-Contact  Drop  Down  

  // GetLeadContact() {
  //   let t = localStorage.getItem('userToken');
  //   t = 'Bearer' + t
  //   this.contactlist = [];
  //   this.CRM_OppertunityService.GetCrmLeadContact(t).then(x => {
  //     this.ContactName = x;
  //     Object.keys(this.ContactName).forEach(key => {
  //       let ContactNametemp = new Model_CRM_Lead_Contacts();
  //       ContactNametemp = this.ContactName[key];
  //       this.contactlist.push(ContactNametemp);


  //     });

  //   });
  // }

  // //Populate  Lead-Contact  Drop  Down  ENDS


  // //Populate Lead-Contact  Drop  Down  

  // GetCrmCompany() {
  //   let t = localStorage.getItem('userToken');
  //   t = 'Bearer' + t
  //   this.companylist = [];
  //   this.CRM_OppertunityService.Get_CrmCompany(t).then(x => {
  //     this.CompanyName = x;
  //     Object.keys(this.CompanyName).forEach(key => {
  //       let CompanyNametemp = new Model_CRM_Company();
  //       CompanyNametemp = this.CompanyName[key];
  //       this.companylist.push(CompanyNametemp);



  //     });

  //   });
  // }

  //     //Populate  Lead-Contact  Drop  Down  ENDS





  // // Save-Update Region  Starts

  // saveCRMOppertunity(): void {
  //   let t = localStorage.getItem('userToken');
  //   t = 'Bearer' + t;
  //   this.CRM_Oppertynity_Model.IsActive = true;

  //   if (this.CRM_Oppertynity_Model.OppertunityId > 0) {
  //     this.CRM_OppertunityService.UpdateCrmOppertunity(t, this.CRM_Oppertynity_Model).subscribe(res => {
  //       this.OppertunityId = res;
  //       this.CRM_Oppertynity_Model.OppertunityId = 0;
  //       if (this.OppertunityId > 0) {
  //         localStorage.setItem('SuccessfullMessage', "Successfully Updated");
  //         this.rout.navigateByUrl('ManageMICEAccess/ManageMICEUser', { skipLocationChange: true }).then(() => this.rout.navigate(["ManageMICEAccess/CrmOppertunity"]));
  //       }
  //     });
  //   }
  //   else {
  //     this.CRM_OppertunityService.AddCrmOppertunity(t, this.CRM_Oppertynity_Model).subscribe(res => {
  //       this.OppertunityId = res;
  //       this.CRM_Oppertynity_Model.OppertunityId = 0;


  //       if (this.OppertunityId > 0) {
  //         localStorage.setItem('SuccessfullMessage', "Successfully Added");
  //         this.rout.navigateByUrl('ManageMICEAccess/ManageMICEUser', { skipLocationChange: true }).then(() => this.rout.navigate(["ManageMICEAccess/CrmOppertunity"]));

  //       }
  //     });
  //   }
  // }


  // // Save-Update Region  Ends


  // begning of Opportunity refresh

  RefreshOpportunity() {

    this.CRM_OppertunityService.IsOppinViewstate = false;

    this.CRM_OppertunityService.CrmCommonUseOportunityDetails = new Model_CRM_Oppertunity();

    this.CRM_OppertunityService.CRM_MoreInformation_ModelShare = new Model_CRM_MoreInformation();

    this.CRM_OppertunityService.CrmCommonUseOportunityDetails.OppertunityId = 0;
    this.CRM_OppertunityService.CRM_MoreInformation_ModelShare.DomesticInternationalId = 0;
    this.CRM_OppertunityService.CRM_MoreInformation_ModelShare.OppertunityMoreInformationId = 0;
    this.CRM_OppertunityService.CRM_MoreInformation_ModelShare.OppertunityId = 0;
    this.CRM_OppertunityService.CRM_MoreInformation_ModelShare.FitMiceId = 0;


    this.CRM_OppertunityService.CRm_Opportunity_Notes = new Model_CRM_OppotunityNotes();
    this.CRM_OppertunityService.CRm_Opportunity_Notes.NoteId = 0;
    this.CRM_OppertunityService.CRm_Opportunity_Notes.NoteDate = new Date();
    this.CRM_OppertunityService.CRm_Opportunity_Notes.NoteTime = '';
    this.CRM_OppertunityService.CRm_Opportunity_Notes.Note = '';
    this.CRM_OppertunityService.CRm_Opportunity_Notes.Important = false;
    this.CRM_OppertunityService.CRm_Opportunity_Notes.File = '';
    this.CRM_OppertunityService.CRm_Opportunity_Notes.FileName = '';
    this.CRM_OppertunityService.CRm_Opportunity_Notes.FileSize = '';
    this.CRM_OppertunityService.CRm_Opportunity_Notes.FileType = '';
    this.CRM_OppertunityService.CRm_Opportunity_Notes.UploadedFilename = '';
    this.CRM_OppertunityService.CRm_Opportunity_Notes.ImportantLink = '';

    this.CRM_OppertunityService.CRm_Opportunity_Notes.ContactId = 0;
    this.CRM_OppertunityService.CRm_Opportunity_Notes.Salution = 0;
    this.CRM_OppertunityService.CRm_Opportunity_Notes.FileName = '';
    this.CRM_OppertunityService.CRm_Opportunity_Notes.MiddleName = '';
    this.CRM_OppertunityService.CRm_Opportunity_Notes.LastName = '';

    this.CRM_OppertunityService.CRm_Opportunity_Notes.Title = '';
    this.CRM_OppertunityService.CRm_Opportunity_Notes.WorkerPhone = '';
    this.CRM_OppertunityService.CRm_Opportunity_Notes.Email = '';
    this.CRM_OppertunityService.CRm_Opportunity_Notes.Roll = 0;





  }


  //end of opportunity Refresh




  //For The Grid Statrs

  GetCRMOppertunity() {

    $('#tblcrmoppertunity').DataTable().destroy();

    let t = localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.CRM_OppertunityService.oppertunitylist = [];
    this.CRM_OppertunityService.OpportunitytempList = [];
    this.CRM_OppertunityService.OpportunityActualList = [];
    this.CRM_OppertunityService.Get_CRMOppertunity(t, this.CRM_OppertunityService.CurretPageNumber, this.CRM_OppertunityService.SearchText).then(x => {
      this.CRMOppertunity = x;
      Object.keys(this.CRMOppertunity).forEach(key => {
        this.CRMOppertunity[key];
        let CRMOppertunitytemp = new Model_CRM_Oppertunity();
        CRMOppertunitytemp = this.CRMOppertunity[key];

        this.CRM_OppertunityService.oppertunitylist.push(CRMOppertunitytemp);
        //   alert("2");
      })


    }).then(x => {

      this.CRM_OppertunityService.OpportunityActualList = this.CRM_OppertunityService.oppertunitylist

      this.setPage(1);
    })

  }


  GetCRMOppertunityFrompage(pagenumner: number) {

    $('#tblcrmoppertunity').DataTable().destroy();

    this.CRM_OppertunityService.CurretPageNumber = pagenumner;

    let t = localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.CRM_OppertunityService.oppertunitylist = [];
    this.CRM_OppertunityService.OpportunitytempList = [];
    this.CRM_OppertunityService.OpportunityActualList = [];
    this.CRM_OppertunityService.Get_CRMOppertunity(t, this.CRM_OppertunityService.CurretPageNumber, this.CRM_OppertunityService.SearchText).then(x => {
      this.CRMOppertunity = x;
      Object.keys(this.CRMOppertunity).forEach(key => {
        this.CRMOppertunity[key];
        let CRMOppertunitytemp = new Model_CRM_Oppertunity();
        CRMOppertunitytemp = this.CRMOppertunity[key];

        this.CRM_OppertunityService.oppertunitylist.push(CRMOppertunitytemp);
        //   alert("2");
      })


    }).then(x => {

      this.CRM_OppertunityService.OpportunityActualList = this.CRM_OppertunityService.oppertunitylist
      this.setPage(this.CRM_OppertunityService.CurretPageNumber);
    })

  }




  setPage(page: number) {
    // get pager object from service



    this.dtTrigger.next();

    if (this.CRM_OppertunityService.OpportunityActualList[0].TotalPages > 20) {
      this.pager = this.pagerService.getPager(this.CRM_OppertunityService.OpportunityActualList[0].TotalPages, page, 20);
    }
    else {
      this.pager = {};
    }


  }



  // onSearchChange(searchValue: string): void {


  //   if (this.CRM_OppertunityService.OpportunitytempList.length == 0) {

  //     this.CRM_OppertunityService.OpportunitytempList = this.CRM_OppertunityService.oppertunitylist;


  //   }



  //   if (searchValue == "") {
  //     this.CRM_OppertunityService.oppertunitylist = this.CRM_OppertunityService.OpportunitytempList;

  //     this.CRM_OppertunityService.OpportunitytempList = [];

  //     this.setPage(1);

  //   }
  //   else {

  //     this.CRM_OppertunityService.oppertunitylist = [];

  //     //this.CRM_LeadService.leadlist = this.CRM_LeadService.tempLeadList.filter(x => x.LastName.includes(searchValue));

  //     //this.setPage(1);
  //     var i = 1;

  //     this.CRM_OppertunityService.OpportunitytempList.forEach(x => {
  //       i = i + 1;
  //       let OppertunityTitle = x.OppertunityTitle.toUpperCase();
  //       let PipelineStatus = x.PipeLineStatus.toUpperCase();
  //       let CompanyName = x.CompanyName.toUpperCase();
  //       let ContactName = x.ContactName.toUpperCase();
  //       let EmployeeName = x.EmployeeName.toUpperCase();
  //       searchValue = searchValue.toUpperCase();
  //       if (OppertunityTitle.indexOf(searchValue) > -1) {

  //         this.CRM_OppertunityService.oppertunitylist.push(x);

  //       }

  //       else if (PipelineStatus.indexOf(searchValue) > -1) {

  //         this.CRM_OppertunityService.oppertunitylist.push(x);

  //       }
  //       else if (CompanyName.indexOf(searchValue) > -1) {
  //         this.CRM_OppertunityService.oppertunitylist.push(x);

  //       }
  //       else if (EmployeeName.indexOf(searchValue) > -1) {
  //         this.CRM_OppertunityService.oppertunitylist.push(x);

  //       }

  //       else {
  //         if (ContactName.indexOf(searchValue) > -1) {
  //           this.CRM_OppertunityService.oppertunitylist.push(x);
  //         }
  //       }


  //       if (i == this.CRM_OppertunityService.OpportunitytempList.length) {


  //         this.setPage(1);


  //       }

  //     })

  //   }
  // }

  // //  Update Section by  Strats




  onSearchChange(searchValue: string): void {


    if ((searchValue == "") || (searchValue == undefined)) {

      if (this.CRM_OppertunityService.SearchText == '') {
        this.searchstatus = false;
      }
      else {
        this.searchstatus = true;
      }
    }
    else {
      if (this.CRM_OppertunityService.SearchText == searchValue) {
        this.searchstatus = false;

      }
      else {
        this.searchstatus = true;
      }

    }

    if (this.searchstatus) {

      this.CRM_OppertunityService.SearchText = searchValue;
      this.GetCRMOppertunity();
    }

  }

  GetCRMOppertunityById(Id) {

    this.CRM_OppertunityService.IsOppinViewstate = false;
    this.IsDataIsInViewState = false;
    // End of the permision portion
    let t = localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.CRM_OppertunityService.GetCRM_Oppertunity_By_id(t, Id).then(x => {
      this.CRMOppertunity = x;
      Object.keys(this.CRMOppertunity).forEach(key => {

        let CRMOppertunitytemp = new Model_CRM_Oppertunity();
        CRMOppertunitytemp = this.CRMOppertunity[key];
        this.CRM_OppertunityService.CrmCommonUseOportunityDetails = new Model_CRM_Oppertunity();
        this.CRM_OppertunityService.CrmCommonUseOportunityDetails.OppertunityId = CRMOppertunitytemp.OppertunityId;
        this.CRM_OppertunityService.CrmCommonUseOportunityDetails.OppertunityTitle = CRMOppertunitytemp.OppertunityTitle;
        this.CRM_OppertunityService.CrmCommonUseOportunityDetails.PipeLineId = CRMOppertunitytemp.PipeLineId;
        this.CRM_OppertunityService.CrmCommonUseOportunityDetails.PipeLineStatus = CRMOppertunitytemp.PipeLineStatus;
        this.CRM_OppertunityService.CrmCommonUseOportunityDetails.ContactId = CRMOppertunitytemp.ContactId;
        this.CRM_OppertunityService.CrmCommonUseOportunityDetails.ContactName = CRMOppertunitytemp.ContactName;
        this.CRM_OppertunityService.CrmCommonUseOportunityDetails.CompanyId = CRMOppertunitytemp.CompanyId;
        this.CRM_OppertunityService.CrmCommonUseOportunityDetails.CompanyName = CRMOppertunitytemp.CompanyName;
        this.CRM_OppertunityService.CrmCommonUseOportunityDetails.CreatedDate = CRMOppertunitytemp.CreatedDate;
        this.CRM_OppertunityService.CrmCommonUseOportunityDetails.CreatedDateval = CRMOppertunitytemp.CreatedDateval;
        this.CRM_OppertunityService.CrmCommonUseOportunityDetails.CloseDate = CRMOppertunitytemp.CloseDate;
        this.CRM_OppertunityService.CrmCommonUseOportunityDetails.CloseDateval = CRMOppertunitytemp.CloseDateval;
        this.CRM_OppertunityService.CrmCommonUseOportunityDetails.Probability = CRMOppertunitytemp.Probability;
        this.CRM_OppertunityService.CrmCommonUseOportunityDetails.OppertunityDescription = CRMOppertunitytemp.OppertunityDescription;
        this.CRM_OppertunityService.CrmCommonUseOportunityDetails.IsActive = CRMOppertunitytemp.IsActive;
        this.CRM_OppertunityService.CRM_MoreInformation_ModelShare.OppertunityId = CRMOppertunitytemp.OppertunityId;
        this.CRM_OppertunityService.CRm_Opportunity_Notes.OportunityId = this.OppertunityId;
        this.CRM_OppertunityService.CrmCommonUseOportunityDetails.Amount = CRMOppertunitytemp.Amount;
        this.CRM_OppertunityService.CrmCommonUseOportunityDetails.MonthId = CRMOppertunitytemp.MonthId;
        this.CRM_OppertunityService.CrmCommonUseOportunityDetails.TypeOfBusiness = CRMOppertunitytemp.TypeOfBusiness;

      });
    }).then(x => {


      this.GetCRMMoreOpprtunityInfoInfo(Id);
      //this.rout.navigate([this.userService.selectedCrmMenu.RouteName]);
      this.CRM_OppertunityService.CrmCommonUseOportunityDetails.IsClosingDateValid = true;
      this.CRM_OppertunityService.CrmCommonUseOportunityDetails.IsCreatedDateValid = true;
      this.CRM_OppertunityService.SelectedCrmIndexPage = 0;



      this.rout.navigateByUrl('/ManageMICEAccess/CrmOppertunity/OportunityMoreInfo', { skipLocationChange: true }).then(() => this.rout.navigate(["ManageMICEAccess/CrmOppertunity/OportunityDetails"]));

    });
  }



  ViewCRMOppertunityById(Id) {

    this.CRM_OppertunityService.IsOppinViewstate = true;

    this.IsDataIsInViewState = true;
    // End of the permision portion
    let t = localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.CRM_OppertunityService.GetCRM_Oppertunity_By_id(t, Id).then(x => {
      this.CRMOppertunity = x;
      Object.keys(this.CRMOppertunity).forEach(key => {

        let CRMOppertunitytemp = new Model_CRM_Oppertunity();
        CRMOppertunitytemp = this.CRMOppertunity[key];
        this.CRM_OppertunityService.CrmCommonUseOportunityDetails = new Model_CRM_Oppertunity();
        this.CRM_OppertunityService.CrmCommonUseOportunityDetails.OppertunityId = CRMOppertunitytemp.OppertunityId;
        this.CRM_OppertunityService.CrmCommonUseOportunityDetails.OppertunityTitle = CRMOppertunitytemp.OppertunityTitle;
        this.CRM_OppertunityService.CrmCommonUseOportunityDetails.PipeLineId = CRMOppertunitytemp.PipeLineId;
        this.CRM_OppertunityService.CrmCommonUseOportunityDetails.PipeLineStatus = CRMOppertunitytemp.PipeLineStatus;
        this.CRM_OppertunityService.CrmCommonUseOportunityDetails.ContactId = CRMOppertunitytemp.ContactId;
        this.CRM_OppertunityService.CrmCommonUseOportunityDetails.ContactName = CRMOppertunitytemp.ContactName;
        this.CRM_OppertunityService.CrmCommonUseOportunityDetails.CompanyId = CRMOppertunitytemp.CompanyId;
        this.CRM_OppertunityService.CrmCommonUseOportunityDetails.CompanyName = CRMOppertunitytemp.CompanyName;
        this.CRM_OppertunityService.CrmCommonUseOportunityDetails.CreatedDate = CRMOppertunitytemp.CreatedDate;
        this.CRM_OppertunityService.CrmCommonUseOportunityDetails.CreatedDateval = CRMOppertunitytemp.CreatedDateval;
        this.CRM_OppertunityService.CrmCommonUseOportunityDetails.CloseDate = CRMOppertunitytemp.CloseDate;
        this.CRM_OppertunityService.CrmCommonUseOportunityDetails.CloseDateval = CRMOppertunitytemp.CloseDateval;
        this.CRM_OppertunityService.CrmCommonUseOportunityDetails.Probability = CRMOppertunitytemp.Probability;
        this.CRM_OppertunityService.CrmCommonUseOportunityDetails.OppertunityDescription = CRMOppertunitytemp.OppertunityDescription;
        this.CRM_OppertunityService.CrmCommonUseOportunityDetails.IsActive = CRMOppertunitytemp.IsActive;
        this.CRM_OppertunityService.CRM_MoreInformation_ModelShare.OppertunityId = CRMOppertunitytemp.OppertunityId;
        this.CRM_OppertunityService.CRm_Opportunity_Notes.OportunityId = this.OppertunityId;
        this.CRM_OppertunityService.CrmCommonUseOportunityDetails.Amount = CRMOppertunitytemp.Amount;
        this.CRM_OppertunityService.CrmCommonUseOportunityDetails.MonthId = CRMOppertunitytemp.MonthId;
        this.CRM_OppertunityService.CrmCommonUseOportunityDetails.TypeOfBusiness = CRMOppertunitytemp.TypeOfBusiness;

      });
    }).then(x => {

      this.GetCRMMoreOpprtunityInfoInfo(Id);
      //this.rout.navigate([this.userService.selectedCrmMenu.RouteName]);
      this.CRM_OppertunityService.CrmCommonUseOportunityDetails.IsClosingDateValid = true;
      this.CRM_OppertunityService.CrmCommonUseOportunityDetails.IsCreatedDateValid = true;
      this.CRM_OppertunityService.SelectedCrmIndexPage = 0;

      this.rout.navigateByUrl('/ManageMICEAccess/CrmOppertunity/OportunityMoreInfo', { skipLocationChange: true }).then(() => this.rout.navigate(["ManageMICEAccess/CrmOppertunity/OportunityDetails"]));

    });
  }




  GetCRMMoreOpprtunityInfoInfo(leadid) {

    let t = localStorage.getItem('userToken');
    t = 'Bearer' + t

    this.CRM_OppertunityService.GetCRM_MoreInfo_ByOpportunityId(t, leadid).then(x => {
      this.OppertunityObjMoreInfo = x;

      Object.keys(this.OppertunityObjMoreInfo).forEach(key => {

        switch (key) {
          case 'OppertunityMoreInformationId': {
            this.CRM_OppertunityService.CRM_MoreInformation_ModelShare.OppertunityMoreInformationId = this.OppertunityObjMoreInfo[key];
            break;
          }

          case 'NumberOfPax': {
            this.CRM_OppertunityService.CRM_MoreInformation_ModelShare.NumberOfPax = this.OppertunityObjMoreInfo[key];
            break;
          }
          case 'Retention': {
            this.CRM_OppertunityService.CRM_MoreInformation_ModelShare.Retention = this.OppertunityObjMoreInfo[key];
            break;
          }
          case 'CreditTime': {
            this.CRM_OppertunityService.CRM_MoreInformation_ModelShare.CreditTime = this.OppertunityObjMoreInfo[key];
            break;
          }
          case 'CloseDate': {
            this.CRM_OppertunityService.CRM_MoreInformation_ModelShare.CloseDate = this.OppertunityObjMoreInfo[key];
            break;
          }
          // case 'CloseDateval': {
          //   if (this.OppertunityObjMoreInfo[key] == null) {
          //     this.CRM_OppertunityService.CRM_MoreInformation_ModelShare.CloseDateval = '';

          //   }
          //   else {
          //     this.CRM_OppertunityService.CRM_MoreInformation_ModelShare.CloseDateval = this.OppertunityObjMoreInfo[key];

          //   }

          //   break;
          // }
          case 'QueryNumber': {
            this.CRM_OppertunityService.CRM_MoreInformation_ModelShare.QueryNumber = this.OppertunityObjMoreInfo[key];
            break;
          }
          case 'AdvancePayment': {
            this.CRM_OppertunityService.CRM_MoreInformation_ModelShare.AdvancePayment = this.OppertunityObjMoreInfo[key];
            break;
          }
          case 'DomesticInternational': {
            this.CRM_OppertunityService.CRM_MoreInformation_ModelShare.DomesticInternational = this.OppertunityObjMoreInfo[key];
            break;
          }
          case 'DomesticInternationalId': {
            this.CRM_OppertunityService.CRM_MoreInformation_ModelShare.DomesticInternationalId = this.OppertunityObjMoreInfo[key];
            break;
          }
          case 'GPFigure': {
            this.CRM_OppertunityService.CRM_MoreInformation_ModelShare.GPFigure = this.OppertunityObjMoreInfo[key];
            break;
          }
          case 'Reason': {
            this.CRM_OppertunityService.CRM_MoreInformation_ModelShare.Reason = this.OppertunityObjMoreInfo[key];
            break;
          }
          case 'FitMiceName': {
            this.CRM_OppertunityService.CRM_MoreInformation_ModelShare.FitMiceName = this.OppertunityObjMoreInfo[key];
            break;
          }
          case 'FitMiceId': {
            this.CRM_OppertunityService.CRM_MoreInformation_ModelShare.FitMiceId = this.OppertunityObjMoreInfo[key];

            break;
          }
          case 'Destination': {
            this.CRM_OppertunityService.CRM_MoreInformation_ModelShare.Destination = this.OppertunityObjMoreInfo[key];
            break;
          }
          case 'DepertureDate': {

            this.CRM_OppertunityService.CRM_MoreInformation_ModelShare.DepertureDate = this.OppertunityObjMoreInfo[key];

            break;
          }
          case 'DepertureDateval': {
            this.CRM_OppertunityService.CRM_MoreInformation_ModelShare.DepertureDateval = this.OppertunityObjMoreInfo[key];

            break;
          }
          case 'TimePeriod': {
            this.CRM_OppertunityService.CRM_MoreInformation_ModelShare.TimePeriod = this.OppertunityObjMoreInfo[key];
            break;
          }

          case 'Source': {
            this.CRM_OppertunityService.CRM_MoreInformation_ModelShare.Source = this.OppertunityObjMoreInfo[key];
            break;
          }
          case 'CreatedDate': {
            this.CRM_OppertunityService.CRM_MoreInformation_ModelShare.CreatedDate = this.OppertunityObjMoreInfo[key];
            break;
          }
          // case 'CloseDateval': {
          //   this.CRM_OppertunityService.CRM_MoreInformation_ModelShare.CloseDateval = this.OppertunityObjMoreInfo[key];

          //   break;
          // }
          case 'IsActive': {
            this.CRM_OppertunityService.CRM_MoreInformation_ModelShare.IsActive = this.OppertunityObjMoreInfo[key];
            break;
          }
          case 'AdvanceId': {
            this.CRM_OppertunityService.CRM_MoreInformation_ModelShare.AdvanceId = this.OppertunityObjMoreInfo[key];
            break;
          }
          case 'SourceQueryId': {
            this.CRM_OppertunityService.CRM_MoreInformation_ModelShare.SourceQueryId = this.OppertunityObjMoreInfo[key];
            break;
          }



          default: {
            console.log("Invalid choice");
            break;
          }
        }

      });

    });
  }





  // // Get CRM Oppertunity By ID 




  // //  Delete  Part  Starts  

  DeleteCrmOppertunity(ID: number): void {
    // alert(ID);
    this._confirmation.create('Confirm to Delete', 'Press Yes to Delete', this.settings)
      .subscribe((ans: ResolveEmit) => {
        if (ans.resolved == true) {

          let t = localStorage.getItem('userToken');
          t = 'Bearer' + t;

          if (ID > 0) {
            this.CRM_OppertunityService.DeleteOppertunity(t, ID).subscribe(res => {
              this.DeleteOppertunityNameByID = res;

              this.CRM_OppertunityService.ResetOportunity();
              if (this.DeleteOppertunityNameByID > 0) {
                localStorage.setItem('SuccessfullMessage', "Successfully Deleted");
                this.rout.navigateByUrl('ManageMICEAccess/ManageMICEUser', { skipLocationChange: true }).then(() => this.rout.navigate(["ManageMICEAccess/CrmOppertunity"]));
              }
            });
          }

        }
      })
  }


  GetCrmOpportunityForDrop() {

    let t = localStorage.getItem('userToken');
    t = 'Bearer' + t
    this.companylist = [];
    this.OpportunityArray = [];
    this.SelectedOpportunity = [];
    this.CRM_OppertunityService.Get_CRMOppertunityForDropdown(t).then(x => {
      this.CRMOppName = x;
      Object.keys(this.CRMOppName).forEach(key => {
        let CRMOpportunitytemp = new Model_CRM_Oppertunity();
        CRMOpportunitytemp = this.CRMOppName[key];
        this.ListOpportunityforShare.push(CRMOpportunitytemp);
        this.OpportunityArray.push({ "id": CRMOpportunitytemp.OppertunityId, "itemName": CRMOpportunitytemp.OppertunityTitle });

      });

    })

  }





  onItemSelectOpportunity(item: any) {

    this.SelectedOpportunityId = item['id'];
    this.GetCrmOpportunitySgareed();

  }
  OnItemDeSelectOpportunity(item: any) { }
  onSelectAllOpportunity(items: any) { }
  onDeSelectAllOpportunity(items: any) { }

  viewApplicationSharedtemplate(template) {

    this.OpportunitySharedList = [];
    this.OpportunityArray = [];
    this.SelectedOpportunity = [];
    this.SelectAssigneList = [];
    this.OpportunitySettings = {
      singleSelection: true,
      text: 'Select Opportunity',
      enableSearchFilter: true,
      showCheckbox: false
    }

    this.AssigneSettings = {
      text: 'Select User',
      enableSearchFilter: true,
      showCheckbox: true
    }
    this.GetCrmOpportunityForDrop();
    // this.GetCrmAssignetoList();
    this.modelopt = new ModalOptions();
    this.modelopt.class = 'heavy_modal';
    this.modalService.config = this.modelopt;

    this.modalRef = this.modalService.show(template, this.modelopt);
    //this.GridShow=true;
    this.ShowPopup = true;
    //  this.ApprovedMsg=false;
    //  this.RejectedMsg=false;
    // Begning of the permission portion
    this.IsDataIsInViewState = false;
    // End of the permision portion
    this.ApprovedMsg = true;
  }


  CRMSubPages(selectitem, RouteName) {
    // this.router.navigate(['Organization']);

    //this.SelectedIndexPage=selectitem;
    this.CRM_OppertunityService.SelectedCrmIndexPage = selectitem
    this.rout.navigate([RouteName]);
  }

  GetCrmAssignetoList() {

    let t = localStorage.getItem('userToken');
    t = 'Bearer' + t

    this.AssigneList = [];
    this.AssigneArray = [];
    this.CRM_LeadService.GetAssignetoList(t).then(x => {
      this.Listobj = x;
      Object.keys(this.Listobj).forEach(key => {
        let AssigneTotemp = new Model_CRM_Assigne();
        AssigneTotemp = this.Listobj[key];

        this.AssigneList.push(AssigneTotemp);
        this.AssigneArray.push({ "id": AssigneTotemp.EmployeeId, "itemName": AssigneTotemp.EmployeeName });
      });
    })
  }



  onItemSelectAssigneTo(item: any) {

    this.SelectedUserId = item['id'];
    let tempshared = this.AssigneList.find(y => y.EmployeeId == this.SelectedUserId);
    let tempharedObj = new Model_CRM_Opportunity_Shared();
    tempharedObj.UserId = tempshared.EmployeeId;
    tempharedObj.OpportunityID = this.SelectedOpportunityId;
    let indexofshare = this.OpportunitySharedList.findIndex(x => x.UserId == tempshared.EmployeeId);
    if (!(indexofshare == -1)) {
      // this.OpportunitySharedList = this.OpportunitySharedList.filter(x => x.UserId !== tempshared.EmployeeId);
      this.OpportunitySharedList.find(x => x.UserId == tempshared.EmployeeId).IsActive = true;
    }
    else {
      tempharedObj.IsActive = true;
      this.OpportunitySharedList.push(tempharedObj);
    }

  }
  OnItemDeSelectAssigneTo(item: any) {
    this.SelectedUserId = item['id'];
    let tempshared = this.AssigneList.find(y => y.EmployeeId == this.SelectedUserId);
    let indexofshare = this.OpportunitySharedList.findIndex(x => x.UserId == tempshared.EmployeeId);
    if (!(indexofshare == -1)) {
      // this.OpportunitySharedList = this.OpportunitySharedList.filter(x => x.UserId !== tempshared.EmployeeId);
      this.OpportunitySharedList.find(x => x.UserId == tempshared.EmployeeId).IsActive = false;
    }

  }

  onSelectAllAssigneTo(items: any) {

    this.OpportunitySharedList = [];
    this.AssigneList.forEach(x => {
      let tempharedObj = new Model_CRM_Opportunity_Shared();
      tempharedObj.UserId = x.EmployeeId;
      tempharedObj.OpportunityID = this.SelectedOpportunityId;
      tempharedObj.IsActive = true;
      let indexofshare = this.OpportunitySharedList.findIndex(x => x.UserId == tempharedObj.UserId);
      if (indexofshare < 0) {
        this.OpportunitySharedList.push(tempharedObj);
      }
    });
  }


  get OpportunityActualList(): Model_CRM_Oppertunity[] {
    if (this.CRM_OppertunityService.OpportunityActualList == undefined || this.CRM_OppertunityService.OpportunityActualList == null) {

      this.CRM_OppertunityService.OpportunityActualList =[];
   
      return this.CRM_OppertunityService.OpportunityActualList;
    }
    else {
      return this.CRM_OppertunityService.OpportunityActualList;
    }
  }

  get OpportunitySelectedCrmIndexPage(): number {
    if (this.CRM_OppertunityService.SelectedCrmIndexPage == undefined || this.CRM_OppertunityService.SelectedCrmIndexPage == null) {

      this.CRM_OppertunityService.SelectedCrmIndexPage =0;
   
      return this.CRM_OppertunityService.SelectedCrmIndexPage;
    }
    else {
      return this.CRM_OppertunityService.SelectedCrmIndexPage;
    }
  }


  onDeSelectAllAssigneTo(items: any) {

    this.OpportunitySharedList.forEach(y => {

      this.OpportunitySharedList.find(x => x.UserId == y.UserId).IsActive = false;

    });
  }


  GetCrmOpportunitySgareed() {

    let t = localStorage.getItem('userToken');
    t = 'Bearer' + t

    // this.AssigneList = [];
    // this.AssigneArray = [];

    this.SelectAssigneList = [];
    this.CRM_OppertunityService.GetCrmOpportunitySharedById(t, this.SelectedOpportunityId).then(x => {
      this.Listobj = x;
      // Object.keys(this.Listobj).forEach(key => {
      //   let AssigneTotemp = new Model_CRM_Opportunity_Shared_Assigned();
      //   AssigneTotemp = this.Listobj[key];

      //   this.OpportunitySharedList.push(AssigneTotemp);
      //   let tempassigneduser = this.AssigneList.find(x => x.EmployeeId == AssigneTotemp.UserId);
      //   this.SelectAssigneList.push({ "id": tempassigneduser.EmployeeId, "itemName": tempassigneduser.EmployeeName });
      // });
      this.OpportunitySharedList = [];
      this.OpportunitySharedAssigned = this.Listobj;

      this.OpportunitySharedList = this.OpportunitySharedAssigned.OpportunityShared;
      this.AssigneList = this.OpportunitySharedAssigned.OpportunityAssigned;

      //SelectAssigneList

      this.AssigneArray = [];
      this.AssigneList.forEach(x => {
        this.AssigneArray.push({ "id": x.EmployeeId, "itemName": x.EmployeeName });
      })

      this.SelectAssigneList = [];

      this.OpportunitySharedList.forEach(x => {
        let tempassigneduser = this.AssigneList.find(y => y.EmployeeId == x.UserId);
        this.SelectAssigneList.push({ "id": x.UserId, "itemName": tempassigneduser.EmployeeName })

      })

    })
  }


  //begning of save 

  SaveCrmOpportunityShared(): void {


    this.confirmationser.confirm('Please confirm..', 'Do you really want to save ?')
      .then((confirmed) => {
        if (confirmed == true) {

          let t = localStorage.getItem('userToken');
          t = 'Bearer' + t;

          if (this.OpportunitySharedList.length > 0) {
            this.CRM_OppertunityService.AddUpdateCrmOpportunityAssigned(t, this.OpportunitySharedList).subscribe(res => {
              this.OppoetunitySharedStatus = res;

              if (this.OppoetunitySharedStatus == true) {
                this.modalRef.hide();
                localStorage.setItem('SuccessfullMessage', "Successfully Update");
                this.showAlertMessage();

              }
            })
          }

        }
      }
      )
      .catch(() => console.log('User dismissed the dialog (e.g., by using ESC, clicking the cross icon, or clicking outside the dialog)'));

  }

  //End of save

  showAlertMessage() {

    if (localStorage.getItem('SuccessfullMessage')) {
      this.SuccessStatus = true;
      this.SuccessMessage = localStorage.getItem('SuccessfullMessage')
      localStorage.removeItem('SuccessfullMessage');
      this.myChild.setvisible();

    }


  }

  onActivate(componentReference) {


    //Below will subscribe to the searchItem emitter
    componentReference.searchItem.subscribe((data) => {


      this.Relode();
    });
  }

  Relode() {

    $('#tblcrmoppertunity').DataTable().destroy();
    this.GetCRMOppertunity()

  }



}
