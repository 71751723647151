<div class="body-content-area tabcontent" id="link1">
<div class="row">
    <div class="col-lg-9">
        <div class="row">
            <div class="col-lg-1 low-padding text-center">
                <!-- <img *ngIf="this.SelectedMenu.Image!=null" src={{this.SelectedMenu.Image}} class="body-icon" alt="Icon"/> -->
            </div>
            <div class="col-lg-10 clear-padding">
                <span class="page-title">Manage Designation</span>
                <small class="page-description"></small>
            </div>
        </div>
    </div>
</div>

<div *ngIf="!(this.CanViewState)">  
    <table id="tblDesignation" datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" class="table table-striped table-bordered text-center table-new row-border hover">
        <thead class="thead-blue text-center">
            <tr>
                <th>Sl No.</th>
                <th>Code</th>
                <th class="text-left">Designation</th>
                <th class="text-left">Grade</th>
                <th class="text-left">Level</th>
                <th class="text-left">Decision Making</th>
                <th>Action</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let D of Designations, let i = index">
                <td scope="row">{{i+1}}</td>
                <td>{{D.DesignationCode}}</td>
                <td class="text-left">{{D.DesignationName}}</td>
                <td class="text-left">{{D.GradeName}}</td>
                <td class="text-left">{{D.LevelName}}</td>
                <td class="text-left">{{D.DecisionName}}</td>
                <td>
                    <button *ngIf="this.SelectedMenu.CanEdit" type="button"  (click)="GetDesignationByid( D.DesignationId )" class="table-edit-btn"><i class="fas fa-edit"></i></button> 
                    <button *ngIf="this.SelectedMenu.CanDelete" type="button"  (click)="DeleteDesignationByid( D.DesignationId )"class="table-remove-btn"><i class="fas fa-trash-alt"></i></button>
                    <button *ngIf="this.SelectedMenu.CanView"   type="button" (click)="ViewDesignationByid( D.DesignationId )" class="table-view-button" type="button" ><i class="fas fa-eye"></i></button>
                    </td>
            </tr>
        </tbody>
    </table>
    
    <fieldset class="body-form-control">
        <app-dialog [(visible)]="this.SuccessStatus">
            <span class="successmsg">{{this.SuccessMessage}}</span>
        </app-dialog>
        
        <legend class="legend--">Designation Creation</legend>
        <form #DesignationForm="ngForm" (ngSubmit)="saveDesignation()">
            <div class="row">
                <div class="col-lg-4">
                    <div  class="col-body-border-right">
                    <div class="form-group row" >
                        <label class="col-sm-4 col-form-label clear-padding-right">Code</label>
                        <div class="col-sm-7 clear-left-padding">
                        <input type="text" [(ngModel)]="DesignationModel.DesignationCode" tabindex="1" maxlength="50" id ="DesignationCode"  name="DesignationCode" #DesignationCode="ngModel" required class="form-control form-control-custom form-control-custom-new" >
                        </div>
                        <div *ngIf="DesignationCode.invalid && (DesignationCode.dirty || DesignationCode.touched)" class="alert alert-danger">
                        <div *ngIf="DesignationCode.errors.required">Designation Code is required.</div>
                        <div *ngIf="DesignationCode.errors.maxlength">Designation Code is not more than 50 characters</div>
                        </div> 
                    </div>
                    <div class="form-group row">
                        <label  class="col-sm-4 col-form-label clear-padding-right">Level</label>
                        <div class="col-sm-7 clear-left-padding">
                            <select (ngModelChange)="onLevelChange($event)" [(ngModel)]="DesignationModel.LevelId" tabindex="4" id ="LevelId" class="form-control"  name="LevelId" #LevelId="ngModel" >
                                <option [value]="0" [disabled]=true >Select Level</option>
                                <option *ngFor="let L of Levels" [value]="L.LevelId">{{L.LevelName}}</option>
                            </select>
                        </div>
                        <div class="required-validation" *ngIf="DesignationModel.LevelId==0">Level is required</div>  
                    </div>
                    </div>  
                </div>
                <div  class="col-lg-4">
                    <div  class="col-body-border-right">
                    <div class="form-group row">
                        <label class="col-sm-4 col-form-label clear-padding-right">Name</label>
                        <div class="col-sm-7 clear-left-padding">
                        <input type="text" [(ngModel)]="DesignationModel.DesignationName" tabindex="2" maxlength="250" id ="DesignationName" (blur)="CheckName()" name="DesignationName" #DesignationName="ngModel" required class="form-control form-control-custom form-control-custom-new" >
                        </div>
                        <div *ngIf="this.Namecheck.length > 0" class="alert alert-danger">Designation Name is exist</div>
                        <div *ngIf="DesignationName.invalid && (DesignationName.dirty || DesignationName.touched)" class="alert alert-danger">
                        <div *ngIf="DesignationName.errors.required">Designation Name is required.</div>
                        <div *ngIf="DesignationName.errors.maxlength">Designation Name is not more than 250 characters</div>
                        </div> 
                    </div>
                    <div class="form-group row">
                        <label  class="col-sm-4 col-form-label clear-padding-right">Grade</label>
                        <div class="col-sm-7 clear-left-padding">
                            <select [(ngModel)]="DesignationModel.GradeId" tabindex="5" (blur)="CheckName()" id ="GradeId" class="form-control"  name="GradeId" #GradeId="ngModel" >
                                <option [value]="0" [disabled]=true >Select Grade</option>
                                <option *ngFor="let G of Grades" [value]="G.GradeId">{{G.GradeName}}</option>
                            </select>
                        </div>
                        <div class="required-validation" *ngIf="DesignationModel.GradeId==0">Grade is required</div>    
                    </div>
                    </div>
                </div>
                <div  class="col-lg-4">
                    <div class="form-group row">
                        <label  class="col-sm-4 col-form-label clear-padding-right">Decision Making</label>
                        <div class="col-sm-7 clear-left-padding">
                            <select  (ngModelChange)="onDecisionChange($event)" [(ngModel)]="DesignationModel.DecisionId" tabindex="3" id ="DecisionId" class="form-control"  name="DecisionId" #DecisionId="ngModel" >
                                <option [value]="0" [disabled]=true >Select Decision Making</option>
                                <option *ngFor="let DM of Decisions" [value]="DM.DecisionId">{{DM.DecisionName}}</option>
                            </select>
                        </div>
                        <div class="required-validation"  *ngIf="DesignationModel.DecisionId==0">Decision Making is required</div>
                    </div>
                    <div class="form-group row">
                    <label class="col-sm-6 col-form-label text-center clear-padding-right" *ngIf="!(this.IsDataIsInViewState)" >
                        <button type="submit" tabindex="5" class="btn btn-primary save-button" [disabled]="!(DesignationForm.valid && this.CheckNameExiststatus==true && DesignationModel.DecisionId !=0 && DesignationModel.LevelId  && DesignationModel.GradeId && this.DisableSave==true)" >Save</button>  
                    </label>
                    <div class="col-sm-6 col-form-label text-center">
                        <button type="button" tabindex="6" (click)="RefreshPage()" class="btn btn-danger cancle-button">Cancel</button>
                    </div>
                    </div>
                </div>
            </div>
        </form>
    </fieldset>
</div>
</div>