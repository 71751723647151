import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from '../../shared/user.service';
import { SalaryMasterService} from '../../shared/HR_ADMIN/Salary/SalaryMaster.service';
import { SalaryComponent} from  '../../shared/HR_ADMIN/Salary/SalaryComponentModel';
// Begning Confirmation
import { ConfirmationService } from '@jaspero/ng2-confirmations';
import { ResolveEmit } from '../../../app/resolve-emit';
import { ConfirmSettings } from '../../../app/confirm-settings';
import { Menu } from '../../shared/menu.model';
// end of Confirmation
import { ModalOptions,BsModalRef, BsModalService } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-manage-salary-component',
  templateUrl: './manage-salary-component.component.html',
  styleUrls: ['./manage-salary-component.component.css']
})

export class ManageSalaryComponentComponent implements OnInit 
{
  SalaryComponentModel : SalaryComponent= new SalaryComponent();
  userClaims : any;
  SalaryComponents : SalaryComponent[] = [];
  Component : any;
  SubMenues : Menu[]=[];
  Mod : any;
  Result : string;
  SuccessStatus : boolean=false;
  SuccessMessage : string ="";
  public tableWidget : any;
  componentId : any;
  base64textString : string;
  SalaryBenefitCheck : boolean = false;
  tempSalaryComponentadd : SalaryComponent =new SalaryComponent();
  // Begning Permission Portion    
  SelectedMenu : Menu;
  IsDataIsInViewState : boolean=false;
  CanViewState : boolean=false;
  DisableSave : boolean=false;
  // End of the permission portion
  //check for duplicate component code
  CheckcomponentCodeExiststatus : boolean=null;  
  Checkstatusedit : boolean = false;
  componentCodecheck : string = "";
  checkcomponent : any;
  checkComponentCodeval : string ="";
  //check for duplicate component code
  //check for duplicate component Name
  CheckNameExiststatus : boolean=null;  
  CheckNamestatusedit : boolean =false;
  componentNamecheck : string ="";
  checkcomponentName : any;
  checkComponentNameval : string ="";
  //check for duplicate component Name
  public modalRef : BsModalRef;
  public modelopt : ModalOptions;
  ShowPopup : boolean= false;
  SaveMsg : boolean= false;
  UpdateMsg : boolean= false;
  DeleteMsg : boolean= false;

  constructor(private SalaryMasterService: SalaryMasterService, private rout: Router, private _confirmation: ConfirmationService, private userService: UserService,private modalService: BsModalService)
  {
    this.modelopt=new ModalOptions();
    this.modelopt.class='heavy_modal';
    this.modalService.config= this.modelopt;
    this.modelopt.ignoreBackdropClick=true;
  }
  
  ngOnInit() 
  {
    // Begning Permission Portion 
    this.SubMenues=[];
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    let menuid = localStorage.getItem("menuidstor");
    this.userService.GetSubMenuList( t, menuid).then(x => 
    {
      this.Mod = x;
      Object.keys(this.Mod).forEach( key => 
      {
        // tslint:disable-next-line:no-unused-expression
        this.Mod[key];
        let menutemp = new Menu();
        menutemp = this.Mod[key];
        this.SubMenues.push(menutemp);
      });
      this.userService.SelectedMenu= this.SubMenues[0];
      this.rout.navigate([this.userService.SelectedMenu.RouteName]);
      this.SelectedMenu=this.userService.SelectedMenu;
      if(this.SelectedMenu.CanAdd)
      {
        this.IsDataIsInViewState=false;
      }
      else
      {
        this.IsDataIsInViewState=true;
      }
      if(this.SelectedMenu.CanView)
      {
        this.CanViewState=false;
      }
      else
      {
        this.CanViewState=true;
      }
    });
    // End of the permission portion
    this.DisableSave=true;
  }

  ngAfterViewInit(): void 
  {
    this.GetSalaryComponentMaster();
  }

  settings: ConfirmSettings | any = 
  {
    overlay: true,
    overlayClickToClose: true,
    showCloseButton: true,
    confirmText: 'Yes',
    declineText: 'No'
  };
  
  GetSalaryComponentMaster()
  {
    this.SalaryComponents=[];
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.SalaryMasterService.GetSalaryComponentMaster( t).then(x => 
    {
      this.Component = x;
      Object.keys(this.Component).forEach( key => 
      {
        this.Component[key];
        let Componenttemp = new SalaryComponent();
        Componenttemp = this.Component[key];
        this.SalaryComponents.push(Componenttemp);
      });
    });
  }
  
  GetSalaryComponentMasterByid(id,template)
  { 
    this.SalaryComponentModel.Value=null;
    // Begning of the permission portion
    this.IsDataIsInViewState=false;
    // End of the permision portion
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.SalaryMasterService.GetSalaryComponentMasterByid( t,id).then(x => 
    {
      if(this.ShowPopup==true)
      {
        this.modalRef.hide();
      }
      this.modalRef = this.modalService.show(template,this.modelopt);
      this.ShowPopup=true;
      this.SaveMsg=false;
      this.UpdateMsg=false;
      this.DeleteMsg=false;
      this.DisableSave=true;
      this.componentId = x;
      Object.keys(this.componentId).forEach( key => 
      {
        // tslint:disable-next-line:no-unused-expression
        this.componentId[key];
        let componenttemp = new SalaryComponent();
        componenttemp = this.componentId[key];
        this.SalaryComponentModel.ComponentId=componenttemp.ComponentId;
        this.SalaryComponentModel.ComponentCode=componenttemp.ComponentCode;
        this.SalaryComponentModel.OldComponentCode=componenttemp.ComponentCode;
        this.SalaryComponentModel.ComponentName=componenttemp.ComponentName;
        this.SalaryComponentModel.IsLongTermBenefit=componenttemp.IsLongTermBenefit;
        this.SalaryComponentModel.IsBenefit=componenttemp.IsBenefit;

        this.SalaryComponentModel.IsTaxable=componenttemp.IsTaxable;
        if(componenttemp.IsTaxable == true)
        {
          this.SalaryComponentModel.Value='Taxable';
        }
        this.SalaryComponentModel.IsExemptedFull=componenttemp.IsExemptedFull;
        if(componenttemp.IsExemptedFull == true)
        {
          this.SalaryComponentModel.Value='ExemptedFull';
        }
        this.SalaryComponentModel.ExemptedAmount=componenttemp.ExemptedAmount;
      });
      // Begin for check company code duplication
      this.checkComponentCodeval= this.SalaryComponentModel.ComponentCode;
      this.Checkstatusedit=true;
      this.CheckcomponentCodeExiststatus =true;
      this.componentCodecheck=""
      // End for check company code duplication
      // Begin for check company Name duplication
      this.checkComponentNameval= this.SalaryComponentModel.ComponentName;
      this.CheckNamestatusedit=true;
      this.CheckNameExiststatus =true;
      this.componentNamecheck=""
      // End for check company Name duplication
    });
  }

  viewSalaryComponentMasteByid(id,template)
  {
    //begin permission
    this.IsDataIsInViewState=true;
    //end permission
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.SalaryMasterService.GetSalaryComponentMasterByid( t,id).then(x => 
    {
      if(this.ShowPopup==true)
      {
        this.modalRef.hide();
      }
      this.modalRef = this.modalService.show(template,this.modelopt);
      this.ShowPopup=true;
      this.SaveMsg=false;
      this.UpdateMsg=false;
      this.DeleteMsg=false;

      this.componentId = x;
      Object.keys(this.componentId).forEach( key => 
      {
        // tslint:disable-next-line:no-unused-expression
        this.componentId[key];
        let componenttemp = new SalaryComponent();
        componenttemp = this.componentId[key];
        this.SalaryComponentModel.ComponentId=componenttemp.ComponentId;
        this.SalaryComponentModel.ComponentCode=componenttemp.ComponentCode;
        this.SalaryComponentModel.ComponentName=componenttemp.ComponentName;
        this.SalaryComponentModel.IsLongTermBenefit=componenttemp.IsLongTermBenefit;
        this.SalaryComponentModel.IsBenefit=componenttemp.IsBenefit;
        this.SalaryComponentModel.IsTaxable=componenttemp.IsTaxable;
        this.SalaryComponentModel.IsExemptedFull=componenttemp.IsExemptedFull;
        this.SalaryComponentModel.ExemptedAmount=componenttemp.ExemptedAmount;
      });
      // Begin for check company code duplication
      this.checkComponentCodeval= this.SalaryComponentModel.ComponentCode;
      this.Checkstatusedit=true;
      this.CheckcomponentCodeExiststatus =true;
      this.componentCodecheck=""
      // End for check company code duplication
      // Begin for check company Name duplication
      this.checkComponentNameval= this.SalaryComponentModel.ComponentName;
      this.CheckNamestatusedit=true;
      this.CheckNameExiststatus =true;
      this.componentNamecheck=""
      // End for check company Name duplication
    });
  }

  saveSalaryComponentMaster(template) :void
  {
    this.DisableSave=false;
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.SalaryComponentModel.IsActive=true;
    if(this.SalaryComponentModel.IsBenefit && this.SalaryComponentModel.IsLongTermBenefit)
    {        
      return;
    }
    if(this.SalaryComponentModel.ComponentId > 0)
    {
      this.SalaryMasterService.UpdateSalaryComponentMaster(t ,this.SalaryComponentModel).subscribe(res=>
      {
        this.componentId=res;
        this.SalaryComponentModel.ComponentId=0;  
        if(this.componentId>0)
        {
          this.GetSalaryComponentMaster();
          this.modalRef.hide();
          this.ShowPopup = false;
          this.SaveMsg = false;
          this.UpdateMsg = true;
          this.DeleteMsg=false;
          this.modalRef = this.modalService.show(template,this.modelopt);
          this.DisableSave=true;
        }
      });                  
    }
    else
    {
      this.SalaryMasterService.AddSalaryComponentMaster(t ,this.SalaryComponentModel).subscribe(res=>
      {
        this.componentId=res;
        this.SalaryComponentModel.ComponentId=0;              
        if(this.componentId > 0)
        {
          this.GetSalaryComponentMaster();
          this.modalRef.hide();
          this.ShowPopup = false;
          this.SaveMsg = true;
          this.UpdateMsg = false;
          this.DeleteMsg =false;
          this.modalRef = this.modalService.show(template,this.modelopt);
          this.DisableSave=true;
        }
      }); 
    }
  }

  DeleteSalaryComponentMasterByid(componentid : number,template) :void
  {
    if(this.SelectedMenu.CanAdd)
    {
     this.IsDataIsInViewState=false;
    }
    else
    {
      this.IsDataIsInViewState=true;
    }
    this._confirmation.create('Confirm to Deleate', 'Press Yes to Delete', this.settings)
      .subscribe((ans: ResolveEmit) => 
      {
        if (ans.resolved == true) 
        {
          let t =  localStorage.getItem('userToken');
          t = 'Bearer' + t;
          this.SalaryComponentModel.IsActive=true;
          if( componentid > 0)
          {
            this.SalaryMasterService.DeleteSalaryComponentMaster(t ,componentid).subscribe(res=>
            {
              this.componentId=res;
              this.SalaryComponentModel.ComponentId=0;                                  
              if(this.componentId >0)
              {
                this.modalRef.hide();
                this.ShowPopup = false;
                this.SaveMsg = false;
                this.UpdateMsg = false;
                //this.DeleteMsg = true;
                this.modalRef = this.modalService.show(template,this.modelopt);
                this.DeleteMsg = true;
                this.DisableSave=true;
                this.GetSalaryComponentMaster();
              }   
            });                  
          }
        }
      })
  }
  // begning of check duplicate componentcode
  CheckSalarycomponentcode()
  {
    this.DisableSave=false;
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    if(!this.Checkstatusedit)
    {
      this.SalaryMasterService.CheckSalaryComponentcode( t, this.SalaryComponentModel.ComponentCode).then(x => 
      {
        this.checkcomponent = x;
        if( this.checkcomponent.length >0)
        {
          this.CheckcomponentCodeExiststatus =false;
          this.componentCodecheck="Exist"
        }
        else
        {
          this.CheckcomponentCodeExiststatus =true;
          this.componentCodecheck="";
        }
        this.DisableSave=true;
     });
    }
    else
    {
      if(this.SalaryComponentModel.ComponentCode !=this.checkComponentCodeval )
      {
        this.SalaryMasterService.CheckSalaryComponentcode( t, this.SalaryComponentModel.ComponentCode).then(x => 
        {
          this.checkcomponent = x;
          if( this.checkcomponent.length >0)
          {
            this.CheckcomponentCodeExiststatus =false;
            this.componentCodecheck="Exist"
          }
          else
          {
            this.CheckcomponentCodeExiststatus =true;
            this.componentCodecheck="";
          }
          this.DisableSave=true;
        });
      }
      else
      {
        this.CheckcomponentCodeExiststatus =true;
        this.componentCodecheck="";
        this.DisableSave=true;
      }
    }
  }
  // end of duplicate component code

  // begning of check duplicate componentcode
  CheckName()
  {
    this.DisableSave=false;
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    if(!this.CheckNamestatusedit)
    {
      this.SalaryMasterService.CheckSalaryComponentName( t, this.SalaryComponentModel.ComponentName).then(x => 
      {
        this.checkcomponentName = x;
        if( this.checkcomponentName.length >0)
        {
          this.CheckNameExiststatus =false;
          this.componentNamecheck="Exist"
        }
        else
        {
          this.CheckNameExiststatus =true;
          this.componentNamecheck="";
        }
        this.DisableSave=true;
     });
    }
    else
    {
      if(this.SalaryComponentModel.ComponentName !=this.checkComponentNameval )
      {
        this.SalaryMasterService.CheckSalaryComponentName( t, this.SalaryComponentModel.ComponentName).then(x => 
        {
          this.checkcomponentName = x;
          if( this.checkcomponentName.length >0)
          {
            this.CheckNameExiststatus =false;
            this.componentNamecheck="Exist"
          }
          else
          {
            this.CheckNameExiststatus =true;
            this.componentNamecheck="";
          }
          this.DisableSave=true;
        });
      }
      else
      {
        this.CheckNameExiststatus =true;
        this.componentNamecheck="";
        this.DisableSave=true;
      }
    }
  }
  // end of duplicate component Name

  RefreshPage()
  {
    this.modalRef.hide();
  }

  ADDClick(template)
  {
    if(this.ShowPopup==true)
    {
      this.modalRef.hide();
    }
    this.SalaryComponentModel.ComponentId=null;
    this.SalaryComponentModel.ComponentCode=null;
    this.SalaryComponentModel.ComponentName=null;
    this.SalaryComponentModel.IsLongTermBenefit=false;
    this.SalaryComponentModel.IsBenefit=false;
    this.SalaryComponentModel.IsTaxable=false;
    this.SalaryComponentModel.IsExemptedFull=false;
    this.SalaryComponentModel.ExemptedAmount=null;
    this.SalaryComponentModel.Value=null;
    this.IsDataIsInViewState=false;
    this.modalRef = this.modalService.show(template,this.modelopt);
    this.ShowPopup=true;
    this.SaveMsg=false;
    this.UpdateMsg=false;
    this.DeleteMsg=false;
    this.DisableSave=true;
  }

  IsTaxableChange(evt)
  {
    var target = evt.target;
    if(target.checked)
    {           
      this.SalaryComponentModel.IsTaxable=true;
      this.SalaryComponentModel.IsExemptedFull=false; 
      this.SalaryComponentModel.Value='Taxable';   
      this.SalaryComponentModel.ExemptedAmount=null;     
    }
    else
    {
      this.SalaryComponentModel.IsTaxable=false;
      this.SalaryComponentModel.Value=null;
    }
  }

  IsExemptedFullchange(evt)
  {
    var target = evt.target;
    if(target.checked)
    {   
      this.SalaryComponentModel.IsTaxable=false;        
      this.SalaryComponentModel.IsExemptedFull=true;
      this.SalaryComponentModel.Value='ExemptedFull';   
    }
    else
    {
      this.SalaryComponentModel.IsExemptedFull=false;
      this.SalaryComponentModel.Value=null;
    }
  }

  IsBenefitchange(evt)
  {
    var target = evt.target;
    if(target.checked)
    {   
      this.SalaryComponentModel.IsBenefit=true;        
    }
    else
    {
      this.SalaryComponentModel.IsBenefit=false;
    }
  }
}
