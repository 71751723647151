<div class="body-content-area tabcontent" id="link1">
  <div class="row">
    <div class="col-lg-9">
      <div class="row">
        <div class="col-lg-1 low-padding text-center">
          <!-- <img *ngIf="this.SelectedMenu.Image!=null" src={{this.SelectedMenu.Image}} class="body-icon" alt="Icon"/> -->
        </div>
        <div class="col-lg-10 clear-padding">
          <div>
            <span class="page-title">Employee CTC Details</span>
            <small class="page-description"></small>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="!(this.CanViewState)">
    <fieldset class="body-form-control" >
      <legend class="legend--">Employee CTC Details</legend>
      <table id="tblEmployeeStandardSalary" *ngIf="(Employees.length > 0)" datatable [dtOptions]="dtOptions" class="table table-striped table-bordered text-center table-new row-border hover">
        <thead class="thead-blue text-center" style="width: 100%;">
          <tr>
            <th style="width: 5%;">Sl No.</th>
            <th style="width: 5%;">Code</th>
            <th style="width: 20%;" class="text-left">Name</th>
            <th style="width: 20%;" class="text-left">Company</th>
            <th style="width: 10%;" class="text-left">Branch</th>
            <th style="width: 15%;" class="text-left">Department</th>
            <th style="width: 15%;" class="text-left">Designation</th>
            <!-- <th style="width: 10%;" class="text-right">CTC Amount(Monthly)</th>
            <th style="width: 10%;" class="text-right">Net Take Amount(Monthly)</th> -->
            <th style="width: 10%;">Action</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let emp of Employees, let i = index">
            <td scope="row">{{i+1}}</td>
            <td>{{emp.EmployeeCode}}</td>
            <td class="text-left">{{emp.EmployeeName}}</td>
            <td class="text-left">{{emp.CompanyName}}</td>
            <td class="text-left">{{emp.BranchName}}</td>
            <td class="text-left">{{emp.DepartmentName}}</td>
            <td class="text-left">{{emp.DesignationName}}</td>
            <!-- <td class="text-right">{{emp.CTCAmount| number:'3.0-0'}}</td>
            <td class="text-right">{{emp.NetTakeHome| number:'3.0-0'}}</td> -->
            <td>
              <button *ngIf="this.SelectedMenu.CanView" type="button" (click)="GetEmployeeStandardSalaryByEmployeeId( emp.EmployeeId,emp.PtaxStateId,template)" class="table-view-button"><i class="fas fa-eye"></i></button>
              <button *ngIf="this.SelectedMenu.CanEdit" type="button" (click)="GetEmployeeStandardSalaryModificationByEmployeeId( emp.EmployeeId,emp.PtaxStateId,template)" class="table-edit-btn"><i class="fas fa-edit"></i></button> 
            </td>
          </tr>
        </tbody>
      </table>
    </fieldset> 

    <div style=" box-align: center; width: 1500px;" >
      <ng-template #template>
        <div class="modal-header" style="background: #2a9bd7; max-width: 1500px;"> 
          <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()" style="color:#fff;">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div style="text-align: center; max-width: 1500px;">
          <div *ngIf="UpdateMsg" style="color: #dc3545; text-align: center;font-size: 15px; font-weight: bold;">Updated Successfully</div>

          <div style=" width: 100%;" *ngIf="ShowPopup">
            <fieldset *ngIf="StandardSalary.length > 0" class="body-form-control" >
              <legend class="legend--">(Salary BrakUp) CTC Details</legend>
              <div class="row">
                <div class="col-lg-4">
                  <div class="col-body-border-right">
                    <div class="form-group row">
                      <label class="col-sm-4 col-form-label clear-padding-right">Emp code</label>
                      <div class="col-sm-7 clear-left-padding">
                        :&nbsp;&nbsp;{{this.StandardSalaryModel.EmployeeCode}} 
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-lg-4">
                  <div class="form-group row">
                    <label class="col-sm-4 col-form-label clear-padding-right">Employee Name</label>
                    <div class="col-sm-7 clear-left-padding">
                      :&nbsp;&nbsp;{{this.StandardSalaryModel.EmployeeName}}
                    </div>
                  </div>
                </div>
              </div>
      
              <div class="modal-body">
                <div *ngIf="StandardSalary.length > 0" >
                  <table id="tblComponent" class="table table-striped table-bordered text-left table-new row-border hover">
                    <thead class="thead-blue text-center">
                      <tr>
                        <th>Component</th>                        
                        <th class="text-right">Monthly Amount(Rs)</th>  
                        <th class="text-right">Yearly Amount(Rs)</th>                     
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let sc of StandardSalary, let i = index">
                        <td *ngIf="sc.IsBenefit==false">
                          <span>{{sc.SalaryComponentName }}</span>
                          <input type="text" hidden="hidden" [(ngModel)]="sc.RefId" id ="RefId" name="RefId" #RefId="ngModel">
                          <input type="text" hidden="hidden" [(ngModel)]="sc.CurrentDate" id ="CurrentDate" name="CurrentDate" #CurrentDate="ngModel">
                          <input type="text" hidden="hidden" [(ngModel)]="sc.CTCAmount" id ="CTCAmount" name="CTCAmount" #CTCAmount="ngModel">
                        </td>
                        <td *ngIf="sc.IsBenefit==false" class="text-right">
                          {{sc.Amount | number:'3.0-0'}}
                        </td>
                        <td *ngIf="sc.IsBenefit==false" class="text-right">
                            {{sc.AmountYearly | number:'3.0-0'}}
                        </td>
                      </tr>
                      <tr style="background-color:rgb(207, 250, 250)">
                        <td>Total Gross Salary : (A)</td>
                        <td class="text-right">{{this.ComponentSubTotal | number:'3.0-0'}} </td>
                        <td class="text-right">{{this.ComponentSubTotal * 12 | number:'3.0-0'}} </td>
                      </tr>
                      <tr *ngFor="let sc of StandardSalary, let i = index">
                        <td *ngIf="sc.IsBenefit==true">
                          <span>{{sc.SalaryComponentName}}</span>
                          <input type="text" hidden="hidden" [(ngModel)]="sc.RefId " id ="RefId" name="RefId" #RefId="ngModel">
                        </td>                            
                        <td *ngIf="sc.IsBenefit==true" class="text-right">
                            {{sc.Amount | number:'3.0-0'}}
                        </td>
                        <td *ngIf="sc.IsBenefit==true" class="text-right">
                            {{sc.AmountYearly | number:'3.0-0'}}
                        </td>
                      </tr>
                      <tr *ngIf="this.PFCont > 0">
                        <td>PF Employer's Contribution</td>
                        <td class="text-right">{{this.PFCont | number:'3.0-0'}} </td>
                        <td class="text-right">{{this.PFCont * 12 | number:'3.0-0'}} </td>
                      </tr>
                      <tr *ngIf="this.ESICont > 0">
                        <td>ESI Employer's Contribution</td>
                        <td class="text-right">{{this.ESICont | number:'3.0-0'}} </td>
                        <td class="text-right">{{this.ESICont | number:'3.0-0'}} </td>
                      </tr>
                      <tr style="background-color:rgb(207, 250, 250)">
                        <td>Long Term benefits : (B)</td>
                        <td class="text-right">{{this.Longtermbenefits + this.PFCont + this.ESICont | number:'3.0-0'}} </td>
                        <td class="text-right">{{this.Longtermbenefits * 12 + this.PFCont * 12 + this.ESICont * 12 | number:'3.0-0'}} </td>
                      </tr>
                      <tr style="background-color:rgb(162, 162, 235)">
                        <td>Computed Compensation CTC : (A+B)</td>
                        <td class="text-right">{{(this.Longtermbenefits + this.ComponentSubTotal + this.PFCont + this.ESICont) | number:'3.0-0'}} </td>
                        <td class="text-right">{{(this.Longtermbenefits * 12 + this.ComponentSubTotal * 12 + this.PFCont * 12 + this.ESICont * 12) | number:'3.0-0'}} </td>
                      </tr>
                      <tr *ngIf="this.PFDed > 0">
                        <td>Less PF Employee's Contribution</td>
                        <td class="text-right">{{this.PFDed | number:'3.0-0'}} </td>
                        <td class="text-right">{{(this.PFDed * 12) | number:'3.0-0'}} </td>
                      </tr>
                      <tr *ngIf="this.ESIDed > 0">
                        <td>Less ESI Employee's Contribution</td>
                        <td class="text-right">{{this.ESIDed | number:'3.0-0'}} </td>
                        <td class="text-right">{{(this.ESIDed * 12) | number:'3.0-0'}} </td>
                      </tr>
                      <tr *ngIf="this.P_Tax > 0">
                        <td>Professional Tax Amount </td>
                        <td class="text-right">{{this.P_Tax | number:'3.0-0'}} </td>
                        <td class="text-right">{{(this.P_Tax * 12) | number:'3.0-0'}} </td>
                      </tr>
                      <tr style="background-color:rgb(207, 250, 250)">
                        <td>Total Deduction : (C)</td>
                        <td class="text-right">{{(this.PFDed + this.ESIDed + this.P_Tax) | number:'3.0-0'}} </td>
                        <td class="text-right">{{((this.PFDed + this.ESIDed + this.P_Tax) * 12) | number:'3.0-0'}} </td>
                      </tr>
                      <tr style="background-color:rgb(162, 162, 235)">
                        <td>Take Home Before Income tax Deduction : (A-C)</td>
                        <td class="text-right">
                          {{(this.ComponentSubTotal)-(this.PFDed + this.ESIDed + this.P_Tax) | number:'3.0-0'}} 
                        </td>
                        <td class="text-right">
                          {{(((this.ComponentSubTotal)-(this.PFDed + this.ESIDed + this.P_Tax)) * 12) | number:'3.0-0'}} 
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </fieldset>
      
            <fieldset *ngIf="ModificationStandardSalary.length > 0" class="body-form-control" >
              <legend class="legend--">(Salary BrakUp) CTC Modification</legend>
              <div class="row">
                <div class="col-lg-4">
                  <div class="col-body-border-right">
                    <div class="form-group row">
                      <label class="col-sm-4 col-form-label clear-padding-right">Emp code</label>
                      <div class="col-sm-7 clear-left-padding">
                        :&nbsp;&nbsp;{{this.StandardSalaryModel.EmployeeCode}} 
                      </div>
                    </div>
                    <div class="form-group row">
                      <label class="col-sm-4 col-form-label clear-padding-right">EPF Applicable</label>
                      <div class="col-sm-7 clear-left-padding">
                          <input type="checkbox" [(ngModel)]="this.StandardSalaryModel.ModificationEPFApplicable" id ="EPFApplicable" tabindex="1" name="EPFApplicable" #EPFApplicable="ngModel" (ngModelChange)="oncheckedEPFApplicableChange()" class="form-control form-control-custom form-control-custom-new">
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-lg-4">
                  <div class="col-body-border-right">
                    <div class="form-group row">
                      <label class="col-sm-4 col-form-label clear-padding-right">Employee Name</label>
                      <div class="col-sm-7 clear-left-padding">
                        :&nbsp;&nbsp;{{this.StandardSalaryModel.EmployeeName}}
                      </div>
                    </div>
                    <div class="form-group row">
                      <label class="col-sm-4 col-form-label clear-padding-right">ESI Applicable</label>
                      <div class="col-sm-7 clear-left-padding">
                          <input type="checkbox" [(ngModel)]="this.StandardSalaryModel.ModificationESIApplicable" tabindex="2" id ="ESIApplicable" name="ESIApplicable" #ESIApplicable="ngModel" (ngModelChange)="oncheckedESIApplicableChange()" class="form-control form-control-custom form-control-custom-new">
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-lg-4">
                  <div class="form-group row">
                    <label class="col-sm-4 col-form-label clear-padding-right">Applicable Date</label>
                    <div class="col-sm-7 clear-left-padding">
                      <input type="date" name="ModificationApplicableFrom" [ngModel]="StandardSalaryModel.ModificationApplicableFrom | date:'yyyy-MM-dd'" (ngModelChange)="dateChanged($event)" #ModificationApplicableFrom="ngModel" required />
                      <label *ngIf="(!validdate)" class="required-validation">Select Valid Date</label>
                    </div>
                  </div>
                  <div class="form-group row">
                    <label class="col-sm-4 col-form-label clear-padding-right">PTAX Applicable</label>
                    <div class="col-sm-7 clear-left-padding">
                        <input type="checkbox" [(ngModel)]="this.StandardSalaryModel.ModificationPTAXApplicable" tabindex="3" id ="PTAXApplicable" name="PTAXApplicable" #PTAXApplicable="ngModel" (ngModelChange)="oncheckedPTAXApplicableChange()" class="form-control form-control-custom form-control-custom-new">
                        <select [(ngModel)]="StandardSalaryModel.PtaxStateId" tabindex="3" (ngModelChange)="onPtaxStateChange()" class="form-control" id="PtaxStateId" name="PtaxStateId" #PtaxStateId="ngModel" *ngIf="(this.DisableState)">
                          <option [value]="0">Select Ptax State</option>
                          <option *ngFor="let st of States" [value]="st.StateID">{{st.StateName}}</option>
                      </select>
                    </div>
                    <div *ngIf="(this.DisableState)">
                      <div class="required-validation" *ngIf="StandardSalaryModel.PtaxStateId == 0">PTAX State is required</div>
                    </div>
                  </div>
                </div>
              </div>
      
              <div class="modal-body">
                <div *ngIf="ModificationStandardSalary.length > 0" >
                  <table id="tblComponent" class="table table-striped table-bordered text-left table-new row-border hover">
                    <thead class="thead-blue text-center">
                      <tr>
                        <th>Component</th>                        
                        <th class="text-right">Monthly Amount(Rs)</th>  
                        <th class="text-right">Modification Amount(Rs)</th>                     
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let sc of ModificationStandardSalary, let i = index">
                        <td *ngIf="sc.IsBenefit==false">
                          <span>{{sc.SalaryComponentName }}</span>
                          <input type="text" hidden="hidden" [(ngModel)]="sc.RefId" id ="RefId" name="RefId" #RefId="ngModel">
                          <input type="text" hidden="hidden" [(ngModel)]="sc.CurrentDate" id ="CurrentDate" name="CurrentDate" #CurrentDate="ngModel">
                          <input type="text" hidden="hidden" [(ngModel)]="sc.CTCAmount" id ="CTCAmount" name="CTCAmount" #CTCAmount="ngModel">
                        </td>
                        <td *ngIf="sc.IsBenefit==false" class="text-right">{{sc.Amount | number:'3.0-0'}}</td>
                        <td *ngIf="sc.IsBenefit==false" class="text-right">
                          <input type="text" pattern="[0-9]+" (blur)="onBasicChangevaluechange()" [(ngModel)]="sc.ModificationAmount" id="ModificationAmount_{{i}}" name="ModificationAmount_{{i}}" #ModificationAmount="ngModel" class="form-control form-control-custom form-control-custom-new">
                          <div *ngIf="ModificationAmount.invalid && (ModificationAmount.dirty || ModificationAmount.touched)" class="required-validation">
                            <div *ngIf="ModificationAmount.errors?.pattern">Amount should be valid.</div>
                          </div>
                        </td>
                      </tr>
                      <tr style="background-color:rgb(207, 250, 250)">
                        <td>Total Gross Salary : (A)</td>
                        <td class="text-right">{{this.ComponentSubTotal | number:'3.0-0'}}</td>
                        <td class="text-right">{{this.ModificationComponentSubTotal | number:'3.0-0'}}</td>
                      </tr>
                      <tr *ngFor="let sc of ModificationStandardSalary, let i = index">
                        <td *ngIf="sc.IsBenefit==true">
                          <span>{{sc.SalaryComponentName}}</span>
                          <input type="text" hidden="hidden" [(ngModel)]="sc.RefId " id ="RefId" name="RefId" #RefId="ngModel">
                        </td>                            
                        <td *ngIf="sc.IsBenefit==true" class="text-right">{{sc.Amount | number:'3.0-0'}}</td>
                        <td *ngIf="sc.IsBenefit==true" class="text-right">
                          <input type="text" pattern="[0-9]+" (blur)="onBasicChangevaluechange()" [(ngModel)]="sc.ModificationAmount" id="ModificationAmount_{{i}}" name="ModificationAmount_{{i}}" #ModificationAmount="ngModel" class="form-control form-control-custom form-control-custom-new">
                          <div *ngIf="ModificationAmount.invalid && (ModificationAmount.dirty || ModificationAmount.touched)" class="required-validation">
                            <div *ngIf="ModificationAmount.errors?.pattern">Deduction Amount should be valid.</div>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>PF Employer's Contribution</td>
                        <td class="text-right">{{this.PFCont | number:'3.0-0'}} </td>
                        <td class="text-right">{{this.ModificationPFCont | number:'3.0-0'}} </td>
                      </tr>
                      <tr>
                        <td>ESI Employer's Contribution</td>
                        <td class="text-right">{{this.ESICont | number:'3.0-0'}} </td>
                        <td class="text-right">{{this.ModificationESICont | number:'3.0-0'}} </td>
                      </tr>
                      <tr style="background-color:rgb(207, 250, 250)">
                        <td>Long Term benefits : (B)</td>
                        <td class="text-right">{{this.Longtermbenefits + this.PFCont + this.ESICont | number:'3.0-0'}} </td>
                        <td class="text-right">{{this.ModificationLongtermbenefits + this.ModificationPFCont + this.ModificationESICont | number:'3.0-0'}} </td>
                      </tr>
                      <tr style="background-color:rgb(162, 162, 235)">
                        <td>Computed Compensation CTC : (A+B)</td>
                        <td class="text-right">{{(this.Longtermbenefits + this.ComponentSubTotal + this.PFCont + this.ESICont) | number:'3.0-0'}} </td>
                        <td class="text-right">{{(this.ModificationLongtermbenefits + this.ModificationComponentSubTotal + this.ModificationPFCont + this.ModificationESICont) | number:'3.0-0'}} </td>
                      </tr>
                      <tr>
                        <td>Less PF Employee's Contribution</td>
                        <td class="text-right">{{this.PFDed | number:'3.0-0'}} </td>
                        <td class="text-right">{{this.ModificationPFDed | number:'3.0-0'}} </td>
                      </tr>
                      <tr>
                        <td>Less ESI Employee's Contribution</td>
                        <td class="text-right">{{this.ESIDed | number:'3.0-0'}} </td>
                        <td class="text-right">{{this.ModificationESIDed | number:'3.0-0'}} </td>
                      </tr>
                      <tr>
                        <td>Professional Tax Amount </td>
                        <td class="text-right">{{this.P_Tax | number:'3.0-0'}} </td>
                        <td class="text-right">{{this.ModificationP_Tax | number:'3.0-0'}} </td>
                      </tr>
                      <tr style="background-color:rgb(207, 250, 250)">
                        <td>Total Deduction : (C)</td>
                        <td class="text-right">{{(this.PFDed + this.ESIDed + this.P_Tax) | number:'3.0-0'}} </td>
                        <td class="text-right">{{(this.ModificationPFDed + this.ModificationESIDed + this.ModificationP_Tax) | number:'3.0-0'}} </td>
                      </tr>
                      <tr style="background-color:rgb(162, 162, 235)">
                        <td>Take Home Before Income tax Deduction : (A-C)</td>
                        <td class="text-right">
                          {{(this.ComponentSubTotal)-(this.PFDed + this.ESIDed + this.P_Tax) | number:'3.0-0'}} 
                        </td>
                        <td class="text-right">
                          {{(this.ModificationComponentSubTotal)-(this.ModificationPFDed + this.ModificationESIDed + this.ModificationP_Tax) | number:'3.0-0'}}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div class="form-group row">
                <label class="col-sm-6 col-form-label text-center clear-padding-right">
                  <button type="submit" (click)="UpdateEmployeeStandardSalary(template)" class="btn btn-primary save-button" [disabled]="!(this.validdate==true && this.DisableSave==true)">Modified</button>  
                </label>
                <div class="col-sm-6 col-form-label text-left">
                  <button type="button" (click)="RefreshPage()" class="btn btn-danger cancle-button">Refresh Page</button>
                </div>
              </div>
            </fieldset>
          
          </div>


          <button type="button" aria-label="Close" (click)="modalRef.hide()" style="background: #2a9bd7; outline: 0px none; border: 0px none; color: #fff; padding: 5px 10px; border-radius: 3px;">
            <span>Close</span>
          </button>             
        </div>
      </ng-template>
    </div>
  </div>
</div>