import { Component, OnInit } from '@angular/core';
import { Model_CRM_Role } from '../../../shared/CRM/MICECRM/Model_CRM_Role'
import { Router } from '@angular/router';
import { Menu } from '../../../shared/menu.model';
import { CrmUserRollService } from '../../../shared/CRM/MICECRM/crm-user-roll.service';
import { Model_CRM_Roll_Menus } from '../../../shared/CRM/MICECRM/Crm_Roll_Menu';
import { CrmRollMenuService } from '../../../shared/CRM/MICECRM/crm-roll-menu.service';
import { MenuMasterModel } from '../../../shared/HR_ADMIN/UserMaster/MenuMasterModel.Model';


@Component({
  selector: 'app-crm-childmenu-roll',
  templateUrl: './crm-childmenu-roll.component.html',
  styleUrls: ['./crm-childmenu-roll.component.css']
})
export class CrmChildmenuRollComponent implements OnInit {

  CrmRoles: Model_CRM_Role[] = [];
  SelectedRoll: Model_CRM_Role;

  Crm_Menu_Roll: Model_CRM_Roll_Menus[] = [];

  // Begning Permission Portion    
  SelectedMenu: Menu;
  IsDataIsInViewState: boolean = false;
  CanViewState: boolean = false;
  DisableSave: boolean = false;
  // End of the permission portion

  SuccessMessage: string = "";
  SuccessStatus: boolean = false;


  emp: any[] = [];
  Rolls: any[] = [];

  Menues: any[] = [];



  RoleMaster: any;
  RoleMasterId: any;

  moduleid: number;


  ModelCrmRollMenues: Model_CRM_Roll_Menus[];

  ModelCrmMenu: Model_CRM_Roll_Menus;

  UserRoleId: any;

  MenuMasters: MenuMasterModel[] = [];
  MenuMaster: any;

  selectedMenuRoll = [];

  RollSettings = {}
  Menuettings = {}

  selectedRoles = []

  constructor(private CrmURS: CrmUserRollService, private rout: Router,
    private CrmRMS: CrmRollMenuService) {
    this.SelectedRoll = new Model_CRM_Role();
    this.ModelCrmMenu = new Model_CRM_Roll_Menus();
  }

  ngOnInit() {
    // this.rout.navigate([this.userService.selectedCrmMenu.RouteName]);
    if (localStorage.getItem('SuccessfullMessage')) {
      this.SuccessStatus = true;
      this.SuccessMessage = localStorage.getItem('SuccessfullMessage')
      localStorage.removeItem('SuccessfullMessage');
    }

    this.GetcrmRolls();

    this.RollSettings = {
      singleSelection: true,
      text: 'Select Rolls',
      enableSearchFilter: true,
      showCheckbox: false
    }

    this.Menuettings = {
      singleSelection: true,
      text: 'Select Menues',
      enableSearchFilter: true,
      showCheckbox: false
    }
    this.moduleid = Number(localStorage.getItem("moduleidstor"));


  }

  onItemSelectRoll(item: any) {

    let Rollid = item['id'];
    this.ModelCrmMenu.Role_Id = Rollid;
    this.DisableSave = true;
    //this.GetCRMRollMainMenu(this.ModelCrmMenu.Role_Id);  
    this.moduleid = Number(localStorage.getItem("moduleidstor"));
    this.GetMenuByRoleId(this.ModelCrmMenu.Role_Id, this.moduleid);
    this.selectedMenuRoll = [{ "id": 0, "itemName": 'Select Menues' }];
    this.Crm_Menu_Roll = [];
  }
  OnItemDeSelectRoll(item: any) { }
  onSelectAllRoll(items: any) { }
  onDeSelectAllRoll(items: any) { }

  onItemSelectMenu(item: any) {

    let ParentId = item['id'];
    this.ModelCrmMenu.ParentId = ParentId;
    this.DisableSave = true;

    //this.GetCRMRollMainMenu(this.ModelCrmMenu.Role_Id);  
    this.GetCRMRollChildMenu(this.ModelCrmMenu.Role_Id, this.ModelCrmMenu.ParentId);
  }
  OnItemDeSelectMenu(item: any) { }
  onSelectAllMenu(items: any) { }
  onDeSelectAllMenu(items: any) { }





  GetcrmRolls() {
    let t = localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.emp = [];
    this.CrmURS.GetCRMRoles(t).then(x => {
      this.RoleMaster = x;
      Object.keys(this.RoleMaster).forEach(key => {
        this.RoleMaster[key];
        let crmemptemp = new Model_CRM_Role();
        crmemptemp = this.RoleMaster[key];
        //this.RoleMasterModel.ModuleIds=SalMaptemp.ModuleIds;
        //this.CrmEmp.push(crmemptemp);
        this.CrmRoles.push(crmemptemp);
        this.Rolls.push({ "id": crmemptemp.Role_ID, "itemName": crmemptemp.RoleName });
      });
    });

  }

  GetMenuByRoleId(RoleId, ModuleId) {
    let t = localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.MenuMasters = [];
    this.Menues = [];
    this.CrmURS.GetMenuByRoleId(t, RoleId, ModuleId).then(x => {
      this.MenuMaster = x;
      Object.keys(this.MenuMaster).forEach(key => {
        // tslint:disable-next-line:no-unused-expression
        this.MenuMaster[key];
        let MenuMastertemp = new MenuMasterModel();
        MenuMastertemp = this.MenuMaster[key];
        this.MenuMasters.push(MenuMastertemp);
        this.Menues.push({ "id": MenuMastertemp.MenuID, "itemName": MenuMastertemp.MenuTitle });
      });
    });
  }


  GetCRMRollChildMenu(rollid: number, meniid: number) {

    let t = localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.Crm_Menu_Roll = [];
    // this.ModelCrmMenu.Role_Id = 0;
    this.CrmRMS.GetCRMChildMenuRoll(t, rollid, meniid).then(x => {
      this.RoleMaster = x;

      Object.keys(this.RoleMaster).forEach(key => {

        this.RoleMaster[key];
        let crmemptemp = new Model_CRM_Roll_Menus();
        crmemptemp = this.RoleMaster[key];
        if (crmemptemp.CanAdd == true) {
          crmemptemp.ViewCanAdd = 'CanAdd';
        }

        if (crmemptemp.CanEdit == true) {
          crmemptemp.ViewCanEdit = 'CanEdit';
        }

        if (crmemptemp.CanDelete == true) {
          crmemptemp.ViewCanDelete = 'CanDelete';
        }
        if (crmemptemp.CanApprove == true) {
          crmemptemp.ViewCanApprove = 'CanApprove';
        }
        if (crmemptemp.CanReject == true) {
          crmemptemp.ViewCanReject = 'CanReject';
        }
        if (crmemptemp.CanTransfer == true) {
          crmemptemp.ViewCanTransfer = 'CanTransfer';
        }
        if (crmemptemp.CanView == true) {

          crmemptemp.ViewCanView = 'CanView';
        }


        this.Crm_Menu_Roll.push(crmemptemp);

      });

    });

  }

  OnChangeCanAdd(evt, i: number) {
    this.Crm_Menu_Roll[i];
    var target = evt.target;
    if (target.checked) {
      this.Crm_Menu_Roll[i].CanAdd = true;
      this.Crm_Menu_Roll[i].ViewCanAdd = "CanAdd";
    }
    else {
      this.Crm_Menu_Roll[i].CanAdd = false;
      this.Crm_Menu_Roll[i].ViewCanAdd = "CanAdd";
    }
  }

  OnChangeCanEdit(evt, i: number) {
    this.Crm_Menu_Roll[i];
    var target = evt.target;
    if (target.checked) {
      this.Crm_Menu_Roll[i].CanEdit = true;
      this.Crm_Menu_Roll[i].ViewCanEdit = "CanEdit";
    }
    else {
      this.Crm_Menu_Roll[i].CanEdit = false;
      this.Crm_Menu_Roll[i].ViewCanEdit = "CanEdit";
    }
  }

  OnChangeCanDelete(evt, i: number) {
    this.Crm_Menu_Roll[i];
    var target = evt.target;
    if (target.checked) {
      this.Crm_Menu_Roll[i].CanDelete = true;
      this.Crm_Menu_Roll[i].ViewCanDelete = "CanDelete";
    }
    else {
      this.Crm_Menu_Roll[i].CanDelete = false;
      this.Crm_Menu_Roll[i].ViewCanDelete = "CanDelete";
    }
  }

  OnChangeCanView(evt, i: number) {
    this.Crm_Menu_Roll[i];
    var target = evt.target;
    if (target.checked) {
      this.Crm_Menu_Roll[i].CanView = true;
      this.Crm_Menu_Roll[i].ViewCanView = "CanView";
    }
    else {
      this.Crm_Menu_Roll[i].CanView = false;
      this.Crm_Menu_Roll[i].ViewCanView = "CanView";
    }
  }

  OnChangeCanApprove(evt, i: number) {
    this.Crm_Menu_Roll[i];
    var target = evt.target;
    if (target.checked) {
      this.Crm_Menu_Roll[i].CanApprove = true;
      this.Crm_Menu_Roll[i].ViewCanApprove = "CanApprove";
    }
    else {
      this.Crm_Menu_Roll[i].CanApprove = false;
      this.Crm_Menu_Roll[i].ViewCanApprove = "CanApprove";
    }
  }

  OnChangeCanReject(evt, i: number) {
    this.Crm_Menu_Roll[i];
    var target = evt.target;
    if (target.checked) {
      this.Crm_Menu_Roll[i].CanReject = true;
      this.Crm_Menu_Roll[i].ViewCanReject = "CanReject";
    }
    else {
      this.Crm_Menu_Roll[i].CanReject = false;
      this.Crm_Menu_Roll[i].ViewCanReject = "CanReject";
    }
  }

  OnChangeCanTransfer(evt, i: number) {
    this.Crm_Menu_Roll[i];
    var target = evt.target;
    if (target.checked) {
      this.Crm_Menu_Roll[i].CanTransfer = true;
      this.Crm_Menu_Roll[i].ViewCanTransfer = "CanTransfer";
    }
    else {
      this.Crm_Menu_Roll[i].CanTransfer = false;
      this.Crm_Menu_Roll[i].ViewCanTransfer = "CanTransfer";
    }
  }

  addCrmRollMapping(): void {
    //this.IsNotBusy=true;
    this.DisableSave = true;

    let t = localStorage.getItem('userToken');
    t = 'Bearer' + t;


    if (this.Crm_Menu_Roll.length > 0) {

      this.CrmRMS.AddCrmRollMenu(t, this.Crm_Menu_Roll).subscribe(res => {

        this.UserRoleId = res;
        this.Crm_Menu_Roll = [];

        if (this.UserRoleId > 0) {

          this.selectedMenuRoll = [{ "id": 0, "itemName": 'Select Menues' }];

          this.DisableSave = false;

          localStorage.setItem('SuccessfullMessage', "Successfully Change");
          this.rout.navigateByUrl('ManageMICEAccess/ManageRoll', { skipLocationChange: true }).then(() => this.rout.navigate(["ManageMICEAccess/CrmChildMenuRoll"]));

        }
      });
    }

  }




}
