<div class="body-content-area tabcontent" id="link1">
    <div class="row">
        <div class="col-lg-9">
            <div class="row">
                <div class="col-lg-1 low-padding text-center">
                    <!-- <img *ngIf="this.SelectedMenu.Image!=null" src={{this.SelectedMenu.Image}} class="body-icon" alt="Icon"/> -->
                </div>
                <div class="col-lg-10 clear-padding">
                    <div>
                        <span class="page-title">Employee Master</span>
                        <small class="page-description"></small>
                    </div>

                    <div class="row" *ngIf="!(this.CanViewState)">
                        <div class="col-lg-4">
                            <div class="col-body-border-right">
                                <div class="form-group row">
                                    <button *ngIf="!(this.IsButtonIsInViewState)" id="AddNew" name="AddNew" (click)="AddNewJoiningClick()" type="button" class="btn btn-primary save-button">ADD New Employee</button> 
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4">
                            <div class="form-group row">
                                <button *ngIf="!(this.IsButtonIsInViewState)" id="DirectlyAdd" name="DirectlyAdd" (click)="DirectlyAddJoiningClick()" type="button" class="btn btn-primary save-button">ADD Old Employee</button> 
                            </div>
                         </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div *ngIf="!(this.CanViewState)">
    <fieldset class="body-form-control" *ngIf="!(this.IsjoiningInViewState)">
    <legend class="legend--">Employee Joining Details</legend>
        <div *ngIf="NoRecordJoinigMsg" style="color: #dc3545; text-align: center;font-size: 15px; font-weight: bold;">No Joining Record Found.</div>
                
        <div *ngIf="NoRecordJoinigMsg">
            <button  type="button" style="width: 10%;" class="btn btn-danger cancle-button" (click)="RefreshPage()">Cancel</button>
        </div>

        <table id="tblJoiningLetter" *ngIf="(this.IsjoiningInViewState == false && this.Joinings.length > 0 )" class="table table-striped table-bordered text-center table-new row-border hover">
            <thead class="thead-blue text-center">
                <tr>
                    <th style="width: 8%;">Sl No.</th>
                    <th style="width: 20%;" class="text-left">Name</th>
                    <th style="width: 15%;" class="text-left">Branch</th>
                    <th style="width: 15%;" class="text-left">Department</th>
                    <th style="width: 15%;" class="text-left">Designation</th>
                    <th style="width: 8%;">Joining Date</th>
                    <th style="width: 10%;">Contact No</th>
                    <th style="width: 9%;">Action</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let joining of Joinings, let i = index">
                    <td scope="row">{{i+1}}</td>
                    <td class="text-left">{{joining.FullName}}</td>
                    <td class="text-left">{{joining.BranchName}}</td>
                    <td class="text-left">{{joining.DepartmentName}}</td>
                    <td class="text-left">{{joining.DesignationName}}</td>
                    <td>{{joining.JoiningDate | date : "dd-MMM-yyyy"}}</td>
                    <td>{{joining.ContactNumber}}</td>
                    <td>
                        <button *ngIf="this.SelectedMenu.CanEdit" type="button" (click)="GetjoiningDetailsByid(joining.JoiningId)" class="table-edit-btn"><i class="fas fa-edit"></i> </button> 
                        <button (click)="RefreshPage()" type="button">Cancel</button> 
                    </td>
                </tr>
            </tbody>
            <tfoot class="thead-blue text-center">
                <tr>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                </tr>
            </tfoot>
        </table>
    </fieldset>

    <!-- <fieldset *ngIf="!(this.IsEmployeeInViewState)" class="body-form-control" >
    <legend class="legend--">Employee Details</legend> -->
        <table id="tblEmployee" *ngIf="!(this.IsEmployeeInViewState)" datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" class="table table-striped table-bordered text-center table-new row-border hover">
            <thead class="thead-blue text-center">
                <tr>
                    <th style="width: 8%;">Sl No.</th>
                    <th style="width: 7%;">Code</th>
                    <th class="text-left" style="width: 17%;">Name</th>
                    <th class="text-left" style="width: 19%;">Branch</th>
                    <th class="text-left" style="width: 15%;">Department</th>
                    <th class="text-left" style="width: 19%;">Designation</th>
                    <th style="width: 10%;">Contact</th>
                    <th style="width: 5%;">Action</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let emp of Employees, let i = index">
                    <td scope="row">{{i+1}}</td>
                    <td>{{emp.EmployeeCode}}</td>
                    <td class="text-left">{{emp.FullName}}</td>
                    <td class="text-left">{{emp.BranchName}}</td>
                    <td class="text-left">{{emp.DepartmentName}}</td>
                    <td class="text-left">{{emp.DesignationName}}</td>
                    <td>{{emp.ContactNumber}}</td>
                    <td>
                        <button *ngIf="this.SelectedMenu.CanEdit" type="button" (click)="GetEmpDetailsByid(emp.EmployeeId)" class="table-edit-btn"><i class="fas fa-edit"></i> </button> 
                        <!-- <button *ngIf="this.SelectedMenu.CanDelete" hidden="hidden" type="button" (click)="DeleteJoiningDetailsByid(emp.JoiningId )" class="table-remove-btn"><i class="fas fa-trash-alt"></i>  </button> -->
                        <!-- <button *ngIf="this.SelectedMenu.CanView" hidden="hidden" type="button" (click)="ViewEmpDetailsByid(emp.EmployeeId)" class="table-view-button" type="button" ><i class="fas fa-eye"></i></button> -->
                        <button (click)="RefreshPage()" hidden="hidden" style="width: 50%;" type="button" class="btn btn-danger cancle-button">Refresh</button> 
                    </td>
                </tr>
            </tbody>
        </table>
    <!-- </fieldset>  -->

    <fieldset class="body-form-control" *ngIf="(this.IsFormView)"> 
    <legend class="legend--"></legend>
        <section>
            <div class="tabs tabs-style-tzoid">
                <nav>
                    <ul>
                        <li [ngClass]="{'tab-current': selectedIndex == 0}" (click)="EmployeeSubPages(0,'Employee/EmployeeDetails/PersonalDetails')" ><a  class="icon icon-home"><span>Personal Details</span></a></li>
                        <li [ngClass]="{'tab-current': selectedIndex == 1}" (click)="EmployeeSubPages(1,'Employee/EmployeeDetails/AddressDetails')" > <a  class="icon icon-box"><span>Address</span></a></li>
                        <li [ngClass]="{'tab-current': selectedIndex == 2}" (click)="EmployeeSubPages(2,'Employee/EmployeeDetails/OthersDetails')" ><a  class="icon icon-upload"><span>Other Details</span></a></li>
                        <li [ngClass]="{'tab-current': selectedIndex == 3}" (click)="EmployeeSubPages(3,'Employee/EmployeeDetails/EducationalDetails')" ><a  class="icon icon-display"><span>Qualification</span></a></li>
                        <li [ngClass]="{'tab-current': selectedIndex == 4}" (click)="EmployeeSubPages(4,'Employee/EmployeeDetails/ExpriencDetails')" ><a  class="icon icon-tools"><span>Experience Details</span></a></li>
                        <li [ngClass]="{'tab-current': selectedIndex == 5}" (click)="EmployeeSubPages(5,'Employee/EmployeeDetails/FamilyDetails')" ><a  class="icon icon-tools"><span>Family Details</span></a></li>
                        <li [ngClass]="{'tab-current': selectedIndex == 6}" (click)="EmployeeSubPages(6,'Employee/EmployeeDetails/NomineeDetails')" ><a  class="icon icon-tools"><span>Nominee Details</span></a></li>
                    </ul>
                </nav>
                <div class="content-wrap">
                    <router-outlet> 
                    </router-outlet>
                </div><!-- /content -->
            </div><!-- /tabs -->
        </section>
    </fieldset>
    </div>
</div>