<div class="body-content-area tabcontent" id="link1">
  <div class="row">
    <div class="col-lg-9">
      <div class="row">
        <div class="col-lg-1 low-padding text-center">
        </div>
        <div class="col-lg-10 clear-padding">
          <span class="page-title">Income Tax Actual Investment</span>
          <small class="page-description"></small>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="!(this.CanViewState)">
    <app-dialog [(visible)]="this.SuccessStatus">
      <span class="successmsg">{{this.SuccessMessage}}</span>
    </app-dialog>

    <fieldset class="body-form-control" > 
      <legend class="legend--">INCOME TAX - ACTUAL INVESTMENT PROFF SUBMISSION FROM 12BB - ( {{this.Form12BBModel.Financialyear}} )</legend>
      <form #EmployeeForm12BBForm="ngForm">
        <div *ngIf="this.Form12BBModel.ShowTextMessage" style="font-weight: bold; color:green; text-align: right;">Tax Declaration is in View Only State.</div>
        <table *ngIf="!this.Form12BBModel.ShowTextMessage">
          <tr>
            <td colspan="3">&nbsp;</td>
          </tr>
          <tr style="width: 100%;">
            <td style="width: 30%;"><strong>Tds Type :</strong></td>
            <td style="width: 30%;">
              <input type="radio" [checked]="this.TdsTypeValue == 'OldType'" (click)="OnChangeOldType($event)" id ="OldType" name="TdsType">
              <strong>Old</strong> &nbsp; &nbsp;
            </td>
            <td style="width: 40%;">
              <input type="radio" [checked]="this.TdsTypeValue == 'NewType'" (click)="OnChangeNewType($event)" id ="NewType" name="TdsType">
              <strong>New</strong>
            </td>
          </tr>
          <tr>
            <td colspan="3">&nbsp;</td>
          </tr>
        </table>
        
        <div style="padding-left:5%; padding-right:5%;" Id="Form12BB" *ngIf="this.TdsTypeValue=='NewType'">
          <table border="1px" style="width:100%; font-size: 12px;">
            <tr>
              <td style=" text-align: center; font-weight: bold;" colspan="4">FORM NO.12BB</td>
            </tr>
            <tr>
              <td style=" text-align: center;" colspan="4">(See rule 26C)</td>
            </tr>
            <tr>
              <td colspan="4">&nbsp;</td>
            </tr>
            <tr>
              <td style=" text-align: left;" colspan="2">&nbsp;&nbsp;1. Name and Code of the employee:</td>
              <td style=" text-align: left;" colspan="2">&nbsp;&nbsp;{{this.Form12BBModel.EmployeeName}} // {{this.Form12BBModel.EmployeeCode}}</td>
            </tr>
            <tr>
              <td style=" text-align: left;" colspan="2">&nbsp;&nbsp;2. Address with Contact of the employee:</td>
              <td style=" text-align: left;" colspan="2">&nbsp;&nbsp;{{this.Form12BBModel.EmployeeAddress}}</td>
            </tr>
            <tr>
              <td style=" text-align: left;" colspan="2">&nbsp;&nbsp;3. Name of Department the employee:</td>
              <td style=" text-align: left;" colspan="2">&nbsp;&nbsp;{{this.Form12BBModel.DepartmentName}}</td>
            </tr>
            <tr>
              <td style=" text-align: left;" colspan="2">&nbsp;&nbsp;4. Permanent Account Number of the employee:</td>
              <td style=" text-align: left;" colspan="2">&nbsp;&nbsp;{{this.Form12BBModel.EmployeePANNo}}</td>
            </tr>
            <tr>
              <td style=" text-align: left;" colspan="2">&nbsp;&nbsp;5. Financial year:</td>
              <td style=" text-align: left;" colspan="2">&nbsp;&nbsp;{{this.Form12BBModel.Financialyear}}</td>
            </tr>
            <tr>
              <td colspan="4">&nbsp;</td>
            </tr>
            <tr>
              <td style=" text-align: center;" colspan="4">Verification</td>
            </tr>
            <tr>
              <td style=" text-align: left;" colspan="4">
                <br>
                I, {{this.Form12BBModel.EmployeeName}},son/daughter of Sri {{this.Form12BBModel.FathersName}}. do hereby certify that the information given above is complete and correct.
                <br>
                <br>
              </td>
            </tr>
            <tr>
              <td colspan="2">&nbsp;Place :</td>
              <td colspan="2">&nbsp;</td>
            </tr>
            <tr>
              <td colspan="2">&nbsp;Date :</td>
              <td colspan="2">&nbsp;(Signature of the employee)&nbsp;&nbsp;&nbsp;</td>
            </tr>
            <tr>
              <td colspan="2" >&nbsp;Designation : {{this.Form12BBModel.DesignationName}}</td>
              <td colspan="2">&nbsp;Full Name : {{this.Form12BBModel.EmployeeName}}&nbsp;&nbsp;&nbsp;</td>
            </tr>
            <tr>
              <td colspan="4">&nbsp;</td>
            </tr>
          </table>
        </div>
        
        <div style="padding-left:5%; padding-right:5%;" Id="Form12BB" *ngIf="this.TdsTypeValue=='OldType'">
          <table border="1px" style="width:100%; font-size: 12px;">
            <tr>
              <td style=" text-align: center; font-weight: bold;" colspan="4">FORM NO.12BB</td>
            </tr>
            <tr>
              <td style=" text-align: center;" colspan="4">(See rule 26C)</td>
            </tr>
            <tr>
              <td colspan="4">&nbsp;</td>
            </tr>
            <tr>
              <td style=" text-align: left;" colspan="2">&nbsp;&nbsp;1. Name and Code of the employee:</td>
              <td style=" text-align: left;" colspan="2">&nbsp;&nbsp;{{this.Form12BBModel.EmployeeName}} // {{this.Form12BBModel.EmployeeCode}}</td>
            </tr>
            <tr>
              <td style=" text-align: left;" colspan="2">&nbsp;&nbsp;2. Address with Contact of the employee:</td>
              <td style=" text-align: left;" colspan="2">&nbsp;&nbsp;{{this.Form12BBModel.EmployeeAddress}}</td>
            </tr>
            <tr>
              <td style=" text-align: left;" colspan="2">&nbsp;&nbsp;3. Name of Department the employee:</td>
              <td style=" text-align: left;" colspan="2">&nbsp;&nbsp;{{this.Form12BBModel.DepartmentName}}</td>
            </tr>
            <tr>
              <td style=" text-align: left;" colspan="2">&nbsp;&nbsp;4. Permanent Account Number of the employee:</td>
              <td style=" text-align: left;" colspan="2">&nbsp;&nbsp;{{this.Form12BBModel.EmployeePANNo}}</td>
            </tr>
            <tr>
              <td style=" text-align: left;" colspan="2">&nbsp;&nbsp;5. Financial year:</td>
              <td style=" text-align: left;" colspan="2">&nbsp;&nbsp;{{this.Form12BBModel.Financialyear}}</td>
            </tr>
            <tr>
              <td colspan="4">&nbsp;</td>
            </tr>
            <tr>
              <td style=" text-align: center; font-weight: bold;" colspan="4">Declaration & Details of claims and evidence thereof</td>
            </tr>
            <tr>
              <td colspan="4">&nbsp;</td>
            </tr>
            <tr>
              <td style=" text-align: center; font-weight: bold; width: 5%;">Sl No.</td>
              <td style=" text-align: center; font-weight: bold; width: 35%;">Nature of claim</td>
              <td style=" text-align: center; font-weight: bold; width: 30%;">Amount (Rs.)</td>
              <td style=" text-align: center; font-weight: bold; width: 30%;">Name of Evidence & Proof / particulars</td>
            </tr>
            <tr>
              <td style=" text-align: center; font-weight: bold;">&nbsp;1.</td>
              <td style=" text-align: left; font-weight: bold;">&nbsp;House Rent Allowance:</td>
              <td style=" text-align: right;">&nbsp;</td>
              <td style=" text-align: left; font-size: 11px;">&nbsp;Rent Agreement along with rent receipt</td>
            </tr>
            <tr>
              <td>&nbsp;</td>
              <td>&nbsp;&nbsp;(i) Rent paid to the landlord</td>
              <td *ngIf="!this.Form12BBModel.ShowTextMessage">&nbsp;
                <input type="text" width="20px" [(ngModel)]="Form12BBModel.HRA_Rent" pattern="[0-9]+" id ="HRA_Rent" name="HRA_Rent" #HRA_Rent="ngModel" class="form-control form-control-custom form-control-custom-new">
                <div *ngIf="HRA_Rent.invalid && (HRA_Rent.dirty || HRA_Rent.touched)" class="alert alert-danger">
                  <div *ngIf="HRA_Rent.errors?.pattern">Rent should be valid.</div>
                </div>
              </td>
              <td *ngIf="this.Form12BBModel.ShowTextMessage" style=" text-align: right;">&nbsp;{{Form12BBModel.HRA_Rent}}</td>
              <td>&nbsp;</td>
            </tr>
            <tr>
              <td>&nbsp;</td>
              <td>&nbsp;&nbsp;(ii) Name of the landlord</td>
              <td>&nbsp;</td>
              <td *ngIf="!this.Form12BBModel.ShowTextMessage">
                &nbsp;<input type="text" width="60%" [(ngModel)]="Form12BBModel.HRA_LandLordName" id ="HRA_LandLordName" name="HRA_LandLordName" #HRA_LandLordName="ngModel" class="form-control form-control-custom form-control-custom-new">
              </td>
              <td *ngIf="this.Form12BBModel.ShowTextMessage">&nbsp;{{Form12BBModel.HRA_LandLordName}}</td>
            </tr>
            <tr>
              <td>&nbsp;</td>
              <td>&nbsp;&nbsp;(iii) Address of the landlord</td>
              <td>&nbsp;</td>
              <td *ngIf="!this.Form12BBModel.ShowTextMessage">&nbsp;<input type="text" width="60%" [(ngModel)]="Form12BBModel.HRA_LandLordAddress" id ="HRA_LandLordAddress" name="HRA_LandLordAddress" #HRA_LandLordAddress="ngModel" class="form-control form-control-custom form-control-custom-new"></td>
              <td *ngIf="this.Form12BBModel.ShowTextMessage">&nbsp;{{Form12BBModel.HRA_LandLordAddress}}</td>
            </tr>
            <tr>
              <td>&nbsp;</td>
              <td>&nbsp;&nbsp;(iv) Permanent Account Number of the landlord</td>
              <td>&nbsp;</td>
              <td *ngIf="!this.Form12BBModel.ShowTextMessage">&nbsp;<input type="text" width="60%" [(ngModel)]="Form12BBModel.HRA_LandLordPANNo" id ="HRA_LandLordPANNo" name="HRA_LandLordPANNo" #HRA_LandLordPANNo="ngModel" class="form-control form-control-custom form-control-custom-new"></td>
              <td *ngIf="this.Form12BBModel.ShowTextMessage">&nbsp;{{Form12BBModel.HRA_LandLordPANNo}}</td>
            </tr>
            <tr>
              <td>&nbsp;</td>
              <td>&nbsp;&nbsp;Note: Permanent Account Number shall be furnished if the aggregate rent paid during the previous year exceeds one lakh rupees</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
            </tr>
            <tr>
              <td style=" text-align: center; font-weight: bold;">&nbsp;2.</td>
              <td style=" text-align: left; font-weight: bold;">&nbsp;Leave travel concessions or assistance</td>
              <td *ngIf="!this.Form12BBModel.ShowTextMessage" style=" text-align: right;">
                &nbsp;<input type="text" pattern="[0-9]+" width="60%" [(ngModel)]="Form12BBModel.LeaveTravelConcessions" id ="LeaveTravelConcessions" name="LeaveTravelConcessions" #LeaveTravelConcessions="ngModel" class="form-control form-control-custom form-control-custom-new">
                <div *ngIf="LeaveTravelConcessions.invalid && (LeaveTravelConcessions.dirty || LeaveTravelConcessions.touched)" class="alert alert-danger">
                  <div *ngIf="LeaveTravelConcessions.errors?.pattern">Leave travel concessions should be valid.</div>
                </div>
              </td>
              <td *ngIf="this.Form12BBModel.ShowTextMessage" style=" text-align: right;">&nbsp;{{Form12BBModel.LeaveTravelConcessions}}</td>
              <td style=" text-align: left; font-size: 11px;">&nbsp;Transport Bill</td>
            </tr>
            <tr>
              <td style=" text-align: center; font-weight: bold;">&nbsp;3.</td>
              <td style=" text-align: left; font-weight: bold;">&nbsp;Deduction of interest on borrowing:</td>
              <td style=" text-align: right;">&nbsp;</td>
              <td style=" text-align: left; font-size: 11px;">&nbsp;</td>
            </tr>
            <tr>
              <td>&nbsp;</td>
              <td>&nbsp;&nbsp;(i) Interest payable/paid to the lender</td>
              <td *ngIf="!this.Form12BBModel.ShowTextMessage">
                &nbsp;<input type="text" width="60%" [(ngModel)]="Form12BBModel.DIB_LenderPaidAmount" pattern="[0-9]+" id ="DIB_LenderPaidAmount" name="DIB_LenderPaidAmount" #DIB_LenderPaidAmount="ngModel" class="form-control form-control-custom form-control-custom-new">
                <div *ngIf="DIB_LenderPaidAmount.invalid && (DIB_LenderPaidAmount.dirty || DIB_LenderPaidAmount.touched)" class="alert alert-danger">
                  <div *ngIf="DIB_LenderPaidAmount.errors?.pattern">Interest payable/paid to the lender should be valid.</div>
                </div>
              </td>
              <td *ngIf="this.Form12BBModel.ShowTextMessage" style=" text-align: right;">&nbsp;{{Form12BBModel.DIB_LenderPaidAmount}}</td>
              <td>&nbsp;</td>
            </tr>
            <tr>
              <td>&nbsp;</td>
              <td>&nbsp;&nbsp;(ii) Name of the lender</td>
              <td>&nbsp;</td>
              <td *ngIf="!this.Form12BBModel.ShowTextMessage">&nbsp;<input type="text" width="60%" [(ngModel)]="Form12BBModel.DIB_LenderName" id ="DIB_LenderName" name="DIB_LenderName" #DIB_LenderName="ngModel" class="form-control form-control-custom form-control-custom-new"></td>
              <td *ngIf="this.Form12BBModel.ShowTextMessage">&nbsp;{{Form12BBModel.DIB_LenderName}}</td>
            </tr>
            <tr>
              <td>&nbsp;</td>
              <td>&nbsp;&nbsp;(iii) Address of the lender</td>
              <td>&nbsp;</td>
              <td *ngIf="!this.Form12BBModel.ShowTextMessage">&nbsp;<input type="text" width="60%" [(ngModel)]="Form12BBModel.DIB_LenderAddress" id ="DIB_LenderAddress" name="DIB_LenderAddress" #DIB_LenderAddress="ngModel" class="form-control form-control-custom form-control-custom-new"></td>
              <td *ngIf="this.Form12BBModel.ShowTextMessage">&nbsp;{{Form12BBModel.DIB_LenderAddress}}</td>
            </tr>
            <tr>
              <td>&nbsp;</td>
              <td>&nbsp;&nbsp;(iv) Permanent Account Number of the lender</td>
              <td>&nbsp;</td>
              <td *ngIf="!this.Form12BBModel.ShowTextMessage">&nbsp;<input type="text" width="60%" [(ngModel)]="Form12BBModel.DIB_LenderPANNo" id ="DIB_LenderPANNo" name="DIB_LenderPANNo" #DIB_LenderPANNo="ngModel" class="form-control form-control-custom form-control-custom-new"></td>
              <td *ngIf="this.Form12BBModel.ShowTextMessage">&nbsp;{{Form12BBModel.DIB_LenderPANNo}}</td>
            </tr>
            <tr>
              <td>&nbsp;</td>
              <td>&nbsp;&nbsp;&nbsp;(a) Financial Institutions(if available)</td>
              <td *ngIf="!this.Form12BBModel.ShowTextMessage">
                &nbsp;<input type="text" width="60%" [(ngModel)]="Form12BBModel.FinancialInstitutions" pattern="[0-9]+" id ="FinancialInstitutions" name="FinancialInstitutions" #FinancialInstitutions="ngModel" class="form-control form-control-custom form-control-custom-new">
                <div *ngIf="FinancialInstitutions.invalid && (FinancialInstitutions.dirty || FinancialInstitutions.touched)" class="alert alert-danger">
                  <div *ngIf="FinancialInstitutions.errors?.pattern">Financial Institutions should be valid.</div>
                </div>
              </td>
              <td *ngIf="this.Form12BBModel.ShowTextMessage" style=" text-align: right;">&nbsp;{{Form12BBModel.FinancialInstitutions}}</td>
              <td>&nbsp;</td>
            </tr>
            <tr>
              <td>&nbsp;</td>
              <td>&nbsp;&nbsp;&nbsp;(b) Employer(if available)</td>
              <td *ngIf="!this.Form12BBModel.ShowTextMessage">
                &nbsp;<input type="text" width="60%" [(ngModel)]="Form12BBModel.Employer" pattern="[0-9]+" id ="Employer" name="Employer" #Employer="ngModel" class="form-control form-control-custom form-control-custom-new">
                <div *ngIf="Employer.invalid && (Employer.dirty || Employer.touched)" class="alert alert-danger">
                  <div *ngIf="Employer.errors?.pattern">Employer should be valid.</div>
                </div>
              </td>
              <td *ngIf="this.Form12BBModel.ShowTextMessage" style=" text-align: right;">&nbsp;{{Form12BBModel.Employer}}</td>
              <td>&nbsp;</td>
            </tr>
            <tr>
              <td>&nbsp;</td>
              <td>&nbsp;&nbsp;&nbsp;(c) Others</td>
              <td *ngIf="!this.Form12BBModel.ShowTextMessage">
                &nbsp;<input type="text" width="60%" [(ngModel)]="Form12BBModel.Other" pattern="[0-9]+" id ="Other" name="Other" #Other="ngModel" class="form-control form-control-custom form-control-custom-new">
                <div *ngIf="Other.invalid && (Other.dirty || Other.touched)" class="alert alert-danger">
                  <div *ngIf="Other.errors?.pattern">Others should be valid.</div>
                </div>
              </td>
              <td *ngIf="this.Form12BBModel.ShowTextMessage" style=" text-align: right;">&nbsp;{{Form12BBModel.Other}}</td>
              <td>&nbsp;</td>
            </tr>
            <tr>
              <td style=" text-align: center; font-weight: bold;">&nbsp;4.</td>
              <td style=" text-align: left; font-weight: bold;">&nbsp;Deduction under Chapter VI-A</td>
              <td style=" font-weight: bold; text-align: right;">&nbsp;{{this.Form12BBModel.TotalDedUnderVIA}}</td>
              <td style=" text-align: left; font-size: 11px;">&nbsp;</td>
            </tr>
            <tr>
              <td>&nbsp;</td>
              <td>&nbsp;&nbsp;(A) Section 80C,80CCC and 80CCD</td>
              <td style=" font-weight: bold; text-align: right;">&nbsp;{{this.Form12BBModel.TotalSEC80C_80CCC_80CCD}}</td>
              <td>&nbsp;</td>
            </tr>
            <tr>
              <td>&nbsp;</td>
              <td>&nbsp;&nbsp;&nbsp;(i) Section 80C</td>
              <td style=" font-weight: bold; text-align: right;">&nbsp;{{this.Form12BBModel.TotalSEC80C}}</td>
              <td>&nbsp;MAX REBATE WILL 1.50 LAC</td>
            </tr>
            <tr>
              <td>&nbsp;</td>
              <td>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(a) Insurance</td>
              <td *ngIf="!this.Form12BBModel.ShowTextMessage">
                &nbsp;<input type="text" (blur)="InsuranceCalculation()" width="60%" [(ngModel)]="Form12BBModel.Insurance_80C" pattern="[0-9]+" id ="Insurance_80C" name="Insurance_80C" #Insurance_80C="ngModel" class="form-control form-control-custom form-control-custom-new">
                <div *ngIf="Insurance_80C.invalid && (Insurance_80C.dirty || Insurance_80C.touched)" class="alert alert-danger">
                  <div *ngIf="Insurance_80C.errors?.pattern">Insurance should be valid.</div>
                </div>
              </td>
              <td *ngIf="this.Form12BBModel.ShowTextMessage" style=" text-align: right;">&nbsp;{{Form12BBModel.Insurance_80C}}</td>
              <td>&nbsp;</td>
            </tr>
            <tr>
              <td>&nbsp;</td>
              <td>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(b) PF & PPF</td>
              <td *ngIf="!this.Form12BBModel.ShowTextMessage">
                &nbsp;<input type="text" (blur)="PFEPFCalculation()" width="60%" [(ngModel)]="Form12BBModel.PF_PPF_80C" pattern="[0-9]+" id ="PF_PPF_80C" name="PF_PPF_80C" #PF_PPF_80C="ngModel" class="form-control form-control-custom form-control-custom-new">
                <div *ngIf="PF_PPF_80C.invalid && (PF_PPF_80C.dirty || PF_PPF_80C.touched)" class="alert alert-danger">
                  <div *ngIf="PF_PPF_80C.errors?.pattern">PF & PPF should be valid.</div>
                </div>
              </td>
              <td *ngIf="this.Form12BBModel.ShowTextMessage" style=" text-align: right;">&nbsp;{{Form12BBModel.PF_PPF_80C}}</td>
              <td>&nbsp;</td>
            </tr>
            <tr>
              <td>&nbsp;</td>
              <td>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(c) NPS (New pension Scheme)</td>
              <td *ngIf="!this.Form12BBModel.ShowTextMessage">
                &nbsp;<input type="text" (blur)="NPSCalculation()" width="60%" [(ngModel)]="Form12BBModel.NPS_80C" pattern="[0-9]+" id ="NPS_80C" name="NPS_80C" #NPS_80C="ngModel" class="form-control form-control-custom form-control-custom-new">
                <div *ngIf="NPS_80C.invalid && (NPS_80C.dirty || NPS_80C.touched)" class="alert alert-danger">
                  <div *ngIf="NPS_80C.errors?.pattern">NPS should be valid.</div>
                </div>
              </td>
              <td *ngIf="this.Form12BBModel.ShowTextMessage" style=" text-align: right;">&nbsp;{{Form12BBModel.NPS_80C}}</td>
              <td>&nbsp;</td>
            </tr>
            <tr>
              <td>&nbsp;</td>
              <td>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(d) ELSS (Tax Saving Mutual Fund)</td>
              <td *ngIf="!this.Form12BBModel.ShowTextMessage">
                &nbsp;<input type="text" (blur)="ELSSCalculation()" width="60%" [(ngModel)]="Form12BBModel.ELSS_80C" pattern="[0-9]+" id ="ELSS_80C" name="ELSS_80C" #ELSS_80C="ngModel" class="form-control form-control-custom form-control-custom-new">
                <div *ngIf="ELSS_80C.invalid && (ELSS_80C.dirty || ELSS_80C.touched)" class="alert alert-danger">
                  <div *ngIf="ELSS_80C.errors?.pattern">ELSS should be valid.</div>
                </div>
              </td>
              <td *ngIf="this.Form12BBModel.ShowTextMessage" style=" text-align: right;">&nbsp;{{Form12BBModel.ELSS_80C}}</td>
              <td>&nbsp;</td>
            </tr>
            <tr>
              <td>&nbsp;</td>
              <td>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(e) Housing Loan (Principal Amount)</td>
              <td *ngIf="!this.Form12BBModel.ShowTextMessage">
                &nbsp;<input type="text" (blur)="HousingLoanCalculation()" width="60%" [(ngModel)]="Form12BBModel.HousingLoan_80C" pattern="[0-9]+" id ="HousingLoan_80C" name="HousingLoan_80C" #HousingLoan_80C="ngModel" class="form-control form-control-custom form-control-custom-new">
                <div *ngIf="HousingLoan_80C.invalid && (HousingLoan_80C.dirty || HousingLoan_80C.touched)" class="alert alert-danger">
                  <div *ngIf="HousingLoan_80C.errors?.pattern">Housing Loan should be valid.</div>
                </div>
              </td>
              <td *ngIf="this.Form12BBModel.ShowTextMessage" style=" text-align: right;">&nbsp;{{Form12BBModel.HousingLoan_80C}}</td>
              <td>&nbsp;Max 1.50 Lac</td>
            </tr>
            <tr>
              <td>&nbsp;</td>
              <td>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(f) Tax Saving FD (5 Years)</td>
              <td *ngIf="!this.Form12BBModel.ShowTextMessage">
                &nbsp;<input type="text" (blur)="FDCalculation()" width="60%" [(ngModel)]="Form12BBModel.FD_80C" pattern="[0-9]+" id ="FD_80C" name="FD_80C" #FD_80C="ngModel" class="form-control form-control-custom form-control-custom-new">
                <div *ngIf="FD_80C.invalid && (FD_80C.dirty || FD_80C.touched)" class="alert alert-danger">
                  <div *ngIf="FD_80C.errors?.pattern">Tax Saving FD should be valid.</div>
                </div>
              </td>
              <td *ngIf="this.Form12BBModel.ShowTextMessage" style=" text-align: right;">&nbsp;{{Form12BBModel.FD_80C}}</td>
              <td>&nbsp;</td>
            </tr>
            <tr>
              <td>&nbsp;</td>
              <td>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(g) Tution Fee Child Education (Max. of 2 Child)</td>
              <td style=" font-weight: bold; text-align: right;">&nbsp;{{this.Form12BBModel.SchoolTution_80C}}</td>
              <td>&nbsp;</td>
            </tr>
            <tr>
              <td>&nbsp;</td>
              <td>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Child 1 :</td>
              <td *ngIf="!this.Form12BBModel.ShowTextMessage">
                &nbsp;<input type="text" (blur)="SchoolTutuionCalculation()" width="60%" [(ngModel)]="Form12BBModel.SchoolTutuion_C1" pattern="[0-9]+" id ="SchoolTutuion_C1" name="SchoolTutuion_C1" #SchoolTutuion_C1="ngModel" class="form-control form-control-custom form-control-custom-new">
                <div *ngIf="SchoolTutuion_C1.invalid && (SchoolTutuion_C1.dirty || SchoolTutuion_C1.touched)" class="alert alert-danger">
                  <div *ngIf="SchoolTutuion_C1.errors?.pattern">Child 1 Tution Fee should be valid.</div>
                </div>
              </td>
              <td *ngIf="this.Form12BBModel.ShowTextMessage" style=" text-align: right;">&nbsp;{{Form12BBModel.SchoolTutuion_C1}}</td>
              <td>&nbsp;</td>
            </tr>
            <tr>
              <td>&nbsp;</td>
              <td>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Child 2 :</td>
              <td *ngIf="!this.Form12BBModel.ShowTextMessage">
                &nbsp;<input type="text" (blur)="SchoolTutuionCalculation()" width="60%" [(ngModel)]="Form12BBModel.SchoolTutuion_C2" pattern="[0-9]+" id ="SchoolTutuion_C2" name="SchoolTutuion_C2" #SchoolTutuion_C2="ngModel" class="form-control form-control-custom form-control-custom-new">
                <div *ngIf="SchoolTutuion_C2.invalid && (SchoolTutuion_C2.dirty || SchoolTutuion_C2.touched)" class="alert alert-danger">
                  <div *ngIf="SchoolTutuion_C2.errors?.pattern">Child 2 Tution Fee should be valid.</div>
                </div>
              </td>
              <td *ngIf="this.Form12BBModel.ShowTextMessage" style=" text-align: right;">&nbsp;{{Form12BBModel.SchoolTutuion_C2}}</td>
              <td>&nbsp;</td>
            </tr>
            <tr>
              <td>&nbsp;</td>
              <td>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(h) Sukanaya Samriddhi Scheme</td>
              <td *ngIf="!this.Form12BBModel.ShowTextMessage">
                &nbsp;<input type="text" (blur)="SukanayaSamriddhiCalculation()" width="60%" [(ngModel)]="Form12BBModel.SukanayaSamriddhi_80C" pattern="[0-9]+" id ="SukanayaSamriddhi_80C" name="SukanayaSamriddhi_80C" #SukanayaSamriddhi_80C="ngModel" class="form-control form-control-custom form-control-custom-new">
                <div *ngIf="SukanayaSamriddhi_80C.invalid && (SukanayaSamriddhi_80C.dirty || SukanayaSamriddhi_80C.touched)" class="alert alert-danger">
                  <div *ngIf="SukanayaSamriddhi_80C.errors?.pattern">Sukanaya Samriddhi should be valid.</div>
                </div>
              </td>
              <td *ngIf="this.Form12BBModel.ShowTextMessage" style=" text-align: right;">&nbsp;{{Form12BBModel.SukanayaSamriddhi_80C}}</td>
              <td>&nbsp;</td>
            </tr>
            <tr>
              <td>&nbsp;</td>
              <td>&nbsp;&nbsp;&nbsp;(ii) Section 80CCC (Mutual Fund)</td>
              <td *ngIf="!this.Form12BBModel.ShowTextMessage">
                &nbsp;<input type="text" (blur)="SEC80CCCCalculation()" width="60%" [(ngModel)]="Form12BBModel.MutaulFund_80CCC" pattern="[0-9]+" id ="MutaulFund_80CCC" name="MutaulFund_80CCC" #MutaulFund_80CCC="ngModel" class="form-control form-control-custom form-control-custom-new">
                <div *ngIf="MutaulFund_80CCC.invalid && (MutaulFund_80CCC.dirty || MutaulFund_80CCC.touched)" class="alert alert-danger">
                  <div *ngIf="MutaulFund_80CCC.errors?.pattern">Section 80CCC should be valid.</div>
                </div>
              </td>
              <td *ngIf="this.Form12BBModel.ShowTextMessage" style=" text-align: right;">&nbsp;{{Form12BBModel.MutaulFund_80CCC}}</td>
              <td>&nbsp; Max Limit 50,000</td>
            </tr>
            <tr>
              <td>&nbsp;</td>
              <td>&nbsp;&nbsp;&nbsp;(iii) Section 80CCD (Employee Pension Plan)</td>
              <td *ngIf="!this.Form12BBModel.ShowTextMessage">
                &nbsp;<input type="text" (blur)="SEC80CCDCalculation()" width="60%" [(ngModel)]="Form12BBModel.EPP_80CCD" pattern="[0-9]+" id ="EPP_80CCD" name="EPP_80CCD" #EPP_80CCD="ngModel" class="form-control form-control-custom form-control-custom-new">
                <div *ngIf="EPP_80CCD.invalid && (EPP_80CCD.dirty || EPP_80CCD.touched)" class="alert alert-danger">
                  <div *ngIf="EPP_80CCD.errors?.pattern">Section 80CCD should be valid.</div>
                </div>
              </td>
              <td *ngIf="this.Form12BBModel.ShowTextMessage" style=" text-align: right;">&nbsp;{{Form12BBModel.EPP_80CCD}}</td>
              <td>&nbsp;Max Limit 50,000</td>
            </tr>
            <tr>
              <td>&nbsp;</td>
              <td>&nbsp;&nbsp;(B) Other sections (e.g. 80E, 80G, 80TTA, etc.) under Chapter VI-A.</td>
              <td style=" font-weight: bold; text-align: right;">&nbsp;{{this.Form12BBModel.OtherSection_80E_80G_80TTA}}</td>
              <td>&nbsp;</td>
            </tr>
            <tr>
              <td>&nbsp;</td>
              <td>&nbsp;&nbsp;&nbsp;(i) section 80D (Medical Insurance Parents)</td>
              <td *ngIf="!this.Form12BBModel.ShowTextMessage">
                &nbsp;<input type="text" (blur)="Sec80DCalculation()" width="60%" [(ngModel)]="Form12BBModel.MIParents_80D" pattern="[0-9]+" id ="MIParents_80D" name="MIParents_80D" #MIParents_80D="ngModel" class="form-control form-control-custom form-control-custom-new">
                <div *ngIf="MIParents_80D.invalid && (MIParents_80D.dirty || MIParents_80D.touched)" class="alert alert-danger">
                  <div *ngIf="MIParents_80D.errors?.pattern">section 80D should be valid.</div>
                </div>
              </td>
              <td *ngIf="this.Form12BBModel.ShowTextMessage" style=" text-align: right;">&nbsp;{{Form12BBModel.MIParents_80D}}</td>
              <td>&nbsp;Max Limit 50,000</td>
            </tr>
            <tr>
              <td>&nbsp;</td>
              <td>&nbsp;&nbsp;&nbsp;(ii) section 80E (Interest paid on Education Loan)</td>
              <td *ngIf="!this.Form12BBModel.ShowTextMessage">
                &nbsp;<input type="text" (blur)="OtherSection_80E_80G_80TTACalculation()" width="60%" [(ngModel)]="Form12BBModel.IPEL_80E" pattern="[0-9]+" id ="IPEL_80E" name="IPEL_80E" #IPEL_80E="ngModel" class="form-control form-control-custom form-control-custom-new">
                <div *ngIf="IPEL_80E.invalid && (IPEL_80E.dirty || IPEL_80E.touched)" class="alert alert-danger">
                  <div *ngIf="IPEL_80E.errors?.pattern">section 80E should be valid.</div>
                </div>
              </td>
              <td *ngIf="this.Form12BBModel.ShowTextMessage" style=" text-align: right;">&nbsp;{{Form12BBModel.IPEL_80E}}</td>
              <td>&nbsp;</td>
            </tr>
            <tr>
              <td>&nbsp;</td>
              <td>&nbsp;&nbsp;&nbsp;(iii) section 80G (Donation to approved Funds)</td>
              <td *ngIf="!this.Form12BBModel.ShowTextMessage">
                &nbsp;<input type="text" (blur)="Sec80GCalculation()" width="60%" [(ngModel)]="Form12BBModel.Donation_80G" pattern="[0-9]+" id ="Donation_80G" name="Donation_80G" #Donation_80G="ngModel" class="form-control form-control-custom form-control-custom-new">
                <div *ngIf="Donation_80G.invalid && (Donation_80G.dirty || Donation_80G.touched)" class="alert alert-danger">
                  <div *ngIf="Donation_80G.errors?.pattern">section 80G should be valid.</div>
                </div>
              </td>
              <td *ngIf="this.Form12BBModel.ShowTextMessage" style=" text-align: right;">&nbsp;{{Form12BBModel.Donation_80G}}</td>
              <td>&nbsp;Max Limit 50,000</td>
            </tr>
            <tr>
              <td>&nbsp;</td>
              <td>&nbsp;&nbsp;&nbsp;(iv) section 80TTA (Interest Received of SB A/c)</td>
              <td *ngIf="!this.Form12BBModel.ShowTextMessage">
                &nbsp;<input type="text"(blur)="Sec80TTACalculation()" width="60%" [(ngModel)]="Form12BBModel.SEC80TTA_IRSBAc" pattern="[0-9]+" id ="SEC80TTA_IRSBAc" name="SEC80TTA_IRSBAc" #SEC80TTA_IRSBAc="ngModel" class="form-control form-control-custom form-control-custom-new">
                <div *ngIf="SEC80TTA_IRSBAc.invalid && (SEC80TTA_IRSBAc.dirty || SEC80TTA_IRSBAc.touched)" class="alert alert-danger">
                  <div *ngIf="SEC80TTA_IRSBAc.errors?.pattern">section 80TTA should be valid.</div>
                </div>
              </td>
              <td *ngIf="this.Form12BBModel.ShowTextMessage" style=" text-align: right;">&nbsp;{{Form12BBModel.SEC80TTA_IRSBAc}}</td>
              <td>&nbsp;Max Limit 10,000</td>
            </tr>
            <tr>
              <td>&nbsp;</td>
              <td>&nbsp;&nbsp;&nbsp;(v) section 24 (Interest paid on Home Loan)</td>
              <td *ngIf="!this.Form12BBModel.ShowTextMessage">
                &nbsp;<input type="text" (blur)="Sec24IPHLGCalculation()" width="60%" [(ngModel)]="Form12BBModel.SEC24_IPHL" pattern="[0-9]+" id ="SEC24_IPHL" name="SEC24_IPHL" #SEC24_IPHL="ngModel" class="form-control form-control-custom form-control-custom-new">
                <div *ngIf="SEC24_IPHL.invalid && (SEC24_IPHL.dirty || SEC24_IPHL.touched)" class="alert alert-danger">
                  <div *ngIf="SEC24_IPHL.errors?.pattern">section 24 should be valid.</div>
                </div>
              </td>
              <td *ngIf="this.Form12BBModel.ShowTextMessage" style=" text-align: right;">&nbsp;{{Form12BBModel.SEC24_IPHL}}</td>
              <td>&nbsp;Max Limit 2.00 Lac</td>
            </tr>
            <tr>
              <td style=" text-align: center;" colspan="4">Verification</td>
            </tr>
            <tr>
              <td style=" text-align: left;" colspan="4">
                <br>
                I, {{this.Form12BBModel.EmployeeName}},son/daughter of Sri {{this.Form12BBModel.FathersName}}. do hereby certify that the information given above is complete and correct.
                <br>
                <br>
              </td>
            </tr>
            <tr>
              <td colspan="2">&nbsp;Place :</td>
              <td colspan="2">&nbsp;</td>
            </tr>
            <tr>
              <td colspan="2">&nbsp;Date :</td>
              <td colspan="2">&nbsp;(Signature of the employee)&nbsp;&nbsp;&nbsp;</td>
            </tr>
            <tr>
              <td colspan="2" >&nbsp;Designation : {{this.Form12BBModel.DesignationName}}</td>
              <td colspan="2">&nbsp;Full Name : {{this.Form12BBModel.EmployeeName}}&nbsp;&nbsp;&nbsp;</td>
            </tr>
            <tr>
              <td colspan="4">&nbsp;</td>
            </tr>
          </table>
        </div>

        <div style="padding-left:5%; padding-right:5%;">
          <table>
            <tr>
              <td *ngIf="this.Form12BBModel.SaveButtonView"><button type="submit" [disabled]="!(EmployeeForm12BBForm.valid)" (click)="SaveForm12BB()" class="btn btn-primary save-button">Save Form 12BB</button></td>
              <td *ngIf="this.Form12BBModel.SaveButtonView"><button type="submit" [disabled]="!(EmployeeForm12BBForm.valid)" (click)="FinalSaveForm12BB()" class="btn btn-primary save-button">Final Submit</button></td>
              <td *ngIf="this.Form12BBModel.ShowTextMessage== true && this.Form12BBModel.RefId > 0"><button type="submit" (click)="print()" class="btn btn-primary save-button">Print</button></td>
            </tr>
          </table>
        </div>
      </form>
    </fieldset>
  </div>
</div>