<!-- <perfect-scrollbar style="max-width: 1260px; max-height: 540px;" [config]="config"> -->

<div>
  <app-dialog [(visible)]="this.SuccessStatus">
    <span class="successmsg">{{this.SuccessMessage}}</span>
  </app-dialog>
</div>
<div class="body-content-area tabcontent" id="link1">
  <div class="row">
    <div class="col-lg-9">
      <div class="row">
        <div class="col-lg-12">
          <span class="page-title">FOREX CRM</span>
          <small class="page-description m-b15">Industry is the mother of good luck </small>
        </div>
      </div>
    </div>
  </div>

  <div>
    <table id="Tbl_ForexCRM_CorporateDetails" datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger"
      class="table table-striped table-bordered text-center table-new row-border hover">
      <thead class="thead-blue text-center">

        <tr>
          <th>Sl No.</th>
          <th class="text-center">Company Name</th>
          <th class="text-center">Customer Name</th>
          <th class="text-center">Organisation Name</th>
          <th class="text-center">Next Meeting / Follow Date</th>
          <th class="text-center">Currency Volume/Yr (USD)</th>
          <th class="text-center">Card Volume/Yr (USD)</th>
          <th class="text-center">Timeline For Conversation</th>
          <th>Action</th>
        </tr>

      </thead>

      <tbody>

        <tr *ngFor="let f of forexcrmlist, let i = index">
          <td scope="row" style="color:black !important" >{{i+1}} </td>
          <td class="text-center" style="color:black" >{{f.CompanyName}}</td>
          <td class="text-center" style="color:black" >{{f.CustomerName}}</td>
          <td class="text-center" style="color:black" >{{f.OrganizationName}}</td>
          <td class="text-center" style="color:red" ><b>{{f.NextMeetingDate}}</b> </td>
          <td class="text-center" style="color:black">{{f.CurrencyVolume}}</td>
          <td class="text-center" style="color:black">{{f.CardVolume}}</td>
          <td class="text-center" style="color:red"><b>{{f.TimelineForConversation}} [ DAY/DAYS ]</b></td>



          <td>


            <button *ngIf="this.SelectedMenu.CanEdit" type="button" (click)="GetForexCRMById(f.ForexCrmId)"
              class="table-edit-btn">
              <i class="fas fa-edit"></i> </button>

              <button  *ngIf="this.SelectedMenu.CanDelete" type="button"(click)="DeleteForexCRM(f.ForexCrmId )" 
              class="table-remove-btn">
              <i class="fas fa-trash-alt"></i>
            </button>

          </td>




      </tbody>
    </table>
    <div>







    </div>

    <div class="row justify-content-start">
      <div class="col-10">
        <section class="crm-form-section">
          <form #CompanyDetails="ngForm" (ngSubmit)="saveForexCRMCorporateDetails()">

            <fieldset class="form-category">
              <h2>Organisation Profile</h2>


              <div class="row">
                <div class="col-sm-6 col-md-6">
                  <div class="form-group">
                    <label>COMPANY NAME</label>
                    <input type="text" [(ngModel)]="Forex_CRM_CorporateDetails_Model.CompanyName" tabindex="1"
                      maxlength="250" id="CompanyName" name="CompanyName" #CompanyName="ngModel" required
                      class="form-control">
                  </div>
                </div>

                <div class="col-sm-6 col-md-6">
                  <div class="form-group">
                    <label> COMPANY TYPE </label>
                    <select [(ngModel)]="Forex_CRM_CorporateDetails_Model.CompanyTypeId" tabindex="2" id="CompanyTypeId"
                      class="form-control" name="CountryId" #CountyId="ngModel">
                      <option [value]="0" [disabled]=true>Select Company</option>
                      <option *ngFor="let c of companytypelist" [value]="c.CompanyTypeId">{{c.CompanyType}}</option>
                    </select>
                  </div>
                </div>

              </div>


            </fieldset>


            <fieldset class="form-category">

              <h2>Customer Profile</h2>
              <div class="row">
                <div class="col-sm-6 col-md-6">
                  <div class="form-group">
                    <label>NAME</label>
                    <input type="text" [(ngModel)]="Forex_CRM_CorporateDetails_Model.CustomerName" tabindex="3"
                      maxlength="250" id="CustomerName" name="CustomerName" #CustomerName="ngModel" required
                      class="form-control">
                  </div>
                </div>

                <div class="col-sm-6 col-md-6">
                  <div class="form-group">
                    <label>E-MAIL ID</label>
                    <input type="text" [(ngModel)]="Forex_CRM_CorporateDetails_Model.CustomerEmailId" tabindex="4"
                      maxlength="250" id="CustomerEmailId" name="CustomerEmailId" #CustomerEmailId="ngModel" required
                      class="form-control">
                  </div>
                </div>

                <div class="col-sm-6 col-md-6">
                  <div class="form-group">
                    <label>CONTACT NUMBER</label>
                    <input type="text" [(ngModel)]="Forex_CRM_CorporateDetails_Model.CustomerContactNo" tabindex="5"
                      maxlength="16" id="CustomerContactNo" name="CustomerContactNo" #CustomerContactNo="ngModel"
                      myNumberOnly required class="form-control">
                  </div>

                  <div class="col-sm-6 col-md-6">
                    <div class="form-group">
                      <label>  DATE OF BIRTH </label>
                      <div class="input-group">
                        <input type="date" style="width:90%;" name="CustomerDOB" 
                        [ngModel]="this.Forex_CRM_CorporateDetails_Model.CustomerDOB | date:'yyyy-MM-dd'" 
                        #CustomerDOB="ngModel" tabindex="6" (ngModelChange)="DOB($event)" required/>
  
                        <!-- <input type="text" [(value)]="this.Forex_CRM_CorporateDetails_Model.AnniversaryDateMoreinfo"
                          readonly="readonly" [minimumDate]="false" [onlyDatePicker]="true" id="AniversaryDate_id"
                          myDatepicker (dateChange)="AniversaryDate($event)" tabindex="6" class="form-control" /> -->
  
                      </div>
                    </div>
                  </div>


                </div>

                <div class="col-sm-6 col-md-6">
                  <div class="form-group">
                    <label> ANNIVERSARY DATE</label>
                    <div class="input-group">
                      <input type="date" style="width:90%;" name="CustomerAniversaryDate" 
                      [ngModel]="this.Forex_CRM_CorporateDetails_Model.CustomerAniversaryDate | date:'yyyy-MM-dd'" 
                      #CustomerAniversaryDate="ngModel" tabindex="6" (ngModelChange)="AniversaryDate($event)" required/>



                      <!-- <input type="text" [(value)]="this.Forex_CRM_CorporateDetails_Model.AnniversaryDateMoreinfo"
                        readonly="readonly" [minimumDate]="false" [onlyDatePicker]="true" id="AniversaryDate_id"
                        myDatepicker (dateChange)="AniversaryDate($event)" tabindex="6" class="form-control" /> -->

                    </div>
                  </div>
                </div>


              </div>

            </fieldset>

            <fieldset class="form-category">
              <h2>Types Of Organisation</h2>
              <form>
                <div class="row">
                  <div class="col-sm-6 col-md-6">
                    <div class="form-group">
                      <label>ORGANISATION NAME</label>
                      <input type="text" [(ngModel)]="Forex_CRM_CorporateDetails_Model.OrganizationName" tabindex="7"
                        maxlength="250" id="OrganizationName" name="OrganizationName" #OrganizationName="ngModel"
                        required class="form-control">
                    </div>
                  </div>

                  <div class="col-sm-6 col-md-6">
                    <div class="form-group">
                      <label> ORGANISATION TYPE </label>
                      <select [(ngModel)]="this.Forex_CRM_CorporateDetails_Model.OrganizationTypeId" tabindex="8"
                        id="OrganisationTypeId" class="form-control" name="OrganizationType"
                        #OrganizationType="ngModel">
                        <option [value]="0" [disabled]=true>Select Organisation Type</option>
                        <option *ngFor="let o of organisationtypelist" [value]="o.OrganisationTypeId">
                          {{o.OrganisationType}}
                        </option>
                      </select>

                    </div>
                  </div>

                </div>
              </form>
            </fieldset>

            <fieldset class="form-category">
              <h2>Meeting details</h2>
              <div class="row">
                <div class="col-sm-12 col-md-12">
                  <div class="form-group">
                    <div class="form-check form-check-inline">
                      <label class="form-check-label" for="ProposalStatus1">POSITIVE</label>
                      <input class="form-check-input" type="checkbox" tabindex="9"
                        [(ngModel)]="Forex_CRM_CorporateDetails_Model.IsMeetingDetailsPositive"
                        id="IsMeetingDetailsPositive" name="IsMeetingDetailsPositive"
                        #IsMeetingDetailsPositive="ngModel" (ngModelChange)="MeetingDetailsStatusChamge(1)" />
                    </div>

                    <div class="form-check form-check-inline">
                      <label class="form-check-label" for="ProposalStatus2">HO SUPPORT REQUIRED</label>
                      <input class="form-check-input" type="checkbox" tabindex="10"
                        [(ngModel)]="Forex_CRM_CorporateDetails_Model.IsMeetingDetailsHODSupportRequired"
                        id="IsMeetingDetailsHODSupportRequired" name="IsMeetingDetailsHODSupportRequired"
                        (ngModelChange)="MeetingDetailsStatusChamge(2)" #IsMeetingDetailsHODSupportRequired="ngModel" />
                    </div>

                    <div class="form-check form-check-inline">
                      <label class="form-check-label" for="ProposalStatus3">NEGETIVE</label>
                      <input class="form-check-input" type="checkbox" tabindex="11"
                        [(ngModel)]="Forex_CRM_CorporateDetails_Model.IsMeetingDetailsIsNegetive"
                        id="IsMeetingDetailsIsNegetive" name="IsMeetingDetailsIsNegetive"
                        #IsMeetingDetailsIsNegetive="ngModel" (ngModelChange)="MeetingDetailsStatusChamge(3)" />
                    </div>

                    <div class="form-check form-check-inline">
                        <label>OTHERS/ADD DETAILS</label>
                        <input type="text" [(ngModel)]="Forex_CRM_CorporateDetails_Model.OtherDetails" tabindex="1"
                          maxlength="250" id="OtherDetails" name="OtherDetails" #OtherDetails="ngModel" required
                          class="form-control">
                        </div>
               



                  </div>
                </div>
              </div>
            </fieldset>


            <fieldset>
              <div class="form-category">
                <h2>Proposal status</h2>
                <div class="row">
                  <div class="col-sm-12 col-md-8">
                    <div class="form-group">
                      <div class="form-check form-check-inline">
                        <input class="form-check-input m-r20-res" type="radio" [value]="1" tabindex="12"
                          [checked]="this.Forex_CRM_CorporateDetails_Model.ProposalStatus==true"
                          (click)="OnProposalStatus(1)" id="ProposalStatus" name="ProposalStatus">
                        <label class="form-check-label" for="ProposalStatus"> PROPOSAL SHARE WITH COMMERCIAL </label>
                     



                        <div class="form-check form-check-inline">
                          <div class="col-sm-7 clear-left-padding">
                            <button type="button" (click)="Uploaddocument(template)" class="btn btn-primary add_new_btn">
                              <span>Upload Documents</span>
                            </button>
                          </div>
                        </div>
            



                      </div>
                    </div>

                    <div class="form-group">
                      <div class="row">
                        <div class="col-sm-5 col-md-5">
                          <div class="form-check">
                            <label class="form-check-label m-tr32"> NEXT MEETING/FOLLOW</label>
                          </div>
                        </div>
                        <div class="col-sm-7 col-md-7">
                          <div class="input-group">

                              <input type="date" style="width:90%;" name="NextMeetingDate" 
                              [ngModel]="this.Forex_CRM_CorporateDetails_Model.NextMeetingDate | date:'yyyy-MM-dd'" 
                              tabindex="13" (ngModelChange)="NextMeetingDate($event)" required/>
      

                            <!-- <input type="text" [(value)]="this.Forex_CRM_CorporateDetails_Model.AnniversaryDateMoreinfo" readonly="readonly"
                                [minimumDate]="false" [onlyDatePicker]="true" id="AniversaryDate_id" myDatepicker
                                (dateChange)="AniversaryDate($event)" tabindex="6"
                                class="form-control" />{{this.Forex_CRM_CorporateDetails_Model.AnniversaryDateMoreinfo}} -->

                            <!-- <input type="text" [(value)]="this.Forex_CRM_CorporateDetails_Model.NextMeetingDateMoreinfo"
                              readonly="readonly" [minimumDate]="false" [onlyDatePicker]="true" id="NextMeetingDate_id"
                              myChangableDatepicker (dateChange)="NextMeetingDate($event)" tabindex="13"
                              class="form-control" /> -->

                          </div>
                        </div>

                      </div>
                    </div>

                    <div class="form-group">
                      <div class="row">
                        <div class="col-sm-5 col-md-5">
                          <div class="form-check">
                            <label class="form-check-label m-tr32">TIMELINE FOR CONVERSATION</label>
                          </div>
                        </div>
                        <div class="col-sm-7 col-md-7">
                          <div class="input-group">
                            <input type="text" [(ngModel)]=" Forex_CRM_CorporateDetails_Model.TimelineForConversation"
                              tabindex="14" maxlength="3" id="TimelineForConversation" name="TimelineForConversation"
                              #TimelineForConversation="ngModel" myNumberOnly required class="form-control">
                            <div class="input-group-append">
                              <div class="input-group-text">
                                <i class="fas fa-calendar-check" class="text-right"> <b>DAY/DAYS</b> </i>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                  </div>
                </div>
              </div>

            </fieldset>


            <fieldset>
              <div class="form-category">
                <h2>Financials</h2>
                <div class="form-group">
                  <div class="row">
                    <div class="col-sm-12 col-md-6">
                      <div class="row no-gutters">
                        <div class="col-sm-6 col-md-6">
                          <label for="CurrencyVolume" class="m-t8">CURRENCY VOLUME/YEAR(USD)</label>
                        </div>
                        <div class="col-sm-6 col-md-6">
                          <input type="text" maxlength="10"
                            [(ngModel)]="Forex_CRM_CorporateDetails_Model.CurrencyVolume" class="form-control m-b15"
                            id="CurrencyVolume" name="CurrencyVolume" tabindex="15" #CurrencyVolume="ngModel"
                            myNumberOnly />
                        </div>
                      </div>
                    </div>
                    <div class="col-sm-12 col-md-6">
                      <div class="row no-gutters">
                        <div class="col-sm-6 col-md-6">
                          <label for="CardVolume" class="m-t8">CARD VOLUME/YEAR(USD)</label>
                        </div>
                        <div class="col-sm-6 col-md-6">
                          <input type="text" maxlength="10" [(ngModel)]="Forex_CRM_CorporateDetails_Model.CardVolume"
                            tabindex="16" class="form-control" id="CardVolume" name="CardVolume" #CardVolume="ngModel"
                            myNumberOnly />
                        </div>
                      </div>
                    </div>
                  </div>

                </div>
              </div>

            </fieldset>



            <fieldset>
              <div class="form-category">
                <h2>LOCATION TO BE SERVED</h2>
                <div class="form-group">
                  <div class="row">
                    <div class="col-sm-12 col-md-12">
                      <angular2-multiselect tabindex="17" class="form-control" [data]="LocationArray"
                        [(ngModel)]="SelectedLocation" name="LocationServed" [settings]="LocationSettings"
                        (onSelect)="onItemSelecLocation($event)" (onDeSelect)="OnItemDeSelectLocation($event)"
                        (onSelectAll)="onSelectAllLocation($event)" (onDeSelectAll)="onDeSelectAllLocation($event)">
                      </angular2-multiselect>
                    </div>
                  </div>
                </div>
              </div>
            </fieldset>

            <div class="form-category m-tb38">
              <button type="submit" tabindex="18" class="btn save-btn m-r14">
                Save </button>
              <button type="button" tabindex="19" (click)="RefreshPage()" class="btn cancel-btn">Cancel</button>
            </div>

          </form>


        </section>
      </div>
    </div>


  </div>
</div>
<!-- </perfect-scrollbar> -->



<div style=" box-align: center; width: 1000px;">


  <ng-template #template>

    <div class="modal-header" style="background: #2a9bd7; max-width: 1000px;">

      <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()" style="color:#fff;">
        <span aria-hidden="true">X</span>
      </button>
    </div>
    <div style="text-align: center; max-width: 900px;padding-left: 10%">



      <fieldset class="body-form-control">

        <legend class="legend--">Add / Update Documents</legend>
        <form #ApprovedApplicationForm="ngForm">
          <div class="form-group">
            <label>File Uploads</label>
            <input type="file" (change)="fileuploads($event)" multiple class="form-control" style="height:100%">

          </div>
          <div lass="row">
            <table id="tblforexCRMDocuments" class="table table-striped table-bordered text-left table-new row-border hover">
              <thead class="thead-blue text-center">
                <tr>
                  <th style="width: 48%;">File Name</th>
                  <th style="width: 10%;">Document Type</th>
                  <th style="width: 10%;">Size</th>
                  <th style="width: 10%;">Extension</th>
                  <th style="width: 14%;">Action</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let Files of this.FileUploads, let i = index">
                  <td>

                    {{Files.DocumentActualName}}

                  </td>
                  <td>
                    {{Files.DocumentType}}
                  </td>
                  <td>
                    {{Files.DocumentSize}}
                  </td>
                  <td>
                    {{Files.DocumentExtension}}
                  </td>

                  <td>

                    <button *ngIf="Files.DocumentId > 0" type="button"
                      (click)="DocDownLoad(Files.DocumentId,Files.DocumentType)" class="table-remove-btn">
                      <i class="fa fa-download"></i>
                    </button>


                    <button *ngIf="Files.DocumentId == 0" type="button" (click)="DeleteFile(Files)"
                      class="table-remove-btn">
                      <i class="fas fa-trash-alt"></i>
                    </button>


                  </td>



                </tr>
              </tbody>
            </table>


            <div class="row float-right">
              <div class=" col-auto text-center">
                <button type="button" tabindex="22" class="btn btn-primary save-button" (click)="save()">Save </button>
              </div>
              <div class=" col-auto text-center">
                <button type="button" tabindex="23" class="btn btn-danger cancle-button"
                  (click)="CancleModelPopup()">Cancel</button>
              </div>
            </div>


          </div>

        </form>
      </fieldset>
    </div>



  </ng-template>
</div>