import {OfferStandardSalary}  from '../Salary/OfferStandardSalary';

export class OfferDetails
{
    public OfferId : Number;
    public OfferDate : Date;
    public FirstName : string;
    public MiddleName : string;
    public LastName : string;
    public Address1 : string;
    public Address2 : string;
    public DistrictId : Number;
    public CountryId : Number;
    public StateId : Number;
    public CityId : Number;
    public PinCode : Number;
    public ContactNumber : Number;
    public EmailId : string;
    public CompanyId : Number;
    public BranchId : Number;
    public DepartmentId : Number;
    public DecisionId : Number;
    public LevelId : Number;
    public GradeId : Number;
    public DesignationId : Number;
    public joiningDate : Date;
    public Approved : boolean;
    public CTCAmount : Number;
    public NetTakeHome : Number;
    public SerialNumber : string;
    public CreatedBy : Number;
    public CreatedDate : Date;
    public UpdatedBy : Number;
    public UpdatedDate : Date;
    public IsActive : boolean;
    public offersSalary :  OfferStandardSalary [];
    //PF
    public  PFEmployeeContributation :number;
    public  PTax : Number;
    //PF
    public EPFApplicable : boolean;
    public ESIApplicable : boolean;
    public PTAXApplicable : boolean;
    public GrossSalary : number;
    public RegionHRId : number;
}