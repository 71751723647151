export class Model_CRM_Lead_Documents
{
    public  DocumentId : number;
    public  LeadId : number;    
    public  File  : string;
    public  DocumentActualName : string;
    public  DocumentType : string;
    public  DocumentExtension : string;
    public  DocumentName  : string;
    public  DocumentSize : string;
    public  Isactive : boolean;
   
}