import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AdminMasterService }  from '../../shared/HR_ADMIN/Admin/AdminMasterService.service';
import { HRPoliciesFormModel }  from '../../shared/HR_ADMIN/Admin/HRPoliciesFormModel.Model';
import { UserService } from '../../shared/user.service';
// Begning Confirmation
import { ConfirmationService } from '@jaspero/ng2-confirmations';
import { ResolveEmit } from '../../../app/resolve-emit';
import { ConfirmSettings } from '../../../app/confirm-settings';
// end of Confirmation
import { Menu } from '../../shared/menu.model';
import { ModalOptions,BsModalRef, BsModalService } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-hrpolicies-form',
  templateUrl: './hrpolicies-form.component.html',
  styleUrls: ['./hrpolicies-form.component.css']
})
export class HrpoliciesFormComponent implements OnInit 
{
  HRPoliciesFormModel : HRPoliciesFormModel= new HRPoliciesFormModel();
  userClaims: any;
  HRPolicies: HRPoliciesFormModel[] = [];
  // Result : string;
  // SuccessStatus:boolean=false;
  // SuccessMessage: string ="";
  // public tableWidget: any;
  RefId : any;
  // Begning Permission Portion    
  SelectedMenu:Menu;
  IsDataIsInViewState : boolean=false;
  CanViewState : boolean=false;
  DisableSave :boolean=false;
  // End of the permission portion
  //For Pop up
  SaveMsg : boolean= false;
  UpdateMsg : boolean= false;
  DeleteMsg : boolean=false;
  ShowPopup : boolean= false;
  public modalRef: BsModalRef;
  public modelopt:ModalOptions;

  allfiles:any=[];

  constructor(private AdminMasterService: AdminMasterService, private _confirmation: ConfirmationService, private rout: Router, private userService: UserService,private modalService: BsModalService)
  {
    this.modelopt=new ModalOptions();
    this.modelopt.class='heavy_modal';
    this.modalService.config= this.modelopt;
    this.modelopt.ignoreBackdropClick=true;
  }

  ngOnInit() 
  {
    //Begin Permission Portion
    this.SelectedMenu=this.userService.SelectedMenu;
    if(this.SelectedMenu !=undefined)
    {
      localStorage.setItem('selectedmenu',JSON.stringify(this.SelectedMenu));
      this.rout.navigate([this.SelectedMenu.RouteName]);
    }
    else
    {
      if(JSON.parse(localStorage.getItem('selectedmenu'))!=undefined)
      {
        this.SelectedMenu = JSON.parse(localStorage.getItem('selectedmenu'));
        this.rout.navigate([this.SelectedMenu.RouteName]);
      }
    }     
    if(this.SelectedMenu.CanAdd)
    {
      this.IsDataIsInViewState=false;
    }
    else
    {
      this.IsDataIsInViewState=true;
    }
    if(this.SelectedMenu.CanView)
    {
      this.CanViewState=false;
    }
    else
    {
      this.CanViewState=true;
    }
    //End of the peremission portion
    this.SaveMsg=false;
    this.UpdateMsg=false;
    this.DeleteMsg=false;
    this.ShowPopup=false;
    this.DisableSave=true; 
    this.GETHRPoliciesForm();    
  }

  settings: ConfirmSettings | any = 
  {
    overlay: true,
    overlayClickToClose: true,
    showCloseButton: true,
    confirmText: 'Yes',
    declineText: 'No'
  };

  GETHRPoliciesForm()
  {
    this.HRPolicies =[];
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.AdminMasterService.GetHRPoliciesFormGV( t).then(x => 
    {
      this.HRPolicies =[];
      this.RefId = x;
      Object.keys(this.RefId).forEach( key => 
      {
        this.RefId[key];
        let Policiestemp = new HRPoliciesFormModel();
        Policiestemp = this.RefId[key];
        this.HRPolicies.push(Policiestemp);
      });
    });
  }

  GetHRPoliciesFormByRefId(id,template) 
  {
    // Begning of the permission portion
    this.IsDataIsInViewState=false;
    // End of the permision portion
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.AdminMasterService.GetHRPoliciesFormByRefId( t,id).then(x => 
    {
      if(this.ShowPopup==true)
      {
        this.modalRef.hide();
      }
      this.modalRef = this.modalService.show(template,this.modelopt);
      this.ShowPopup=true;
      this.SaveMsg=false;
      this.UpdateMsg=false;
      this.DeleteMsg=false;
      this.RefId = x;
      Object.keys(this.RefId).forEach( key => 
      {
        this.RefId[key];
        let temp = new HRPoliciesFormModel();
        temp = this.RefId[key];
        this.HRPoliciesFormModel.RefId=temp.RefId;
        this.HRPoliciesFormModel.PolicyName=temp.PolicyName;
        this.HRPoliciesFormModel.FileName=temp.FileName;
        this.HRPoliciesFormModel.FromDate=temp.FromDate;
        this.HRPoliciesFormModel.ToDate=temp.ToDate;
      });
    });
  }

  SaveHRPoliciesForm(template) :void
  {
    this.DisableSave=false;
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    if(this.HRPoliciesFormModel.RefId > 0)
    {
      this.AdminMasterService.UpdateHRPoliciesForm(t ,this.HRPoliciesFormModel).subscribe(res=>
      {
        this.RefId=res;  
        if(this.RefId>0)
        {
          this.HRPoliciesFormModel.RefId=0;
          this.HRPoliciesFormModel.PolicyName=null;
          this.HRPoliciesFormModel.FromDate=null;
          this.HRPoliciesFormModel.ToDate=null;
          this.HRPoliciesFormModel.File=null;
          this.HRPoliciesFormModel.FileExtension=null;
          this.HRPoliciesFormModel.FileName=null;
          this.HRPoliciesFormModel.Filesize=null;
          this.modalRef.hide();
          this.SaveMsg=false;
          this.UpdateMsg=true;
          this.DeleteMsg=false;
          this.ShowPopup=false;
          this.modalRef = this.modalService.show(template);
          this.GETHRPoliciesForm();
          this.DisableSave=true;
        }
      });
    }
    else
    {
      this.AdminMasterService.AddHRPoliciesForm(t ,this.HRPoliciesFormModel).subscribe(res=>
      {
        this.RefId=res;            
        if(this.RefId>0)
        {
          this.HRPoliciesFormModel.RefId=0;
          this.HRPoliciesFormModel.PolicyName=null;
          this.HRPoliciesFormModel.FromDate=null;
          this.HRPoliciesFormModel.ToDate=null;
          this.HRPoliciesFormModel.File=null;
          this.HRPoliciesFormModel.FileExtension=null;
          this.HRPoliciesFormModel.FileName=null;
          this.HRPoliciesFormModel.Filesize=null;
          this.modalRef.hide();
          this.SaveMsg=true;
          this.UpdateMsg=false;
          this.DeleteMsg=false;
          this.ShowPopup=false;
          this.modalRef = this.modalService.show(template);
          this.GETHRPoliciesForm();
          this.DisableSave=true;
        }
      }); 
    }
  }

  DeleteHRPoliciesFormById(RefId : number,template) :void
  {
    this._confirmation.create('Confirm to Deleate', 'Press Yes to Delete', this.settings)
    .subscribe((ans: ResolveEmit) => 
    {
      if (ans.resolved == true) 
      {
        let t =  localStorage.getItem('userToken');
        t = 'Bearer' + t;
        if( RefId > 0)
        {
          this.AdminMasterService.DeleteHRPoliciesForm(t ,RefId).subscribe(res=>
          {
            this.RefId=res;                                
            if(this.RefId >0)
            {
              this.HRPoliciesFormModel.RefId=0;
              this.HRPoliciesFormModel.PolicyName=null;
              this.HRPoliciesFormModel.FromDate=null;
              this.HRPoliciesFormModel.ToDate=null;
              this.HRPoliciesFormModel.File=null;
              this.HRPoliciesFormModel.FileExtension=null;
              this.HRPoliciesFormModel.FileName=null;
              this.HRPoliciesFormModel.Filesize=null;
              if(this.ShowPopup==true)
              {
                this.modalRef.hide();
              }
              this.SaveMsg=false;
              this.UpdateMsg=false;
              this.DeleteMsg=true;
              this.ShowPopup=false;
              this.modalRef = this.modalService.show(template);
              this.GETHRPoliciesForm();
              this.DisableSave=true;
            }
          });                  
        }
      }
    })
  }

  ADDClick(template)
  {
    this.HRPoliciesFormModel.RefId=0;
    this.HRPoliciesFormModel.PolicyName=null;
    this.HRPoliciesFormModel.FromDate=null;
    this.HRPoliciesFormModel.ToDate=null;
    this.HRPoliciesFormModel.File=null;
    this.HRPoliciesFormModel.FileExtension=null;
    this.HRPoliciesFormModel.FileName=null;
    this.HRPoliciesFormModel.Filesize=null;
    this.IsDataIsInViewState=false;
    if(this.ShowPopup==true)
    {
      this.modalRef.hide();
    }
    this.modalRef = this.modalService.show(template,this.modelopt);
    this.ShowPopup=true;
    this.SaveMsg=false;
    this.UpdateMsg=false;
    this.DeleteMsg=false;
    this.DisableSave=true;
    this.IsDataIsInViewState=false;
  }

  FromDateChanged( date)
  {
    this.HRPoliciesFormModel.FromDate=date;
  }

  ToDateChanged( date)
  {
    this.HRPoliciesFormModel.ToDate=date;
  }

  FileUploaded(event)
  {
    this.HRPoliciesFormModel.FileName=null;
    const files=event.target.files;
    //console.log(files);
    if(files)
    {
      for(let i=0; i<files.length; i++)
      {
        const image =
        {
          name :'',
          type : '',
          size: '',
          url:'',
          TRDRefId:'',
          ExpenseHeadId:''
        } ;
        this.allfiles.push(files[i]);
        image.name=files[i].name;
        image.type=files[i].type;
        const size= files[i].size /1000;
        const mbc =  size + '';
        const mb = mbc.split('.')[0];
        const length = mb.length;

        if(length===4 || length===5)
        {
          const mbsize= size /1000;
          const splitdata = mbsize + '';
          const splitvalue= splitdata.split('.');
          let secondvariable ='';
          for(let j=0; j<splitvalue.length; j++)
          {
            if(j===1)
            {
              secondvariable= splitvalue[j].slice(0,2);
            }
          }
          image.size=splitvalue[0]  + '.' + secondvariable + ' MB';
        }
        else
        {         
          const splitdata = size + '';
          const splitvalue= splitdata.split('.');
          let secondvariable ='';
          for(let j=0; j<splitvalue.length; j++)
          {
            if(j===1)
            {
              secondvariable= splitvalue[j].slice(0,2);
            }
          }
          image.size=splitvalue[0]  + '.' + secondvariable + ' KB';
        }

        var FileReades : FileReader= new FileReader();
        let filetyotypearray = image.type.split("/");
        var re = /(?:\.([^.]+))?$/;
        this.HRPoliciesFormModel.FileExtension = re.exec(image.name)[1];  
        this.HRPoliciesFormModel.FileType= filetyotypearray[0];
        this.HRPoliciesFormModel.Filesize= image.size;
        // image.size=files[i].size /1000;
        const reader= new FileReader();
        reader.onload=(filedata)=>
        {
          image.url=reader.result + '';
          this.HRPoliciesFormModel.File = String( reader.result);
        };
        reader.readAsDataURL(files[i]);
        this.HRPoliciesFormModel.FileName=image.name;
      }
      event.srcElement.value=null;
    }
  }

  DownloadHRPoliciesForm(FileName:string)
  {
    this.AdminMasterService.getFile(FileName);  
  }
}