<app-dialog [(visible)]="this.SuccessStatus">
  <span class="successmsg">{{this.SuccessMessage}}</span>
</app-dialog>



<perfect-scrollbar style="max-width: 1260px; max-height: 540px;" class="mukesh">


  <div class="body-content-area tabcontent" id="link1">
    <div class="row">
      <div class="col-lg-9">
        <div class="row">
          <div class="col-lg-1 low-padding text-center">
            <img src="assets/img/icon.png" class="body-icon" alt="Icon" />
          </div>
          <div class="col-lg-10 clear-padding">
            <span class="page-title">Schedule A Call</span>
            <small class="page-description">It is a long established fact that a reader will be distracted</small>
          </div>

        </div>
      </div>

    </div>


    <fieldset class="body-form-control">

      <legend class="legend--">Schedule A Call</legend>
      <div *ngIf="!(this.Documents.DocumentName =='')">
        <!-- <table id="tblDocument" class="table table-striped table-bordered text-left table-new row-border hover"> -->
        <table id="tblDocument" class="table table-striped table-bordered text-left table-new row-border">
          <thead class="thead-blue text-center">
            <tr>
              <th style="width: 25%;">File Name</th>
              <!-- <th style="width: 10%;">Document Type</th> -->
              <th style="width: 10%;">Size</th>
              <th style="width: 10%;">Extension</th>
              <th style="width: 14%;">Action</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>

                {{this.Documents.DocumentName}}

              </td>

              <td>
                {{this.Documents.DocumentSize}}
              </td>
              <td>
                {{this.Documents.DocumentExtension}}
              </td>

              <td>
                <button type="button" (click)="DeleteFile()" class="table-remove-btn">
                  <i class="fas fa-trash-alt"></i>
                </button>
              </td>



            </tr>
          </tbody>
        </table>
      </div>
      <div class="row">

        <div class="col-lg-4">
          <div class="col-body-border-right">
            <div class="form-group row">
              <label class="col-sm-4 col-form-label clear-padding-right">Company Name
                <span class="required-validation">*</span>
              </label>
              <div class="col-sm-8 clear-left-padding select_dropdown">

                <angular2-multiselect tabindex="5" class="form-control" [data]="CompanyArray"
                  [(ngModel)]="SelectedCompany" name="CompanyId" [settings]="this.CompanySettings"
                  (onSelect)="onItemSelectCompany($event)" (onDeSelect)="OnItemDeSelectCompany($event)"
                  (onSelectAll)="onSelectAllCompany($event)" (onDeSelectAll)="onDeSelectAllCompany($event)">
                </angular2-multiselect>

              </div>
            </div>
            <div class="form-group row">
              <label class="col-sm-4 col-form-label clear-padding-right">Opportunity Name</label>
              <div class="col-sm-7 clear-left-padding">

                <angular2-multiselect tabindex="5" class="form-control" [data]="OpportunityArray"
                  [(ngModel)]="Selectedopportunities" name="OpportunityId" [settings]="this.OpportunitySettings"
                  (onSelect)="onItemSelectOpportunity($event)" (onDeSelect)="OnItemDeSelectOpportunity($event)"
                  (onSelectAll)="onSelectAllOpportunity($event)" (onDeSelectAll)="onDeSelectAllOpportunity($event)">
                </angular2-multiselect>

              </div>
            </div>
            <div class="form-group row">
              <label class="col-sm-4 col-form-label clear-padding-right">Subject</label>
              <div class="col-sm-7 clear-left-padding">
                <!-- <textarea class="form-control form-control-custom form-control-custom-new" style="height:27px;"
                  rows="40" cols="20"></textarea> -->
                <textarea id="some-value" style="width:100%; height:100px;border-radius: 3px;
                      resize: none; border:1px solid #c3c3c3;"
                  (change)="SubjectChange($event)">{{this.CRM_ScheduleCall.Subject}}</textarea>

              </div>
            </div>
            <div class="form-group row">
              <label class="col-sm-4 col-form-label clear-padding-right">Call Type</label>
              <div class="col-sm-7 clear-left-padding">


                <select class="form-control form-control-custom form-control-custom-new"
                  [(ngModel)]="this.CRM_ScheduleCall.CallType" tabindex="15" id="CallTypeId" name="CallTypeId"
                  #CallTypeId="ngModel">
                  <option [value]="0" [disabled]=true>Select Call Type</option>
                  <option *ngFor="let R of CRM_Call_Types" [value]="R.CallTypeId">{{R.Calldescription}}</option>
                </select>
              </div>
            </div>
          </div>
        </div>

        <div class="col-lg-4">
          <div class="col-body-border-right">
            <div class="form-group row">
              <label class="col-sm-4 col-form-label clear-padding-right">Date</label>
              <div class="col-sm-7 clear-left-padding">
                <!-- <input type="date" class="form-control form-control-custom form-control-custom-new"> -->

                <input type="text" [(value)]="this.CRM_ScheduleCall.CallDateVal" readonly="readonly"
                  [minimumDate]="false" [onlyDatePicker]="true" id="Close_Date_id" myDatepicker
                  (dateChange)="CallDates($event)"
                  class="form-control form-control-custom form-control-custom-new ng-pristine ng-valid ng-touched" />
              </div>
            </div>
            <div class="form-group row">
              <label class="col-sm-4 col-form-label clear-padding-right">Start Time</label>
              <div class="col-sm-7 clear-left-padding">
                <!-- <input type="datetime-local" class="form-control form-control-custom form-control-custom-new"> -->
                <select [(ngModel)]="this.CRM_ScheduleCall.startTme" tabindex="15" id="StartTimeId" class="form-control"
                  name="StartTimeId" #StartTimeId="ngModel">
                  <option [value]="0" [disabled]=true>Select start Time</option>
                  <option *ngFor="let R of  this.StartimeList" [value]="R.TimeId">{{R.TimeDesc}}</option>
                </select>
                <div class="fleft ptop5">
                  <!--  [value]="Allday" -->
                  <input type="checkbox" (click)="OnChangeAlldayevent($event)"
                    [checked]="this.CRM_ScheduleCall.IsAllDay" id="AlldayId" name="AlldayId">

                  All Day Event

                </div>
                <div class="fright ptop5">
                  <!-- [value]="Prioritycall" -->
                  <input type="checkbox" (click)="OnChangePriorityCall($event)"
                    [checked]="this.CRM_ScheduleCall.IsPriorityCall" id="PriorityCallId" name="PriorityCallId">
                  Priority Call</div>
              </div>
            </div>
            <div class="form-group row">
              <label class="col-sm-4 col-form-label clear-padding-right">End Time</label>
              <div class="col-sm-7 clear-left-padding">
                <!-- <input type="datetime-local" class="form-control form-control-custom form-control-custom-new"> -->

                <select [(ngModel)]="this.CRM_ScheduleCall.Endtime" tabindex="15" id="EndtimeTimeId"
                  class="form-control" name="Endtime" #EndtimeTimeId="ngModel">
                  <option [value]="0" [disabled]=true>Select End Time</option>
                  <option *ngFor="let R of  this.StartimeList" [value]="R.TimeId">{{R.TimeDesc}}</option>
                </select>
              </div>
            </div>
            <div class="form-group row">
              <label class="col-sm-4 col-form-label clear-padding-right">Select Contacts</label>
              <div class="col-sm-7 clear-left-padding">
                <angular2-multiselect tabindex="5" class="form-control" [data]="this.ContactsArray"
                  [(ngModel)]="this.SelectedContacts" name="ContactId" [settings]="this.ContactsSettings"
                  (onSelect)="onItemSelectContacts($event)" (onDeSelect)="OnItemDeSelectContacts($event)"
                  (onSelectAll)="onSelectAllContacts($event)" (onDeSelectAll)="onDeSelectAllContacts($event)">
                </angular2-multiselect>

              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-4">
          <div class="form-group row">
            <label class="col-sm-4 col-form-label clear-padding-right">Invited Attendance</label>

            <div class="col-sm-7 clear-left-padding">
              <angular2-multiselect tabindex="5" class="form-control" [data]="this.AttendesArray"
                [(ngModel)]="this.SelectedAttendees" name="ContactId" [settings]="this.AttendenceSettings"
                (onSelect)="onItemSelectAttendees($event)" (onDeSelect)="OnItemDeSelectAttendees($event)"
                (onSelectAll)="onSelectAllAttendees($event)" (onDeSelectAll)="onDeSelectAllAttendees($event)">
              </angular2-multiselect>
            </div>
          </div>
          <div class="form-group row">
            <label class="col-sm-4 col-form-label clear-padding-right">Select Status</label>
            <div class="col-sm-7 clear-left-padding">


              <select class="form-control form-control-custom form-control-custom-new"
                [(ngModel)]="this.CRM_ScheduleCall.DispositionStatus" tabindex="15" id="DispositionStatusId"
                name="DispositionStatusId" #DispositionStatusId="ngModel">
                <option [value]="0" [disabled]=true>Select Status</option>
                <option *ngFor="let R of this.DispositionList" [value]="R.DispositionId">{{R.DispositionDesc}}</option>
              </select>
            </div>
          </div>
          <div class="form-group row">
            <label class="col-sm-4 col-form-label clear-padding-right">Note</label>
            <div class="col-sm-7 clear-left-padding">
              <!-- <textarea class="form-control form-control-custom form-control-custom-new" style="height:27px;" rows="40"
                cols="20"></textarea> -->
              <textarea id="some-value" style="width:100%; height:100px;border-radius: 3px;
                                      resize: none; border:1px solid #c3c3c3;"
                (change)="NotechangeChange($event)">{{this.CRM_ScheduleCall.Notes}}</textarea>

              <div class="fleft ptop5">
                <!--  [value]="Prioritycall -->

                <input type="checkbox" (click)="OnChangeComplete($event)"
                  [checked]="this.CRM_ScheduleCall.IsComplete" id="CallCompleteId" name="CallCompleteId">

                Mark Call as Completed</div>
            </div>
          </div>
          <div class="form-group row">
            <label class="col-sm-4 col-form-label clear-padding-right" *ngIf="(this.Documents.DocumentName =='')">Attach
              Documents</label>
            <div class="col-sm-7 clear-left-padding" *ngIf="(this.Documents.DocumentName =='')">
              <!-- <input type="file"  class="form-control form-control-custom form-control-custom-new"> -->
              <input type="file" (change)="fileuploads($event)" multiple class="form-control" style="height:100%">
            </div>
          </div>
          <div class="form-group row">
            <label class="col-sm-4 col-form-label text-center clear-padding-right" *ngIf="this.SubmitEnable">
              <button type="button" class="btn btn-primary save-button" (click)="saveCRMScheduleCall()">Save</button>
            </label>
            <!-- <label class="col-sm-4 col-form-label text-center clear-padding-right">
              <button type="button" class="btn btn-primary btn-success" data-toggle="modal" data-target="#myModal">Show
                Notes</button>
            </label> -->
            <div class="col-sm-4 col-form-label text-center">
              <button type="button" class="btn btn-danger cancle-button" (click)="CancleScheduleCall()">Cancel</button>
            </div>
          </div>
        </div>
      </div>
    </fieldset>

  </div>
</perfect-scrollbar>