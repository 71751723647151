export class EmployeeBank
{
    public RefId : Number;
    public EmployeeId : Number;
    public EmployeeCode : string;
    public BankId : Number;
    public BranchName : string;
    public AccountNumber : string;
    public IFSCCode : string;
    public BankName : string;
    public IsActive : boolean;
    public EmployeeName : string;
    public OldBankId : Number;
}