import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs/Subject';
import { Menu } from '../../../shared/menu.model';
import { UserService } from '../../../shared/user.service';
import { Model_CrmCountry } from '../../../shared/CRM/MICECRM/Model_CrmCountry';
import { CrmCountryService } from '../../../shared/CRM/MICECRM/CrmCountryservice';
//Begin For Notification service
import { NotificationsService } from '../../../shared/notifications.service';
import { ResolveEmit } from '@jaspero/ng2-confirmations/src/interfaces/resolve-emit';
import { ConfirmationService } from '@jaspero/ng2-confirmations';
//End of notification service

@Component({
  selector: 'app-crmcountry',
  templateUrl: './crmcountry.component.html',
  styleUrls: ['./crmcountry.component.css']
})

export class AddCrmCountryComponent implements OnInit {

  checkNameval: string;
  Namecheck: string;
  CheckNameExiststatus: boolean;
  CheckNamestatusedit: boolean;
  DeleteCrmCountryByID: number = 0;
  settings: any;
  //_confirmation: any;
  SuccessMessage: string;
  SuccessStatus: boolean;
  dtOptions: { pagingType: string; pageLength: number; };
  DisableSave: boolean;
  dtTrigger: Subject<any> = new Subject();
 
  crmcountry: Model_CrmCountry[] = [];
  CRMCountry: any;
 
  CountryId: any;
  CRM_Country_Model: Model_CrmCountry;


  // Begning Permission Portion    
  SelectedMenu: Menu;
  IsDataIsInViewState: boolean = false;
  CanViewState: boolean = true;
  selectedIndex: number;
  // End of the permission portion

  constructor(private AddCrmCountry: CrmCountryService, private rout: Router, public userService: UserService, private notificationsService: NotificationsService, private _confirmation: ConfirmationService,) {

    this.CRM_Country_Model = new Model_CrmCountry();
    this.CRM_Country_Model.CountryName='';
    notificationsService.emitChange.subscribe(slectedtabindex => {
      if (slectedtabindex != null) {
        this.selectedIndex = slectedtabindex;
      }
    });
  }

  ngOnInit() {
    this.rout.navigate([this.userService.selectedCrmMenu.RouteName]);

    //Begin Permission Portion
    // this.SelectedMenu = this.userService.SelectedMenu;
    // if (this.SelectedMenu != undefined) {
    //   localStorage.setItem('selectedmenu', JSON.stringify(this.SelectedMenu));
    //   this.rout.navigate([this.SelectedMenu.RouteName]);
    // }
    // else {
    //   if (JSON.parse(localStorage.getItem('selectedmenu')) != undefined) {
    //     this.SelectedMenu = JSON.parse(localStorage.getItem('selectedmenu'));
    //     this.rout.navigate([this.SelectedMenu.RouteName]);
    //   }
    // }
    // if (this.SelectedMenu.CanAdd) {
    //   this.IsDataIsInViewState = false;
    // }
    // else {
    //   this.IsDataIsInViewState = true;
    // }
    // if (this.SelectedMenu.CanView) {
    //   this.CanViewState = false;
    // }
    // else {
    //   this.CanViewState = true;
    // }
    //End of the peremission portion
    this.DisableSave = true;
    this.dtOptions =
      {
        pagingType: 'full_numbers',
        pageLength: 4
      };
    if (localStorage.getItem('SuccessfullMessage')) {
      this.SuccessStatus = true;
      this.SuccessMessage = localStorage.getItem('SuccessfullMessage')
      localStorage.removeItem('SuccessfullMessage');
    }

  }

  ngAfterViewInit(): void {
    this.GetCRMCountry();
    
    this.dtOptions =
      {
        pagingType: 'full_numbers',
        pageLength: 4
      };
  }


  SaveCrmCountry(): void {
    //this.IsNotBusy=true;
    let t = localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.CRM_Country_Model.IsActive = true;

    if (this.CRM_Country_Model.CountryId > 0) {

      this.AddCrmCountry.UpdateCrmCountry(t, this.CRM_Country_Model).subscribe(res => {
       
        this.CountryId = res;
        this.CRM_Country_Model.CountryId = 0;

        if (this.CountryId > 0) {
          localStorage.setItem('SuccessfullMessage', "Successfully Updated");
          this.rout.navigateByUrl('ManageMICEAccess/ManageMICEUser', { skipLocationChange: true }).then(() => this.rout.navigate(["ManageMICEAccess/CrmCountry"]));

        }
      });
    }
    else {
      this.AddCrmCountry.AddCrmCountry(t, this.CRM_Country_Model).subscribe(res => {
        this.CountryId = res;
        this.CRM_Country_Model.CountryId = 0;

        if (this.CountryId > 0) {
          localStorage.setItem('SuccessfullMessage', "Successfully Added");
          this.rout.navigateByUrl('ManageMICEAccess/ManageMICEUser', { skipLocationChange: true }).then(() => this.rout.navigate(["ManageMICEAccess/CrmCountry"]));

        }
      });
    }
  }
  //For The Grid
    GetCRMCountry():  void{
    let t = localStorage.getItem('userToken');
    t = 'Bearer' + t;
      this.AddCrmCountry.GetCRMCountry(t).then(x => {
      this.CRMCountry = x;
        Object.keys(this.CRMCountry).forEach(key => {
          this.CRMCountry[key];
          let CRMCountrytemp = new Model_CrmCountry();
          CRMCountrytemp = this.CRMCountry[key];
          
          this.crmcountry.push(CRMCountrytemp);
      });
      this.dtTrigger.next();
    });
  }


  //  For Edit  Or  Update

  

  DeleteCrmCountry(ID: number): void {
     //alert(ID);
    this._confirmation.create('Confirm to Delete', 'Press Yes to Delete', this.settings)
      .subscribe((ans: ResolveEmit) => {
        //alert("ok");
        if (ans.resolved == true) {
        //  alert("ok1");

          let t = localStorage.getItem('userToken');
          t = 'Bearer' + t;

          if (ID > 0) {
          //  alert("ok2");
            this.AddCrmCountry.Delete_CrmCountry(t, ID).subscribe(res => {
              this.DeleteCrmCountryByID = Number(res);
             
              if (this.DeleteCrmCountryByID > 0) {
                localStorage.setItem('SuccessfullMessage', "Successfully Deleted");
                this.rout.navigateByUrl('ManageMICEAccess/ManageMICEUser', { skipLocationChange: true }).then(() => this.rout.navigate(["ManageMICEAccess/CrmCountry"]));

              }
            });
          }

        }
      })
  }


  GetCRMCountryById(Id): void {

    // Begning of the permission portion
    this.IsDataIsInViewState = false;
    // End of the permision portion
    let t = localStorage.getItem('userToken');

    t = 'Bearer' + t;
    this.AddCrmCountry.GetCRM_Country_By_id(t, Id).then(x => {
      this.CountryId = x;
      Object.keys(this.CountryId).forEach(key => {
        // tslint:disable-next-line:no-unused-expression

        let CRMCountrytemp = new Model_CrmCountry();
        CRMCountrytemp = this.CountryId[key];


        this.CRM_Country_Model.CountryId = CRMCountrytemp.CountryId;
        this.CRM_Country_Model.CountryName = CRMCountrytemp.CountryName;
        this.CRM_Country_Model.IsActive = this.CRM_Country_Model.IsActive;

      });

    });
  }



  ViewCRMCountryById(id) {
    // begning of the permission portion
    this.IsDataIsInViewState = true;
    // end of the permission portion
    let t = localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.AddCrmCountry.GetCRM_Country_By_id(t, id).then(x => {
      this.CountryId = x;
      Object.keys(this.CountryId).forEach(key => {
        this.CountryId[key];
        let CRMCountrytemp = new Model_CrmCountry();
        CRMCountrytemp = this.CountryId[key];
               
        this.CRM_Country_Model.CountryName = CRMCountrytemp.CountryName;
    
      });
      // Begin for check Name duplication
      this.checkNameval = this.CRM_Country_Model.CountryName;
      this.CheckNamestatusedit = false;
      this.CheckNameExiststatus = true;
      this.Namecheck = ""
      // End for check Name duplication
    });
  }

  
  RefreshPage(): void {     

    this.rout.navigateByUrl('ManageMICEAccess/ManageMICEUser', { skipLocationChange: true }).then(() => this.rout.navigate(["ManageMICEAccess/CrmCountry"]));

  }



}
