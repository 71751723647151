export class ExpenseHeadMappingModel 
{
    public RefId : number;
    public ExpenseHeadId : number;
    public DecisionId : number;
    public LevelId : number;
    public GradeId : number;
    public DecisionName : string;
    public LevelName : string;
    public GradeName : string;
    public BudgetAmount : number;
    public DayWise : boolean;
    public OverAll : boolean;
    public value : string;
}