export class EmployeeAssetsModel
{
    public RefId : number;
    public EmployeeId : number;
    public EmployeeName : string;
    public AssetsId : number;
    public Particulars : string;
    public SerialNumber : string;
    public Amount : number;
    public AssetsName : string;
    public IsActive : boolean;
}