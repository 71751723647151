import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';  
import { Http, Headers, RequestOptions, Response } from '@angular/http';  
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner'; 
import 'rxjs/add/operator/toPromise';
import { Subscriber } from 'rxjs';
import { GlobalVariable  } from '../../../app.global';


import { Model_CRM_State } from './Model_CRM_State';
import { Model_CRM_City } from './Model_CRM_City';
import { Model_CRM_Branch } from './Model_CRM_Branch';

    


@Injectable()

export class CrmBranchService {

  constructor (private http: HttpClient,private spinnerService: Ng4LoadingSpinnerService) 
  {
  }


  //    Populate Country Drop  Down Starts

    GetCountryType(token) {
        this.spinnerService.show();
        const authHeader = new HttpHeaders();
        authHeader.append('Content-Type', 'application/json');
        authHeader.append('Authorization', token);
        return new Promise((resolve, reject) => {
            setTimeout(() => {
               // alert('');

                this.http.get(GlobalVariable.BASE_API_URL + '/api/CRMBranch/GetCountryName', { headers: authHeader })
                    .subscribe(data => {
                        this.spinnerService.hide();
                        resolve(data)
                    },
                        error => {
                            this.spinnerService.hide();
                            reject(error);
                        }, );
            }, 3000);
        });
    }

  //  Populate  Country Drop  Down Ends



    //    Populate Zone Drop  Down Starts

    GetZoneType(token) {
        this.spinnerService.show();
        const authHeader = new HttpHeaders();
        authHeader.append('Content-Type', 'application/json');
        authHeader.append('Authorization', token);
        return new Promise((resolve, reject) => {
            setTimeout(() => {

                this.http.get(GlobalVariable.BASE_API_URL + '/api/CRMBranch/GetZoneName', { headers: authHeader })
                    .subscribe(data => {
                        this.spinnerService.hide();
                        resolve(data)
                    },
                        error => {
                            this.spinnerService.hide();
                            reject(error);
                        }, );
            }, 3000);
        });
    }

//   //  Populate Zone Drop  Down Ends


    //    Populate State Drop  Down Starts

    GetStateType(token) {
        this.spinnerService.show();
        const authHeader = new HttpHeaders();
        authHeader.append('Content-Type', 'application/json');
        authHeader.append('Authorization', token);
        return new Promise((resolve, reject) => {
            setTimeout(() => {

                this.http.get(GlobalVariable.BASE_API_URL + '/api/CRMBranch/GetStateName', { headers: authHeader })
                    .subscribe(data => {
                        this.spinnerService.hide();
                        resolve(data)
                    },
                        error => {
                            this.spinnerService.hide();
                            reject(error);
                        }, );
            }, 3000);
        });
    }

  //  Populate State Drop  Down Ends




    //    Populate State Drop  Down Starts

            GetCityType(token) {
                this.spinnerService.show();
                const authHeader = new HttpHeaders();
                authHeader.append('Content-Type', 'application/json');
                authHeader.append('Authorization', token);
                return new Promise((resolve, reject) => {
                    setTimeout(() => {

                        this.http.get(GlobalVariable.BASE_API_URL + '/api/CRMBranch/GetCityName', { headers: authHeader })
                            .subscribe(data => {
                                this.spinnerService.hide();
                                resolve(data)
                            },
                                error => {
                                    this.spinnerService.hide();
                                    reject(error);
                                }, );
                    }, 3000);
                });
            }

            //  Populate State Drop  Down Ends




                AddCrmBranch(token, CRMBranch: Model_CRM_Branch) 
                     {
                const authHeader = new HttpHeaders();         
                authHeader.append('Content-Type', 'application/json');
                authHeader.append('Authorization', token);  
                authHeader.append('Accept', 'application/json');
              //  alert();
                return this.http.post(GlobalVariable.BASE_API_URL + '/api/CrmBranch/Add', CRMBranch ,{headers: authHeader} )
                .map(this.extractData)
                .catch(this.handleErrorObservable);
                   }




                    // Grid Data Fetch  

                    Get_CRMBranch(token) {
                        this.spinnerService.show();
                        const authHeader = new HttpHeaders();
                        authHeader.append('Content-Type', 'application/json');
                        authHeader.append('Authorization', token);
                        return new Promise((resolve, reject) => {
                        setTimeout(() => {
                        //alert();
                        this.http.get(GlobalVariable.BASE_API_URL + '/api/CRMBranch/GetCRMBranch',{ headers: authHeader }).subscribe(data => {
                        this.spinnerService.hide();
                        resolve(data)
                         },
                        error => {
                         this.spinnerService.hide();
                        reject(error);
                        },);
                        }, 3000);
                        });
                    }





        //  View  Section Starts

    GetCRM_Branch_By_id(token, id:number ) 
    {
        this.spinnerService.show(); 
        const authHeader = new HttpHeaders();     
        authHeader.append('Content-Type', 'application/json');
        authHeader.append('Authorization', token);
        return new Promise((resolve, reject) => 
        {   
            setTimeout(()=>
            {
                this.http.get(GlobalVariable.BASE_API_URL +'/api/CRMBranch/GetCRMBranchById?BranchId='+id,{headers: authHeader})
                .subscribe(data => 
                {
                    this.spinnerService.hide();
                    resolve(data)
                },
                error => 
                {
                    this.spinnerService.hide();
                    reject(error);
                },);
            },3000);
        });
    }

// //     //  View  Section Ends    


            


         UpdateCrmBranch(token, CRMBranch: Model_CRM_Branch) {

        const authHeader = new HttpHeaders();
        authHeader.append('Content-Type', 'application/json');
        authHeader.append('Authorization', token);
        authHeader.append('Accept', 'application/json');
             return this.http.post(GlobalVariable.BASE_API_URL + '/api/CrmBranch/Update', CRMBranch, { headers: authHeader })
            .map(this.extractData)
            .catch(this.handleErrorObservable);
    }



    

     

                    //  Delete  a   Record  Starts

                    DeleteBranch( token ,ID : number) 
                {

                    const authHeader = new HttpHeaders();         
                    authHeader.append('Content-Type', 'application/json');
                    authHeader.append('Authorization', token);
                    authHeader.append('Accept', 'application/json');
                        return this.http.put(GlobalVariable.BASE_API_URL +'/api/CrmBranch/Delete', ID ,{headers: authHeader} )
                    .map(this.extractData)
                    .catch(this.handleErrorObservable);
                } 

                    //  Delete  a  record   Ends    



    private extractData(res: Response) {
        let body = res;
        return body || {};
    }
    private handleErrorObservable(error: Response | any) {
        console.error(error.message || error);
        return Observable.throw(error.message || error);
    }


 }
