import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AdminMasterService }  from '../../shared/HR_ADMIN/Admin/AdminMasterService.service';
import { TourExpenseHeadModel }  from '../../shared/HR_ADMIN/Admin/TourExpenseHeadModel.Model';
import { UserService } from '../../shared/user.service';
// Begning Confirmation
import { ConfirmationService } from '@jaspero/ng2-confirmations';
import { ResolveEmit } from '../../../app/resolve-emit';
import { ConfirmSettings } from '../../../app/confirm-settings';
// end of Confirmation
import { Menu } from '../../shared/menu.model';
import { ModalOptions,BsModalRef, BsModalService } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-manage-expense-head',
  templateUrl: './manage-expense-head.component.html',
  styleUrls: ['./manage-expense-head.component.css']
})
export class ManageExpenseHeadComponent implements OnInit 
{
  TourExpenseHeadModel : TourExpenseHeadModel= new TourExpenseHeadModel();
  userClaims: any;
  TourExpenseHeads: TourExpenseHeadModel[] = [];
  TourExpenseHead:any;
  Result : string;
  SuccessStatus:boolean=false;
  SuccessMessage: string ="";
  public tableWidget: any;
  RefId : any;
  //check for duplicate Expense Head Name
  CheckExpenseHeadName : boolean=null;  
  checkstatusedit : boolean =false;
  ExpenseHeadNamecheck : string ="";
  checkExpenseHeadNameval : string ="";
  //check for duplicate Expense Head Name
  // Begning Permission Portion    
  SelectedMenu:Menu;
  IsDataIsInViewState : boolean=false;
  CanViewState : boolean=false;
  DisableSave :boolean=false;
  // End of the permission portion
  //For Pop up
  SaveMsg : boolean= false;
  UpdateMsg : boolean= false;
  DeleteMsg : boolean=false;
  ShowPopup : boolean= false;
  public modalRef: BsModalRef;
  public modelopt:ModalOptions;

  constructor(private AdminMasterService: AdminMasterService, private rout: Router, private _confirmation: ConfirmationService, private userService: UserService,private modalService: BsModalService)
  {
    this.modelopt=new ModalOptions();
    this.modelopt.class='heavy_modal';
    this.modalService.config= this.modelopt;
    this.modelopt.ignoreBackdropClick=true;
  }

  ngOnInit() 
  {
    //Begin Permission Portion
    this.SelectedMenu=this.userService.SelectedMenu;
    if(this.SelectedMenu !=undefined)
    {
      localStorage.setItem('selectedmenu',JSON.stringify(this.SelectedMenu));
      this.rout.navigate([this.SelectedMenu.RouteName]);
    }
    else
    {
      if(JSON.parse(localStorage.getItem('selectedmenu'))!=undefined)
      {
        this.SelectedMenu = JSON.parse(localStorage.getItem('selectedmenu'));
        this.rout.navigate([this.SelectedMenu.RouteName]);
      }
    }     
    if(this.SelectedMenu.CanAdd)
    {
      this.IsDataIsInViewState=false;
    }
    else
    {
      this.IsDataIsInViewState=true;
    }
    if(this.SelectedMenu.CanView)
    {
      this.CanViewState=false;
    }
    else
    {
      this.CanViewState=true;
    }
    //End of the peremission portion
    this.SaveMsg=false;
    this.UpdateMsg=false;
    this.DeleteMsg=false;
    this.ShowPopup=false;
    this.DisableSave=true; 
    this.GETTourExpenseHead();    
  }

  settings: ConfirmSettings | any = 
  {
    overlay: true,
    overlayClickToClose: true,
    showCloseButton: true,
    confirmText: 'Yes',
    declineText: 'No'
  };

  GETTourExpenseHead()
  {
    this.TourExpenseHeads =[];
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.AdminMasterService.GetTourExpenseHead( t).then(x => 
    {
      this.TourExpenseHead = x;
      Object.keys(this.TourExpenseHead).forEach( key => 
      {
        this.TourExpenseHead[key];
        let Expensetemp = new TourExpenseHeadModel();
        Expensetemp = this.TourExpenseHead[key];
        this.TourExpenseHeads.push(Expensetemp);
      });
    });
  }

  GetExpenseHeadById(id,template) 
  {
    // Begning of the permission portion
    this.IsDataIsInViewState=false;
    // End of the permision portion
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.AdminMasterService.GetTourExpenseHeadByExpenseHeadId( t,id).then(x => 
    {
      if(this.ShowPopup==true)
      {
        this.modalRef.hide();
      }
      this.modalRef = this.modalService.show(template,this.modelopt);
      this.ShowPopup=true;
      this.SaveMsg=false;
      this.UpdateMsg=false;
      this.DeleteMsg=false;
      this.TourExpenseHead = x;
      Object.keys(this.TourExpenseHead).forEach( key => 
      {
        this.TourExpenseHead[key];
        let Expensetemp = new TourExpenseHeadModel();
        Expensetemp = this.TourExpenseHead[key];
        this.TourExpenseHeadModel.ExpenseHeadId=Expensetemp.ExpenseHeadId;
        this.TourExpenseHeadModel.ExpenseHeadCode=Expensetemp.ExpenseHeadCode;
        this.TourExpenseHeadModel.ExpenseHeadName=Expensetemp.ExpenseHeadName;
      });
      // check for duplicate Expense Head 
      this.checkstatusedit=true;
      this.checkExpenseHeadNameval = this.TourExpenseHeadModel.ExpenseHeadName;
      this.CheckExpenseHeadName=true;      
      this.ExpenseHeadNamecheck="";
      // check for duplicate Expense Head
    });
  }

  ViewExpenseHeadById(id,template) 
  {
    //begin permission
    this.IsDataIsInViewState=true;
    //end permission
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.AdminMasterService.GetTourExpenseHeadByExpenseHeadId( t,id).then(x => 
    {
      if(this.ShowPopup==true)
      {
        this.modalRef.hide();
      }
      this.modalRef = this.modalService.show(template,this.modelopt);
      this.ShowPopup=true;
      this.SaveMsg=false;
      this.UpdateMsg=false;
      this.DeleteMsg=false;
      this.TourExpenseHead = x;
      Object.keys(this.TourExpenseHead).forEach( key => 
      {
        this.TourExpenseHead[key];
        let Expensetemp = new TourExpenseHeadModel();
        Expensetemp = this.TourExpenseHead[key];
        this.TourExpenseHeadModel.ExpenseHeadId=Expensetemp.ExpenseHeadId;
        this.TourExpenseHeadModel.ExpenseHeadCode=Expensetemp.ExpenseHeadCode;
        this.TourExpenseHeadModel.ExpenseHeadName=Expensetemp.ExpenseHeadName;
      });
    });
  }

  saveExpenseHead(template) :void
  {
    this.DisableSave=false;
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    if(this.TourExpenseHeadModel.ExpenseHeadId > 0)
    {
      this.AdminMasterService.UpdateTourExpenseHead(t ,this.TourExpenseHeadModel).subscribe(res=>
      {
        this.RefId=res;
        this.TourExpenseHeadModel.ExpenseHeadId=0;  
        if(this.RefId>0)
        {
          this.TourExpenseHeadModel.ExpenseHeadCode=null;
          this.TourExpenseHeadModel.ExpenseHeadName=null;
          this.modalRef.hide();
          this.SaveMsg=false;
          this.UpdateMsg=true;
          this.DeleteMsg=false;
          this.ShowPopup=false;
          this.modalRef = this.modalService.show(template);
          this.GETTourExpenseHead();
          this.DisableSave=true;
        }
      });
    }
    else
    {
      this.AdminMasterService.AddTourExpenseHead(t ,this.TourExpenseHeadModel).subscribe(res=>
      {
        this.RefId=res;
        this.TourExpenseHeadModel.ExpenseHeadId=0;              
        if(this.RefId>0)
        {
          this.TourExpenseHeadModel.ExpenseHeadCode=null;
          this.TourExpenseHeadModel.ExpenseHeadName=null;
          this.modalRef.hide();
          this.SaveMsg=true;
          this.UpdateMsg=false;
          this.DeleteMsg=false;
          this.ShowPopup=false;
          this.modalRef = this.modalService.show(template);
          this.GETTourExpenseHead();
          this.DisableSave=true;
        }
      }); 
    }
  }

  DeleteExpenseHeadById(ExpenseHeadId : number,template) :void
  {
    this._confirmation.create('Confirm to Deleate', 'Press Yes to Delete', this.settings)
    .subscribe((ans: ResolveEmit) => 
    {
      if (ans.resolved == true) 
      {
        let t =  localStorage.getItem('userToken');
        t = 'Bearer' + t;
        if( ExpenseHeadId > 0)
        {
          this.AdminMasterService.DeleteTourExpenseHeadName(t ,ExpenseHeadId).subscribe(res=>
          {
            this.RefId=res;
            this.TourExpenseHeadModel.ExpenseHeadId=0;                                  
            if(this.RefId >0)
            {
              this.TourExpenseHeadModel.ExpenseHeadCode=null;
              this.TourExpenseHeadModel.ExpenseHeadName=null;
              this.modalRef.hide();
              this.SaveMsg=false;
              this.UpdateMsg=false;
              this.DeleteMsg=true;
              this.ShowPopup=false;
              this.modalRef = this.modalService.show(template);
              this.GETTourExpenseHead();
              this.DisableSave=true;
            }
          });                  
        }
      }
    })
  }

  RefreshPage()
  {
    this.rout.navigateByUrl('/Organization/ManageRegionForHR', {skipLocationChange: true}).then(()=>this.rout.navigate(["/Organization/RegionForHR"]))
  }

  ADDClick(template)
  {
    this.TourExpenseHeadModel.ExpenseHeadCode=null;
    this.TourExpenseHeadModel.ExpenseHeadName=null;
    this.IsDataIsInViewState=false;
    if(this.ShowPopup==true)
    {
      this.modalRef.hide();
    }
    this.modalRef = this.modalService.show(template,this.modelopt);
    this.ShowPopup=true;
    this.SaveMsg=false;
    this.UpdateMsg=false;
    this.DeleteMsg=false;
    this.DisableSave=true;
    this.CheckExpenseHeadName =true;
  }

  // begning of check duplicate Expense Head Name
  checkExpenseHeadName()
  {
    this.CheckExpenseHeadName ==false;
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    if(!this.checkstatusedit)
    {
      this.AdminMasterService.CheckTourExpenseHeadName( t, this.TourExpenseHeadModel.ExpenseHeadName).then(x => 
      {
        this.TourExpenseHead = x;
        if( this.TourExpenseHead.length >0)
        {
          this.CheckExpenseHeadName =false;
          this.ExpenseHeadNamecheck="Exist"
        }
        else
        {
          this.CheckExpenseHeadName =true;
          this.ExpenseHeadNamecheck="";
        }
      });
    }
    else
    {
      if(this.TourExpenseHeadModel.ExpenseHeadName !=this.checkExpenseHeadNameval )
      {
        this.AdminMasterService.CheckTourExpenseHeadName( t, this.TourExpenseHeadModel.ExpenseHeadName).then(x => 
        {
          this.TourExpenseHead = x;
          if( this.TourExpenseHead.length >0)
          {
            this.CheckExpenseHeadName =false;
            this.ExpenseHeadNamecheck="Exist"
          }
          else
          {
            this.CheckExpenseHeadName =true;
            this.ExpenseHeadNamecheck="";
          }
        });
      }
      else
      {
        this.ExpenseHeadNamecheck="";
        this.CheckExpenseHeadName =true;
      }
    }
  }
  // end of duplicate Expense Head Name
}