import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs/Subject';
import { DataTableDirective } from 'angular-datatables';
import { UserService } from '../../shared/user.service';
import { UserMasterService } from '../../shared/HR_ADMIN/UserMaster/UserMasterService.service';
import { RoleMasterModel } from  '../../shared/HR_ADMIN/UserMaster/RoleMasterModel.Model';
import { MenuUserMappingModel } from  '../../shared/HR_ADMIN/UserMaster/MenuUserMappingModel.Model';
import { MenuMasterModel } from  '../../shared/HR_ADMIN/UserMaster/MenuMasterModel.Model';
import { Menu } from '../../shared/menu.model';
// end of Confirmation

@Component({
  selector: 'app-user-mapping',
  templateUrl: './user-mapping.component.html',
  styleUrls: ['./user-mapping.component.css']
})
export class UserMappingComponent implements OnInit 
{
  MenuUserMappingModel : MenuUserMappingModel= new MenuUserMappingModel();
  userClaims: any;
  MenuUserMappings: MenuUserMappingModel[] = [];
  MenuUserMappingmains: MenuUserMappingModel[] = [];
  Result : string;
  SuccessStatus:boolean=false;
  SuccessMessage: string ="";
  public tableWidget: any;

  RefId : any;
  MenuUserMapping: any;
  base64textString : string;

  RoleMasters: RoleMasterModel[] = [];
  RoleMaster: any;

  ModuleMasters: MenuMasterModel[] = [];
  ModuleMaster: any;
  
  MenuMasters: MenuMasterModel[] = [];
  MenuMaster: any;

  tempMenuUserMappingadd : MenuUserMappingModel =new MenuUserMappingModel();
  MenuIsValid : boolean =false;  
  // Begning Permission Portion    
  SelectedMenu:Menu;
  IsDataIsInViewState : boolean=false;
  CanViewState : boolean=false;
  DisableSave :boolean=false;
  // End of the permission portion

  constructor(private UserMasterService: UserMasterService, private rout: Router, private userService: UserService) { }
  
  @ViewChild(DataTableDirective, {static: false})
  setDtOptions: DataTables.Settings = {};
  cardDtOptions: DataTables.Settings = {};
  dtElement: DataTableDirective;
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();
  
  ngOnInit() 
  {
    //Begin Permission Portion
    this.SelectedMenu=this.userService.SelectedMenu;
    if(this.SelectedMenu !=undefined)
    {
      localStorage.setItem('selectedmenu',JSON.stringify(this.SelectedMenu));
      this.rout.navigate([this.SelectedMenu.RouteName]);
    }
    else
    {
      if(JSON.parse(localStorage.getItem('selectedmenu'))!=undefined)
      {
        this.SelectedMenu = JSON.parse(localStorage.getItem('selectedmenu'));
        this.rout.navigate([this.SelectedMenu.RouteName]);
      }
    }      
    if(this.SelectedMenu.CanAdd)
    {
      this.IsDataIsInViewState=false;
    }
    else
    {
      this.IsDataIsInViewState=true;
    }
    if(this.SelectedMenu.CanView)
    {
      this.CanViewState=false;
    }
    else
    {
      this.CanViewState=true;
    }
    //End of the peremission portion
    this.DisableSave=true;
    this.GetRoleMasters();
    this.MenuUserMappingModel.RoleId=0;
    this.MenuUserMappingModel.ModuleId=0;
    this.dtOptions = 
    {
      pagingType: 'full_numbers',
      pageLength: 4
    };
    if(localStorage.getItem('SuccessfullMessage'))
    {
      this.SuccessStatus=true;
      this.SuccessMessage =localStorage.getItem('SuccessfullMessage')
      localStorage.removeItem('SuccessfullMessage');
    }  
  }

  ngAfterViewInit(): void 
  {
    //this.GetMenuUserMapping();
    this.dtOptions = 
    {
      pagingType: 'full_numbers',
      pageLength: 4
    };
  }
 
  GetModuleMaster(RoleId)
  {
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.ModuleMasters = [];
    this.UserMasterService.GetModuleMasterByRoleId( t,RoleId).then(x => 
    {
      this.ModuleMaster = x;
      Object.keys(this.ModuleMaster).forEach( key => 
      {
        this.ModuleMaster[key];
        let MenuMastertemp = new MenuMasterModel();
        MenuMastertemp = this.ModuleMaster[key];
        this.ModuleMasters.push(MenuMastertemp);
      });
    });
  }

  GetRoleMasters() 
  {
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.UserMasterService.GetRoleMaster( t).then(x => 
    {
      this.RoleMaster = x;
      Object.keys(this.RoleMaster).forEach( key => 
      {
        // tslint:disable-next-line:no-unused-expression
        this.RoleMaster[key];
        let RoleMastertemp = new RoleMasterModel();
        RoleMastertemp = this.RoleMaster[key];
        this.RoleMasters.push(RoleMastertemp);
      });     
    });      
  }

  GetMenuByRoleId(RoleId,ModuleId) 
  {
    let t = localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.UserMasterService.GetMenuByRoleId( t,RoleId,ModuleId).then(x => 
    {
      this.MenuMaster = x;
      Object.keys(this.MenuMaster).forEach( key => 
      {
        // tslint:disable-next-line:no-unused-expression
        this.MenuMaster[key];
        let MenuMastertemp = new MenuMasterModel();
        MenuMastertemp = this.MenuMaster[key];
        this.MenuMasters.push(MenuMastertemp);
      });     
    });      
  }

  GetMenuUserMapping()
  {
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.UserMasterService.GetMenuUserMapping( t).then(x => 
    {
      this.MenuUserMapping = x;
      Object.keys(this.MenuUserMapping).forEach( key => 
      {
        this.MenuUserMapping[key];
        let MenuUserMappingtemp = new MenuUserMappingModel();
        MenuUserMappingtemp = this.MenuUserMapping[key];
        this.MenuUserMappings.push(MenuUserMappingtemp);
      });
      this.dtTrigger.next();
    });
  }

  GetMenuUserMappingByRoleId(RoleId) 
  { 
    this.MenuIsValid=true;
    // Begning of the permission portion
    this.IsDataIsInViewState=false;
    // End of the permision portion
    this.GetModuleMaster(RoleId);
    this.MenuUserMappingModel.RoleId=RoleId;
    this.MenuUserMappingModel.ModuleId=0;
    this.MenuUserMappingModel.MenuId=0;
    this.MenuUserMappingmains=[];
    this.MenuMasters=[];
  }

  GetGridViewData(RoleId,MenuId)
  {
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.UserMasterService.GetMenuUserMappingByRoleId( t,RoleId,MenuId).then(x => 
    {
      this.RefId = x;
      this.MenuUserMappingmains=  [];
      Object.keys(this.RefId).forEach( key => 
      {
        // tslint:disable-next-line:no-unused-expression
        this.RefId[key];
        var MenuUserMappingtemp = new MenuUserMappingModel();
        MenuUserMappingtemp = this.RefId[key];  

        if(MenuUserMappingtemp.CanAdd==true)
        {
           MenuUserMappingtemp.valueAdd="CanAdd";
        }
        if(MenuUserMappingtemp.CanEdit==true)
        {
           MenuUserMappingtemp.valueEdit="CanEdit";
        }
        if(MenuUserMappingtemp.CanDelete==true)
        {
           MenuUserMappingtemp.valueDelete="CanDelete";
        }
        if(MenuUserMappingtemp.CanApprove==true)
        {
           MenuUserMappingtemp.valueApprove="CanApprove";
        }
        if(MenuUserMappingtemp.CanView==true)
        {
           MenuUserMappingtemp.valueView="CanView";
        }
        if(MenuUserMappingtemp.MobileView==true)
        {
           MenuUserMappingtemp.valueMobileView="MobileView";
        }
        this.MenuUserMappingmains.push(MenuUserMappingtemp);
      });
    });
  }

  viewMenuUserMappingByRoleId(RoleId) 
  {
    //begin permission
    this.IsDataIsInViewState=true;
    //end permission
    this.GetModuleMaster(RoleId);
    this.MenuUserMappingModel.RoleId=RoleId;
    this.MenuUserMappingModel.ModuleId=0;
    this.MenuUserMappingModel.MenuId=0;
    this.MenuUserMappingmains=[];
    this.MenuMasters=[];
  }
  
  onRolechange(Roleid) 
  {
    if(Roleid > 0)
    {
      this.DisableSave=true;
      this.MenuMasters=[];
      this.MenuUserMappingmains=[];
      this.GetModuleMaster(Roleid);
      //this.GetMenuByRoleId(Roleid,this.MenuUserMappingModel.ModuleId);
      this.MenuUserMappingModel.ModuleId=0;
      this.MenuUserMappingModel.MenuId=0;
    }
  }

  onModulechange(ModuleId) 
  {
    if(this.MenuUserMappingModel.RoleId > 0)
    {
      this.DisableSave=true;
      this.MenuMasters=[];
      this.MenuUserMappingmains=[];
      this.GetMenuByRoleId(this.MenuUserMappingModel.RoleId,ModuleId);
      this.MenuUserMappingModel.MenuId=0;
    }
  }

  onMenuchange(Menuid) 
  {
    this.DisableSave=true;
    this.MenuUserMappingModel.MenuId=Menuid;
    this.GetGridViewData(this.MenuUserMappingModel.RoleId, this.MenuUserMappingModel.MenuId)
  }

  OnChangeCanAdd(evt,i:number)
  {
    this.MenuUserMappingmains[i];
    var target = evt.target;
    if(target.checked)
    {           
      this.MenuUserMappingmains[i].CanAdd=true;
      this.MenuUserMappingmains[i].valueAdd="CanAdd";        
    }
    else
    {
      this.MenuUserMappingmains[i].CanAdd=false;
      this.MenuUserMappingmains[i].valueAdd="CanAdd"; 
    }
  }

  OnChangeCanEdit(evt,i:number)
  {
    this.MenuUserMappingmains[i];
    var target = evt.target;
    if(target.checked)
    {           
      this.MenuUserMappingmains[i].CanEdit=true;
      this.MenuUserMappingmains[i].valueEdit="CanEdit";        
    }
    else
    {
      this.MenuUserMappingmains[i].CanEdit=false;
      this.MenuUserMappingmains[i].valueEdit="CanEdit"; 
    }
  }

  OnChangeCanDelete(evt,i:number)
  {
    this.MenuUserMappingmains[i];
    var target = evt.target;
    if(target.checked)
    {           
      this.MenuUserMappingmains[i].CanDelete=true;
      this.MenuUserMappingmains[i].valueDelete="CanDelete";        
    }
    else
    {
      this.MenuUserMappingmains[i].CanDelete=false;
      this.MenuUserMappingmains[i].valueDelete="CanDelete"; 
    }
  }

  OnChangeCanApprove(evt,i:number)
  {
    this.MenuUserMappingmains[i];
    var target = evt.target;
    if(target.checked)
    {           
      this.MenuUserMappingmains[i].CanApprove=true;
      this.MenuUserMappingmains[i].valueApprove="CanApprove";        
    }
    else
    {
      this.MenuUserMappingmains[i].CanApprove=false;
      this.MenuUserMappingmains[i].valueApprove="CanApprove"; 
    }
  }

  OnChangeCanView(evt,i:number)
  {
    this.MenuUserMappingmains[i];
    var target = evt.target;
    if(target.checked)
    {           
      this.MenuUserMappingmains[i].CanView=true;
      this.MenuUserMappingmains[i].valueView="CanView";        
    }
    else
    {
      this.MenuUserMappingmains[i].CanView=false;
      this.MenuUserMappingmains[i].valueView="CanView"; 
    }
  }

  OnChangeMobileView(evt,i:number)
  {
    this.MenuUserMappingmains[i];
    var target = evt.target;
    if(target.checked)
    {           
      this.MenuUserMappingmains[i].MobileView=true;
      this.MenuUserMappingmains[i].valueMobileView="MobileView";        
    }
    else
    {
      this.MenuUserMappingmains[i].MobileView=false;
      this.MenuUserMappingmains[i].valueMobileView="MobileView"; 
    }
  }

  RefreshPage()
  {
    this.rout.navigateByUrl('/ManageUserMaster/ModuleMaster', {skipLocationChange: true}).then(()=>this.rout.navigate(["/ManageUserMaster/UserMapping"]))
  }

  UpdateMenuMapping() :void
  {
    this.DisableSave=false;
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.UserMasterService.UpdateMenuUserMapping(t ,this.MenuUserMappingmains).subscribe(res=>
    {
      this.RefId=res;
      if(this.RefId>0)
      {
        //localStorage.setItem('SuccessfullMessage', "Modified Successfully");
        this.MenuUserMappingmains=[];
        //this.rout.navigateByUrl('/ManageUserMaster/ModuleMaster', {skipLocationChange: true}).then(()=>this.rout.navigate(["/ManageUserMaster/UserMapping"]));
      }
    });                  
  }
}
