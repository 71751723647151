import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs/Subject';
import { DataTableDirective } from 'angular-datatables';
import { UserService } from '../../shared/user.service';
import { UserMasterService } from '../../shared/HR_ADMIN/UserMaster/UserMasterService.service';
import { ModuleModel } from  '../../shared/HR_ADMIN/UserMaster/ModuleMasterModel.Model';
import { RoleMasterModel } from  '../../shared/HR_ADMIN/UserMaster/RoleMasterModel.Model';
// Begning Confirmation
import { ConfirmationService } from '@jaspero/ng2-confirmations';
import { ResolveEmit } from '../../../app/resolve-emit';
import { ConfirmSettings } from '../../../app/confirm-settings';
import { Menu } from '../../shared/menu.model';
// end of Confirmation

@Component({
  selector: 'app-role-master',
  templateUrl: './role-master.component.html',
  styleUrls: ['./role-master.component.css']
})
export class RoleMasterComponent implements OnInit 
{
  RoleMasterModel : RoleMasterModel= new RoleMasterModel();
  ModuleModel : ModuleModel= new ModuleModel();
  userClaims: any;
  RoleMasters: RoleMasterModel[] = [];
  Result : string;
  SuccessStatus:boolean=false;
  SuccessMessage: string ="";
  public tableWidget: any;
  RoleMaster: any;
  RoleMasterId : any;
  base64textString : string;

  tempRoleMasterModeladd : RoleMasterModel =new RoleMasterModel();

  // Begning Permission Portion    
  SelectedMenu:Menu;
  IsDataIsInViewState : boolean=false;
  CanViewState : boolean=false;
  DisableSave :boolean=false;
  // End of the permission portion
  //check for duplicate component code
  CheckExiststatus : boolean=null;  
  Checkeditstatus : boolean =false;
  check : string ="";
  checkdata : any;
  checkdataval : string ="";
  //check for duplicate component code
  //for Salary Component Dropdown list
  ModuleList = [];
  ModuleselectedItems = [];
  dropdownSettings = {};
  ModuleSettings = {};
  ModuleMasters :any;
  //for branch Dropdown list

  constructor(private UserMasterService: UserMasterService, private rout: Router,  private _confirmation: ConfirmationService, private userService: UserService) { }
  
  @ViewChild(DataTableDirective, {static: false})
  setDtOptions: DataTables.Settings = {};
  cardDtOptions: DataTables.Settings = {};
  dtElement: DataTableDirective;
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();
  
  ngOnInit() 
  {
    //Begin Permission Portion
    this.SelectedMenu=this.userService.SelectedMenu;
    if(this.SelectedMenu !=undefined)
    {
      localStorage.setItem('selectedmenu',JSON.stringify(this.SelectedMenu));
      this.rout.navigate([this.SelectedMenu.RouteName]);
    }
    else
    {
      if(JSON.parse(localStorage.getItem('selectedmenu'))!=undefined)
      {
        this.SelectedMenu = JSON.parse(localStorage.getItem('selectedmenu'));
        this.rout.navigate([this.SelectedMenu.RouteName]);
      }
    }
    if(this.SelectedMenu.CanAdd)
    {
    this.IsDataIsInViewState=false;
    }
    else
    {
      this.IsDataIsInViewState=true;
    }
    if(this.SelectedMenu.CanView)
    {
    this.CanViewState=false;
    }
    else
    {
      this.CanViewState=true;
    }
    //End of the peremission portion
    this.DisableSave=true;
    this.GetModuleMasters();
    this.dtOptions = 
    {
      pagingType: 'full_numbers',
      pageLength: 4
    };
    if(localStorage.getItem('SuccessfullMessage'))
    {
      this.SuccessStatus=true;
      this.SuccessMessage =localStorage.getItem('SuccessfullMessage')
      localStorage.removeItem('SuccessfullMessage');
    }  
  }

  ngAfterViewInit(): void 
  {
    this.GetRoleMaster();
    this.dtOptions = 
    {
      pagingType: 'full_numbers',
      pageLength: 4
    };
  }

  settings: ConfirmSettings | any = 
  {
    overlay: true,
    overlayClickToClose: true,
    showCloseButton: true,
    confirmText: 'Yes',
    declineText: 'No'
  };

  GetRoleMaster()
  {
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.UserMasterService.GetRoleMaster( t).then(x => 
    {
      this.RoleMaster = x;
      Object.keys(this.RoleMaster).forEach( key => 
      {
        this.RoleMaster[key];
        let RoleMastertemp = new RoleMasterModel();
        RoleMastertemp = this.RoleMaster[key];
        //this.RoleMasterModel.ModuleIds=SalMaptemp.ModuleIds;
        this.RoleMasters.push(RoleMastertemp);
      });
      this.dtTrigger.next();
    });
  }
  
  GetRoleMasterById(Id) 
  { 
    // Begning of the permission portion
    this.IsDataIsInViewState=false;
    // End of the permision portion
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.UserMasterService.GetRoleMasterById( t,Id).then(x => 
    {
      this.RoleMasterId = x;
      Object.keys(this.RoleMasterId).forEach( key => 
      {
        // tslint:disable-next-line:no-unused-expression
        this.RoleMasterId[key];
        let RoleMastertemp = new RoleMasterModel();
        RoleMastertemp = this.RoleMasterId[key];
        this.RoleMasterModel.RoleId=RoleMastertemp.RoleId;
        this.RoleMasterModel.RoleName=RoleMastertemp.RoleName;
        this.RoleMasterModel.OldRoleName=RoleMastertemp.RoleName;
        this.RoleMasterModel.ModuleIds=RoleMastertemp.ModuleIds;
      });
      this.GetModuleMasters();
      // Begin for check company code duplication
      this.checkdataval= this.RoleMasterModel.ModuleIds;
      this.Checkeditstatus=true;
      this.CheckExiststatus =true;
      this.check=""
      // End for check company code duplication
    });
  }

  ViewRoleMasterById(Id) 
  {
    //begin permission
    this.IsDataIsInViewState=true;
    //end permission
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.UserMasterService.GetRoleMasterById( t,Id).then(x => 
    {
      this.RoleMasterId = x;
      Object.keys(this.RoleMasterId).forEach( key => 
      {
          // tslint:disable-next-line:no-unused-expression
        this.RoleMasterId[key];
        let RoleMastertemp = new RoleMasterModel();
        RoleMastertemp = this.RoleMasterId[key];
        this.RoleMasterModel.RoleId=RoleMastertemp.RoleId;
        this.RoleMasterModel.RoleName=RoleMastertemp.RoleName;
        this.RoleMasterModel.ModuleIds=RoleMastertemp.ModuleIds;
      });
      this.GetModuleMasters();
        // Begin for check company code duplication
      this.checkdataval= this.RoleMasterModel.ModuleIds;
      this.Checkeditstatus=true;
      this.CheckExiststatus =true;
      this.check=""
        // End for check company code duplication
    });
  }

  SaveRoleMaster() :void
  {
    this.DisableSave=false;
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.RoleMasterModel.IsActive=true;
    if(this.RoleMasterModel.RoleId > 0)
    {
      if(this.ModuleselectedItems.length > 0)  
      {
        this.RoleMasterModel.ModuleIds='';
        this.ModuleselectedItems.forEach(element => 
        {
          this.RoleMasterModel.ModuleIds= this.RoleMasterModel.ModuleIds + element.id + ",";
        });
        var l = this.RoleMasterModel.ModuleIds.length
        this.RoleMasterModel.ModuleIds = this.RoleMasterModel.ModuleIds.substr(0,l-1);
        this.UserMasterService.UpdateRoleMaster(t ,this.RoleMasterModel).subscribe(res=>
        {
          this.RoleMasterId=res;
          this.RoleMasterModel.RoleId=0;  
          if(this.RoleMasterId>0)
          {
            localStorage.setItem('SuccessfullMessage', "Update Successfully");
            this.rout.navigateByUrl('/ManageUserMaster/MenuMaster', {skipLocationChange: true}).then(()=>this.rout.navigate(["/ManageUserMaster/RoleMaster"]));
          }
        });
      }
    }
    else
    {
      if(this.ModuleselectedItems.length > 0)  
      {
        this.RoleMasterModel.ModuleIds='';
        this.ModuleselectedItems .forEach(element => 
        {
          this.RoleMasterModel.ModuleIds= this.RoleMasterModel.ModuleIds + element.id + ",";         
        });
        var l = this.RoleMasterModel.ModuleIds.length
        this.RoleMasterModel.ModuleIds = this.RoleMasterModel.ModuleIds.substr(0,l-1);
        this.UserMasterService.AddRoleMaster(t ,this.RoleMasterModel).subscribe(res=>
        {
          this.RoleMasterId=res;
          this.RoleMasterModel.RoleId=0;              
          if(this.RoleMasterId>0)
          {
            localStorage.setItem('SuccessfullMessage', "Save Successfully");
            this.rout.navigateByUrl('/ManageUserMaster/MenuMaster', {skipLocationChange: true}).then(()=>this.rout.navigate(["/ManageUserMaster/RoleMaster"]));
          }
        }); 
      }
    }
  }

  DeleteRoleMasterById(RoleId : number) :void
  {
    if(this.SelectedMenu.CanAdd)
    {
     this.IsDataIsInViewState=false;
    }
    else
    {
      this.IsDataIsInViewState=true;
    }
    this._confirmation.create('Confirm to Deleate', 'Press Yes to Delete', this.settings)
      .subscribe((ans: ResolveEmit) => 
      {
        if (ans.resolved == true) 
        {
          let t =  localStorage.getItem('userToken');
          t = 'Bearer' + t;
          this.RoleMasterModel.IsActive=true;
          if( RoleId > 0)
          {
            this.UserMasterService.DeleteRoleMaster(t ,RoleId).subscribe(res=>
            {
              this.RoleMasterId=res;
              this.RoleMasterModel.RoleId=0;                                  
              if(this.RoleMasterId >0)
              {
                localStorage.setItem('SuccessfullMessage', "Deleted Successfully");
                this.rout.navigateByUrl('/ManageUserMaster/MenuMaster', {skipLocationChange: true}).then(()=>this.rout.navigate(["/ManageUserMaster/RoleMaster"]));
              }   
            });                  
          }
        }
      })
  }
  // begning of check duplicate Salary Mapping
  CheckRoleNameDuplicate()
  {
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    if(!this.Checkeditstatus)
    {
      this.UserMasterService.CheckRoleNameDuplicate( t, this.RoleMasterModel.RoleName).then(x => 
      {
        this.checkdata = x;
        if( this.checkdata.length >0)
        {
          this.CheckExiststatus =false;
          this.check="Exist"
        }
        else
        {
          this.CheckExiststatus =true;
          this.check="";
        }
     });
    }
    else
    {
      if(this.RoleMasterModel.ModuleIds !=this.checkdataval )
      {
        this.UserMasterService.CheckRoleNameDuplicate( t, this.RoleMasterModel.RoleName).then(x => 
        {
          this.checkdata = x;
          if( this.checkdata.length >0)
          {
            this.CheckExiststatus =false;
            this.check="Exist"
          }
          else
          {
            this.CheckExiststatus =true;
            this.check="";
          }
        });
      }
      else
      {
        this.CheckExiststatus =true;
        this.check="";
      }
    }
  }
  // end of duplicate Salary Mapping
  
  GetModuleMasters() 
  {
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t
    this.ModuleselectedItems = [];
    this.ModuleList = [];
    this.UserMasterService.GetModuleMaster( t).then(x => 
    {
      this.ModuleMasters = x;
      Object.keys(this.ModuleMasters).forEach( key => 
      {
        // tslint:disable-next-line:no-unused-expression
        this.ModuleMasters[key];
        let Moduletemp= new ModuleModel();
        Moduletemp = this.ModuleMasters[key];
        this.ModuleList.push({"id":Moduletemp.ModuleId,"itemName":Moduletemp.ModuleName},);
      });  
      if(this.ModuleList.length > 0)  
      {
        var selected_br = this.RoleMasterModel.ModuleIds.split(',');
        for (var _i = 0; _i < selected_br.length; _i++) 
        {
          var item = this.ModuleList.find(x=>x.id==selected_br[_i])
          this.ModuleselectedItems.push(item);
        }
      }
    });      
  }
  
  onItemSelect(item:any){ }
  OnItemDeSelect(item:any){ }
  onSelectAll(items: any){ }
  onDeSelectAll(items: any){ }

  RefreshPage()
  {
    this.rout.navigateByUrl('/ManageUserMaster/MenuMaster', {skipLocationChange: true}).then(()=>this.rout.navigate(["/ManageUserMaster/RoleMaster"]))
  }
}
