export class BankMaster
{
    public BankId : Number;
    public BankCode : string;
    public BankName : string;
    public BranchName : string;
    public BankAddress : string;
    public IFSCCode : string;
    public CountryId : Number;
    public StateId : Number;
    public CityId : Number;
    public PostCode : Number;
    public IsActive : boolean;
}