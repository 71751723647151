<div class="body-content-area tabcontent" id="link1">
  <div class="row">
    <div class="col-lg-9">
        <div class="row">
            <div class="col-lg-1 low-padding text-center">
                <!-- <img *ngIf="this.SelectedMenu.Image!=null" src={{this.SelectedMenu.Image}} class="body-icon" alt="Icon"/> -->
            </div>
            <div class="col-lg-10 clear-padding">
                <span class="page-title">Generate Payslip</span>
                <small class="page-description"></small>
            </div>
        </div>
    </div>
  </div>
  <div *ngIf="!(this.CanViewState)">
    <fieldset class="body-form-control" > 
    <legend class="legend--"></legend>
      <form #EmployeepayslipForm="ngForm">
        <div class="row">
          <div class="col-lg-4">
            <div class="col-body-border-right">
              <div class="form-group row">
                <label class="col-sm-4 col-form-label clear-padding-right">Year</label>
                <div class="col-sm-7 clear-left-padding">
                  <select [(ngModel)]="EmployeeMonthelyAttendenceModel.Year" id ="Year" class="form-control" name="Year" #Year="ngModel" (ngModelChange)="onchangeFinYear($event)">
                    <option [value]="0" [disabled]=true >Select Year</option>
                    <option *ngFor="let FY of FinancialYears" [value]="FY.Year">{{FY.Year}}</option>
                  </select>
                </div>
                <div class="required-validation" *ngIf="EmployeeMonthelyAttendenceModel.Year==0">Year is required</div>
              </div>
            </div>
          </div>
          <div class="col-lg-4">
            <div class="col-body-border-right">
              <div class="form-group row">
                <label class="col-sm-4 col-form-label clear-padding-right">Month</label>
                <div class="col-sm-7 clear-left-padding">
                  <select [(ngModel)]="EmployeeMonthelyAttendenceModel.MonthId" (ngModelChange)="onchangeMonth($event)" id ="MonthId" class="form-control" name="MonthId" #MonthId="ngModel" >
                    <option [value]="0" [disabled]=true >Select Month</option>
                    <option *ngFor="let Mo of Months" [value]="Mo.MonthId">{{Mo.MonthName}}</option>
                  </select>
                </div>
                <div class="required-validation" *ngIf="EmployeeMonthelyAttendenceModel.MonthId==0">Month is required</div>
              </div>
            </div>
          </div>
          <div class="col-lg-4">
            <div class="form-group row" >
              <label class="col-sm-2 col-form-label clear-padding-right">Employee</label>
              <div class="col-sm-9 clear-left-padding">
                <angular2-multiselect tabindex="1" class="form-control" [data]="emp" [(ngModel)]="selectedItems" name="EmployeeId" 
                  [settings]="EmployeeSettings" (onSelect)="onItemSelect($event)" (onDeSelect)="OnItemDeSelect($event)" (onSelectAll)="onSelectAll($event)"
                  (onDeSelectAll)="onDeSelectAll($event)">
                </angular2-multiselect>
              </div>
              <div class="required-validation" *ngIf="EmployeeMonthelyAttendenceModel.EmployeeId == 0">Employee is required</div>
            </div>  
            <div class="form-group row">
              <label class="col-sm-5 col-form-label clear-padding-right">
                <button type="submit" tabindex="5" (click)="GetEmployeeSalaryDetails()" class="btn btn-primary save-button" [disabled]="!(EmployeepayslipForm.valid && EmployeeMonthelyAttendenceModel.MonthId > 0 && EmployeeMonthelyAttendenceModel.Year > 0 && EmployeeMonthelyAttendenceModel.EmployeeId > 0 && this.DisableSave == true)">Generate Payslip</button>
              </label>
              <div class="col-sm-5 clear-left-padding" *ngIf="this.EmployeeMonthelyAttendenceModelResult.GrossSalary > 0">
                <button type="submit" class="btn btn-danger cancle-button" (click)="print()">Print Payslip</button>
              </div>
            </div>
          </div>
        </div>
      </form>    
    </fieldset>
    <div *ngIf="NoRecordMsg" style="color: #dc3545; text-align: center;font-size: 15px; font-weight: bold;">No Record Found.</div>
    <fieldset class="body-form-control" *ngIf="this.EmployeeMonthelyAttendenceModelResult.GrossSalary > 0">
      <legend class="legend--"></legend>
      <div style="padding-left:15%;padding-right:15%" Id="Payslip">
        <table border="1px" style="width:100%;">
          <tr >
            <td style="width:100%;">
              <table border="0" style="width:100%;">
                <tr>
                  <td style="width:80%; vertical-align: top;">
                    <span style="font-weight: bold;font-size: 11px;">&nbsp;{{this.EmployeeMonthelyAttendenceModelResult.CompanyName}}</span><br>
                    <span style="font-size: 10px;">&nbsp;{{this.EmployeeMonthelyAttendenceModelResult.Address1}}, {{this.EmployeeMonthelyAttendenceModelResult.Address2}},</span><br>
                    <span style="font-size: 10px;">&nbsp;{{this.EmployeeMonthelyAttendenceModelResult.City}} - {{this.EmployeeMonthelyAttendenceModelResult.PinCode}}</span>
                  </td>
                  <td style="padding-left:10%; width:20%;">
                    <img *ngIf="this.EmployeeMonthelyAttendenceModelResult.LogoImage!=null" [src]="this.EmployeeMonthelyAttendenceModelResult.LogoImage" style="width:100%;">&nbsp;
                  </td>
                </tr>
              </table>
            </td>
          </tr>
  
          <tr>
            <td>
              <table border="0">
                <tr>
                  <td style="font-weight: bold; font-size: 10px;">
                    &nbsp;PAYSLIP FOR THE MONTH OF {{this.EmployeeMonthelyAttendenceModelResult.MonthName}}&nbsp;{{this.EmployeeMonthelyAttendenceModelResult.CurrentYear}}
                  </td>
                </tr>
              </table>
            </td>
          </tr>
          
          <tr>
            <td>
              <table border="1" style="width:100%; font-size: 10px;">
                <tr style="border-left: 0%;">
                  <td style="width:15%;border-left: 0%;">&nbsp;EMPLOYEE ID</td>
                  <td style="width:45%;">&nbsp;{{this.EmployeeMonthelyAttendenceModelResult.EmployeeCode}}</td>
                  <td style="width:20%;">&nbsp;PF No</td>
                  <td tyle="width:20%;">&nbsp;{{this.EmployeeMonthelyAttendenceModelResult.EPFNO}}</td>
                </tr>
                <tr style="border-left: 0px;">
                  <td>&nbsp;EMPLOYEE NAME</td>
                  <td>&nbsp;{{this.EmployeeMonthelyAttendenceModelResult.EmployeeName}}</td>
                  <td>&nbsp;UAN No</td>
                  <td>&nbsp;{{this.EmployeeMonthelyAttendenceModelResult.UANNo}}</td>
                </tr>
                <tr style="border-left: 0px;">
                  <td>&nbsp;DESIGNATION</td>
                  <td>&nbsp;{{this.EmployeeMonthelyAttendenceModelResult.DesignationName}}</td>
                  <td>&nbsp;BANK NAME</td>
                  <td>&nbsp;{{this.EmployeeMonthelyAttendenceModelResult.BankName}}</td>
                </tr>
                <tr style="border-left: 0px;">
                  <td>&nbsp;LOCATION</td>
                  <td>&nbsp;{{this.EmployeeMonthelyAttendenceModelResult.Branchname}}</td>
                  <td>&nbsp;ACCOUNT NO</td>
                  <td>&nbsp;{{this.EmployeeMonthelyAttendenceModelResult.AccountNo}}</td>
                </tr>
                <tr style="border-left: 0px;">
                  <td>&nbsp;DEPARTMENT</td>
                  <td>&nbsp;{{this.EmployeeMonthelyAttendenceModelResult.DepartmentName}}</td>
                  <td>&nbsp;PAYABLE DAYS</td>
                  <td>&nbsp;{{this.EmployeeMonthelyAttendenceModelResult.FinalAttendence}}</td>
                </tr>
                <tr style="border-left: 0px;">
                  <td>&nbsp;PAN</td>
                  <td>&nbsp;{{this.EmployeeMonthelyAttendenceModelResult.PANNo}}</td>
                  <td>&nbsp;LOP DAYS</td>
                  <td>&nbsp;{{this.EmployeeMonthelyAttendenceModelResult.FinalLopNo}}</td>
                </tr>
                <tr style="border-left: 0px; height:2%">
                  <td>&nbsp;</td>
                  <td></td>
                  <td>ATTENDANCE CYCLE</td>
                  <td>&nbsp;{{this.EmployeeMonthelyAttendenceModelResult.FromDate}}&nbsp;TO&nbsp;{{this.EmployeeMonthelyAttendenceModelResult.ToDate}}</td>
                </tr>
              </table>
            </td>
          </tr>
  
          <tr>
            <td>
              <table border="1" style="width:100%;">
                <tr border="1" style="border-left: 0%;">
                  <td style="width:60%; border-left: 0%; vertical-align: top;"> 
                    <table style="width:100%; vertical-align: top; font-size: 10px;">
                      <tr>
                        <td style="width:50%; border-left: 0%; font-weight: bold;">&nbsp;<u>EARNINGS</u></td>
                        <td style="width:50%; border-left: 0%; text-align: right; font-weight: bold;"><u>AMOUNT</u>&nbsp;</td>
                      </tr>
  
                      <tr *ngFor="let Earnings of this.EmployeeMonthelyAttendenceModelResult.SalaryCompList">
                        <td style="width:50%; border-left: 0%;">&nbsp;{{Earnings.SalaryComponentName}}</td>
                        <td style="width:50%; border-left: 0%; text-align: right;">{{Earnings.Amount | number:'2.0-0'}}.00&nbsp;</td>
                      </tr>
  
                      <tr *ngIf="this.EmployeeMonthelyAttendenceModelResult.Arears > 0">
                        <td style="width:50%; border-left: 0%;">&nbsp;ARREARS</td>
                        <td style="width:50%; border-left: 0%; text-align: right;">{{this.EmployeeMonthelyAttendenceModelResult.Arears | number:'2.0-0'}}.00&nbsp;</td>
                      </tr>

                      <tr *ngIf="this.EmployeeMonthelyAttendenceModelResult.OtherIncome > 0">
                        <td style="width:50%; border-left: 0%;">&nbsp;OTHER INCOME</td>
                        <td style="width:50%; border-left: 0%; text-align: right;">{{this.EmployeeMonthelyAttendenceModelResult.OtherIncome | number:'2.0-0'}}.00&nbsp;</td>
                      </tr>

                    </table>
                  </td>
                
                  <td style="width:40%; border-left: 0%; vertical-align: text-top;">
                    <table style="width:100%; font-size: 10px;">
                      <tr border="1" style="border-left: 0%;">
                        <td>
                          <table style="width:100%; vertical-align:top; font-size: 10px;">
                            <tr>
                              <td style="width:50%;border-left: 0%; font-weight: bold;">&nbsp;<u>DEDUCTIONS</u></td>
                              <td style="width:50%;border-left: 0%; text-align: right; font-weight: bold;"><u>Amount</u>&nbsp;</td>
                            </tr>
                            <tr *ngIf="this.EmployeeMonthelyAttendenceModelResult.PF > 0">
                              <td style="width:50%;border-left: 0%;">&nbsp;EPF DEDUCTION</td>
                              <td style="width:50%;border-left: 0%; text-align: right;">{{this.EmployeeMonthelyAttendenceModelResult.PF | number:'2.0-0'}}.00&nbsp;</td>
                            </tr>
                            <tr *ngIf="this.EmployeeMonthelyAttendenceModelResult.Ptax > 0">
                              <td style="width:50%;border-left: 0%;">&nbsp;PROFESSION TAX</td>
                              <td style="width:50%;border-left: 0%; text-align: right;">{{this.EmployeeMonthelyAttendenceModelResult.Ptax | number:'2.0-0'}}.00&nbsp;</td>
                            </tr>
                            <tr *ngIf="this.EmployeeMonthelyAttendenceModelResult.TDS > 0">
                              <td style="width:50%;border-left: 0%;">&nbsp;TDS</td>
                              <td style="width:50%;border-left: 0%; text-align: right;">{{this.EmployeeMonthelyAttendenceModelResult.TDS | number:'2.0-0'}}.00&nbsp;</td>
                            </tr>
                            <tr *ngIf="this.EmployeeMonthelyAttendenceModelResult.Advance > 0">
                              <td style="width:50%;border-left: 0%;">&nbsp;ADVANCE AMOUNT</td>
                              <td style="width:50%;border-left: 0%; text-align: right;">{{this.EmployeeMonthelyAttendenceModelResult.Advance | number:'2.0-0'}}.00&nbsp;</td>
                            </tr>
                            <tr *ngIf="this.EmployeeMonthelyAttendenceModelResult.Loan > 0">
                              <td style="width:50%;border-left: 0%;">&nbsp;LOAN AMOUNT</td>
                              <td style="width:50%;border-left: 0%; text-align: right;">{{this.EmployeeMonthelyAttendenceModelResult.Loan | number:'2.0-0'}}.00&nbsp;</td>
                            </tr>
                            <tr *ngIf="this.EmployeeMonthelyAttendenceModelResult.MediClame > 0">
                              <td style="width:50%;border-left: 0%;">&nbsp;MEDICLAIM</td>
                              <td style="width:50%;border-left: 0%; text-align: right;">{{this.EmployeeMonthelyAttendenceModelResult.MediClame | number:'2.0-0'}}.00&nbsp;</td>
                            </tr>
                            <tr *ngIf="this.EmployeeMonthelyAttendenceModelResult.OtherDeduction > 0">
                              <td style="width:50%;border-left: 0%;">&nbsp;OTHER DEDUCTION</td>
                              <td style="width:50%;border-left: 0%; text-align: right;">{{this.EmployeeMonthelyAttendenceModelResult.OtherDeduction | number:'2.0-0'}}.00&nbsp;</td>
                            </tr>
                            <tr *ngIf="this.EmployeeMonthelyAttendenceModelResult.Donation > 0">
                              <td style="width:50%;border-left: 0%;">&nbsp;DONATION</td>
                              <td style="width:50%;border-left: 0%; text-align: right;">{{this.EmployeeMonthelyAttendenceModelResult.Donation | number:'2.0-0'}}.00&nbsp;</td>
                            </tr>
                            <tr *ngIf="this.EmployeeMonthelyAttendenceModelResult.LOPAmount > 0" >
                              <td style="width:50%;border-left: 0%;">&nbsp;LOP AMOUNT</td>
                              <td style="width:50%;border-left: 0%; text-align: right;">{{this.EmployeeMonthelyAttendenceModelResult.LOPAmount | number:'2.0-0'}}.00&nbsp;</td>
                            </tr>

                            <!-- 11042020 Begin Change by Mukesh -->
                            <tr *ngIf="this.EmployeeMonthelyAttendenceModelResult.HoldAmount > 0" >
                              <td style="width:50%;border-left: 0%;">&nbsp;HOLD AMOUNT</td>
                              <td style="width:50%;border-left: 0%; text-align: right;">{{this.EmployeeMonthelyAttendenceModelResult.HoldAmount | number:'2.0-0'}}.00&nbsp;</td>
                            </tr>

                            <tr *ngIf="this.EmployeeMonthelyAttendenceModelResult.LWPAmount > 0" >
                              <td style="width:50%;border-left: 0%;">&nbsp;LWP AMOUNT</td>
                              <td style="width:50%;border-left: 0%; text-align: right;">{{this.EmployeeMonthelyAttendenceModelResult.LWPAmount | number:'2.0-0'}}.00&nbsp;</td>
                            </tr>
                            <!-- 11042020 End Change by Mukesh -->
                          </table>
                        </td>
                      </tr>
                    </table>
                  </td> 
                </tr>
              </table>
            </td>
          </tr>
  
          <tr>
            <td>
              <table border="1" style="width:100%;">
                <tr>
                  <td style="width:60%;border-left: 0%;">
                    <table style="width:100%; font-size: 10px;">
                      <tr>
                        <td style="width:50%;border-left: 0%; font-weight: bold;">&nbsp;TOTAL EARNINGS</td>
                        <td style="width:50%;border-left: 0%; text-align: right; font-weight: bold;">{{this.EmployeeMonthelyAttendenceModelResult.TotalIncome| number:'2.0-0'}}.00&nbsp;</td>
                      </tr>
                    </table>
                  </td>
                  <td style="width:40%;border-left: 0%;">
                    <table style="width:100%; font-size: 10px;">
                      <tr>
                        <td style="width:50%;border-left: 0%; font-weight: bold; font-size: 10px;">&nbsp;TOTAL DEDUCTIONS</td>
                        <td style="width:50%;border-left: 0%; text-align: right; font-weight: bold; font-size: 10px;">{{this.EmployeeMonthelyAttendenceModelResult.TotalDeduction| number:'2.0-0'}}.00&nbsp;</td>
                      </tr>
                    </table>
                  </td>
                </tr>
              </table>
            </td>
          </tr>
  
          <tr>
            <table border="1" style="width:100%;">
              <tr>
                <td style="font-weight: bold;font-size: 10px; " >
                    &nbsp;NET PAY&nbsp;&nbsp;&nbsp;:&nbsp;&nbsp;Rs.&nbsp;{{this.EmployeeMonthelyAttendenceModelResult.NetTakeHome | number:'2.0-0'}}.00
                  <br>
                  &nbsp;IN WORDS :&nbsp;&nbsp;{{this.EmployeeMonthelyAttendenceModelResult.NetTakeHomeINWORD}}
                </td>
              </tr>
            </table>
          </tr>
  
          <tr>
            <table border="1" style="width:100%; font-size: 10px;">
              <tr>
                <td style="text-align: center">
                  * COMPUTER GENERATED SALARY SLIP. SIGNATURE NOT REQUIRED *
                </td>
              </tr>
            </table>
          </tr>
        </table>
      </div>
    </fieldset>
    <br>
  </div>
</div>