import {OfferStandardSalary} from './OfferStandardSalary';

export class EmployeeSalaryComponentAttendence
{
    public RefIdEmpAttMast :number;
    public EmpMonthSalId : number;
    public EmployeeId :number;
    public EmployeeCode : string;
    public EmployeeName : string;
    public AttendenceMonth : string;
    public AttendenceMonthId : number;
    public AttendenceFromDate : Date;
    public AttendenceToDate : Date;
    public NoOfLOP :number;
    public GrossSalary : number;
    public NetTakeHome : number;
    public PF : number;
    public PFCont : number;
    public Ptax :number;
    public TotalLoPDeduction : number;
    public TDS : number;
    public Advance : number;
    public MediClame : number;
    public OtherDeduction : number;
    public Loan : number;
    public Donation : number;
    public Arears : number;
    public LOPAmount : number;
    public AdjustmentAddition : number;
    public AdjustmentSubtraction : number;
    public OtherIncome : number;
    public TotalIncome : number;
    public TotalDeduction : number;
    public SalaryCompList : OfferStandardSalary[];
    public IsSubmited : boolean;
    public Year :number;
    public YearId :number;
    public MonthId : number
    public ComanyId : number;
    public BranchId : number;
    public DepartmentId : number;
    public salaryId : number;
    public CompanyName : string;
    public EPFNO : string;
    public UANNo : string;
    public DesignationName : string;
    public BankName : string;
    public DepartmentName : string;
    public AccountNo : string;
    public PANNo : string;
    public FinalLopNo : number;
    public Branchname: string
    public FinalAttendence : number;
    public NetTakeHomeINWORD : string;
    public FromDate : string;
    public ToDate : string;
    public City : string;
    public PinCode : string;
    public TotalYearlyIncome : number;
    public TotalExemptedAmount : number;
    public TotalTaxableAmount : number;
    public TaxableComponentList : OfferStandardSalary[];
    public SEC80C_80CCC_80CCD : number;
    public MIParents_80D : number;
    public IPEL_80E : number;
    public Donation_80G : number;
    public SEC80TTA_IRSBAc : number;
    public SEC24_IPHL : number;
    public LogoImage : string;
    public Address1 : string;
    public Address2 : string;
    public MonthName : string;
    public CurrentYear : string;

    public TDSRemaining : number;
    public TDSPaid : number;
    public TotalTDSPaid : number;
    public TDSCurrentMonth : number;
    public TaxPayableAmount : number;
    public ActualTaxableAmount : number;
    public TAXAmount : number;
    public TotalPtaxAmount : number;
    public EducationCessAmount : number;

    public EducationCess : number;
    public EPP_80CCD : number;
    public MutaulFund_80CCC : number;
    public HRAFIFTYPER : number;
    public TotalSEC80C : number;
    public LEASTEXEMPT : number;
    public RENTBASIC : number;
    public HRAYearly : number;
    public BasicYearly : number;
    public RentPaid : number;
    public DOB : Date;
    public JoiningDate : Date;

    public TDSSlab =[];
    public Under80CList =[];
    //10042020 Begin Change by Mukesh
    public HoldAmount : number;
    public LWPAmount : number;
    //10042020 End Change by Mukesh
}
