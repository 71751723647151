import { Component, OnInit, Input } from '@angular/core';
import { CrmOppertunityService } from '../../../shared/CRM/MICECRM/Oppertunity/CrmOppertunityService';
import { Router } from '@angular/router';
import { Model_CRM_Lead_Contacts } from '../../../shared/CRM/MICECRM/Oppertunity/Model_CRM_Lead_Contacts';
import { ConfirmSettings } from '@jaspero/ng2-confirmations/src/interfaces/confirm-settings';
import { Model_CRM_OppotunityNotes } from '../../../shared/CRM/MICECRM/Oppertunity/Model_CRM_Opportunity_Notes';
import { Model_CRM_Opportunity_Rolls } from '../../../shared/CRM/MICECRM/Oppertunity/Model_CRM_Opportunity_Rolls';

@Component({
  selector: 'app-crm-manage-notes',
  templateUrl: './crm-manage-notes.component.html',
  styleUrls: ['./crm-manage-notes.component.css']
})
export class CrmManageNotesComponent implements OnInit {

  public startDate: any = new Date();


  // Begin success message popup
  SuccessStatus: boolean;
  SuccessMessage: string;
  IsSavevisible: boolean = true;
  // end success message popup


  // CRM - Lead Contact Drop Down   
  ContactName: any;
  contactlist: Model_CRM_Lead_Contacts[] = [];
  // CRM Opportunity Drop down list
  OpportunityRoles: any;
  OpportunityRollList: Model_CRM_Opportunity_Rolls[] = [];



  Uploadfile: any;

  Noteid: any;

  OppertunityMoreInformationId: number;


  constructor(private CRM_OppertunityService: CrmOppertunityService, private rout: Router) { }

  ngOnInit() {

    if (localStorage.getItem('SuccessfullMessage')) {

      this.SuccessStatus = true;
      this.SuccessMessage = localStorage.getItem('SuccessfullMessage')

      localStorage.removeItem('SuccessfullMessage');

    }
    // this.GetLeadContact();
    // this.GetOpportunityRoles();
    if (this.CRM_OppertunityService.CRm_Opportunity_Notes.OportunityId == 0) {
      this.RefreshNotes()
    }

    if ((this.CRM_OppertunityService.CrmCommonUseOportunityDetails.OppertunityId == undefined)) {
      this.CRM_OppertunityService.SelectedCrmIndexPage = 0;
      this.rout.navigate(["/ManageMICEAccess/CrmOppertunity/OportunityDetails"]);
    }

    if ((this.CRM_OppertunityService.CrmCommonUseOportunityDetails.OppertunityId == 0)) {
      this.CRM_OppertunityService.SelectedCrmIndexPage = 0;
      this.rout.navigate(["/ManageMICEAccess/CrmOppertunity/OportunityDetails"]);
    }



    if (this.CRM_OppertunityService.CRm_Opportunity_Notes.NoteDateval == undefined) {
      this.CRM_OppertunityService.CRm_Opportunity_Notes.NoteDateval = '';

    }


  }


  public onStartedateChange(date) {

    var dateParts = date.split("/");
    this.CRM_OppertunityService.CRm_Opportunity_Notes.NoteDate = new Date(+dateParts[2], dateParts[1], +dateParts[0]);
    this.CRM_OppertunityService.CRm_Opportunity_Notes.NoteDateval = date;

  }


  settings: ConfirmSettings | any =
    {
      overlay: true,
      overlayClickToClose: true,
      showCloseButton: true,
      confirmText: 'Yes',
      declineText: 'No'
    };





  GetLeadContact() {
    let t = localStorage.getItem('userToken');



    t = 'Bearer' + t
    this.contactlist = [];
    this.CRM_OppertunityService.GetCrmLeadContact(t, this.CRM_OppertunityService.CrmCommonUseOportunityDetails.CompanyId).then(x => {
      this.ContactName = x;
      Object.keys(this.ContactName).forEach(key => {
        let ContactNametemp = new Model_CRM_Lead_Contacts();
        ContactNametemp = this.ContactName[key];
        this.contactlist.push(ContactNametemp);

      });

    });
  }

  GetOpportunityRoles() {
    let t = localStorage.getItem('userToken');
    t = 'Bearer' + t
    this.OpportunityRollList = [];
    this.CRM_OppertunityService.GetCrmOpportunityRoles(t).then(x => {
      this.OpportunityRoles = x;
      Object.keys(this.OpportunityRoles).forEach(key => {
        let Rolestemp = new Model_CRM_Opportunity_Rolls();
        Rolestemp = this.OpportunityRoles[key];
        this.OpportunityRollList.push(Rolestemp);
      });

    });
  }

  doTextareaValueChange(ev) {
    try {
      this.CRM_OppertunityService.CRm_Opportunity_Notes.Note = ev.target.value;
    } catch (e) {
      console.info('could not set textarea-value');
    }
  }

  OnChangeImportant(evt) {

    var target = evt.target;
    if (target.checked) {
      this.CRM_OppertunityService.CRm_Opportunity_Notes.IsImportant = true;
      this.CRM_OppertunityService.CRm_Opportunity_Notes.Importantcheck = "Importantcheck";
    }
    else {
      this.CRM_OppertunityService.CRm_Opportunity_Notes.IsImportant = false;

      this.CRM_OppertunityService.CRm_Opportunity_Notes.Importantcheck = "Importantcheck";



    }
  }


  doImpLinksValueChange(event) {
    try {
      this.CRM_OppertunityService.CRm_Opportunity_Notes.ImportantLink = event.target.value;
    } catch (e) {
      console.info('could not set textarea-value');
    }

  }



  fileuploads(event) {



    const files = event.target.files;
    //console.log(files);

    if (files) {
      for (let i = 0; i < files.length; i++) {

        // const image ={

        //   name :'',
        //   type : '',
        //   size: '',
        //   url:''
        // } ;

        let image = new Model_CRM_OppotunityNotes();

        // this.allfiles.push(files[i]);

        this.Uploadfile = files[i];

        image.FileName = files[i].name;
        image.FileType = files[i].type;

        image.UploadedFilename = image.FileName;

        const size = files[i].size / 1024;
        const mbc = size + '';
        const mb = mbc.split('.')[0];
        const length = mb.length;

        if (length === 4 || length === 5) {

          const mbsize = size / 1024;

          const splitdata = mbsize + '';
          const splitvalue = splitdata.split('.');
          let secondvariable = '';
          for (let j = 0; j < splitvalue.length; j++) {

            if (j === 1) {
              secondvariable = splitvalue[j].slice(0, 2);
            }
          }

          image.FileSize = splitvalue[0] + '.' + secondvariable + ' MB';
        }
        else {

          const splitdata = size + '';
          const splitvalue = splitdata.split('.');
          let secondvariable = '';
          for (let j = 0; j < splitvalue.length; j++) {

            if (j === 1) {
              secondvariable = splitvalue[j].slice(0, 2);
            }
          }

          image.FileSize = splitvalue[0] + '.' + secondvariable + ' KB';

        }



        var FileReades: FileReader = new FileReader();


        let filetyotypearray = image.FileSize.split("/");
        var re = /(?:\.([^.]+))?$/;





        this.CRM_OppertunityService.CRm_Opportunity_Notes.FileName = image.FileName

        this.CRM_OppertunityService.CRm_Opportunity_Notes.FileExtension = re.exec(image.FileName)[1];

        this.CRM_OppertunityService.CRm_Opportunity_Notes.FileType = image.FileType;

        this.CRM_OppertunityService.CRm_Opportunity_Notes.FileSize = image.FileSize;




        // image.size=files[i].size /1000;




        const reader = new FileReader();
        reader.onload = (filedata) => {
          // image.url=reader.result + '';

          this.CRM_OppertunityService.CRm_Opportunity_Notes.File = String(reader.result);


        };

        reader.readAsDataURL(files[i]);

        // reader.readAsText(files[i]);
        // this.images.push(image);

        this.CRM_OppertunityService.CRm_Opportunity_Notes.FileName = image.FileName;
        this.CRM_OppertunityService.CRm_Opportunity_Notes.UploadedFilename = image.UploadedFilename;

        // ObjFileUpload.File = String( reader.result);
        // alert(ObjFileUpload.File);



      }
      event.srcElement.value = null;
    }



  }


  DeleteFile() {

    this.CRM_OppertunityService.CRm_Opportunity_Notes.FileName = '';
    this.CRM_OppertunityService.CRm_Opportunity_Notes.FileExtension = '';
    this.CRM_OppertunityService.CRm_Opportunity_Notes.FileType = '';
    this.CRM_OppertunityService.CRm_Opportunity_Notes.FileSize = '';
    this.CRM_OppertunityService.CRm_Opportunity_Notes.UploadedFilename = '';
  }


  //End File upload




  saveCRMOppertunityNotes(): void {


    let t = localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.CRM_OppertunityService.CRm_Opportunity_Notes.IsActive = true;

    if (this.CRM_OppertunityService.CrmCommonUseOportunityDetails.OppertunityId > 0) {
      this.CRM_OppertunityService.CRm_Opportunity_Notes.OportunityId = this.CRM_OppertunityService.CrmCommonUseOportunityDetails.OppertunityId;
      this.CRM_OppertunityService.AddCRMOpportunityNotes(t, this.CRM_OppertunityService.CRm_Opportunity_Notes).subscribe(res => {
        this.Noteid = res;


        if (this.Noteid > 0) {

          this.CRM_OppertunityService.CRm_Opportunity_Notes = new Model_CRM_OppotunityNotes();
          this.CRM_OppertunityService.CRm_Opportunity_Notes.NoteId = 0;
          this.CRM_OppertunityService.CRm_Opportunity_Notes.OportunityId = 0;
          this.CRM_OppertunityService.CRm_Opportunity_Notes.NoteDate = new Date();
          this.CRM_OppertunityService.CRm_Opportunity_Notes.NoteDateval = ''
          this.CRM_OppertunityService.CRm_Opportunity_Notes.NoteTime = '';
          this.CRM_OppertunityService.CRm_Opportunity_Notes.Note = '';
          this.CRM_OppertunityService.CRm_Opportunity_Notes.Important = false;
          this.CRM_OppertunityService.CRm_Opportunity_Notes.File = '';
          this.CRM_OppertunityService.CRm_Opportunity_Notes.FileName = '';
          this.CRM_OppertunityService.CRm_Opportunity_Notes.FileSize = '';
          this.CRM_OppertunityService.CRm_Opportunity_Notes.FileType = '';
          this.CRM_OppertunityService.CRm_Opportunity_Notes.UploadedFilename = '';
          this.CRM_OppertunityService.CRm_Opportunity_Notes.ImportantLink = '';
          this.CRM_OppertunityService.CRm_Opportunity_Notes.ContactId = 0;
          this.CRM_OppertunityService.CRm_Opportunity_Notes.Salution = 0;
          this.CRM_OppertunityService.CRm_Opportunity_Notes.FileName = '';
          this.CRM_OppertunityService.CRm_Opportunity_Notes.MiddleName = '';
          this.CRM_OppertunityService.CRm_Opportunity_Notes.LastName = '';
          this.CRM_OppertunityService.CRm_Opportunity_Notes.Title = '';
          this.CRM_OppertunityService.CRm_Opportunity_Notes.WorkerPhone = '';
          this.CRM_OppertunityService.CRm_Opportunity_Notes.Email = '';
          this.CRM_OppertunityService.CRm_Opportunity_Notes.Roll = 0;

          localStorage.setItem('SuccessfullMessage', "Successfully Updated");
          this.CRM_OppertunityService.SelectedCrmIndexPage = 0;
          this.rout.navigate(["/ManageMICEAccess/CrmOppertunity/OportunityDetails"]);

        }

      });



    }

  }

  RefreshNotes() {
    this.CRM_OppertunityService.CRm_Opportunity_Notes = new Model_CRM_OppotunityNotes();
    this.CRM_OppertunityService.CRm_Opportunity_Notes.NoteId = 0;
    this.CRM_OppertunityService.CRm_Opportunity_Notes.NoteDate = new Date();
    this.CRM_OppertunityService.CRm_Opportunity_Notes.NoteDateval = '';
    this.CRM_OppertunityService.CRm_Opportunity_Notes.NoteTime = '';
    this.CRM_OppertunityService.CRm_Opportunity_Notes.Note = '';
    this.CRM_OppertunityService.CRm_Opportunity_Notes.Important = false;
    this.CRM_OppertunityService.CRm_Opportunity_Notes.File = '';
    this.CRM_OppertunityService.CRm_Opportunity_Notes.FileName = '';
    this.CRM_OppertunityService.CRm_Opportunity_Notes.FileSize = '';
    this.CRM_OppertunityService.CRm_Opportunity_Notes.FileType = '';
    this.CRM_OppertunityService.CRm_Opportunity_Notes.UploadedFilename = '';
    this.CRM_OppertunityService.CRm_Opportunity_Notes.ImportantLink = '';

    this.CRM_OppertunityService.CRm_Opportunity_Notes.ContactId = 0;
    this.CRM_OppertunityService.CRm_Opportunity_Notes.Salution = 0;
    this.CRM_OppertunityService.CRm_Opportunity_Notes.FileName = '';
    this.CRM_OppertunityService.CRm_Opportunity_Notes.MiddleName = '';
    this.CRM_OppertunityService.CRm_Opportunity_Notes.LastName = '';
    this.CRM_OppertunityService.CRm_Opportunity_Notes.Title = '';
    this.CRM_OppertunityService.CRm_Opportunity_Notes.WorkerPhone = '';
    this.CRM_OppertunityService.CRm_Opportunity_Notes.Email = '';
    this.CRM_OppertunityService.CRm_Opportunity_Notes.Roll = 0;

  }

  RefreshOpportunity(): void {

    this.RefreshNotes();
    this.CRM_OppertunityService.ResetOportunity();
    this.CRM_OppertunityService.SelectedCrmIndexPage = 0;

    this.rout.navigate(["/ManageMICEAccess/CrmOppertunity/OportunityDetails"]);

  }

  saveCRMMoreInformation(): void {
    let t = localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.CRM_OppertunityService.CRM_MoreInformation_ModelShare.IsActive = true;

    if (this.CRM_OppertunityService.CRM_MoreInformation_ModelShare.OppertunityMoreInformationId > 0) {
      this.CRM_OppertunityService.UpdateCrmMoreInformation(t, this.CRM_OppertunityService.CRM_MoreInformation_ModelShare).subscribe(res => {
        this.OppertunityMoreInformationId = Number(res);
        this.CRM_OppertunityService.CRM_MoreInformation_ModelShare.OppertunityMoreInformationId = this.OppertunityMoreInformationId;
        if (this.OppertunityMoreInformationId > 0) {

          localStorage.setItem('SuccessfullMessage', "Successfully Updated");
          this.CRM_OppertunityService.SelectedCrmIndexPage = 0;
          this.rout.navigate(["/ManageMICEAccess/CrmOppertunity/OportunityDetails"]);

        }
      });
    }
    else {
      this.CRM_OppertunityService.AddCrmMoreInformation(t, this.CRM_OppertunityService.CRM_MoreInformation_ModelShare).subscribe(res => {
        this.OppertunityMoreInformationId = Number(res);
        this.CRM_OppertunityService.CRM_MoreInformation_ModelShare.OppertunityMoreInformationId = this.OppertunityMoreInformationId;


        if (this.OppertunityMoreInformationId > 0) {
          localStorage.setItem('SuccessfullMessage', "Successfully Updated");
          this.CRM_OppertunityService.SelectedCrmIndexPage = 0;
          this.rout.navigate(["/ManageMICEAccess/CrmOppertunity/OportunityDetails"]);

        }
      });
    }
  }



  RefreshPage(): void {
   
    this.CRM_OppertunityService.ResetOportunity();

    this.CRM_OppertunityService.SelectedCrmIndexPage = 0;

    this.rout.navigate(["/ManageMICEAccess/CrmOppertunity/OportunityDetails"]);


  }

  


  get OpportunityNoteFileName(): string {
    if (this.CRM_OppertunityService.CRm_Opportunity_Notes.FileName == undefined || this.CRM_OppertunityService.CRm_Opportunity_Notes.FileName == null) {

      this.CRM_OppertunityService.CRm_Opportunity_Notes.FileName = "";
     
      return this.CRM_OppertunityService.CRm_Opportunity_Notes.FileName;
    }
    else {
     
      return this.CRM_OppertunityService.CRm_Opportunity_Notes.FileName;
    }
  }

  @Input('OpportunityNoteFileName')
  set OpportunityNoteFileName(value: string) {

    this.CRM_OppertunityService.CRm_Opportunity_Notes.FileName = value;

    if (this.CRM_OppertunityService.CRm_Opportunity_Notes.FileName == undefined || this.CRM_OppertunityService.CRm_Opportunity_Notes.FileName == null) {

      this.CRM_OppertunityService.CRm_Opportunity_Notes.FileName = "";
      
    }

    else {
      this.CRM_OppertunityService.CRm_Opportunity_Notes.FileName = value;


    }

    
  }

  get OpportunityNoteFileSize(): string {
    if (this.CRM_OppertunityService.CRm_Opportunity_Notes.FileSize == undefined || this.CRM_OppertunityService.CRm_Opportunity_Notes.FileSize == null) {
      
      this.CRM_OppertunityService.CRm_Opportunity_Notes.FileSize = "";
     
      return this.CRM_OppertunityService.CRm_Opportunity_Notes.FileSize;
    }
    else {
     
      return this.CRM_OppertunityService.CRm_Opportunity_Notes.FileSize;
    }
  }

  @Input('OpportunityNoteFileSize')
  set OpportunityNoteFileSize(value: string) {

    this.CRM_OppertunityService.CRm_Opportunity_Notes.FileSize = value;

    if (this.CRM_OppertunityService.CRm_Opportunity_Notes.FileSize == undefined || this.CRM_OppertunityService.CRm_Opportunity_Notes.FileSize == null) {

      this.CRM_OppertunityService.CRm_Opportunity_Notes.FileSize = "";
      
    }

    else {
      this.CRM_OppertunityService.CRm_Opportunity_Notes.FileSize = value;


    }

   
  }


  get OpportunityNoteFileExtension(): string {
    if (this.CRM_OppertunityService.CRm_Opportunity_Notes.FileExtension == undefined || this.CRM_OppertunityService.CRm_Opportunity_Notes.FileExtension == null) {
      
      this.CRM_OppertunityService.CRm_Opportunity_Notes.FileExtension = "";
     
      return this.CRM_OppertunityService.CRm_Opportunity_Notes.FileExtension;
    }
    else {
     
      return this.CRM_OppertunityService.CRm_Opportunity_Notes.FileExtension;
    }
  }

  @Input('OpportunityNoteFileExtension')
  set OpportunityNoteFileExtension(value: string) {

    this.CRM_OppertunityService.CRm_Opportunity_Notes.FileExtension = value;

    if (this.CRM_OppertunityService.CRm_Opportunity_Notes.FileExtension == undefined || this.CRM_OppertunityService.CRm_Opportunity_Notes.FileExtension == null) {

      this.CRM_OppertunityService.CRm_Opportunity_Notes.FileExtension = "";
      
    }

    else {
      this.CRM_OppertunityService.CRm_Opportunity_Notes.FileExtension = value;


    }

    
  }


  get OpportunityNoteReason(): string {
    if (this.CRM_OppertunityService.CRM_MoreInformation_ModelShare.Reason == undefined || this.CRM_OppertunityService.CRM_MoreInformation_ModelShare.Reason == null) {
      
      this.CRM_OppertunityService.CRM_MoreInformation_ModelShare.Reason = "";
     
      return this.CRM_OppertunityService.CRM_MoreInformation_ModelShare.Reason;
    }
    else {
     
      return this.CRM_OppertunityService.CRM_MoreInformation_ModelShare.Reason;
    }
  }

  @Input('OpportunityNoteReason')
  set OpportunityNoteReason(value: string) {

    this.CRM_OppertunityService.CRM_MoreInformation_ModelShare.Reason = value;

    if (this.CRM_OppertunityService.CRM_MoreInformation_ModelShare.Reason == undefined || this.CRM_OppertunityService.CRM_MoreInformation_ModelShare.Reason == null) {

      this.CRM_OppertunityService.CRM_MoreInformation_ModelShare.Reason = "";
      
    }

    else {
      this.CRM_OppertunityService.CRM_MoreInformation_ModelShare.Reason = value;


    }

    
  }


  get OpportunityNoteRetention(): number {
    if (this.CRM_OppertunityService.CRM_MoreInformation_ModelShare.Retention == undefined || this.CRM_OppertunityService.CRM_MoreInformation_ModelShare.Retention == null) {
      
      this.CRM_OppertunityService.CRM_MoreInformation_ModelShare.Retention =0;
     
      return this.CRM_OppertunityService.CRM_MoreInformation_ModelShare.Retention;
    }
    else {
     
      return this.CRM_OppertunityService.CRM_MoreInformation_ModelShare.Retention;
    }
  }

  @Input('OpportunityNoteRetention')
  set OpportunityNoteRetention(value: number) {

    this.CRM_OppertunityService.CRM_MoreInformation_ModelShare.Retention = value;

    if (this.CRM_OppertunityService.CRM_MoreInformation_ModelShare.Retention == undefined || this.CRM_OppertunityService.CRM_MoreInformation_ModelShare.Retention == null) {

      this.CRM_OppertunityService.CRM_MoreInformation_ModelShare.Retention = 0;
      
    }

    else {
      this.CRM_OppertunityService.CRM_MoreInformation_ModelShare.Retention = 0;


    }

    
  }




  get OpportunityNoteGPFigure(): number {
    if (this.CRM_OppertunityService.CRM_MoreInformation_ModelShare.GPFigure == undefined || this.CRM_OppertunityService.CRM_MoreInformation_ModelShare.GPFigure == null) {
      
      this.CRM_OppertunityService.CRM_MoreInformation_ModelShare.GPFigure =0;
     
      return this.CRM_OppertunityService.CRM_MoreInformation_ModelShare.GPFigure;
    }
    else {
     
      return this.CRM_OppertunityService.CRM_MoreInformation_ModelShare.GPFigure;
    }
  }

  @Input('OpportunityNoteGPFigure')
  set OpportunityNoteGPFigure(value: number) {

    this.CRM_OppertunityService.CRM_MoreInformation_ModelShare.GPFigure = value;

    if (this.CRM_OppertunityService.CRM_MoreInformation_ModelShare.GPFigure == undefined || this.CRM_OppertunityService.CRM_MoreInformation_ModelShare.GPFigure == null) {

      this.CRM_OppertunityService.CRM_MoreInformation_ModelShare.GPFigure = 0;
      
    }

    else {
      this.CRM_OppertunityService.CRM_MoreInformation_ModelShare.GPFigure = 0;


    }

    

    
  }

  get OpportunityNoteIsViewstate(): boolean {
    if (this.CRM_OppertunityService.IsOppinViewstate == undefined || this.CRM_OppertunityService.IsOppinViewstate== null) {
      
      this.CRM_OppertunityService.IsOppinViewstate =false;
     
      return this.CRM_OppertunityService.IsOppinViewstate;
    }
    else {
     
      return this.CRM_OppertunityService.IsOppinViewstate;
    }
  }
  





}
