<div class="body-content-area tabcontent" id="link1">
    <div class="row">
        <div class="col-lg-9">
            <div class="row">
                <div class="col-lg-1 low-padding text-center">
                    <!-- <img *ngIf="this.SelectedMenu.Image!=null" src={{this.SelectedMenu.Image}} class="body-icon" alt="Icon"/> -->
                </div>
                <div class="col-lg-10 clear-padding">
                    <span class="page-title">Manage Company</span>
                    <small class="page-description"></small>
                   
                </div>
            </div>
        </div>
    </div>

    <div *ngIf="!(this.CanViewState)">  
        <div style=" text-align: right;">
            <button (click)="ADDClick(template)" type="button" class="btn btn-primary Add-button">Add New Company</button> 
        </div>
        <fieldset class="body-form-control">
            <legend class="legend--">Company Details</legend>
            <table id="tblcompany" class="table table-striped table-bordered text-center table-new row-border hover">
                <thead class="thead-blue text-center">
                    <tr>
                        <th>Sl No.</th>
                        <th>Company Code</th>
                        <th>Company Name</th>
                        <th>Address</th>
                        <th>Contact Number</th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let comp of Companies, let i = index">
                        <td scope="row">{{i+1}}</td>
                        <td>{{comp.CompCode}}</td>
                        <td class="text-left">{{comp.CompName}}</td>
                        <td class="text-left">{{comp.Address1}}</td>
                        <td>{{comp.PhoneNo1}}</td>
                        <td>
                            <button *ngIf="this.SelectedMenu.CanEdit" type="button" (click)="GetCompanByid(comp.CompId ,template)"class="table-edit-btn"><i class="fas fa-edit"></i> </button> 
                            <button *ngIf="this.SelectedMenu.CanDelete" type="button" (click)="DeleteCompanByid(comp.CompId,template)" class="table-remove-btn"><i class="fas fa-trash-alt"></i></button>
                            <button *ngIf="this.SelectedMenu.CanView" type="button" (click)="viewCompanByid(comp.CompId,template)" class="table-view-button" type="button" ><i class="fas fa-eye"></i></button>
                        </td>
                    </tr>
                </tbody>
                <tfoot class="thead-blue">
                    <tr>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th></th>
                    </tr>
                </tfoot>
            </table>
        </fieldset>

        <div style=" box-align: center; width: 1500px;" >
            <ng-template #template>
                <div class="modal-header" style="background: #2a9bd7; max-width: 1500px;"> 
                    <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()" style="color:#fff;">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div  style="text-align: center; max-width: 1500px;">
                <div *ngIf="SaveMsg" style="color: #dc3545; text-align: center;font-size: 15px; font-weight: bold;">Company Save Successfully</div>
                <div *ngIf="UpdateMsg" style="color: #dc3545; text-align: center;font-size: 15px; font-weight: bold;">Company Update Successfully</div>
                <div *ngIf="DeleteMsg" style="color: #dc3545; text-align: center;font-size: 15px; font-weight: bold;">Company Delete Successfully</div>
                <div style=" width: 100%;" *ngIf="ShowPopup">
                    <fieldset class="body-form-control">
                        <legend class="legend--">Company Creation</legend>
                        <form #companyForm="ngForm" (ngSubmit)="saveCompany(template)">
                            <div class="row" style=" text-align: left;">
                                <div class="col-lg-4">
                                    <div class="col-body-border-right">
                                        <div class="form-group row">
                                            <label  class="col-sm-4 col-form-label clear-padding-right">Code</label>
                                            <div *ngIf="!(this.IsDataIsInViewState)" class="col-sm-7 clear-left-padding">
                                                <div>
                                                    <input type="text" [(ngModel)]="CompanyModel.CompCode" tabindex="1" maxlength="40" id ="CompCode"  name="CompCode" #CompCode="ngModel" required class="form-control form-control-custom form-control-custom-new" (blur)="GetCompanyByCompanycode()" >
                                                </div>
                                                <div *ngIf="this.companyCodecheck.length > 0" class="alert alert-danger">Company Code is exist</div>
                                                <div *ngIf="CompCode.invalid && (CompCode.dirty || CompCode.touched)" class="alert alert-danger">
                                                    <div *ngIf="CompCode.errors.required">Company Code is required.</div>
                                                    <div *ngIf="CompCode.errors.maxlength">Company Code is not more than 40 characters</div>
                                                </div>
                                            </div>
                                            <div *ngIf="(this.IsDataIsInViewState)">
                                                :&nbsp;&nbsp;{{CompanyModel.CompCode}}
                                            </div>
                                        </div>
                                        <div class="form-group row">
                                            <label  class="col-sm-4 col-form-label clear-padding-right">Address 2</label>
                                            <div *ngIf="!(this.IsDataIsInViewState)" class="col-sm-7 clear-left-padding">
                                                <div>
                                                    <input type="text" [(ngModel)]="CompanyModel.Address2" tabindex="4" id ="Address2"  name="Address2" #Address2="ngModel" required maxlength="500" class="form-control form-control-custom form-control-custom-new" >
                                                </div>
                                                <div *ngIf="Address2.invalid && (Address2.dirty || Address2.touched)" class="alert alert-danger">
                                                    <div *ngIf="Address2.errors.required">Address2  is required.</div>
                                                    <div *ngIf="Address2.errors.maxlength">Address2 is not more than 500 characters</div>
                                                </div>
                                            </div>
                                            <div *ngIf="(this.IsDataIsInViewState)">
                                                :&nbsp;&nbsp;{{CompanyModel.Address2}}
                                            </div>
                                        </div>
                                        <div class="form-group row">
                                            <label  class="col-sm-4 col-form-label clear-padding-right">City</label>
                                            <div class="col-sm-7 clear-left-padding">
                                                <select  [(ngModel)]="CompanyModel.CityId" id ="CityId" class="form-control" name="CityId" #CityId="ngModel">
                                                    <option [value]="0" [disabled]=true >Select City</option>
                                                    <option *ngFor="let c of Cities" [value]="c.CityId">{{c.CityName}}</option>
                                                </select>
                                            </div>
                                            <div class="required-validation" *ngIf="CompanyModel.CityId==0">City is required</div>
                                        </div>
                                        <div class="form-group row">
                                            <label  class="col-sm-4 col-form-label clear-padding-right">Contact No</label>
                                            <div *ngIf="!(this.IsDataIsInViewState)" class="col-sm-7 clear-left-padding">
                                                <div>
                                                    <input type="text" [(ngModel)]="CompanyModel.PhoneNo1" tabindex="10"  required  maxlength="10" minlength="10"  pattern="[0-9]+" id ="PhoneNo1"  name="PhoneNo1" #PhoneNo1="ngModel" class="form-control form-control-custom form-control-custom-new" >
                                                </div>
                                                <div *ngIf="PhoneNo1.invalid && (PhoneNo1.dirty || PhoneNo1.touched)" class="alert alert-danger">
                                                    <div *ngIf="PhoneNo1.errors.required">Contact No  is required.</div>
                                                    <div *ngIf="PhoneNo1.errors.maxlength">Contact No is not more than 10 digit.</div>
                                                    <div *ngIf="PhoneNo1.errors.minlength">Contact No is not less than 10 dogit.</div>
                                                    <div *ngIf="PhoneNo1.errors?.pattern">Contact No should be valid.</div>
                                                </div>
                                            </div>
                                            <div *ngIf="(this.IsDataIsInViewState)">
                                                :&nbsp;&nbsp;{{CompanyModel.PhoneNo1}}
                                            </div>
                                        </div>
                                        <div class="form-group row">
                                            <label  class="col-sm-4 col-form-label clear-padding-right">Fax No</label>
                                            <div *ngIf="!(this.IsDataIsInViewState)" class="col-sm-7 clear-left-padding">
                                                <div>
                                                    <input type="text" [(ngModel)]="CompanyModel.Fax" tabindex="13" maxlength="10" minlength="10" pattern="[0-9]+" id ="Fax"  name="Fax" #Fax="ngModel" class="form-control form-control-custom form-control-custom-new" >
                                                </div>
                                                <div *ngIf="Fax.invalid && (Fax.dirty || Fax.touched)" class="alert alert-danger">
                                                    <div *ngIf="Fax.errors.maxlength">Fax No is not more than 10 digit.</div>
                                                    <div *ngIf="Fax.errors.minlength">Fax No is not less than 10 dogit.</div>
                                                    <div *ngIf="Fax.errors?.pattern">Fax No should be valid.</div>
                                                </div>
                                            </div>
                                            <div *ngIf="(this.IsDataIsInViewState)">
                                                :&nbsp;&nbsp;{{CompanyModel.Fax}}
                                            </div>
                                        </div>
                                        <div class="form-group row">
                                            <label  class="col-sm-4 col-form-label clear-padding-right">Registation no</label>
                                            <div *ngIf="!(this.IsDataIsInViewState)" class="col-sm-7 clear-left-padding">
                                                <div>
                                                    <input type="text" [(ngModel)]="CompanyModel.RegistrationNumber" id ="registationNo" tabindex="16" name="registationNo" class="form-control form-control-custom form-control-custom-new" >
                                                </div>
                                            </div>
                                            <div *ngIf="(this.IsDataIsInViewState)">
                                                :&nbsp;&nbsp;{{CompanyModel.RegistrationNumber}}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                
                                <div class="col-lg-4">
                                    <div class="col-body-border-right">
                                        <div class="form-group row">
                                            <label  class="col-sm-4 col-form-label clear-padding-right">Name</label>
                                            <div *ngIf="!(this.IsDataIsInViewState)" class="col-sm-7 clear-left-padding">
                                                <div>
                                                    <input type="text" [(ngModel)]="CompanyModel.CompName" tabindex="2" id ="CompName" (blur)="CheckName()" name="CompName" #CompName="ngModel" required maxlength="250" class="form-control form-control-custom form-control-custom-new" >
                                                </div>
                                                <div *ngIf="this.Namecheck.length > 0" class="alert alert-danger">Company Name is exist</div>
                                                <div *ngIf="CompName.invalid && (CompName.dirty || CompName.touched)" class="alert alert-danger">
                                                    <div *ngIf="CompName.errors.required">Compy Name  is required.</div>
                                                    <div *ngIf="CompName.errors.maxlength">Compy Name is not more than 250 characters</div>
                                                </div>
                                            </div>
                                            <div *ngIf="(this.IsDataIsInViewState)">
                                                :&nbsp;&nbsp;{{CompanyModel.CompName}}
                                            </div>
                                        </div>
                                        <div class="form-group row">
                                            <label class="col-sm-4 col-form-label clear-padding-right">Country</label>
                                            <div class="col-sm-7 clear-left-padding">
                                                <select [(ngModel)]="CompanyModel.CountryId"  tabindex="5" (ngModelChange)="onChange($event)" id ="CountryId" class="form-control"  name="CountryId" #CountryId="ngModel" >
                                                    <option [value]="0" [disabled]=true >Select Country</option>
                                                    <option *ngFor="let c of Counries" [value]="c.CountryId">{{c.CountryName}}</option>
                                                </select>
                                            </div>
                                            <div class="required-validation" *ngIf="CompanyModel.CountryId==0">Country is required</div>
                                        </div>
                                        <div class="form-group row">
                                            <label class="col-sm-4 col-form-label clear-padding-right">Pin Code</label>
                                            <div *ngIf="!(this.IsDataIsInViewState)" class="col-sm-7 clear-left-padding">
                                                <div>
                                                    <input type="text" [(ngModel)]="CompanyModel.PostCode" tabindex="8" id ="PostCode" maxlength="6" minlength="6"  name="PostCode" #PostCode="ngModel" pattern="[0-9]+" required class="form-control form-control-custom form-control-custom-new" >
                                                </div>
                                                <div *ngIf="PostCode.invalid && (PostCode.dirty || PostCode.touched)" class="alert alert-danger">
                                                    <div *ngIf="PostCode.errors.required">PostCode is required.</div>
                                                    <div *ngIf="PostCode.errors.maxlength">PostCode not more than 6 digits.</div>
                                                    <div *ngIf="PostCode.errors.minlength">PostCode not more than 6 digits.</div>
                                                    <div *ngIf="PostCode.errors?.pattern">PostCode should be valid</div>
                                                </div>
                                            </div>
                                            <div *ngIf="(this.IsDataIsInViewState)">
                                                :&nbsp;&nbsp;{{CompanyModel.PostCode}}
                                            </div>
                                        </div>
                                        <div class="form-group row">
                                            <label  class="col-sm-4 col-form-label clear-padding-right">Mobile No</label>
                                            <div *ngIf="!(this.IsDataIsInViewState)" class="col-sm-7 clear-left-padding">
                                                <div>
                                                    <input type="text" [(ngModel)]="CompanyModel.PhoneNo2" tabindex="10" maxlength="10" minlength="10" pattern="[0-9]+" id ="PhoneNo2"  name="PhoneNo2" #PhoneNo2="ngModel" class="form-control form-control-custom form-control-custom-new" >
                                                </div>
                                                <div *ngIf="PhoneNo2.invalid && (PhoneNo2.dirty || PhoneNo2.touched)" class="alert alert-danger">
                                                    <div *ngIf="PhoneNo2.errors.maxlength">Mobile No is not more than 10 digit.</div>
                                                    <div *ngIf="PhoneNo2.errors.minlength">Mobile No is not less than 10 dogit.</div>
                                                    <div *ngIf="PhoneNo2.errors?.pattern">Mobile No should be valid.</div>
                                                </div>
                                            </div>
                                            <div *ngIf="(this.IsDataIsInViewState)">
                                                :&nbsp;&nbsp;{{CompanyModel.PhoneNo2}}
                                            </div>
                                        </div>
                                        <div class="form-group row">
                                            <label  class="col-sm-4 col-form-label clear-padding-right">PAN No</label>
                                            <div *ngIf="!(this.IsDataIsInViewState)" class="col-sm-7 clear-left-padding">
                                                <div>
                                                    <input type="text" [(ngModel)]="CompanyModel.PANNumber" id ="PANNumber"  #PANNumber="ngModel"  tabindex="14" name="PANNumber" class="form-control form-control-custom form-control-custom-new" >
                                                </div>
                                            </div>
                                            <div *ngIf="(this.IsDataIsInViewState)">
                                                :&nbsp;&nbsp;{{CompanyModel.PANNumber}}
                                            </div>
                                        </div>
                                        <div class="form-group row">
                                            <label  class="col-sm-4 col-form-label clear-padding-right">Logo</label>
                                            <div *ngIf="!(this.IsDataIsInViewState)" class="col-sm-7 clear-left-padding">  
                                                <div>
                                                    <img *ngIf="this.CompanyModel.Immage!=null" [src]="CompanyModel.Immage" style="width:100px; height: 100px;" >
                                                    <input type="file" (change)="handleFileinput($event.target.files)" #Image accept="image/*" >
                                                </div>
                                                <label *ngIf="!CompanyModel.validfile"  class="col-sm-4 col-form-label clear-padding-right">Invalid File, will not be uploaded </label>
                                            </div>
                                            <div *ngIf="(this.IsDataIsInViewState)">
                                                <img *ngIf="this.CompanyModel.Immage!=null" [src]="CompanyModel.Immage" style="width:100px; height: 100px;">
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-lg-4">
                                    <div class="form-group row">
                                        <label  class="col-sm-4 col-form-label clear-padding-right">Address</label>
                                        <div *ngIf="!(this.IsDataIsInViewState)" class="col-sm-7 clear-left-padding">
                                            <div>
                                                <input type="text" [(ngModel)]="CompanyModel.Address1" tabindex="3" id ="Address1"  name="Address1" #Address1="ngModel" required maxlength="500" class="form-control form-control-custom form-control-custom-new" >
                                            </div>
                                            <div *ngIf="Address1.invalid && (Address1.dirty || Address1.touched)" class="alert alert-danger">
                                                <div *ngIf="Address1.errors.required">Address1 is required.</div>
                                                <div *ngIf="Address1.errors.maxlength">Address1 is not more than 500 characters</div>
                                            </div>
                                        </div>
                                        <div *ngIf="(this.IsDataIsInViewState)">
                                            :&nbsp;&nbsp;{{CompanyModel.Address1}}
                                        </div>
                                    </div>
                                    <div class="form-group row">
                                        <label  class="col-sm-4 col-form-label clear-padding-right">State</label>
                                        <div class="col-sm-7 clear-left-padding">
                                            <select [(ngModel)]="CompanyModel.StateId" tabindex="6" (ngModelChange)="onstatechange($event)"  id ="StateId" class="form-control" name="StateId" #StateId="ngModel" >
                                                <option [value]="0" [disabled]=true >Select State</option>
                                                <option *ngFor="let s of States" [value]="s.StateID">{{s.StateName}}</option>
                                            </select>
                                        </div>
                                        <div class="required-validation" *ngIf="CompanyModel.StateId==0">State is required</div>
                                    </div>
                                    <div class="form-group row">
                                        <label class="col-sm-4 col-form-label clear-padding-right">Website</label>
                                        <div *ngIf="!(this.IsDataIsInViewState)" class="col-sm-7 clear-left-padding">
                                            <div>
                                                <input type="text" [(ngModel)]="CompanyModel.Website" tabindex="9" id ="Website"  name="Website" #Website="ngModel" class="form-control form-control-custom form-control-custom-new" >
                                            </div>
                                        </div>
                                        <div *ngIf="(this.IsDataIsInViewState)">
                                            :&nbsp;&nbsp;{{CompanyModel.Website}}
                                        </div>
                                    </div>
                                    <div class="form-group row">
                                        <label  class="col-sm-4 col-form-label clear-padding-right">Email Id</label>
                                        <div *ngIf="!(this.IsDataIsInViewState)" class="col-sm-7 clear-left-padding">  
                                            <div>
                                                <input type="text" [(ngModel)]="CompanyModel.Email" tabindex="12" required id ="Email"  name="Email"  #Email="ngModel" pattern="^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$" class="form-control form-control-custom form-control-custom-new" >
                                            </div>
                                            <div *ngIf="Email.invalid && (Email.dirty || Email.touched)" class="alert alert-danger">
                                                <div *ngIf="Email.errors.required">Email is required.</div>
                                                <div *ngIf="Email.errors?.pattern">Email should be valid.</div>
                                            </div>
                                        </div>
                                        <div *ngIf="(this.IsDataIsInViewState)">
                                            :&nbsp;&nbsp;{{CompanyModel.Email}}
                                        </div>
                                    </div>
                                    <div class="form-group row">
                                        <label  class="col-sm-4 col-form-label clear-padding-right">TAN No</label>
                                        <div *ngIf="!(this.IsDataIsInViewState)" class="col-sm-7 clear-left-padding">
                                            <div>
                                                <input type="text" [(ngModel)]="CompanyModel.TANNumber" id ="TANNumber" tabindex="15" #TANNumber="ngModel"  name="TANNumber" class="form-control form-control-custom form-control-custom-new" >
                                            </div>
                                        </div>
                                        <div *ngIf="(this.IsDataIsInViewState)">
                                            :&nbsp;&nbsp;{{CompanyModel.TANNumber}}
                                        </div>
                                    </div>
                                    <div class="form-group row">
                                        <label  class="col-sm-6 col-form-label text-center clear-padding-right" *ngIf="!(this.IsDataIsInViewState)">
                                            <button  type="submit" tabindex="18" class="btn btn-primary save-button" [disabled]="!(companyForm.valid && CompanyModel.StateId !=0 && CompanyModel.CountryId!=0 && CompanyModel.CityId!=0 && this.CheckcompanyCodeExiststatus==true && this.DisableSave==true)">Save</button>  
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </fieldset>
                </div>
                <button type="button" aria-label="Close" (click)="modalRef.hide()" style="background: #2a9bd7; outline: 0px none; border: 0px none; color: #fff; padding: 5px 10px; border-radius: 3px;">
                <span>Close</span>
                </button>             
                </div>
            </ng-template>
        </div>
    </div>
</div>