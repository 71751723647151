import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs/Subject';
import { DataTableDirective } from 'angular-datatables';
import { OrganizationService } from '../shared/Organization/organization.service';
import { LocationService } from '../shared/HR_ADMIN/Location/location.service';
import { Country } from '../shared/Organization/Country.Model';
import { Zone } from '../shared/Organization/Zone.Model';
import { UserService } from '../shared/user.service';
// Begning Confirmation
import { ConfirmationService } from '@jaspero/ng2-confirmations';
import { ResolveEmit } from '../../app/resolve-emit';
import { ConfirmSettings } from '../../app/confirm-settings';
// end of Confirmation
import { Menu } from '../shared/menu.model';

@Component({
  selector: 'app-manage-zone',
  templateUrl: './manage-zone.component.html',
  styleUrls: ['./manage-zone.component.css']
})
export class ManageZoneComponent implements OnInit 
{
  ZoneModel : Zone= new Zone();
  userClaims: any;
  Countries: Country[] = [];
  Zones: Zone[] = [];    
  Result : string;
  SuccessStatus:boolean=false;
  SuccessMessage: string ="";
  public tableWidget: any;
  countryId : any;
  country : any;
  zoneId : any;
  zone : any;
  tempzoneadd : Zone =new Zone();
  // Begning Permission Portion    
  SelectedMenu:Menu;
  IsDataIsInViewState : boolean=false;
  CanViewState : boolean=false;
  DisableSave : boolean=false;
  FormViewShow : boolean =false;
  CanSaveButtonView : boolean =false;
  // End of the permission portion
  //check for duplicate Name
  CheckNameExiststatus : boolean=null;  
  CheckNamestatusedit : boolean =false;
  Namecheck : string ="";
  checkName : any;
  checkNameval : string ="";
  //check for duplicate Name

  constructor(private organizationService: OrganizationService, private rout: Router,
  private LocationService : LocationService,private _confirmation: ConfirmationService,
  private userService: UserService )  { }

  @ViewChild(DataTableDirective, {static: false})
  setDtOptions: DataTables.Settings = {};
  cardDtOptions: DataTables.Settings = {};
  dtElement: DataTableDirective;
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();

  ngOnInit() 
  {
    // Begning Permission Portion 
    this.SelectedMenu=this.userService.SelectedMenu;
    if(this.SelectedMenu !=undefined)
    {
      localStorage.setItem('selectedmenu',JSON.stringify(this.SelectedMenu));
      this.rout.navigate([this.SelectedMenu.RouteName]);
    }
    else
    {
      if(JSON.parse(localStorage.getItem('selectedmenu'))!=undefined)
      {
        this.SelectedMenu = JSON.parse(localStorage.getItem('selectedmenu'));
        this.rout.navigate([this.SelectedMenu.RouteName]);
      }
    }
    if(this.SelectedMenu.CanAdd)
    {
      this.IsDataIsInViewState=false;
      this.CanSaveButtonView=true;
    }
    else
    {
      this.IsDataIsInViewState=true;
    }
    if(this.SelectedMenu.CanView)
    {
      this.CanViewState=false;
    }
    else
    {
      this.CanViewState=true;
    }
    // End of the permission portion
    this.DisableSave=true;
    this.FormViewShow=false;
    this.getCountries();
    this.ZoneModel.CountryID=0;
    if(localStorage.getItem('SuccessfullMessage'))
    {
      this.SuccessStatus=true;
      this.SuccessMessage =localStorage.getItem('SuccessfullMessage')
      localStorage.removeItem('SuccessfullMessage');
    }
  }

  settings: ConfirmSettings | any = 
  {
    overlay: true,
    overlayClickToClose: true,
    showCloseButton: true,
    confirmText: 'Yes',
    declineText: 'No'
  };

  ngAfterViewInit(): void 
  {
    this.getZones();
    this.dtOptions = 
    {
      pagingType: 'full_numbers',
      pageLength: 8
    };
  }

  getZones() 
  {
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.LocationService.GetZone( t).then(x => 
    {
      this.zone = x;
      Object.keys(this.zone).forEach( key => 
      {
        // tslint:disable-next-line:no-unused-expression
        this.zone[key];
        let zonetemp = new Zone();
        zonetemp = this.zone[key];
        this.Zones.push(zonetemp);
      });
      this.dtTrigger.next();     
    });      
  }

  getCountries() 
  {
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.organizationService.GetCountry( t).then(x => 
    {
      this.country = x;
      Object.keys(this.country).forEach( key => 
      {
        // tslint:disable-next-line:no-unused-expression
        this.country[key];
        let Countrytemp = new Country();
        Countrytemp = this.country[key];
        this.Countries.push(Countrytemp);
      });
    });      
  }
  
  ADDClick()
  {
    this.FormViewShow=true;
    this.CanSaveButtonView=false;
  }

  GetZoneByid(id) 
  {
    // begning of the permission portion
    this.IsDataIsInViewState=false;
    // end of the permission portion
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.LocationService.GetZoneByid( t,id).then(x => 
    {
      this.FormViewShow=true;
      this.CanSaveButtonView=false;
      this.zone = x;
      Object.keys(this.zone).forEach( key => 
      {
        // tslint:disable-next-line:no-unused-expression
        this.zone[key];
        let zonetemp = new Zone();
        zonetemp = this.zone[key];
        this.ZoneModel.ZoneID=zonetemp.ZoneID;
        this.ZoneModel.CountryID=zonetemp.CountryID;
        this.ZoneModel.ZoneCode=zonetemp.ZoneCode;
        this.ZoneModel.ZoneName=zonetemp.ZoneName;
        this.ZoneModel.CountryName=zonetemp.CountryName; 
      });
      // Begin for check Name duplication
      this.checkNameval= this.ZoneModel.ZoneName;
      this.CheckNamestatusedit = true;
      this.CheckNameExiststatus = true;
      this.Namecheck=""
      // End for check Name duplication
    });
  }

  ViewZoneByid(id) 
  {
    // begning of the permission portion
    this.IsDataIsInViewState=true;
    // end of the permission portion
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.LocationService.GetZoneByid( t,id).then(x => 
    {
      this.FormViewShow=true;
      this.CanSaveButtonView=false;
      this.zone = x;
      Object.keys(this.zone).forEach( key => 
      {
        // tslint:disable-next-line:no-unused-expression
        this.zone[key];
        let zonetemp = new Zone();
        zonetemp = this.zone[key];
        this.ZoneModel.ZoneID=zonetemp.ZoneID;
        this.ZoneModel.CountryID=zonetemp.CountryID;
        this.ZoneModel.ZoneCode=zonetemp.ZoneCode;
        this.ZoneModel.ZoneName=zonetemp.ZoneName;
        this.ZoneModel.CountryName=zonetemp.CountryName; 
      });
    });
  }

  saveZone() :void
  {
    this.DisableSave=false;
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.ZoneModel.IsActive=true;
    if(this.ZoneModel.ZoneID > 0)
    {
      this.LocationService.UpdateZone(t ,this.ZoneModel).subscribe(res=>
      {
        this.zoneId=res;
        this.ZoneModel.ZoneID=0;  
        if(this.zoneId>0)
        {
          localStorage.setItem('SuccessfullMessage', "Update Successfully");
          this.rout.navigateByUrl('/Location/ManageCountry', {skipLocationChange: true}).then(()=>this.rout.navigate(["/Location/ManageZone"]));
        }
      });                  
    }
    else
    {
      this.LocationService.AddZone(t ,this.ZoneModel).subscribe(res=>
      {
        this.zoneId=res;
        this.ZoneModel.ZoneID=0;                
        if(this.zoneId>0)
        {
          localStorage.setItem('SuccessfullMessage', "Save Successfully");
          this.rout.navigateByUrl('/Location/ManageCountry', {skipLocationChange: true}).then(()=>this.rout.navigate(["/Location/ManageZone"]));
        }
      }); 
    }
  }

  DeleteZoneByid(Zoneid : number) :void
  {
    this._confirmation.create('Confirm to Deleate', 'Press Yes to Delete', this.settings)
    .subscribe((ans: ResolveEmit) => 
    {
      if (ans.resolved == true) 
      {
        let t =  localStorage.getItem('userToken');
        t = 'Bearer' + t;
        this.ZoneModel.IsActive=true;
        if( Zoneid > 0)
        {
          this.LocationService.DeleteZone(t ,Zoneid).subscribe(res=>
          {
            this.zoneId=res;
            this.ZoneModel.ZoneID=0;                                  
            if(this.zoneId >0)
            {
              localStorage.setItem('SuccessfullMessage', "Deleted Successfully");
              this.rout.navigateByUrl('/Location/ManageCountry', {skipLocationChange: true}).then(()=>this.rout.navigate(["/Location/ManageZone"]));
            }
          });                  
        }
      } 
    })
  }

  RefreshPage() 
  {       
    this.rout.navigateByUrl('/Location/ManageCountry', {skipLocationChange: true}).then(()=>this.rout.navigate(["/Location/ManageZone"]));
  }
  // begning of check duplicate Name
  CheckName()
  {
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    if(!this.CheckNamestatusedit)
    {
      this.LocationService.CheckZoneName( t, this.ZoneModel.ZoneName,this.ZoneModel.CountryID).then(x => 
      {
        this.checkName = x;
        if( this.checkName.length >0)
        {
          this.CheckNameExiststatus =false;
          this.Namecheck="Exist"
        }
        else
        {
          this.CheckNameExiststatus =true;
          this.Namecheck="";
        }
     });
    }
    else
    {
      if(this.ZoneModel.ZoneName !=this.checkNameval )
      {
        this.LocationService.CheckZoneName( t, this.ZoneModel.ZoneName,this.ZoneModel.CountryID).then(x => 
        {
          this.checkName = x;
          if( this.checkName.length >0)
          {
            this.CheckNameExiststatus =false;
            this.Namecheck="Exist"
          }
          else
          {
            this.CheckNameExiststatus =true;
            this.Namecheck="";
          }
        });
      }
      else
      {
        this.CheckNameExiststatus =true;
        this.Namecheck="";
      }
    }
  }
  // end of duplicate Name
}
