export class KRAMappingModel
{
    public RefId : number;
    public CompanyId : number;
    public DecisionId : number;
    public LevelId : number;
    public GradeId : number;
    public DesignationId : number;
    public KRAMasterIds : string;
    public KRAName : string;
    public KRAMasterName : string;
    public IsActive : boolean;
}