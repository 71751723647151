import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs/Subject';
import { OrganizationService}  from '../shared/Organization/organization.service';
import { Department } from  '../shared/Organization/Department.Model';
import { Branch } from  '../shared/Organization/Branch.Model';
import { DataTableDirective } from 'angular-datatables';
import { Company } from '../shared/Organization/Company.Model';
import { Menu } from '../shared/menu.model';
import { UserService } from '../shared/user.service';
// Begning Confirmation
import { ConfirmationService } from '@jaspero/ng2-confirmations';
import { ResolveEmit } from '../../app/resolve-emit';
import { ConfirmSettings } from '../../app/confirm-settings';
// end of Confirmation

@Component
({
  selector: 'app-manage-departmeiants',
  templateUrl: './manage-departments.component.html',
  styleUrls: ['./manage-departments.component.css']
})

export class ManageDepartmentsComponent implements OnInit 
{
  DepartmentModel : Department= new Department();
  userClaims: any;
  Branches: Branch[] = [];
  Departmentes: Department[] = [];
  Employees: Department[] = [];
  Companies : Company[]=[];
  Branch:any;
  Depart: any;
  company : any;
  Result : string;
  SuccessStatus:boolean=false;
  SuccessMessage: string ="";
  public tableWidget: any;
  DepartmentId : any;
  // Begning Permission Portion    
  SelectedMenu:Menu;
  IsDataIsInViewState : boolean=false;
  CanViewState : boolean=false;
  DisableSave :boolean=false;
  // End of the permission portion
  //check for duplicate Name
  CheckNameExiststatus : boolean=null;  
  CheckNamestatusedit : boolean =false;
  Namecheck : string ="";
  checkName : any;
  checkNameval : string ="";
  //check for duplicate Name
  FormViewShow : boolean =false;
  CanSaveButtonView : boolean =false;

  tempDepartmentadd : Department =new Department();

  constructor(private organizationService: OrganizationService, private rout: Router, 
    private _confirmation: ConfirmationService, private userService: UserService) { }

  @ViewChild(DataTableDirective, {static: false})
  setDtOptions: DataTables.Settings = {};
  cardDtOptions: DataTables.Settings = {};
  dtElement: DataTableDirective;
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();

  ngOnInit() 
  {
    this.getCompanies();
    this.getFunctionalHead();
    this.dtOptions = 
    {
      pagingType: 'full_numbers',
      pageLength: 8
    };
    this.DepartmentModel.FunctionalHead=0;
    this.DepartmentModel.CompId=0;
    this.DepartmentModel.DepartmentId=0;
     //Begin Permission Portion
    this.SelectedMenu=this.userService.SelectedMenu;
    if(this.SelectedMenu !=undefined)
    {
      localStorage.setItem('selectedmenu',JSON.stringify(this.SelectedMenu));
      this.rout.navigate([this.SelectedMenu.RouteName]);
    }
    else
    {
      if(JSON.parse(localStorage.getItem('selectedmenu'))!=undefined)
      {
        this.SelectedMenu = JSON.parse(localStorage.getItem('selectedmenu'));
        this.rout.navigate([this.SelectedMenu.RouteName]);
      }
    }     
    if(this.SelectedMenu.CanAdd)
    {
      this.IsDataIsInViewState=false;
      this.CanSaveButtonView=true;
    }
    else
    {
      this.IsDataIsInViewState=true;
    }
    if(this.SelectedMenu.CanView)
    {
      this.CanViewState=false;
    }
    else
    {
      this.CanViewState=true;
    }
    //End of the peremission porttion
    this.DisableSave=true;
    if(localStorage.getItem('SuccessfullMessage'))
    {
      this.SuccessStatus=true;
      this.SuccessMessage =localStorage.getItem('SuccessfullMessage')
      localStorage.removeItem('SuccessfullMessage');
    }
    this.FormViewShow=false;
  }

  settings: ConfirmSettings | any = {
    overlay: true,
    overlayClickToClose: true,
    showCloseButton: true,
    confirmText: 'Yes',
    declineText: 'No'
  };

  ngAfterViewInit(): void 
  {
    this.getDepartments();
    this.dtOptions = 
    {
      pagingType: 'full_numbers',
      pageLength: 8
    };
  }

  getDepartments()
  {
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.organizationService.GetDepartment( t).then(x => 
    {
      this.Depart = x;
      Object.keys(this.Depart).forEach( key => 
      {
        // tslint:disable-next-line:no-unused-expression
        this.Depart[key];
        let Departmenttemp = new Department();
        Departmenttemp = this.Depart[key];
        this.Departmentes.push(Departmenttemp);
      });
      this.dtTrigger.next();
    });
  }

  GetDepartmentByid(id) 
  {
    // Begning of the permission portion
    this.IsDataIsInViewState=false;
    // End of the permision portion
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.organizationService.GetDepartmentByid( t,id).then(x => 
    {
      this.FormViewShow=true;
      this.CanSaveButtonView=false;
      this.Depart = x;
      Object.keys(this.Depart).forEach( key => 
      {
        // tslint:disable-next-line:no-unused-expression
        this.Depart[key];
        let Departmenttemp = new Department();
        Departmenttemp = this.Depart[key];
        this.DepartmentModel.DepartmentId=Departmenttemp.DepartmentId;
        this.DepartmentModel.DepartmentCode=Departmenttemp.DepartmentCode;
        this.DepartmentModel.DepartmentName=Departmenttemp.DepartmentName;
        this.DepartmentModel.CompId=Departmenttemp.CompId;
        this.DepartmentModel.BranchId=Departmenttemp.BranchId;          
        this.DepartmentModel.FunctionalHead=Departmenttemp.FunctionalHead;
      });
      this.getBranches(this.DepartmentModel.CompId);
      // Begin for check Name duplication
      this.checkNameval= this.DepartmentModel.DepartmentName;
      this.CheckNamestatusedit=true;
      this.CheckNameExiststatus =true;
      this.Namecheck=""
      // End for check Name duplication
    });
  }

  ViewDepartmentByid(id) 
  {
    this.IsDataIsInViewState=true;
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.organizationService.GetDepartmentByid( t,id).then(x => 
    {
      this.FormViewShow=true;
      this.CanSaveButtonView=false;
      this.Depart = x;
      Object.keys(this.Depart).forEach( key => 
      {
        // tslint:disable-next-line:no-unused-expression
        this.Depart[key];
        let Departmenttemp = new Department();
        Departmenttemp = this.Depart[key];
        this.DepartmentModel.DepartmentId=Departmenttemp.DepartmentId;
        this.DepartmentModel.DepartmentCode=Departmenttemp.DepartmentCode;
        this.DepartmentModel.DepartmentName=Departmenttemp.DepartmentName;
        this.DepartmentModel.CompId=Departmenttemp.CompId;
        this.DepartmentModel.BranchId=Departmenttemp.BranchId;          
        this.DepartmentModel.FunctionalHead=Departmenttemp.FunctionalHead;
      });
      this.getBranches(this.DepartmentModel.CompId);
      // Begin for check Name duplication
      this.checkNameval= this.DepartmentModel.DepartmentName;
      this.CheckNamestatusedit=true;
      this.CheckNameExiststatus =true;
      this.Namecheck=""
      // End for check Name duplication
    });
  }

  getFunctionalHead() 
  {
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.organizationService.GetFunctionalHead( t).then(x => 
    {
      this.Depart = x;
      Object.keys(this.Depart).forEach( key => 
      {
        // tslint:disable-next-line:no-unused-expression
        this.Depart[key];
        let Departtemp = new Department();
        Departtemp = this.Depart[key];
        this.Employees.push(Departtemp);
      });     
    });      
  }

  getCompanies() 
  {
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.organizationService.GetCompany( t).then(x => 
    {
      this.company = x;
      Object.keys(this.company).forEach( key => 
      {
        // tslint:disable-next-line:no-unused-expression
        this.company[key];
        let Companytemp = new Company();
        Companytemp = this.company[key];
        this.Companies.push(Companytemp);
      });     
    });      
  }

  getBranches( companyid) 
  {
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t
    if(this.Branches.length>0)
    {
      this.Branches.splice(0, this.Branches.length);
    }
    this.organizationService.GetBranchesByComp( t,companyid).then(x => 
    {
      this.Branch = x;
      Object.keys(this.Branch).forEach( key => 
      {
        // tslint:disable-next-line:no-unused-expression
        this.Branch[key];
        let Branchtemp = new Branch();
        Branchtemp = this.Branch[key];
        this.Branches.push(Branchtemp);
      });     
    });      
  }
  
  onCompanychange(Companyid) 
  {
    this.getBranches(Companyid);
    this.DepartmentModel.BranchId=0;
  }
  
  saveDepartment() :void
  {
    this.DisableSave=false;
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.DepartmentModel.IsActive=true;
    if(this.DepartmentModel.DepartmentId > 0)
    {
      this.organizationService.UpdateDepartment(t ,this.DepartmentModel).subscribe(res=>
      {
        this.DepartmentId=res;
        this.DepartmentModel.DepartmentId=0;  
        if(this.DepartmentId>0)
        {
          localStorage.setItem('SuccessfullMessage', "Update Successfully");
          this.rout.navigateByUrl('/Organization/ManageBranch', {skipLocationChange: true}).then(()=>this.rout.navigate(["/Organization/Managedepartments"]));
        }
      });                  
    }
    else
    {
      this.organizationService.AddDepartment(t ,this.DepartmentModel).subscribe(res=>
      {
        this.DepartmentId=res;
        this.DepartmentModel.DepartmentId=0;              
        if(this.DepartmentId>0)
        {
          localStorage.setItem('SuccessfullMessage', "Save Successfully");
          this.rout.navigateByUrl('/Organization/ManageBranch', {skipLocationChange: true}).then(()=>this.rout.navigate(["/Organization/Managedepartments"]));
        }
      }); 
    }
  }

  DeleteDepartmentByid(DepartmentId : number) :void
  {
    this._confirmation.create('Confirm to Delete', 'Press Yes to Delete', this.settings)
    .subscribe((ans: ResolveEmit) => {
      if (ans.resolved == true) {

        let t =  localStorage.getItem('userToken');
          t = 'Bearer' + t;
          this.DepartmentModel.IsActive=true;
          if( DepartmentId > 0)
          {
            this.organizationService.DeleteDepartment(t ,DepartmentId).subscribe(res=>
            {
              this.DepartmentId=res;
              this.DepartmentModel.DepartmentId=0;                                  
              if(this.DepartmentId >0)
              {
                localStorage.setItem('SuccessfullMessage', "Deleted Successfully");
                this.rout.navigateByUrl('/Organization/ManageBranch', {skipLocationChange: true}).then(()=>this.rout.navigate(["/Organization/Managedepartments"]));
              }
            });                  
          }
         
      }
    })



  }

  RefreshPage()
  {
    this.rout.navigateByUrl('/Organization/ManageBranch', {skipLocationChange: true}).then(()=>this.rout.navigate(["/Organization/Managedepartments"]))
  }

  // begning of check duplicate Name
  CheckName()
  {
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    if(!this.CheckNamestatusedit)
    {
      this.organizationService.CheckDepartmentName( t, this.DepartmentModel.DepartmentName,this.DepartmentModel.BranchId).then(x => 
      {
        this.checkName = x;
        if( this.checkName.length >0)
        {
          this.CheckNameExiststatus =false;
          this.Namecheck="Exist"
        }
        else
        {
          this.CheckNameExiststatus =true;
          this.Namecheck="";
        }
     });
    }
    else
    {
      if(this.DepartmentModel.DepartmentName !=this.checkNameval )
      {
        this.organizationService.CheckDepartmentName( t, this.DepartmentModel.DepartmentName, this.DepartmentModel.BranchId).then(x => 
        {
          this.checkName = x;
          if( this.checkName.length >0)
          {
            this.CheckNameExiststatus =false;
            this.Namecheck="Exist"
          }
          else
          {
            this.CheckNameExiststatus =true;
            this.Namecheck="";
          }
        });
      }
      else
      {
        this.CheckNameExiststatus =true;
        this.Namecheck="";
      }
    }
  }
  // end of duplicate Name
  ADDClick()
  {
    this.FormViewShow=true;
    this.CanSaveButtonView=false;
  }
}
