import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from '../../shared/user.service';
import { KRAService }  from '../../shared/HR_ADMIN/KRA/KRAService.service';
import { KRAMasterModel } from '../../shared/HR_ADMIN/KRA/KRAMasterModel.Model';
import { KRAApplicationModel } from '../../shared/HR_ADMIN/KRA/KRAApplicationModel.Model';
import { Menu } from '../../shared/menu.model';
import { ModalOptions,BsModalRef, BsModalService } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-krareports',
  templateUrl: './krareports.component.html',
  styleUrls: ['./krareports.component.css']
})

export class KrareportsComponent implements OnInit 
{
  KRAMasterModel : KRAMasterModel= new KRAMasterModel();
  KRAApplicationModel : KRAApplicationModel= new KRAApplicationModel();
  EmployeeDetails: KRAApplicationModel[] = [];
  employee: any;
  KRAApplications: KRAApplicationModel[] = [];
  kraapplication: any;
  KRAApplicationSession: KRAApplicationModel[] = [];
  kraapplicationSession: any;
  RefId : any;
  MasterRefId : any;
  userClaims: any;
  Result : string;
  SuccessStatus:boolean=false;
  SuccessMessage: string ="";
  public tableWidget: any;
  selectedIndex : number;
  // Begning Permission Portion    
  SelectedMenu:Menu;
  IsDataIsInViewState : boolean=false;
  CanViewState : boolean=true;
  IsApplicationView : boolean=false;
  DisableSave : boolean=false;
  Totalwts : number=0;
  TotalRating : number=null;
  // End of the permission portion
  //For Pop up
  ShowPopup : boolean= false;
  public modalRef: BsModalRef;
  public modelopt:ModalOptions;
  NoRecordMsg : boolean;

  constructor(private KRAService: KRAService, private rout: Router, private userService: UserService, private modalService: BsModalService) 
  {  
    this.modelopt=new ModalOptions();
    this.modelopt.class='heavy_modal';
    this.modalService.config= this.modelopt;
    this.modelopt.ignoreBackdropClick=true;
  }

  ngOnInit() 
  {
    // Begning Permission Portion 
    this.SelectedMenu=this.userService.SelectedMenu;
    if(this.SelectedMenu !=undefined)
    {
      localStorage.setItem('selectedmenu',JSON.stringify(this.SelectedMenu));
      this.rout.navigate([this.SelectedMenu.RouteName]);
    }
    else
    {
      if(JSON.parse(localStorage.getItem('selectedmenu'))!=undefined)
      {
        this.SelectedMenu = JSON.parse(localStorage.getItem('selectedmenu'));
        this.rout.navigate([this.SelectedMenu.RouteName]);
      }
    }
    if(this.SelectedMenu.CanAdd)
    {
      this.IsDataIsInViewState=false;
    }
    else
    {
      this.IsDataIsInViewState=true;
    }
    if(this.SelectedMenu.CanView)
    {
      this.CanViewState=false;
    }
    else
    {
      this.CanViewState=true;
    }
    // End of the permission portion
    this.GetEmployeeDetails();
    this.GetKRAReportSession();
    this.NoRecordMsg = false;
  }

  GetEmployeeDetails()
  {
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.KRAService.GetEmployeeDetailsBYLoginId( t).then(x => 
    {
      this.employee = x;
      Object.keys(this.employee).forEach( key => 
      {
        this.employee[key];
        let KRAApplicationtemp = new KRAApplicationModel();
        KRAApplicationtemp = this.employee[key];
        this.KRAApplicationModel.EmployeeId=KRAApplicationtemp.EmployeeId;
        this.KRAApplicationModel.EmployeeCode=KRAApplicationtemp.EmployeeCode;
        this.KRAApplicationModel.EmployeeName=KRAApplicationtemp.EmployeeName;
        this.KRAApplicationModel.EmployeeEmailId=KRAApplicationtemp.EmployeeEmailId;
        this.KRAApplicationModel.DesignationName=KRAApplicationtemp.DesignationName;
        this.KRAApplicationModel.RHId=KRAApplicationtemp.RHId;
        this.KRAApplicationModel.RHCode=KRAApplicationtemp.RHCode;
        this.KRAApplicationModel.RHName=KRAApplicationtemp.RHName;
        this.KRAApplicationModel.RHEmailId=KRAApplicationtemp.RHEmailId;
        this.KRAApplicationModel.FHId=KRAApplicationtemp.FHId;
        this.KRAApplicationModel.FHCode=KRAApplicationtemp.FHCode;
        this.KRAApplicationModel.FHName=KRAApplicationtemp.FHName;
        this.KRAApplicationModel.FHEmailId=KRAApplicationtemp.FHEmailId;
        this.KRAApplicationModel.CompanyId=KRAApplicationtemp.CompanyId;
        this.KRAApplicationModel.CompanyName=KRAApplicationtemp.CompanyName;
        this.EmployeeDetails.push(KRAApplicationtemp);
      });
    });
  }
  
  GetKRAReportSession() 
  { 
    this.KRAApplicationSession=[];
    let t = localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.KRAService.GetKRAReportsSessionDisplayByUserId( t).then(x => 
    {
      this.NoRecordMsg = false;
      this.RefId = x;
      if(this.RefId.length > 0)
      {
        this.KRAApplicationSession=[];
      }
      Object.keys(this.RefId).forEach( key => 
      {
        // tslint:disable-next-line:no-unused-expression
        this.RefId[key];
        let KRAApplicationtemp = new KRAApplicationModel();
        KRAApplicationtemp = this.RefId[key];
        this.KRAApplicationSession.push(KRAApplicationtemp);
      });
      if(this.KRAApplicationSession.length == 0)
      {
        this.NoRecordMsg=true;
      }
    });
  }

  GetKRAReports(MasterRefId,template) 
  { 
    this.KRAApplications=[];
    // Begning of the permission portion
    this.IsDataIsInViewState=false;
    // End of the permision portion
    this.KRAApplicationModel.IsActive=false;
    let t = localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.KRAService.GetKRAReportsByUserId( t,MasterRefId).then(x => 
    {
      if(this.ShowPopup==true)
      {
        this.modalRef.hide();
      }
      this.KRAApplicationModel.TotalWateges=0;
      this.Totalwts=0;
      this.modalRef = this.modalService.show(template,this.modelopt);
      this.ShowPopup=true;
      this.RefId = x;
      if(this.RefId.length > 0)
      {
        this.KRAApplications=[];
      }
      Object.keys(this.RefId).forEach( key => 
      {
        // tslint:disable-next-line:no-unused-expression
        this.RefId[key];
        let KRAApplicationtemp = new KRAApplicationModel();
        KRAApplicationtemp = this.RefId[key];

        this.Totalwts=this.Totalwts + KRAApplicationtemp.Weighted;
        this.KRAApplicationModel.FinalSelfRating=KRAApplicationtemp.FinalSelfRating;
        this.KRAApplicationModel.FinalRHRating=KRAApplicationtemp.FinalRHRating;
        this.KRAApplicationModel.FinalFHRating=KRAApplicationtemp.FinalFHRating;
        if(KRAApplicationtemp.SelfRating==0)
        {
          KRAApplicationtemp.SelfRating=null;
        }
        this.KRAApplications.push(KRAApplicationtemp);
      });
      this.KRAApplicationModel.TotalWateges=this.Totalwts;
    });
  }
}