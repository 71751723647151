import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from '../../shared/user.service';
import { Menu } from '../../shared/menu.model';
import { EmployeeDetail } from '../../shared/HR_ADMIN/Employee/EmployeeDetail.Model';
import { EmployeeMasterService } from '../../shared/HR_ADMIN/Employee/EmployeeMaster.Service';
import { ModalOptions,BsModalRef, BsModalService  } from 'ngx-bootstrap/modal'

@Component({
  selector: 'app-all-employee-perosnal-info-application',
  templateUrl: './all-employee-perosnal-info-application.component.html',
  styleUrls: ['./all-employee-perosnal-info-application.component.css']
})
export class AllEmployeePerosnalInfoApplicationComponent implements OnInit 
{
  Employees: EmployeeDetail[] = [];
  employee: any;
  userClaims: any;
  ListApplication: EmployeeDetail[] = [];
  SubMenues : Menu[]=[];
  Mod : any;
  Approver: any;
  Result : string;
  SuccessStatus:boolean=false;
  SuccessMessage: string ="";
  public tableWidget: any;
  RefId : any;
  // Begning Permission Portion    
  SelectedMenu:Menu;
  IsDataIsInViewState : boolean=false;
  CanViewState : boolean=false;  
  CanApproveState : boolean=false; 
  GridShow : boolean=false; 
  DisableSave : boolean=false;
  // End of the permission portion
  // begin for Validation
  validdate : boolean= false;
  // end of validation
  //For Pop up
  ApprovedMsg : boolean= false;
  RejectedMsg : boolean= false;
  ShowPopup : boolean= false;
  public modalRef: BsModalRef;
  public modelopt:ModalOptions;
  NoRecordMsg : boolean;
  employeeEducationUndefine : string ="";
  employeeExpriencUndefine : string ="";
  employeeNomineeDetailsUndefine : string ="";
  employeeFamilyDetailsUndefine : string ="";

  constructor(public EmployeeMasterService: EmployeeMasterService, private rout: Router, private userService: UserService,private modalService: BsModalService)
  { 
    this.modelopt=new ModalOptions();
    this.modelopt.class='heavy_modal';
    this.modalService.config= this.modelopt;
    this.modelopt.ignoreBackdropClick=true;
  }

  ngOnInit()
  {
   //Begin Permission Portion
   this.SelectedMenu=this.userService.SelectedMenu;
   if(this.SelectedMenu !=undefined)
   {
     localStorage.setItem('selectedmenu',JSON.stringify(this.SelectedMenu));
     this.rout.navigate([this.SelectedMenu.RouteName]);
   }
   else
   {
     if(JSON.parse(localStorage.getItem('selectedmenu'))!=undefined)
     {
       this.SelectedMenu = JSON.parse(localStorage.getItem('selectedmenu'));
       this.rout.navigate([this.SelectedMenu.RouteName]);
     }
   }
   if(this.SelectedMenu.CanAdd)
   {
     this.IsDataIsInViewState=false;
   }
   else
   {
     this.IsDataIsInViewState=true;
   }
   if(this.SelectedMenu.CanView)
   {
     this.CanViewState=false;
   }
   else
   {
     this.CanViewState=true;
   }
   if(this.SelectedMenu.CanApprove)
   {
     this.CanApproveState=false;
   }
   else
   {
     this.CanApproveState=true;
   }
   //End of the peremission portion
    this.DisableSave=true;
    this.GetAllApplication();
    this.ApprovedMsg=false;
    this.RejectedMsg=false;
    this.ShowPopup=false;
    this.NoRecordMsg= false;
  }

  GetAllApplication()
  {
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.EmployeeMasterService.GetEmployeeModificationApplicationGV( t).then(x => 
    {
      this.NoRecordMsg= false;
      this.Approver = x;
      Object.keys(this.Approver).forEach( key => 
      {
        this.Approver[key];
        let Approve = new EmployeeDetail();
        Approve= this.Approver[key];
        this.ListApplication.push(Approve);
      });
      if(this.ListApplication.length==0)
      {
        this.NoRecordMsg= true;
      }
    });
  }

  viewAppliedApplicationByRefId(RefIdApplication,PersonalDetails,AddressDetails, OtherDetails, QualificationDetails, ExperienceDetails, FamilyDetails,template)
  {
    this.DisableSave=true;
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.EmployeeMasterService.GetEmployeePersonalDetailsForModificationByRefId( t,RefIdApplication,PersonalDetails,AddressDetails, OtherDetails, QualificationDetails, ExperienceDetails, FamilyDetails).then(x => 
    {
      if(this.ShowPopup==true)
      {
        this.modalRef.hide();
      }
      this.EmployeeMasterService.SharedEmployees.RefIdApplication=RefIdApplication;
      this.modalRef = this.modalService.show(template,this.modelopt);
      this.ShowPopup=true;
      this.ApprovedMsg=false;
      this.RejectedMsg=false;
      this.employee = x;
      Object.keys(this.employee).forEach( key => 
      {
        this.employee[key];
        let employeetemp = new EmployeeDetail();
        employeetemp = this.employee[key];
        this.EmployeeMasterService.SharedEmployees=this.employee;
        if( this.EmployeeMasterService.SharedEmployees.EmployeeEducation.length == 0)
        {
          this.employeeEducationUndefine ='notdefinedval';
        }
        if( this.EmployeeMasterService.SharedEmployees.EmployeeExprienceModel.length == 0)
        {
          this.employeeExpriencUndefine ='notdefinedval';
        }
        if(this.EmployeeMasterService.SharedEmployees.EmployeeNomineeDetailModel.length==0)
        {
          this.employeeNomineeDetailsUndefine ='notdefinedval';
        }
        if(this.EmployeeMasterService.SharedEmployees.EmployeeFamilyDetailsModel.length ==0)
        {
          this.employeeFamilyDetailsUndefine='notdefinedval';
        }
      });
    });
  }

  PersonalInfoApproved(template) :void
  {
    this.DisableSave=false;
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    if(this.EmployeeMasterService.SharedEmployees.RefIdApplication > 0)
    {
      this.EmployeeMasterService.SharedEmployees.IsApproved=true;
      this.EmployeeMasterService.SharedEmployees.IsRejected=false;
      this.EmployeeMasterService.EmployeePersonalDetailsApproval(t ,this.EmployeeMasterService.SharedEmployees).subscribe(res=>
      {
        this.RefId=res;
        this.EmployeeMasterService.SharedEmployees.RefIdApplication=0;  
        if(this.RefId > 0)
        {
          this.EmployeeMasterService.SharedEmployees.Remarks="";
          this.modalRef.hide();
          this.ApprovedMsg=true;
          this.RejectedMsg=false;
          this.ShowPopup=false;
          this.modalRef = this.modalService.show(template);
          this.ListApplication=[];
          this.GetAllApplication();
          this.DisableSave=true;
        }
      });                  
    }
  }

  PersonalInfoRejected(template) :void
  {
    this.DisableSave=false;
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    if(this.EmployeeMasterService.SharedEmployees.RefIdApplication > 0)
    {
      this.EmployeeMasterService.SharedEmployees.IsApproved=false;
      this.EmployeeMasterService.SharedEmployees.IsRejected=true;
      this.EmployeeMasterService.EmployeePersonalDetailsApproval(t ,this.EmployeeMasterService.SharedEmployees).subscribe(res=>
      {
        this.RefId=res;
        this.EmployeeMasterService.SharedEmployees.RefIdApplication=0;  
        if(this.RefId>0)
        {
          this.EmployeeMasterService.SharedEmployees.Remarks="";
          this.modalRef.hide();
          this.ApprovedMsg=false;
          this.RejectedMsg=true;
          this.ShowPopup=false;
          this.modalRef = this.modalService.show(template);
          this.ListApplication=[];
          this.GetAllApplication();
          this.DisableSave=true;
        }
      });                  
    }
  }
}