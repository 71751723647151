<div class="body-content-area tabcontent" id="link1">
  <div class="row">
    <div class="col-lg-9">
      <div class="row">
        <div class="col-lg-1 low-padding text-center">
          <!-- <img *ngIf="this.SelectedMenu.Image!=null" src={{this.SelectedMenu.Image}} class="body-icon" alt="Icon"/> -->
        </div>
        <div class="col-lg-10 clear-padding">
          <div>
            <span class="page-title">Employee Leave Balance</span>
            <small class="page-description"></small>
          </div>
        </div>
      </div>
    </div>
  </div>
  
  <fieldset class="body-form-control" > 
    <legend class="legend--">Leave Balance Details</legend>
    <div class="row">
      <div class="col-lg-4">
        <div class="col-body-border-right">
          <div class="form-group row">
            <label class="col-sm-4 col-form-label clear-padding-right">Emp code</label>
            <div class="col-sm-7 clear-left-padding">
              :&nbsp;&nbsp;{{this.EmployeeLeaveBalanceModel.EmployeeCode}} 
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-4">
        <div class="form-group row">
          <label class="col-sm-4 col-form-label clear-padding-right">Employee Name</label>
          <div class="col-sm-7 clear-left-padding">
            :&nbsp;&nbsp;{{this.EmployeeLeaveBalanceModel.EmployeeName}}
          </div>
        </div>
      </div>
    </div>
    
      <form #EmployeeAttendanceDailyDetails="ngForm">
        <div lass="row" >
          <table id="tblEmployeeAttendanceDailyDetails" class="table table-striped table-bordered text-left table-new row-border hover">
            <thead class="thead-blue text-center">
              <tr>
                <th>Sl.No.</th>
                <th>Leave Type</th>
                <th>Leave Entitlement</th>
                <th>Leave Taken During the Year</th>
                <th>Opening Balance as of Today</th>
                <th>Leave Applied or pending for Approval</th>
                <th>Available Balance as of Today</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let EmpLeaveBal of EmployeeLeaveBalance, let i = index">
                <td scope="row">{{i+1}}</td>
                <td>{{EmpLeaveBal.LeaveTypeName}}</td>
                <td>{{EmpLeaveBal.NoOfLeave}}</td>
                <td>{{EmpLeaveBal.LeaveTaken}}</td>
                <td>{{EmpLeaveBal.BalanceLeave}}</td>
                <td>{{EmpLeaveBal.LeaveApply}}</td>
                <td>{{EmpLeaveBal.BalanceLeaveAfterApply}}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </form>
  </fieldset>
</div>