import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ReportType } from '../../../shared/HR_ADMIN/Report/ReportType'
import { UserService } from '../../../shared/user.service';
import { EmployeePortalService } from '../../../shared/HR_ADMIN/EmployeePortal/EmployeePortalService.service';
import { EmployeeDetail } from '../../../shared/HR_ADMIN/Employee/EmployeeDetail.Model';
import { EmployeeAttendanceModel } from '../../../shared/HR_ADMIN/EmployeePortal/EmployeeAttendanceModel.Model';
import { OrganizationService } from '../../../shared/Organization/organization.service';
import { Company } from '../../../shared/Organization/Company.Model';
import { Department } from '../../../shared/Organization/Department.Model';
import { Branch } from '../../../shared/Organization/Branch.Model';
import { CommonMenuHandelling } from '../../../shared/Menu/CommonMenuHandelling';
import { DDTRLeaveReportModel } from '../../../shared/HR_ADMIN/EmployeePortal/DDTRLeaveReportModel.Model'
//End of notification service
import { Menu } from '../../../shared/menu.model';

@Component({
  selector: 'app-dd-tour-leave-reports',
  templateUrl: './dd-tour-leave-reports.component.html',
  styleUrls: ['./dd-tour-leave-reports.component.css']
})
export class DdTourLeaveReportsComponent implements OnInit 
{
  EmployeeAttendanceModel: EmployeeAttendanceModel = new EmployeeAttendanceModel();
  EmployeeDetailModel: EmployeeDetail = new EmployeeDetail();
  userClaims: any;
  Employees: EmployeeDetail[] = [];
  employee: any;
  //Reports Start
  ReportTypeList: ReportType[] = [];
  ReportExecl: ReportType;
  ReportPdf: ReportType;
  //Reports End
  //
  empcode: string;
  EmployeeAttendance: EmployeeAttendanceModel[] = [];
  Attendance: any;
  SubMenues: Menu[] = [];
  Mod: any;

  Result: string;
  SuccessStatus: boolean = false;
  SuccessMessage: string = "";
  public tableWidget: any;
  EmployeeId: any;
  selectedIndex: number;
  // Begning Permission Portion    
  SelectedMenu: Menu;
  IsDataIsInViewState: boolean = false;
  CanViewState: boolean = true;
  DisableSave: boolean = false;
  // End of the permission portion

  company: any;
  Companies: Company[] = [];
  Branch: any;
  Branches: Branch[] = [];
  Departments: Department[] = [];

  Department: any;
  DepartmentModel: Department = new Department();

  DD : any;
  DDTRLeaveReportModel:DDTRLeaveReportModel[]=[];

  // begin for Validation
  validdate: boolean = false;
  // end of validation
  ReportData: any;

  constructor(private CommonMenuHandelling:CommonMenuHandelling,private rout: Router, private userService: UserService, private EmployeePortalService: EmployeePortalService, private organizationService: OrganizationService) { }

    ngOnInit() 
    {
      // Begning Permission Portion 
      this.SelectedMenu=this.userService.SelectedMenu;
      if(this.SelectedMenu !=undefined)
      {
        localStorage.setItem('selectedmenu',JSON.stringify(this.SelectedMenu));
        this.rout.navigate([this.SelectedMenu.RouteName]);
      }
      else
      {
        if(JSON.parse(localStorage.getItem('selectedmenu'))!=undefined)
        {
          this.SelectedMenu = JSON.parse(localStorage.getItem('selectedmenu'));
          this.rout.navigate([this.SelectedMenu.RouteName]);
        }
      }
      if(this.SelectedMenu.CanAdd)
      {
        this.IsDataIsInViewState=false;
      }
      else
      {
        this.IsDataIsInViewState=true;
      }
      if(this.SelectedMenu.CanView)
      {
        this.CanViewState=false;
      }
      else
      {
        this.CanViewState=true;
      }
      // End of the permission portion
      this.DisableSave = true;
      if (localStorage.getItem('SuccessfullMessage')) 
      {
        this.SuccessStatus = true;
        this.SuccessMessage = localStorage.getItem('SuccessfullMessage')
        localStorage.removeItem('SuccessfullMessage');
      }
      this.getCompanies();
      this.EmployeeAttendanceModel.CompId = 0;
      this.EmployeeAttendanceModel.BranchId = 0;
    }

    getCompanies() 
    {
      this.Companies=[];
      let t = localStorage.getItem('userToken');
      t = 'Bearer' + t;
      this.organizationService.GetCompany(t).then(x => 
      {
        this.company = x;
        Object.keys(this.company).forEach(key => 
        {
        // tslint:disable-next-line:no-unused-expression
        this.company[key];
        let Companytemp = new Company();
        Companytemp = this.company[key];
        this.Companies.push(Companytemp);
      });
    });
  }

  onChangeCompany(Companyid) 
  {
    if (Companyid > 0) 
    {
      this.getBranches(Companyid);
      this.EmployeeAttendanceModel.BranchId = 0;
    }
    else 
    {
      this.EmployeeAttendanceModel.BranchId = 0;
      this.getBranches(0);
    }
  }

  getBranches(companyid) 
  {
    this.Branches=[];
    let t = localStorage.getItem('userToken');
    t = 'Bearer' + t
    if (this.Branches.length > 0) 
    {
      this.Branches.splice(0, this.Branches.length);
    }
    this.organizationService.GetBranchesByComp(t, companyid).then(x => 
    {
      this.Branch = x;
      Object.keys(this.Branch).forEach(key => 
      {
        // tslint:disable-next-line:no-unused-expression
        this.Branch[key];
        let Branchtemp = new Branch();
        Branchtemp = this.Branch[key];
        this.Branches.push(Branchtemp);
      });
    });
  }


  submitReport() 
  {
    if (this.EmployeeAttendanceModel.FromDate != null && this.EmployeeAttendanceModel.ToDate != null) 
    {
      if (this.EmployeeAttendanceModel.ToDate >= this.EmployeeAttendanceModel.FromDate) 
      {
        //this.empcode=this.EmployeeAttendanceModel.EmployeeId.toString();
        this.GetEmployeeLeaveDDTourRegReport( this.EmployeeAttendanceModel.FromDate, this.EmployeeAttendanceModel.ToDate, this.EmployeeAttendanceModel.CompId, this.EmployeeAttendanceModel.BranchId);
      }
    }
  }

  FromDateChanged(date) 
  {
    this.EmployeeAttendanceModel.FromDate = date;
  }

  ToDateChanged(date) 
  {
    this.EmployeeAttendanceModel.ToDate = date;
  }

  GetEmployeeAttendanceDetailsReports( FromDate, ToDate, companyid, branchid) 
  {
    this.EmployeeAttendance = [];
    let t = localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.EmployeePortalService.GetEmployeeDD_Tour_Leave_Reports(t,  FromDate, ToDate, companyid, branchid);
  }

  RefreshPage()
  {
    this.rout.navigateByUrl('/AttendenceReports/MonthlyAttendenceReport', { skipLocationChange: true }).then(() => this.rout.navigate(["/AttendenceReports/DD_Tour_Leave_Report"]));
  }

  GetEmployeeLeaveDDTourRegReport( FromDate, ToDate, companyid, branchid)
  {
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.EmployeePortalService.GetEmployeeLeaveDDTourRegReport( t,  FromDate, ToDate, companyid, branchid).then(x => 
    {
      this.DDTRLeaveReportModel=[];
      this.DD = x;
      Object.keys(this.DD).forEach( key => 
      {
        this.DD[key];
        let Holidaystemp = new DDTRLeaveReportModel();
        Holidaystemp = this.DD[key];
        this.DDTRLeaveReportModel.push(Holidaystemp);
      });
      this.export();
    });
  }

  export() 
  {
    this.CommonMenuHandelling.exportExcel(this.DDTRLeaveReportModel, 'DD Tour Leave Regularation');
  }
}
