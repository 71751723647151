<div class="body-content-area tabcontent" id="link1">
    <div class="row">
        <div class="col-lg-9">
            <div class="row">
                <div class="col-lg-1 low-padding text-center">
                    <!-- <img *ngIf="this.SelectedMenu.Image!=null" src={{this.SelectedMenu.Image}} class="body-icon" alt="Icon"/> -->
                </div>
                <div class="col-lg-10 clear-padding">
                    <span class="page-title">Manage User Master</span>
                    <small class="page-description"></small>
                </div>
            </div>
        </div>
    </div>
  
    <div *ngIf="!(this.CanViewState)">
        <div style=" text-align: right;">
            <button (click)="ADDClick(template)" type="button" class="btn btn-primary Add-button">ADD New</button> 
        </div>
        <fieldset class="body-form-control">
            <legend class="legend--">User Master Details</legend>
            <table id="tblUser" datatable [dtOptions]="dtOptions" *ngIf="this.UserMasters.length > 0" class="table table-striped table-bordered text-center table-new row-border hover">
                <thead class="thead-blue text-center" style="width: 100%;">
                    <tr>
                        <th style=" width: 6%;">Sl No.</th>
                        <th style=" width: 7%;">Code</th>
                        <th style=" width: 17%;" class="text-left">Name</th>
                        <th style=" width: 20%;" class="text-left">Companies</th>
                        <th style=" width: 22%;" class="text-left">Branches</th>
                        <th style=" width: 8%;" class="text-left">User Id</th>
                        <th style=" width: 10%;" class="text-left">Roles</th>
                        <th style=" width: 10%;">Action</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let user of UserMasters, let i = index">
                        <td scope="row">{{i+1}}</td>
                        <td>{{user.EmployeeCode}}</td>
                        <td class="text-left">{{user.EmployeeName}}</td>
                        <td class="text-left">{{user.CompName}}</td>
                        <td class="text-left">{{user.BranchName}}</td>
                        <td class="text-left">{{user.UserId}}</td>
                        <td class="text-left">{{user.RoleName}}</td>
                        <td>
                            <button *ngIf="this.SelectedMenu.CanEdit" type="button" (click)="GetUserMasterById(user.Id,template)" class="table-edit-btn"><i class="fas fa-edit"></i></button> 
                            <button *ngIf="this.SelectedMenu.CanDelete" type="button" (click)="DeleteUserMasterById(user.Id,template)" class="table-remove-btn"><i class="fas fa-trash-alt"></i></button>
                            <button *ngIf="this.SelectedMenu.CanView" type="button" (click)="ViewUserMasterById(user.Id,template)" class="table-view-button" type="button" ><i class="fas fa-eye"></i></button>
                        </td>
                    </tr>
                </tbody>
                <!-- <tfoot class="thead-blue text-center">
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                </tfoot> -->
            </table>
        </fieldset>

        <div style=" box-align: center; width: 1500px;" >
            <ng-template #template>
                <div class="modal-header" style="background: #2a9bd7; max-width: 1500px;"> 
                <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()" style="color:#fff;">
                    <span aria-hidden="true">&times;</span>
                </button>
                </div>
                <div style="text-align: center; max-width: 1500px;">
                <div *ngIf="DeleteMsg" style="color: #dc3545; text-align: center;font-size: 15px; font-weight: bold;">User Deleted Successfully.</div>
                <div *ngIf="UpdateMsg" style="color: #dc3545; text-align: center;font-size: 15px; font-weight: bold;">Modified User Details Successfully.</div>
                <div *ngIf="SaveMsg" style="color: #dc3545; text-align: center;font-size: 15px; font-weight: bold;">User Created Successfully.</div>
                <div style=" width: 100%;" *ngIf="ShowPopup">
                    <fieldset class="body-form-control">
                        <legend class="legend--">User Master Creation</legend>
                        <form #UserMaster="ngForm" (ngSubmit)="SaveUserMaster(template)">
                            <div class="row" style=" width: 100%;">
                                <div class="col-lg-4" style=" width: 34%;">
                                    <div class="col-body-border-right" style=" width: 100%;">
                                    <div class="form-group row" style=" width: 100%;">
                                        <label class="col-sm-4 col-form-label clear-padding-right">Employee</label>
                                        <div *ngIf="!(this.EmpDDLView)" class="col-sm-7 clear-left-padding">
                                        <select [(ngModel)]="UserModel.EmployeeId" tabindex="1" id ="EmployeeId" class="form-control" name="EmployeeId" #EmployeeId="ngModel" >
                                            <option [value]="0" [disabled]=true >Select Employee</option>
                                            <option *ngFor="let emp of EmployeeDetails" [value]="emp.EmployeeId">{{emp.EmployeeCode}} - {{emp.EmployeeName}}</option>
                                        </select>
                                        </div>
                                        <div *ngIf="(this.EmpDDLView)" class="col-sm-7 clear-left-padding">
                                            :&nbsp;&nbsp;{{UserModel.EmployeeName}}
                                        </div>
                                        <div class="required-validation" *ngIf="UserModel.EmployeeId==0">Employee is required</div>
                                    </div>
                
                                    <div class="form-group row" *ngIf="UserModel.RoleId==2 || UserModel.RoleId==3">
                                        <label class="col-sm-4 col-form-label clear-padding-right">Company List</label>
                                        <div class="col-lg-11">
                                            <angular2-multiselect class="form-control" [data]="CompanyList" [(ngModel)]="CompanyselectedItems" name="CompanyselectedItems" 
                                            [settings]="CompanySettings" (onSelect)="onCompanyItemSelect($event)" (onDeSelect)="OnCompanyItemDeSelect($event)" (onSelectAll)="onCompanySelectAll($event)" (onDeSelectAll)="onCompanyDeSelectAll($event)"
                                            (ngModelChange)="onCompanychange($event)">
                                            </angular2-multiselect>
                                        </div>
                                    </div>
                                    </div>
                                </div>
                                
                                <div class="col-lg-4" style=" width: 33%;">
                                    <div class="col-body-border-right">
                                        <div class="form-group row">
                                            <label class="col-sm-4 col-form-label clear-padding-right">Roles Name</label>
                                            <div class="col-sm-7 clear-left-padding">
                                                <select [(ngModel)]="UserModel.RoleId" tabindex="2" (ngModelChange)="onRoleChange()" id ="RoleId" class="form-control"  name="RoleId" #RoleId="ngModel" >
                                                <option [value]="0" [disabled]=true >Select Role</option>
                                                    <option *ngFor="let Role of RoleMaster" [value]="Role.RoleId">{{Role.RoleName}}</option>
                                                </select>
                                            </div>
                                            <div class="required-validation" *ngIf="UserModel.RoleId==0">Role is required</div>
                                        </div>
                                        <div class="form-group row" *ngIf="UserModel.RoleId==2 || UserModel.RoleId==3">
                                            <label  class="col-sm-4 col-form-label clear-padding-right">Branch List</label>
                                            <div class="col-lg-11">
                                                <angular2-multiselect tabindex="5" class="form-control" [data]="BranchList" [(ngModel)]="BranchselectedItems" name="BranchselectedItems" 
                                                    [settings]="BranchSettings" (onSelect)="onItemSelect($event)" (onDeSelect)="OnItemDeSelect($event)" (onSelectAll)="onSelectAll($event)"
                                                    (onDeSelectAll)="onDeSelectAll($event)">
                                                </angular2-multiselect>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                    
                                <div class="col-lg-4" style=" width: 33%;">
                                    <div *ngIf="(this.EmpDDLView)" class="form-group row">
                                        <label  class="col-sm-4 col-form-label clear-padding-right">User Id</label>
                                        <div class="col-sm-7 clear-left-padding">
                                            :&nbsp;&nbsp;{{UserModel.UserId}}
                                        </div>
                                    </div>
                                    <div *ngIf="(this.EmpDDLView)" class="form-group row">
                                        <label  class="col-sm-4 col-form-label clear-padding-right">Password</label>
                                        <div class="col-sm-7 clear-left-padding">
                                            :&nbsp;&nbsp;{{UserModel.Password}}
                                        </div>
                                    </div>
                                    <div class="form-group row">
                                    <label class="col-sm-6 col-form-label text-center clear-padding-right" *ngIf="!(this.IsDataIsInViewState)">
                                        <button type="submit" tabindex="6" class="btn btn-primary save-button" [disabled]="!(UserMaster.valid && this.UserModel.RoleId > 0 && this.DisableSave==true)">Save</button>  
                                    </label>
                                    <div class="col-sm-6 col-form-label text-center">       
                                        <!-- <button type="button" tabindex="7" class="btn btn-danger cancle-button" (click)="RefreshPage()" >Cancel</button> -->
                                    </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </fieldset>
                </div>
                <button type="button" aria-label="Close" (click)="modalRef.hide()" style="background: #2a9bd7; outline: 0px none; border: 0px none; color: #fff; padding: 5px 10px; border-radius: 3px;">
                    <span>Close</span>
                </button>             
                </div>
            </ng-template>
        </div>
    </div>
</div> 