import { INgxMyDpOptions, IMyDateModel } from 'ngx-mydatepicker';
import { Component, OnInit, TemplateRef } from '@angular/core';
import { Router } from '@angular/router';
import { EmployeeMasterService }  from '../../../shared/HR_ADMIN/Employee/EmployeeMaster.Service';
import { EmployeeDetail } from '../../../shared/HR_ADMIN/Employee/EmployeeDetail.Model';
import { ReplaySubject } from "rxjs/ReplaySubject";
//Begin Organisation & Levels(Model & Service)
import { OrganizationService }  from '../../../shared/Organization/organization.service';
import { LocationService }  from '../../../shared/HR_ADMIN/Location/location.service';
import { LevelService }  from '../../../shared/HR_ADMIN/Lavels_Grades/Levels.service';
import { AttendanceService }  from '../../../shared/HR_ADMIN/Attendance/AttendanceService.service';
import { Company } from '../../../shared/Organization/Company.Model';
import { Branch } from  '../../../shared/Organization/Branch.Model';
import { Department } from  '../../../shared/Organization/Department.Model';
import { DecisionMaking } from '../../../shared/HR_ADMIN/Lavels_Grades/DecisionMaking.Model';
import { Level } from '../../../shared/HR_ADMIN/Lavels_Grades/Level.Model';
import { Grade } from '../../../shared/HR_ADMIN/Lavels_Grades/Grade.Model';
import { Designation } from '../../../shared/HR_ADMIN/Lavels_Grades/Designation.Model';
import { ModelGander } from '../../../shared/HR_ADMIN/Employee/ModelGander';
import { ModelMarital } from '../../../shared/HR_ADMIN/Employee/ModelMarital';
import { Languages } from '../../../shared/HR_ADMIN/Employee/Language';
import { Religion } from '../../../shared/HR_ADMIN/Employee/Religion';
import { WorkingGroupHours }  from '../../../shared/HR_ADMIN/Attendance/WorkingGroupHours.model';
import { PtaxSlabModel } from '../../../shared/HR_ADMIN/Salary/PtaxSlabModel'
import { EPFESIModel } from '../../../shared/HR_ADMIN/Admin/EPFESIModel.Model'
import { OfferStandardSalary }  from '../../../shared/HR_ADMIN/Salary/OfferStandardSalary';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { SalaryMasterService }  from '../../../shared/HR_ADMIN/Salary/SalaryMaster.service';
import { State } from '../../../shared/Organization/State.Model';
import { RegionForHR } from  '../../../shared/Organization/RegionForHRModel.Model';
//End Organisation & Levels(Model & Service)
//Begin For Notification service
 import { NotificationsService} from '../../../shared/notifications.service';
//End of notification service

@Component({
  selector: 'app-employee-personal-details',
  templateUrl: './employee-personal-details.component.html',
  styleUrls: ['./employee-personal-details.component.css']
})

export class EmployeePersonalDetailsComponent implements OnInit 
{
  EmployeeModel : EmployeeDetail= new EmployeeDetail();
  OfferedSalaryTemp : OfferStandardSalary = new OfferStandardSalary();
  //Slary Calculation
  //for popup
  public modalRef: BsModalRef;
  OfferedSalary: OfferStandardSalary[] = [];
  Offer : any;
  //SalaryComponentRules : SalaryRule[] =[];
  //for popup
  ComponentTotal : number;
  ComponentSubTotal : number;
  Longtermbenefits : number;
  TotalOffer :any;
  tempPtaxSlabModel : PtaxSlabModel = new PtaxSlabModel();
  tempPtaxSlabModels : any;
  
  tempEPFESIModel : EPFESIModel = new EPFESIModel();
  tempEPFESIModels : any;
  
  PFDed :number=0;
  PFCont :number=0;
  ESIDed :number=0;
  ESICont :number=0;
  P_Tax : number = 0;
  MinBasicSalary : number=0;
  PFAmount : number;
  
  BranchStateId: number=0;

  States: State[] = [];
  State: any;
  //Functional Head && Reporting Head
  FunctionalHeads:EmployeeDetail[]=[];
  FunctionalHead: any;
  ReportingHeads:EmployeeDetail[]=[];
  ReportingHead: any;
  //Organisation
  Companies : Company[]=[];
  Company : any;

  Ganders : ModelGander[]=[];
  Gander : any;

  Maritials : ModelMarital[]=[];
  Marit : any;

  WorkingGroupHour: WorkingGroupHours[] = [];
  workinggrouphour:any;

  Branches: Branch[] = [];
  Branch: any;
  Departments: Department[] = [];
  Department: any;
  
  Languages : Languages[] = [];
  Lang : any;

  Religions : Religion[] =[];
  Reli : any;
  imageUrl: string="";
  //Grade & Level
  DecisionMakings : DecisionMaking[]=[];
  DecisionMaking : any;
  Levels: Level[] = [];
  Level: any;
  Grades: Grade[] = [];
  Grade: any;
  Designations : Designation[]=[];
  Designation : any;
  // Marital & Gender
  filetoupload: File=null;
  EmployeeCodecheck : string;

  EmployeeId:number;
  Tabindex:number=0
  myOptions: INgxMyDpOptions = 
  {
    // other options...
    dateFormat: 'dd.mm.yyyy',
  };

  //Begning Code checking
  IsEmpCodeStatus : Boolean=false;
  status :any;
  IsEmpCodeShow : Boolean=false;
  SuccessStatus : boolean=false;
  DisableSave : boolean=false;
  DisableState : boolean=false;
  //end of code checking
  //RegionForHR
  RegionForHR : RegionForHR[];
  RegionHR : any;

  WorkingGroupShow : boolean=false;
  AnniversaryDateShow : boolean= false;

  constructor(public EmployeeMasterService: EmployeeMasterService,private organizationService: OrganizationService,private AttendanceService:AttendanceService,private LevelService: LevelService, private rout: Router,
    private LocationService: LocationService,private modalService: BsModalService, private EmpSalary: SalaryMasterService,private notificationsService: NotificationsService)
  {
  }
  SuccessMessage:string;

  ngOnInit() 
  {
    if( this.EmployeeMasterService.SaveOrUpdate==2)
    {
      localStorage.setItem('SuccessfullMessage', "Update Successfully");
      this.EmployeeMasterService.SaveOrUpdate=0;
    }
    if( this.EmployeeMasterService.SaveOrUpdate==1)
    {
      localStorage.setItem('SuccessfullMessage', "Save Successfully");
      this.EmployeeMasterService.SaveOrUpdate=0;
    }
    if(localStorage.getItem('SuccessfullMessage'))
    {
      this.SuccessStatus=true;
      this.SuccessMessage =localStorage.getItem('SuccessfullMessage')
      localStorage.removeItem('SuccessfullMessage');
    } 
    this.getCompanies();
    this.getDecisionMaking();
    this.getGenders();
    this.getMaritalStatus();
    this.getReligion();
    this.getLanguage();
    this.GetFunctionalHeads();
    this.GetReportingHeads();
    this.GetEpfEsi();
    this.getStates();
    this.GetPayZone();
    if(this.EmployeeMasterService.SharedEmployees.EmployeeId == 0)
    {
      this.getWorkingHour();  
      this.EmployeeMasterService.SharedEmployees.WorkingGroupId = 0;
      this.EmployeeMasterService.SharedEmployees.VerificationStatusId = 0;
      this.EmployeeMasterService.SharedEmployees.FirstName='';
      this.EmployeeMasterService.SharedEmployees.EmailId='';
      this.EmployeeMasterService.SharedEmployees.ContactNumber=null;
      this.IsEmpCodeShow=false;
      if(this.EmployeeMasterService.SharedEmployees.OfferId > 0 &&this.EmployeeMasterService.SharedEmployees.CompanyId >0 && this.EmployeeMasterService.SharedEmployees.GradeId > 0)
      {
        this.GetBranchStateId(this.EmployeeMasterService.SharedEmployees.BranchId);
        this.get_offerdSalarytails(this.EmployeeMasterService.SharedEmployees.OfferId,this.EmployeeMasterService.SharedEmployees.CompanyId,this.EmployeeMasterService.SharedEmployees.GradeId);
      }
      this.CheckEmployeeCodeByCode();
    } 
    else
    {
      this.EmployeeMasterService.SharedEmployees.WorkingGroupId = 1;
      this.EmployeeMasterService.SharedEmployees.NetTakeHome = 1;
      if(this.EmployeeMasterService.SharedEmployees.VerificationStatus == "Pending")
      {
        this.EmployeeMasterService.SharedEmployees.VerificationStatusId=1;
      }
      else if(this.EmployeeMasterService.SharedEmployees.VerificationStatus == "In Progress")
      {
        this.EmployeeMasterService.SharedEmployees.VerificationStatusId=2;
      }
      else if(this.EmployeeMasterService.SharedEmployees.VerificationStatus == "Completed")
      {
        this.EmployeeMasterService.SharedEmployees.VerificationStatusId=3;
      }
      else
      {
        this.EmployeeMasterService.SharedEmployees.VerificationStatusId=0;
      }
    }
    if(this.EmployeeMasterService.SharedEmployees.CompanyId > 0)
    {
      this.getBranches( this.EmployeeMasterService.SharedEmployees.CompanyId);
    }
    if(this.EmployeeMasterService.SharedEmployees.BranchId > 0)
    {
      this.getDepartments(this.EmployeeMasterService.SharedEmployees.BranchId);
    }
    if(this.EmployeeMasterService.SharedEmployees.DecisionId > 0)
    {
      this.getLevel(this.EmployeeMasterService.SharedEmployees.DecisionId);
    }
    if(this.EmployeeMasterService.SharedEmployees.LevelId > 0)
    {
      this.getGrade(this.EmployeeMasterService.SharedEmployees.LevelId);
    }
    if(this.EmployeeMasterService.SharedEmployees.GradeId > 0)
    {
      this.getDesignation(this.EmployeeMasterService.SharedEmployees.GradeId);
    }
    if(this.EmployeeMasterService.SharedEmployees.MaritalId == 1)
    {
      this.AnniversaryDateShow=true;
    }
    if(this.EmployeeMasterService.SharedEmployees.ImageFile != null || this.EmployeeMasterService.SharedEmployees.ImageFile != '')
    {
      this.EmployeeMasterService.SharedEmployees.validfile=true;
    }
    this.changetdateformat();
    this.BranchStateId=0;
    this.DisableSave=true;
  }

  onDateChangedDOB(event: IMyDateModel): void
  {
    const monthNames = [
    'January', 'February', 'March',
    'April', 'May', 'June', 'July',
    'August', 'September', 'October',
    'November', 'December'
    ];
    var m= event.jsdate.getMonth();
    m=m+1;
    var d= event.jsdate.getDate();
    var y= event.jsdate.getFullYear();
    var fulldate="";
    if(m>=10)
    {
      if(d < 10)
      {
        fulldate= m + '/' + '0'+ d + '/' + y;
      }
      else
      {
        fulldate= m + '/' + d + '/' + y; 
      }
    }
    else
    {
      if(d < 10)
      {
        fulldate= '0' + m + '/' + '0'+ d + '/' + y;
      }
      else
      {
        fulldate= '0' + m + '/' + d + '/' + y; 
      } 
    }
    this.EmployeeMasterService.SharedEmployees.DOBPass=fulldate;
  }

  onDateChangedOfferDate(event: IMyDateModel): void
  {
    const monthNames = [
    'January', 'February', 'March',
    'April', 'May', 'June', 'July',
    'August', 'September', 'October',
    'November', 'December'
    ];
    var m= event.jsdate.getMonth();
    m=m+1;
    var d= event.jsdate.getDate();
    var y= event.jsdate.getFullYear();
    var fulldate="";
    if(m>=10)
    {
      if(d < 10)
      {
        fulldate= m + '/' + '0'+ d + '/' + y;
      }
      else
      {
        fulldate= m + '/' + d + '/' + y; 
      }
    }
    else
    {
      if(d < 10)
      {
        fulldate= '0' + m + '/' + '0'+ d + '/' + y;
      }
      else
      {
        fulldate= '0' + m + '/' + d + '/' + y; 
      } 
    }
    this.EmployeeMasterService.SharedEmployees.OfferDatePass=fulldate;
  }

  onDateChangedJoiningDate(event: IMyDateModel): void
  {
    const monthNames = [
    'January', 'February', 'March',
    'April', 'May', 'June', 'July',
    'August', 'September', 'October',
    'November', 'December'
    ];
    var m= event.jsdate.getMonth();
    m=m+1;
    var d= event.jsdate.getDate();
    var y= event.jsdate.getFullYear();
    var fulldate="";
    if(m>=10)
    {
      if(d < 10)
      {
        fulldate= m + '/' + '0'+ d + '/' + y;
      }
      else
      {
        fulldate= m + '/' + d + '/' + y; 
      }
    }
    else
    {
      if(d < 10)
      {
        fulldate= '0' + m + '/' + '0'+ d + '/' + y;
      }
      else
      {
        fulldate= '0' + m + '/' + d + '/' + y; 
      } 
    }
    this.EmployeeMasterService.SharedEmployees.JoiningDatePass=fulldate;
    this.SalaryBreakupCalculation()
  }

  MaritalChanged( MaritalId)
  {
    if(MaritalId==1)
    {
      this.AnniversaryDateShow=true;
    }
    else
    {
      this.AnniversaryDateShow=false;
    }
  }
 
  onDateChangedAnniversaryDate(event: IMyDateModel): void
  {
    const monthNames = [
      'January', 'February', 'March',
      'April', 'May', 'June', 'July',
      'August', 'September', 'October',
      'November', 'December'
      ];
    var m= event.jsdate.getMonth();
    m=m+1;
    var d= event.jsdate.getDate();
    var y= event.jsdate.getFullYear();
    var fulldate="";
    if(m>=10)
    {
      if(d < 10)
      {
        fulldate= m + '/' + '0'+ d + '/' + y;
      }
      else
      {
        fulldate= m + '/' + d + '/' + y; 
      }
    }
    else
    {
      if(d < 10)
      {
        fulldate= '0' + m + '/' + '0'+ d + '/' + y;
      }
      else
      {
        fulldate= '0' + m + '/' + d + '/' + y; 
      } 
    }
    this.EmployeeMasterService.SharedEmployees.AnniversaryDatePass=fulldate;
  }

  handleFileinput(files : FileList)
  {
    this.filetoupload=files.item(0);
    //this.CompanyModel.LogoType= this.filetoupload.type
    var file = files[0];
    let filesize = this.filetoupload.size; 
    let filetyotypearray = this.filetoupload.type.split("/");
    this.EmployeeMasterService.SharedEmployees.PhotoType= filetyotypearray[1];
    this.EmployeeMasterService.SharedEmployees.Photodoctype= filetyotypearray[0];
    if(this.EmployeeMasterService.SharedEmployees.Photodoctype=="image")
    {
      this.EmployeeMasterService.SharedEmployees.validfile=true; 
    }
    else
    {
      this.EmployeeMasterService.SharedEmployees.validfile=false; 
    }
    if(filesize > 500000)
    {
      this.EmployeeMasterService.SharedEmployees.validfile=false; 
    }
    if(!((this.EmployeeMasterService.SharedEmployees.PhotoType=='jpeg') || (this.EmployeeMasterService.SharedEmployees.PhotoType=='png') || (this.EmployeeMasterService.SharedEmployees.PhotoType=='svg')|| (this.EmployeeMasterService.SharedEmployees.PhotoType=='gif') || (this.EmployeeMasterService.SharedEmployees.PhotoType=='wbmp')))
    {
      this.EmployeeMasterService.SharedEmployees.validfile=false; 
    }
    var reader : FileReader= new FileReader();
    reader.onload=(event:any)=>
    {
      this.imageUrl=event.target.result;
    } 
    reader.onloadend = (e) => 
    {
      this.EmployeeMasterService.SharedEmployees.Isphotochange = true;
      this.EmployeeMasterService.SharedEmployees.ImageFile = String( reader.result);
    }
    reader.readAsDataURL(file);   
  }

  public readFile(fileToRead: File)
  {
    let base64Observable = new ReplaySubject(1);
    let fileReader = new FileReader();
    fileReader.onload = event => 
    {
      base64Observable.next(fileReader.result);
    };
    fileReader.readAsDataURL(fileToRead);
    return base64Observable;
  }
  
  getWorkingHour() 
  {
    this.WorkingGroupHour=[];
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.AttendanceService.GetWorkingGroupHours( t).then(x => 
    {
      this.workinggrouphour = x;
      Object.keys(this.workinggrouphour).forEach( key => 
      {
        this.workinggrouphour[key];
        let workinggrouphourtemp = new WorkingGroupHours();
        workinggrouphourtemp = this.workinggrouphour[key];
        this.WorkingGroupHour.push(workinggrouphourtemp);
      });     
    });      
  }

  getGenders() 
  {
    this.Ganders=[];
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.EmployeeMasterService.GetGenderForDDL( t).then(x => 
    {
      this.Gander = x;
      Object.keys(this.Gander).forEach( key => 
      {
        let Gendertemp = new ModelGander();
        Gendertemp = this.Gander[key];
        this.Ganders.push(Gendertemp);
      });     
    });      
  }

  getLanguage() 
  {
    this.Languages=[];
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.EmployeeMasterService.GetLanguages( t).then(x => 
    {
      this.Lang = x;
      Object.keys(this.Lang).forEach( key => 
      {
        let Languagetemp = new Languages();
        Languagetemp = this.Lang[key];
        this.Languages.push(Languagetemp);
      });     
    });      
  }

  getReligion() 
  {
    this.Religions=[];
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.EmployeeMasterService.GetReligion( t).then(x => 
    {
      this.Reli = x;
      Object.keys(this.Reli).forEach( key => 
      {       
        let ReligionTemp = new Religion();
        ReligionTemp = this.Reli[key];
        this.Religions.push(ReligionTemp);
      });     
    });      
  }

  getMaritalStatus() 
  {
    this.Maritials=[];
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.EmployeeMasterService.GetMaritalStatusForDDL( t).then(x => 
    {
      this.Marit = x;
      Object.keys(this.Marit).forEach( key => 
      {
        this.Marit[key];
        let Maritaltemp = new ModelMarital();
        Maritaltemp = this.Marit[key];
        this.Maritials.push(Maritaltemp);
      });     
    });      
  }
  //Begin Organisation
  getCompanies() 
  {
    this.Companies=[];
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.organizationService.GetCompanyForDDL( t).then(x => 
    {
      this.Company = x;
      Object.keys(this.Company).forEach( key => 
      {
        this.Company[key];
        let Companytemp = new Company();
        Companytemp = this.Company[key];
        this.Companies.push(Companytemp);
      });     
    });      
  }

  getBranches( Companyid) 
  {
    this.Branches=[];
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t
    if(this.Branches.length>0)
    {
      this.Branches.splice(0, this.Branches.length);
    }
    this.organizationService.GetBranchByCompanyId( t,Companyid).then(x => 
    {
      this.Branch = x;
      Object.keys(this.Branch).forEach( key => 
      {
        this.Branch[key];
        let Branchtemp = new Branch();
        Branchtemp = this.Branch[key];
        this.Branches.push(Branchtemp);
      });     
    });      
  }

  getDepartments(Branchid) 
  {
    this.Departments=[];
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t
    if(this.Departments.length>0)
    {
      this.Departments.splice(0,this.Departments.length )
    }
    this.organizationService.GetDepartmentByBranchid( t,Branchid).then(x => 
    {
      this.Department = x;
      Object.keys(this.Department).forEach( key => 
      {
        this.Department[key];
        let Departmenttemp = new Department();
        Departmenttemp = this.Department[key];
        this.Departments.push(Departmenttemp);
      });     
    });      
  }

  onChangeCompany(Companyid) 
  {
    this.getBranches(Companyid);
    this.getDepartments(0);
    this.EmployeeMasterService.SharedEmployees.BranchId=0;
    this.EmployeeMasterService.SharedEmployees.DepartmentId=0;
  }

  onchangeBranch(Branchid)
  {
    this.getDepartments(Branchid);
    this.GetBranchStateId(Branchid)
    this.EmployeeMasterService.SharedEmployees.DepartmentId=0;
  }

  GetBranchStateId( Branchid) 
  {
    this.BranchStateId=0;
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t
    this.organizationService.GetBranchStateId( t,Branchid).then(x => 
    {
      this.Branch = x;
      Object.keys(this.Branch).forEach( key => 
      {
        this.Branch[key];
        let Branchtemp = new Branch();
        Branchtemp = this.Branch[key];
        this.BranchStateId=Branchtemp.StateId;
        if(this.EmployeeMasterService.SharedEmployees.OfferId > 0)
        {
          if(this.EmployeeMasterService.SharedEmployees.PTAXApplicable==true)
          {
            this.DisableState=true;
            this.EmployeeMasterService.SharedEmployees.PtaxStateId=this.BranchStateId;
          }
          else
          {
            this.EmployeeMasterService.SharedEmployees.PtaxStateId=0;
            this.DisableState=false;
          }
        }
      });  
    });      
  }
  //End Organisation
  //Begin Grades & Level
  getDecisionMaking() 
  {
    this.DecisionMakings=[];
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.LevelService.GetDecisionMaking( t).then(x => 
    {
      this.DecisionMaking = x;
      Object.keys(this.DecisionMaking).forEach( key => 
      {
        this.DecisionMaking[key];
        let DecisionMakingtemp = new DecisionMaking();
        DecisionMakingtemp = this.DecisionMaking[key];
        this.DecisionMakings.push(DecisionMakingtemp);
      });     
    });      
  }

  getLevel( DecisionMakingid) 
  {
    this.Levels=[];
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t
    if(this.Levels.length>0)
    {
      this.Level.splice(0, this.Level.length);
    }
    this.LevelService.GetLevelByDecisionid( t,DecisionMakingid).then(x => 
    {
      this.Level = x;
      Object.keys(this.Level).forEach( key => 
      {
        this.Level[key];
        let Leveltemp = new Level();
        Leveltemp = this.Level[key];
        this.Levels.push(Leveltemp);
      });     
    });      
  }

  getGrade(Levelid) 
  {
    this.Grades=[];
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t
    if(this.Grades.length>0)
    {
      this.Grades.splice(0,this.Grades.length )
    }
    this.LevelService.GetGradeByLevelid( t,Levelid).then(x => 
    {
      this.Grade = x;
      Object.keys(this.Grade).forEach( key => 
      {
        this.Grade[key];
        let Gradetemp = new Grade();
        Gradetemp = this.Grade[key];
        this.Grades.push(Gradetemp);
      });     
    });      
  }

  getDesignation(Gradeid) 
  {
    this.Designations=[];
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t
    if(this.Designations.length>0)
    {
      this.Designations.splice(0,this.Designations.length )
    }
    this.LevelService.GetDesignationByGradeid( t,Gradeid).then(x => 
    {
      this.Designation = x;
      Object.keys(this.Designation).forEach( key => 
      {
        this.Designation[key];
        let Designationtemp = new Designation();
        Designationtemp = this.Designation[key];
        this.Designations.push(Designationtemp);
      });     
    });      
  }

  SaveEmpolyeePersonalDetails() :void
  {
    this.DisableSave=false;
    let t = localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.EmployeeMasterService.SharedEmployees.IsActive=true;
    if(this.EmployeeMasterService.SharedEmployees.EmployeeId> 0)
    {
      this.EmployeeMasterService.UpdateEmployeePersonalDetails(t ,this.EmployeeMasterService.SharedEmployees).subscribe(res=>
      {
        this.EmployeeId=Number(res);
        if(this.EmployeeId>0)
        {
          this.EmployeeMasterService.SharedEmployees.IsPhotochange=false;
          this.EmployeeMasterService.SaveOrUpdate=2;
          this.Tabindex=1         
          this.notificationsService.emit(this.Tabindex);
          this.rout.navigateByUrl('/Employee/EmployeeDetails/AddressDetails');
        }
      });                  
    }                             
    else
    {
      this.EmployeeMasterService.SharedEmployees.OfferSalary=this.OfferedSalary;
      this.EmployeeMasterService.AddEmployeePersonalDetails(t ,this.EmployeeMasterService.SharedEmployees).subscribe(res=>
      {
        this.EmployeeId=Number(res);
        if(this.EmployeeId>0)
        {
          this.EmployeeMasterService.SaveOrUpdate=1;
          this.EmployeeMasterService.SharedEmployees.EmployeeId=this.EmployeeId; 
          this.Tabindex=1         
          this.notificationsService.emit(this.Tabindex);
          this.rout.navigateByUrl('/Employee/EmployeeDetails/AddressDetails');
        }
      }); 
    }
  }

  onDecisionChange(Decisionid) 
  {
    this.getLevel(Decisionid);
    this.getGrade(0);
    this.getDesignation(0);
    this.EmployeeMasterService.SharedEmployees.LevelId=0;
    this.EmployeeMasterService.SharedEmployees.GradeId=0;
    this.EmployeeMasterService.SharedEmployees.DesignationId=0;
  }

  onLevelChange(Levelid)
  {
    this.getGrade(Levelid);
    this.getDesignation(0);
    this.EmployeeMasterService.SharedEmployees.GradeId=0;
    this.EmployeeMasterService.SharedEmployees.DesignationId=0;
  }

  onGradechange(Gradeid)
  {
    this.getDesignation(Gradeid);
    this.EmployeeMasterService.SharedEmployees.DesignationId=0;
    if(this.EmployeeMasterService.SharedEmployees.EmployeeId == 0 && this.EmployeeMasterService.SharedEmployees.OfferId==0)
    {
      this.SalaryBreakupCalculation();
    }
    else
    {
      this.get_offerdSalarytails(this.EmployeeMasterService.SharedEmployees.OfferId,this.EmployeeMasterService.SharedEmployees.CompanyId,this.EmployeeMasterService.SharedEmployees.GradeId)
    }
  }

  //End Grades & Level
  CheckEmployeeCodeByCode()
  {
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.EmployeeMasterService.CheckemployeeCode( t, this.EmployeeMasterService.SharedEmployees.EmployeeCode).then(x => 
    {
      this.status = x;
      if( this.status)
      {
        this.IsEmpCodeStatus =true;
      }
      else
      {
        this.IsEmpCodeStatus =false;
      }
    });
  }
  
  changetdateformat()
  {
    let objemp = new EmployeeDetail();
    if(this.EmployeeMasterService.SharedEmployees.OfferDatePass != null)
    {
      this.EmployeeMasterService.SharedEmployees.OfferDate=objemp.ReturnFormatdate(this.EmployeeMasterService.SharedEmployees.OfferDatePass)
    }
    else
    {
      this.EmployeeMasterService.SharedEmployees.OfferDate=null;
    }
    if(this.EmployeeMasterService.SharedEmployees.JoiningDatePass != null)
    {
      this.EmployeeMasterService.SharedEmployees.JoiningDate=objemp.ReturnFormatdate(this.EmployeeMasterService.SharedEmployees.JoiningDatePass)
    }
    else
    {
      this.EmployeeMasterService.SharedEmployees.JoiningDate=null;
    }
    if(this.EmployeeMasterService.SharedEmployees.DOBPass != null)
    {
      this.EmployeeMasterService.SharedEmployees.DOB=objemp.ReturnFormatdate(this.EmployeeMasterService.SharedEmployees.DOBPass)
    }
    else
    {
      this.EmployeeMasterService.SharedEmployees.DOB=null;
    }
    if(this.EmployeeMasterService.SharedEmployees.AnniversaryDatePass != null)
    {
      this.EmployeeMasterService.SharedEmployees.AnniversaryDate=objemp.ReturnFormatdate(this.EmployeeMasterService.SharedEmployees.AnniversaryDatePass)
    }
    else
    {
      this.EmployeeMasterService.SharedEmployees.AnniversaryDate=null;
    }
  }

  //Employee Details
  GetFunctionalHeads()
  {
    this.FunctionalHeads=[];
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.EmployeeMasterService.GetReportingandFunctionalHead( t).then(x => 
    {
      this.FunctionalHead = x;
      Object.keys(this.FunctionalHead).forEach( key => 
      {
        let FHtemp = new EmployeeDetail();
        FHtemp = this.FunctionalHead[key];
        this.FunctionalHeads.push(FHtemp);
      });     
    });      
  }

  GetReportingHeads()
  {
    this.ReportingHeads=[];
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.EmployeeMasterService.GetReportingandFunctionalHead( t).then(x => 
    {
      this.ReportingHead = x;
      Object.keys(this.ReportingHead).forEach( key => 
      {
        let RHtemp = new EmployeeDetail();
        RHtemp = this.ReportingHead[key];
        this.ReportingHeads.push(RHtemp);
      });     
    });      
  }

  RefreshPage()
  {
    this.rout.navigateByUrl('/Employee/EmployeeOffer', {skipLocationChange: true}).then(()=>this.rout.navigate(["/Employee/EmployeeDetails"]));
  }
  
  GetFullName()
  {
    if(this.EmployeeMasterService.SharedEmployees.FirstName != null && this.EmployeeMasterService.SharedEmployees.MiddleName != null && this.EmployeeMasterService.SharedEmployees.LastName != null)
    {
      this.EmployeeMasterService.SharedEmployees.FullName = this.EmployeeMasterService.SharedEmployees.FirstName + ' ' + this.EmployeeMasterService.SharedEmployees.MiddleName + ' ' + this.EmployeeMasterService.SharedEmployees.LastName;
    }
    else if(this.EmployeeMasterService.SharedEmployees.FirstName != null && this.EmployeeMasterService.SharedEmployees.MiddleName == null && this.EmployeeMasterService.SharedEmployees.LastName != null)
    {
      this.EmployeeMasterService.SharedEmployees.FullName = this.EmployeeMasterService.SharedEmployees.FirstName + ' ' + this.EmployeeMasterService.SharedEmployees.LastName;
    }
    else if(this.EmployeeMasterService.SharedEmployees.FirstName != null && this.EmployeeMasterService.SharedEmployees.MiddleName != null && this.EmployeeMasterService.SharedEmployees.LastName == null)
    {
      this.EmployeeMasterService.SharedEmployees.FullName = this.EmployeeMasterService.SharedEmployees.FirstName + ' ' + this.EmployeeMasterService.SharedEmployees.MiddleName;
    }
    else if(this.EmployeeMasterService.SharedEmployees.FirstName != null && this.EmployeeMasterService.SharedEmployees.MiddleName == null && this.EmployeeMasterService.SharedEmployees.LastName == null)
    {
      this.EmployeeMasterService.SharedEmployees.FullName = this.EmployeeMasterService.SharedEmployees.FirstName;
    }
  }

  // offered Salary
  GetOfferdetails_Salary()
  {
    this.SalaryBreakupCalculation();
  }

  onBasicChangevaluechange()
  {
    this.ComponentTotal=0;
    this.ComponentSubTotal=0;
    this.Longtermbenefits = 0;
    this.OfferedSalary.forEach(x=>
    {     
      let BAmount = Math.round(this.OfferedSalary.find(x=>x.SalaryComponentCode=='BS').Amount);
      this.GetEPFCalculation(BAmount);
      if(x.IsBenefit==false)
      {
        var tempvaluef = 
        {
          a: Number(this.ComponentSubTotal),
          b: Number(x.Amount)  
        }
        this.ComponentSubTotal = Math.round(tempvaluef.a) + Math.round(tempvaluef.b);
      }
      else
      {
        var tempvaluef = 
        {
          a: Number(this.Longtermbenefits),
          b: Number(x.Amount)                 
        }
        this.Longtermbenefits = Math.round(tempvaluef.a) + Math.round(tempvaluef.b);
      }
    })
    this.EmployeeMasterService.SharedEmployees.GrossSalary=Math.round(this.ComponentSubTotal);
    this.GetESICalculation(Math.round(this.ComponentSubTotal));
    this.GetPtax();
    this.EmployeeMasterService.SharedEmployees.CTCAmount=Math.round(this.Longtermbenefits + this.ComponentSubTotal + this.PFCont + this.ESICont);
  }

  GetEPFCalculation( BAmount)
  {
    if(this.EmployeeMasterService.SharedEmployees.EPFApplicable==true)
    {
      this.MinBasicSalary=this.tempEPFESIModel.HighestBasicSalary;
      if(BAmount >= this.MinBasicSalary)
      {
        this.PFDed=Math.round((this.MinBasicSalary*this.tempEPFESIModel.EPFDed)/100);
        this.PFCont=Math.round((this.MinBasicSalary*this.tempEPFESIModel.EPFCont)/100);
      }
      else
      {
        this.PFDed=Math.round((BAmount * this.tempEPFESIModel.EPFDed)/100);
        this.PFCont=Math.round((BAmount * this.tempEPFESIModel.EPFCont)/100);
      }
    }
    else
    {
      this.PFDed=0.00;
      this.PFCont=0.00;
    }
  }

  GetESICalculation( GrossSalary)
  {
    if(this.EmployeeMasterService.SharedEmployees.ESIApplicable==true)
    {
      this.MinBasicSalary=this.tempEPFESIModel.HighestBasicSalary;
      if(this.EmployeeMasterService.SharedEmployees.GrossSalary >= this.MinBasicSalary)
      {
        this.ESIDed=0.00;
        this.ESICont=0.00;
      }
      else
      {
        this.ESIDed=Math.round((GrossSalary * this.tempEPFESIModel.ESIDed)/100);
        this.ESICont=Math.round((GrossSalary * this.tempEPFESIModel.ESICont)/100);
      } 
    }
    else
    {
      this.ESIDed=0.00;
      this.ESICont=0.00;
    }
  }

  GetEpfEsi()
  {
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.EmpSalary.GetEPFESISlabforOffer(t).then(y=>
    {
      this.tempEPFESIModels=y;
      Object.keys(this.tempEPFESIModels).forEach( key => 
      {
        this.tempEPFESIModel=this.tempEPFESIModels[key]; 
        this.tempEPFESIModel.EPFDed=this.tempEPFESIModel.EPFDed;
        this.tempEPFESIModel.EPFCont=this.tempEPFESIModel.EPFCont;
        this.tempEPFESIModel.ESIDed=this.tempEPFESIModel.ESIDed;
        this.tempEPFESIModel.ESICont=this.tempEPFESIModel.ESICont;
        this.tempEPFESIModel.HighestBasicSalary=this.tempEPFESIModel.HighestBasicSalary;                    
      }); 
    });
  }

  GetPtax()
  {
    if(this.EmployeeMasterService.SharedEmployees.PTAXApplicable==true && this.EmployeeMasterService.SharedEmployees.PtaxStateId > 0)
    {
      let t =  localStorage.getItem('userToken');
      t = 'Bearer' + t;
      this.EmpSalary.GetPtaxSlabByBasicSalaryForOffer(t,this.EmployeeMasterService.SharedEmployees.PtaxStateId,this.ComponentSubTotal).then(y=>
      {
        this.tempPtaxSlabModels=y;
        this.P_Tax = 0.00;
        this.EmployeeMasterService.SharedEmployees.NetTakeHome=(this.ComponentSubTotal)-(this.PFDed + this.ESIDed + this.P_Tax);
        Object.keys(this.tempPtaxSlabModels).forEach( key => 
        {
          this.tempPtaxSlabModel=this.tempPtaxSlabModels[key]; 
          this.P_Tax = Math.round(this.tempPtaxSlabModel.TaxAmount);                    
        }); 
        this.EmployeeMasterService.SharedEmployees.NetTakeHome=Math.round((this.ComponentSubTotal)-(this.PFDed + this.ESIDed + this.P_Tax));
      }); 
    }
    else
    {
      this.P_Tax = 0.00;
      this.EmployeeMasterService.SharedEmployees.NetTakeHome=Math.round((this.ComponentSubTotal)-(this.PFDed + this.ESIDed + this.P_Tax));
    }
  }

  public openModal(template: TemplateRef<any>) 
  {
    this.modalRef = this.modalService.show(template);
  }

  public SalaryBreakupCalculation()
  {
    this.EmployeeMasterService.SharedEmployees.NetTakeHome=0;
    // Salary Breakup conponent
    if(this.EmployeeMasterService.SharedEmployees.OfferId == 0 &&this.EmployeeMasterService.SharedEmployees.CompanyId > 0 && this.EmployeeMasterService.SharedEmployees.GradeId> 0 && this.EmployeeMasterService.SharedEmployees.GrossSalary > 0)
    {
      let t =  localStorage.getItem('userToken');
      t = 'Bearer' + t;
      this.EmpSalary.GetOfferedSalary( t,this.EmployeeMasterService.SharedEmployees.CompanyId,this.EmployeeMasterService.SharedEmployees.GradeId,this.EmployeeMasterService.SharedEmployees.GrossSalary).then(x => 
      {
        this.Offer = x;
        if(this.OfferedSalary.length >0)
        {
          this.OfferedSalary=[];
        } 
        this.ComponentTotal=0;
        this.ComponentSubTotal=0;
        this.Longtermbenefits = 0;
        Object.keys(this.Offer).forEach( key => 
        {
          this.Offer[key];
          let Offertemp = new OfferStandardSalary();
          Offertemp = this.Offer[key];

          if(Offertemp.SalaryComponentCode=='BS')
          {
            let BAmount = Math.round(this.Offer.find(x=>x.SalaryComponentCode=='BS').Amount);
            this.GetEPFCalculation(BAmount);
          }

          if(Offertemp.IsBenefit==false)
          {              
            //this.ComponentSubTotal = this.ComponentSubTotal + Offertemp.Amount; 
            var tempvaluef =
            {
              a: Number(this.ComponentSubTotal),
              b: Number(Offertemp.Amount)  
            }
            this.ComponentSubTotal = Math.round(tempvaluef.a) + Math.round(tempvaluef.b); 
          }
          else
          {
            // this.Longtermbenefits = this.Longtermbenefits +  Offertemp.Amount;
            var tempvaluef = 
            {
              a: Number(this.Longtermbenefits),
              b: Number(Offertemp.Amount)  
            }
            this.Longtermbenefits = Math.round(tempvaluef.a) + Math.round(tempvaluef.b); 
          }
          this.OfferedSalary.push(Offertemp);            
        });          
        if(this.OfferedSalary.find(x=>x.SalaryComponentCode=='SPA').Amount==0)
        {
          var tempvaluefox = 
          {
            a: Number(this.ComponentSubTotal),
            b: Number(this.EmployeeMasterService.SharedEmployees.GrossSalary),  
            c: Number(this.Longtermbenefits)
          }
          this.OfferedSalary.find(x=>x.SalaryComponentCode=='SPA').Amount= Math.round(tempvaluefox.b -  tempvaluefox.a);
          this.ComponentSubTotal = tempvaluefox.b;
        }
        else
        {
          var tempvaluefoy = 
          {
            a: Number(this.EmployeeMasterService.SharedEmployees.GrossSalary),
            b: Number(this.ComponentSubTotal),
            c: this.OfferedSalary.find(x=>x.SalaryComponentCode=='SPA').Amount,
            d: Number(this.Longtermbenefits)
          }
          this.OfferedSalary.find(x=>x.SalaryComponentCode=='SPA').Amount=Math.round((tempvaluefoy.a - tempvaluefoy.b + tempvaluefoy.c)); 
          this.ComponentSubTotal = Math.round(tempvaluefoy.a);
        }
        this.GetESICalculation(this.ComponentSubTotal);
        this.GetPtax();
        this.EmployeeMasterService.SharedEmployees.CTCAmount=Math.round(this.Longtermbenefits + this.ComponentSubTotal + this.PFCont + this.ESICont);
      });
    }
  }

  get_offerdSalarytails(Offerid,CompanyId,GradeId)
  {
    let t = localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.EmpSalary.GetOfferSalaryComponentDetailsByid(t,Offerid,CompanyId,GradeId).then(y=>
    {
      this.Offer=y;
      this.ComponentTotal=0;
      this.ComponentSubTotal=0;
      this.Longtermbenefits = 0;
      this.OfferedSalary=[]
      Object.keys(this.Offer).forEach( key => 
      {
        this.Offer[key];
        let tempofferSalary= new OfferStandardSalary();
        tempofferSalary=this.Offer[key];
        if(tempofferSalary.SalaryComponentCode=='BS')
        {
          let BAmount = Math.round(this.Offer.find(x=>x.SalaryComponentCode=='BS').Amount);
          this.GetEPFCalculation(BAmount);
        }
        tempofferSalary.OfferId=tempofferSalary.OfferId;
        tempofferSalary.OfferSalaryId=tempofferSalary.OfferSalaryId;
        tempofferSalary.IsActive=tempofferSalary.IsActive;
        tempofferSalary.IsBenefit=tempofferSalary.IsBenefit;
        tempofferSalary.IsBasic=tempofferSalary.IsBasic;
        tempofferSalary.IsCTC=tempofferSalary.IsCTC;
        tempofferSalary.Percentage=tempofferSalary.Percentage;
        tempofferSalary.SalaryComponentCode=tempofferSalary.SalaryComponentCode;
        tempofferSalary.SalaryComponentId=tempofferSalary.SalaryComponentId;
        tempofferSalary.SalaryComponentName=tempofferSalary.SalaryComponentName;
        tempofferSalary.Amount=Math.round(tempofferSalary.Amount);
        if(tempofferSalary.IsBenefit==false)
        {     
          //this.ComponentSubTotal = this.ComponentSubTotal + Offertemp.Amount;  
          var tempvaluef = 
          {
            a: Number(this.ComponentSubTotal),
            b: Number(tempofferSalary.Amount)  
          }
          if(tempvaluef.a==undefined)
          {
            tempvaluef.a=0;
          }
          if(tempvaluef.b==undefined)
          {
            tempvaluef.b=0;
          }
          this.ComponentSubTotal = Math.round(tempvaluef.a) + Math.round(tempvaluef.b); 
        }
        else
        {
          // this.Longtermbenefits = this.Longtermbenefits +  Offertemp.Amount;
          var tempvaluef = 
          {
            a: Number(this.Longtermbenefits),
            b: Number(tempofferSalary.Amount)  
          }
          if(tempvaluef.a==undefined)
          {
            tempvaluef.a=0;
          }
          if(tempvaluef.b==undefined)
          {
            tempvaluef.b=0;
          }
          this.Longtermbenefits = Math.round(tempvaluef.a) + Math.round(tempvaluef.b); 
        }
        this.OfferedSalary.push(tempofferSalary);
      });
      this.GetESICalculation(this.ComponentSubTotal); 
      this.GetPtax();
    });
  }

  oncheckedEPFApplicableChange()
  {
    if(this.EmployeeMasterService.SharedEmployees.EmployeeId==0 && this.EmployeeMasterService.SharedEmployees.OfferId==0)
    {
      this.SalaryBreakupCalculation();
    }
    else
    {
      this.get_offerdSalarytails(this.EmployeeMasterService.SharedEmployees.OfferId,this.EmployeeMasterService.SharedEmployees.CompanyId,this.EmployeeMasterService.SharedEmployees.GradeId)
    }
  }

  oncheckedESIApplicableChange() 
  {
    if(this.EmployeeMasterService.SharedEmployees.EmployeeId==0 && this.EmployeeMasterService.SharedEmployees.OfferId==0)
    {
      this.SalaryBreakupCalculation();
    }
    else
    {
      this.get_offerdSalarytails(this.EmployeeMasterService.SharedEmployees.OfferId,this.EmployeeMasterService.SharedEmployees.CompanyId,this.EmployeeMasterService.SharedEmployees.GradeId)
    }
  }

  oncheckedPTAXApplicableChange() 
  {
    if(this.EmployeeMasterService.SharedEmployees.PTAXApplicable==true)
    {
      this.DisableState=true;
      this.EmployeeMasterService.SharedEmployees.PtaxStateId=0;
    }
    else
    {
      this.EmployeeMasterService.SharedEmployees.PtaxStateId=0;
      this.DisableState=false;
    }
    if(this.EmployeeMasterService.SharedEmployees.EmployeeId==0 && this.EmployeeMasterService.SharedEmployees.OfferId==0)
    {
      this.SalaryBreakupCalculation();
    }
    else
    {
      this.get_offerdSalarytails(this.EmployeeMasterService.SharedEmployees.OfferId,this.EmployeeMasterService.SharedEmployees.CompanyId,this.EmployeeMasterService.SharedEmployees.GradeId)
    }
  }

  onVerificationStatusChange(status)
  {
    if(status == 1)
    {
      this.EmployeeMasterService.SharedEmployees.VerificationStatus="Pending";
    }
    else if(status == 2)
    {
      this.EmployeeMasterService.SharedEmployees.VerificationStatus="In Progress";
    }
    else if(status == 3)
    {
      this.EmployeeMasterService.SharedEmployees.VerificationStatus="Completed";
    }
  }

  getStates() 
  {
    this.States=[];
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t
    if(this.States.length>0)
    {
      this.States.splice(0, this.States.length);
    }
    this.LocationService.GetState( t).then(x => 
    {
      this.States=[];
      this.State = x;
      Object.keys(this.State).forEach( key => 
      {
        // tslint:disable-next-line:no-unused-expression
        let Statetemp = new State();
        Statetemp = this.State[key];
        this.States.push(Statetemp);
      });     
    });      
  }

  onPtaxStateChange()
  {
    if(this.EmployeeMasterService.SharedEmployees.PtaxStateId > 0)
    {
      if(this.EmployeeMasterService.SharedEmployees.EmployeeId==0 && this.EmployeeMasterService.SharedEmployees.OfferId==0)
      {
        this.SalaryBreakupCalculation();
      }
      else
      {
        this.get_offerdSalarytails(this.EmployeeMasterService.SharedEmployees.OfferId,this.EmployeeMasterService.SharedEmployees.CompanyId,this.EmployeeMasterService.SharedEmployees.GradeId)
      }
    }
    else
    {
      this.EmployeeMasterService.SharedEmployees.PtaxStateId=0;
    }
  }

  GetPayZone()
  {
    this.RegionForHR=[];
    let t = localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.organizationService.GetPayZoneDDL( t).then(x => 
    {
      this.RegionHR = x;
      Object.keys(this.RegionHR).forEach( key => 
      {
        this.RegionHR[key];
        let RegionHRtemp = new RegionForHR();
        RegionHRtemp = this.RegionHR[key];
        this.RegionForHR.push(RegionHRtemp);
      });     
    });      
  }
}