<div class="body-content-area tabcontent" id="link1">
<div class="row">
    <div class="col-lg-9">
        <div class="row">
            <div class="col-lg-1 low-padding text-center">
                <!-- <img *ngIf="this.SelectedMenu.Image!=null" src={{this.SelectedMenu.Image}} class="body-icon" alt="Icon"/> -->
            </div>
            <div class="col-lg-10 clear-padding">
                <span class="page-title">Manage Vertical</span>
                <small class="page-description"></small>
            </div>
        </div>
    </div>
</div>

<div *ngIf="!(this.CanViewState)">  
<table id="tblVertical" datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" class="table table-striped table-bordered text-center table-new row-border hover">
    <thead class="thead-blue text-center">
        <tr>
            <th>Sl No.</th>
            <th>Vertical Code</th>
            <th>Vertical Name</th>
            <th>Company</th>
            <th>Action</th>
        </tr>
    </thead>
    <tbody>
        <tr *ngFor="let V of Verticals, let i = index">
            <td scope="row">{{i+1}}</td>
            <td class="text-left">{{V.VerticalCode}}</td>
            <td class="text-left">{{V.VerticalName}}</td>
            <td class="text-left">{{V.CompName}}</td>
            <td>
                <button *ngIf="this.SelectedMenu.CanEdit" type="button"  (click)="GetVerticalByid( V.VerticalId )" class="table-edit-btn"><i class="fas fa-edit"></i> </button> 
                <button *ngIf="this.SelectedMenu.CanDelete" type="button"  (click)="DeleteVerticalByid( V.VerticalId )"class="table-remove-btn"><i class="fas fa-trash-alt"></i> </button>
                <button  *ngIf="this.SelectedMenu.CanView"  type="button" (click)="ViewVerticalByid( V.VerticalId )" class="table-view-button" type="button" ><i class="fas fa-eye"></i></button>

            </td>
        </tr>
    </tbody>
</table>

<fieldset class="body-form-control">
    <app-dialog [(visible)]="this.SuccessStatus">
        <span class="successmsg">{{this.SuccessMessage}}</span>
    </app-dialog>
    
    <legend class="legend--">Vertical Creation</legend>
    <form #VerticalForm="ngForm" (ngSubmit)="saveVertical()">
    <div class="row">
        <div class="col-lg-4">
            <div class="col-body-border-right">
                <div class="form-group row">
                    <label class="col-sm-4 col-form-label clear-padding-right">Code</label>
                    <div class="col-sm-7 clear-left-padding">
                        <input type="text" [(ngModel)]="VerticalModel.VerticalCode" tabindex="1" maxlength="40" id ="VerticalCode"  name="VerticalCode" #VerticalCode="ngModel" required class="form-control form-control-custom form-control-custom-new" (blur)="checkVerticalcode()" >
                    </div>
                    <div *ngIf="this.verticalCodecheck.length > 0" class="alert alert-danger">Vertical Code is exist</div>
                    <div *ngIf="VerticalCode.invalid && (VerticalCode.dirty || VerticalCode.touched)" class="alert alert-danger">
                        <div *ngIf="VerticalCode.errors.required">Vertical Code is required.</div>
                        <div *ngIf="VerticalCode.errors.maxlength">Vertical Code is not more than 40 characters</div>
                    </div>
                </div>
                <div class="form-group row">
                    
                </div>
            </div>
        </div>
        
        <div class="col-lg-4">
            <div class="col-body-border-right">
                <div class="form-group row">
                    <label  class="col-sm-4 col-form-label clear-padding-right">Name</label>
                    <div class="col-sm-7 clear-left-padding">
                        <input type="text" [(ngModel)]="VerticalModel.VerticalName" tabindex="2" id ="VerticalName" name="VerticalName" #VerticalName="ngModel" required maxlength="250" class="form-control form-control-custom form-control-custom-new"  >
                    </div>
                    <div *ngIf="VerticalName.invalid && (VerticalName.dirty || VerticalName.touched)" class="alert alert-danger">
                        <div *ngIf="VerticalName.errors.required">Vertical Name  is required.</div>
                        <div *ngIf="VerticalName.errors.maxlength">Vertical Name is not more than 250 characters</div>
                    </div>
                </div>
                <div class="form-group row">
                        <div class="col-lg-11">
                    <angular2-multiselect class="form-control" [data]="BranchesdropdownList" [(ngModel)]="BranchselectedItems" name="BranchselectedItems" 
[settings]="BranchdropdownSettings" 
(onSelect)="onItemSelect($event)" 
(onDeSelect)="OnItemDeSelect($event)"
(onSelectAll)="onSelectAll($event)"
(onDeSelectAll)="onDeSelectAll($event)">

</angular2-multiselect>

            </div>
                    </div>
            </div>
        </div>

        <div class="col-lg-4">
            <div class="form-group row">
                <label  class="col-sm-4 col-form-label clear-padding-right">Company Name</label>
                <div class="col-sm-7 clear-left-padding">
                    <select [(ngModel)]="VerticalModel.CompId" tabindex="3" id ="CompId" class="form-control"  name="CompId" #CompId="ngModel" (ngModelChange)="getBranches($event)" >
                        <option [value]="0" [disabled]=true >Select Company</option>
                        <option *ngFor="let comp of Companies" [value]="comp.CompId">{{comp.CompName}}</option>
                    </select>
                </div>
                <div class="required-validation"  *ngIf="VerticalModel.CompId==0">Company is required</div>
            </div>
            <div class="form-group row">
                <label *ngIf="!(this.IsDataIsInViewState)" class="col-sm-6 col-form-label text-center clear-padding-right">
                    <button type="submit" tabindex="4" class="btn btn-primary save-button" 
                    [disabled]="!(VerticalForm.valid && VerticalModel.CompId !=0 && this.DisableSave==true)" >Save</button>  
                </label>
                <div class="col-sm-6 col-form-label text-center">
                    <button type="button" tabindex="5" (click)="RefreshPage()" class="btn btn-danger cancle-button">Cancel</button>
                </div>
            </div>
        </div>
    </div>
</form>
</fieldset>
</div>
</div>