import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs/Subject';
import { DataTableDirective } from 'angular-datatables';
import { UserService } from '../shared/user.service';
import { OrganizationService}  from '../shared/Organization/organization.service';
import { FinancialYear } from  '../shared/Organization/FinancialYear.Model';
// Begning Confirmation
import { ConfirmationService } from '@jaspero/ng2-confirmations';
import { ResolveEmit } from '../../app/resolve-emit';
import { ConfirmSettings } from '../../app/confirm-settings';
// end of Confirmation
import { Menu } from '../shared/menu.model';

@Component({
  selector: 'app-manage-financial-year',
  templateUrl: './manage-financial-year.component.html',
  styleUrls: ['./manage-financial-year.component.css']
})
export class ManageFinancialYearComponent implements OnInit 
{
  FinYearModel : FinancialYear= new FinancialYear();
  userClaims: any;
  FinancialYears: FinancialYear[] = [];
  FinYear:any;
  Result : string;
  SuccessStatus:boolean=false;
  SuccessMessage: string ="";
  public tableWidget: any;
  FinYearId : any;
  tempFinancialYearadd : FinancialYear =new FinancialYear();

  //check for duplicate code
  CheckCode : boolean=null;  
  checkststusedit : boolean =false;
  Codecheck : string ="";
  checkcodeval : string ="";
  //check for duplicate code
   
  // Begning Permission Portion    
  SelectedMenu:Menu;
  IsDataIsInViewState : boolean=false;
  CanViewState : boolean=false;
  validdate : boolean= false;
  DisableSave : boolean= false;
  // End of the permission portion

  constructor(private organizationService: OrganizationService, private rout: Router, 
  private _confirmation: ConfirmationService, private userService: UserService) { }

  //@ViewChild(DataTableDirective, {static: false})
  setDtOptions: DataTables.Settings = {};
  cardDtOptions: DataTables.Settings = {};
  dtElement: DataTableDirective;
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();

  ngOnInit() 
  {
    //Begin Permission Portion
    this.SelectedMenu=this.userService.SelectedMenu;
    if(this.SelectedMenu !=undefined)
    {
      localStorage.setItem('selectedmenu',JSON.stringify(this.SelectedMenu));
      this.rout.navigate([this.SelectedMenu.RouteName]);
    }
    else
    {
      if(JSON.parse(localStorage.getItem('selectedmenu'))!=undefined)
      {
        this.SelectedMenu = JSON.parse(localStorage.getItem('selectedmenu'));
        this.rout.navigate([this.SelectedMenu.RouteName]);
      }
    }     
    if(this.SelectedMenu.CanAdd)
    {
      this.IsDataIsInViewState=false;
    }
    else
    {
      this.IsDataIsInViewState=true;
    }
    if(this.SelectedMenu.CanView)
    {
      this.CanViewState=false;
    }
    else
    {
      this.CanViewState=true;
    }
    //End of the peremission portion
    this.DisableSave=true;
    this.FinYearModel.StatusId=0;
    this.dtOptions = 
    {
      pagingType: 'full_numbers',
      pageLength: 2
    };
    if(localStorage.getItem('SuccessfullMessage'))
    {
      this.SuccessStatus=true;
      this.SuccessMessage =localStorage.getItem('SuccessfullMessage')
      localStorage.removeItem('SuccessfullMessage');
    }
  }

  settings: ConfirmSettings | any = 
  {
    overlay: true,
    overlayClickToClose: true,
    showCloseButton: true,
    confirmText: 'Yes',
    declineText: 'No'
  };

 // begning of check duplicate code
  Checkcode()
  {
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    if(!this.checkststusedit)
    {
      this.organizationService.CheckFinancialYear( t, this.FinYearModel.FromDate, this.FinYearModel.ToDate).then(x => 
      {
        this.FinYear = x;
        if( this.FinYear.length >0)
        {
          this.CheckCode =false;
          this.Codecheck="Exist"
        }
        else
        {
          this.CheckCode =true;
          this.Codecheck="";
        }
     });
    }
    else
    {   
      if(this.FinYearModel.FinancialYear !=this.checkcodeval )
      {
        this.organizationService.CheckFinancialYear( t, this.FinYearModel.FromDate, this.FinYearModel.ToDate).then(x => 
        {
          this.FinYear = x;
          if( this.FinYear.length >0)
          {
            this.CheckCode =false;
            this.Codecheck="Exist"
          }
          else
          {
            this.CheckCode =true;
            this.Codecheck="";
          }
        });
      }
      else
      {
        this.Codecheck="";
        this.CheckCode =true;
      }
    }
  }

  // end of duplicate vettical code
  ngAfterViewInit(): void 
  {
    this.GetFinancialYear();
    this.dtOptions = 
    {
      pagingType: 'full_numbers',
      pageLength: 4
    };
  }

  GetFinancialYear()
  {
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.organizationService.GetFinancialYear( t).then(x => 
    {
      this.FinYear = x;
      Object.keys(this.FinYear).forEach( key => 
      {
        this.FinYear[key];
        let FinYeartemp = new FinancialYear();
        FinYeartemp = this.FinYear[key];
        this.FinancialYears.push(FinYeartemp);
      });
      this.dtTrigger.next();
    });
  }

  GetFinancialYearByid(id) 
  {
    // Begning of the permission portion
    this.IsDataIsInViewState=false;
    // End of the permision portion
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.organizationService.GetFinancialYearByid( t,id).then(x => 
    {
      this.FinYear = x;
      Object.keys(this.FinYear).forEach( key => 
      {
        this.FinYear[key];
        let FinYeartemp = new FinancialYear();
        FinYeartemp = this.FinYear[key];
        this.FinYearModel.FinancialyearId=FinYeartemp.FinancialyearId;
        this.FinYearModel.FinancialYear=FinYeartemp.FinancialYear;
        this.FinYearModel.Year=FinYeartemp.Year;
        this.FinYearModel.FromDate=FinYeartemp.FromDate;
        this.FinYearModel.ToDate= FinYeartemp.ToDate;
        this.FinYearModel.StatusId= FinYeartemp.StatusId;
        this.FinYearModel.Status= FinYeartemp.Status;
      });
      // check for duplicate vertical code 
      this.checkststusedit=true;
      this.checkcodeval = this.FinYearModel.FinancialYear;
      this.CheckCode=true;      
      this.Codecheck="";
      // check for duplicate vertical code 
    });
  }

  ViewFinancialYearByid(id) 
  {
    //begin permission
    this.IsDataIsInViewState=true;
    //end permission
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.organizationService.GetFinancialYearByid( t,id).then(x => 
    {
      this.FinYear = x;
      Object.keys(this.FinYear).forEach( key => 
      {
        this.FinYear[key];
        let FinYeartemp = new FinancialYear();
        FinYeartemp = this.FinYear[key];
        this.FinYearModel.FinancialyearId=FinYeartemp.FinancialyearId;
        this.FinYearModel.FinancialYear=FinYeartemp.FinancialYear;
        this.FinYearModel.Year=FinYeartemp.Year;
        this.FinYearModel.FromDate=FinYeartemp.FromDate;
        this.FinYearModel.ToDate= FinYeartemp.ToDate;
        this.FinYearModel.StatusId= FinYeartemp.StatusId;
        this.FinYearModel.Status= FinYeartemp.Status;
      });
      // check for duplicate vertical code 
      this.checkststusedit=true;
      this.checkcodeval = this.FinYearModel.FinancialYear;
      this.CheckCode=true;      
      this.Codecheck="";
      // check for duplicate vertical code 
    });
  }

  saveFinancialYear() :void
  {
    this.DisableSave=false;
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.FinYearModel.IsActive=true;
    if(this.FinYearModel.FinancialyearId > 0)
    {
      this.organizationService.UpdateFinancialYear(t ,this.FinYearModel).subscribe(res=>
      {
        this.FinYearId=res;
        this.FinYearModel.FinancialyearId=0;  
        if(this.FinYearId>0)
        {
          localStorage.setItem('SuccessfullMessage', "Update Successfully");
          this.rout.navigateByUrl('/Admin/ManageBankMaster', {skipLocationChange: true}).then(()=>this.rout.navigate(["/Admin/ManageFinancialYear"]));
        }
      });                  
    }
    else
    {
      this.organizationService.AddFinancialYear(t ,this.FinYearModel).subscribe(res=>
      {
        this.FinYearId=res;
        this.FinYearModel.FinancialyearId=0;              
        if(this.FinYearId>0)
        {
          localStorage.setItem('SuccessfullMessage', "Save Successfully");
          this.rout.navigateByUrl('/Admin/ManageBankMaster', {skipLocationChange: true}).then(()=>this.rout.navigate(["/Admin/ManageFinancialYear"]));
        }
      }); 
    }
  }

  DeleteFinancialYearByid(FinyearId : number) :void
  {
    this._confirmation.create('Confirm to Deleate', 'Press Yes to Delete', this.settings)
    .subscribe((ans: ResolveEmit) => 
    {
      if (ans.resolved == true)
      {
        let t =  localStorage.getItem('userToken');
        t = 'Bearer' + t;
        this.FinYearModel.IsActive=true;
        if( FinyearId > 0)
        {
          this.organizationService.DeleteFinancialYear(t ,FinyearId).subscribe(res=>
          {
            this. FinYearId=res;
            this.FinYearModel.FinancialyearId=0;                                  
            if(this.FinYearId >0)
            {
              localStorage.setItem('SuccessfullMessage', "Deleted Successfully");
              this.rout.navigateByUrl('/Admin/ManageBankMaster', {skipLocationChange: true}).then(()=>this.rout.navigate(["/Admin/ManageFinancialYear"]));
            }
          });                  
        }
      }
    })
  }

  FromDateChanged( date)
  {
    this.FinYearModel.FromDate=date;
    var CurrentDate = new Date();
    var CurrentYear = CurrentDate.getFullYear();
    var currentMonth = CurrentDate.getMonth();
    var CurrentDay = CurrentDate.getDate();
    
    var selecteedDate = new Date(date);
    var selectedYear=selecteedDate.getFullYear();
    var selectedMonth=selecteedDate.getMonth();
    var selectedDay=selecteedDate.getDate();

    if(CurrentYear > selectedYear )
    {
     this.validdate = false;      
    }
    else
     {
       if (currentMonth > selectedMonth)
       {
         this.validdate = false;
       }
       else
       {
         if(CurrentDay >= selectedDay )
         {
           this.validdate = false;
         }
         else
         {
           this.validdate = true;
         }
       }
     }
     if(this.FinYearModel.FromDate!=null && this.FinYearModel.ToDate!=null)
     {
       this.Checkcode();
     }
  }

  ToDateChanged( date)
  {
    this.FinYearModel.ToDate=date;
    var CurrentDate = new Date();
    var CurrentYear = CurrentDate.getFullYear();
    var currentMonth = CurrentDate.getMonth();
    var CurrentDay = CurrentDate.getDate();
    
    var selecteedDate = new Date(date);
    var selectedYear=selecteedDate.getFullYear();
    var selectedMonth=selecteedDate.getMonth();
    var selectedDay=selecteedDate.getDate();

    if(CurrentYear > selectedYear )
    {
     this.validdate = false;      
    }
    else
     {
       if (currentMonth > selectedMonth)
       {
         this.validdate = false;
       }
       else
       {
         if(CurrentDay >= selectedDay )
         {
           this.validdate = false;
         }
         else
         {
           this.validdate = true;
         }
       }
     }
     if(this.FinYearModel.FromDate!=null && this.FinYearModel.ToDate!=null)
     {
       this.Checkcode();
     }
  }

  RefreshPage()
  {
    this.rout.navigateByUrl('/Admin/ManageBankMaster', {skipLocationChange: true}).then(()=>this.rout.navigate(["/Admin/ManageFinancialYear"]))
  }
}
