export class DDTRLeaveReportModel
{
    public DirectId : number;
    public EmployeeId : string;
    public Name : string;
    public Date : Date;
    public AttendanceType : string;
    public LeaveType : string;
    public Status : string;
    public Reason : string;
    public Place : string;
    public ApplicationDate : Date;
    public ApproverRejecterName : string;
    public Remarks : string;
    public ApproveRejectDate : Date;
    public DayStatus : string;
    public Company : string;
    public Branch : string;
    public Department : string;
}