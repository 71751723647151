export class Model_CRM_State
    {
        
  
         
        public ZoneName : string;
        public StateName: string;
        public CountryName  :string;
        public ZoneId:  number;
        public CountryId: number;
        public StateId :number;  
        public IsActive :boolean;

    


        
    }       