<div class="body-content-area tabcontent" id="link1">
  <div class="row">
      <div class="col-lg-9">
          <div class="row">
              <div class="col-lg-1 low-padding text-center">
                  <!-- <img *ngIf="this.SelectedMenu.Image!=null" src={{this.SelectedMenu.Image}} class="body-icon" alt="Icon"/> -->
              </div>
              <div class="col-lg-10 clear-padding">
                  <span class="page-title">Approve/Reject Tour Expense Reimbursement Application</span>
                  <small class="page-description"></small>
              </div>
          </div>
      </div>
  </div>
  
  <div *ngIf="!(this.CanViewState)">
    <fieldset class="body-form-control">
      <legend class="legend--">Tour Expense Details</legend>
      
      <div *ngIf="NoRecordMsg" style="color: #dc3545; text-align: center;font-size: 15px; font-weight: bold;">No Record Found.</div>

      <table id="tblApplication" *ngIf="(this.ReimbursementApplications.length > 0)" class="table table-striped table-bordered text-center table-new row-border hover">
        <thead class="thead-blue text-center">
          <tr style=" width: 100%;">
            <th style=" width: 8%;">Sl No.</th>
            <th style=" width: 8%;">Code</th>
            <th style=" width: 18%;" class="text-left">Name</th>
            <th style=" width: 15%;" class="text-left">Expense Name</th>
            <th style=" width: 9%;">From Date</th>
            <th style=" width: 9%;">To Date</th>
            <th style=" width: 15%;" class="text-left">Tour Place</th>
            <th style=" width: 12%;" class="text-right">Final Approved Amount</th>
            <th style=" width: 12%;" class="text-right">Advance Amount</th>
            <th style=" width: 12%;" class="text-right">Balance Amount</th>
            <th style=" width: 6%;">Action</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let appl of ReimbursementApplications, let i = index">
            <td scope="row">{{i+1}}</td>
            <td>{{appl.EmployeeCode}}</td>
            <td class="text-left">{{appl.EmployeeName}}</td>
            <td class="text-left">{{appl.ExpenseName}}</td>
            <td class="text-center">{{appl.FromDate| date : "dd-MMM-yyyy"}}</td>
            <td class="text-center">{{appl.ToDate| date : "dd-MMM-yyyy"}}</td>
            <td class="text-left">{{appl.TourPlace}}</td>
            <td class="text-right">{{appl.ApproveFinalAmount}}</td>
            <td class="text-right">{{appl.AdvanceAmount}}</td>
            <td class="text-right">{{appl.BalanceAmount}}</td>
            <td>
              <button *ngIf="this.SelectedMenu.CanView" type="button" (click)="viewTourReimbursementDetails(appl.RefIdTR,template)" class="table-view-button" type="button" ><i class="fas fa-eye"></i></button>
            </td>
          </tr>
        </tbody>
        <tfoot class="thead-blue text-center">
          <tr>
            <th></th>
            <th></th>
            <th></th>
            <th></th>
            <th></th>
            <th></th>
            <th></th>
            <th></th>
            <th></th>
            <th></th>
            <th></th>
          </tr>
        </tfoot>
      </table>
    </fieldset>

    <div style=" box-align: center; width: 1500px;" >
      <ng-template #template>
        <div class="modal-header" style="background: #2a9bd7; max-width: 1500px;"> 
          <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()" style="color:#fff;">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div  style="text-align: center; max-width: 1500px;">
          <div *ngIf="ApprovedMsg" style="color: #dc3545; text-align: center;font-size: 15px; font-weight: bold;">Reibursement Application Approved Successfully</div>
          <div style=" width: 100%;" *ngIf="ShowPopup">
            <fieldset *ngIf="GridShow" class="body-form-control" style=" text-align:left;"> 
              <legend class="legend--">Tour Expense Approval</legend>
              <form #TourReibursementForm="ngForm">
                <div class="row"> 
                  <div class="col-lg-4">
                    <div class="col-body-border-right">
                      <div class="form-group row">
                        <label  class="col-sm-4 col-form-label clear-padding-right">Employee Code</label>
                        <div class="col-sm-7 clear-left-padding">
                          :&nbsp;&nbsp;{{this.ModelListApplication.EmployeeCode}}
                        </div>
                      </div>
                      <div class="form-group row">
                        <label class="col-sm-4 col-form-label clear-padding-right">Expense Name</label>
                        <div class="col-sm-7 clear-left-padding">
                          :&nbsp;&nbsp;{{this.ModelListApplication.ExpenseName}}
                        </div>
                      </div>  
                      <div class="form-group row">
                        <label  class="col-sm-4 col-form-label clear-padding-right">From Date</label>
                        <div class="col-sm-7 clear-left-padding">
                          :&nbsp;&nbsp;{{this.ModelListApplication.FromDate | date:'dd-MMM-yyyy'}}
                        </div>
                      </div>
                      <div class="form-group row">
                        <label  class="col-sm-4 col-form-label clear-padding-right">No Of Trip</label>
                        <div class="col-sm-7 clear-left-padding">
                          :&nbsp;&nbsp;{{this.ModelListApplication.NOofTrip}} 
                        </div>
                      </div> 
                      <div class="form-group row">
                        <label  class="col-sm-4 col-form-label clear-padding-right">RH Remarks</label>
                        <div class="col-sm-7 clear-left-padding">
                          :&nbsp;&nbsp;{{this.ModelListApplication.RHRemarks}} 
                        </div>
                      </div>      
                    </div>  
                  </div>
        
                  <div class="col-lg-4">
                    <div class="col-body-border-right">
                      <div class="form-group row">
                        <label  class="col-sm-4 col-form-label clear-padding-right">Name</label>
                        <div class="col-sm-7 clear-left-padding">
                          :&nbsp;&nbsp;{{this.ModelListApplication.EmployeeName}}
                        </div>
                      </div>
                      <div class="form-group row">
                        <label class="col-sm-4 col-form-label clear-padding-right">Tour Place</label>
                        <div class="col-sm-7 clear-left-padding">
                          :&nbsp;&nbsp;{{this.ModelListApplication.TourPlace}}
                        </div>
                      </div> 
                      <div class="form-group row">
                        <label  class="col-sm-4 col-form-label clear-padding-right">To Date</label>
                        <div class="col-sm-7 clear-left-padding">
                          :&nbsp;&nbsp;{{this.ModelListApplication.ToDate | date:'dd-MMM-yyyy'}}
                        </div>
                      </div>
                      <div class="form-group row">
                        <label  class="col-sm-4 col-form-label clear-padding-right">No of Days </label>
                        <div class="col-sm-7 clear-left-padding">
                          :&nbsp;&nbsp;{{this.ModelListApplication.NoOfDays}}
                        </div> 
                      </div> 
                      <div class="form-group row">
                        <label  class="col-sm-4 col-form-label clear-padding-right">HR Remarks</label>
                        <div class="col-sm-7 clear-left-padding">
                          :&nbsp;&nbsp;{{this.ModelListApplication.HRRemarks}} 
                        </div>
                      </div>
                    </div>
                  </div>
        
                  <div class="col-lg-4">
                    <!-- <div class="form-group row">
                      <label  class="col-sm-4 col-form-label clear-padding-right">Finance Head</label>
                      <div class="col-sm-7 clear-left-padding">
                        :&nbsp;&nbsp;{{this.ModelListApplication.FHName}} 
                      </div>
                    </div> -->
                    <div class="form-group row">
                      <label class="col-sm-4 col-form-label clear-padding-right">Advance Amount</label>
                      <div class="col-sm-7 clear-left-padding">
                        :&nbsp;&nbsp;{{this.ModelListApplication.AdvanceAmount}}
                      </div>
                    </div> 
                    <div class="form-group row">
                      <label class="col-sm-4 col-form-label clear-padding-right">Reporting</label>
                      <div class="col-sm-7 clear-left-padding">
                        :&nbsp;&nbsp;{{this.ModelListApplication.RHName}} 
                      </div>
                    </div>
                    <div class="form-group row">
                      <label  class="col-sm-4 col-form-label clear-padding-right">Advance Date</label>
                      <div class="col-sm-7 clear-left-padding">
                        :&nbsp;&nbsp;{{this.ModelListApplication.AdvancePaidDate | date:'dd-MMM-yyyy'}}
                      </div>
                    </div>
                    <div class="form-group row">
                      <label  class="col-sm-4 col-form-label clear-padding-right">No of Nights </label>
                      <div class="col-sm-7 clear-left-padding">
                        :&nbsp;&nbsp;{{this.ModelListApplication.NoOfNights}}
                      </div> 
                    </div>
                    <div class="form-group row">
                      <label  class="col-sm-4 col-form-label clear-padding-right">Finance Remarks</label>
                      <div class="col-sm-7 clear-left-padding">
                        :&nbsp;&nbsp;{{this.ModelListApplication.FHRemarks}} 
                      </div>
                    </div>
                  </div> 
                </div> 
        
                <div lass="row" >
                  <table id="tblExpense" class="table table-striped table-bordered text-left table-new row-border hover" style="width: 100%;">
                    <thead class="thead-blue text-center"s tyle="width: 100%;">
                      <tr>
                        <th style=" text-align: center;">Expense Head</th>
                        <th style=" text-align: right;">Budget Amount</th> 
                        <th style=" text-align: right;">Expected Expense Amount</th> 
                        <th style=" text-align: right;">Approved Expense Amount</th> 
                        <th style=" text-align: right;">Final Expense Amount</th>
                        <th style=" text-align: right;">Approved Final Expense</th>
                        <th style=" text-align: left;">Uploaded File</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let TEH of TourExpenseHeads, let i = index">
                        <td>
                          {{TEH.ExpenseHeadName}}
                          <input type="text" hidden="hidden" [(ngModel)]="TEH.RefIdTRD" name="RefIdTRD_{{i}}" #RefIdTRD="ngModel">
                        </td>
                        <td style=" text-align: right;">{{TEH.BudgetAmount}}</td>
                        <td style=" text-align: right;">{{TEH.ExpenseAmount}}</td>
                        <td style=" text-align: right;">{{TEH.ApprovedExpenseAmount}}</td>
                        <td style=" text-align: right;">{{TEH.FinalExpenseAmount}}</td>
                        <td style=" text-align: right;">{{TEH.FinalApprovedAmount}}</td>
                        <td>
                          <tr *ngFor="let fil of TEH.ReimbursementFileUploadModel">
                            <td>
                              <label style=" color:blue; text-decoration: underline;" class="col-sm-4 col-form-label clear-padding-left" (click)="download(fil.FileName)">{{fil.FileName}}</label>
                            </td>
                          </tr>
                        </td>
                      </tr>
                    </tbody>
                    <tfoot class="thead-blue text-center">
                      <tr>
                        <th></th>
                        <th style=" text-align: right;">Total Expense Amount</th>
                        <th style=" text-align: right;">: &nbsp;&nbsp;{{this.ModelListApplication.ExpectedExpenseAmount}}</th>
                        <th style=" text-align: right;">: &nbsp;&nbsp;{{this.ModelListApplication.ApprovedExpenseAmount}}</th>  
                        <th style=" text-align: right;">: &nbsp;&nbsp;{{this.ModelListApplication.FinalExpecetdAmount}}</th>  
                        <th style=" text-align: right;">: &nbsp;&nbsp;{{this.ModelListApplication.ApproveFinalAmount}}</th>  
                        <th style=" text-align: left;"></th>  
                      </tr>
                    </tfoot>
                  </table>
                </div>
        
                <div class="row">
                  <div class="col-lg-4">
                    <div class="col-body-border-right">
                      <div class="form-group row">
                        <label  class="col-sm-4 col-form-label clear-padding-right">Employee Remarks</label>
                        <div class="col-sm-7 clear-left-padding">
                          : &nbsp;&nbsp;{{ModelListApplication.FinalRemarks}}
                        </div>
                      </div>
                      <div class="form-group row">
                        <label  class="col-sm-4 col-form-label clear-padding-right">HR Remarks</label>
                        <div class="col-sm-7 clear-left-padding">
                          : &nbsp;&nbsp;{{ModelListApplication.FinalHRRemarks}}
                        </div>
                      </div>
                    </div> 
                  </div>
                  <div class="col-lg-4">
                    <div class="col-body-border-right">
                      <div class="form-group row">
                        <label  class="col-sm-4 col-form-label clear-padding-right">Balance Amount</label>
                        <div class="col-sm-7 clear-left-padding">
                          : &nbsp;&nbsp;{{ModelListApplication.BalanceAmount}}
                        </div>
                      </div>
                      <div class="form-group row">
                        <label  class="col-sm-4 col-form-label clear-padding-right">Paid Date</label>
                        <div class="col-sm-7 clear-left-padding">
                          <input type="date" style="width:90%;" name="BalancePaidDate" [ngModel]="this.ModelListApplication.BalancePaidDate | date:'yyyy-MM-dd'" (ngModelChange)="BalancePaidDateChanged($event)" #BalancePaidDate="ngModel" required/>
                        </div>
                        <!-- <div class="required-validation" *ngIf="validAdvancePaidDate"> Date is not Valid Date.</div>
                        <div class="required-validation" *ngIf="validAdvancePaidDate">Please select Valid Date.</div> -->
                      </div>
                      <div class="form-group row">
                        <!-- <label  class="col-sm-4 col-form-label clear-padding-right">Intelligence Report</label> -->
                        <div class="col-sm-7 clear-left-padding">
                          <label style=" color:blue; text-decoration: underline;" class="col-sm-4 col-form-label clear-padding-right" (click)="download(this.ModelListApplication.FileName)">{{this.ModelListApplication.FileName}}</label>
                        </div>
                      </div>
                    </div> 
                  </div>
          
                  <div class="col-lg-4">
                    <div class="col-body-border-right">
                      <div class="form-group row">
                        <label  class="col-sm-4 col-form-label clear-padding-right">Remarks</label>
                        <div class="col-sm-7 clear-left-padding">
                          <input type="text" [(ngModel)]="ModelListApplication.FinalFinanceRemarks" maxlength="500" id ="FinalFinanceRemarks" name="FinalFinanceRemarks" #FinalFinanceRemarks="ngModel" required class="form-control form-control-custom form-control-custom-new" >
                        </div>
                      </div>
                      <div class="form-group row">
                        <div *ngIf="!(this.CanApproveState)" class="col-sm-6 col-form-label text-center">
                          <button type="submit" (click)="UpdateTourReimbursementMasterForFinanceFinalSubmit(template)" class="btn btn-primary save-button" [disabled]="!(TourReibursementForm.valid && this.DisableSave==true)">Approved</button>  
                        </div>
                      </div>
                    </div> 
                  </div>
                </div>
              </form>
            </fieldset>
          </div>

          <button type="button" aria-label="Close" (click)="modalRef.hide()" style="background: #2a9bd7; outline: 0px none; border: 0px none; color: #fff; padding: 5px 10px; border-radius: 3px;">
            <span>Close</span>
          </button>             
        </div>
      </ng-template>
    </div>

  </div>
</div>