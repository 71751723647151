export class ResignApplicationModel
{
    public RefId : number;
    public EmployeeId : number;
    public EmployeeCode : string;
    public EmployeeName : string;
    public BranchName : string;
    public DepartmentName : string;
    public DesignationName : string;
    public JoiningDate : Date;
    public EmployeeEmailId : string;
    public RHId : number;
    public RHCode : string;
    public RHName : string;
    public RHEmailId : string;
    public FHId : number;
    public FHCode : string;
    public FHName : string;
    public FHEmailId : string;
    public HRNames : string;
    public HRIds : string;
    public HREmailIds : string;
    public NoticePeriod : number;
    public RequestNoticePeriod : number;
    public PersonalEmailId : string;
    public ResignReasons : string;
    public RHRemarks : string;
    public RHApproved : boolean;
    public RHRejected : boolean;
    public FHRemarks  : string;
    public FHApproved : boolean;
    public FHRejected : boolean;
    public HRRemarks  : string;
    public HRApproved : boolean;
    public HRRejected : boolean;
    public ApplicationDate : Date;
    public ResignNoticeDate : Date;
    public RelivingDate : Date;
    public CompanyId : number;
    public SentMailid : string;
    public SentMailPassword : string;
    public HRISURL : string;
    public ApplicationExist : boolean;
}