<div class="body-content-area tabcontent" id="link1">
    <div class="row">
        <div class="col-lg-9">
            <div class="row">
                <div class="col-lg-1 low-padding text-center">
                    <img *ngIf="this.SelectedMenu.Image!=null" src={{this.SelectedMenu.Image}} class="body-icon"
                        alt="Icon" />
                </div>
                <div class="col-lg-10 clear-padding">
                    <span class="page-title">Main Menu Permission Module</span>
                    <small class="page-description"></small>
                </div>
            </div>
        </div>
    </div>

    <div *ngIf="!(this.CanViewState)">
        <fieldset class="body-form-control">
            <app-dialog [(visible)]="this.SuccessStatus">
                <span class="successmsg">{{this.SuccessMessage}}</span>
            </app-dialog>

            <legend class="legend--">Role Permission Modification</legend>
            <form #MenuUserMapping="ngForm" (ngSubmit)="UpdateMenuMapping()">
                <div class="row">
                    <div class="col-lg-4">
                        <div class="col-body-border-right">
                            <div class="form-group row">
                                <label class="col-sm-4 col-form-label clear-padding-right"> Role</label>
                                <div class="col-sm-7 clear-left-padding">
                                    <select [(ngModel)]="MenuUserMappingModel.RoleId" tabindex="1"
                                        (ngModelChange)="onRolechange($event)" id="RoleId" class="form-control"
                                        name="RoleId" #RoleId="ngModel">
                                        <option [value]="0" [disabled]=true>Select Role</option>
                                        <option *ngFor="let Role of RoleMasters" [value]="Role.RoleId">
                                            {{Role.RoleName}}</option>
                                    </select>
                                </div>
                                <div class="required-validation" *ngIf="MenuUserMappingModel.RoleId==0">Role is
                                    required</div>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-4">
                        <div class="form-group row">
                            <label class="col-sm-4 col-form-label clear-padding-right"> Module</label>
                            <div class="col-sm-7 clear-left-padding">
                                <select [(ngModel)]="MenuUserMappingModel.ModuleId" tabindex="1"
                                    (ngModelChange)="onModulechange($event)" id="ModuleId" class="form-control"
                                    name="ModuleId" #ModuleId="ngModel">
                                    <option [value]="0" [disabled]=true>Select Module</option>
                                    <option *ngFor="let Module of ModuleMasters" [value]="Module.ModuleId">
                                        {{Module.ModuleName}}</option>
                                </select>
                            </div>
                            <div class="required-validation" *ngIf="MenuUserMappingModel.ModuleId==0">Module is
                                required</div>
                        </div>
                    </div>
                </div>
                <div *ngIf="MenuUserMappingmains.length > 0">
                    <table id="tblComponent"
                        class="table table-striped table-bordered text-left table-new row-border hover">
                        <thead class="thead-blue text-center">
                            <tr>
                                <th>Sl No.</th>
                                <th>Menu</th>
                                <th>Desktop Access</th>
                                <th>Mobile Access</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let mum of MenuUserMappingmains, let i = index">
                                <td scope="row">{{i+1}}</td>
                                <td>
                                    <span>{{mum.MenuName}}</span>
                                    <input type="text" hidden="hidden" [(ngModel)]="mum.RefId" id="RefId"
                                        name="RefId" #RefId="ngModel">
                                </td>
                                <td>
                                    <!-- [value]="CanView" -->
                                    <input type="checkbox" (click)="OnChangeCanView($event,i)"
                                        [checked]="MenuUserMappingmains[i].valueView=='CanView'" id="CanView_{{i}}"
                                        name="CanView_{{i}}" class="form-control form-control-custom form-control-custom-new">
                                </td>
                                <td>
                                    <!-- [value]="MobileView" -->
                                    <input type="checkbox" (click)="OnChangeMobileView($event,i)"
                                        [checked]="MenuUserMappingmains[i].valueMobileView=='MobileView'" id="MobileView_{{i}}"
                                        name="MobileView_{{i}}" class="form-control form-control-custom form-control-custom-new">
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="row">
                    <div class="col-lg-4">

                    </div>
                    <div class="col-lg-4">

                    </div>
                    <div class="col-lg-4" *ngIf="MenuUserMappingmains.length > 0">
                        <div class="form-group row">
                            <label class="col-sm-6 col-form-label text-center clear-padding-right"
                                *ngIf="!(this.IsDataIsInViewState)">
                                <button type="submit" tabindex="4"
                                    [disabled]="!(MenuUserMapping.valid  && this.DisableSave==true)"
                                    class="btn btn-primary save-button">Save</button>
                            </label>
                            <div class="col-sm-6 col-form-label text-center">
                                <button type="button" tabindex="5" class="btn btn-danger cancle-button"
                                    (click)="RefreshPage()">Cancel</button></div>
                        </div>
                    </div>
                </div>
            </form>
        </fieldset>
    </div>
</div>