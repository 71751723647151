/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./confirmation-dialog.component";
import * as i2 from "@ng-bootstrap/ng-bootstrap";
var styles_ConfirmationDialogComponent = [];
var RenderType_ConfirmationDialogComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_ConfirmationDialogComponent, data: {} });
export { RenderType_ConfirmationDialogComponent as RenderType_ConfirmationDialogComponent };
export function View_ConfirmationDialogComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 5, "div", [["class", "modal-header"], ["style", "background: #2a9bd7;"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "h4", [["class", "modal-title"], ["style", "color: #fff !important;"]], null, null, null, null, null)), (_l()(), i0.ɵted(2, null, ["", ""])), (_l()(), i0.ɵeld(3, 0, null, null, 2, "button", [["aria-label", "Close"], ["class", "close"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.dismiss() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(4, 0, null, null, 1, "span", [["aria-hidden", "true"], ["style", "color: #fff !important;"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\u00D7"])), (_l()(), i0.ɵeld(6, 0, null, null, 1, "div", [["class", "modal-body"]], null, null, null, null, null)), (_l()(), i0.ɵted(7, null, [" ", "\n"])), (_l()(), i0.ɵeld(8, 0, null, null, 4, "div", [["class", "modal-footer"]], null, null, null, null, null)), (_l()(), i0.ɵeld(9, 0, null, null, 1, "button", [["class", "btn btn-danger"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.decline() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(10, null, ["", ""])), (_l()(), i0.ɵeld(11, 0, null, null, 1, "button", [["class", "btn btn-primary"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.accept() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(12, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.title; _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.message; _ck(_v, 7, 0, currVal_1); var currVal_2 = _co.btnCancelText; _ck(_v, 10, 0, currVal_2); var currVal_3 = _co.btnOkText; _ck(_v, 12, 0, currVal_3); }); }
export function View_ConfirmationDialogComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-confirmation-dialog", [], null, null, null, View_ConfirmationDialogComponent_0, RenderType_ConfirmationDialogComponent)), i0.ɵdid(1, 114688, null, 0, i1.ConfirmationDialogComponent, [i2.NgbActiveModal], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ConfirmationDialogComponentNgFactory = i0.ɵccf("app-confirmation-dialog", i1.ConfirmationDialogComponent, View_ConfirmationDialogComponent_Host_0, { title: "title", message: "message", btnOkText: "btnOkText", btnCancelText: "btnCancelText" }, {}, []);
export { ConfirmationDialogComponentNgFactory as ConfirmationDialogComponentNgFactory };
