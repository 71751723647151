<div class="top-header top-header-area-for-inner-dashboard">
    <div class="row">
        <div class="col-lg-1">
            <a class="menu-icon"><i class="fas fa-align-justify fa-lg"></i></a>
        </div>
        <div class="offset-lg-6 col-lg-3">
            <div class="text-right header-text">
                <span>
                    Last Login Time: {{UserModel.LastLoginTime | date : "dd-MMM-yyyy hh:mm:ss a"}}
                </span>
                <span>/</span>
                <span> IP: {{UserModel.IPAddress}}</span>
            </div>
        </div>
        <div class="col-lg-2">
            <div class="text-center">
                <button class="header-icons notification-number " title={{TotalNoofLeaveNotifi}}
                    (click)="Approvalpage()">
                    <i class="fas fa-bell"></i>
                    {{TotalNoofLeaveNotifi}}
                </button>
                <span class="header-icons"><i class="fas fa-comments"></i></span>
                <button class="header-icons power_off" title="Logout" (click)="Logout()"><i
                        class="fas fa-power-off"></i></button>
            </div>
        </div>
    </div>
</div>
<div class="container-fluid">
    <div class="row">
        <div class="col-lg-auto col-lg-2-5 clear-padding" style=" width: 15%;">
            <div class="side-nevigation area-one">
                <div class="logo-area logo-area-others">
                    <img *ngIf="this.UserModel.CompIconUrlImage!=null" [src]="UserModel.CompIconUrlImage" alt="Logo"
                        (click)="OnDashboard()" class="logo logo-others">
                </div>
                <div class="row user-area">
                    <div class="col-lg-4">
                        <div class="user-image">
                            <img *ngIf="this.UserModel.IconUrlImage!=null" [src]="UserModel.IconUrlImage"
                                class="img-fluid" style="height: 100%;">
                        </div>
                    </div>
                    <div class="col-lg-6 low-clear-padding">
                        <div class="Username-area">
                            <span class="username">{{UserModel.EmployeeName}}</span>
                            <span class="userdesignation">{{UserModel.DesignationName}}</span>
                        </div>
                    </div>
                    <div class="col-lg-2 clear-padding">
                        <div class="logborder">
                            {{UserModel.CurrentTime}}
                        </div>
                    </div>
                </div>
                <perfect-scrollbar style="max-width: 240px; max-height: 480px;">
                    <div class="sidebar-nav-area">
                        <ul id="sidebarmenu" class="tab">
                            <li [ngClass]="{ 'menustyle tablinks': true, 'current': selectedIndex == i }"
                                *ngFor="let menu of SubMenuesCRM; let i=index"
                                (click)="LoadComponents(menu.RouteName, i,menu.MenuId )">
                                <img src={{menu.SmallImage}} class="menu-icons" alt=""> {{menu.MenuTitle}}
                            </li>
                        </ul>
                    </div>
                </perfect-scrollbar>
            </div>
        </div>
        <div class="col col-lg-9-5 clear-padding" style=" width: 85%;">
            <div class="main-content-area">
                <div class="body-nav-area " id="container">
                    <div id="container" [class.disabled]="disableFlag">

                        <!-- <div ng2-carouselamos class="slides-wrapper" [items]="MainMenuCRM" [width]="1030" [$prev]="prev"
                            [$next]="next" [$item]="itemTemplate"
                            (onSelectedItem)="selectedItem = $event.item; selectedIndex = $event.index">
                            
                        </div> -->

                        <div ng2-carouselamos class="slides-wrapper" [items]="MainMenuCRM" [width]="1030" [$prev]="prev"
                            [$next]="next" [$item]="itemTemplate">

                        </div>
                        <ng-template #prev>
                            <img src="assets/img/Left_arrow.png" id="left" style="width:35px;">
                        </ng-template>
                        <ng-template #next>
                            <img src="assets/img/Right_arrow.png" id="right" style="width:35px;">
                        </ng-template>




                        <ul class="body-nav-ul owl-carousel">



                            <ng-template #itemTemplate let-item let-i="index" ; let j="index">


                                <li *ngIf="i== this.commonmenu.SelectedMainMenu"
                                    [ngClass]="{ 'menu-item-li ': true,  'active-menu-item': this.commonmenu.SelectedMainMenu == i }"
                                    (click)="MainPages( item.MenuId,item.ModuleId,item.RouteName ,i)">
                                    <div class="menu-slider-icons">
                                        <img src={{item.SmallImage}} alt="Image1" class="icon-size">
                                    </div>

                                    <span class="menu-item-text">{{item.MenuTitle}}</span>

                                </li>

                                <li *ngIf="i != this.commonmenu.SelectedMainMenu"
                                    [ngClass]="{ 'menu-item-li ': true   }"
                                    (click)="MainPages( item.MenuId,item.ModuleId,item.RouteName ,i)">
                                    <div class="menu-slider-icons">
                                        <img src={{item.SmallImage}} alt="Image1" class="icon-size">
                                    </div>

                                    <span class="menu-item-text">{{item.MenuTitle}}</span>
                                </li>
                            </ng-template>

                        </ul>


                    </div>


                </div>
                <router-outlet> </router-outlet>
            </div>
        </div>
    </div>
</div>