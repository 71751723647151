<div class="body-content-area tabcontent" id="link1">
  <div class="row">
    <div class="col-lg-9">
      <div class="row">
        <div class="col-lg-1 low-padding text-center">
          <!-- <img *ngIf="this.SelectedMenu.Image!=null" src={{this.SelectedMenu.Image}} class="body-icon" alt="Icon"/> -->
        </div>
        <div class="col-lg-10 clear-padding">
          <div>
            <span class="page-title">Employee Tour Reimbursement Status</span>
            <small class="page-description"></small>
          </div>
        </div>
      </div>
    </div>
  </div>
    
  <div class="row">
    <div class="col-lg-4">
      <div class="col-body-border-right">
        <div class="form-group row">
          <label class="col-sm-4 col-form-label clear-padding-right">Emp code</label>
          <div class="col-sm-7 clear-left-padding">
            :&nbsp;&nbsp;{{this.TourReimbursementMasterModel.EmployeeCode}} 
          </div>
        </div>
      </div>
    </div>
    <div class="col-lg-4">
      <div class="form-group row">
        <label class="col-sm-4 col-form-label clear-padding-right">Name</label>
        <div class="col-sm-7 clear-left-padding">
          :&nbsp;&nbsp;{{this.TourReimbursementMasterModel.EmployeeName}}
        </div>
      </div>
    </div>
  </div>
  <fieldset class="body-form-control" *ngIf="ApplicationStatus.length > 0"> 
    <legend class="legend--">Employee Tour Reimbursement</legend>
      <form #EmployeeExpense="ngForm">
        <div lass="row" >
          <table id="tblExpense" class="table table-striped table-bordered text-center table-new row-border hover">
            <thead class="thead-blue text-center">
              <tr>
                <th style="width: 8%">Sl.No.</th>
                <th class="text-left" style="width: 12%">ExpenseName</th>
                <th style="width: 10%">From Date</th>
                <th style="width: 10%">To Date</th>
                <th style="width: 12%" class="text-left">Tour Place</th>
                <th style="width: 10%" class="text-left">Expected Expense</th>
                <th style="width: 10%" class="text-left">Approved Amount</th>
                <th style="width: 10%" class="text-left">Advance Amount</th>
                <th style="width: 10%" class="text-left">Status</th>
                <th style="width: 8%">Action</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let app of ApplicationStatus, let i = index">
                <td scope="row">{{i+1}}</td>
                <td class="text-left">{{app.ExpenseName}}</td>
                <td>{{app.FromDate | date : "dd-MMM-yyyy"}}</td>
                <td>{{app.ToDate | date : "dd-MMM-yyyy"}}</td>
                <td class="text-left">{{app.TourPlace}}</td>
                <td class="text-right">{{app.ExpectedExpenseAmount}}</td>
                <td class="text-right">{{app.ApprovedExpenseAmount}}</td>
                <td class="text-right">{{app.AdvanceAmount}}</td>
                <td class="text-left">{{app.ApplicationStatus}}</td>
                <td>
                  <button *ngIf="(app.FHApproved == true && app.FinalSubmit == false)" type="button" (click)="viewTourReimbursementDetails(app.RefIdTR,template)" class="table-view-button" type="button" ><i class="fas fa-eye"></i></button>
                </td>
              </tr>
            </tbody>
            <tfoot class="thead-blue text-center">
              <tr>
                <th></th>
                <th></th>
                <th></th>
                <th></th>
                <th></th>
                <th></th>
                <th></th>
                <th></th>
                <th></th>
                <th></th>
              </tr>
            </tfoot>
          </table>
        </div>
      </form>
  </fieldset>

  <div style=" box-align: center; width: 1500px;" >
    <ng-template #template>
      <div class="modal-header" style="background: #2a9bd7; max-width: 1500px;"> 
        <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()" style="color:#fff;">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div  style="text-align: center; max-width: 1500px;">
        <div *ngIf="ShowMsg" style="color: #dc3545; text-align: center;font-size: 15px; font-weight: bold;">Reimbursement Application Farward to HR Department Successfully</div>
        <div style=" width: 100%;" *ngIf="ShowPopup">
          <fieldset class="body-form-control" style=" text-align:left;"> 
            <legend class="legend--">Tour Expense Approval</legend>
            <form #TourReibursementForm="ngForm">
              <div class="row"> 
                <div class="col-lg-4">
                  <div class="col-body-border-right">
                    <div class="form-group row">
                      <label  class="col-sm-4 col-form-label clear-padding-right">Employee Code</label>
                      <div class="col-sm-7 clear-left-padding">
                        :&nbsp;&nbsp;{{this.ModelListApplication.EmployeeCode}}
                      </div>
                    </div>
                    <div class="form-group row">
                      <label class="col-sm-4 col-form-label clear-padding-right">Expense Name</label>
                      <div class="col-sm-7 clear-left-padding">
                        :&nbsp;&nbsp;{{this.ModelListApplication.ExpenseName}}
                      </div>
                    </div>  
                    <div class="form-group row">
                      <label  class="col-sm-4 col-form-label clear-padding-right">From Date</label>
                      <div class="col-sm-7 clear-left-padding">
                        :&nbsp;&nbsp;{{this.ModelListApplication.FromDate | date:'dd-MMM-yyyy'}}
                      </div>
                    </div>
                    <div class="form-group row">
                      <label  class="col-sm-4 col-form-label clear-padding-right">No Of Trip</label>
                      <div class="col-sm-7 clear-left-padding">
                        :&nbsp;&nbsp;{{this.ModelListApplication.NOofTrip}} 
                      </div>
                    </div>
                    <div class="form-group row">
                      <label  class="col-sm-4 col-form-label clear-padding-right">Reporting Remarks</label>
                      <div class="col-sm-7 clear-left-padding">
                        :&nbsp;&nbsp;{{this.ModelListApplication.RHRemarks}} 
                      </div>
                    </div>        
                  </div>  
                </div>
      
                <div class="col-lg-4">
                  <div class="col-body-border-right">
                    <div class="form-group row">
                      <label  class="col-sm-4 col-form-label clear-padding-right">Name</label>
                      <div class="col-sm-7 clear-left-padding">
                        :&nbsp;&nbsp;{{this.ModelListApplication.EmployeeName}}
                      </div>
                    </div>
                    <div class="form-group row">
                      <label class="col-sm-4 col-form-label clear-padding-right">Tour Place</label>
                      <div class="col-sm-7 clear-left-padding">
                        :&nbsp;&nbsp;{{this.ModelListApplication.TourPlace}}
                      </div>
                    </div> 
                    <div class="form-group row">
                      <label  class="col-sm-4 col-form-label clear-padding-right">To Date</label>
                      <div class="col-sm-7 clear-left-padding">
                        :&nbsp;&nbsp;{{this.ModelListApplication.ToDate | date:'dd-MMM-yyyy'}}
                      </div>
                    </div>
                    <div class="form-group row">
                      <label  class="col-sm-4 col-form-label clear-padding-right">No of Days </label>
                      <div class="col-sm-7 clear-left-padding">
                        :&nbsp;&nbsp;{{this.ModelListApplication.NoOfDays}}
                      </div> 
                    </div> 
                    <div class="form-group row">
                      <label  class="col-sm-4 col-form-label clear-padding-right">HR Remarks</label>
                      <div class="col-sm-7 clear-left-padding">
                        :&nbsp;&nbsp;{{this.ModelListApplication.HRRemarks}} 
                      </div>
                    </div> 
                  </div>
                </div>
      
                <div class="col-lg-4">
                  <!-- <div class="form-group row">
                    <label  class="col-sm-4 col-form-label clear-padding-right">Finance Head</label>
                    <div class="col-sm-7 clear-left-padding">
                      :&nbsp;&nbsp;{{this.ModelListApplication.FHName}} 
                    </div>
                  </div> -->
                  <div class="form-group row">
                    <label class="col-sm-4 col-form-label clear-padding-right">Available Amount</label>
                    <div class="col-sm-7 clear-left-padding">
                      :&nbsp;&nbsp;{{this.ModelListApplication.NetAvailableAmount + this.ModelListApplication.ApprovedExpenseAmount}}
                    </div>
                  </div>
                  <div class="form-group row">
                    <label class="col-sm-4 col-form-label clear-padding-right">Advance Amount</label>
                    <div class="col-sm-7 clear-left-padding">
                      :&nbsp;&nbsp;{{this.ModelListApplication.AdvanceAmount}}
                    </div>
                  </div>
                  <div class="form-group row">
                    <label  class="col-sm-4 col-form-label clear-padding-right">Advance Date</label>
                    <div class="col-sm-7 clear-left-padding">
                      :&nbsp;&nbsp;{{this.ModelListApplication.AdvancePaidDate | date:'dd-MMM-yyyy'}}
                    </div>
                  </div>
                  <div class="form-group row">
                    <label  class="col-sm-4 col-form-label clear-padding-right">No of Nights </label>
                    <div class="col-sm-7 clear-left-padding">
                      :&nbsp;&nbsp;{{this.ModelListApplication.NoOfNights}}
                    </div> 
                  </div> 
                  <div class="form-group row">
                    <label  class="col-sm-4 col-form-label clear-padding-right">Finance Remarks</label>
                    <div class="col-sm-7 clear-left-padding">
                      :&nbsp;&nbsp;{{this.ModelListApplication.FHRemarks}} 
                    </div>
                  </div> 
                </div> 
              </div> 
      
              <div lass="row" >
                <table id="tblExpense" class="table table-striped table-bordered text-left table-new row-border hover" style="width: 100%;">
                  <thead class="thead-blue text-center"s tyle="width: 100%;">
                    <tr>
                      <th style=" text-align: center;">Expense Head</th>
                      <th style=" text-align: left;">Budget Amount</th> 
                      <th style=" text-align: left;">Expected Expense Amount</th> 
                      <th style=" text-align: left;">Approved Expense Amount</th> 
                      <th style=" text-align: left;">Final Expense Amount</th>
                      <th style=" text-align: left;">File Upload</th> 
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let TEH of TourExpenseHeads, let i = index">
                      <td>
                        {{TEH.ExpenseHeadName}}
                        <input type="text" hidden="hidden" [(ngModel)]="TEH.RefIdTRD" name="RefIdTRD_{{i}}" #RefIdTRD="ngModel">
                        <input type="text" hidden="hidden" [(ngModel)]="TEH.ExpenseHeadId" name="ExpenseHeadId_{{i}}" #ExpenseHeadId="ngModel">
                      </td>
                      <td style=" text-align: right;">{{TEH.BudgetAmount}}</td>
                      <td style=" text-align: right;">{{TEH.ExpenseAmount}}</td>
                      <td style=" text-align: right;">{{TEH.ApprovedExpenseAmount}}</td>
                      <td>
                        <input type="text" [(ngModel)]="TEH.FinalExpenseAmount" name="FinalExpenseAmount_{{i}}" #FinalExpenseAmount="ngModel" pattern="[0-9]+" (ngModelChange)="FinalExpenseAmountChanged()" class="form-control form-control-custom form-control-custom-new" required>
                        <div *ngIf="FinalExpenseAmount.invalid && (FinalExpenseAmount.dirty || FinalExpenseAmount.touched)" class="required-validation">
                          <div *ngIf="FinalExpenseAmount.errors?.pattern">Amount should be valid.</div>
                        </div>
                      </td>
                      <td>
                        <input type="file" name="fileuploads_{{i}}" (change)="fileuploads($event,TEH.RefIdTRD,TEH.ExpenseHeadId)" multiple class="form-control" style="height:100%">
                        <table class="table table-bordered">
                          <thead class="thead.dark">
                            <th>Name</th>
                            <th>Action</th>
                          </thead>
                          <tbody>
                            <tr *ngFor="let image of images let i = index" [attr.data-index]="i">
                              <!-- | paginate: { itemsPerPage: 5, currentPage: p } -->
                              <td *ngIf="image.ExpenseHeadId==TEH.ExpenseHeadId">{{image.name}}</td>
                              <td *ngIf="image.ExpenseHeadId==TEH.ExpenseHeadId" ><a href="javascript:void(0)" (click)="deleteImage(image)" ><i class="fa fa-trash"></i></a></td>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                    </tr>
                  </tbody>
                  <tfoot class="thead-blue text-center">
                    <tr>
                      <th></th>
                      <th style=" text-align: right;">Total Expense Amount</th>
                      <th style=" text-align: right;">: &nbsp;&nbsp;{{this.ModelListApplication.ExpectedExpenseAmount}}</th>
                      <th style=" text-align: right;">: &nbsp;&nbsp;{{this.ModelListApplication.ApprovedExpenseAmount}}</th>  
                      <th style=" text-align: left;">: &nbsp;&nbsp;{{this.ModelListApplication.FinalExpecetdAmount}}</th>  
                      <th style=" text-align: left;"></th>  
                    </tr>
                  </tfoot>
                </table>
              </div>
      
              <div class="row">
                <div class="col-lg-4">
                  <div class="col-body-border-right">
                    <div class="form-group row">
                      <label  class="col-sm-4 col-form-label clear-padding-right">Remarks</label>
                      <div class="col-sm-7 clear-left-padding">
                        <input type="text" [(ngModel)]="ModelListApplication.FinalRemarks" maxlength="500" id ="FinalRemarks" name="FinalRemarks" #FinalRemarks="ngModel" required class="form-control form-control-custom form-control-custom-new" >
                      </div>
                    </div>
                  </div> 
                </div>
        
                <div class="col-lg-4">
                  <div class="col-body-border-right">
                    <div class="form-group row">
                      <label class="col-sm-4 col-form-label clear-padding-right">Intelligence Report</label>
                      <div class="col-sm-7 clear-left-padding">{{ModelListApplication.FileName}}
                        <input type="file" name="IntelligenceReport" (change)="IntelligenceReportChange($event)" class="form-control" style="height:100%" required>
                      </div>
                    </div>
                  </div> 
                </div>
                <div class="col-lg-4">
                  <div class="col-body-border-right">
                    <div class="form-group row">
                      <label  class="col-sm-4 col-form-label clear-padding-right">INTELLIGENCE Report</label>
                      <div class="col-sm-7 clear-left-padding">
                        <label style=" color:blue; text-decoration: underline;" class="col-sm-4 col-form-label clear-padding-right" (click)="download()">MARKET VISIT REPORT Blank</label>
                      </div>
                    </div>
                    <div class="form-group row">
                      <div class="col-sm-6 col-form-label text-center">
                        <button type="submit" (click)="ReSubmitTourReimbursement(template)" class="btn btn-primary save-button" [disabled]="!(TourReibursementForm.valid && this.DisableSave==true && ModelListApplication.FileName != null && ((this.ModelListApplication.NetAvailableAmount + this.ModelListApplication.ApprovedExpenseAmount) - this.ModelListApplication.FinalExpecetdAmount)>=0)">Final Submit</button>
                      </div>
                    </div>
                    <div class="form-group row">
                      <div class="required-validation" *ngIf="((this.ModelListApplication.NetAvailableAmount + this.ModelListApplication.ApprovedExpenseAmount) - this.ModelListApplication.FinalExpecetdAmount) < 0 ">You Have Insufficant Amount To Applied.</div>
                    </div>
                  </div> 
                </div>

              </div>
            </form>


          </fieldset>
        </div>
        <button type="button" aria-label="Close" (click)="modalRef.hide()" style="background: #2a9bd7; outline: 0px none; border: 0px none; color: #fff; padding: 5px 10px; border-radius: 3px;">
          <span>Close</span>
        </button>             
      </div>
    </ng-template>
  </div>
</div>