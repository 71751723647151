<div class="body-content-area tabcontent" id="link1">
    <div class="row">
        <div class="col-lg-9">
            <div class="row">
                <div class="col-lg-1 low-padding text-center">
                    <!-- <img *ngIf="this.SelectedMenu.Image!=null" src={{this.SelectedMenu.Image}} class="body-icon" alt="Icon"/> -->
                </div>
                <div class="col-lg-10 clear-padding">
                    <span class="page-title">Manage Department</span>
                    <small class="page-description"></small>
                    <div class="col-lg-10 clear-padding" *ngIf="!(this.CanViewState)">
                        <button *ngIf="(this.CanSaveButtonView)" (click)="ADDClick()" type="button" class="btn btn-primary Add-button">ADD New</button> 
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div *ngIf="!(this.CanViewState)">
        <app-dialog [(visible)]="this.SuccessStatus">
            <span class="successmsg">{{this.SuccessMessage}}</span>
        </app-dialog>
        <fieldset *ngIf="!(this.FormViewShow)" class="body-form-control">
            <legend class="legend--">Department Details</legend>
            <table id="tbldepartment" style=" width: 100%;" *ngIf="!(this.FormViewShow)" datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" class="table table-striped table-bordered text-center table-new row-border hover">
                <thead class="thead-blue text-center">
                    <tr>
                        <th>Sl No.</th>
                        <th>Department Code</th>
                        <th>Department</th>
                        <th>Company</th>
                        <th>Branch</th>
                        <th>Functional Head</th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let Depart of Departmentes, let i = index">
                        <td scope="row">{{i+1}}</td>
                        <td>{{Depart.DepartmentCode}}</td>
                        <td class="text-left">{{Depart.DepartmentName}}</td>
                        <td class="text-left">{{Depart.CompName}}</td>
                        <td class="text-left">{{Depart.BranchName}}</td>
                        <td class="text-left">{{Depart.FunctionalHeadName}}</td>
                        <td>
                            <button  *ngIf="this.SelectedMenu.CanEdit"  type="button"  (click)="GetDepartmentByid( Depart.DepartmentId )" class="table-edit-btn"><i class="fas fa-edit"></i> </button> 
                            <button *ngIf="this.SelectedMenu.CanDelete" type="button"  (click)="DeleteDepartmentByid( Depart.DepartmentId )" class="table-remove-btn"><i class="fas fa-trash-alt"></i> </button>
                            <button  *ngIf="this.SelectedMenu.CanView"  type="button" (click)="ViewDepartmentByid( Depart.DepartmentId )" class="table-view-button" type="button" ><i class="fas fa-eye"></i></button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </fieldset>
    
        <fieldset class="body-form-control" style=" width: 100%;" *ngIf="(this.FormViewShow)">
            <legend class="legend--">Department Creation</legend>
            <form #DepartmentForm="ngForm" (ngSubmit)="saveDepartment()">
                <div class="row">
                    <div class="col-lg-4">
                        <div class="col-body-border-right">
                            <div class="form-group row">
                                <label class="col-sm-4 col-form-label clear-padding-right">Code</label>
                                <div class="col-sm-7 clear-left-padding">
                                    <input type="text" [(ngModel)]="DepartmentModel.DepartmentCode" tabindex="1" maxlength="40" id ="DepartmentCode"  name="DepartmentCode" #DepartmentCode="ngModel" required class="form-control form-control-custom form-control-custom-new" >
                                </div>
                                <div *ngIf="DepartmentCode.invalid && (DepartmentCode.dirty || DepartmentCode.touched)" class="alert alert-danger">
                                    <div *ngIf="DepartmentCode.errors.required">Department Code is required.</div>
                                    <div *ngIf="DepartmentCode.errors.maxlength">Department Code is not more than 40 characters</div>
                                </div>
                            </div>
                            <div class="form-group row">
                                <label  class="col-sm-4 col-form-label clear-padding-right">Company Name</label>
                                <div class="col-sm-7 clear-left-padding">
                                    <select [(ngModel)]="DepartmentModel.CompId" (ngModelChange)="onCompanychange($event)" tabindex="4" id ="CompId" class="form-control"  name="CompId" #CompId="ngModel" >
                                        <option [value]="0" [disabled]=true >Select Company</option>
                                        <option *ngFor="let comp of Companies" [value]="comp.CompId">{{comp.CompName}}</option>
                                    </select>
                                </div>
                                <div class="required-validation"  *ngIf="DepartmentModel.CompId==0">Company is required</div>
                            </div>
                        </div>
                    </div>
                    
                    <div class="col-lg-4">
                        <div class="col-body-border-right">
                            <div class="form-group row">
                                <label  class="col-sm-4 col-form-label clear-padding-right">Name</label>
                                <div class="col-sm-7 clear-left-padding">
                                    <input type="text" [(ngModel)]="DepartmentModel.DepartmentName" tabindex="2" id ="DepartmentName" (blur)="CheckName()" name="DepartmentName" #DepartmentName="ngModel" required maxlength="250" class="form-control form-control-custom form-control-custom-new" >
                                </div>
                                <div *ngIf="this.Namecheck.length > 0" class="alert alert-danger">Department Name is exist</div>
                                <div *ngIf="DepartmentName.invalid && (DepartmentName.dirty || DepartmentName.touched)" class="alert alert-danger">
                                    <div *ngIf="DepartmentName.errors.required">Department Name  is required.</div>
                                    <div *ngIf="DepartmentName.errors.maxlength">Department Name is not more than 250 characters</div>
                                </div>
                            </div>
                            <div class="form-group row">
                                <label  class="col-sm-4 col-form-label clear-padding-right">Branch</label>
                                <div class="col-sm-7 clear-left-padding">
                                    <select [(ngModel)]="DepartmentModel.BranchId" tabindex="5" id ="BranchId" class="form-control" name="BranchId" #BranchId="ngModel" (blur)="CheckName()">
                                        <option [value]="0" [disabled]=true >Select Branch</option>
                                        <option *ngFor="let Bran of Branches" [value]="Bran.BranchId">{{Bran.BranchName}}</option>
                                    </select>
                                </div>
                                <div class="required-validation" *ngIf="DepartmentModel.BranchId==0">Branch is required</div>
                            </div> 
                        </div>
                    </div>

                    <div class="col-lg-4">
                        <div class="form-group row">
                            <label  class="col-sm-4 col-form-label clear-padding-right">Functional Head</label>
                            <div class="col-sm-7 clear-left-padding">
                                <select [(ngModel)]="DepartmentModel.FunctionalHead" tabindex="3" id ="FunctionalHead" class="form-control"  name="FunctionalHead" #FunctionalHead="ngModel" >
                                    <option [value]="0" [disabled]=true >Select FunctionalHead</option>
                                    <option *ngFor="let emp of Employees" [value]="emp.EmployeeId">{{emp.EmployeeCode}}-{{emp.EmployeeName}}</option>
                                </select>
                            </div>
                            <div class="required-validation" *ngIf="DepartmentModel.FunctionalHead==0">Functional Head is required</div>
                        </div>
                        <div class="form-group row">
                            <label *ngIf="!(this.IsDataIsInViewState)" class="col-sm-6 col-form-label text-center clear-padding-right">
                                <button type="submit" tabindex="6" class="btn btn-primary save-button" [disabled]="!(DepartmentForm.valid && DepartmentModel.CompId !=0 && DepartmentModel.FunctionalHead!=0 && DepartmentModel.BranchId!=0 && this.DisableSave==true) " >Save</button>  
                            </label>
                            <div class="col-sm-6 col-form-label text-center">
                            <button type="button" tabindex="7" (click)="RefreshPage()" class="btn btn-danger cancle-button">Cancel</button>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </fieldset>
    </div>
</div>