<form #EmployeeDetailForm="ngForm">
    <div class="row">
        <div class="col-lg-4">
            <div class="col-body-border-right">
                <div class="form-group row">
                    <label class="col-sm-4 col-form-label clear-padding-right" style="font-weight: bold;">Code</label>
                    <div class="col-sm-7 clear-left-padding" style="font-weight: bold; padding-top: 7px;">
                        :&nbsp;&nbsp;{{EmployeeMasterService.SharedEmployees.EmployeeCode}}
                    </div>
                </div>
            </div>
        </div>
        <div class="col-lg-4">
            <div class="form-group row">
                <label class="col-sm-4 col-form-label clear-padding-right" style="font-weight: bold;">Name</label>
                <div class="col-sm-7 clear-left-padding" style="font-weight: bold; padding-top: 7px;">
                    :&nbsp;&nbsp;{{EmployeeMasterService.SharedEmployees.FullName}}
                </div>
            </div>
        </div>
    </div>
</form>

<fieldset class="body-form-control">
    <app-dialog [(visible)]="this.SuccessStatus">
        <span class="successmsg">{{this.SuccessMessage}}</span>
    </app-dialog>
  
    <legend class="legend--">Employee Personal Details</legend>
    <form #EmployeeDetailForm="ngForm" (ngSubmit)="SaveEmpolyeePersonalDetails()">
        <div class="row">
            <div class="col-lg-4">
                <div class="col-body-border-right">
                    <div class="form-group row" *ngIf="EmployeeMasterService.SharedEmployees.EmployeeId == 0">                        
                        <label class="col-sm-4 col-form-label clear-padding-right">EPF Applicable</label>
                        <div class="col-sm-7 clear-left-padding">
                            <input type="checkbox" [(ngModel)]="EmployeeMasterService.SharedEmployees.EPFApplicable" id ="EPFApplicable" tabindex="1" name="EPFApplicable" #EPFApplicable="ngModel" (ngModelChange)="oncheckedEPFApplicableChange()" class="form-control form-control-custom form-control-custom-new">
                        </div>
                    </div>
                    <div class="form-group row">
                        <label class="col-sm-4 col-form-label clear-padding-right">First Name</label>
                        <div class="col-sm-7 clear-left-padding">
                            <input type="text" [(ngModel)]="EmployeeMasterService.SharedEmployees.FirstName" tabindex="4" maxlength="100" id ="FirstName" name="FirstName" #FirstName="ngModel" (blur)="GetFullName()" class="form-control form-control-custom form-control-custom-new" required>
                        </div>
                        <div *ngIf="FirstName.invalid && (FirstName.dirty || FirstName.touched)" class="required-validation">
                            <div *ngIf="FirstName.errors.required">First Name is required.</div>
                            <div *ngIf="FirstName.errors.maxlength">Maximum 100 characters</div>
                        </div>
                    </div>
                    <div class="form-group row" *ngIf="EmployeeMasterService.SharedEmployees.EmployeeId == 0">
                        <label class="col-sm-4 col-form-label clear-padding-right">Employee code</label>
                        <div class="col-sm-7 clear-left-padding">
                            <input type="text" tabindex="7" pattern="[0-9]+" [(ngModel)]="EmployeeMasterService.SharedEmployees.EmployeeCode" id ="EmployeeCode" maxlength="20" name="EmployeeCode" (blur)="CheckEmployeeCodeByCode()" #EmployeeCode="ngModel" class="form-control form-control-custom form-control-custom-new" required>
                        </div>
                        <div class="required-validation" *ngIf="EmployeeCode.invalid && (EmployeeCode.dirty || EmployeeCode.touched)">
                            <div *ngIf="EmployeeCode.errors.required">Employee Code is required.</div>
                            <div *ngIf="EmployeeCode.errors?.pattern">Employee Code should be valid.</div>
                        </div>
                        <div *ngIf="this.IsEmpCodeStatus==true" class="required-validation">Employee Code is Exist.</div>
                    </div>

                    <div class="form-group row" *ngIf="EmployeeMasterService.SharedEmployees.EmployeeId != 0">
                        <label class="col-sm-4 col-form-label clear-padding-right">Employee code</label>
                        <div class="col-sm-7 clear-left-padding">
                            <input type="text" disabled="disabled" [(ngModel)]="EmployeeMasterService.SharedEmployees.EmployeeCode" id ="EmployeeCodeshow" name="EmployeeCodeshow" class="form-control form-control-custom form-control-custom-new">
                        </div>
                    </div>


                    <div class="form-group row">
                        <label  class="col-sm-4 col-form-label clear-padding-right">DOB</label>
                        <div class="col-sm-7 clear-left-padding">
                            <input class="form-control" style="float:none" placeholder="Select date" ngx-mydatepicker name="DOB" tabindex="10"  [(ngModel)]="EmployeeMasterService.SharedEmployees.DOB" [options]="myOptions" #DOB="ngx-mydatepicker" (dateChanged)="onDateChangedDOB($event)" required />
                            <span class="mt-2">
                                <a class="btn btn-primary pl-10 pr-10 pt-1 pb-1" (click)="DOB.toggleCalendar()">
                                    <i style="color:#fff" class="fas fa-calendar-alt fa-lg"></i>
                                </a>
                                <a class="btn btn-danger pl-10 pr-10 pt-1 pb-1" (click)="DOB.clearDate()">
                                    <i style="color:#fff" class="far fa-calendar-times fa-lg"></i>
                                </a>
                            </span>
                            <div *ngIf="EmployeeMasterService.SharedEmployees.DOBPass==null" class="required-validation">Required.</div>
                        </div>
                    </div>
                    <div class="form-group row">
                        <label  class="col-sm-4 col-form-label clear-padding-right">Company</label>
                        <div class="col-sm-7 clear-left-padding">
                            <select [(ngModel)]="EmployeeMasterService.SharedEmployees.CompanyId" tabindex="13" (ngModelChange)="onChangeCompany($event)" id ="CompanyId" class="form-control" name="CompanyId" #CompanyId="ngModel" >
                                <option [value]="0" [disabled]=true >Select Company</option>
                                <option *ngFor="let c of Companies" [value]="c.CompId">{{c.CompName}}</option>
                            </select>
                        </div>
                        <div class="required-validation" *ngIf="EmployeeMasterService.SharedEmployees.CompanyId==0">Company is required</div>
                    </div>
                    <div class="form-group row">
                        <label  class="col-sm-4 col-form-label clear-padding-right">Decision Making</label>
                        <div class="col-sm-7 clear-left-padding">
                            <select (ngModelChange)="onDecisionChange($event)" [(ngModel)]="EmployeeMasterService.SharedEmployees.DecisionId" tabindex="16" id ="DecisionId" class="form-control"  name="DecisionId" #DecisionId="ngModel" >
                                <option [value]="0" [disabled]=true >Select Decision Making</option>
                                <option *ngFor="let DM of DecisionMakings" [value]="DM.DecisionId">{{DM.DecisionName}}</option>
                            </select>
                        </div>
                        <div class="required-validation" *ngIf="EmployeeMasterService.SharedEmployees.DecisionId==0">Decision Making is required</div>
                    </div>
                    <div class="form-group row">
                        <label  class="col-sm-4 col-form-label clear-padding-right">Designation</label>
                        <div class="col-sm-7 clear-left-padding">
                            <select [(ngModel)]="EmployeeMasterService.SharedEmployees.DesignationId" tabindex="19" id ="DesignationId" class="form-control"  name="DesignationId" #DesignationId="ngModel" >
                                <option [value]="0" [disabled]=true >Select Designation</option>
                                <option *ngFor="let G of Designations" [value]="G.DesignationId">{{G.DesignationName}}</option>
                            </select>
                        </div> 
                        <div class="required-validation" *ngIf="EmployeeMasterService.SharedEmployees.DesignationId==0">Designation is required</div>
                    </div>
                    <div class="form-group row">
                        <label  class="col-sm-4 col-form-label clear-padding-right">Mother Tongue</label>
                        <div class="col-sm-7 clear-left-padding">
                            <select [(ngModel)]="EmployeeMasterService.SharedEmployees.LanguageID" tabindex="22" id ="LanguageID" class="form-control"  name="LanguageID" #LanguageID="ngModel" >
                                <option [value]="0" [disabled]=true >Select Language</option>
                                <option *ngFor="let L of Languages" [value]="L.LanguageID">{{L.LanguageName}}</option>
                            </select>
                        </div>
                        <div class="required-validation" *ngIf="EmployeeMasterService.SharedEmployees.LanguageID==0">Language is required</div>
                    </div>
                    <div class="form-group row">
                        <label  class="col-sm-4 col-form-label clear-padding-right">Email Id</label>
                        <div class="col-sm-7 clear-left-padding">
                            <input type="text" [(ngModel)]="EmployeeMasterService.SharedEmployees.Emailid" tabindex="25" id ="EmailId" name="EmailId" #EmailId="ngModel" pattern="^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$" class="form-control form-control-custom form-control-custom-new" required>
                        </div>
                        <div *ngIf="EmailId.invalid && (EmailId.dirty || EmailId.touched)" class="required-validation">
                            <div *ngIf="EmailId.errors.required">Email is required.</div>
                            <div *ngIf="EmailId.errors?.pattern">Email should be valid.</div>
                        </div>
                    </div>
                    <div class="form-group row" *ngIf="EmployeeMasterService.SharedEmployees.EmployeeId == 0 && EmployeeMasterService.SharedEmployees.OfferId == 0">
                        <label  class="col-sm-4 col-form-label clear-padding-right">Gross Salary(Monthly)</label>
                        <div class="col-sm-7 clear-left-padding">
                            <input type="text" [(ngModel)]="EmployeeMasterService.SharedEmployees.GrossSalary" (blur)="GetOfferdetails_Salary()" tabindex="28" maxlength="10" pattern="[0-9]+" id ="GrossSalary" name="GrossSalary" #GrossSalary="ngModel" required class="form-control form-control-custom form-control-custom-new">
                        </div>
                        <div *ngIf="GrossSalary.invalid && (GrossSalary.dirty || GrossSalary.touched)" class="required-validation">
                            <div *ngIf="GrossSalary.errors.maxlength">Gross Salary is not more than 10 digit.</div>
                            <div *ngIf="GrossSalary.errors?.pattern">Gross Salary should be valid.</div>
                        </div>
                    </div>
                    <div class="form-group row" *ngIf="EmployeeMasterService.SharedEmployees.EmployeeId == 0 && EmployeeMasterService.SharedEmployees.OfferId > 0">
                        <label class="col-sm-4 col-form-label clear-padding-right">Gross Salary(Monthly)</label>
                        <div class="col-sm-7 clear-left-padding">
                            <input type="text" readonly="true" [(ngModel)]="EmployeeMasterService.SharedEmployees.GrossSalary" maxlength="10" pattern="[0-9]+" id ="GrossSalary" name="GrossSalary" #GrossSalary="ngModel" required class="form-control form-control-custom form-control-custom-new">
                        </div>
                    </div>
                    <div class="form-group row">
                        <label  class="col-sm-4 col-form-label clear-padding-right">Marital Status</label>
                        <div class="col-sm-7 clear-left-padding">
                            <select (ngModelChange)="MaritalChanged($event)" [(ngModel)]="EmployeeMasterService.SharedEmployees.MaritalId" tabindex="29" id ="MaritalId" class="form-control" name="MaritalId" #MaritalId="ngModel">
                            <option [value]="0" [disabled]=true >Select Marital</option>
                            <option *ngFor="let M of Maritials" [value]="M.MaritalId">{{M.MaritalName}}</option>
                            </select>
                        </div>
                        <div class="required-validation" *ngIf="EmployeeMasterService.SharedEmployees.MaritalId==0">Marital is required</div>
                    </div>
                    <div class="form-group row" *ngIf="AnniversaryDateShow">
                        <label  class="col-sm-4 col-form-label clear-padding-right">Anniversary Date</label>
                        <div class="col-sm-7 clear-left-padding">
                            <input class="form-control" style="float:none" placeholder="Select date" ngx-mydatepicker name="AnniversaryDate" tabindex="30" [(ngModel)]="EmployeeMasterService.SharedEmployees.AnniversaryDate" [options]="myOptions" #AnniversaryDate="ngx-mydatepicker" (dateChanged)="onDateChangedAnniversaryDate($event)" required/>
                            <span class="mt-2">
                                <a class="btn btn-primary pl-10 pr-10 pt-1 pb-1" (click)="AnniversaryDate.toggleCalendar()">
                                    <i style="color:#fff" class="fas fa-calendar-alt fa-lg"></i>
                                </a>
                                <a class="btn btn-danger pl-10 pr-10 pt-1 pb-1" (click)="AnniversaryDate.clearDate()">
                                    <i style="color:#fff" class="far fa-calendar-times fa-lg"></i>
                                </a>
                            </span>
                            <div *ngIf="EmployeeMasterService.SharedEmployees.AnniversaryDatePass==null" class="required-validation">Required.</div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-4">
                <div class="col-body-border-right">
                    <div class="form-group row" *ngIf="EmployeeMasterService.SharedEmployees.EmployeeId == 0">                        
                        <label class="col-sm-4 col-form-label clear-padding-right">ESI Applicable</label>
                        <div class="col-sm-7 clear-left-padding">
                            <input type="checkbox" [(ngModel)]="EmployeeMasterService.SharedEmployees.ESIApplicable" tabindex="2" id ="ESIApplicable" name="ESIApplicable" #ESIApplicable="ngModel" (ngModelChange)="oncheckedESIApplicableChange()" class="form-control form-control-custom form-control-custom-new">
                        </div>
                    </div>
                    <div class="form-group row">
                        <label  class="col-sm-4 col-form-label clear-padding-right" >Middle Name</label>
                        <div class="col-sm-7 clear-left-padding">
                            <input type="text" tabindex="5" [(ngModel)]="EmployeeMasterService.SharedEmployees.MiddleName" id ="MiddleName" name="MiddleName" #MiddleName="ngModel" maxlength="100" (blur)="GetFullName()" class="form-control form-control-custom form-control-custom-new">
                        </div>
                        <div *ngIf="MiddleName.invalid && (MiddleName.dirty || MiddleName.touched)" class="required-validation">
                            <div *ngIf="MiddleName.errors.maxlength">Maximum 100 characters</div>
                        </div>
                    </div>
                    <div class="form-group row">
                        <label  class="col-sm-4 col-form-label clear-padding-right">Father's Name</label>
                        <div class="col-sm-7 clear-left-padding">
                            <input type="text" [(ngModel)]="EmployeeMasterService.SharedEmployees.FathersName" tabindex="8" maxlength="250" id ="FathersName" name="FathersName" #FathersName="ngModel" class="form-control form-control-custom form-control-custom-new" >
                        </div>
                    </div>
                    <div class="form-group row">
                        <label  class="col-sm-4 col-form-label clear-padding-right">Offer Date</label>
                        <div class="col-sm-7 clear-left-padding">
                            <input class="form-control" style="float:none" placeholder="Select date" ngx-mydatepicker name="OfferDate" tabindex="11" [(ngModel)]="EmployeeMasterService.SharedEmployees.OfferDate" [options]="myOptions" #OfferDate="ngx-mydatepicker" (dateChanged)="onDateChangedOfferDate($event)" required/>
                            <span class="mt-2">
                                <a class="btn btn-primary pl-10 pr-10 pt-1 pb-1" (click)="OfferDate.toggleCalendar()">
                                    <i style="color:#fff" class="fas fa-calendar-alt fa-lg"></i>
                                </a>
                                <a class="btn btn-danger pl-10 pr-10 pt-1 pb-1" (click)="OfferDate.clearDate()">
                                    <i style="color:#fff" class="far fa-calendar-times fa-lg"></i>
                                </a>
                            </span>
                            <div *ngIf="EmployeeMasterService.SharedEmployees.OfferDatePass==null" class="required-validation">Required.</div>
                        </div>
                    </div>
                    <div class="form-group row">
                        <label  class="col-sm-4 col-form-label clear-padding-right">Branch</label>
                        <div class="col-sm-7 clear-left-padding">
                            <select [(ngModel)]="EmployeeMasterService.SharedEmployees.BranchId" tabindex="14" (ngModelChange)="onchangeBranch($event)" id ="BranchId" class="form-control" name="BranchId" #BranchId="ngModel" >
                            <option [value]="0" [disabled]=true >Select Branch</option>
                            <option *ngFor="let b of Branches" [value]="b.BranchId">{{b.BranchName}}</option>
                            </select>
                        </div>
                        <div class="required-validation" *ngIf="EmployeeMasterService.SharedEmployees.BranchId==0">Branch is required</div>  
                    </div>
                    <div class="form-group row">
                        <label  class="col-sm-4 col-form-label clear-padding-right">Level</label>
                        <div class="col-sm-7 clear-left-padding">
                            <select (ngModelChange)="onLevelChange($event)" [(ngModel)]="EmployeeMasterService.SharedEmployees.LevelId" tabindex="17" id ="LevelId" class="form-control"  name="LevelId" #LevelId="ngModel" >
                                <option [value]="0" [disabled]=true >Select Level</option>
                                <option *ngFor="let L of Levels" [value]="L.LevelId">{{L.LevelName}}</option>
                            </select>
                        </div>
                        <div class="required-validation" *ngIf="EmployeeMasterService.SharedEmployees.LevelId==0">Level is required</div>  
                    </div>
                    <div class="form-group row">
                        <label  class="col-sm-4 col-form-label clear-padding-right">Gender</label>
                        <div class="col-sm-7 clear-left-padding">
                            <select [(ngModel)]="EmployeeMasterService.SharedEmployees.GenderId" tabindex="20" id ="GenderId" class="form-control"  name="GenderId" #GenderId="ngModel" >
                                <option [value]="0" [disabled]=true >Select Gender</option>
                                <option *ngFor="let G of Ganders" [value]="G.GenderId">{{G.GenderName}}</option>
                            </select>
                        </div>
                        <div class="required-validation" *ngIf="EmployeeMasterService.SharedEmployees.GenderId==0">Gender is required</div>  
                    </div>
                    <div class="form-group row">
                        <label class="col-sm-4 col-form-label clear-padding-right">Reporting Head</label>
                        <div class="col-sm-7 clear-left-padding">
                            <select [(ngModel)]="EmployeeMasterService.SharedEmployees.ReportingHeadId" tabindex="23" id ="ReportingHeadId" class="form-control" name="ReportingHeadId" #ReportingHeadId="ngModel">
                            <option [value]="0" [disabled]=true >Select Reporting Head</option>
                            <option *ngFor="let RH of ReportingHeads" [value]="RH.EmployeeId">{{RH.EmployeeCode}} - {{RH.EmployeeName}}</option>
                            </select>
                        </div>
                        <div class="required-validation" *ngIf="EmployeeMasterService.SharedEmployees.ReportingHeadId == 0">Reporting Head is required</div>
                    </div>
                    <div class="form-group row">
                        <label  class="col-sm-4 col-form-label clear-padding-right">Blood Group</label>
                        <div class="col-sm-7 clear-left-padding">
                            <input type="text" [(ngModel)]="EmployeeMasterService.SharedEmployees.BloodGroup" tabindex="26" id ="BloodGroup" name="BloodGroup" class="form-control form-control-custom form-control-custom-new" >
                        </div>
                    </div>
                    <div class="form-group row" *ngIf="EmployeeMasterService.SharedEmployees.EmployeeId == 0">
                        <label  class="col-sm-4 col-form-label clear-padding-right" >CTC Salary(Rs)</label>
                        <div class="col-sm-7 clear-left-padding">
                        <input type="text" readonly="readonly" name="CTCAmount" [ngModel]="EmployeeMasterService.SharedEmployees.CTCAmount | number:'3.0-0'" #CTCAmount="ngModel" required class="form-control form-control-custom form-control-custom-new"/>
                        </div>
                    </div>
                    <div class="form-group row">
                        <label class="col-sm-4 col-form-label clear-padding-right">Verification Status</label>
                        <div class="col-sm-7 clear-left-padding">
                            <select [(ngModel)]="EmployeeMasterService.SharedEmployees.VerificationStatusId" tabindex="31" id ="VerificationStatusId" class="form-control" name="VerificationStatusId" #VerificationStatusId="ngModel" (ngModelChange)="onVerificationStatusChange($event)" required>
                                <option [value]="0" [disabled]=true >Select Verification Status</option>
                                <option [value]="1">Pending</option>
                                <option [value]="2">In Progress</option>
                                <option [value]="3">Completed</option>
                            </select>
                        </div>
                        <div class="required-validation" *ngIf="EmployeeMasterService.SharedEmployees.VerificationStatusId == 0">Verification is required</div>
                    </div>
                    <div class="form-group row" *ngIf="EmployeeMasterService.SharedEmployees.EmployeeId == 0">
                        <label class="col-sm-4 col-form-label clear-padding-right">Working Group</label>
                        <div class="col-sm-7 clear-left-padding">
                            <select [(ngModel)]="EmployeeMasterService.SharedEmployees.WorkingGroupId" tabindex="31" id ="WorkingGroupId" class="form-control" name="WorkingGroupId" #WorkingGroupId="ngModel" required>
                                <option [value]="0" [disabled]=true >Select Working Group</option>
                                <option *ngFor="let WG of WorkingGroupHour" [value]="WG.WorkingGroupId">{{WG.WorkingGroupName}}</option>
                            </select>
                        </div>
                        <div class="required-validation" *ngIf="EmployeeMasterService.SharedEmployees.WorkingGroupId == 0">Working Group is required</div>
                    </div>
                    <div class="form-group row" *ngIf="EmployeeMasterService.SharedEmployees.EmployeeId == 0">
                        <div class="col-sm-7 clear-left-padding">
                            <button type="button" class="btn btn-info" (click)="openModal(template)" [disabled]="!(this.OfferedSalary.length > 0)">Salary BreakUp</button>
                        </div>
                    </div>
              </div>
            </div>

            <div class="col-lg-4">
                <div class="form-group row" *ngIf="EmployeeMasterService.SharedEmployees.EmployeeId == 0">                        
                    <label class="col-sm-4 col-form-label clear-padding-right">PTAX Applicable</label>
                    <div class="col-sm-7 clear-left-padding">
                        <input type="checkbox" [(ngModel)]="EmployeeMasterService.SharedEmployees.PTAXApplicable" tabindex="3" id ="PTAXApplicable" name="PTAXApplicable" #PTAXApplicable="ngModel" (ngModelChange)="oncheckedPTAXApplicableChange()" class="form-control form-control-custom form-control-custom-new">
                        <select [(ngModel)]="EmployeeMasterService.SharedEmployees.PtaxStateId" tabindex="3" (ngModelChange)="onPtaxStateChange()" class="form-control" id="PtaxStateId" name="PtaxStateId" #PtaxStateId="ngModel" *ngIf="(this.DisableState)">
                            <option [value]="0">Select Ptax State</option>
                            <option *ngFor="let st of States" [value]="st.StateID">{{st.StateName}}</option>
                        </select>
                    </div>
                    <div *ngIf="(this.DisableState)">
                        <div class="required-validation" *ngIf="EmployeeMasterService.SharedEmployees.PtaxStateId == 0">PTAX State is required</div>
                    </div>
                </div>
                <div class="form-group row">
                    <label  class="col-sm-4 col-form-label clear-padding-right">Last Name</label>
                    <div class="col-sm-7 clear-left-padding">
                        <input type="text" tabindex="6" [(ngModel)]="EmployeeMasterService.SharedEmployees.LastName" id ="LastName" maxlength="100" #LastName="ngModel" name="LastName" (blur)="GetFullName()" class="form-control form-control-custom form-control-custom-new">
                    </div>
                    <div *ngIf="LastName.invalid && (LastName.dirty || LastName.touched)" class="required-validation">
                        <div *ngIf="LastName.errors.maxlength">Maximum 100 characters</div>
                    </div>
                </div>
                <div class="form-group row">
                    <label  class="col-sm-4 col-form-label clear-padding-right">Mother's Name</label>
                    <div class="col-sm-7 clear-left-padding">
                        <input type="text" [(ngModel)]="EmployeeMasterService.SharedEmployees.MothersName" tabindex="9" id ="MothersName"  name="MothersName" #MothersName="ngModel" class="form-control form-control-custom form-control-custom-new" >
                    </div>
                </div>
                <div class="form-group row">
                    <label  class="col-sm-4 col-form-label clear-padding-right">Joining Date</label>
                    <div class="col-sm-7 clear-left-padding">
                        <input class="form-control" style="float:none" placeholder="Select date" ngx-mydatepicker name="JoiningDate" tabindex="12" [(ngModel)]="EmployeeMasterService.SharedEmployees.JoiningDate" [options]="myOptions" #JoiningDate="ngx-mydatepicker" (dateChanged)="onDateChangedJoiningDate($event)" required/>
                        <span class="mt-2">
                            <a class="btn btn-primary pl-10 pr-10 pt-1 pb-1" (click)="JoiningDate.toggleCalendar()">
                                <i style="color:#fff" class="fas fa-calendar-alt fa-lg"></i>
                            </a>
                            <a class="btn btn-danger pl-10 pr-10 pt-1 pb-1" (click)="JoiningDate.clearDate()">
                                <i style="color:#fff" class="far fa-calendar-times fa-lg"></i>
                            </a>
                        </span>
                        <div *ngIf="EmployeeMasterService.SharedEmployees.JoiningDatePass==null" class="required-validation">Required.</div>
                    </div>
                </div>
                <div class="form-group row">
                    <label class="col-sm-4 col-form-label clear-padding-right">Department</label>
                    <div class="col-sm-7 clear-left-padding">
                        <select  [(ngModel)]="EmployeeMasterService.SharedEmployees.DepartmentId" tabindex="15" id ="DepartmentId" class="form-control" name="DepartmentId" #DepartmentId="ngModel">
                        <option [value]="0" [disabled]=true >Select Department</option>
                        <option *ngFor="let d of Departments" [value]="d.DepartmentId">{{d.DepartmentName}}</option>
                        </select>
                    </div>
                    <div class="required-validation" *ngIf="EmployeeMasterService.SharedEmployees.DepartmentId==0">Department is required</div>
                </div>
                <div class="form-group row">
                    <label  class="col-sm-4 col-form-label clear-padding-right">Grade</label>
                    <div class="col-sm-7 clear-left-padding">
                        <select [(ngModel)]="EmployeeMasterService.SharedEmployees.GradeId" tabindex="18" id ="GradeId" (ngModelChange)="onGradechange($event)" class="form-control"  name="GradeId" #GradeId="ngModel" >
                            <option [value]="0" [disabled]=true >Select Grade</option>
                            <option *ngFor="let G of Grades" [value]="G.GradeId">{{G.GradeName}}</option>
                        </select>
                    </div>
                    <div class="required-validation" *ngIf="EmployeeMasterService.SharedEmployees.GradeId==0">Grade is required</div>
                </div>
                <div class="form-group row">
                    <label  class="col-sm-4 col-form-label clear-padding-right">Religion</label>
                    <div class="col-sm-7 clear-left-padding">
                        <select [(ngModel)]="EmployeeMasterService.SharedEmployees.ReligionID" tabindex="21" id ="ReligionID" class="form-control"  name="ReligionID" #ReligionID="ngModel" >
                            <option [value]="0" [disabled]=true >Select Religion</option>
                            <option *ngFor="let G of Religions" [value]="G.ReligionID">{{G.ReligionName}}</option>
                        </select>
                    </div>
                    <div class="required-validation" *ngIf="EmployeeMasterService.SharedEmployees.ReligionID==0">Religion is required</div>  
                </div>
                <div class="form-group row">
                    <label class="col-sm-4 col-form-label clear-padding-right">Functional Head</label>
                    <div class="col-sm-7 clear-left-padding">
                        <select [(ngModel)]="EmployeeMasterService.SharedEmployees.FunctionalHeadId" tabindex="24" id ="FunctionalHeadId" class="form-control" name="FunctionalHeadId" #FunctionalHeadId="ngModel">
                            <option [value]="0" [disabled]=true >Select Functional Head</option>
                            <option *ngFor="let FH of FunctionalHeads" [value]="FH.EmployeeId">{{FH.EmployeeCode}} - {{FH.EmployeeName}}</option>
                        </select>
                    </div>
                    <div class="required-validation" *ngIf="EmployeeMasterService.SharedEmployees.FunctionalHeadId == 0">Functional Head is required</div>
                </div>
                <div class="form-group row">
                    <label  class="col-sm-4 col-form-label clear-padding-right">Mobile No</label>
                    <div class="col-sm-7 clear-left-padding">
                        <input type="text" [(ngModel)]="EmployeeMasterService.SharedEmployees.ContactNumber" tabindex="27" maxlength="10" minlength="10" pattern="[0-9]+" id ="ContactNumber" name="ContactNumber" #ContactNumber="ngModel" required class="form-control form-control-custom form-control-custom-new" >
                    </div>
                    <div *ngIf="ContactNumber.invalid && (ContactNumber.dirty || ContactNumber.touched)" class="required-validation">
                        <div *ngIf="ContactNumber.errors.required">Mobile No is required.</div>
                        <div *ngIf="ContactNumber.errors?.pattern">Mobile No should be valid.</div>
                        <div *ngIf="ContactNumber.errors.maxlength">Mobile No is not more than 10 digit.</div>
                        <div *ngIf="ContactNumber.errors.minlength">Mobile No is not less than 10 digit.</div>
                    </div>
                </div>
                <div class="form-group row" *ngIf="EmployeeMasterService.SharedEmployees.EmployeeId == 0">
                    <label  class="col-sm-4 col-form-label clear-padding-right" >Net Take Salary</label>
                    <div class="col-sm-7 clear-left-padding">
                      <input type="text" readonly="readonly" name="NetTakeHome" [ngModel]="EmployeeMasterService.SharedEmployees.NetTakeHome | number:'3.0-0'" #NetTakeHome="ngModel" required class="form-control form-control-custom form-control-custom-new"/>
                    </div>
                </div>
                <div class="form-group row">
                    <label class="col-sm-4 col-form-label clear-padding-right">Pay Zone</label>
                    <div class="col-sm-7 clear-left-padding">
                        <select [(ngModel)]="EmployeeMasterService.SharedEmployees.RegionHRId" id ="RegionHRId" class="form-control" name="RegionHRId" #RegionHRId="ngModel">
                            <option [value]="0" [disabled]=true >Select Pay Zone</option>
                            <option *ngFor="let PZ of RegionForHR" [value]="PZ.RefId">{{PZ.RegionName}}</option>
                        </select>
                    </div>
                    <div class="required-validation" *ngIf="EmployeeMasterService.SharedEmployees.RegionHRId==0">Pay Zone is required</div>
                </div>
                <div class="form-group row">
                    <label  class="col-sm-4 col-form-label clear-padding-right">Photo</label>
                    <div class="col-sm-7 clear-left-padding">
                        <img *ngIf="this.EmployeeMasterService.SharedEmployees.ImageFile!=null" tabindex="32" [src]="EmployeeMasterService.SharedEmployees.ImageFile" style="width:175px; height: 100px;">
                        <input type="file" (change)="handleFileinput($event.target.files)" tabindex="32" #Image accept="image/*" >
                    </div>
                    <!-- <div *ngIf="!EmployeeMasterService.SharedEmployees.validfile" class="required-validation">Invalid Photo, will not be uploaded</div> -->
                </div>
                <div class="form-group row">
                    <label class="col-sm-6 col-form-label text-center clear-padding-right">
                        <button type="submit" tabindex="36" class="btn btn-primary save-button" 
                        [disabled]="!(EmployeeDetailForm.valid 
                        && EmployeeMasterService.SharedEmployees.DesignationId > 0 
                        && EmployeeMasterService.SharedEmployees.MaritalId > 0  
                        && EmployeeMasterService.SharedEmployees.LanguageID > 0 
                        && EmployeeMasterService.SharedEmployees.ReligionID > 0
                        && EmployeeMasterService.SharedEmployees.DepartmentId > 0 
                        && EmployeeMasterService.SharedEmployees.DOBPass !=null 
                        && EmployeeMasterService.SharedEmployees.OfferDatePass !=null
                        && EmployeeMasterService.SharedEmployees.JoiningDatePass !=null
                        && EmployeeMasterService.SharedEmployees.ReportingHeadId > 0 
                        && EmployeeMasterService.SharedEmployees.FunctionalHeadId > 0 
                        && EmployeeMasterService.SharedEmployees.WorkingGroupId > 0
                        && this.DisableSave == true
                        && EmployeeMasterService.SharedEmployees.VerificationStatusId > 0
                        && EmployeeMasterService.SharedEmployees.RegionHRId > 0
                        && (!this.IsEmpCodeStatus))" >Save & Next</button>  
                    </label>
                    <div class="col-sm-6 col-form-label text-center">
                    <button  type="button" tabindex="37" class="btn btn-danger cancle-button" (click)="RefreshPage()">Cancel</button></div>
                </div>
            </div>
        </div>
        <!-- && EmployeeMasterService.SharedEmployees.validfile == true 
        && EmployeeMasterService.SharedEmployees.NetTakeHome > 0-->
        <ng-template #template>
            <div class="modal-header">
                <h4 class="modal-title pull-left">Salary BreakUp</h4>
                <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <div *ngIf="OfferedSalary.length > 0" >
                    <table id="tblComponent" class="table table-striped table-bordered text-left table-new row-border hover">
                        <thead class="thead-blue text-center">
                            <tr>
                                <th>Component</th>                        
                                <th class="text-right">Amount(Rs)</th>                       
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let sc of OfferedSalary, let i = index">
                                <td *ngIf="sc.IsBenefit==false">
                                    <span>{{sc.SalaryComponentName }}</span>
                                    <input type="text" hidden="hidden" [(ngModel)]="sc.OfferSalaryId " id ="RefId" name="RefId" #RefId="ngModel">
                                </td>
                                <td *ngIf="sc.IsBenefit==false" class="text-right">
                                    <input type="text" (blur)="onBasicChangevaluechange()" [(ngModel)]="sc.Amount" name="Amount_{{i}}" #Amount="ngModel" class="form-control form-control-custom form-control-custom-new">
                                </td>
                            </tr>
                            <tr style="background-color:rgb(207, 250, 250)">
                                <td>Total Gross Salary : (A)</td>
                                <td class="text-right">{{this.ComponentSubTotal | number:'3.0-0'}} </td>
                            </tr>
                            <tr *ngFor="let sc of OfferedSalary, let i = index">
                                <td *ngIf="sc.IsBenefit==true">
                                    <span>{{sc.SalaryComponentName}}</span>
                                    <input type="text" hidden="hidden" [(ngModel)]="sc.OfferSalaryId " id ="RefId" name="RefId" #RefId="ngModel">
                                </td>                            
                                <td *ngIf="sc.IsBenefit==true" class="text-right">
                                    <input type="text" (blur)="onBasicChangevaluechange()" [(ngModel)]="sc.Amount" name="Amount_{{i}}" #Amount="ngModel" class="form-control form-control-custom form-control-custom-new">
                                </td>
                            </tr>
                            <tr *ngIf="this.PFCont > 0">
                                <td>PF Employer's Contribution</td>
                                <td class="text-right">{{this.PFCont | number:'3.0-0'}} </td>
                            </tr>
                            <tr *ngIf="this.ESICont > 0">
                                <td>ESI Employer's Contribution</td>
                                <td class="text-right">{{this.ESICont | number:'3.0-0'}} </td>
                            </tr>
                            <tr style="background-color:rgb(207, 250, 250)">
                                <td>Long Term benefits : (B)</td>
                                <td class="text-right">{{this.Longtermbenefits + this.PFCont + this.ESICont | number:'3.0-0'}} </td>
                            </tr>
                            <tr style="background-color:rgb(162, 162, 235)">
                                <td>Computed Compensation CTC : (A+B)</td>
                                <td class="text-right">{{(this.Longtermbenefits + this.ComponentSubTotal + this.PFCont + this.ESICont) | number:'3.0-0'}} </td>
                            </tr>
                            <tr *ngIf="this.PFDed > 0">
                                <td>Less PF Employee's Contribution</td>
                                <td class="text-right">{{this.PFDed | number:'3.0-0'}} </td>
                            </tr>
                            <tr *ngIf="this.ESIDed > 0">
                                <td>Less ESI Employee's Contribution</td>
                                <td class="text-right">{{this.ESIDed | number:'3.0-0'}} </td>
                            </tr>
                            <tr *ngIf="this.P_Tax > 0">
                                <td>Professional Tax Amount </td>
                                <td class="text-right">{{this.P_Tax | number:'3.0-0'}} </td>
                            </tr>
                            <tr style="background-color:rgb(207, 250, 250)">
                                <td>Total Deduction : (C)</td>
                                <td class="text-right">{{(this.PFDed + this.ESIDed + this.P_Tax) | number:'3.0-0'}} </td>
                            </tr>
                            <tr style="background-color:rgb(162, 162, 235)">
                                <td>Take Home Before Income tax Deduction : (A-C)</td>
                                <td class="text-right">
                                    {{(this.ComponentSubTotal)-(this.PFDed + this.ESIDed + this.P_Tax) | number:'3.0-0'}} 
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </ng-template>
    </form>
</fieldset>
