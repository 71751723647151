import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { EmployeeMasterService }  from '../../../shared/HR_ADMIN/Employee/EmployeeMaster.Service';
//Begin Organisation & Levels(Model & Service)
import { OrganizationService }  from '../../../shared/Organization/organization.service';
import { PaymentMode } from '../../../shared/HR_ADMIN/Employee/PaymentModeModel.Model';
import { BankMaster } from '../../../shared/Organization/BankMaster.Model';
//End Organisation & Levels(Model & Service)
//Begin For Notification service
 import { NotificationsService} from '../../../shared/notifications.service';


@Component({
  selector: 'app-employee-other-details',
  templateUrl: './employee-other-details.component.html',
  styleUrls: ['./employee-other-details.component.css']
})

export class EmployeeOtherDetailsComponent implements OnInit 
{
  PaymentModes: PaymentMode[] = [];
  PaymentMode: any;

  BankMasters : BankMaster[]=[];
  BankMaster: any;

  constructor(public EmployeeMasterService: EmployeeMasterService,private organizationService: OrganizationService, private rout: Router, private notificationsService: NotificationsService) {  }
 
  SuccessMessage:string;
  Tabindex:number=0;
  SuccessStatus:boolean=false;
  OtherRefID : number;

  IsBankShow :boolean=false;
  DisableSave :boolean=false;
  
  ngOnInit() 
  {
    if( this.EmployeeMasterService.SaveOrUpdate==1)
    {
      localStorage.setItem('SuccessfullMessage', "Save Successfully");
      this.EmployeeMasterService.SaveOrUpdate=0;
    }
    if( this.EmployeeMasterService.SaveOrUpdate==2)
    {
      localStorage.setItem('SuccessfullMessage', "Update Successfully");
      this.EmployeeMasterService.SaveOrUpdate=0;
    }
    if(localStorage.getItem('SuccessfullMessage'))
    {
      this.SuccessStatus=true;
      this.SuccessMessage =localStorage.getItem('SuccessfullMessage')
      localStorage.removeItem('SuccessfullMessage');
    } 
    if( this.EmployeeMasterService.SaveOrUpdate==2)
    {
      localStorage.setItem('SuccessfullMessage', "Update Successfully");        
      this.EmployeeMasterService.SaveOrUpdate=0;
    }
    this.GetPaymentMode();
    this.getBankMasters();
    if(this.EmployeeMasterService.SharedEmployees.PaymentModeId == 0)
    {
      this.EmployeeMasterService.SharedEmployees.PaymentModeId=0;
    }
    if(this.EmployeeMasterService.SharedEmployees.BankId == 0)
    {
      this.EmployeeMasterService.SharedEmployees.BankId=0;
    }
    if(this.EmployeeMasterService.SharedEmployees.PaymentModeId != 1)
    {
      this.IsBankShow=true;
    }
    this.DisableSave=true;
  }

  GetPaymentMode() 
  {
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.EmployeeMasterService.GetPaymentMode( t).then(x => 
    {
      this.PaymentMode = x;
      Object.keys(this.PaymentMode).forEach( key => 
      {       
        let PaymentModeTemp = new PaymentMode();
        PaymentModeTemp = this.PaymentMode[key];
        this.PaymentModes.push(PaymentModeTemp);
      });     
    });      
  }

  PaymentModeChanged( PaymentId)
  {
    if(PaymentId!=1)
    {
      this.IsBankShow=true;
    }
    else
    {
      this.IsBankShow=false;
    }
  }

  getBankMasters() 
  {
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.organizationService.GetBankMaster( t).then(x => 
    {
      this.BankMaster = x;
      Object.keys(this.BankMaster).forEach( key => 
      {
        this.BankMaster[key];
        let BankMastertemp = new BankMaster();
        BankMastertemp = this.BankMaster[key];
        this.BankMasters.push(BankMastertemp);
      });     
    });      
  }

  onChangeBank(BankId)
  {
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.organizationService.GetBankMasterByid( t,BankId).then(x => 
    {
      this.BankMaster = x;
      Object.keys(this.BankMaster).forEach( key => 
      {
        // tslint:disable-next-line:no-unused-expression
        this.BankMaster[key];
        let BankMastertemp = new BankMaster();
        BankMastertemp = this.BankMaster[key];
        this.EmployeeMasterService.SharedEmployees.BranchName=BankMastertemp.BranchName;
        this.EmployeeMasterService.SharedEmployees.IFSCCode=BankMastertemp.IFSCCode;
      });
    });
  }

  SaveEmpolyeeOtherDetails()
  {
    this.DisableSave=false;
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.EmployeeMasterService.SharedEmployees.IsActive=true;
    if(this.EmployeeMasterService.SharedEmployees.OthesRefId> 0)
    {
      this.EmployeeMasterService.UpdateEmployeeOtherDetails(t ,this.EmployeeMasterService.SharedEmployees).subscribe(res=>
      {
        this.OtherRefID=Number(res);
        if(this.OtherRefID>0)
        {
          this.EmployeeMasterService.SaveOrUpdate=2;
          this.Tabindex=3;         
          this.notificationsService.emit(this.Tabindex);
          this.rout.navigateByUrl('/Employee/EmployeeDetails/EducationalDetails');
        }
      });                  
    }                             
    else
    {
      this.EmployeeMasterService.AddEmployeeOthersDetails(t ,this.EmployeeMasterService.SharedEmployees).subscribe(res=>
      {
        this.OtherRefID=Number(res);
        if(this.OtherRefID>0)
        {
          this.EmployeeMasterService.SaveOrUpdate=1;         
          this.Tabindex=3;   
          this.notificationsService.emit(this.Tabindex);     
          this.EmployeeMasterService.SharedEmployees.OthesRefId=this.OtherRefID; 
          this.rout.navigateByUrl('/Employee/EmployeeDetails/EducationalDetails');
        }
      }); 
    }
  }
  
  RefreshPage()
  {
    this.rout.navigateByUrl('/Employee/EmployeeOffer', {skipLocationChange: true}).then(()=>this.rout.navigate(["/Employee/EmployeeDetails"]));
  }
}