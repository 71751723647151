export class EmployeeFamilyDetailsModel
{
    public FamilyRefId: Number;
    public FamilyMemberName: string;
    public FamilyRelationId: Number;
    public FamilyRelation: string;
    public FamilyGenderId: Number;
    public FamilyGender: string;
    public FamilyDOB: any;
    public  FamilyDOBPass :string;
    public FamilyQualification: string;
    public FamilyOccuption: string;
    public  EmployeeId : Number;
}