<perfect-scrollbar style="max-width: 1260px; max-height: 584px;" class="mukesh">
  <div class="body-content-area tabcontent" id="link1">
    <div class="row">
        <div class="col-lg-9">
            <div class="row">
                <div class="col-lg-1 low-padding text-center">
                    <!-- <img *ngIf="this.SelectedMenu.Image!=null" src={{this.SelectedMenu.Image}} class="body-icon" alt="Icon"/> -->
                </div>
                <div class="col-lg-10 clear-padding">
                    <span class="page-title">Employee Confirmation Approval</span>
                    <small class="page-description"></small>
                </div>
            </div>
        </div>
    </div>
    
    <div *ngIf="!(this.CanViewState)">  
      <fieldset class="body-form-control" > 
        <div>
          <app-dialog [(visible)]="this.SuccessStatus">
            <span class="successmsg">{{this.SuccessMessage}}</span>
          </app-dialog>
        </div>
        <legend class="legend--">Employee Confirmation Approval</legend>
        <form #EmployeeConfirmation="ngForm">
          <div class="row"> 
            <div class="col-lg-4">
              <div class="col-body-border-right">
                <div class="form-group row" >
                  <label class="col-sm-4 col-form-label clear-padding-right">Employee</label>
                  <div class="col-sm-7 clear-left-padding">
                    <select [(ngModel)]="EmployeeConfirmationModel.EmployeeId" tabindex="1" id ="EmployeeId" (ngModelChange)="OnEmployeeddlChange($event)" class="form-control" name="EmployeeId" #EmployeeId="ngModel" >
                      <option [value]="0" [disabled]=true >Select Employee</option>
                      <option *ngFor="let emp of EmployeeDetails" [value]="emp.EmployeeId">{{emp.EmployeeCode}} - {{emp.EmployeeName}}</option>
                    </select>
                  </div>
                  <div class="required-validation" *ngIf="EmployeeConfirmationModel.EmployeeId==0">Employee is required</div>
                </div>

                <div class="form-group row">
                  <label  class="col-sm-4 col-form-label clear-padding-right">Designation</label>
                  <div class="col-sm-7 clear-left-padding">
                    :&nbsp;&nbsp;{{EmployeeConfirmationModel.Designation}}
                  </div>
                </div>

                <div class="form-group row">
                  <label  class="col-sm-4 col-form-label clear-padding-right">Joining Date</label>
                  <div class="col-sm-7 clear-left-padding">
                    :&nbsp;&nbsp;{{EmployeeConfirmationModel.JoingingDate | date : "dd-MMM-yyyy"}}
                  </div>
                </div>
              </div>  
            </div>
      
            <div class="col-lg-4">
              <div class="col-body-border-right">
                <div class="form-group row">
                  <label  class="col-sm-4 col-form-label clear-padding-right">Function Head</label>
                  <div class="col-sm-7 clear-left-padding">
                    :&nbsp;&nbsp;{{EmployeeConfirmationModel.FHCode}}-{{EmployeeConfirmationModel.FHName}}
                  </div>
                </div>

                <div class="form-group row">
                  <label  class="col-sm-4 col-form-label clear-padding-right">Department</label>
                  <div class="col-sm-7 clear-left-padding">
                    :&nbsp;&nbsp;{{EmployeeConfirmationModel.Department}}
                  </div>
                </div>

                <div class="form-group row">
                  <label class="col-sm-4 col-form-label clear-padding-right">Confirmed Date</label>
                  <div class="col-sm-7 clear-left-padding">
                    <input class="form-control" style="float:none" placeholder="Select date" ngx-mydatepicker name="ConfirmedDate" tabindex="2"  [(ngModel)]="EmployeeConfirmationModel.ConfirmedDate" [options]="myOptions" #ConfirmedDate="ngx-mydatepicker" (dateChanged)="onDateChanged($event)" required />
                    <span class="mt-2">
                        <a class="btn btn-primary pl-10 pr-10 pt-1 pb-1" (click)="ConfirmedDate.toggleCalendar()">
                            <i style="color:#fff" class="fas fa-calendar-alt fa-lg"></i>
                        </a>
                        <a class="btn btn-danger pl-10 pr-10 pt-1 pb-1" (click)="ConfirmedDate.clearDate()">
                            <i style="color:#fff" class="far fa-calendar-times fa-lg"></i>
                        </a>
                    </span>
                    <div *ngIf="EmployeeConfirmationModel.ConfirmedDatePass==null" class="required-validation">Confirmed Date is required.</div>
                  </div>
                </div>
              </div> 
            </div>
      
            <div class="col-lg-4">
              <div class="form-group row">
                <label  class="col-sm-4 col-form-label clear-padding-right">Approver</label>
                <div class="col-sm-7 clear-left-padding">
                  :&nbsp;&nbsp;{{EmployeeConfirmationModel.RHCode}}-{{EmployeeConfirmationModel.RHName}}
                </div>
              </div>

              <div class="form-group row">
                <label  class="col-sm-4 col-form-label clear-padding-right">Branch</label>
                <div class="col-sm-7 clear-left-padding">
                  :&nbsp;&nbsp;{{EmployeeConfirmationModel.Branch}}
                </div>
              </div>

              <div class="form-group row">
                <label class="col-sm-4 col-form-label clear-padding-right">Remarks</label>
                <div class="col-sm-7 clear-left-padding">
                  <input type="text" [(ngModel)]="this.EmployeeConfirmationModel.Remarks" tabindex="1" maxlength="500" id ="Remarks" name="Remarks" #Remarks="ngModel" required class="form-control form-control-custom form-control-custom-new" >
                </div>
                <div *ngIf="Remarks.invalid && (Remarks.dirty || Remarks.touched)" class="alert alert-danger">
                  <div *ngIf="Remarks.errors.required">Remarks is required.</div>
                  <div *ngIf="Remarks.errors.maxlength">Remarks is not more than 500 characters</div>
                </div> 
              </div>
              <div class="form-group row">
                <label *ngIf="!(this.CanApproveState)" class="col-sm-6 col-form-label text-center clear-padding-right">
                  <button type="submit" tabindex="2" (click)="ConfirmationApproved()" class="btn btn-primary save-button" [disabled]="!(EmployeeConfirmation.valid && this.DisableSave==true)">Confirmed</button>  
                </label>
                <div class="col-sm-6 col-form-label text-left">
                  <button type="button" tabindex="4" (click)="RefreshPage()" class="btn btn-danger cancle-button">Cancel</button>
                </div>
              </div>
            </div> 
          </div> 
        </form>
      </fieldset>
    </div>
  </div>
  </perfect-scrollbar>