<div class="body-content-area tabcontent" id="link1">
  <div class="row">
    <div class="col-lg-9">
      <div class="row">
        <div class="col-lg-1 low-padding text-center">
          <!-- <img *ngIf="this.SelectedMenu.Image!=null" src={{this.SelectedMenu.Image}} class="body-icon" alt="Icon"/> -->
        </div>
        <div class="col-lg-10 clear-padding">
          <span class="page-title">Manage Financial Year</span>
          <small class="page-description"></small>
        </div>
      </div>
    </div>
  </div>
  
  <div *ngIf="!(this.CanViewState)">  
  <table id="tblFinYear" datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" class="table table-striped table-bordered text-center table-new row-border hover">
    <thead class="thead-blue text-center">
      <tr>
        <th>Sl No.</th>
        <th>Financial Year</th>
        <th>From Date</th>
        <th>To Date</th>
        <th>Year</th>
        <th>Status</th>
        <th>Action</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let FinYear of FinancialYears, let i = index">
        <td scope="row">{{i+1}}</td>
        <td>{{FinYear.FinancialYear}}</td>
        <td>{{FinYear.FromDate | date : "dd-MMM-yyyy"}}</td>
        <td>{{FinYear.ToDate | date : "dd-MMM-yyyy"}}</td>
        <td>{{FinYear.Year}}</td>
        <td>{{FinYear.Status}}</td>
        <td>
          <button type="button" *ngIf="this.SelectedMenu.CanEdit" (click)="GetFinancialYearByid( FinYear.FinancialyearId )"class="table-edit-btn"><i class="fas fa-edit"></i>  </button> 
          <button type="button" *ngIf="this.SelectedMenu.CanDelete" (click)="DeleteFinancialYearByid( FinYear.FinancialyearId )" class="table-remove-btn"><i class="fas fa-trash-alt"></i></button>
          <button type="button" *ngIf="this.SelectedMenu.CanView" (click)="ViewFinancialYearByid( FinYear.FinancialyearId )" class="table-view-button" type="button" ><i class="fas fa-eye"></i></button>
        </td>
      </tr>
    </tbody>
  </table>

  <fieldset class="body-form-control">
    <app-dialog [(visible)]="this.SuccessStatus">
      <span class="successmsg">{{this.SuccessMessage}}</span>
    </app-dialog>
    <legend class="legend--">Financial Year Creation</legend>
      <form #FinancialYearForm="ngForm" (ngSubmit)="saveFinancialYear()">
        <div class="row">
          <div class="col-lg-4">
            <div class="col-body-border-right">
              <div class="form-group row">
                <label  class="col-sm-4 col-form-label clear-padding-right">From Date</label>
                <div class="col-sm-7 clear-left-padding">
                  <input type="date" name="FromDate" tabindex="1" [ngModel]="FinYearModel.FromDate | date:'yyyy-MM-dd'" (ngModelChange)="FromDateChanged($event)" #FromDate="ngModel" required />
                  <label *ngIf="(!FromDate)" >Select Valid Date</label>
                </div>
                <div *ngIf="this.Codecheck.length > 0" class="alert alert-danger">Financial Year is exist</div>
              </div>
              <div class="form-group row">
              </div>
            </div>
          </div>

          <div class="col-lg-4">
            <div class="col-body-border-right">
              <div class="form-group row">
                <label  class="col-sm-4 col-form-label clear-padding-right">To Date</label>
                <div class="col-sm-7 clear-left-padding">
                  <input type="date" name="ToDate" tabindex="2" [ngModel]="FinYearModel.ToDate | date:'yyyy-MM-dd'" (ngModelChange)="ToDateChanged($event)" #ToDate="ngModel" required />
                  <label *ngIf="(!ToDate)" >Select Valid Date</label>
                </div>
                <div *ngIf="this.Codecheck.length > 0" class="alert alert-danger">Financial Year is exist</div>
              </div>
              <div class="form-group row">

              </div>
            </div>
          </div>

          <div class="col-lg-4">
            <div class="form-group row">
                <label  class="col-sm-4 col-form-label clear-padding-right">Status</label>
                <div class="col-sm-7 clear-left-padding">
                  <select [(ngModel)]="FinYearModel.StatusId" tabindex="5" id ="StatusId" class="form-control" name="StatusId" #StatusId="ngModel" >
                    <option selected="selected" [value]="0" [disabled]=true >Select Status</option>
                    <option [value]="1">Live</option>
                    <option [value]="2">Close</option>
                    <option [value]="3">Next</option>
                  </select>
                </div>
                <div *ngIf="StatusId.invalid && (StatusId.dirty || StatusId.touched)" class="alert alert-danger">
                  <div *ngIf="StatusId.errors.required">Status is required.</div>
                </div>
            </div>
            <div class="form-group row">
              
            </div>
            <div class="form-group row">
              <label class="col-sm-6 col-form-label text-center clear-padding-right" *ngIf="!(this.IsDataIsInViewState)">
                <button type="submit" tabindex="10" class="btn btn-primary save-button" [disabled]="!(FinancialYearForm.valid && this.DisableSave==true)" >Save</button>  
              </label>
              <div class="col-sm-6 col-form-label text-center">
                <button type="button" tabindex="11" class="btn btn-danger cancle-button" (click)="RefreshPage()">Cancel</button>
              </div>
            </div>
          </div>
        </div>
      </form>
  </fieldset>
  </div>
</div>