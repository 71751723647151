import { Injectable,Inject } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';  
import { Http, Headers, RequestOptions, Response } from '@angular/http';  
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import {EmployeeExprienceModel} from '../../../shared/HR_ADMIN/Employee/EmployeeExprienceModel';

import 'rxjs/add/operator/toPromise';
import { Subscriber } from 'rxjs';
import { GlobalVariable  } from '../../../app.global';
import { OfferDetails } from   '../Employee/OfferDetails.Model';
import { JoiningDetails } from   '../Employee/JoiningDetails.Model';
import { EmployeeDetail } from   '../Employee/EmployeeDetail.Model';
import { EmployeeBank } from   '../Employee/EmployeeBank.Model';
import { EmployeeEducationalModel } from './EmployeeEducationalModel';
import { EmployeeFamilyDetailsModel } from '../../../shared/HR_ADMIN/Employee/EmployeeFamilyDetailsModel';
import { EmployeeNomineeDetailsModel } from '../../../shared/HR_ADMIN/Employee/EmployeeNomineeDetailsModel';
import { StandardSalaryModel } from './StandardSalaryModel.Model';
import { BirthDayAnniversaryModel } from './BirthDayAnniversaryModel.Model';

@Injectable()
export class EmployeeMasterService 
{
    SharedEmployees: EmployeeDetail ;
    constructor(private http: HttpClient,private spinnerService: Ng4LoadingSpinnerService) 
    {
        this.SharedEmployees=new EmployeeDetail();
    }
     
    SaveOrUpdate : number =0;

    // Begning of the Offer Details
    GetOfferDetails(token )
    {
        this.spinnerService.show();
        const authHeader = new HttpHeaders();     
        authHeader.append('Content-Type', 'application/json');
        authHeader.append('Authorization', token);
        return new Promise((resolve, reject) =>
        {
            setTimeout(()=>
            {
                this.http.get(GlobalVariable.BASE_API_URL +'/api/OfferLetterDetails/GetOfferLetterDetails',{headers: authHeader})
                .subscribe(data => 
                {
                    this.spinnerService.hide();
                    resolve(data)
                },
                error => 
                {
                    this.spinnerService.hide();
                    reject(error);
                },);
            },3000);
        });
    }
    GetOfferDetailsByid(token, id:number ) 
    {
        this.spinnerService.show();
        const authHeader = new HttpHeaders();     
        authHeader.append('Content-Type', 'application/json');
        authHeader.append('Authorization', token);
        return new Promise((resolve, reject) => 
        {
            setTimeout(()=>
            {
                this.http.get(GlobalVariable.BASE_API_URL +'/api/OfferLetterDetails/GetOfferLetterDetailsById?OfferId='+id,{headers: authHeader})
                .subscribe(data => 
                {
                    this.spinnerService.hide();
                    resolve(data)
                },
                error => 
                {
                    this.spinnerService.hide();
                    reject(error);
                },);
            },3000);
        });
    }
    AddOfferDetails( token ,Offers :OfferDetails) 
    {
        const authHeader = new HttpHeaders();         
        authHeader.append('Content-Type', 'application/json');
        authHeader.append('Authorization', token);
        authHeader.append('Accept', 'application/json');
        return this.http.post(GlobalVariable.BASE_API_URL +'/api/OfferLetterDetails/Add', Offers ,{headers: authHeader} )
        .map(this.extractData)
        .catch(this.handleErrorObservable);
    }
    DeleteOfferDetails( token ,Offerid : number) 
    {
        const authHeader = new HttpHeaders();         
        authHeader.append('Content-Type', 'application/json');
        authHeader.append('Authorization', token);
        authHeader.append('Accept', 'application/json');
        return this.http.put(GlobalVariable.BASE_API_URL +'/api/OfferLetterDetails/Delete', Offerid ,{headers: authHeader} )
        .map(this.extractData)
        .catch(this.handleErrorObservable);
    }
    UpdateOfferDetails( token ,Offer :OfferDetails) 
    {
        const authHeader = new HttpHeaders();         
        authHeader.append('Content-Type', 'application/json');
        authHeader.append('Authorization', token);
        authHeader.append('Accept', 'application/json');
        return this.http.put(GlobalVariable.BASE_API_URL +'/api/OfferLetterDetails/Update', Offer ,{headers: authHeader} )
        .map(this.extractData)
        .catch(this.handleErrorObservable);
    }
    // End of the Offer Details
    // Begning of the Joining Details
    GetOfferDetailsForJoining(token )
    {
        this.spinnerService.show();
        const authHeader = new HttpHeaders();     
        authHeader.append('Content-Type', 'application/json');
        authHeader.append('Authorization', token);
        return new Promise((resolve, reject) =>
        {
            setTimeout(()=>
            {
                this.http.get(GlobalVariable.BASE_API_URL +'/api/JoiningDetails/GetOfferLetterDetailsForJoiningGV',{headers: authHeader})
                .subscribe(data => 
                {
                    this.spinnerService.hide();
                    resolve(data)
                },
                error => 
                {
                    this.spinnerService.hide();
                    reject(error);
                },);
            },3000);
        });
    }
    GetOfferDetailsByidforJoining(token, id:number ) 
    {
        this.spinnerService.show();
        const authHeader = new HttpHeaders();     
        authHeader.append('Content-Type', 'application/json');
        authHeader.append('Authorization', token);
        return new Promise((resolve, reject) => 
        {
            setTimeout(()=>
            {
                this.http.get(GlobalVariable.BASE_API_URL +'/api/JoiningDetails/GetOfferLetterDetailsforjoiningById?OfferId='+id,{headers: authHeader})
                .subscribe(data => 
                {
                    this.spinnerService.hide();
                    resolve(data)
                },
                error => 
                {
                    this.spinnerService.hide();
                    reject(error);
                },);
            },3000);
        });
    }
    GetJoiningDetails(token )
    {
        this.spinnerService.show();
        const authHeader = new HttpHeaders();     
        authHeader.append('Content-Type', 'application/json');
        authHeader.append('Authorization', token);
        return new Promise((resolve, reject) =>
        {
            setTimeout(()=>
            {
                this.http.get(GlobalVariable.BASE_API_URL +'/api/JoiningDetails/GetJoiningDetails',{headers: authHeader})
                .subscribe(data => 
                {
                    this.spinnerService.hide();
                    resolve(data)
                },
                error => 
                {
                    this.spinnerService.hide();
                    reject(error);
                },);
            },3000);
        });
    }
    GetJoiningDetailsById(token, id:number ) 
    {
        this.spinnerService.show();
        const authHeader = new HttpHeaders();     
        authHeader.append('Content-Type', 'application/json');
        authHeader.append('Authorization', token);
        return new Promise((resolve, reject) => 
        {
            setTimeout(()=>
            {
                this.http.get(GlobalVariable.BASE_API_URL +'/api/JoiningDetails/GetJoiningDetailsById?JoiningId='+id,{headers: authHeader})
                .subscribe(data => 
                {
                    this.spinnerService.hide();
                    resolve(data)
                },
                error => 
                {
                    this.spinnerService.hide();
                    reject(error);
                },);
            },3000);
        });
    }
    AddJoiningDetails( token ,Joining :JoiningDetails) 
    {
        const authHeader = new HttpHeaders();         
        authHeader.append('Content-Type', 'application/json');
        authHeader.append('Authorization', token);
        authHeader.append('Accept', 'application/json');
        return this.http.post(GlobalVariable.BASE_API_URL +'/api/JoiningDetails/Add', Joining ,{headers: authHeader} )
        .map(this.extractData)
        .catch(this.handleErrorObservable);
    }
    DeleteJoiningDetails( token ,Joiningid : number) 
    {
        const authHeader = new HttpHeaders();         
        authHeader.append('Content-Type', 'application/json');
        authHeader.append('Authorization', token);
        authHeader.append('Accept', 'application/json');
        return this.http.put(GlobalVariable.BASE_API_URL +'/api/JoiningDetails/Delete', Joiningid ,{headers: authHeader} )
        .map(this.extractData)
        .catch(this.handleErrorObservable);
    }
    UpdateJoiningDetails( token ,Joining :JoiningDetails) 
    {
        const authHeader = new HttpHeaders();         
        authHeader.append('Content-Type', 'application/json');
        authHeader.append('Authorization', token);
        authHeader.append('Accept', 'application/json');
        return this.http.put(GlobalVariable.BASE_API_URL +'/api/JoiningDetails/Update', Joining ,{headers: authHeader} )
        .map(this.extractData)
        .catch(this.handleErrorObservable);
    }
    // End of the JOininig Details

    // Begning of the Employee Details
    GetJoiningDetailsForNew(token )
    {
        this.spinnerService.show();
        const authHeader = new HttpHeaders();     
        authHeader.append('Content-Type', 'application/json');
        authHeader.append('Authorization', token);
        return new Promise((resolve, reject) =>
        {
            setTimeout(()=>
            {
                this.http.get(GlobalVariable.BASE_API_URL +'/api/EmployeeDetails/GetJoiningDetailsForEmployeeGV',{headers: authHeader})
                .subscribe(data => 
                {
                    this.spinnerService.hide();
                    resolve(data)
                },
                error => 
                {
                    this.spinnerService.hide();
                    reject(error);
                },);
            },3000);
        });
    }

    GetJoiningDetailsByJoiningID(token, JoiningID )
    { 
        this.spinnerService.show();
        const authHeader = new HttpHeaders();     
        authHeader.append('Content-Type', 'application/json');
        authHeader.append('Authorization', token);
        return new Promise((resolve, reject) =>
        {
            setTimeout(()=>
            {
                this.http.get(GlobalVariable.BASE_API_URL +'/api/EmployeeDetails/GetJoiningDetailsByJoiningID?JoiningID='+JoiningID,{headers: authHeader})
                .subscribe(data => 
                {
                    this.spinnerService.hide();
                    resolve(data)
                },
                error => 
                {
                    this.spinnerService.hide();
                    reject(error);
                },);
            },3000);
        });
    }

    GetEmployeeDetails(token )
    {
        this.spinnerService.show();
        const authHeader = new HttpHeaders();     
        authHeader.append('Content-Type', 'application/json');
        authHeader.append('Authorization', token);
        return new Promise((resolve, reject) =>
        {
            setTimeout(()=>
            {
                this.http.get(GlobalVariable.BASE_API_URL +'/api/EmployeeDetails/GetEmployeeDetailsForGV',{headers: authHeader})
                .subscribe(data => 
                {
                    this.spinnerService.hide();
                    resolve(data)
                },
                error => 
                {
                    this.spinnerService.hide();
                    reject(error);
                },);
            },3000);
        });
    }
    
    //Begin Add Employee Information
    AddEmployeePersonalDetails( token ,EmployeeDetails :EmployeeDetail) 
    {
        const authHeader = new HttpHeaders();         
        authHeader.append('Content-Type', 'application/json');
        authHeader.append('Authorization', token);
        authHeader.append('Accept', 'application/json');
        return this.http.post(GlobalVariable.BASE_API_URL +'/api/EmployeeDetails/AddPersonal', EmployeeDetails ,{headers: authHeader} )
        .map(this.extractData)
        .catch(this.handleErrorObservable);
    }
    AddEmployeeEducationDetails( token ,Education :EmployeeEducationalModel) 
    {
        const authHeader = new HttpHeaders();         
        authHeader.append('Content-Type', 'application/json');
        authHeader.append('Authorization', token);
        authHeader.append('Accept', 'application/json');
        return this.http.post(GlobalVariable.BASE_API_URL +'/api/EmployeeDetails/AddEducation', Education ,{headers: authHeader} )
        .map(this.extractData)
        .catch(this.handleErrorObservable);
    }
    AddEmployeeExperienceDetails( token ,Experience :EmployeeExprienceModel) 
    {
        const authHeader = new HttpHeaders();         
        authHeader.append('Content-Type', 'application/json');
        authHeader.append('Authorization', token);
        authHeader.append('Accept', 'application/json');
        return this.http.post(GlobalVariable.BASE_API_URL +'/api/EmployeeDetails/AddExperience', Experience ,{headers: authHeader} )
        .map(this.extractData)
        .catch(this.handleErrorObservable);
    }
    AddEmployeeFamilyDetails( token ,Family :EmployeeFamilyDetailsModel) 
    {
        const authHeader = new HttpHeaders();         
        authHeader.append('Content-Type', 'application/json');
        authHeader.append('Authorization', token);
        authHeader.append('Accept', 'application/json');
        return this.http.post(GlobalVariable.BASE_API_URL +'/api/EmployeeDetails/AddFamily', Family ,{headers: authHeader} )
        .map(this.extractData)
        .catch(this.handleErrorObservable);
    }
    AddEmployeeNomineeDetails( token ,Nominee :EmployeeNomineeDetailsModel) 
    {
        const authHeader = new HttpHeaders();         
        authHeader.append('Content-Type', 'application/json');
        authHeader.append('Authorization', token);
        authHeader.append('Accept', 'application/json');
        return this.http.post(GlobalVariable.BASE_API_URL +'/api/EmployeeDetails/AddNominee', Nominee ,{headers: authHeader} )
        .map(this.extractData)
        .catch(this.handleErrorObservable);
    }
    AddEmployeeOthersDetails( token ,Others :EmployeeDetail) 
    {
        const authHeader = new HttpHeaders();         
        authHeader.append('Content-Type', 'application/json');
        authHeader.append('Authorization', token);
        authHeader.append('Accept', 'application/json');
        return this.http.post(GlobalVariable.BASE_API_URL +'/api/EmployeeDetails/AddOthers', Others ,{headers: authHeader} )
        .map(this.extractData)
        .catch(this.handleErrorObservable);
    }
    AddEmployeeAddressDetails( token ,Address :EmployeeDetail) 
    {
        const authHeader = new HttpHeaders();         
        authHeader.append('Content-Type', 'application/json');
        authHeader.append('Authorization', token);
        authHeader.append('Accept', 'application/json');
        return this.http.post(GlobalVariable.BASE_API_URL +'/api/EmployeeDetails/AddAddress', Address ,{headers: authHeader} )
        .map(this.extractData)
        .catch(this.handleErrorObservable);
    }
    //End Add Employee Information

    //Begin Update Employee Information
    UpdateEmployeePersonalDetails( token ,EmployeeDetails :EmployeeDetail) 
    {
        const authHeader = new HttpHeaders();         
        authHeader.append('Content-Type', 'application/json');
        authHeader.append('Authorization', token);
        authHeader.append('Accept', 'application/json');
        return this.http.put(GlobalVariable.BASE_API_URL +'/api/EmployeeDetails/UpdatePersonal', EmployeeDetails ,{headers: authHeader} )
        .map(this.extractData)
        .catch(this.handleErrorObservable);
    }
    UpdateEmployeeEducationDetails( token ,Education :EmployeeEducationalModel) 
    {
        const authHeader = new HttpHeaders();         
        authHeader.append('Content-Type', 'application/json');
        authHeader.append('Authorization', token);
        authHeader.append('Accept', 'application/json');
        return this.http.put(GlobalVariable.BASE_API_URL +'/api/EmployeeDetails/UpdateEducation', Education ,{headers: authHeader} )
        .map(this.extractData)
        .catch(this.handleErrorObservable);
    }
    UpdateEmployeeExperienceDetails( token ,Experience :EmployeeExprienceModel) 
    {
        const authHeader = new HttpHeaders();         
        authHeader.append('Content-Type', 'application/json');
        authHeader.append('Authorization', token);
        authHeader.append('Accept', 'application/json');
        return this.http.put(GlobalVariable.BASE_API_URL +'/api/EmployeeDetails/UpdateExperience', Experience ,{headers: authHeader} )
        .map(this.extractData)
        .catch(this.handleErrorObservable);
    }
    UpdateEmployeeFamilyDetails( token ,Family :EmployeeFamilyDetailsModel) 
    {
        const authHeader = new HttpHeaders();         
        authHeader.append('Content-Type', 'application/json');
        authHeader.append('Authorization', token);
        authHeader.append('Accept', 'application/json');
        return this.http.put(GlobalVariable.BASE_API_URL +'/api/EmployeeDetails/UpdateFamily', Family ,{headers: authHeader} )
        .map(this.extractData)
        .catch(this.handleErrorObservable);
    }
    UpdateEmployeeNomineeDetails( token ,Nominee :EmployeeNomineeDetailsModel) 
    {
        const authHeader = new HttpHeaders();         
        authHeader.append('Content-Type', 'application/json');
        authHeader.append('Authorization', token);
        authHeader.append('Accept', 'application/json');
        return this.http.put(GlobalVariable.BASE_API_URL +'/api/EmployeeDetails/UpdateNominee', Nominee ,{headers: authHeader} )
        .map(this.extractData)
        .catch(this.handleErrorObservable);
    }
    UpdateEmployeeOtherDetails( token ,Others :EmployeeDetail) 
    {
        const authHeader = new HttpHeaders();         
        authHeader.append('Content-Type', 'application/json');
        authHeader.append('Authorization', token);
        authHeader.append('Accept', 'application/json');
        return this.http.put(GlobalVariable.BASE_API_URL +'/api/EmployeeDetails/UpdateOthers', Others ,{headers: authHeader} )
        .map(this.extractData)
        .catch(this.handleErrorObservable);
    }
    UpdateEmployeeAddressDetails( token ,Address :EmployeeDetail) 
    {
        const authHeader = new HttpHeaders();         
        authHeader.append('Content-Type', 'application/json');
        authHeader.append('Authorization', token);
        authHeader.append('Accept', 'application/json');
        return this.http.put(GlobalVariable.BASE_API_URL +'/api/EmployeeDetails/UpdateAddress', Address ,{headers: authHeader} )
        .map(this.extractData)
        .catch(this.handleErrorObservable);
    }

    AddPersonalModificationApplication( token ,EmployeeDetails :EmployeeDetail) 
    {
        const authHeader = new HttpHeaders();         
        authHeader.append('Content-Type', 'application/json');
        authHeader.append('Authorization', token);
        authHeader.append('Accept', 'application/json');
        return this.http.post(GlobalVariable.BASE_API_URL +'/api/EmployeeDetails/AddPersonalModificationApplication', EmployeeDetails ,{headers: authHeader} )
        .map(this.extractData)
        .catch(this.handleErrorObservable);
    }
    CheckEmployeeModoificationApplicationExists(token)
    {
        const authHeader = new HttpHeaders();     
        authHeader.append('Content-Type', 'application/json');
        authHeader.append('Authorization', token);
        return new Promise((resolve, reject) =>
        {       setTimeout(()=>{

        this.http.get(GlobalVariable.BASE_API_URL +'/api/EmployeeDetails/CheckEmployeeModoificationApplicationExists',{headers: authHeader})
        .subscribe(data => 
        {
            resolve(data)
        },
        error => 
        {
            reject(error);
        },);
        },3000);

        });
    }
    //End Update Employee Information

    //Begin Select Employee Information By EmployeeId

    GetEmployeedetailsByEmployeeID(token, EmployeeID )
    { 
        this.spinnerService.show();
        const authHeader = new HttpHeaders();     
        authHeader.append('Content-Type', 'application/json');
        authHeader.append('Authorization', token);
        return new Promise((resolve, reject) =>
        {
            setTimeout(()=>
            {
                this.http.get(GlobalVariable.BASE_API_URL +'/api/EmployeeDetails/GetEmployeeDetailByID?EmpId='+EmployeeID,{headers: authHeader})
                .subscribe(data => 
                {
                    this.spinnerService.hide();
                    resolve(data)
                },
                error => 
                {
                    this.spinnerService.hide();
                    reject(error);
                },);
            },3000);
        });
    }

    GetEmployeePersonalDetailsByEmployeeId(token, id:number ) 
    {
        this.spinnerService.show();
        const authHeader = new HttpHeaders();     
        authHeader.append('Content-Type', 'application/json');
        authHeader.append('Authorization', token);
        return new Promise((resolve, reject) => 
        {
            setTimeout(()=>
            {
                this.http.get(GlobalVariable.BASE_API_URL +'/api/EmployeeDetails/GetEmployeePersonalDetailsByEmployeeId?EmployeeId='+id,{headers: authHeader})
                .subscribe(data => 
                {
                    this.spinnerService.hide();
                    resolve(data)
                },
                error => 
                {
                    this.spinnerService.hide();
                    reject(error);
                },);
            },3000);
        });
    }
    GetEmployeeEducationDetailsByEmployeeId(token, id:number ) 
    {
        this.spinnerService.show();
        const authHeader = new HttpHeaders();     
        authHeader.append('Content-Type', 'application/json');
        authHeader.append('Authorization', token);
        return new Promise((resolve, reject) => 
        {
            setTimeout(()=>
            {
                this.http.get(GlobalVariable.BASE_API_URL +'/api/EmployeeDetails/GetEmployeeEducationDetailsByEmployeeId?EmployeeId='+id,{headers: authHeader})
                .subscribe(data => 
                {
                    this.spinnerService.hide();
                    resolve(data)
                },
                error => 
                {
                    this.spinnerService.hide();
                    reject(error);
                },);
            },3000);
        });
    }
    GetEmployeeExperienceDetailsByEmployeeId(token, id:number ) 
    {
        this.spinnerService.show();
        const authHeader = new HttpHeaders();     
        authHeader.append('Content-Type', 'application/json');
        authHeader.append('Authorization', token);
        return new Promise((resolve, reject) => 
        {
            setTimeout(()=>
            {
                this.http.get(GlobalVariable.BASE_API_URL +'/api/EmployeeDetails/GetEmployeeExperienceDetailsByEmployeeId?EmployeeId='+id,{headers: authHeader})
                .subscribe(data => 
                {
                    this.spinnerService.hide();
                    resolve(data)
                },
                error => 
                {
                    this.spinnerService.hide();
                    reject(error);
                },);
            },3000);
        });
    }
      
    GetEmployeeFamilyDetailsByEmployeeId(token, id:number ) 
    {
        this.spinnerService.show();
        const authHeader = new HttpHeaders();     
        authHeader.append('Content-Type', 'application/json');
        authHeader.append('Authorization', token);
        return new Promise((resolve, reject) => 
        {
            setTimeout(()=>
            {
                this.http.get(GlobalVariable.BASE_API_URL +'/api/EmployeeDetails/GetEmployeeFamilyDetailsByEmployeeId?EmployeeId='+id,{headers: authHeader})
                .subscribe(data => 
                {
                    this.spinnerService.hide();
                    resolve(data)
                },
                error => 
                {
                    this.spinnerService.hide();
                    reject(error);
                },);
            },3000);
        });
    }
    GetEmployeeNomineeDetailsByEmployeeId(token, id:number ) 
    {
        this.spinnerService.show();
        const authHeader = new HttpHeaders();     
        authHeader.append('Content-Type', 'application/json');
        authHeader.append('Authorization', token);
        return new Promise((resolve, reject) => 
        {
            setTimeout(()=>
            {
                this.http.get(GlobalVariable.BASE_API_URL +'/api/EmployeeDetails/GetEmployeeNomineeDetailsByEmployeeId?EmployeeId='+id,{headers: authHeader})
                .subscribe(data => 
                {
                    this.spinnerService.hide();
                    resolve(data)
                },
                error => 
                {
                    this.spinnerService.hide();
                    reject(error);
                },);
            },3000);
        });
    }
    GetEmployeeOtherDetailsByEmployeeId(token, id:number ) 
    {
        this.spinnerService.show();
        const authHeader = new HttpHeaders();     
        authHeader.append('Content-Type', 'application/json');
        authHeader.append('Authorization', token);
        return new Promise((resolve, reject) => 
        {
            setTimeout(()=>
            {
                this.http.get(GlobalVariable.BASE_API_URL +'/api/EmployeeDetails/GetEmployeeOtherDetailsByEmployeeId?EmployeeId='+id,{headers: authHeader})
                .subscribe(data => 
                {
                    this.spinnerService.hide();
                    resolve(data)
                },
                error => 
                {
                    this.spinnerService.hide();
                    reject(error);
                },);
            },3000);
        });
    }
    GetEmployeeBankDetailsByEmployeeId(token, id:number ) 
    {
        this.spinnerService.show();
        const authHeader = new HttpHeaders();     
        authHeader.append('Content-Type', 'application/json');
        authHeader.append('Authorization', token);
        return new Promise((resolve, reject) => 
        {
            setTimeout(()=>
            {
                this.http.get(GlobalVariable.BASE_API_URL +'/api/EmployeeDetails/GetEmployeeBankDetailsByEmployeeId?EmployeeId='+id,{headers: authHeader})
                .subscribe(data => 
                {
                    this.spinnerService.hide();
                    resolve(data)
                },
                error => 
                {
                    this.spinnerService.hide();
                    reject(error);
                },);
            },3000);
        });
    }
    GetEmployeeAddressDetailsByEmployeeId(token, id:number ) 
    {
        this.spinnerService.show();
        const authHeader = new HttpHeaders();     
        authHeader.append('Content-Type', 'application/json');
        authHeader.append('Authorization', token);
        return new Promise((resolve, reject) => 
        {
            setTimeout(()=>
            {
                this.http.get(GlobalVariable.BASE_API_URL +'/api/EmployeeDetails/GetEmployeeAddressDetailsByEmployeeId?EmployeeId='+id,{headers: authHeader})
                .subscribe(data => 
                {
                    this.spinnerService.hide();
                    resolve(data)
                },
                error => 
                {
                    this.spinnerService.hide();
                    reject(error);
                },);
            },3000);
        });
    }
    //End Select Employee Information By EmployeeId
    DeleteEmployeeDetails( token ,Employeeid : number) 
    {
        const authHeader = new HttpHeaders();         
        authHeader.append('Content-Type', 'application/json');
        authHeader.append('Authorization', token);
        authHeader.append('Accept', 'application/json');
        return this.http.put(GlobalVariable.BASE_API_URL +'/api/EmployeeDetails/Delete', Employeeid ,{headers: authHeader} )
        .map(this.extractData)
        .catch(this.handleErrorObservable);
    }

    CheckemployeeCode(token, code )
    {
        const authHeader = new HttpHeaders();     
        authHeader.append('Content-Type', 'application/json');
        authHeader.append('Authorization', token);
        return new Promise((resolve, reject) =>
        {       setTimeout(()=>{

        this.http.get(GlobalVariable.BASE_API_URL +'/api/EmployeeDetails/CheckEmployeeCode?EmployeeCode='+code,{headers: authHeader})
        .subscribe(data => 
        {
            resolve(data)
        },
        error => 
        {
            reject(error);
        },);
        },3000);

        });
    }

    GetEmployeeStandardSalaryByEmployeeId(token, EmployeeId )
    { 
        this.spinnerService.show();
        const authHeader = new HttpHeaders();     
        authHeader.append('Content-Type', 'application/json');
        authHeader.append('Authorization', token);
        return new Promise((resolve, reject) =>
        {
            setTimeout(()=>
            {
                this.http.get(GlobalVariable.BASE_API_URL +'/api/EmployeeStandardSalary/GetEmployeeStandardSalaryByEmployeeId?EmployeeId='+EmployeeId,{headers: authHeader})
                .subscribe(data => 
                {
                    this.spinnerService.hide();
                    resolve(data)
                },
                error => 
                {
                    this.spinnerService.hide();
                    reject(error);
                },);
            },3000);
        });
    }
    
    GetLanguages(token ) 
    {
        this.spinnerService.show();
        const authHeader = new HttpHeaders();     
        authHeader.append('Content-Type', 'application/json');
        authHeader.append('Authorization', token);
        return new Promise((resolve, reject) => 
        {
            setTimeout(()=>
            {
                this.http.get(GlobalVariable.BASE_API_URL +'/api/EmployeeDetails/Languages',{headers: authHeader})
                .subscribe(data => 
                {
                    this.spinnerService.hide();
                    resolve(data)
                },
                error => 
                {
                    this.spinnerService.hide();
                    reject(error);
                },);
            },3000);
        });
    }

    GetReligion(token ) 
    {
        this.spinnerService.show();
        const authHeader = new HttpHeaders();     
        authHeader.append('Content-Type', 'application/json');
        authHeader.append('Authorization', token);
        return new Promise((resolve, reject) => 
        {
            setTimeout(()=>
            {
                this.http.get(GlobalVariable.BASE_API_URL +'/api/EmployeeDetails/ReLigoin',{headers: authHeader})
                .subscribe(data => 
                {
                    this.spinnerService.hide();
                    resolve(data)
                },
                error => 
                {
                    this.spinnerService.hide();
                    reject(error);
                },);
            },3000);
        });
    }

    GetPaymentMode(token ) 
    {
        this.spinnerService.show();
        const authHeader = new HttpHeaders();     
        authHeader.append('Content-Type', 'application/json');
        authHeader.append('Authorization', token);
        return new Promise((resolve, reject) => 
        {
            setTimeout(()=>
            {
                this.http.get(GlobalVariable.BASE_API_URL +'/api/EmployeeDetails/GetPaymentMode',{headers: authHeader})
                .subscribe(data => 
                {
                    this.spinnerService.hide();
                    resolve(data)
                },
                error => 
                {
                    this.spinnerService.hide();
                    reject(error);
                },);
            },3000);
        });
    }

    GetRelation(token ) 
    {
        this.spinnerService.show();
        const authHeader = new HttpHeaders();     
        authHeader.append('Content-Type', 'application/json');
        authHeader.append('Authorization', token);
        return new Promise((resolve, reject) => 
        {
            setTimeout(()=>
            {
                this.http.get(GlobalVariable.BASE_API_URL +'/api/EmployeeDetails/GetRelation',{headers: authHeader})
                .subscribe(data => 
                {
                    this.spinnerService.hide();
                    resolve(data)
                },
                error => 
                {
                    this.spinnerService.hide();
                    reject(error);
                },);
            },3000);
        });
    }

    //Employee Standand salary view for login user
    GetEmployeeDetailsForStandardSalary(token )
    {
        this.spinnerService.show();
        const authHeader = new HttpHeaders();     
        authHeader.append('Content-Type', 'application/json');
        authHeader.append('Authorization', token);
        return new Promise((resolve, reject) =>
        {
            setTimeout(()=>
            {
                this.http.get(GlobalVariable.BASE_API_URL +'/api/EmployeeStandardSalary/GetEmployeeDetailsForStandardSalaryGV',{headers: authHeader})
                .subscribe(data => 
                {
                    this.spinnerService.hide();
                    resolve(data)
                },
                error => 
                {
                    this.spinnerService.hide();
                    reject(error);
                },);
            },3000);
        });
    }

    GetEmployeeStandardSalaryByLoginuser(token )
    { 
        this.spinnerService.show();
        const authHeader = new HttpHeaders();     
        authHeader.append('Content-Type', 'application/json');
        authHeader.append('Authorization', token);
        return new Promise((resolve, reject) =>
        {
            setTimeout(()=>
            {
                this.http.get(GlobalVariable.BASE_API_URL +'/api/EmployeeStandardSalary/GetEmployeeStandardSalaryByLoginuser',{headers: authHeader})
                .subscribe(data => 
                {
                    this.spinnerService.hide();
                    resolve(data)
                },
                error => 
                {
                    this.spinnerService.hide();
                    reject(error);
                },);
            },3000);
        });
    }

    GetEmployeeStandardSalaryModificationByEmployeeId(token, EmployeeId )
    { 
        this.spinnerService.show();
        const authHeader = new HttpHeaders();     
        authHeader.append('Content-Type', 'application/json');
        authHeader.append('Authorization', token);
        return new Promise((resolve, reject) =>
        {
            setTimeout(()=>
            {
                this.http.get(GlobalVariable.BASE_API_URL +'/api/EmployeeStandardSalary/GetEmployeeStandardSalaryByModificationEmployeeId?EmployeeId='+EmployeeId,{headers: authHeader})
                .subscribe(data => 
                {
                    this.spinnerService.hide();
                    resolve(data)
                },
                error => 
                {
                    this.spinnerService.hide();
                    reject(error);
                },);
            },3000);
        });
    }

    UpdateEmployeeStandardSalaryMaster( token ,StandardSalary :StandardSalaryModel) 
    {
        const authHeader = new HttpHeaders();         
        authHeader.append('Content-Type', 'application/json');
        authHeader.append('Authorization', token);
        authHeader.append('Accept', 'application/json');
        return this.http.put(GlobalVariable.BASE_API_URL +'/api/EmployeeStandardSalary/UpdateEmployeeStandardSalaryMaster', StandardSalary ,{headers: authHeader} )
        .map(this.extractData)
        .catch(this.handleErrorObservable);
    }

    UpdateEmployeeStandardSalary( token ,StandardSalary :StandardSalaryModel) 
    {
        const authHeader = new HttpHeaders();         
        authHeader.append('Content-Type', 'application/json');
        authHeader.append('Authorization', token);
        authHeader.append('Accept', 'application/json');
        return this.http.put(GlobalVariable.BASE_API_URL +'/api/EmployeeStandardSalary/UpdateEmployeeStandardSalary', StandardSalary ,{headers: authHeader} )
        .map(this.extractData)
        .catch(this.handleErrorObservable);
    }
     // End of the Employee Details

    // Begning of the Employee Bank Details
    GetEmployeeBankDetails(token )
    {
        this.spinnerService.show();
        const authHeader = new HttpHeaders();     
        authHeader.append('Content-Type', 'application/json');
        authHeader.append('Authorization', token);
        return new Promise((resolve, reject) =>
        {
            setTimeout(()=>
            {
                this.http.get(GlobalVariable.BASE_API_URL +'/api/EmployeeBank/GetEmployeeBank',{headers: authHeader})
                .subscribe(data => 
                {
                    this.spinnerService.hide();
                    resolve(data)
                },
                error => 
                {
                    this.spinnerService.hide();
                    reject(error);
                },);
            },3000);
        });
    }
    GetEmployeeBankDetailsByid(token, id:number ) 
    {
        this.spinnerService.show();
        const authHeader = new HttpHeaders();     
        authHeader.append('Content-Type', 'application/json');
        authHeader.append('Authorization', token);
        return new Promise((resolve, reject) => 
        {
            setTimeout(()=>
            {
                this.http.get(GlobalVariable.BASE_API_URL +'/api/EmployeeBank/GetEmployeeBankById?RefId='+id,{headers: authHeader})
                .subscribe(data => 
                {
                    this.spinnerService.hide();
                    resolve(data)
                },
                error => 
                {
                    this.spinnerService.hide();
                    reject(error);
                },);
            },3000);
        });
    }
    AddEmployeeBankDetails( token ,empbank :EmployeeBank) 
    {
        const authHeader = new HttpHeaders();         
        authHeader.append('Content-Type', 'application/json');
        authHeader.append('Authorization', token);
        authHeader.append('Accept', 'application/json');
        return this.http.post(GlobalVariable.BASE_API_URL +'/api/EmployeeBank/Add', empbank ,{headers: authHeader} )
        .map(this.extractData)
        .catch(this.handleErrorObservable);
    }
    DeleteEmployeeBankDetails( token ,Refid : number) 
    {
        const authHeader = new HttpHeaders();         
        authHeader.append('Content-Type', 'application/json');
        authHeader.append('Authorization', token);
        authHeader.append('Accept', 'application/json');
        return this.http.put(GlobalVariable.BASE_API_URL +'/api/EmployeeBank/Delete', Refid ,{headers: authHeader} )
        .map(this.extractData)
        .catch(this.handleErrorObservable);
    }
    UpdateEmployeeBankDetails( token ,empbank :EmployeeBank) 
    {
        const authHeader = new HttpHeaders();         
        authHeader.append('Content-Type', 'application/json');
        authHeader.append('Authorization', token);
        authHeader.append('Accept', 'application/json');
        return this.http.put(GlobalVariable.BASE_API_URL +'/api/EmployeeBank/Update', empbank ,{headers: authHeader} )
        .map(this.extractData)
        .catch(this.handleErrorObservable);
    }
    GetNewEmployeeBankDetailsForDDL(token )
    {
        this.spinnerService.show();
        const authHeader = new HttpHeaders();     
        authHeader.append('Content-Type', 'application/json');
        authHeader.append('Authorization', token);
        return new Promise((resolve, reject) =>
        {
            setTimeout(()=>
            {
                this.http.get(GlobalVariable.BASE_API_URL +'/api/EmployeeBank/GetEmployeeForBankDetailsDDL',{headers: authHeader})
                .subscribe(data => 
                {
                    this.spinnerService.hide();
                    resolve(data)
                },
                error => 
                {
                    this.spinnerService.hide();
                    reject(error);
                },);
            },3000);
        });
    }
    // End of the Employee Bank Details

    //GetEmployeeDetailsForView For Personal View
    GetEmployeeDetailsForView(token )
    { 
        this.spinnerService.show();
        const authHeader = new HttpHeaders();     
        authHeader.append('Content-Type', 'application/json');
        authHeader.append('Authorization', token);
        return new Promise((resolve, reject) =>
        {
            this.http.get(GlobalVariable.BASE_API_URL +'/api/EmployeeDetails/GetEmployeeDetailsForView',{headers: authHeader})
            .subscribe(data => 
            {
                this.spinnerService.hide();
                resolve(data)
            },
            error => 
            {
                this.spinnerService.hide();
                reject(error);
            },);
        });
    }
    //Begin Gander & Marital Status
    GetGenderForDDL(token )
    {
        this.spinnerService.show();
        const authHeader = new HttpHeaders();     
        authHeader.append('Content-Type', 'application/json');
        authHeader.append('Authorization', token);
        return new Promise((resolve, reject) =>
        {
            setTimeout(()=>
            {
                this.http.get(GlobalVariable.BASE_API_URL +'/api/EmployeeDetails/GetGender',{headers: authHeader})
                .subscribe(data => 
                {
                    this.spinnerService.hide();
                    resolve(data)
                },
                error => 
                {
                    this.spinnerService.hide();
                    reject(error);
                },);
            },3000);
        });
    }
    GetMaritalStatusForDDL(token )
    {
        this.spinnerService.show();
        const authHeader = new HttpHeaders();     
        authHeader.append('Content-Type', 'application/json');
        authHeader.append('Authorization', token);
        return new Promise((resolve, reject) =>
        {
            setTimeout(()=>
            {
                this.http.get(GlobalVariable.BASE_API_URL +'/api/EmployeeDetails/GetMaritalStatus',{headers: authHeader})
                .subscribe(data => 
                {
                    this.spinnerService.hide();
                    resolve(data)
                },
                error => 
                {
                    this.spinnerService.hide();
                    reject(error);
                },);
            },3000);
        });
    }
    //End Gander & Marital Status
    //EmployeeDetails for ddl
    GetEmployeeDetailsForDDL(token )
    {
        this.spinnerService.show();
        const authHeader = new HttpHeaders();     
        authHeader.append('Content-Type', 'application/json');
        authHeader.append('Authorization', token);
        return new Promise((resolve, reject) =>
        {
            setTimeout(()=>
            {
                this.http.get(GlobalVariable.BASE_API_URL +'/api/EmployeeDetails/GetEmployeeDetailsForDDL',{headers: authHeader})
                .subscribe(data => 
                {
                    this.spinnerService.hide();
                    resolve(data)
                },
                error => 
                {
                    this.spinnerService.hide();
                    reject(error);
                },);
            },3000);
        });
    }

    EmployeeDetailsByLocation(token, CompanyId:number, BranchId:number, DepartmentId:number)
    {
        this.spinnerService.show();
        const authHeader = new HttpHeaders();     
        authHeader.append('Content-Type', 'application/json');
        authHeader.append('Authorization', token);
        return new Promise((resolve, reject) => 
        {
            setTimeout(()=>{
            this.http.get(GlobalVariable.BASE_API_URL +'/api/EmployeeDetails/EmployeeDetailsByLocation?CompanyId='+CompanyId+'&BranchId='+BranchId+'&DepartmentId='+DepartmentId,{headers: authHeader})
            .subscribe(data => 
            {
                this.spinnerService.hide();
                resolve(data)
            },
            error => 
            {
                this.spinnerService.hide();
                reject(error);
            },);


        },3000);
        });
    }

    GetReportingandFunctionalHead(token)
    {
        this.spinnerService.show();
        const authHeader = new HttpHeaders();     
        authHeader.append('Content-Type', 'application/json');
        authHeader.append('Authorization', token);
        return new Promise((resolve, reject) =>
        {
            setTimeout(()=>
            {
                this.http.get(GlobalVariable.BASE_API_URL +'/api/EmployeeDetails/GetReportingandFunctionalHead',{headers: authHeader})
                .subscribe(data => 
                {
                    this.spinnerService.hide();
                    resolve(data)
                },
                error => 
                {
                    this.spinnerService.hide();
                    reject(error);
                },);
            },3000);
        });
    }

    GetEmployeeNameForRHandFH(token)
    {
        this.spinnerService.show();
        const authHeader = new HttpHeaders();     
        authHeader.append('Content-Type', 'application/json');
        authHeader.append('Authorization', token);
        return new Promise((resolve, reject) =>
        {
            setTimeout(()=>
            {
                this.http.get(GlobalVariable.BASE_API_URL +'/api/EmployeeDetails/GetEmployeeNameForRHandFH',{headers: authHeader})
                .subscribe(data => 
                {
                    this.spinnerService.hide();
                    resolve(data)
                },
                error => 
                {
                    this.spinnerService.hide();
                    reject(error);
                },);
            },3000);
        });
    }
    //end

    // Employee Personal Details Modification
    GetEmployeeModificationApplicationGV(token )
    { 
        this.spinnerService.show();
        const authHeader = new HttpHeaders();     
        authHeader.append('Content-Type', 'application/json');
        authHeader.append('Authorization', token);
        return new Promise((resolve, reject) =>
        {
            setTimeout(()=>
            {
                this.http.get(GlobalVariable.BASE_API_URL +'/api/EmployeeDetails/GetEmployeeModificationApplicationGV',{headers: authHeader})
                .subscribe(data => 
                {
                    this.spinnerService.hide();
                    resolve(data)
                },
                error => 
                {
                    this.spinnerService.hide();
                    reject(error);
                },);
            },3000);
        });
    }
    GetEmployeeModificationApplicationGVForHRApproval(token )
    { 
        this.spinnerService.show();
        const authHeader = new HttpHeaders();     
        authHeader.append('Content-Type', 'application/json');
        authHeader.append('Authorization', token);
        return new Promise((resolve, reject) =>
        {
            setTimeout(()=>
            {
                this.http.get(GlobalVariable.BASE_API_URL +'/api/EmployeeDetails/GetEmployeeModificationApplicationGVForHRApproval',{headers: authHeader})
                .subscribe(data => 
                {
                    this.spinnerService.hide();
                    resolve(data)
                },
                error => 
                {
                    this.spinnerService.hide();
                    reject(error);
                },);
            },3000);
        });
    }
    GetEmployeePersonalDetailsForModificationByRefId(token,RefIdApplication : number, PersonalDetails : boolean, AddressDetails : boolean, OtherDetails : boolean, QualificationDetails : boolean, ExperienceDetails  : boolean, FamilyDetails : boolean) 
    {
        this.spinnerService.show();
        const authHeader = new HttpHeaders();     
        authHeader.append('Content-Type', 'application/json');
        authHeader.append('Authorization', token);
        return new Promise((resolve, reject) => 
        {
            setTimeout(()=>
            {
                this.http.get(GlobalVariable.BASE_API_URL +'/api/EmployeeDetails/GetEmployeePersonalDetailsForModificationApplicationByRefIdApplication?RefIdApplication='+RefIdApplication +'&PersonalDetails='+PersonalDetails+'&AddressDetails='+AddressDetails+'&OtherDetails='+OtherDetails+'&QualificationDetails='+QualificationDetails+'&ExperienceDetails='+ExperienceDetails+'&FamilyDetails='+FamilyDetails,{headers: authHeader})
                .subscribe(data => 
                {
                    this.spinnerService.hide();
                    resolve(data)
                },
                error => 
                {
                    this.spinnerService.hide();
                    reject(error);
                },);
            },3000);
        });
    }
    EmployeePersonalDetailsApproval( token ,EmployeeDetails :EmployeeDetail) 
    {
        const authHeader = new HttpHeaders();         
        authHeader.append('Content-Type', 'application/json');
        authHeader.append('Authorization', token);
        authHeader.append('Accept', 'application/json');
        return this.http.put(GlobalVariable.BASE_API_URL +'/api/EmployeeDetails/EmployeePersonalDetailsApproval', EmployeeDetails ,{headers: authHeader} )
        .map(this.extractData)
        .catch(this.handleErrorObservable);
    }
    //Begin Employee Bithday & Anniversary
    GetEmployeeBirtDay(token, CurrentDate:Date)
    {
        this.spinnerService.show();
        const authHeader = new HttpHeaders();     
        authHeader.append('Content-Type', 'application/json');
        authHeader.append('Authorization', token);
        return new Promise((resolve, reject) => 
        {
            setTimeout(()=>{
            this.http.get(GlobalVariable.BASE_API_URL +'/api/BirthdayAnniversary/GetEmployeeBirtDay?CurrentDate='+CurrentDate,{headers: authHeader})
            .subscribe(data => 
            {
                this.spinnerService.hide();
                resolve(data)
            },
            error => 
            {
                this.spinnerService.hide();
                reject(error);
            },);
        },3000);
        });
    }
    GetEmployeeMarriageAnniversary(token, CurrentDate:Date)
    {
        this.spinnerService.show();
        const authHeader = new HttpHeaders();     
        authHeader.append('Content-Type', 'application/json');
        authHeader.append('Authorization', token);
        return new Promise((resolve, reject) => 
        {
            setTimeout(()=>{
            this.http.get(GlobalVariable.BASE_API_URL +'/api/BirthdayAnniversary/GetEmployeeMarriageAnniversary?CurrentDate='+CurrentDate,{headers: authHeader})
            .subscribe(data => 
            {
                this.spinnerService.hide();
                resolve(data)
            },
            error => 
            {
                this.spinnerService.hide();
                reject(error);
            },);
        },3000);
        });
    }
    GetEmployeeWorkAnniversary(token, CurrentDate:Date)
    {
        this.spinnerService.show();
        const authHeader = new HttpHeaders();     
        authHeader.append('Content-Type', 'application/json');
        authHeader.append('Authorization', token);
        return new Promise((resolve, reject) => 
        {
            setTimeout(()=>{
            this.http.get(GlobalVariable.BASE_API_URL +'/api/BirthdayAnniversary/GetEmployeeWorkAnniversary?CurrentDate='+CurrentDate,{headers: authHeader})
            .subscribe(data => 
            {
                this.spinnerService.hide();
                resolve(data)
            },
            error => 
            {
                this.spinnerService.hide();
                reject(error);
            },);
        },3000);
        });
    }
    BirthDayMailFarward( token ,Birthday :BirthDayAnniversaryModel) 
    {
        const authHeader = new HttpHeaders();         
        authHeader.append('Content-Type', 'application/json');
        authHeader.append('Authorization', token);
        authHeader.append('Accept', 'application/json');
        return this.http.post(GlobalVariable.BASE_API_URL +'/api/BirthdayAnniversary/BithdayMail', Birthday ,{headers: authHeader} )
        .map(this.extractData)
        .catch(this.handleErrorObservable);
    }
    MarriageAnniversaryMailFarward( token ,MarriageAnniversaryMail :BirthDayAnniversaryModel) 
    {
        const authHeader = new HttpHeaders();         
        authHeader.append('Content-Type', 'application/json');
        authHeader.append('Authorization', token);
        authHeader.append('Accept', 'application/json');
        return this.http.post(GlobalVariable.BASE_API_URL +'/api/BirthdayAnniversary/MarriageAnniversaryMail', MarriageAnniversaryMail ,{headers: authHeader} )
        .map(this.extractData)
        .catch(this.handleErrorObservable);
    }
    WorkAnniversaryMailFarward( token ,WorkAnniversary :BirthDayAnniversaryModel) 
    {
        const authHeader = new HttpHeaders();         
        authHeader.append('Content-Type', 'application/json');
        authHeader.append('Authorization', token);
        authHeader.append('Accept', 'application/json');
        return this.http.post(GlobalVariable.BASE_API_URL +'/api/BirthdayAnniversary/WorkAnniversaryMail', WorkAnniversary ,{headers: authHeader} )
        .map(this.extractData)
        .catch(this.handleErrorObservable);
    }
    //End Employee Bithday & Anniversary
    //Begin Resign Employee DDL
    GetResignEmployeeDetailsForDDL(token )
    {
        this.spinnerService.show();
        const authHeader = new HttpHeaders();     
        authHeader.append('Content-Type', 'application/json');
        authHeader.append('Authorization', token);
        return new Promise((resolve, reject) =>
        {
            setTimeout(()=>
            {
                this.http.get(GlobalVariable.BASE_API_URL +'/api/EmployeeDetails/GetResignEmployeeDetailsForDDL',{headers: authHeader})
                .subscribe(data => 
                {
                    this.spinnerService.hide();
                    resolve(data)
                },
                error => 
                {
                    this.spinnerService.hide();
                    reject(error);
                },);
            },3000);
        });
    }
    GetResignEmployeeDetailsForView(token,EmployeeId:number)
    { 
        this.spinnerService.show();
        const authHeader = new HttpHeaders();     
        authHeader.append('Content-Type', 'application/json');
        authHeader.append('Authorization', token);
        return new Promise((resolve, reject) =>
        {
            setTimeout(()=>
            {
                this.http.get(GlobalVariable.BASE_API_URL +'/api/EmployeeDetails/GetResignEmployeeDetailsForView?EmployeeId='+EmployeeId,{headers: authHeader})
                .subscribe(data => 
                {
                    this.spinnerService.hide();
                    resolve(data)
                },
                error => 
                {
                    this.spinnerService.hide();
                    reject(error);
                },);
            },3000);
        });
    }
    //End Resign Employee DDL
    private extractData(res: Response) 
    {
        let body = res;
        return body || {};
    }
    private handleErrorObservable (error: Response | any) 
    {
        console.error(error.message || error);
        return Observable.throw(error.message || error);
    }
}
