import { Component, OnInit, ViewChild, Output, EventEmitter,Input  } from '@angular/core';
import { DatePipe } from '@angular/common'
import { CrmOppertunityService } from '../../../shared/CRM/MICECRM/Oppertunity/CrmOppertunityService';
import { Router } from '@angular/router';
import { Model_CRM_PipeLine } from '../../../shared/CRM/MICECRM/Oppertunity/Model_CRM_PipeLine';
import { Model_CRM_Lead_Contacts } from '../../../shared/CRM/MICECRM/Oppertunity/Model_CRM_Lead_Contacts';
import { ConfirmSettings } from '@jaspero/ng2-confirmations/src/interfaces/confirm-settings';
import { Model_CRM_Opportunity_Document } from '../../../shared/CRM/MICECRM/Oppertunity/Model_CRM_Opportunity_Documents';
import { ModalOptions,BsModalRef, BsModalService  } from 'ngx-bootstrap/modal'
import { Model_CRM_Lead } from '../../../shared/CRM/MICECRM/LEADCRM/Model_CRM_LEAD';
import { ChangableDatePicker } from '../../../../../src/app/for-all/myDatePickerChangable';
import { DatePicker } from '../../../../../src/app/for-all/myDatepicker';
import { Model_CRM_MonthOfBusiness } from '../../../shared/CRM/MICECRM/Oppertunity/Model_CRM_MonthOfBusiness ';
import { Model_CRM_BusinessType } from '../../../shared/CRM/MICECRM/Oppertunity/Model_CRM_BusinessType';

@Component({
  selector: 'app-crm-oportunity-details',
  templateUrl: './crm-oportunity-details.component.html',
  styleUrls: ['./crm-oportunity-details.component.css']

})
export class CrmOportunityDetailsComponent implements OnInit {

  @ViewChild(ChangableDatePicker, {static: false}) private myDatePickerChangable: ChangableDatePicker;
  @ViewChild(DatePicker, {static: false}) private myDatePicker: DatePicker;

  // Begin success message popup
  SuccessStatus: boolean;
  SuccessMessage: string;
  IsSavevisible: boolean = true;
  // end success message popup
  @Output() searchItem: EventEmitter<any> = new EventEmitter();
  OutputDate: string = "";

  //Begning of Company list
  CompanySettings = {}
  CompanyArray: any[] = [];
  ompanyName: any;
  Companylist: Model_CRM_Lead[] = [];
  SelectedCompany: any[] = [];
  //End of Company List



  // CRM_Oppertynity_Model: Model_CRM_Oppertunity;


  PipeLineName: any;
  pipelinelist: Model_CRM_PipeLine[] = [];

  // for company
  CompanyName: any;
  companylist: Model_CRM_Lead[] = [];


  // CRM - Lead Contact Drop Down   
  ContactName: any;
  contactlist: Model_CRM_Lead_Contacts[] = [];


  // ADD-UPDATE-DELETE PART 
  OppertunityId: number;
  DeleteOppertunityNameByID: any;
  IsDataIsInViewState: boolean;

  document: any;

  //for model 
  public modalRef: BsModalRef;
  public modelopt: ModalOptions;
  ShowPopup: boolean = false;
  ApprovedMsg: boolean = false;
  //for model

  //Begning for Upload

  allfiles: any = [];
  images: Model_CRM_Opportunity_Document[] = [];
  Imge: Model_CRM_Opportunity_Document;
  // End for File Upload
  docId: any;

  date: any;


  businessmontlist: Model_CRM_MonthOfBusiness[] = [];
  BusinessMonthName: any;
  businesstypelist: Model_CRM_BusinessType[] = [];
  BusinessTypeName: any;


  constructor(private CRM_OppertunityService: CrmOppertunityService, private rout: Router,
     private modalService: BsModalService,
    private datepipe: DatePipe) {

    // this.CRM_Oppertynity_Model = new Model_CRM_Oppertunity();
    // this.CRM_Oppertynity_Model.IsCreatedDateValid = false;
    // this.CRM_Oppertynity_Model.IsClosingDateValid = false;
    // this.CRM_Oppertynity_Model.OppertunityTitle = "";
    if (this.CRM_OppertunityService.CrmCommonUseOportunityDetails.OppertunityId == 0) {
      this.CRM_OppertunityService.CrmCommonUseOportunityDetails.IsClosingDateValid = true;
      this.CRM_OppertunityService.CrmCommonUseOportunityDetails.IsCreatedDateValid = true;
      this.CRM_OppertunityService.CrmCommonUseOportunityDetails.OppertunityTitle = "";
      this.CRM_OppertunityService.CrmCommonUseOportunityDetails.ListOpportunityDocs = [];
    }
    else {
      this.CRM_OppertunityService.CrmCommonUseOportunityDetails.IsClosingDateValid = true;
      this.CRM_OppertunityService.CrmCommonUseOportunityDetails.IsCreatedDateValid = true;

    }




  }

  ngOnInit() {
    this.CRM_OppertunityService.CrmCommonUseOportunityDetails.ListOpportunityDocs = [];
    //this.rout.navigate([this.userService.selectedCrmMenu.RouteName]);
    if (this.CRM_OppertunityService.CrmCommonUseOportunityDetails.OppertunityId == undefined) {
      this.CRM_OppertunityService.CrmCommonUseOportunityDetails.OppertunityId = 0;

    }

    if (this.CRM_OppertunityService.CrmCommonUseOportunityDetails.OppertunityId > 0) {



    }

    if (this.CRM_OppertunityService.CrmCommonUseOportunityDetails.OppertunityId == 0) {
      this.CRM_OppertunityService.CrmCommonUseOportunityDetails.PipeLineId = 0;
      this.CRM_OppertunityService.CrmCommonUseOportunityDetails.ContactId = 0;
      this.CRM_OppertunityService.CrmCommonUseOportunityDetails.CompanyId = 0;
      this.CRM_OppertunityService.CrmCommonUseOportunityDetails.OppertunityId = 0;
      this.CRM_OppertunityService.CrmCommonUseOportunityDetails.ChooseFile = "";
      this.CRM_OppertunityService.CrmCommonUseOportunityDetails.OppertunityTitle = "";
      this.CRM_OppertunityService.CrmCommonUseOportunityDetails.MonthId = 0;
      this.CRM_OppertunityService.CrmCommonUseOportunityDetails.TypeOfBusiness = 0;



    }


    this.GetCrmCompany();
    this.GetPipeLine();
    this.GetMonthOfBusiness();
    this.GetTypesOfBusiness();





    if (localStorage.getItem('SuccessfullMessage')) {
      this.SuccessStatus = true;
      this.SuccessMessage = localStorage.getItem('SuccessfullMessage')
      localStorage.removeItem('SuccessfullMessage');

    }

    //Begning for File Upload documents

    //this.CRM_OppertunityService.CrmCommonUseOportunityDetails.ListOpportunityDocs
    //End For file upload documents



    if (this.CRM_OppertunityService.CrmCommonUseOportunityDetails.CompanyId > 0) {

      this.GetLeadContact();
    }
    else {
      this.CRM_OppertunityService.CrmCommonUseOportunityDetails.CloseDateval = '';
    }


    this.CompanySettings = {
      singleSelection: true,
      text: 'Select Company',
      enableSearchFilter: true,
      showCheckbox: false,
      position: 'top'
    }

    if (this.CRM_OppertunityService.CrmCommonUseOportunityDetails.CreatedDateval == undefined) {

      this.date = new Date();
      let latest_date = this.datepipe.transform(this.date, 'dd/MM/yyyy');

      var dateParts = latest_date.split("/");

      this.CRM_OppertunityService.CrmCommonUseOportunityDetails.CreatedDate = new Date(+dateParts[2], +dateParts[1], +dateParts[0]);
      this.CRM_OppertunityService.CrmCommonUseOportunityDetails.CreatedDateval = latest_date;
      this.CRM_OppertunityService.CrmCommonUseOportunityDetails.CloseDate = new Date();
      this.CRM_OppertunityService.CrmCommonUseOportunityDetails.CloseDateval = '';

    }

    if (this.CRM_OppertunityService.CrmCommonUseOportunityDetails.CreatedDateval == '') {

      this.date = new Date();
      let latest_date = this.datepipe.transform(this.date, 'dd/MM/yyyy');

      var dateParts = latest_date.split("/");

      this.myDatePickerChangable.change(latest_date);

      this.myDatePicker.changedatewithvalue(latest_date)

      this.CRM_OppertunityService.CrmCommonUseOportunityDetails.CreatedDate = new Date(+dateParts[2], +dateParts[1], +dateParts[0]);
      this.CRM_OppertunityService.CrmCommonUseOportunityDetails.CreatedDateval = latest_date;
      this.CRM_OppertunityService.CrmCommonUseOportunityDetails.CloseDate = new Date();
      this.CRM_OppertunityService.CrmCommonUseOportunityDetails.CloseDateval = '';

    }



  }



  ngAfterViewInit() {
    if (this.CRM_OppertunityService.CrmCommonUseOportunityDetails.OppertunityId > 0) {

      this.myDatePickerChangable.changedatewithPreviousvalue(this.CRM_OppertunityService.CrmCommonUseOportunityDetails.CreatedDateval,
        this.CRM_OppertunityService.CrmCommonUseOportunityDetails.CloseDateval);
    }



  }

  settings: ConfirmSettings | any =
    {
      overlay: true,
      overlayClickToClose: true,
      showCloseButton: true,
      confirmText: 'Yes',
      declineText: 'No'
    };

  //Populate Pipeline  Drop  Down  

  GetPipeLine() {

    let t = localStorage.getItem('userToken');
    t = 'Bearer' + t
    this.pipelinelist = [];
    this.CRM_OppertunityService.GetPipeLineStatus(t).then(x => {
      this.PipeLineName = x;
      Object.keys(this.PipeLineName).forEach(key => {
        let PipeLineNametemp = new Model_CRM_PipeLine();
        PipeLineNametemp = this.PipeLineName[key];
        this.pipelinelist.push(PipeLineNametemp);
      });

    });
  }



  GetCrmCompany() {

    let t = localStorage.getItem('userToken');
    t = 'Bearer' + t
    this.companylist = [];
    this.CompanyArray = [];
    this.CRM_OppertunityService.Get_CrmCompany(t).then(x => {
      this.CompanyName = x;
      Object.keys(this.CompanyName).forEach(key => {
        let CompanyNametemp = new Model_CRM_Lead();
        CompanyNametemp = this.CompanyName[key];
        this.companylist.push(CompanyNametemp);
        this.CompanyArray.push({ "id": CompanyNametemp.LeadId, "itemName": CompanyNametemp.LeadName });
      });

    }).then(y => {

      if (this.CRM_OppertunityService.CrmCommonUseOportunityDetails.CompanyId > 0) {
        let OpportunityCompany = this.companylist.find(x => x.LeadId == this.CRM_OppertunityService.CrmCommonUseOportunityDetails.CompanyId);
        this.SelectedCompany = [];
        this.SelectedCompany.push({ "id": OpportunityCompany.LeadId, "itemName": OpportunityCompany.LeadName });
        this.GetLeadContact();
      }
    });
  }



  onItemSelectCompany(item: any) {

    let CompanyId = item['id'];
    this.CRM_OppertunityService.CrmCommonUseOportunityDetails.CompanyId = CompanyId;
    this.CRM_OppertunityService.CrmCommonUseOportunityDetails.ContactId = 0;
    if (this.CRM_OppertunityService.CrmCommonUseOportunityDetails.CompanyId > 0) {
      this.GetLeadContact();
    }
  }
  OnItemDeSelectCompany(item: any) { }
  onSelectAllCompany(items: any) { }
  onDeSelectAllCompany(items: any) { }
  //Populate  Company  Drop  Down  ENDS
  //Populate Lead-Contact  Drop  Down
  GetLeadContact() {
    let t = localStorage.getItem('userToken');
    t = 'Bearer' + t
    this.contactlist = [];
    this.CRM_OppertunityService.GetCrmLeadContact(t, this.CRM_OppertunityService.CrmCommonUseOportunityDetails.CompanyId).then(x => {
      this.ContactName = x;
      Object.keys(this.ContactName).forEach(key => {
        let ContactNametemp = new Model_CRM_Lead_Contacts();
        ContactNametemp = this.ContactName[key];
        this.contactlist.push(ContactNametemp);
      });
    });
  }

  //Populate  Lead-Contact  Drop  Down  ENDS






  // Save-Update Region  Starts



  CreateDate(datecreate) {


    this.myDatePickerChangable.change(datecreate);

    this.myDatePicker.changedatewithvalue(datecreate)


    //this.myDatePickerChangable.mindate = datecreate;

    // if (this.CRM_OppertunityService.CrmCommonUseOportunityDetails.CloseDateval != undefined) {

    // }

    // if (this.CRM_OppertunityService.CrmCommonUseOportunityDetails.CloseDateval == undefined) {

    //   this.myDatePickerChangable.CahngeDate();
    // }

    var dateParts = datecreate.split("/");
    this.CRM_OppertunityService.CrmCommonUseOportunityDetails.CreatedDate = new Date(+dateParts[2], dateParts[1], +dateParts[0]);
    this.CRM_OppertunityService.CrmCommonUseOportunityDetails.CreatedDateval = datecreate;
    this.CRM_OppertunityService.CrmCommonUseOportunityDetails.CloseDate = new Date();
    this.CRM_OppertunityService.CrmCommonUseOportunityDetails.CloseDateval = '';

    //this.CRM_OppertunityService.CrmCommonUseOportunityDetails.CloseDateval = '';
    //this.myDatePickerChangable.claer();
  }

  Closedate(DateClose) {

    var dateParts = DateClose.split("/");
    this.CRM_OppertunityService.CrmCommonUseOportunityDetails.CloseDate = new Date(+dateParts[2], dateParts[1], +dateParts[0]);
    this.CRM_OppertunityService.CrmCommonUseOportunityDetails.CloseDateval = DateClose;

  }







  saveCRMOppertunity(): void {


    let t = localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.CRM_OppertunityService.CrmCommonUseOportunityDetails.IsActive = true;

    this.CRM_OppertunityService.CrmCommonUseOportunityDetails.Destination = this.CRM_OppertunityService.CRM_MoreInformation_ModelShare.Destination;

    this.CRM_OppertunityService.CrmCommonUseOportunityDetails.NumberOfPax = this.CRM_OppertunityService.CRM_MoreInformation_ModelShare.NumberOfPax;

    if (this.CRM_OppertunityService.CrmCommonUseOportunityDetails.OppertunityId > 0) {




      this.CRM_OppertunityService.UpdateCrmOppertunity(t, this.CRM_OppertunityService.CrmCommonUseOportunityDetails).subscribe(res => {
        this.OppertunityId = Number(res);
        this.CRM_OppertunityService.CrmCommonUseOportunityDetails.OppertunityId = this.OppertunityId;
        this.CRM_OppertunityService.CRM_MoreInformation_ModelShare.OppertunityId = this.OppertunityId;
        this.CRM_OppertunityService.CRm_Opportunity_Notes.OportunityId = this.OppertunityId;
        if ((this.CRM_OppertunityService.CrmCommonUseOportunityDetails.ListOpportunityDocs) != undefined) {
          if (this.CRM_OppertunityService.CrmCommonUseOportunityDetails.ListOpportunityDocs.length > 0) {
            this.CRM_OppertunityService.CrmCommonUseOportunityDetails.ListOpportunityDocs.map(x => {
              x.OpportunityId = this.CRM_OppertunityService.CRM_MoreInformation_ModelShare.OppertunityId;
            });



            if ((this.CRM_OppertunityService.CrmCommonUseOportunityDetails.ListOpportunityDocs.length > 0)) {

              this.save();




            }
          }

        }



        // if (this.CRM_OppertunityService.CRM_MoreInformation_ModelShare.OppertunityId > 0) {

        //   this.Afterassigne().then((resolve: any) => {

        //     this.save();

        //   });

        // }


        if (this.OppertunityId > 0) {
          this.OutputDate = "cccc";
          this.searchItem.emit(this.OutputDate);
          localStorage.setItem('SuccessfullMessage', "Successfully Updated");
          this.CRM_OppertunityService.SelectedCrmIndexPage = 1;
          this.rout.navigate(["/ManageMICEAccess/CrmOppertunity/OportunityMoreInfo"]);
        }
      });
    }
    else {
      this.CRM_OppertunityService.AddCrmOppertunity(t, this.CRM_OppertunityService.CrmCommonUseOportunityDetails).subscribe(res => {
        this.OppertunityId = Number(res);
        // this.CRM_OppertunityService.CrmCommonUseOportunityDetails.OppertunityId = 0;

        this.CRM_OppertunityService.CrmCommonUseOportunityDetails.OppertunityId = this.OppertunityId;
        this.CRM_OppertunityService.CRM_MoreInformation_ModelShare.OppertunityId = this.OppertunityId;


        if ((this.CRM_OppertunityService.CrmCommonUseOportunityDetails.ListOpportunityDocs) != undefined) {
          if (this.CRM_OppertunityService.CrmCommonUseOportunityDetails.ListOpportunityDocs.length > 0) {
            if (this.OppertunityId > 0) {
              this.CRM_OppertunityService.CrmCommonUseOportunityDetails.ListOpportunityDocs.map(x => {
                x.OpportunityId = this.CRM_OppertunityService.CRM_MoreInformation_ModelShare.OppertunityId;
              });
              if ((this.CRM_OppertunityService.CrmCommonUseOportunityDetails.ListOpportunityDocs) != undefined) {
                if ((this.CRM_OppertunityService.CrmCommonUseOportunityDetails.ListOpportunityDocs.length > 0)) {

                  this.save();
                }



              }

            }
          }
        }

        this.CRM_OppertunityService.CRM_MoreInformation_ModelShare.OppertunityId = this.OppertunityId;
        localStorage.setItem('SuccessfullMessage', "Successfully Added");
        this.OutputDate = "cccc";
        this.searchItem.emit(this.OutputDate);
        this.CRM_OppertunityService.SelectedCrmIndexPage = 1;
        this.rout.navigate(["/ManageMICEAccess/CrmOppertunity/OportunityMoreInfo"]);


      });
    }
  }

  private Afterassigne() {


    // {
    //   setTimeout(() => {
    //     this.http.get(GlobalVariable.BASE_API_URL + '/api/CRMOppertunity/GetCRMOppertunity', { headers: authHeader }).subscribe(data => {
    //       this.spinnerService.hide();
    //       //  alert("1");
    //       resolve(data)
    //     },
    //       error => {
    //         this.spinnerService.hide();
    //         reject(error);
    //       });
    //   }, 3000);
    // }


    return new Promise((resolve, reject) => {

      this.CRM_OppertunityService.CrmCommonUseOportunityDetails.ListOpportunityDocs.map(x => {
        x.OpportunityId = this.CRM_OppertunityService.CRM_MoreInformation_ModelShare.OppertunityId;

      })



    });
  }


  onCompanyChange(companyid): void {

    this.GetLeadContact();


  }


  // Save-Update Region  Ends

  //    Date Vallidation Starts


  //    Date Vallidation Starts



  //

  OportunityDesription(ev) {
    try {
      this.CRM_OppertunityService.CrmCommonUseOportunityDetails.OppertunityDescription = ev.target.value;
    } catch (e) {
      console.info('could not set textarea-value');
    }
  }


  RefreshPage(): void {

    this.SelectedCompany = [];
    this.SelectedCompany.push({ "id": 0, "itemName": "Select Company" });
    //this.myDatePicker.refreshDatePicker();

    this.CRM_OppertunityService.ResetOportunity();

    this.myDatePickerChangable.refreshDatePicker();


    this.date = new Date();

    let latest_date = this.datepipe.transform(this.date, 'dd/MM/yyyy');

    this.CreateDate(latest_date);

    // var dateParts = latest_date.split("/");

    // this.CRM_OppertunityService.CrmCommonUseOportunityDetails.CreatedDate = new Date(+dateParts[2], +dateParts[1], +dateParts[0]);
    // this.CRM_OppertunityService.CrmCommonUseOportunityDetails.CreatedDateval = latest_date;
    // this.CRM_OppertunityService.CrmCommonUseOportunityDetails.CloseDate = new Date();
    // this.CRM_OppertunityService.CrmCommonUseOportunityDetails.CloseDateval = '';








  }

  Uploaddocument(template) {

    this.GetUplodesFileById(this.CRM_OppertunityService.CrmCommonUseOportunityDetails.OppertunityId);
    this.modelopt = new ModalOptions();
    this.modelopt.class = 'heavy_modal';
    this.modalService.config = this.modelopt;

    this.modalRef = this.modalService.show(template, this.modelopt);
    //this.GridShow=true;
    this.ShowPopup = true;
    //  this.ApprovedMsg=false;
    //  this.RejectedMsg=false;
    // Begning of the permission portion
    this.IsDataIsInViewState = false;
    // End of the permision portion
    this.ApprovedMsg = true;

  }

  GetUplodesFileById(Id) {

    this.IsDataIsInViewState = false;
    // End of the permision portion
    let t = localStorage.getItem('userToken');
    t = 'Bearer' + t;
    if (Id > 0) {
      this.CRM_OppertunityService.CrmCommonUseOportunityDetails.ListOpportunityDocs = [];

      this.CRM_OppertunityService.Get_CRMOpportunityGetDocument(t, Id).then(x => {
        this.document = x;

        Object.keys(this.document).forEach(key => {



          let CRMLeadtemp = new Model_CRM_Opportunity_Document();
          CRMLeadtemp = this.document[key];

          this.CRM_OppertunityService.CrmCommonUseOportunityDetails.ListOpportunityDocs.push(CRMLeadtemp);

        });


      })
    }

  }


  //Begin File Upload



  fileuploads(event) {

    const files = event.target.files;
    //console.log(files);

    if (files) {
      for (let i = 0; i < files.length; i++) {

        // const image ={

        //   name :'',
        //   type : '',
        //   size: '',
        //   url:''
        // } ;

        let image = new Model_CRM_Opportunity_Document();

        this.allfiles.push(files[i]);
        image.DocumentActualName = files[i].name;
        image.DocumentType = files[i].type;

        const size = files[i].size / 1024;
        const mbc = size + '';
        const mb = mbc.split('.')[0];
        const length = mb.length;

        if (length === 4 || length === 5) {

          const mbsize = size / 1024;

          const splitdata = mbsize + '';
          const splitvalue = splitdata.split('.');
          let secondvariable = '';
          for (let j = 0; j < splitvalue.length; j++) {

            if (j === 1) {
              secondvariable = splitvalue[j].slice(0, 2);
            }
          }

          image.DocumentSize = splitvalue[0] + '.' + secondvariable + ' MB';
        }
        else {

          const splitdata = size + '';
          const splitvalue = splitdata.split('.');
          let secondvariable = '';
          for (let j = 0; j < splitvalue.length; j++) {

            if (j === 1) {
              secondvariable = splitvalue[j].slice(0, 2);
            }
          }

          image.DocumentSize = splitvalue[0] + '.' + secondvariable + ' KB';

        }

        let ObjFileUpload = new Model_CRM_Opportunity_Document();

        var FileReades: FileReader = new FileReader();


        let filetyotypearray = image.DocumentSize.split("/");
        var re = /(?:\.([^.]+))?$/;


        ObjFileUpload.OpportunityId = this.CRM_OppertunityService.CrmCommonUseOportunityDetails.OppertunityId;
        ObjFileUpload.DocumentId = 0;

        ObjFileUpload.DocumentName = image.DocumentActualName

        ObjFileUpload.DocumentExtension = re.exec(image.DocumentActualName)[1];

        ObjFileUpload.DocumentType = image.DocumentType;

        ObjFileUpload.DocumentSize = image.DocumentSize;




        // image.size=files[i].size /1000;




        const reader = new FileReader();
        reader.onload = (filedata) => {
          // image.url=reader.result + '';

          ObjFileUpload.File = String(reader.result);


        };

        reader.readAsDataURL(files[i]);

        // reader.readAsText(files[i]);
        this.images.push(image);

        ObjFileUpload.DocumentName = image.DocumentActualName;
        ObjFileUpload.DocumentActualName = image.DocumentActualName;

        // ObjFileUpload.File = String( reader.result);
        // alert(ObjFileUpload.File);
        this.CRM_OppertunityService.CrmCommonUseOportunityDetails.ListOpportunityDocs.push(ObjFileUpload);


      }
      event.srcElement.value = null;
    }

  }


  //End File upload



  DeleteFile(Filex: any) {

    const index = this.CRM_OppertunityService.CrmCommonUseOportunityDetails.ListOpportunityDocs.indexOf(Filex);
    let tempfile = this.CRM_OppertunityService.CrmCommonUseOportunityDetails.ListOpportunityDocs[index];



    if (tempfile.DocumentId == 0) {

      this.CRM_OppertunityService.CrmCommonUseOportunityDetails.ListOpportunityDocs.splice(index, 1);

      this.Imge = this.images.find(x => x.DocumentActualName == tempfile.DocumentActualName);

      const indexofimg = this.images.indexOf(this.Imge);
      this.images.splice(indexofimg, 1);
    }


  }


  DocDownLoad(DocumentId, type): void {


    let t = localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.CRM_OppertunityService.getDocument(t, DocumentId).subscribe(respData => {
      this.downLoadFile(respData, type);
    }, error => {

    });

  }

  downLoadFile(data: any, type: string) {
    var blob = new Blob([data], { type: type.toString() });
    var url = window.URL.createObjectURL(blob);
    var pwa = window.open(url);
    if (!pwa || pwa.closed || typeof pwa.closed == 'undefined') {
      alert('Please disable your Pop-up blocker and try again.');
    }
  }


  save() {
    let t = localStorage.getItem('userToken');
    t = 'Bearer' + t;


    // if (this.CRM_LeadService.model_CRM_Shared_lead.LeadId > 0) {
    this.CRM_OppertunityService.AddCRMDocuments(t, this.CRM_OppertunityService.CrmCommonUseOportunityDetails.ListOpportunityDocs).subscribe(res => {

      this.docId = res;
      this.GetUplodesFileById(this.CRM_OppertunityService.CrmCommonUseOportunityDetails.OppertunityId);

      if (this.docId > 0) {

        //localStorage.setItem('SuccessfullMessage', "Successfully Updated");          

      }
    });
    // }

  }


  GetMonthOfBusiness() {

    let t = localStorage.getItem('userToken');
    t = 'Bearer' + t
    this.businessmontlist = [];
    this.CRM_OppertunityService.GetMonthOfBusiness(t).then(x => {
      this.BusinessMonthName = x;
      Object.keys(this.BusinessMonthName).forEach(key => {
        let BusinessNametemp = new Model_CRM_MonthOfBusiness();
        BusinessNametemp = this.BusinessMonthName[key];
        this.businessmontlist.push(BusinessNametemp);
      });

    });
  }


  GetTypesOfBusiness() {


    let t = localStorage.getItem('userToken');
    t = 'Bearer' + t
    this.businesstypelist = [];
    this.CRM_OppertunityService.GetBusinessType(t).then(x => {
      this.BusinessTypeName = x;
      Object.keys(this.BusinessTypeName).forEach(key => {
        let BusinessTypetemp = new Model_CRM_BusinessType();
        BusinessTypetemp = this.BusinessTypeName[key];
        this.businesstypelist.push(BusinessTypetemp);
      });

    });
  }


  

  get CRMOppertunityTitle(): string {
    if (this.CRM_OppertunityService.CrmCommonUseOportunityDetails.OppertunityTitle == undefined || this.CRM_OppertunityService.CrmCommonUseOportunityDetails.OppertunityTitle == null) {

      this.CRM_OppertunityService.CrmCommonUseOportunityDetails.OppertunityTitle = "";
    
      return this.CRM_OppertunityService.CrmCommonUseOportunityDetails.OppertunityTitle;
    }
    else {
      return this.CRM_OppertunityService.CrmCommonUseOportunityDetails.OppertunityTitle;
    }
  }

  @Input('CRMOppertunityTitle')
  set CRMOppertunityTitle(value: string) {

    this.CRM_OppertunityService.CrmCommonUseOportunityDetails.OppertunityTitle = value;

    if (this.CRM_OppertunityService.CrmCommonUseOportunityDetails.OppertunityTitle == undefined || this.CRM_OppertunityService.CrmCommonUseOportunityDetails.OppertunityTitle == null) {

      this.CRM_OppertunityService.CrmCommonUseOportunityDetails.OppertunityTitle = "";
     
    }

    else {
      this.CRM_OppertunityService.CrmCommonUseOportunityDetails.OppertunityTitle=value;


    }
  }

  

  get CRMOportunityProbability(): string {
    if (this.CRM_OppertunityService.CrmCommonUseOportunityDetails.Probability == undefined || this.CRM_OppertunityService.CrmCommonUseOportunityDetails.Probability == null) {

      this.CRM_OppertunityService.CrmCommonUseOportunityDetails.Probability = "";
    
      return this.CRM_OppertunityService.CrmCommonUseOportunityDetails.Probability;
    }
    else {
      return  this.CRM_OppertunityService.CrmCommonUseOportunityDetails.Probability;
    }
  }

  @Input('CRMOportunityProbability')
  set CRMOportunityProbability(value: string) {

    this.CRM_OppertunityService.CrmCommonUseOportunityDetails.Probability = value;

    if (this.CRM_OppertunityService.CrmCommonUseOportunityDetails.Probability == undefined || this.CRM_OppertunityService.CrmCommonUseOportunityDetails.Probability == null) {

      this.CRM_OppertunityService.CrmCommonUseOportunityDetails.Probability = "";     
    }

    else {
      this.CRM_OppertunityService.CrmCommonUseOportunityDetails.Probability=value;
    }
  }

  

  get CRMOpportunityAmount(): number {
    if (this.CRM_OppertunityService.CrmCommonUseOportunityDetails.Amount == undefined || this.CRM_OppertunityService.CrmCommonUseOportunityDetails.Amount == null) {

      this.CRM_OppertunityService.CrmCommonUseOportunityDetails.Amount =0;
    
      return this.CRM_OppertunityService.CrmCommonUseOportunityDetails.Amount;
    }
    else {
      return  this.CRM_OppertunityService.CrmCommonUseOportunityDetails.Amount;
    }
  }

  @Input('CRMOpportunityAmount()')
  set CRMOpportunityAmount(value: number) {

    this.CRM_OppertunityService.CrmCommonUseOportunityDetails.Amount = value;

    if (this.CRM_OppertunityService.CrmCommonUseOportunityDetails.Amount == undefined || this.CRM_OppertunityService.CrmCommonUseOportunityDetails.Amount == null) {

      this.CRM_OppertunityService.CrmCommonUseOportunityDetails.Amount = 0;     
    }

    else {
      this.CRM_OppertunityService.CrmCommonUseOportunityDetails.Amount=value;
    }
  }



  get CRMOportunityDestination(): string {
    if (this.CRM_OppertunityService.CRM_MoreInformation_ModelShare.Destination == undefined || this.CRM_OppertunityService.CRM_MoreInformation_ModelShare.Destination == null) {

      this.CRM_OppertunityService.CRM_MoreInformation_ModelShare.Destination = "";
    
      return   this.CRM_OppertunityService.CRM_MoreInformation_ModelShare.Destination;
    }
    else {
      return    this.CRM_OppertunityService.CRM_MoreInformation_ModelShare.Destination;
    }
  }

  @Input('CRMOportunityDestination')
  set CRMOportunityDestination(value: string) {

    this.CRM_OppertunityService.CRM_MoreInformation_ModelShare.Destination = value;

    if (this.CRM_OppertunityService.CRM_MoreInformation_ModelShare.Destination == undefined || this.CRM_OppertunityService.CRM_MoreInformation_ModelShare.Destination == null) {

      this.CRM_OppertunityService.CRM_MoreInformation_ModelShare.Destination = "";     
    }

    else {
      this.CRM_OppertunityService.CRM_MoreInformation_ModelShare.Destination=value;
      
    }
  }

  get CRMOpportunityPipeLineId(): number {
    if (this.CRM_OppertunityService.CrmCommonUseOportunityDetails.PipeLineId == undefined || this.CRM_OppertunityService.CrmCommonUseOportunityDetails.PipeLineId == null) {

      this.CRM_OppertunityService.CrmCommonUseOportunityDetails.PipeLineId =0;
    
      return this.CRM_OppertunityService.CrmCommonUseOportunityDetails.PipeLineId;
    }
    else {
      return  this.CRM_OppertunityService.CrmCommonUseOportunityDetails.PipeLineId;
    }
  }

  @Input('CRMOpportunityPipeLineId()')
  set CRMOpportunityPipeLineId(value: number) {

    this.CRM_OppertunityService.CrmCommonUseOportunityDetails.PipeLineId = value;

    if (this.CRM_OppertunityService.CrmCommonUseOportunityDetails.PipeLineId == undefined || this.CRM_OppertunityService.CrmCommonUseOportunityDetails.PipeLineId == null) {

      this.CRM_OppertunityService.CrmCommonUseOportunityDetails.PipeLineId = 0;     
    }

    else {
      this.CRM_OppertunityService.CrmCommonUseOportunityDetails.PipeLineId=value;

      
    }
  }


  get CRMOportunityCreatedDateval(): string {
    if (this.CRM_OppertunityService.CrmCommonUseOportunityDetails.CreatedDateval == undefined || this.CRM_OppertunityService.CrmCommonUseOportunityDetails.CreatedDateval == null) {

      this.CRM_OppertunityService.CrmCommonUseOportunityDetails.CreatedDateval = "";
    
      return   this.CRM_OppertunityService.CrmCommonUseOportunityDetails.CreatedDateval;
    }
    else {
      return    this.CRM_OppertunityService.CrmCommonUseOportunityDetails.CreatedDateval;
    }
  }

  @Input('CRMOportunityCreatedDateval')
  set CRMOportunityCreatedDateval(value: string) {

    this.CRM_OppertunityService.CrmCommonUseOportunityDetails.CreatedDateval = value;

    if (this.CRM_OppertunityService.CrmCommonUseOportunityDetails.CreatedDateval == undefined || this.CRM_OppertunityService.CrmCommonUseOportunityDetails.CreatedDateval == null) {

      this.CRM_OppertunityService.CrmCommonUseOportunityDetails.CreatedDateval = "";     
    }

    else {
      this.CRM_OppertunityService.CrmCommonUseOportunityDetails.CreatedDateval=value;
      
    }
  }


  get CRMOportunityClosDateIsValid(): boolean {
    
    if (this.CRM_OppertunityService.CrmCommonUseOportunityDetails.IsClosingDateValid == undefined || this.CRM_OppertunityService.CrmCommonUseOportunityDetails.IsClosingDateValid == null) {

      this.CRM_OppertunityService.CrmCommonUseOportunityDetails.IsClosingDateValid = false;
    
      return   this.CRM_OppertunityService.CrmCommonUseOportunityDetails.IsClosingDateValid;
    }
    else {
      
      return    this.CRM_OppertunityService.CrmCommonUseOportunityDetails.IsClosingDateValid;
    }
  }


  get CRMOpportunityTypeOfBusiness(): number {
    if (this.CRM_OppertunityService.CrmCommonUseOportunityDetails.TypeOfBusiness == undefined || this.CRM_OppertunityService.CrmCommonUseOportunityDetails.TypeOfBusiness == null) {

      this.CRM_OppertunityService.CrmCommonUseOportunityDetails.TypeOfBusiness =0;
    
     return this.CRM_OppertunityService.CrmCommonUseOportunityDetails.TypeOfBusiness;
    }
    else {
    return  this.CRM_OppertunityService.CrmCommonUseOportunityDetails.TypeOfBusiness;
    }
  }

  @Input('CRMOpportunityTypeOfBusiness()')
  set CRMOpportunityTypeOfBusiness(value: number) {

    this.CRM_OppertunityService.CrmCommonUseOportunityDetails.TypeOfBusiness = value;

    if (this.CRM_OppertunityService.CrmCommonUseOportunityDetails.TypeOfBusiness == undefined || this.CRM_OppertunityService.CrmCommonUseOportunityDetails.TypeOfBusiness == null) {

      this.CRM_OppertunityService.CrmCommonUseOportunityDetails.TypeOfBusiness = 0;     
    }

    else {
      this.CRM_OppertunityService.CrmCommonUseOportunityDetails.TypeOfBusiness=value;

      
    }
  }


  get CRMOpportunityMonthId(): number {
    if (this.CRM_OppertunityService.CrmCommonUseOportunityDetails.MonthId == undefined || this.CRM_OppertunityService.CrmCommonUseOportunityDetails.MonthId == null) {

      this.CRM_OppertunityService.CrmCommonUseOportunityDetails.MonthId =0;
    
     return this.CRM_OppertunityService.CrmCommonUseOportunityDetails.MonthId;
    }
    else {
    return  this.CRM_OppertunityService.CrmCommonUseOportunityDetails.MonthId;
    }
  }

  @Input('CRMOpportunityMonthId()')
  set CRMOpportunityMonthId(value: number) {

    this.CRM_OppertunityService.CrmCommonUseOportunityDetails.MonthId = value;

    if (this.CRM_OppertunityService.CrmCommonUseOportunityDetails.MonthId == undefined || this.CRM_OppertunityService.CrmCommonUseOportunityDetails.MonthId == null) {

      this.CRM_OppertunityService.CrmCommonUseOportunityDetails.MonthId = 0;     
    }

    else {
      this.CRM_OppertunityService.CrmCommonUseOportunityDetails.MonthId=value;

      
    }
  }





  get CRMOportunityCloseDateval(): string {
    if (this.CRM_OppertunityService.CrmCommonUseOportunityDetails.CloseDateval == undefined || this.CRM_OppertunityService.CrmCommonUseOportunityDetails.CloseDateval == null) {

      this.CRM_OppertunityService.CrmCommonUseOportunityDetails.CloseDateval = "";
    
      return   this.CRM_OppertunityService.CrmCommonUseOportunityDetails.CloseDateval;
    }
    else {
      return    this.CRM_OppertunityService.CrmCommonUseOportunityDetails.CloseDateval;
    }
  }

  @Input('CRMOportunityCloseDateval')
  set CRMOportunityCloseDateval(value: string) {

    this.CRM_OppertunityService.CrmCommonUseOportunityDetails.CloseDateval = value;

    if (this.CRM_OppertunityService.CrmCommonUseOportunityDetails.CloseDateval == undefined || this.CRM_OppertunityService.CrmCommonUseOportunityDetails.CloseDateval == null) {

      this.CRM_OppertunityService.CrmCommonUseOportunityDetails.CloseDateval = "";     
    }

    else {
      this.CRM_OppertunityService.CrmCommonUseOportunityDetails.CloseDateval=value;
      
    }
  }


  get CRMOportunityCreatedDateIsValid(): boolean {
    
    if (this.CRM_OppertunityService.CrmCommonUseOportunityDetails.IsCreatedDateValid == undefined || this.CRM_OppertunityService.CrmCommonUseOportunityDetails.IsCreatedDateValid == null) {

      this.CRM_OppertunityService.CrmCommonUseOportunityDetails.IsCreatedDateValid = false;
    
      return   this.CRM_OppertunityService.CrmCommonUseOportunityDetails.IsCreatedDateValid;
    }
    else {
      
      return    this.CRM_OppertunityService.CrmCommonUseOportunityDetails.IsCreatedDateValid;
    }
  }




  get CRMOpportunityNumberOfPax(): number {
    if (this.CRM_OppertunityService.CRM_MoreInformation_ModelShare.NumberOfPax == undefined || this.CRM_OppertunityService.CRM_MoreInformation_ModelShare.NumberOfPax == null) {

      this.CRM_OppertunityService.CRM_MoreInformation_ModelShare.NumberOfPax =0;
    
      return this.CRM_OppertunityService.CRM_MoreInformation_ModelShare.NumberOfPax;
    }
    else {
      return  this.CRM_OppertunityService.CRM_MoreInformation_ModelShare.NumberOfPax;
    }
  }

  @Input('CRMOpportunityNumberOfPax()')
  set CRMOpportunityNumberOfPax(value: number) {

    this.CRM_OppertunityService.CRM_MoreInformation_ModelShare.NumberOfPax = value;

    if (this.CRM_OppertunityService.CRM_MoreInformation_ModelShare.NumberOfPax == undefined || this.CRM_OppertunityService.CRM_MoreInformation_ModelShare.NumberOfPax == null) {

      this.CRM_OppertunityService.CRM_MoreInformation_ModelShare.NumberOfPax = 0;     
    }

    else {
      this.CRM_OppertunityService.CRM_MoreInformation_ModelShare.NumberOfPax=value;

      
    }
  }




  get CRMOppertunityDescription(): string {
    if (this.CRM_OppertunityService.CrmCommonUseOportunityDetails.OppertunityDescription== undefined || this.CRM_OppertunityService.CrmCommonUseOportunityDetails.OppertunityDescription == null) {

      this.CRM_OppertunityService.CrmCommonUseOportunityDetails.OppertunityDescription = "";
    
      return this.CRM_OppertunityService.CrmCommonUseOportunityDetails.OppertunityDescription;
    }
    else {
      return this.CRM_OppertunityService.CrmCommonUseOportunityDetails.OppertunityDescription;
    }
  }

  @Input('CRMOppertunityDescription')
  set CRMOppertunityDescription(value: string) {

    this.CRM_OppertunityService.CrmCommonUseOportunityDetails.OppertunityDescription = value;

    if (this.CRM_OppertunityService.CrmCommonUseOportunityDetails.OppertunityDescription == undefined || this.CRM_OppertunityService.CrmCommonUseOportunityDetails.OppertunityDescription == null) {

      this.CRM_OppertunityService.CrmCommonUseOportunityDetails.OppertunityDescription = "";
     
    }

    else {
      this.CRM_OppertunityService.CrmCommonUseOportunityDetails.OppertunityDescription=value;


    }
  }



  get CRMIsOppinViewstat(): boolean {
    if (this.CRM_OppertunityService.IsOppinViewstate== undefined || this.CRM_OppertunityService.IsOppinViewstate == null) {

      this.CRM_OppertunityService.IsOppinViewstate = false;
    
      return this.CRM_OppertunityService.IsOppinViewstate;
    }
    else {
      return this.CRM_OppertunityService.IsOppinViewstate;
    }
  }

  get CRMOpportunityCompanyId(): number {
    if (this.CRM_OppertunityService.CrmCommonUseOportunityDetails.CompanyId == undefined || this.CRM_OppertunityService.CrmCommonUseOportunityDetails.CompanyId == null) {

      this.CRM_OppertunityService.CrmCommonUseOportunityDetails.CompanyId =0;
    
      return this.CRM_OppertunityService.CrmCommonUseOportunityDetails.CompanyId;
    }
    else {
      return  this.CRM_OppertunityService.CrmCommonUseOportunityDetails.CompanyId;
    }

    
  }
       
  
  get CRMListOpportunityDocs(): Model_CRM_Opportunity_Document[] {
    if (this.CRM_OppertunityService.CrmCommonUseOportunityDetails.ListOpportunityDocs == undefined || this.CRM_OppertunityService.CrmCommonUseOportunityDetails.ListOpportunityDocs == null) {

      this.CRM_OppertunityService.CrmCommonUseOportunityDetails.ListOpportunityDocs =[];
    
      return this.CRM_OppertunityService.CrmCommonUseOportunityDetails.ListOpportunityDocs;
    }
    else {
      return  this.CRM_OppertunityService.CrmCommonUseOportunityDetails.ListOpportunityDocs;
    }

    this.CRM_OppertunityService.CrmCommonUseOportunityDetails.ListOpportunityDocs;
  }



  get CRMOppertunityId(): number {
    if (this.CRM_OppertunityService.CrmCommonUseOportunityDetails.OppertunityId == undefined ||this.CRM_OppertunityService.CrmCommonUseOportunityDetails.OppertunityId == null) {

      this.CRM_OppertunityService.CrmCommonUseOportunityDetails.OppertunityId =0;
    
      return this.CRM_OppertunityService.CrmCommonUseOportunityDetails.OppertunityId;
    }
    else {
      return  this.CRM_OppertunityService.CrmCommonUseOportunityDetails.OppertunityId;
    }

    
  }



}
