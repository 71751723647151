import { Component, OnInit} from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from '../../shared/user.service';
import { EmployeeMasterService }  from '../../shared/HR_ADMIN/Employee/EmployeeMaster.Service';
import { EmployeeDetail } from '../../shared/HR_ADMIN/Employee/EmployeeDetail.Model';
//Begin For Notification service
import { NotificationsService} from '../../shared/notifications.service';
//End of notification service
import { Menu } from '../../shared/menu.model';

@Component({
  selector: 'app-employee-personal-info',
  templateUrl: './employee-personal-info.component.html',
  styleUrls: ['./employee-personal-info.component.css']
})
export class EmployeePersonalInfoComponent implements OnInit 
{
  EmployeeModel : EmployeeDetail= new EmployeeDetail();
  userClaims: any;
  Employees: EmployeeDetail[] = [];
  employee: any;
  SubMenues : Menu[]=[];
  Mod : any;
  Result : string;
  SuccessStatus:boolean=false;
  SuccessMessage: string ="";
  public tableWidget: any;
  EmployeeId : any;
  selectedIndex : number;
  // Begning Permission Portion    
  SelectedMenu:Menu;
  IsDataIsInViewState : boolean=false;
  IsButtonIsInViewState : boolean=false;
  IsEmployeeInViewState : boolean=false;
  IsjoiningInViewState:boolean=true;
  // End of the permission portion
  // begin for Validation
  validdate : boolean= false;
  // end of validation
  employeeEducationUndefine : string ="";
  employeeExpriencUndefine : string ="";
  employeeNomineeDetailsUndefine : string ="";
  employeeFamilyDetailsUndefine : string ="";

  constructor(public EmployeeMasterService: EmployeeMasterService, private rout: Router, private userService: UserService, private notificationsService: NotificationsService) 
  { 
  }
  
  ngOnInit() 
  {
    // Begning Permission Portion 
    // this.SubMenues=[];
    // let t =  localStorage.getItem('userToken');
    // t = 'Bearer' + t;
    // let menuid = localStorage.getItem("menuidstor");
    // this.userService.GetSubMenuList( t, menuid).then(x => 
    // {
    //   this.Mod = x;
    //   Object.keys(this.Mod).forEach( key => 
    //   {
    //     // tslint:disable-next-line:no-unused-expression
    //     this.Mod[key];
    //     let menutemp = new Menu();
    //     menutemp = this.Mod[key];
    //     this.SubMenues.push(menutemp);
    //   });
    //   this.userService.SelectedMenu= this.SubMenues[0];
    //   this.rout.navigate([this.userService.SelectedMenu.RouteName]);
    //   this.SelectedMenu=this.userService.SelectedMenu;
    //   if(this.SelectedMenu.CanAdd)
    //   {
    //     this.IsDataIsInViewState=false;
    //   }
    //   else
    //   {
    //     this.IsDataIsInViewState=true;
    //   }
    // });
    // End of the permission portion

    this.GetEmpDetailsForView();
  }

  GetEmpDetailsForView()
  {
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.EmployeeMasterService.GetEmployeeDetailsForView( t).then(x => 
    {
      this.employee = x;
      Object.keys(this.employee).forEach( key => 
      {
        this.employee[key];
        let employeetemp = new EmployeeDetail();
        employeetemp = this.employee[key];
        this.EmployeeMasterService.SharedEmployees=this.employee;
        if( this.EmployeeMasterService.SharedEmployees.EmployeeEducation.length == 0)
        {
          this.employeeEducationUndefine ='notdefinedval';
        }
        if( this.EmployeeMasterService.SharedEmployees.EmployeeExprienceModel.length == 0)
        {
          this.employeeExpriencUndefine ='notdefinedval';
        }
        if(this.EmployeeMasterService.SharedEmployees.EmployeeNomineeDetailModel.length==0)
        {
          this.employeeNomineeDetailsUndefine ='notdefinedval';
        }
        if( this.EmployeeMasterService.SharedEmployees.EmployeeFamilyDetailsModel.length ==0)
        {
          this.employeeFamilyDetailsUndefine='notdefinedval';
        }
      });
    });
  }
}
