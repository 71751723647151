import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from '../../shared/user.service';
import { LocationService }  from '../../shared/HR_ADMIN/Location/location.service';
import { EmployeeMasterService }  from '../../shared/HR_ADMIN/Employee/EmployeeMaster.Service';
import { EmployeeDetail } from '../../shared/HR_ADMIN/Employee/EmployeeDetail.Model';
import { StandardSalaryModel } from '../../shared/HR_ADMIN/Employee/StandardSalaryModel.Model'
import { PtaxSlabModel } from '../../shared/HR_ADMIN/Salary/PtaxSlabModel'
import { EPFESIModel } from '../../shared/HR_ADMIN/Admin/EPFESIModel.Model'
import { SalaryMasterService }  from '../../shared/HR_ADMIN/Salary/SalaryMaster.service';
import { State } from '../../shared/Organization/State.Model';
import { Menu } from '../../shared/menu.model';
import { ModalOptions,BsModalRef, BsModalService } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-employee-ctc',
  templateUrl: './employee-ctc.component.html',
  styleUrls: ['./employee-ctc.component.css']
})

export class EmployeeCtcComponent implements OnInit 
{
  StandardSalaryModel : StandardSalaryModel= new StandardSalaryModel();
  StandardSalarys : StandardSalaryModel[]=[];

  StandardSalary: StandardSalaryModel[] = [];
  SalaryComponentRules : StandardSalaryModel[] =[];

  userClaims: any;
  Employees: EmployeeDetail[] = [];
  employee: any;
  RefId : any;

  Result : string;
  SuccessStatus:boolean=false;
  SuccessMessage: string ="";
  public tableWidget: any;
  EmployeeId : any;
  selectedIndex : number;

  tempPtaxSlabModel : PtaxSlabModel = new PtaxSlabModel();
  tempPtaxSlabModels : any;
  P_Tax : number;

  tempEPFESIModel : EPFESIModel = new EPFESIModel();
  tempEPFESIModels : any;
  
  ComponentTotal : number;
  ComponentSubTotal : number;
  Longtermbenefits : number;

  PFDed :number=0;
  PFCont :number=0;
  ESIDed :number=0;
  ESICont :number=0;
  MinBasicSalary : number=0;

  PtaxStateId : number=0;
  tempEmployeeadd : EmployeeDetail =new EmployeeDetail();
  // Begning Permission Portion    
  SelectedMenu:Menu;
  IsDataIsInViewState : boolean=false;
  CanViewState : boolean=true;
  // End of the permission portion
  States: State[] = [];
  State: any;
  // begin for Validation
  validdate : boolean= false;
  DisableSave : boolean=false;
  DisableState : boolean=false;
  // end of validation

  //Begin Modification Standard Salary
  ModificationStandardSalary: StandardSalaryModel[] = [];
  ModificationComponentTotal : number;
  ModificationComponentSubTotal : number;
  ModificationLongtermbenefits : number;
  ModificationPFDed :number=0;
  ModificationPFCont :number=0;
  ModificationESIDed :number=0;
  ModificationESICont :number=0;
  ModificationP_Tax : number;
  // End Modification Standard Salary
  //For Pop up
  UpdateMsg : boolean= false;
  ShowPopup : boolean= false;
  public modalRef: BsModalRef;
  public modelopt:ModalOptions;
  dtOptions: DataTables.Settings = {};

  constructor(private EmployeeMasterService: EmployeeMasterService, private rout: Router, private userService: UserService,private LocationService: LocationService,private EmpSalary: SalaryMasterService,private modalService: BsModalService) 
  {
    this.modelopt=new ModalOptions();
    this.modelopt.class='heavy_modal';
    this.modalService.config= this.modelopt;
    this.modelopt.ignoreBackdropClick=true;
  }

  ngOnInit() 
  {
    // Begning Permission Portion 
    this.SelectedMenu=this.userService.SelectedMenu;
    if(this.SelectedMenu !=undefined)
    {
      localStorage.setItem('selectedmenu',JSON.stringify(this.SelectedMenu));
      this.rout.navigate([this.SelectedMenu.RouteName]);
    }
    else
    {
      if(JSON.parse(localStorage.getItem('selectedmenu'))!=undefined)
      {
        this.SelectedMenu = JSON.parse(localStorage.getItem('selectedmenu'));
        this.rout.navigate([this.SelectedMenu.RouteName]);
      }
    }
    if(this.SelectedMenu.CanAdd)
    {
      this.IsDataIsInViewState=false;
    }
    else
    {
      this.IsDataIsInViewState=true;
    }
    if(this.SelectedMenu.CanView)
    {
      this.CanViewState=false;
    }
    else
    {
      this.CanViewState=true;
    }
    // End of the permission portion
    this.DisableSave=true;
    this.GetEpfEsi();
    this.getStates();
    this.GetEmployees();
    this.P_Tax=0;
    this.ModificationP_Tax=0;
    this.UpdateMsg=false;
  }
  
  GetEmployees()
  {
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.EmployeeMasterService.GetEmployeeDetailsForStandardSalary( t).then(x => 
    {
      this.employee = x;
      Object.keys(this.employee).forEach( key => 
      {
        this.employee[key];
        let employeetemp = new EmployeeDetail();
        employeetemp = this.employee[key];
        this.Employees.push(employeetemp);
      });
    });
  }

  GetEmployeeStandardSalaryByEmployeeId(EmployeeId,PtaxStateId,template)
  {
    this.ModificationStandardSalary=[];
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.EmployeeMasterService.GetEmployeeStandardSalaryByEmployeeId( t,EmployeeId).then(x => 
    {
      if(this.ShowPopup==true)
      {
        this.modalRef.hide();
      }
      this.modalRef = this.modalService.show(template,this.modelopt);
      this.ShowPopup=true;
      this.UpdateMsg=false;
      this.employee = x;
      {
        if(this.StandardSalary.length >0)
        {
          this.StandardSalary=[];
        } 
        this.ComponentTotal=0;
        this.ComponentSubTotal=0;
        this.Longtermbenefits = 0;
        Object.keys(this.employee).forEach( key => 
        {
          this.employee[key];
          let employeetemp = new StandardSalaryModel();
          employeetemp = this.employee[key];
          this.StandardSalaryModel.EmployeeCode=employeetemp.EmployeeCode;
          this.StandardSalaryModel.EmployeeName=employeetemp.EmployeeName;
          this.StandardSalaryModel.ESIApplicable=employeetemp.ESIApplicable;
          this.StandardSalaryModel.EPFApplicable=employeetemp.EPFApplicable;
          this.StandardSalaryModel.PTAXApplicable=employeetemp.PTAXApplicable;
          this.StandardSalaryModel.PtaxStateId=PtaxStateId;
          if(employeetemp.SalaryComponentCode=='BS')
          {
            let BAmount = Math.round(this.employee.find(x=>x.SalaryComponentCode=='BS').Amount);
            this.GetEPFCalculation(BAmount);
          }
          if(employeetemp.IsBenefit==false)
          {              
            //this.ComponentSubTotal = this.ComponentSubTotal + employeetemp.Amount;  
            var tempvaluef =
            {
              a: Number(this.ComponentSubTotal),
              b: Number(employeetemp.Amount)  
            }
            this.ComponentSubTotal = Math.round(tempvaluef.a) + Math.round(tempvaluef.b); 
          }
          else
          {
            // this.Longtermbenefits = this.Longtermbenefits +  employeetemp.Amount;
            var tempvaluef = 
            {
              a: Number(this.Longtermbenefits),
              b: Number(employeetemp.Amount)  
            }
            this.Longtermbenefits = Math.round(tempvaluef.a) + Math.round(tempvaluef.b); 
          }
          this.StandardSalary.push(employeetemp);            
        }); 
        this.GetESICalculation(this.ComponentSubTotal);
        this.GetPtax();
      }
    });
  }
 
  GetEPFCalculation( BAmount)
  {
    if(this.StandardSalaryModel.EPFApplicable==true)
    {
      this.MinBasicSalary=this.tempEPFESIModel.HighestBasicSalary;
      if(BAmount >= this.MinBasicSalary)
      {
        this.PFDed=Math.round((this.MinBasicSalary*this.tempEPFESIModel.EPFDed)/100);
        this.PFCont=Math.round((this.MinBasicSalary*this.tempEPFESIModel.EPFCont)/100);
      }
      else
      {
        this.PFDed=Math.round((BAmount * this.tempEPFESIModel.EPFDed)/100);
        this.PFCont=Math.round((BAmount * this.tempEPFESIModel.EPFCont)/100);
      }
    }
    else
    {
      this.PFDed=0.00;
      this.PFCont=0.00;
    }
  }

  GetESICalculation( GrossSalary)
  {
    if(this.StandardSalaryModel.ESIApplicable==true)
    {
      this.MinBasicSalary=this.tempEPFESIModel.HighestBasicSalary;
      if(GrossSalary >= this.MinBasicSalary)
      {
        this.ESIDed=0.00;
        this.ESICont=0.00;
      }
      else
      {
        this.ESIDed=Math.round((GrossSalary * this.tempEPFESIModel.ESIDed)/100);
        this.ESICont=Math.round((GrossSalary * this.tempEPFESIModel.ESICont)/100);
      } 
    }
    else
    {
      this.ESIDed=0.00;
      this.ESICont=0.00;
    }
  }

  GetEpfEsi()
  {
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.EmpSalary.GetEPFESISlabforOffer(t).then(y=>
    {
      this.tempEPFESIModels=y;
      Object.keys(this.tempEPFESIModels).forEach( key => 
      {
        this.tempEPFESIModel=this.tempEPFESIModels[key]; 
        this.tempEPFESIModel.EPFDed=this.tempEPFESIModel.EPFDed;
        this.tempEPFESIModel.EPFCont=this.tempEPFESIModel.EPFCont;
        this.tempEPFESIModel.ESIDed=this.tempEPFESIModel.ESIDed;
        this.tempEPFESIModel.ESICont=this.tempEPFESIModel.ESICont;
        this.tempEPFESIModel.HighestBasicSalary=this.tempEPFESIModel.HighestBasicSalary;                    
      }); 
    });
  }

  GetPtax()
  {
    if(this.StandardSalaryModel.PTAXApplicable==true)
    {
      let t =  localStorage.getItem('userToken');
      t = 'Bearer' + t;
      this.EmpSalary.GetPtaxSlabByBasicSalaryForOffer(t,this.StandardSalaryModel.PtaxStateId,this.ComponentSubTotal).then(y=>
      {
        this.tempPtaxSlabModels=y;
        this.P_Tax = 0.00;
        this.StandardSalaryModel.NetTakeHome=Math.round((this.ComponentSubTotal)-(this.PFDed + this.ESIDed + this.P_Tax));
        Object.keys(this.tempPtaxSlabModels).forEach( key => 
        {
          this.tempPtaxSlabModel=this.tempPtaxSlabModels[key]; 
          this.P_Tax = Math.round(this.tempPtaxSlabModel.TaxAmount);                    
        }); 
        this.StandardSalaryModel.NetTakeHome=Math.round((this.ComponentSubTotal)-(this.PFDed + this.ESIDed + this.P_Tax));
      }); 
    }
    else
    {
      this.P_Tax = 0.00;
      this.StandardSalaryModel.NetTakeHome=Math.round((this.ComponentSubTotal)-(this.PFDed + this.ESIDed + this.P_Tax));
    }
  }

  RefreshPage()
  {
    this.modalRef.hide();
    this.rout.navigateByUrl('/Employee/EmployeeOffer', {skipLocationChange: true}).then(()=>this.rout.navigate(["/Employee/EmployeeCTC"]));
  }

  // Modification Standard Salary
  GetEmployeeStandardSalaryModificationByEmployeeId(EmployeeId,PtaxStateId,template)
  {
    this.StandardSalary=[];
    this.validdate = true;
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.EmployeeMasterService.GetEmployeeStandardSalaryModificationByEmployeeId( t,EmployeeId).then(x => 
    {
      if(this.ShowPopup==true)
      {
        this.modalRef.hide();
      }
      this.DisableSave=true;
      this.modalRef = this.modalService.show(template,this.modelopt);
      this.ShowPopup=true;
      this.UpdateMsg=false;
      this.employee = x;
      {
        if(this.ModificationStandardSalary.length >0)
        {
          this.ModificationStandardSalary=[];
        } 
        this.ComponentTotal=0;
        this.ComponentSubTotal=0;
        this.Longtermbenefits = 0;
        this.ModificationComponentTotal=0;
        this.ModificationComponentSubTotal=0;
        this.ModificationLongtermbenefits = 0;
        Object.keys(this.employee).forEach( key => 
        {
          this.employee[key];
          let employeetemp = new StandardSalaryModel();
          employeetemp = this.employee[key];
          this.StandardSalaryModel.RefIdSSM=employeetemp.RefIdSSM;
          this.StandardSalaryModel.EmployeeId=employeetemp.EmployeeId;
          this.StandardSalaryModel.EmployeeCode=employeetemp.EmployeeCode;
          this.StandardSalaryModel.EmployeeName=employeetemp.EmployeeName;
          this.StandardSalaryModel.ESIApplicable=employeetemp.ESIApplicable;
          this.StandardSalaryModel.EPFApplicable=employeetemp.EPFApplicable;
          this.StandardSalaryModel.PTAXApplicable=employeetemp.PTAXApplicable;
          this.StandardSalaryModel.ModificationESIApplicable=employeetemp.ESIApplicable;
          this.StandardSalaryModel.ModificationEPFApplicable=employeetemp.EPFApplicable;
          this.StandardSalaryModel.ModificationPTAXApplicable=employeetemp.PTAXApplicable;
          this.StandardSalaryModel.PtaxStateId=PtaxStateId;

          if(employeetemp.PTAXApplicable==true)
          {
            this.DisableState=true;
            this.StandardSalaryModel.PtaxStateId=PtaxStateId;
          }
          else
          {
            this.StandardSalaryModel.PtaxStateId=0;
            this.DisableState=false;
          }
          this.StandardSalaryModel.ModificationApplicableFrom=employeetemp.ModificationApplicableFrom;
          this.StandardSalaryModel.ApplicableFrom=employeetemp.ApplicableFrom;
          if(employeetemp.SalaryComponentCode == 'BS')
          {
            let BAmount = Math.round(this.employee.find(x=>x.SalaryComponentCode=='BS').Amount);
            let ModificationBAmount = Math.round(this.employee.find(x=>x.SalaryComponentCode=='BS').ModificationAmount);
            this.GetEPFCalculation(BAmount);
            this.GetEPFCalculationModification(ModificationBAmount);
          }
          if(employeetemp.IsBenefit==false)
          {              
            //this.ComponentSubTotal = this.ComponentSubTotal + employeetemp.Amount;  
            var tempvaluef =
            {
              a: Number(this.ComponentSubTotal),
              b: Number(employeetemp.Amount)  
            }
            this.ComponentSubTotal = Math.round(tempvaluef.a) + Math.round(tempvaluef.b); 
          }
          else
          {
            // this.Longtermbenefits = this.Longtermbenefits +  employeetemp.Amount;
            var tempvaluef = 
            {
              a: Number(this.Longtermbenefits),
              b: Number(employeetemp.Amount)  
            }
            this.Longtermbenefits = Math.round(tempvaluef.a) + Math.round(tempvaluef.b); 
          }

          if(employeetemp.ModificationIsBenefit==false)
          {              
            //this.ComponentSubTotal = this.ComponentSubTotal + employeetemp.Amount;  
            var tempvaluef =
            {
              a: Number(this.ModificationComponentSubTotal),
              b: Number(employeetemp.ModificationAmount)  
            }
            this.ModificationComponentSubTotal = Math.round(tempvaluef.a) + Math.round(tempvaluef.b); 
          }
          else
          {
            // this.Longtermbenefits = this.Longtermbenefits +  employeetemp.Amount;
            var tempvaluef = 
            {
              a: Number(this.ModificationLongtermbenefits),
              b: Number(employeetemp.ModificationAmount)  
            }
            this.ModificationLongtermbenefits = Math.round(tempvaluef.a) + Math.round(tempvaluef.b); 
          }
          
          this.ModificationStandardSalary.push(employeetemp);            
        }); 
        this.GetESICalculation(this.ComponentSubTotal);
        this.GetESICalculationModification(this.ModificationComponentSubTotal);
        this.GetPtax();
        this.GetPtaxModification();
        this.StandardSalaryModel.ModificationGrossSalary=Math.round((this.ModificationComponentSubTotal));
        this.StandardSalaryModel.ModificationCTCAmount=Math.round((this.ModificationLongtermbenefits + this.ModificationComponentSubTotal + this.ModificationPFCont + this.ModificationESICont));
        this.StandardSalaryModel.ModificationNetTakeHome=Math.round((this.ModificationComponentSubTotal)-(this.ModificationPFDed + this.ModificationESIDed + this.ModificationP_Tax));
      }
    });
  }

  GetEPFCalculationModification( BAmount)
  {
    if(this.StandardSalaryModel.ModificationEPFApplicable==true)
    {
      this.MinBasicSalary=this.tempEPFESIModel.HighestBasicSalary;
      if(BAmount >= this.MinBasicSalary)
      {
        this.ModificationPFDed=Math.round((this.MinBasicSalary*this.tempEPFESIModel.EPFDed)/100);
        this.ModificationPFCont=Math.round((this.MinBasicSalary*this.tempEPFESIModel.EPFCont)/100);
      }
      else
      {
        this.ModificationPFDed=Math.round((BAmount * this.tempEPFESIModel.EPFDed)/100);
        this.ModificationPFCont=Math.round((BAmount * this.tempEPFESIModel.EPFCont)/100);
      }
    }
    else
    {
      this.ModificationPFDed=0.00;
      this.ModificationPFCont=0.00;
    }
  }

  GetESICalculationModification( GrossSalary)
  {
    if(this.StandardSalaryModel.ModificationESIApplicable==true)
    {
      this.MinBasicSalary=this.tempEPFESIModel.HighestBasicSalary;
      if(GrossSalary >= this.MinBasicSalary)
      {
        this.ModificationESIDed=0.00;
        this.ModificationESICont=0.00;
      }
      else
      {
        this.ModificationESIDed=Math.round((GrossSalary * this.tempEPFESIModel.ESIDed)/100);
        this.ModificationESICont=Math.round((GrossSalary * this.tempEPFESIModel.ESICont)/100);
      } 
    }
    else
    {
      this.ModificationESIDed=0.00;
      this.ModificationESICont=0.00;
    }
  }

  GetPtaxModification()
  {
    if(this.StandardSalaryModel.ModificationPTAXApplicable==true)
    {
      let t =  localStorage.getItem('userToken');
      t = 'Bearer' + t;
      this.EmpSalary.GetPtaxSlabByBasicSalaryForOffer(t,this.StandardSalaryModel.PtaxStateId,this.ModificationComponentSubTotal).then(y=>
      {
        this.tempPtaxSlabModels=y;
        this.ModificationP_Tax = 0.00;
        this.StandardSalaryModel.ModificationNetTakeHome=Math.round((this.ModificationComponentSubTotal)-(this.ModificationPFDed + this.ModificationESIDed + this.ModificationP_Tax));
        Object.keys(this.tempPtaxSlabModels).forEach( key => 
        {
          this.tempPtaxSlabModel=this.tempPtaxSlabModels[key]; 
          this.ModificationP_Tax =Math.round(this.tempPtaxSlabModel.TaxAmount);                    
        }); 
        this.StandardSalaryModel.ModificationNetTakeHome=Math.round((this.ModificationComponentSubTotal)-(this.ModificationPFDed + this.ModificationESIDed + this.ModificationP_Tax));
      }); 
    }
    else
    {
      this.ModificationP_Tax = 0.00;
      this.StandardSalaryModel.ModificationNetTakeHome=Math.round((this.ModificationComponentSubTotal)-(this.ModificationPFDed + this.ModificationESIDed + this.ModificationP_Tax));
    }
  }

  onBasicChangevaluechange()
  {
    this.ModificationComponentTotal=0;
    this.ModificationComponentSubTotal=0;
    this.ModificationLongtermbenefits = 0;
    this.ModificationStandardSalary.forEach(x=>
    {     
      let BAmount =Math.round(this.ModificationStandardSalary.find(x=>x.SalaryComponentCode=='BS').ModificationAmount);
      this.GetEPFCalculationModification(BAmount);
      if(x.ModificationIsBenefit==false)
      {
        var tempvaluef = 
        {
          a: Number(this.ModificationComponentSubTotal),
          b: Number(x.ModificationAmount)  
        }
        this.ModificationComponentSubTotal = Math.round(tempvaluef.a) + Math.round(tempvaluef.b);
      }
      else
      {
        var tempvaluef = 
        {
          a: Number(this.ModificationLongtermbenefits),
          b: Number(x.ModificationAmount)                 
        }
        this.ModificationLongtermbenefits = Math.round(tempvaluef.a) + Math.round(tempvaluef.b);
      }
    })
    this.StandardSalaryModel.ModificationGrossSalary=Math.round(this.ModificationComponentSubTotal);
    this.GetESICalculationModification(Math.round(this.ModificationComponentSubTotal));
    this.GetPtaxModification();
    this.StandardSalaryModel.ModificationCTCAmount=Math.round(this.ModificationLongtermbenefits + this.ModificationComponentSubTotal + this.ModificationPFCont + this.ModificationESICont);
    this.StandardSalaryModel.ModificationGrossSalary=Math.round((this.ModificationComponentSubTotal));
    this.StandardSalaryModel.ModificationCTCAmount=Math.round((this.ModificationLongtermbenefits + this.ModificationComponentSubTotal + this.ModificationPFCont + this.ModificationESICont));
    this.StandardSalaryModel.ModificationNetTakeHome=Math.round((this.ModificationComponentSubTotal)-(this.ModificationPFDed + this.ModificationESIDed + this.ModificationP_Tax));
  }

  dateChanged( date)
  {
    this.StandardSalaryModel.ModificationApplicableFrom=date;
    var CurrentDate = new Date(this.StandardSalaryModel.ApplicableFrom);
    var CurrentYear = CurrentDate.getFullYear();
    var currentMonth = CurrentDate.getMonth();
    var CurrentDay = CurrentDate.getDate();
    
    var selecteedDate = new Date(date);
    var selectedYear=selecteedDate.getFullYear();
    var selectedMonth=selecteedDate.getMonth();
    var selectedDay=selecteedDate.getDate();

    if(CurrentYear < selectedYear )
    {
      this.validdate = true;      
    }
    else if(CurrentYear > selectedYear)
    {
      this.validdate = false;
    }
    else
    {
      if (currentMonth < selectedMonth)
      {
        this.validdate = true;
      }
      else if(currentMonth > selectedMonth)
      {
        this.validdate = false;
      }
      else
      {
        if(CurrentDay <= selectedDay )
        {
          this.validdate = true;
        }
        else
        {
          this.validdate = false;
        }
      }
    }
  }

  oncheckedEPFApplicableChange()
  {
    this.onBasicChangevaluechange();
  }

  oncheckedESIApplicableChange() 
  {
    this.onBasicChangevaluechange();
  }

  oncheckedPTAXApplicableChange() 
  {
    if(this.StandardSalaryModel.ModificationPTAXApplicable == true)
    {
      this.DisableState=true;
      this.StandardSalaryModel.PtaxStateId=0;
    }
    else
    {
      this.StandardSalaryModel.PtaxStateId=0;
      this.DisableState=false;
    }
    this.onBasicChangevaluechange();
  }

  UpdateEmployeeStandardSalary(template) :void
  {
    this.UpdateMsg=false;
    this.DisableSave=false;
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.StandardSalaryModel.ModificationApplicableFrom=this.StandardSalaryModel.ModificationApplicableFrom;
    this.EmployeeMasterService.UpdateEmployeeStandardSalaryMaster(t ,this.StandardSalaryModel).subscribe(res=>
    {
      this.StandardSalaryModel.RefIdSSM=Number(res);
      var j=0;
      var l=this.ModificationStandardSalary.length;
      this.ModificationStandardSalary.forEach(item =>
      {
        j=j+1;
        if(item.ModificationAmount > 0)
        {
          item.RefIdSSM=this.StandardSalaryModel.RefIdSSM;
          this.EmployeeMasterService.UpdateEmployeeStandardSalary(t ,item).subscribe(res=>
          {
            this.RefId=res;        
          });                  
        }   
      }); 
      if(this.StandardSalaryModel.RefIdSSM>0)
      {
        this.StandardSalary=[];
        this.validdate = true;
        if(this.ModificationStandardSalary.length >0)
        {
          this.ModificationStandardSalary=[];
        } 
        this.ComponentTotal=0;
        this.ComponentSubTotal=0;
        this.Longtermbenefits = 0;
        this.ModificationComponentTotal=0;
        this.ModificationComponentSubTotal=0;
        this.ModificationLongtermbenefits = 0;
        this.StandardSalaryModel.RefIdSSM=null;
        this.StandardSalaryModel.EmployeeId=null;
        this.StandardSalaryModel.EmployeeCode=null;
        this.StandardSalaryModel.EmployeeName=null;
        this.StandardSalaryModel.ESIApplicable=null;
        this.StandardSalaryModel.EPFApplicable=null;
        this.StandardSalaryModel.PTAXApplicable=null;
        this.StandardSalaryModel.ModificationESIApplicable=null;
        this.StandardSalaryModel.ModificationEPFApplicable=null;
        this.StandardSalaryModel.ModificationPTAXApplicable=null;
        this.StandardSalaryModel.PtaxStateId=null;
        
        this.modalRef.hide();
        this.ShowPopup=false;
        this.UpdateMsg = true;
        this.modalRef = this.modalService.show(template,this.modelopt);
        
        // localStorage.setItem('SuccessfullMessage', "Modified Successfully");
        // this.rout.navigateByUrl('/Employee/EmployeeOffer', {skipLocationChange: true}).then(()=>this.rout.navigate(["/Employee/EmployeeCTC"]));
      }  
    });
  }

  getStates() 
  {
    this.States=[];
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t
    if(this.States.length>0)
    {
      this.States.splice(0, this.States.length);
    }
    this.LocationService.GetState( t).then(x => 
    {
      this.States=[];
      this.State = x;
      Object.keys(this.State).forEach( key => 
      {
        // tslint:disable-next-line:no-unused-expression
        let Statetemp = new State();
        Statetemp = this.State[key];
        this.States.push(Statetemp);
      });     
    });      
  }

  onPtaxStateChange()
  {
    if(this.StandardSalaryModel.PtaxStateId > 0)
    {
      this.onBasicChangevaluechange();
    }
    else
    {
      this.StandardSalaryModel.PtaxStateId=0;
    }
  }
}