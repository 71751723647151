import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from '../../shared/user.service';
import { EmployeePortalService }  from '../../shared/HR_ADMIN/EmployeePortal/EmployeePortalService.service';
import { EmployeeLeaveBalanceModel }  from '../../shared/HR_ADMIN/EmployeePortal/EmployeeLeaveBalanceModel.Model';
import { Menu } from '../../shared/menu.model';

@Component({
  selector: 'app-leave-balance-adjustment-details',
  templateUrl: './leave-balance-adjustment-details.component.html',
  styleUrls: ['./leave-balance-adjustment-details.component.css']
})

export class LeaveBalanceAdjustmentDetailsComponent implements OnInit 
{
  EmployeeLeaveBalanceModel : EmployeeLeaveBalanceModel= new EmployeeLeaveBalanceModel();
  EmployeeLeaveBalanceAdjustemts: EmployeeLeaveBalanceModel[]=[];
  Application: any;
  SelectedMenu:Menu;
  CanViewState : boolean=true;


  constructor(private rout: Router, private userService: UserService,private EmployeePortalService: EmployeePortalService) 
  {  
  }

  ngOnInit() 
  {
    // Begning Permission Portion 
    this.SelectedMenu=this.userService.SelectedMenu;
    if(this.SelectedMenu !=undefined)
    {
      localStorage.setItem('selectedmenu',JSON.stringify(this.SelectedMenu));
      this.rout.navigate([this.SelectedMenu.RouteName]);
    }
    else
    {
      if(JSON.parse(localStorage.getItem('selectedmenu'))!=undefined)
      {
        this.SelectedMenu = JSON.parse(localStorage.getItem('selectedmenu'));
        this.rout.navigate([this.SelectedMenu.RouteName]);
      }
    }
    if(this.SelectedMenu.CanView)
    {
      this.CanViewState=false;
    }
    else
    {
      this.CanViewState=true;
    }
    // End of the permission portion
    this.GetEmployeeLeaveBalanceAdjustment();
  }

  GetEmployeeLeaveBalanceAdjustment()
  {
    this.EmployeeLeaveBalanceAdjustemts=[];
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.EmployeePortalService.GetEmployeeLeaveBalanceAdjustmentDetailsByLogingId( t).then(x => 
    {
      this.Application = x;
      Object.keys(this.Application).forEach( key => 
      {
        this.Application[key];
        let Applicationtemp = new EmployeeLeaveBalanceModel();
        Applicationtemp = this.Application[key];
        this.EmployeeLeaveBalanceAdjustemts.push(Applicationtemp);
      });
    });
  }
}