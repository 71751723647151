import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs/Subject';
import { DataTableDirective } from 'angular-datatables';
import { OrganizationService } from '../shared/Organization/organization.service';
import { LocationService } from '../shared/HR_ADMIN/Location/location.service';
import { Country } from '../shared/Organization/Country.Model';
import { City } from '../shared/HR_ADMIN/Location/City.Model';
import { State } from '../shared/Organization/State.Model';
import { District } from '../shared/HR_ADMIN/Location/District.Model';
import { UserService } from '../shared/user.service';
// Begning Confirmation
import { ConfirmationService } from '@jaspero/ng2-confirmations';
import { ResolveEmit } from '../../app/resolve-emit';
import { ConfirmSettings } from '../../app/confirm-settings';
// end of Confirmation
import { Menu } from '../shared/menu.model';

@Component({
  selector: 'app-manage-city',
  templateUrl: './manage-city.component.html',
  styleUrls: ['./manage-city.component.css']
})
export class ManageCityComponent implements OnInit 
{
  CityModel : City= new City();
  userClaims: any;
  Countries: Country[] = [];
  Cities: City[] = []; 
  States: State[] = []; 
  Districts: District[] = []; 
  Result : string;
  SuccessStatus:boolean=false;
  SuccessMessage: string ="";
  public tableWidget: any;
  countryId : any;
  country : any;
  CityId : any;
  city: any;
  state: any;
  District:any;
  tempCityadd : City =new City();
  // Begning Permission Portion    
  SelectedMenu:Menu;
  IsDataIsInViewState : boolean=false;
  CanViewState : boolean=false;
  DisableSave :boolean=false;
  FormViewShow : boolean =false;
  CanSaveButtonView : boolean =false;
  // End of the permission portion
  //check for duplicate Name
  CheckNameExiststatus : boolean=null;  
  CheckNamestatusedit : boolean =false;
  Namecheck : string ="";
  checkName : any;
  checkNameval : string ="";
  //check for duplicate Name

  constructor(private organizationService: OrganizationService, private rout: Router,
    private LocationService : LocationService, private _confirmation: ConfirmationService,
     private userService: UserService ){}

  @ViewChild(DataTableDirective, {static: false})
  setDtOptions: DataTables.Settings = {};
  cardDtOptions: DataTables.Settings = {};
  dtElement: DataTableDirective;
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();

  ngOnInit() 
  {
    // Begning Permission Portion 
    this.SelectedMenu=this.userService.SelectedMenu;
    if(this.SelectedMenu !=undefined)
    {
      localStorage.setItem('selectedmenu',JSON.stringify(this.SelectedMenu));
      this.rout.navigate([this.SelectedMenu.RouteName]);
    }
    else
    {
      if(JSON.parse(localStorage.getItem('selectedmenu'))!=undefined)
      {
        this.SelectedMenu = JSON.parse(localStorage.getItem('selectedmenu'));
        this.rout.navigate([this.SelectedMenu.RouteName]);
      }
    }
    if(this.SelectedMenu.CanAdd)
    {
      this.IsDataIsInViewState=false;
      this.CanSaveButtonView=true;
    }
    else
    {
      this.IsDataIsInViewState=true;
    }
    if(this.SelectedMenu.CanView)
    {
      this.CanViewState=false;
    }
    else
    {
      this.CanViewState=true;
    }
    // End of the permission portion
    this.DisableSave=true;
    this.FormViewShow=false;
    this.getCountries();
    this.dtOptions = 
    {
      pagingType: 'full_numbers',
      pageLength: 8
    };
    this.CityModel.CountryId=0;
    if(localStorage.getItem('SuccessfullMessage'))
    {
      this.SuccessStatus=true;
      this.SuccessMessage =localStorage.getItem('SuccessfullMessage')
      localStorage.removeItem('SuccessfullMessage');
    }
  }

  settings: ConfirmSettings | any = 
  {
    overlay: true,
    overlayClickToClose: true,
    showCloseButton: true,
    confirmText: 'Yes',
    declineText: 'No'
  };

  ngAfterViewInit(): void 
  {
    this.getCities();
    this.dtOptions = 
    {
      pagingType: 'full_numbers',
      pageLength: 8
    };
  }

  getCities()
  {
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.LocationService.GetCity( t).then(x => 
    {
      this.Cities=[];
      this.city = x;
      Object.keys(this.city).forEach( key => 
      {
        this.city[key];
        let Citytemp = new City();
        Citytemp = this.city[key];
        this.Cities.push(Citytemp);
      });
      this.dtTrigger.next();
    });
  }

  ADDClick()
  {
    this.FormViewShow=true;
    this.CanSaveButtonView=false;
  }

  GetCityByid(id) 
  {
     // begning of the permission portion
     this.IsDataIsInViewState=false;
     // end of the permission portion
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.LocationService.GetCityByid( t,id).then(x => 
    {
      this.FormViewShow=true;
      this.CanSaveButtonView=false;
      this.city = x;
      Object.keys(this.city).forEach( key => 
      {
        this.city[key];
        let Citytemp = new City();
        Citytemp = this.city[key];
        this.CityModel.CityId=Citytemp.CityId;
        this.CityModel.CountryId=Citytemp.CountryId;
        this.CityModel.CityCode=Citytemp.CityCode;
        this.CityModel.CityName=Citytemp.CityName;
        this.CityModel.StateId=Citytemp.StateId; 
        this.CityModel.DistId=Citytemp.DistId; 
      });
      this.getStates(this.CityModel.CountryId);
      this.getDistricts(this.CityModel.StateId);
    });
    // Begin for check Name duplication
    this.checkNameval= this.CityModel.CityName;
    this.CheckNamestatusedit=true;
    this.CheckNameExiststatus =true;
    this.Namecheck=""
    // End for check Name duplication 
  }

  ViewCityByid(id) 
  {
     // begning of the permission portion
     this.IsDataIsInViewState=true;
     // end of the permission portion
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.LocationService.GetCityByid( t,id).then(x => 
    {
      this.FormViewShow=true;
      this.CanSaveButtonView=false;
      this.city = x;
      Object.keys(this.city).forEach( key => 
      {
        this.city[key];
        let Citytemp = new City();
        Citytemp = this.city[key];
        this.CityModel.CityId=Citytemp.CityId;
        this.CityModel.CountryId=Citytemp.CountryId;
        this.CityModel.CityCode=Citytemp.CityCode;
        this.CityModel.CityName=Citytemp.CityName;
        this.CityModel.StateId=Citytemp.StateId; 
        this.CityModel.DistId=Citytemp.DistId;
      });
      this.getStates(this.CityModel.CountryId);
      this.getDistricts(this.CityModel.StateId);
    });
    // Begin for check Name duplication
    this.checkNameval= this.CityModel.CityName;
    this.CheckNamestatusedit=true;
    this.CheckNameExiststatus =true;
    this.Namecheck=""
    // End for check Name duplication 
  }

  saveCity() :void
  {
    this.DisableSave=false;
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.CityModel.IsActive=true;
    if(this.CityModel.CityId > 0)
    {
      this.LocationService.UpdateCity(t ,this.CityModel).subscribe(res=>
      {
        this.CityId=res;
        this.CityModel.CityId=0;  
        if(this.CityId>0)
        {
          localStorage.setItem('SuccessfullMessage', "Update Successfully");
          this.rout.navigateByUrl('/Location/ManageCountry', {skipLocationChange: true}).then(()=>this.rout.navigate(["/Location/ManageCity"]));
        }
      }); 
    }
    else
    {
      this.LocationService.AddCity(t ,this.CityModel).subscribe(res=>
      {
        this.CityId=res;
        this.CityModel.CityId=0;
        if(this.CityId>0)
        {
          localStorage.setItem('SuccessfullMessage', "Save Successfully");
          this.rout.navigateByUrl('/Location/ManageCountry', {skipLocationChange: true}).then(()=>this.rout.navigate(["/Location/ManageCity"]));
        }
     }); 
    }
  } 

  DeleteCityByid(Cityid : number) :void
  {    
    this._confirmation.create('Confirm to Deleate', 'Press Yes to Delete', this.settings)
    .subscribe((ans: ResolveEmit) => {
      if (ans.resolved == true) {

        let t =  localStorage.getItem('userToken');
        t = 'Bearer' + t;
        this.CityModel.IsActive=true;
        if( Cityid > 0)
        {
          this.LocationService.DeleteCity(t ,Cityid).subscribe(res=>
          {
            this.CityId=res;
            this.CityModel.CityId=0;                                  
            if(this.CityId >0)
            {
              localStorage.setItem('SuccessfullMessage', "Deleted Successfully");
              this.rout.navigateByUrl('/Location/ManageCountry', {skipLocationChange: true}).then(()=>this.rout.navigate(["/Location/ManageCity"]));
            }
          });                  
        }
         
      }
    })
  }
  
  getCountries() 
  {
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.organizationService.GetCountry( t).then(x => 
    {
      this.Countries=[];
      this.country = x;
      Object.keys(this.country).forEach( key => 
      {
        this.country[key];
        let Countrytemp = new Country();
        Countrytemp = this.country[key];
        this.Countries.push(Countrytemp);
      });     
    });      
  }

  getStates( countryid) 
  {
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t
    this.organizationService.GetState( t,countryid).then(x => 
    {
      this.States=[];
      this.state = x;
      Object.keys(this.state).forEach( key => 
      {
        this.state[key];
        let Statetemp = new State();
        Statetemp = this.state[key];
        this.States.push(Statetemp);
      });     
    });      
  }

  getDistricts( Stateid) 
  {
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t
    this.LocationService.GetDistrictByStateid( t,Stateid).then(x => 
    {
      this.Districts=[];
      this.District = x;
      Object.keys(this.District).forEach( key => 
      {
        this.District[key];
        let Districttemp = new District();
        Districttemp = this.District[key];
        this.Districts.push(Districttemp);
      });     
    });      
  }

  onChange(countryid) 
  {
    if(countryid!=0)
    {
      this.getStates(countryid);
      this.CityModel.StateId=0;
    }
    else
    {
      this.getStates(countryid);
      this.CityModel.StateId=0;
      this.getDistricts(0);
    }
  }

  onStateChange(Stateid) 
  {
    this.Districts=[];
    if(Stateid!=0)
    {
      this.getDistricts(Stateid);
      this.CityModel.DistId=0;
    }
    else
    {
      this.getDistricts(Stateid);
      this.CityModel.DistId=0;
    }
  }
  
  RefreshPage() 
  {       
    this.rout.navigateByUrl('/Location/ManageCountry', {skipLocationChange: true}).then(()=>this.rout.navigate(["/Location/ManageCity"]));
  }

  // begning of check duplicate Name
  CheckName()
  {
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    if(!this.CheckNamestatusedit)
    {
      this.LocationService.CheckCityName( t, this.CityModel.CityName,this.CityModel.DistId).then(x => 
      {
        this.checkName = x;
        if( this.checkName.length >0)
        {
          this.CheckNameExiststatus =false;
          this.Namecheck="Exist"
        }
        else
        {
          this.CheckNameExiststatus =true;
          this.Namecheck="";
        }
     });
    }
    else
    {
      if(this.CityModel.CityName !=this.checkNameval )
      {
        this.LocationService.CheckCityName( t, this.CityModel.CityName,this.CityModel.DistId).then(x => 
        {
          this.checkName = x;
          if( this.checkName.length >0)
          {
            this.CheckNameExiststatus =false;
            this.Namecheck="Exist"
          }
          else
          {
            this.CheckNameExiststatus =true;
            this.Namecheck="";
          }
        });
      }
      else
      {
        this.CheckNameExiststatus =true;
        this.Namecheck="";
      }
    }
  }
  // end of duplicate Name
}
