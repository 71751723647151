export class Branch 
{
    public BranchId : number;
    public BranchCode : string;
    public BranchName : string;
    public CompId : number;
    public Address1 : string;
    public Address2 : string;
    public CountryId : number;
    public StateId : number;
    public CityId : number;
    public PostCode : number;
    public Website : string;
    public LogoPath : string;
    public PhoneNo1 : number;
    public PhoneNo2 : number;
    public Fax : number;
    public PANNumber : string;
    public Email : string;
    public IsActive : boolean;
    public CompanyManagerId : number;
    public RegistrationNumber : string;
}