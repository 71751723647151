import { Injectable,Inject } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';  
import { Http, Headers, RequestOptions, Response } from '@angular/http';  
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';

import 'rxjs/add/operator/toPromise';
import { Subscriber } from 'rxjs';
import { GlobalVariable  } from '../../../app.global';
import { LeaveType } from   '../Leave/LeaveType.Model';
import { LeaveMapping } from   '../Leave/LeaveMapping.Model';
import { LeaveRule } from   '../Leave/LeaveRule.Model';
import { LeaveMasterModel } from   '../Leave/LeaveMaster.Model';
import { EmployeeLeaveBalanceModel } from   '../EmployeePortal/EmployeeLeaveBalanceModel.Model';


@Injectable()
export class LeaveMasterService
{
  constructor(private http: HttpClient,private spinnerService: Ng4LoadingSpinnerService) 
  {

  }
  // Begning of the Leave Type
  GetLeaveType(token )
  {
      this.spinnerService.show();
      const authHeader = new HttpHeaders();     
      authHeader.append('Content-Type', 'application/json');
      authHeader.append('Authorization', token);
      return new Promise((resolve, reject) =>
      {
          setTimeout(()=>
          {
              this.http.get(GlobalVariable.BASE_API_URL +'/api/LeaveType/GetLeaveType',{headers: authHeader})
              .subscribe(data => 
              {
                  this.spinnerService.hide();
                  resolve(data)
              },
              error => 
              {
                  this.spinnerService.hide();
                  reject(error);
              },);
          },3000);
      });
  }

  GetLeaveTypeById(token, id:number ) 
  {
      this.spinnerService.show();
      const authHeader = new HttpHeaders();     
      authHeader.append('Content-Type', 'application/json');
      authHeader.append('Authorization', token);
      return new Promise((resolve, reject) => 
      {
          setTimeout(()=>{
          this.http.get(GlobalVariable.BASE_API_URL +'/api/LeaveType/GetLeaveTypeById?LeaveTypeId='+id,{headers: authHeader})
          .subscribe(data => 
          {
              this.spinnerService.hide();
              resolve(data)
          },
          error => 
          {
              this.spinnerService.hide();
              reject(error);
          },);
      },3000);
      });
  }

  AddLeaveType( token ,LeaveType :LeaveType) 
  {
      const authHeader = new HttpHeaders();         
      authHeader.append('Content-Type', 'application/json');
      authHeader.append('Authorization', token);
      authHeader.append('Accept', 'application/json');
      return this.http.post(GlobalVariable.BASE_API_URL +'/api/LeaveType/Add', LeaveType ,{headers: authHeader} )
      .map(this.extractData)
      .catch(this.handleErrorObservable);
  }

  DeleteLeaveType( token ,LeaveTypeid : number) 
  {
      const authHeader = new HttpHeaders();         
      authHeader.append('Content-Type', 'application/json');
      authHeader.append('Authorization', token);
      authHeader.append('Accept', 'application/json');
      return this.http.put(GlobalVariable.BASE_API_URL +'/api/LeaveType/Delete', LeaveTypeid ,{headers: authHeader} )
      .map(this.extractData)
      .catch(this.handleErrorObservable);
  }

  UpdateLeaveType( token ,LeaveType :LeaveType) 
  {
      const authHeader = new HttpHeaders();         
      authHeader.append('Content-Type', 'application/json');
      authHeader.append('Authorization', token);
      authHeader.append('Accept', 'application/json');
      return this.http.put(GlobalVariable.BASE_API_URL +'/api/LeaveType/Update', LeaveType ,{headers: authHeader} )
      .map(this.extractData)
      .catch(this.handleErrorObservable);
  }

  CheckLeaveTypeByCode(token, Code:string ) 
  {
      this.spinnerService.show();
      const authHeader = new HttpHeaders();     
      authHeader.append('Content-Type', 'application/json');
      authHeader.append('Authorization', token);
      return new Promise((resolve, reject) => 
      {
          setTimeout(()=>{
          this.http.get(GlobalVariable.BASE_API_URL +'/api/LeaveType/CheckLeaveTypeByCode?LeaveTypeCode='+Code,{headers: authHeader})
          .subscribe(data => 
          {
              this.spinnerService.hide();
              resolve(data)
          },
          error => 
          {
              this.spinnerService.hide();
              reject(error);
          },);
      },3000);
      });
  }
  
  CheckLeaveTypeByName(token, Name:string ) 
  {
      this.spinnerService.show();
      const authHeader = new HttpHeaders();     
      authHeader.append('Content-Type', 'application/json');
      authHeader.append('Authorization', token);
      return new Promise((resolve, reject) => 
      {
          setTimeout(()=>{
          this.http.get(GlobalVariable.BASE_API_URL +'/api/LeaveType/CheckLeaveTypeByName?LeaveTypeName='+Name,{headers: authHeader})
          .subscribe(data => 
          {
              this.spinnerService.hide();
              resolve(data)
          },
          error => 
          {
              this.spinnerService.hide();
              reject(error);
          },);
      },3000);
      });
  }
  // End of the Leave Type
  // Begning of Leave Mapping
  GetLeaveMapping(token )
  { 
      this.spinnerService.show();
      const authHeader = new HttpHeaders();     
      authHeader.append('Content-Type', 'application/json');
      authHeader.append('Authorization', token);
      return new Promise((resolve, reject) =>
      {  
          setTimeout(()=>{
          this.http.get(GlobalVariable.BASE_API_URL +'/api/LeaveMappping/GetLeaveMappping',{headers: authHeader})
          .subscribe(data => 
          {
              this.spinnerService.hide();
              resolve(data)
          },
          error => 
          {
              this.spinnerService.hide();
              reject(error);
          },);
      },3000);
      });
  }
  
  GetLeaveMappingByid(token, LeaveMappingId:number) 
  {
      this.spinnerService.show();
      const authHeader = new HttpHeaders();     
      authHeader.append('Content-Type', 'application/json');
      authHeader.append('Authorization', token);
      return new Promise((resolve, reject) => 
      {
          setTimeout(()=>{
          this.http.get(GlobalVariable.BASE_API_URL +'/api/LeaveMappping/GetLeaveMapppingById?LeaveMappingId='+ LeaveMappingId,{headers: authHeader})
          .subscribe(data => 
          {
              this.spinnerService.hide();
              resolve(data)
          },
          error => 
          {
              this.spinnerService.hide();
              reject(error);
          },);
      },3000);
      });
  }

  AddLeaveMapping( token ,LeaveMapping :LeaveMapping) 
  {
      const authHeader = new HttpHeaders();         
      authHeader.append('Content-Type', 'application/json');
      authHeader.append('Authorization', token);
      authHeader.append('Accept', 'application/json');
      return this.http.post(GlobalVariable.BASE_API_URL +'/api/LeaveMappping/Add', LeaveMapping ,{headers: authHeader} )
      .map(this.extractData)
      .catch(this.handleErrorObservable);
  }
  
  DeleteLeaveMapping( token ,LeaveMappingid : number) 
  {
      const authHeader = new HttpHeaders();         
      authHeader.append('Content-Type', 'application/json');
      authHeader.append('Authorization', token);
      authHeader.append('Accept', 'application/json');
      return this.http.put(GlobalVariable.BASE_API_URL +'/api/LeaveMappping/Delete', LeaveMappingid ,{headers: authHeader} )
      .map(this.extractData)
      .catch(this.handleErrorObservable);
  }
  
  UpdateLeaveMapping( token ,LeaveMapping :LeaveMapping) 
  {
      const authHeader = new HttpHeaders();         
      authHeader.append('Content-Type', 'application/json');
      authHeader.append('Authorization', token);
      authHeader.append('Accept', 'application/json');
      return this.http.put(GlobalVariable.BASE_API_URL +'/api/LeaveMappping/Update', LeaveMapping ,{headers: authHeader} )
      .map(this.extractData)
      .catch(this.handleErrorObservable);
  }
  
  CheckLeaveMappping(token, CompanyId:number, Year :number) 
  {
      this.spinnerService.show();
      const authHeader = new HttpHeaders();     
      authHeader.append('Content-Type', 'application/json');
      authHeader.append('Authorization', token);
      return new Promise((resolve, reject) => 
      {
          setTimeout(()=>{
          this.http.get(GlobalVariable.BASE_API_URL +'/api/LeaveMappping/CheckLeaveMappping?CompanyId='+ CompanyId +'&Year='+Year ,{headers: authHeader})
          .subscribe(data => 
          {
              this.spinnerService.hide();
              resolve(data)
          },
          error => 
          {
              this.spinnerService.hide();
              reject(error);
          },);
      },3000);
      });
  }

  ChecklivemapingIsinUsedOrNot(token, Leavetypeid : Number, CompanyId:Number,finyear:Number) 
  {
      this.spinnerService.show();
      const authHeader = new HttpHeaders();     
      authHeader.append('Content-Type', 'application/json');
      authHeader.append('Authorization', token);
      return new Promise((resolve, reject) => 
      {
          setTimeout(()=>{
    this.http.get(GlobalVariable.BASE_API_URL +'/api/LeaveMappping/GetLeaveMapingCheck?LeaveTypeId='+ Leavetypeid +'&companyid='+ CompanyId +'&finyear='+finyear,{headers: authHeader})
          .subscribe(data => 
          {
              this.spinnerService.hide();
              resolve(data)
          },
          error => 
          {
              this.spinnerService.hide();
              reject(error);
          },);
      },3000);
      });
  }
  // End of the Leave Mapping
  //Start Leave Rule
  GetLeaveRule(token)
  {
    const authHeader = new HttpHeaders();     
    authHeader.append('Content-Type', 'application/json');
    authHeader.append('Authorization', token);
    return new Promise((resolve, reject) =>
    {       
      setTimeout(()=>
      {
        this.http.get(GlobalVariable.BASE_API_URL +'/api/LeaveRule/GetLeaveRule',{headers: authHeader})
        .subscribe(data => 
        {
          resolve(data)
        },
        error => 
        {
          reject(error);
        },);
      },3000);
    });
  }

  GetLeaveRuleById(token, CompanyId:number,  Year:number ) 
  {
    // this.spinnerService.show();
    const authHeader = new HttpHeaders();     
    authHeader.append('Content-Type', 'application/json');
    authHeader.append('Authorization', token);
    return new Promise((resolve, reject) => 
    {
      setTimeout(()=>{
      this.http.get(GlobalVariable.BASE_API_URL +'/api/LeaveRule/GetLeaveRuleById?CompanyId='+ CompanyId +'&Year='+Year,{headers: authHeader})
      .subscribe(data => 
      {
        this.spinnerService.hide();
        resolve(data)
      },
      error => 
      {
        this.spinnerService.hide();
        reject(error);
      },);
    },3000);
    });
  }

  AddLeaveRule( token ,LeaveRule :LeaveRule ) 
  {
    const authHeader = new HttpHeaders();         
    authHeader.append('Content-Type', 'application/json');
    authHeader.append('Authorization', token);
    authHeader.append('Accept', 'application/json');
      return this.http.post(GlobalVariable.BASE_API_URL +'/api/LeaveRule/Add', LeaveRule ,{headers: authHeader} )
    .map(this.extractData)
    .catch(this.handleErrorObservable);
  }

  DeleteLeaveRule( token ,RefId : number) 
  {
    const authHeader = new HttpHeaders();         
    authHeader.append('Content-Type', 'application/json');
    authHeader.append('Authorization', token);
    authHeader.append('Accept', 'application/json');
    return this.http.put(GlobalVariable.BASE_API_URL +'/api/LeaveRule/Delete', RefId ,{headers: authHeader} )
    .map(this.extractData)
    .catch(this.handleErrorObservable);
  }

  UpdateLeaveRule( token ,LeaveRule :LeaveRule ) 
  {
    const authHeader = new HttpHeaders();         
    authHeader.append('Content-Type', 'application/json');
    authHeader.append('Authorization', token);
    authHeader.append('Accept', 'application/json');
    return this.http.put(GlobalVariable.BASE_API_URL +'/api/LeaveRule/Update', LeaveRule ,{headers: authHeader} )
    .map(this.extractData)
    .catch(this.handleErrorObservable);
  }

  UpdateLeaveRulebulk( token ,LeaveRule  : LeaveRule[] ) 
  {
      this.spinnerService.show();
    const authHeader = new HttpHeaders();         
    authHeader.append('Content-Type', 'application/json');
    authHeader.append('Authorization', token);
    authHeader.append('Accept', 'application/json');
    return this.http.put(GlobalVariable.BASE_API_URL +'/api/LeaveRule/Update', LeaveRule ,{headers: authHeader} )
    .map(this.extractData)
    .catch(this.handleErrorObservable);
  }

  EmployeeLeaveBalanceAdjustment(token ,LeaveAdjust :EmployeeLeaveBalanceModel) 
  {
      const authHeader = new HttpHeaders();         
      authHeader.append('Content-Type', 'application/json');
      authHeader.append('Authorization', token);
      authHeader.append('Accept', 'application/json');
      return this.http.put(GlobalVariable.BASE_API_URL +'/api/EmployeeLeaveBalance/UpdateEmployeeLeaveAdjustment', LeaveAdjust ,{headers: authHeader} )
      .map(this.extractData)
      .catch(this.handleErrorObservable);
  }
  
  GetEmployeeLeaveBalanceDetailsForAdjustment(token, LeaveTypeId : number, CompanyId  : number, BranchId  : number, DepartmentId  : number) 
  {
    // this.spinnerService.show();
    const authHeader = new HttpHeaders();     
    authHeader.append('Content-Type', 'application/json');
    authHeader.append('Authorization', token);
    return new Promise((resolve, reject) => 
    {
      setTimeout(()=>{
      this.http.get(GlobalVariable.BASE_API_URL +'/api/EmployeeLeaveBalance/GetEmployeeLeaveBalanceDetailsForAdjustment?LeaveTypeId='+ LeaveTypeId + '&CompanyId=' + CompanyId + '&BranchId= '+ BranchId +'&DepartmentId=' + DepartmentId ,{headers: authHeader})
      .subscribe(data => 
      {
        this.spinnerService.hide();
        resolve(data)
      },
      error => 
      {
        this.spinnerService.hide();
        reject(error);
      },);
    },3000);
    });
  }
  //End Leave Rule
  //begning of the leave master
  AddLeaveaster( token ,Leavemaster :LeaveMasterModel) 
  {
      const authHeader = new HttpHeaders();         
      authHeader.append('Content-Type', 'application/json');
      authHeader.append('Authorization', token);
      authHeader.append('Accept', 'application/json');
      
      return this.http.post(GlobalVariable.BASE_API_URL +'/api/LeaveMaster/Add', Leavemaster ,{headers: authHeader} )
      .map(this.extractData)
      .catch(this.handleErrorObservable);
  }

  UpdateLeaveMaster( token ,Leavemaster  : LeaveMasterModel ) 
  {
    const authHeader = new HttpHeaders();         
    authHeader.append('Content-Type', 'application/json');
    authHeader.append('Authorization', token);
    authHeader.append('Accept', 'application/json');
    return this.http.put(GlobalVariable.BASE_API_URL +'/api/LeaveMaster/Update', Leavemaster ,{headers: authHeader} )
    .map(this.extractData)
    .catch(this.handleErrorObservable);
  }

  GetLeaveMaster(token)
  {
    const authHeader = new HttpHeaders();     
    authHeader.append('Content-Type', 'application/json');
    authHeader.append('Authorization', token);
    return new Promise((resolve, reject) =>
    {       
      setTimeout(()=>
      {
        this.http.get(GlobalVariable.BASE_API_URL +'/api/LeaveMaster/GetLeavemaster',{headers: authHeader})
        .subscribe(data => 
        {
          resolve(data)
        },
        error => 
        {
          reject(error);
        },);
      },3000);
    });
  }
  
  GetLeaveMasterById(token, leaveMasterId : number ) 
  {
    // this.spinnerService.show();
    const authHeader = new HttpHeaders();     
    authHeader.append('Content-Type', 'application/json');
    authHeader.append('Authorization', token);
    return new Promise((resolve, reject) => 
    {
      setTimeout(()=>{
      this.http.get(GlobalVariable.BASE_API_URL +'/api/LeaveMaster/GetLeaveMasterById?leaveMasterId='+ leaveMasterId ,{headers: authHeader})
      .subscribe(data => 
      {
        this.spinnerService.hide();
        resolve(data)
      },
      error => 
      {
        this.spinnerService.hide();
        reject(error);
      },);
    },3000);
    });
  }

  CheckLeaveMasterByCompanyIdAndLeavetype(token, leaveType : Number, companyid : Number ) 
  {
    // this.spinnerService.show();
    const authHeader = new HttpHeaders();     
    authHeader.append('Content-Type', 'application/json');
    authHeader.append('Authorization', token);
    return new Promise((resolve, reject) => 
    {
      setTimeout(()=>{
      this.http.get(GlobalVariable.BASE_API_URL +'/api/LeaveMaster/CheckCompanyLeavetype?Leavetype='+ leaveType + '&Companyid='+companyid ,{headers: authHeader})
      .subscribe(data => 
      {
        this.spinnerService.hide();
        resolve(data)
      },
      error => 
      {
        this.spinnerService.hide();
        reject(error);
      },);
    },3000);
    });
  }
  // end of the leave master

  private extractData(res: Response) 
  {
    let body = res;
    return body || {};
  }
  
  private handleErrorObservable (error: Response | any) 
  {
    console.error(error.message || error);
    return Observable.throw(error.message || error);
  }
}
