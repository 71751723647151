import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs/Subject';
import { DataTableDirective } from 'angular-datatables';
import { UserService } from '../shared/user.service';
import { OrganizationService}  from '../shared/Organization/organization.service';
import { Country } from '../shared/Organization/Country.Model';
import { State } from '../shared/Organization/State.Model';
import { City } from '../shared/HR_ADMIN/Location/City.Model';
import { BankMaster } from '../shared/Organization/BankMaster.Model';
// Begning Confirmation
import { ConfirmationService } from '@jaspero/ng2-confirmations';
import { ResolveEmit } from '../../app/resolve-emit';
import { ConfirmSettings } from '../../app/confirm-settings';
// end of Confirmation
import { Menu } from '../shared/menu.model';

@Component({
  selector: 'app-bank-master',
  templateUrl: './bank-master.component.html',
  styleUrls: ['./bank-master.component.css']
})
export class BankMasterComponent implements OnInit 
{
  BankModel : BankMaster= new BankMaster();
  userClaims: any;
  Banks: BankMaster[] = [];
  Cities : City[]=[];
  Countries : Country[]=[];
  States : State[]=[];
  City : any;
  State : any;
  country : any;
  bank : any;
  Result : string;
  SuccessStatus:boolean=false;
  SuccessMessage: string ="";
  public tableWidget: any;
  BankId : any;
  SubMenues : Menu[]=[];
  Mod : any;
  tempBankadd : BankMaster =new BankMaster();

  // Begning Permission Portion    
  SelectedMenu:Menu;
  IsDataIsInViewState : boolean=false;
  CanViewState : boolean = false;
  DisableSave : boolean=false;
  // End of the permission portion

  constructor(private organizationService: OrganizationService, private rout: Router,
    private _confirmation: ConfirmationService,  private userService: UserService)  { }

  @ViewChild(DataTableDirective, {static: false})
  setDtOptions: DataTables.Settings = {};
  cardDtOptions: DataTables.Settings = {};
  dtElement: DataTableDirective;
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();

  ngOnInit() 
  {
    // Begning Permission Portion 
    this.SubMenues=[];
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    let menuid = localStorage.getItem("menuidstor");
    this.userService.GetSubMenuList( t, menuid).then(x => 
    {
      this.Mod = x;
      Object.keys(this.Mod).forEach( key => 
      {
        // tslint:disable-next-line:no-unused-expression
        this.Mod[key];
        let menutemp = new Menu();
        menutemp = this.Mod[key];
        this.SubMenues.push(menutemp);
      });
      this.userService.SelectedMenu= this.SubMenues[0];
      this.rout.navigate([this.userService.SelectedMenu.RouteName]);
      this.SelectedMenu=this.userService.SelectedMenu;
      if(this.SelectedMenu.CanAdd)
      {
        this.IsDataIsInViewState=false;
      }
      else
      {
        this.IsDataIsInViewState=true;
      }
      if(this.SelectedMenu.CanView)
      {
        this.CanViewState=false;
      }
      else
      {
        this.CanViewState=true;
      }
    });
    // End of the permission portion
    this.DisableSave=true;
    this.getCountries();
    this.dtOptions = 
    {
      pagingType: 'full_numbers',
      pageLength: 5
    };
    this.BankModel.CountryId=0;
    if(localStorage.getItem('SuccessfullMessage'))
    {
      this.SuccessStatus=true;
      this.SuccessMessage =localStorage.getItem('SuccessfullMessage')
      localStorage.removeItem('SuccessfullMessage');
    }
  }

  settings: ConfirmSettings | any = 
  {
    overlay: true,
    overlayClickToClose: true,
    showCloseButton: true,
    confirmText: 'Yes',
    declineText: 'No'
  };

  ngAfterViewInit(): void 
  {
    this.getBankMaster();
    this.dtOptions = 
    {
      pagingType: 'full_numbers',
      pageLength: 5
    };
  }

  getBankMaster()
  {
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.organizationService.GetBankMaster( t).then(x => 
    {
      this.bank = x;
      Object.keys(this.bank).forEach( key => 
      {
        // tslint:disable-next-line:no-unused-expression
        this.bank[key];
        let Banktemp = new BankMaster();
        Banktemp = this.bank[key];
        this.Banks.push(Banktemp);
      });
      this.dtTrigger.next();
    });
  }

  GetBankMasterByid(id) 
  {
     // begning of the permission portion
    this.IsDataIsInViewState=false;
     // end of the permission portion
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.organizationService.GetBankMasterByid( t,id).then(x => 
    {
      this.bank = x;
      Object.keys(this.bank).forEach( key => 
      {
        // tslint:disable-next-line:no-unused-expression
        this.bank[key];
        let Banktemp = new BankMaster();
        Banktemp = this.bank[key];
        this.BankModel.BankId=Banktemp.BankId;
        this.BankModel.BankCode=Banktemp.BankCode;
        this.BankModel.BankName=Banktemp.BankName;
        this.BankModel.BranchName=Banktemp.BranchName;
        this.BankModel.BankAddress=Banktemp.BankAddress;          
        this.BankModel.IFSCCode=Banktemp.IFSCCode;
        this.BankModel.CountryId=Banktemp.CountryId;
        this.BankModel.StateId=Banktemp.StateId;
        this.BankModel.CityId=Banktemp.CityId;
        this.BankModel.PostCode=Banktemp.PostCode;
      });
      this.getStates(this.BankModel.CountryId);
      this.getCities(this.BankModel.StateId); 
    });
  }

  viewBankMasterByid(id) 
  {
    this.IsDataIsInViewState=true;
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.organizationService.GetBankMasterByid( t,id).then(x => 
    {
      this.bank = x;
      Object.keys(this.bank).forEach( key => 
      {
        // tslint:disable-next-line:no-unused-expression
        this.bank[key];
        let Banktemp = new BankMaster();
        Banktemp = this.bank[key];
        this.BankModel.BankId=Banktemp.BankId;
        this.BankModel.BankCode=Banktemp.BankCode;
        this.BankModel.BankName=Banktemp.BankName;
        this.BankModel.BranchName=Banktemp.BranchName;
        this.BankModel.BankAddress=Banktemp.BankAddress;          
        this.BankModel.IFSCCode=Banktemp.IFSCCode;
        this.BankModel.CountryId=Banktemp.CountryId;
        this.BankModel.StateId=Banktemp.StateId;
        this.BankModel.CityId=Banktemp.CityId;
        this.BankModel.PostCode=Banktemp.PostCode;
      });
      this.getStates(this.BankModel.CountryId);
      this.getCities(this.BankModel.StateId); 
    });
  }

  getCountries() 
  {
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.organizationService.GetCountry( t).then(x => 
    {
      this.country = x;
      Object.keys(this.country).forEach( key => 
      {
        // tslint:disable-next-line:no-unused-expression
        this.country[key];
        let Countrytemp = new Country();
        Countrytemp = this.country[key];
        this.Countries.push(Countrytemp);
      });     
    });      
  }

  getStates( countryid) 
  {
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t
    if(this.States.length>0)
    {
      this.States.splice(0, this.States.length);
    }
    this.organizationService.GetState( t,countryid).then(x => 
    {
      this.State = x;
      Object.keys(this.State).forEach( key => 
      {
        // tslint:disable-next-line:no-unused-expression
        this.State[key];
        let Statetemp = new State();
        Statetemp = this.State[key];
        this.States.push(Statetemp);
      });     
    });      
  }

  getCities(stateid) 
  {
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t
    if(this.Cities.length>0)
    {
      this.Cities.splice(0,this.Cities.length )
    }
    this.organizationService.GetCity( t,stateid).then(x => 
    {
      this.City = x;
      Object.keys(this.City).forEach( key => 
      {
          // tslint:disable-next-line:no-unused-expression
        this.City[key];
        let Citytemp = new City();
        Citytemp = this.City[key];
        this.Cities.push(Citytemp);
      });     
    });      
  }

  onChange(countryid) 
  {
    if(countryid!=0)
    {
      this.getStates(countryid);
      this.BankModel.StateId=0;
    }
    else
    {
      this.getStates(countryid);
      this.BankModel.StateId=0;
      this.getCities(0);
    }
  }

  onstatechange(stateid)
  {
    if(stateid!=0)
    {
      this.getCities(stateid) ;
      this.BankModel.CityId=0;
    }
    else
    {
      this.getCities(stateid) ;
      this.BankModel.CityId=0;
    }      
  }

  saveBankMaster() :void
  {
    this.DisableSave=false;
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.BankModel.IsActive=true;
    if(this.BankModel.BankId > 0)
    {
      this.organizationService.UpdateBankMaster(t ,this.BankModel).subscribe(res=>
      {
        this.BankId=res;
        this.BankModel.BankId=0;  
        if(this.BankId>0)
        {
          localStorage.setItem('SuccessfullMessage', "Update Successfully");
          this.rout.navigateByUrl('/Admin/ManageFinancialYear', {skipLocationChange: true}).then(()=>this.rout.navigate(["/Admin/ManageBankMaster"]));
        }
      });                  
    }
    else
    {
      this.organizationService.AddBankMaster(t ,this.BankModel).subscribe(res=>
      {
        this.BankId=res;
        this.BankModel.BankId=0;              
        if(this.BankId>0)
        {
          localStorage.setItem('SuccessfullMessage', "Save Successfully");
          this.rout.navigateByUrl('/Admin/ManageFinancialYear', {skipLocationChange: true}).then(()=>this.rout.navigate(["/Admin/ManageBankMaster"]));
        }
      }); 
    }
  }

  DeleteBankMasterByid(bankid : number) :void
  {    
    this._confirmation.create('Confirm to Deleate', 'Press Yes to Delete', this.settings)
    .subscribe((ans: ResolveEmit) => 
    {
      if (ans.resolved == true) 
      {
        let t =  localStorage.getItem('userToken');
        t = 'Bearer' + t;
        this.BankModel.IsActive=true;
        if( bankid > 0)
        {
          this.organizationService.DeleteBankMaster(t ,bankid).subscribe(res=>
          {
            this.BankId=res;
            this.BankModel.BankId=0;                                  
            if(this.BankId >0)
            {
              localStorage.setItem('SuccessfullMessage', "Deleted Successfully");
              this.rout.navigateByUrl('/Admin/ManageFinancialYear', {skipLocationChange: true}).then(()=>this.rout.navigate(["/Admin/ManageBankMaster"]));
            }
          });                  
        }
      } 
    })
  }

  RefreshPage() 
  {
    this.rout.navigateByUrl('/Admin/ManageFinancialYear', {skipLocationChange: true}).then(()=>this.rout.navigate(["/Admin/ManageBankMaster"]))
  }
}
