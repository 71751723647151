export class Company 
{
    public CompId : number;
    public CompCode : string;
    public CompName : string;
    public Address1 : string;
    public Address2 : string;
    public CountryId : number;
    public StateId : number;
    public CityId : number;
    public PostCode : string;
    public Website : string;
    public LogoPath : string;
    public PhoneNo1 : string;
    public PhoneNo2 : string;
    public Fax : string;
    public Email : string;
    public IsActive : boolean;
    public CompanyManagerId : number;
    public PANNumber : string;
    public TANNumber : string;
    public RegistrationNumber : string;
    public  Islogochange  : boolean;
    public Immage:string;
    public LogoType:string;
    public Logodoctype:string;
    public validfile:boolean;

    public MonthId : number;
    public MonthName : string;
}