import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-manage-fine-type',
  templateUrl: './manage-fine-type.component.html',
  styleUrls: ['./manage-fine-type.component.css']
})
export class ManageFineTypeComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
