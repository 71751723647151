import { Input, Output, EventEmitter, ViewChild, ElementRef, Directive, Renderer } from '@angular/core';
declare var jQuery: any;
@Directive({
    selector: '[myChangableDatepicker]'
})

export class ChangableDatePicker {
    @Input() value = '';
    @Input() minimumDate: boolean = true;
    @Input() onlyDatePicker: boolean = true;
    @Input() mindate: Date;
    @Output() dateChange = new EventEmitter();
    private $el: any;
    constructor(public el: ElementRef, public renderer: Renderer) {
        this.$el = $(el.nativeElement);
    }
    ngOnInit() {

        // if (this.onlyDatePicker === true) {

        //     jQuery(this.el.nativeElement).datepicker({
        //         controlType: 'select'
        //         , oneLine: true,
        //         dateFormat: 'dd/mm/yy',
        //         changeMonth: true,
        //         changeYear: true,
        //         minDate: this.minimumDate === false ? '' : this.mindate,

        //         onSelect: (value) => {
        //             this.value = value;
        //             this.dateChange.next(value);
        //         }
        //     })
        //         .datepicker('setDate', this.value);
        // }
        // else {
        //     jQuery(this.el.nativeElement).datetimepicker({
        //         controlType: 'select',
        //         oneLine: true,
        //         timeFormat: 'hh: mm tt',
        //         dateFormat: 'dd/mm/yy',
        //         changeMonth: true,
        //         changeYear: true,

        //         minDate: this.minimumDate === false ? '' : new Date(),
        //         onSelect: (value) => {
        //             this.value = value;
        //             this.dateChange.next(value);
        //         }
        //     })
        //         .datepicker('setDate', this.value);
        // }
    }


    CahngeDate() {
        this.value = '';


        if (this.onlyDatePicker === true) {

            jQuery(this.el.nativeElement).datepicker({
                controlType: 'select'
                , oneLine: true,
                dateFormat: 'dd/mm/yy',
                changeMonth: true,
                changeYear: true,
                minDate: this.minimumDate === false ? '' : this.mindate,

                onSelect: (value) => {
                    this.value = value;
                    this.dateChange.next(value);
                }
            })
                .datepicker('setDate', this.value);
        }
        else {
            jQuery(this.el.nativeElement).datetimepicker({
                controlType: 'select',
                oneLine: true,
                timeFormat: 'hh: mm tt',
                dateFormat: 'dd/mm/yy',
                changeMonth: true,
                changeYear: true,

                minDate: this.minimumDate === false ? '' : new Date(),
                onSelect: (value) => {

                    this.value = value;
                    this.dateChange.next(value);
                }
            })
                .datepicker('setDate', this.value);
        }
    }


    changedatewithvalue(prevoiusdate: string) {

        this.value = prevoiusdate;

        if (this.onlyDatePicker === true) {

            jQuery(this.el.nativeElement).datepicker({
                controlType: 'select'
                , oneLine: true,
                dateFormat: 'dd/mm/yy',
                changeMonth: true,
                changeYear: true,
                minDate: this.minimumDate === false ? '' : this.mindate,

                onSelect: (value) => {
                    this.value = value;
                    this.dateChange.next(value);
                }
            })
                .datepicker('setDate', this.value);
        }
        else {
            jQuery(this.el.nativeElement).datetimepicker({
                controlType: 'select',
                oneLine: true,
                timeFormat: 'hh: mm tt',
                dateFormat: 'dd/mm/yy',
                changeMonth: true,
                changeYear: true,

                minDate: this.minimumDate === false ? '' : new Date(),
                onSelect: (value) => {
                    this.value = value;
                    this.dateChange.next(value);
                }
            })
                .datepicker('setDate', this.value);
        }

    }


    change(date1: string) {
        this.$el.datepicker("destroy");

        if ((typeof date1 === 'string') && (date1.indexOf('/') > -1)) {
            const str = date1.split('/');

            const year = Number(str[2]);
            const month = Number(str[1]) - 1;
            const date = Number(str[0]);

            this.mindate = new Date(year, month, date);

        } else if ((typeof date1 === 'string') && date1 === '') {
            this.mindate = new Date();

        }
        this.CahngeDate();
    }

    changedatewithPreviousvalue(previousdate1: string, previousdate2: string) {

        this.$el.datepicker("destroy");
        if ((typeof previousdate1 === 'string') && (previousdate1.indexOf('/') > -1)) {
            const str = previousdate1.split('/');

            const year = Number(str[2]);
            const month = Number(str[1]) - 1;
            const date = Number(str[0]);
            this.mindate = new Date(year, month, date);
            this.onlyDatePicker = true;
            this.changedatewithvalue(previousdate2);

        } else if ((typeof previousdate1 === 'string') && previousdate1 === '') {
            this.mindate = new Date();
            this.changedatewithvalue(previousdate2);

        }

    }


    Refreshdate() {


        this.value = '';
        if (this.onlyDatePicker === true) {
            jQuery(this.el.nativeElement).datepicker({
                controlType: 'select'
                , oneLine: true,
                dateFormat: 'dd/mm/yy',
                changeMonth: true,
                changeYear: true,
                minDate: this.minimumDate === false ? '' : new Date(),

                onSelect: (value) => {
                    this.value = value;
                    this.dateChange.next(value);
                }
            })
                .datepicker('setDate', this.value);
        }
        else {
            jQuery(this.el.nativeElement).datetimepicker({
                controlType: 'select',
                oneLine: true,
                timeFormat: 'hh: mm tt',
                dateFormat: 'dd/mm/yy',
                changeMonth: true,
                changeYear: true,

                minDate: this.minimumDate === false ? '' : new Date(),
                onSelect: (value) => {
                    this.value = value;
                    this.dateChange.next(value);
                }
            })
                .datepicker('setDate', this.value);
        }
    }


    refreshDatePicker() {



        this.$el.datepicker("destroy");

        this.Refreshdate();

    }



}