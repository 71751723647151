import {INgxMyDpOptions, IMyDateModel} from 'ngx-mydatepicker';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { EmployeeMasterService }  from '../../../shared/HR_ADMIN/Employee/EmployeeMaster.Service';
import { EmployeeDetail } from '../../../shared/HR_ADMIN/Employee/EmployeeDetail.Model';
import { ModelGander } from '../../../shared/HR_ADMIN/Employee/ModelGander';
import { EmployeeRelationModel } from '../../../shared/HR_ADMIN/Employee/EmployeeRelation.Model';
import { EmployeeFamilyDetailsModel} from '../../../shared/HR_ADMIN/Employee/EmployeeFamilyDetailsModel';
//Begin For Notification service
import { NotificationsService} from '../../../shared/notifications.service';

@Component({
  selector: 'app-employee-family-details',
  templateUrl: './employee-family-details.component.html',
  styleUrls: ['./employee-family-details.component.css']
})
export class EmployeeFamilyDetailsComponent implements OnInit
{
  AddressRefID:number;
  SuccessStatus:boolean=false;
  SuccessMessage: string ="";
  Tabindex:number=0;
  ExpRefId:number =0;
  Formdate :string;
  Todate:string;
  DisableSave : boolean=false;
  myOptions: INgxMyDpOptions = 
  {
    // other options...
    dateFormat: 'dd.mm.yyyy',
  };

  Ganders : ModelGander[]=[];
  Gander : any;
  Relations : EmployeeRelationModel[]=[];
  Realation : any;
  // Initialized to specific date (09.10.2018)
  model: any = { date: { year: 2018, month: 10, day: 9 } };

  constructor(public EmployeeMasterService: EmployeeMasterService, private rout: Router, private notificationsService: NotificationsService) {   }

  // optional date changed callback
  
  ngOnInit() 
  {
    this.DisableSave=true;
    this.getGenders();
    this.getRelation();
    if(this.EmployeeMasterService.SharedEmployees.EmployeeFamilyDetailsModel==null)
    {
      let EmployeeFamilyList : EmployeeFamilyDetailsModel[]=[];
      for( var i=0; i<4; i++ )
      {
        let tempfamily = new EmployeeFamilyDetailsModel(); 
        tempfamily.FamilyRelationId=0; 
        tempfamily.FamilyGenderId=0;  
        EmployeeFamilyList.push(tempfamily);         
      }
      this.EmployeeMasterService.SharedEmployees.EmployeeFamilyDetailsModel=EmployeeFamilyList;
    }
    if(this.EmployeeMasterService.SharedEmployees.EmployeeId > 0)
    {
      if(this.EmployeeMasterService.SharedEmployees.EmployeeFamilyDetailsModel.length==0)
      {
        let EmployeeFamilyList : EmployeeFamilyDetailsModel[]=[];
        for( var i=0; i<4; i++ )
        {
          let tempfamily = new EmployeeFamilyDetailsModel(); 
          tempfamily.FamilyRelationId=0; 
          tempfamily.FamilyGenderId=0;  
          EmployeeFamilyList.push(tempfamily);         
        }
        this.EmployeeMasterService.SharedEmployees.EmployeeFamilyDetailsModel=EmployeeFamilyList;
      }
      else
      {
        this.changetdateformat();
      }
    }
    if( this.EmployeeMasterService.SaveOrUpdate==1)
    {
      localStorage.setItem('SuccessfullMessage', "Save Successfully");
      this.EmployeeMasterService.SaveOrUpdate=0;
    }
    if( this.EmployeeMasterService.SaveOrUpdate==2)
    {
      localStorage.setItem('SuccessfullMessage', "Update Successfully");
      this.EmployeeMasterService.SaveOrUpdate=0;
    }
    if(localStorage.getItem('SuccessfullMessage'))
    {
      this.SuccessStatus=true;
      this.SuccessMessage =localStorage.getItem('SuccessfullMessage')
      localStorage.removeItem('SuccessfullMessage');
    } 
  }

  changetdateformat()
  {
    let objemp = new EmployeeDetail();
    this.EmployeeMasterService.SharedEmployees.EmployeeFamilyDetailsModel.forEach(temp=>
    {
      temp.FamilyDOB=objemp.ReturnFormatdate(temp.FamilyDOBPass)
    })
  }

  AddnweRow()
  {
    let tempf = new EmployeeFamilyDetailsModel();  
    tempf.FamilyRelationId=0; 
    tempf.FamilyGenderId=0;          
    this.EmployeeMasterService.SharedEmployees.EmployeeFamilyDetailsModel.push(tempf);     
  }  
  
  SaveEmpolyeeFamilydetails() :void
  {
    this.DisableSave=false;
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;    
    var j=0;
    var l=this.EmployeeMasterService.SharedEmployees.EmployeeFamilyDetailsModel.length;
    this.EmployeeMasterService.SharedEmployees.EmployeeFamilyDetailsModel.forEach(item => 
    {
      j=j+1;
      if(item.FamilyRelationId >0)
      {      
        item.EmployeeId= this.EmployeeMasterService.SharedEmployees.EmployeeId;      
        //let toArray =  tempedumodel.FromDate.getDay();
        if(item.FamilyRefId==null)
        {
          item.FamilyRefId=0;
        }
        if(item.FamilyRefId> 0)
        {
          this.EmployeeMasterService.UpdateEmployeeFamilyDetails(t ,item).subscribe(res=>
          {
            this.ExpRefId=Number(res);        
            if(this.ExpRefId>0)
            {
              this.EmployeeMasterService.SaveOrUpdate=2;
              this.Tabindex=6         
              this.notificationsService.emit(this.Tabindex); 
              if(j==l)
              {
                this.rout.navigateByUrl('/Employee/EmployeeDetails/NomineeDetails'); 
              }        
            }
          });                  
        }                             
        else
        {
          this.EmployeeMasterService.AddEmployeeFamilyDetails(t ,item).subscribe(res=>
          {
            this.ExpRefId=Number(res);         
            if(this.ExpRefId>0)
            {
              this.EmployeeMasterService.SaveOrUpdate=1;          
              item.FamilyRefId=Number(this.ExpRefId); 
              this.Tabindex=6         
              this.notificationsService.emit(this.Tabindex);
              if(j==l)
              {
                this.rout.navigateByUrl('/Employee/EmployeeDetails/NomineeDetails'); 
              }        
            }
          }); 
        }
      }
    });   
  }

  OnChangeDOBF(event: IMyDateModel,i): void
  {
    const monthNames = [
      'January', 'February', 'March',
      'April', 'May', 'June', 'July',
      'August', 'September', 'October',
      'November', 'December'
      ];
    var m= event.jsdate.getMonth();
    m=m+1;
    var d= event.jsdate.getDate();
    var y= event.jsdate.getFullYear();
    var fulldate="";
    if(m>=10)
    {
      if(d < 10)
      {
        fulldate= m + '/' + '0'+ d + '/' + y;
      }
      else
      {
        fulldate= m + '/' + d + '/' + y; 
      }
    }
    else
    {
      if(d < 10)
      {
        fulldate= '0' + m + '/' + '0'+ d + '/' + y;
      }
      else
      {
        fulldate= '0' + m + '/' + d + '/' + y; 
      } 
    }
    this.EmployeeMasterService.SharedEmployees.EmployeeFamilyDetailsModel[i].FamilyDOBPass=fulldate;
  }

  getGenders() 
  {
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.EmployeeMasterService.GetGenderForDDL( t).then(x => 
    {
      this.Gander = x;
      Object.keys(this.Gander).forEach( key => 
      {
        let Gendertemp = new ModelGander();
        Gendertemp = this.Gander[key];
        this.Ganders.push(Gendertemp);
      });     
    });      
  }

  getRelation() 
  {
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.EmployeeMasterService.GetRelation( t).then(x => 
    {
      this.Realation = x;
      Object.keys(this.Realation).forEach( key => 
      {
        let Relationtemp = new EmployeeRelationModel();
        Relationtemp = this.Realation[key];
        this.Relations.push(Relationtemp);
      });     
    });      
  }
  
  RefreshPage()
  {
    this.rout.navigateByUrl('/Employee/EmployeeOffer', {skipLocationChange: true}).then(()=>this.rout.navigate(["/Employee/EmployeeDetails"]));
  }
}