<div class="body-content-area tabcontent" id="link1">
  <div class="row">
    <div class="col-lg-9">
      <div class="row">
        <div class="col-lg-1 low-padding text-center">
          <!-- <img *ngIf="this.SelectedMenu.Image!=null" src={{this.SelectedMenu.Image}} class="body-icon" alt="Icon"/> -->
        </div>
        <div class="col-lg-10 clear-padding">
          <span class="page-title">P-Tax Slab</span>
          <small class="page-description"></small>
        </div>
      </div>
    </div>
  </div>
  
  <div *ngIf="!(this.CanViewState)">
    <div>
      <app-dialog [(visible)]="this.SuccessStatus">
        <span class="successmsg">{{this.SuccessMessage}}</span>
      </app-dialog>
    </div>

    <div class="row">
      <div class="col-lg-4">
        <div class="col-body-border-right">
          <div class="form-group row">
            <label  class="col-sm-4 col-form-label clear-padding-right">State</label>
            <div class="col-sm-7 clear-left-padding">
              <select [(ngModel)]="PTAXModel.StateId" tabindex="8" (ngModelChange)="onstatechange($event)"  id ="StateId" class="form-control" name="StateId" #StateId="ngModel" >
                <option [value]="0" [disabled]=true >Select State</option>
                <option *ngFor="let s of States" [value]="s.StateID">{{s.StateName}}</option>
              </select>
            </div>
            <div class="required-validation" *ngIf="PTAXModel.StateId==0">State is required</div>
          </div>
        </div>
      </div>
    </div>
      
    <fieldset class="body-form-control" *ngIf="(this.IsFormViewShow)">
      <legend class="legend--">P-Tax Slab</legend>
      <form #PtaxSlabForm="ngForm" (ngSubmit)="SavePTAXSlab()">
        <div lass="row" >
          <table id="tblPtaxSlab" class="table table-striped table-bordered text-left table-new row-border hover">
              <thead class="thead-blue text-center">
                  <tr>
                    <th style="width: 15%;">From Amount </th>
                    <th style="width: 15%;">To Amount</th>
                    <th style="width: 20%;">Tax Amount</th>
                  </tr>
              </thead>
              <tbody>
                <tr *ngFor="let ptax of PTAXes, let i = index">
                <td>
                    <input type="text" [(ngModel)]="ptax.FromAmount" id ="FromAmount_{{i}}" name="FromAmount_{{i}}" #FromAmount="ngModel" class="form-control form-control-custom form-control-custom-new"  >
                </td>
                <td>
                  <input type="text" [(ngModel)]="ptax.ToAmount" id ="ToAmount_{{i}}" name="ToAmount_{{i}}" #ToAmount="ngModel" class="form-control form-control-custom form-control-custom-new">
                </td>
                <td>
                  <input type="text" [(ngModel)]="ptax.TaxAmount" id ="TaxAmount_{{i}}" name="TaxAmount_{{i}}" #TaxAmount="ngModel" class="form-control form-control-custom form-control-custom-new">
                </td>
              </tr>
            </tbody>
          </table>
          
          <div class="row float-right">
            <div class="form-group row">
              <label>Applicable Date :</label>
              <div class="col-sm-7 clear-left-padding">
                <input type="date" name="ApplicableDate" tabindex="1" [ngModel]="PTAXModel.ApplicableDate | date:'yyyy-MM-dd'" (ngModelChange)="dateChanged($event)" #ApplicableDate="ngModel" required />
                <label *ngIf="(!validdate)" class="required-validation">Select Valid Date</label>
              </div>
            </div>
            <div  class=" col-auto text-center">
              <button type="submit" tabindex="22" class="btn btn-primary save-button" [disabled]="!(PtaxSlabForm.valid && this.validdate==true && this.DisableSave==true)" >Save</button>  
            </div>
            <div class=" col-auto text-center">
              <button type="button" tabindex="23" class="btn btn-danger cancle-button" (click)="RefreshPage()">Cancel</button>
            </div>
            <div class=" col-auto text-center">
              <button type="button" tabindex="23" class="btn btn-danger cancle-button" (click)="AddnweRow()">Add Row </button>
            </div>
          </div>
        </div>
      </form>
      <br>
    </fieldset>
  </div>
</div>
