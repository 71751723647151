<div class="body-content-area tabcontent" id="link1">
  <div class="row">
    <div class="col-lg-9">
      <div class="row">
        <div class="col-lg-1 low-padding text-center">
          <!-- <img *ngIf="this.SelectedMenu.Image!=null" src={{this.SelectedMenu.Image}} class="body-icon" alt="Icon"/> -->
        </div>
        <div class="col-lg-10 clear-padding">
          <span class="page-title">HR Policies & Forms</span>
          <small class="page-description"></small>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="!(this.CanViewState)">
    <div style=" text-align: right;">
      <button (click)="ADDClick(template)" type="button" class="btn btn-primary Add-button">Add New</button> 
    </div>

    <fieldset class="body-form-control">
      <legend class="legend--">Policies & Forms</legend>
      <table id="tblEventMessages" class="table table-striped table-bordered text-center table-new row-border hover">
        <thead class="thead-blue text-center">
          <tr>
            <th style=" width: 10%;">Sl No.</th>
            <th style=" width: 50%;">Policies & Forms Name</th>
            <th style=" width: 30%;">File Name</th>
            <th style=" width: 10%;">Action</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let HRPF of HRPolicies, let i = index">
            <td scope="row">{{i+1}}</td>
            <td class="text-left">{{HRPF.PolicyName}}</td>
              <td class="text-left">{{HRPF.FileName}}</td> 
            <td>
              <button *ngIf="this.SelectedMenu.CanEdit" type="button" (click)="GetHRPoliciesFormByRefId(HRPF.RefId,template)" class="table-edit-btn"><i class="fas fa-edit"></i></button> 
              <button *ngIf="this.SelectedMenu.CanDelete" type="button" (click)="DeleteHRPoliciesFormById(HRPF.RefId,template)" class="table-remove-btn"><i class="fas fa-trash-alt"></i></button>
              <button *ngIf="this.SelectedMenu.CanView" type="button" (click)="DownloadHRPoliciesForm(HRPF.FileName)" class="table-view-button" type="button"><i class="fas fa-eye"></i></button>
            </td>
          </tr>
        </tbody>
        <tfoot class="thead-blue text-center">
          <tr>
            <th></th>
            <th></th>
            <th></th>
            <th></th>
          </tr>
        </tfoot>
      </table>
    </fieldset>
  
    <div style=" width: 1500px;">
      <ng-template #template>
        <div class="modal-header" style="background: #2a9bd7; max-width: 1500px;"> 
          <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()" style="color:#fff;">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div style="text-align: center; max-width: 1500px;">
          <div *ngIf="SaveMsg" style="color: #dc3545; text-align: center;font-size: 15px; font-weight: bold;">Save Successfully</div>
          <div *ngIf="UpdateMsg" style="color: #dc3545; text-align: center;font-size: 15px; font-weight: bold;">Updated Successfully</div>
          <div *ngIf="DeleteMsg" style="color: #dc3545; text-align: center;font-size: 15px; font-weight: bold;">Deleted Successfully</div>
          <div style=" width: 100%;" *ngIf="ShowPopup">
            <fieldset class="body-form-control">
              <legend class="legend--">HR Policies & Forms</legend>
              <form #HRPoliciesFormForm="ngForm" (ngSubmit)="SaveHRPoliciesForm(template)">
                <div class="row">
                  <div class="col-lg-4">
                    <div class="col-body-border-right">
                      <div class="form-group row">
                        <label class="col-sm-4 col-form-label clear-padding-right">Policies Name</label>
                        <div class="col-sm-7 clear-left-padding">
                          <input type="text" [(ngModel)]="this.HRPoliciesFormModel.PolicyName" minlength="5" id ="PolicyName" name="PolicyName" #PolicyName="ngModel" required class="form-control form-control-custom form-control-custom-new">
                        </div>
                        <div *ngIf="PolicyName.invalid && (PolicyName.dirty || PolicyName.touched)" class="alert alert-danger">
                          <div *ngIf="PolicyName.errors.required">Message Or Event Required.</div>
                          <div *ngIf="PolicyName.errors.minlength">Minimum 5 Characters</div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="col-lg-4">
                    <div class="col-body-border-right">
                      <div class="form-group row">
                        <label class="col-sm-4 col-form-label clear-padding-right">Files</label>
                        <div class="col-sm-7 clear-left-padding">{{HRPoliciesFormModel.FileName}}
                          <input type="file" name="FileUpload" (change)="FileUploaded($event)" class="form-control" style="height:100%" required>
                        </div>
                      </div>
                      <!-- <div class="form-group row">
                        <label class="col-sm-4 col-form-label clear-padding-right">From Date</label>
                        <div class="col-sm-7 clear-left-padding">
                          <input type="date" name="FromDate" [ngModel]="this.HRPoliciesFormModel.FromDate | date:'yyyy-MM-dd'" (ngModelChange)="FromDateChanged($event)" #FromDate="ngModel" required/>
                        </div>
                      </div> -->
                    </div>
                  </div>

                  <div class="col-lg-4">
                    <!-- <div class="form-group row">
                      <label class="col-sm-4 col-form-label clear-padding-right">To Date</label>
                      <div class="col-sm-7 clear-left-padding">
                        <input type="date" name="ToDate" [ngModel]="this.HRPoliciesFormModel.ToDate | date:'yyyy-MM-dd'" (ngModelChange)="ToDateChanged($event)" #ToDate="ngModel" required/>
                      </div>
                    </div> -->
                    <div class="form-group row">
                      <label class="col-sm-4 col-form-label clear-padding-right">&nbsp;</label>
                      <div class="col-sm-7 clear-left-padding">
                        <button type="submit" class="btn btn-primary save-button" [disabled]="!(HRPoliciesFormForm.valid && this.DisableSave==true)">Save</button>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </fieldset>
          </div>
          <button type="button" aria-label="Close" (click)="modalRef.hide()" style="background: #2a9bd7; outline: 0px none; border: 0px none; color: #fff; padding: 5px 10px; border-radius: 3px;">
            <span>Close</span>
          </button>             
        </div>
      </ng-template>
    </div>
  </div>
</div>