<div class="body-content-area tabcontent" id="link1">
  <div class="row">
    <div class="col-lg-9">
      <div class="row">
        <div class="col-lg-1 low-padding text-center">
          <!-- <img *ngIf="this.SelectedMenu.Image!=null" src={{this.SelectedMenu.Image}} class="body-icon" alt="Icon"/> -->
        </div>
        <div class="col-lg-10 clear-padding">
          <div>
            <span class="page-title">Exit Application</span>
            <small class="page-description"></small>
          </div>
        </div>
      </div>
    </div>
  </div>

  <fieldset class="body-form-control" > 
    <div>
      <app-dialog [(visible)]="this.SuccessStatus">
        <span class="successmsg">{{this.SuccessMessage}}</span>
      </app-dialog>
    </div>
    <legend class="legend--">Exit Application</legend>
    <form #ExitApplication="ngForm" (ngSubmit)="SaveResignApplication()">
      <div class="row"> 
        <div class="col-lg-4">
          <div class="col-body-border-right">
            <div class="form-group row">
              <label  class="col-sm-4 col-form-label clear-padding-right">Id</label>
              <div class="col-sm-7 clear-left-padding">
                :&nbsp;&nbsp;{{this.ResignApplicationModel.EmployeeCode}}
              </div>
            </div>
            <div class="form-group row">
              <label  class="col-sm-4 col-form-label clear-padding-right">Joining Date</label>
              <div class="col-sm-7 clear-left-padding">
                :&nbsp;&nbsp;{{this.ResignApplicationModel.JoiningDate | date:'dd-MMM-yyyy'}}
              </div>
            </div>
            <div class="form-group row">
              <label  class="col-sm-4 col-form-label clear-padding-right">Application Date</label>
              <div class="col-sm-7 clear-left-padding">
                :&nbsp;&nbsp;<input type="date" readonly="readonly" style="width:90%;" name="ApplicationDate" [ngModel]="this.ResignApplicationModel.ApplicationDate | date:'yyyy-MM-dd'" #FromDate="ngModel" required/>
              </div>
            </div>
            <div class="form-group row">
              <label class="col-sm-4 col-form-label clear-padding-right">Reasons</label>
              <div class="col-sm-7 clear-left-padding">
                <textarea type="text" [(ngModel)]="this.ResignApplicationModel.ResignReasons" minlength="10" maxlength="500" id="ResignReasons" name="ResignReasons" #ResignReasons="ngModel" required class="md-textarea form-control" mdbInput></textarea>
              </div>
              <div *ngIf="ResignReasons.invalid && (ResignReasons.dirty || ResignReasons.touched)" class="alert alert-danger">
                <div *ngIf="ResignReasons.errors.required">Reason Required.</div>
                <div *ngIf="ResignReasons.errors.minlength">Minimum 10 Characters</div>
              </div>
            </div>        
          </div>  
        </div>

        <div class="col-lg-4">
          <div class="col-body-border-right">
            <div class="form-group row">
              <label  class="col-sm-4 col-form-label clear-padding-right">Name</label>
              <div class="col-sm-7 clear-left-padding">
                :&nbsp;&nbsp;{{this.ResignApplicationModel.EmployeeName}}
              </div>
            </div>
            <div class="form-group row">
              <label  class="col-sm-4 col-form-label clear-padding-right">Notice Days</label>
              <div class="col-sm-7 clear-left-padding">
                :&nbsp;&nbsp;{{this.ResignApplicationModel.NoticePeriod}}
              </div>
            </div>
            <div class="form-group row">
              <label  class="col-sm-4 col-form-label clear-padding-right">Exit Date</label>
              <div class="col-sm-7 clear-left-padding">
                :&nbsp;&nbsp;<input type="date" style="width:90%;" name="ResignNoticeDate" [ngModel]="this.ResignApplicationModel.ResignNoticeDate | date:'yyyy-MM-dd'" #ResignNoticeDate="ngModel" (ngModelChange)="ResignNoticeDateChanged($event)" required/>
              </div>
              <div class="required-validation" *ngIf="validExitdate">Exit Date is not Valid Date.</div>
            </div>
            <div class="form-group row">
              <label  class="col-sm-4 col-form-label clear-padding-right">Email Id</label>
              <div class="col-sm-7 clear-left-padding">
                  <input type="text" [(ngModel)]="ResignApplicationModel.PersonalEmailId" id ="PersonalEmailId" name="PersonalEmailId" #EmailId="ngModel" pattern="^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$" class="form-control form-control-custom form-control-custom-new" required>
              </div>
              <div *ngIf="EmailId.invalid && (EmailId.dirty || EmailId.touched)" class="required-validation">
                  <div *ngIf="EmailId.errors.required">Email is required.</div>
                  <div *ngIf="EmailId.errors?.pattern">Email should be valid.</div>
              </div>
            </div>
          </div> 
        </div>

        <div class="col-lg-4">
          <div class="form-group row">
            <label  class="col-sm-4 col-form-label clear-padding-right">Reporting Head</label>
            <div class="col-sm-7 clear-left-padding">
              :&nbsp;&nbsp;{{this.ResignApplicationModel.RHName}} 
            </div>
          </div>
          <div class="form-group row">
            <label class="col-sm-4 col-form-label clear-padding-right">Functional Head</label>
            <div class="col-sm-7 clear-left-padding">
              :&nbsp;&nbsp;{{this.ResignApplicationModel.FHName}} 
            </div>
          </div>
          <div class="form-group row">
            <label class="col-sm-4 col-form-label clear-padding-right">HR Name</label>
            <div class="col-sm-7 clear-left-padding">
              :&nbsp;&nbsp;{{this.ResignApplicationModel.HRNames}} 
            </div>
          </div>
          <div class="form-group row">
            <label class="col-sm-6 col-form-label text-center clear-padding-right">
            <button type="submit" class="btn btn-primary save-button" [disabled]="!(ExitApplication.valid && this.DisableSave== true && this.validExitdate == false && this.ResignApplicationModel.ApplicationExist== false)">Save</button>  
            </label>
            <div class="col-sm-6 col-form-label text-center">
              <button type="button" class="btn btn-danger cancle-button" (click)="RefreshPage()">Cancel</button>
            </div>
            <div class="required-validation" *ngIf="this.ResignApplicationModel.ApplicationExist">Application Already Applied.</div>
          </div>
          <div class="form-group row">

          </div>
        </div> 
      </div> 
    </form>
  </fieldset>
</div>
