<div class="body-content-area tabcontent" id="link1">
  <div class="row">
    <div class="col-lg-9">
      <div class="row">
        <div class="col-lg-1 low-padding text-center">
          <!-- <img *ngIf="this.SelectedMenu.Image!=null" src={{this.SelectedMenu.Image}} class="body-icon" alt="Icon"/> -->
        </div>
        <div class="col-lg-10 clear-padding">
          <span class="page-title">Manage Expense Head Mapping </span>
          <small class="page-description"></small>
        </div>
      </div>
    </div>
  </div>
  
  <div *ngIf="!(this.CanViewState)">
    <div style=" text-align: right;">
      <button (click)="ADDClick(template)" type="button" class="btn btn-primary Add-button">Add New</button> 
    </div>
    <fieldset class="body-form-control">
      <legend class="legend--">Expense Head Mapping With Grade</legend>
      <table id="tblExpenseHead" class="table table-striped table-bordered text-center table-new row-border hover">
        <thead class="thead-blue text-center">
          <tr>
            <th>Sl No.</th>
            <th>Decision</th>
            <th>Level</th>
            <th>Grade</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let EH of ExpenseHeadMappings, let i = index">
            <td scope="row">{{i+1}}</td>
            <td class="text-left">{{EH.DecisionName}}</td>
            <td class="text-left">{{EH.LevelName}}</td>
            <td class="text-left">{{EH.GradeName}}</td>
            <td>
              <button *ngIf="this.SelectedMenu.CanEdit" type="button" (click)="GetExpenseHeadMappingById(EH.DecisionId,EH.LevelId,EH.GradeId,template)" class="table-edit-btn"><i class="fas fa-edit"></i> </button> 
              <button *ngIf="this.SelectedMenu.CanView" type="button" (click)="ViewExpenseHeadMappingById(EH.DecisionId,EH.LevelId,EH.GradeId,template)" class="table-view-button" type="button" ><i class="fas fa-eye"></i></button>
            </td>
          </tr>
        </tbody>
        <tfoot class="thead-blue text-center">
          <tr>
            <th></th>
            <th></th>
            <th></th>
            <th></th>
            <th></th>
          </tr>
        </tfoot>
      </table>
    </fieldset>
  
    <div style=" width: 1500px;">
      <ng-template #template>
        <div class="modal-header" style="background: #2a9bd7; max-width: 1500px;"> 
          <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()" style="color:#fff;">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div  style="text-align: center; max-width: 1500px;">
          <div *ngIf="SaveMsg" style="color: #dc3545; text-align: center;font-size: 15px; font-weight: bold;">Save Successfully</div>
          <!-- <div *ngIf="UpdateMsg" style="color: #dc3545; text-align: center;font-size: 15px; font-weight: bold;">Update Successfully</div>
          <div *ngIf="DeleteMsg" style="color: #dc3545; text-align: center;font-size: 15px; font-weight: bold;">Deleted Successfully</div> -->
          <div style=" width: 100%;" *ngIf="ShowPopup">
            <fieldset class="body-form-control">
              <legend class="legend--">Expense Head  Mapping</legend>
              <form #ExpenseHeadForm="ngForm" (ngSubmit)="SaveExpenseHeadMapping(template)">
                <div class="row">
                  <div class="col-lg-4">
                    <div class="col-body-border-right">
                      <div class="form-group row" *ngIf="IsDDViewState == true">
                        <label  class="col-sm-4 col-form-label clear-padding-right">Decision Making</label>
                        <div class="col-sm-7 clear-left-padding">
                          <select  (ngModelChange)="onDecisionChange($event)" [(ngModel)]="ExpenseHeadMappingModel.DecisionId" tabindex="3" id ="DecisionId" class="form-control"  name="DecisionId" #DecisionId="ngModel" >
                            <option [value]="0" [disabled]=true >Select Decision Making</option>
                            <option *ngFor="let DM of Decisions" [value]="DM.DecisionId">{{DM.DecisionName}}</option>
                          </select>
                        </div>
                        <div class="required-validation"  *ngIf="ExpenseHeadMappingModel.DecisionId==0">Decision Making is required</div>
                      </div>
                      <div class="form-group row" *ngIf="IsDDViewState == false">
                        <label  class="col-sm-4 col-form-label clear-padding-right">Decision Making</label>
                        <div class="col-sm-7 clear-left-padding">
                          :&nbsp;&nbsp;{{this.ExpenseHeadMappingModel.DecisionName}}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-4">
                    <div class="form-group row" *ngIf="IsDDViewState == true">
                      <label  class="col-sm-4 col-form-label clear-padding-right">Level</label>
                      <div class="col-sm-7 clear-left-padding">
                        <select (ngModelChange)="onLevelChange($event)" [(ngModel)]="ExpenseHeadMappingModel.LevelId" tabindex="4" id ="LevelId" class="form-control"  name="LevelId" #LevelId="ngModel" >
                          <option [value]="0" [disabled]=true >Select Level</option>
                          <option *ngFor="let L of Levels" [value]="L.LevelId">{{L.LevelName}}</option>
                        </select>
                      </div>
                      <div class="required-validation" *ngIf="ExpenseHeadMappingModel.LevelId==0">Level is required</div>  
                    </div>
                    <div class="form-group row" *ngIf="IsDDViewState == false">
                      <label class="col-sm-4 col-form-label clear-padding-right">Level</label>
                      <div class="col-sm-7 clear-left-padding">
                        :&nbsp;&nbsp;{{this.ExpenseHeadMappingModel.LevelName}}
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-4">
                    <div class="form-group row" *ngIf="IsDDViewState == true">
                      <label  class="col-sm-4 col-form-label clear-padding-right">Grade</label>
                      <div class="col-sm-7 clear-left-padding">
                        <select (ngModelChange)="onGradeChange($event)" [(ngModel)]="ExpenseHeadMappingModel.GradeId" tabindex="5" id ="GradeId" class="form-control"  name="GradeId" #GradeId="ngModel" >
                          <!-- (blur)="CheckName()" -->
                          <option [value]="0" [disabled]=true >Select Grade</option>
                          <option *ngFor="let G of Grades" [value]="G.GradeId">{{G.GradeName}}</option>
                        </select>
                      </div>
                      <div class="required-validation" *ngIf="ExpenseHeadMappingModel.GradeId==0">Grade is required</div>    
                    </div>
                    <div class="form-group row" *ngIf="IsDDViewState == false">
                      <label class="col-sm-4 col-form-label clear-padding-right">Grade</label>
                      <div class="col-sm-7 clear-left-padding">
                        :&nbsp;&nbsp;{{this.ExpenseHeadMappingModel.GradeName}}
                      </div>
                    </div>
                  </div>
                </div>
                <div lass="row" >
                  <table id="tblExpense" class="table table-striped table-bordered text-left table-new row-border hover" style="width: 100%;">
                    <thead class="thead-blue text-center"s tyle="width: 100%;">
                      <tr>
                        <th style=" text-align: center;">Expense Head</th>
                        <th style=" text-align: left;">Budget Amount</th> 
                        <th style=" text-align: left;">Day Wise</th>
                        <th style=" text-align: left;">Over All</th> 
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let TEH of ExpenseHeadAdd, let i = index">
                        <td>
                          {{TEH.ExpenseHeadName}}
                          <input type="text" hidden="hidden" [(ngModel)]="TEH.ExpenseHeadId" name="ExpenseHeadId_{{i}}" #ExpenseHeadId="ngModel">
                        </td>
                        <td>
                          <input type="text" [(ngModel)]="TEH.BudgetAmount" name="BudgetAmount_{{i}}" #BudgetAmount="ngModel" pattern="[0-9]+" class="form-control form-control-custom form-control-custom-new" required>
                          <!-- (ngModelChange)="BudgetAmountChanged()" -->
                          <div *ngIf="BudgetAmount.invalid && (BudgetAmount.dirty || BudgetAmount.touched)" class="alert alert-danger">
                            <div *ngIf="BudgetAmount.errors?.pattern">Amount should be valid.</div>
                          </div>
                        </td>
                        <td>
                          <input type="radio" [checked]="ExpenseHeadAdd[i].value=='DayWise'" (click)="OnChangeDayWise($event,i)" id ="DayWise_{{i}}" name="DayWise_{{i}}" class="form-control form-control-custom form-control-custom-new">
                          <!-- [value]="DayWise" -->
                        </td>
                        <td>
                            <input type="radio" [checked]="ExpenseHeadAdd[i].value=='OverAll'" (click)="OnChangeOverAll($event,i)" id ="OverAll_{{i}}" name="OverAll_{{i}}" class="form-control form-control-custom form-control-custom-new">
                            <!-- [value]="OverAll" -->
                        </td>
                      </tr>
                    </tbody>
                    <tfoot class="thead-blue text-center">
                        <tr>
                          <th style="width: 50%; text-align: right;"></th>
                          <th style="width: 20%; text-align: left;"></th>  
                          <th style="width: 15%; text-align: center;"></th>
                          <th style="width: 15%; text-align: center;"></th>
                        </tr>
                      </tfoot>
                  </table>
                </div>
                <div class="row">
                  <div class="col-lg-4">
                    <div class="col-body-border-right">
                      <div class="form-group row">
                        
                    </div>
                    </div>
                  </div>
                  <div class="col-lg-4">
                    <div class="form-group row">
                    </div>
                  </div>
                  <div class="col-lg-4">
                    <div class="form-group row">
                      <label *ngIf="!(this.IsDataIsInViewState)" class="col-sm-6 col-form-label text-center clear-padding-right">
                        <button type="submit" class="btn btn-primary save-button" [disabled]="!(ExpenseHeadForm.valid && this.DisableSave==true && this.ExpenseHeadMappingModel.GradeId > 0 && this.ExpenseHeadAdd.length > 0)" >Save</button>  
                      </label>
                    </div>
                  </div>
                </div>
              </form>
            </fieldset>
          </div>
          <button type="button" aria-label="Close" (click)="modalRef.hide()" style="background: #2a9bd7; outline: 0px none; border: 0px none; color: #fff; padding: 5px 10px; border-radius: 3px;">
            <span>Close</span>
          </button>             
        </div>
      </ng-template>
    </div>
  </div>
</div>