import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from '../../shared/user.service';
import { EmployeePortalService }  from '../../shared/HR_ADMIN/EmployeePortal/EmployeePortalService.service';
import { EmployeeMasterService }  from '../../shared/HR_ADMIN/Employee/EmployeeMaster.Service';
import { EmployeeDetail } from '../../shared/HR_ADMIN/Employee/EmployeeDetail.Model';
import { AttendanceRegularationModel } from '../../shared/HR_ADMIN/EmployeePortal/AttendanceRegularationModel.Model';
import { AmazingTimePickerService } from 'amazing-time-picker';
import { Menu } from '../../shared/menu.model';

@Component({
  selector: 'app-all-employee-regularisation-apply',
  templateUrl: './all-employee-regularisation-apply.component.html',
  styleUrls: ['./all-employee-regularisation-apply.component.css']
})
export class AllEmployeeRegularisationApplyComponent implements OnInit 
{
  AttendanceRegularationModel : AttendanceRegularationModel= new AttendanceRegularationModel();
  EmployeeRegularation: AttendanceRegularationModel[]=[];
  Regularation: any;
  RefId : any;
  Employees: EmployeeDetail[] = [];
  employee: any;
  userClaims: any;
  Result : string;
  SuccessStatus:boolean=false;
  SuccessMessage: string ="";
  public tableWidget: any;
  selectedIndex : number;
  // Begning Permission Portion    
  SelectedMenu:Menu;
  IsDataIsInViewState : boolean=false;
  CanViewState : boolean=true;
  IsApplicationView : boolean=false;
  // End of the permission portion
  // begin for Validation
  validdate : boolean= false;
  DisableSave: boolean=false;
  ShowMessage : boolean;
  // end of validation
  INTIME:any;
  OUTTIME : any;
  public selectedintime = undefined;
  public selectedOuttime = undefined;
  EmployeeSettings = {};
  selectedItems = [];
  emp : any [] =[]

  constructor(private EmployeeMasterService: EmployeeMasterService, private rout: Router, private userService: UserService, private atp: AmazingTimePickerService, private EmployeePortalService: EmployeePortalService) 
  {  }

  ngOnInit() 
  {
    // Begning Permission Portion 
    this.SelectedMenu=this.userService.SelectedMenu;
    if(this.SelectedMenu !=undefined)
    {
      localStorage.setItem('selectedmenu',JSON.stringify(this.SelectedMenu));
      this.rout.navigate([this.SelectedMenu.RouteName]);
    }
    else
    {
      if(JSON.parse(localStorage.getItem('selectedmenu'))!=undefined)
      {
        this.SelectedMenu = JSON.parse(localStorage.getItem('selectedmenu'));
        this.rout.navigate([this.SelectedMenu.RouteName]);
      }
    }
    if(this.SelectedMenu.CanAdd)
    {
      this.IsDataIsInViewState=false;
    }
    else
    {
      this.IsDataIsInViewState=true;
    }
    if(this.SelectedMenu.CanView)
    {
      this.CanViewState=false;
    }
    else
    {
      this.CanViewState=true;
    }
    // End of the permission portion
    if(localStorage.getItem('SuccessfullMessage'))
    {
      this.SuccessStatus=true;
      this.SuccessMessage =localStorage.getItem('SuccessfullMessage')
      localStorage.removeItem('SuccessfullMessage');
    }
    this.GetEmployeeDetailsDDL();
    this.AttendanceRegularationModel.EmployeeId=0;
    this.DisableSave=true;
    this.EmployeeSettings =
    {
      singleSelection:true,
      text:'Select Employee',
      enableSearchFilter:true,
      showCheckbox:false
    }
  }

  onItemSelect(item:any)
  {
    let EmployeeId= item['id'];
    if(EmployeeId>0)
    {
      this.IsApplicationView=true;
      this.ClearText();
      this.GetEmployeeDetails(EmployeeId);
    }     
  }
  OnItemDeSelect(item:any){ }
  onSelectAll(items: any){ }
  onDeSelectAll(items: any){ }

  GetEmployeeDetailsDDL() 
  {
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.EmployeeMasterService.GetEmployeeDetailsForDDL( t).then(x => 
    {
      this.employee = x;
      Object.keys(this.employee).forEach( key => 
      {
        let Emptemp = new EmployeeDetail();
        Emptemp = this.employee[key];
        this.Employees.push(Emptemp);
        this.emp.push({"id":Emptemp.EmployeeId,"itemName":Emptemp.EmployeeCode + '-' +Emptemp.EmployeeName},);
      });     
    });      
  }

  GetEmployeeAttendanceDetails(AttendanceDate)
  {
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.EmployeePortalService.GetEmployeeDetailsByEmployeeIdRegularation( t,this.AttendanceRegularationModel.EmployeeId,AttendanceDate).then(x => 
    {
      this.Regularation = x;
      Object.keys(this.Regularation).forEach( key => 
      {
        this.Regularation[key];
        let Regularationtemp = new AttendanceRegularationModel();
        Regularationtemp = this.Regularation[key];
        this.AttendanceRegularationModel.AttendanceDay=this.Regularation.AttendanceDay;
        this.AttendanceRegularationModel.ActualInTime=this.Regularation.ActualInTime;
        this.AttendanceRegularationModel.ActualOutTime=this.Regularation.ActualOutTime;
        this.AttendanceRegularationModel.ActualWorkingDuration=this.Regularation.ActualWorkingDuration;
        this.AttendanceRegularationModel.AttendanceStatus=this.Regularation.AttendanceStatus;
        this.ShowMessage=this.Regularation.AppliedStatus;
      });
    });
  }

  GetEmployeeDetails(EmployeeId)
  {
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.EmployeePortalService.GetEmployeeDetailsByEmployeeIdLeaveApply( t,EmployeeId).then(x => 
    {
      this.Regularation = x;
      Object.keys(this.Regularation).forEach( key => 
      {
        this.Regularation[key];
        let Regularationtemp = new AttendanceRegularationModel();
        Regularationtemp = this.Regularation[key];
        this.AttendanceRegularationModel.EmployeeId=this.Regularation.EmployeeId;
        this.AttendanceRegularationModel.EmployeeCode=this.Regularation.EmployeeCode;
        this.AttendanceRegularationModel.EmployeeName=this.Regularation.EmployeeName;
        this.AttendanceRegularationModel.EmployeeEmailId=this.Regularation.EmployeeEmailId;
        this.AttendanceRegularationModel.RHId=this.Regularation.RHId;
        this.AttendanceRegularationModel.RHCode=this.Regularation.RHCode;
        this.AttendanceRegularationModel.RHName=this.Regularation.RHName;
        this.AttendanceRegularationModel.RHEmailId=this.Regularation.RHEmailId;
        this.AttendanceRegularationModel.CompanyId=this.Regularation.CompanyId;
      });
    });
  }

  AttendanceDateChanged( date)
  {
    this.ClearText();
    this.AttendanceRegularationModel.AttendanceDate=date;
    var CurrentDate = new Date();
    var currentMonth = CurrentDate.getMonth();
    var CurrentDay = CurrentDate.getDate();
    
    var selectedDate = new Date(date);
    var selectedMonth=selectedDate.getMonth();
    var selectedDay=selectedDate.getDate();

    let selecteddateString = selectedDate.toDateString();
    let newselectedDate = new Date(selecteddateString);
    let CurrentdateString = CurrentDate.toDateString(); 
    let newCurrentDate = new Date(CurrentdateString);
    if (newselectedDate > newCurrentDate)
    {
      this.validdate = true;
    }
    else if (currentMonth == selectedMonth)
    {
      if(CurrentDay <=  25)
      {
        this.validdate = false;
      }
      else
      {
        if(selectedDay > 25)
        {
          this.validdate = false;
        }
        else
        {
          this.validdate = true;
        }
      }
    }
    else if (currentMonth == 0 && selectedMonth == 11)
    {
      if(selectedDay >= 26 )
      {
        this.validdate = false;
      }
      else
      {
        this.validdate = true;
      }
    }
    else if ((currentMonth-1) == selectedMonth)
    {
      if(selectedDay >= 26 )
      {
        this.validdate = false;
      }
      else
      {
        this.validdate = true;
      }
    }
    else
    {
      this.validdate = true;
    }
    
    if(this.validdate== false)
    {
      this.GetEmployeeAttendanceDetails(date);
      this.AttendanceRegularationModel.RegularationDuration=this.AttendanceRegularationModel.TotalWorkingHours - this.AttendanceRegularationModel.ActualWorkingDuration;
    }
  }

  SaveAttendanceRegularisationApply() :void
  {
    this.DisableSave=false;
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.EmployeePortalService.AddEmployeeAttendanceRegularation(t ,this.AttendanceRegularationModel).subscribe(res=>
    {
      this.RefId=res;
      this.AttendanceRegularationModel.RefId=0;              
      if(this.RefId>0)
      {
        localStorage.setItem('SuccessfullMessage', "Attendance Regularation Applied Successfully");
        this.rout.navigateByUrl('/Employee/EmployeeOffer', {skipLocationChange: true}).then(()=>this.rout.navigate(["/Employee/AllEmployeeAttendanceRegularizationApply"]));
      }
      else
      {
        this.ShowMessage=true;
        this.DisableSave=true;
      }
    }); 
  }

  // Time Change
  SelectinTime()
  {
    const amazingTimePicker = this.atp.open({});
    amazingTimePicker.afterClose().subscribe(time => 
    {     
      this.selectedintime = time;
      this.AttendanceRegularationModel.StartTime=this.selectedintime;      
      if(this.selectedOuttime != undefined)
      {
        var intimevar = this.selectedintime.split(':');
        var outtimevar= this.selectedOuttime.split(':');
        var totalintime= Number(intimevar[0])*60 + Number(intimevar[1]);    
        var totalouttime= Number(outtimevar[0])*60 + Number(outtimevar[1]);
        var diffIntimeOutTime = totalouttime -totalintime ;
        if(diffIntimeOutTime>=0)
        {
          this.AttendanceRegularationModel.TotalWorkingHours=diffIntimeOutTime;
        }
        else
        {
          this.AttendanceRegularationModel.TotalWorkingHours=1440 + diffIntimeOutTime;
        }
      }
      this.AttendanceRegularationModel.RegularationDuration=this.AttendanceRegularationModel.TotalWorkingHours - this.AttendanceRegularationModel.ActualWorkingDuration;
    });
  }
  
  SelectOutTime()
  {
    const amazingTimePicker = this.atp.open({});
    amazingTimePicker.afterClose().subscribe(time => 
    {
      this.selectedOuttime = time;
      this.AttendanceRegularationModel.EndTime=this.selectedOuttime;
      if(this.selectedintime != undefined)
      {
        var intimevar = this.selectedintime.split(':');
        var outtimevar= this.selectedOuttime.split(':');
        var totalintime= Number(intimevar[0])*60 + Number(intimevar[1]);    
        var totalouttime= Number(outtimevar[0])*60 + Number(outtimevar[1]);
        var diffIntimeOutTime = totalouttime -totalintime ;
        if(diffIntimeOutTime>=0)
        {
          this.AttendanceRegularationModel.TotalWorkingHours=diffIntimeOutTime;
        }
        else
        {
          this.AttendanceRegularationModel.TotalWorkingHours=1440 + diffIntimeOutTime;
        }
      }
      this.AttendanceRegularationModel.RegularationDuration=this.AttendanceRegularationModel.TotalWorkingHours - this.AttendanceRegularationModel.ActualWorkingDuration;
    });
  }

  RefreshPage()
  {
    this.rout.navigateByUrl('/Employee/EmployeeOffer', {skipLocationChange: true}).then(()=>this.rout.navigate(["/Employee/AllEmployeeAttendanceRegularizationApply"]));
  }

  ClearText()
  {
    this.AttendanceRegularationModel.AttendanceDate=null;
    this.AttendanceRegularationModel.AttendanceDay="";
    this.AttendanceRegularationModel.ActualInTime=null;
    this.AttendanceRegularationModel.ActualOutTime=null;
    this.AttendanceRegularationModel.ActualWorkingDuration=0;
    this.AttendanceRegularationModel.StartTime=null;
    this.AttendanceRegularationModel.EndTime=null;
    this.AttendanceRegularationModel.TotalWorkingHours=0;
    this.AttendanceRegularationModel.RegularationDuration=0;
    this.AttendanceRegularationModel.AttendanceStatus=null;
  }
}