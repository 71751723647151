<div class="body-content-area tabcontent" id="link1">
    <div class="row">
        <div class="col-lg-9">
            <div class="row">
                <div class="col-lg-1 low-padding text-center">
                    <!-- <img *ngIf="this.SelectedMenu.Image!=null" src={{this.SelectedMenu.Image}} class="body-icon" alt="Icon"/> -->
                </div>
                <div class="col-lg-10 clear-padding">
                    <span class="page-title">Mapping Leave Component</span>
                    <small class="page-description"></small>
                </div>
            </div>
        </div>
    </div>
    <div *ngIf="!(this.CanViewState)">
    <table id="tblLeaveMapping" datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" class="table table-striped table-bordered text-center table-new row-border hover">
        <thead class="thead-blue text-center">
            <tr>
                <th>Sl No.</th>
                <th class="text-left">Company</th>
                <th class="text-left">Leave Type</th>
                <th>Year </th>
                <th>Action</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let lm of LeaveMappings, let i = index">
                <td scope="row">{{i+1}}</td>
                <td class="text-left">{{lm.CompanyName}}</td>
                <td class="text-left">{{lm.LeaveTypeName}}</td>
                <td>{{lm.Year}}</td>
                <td>
                    <button *ngIf="this.SelectedMenu.CanEdit" type="button" (click)="GetLeaveMappingByid( lm.LeaveMappingId )" class="table-edit-btn"><i class="fas fa-edit"></i> </button> 
                    <button *ngIf="this.SelectedMenu.CanDelete" hidden="hidden" type="button" (click)="DeleteLeaveMappingByid( lm.LeaveMappingId )" class="table-remove-btn"><i class="fas fa-trash-alt"></i>  </button>
                    <button *ngIf="this.SelectedMenu.CanView" type="button" (click)="viewLeaveMappingByid( lm.LeaveMappingId )" class="table-view-button" type="button" ><i class="fas fa-eye"></i></button>
                </td>
            </tr>
        </tbody>
    </table>
    <fieldset class="body-form-control">
        <app-dialog [(visible)]="this.SuccessStatus">
            <span class="successmsg">{{this.SuccessMessage}}</span>
        </app-dialog>
        
        <legend class="legend--">Leave Mapping Creation</legend>
        <form #LeaveMapping="ngForm" (ngSubmit)="saveLeaveMapping()">
            <div class="row">
                <div class="col-lg-4">
                    <div class="col-body-border-right">
                        <div class="form-group row">
                            <label  class="col-sm-4 col-form-label clear-padding-right"> Company</label>
                            <div class="col-sm-7 clear-left-padding">
                            <select [(ngModel)]="LeaveMappingModel.CompanyId" (ngModelChange)="CheckLeaveMapping()" tabindex="1" id="CompanyId" [disabled]="IsCompanyEnable" class="form-control" name="CompanyId" #CompanyId="ngModel"  >
                                <option [value]="0" [disabled]=true >Select Company</option>
                                <option *ngFor="let comp of Companies" [value]="comp.CompId">{{comp.CompName}}</option>
                            </select>
                        </div>
                        <div *ngIf="this.check.length > 0" class="alert alert-danger">Component is already exist for selected Company</div>
                        <div class="required-validation" *ngIf="LeaveMappingModel.CompanyId==0">Company is required</div>
                        </div>
                        <div class="form-group row">
                        <label class="col-sm-6 col-form-label text-center clear-padding-right" *ngIf="!(this.IsDataIsInViewState)">
                            <button type="submit" tabindex="4" class="btn btn-primary save-button" [disabled]="!(LeaveMapping.valid && this.CheckExiststatus==true && this.LeaveTypeselectedItems.length > 0 && this.DisableSave==true)">Save</button>  
                        </label>
                        <div class="col-sm-6 col-form-label text-center">       
                        <button  type="button" tabindex="5" class="btn btn-danger cancle-button" (click)="RefreshPage()" >Cancel</button></div>
                        </div>
                    </div>
                </div>
                
                <div class="col-lg-4">
                    <div class="col-body-border-right">
                        <div class="form-group row">
                            <label  class="col-sm-4 col-form-label clear-padding-right">FinYear</label>
                            <div class="col-sm-7 clear-left-padding">
                            <select [(ngModel)]="LeaveMappingModel.Year" tabindex="2" id ="Year" class="form-control" [disabled]="IsBranchEnable"  name="Year" #Year="ngModel" (blur)="CheckLeaveMapping()">
                                <option [value]="0" [disabled]=true >Select Year</option>
                                <option *ngFor="let FY of FinancialYears" [value]="FY.Year">{{FY.Year}}</option>
                            </select>
                        </div>
                        <div *ngIf="this.check.length > 0" class="alert alert-danger">Component is already exist for selected Year</div>
                        <div class="required-validation" *ngIf="LeaveMappingModel.Year==0">Year is required</div>
                        </div>
                    </div>
                </div>

                <div class="col-lg-4">
                <div class="form-group row">
                    <div class="col-lg-11">
                    <angular2-multiselect class="form-control" [data]="LeaveTypeList" [(ngModel)]="LeaveTypeselectedItems" name="LeaveTypeselectedItems" 
                        [settings]="LeaveTypeSettings" (onSelect)="onItemSelect($event)" (onDeSelect)="OnItemDeSelect($event)" (onSelectAll)="onSelectAll($event)"
                        (onDeSelectAll)="onDeSelectAll($event)">
                    </angular2-multiselect>
                    </div>
                </div>
                </div>
            </div>
        </form>
    </fieldset>
    </div>
</div>  