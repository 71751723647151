<div class="body-content-area tabcontent" id="link1">
  <div class="row">
    <div class="col-lg-9">
      <div class="row">
        <div class="col-lg-1 low-padding text-center">
          <!-- <img *ngIf="this.SelectedMenu.Image!=null" src={{this.SelectedMenu.Image}} class="body-icon" alt="Icon"/> -->
        </div>
        <div class="col-lg-10 clear-padding">
          <div>
            <span class="page-title">Employee Direct Duty Application Status</span>
            <small class="page-description"></small>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="!(this.CanViewState)">
    <fieldset class="body-form-control" > 
      <legend class="legend--">Employee Details</legend>
      <form #SearchForm="ngForm">
        <div class="row">
          <div class="col-lg-4">
            <div class="col-body-border-right">
              <div class="form-group row">
                <label class="col-sm-2 col-form-label clear-padding-right">Employee</label>
                <div class="col-sm-9 clear-left-padding">
                  <angular2-multiselect tabindex="1" class="form-control" [data]="emp" [(ngModel)]="selectedItems" name="EmpId" [settings]="EmployeeSettings"
                    (onSelect)="onItemSelect($event)" (onDeSelect)="OnItemDeSelect($event)" (onSelectAll)="onSelectAll($event)" (onDeSelectAll)="onDeSelectAll($event)">
                  </angular2-multiselect>
                </div>
                <div class="required-validation" *ngIf="ModelListApplication.EmpId == 0">Employee is required</div>
              </div>
              <div class="form-group row">
                <label class="col-sm-4 col-form-label clear-padding-right">Status</label>
                <div class="col-sm-7 clear-left-padding">
                  <select [(ngModel)]="this.ModelListApplication.Status" id ="Status" (ngModelChange)="StatusChanged()" class="form-control" name="Status" required>
                    <option [value]="0" [disabled]=true >Select Status</option>
                    <option [value]="1">Pending</option>
                    <option [value]="2">Approved</option>
                    <option [value]="3">Rejected</option>
                  </select>
                </div>
                <div class="required-validation" *ngIf="this.ModelListApplication.Status == 0">Status is required</div>
              </div>
            </div>
          </div>
          <div class="col-lg-4">
            <div class="col-body-border-right">
              <div class="form-group row">
                <label class="col-sm-4 col-form-label clear-padding-right">From Date</label>
                <div class="col-sm-7 clear-left-padding">
                  <input type="date" name="SearchFromDate" [ngModel]="ModelListApplication.SearchFromDate | date:'yyyy-MM-dd'" (ngModelChange)="SearchFromDateChanged($event)" #SearchFromDate="ngModel" required/>
                </div>
              </div>
            </div>
            <div class="form-group row">
              <div class="col-sm-7 clear-left-padding">
                <button type="submit" (click)="submitClick()" class="btn btn-primary save-button" [disabled]="!(SearchForm.valid && this.ModelListApplication.Status > 0 && this.DisableSave == true && this.validdate == false && this.validTodate == false)">Generate</button>   
              </div>
            </div>
          </div>
          <div class="col-lg-4">
            <div class="form-group row">
              <label class="col-sm-4 col-form-label clear-padding-right">To Date</label>
              <div class="col-sm-7 clear-left-padding">
                <input type="date" name="SearchToDate" [ngModel]="ModelListApplication.SearchToDate | date:'yyyy-MM-dd'" (ngModelChange)="SearchToDateChanged($event)" #SearchToDate="ngModel" required/>
              </div>
            </div>
          </div>
        </div>
      </form>  

    </fieldset>

    <div *ngIf="PendingMsg" style="color: #dc3545; text-align: center;font-size: 15px; font-weight: bold;">No Pending Application is Found.</div>
    <div *ngIf="ApprovedMsg" style="color: #dc3545; text-align: center;font-size: 15px; font-weight: bold;">No Approved Application is Found.</div>
    <div *ngIf="RejectedMsg" style="color: #dc3545; text-align: center;font-size: 15px; font-weight: bold;">No Rejected Application is Found.</div>
    <div *ngIf="validdate" style="color: #dc3545; text-align: center;font-size: 15px; font-weight: bold;">Date From is not Valid Date.</div>
    <div *ngIf="validTodate" style="color: #dc3545; text-align: center;font-size: 15px; font-weight: bold;">Date To is not Valid Date.</div>

    <fieldset class="body-form-control" *ngIf="DirectDutyPending.length > 0"> 
      <legend class="legend--">Employee Direct Duty Pending</legend>
        <form #EmployeeDirectDutyPending="ngForm">
          <div lass="row" >
            <table id="tblPending" class="table table-striped table-bordered text-center table-new row-border hover">
              <thead class="thead-blue text-center">
                <tr>
                  <th>Sl.No.</th>
                  <th>Direct Duty Date</th>
                  <th class="text-left">Direct Duty Type</th>
                  <th class="text-left">Reason</th>
                  <th class="text-left">Place</th>
                  <th>Status</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let Pending of DirectDutyPending, let i = index">
                  <td scope="row">{{i+1}}</td>
                  <td>{{Pending.DateOfDirectDuty | date : "dd-MMM-yyyy"}}</td>
                  <td class="text-left" *ngIf="Pending.IsMorningDirectduty==true">First Half Direct Duty</td>
                  <td class="text-left" *ngIf="Pending.IsSecondHalfDirectDuty==true">Second Half Direct Duty</td>
                  <td class="text-left" *ngIf="Pending.IsFullDayDirectDuty==true">FullDay Direct Duty</td>
                  <td class="text-left">{{Pending.Reason}}</td>
                  <td class="text-left">{{Pending.Place}}</td>
                  <td>Pending</td>
                  <td>
                    <button type="button" (click)="viewAppliedDirectDuty(Pending.DirectId,template)" class="table-remove-btn" type="button"><i class="fas fa-trash-alt"></i></button>
                  </td>
                </tr>
              </tbody>
              <tfoot class="thead-blue text-center">
                <tr>
                  <th></th>
                  <th></th>
                  <th></th>
                  <th></th>
                  <th></th>
                  <th></th>
                  <th></th>
                </tr>
              </tfoot>
            </table>
          </div>
        </form>
    </fieldset>
  
    <fieldset class="body-form-control" *ngIf="DirectDutyApproved.length > 0"> 
      <legend class="legend--">Employee Direct Duty Approved</legend>
      <table id="tblDirectDutyApproved" class="table table-striped table-bordered text-center table-new row-border hover">
        <thead class="thead-blue text-center">
          <tr>
            <th>Sl.No.</th>
            <th>Direct Duty Date</th>
            <th class="text-left">Direct Duty Type</th>
            <th class="text-left">Reason</th>
            <th class="text-left">Place</th>
            <th class="text-left">Remarks</th>
            <th>Status</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let Approved of DirectDutyApproved, let i = index">
            <td scope="row">{{i+1}}</td>
            <td>{{Approved.DateOfDirectDuty | date : "dd-MMM-yyyy"}}</td>
            <td class="text-left" *ngIf="Approved.IsMorningDirectduty==true">First Half Direct Duty</td>
            <td class="text-left" *ngIf="Approved.IsSecondHalfDirectDuty==true">Second Half Direct Duty</td>
            <td class="text-left" *ngIf="Approved.IsFullDayDirectDuty==true">FullDay Direct Duty</td>
            <td class="text-left">{{Approved.Reason}}</td>
            <td class="text-left">{{Approved.Place}}</td>
            <td class="text-left">{{Approved.Remarks}}</td>
            <td>Approved</td>
          </tr>
        </tbody>
        <tfoot class="thead-blue text-center">
          <tr>
            <th></th>
            <th></th>
            <th></th>
            <th></th>
            <th></th>
            <th></th>
            <th></th>
          </tr>
        </tfoot>
      </table>
    </fieldset>
  
    <fieldset class="body-form-control" *ngIf="DirectDutyRejected.length > 0"> 
      <legend class="legend--">Employee Direct Duty Rejected</legend>
        <form #EmployeeDirectDutyRejected="ngForm">
          <div lass="row" >
            <table id="tblRejected" class="table table-striped table-bordered text-center table-new row-border hover">
              <thead class="thead-blue text-center">
                <tr>
                  <th>Sl.No.</th>
                  <th>Direct Duty Date</th>
                  <th class="text-left">Direct Duty Type</th>
                  <th class="text-left">Reason</th>
                  <th class="text-left">Place</th>
                  <th class="text-left">Remarks</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let Rejected of DirectDutyRejected, let i = index">
                  <td scope="row">{{i+1}}</td>
                  <td>{{Rejected.DateOfDirectDuty | date : "dd-MMM-yyyy"}}</td>
                  <td class="text-left" *ngIf="Rejected.IsMorningDirectduty==true">First Half Direct Duty</td>
                  <td class="text-left" *ngIf="Rejected.IsSecondHalfDirectDuty==true">Second Half Direct Duty</td>
                  <td class="text-left" *ngIf="Rejected.IsFullDayDirectDuty==true">FullDay Direct Duty</td>
                  <td class="text-left">{{Rejected.Reason}}</td>
                  <td class="text-left">{{Rejected.Place}}</td>
                  <td class="text-left">{{Rejected.Remarks}}</td>
                  <td>Rejected</td>
                </tr>
              </tbody>
              <tfoot class="thead-blue text-center">
                <tr>
                  <th></th>
                  <th></th>
                  <th></th>
                  <th></th>
                  <th></th>
                  <th></th>
                  <th></th>
                </tr>
              </tfoot>
            </table>
          </div>
        </form>
    </fieldset>

    <div style=" box-align: center; width: 1500px;" >
      <ng-template #template>
        <div class="modal-header" style="background: #2a9bd7; max-width: 1500px;"> 
          <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()" style="color:#fff;">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div  style="text-align: center; max-width: 1500px;">
          <div *ngIf="CancelMsg" style="color: #dc3545; text-align: center;font-size: 15px; font-weight: bold;">Application has been Cancel Successfully</div>
          <div style=" width: 100%;" *ngIf="ShowPopup">

            <fieldset class="body-form-control" style=" text-align: left;"> 
              <legend class="legend--">Direct Duty Details</legend>
              <form #EmployeeDirectduty="ngForm">
                <div class="row"> 
                  <div class="col-lg-4">
                    <div class="col-body-border-right">
                      <div class="form-group row">
                        <label  class="col-sm-4 col-form-label clear-padding-right">Code</label>
                        <div class="col-sm-7 clear-left-padding">
                          :&nbsp;&nbsp;{{this.ModelListApplication.EmployeeCode}}
                        </div>
                      </div>
                      <div class="form-group row">
                        <label  class="col-sm-4 col-form-label clear-padding-right">DD Date</label>
                        <div class="col-sm-7 clear-left-padding">
                          :&nbsp;&nbsp;{{ModelListApplication.DateOfDirectDuty | date : "dd-MMM-yyyy"}}
                        </div>
                      </div>
                      <div class="form-group row">
                        <label class="col-sm-4 col-form-label clear-padding-right">Place</label>
                        <div class="col-sm-7 clear-left-padding">
                          :&nbsp;&nbsp;{{ModelListApplication.Place}}
                        </div>
                      </div>         
                    </div>  
                  </div>
            
                  <div class="col-lg-4">
                    <div class="col-body-border-right">
                      <div class="form-group row">
                        <label  class="col-sm-4 col-form-label clear-padding-right">Name</label>
                        <div class="col-sm-7 clear-left-padding">
                          :&nbsp;&nbsp;{{this.ModelListApplication.EmployeeName}}
                        </div>
                      </div>
                      <div class="form-group row">
                        <div class="col-sm-4 col-form-label clear-padding-right">
                          <input type="radio" disabled="true" [checked]="this.ModelListApplication.Fullday=='Fullday'" id ="Fullday" name="Fullday" >
                            Full Day
                        </div>
                        <div class="col-sm-4 col-form-label clear-padding-center">
                          <input type="radio" disabled="true" [checked]="this.ModelListApplication.FirstHalf=='FirstHalf'" id ="FirstHalf" name="FirstHalf" >
                            FirstHalf &nbsp; &nbsp;
                        </div>
                        <div class="col-sm-4 col-form-label clear-padding-left">
                          <input type="radio" disabled="true" [checked]="this.ModelListApplication.SecondHalf=='SecondHalf'" id ="SecondHalf" name="SecondHalf" >
                            SecondHalf
                        </div>
                      </div>
                      <div class="form-group row">
                        <label class="col-sm-4 col-form-label clear-padding-right">Reason</label>
                        <div class="col-sm-7 clear-left-padding">
                          :&nbsp;&nbsp;{{this.ModelListApplication.Reason}}
                        </div>
                      </div>
                    </div> 
                  </div>
            
                  <div class="col-lg-4">
                    <div class="form-group row">
                      <label  class="col-sm-4 col-form-label clear-padding-right">Approver</label>
                      <div class="col-sm-7 clear-left-padding">
                        :&nbsp;&nbsp;{{this.ModelListApplication.RHCode}}-{{this.ModelListApplication.RHName}} 
                      </div>
                    </div>
                    <div class="form-group row">
                      <label class="col-sm-4 col-form-label clear-padding-right">RH EmailId</label>
                      <div class="col-sm-7 clear-left-padding">
                        :&nbsp;&nbsp;{{this.ModelListApplication.RHEmailId}} 
                      </div>
                    </div>
                    <div class="form-group row">
                      <label class="col-sm-4 col-form-label clear-padding-right">Remarks</label>
                      <div class="col-sm-7 clear-left-padding">
                        <input type="text" [(ngModel)]="this.ModelListApplication.Remarks" tabindex="1" maxlength="500" id ="Remarks" name="Remarks" #Remarks="ngModel" required class="form-control form-control-custom form-control-custom-new" >
                      </div>
                      <div *ngIf="Remarks.invalid && (Remarks.dirty || Remarks.touched)" class="alert alert-danger">
                        <div *ngIf="Remarks.errors.required">Remarks is required.</div>
                        <div *ngIf="Remarks.errors.maxlength">Remarks is not more than 500 characters</div>
                      </div> 
                    </div>
                    <div class="form-group row">
                      <label class="col-sm-6 col-form-label text-center clear-padding-right">
                        <button type="submit" tabindex="2" (click)="DirectDutyCancel(template)" class="btn btn-primary save-button" [disabled]="!(EmployeeDirectduty.valid && this.DisableSave == true)">Cancel</button>  
                      </label>
                    </div>
                  </div> 
                </div> 
              </form>
            </fieldset>

          </div>

          <button type="button" aria-label="Close" (click)="modalRef.hide()" style="background: #2a9bd7; outline: 0px none; border: 0px none; color: #fff; padding: 5px 10px; border-radius: 3px;">
            <span >OK</span>
          </button>             
        </div>
      </ng-template>
    </div>
  </div>
</div>