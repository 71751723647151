export class EPFESIModel 
{
    public RefId: number;
    public EPFCont :number;
    public EPFDed: number;
    public ESICont: number;
    public ESIDed: number;
    public FinYear: string;
    public EducationCess : number;
    public HigherEducationCess: number;
    public HighestBasicSalary : number;
    public ApplicableDate : Date;
    public IsActive : boolean;
    public ApplicableDateCheck : Date;
}