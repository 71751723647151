<div class="body-content-area tabcontent" id="link1">
<div class="row">
    <div class="col-lg-9">
        <div class="row">
            <div class="col-lg-1 low-padding text-center">
                <!-- <img *ngIf="this.SelectedMenu.Image!=null" src={{this.SelectedMenu.Image}} class="body-icon" alt="Icon"/> -->
            </div>
            <div class="col-lg-10 clear-padding">
                <span class="page-title">Salary Component Rule</span>
                <small class="page-description"></small>
            </div>
        </div>
    </div>
</div>

<div *ngIf="!(this.CanViewState)">
<table id="tblSalaryComponentRule" datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" class="table table-striped table-bordered text-center table-new row-border hover">
    <thead class="thead-blue text-center">
        <tr>
            <th>Sl No.</th>
            <th>Company</th>
            <th>Decision Making</th>
            <th>Level</th>
            <th>Grade</th>
            <th>Action</th>
        </tr>
    </thead>
    <tbody>
        <tr *ngFor="let Sal of SalaryRules, let i = index">
            <td scope="row">{{i+1}}</td>
            <td class="text-left">{{Sal.CompanyName}}</td>
            <td class="text-left">{{Sal.DecisionName}}</td>
            <td class="text-left">{{Sal.LevelName}}</td>
            <td class="text-left">{{Sal.GradeName}}</td>
            <td>
                <button *ngIf="this.SelectedMenu.CanEdit" type="button" (click)="GetSalaryRuleByid(Sal.CompanyId, Sal.GradeId)" class="table-edit-btn"><i class="fas fa-edit"></i> </button> 
                <button *ngIf="this.SelectedMenu.CanDelete" hidden="hidden" type="button" (click)="DeleteSalaryRuleByid( Sal.RefId )" class="table-remove-btn"><i class="fas fa-trash-alt"></i>  </button>
                <button *ngIf="this.SelectedMenu.CanView" type="button" (click)="viewSalaryRuleByid(Sal.CompanyId, Sal.GradeId)" class="table-view-button" type="button" ><i class="fas fa-eye"></i></button>
            </td>
        </tr>
    </tbody>
</table>
<fieldset class="body-form-control" *ngIf="SalaryComponentRules.length > 0" >
    <app-dialog [(visible)]="this.SuccessStatus">
        <span class="successmsg">{{this.SuccessMessage}}</span>
    </app-dialog>
    
    <legend class="legend--">Salary Component Rule Creation</legend>
    <form #SalaryComponentRule="ngForm" (ngSubmit)="saveSalaryComponentMaster()">
        <div class="row">
            <div class="col-lg-4">
                <div class="col-body-border-right">
                    <div class="form-group row" *ngIf="SalaryComponentRules.length > 0">
                        <label  class="col-sm-4 col-form-label clear-padding-right"> Company</label>
                        <div class="col-sm-7 clear-left-padding">
                            <select disabled="disabled" [(ngModel)]="SalaryRuleModel.CompanyId" tabindex="1" id ="CompanyId" [disabled]="IsCompanyEnable" class="form-control"  name="CompanyId" #CompanyId="ngModel" (blur)="CheckSalarycomponentcode()">
                                <option [value]="0" [disabled]=true >Select Company</option>
                                <option *ngFor="let comp of Companies" [value]="comp.CompId">{{comp.CompName}}</option>
                            </select>
                        </div>
                        <div *ngIf="this.check.length > 0" class="alert alert-danger">Component is already exist for selected Company</div>
                        
                    </div>
                </div>
            </div>
            <div class="col-lg-4">
                <div class="col-body-border-right">
                    <div class="form-group row" *ngIf="SalaryComponentRules.length > 0" >
                        <label  class="col-sm-4 col-form-label clear-padding-right">Grade</label>
                        <div class="col-sm-7 clear-left-padding">
                            <select disabled="disabled" [(ngModel)]="SalaryRuleModel.GradeId" tabindex="2" id ="GradeId" class="form-control" [disabled]="IsGradeEnable"  name="GradeId" #GradeId="ngModel"  (blur)="CheckSalarycomponentcode()">
                                <option [value]="0" [disabled]=true >Select Grade</option>
                                <option *ngFor="let Gr of Grades" [value]="Gr.GradeId">{{Gr.GradeName}}</option>
                            </select>
                        </div>
                        <div *ngIf="this.check.length > 0" class="alert alert-danger">Component is already exist for selected Grade</div>                        
                    </div>
                </div>
            </div>
            <div class="col-lg-4">
            <div class="form-group row">
                
            </div>
            </div>
        </div>
        <div *ngIf="SalaryComponentRules.length > 0" >
            <table id="tblComponent" class="table table-striped table-bordered text-left table-new row-border hover">
                <thead class="thead-blue text-center">
                    <tr>
                        <th>Sl No.</th>
                        <th>Component</th>
                        <th>Percentage(%)</th>
                        <th>Amount(Rs)</th>
                        <th>IsBasic</th>
                        <th>IsCTC</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let sc of SalaryComponentRules, let i = index">
                        <td scope="row">{{i+1}}</td>
                        <td class="text-left">
                            <span>{{sc.ComponentName}}</span>
                            <input type="text" hidden="hidden" [(ngModel)]="sc.RefId" id ="RefId" name="RefId" #RefId="ngModel">
                        </td>
                        <td>
                            <input type="text"   (blur)="PercentageChange(i)"  [(ngModel)]="sc.Percentage"  name="Percentage_{{i}}" #Percentage="ngModel" class="form-control form-control-custom form-control-custom-new">
                        </td>
                        <td>
                            <input type="text"  (blur)="AmountageChange(i)" [(ngModel)]="sc.Amount"  name="Amount_{{i}}" #Amount="ngModel" class="form-control form-control-custom form-control-custom-new">
                        </td>
                        <td>
                            <input type="radio" [disabled]="!sc.isenable" [checked]="SalaryComponentRules[i].value=='Basic'"    (click)="OnChangeBasic($event,i)" id ="IsBasic_{{i}}" name="IsBasic_{{i}}"   class="form-control form-control-custom form-control-custom-new">
                            <!-- [value]="Basic" -->
                        </td>
                        <td>
                            <input type="radio" [disabled]="!sc.isenable" [checked]="SalaryComponentRules[i].value=='CTC'"    (click)="OnChangeCTC($event,i)"  id ="IsCTC_{{i}}" name="IsCTC_{{i}}"  class="form-control form-control-custom form-control-custom-new">
                            <!-- [value]="CTC" -->
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="row">
            <div class="col-lg-4">

        </div>
        <div class="col-lg-4">

        </div>
        <div class="col-lg-4" *ngIf="SalaryComponentRules.length > 0" >
            <div class="form-group row">
                <label class="col-sm-6 col-form-label text-center clear-padding-right" *ngIf="!(this.IsDataIsInViewState)">
                    <button type="submit" tabindex="4" class="btn btn-primary save-button" [disabled]="!(this.SalaryComponentsIsValid==true && this.DisableSave==true)">Save</button>  
                </label>
                <div class="col-sm-6 col-form-label text-center">       
                <button  type="button" tabindex="5" class="btn btn-danger cancle-button" (click)="RefreshPage()" >Cancel</button></div>
            </div>
        </div>
        </div>
    </form>
</fieldset>
</div>
</div>