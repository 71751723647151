import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs/Subject';
import { DataTableDirective } from 'angular-datatables';
import { UserService } from '../../shared/user.service';
import { EmployeePortalService }  from '../../shared/HR_ADMIN/EmployeePortal/EmployeePortalService.service';
import { AttendanceRegularationModel } from '../../shared/HR_ADMIN/EmployeePortal/AttendanceRegularationModel.Model';
import { Menu } from '../../shared/menu.model';
// for time picker
import { AmazingTimePickerService } from 'amazing-time-picker';
//for time picker

@Component({
  selector: 'app-employee-attendance-regularisation',
  templateUrl: './employee-attendance-regularisation.component.html',
  styleUrls: ['./employee-attendance-regularisation.component.css']
})
export class EmployeeAttendanceRegularisationComponent implements OnInit 
{
  AttendanceRegularationModel : AttendanceRegularationModel= new AttendanceRegularationModel();
 
  EmployeeRegularation: AttendanceRegularationModel[]=[];
  Regularation: any;
  RefId : any;
  userClaims: any;

  Result : string;
  SuccessStatus:boolean=false;
  SuccessMessage: string ="";
  public tableWidget: any;
  selectedIndex : number;
  // Begning Permission Portion    
  SelectedMenu:Menu;
  // End of the permission portion
  // begin for Validation
  validdate : boolean;
  // end of validation
  ShowMessage : boolean;
  DisableSave :boolean;
  //INTIME :any;
  //OUTTIME : any;
  public selectedintime = undefined;
  public selectedOuttime = undefined;

  constructor(private EmployeePortalService: EmployeePortalService, private rout: Router, 
     private userService: UserService, private atp: AmazingTimePickerService){ }

  @ViewChild(DataTableDirective, {static: false})
  setDtOptions: DataTables.Settings = {};
  cardDtOptions: DataTables.Settings = {};
  dtElement: DataTableDirective;
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();

  ngOnInit() 
  {
    // Begning Permission Portion 
    this.SelectedMenu=this.userService.SelectedMenu;
    if(this.SelectedMenu !=undefined)
    {
      localStorage.setItem('selectedmenu',JSON.stringify(this.SelectedMenu));
      this.rout.navigate([this.SelectedMenu.RouteName]);
    }
    else
    {
      if(JSON.parse(localStorage.getItem('selectedmenu'))!=undefined)
      {
        this.SelectedMenu = JSON.parse(localStorage.getItem('selectedmenu'));
        this.rout.navigate([this.SelectedMenu.RouteName]);
      }
    }
    // End of the permission portion
    this.DisableSave=true;
    this.GetEmployeeDetails();
    this.AttendanceRegularationModel.ActualWorkingDuration=0;
    if(localStorage.getItem('SuccessfullMessage'))
    {
      this.SuccessStatus=true;
      this.SuccessMessage =localStorage.getItem('SuccessfullMessage')
      localStorage.removeItem('SuccessfullMessage');
    }
  }

  GetEmployeeAttendanceDetails(AttendanceDate)
  {
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.EmployeePortalService.GetEmployeeDetailsByUserLoginIdRegularation( t,AttendanceDate).then(x => 
    {
      this.Regularation = x;
      Object.keys(this.Regularation).forEach( key => 
      {
        this.Regularation[key];
        let Regularationtemp = new AttendanceRegularationModel();
        Regularationtemp = this.Regularation[key];
        this.AttendanceRegularationModel.AttendanceDay=this.Regularation.AttendanceDay;
        this.AttendanceRegularationModel.ActualInTime=this.Regularation.ActualInTime;
        this.AttendanceRegularationModel.ActualOutTime=this.Regularation.ActualOutTime;
        this.AttendanceRegularationModel.ActualWorkingDuration=this.Regularation.ActualWorkingDuration;
        this.AttendanceRegularationModel.AttendanceStatus=this.Regularation.AttendanceStatus;

        this.AttendanceRegularationModel.StartTime=this.Regularation.ActualInTime;
        this.AttendanceRegularationModel.EndTime=this.Regularation.ActualOutTime;
        this.AttendanceRegularationModel.TotalWorkingHours=this.Regularation.ActualWorkingDuration;

        this.ShowMessage=this.Regularation.AppliedStatus;
      });
    });
  }

  GetEmployeeDetails()
  {
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.EmployeePortalService.GetEmployeeDetailsByEmployeeId( t).then(x => 
    {
      this.Regularation = x;
      Object.keys(this.Regularation).forEach( key => 
      {
        this.Regularation[key];
        let LeaveApplicationtemp = new AttendanceRegularationModel();
        LeaveApplicationtemp = this.Regularation[key];
        this.AttendanceRegularationModel.EmployeeId=this.Regularation.EmployeeId;
        this.AttendanceRegularationModel.EmployeeCode=this.Regularation.EmployeeCode;
        this.AttendanceRegularationModel.EmployeeName=this.Regularation.EmployeeName;
        this.AttendanceRegularationModel.EmployeeEmailId=this.Regularation.EmployeeEmailId;
        this.AttendanceRegularationModel.RHId=this.Regularation.RHId;
        this.AttendanceRegularationModel.RHCode=this.Regularation.RHCode;
        this.AttendanceRegularationModel.RHName=this.Regularation.RHName;
        this.AttendanceRegularationModel.RHEmailId=this.Regularation.RHEmailId;
        this.AttendanceRegularationModel.CompanyId=this.Regularation.CompanyId;
      });
    });
  }

  AttendanceDateChanged( date)
  {
    this.ClearText();
    this.AttendanceRegularationModel.AttendanceDate=date;
    var CurrentDate = new Date();
    var currentMonth = CurrentDate.getMonth();
    var CurrentDay = CurrentDate.getDate();
    
    var selectedDate = new Date(date);
    var selectedMonth=selectedDate.getMonth();
    var selectedDay=selectedDate.getDate();

    let selecteddateString = selectedDate.toDateString();
    let newselectedDate = new Date(selecteddateString);
    let CurrentdateString = CurrentDate.toDateString(); 
    let newCurrentDate = new Date(CurrentdateString);
    let time = new Date().getHours() + ':' + new Date().getMinutes() + ':'+  new Date().getSeconds();
    
    if (newselectedDate > newCurrentDate)
    {
      this.validdate = true;
    }
    else if (currentMonth == selectedMonth)
    {
      if(CurrentDay == 26)
      {
        if('12:00:59' <= time)
        {
          this.validdate = true;
        }
        else
        {
          this.validdate = false;
        }
      }
      else if(CurrentDay <=  25)
      {
        this.validdate = false;
      }
      else
      {
        if(selectedDay > 25)
        {
          this.validdate = false;
        }
        else
        {
          this.validdate = true;
        }
      }
    }
    else if (currentMonth == 0 && selectedMonth == 11)
    {
      if(selectedDay >= 26 )
      {
        this.validdate = false;
      }
      else
      {
        this.validdate = true;
      }
    }
    else if ((currentMonth-1) == selectedMonth)
    {
      if(selectedDay >= 26 )
      {
        this.validdate = false;
      }
      else
      {
        this.validdate = true;
      }
    }
    else
    {
      this.validdate = true;
    }

    if(this.validdate== false)
    {
      this.GetEmployeeAttendanceDetails(date);
      this.AttendanceRegularationModel.RegularationDuration=this.AttendanceRegularationModel.TotalWorkingHours - this.AttendanceRegularationModel.ActualWorkingDuration;
    }
  }

  SaveRegularationApply() :void
  {
    if( this.AttendanceRegularationModel.StartTime !=null && this.AttendanceRegularationModel.EndTime !=null && this.AttendanceRegularationModel.AttendanceStatus != '' && this.validdate == false && this.ShowMessage == false && this.AttendanceRegularationModel.RegularationDuration > 0)
    {
      this.DisableSave=false;
      let t =  localStorage.getItem('userToken');
      t = 'Bearer' + t;
      this.EmployeePortalService.AddEmployeeAttendanceRegularation(t ,this.AttendanceRegularationModel).subscribe(res=>
      {
        this.RefId=res;
        this.AttendanceRegularationModel.RefId=0;              
        if(this.RefId>0)
        {
          localStorage.setItem('SuccessfullMessage', "Attendance Regularation Applied Successfully");
          this.rout.navigateByUrl('/EmployeePortal/PersonalInformation', {skipLocationChange: true}).then(()=>this.rout.navigate(["/EmployeePortal/Regularization"]));
        }
      });
    }
  }

  RefreshPage()
  {
    this.rout.navigateByUrl('/EmployeePortal/PersonalInformation', {skipLocationChange: true}).then(()=>this.rout.navigate(["/EmployeePortal/Regularization"]));
  }

 // Time Change
  SelectinTime()
  {
    const amazingTimePicker = this.atp.open({});
    amazingTimePicker.afterClose().subscribe(time => 
    {     
      this.selectedintime = time;
      this.AttendanceRegularationModel.StartTime=this.selectedintime;      
      if(this.selectedOuttime != undefined)
      {
        var intimevar = this.selectedintime.split(':');
        var outtimevar= this.selectedOuttime.split(':');
        var totalintime= Number(intimevar[0])*60 + Number(intimevar[1]);    
        var totalouttime= Number(outtimevar[0])*60 + Number(outtimevar[1]);
        var diffIntimeOutTime = totalouttime -totalintime ;
        if(diffIntimeOutTime>=0)
        {
          this.AttendanceRegularationModel.TotalWorkingHours=diffIntimeOutTime;
        }
        else
        {
          this.AttendanceRegularationModel.TotalWorkingHours=1440 + diffIntimeOutTime;
        }
      }
      this.AttendanceRegularationModel.RegularationDuration=this.AttendanceRegularationModel.TotalWorkingHours - this.AttendanceRegularationModel.ActualWorkingDuration;
    });
  }
  
  SelectOutTime()
  {
    const amazingTimePicker = this.atp.open({});
    amazingTimePicker.afterClose().subscribe(time => 
    {
      this.selectedOuttime = time;
      this.AttendanceRegularationModel.EndTime=this.selectedOuttime;
      if(this.selectedintime != undefined)
      {
        var intimevar = this.selectedintime.split(':');
        var outtimevar= this.selectedOuttime.split(':');
        var totalintime= Number(intimevar[0])*60 + Number(intimevar[1]);    
        var totalouttime= Number(outtimevar[0])*60 + Number(outtimevar[1]);
        var diffIntimeOutTime = totalouttime -totalintime ;
        if(diffIntimeOutTime>=0)
        {
          this.AttendanceRegularationModel.TotalWorkingHours=diffIntimeOutTime;
        }
        else
        {
          this.AttendanceRegularationModel.TotalWorkingHours=1440 + diffIntimeOutTime;
        }
      }
      this.AttendanceRegularationModel.RegularationDuration=this.AttendanceRegularationModel.TotalWorkingHours - this.AttendanceRegularationModel.ActualWorkingDuration;
    });
  }

  ClearText()
  {
    this.AttendanceRegularationModel.AttendanceDate=null;
    this.AttendanceRegularationModel.AttendanceDay="";
    this.AttendanceRegularationModel.ActualInTime=null;
    this.AttendanceRegularationModel.ActualOutTime=null;
    this.AttendanceRegularationModel.ActualWorkingDuration=0;
    this.AttendanceRegularationModel.StartTime=null;
    this.AttendanceRegularationModel.EndTime=null;
    this.AttendanceRegularationModel.TotalWorkingHours=0;
    this.AttendanceRegularationModel.RegularationDuration=0;
    this.AttendanceRegularationModel.AttendanceStatus=null;
  }
}
