<div class="body-content-area tabcontent" id="link1">
    <div class="row">
        <div class="col-lg-9">
            <div class="row">
                <div class="col-lg-1 low-padding text-center">
                    <!-- <img *ngIf="this.SelectedMenu.Image!=null" src={{this.SelectedMenu.Image}} class="body-icon" alt="Icon"/> -->
                </div>
                <div class="col-lg-10 clear-padding">
                    <span class="page-title">Roll Mapping with employee</span>
                    <!-- <small class="page-description">It is a long established fact that a reader will be distracted</small> -->
                </div>
            </div>
        </div>
    </div>

    <!-- <div *ngIf="!(this.CanViewState)"> -->
    <table id="tblMenuUserMapping" datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger"
        class="table table-striped table-bordered text-center table-new row-border hover">
        <thead class="thead-blue text-center">
            <tr>
                <th>Sl No.</th>
                <th class="text-left">User Name</th>
                <th class="text-left">Roll Name</th>
                <th>Action</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let CUR of Crm_UserRolls, let i = index">
                <td scope="row">{{i+1}}</td>
                <td class="text-left">{{CUR.EmployeeName}}</td>
                <td class="text-left">{{CUR.Rollname}}</td>
                <td>
                    <!-- <button *ngIf="this.SelectedMenu.CanEdit" type="button" (click)="GetMenuUserMappingByRoleId(MUM.UserId)" class="table-edit-btn"><i class="fas fa-edit"></i> </button> 
                      <button *ngIf="this.SelectedMenu.CanView" type="button" (click)="viewMenuUserMappingByRoleId(MUM.UserId)" class="table-view-button" type="button" ><i class="fas fa-eye"></i></button>  -->



                    <button type="button" *ngIf="this.userService.selectedCrmMenu.CanEdit"
                        (click)="GetUserRollById(CUR.UserRollId)" class="table-edit-btn"><i class="fas fa-edit"></i>
                    </button>
                    <button type="button" *ngIf="this.userService.selectedCrmMenu.CanView"
                        (click)="ViewUserRoleById(CUR.UserRollId)" class="table-view-button" type="button"><i
                            class="fas fa-eye"></i></button>
                </td>
            </tr>
        </tbody>
    </table>

    <fieldset class="body-form-control">
        <app-dialog [(visible)]="this.SuccessStatus">
            <span class="successmsg">{{this.SuccessMessage}}</span>
        </app-dialog>

        <legend class="legend--">Crm Role Main Menu </legend>
        <form #MenuUserMapping="ngForm" (ngSubmit)="addCrmRollMapping()">
            <div class="row">
                <div class="col-lg-4">
                    <div class="col-body-border-right">
                        <div class="form-group row">
                            <label class="col-sm-4 col-form-label clear-padding-right"> Select User</label>
                            <div class="col-sm-7 clear-left-padding">

                                <angular2-multiselect tabindex="5" class="form-control" [data]="emp"
                                    [(ngModel)]="selectedItems" name="EmployeeId" [settings]="UserSettings"
                                    (onSelect)="onItemSelect($event)" (onDeSelect)="OnItemDeSelect($event)"
                                    (onSelectAll)="onSelectAll($event)" (onDeSelectAll)="onDeSelectAll($event)">
                                </angular2-multiselect>

                            </div>
                            <div class="required-validation" *ngIf="CrmuserRoll.EmpId == 0">Employee is required</div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4">
                    <div class="col-body-border-right">
                        <div class="form-group row">
                            <label class="col-sm-4 col-form-label clear-padding-right"> Select Role </label>
                            <div class="col-sm-7 clear-left-padding">

                                <angular2-multiselect tabindex="5" class="form-control" [data]="Rolls"
                                    [(ngModel)]="selectedRoles" name="Rollid" [settings]="RollSettings"
                                    (onSelect)="onItemSelectRoll($event)" (onDeSelect)="OnItemDeSelectRoll($event)"
                                    (onSelectAll)="onSelectAllRoll($event)" (onDeSelectAll)="onDeSelectAllRoll($event)">
                                </angular2-multiselect>

                            </div>
                            <div class="required-validation" *ngIf="this.CrmuserRoll.Roll_Id == 0">Roll is required
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4">
                    <div class="form-group row">
                        <label class="col-sm-6 col-form-label text-center clear-padding-right">
                            <button type="submit" tabindex="4" [disabled]="this.DisableSave"
                                class="btn btn-primary save-button">Save</button>
                        </label>
                        <div class="col-sm-6 col-form-label text-center">
                            <button type="button" tabindex="5" class="btn btn-danger cancle-button"
                                (click)="RefreshPage()">Cancel</button>
                        </div>
                    </div>
                </div>

            </div>
        </form>
    </fieldset>
</div>