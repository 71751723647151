<div class="body-content-area tabcontent" id="link1">
  <div class="row">
    <div class="col-lg-9">
      <div class="row">
        <div class="col-lg-1 low-padding text-center">
          <!-- <img *ngIf="this.SelectedMenu.Image!=null" src={{this.SelectedMenu.Image}} class="body-icon" alt="Icon"/> -->
        </div>
        <div class="col-lg-10 clear-padding">
          <span class="page-title">Manage KRA Template</span>
          <small class="page-description"></small>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="(this.CanViewState)">
    <div style=" text-align: right;">
      <button (click)="ADDClick(template)" type="button" class="btn btn-primary Add-button">ADD New</button> 
    </div>
    <fieldset class="body-form-control">
      <legend class="legend--">KRA Master Details</legend>
      <div class="row">
        <div class="col-lg-6">
          <div class="form-group row">
            <label  class="col-sm-4 col-form-label clear-padding-right">Company</label>
            <div class="col-sm-7 clear-left-padding">
              <select [(ngModel)]="KRAMasterModel.CompanyIdShow" (ngModelChange)="onChangeCompanyShow($event)" id ="CompanyIdshow" class="form-control" name="CompanyIdshow"> 
                <option [value]="0" [disabled]=true >Select Company</option>
                <option *ngFor="let c of Companiesshow" [value]="c.CompId">{{c.CompName}}</option>
              </select>
            </div>
            <div class="required-validation" *ngIf="KRAMasterModel.CompanyIdShow==0">Company is required</div>
          </div>
        </div>
      </div>

      <div *ngIf="NoRecordMsg" style="color: #dc3545; text-align: center;font-size: 15px; font-weight: bold;">No Template Found for Selected Company.</div>

      <table id="tblKRAMaster" *ngIf="this.KRAMasters.length > 0"  datatable [dtOptions]="dtOptions" class="table table-striped table-bordered text-center table-new row-border hover">
        <thead class="thead-blue text-center" style="width: 100%;">
          <tr>
            <th style="width: 10%;">Sl No.</th>
            <th style="width: 30%;" class="text-left">Template Name</th>
            <th style="width: 10%;">Action</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let kra of KRAMasters, let i = index">
            <td scope="row">{{i+1}}</td>
            <td class="text-left">{{kra.TemplateName}}</td>
            <td>
              <button *ngIf="this.SelectedMenu.CanEdit" type="button" (click)="GetKRATemplateDetails(kra.TemplateRefId,template)" class="table-edit-btn"><i class="fas fa-edit"></i></button> 
              <button *ngIf="this.SelectedMenu.CanDelete" type="button" (click)="DeleteKRATemplateMaster(kra.TemplateRefId,template)" class="table-remove-btn"><i class="fas fa-trash-alt"></i></button>
              <button *ngIf="this.SelectedMenu.CanView" type="button" (click)="viewKRATemplateDetails(kra.TemplateRefId,template)" class="table-view-button" type="button"><i class="fas fa-eye"></i></button>
            </td>
          </tr>
        </tbody>
        <tfoot class="thead-blue text-center" style="width: 100%;">
          <tr>
            <th></th>
            <th></th>
            <th></th>
          </tr>
        </tfoot>
      </table>
    </fieldset>

    <div style=" box-align: center; width: 1500px;" >
      <ng-template #template>
        <div class="modal-header" style="background: #2a9bd7; max-width: 1500px;"> 
          <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()" style="color:#fff;">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div  style="text-align: center; max-width: 1500px;">
          <div *ngIf="DeleteDetailsMsg" style="color: #dc3545; text-align: center;font-size: 15px; font-weight: bold;">Delete Template Details Successfully</div>
          <div *ngIf="DeleteTemplateMsg" style="color: #dc3545; text-align: center;font-size: 15px; font-weight: bold;">Delete Template Master Successfully</div>
          <div *ngIf="SaveMsg" style="color: #dc3545; text-align: center;font-size: 15px; font-weight: bold;">Modified Template Master Successfully</div>
          <div style=" width: 100%;" *ngIf="ShowPopup">
            <fieldset class="body-form-control">
              <legend class="legend--">KRA Template Creation</legend>
              <form #KRAMasterForm="ngForm" (ngSubmit)="SaveKRATemplate(template)">
                <div class="row">
                  <div class="col-lg-6">
                    <div class="form-group row">
                      <label  class="col-sm-4 col-form-label clear-padding-right">Template Name</label>
                      <div class="col-sm-7 clear-left-padding">
                        <input type="text" [(ngModel)]="KRAMasterModel.TemplateName" tabindex="1" id ="TemplateName" name="TemplateName" #TemplateName="ngModel" required class="form-control form-control-custom form-control-custom-new" (blur)="CheckName()">
                      </div>

                      <div *ngIf="this.Namecheck.length > 0" class="alert alert-danger">Template Name is exist</div>
                      <div *ngIf="TemplateName.invalid && (TemplateName.dirty || TemplateName.touched)" class="alert alert-danger">
                        <div *ngIf="TemplateName.errors.required">Template Name is required.</div>
                      </div>
                    </div>
                  </div>
                      
                  <div class="col-lg-6">
                    <div class="form-group row">
                      <label  class="col-sm-4 col-form-label clear-padding-right">Company</label>
                      <div class="col-sm-7 clear-left-padding" *ngIf="(this.CanSaveButtonView)">
                        <select [(ngModel)]="KRAMasterModel.CompanyId" id ="CompanyId" class="form-control" name="CompanyId"> 
                          <option [value]="0" [disabled]=true >Select Company</option>
                          <option *ngFor="let c of Companies" [value]="c.CompId">{{c.CompName}}</option>
                        </select>
                      </div>
                      <div class="col-sm-7 clear-left-padding" *ngIf="!(this.CanSaveButtonView)">
                        <select [(ngModel)]="KRAMasterModel.CompanyId" disabled="true" id ="CompanyId" class="form-control" name="CompanyId"> 
                          <option [value]="0" [disabled]=true >Select Company</option>
                          <option *ngFor="let c of Companies" [value]="c.CompId">{{c.CompName}}</option>
                        </select>
                      </div>
                      <div class="required-validation" *ngIf="KRAMasterModel.CompanyId==0">Company is required</div>
                    </div>
                  </div>
                </div>
    
                <table *ngIf="(this.CanSaveButtonView)" id="tblTemplateDetails" class="table table-striped table-bordered text-left table-new row-border hover">
                  <thead class="thead-blue text-center">
                    <tr>
                      <th style="width: 5%;">Sl.No.</th>
                      <th style="width: 40%;">KRA Name</th>
                      <th style="width: 10%;">KRA Weighted</th>
                      <th style="width: 40%;">Rating Criteria</th>
                      <th style="width: 5%;">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let templates of KRATemplates, let i = index">
                      <td scope="row">
                        {{i+1}}
                      </td>
                      <td>
                          <input type="text" [(ngModel)]="templates.KRAName" id ="KRAName_{{i}}" name="KRAName_{{i}}" #KRAName="ngModel" class="form-control form-control-custom form-control-custom-new">
                      </td>
                      <td>
                        <input type="text" [(ngModel)]="templates.KRAWeighted" name="KRAWeighted_{{i}}" #KRAWeighted="ngModel" maxlength="2" (ngModelChange)="WategesChanged()" pattern="[0-9]+" class="form-control form-control-custom form-control-custom-new">
                        <div *ngIf="KRAWeighted.invalid && (KRAWeighted.dirty || KRAWeighted.touched)" class="alert alert-danger">
                          <div *ngIf="KRAWeighted.errors?.pattern">Weighted should be valid.</div>
                          <div *ngIf="KRAWeighted.errors.maxlength">Weighted is not more than 2 digit.</div>
                        </div>
                      </td>
                      <td>
                        <input type="text" [(ngModel)]="templates.RatingCriteria" id ="RatingCriteria_{{i}}" name="RatingCriteria_{{i}}" #RatingCriteria="ngModel" class="form-control form-control-custom form-control-custom-new">
                      </td>
                      <td style=" text-align: center;" *ngIf="(this.CanSaveButtonView)">
                        <button *ngIf="this.SelectedMenu.CanDelete && templates.RefId > 0" type="button" (click)="DeleteKRATemplateDetail(templates.RefId,template)" class="table-remove-btn"><i class="fas fa-trash-alt"></i></button>
                      </td>
                    </tr>
                  </tbody>
                  <tfoot class="thead-blue text-center">
                    <tr>
                      <th></th>
                      <th style=" text-align: right;">Total Weighted&nbsp;:&nbsp;</th>
                      <th>{{this.KRAMasterModel.TotalKRAWeighted}}</th>
                      <th></th>
                      <th *ngIf="(this.CanSaveButtonView)"></th>
                    </tr>
                  </tfoot>
                </table>
              
                <table *ngIf="!(this.CanSaveButtonView)" id="tblTemplateDetailsshow" class="table table-striped table-bordered text-left table-new row-border hover">
                  <thead class="thead-blue text-center">
                    <tr>
                      <th style="width: 5%;">Sl.No.</th>
                      <th style="width: 40%;">KRA Name</th>
                      <th style="width: 12%; text-align: center;">KRA Weighted</th>
                      <th style="width: 43%;">Rating Criteria</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let templates of KRATemplates, let i = index">
                      <td scope="row">{{i+1}}</td>
                      <td>{{templates.KRAName}}</td>
                      <td style="text-align: center;">{{templates.KRAWeighted}}</td>
                      <td>{{templates.RatingCriteria}}</td>
                    </tr>
                  </tbody>
                  <tfoot class="thead-blue text-center">
                    <tr>
                      <th></th>
                      <th style=" text-align: right;">Total Weighted&nbsp;:&nbsp;</th>
                      <th>{{this.KRAMasterModel.TotalKRAWeighted}}</th>
                      <th></th>
                    </tr>
                  </tfoot>
                </table>

                <div class="row float-right">
                  <div class="form-group row">
                    &nbsp;
                  </div>
                  <div class="col-auto text-center" *ngIf="(this.CanSaveButtonView)">
                    <button type="submit" class="btn btn-primary save-button" [disabled]="!(KRAMasterForm.valid && this.CheckNameExiststatus==true && this.KRAMasterModel.TotalKRAWeighted == 100 && this.DisableSave==true && KRAMasterModel.CompanyId > 0)">Save</button>
                  </div>
                  <div class="col-auto text-center" *ngIf="(this.CanSaveButtonView)">
                    <button type="button" class="btn btn-danger cancle-button" (click)="AddnewRow()">Add Row </button>
                  </div>
                </div>
                
              </form>
            </fieldset>
          </div>
          <button type="button" aria-label="Close" (click)="modalRef.hide()" style="background: #2a9bd7; outline: 0px none; border: 0px none; color: #fff; padding: 5px 10px; border-radius: 3px;">
            <span>Close</span>
          </button>             
        </div>
      </ng-template>
    </div>
  </div>
</div>