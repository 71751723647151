
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Http, Headers, RequestOptions, Response } from '@angular/http';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import 'rxjs/add/operator/toPromise';
import { Subscriber } from 'rxjs';
import { GlobalVariable } from '../../../../app.global';
import { Model_CrmCountry } from '../Model_CrmCountry';
import { Model_Crm_UserRoll } from '../Model_Crm_User_Roll';
import { AlertPromise } from 'selenium-webdriver';
import { Model_CRM_Telecall } from './Model_CRM_Telecall';
import { Model_CRM_TeleCall_FollowUp } from './Model_CRM_TeleCall_FollowUp';
import { Model_CRM_Telecall_Next_Followup } from './Model_CRM_Telecall_Next_Followup';



@Injectable()
export class SalesCallService {

    Telecallid: number;

    constructor(private http: HttpClient, private spinnerService: Ng4LoadingSpinnerService) {


    }

    GetTelecalls(token) {
        this.spinnerService.show();
        const authHeader = new HttpHeaders();
        authHeader.append('Content-Type', 'application/json');
        authHeader.append('Authorization', token);
        return new Promise((resolve, reject) => {
            setTimeout(() => {
                this.http.get(GlobalVariable.BASE_API_URL + '/api/CRMTeleCall/GetTeleCalls', { headers: authHeader })
                    .subscribe(data => {
                        this.spinnerService.hide();
                        resolve(data)
                    },
                        error => {
                            this.spinnerService.hide();
                            reject(error);
                        });
            }, 3000);
        });
    }


    GetTelecallsFollowUpNext(token, TelecallId: Number) {
        this.spinnerService.show();
        const authHeader = new HttpHeaders();
        authHeader.append('Content-Type', 'application/json');
        authHeader.append('Authorization', token);
        return new Promise((resolve, reject) => {
            setTimeout(() => {
                this.http.get(GlobalVariable.BASE_API_URL + '/api/CRMTeleCall/GetNextFollowUp?TelecallId=' + TelecallId, { headers: authHeader })
                    .subscribe(data => {
                        this.spinnerService.hide();
                        resolve(data)
                    },
                        error => {
                            this.spinnerService.hide();
                            reject(error);
                        });
            }, 3000);
        });
    }


    AddUpdateTeleCall(token, CrmTeleCall: Model_CRM_Telecall) {

        const authHeader = new HttpHeaders();
        authHeader.append('Content-Type', 'application/json');
        authHeader.append('Authorization', token);
        authHeader.append('Accept', 'application/json');

        // return this.http.post(GlobalVariable.BASE_API_URL + '/api/Crm_User_Roll/Update', CrmUserRole, { headers: authHeader })
        //     .map(this.extractData)
        //     .catch(this.handleErrorObservable);
        this.spinnerService.show();
        return new Promise((resolve, reject) => {
            setTimeout(() => {
                this.http.post(GlobalVariable.BASE_API_URL + '/api/CRMTeleCall/AddUpdateTeleCall', CrmTeleCall, { headers: authHeader })
                    .subscribe(data => {
                        this.spinnerService.hide();
                        resolve(data)
                        this.spinnerService.show();
                    },
                        error => {
                            this.spinnerService.hide();
                            reject(error);
                            this.spinnerService.show();
                        });
            }, 3000);
        });
    }


    AddUpdateFollowUpsNext(token, TeleCall_Next_FollowUp: Model_CRM_Telecall_Next_Followup) {
        const authHeader = new HttpHeaders();
        authHeader.append('Content-Type', 'application/json');
        authHeader.append('Authorization', token);
        authHeader.append('Accept', 'application/json');

        // return new Promise((resolve, reject) => {
        //     setTimeout(() => {
        //         this.http.post(GlobalVariable.BASE_API_URL + '/api/CRMTeleCall/UpdateNextFollowUp', TeleCall_Next_FollowUp, { headers: authHeader })
        //             .subscribe(data => {
        //                 this.spinnerService.hide();
        //                 resolve(data)
        //             },
        //                 error => {
        //                     this.spinnerService.hide();
        //                     reject(error);
        //                 });
        //     }, 3000);
        // });

        return this.http.post(GlobalVariable.BASE_API_URL + '/api/CRMTeleCall/UpdateNextFollowUp', TeleCall_Next_FollowUp, { headers: authHeader })
            .map(this.extractData)
            .catch(this.handleErrorObservable);

    }



    AddUpdateFollowUps(token, FollowUpId: number, TelecallId: number, FollowUpText: string) {

        let FollowUp = new Model_CRM_TeleCall_FollowUp();
        FollowUp.FollowUpId = FollowUpId;
        FollowUp.Tele_Call_Id = TelecallId;
        FollowUp.FolloWUpText = FollowUpText;

        const authHeader = new HttpHeaders();
        authHeader.append('Content-Type', 'application/json');
        authHeader.append('Authorization', token);
        authHeader.append('Accept', 'application/json');
        return this.http.post(GlobalVariable.BASE_API_URL + '/api/CRMTeleCall/AddUpdateFollowUp', FollowUp, { headers: authHeader })
            .map(this.extractData)
            .catch(this.handleErrorObservable);


    }



    GetTeleFollowups(token, telecallId: number) {
        this.spinnerService.show();
        const authHeader = new HttpHeaders();
        authHeader.append('Content-Type', 'application/json');
        authHeader.append('Authorization', token);
        return new Promise((resolve, reject) => {
            setTimeout(() => {
                this.http.get(GlobalVariable.BASE_API_URL + '/api/CRMTeleCall/GetFollowUps?TelecallId=' + telecallId, { headers: authHeader })
                    .subscribe(data => {
                        this.spinnerService.hide();
                        resolve(data)
                    },
                        error => {
                            this.spinnerService.hide();
                            reject(error);
                        });
            }, 3000);
        });
    }

    // BEGIN of Employee Salary Sheet Report
    GetTelecallReports(token, FromDate: Date, ToDate: Date) {

        const authHeader = new HttpHeaders();
        authHeader.append('Content-Type', 'application/json');
        authHeader.append('Authorization', token);
        setTimeout(() => {
            this.http.get(GlobalVariable.BASE_API_URL + '/api/CRMTeleCall/GetTeleCallReport?Fromdate=' + FromDate + '&Todate=' + ToDate, { headers: authHeader, responseType: 'blob' as 'json' }).subscribe((res) => {
                var file = new Blob(<any>[res], { type: 'application/vnd.ms-excel' });
                var fileURL = URL.createObjectURL(file);
                window.open(fileURL);
            });
        }, 3000);
    }
    // End of Employee Salary sheet Report



    private extractData(res: Response) {
        let body = res;
        return body || {};
    }
    private handleErrorObservable(error: Response | any) {
        console.error(error.message || error);
        return Observable.throw(error.message || error);
    }
}