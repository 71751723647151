<!-- <perfect-scrollbar style="max-width: 1260px; max-height: 540px;"> -->

<div class="body-content-area tabcontent" id="link1">
  <div class="row">
    <div class="col-lg-9">
      <div class="row">
        <div class="col-lg-1 low-padding text-center">


        </div>

        <div class="col-lg-10 clear-padding">
          <span class="page-title">Manage Role</span>
          <small class="page-description">It is a long established fact that a reader will be distracted</small>
        </div>
      </div>
    </div>
  </div>


  <div>
    <table id="tbl_CRM_ROLE" datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger"
      class="table table-striped table-bordered text-center table-new row-border hover">
      <thead class="thead-blue text-center">

        <tr>
          <th>Sl No.</th>
          <th class="text-left">ROLE NAME</th>
          <th class="text-left">CRM TYPE</th>
          <th>Action</th>
        </tr>

      </thead>
      <tbody>


        <tr *ngFor="let R of crmrole, let i = index">
          <td scope="row">{{i+1}}</td>
          <td class="text-left">{{ R.RoleName}}</td>
          <td class="text-left">{{R.CRMTypeName}}</td>

          <td>
            <button *ngIf="this.userService.selectedCrmMenu.CanEdit" type="button" (click)="GetCRMRoleById( R.Role_ID )"
              class="table-edit-btn"><i class="fas fa-edit"></i></button>

            <button *ngIf="this.userService.selectedCrmMenu.CanDelete" type="button"
              (click)="DeleteManageRole( R.Role_ID )" class="table-remove-btn"><i class="fas fa-trash-alt"></i></button>

            <button *ngIf="this.userService.selectedCrmMenu.CanView" type="button"
              (click)="ViewCRMRoleById( R.Role_ID )" class="table-view-button" type="button"><i
                class="fas fa-eye"></i></button>

            <!-- <button type="button" (click)="GetCRMRoleById( R.Role_ID )" class="table-edit-btn"><i
                  class="fas fa-edit"></i></button>


              <button *ngIf="this.userService.selectedCrmMenu.CanView" type="button"
                (click)="ViewCRMRoleById( R.Role_ID )" class="table-view-button" type="button"><i
                  class="fas fa-eye"></i></button> -->

        </tr>

      </tbody>
    </table>


    <fieldset class="body-form-control">
      <app-dialog [(visible)]="this.SuccessStatus">
        <span class="successmsg">{{this.SuccessMessage}}</span>
      </app-dialog>



      <legend class="legend--">ADD ROLE</legend>
      <form #MannageRole="ngForm" (ngSubmit)="saveCRMRole()">



        <div class="row">
          <div class="col-lg-4">
            <div class="col-body-border-right">
              <div class="form-group row">
                <label class="col-sm-4 col-form-label clear-padding-right">ROLE NAME</label>
                <div class="col-sm-7 clear-left-padding">
                  <input type="text" [(ngModel)]="Role_Manage_Model.RoleName" tabindex="1" maxlength="250" id="RoleName"
                    name="RoleName" #RoleName="ngModel" required maxlength="250"
                    class="form-control form-control-custom form-control-custom-new" #RoleName="ngModel" required
                    class="form-control form-control-custom form-control-custom-new">
                </div>
                <div *ngIf="RoleName.invalid && (RoleName.dirty || RoleName.touched)" class="alert alert-danger">
                  <div *ngIf="RoleName.errors.required">Role Name is required.</div>
                  <div *ngIf="RoleName.errors.maxlength">Role Name is not more than 250 characters</div>
                </div>
              </div>
            </div>




          </div>





          <div class="col-lg-4">
            <div class="form-group row">
              <label class="col-sm-4 col-form-label clear-padding-right">CRM TYPE</label>
              <div class="col-sm-7 clear-left-padding">
                <select [(ngModel)]="Role_Manage_Model.CRMType" tabindex="2" (ngModelChange)="onCRMTypeChange($event)"
                  id="CRMType" class="form-control" name="CRMType" #CRMType="ngModel">
                  <option [value]="0" [disabled]=true>Select CRM Type</option>
                  <option *ngFor="let C of CrmType" [value]="C.CRMTypeId">{{C.Crm_Type_Name}}</option>
                </select>
              </div>
              <div class="alert alert-danger ng-star-inserted m-l8" *ngIf="Role_Manage_Model.CRMType==0">CRM TYPE
                required
              </div>
            </div>

          </div>


          <!-- Save  And Cancel  Button   -->

          <div class="col-lg-4">
            <div class="form-group row">
              <div class="col-sm-5 col-form-label clear-padding-right" *ngIf="!(this.IsDataIsInViewState)">
                <button type="submit" id="submit" tabindex="4" class="btn btn-primary save-button"
                  [disabled]="!((this.Role_Manage_Model.CRMType!=0)&&(this.Role_Manage_Model.RoleName.length>0))">Save</button>


              </div>

              <div class="col-sm-5 col-form-label text-center">
                <button type="button" tabindex="5" class="btn btn-danger cancle-button"
                  (click)="RefreshPage()">Cancel</button>
              </div>

            </div>
          </div>

          <!-- Save  And Cancel  Button   -->


        </div>
      </form>
    </fieldset>
  </div>
</div>
<!-- </perfect-scrollbar> -->