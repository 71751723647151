<div id="wrapper">
  <!-- Sidebar -->
  <ul class="navbar-nav bg-gradient-primary sidebar sidebar-dark accordion" id="accordionSidebar">
    <!-- Sidebar - Brand -->
    <a class="sidebar-brand d-flex align-items-center justify-content-center bg-white" href="javascript:void(0);" (click)="OnDashboard()">
      <div class="sidebar-brand-icon">
        <img *ngIf="this.UserModel.CompIconUrlImage!=null" [src]="UserModel.CompIconUrlImage" alt="Logo" class="logo logo-Dashbord">
      </div>
    </a>
    <!-- Divider -->
    <hr class="sidebar-divider my-0">
    <!-- Nav Item - Dashboard -->
    <li class="nav-item active">
      <a class="nav-link" href="javascript:void(0);" (click)="OnDashboard()">
        <i class="fas fa-fw fa-tachometer-alt"></i>
        <span>Dashboard</span>
      </a>
    </li>
    <!-- Divider -->
    <hr class="sidebar-divider">
    <!-- Heading -->
    <div class="sidebar-heading">Sub Menu</div>
    <li class="nav-item" *ngFor="let menu of SubMenues; let i=index">
      <a [ngClass]="{ 'menustyle tablinks': true, 'current': selectedIndex == i }" class="nav-link" href="javascript:void(0);" (click)="LoadComponents(menu.RouteName, i,menu.MenuId)">
        {{menu.MenuTitle}}
      </a>
    </li>
    <hr class="sidebar-divider d-none d-md-block">
    <div class="text-center d-none d-md-inline">
      <button class="rounded-circle border-0" id="sidebarToggle"></button>
    </div>
  </ul>
  <!-- End of Sidebar -->
  <!-- Content Wrapper -->
  <div id="content-wrapper" class="d-flex flex-column">
    <!-- Main Content -->
    <div id="content">
      <!-- Topbar -->
      <nav class="navbar navbar-expand navbar-light bg-white topbar mb-4 static-top shadow">
        <!-- Sidebar Toggle (Topbar) -->
        <button id="sidebarToggleTop" class="btn btn-link d-md-none rounded-circle mr-3">
          <i class="fa fa-bars"></i>
        </button>
        <div class="offset-lg-5 col-lg-4">
          <div class="text-right header-text">
            <span>
              Last Login Time: {{UserModel.LastLoginTime | date : "dd-MMM-yyyy hh:mm:ss a"}} 
            </span>
            <span>/</span>
            <span> IP: {{UserModel.IPAddress}}</span>
          </div>
        </div>
        <!-- Topbar Navbar -->
        <ul class="navbar-nav ml-auto">
          <!-- Nav Item - Alerts -->
          <li class="nav-item dropdown no-arrow mx-1">
            <a class="nav-link dropdown-toggle" href="javascript:void(0);" id="alertsDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              <i class="fas fa-bell fa-fw"></i>
              <!-- Counter - Alerts -->
              <span class="badge badge-danger badge-counter">{{this.UserModel.TotalAllApplication}}</span>
            </a>
            <!-- Dropdown - Alerts -->
            <div *ngIf="this.Notifications.length > 0" class="dropdown-list dropdown-menu dropdown-menu-right shadow animated--grow-in" aria-labelledby="alertsDropdown">
              <h6 class="dropdown-header">Notification Alerts</h6>
              <li class="nav-item" *ngFor="let Noti of Notifications">
                <a class="nav-link" href="javascript:void(0);" (click)="PageRedirectClick(Noti.ModuleId,Noti.MainMenuId,Noti.MenuId)">
                  {{Noti.TotalApplication}} {{Noti.ApplicationType}}
                </a>
              </li>
            </div>
          </li>

        <div class="topbar-divider d-none d-sm-block"></div>
          <!-- Nav Item - User Information -->
          <li class="nav-item dropdown no-arrow">
            <a class="nav-link dropdown-toggle" href="javascript:void(0);" id="userDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              <span class="mr-2 d-none d-lg-inline text-gray-600 small">{{this.UserModel.EmployeeName}}</span>
              <img *ngIf="this.UserModel.ImageType!=null" [src]="this.UserModel.ImageFile" class="img-fluid" style="height: 80%;">
            </a>
           
            <!-- User Information -->
            <div class="dropdown-menu dropdown-menu-right shadow animated--grow-in" aria-labelledby="userDropdown">
              <a class="dropdown-item" href="javascript:void(0);" (click)="PageRedirectClick(10,66,67)">
                <i class="fas fa-user fa-sm fa-fw mr-2 text-gray-400"></i>
                Profile
              </a>
              <a class="dropdown-item" href="javascript:void(0);" (click)="PageRedirectClick(10,13,10134)">
                <i class="fas fa-cogs fa-sm fa-fw mr-2 text-gray-400"></i>
                Password Settings
              </a>

              <div class="dropdown-divider"></div>

              <a class="header-icons power_off" href="javascript:void(0);" data-toggle="modal" (click)="Logout()">
                <i class="fas fa-power-off"></i>
                Logout
              </a>
            </div>
          </li>
        </ul>
      </nav>
      <!-- End of Topbar -->

      <!-- Begin Page Content -->
      <div class="container-fluid">
          <div class="main-content-area">
              <div class="body-nav-area" id="container">
                  <div id="container">
                      <div ng2-carouselamos class="slides-wrapper"
                              [items]="MainMenu"
                              [width]="1030"
                              [$prev]="prev"
                              [$next]="next"
                              [$item]="item" >  
                      </div>
                      <ng-template #prev>
                          <img src="assets/img/Left_arrow.png" id="left" style="width:35px;">
                      </ng-template>
                      <ng-template #next>
                          <img src="assets/img/Right_arrow.png" id="right" style="width:35px;">
                      </ng-template>
                      <ul class="body-nav-ul owl-carousel" >
                          <ng-template #item let-item let-i="index"; let j="index" >
                              <li  *ngFor="let tempmenu of MainMenu; let j=index" [ngClass]="{'menu-item-li ': true, 'active-menu-item': selecteModule == j}" (click)="MainPages( tempmenu.MenuId,tempmenu.ModuleId,tempmenu.RouteName ,j)">
                                  <div class="menu-slider-icons">
                                      <img src={{tempmenu.SmallImage}} alt="Image1" class="icon-size">
                                  </div>
                                  <span class="menu-item-text">{{tempmenu.MenuTitle}}</span>
                              </li>
                          </ng-template>
                      </ul>
                  </div>
              </div>
              <router-outlet> </router-outlet>
          </div>
      </div>
      <!-- /.container-fluid -->

    </div>
    <!-- End of Main Content -->

    <!-- Footer -->
    <footer class="sticky-footer">
      <div class="container my-auto">
        <div class="copyright text-center my-auto">
          <span>Copyright © 1997-2018. All Rights Reserved</span>
        </div>
      </div>
    </footer>
    <!-- End of Footer -->

  </div>
  <!-- End of Content Wrapper -->
</div>