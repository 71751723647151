<div class="body-content-area tabcontent" id="link1">
  <div class="row">
    <div class="col-lg-9">
      <div class="row">
        <div class="col-lg-1 low-padding text-center">
          <!-- <img *ngIf="this.SelectedMenu.Image!=null" src={{this.SelectedMenu.Image}} class="body-icon" alt="Icon"/> -->
        </div>
        <div class="col-lg-10 clear-padding">
          <span class="page-title">Employee Work Anniversary</span>
          <small class="page-description"></small>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="!(this.CanViewState)">
    <div>
      <div class="row">
        <div class="col-lg-4">
          <div class="form-group row">
            <label class="col-sm-4 col-form-label clear-padding-right">Anniversary Date</label>
            <div class="col-sm-7 clear-left-padding">
            <input type="date" name="WorkAnniversary" [ngModel]="this.BirthDayAnniversaryModel.WorkAnniversary | date:'yyyy-MM-dd'" (ngModelChange)="DateChanged($event)" #WorkAnniversary="ngModel" required/>
          </div>
        </div>
        </div>
      </div>
    </div>
    <fieldset class="body-form-control">
      <legend class="legend--">Employee Work Anniversary List</legend>
      <table id="tblExpenseHead" class="table table-striped table-bordered text-center table-new row-border hover">
        <thead class="thead-blue text-center">
          <tr>
            <th>Sl No.</th>
            <th>Id</th>
            <th>Name</th>
            <th>Anniversary Date</th>
            <th>Company</th>
            <th>Branch</th>
            <th>Department</th>
            <th>Designation</th>
            <th>Contact Number</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let WA of WorkAnniversarys, let i = index">
            <td scope="row">{{i+1}}</td>
            <td class="text-left">{{WA.EmployeeCode}}</td>
            <td class="text-left">{{WA.EmployeeName}}</td>
            <td class="text-left">{{WA.WorkAnniversary | date:'dd/MMM/yyyy'}}</td>
            <td class="text-left">{{WA.CompanyName}}</td>
            <td class="text-left">{{WA.BranchName}}</td>
            <td class="text-left">{{WA.DepartmentName}}</td>
            <td class="text-left">{{WA.DesignationName}}</td>
            <td class="text-left">{{WA.MobileNumber}}</td>
            <td>
              <button *ngIf="this.SelectedMenu.CanView" type="button" (click)="ViewWorkAnniversaryDetailsForMail(WA.EmployeeCode,WA.EmployeeName,WA.EmployeeEmailId,template)" class="table-view-button" type="button" ><i class="fas fa-eye"></i></button>
            </td>
          </tr>
        </tbody>
        <tfoot class="thead-blue text-center">
          <tr>
            <th></th>
            <th></th>
            <th></th>
            <th></th>
            <th></th>
            <th></th>
            <th></th>
            <th></th>
            <th></th>
            <th></th>
          </tr>
        </tfoot>
      </table>
    </fieldset>
  
    <div style=" box-align: center; width: 1500px;">
      <ng-template #template>
        <div class="modal-header" style="background: #2a9bd7; max-width: 1500px;"> 
          <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()" style="color:#fff;">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div  style="text-align: center; max-width: 1500px;">
        <div *ngIf="MsgSent" style="color: #dc3545; text-align: center;font-size: 15px; font-weight: bold;">Mail Sent Successfully</div>
        <div *ngIf="MsgnotSent" style="color: #dc3545; text-align: center;font-size: 15px; font-weight: bold;">Mail Sent failed</div>
        <div style=" width: 100%;" *ngIf="ShowPopup">
          <fieldset class="body-form-control">
            <legend class="legend--">Employee Work Anniversary Wishes</legend>
            <form #WorkAnniversaryForm="ngForm" (ngSubmit)="WorkAnniversaryMailFarward(template)">
              <div class="row">
                <div class="col-lg-4">
                  <div class="col-body-border-right">
                    <div class="form-group row">
                      <label class="col-sm-4 col-form-label clear-padding-right">Id</label>
                      <div class="col-sm-7 clear-left-padding">
                        :&nbsp;&nbsp;{{this.BirthDayAnniversaryModel.EmployeeCode}}
                      </div>
                    </div>
                    <div class="form-group row">
                      <label class="col-sm-4 col-form-label clear-padding-right">Message</label>
                      <div class="col-sm-7 clear-left-padding">
                        <textarea [(ngModel)]="this.BirthDayAnniversaryModel.Message" name="Message" mdInput placeholder="Message" rows="3" #Message="ngModel" required class="form-control form-control-custom form-control-custom-new"></textarea>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-lg-4">
                  <div class="col-body-border-right">
                    <div class="form-group row">
                      <label class="col-sm-4 col-form-label clear-padding-right">Name</label>
                      <div class="col-sm-7 clear-left-padding">
                        :&nbsp;&nbsp;{{this.BirthDayAnniversaryModel.EmployeeName}}
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <div class="form-group row">
                    <label class="col-sm-4 col-form-label clear-padding-right">Mail Id</label>
                    <div class="col-sm-7 clear-left-padding">
                      :&nbsp;&nbsp;{{this.BirthDayAnniversaryModel.EmployeeEmailId}}
                    </div>
                  </div>
                  <div class="form-group row">
                    <label class="col-sm-4 col-form-label clear-padding-right">&nbsp;</label>
                    <label *ngIf="!(this.IsDataIsInViewState)" class="col-sm-7 col-form-label text-center clear-padding-right">
                      <button type="submit" class="btn btn-primary save-button" [disabled]="!(WorkAnniversaryForm.valid && this.DisableSave==true)" >Mail</button>  
                    </label>
                  </div>
                </div>
              </div>
            </form>
          </fieldset>
        </div>
        <button type="button" aria-label="Close" (click)="modalRef.hide()" style="background: #2a9bd7; outline: 0px none; border: 0px none; color: #fff; padding: 5px 10px; border-radius: 3px;">
          <span>Close</span>
        </button>             
      </div>
    </ng-template>
  </div>
</div>
</div>