<fieldset class="body-form-control" style="    border: none;">
    <app-dialog [(visible)]="this.SuccessStatus">
        <span class="successmsg">{{this.SuccessMessage}}</span>
    </app-dialog>

    <legend class="legend--">Contact Details</legend>
    <form #ContactsForm="ngForm" (ngSubmit)="SaveContactdetails()">
        <div lass="row">
            <table id="tblComponent" class="table table-striped table-bordered text-left table-new row-border hover">
                <thead class="thead-blue text-center">
                    <tr>
                        <th colspan="4" style="width: 48%;">Contact Name </th>
                        <th style="width: 10%;">Title</th>
                        <th style="width: 10%;">Phone</th>
                        <th style="width: 10%;">Email</th>
                        <th style="width: 14%;">Role</th>

                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let Contacts of ContactsList, let i = index">
                        <td>


                            <select [(ngModel)]="Contacts.MR" tabindex="MR_{{i}}"
                                (ngModelChange)="onChangeMister($event,i)" id="MR_{{i}}"
                                class="form-control option_width" name="MR_{{i}}" #MR="ngModel">
                                <option [selected]="true" [value]="0">Mr</option>
                                <option [value]="1">Mrs</option>
                                <option [value]="2">Ms</option>
                                <option [value]="3">Dr</option>
                            </select>
                        </td>
                        <td>
                            <input type="text" [(ngModel)]="Contacts.FirstName" placeholder="First Name" maxlength="100"
                                id="FirstName_{{i}}" name="FirstName_{{i}}" #FirstNameName="ngModel"
                                class="form-control form-control-custom form-control-custom-new"
                                tabindex="FirstName_{{i}}">
                        </td>
                        <td>
                            <input type="text" [(ngModel)]="Contacts.MiddleName" placeholder="Middle Name"
                                maxlength="100" id="MiddleName_{{i}}" name="MiddleName_{{i}}" #MiddleName="ngModel"
                                class="form-control form-control-custom form-control-custom-new"
                                tabindex="MiddleName_{{i}}">
                        </td>
                        <td>
                            <input type="text" [(ngModel)]="Contacts.LastName" placeholder="Last Name" maxlength="100"
                                id="LastName_{{i}}" name="LastName_{{i}}" #LastName="ngModel"
                                class="form-control form-control-custom form-control-custom-new"
                                tabindex="LastName_{{i}}">
                        </td>

                        <td>
                            <input type="text" [(ngModel)]="Contacts.Title" placeholder="Title" maxlength="200"
                                id="Title_{{i}}" name="Title_{{i}}" #Title="ngModel"
                                class="form-control form-control-custom form-control-custom-new" tabindex="Title_{{i}}">
                        </td>

                        <td>


                            <input NumberOn [(ngModel)]="Contacts.PhoneNo" maxlength="12" id="PhoneNo_{{i}}"
                                name="PhoneNo_{{i}}" #PhoneNo="ngModel"
                                class="form-control form-control-custom form-control-custom-new"
                                tabindex="PhoneNo_{{i}}">
                        </td>

                        <td>
                            <!-- <input type="text" [(ngModel)]="Contacts.EmailId" placeholder="EmailId" maxlength="50"
                                id="EmailId_{{i}}" name="EmailId_{{i}}" #EmailId="ngModel" tabindex="EmailId_{{i}}"
                                class="form-control form-control-custom form-control-custom-new" /> -->
                            <input Emailcheck [(ngModel)]="Contacts.EmailId" placeholder="EmailId" maxlength="50"
                                id="EmailId_{{i}}" name="EmailId_{{i}}" #EmailId="ngModel" tabindex="EmailId_{{i}}"
                                class="form-control form-control-custom form-control-custom-new" />


                        </td>

                        <td>
                            <select [(ngModel)]="Contacts.RollID" tabindex="15" id="RollID_{{i}}" class="form-control"
                                name="RollID_{{i}}" #RollID="ngModel" tabindex="RollID_{{i}}">
                                <option [value]="0" [disabled]=true>Select Role</option>
                                <option *ngFor="let R of DesignationList" [value]="R.DesigID">{{R.Designation}}</option>
                            </select>

                        </td>


                    </tr>
                </tbody>
            </table>

            <div class="row float-right">
                <div class=" col-auto text-center" *ngIf="IsinViewState==false">


                    <button type="submit" tabindex="22" class="btn btn-primary save-button">Save </button>
                </div>
                <div class=" col-auto text-center">
                    <button type="button" tabindex="23" class="btn btn-danger cancle-button"
                        (click)="RefreshPage()">Cancel</button>
                </div>

            </div>
        </div>
    </form>
</fieldset>