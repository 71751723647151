   
    export class Model_CRM_Roll_Menus
    {      
  
        public  RoleMenuId :number;
        public  MenuId :number;
        public  ParentId : number;
        public  MenuName : string;
        public  Role_Id : number;
        public  CanAccess : boolean;
        public  ViewCanAccess : string;
        public  CanAdd: boolean;
        public  ViewCanAdd: string;
        public  CanEdit :boolean;
        public  ViewCanEdit: string;
        public  CanDelete : boolean;
        public  ViewCanDelete: string;
        public  CanApprove : boolean;
        public  ViewCanApprove : string;
        public  CanView : boolean;
        public  ViewCanView : string;
        public  CanReject : boolean;
        public  ViewCanReject : string;
        public  CanTransfer : boolean;
        public  ViewCanTransfer : string;
        public  IsActive : boolean;
    
    }
  