import { Component, OnInit, TemplateRef } from '@angular/core';
import { Router } from '@angular/router';
import { EmployeeMasterService }  from '../../../shared/HR_ADMIN/Employee/EmployeeMaster.Service';
import { ModalOptions,BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
//Begin Organisation & Levels(Model & Service)
import { Country } from'../../../shared/HR_ADMIN/Location/Country.Model';
import { State } from '../../../shared/Organization/State.Model';
import { District } from'../../../shared/HR_ADMIN/Location/District.Model';
import { City } from'../../../shared/HR_ADMIN/Location/City.Model';
import { Zone } from '../../../shared/Organization/Zone.Model';
import { OrganizationService }  from '../../../shared/Organization/organization.service';
import { LocationService }  from '../../../shared/HR_ADMIN/Location/location.service';
import { Company } from '../../../shared/Organization/Company.Model';
import { NotificationsService} from '../../../shared/notifications.service';

@Component({
  selector: 'app-employee-address-details',
  templateUrl: './employee-address-details.component.html',
  styleUrls: ['./employee-address-details.component.css']
})
export class EmployeeAddressDetailsComponent implements OnInit 
{
  CountryModel : Country= new Country();
  StateModel : State= new State();
  DistrictModel : District= new District();
  CityModel : City= new City();
  Companies: Company[] = [];
  Countries_P : Country[]=[];
  Countries_C : Country[]=[];
  States_P : State[]=[];
  States_C : State[]=[];
  Zones : Zone[] = [];
  Dists_P : District[] =[];
  Dists_C : District[] =[];
  Cities_P : City[]=[];
  Cities_C : City[]=[];
  Comp: any;
  CityP : any;
  CityC : any;
  StateP : any;
  StateC : any;
  Zone : any;
  countryP : any;
  countryC : any;
  DistP : any;
  DistC : any;
  AddressRefID:number;
  SuccessStatus:boolean=false;
  SuccessMessage: string ="";
  Tabindex:number=0;

  Address1 : string="";
  Address2 : string="";
  ContactNo : string="";
  Country : number=0;
  State : number=0;
  District : number=0;
  City : number=0;
  PinCode : string="";
  public modalRef: BsModalRef;

  Countries: Country[] = [];
  country : any;
  States: State[] = [];
  state: any;
  Cities: City[] = [];
  city: any;
  Districts: District[] = [];
  district:any;
  //check for duplicate District Name
  CheckNameExiststatus : boolean=null;  
  CheckNamestatusedit : boolean =false;
  Namecheck : string ="";
  checkName : any;
  checkNameval : string ="";
  //check for duplicate Country Name
  CountryCheckNameExiststatus : boolean=null;  
  CountryNamecheck : string ="";
  CountrycheckName : any;
  //check for duplicate State Name
  StateCheckNameExiststatus : boolean=null;  
  StateNamecheck : string ="";
  StatecheckName : any;
  //check for duplicate City Name
  CityCheckNameExiststatus : boolean=null;  
  CityNamecheck : string ="";
  CitycheckName : any;
  //check for duplicate Name
  ShowCountry : boolean=false;
  ShowState : boolean=false;
  ShowDistrict : boolean=false;
  ShowCity : boolean=false;
  DisableSave : boolean=false;
  public modelopt:ModalOptions;

  constructor(public EmployeeMasterService: EmployeeMasterService,private organizationService: OrganizationService, private rout: Router,
    private LocationService: LocationService,private modalService: BsModalService, private notificationsService: NotificationsService) 
  {
    this.modelopt=new ModalOptions();
    this.modelopt.class='heavy_modal';
    this.modalService.config= this.modelopt;
    this.modelopt.ignoreBackdropClick=true;
  }

  ngOnInit() 
  {   
    if( this.EmployeeMasterService.SaveOrUpdate==1)
    {
      localStorage.setItem('SuccessfullMessage', "Save Successfully");
      this.EmployeeMasterService.SaveOrUpdate=0;
    }
    if( this.EmployeeMasterService.SaveOrUpdate==2)
    {
      localStorage.setItem('SuccessfullMessage', "Update Successfully");
      this.EmployeeMasterService.SaveOrUpdate=0;
    }
    if(localStorage.getItem('SuccessfullMessage'))
    {
      this.SuccessStatus=true;
      this.SuccessMessage =localStorage.getItem('SuccessfullMessage')
      localStorage.removeItem('SuccessfullMessage');
    } 
    this.getCountries_C();
    this.getCountries_P();
    if(this.EmployeeMasterService.SharedEmployees.PCountryId>0)
    {
      this.getStates_P(this.EmployeeMasterService.SharedEmployees.PCountryId);
    }
    if(this.EmployeeMasterService.SharedEmployees.CCountryId>0)
    {
      this.getStates_C(this.EmployeeMasterService.SharedEmployees.CCountryId);
    }
    if(this.EmployeeMasterService.SharedEmployees.PStateId>0)
    {
      this.getDists_p(this.EmployeeMasterService.SharedEmployees.PStateId);
    }
    if(this.EmployeeMasterService.SharedEmployees.CStateId>0)
    {
      this.getDists_c(this.EmployeeMasterService.SharedEmployees.CStateId);
    }
    if(this.EmployeeMasterService.SharedEmployees.PDistrictId>0)
    {
      this.getCities_P(this.EmployeeMasterService.SharedEmployees.PDistrictId);
    }
    if(this.EmployeeMasterService.SharedEmployees.CDistrictId>0)
    {
      this.getCities_C(this.EmployeeMasterService.SharedEmployees.CDistrictId);
    }
    this.Address1 =this.EmployeeMasterService.SharedEmployees.PAddress1;
    this.Address2 =this.EmployeeMasterService.SharedEmployees.PAddress2;
    this.ContactNo =this.EmployeeMasterService.SharedEmployees.PContactNo;
    this.Country =this.EmployeeMasterService.SharedEmployees.PCountryId;
    this.State =this.EmployeeMasterService.SharedEmployees.PStateId;
    this.District =this.EmployeeMasterService.SharedEmployees.PDistrictId;
    this.City =this.EmployeeMasterService.SharedEmployees.PCityId;
    this.PinCode =this.EmployeeMasterService.SharedEmployees.PPincode;
    this.ShowCountry=false;
    this.ShowState=false;
    this.ShowDistrict=false;
    this.ShowCity=false;
    this.DisableSave=true;
  }

  getCities_P(Distid) 
  {
    this.Cities_P=[];
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t
    if(this.Cities_P.length>0)
    {
      this.Cities_P.splice(0,this.Cities_P.length )
    }
    this.LocationService.GetCityByDistrictid( t,Distid).then(x => 
    {
      this.CityP = x;
      Object.keys(this.CityP).forEach( key => 
      {
        // tslint:disable-next-line:no-unused-expression
        this.CityP[key];
        let Citytemp = new City();
        Citytemp = this.CityP[key];
        this.Cities_P.push(Citytemp);
      });     
    });      
  }

  getCities_C(Distid) 
  {
    this.Cities_C=[];
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t
    if(this.Cities_C.length>0)
    {
      this.Cities_C.splice(0,this.Cities_C.length )
    }
    this.LocationService.GetCityByDistrictid( t,Distid).then(x => 
    {
      this.CityC = x;
      Object.keys(this.CityC).forEach( key => 
      {
        // tslint:disable-next-line:no-unused-expression
        this.CityC[key];
        let Citytemp = new City();
        Citytemp = this.CityC[key];
        this.Cities_C.push(Citytemp);
      });     
    });      
  }
 
  getCountries_P() 
  {
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.organizationService.GetCountry( t).then(x => 
    {
      this.Countries_P=[];
      this.countryP = x;
      Object.keys(this.countryP).forEach( key => 
      {
        // tslint:disable-next-line:no-unused-expression
        this.countryP[key];
        let Countrytemp = new Country();
        Countrytemp = this.countryP[key];
        this.Countries_P.push(Countrytemp);
      });     
    });      
  }

  getCountries_C() 
  {
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.organizationService.GetCountry( t).then(x => 
    {
      this.Countries_C=[];
      this.countryC = x;
      Object.keys(this.countryC).forEach( key => 
      {
        // tslint:disable-next-line:no-unused-expression
        this.countryC[key];
        let Countrytemp = new Country();
        Countrytemp = this.countryC[key];
        this.Countries_C.push(Countrytemp);
      });     
    });      
  }

  onCountryChange_C(countryid) 
  {
    this.EmployeeMasterService.SharedEmployees.CStateId=0;
    this.getStates_C(countryid);
    this.getDists_c(0);
    this.getCities_C(0);
  }

  onCountryChange_P(countryid) 
  {
    this.EmployeeMasterService.SharedEmployees.PStateId=0;
    this.getStates_P(countryid);
    this.getDists_p(0);
    this.getCities_P(0);
  }

  getStates_P( countryid) 
  {
    this.States_P=[];
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t
    if(this.States_P.length>0)
    {
      this.States_P.splice(0, this.States_P.length);
    }
    this.organizationService.GetState( t,countryid).then(x => 
    {
      this.States_P=[];
      this.StateP = x;
      Object.keys(this.StateP).forEach( key => 
      {
        // tslint:disable-next-line:no-unused-expression
        let Statetemp = new State();
        Statetemp = this.StateP[key];
        this.States_P.push(Statetemp);
      });     
    });      
  }

  getStates_C( countryid) 
  {
    this.States_C=[];
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t
    if(this.States_C.length>0)
    {
      this.States_C.splice(0, this.States_C.length);
    }
    this.organizationService.GetState( t,countryid).then(x => 
    {
      this.States_C=[];
      this.StateC = x;
      Object.keys(this.StateC).forEach( key => 
      {
        // tslint:disable-next-line:no-unused-expression
        let Statetemp = new State();
        Statetemp = this.StateC[key];
        this.States_C.push(Statetemp);
      });     
    });      
  }

  onstatechange_p(stateid)
  {
    this.EmployeeMasterService.SharedEmployees.PDistrictId=0;
    this.getDists_p(stateid);
  }

  onstatechange_c(stateid)
  {
    this.EmployeeMasterService.SharedEmployees.CDistrictId=0;
    this.getDists_c(stateid);      
  }

  getDists_p(stateid) 
  {
    this.Dists_P=[];
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    if(this.Dists_P.length>0)
    {
      this.Dists_P.splice(0, this.Dists_P.length);
    }
    this.LocationService.GetDistrictByStateid( t,stateid).then(x => 
    {
      this.Dists_P=[];
      this.DistP = x;
      Object.keys(this.DistP).forEach( key => 
      {
        // tslint:disable-next-line:no-unused-expression
        this.DistP[key];
        let Disttemp = new District();
        Disttemp = this.DistP[key];
        this.Dists_P.push(Disttemp);
      });     
    });      
  }

  getDists_c(stateid) 
  {
    this.Dists_C=[];
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    if(this.Dists_C.length>0)
    {
      this.Dists_C.splice(0, this.Dists_C.length);
    }
    this.LocationService.GetDistrictByStateid( t,stateid).then(x => 
    {
      this.Dists_C=[];
      this.DistC = x;
      Object.keys(this.DistC).forEach( key => 
      {
        // tslint:disable-next-line:no-unused-expression
        let Disttemp = new District();
        Disttemp = this.DistC[key];
        this.Dists_C.push(Disttemp);
      });     
    });      
  }

  onDistrictchange_P(Districtid)
  {
    this.EmployeeMasterService.SharedEmployees.PCityId=0;
    this.getCities_P(Districtid);
  }

  onDistrictchange_C(Districtid)
  {
    this.EmployeeMasterService.SharedEmployees.CCityId=0;
    this.getCities_C(Districtid);      
  }

  getZones() 
  {
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.organizationService.GetZone( t).then(x => 
    {
      this.Zone = x;
      Object.keys(this.Zone).forEach( key => 
      {
        // tslint:disable-next-line:no-unused-expression
        this.Zone[key];
        let Zonetemp = new Zone();
        Zonetemp = this.Zone[key];
        this.Zones.push(Zonetemp);
      });     
    });      
  }

  SaveEmpolyeeAddressDetails()
  {
    this.DisableSave=false;
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.EmployeeMasterService.SharedEmployees.IsActive=true;
    if(this.EmployeeMasterService.SharedEmployees.AddressRefId> 0)
    {
      this.EmployeeMasterService.UpdateEmployeeAddressDetails(t ,this.EmployeeMasterService.SharedEmployees).subscribe(res=>
      {
        this.AddressRefID=Number(res);
        if(this.AddressRefID>0)
        {
          //localStorage.setItem('SuccessfullMessage', "Update Successfully");
          this.EmployeeMasterService.SaveOrUpdate=2;
          this.Tabindex=2         
          this.notificationsService.emit(this.Tabindex);
          this.EmployeeMasterService.SaveOrUpdate=2;
          this.rout.navigateByUrl('/Employee/EmployeeDetails/OthersDetails');
        }
      });                  
    }                             
    else
    {
      this.EmployeeMasterService.AddEmployeeAddressDetails(t ,this.EmployeeMasterService.SharedEmployees).subscribe(res=>
      {
        this.AddressRefID=Number(res);
        if(this.AddressRefID>0)
        {
          this.EmployeeMasterService.SaveOrUpdate=1;
          this.Tabindex=2  
          this.notificationsService.emit(this.Tabindex);     
          this.EmployeeMasterService.SaveOrUpdate=1;
          this.EmployeeMasterService.SharedEmployees.AddressRefId=this.AddressRefID; 
         // localStorage.setItem('SuccessfullMessage', "Save Successfully");
          this.rout.navigateByUrl('/Employee/EmployeeDetails/OthersDetails');
        }
      }); 
    }
  }

  SameAddressChange()
  {
    if(this.EmployeeMasterService.SharedEmployees.SameAddress==true)
    {
      this.EmployeeMasterService.SharedEmployees.PAddress1 = this.EmployeeMasterService.SharedEmployees.CAddress1;
      this.EmployeeMasterService.SharedEmployees.PAddress2 = this.EmployeeMasterService.SharedEmployees.CAddress2;
      this.EmployeeMasterService.SharedEmployees.PContactNo = this.EmployeeMasterService.SharedEmployees.CContactNo;
      this.EmployeeMasterService.SharedEmployees.PCountryId = this.EmployeeMasterService.SharedEmployees.CCountryId;
      this.getStates_P(this.EmployeeMasterService.SharedEmployees.PCountryId);
      this.EmployeeMasterService.SharedEmployees.PStateId = this.EmployeeMasterService.SharedEmployees.CStateId;
      this.getDists_p(this.EmployeeMasterService.SharedEmployees.PStateId);
      this.EmployeeMasterService.SharedEmployees.PDistrictId = this.EmployeeMasterService.SharedEmployees.CDistrictId;
      this.getCities_P(this.EmployeeMasterService.SharedEmployees.PDistrictId);
      this.EmployeeMasterService.SharedEmployees.PCityId = this.EmployeeMasterService.SharedEmployees.CCityId;
      this.EmployeeMasterService.SharedEmployees.PPincode = this.EmployeeMasterService.SharedEmployees.CPincode;
    }
    else
    {
      this.EmployeeMasterService.SharedEmployees.PAddress1=this.Address1;
      this.EmployeeMasterService.SharedEmployees.PAddress2=this.Address2;
      this.EmployeeMasterService.SharedEmployees.PContactNo =this.ContactNo;
      this.EmployeeMasterService.SharedEmployees.PCountryId =this.Country;
      this.getStates_P(this.Country);
      this.EmployeeMasterService.SharedEmployees.PStateId = this.State;
      this.getDists_p(this.State);
      this.EmployeeMasterService.SharedEmployees.PDistrictId =this.District;
      this.getCities_P(this.District);
      this.EmployeeMasterService.SharedEmployees.PCityId = this.City;
      this.EmployeeMasterService.SharedEmployees.PPincode = this.PinCode;
    }
  }

  RefreshPage()
  {
    this.rout.navigateByUrl('/Employee/EmployeeOffer', {skipLocationChange: true}).then(()=>this.rout.navigate(["/Employee/EmployeeDetails"]));
  }

  //Model Popup
  public openModalCountry(template: TemplateRef<any>) 
  {
    this.modalRef = this.modalService.show(template);
    this.ShowCountry=true;
    this.ShowState=false;
    this.ShowDistrict=false;
    this.ShowCity=false;
  }

  public openModalState(template: TemplateRef<any>) 
  {
    this.modalRef = this.modalService.show(template);
    this.ShowCountry=false;
    this.ShowState=true;
    this.ShowDistrict=false;
    this.ShowCity=false;
    this.getCountry();
    this.StateModel.CountryId=0;
    this.Zones=[];
  }

  public openModalDistrict(template: TemplateRef<any>) 
  {
    this.modalRef = this.modalService.show(template);
    this.getCountry();
    this.DistrictModel.CountryId=0;
    this.States=[];
    this.ShowCountry=false;
    this.ShowState=false;
    this.ShowDistrict=true;
    this.ShowCity=false;
  }

  public openModalCity(template: TemplateRef<any>) 
  {
    this.modalRef = this.modalService.show(template);
    this.ShowCountry=false;
    this.ShowState=false;
    this.ShowDistrict=false;
    this.ShowCity=true;
    this.getCountry();
    this.CityModel.CountryId=0;
    this.States=[];
    this.Districts=[];
  }
  //Country
  saveCountry() :void
  {
    this.DisableSave=false;
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.CountryModel.IsActive=true;
    this.LocationService.AddCountry(t ,this.CountryModel).subscribe(res=>
    {
      this.getCountries_C();
      this.getCountries_P();
      this.CountryModel.CountryCode="";
      this.CountryModel.CountryName="";
      this.CountryModel.LanguageCode="";
      this.CountryModel.LanguageName="";
      this.CountryModel.CurrencyCode="";
      this.CountryModel.CurrencyName="";  
      this.DisableSave=true;              
    });
  }

  CheckCountryName()
  {
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.LocationService.CheckCountryName( t, this.CountryModel.CountryName).then(x => 
    {
      this.CountrycheckName = x;
      if( this.CountrycheckName.length >0)
      {
        this.CountryCheckNameExiststatus =false;
        this.CountryNamecheck="Exist"
      }
      else
      {
        this.CountryCheckNameExiststatus =true;
        this.CountryNamecheck="";
      }
    });
  }
  //State
  getCountry() 
  {
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.organizationService.GetCountry( t).then(x => 
    {
      this.Countries=[];
      this.country = x;
      Object.keys(this.country).forEach( key => 
      {
        this.country[key];
        let countrytemp = new Country();
        countrytemp = this.country[key];
        this.Countries.push(countrytemp);
      });
    });      
  } 

  onCountryChange(countryid) 
  {
    if(countryid!=0)
    {
      this.getZoneByCompanyid(countryid);
      this.StateModel.ZoneId=0;
    }
    else
    {
      this.getZoneByCompanyid(countryid);
      this.StateModel.ZoneId=0;
    }
  }

  getZoneByCompanyid( countryid) 
  {
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t
    this.LocationService.GetZoneByCountryId( t,countryid).then(x => 
    {
      this.Zone = x;
      Object.keys(this.Zone).forEach( key => 
      {
        this.Zone[key];
        let zonetemp = new Zone();
        zonetemp = this.Zone[key];
        this.Zones.push(zonetemp);
      });     
    });      
  }

  saveState() :void
  {
    this.DisableSave=false;
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.StateModel.IsActive=true;
    this.LocationService.AddState(t ,this.StateModel).subscribe(res=>
    {
      this.getStates_C(this.EmployeeMasterService.SharedEmployees.CCountryId);
      this.getStates_P(this.EmployeeMasterService.SharedEmployees.PCountryId);
      this.StateModel.StateCode="";
      this.StateModel.StateName="";
      this.StateModel.CountryId=0;
      this.StateModel.ZoneId=0;
      this.DisableSave=true;
    }); 
  }

  CheckStateName()
  {
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.LocationService.CheckStateName( t, this.StateModel.StateName,this.StateModel.CountryId).then(x => 
    {
      this.StatecheckName = x;
      if( this.StatecheckName.length >0)
      {
        this.StateCheckNameExiststatus =false;
        this.StateNamecheck="Exist"
      }
      else
      {
        this.StateCheckNameExiststatus =true;
        this.StateNamecheck="";
      }
    });
  }
  //District
  getStates( countryid) 
  {
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t
    this.organizationService.GetState( t,countryid).then(x => 
    {
      this.States=[];
      this.state = x;
      Object.keys(this.state).forEach( key => 
      {
        this.state[key];
        let Statetemp = new State();
        Statetemp = this.state[key];
        this.States.push(Statetemp);
      });     
    });      
  }

  onChange(countryid) 
  {
    if(countryid!=0)
    {
      this.getStates(countryid);
      this.DistrictModel.StateId=0;
    }
    else
    {
      this.getStates(countryid);
      this.DistrictModel.StateId=0;
    }
  }

  saveDistrict() :void
  {
    this.DisableSave=false;
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.DistrictModel.IsActive=true;
    this.LocationService.AddDistrict(t ,this.DistrictModel).subscribe(res=>
    {
      this.getDists_c(this.EmployeeMasterService.SharedEmployees.CStateId);
      this.getDists_p(this.EmployeeMasterService.SharedEmployees.PStateId);
      this.DistrictModel.DistCode="";
      this.DistrictModel.DistName="";
      this.DistrictModel.CountryId=0;
      this.DistrictModel.StateId=0;
      this.DisableSave=true;
    }); 
  }

  CheckName()
  {
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    if(!this.CheckNamestatusedit)
    {
      this.LocationService.CheckDistrictName( t, this.DistrictModel.DistName).then(x => 
      {
        this.checkName = x;
        if( this.checkName.length >0)
        {
          this.CheckNameExiststatus =false;
          this.Namecheck="Exist"
        }
        else
        {
          this.CheckNameExiststatus =true;
          this.Namecheck="";
        }
     });
    }
    else
    {
      if(this.DistrictModel.DistName !=this.checkNameval )
      {
        this.LocationService.CheckDistrictName( t, this.DistrictModel.DistName).then(x => 
        {
          this.checkName = x;
          if( this.checkName.length >0)
          {
            this.CheckNameExiststatus =false;
            this.Namecheck="Exist"
          }
          else
          {
            this.CheckNameExiststatus =true;
            this.Namecheck="";
          }
        });
      }
      else
      {
        this.CheckNameExiststatus =true;
        this.Namecheck="";
      }
    }
  }
  //City
  getDistricts( Stateid) 
  {
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t
    this.LocationService.GetDistrictByStateid( t,Stateid).then(x => 
    {
      this.Districts=[];
      this.district = x;
      Object.keys(this.district).forEach( key => 
      {
        this.district[key];
        let Districttemp = new District();
        Districttemp = this.district[key];
        this.Districts.push(Districttemp);
      });     
    });      
  }

  oncountryChange(countryid) 
  {
    if(countryid!=0)
    {
      this.getStates(countryid);
      this.CityModel.StateId=0;
    }
    else
    {
      this.getStates(countryid);
      this.CityModel.StateId=0;
      this.getDistricts(0);
    }
  }

  onStateChange(Stateid) 
  {
    this.Districts=[];
    if(Stateid!=0)
    {
      this.getDistricts(Stateid);
      this.CityModel.DistId=0;
    }
    else
    {
      this.getDistricts(Stateid);
      this.CityModel.DistId=0;
    }
  }

  saveCity() :void
  {
    this.DisableSave=false;
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.CityModel.IsActive=true;
    this.LocationService.AddCity(t ,this.CityModel).subscribe(res=>
    {
      this.getCities_C(this.EmployeeMasterService.SharedEmployees.CDistrictId);
      this.getCities_P(this.EmployeeMasterService.SharedEmployees.PDistrictId);
      this.CityModel.CityCode="";
      this.CityModel.CityName="";
      this.CityModel.CountryId=0;
      this.CityModel.StateId=0;
      this.CityModel.DistId=0;
      this.DisableSave=true;
    }); 
  }

  CheckCityName()
  {
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.LocationService.CheckCityName( t, this.CityModel.CityName,this.CityModel.DistId).then(x => 
    {
      this.CitycheckName = x;
      if( this.CitycheckName.length >0)
      {
        this.CityCheckNameExiststatus =false;
        this.CityNamecheck="Exist"
      }
      else
      {
        this.CityCheckNameExiststatus =true;
        this.CityNamecheck="";
      }
    });
  }
  //End POPUP
}