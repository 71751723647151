export class TDSSurchargeModel 
{
    //public TDSSurchargeModel : TDSSurchargeModel [];
    public RefId : number;
    public FromAmount :number;
    public ToAmount : number;
    public Percentage : number;
    public ApplicableFromDate : Date;
    public IsActive : boolean;
    public ApplicableDateCheck : Date;
}