import { Injectable,Inject } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';  
import { Http, Headers, RequestOptions, Response } from '@angular/http';  
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import 'rxjs/add/operator/toPromise';
import { Subscriber } from 'rxjs';
import { GlobalVariable  } from '../../../app.global';

import { Model_CRM_B2B } from '../../../shared/CRM/B2B/B2B_Model';


@Injectable()
export class B2bService {

  constructor(private http: HttpClient,private spinnerService: Ng4LoadingSpinnerService) 
  {
  }

  Shared_B2B: Model_CRM_B2B ;
     
    SaveOrUpdate : number =0;


    GetB2BDetails(token )
    {
        this.spinnerService.show();
        const authHeader = new HttpHeaders();     
        authHeader.append('Content-Type', 'application/json');
        authHeader.append('Authorization', token);
        return new Promise((resolve, reject) =>
        {
            setTimeout(()=>
            {
                this.http.get(GlobalVariable.BASE_API_URL +'/api/B2B/GetB2BDetails',{headers: authHeader})
                .subscribe(data => 
                {
                    this.spinnerService.hide();
                    resolve(data)
                },
                error => 
                {
                    this.spinnerService.hide();
                    reject(error);
                },);
            },3000);
        });
    }


    GetbB2BByid(token, id:number ) 
    {
        this.spinnerService.show();
        const authHeader = new HttpHeaders();     
        authHeader.append('Content-Type', 'application/json');
        authHeader.append('Authorization', token);
        return new Promise((resolve, reject) => 
        {
            setTimeout(()=>
            {
                this.http.get(GlobalVariable.BASE_API_URL +'/api/B2B/GetB2BDetailsById?ID='+id,{headers: authHeader})
                .subscribe(data => 
                {
                    this.spinnerService.hide();
                    resolve(data)
                },
                error => 
                {
                    this.spinnerService.hide();
                    reject(error);
                },);
            },3000);
        });
    }

    AddB2BDetails( token ,B2BModels :Model_CRM_B2B) 
    {
        const authHeader = new HttpHeaders();         
        authHeader.append('Content-Type', 'application/json');
        authHeader.append('Authorization', token);
        authHeader.append('Accept', 'application/json');
        return this.http.post(GlobalVariable.BASE_API_URL +'/api/B2B/AddB2BDetails', B2BModels ,{headers: authHeader} )
        .map(this.extractData)
        .catch(this.handleErrorObservable);
    }

 UpdateB2BDetails( token ,B2BModels :Model_CRM_B2B) 
    {
        const authHeader = new HttpHeaders();         
        authHeader.append('Content-Type', 'application/json');
        authHeader.append('Authorization', token);
        authHeader.append('Accept', 'application/json');
        return this.http.post(GlobalVariable.BASE_API_URL +'/api/B2B/UpdateB2BDetails', B2BModels ,{headers: authHeader} )
        .map(this.extractData)
        .catch(this.handleErrorObservable);
    }

    DeleteB2B( token ,ID : number) 
    {
        const authHeader = new HttpHeaders();         
        authHeader.append('Content-Type', 'application/json');
        authHeader.append('Authorization', token);
        authHeader.append('Accept', 'application/json');
        return this.http.put(GlobalVariable.BASE_API_URL +'/api/B2C/DeleteB2B', ID ,{headers: authHeader} )
        .map(this.extractData)
        .catch(this.handleErrorObservable);
    }


    private extractData(res: Response) 
    {
        let body = res;
        return body || {};
    }
    private handleErrorObservable (error: Response | any) 
    {
        console.error(error.message || error);
        return Observable.throw(error.message || error);
    }


}
