import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { EmployeeMasterService }  from '../../shared/HR_ADMIN/Employee/EmployeeMaster.Service';
import { BirthDayAnniversaryModel }  from '../../shared/HR_ADMIN/Employee/BirthDayAnniversaryModel.Model';
import { UserService } from '../../shared/user.service';
import { Menu } from '../../shared/menu.model';
import { ModalOptions,BsModalRef, BsModalService } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-manage-marriage-anniversary',
  templateUrl: './manage-marriage-anniversary.component.html',
  styleUrls: ['./manage-marriage-anniversary.component.css']
})
export class ManageMarriageAnniversaryComponent implements OnInit
{
  BirthDayAnniversaryModel : BirthDayAnniversaryModel= new BirthDayAnniversaryModel();
  userClaims: any;
  MarriageAnniversarys: BirthDayAnniversaryModel[] = [];
  Anniversary: any;
  public tableWidget: any;
  // Begning Permission Portion    
  SelectedMenu:Menu;
  IsDataIsInViewState : boolean=false;
  CanViewState : boolean=false;
  DisableSave :boolean=false;
  DOB : Date;
  // End of the permission portion
  SubMenues : Menu[]=[];
  Mod : any;
  //For Pop up
  ShowPopup : boolean= false;
  MsgSent : boolean= false;
  MsgnotSent : boolean= false;
  public modalRef: BsModalRef;
  public modelopt:ModalOptions;
  RefId: any;

  constructor(private EmployeeMasterService: EmployeeMasterService, private rout: Router, private userService: UserService,private modalService: BsModalService)
  {
    this.modelopt=new ModalOptions();
    this.modelopt.class='heavy_modal';
    this.modalService.config= this.modelopt;
    this.modelopt.ignoreBackdropClick=true;
  }

  ngOnInit() 
  {
    //Begin Permission Portion
    this.SelectedMenu=this.userService.SelectedMenu;
    if(this.SelectedMenu !=undefined)
    {
      localStorage.setItem('selectedmenu',JSON.stringify(this.SelectedMenu));
      this.rout.navigate([this.SelectedMenu.RouteName]);
    }
    else
    {
      if(JSON.parse(localStorage.getItem('selectedmenu'))!=undefined)
      {
        this.SelectedMenu = JSON.parse(localStorage.getItem('selectedmenu'));
        this.rout.navigate([this.SelectedMenu.RouteName]);
      }
    }
    if(this.SelectedMenu.CanAdd)
    {
    this.IsDataIsInViewState=false;
    }
    else
    {
      this.IsDataIsInViewState=true;
    }
    if(this.SelectedMenu.CanView)
    {
    this.CanViewState=false;
    }
    else
    {
      this.CanViewState=true;
    }
    //End of the peremission portion
    this.ShowPopup=false;
    this.DisableSave=true; 
    var CurrentDate = new Date();
    var CurrentYear = CurrentDate.getFullYear();
    var currentMonth = CurrentDate.getMonth();
    var CurrentDay = CurrentDate.getDate();
    var CDate = CurrentYear + '-' + (currentMonth+1) +'-'+ CurrentDay;
    this.BirthDayAnniversaryModel.MarriageAnniversary=CurrentDate;
    this.GetEmployeeMarriageAnniversary(CDate);    
  }

  GetEmployeeMarriageAnniversary(MarriageAnniversary)
  {
    this.MarriageAnniversarys =[];
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.EmployeeMasterService.GetEmployeeMarriageAnniversary( t,MarriageAnniversary).then(x => 
    {
      this.Anniversary = x;
      Object.keys(this.Anniversary).forEach( key => 
      {
        this.Anniversary[key];
        let Anntemp = new BirthDayAnniversaryModel();
        Anntemp = this.Anniversary[key];
        this.MarriageAnniversarys.push(Anntemp);
      });
    });
  }

  DateChanged( date)
  {
    this.GetEmployeeMarriageAnniversary(date);
  }

  ViewMarriageAnniversaryDetailsForMail(Id,Name,EmployeeEmailId,template) 
  {
    if(this.ShowPopup==true)
    {
      this.modalRef.hide();
    }
    this.modalRef = this.modalService.show(template,this.modelopt);
    this.ShowPopup=true;
    this.BirthDayAnniversaryModel.EmployeeCode=Id;
    this.BirthDayAnniversaryModel.EmployeeName=Name;
    this.BirthDayAnniversaryModel.EmployeeEmailId=EmployeeEmailId;
    this.BirthDayAnniversaryModel.Message=null;
  }

  MarriageAnniversaryMailFarward(template) :void
  {
    this.DisableSave=false;
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.EmployeeMasterService.MarriageAnniversaryMailFarward(t ,this.BirthDayAnniversaryModel).subscribe(res=>
    {
      this.RefId=res;
      this.BirthDayAnniversaryModel.EmployeeId=0;  
      if(this.RefId>0)
      {
        this.modalRef.hide();
        this.ShowPopup=false;
        this.MsgSent=true;
        this.MsgnotSent=false;
        this.modalRef = this.modalService.show(template);
      }
      else
      {
        this.modalRef.hide();
        this.ShowPopup=false;
        this.MsgSent=false;
        this.MsgnotSent=true;
        this.modalRef = this.modalService.show(template);
      }
    });
  }
}