import { Injectable,Inject } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';  
import { Http, Headers, RequestOptions, Response } from '@angular/http';  
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import 'rxjs/add/operator/toPromise';
import { Subscriber } from 'rxjs';
import { GlobalVariable  } from '../../app.global';
import { Company } from './Company.Model';
import { Branch } from './Branch.Model';
import { Department } from './Department.Model';
import { Vertical } from './Vertical.Model';
import { BankMaster } from './BankMaster.Model';
import { FinancialYear } from './FinancialYear.Model';
import { RegionForHR } from './RegionForHRModel.Model';


@Injectable()
export class OrganizationService 
{
  constructor(private http: HttpClient, private spinnerService: Ng4LoadingSpinnerService) 
  { }
  //Start Company Component
  GetCompanyByCompanycode(token, code )
  {
    const authHeader = new HttpHeaders();     
    authHeader.append('Content-Type', 'application/json');
    authHeader.append('Authorization', token);
    return new Promise((resolve, reject) =>
    {       setTimeout(()=>{

      this.http.get(GlobalVariable.BASE_API_URL +'/api/Company/GetCompanyByCode?CompanyCode='+code,{headers: authHeader})
      .subscribe(data => 
      {
        resolve(data)
      },
      error => 
      {
        reject(error);
      },);
    },3000);

    });
  }

  GetCompany(token )
  { 
    this.spinnerService.show();
    const authHeader = new HttpHeaders();     
    authHeader.append('Content-Type', 'application/json');
    authHeader.append('Authorization', token);
    return new Promise((resolve, reject) =>
    {       setTimeout(()=>{

      this.http.get(GlobalVariable.BASE_API_URL +'/api/Company/GetCompany',{headers: authHeader})
      .subscribe(data => 
      { this.spinnerService.hide();
        resolve(data)
      },
      error => 
      {
        this.spinnerService.hide();
        reject(error);
      },);
    },3000);

    });
  }

  GetCity(token , steteid)
  {
    const authHeader = new HttpHeaders();     
    authHeader.append('Content-Type', 'application/json');
    authHeader.append('Authorization', token);
    return new Promise((resolve, reject) => 
    {
      setTimeout(()=>{

      this.http.get(GlobalVariable.BASE_API_URL +'/api/City/GetCityByStateId?Stateid='+steteid,{headers: authHeader})
      .subscribe(data => 
      {
        resolve(data)
      },
      error =>
      {
        reject(error);
      },);

    },3000);
    });
  }

  GetCountry(token )
  {
    const authHeader = new HttpHeaders();     
    authHeader.append('Content-Type', 'application/json');
    authHeader.append('Authorization', token);
    return new Promise((resolve, reject) => 
    {
      setTimeout(()=>{

      this.http.get(GlobalVariable.BASE_API_URL +'/api/Country/GetCountry',{headers: authHeader})
      .subscribe(data => 
      {
        resolve(data)
      },
      error => 
      {
        reject(error);
      },);
    },3000);
    });
  }

  GetState(token , Countryid ) 
  {
    const authHeader = new HttpHeaders();     
    authHeader.append('Content-Type', 'application/json');
    authHeader.append('Authorization', token);
    return new Promise((resolve, reject) => 
    {
      setTimeout(()=>{
      this.http.get(GlobalVariable.BASE_API_URL +'/api/State/GetStateByCountryId?Countryid='+Countryid,{headers: authHeader})
      .subscribe(data => 
      {
        resolve(data)
      },
      error => 
      {
        reject(error);
      },);
    },3000);
    });
  }

  GetZone(token ) 
  {
    const authHeader = new HttpHeaders();     
    authHeader.append('Content-Type', 'application/json');
    authHeader.append('Authorization', token);
    return new Promise((resolve, reject) => 
    {
      setTimeout(()=>{
      this.http.get(GlobalVariable.BASE_API_URL +'/api/Zone/GetZone',{headers: authHeader})
      .subscribe(data => 
      {
        resolve(data)
      },
      error => 
      {
        reject(error);
      },);
    },3000);
    });
  }

  GetCompanByid(token, id:number ) 
  {
    this.spinnerService.show();

    const authHeader = new HttpHeaders();     
    authHeader.append('Content-Type', 'application/json');
    authHeader.append('Authorization', token);
    return new Promise((resolve, reject) => 
    {
      setTimeout(()=>{
      this.http.get(GlobalVariable.BASE_API_URL +'/api/Company/GetCompanyById?Companyid='+id,{headers: authHeader})
      .subscribe(data => 
      {
        this.spinnerService.hide();
        resolve(data)
      },
      error => 
      {
        this.spinnerService.hide();
        reject(error);
      },);
    },3000);
    });
  }

  AddCompany( token ,company :Company ) 
  {
    const authHeader = new HttpHeaders();         
    authHeader.append('Content-Type', 'application/json');
    authHeader.append('Authorization', token);
    authHeader.append('Accept', 'application/json');
     return this.http.post(GlobalVariable.BASE_API_URL +'/api/Company/Add', company ,{headers: authHeader} )
    .map(this.extractData)
    .catch(this.handleErrorObservable);
  }

  DeleteCompany( token ,companyid : number) 
  {
    const authHeader = new HttpHeaders();         
    authHeader.append('Content-Type', 'application/json');
    authHeader.append('Authorization', token);
    authHeader.append('Accept', 'application/json');
    return this.http.put(GlobalVariable.BASE_API_URL +'/api/Company/Delete', companyid ,{headers: authHeader} )
    .map(this.extractData)
    .catch(this.handleErrorObservable);
  }

  UpdateCompany( token ,company :Company ) 
  {
    const authHeader = new HttpHeaders();         
    authHeader.append('Content-Type', 'application/json');
    authHeader.append('Authorization', token);
    authHeader.append('Accept', 'application/json');
    return this.http.put(GlobalVariable.BASE_API_URL +'/api/Company/Update', company ,{headers: authHeader} )
    .map(this.extractData)
    .catch(this.handleErrorObservable);
  }

  GetCompanyForDDL(token )
  { 
    this.spinnerService.show();
    const authHeader = new HttpHeaders();     
    authHeader.append('Content-Type', 'application/json');
    authHeader.append('Authorization', token);
    return new Promise((resolve, reject) =>
    {       
      setTimeout(()=>
      {
        this.http.get(GlobalVariable.BASE_API_URL +'/api/Company/GetCompanyDDL',{headers: authHeader})
        .subscribe(data => 
        { this.spinnerService.hide();
          resolve(data)
        },
        error => 
        {
          this.spinnerService.hide();
          reject(error);
        },);
      },3000);
    });
  }

  CheckCompanyName(token, Name:string) 
  {
    this.spinnerService.show();
    const authHeader = new HttpHeaders();     
    authHeader.append('Content-Type', 'application/json');
    authHeader.append('Authorization', token);
    return new Promise((resolve, reject) => 
    {
    setTimeout(()=>{
    this.http.get(GlobalVariable.BASE_API_URL +'/api/Company/CheckCompanyName?CompanyName='+Name,{headers: authHeader})
    .subscribe(data => 
    {
        this.spinnerService.hide();
        resolve(data)
    },
    error => 
    {
        this.spinnerService.hide();
        reject(error);
    },);
    },3000);
    });
  }
  //End Company Component

  //Start Branch Component
  GetBranch(token )
  {
    this.spinnerService.show();
    const authHeader = new HttpHeaders();     
    authHeader.append('Content-Type', 'application/json');
    authHeader.append('Authorization', token);
    return new Promise((resolve, reject) =>
    {
      setTimeout(()=>{
      this.http.get(GlobalVariable.BASE_API_URL +'/api/Branch/GetBranch',{headers: authHeader})
      .subscribe(data => 
      {
        this.spinnerService.hide();
        resolve(data)
      },
      error => 
      {
        this.spinnerService.hide();
        reject(error);
      },);
    },3000);
    });
  }

  GetBranchByid(token, id:number ) 
  {
    this.spinnerService.show();
    const authHeader = new HttpHeaders();     
    authHeader.append('Content-Type', 'application/json');
    authHeader.append('Authorization', token);
    return new Promise((resolve, reject) => 
    {
      setTimeout(()=>{
      this.http.get(GlobalVariable.BASE_API_URL +'/api/Branch/GetBranchById?Branchid='+id,{headers: authHeader})
      .subscribe(data => 
      {
        this.spinnerService.hide();
        resolve(data)
      },
      error => 
      {
        this.spinnerService.hide();
        reject(error);
      },);
    },3000);
    });
  }

  GetBranchByCompanyId(token, Companyid:number ) 
  {
    const authHeader = new HttpHeaders();     
    authHeader.append('Content-Type', 'application/json');
    authHeader.append('Authorization', token);
    return new Promise((resolve, reject) => 
    {
      setTimeout(()=>{
      this.http.get(GlobalVariable.BASE_API_URL +'/api/Branch/GetBranchByCompId?Companyid='+Companyid,{headers: authHeader})
      .subscribe(data => 
      {
        resolve(data)
      },
      error => 
      {
        reject(error);
      },);
    },3000);
    });
  }

  GetBranchByCompanyIds(token, Companyids:string ) 
  {
    const authHeader = new HttpHeaders();     
    authHeader.append('Content-Type', 'application/json');
    authHeader.append('Authorization', token);
    return new Promise((resolve, reject) => 
    {
      setTimeout(()=>{
      this.http.get(GlobalVariable.BASE_API_URL +'/api/Branch/GetBranchByCompanyIds?Companyids='+Companyids,{headers: authHeader})
      .subscribe(data => 
      {
        resolve(data)
      },
      error => 
      {
        reject(error);
      },);
    },3000);
    });
  }

  GetBranchStateId(token, Branchid:number ) 
  {
    const authHeader = new HttpHeaders();     
    authHeader.append('Content-Type', 'application/json');
    authHeader.append('Authorization', token);
    return new Promise((resolve, reject) => 
    {
      setTimeout(()=>{
      this.http.get(GlobalVariable.BASE_API_URL +'/api/Branch/GetBranchStateId?BranchId='+Branchid,{headers: authHeader})
      .subscribe(data => 
      {
        resolve(data)
      },
      error => 
      {
        reject(error);
      },);
    },3000);
    });
  }

  AddBranch( token ,Branch :Branch) 
  {
    const authHeader = new HttpHeaders();         
    authHeader.append('Content-Type', 'application/json');
    authHeader.append('Authorization', token);
    authHeader.append('Accept', 'application/json');
     return this.http.post(GlobalVariable.BASE_API_URL +'/api/Branch/Add', Branch ,{headers: authHeader} )
    .map(this.extractData)
    .catch(this.handleErrorObservable);
  }

  DeleteBranch( token ,Branchid : number) 
  {
    const authHeader = new HttpHeaders();         
    authHeader.append('Content-Type', 'application/json');
    authHeader.append('Authorization', token);
    authHeader.append('Accept', 'application/json');
    return this.http.put(GlobalVariable.BASE_API_URL +'/api/Branch/Delete', Branchid ,{headers: authHeader} )
    .map(this.extractData)
    .catch(this.handleErrorObservable);
  }

  UpdateBranch( token ,branch :Branch) 
  {
    const authHeader = new HttpHeaders();         
    authHeader.append('Content-Type', 'application/json');
    authHeader.append('Authorization', token);
    authHeader.append('Accept', 'application/json');
    return this.http.put(GlobalVariable.BASE_API_URL +'/api/Branch/Update', branch ,{headers: authHeader} )
    .map(this.extractData)
    .catch(this.handleErrorObservable);
  }

  CheckBranchName(token, Name:string,CompId:number) 
  {
    this.spinnerService.show();
    const authHeader = new HttpHeaders();     
    authHeader.append('Content-Type', 'application/json');
    authHeader.append('Authorization', token);
    return new Promise((resolve, reject) => 
    {
    setTimeout(()=>{
    this.http.get(GlobalVariable.BASE_API_URL +'/api/Branch/CheckBranchName?BranchName='+Name+'&CompanyId='+CompId,{headers: authHeader})
    .subscribe(data => 
    {
        this.spinnerService.hide();
        resolve(data)
    },
    error => 
    {
        this.spinnerService.hide();
        reject(error);
    },);
    },3000);
    });
  }
  //End Branch Component

//Start Department Component
  GetDepartment(token )
  {
    this.spinnerService.show();
    const authHeader = new HttpHeaders();     
    authHeader.append('Content-Type', 'application/json');
    authHeader.append('Authorization', token);
    return new Promise((resolve, reject) =>
    {
      setTimeout(()=>{
      this.http.get(GlobalVariable.BASE_API_URL +'/api/Department/GetDepartment',{headers: authHeader})
      .subscribe(data => 
      {
        this.spinnerService.hide();
        resolve(data)
      },
      error => 
      {
        this.spinnerService.hide();
        reject(error);
      },);
    },3000);
    });
  }

  GetDepartmentByid(token, id:number ) 
  {
    this.spinnerService.show();
    const authHeader = new HttpHeaders();     
    authHeader.append('Content-Type', 'application/json');
    authHeader.append('Authorization', token);
    return new Promise((resolve, reject) => 
    {
      setTimeout(()=>{
      this.http.get(GlobalVariable.BASE_API_URL +'/api/Department/GetDepartmentById?Departmentid='+id,{headers: authHeader})
      .subscribe(data => 
      {
        this.spinnerService.hide();
        resolve(data)
      },
      error => 
      {
        this.spinnerService.hide();
        reject(error);
      },);
    },3000);
    });
  }

  AddDepartment( token ,Department :Department) 
  {
    const authHeader = new HttpHeaders();         
    authHeader.append('Content-Type', 'application/json');
    authHeader.append('Authorization', token);
    authHeader.append('Accept', 'application/json');
    return this.http.post(GlobalVariable.BASE_API_URL +'/api/Department/Add', Department ,{headers: authHeader} )
    .map(this.extractData)
    .catch(this.handleErrorObservable);
  }

  DeleteDepartment( token ,Departmentid : number) 
  {
    const authHeader = new HttpHeaders();         
    authHeader.append('Content-Type', 'application/json');
    authHeader.append('Authorization', token);
    authHeader.append('Accept', 'application/json');
    return this.http.put(GlobalVariable.BASE_API_URL +'/api/Department/Delete', Departmentid ,{headers: authHeader} )
    .map(this.extractData)
    .catch(this.handleErrorObservable);
  }

  UpdateDepartment( token ,Department :Department) 
  {
    const authHeader = new HttpHeaders();         
    authHeader.append('Content-Type', 'application/json');
    authHeader.append('Authorization', token);
    authHeader.append('Accept', 'application/json');
    return this.http.put(GlobalVariable.BASE_API_URL +'/api/Department/Update', Department ,{headers: authHeader} )
    .map(this.extractData)
    .catch(this.handleErrorObservable);
  }

  GetBranchesByComp(token , Companyid ) 
  {
  const authHeader = new HttpHeaders();     
  authHeader.append('Content-Type', 'application/json');
  authHeader.append('Authorization', token);
  return new Promise((resolve, reject) => 
  {
    setTimeout(()=>{
    this.http.get(GlobalVariable.BASE_API_URL +'/api/Branch/GetBranchByCompId?Companyid='+Companyid,{headers: authHeader})
    .subscribe(data => 
    {
      resolve(data)
    },
    error => 
    {
      reject(error);
    },);
    },3000);
  });
  }

  GetFunctionalHead(token )
  {
    const authHeader = new HttpHeaders();     
    authHeader.append('Content-Type', 'application/json');
    authHeader.append('Authorization', token);
    return new Promise((resolve, reject) =>
    {
      setTimeout(()=>{
      this.http.get(GlobalVariable.BASE_API_URL +'/api/Department/GetEmployeeName',{headers: authHeader})
      .subscribe(data => 
      {
        resolve(data)
      },
      error => 
      {
        reject(error);
      },);
    },3000);
    });
  }

  GetDepartmentByBranchid(token, id:number ) 
  {
    this.spinnerService.show();
    const authHeader = new HttpHeaders();     
    authHeader.append('Content-Type', 'application/json');
    authHeader.append('Authorization', token);
    return new Promise((resolve, reject) => 
    {
      setTimeout(()=>{
      this.http.get(GlobalVariable.BASE_API_URL +'/api/Department/GetDepartmentByBranchId?Branchid='+id,{headers: authHeader})
      .subscribe(data => 
      {
        this.spinnerService.hide();
        resolve(data)
      },
      error => 
      {
        this.spinnerService.hide();
        reject(error);
      },);
    },3000);
    });
  }

  CheckDepartmentName(token, Name:string,BranchId:number) 
  {
    this.spinnerService.show();
    const authHeader = new HttpHeaders();     
    authHeader.append('Content-Type', 'application/json');
    authHeader.append('Authorization', token);
    return new Promise((resolve, reject) => 
    {
    setTimeout(()=>{
    this.http.get(GlobalVariable.BASE_API_URL +'/api/Department/CheckDepartmentName?DepartmentName='+Name+'&BranchId='+BranchId,{headers: authHeader})
    .subscribe(data => 
    {
        this.spinnerService.hide();
        resolve(data)
    },
    error => 
    {
        this.spinnerService.hide();
        reject(error);
    },);
    },3000);
    });
  }
  //End Department Component


  //Start Vertical Component
  GetVerticalBycode(token , verticalcode)
  {
    this.spinnerService.show();
    const authHeader = new HttpHeaders();     
    authHeader.append('Content-Type', 'application/json');
    authHeader.append('Authorization', token);
    return new Promise((resolve, reject) =>
    { 
      setTimeout(()=>{
      this.http.get(GlobalVariable.BASE_API_URL +'/api/Vertical/GetVerticalBycode?code='+verticalcode,{headers: authHeader})
      .subscribe(data => 
      {
        this.spinnerService.hide();
        resolve(data)
      },
      error => 
      {
        this.spinnerService.hide();
        reject(error);
      },);
    },3000);
    });
  }

  GetVertical(token )
  {
    this.spinnerService.show();
    const authHeader = new HttpHeaders();     
    authHeader.append('Content-Type', 'application/json');
    authHeader.append('Authorization', token);
    return new Promise((resolve, reject) =>
    {
      setTimeout(()=>{
      this.http.get(GlobalVariable.BASE_API_URL +'/api/Vertical/GetVertical',{headers: authHeader})
      .subscribe(data => 
      {
        this.spinnerService.hide();
        resolve(data)
      },
      error => 
      {
        this.spinnerService.hide();
        reject(error);
      },);
    },3000);
    });
  }

  GetVerticalByid(token, id:number ) 
  {
    this.spinnerService.show();
    const authHeader = new HttpHeaders();     
    authHeader.append('Content-Type', 'application/json');
    authHeader.append('Authorization', token);
    return new Promise((resolve, reject) => 
    {
      setTimeout(()=>{
      this.http.get(GlobalVariable.BASE_API_URL +'/api/Vertical/GetVerticalById?Verticalid='+id,{headers: authHeader})
      .subscribe(data => 
      {
        this.spinnerService.hide();
        resolve(data)
      },
      error => 
      {
        this.spinnerService.hide();
        reject(error);
      },);
    },3000);
    });
  }

  AddVertical( token ,Vertical :Vertical) 
  {
    const authHeader = new HttpHeaders();         
    authHeader.append('Content-Type', 'application/json');
    authHeader.append('Authorization', token);
    authHeader.append('Accept', 'application/json');
    return this.http.post(GlobalVariable.BASE_API_URL +'/api/Vertical/Add', Vertical ,{headers: authHeader} )
    .map(this.extractData)
    .catch(this.handleErrorObservable);
  }

  DeleteVertical( token ,Verticalid : number) 
  {
    const authHeader = new HttpHeaders();         
    authHeader.append('Content-Type', 'application/json');
    authHeader.append('Authorization', token);
    authHeader.append('Accept', 'application/json');
    return this.http.put(GlobalVariable.BASE_API_URL +'/api/Vertical/Delete', Verticalid ,{headers: authHeader} )
    .map(this.extractData)
    .catch(this.handleErrorObservable);
  }

  UpdateVertical( token ,Vertical :Vertical) 
  {
    const authHeader = new HttpHeaders();         
    authHeader.append('Content-Type', 'application/json');
    authHeader.append('Authorization', token);
    authHeader.append('Accept', 'application/json');
    return this.http.put(GlobalVariable.BASE_API_URL +'/api/Vertical/Update', Vertical ,{headers: authHeader} )
    .map(this.extractData)
    .catch(this.handleErrorObservable);
  }

  //Start Bank Master
  GetBankMaster(token )
  {
    this.spinnerService.show();
    const authHeader = new HttpHeaders();     
    authHeader.append('Content-Type', 'application/json');
    authHeader.append('Authorization', token);
    return new Promise((resolve, reject) =>
    {
      setTimeout(()=>{
      this.http.get(GlobalVariable.BASE_API_URL +'/api/BankMaster/GetBankMaster',{headers: authHeader})
      .subscribe(data => 
      {
        this.spinnerService.hide();
        resolve(data)
      },
      error => 
      {
        this.spinnerService.hide();
        reject(error);
      },);
    },3000);
    });
  }

  GetBankMasterByid(token, id:number ) 
  {
    this.spinnerService.show();
    const authHeader = new HttpHeaders();     
    authHeader.append('Content-Type', 'application/json');
    authHeader.append('Authorization', token);
    return new Promise((resolve, reject) => 
    {
      setTimeout(()=>{
      this.http.get(GlobalVariable.BASE_API_URL +'/api/BankMaster/GetBankMasterById?Bankid='+id,{headers: authHeader})
      .subscribe(data => 
      {
        this.spinnerService.hide();
        resolve(data)
      },
      error => 
      {
        this.spinnerService.hide();
        reject(error);
      },);
    },3000);
    });
  }

  AddBankMaster( token ,Bank :BankMaster) 
  {
    const authHeader = new HttpHeaders();         
    authHeader.append('Content-Type', 'application/json');
    authHeader.append('Authorization', token);
    authHeader.append('Accept', 'application/json');
    return this.http.post(GlobalVariable.BASE_API_URL +'/api/BankMaster/Add', Bank ,{headers: authHeader} )
    .map(this.extractData)
    .catch(this.handleErrorObservable);
  }

  DeleteBankMaster( token ,Bankid : number) 
  {
    const authHeader = new HttpHeaders();         
    authHeader.append('Content-Type', 'application/json');
    authHeader.append('Authorization', token);
    authHeader.append('Accept', 'application/json');
    return this.http.put(GlobalVariable.BASE_API_URL +'/api/BankMaster/Delete', Bankid ,{headers: authHeader} )
    .map(this.extractData)
    .catch(this.handleErrorObservable);
  }

  UpdateBankMaster( token ,Bank :BankMaster) 
  {
    const authHeader = new HttpHeaders();         
    authHeader.append('Content-Type', 'application/json');
    authHeader.append('Authorization', token);
    authHeader.append('Accept', 'application/json');
    return this.http.put(GlobalVariable.BASE_API_URL +'/api/BankMaster/Update', Bank ,{headers: authHeader} )
    .map(this.extractData)
    .catch(this.handleErrorObservable);
  }
  //End Bank Master

  //Start Financial Year
  GetFinancialYear(token )
  {
    this.spinnerService.show();
    const authHeader = new HttpHeaders();     
    authHeader.append('Content-Type', 'application/json');
    authHeader.append('Authorization', token);
    return new Promise((resolve, reject) =>
    {
      setTimeout(()=>{
      this.http.get(GlobalVariable.BASE_API_URL +'/api/FinancialYear/GetFinancialyear',{headers: authHeader})
      .subscribe(data => 
      {
        this.spinnerService.hide();
        resolve(data)
      },
      error => 
      {
        this.spinnerService.hide();
        reject(error);
      },);
    },3000);
    });
  }

  GetYearOnly(token )
  {
    this.spinnerService.show();
    const authHeader = new HttpHeaders();     
    authHeader.append('Content-Type', 'application/json');
    authHeader.append('Authorization', token);
    return new Promise((resolve, reject) =>
    {
      setTimeout(()=>{
      this.http.get(GlobalVariable.BASE_API_URL +'/api/FinancialYear/GetYearOnly',{headers: authHeader})
      .subscribe(data => 
      {
        this.spinnerService.hide();
        resolve(data)
      },
      error => 
      {
        this.spinnerService.hide();
        reject(error);
      },);
    },3000);
    });
  }

  GetFinancialYearByid(token, id:number ) 
  {
    this.spinnerService.show();
    const authHeader = new HttpHeaders();     
    authHeader.append('Content-Type', 'application/json');
    authHeader.append('Authorization', token);
    return new Promise((resolve, reject) => 
    {
      setTimeout(()=>{
      this.http.get(GlobalVariable.BASE_API_URL +'/api/FinancialYear/GetFinancialyearById?Financialyearid='+id,{headers: authHeader})
      .subscribe(data => 
      {
        this.spinnerService.hide();
        resolve(data)
      },
      error => 
      {
        this.spinnerService.hide();
        reject(error);
      },);
    },3000);
    });
  }

  AddFinancialYear( token ,FinYear :FinancialYear) 
  {
    const authHeader = new HttpHeaders();         
    authHeader.append('Content-Type', 'application/json');
    authHeader.append('Authorization', token);
    authHeader.append('Accept', 'application/json');
    return this.http.post(GlobalVariable.BASE_API_URL +'/api/FinancialYear/Add', FinYear ,{headers: authHeader} )
    .map(this.extractData)
    .catch(this.handleErrorObservable);
  }

  DeleteFinancialYear( token ,FinancialYearid : number) 
  {
    const authHeader = new HttpHeaders();         
    authHeader.append('Content-Type', 'application/json');
    authHeader.append('Authorization', token);
    authHeader.append('Accept', 'application/json');
    return this.http.put(GlobalVariable.BASE_API_URL +'/api/FinancialYear/Delete', FinancialYearid ,{headers: authHeader} )
    .map(this.extractData)
    .catch(this.handleErrorObservable);
  }

  UpdateFinancialYear( token ,FinYear :FinancialYear) 
  {
    const authHeader = new HttpHeaders();         
    authHeader.append('Content-Type', 'application/json');
    authHeader.append('Authorization', token);
    authHeader.append('Accept', 'application/json');
    return this.http.put(GlobalVariable.BASE_API_URL +'/api/FinancialYear/Update', FinYear ,{headers: authHeader} )
    .map(this.extractData)
    .catch(this.handleErrorObservable);
  }
  
  CheckFinancialYear(token, FromDate:Date, ToDate:Date) 
  {
    this.spinnerService.show();
    const authHeader = new HttpHeaders();     
    authHeader.append('Content-Type', 'application/json');
    authHeader.append('Authorization', token);
    return new Promise((resolve, reject) => 
    {
      setTimeout(()=>{
      this.http.get(GlobalVariable.BASE_API_URL +'/api/FinancialYear/CheckFinancialyear?FromDate='+ FromDate +' &ToDate='+ToDate,{headers: authHeader})
      .subscribe(data => 
      {
        this.spinnerService.hide();
        resolve(data)
      },
      error => 
      {
        this.spinnerService.hide();
        reject(error);
      },);
    },3000);
    });
  }
  //End Financial Year
  // Begin Month Name
  GetMonthName(token )
  {
    this.spinnerService.show();
    const authHeader = new HttpHeaders();     
    authHeader.append('Content-Type', 'application/json');
    authHeader.append('Authorization', token);
    return new Promise((resolve, reject) =>
    {
      setTimeout(()=>{
      this.http.get(GlobalVariable.BASE_API_URL +'/api/Company/GetMonth',{headers: authHeader})
      .subscribe(data => 
      {
        this.spinnerService.hide();
        resolve(data)
      },
      error => 
      {
        this.spinnerService.hide();
        reject(error);
      },);
    },3000);
    });
  }
  // End Month Name
  // Begin Year Name
  GetYear(token )
  {
    this.spinnerService.show();
    const authHeader = new HttpHeaders();     
    authHeader.append('Content-Type', 'application/json');
    authHeader.append('Authorization', token);
    return new Promise((resolve, reject) =>
    {
      setTimeout(()=>{
      this.http.get(GlobalVariable.BASE_API_URL +'/api/Company/GetYear',{headers: authHeader})
      .subscribe(data => 
      {
        this.spinnerService.hide();
        resolve(data)
      },
      error => 
      {
        this.spinnerService.hide();
        reject(error);
      },);
    },3000);
    });
  }
  // End Year Name
  //Start RegionForHR Component
  GetRegionForHR(token)
  {
    this.spinnerService.show();
    const authHeader = new HttpHeaders();     
    authHeader.append('Content-Type', 'application/json');
    authHeader.append('Authorization', token);
    return new Promise((resolve, reject) =>
    {
      setTimeout(()=>{
      this.http.get(GlobalVariable.BASE_API_URL +'/api/RegionForHR/GetRegionForHR',{headers: authHeader})
      .subscribe(data => 
      {
        this.spinnerService.hide();
        resolve(data)
      },
      error => 
      {
        this.spinnerService.hide();
        reject(error);
      },);
    },3000);
    });
  }

  GetRegionForHRById(token, id:number )
  {
    this.spinnerService.show();
    const authHeader = new HttpHeaders();     
    authHeader.append('Content-Type', 'application/json');
    authHeader.append('Authorization', token);
    return new Promise((resolve, reject) => 
    {
      setTimeout(()=>{
      this.http.get(GlobalVariable.BASE_API_URL +'/api/RegionForHR/GetRegionForHRById?RefId='+id,{headers: authHeader})
      .subscribe(data => 
      {
        this.spinnerService.hide();
        resolve(data)
      },
      error => 
      {
        this.spinnerService.hide();
        reject(error);
      },);
    },3000);
    });
  }

  AddRegionForHR( token ,RegionForHR :RegionForHR)
  {
    const authHeader = new HttpHeaders();         
    authHeader.append('Content-Type', 'application/json');
    authHeader.append('Authorization', token);
    authHeader.append('Accept', 'application/json');
     return this.http.post(GlobalVariable.BASE_API_URL +'/api/RegionForHR/Add', RegionForHR ,{headers: authHeader} )
    .map(this.extractData)
    .catch(this.handleErrorObservable);
  }

  DeleteRegionForHR( token ,RefId : number)
  {
    const authHeader = new HttpHeaders();         
    authHeader.append('Content-Type', 'application/json');
    authHeader.append('Authorization', token);
    authHeader.append('Accept', 'application/json');
    return this.http.put(GlobalVariable.BASE_API_URL +'/api/RegionForHR/Delete', RefId ,{headers: authHeader} )
    .map(this.extractData)
    .catch(this.handleErrorObservable);
  }

  UpdateRegionForHR( token ,RegionForHR :RegionForHR)
  {
    const authHeader = new HttpHeaders();         
    authHeader.append('Content-Type', 'application/json');
    authHeader.append('Authorization', token);
    authHeader.append('Accept', 'application/json');
    return this.http.put(GlobalVariable.BASE_API_URL +'/api/RegionForHR/Update', RegionForHR ,{headers: authHeader} )
    .map(this.extractData)
    .catch(this.handleErrorObservable);
  }

  CheckRegionForHR(token, RegionName:string)
  {
    this.spinnerService.show();
    const authHeader = new HttpHeaders();     
    authHeader.append('Content-Type', 'application/json');
    authHeader.append('Authorization', token);
    return new Promise((resolve, reject) => 
    {
    setTimeout(()=>{
    this.http.get(GlobalVariable.BASE_API_URL +'/api/RegionForHR/CheckRegionForHRName?RegionName='+RegionName,{headers: authHeader})
    .subscribe(data => 
    {
        this.spinnerService.hide();
        resolve(data)
    },
    error => 
    {
        this.spinnerService.hide();
        reject(error);
    },);
    },3000);
    });
  }

  GetPayZoneDDL(token)
  {
    this.spinnerService.show();
    const authHeader = new HttpHeaders();     
    authHeader.append('Content-Type', 'application/json');
    authHeader.append('Authorization', token);
    return new Promise((resolve, reject) =>
    {
      setTimeout(()=>{
      this.http.get(GlobalVariable.BASE_API_URL +'/api/RegionForHR/GetPayZoneDDL',{headers: authHeader})
      .subscribe(data => 
      {
        this.spinnerService.hide();
        resolve(data)
      },
      error => 
      {
        this.spinnerService.hide();
        reject(error);
      },);
    },3000);
    });
  }
  //End RegionForHR Component
  //Begin HR List
  GetHRNameLists(token)
  {
    this.spinnerService.show();
    const authHeader = new HttpHeaders();     
    authHeader.append('Content-Type', 'application/json');
    authHeader.append('Authorization', token);
    return new Promise((resolve, reject) =>
    {
      setTimeout(()=>{
      this.http.get(GlobalVariable.BASE_API_URL +'/api/RegionForHR/GetHRNameLists',{headers: authHeader})
      .subscribe(data => 
      {
        this.spinnerService.hide();
        resolve(data)
      },
      error => 
      {
        this.spinnerService.hide();
        reject(error);
      },);
    },3000);
    });
  }
  //End HR List
  //End Vertical Component
  private extractData(res: Response) 
  {
    let body = res;
    return body || {};
  }

  private handleErrorObservable (error: Response | any) 
  {
    console.error(error.message || error);
    return Observable.throw(error.message || error);
  }
}
