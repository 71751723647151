<div class="body-content-area tabcontent" id="link1">
  <div class="row">
  <div class="col-lg-9">
      <div class="row">
          <div class="col-lg-1 low-padding text-center">
              <!-- <img *ngIf="this.SelectedMenu.Image!=null" src={{this.SelectedMenu.Image}} class="body-icon" alt="Icon"/> -->
          </div>
          <div class="col-lg-10 clear-padding">
              <span class="page-title">Generate Payslip</span>
              <small class="page-description"></small>
          </div>
      </div>
  </div>
</div>
<div *ngIf="!(this.CanViewState)">
  <fieldset class="body-form-control" > 
  <legend class="legend--"></legend>
    <form #EmployeepayslipForm="ngForm">
      <div class="row">
        <div class="col-lg-4">
          <div class="col-body-border-right">
            <div class="form-group row">
              <label class="col-sm-4 col-form-label clear-padding-right">Year</label>
              <div class="col-sm-7 clear-left-padding">
                <select [(ngModel)]="EmployeeMonthelyAttendenceModel.Year" tabindex="1" id ="Year" class="form-control" name="Year" #Year="ngModel" (ngModelChange)="onchangeFinYear($event)">
                  <option [value]="0" [disabled]=true >Select Year</option>
                  <option *ngFor="let FY of FinancialYears" [value]="FY.Year">{{FY.Year}}</option>
                </select>
              </div>
              <div class="required-validation" *ngIf="EmployeeMonthelyAttendenceModel.Year==0">Year is required</div>
            </div>
          </div>
        </div>
        <div class="col-lg-4">
          <div class="col-body-border-right">
            <div class="form-group row">
              <label class="col-sm-4 col-form-label clear-padding-right">Month</label>
              <div class="col-sm-7 clear-left-padding">
                <select [(ngModel)]="EmployeeMonthelyAttendenceModel.MonthId" tabindex="2" (ngModelChange)="onchangeMonth($event)" id ="MonthId" class="form-control" name="MonthId" #MonthId="ngModel" >
                  <option [value]="0" [disabled]=true >Select Month</option>
                  <option *ngFor="let Mo of Months" [value]="Mo.MonthId">{{Mo.MonthName}}</option>
                </select>
              </div>
              <div class="required-validation" *ngIf="EmployeeMonthelyAttendenceModel.MonthId==0">Month is required</div>
            </div>
          </div>
        </div>
        <div class="col-lg-4">
          <div class="form-group row" >
            <label class="col-sm-2 col-form-label clear-padding-right">Employee</label>
            <div class="col-sm-9 clear-left-padding">
              <angular2-multiselect tabindex="1" class="form-control" [data]="emp" [(ngModel)]="selectedItems" name="EmployeeId" 
                [settings]="EmployeeSettings" (onSelect)="onItemSelect($event)" (onDeSelect)="OnItemDeSelect($event)" (onSelectAll)="onSelectAll($event)"
                (onDeSelectAll)="onDeSelectAll($event)">
              </angular2-multiselect>
            </div>
            <div class="required-validation" *ngIf="EmployeeMonthelyAttendenceModel.EmployeeId == 0">Employee is required</div>
          </div>  
          <div class="form-group row">
            <label class="col-sm-5 col-form-label clear-padding-right">
              <button type="submit" tabindex="5" (click)="GetEmployeeAttendanceDetails()" class="btn btn-primary save-button" [disabled]="!(EmployeepayslipForm.valid && EmployeeMonthelyAttendenceModel.MonthId > 0 && EmployeeMonthelyAttendenceModel.Year > 0 && EmployeeMonthelyAttendenceModel.EmployeeId > 0 && this.DisableSave == true)">Generate Payslip</button>
            </label>
            <div class="col-sm-5 clear-left-padding" *ngIf="this.EmployeeMonthelyAttendenceModelResult.GrossSalary > 0">
              <button type="submit" class="btn btn-danger cancle-button" (click)="print()">Print Payslip</button>
            </div>
          </div>
        </div>
      </div>
    </form>    
  </fieldset>
  <fieldset  class="body-form-control" *ngIf="this.EmployeeMonthelyAttendenceModelResult.GrossSalary > 0">
    <legend class="legend--"></legend>
    <div style="padding-left:15%;padding-right:15%" Id="Payslip" >
      <table border="1px" style="width:100%; ">
        <tr >
          <td style="width:100%;">
            <table border="0" style="width:100%;">
              <tr>
                <td style="width:80%; vertical-align: top;" >
                  <span style="font-weight: bold;font-size: 11pt;">&nbsp;{{this.EmployeeMonthelyAttendenceModelResult.CompanyName}}</span><br>
                  <span style="font-size: 10pt;">&nbsp;{{this.EmployeeMonthelyAttendenceModelResult.Address1}}, {{this.EmployeeMonthelyAttendenceModelResult.Address2}},</span><br>
                  <span style="font-size: 10pt;">&nbsp;{{this.EmployeeMonthelyAttendenceModelResult.City}} - {{this.EmployeeMonthelyAttendenceModelResult.PinCode}}</span>
                </td>
                <td style="padding-left:10%; width:20%;">
                  <img *ngIf="this.EmployeeMonthelyAttendenceModelResult.LogoImage!=null" [src]="this.EmployeeMonthelyAttendenceModelResult.LogoImage" style="width:100%;">&nbsp;
                </td>
              </tr>
            </table>
          </td>
        </tr>

        <tr>
          <td>
            <table border="0">
              <tr>
                <td style="font-weight: bold;font-size: 10pt;">
                    &nbsp;Payslip for the month of {{this.EmployeeMonthelyAttendenceModelResult.MonthName}}&nbsp;{{this.EmployeeMonthelyAttendenceModelResult.CurrentYear}}
                </td>
              </tr>
            </table>
          </td>
        </tr>
        
        <tr>
          <td>
            <table border="1" style="width:100%;">
              <tr style="border-left: 0%;">
                <td style="width:15%;border-left: 0%;">&nbsp;Employee Id</td>
                <td style="width:45%;">&nbsp;{{this.EmployeeMonthelyAttendenceModelResult.EmployeeCode}}</td>
                <td style="width:15%;">&nbsp;PF No</td>
                <td tyle="width:15%;">&nbsp;{{this.EmployeeMonthelyAttendenceModelResult.EPFNO}}</td>
              </tr>
              <tr style="border-left: 0px;">
                <td style="width:15%;border-left: 0%;">&nbsp;Name</td>
                <td style="width:45%;">&nbsp;{{this.EmployeeMonthelyAttendenceModelResult.EmployeeName}}</td>
                <td style="width:15%;">&nbsp;UAN No</td>
                <td tyle="width:15%;">&nbsp;{{this.EmployeeMonthelyAttendenceModelResult.UANNo}}</td>
              </tr>
              <tr style="border-left: 0px;">
                <td style="width:15%;border-left: 0%;">&nbsp;Designation</td>
                <td style="width:45%;">&nbsp;{{this.EmployeeMonthelyAttendenceModelResult.DesignationName}}</td>
                <td style="width:15%;">&nbsp;Bank Name</td>
                <td tyle="width:15%;">&nbsp;{{this.EmployeeMonthelyAttendenceModelResult.BankName}}</td>
              </tr>
              <tr style="border-left: 0px;">
                <td style="width:15%;border-left: 0%;">&nbsp;Location</td>
                <td style="width:45%;">&nbsp;{{this.EmployeeMonthelyAttendenceModelResult.Branchname}}</td>
                <td style="width:15%;">&nbsp;Account No</td>
                <td tyle="width:15%;">&nbsp;{{this.EmployeeMonthelyAttendenceModelResult.AccountNo}}</td>
              </tr>
              <tr style="border-left: 0px;">
                <td style="width:15%;border-left: 0%;">&nbsp;Department</td>
                <td style="width:45%;">&nbsp;{{this.EmployeeMonthelyAttendenceModelResult.DepartmentName}}</td>
                <td style="width:15%;">&nbsp;Days Payable</td>
                <td tyle="width:15%;">&nbsp;{{this.EmployeeMonthelyAttendenceModelResult.FinalAttendence}}</td>
              </tr>
              <tr style="border-left: 0px;">
                <td style="width:15%;border-left: 0%;">&nbsp;PAN</td>
                <td style="width:45%;">&nbsp;{{this.EmployeeMonthelyAttendenceModelResult.PANNo}}</td>
                <td style="width:15%;">&nbsp;LOP Days</td>
                <td tyle="width:15%;">&nbsp;{{this.EmployeeMonthelyAttendenceModelResult.FinalLopNo}}</td>
              </tr>
              <tr style="border-left: 0px; height:2%">
                <td style="width:15%;border-left: 0%;">&nbsp;</td>
                <td style="width:45%;"></td>
                <td style="width:15%;">Attendance Cycle</td>
                <td tyle="width:15%;">&nbsp;{{this.EmployeeMonthelyAttendenceModelResult.FromDate}}&nbsp;to&nbsp;{{this.EmployeeMonthelyAttendenceModelResult.ToDate}}</td>
              </tr>
            </table>
          </td>
        </tr>

        <tr>
          <td>
            <table border="1" style="width:100%;">
              <tr border="1" style="border-left: 0%;">
                <td style="width:45%; border-left: 0%; vertical-align: top;"> 
                  <table style="width:100%; vertical-align: top;">
                    <tr>
                      <td style="width:50%; border-left: 0%; font-weight: bold;">&nbsp;<u>Earnings</u></td>
                      <td style="width:50%; border-left: 0%; text-align: right; font-weight: bold;"><u>Amount</u>&nbsp;</td>
                    </tr>

                    <tr *ngFor="let Earnings of this.EmployeeMonthelyAttendenceModelResult.SalaryCompList">
                      <td style="width:50%; border-left: 0%;">&nbsp;{{Earnings.SalaryComponentName}}</td>
                      <td style="width:50%; border-left: 0%; text-align: right;">{{Earnings.Amount | number:'3.0-0'}}.00&nbsp;</td>
                    </tr>

                    <tr *ngIf="this.EmployeeMonthelyAttendenceModelResult.Arears > 0" >
                      <td style="width:50%; border-left: 0%;">&nbsp;Arrears</td>
                      <td style="width:50%; border-left: 0%; text-align: right;">{{this.EmployeeMonthelyAttendenceModelResult.Arears | number:'3.0-0'}}.00&nbsp;</td>
                    </tr>
                  </table>
                </td>
              
                <td style="width:30%; border-left: 0%; vertical-align: text-top;">
                  <table style="width:100%;">
                    <tr border="1" style="border-left: 0%;">
                      <td>
                        <table style="width:100%; vertical-align:top;">
                          <tr>
                            <td style="width:50%;border-left: 0%; font-weight: bold;">&nbsp;<u>Deductions</u></td>
                            <td style="width:50%;border-left: 0%; text-align: right; font-weight: bold;"><u>Amount</u>&nbsp;</td>
                          </tr>
                          <tr *ngIf="this.EmployeeMonthelyAttendenceModelResult.PF > 0" >
                            <td style="width:50%;border-left: 0%;">&nbsp;PF</td>
                            <td style="width:50%;border-left: 0%; text-align: right;">{{this.EmployeeMonthelyAttendenceModelResult.PF | number:'3.0-0'}}.00&nbsp;</td>
                          </tr>
                          <tr *ngIf="this.EmployeeMonthelyAttendenceModelResult.Ptax > 0" >
                            <td style="width:50%;border-left: 0%;">&nbsp;P-Tax</td>
                            <td style="width:50%;border-left: 0%; text-align: right;">{{this.EmployeeMonthelyAttendenceModelResult.Ptax | number:'3.0-0'}}.00&nbsp;</td>
                          </tr>
                          <tr *ngIf="this.EmployeeMonthelyAttendenceModelResult.TDS > 0" >
                            <td style="width:50%;border-left: 0%;">&nbsp;TDS</td>
                            <td style="width:50%;border-left: 0%; text-align: right;">{{this.EmployeeMonthelyAttendenceModelResult.TDS | number:'3.0-0'}}.00&nbsp;</td>
                          </tr>
                          <tr *ngIf="this.EmployeeMonthelyAttendenceModelResult.Advance > 0" >
                            <td style="width:50%;border-left: 0%;">&nbsp;Advance</td>
                            <td style="width:50%;border-left: 0%; text-align: right;">{{this.EmployeeMonthelyAttendenceModelResult.Advance | number:'3.0-0'}}.00&nbsp;</td>
                          </tr>
                          <tr *ngIf="this.EmployeeMonthelyAttendenceModelResult.MediClame > 0" >
                            <td style="width:50%;border-left: 0%;">&nbsp;MediClaim</td>
                            <td style="width:50%;border-left: 0%; text-align: right;">{{this.EmployeeMonthelyAttendenceModelResult.MediClame | number:'3.0-0'}}.00&nbsp;</td>
                          </tr>
                          <tr *ngIf="this.EmployeeMonthelyAttendenceModelResult.OtherDeduction > 0" >
                            <td style="width:50%;border-left: 0%;">&nbsp;Other Deduction</td>
                            <td style="width:50%;border-left: 0%; text-align: right;">{{this.EmployeeMonthelyAttendenceModelResult.OtherDeduction | number:'3.0-0'}}.00&nbsp;</td>
                          </tr>
                          <tr *ngIf="this.EmployeeMonthelyAttendenceModelResult.Donation > 0" >
                            <td style="width:50%;border-left: 0%;">&nbsp;Donation</td>
                            <td style="width:50%;border-left: 0%; text-align: right;">{{this.EmployeeMonthelyAttendenceModelResult.Donation | number:'3.0-0'}}.00&nbsp;</td>
                          </tr>
                          <tr *ngIf="this.EmployeeMonthelyAttendenceModelResult.Loan > 0" >
                            <td style="width:50%;border-left: 0%;">&nbsp;Loan</td>
                            <td style="width:50%;border-left: 0%; text-align: right;">{{this.EmployeeMonthelyAttendenceModelResult.Loan | number:'3.0-0'}}.00&nbsp;</td>
                          </tr>
                          <tr *ngIf="this.LopAmount > 0" >
                            <td style="width:50%;border-left: 0%;">&nbsp;LOP Amount</td>
                            <td style="width:50%;border-left: 0%; text-align: right;">{{this.LopAmount | number:'3.0-0'}}.00&nbsp;</td>
                          </tr>
                        </table>
                      </td>
                    </tr>
                  </table>
                </td> 
              </tr>
            </table>
          </td>
        </tr>

        <tr>
          <td>
            <table border="1" style="width:100%;">
              <tr>
                <td style="width:45%;border-left: 0%;">
                  <table style="width:100%;">
                    <tr>
                      <td style="width:50%;border-left: 0%; font-weight: bold;">&nbsp;Total Earnings</td>
                      <td style="width:50%;border-left: 0%; text-align: right; font-weight: bold;">{{this.TotalaEarnings| number:'3.0-0'}}.00&nbsp;</td>
                    </tr>
                  </table>
                </td>
                <td style="width:30%;border-left: 0%;">
                  <table style="width:100%;">
                    <tr>
                      <td style="width:50%;border-left: 0%; font-weight: bold;">&nbsp;Total Deductions</td>
                      <td style="width:50%;border-left: 0%; text-align: right; font-weight: bold;">{{this.TotalDeduction| number:'3.0-0'}}.00&nbsp;</td>
                    </tr>
                  </table>
                </td>
              </tr>
            </table>
          </td>
        </tr>

        <tr>
          <table border="1" style="width:100%;">
            <tr>
              <td style="font-weight: bold;font-size: 10pt; " >
                  &nbsp;Net Pay&nbsp;&nbsp;&nbsp;:&nbsp;&nbsp;Rs.&nbsp;{{this.EmployeeMonthelyAttendenceModelResult.NetTakeHome | number:'3.0-0'}}.00
                <br>
                &nbsp;In Words :&nbsp;&nbsp;{{this.EmployeeMonthelyAttendenceModelResult.NetTakeHomeINWORD}}
              </td>
            </tr>
          </table>
        </tr>

        <tr>
          <table border="1" style="width:100%;">
            <tr>
              <td style="text-align: center">
                * Computer generated salary slip. Signature not required
              </td>
            </tr>
          </table>
        </tr>
      </table>
    </div>
  </fieldset>
</div>
</div>