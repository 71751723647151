import { Component, OnInit, Input } from '@angular/core';
import { CrmLeadService } from '../../../shared/CRM/MICECRM/LEADCRM/CrmLeadService';
import { Router } from '@angular/router';
import { Model_CRM_Designation } from '../../../shared/CRM/MICECRM/Model_CRM_Designation';
import { Model_CRM_Segments } from '../../../shared/CRM/MICECRM/Model_CRM_Segments';
//import { forEach } from '@angular/router/src/utils/collection';
import { ModalOptions,BsModalRef, BsModalService  } from 'ngx-bootstrap/modal'
import { Model_CRM_LEAD_Note } from '../../../../../src/app/shared/CRM/MICECRM/LEADCRM/Model_CRM_LEAD_Note';
//import { from } from 'rxjs/observable/from';

//import {MiceCrmBasicLeadInfoComponent } from './mice-crm-basic-lead-info.component'
//import { AsyncAction } from 'rxjs/scheduler/AsyncAction';



@Component({
  selector: 'app-mic-crm-more-info',
  templateUrl: './mic-crm-more-info.component.html',
  styleUrls: ['./mic-crm-more-info.component.css']
})
export class MicCrmMoreInfoComponent implements OnInit {


  //for model 
  public modalRef: BsModalRef;
  public modelopt: ModalOptions;
  //for model

  updatedLeadId: any;


  //for model popup
  CRMLEADNOTE: Model_CRM_LEAD_Note;
  //for model popup

  ShowPopup: boolean = false;
  IsDataIsInViewState: boolean = false;
  ApprovedMsg: boolean = false;

  // For Searchable Dropdown
  DesignationList: Model_CRM_Designation[] = [];
  Designationobj: any;
  DesignationArray: any[] = [];
  DesignationSettings = {};
  SelectedDesignation: any[] = [];


  SegmentList: Model_CRM_Segments[] = [];
  Segmentobj: any;
  SegmentArray: any[] = [];
  SegmentSettings = {};
  SelectedSegment: any[] = [];


  SuccessMessage: string = "";
  SuccessStatus: boolean = false;

  public NoteId: any;

  // End for serachable drop down

  CRM_Obj_Designation: any;
  constructor( private rout: Router, private CRM_LeadService: CrmLeadService, private modalService: BsModalService) { }

  ngOnInit() {



    this.modelopt = new ModalOptions();
    this.modelopt.class = 'heavy_modal';
    this.modalService.config = this.modelopt;

    this.CRMLEADNOTE = new Model_CRM_LEAD_Note();

    if (this.CRM_LeadService.model_CRM_Shared_lead.LeadId > 0) {
      this.CRMLEADNOTE.LeadId = this.CRM_LeadService.model_CRM_Shared_lead.LeadId;


    }

    if ((this.CRM_LeadService.model_CRM_Shared_lead.LeadId == undefined)) {
      this.rout.navigate(["/ManageMICEAccess/ManageLead/LeadBasicInfo"]);
      this.CRM_LeadService.SelectedCrmIndexPage = 0;
    }


    if (!(this.CRM_LeadService.model_CRM_Shared_lead.LeadId > 0)) {
      this.rout.navigate(["/ManageMICEAccess/ManageLead/LeadBasicInfo"]);
      this.CRM_LeadService.SelectedCrmIndexPage = 0;
    }


    if (localStorage.getItem('SuccessfullMessage')) {
      this.SuccessStatus = true;
      this.SuccessMessage = localStorage.getItem('SuccessfullMessage')
      localStorage.removeItem('SuccessfullMessage');
    }




    this.SegmentSettings = {
      singleSelection: false,
      text: 'Select Industry',
      enableSearchFilter: true,
      showCheckbox: true,
      position: 'top'
    }

    this.GetSegments();

  }

  // Begning Populate designation



  GetSegments() {

    let t = localStorage.getItem('userToken');
    t = 'Bearer' + t
    this.SegmentList = [];
    this.SegmentArray = [];
    this.CRM_LeadService.GetCRM_Segments(t).then(x => {
      this.Segmentobj = x;
      Object.keys(this.Segmentobj).forEach(key => {
        let segmenttemp = new Model_CRM_Segments();
        segmenttemp = this.Segmentobj[key];

        this.SegmentList.push(segmenttemp);
        this.SegmentArray.push({ "id": segmenttemp.SegmentId, "itemName": segmenttemp.Segmentname });
      });

    }).then(x => {
      if (this.CRM_LeadService.model_CRM_Shared_lead.SegmentIDs.length > 0) {

        this.SelectedSegment = [];
        for (let y of this.CRM_LeadService.model_CRM_Shared_lead.SegmentIDs) {
          let segment = this.SegmentList.find(x => x.SegmentId == y);

          this.SelectedSegment.push({ "id": segment.SegmentId, "itemName": segment.Segmentname });

        }

      }

    });
  }

  // end populate designation 




  get DOBMoreinfoM(): string {
    if (this.CRM_LeadService.model_CRM_Shared_lead.DOBMoreinfo == undefined || this.CRM_LeadService.model_CRM_Shared_lead.DOBMoreinfo == '') {

      this.CRM_LeadService.model_CRM_Shared_lead.DOBMoreinfo = '';
      return this.CRM_LeadService.model_CRM_Shared_lead.DOBMoreinfo;
    }
    else {
      return this.CRM_LeadService.model_CRM_Shared_lead.DOBMoreinfo;
    }
  }

  @Input('DOBMoreinfoM')
  set DOBMoreinfoM(value: string) {
    this.CRM_LeadService.model_CRM_Shared_lead.DOBMoreinfo = value;

  }




  AniversaryDate(dateanniver) {
    // this.CRM_LeadService.model_CRM_Shared_lead.AnniversaryDate = dateanniver;

    var dateParts = dateanniver.split("/");
    this.CRM_LeadService.model_CRM_Shared_lead.AnniversaryDate = new Date(+dateParts[2], dateParts[1], +dateParts[0]);
    this.CRM_LeadService.model_CRM_Shared_lead.AnniversaryDateMoreinfo = dateanniver

  }

  DateOfBirth(birthdate) {
    //this.CRM_LeadService.model_CRM_Shared_lead.DOB = birthdate;

    var dateParts = birthdate.split("/");
    this.CRM_LeadService.model_CRM_Shared_lead.DOB = new Date(+dateParts[2], dateParts[1], +dateParts[0]);
    this.CRM_LeadService.model_CRM_Shared_lead.DOBMoreinfo = birthdate

  }




  // onItemSelecDesignation(item: any) {

  //   let DesignationId = item['id'];
  //   this.CRM_LeadService.model_CRM_Shared_lead.DesignationID = DesignationId;
  // }
  // OnItemDeSelectDesignation(item: any) { }
  // onSelectAllDesignation(items: any) { }
  // onDeSelectAlldesignation(items: any) { }

  get IsInViewState(): boolean {
    if (this.CRM_LeadService.IsinViewState == undefined || this.CRM_LeadService.IsinViewState == null) {

      this.CRM_LeadService.IsinViewState= false;
    
      return this.CRM_LeadService.IsinViewState;
    }
    else {
      return this.CRM_LeadService.IsinViewState
    }
  }




  onItemSelecSegment(item: any) {
    if (this.CRM_LeadService.model_CRM_Shared_lead.SegmentIDs == undefined) {
      this.CRM_LeadService.model_CRM_Shared_lead.SegmentIDs = [];
    }

    let SegmentId = item['id'];
    this.CRM_LeadService.model_CRM_Shared_lead.SegmentIDs.push(SegmentId);

  }
  OnItemDeSelectSegment(itemv: any) {

    this.CRM_LeadService.model_CRM_Shared_lead.SegmentIDs.forEach((item, index) => {

      if (item === itemv['id']) this.CRM_LeadService.model_CRM_Shared_lead.SegmentIDs.splice(index, 1);
    });

  }
  onSelectAllSegment(items: any) {
    this.CRM_LeadService.model_CRM_Shared_lead.SegmentIDs = [];
    items.forEach(x => {

      this.CRM_LeadService.model_CRM_Shared_lead.SegmentIDs.push(x['id']);

    })



  }
  onDeSelectAllSegment(items: any) {

    this.CRM_LeadService.model_CRM_Shared_lead.SegmentIDs = [];
  }





  get SelectedSegments(): number {
    if (this.CRM_LeadService.model_CRM_Shared_lead.SegmentIDs == undefined || this.CRM_LeadService.model_CRM_Shared_lead.SegmentIDs.length == 0) {


      return 0;
    }
    else {
      return this.CRM_LeadService.model_CRM_Shared_lead.SegmentIDs.length;
    }
  }



  @Input('AnniversaryDateMoreinfoM')
  set AnniversaryDateMoreinfoM(value: string) {
    this.CRM_LeadService.model_CRM_Shared_lead.AnniversaryDateMoreinfo = value;
  }


  get AnniversaryDateMoreinfoM(): string {
    if (this.CRM_LeadService.model_CRM_Shared_lead.AnniversaryDateMoreinfo == undefined || this.CRM_LeadService.model_CRM_Shared_lead.AnniversaryDateMoreinfo == '') {

      this.CRM_LeadService.model_CRM_Shared_lead.AnniversaryDateMoreinfo = '';
      return this.CRM_LeadService.model_CRM_Shared_lead.AnniversaryDateMoreinfo;
    }
    else {
      return this.CRM_LeadService.model_CRM_Shared_lead.AnniversaryDateMoreinfo;
    }
  }










  saveCRMMoreInformation(): void {
    let t = localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.CRM_LeadService.model_CRM_Shared_lead.IsActive = true;


    if (this.CRM_LeadService.model_CRM_Shared_lead.LeadId > 0) {
      this.CRMLEADNOTE.LeadId = this.CRM_LeadService.model_CRM_Shared_lead.LeadId;

      this.CRM_LeadService.UpdateCrmLeadMoreInFo(t, this.CRM_LeadService.model_CRM_Shared_lead).subscribe(res => {
        this.updatedLeadId = res;

        if (this.updatedLeadId > 0) {
          localStorage.setItem('SuccessfullMessage', "Successfully Updated");
          this.CRM_LeadService.SelectedCrmIndexPage = 2;
          this.rout.navigate(["/ManageMICEAccess/ManageLead/Contacts"]);

        }
      });
    }

  }


  viewAppliedForNote(template) {
    this.GetCRMNote(this.CRM_LeadService.model_CRM_Shared_lead.LeadId);

    this.modelopt = new ModalOptions();
    this.modelopt.class = 'heavy_modal';
    this.modalService.config = this.modelopt;

    this.modalRef = this.modalService.show(template, this.modelopt);
    //this.GridShow=true;
    this.ShowPopup = true;
    //  this.ApprovedMsg=false;
    //  this.RejectedMsg=false;
    // Begning of the permission portion
    this.IsDataIsInViewState = false;
    // End of the permision portion
    this.ApprovedMsg = true;



  }


  doTextareaValueChange(ev) {
    try {
      this.CRMLEADNOTE.LeadNote = ev.target.value;
    } catch (e) {
      console.info('could not set textarea-value');
    }
  }

  GetCRMNote(leadid) {

    let t = localStorage.getItem('userToken');
    t = 'Bearer' + t

    this.CRM_LeadService.Get_CRMLeadNote(t, leadid).then(x => {
      this.Segmentobj = x;

      Object.keys(this.Segmentobj).forEach(key => {

        switch (key) {
          case 'LeadId': {
            this.CRMLEADNOTE.LeadId = this.Segmentobj[key];
            break;
          }

          case 'LeadNote': {
            this.CRMLEADNOTE.LeadNote = this.Segmentobj[key];
            break;
          }

          default: {
            console.log("Invalid choice");
            break;
          }
        }

      });

    });
  }



  saveNote(template): void {


    let t = localStorage.getItem('userToken');
    t = 'Bearer' + t;

    this.CRM_LeadService.AddupdateCrmLeadNote(t, this.CRMLEADNOTE).subscribe(res => {
      this.NoteId = res;
      if (this.NoteId > 0) {
        this.modalRef.hide();
        this.modelopt = new ModalOptions();
        this.modelopt.class = 'small_modal';
        this.modalService.config = this.modelopt;
        this.modalRef = this.modalService.show(template, this.modelopt);

      }
    });
  }







  RefreshPage() {

    this.CRM_LeadService.CancelLead();
    this.CRM_LeadService.SelectedCrmIndexPage = 0
    let RoutName_more = 'ManageMICEAccess/ManageLead/LeadBasicInfo'
    this.rout.navigate([RoutName_more]);

  }






}
