import { EmployeeEducationalModel } from './EmployeeEducationalModel';
import { EmployeeExprienceModel } from './EmployeeExprienceModel'
import { EmployeeFamilyDetailsModel } from './EmployeeFamilyDetailsModel'
import { EmployeeNomineeDetailsModel } from   './EmployeeNomineeDetailsModel'
import { OfferStandardSalary }  from '../Salary/OfferStandardSalary';
import {INgxMyDpOptions, IMyDateModel} from 'ngx-mydatepicker';

export class EmployeeDetail
{
    //Employee Personal Details
    public EmployeeId    : number;
    public EmployeeName : string;
    public EmployeeCode	 : String;
    public FirstName	 : String;
    public MiddleName	 : String;
    public LastName		 : String;
    public FullName		 : String;
    public CompanyId	 : number;
    public CompanyName	 : string;
    public BranchId		 : number;
    public BranchName	 : string;
    public DepartmentId	 : number;
    public DepartmentName	 : string;
    public DecisionId	 : number;
    public DecisionName	 : string;
    public LevelId		 : number;
    public LevelName	 : string;
    public GradeId		 : number;
    public GradeName	 : string;
    public DesignationId : number;
    public DesignationName	 : string;
    public FathersName	 : String;
    public MothersName	 : String;
    public DOB			 : any;
    public DOBPass       : string;
    public GenderId		 : number;
    public GenderName	 : string;
    public MaritalId	 : number;
    public MaritalName	 : string;
    public BloodGroup	 : String;
    public ReligionID    : number;
    public Religion		 : String;
    public ReligionName	 : String;
    public LanguageID    :number; 
    public MotherTongue	 : String;
    public ReportingHeadId	 : number;
    public ReportingHeadName	 : string;
    public FunctionalHeadId	 : number;
    public FunctionalHeadName	 : string;
    //Begin Photo Upload
    public PhotoUpload	 : String;
    public IsPhotochange  : boolean;
    public Immage : string;
    public PhotoType : string;
    public Photodoctype : string;
    public validfile : boolean;
    public Isphotochange  : boolean;
    //End Photo Upload
    public EmailId		 : String;
    public ContactNumber : number;
    public OfferDate	 : Date;
    public OfferDatePass       : string;
    public JoiningDate	 : Date;
    public JoiningDatePass       : string;
    public IsActive		 : boolean;
    public JoiningId	 : number;
    public OfferId	 : number;
    //Employee Personal Details
    //Educational Details
    public EmployeeEducation  :  EmployeeEducationalModel [];
    //Experince Details
    public   EmployeeExprienceModel    : EmployeeExprienceModel [];
    //Family Details
    public EmployeeFamilyDetailsModel : EmployeeFamilyDetailsModel[];
    //Nominee Details
    public EmployeeNomineeDetailModel : EmployeeNomineeDetailsModel[];
    //Employee others Details
    public OthesRefId: number;
    public PANNo: string;
    public VoterId: string;
    public AdhaarNo: string;
    public EPFNo: string;
    public ESINo: string;
    public GratuityNo: string;
    public PensionNo: string;
    public SuperannuationNo: string;
    public UANNo: string;
    public AnniversaryDatePass : string;
    public AnniversaryDate: any;
    public PaymentModeId : number;
    public PaymentModeName : string;
    public CTCAmount: number;
    public GrossSalary: number;
    public NetTakeHome: number;
    public EPFApplicable  : boolean;
    public ESIApplicable  : boolean;
    public PTAXApplicable  : boolean;
    public PtaxStateId : number;
    //Employee Bank Details
    public ExistsBankId : number;
    public BankId : number;
    public EmpBankRefId: number;
    public BankName: string;
    public BankBranchName: string;
    public AccountNumber: string;
    public IFSCCode: string;

    //Employee Address Details
    public AddressRefId: number;
    public PAddress1: string;
    public PAddress2: string;
    public PCountry: string;
    public PCountryId: number;
    public PState: string;
    public PStateId: number;
    public PDistrict: string;
    public PDistrictId: number;
    public PCity: string;
    public PCityId: number;
    public PPincode: string;
    public PContactNo: string;
    public CAddress1: string;
    public CAddress2: string;
    public CCountry: string;
    public CCountryId: number;
    public CState: string;
    public CStateId: number;
    public CDistrict: string;
    public CDistrictId: number;
    public CCity: string;
    public CCityId: number;
    public CPincode: string;
    public CContactNo: string;
    public Emailid: string;
    public WorkingGroupId: number;
    public VerificationStatusId: number;
    public VerificationStatus:string;
    public BranchStateId: number;
    public RegionHRId: number;
    
    public OfferSalary : OfferStandardSalary[];
    public SameAddress : boolean;
    ReturnFormatdate( datetochange : string ) : any
    {
        let toArray =datetochange.split("/");
        var year = Number(toArray[0]);
        var day = Number(toArray[1]);
        let date1 = { date: { year: toArray[2], month:year, day: day } };
        return date1;
    }

    // Employee details Modification
    public PersonalDetails : boolean;
    public AddressDetails : boolean;
    public OtherDetails : boolean;
    public QualificationDetails : boolean;
    public ExperienceDetails : boolean;
    public FamilyDetails : boolean;
    public Reason : string;
    public Remarks : string;
    public ImageType : string;
    public ImageFile : string;
    public RefIdApplication : number;
    public IsApproved : boolean;
    public IsRejected : boolean;
}