import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from '../../shared/user.service';
import { EmployeePortalService }  from '../../shared/HR_ADMIN/EmployeePortal/EmployeePortalService.service';
import { EmployeeMasterService }  from '../../shared/HR_ADMIN/Employee/EmployeeMaster.Service';
import { EmployeeDetail } from '../../shared/HR_ADMIN/Employee/EmployeeDetail.Model';
import { LeaveApplicationModel } from '../../shared/HR_ADMIN/EmployeePortal/LeaveApplicationModel.Model';
import { EmployeeLeaveBalanceModel } from '../../shared/HR_ADMIN/EmployeePortal/EmployeeLeaveBalanceModel.Model';
import { LeaveType } from '../../shared/HR_ADMIN/Leave/LeaveType.Model';
import { Menu } from '../../shared/menu.model';

@Component({
  selector: 'app-all-employee-leave-apply',
  templateUrl: './all-employee-leave-apply.component.html',
  styleUrls: ['./all-employee-leave-apply.component.css']
})

export class AllEmployeeLeaveApplyComponent implements OnInit 
{
  LeaveApplicationModel : LeaveApplicationModel= new LeaveApplicationModel();
  EmployeeLeaveApplication: LeaveApplicationModel[]=[];
  LeaveApplication: any;
  EmployeeLeaveBalance: EmployeeLeaveBalanceModel[]=[];
  LeaveBalance: any;
  Employees: EmployeeDetail[] = [];
  employee: any;
  LeaveTypeMain: LeaveType[]=[];
  LeaveType: any;
  RefId : any;
  userClaims: any;
  Result : string;
  SuccessStatus:boolean=false;
  SuccessMessage: string ="";
  public tableWidget: any;
  selectedIndex : number;
  // Begning Permission Portion    
  SelectedMenu:Menu;
  IsDataIsInViewState : boolean=false;
  CanViewState : boolean=true;
  IsApplicationView : boolean=false;
  DisableSave : boolean=false;
  // End of the boolean portion
  // begin for Validation
  validTodate : boolean= false;
  validFromdate : boolean= false;
  FromdateOffMSG : boolean= false;
  FromdateHalfdayMSG : boolean= false;
  TodateOffMSG : boolean= false;
  TodateHalfdayMSG : boolean= false;
  // end of validation
  FromFirstHalf : boolean= true;
  FromSecondHalf : boolean= false;
  FromValue:string='FirstHalf';
  ToFirstHalf : boolean= true;
  ToSecondHalf : boolean= false;
  ToValue:string='FirstHalf';
  ShowMessage : boolean=false;
  ShowBalanceMessage : boolean=false;
  Differencevalue: number;
  EmployeeSettings = {};
  selectedItems = [];
  emp : any [] =[]
  LeaveExistsValue: any;
  LeaveExistsStatus: boolean;

  constructor(private EmployeeMasterService: EmployeeMasterService, private rout: Router, private userService: UserService,private EmployeePortalService: EmployeePortalService) 
  {  }

  ngOnInit() 
  {
    // Begning Permission Portion 
    this.SelectedMenu=this.userService.SelectedMenu;
    if(this.SelectedMenu !=undefined)
    {
      localStorage.setItem('selectedmenu',JSON.stringify(this.SelectedMenu));
      this.rout.navigate([this.SelectedMenu.RouteName]);
    }
    else
    {
      if(JSON.parse(localStorage.getItem('selectedmenu'))!=undefined)
      {
        this.SelectedMenu = JSON.parse(localStorage.getItem('selectedmenu'));
        this.rout.navigate([this.SelectedMenu.RouteName]);
      }
    }
    if(this.SelectedMenu.CanAdd)
    {
      this.IsDataIsInViewState=false;
    }
    else
    {
      this.IsDataIsInViewState=true;
    }
    if(this.SelectedMenu.CanView)
    {
      this.CanViewState=false;
    }
    else
    {
      this.CanViewState=true;
    }
    // End of the permission portion
    if(localStorage.getItem('SuccessfullMessage'))
    {
      this.SuccessStatus=true;
      this.SuccessMessage =localStorage.getItem('SuccessfullMessage')
      localStorage.removeItem('SuccessfullMessage');
    }
    this.GetEmployeeDetailsDDL();
    this.LeaveApplicationModel.EmployeeId=0;
    this.LeaveApplicationModel.LeaveTypeId=0;
    this.LeaveApplicationModel.NoDaysLeave=0;
    this.FromFirstHalf==true;
    this.FromSecondHalf==false;
    this.LeaveApplicationModel.Fromvalue="FirstHalf";
    this.ToFirstHalf==false;
    this.ToSecondHalf==true; 
    this.LeaveApplicationModel.Tovalue="SecondHalf";
    this.Differencevalue=0;
    this.DisableSave=true;
    this.FromdateOffMSG=false;
    this.FromdateHalfdayMSG=false;
    this.TodateOffMSG=false;
    this.TodateHalfdayMSG=false;
    this.EmployeeSettings =
    {
      singleSelection:true,
      text:'Select Employee',
      enableSearchFilter:true,
      showCheckbox:false
    }
  }

  onItemSelect(item:any)
  {
    let empid= item['id'];
    //this.LeaveApplicationModel.EmployeeId=empid; 
    if(empid>0)
    {
      this.IsApplicationView=true;
      this.Clear();
      this.GetEmployeeLeaveBalanceDetails(empid);
      this.GetEmployeeDetailsForLeaveApply(empid);
      this.GetEmployeeLeaveApplicationDetails(empid);
      this.GetLeaveType(empid);
    }     
  }
  OnItemDeSelect(item:any){ }
  onSelectAll(items: any){ }
  onDeSelectAll(items: any){ }

  GetEmployeeDetailsDDL() 
  {
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.EmployeeMasterService.GetEmployeeDetailsForDDL( t).then(x => 
    {
      this.employee = x;
      Object.keys(this.employee).forEach( key => 
      {
        let RHtemp = new EmployeeDetail();
        RHtemp = this.employee[key];
        this.Employees.push(RHtemp);
        this.emp.push({"id":RHtemp.EmployeeId,"itemName":RHtemp.EmployeeCode + '-' +RHtemp.EmployeeName},);
      });     
    });      
  }

  GetEmployeeLeaveApplicationDetails(EmployeeId)
  {
    this.EmployeeLeaveApplication=[];
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.EmployeePortalService.GetEmployeeLeaveApplicationByEmployeeId( t,EmployeeId).then(x => 
    {
      this.LeaveApplication = x;
      Object.keys(this.LeaveApplication).forEach( key => 
      {
        this.LeaveApplication[key];
        let LeaveApplicationtemp = new LeaveApplicationModel();
        LeaveApplicationtemp = this.LeaveApplication[key];
        this.EmployeeLeaveApplication.push(LeaveApplicationtemp);
      });
    });
  }

  GetEmployeeLeaveBalanceDetails(EmployeeId)
  {
    this.EmployeeLeaveBalance=[];
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.EmployeePortalService.GetEmployeeLeaveBalanceByEmployeeId( t,EmployeeId).then(x => 
    {
      this.LeaveBalance = x;
      Object.keys(this.LeaveBalance).forEach( key => 
      {
        this.LeaveBalance[key];
        let LeaveBalancetemp = new EmployeeLeaveBalanceModel();
        LeaveBalancetemp = this.LeaveBalance[key];
        this.EmployeeLeaveBalance.push(LeaveBalancetemp);
      });
    });
  }

  GetEmployeeDetailsForLeaveApply(EmployeeId)
  {
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.EmployeePortalService.GetEmployeeDetailsByEmployeeIdLeaveApply( t,EmployeeId).then(x => 
    {
      this.LeaveApplication = x;
      Object.keys(this.LeaveApplication).forEach( key => 
      {
        this.LeaveApplication[key];
        let LeaveApplicationtemp = new LeaveApplicationModel();
        LeaveApplicationtemp = this.LeaveApplication[key];
        this.LeaveApplicationModel.EmployeeId=this.LeaveApplication.EmployeeId;
        this.LeaveApplicationModel.EmployeeCode=this.LeaveApplication.EmployeeCode;
        this.LeaveApplicationModel.EmployeeName=this.LeaveApplication.EmployeeName;
        this.LeaveApplicationModel.EmployeeEmailId=this.LeaveApplication.EmployeeEmailId;
        this.LeaveApplicationModel.RHId=this.LeaveApplication.RHId;
        this.LeaveApplicationModel.RHCode=this.LeaveApplication.RHCode;
        this.LeaveApplicationModel.RHName=this.LeaveApplication.RHName;
        this.LeaveApplicationModel.RHEmailId=this.LeaveApplication.RHEmailId;
        this.LeaveApplicationModel.ApplicationDate=this.LeaveApplication.ApplicationDate;
        this.LeaveApplicationModel.CompanyId=this.LeaveApplication.CompanyId;
      });
    });
  }

  GetLeaveType(EmployeeId)
  {
    this.LeaveTypeMain=[];
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.EmployeePortalService.GetLeaveTypeByEmployeeId( t,EmployeeId).then(x => 
    {
      this.LeaveType = x;
      Object.keys(this.LeaveType).forEach( key => 
      {
        this.LeaveType[key];
        let LeaveTypetemp = new LeaveType();
        LeaveTypetemp = this.LeaveType[key];
        this.LeaveTypeMain.push(LeaveTypetemp);
      });
    });
  }

  FromDateChanged( date)
  {
    this.LeaveApplicationModel.FromDate=date;
    this.LeaveApplicationModel.NoDaysLeave=0;
    var CurrentDate = new Date();
    var CurrentYear = CurrentDate.getFullYear();
    var currentMonth = CurrentDate.getMonth();
    var CurrentDay = CurrentDate.getDate();
    
    var selectedDate = new Date(date);
    var selectedYear=selectedDate.getFullYear();
    var selectedMonth=selectedDate.getMonth();
    var selectedDay=selectedDate.getDate();

    if(CurrentYear != selectedYear)
    {
     this.validFromdate = true;      
    }
    else
    {
      let selecteddateString = selectedDate.toDateString();
      let newselectedDate = new Date(selecteddateString);
      let CurrentdateString = CurrentDate.toDateString(); 
      let newCurrentDate = new Date(CurrentdateString);
      if (newselectedDate >= newCurrentDate)
      {
        this.validFromdate = false;
      }
      else if (currentMonth == selectedMonth)
      {
        if(CurrentDay <=  25)
        {
          this.validFromdate = false;
        }
        else
        {
          if(selectedDay > 25)
          {
            this.validFromdate = false;
          }
          else
          {
            this.validFromdate = true;
          }
        }
      }
      else if (currentMonth == 0 && selectedMonth == 11)
      {
        if(selectedDay >= 26 )
        {
          this.validFromdate = false;
        }
        else
        {
          this.validFromdate = true;
        }
      }
      else if ((currentMonth-1) == selectedMonth)
      {
        if(selectedDay >= 26 )
        {
          this.validFromdate = false;
        }
        else
        {
          this.validFromdate = true;
        }
      }
      else
      {
        this.validFromdate = true;
      }
    }
    if(this.validFromdate == false)
    {
      this.FromDateEmployeeWorkingDayStatus(this.LeaveApplicationModel.EmployeeId,date);
    }
    else
    {
      this.ShowMessage = null;
      this.FromdateOffMSG = null;
      this.FromdateHalfdayMSG = null;
      this.ShowBalanceMessage = null;
      this.LeaveExistsStatus = null;
    }
  }

  ToDateChanged( date)
  {
    this.LeaveApplicationModel.ToDate=date;
    this.LeaveApplicationModel.NoDaysLeave=0;
    var CurrentDate = new Date();
    var CurrentYear = CurrentDate.getFullYear();
    var currentMonth = CurrentDate.getMonth();
    var CurrentDay = CurrentDate.getDate();
    
    var selectedDate = new Date(date);
    var selectedYear=selectedDate.getFullYear();
    var selectedMonth=selectedDate.getMonth();
    var selectedDay=selectedDate.getDate();

    if(CurrentYear != selectedYear)
    {
     this.validTodate = true;      
    }
    else
    {
      let selecteddateString = selectedDate.toDateString();
      let newselectedDate = new Date(selecteddateString);
      let CurrentdateString = CurrentDate.toDateString(); 
      let newCurrentDate = new Date(CurrentdateString);
      if (newselectedDate >= newCurrentDate)
      {
        this.validTodate = false;
      }
      else if (currentMonth == selectedMonth)
      {
        if(CurrentDay <=  25)
        {
          this.validTodate = false;
        }
        else
        {
          if(selectedDay > 25)
          {
            this.validTodate = false;
          }
          else
          {
            this.validTodate = true;
          }
        }
      }
      else if (currentMonth == 0 && selectedMonth == 11)
      {
        if(selectedDay >= 26 )
        {
          this.validTodate = false;
        }
        else
        {
          this.validTodate = true;
        }
      }
      else if ((currentMonth-1) == selectedMonth)
      {
        if(selectedDay >= 26 )
        {
          this.validTodate = false;
        }
        else
        {
          this.validTodate = true;
        }
      }
      else
      {
        this.validTodate = true;
      }
    }
    if(this.validTodate == false)
    {
      this.ToDateEmployeeWorkingDayStatus(this.LeaveApplicationModel.EmployeeId,date);
    }
    else
    {
      this.ShowMessage = null;
      this.FromdateOffMSG = null;
      this.FromdateHalfdayMSG = null;
      this.ShowBalanceMessage = null;
      this.LeaveExistsStatus = null;
    }
  }

  onChangeLeaveTypeId(LeaveTypeId)
  {
    if(LeaveTypeId!=0)
    {
      this.LeaveApplicationModel.LeaveBalance=0;
      this.GetLeaveBalanceByLeaveType(LeaveTypeId,this.LeaveApplicationModel.EmployeeId);
    }
    else
    {
      this.LeaveApplicationModel.LeaveBalance=0;
    }  
    if((this.LeaveApplicationModel.LeaveBalance - this.LeaveApplicationModel.NoDaysLeave)>=0)
    {
      this.ShowBalanceMessage=false;
    }
    else
    {
      this.ShowBalanceMessage=true;
    }      
  }

  GetLeaveBalanceByLeaveType(LeaveTypeId,EmployeeId)
  {
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.EmployeePortalService.GetEmployeeLeaveBalanceByLeaveType( t,LeaveTypeId,EmployeeId).then(x => 
    {
      this.LeaveApplication = x;
      Object.keys(this.LeaveApplication).forEach( key => 
      {
        this.LeaveApplication[key];
        let LeaveApplicationtemp = new LeaveApplicationModel();
        LeaveApplicationtemp = this.LeaveApplication[key];
        this.LeaveApplicationModel.LeaveBalance=this.LeaveApplication.LeaveBalance;
        if((this.LeaveApplicationModel.LeaveBalance - this.LeaveApplicationModel.NoDaysLeave)>=0)
        {
          this.ShowBalanceMessage=false;
        }
        else
        {
          this.ShowBalanceMessage=true;
        }  
      });
    });
  }
  
  OnChangeFromFirstHalf(evt)
  {
    var target = evt.target;
    if(target.checked)
    {           
      this.LeaveApplicationModel.FromFirstHalf=true;
      this.LeaveApplicationModel.FromSecondHalf=false; 
      this.LeaveApplicationModel.Fromvalue="FirstHalf";
    }
    if(this.LeaveApplicationModel.EmployeeId > 0 && this.LeaveApplicationModel.FromDate != null && this.LeaveApplicationModel.ToDate != null)
    {
      this.LeaveExistsStatus=null;
      this.NoOfDaysLeaveApply();
    }
  }

  OnChangeFromSecondHalf(evt)
  {
    var target = evt.target;
    if(target.checked)
    {           
      this.LeaveApplicationModel.FromFirstHalf=false;
      this.LeaveApplicationModel.FromSecondHalf=true; 
      this.LeaveApplicationModel.Fromvalue="SecondHalf";
    }
    if(this.LeaveApplicationModel.EmployeeId > 0 && this.LeaveApplicationModel.FromDate != null && this.LeaveApplicationModel.ToDate != null)
    {
      this.LeaveExistsStatus=null;
      this.NoOfDaysLeaveApply();
    }
  }
  
  OnChangeToFirstHalf(evt)
  {
    var target = evt.target;
    if(target.checked)
    {           
      this.LeaveApplicationModel.ToFirstHalf=true;
      this.LeaveApplicationModel.ToSecondHalf=false; 
      this.LeaveApplicationModel.Tovalue="FirstHalf";
    }
    if(this.LeaveApplicationModel.EmployeeId > 0 && this.LeaveApplicationModel.FromDate != null && this.LeaveApplicationModel.ToDate != null)
    {
      this.LeaveExistsStatus=null;
      this.NoOfDaysLeaveApply();
    }
  }

  OnChangeToSecondHalf(evt)
  {
    var target = evt.target;
    if(target.checked)
    {           
      this.LeaveApplicationModel.ToFirstHalf=false;
      this.LeaveApplicationModel.ToSecondHalf=true; 
      this.LeaveApplicationModel.Tovalue="SecondHalf";
    }
    if(this.LeaveApplicationModel.EmployeeId > 0 && this.LeaveApplicationModel.FromDate != null && this.LeaveApplicationModel.ToDate != null)
    {
      this.LeaveExistsStatus=null;
      this.NoOfDaysLeaveApply();
    }
  }

  NoOfDaysLeaveApply()
  {
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.EmployeePortalService.LeaveDateExistsChecking( t,this.LeaveApplicationModel.FromDate,this.LeaveApplicationModel.ToDate,this.LeaveApplicationModel.EmployeeId).then(res => 
    {
      this.LeaveExistsValue = res;
      if(this.LeaveExistsValue == 1)
      {
        this.LeaveExistsStatus=true;
      }
      else
      {
        this.LeaveExistsStatus = false;
        if(this.LeaveExistsStatus == false)
        {
          if(this.LeaveApplicationModel.ToDate < this.LeaveApplicationModel.FromDate)
          {
            this.ShowMessage = true;
          }
          this.LeaveApplicationModel.NoDaysLeave=0;
          if(this.LeaveApplicationModel.FromOffday==true)
          {
            this.FromdateOffMSG=true;
          }
          else
          {
            this.FromdateOffMSG=false;
          }
          if(this.LeaveApplicationModel.ToOffday == true)
          {
            this.TodateOffMSG=true;
          }
          else
          {
            this.TodateOffMSG=false;
          }

          if(this.LeaveApplicationModel.FromHalfday == true && this.LeaveApplicationModel.FromSecondHalf==true)
          {
            this.FromdateHalfdayMSG=true;
          }
          else
          {
            this.FromdateHalfdayMSG=false;
          }
          if(this.LeaveApplicationModel.ToHalfday==true && this.LeaveApplicationModel.ToFirstHalf==true)
          {
            this.TodateHalfdayMSG=true;
          }
          else
          {
            this.TodateHalfdayMSG=false;
          }

          if(this.LeaveApplicationModel.FromDate!=undefined && this.LeaveApplicationModel.ToDate!=undefined &&this.LeaveApplicationModel.Fromvalue!=undefined &&this.LeaveApplicationModel.Tovalue!=undefined)
          {
            if(this.LeaveApplicationModel.ToDate >= this.LeaveApplicationModel.FromDate)
            {
              this.ShowMessage=false;
              if(this.LeaveApplicationModel.FromDate == this.LeaveApplicationModel.ToDate)
              {
                if(this.LeaveApplicationModel.Fromvalue == this.LeaveApplicationModel.Tovalue)
                {
                  this.LeaveApplicationModel.NoDaysLeave=0.5;
                }
                else if(this.LeaveApplicationModel.Fromvalue=='SecondHalf' && this.LeaveApplicationModel.Tovalue=='FirstHalf')
                {
                  this.ShowMessage=true;
                }
                else
                {
                  this.LeaveApplicationModel.NoDaysLeave=1;
                }
                if((this.LeaveApplicationModel.LeaveBalance - this.LeaveApplicationModel.NoDaysLeave)>=0)
                {
                  this.ShowBalanceMessage=false;
                }
                else
                {
                  this.ShowBalanceMessage=true;
                }
              }
              else
              {
                let t =  localStorage.getItem('userToken');
                t = 'Bearer' + t;
                this.EmployeePortalService.DateDifference( t,this.LeaveApplicationModel.FromDate,this.LeaveApplicationModel.ToDate,this.LeaveApplicationModel.EmployeeId).then(x => 
                {
                  this.LeaveApplication = x;
                  this.Differencevalue=this.LeaveApplication.NoDaysLeave;
                  if(this.LeaveApplicationModel.Fromvalue == this.LeaveApplicationModel.Tovalue)
                  {
                    this.LeaveApplicationModel.NoDaysLeave=this.Differencevalue - 0.5;
                  }
                  else if(this.LeaveApplicationModel.Fromvalue =='SecondHalf' && this.LeaveApplicationModel.Tovalue=='FirstHalf')
                  {
                    this.LeaveApplicationModel.NoDaysLeave=this.Differencevalue - 1;
                  }
                  else
                  {
                    this.LeaveApplicationModel.NoDaysLeave=this.Differencevalue;
                  }
                  if((this.LeaveApplicationModel.LeaveBalance - this.LeaveApplicationModel.NoDaysLeave)>=0)
                  {
                    this.ShowBalanceMessage=false;
                  }
                  else
                  {
                    this.ShowBalanceMessage=true;
                  }
                });
              }
            }
          }
          else
          {
            this.ShowMessage=true;
          }
        }
      }
    });
  }

  SaveLeaveApply() :void
  {
    this.DisableSave=false;
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.EmployeePortalService.AddEmployeeLeaveApply(t ,this.LeaveApplicationModel).subscribe(res=>
    {
      this.RefId=res;
      this.LeaveApplicationModel.LeaveId=0;              
      if(this.RefId>0)
      {
        localStorage.setItem('SuccessfullMessage', "Leave Apply Successfully");
        this.rout.navigateByUrl('/Employee/EmployeeOffer', {skipLocationChange: true}).then(()=>this.rout.navigate(["/Employee/AllEmployeeLeaveApply"]));
      }
    }); 
  }

  RefreshPage()
  {
    this.rout.navigateByUrl('/Employee/EmployeeOffer', {skipLocationChange: true}).then(()=>this.rout.navigate(["/Employee/AllEmployeeLeaveApply"]));
  }

  //Working Day Status
  FromDateEmployeeWorkingDayStatus(EmployeeId,Date)
  {
    this.DisableSave==false;
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.EmployeePortalService.EmployeeWorkingDayStatus( t,EmployeeId,Date).then(x => 
    {
      this.LeaveApplication = x;
      Object.keys(this.LeaveApplication).forEach( key => 
      {
        this.LeaveApplication[key];
        let LeaveApplicationtemp = new LeaveApplicationModel();
        LeaveApplicationtemp = this.LeaveApplication[key];
        this.LeaveApplicationModel.FromOffday=LeaveApplicationtemp.IsOffday;
        this.LeaveApplicationModel.FromHalfday=LeaveApplicationtemp.IsHalfday;
        if(this.LeaveApplicationModel.EmployeeId > 0 && this.LeaveApplicationModel.FromDate != null && this.LeaveApplicationModel.ToDate != null)
        {
          this.LeaveExistsStatus=null;
          this.NoOfDaysLeaveApply();
        }
      });
    });
    this.DisableSave==true;
  }

  ToDateEmployeeWorkingDayStatus(EmployeeId,Date)
  {
    this.DisableSave==false;
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.EmployeePortalService.EmployeeWorkingDayStatus( t,EmployeeId,Date).then(x => 
    {
      this.LeaveApplication = x;
      Object.keys(this.LeaveApplication).forEach( key => 
      {
        this.LeaveApplication[key];
        let LeaveApplicationtemp = new LeaveApplicationModel();
        LeaveApplicationtemp = this.LeaveApplication[key];
        this.LeaveApplicationModel.ToOffday=LeaveApplicationtemp.IsOffday;
        this.LeaveApplicationModel.ToHalfday=LeaveApplicationtemp.IsHalfday;
        if(this.LeaveApplicationModel.EmployeeId > 0 && this.LeaveApplicationModel.FromDate != null && this.LeaveApplicationModel.ToDate != null)
        {
          this.LeaveExistsStatus=null;
          this.NoOfDaysLeaveApply();
        }
      });
    });
    this.DisableSave==true;
  }

  Clear()
  {
    this.LeaveApplicationModel.EmployeeId=0;
    this.LeaveApplicationModel.EmployeeCode='';
    this.LeaveApplicationModel.EmployeeName='';
    this.LeaveApplicationModel.RHId=0;
    this.LeaveApplicationModel.RHCode='';
    this.LeaveApplicationModel.RHName='';
    this.LeaveApplicationModel.RHEmailId='';
    this.LeaveApplicationModel.LeaveTypeId=0;
    this.LeaveApplicationModel.FromDate=null;
    this.LeaveApplicationModel.ToDate=null;
    this.LeaveApplicationModel.ReasonsLeave=null;
    this.LeaveApplicationModel.PersonToContact=null;
    this.LeaveApplicationModel.ContactNoDuringLeave=null;
    this.LeaveApplicationModel.AddressDuringLeave=null;
    this.LeaveApplicationModel.RemarksLeave=null;
    this.LeaveApplicationModel.NoDaysLeave=0;
  }
}