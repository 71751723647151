import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from '../../shared/user.service';
import { AdminMasterService} from '../../shared/HR_ADMIN/Admin/AdminMasterService.service';
import { TDSSurchargeModel } from  '../../shared/HR_ADMIN/Admin/TDSSurchargeModel.Model';
import { Menu } from '../../shared/menu.model';

@Component({
  selector: 'app-manage-tdssurcharge-slab',
  templateUrl: './manage-tdssurcharge-slab.component.html',
  styleUrls: ['./manage-tdssurcharge-slab.component.css']
})
export class ManageTdssurchargeSlabComponent implements OnInit 
{
  TDSSurchargeModel : TDSSurchargeModel= new TDSSurchargeModel();
  //userClaims: any;
  TDSSurcharges: TDSSurchargeModel[] = [];
  Surcharge: any;
  //Result : string;
  SuccessStatus:boolean=false;
  SuccessMessage: string ="";
  //public tableWidget: any;
  RefId : any;
  //tempSurchargeadd : TDSSurchargeModel =new TDSSurchargeModel();

  // Begning Permission Portion    
  SelectedMenu:Menu;
  IsDataIsInViewState : boolean=false;
  CanViewState : boolean=false;
  IsFormViewShow : boolean=false;
  DisableSave : boolean=false;
  // End of the permission portion
  // begin for Validation
  validdate : boolean= false;
  IsGridInViewState : boolean= false;
  // end of validation

  constructor(private AdminMasterService: AdminMasterService, private rout: Router, private userService: UserService) { }

  
  ngOnInit() 
  {
    //Begin Permission Portion
    this.SelectedMenu=this.userService.SelectedMenu;
    if(this.SelectedMenu !=undefined)
    {
      localStorage.setItem('selectedmenu',JSON.stringify(this.SelectedMenu));
      this.rout.navigate([this.SelectedMenu.RouteName]);
    }
    else
    {
      if(JSON.parse(localStorage.getItem('selectedmenu'))!=undefined)
      {
        this.SelectedMenu = JSON.parse(localStorage.getItem('selectedmenu'));
        this.rout.navigate([this.SelectedMenu.RouteName]);
      }
    }
    if(this.SelectedMenu.CanAdd)
    {
    this.IsDataIsInViewState=false;
    }
    else
    {
      this.IsDataIsInViewState=true;
    }
    if(this.SelectedMenu.CanView)
    {
    this.CanViewState=false;
    }
    else
    {
      this.CanViewState=true;
    }
    this.IsFormViewShow=false;
    //End of the peremission portion
    this.IsGridInViewState=false;
    this.DisableSave=true;
    this.GetTDSSurchargeDetails();
    if(localStorage.getItem('SuccessfullMessage'))
    {
      this.SuccessStatus=true;
      this.SuccessMessage =localStorage.getItem('SuccessfullMessage')
      localStorage.removeItem('SuccessfullMessage');
    }
  }
  
  GetTDSSurchargeDetails() 
  { 
    // Begning of the permission portion
    this.IsDataIsInViewState=false;
    this.IsFormViewShow=true;
    // End of the permision portion
    this.TDSSurchargeModel.ApplicableFromDate=null;
    this.validdate=false;
    this.TDSSurchargeModel.IsActive=false;
    let t = localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.AdminMasterService.GetTDSSurchargeDetails( t).then(x => 
    {
      this.IsGridInViewState=false;
      this.RefId = x;
      if(this.RefId.length > 0)
      {
        this.TDSSurcharges=[];
      }
      Object.keys(this.RefId).forEach( key => 
      {
        // tslint:disable-next-line:no-unused-expression
        this.RefId[key];
        let tdstemp = new TDSSurchargeModel();
        tdstemp = this.RefId[key];
        this.TDSSurcharges.push(tdstemp);
        this.TDSSurchargeModel.ApplicableFromDate=tdstemp.ApplicableFromDate;
        this.TDSSurchargeModel.ApplicableDateCheck=tdstemp.ApplicableFromDate;
        this.TDSSurchargeModel.IsActive=tdstemp.IsActive;
        this.validdate=true;
      });
      if(this.TDSSurcharges.length == 0 )
      {
        let tdsList : TDSSurchargeModel[]=[];
        for( var i=0; i<3; i++ )
        {
          let tempTDS = new TDSSurchargeModel();          
          tdsList.push(tempTDS);         
        }
        this.TDSSurcharges=tdsList;
      }
    });
  }

  RefreshPage()
  {
    this.rout.navigateByUrl('/Admin/ManageFinancialYear', {skipLocationChange: true}).then(()=>this.rout.navigate(["/Admin/SurchargeMaster"]))
  }
 
  dateChanged( date)
  {
    this.TDSSurchargeModel.ApplicableFromDate=date;
    var CurrentDate = new Date();
    var CurrentYear = CurrentDate.getFullYear();
    var currentMonth = CurrentDate.getMonth();
    var CurrentDay = CurrentDate.getDate();
    
    var selecteedDate = new Date(date);
    var selectedYear=selecteedDate.getFullYear();
    var selectedMonth=selecteedDate.getMonth();
    var selectedDay=selecteedDate.getDate();

    if(CurrentYear < selectedYear )
    {
      this.validdate = true;      
    }
    else if(CurrentYear > selectedYear)
    {
      this.validdate = false;
    }
    else
    {
      if (currentMonth < selectedMonth)
      {
        this.validdate = true;
      }
      else if(currentMonth > selectedMonth)
      {
        this.validdate = false;
      }
      else
      {
        if(CurrentDay <= selectedDay )
        {
          this.validdate = true;
        }
        else
        {
          this.validdate = false;
        }
      }
    }
  }
  
  AddnweRow()
  {
    let tdstemp = new TDSSurchargeModel();          
    this.TDSSurcharges.push(tdstemp);     
  }

  SaveTDSSurchargeSlab() :void
  {
    this.DisableSave=false;
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;    
    var j=0;
    var l=this.TDSSurcharges.length;
    this.TDSSurcharges.forEach(item => 
    {
      j=j+1;
      if(item.ToAmount >0)
      {     
        item.ApplicableFromDate=this.TDSSurchargeModel.ApplicableFromDate;
        item.ApplicableDateCheck=this.TDSSurchargeModel.ApplicableDateCheck;
        if(item.RefId==null)
        {
          item.RefId=0;
        }
        this.AdminMasterService.AddTDSSurcharge(t ,item).subscribe(res=>
        {
          this.RefId=res;        
          if(this.RefId>0)
          {
            localStorage.setItem('SuccessfullMessage', "Modify Successfully");
            this.rout.navigateByUrl('Admin/ManageFinancialYear', {skipLocationChange: true}).then(()=>this.rout.navigate(["Admin/SurchargeMaster"]));     
          }
        }); 
      }
    });   
  }
}