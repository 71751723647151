<div class="body-content-area tabcontent" id="link1">
<div class="row">
    <div class="col-lg-9">
        <div class="row">
            <div class="col-lg-1 low-padding text-center">
                <!-- <img *ngIf="this.SelectedMenu.Image!=null" src={{this.SelectedMenu.Image}} class="body-icon" alt="Icon"/> -->
            </div>
            <div class="col-lg-10 clear-padding">
                <span class="page-title">Manage State</span>
                <small class="page-description"></small>
                <div class="col-lg-10 clear-padding" *ngIf="!(this.CanViewState)">
                    <button *ngIf="(this.CanSaveButtonView)" (click)="ADDClick()" type="button" class="btn btn-primary Add-button">ADD New</button> 
                </div>
            </div>
        </div>
    </div>
</div>
<div *ngIf="!(this.CanViewState)">
    <app-dialog [(visible)]="this.SuccessStatus">
        <span class="successmsg">{{this.SuccessMessage}}</span>
    </app-dialog>
    <fieldset *ngIf="!(this.FormViewShow)" class="body-form-control">
        <legend class="legend--">State Details</legend>
        <table id="tblState" *ngIf="!(this.FormViewShow)" datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" class="table table-striped table-bordered text-center table-new row-border hover">
            <thead class="thead-blue text-center">
                <tr>
                    <th>Sl No.</th>
                    <th>State Code</th>
                    <th>State Name</th>
                    <th>Country Name</th>
                    <th>Zone Name</th>
                    <th>Action</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let state of States, let i = index">
                    <td scope="row">{{i+1}}</td>
                    <td>{{state.StateCode}}</td>
                    <td class="text-left">{{state.StateName}}</td>
                    <td class="text-left">{{state.CountryName}}</td>
                    <td class="text-left">{{state.ZoneName}}</td>
                    <td>
                        <button *ngIf="this.SelectedMenu.CanEdit" type="button"  (click)="GetStateByid( state.StateID )" class="table-edit-btn"><i class="fas fa-edit"></i></button> 
                        <button *ngIf="this.SelectedMenu.CanDelete" type="button"  (click)="DeleteStateByid( state.StateID )"class="table-remove-btn"><i class="fas fa-trash-alt"></i> </button>
                        <button *ngIf="this.SelectedMenu.CanView"   type="button" (click)="ViewStateByid( state.StateID )" class="table-view-button" type="button" ><i class="fas fa-eye"></i></button>
                    </td>
                </tr>
            </tbody>
        </table>
    </fieldset>

    <fieldset *ngIf="(this.FormViewShow)" class="body-form-control">
        <legend class="legend--">State Creation</legend>
        <form #StateForm="ngForm" (ngSubmit)="saveState()">
            <div class="row">
                <div class="col-lg-4">
                    <div class="col-body-border-right">
                        <div class="form-group row">
                            <label class="col-sm-4 col-form-label clear-padding-right">State Code</label>
                            <div class="col-sm-7 clear-left-padding">
                                <input type="text" [(ngModel)]="StateModel.StateCode" tabindex="1" maxlength="40" id ="StateCode"  name="StateCode" #StateCode="ngModel" required class="form-control form-control-custom form-control-custom-new" >
                            </div>
                            <div *ngIf="StateCode.invalid && (StateCode.dirty || StateCode.touched)" class="alert alert-danger">
                                <div *ngIf="StateCode.errors.required">State Code is required.</div>
                                <div *ngIf="StateCode.errors.maxlength">State Code is not more than 40 characters</div>
                            </div>
                        </div>
                        <div class="form-group row">
                            <label  class="col-sm-4 col-form-label clear-padding-right">Zone Name</label>
                            <div class="col-sm-7 clear-left-padding">
                                <select [(ngModel)]="StateModel.ZoneId" tabindex="4" id ="ZoneId" class="form-control"  name="ZoneId" #ZoneId="ngModel" >
                                <option [value]="0" [disabled]=true >Select Zone Name</option>
                                <option *ngFor="let Z of Zones" [value]="Z.ZoneID">{{Z.ZoneName}}</option>
                                </select>
                            </div>
                            <div class="required-validation"  *ngIf="StateModel.ZoneId==0">Zone is required</div>
                        </div>
                    </div>
                </div>
                
                <div class="col-lg-4">
                    <div class="col-body-border-right">
                        <div class="form-group row">
                            <label  class="col-sm-4 col-form-label clear-padding-right">State Name</label>
                            <div class="col-sm-7 clear-left-padding">
                                <input type="text" [(ngModel)]="StateModel.StateName" tabindex="2" id ="StateName" (blur)="CheckName()" name="StateName" #StateName="ngModel" required maxlength="250" class="form-control form-control-custom form-control-custom-new" >
                            </div>
                            <div *ngIf="this.Namecheck.length > 0" class="alert alert-danger">State Name is exist</div>
                            <div *ngIf="StateName.invalid && (StateName.dirty || StateName.touched)" class="alert alert-danger">
                                <div *ngIf="StateName.errors.required">State Name  is required.</div>
                                <div *ngIf="StateName.errors.maxlength">State Name is not more than 250 characters</div>
                            </div>
                        </div>
                        <div class="form-group row">
                            
                        </div>
                    </div>
                </div>

                <div class="col-lg-4">
                    <div class="form-group row">
                        <label  class="col-sm-4 col-form-label clear-padding-right">Country Name</label>
                        <div class="col-sm-7 clear-left-padding">
                            <select  (ngModelChange)="onCountryChange($event)" [(ngModel)]="StateModel.CountryId" tabindex="3" id ="CountryId" (blur)="CheckName()" class="form-control"  name="CountryId" #CountryId="ngModel" >
                                <option [value]="0" [disabled]=true >Select Country Name</option>
                                <option *ngFor="let C of Countries" [value]="C.CountryId">{{C.CountryName}}</option>
                            </select>
                        </div>
                        <div class="required-validation"  *ngIf="StateModel.CountryId==0">Country is required</div>
                    </div>
                    <div class="form-group row">
                        <label class="col-sm-6 col-form-label text-center clear-padding-right" *ngIf="!(this.IsDataIsInViewState)" >
                            <button type="submit" tabindex="5" class="btn btn-primary save-button" [disabled]="!(StateForm.valid && this.CheckNameExiststatus==true && StateModel.CountryId !=0 && StateModel.ZoneId !=0 && this.DisableSave==true)" >Save</button>  
                        </label>
                        <div class="col-sm-6 col-form-label text-center">
                        <button type="button" tabindex="6" (click)="RefreshPage()" class="btn btn-danger cancle-button">Cancel</button>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </fieldset>
</div>
</div>