<div class="container">
  <div class="row">
    <div class="col-sm-4">
      <button (click)="AddRows()" class="btn btn-primary m-tl"> Add Rows</button>
      <button (click)="DownloadReport()" class="btn btn-info m-tl"> Export To Excel</button>
    </div>

    <div class="col-sm-7 m-t14">
      <div class="row">
        <div class="col-sm-2">
          <label class="col-form-label clear-padding-right font-14">From Date</label>
        </div>
        <div class="col-sm-4">
          <input type="date" name="FromDate" [ngModel]=" this.FromDate | date:'yyyy-MM-dd'"
            (ngModelChange)="FromDateChanged($event)" #FromDate="ngModel" required />
        </div>
        <div class="col-sm-2">
          <label class="col-form-label clear-padding-right font-14">To Date</label>
        </div>
        <div class="col-sm-4">
          <input type="date" name="ToDate" [ngModel]="this.Todate | date:'yyyy-MM-dd'"
            (ngModelChange)="ToDateChanged($event)" #ToDate="ngModel" required />
        </div>
      </div>
    </div>
  </div>
</div>


<div class="body-content-area " style="height: 900px; width: 1150px;" id="link1">
  <div class="row" style="height: auto;">
    <div class="col-lg-12" style="height:900px; width: 1150px;">
      <div class="row" style="height: 900px; width: 1150px;">
        <ag-grid-angular #agGrid style="width: 100%; height: 50%;" id="myGrid" class="ag-theme-balham"
          [modules]="modules" [columnDefs]="columnDefs" [defaultColDef]="defaultColDef" [rowData]="rowData"
          [components]="components" [floatingFilter]="true" [frameworkComponents]="frameworkComponents"
          class="ag-theme-balham" (gridReady)="onGridReady($event)" [rowHeight]="32">
        </ag-grid-angular>
      </div>
    </div>
  </div>
</div>