export class KRAMappingWithEmployeeModel
{
    public RefId : number;
    public EmployeeId : number;
    public EmployeeCode : string;
    public EmployeeName : string;
    public DesignationId : number;
    public DesignationName : string;
    public CompanyId : number;
    public CompanyName : string;
    public KRAId : number;
    public KRAName : string;
    public RatingCriteria : string;
    public FinYear : string;
    public Weighted : number;
    public Achievement : string;
    public RHId : number;
    public RHCode : string;
    public RHName : string;
    public FHId : number;
    public FHCode : string;
    public FHName : string;
    public IsActive : boolean;
    public TotalWateges : number;

    public BranchId : number;
    public DepartmentId : number;
    public TemplateMasterRefId : number;
    public TemplateName : string;
    public EmployeeIds : string;
    public OldTemplateMasterRefId : number;
}