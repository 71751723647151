import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from '../../shared/user.service';
import { EmployeePortalService } from '../../shared/HR_ADMIN/EmployeePortal/EmployeePortalService.service';
import { EmployeeDetail } from '../../shared/HR_ADMIN/Employee/EmployeeDetail.Model';
import { EmployeeCompoffModel } from '../../shared/HR_ADMIN/EmployeePortal/EmployeeCompoffModel.model';
import { Menu } from '../../shared/menu.model';
import { ModalOptions,BsModalRef, BsModalService } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-employee-compoff-status',
  templateUrl: './employee-compoff-status.component.html',
  styleUrls: ['./employee-compoff-status.component.css']
})
export class EmployeeCompoffStatusComponent implements OnInit {
  EmployeeCompoffModel: EmployeeCompoffModel = new EmployeeCompoffModel();
  CompoffPending: EmployeeCompoffModel[] = [];
  CompoffApproved: EmployeeCompoffModel[] = [];
  CompoffRejected: EmployeeCompoffModel[] = [];
  Compoff: any;
  userClaims: any;
  employee: any;
  Result: string;
  SuccessStatus: boolean = false;
  SuccessMessage: string = "";
  public tableWidget: any;
  selectedIndex: number;
  // Begning Permission Portion    
  SelectedMenu: Menu;
  // End of the permission portion
  //For Pop up
  ModelListApplication: EmployeeCompoffModel;
  CancelMsg: boolean = false;
  ShowPopup: boolean = false;
  public modalRef: BsModalRef;
  public modelopt: ModalOptions;
  Approver: any;
  RefId: any;
  //New
  PendingMsg: boolean = false;
  ApprovedMsg: boolean = false;
  RejectedMsg: boolean = false;
  validdate: boolean = false;
  validTodate: boolean = false;
  DisableSave: boolean;

  constructor(private EmployeePortalService: EmployeePortalService, private rout: Router, private userService: UserService, private modalService: BsModalService)
  {
    this.ModelListApplication = new EmployeeCompoffModel();
    this.ModelListApplication.HalfDay = false;
    this.ModelListApplication.FullDay = false;
    this.modelopt = new ModalOptions();
    this.modelopt.class = 'heavy_modal';
    this.modalService.config = this.modelopt;
    this.modelopt.ignoreBackdropClick = true;
  }

  ngOnInit()
  {
    // Begning Permission Portion 
    this.SelectedMenu = this.userService.SelectedMenu;
    if (this.SelectedMenu != undefined)
    {
      localStorage.setItem('selectedmenu', JSON.stringify(this.SelectedMenu));
      this.rout.navigate([this.SelectedMenu.RouteName]);
    }
    else
    {
      if (JSON.parse(localStorage.getItem('selectedmenu')) != undefined)
      {
        this.SelectedMenu = JSON.parse(localStorage.getItem('selectedmenu'));
        this.rout.navigate([this.SelectedMenu.RouteName]);
      }
    }
    // End of the permission portion
    this.GetEmployeeDetails();
    this.DisableSave = true;
    this.ModelListApplication.ApplicationStatus = 0;
    this.validdate = false;
    this.validTodate = false;
    this.DisableSave = true;
  }

  GetEmployeeDetails()
  {
    let t = localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.EmployeePortalService.GetEmployeeDetailsByEmployeeId(t).then(x => {
      this.employee = x;
      Object.keys(this.employee).forEach(key => {
        this.employee[key];
        let employeetemp = new EmployeeDetail();
        employeetemp = this.employee[key];
        this.EmployeeCompoffModel.EmployeeCode = this.employee.EmployeeCode;
        this.EmployeeCompoffModel.EmployeeName = this.employee.EmployeeName;
      });
    });
  }

  GetEmployeeCompoffPendingDetails(FromDate, ToDate)
  {
    this.CompoffPending = [];
    let t = localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.EmployeePortalService.GetEmployeeCompoffStatusPending(t, FromDate, ToDate).then(x =>
    {
      this.Compoff = x;
      Object.keys(this.Compoff).forEach(key => 
      {
        this.Compoff[key];
        let Compofftemp = new EmployeeCompoffModel();
        Compofftemp = this.Compoff[key];
        this.CompoffPending.push(Compofftemp);
      });
      if (this.CompoffPending.length == 0) 
      {
        this.PendingMsg = true;
        this.ApprovedMsg = false;
        this.RejectedMsg = false;
      }
    });
  }

  GetEmployeeCompoffApprovedDetails(FromDate, ToDate)
  {
    this.CompoffApproved = [];
    let t = localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.EmployeePortalService.GetEmployeeCompoffStatusApproved(t, FromDate, ToDate).then(x =>
    {
      this.Compoff = x;
      Object.keys(this.Compoff).forEach(key => 
      {
        this.Compoff[key];
        let Compofftemp = new EmployeeCompoffModel();
        Compofftemp = this.Compoff[key];
        this.CompoffApproved.push(Compofftemp);
      });
      if (this.CompoffApproved.length == 0) 
      {
        this.PendingMsg = false;
        this.ApprovedMsg = true;
        this.RejectedMsg = false;
      }
    });
  }

  GetEmployeeCompoffRejectedDetails(FromDate, ToDate) 
  {
    this.CompoffRejected = [];
    let t = localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.EmployeePortalService.GetEmployeeCompoffStatusRejected(t, FromDate, ToDate).then(x => 
    {
      this.Compoff = x;
      Object.keys(this.Compoff).forEach(key => 
      {
        this.Compoff[key];
        let Compofftemp = new EmployeeCompoffModel();
        Compofftemp = this.Compoff[key];
        this.CompoffRejected.push(Compofftemp);
      });
      if (this.CompoffRejected.length == 0) 
      {
        this.PendingMsg = false;
        this.ApprovedMsg = false;
        this.RejectedMsg = true;
      }
    });
  }

  viewAppliedEmployeeCompoff(id, template) 
  {
    this.DisableSave = true;
    this.ModelListApplication.Remarks = "";
    let t = localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.EmployeePortalService.GetEmployeeCompoffApplyByRefId(t, id).then(x => 
    {
      if(this.ShowPopup==true)
      {
        this.modalRef.hide();
      }
      this.modalRef = this.modalService.show(template, this.modelopt);
      this.ShowPopup = true;
      this.CancelMsg = false;
      this.Approver = x;
      Object.keys(this.Approver).forEach(key => 
      {
        // tslint:disable-next-line:no-unused-expression
        this.Approver[key];
        let Approvetemp = new EmployeeCompoffModel();
        Approvetemp = this.Approver[key];
        this.ModelListApplication.RefId = Approvetemp.RefId;
        this.ModelListApplication.EmployeeId = Approvetemp.EmployeeId;
        this.ModelListApplication.EmployeeCode = Approvetemp.EmployeeCode;
        this.ModelListApplication.EmployeeName = Approvetemp.EmployeeName;
        this.ModelListApplication.EmployeeEmailId = Approvetemp.EmployeeEmailId;
        this.ModelListApplication.RHEmailId = Approvetemp.RHEmailId;
        this.ModelListApplication.RHName = Approvetemp.RHName;
        this.ModelListApplication.RHCode = Approvetemp.RHCode;
        this.ModelListApplication.AttendanceDate = Approvetemp.AttendanceDate;
        this.ModelListApplication.Status = Approvetemp.Status;
        this.ModelListApplication.HalfDay = Approvetemp.HalfDay;
        this.ModelListApplication.FullDay = Approvetemp.FullDay;
        if (Approvetemp.HalfDay == true)
        {
          this.ModelListApplication.Fromvalue = "HalfDay";
        }
        if (Approvetemp.FullDay == true)
        {
          this.ModelListApplication.Fromvalue = "FullDay";
        }
        this.ModelListApplication.ActualInTime = Approvetemp.ActualInTime;
        this.ModelListApplication.ActualOutTime = Approvetemp.ActualOutTime;
        this.ModelListApplication.ActualWorkingDuration = Approvetemp.ActualWorkingDuration;
        this.ModelListApplication.Reason = Approvetemp.Reason;
        this.ModelListApplication.CompanyId = Approvetemp.CompanyId;
      });
      // End for check company code duplication
    });
  }

  EmployeeCompoffCancel(template): void
  {
    if (confirm("Are you sure to Cancel "))
    {
      this.DisableSave = false;
      let t = localStorage.getItem('userToken');
      t = 'Bearer' + t;
      if (this.ModelListApplication.RefId > 0)
      {
        this.ModelListApplication.IsRejected = true;
        this.EmployeePortalService.EmployeeCompoffCancel(t, this.ModelListApplication).subscribe(res => 
        {
          this.RefId = res;
          this.ModelListApplication.RefId = 0;
          if (this.RefId > 0) 
          {
            this.ModelListApplication.Remarks = "";
            this.modalRef.hide();
            this.CancelMsg = true;
            this.ShowPopup = false;
            this.modalRef = this.modalService.show(template);
            this.DisableSave = true;
          }
        });
      }
    }
  }

  SearchFromDateChanged(date)
  {
    this.DisableSave = true;
    this.PendingMsg = false;
    this.ApprovedMsg = false;
    this.RejectedMsg = false;
    this.CompoffPending = [];
    this.CompoffApproved = [];
    this.CompoffRejected = [];

    this.ModelListApplication.SearchFromDate = date;
  }

  SearchToDateChanged(date)
  {
    this.DisableSave = true;
    this.PendingMsg = false;
    this.ApprovedMsg = false;
    this.RejectedMsg = false;
    this.CompoffPending = [];
    this.CompoffApproved = [];
    this.CompoffRejected = [];

    this.ModelListApplication.SearchToDate = date;
  }

  StatusChanged()
  {
    this.DisableSave = true;
    this.PendingMsg = false;
    this.ApprovedMsg = false;
    this.RejectedMsg = false;
    this.CompoffPending = [];
    this.CompoffApproved = [];
    this.CompoffRejected = [];
  }

  submitClick()
  {
    this.DisableSave = false;
    this.validdate = false;
    this.validTodate = false;
    this.PendingMsg = false;
    this.ApprovedMsg = false;
    this.RejectedMsg = false;
    this.CompoffPending = [];
    this.CompoffApproved = [];
    this.CompoffRejected = [];
    if (this.ModelListApplication.SearchFromDate != null && this.ModelListApplication.SearchToDate != null && this.ModelListApplication.ApplicationStatus > 0)
    {
      if (this.ModelListApplication.SearchToDate >= this.ModelListApplication.SearchFromDate) 
      {
        if (this.ModelListApplication.ApplicationStatus == 1) 
        {
          this.GetEmployeeCompoffPendingDetails(this.ModelListApplication.SearchFromDate, this.ModelListApplication.SearchToDate);
        }
        else if (this.ModelListApplication.ApplicationStatus == 2) 
        {
          this.GetEmployeeCompoffApprovedDetails(this.ModelListApplication.SearchFromDate, this.ModelListApplication.SearchToDate);
        }
        else 
        {
          this.GetEmployeeCompoffRejectedDetails(this.ModelListApplication.SearchFromDate, this.ModelListApplication.SearchToDate);
        }
      }
      else 
      {
        this.validTodate = true;
      }
    }
  }
}
