import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs/Subject';
import { DataTableDirective } from 'angular-datatables';
import { UserService } from '../../shared/user.service';
import { EmployeeMasterService }  from '../../shared/HR_ADMIN/Employee/EmployeeMaster.Service';
import { OfferDetails } from '../../shared/HR_ADMIN/Employee/OfferDetails.Model';
import { JoiningDetails } from '../../shared/HR_ADMIN/Employee/JoiningDetails.Model';
// Begning Confirmation
import { ConfirmationService } from '@jaspero/ng2-confirmations';
import { ResolveEmit } from '../../../app/resolve-emit';
import { ConfirmSettings } from '../../../app/confirm-settings';
// end of Confirmation
import { Menu } from '../../shared/menu.model';

@Component({
  selector: 'app-employee-joining',
  templateUrl: './employee-joining.component.html',
  styleUrls: ['./employee-joining.component.css']
})

export class EmployeeJoiningComponent implements OnInit
{
  JoiningModel : JoiningDetails= new JoiningDetails();
  userClaims: any;
  Joinings: JoiningDetails[] = [];
  Joining: any;
  Offers: OfferDetails[] = [];
  Offer: any;
  Result : string;
  SuccessStatus:boolean=false;
  SuccessMessage: string ="";
  public tableWidget: any;
  JoiningId : any;
  tempJoiningadd : JoiningDetails =new JoiningDetails();
  // Begning Permission Portion    
  SelectedMenu:Menu;
  IsDataIsInViewState : boolean=false;
  CanViewState : boolean=true;
  IsOfferInViewState : boolean=true;
  IsjoiningInViewState:boolean=false;
  IsFormView : boolean=false;
  IsButtonIsInViewState : boolean=false;
  DisableSave :boolean=false;
  // End of the permission portion
  // begin for Validation
  validdate : boolean= false;
  // end of validation
  NoRecordMsg : boolean;
  NoRecordOfferMsg : boolean;

  constructor(private EmployeeMasterService: EmployeeMasterService, private rout: Router, private _confirmation: ConfirmationService,  private userService: UserService)  { }

  @ViewChild(DataTableDirective, {static: false})
  setDtOptions: DataTables.Settings = {};
  cardDtOptions: DataTables.Settings = {};
  dtElement: DataTableDirective;
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();
 
  ngOnInit() 
  {
    // Begning Permission Portion 
    this.SelectedMenu=this.userService.SelectedMenu;
    if(this.SelectedMenu !=undefined)
    {
      localStorage.setItem('selectedmenu',JSON.stringify(this.SelectedMenu));
      this.rout.navigate([this.SelectedMenu.RouteName]);
    }
    else
    {
      if(JSON.parse(localStorage.getItem('selectedmenu'))!=undefined)
      {
        this.SelectedMenu = JSON.parse(localStorage.getItem('selectedmenu'));
        this.rout.navigate([this.SelectedMenu.RouteName]);
      }
    }
    if(this.SelectedMenu.CanAdd)
    {
      this.IsDataIsInViewState=false;
    }
    else
    {
      this.IsDataIsInViewState=true;
    }
    if(this.SelectedMenu.CanView)
    {
      this.CanViewState=false;
    }
    else
    {
      this.CanViewState=true;
    }
    this.IsFormView=false;
    this.DisableSave=true;
    // End of the permission portion
    this.dtOptions = 
    {
      pagingType: 'full_numbers',
      pageLength: 2
    };
    if(localStorage.getItem('SuccessfullMessage'))
    {
      this.SuccessStatus=true;
      this.SuccessMessage =localStorage.getItem('SuccessfullMessage')
      localStorage.removeItem('SuccessfullMessage');
    }
    this.GetJoiningLetters();
    this.NoRecordMsg = false;
    this.NoRecordOfferMsg = false;
  }
  
  ADDClick()
  {
    this.IsButtonIsInViewState=true;
    this.IsOfferInViewState = false;
    this.IsjoiningInViewState = true;
    this.IsFormView=false;
    this.GetOfferLetters();
  }

  settings: ConfirmSettings | any = 
  {
    overlay: true,
    overlayClickToClose: true,
    showCloseButton: true,
    confirmText: 'Yes',
    declineText: 'No'
  };

  ngAfterViewInit(): void 
  {
    this.dtOptions = 
    {
      pagingType: 'full_numbers',
      pageLength: 2
    };
  }
  
  GetOfferLetters()
  {
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.EmployeeMasterService.GetOfferDetailsForJoining( t).then(x => 
    {
      this.NoRecordOfferMsg = false;
      this.Offer = x;
      Object.keys(this.Offer).forEach( key => 
      {
        this.Offer[key];
        let Offertemp = new OfferDetails();
        Offertemp = this.Offer[key];
        this.Offers.push(Offertemp);
      });
      if(this.Offers.length == 0)
      {
        this.NoRecordOfferMsg = true;
        this.NoRecordMsg = false;
      }
      this.dtTrigger.next();
    });
    
  }

  GetOfferDetailsByid(id) 
  {
    this.validdate = true;
     // begning of the permission portion
    this.IsDataIsInViewState=false;
    this.IsFormView=true;
     // end of the permission portion
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.EmployeeMasterService.GetOfferDetailsByidforJoining( t,id).then(x => 
    {
      this.Joining = x;
      Object.keys(this.Joining).forEach( key => 
      {
        this.Joining[key];
        let Joiningtemp = new JoiningDetails();
        Joiningtemp = this.Joining[key];
        this.JoiningModel.JoiningId=Joiningtemp.JoiningId;
        this.JoiningModel.OfferRefid=Joiningtemp.OfferRefid;
        this.JoiningModel.ReportingDate=Joiningtemp.ReportingDate;
        this.JoiningModel.FullName=Joiningtemp.FullName;
        this.JoiningModel.InHandSalary=Joiningtemp.InHandSalary;
        this.JoiningModel.CTCSalary=Joiningtemp.CTCSalary;          
        this.JoiningModel.Assets=Joiningtemp.Assets;
        this.JoiningModel.Records=Joiningtemp.Records;
        this.JoiningModel.JoiningApproved=Joiningtemp.JoiningApproved;
        this.JoiningModel.Joiningtatus=Joiningtemp.Joiningtatus;
        this.JoiningModel.MobileApply=Joiningtemp.MobileApply;
        this.JoiningModel.MobileAmount=Joiningtemp.MobileAmount;
        this.JoiningModel.IsActive=Joiningtemp.IsActive;
        this.JoiningModel.OfferDate=Joiningtemp.OfferDate;
        this.JoiningModel.Address1=Joiningtemp.Address1;
        this.JoiningModel.Address2=Joiningtemp.Address2;
        this.JoiningModel.District=Joiningtemp.District;
        this.JoiningModel.CountryName=Joiningtemp.CountryName;
        this.JoiningModel.StateName=Joiningtemp.StateName;
        this.JoiningModel.CityName=Joiningtemp.CityName;
        this.JoiningModel.PinCode=Joiningtemp.PinCode;
        this.JoiningModel.ContactNumber=Joiningtemp.ContactNumber;

        let dateString : string = Joiningtemp.JoiningDate.toString();
        let days : number = parseInt(dateString.substring(8, 10));
        let months : number = parseInt(dateString.substring(5, 7));
        let years : number = parseInt(dateString.substring(0, 5));
        let goodDate : Date = new Date(months + "/" + days + "/" + years);
        goodDate.setDate(goodDate.getDate() );
        this.JoiningModel.JoiningDate=goodDate;

        this.JoiningModel.EmailId=Joiningtemp.EmailId;
        this.JoiningModel.CompanyName=Joiningtemp.CompanyName;
        this.JoiningModel.BranchName=Joiningtemp.BranchName;
        this.JoiningModel.DepartmentName=Joiningtemp.DepartmentName;
        this.JoiningModel.DecisionName=Joiningtemp.DecisionName;
        this.JoiningModel.LevelName=Joiningtemp.LevelName;
        this.JoiningModel.GradeName=Joiningtemp.GradeName;
        this.JoiningModel.DesignationName=Joiningtemp.DesignationName;
      });
      this.IsOfferInViewState = true;
    });
  }

  GetJoiningLetters()
  {
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.EmployeeMasterService.GetJoiningDetails( t).then(x => 
    {
      this.NoRecordMsg = false;
      this.Joining = x;
      Object.keys(this.Joining).forEach( key => 
      {
        this.Joining[key];
        let Joiningtemp = new JoiningDetails();
        Joiningtemp = this.Joining[key];
        this.Joinings.push(Joiningtemp);
      });
      this.dtTrigger.next();
      if(this.Joinings.length == 0)
      {
        this.NoRecordOfferMsg = false;
        this.NoRecordMsg = true;
      }
    });
  }

  GetJoiningDetailsByid(id) 
  {
    this.validdate = true;
     // begning of the permission portion
    this.IsDataIsInViewState=false;
    this.IsFormView=true;
     // end of the permission portion
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.EmployeeMasterService.GetJoiningDetailsById( t,id).then(x => 
    {
      this.Joining = x;
      Object.keys(this.Joining).forEach( key => 
      {
        this.Joining[key];
        let Joiningtemp = new JoiningDetails();
        Joiningtemp = this.Joining[key];
        this.JoiningModel.JoiningId=Joiningtemp.JoiningId;
        this.JoiningModel.OfferRefid=Joiningtemp.OfferRefid;
        this.JoiningModel.ReportingDate=Joiningtemp.JoiningDate;
        this.JoiningModel.FullName=Joiningtemp.FullName;
        this.JoiningModel.InHandSalary=Joiningtemp.InHandSalary;
        this.JoiningModel.CTCSalary=Joiningtemp.CTCSalary;          
        this.JoiningModel.Assets=Joiningtemp.Assets;
        this.JoiningModel.Records=Joiningtemp.Records;
        this.JoiningModel.JoiningApproved=Joiningtemp.JoiningApproved;
        this.JoiningModel.Joiningtatus=Joiningtemp.Joiningtatus;
        this.JoiningModel.MobileApply=Joiningtemp.MobileApply;
        this.JoiningModel.MobileAmount=Joiningtemp.MobileAmount;
        this.JoiningModel.IsActive=Joiningtemp.IsActive;
        this.JoiningModel.OfferDate=Joiningtemp.OfferDate;
        this.JoiningModel.Address1=Joiningtemp.Address1;
        this.JoiningModel.Address2=Joiningtemp.Address2;
        this.JoiningModel.District=Joiningtemp.District;
        this.JoiningModel.CountryName=Joiningtemp.CountryName;
        this.JoiningModel.StateName=Joiningtemp.StateName;
        this.JoiningModel.CityName=Joiningtemp.CityName;
        this.JoiningModel.PinCode=Joiningtemp.PinCode;
        this.JoiningModel.ContactNumber=Joiningtemp.ContactNumber;

        let dateString : string = Joiningtemp.JoiningDate.toString();
        let days : number = parseInt(dateString.substring(8, 10));
        let months : number = parseInt(dateString.substring(5, 7));
        let years : number = parseInt(dateString.substring(0, 5));
        let goodDate : Date = new Date(months + "/" + days + "/" + years);
        goodDate.setDate(goodDate.getDate() );
        this.JoiningModel.JoiningDate=goodDate;

        this.JoiningModel.EmailId=Joiningtemp.EmailId;
        this.JoiningModel.CompanyName=Joiningtemp.CompanyName;
        this.JoiningModel.BranchName=Joiningtemp.BranchName;
        this.JoiningModel.DepartmentName=Joiningtemp.DepartmentName;
        this.JoiningModel.DecisionName=Joiningtemp.DecisionName;
        this.JoiningModel.LevelName=Joiningtemp.LevelName;
        this.JoiningModel.GradeName=Joiningtemp.GradeName;
        this.JoiningModel.DesignationName=Joiningtemp.DesignationName;
      });
    });
  }

  viewJoiningDetailsByid(id) 
  {
    this.validdate = true;
    this.IsDataIsInViewState=true;
    this.IsFormView=true;
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.EmployeeMasterService.GetJoiningDetailsById( t,id).then(x => 
    {
      this.Joining = x;
      Object.keys(this.Joining).forEach( key => 
      {
        this.Joining[key];
        let Joiningtemp = new JoiningDetails();
        Joiningtemp = this.Joining[key];
        this.JoiningModel.JoiningId=Joiningtemp.JoiningId;
        this.JoiningModel.OfferRefid=Joiningtemp.OfferRefid;
        this.JoiningModel.ReportingDate=Joiningtemp.ReportingDate;
        this.JoiningModel.FullName=Joiningtemp.FullName;
        this.JoiningModel.InHandSalary=Joiningtemp.InHandSalary;
        this.JoiningModel.CTCSalary=Joiningtemp.CTCSalary;          
        this.JoiningModel.Assets=Joiningtemp.Assets;
        this.JoiningModel.Records=Joiningtemp.Records;
        this.JoiningModel.JoiningApproved=Joiningtemp.JoiningApproved;
        this.JoiningModel.Joiningtatus=Joiningtemp.Joiningtatus;
        this.JoiningModel.MobileApply=Joiningtemp.MobileApply;
        this.JoiningModel.MobileAmount=Joiningtemp.MobileAmount;
        this.JoiningModel.IsActive=Joiningtemp.IsActive;
        this.JoiningModel.OfferDate=Joiningtemp.OfferDate;
        this.JoiningModel.Address1=Joiningtemp.Address1;
        this.JoiningModel.Address2=Joiningtemp.Address2;
        this.JoiningModel.District=Joiningtemp.District;
        this.JoiningModel.CountryName=Joiningtemp.CountryName;
        this.JoiningModel.StateName=Joiningtemp.StateName;
        this.JoiningModel.CityName=Joiningtemp.CityName;
        this.JoiningModel.PinCode=Joiningtemp.PinCode;
        this.JoiningModel.ContactNumber=Joiningtemp.ContactNumber;

        let dateString : string = Joiningtemp.JoiningDate.toString();
        let days : number = parseInt(dateString.substring(8, 10));
        let months : number = parseInt(dateString.substring(5, 7));
        let years : number = parseInt(dateString.substring(0, 5));
        let goodDate : Date = new Date(months + "/" + days + "/" + years);
        goodDate.setDate(goodDate.getDate() );
        this.JoiningModel.JoiningDate=goodDate;

        this.JoiningModel.EmailId=Joiningtemp.EmailId;
        this.JoiningModel.CompanyName=Joiningtemp.CompanyName;
        this.JoiningModel.BranchName=Joiningtemp.BranchName;
        this.JoiningModel.DepartmentName=Joiningtemp.DepartmentName;
        this.JoiningModel.DecisionName=Joiningtemp.DecisionName;
        this.JoiningModel.LevelName=Joiningtemp.LevelName;
        this.JoiningModel.GradeName=Joiningtemp.GradeName;
        this.JoiningModel.DesignationName=Joiningtemp.DesignationName;
      });
    });
  }

  saveJoiningDetails() :void
  {
    this.DisableSave=false;
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.JoiningModel.IsActive=true;
    if(this.JoiningModel.JoiningId > 0)
    {
      this.EmployeeMasterService.UpdateJoiningDetails(t ,this.JoiningModel).subscribe(res=>
      {
        this.JoiningId=res;
        this.JoiningModel.JoiningId=0;  
        if(this.JoiningId>0)
        {
          localStorage.setItem('SuccessfullMessage', "Update Successfully");
          this.rout.navigateByUrl('/Employee/EmployeeOffer', {skipLocationChange: true}).then(()=>this.rout.navigate(["/Employee/EmployeeJoining"]));
        }
      });                  
    }
    else
    {
      this.EmployeeMasterService.AddJoiningDetails(t ,this.JoiningModel).subscribe(res=>
      {
        this.JoiningId=res;
        this.JoiningModel.JoiningId=0;                            
        if(this.JoiningId>0)
        {
          localStorage.setItem('SuccessfullMessage', "Save Successfully");
          this.rout.navigateByUrl('/Employee/EmployeeOffer', {skipLocationChange: true}).then(()=>this.rout.navigate(["/Employee/EmployeeJoining"]));
        }
      }); 
    }
  }

  DeleteJoiningDetailsByid(Joiningid : number) :void
  {    
    this._confirmation.create('Confirm to Deleate', 'Press Yes to Delete', this.settings)
    .subscribe((ans: ResolveEmit) => 
    {
      if (ans.resolved == true) 
      {
        let t =  localStorage.getItem('userToken');
        t = 'Bearer' + t;
        this.JoiningModel.IsActive=true;
        if( Joiningid > 0)
        {
          this.EmployeeMasterService.DeleteJoiningDetails(t ,Joiningid).subscribe(res=>
          {
            this.JoiningId=res;
            this.JoiningModel.JoiningId=0;                                  
            if(this.JoiningId >0)
            {
              localStorage.setItem('SuccessfullMessage', "Deleted Successfully");
              this.rout.navigateByUrl('/Employee/EmployeeOffer', {skipLocationChange: true}).then(()=>this.rout.navigate(["/Employee/EmployeeJoining"]));
            }
          });                  
        }
      } 
    })
  }

  dateChanged( date)
  {
    this.JoiningModel.JoiningDate=date;
    var CurrentDate = new Date();
    var CurrentYear = CurrentDate.getFullYear();
    var currentMonth = CurrentDate.getMonth();
    var CurrentDay = CurrentDate.getDate();
    
    var selecteedDate = new Date(date);
    var selectedYear=selecteedDate.getFullYear();
    var selectedMonth=selecteedDate.getMonth();
    var selectedDay=selecteedDate.getDate();

    if(CurrentYear < selectedYear )
    {
      this.validdate = true;      
    }
    else if(CurrentYear > selectedYear)
    {
      this.validdate = false;
    }
    else
    {
      if (currentMonth < selectedMonth)
      {
        this.validdate = true;
      }
      else if(currentMonth > selectedMonth)
      {
        this.validdate = false;
      }
      else
      {
        if(CurrentDay <= selectedDay )
        {
          this.validdate = true;
        }
        else
        {
          this.validdate = false;
        }
      }
    }
  }

  RefreshPage()
  {
    this.rout.navigateByUrl('/Employee/EmployeeOffer', {skipLocationChange: true}).then(()=>this.rout.navigate(["/Employee/EmployeeJoining"]));
  }
}
