<div class="body-content-area tabcontent" id="link1">
    <div class="row">
        <div class="col-lg-9">
            <div class="row">
                <div class="col-lg-1 low-padding text-center">
                    <!-- <img *ngIf="this.SelectedMenu.Image!=null" src={{this.SelectedMenu.Image}} class="body-icon" alt="Icon"/> -->
                </div>
                <div class="col-lg-10 clear-padding">
                    <span class="page-title">Manage Country</span>
                    <small class="page-description"></small>
                    <div class="col-lg-10 clear-padding" *ngIf="!(this.CanViewState)">
                        <button *ngIf="(this.CanSaveButtonView)" (click)="ADDClick()" type="button" class="btn btn-primary Add-button">ADD New</button> 
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div *ngIf="!(this.CanViewState)">  
        <app-dialog [(visible)]="this.SuccessStatus">
            <span class="successmsg">{{this.SuccessMessage}}</span>
        </app-dialog>
        <fieldset *ngIf="!(this.FormViewShow)" class="body-form-control">
            <legend class="legend--">Country Details</legend>
            <table id="tblcompany" *ngIf="!(this.FormViewShow)" datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" class="table table-striped table-bordered text-center table-new row-border hover">
                <thead class="thead-blue text-center">
                    <tr>
                        <th>Sl No.</th>
                        <th>Country Code</th>
                        <th>Country Name</th>
                        <th>Language Code</th>
                        <th>Language Name</th>
                        <th>Currency Code</th>
                        <th>Currency Name</th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let country of Countries, let i = index">
                        <td scope="row">{{i+1}}</td>
                        <td>{{country.CountryCode}}</td>
                        <td class="text-left">{{country.CountryName}}</td>
                        <td>{{country.LanguageCode}}</td>
                        <td class="text-left">{{country.LanguageName}}</td>
                        <td>{{country.CurrencyCode}}</td>
                        <td class="text-left">{{country.CurrencyName}}</td>
                        <td>
                            <button type="button" *ngIf="this.SelectedMenu.CanEdit" (click)="GetCountryByid( country.CountryId )" class="table-edit-btn"><i class="fas fa-edit"></i> </button> 
                            <button type="button"  *ngIf="this.SelectedMenu.CanDelete" (click)="DeleteCountryByid( country.CountryId )" class="table-remove-btn"><i class="fas fa-trash-alt"></i></button>
                            <button *ngIf="this.SelectedMenu.CanView"   type="button" (click)="ViewCountryByid( country.CountryId )" class="table-view-button" type="button" ><i class="fas fa-eye"></i></button>
                        </td>
                    </tr>
                </tbody>
                <tfoot class="thead-blue text-center">
                    <tr>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th></th>
                    </tr>
                </tfoot>
            </table>
        </fieldset>

        <fieldset *ngIf="(this.FormViewShow)" class="body-form-control">
            <legend class="legend--">Company Creation</legend>
            <form  #CounrtyForm="ngForm" (ngSubmit)="saveCountry()">
               <div class="row">
                    <div class="col-lg-4">
                        <div class="col-body-border-right">
                            <div class="form-group row">
                                <label  class="col-sm-4 col-form-label clear-padding-right">Country Code</label>
                                <div class="col-sm-7 clear-left-padding">
                                    <input type="text" [(ngModel)]="CountryModel.CountryCode" tabindex="1" maxlength="20" id ="CountryCode"  name="CountryCode" #CountryCode="ngModel" required class="form-control form-control-custom form-control-custom-new" >
                                </div>
                                <div *ngIf="CountryCode.invalid && (CountryCode.dirty || CountryCode.touched)" class="alert alert-danger">
                                    <div *ngIf="CountryCode.errors.required">Country Code is required.</div>
                                    <div *ngIf="CountryCode.errors.maxlength">Country Code is not more than 20 characters</div>
                                </div>
                            </div>
                            <div class="form-group row">
                                <label  class="col-sm-4 col-form-label clear-padding-right">Language Name</label>
                                <div class="col-sm-7 clear-left-padding">
                                    <input type="text" [(ngModel)]="CountryModel.LanguageName" tabindex="4" id ="LanguageName"  name="LanguageName" #LanguageName="ngModel"  maxlength="200" class="form-control form-control-custom form-control-custom-new" >
                                </div>                          
                                <div class="alert alert-danger" *ngIf="LanguageName.errors?.maxlength">LanguageName is not more than 200 characters</div>
                            </div>
                        </div>
                    </div>
                    
                    <div class="col-lg-4">
                        <div class="col-body-border-right">
                            <div class="form-group row">
                                <label  class="col-sm-4 col-form-label clear-padding-right">Country Name </label>
                                <div class="col-sm-7 clear-left-padding">
                                    <input type="text" [(ngModel)]="CountryModel.CountryName" tabindex="2" id ="CountryName"  name="CountryName" #CountryName="ngModel" required maxlength="250" (blur)="CheckName()" class="form-control form-control-custom form-control-custom-new" >
                                </div>
                                <div *ngIf="this.Namecheck.length > 0" class="alert alert-danger">Country Name is exist</div>
                                <div *ngIf="CountryName.invalid && (CountryName.dirty || CountryName.touched)" class="alert alert-danger">
                                    <div *ngIf="CountryName.errors.required">Country Name  is required.</div>
                                    <div *ngIf="CountryName.errors.maxlength">Counrty Name is not more than 250 characters</div>
                                </div>
                            </div>
                            <div class="form-group row">
                                <label  class="col-sm-4 col-form-label clear-padding-right">Currency Code</label>
                                <div class="col-sm-7 clear-left-padding">
                                    <input type="text" [(ngModel)]="CountryModel.CurrencyCode" tabindex="5" id ="CurrencyCode"  name="CurrencyCode" #CurrencyCode="ngModel"  maxlength="50" class="form-control form-control-custom form-control-custom-new" >
                                </div>
                                <div class="alert alert-danger" *ngIf="CurrencyCode.errors?.maxlength">CurrencyCode is not more than 50 characters</div>
                            </div>                      
                        </div>
                    </div>

                    <div class="col-lg-4">
                        <div class="form-group row">
                            <label  class="col-sm-4 col-form-label clear-padding-right">Language Code</label>
                            <div class="col-sm-7 clear-left-padding">
                                <input type="text" [(ngModel)]="CountryModel.LanguageCode" tabindex="3" id ="LanguageCode"  name="LanguageCode" #LanguageCode="ngModel"  maxlength="20" class="form-control form-control-custom form-control-custom-new" >
                            </div>
                            <div class="alert alert-danger" *ngIf="LanguageCode.errors?.maxlength">Language Code is not more than 20 characters</div>
                        </div>
                        
                        <div class="form-group row">
                            <label  class="col-sm-4 col-form-label clear-padding-right">Currency Name</label>
                            <div class="col-sm-7 clear-left-padding">
                                <input type="text" [(ngModel)]="CountryModel.CurrencyName" id ="CurrencyName" tabindex="15" #CurrencyName="ngModel" maxlength="100"  name="CurrencyName" class="form-control form-control-custom form-control-custom-new" >
                            </div>
                            <div class="alert alert-danger" *ngIf="CurrencyName.errors?.maxlength">Currency Name is not more than 100 characters</div>
                        </div>
                        <div class="form-group row">
                            <label  class="col-sm-6 col-form-label text-center clear-padding-right" *ngIf="!(this.IsDataIsInViewState)">
                                <button type="submit" tabindex="18" class="btn btn-primary save-button" [disabled]="!(CounrtyForm.valid && this.CheckNameExiststatus==true && this.DisableSave==true)" >Save</button>  
                            </label>
                            <div class="col-sm-6 col-form-label text-center">  
                            <button type="button"  tabindex="19" class="btn btn-danger cancle-button" (click)="RefreshPage()" >Cancel</button></div>
                        </div>
                    </div>
                </div>
            </form>
        </fieldset>
    </div>
</div>