export class User {
    Id: number;
    UserId: string;
    Password: string;
    Roll: string;
    EmpId: number;
    Rememberme: boolean;

    public EmployeeCode: string;
    public EmployeeName: string;
    public EmpPhotoURL: string;
    public IconUrlLogoType: string;
    public IconUrlImage: string;
    public DesignationName: string;
    public LastLoginTime: Date;
    public IPAddress: string;
    public CurrentTime: string;

    public CompPhotoURL: string;
    public CompIconUrlLogoType: string;
    public CompIconUrlImage: string;
    public ImageType: string;
    public ImageFile: string;

    public TotalAllApplication : number;
    public PendingCount : number;
    public ApprovedCount : number;
    public RejectedCount : number;

    public DOB : Date;
    public JoiningDate : Date;
    public BloodGroup : string;
    public Branch : string;
    public Department : string;
    public RHName : string;
}
