import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs/Subject';
import { Menu } from '../../../shared/menu.model';
import { UserService } from '../../../shared/user.service';
import { NotificationsService } from '../../../shared/notifications.service';
import { Model_CRM_Zone } from '../../../shared/CRM/MICECRM/Model_CRM_Zone';
import { CrmZoneService } from '../../../shared/CRM/MICECRM/CrmZoneService';
import { Model_CrmCountry } from '../../../shared/CRM/MICECRM/Model_CrmCountry';
import { ConfirmSettings } from '@jaspero/ng2-confirmations/src/interfaces/confirm-settings';
import { DataTableDirective } from 'angular-datatables';
import { ConfirmationService } from '@jaspero/ng2-confirmations';
import { ResolveEmit } from '@jaspero/ng2-confirmations/src/interfaces/resolve-emit';
//End of notification service

@Component({
  selector: 'app-crm-zone',
  templateUrl: './crm-zone.component.html',
  styleUrls: ['./crm-zone.component.css']
})
export class CrmZoneComponent implements OnInit {
  //  View  Field Decleration Starts
  SuccessMessage: string;
  SuccessStatus: boolean;
  check: string;
  CheckExiststatus: boolean;
  Checkeditstatus: boolean;
  checkdataval: any;
  //  View  Field Decleration Starts
  //  Zone  Update  Delete  View  property  Decleration Starts
  DeleteZoneNameByID: any;
  ZoneId: number;
  ZONELIST: Model_CRM_Zone[] = [];
  CRMZone: any;
  //  Zone  Update  Delete  View  property  Decleration Ends
  @ViewChild(DataTableDirective, {static: false})
  setDtOptions: DataTables.Settings = {};
  cardDtOptions: DataTables.Settings = {};
  dtElement: DataTableDirective;
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();
  // Populate Drop Down Country 
  CountryName: any;
  COUNTRYList: Model_CrmCountry[] = [];
  Crm_Country: Model_CrmCountry;
  // Begning Permission Portion    
  SelectedMenu: Menu;
  IsDataIsInViewState: boolean = false;
  CanViewState: boolean = true;
  selectedIndex: number;
  CRM_Zone_Model: Model_CRM_Zone;
  // End of the permission portion

  constructor(private CRM_ZoneService: CrmZoneService, private rout: Router,
    public userService: UserService, private notificationsService: NotificationsService, private _confirmation: ConfirmationService) {
    this.CRM_Zone_Model = new Model_CRM_Zone();
    this.CRM_Zone_Model.CountryId = 0;
    notificationsService.emitChange.subscribe(slectedtabindex => {
      if (slectedtabindex != null) {
        this.selectedIndex = slectedtabindex;
      }
    });
  }

  ngAfterViewInit(): void {

    this.dtOptions =
      {
        pagingType: 'full_numbers',
        pageLength: 4
      };

    if (localStorage.getItem('SuccessfullMessage')) {
      this.SuccessStatus = true;
      this.SuccessMessage = localStorage.getItem('SuccessfullMessage')
      localStorage.removeItem('SuccessfullMessage');
    }
    else {
      this.SuccessStatus = false;
    }
  }

  settings: ConfirmSettings | any =
    {
      overlay: true,
      overlayClickToClose: true,
      showCloseButton: true,
      confirmText: 'Yes',
      declineText: 'No'
    };

  ngOnInit() {

    this.rout.navigate([this.userService.selectedCrmMenu.RouteName]);

    // Begning Permission Portion 
    // this.SelectedMenu = this.userService.SelectedMenu;
    // if (this.SelectedMenu != undefined) {
    //   localStorage.setItem('selectedmenu', JSON.stringify(this.SelectedMenu));
    //   this.rout.navigate([this.SelectedMenu.RouteName]);
    // }
    // else {
    //   if (JSON.parse(localStorage.getItem('selectedmenu')) != undefined) {
    //     this.SelectedMenu = JSON.parse(localStorage.getItem('selectedmenu'));
    //     this.rout.navigate([this.SelectedMenu.RouteName]);
    //   }
    // }
    // if (this.SelectedMenu.CanAdd) {
    //   this.IsDataIsInViewState = false;
    // }
    // else {
    //   this.IsDataIsInViewState = true;
    // }
    // if (this.SelectedMenu.CanView) {
    //   this.CanViewState = false;
    // }
    // else {
    //   this.CanViewState = true;
    // }

    this.CRM_Zone_Model.CountryId = 0;
    this.GetCountry();
    this.GetCRMZone();

  }


  //Populate  Country Drop  Down  
  GetCountry() {
    let t = localStorage.getItem('userToken');
    t = 'Bearer' + t
    this.COUNTRYList = [];
    this.CRM_ZoneService.GetCountryType(t).then(x => {
      this.CountryName = x;
      Object.keys(this.CountryName).forEach(key => {
        let CountryNametemp = new Model_CrmCountry();
        CountryNametemp = this.CountryName[key];
        this.COUNTRYList.push(CountryNametemp);
      });

    });
  }

  //Populate  Country Drop  Down  ENDS



  //  Grid  Data  Fetch 

  GetCRMZone() {

    let t = localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.ZONELIST = [];
    this.CRM_ZoneService.Get_CRMZone(t).then(x => {
      this.CRMZone = x;
      Object.keys(this.CRMZone).forEach(key => {
        this.CRMZone[key];
        let CRMZonetemp = new Model_CRM_Zone();
        CRMZonetemp = this.CRMZone[key];

        this.ZONELIST.push(CRMZonetemp);
      });
      this.dtTrigger.next();
    });
  }

  //  Grid  Data  Fetch Ends




  // Save Region  Starts

  saveCRMZone(): void {
    let t = localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.CRM_Zone_Model.IsActive = true;

    if (this.CRM_Zone_Model.ZoneId > 0) {
      this.CRM_ZoneService.UpdateCrmZone(t, this.CRM_Zone_Model).subscribe(res => {
        this.ZoneId = Number(res);
        this.CRM_Zone_Model.ZoneId = 0;

        if (this.ZoneId > 0) {
          localStorage.setItem('SuccessfullMessage', "Successfully Updated");
          this.rout.navigateByUrl('ManageMICEAccess/ManageMICEUser', { skipLocationChange: true }).then(() => this.rout.navigate(["ManageMICEAccess/CrmZone"]));

        }
      });
    }
    else {
      this.CRM_ZoneService.AddCrmZone(t, this.CRM_Zone_Model).subscribe(res => {
        this.ZoneId = Number(res);
        this.CRM_Zone_Model.ZoneId = 0;

        if (this.ZoneId > 0) {
          localStorage.setItem('SuccessfullMessage', "Successfully Added");
          this.rout.navigateByUrl('ManageMICEAccess/ManageMICEUser', { skipLocationChange: true }).then(() => this.rout.navigate(["ManageMICEAccess/CrmZone"]));

        }
      });
    }
  }


  //  Update  Section Starts  


  GetCRMZoneById(Id) {

    this.IsDataIsInViewState = false;
    // End of the permision portion
    let t = localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.CRM_ZoneService.GetCRM_Zone_By_id(t, Id).then(x => {
      this.CRMZone = x;
      Object.keys(this.CRMZone).forEach(key => {

        let CRMZonetemp = new Model_CRM_Zone();
        CRMZonetemp = this.CRMZone[key];


        this.CRM_Zone_Model.ZoneId = CRMZonetemp.ZoneId;
        this.CRM_Zone_Model.ZoneName = CRMZonetemp.ZoneName;
        this.CRM_Zone_Model.CountryId = CRMZonetemp.CountryId;
        this.CRM_Zone_Model.CountryName = CRMZonetemp.CountryName;
        this.CRM_Zone_Model.IsActive = CRMZonetemp.IsActive;

      });

    });
  }

  //  Update  Section Ends  


  //  Delete  Part  Starts  

  DeleteZone(ID: number): void {
    // alert(ID);
    this._confirmation.create('Confirm to Delete', 'Press Yes to Delete', this.settings)
      .subscribe((ans: ResolveEmit) => {
        if (ans.resolved == true) {

          let t = localStorage.getItem('userToken');
          t = 'Bearer' + t;

          if (ID > 0) {
            this.CRM_ZoneService.DeleteCRMZone(t, ID).subscribe(res => {
              this.DeleteZoneNameByID = res;

              if (this.DeleteZoneNameByID > 0) {
                localStorage.setItem('SuccessfullMessage', "Successfully Deleted");
                this.rout.navigateByUrl('ManageMICEAccess/ManageMICEUser', { skipLocationChange: true }).then(() => this.rout.navigate(["ManageMICEAccess/CrmZone"]));
              }
            });
          }

        }
      })
  }

  //  Delete  Part  Starts  


  //  View  Section Starts  


  ViewCRMZoneById(Id) {

    this.IsDataIsInViewState = true;
    let t = localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.CRM_ZoneService.GetCRM_Zone_By_id(t, Id).then(x => {
      this.CRMZone = x;
      Object.keys(this.CRMZone).forEach(key => {

        let CRMZonetemp = new Model_CRM_Zone();
        CRMZonetemp = this.CRMZone[key];

        this.CRM_Zone_Model.ZoneId = CRMZonetemp.ZoneId;
        this.CRM_Zone_Model.ZoneName = CRMZonetemp.ZoneName;
        this.CRM_Zone_Model.CountryId = CRMZonetemp.CountryId;
        this.CRM_Zone_Model.IsActive = CRMZonetemp.IsActive;
        this.CRM_Zone_Model.CountryName = CRMZonetemp.CountryName;

      });


      this.checkdataval = this.CRM_Zone_Model.CountryId;
      this.Checkeditstatus = false;
      this.CheckExiststatus = false;
      this.check = ""
    });
  }

  //  View Section  Strats
  RefreshPage() {

    this.rout.navigateByUrl('ManageMICEAccess/ManageMICEUser', { skipLocationChange: true }).then(() => this.rout.navigate(["ManageMICEAccess/CrmZone"]));
  }


}
