import { Component, OnInit } from '@angular/core';
import { DatePipe } from '@angular/common';
import { Router } from '@angular/router';
import { UserService } from '../../shared/user.service';
import { EmployeePortalService }  from '../../shared/HR_ADMIN/EmployeePortal/EmployeePortalService.service';
import { DirectDuty } from '../../shared/HR_ADMIN/EmployeePortal/DirectDuty.Model';
import { Menu } from '../../shared/menu.model';

@Component({
  selector: 'app-employee-direct-duty',
  templateUrl: './employee-direct-duty.component.html',
  styleUrls: ['./employee-direct-duty.component.css'],
  providers: [DatePipe]
})

export class EmployeeDirectDutyComponent implements OnInit 
{
  DirectDutyModel : DirectDuty= new DirectDuty();
  DirectDutyList: DirectDuty[]=[];
  DirectDutyApplication: any;
  DirectDuty: any;
  DirectId : any;
  userClaims: any;
  Result : string;
  SuccessStatus:boolean=false;
  SuccessMessage: string ="";
  public tableWidget: any;
  selectedIndex : number;
  // Begning Permission Portion    
  SelectedMenu:Menu;
  // End of the permission portion
  // begin for Validation
  validdate : boolean= false;
  DisableSave : boolean=false;
  
  AppliedMessage : string;
  CanApply : boolean=false;
  CanApplyHalfDay : boolean=false;
  // end of validation
  FromFirstHalf : boolean= true;
  FromSecondHalf : boolean= false;
  FromValue:string='FirstHalf';
  ToFirstHalf : boolean= true;
  ToSecondHalf : boolean= false;
  ToValue:string='FirstHalf';
  ShowMessage : boolean=false;
  ShowBalanceMessage : boolean=false;
  Differencevalue: number;
  FullDayShowMessage : boolean=false;
  FirstHalfShowMessage : boolean=false;
  SecondHalfShowMessage : boolean=false;

  constructor(private EmployeePortalService: EmployeePortalService, private rout: Router, private userService: UserService,public datepipe: DatePipe)
  { }

  ngOnInit()
  {
    // Begning Permission Portion 
    this.SelectedMenu=this.userService.SelectedMenu;
    if(this.SelectedMenu !=undefined)
    {
      localStorage.setItem('selectedmenu',JSON.stringify(this.SelectedMenu));
      this.rout.navigate([this.SelectedMenu.RouteName]);
    }
    else
    {
      if(JSON.parse(localStorage.getItem('selectedmenu'))!=undefined)
      {
        this.SelectedMenu = JSON.parse(localStorage.getItem('selectedmenu'));
        this.rout.navigate([this.SelectedMenu.RouteName]);
      }
    }
    // End of the permission portion
    if(localStorage.getItem('SuccessfullMessage'))
    {
      this.SuccessStatus=true;
      this.SuccessMessage =localStorage.getItem('SuccessfullMessage')
      localStorage.removeItem('SuccessfullMessage');
    }
    this.DisableSave=true;
    this.FromFirstHalf==true;
    this.FromSecondHalf==false;
    this.DirectDutyModel.FirstHalf="";
    this.ToFirstHalf==false;
    this.ToSecondHalf==true; 
    this.DirectDutyModel.SecondHalf="";
    this.DirectDutyModel.Fullday="Fullday";
    this.Differencevalue=0;
    this.FullDayShowMessage=false;
    this.FirstHalfShowMessage=false;
    this.SecondHalfShowMessage=false;
    this.GetEmployeeDirectDutyList();
    this.GetEmployeeDetails();
  }

  GetEmployeeDetails()
  {
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.EmployeePortalService.GetEmployeeDetailsByUserLogingIdLeaveApply( t).then(x => 
    {
      this.DirectDutyApplication = x;
      Object.keys(this.DirectDutyApplication).forEach( key => 
      {
        this.DirectDutyApplication[key];
        let DirectDutytemp = new DirectDuty();
        DirectDutytemp = this.DirectDutyApplication[key];
        this.DirectDutyModel.EmpId=this.DirectDutyApplication.EmployeeId;
        this.DirectDutyModel.EmpIdChk=this.DirectDutyApplication.EmployeeId;
        this.DirectDutyModel.EmployeeCode=this.DirectDutyApplication.EmployeeCode;
        this.DirectDutyModel.EmployeeName=this.DirectDutyApplication.EmployeeName;
        this.DirectDutyModel.EmployeeEmailId=this.DirectDutyApplication.EmployeeEmailId;
        this.DirectDutyModel.RHId=this.DirectDutyApplication.RHId;
        this.DirectDutyModel.RHCode=this.DirectDutyApplication.RHCode;
        this.DirectDutyModel.RHName=this.DirectDutyApplication.RHName;
        this.DirectDutyModel.RHEmailId=this.DirectDutyApplication.RHEmailId;
        this.DirectDutyModel.DateOfApply=this.DirectDutyApplication.ApplicationDate;
        this.DirectDutyModel.CompanyId=this.DirectDutyApplication.CompanyId;
      });
    });
  }

  GetEmployeeDirectDutyList()
  {
    this.DirectDutyList=[];
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.EmployeePortalService.GetEmployeeDirectDutyStatus( t).then(x => 
    {
      this.DirectDutyApplication = x;
      Object.keys(this.DirectDutyApplication).forEach( key => 
      {
        this.DirectDutyApplication[key];
        let DidrectDeutytemp = new DirectDuty();
        DidrectDeutytemp = this.DirectDutyApplication[key];
        this.DirectDutyList.push(DidrectDeutytemp);
      });
    });
  }

  ApplydateChanged( date)
  {
    this.DirectDutyModel.IsMorningDirectduty=false;
    this.DirectDutyModel.IsSecondHalfDirectDuty=false;
    this.DirectDutyModel.IsFullDayDirectDuty=true; 
    this.DirectDutyModel.SecondHalf="";
    this.DirectDutyModel.FirstHalf="";
    this.DirectDutyModel.Fullday="Fullday";
    this.FullDayShowMessage=false;
    this.FirstHalfShowMessage=false;
    this.SecondHalfShowMessage=false;
    this.DirectDutyModel.Reason="";
    this.DirectDutyModel.Place="";
    this.DirectDutyModel.DateOfDirectDuty=date;
    var CurrentDate = new Date();
    let time = new Date().getHours() + ':' + new Date().getMinutes() + ':'+  new Date().getSeconds();
    if(this.DirectDutyModel.CompanyId == 3)
    {
      var CurrentDay = CurrentDate.getDate();
      if(CurrentDay == 26)
      {
        if('12:00:59' <= time)
        {
          CurrentDate.setDate( CurrentDate.getDate() - 1 );
        }
      }
      else if(CurrentDay !=  26)
      {
        CurrentDate.setDate( CurrentDate.getDate() - 1 );
      }
    }
    
    var selectedDate = new Date(date);

    let selecteddateString = selectedDate.toDateString();
    let newselectedDate = new Date(selecteddateString);
    let CurrentdateString = CurrentDate.toDateString();
    let newCurrentDate = new Date(CurrentdateString);
    if (newselectedDate >= newCurrentDate)
    {
      this.validdate = true;
    }
    else
    {
      this.validdate = false;
    }

    if(this.validdate==false)
    {
      this.ShowMessage=true;
    }
    else
    {
      this.GetEmployeeAttendanceDetails(date);
      this.ShowMessage=false;
    }
  }

  OnChangeFromFirstHalf(evt)
  {
    var target = evt.target;
    if(target.checked)
    {           
      this.DirectDutyModel.IsMorningDirectduty=true;
      this.DirectDutyModel.IsSecondHalfDirectDuty=false; 
      this.DirectDutyModel.IsFullDayDirectDuty=false; 
      this.DirectDutyModel.FirstHalf="FirstHalf";
      this.DirectDutyModel.SecondHalf="";
      this.DirectDutyModel.Fullday="";
    }
    if(this.CanApplyHalfDay == true && this.CanApply == true)
    {
      if(this.DirectDutyModel.DateOfDirectDuty != null)
      {
        this.FullDayShowMessage=false;
        this.FirstHalfShowMessage=false;
        this.SecondHalfShowMessage=false;
      }
      else
      {
        this.ShowMessage=true;
      }
    }
    else
    {
      this.FullDayShowMessage=false;
      this.FirstHalfShowMessage=true;
      this.SecondHalfShowMessage=false;
      this.DirectDutyModel.IsMorningDirectduty=false;
      this.DirectDutyModel.IsSecondHalfDirectDuty=false;
      this.DirectDutyModel.IsFullDayDirectDuty=true; 
      this.DirectDutyModel.SecondHalf="";
      this.DirectDutyModel.FirstHalf="";
      this.DirectDutyModel.Fullday="Fullday";
    }
  }

  OnChangeFromSecondHalf(evt)
  {
    var target = evt.target;
    if(target.checked)
    {           
      this.DirectDutyModel.IsMorningDirectduty=false;
      this.DirectDutyModel.IsSecondHalfDirectDuty=true;
      this.DirectDutyModel.IsFullDayDirectDuty=false; 
      this.DirectDutyModel.SecondHalf="SecondHalf";
      this.DirectDutyModel.FirstHalf="";
      this.DirectDutyModel.Fullday="";
    }
    if(this.CanApplyHalfDay == true && this.CanApply == true)
    {
      if(this.DirectDutyModel.DateOfDirectDuty!= null)
      {
        let tempdate: any;
        tempdate= this.datepipe.transform(this.DirectDutyModel.DateOfApply, 'yyyy-MM-dd');
        
        //  let datetest= new Date(tempdate);
        this.DirectDutyModel.DateOfApply =tempdate;
        if((this.DirectDutyModel.DateOfApply) <= this.DirectDutyModel.DateOfDirectDuty)
        {
          this.FullDayShowMessage=false;
          this.FirstHalfShowMessage=false;
          this.SecondHalfShowMessage=false;
        }
        else if(this.DirectDutyModel.CompanyId == 3)
        {
          this.FullDayShowMessage=false;
          this.FirstHalfShowMessage=false;
          this.SecondHalfShowMessage=false;
        }
        else
        {
          this.FullDayShowMessage=false;
          this.FirstHalfShowMessage=false;
          this.SecondHalfShowMessage=true;
        }
      }
      else
      {
        this.ShowMessage=true;
      }
    }
    else
    {
      this.FullDayShowMessage=false;
      this.FirstHalfShowMessage=false;
      this.SecondHalfShowMessage=true;
      this.DirectDutyModel.IsMorningDirectduty=false;
      this.DirectDutyModel.IsSecondHalfDirectDuty=false;
      this.DirectDutyModel.IsFullDayDirectDuty=true; 
      this.DirectDutyModel.SecondHalf="";
      this.DirectDutyModel.FirstHalf="";
      this.DirectDutyModel.Fullday="Fullday";
    }
  }

  OnChangeFullday(evt)
  {
    var target = evt.target;
    if(target.checked)
    {           
      this.DirectDutyModel.IsMorningDirectduty=false;
      this.DirectDutyModel.IsSecondHalfDirectDuty=false;
      this.DirectDutyModel.IsFullDayDirectDuty=true; 
      this.DirectDutyModel.SecondHalf="";
      this.DirectDutyModel.FirstHalf="";
      this.DirectDutyModel.Fullday="Fullday";
    }
    if(this.CanApply == true)
    {
      if(this.DirectDutyModel.DateOfDirectDuty!= null)
      {
        var EmployeeIds=[6,12,27,28,34,187,189,201,214,224,451,452];
        
        if(EmployeeIds.length > 0)  
        {
          for (var _i = 0; _i < EmployeeIds.length; _i++) 
          {
            var item;
            if(EmployeeIds[_i] == this.DirectDutyModel.EmpIdChk)
            {
              item=this.DirectDutyModel.EmpIdChk;
              break;
            }
            else
            {
              item=0;
            }
          }
        }

        if(this.DirectDutyModel.CompanyId  > 0)
        {
          this.FullDayShowMessage=false;
          this.FirstHalfShowMessage=false;
          this.SecondHalfShowMessage=false;
        }
        else if(this.DirectDutyModel.EmpId == item)
        {
          this.FullDayShowMessage=false;
          this.FirstHalfShowMessage=false;
          this.SecondHalfShowMessage=false;
        }
        else if(this.DirectDutyModel.DateOfApply < this.DirectDutyModel.DateOfDirectDuty)
        {
          this.FullDayShowMessage=false;
          this.FirstHalfShowMessage=false;
          this.SecondHalfShowMessage=false;
        }
        else
        {
          this.FullDayShowMessage=true;
          this.FirstHalfShowMessage=false;
          this.SecondHalfShowMessage=false;
        }
      }
      else
      {
        this.ShowMessage=true;
      }
    }
    else
    {
      this.FullDayShowMessage=true;
      this.FirstHalfShowMessage=false;
      this.SecondHalfShowMessage=false;
    }
  }

  SaveEmployeeDirectDuty() :void
  {
    if(this.validdate == true 
      && this.DirectDutyModel.Reason.length > 0 && this.CanApply == true
      && this.DirectDutyModel.AppliedIsApproved == false 
      && this.DirectDutyModel.Place.length > 0
      && (this.DirectDutyModel.IsMorningDirectduty==true || this.DirectDutyModel.IsSecondHalfDirectDuty==true || this.DirectDutyModel.IsFullDayDirectDuty==true))
    {
      this.DisableSave=false;
      let t =  localStorage.getItem('userToken');
      t = 'Bearer' + t;
      this.EmployeePortalService.AddEmployeeDirectDuty(t ,this.DirectDutyModel).subscribe(res=>
      {
        this.DirectId=res;
        this.DirectDutyModel.DirectId=0;              
        if(this.DirectId>0)
        {
          localStorage.setItem('SuccessfullMessage', "Direct Duty Apply Successfully");
          this.rout.navigateByUrl('/EmployeePortal/LeaveStatus', {skipLocationChange: true}).then(()=>this.rout.navigate(["/EmployeePortal/DireectDuty"]));
        }
      }); 
    }
  }

  RefreshPage()
  {
    this.rout.navigateByUrl('/EmployeePortal/LeaveStatus', {skipLocationChange: true}).then(()=>this.rout.navigate(["/EmployeePortal/DireectDuty"]));
  }

  GetEmployeeAttendanceDetails(AttendanceDate)
  {
    this.CanApply = true;
    this.CanApplyHalfDay= true;
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.EmployeePortalService.CheckDirectDutyExistsByUserId( t,AttendanceDate).then(x => 
    {
      this.DirectDuty = x;
      Object.keys(this.DirectDuty).forEach( key => 
      {
        this.DirectDuty[key];
        let DirectDutytemp = new DirectDuty();
        DirectDutytemp = this.DirectDuty[key];
        this.DirectDutyModel.AppliedStatus=DirectDutytemp.AppliedStatus;
        this.DirectDutyModel.DirectId=DirectDutytemp.DirectId;
        this.DirectDutyModel.AppliedIsApproved=DirectDutytemp.IsApproved;
        this.DirectDutyModel.WorkingDuration=DirectDutytemp.WorkingDuration;
        this.DirectDutyModel.IsOffday=DirectDutytemp.IsOffday;
        if(DirectDutytemp.IsOffday == true)
        {
          this.AppliedMessage="Off Day ! Hollidays !.";
          this.CanApply=false;
          this.CanApplyHalfDay=false;
          this.DirectDutyModel.AppliedStatus=true;
        }
        else if(DirectDutytemp.IsFullDayDirectDuty == true)
        {
          this.AppliedMessage="Application has been Already Applied for Full Day.";
          this.CanApply=false;
          this.CanApplyHalfDay=false;
        }
        else if(DirectDutytemp.IsMorningDirectduty == true)
        {
          if(DirectDutytemp.IsApproved == true)
          {
            this.AppliedMessage="Application has been Already Approved for First Half. You Can't Apply!";
          }
          else
          {
            this.AppliedMessage="Application has been Already Applied for First Half. You Can Apply for Full Day!";
          }
          this.CanApply=true;
          this.CanApplyHalfDay=false;
          this.DirectDutyModel.IsMorningDirectduty=false;
          this.DirectDutyModel.IsSecondHalfDirectDuty=false;
          this.DirectDutyModel.IsFullDayDirectDuty=true; 
          this.DirectDutyModel.SecondHalf="";
          this.DirectDutyModel.FirstHalf="";
          this.DirectDutyModel.Fullday="Fullday";
        }
        else if(DirectDutytemp.IsSecondHalfDirectDuty == true)
        {
          if(DirectDutytemp.IsApproved == true)
          {
            this.AppliedMessage="Application has been Already Approved for Second Half. You Can't Apply!";
          }
          else
          {
            this.AppliedMessage="Application has been Already Applied for Second Half. You Can Apply for Full Day!";
          }
          this.CanApply=true;
          this.CanApplyHalfDay=false;
          this.DirectDutyModel.IsMorningDirectduty=false;
          this.DirectDutyModel.IsSecondHalfDirectDuty=false;
          this.DirectDutyModel.IsFullDayDirectDuty=true; 
          this.DirectDutyModel.SecondHalf="";
          this.DirectDutyModel.FirstHalf="";
          this.DirectDutyModel.Fullday="Fullday";
        }
        this.DirectDutyModel.AppliedFirstHalf=DirectDutytemp.IsMorningDirectduty;
        this.DirectDutyModel.AppliedSecondHalf=DirectDutytemp.IsSecondHalfDirectDuty;
        this.DirectDutyModel.AppliedFullDay=DirectDutytemp.IsFullDayDirectDuty;
      });
    });
  }
}