<perfect-scrollbar style="max-width: 1260px; max-height: 584px;" class="mukesh">
  <div class="body-content-area tabcontent" id="link1">
    <div class="row">
      <div class="col-lg-9">
        <div class="row">
          <div class="col-lg-1 low-padding text-center">
            <!-- <img *ngIf="this.SelectedMenu.Image!=null" src={{this.SelectedMenu.Image}} class="body-icon" alt="Icon"/> -->
          </div>
          <div class="col-lg-10 clear-padding">
            <div>
              <span class="page-title">Employee CompOff Application</span>
              <small class="page-description"></small>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div *ngIf="!(this.CanViewState)">
      <app-dialog [(visible)]="this.SuccessStatus">
        <span class="successmsg">{{this.SuccessMessage}}</span>
      </app-dialog>
    <fieldset class="body-form-control" > 
      <legend class="legend--">Employee Details</legend>
      <form #EmployeeAttendanceForm="ngForm">
        <div class="row">
          <div class="col-lg-4">
            <div class="form-group row">
              <label class="col-sm-2 col-form-label clear-padding-right">Employee</label>
              <div class="col-sm-9 clear-left-padding">
                <angular2-multiselect tabindex="1" class="form-control" [data]="emp" [(ngModel)]="selectedItems" name="EmployeeId" [settings]="EmployeeSettings"
                  (onSelect)="onItemSelect($event)" (onDeSelect)="OnItemDeSelect($event)" (onSelectAll)="onSelectAll($event)" (onDeSelectAll)="onDeSelectAll($event)">
                </angular2-multiselect>
              </div>
              <div class="required-validation" *ngIf="EmployeeCompoffModel.EmployeeId == 0">Employee is required</div>
            </div>
          </div>
        </div>
      </form>    
    </fieldset>

    <fieldset *ngIf="(IsApplicationView)" class="body-form-control" > 
      <legend class="legend--">Leave CompOff Application</legend>
      <form #EmployeeCompOffApplication="ngForm" (ngSubmit)="SaveAttendanceCompOffApply()">
        <div class="row">
          <div class="col-lg-4">
            <div class="col-body-border-right">
              <div class="form-group row">
                <label class="col-sm-4 col-form-label clear-padding-right">Employee Id</label>
                <div class="col-sm-7 clear-left-padding">
                  :&nbsp;&nbsp;{{this.EmployeeCompoffModel.EmployeeCode}} 
                </div>
              </div>
              <div class="form-group row">
                <label  class="col-sm-4 col-form-label clear-padding-right">Attendance Date</label>
                <div class="col-sm-7 clear-left-padding">
                  :&nbsp;&nbsp;<input type="date" tabindex="1" style="width: 90%;" name="FromDate" [ngModel]="EmployeeCompoffModel.AttendanceDate | date:'yyyy-MM-dd'" (ngModelChange)="AttendanceDateChanged($event)" #FromDate="ngModel" required/>
                </div>
                <div class="required-validation" *ngIf="!(this.IsOffDay)">Already Working Day.</div>
                <div class="required-validation" *ngIf="validdate">Please select Valid Date.</div>
              </div>
              
              <div class="form-group row">
                <label class="col-sm-4 col-form-label clear-padding-right">Login InTime</label>
                <div class="col-sm-7 clear-left-padding">
                  :&nbsp;&nbsp;{{this.EmployeeCompoffModel.ActualInTime}}
                </div>
              </div>
              <div class="form-group row">
                <label class="col-sm-4 col-form-label clear-padding-right">CompOff</label>
                <div class="col-sm-7 clear-left-padding">
                    :&nbsp;&nbsp;
                  <label class="col-sm-4 col-form-label clear-padding-right">
                    <input type="radio" tabindex="3" [checked]="this.EmployeeCompoffModel.Fromvalue=='HalfDay'" (click)="OnChangeHalfDay($event)" id ="HalfDay" name="HalfDay">
                    HalfDay
                  </label>
                  &nbsp;
                  <label class="col-sm-4 col-form-label clear-padding-left">
                    <input type="radio" tabindex="4" [checked]="this.EmployeeCompoffModel.Fromvalue=='FullDay'" (click)="OnChangeFullDay($event)" id ="FullDay" name="FullDay">
                    FullDay
                  </label>
                </div>
              </div>
            </div> 
          </div>

          <div class="col-lg-4">
            <div class="col-body-border-right">
              <div class="form-group row">
                <label class="col-sm-4 col-form-label clear-padding-right">Employee Name</label>
                <div class="col-sm-7 clear-left-padding">
                  :&nbsp;&nbsp;{{this.EmployeeCompoffModel.EmployeeName}} 
                </div>
              </div>
              <div class="form-group row">
                <label  class="col-sm-4 col-form-label clear-padding-right">Day</label>
                <div class="col-sm-7 clear-left-padding">
                  :&nbsp;&nbsp;{{this.EmployeeCompoffModel.AttendanceDay}}
                </div>
              </div>
              <div class="form-group row">
                <label  class="col-sm-4 col-form-label clear-padding-right">LogOut Time</label>
                <div class="col-sm-7 clear-left-padding">
                    :&nbsp;&nbsp;{{this.EmployeeCompoffModel.ActualOutTime}}
                </div>
              </div>
              <div class="form-group row">
                <label  class="col-sm-4 col-form-label clear-padding-right">Reason</label>
                <div class="col-sm-7 clear-left-padding">
                  <input type="text" [(ngModel)]="EmployeeCompoffModel.Reason" tabindex="4" maxlength="500" id ="Reason" name="Reason" #Reason="ngModel" required class="form-control form-control-custom form-control-custom-new" >
                </div>
                <div *ngIf="Reason.invalid && (Reason.dirty || Reason.touched)" class="required-validation">
                  <div *ngIf="Reason.errors.required">Reasons is required.</div>
                  <div *ngIf="Reason.errors.maxlength">Reasons is not more than 500 characters</div>
                </div> 
              </div>
            </div> 
          </div>

          <div class="col-lg-4">
            <div class="form-group row">
              <label class="col-sm-4 col-form-label clear-padding-right">Approver Name</label>
              <div class="col-sm-7 clear-left-padding">
                :&nbsp;&nbsp;{{this.EmployeeCompoffModel.RHName}}
              </div>
            </div>
            <div class="form-group row">
              <label class="col-sm-4 col-form-label clear-padding-right">Status</label>
              <div class="col-sm-7 clear-left-padding">
                :&nbsp;&nbsp;{{this.EmployeeCompoffModel.Status}}
              </div>
            </div>
            <div class="form-group row">
              <label  class="col-sm-4 col-form-label clear-padding-right">No. Of Works Hrs</label>
              <div class="col-sm-7 clear-left-padding">
                  :&nbsp;&nbsp;{{this.EmployeeCompoffModel.ActualWorkingDuration}}
              </div>
            </div>
            <div class="form-group row">
              <label class="col-sm-6 col-form-label text-center clear-padding-right">
                <button type="submit" tabindex="13" class="btn btn-primary save-button" [disabled]="!(EmployeeCompOffApplication.valid && EmployeeCompoffModel.Reason !='' && this.DisableSave==true && this.validdate == false && this.IsOffDay== true && this.ShowMessage== false && this.EmployeeCompoffModel.Status !='')">Save</button>  
              </label>
              <div class="col-sm-6 col-form-label text-center">
                <button type="button" tabindex="14" (click)="RefreshPage()" class="btn btn-danger cancle-button">Cancel</button>
              </div>
            </div>
            <div class="form-group row">
              <div class="required-validation" *ngIf="ShowMessage">Application has been Already Applied.</div>
            </div>
          </div>
        </div>
      </form>
    </fieldset>
    </div>
  </div>
</perfect-scrollbar>