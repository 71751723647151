import { INgxMyDpOptions, IMyDateModel } from 'ngx-mydatepicker';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from '../../shared/user.service';
import { EmployeeMasterService }  from '../../shared/HR_ADMIN/Employee/EmployeeMaster.Service';
import { EmployeePortalService }  from '../../shared/HR_ADMIN/EmployeePortal/EmployeePortalService.service';
import { EmployeeDetail } from '../../shared/HR_ADMIN/Employee/EmployeeDetail.Model';
import { Menu } from '../../shared/menu.model';
import { Languages } from '../../shared/HR_ADMIN/Employee/Language';
import { Religion } from '../../shared/HR_ADMIN/Employee/Religion';
import { ModelGander } from '../../shared/HR_ADMIN/Employee/ModelGander';
import { ModelMarital } from '../../shared/HR_ADMIN/Employee/ModelMarital';
import { EmployeeEducationalModel } from '../../shared/HR_ADMIN/Employee/EmployeeEducationalModel';
import { EmployeeExprienceModel} from '../../shared/HR_ADMIN/Employee/EmployeeExprienceModel';
import { EmployeeFamilyDetailsModel} from '../../shared/HR_ADMIN/Employee/EmployeeFamilyDetailsModel';
import { ReplaySubject } from "rxjs/ReplaySubject";
import { EmployeeRelationModel } from '../../shared/HR_ADMIN/Employee/EmployeeRelation.Model';
import { Country } from'../../shared/HR_ADMIN/Location/Country.Model';
import { State } from '../../shared/Organization/State.Model';
import { District } from'../../shared/HR_ADMIN/Location/District.Model';
import { City } from'../../shared/HR_ADMIN/Location/City.Model';
import { LocationService }  from '../../shared/HR_ADMIN/Location/location.service';
import { OrganizationService }  from '../../shared/Organization/organization.service';

@Component({
  selector: 'app-manage-employee-details-modification',
  templateUrl: './manage-employee-details-modification.component.html',
  styleUrls: ['./manage-employee-details-modification.component.css']
})
export class ManageEmployeeDetailsModificationComponent implements OnInit 
{
  EmployeeModel : EmployeeDetail= new EmployeeDetail();
  userClaims: any;
  Employees: EmployeeDetail[] = [];
  employee: any;
  Application: any;

  Result : string;
  SuccessStatus:boolean=false;
  SuccessMessage: string ="";
  public tableWidget: any;
  EmployeeId : any;
  selectedIndex : number;

  tempEmployeeadd : EmployeeDetail =new EmployeeDetail();
  // Begning Permission Portion    
  SelectedMenu:Menu;
  // End of the permission portion
  Ganders : ModelGander[]=[];
  Gander : any;

  GanderFamilys : ModelGander[]=[];
  GanderFamily : any;

  Maritials : ModelMarital[]=[];
  Marit : any;

  Languages : Languages[] = [];
  Lang : any;

  Religions : Religion[] =[];
  Reli : any;

  Relations : EmployeeRelationModel[]=[];
  Realation : any;

  imageUrl: string="";
  AnniversaryDateShow : boolean= false;
  filetoupload: File=null;

  CountryModel : Country= new Country();
  StateModel : State= new State();
  DistrictModel : District= new District();
  CityModel : City= new City();
  Countries : Country[]=[];
  States : State[]=[];
  Dists : District[] =[];
  Cities : City[]=[];

  City : any;
  State : any;
  country : any;
  Dist : any;

  employeeEducationUndefine : string ="";
  employeeExpriencUndefine : string ="";
  employeeNomineeDetailsUndefine : string ="";
  employeeFamilyDetailsUndefine : string ="";
  myOptions: INgxMyDpOptions = 
  {
    // other options...
    dateFormat: 'dd.mm.yyyy',
  };
  model: any = { date: { year: 2018, month: 10, day: 9 } };
  DisableSave : boolean;
  AppliedStatus : boolean;
  status :any;

  constructor(public EmployeeMasterService: EmployeeMasterService, private rout: Router, private LocationService: LocationService,
    private OrganizationService: OrganizationService, private userService: UserService, private EmployeePortalService: EmployeePortalService) 
  {

  }

  ngOnInit() 
  {
    // Begning Permission Portion 
    this.SelectedMenu=this.userService.SelectedMenu;
    if(this.SelectedMenu !=undefined)
    {
      localStorage.setItem('selectedmenu',JSON.stringify(this.SelectedMenu));
      this.rout.navigate([this.SelectedMenu.RouteName]);
    }
    else
    {
      if(JSON.parse(localStorage.getItem('selectedmenu'))!=undefined)
      {
        this.SelectedMenu = JSON.parse(localStorage.getItem('selectedmenu'));
        this.rout.navigate([this.SelectedMenu.RouteName]);
      }
    }
    // End of the permission portion
    if(localStorage.getItem('SuccessfullMessage'))
    {
      this.SuccessStatus=true;
      this.SuccessMessage =localStorage.getItem('SuccessfullMessage')
      localStorage.removeItem('SuccessfullMessage');
    }
    this.getGenders();
    this.getMaritalStatus();
    this.getReligion();
    this.getLanguage();
    this.getGenderFamily();
    this.getRelation();
    this.getCountries();
    this.GetEmployeeDetails();
    this.GetEmpDetailsForView();
    this.DisableSave=true;
    this.CheckEmployeeCodeByCode(); 
  }

  GetEmployeeDetails()
  {
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.EmployeePortalService.GetEmployeeDetailsByUserLogingIdLeaveApply( t).then(x => 
    {
      this.Application = x;
      Object.keys(this.Application).forEach( key => 
      {
        this.Application[key];
        let Applicationtemp = new EmployeeDetail();
        Applicationtemp = this.Application[key];
        this.EmployeeModel.EmployeeId=this.Application.EmployeeId;
        this.EmployeeModel.EmployeeCode=this.Application.EmployeeCode;
        this.EmployeeModel.EmployeeName=this.Application.EmployeeName;
        this.EmployeeModel.ReportingHeadId=this.Application.ReportingHeadId;
        this.EmployeeModel.ReportingHeadName=this.Application.ReportingHeadName;
      });
    });
  }

  OnChangePersonalInfo(evt)
  {
    var target = evt.target;
    if(target.checked)
    {           
      this.EmployeeModel.PersonalDetails=true;
    }
    else
    {
      this.EmployeeModel.PersonalDetails=false;
    }
    if(this.EmployeeMasterService.SharedEmployees.MaritalId == 1)
    {
      this.AnniversaryDateShow=true;
    }
    else
    {
      this.AnniversaryDateShow=false;
    }
    if(this.EmployeeMasterService.SharedEmployees.ImageFile != null || this.EmployeeMasterService.SharedEmployees.ImageFile != '')
    {
      this.EmployeeMasterService.SharedEmployees.validfile=true;
    }
    this.changetdateformat();
  }

  OnChangeAddress(evt)
  {
    var target = evt.target;
    if(target.checked)
    {           
      this.EmployeeModel.AddressDetails=true;
    }
    else
    {
      this.EmployeeModel.AddressDetails=false;
    }
    if(this.EmployeeMasterService.SharedEmployees.CCountryId>0)
    {
      this.getStates(this.EmployeeMasterService.SharedEmployees.CCountryId);
    }
    if(this.EmployeeMasterService.SharedEmployees.CStateId>0)
    {
      this.getDists(this.EmployeeMasterService.SharedEmployees.CStateId);
    }
    if(this.EmployeeMasterService.SharedEmployees.CDistrictId>0)
    {
      this.getCities(this.EmployeeMasterService.SharedEmployees.CDistrictId);
    }
  }

  OnChangeOtherInfo(evt)
  {
    var target = evt.target;
    if(target.checked)
    {           
      this.EmployeeModel.OtherDetails=true;
    }
    else
    {
      this.EmployeeModel.OtherDetails=false;
    }
  }

  OnChangeQualification(evt)
  {
    var target = evt.target;
    if(target.checked)
    {           
      this.EmployeeModel.QualificationDetails=true;
    }
    else
    {
      this.EmployeeModel.QualificationDetails=false;
    }
    if(this.EmployeeMasterService.SharedEmployees.EmployeeEducation==null)
    {
      let EmployeeEducationList : EmployeeEducationalModel[]=[];
      for( var i=0; i<2; i++ )
      {
        let tempeducation = new EmployeeEducationalModel();          
        EmployeeEducationList.push(tempeducation);         
      }
      this.EmployeeMasterService.SharedEmployees.EmployeeEducation=EmployeeEducationList;
    }
    if(this.EmployeeMasterService.SharedEmployees.EmployeeId > 0)
    {
      if(this.EmployeeMasterService.SharedEmployees.EmployeeEducation.length==0)
      {
        let EmployeeEducationList : EmployeeEducationalModel[]=[];
        for( var i=0; i<2; i++ )
        {
          let tempeducation = new EmployeeEducationalModel();          
          EmployeeEducationList.push(tempeducation);         
        }
        this.EmployeeMasterService.SharedEmployees.EmployeeEducation=EmployeeEducationList;
      }
      else
      {
        this.Qualificationchangetdateformat();
      }
    }
  }

  OnChangeExperience(evt)
  {
    var target = evt.target;
    if(target.checked)
    {           
      this.EmployeeModel.ExperienceDetails=true;
    }
    else
    {
      this.EmployeeModel.ExperienceDetails=false;
    }
    if(this.EmployeeMasterService.SharedEmployees.EmployeeExprienceModel==null)
    {
      let EmployeeExprienceList : EmployeeExprienceModel[]=[];
      for( var i=0; i<2; i++ )
      {
        let tempexprience = new EmployeeExprienceModel();          
        EmployeeExprienceList.push(tempexprience);         
      }
      this.EmployeeMasterService.SharedEmployees.EmployeeExprienceModel=EmployeeExprienceList;
    }
    if(this.EmployeeMasterService.SharedEmployees.EmployeeId > 0)
    {
      if(this.EmployeeMasterService.SharedEmployees.EmployeeExprienceModel.length==0)
      {
        let EmployeeExprienceList : EmployeeExprienceModel[]=[];
        for( var i=0; i<2; i++ )
        {
          let tempexprience = new EmployeeExprienceModel();          
          EmployeeExprienceList.push(tempexprience);         
        }
        this.EmployeeMasterService.SharedEmployees.EmployeeExprienceModel=EmployeeExprienceList;
      }
      else
      {
        this.changetdateformatExperince();
      }
    }
  }

  OnChangeFamily(evt)
  {
    var target = evt.target;
    if(target.checked)
    {           
      this.EmployeeModel.FamilyDetails=true;
    }
    else
    {
      this.EmployeeModel.FamilyDetails=false;
    }
    if(this.EmployeeMasterService.SharedEmployees.EmployeeFamilyDetailsModel==null)
    {
      let EmployeeFamilyList : EmployeeFamilyDetailsModel[]=[];
      for( var i=0; i<2; i++ )
      {
        let tempfamily = new EmployeeFamilyDetailsModel(); 
        tempfamily.FamilyRelationId=0; 
        tempfamily.FamilyGenderId=0;  
        EmployeeFamilyList.push(tempfamily);         
      }
      this.EmployeeMasterService.SharedEmployees.EmployeeFamilyDetailsModel=EmployeeFamilyList;
    }
    if(this.EmployeeMasterService.SharedEmployees.EmployeeId > 0)
    {
      if(this.EmployeeMasterService.SharedEmployees.EmployeeFamilyDetailsModel.length==0)
      {
        let EmployeeFamilyList : EmployeeFamilyDetailsModel[]=[];
        for( var i=0; i<2; i++ )
        {
          let tempfamily = new EmployeeFamilyDetailsModel(); 
          tempfamily.FamilyRelationId=0; 
          tempfamily.FamilyGenderId=0;  
          EmployeeFamilyList.push(tempfamily);         
        }
        this.EmployeeMasterService.SharedEmployees.EmployeeFamilyDetailsModel=EmployeeFamilyList;
      }
      else
      {
        this.changetdateformatFamily();
      }
    }
  }

  GetEmpDetailsForView()
  {
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.EmployeeMasterService.GetEmployeeDetailsForView( t).then(x => 
    {
      this.employee = x;
      Object.keys(this.employee).forEach( key => 
      {
        this.employee[key];
        let employeetemp = new EmployeeDetail();
        employeetemp = this.employee[key];
        this.EmployeeMasterService.SharedEmployees=this.employee;

        if( this.EmployeeMasterService.SharedEmployees.EmployeeEducation.length == 0)
        {
          this.employeeEducationUndefine ='notdefinedval';
        }
        if( this.EmployeeMasterService.SharedEmployees.EmployeeExprienceModel.length == 0)
        {
          this.employeeExpriencUndefine ='notdefinedval';
        }
        if(this.EmployeeMasterService.SharedEmployees.EmployeeNomineeDetailModel.length==0)
        {
          this.employeeNomineeDetailsUndefine ='notdefinedval';
        }
        if( this.EmployeeMasterService.SharedEmployees.EmployeeFamilyDetailsModel.length ==0)
        {
          this.employeeFamilyDetailsUndefine='notdefinedval';
        }
      });
    });
  }

  onDateChangedDOB(event: IMyDateModel): void
  {
    const monthNames = [
    'January', 'February', 'March',
    'April', 'May', 'June', 'July',
    'August', 'September', 'October',
    'November', 'December'
    ];
    var m= event.jsdate.getMonth();
    m=m+1;
    var d= event.jsdate.getDate();
    var y= event.jsdate.getFullYear();
    var fulldate="";
    if(m>=10)
    {
      if(d < 10)
      {
        fulldate= m + '/' + '0'+ d + '/' + y;
      }
      else
      {
        fulldate= m + '/' + d + '/' + y; 
      }
    }
    else
    {
      if(d < 10)
      {
        fulldate= '0' + m + '/' + '0'+ d + '/' + y;
      }
      else
      {
        fulldate= '0' + m + '/' + d + '/' + y; 
      } 
    }
    this.EmployeeMasterService.SharedEmployees.DOBPass=fulldate;
  }

  onDateChangedAnniversaryDate(event: IMyDateModel): void
  {
    const monthNames = [
      'January', 'February', 'March',
      'April', 'May', 'June', 'July',
      'August', 'September', 'October',
      'November', 'December'
      ];
    var m= event.jsdate.getMonth();
    m=m+1;
    var d= event.jsdate.getDate();
    var y= event.jsdate.getFullYear();
    var fulldate="";
    if(m>=10)
    {
      if(d < 10)
      {
        fulldate= m + '/' + '0'+ d + '/' + y;
      }
      else
      {
        fulldate= m + '/' + d + '/' + y; 
      }
    }
    else
    {
      if(d < 10)
      {
        fulldate= '0' + m + '/' + '0'+ d + '/' + y;
      }
      else
      {
        fulldate= '0' + m + '/' + d + '/' + y; 
      } 
    }
    this.EmployeeMasterService.SharedEmployees.AnniversaryDatePass=fulldate;
  }

  getGenders() 
  {
    this.Ganders=[];
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.EmployeeMasterService.GetGenderForDDL( t).then(x => 
    {
      this.Gander = x;
      Object.keys(this.Gander).forEach( key => 
      {
        let Gendertemp = new ModelGander();
        Gendertemp = this.Gander[key];
        this.Ganders.push(Gendertemp);
      });     
    });      
  }

  getGenderFamily() 
  {
    this.GanderFamilys=[];
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.EmployeeMasterService.GetGenderForDDL( t).then(x => 
    {
      this.GanderFamily = x;
      Object.keys(this.GanderFamily).forEach( key => 
      {
        let Gendertemp = new ModelGander();
        Gendertemp = this.GanderFamily[key];
        this.GanderFamilys.push(Gendertemp);
      });     
    });      
  }

  getLanguage() 
  {
    this.Languages=[];
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.EmployeeMasterService.GetLanguages( t).then(x => 
    {
      this.Lang = x;
      Object.keys(this.Lang).forEach( key => 
      {
        let Languagetemp = new Languages();
        Languagetemp = this.Lang[key];
        this.Languages.push(Languagetemp);
      });     
    });      
  }

  getReligion() 
  {
    this.Religions=[];
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.EmployeeMasterService.GetReligion( t).then(x => 
    {
      this.Reli = x;
      Object.keys(this.Reli).forEach( key => 
      {       
        let ReligionTemp = new Religion();
        ReligionTemp = this.Reli[key];
        this.Religions.push(ReligionTemp);
      });     
    });      
  }

  getMaritalStatus() 
  {
    this.Maritials=[];
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.EmployeeMasterService.GetMaritalStatusForDDL( t).then(x => 
    {
      this.Marit = x;
      Object.keys(this.Marit).forEach( key => 
      {
        this.Marit[key];
        let Maritaltemp = new ModelMarital();
        Maritaltemp = this.Marit[key];
        this.Maritials.push(Maritaltemp);
      });     
    });      
  }

  MaritalChanged( MaritalId)
  {
    if(MaritalId==1)
    {
      this.AnniversaryDateShow=true;
    }
    else
    {
      this.AnniversaryDateShow=false;
    }
  }

  changetdateformat()
  {
    let objemp = new EmployeeDetail();
    if(this.EmployeeMasterService.SharedEmployees.DOBPass != null)
    {
      this.EmployeeMasterService.SharedEmployees.DOB=objemp.ReturnFormatdate(this.EmployeeMasterService.SharedEmployees.DOBPass)
    }
    else
    {
      this.EmployeeMasterService.SharedEmployees.DOB=null;
    }
    if(this.EmployeeMasterService.SharedEmployees.AnniversaryDatePass != null)
    {
      this.EmployeeMasterService.SharedEmployees.AnniversaryDate=objemp.ReturnFormatdate(this.EmployeeMasterService.SharedEmployees.AnniversaryDatePass)
    }
    else
    {
      this.EmployeeMasterService.SharedEmployees.AnniversaryDate=null;
    }
  }
  
  handleFileinput(files : FileList)
  {
    this.filetoupload=files.item(0);
    //this.CompanyModel.LogoType= this.filetoupload.type
    var file = files[0];
    let filesize = this.filetoupload.size; 
    let filetyotypearray = this.filetoupload.type.split("/");
    this.EmployeeMasterService.SharedEmployees.ImageType= filetyotypearray[1];
    this.EmployeeMasterService.SharedEmployees.Photodoctype= filetyotypearray[0];
    if(this.EmployeeMasterService.SharedEmployees.Photodoctype=="image")
    {
      this.EmployeeMasterService.SharedEmployees.validfile=true; 
    }
    else
    {
      this.EmployeeMasterService.SharedEmployees.validfile=false; 
    }
    if(filesize > 500000)
    {
      this.EmployeeMasterService.SharedEmployees.validfile=false; 
    }
    if(!((this.EmployeeMasterService.SharedEmployees.ImageType=='jpeg') || (this.EmployeeMasterService.SharedEmployees.ImageType=='png') || (this.EmployeeMasterService.SharedEmployees.ImageType=='svg')|| (this.EmployeeMasterService.SharedEmployees.ImageType=='gif') || (this.EmployeeMasterService.SharedEmployees.ImageType=='wbmp')))
    {
      this.EmployeeMasterService.SharedEmployees.validfile=false; 
    }
    var reader : FileReader= new FileReader();
    reader.onload=(event:any)=>
    {
      this.imageUrl=event.target.result;
    } 
    reader.onloadend = (e) => 
    {
      this.EmployeeMasterService.SharedEmployees.Isphotochange=true;
      this.EmployeeMasterService.SharedEmployees.ImageFile = String( reader.result);
    }
    reader.readAsDataURL(file);   
  }

  public readFile(fileToRead: File)
  {
    let base64Observable = new ReplaySubject(1);
    let fileReader = new FileReader();
    fileReader.onload = event => 
    {
      base64Observable.next(fileReader.result);
    };
    fileReader.readAsDataURL(fileToRead);
    return base64Observable;
  }

  //Qualification
  onDateChangedFromQualification(event: IMyDateModel,i: number): void
  {
    const monthNames = [
      'January', 'February', 'March',
      'April', 'May', 'June', 'July',
      'August', 'September', 'October',
      'November', 'December'
      ];
    var m= event.jsdate.getMonth();
    m=m+1;
    var d= event.jsdate.getDate();
    var y= event.jsdate.getFullYear();
    var fulldate="";
    if(m>=10)
    {
      if(d < 10)
      {
        fulldate= m + '/' + '0'+ d + '/' + y;
      }
      else
      {
        fulldate= m + '/' + d + '/' + y; 
      }
    }
    else
    {
      if(d < 10)
      {
        fulldate= '0' + m + '/' + '0'+ d + '/' + y;
      }
      else
      {
        fulldate= '0' + m + '/' + d + '/' + y; 
      } 
    }
    this.EmployeeMasterService.SharedEmployees.EmployeeEducation[i].FromDatepass=fulldate;
     //event.formatted = event.jsdate.getDate() + ' ' +     monthNames[event.jsdate.getMonth()] + ', ' + event.jsdate.getFullYear();
  }

  onDateChangedToQualification(event: IMyDateModel,i: number): void
  {
    const monthNames = [
      'January', 'February', 'March',
      'April', 'May', 'June', 'July',
      'August', 'September', 'October',
      'November', 'December'
      ];
    var m= event.jsdate.getMonth();
    m=m+1;
    var d= event.jsdate.getDate();
    var y= event.jsdate.getFullYear();
    var fulldate="";
    if(m>=10)
    {
      if(d < 10)
      {
        fulldate= m + '/' + '0'+ d + '/' + y;
      }
      else
      {
        fulldate= m + '/' + d + '/' + y; 
      }
    }
    else
    {
      if(d < 10)
      {
        fulldate= '0' + m + '/' + '0'+ d + '/' + y;
      }
      else
      {
        fulldate= '0' + m + '/' + d + '/' + y; 
      } 
    }
    this.EmployeeMasterService.SharedEmployees.EmployeeEducation[i].ToDatepass=fulldate;
    //event.formatted = event.jsdate.getDate() + ' ' +     monthNames[event.jsdate.getMonth()] + ', ' + event.jsdate.getFullYear();
  }

  Qualificationchangetdateformat()
  {
    let objemp = new EmployeeDetail();
    this.EmployeeMasterService.SharedEmployees.EmployeeEducation.forEach(temp=>
    {
      temp.FromDate=objemp.ReturnFormatdate(temp.FromDatepass);
      temp.ToDate=objemp.ReturnFormatdate(temp.ToDatepass);
    })
  }

  AddnweRowQualification()
  {
    let tempeducation = new EmployeeEducationalModel();          
    this.EmployeeMasterService.SharedEmployees.EmployeeEducation.push(tempeducation);  
  }
  //Experince
  onDateChangedFromExperince(event: IMyDateModel,i: number): void
  {
    const monthNames = [
      'January', 'February', 'March',
      'April', 'May', 'June', 'July',
      'August', 'September', 'October',
      'November', 'December'
      ];
    var m= event.jsdate.getMonth();
    m=m+1;
    var d= event.jsdate.getDate();
    var y= event.jsdate.getFullYear();
    var fulldate="";
    if(m>=10)
    {
      if(d < 10)
      {
        fulldate= m + '/' + '0'+ d + '/' + y;
      }
      else
      {
        fulldate= m + '/' + d + '/' + y; 
      }
    }
    else
    {
      if(d < 10)
      {
        fulldate= '0' + m + '/' + '0'+ d + '/' + y;
      }
      else
      {
        fulldate= '0' + m + '/' + d + '/' + y; 
      } 
    }
    this.EmployeeMasterService.SharedEmployees.EmployeeExprienceModel[i].ExperinceFromDatePass=fulldate;
    //event.formatted = event.jsdate.getDate() + ' ' +     monthNames[event.jsdate.getMonth()] + ', ' + event.jsdate.getFullYear();
  }

  onDateChangedToExperince(event: IMyDateModel,i: number): void
  {
    const monthNames = [
      'January', 'February', 'March',
      'April', 'May', 'June', 'July',
      'August', 'September', 'October',
      'November', 'December'
      ];
    var m= event.jsdate.getMonth();
    m=m+1;
    var d= event.jsdate.getDate();
    var y= event.jsdate.getFullYear();
    var fulldate="";
    if(m>=10)
    {
      if(d < 10)
      {
        fulldate= m + '/' + '0'+ d + '/' + y;
      }
      else
      {
        fulldate= m + '/' + d + '/' + y; 
      }
    }
    else
    {
      if(d < 10)
      {
        fulldate= '0' + m + '/' + '0'+ d + '/' + y;
      }
      else
      {
        fulldate= '0' + m + '/' + d + '/' + y; 
      } 
    }
    this.EmployeeMasterService.SharedEmployees.EmployeeExprienceModel[i].ExperinceToDatePass=fulldate;
    //event.formatted = event.jsdate.getDate() + ' ' +     monthNames[event.jsdate.getMonth()] + ', ' + event.jsdate.getFullYear();
  }

  AddnweRowExperince()
  {
    let tempeducation = new EmployeeExprienceModel();          
    this.EmployeeMasterService.SharedEmployees.EmployeeExprienceModel.push(tempeducation);     
  }

  changetdateformatExperince()
  {
    let objemp = new EmployeeDetail();
    this.EmployeeMasterService.SharedEmployees.EmployeeExprienceModel.forEach(temp=>
    {
      temp.ExperinceFromDate=objemp.ReturnFormatdate(temp.ExperinceFromDatePass);
      temp.ExperinceToDate=objemp.ReturnFormatdate(temp.ExperinceToDatePass);
    })
  }
  //Family
  changetdateformatFamily()
  {
    let objemp = new EmployeeDetail();
    this.EmployeeMasterService.SharedEmployees.EmployeeFamilyDetailsModel.forEach(temp=>
    {
      temp.FamilyDOB=objemp.ReturnFormatdate(temp.FamilyDOBPass)
    })
  }

  AddnweRowFamily()
  {
    let tempf = new EmployeeFamilyDetailsModel();  
    tempf.FamilyRelationId=0; 
    tempf.FamilyGenderId=0;          
    this.EmployeeMasterService.SharedEmployees.EmployeeFamilyDetailsModel.push(tempf);     
  }

  OnChangeDOBFamily(event: IMyDateModel,i): void
  {
    const monthNames = [
      'January', 'February', 'March',
      'April', 'May', 'June', 'July',
      'August', 'September', 'October',
      'November', 'December'
      ];
    var m= event.jsdate.getMonth();
    m=m+1;
    var d= event.jsdate.getDate();
    var y= event.jsdate.getFullYear();
    var fulldate="";
    if(m>=10)
    {
      if(d < 10)
      {
        fulldate= m + '/' + '0'+ d + '/' + y;
      }
      else
      {
        fulldate= m + '/' + d + '/' + y; 
      }
    }
    else
    {
      if(d < 10)
      {
        fulldate= '0' + m + '/' + '0'+ d + '/' + y;
      }
      else
      {
        fulldate= '0' + m + '/' + d + '/' + y; 
      } 
    }
    this.EmployeeMasterService.SharedEmployees.EmployeeFamilyDetailsModel[i].FamilyDOBPass=fulldate;
  }

  getRelation() 
  {
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.EmployeeMasterService.GetRelation( t).then(x => 
    {
      this.Realation = x;
      Object.keys(this.Realation).forEach( key => 
      {
        let Relationtemp = new EmployeeRelationModel();
        Relationtemp = this.Realation[key];
        this.Relations.push(Relationtemp);
      });     
    });      
  }
  //Address
  getCountries() 
  {
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.OrganizationService.GetCountry( t).then(x => 
    {
      this.Countries=[];
      this.country = x;
      Object.keys(this.country).forEach( key => 
      {
        // tslint:disable-next-line:no-unused-expression
        this.country[key];
        let Countrytemp = new Country();
        Countrytemp = this.country[key];
        this.Countries.push(Countrytemp);
      });     
    });      
  }

  onCountryChange(countryid) 
  {
    this.EmployeeMasterService.SharedEmployees.CStateId=0;
    this.getStates(countryid);
    this.getDists(0);
    this.getCities(0);
  }

  getStates( countryid) 
  {
    this.States=[];
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t
    if(this.States.length>0)
    {
      this.States.splice(0, this.States.length);
    }
    this.OrganizationService.GetState( t,countryid).then(x => 
    {
      this.States=[];
      this.State = x;
      Object.keys(this.State).forEach( key => 
      {
        // tslint:disable-next-line:no-unused-expression
        let Statetemp = new State();
        Statetemp = this.State[key];
        this.States.push(Statetemp);
      });     
    });      
  }

  onstatechange(stateid)
  {
    this.EmployeeMasterService.SharedEmployees.CDistrictId=0;
    this.getDists(stateid);      
  }

  getDists( stateid) 
  {
    this.Dists=[];
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    if(this.Dists.length>0)
    {
      this.Dists.splice(0, this.Dists.length);
    }
    this.LocationService.GetDistrictByStateid( t,stateid).then(x => 
    {
      this.Dists=[];
      this.Dist = x;
      Object.keys(this.Dist).forEach( key => 
      {
        // tslint:disable-next-line:no-unused-expression
        let Disttemp = new District();
        Disttemp = this.Dist[key];
        this.Dists.push(Disttemp);
      });     
    });      
  }

  onDistrictchange(Districtid)
  {
    this.EmployeeMasterService.SharedEmployees.CCityId=0;
    this.getCities(Districtid);      
  }

  getCities(Distid) 
  {
    this.Cities=[];
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t
    if(this.Cities.length>0)
    {
      this.Cities.splice(0,this.Cities.length )
    }
    this.LocationService.GetCityByDistrictid( t,Distid).then(x => 
    {
      this.City = x;
      Object.keys(this.City).forEach( key => 
      {
        // tslint:disable-next-line:no-unused-expression
        this.City[key];
        let Citytemp = new City();
        Citytemp = this.City[key];
        this.Cities.push(Citytemp);
      });     
    });      
  }

  RefreshPage()
  {
    this.rout.navigateByUrl('/EmployeePortal/EmployeePayslip', {skipLocationChange: true}).then(()=>this.rout.navigate(["/EmployeePortal/ManageEmployeeModification"]));
  }

  SavePersonalModificationApplication() :void
  {
    if(this.DisableSave == true)
    {
      this.DisableSave=false;
      let t = localStorage.getItem('userToken');
      t = 'Bearer' + t;
      this.EmployeeMasterService.SharedEmployees.IsActive=true;
      if(this.EmployeeModel.PersonalDetails == true)
      {
        this.EmployeeMasterService.SharedEmployees.PersonalDetails=true;
      }
      if(this.EmployeeModel.AddressDetails == true)
      {
        this.EmployeeMasterService.SharedEmployees.AddressDetails=true;
      }
      if(this.EmployeeModel.OtherDetails == true)
      {
        this.EmployeeMasterService.SharedEmployees.OtherDetails=true;
      }
      if(this.EmployeeModel.QualificationDetails == true)
      {
        this.EmployeeMasterService.SharedEmployees.QualificationDetails=true;
      }
      if(this.EmployeeModel.ExperienceDetails == true)
      {
        this.EmployeeMasterService.SharedEmployees.ExperienceDetails=true;
      }
      if(this.EmployeeModel.FamilyDetails == true)
      {
        this.EmployeeMasterService.SharedEmployees.FamilyDetails=true;
      }
      this.EmployeeMasterService.AddPersonalModificationApplication(t ,this.EmployeeMasterService.SharedEmployees).subscribe(res=>
      {
        this.EmployeeId=res;
        if(this.EmployeeId>0)
        {
          localStorage.setItem('SuccessfullMessage', "Modification Application Forward to HR Successfully");
          this.rout.navigateByUrl('/EmployeePortal/EmployeePayslip', {skipLocationChange: true}).then(()=>this.rout.navigate(["/EmployeePortal/ManageEmployeeModification"]));
        }
      }); 
    }
  }

  CheckEmployeeCodeByCode()
  {
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.EmployeeMasterService.CheckEmployeeModoificationApplicationExists( t).then(x => 
    {
      this.status = x;
      if( this.status)
      {
        this.AppliedStatus =true;
      }
      else
      {
        this.AppliedStatus =false;
      }
    });
  }
}
