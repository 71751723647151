  <div class="body-content-area tabcontent" id="link1">
    <div class="row">
      <div class="col-lg-9">
        <div class="row">
          <div class="col-lg-1 low-padding text-center">
            <!-- <img *ngIf="this.SelectedMenu.Image!=null" src={{this.SelectedMenu.Image}} class="body-icon" alt="Icon"/> -->
          </div>
          <div class="col-lg-10 clear-padding">
            <span class="page-title">Manage Employee Expense Budget</span>
            <small class="page-description"></small>
          </div>
        </div>
      </div>
    </div>
  
    <div *ngIf="!(this.CanViewState)">
      <div style=" text-align: right;">
        <button (click)="ADDClick(template)" type="button" class="btn btn-primary Add-button">Add New</button> 
      </div>
      <fieldset class="body-form-control">
        <legend class="legend--">Employee Budget Details</legend>
        <table id="tblEmployeeBudget" class="table table-striped table-bordered text-center table-new row-border hover">
          <thead class="thead-blue text-center">
            <tr>
              <th>Sl No.</th>
              <th>Code</th>
              <th>Name</th>
              <th>Opening Amount</th>
              <th>Balance Amount</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let EB of EmployeeBudgets, let i = index">
              <td scope="row">{{i+1}}</td>
              <td class="text-center">{{EB.EmployeeCode}}</td>
              <td class="text-left">{{EB.EmployeeName}}</td>
              <td class="text-right">{{EB.OpeningAmount}}</td>
              <td class="text-right">{{EB.NetAvailableAmount}}</td>
              <td>
                <button *ngIf="this.SelectedMenu.CanEdit" type="button" (click)="GetEmployeeTourBudgetById(EB.RefId,template)" class="table-edit-btn"><i class="fas fa-edit"></i> </button> 
              </td>
            </tr>
          </tbody>
          <tfoot class="thead-blue text-center">
            <tr>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
            </tr>
          </tfoot>
        </table>
      </fieldset>
    
      <div style=" box-align: center; width: 1500px;">
        <ng-template #template>
          <div class="modal-header" style="background: #2a9bd7; max-width: 1500px;"> 
            <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()" style="color:#fff;">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div  style="text-align: center; max-width: 1500px;">
          <div *ngIf="SaveMsg" style="color: #dc3545; text-align: center;font-size: 15px; font-weight: bold;">Save Successfully</div>
          <div *ngIf="UpdateMsg" style="color: #dc3545; text-align: center;font-size: 15px; font-weight: bold;">Update Successfully</div>
          <!-- <div *ngIf="DeleteMsg" style="color: #dc3545; text-align: center;font-size: 15px; font-weight: bold;">Deleted Successfully</div> -->
          <div style=" width: 100%;" *ngIf="Insert">
            <fieldset class="body-form-control">
              <legend class="legend--">Employee Budget Insertion</legend>
              <form #EmployeeBudgetForm="ngForm" (ngSubmit)="saveEmployeeBudget(template)">
                <div class="row">
                  <div class="col-lg-4">
                    <div class="col-body-border-right">
                      <div class="form-group row">
                        <label class="col-sm-3 col-form-label clear-padding-right">Employee</label>
                        <div class="col-sm-9 clear-left-padding">
                          <angular2-multiselect tabindex="5" class="form-control" [data]="emp" [(ngModel)]="selectedItems" name="EmployeeId" 
                            [settings]="EmployeeSettings" (onSelect)="onItemSelect($event)" (onDeSelect)="OnItemDeSelect($event)" (onSelectAll)="onSelectAll($event)"
                            (onDeSelectAll)="onDeSelectAll($event)">
                          </angular2-multiselect>
                        </div>
                        <div class="required-validation" *ngIf="EmployeeBudgetModel.EmployeeId == 0">Employee is required</div>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-4">
                    <div class="form-group row">
                      <label class="col-sm-4 col-form-label clear-padding-right">Opening Amount</label>
                      <div class="col-sm-7 clear-left-padding">
                        <input type="text" [(ngModel)]="this.EmployeeBudgetModel.OpeningAmount" pattern="[0-9]+" id ="OpeningAmount" name="OpeningAmount" #OpeningAmount="ngModel" required class="form-control form-control-custom form-control-custom-new">
                      </div>
                      <div *ngIf="OpeningAmount.invalid && (OpeningAmount.dirty || OpeningAmount.touched)" class="alert alert-danger">
                        <div *ngIf="OpeningAmount.errors?.pattern">Opening Amount No should be valid.</div>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-4">
                    <div class="form-group row">
                      <label *ngIf="!(this.IsDataIsInViewState)" class="col-sm-6 col-form-label text-center clear-padding-right">
                        <button type="submit" class="btn btn-primary save-button" [disabled]="!(EmployeeBudgetForm.valid && this.DisableSave==true && this.EmployeeBudgetModel.EmployeeId > 0 && this.EmployeeBudgetModel.OpeningAmount > 0)" >Save</button>  
                      </label>
                    </div>
                  </div>
                </div>
              </form>
            </fieldset>
          </div>
          <div style=" width: 100%;" *ngIf="Updated">
            <fieldset class="body-form-control">
              <legend class="legend--">Employee Tour Budget</legend>
              <form #EmployeeBudgetUpdateForm="ngForm" (ngSubmit)="saveEmployeeBudget(template)">
                <div class="row">
                  <div class="col-lg-4">
                    <div class="col-body-border-right">
                      <div class="form-group row">
                        <label class="col-sm-3 col-form-label clear-padding-right">Id</label>
                        <div class="col-sm-9 clear-left-padding">
                          {{this.EmployeeBudgetModel.EmployeeCode}}
                          <input type="text" [(ngModel)]="this.EmployeeBudgetModel.EmployeeId" id ="EmployeeId" name="EmployeeId" #EmployeeId="ngModel" hidden="true">
                          <input type="text" [(ngModel)]="this.EmployeeBudgetModel.RefId" id ="RefId" name="RefId" #RefId="ngModel" hidden="true">
                        </div>
                      </div>
                      <div class="form-group row">
                        <label class="col-sm-4 col-form-label clear-padding-right">Credit Amount</label>
                        <div class="col-sm-7 clear-left-padding">
                          <input type="text" [(ngModel)]="this.EmployeeBudgetModel.CreditAmount" (ngModelChange)="onchangeCreditAmount($event)" pattern="[0-9]+" id ="CreditAmount" name="CreditAmount" #CreditAmount="ngModel" class="form-control form-control-custom form-control-custom-new">
                        </div>
                        <div *ngIf="CreditAmount.invalid && (CreditAmount.dirty || CreditAmount.touched)" class="alert alert-danger">
                          <div *ngIf="CreditAmount.errors?.pattern">Credit Amount No should be valid.</div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-4">
                    <div class="col-body-border-right">
                      <div class="form-group row">
                        <label class="col-sm-4 col-form-label clear-padding-right">Name</label>
                        <div class="col-sm-7 clear-left-padding">
                          {{this.EmployeeBudgetModel.EmployeeName}}
                        </div>
                      </div>
                      <div class="form-group row">
                        <label class="col-sm-4 col-form-label clear-padding-right">Debit Amount</label>
                        <div class="col-sm-7 clear-left-padding">
                          <input type="text" [(ngModel)]="this.EmployeeBudgetModel.DebitAmount" (ngModelChange)="onchangeDebitAmount($event)" pattern="[0-9]+" id ="DebitAmount" name="DebitAmount" #DebitAmount="ngModel" class="form-control form-control-custom form-control-custom-new">
                        </div>
                        <div *ngIf="DebitAmount.invalid && (DebitAmount.dirty || DebitAmount.touched)" class="alert alert-danger">
                          <div *ngIf="DebitAmount.errors?.pattern">Debit Amount No should be valid.</div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-4">
                    <div class="form-group row">
                      <label class="col-sm-4 col-form-label clear-padding-right">Available Balance</label>
                      <div class="col-sm-7 clear-left-padding">
                        {{this.EmployeeBudgetModel.NetAvailableAmount}}
                      </div>
                    </div>
                    <div class="form-group row">
                      <label class="col-sm-4 col-form-label clear-padding-right">Remarks</label>
                      <div class="col-sm-7 clear-left-padding">
                          <input type="text" [(ngModel)]="EmployeeBudgetModel.Remarks" maxlength="500" id ="Remarks" name="Remarks" #Remarks="ngModel" required class="form-control form-control-custom form-control-custom-new">
                      </div>
                      <div *ngIf="Remarks.invalid && (Remarks.dirty || Remarks.touched)" class="alert alert-danger">
                        <div *ngIf="Remarks.errors.required">Remarks is required.</div>
                        <div *ngIf="Remarks.errors.maxlength">Remarks is not more than 500 characters</div>
                      </div>
                    </div> 
                    <div class="form-group row">
                      <label *ngIf="!(this.IsDataIsInViewState)" class="col-sm-6 col-form-label text-center clear-padding-right">
                        <button type="submit" class="btn btn-primary save-button" [disabled]="!(EmployeeBudgetUpdateForm.valid && this.DisableSave==true && this.EmployeeBudgetModel.EmployeeId > 0 && this.EmployeeBudgetModel.RefId > 0 && (this.EmployeeBudgetModel.CreditAmount > 0 || this.EmployeeBudgetModel.DebitAmount > 0))" >Save</button>  
                      </label>
                    </div>
                  </div>
                </div>
              </form>
            </fieldset>
          </div>
          <button type="button" aria-label="Close" (click)="modalRef.hide()" style="background: #2a9bd7; outline: 0px none; border: 0px none; color: #fff; padding: 5px 10px; border-radius: 3px;">
            <span>Close</span>
          </button>             
        </div>
      </ng-template>
    </div>
  </div>
</div>