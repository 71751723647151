import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from '../../shared/user.service';
import { EmployeePortalService }  from '../../shared/HR_ADMIN/EmployeePortal/EmployeePortalService.service';
import { EmployeeMasterService }  from '../../shared/HR_ADMIN/Employee/EmployeeMaster.Service';
import { EmployeeDetail } from '../../shared/HR_ADMIN/Employee/EmployeeDetail.Model';
import { TourModel }  from '../../shared/HR_ADMIN/EmployeePortal/TourModel.Model';
import { Menu } from '../../shared/menu.model';
import { ModalOptions,BsModalRef, BsModalService } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-all-employee-tour-status',
  templateUrl: './all-employee-tour-status.component.html',
  styleUrls: ['./all-employee-tour-status.component.css']
})
export class AllEmployeeTourStatusComponent implements OnInit 
{
  TourModel : TourModel= new TourModel();
  ApplicationPending: TourModel[]=[];
  ApplicationApproved: TourModel[]=[];
  ApplicationRejected: TourModel[]=[];
  Application: any;
  userClaims: any;
  Result : string;
  SuccessStatus:boolean=false;
  SuccessMessage: string ="";
  public tableWidget: any;
  selectedIndex : number;
  // Begning Permission Portion    
  SelectedMenu:Menu;
  // End of the permission portion
  Employees: EmployeeDetail[] = [];
  employee: any;
  // Begning Permission Portion    
  IsDataIsInViewState : boolean=false;
  CanViewState : boolean=true;
  IsApplicationView : boolean=false;
  // End of the permission portion
  EmployeeSettings = {};
  selectedItems = [];
  emp : any [] =[]
  //For Pop up
  ModelListApplication: TourModel;
  CancelMsg : boolean= false;
  ShowPopup : boolean= false;
  public modalRef: BsModalRef;
  public modelopt:ModalOptions;
  TempTour : TourModel;
  Approver : any;
  RefId : any;
  //New
  PendingMsg : boolean= false;
  ApprovedMsg : boolean= false;
  RejectedMsg : boolean= false;
  validdate : boolean= false;
  validTodate : boolean= false;
  DisableSave : boolean;

  constructor(private EmployeeMasterService: EmployeeMasterService, private rout: Router, private userService: UserService,private EmployeePortalService: EmployeePortalService, private modalService: BsModalService) 
  {  
    this.ModelListApplication = new TourModel();
    this.modelopt=new ModalOptions();
    this.modelopt.class='heavy_modal';
    this.modalService.config= this.modelopt;
    this.modelopt.ignoreBackdropClick=true;
  }

  ngOnInit() 
  {
    // Begning Permission Portion 
    this.SelectedMenu=this.userService.SelectedMenu;
    if(this.SelectedMenu !=undefined)
    {
      localStorage.setItem('selectedmenu',JSON.stringify(this.SelectedMenu));
      this.rout.navigate([this.SelectedMenu.RouteName]);
    }
    else
    {
      if(JSON.parse(localStorage.getItem('selectedmenu'))!=undefined)
      {
        this.SelectedMenu = JSON.parse(localStorage.getItem('selectedmenu'));
        this.rout.navigate([this.SelectedMenu.RouteName]);
      }
    }
    if(this.SelectedMenu.CanAdd)
    {
      this.IsDataIsInViewState=false;
    }
    else
    {
      this.IsDataIsInViewState=true;
    }
    if(this.SelectedMenu.CanView)
    {
      this.CanViewState=false;
    }
    else
    {
      this.CanViewState=true;
    }
    // End of the permission portion
    if(localStorage.getItem('SuccessfullMessage'))
    {
      this.SuccessStatus=true;
      this.SuccessMessage =localStorage.getItem('SuccessfullMessage')
      localStorage.removeItem('SuccessfullMessage');
    }
    this.GetEmployeeDetailsDDL();
    this.ModelListApplication.EmployeeId=0;
    this.DisableSave=true;
    this.ModelListApplication.Status=0;
    this.validdate=false;
    this.validTodate=false;
    this.EmployeeSettings =
    {
      singleSelection:true,
      text:'Select Employee',
      enableSearchFilter:true,
      showCheckbox:false
    }
  }

  GetEmployeeDetailsDDL() 
  {
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.EmployeeMasterService.GetEmployeeDetailsForDDL( t).then(x => 
    {
      this.employee = x;
      Object.keys(this.employee).forEach( key => 
      {
        let emptemp = new EmployeeDetail();
        emptemp = this.employee[key];
        this.Employees.push(emptemp);
        this.emp.push({"id":emptemp.EmployeeId,"itemName":emptemp.EmployeeCode + '-' +emptemp.EmployeeName},);
      });     
    });      
  }

  onItemSelect(item:any)
  {
    this.DisableSave = true;
    this.PendingMsg = false;
    this.ApprovedMsg = false;
    this.RejectedMsg = false;
    this.ApplicationPending=[];
    this.ApplicationApproved=[];
    this.ApplicationRejected=[];
    let EmployeeId= item['id'];
    if(EmployeeId>0)
    {
      this.ModelListApplication.EmployeeId=EmployeeId;
    }     
  }

  OnItemDeSelect(item:any){ }
  onSelectAll(items: any){ }
  onDeSelectAll(items: any){ }

  GetEmployeeTourStatusPending(EmployeeId,FromDate,ToDate)
  {
    this.ApplicationPending=[];
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.EmployeePortalService.GetTourStatusPendingByEmployeeId( t,EmployeeId,FromDate,ToDate).then(x => 
    {
      this.Application = x;
      Object.keys(this.Application).forEach( key => 
      {
        this.Application[key];
        let Applicationtemp = new TourModel();
        Applicationtemp = this.Application[key];
        this.ApplicationPending.push(Applicationtemp);
      });
      if(this.ApplicationPending.length == 0)
      {
        this.PendingMsg = true;
        this.ApprovedMsg = false;
        this.RejectedMsg = false;
      }
    });
  }

  GetEmployeeTourStatusApproved(EmployeeId,FromDate,ToDate)
  {
    this.ApplicationApproved=[];
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.EmployeePortalService.GetTourStatusApprovedByEmployeeId( t,EmployeeId,FromDate,ToDate).then(x => 
    {
      this.Application = x;
      Object.keys(this.Application).forEach( key => 
      {
        this.Application[key];
        let Applicationtemp = new TourModel();
        Applicationtemp = this.Application[key];
        this.ApplicationApproved.push(Applicationtemp);
      });
      if(this.ApplicationApproved.length == 0)
      {
        this.PendingMsg = false;
        this.ApprovedMsg = true;
        this.RejectedMsg = false;
      }
    });
  }

  GetEmployeeTourStatusRejected(EmployeeId,FromDate,ToDate)
  {
    this.ApplicationRejected=[];
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.EmployeePortalService.GetTourStatusRejectedByEmployeeId( t,EmployeeId,FromDate,ToDate).then(x => 
    {
      this.Application = x;
      Object.keys(this.Application).forEach( key => 
      {
        this.Application[key];
        let Applicationtemp = new TourModel();
        Applicationtemp = this.Application[key];
        this.ApplicationRejected.push(Applicationtemp);
      });
      if(this.ApplicationRejected.length == 0)
      {
        this.PendingMsg = false;
        this.ApprovedMsg = false;
        this.RejectedMsg = true;
      }
    });
  }

  RefreshPage()
  {
    this.rout.navigateByUrl('/Employee/EmployeeOffer', {skipLocationChange: true}).then(()=>this.rout.navigate(["/Employee/AllEmployeeTourStatus"]));
  }

  viewAppliedTour(id,template) 
  {
    this.DisableSave = true;
    this.ModelListApplication.Remarks="";
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.EmployeePortalService.GetEmployeeTourForApprovalById( t,id).then(x => 
    {
      if(this.ShowPopup==true)
      {
        this.modalRef.hide();
      }
      this.modalRef = this.modalService.show(template,this.modelopt);
      this.ShowPopup=true;
      this.CancelMsg=false;
      this.Approver = x;
      Object.keys(this.Approver).forEach( key => 
      {
        // tslint:disable-next-line:no-unused-expression
        this.Approver[key];
        let Approvetemp = new TourModel();
        Approvetemp = this.Approver[key];
        this.ModelListApplication.TourId=Approvetemp.TourId;
        this.ModelListApplication.EmployeeId=Approvetemp.EmployeeId;
        this.ModelListApplication.EmployeeCode=Approvetemp.EmployeeCode;
        this.ModelListApplication.EmployeeName=Approvetemp.EmployeeName; 
        this.ModelListApplication.EmployeeEmailId=Approvetemp.EmployeeEmailId; 
        this.ModelListApplication.RHId=Approvetemp.RHId; 
        this.ModelListApplication.RHName=Approvetemp.RHName; 
        this.ModelListApplication.RHCode=Approvetemp.RHCode; 
        this.ModelListApplication.RHEmailId=Approvetemp.RHEmailId; 
        this.ModelListApplication.FromDate=Approvetemp.FromDate; 
        this.ModelListApplication.ToDate=Approvetemp.ToDate;
        this.ModelListApplication.TourPlace=Approvetemp.TourPlace; 
        this.ModelListApplication.CompanyId=Approvetemp.CompanyId;
      });
      // End for check company code duplication
    });
  }
  
  TourCancel(template) :void
  {
    if(confirm("Are you sure to Cancel "))
    {
      this.DisableSave=false;
      let t =  localStorage.getItem('userToken');
      t = 'Bearer' + t;
      if(this.ModelListApplication.TourId > 0)
      {
        this.ModelListApplication.IsRejected=true;
        this.EmployeePortalService.CancelEmployeeTour(t ,this.ModelListApplication).subscribe(res=>
        {
          this.RefId=res;
          this.ModelListApplication.TourId=0;  
          if(this.RefId>0)
          {
            this.ModelListApplication.Remarks="";
            this.ModelListApplication.RemarksAll="";
            this.modalRef.hide();
            this.CancelMsg=true;
            this.ShowPopup=false;
            this.modalRef = this.modalService.show(template);
            this.GetEmployeeTourStatusPending(this.ModelListApplication.EmployeeId,this.ModelListApplication.SearchFromDate,this.ModelListApplication.SearchToDate);
            this.GetEmployeeTourStatusRejected(this.ModelListApplication.EmployeeId,this.ModelListApplication.SearchFromDate,this.ModelListApplication.SearchToDate);
            this.DisableSave=true;
          }
        });                  
      }
    }
  }

  SearchFromDateChanged( date)
  {
    this.DisableSave = true;
    this.PendingMsg = false;
    this.ApprovedMsg = false;
    this.RejectedMsg = false;
    this.ApplicationPending=[];
    this.ApplicationApproved=[];
    this.ApplicationRejected=[];
    
    this.ModelListApplication.SearchFromDate=date;
    var CurrentDate = new Date();
    var CurrentYear = CurrentDate.getFullYear();
    var currentMonth = CurrentDate.getMonth();
    var CurrentDay = CurrentDate.getDate();
    
    var selecteedDate = new Date(date);
    var selectedYear=selecteedDate.getFullYear();
    var selectedMonth=selecteedDate.getMonth();
    var selectedDay=selecteedDate.getDate();

    if(CurrentYear > selectedYear )
    {
     this.validdate = false;      
    }
    else
    {
      if (currentMonth > selectedMonth)
      {
        this.validdate = false;
      }
      else
      {
        if(CurrentDay >= selectedDay )
        {
          this.validdate = false;
        }
        else
        {
          this.validdate = true;
        }
      }
    }
  }

  SearchToDateChanged( date)
  {
    this.DisableSave = true;
    this.PendingMsg = false;
    this.ApprovedMsg = false;
    this.RejectedMsg = false;
    this.ApplicationPending=[];
    this.ApplicationApproved=[];
    this.ApplicationRejected=[];

    this.ModelListApplication.SearchToDate=date;
    var CurrentDate = new Date();
    var CurrentYear = CurrentDate.getFullYear();
    var currentMonth = CurrentDate.getMonth();
    var CurrentDay = CurrentDate.getDate();
    
    var selecteedDate = new Date(date);
    var selectedYear=selecteedDate.getFullYear();
    var selectedMonth=selecteedDate.getMonth();
    var selectedDay=selecteedDate.getDate();

    if(CurrentYear > selectedYear )
    {
     this.validTodate= false;      
    }
    else
    {
      if (currentMonth > selectedMonth)
      {
        this.validTodate = false;
      }
      else
      {
        if(CurrentDay >= selectedDay )
        {
          this.validTodate = false;
        }
        else
        {
          this.validTodate = true;
        }
      }
    }
  }

  StatusChanged()
  {
    this.DisableSave = true;
    this.PendingMsg = false;
    this.ApprovedMsg = false;
    this.RejectedMsg = false;
    this.ApplicationPending=[];
    this.ApplicationApproved=[];
    this.ApplicationRejected=[];
  }

  submitClick()
  {
    this.DisableSave = false;
    this.validdate = false;
    this.validTodate=false;
    this.PendingMsg = false;
    this.ApprovedMsg = false;
    this.RejectedMsg = false;
    this.ApplicationPending=[];
    this.ApplicationApproved=[];
    this.ApplicationRejected=[];
    if(this.ModelListApplication.SearchFromDate!=null && this.ModelListApplication.SearchToDate!=null && this.ModelListApplication.Status > 0)
    {
      if(this.ModelListApplication.SearchToDate >= this.ModelListApplication.SearchFromDate)
      {
        if(this.ModelListApplication.Status == 1)
        {
          this.GetEmployeeTourStatusPending(this.ModelListApplication.EmployeeId,this.ModelListApplication.SearchFromDate, this.ModelListApplication.SearchToDate);
        }
        else if(this.ModelListApplication.Status == 2)
        {
          this.GetEmployeeTourStatusApproved(this.ModelListApplication.EmployeeId,this.ModelListApplication.SearchFromDate, this.ModelListApplication.SearchToDate);
        }
        else
        {
          this.GetEmployeeTourStatusRejected(this.ModelListApplication.EmployeeId,this.ModelListApplication.SearchFromDate, this.ModelListApplication.SearchToDate);
        }
      }
      else
      {
        this.validTodate = true;
      }
    }
  }
}