import { Component, OnInit, ViewChild } from '@angular/core';
import { CrmOppertunityService } from '../../../../shared/CRM/MICECRM/Oppertunity/CrmOppertunityService';
import { CrmLeadService } from '../../../../shared/CRM/MICECRM/LEADCRM/CrmLeadService';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { ModalOptions,BsModalRef, BsModalService  } from 'ngx-bootstrap/modal'
import { Model_CRM_Lead } from '../../../../shared/CRM/MICECRM/LEADCRM/Model_CRM_LEAD';
import { ChangableDatePicker } from '../../../../../../src/app/for-all/myDatePickerChangable';
import { DatePicker } from '../../../../../../src/app/for-all/myDatepicker';
import { Molel_CRM_Opportunity_List } from '../../../../shared/CRM/MICECRM/Oppertunity/Molel_CRM_Opportunity_List';
import { Model_CRM_ScheduleCall } from '../../../../../app/shared/CRM/MICECRM/LEADCRM/Model_CRM_ScheduleCall'
import { Model_CRM_ScheduleCall_Documents } from '../../../../../app/shared/CRM/MICECRM/LEADCRM/Model_CRM_ScheduleCall_Documents'

@Component({
  selector: 'app-view-schedule-call',
  templateUrl: './view-schedule-call.component.html',
  styleUrls: ['./view-schedule-call.component.css']
})
export class ViewScheduleCallComponent implements OnInit {

  @ViewChild(ChangableDatePicker, {static: false}) private myDatePickerChangable: ChangableDatePicker;
  @ViewChild(DatePicker, {static: false}) private myDatePicker: DatePicker;

  // Begnig of the from-To date
  FromDateVal: string = '';
  FromDate: Date;
  ToDateval: string = '';
  ToDate: Date;
  ToDateSelct: Date;
  // End of the from-To Date  
  //for model 
  public modalRef: BsModalRef;
  public modelopt: ModalOptions;

  CRM_ScheduleCall: Model_CRM_ScheduleCall;

  //Begning of Company list
  CompanySettings = {}
  CompanyArray: any[] = [];

  Companylist: Model_CRM_Lead[] = [];
  SelectedCompany: any[] = [];
  CompanyName: any;
  companylist: Model_CRM_Lead[] = [];
  //End of Company List
  //Begning of Selected opportunity
  OpportunitySettings = {}
  OpportunityArray: any[] = [];
  OpportunityName: any;
  OpportunityList: Molel_CRM_Opportunity_List[] = [];
  Selectedopportunities: any[] = [];
  SelectedOpportunitiesId: number;
  //End of Selected Opportunity
  ContactName: any;
  ModelScheduleCall: Model_CRM_ScheduleCall[] = [];
  //  View  Field Decleration Starts
  @ViewChild(DataTableDirective, {static: false})
  dtElement: DataTableDirective;
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();
  //  View  Field Decleration Starts
  ListScheduleCallObj: any;
  View_ScheduleCall: Model_CRM_ScheduleCall;
  // begin Vesibl Button

  ViewButtonVisibility: boolean = false;

  //End Vesible Button


  constructor(private CRM_OppertunityService: CrmOppertunityService,
    private LeadSrevice: CrmLeadService,
    private modalService: BsModalService) { }

  ngOnInit() {

    this.FromDateVal = '';
    this.ToDateval = '';

    this.ViewButtonVisibility = false;

    this.modelopt = new ModalOptions();
    this.modelopt.class = 'heavy_modal_big';
    this.modalService.config = this.modelopt;

    this.CompanySettings = {
      singleSelection: true,
      text: 'Select Company',
      enableSearchFilter: true,
      showCheckbox: false
    }

    this.OpportunitySettings = {

      singleSelection: true,
      text: 'Select Opportunity',
      enableSearchFilter: true,
      showCheckbox: false
    }

    this.CRM_ScheduleCall = new Model_CRM_ScheduleCall();

    if (this.CRM_ScheduleCall.SheduleCall_Id == undefined) {
      this.CRM_ScheduleCall.SheduleCall_Id = 0;
      this.CRM_ScheduleCall.Company_Id = 0;
      this.CRM_ScheduleCall.IsComplete = false;
      this.CRM_ScheduleCall.OpportunityId = 0;
    }


    this.GetCrmCompany();

  }




  ngAfterViewInit(): void {

    this.dtOptions =
      {
        pagingType: 'full_numbers',
        pageLength: 3
      };

  }

  RefreshPage(): void {

    this.SelectedCompany = [];
    this.SelectedCompany.push({ "id": 0, "itemName": "Select Company" });


    this.myDatePicker.refreshDatePicker();
    this.myDatePickerChangable.refreshDatePicker();

  }

  GetCrmCompany() {

    let t = localStorage.getItem('userToken');
    t = 'Bearer' + t
    this.companylist = [];
    this.CompanyArray = [];
    this.CRM_OppertunityService.Get_CrmCompany(t).then(x => {
      this.CompanyName = x;
      Object.keys(this.CompanyName).forEach(key => {
        let CompanyNametemp = new Model_CRM_Lead();
        CompanyNametemp = this.CompanyName[key];
        this.companylist.push(CompanyNametemp);
        this.CompanyArray.push({ "id": CompanyNametemp.LeadId, "itemName": CompanyNametemp.LeadName });
      });

    })
    //   .then(y => {

    //   if (this.CRM_OppertunityService.CrmCommonUseOportunityDetails.CompanyId > 0) {
    //     let OpportunityCompany = this.companylist.find(x => x.LeadId == this.CRM_OppertunityService.CrmCommonUseOportunityDetails.CompanyId);
    //     this.SelectedCompany = [];
    //     this.SelectedCompany.push({ "id": OpportunityCompany.LeadId, "itemName": OpportunityCompany.LeadName });
    //     this.GetLeadContact();
    //   }
    // });
  }


  onItemSelectCompany(item: any) {

    let CompanyId = item['id'];
    this.CRM_ScheduleCall.Company_Id = CompanyId;

    this.CRM_ScheduleCall.OpportunityId = 0;;

    if (this.CRM_ScheduleCall.Company_Id > 0) {


      if (((this.FromDateVal == '') && (this.ToDateval == '')) || ((this.FromDateVal != '') && (this.ToDateval != ''))) {
        this.ViewButtonVisibility = true;

      }
      else {

        this.ViewButtonVisibility = false;

      }


      this.GetOpportunityList();

    }
  }
  OnItemDeSelectCompany(item: any) {
    this.CRM_ScheduleCall.Company_Id = 0;
    this.CRM_ScheduleCall.OpportunityId = 0;
  }
  onSelectAllCompany(items: any) { } l
  onDeSelectAllCompany(items: any) { }


  onItemSelectOpportunity(item: any) {

    let OpportunityId = item['id'];
    this.CRM_ScheduleCall.OpportunityId = OpportunityId;

  }
  OnItemDeSelectOpportunity(item: any) {
    this.CRM_ScheduleCall.OpportunityId = 0;

  }
  onSelectAllOpportunity(items: any) { }
  onDeSelectAllOpportunity(items: any) { }


  GetOpportunityList() {
    let t = localStorage.getItem('userToken');
    t = 'Bearer' + t
    this.OpportunityList = [];
    this.OpportunityArray = [];

    this.CRM_OppertunityService.Get_CRMOppertunityList(t, this.CRM_ScheduleCall.Company_Id).then(x => {
      this.OpportunityName = x;
      Object.keys(this.OpportunityName).forEach(key => {
        let OpportunityTemp = new Molel_CRM_Opportunity_List();
        OpportunityTemp = this.OpportunityName[key];
        this.OpportunityList.push(OpportunityTemp);
        this.OpportunityArray.push({ "id": OpportunityTemp.OpportunityId, "itemName": OpportunityTemp.OpportunityName });
      });
    });
  }


  ViewScheduleCall() {

    if (this.ViewButtonVisibility) {

      $('#tblDocument').DataTable().destroy();

      let t = localStorage.getItem('userToken');
      t = 'Bearer' + t
      this.ModelScheduleCall = [];


      this.LeadSrevice.GetSchedulecall(t, this.CRM_ScheduleCall.Company_Id, this.CRM_ScheduleCall.OpportunityId, this.FromDateVal, this.ToDateval).then(x => {
        this.ListScheduleCallObj = x;
        Object.keys(this.ListScheduleCallObj).forEach(key => {
          let ScheduleCalltemp = new Model_CRM_ScheduleCall();
          ScheduleCalltemp.Invited_Attendees = [];
          ScheduleCalltemp.Document = new Model_CRM_ScheduleCall_Documents();

          ScheduleCalltemp = this.ListScheduleCallObj[key];

          this.ModelScheduleCall.push(ScheduleCalltemp);

        });


      }).then(x => {
        this.dtTrigger.next();
      })

    }
  }


  DocDownLoad(DocumentName, type): void {


    let t = localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.LeadSrevice.getScheduleCallDocument(t, DocumentName).subscribe(respData => {
      this.downLoadFile(respData, type);
    }, error => {

    });

  }


  /**
     * Method is use to download file from server.
     * @param data - Array Buffer data
     * @param type - type of the document.
     */
  downLoadFile(data: any, type: string) {
    var blob = new Blob([data], { type: type.toString() });
    var url = window.URL.createObjectURL(blob);
    var pwa = window.open(url);
    if (!pwa || pwa.closed || typeof pwa.closed == 'undefined') {
      alert('Please disable your Pop-up blocker and try again.');
    }
  }


  ViewCRMScheduleCall(shecdulecallId: number, template) {

    this.View_ScheduleCall = new Model_CRM_ScheduleCall();
    this.View_ScheduleCall.Invited_Attendees = []
    this.View_ScheduleCall.ContactsName = []
    this.modelopt = new ModalOptions();
    this.modelopt.class = 'heavy_modal';
    this.modalService.config = this.modelopt;
    this.View_ScheduleCall = this.ModelScheduleCall.find(x => x.SheduleCall_Id == shecdulecallId);

    this.modalRef = this.modalService.show(template, this.modelopt);
    //this.GridShow=true;
    // this.ShowPopup = true;
    //  this.ApprovedMsg=false;
    //  this.RejectedMsg=false;
    // Begning of the permission portion
    // this.IsDataIsInViewState = false;
    // // End of the permision portion
    // this.ApprovedMsg = true;



  }


  CreateDate(datecreate) {

    this.myDatePickerChangable.change(datecreate)

    var dateParts = datecreate.split("/");
    this.FromDate = new Date(+dateParts[2], dateParts[1], +dateParts[0]);
    this.FromDateVal = datecreate;
    this.ToDate = new Date();
    this.ToDateval = '';
    this.ViewButtonVisibility = false;

  }


  Closedate(DateClose) {

    var dateParts = DateClose.split("/");
    this.ToDate = new Date(+dateParts[2], dateParts[1], +dateParts[0]);
    this.ToDateval = DateClose;

    this.ViewButtonVisibility = true;

  }




}
