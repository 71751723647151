import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from '../../shared/user.service';
import { Menu } from '../../shared/menu.model';
import { ResignApplicationModel } from '../../shared/HR_ADMIN/EmployeePortal/ResignApplicationModel.Model';
import { EmployeePortalService }  from '../../shared/HR_ADMIN/EmployeePortal/EmployeePortalService.service';
import { ModalOptions,BsModalRef, BsModalService } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-exit-application',
  templateUrl: './exit-application.component.html',
  styleUrls: ['./exit-application.component.css']
})
export class ExitApplicationComponent implements OnInit 
{
  ResignApplicationModel : ResignApplicationModel= new ResignApplicationModel();
  Application: any;
  SelectedMenu:Menu;
  SuccessStatus:boolean=false;
  SuccessMessage: string ="";
  DisableSave : boolean=false;
  validExitdate : boolean= false;
  constructor(private EmployeePortalService: EmployeePortalService,private rout: Router,private userService: UserService) { }

  ngOnInit() 
  {
    // Begning Permission Portion 
    this.SelectedMenu=this.userService.SelectedMenu;
    if(this.SelectedMenu !=undefined)
    {
      localStorage.setItem('selectedmenu',JSON.stringify(this.SelectedMenu));
      this.rout.navigate([this.SelectedMenu.RouteName]);
    }
    else
    {
      if(JSON.parse(localStorage.getItem('selectedmenu'))!=undefined)
      {
        this.SelectedMenu = JSON.parse(localStorage.getItem('selectedmenu'));
        this.rout.navigate([this.SelectedMenu.RouteName]);
      }
    }
    // End of the permission portion
    if(localStorage.getItem('SuccessfullMessage'))
    {
      this.SuccessStatus=true;
      this.SuccessMessage =localStorage.getItem('SuccessfullMessage')
      localStorage.removeItem('SuccessfullMessage');
    }
    this.GetEmployeeDetails();
    this.DisableSave=true;
    this.validExitdate=false;
  }
  
  GetEmployeeDetails()
  {
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.EmployeePortalService.GetEmployeeDetailsForResignation( t).then(x => 
    {
      this.Application = x;
      this.ResignApplicationModel.EmployeeId=this.Application.EmployeeId;
      this.ResignApplicationModel.EmployeeCode=this.Application.EmployeeCode;
      this.ResignApplicationModel.EmployeeName=this.Application.EmployeeName;
      this.ResignApplicationModel.EmployeeEmailId=this.Application.EmployeeEmailId;
      this.ResignApplicationModel.RHId=this.Application.RHId;
      this.ResignApplicationModel.RHName=this.Application.RHName;
      this.ResignApplicationModel.RHEmailId=this.Application.RHEmailId;
      this.ResignApplicationModel.FHId=this.Application.FHId;
      this.ResignApplicationModel.FHName=this.Application.FHName;
      this.ResignApplicationModel.FHEmailId=this.Application.FHEmailId;
      this.ResignApplicationModel.CompanyId=this.Application.CompanyId;
      this.ResignApplicationModel.HRIds=this.Application.HRIds;
      this.ResignApplicationModel.HRNames=this.Application.HRNames;
      this.ResignApplicationModel.HREmailIds=this.Application.HREmailIds;
      this.ResignApplicationModel.JoiningDate=this.Application.JoiningDate;
      this.ResignApplicationModel.NoticePeriod=this.Application.NoticePeriod;
      this.ResignApplicationModel.ApplicationDate=this.Application.ApplicationDate;
      this.ResignApplicationModel.ResignNoticeDate=this.Application.ResignNoticeDate;
      this.ResignApplicationModel.ApplicationExist=this.Application.ApplicationExist;
      this.ResignApplicationModel.RequestNoticePeriod=this.Application.NoticePeriod;
      this.ResignApplicationModel.SentMailid=this.Application.SentMailid;
      this.ResignApplicationModel.SentMailPassword=this.Application.SentMailPassword;
      this.ResignApplicationModel.HRISURL=this.Application.HRISURL;
    });
  }

  SaveResignApplication() :void
  {
    this.DisableSave=false;
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
     this.EmployeePortalService.ResignApplicationApply(t ,this.ResignApplicationModel).subscribe(res=>
    {
      this.Application=res;
      this.ResignApplicationModel.RefId=0;              
      if(this.Application>0)
      {
        localStorage.setItem('SuccessfullMessage', "Application Forward to HR Successfully");
        this.rout.navigateByUrl('/EmployeePortal/PersonalInformation', {skipLocationChange: true}).then(()=>this.rout.navigate(["/EmployeePortal/ExitApplication"]));
      }
    }); 
  }

  RefreshPage()
  {
    this.rout.navigateByUrl('/EmployeePortal/PersonalInformation', {skipLocationChange: true}).then(()=>this.rout.navigate(["/EmployeePortal/ExitApplication"]));
  }

  ResignNoticeDateChanged( date)
  {
    if(this.ResignApplicationModel.ApplicationDate >= date)
    {
      this.validExitdate=true;
    }
    else
    {
      this.validExitdate=false;

      var date1 = new Date(this.ResignApplicationModel.ApplicationDate); 
      var date2 = new Date(date); 

      var diff = Math.abs(date1.getTime() - date2.getTime());
      var diffDays = Math.ceil(diff / (1000 * 3600 * 24));
      this.ResignApplicationModel.RequestNoticePeriod=diffDays;
    }
  }
}
