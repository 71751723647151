export class LeaveRule 
{
    public LeaveRuleId : Number;
    public CompanyId : Number;
    public BranchId : Number;
    public Year : Number;
    public LeaveTypeId : Number;
    public NoOfLeave : Number;
    public IsMonthly : boolean;
    public IsYearly : boolean;
    public IsAllTime : boolean;
    public IsActive : boolean;
    public value : string;
    public  IsAfterConfirmation :boolean;
    public  IsTransferable : boolean;
    public  AvaleafterNoMonths :Number;
    public IsConfirm : string;
    public Istransfirm : string;

}