import { Component, OnInit} from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from '../../shared/user.service';
import { EmployeeMasterService }  from '../../shared/HR_ADMIN/Employee/EmployeeMaster.Service';
import { EmployeeDetail } from '../../shared/HR_ADMIN/Employee/EmployeeDetail.Model';
import { Menu } from '../../shared/menu.model';

@Component({
  selector: 'app-resign-employee-personal-details',
  templateUrl: './resign-employee-personal-details.component.html',
  styleUrls: ['./resign-employee-personal-details.component.css']
})
export class ResignEmployeePersonalDetailsComponent implements OnInit 
{
  EmployeeModel : EmployeeDetail= new EmployeeDetail();
  userClaims: any;
  Employees: EmployeeDetail[] = [];
  employee: any;
  SubMenues : Menu[]=[];
  Mod : any;
  Result : string;
  SuccessStatus:boolean=false;
  SuccessMessage: string ="";
  public tableWidget: any;
  EmployeeId : any;
  selectedIndex : number;
  // Begning Permission Portion    
  SelectedMenu:Menu;
  IsDataIsInViewState : boolean=false;
  IsButtonIsInViewState : boolean=false;
  IsEmployeeInViewState : boolean=false;
  IsjoiningInViewState:boolean=true;
  // End of the permission portion
  // begin for Validation
  validdate : boolean= false;
  // end of validation
  employeeEducationUndefine : string ="";
  employeeExpriencUndefine : string ="";
  employeeNomineeDetailsUndefine : string ="";
  employeeFamilyDetailsUndefine : string ="";
  EmployeeSettings = {};
  selectedItems = [];
  emp : any [] =[]
  NoRecordMsg : boolean;
  constructor(public EmployeeMasterService: EmployeeMasterService, private rout: Router, private userService: UserService) 
  { 
  }

  ngOnInit() 
  {
    // Begning Permission Portion 
    this.SubMenues=[];
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    let menuid = localStorage.getItem("menuidstor");
    this.userService.GetSubMenuList( t, menuid).then(x => 
    {
      this.Mod = x;
      Object.keys(this.Mod).forEach( key => 
      {
        // tslint:disable-next-line:no-unused-expression
        this.Mod[key];
        let menutemp = new Menu();
        menutemp = this.Mod[key];
        this.SubMenues.push(menutemp);
      });
      this.userService.SelectedMenu= this.SubMenues[0];
      this.rout.navigate([this.userService.SelectedMenu.RouteName]);
      this.SelectedMenu=this.userService.SelectedMenu;
      if(this.SelectedMenu.CanAdd)
      {
        this.IsDataIsInViewState=false;
      }
      else
      {
        this.IsDataIsInViewState=true;
      }
    });
    // End of the permission portion
    this.GetEmployeeDetailsDDL();
    this.NoRecordMsg= false;
    this.EmployeeMasterService.SharedEmployees.EmployeeId=0
    this.employeeEducationUndefine ='notdefinedval';
    this.employeeExpriencUndefine ='notdefinedval';
    this.employeeNomineeDetailsUndefine ='notdefinedval';
    this.employeeFamilyDetailsUndefine='notdefinedval';
    this.EmployeeSettings =
    {
      singleSelection:true,
      text:'Select Employee',
      enableSearchFilter:true,
      showCheckbox:false
    }
  }

  onItemSelect(item:any)
  {
    let empid= item['id'];
    this.EmployeeMasterService.SharedEmployees=empid ;  
    this.NoRecordMsg= false;  
    this. GetEmpDetailsForView(empid)
  }
  OnItemDeSelect(item:any){ }
  onSelectAll(items: any){ }
  onDeSelectAll(items: any){ }
  
  GetEmployeeDetailsDDL() 
  {
    this.Employees=[];
    this.emp=[];
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.EmployeeMasterService.GetResignEmployeeDetailsForDDL( t).then(x => 
    {
      this.employee = x;
      Object.keys(this.employee).forEach( key => 
      {
        let RHtemp = new EmployeeDetail();
        RHtemp = this.employee[key];
        this.Employees.push(RHtemp);
        this.emp.push({"id":RHtemp.EmployeeId,"itemName":RHtemp.EmployeeCode + '-' +RHtemp.EmployeeName},);
      });     
    });      
  }

  GetEmpDetailsForView(EmployeeId)
  {
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.EmployeeMasterService.GetResignEmployeeDetailsForView( t,EmployeeId).then(x => 
    {
      this.employee = x;
      Object.keys(this.employee).forEach( key => 
      {
        this.employee[key];
        let employeetemp = new EmployeeDetail();
        employeetemp = this.employee[key];
        this.EmployeeMasterService.SharedEmployees=this.employee;
        if( this.EmployeeMasterService.SharedEmployees.EmployeeEducation.length == 0)
        {
          this.employeeEducationUndefine ='notdefinedval';
        }
        if( this.EmployeeMasterService.SharedEmployees.EmployeeExprienceModel.length == 0)
        {
          this.employeeExpriencUndefine ='notdefinedval';
        }
        if(this.EmployeeMasterService.SharedEmployees.EmployeeNomineeDetailModel.length==0)
        {
          this.employeeNomineeDetailsUndefine ='notdefinedval';
        }
        if( this.EmployeeMasterService.SharedEmployees.EmployeeFamilyDetailsModel.length ==0)
        {
          this.employeeFamilyDetailsUndefine='notdefinedval';
        }
      });
    });
  }
}
