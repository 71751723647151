<!-- <perfect-scrollbar style="max-width: 1260px; max-height: 540px;" class="mukesh"> -->
<div>
  <fieldset class="body-form-control" style="    border: none;">
    <app-dialog [(visible)]="this.SuccessStatus">
      <span class="successmsg">{{this.SuccessMessage}}</span>
    </app-dialog>



    <legend class="legend--">More Lead Info</legend>
    <form #LeadName="ngForm">


      <div class="row">
        <div class="col-lg-4">
          <div class="col-body-border-right">
            <div class="form-group row">
              <label class="col-sm-4 col-form-label clear-padding-right"> Birth Date </label>

              <div class="col-sm-8 clear-left-padding">
                <!-- <input type="date" name="DOBDate" tabindex="9"
                    [ngModel]="this.CRM_LeadService.model_CRM_Shared_lead.DOB | date:'yyyy-MM-dd'"
                    (ngModelChange)="DateOfBirth($event)" #DOBDate="ngModel"> -->
                <input type="text" [(value)]="DOBMoreinfoM" readonly="readonly" [minimumDate]="false"
                  [onlyDatePicker]="true" id="startDate_id" myDatepicker (dateChange)="DateOfBirth($event)" tabindex="1"
                  class="form-control form-control-custom form-control-custom-new ng-pristine ng-valid ng-touched" />


              </div>
            </div>

            <div class="form-group row">
              <label class="col-sm-4 col-form-label clear-padding-right">Segment
                <span class="required-validation" *ngIf="SelectedSegments==0">*</span>
              </label>
              <div class="col-sm-8 clear-left-padding">


                <angular2-multiselect tabindex="4" class="form-control" [data]="SegmentArray"
                  [(ngModel)]="SelectedSegment" name="SegmentId" [settings]="SegmentSettings"
                  (onSelect)="onItemSelecSegment($event)" (onDeSelect)="OnItemDeSelectSegment($event)"
                  (onSelectAll)="onSelectAllSegment($event)" (onDeSelectAll)="onDeSelectAllSegment($event)">
                </angular2-multiselect>

              </div>
            </div>









          </div>
        </div>

        <div class="col-lg-4">
          <div class="col-body-border-right">

            <div class="form-group row">
              <label class="col-sm-4 col-form-label clear-padding-right"> Anniversary Date</label>
              <div class="col-sm-8 clear-left-padding">



                <input type="text" [(value)]="AnniversaryDateMoreinfoM" readonly="readonly" [minimumDate]="false"
                  [onlyDatePicker]="true" id="AniversaryDate_id" myDatepicker (dateChange)="AniversaryDate($event)"
                  tabindex="2"
                  class="form-control form-control-custom form-control-custom-new ng-pristine ng-valid ng-touched" />


              </div>
            </div>

            <div class="form-group row">
              <!-- <label class="col-sm-5 col-form-label clear-padding-right"> Anniversary Date</label>
              <div class="col-sm-7 clear-left-padding">
                <input type="date" name="AnniverSaryDate" tabindex="9" [ngModel]="this.CRM_LeadService.model_CRM_Shared_lead.AnniversaryDate | date:'yyyy-MM-dd'" (ngModelChange)="AniversaryDate($event)"
                  #AnniverSaryDate="ngModel" >
              </div> -->

              <div class="col-sm-7 clear-left-padding">

                <button type="button" (click)="viewAppliedForNote(template)" class="btn btn-primary add_new_btn"
                  tabindex="5">
                  <span>Add Notes</span>
                </button>

              </div>
            </div>


          </div>
        </div>




        <div class="col-lg-4">

          <!-- <div class="form-group row">
              <label class="col-sm-4 col-form-label clear-padding-right">Designation
                <span class="required-validation" *ngIf="this.CRM_LeadService.model_CRM_Shared_lead.StateId==0">*</span>
              </label>
              <div class="col-sm-8 clear-left-padding">



                <angular2-multiselect tabindex="3" class="form-control" [data]="DesignationArray"
                  [(ngModel)]="SelectedDesignation" name="DesignationId" [settings]="DesignationSettings"
                  (onSelect)="onItemSelecDesignation($event)" (onDeSelect)="OnItemDeSelectDesignation($event)"
                  (onSelectAll)="onSelectAllDesignation($event)" (onDeSelectAll)="onDeSelectAlldesignation($event)">
                </angular2-multiselect>

              </div>
            </div> -->




          <div class="form-group row">

            <label class="col-sm-6 col-form-label text-center clear-padding-right"
              *ngIf="!IsInViewState">
              <button type="button" tabindex="6" required class="btn btn-primary save-button"
                (click)="saveCRMMoreInformation()">
                Save Next</button>
            </label>

            <div class="col-sm-6 col-form-label text-center">
              <button type="button" tabindex="7" (click)="RefreshPage()"
                class="btn btn-danger cancle-button">Cancel</button>
            </div>
          </div>
        </div>
      </div>
    </form>
  </fieldset>


</div>
<!-- </perfect-scrollbar> -->

<div style=" box-align: center; width: 1000px;">


  <ng-template #template>
    <!-- The Modal -->
    <div class="modal" id="myModal">
      <div class="modal-dialog">
        <div class="modal-content">

          <!-- Modal Header -->
          <div class="modal-header">
            <h4 class="modal-title">Modal Heading</h4>
            <button type="button" class="close" data-dismiss="modal">&times;</button>
          </div>

          <!-- Modal body -->
          <div class="modal-body">
            Modal body..
          </div>

          <!-- Modal footer -->
          <div class="modal-footer">
            <button type="button" class="btn btn-danger" data-dismiss="modal">Close</button>
          </div>

        </div>
      </div>
    </div>

    <div class="modal-header" style="background: #2a9bd7; max-width: 1000px;">

      <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()" style="color:#fff;">
        <span aria-hidden="true">X</span>
      </button>
    </div>
    <div style="text-align: center; max-width: 900px;padding-left: 10%">



      <fieldset class="body-form-control">

        <legend class="legend--">Add / Update Notes</legend>
        <form #ApprovedApplicationForm="ngForm">
          <div class="row">

            <div class="col-lg-12">
              <div class="form-group row">

                <label class="col-sm-2 col-form-label clear-padding-right">Note</label>

                <div class="col-sm-10 clear-left-padding">
                  <!-- <input type="text" [(ngModel)]="this.CRMLEADNOTE.LeadNote" tabindex="1" maxlength="500" id ="idNotee" name="Note" #idNote="ngModel" required class="form-control form-control-custom form-control-custom-new" > -->

                  <textarea id="some-value" style="width:100%; height:100px;border-radius: 3px;
    resize: none; border:1px solid #c3c3c3;"
                    (change)="doTextareaValueChange($event)">{{this.CRMLEADNOTE.LeadNote}}</textarea>
                </div>
              </div>
              <div class="form-group row">
                <div class="col-sm-12 text-right">
                  <button type="button" aria-label="Close"
                    *ngIf="((this.CRMLEADNOTE.LeadNote.length > 0)&&(IsInViewState==false))"
                    (click)="saveNote(templateAlert)"
                    style="float:right;background: #2a9bd7; outline: 0px none; border: 0px none; color: #fff; padding: 5px 10px; border-radius: 3px;">
                    <span>Save</span>
                  </button>
                </div>

              </div>

            </div>
          </div>
        </form>
      </fieldset>
    </div>



  </ng-template>
</div>

<div style="box-align: center; width: 300px;">
  <ng-template #templateAlert>
    <div class="modal-header" style="background: #2a9bd7; max-width: 300px;">
      <div style="color: #dc3545; text-align: center;font-size: 15px; font-weight: bold;">Note Added Successfully</div>
      <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()" style="color:#fff;">
        <span aria-hidden="true">OK</span>
      </button>
    </div>
  </ng-template>
</div>