<div class="body-content-area tabcontent" id="link1">
  <div class="row">
    <div class="col-lg-9">
      <div class="row">
        <div class="col-lg-1 low-padding text-center">
          <!-- <img *ngIf="this.SelectedMenu.Image!=null" src={{this.SelectedMenu.Image}} class="body-icon" alt="Icon"/> -->
        </div>
        <div class="col-lg-10 clear-padding">
          <div>
            <span class="page-title">Employee KRA Functional Head Approval</span>
            <small class="page-description"></small>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="!(this.CanViewState)">
    <fieldset class="body-form-control">
      <legend class="legend--">KRA Details</legend> 
      
      <div *ngIf="NoRecordMsg" style="color: #dc3545; text-align: center;font-size: 15px; font-weight: bold;">No Record Found for Approval.</div>

      <table id="tblApplication" *ngIf="(this.EmployeeDetails.length > 0)" class="table table-striped table-bordered text-center table-new row-border hover">
        <thead class="thead-blue text-center">
          <tr>
            <th>Sl No.</th>
            <th>Employee Code</th>
            <th class="text-left">Name</th>
            <th class="text-left">Reporting Head</th>
            <th class="text-left">Functional Head</th>
            <th class="text-left">Financial Year</th>
            <th class="text-left">Session</th>
            <th class="text-left">Submission Date</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let appl of EmployeeDetails, let i = index">
            <td scope="row">{{i+1}}</td>
            <td>{{appl.EmployeeCode}}</td>
            <td class="text-left">{{appl.EmployeeName}}</td>
            <td class="text-left">{{appl.RHCode}}-{{appl.RHName}}</td>
            <td class="text-left">{{appl.FHCode}}-{{appl.FHName}}</td>
            <td class="text-center">{{appl.FinYear}}</td>
            <td class="text-center">{{appl.KRASession}}</td>
            <td class="text-left">{{appl.SubmitDate | date : "dd-MMM-yyyy"}}</td>
            <td>
              <button *ngIf="this.SelectedMenu.CanView" type="button" (click)="GetKRAApplication(appl.EmployeeId,appl.MasterRefId,template)" class="table-view-button" type="button" ><i class="fas fa-eye"></i></button>
            </td>
          </tr>
        </tbody>
        <tfoot class="thead-blue text-center">
          <tr>
            <th></th>
            <th></th>
            <th></th>
            <th></th>
            <th></th>
            <th></th>
            <th></th>
            <th></th>
            <th></th>
          </tr>
        </tfoot>
      </table>
    </fieldset>

    <div style=" box-align: center; width: 1500px;" >
      <ng-template #template>
        <div class="modal-header" style="background: #2a9bd7; max-width: 1500px;"> 
          <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()" style="color:#fff;">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div style="text-align: center; max-width: 1500px;">
          <div *ngIf="ApprovedMsg" style="color: #dc3545; text-align: center;font-size: 15px; font-weight: bold;">KRA Submitted & Closed Successfully</div>
          <div style=" width: 100%;" *ngIf="ShowPopup">
            <fieldset class="body-form-control" *ngIf="KRAApplications.length > 0" style=" text-align: left;"> 
              <legend class="legend--">KRA Details</legend>
              <form #KRADetailForm="ngForm">
                <div class="row">
                  <div class="col-lg-4">
                    <div class="col-body-border-right">
                      <div class="form-group row">
                        <label class="col-sm-4 col-form-label clear-padding-right">Code</label>
                        <div class="col-sm-7 clear-left-padding">
                          :&nbsp;&nbsp;{{this.KRAApplicationModel.EmployeeCode}}
                        </div>
                      </div>
                      <div class="form-group row">
                        <label class="col-sm-4 col-form-label clear-padding-right">RH</label>
                        <div class="col-sm-7 clear-left-padding">
                          :&nbsp;&nbsp;{{this.KRAApplicationModel.RHName}}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-4">
                    <div class="col-body-border-right">
                      <div class="form-group row">
                        <label class="col-sm-4 col-form-label clear-padding-right">Name</label>
                        <div class="col-sm-7 clear-left-padding">
                          :&nbsp;&nbsp;{{this.KRAApplicationModel.EmployeeName}}
                        </div>
                      </div>
                      <div class="form-group row">
                        <label class="col-sm-4 col-form-label clear-padding-right">FH</label>
                        <div class="col-sm-7 clear-left-padding">
                          :&nbsp;&nbsp;{{this.KRAApplicationModel.FHName}}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-4">
                    <div class="form-group row">
                      <label class="col-sm-4 col-form-label clear-padding-right">Designation</label>
                      <div class="col-sm-7 clear-left-padding">
                        :&nbsp;&nbsp;{{this.KRAApplicationModel.DesignationName}}
                      </div>
                    </div>
                  </div>
                </div>
      
                <div lass="row">
                  <table id="tblkra" class="table table-striped table-bordered text-left table-new row-border hover" style="width: 100%;">
                    <thead class="thead-blue text-center" style="width: 100%;">
                      <tr>
                        <th style="width: 20%; text-align: left;">Question</th>
                        <th style="width: 5%; text-align: center;">Weighted(%)</th>  
                        <th style="width: 20%; text-align: left;">Rating Guideline</th>
                        <th style="width: 10%; text-align: left;">Achievement</th>
                        <th style="width: 5%; text-align: left;">Self Rating</th>
                        <th style="width: 10%; text-align: left;">Self Remarks</th>
                        <th style="width: 5%; text-align: left;" *ngIf="this.KRAApplicationModel.RHCode != this.KRAApplicationModel.FHCode">RH Rating</th>
                        <th style="width: 10%; text-align: left;" *ngIf="this.KRAApplicationModel.RHCode != this.KRAApplicationModel.FHCode">RH Remarks</th>
                        <th style="width: 5%; text-align: left;">FH Rating</th>
                        <th style="width: 10%; text-align: left;">FH Remarks</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let kra of KRAApplications, let i = index">
                        <td>
                          {{kra.KRAName}}
                          <input type="text" hidden="hidden" [(ngModel)]="kra.KRAId" name="KRAId_{{i}}" #KRAId="ngModel">
                          <input type="text" hidden="hidden" [(ngModel)]="kra.RefId" name="RefId_{{i}}" #RefId="ngModel">
                          <input type="text" hidden="hidden" [(ngModel)]="kra.KRAMappingId" name="KRAMappingId_{{i}}" #KRAMappingId="ngModel">
                        </td>
                        <td style="text-align: center;">{{kra.Weighted}}</td>
                        <td>{{kra.RatingCriteria}}</td> 
                        <td>{{kra.Achievement}}</td> 
                        <td>{{kra.SelfRating}}</td>
                        <td>{{kra.SelfRemarks}}</td>
                        <td *ngIf="this.KRAApplicationModel.RHCode != this.KRAApplicationModel.FHCode">{{kra.RHRating}}</td>
                        <td *ngIf="this.KRAApplicationModel.RHCode != this.KRAApplicationModel.FHCode">{{kra.RHRemarks}}</td>
                        <td>
                          <input type="text" [(ngModel)]="kra.FHRating" name="FHRating_{{i}}" #FHRating="ngModel" maxlength="1" pattern="[1-5]+" (ngModelChange)="FHRatingChanged()" class="form-control form-control-custom form-control-custom-new" required>
                          <div *ngIf="FHRating.invalid && (FHRating.dirty || FHRating.touched)" class="alert alert-danger">
                            <div *ngIf="FHRating.errors?.pattern">Rating should be valid.</div>
                            <div *ngIf="FHRating.errors.maxlength">Rating is not more than 1 digit.</div>
                          </div>
                        </td> 
                        <td>
                          <input type="text" [(ngModel)]="kra.FHRemarks" name="FHRemarks_{{i}}" #FHRemarks="ngModel" minlength="1" class="form-control form-control-custom form-control-custom-new" required>
                          <div *ngIf="FHRemarks.invalid && (FHRemarks.dirty || FHRemarks.touched)" class="alert alert-danger">
                            <div *ngIf="FHRemarks.errors.minlength">Please fill Remarks.</div>
                          </div>
                        </td> 
                      </tr>
                    </tbody>
                    <tfoot class="thead-blue text-center">
                      <tr>
                        <th style="width: 20%; text-align: right;">Total Wts(%)</th>
                        <th style="width: 5%; text-align: center;">{{this.KRAApplicationModel.TotalWateges}}</th>  
                        <th style="width: 20%; text-align: left;"></th>
                        <th style="width: 10%; text-align: left;">Self Rating(%)</th>
                        <th style="width: 5%; text-align: left;">{{this.KRAApplicationModel.FinalSelfRating}}</th>
                        <th style="width: 10%; text-align: left;">
                          <div *ngIf="this.KRAApplicationModel.RHCode != this.KRAApplicationModel.FHCode">RH Rating(%)</div>
                          <div *ngIf="this.KRAApplicationModel.RHCode == this.KRAApplicationModel.FHCode">FH Rating(%)</div>
                        </th>
                        <th style="width: 5%; text-align: left;" *ngIf="this.KRAApplicationModel.RHCode != this.KRAApplicationModel.FHCode">
                          {{this.KRAApplicationModel.FinalRHRating}}
                        </th>
                        <th style="width: 10%; text-align: left;" *ngIf="this.KRAApplicationModel.RHCode != this.KRAApplicationModel.FHCode">
                          FH Rating(%)
                        </th>
                        <th style="width: 5%; text-align: left;">{{this.KRAApplicationModel.FinalFHRating}}</th>
                        <th style="width: 10%; text-align: left;"></th>
                      </tr>
                    </tfoot>
                  </table>
                  <div class="row float-right">
                    <div class=" col-auto text-center">
                      <button type="submit" tabindex="22" class="btn btn-primary save-button" [disabled]="!(KRADetailForm.valid && this.KRAApplicationModel.TotalWateges==100 && this.DisableSave==true)" (click)="SaveKRAApplication(template)">Final Submit</button>  
                    </div>
                  </div>
                </div>
              </form>
            </fieldset>
          </div>

          <button type="button" aria-label="Close" (click)="modalRef.hide()" style="background: #2a9bd7; outline: 0px none; border: 0px none; color: #fff; padding: 5px 10px; border-radius: 3px;">
            <span>Close</span>
          </button>             
        </div>
      </ng-template>
    </div>

  </div>
</div>