<perfect-scrollbar style="max-width: 1260px; max-height: 584px;">
    <div class="body-content-area tabcontent" id="link1">
        <div class="row">
            <div class="col-lg-9">
                <div class="row">
                    <div class="col-lg-1 low-padding text-center">
                        <img *ngIf="this.SelectedMenu.Image!=null" src={{this.SelectedMenu.Image}} class="body-icon"
                            alt="Icon" />
                    </div>
                    <div class="col-lg-10 clear-padding">
                        <span class="page-title">Leave Master</span>
                        <small class="page-description"></small>
                    </div>
                </div>
            </div>
        </div>

        <div *ngIf="!(this.CanViewState)">
            <table id="tblLeaveMasters" datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger"
                class="table table-striped table-bordered text-center table-new row-border hover">
                <thead class="thead-blue text-center">
                    <tr>
                        <th>Sl No.</th>
                        <th class="text-left">Company</th>
                        <th class="text-left">Leave Type</th>
                        <th>Total</th>
                        <th>Action</th>

                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let lm of LeaveMasters, let i = index">
                        <td scope="row">{{i+1}}</td>
                        <td class="text-left">{{lm.CompanyName}}</td>
                        <td class="text-left">{{lm.LiveTypeName}}</td>
                        <td>{{lm.TotalLeave}}</td>
                        <td>
                            <button *ngIf="this.SelectedMenu.CanEdit" type="button"
                                (click)="GetLeaveMasterByIdyid(lm.LeaveMasterId)" class="table-edit-btn"><i
                                    class="fas fa-edit"></i> </button>
                            <button *ngIf="this.SelectedMenu.CanDelete" hidden="hidden" type="button"
                                (click)="DeleteLeaveRuleByid( lm.LeaveMasterId )" class="table-remove-btn"><i
                                    class="fas fa-trash-alt"></i> </button>
                            <button *ngIf="this.SelectedMenu.CanView" type="button"
                                (click)="viewLeaveMasterByid(lm.LeaveMasterId)" class="table-view-button"
                                type="button"><i class="fas fa-eye"></i></button>
                        </td>
                    </tr>
                </tbody>
            </table>
            <fieldset class="body-form-control">
                <app-dialog [(visible)]="this.SuccessStatus">
                    <span class="successmsg">{{this.SuccessMessage}}</span>
                </app-dialog>

                <legend class="legend--">Leave Master Creation</legend>
                <form #LeaveMsater="ngForm" (ngSubmit)="saveLeaveMaster()">
                    <div class="row">
                        <div class="col-lg-4">
                            <div *ngIf="this.LeaveIsExist">This Live Is exist </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-4">
                            <div class="col-body-border-right">
                                <div class="form-group row">
                                    <label class="col-sm-4 col-form-label clear-padding-right"> Company</label>
                                    <div class="col-sm-7 clear-left-padding">
                                        <select [(ngModel)]="this.LeaveMastermodel.CompanyId" tabindex="1"
                                            id="CompanyId" class="form-control" name="CompanyId"
                                            [disabled]="IsInEditstate" #CompanyId="ngModel"
                                            (ngModelChange)="onCcompanyandLeavetyprchange($event)">
                                            <option [value]="0" [disabled]=true>Select Company</option>
                                            <option *ngFor="let comp of Companies" [value]="comp.CompId">
                                                {{comp.CompName}}
                                            </option>
                                        </select>
                                    </div>

                                </div>
                            </div>
                        </div>

                        <div class="col-lg-4">
                            <div class="form-group row">
                                <label class="col-sm-4 col-form-label clear-padding-right">Leave Type</label>
                                <div class="col-sm-7 clear-left-padding">
                                    <select [(ngModel)]="this.LeaveMastermodel.LeaveType" tabindex="3" id="LeaveType"
                                        class="form-control" [disabled]="IsInEditstate" name="LeaveType"
                                        #LeaveType="ngModel" (ngModelChange)="onCcompanyandLeavetyprchange($event)">
                                        <option [value]="0" [disabled]=true>Select Leave</option>
                                        <option *ngFor="let ltype of LeaveTypes" [value]="ltype.LeaveTypeId">
                                            {{ltype.LeaveTypeName}}</option>
                                    </select>
                                </div>

                            </div>
                        </div>

                        <div class="col-lg-4">
                            <div class="form-group row">
                                <label class="col-sm-4 col-form-label clear-padding-right">Total Leave</label>
                                <div class="col-sm-7 clear-left-padding">
                                    <input type="text" [(ngModel)]="this.LeaveMastermodel.TotalLeave" tabindex="5"
                                        maxlength="250" id="Totalleave" name="TotalLeave" #Totalleave="ngModel"
                                        class="form-control form-control-custom form-control-custom-new"
                                        [disabled]="IsInViewState">
                                </div>

                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-lg-4">

                            <div class="form-group row">
                                <label class="col-sm-4 col-form-label clear-padding-right">From date</label>
                                <div class="col-sm-7 clear-left-padding">
                                    <input class="form-control" style="float:none" placeholder="Select date"
                                        ngx-mydatepicker name="FromDate" [(ngModel)]="this.LeaveMastermodel.Fromdate"
                                         #dp="ngx-mydatepicker"
                                        (dateChanged)="onDateChangedFrom($event)" />
                                        <!-- [options]="myOptions" -->
                                    <span class="mt-2">
                                        <a class="btn btn-primary pl-10 pr-10 pt-1 pb-1" (click)="dp.toggleCalendar()">
                                            <i style="color:#fff" class="fas fa-calendar-alt fa-lg"></i>
                                        </a>

                                        <a class="btn btn-danger pl-10 pr-10 pt-1 pb-1" (click)="dp.clearDate()">
                                            <i style="color:#fff" class="far fa-calendar-times fa-lg"></i>
                                        </a>
                                    </span>
                                </div>

                            </div>

                        </div>
                        <div class="col-lg-4">

                        </div>
                        <div class="col-lg-4">
                            <div class="form-group row">
                                <label class="col-sm-6 col-form-label text-center clear-padding-right"
                                    *ngIf="!(this.IsDataIsInViewState)">
                                    <button type="submit" tabindex="4"
                                        *ngIf="((!this.LeaveIsExist) && (this.LeaveMastermodel.LeaveType > 0) && (this.LeaveMastermodel.CompanyId > 0) && (this.LeaveMastermodel.LeaveType > 0) ) "
                                        class="btn btn-primary save-button">Save</button>
                                </label>
                                <div class="col-sm-6 col-form-label text-center">
                                    <button type="button" tabindex="5" class="btn btn-danger cancle-button"
                                        (click)="RefreshPage()">Cancel</button></div>
                            </div>
                        </div>
                    </div>
                </form>
            </fieldset>
        </div>
    </div>
</perfect-scrollbar>