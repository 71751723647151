import { TourReimbursementDetailsModel }  from '../EmployeePortal/TourReimbursementDetailsModel.Model';

export class TourReimbursementMasterModel
{
    public RefIdTR : number;
    public EmployeeId : number;
    public EmployeeCode : number;
    public EmployeeName : number;
    public EmployeeEmailId : string;

    public DecisionId : number;
    public LevelId : number;
    public GradeId : number;
    public NOofTrip : number;
    public RHId : number;
    public RHCode : number;
    public RHName :number;
    public RHEmailId : number;
    public FHId : number;
    public FHCode :number;
    public FHName : number;
    public FHEmailId : number;
    public HRNames :number;
    public HREmailIds :number;
    public ExpenseName : string;
    public FromDate : Date;
    public ToDate : Date;
    public NoOfDays : number;
    public NoOfNights : number;
    public TourPlace : string;
    public ExpectedExpenseAmount : number;
    public ApprovedExpenseAmount : number;
    public AdvanceAmount : number;
    public AdvancePaidDate : Date;
    public HRRemarks : string;
    public RHRemarks : string;
    public FHRemarks : string;
    // Final Approval
    public FinalExpecetdAmount : number;
    public ApproveFinalAmount : number;
    public FinalRemarks : string;
    public FinalHRRemarks : string;
    public FinalFinanceRemarks : string;
    public File :string;
    public FileName :string;
    public FileType : string;
    public FileExtension : string; 
    public Filesize : string;
    public BalancePaidDate : Date;

    public TourReimbursementDetails  :  TourReimbursementDetailsModel [];
    public NetAvailableAmount : number;
    public AdvanceAmountMain : number;
    public SentMailid : string;
    public SentMailPassword : string;
    public HRISURL : string;
    public BalanceAmount : number;
}