export class CrmMenuAccessUsers 
{
        public  MenuId : number;
        public  RollName : string;
        public  MenuTitle : string;
        public  ModuleId  : number;
        public  IconUrl  : string;
        public  SmallIconUrl : string;
        public  RouteName : string;
        public  UserId : number;
        public  CanAdd : boolean
        public  CanEdit : boolean;
        public  CanDelete : boolean;
        public  CanApprove : boolean;
        public  CanView : boolean;
        public  CanTransfer : boolean;
        public  CanReject : boolean;
        public  CanAccess : boolean;
        public  IsDefaultPage : boolean;
        public  Image : string;
        public  LogoType : string;
        public  SmallImage : string;
        public  SmallLogoType : string;
}