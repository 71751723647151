

export class Model_ForexCRM_CorporateDetails {
    // FromDate: Date;
    public ForexCrmId: number;
    public CompanyName: string;
    public CompanyTypeId: number;
    public CompanyType: string;
    public CustomerName: string;
    public CustomerContactNo: string;
    public CustomerEmailId: string;
    public CustomerAniversaryDate: Date;
    public CustomerDOB:Date;
    public OrganizationName: string;
    public OrganizationType: string;
    public OrganizationTypeId: number;
    //public MeetingDetails: Date;
    public IsMeetingDetailsPositive: boolean;
    public IsMeetingDetailsHODSupportRequired: boolean;
    public IsMeetingDetailsIsNegetive: boolean;
    public ProposalStatus: boolean;
    public OtherDetails : string;
    public NextMeetingDate: Date;
    public TimelineForConversation: string;
    public CurrencyVolume: string;
    public CardVolume: string;
    public LocationServedId: number[];
    public IsActive: boolean;
    public CreatedDate: Date;
    public CreatedBy: number;
    public Updateddate: Date;
    public UpdatedBy: number;
    public IsAnniversaryDateMoreinfo: boolean;
    public IsDOBMoreinfo: boolean;

    public IsNextMeetingDate: boolean;

// File Upload 
    //  public File: string;
    //  public DocumentActualName: string;
    //  public DocumentType: string;
    //  public DocumentExtension: string;
    //  public DocumentName: string;
    //  public DocumentSize: string;

    constructor() {
        this.LocationServedId = [];

    }

}       