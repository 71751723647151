import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs/Subject';
import { DataTableDirective } from 'angular-datatables';
import { UserService } from '../../shared/user.service';
import { OrganizationService } from '../../shared/Organization/organization.service';
import { LeaveMasterService } from '../../shared/HR_ADMIN/Leave/LeaveMaster.service';
import { LeaveRule } from  '../../shared/HR_ADMIN/Leave/LeaveRule.Model';
import { Company } from  '../../shared/Organization/Company.Model';
import { FinancialYear } from  '../../shared/Organization/FinancialYear.Model';
import { Branch } from  '../../shared/Organization/Branch.Model';

// Begning Confirmation
import { ConfirmationService } from '@jaspero/ng2-confirmations';
import { ResolveEmit } from '../../../app/resolve-emit';
import { ConfirmSettings } from '../../../app/confirm-settings';
import { Menu } from '../../shared/menu.model';
import 'rxjs/util/isNumeric';
// end of Confirmation


@Component({
  selector: 'app-leave-rule',
  templateUrl: './leave-rule.component.html',
  styleUrls: ['./leave-rule.component.css']
})
export class LeaveRuleComponent implements OnInit 
{
  LeaveRuleModel : LeaveRule= new LeaveRule();
  userClaims: any;
  LeaveRules: LeaveRule[] = [];
  LeavecompRules: LeaveRule[] = [];

  Result : string;
  SuccessStatus:boolean=false;
  SuccessMessage: string ="";
  public tableWidget: any;
  LeaveRuleId : any;
  LeaveRule: any;
  base64textString : string;

  Companies: Company[] = [];
  company: any;
  Branches: Branch[] = [];
  Branch: any;
  Years:FinancialYear[]=[];
  Year:any;
  tempLeaveRuleadd : LeaveRule =new LeaveRule();
  LeavesIsValid : boolean =false;  
  // Begning Permission Portion    
  SelectedMenu:Menu;
  IsDataIsInViewState : boolean=false;
  CanViewState : boolean=false;
  DisableSave :boolean=false;
  // End of the permission portion
  //check for duplicate component code
  CheckExiststatus : boolean=null;  
  Checkeditstatus : boolean =false;
  check : string ="";
  checkdata : any;
  checkdataval : string ="";
  //check for duplicate component code
  //Begin for disable
  IsCompanyEnable : boolean = false;
  IsBranchEnable : boolean = false; 
  IsYearEnable : boolean = false; 
  IsGridEnable : boolean = false;
  //End for disable
  
  constructor(private LeaveMasterService: LeaveMasterService, private organizationService: OrganizationService, private rout: Router,  private _confirmation: ConfirmationService, private userService: UserService) { }
  
  @ViewChild(DataTableDirective, {static: false})
  setDtOptions: DataTables.Settings = {};
  cardDtOptions: DataTables.Settings = {};
  dtElement: DataTableDirective;
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();
  
  ngOnInit() 
  {
    //Begin Permission Portion
    this.SelectedMenu=this.userService.SelectedMenu;
    if(this.SelectedMenu !=undefined)
    {
      localStorage.setItem('selectedmenu',JSON.stringify(this.SelectedMenu));
      this.rout.navigate([this.SelectedMenu.RouteName]);
    }
    else
    {
      if(JSON.parse(localStorage.getItem('selectedmenu'))!=undefined)
      {
        this.SelectedMenu = JSON.parse(localStorage.getItem('selectedmenu'));
        this.rout.navigate([this.SelectedMenu.RouteName]);
      }
    }
    if(this.SelectedMenu.CanAdd)
    {
    this.IsDataIsInViewState=false;
    }
    else
    {
      this.IsDataIsInViewState=true;
    }
    if(this.SelectedMenu.CanView)
    {
    this.CanViewState=false;
    }
    else
    {
      this.CanViewState=true;
    }
    //End of the peremission portion
    this.getCompanies();
    this.getYears();
    this.LeaveRuleModel.CompanyId=0;
    this.LeaveRuleModel.BranchId=0;
    this.LeaveRuleModel.Year=0;
    this.DisableSave=true;
    if(localStorage.getItem('SuccessfullMessage'))
    {
      this.SuccessStatus=true;
      this.SuccessMessage =localStorage.getItem('SuccessfullMessage')
      localStorage.removeItem('SuccessfullMessage');
    }  
  }

  ngAfterViewInit(): void 
  {
    this.GetLeaveRule();
    this.dtOptions = 
    {
      pagingType: 'full_numbers',
      pageLength: 5
    };
  }

  settings: ConfirmSettings | any = 
  {
    overlay: true,
    overlayClickToClose: true,
    showCloseButton: true,
    confirmText: 'Yes',
    declineText: 'No'
  };

  getCompanies() 
  {
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.organizationService.GetCompany( t).then(x => 
    {
      this.company = x;
      Object.keys(this.company).forEach( key => 
      {
        // tslint:disable-next-line:no-unused-expression
        this.company[key];
        let Companytemp = new Company();
        Companytemp = this.company[key];
        this.Companies.push(Companytemp);
      });     
    });      
  }

  getBranches(BranchId) 
  {
    let t = localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.organizationService.GetBranchByCompanyId( t,BranchId).then(x => 
    {
      this.Branch = x;
      Object.keys(this.Branch).forEach( key => 
      {
        // tslint:disable-next-line:no-unused-expression
        this.Branch[key];
        let Branchtemp = new Branch();
        Branchtemp = this.Branch[key];
        this.Branches.push(Branchtemp);
      });     
    });      
  }

  GetLeaveRule()
  {
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.LeaveMasterService.GetLeaveRule( t).then(x => 
    {
      this.LeaveRule = x;
      Object.keys(this.LeaveRule).forEach( key => 
      {
        this.LeaveRule[key];
        let LeaveRuletemp = new LeaveRule();
        LeaveRuletemp = this.LeaveRule[key];
        this.LeaveRules.push(LeaveRuletemp);
      });
      this.dtTrigger.next();
    });
  }

  getYears() 
  {
    let t = localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.organizationService.GetYearOnly( t).then(x => 
    {
      this.Year = x;
      Object.keys(this.Year).forEach( key => 
      {
        // tslint:disable-next-line:no-unused-expression
        this.Year[key];
        let Yeartemp = new FinancialYear();
        Yeartemp = this.Year[key];
        this.Years.push(Yeartemp);
      });     
    });  
  }

  GetLeaveRuleByid(CompanyId,  Year) 
  { 
    this.LeavesIsValid=true;
    //Begin for disable   
    this.IsCompanyEnable = true;
    this.IsBranchEnable = true; 
    this.IsYearEnable = true; 
    this.IsGridEnable = true;      
    //End for disable
    // Begning of the permission portion
    this.IsDataIsInViewState=false;
    // End of the permision portion
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.LeaveMasterService.GetLeaveRuleById( t,CompanyId,  Year).then(x => 
    {
      this.LeaveRuleId = x;
      this.LeavecompRules=  [];
      

      Object.keys(this.LeaveRuleId).forEach( key => 
      {
        // tslint:disable-next-line:no-unused-expression
        this.LeaveRuleId[key];
        var leavetemp = new LeaveRule();
        leavetemp = this.LeaveRuleId[key];  
        if(leavetemp.IsMonthly==true)
        {
          leavetemp.value="IsMonthly";
        }
        if(leavetemp.IsYearly==true)
        {
          leavetemp.value="IsYearly";
        }
        if(leavetemp.IsAllTime==true)
        {
          leavetemp.value="IsAllTime";
        }
        if(leavetemp.IsTransferable==true)
        {
          leavetemp.Istransfirm="IsTransferable";
        }

        if(leavetemp.IsAfterConfirmation==true)
        {
          leavetemp.IsConfirm="IsAfterConfirmation";
        }

        
        this.LeavecompRules.push(leavetemp);
      });
      this.LeaveRuleModel.CompanyId=CompanyId;
     
      this.LeaveRuleModel.Year=Year;
      this.getBranches(this.LeaveRuleModel.CompanyId);
      // Begin for check company code duplication
      this.checkdataval= this.LeaveRuleModel.LeaveRuleId.toString();
      this.Checkeditstatus=true;
      this.CheckExiststatus =true;
      this.check=""
      // End for check company code duplication
    });
  }

  viewLeaveRuleByid(CompanyId, Year) 
  {
    //Begin for disable   
    this.IsCompanyEnable = true;
    this.IsBranchEnable = true; 
    this.IsYearEnable = true; 
    this.IsGridEnable = false;     
    //End for disable
    //begin permission
    this.IsDataIsInViewState=true;
    //end permission
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.LeaveMasterService.GetLeaveRuleById( t,CompanyId, Year).then(x => 
    {
      this.LeaveRuleId = x;
      this.LeavecompRules=  [];
      Object.keys(this.LeaveRuleId).forEach( key => 
      {
        // tslint:disable-next-line:no-unused-expression
        this.LeaveRuleId[key];
        let leavetemp = new LeaveRule();
        leavetemp = this.LeaveRuleId[key];
        if(leavetemp.IsMonthly==true)
        {
          leavetemp.value="IsMonthly";
        }
        if(leavetemp.IsYearly==true)
        {
          leavetemp.value="IsYearly";
        }
        if(leavetemp.IsAllTime==true)
        {
          leavetemp.value="IsAllTime";
        }

        if(leavetemp.IsTransferable==true)
        {
          leavetemp.Istransfirm="IsTransferable";
        }

        if(leavetemp.IsAfterConfirmation==true)
        {
          leavetemp.IsConfirm="IsAfterConfirmation";
        }
        this.LeavecompRules.push(leavetemp);
      });
      this.LeaveRuleModel.CompanyId=CompanyId;
      
      this.LeaveRuleModel.Year=Year;
      this.getBranches(this.LeaveRuleModel.CompanyId);
      // Begin for check company code duplication
      this.checkdataval= this.LeaveRuleModel.LeaveRuleId.toString();
      this.Checkeditstatus=true;
      this.CheckExiststatus =true;
      this.check=""
      // End for check company code duplication
    });
  }

  DeleteLeaveRuleByid(Refid : number) :void
  {
    if(this.SelectedMenu.CanAdd)
    {
     this.IsDataIsInViewState=false;
    }
    else
    {
      this.IsDataIsInViewState=true;
    }
    this._confirmation.create('Confirm to Deleate', 'Press Yes to Delete', this.settings)
      .subscribe((ans: ResolveEmit) => 
      {
        if (ans.resolved == true) 
        {
          let t =  localStorage.getItem('userToken');
          t = 'Bearer' + t;
          this.LeaveRuleModel.IsActive=true;
          if( Refid > 0)
          {
            this.LeaveMasterService.DeleteLeaveRule(t ,Refid).subscribe(res=>
            {
              this.LeaveRuleId=res;
              this.LeaveRuleModel.LeaveRuleId=0;                                  
              if(this.LeaveRuleId >0)
              {
                localStorage.setItem('SuccessfullMessage', "Deleted Successfully");
                this.rout.navigateByUrl('/LeaveMaster/LeaveType', {skipLocationChange: true}).then(()=>this.rout.navigate(["/LeaveMaster/ManageLeaveRule"]));
              }   
            });                  
          }
        }
      })
  }

  RefreshPage()
  {
    this.rout.navigateByUrl('/LeaveMaster/LeaveType', {skipLocationChange: true}).then(()=>this.rout.navigate(["/LeaveMaster/ManageLeaveRule"]))
  }

  saveLeaveRule() :void
  {
    this.DisableSave=false;
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.LeaveMasterService.UpdateLeaveRulebulk(t ,this.LeavecompRules).subscribe(res=>
    {
      this.LeaveRuleId=res;
      if(this.LeaveRuleId>0)
      {
        localStorage.setItem('SuccessfullMessage', "Update Successfully");
        this.rout.navigateByUrl('/LeaveMaster/LeaveType', {skipLocationChange: true}).then(()=>this.rout.navigate(["/LeaveMaster/ManageLeaveRule"]));
      }
    });                  
  }

  onCompanychange(Companyid) 
  {
    this.getBranches(Companyid);
  }

  OnChangeIsMonthly(evt,i:number)
  {
    this.LeavecompRules[i];
    var target = evt.target;
    if(target.checked)
    {           
      this.LeavecompRules[i].IsMonthly=true;
      this.LeavecompRules[i].IsYearly=false;
      this.LeavecompRules[i].IsAllTime=false; 
      this.LeavecompRules[i].value="IsMonthly";
    }
  }

  OnChangeIsConfirmation(evt,i:number)
  {
    this.LeavecompRules[i];
    var target = evt.target;
    if( this.LeavecompRules[i].IsAfterConfirmation)
    {           
     
      this.LeavecompRules[i].IsAfterConfirmation=false; 
      this.LeavecompRules[i].IsConfirm="";
    }
    else
    {
      
      this.LeavecompRules[i].IsAfterConfirmation=true; 
      this.LeavecompRules[i].IsConfirm="IsAfterConfirmation";
      this.LeavecompRules[i].AvaleafterNoMonths=0;
    }
  }

  onMonthchangechange(i)
  { 
    
    if(this.LeavecompRules[i].AvaleafterNoMonths > 0)
    {
      this.LeavecompRules[i].IsAfterConfirmation=false; 
      this.LeavecompRules[i].IsConfirm="";
      
    } 
    else{
    this.LeavecompRules[i].AvaleafterNoMonths=0;
    }

    
    
  }

  OnChangeIsTransfer(evt,i:number)
  {
    //this.LeavecompRules[i];
    var target = evt.target;
         
     
    if( this.LeavecompRules[i].IsTransferable){
      this.LeavecompRules[i].IsTransferable=false; 
      this.LeavecompRules[i].Istransfirm="";
    }
    else
    {
      this.LeavecompRules[i].IsTransferable=true; 
      this.LeavecompRules[i].Istransfirm="IsTransferable";
    }
  }
  
  OnChangeIsYearly(evt,i:number)
  {
    this.LeavecompRules[i];
    var target = evt.target;
    if(target.checked)
    {           
      this.LeavecompRules[i].IsMonthly=false;
      this.LeavecompRules[i].IsYearly=true;
      this.LeavecompRules[i].IsAllTime=false; 
      this.LeavecompRules[i].value="IsYearly";
    }
  }
  
  OnChangeIsAllTime(evt,i:number)
  {
    this.LeavecompRules[i];
    var target = evt.target;
    if(target.checked)
    {           
      this.LeavecompRules[i].IsMonthly=false;
      this.LeavecompRules[i].IsYearly=false;
      this.LeavecompRules[i].IsAllTime=true; 
      this.LeavecompRules[i].value="IsAllTime";
    }
  }
}
