<div>

  <app-dialog>
    <span class="successmsg">{{this.SuccessMessage}}</span>
  </app-dialog>
</div>


<!-- <perfect-scrollbar style="max-width: 1260px; max-height: 540px;"> -->



<div class="body-content-area " id="link1">
  <div class="row">
    <div class="col-lg-12">
      <div class="row">
        <div class="col-lg-6 text-center p-t18">
          <span class="page-title">
            <h4>Manage Lead</h4>
          </span>
        </div>

        <div class="col-lg-6 text-right p-t18">
          <!-- <a class="btn btn-primary add_new_btn" href="#" onclick="viewAppliedLeave(template)">+ New List</a>. -->
          <button type="button" (click)="viewApplicationSharedtemplate(templateShareLead)" class="btn btn-success ">
            <span>Share Lead</span>
          </button>
          <button type="button" (click)="viewAppliedLeave(template)" class="btn btn-primary add_new_btn">
            <span>+ New List</span>
          </button>
        </div>

      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-md-6">
      <!-- <fieldset *ngIf="!(this.IsEmployeeInViewState)" class="body-form-control"> -->
      <legend class="legend--">
        <h5>Leads....</h5>
      </legend>

      <div class="row">
        <div class="col-md-1">
          <p class="text-left p-0">Search:</p>
        </div>
        <div class="col-md-5">
          <div id="tblCRMList_filter" class="dataTables_filter"><input type="search" class="w-100"
              (focusout)="onSearchChange($event.target.value)" placeholder="" aria-controls="tblCRMList">
          </div>
        </div>
        <!-- <div class="col-md-4">
          <button _ngcontent-fwp-c6="" class="btn btn-primary p-24" type="button">Search</button>
        </div> -->
      </div>



      <!-- <perfect-scrollbar style="max-width: 100%; max-height: 218px;"> -->
      <div class="tablediv">
        <table id="tblCRMLead" datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger"
          class="table table-striped table-bordered text-center table-new row-border hover" fixed-header>

          <thead class=" thead-blue text-center">
            <tr class="table-head" fixed-header>
              <!-- <th>Sl No.</th> -->
              <th class="text-left">Lead Name</th>
              <th class="text-left">Last Activity Date</th>
              <th class="text-left">Created By</th>
              <th>Action</th>
            </tr>
          </thead>

          <tbody>

            <tr *ngFor="let l of  Pagelist, let i = index">
              <!-- <td scope="row">{{i+1}}</td> -->

              <td class="text-left">{{l.LeadName}}</td>
              <td class="text-left">{{l.LastActivity}}</td>
              <td class="text-left">{{l.EmployeeName}}</td>

              <td>


                <button type="button" (click)="GetCRMLeadById( l.LeadId )"
                  *ngIf="UserServiceData.selectedCrmMenu.CanEdit" class="table-edit-btn">
                  <i class="fas fa-edit"></i>

                </button>

                <button type="button" (click)="DeleteCrmLead( l.LeadId )"
                  *ngIf="UserServiceData.selectedCrmMenu.CanDelete" class="table-remove-btn">
                  <i class="fas fa-trash-alt"></i>
                </button>

                <button type="button" (click)="ViewCRMLeadById( l.LeadId )"
                  *ngIf="UserServiceData.selectedCrmMenu.CanView" class="table-view-button" type="button">
                  <i class="fas fa-eye"></i>
                </button>
              </td>
            </tr>

          </tbody>

        </table>
      </div>
      <!-- </perfect-scrollbar> -->
      <nav aria-label="Page navigation example">
        <ul *ngIf="pager.pages && pager.pages.length" class="pagination">
          <li [ngClass]="{disabled:pager.currentPage === 1}" class="page-item">
            <a (click)="GetCRMLeadFrompage(1)" class="page-link">First </a>
          </li>
          <li [ngClass]="{disabled:pager.currentPage === 1}" class="page-item">
            <a (click)="GetCRMLeadFrompage(pager.currentPage - 1)" class="page-link">Previous</a>
          </li>
          <li *ngFor="let page of pager.pages" [ngClass]="{active:pager.currentPage === page}" class="page-item">
            <a (click)="GetCRMLeadFrompage(page)" class="page-link">{{page}}</a>
          </li>
          <li [ngClass]="{disabled:pager.currentPage === pager.totalPages}" class="page-item">
            <a (click)="GetCRMLeadFrompage(pager.currentPage + 1)" class="page-link">Next</a>
          </li>
          <li [ngClass]="{active1:pager.currentPage === pager.totalPages}" class="page-item">
            <a (click)="GetCRMLeadFrompage(pager.totalPages)" class="page-link">Last</a>
          </li>
        </ul>
      </nav>



      <!-- </fieldset> -->
      <!-- <button type="button" tabindex="33" (click)="Relode()" class="btn btn-danger cancle-button">Relod</button> -->

    </div>





    <div class="col-md-6">
      <fieldset class="body-form-control">
        <legend class="legend--">Lists</legend>
        <table id="tblCRMList" datatable [dtOptions]="dtOptionsList" [dtTrigger]="dtTriggerList"
          class="table table-striped table-bordered text-center table-new row-border hover">
          <thead class="thead-blue text-center">

            <tr>
              <th>Sl No.</th>

              <th class="text-left">List Name</th>
              <!-- <th class="text-left">Last Activity Date</th> -->
              <th>Action</th>

          </thead>

          <tbody>

            <tr *ngFor="let l of this.ListList, let i = index">
              <td scope="row">{{i+1}}</td>

              <td class="text-left">{{l.ListName}}</td>

              <td>
                <button type="button" (click)="GetListById( l.ListId, template )"
                  *ngIf="this.userService.selectedCrmMenu.CanEdit" class="table-edit-btn">
                  <i class="fas fa-edit"></i>
                </button>
              </td>


          </tbody>
        </table>
      </fieldset>
    </div>
  </div>
  <!-- <fieldset class="body-form-control" *ngIf="(this.IsFormView)">  -->
  <fieldset class="body-form-control">
    <legend class="legend--"></legend>
    <section>
      <div class="tabs tabs-style-tzoid">
        <nav>
          <ul>
            <li [ngClass]="{'tab-current':  SelectedPageIndex == 0}"
              (click)="CRMSubPages(0,'ManageMICEAccess/ManageLead/LeadBasicInfo')"><a class="icon icon-home"><span>Basic
                  Info</span></a></li>
            <li [ngClass]="{'tab-current':  SelectedPageIndex == 1}"
              (click)="CRMSubPages(1,'ManageMICEAccess/ManageLead/LeadMoreInfo')"> <a class="icon icon-box"><span>More
                  Info </span></a></li>
            <li [ngClass]="{'tab-current':  SelectedPageIndex == 2}"
              (click)="CrmContacts(2,'ManageMICEAccess/ManageLead/Contacts')"><a
                class="icon icon-upload"><span>Contacts</span></a></li>
            <li [ngClass]="{'tab-current':  SelectedPageIndex == 3}"
              (click)="CrmContacts(3,'ManageMICEAccess/ManageLead/AccountInfo')"><a
                class="icon icon-upload"><span>AccoumtInfo</span></a></li>
            <!-- <li [ngClass]="{'tab-current':  this.CRM_LeadService.SelectedCrmIndexPage == 3}" (click)="EmployeeSubPages(3,'Employee/EmployeeDetails/EducationalDetails')" ><a  class="icon icon-display"><span> Qualification</span></a></li>
                            <li [ngClass]="{'tab-current':  this.CRM_LeadService.SelectedCrmIndexPage == 4}" (click)="EmployeeSubPages(4,'Employee/EmployeeDetails/ExpriencDetails')" ><a  class="icon icon-tools"><span>Experianced Details</span></a></li>
                            <li [ngClass]="{'tab-current':  this.CRM_LeadService.SelectedCrmIndexPage == 5}" (click)="EmployeeSubPages(5,'Employee/EmployeeDetails/FamilyDetails')" ><a  class="icon icon-tools"><span>Family Details</span></a></li>
                            <li [ngClass]="{'tab-current':  this.CRM_LeadService.SelectedCrmIndexPage == 6}" (click)="EmployeeSubPages(6,'Employee/EmployeeDetails/NomineeDetails')" ><a  class="icon icon-tools"><span>Nomine Details</span></a></li> -->
          </ul>
        </nav>
        <div class="content-wrap">
          <router-outlet (activate)="onActivate($event)">
          </router-outlet>
        </div><!-- /content -->
      </div><!-- /tabs -->
    </section>
  </fieldset>


</div>


<!-- </perfect-scrollbar> -->

<div style=" box-align: center; width: 1000px;">


  <ng-template #template>

    <div class="modal-header" style="background: #2a9bd7; max-width: 1000px;">

      <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()" style="color:#fff;">
        <span aria-hidden="true">X</span>
      </button>
    </div>
    <div style="text-align: center; max-width: 900px;padding-left: 10%" class="no-border-fieldset">



      <fieldset class="body-form-control">

        <legend class="legend--">Add List</legend>
        <form #ApprovedApplicationForm="ngForm">
          <div class="row">

            <div class="col-lg-12">
              <div class="form-group row">

                <label class="col-sm-4 col-form-label clear-padding-right">List Name</label>

                <div class="col-sm-8 clear-left-padding">
                  <input type="text" [(ngModel)]="this.CRM_List_Model.ListName" tabindex="1" maxlength="500"
                    id="idLeadName" name="LeadName" #idLeadName="ngModel" required
                    class="form-control form-control-custom form-control-custom-new">
                </div>
              </div>
              <div class="form-group row">
                <div class="col-sm-12 text-right">
                  <button type="button" aria-label="Close" *ngIf="this.CRM_List_Model.ListName !=''"
                    (click)="saveList(templateAlert)"
                    style="background: #2a9bd7; outline: 0px none; border: 0px none; color: #fff; padding: 5px 10px; border-radius: 3px;">
                    <span>Save</span>
                  </button>
                </div>
              </div>

            </div>
          </div>
        </form>
      </fieldset>
    </div>

    <!-- <button type="button" aria-label="Close" (click)="modalRef.hide()" style="background: #2a9bd7; outline: 0px none; border: 0px none; color: #fff; padding: 5px 10px; border-radius: 3px;">
        <span >OK</span>
      </button>             
     -->

  </ng-template>
</div>



<div style=" box-align: center; width: 1000px;">


  <ng-template #templateShareLead>

    <div class="modal-header" style="background: #2a9bd7; max-width: 1000px;">

      <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()" style="color:#fff;">
        <span aria-hidden="true">X</span>
      </button>
    </div>


    <div style="text-align: center; max-width: 900px;padding-left: 10%" class="no-border-fieldset">



      <fieldset class="body-form-control">

        <legend class="legend--">Shared Lead</legend>
        <form #ApprovedApplicationForm="ngForm">
          <div class="row">

            <div class="col-lg-12">
              <div class="form-group row">

                <label class="col-sm-4 col-form-label clear-padding-right">Select Lead</label>

                <div class="col-sm-4 clear-left-padding select_dropdown">

                  <angular2-multiselect tabindex="5" class="form-control" [data]="CompanyArray"
                    [(ngModel)]="SelectedCompany" name="CompanyId" [settings]="this.CompanySettings"
                    (onSelect)="onItemSelectCompany($event)" (onDeSelect)="OnItemDeSelectCompany($event)"
                    (onSelectAll)="onSelectAllCompany($event)" (onDeSelectAll)="onDeSelectAllCompany($event)">
                  </angular2-multiselect>

                </div>
              </div>
              <div class="form-group row">
                <label class="col-sm-4 col-form-label clear-padding-right">Assigne To
                </label>
                <div class="col-sm-4 clear-left-padding">

                  <angular2-multiselect tabindex="5" class="form-control" [data]="AssigneArray"
                    [(ngModel)]="SelectAssigneList" name="AssignedId" [settings]="AssigneSettings"
                    (onSelect)="onItemSelectAssigneTo($event)" (onDeSelect)="OnItemDeSelectAssigneTo($event)"
                    (onSelectAll)="onSelectAllAssigneTo($event)" (onDeSelectAll)="onDeSelectAllAssigneTo($event)">
                  </angular2-multiselect>

                </div>
              </div>
              <div class="form-group row">
                <div class="col-sm-12 text-right">
                  <button type="button" aria-label="Close" *ngIf="this.CRM_List_Model.ListName !=''"
                    (click)="SaveCrmLeadShared()"
                    style="background: #2a9bd7; outline: 0px none; border: 0px none; color: #fff; padding: 5px 10px; border-radius: 3px;">
                    <span>Save</span>
                  </button>
                </div>
              </div>

            </div>
          </div>
        </form>
      </fieldset>
    </div>

    <!-- <button type="button" aria-label="Close" (click)="modalRef.hide()" style="background: #2a9bd7; outline: 0px none; border: 0px none; color: #fff; padding: 5px 10px; border-radius: 3px;">
        <span >OK</span>
      </button>             
     -->

  </ng-template>
</div>

<div style="box-align: center; width: 300px;">
  <ng-template #templateAlert>
    <div class="modal-header" style="background: #2a9bd7; max-width: 300px;">
      <div style="color: #dc3545; text-align: center;font-size: 15px; font-weight: bold;">
        {{this.SuccessFullMessageString}}</div>
      <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()" style="color:#fff;">
        <span aria-hidden="true">OK</span>
      </button>
    </div>
  </ng-template>
</div>