export class PTAXModel 
{
    public PtaxModel : PTAXModel [];
    public PtaxRefId : number;
    public FromAmount :number;
    public ToAmount : number;
    public TaxAmount : number;
    public ApplicableDate : Date;
    public StateId : number;
    public IsActive : boolean;
    public ApplicableDateCheck : Date;
}