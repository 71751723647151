

export class Model_CRM_Lead_Contacts  {

 


    public ContactID: number;
    public LeadId : number;
    public MR:string;
    public FirstName: string;
    public MiddleName: string;
    public LastName: string;
    public PhoneNo: string;
    public EmailId: string;
    public RollID: number;
    public Title: string;

}       