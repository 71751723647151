import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from '../../shared/user.service';
// Begning Confirmation
import { ConfirmationService } from '@jaspero/ng2-confirmations';
import { ResolveEmit } from '../../../app/resolve-emit';
import { ConfirmSettings } from '../../../app/confirm-settings';
// end of Confirmation
import { Menu } from '../../shared/menu.model';
import { ApproveLeaveService }  from '../../shared/HR_ADMIN/Approve/ApproveLeave.service';
import { LeaveApprovalModel } from '../../shared/HR_ADMIN/Approve/ApproveLeave.Model'
import { EmployeePortalService }  from '../../shared/HR_ADMIN/EmployeePortal/EmployeePortalService.service';
import { EmployeeLeaveBalanceModel } from '../../shared/HR_ADMIN/EmployeePortal/EmployeeLeaveBalanceModel.Model';
import { ModalOptions,BsModalRef, BsModalService } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-admin-leave-approval',
  templateUrl: './admin-leave-approval.component.html',
  styleUrls: ['./admin-leave-approval.component.css']
})
export class AdminLeaveApprovalComponent implements OnInit 
{
  EmployeeLeaveBalance: EmployeeLeaveBalanceModel[]=[];
  LeaveBalance: any;
  userClaims: any;
  ListApplication: LeaveApprovalModel[] = [];
  SubMenues : Menu[]=[];
  Mod : any;
  ModelListApplication: LeaveApprovalModel;
  Approver: any;
  Result : string;
  SuccessStatus:boolean=false;
  SuccessMessage: string ="";
  public tableWidget: any;
  RefId : any;
  // Begning Permission Portion    
  SelectedMenu:Menu;
  IsDataIsInViewState : boolean=false;
  CanViewState : boolean=false;  
  CanApproveState : boolean=false; 
  GridShow : boolean=false; 
  DisableSave : boolean=false;
  // End of the permission portion
  // begin for Validation
  validdate : boolean= false;
  // end of validation
  //For Pop up
  TempLeave : LeaveApprovalModel;
  ApprovedMsg : boolean= false;
  RejectedMsg : boolean= false;
  ShowPopup : boolean= false;
  public modalRef: BsModalRef;
  public modelopt:ModalOptions;
  NoRecordMsg : boolean;

  constructor(private ApproveLeaveSrevice: ApproveLeaveService,private EmployeePortalService:EmployeePortalService, private rout: Router, private _confirmation: ConfirmationService,  private userService: UserService,private modalService: BsModalService)
  { 
    this.ModelListApplication = new LeaveApprovalModel();
    this.ModelListApplication.FromFirstHalf=false;
    this.ModelListApplication.FromSecondHalf=false;
    this.ModelListApplication.ToFirstHalf=false;
    this.ModelListApplication.ToSecondHalf=false;
    this.modelopt=new ModalOptions();
    this.modelopt.class='heavy_modal';
    this.modalService.config= this.modelopt;
    this.modelopt.ignoreBackdropClick=true;
  }

  ngOnInit()
  {
    // Begning Permission Portion 
    this.SubMenues=[];
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    let menuid = localStorage.getItem("menuidstor");
    this.userService.GetSubMenuList( t, menuid).then(x => 
    {
      this.Mod = x;
      Object.keys(this.Mod).forEach( key => 
      {
        // tslint:disable-next-line:no-unused-expression
        this.Mod[key];
        let menutemp = new Menu();
        menutemp = this.Mod[key];
        this.SubMenues.push(menutemp);
      });
      this.userService.SelectedMenu= this.SubMenues[0];
      this.rout.navigate([this.userService.SelectedMenu.RouteName]);
      this.SelectedMenu=this.userService.SelectedMenu;
      if(this.SelectedMenu.CanAdd)
      {
        this.IsDataIsInViewState=false;
      }
      else
      {
        this.IsDataIsInViewState=true;
      }
      if(this.SelectedMenu.CanView)
      {
        this.CanViewState=false;
      }
      else
      {
        this.CanViewState=true;
      }
      if(this.SelectedMenu.CanApprove)
      {
        this.CanApproveState=false;
      }
      else
      {
        this.CanApproveState=true;
      }
    });
    //End of the permission portion
    this.DisableSave=true;
    this.GetAllApplication();
    this.ApprovedMsg=false;
    this.RejectedMsg=false;
    this.ShowPopup=false;
    this.NoRecordMsg= false;
  }

  settings: ConfirmSettings | any = 
  {
    overlay: true,
    overlayClickToClose: true,
    showCloseButton: true,
    confirmText: 'Yes',
    declineText: 'No'
  };

  GetAllApplication()
  {
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.ApproveLeaveSrevice.GetLeaveApplications( t).then(x => 
    {
      this.NoRecordMsg= false;
      this.Approver = x;
      Object.keys(this.Approver).forEach( key => 
      {
        this.Approver[key];
        let ApproveLeave = new LeaveApprovalModel();
        ApproveLeave = this.Approver[key];
        this.ListApplication.push(ApproveLeave);
      });
      if(this.ListApplication.length==0)
      {
        this.NoRecordMsg= true;
      }
    });
  }

  viewAppliedLeave(id,template) 
  {
    this.ModelListApplication.ApprovedRejectedRemarks="";
    this.ModelListApplication.RemarksAll="";
    this.GridShow=true;
    // Begning of the permission portion
    this.IsDataIsInViewState=false;
    // End of the permision portion
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.ApproveLeaveSrevice.GetLeavedetailsByID( t,id).then(x => 
    {
      if(this.ShowPopup==true)
      {
        this.modalRef.hide();
      }
      this.modalRef = this.modalService.show(template,this.modelopt);
      this.ShowPopup=true;
      this.ApprovedMsg=false;
      this.RejectedMsg=false;
      this.Approver = x;
      Object.keys(this.Approver).forEach( key => 
      {
        // tslint:disable-next-line:no-unused-expression
        this.Approver[key];
        let LeaveApprovetemp = new LeaveApprovalModel();
        LeaveApprovetemp = this.Approver[key];
        this.ModelListApplication.RefId=LeaveApprovetemp.RefId;
        this.ModelListApplication.EmployeeId=LeaveApprovetemp.EmployeeId;
        this.ModelListApplication.EmployeeCode=LeaveApprovetemp.EmployeeCode;
        this.ModelListApplication.EmployeeName=LeaveApprovetemp.EmployeeName; 
        this.ModelListApplication.EmployeeEmailId=LeaveApprovetemp.EmployeeEmailId; 
        this.ModelListApplication.LeaveTypeName=LeaveApprovetemp.LeaveTypeName; 
        this.ModelListApplication.LeaveTypeId=LeaveApprovetemp.LeaveTypeId; 
        this.ModelListApplication.NoDaysLeave=LeaveApprovetemp.NoDaysLeave; 
        this.ModelListApplication.FromDate=LeaveApprovetemp.FromDate; 
        this.ModelListApplication.FromFirstHalf=LeaveApprovetemp.FromFirstHalf; 
        this.ModelListApplication.FromSecondHalf=LeaveApprovetemp.FromSecondHalf;
        if(LeaveApprovetemp.FromFirstHalf==true)
        {
          this.ModelListApplication.Fromvalue="FirstHalf";
        }
        if(LeaveApprovetemp.FromSecondHalf==true)
        {
          this.ModelListApplication.Fromvalue="SecondHalf";
        } 
        this.ModelListApplication.ToDate=LeaveApprovetemp.ToDate; 
        this.ModelListApplication.ToFirstHalf=LeaveApprovetemp.ToFirstHalf; 
        this.ModelListApplication.ToSecondHalf=LeaveApprovetemp.ToSecondHalf; 
        if(LeaveApprovetemp.ToFirstHalf==true)
        {
          this.ModelListApplication.Tovalue="FirstHalf";
        }
        if(LeaveApprovetemp.ToSecondHalf==true)
        {
          this.ModelListApplication.Tovalue="SecondHalf";
        } 
        this.ModelListApplication.ReasonsLeave=LeaveApprovetemp.ReasonsLeave; 
        this.ModelListApplication.ApplicationDate=LeaveApprovetemp.ApplicationDate; 
        this.ModelListApplication.RemarksLeave=LeaveApprovetemp.RemarksLeave;
        this.ModelListApplication.ContactNoDuringLeave=LeaveApprovetemp.ContactNoDuringLeave;
        this.ModelListApplication.AddressDuringLeave=LeaveApprovetemp.AddressDuringLeave;
        this.ModelListApplication.Approved=LeaveApprovetemp.Approved;
        this.ModelListApplication.Rejected=LeaveApprovetemp.Rejected;
        this.ModelListApplication.ApprovedRejectedRemarks=LeaveApprovetemp.ApprovedRejectedRemarks;
        this.ModelListApplication.PersonToContact=LeaveApprovetemp.PersonToContact;
        this.ModelListApplication.RHId=LeaveApprovetemp.RHId;
        this.ModelListApplication.RHCode=LeaveApprovetemp.RHCode;
        this.ModelListApplication.RHName=LeaveApprovetemp.RHName;
        this.ModelListApplication.RHEmailId=LeaveApprovetemp.RHEmailId;
        this.ModelListApplication.CompanyId=LeaveApprovetemp.CompanyId;
        this.ModelListApplication.SentMailid=LeaveApprovetemp.SentMailid;
        this.ModelListApplication.SentMailPassword=LeaveApprovetemp.SentMailPassword;
        this.ModelListApplication.HRISURL=LeaveApprovetemp.HRISURL;
        this.GetEmployeeLeaveBalanceDetails(LeaveApprovetemp.EmployeeId);
      });
      // End for check company code duplication
    });
  }

  GetEmployeeLeaveBalanceDetails(EmployeeId)
  {
    this.EmployeeLeaveBalance=[];
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.EmployeePortalService.GetEmployeeLeaveBalanceByEmployeeId( t,EmployeeId).then(x => 
    {
      this.LeaveBalance = x;
      Object.keys(this.LeaveBalance).forEach( key => 
      {
        this.LeaveBalance[key];
        let LeaveBalancetemp = new EmployeeLeaveBalanceModel();
        LeaveBalancetemp = this.LeaveBalance[key];
        this.EmployeeLeaveBalance.push(LeaveBalancetemp);
      });
    });
  }
  
  LeaveApproved(template) :void
  {
    this.DisableSave=false;
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    if(this.ModelListApplication.RefId > 0)
    {
      this.ModelListApplication.Approved=true;
      this.ModelListApplication.Rejected=false;
      this.ApproveLeaveSrevice.LeaveApprovedOrRejected(t ,this.ModelListApplication).subscribe(res=>
      {
        this.RefId=res;
        this.ModelListApplication.RefId=0;  
        if(this.RefId>0)
        {
          this.ModelListApplication.ApprovedRejectedRemarks="";
          this.ModelListApplication.RemarksAll="";
          this.modalRef.hide();
          this.ApprovedMsg=true;
          this.RejectedMsg=false;
          this.ShowPopup=false;
          this.modalRef = this.modalService.show(template);
          this.ListApplication=[];
          this.GetAllApplication();
          this.DisableSave=true;
        }
      });                  
    }
  }

  LeaveRejected(template) :void
  {
    this.DisableSave=false;
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    if(this.ModelListApplication.RefId > 0)
    {
      this.ModelListApplication.Approved=false;
      this.ModelListApplication.Rejected=true;
      this.ApproveLeaveSrevice.LeaveApprovedOrRejected(t ,this.ModelListApplication).subscribe(res=>
      {
        this.RefId=res;
        this.ModelListApplication.RefId=0;  
        if(this.RefId>0)
        {
          this.ModelListApplication.ApprovedRejectedRemarks="";
          this.ModelListApplication.RemarksAll="";
          this.modalRef.hide();
          this.ApprovedMsg=false;
          this.RejectedMsg=true;
          this.ShowPopup=false;
          this.modalRef = this.modalService.show(template);
          this.ListApplication=[];
          this.GetAllApplication();
          this.DisableSave=true;
        }
      });                  
    }
  }

  RefreshPage()
  {
    this.rout.navigateByUrl('/AdminApproveOrReject/REGULARIZATION', {skipLocationChange: true}).then(()=>this.rout.navigate(["/AdminApproveOrReject/Leave"]));
  }

  SelectedChange(rowid,ev)
  {
    this.TempLeave =this.ListApplication[rowid];
    if(ev.target.checked==true)
    {
      this.TempLeave.Selected=true;
    }
    else
    {
      this.TempLeave.Selected=false;
    }
  }

  checkAll(ev) 
  {
    if(ev.target.checked == true)
    {
      var J=this.ListApplication.length;
      for(var i=0;i<J;i++) 
      {
        this.ListApplication[i].Selected=true;
      }
    }
    else
    {
      var J=this.ListApplication.length;
      for(var i=0; i<J; i++)
      {
        this.ListApplication[i].Selected=false;
      }
    }
  }

  LeaveApprovedAll(template) :void
  {
    if(this.ListApplication.length > 0)
    {
      this._confirmation.create('Confirm to Approve', 'Press Yes to Approve', this.settings)
      .subscribe((ans: ResolveEmit) => 
      {
        if (ans.resolved == true) 
        {
          this.DisableSave=false;
          let t =  localStorage.getItem('userToken');
          t = 'Bearer' + t;
          var j=0;
          var l=this.ListApplication.length;
          this.ListApplication.forEach(item => 
          {
            j=j+1;
            if(item.RefId > 0 && item.Selected == true)
            {
              item.ApprovedRejectedRemarks=this.ModelListApplication.RemarksAll;
              item.Approved=true;
              item.Rejected=false;
              this.ApproveLeaveSrevice.LeaveApprovedOrRejected(t ,item).subscribe(res=>
              {
                this.RefId=res;
                item.RefId=0;  
                if(j == this.ListApplication.length)
                {
                  if(this.ShowPopup==true)
                  {
                    this.modalRef.hide();
                  }
                  this.ModelListApplication.ApprovedRejectedRemarks="";
                  this.ModelListApplication.RemarksAll="";
                  this.ApprovedMsg=true;
                  this.RejectedMsg=false;
                  this.ShowPopup=false;
                  this.modalRef = this.modalService.show(template);
                  this.ListApplication=[];
                  this.GetAllApplication();
                  this.DisableSave=true;
                }
              });                  
            }
          })
        }
      })
    }
  }

  LeaveRejectedAll(template) :void
  {
    if(this.ListApplication.length > 0)
    {
      this._confirmation.create('Confirm to Reject', 'Press Yes to Reject', this.settings)
      .subscribe((ans: ResolveEmit) => 
      {
        if (ans.resolved == true) 
        {
          this.DisableSave=false;
          let t =  localStorage.getItem('userToken');
          t = 'Bearer' + t;
          var j=0;
          var l=this.ListApplication.length;
          this.ListApplication.forEach(item => 
          {
            j=j+1;
            if(item.RefId > 0 && item.Selected == true)
            {
              item.ApprovedRejectedRemarks=this.ModelListApplication.RemarksAll;
              item.Approved=false;
              item.Rejected=true;
              this.ApproveLeaveSrevice.LeaveApprovedOrRejected(t ,item).subscribe(res=>
              {
                this.RefId=res;
                item.RefId=0;  
                if(j == this.ListApplication.length)
                {
                  if(this.ShowPopup==true)
                  {
                    this.modalRef.hide();
                  }
                  this.ModelListApplication.ApprovedRejectedRemarks="";
                  this.ModelListApplication.RemarksAll="";
                  this.ApprovedMsg=false;
                  this.RejectedMsg=true;
                  this.ShowPopup=false;
                  this.modalRef = this.modalService.show(template);
                  this.ListApplication=[];
                  this.GetAllApplication();
                  this.DisableSave=true;
                }
              });                  
            }
          })
        }
      })
    }
  }
}