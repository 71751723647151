<div>
  <app-dialog>
    <span class="successmsg">{{this.SuccessMessage}}</span>
  </app-dialog>
</div>

<!-- <perfect-scrollbar style="max-width: 1260px; max-height: 540px;" class="mukesh"> -->
<div class="container">
  <div class="row">
    <div class="col-md-12">
      <div class="row">
        <div class="col-lg-12 text-center p-t18">
          <span class="page-title">
            <h4>Manage Opportunity</h4>
          </span>
        </div>
      </div>
    </div>
  </div>

  <!-- <perfect-scrollbar style="max-width: 1260px; max-height: 380px;"> -->
  <div class="row">
    <div class="col-md-12">

      <fieldset>

        <legend class="legend--">
          <h5>Opportunities</h5>
        </legend>

        <form #AddOppertunity="ngForm">
          <!-- (ngSubmit)="saveCRMOppertunity()" -->

          <div class="row">
            <div class="col-md-1">
              <p class="text-left p-0">Search:</p>
            </div>
            <div class="col-md-3">
              <div id="tblCRMList_filter" class="dataTables_filter"><input type="search" class="w-100"
                  (focusout)="onSearchChange($event.target.value)" placeholder="" aria-controls="tblCRMList">
              </div>
            </div>

            <!-- <div class="col-md-4">
              <button _ngcontent-fwp-c6="" class="btn btn-primary p-24" type="button">Submit</button>
            </div> -->
            <div class="col-md-8 clear-padding text-right">
              <!-- <a class="btn btn-primary add_new_btn" href="#" onclick="viewAppliedLeave(template)">+ New List</a>. -->
              <button type="button" (click)="viewApplicationSharedtemplate(templateShareLead)" class="btn btn-success ">
                <span>Share Opportunity</span>
              </button>
            </div>
          </div>

          <div class="row">
            <div class="tablediv">
              <table id="tblcrmoppertunity" datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger"
                class="table table-striped table-bordered text-left table-new row-border hover fixed-header">
                <thead class="thead-blue text-center">
                  <tr class="table-head" fixed-header>
                    <!-- <th>Sl.No.</th> -->
                    <th>Opportunity Title</th>
                    <th>Pipeline Status </th>
                    <th>Company Name</th>
                    <th>Contact Name</th>
                    <th>Closing Date</th>
                    <th>Probability</th>

                    <th>Owned By</th>
                    <th>Action</th>

                  </tr>
                </thead>
                <tbody>

                  <tr *ngFor="let o of OpportunityActualList, let i = index">
                    <!-- <td scope="row">{{i+1}}</td> -->
                    <td>{{o.OppertunityTitle }}</td>
                    <td>{{o.PipeLineStatus}}</td>
                    <td>{{o.CompanyName}}</td>
                    <td>{{o.ContactName }}</td>
                    <td>{{o.CloseDateval}}</td>
                    <td>{{o.Probability}}</td>
                    <td>{{o.EmployeeName}}</td>

                    <td>
                      <button type="button" (click)="GetCRMOppertunityById( o.OppertunityId )" class="table-edit-btn">
                        <i class="fas fa-edit"></i>
                      </button>

                      <button type="button" (click)="DeleteCrmOppertunity( o.OppertunityId )" class="table-remove-btn">
                        <i class="fas fa-trash-alt"></i>
                      </button>

                      <button type="button" (click)="ViewCRMOppertunityById( o.OppertunityId )"
                        class="table-view-button" type="button">
                        <i class="fas fa-eye"></i>
                      </button>
                    </td>


                  </tr>


                </tbody>
              </table>
            </div>
            <nav aria-label="Page navigation example mb-2">
              <!-- pager -->
              <ul *ngIf="pager.pages && pager.pages.length" class="pagination ">
                <li [ngClass]="{disabled:pager.currentPage === 1}" class="page-item">
                  <a (click)="GetCRMOppertunityFrompage(1)" class="page-link">First </a>
                </li>
                <li [ngClass]="{disabled:pager.currentPage === 1}" class="page-item">
                  <a (click)="GetCRMOppertunityFrompage(pager.currentPage - 1)" class="page-link">Previous</a>
                </li>
                <li *ngFor="let page of pager.pages" [ngClass]="{active:pager.currentPage === page}" class="page-item">
                  <a (click)="GetCRMOppertunityFrompage(page)" class="page-link">{{page}}</a>
                </li>
                <li [ngClass]="{disabled:pager.currentPage === pager.totalPages}" class="page-item">
                  <a (click)="GetCRMOppertunityFrompage(pager.currentPage + 1)" class="page-link">Next</a>
                </li>
                <li [ngClass]="{active1:pager.currentPage === pager.totalPages}" class="page-item">
                  <a (click)="GetCRMOppertunityFrompage(pager.totalPages)" class="page-link">Last</a>
                </li>
              </ul>
            </nav>
          </div>
        </form>
      </fieldset>
    </div>
  </div>
  <!-- </perfect-scrollbar> -->

  <div class="row">
    <div class="col-md-12">
      <fieldset>
        <legend class="legend--"></legend>
        <section>
          <div class="tabs tabs-style-tzoid mt-4">
            <nav>
              <ul>
                <li [ngClass]="{'tab-current':  OpportunitySelectedCrmIndexPage == 0}"
                  (click)="CRMSubPages(0,'ManageMICEAccess/CrmOppertunity/OportunityDetails')"><a
                    class="icon icon-home"><span>Opportunity Details</span></a></li>
                <li [ngClass]="{'tab-current':  OpportunitySelectedCrmIndexPage == 1}"
                  (click)="CRMSubPages(1,'ManageMICEAccess/CrmOppertunity/OportunityMoreInfo')"> <a
                    class="icon icon-box"><span>Custom Info </span></a></li>
                <li [ngClass]="{'tab-current':  OpportunitySelectedCrmIndexPage == 2}"
                  (click)="CRMSubPages(2,'ManageMICEAccess/CrmOppertunity/FollowUp')"> <a
                    class="icon icon-box"><span>More
                      Info </span></a></li>
                <!--<li [ngClass]="{'tab-current':  this.CRM_LeadService.SelectedCrmIndexPage == 2}"
              (click)="CrmContacts(2,'ManageMICEAccess/ManageLead/Contacts')"><a
                class="icon icon-upload"><span>Contacts</span></a></li>
            <li [ngClass]="{'tab-current':  this.CRM_LeadService.SelectedCrmIndexPage == 3}"
              (click)="CrmContacts(3,'ManageMICEAccess/ManageLead/AccountInfo')"><a
                class="icon icon-upload"><span>AccoumtInfo</span></a></li> -->
                <!-- <li [ngClass]="{'tab-current':  this.CRM_LeadService.SelectedCrmIndexPage == 3}" (click)="EmployeeSubPages(3,'Employee/EmployeeDetails/EducationalDetails')" ><a  class="icon icon-display"><span> Qualification</span></a></li>
                              <li [ngClass]="{'tab-current':  this.CRM_LeadService.SelectedCrmIndexPage == 4}" (click)="EmployeeSubPages(4,'Employee/EmployeeDetails/ExpriencDetails')" ><a  class="icon icon-tools"><span>Experianced Details</span></a></li>
                              <li [ngClass]="{'tab-current':  this.CRM_LeadService.SelectedCrmIndexPage == 5}" (click)="EmployeeSubPages(5,'Employee/EmployeeDetails/FamilyDetails')" ><a  class="icon icon-tools"><span>Family Details</span></a></li>
                              <li [ngClass]="{'tab-current':  this.CRM_LeadService.SelectedCrmIndexPage == 6}" (click)="EmployeeSubPages(6,'Employee/EmployeeDetails/NomineeDetails')" ><a  class="icon icon-tools"><span>Nomine Details</span></a></li> -->
              </ul>
            </nav>
            <div class="content-wrap">
              <router-outlet (activate)="onActivate($event)">
              </router-outlet>
            </div><!-- /content -->
          </div><!-- /tabs -->
        </section>
      </fieldset>
    </div>
  </div>
</div>










<!-- </perfect-scrollbar> -->
<div style=" box-align: center; width: 1000px;">


  <ng-template #templateShareLead>

    <div class="modal-header" class="text-white" style="background: #2a9bd7; max-width: 1000px;">
      <h2 class="modal-title pull-left">Shared Opportunity</h2>
      <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()" style="color:#fff;">
        <span aria-hidden="true">X</span>
      </button>
    </div>


    <fieldset class="body-form-control">


      <form #ApprovedApplicationForm="ngForm">
        <div class="row">

          <div class="col-lg-12">
            <div class="form-group row">

              <label class="col-sm-4">Select Opportunity</label>

              <div class="col-sm-4">

                <angular2-multiselect tabindex="5" class="form-control" [data]="OpportunityArray"
                  [(ngModel)]="SelectedOpportunity" name="OpportunityId" [settings]="this.OpportunitySettings"
                  (onSelect)="onItemSelectOpportunity($event)" (onDeSelect)="OnItemDeSelectOpportunity($event)"
                  (onSelectAll)="onSelectAllOpportunity($event)" (onDeSelectAll)="onDeSelectAllOpportunity($event)">
                </angular2-multiselect>

              </div>
            </div>
            <div class="form-group row">
              <label class="col-sm-4 col-form-label clear-padding-right">Assigne To
              </label>
              <div class="col-sm-4 clear-left-padding">

                <angular2-multiselect tabindex="5" class="form-control" [data]="AssigneArray"
                  [(ngModel)]="SelectAssigneList" name="AssignedId" [settings]="AssigneSettings"
                  (onSelect)="onItemSelectAssigneTo($event)" (onDeSelect)="OnItemDeSelectAssigneTo($event)"
                  (onSelectAll)="onSelectAllAssigneTo($event)" (onDeSelectAll)="onDeSelectAllAssigneTo($event)">
                </angular2-multiselect>

              </div>
            </div>
            <div class="form-group row">
              <div class="col-sm-12 text-right">
                <button type="button" aria-label="Close" (click)="SaveCrmOpportunityShared()" tabindex="10"
                  style="background: #2a9bd7; outline: 0px none; border: 0px none; color: #fff; padding: 5px 10px; border-radius: 3px;">
                  <span>Save</span>
                </button>
              </div>
            </div>

          </div>
        </div>
      </form>
    </fieldset>


    <!-- <button type="button" aria-label="Close" (click)="modalRef.hide()" style="background: #2a9bd7; outline: 0px none; border: 0px none; color: #fff; padding: 5px 10px; border-radius: 3px;">
        <span >OK</span>
      </button>             
     -->

  </ng-template>
</div>