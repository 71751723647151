import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from '../../shared/user.service';
import { EmployeePortalService }  from '../../shared/HR_ADMIN/EmployeePortal/EmployeePortalService.service';
import { DirectDuty } from '../../shared/HR_ADMIN/EmployeePortal/DirectDuty.Model';
import { Menu } from '../../shared/menu.model';
import { ModalOptions,BsModalRef, BsModalService } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-employee-direct-duty-status',
  templateUrl: './employee-direct-duty-status.component.html',
  styleUrls: ['./employee-direct-duty-status.component.css']
})
export class EmployeeDirectDutyStatusComponent implements OnInit 
{
  DirectDutyModel : DirectDuty= new DirectDuty();
  DirectDutyPending : DirectDuty[]=[];
  DirectDutyApproved : DirectDuty[]=[];
  DirectDutyRejected : DirectDuty[]=[];
  DirectDuty : any;
  userClaims : any;
  employee : any;
  Result : string;
  SuccessStatus :boolean=false;
  SuccessMessage : string ="";
  public tableWidget : any;
  selectedIndex : number;
  // Begning Permission Portion    
  SelectedMenu : Menu;
  // End of the permission portion
  //For Pop up
  CancelMsg : boolean= false;
  ShowPopup : boolean= false;
  public modalRef: BsModalRef;
  public modelopt:ModalOptions;
  ModelListApplication: DirectDuty;
  Approver: any;
  RefId: any;
  //New
  PendingMsg : boolean= false;
  ApprovedMsg : boolean= false;
  RejectedMsg : boolean= false;
  validdate : boolean= false;
  validTodate : boolean= false;
  DisableSave : boolean;

  constructor(private EmployeePortalService: EmployeePortalService, private rout: Router, private userService: UserService, private modalService: BsModalService)
  {
    this.ModelListApplication = new DirectDuty();
    this.ModelListApplication.IsFullDayDirectDuty=false;
    this.ModelListApplication.IsMorningDirectduty=false;
    this.ModelListApplication.IsSecondHalfDirectDuty=false;
    this.modelopt=new ModalOptions();
    this.modelopt.class='heavy_modal';
    this.modalService.config= this.modelopt;
    this.modelopt.ignoreBackdropClick=true;
  }

  ngOnInit() 
  {
    // Begning Permission Portion 
    this.SelectedMenu=this.userService.SelectedMenu;
    if(this.SelectedMenu !=undefined)
    {
      localStorage.setItem('selectedmenu',JSON.stringify(this.SelectedMenu));
      this.rout.navigate([this.SelectedMenu.RouteName]);
    }
    else
    {
      if(JSON.parse(localStorage.getItem('selectedmenu'))!=undefined)
      {
        this.SelectedMenu = JSON.parse(localStorage.getItem('selectedmenu'));
        this.rout.navigate([this.SelectedMenu.RouteName]);
      }
    }
    // End of the permission portion
    this.GetEmployeeDetails();
    this.DisableSave=true;
    this.ModelListApplication.Status=0;
    this.validdate=false;
    this.validTodate=false;
    this.DisableSave=true;
  }

  GetEmployeeDetails()
  {
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.EmployeePortalService.GetEmployeeDetailsByEmployeeId( t).then(x => 
    {
      this.employee = x;
      Object.keys(this.employee).forEach( key => 
      {
        this.employee[key];
        this.DirectDutyModel.EmployeeCode=this.employee.EmployeeCode;
        this.DirectDutyModel.EmployeeName=this.employee.EmployeeName;
      });
    });
  }

  GetEmployeeDirectDutyPendingDetails(FromDate,ToDate)
  {
    this.DirectDutyPending=[];
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.EmployeePortalService.GetDirectDutyStatusPending( t,FromDate,ToDate).then(x => 
    {
      this.DirectDuty = x;
      Object.keys(this.DirectDuty).forEach( key => 
      {
        this.DirectDuty[key];
        let DirectDutytemp = new DirectDuty();
        DirectDutytemp = this.DirectDuty[key];
        this.DirectDutyPending.push(DirectDutytemp);
      });
      if(this.DirectDutyPending.length == 0)
      {
        this.PendingMsg = true;
        this.ApprovedMsg = false;
        this.RejectedMsg = false;
      }
    });
  }

  GetEmployeeDirectDutyApprovedDetails(FromDate,ToDate)
  {
    this.DirectDutyApproved=[];
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.EmployeePortalService.GetDirectDutyStatusApproved( t,FromDate,ToDate).then(x => 
    {
      this.DirectDuty = x;
      Object.keys(this.DirectDuty).forEach( key => 
      {
        this.DirectDuty[key];
        let DirectDutytemp = new DirectDuty();
        DirectDutytemp = this.DirectDuty[key];
        this.DirectDutyApproved.push(DirectDutytemp);
      });
      if(this.DirectDutyApproved.length == 0)
      {
        this.PendingMsg = false;
        this.ApprovedMsg = true;
        this.RejectedMsg = false;
      }
    });
  }

  GetEmployeeDirectDutyRejectedDetails(FromDate,ToDate)
  {
    this.DirectDutyRejected=[];
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.EmployeePortalService.GetDirectDutyStatusRejected( t,FromDate,ToDate).then(x => 
    {
      this.DirectDuty = x;
      Object.keys(this.DirectDuty).forEach( key => 
      {
        this.DirectDuty[key];
        let DirectDutytemp = new DirectDuty();
        DirectDutytemp = this.DirectDuty[key];
        this.DirectDutyRejected.push(DirectDutytemp);
      });
      if(this.DirectDutyRejected.length == 0)
      {
        this.PendingMsg = false;
        this.ApprovedMsg = false;
        this.RejectedMsg = true;
      }
    });
  }

  viewAppliedDirectDuty(id,template) 
  {
    this.DisableSave=true;
    this.ModelListApplication.Remarks="";
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.EmployeePortalService.GetEmployeeDirectDutyForApprovalById( t,id).then(x => 
    {
      if(this.ShowPopup==true)
      {
        this.modalRef.hide();
      }
      this.modalRef = this.modalService.show(template,this.modelopt);
      this.ShowPopup = true;
      this.CancelMsg = false;
      this.Approver = x;
      Object.keys(this.Approver).forEach( key => 
      {
        // tslint:disable-next-line:no-unused-expression
        this.Approver[key];
        let Approvetemp = new DirectDuty();
        Approvetemp = this.Approver[key];
        this.ModelListApplication.DirectId=Approvetemp.DirectId;
        this.ModelListApplication.EmpId=Approvetemp.EmpId;
        this.ModelListApplication.EmployeeCode=Approvetemp.EmployeeCode;
        this.ModelListApplication.EmployeeName=Approvetemp.EmployeeName; 
        this.ModelListApplication.EmployeeEmailId=Approvetemp.EmployeeEmailId; 
        this.ModelListApplication.RHCode=Approvetemp.RHCode;
        this.ModelListApplication.RHName=Approvetemp.RHName; 
        this.ModelListApplication.RHEmailId=Approvetemp.RHEmailId; 
        this.ModelListApplication.DateOfDirectDuty=Approvetemp.DateOfDirectDuty; 
        this.ModelListApplication.IsFullDayDirectDuty=Approvetemp.IsFullDayDirectDuty;
        if(Approvetemp.IsFullDayDirectDuty==true)
        {
          this.ModelListApplication.Fullday="Fullday";
          this.ModelListApplication.FirstHalf="";
          this.ModelListApplication.SecondHalf="";
        }
        this.ModelListApplication.IsMorningDirectduty=Approvetemp.IsMorningDirectduty;
        if(Approvetemp.IsMorningDirectduty==true)
        {
          this.ModelListApplication.FirstHalf="FirstHalf";
          this.ModelListApplication.Fullday="";
          this.ModelListApplication.SecondHalf="";
        }
        this.ModelListApplication.IsSecondHalfDirectDuty=Approvetemp.IsSecondHalfDirectDuty;
        if(Approvetemp.IsSecondHalfDirectDuty==true)
        {
          this.ModelListApplication.SecondHalf="SecondHalf";
          this.ModelListApplication.Fullday="";
          this.ModelListApplication.FirstHalf="";
        }
        this.ModelListApplication.Place=Approvetemp.Place; 
        this.ModelListApplication.Reason=Approvetemp.Reason; 
        this.ModelListApplication.CompanyId=Approvetemp.CompanyId;
      });
      // End for check company code duplication
    });
  }
  
  DirectDutyCancel(template) :void
  {
    if(confirm("Are you sure to Cancel "))
    {
      this.DisableSave=false;
      let t =  localStorage.getItem('userToken');
      t = 'Bearer' + t;
      if(this.ModelListApplication.DirectId > 0)
      {
        this.ModelListApplication.IsRejected=true;
        this.EmployeePortalService.CancelEmployeeDirectDuty(t ,this.ModelListApplication).subscribe(res=>
        {
          this.RefId=res;
          this.ModelListApplication.DirectId=0;  
          if(this.RefId>0)
          {
            this.ModelListApplication.Remarks="";
            this.modalRef.hide();
            this.CancelMsg=true;
            this.ShowPopup=false;
            this.modalRef = this.modalService.show(template);
            this.DisableSave=true;
          }
        });                  
      }
    }
  }

  SearchFromDateChanged( date)
  {
    this.DisableSave = true;
    this.PendingMsg = false;
    this.ApprovedMsg = false;
    this.RejectedMsg = false;
    this.DirectDutyPending=[];
    this.DirectDutyApproved=[];
    this.DirectDutyRejected=[];
    
    this.ModelListApplication.SearchFromDate=date;
  }

  SearchToDateChanged( date)
  {
    this.DisableSave = true;
    this.PendingMsg = false;
    this.ApprovedMsg = false;
    this.RejectedMsg = false;
    this.DirectDutyPending=[];
    this.DirectDutyApproved=[];
    this.DirectDutyRejected=[];

    this.ModelListApplication.SearchToDate=date;
  }

  StatusChanged()
  {
    this.DisableSave = true;
    this.PendingMsg = false;
    this.ApprovedMsg = false;
    this.RejectedMsg = false;
    this.DirectDutyPending=[];
    this.DirectDutyApproved=[];
    this.DirectDutyRejected=[];
  }

  submitClick()
  {
    this.DisableSave = false;
    this.validdate = false;
    this.validTodate=false;
    this.PendingMsg = false;
    this.ApprovedMsg = false;
    this.RejectedMsg = false;
    this.DirectDutyPending=[];
    this.DirectDutyApproved=[];
    this.DirectDutyRejected=[];
    if(this.ModelListApplication.SearchFromDate!=null && this.ModelListApplication.SearchToDate!=null && this.ModelListApplication.Status > 0)
    {
      if(this.ModelListApplication.SearchToDate >= this.ModelListApplication.SearchFromDate)
      {
        if(this.ModelListApplication.Status == 1)
        {
          this.GetEmployeeDirectDutyPendingDetails(this.ModelListApplication.SearchFromDate, this.ModelListApplication.SearchToDate);
        }
        else if(this.ModelListApplication.Status == 2)
        {
          this.GetEmployeeDirectDutyApprovedDetails(this.ModelListApplication.SearchFromDate, this.ModelListApplication.SearchToDate);
        }
        else
        {
          this.GetEmployeeDirectDutyRejectedDetails(this.ModelListApplication.SearchFromDate, this.ModelListApplication.SearchToDate);
        }
      }
      else
      {
        this.validTodate = true;
      }
    }
  }
}