/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./mail-to-client.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./mail-to-client.component";
var styles_MailToClientComponent = [i0.styles];
var RenderType_MailToClientComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_MailToClientComponent, data: {} });
export { RenderType_MailToClientComponent as RenderType_MailToClientComponent };
export function View_MailToClientComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["mail-to-client works!"]))], null, null); }
export function View_MailToClientComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-mail-to-client", [], null, null, null, View_MailToClientComponent_0, RenderType_MailToClientComponent)), i1.ɵdid(1, 114688, null, 0, i2.MailToClientComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var MailToClientComponentNgFactory = i1.ɵccf("app-mail-to-client", i2.MailToClientComponent, View_MailToClientComponent_Host_0, {}, {}, []);
export { MailToClientComponentNgFactory as MailToClientComponentNgFactory };
