<div class="body-content-area tabcontent" id="link1">
    <div class="row">
        <div class="col-lg-9">
            <div class="row">
                <div class="col-lg-1 low-padding text-center">
                    <!-- <img *ngIf="this.SelectedMenu.Image!=null" src={{this.SelectedMenu.Image}} class="body-icon" alt="Icon"/> -->
                </div>
                <div class="col-lg-10 clear-padding">
                    <div>
                    <span class="page-title">Employee Bank Details</span>
                    <small class="page-description"></small>
                    </div>
                </div>
            </div>
        </div>
    </div>
    
    <div *ngIf="!(this.CanViewState)">
        <app-dialog [(visible)]="this.SuccessStatus">
            <span class="successmsg">{{this.SuccessMessage}}</span>
        </app-dialog>
        <table id="tblEmployeeBank" datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" class="table table-striped table-bordered text-center table-new row-border hover">
            <thead class="thead-blue text-center">
                <tr>
                    <th>Sl No.</th>
                    <th>Code</th>
                    <th class="text-left">Name</th>
                    <th class="text-left">Bank</th>
                    <th>A/C No</th>
                    <th class="text-left">Branch</th>
                    <th>Action</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let empb of EmployeeBanks, let i = index">
                    <td scope="row">{{i+1}}</td>
                    <td>{{empb.EmployeeCode }}</td>
                    <td class="text-left">{{empb.EmployeeName}}</td>
                    <td class="text-left">{{empb.BankName}}</td>
                    <td>{{empb.AccountNumber}}</td>
                    <td class="text-left">{{empb.BranchName}}</td>
                    <td>
                        <button *ngIf="this.SelectedMenu.CanEdit" type="button" (click)="GetEmployeeBankDetailsByid(empb.RefId)" class="table-edit-btn"><i class="fas fa-edit"></i> </button> 
                        <button *ngIf="this.SelectedMenu.CanDelete" type="button" (click)="DeleteEmployeeBankDetailsByid(empb.RefId )" class="table-remove-btn"><i class="fas fa-trash-alt"></i>  </button>
                        <button *ngIf="this.SelectedMenu.CanView" type="button" (click)="viewEmployeeBankDetailsByid( empb.RefId)" class="table-view-button" type="button" ><i class="fas fa-eye"></i></button>
                    </td>
                </tr>
            </tbody>
        </table>

        <fieldset class="body-form-control" *ngIf="(this.IsFormView)">
            <legend class="legend--">Employee Bank Details</legend>
            <form #EmployeeBankForm="ngForm" (ngSubmit)="saveEmployeeBankDetails()">
                <div class="row">
                    <div class="col-lg-4">
                        <div class="col-body-border-right">
                            <div class="form-group row">
                                <label  class="col-sm-4 col-form-label clear-padding-right">Code</label> 
                                <div class="col-sm-7 clear-left-padding">
                                    <input type="text" [(ngModel)]="EmpBankModel.EmployeeCode" disabled="disabled" id ="EmployeeCode" name="EmployeeCode" class="form-control form-control-custom form-control-custom-new" >
                                </div>
                            </div>
                            <div class="form-group row">
                                <label  class="col-sm-4 col-form-label clear-padding-right">Bank</label>
                                <div class="col-sm-7 clear-left-padding">
                                    <select [(ngModel)]="EmpBankModel.BankId" tabindex="2" (ngModelChange)="onChangeBank($event)" id ="BankId" class="form-control"  name="BankId" #BankId="ngModel" >
                                        <option [value]="0" [disabled]=true >Select Bank</option>
                                        <option *ngFor="let bank of BankMasters" [value]="bank.BankId">{{bank.BankName}}</option>
                                    </select>
                                </div>
                                <div class="required-validation" *ngIf="EmpBankModel.BankId==0">Bank is required</div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4">
                        <div class="col-body-border-right">
                            <div class="form-group row">
                                <label  class="col-sm-4 col-form-label clear-padding-right">Name</label>
                                <div class="col-sm-7 clear-left-padding">
                                    <input type="text" [(ngModel)]="EmpBankModel.EmployeeName" disabled="disabled" id ="EmployeeName" name="EmployeeName" class="form-control form-control-custom form-control-custom-new" >
                                    <input type="text" hidden="hidden" [(ngModel)]="EmpBankModel.OldBankId" id ="OldBankId" name="OldBankId" #OldBankId="ngModel">
                                    <input type="text" hidden="hidden" [(ngModel)]="EmpBankModel.EmployeeId" id ="EmployeeId" name="EmployeeId" #EmployeeId="ngModel">
                                </div>
                            </div>
                            <div class="form-group row">
                                <label  class="col-sm-4 col-form-label clear-padding-right">Branch Name</label>
                                <div class="col-sm-7 clear-left-padding">
                                    <input type="text" [(ngModel)]="EmpBankModel.BranchName" id ="BranchName"  name="BranchName" #BranchName="ngModel" required class="form-control form-control-custom form-control-custom-new" >
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4">
                        <div class="form-group row">
                            <label  class="col-sm-4 col-form-label clear-padding-right">Account No</label>
                            <div class="col-sm-7 clear-left-padding">
                                <input type="text" tabindex="1" [(ngModel)]="EmpBankModel.AccountNumber" minlength="10" maxlength="50" pattern="[0-9]+" id ="AccountNumber" name="AccountNumber" #AccountNumber="ngModel" required class="form-control form-control-custom form-control-custom-new" >
                            </div>
                            <div *ngIf="AccountNumber.invalid && (AccountNumber.dirty || AccountNumber.touched)" class="required-validation">
                                <div *ngIf="AccountNumber.errors.minlength">Minimum 10 characters</div>
                                <div *ngIf="AccountNumber.errors?.pattern">Account Number should be valid.</div>
                            </div>
                        </div>
                        <div class="form-group row">
                                <label  class="col-sm-4 col-form-label clear-padding-right">IFSC Code</label>
                                <div class="col-sm-7 clear-left-padding">
                                    <input type="text" [(ngModel)]="EmpBankModel.IFSCCode" id ="IFSCCode" name="IFSCCode" #IFSCCode="ngModel" required class="form-control form-control-custom form-control-custom-new" >
                                </div>
                            </div>
                        <div class="form-group row">
                            <label  class="col-sm-6 col-form-label text-center clear-padding-right" *ngIf="!(this.IsDataIsInViewState)">
                                <button  type="submit" tabindex="3" class="btn btn-primary save-button" [disabled]="!(EmployeeBankForm.valid && EmpBankModel.EmployeeId!=0 && EmpBankModel.BankId !=0 && this.DisableSave==true)" >Save</button>  
                            </label>
                            <div class="col-sm-6 col-form-label text-center">
                            <button  type="button" tabindex="4" class="btn btn-danger cancle-button" (click)="RefreshPage()">Cancel</button></div>
                        </div>
                    </div>
                </div>
            </form>
        </fieldset>
    </div>
</div>