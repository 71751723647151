export class SalaryComponent 
{
    public ComponentId : number;
    public ComponentCode : string;
    public ComponentName :string;
    public IsBenefit : boolean;
    public IsLongTermBenefit : boolean;
    public IsActive : boolean;
    public OldComponentCode : string;
    public IsTaxable : boolean;
    public IsExemptedFull : boolean;
    public ExemptedAmount : number;

    public Value : string;
}