import { Component, OnInit, ViewChild,TemplateRef } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs/Subject';
import { DataTableDirective } from 'angular-datatables';
import { UserService } from '../../shared/user.service';
import { OrganizationService }  from '../../shared/Organization/organization.service';
import { LocationService }  from '../../shared/HR_ADMIN/Location/location.service';
import { LevelService }  from '../../shared/HR_ADMIN/Lavels_Grades/Levels.service';
import { EmployeeMasterService }  from '../../shared/HR_ADMIN/Employee/EmployeeMaster.Service';
import { SalaryMasterService }  from '../../shared/HR_ADMIN/Salary/SalaryMaster.service';
import { OfferDetails } from '../../shared/HR_ADMIN/Employee/OfferDetails.Model';
import { Company } from '../../shared/Organization/Company.Model';
import { Branch } from  '../../shared/Organization/Branch.Model';
import { Department } from  '../../shared/Organization/Department.Model';
import { Country } from '../../shared/Organization/Country.Model';
import { State } from '../../shared/Organization/State.Model';
import { District } from '../../shared/HR_ADMIN/Location/District.Model';
import { City } from '../../shared/HR_ADMIN/Location/City.Model';
import { DecisionMaking } from '../../shared/HR_ADMIN/Lavels_Grades/DecisionMaking.Model';
import { OfferStandardSalary }  from '../../shared/HR_ADMIN/Salary/OfferStandardSalary';
import { Level } from '../../shared/HR_ADMIN/Lavels_Grades/Level.Model';
import { Grade } from '../../shared/HR_ADMIN/Lavels_Grades/Grade.Model';
import { Designation } from '../../shared/HR_ADMIN/Lavels_Grades/Designation.Model';
import { PtaxSlabModel } from '../../shared/HR_ADMIN/Salary/PtaxSlabModel'
import { EPFESIModel } from '../../shared/HR_ADMIN/Admin/EPFESIModel.Model'
import { RegionForHR } from  '../../shared/Organization/RegionForHRModel.Model';
// Begning Confirmation
import { ConfirmationService } from '@jaspero/ng2-confirmations';
import { ResolveEmit } from '../../../app/resolve-emit';
import { ConfirmSettings } from '../../../app/confirm-settings';
// end of Confirmation
import { Menu } from '../../shared/menu.model';
//Begning boot strap
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { SalaryRule } from '../../shared/HR_ADMIN/Salary/SalaryRuleModel';
//End of boot strap

@Component({
  selector: 'app-employee-offer',
  templateUrl: './employee-offer.component.html',
  styleUrls: ['./employee-offer.component.css']
})

export class EmployeeOfferComponent implements OnInit 
{
  OfferModel : OfferDetails= new OfferDetails();
  OfferedSalaryTemp : OfferStandardSalary = new OfferStandardSalary();
  Offers : OfferDetails[]=[];
  ComponentTotal : number;
  ComponentSubTotal : number;
  Longtermbenefits : number;
  TotalOffer : any;
  Offer : any;
  userClaims: any;
  SalOffer: any;
  SubMenues : Menu[]=[];
  Mod : any;
  //Organisation
  Companies : Company[]=[];
  Company : any;
  Branches: Branch[] = [];
  Branch: any;
  Departments: Department[] = [];
  Department: any;
  //Location
  Countries : Country[]=[];
  country : any;
  States : State[]=[];
  State : any;
  Districts : District[]=[];
  District : any;
  Cities : City[]=[];
  City : any;
  //Grade & Level
  DecisionMakings : DecisionMaking[]=[];
  DecisionMaking : any;
  Levels: Level[] = [];
  Level: any;
  Grades: Grade[] = [];
  Grade: any;
  Designations : Designation[]=[];
  Designation : any;
  
  //RegionForHR
  RegionForHR: RegionForHR[];
  RegionHR : any;

  Result : string;
  SuccessStatus:boolean=false;
  SuccessMessage: string ="";
  public tableWidget: any;
  OfferId : any;

  tempOfferadd : OfferDetails =new OfferDetails();
  tempPtaxSlabModel : PtaxSlabModel = new PtaxSlabModel();
  tempPtaxSlabModels : any;
  
  tempEPFESIModel : EPFESIModel = new EPFESIModel();
  tempEPFESIModels : any;
  
  PFDed :number=0;
  PFCont :number=0;
  ESIDed :number=0;
  ESICont :number=0;
  P_Tax : number = 0;
  MinBasicSalary : number=0;
  BranchStateId : number=0;
  // Begning Permission Portion    
  SelectedMenu:Menu;
  IsDataIsInViewState : boolean=false;
  CanViewState : boolean=true;
  SalaryRuleId : any;
  PFAmount : number;
  // End of the permission portion

  // begin for Validation
  validdate : boolean= false;
  IsFormViewInViewState : boolean = false;
  IsButtonIsInViewState : boolean = false;
  DisableSave : boolean =false;
  // end of validation

  //for popup
  public modalRef: BsModalRef;
  OfferedSalary: OfferStandardSalary[] = [];
  SalaryComponentRules : SalaryRule[] =[];
  //for popup
  NoRecordMsg : boolean;

  constructor(private organizationService: OrganizationService, private LocationService: LocationService,private LevelService: LevelService,
  private EmployeeMasterService: EmployeeMasterService, private rout: Router, private _confirmation: ConfirmationService,  private userService: UserService,
  private modalService: BsModalService, private EmpSalary: SalaryMasterService )
  { }

  @ViewChild(DataTableDirective, {static: false})
  setDtOptions: DataTables.Settings = {};
  cardDtOptions: DataTables.Settings = {};
  dtElement: DataTableDirective;
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();

  ngOnInit() 
  {
    // Begning Permission Portion 
    this.SubMenues=[];
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    let menuid = localStorage.getItem("menuidstor");
    this.userService.GetSubMenuList( t, menuid).then(x => 
    {
      this.Mod = x;
      Object.keys(this.Mod).forEach( key => 
      {
        // tslint:disable-next-line:no-unused-expression
        this.Mod[key];
        let menutemp = new Menu();
        menutemp = this.Mod[key];
        this.SubMenues.push(menutemp);
      });
      this.userService.SelectedMenu= this.SubMenues[0];
      this.rout.navigate([this.userService.SelectedMenu.RouteName]);
      this.SelectedMenu=this.userService.SelectedMenu;
      if(this.SelectedMenu.CanAdd)
      {
        this.IsDataIsInViewState=false;
      }
      else
      {
        this.IsDataIsInViewState=true;
      }
      if(this.SelectedMenu.CanView)
      {
        this.CanViewState=false;
      }
      else
      {
        this.CanViewState=true;
      }
      this.OfferModel.EPFApplicable=false;
      this.OfferModel.ESIApplicable=false;
      this.OfferModel.PTAXApplicable=false;
      this.OfferModel.OfferId=0;
    });
    // End of the permission portion
    this.getCompanies();
    this.getCountries();
    this.getDecisionMaking();
    this.GetEpfEsi();
    this.GetPayZone();
    this.GetOfferLetters();
    this.dtOptions = 
    {
      pagingType: 'full_numbers',
      pageLength: 2
    };
    this.OfferModel.CountryId=0;
    this.OfferModel.CompanyId=0;
    this.OfferModel.DecisionId=0;
    this.OfferModel.RegionHRId=0;
    this.BranchStateId=0;
    this.IsFormViewInViewState=false;
    this.IsButtonIsInViewState=true;
    if(localStorage.getItem('SuccessfullMessage'))
    {
      this.SuccessStatus=true;
      this.SuccessMessage =localStorage.getItem('SuccessfullMessage')
      localStorage.removeItem('SuccessfullMessage');
    }
    this.DisableSave=true;
    this.NoRecordMsg = false;
  }

  settings: ConfirmSettings | any = 
  {
    overlay: true,
    overlayClickToClose: true,
    showCloseButton: true,
    confirmText: 'Yes',
    declineText: 'No'
  };

  ADDClick()
  {
    this.IsButtonIsInViewState = false;
    this.IsFormViewInViewState = true;
    this.NoRecordMsg = false;
    this.clearData();
  }

  ngAfterViewInit(): void 
  {
    this.dtOptions = 
    {
      pagingType: 'full_numbers',
      pageLength: 2
    };
  }

  GetOfferLetters()
  {
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.EmployeeMasterService.GetOfferDetails( t).then(x => 
    {
      this.NoRecordMsg = false;
      this.Offer = x;
      Object.keys(this.Offer).forEach( key => 
      {
        this.Offer[key];
        let Offertemp = new OfferDetails();
        Offertemp = this.Offer[key];
        this.Offers.push(Offertemp);
      });
      this.dtTrigger.next();
      if(this.Offers.length == 0)
      {
        this.NoRecordMsg = true;
      }
    });
  }

  GetOfferDetailsByid(id) 
  {
     // begning of the permission portion
    this.IsFormViewInViewState = true;
    this.IsDataIsInViewState=false;
    this.validdate=true;
     // end of the permission portion
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.EmployeeMasterService.GetOfferDetailsByid( t,id).then(x => 
    {
      this.Offer = x;
      Object.keys(this.Offer).forEach( key => 
      {
        this.Offer[key];
        let Offertemp = new OfferDetails();
        Offertemp = this.Offer[key];
        this.OfferModel.OfferId=Offertemp.OfferId;
        this.OfferModel.OfferDate=Offertemp.OfferDate;
        this.OfferModel.FirstName=Offertemp.FirstName;
        this.OfferModel.MiddleName=Offertemp.MiddleName;
        this.OfferModel.LastName=Offertemp.LastName;          
        this.OfferModel.Address1=Offertemp.Address1;
        this.OfferModel.Address2=Offertemp.Address2;
        this.OfferModel.DistrictId=Offertemp.DistrictId;
        this.OfferModel.CountryId=Offertemp.CountryId;
        this.OfferModel.StateId=Offertemp.StateId;
        this.OfferModel.CityId=Offertemp.CityId;
        this.OfferModel.PinCode=Offertemp.PinCode;
        this.OfferModel.ContactNumber=Offertemp.ContactNumber;
        this.OfferModel.EmailId=Offertemp.EmailId;
        this.OfferModel.CompanyId=Offertemp.CompanyId;
        this.OfferModel.BranchId=Offertemp.BranchId;
        this.OfferModel.DepartmentId=Offertemp.DepartmentId;
        this.OfferModel.DecisionId=Offertemp.DecisionId;
        this.OfferModel.LevelId=Offertemp.LevelId;
        this.OfferModel.GradeId=Offertemp.GradeId;
        this.OfferModel.DesignationId=Offertemp.DesignationId;

        let dateString : string = Offertemp.joiningDate.toString();
        let days : number = parseInt(dateString.substring(8, 10));
        let months : number = parseInt(dateString.substring(5, 7));
        let years : number = parseInt(dateString.substring(0, 5));
        let goodDate : Date = new Date(months + "/" + days + "/" + years);
        goodDate.setDate(goodDate.getDate() );

        this.OfferModel.joiningDate=goodDate;
        this.OfferModel.Approved=Offertemp.Approved;
        this.OfferModel.CTCAmount=Offertemp.CTCAmount;
        this.OfferModel.NetTakeHome=Offertemp.NetTakeHome;
        this.OfferModel.SerialNumber=Offertemp.SerialNumber;
        this.OfferModel.IsActive=Offertemp.IsActive;

        this.OfferModel.GrossSalary=Offertemp.GrossSalary;
        this.OfferModel.EPFApplicable=Offertemp.EPFApplicable;
        this.OfferModel.ESIApplicable=Offertemp.ESIApplicable;
        this.OfferModel.PTAXApplicable=Offertemp.PTAXApplicable;
        this.OfferModel.RegionHRId=Offertemp.RegionHRId;
      });
      this.getStates(this.OfferModel.CountryId);
      this.getDistrict(this.OfferModel.StateId);
      this.getCities(this.OfferModel.DistrictId);
      this.getBranches(this.OfferModel.CompanyId);
      this.GetBranchStateId(this.OfferModel.BranchId);
      this.getDepartments(this.OfferModel.BranchId); 
      this.getLevel(this.OfferModel.DecisionId);
      this.getGrade(this.OfferModel.LevelId);
      this.getDesignation(this.OfferModel.GradeId);
      this.get_offerdSalarytails(this.OfferModel.OfferId,this.OfferModel.CompanyId,this.OfferModel.GradeId);
    });
  }

  viewOfferDetailsByid(id) 
  {
    this.IsFormViewInViewState = true;
    this.IsDataIsInViewState=true;
    this.validdate=true;
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.EmployeeMasterService.GetOfferDetailsByid( t,id).then(x => 
    {
      this.Offer = x;
      Object.keys(this.Offer).forEach( key => 
      {
        this.Offer[key];
        let Offertemp = new OfferDetails();
        Offertemp = this.Offer[key];
        this.OfferModel.OfferId=Offertemp.OfferId;
        this.OfferModel.OfferDate=Offertemp.OfferDate;
        this.OfferModel.FirstName=Offertemp.FirstName;
        this.OfferModel.MiddleName=Offertemp.MiddleName;
        this.OfferModel.LastName=Offertemp.LastName;          
        this.OfferModel.Address1=Offertemp.Address1;
        this.OfferModel.Address2=Offertemp.Address2;
        this.OfferModel.DistrictId=Offertemp.DistrictId;
        this.OfferModel.CountryId=Offertemp.CountryId;
        this.OfferModel.StateId=Offertemp.StateId;
        this.OfferModel.CityId=Offertemp.CityId;
        this.OfferModel.PinCode=Offertemp.PinCode;
        this.OfferModel.ContactNumber=Offertemp.ContactNumber;
        this.OfferModel.EmailId=Offertemp.EmailId;
        this.OfferModel.CompanyId=Offertemp.CompanyId;
        this.OfferModel.BranchId=Offertemp.BranchId;
        this.OfferModel.DepartmentId=Offertemp.DepartmentId;
        this.OfferModel.DecisionId=Offertemp.DecisionId;
        this.OfferModel.LevelId=Offertemp.LevelId;
        this.OfferModel.GradeId=Offertemp.GradeId;
        this.OfferModel.DesignationId=Offertemp.DesignationId;
        let dateString : string = Offertemp.joiningDate.toString();
        let days : number = parseInt(dateString.substring(8, 10));
        let months : number = parseInt(dateString.substring(5, 7));
        let years : number = parseInt(dateString.substring(0, 5));
        let goodDate : Date = new Date(months + "/" + days + "/" + years);
        goodDate.setDate(goodDate.getDate() );

        this.OfferModel.joiningDate=goodDate;
        this.OfferModel.Approved=Offertemp.Approved;
        this.OfferModel.CTCAmount=Offertemp.CTCAmount;
        this.OfferModel.NetTakeHome=Offertemp.NetTakeHome;
        this.OfferModel.SerialNumber=Offertemp.SerialNumber;
        this.OfferModel.IsActive=Offertemp.IsActive;
        this.OfferModel.GrossSalary=Offertemp.GrossSalary;
        this.OfferModel.EPFApplicable=Offertemp.EPFApplicable;
        this.OfferModel.ESIApplicable=Offertemp.ESIApplicable;
        this.OfferModel.PTAXApplicable=Offertemp.PTAXApplicable;
        this.OfferModel.RegionHRId=Offertemp.RegionHRId;
      });
      this.getStates(this.OfferModel.CountryId);
      this.getDistrict(this.OfferModel.StateId);
      this.getCities(this.OfferModel.DistrictId); 
      this.getBranches(this.OfferModel.CompanyId);
      this.GetBranchStateId(this.OfferModel.BranchId);
      this.getDepartments(this.OfferModel.BranchId);
      this.getLevel(this.OfferModel.DecisionId);
      this.getGrade(this.OfferModel.LevelId);
      this.getDesignation(this.OfferModel.GradeId);
    });
  }

  saveOfferDetails() :void
  {
    this.DisableSave=false;
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.OfferModel.IsActive=true;
    this.OfferModel.offersSalary = this.OfferedSalary; 
    if(this.OfferModel.OfferId > 0)
    {
      this.EmployeeMasterService.UpdateOfferDetails(t ,this.OfferModel).subscribe(res=>
      {
        this.OfferId=res;
        this.OfferModel.OfferId=0;  
        if(this.OfferId>0)
        {
          localStorage.setItem('SuccessfullMessage', "Update Successfully");
          this.rout.navigateByUrl('/Employee/EmployeeJoining', {skipLocationChange: true}).then(()=>this.rout.navigate(["/Employee/EmployeeOffer"]));
        }
      });                  
    }                             
    else
    {
      this.EmployeeMasterService.AddOfferDetails(t ,this.OfferModel).subscribe(res=>
      {
        this.OfferId=res;
        this.OfferModel.OfferId=0;   
        if(this.OfferId>0)
        {
          localStorage.setItem('SuccessfullMessage', "Save Successfully");
          this.rout.navigateByUrl('/Employee/EmployeeJoining', {skipLocationChange: true}).then(()=>this.rout.navigate(["/Employee/EmployeeOffer"]));
        }
      }); 
    }
  }

  DeleteOfferDetailsByid(Offerid : number) :void
  {    
    this._confirmation.create('Confirm to Delete', 'Press Yes to Delete', this.settings)
    .subscribe((ans: ResolveEmit) => 
    {
      if (ans.resolved == true) 
      {
        let t =  localStorage.getItem('userToken');
        t = 'Bearer' + t;
        this.OfferModel.IsActive=true;
        if( Offerid > 0)
        {
          this.EmployeeMasterService.DeleteOfferDetails(t ,Offerid).subscribe(res=>
          {
            this.OfferId=res;
            this.OfferModel.OfferId=0;                                  
            if(this.OfferId >0)
            {
              localStorage.setItem('SuccessfullMessage', "Deleted Successfully");
              this.rout.navigateByUrl('/Employee/EmployeeJoining', {skipLocationChange: true}).then(()=>this.rout.navigate(["/Employee/EmployeeOffer"]));
            }
          });                  
        }
      } 
    })
  }

  dateChanged( date)
  {
    this.OfferModel.joiningDate=date;
    var CurrentDate = new Date();
    var CurrentYear = CurrentDate.getFullYear();
    var currentMonth = CurrentDate.getMonth();
    var CurrentDay = CurrentDate.getDate();
    
    var selecteedDate = new Date(date);
    var selectedYear=selecteedDate.getFullYear();
    var selectedMonth=selecteedDate.getMonth();
    var selectedDay=selecteedDate.getDate();

    if(CurrentYear < selectedYear )
    {
      this.validdate = true;      
    }
    else if(CurrentYear > selectedYear)
    {
      this.validdate = false;
    }
    else
    {
      if (currentMonth < selectedMonth)
      {
        this.validdate = true;
      }
      else if(currentMonth > selectedMonth)
      {
        this.validdate = false;
      }
      else
      {
        if(CurrentDay <= selectedDay )
        {
          this.validdate = true;
        }
        else
        {
          this.validdate = false;
        }
      }
    }
  }
  
  //Begin Location
  getCountries() 
  {
    this.Countries=[];
    let t = localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.organizationService.GetCountry( t).then(x => 
    {
      this.country = x;
      Object.keys(this.country).forEach( key => 
      {
        this.country[key];
        let Countrytemp = new Country();
        Countrytemp = this.country[key];
        this.Countries.push(Countrytemp);
      });     
    });      
  }

  getStates( countryid) 
  {
    this.States=[];
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t
    if(this.States.length>0)
    {
      this.States.splice(0, this.States.length);
    }
    this.organizationService.GetState( t,countryid).then(x => 
    {
      this.State = x;
      Object.keys(this.State).forEach( key => 
      {
        this.State[key];
        let Statetemp = new State();
        Statetemp = this.State[key];
        this.States.push(Statetemp);
      });     
    });      
  }

  getDistrict( Stateid) 
  {
    this.Districts=[];
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t
    if(this.Districts.length>0)
    {
      this.Districts.splice(0, this.Districts.length);
    }
    this.LocationService.GetDistrictByStateid( t,Stateid).then(x => 
    {
      this.District = x;
      Object.keys(this.District).forEach( key => 
      {
        this.District[key];
        let Districttemp = new District();
        Districttemp = this.District[key];
        this.Districts.push(Districttemp);
      });     
    });      
  }

  getCities(DistID) 
  {
    this.Cities=[];
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t
    if(this.Cities.length>0)
    {
      this.Cities.splice(0,this.Cities.length )
    }
    this.LocationService.GetCityByDistrictid( t,DistID).then(x => 
    {
      this.City = x;
      Object.keys(this.City).forEach( key => 
      {
        this.City[key];
        let Citytemp = new City();
        Citytemp = this.City[key];
        this.Cities.push(Citytemp);
      });     
    });      
  }

  onChangeCountry(countryid) 
  {
    this.States=[];
    this.Cities=[];
    this.Districts=[];
    if(countryid!=0)
    {
      this.getStates(countryid);
      this.OfferModel.StateId=0;
    }
    else
    {
      this.getStates(countryid);
      this.OfferModel.StateId=0;
      this.OfferModel.DistrictId=0;
      this.OfferModel.CityId=0;
      this.getDistrict(0);
      this.getCities(0);
    }
  }

  onstatechange(stateid)
  {
    this.Cities=[];
    this.Districts=[];
    this.GetOfferdetails_Salary();
    if(stateid!=0)
    {
      this.getDistrict(stateid) ;
      this.OfferModel.DistrictId=0;
    }
    else
    {
      this.getDistrict(stateid) ;
      this.OfferModel.DistrictId=0;
      this.OfferModel.CityId=0;
      this.getCities(0);
    }      
  }

  onDistrictchange(DistrictId)
  {
    this.Cities=[];
    if(DistrictId!=0)
    {
      this.getCities(DistrictId) ;
      this.OfferModel.CityId=0;
    }
    else
    {
      this.getCities(DistrictId) ;
      this.OfferModel.CityId=0;
    }      
  }
  //End Location

  //Begin Organisation
  getCompanies() 
  {
    this.Companies=[];
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.organizationService.GetCompanyForDDL( t).then(x => 
    {
      this.Company = x;
      Object.keys(this.Company).forEach( key => 
      {
        this.Company[key];
        let Companytemp = new Company();
        Companytemp = this.Company[key];
        this.Companies.push(Companytemp);
      });     
    });      
  }
  
  getBranches( Companyid) 
  {
    this.Branches=[];
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t
    if(this.Branches.length>0)
    {
      this.Branches.splice(0, this.Branches.length);
    }
    this.organizationService.GetBranchByCompanyId( t,Companyid).then(x => 
    {
      this.Branch = x;
      Object.keys(this.Branch).forEach( key => 
      {
        this.Branch[key];
        let Branchtemp = new Branch();
        Branchtemp = this.Branch[key];
        this.Branches.push(Branchtemp);
      });     
    });      
  }
  
  getDepartments(Branchid) 
  {
    this.Departments=[];
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t
    if(this.Departments.length>0)
    {
      this.Departments.splice(0,this.Departments.length )
    }
    this.organizationService.GetDepartmentByBranchid( t,Branchid).then(x => 
    {
      this.Department = x;
      Object.keys(this.Department).forEach( key => 
      {
        this.Department[key];
        let Departmenttemp = new Department();
        Departmenttemp = this.Department[key];
        this.Departments.push(Departmenttemp);
      });     
    });      
  }
  
  onChangeCompany(Companyid) 
  {
    this.Branches=[];
    this.Departments=[];
    this.GetOfferdetails_Salary();
    if(Companyid!=0)
    {
      this.getBranches(Companyid);
      this.OfferModel.BranchId=0;
    }
    else
    {
      this.getBranches(Companyid);
      this.OfferModel.BranchId=0;
      this.getDepartments(0);
    }
  }
  
  onchangeBranch(Branchid)
  {
    this.Departments=[];
    if(Branchid!=0)
    {
      this.getDepartments(Branchid);
      this.OfferModel.DepartmentId=0;
      this.BranchStateId=0;
      this.GetBranchStateId(Branchid);
    }
    else
    {
      this.getDepartments(Branchid);
      this.OfferModel.DepartmentId=0;
      this.BranchStateId=0;
      this.GetBranchStateId(Branchid);
    } 
    this.SalaryBreakupCalculation();    
  }

  GetBranchStateId( Branchid) 
  {
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t
    this.organizationService.GetBranchStateId( t,Branchid).then(x => 
    {
      this.BranchStateId=0;
      this.Branch = x;
      Object.keys(this.Branch).forEach( key => 
      {
        this.Branch[key];
        let Branchtemp = new Branch();
        Branchtemp = this.Branch[key];
        this.BranchStateId=Branchtemp.StateId;
        if(this.OfferModel.OfferId==0)
        {
          this.SalaryBreakupCalculation();
        }
        else
        {
          this.get_offerdSalarytails(this.OfferModel.OfferId,this.OfferModel.CompanyId,this.OfferModel.GradeId)
        }
      });  
    });      
  }
  //End Organisation
  
  //Begin Grades & Level
  getDecisionMaking() 
  {
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.LevelService.GetDecisionMaking( t).then(x => 
    {
      this.DecisionMaking = x;
      Object.keys(this.DecisionMaking).forEach( key => 
      {
        this.DecisionMaking[key];
        let DecisionMakingtemp = new DecisionMaking();
        DecisionMakingtemp = this.DecisionMaking[key];
        this.DecisionMakings.push(DecisionMakingtemp);
      });     
    });      
  }
  
  getLevel( DecisionMakingid) 
  {
    this.Levels=[];
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t
    if(this.Levels.length>0)
    {
      this.Level.splice(0, this.Level.length);
    }
    this.LevelService.GetLevelByDecisionid( t,DecisionMakingid).then(x => 
    {
      this.Level = x;
      Object.keys(this.Level).forEach( key => 
      {
        this.Level[key];
        let Leveltemp = new Level();
        Leveltemp = this.Level[key];
        this.Levels.push(Leveltemp);
      });     
    });      
  }
  
  getGrade(Levelid) 
  {
    this.Grades=[];
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t
    if(this.Grades.length>0)
    {
      this.Grades.splice(0,this.Grades.length )
    }
    this.LevelService.GetGradeByLevelid( t,Levelid).then(x => 
    {
      this.Grade = x;
      Object.keys(this.Grade).forEach( key => 
      {
        this.Grade[key];
        let Gradetemp = new Grade();
        Gradetemp = this.Grade[key];
        this.Grades.push(Gradetemp);
      });     
    });      
  }
  
  getDesignation(Gradeid) 
  {
    this.Designations=[];
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t
    if(this.Designations.length>0)
    {
      this.Designations.splice(0,this.Designations.length )
    }
    this.LevelService.GetDesignationByGradeid( t,Gradeid).then(x => 
    {
      this.Designation = x;
      Object.keys(this.Designation).forEach( key => 
      {
        this.Designation[key];
        let Designationtemp = new Designation();
        Designationtemp = this.Designation[key];
        this.Designations.push(Designationtemp);
      });     
    });      
  }
  
  onDecisionChange(Decisionid) 
  {
    this.Levels=[];
    this.Grades=[];
    this.Designations=[];
    if(Decisionid!=0)
    {
      this.getLevel(Decisionid);
      this.OfferModel.LevelId=0;
    }
    else
    {
      this.getLevel(Decisionid);
      this.OfferModel.LevelId=0;
      this.getGrade(0);
    }
  }
  
  onLevelChange(Levelid)
  {
    this.Grades=[];
    this.Designations=[];
    if(Levelid!=0)
    {
      this.getGrade(Levelid);
      this.OfferModel.GradeId=0;
    }
    else
    {
      this.getGrade(Levelid);
      this.OfferModel.GradeId=0;
      this.getDesignation(0);
    } 
  }
  
  onGradechange(Gradeid)
  {
    this.Designations=[];
    this.GetOfferdetails_Salary();
    if(Gradeid!=0)
    {
      this.getDesignation(Gradeid);
      this.OfferModel.DesignationId=0;
    }
    else
    {
      this.getDesignation(Gradeid);
      this.OfferModel.DesignationId=0;
    }        
  }
  //End Grades & Level
  
  RefreshPage()
  {
    this.rout.navigateByUrl('/Employee/EmployeeJoining', {skipLocationChange: true}).then(()=>this.rout.navigate(["/Employee/EmployeeOffer"]));
  }

  // offered Salary
  GetOfferdetails_Salary()
  {
    this.SalaryBreakupCalculation();
  }

  onBasicChangevaluechange()
  {
    this.ComponentTotal=0;
    this.ComponentSubTotal=0;
    this.Longtermbenefits = 0;
    this.OfferedSalary.forEach(x=>
    {     
      let BAmount = Math.round(this.OfferedSalary.find(x=>x.SalaryComponentCode=='BS').Amount);
      this.GetEPFCalculation(BAmount);
      if(x.IsBenefit==false)
      {
        var tempvaluef = 
        {
          a: Number(this.ComponentSubTotal),
          b: Number(x.Amount)  
        }
        this.ComponentSubTotal = tempvaluef.a + tempvaluef.b;
      }
      else
      {
        var tempvaluef = 
        {
          a: Number(this.Longtermbenefits),
          b: Number(x.Amount)                 
        }
        this.Longtermbenefits = tempvaluef.a + tempvaluef.b;
      }
    })
    this.OfferModel.GrossSalary=this.ComponentSubTotal;
    this.GetESICalculation(this.ComponentSubTotal);
    this.GetPtax();
    this.OfferModel.CTCAmount=Math.round(this.Longtermbenefits + this.ComponentSubTotal + this.PFCont + this.ESICont);
  }

  GetEPFCalculation( BAmount)
  {
    if(this.OfferModel.EPFApplicable==true)
    {
      this.MinBasicSalary=this.tempEPFESIModel.HighestBasicSalary;
      if(BAmount >= this.MinBasicSalary)
      {
        this.PFDed=Math.round((this.MinBasicSalary*this.tempEPFESIModel.EPFDed)/100);
        this.PFCont=Math.round((this.MinBasicSalary*this.tempEPFESIModel.EPFCont)/100);
      }
      else
      {
        this.PFDed=Math.round((BAmount * this.tempEPFESIModel.EPFDed)/100);
        this.PFCont=Math.round((BAmount * this.tempEPFESIModel.EPFCont)/100);
      }
    }
    else
    {
      this.PFDed=0.00;
      this.PFCont=0.00;
    }
  }

  GetESICalculation( GrossSalary)
  {
    if(this.OfferModel.ESIApplicable==true)
    {
      this.MinBasicSalary=this.tempEPFESIModel.HighestBasicSalary;
      if(this.OfferModel.GrossSalary >= this.MinBasicSalary)
      {
        this.ESIDed=0.00;
        this.ESICont=0.00;
      }
      else
      {
        this.ESIDed=Math.round((GrossSalary * this.tempEPFESIModel.ESIDed)/100);
        this.ESICont=Math.round((GrossSalary * this.tempEPFESIModel.ESICont)/100);
      } 
    }
    else
    {
      this.ESIDed=0.00;
      this.ESICont=0.00;
    }
  }

  GetEpfEsi()
  {
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.EmpSalary.GetEPFESISlabforOffer(t).then(y=>
    {
      this.tempEPFESIModels=y;
      Object.keys(this.tempEPFESIModels).forEach( key => 
      {
        this.tempEPFESIModel=this.tempEPFESIModels[key]; 
        this.tempEPFESIModel.EPFDed=this.tempEPFESIModel.EPFDed;
        this.tempEPFESIModel.EPFCont=this.tempEPFESIModel.EPFCont;
        this.tempEPFESIModel.ESIDed=this.tempEPFESIModel.ESIDed;
        this.tempEPFESIModel.ESICont=this.tempEPFESIModel.ESICont;
        this.tempEPFESIModel.HighestBasicSalary=this.tempEPFESIModel.HighestBasicSalary;                    
      }); 
    });
  }

  GetPtax()
  {
    if(this.OfferModel.PTAXApplicable==true)
    {
      let t =  localStorage.getItem('userToken');
      t = 'Bearer' + t;
      this.EmpSalary.GetPtaxSlabByBasicSalaryForOffer(t,this.BranchStateId,this.ComponentSubTotal).then(y=>
      {
        this.tempPtaxSlabModels=y;
        this.P_Tax = 0.00;
        this.OfferModel.NetTakeHome=(this.ComponentSubTotal)-(this.PFDed + this.ESIDed + this.P_Tax);
        Object.keys(this.tempPtaxSlabModels).forEach( key => 
        {
          this.tempPtaxSlabModel=this.tempPtaxSlabModels[key]; 
          this.P_Tax = this.tempPtaxSlabModel.TaxAmount ;                    
        }); 
        this.OfferModel.NetTakeHome=Math.round((this.ComponentSubTotal)-(this.PFDed + this.ESIDed + this.P_Tax));
      }); 
    }
    else
    {
      this.P_Tax = 0.00;
      this.OfferModel.NetTakeHome=Math.round((this.ComponentSubTotal)-(this.PFDed + this.ESIDed + this.P_Tax));
    }
  }

  public openModal(template: TemplateRef<any>) 
  {
    this.modalRef = this.modalService.show(template);
  }

  public SalaryBreakupCalculation()
  {
    if(this.OfferModel.OfferId==0)
    {
      this.OfferModel.NetTakeHome=0;
      // Salary Breakup conponent
      if(this.OfferModel.CompanyId > 0 && this.OfferModel.GradeId > 0 && this.OfferModel.GrossSalary > 0 && this.BranchStateId >0)
      {
        let t =  localStorage.getItem('userToken');
        t = 'Bearer' + t;
        this.EmpSalary.GetOfferedSalary( t,this.OfferModel.CompanyId,this.OfferModel.GradeId,this.OfferModel.GrossSalary).then(x => 
        {
          this.Offer = x;
          
          if(this.OfferedSalary.length >0)
          {
            this.OfferedSalary=[];
          } 
          this.ComponentTotal=0;
          this.ComponentSubTotal=0;
          this.Longtermbenefits = 0;
          Object.keys(this.Offer).forEach( key => 
          {
            this.Offer[key];
            let Offertemp = new OfferStandardSalary();
            Offertemp = this.Offer[key];
  
            if(Offertemp.SalaryComponentCode=='BS')
            {
              let BAmount = this.Offer.find(x=>x.SalaryComponentCode=='BS').Amount;
              this.GetEPFCalculation(BAmount);
            }
  
            if(Offertemp.IsBenefit==false)
            {              
              //this.ComponentSubTotal = this.ComponentSubTotal + Offertemp.Amount;  
              var tempvaluef =
              {
                a: Number(this.ComponentSubTotal),
                b: Number(Offertemp.Amount)  
              }
              this.ComponentSubTotal = tempvaluef.a + tempvaluef.b; 
            }
            else
            {
            // this.Longtermbenefits = this.Longtermbenefits +  Offertemp.Amount;
              var tempvaluef = 
              {
                a: Number(this.Longtermbenefits),
                b: Number(Offertemp.Amount)  
              }
              this.Longtermbenefits = tempvaluef.a + tempvaluef.b; 
            }
            this.OfferedSalary.push(Offertemp);            
          });          
          if(this.OfferedSalary.find(x=>x.SalaryComponentCode=='SPA').Amount==0)
          {
            var tempvaluefox = 
            {
              a: Number(this.ComponentSubTotal),
              b: Number(this.OfferModel.GrossSalary),  
              c: Number(this.Longtermbenefits)
            }
            this.OfferedSalary.find(x=>x.SalaryComponentCode=='SPA').Amount=tempvaluefox.b -  tempvaluefox.a;
            this.ComponentSubTotal = tempvaluefox.b;
          }
          else
          {
            var tempvaluefoy = 
            {
              a: Number(this.OfferModel.GrossSalary),
              b: Number(this.ComponentSubTotal),
              c: this.OfferedSalary.find(x=>x.SalaryComponentCode=='SPA').Amount,
              d: Number(this.Longtermbenefits)
            }
            this.OfferedSalary.find(x=>x.SalaryComponentCode=='SPA').Amount=(tempvaluefoy.a - tempvaluefoy.b + tempvaluefoy.c); 
            this.ComponentSubTotal = tempvaluefoy.a;
          }
          this.GetESICalculation(this.ComponentSubTotal);
          this.GetPtax();
          this.OfferModel.CTCAmount=Math.round(this.Longtermbenefits + this.ComponentSubTotal + this.PFCont + this.ESICont);
        });
      }
      else
      {
        this.OfferedSalary=[];
      }
    }
  }

  get_offerdSalarytails(Offerid,CompanyId,GradeId)
  {
    let t = localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.EmpSalary.GetOfferSalaryComponentDetailsByid(t,Offerid,CompanyId,GradeId).then(y=>
    {
      this.SalOffer=y;
      this.ComponentTotal=0;
      this.ComponentSubTotal=0;
      this.Longtermbenefits = 0;
      this.OfferedSalary=[]
      Object.keys(this.SalOffer).forEach( key => 
      {
        this.SalOffer[key];
        let tempofferSalary= new OfferStandardSalary();
        tempofferSalary=this.SalOffer[key];
        if(tempofferSalary.SalaryComponentCode=='BS')
        {
          let BAmount = this.SalOffer.find(x=>x.SalaryComponentCode=='BS').Amount;
          this.GetEPFCalculation(BAmount);
        }
        tempofferSalary.OfferId=tempofferSalary.OfferId;
        tempofferSalary.OfferSalaryId=tempofferSalary.OfferSalaryId;
        tempofferSalary.IsActive=tempofferSalary.IsActive;
        tempofferSalary.IsBenefit=tempofferSalary.IsBenefit;
        tempofferSalary.IsBasic=tempofferSalary.IsBasic;
        tempofferSalary.IsCTC=tempofferSalary.IsCTC;
        tempofferSalary.Percentage=tempofferSalary.Percentage;
        tempofferSalary.SalaryComponentCode=tempofferSalary.SalaryComponentCode;
        tempofferSalary.SalaryComponentId=tempofferSalary.SalaryComponentId;
        tempofferSalary.SalaryComponentName=tempofferSalary.SalaryComponentName;
        tempofferSalary.Amount=Math.round(tempofferSalary.Amount);
        if(tempofferSalary.IsBenefit==false)
        {     
          //this.ComponentSubTotal = this.ComponentSubTotal + Offertemp.Amount;  
          var tempvaluef = 
          {
            a: Number(this.ComponentSubTotal),
            b: Number(tempofferSalary.Amount)  
          }
          this.ComponentSubTotal = Math.round(tempvaluef.a) + Math.round(tempvaluef.b); 
        }
        else
        {
          // this.Longtermbenefits = this.Longtermbenefits +  Offertemp.Amount;
          var tempvaluef = 
          {
            a: Number(this.Longtermbenefits),
            b: Number(tempofferSalary.Amount)  
          }
          this.Longtermbenefits = Math.round(tempvaluef.a) + Math.round(tempvaluef.b); 
        }
        this.OfferedSalary.push(tempofferSalary);
      });
      this.GetESICalculation(Math.round(this.ComponentSubTotal)); 
      this.GetPtax();
      this.OfferModel.CTCAmount=Math.round(this.Longtermbenefits + this.ComponentSubTotal + this.PFCont + this.ESICont);
    });
  }

  oncheckedEPFApplicableChange()
  {
    if(this.OfferModel.OfferId==0)
    {
      this.SalaryBreakupCalculation();
    }
    else
    {
      this.get_offerdSalarytails(this.OfferModel.OfferId,this.OfferModel.CompanyId,this.OfferModel.GradeId)
    }
  }

  oncheckedESIApplicableChange() 
  {
    if(this.OfferModel.OfferId == 0)
    {
      this.SalaryBreakupCalculation();
    }
    else
    {
      this.get_offerdSalarytails(this.OfferModel.OfferId,this.OfferModel.CompanyId,this.OfferModel.GradeId)
    }
  }

  oncheckedPTAXApplicableChange() 
  {
    if(this.OfferModel.OfferId==0)
    {
      this.SalaryBreakupCalculation();
    }
    else
    {
      this.get_offerdSalarytails(this.OfferModel.OfferId,this.OfferModel.CompanyId,this.OfferModel.GradeId)
    }
  }

  clearData()
  {
    this.OfferModel.OfferId=0;
    this.OfferModel.OfferDate=null;
    this.OfferModel.FirstName=null;
    this.OfferModel.MiddleName=null;
    this.OfferModel.LastName=null;
    this.OfferModel.Address1=null;
    this.OfferModel.Address2=null;
    this.OfferModel.DistrictId=null;
    this.OfferModel.CountryId=0;
    this.OfferModel.StateId=null;
    this.OfferModel.CityId=null;
    this.OfferModel.PinCode=null;
    this.OfferModel.ContactNumber =null;
    this.OfferModel.EmailId=null;
    this.OfferModel.CompanyId=0;
    this.OfferModel.BranchId=null;
    this.OfferModel.DepartmentId=null;
    this.OfferModel.DecisionId=0;
    this.OfferModel.LevelId=null;
    this.OfferModel.GradeId=null;
    this.OfferModel.DesignationId=null;
    this.OfferModel.joiningDate=null;
    this.OfferModel.Approved=null;
    this.OfferModel.CTCAmount=null;
    this.OfferModel.NetTakeHome=null;
    this.OfferModel.SerialNumber=null;
    this.OfferModel.IsActive=null;
    this.OfferModel.PFEmployeeContributation=null;
    this.OfferModel.PTax=null;
    this.OfferModel.EPFApplicable=null;
    this.OfferModel.ESIApplicable=null;
    this.OfferModel.PTAXApplicable=null;
    this.OfferModel.GrossSalary=null;
  }

  GetPayZone()
  {
    this.RegionForHR=[];
    let t = localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.organizationService.GetPayZoneDDL( t).then(x => 
    {
      this.RegionHR = x;
      Object.keys(this.RegionHR).forEach( key => 
      {
        this.RegionHR[key];
        let RegionHRtemp = new RegionForHR();
        RegionHRtemp = this.RegionHR[key];
        this.RegionForHR.push(RegionHRtemp);
      });     
    });      
  }
}