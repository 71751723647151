import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from '../../shared/user.service';
import { Menu } from '../../shared/menu.model';
import { SalaryMasterService }  from '../../shared/HR_ADMIN/Salary/SalaryMaster.service';
import { Form12BBModel } from '../../shared/HR_ADMIN/Salary/Form12BBModel.model'
import { ModalOptions,BsModalRef, BsModalService } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-form12-bbapproval',
  templateUrl: './form12-bbapproval.component.html',
  styleUrls: ['./form12-bbapproval.component.css']
})
export class Form12BbapprovalComponent implements OnInit 
{
  userClaims: any;
  ListApplication: Form12BBModel[] = [];
  SubMenues : Menu[]=[];
  Mod : any;
  ModelListApplication: Form12BBModel;
  Approver: any;
  Result : string;
  SuccessStatus:boolean=false;
  SuccessMessage: string ="";
  public tableWidget: any;
  RefId : any;
  // Begning Permission Portion    
  SelectedMenu:Menu;
  IsDataIsInViewState : boolean=false;
  CanViewState : boolean=false;  
  CanApproveState : boolean=false; 
  GridShow : boolean=false; 
  DisableSave : boolean=false;
  // End of the permission portion
  // begin for Validation
  validdate : boolean= false;
  // end of validation
  //For Pop up
  TempRegularation : Form12BBModel;
  ApprovedMsg : boolean= false;
  ShowPopup : boolean= false;
  public modalRef: BsModalRef;
  public modelopt:ModalOptions;
  NoRecordMsg : boolean;
  OldType : boolean;
  NewType : boolean;
  TdsTypeValue: string;

  constructor(private SalaryMasterService: SalaryMasterService, private rout: Router, private userService: UserService,private modalService: BsModalService)
  { 
    this.ModelListApplication = new Form12BBModel();
    this.modelopt=new ModalOptions();
    this.modelopt.class='heavy_modal';
    this.modalService.config= this.modelopt;
    this.modelopt.ignoreBackdropClick=true;
  }

  ngOnInit() 
  {
    //Begin Permission Portion
    this.SelectedMenu=this.userService.SelectedMenu;
    if(this.SelectedMenu !=undefined)
    {
      localStorage.setItem('selectedmenu',JSON.stringify(this.SelectedMenu));
      this.rout.navigate([this.SelectedMenu.RouteName]);
    }
    else
    {
      if(JSON.parse(localStorage.getItem('selectedmenu'))!=undefined)
      {
        this.SelectedMenu = JSON.parse(localStorage.getItem('selectedmenu'));
        this.rout.navigate([this.SelectedMenu.RouteName]);
      }
    }
    if(this.SelectedMenu.CanAdd)
    {
      this.IsDataIsInViewState=false;
    }
    else
    {
      this.IsDataIsInViewState=true;
    }
    if(this.SelectedMenu.CanView)
    {
      this.CanViewState=false;
    }
    else
    {
      this.CanViewState=true;
    }
    if(this.SelectedMenu.CanApprove)
    {
      this.CanApproveState=false;
    }
    else
    {
      this.CanApproveState=true;
    }
    //End of the peremission portion
    this.DisableSave=true;
    this.GetAllApplication();
    this.ApprovedMsg=false;
    this.ShowPopup=false;
    this.NoRecordMsg =false;
  }

  GetAllApplication()
  {
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.SalaryMasterService.GetEmployeeForm12BBApprovalForHRApproval( t).then(x => 
    {
      this.ListApplication=[];
      this.NoRecordMsg =false;
      this.Approver = x;
      Object.keys(this.Approver).forEach( key => 
      {
        this.Approver[key];
        let ApproveRegularation = new Form12BBModel();
        ApproveRegularation = this.Approver[key];
        this.ListApplication.push(ApproveRegularation);
      });
      if(this.ListApplication.length == 0)
      {
        this.NoRecordMsg =true;
      }
    });
  }

  GetEmployeeFORM12BBForApprovalByRefId(id,EmployeeId,template) 
  {
    //this.ModelListApplication.Remarks="";
    this.GridShow=true;
    // Begning of the permission portion
    this.IsDataIsInViewState=false;
    // End of the permision portion
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.SalaryMasterService.GetEmployeeFORM12BBForApprovalByRefId( t,id).then(x => 
    {
      this.GetEmployeeDetails(EmployeeId)
      if(this.ShowPopup==true)
      {
        this.modalRef.hide();
      }
      this.modalRef = this.modalService.show(template,this.modelopt);
      this.ShowPopup=true;
      this.ApprovedMsg=false;
      this.Approver = x;
      this.ModelListApplication.RefId = this.Approver.RefId;
      this.ModelListApplication.HRA_Rent = this.Approver.HRA_Rent;
      if(this.ModelListApplication.HRA_Rent == 0)
      {
        this.ModelListApplication.HRA_Rent=null;
      }
      this.ModelListApplication.HRA_LandLordName = this.Approver.HRA_LandLordName;
      this.ModelListApplication.HRA_LandLordAddress = this.Approver.HRA_LandLordAddress;
      this.ModelListApplication.HRA_LandLordPANNo = this.Approver.HRA_LandLordPANNo;
      this.ModelListApplication.LeaveTravelConcessions = this.Approver.LeaveTravelConcessions;
      if(this.ModelListApplication.LeaveTravelConcessions == 0)
      {
        this.ModelListApplication.LeaveTravelConcessions=null;
      }
      this.ModelListApplication.DIB_LenderPaidAmount = this.Approver.DIB_LenderPaidAmount;
      if(this.ModelListApplication.DIB_LenderPaidAmount == 0)
      {
        this.ModelListApplication.DIB_LenderPaidAmount=null;
      }
      this.ModelListApplication.DIB_LenderName = this.Approver.DIB_LenderName;
      this.ModelListApplication.DIB_LenderAddress = this.Approver.DIB_LenderAddress;
      this.ModelListApplication.DIB_LenderPANNo = this.Approver.DIB_LenderPANNo;
      this.ModelListApplication.FinancialInstitutions = this.Approver.FinancialInstitutions;
      if(this.ModelListApplication.FinancialInstitutions == 0)
      {
        this.ModelListApplication.FinancialInstitutions=null;
      }
      this.ModelListApplication.Employer = this.Approver.Employer;
      if(this.ModelListApplication.Employer == 0)
      {
        this.ModelListApplication.Employer=null;
      }
      this.ModelListApplication.Other = this.Approver.Other;
      if(this.ModelListApplication.Other == 0)
      {
        this.ModelListApplication.Other=null;
      }
      this.ModelListApplication.TotalDedUnderVIA = this.Approver.TotalDedUnderVIA;
      this.ModelListApplication.TotalSEC80C_80CCC_80CCD = this.Approver.TotalSEC80C_80CCC_80CCD;
      this.ModelListApplication.TotalSEC80C = this.Approver.TotalSEC80C;
      this.ModelListApplication.Insurance_80C = this.Approver.Insurance_80C;
      if(this.ModelListApplication.Insurance_80C == 0)
      {
        this.ModelListApplication.Insurance_80C=null;
      }
      this.ModelListApplication.PF_PPF_80C = this.Approver.PF_PPF_80C;
      if(this.ModelListApplication.PF_PPF_80C == 0)
      {
        this.ModelListApplication.PF_PPF_80C=null;
      }
      this.ModelListApplication.NPS_80C = this.Approver.NPS_80C;
      if(this.ModelListApplication.NPS_80C == 0)
      {
        this.ModelListApplication.NPS_80C=null;
      }
      this.ModelListApplication.ELSS_80C = this.Approver.ELSS_80C;
      if(this.ModelListApplication.ELSS_80C == 0)
      {
        this.ModelListApplication.ELSS_80C=null;
      }
      this.ModelListApplication.HousingLoan_80C = this.Approver.HousingLoan_80C;
      if(this.ModelListApplication.HousingLoan_80C == 0)
      {
        this.ModelListApplication.HousingLoan_80C=null;
      }
      this.ModelListApplication.FD_80C = this.Approver.FD_80C;
      if(this.ModelListApplication.FD_80C == 0)
      {
        this.ModelListApplication.FD_80C=null;
      }
      this.ModelListApplication.SukanayaSamriddhi_80C = this.Approver.SukanayaSamriddhi_80C;
      if(this.ModelListApplication.SukanayaSamriddhi_80C == 0)
      {
        this.ModelListApplication.SukanayaSamriddhi_80C=null;
      }
      this.ModelListApplication.SchoolTution_80C = this.Approver.SchoolTution_80C;
      this.ModelListApplication.SchoolTutuion_C1 = this.Approver.SchoolTutuion_C1;
      if(this.ModelListApplication.SchoolTutuion_C1 == 0)
      {
        this.ModelListApplication.SchoolTutuion_C1=null;
      }
      this.ModelListApplication.SchoolTutuion_C2 = this.Approver.SchoolTutuion_C2;
      if(this.ModelListApplication.SchoolTutuion_C2 == 0)
      {
        this.ModelListApplication.SchoolTutuion_C2=null;
      }
      this.ModelListApplication.MutaulFund_80CCC = this.Approver.MutaulFund_80CCC;
      if(this.ModelListApplication.MutaulFund_80CCC == 0)
      {
        this.ModelListApplication.MutaulFund_80CCC=null;
      }
      this.ModelListApplication.EPP_80CCD = this.Approver.EPP_80CCD;
      if(this.ModelListApplication.EPP_80CCD == 0)
      {
        this.ModelListApplication.EPP_80CCD=null;
      }
      this.ModelListApplication.OtherSection_80E_80G_80TTA = this.Approver.OtherSection_80E_80G_80TTA;
      this.ModelListApplication.MIParents_80D = this.Approver.MIParents_80D;
      if(this.ModelListApplication.MIParents_80D == 0)
      {
        this.ModelListApplication.MIParents_80D=null;
      }
      this.ModelListApplication.IPEL_80E = this.Approver.IPEL_80E;
      if(this.ModelListApplication.IPEL_80E == 0)
      {
        this.ModelListApplication.IPEL_80E=null;
      }
      this.ModelListApplication.Donation_80G = this.Approver.Donation_80G;
      if(this.ModelListApplication.Donation_80G == 0)
      {
        this.ModelListApplication.Donation_80G=null;
      }
      this.ModelListApplication.SEC80TTA_IRSBAc = this.Approver.SEC80TTA_IRSBAc;
      if(this.ModelListApplication.SEC80TTA_IRSBAc == 0)
      {
        this.ModelListApplication.SEC80TTA_IRSBAc=null;
      }
      this.ModelListApplication.SEC24_IPHL = this.Approver.SEC24_IPHL;
      if(this.ModelListApplication.SEC24_IPHL == 0)
      {
        this.ModelListApplication.SEC24_IPHL=null;
      }
      this.ModelListApplication.StartDate = this.Approver.StartDate;
      this.ModelListApplication.EndDate = this.Approver.EndDate;
      this.ModelListApplication.FinalSubmit=this.Approver.FinalSubmit;
      this.ModelListApplication.SaveButtonView = this.Approver.SaveButtonView;
      this.ModelListApplication.ShowTextMessage=this.Approver.ShowTextMessage;
      this.ModelListApplication.TDSType=this.Approver.TDSType;
      if(this.ModelListApplication.TDSType=='Old')
      {
        this.OldType = true;
        this.NewType = false;
        this.TdsTypeValue = 'OldType';
      }
      else
      {
        this.OldType = false;
        this.NewType = true;
        this.TdsTypeValue = 'NewType';
      }
    });
  }

  OnChangeOldType(evt)
  {
    var target = evt.target;
    if(target.checked)
    {           
      this.TdsTypeValue="OldType";
      this.OldType=true;
      this.NewType=false;
    }
  }

  OnChangeNewType(evt)
  {
    var target = evt.target;
    if(target.checked)
    {           
      this.TdsTypeValue="NewType";
    }
    this.OldType=false;
    this.NewType=true;
  }
  
  GetEmployeeDetails(EmployeeId)
  {
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.SalaryMasterService.GetEmployeeDetailsForForm12BBByEmployeeId( t,EmployeeId).then(x => 
    {
      this.Approver = x;
      this.ModelListApplication.EmployeeCode = this.Approver.EmployeeCode;
      this.ModelListApplication.Financialyear = this.Approver.Financialyear;
      this.ModelListApplication.EmployeeCode = this.Approver.EmployeeCode;
      this.ModelListApplication.EmployeeName = this.Approver.EmployeeName;
      this.ModelListApplication.EmployeeAddress = this.Approver.EmployeeAddress;
      this.ModelListApplication.ContactNo = this.Approver.ContactNo;
      this.ModelListApplication.DepartmentName = this.Approver.DepartmentName;
      this.ModelListApplication.EmployeePANNo = this.Approver.EmployeePANNo;
      this.ModelListApplication.DesignationName = this.Approver.DesignationName;
      this.ModelListApplication.FathersName = this.Approver.FathersName;
      this.ModelListApplication.EmployeeId = this.Approver.EmployeeId;
      this.ModelListApplication.FinYear = this.Approver.FinYear;
    });
  }

  print(): void
  {
    let printContents, popupWin;
    printContents = document.getElementById('Form12BB').innerHTML;
    popupWin = window.open('', '_blank', 'letf=0,top=0,width=2480px,height=3508px,toolbar=0, scrollbars=0,sta­tus=0');
    popupWin.document.open();
    popupWin.document.write(`
      <html>
        <head>
          <title>Form-12BB</title>
          <style=" margin:25px 10px 15px 30px; font-family:Calibri;">
          
          </style>
        </head>
      <body onload="window.print();window.close()">${printContents}</body>
      </html>`
    );
    popupWin.document.close();
  }
  
  InsuranceCalculation()
  {
    if((Number(this.ModelListApplication.TotalSEC80C) + Number(this.ModelListApplication.Insurance_80C)) > 150000)
    {
      this.ModelListApplication.Insurance_80C=0;
    }
    this.SEC80CCalculation();
  }

  PFEPFCalculation()
  {
    if((Number(this.ModelListApplication.TotalSEC80C) + Number(this.ModelListApplication.PF_PPF_80C)) > 150000)
    {
      this.ModelListApplication.PF_PPF_80C=0;
    }
    this.SEC80CCalculation();
  }

  NPSCalculation()
  {
    if((Number(this.ModelListApplication.TotalSEC80C) + Number(this.ModelListApplication.NPS_80C)) > 150000)
    {
      this.ModelListApplication.NPS_80C=0;
    }
    this.SEC80CCalculation();
  }

  ELSSCalculation()
  {
    if((Number(this.ModelListApplication.TotalSEC80C) + Number(this.ModelListApplication.ELSS_80C)) > 150000)
    {
      this.ModelListApplication.ELSS_80C=0;
    }
    this.SEC80CCalculation();
  }

  HousingLoanCalculation()
  {
    if(this.ModelListApplication.HousingLoan_80C > 150000)
    {
      this.ModelListApplication.HousingLoan_80C=0;
    }
    else if((Number(this.ModelListApplication.TotalSEC80C) + Number(this.ModelListApplication.HousingLoan_80C)) > 150000)
    {
      this.ModelListApplication.HousingLoan_80C=0;
    }
    this.SEC80CCalculation();
  }
  
  FDCalculation()
  {
    if((Number(this.ModelListApplication.TotalSEC80C) + Number(this.ModelListApplication.FD_80C)) > 150000)
    {
      this.ModelListApplication.FD_80C=0;
    }
    this.SEC80CCalculation();
  }

  SchoolTutuionCalculation()
  {
    this.ModelListApplication.SchoolTution_80C = Number(this.ModelListApplication.SchoolTutuion_C1) + Number(this.ModelListApplication.SchoolTutuion_C2);
    if((Number(this.ModelListApplication.TotalSEC80C) + Number(this.ModelListApplication.SchoolTution_80C)) > 150000)
    {
      this.ModelListApplication.SchoolTution_80C=0;
      this.ModelListApplication.SchoolTutuion_C1=0;
      this.ModelListApplication.SchoolTutuion_C2=0;
    }
    this.SEC80CCalculation();
  }
    
  SukanayaSamriddhiCalculation()
  {
    if((Number(this.ModelListApplication.TotalSEC80C) + Number(this.ModelListApplication.SukanayaSamriddhi_80C)) > 150000)
    {
      this.ModelListApplication.SukanayaSamriddhi_80C=0;
    }
    this.SEC80CCalculation();
  }

  SEC80CCalculation()
  {
    this.ModelListApplication.TotalSEC80C=(
      Number(this.ModelListApplication.Insurance_80C) +
      Number(this.ModelListApplication.PF_PPF_80C) +
      Number(this.ModelListApplication.NPS_80C) +
      Number(this.ModelListApplication.ELSS_80C) +
      Number(this.ModelListApplication.HousingLoan_80C) +
      Number(this.ModelListApplication.FD_80C) +
      Number(this.ModelListApplication.SchoolTution_80C) +
      Number(this.ModelListApplication.SukanayaSamriddhi_80C) 
      );
      this.SEC80C_80CCC_80CCDCalculation();
  }

  SEC80C_80CCC_80CCDCalculation()
  {
    this.ModelListApplication.TotalSEC80C_80CCC_80CCD = 
    Number(this.ModelListApplication.TotalSEC80C) + 
    Number(this.ModelListApplication.MutaulFund_80CCC) +
    Number(this.ModelListApplication.EPP_80CCD);

    this.ModelListApplication.TotalDedUnderVIA = Number(this.ModelListApplication.TotalSEC80C_80CCC_80CCD) + Number(this.ModelListApplication.OtherSection_80E_80G_80TTA);
  }
  
  SEC80CCCCalculation()
  {
    if(this.ModelListApplication.MutaulFund_80CCC > 50000)
    {
      this.ModelListApplication.MutaulFund_80CCC=0;
    }
    this.SEC80C_80CCC_80CCDCalculation();
  }
  
  SEC80CCDCalculation()
  {
    if(this.ModelListApplication.EPP_80CCD > 50000)
    {
      this.ModelListApplication.EPP_80CCD=0;
    }
    this.SEC80C_80CCC_80CCDCalculation();
  }
  
  OtherSection_80E_80G_80TTACalculation()
  {
    this.ModelListApplication.OtherSection_80E_80G_80TTA=
      Number(this.ModelListApplication.MIParents_80D) +
      Number(this.ModelListApplication.IPEL_80E) +
      Number(this.ModelListApplication.Donation_80G) +
      Number(this.ModelListApplication.SEC80TTA_IRSBAc) +
      Number(this.ModelListApplication.SEC24_IPHL);

    this.ModelListApplication.TotalDedUnderVIA = Number(this.ModelListApplication.TotalSEC80C_80CCC_80CCD) + Number(this.ModelListApplication.OtherSection_80E_80G_80TTA);
  }

  Sec80DCalculation()
  {
    if(this.ModelListApplication.MIParents_80D > 50000)
    {
      this.ModelListApplication.MIParents_80D=0;
    }
    this.OtherSection_80E_80G_80TTACalculation();
  }

  Sec80GCalculation()
  {
    if(this.ModelListApplication.Donation_80G > 50000)
    {
      this.ModelListApplication.Donation_80G=0;
    }
    this.OtherSection_80E_80G_80TTACalculation();
  }

  Sec80TTACalculation()
  {
    if(this.ModelListApplication.SEC80TTA_IRSBAc > 10000)
    {
      this.ModelListApplication.SEC80TTA_IRSBAc=0;
    }
    this.OtherSection_80E_80G_80TTACalculation();
  }

  Sec24IPHLGCalculation()
  {
    if(this.ModelListApplication.SEC24_IPHL > 200000)
    {
      this.ModelListApplication.SEC24_IPHL=0;
    }
    this.OtherSection_80E_80G_80TTACalculation();
  }

  FinalSaveForm12BB(template) :void
  {
    this.DisableSave=false;
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    if(this.NewType == true)
    {
      this.ModelListApplication.HRA_Rent = 0;
      this.ModelListApplication.HRA_LandLordName = "";
      this.ModelListApplication.HRA_LandLordAddress = "";
      this.ModelListApplication.HRA_LandLordPANNo = "";
      this.ModelListApplication.LeaveTravelConcessions = 0;
      this.ModelListApplication.DIB_LenderPaidAmount = 0;
      this.ModelListApplication.DIB_LenderName = "";
      this.ModelListApplication.DIB_LenderAddress = "";
      this.ModelListApplication.DIB_LenderPANNo = "";
      this.ModelListApplication.FinancialInstitutions = 0;
      this.ModelListApplication.Employer = 0;
      this.ModelListApplication.Other = 0;
      this.ModelListApplication.TotalDedUnderVIA = 0;
      this.ModelListApplication.TotalSEC80C_80CCC_80CCD = 0;
      this.ModelListApplication.TotalSEC80C = 0;
      this.ModelListApplication.Insurance_80C = 0;
      this.ModelListApplication.PF_PPF_80C = 0;
      this.ModelListApplication.NPS_80C = 0;
      this.ModelListApplication.ELSS_80C = 0;
      this.ModelListApplication.HousingLoan_80C = 0;
      this.ModelListApplication.FD_80C = 0;
      this.ModelListApplication.SukanayaSamriddhi_80C = 0;
      this.ModelListApplication.SchoolTution_80C = 0;
      this.ModelListApplication.SchoolTutuion_C1 = 0;
      this.ModelListApplication.SchoolTutuion_C2 = 0;
      this.ModelListApplication.MutaulFund_80CCC = 0;
      this.ModelListApplication.EPP_80CCD = 0;
      this.ModelListApplication.OtherSection_80E_80G_80TTA = 0;
      this.ModelListApplication.MIParents_80D = 0;
      this.ModelListApplication.IPEL_80E = 0;
      this.ModelListApplication.Donation_80G = 0;
      this.ModelListApplication.SEC80TTA_IRSBAc = 0;
      this.ModelListApplication.SEC24_IPHL = 0;
      this.ModelListApplication.TDSType="New";
    }
    else
    {
      this.ModelListApplication.TDSType="Old"
    }
    this.SalaryMasterService.HRApprovedFORM12BB(t ,this.ModelListApplication).subscribe(res=>
    {
      this.RefId=res;
      this.ModelListApplication.RefId=0;              
      if(this.RefId>0)
      {
        this.ModelListApplication.Remarks="";
        this.modalRef.hide();
        this.ApprovedMsg=true;
        this.ShowPopup=false;
        this.modalRef = this.modalService.show(template);
        this.GetAllApplication();
        this.DisableSave=true;
      }
    });
  }
}
