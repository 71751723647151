export class AttendanceRegularationModel
{
    public RefId : number;
    public EmployeeId : number;
    public EmployeeCode : string;
    public EmployeeName : string;
    public EmployeeEmailId : string;
    public AttendanceDate : Date;
    public AttendanceDay : string; 
    public ActualInTime : Date;
    public ActualOutTime : Date;
    public ActualWorkingDuration : number;
    public StartTime : Date;
    public EndTime : Date;
    public TotalWorkingHours : number;
    public RegularationDuration : number;
    public Reason: string;
    public Remarks: string;
    public RHId : number;
    public RHCode : string;
    public RHName : string;
    public RHEmailId : string;
    public CompanyId : number;
    public AttendanceStatus : string;
}