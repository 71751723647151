<div class="body-content-area tabcontent" id="link1">
  <div class="row">
    <div class="col-lg-9">
      <div class="row">
        <div class="col-lg-1 low-padding text-center">
          <!-- <img *ngIf="this.SelectedMenu.Image!=null" src={{this.SelectedMenu.Image}} class="body-icon" alt="Icon"/> -->
        </div>
        <div class="col-lg-10 clear-padding">
          <span class="page-title">Manage Events & MD Messages</span>
          <small class="page-description"></small>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="!(this.CanViewState)">
    <div style=" text-align: right;">
      <button (click)="ADDClick(template)" type="button" class="btn btn-primary Add-button">Add New</button> 
    </div>

    <fieldset class="body-form-control">
      <legend class="legend--">Events & Messages</legend>
      <table id="tblEventMessages" class="table table-striped table-bordered text-center table-new row-border hover">
        <thead class="thead-blue text-center">
          <tr>
            <th style=" width: 10%;">Sl No.</th>
            <th style=" width: 50%;">MD Messages/Events</th>
            <th style=" width: 10%;">From-Date</th>
            <th style=" width: 10%;">To-Date</th>
            <th style=" width: 10%;">Type</th>
            <th style=" width: 10%;">Action</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let EM of EventMessages, let i = index">
            <td scope="row">{{i+1}}</td>
            <td class="text-left">{{EM.Message}}</td>
            <td class="text-left">{{EM.MessageType}}</td>
            <td class="text-left">{{EM.FromDate | date : "dd-MMM-yyyy"}}</td>
            <td class="text-left">{{EM.ToDate | date : "dd-MMM-yyyy"}}</td>
            <td>
              <button *ngIf="this.SelectedMenu.CanEdit" type="button" (click)="GetEventMessageByRefId(EM.RefId,template)" class="table-edit-btn"><i class="fas fa-edit"></i></button> 
              <button *ngIf="this.SelectedMenu.CanDelete" type="button" (click)="DeleteEventMessageById( EM.RefId,template)" class="table-remove-btn"><i class="fas fa-trash-alt"></i></button>
              <button *ngIf="this.SelectedMenu.CanView" type="button" (click)="ViewEventMessageById(EM.RefId,template)" class="table-view-button" type="button"><i class="fas fa-eye"></i></button>
            </td>
          </tr>
        </tbody>
        <tfoot class="thead-blue text-center">
          <tr>
            <th></th>
            <th></th>
            <th></th>
            <th></th>
            <th></th>
            <th></th>
          </tr>
        </tfoot>
      </table>
    </fieldset>
  
    <div style=" width: 1500px;">
      <ng-template #template>
        <div class="modal-header" style="background: #2a9bd7; max-width: 1500px;"> 
          <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()" style="color:#fff;">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div style="text-align: center; max-width: 1500px;">
          <div *ngIf="SaveMsg" style="color: #dc3545; text-align: center;font-size: 15px; font-weight: bold;">Save Successfully</div>
          <div *ngIf="UpdateMsg" style="color: #dc3545; text-align: center;font-size: 15px; font-weight: bold;">Updated Successfully</div>
          <div *ngIf="DeleteMsg" style="color: #dc3545; text-align: center;font-size: 15px; font-weight: bold;">Deleted Successfully</div>
          <div style=" width: 100%;" *ngIf="ShowPopup">
            <fieldset class="body-form-control">
              <legend class="legend--">Event & MD Message</legend>
              <form #EventMessageForm="ngForm" (ngSubmit)="SaveEventMessage(template)">
                <div class="row">
                  <div class="col-lg-4">
                    <div class="col-body-border-right">
                      <div class="form-group row">
                        <label class="col-sm-4 col-form-label clear-padding-right">Message/Event</label>
                        <div class="col-sm-7 clear-left-padding">
                          <textarea type="text" [(ngModel)]="this.EventMessageModel.Message" minlength="10" id="Message" name="Message" #Message="ngModel" required class="md-textarea form-control" mdbInput></textarea>
                        </div>
                        
                        <div *ngIf="Message.invalid && (Message.dirty || Message.touched)" class="alert alert-danger">
                          <div *ngIf="Message.errors.required">Message Or Event Required.</div>
                          <div *ngIf="Message.errors.minlength">Minimum 10 Characters</div>
                        </div>
                      </div>
                      <div class="form-group row">
                        <label class="col-sm-4 col-form-label clear-padding-right">From Date</label>
                        <div class="col-sm-7 clear-left-padding">
                          <input type="date" name="FromDate" [ngModel]="this.EventMessageModel.FromDate | date:'yyyy-MM-dd'" (ngModelChange)="FromDateChanged($event)" #FromDate="ngModel" required/>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="col-lg-4">
                    <div class="col-body-border-right">
                      <div class="form-group row">
                        <label class="col-sm-4 col-form-label clear-padding-right">Type</label>
                        <div class="col-sm-7 clear-left-padding">
                          <select [(ngModel)]="this.EventMessageModel.TypeId" id ="TypeId" class="form-control" name="TypeId" #TypeId="ngModel">
                            <option [value]="0" [disabled]=true >Select Type</option>
                            <option [value]="1">Event</option>
                            <option [value]="2">MD Message</option>
                          </select>
                        </div>
                        <div class="required-validation" *ngIf="this.EventMessageModel.TypeId == 0">Type is required</div>  
                      </div>
                      <div class="form-group row">
                        <label class="col-sm-4 col-form-label clear-padding-right">To Date</label>
                        <div class="col-sm-7 clear-left-padding">
                          <input type="date" name="ToDate" [ngModel]="this.EventMessageModel.ToDate | date:'yyyy-MM-dd'" (ngModelChange)="ToDateChanged($event)" #ToDate="ngModel" required/>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="col-lg-4">
                    <div class="form-group row">
                      <label class="col-sm-4 col-form-label clear-padding-right">&nbsp;</label>
                      <div class="col-sm-7 clear-left-padding">
                        &nbsp;
                      </div>
                    </div>
                    <div class="form-group row">
                      <label class="col-sm-4 col-form-label clear-padding-right">&nbsp;</label>
                      <div class="col-sm-7 clear-left-padding">
                        <button type="submit" class="btn btn-primary save-button" [disabled]="!(EventMessageForm.valid && this.DisableSave==true)">Save</button>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </fieldset>
          </div>
          <button type="button" aria-label="Close" (click)="modalRef.hide()" style="background: #2a9bd7; outline: 0px none; border: 0px none; color: #fff; padding: 5px 10px; border-radius: 3px;">
            <span>Close</span>
          </button>             
        </div>
      </ng-template>
    </div>
  </div>
</div>