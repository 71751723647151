import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs/Subject';
import { DataTableDirective } from 'angular-datatables';
import { UserService } from '../../shared/user.service';
import { OrganizationService } from '../../shared/Organization/organization.service';
import { LevelService } from '../../shared/HR_ADMIN/Lavels_Grades/Levels.service';
import { KRAService } from '../../shared/HR_ADMIN/KRA/KRAService.service';
import { KRAMasterModel } from  '../../shared/HR_ADMIN/KRA/KRAMasterModel.Model';
import { KRAMappingModel } from  '../../shared/HR_ADMIN/KRA/KRAMappingModel.Model';
import { Company } from  '../../shared/Organization/Company.Model';
import { DecisionMaking } from '../../shared/HR_ADMIN/Lavels_Grades/DecisionMaking.Model';
import { Level } from '../../shared/HR_ADMIN/Lavels_Grades/Level.Model';
import { Grade } from '../../shared/HR_ADMIN/Lavels_Grades/Grade.Model';
import { Designation } from  '../../shared/HR_ADMIN/Lavels_Grades/Designation.Model';
// Begning Confirmation
import { ConfirmationService } from '@jaspero/ng2-confirmations';
import { ResolveEmit } from '../../../app/resolve-emit';
import { ConfirmSettings } from '../../../app/confirm-settings';
import { Menu } from '../../shared/menu.model';
// end of Confirmation

@Component({
  selector: 'app-manage-kramapping',
  templateUrl: './manage-kramapping.component.html',
  styleUrls: ['./manage-kramapping.component.css']
})
export class ManageKramappingComponent implements OnInit 
{
  userClaims: any;
  Result : string;
  SuccessStatus:boolean=false;
  SuccessMessage: string ="";
  public tableWidget: any;
  base64textString : string;

  KRAMappingModel : KRAMappingModel= new KRAMappingModel();
  KRAMasterModel : KRAMasterModel= new KRAMasterModel();
  KRAMapping: KRAMappingModel[] = [];

  Companies: Company[] = [];
  company: any;

  //Grade & Level
  DecisionMakings : DecisionMaking[]=[];
  DecisionMaking : any;
  Levels: Level[] = [];
  Level: any;
  Grades: Grade[] = [];
  Grade: any;
  Designations : Designation[]=[];
  Designation : any;

  RefId : any;
  KRAMap: any;

  // Begning Permission Portion    
  SelectedMenu:Menu;
  IsDataIsInViewState : boolean=false;
  CanViewState : boolean=false;
  DisableSave : boolean=false;
  // End of the permission portion
  //check for duplicate component code
  CheckExiststatus : boolean=null;  
  Checkeditstatus : boolean =false;
  check : string ="";
  checkdata : any;
  checkdataval : string ="";
  //check for duplicate component code
  //for Salary Component Dropdown list
  ComponentList = [];
  ComponentselectedItems = [];
  dropdownSettings = {};
  KRAComponentsettings = {};
  KRAComponents :any;
  //for branch Dropdown list

  //Begin for disable
  IsCompanyEnable : boolean = false;
  IsDesignationEnable : boolean = false; 
  //End for disable

  constructor(private KRAService: KRAService, private LevelService:LevelService,private OrganizationService: OrganizationService, private rout: Router,  private _confirmation: ConfirmationService, private userService: UserService) { }
  
  @ViewChild(DataTableDirective, {static: false})
  setDtOptions: DataTables.Settings = {};
  cardDtOptions: DataTables.Settings = {};
  dtElement: DataTableDirective;
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();
  
  ngOnInit() 
  {
    //Begin Permission Portion
    this.SelectedMenu=this.userService.SelectedMenu;
    if(this.SelectedMenu !=undefined)
    {
      localStorage.setItem('selectedmenu',JSON.stringify(this.SelectedMenu));
      this.rout.navigate([this.SelectedMenu.RouteName]);
    }
    else
    {
      if(JSON.parse(localStorage.getItem('selectedmenu'))!=undefined)
      {
        this.SelectedMenu = JSON.parse(localStorage.getItem('selectedmenu'));
        this.rout.navigate([this.SelectedMenu.RouteName]);
      }
    }
    if(this.SelectedMenu.CanAdd)
    {
      this.IsDataIsInViewState=false;
    }
    else
    {
      this.IsDataIsInViewState=true;
    }
    if(this.SelectedMenu.CanView)
    {
      this.CanViewState=false;
    }
    else
    {
      this.CanViewState=true;
    }
    //End of the peremission portion
    this.getCompanies();
    this.getDecisionMaking();
    this.getKRAComponents();
    this.KRAMappingModel.CompanyId=0;
    this.KRAMappingModel.DecisionId=0;
    this.DisableSave=true;
    this.dtOptions = 
    {
      pagingType: 'full_numbers',
      pageLength: 6
    };
    if(localStorage.getItem('SuccessfullMessage'))
    {
      this.SuccessStatus=true;
      this.SuccessMessage =localStorage.getItem('SuccessfullMessage')
      localStorage.removeItem('SuccessfullMessage');
    }  
  }

  ngAfterViewInit(): void 
  {
    this.GetKRAMasterMapping();
    this.dtOptions = 
    {
      pagingType: 'full_numbers',
      pageLength: 6
    };
  }

  settings: ConfirmSettings | any = 
  {
    overlay: true,
    overlayClickToClose: true,
    showCloseButton: true,
    confirmText: 'Yes',
    declineText: 'No'
  };
  
  getCompanies() 
  {
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.OrganizationService.GetCompanyForDDL( t).then(x => 
    {
      this.company = x;
      Object.keys(this.company).forEach( key => 
      {
        // tslint:disable-next-line:no-unused-expression
        this.company[key];
        let Companytemp = new Company();
        Companytemp = this.company[key];
        this.Companies.push(Companytemp);
      });     
    });      
  }

  // getDesignation() 
  // {
  //   let t = localStorage.getItem('userToken');
  //   t = 'Bearer' + t;
  //   this.LevelService.GetDesignation( t).then(x => 
  //   {
  //     this.Designation = x;
  //     Object.keys(this.Designation).forEach( key => 
  //     {
  //       // tslint:disable-next-line:no-unused-expression
  //       this.Designation[key];
  //       let Designationtemp = new Designation();
  //       Designationtemp = this.Designation[key];
  //       this.Designations.push(Designationtemp);
  //     });     
  //   });      
  // }

  GetKRAMasterMapping()
  {
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.KRAService.GetKRAMapping( t).then(x => 
    {
      this.KRAMap = x;
      Object.keys(this.KRAMap).forEach( key => 
      {
        this.KRAMap[key];
        let KRAMaptemp = new KRAMappingModel();
        KRAMaptemp = this.KRAMap[key];
        //this.KRAMappingModel.KRAMasterIds=SalMaptemp.KRAMasterIds;
        this.KRAMapping.push(KRAMaptemp);
      });
      this.dtTrigger.next();
    });
  }
  
  GetKRAMasterMappingByid(id) 
  { 
    //Begin for disable   
    this.IsCompanyEnable  = true;
    this.IsDesignationEnable  = true;      
    //End for disable
    // Begning of the permission portion
    this.IsDataIsInViewState=false;
    // End of the permision portion
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.KRAService.GetKRAMappingById( t,id).then(x => 
    {
      this.RefId = x;
      Object.keys(this.RefId).forEach( key => 
      {
        // tslint:disable-next-line:no-unused-expression
        this.RefId[key];
        let componenttemp = new KRAMappingModel();
        componenttemp = this.RefId[key];
        this.KRAMappingModel.RefId=componenttemp.RefId;
        this.KRAMappingModel.CompanyId=componenttemp.CompanyId;
        this.KRAMappingModel.DecisionId=componenttemp.DecisionId;
        this.KRAMappingModel.LevelId=componenttemp.LevelId;
        this.KRAMappingModel.GradeId=componenttemp.GradeId;
        this.KRAMappingModel.DesignationId=componenttemp.DesignationId;
        this.KRAMappingModel.KRAMasterIds=componenttemp.KRAMasterIds;
      });
      this.getKRAComponents();
      this.getLevel(this.KRAMappingModel.DecisionId);
      this.getGrade(this.KRAMappingModel.LevelId);
      this.getDesignation(this.KRAMappingModel.GradeId);
      // Begin for check company code duplication
      this.checkdataval= this.KRAMappingModel.KRAMasterIds;
      this.Checkeditstatus=true;
      this.CheckExiststatus =true;
      this.check=""
      // End for check company code duplication
    });
  }

  viewKRAMasterMappingByid(id) 
  {
    //Begin for disable   
    this.IsCompanyEnable  = true;
    this.IsDesignationEnable  = true;      
    //End for disable
    //begin permission
    this.IsDataIsInViewState=true;
    //end permission
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.KRAService.GetKRAMappingById( t,id).then(x => 
      {
        this.RefId = x;
        Object.keys(this.RefId).forEach( key => 
        {
          // tslint:disable-next-line:no-unused-expression
          this.RefId[key];
          let componenttemp = new KRAMappingModel();
          componenttemp = this.RefId[key];
          this.KRAMappingModel.RefId=componenttemp.RefId;
          this.KRAMappingModel.CompanyId=componenttemp.CompanyId;
          this.KRAMappingModel.DecisionId=componenttemp.DecisionId;
          this.KRAMappingModel.LevelId=componenttemp.LevelId;
          this.KRAMappingModel.GradeId=componenttemp.GradeId;
          this.KRAMappingModel.DesignationId=componenttemp.DesignationId;
          this.KRAMappingModel.KRAMasterIds=componenttemp.KRAMasterIds;
        });
        this.getKRAComponents();
        this.getLevel(this.KRAMappingModel.DecisionId);
        this.getGrade(this.KRAMappingModel.LevelId);
        this.getDesignation(this.KRAMappingModel.GradeId);
        // Begin for check company code duplication
        this.checkdataval= this.KRAMappingModel.KRAMasterIds;
        this.Checkeditstatus=true;
        this.CheckExiststatus =true;
        this.check=""
        // End for check company code duplication
      });
  }

  saveKRAMapping() :void
  {
    this.DisableSave=false;
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.KRAMappingModel.IsActive=true;
    if(this.KRAMappingModel.RefId > 0)
    {
      if(this.ComponentselectedItems.length > 0)  
      {
        this.KRAMappingModel.KRAMasterIds='';
        this.ComponentselectedItems.forEach(element => 
        {
          this.KRAMappingModel.KRAMasterIds= this.KRAMappingModel.KRAMasterIds + element.id + ",";
        });
        var l = this.KRAMappingModel.KRAMasterIds.length
        this.KRAMappingModel.KRAMasterIds = this.KRAMappingModel.KRAMasterIds.substr(0,l-1);
        this.KRAService.UpdateKRAMapping(t ,this.KRAMappingModel).subscribe(res=>
        {
          this.RefId=res;
          this.KRAMappingModel.RefId=0;  
          if(this.RefId>0)
          {
            localStorage.setItem('SuccessfullMessage', "Update Successfully");
            this.rout.navigateByUrl('/Appraisal/KRAMaster', {skipLocationChange: true}).then(()=>this.rout.navigate(["/Appraisal/KRAMapping"]));
          }
        });
      }
    }
    else
    {
      if(this.ComponentselectedItems.length > 0)  
      {
        this.KRAMappingModel.KRAMasterIds='';
        this.ComponentselectedItems .forEach(element => 
        {
          this.KRAMappingModel.KRAMasterIds= this.KRAMappingModel.KRAMasterIds + element.id + ",";         
        });
        var l = this.KRAMappingModel.KRAMasterIds.length
        this.KRAMappingModel.KRAMasterIds = this.KRAMappingModel.KRAMasterIds.substr(0,l-1);
        this.KRAService.AddKRAMapping(t ,this.KRAMappingModel).subscribe(res=>
        {
          this.RefId=res;
          this.KRAMappingModel.RefId=0;              
          if(this.RefId>0)
          {
            localStorage.setItem('SuccessfullMessage', "Save Successfully");
            this.rout.navigateByUrl('/Appraisal/KRAMaster', {skipLocationChange: true}).then(()=>this.rout.navigate(["/Appraisal/KRAMapping"]));
          }
        }); 
      }
    }
  }

  DeleteKRAMappingByid(Refid : number) :void
  {
    if(this.SelectedMenu.CanAdd)
    {
     this.IsDataIsInViewState=false;
    }
    else
    {
      this.IsDataIsInViewState=true;
    }
    this._confirmation.create('Confirm to Deleate', 'Press Yes to Delete', this.settings)
      .subscribe((ans: ResolveEmit) => 
      {
        if (ans.resolved == true) 
        {
          let t =  localStorage.getItem('userToken');
          t = 'Bearer' + t;
          this.KRAMappingModel.IsActive=true;
          if( Refid > 0)
          {
            this.KRAService.DeleteKRAMapping(t ,Refid).subscribe(res=>
            {
              this.RefId=res;
              this.KRAMappingModel.RefId=0;                                  
              if(this.RefId >0)
              {
                localStorage.setItem('SuccessfullMessage', "Deleted Successfully");
                this.rout.navigateByUrl('/Appraisal/KRAMaster', {skipLocationChange: true}).then(()=>this.rout.navigate(["/Appraisal/KRAMapping"]));
              }   
            });                  
          }
        }
      })
  }
  // begning of check duplicate Salary Mapping
  CheckKRAMAPPING()
  {
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    if(!this.Checkeditstatus)
    {
      this.KRAService.CheckKRAMapping( t, this.KRAMappingModel.CompanyId, this.KRAMappingModel.DesignationId).then(x => 
      {
        this.checkdata = x;
        if( this.checkdata.length >0)
        {
          this.CheckExiststatus =false;
          this.check="Exist"
        }
        else
        {
          this.CheckExiststatus =true;
          this.check="";
        }
     });
    }
    else
    {
      if(this.KRAMappingModel.KRAMasterIds !=this.checkdataval )
      {
        this.KRAService.CheckKRAMapping( t, this.KRAMappingModel.CompanyId,this.KRAMappingModel.DesignationId).then(x => 
        {
          this.checkdata = x;
          if( this.checkdata.length >0)
          {
            this.CheckExiststatus =false;
            this.check="Exist"
          }
          else
          {
            this.CheckExiststatus =true;
            this.check="";
          }
        });
      }
      else
      {
        this.CheckExiststatus =true;
        this.check="";
      }
    }
  }
  // end of duplicate Salary Mapping
  
  getKRAComponents() 
  {
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t
    this.ComponentselectedItems = [];
    this.ComponentList = [];
    this.KRAService.GetKRAMaster( t).then(x => 
    {
      this.KRAComponents = x;
      Object.keys(this.KRAComponents).forEach( key => 
      {
        // tslint:disable-next-line:no-unused-expression
        this.KRAComponents[key];
        let  Componenttemp= new KRAMasterModel();
        Componenttemp = this.KRAComponents[key];
        this.ComponentList.push({"id":Componenttemp.RefId,"itemName":Componenttemp.KRAName},);
      });  
      if(this.ComponentList.length > 0)  
      {
        var selected_br = this.KRAMappingModel.KRAMasterIds.split(',');
        for (var _i = 0; _i < selected_br.length; _i++) 
        {
          var item = this.ComponentList.find(x=>x.id==selected_br[_i])
          this.ComponentselectedItems.push(item);
        }
      }
    });      
  }

  onItemSelect(item:any){ }
  OnItemDeSelect(item:any){ }
  onSelectAll(items: any){ }
  onDeSelectAll(items: any){ }

  RefreshPage()
  {
    this.rout.navigateByUrl('/Appraisal/KRAMaster', {skipLocationChange: true}).then(()=>this.rout.navigate(["/Appraisal/KRAMapping"]))
  }

  //Begin Grades & Level
  getDecisionMaking() 
  {
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.LevelService.GetDecisionMaking( t).then(x => 
    {
      this.DecisionMaking = x;
      Object.keys(this.DecisionMaking).forEach( key => 
      {
        this.DecisionMaking[key];
        let DecisionMakingtemp = new DecisionMaking();
        DecisionMakingtemp = this.DecisionMaking[key];
        this.DecisionMakings.push(DecisionMakingtemp);
      });     
    });      
  }
  
  getLevel( DecisionMakingid) 
  {
    this.Levels=[];
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t
    if(this.Levels.length>0)
    {
      this.Level.splice(0, this.Level.length);
    }
    this.LevelService.GetLevelByDecisionid( t,DecisionMakingid).then(x => 
    {
      this.Level = x;
      Object.keys(this.Level).forEach( key => 
      {
        this.Level[key];
        let Leveltemp = new Level();
        Leveltemp = this.Level[key];
        this.Levels.push(Leveltemp);
      });     
    });      
  }
  
  getGrade(Levelid) 
  {
    this.Grades=[];
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t
    if(this.Grades.length>0)
    {
      this.Grades.splice(0,this.Grades.length )
    }
    this.LevelService.GetGradeByLevelid( t,Levelid).then(x => 
    {
      this.Grade = x;
      Object.keys(this.Grade).forEach( key => 
      {
        this.Grade[key];
        let Gradetemp = new Grade();
        Gradetemp = this.Grade[key];
        this.Grades.push(Gradetemp);
      });     
    });      
  }
  
  getDesignation(Gradeid) 
  {
    this.Designations=[];
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t
    if(this.Designations.length>0)
    {
      this.Designations.splice(0,this.Designations.length )
    }
    this.LevelService.GetDesignationByGradeid( t,Gradeid).then(x => 
    {
      this.Designation = x;
      Object.keys(this.Designation).forEach( key => 
      {
        this.Designation[key];
        let Designationtemp = new Designation();
        Designationtemp = this.Designation[key];
        this.Designations.push(Designationtemp);
      });     
    });      
  }
  
  onDecisionChange(Decisionid) 
  {
    if(Decisionid!=0)
    {
      this.getLevel(Decisionid);
      this.KRAMappingModel.LevelId=0;
    }
    else
    {
      this.getLevel(Decisionid);
      this.KRAMappingModel.LevelId=0;
      this.getGrade(0);
    }
    this.KRAMappingModel.GradeId=0;
    this.KRAMappingModel.DesignationId=0;
  }
  
  onLevelChange(Levelid)
  {
    if(Levelid!=0)
    {
      this.getGrade(Levelid);
      this.KRAMappingModel.GradeId=0;
    }
    else
    {
      this.getGrade(Levelid);
      this.KRAMappingModel.GradeId=0;
      this.getDesignation(0);
    } 
    this.KRAMappingModel.DesignationId=0;
  }
  
  onGradechange(Gradeid)
  {
    if(Gradeid!=0)
    {
      this.getDesignation(Gradeid);
      this.KRAMappingModel.DesignationId=0;
    }
    else
    {
      this.getDesignation(Gradeid);
      this.KRAMappingModel.DesignationId=0;
    }        
  }
  //End Grades & Level
}