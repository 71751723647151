import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from '../../shared/user.service';
import { EmployeePortalService }  from '../../shared/HR_ADMIN/EmployeePortal/EmployeePortalService.service';
import { EmployeeMasterService }  from '../../shared/HR_ADMIN/Employee/EmployeeMaster.Service';
import { EmployeeDetail } from '../../shared/HR_ADMIN/Employee/EmployeeDetail.Model';
import { DirectDuty }  from '../../shared/HR_ADMIN/EmployeePortal/DirectDuty.Model';
import { Menu } from '../../shared/menu.model';

@Component({
  selector: 'app-all-employee-direct-duty-application',
  templateUrl: './all-employee-direct-duty-application.component.html',
  styleUrls: ['./all-employee-direct-duty-application.component.css']
})

export class AllEmployeeDirectDutyApplicationComponent implements OnInit 
{
  DirectDutyModel : DirectDuty= new DirectDuty();
  DirectDutyList: DirectDuty[]=[];
  DirectDuty: any;
  DirectDutyApplication: any;
  DirectId : any;
  userClaims: any;
  Result : string;
  SuccessStatus:boolean=false;
  SuccessMessage: string ="";
  public tableWidget: any;
  selectedIndex : number;
  // Begning Permission Portion    
  SelectedMenu:Menu;
  // End of the permission portion
  // begin for Validation
  validdate : boolean= false;
  DisableSave : boolean=false;
  AppliedStatus : boolean;
  // end of validation

  //
  FromFirstHalf : boolean= true;
  FromSecondHalf : boolean= false;
  FromValue:string='FirstHalf';

  ToFirstHalf : boolean= true;
  ToSecondHalf : boolean= false;
  ToValue:string='FirstHalf';

  ShowMessage : boolean=false;
  ShowBalanceMessage : boolean=false;
  Differencevalue: number;

  FullDayShowMessage : boolean=false;
  FirstHalfShowMessage : boolean=false;
  SecondHalfShowMessage : boolean=false;

  Employees: EmployeeDetail[] = [];
  employee: any;

  LeaveType: any;
  RefId : any;
  
  // Begning Permission Portion    
  IsDataIsInViewState : boolean=false;
  CanViewState : boolean=true;
  IsApplicationView : boolean=false;
  // End of the permission portion
  EmployeeSettings = {};
  selectedItems = [];
  emp : any [] =[]

  constructor(private EmployeeMasterService: EmployeeMasterService, private rout: Router, private userService: UserService,private EmployeePortalService: EmployeePortalService) 
  {  }

  ngOnInit() 
  {
    // Begning Permission Portion 
    this.SelectedMenu=this.userService.SelectedMenu;
    if(this.SelectedMenu !=undefined)
    {
      localStorage.setItem('selectedmenu',JSON.stringify(this.SelectedMenu));
      this.rout.navigate([this.SelectedMenu.RouteName]);
    }
    else
    {
      if(JSON.parse(localStorage.getItem('selectedmenu'))!=undefined)
      {
        this.SelectedMenu = JSON.parse(localStorage.getItem('selectedmenu'));
        this.rout.navigate([this.SelectedMenu.RouteName]);
      }
    }
    if(this.SelectedMenu.CanAdd)
    {
      this.IsDataIsInViewState=false;
    }
    else
    {
      this.IsDataIsInViewState=true;
    }
    if(this.SelectedMenu.CanView)
    {
      this.CanViewState=false;
    }
    else
    {
      this.CanViewState=true;
    }
    // End of the permission portion
    if(localStorage.getItem('SuccessfullMessage'))
    {
      this.SuccessStatus=true;
      this.SuccessMessage =localStorage.getItem('SuccessfullMessage')
      localStorage.removeItem('SuccessfullMessage');
    }
    this.GetEmployeeDetailsDDL();
    this.DirectDutyModel.EmpId=0;
    this.FromFirstHalf==true;
    this.FromSecondHalf==false;
    this.DirectDutyModel.FirstHalf="";
    this.ToFirstHalf==false;
    this.ToSecondHalf==true; 
    this.DirectDutyModel.SecondHalf="";
    this.DirectDutyModel.Fullday="Fullday";
    this.Differencevalue=0;
    this.FullDayShowMessage=false;
    this.FirstHalfShowMessage=false;
    this.SecondHalfShowMessage=false;
    this.DisableSave=true;
    this.EmployeeSettings =
    {
      singleSelection:true,
      text:'Select Employee',
      enableSearchFilter:true,
      showCheckbox:false
    }
  }
  
  GetEmployeeDetailsDDL() 
  {
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.EmployeeMasterService.GetEmployeeDetailsForDDL( t).then(x => 
    {
      this.employee = x;
      Object.keys(this.employee).forEach( key => 
      {
        let emptemp = new EmployeeDetail();
        emptemp = this.employee[key];
        this.Employees.push(emptemp);
        this.emp.push({"id":emptemp.EmployeeId,"itemName":emptemp.EmployeeCode + '-' +emptemp.EmployeeName},);
      });     
    });      
  }

  onItemSelect(item:any)
  {
    let EmployeeId= item['id'];
    if(EmployeeId>0)
    {
      this.DirectDutyModel.EmpId=EmployeeId;
      this.IsApplicationView=true;
      this.GetEmployeeDirectDutyList(EmployeeId);
      this.GetEmployeeDetails(EmployeeId);
    }     
  }
  OnItemDeSelect(item:any){ }
  onSelectAll(items: any){ }
  onDeSelectAll(items: any){ }

  GetEmployeeDetails(EmployeeId)
  {
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.EmployeePortalService.GetEmployeeDetailsByEmployeeIdLeaveApply( t,EmployeeId).then(x => 
    {
      this.DirectDutyApplication = x;
      Object.keys(this.DirectDutyApplication).forEach( key => 
      {
        this.DirectDutyApplication[key];
        let DirectDutytemp = new DirectDuty();
        DirectDutytemp = this.DirectDutyApplication[key];
        this.DirectDutyModel.EmpId=this.DirectDutyApplication.EmployeeId;
        this.DirectDutyModel.EmployeeCode=this.DirectDutyApplication.EmployeeCode;
        this.DirectDutyModel.EmployeeName=this.DirectDutyApplication.EmployeeName;
        this.DirectDutyModel.EmployeeEmailId=this.DirectDutyApplication.EmployeeEmailId;
        this.DirectDutyModel.RHId=this.DirectDutyApplication.RHId;
        this.DirectDutyModel.RHCode=this.DirectDutyApplication.RHCode;
        this.DirectDutyModel.RHName=this.DirectDutyApplication.RHName;
        this.DirectDutyModel.RHEmailId=this.DirectDutyApplication.RHEmailId;
        this.DirectDutyModel.DateOfApply=this.DirectDutyApplication.ApplicationDate;
        this.DirectDutyModel.CompanyId=this.DirectDutyApplication.CompanyId;
      });
    });
  }

  GetEmployeeDirectDutyList(EmployeeId)
  {
    this.DirectDutyList=[];
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.EmployeePortalService.GetEmployeeDirectDutyStatusByEmployeeId( t,EmployeeId).then(x => 
    {
      this.DirectDutyApplication = x;
      Object.keys(this.DirectDutyApplication).forEach( key => 
      {
        this.DirectDutyApplication[key];
        let DidrectDeutytemp = new DirectDuty();
        DidrectDeutytemp = this.DirectDutyApplication[key];
        this.DirectDutyList.push(DidrectDeutytemp);
      });
    });
  }

  ApplydateChanged( date)
  {
    this.CheckDirectDutyExists(date);
    this.DirectDutyModel.IsMorningDirectduty=false;
    this.DirectDutyModel.IsSecondHalfDirectDuty=false;
    this.DirectDutyModel.IsFullDayDirectDuty=false; 
    this.DirectDutyModel.SecondHalf="";
    this.DirectDutyModel.FirstHalf="";
    this.DirectDutyModel.Fullday="";
    this.FullDayShowMessage=false;
    this.FirstHalfShowMessage=false;
    this.SecondHalfShowMessage=false;

    this.DirectDutyModel.DateOfDirectDuty=date;
    
    var CurrentDate = new Date();
    var currentMonth = CurrentDate.getMonth();
    var CurrentDay = CurrentDate.getDate();
    
    var selectedDate = new Date(date);
    var selectedMonth=selectedDate.getMonth();
    var selectedDay=selectedDate.getDate();

    let selecteddateString = selectedDate.toDateString();
    let newselectedDate = new Date(selecteddateString);
    let CurrentdateString = CurrentDate.toDateString(); 
    let newCurrentDate = new Date(CurrentdateString);
    if (newselectedDate >= newCurrentDate)
    {
      this.validdate = false;
    }
    else if (currentMonth == selectedMonth)
    {
      if(CurrentDay <=  26)
      {
        this.validdate = false;
      }
      else
      {
        if(selectedDay > 25)
        {
          this.validdate = false;
        }
        else
        {
          this.validdate = true;
        }
      }
    }
    else if (currentMonth == 0 && selectedMonth == 11)
    {
      if(selectedDay >= 26 )
      {
        this.validdate = false;
      }
      else
      {
        this.validdate = true;
      }
    }
    else if ((currentMonth-1) == selectedMonth)
    {
      if(selectedDay >= 26 )
      {
        this.validdate = false;
      }
      else
      {
        this.validdate = true;
      }
    }
    else
    {
      this.validdate = true;
    }

    if(this.validdate==false)
    {
      this.ShowMessage=false;
    }
    else
    {
      this.ShowMessage=true;
    }
  }

  OnChangeFromFirstHalf(evt)
  {
    var target = evt.target;
    if(target.checked)
    {           
      this.DirectDutyModel.IsMorningDirectduty=true;
      this.DirectDutyModel.IsSecondHalfDirectDuty=false; 
      this.DirectDutyModel.IsFullDayDirectDuty=false; 
      this.DirectDutyModel.FirstHalf="FirstHalf";
      this.DirectDutyModel.SecondHalf="";
      this.DirectDutyModel.Fullday="";
    }
  }

  OnChangeFromSecondHalf(evt)
  {
    var target = evt.target;
    if(target.checked)
    {           
      this.DirectDutyModel.IsMorningDirectduty=false;
      this.DirectDutyModel.IsSecondHalfDirectDuty=true;
      this.DirectDutyModel.IsFullDayDirectDuty=false; 
      this.DirectDutyModel.SecondHalf="SecondHalf";
      this.DirectDutyModel.FirstHalf="";
      this.DirectDutyModel.Fullday="";
    }
  }

  OnChangeFullday(evt)
  {
    var target = evt.target;
    if(target.checked)
    {           
      this.DirectDutyModel.IsMorningDirectduty=false;
      this.DirectDutyModel.IsSecondHalfDirectDuty=false;
      this.DirectDutyModel.IsFullDayDirectDuty=true; 
      this.DirectDutyModel.SecondHalf="";
      this.DirectDutyModel.FirstHalf="";
      this.DirectDutyModel.Fullday="Fullday";
    }
  }

  SaveEmployeeDirectDuty() :void
  {
    this.DisableSave=false;
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.EmployeePortalService.AddEmployeeDirectDuty(t ,this.DirectDutyModel).subscribe(res=>
    {
      this.DirectId=res;
      this.DirectDutyModel.DirectId=0;              
      if(this.DirectId>0)
      {
        localStorage.setItem('SuccessfullMessage', "Direct Duty Apply Successfully");
        this.rout.navigateByUrl('/Employee/EmployeeOffer', {skipLocationChange: true}).then(()=>this.rout.navigate(["/Employee/AllEmployeeDirectDutyApplication"]));
      }
    }); 
  }

  RefreshPage()
  {
    this.rout.navigateByUrl('/Employee/EmployeeOffer', {skipLocationChange: true}).then(()=>this.rout.navigate(["/Employee/AllEmployeeDirectDutyApplication"]));
  }

  CheckDirectDutyExists(AttendanceDate)
  {
    let t =  localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.EmployeePortalService.CheckDirectDutyExistsByEmployeeId( t,this.DirectDutyModel.EmpId,AttendanceDate).then(x => 
    {
      this.DirectDuty = x;
      //this.AppliedStatus=this.DirectDuty.AppliedStatus;
      Object.keys(this.DirectDuty).forEach( key => 
      {
        this.DirectDuty[key];
        let DirectDutytemp = new DirectDuty();
        DirectDutytemp = this.DirectDuty[key];
        this.AppliedStatus=DirectDutytemp.AppliedStatus;
      });
    });
  }
}