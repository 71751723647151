<div>
    <app-dialog [(visible)]="this.SuccessStatus">
        <span class="successmsg">{{this.SuccessMessage}}</span>
    </app-dialog>
</div>

<perfect-scrollbar style="max-width: 1260px; max-height: 540px;">



    <div class="body-content-area tabcontent" id="link1">
        <div class="row">
            <div class="col-lg-9">
                <div class="row">
                    <div class="col-lg-1 low-padding text-center">


                    </div>

                    <div class="col-lg-10 clear-padding">
                        <span class="page-title">ADD CRM STATE </span>
                        <small class="page-description">It is a long established fact that a reader will be
                            distracted</small>
                    </div>
                </div>
            </div>
        </div>

        <div>

            <table id="tblCRMState" datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger"
                class="table table-striped table-bordered text-center table-new row-border hover">

                <thead class="thead-blue text-center">

                    <tr>
                        <th>Sl No.</th>
                        <th class="text-left">State</th>
                        <th class="text-left">Country</th>
                        <th class="text-left">Zone</th>

                        <th>Action</th>
                    </tr>

                </thead>

                <tbody>
                    <tr *ngFor="let s of statelist, let i = index">
                        <td scope="row">{{i+1}}</td>
                        <td class="text-left">{{s.StateName}}</td>
                        <td class="text-left">{{ s.CountryName}}</td>
                        <td class="text-left">{{s.ZoneName}}</td>


                        <td>
                            <!-- <button *ngIf="this.SelectedMenu.CanEdit" type="button" (click)="GetCRMStateById( s.StateId )" class="table-edit-btn">
                            <i class="fas fa-edit"></i>
                        </button>
                    
                        <button *ngIf="this.SelectedMenu.CanDelete" type="button" (click)="DeleteState( s.StateId )" class="table-remove-btn">
                            <i class="fas fa-trash-alt"></i>
                        </button>
                    
                        <button *ngIf="this.SelectedMenu.CanView" type="button" (click)="ViewCRMStateById( s.StateId )" class="table-view-button" type="button">
                            <i class="fas fa-eye"></i>
                        </button> -->


                            <button type="button" *ngIf="this.userService.selectedCrmMenu.CanEdit"
                                (click)="GetCRMStateById( s.StateId )" class="table-edit-btn">
                                <i class="fas fa-edit"></i>
                            </button>

                            <button type="button" *ngIf="this.userService.selectedCrmMenu.CanDelete"
                                (click)="DeleteState( s.StateId )" class="table-remove-btn">
                                <i class="fas fa-trash-alt"></i>
                            </button>

                            <button type="button" *ngIf="this.userService.selectedCrmMenu.CanView"
                                (click)="ViewCRMStateById( s.StateId )" class="table-view-button" type="button">
                                <i class="fas fa-eye"></i>
                            </button>


                        </td>

                    </tr>

                </tbody>


            </table>

            <fieldset class="body-form-control">



                <legend class="legend--">ADD STATE</legend>
                <form #StateName="ngForm" (ngSubmit)="saveCRMState()">



                    <div class="row">
                        <div class="col-lg-4">
                            <div class="col-body-border-right">
                                <div class="form-group row">
                                    <label class="col-sm-4 col-form-label clear-padding-right">STATE NAME</label>
                                    <div class="col-sm-7 clear-left-padding">
                                        <input type="text" [(ngModel)]=" CRM_State_Model.StateName" tabindex="1"
                                            maxlength="250" id="StateName" name="StateName" #StateName="ngModel"
                                            required class="form-control form-control-custom form-control-custom-new">
                                    </div>

                                    <div *ngIf="StateName.invalid && (StateName.dirty || StateName.touched)"
                                        class="alert alert-danger">
                                        <div *ngIf="StateName.errors.required">State Name is required.</div>
                                        <div *ngIf="StateName.errors.maxlength">State Name is not more than 250
                                            characters</div>
                                    </div>


                                </div>
                            </div>
                        </div>




                        <div class="col-lg-4">
                            <div class="form-group row">
                                <label class="col-sm-4 col-form-label clear-padding-right">COUNTRY </label>
                                <div class="col-sm-7 clear-left-padding">
                                    <div *ngIf="this.CRM_State_Model.CountryId ==0 " class="required-validation">*</div>
                                    <!-- <select   [(ngModel)]="CRM_State_Model.CountryId" tabindex="3" id="CountryId" class="form-control" name="CountryId" #CountryId="ngModel">
                                    <option [value]="0" [disabled]=true> Select Country</option>
                            <option *ngFor="let c of countrylist" [value]="c.CountryId">{{c.CountryName}}</option>
                                </select> -->

                                    <angular2-multiselect tabindex="5" class="form-control" [data]="CountryArray"
                                        [(ngModel)]="SelectedCountry" name="CountryId" [settings]="CountrySttings"
                                        (onSelect)="onItemSelectCountry($event)"
                                        (onDeSelect)="OnItemDeSelectCountry($event)"
                                        (onSelectAll)="onSelectAllCountry($event)"
                                        (onDeSelectAll)="onDeSelectAllCountry($event)"></angular2-multiselect>

                                </div>

                            </div>



                        </div>



                        <div class="col-lg-4">
                            <div class="form-group row">
                                <label class="col-sm-4 col-form-label clear-padding-right">ZONE </label>
                                <div class="col-sm-7 clear-left-padding">
                                    <!-- <select [(ngModel)]="CRM_State_Model.ZoneId" tabindex="3" (ngModelChange)="onModuleChange($event)" id="ZoneId" class="form-control"
                                    name="ZoneId" #ZoneId="ngModel">
                                    <option [value]="0" [disabled]=true> Select Zone </option>
                                    <option *ngFor="let z of zonelist" [value]="z.ZoneId">{{z.ZoneName}}</option>
                                </select> -->
                                    <div *ngIf="this.CRM_State_Model.ZoneId ==0 " class="required-validation">*</div>
                                    <angular2-multiselect tabindex="5" class="form-control" [data]="ZoneArray"
                                        [(ngModel)]="SelectedZone" name="ZoneId" [settings]="ZoneSettings"
                                        (onSelect)="onItemSelecZone($event)" (onDeSelect)="OnItemDeSelectZone($event)"
                                        (onSelectAll)="onSelectAllZone($event)"
                                        (onDeSelectAll)="onDeSelectAllZone($event)"></angular2-multiselect>

                                </div>

                            </div>


                        </div>


                    </div>
                    <div class="row">
                        <div class="col-lg-4">
                            <div class="form-group row">
                            </div>
                        </div>
                        <div class="col-lg-4">
                            <div class="form-group row">
                            </div>
                        </div>
                        <div class="col-lg-4">
                            <div class="form-group row">
                                <div class="col-sm-5 col-form-label text-center" *ngIf="!(this.IsDataIsInViewState)">
                                    <button type="submit"
                                        *ngIf="(this.CRM_State_Model.CountryId !=0 
                                        && this.CRM_State_Model.ZoneId !=0 
                                        && (this.CRM_State_Model.StateName !='' || this.CRM_State_Model.StateName != undefined) )  "
                                        tabindex="3" class="btn btn-primary save-button">Save</button>
                                </div>
                                <div class="col-sm-5 col-form-label text-center">
                                    <button type="button" tabindex="4" (click)="RefreshPage()"
                                        class="btn btn-danger cancle-button">Cancel</button>
                                </div>
                            </div>
                        </div>

                    </div>
                </form>
            </fieldset>
        </div>
    </div>

</perfect-scrollbar>