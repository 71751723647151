import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs/Subject';
import { Menu } from '../../../shared/menu.model';
import { UserService } from '../../../shared/user.service';
//Begin For Notification service
import { NotificationsService } from '../../../shared/notifications.service';
import { Model_CrmCountry } from '../../../shared/CRM/MICECRM/Model_CrmCountry';
import { CrmCityService } from '../../../shared/CRM/MICECRM/CrmCityService';
import { Model_CRM_City } from '../../../shared/CRM/MICECRM/Model_CRM_City';
import { Model_CRM_Zone } from '../../../shared/CRM/MICECRM/Model_CRM_Zone';
import { Model_CRM_State } from '../../../shared/CRM/MICECRM/Model_CRM_State';
import { DataTableDirective } from 'angular-datatables';
import { ConfirmationService } from '@jaspero/ng2-confirmations';
import { ResolveEmit } from '@jaspero/ng2-confirmations/src/interfaces/resolve-emit';
import { ConfirmSettings } from '@jaspero/ng2-confirmations/src/interfaces/confirm-settings';
//End of notification service

@Component({
  selector: 'app-crm-city',
  templateUrl: './crm-city.component.html',
  styleUrls: ['./crm-city.component.css']
})


export class CrmCityComponent implements OnInit {

  //  View  Field Decleration Starts
  check: string;
  CheckExiststatus: boolean;
  Checkeditstatus: boolean;
  checkdataval: any;

  //  View  Field Decleration Starts

  @ViewChild(DataTableDirective, {static: false})
  setDtOptions: DataTables.Settings = {};
  cardDtOptions: DataTables.Settings = {};
  dtElement: DataTableDirective;
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();


  //searchable Dropdown

  ZoneArray: any[] = [];
  CountryArray: any[] = [];
  ZoneSettings = {}
  CountrySttings = {}
  StateArray: any[] = [];
  StateSettings = {};

  SelectedCountry = [];
  SelectedZone = [];
  SelectedState = [];




  // For Populate Country Dropdown
  CRM_City_Model: Model_CRM_City;
  CountryName: any;
  countrylist: Model_CrmCountry[] = [];
  Crm_Country: Model_CrmCountry;


  // For Populate Zone Dropdown
  ZoneName: any;
  zonelist: Model_CRM_Zone[] = [];
  Crm_Zone: Model_CRM_Zone;
  IsSavevisible: boolean = true;

  // Begin success message popup
  SuccessStatus: boolean;
  SuccessMessage: string;
  // end success message popup

  // For Populate State Drop Down 
  StateName: any;
  statelist: Model_CRM_State[] = [];

  // Add-View-Delete  City Field 

  CityId: number;
  DeleteCityNameByID: any;


  // Grid Fetch 

  citylist: Model_CRM_City[] = [];
  CRMCity: any;



  // Begning Permission Portion    
  SelectedMenu: Menu;
  IsDataIsInViewState: boolean = false;
  CanViewState: boolean = true;
  selectedIndex: number;
  // End of the permission portion

  constructor( private rout: Router, public userService: UserService, private notificationsService: NotificationsService, private CRM_CityService: CrmCityService, private _confirmation: ConfirmationService) {


    this.CRM_City_Model = new Model_CRM_City();





    notificationsService.emitChange.subscribe(slectedtabindex => {
      if (slectedtabindex != null) {
        this.selectedIndex = slectedtabindex;
      }
    });
  }






  ngOnInit() {

    if (this.CRM_City_Model.CityName == undefined) {
      this.CRM_City_Model.CityName = '';

    }

    this.rout.navigate([this.userService.selectedCrmMenu.RouteName]);


    this.ZoneSettings = {
      singleSelection: true,
      text: 'Select Zone',
      enableSearchFilter: true,
      showCheckbox: false
    }

    this.CountrySttings = {
      singleSelection: true,
      text: 'Select Country',
      enableSearchFilter: true,
      showCheckbox: false
    }


    this.StateSettings = {
      singleSelection: true,
      text: 'Select State',
      enableSearchFilter: true,
      showCheckbox: false
    }






    if (localStorage.getItem('SuccessfullMessage')) {
      this.SuccessStatus = true;
      this.SuccessMessage = localStorage.getItem('SuccessfullMessage')
      localStorage.removeItem('SuccessfullMessage');
    }


    this.CRM_City_Model.CountryId = 0;
    this.CRM_City_Model.ZoneId = 0;
    this.CRM_City_Model.StateId = 0;
    this.GetCountry();
    this.SelectedCountry = [{ "id": 0, "itemName": 'Select Country' }];
    //this.GetZone();
    // this.GetState();
    this.GetCRMCity();

  }

  ngAfterViewInit(): void {



    this.dtOptions =
      {
        pagingType: 'full_numbers',
        pageLength: 4
      };
  }


  settings: ConfirmSettings | any =
    {
      overlay: true,
      overlayClickToClose: true,
      showCloseButton: true,
      confirmText: 'Yes',
      declineText: 'No'
    };

  //Populate  Country Drop  Down  

  GetCountry() {
    let t = localStorage.getItem('userToken');
    t = 'Bearer' + t
    this.countrylist = [];
    this.CRM_CityService.GetCountryType(t).then(x => {
      this.CountryName = x;
      Object.keys(this.CountryName).forEach(key => {
        let CountryNametemp = new Model_CrmCountry();
        CountryNametemp = this.CountryName[key];
        // alert('1');
        this.countrylist.push(CountryNametemp);
        this.CountryArray.push({ "id": CountryNametemp.CountryId, "itemName": CountryNametemp.CountryName });
      });

    });
  }

  //Populate  Country Drop  Down  ENDS


  //Populate  ZOne  Drop  Down  

  GetZone(Countryid: number) {
    let t = localStorage.getItem('userToken');
    t = 'Bearer' + t
    this.zonelist = [];
    this.CRM_CityService.GetZoneType(t, Countryid).then(x => {
      this.ZoneName = x;
      Object.keys(this.ZoneName).forEach(key => {
        let ZoneNametemp = new Model_CRM_Zone();
        ZoneNametemp = this.ZoneName[key];
        this.zonelist.push(ZoneNametemp);
        this.ZoneArray.push({ "id": ZoneNametemp.ZoneId, "itemName": ZoneNametemp.ZoneName });
      });

    }).then(t => {

      if (this.CRM_City_Model.ZoneId > 0) {
        this.SelectedZone = [];
        let Zone = this.zonelist.find(x => x.ZoneId == this.CRM_City_Model.ZoneId);

        this.SelectedZone.push({ "id": Zone.ZoneId, "itemName": Zone.ZoneName });
        if (this.CRM_City_Model.CityId > 0) {
          this.GetState(this.CRM_City_Model.ZoneId)
        }

      }

    });
  }

  //Populate  Zone  Down  ENDS

  //Populate  State  Drop  Down  

  GetState(Zoneid: number) {
    let t = localStorage.getItem('userToken');
    t = 'Bearer' + t
    this.statelist = [];
    this.CRM_CityService.GetStateType(t, Zoneid).then(x => {
      this.StateName = x;
      Object.keys(this.StateName).forEach(key => {
        let StateNametemp = new Model_CRM_State();
        StateNametemp = this.StateName[key];
        this.statelist.push(StateNametemp);
        this.StateArray.push({ "id": StateNametemp.StateId, "itemName": StateNametemp.StateName });
      });

    }).then(t => {



      if (this.CRM_City_Model.StateId > 0) {
        this.SelectedState = [];
        let State = this.statelist.find(x => x.StateId == this.CRM_City_Model.StateId);

        this.SelectedState.push({ "id": State.StateId, "itemName": State.StateName });

      }

    });
  }

  //Populate State Drop  Down  ENDS


  // Get Zone By Country starts
  onItemSelectCountry(item: any) {

    let CountryId = item['id'];
    this.CRM_City_Model.CountryId = CountryId;
    //  this.CRM_City_Model.ZoneId=ZoneId;


    this.ZoneArray = [];
    this.StateArray = [];
    if (this.CRM_City_Model.CountryId > 0) {
      this.GetZone(this.CRM_City_Model.CountryId);



    }

  }
  OnItemDeSelectCountry(item: any) { }
  onSelectAllCountry(items: any) { }
  onDeSelectAllCountry(items: any) { }



  onItemSelecZone(item: any) {

    let ZoneId = item['id'];
    this.CRM_City_Model.ZoneId = ZoneId;
    this.StateArray = [];

    if (this.CRM_City_Model.ZoneId > 0) {
      this.GetState(this.CRM_City_Model.ZoneId);

    }



  }
  OnItemDeSelectZone(item: any) { }
  onSelectAllZone(items: any) { }
  onDeSelectAllZone(items: any) { }

  onItemSelecState(item: any) {



    let StateId = item['id'];

    this.CRM_City_Model.StateId = StateId;


    // this.SelectedState = [];
    // let Statetemp = this.statelist.find(x => x.StateId == this.CRM_City_Model.StateId);

    // this.SelectedState.push({ "id": Statetemp.StateId, "itemName": Statetemp.StateName });





  }
  OnItemDeSelectState(item: any) { }
  onSelectAllState(items: any) { }
  onDeSelectAllState(items: any) { }

  // Get Zone By Country starts



  // Save Region  Starts

  saveCRMCity(): void {
    this.IsSavevisible = false;
    let t = localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.CRM_City_Model.IsActive = true;

    if (this.CRM_City_Model.CityId > 0) {
      this.CRM_CityService.UpdateCrmCity(t, this.CRM_City_Model).subscribe(res => {
        this.CityId = Number(res);
        this.CRM_City_Model.CityId = 0;

        if (this.CityId > 0) {
          localStorage.setItem('SuccessfullMessage', "Successfully Updated");
          this.rout.navigateByUrl('ManageMICEAccess/ManageMICEUser', { skipLocationChange: true }).then(() => this.rout.navigate(["ManageMICEAccess/CrmCity"]));

        }
      });
    }
    else {
      this.CRM_CityService.AddCrmCity(t, this.CRM_City_Model).subscribe(res => {
        this.CityId = Number(res);
        this.CRM_City_Model.CityId = 0;


        if (this.CityId > 0) {
          // alert();
          localStorage.setItem('SuccessfullMessage', "Successfully Added");
          this.rout.navigateByUrl('ManageMICEAccess/ManageMICEUser', { skipLocationChange: true }).then(() => this.rout.navigate(["ManageMICEAccess/CrmCity"]));

        }
      });
    }
  }


  //  Grid  Data  Fetch 

  GetCRMCity() {

    let t = localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.citylist = [];
    this.CRM_CityService.Get_CRMCity(t).then(x => {
      this.CRMCity = x;
      Object.keys(this.CRMCity).forEach(key => {
        this.CRMCity[key];
        let CRMCitytemp = new Model_CRM_City();
        CRMCitytemp = this.CRMCity[key];

        this.citylist.push(CRMCitytemp);
      });
      this.dtTrigger.next();
    });
  }

  //  Grid  Data  Fetch Ends



  //  Update Section  Strats

  GetCRMCityById(Id) {

    this.IsDataIsInViewState = false;
    // End of the permision portion
    let t = localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.CRM_CityService.GetCRM_City_By_id(t, Id).then(x => {
      this.CRMCity = x;
      Object.keys(this.CRMCity).forEach(key => {

        let CRMCitytemp = new Model_CRM_City();
        CRMCitytemp = this.CRMCity[key];


        this.CRM_City_Model.CityId = CRMCitytemp.CityId;
        this.CRM_City_Model.CityName = CRMCitytemp.CityName;
        this.CRM_City_Model.StateId = CRMCitytemp.StateId;
        this.CRM_City_Model.StateName = CRMCitytemp.StateName;
        this.CRM_City_Model.ZoneId = CRMCitytemp.ZoneId;
        this.CRM_City_Model.ZoneName = CRMCitytemp.ZoneName;
        this.CRM_City_Model.CountryId = CRMCitytemp.CountryId;
        this.CRM_City_Model.CountryName = CRMCitytemp.CountryName;
        this.CRM_City_Model.IsActive = CRMCitytemp.IsActive;

      });

    }).then(y => {

      if (this.CRM_City_Model.CountryId > 0) {
        let Country = this.countrylist.find(x => x.CountryId == this.CRM_City_Model.CountryId);
        this.SelectedCountry = [];

        this.SelectedCountry.push({ "id": Country.CountryId, "itemName": Country.CountryName });

        this.GetZone(this.CRM_City_Model.CountryId);
      }


    });

  }

  //  Update Section Ends

  //  Delete  Part  Starts  

  DeleteCrmCity(ID: number): void {
    // alert(ID);
    this._confirmation.create('Confirm to Delete', 'Press Yes to Delete', this.settings)
      .subscribe((ans: ResolveEmit) => {
        if (ans.resolved == true) {

          let t = localStorage.getItem('userToken');
          t = 'Bearer' + t;

          if (ID > 0) {
            this.CRM_CityService.DeleteCity(t, ID).subscribe(res => {
              this.DeleteCityNameByID = res;

              if (this.DeleteCityNameByID > 0) {
                localStorage.setItem('SuccessfullMessage', "Successfully Deleted");
                this.rout.navigateByUrl('ManageMICEAccess/ManageMICEUser', { skipLocationChange: true }).then(() => this.rout.navigate(["ManageMICEAccess/CrmCity"]));
              }
            });
          }

        }
      })
  }

  //  Delete  Part  Starts



  //  View  Section Starts  


  ViewCRMCityById(Id) {

    this.IsDataIsInViewState = true;
    let t = localStorage.getItem('userToken');
    t = 'Bearer' + t;
    this.CRM_CityService.GetCRM_City_By_id(t, Id).then(x => {
      this.CRMCity = x;
      Object.keys(this.CRMCity).forEach(key => {

        let CRMCitytemp = new Model_CRM_City();
        CRMCitytemp = this.CRMCity[key];

        this.CRM_City_Model.CityId = CRMCitytemp.CityId;
        this.CRM_City_Model.CityName = CRMCitytemp.CityName;
        this.CRM_City_Model.StateId = CRMCitytemp.StateId;
        this.CRM_City_Model.StateName = CRMCitytemp.StateName;
        this.CRM_City_Model.ZoneId = CRMCitytemp.ZoneId;
        this.CRM_City_Model.ZoneName = CRMCitytemp.ZoneName;
        this.CRM_City_Model.CountryId = CRMCitytemp.CountryId;
        this.CRM_City_Model.CountryName = CRMCitytemp.CountryName;
        this.CRM_City_Model.IsActive = CRMCitytemp.IsActive;

      });


      this.checkdataval = this.CRM_City_Model.CityId;
      this.Checkeditstatus = false;
      this.CheckExiststatus = false;
      this.check = ""
    });
  }

  // View Section Ends 


  RefreshPage() {
    this.rout.navigateByUrl('ManageMICEAccess/ManageMICEUser', { skipLocationChange: true }).then(() => this.rout.navigate(["ManageMICEAccess/CrmCity"]));
  }

}
