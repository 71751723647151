<div class="body-content-area tabcontent" id="link1">
  <div class="row">
    <div class="col-lg-9">
      <div class="row">
        <div class="col-lg-1 low-padding text-center">
          <!-- <img *ngIf="this.SelectedMenu.Image!=null" src={{this.SelectedMenu.Image}} class="body-icon" alt="Icon"/> -->
        </div>
        <div class="col-lg-10 clear-padding">
          <div>
            <span class="page-title">Personal Detail Modification</span>
            <small class="page-description"></small>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div>
    <app-dialog [(visible)]="this.SuccessStatus">
      <span class="successmsg">{{this.SuccessMessage}}</span>
    </app-dialog>
    <fieldset class="body-form-control">
      <legend class="legend--">Employee Details</legend>
      <form #EmployeeForm="ngForm">
        <div class="row">
          <div class="col-lg-4">
            <div class="col-body-border-right">
              <div class="form-group row">
                <label class="col-sm-4 col-form-label clear-padding-right" style="font-weight: bold;">Employee Id</label>
                <div class="col-sm-7 clear-left-padding">
                  :&nbsp;&nbsp;{{this.EmployeeModel.EmployeeCode}} 
                </div>
              </div>           
            </div>
          </div>
          <div class="col-lg-4">
            <div class="form-group row">
              <label class="col-sm-4 col-form-label clear-padding-right" style="font-weight: bold;">Name</label>
              <div class="col-sm-7 clear-left-padding">
                :&nbsp;&nbsp;{{this.EmployeeModel.EmployeeName}}
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-2">
            <div class="form-group row">
              <label class="col-sm-2 col-form-label clear-padding-right" style="font-weight: bold;">Modification In</label>
              <div class="col-sm-10 clear-left-padding">
              </div>
            </div> 
          </div>
          <div class="col-lg-10">
            <div class="form-group row">
              <div class="col-sm-12 clear-left-padding">
                :&nbsp;
                <input type="checkbox" (click)="OnChangePersonalInfo($event)" id ="PersonalInfo" name="PersonalInfo">
                  Personal Information&nbsp;&nbsp;&nbsp;

                  <input type="checkbox" (click)="OnChangeAddress($event)" id ="Address" name="Address">
                  Address Details&nbsp;&nbsp;&nbsp;

                  <input type="checkbox" (click)="OnChangeOtherInfo($event)" id ="OtherInfo" name="OtherInfo">
                  Other Information&nbsp;&nbsp;&nbsp;

                  <input type="checkbox" (click)="OnChangeQualification($event)" id ="Qualification" name="Qualification">
                  Qualification Details&nbsp;&nbsp;&nbsp;

                  <input type="checkbox" (click)="OnChangeExperience($event)" id ="Experience" name="Experience">
                  Experience Details&nbsp;&nbsp;&nbsp;

                  <input type="checkbox" (click)="OnChangeFamily($event)" id ="Family" name="Family">
                  Family Details&nbsp;&nbsp;&nbsp;
              </div>
            </div> 
          </div>
        </div>
      </form> 
    </fieldset>

    <fieldset class="body-form-control" *ngIf="(this.EmployeeModel.PersonalDetails)"> 
      <legend class="legend--">Employee Personal Details</legend>
      <form #EmployeeDetailForm="ngForm">
        <div class="row">
          <div class="col-lg-4">
            <div class="col-body-border-right">
              <div class="form-group row">
                <label  class="col-sm-4 col-form-label clear-padding-right">First Name</label>
                <div class="col-sm-7 clear-left-padding">
                  <input type="text" [(ngModel)]="this.EmployeeMasterService.SharedEmployees.FirstName" maxlength="100" id ="FirstName" name="FirstName" #FirstName="ngModel" class="form-control form-control-custom form-control-custom-new" required>
                  <div *ngIf="FirstName.invalid && (FirstName.dirty || FirstName.touched)" class="required-validation">
                    <div *ngIf="FirstName.errors.maxlength">First Name is not more than 100 characters</div>
                  </div>
                </div>
              </div>
              <div class="form-group row">
                <label  class="col-sm-4 col-form-label clear-padding-right">DOB</label>
                <div class="col-sm-7 clear-left-padding">
                  <input class="form-control" style="float:none" placeholder="Select date" ngx-mydatepicker name="DOB"  [(ngModel)]="EmployeeMasterService.SharedEmployees.DOB" [options]="myOptions" #DOB="ngx-mydatepicker" (dateChanged)="onDateChangedDOB($event)" required />
                  <span class="mt-2">
                    <a class="btn btn-primary pl-10 pr-10 pt-1 pb-1" (click)="DOB.toggleCalendar()">
                      <i style="color:#fff" class="fas fa-calendar-alt fa-lg"></i>
                    </a>
                    <a class="btn btn-danger pl-10 pr-10 pt-1 pb-1" (click)="DOB.clearDate()">
                      <i style="color:#fff" class="far fa-calendar-times fa-lg"></i>
                    </a>
                  </span>
                  <div *ngIf="EmployeeMasterService.SharedEmployees.DOBPass==null" class="required-validation">DOB  is required.</div>
                </div>
              </div>
              <div class="form-group row">
                <label  class="col-sm-4 col-form-label clear-padding-right">Mother Tongue</label>
                <div class="col-sm-7 clear-left-padding">
                  <select [(ngModel)]="EmployeeMasterService.SharedEmployees.LanguageID" id ="LanguageID" class="form-control"  name="LanguageID" #LanguageID="ngModel" >
                    <option [value]="0" [disabled]=true >Select Language</option>
                    <option *ngFor="let L of Languages" [value]="L.LanguageID">{{L.LanguageName}}</option>
                  </select>
                  <div class="required-validation" *ngIf="EmployeeMasterService.SharedEmployees.LanguageID==0">Language is required</div>
                </div>
              </div>
              <div class="form-group row">
                <label  class="col-sm-4 col-form-label clear-padding-right">Email Id</label>
                <div class="col-sm-7 clear-left-padding">
                  <input type="text" [(ngModel)]="EmployeeMasterService.SharedEmployees.EmailId" required id ="EmailId"  name="EmailId"  #EmailId="ngModel" pattern="^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$" class="form-control form-control-custom form-control-custom-new" >
                </div>
                <div *ngIf="EmailId.invalid && (EmailId.dirty || EmailId.touched)" class="required-validation">
                  <div *ngIf="EmailId.errors.required">Email is required.</div>
                  <div *ngIf="EmailId.errors?.pattern">Email should be valid.</div>
                </div>
              </div>
              <div class="form-group row">
                <label  class="col-sm-4 col-form-label clear-padding-right">Marital Status</label>
                <div class="col-sm-7 clear-left-padding">
                  <select (ngModelChange)="MaritalChanged($event)" [(ngModel)]="EmployeeMasterService.SharedEmployees.MaritalId" id ="MaritalId" class="form-control" name="MaritalId" #MaritalId="ngModel">
                  <option [value]="0" [disabled]=true >Select Marital</option>
                  <option *ngFor="let M of Maritials" [value]="M.MaritalId">{{M.MaritalName}}</option>
                  </select>
                </div>
                <div class="required-validation" *ngIf="EmployeeMasterService.SharedEmployees.MaritalId==0">Marital is required</div>
              </div>
            </div>
          </div>

          <div class="col-lg-4">
            <div class="col-body-border-right">
              <div class="form-group row">
                <label  class="col-sm-4 col-form-label clear-padding-right" >Middle Name</label>
                <div class="col-sm-7 clear-left-padding">
                  <input type="text" [(ngModel)]="EmployeeMasterService.SharedEmployees.MiddleName" id ="MiddleName" name="MiddleName" #MiddleName="ngModel" maxlength="100" class="form-control form-control-custom form-control-custom-new">
                  <div *ngIf="MiddleName.invalid && (MiddleName.dirty || MiddleName.touched)" class="required-validation">
                    <div *ngIf="MiddleName.errors.maxlength">Middle Name is not more than 100 characters</div>
                  </div>
                </div>
              </div>
              <div class="form-group row">
                <label  class="col-sm-4 col-form-label clear-padding-right">Father's Name</label>
                <div class="col-sm-7 clear-left-padding">
                  <input type="text" [(ngModel)]="EmployeeMasterService.SharedEmployees.FathersName" maxlength="250" id ="FathersName" name="FathersName" #FathersName="ngModel" class="form-control form-control-custom form-control-custom-new" >
                </div>
              </div>
              <div class="form-group row">
                <label  class="col-sm-4 col-form-label clear-padding-right">Gender</label>
                <div class="col-sm-7 clear-left-padding">
                  <select [(ngModel)]="EmployeeMasterService.SharedEmployees.GenderId" id ="GenderId" class="form-control"  name="GenderId" #GenderId="ngModel" >
                    <option [value]="0" [disabled]=true >Select Gender</option>
                    <option *ngFor="let G of Ganders" [value]="G.GenderId">{{G.GenderName}}</option>
                  </select>
                </div>
                <div class="required-validation" *ngIf="EmployeeMasterService.SharedEmployees.GenderId==0">Gender is required</div>  
              </div>
              <div class="form-group row">
                <label  class="col-sm-4 col-form-label clear-padding-right">Blood Group</label>
                <div class="col-sm-7 clear-left-padding">
                  <input type="text" [(ngModel)]="EmployeeMasterService.SharedEmployees.BloodGroup" id ="BloodGroup" name="BloodGroup" class="form-control form-control-custom form-control-custom-new" >
                </div>
              </div>
              <div *ngIf="AnniversaryDateShow" class="form-group row">
                <label  class="col-sm-4 col-form-label clear-padding-right">Anniversary Date</label>
                <div class="col-sm-7 clear-left-padding">
                  <input class="form-control" style="float:none" placeholder="Select date" ngx-mydatepicker name="AnniversaryDate" [(ngModel)]="EmployeeMasterService.SharedEmployees.AnniversaryDate" [options]="myOptions" #AnniversaryDate="ngx-mydatepicker" (dateChanged)="onDateChangedAnniversaryDate($event)" required/>
                  <span class="mt-2">
                    <a class="btn btn-primary pl-10 pr-10 pt-1 pb-1" (click)="AnniversaryDate.toggleCalendar()">
                      <i style="color:#fff" class="fas fa-calendar-alt fa-lg"></i>
                    </a>
                    <a class="btn btn-danger pl-10 pr-10 pt-1 pb-1" (click)="AnniversaryDate.clearDate()">
                      <i style="color:#fff" class="far fa-calendar-times fa-lg"></i>
                    </a>
                  </span>
                </div>
                <div *ngIf="EmployeeMasterService.SharedEmployees.AnniversaryDatePass==null" class="required-validation">AnniversaryDate is required.</div>
              </div>
            </div>
          </div>

          <div class="col-lg-4">
            <div class="form-group row">
              <label  class="col-sm-4 col-form-label clear-padding-right">Last Name</label>
              <div class="col-sm-7 clear-left-padding">
                <input type="text" [(ngModel)]="EmployeeMasterService.SharedEmployees.LastName" id ="LastName" maxlength="100" #LastName="ngModel" name="LastName" class="form-control form-control-custom form-control-custom-new">
                <div *ngIf="LastName.invalid && (LastName.dirty || LastName.touched)" class="required-validation">
                  <div *ngIf="LastName.errors.maxlength">Last Name is not more than 100 characters</div>
                </div>
              </div>
            </div>
            <div class="form-group row">
              <label  class="col-sm-4 col-form-label clear-padding-right">Mother's Name</label>
              <div class="col-sm-7 clear-left-padding">
                <input type="text" [(ngModel)]="EmployeeMasterService.SharedEmployees.MothersName" id ="MothersName"  name="MothersName" #MothersName="ngModel" class="form-control form-control-custom form-control-custom-new" >
              </div>
            </div>
            <div class="form-group row">
              <label  class="col-sm-4 col-form-label clear-padding-right">Religion</label>
              <div class="col-sm-7 clear-left-padding">
                <select [(ngModel)]="EmployeeMasterService.SharedEmployees.ReligionID" id ="ReligionID" class="form-control"  name="ReligionID" #ReligionID="ngModel" >
                  <option [value]="0" [disabled]=true >Select Religion</option>
                  <option *ngFor="let G of Religions" [value]="G.ReligionID">{{G.ReligionName}}</option>
                </select>
              </div>
              <div class="required-validation" *ngIf="EmployeeMasterService.SharedEmployees.ReligionID==0">Religion is required</div>  
            </div>
            <div class="form-group row">
              <label  class="col-sm-4 col-form-label clear-padding-right">Mobile No</label>
              <div class="col-sm-7 clear-left-padding">
                <input type="text" [(ngModel)]="EmployeeMasterService.SharedEmployees.ContactNumber" maxlength="10" minlength="10" pattern="[0-9]+" id ="ContactNumber" name="ContactNumber" #ContactNumber="ngModel" required class="form-control form-control-custom form-control-custom-new" >
              </div>
              <div *ngIf="ContactNumber.invalid && (ContactNumber.dirty || ContactNumber.touched)" class="required-validation">
                <div *ngIf="ContactNumber.errors.maxlength">Mobile No is not more than 10 digit.</div>
                <div *ngIf="ContactNumber.errors.minlength">Mobile No is not less than 10 digit.</div>
                <div *ngIf="ContactNumber.errors?.pattern">Mobile No should be valid.</div>
              </div>
            </div>
            <div class="form-group row">
              <label  class="col-sm-4 col-form-label clear-padding-right">Photo</label>
              <div class="col-sm-7 clear-left-padding">
                <img *ngIf="this.EmployeeMasterService.SharedEmployees.ImageFile!=null" [src]="EmployeeMasterService.SharedEmployees.ImageFile" style="width:175px; height: 100px;" >
                <input type="file" (change)="handleFileinput($event.target.files)" #Image accept="image/*" >
              </div>
              <!-- <label *ngIf="!EmployeeMasterService.SharedEmployees.validfile" class="required-validation">Invalid Photo, will not be uploaded </label> -->
            </div>
          </div>
        </div>
      </form>
    </fieldset>

    <fieldset class="body-form-control" *ngIf="(this.EmployeeModel.AddressDetails)"> 
      <legend class="legend--">Employee Address Details</legend>
      <form #AddressForm="ngForm">
        <div class="row">
          <div class="col-lg-4">
            <div class="col-body-border-right">
              <div class="form-group row">
                <label  class="col-sm-4 col-form-label clear-padding-right">House No/ Street</label>
                <div class="col-sm-7 clear-left-padding">
                  <input type="text" [(ngModel)]="this.EmployeeMasterService.SharedEmployees.CAddress1" maxlength="500" id ="CAddress1"  name="CAddress1" #CAddress1="ngModel" required class="form-control form-control-custom form-control-custom-new">
                </div>
                <div *ngIf="CAddress1.invalid && (CAddress1.dirty || CAddress1.touched)" class="required-validation">
                  <div *ngIf="CAddress1.errors.required">House No/ Street is required.</div>
                  <div *ngIf="CAddress1.errors.maxlength">House No/ Street is not more than 500 characters</div>
                </div>
              </div>
              <div class="form-group row">
                <label  class="col-sm-4 col-form-label clear-padding-right">Country</label>
                <div class="col-sm-7 clear-left-padding">
                  <select  [(ngModel)]="this.EmployeeMasterService.SharedEmployees.CCountryId" (ngModelChange)="onCountryChange($event)" class="form-control" name="CCountryId" #CCountryId="ngModel">
                    <option [value]="0" [disabled]=true >Select Country</option>
                    <option *ngFor="let c of Countries" [value]="c.CountryId">{{c.CountryName}}</option>
                  </select>
                </div>
                <div class="required-validation" *ngIf="EmployeeMasterService.SharedEmployees.CCountryId==0">Country is required</div>
              </div>
              <div class="form-group row">
                <label  class="col-sm-4 col-form-label clear-padding-right">City</label>
                <div class="col-sm-7 clear-left-padding">
                  <select [(ngModel)]="this.EmployeeMasterService.SharedEmployees.CCityId" id ="CCityId" class="form-control" name="CCityId" #CCityId="ngModel">
                    <option [value]="0" [disabled]=true >Select City</option>
                    <option *ngFor="let c of Cities" [value]="c.CityId">{{c.CityName}}</option>
                  </select>
                </div>
                <div class="required-validation" *ngIf="EmployeeMasterService.SharedEmployees.CCityId==0">City is required</div>
              </div>
            </div>
          </div>
          
          <div class="col-lg-4">
            <div class="col-body-border-right">
              <div class="form-group row">
                <label class="col-sm-4 col-form-label clear-padding-right">Address</label>
                <div class="col-sm-7 clear-left-padding">
                  <input type="text" [(ngModel)]="this.EmployeeMasterService.SharedEmployees.CAddress2" maxlength="500" id ="CAddress2"  name="CAddress2" #CAddress2="ngModel" required class="form-control form-control-custom form-control-custom-new">
                </div>
                <div *ngIf="CAddress2.invalid && (CAddress2.dirty || CAddress2.touched)" class="required-validation">
                  <div *ngIf="CAddress2.errors.required">Address2 is required.</div>
                  <div *ngIf="CAddress2.errors.maxlength">Address2 is not more than 500 characters</div>
                </div>
              </div>
              <div class="form-group row">
                <label  class="col-sm-4 col-form-label clear-padding-right">State</label>
                <div class="col-sm-7 clear-left-padding">
                  <select [(ngModel)]="this.EmployeeMasterService.SharedEmployees.CStateId" (ngModelChange)="onstatechange($event)"  id ="CStateId" class="form-control" name="CStateId" #CStateId="ngModel" >
                    <option [value]="0" [disabled]=true >Select State</option>
                    <option *ngFor="let s of States" [value]="s.StateID">{{s.StateName}}</option>
                  </select>
                </div>
                <div class="required-validation" *ngIf="EmployeeMasterService.SharedEmployees.CStateId==0">State is required</div>
              </div>
              <div class="form-group row">
                <label  class="col-sm-4 col-form-label clear-padding-right">Pin Code</label>
                <div class="col-sm-7 clear-left-padding">
                  <input type="text" [(ngModel)]="this.EmployeeMasterService.SharedEmployees.CPincode" maxlength="6" minlength="6" id ="CPincode"  name="CPincode" #CPincode="ngModel"  pattern="[0-9]+"  required class="form-control form-control-custom form-control-custom-new">
                </div>
                <div *ngIf="CPincode.invalid && (CPincode.dirty || CPincode.touched)" class="required-validation">
                  <div *ngIf="CPincode.errors.required">Pin Code is required.</div>
                  <div *ngIf="CPincode.errors.maxlength">Pin Code not more than 6 digits.</div>
                  <div *ngIf="CPincode.errors.minlength">Pin Code not more than 6 digits.</div>
                  <div *ngIf="CPincode.errors?.pattern">Pin Code should be valid</div>
                </div>
              </div>
            </div>
          </div>

          <div class="col-lg-4">
            <div class="form-group row">
              <label  class="col-sm-4 col-form-label clear-padding-right">Contact No</label>
              <div class="col-sm-7 clear-left-padding">
                <input type="text" [(ngModel)]="this.EmployeeMasterService.SharedEmployees.CContactNo" maxlength="10" minlength="10" pattern="[0-9]+" id ="CContactNo"  name="CContactNo" #CContactNo="ngModel" required class="form-control form-control-custom form-control-custom-new">
              </div>
              <div *ngIf="CContactNo.invalid && (CContactNo.dirty || CContactNo.touched)" class="required-validation">
                <div *ngIf="CContactNo.errors.maxlength">Contact No is not more than 15 digit.</div>
                <div *ngIf="CContactNo.errors.minlength">Contact No is not less than 10 dogit.</div>
                <div *ngIf="CContactNo.errors?.pattern">Contact No should be valid.</div>
              </div>
            </div>
            <div class="form-group row">
              <label class="col-sm-4 col-form-label clear-padding-right">District</label>
              <div class="col-sm-7 clear-left-padding">
                <select [(ngModel)]="this.EmployeeMasterService.SharedEmployees.CDistrictId" (ngModelChange)="onDistrictchange($event)" class="form-control" id ="CDistrictId" name="CDistrictId" #CDistrictId="ngModel">
                  <option [value]="0" [disabled]=true >Select District</option>
                  <option *ngFor="let D of Dists" [value]="D.DistId">{{D.DistName}}</option>
                </select>
              </div>
              <div class="required-validation" *ngIf="this.EmployeeMasterService.SharedEmployees.CDistrictId==0">District is required</div>
            </div>
          </div>
        </div>
      </form>
    </fieldset>

    <fieldset class="body-form-control" *ngIf="(this.EmployeeModel.OtherDetails)"> 
      <legend class="legend--">Employee Other Details</legend>
      <form #EmployeeDetailForm="ngForm">
        <div class="row">
          <div class="col-lg-4">
            <div class="col-body-border-right">
              <div class="form-group row">
                <label  class="col-sm-4 col-form-label clear-padding-right">Pan No</label>
                <div class="col-sm-7 clear-left-padding">
                  <input type="text" [(ngModel)]="EmployeeMasterService.SharedEmployees.PANNo" id ="PANNo" maxlength="20" name="PANNo" #PANNo="ngModel" class="form-control form-control-custom form-control-custom-new"  >
                  <div *ngIf="PANNo.invalid && (PANNo.dirty || PANNo.touched)" class="required-validation">
                    <div *ngIf="PANNo.errors.maxlength">Employee PanNo is not more than 20 characters</div>
                  </div>
                </div>
              </div>
              <div class="form-group row">
                <label  class="col-sm-4 col-form-label clear-padding-right">Voter Id</label>
                <div class="col-sm-7 clear-left-padding">
                  <input type="text" [(ngModel)]="EmployeeMasterService.SharedEmployees.VoterId" id ="VoterId" maxlength="20" #VoterId="ngModel" name="VoterId" class="form-control form-control-custom form-control-custom-new"  >
                  <div *ngIf="VoterId.invalid && (VoterId.dirty || VoterId.touched)" class="required-validation">
                    <div *ngIf="VoterId.errors.maxlength">Voter Id is not more than 20 characters</div>
                  </div>
                </div>
              </div>
              <div class="form-group row">
                <label  class="col-sm-4 col-form-label clear-padding-right">Aadhaar No</label>
                <div class="col-sm-7 clear-left-padding">
                  <input type="text" [(ngModel)]="EmployeeMasterService.SharedEmployees.AdhaarNo" id ="AdhaarNo" maxlength="20" #AdhaarNo="ngModel" name="AdhaarNo" class="form-control form-control-custom form-control-custom-new"  >
                  <div *ngIf="AdhaarNo.invalid && (AdhaarNo.dirty || AdhaarNo.touched)" class="required-validation">
                    <div *ngIf="AdhaarNo.errors.maxlength">Aadhaar No is not more than 20 characters</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-4">
            <div class="col-body-border-right">
              <div class="form-group row">
                <label class="col-sm-4 col-form-label clear-padding-right">EPF No</label>
                <div class="col-sm-7 clear-left-padding">
                  <input type="text" [(ngModel)]="EmployeeMasterService.SharedEmployees.EPFNo" id ="EPFNo" maxlength="25" #EPFNo="ngModel" name="EPFNo" class="form-control form-control-custom form-control-custom-new"  >
                  <div *ngIf="EPFNo.invalid && (EPFNo.dirty || EPFNo.touched)" class="required-validation">
                    <div *ngIf="EPFNo.errors.maxlength">EPFNo is not more than 25 characters</div>
                  </div>
                </div>                    
              </div>
              <div class="form-group row">
                <label  class="col-sm-4 col-form-label clear-padding-right">ESI No</label>
                <div class="col-sm-7 clear-left-padding">
                  <input type="text" [(ngModel)]="EmployeeMasterService.SharedEmployees.ESINo" id ="ESINo" maxlength="25" #ESINo="ngModel" name="ESINo" class="form-control form-control-custom form-control-custom-new"  >
                  <div *ngIf="ESINo.invalid && (ESINo.dirty || ESINo.touched)" class="required-validation">
                    <div *ngIf="ESINo.errors.maxlength">ESINo is not more than 25 characters</div>
                  </div>
                </div>
              </div>
              <div class="form-group row">
                <label  class="col-sm-4 col-form-label clear-padding-right">Gratuity No</label>
                <div class="col-sm-7 clear-left-padding">
                  <input type="text" [(ngModel)]="EmployeeMasterService.SharedEmployees.GratuityNo" id ="GratuityNo" maxlength="50" #GratuityNo="ngModel" name="GratuityNo" class="form-control form-control-custom form-control-custom-new"  >
                  <div *ngIf="GratuityNo.invalid && (GratuityNo.dirty || GratuityNo.touched)" class="required-validation">
                    <div *ngIf="GratuityNo.errors.maxlength">ESINo is not more than 50 characters</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-4">
            <div class="form-group row">
              <label  class="col-sm-4 col-form-label clear-padding-right">Pension No</label>
              <div class="col-sm-7 clear-left-padding">
                <input type="text" [(ngModel)]="this.EmployeeMasterService.SharedEmployees.PensionNo" id ="PensionNo" maxlength="50" #PensionNo="ngModel" name="PensionNo" class="form-control form-control-custom form-control-custom-new"  >
                <div *ngIf="PensionNo.invalid && (PensionNo.dirty || PensionNo.touched)" class="required-validation">
                  <div *ngIf="PensionNo.errors.maxlength">PensionNo is not more than 50 characters</div>
                </div>
              </div>
            </div>
            <div class="form-group row">
              <label  class="col-sm-4 col-form-label clear-padding-right">Superannuation No</label>
              <div class="col-sm-7 clear-left-padding">
                <input type="text" [(ngModel)]="this.EmployeeMasterService.SharedEmployees.SuperannuationNo" id ="SuperannuationNo" maxlength="50" #SuperannuationNo="ngModel" name="SuperannuationNo" class="form-control form-control-custom form-control-custom-new"  >
                <div *ngIf="SuperannuationNo.invalid && (SuperannuationNo.dirty || SuperannuationNo.touched)" class="required-validation">
                  <div *ngIf="SuperannuationNo.errors.maxlength">SuperannuationNo is not more than 50 characters</div>
                </div>
              </div>
            </div>
            <div class="form-group row">
              <label  class="col-sm-4 col-form-label clear-padding-right">UAN No</label>
              <div class="col-sm-7 clear-left-padding">
                <input type="text" [(ngModel)]="this.EmployeeMasterService.SharedEmployees.UANNo" id ="UANNo" maxlength="50" #UANNo="ngModel" name="UANNo" class="form-control form-control-custom form-control-custom-new"  >
                <div *ngIf="UANNo.invalid && (UANNo.dirty || UANNo.touched)" class="required-validation">
                  <div *ngIf="UANNo.errors.maxlength">UANNo is not more than 50 characters</div>
                </div>
              </div>
            </div>  
          </div>
        </div>
      </form>
    </fieldset>

    <fieldset class="body-form-control" *ngIf="(this.EmployeeModel.QualificationDetails)"> 
      <legend class="legend--">Employee Qualification Details</legend>
      <form #EmployeeEducationDetailForm="ngForm">
        <div lass="row" >
          <table id="tblComponent" class="table table-striped table-bordered text-left table-new row-border hover">
            <thead class="thead-blue text-center">
              <tr>
                <th style="width: 15%;">Degree </th>
                <th style="width: 15%;">Qualification</th>
                <th style="width: 15%;">InstituteName</th>
                <th style="width: 10%;">University/Board</th>
                <th style="width: 5%;">Marks/Grade(%)</th>
                <th style="width: 10%;">Division</th>
                <th style="width: 15%;">From Date</th>
                <th style="width: 15%;">To Date</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let Edu of this.EmployeeMasterService.SharedEmployees.EmployeeEducation, let i = index">
                <td >
                  <input type="text" [(ngModel)]="Edu.DegreeName" name="DegreeName_{{i}}" #DegreeName="ngModel" class="form-control form-control-custom form-control-custom-new">
                </td>
                <td >
                  <input type="text" [(ngModel)]="Edu.DegreeCode" name="DegreeCode_{{i}}" #DegreeCode="ngModel" class="form-control form-control-custom form-control-custom-new">
                </td>
                <td>
                  <input type="text" [(ngModel)]="Edu.Institute" name="Institute_{{i}}" #Institute="ngModel" class="form-control form-control-custom form-control-custom-new">
                </td>
                <td >
                  <input type="text" [(ngModel)]="Edu.BoardUniversity" name="BoardUniversity_{{i}}" #BoardUniversity="ngModel" class="form-control form-control-custom form-control-custom-new">
                </td>
                <td >
                  <input type="text" name="MarksPercentage_{{i}}" [(ngModel)]="Edu.MarksPercentage"  #MarksPercentage="ngModel"  class="form-control form-control-custom form-control-custom-new"/>
                </td>
                <td >
                  <input type="text" name="Division_{{i}}" [(ngModel)]="Edu.Division"  #Division="ngModel"  class="form-control form-control-custom form-control-custom-new"/>
                </td>
                <td >
                  <input class="form-control" style="float:none" placeholder="Select date" ngx-mydatepicker name="FromDate_{{i}}" [(ngModel)]="Edu.FromDate" [options]="myOptions" #dpx="ngx-mydatepicker" (dateChanged)="onDateChangedFromQualification($event,i)"/>
                  <span class="mt-2">
                    <a class="btn btn-primary pl-10 pr-10 pt-1 pb-1" (click)="dpx.toggleCalendar()">
                      <i style="color:#fff" class="fas fa-calendar-alt fa-lg"></i>
                    </a>

                    <a class="btn btn-danger pl-10 pr-10 pt-1 pb-1" (click)="dpx.clearDate()">
                      <i style="color:#fff" class="far fa-calendar-times fa-lg"></i>
                    </a>
                  </span>
                </td>
                <td >
                  <input class="form-control" style="float:none" placeholder="Select date" ngx-mydatepicker name="ToDate_{{i}}" [(ngModel)]="Edu.ToDate" [options]="myOptions" #dp="ngx-mydatepicker" (dateChanged)="onDateChangedToQualification($event,i)"/>
                  <span class="mt-2">
                    <a class="btn btn-primary pl-10 pr-10 pt-1 pb-1" (click)="dp.toggleCalendar()">
                      <i style="color:#fff" class="fas fa-calendar-alt fa-lg"></i>
                    </a>

                    <a class="btn btn-danger pl-10 pr-10 pt-1 pb-1" (click)="dp.clearDate()">
                      <i style="color:#fff" class="far fa-calendar-times fa-lg"></i>
                    </a>
                  </span>
                </td>
              </tr>
            </tbody>
          </table>
          <div class="row float-right">
            <div class=" col-auto text-center">
              <button  type="button" class="btn btn-primary pl-10 pr-10 pt-1 pb-1" (click)="AddnweRowQualification()">Add Row </button>
            </div>
          </div>
        </div>
      </form>
    </fieldset>

    <fieldset class="body-form-control" *ngIf="(this.EmployeeModel.ExperienceDetails)"> 
      <legend class="legend--">Employee Experience Details</legend>
      <form #EmployeeExperienceDetailForm="ngForm">
        <div lass="row" >
          <table id="tblComponent" class="table table-striped table-bordered text-left table-new row-border hover">
            <thead class="thead-blue text-center">
              <tr>
                <th style="width: 25%;">Name Of Employeer</th>
                <th style="width: 25%;">Designation</th>  
                <th style="width: 25%;">Form Date</th>
                <th style="width: 25%;">To Date</th>       
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let Exp of this.EmployeeMasterService.SharedEmployees.EmployeeExprienceModel, let i = index">
                <td >
                  <input type="text" [(ngModel)]="Exp.ExperinceCompany" name="ExperinceCompany_{{i}}" #ExperinceCompany="ngModel" class="form-control form-control-custom form-control-custom-new">
                </td>
                <td >
                  <input type="text" [(ngModel)]="Exp.ExperinceDesignation" name="ExperinceDesignation_{{i}}" #ExperinceDesignation="ngModel" class="form-control form-control-custom form-control-custom-new">
                </td>  
                <td >
                  <input class="form-control" style="float:none" placeholder="Select date" ngx-mydatepicker name="ExperinceFromDate_{{i}}" [(ngModel)]="Exp.ExperinceFromDate" [options]="myOptions" #dpx="ngx-mydatepicker" (dateChanged)="onDateChangedFromExperince($event,i)"/>
                  <span class="mt-2">
                    <a class="btn btn-primary pl-10 pr-10 pt-1 pb-1" (click)="dpx.toggleCalendar()">
                      <i style="color:#fff" class="fas fa-calendar-alt fa-lg"></i>
                    </a>
                    <a class="btn btn-danger pl-10 pr-10 pt-1 pb-1" (click)="dpx.clearDate()">
                      <i style="color:#fff" class="far fa-calendar-times fa-lg"></i>
                    </a>
                  </span>
                </td>
                <td >
                  <input class="form-control" style="float:none" placeholder="Select date" ngx-mydatepicker name="ToDate_{{i}}" [(ngModel)]="Exp.ExperinceToDate" [options]="myOptions" #dp="ngx-mydatepicker" (dateChanged)="onDateChangedToExperince($event,i)"/>
                  <span class="mt-2">
                    <a class="btn btn-primary pl-10 pr-10 pt-1 pb-1" (click)="dp.toggleCalendar()">
                      <i style="color:#fff" class="fas fa-calendar-alt fa-lg"></i>
                    </a>

                    <a class="btn btn-danger pl-10 pr-10 pt-1 pb-1" (click)="dp.clearDate()">
                      <i style="color:#fff" class="far fa-calendar-times fa-lg"></i>
                    </a>
                  </span>
                </td>
              </tr>
            </tbody>
          </table>
          <div class="row float-right">
            <div class=" col-auto text-center">
              <button  type="button" class="btn btn-primary pl-10 pr-10 pt-1 pb-1" (click)="AddnweRowExperince()">Add Row </button>
            </div>
          </div>
        </div>
      </form>
    </fieldset>

    <fieldset class="body-form-control" *ngIf="(this.EmployeeModel.FamilyDetails)"> 
      <legend class="legend--">Employee Family Details</legend>
      <form #EmployeeFamilyDetailForm="ngForm">
        <div lass="row" >
          <table id="tblComponent" class="table table-striped table-bordered text-left table-new row-border hover">
            <thead class="thead-blue text-center">
              <tr>
                <th style="width: 25%;">Relation </th>
                <th style="width: 25%;">Name</th>  
                <th style="width: 25%;">Gender</th>
                <th style="width: 25%;">Date Of Birth</th>       
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let Fam of this.EmployeeMasterService.SharedEmployees.EmployeeFamilyDetailsModel, let i = index">
                <td>
                  <select  [(ngModel)]="Fam.FamilyRelationId" id ="FamilyRelationId_{{i}}" class="form-control" name="FamilyRelationId_{{i}}" #FamilyRelationId="ngModel">
                    <option [value]="0" [disabled]=true >Select Relation</option>
                    <option *ngFor="let f of Relations" [value]="f.RelationshipId">{{f.RelationshipName}}</option>
                  </select>
                </td>
                <td>
                  <input type="text" [(ngModel)]="Fam.FamilyMemberName" maxlength="500" id ="FamilyMemberName_{{i}}" name="FamilyMemberName_{{i}}" #FamilyMemberName="ngModel" class="form-control form-control-custom form-control-custom-new"  >
                </td>
                <td>
                  <select  [(ngModel)]="Fam.FamilyGenderId" id ="FamilyGenderId_{{i}}" class="form-control" name="FamilyGenderId_{{i}}" #FamilyGenderId="ngModel">
                    <option [value]="0" [disabled]=true >Select Gender</option>
                    <option *ngFor="let G of GanderFamilys" [value]="G.GenderId">{{G.GenderName}}</option>
                  </select>
                </td>
                <td >                   
                  <input class="form-control"  style="float:none" placeholder="Select date" ngx-mydatepicker name="FamilyDOB_{{i}}" [(ngModel)]="Fam.FamilyDOB" [options]="myOptions" #dp="ngx-mydatepicker" (dateChanged)="OnChangeDOBFamily($event,i)"/>
                  <span class="mt-2">
                    <a class="btn btn-primary pl-10 pr-10 pt-1 pb-1" (click)="dp.toggleCalendar()">
                      <i style="color:#fff" class="fas fa-calendar-alt fa-lg"></i>
                    </a>
                    <a class="btn btn-danger pl-10 pr-10 pt-1 pb-1" (click)="dp.clearDate()">
                      <i style="color:#fff" class="far fa-calendar-times fa-lg"></i>
                    </a>
                  </span>
                </td>
              </tr>
            </tbody>
          </table>

          <div class="row float-right">
            <div class=" col-auto text-center">
              <button  type="button" class="btn btn-primary pl-10 pr-10 pt-1 pb-1" (click)="AddnweRowFamily()">Add Row </button>
            </div>
          </div>
        </div>
      </form>
    </fieldset>

    <fieldset class="body-form-control" *ngIf="(this.EmployeeModel.PersonalDetails || this.EmployeeModel.AddressDetails || this.EmployeeModel.OtherDetails || this.EmployeeModel.QualificationDetails || this.EmployeeModel.ExperienceDetails || this.EmployeeModel.FamilyDetails)"> 
      <legend class="legend--"></legend>
      <form #RemarksForm="ngForm">
        <div class="row">
          <div class="col-lg-4">
            <div class="col-body-border-right">
              <div class="form-group row">
                <label  class="col-sm-4 col-form-label clear-padding-right">Remarks</label>
                <div class="col-sm-7 clear-left-padding">
                  <input type="text" [(ngModel)]="this.EmployeeMasterService.SharedEmployees.Remarks" maxlength="500" id ="Remarks" name="Remarks" #Remarks="ngModel" required class="form-control form-control-custom form-control-custom-new" >
                </div>
                <div *ngIf="Remarks.invalid && (Remarks.dirty || Remarks.touched)" class="required-validation">
                  <div *ngIf="Remarks.errors.required">Remarks is required.</div>
                  <div *ngIf="Remarks.errors.maxlength">Remarks is not more than 500 characters</div>
                </div>
              </div>
            </div> 
          </div>
  
          <div class="col-lg-4">
            <div class="form-group row">
              <label  class="col-sm-6 col-form-label text-center clear-padding-right" *ngIf="this.EmployeeMasterService.SharedEmployees.EmployeeId>0">
                <button type="submit" (click)="SavePersonalModificationApplication()" class="btn btn-primary save-button" [disabled]="!(RemarksForm.valid && this.DisableSave==true && this.AppliedStatus == false)">Submit</button>
              </label>
              <div class="col-sm-6 col-form-label text-center">
                <button type="button" class="btn btn-danger cancle-button" (click)="RefreshPage()">Refresh Page</button>
              </div>
            </div>
            <div class="required-validation" *ngIf="(AppliedStatus)">Already Applied</div>
          </div>
        </div>
      </form>
    </fieldset>

  </div>
</div>