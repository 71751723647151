<div class="body-content-area tabcontent" id="link1">
  <div class="row">
    <div class="col-lg-9">
      <div class="row">
        <div class="col-lg-1 low-padding text-center">
          <!-- <img *ngIf="this.SelectedMenu.Image!=null" src={{this.SelectedMenu.Image}} class="body-icon" alt="Icon"/> -->
        </div>
        <div class="col-lg-10 clear-padding">
          <div>
            <span class="page-title">Employee Leave Application</span>
            <small class="page-description"></small>
          </div>
        </div>
      </div>
    </div>
  </div>

  <app-dialog [(visible)]="this.SuccessStatus">
    <span class="successmsg">{{this.SuccessMessage}}</span>
  </app-dialog>

  <table>
    <tr>
      <td colspan="3">&nbsp;</td>
    </tr>
    <tr style="width: 100%;">
      <td style="width: 30%;"><strong>Leave Mode :</strong></td>
      <td style="width: 30%;">
        <input type="radio" [checked]="this.LeavemodeValue == 'DayWise'" (click)="OnChangeDayWise($event)" id ="Daywise" name="LeaveMode">
        <strong>Single Day / HalfDay</strong> &nbsp; &nbsp;
      </td>
      <td style="width: 40%;">
        <input type="radio" [checked]="this.LeavemodeValue == 'MultiDays'" (click)="OnChangeMultiDays($event)" id ="MultiDays" name="LeaveMode">
        <strong>Multiple Days</strong>
      </td>
    </tr>
    <tr>
      <td style="color: red;" colspan="3" *ngIf="ShowBalanceMessage">
        <strong>Leave is not Sufficient to Apply.</strong>
      </td>
      <td style="color: red;" colspan="3" *ngIf="LeaveExistsStatus">
        <strong>Leave Application has been Already Applied.</strong>
      </td>
    </tr>
  </table>

  <fieldset class="body-form-control" *ngIf="this.LeavemodeValue=='MultiDays'"> 
    <legend class="legend--">Leave Application</legend>
    <form #EmployeeLeaveApplicationDetails="ngForm" (ngSubmit)="SaveLeaveApply()">
      <div class="row">
        <div class="col-lg-4">
          <div class="col-body-border-right">
            <div class="form-group row">
              <label  class="col-sm-4 col-form-label clear-padding-right">Employee Code</label>
              <div class="col-sm-7 clear-left-padding">
                :&nbsp;&nbsp;{{this.LeaveApplicationModel.EmployeeCode}}
              </div>
            </div>
            <div class="form-group row">
              <label  class="col-sm-4 col-form-label clear-padding-right">Leave Type</label>
              <div class="col-sm-7 clear-left-padding">
                <select [(ngModel)]="LeaveApplicationModel.LeaveTypeId" (ngModelChange)="onChangeLeaveTypeId($event)" id ="LeaveTypeId" class="form-control"  name="LeaveTypeId" #LeaveTypeId="ngModel" >
                  <option [value]="0" [disabled]=true >Select Leave Type</option>
                  <option *ngFor="let LT of LeaveTypeMain" [value]="LT.LeaveTypeId">{{LT.LeaveTypeName}}</option>
                </select>
              </div>
              <div class="required-validation" *ngIf="LeaveApplicationModel.LeaveTypeId==0">Leave Type is required</div>
            </div>
            <div class="form-group row">
              <label  class="col-sm-4 col-form-label clear-padding-right">Date From</label>
              <div class="col-sm-7 clear-left-padding">
                :&nbsp;&nbsp;<input type="date" style=" width: 95%;" name="FromDate" [ngModel]="LeaveApplicationModel.FromDate | date:'yyyy-MM-dd'" (ngModelChange)="FromDateChanged($event)" #FromDate="ngModel" required/>
              </div>
              <div class="required-validation" *ngIf="validFromdate">From Date is not Valid Date.</div>
              <div class="required-validation" *ngIf="ShowMessage">Please select Valid Date.</div>
              <div class="required-validation" *ngIf="FromdateOffMSG">From Date is Already Off.</div>
              <div class="required-validation" *ngIf="FromdateHalfdayMSG">You can't apply For Second Half.</div>
            </div>
            <div class="form-group row">
              <div class="col-sm-4 col-form-label clear-padding-right">
                <input type="radio" [checked]="this.LeaveApplicationModel.Fromvalue=='FirstHalf'" (click)="OnChangeFromFirstHalf($event)" id ="FromFirstHalf" name="FromFirstHalf" >
                FirstHalf &nbsp; &nbsp;
                <!-- [value]="FirstHalf" -->
              </div>
              <div class="col-sm-7 clear-left-padding">
                <input type="radio" [checked]="this.LeaveApplicationModel.Fromvalue=='SecondHalf'" (click)="OnChangeFromSecondHalf($event)" id ="FromSecondHalf" name="FromSecondHalf" >
                SecondHalf
                <!-- [value]="SecondHalf" -->
              </div>
            </div>
            <div class="form-group row">
              <label  class="col-sm-4 col-form-label clear-padding-right">Reason of Leave</label>
              <div class="col-sm-7 clear-left-padding">
                <input type="text" [(ngModel)]="LeaveApplicationModel.ReasonsLeave" maxlength="500" id ="ReasonsLeave" name="ReasonsLeave" #ReasonsLeave="ngModel" required class="form-control form-control-custom form-control-custom-new" >
              </div>
              <div *ngIf="ReasonsLeave.invalid && (ReasonsLeave.dirty || ReasonsLeave.touched)" class="required-validation">
                <div *ngIf="ReasonsLeave.errors.required">Reasons is required.</div>
                <div *ngIf="ReasonsLeave.errors.maxlength">Reasons is not more than 500 characters</div>
              </div> 
            </div>
          </div> 
        </div>

        <div class="col-lg-4">
          <div class="col-body-border-right">
            <div class="form-group row">
              <label  class="col-sm-4 col-form-label clear-padding-right">Name</label>
              <div class="col-sm-7 clear-left-padding">
                :&nbsp;&nbsp;{{this.LeaveApplicationModel.EmployeeName}}
              </div>
            </div>
            <div class="form-group row">
              <label class="col-sm-4 col-form-label clear-padding-right">Balance Leave</label>
              <div class="col-sm-7 clear-left-padding">
                :&nbsp;&nbsp;{{this.LeaveApplicationModel.LeaveBalance}}
              </div>
            </div>
            <div class="form-group row">
              <label  class="col-sm-4 col-form-label clear-padding-right">Date To</label>
              <div class="col-sm-7 clear-left-padding">
                :&nbsp;&nbsp;<input type="date" style=" width: 95%;" name="ToDate" [ngModel]="LeaveApplicationModel.ToDate | date:'yyyy-MM-dd'" (ngModelChange)="ToDateChanged($event)" #ToDate="ngModel" required/>
              </div>
              <div class="required-validation" *ngIf="validTodate">To Date is not Valid Date.</div>
              <div class="required-validation" *ngIf="TodateOffMSG">To Date is Already Off.</div>
              <div class="required-validation" *ngIf="TodateHalfdayMSG">You can't apply For First Half.</div>
            </div>
            <div class="form-group row">
              <div class="col-sm-4 col-form-label clear-padding-right">
                <input type="radio" [checked]="this.LeaveApplicationModel.Tovalue=='FirstHalf'" (click)="OnChangeToFirstHalf($event)" id ="ToFirstHalf" name="ToFirstHalf">
                FirstHalf &nbsp; &nbsp;
                <!-- [value]="FirstHalf" -->
              </div>
              <div class="col-sm-7 clear-left-padding">
                <input type="radio" [checked]="this.LeaveApplicationModel.Tovalue=='SecondHalf'" (click)="OnChangeToSecondHalf($event)" id ="ToSecondHalf" name="ToSecondHalf">
                SecondHalf
                <!-- [value]="SecondHalf" -->
              </div>
            </div>
            <div class="form-group row">
              <label  class="col-sm-4 col-form-label clear-padding-right">Person To Contact</label>
              <div class="col-sm-7 clear-left-padding">
                <input type="text" [(ngModel)]="LeaveApplicationModel.PersonToContact" maxlength="250" id ="PersonToContact" name="PersonToContact" #PersonToContact="ngModel" class="form-control form-control-custom form-control-custom-new" required>
              </div>
              <div *ngIf="PersonToContact.invalid && (PersonToContact.dirty || PersonToContact.touched)" class="required-validation">
                <div *ngIf="PersonToContact.errors.maxlength">Person To Contact is not more than 250 characters</div>
                <div *ngIf="PersonToContact.errors.required">Person To Contact is required.</div>
              </div>
            </div>           
          </div> 
        </div>

        <div class="col-lg-4">
          <div class="form-group row">
            <label  class="col-sm-4 col-form-label clear-padding-right">Approver</label>
            <div class="col-sm-7 clear-left-padding">
              :&nbsp;&nbsp;{{this.LeaveApplicationModel.RHName}} 
            </div>
          </div>
          <div class="form-group row">
            <label  class="col-sm-4 col-form-label clear-padding-right">Date Of Application</label>
            <div class="col-sm-7 clear-left-padding">
              :&nbsp;&nbsp;{{this.LeaveApplicationModel.ApplicationDate | date : "dd-MMM-yyyy"}}
            </div>
          </div>
          <div class="form-group row">
            <label class="col-sm-4 col-form-label clear-padding-right">No Of Days</label>
            <div class="col-sm-7 clear-left-padding">
              :&nbsp;&nbsp;{{this.LeaveApplicationModel.NoDaysLeave}}
            </div>
          </div>
          <div class="form-group row">
            <label class="col-sm-4 col-form-label clear-padding-right">Alternate No</label>
            <div class="col-sm-7 clear-left-padding">
                <input type="text" [(ngModel)]="LeaveApplicationModel.ContactNoDuringLeave" maxlength="10" minlength="10" pattern="[0-9]+" id ="ContactNoDuringLeave" name="ContactNoDuringLeave" #ContactNoDuringLeave="ngModel" class="form-control form-control-custom form-control-custom-new" required>
            </div>
            <div *ngIf="ContactNoDuringLeave.invalid && (ContactNoDuringLeave.dirty || ContactNoDuringLeave.touched)" class="required-validation">
                <div *ngIf="ContactNoDuringLeave.errors.maxlength">Mobile No is not more than 10 digit.</div>
                <div *ngIf="ContactNoDuringLeave.errors.minlength">Mobile No is not less than 10 digit.</div>
                <div *ngIf="ContactNoDuringLeave.errors?.pattern">Mobile No should be valid.</div>
                <div *ngIf="ContactNoDuringLeave.errors.required">Alternate No is required.</div>
            </div>
          </div>
          <div class="form-group row">
            <label class="col-sm-6 col-form-label text-center clear-padding-right">
              <button type="submit" class="btn btn-primary save-button" 
              [disabled]="!(EmployeeLeaveApplicationDetails.valid 
              && LeaveApplicationModel.LeaveTypeId!=0 && this.DisableSave==true 
              && this.LeaveApplicationModel.NoDaysLeave >0
              && this.FromdateOffMSG == false && this.TodateOffMSG == false
              && this.FromdateHalfdayMSG == false && this.TodateHalfdayMSG == false
              && this.validFromdate == false && this.validTodate == false && this.LeaveExistsStatus == false
              && this.ShowBalanceMessage == false)">Save</button>  

              <!-- && (this.LeaveApplicationModel.LeaveBalance-this.LeaveApplicationModel.NoDaysLeave)>=0 -->
            </label>
            <div class="col-sm-6 col-form-label text-center">
              <button type="button" (click)="RefreshPage()" class="btn btn-danger cancle-button">Cancel</button>
            </div>
          </div>
          
        </div>
      </div>

    </form>
  </fieldset>

  <fieldset class="body-form-control" *ngIf="this.LeavemodeValue=='DayWise'"> 
    <legend class="legend--">Leave Application Day Wise/ HalfDay</legend>
    <form #EmployeeLeaveApplicationDaywise="ngForm" (ngSubmit)="SaveLeaveApply()">
      <div class="row">
        <div class="col-lg-4">
          <div class="col-body-border-right">
            <div class="form-group row">
              <label  class="col-sm-4 col-form-label clear-padding-right">Employee Code</label>
              <div class="col-sm-7 clear-left-padding">
                :&nbsp;&nbsp;{{this.LeaveApplicationModel.EmployeeCode}}
              </div>
            </div>
            <div class="form-group row">
              <label  class="col-sm-4 col-form-label clear-padding-right">Leave Type</label>
              <div class="col-sm-7 clear-left-padding">
                <select [(ngModel)]="LeaveApplicationModel.LeaveTypeId" (ngModelChange)="onChangeLeaveTypeId($event)" id ="LeaveTypeId" class="form-control"  name="LeaveTypeId" #LeaveTypeId="ngModel" >
                  <option [value]="0" [disabled]=true >Select Leave Type</option>
                  <option *ngFor="let LT of LeaveTypeMain" [value]="LT.LeaveTypeId">{{LT.LeaveTypeName}}</option>
                </select>
              </div>
              <div class="required-validation" *ngIf="LeaveApplicationModel.LeaveTypeId==0">Leave Type is required</div>
            </div>
            <div class="form-group row">
              <label  class="col-sm-4 col-form-label clear-padding-right">Date :</label>
              <div class="col-sm-7 clear-left-padding">
                :&nbsp;&nbsp;<input type="date" style=" width: 95%;" name="FromDate" [ngModel]="LeaveApplicationModel.FromDate | date:'yyyy-MM-dd'" (ngModelChange)="FromDateDaywiseChanged($event)" #FromDate="ngModel" required/>
              </div>
              <div class="required-validation" *ngIf="validFromdate">Date is not Valid Date.</div>
              <div class="required-validation" *ngIf="ShowMessage">Please select Valid Date.</div>
              <div class="required-validation" *ngIf="FromdateOffMSG">Attendance Date is Already Off/Holidays.</div>
            </div>
            <div class="form-group row">
              <label  class="col-sm-4 col-form-label clear-padding-right">Reason of Leave</label>
              <div class="col-sm-7 clear-left-padding">
                <input type="text" [(ngModel)]="LeaveApplicationModel.ReasonsLeave" maxlength="500" id ="ReasonsLeave" name="ReasonsLeave" #ReasonsLeave="ngModel" required class="form-control form-control-custom form-control-custom-new" >
              </div>
              <div *ngIf="ReasonsLeave.invalid && (ReasonsLeave.dirty || ReasonsLeave.touched)" class="required-validation">
                <div *ngIf="ReasonsLeave.errors.required">Reasons is required.</div>
                <div *ngIf="ReasonsLeave.errors.maxlength">Reasons is not more than 500 characters</div>
              </div> 
            </div>
          </div> 
        </div>

        <div class="col-lg-4">
          <div class="col-body-border-right">
            <div class="form-group row">
              <label  class="col-sm-4 col-form-label clear-padding-right">Name</label>
              <div class="col-sm-7 clear-left-padding">
                :&nbsp;&nbsp;{{this.LeaveApplicationModel.EmployeeName}}
              </div>
            </div>
            <div class="form-group row">
              <label class="col-sm-4 col-form-label clear-padding-right">Balance Leave</label>
              <div class="col-sm-7 clear-left-padding">
                :&nbsp;&nbsp;{{this.LeaveApplicationModel.LeaveBalance}}
              </div>
            </div>
            <div class="form-group row">
              <label class="col-sm-4 col-form-label clear-padding-right">Leave Mode</label>
              <div class="col-sm-7 clear-left-padding">
                <select [(ngModel)]="LeaveApplicationModel.LeaveModeId" (ngModelChange)="onChangeLeaveMode($event)" id ="LeaveModeId" class="form-control" name="LeaveModeId">
                  <option [value]="0" [disabled]=true >Select Leave Mode</option>
                  <option [value]="1">First Half</option>
                  <option [value]="2">Second Half</option>
                  <option [value]="3">Full Day</option>
                </select>
              </div>
            </div>
            <div class="form-group row">
              <label  class="col-sm-4 col-form-label clear-padding-right">Person To Contact</label>
              <div class="col-sm-7 clear-left-padding">
                <input type="text" [(ngModel)]="LeaveApplicationModel.PersonToContact" maxlength="250" id ="PersonToContact" name="PersonToContact" #PersonToContact="ngModel" class="form-control form-control-custom form-control-custom-new" required>
              </div>
              <div *ngIf="PersonToContact.invalid && (PersonToContact.dirty || PersonToContact.touched)" class="required-validation">
                <div *ngIf="PersonToContact.errors.maxlength">Person To Contact is not more than 250 characters</div>
                <div *ngIf="PersonToContact.errors.required">Person To Contact is required.</div>
              </div>
            </div>           
          </div> 
        </div>

        <div class="col-lg-4">
          <div class="form-group row">
            <label  class="col-sm-4 col-form-label clear-padding-right">Approver</label>
            <div class="col-sm-7 clear-left-padding">
              :&nbsp;&nbsp;{{this.LeaveApplicationModel.RHName}} 
            </div>
          </div>
          <div class="form-group row">
            <label  class="col-sm-4 col-form-label clear-padding-right">Date Of Application</label>
            <div class="col-sm-7 clear-left-padding">
              :&nbsp;&nbsp;{{this.LeaveApplicationModel.ApplicationDate | date : "dd-MMM-yyyy"}}
            </div>
          </div>
          <div class="form-group row">
            <label class="col-sm-4 col-form-label clear-padding-right">No Of Days</label>
            <div class="col-sm-7 clear-left-padding">
              :&nbsp;&nbsp;{{this.LeaveApplicationModel.NoDaysLeave}}
            </div>
          </div>
          <div class="form-group row">
            <label class="col-sm-4 col-form-label clear-padding-right">Alternate No</label>
            <div class="col-sm-7 clear-left-padding">
                <input type="text" [(ngModel)]="LeaveApplicationModel.ContactNoDuringLeave" maxlength="10" minlength="10" pattern="[0-9]+" id ="ContactNoDuringLeave" name="ContactNoDuringLeave" #ContactNoDuringLeave="ngModel" class="form-control form-control-custom form-control-custom-new" required>
            </div>
            <div *ngIf="ContactNoDuringLeave.invalid && (ContactNoDuringLeave.dirty || ContactNoDuringLeave.touched)" class="required-validation">
                <div *ngIf="ContactNoDuringLeave.errors.maxlength">Mobile No is not more than 10 digit.</div>
                <div *ngIf="ContactNoDuringLeave.errors.minlength">Mobile No is not less than 10 digit.</div>
                <div *ngIf="ContactNoDuringLeave.errors?.pattern">Mobile No should be valid.</div>
                <div *ngIf="ContactNoDuringLeave.errors.required">Alternate No is required.</div>
            </div>
          </div>
          <div class="form-group row">
            <label class="col-sm-6 col-form-label text-center clear-padding-right">
              <button type="submit" class="btn btn-primary save-button" 
              [disabled]="!(EmployeeLeaveApplicationDaywise.valid 
              && LeaveApplicationModel.LeaveTypeId!=0 && this.DisableSave==true 
              && this.LeaveApplicationModel.NoDaysLeave >0
              && this.FromdateOffMSG == false && this.TodateOffMSG == false
              && this.FromdateHalfdayMSG == false && this.TodateHalfdayMSG == false
              && this.validFromdate == false && this.validTodate == false && this.LeaveExistsStatus == false
              && this.ShowBalanceMessage == false)">Save</button>  
            </label>
            <div class="col-sm-6 col-form-label text-center">
              <button type="button" (click)="RefreshPage()" class="btn btn-danger cancle-button">Cancel</button>
            </div>
          </div>
        </div>
      </div>

    </form>
  </fieldset>

  <fieldset class="body-form-control" *ngIf="EmployeeLeaveBalance.length > 0"> 
    <legend class="legend--">Leave Balance Details</legend>
    <table id="tblEmployeeLeaveBalanceDetails" class="table table-striped table-bordered text-center table-new row-border hover">
      <thead class="thead-blue text-center">
        <tr>
          <th>Sl.No.</th>
          <th>Leave Type</th>
          <th>Leave Entitlement</th>
          <th>Leave Taken During the Year</th>
          <th>Opening Balance as of Today</th>
          <th>Leave Applied or pending for Approval</th>
          <th>Available Balance as of Today</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let EmpLeaveBal of EmployeeLeaveBalance, let i = index">
          <td scope="row">{{i+1}}</td>
          <td style=" text-align: left;">{{EmpLeaveBal.LeaveTypeName}}</td>
          <td>{{EmpLeaveBal.NoOfLeave}}</td>
          <td>{{EmpLeaveBal.LeaveTaken}}</td>
          <td>{{EmpLeaveBal.BalanceLeave}}</td>
          <td>{{EmpLeaveBal.LeaveApply}}</td>
          <td>{{EmpLeaveBal.BalanceLeaveAfterApply}}</td>
        </tr>
      </tbody>
      <tfoot class="thead-blue text-center">
        <tr>
          <th></th>
          <th></th>
          <th></th>
          <th></th>
          <th></th>
          <th></th>
          <th></th>
        </tr>
      </tfoot>
    </table>
  </fieldset>
  
  <fieldset class="body-form-control" *ngIf="EmployeeLeaveApplication.length > 0"> 
    <legend class="legend--">Leave Applied</legend>
    <table id="tblEmployeeLeaveApplicationDetails" class="table table-striped table-bordered text-center table-new row-border hover">
      <thead class="thead-blue text-center">
        <tr>
          <th>Sl.No.</th>
          <th>Application Date</th>
          <th>From-Date</th>
          <th>To-Date</th>
          <th>No of Days</th>
          <th>Remarks</th>
          <th>Status</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let EmpLeaveApp of EmployeeLeaveApplication, let i = index">
          <td scope="row">{{i+1}}</td>
          <td>{{EmpLeaveApp.ApplicationDate | date:'dd-MMM-yyyy'}}</td>
          <td>{{EmpLeaveApp.FromDate | date:'dd-MMM-yyyy'}}</td>
          <td>{{EmpLeaveApp.ToDate | date:'dd-MMM-yyyy'}}</td>
          <td>{{EmpLeaveApp.NoDaysLeave}}</td>
          <td style=" text-align: left;">{{EmpLeaveApp.ReasonsLeave}}</td>
          <td>{{EmpLeaveApp.LeaveStatus}}</td>
        </tr>
      </tbody>
      <tfoot class="thead-blue text-center">
        <tr>
          <th></th>
          <th></th>
          <th></th>
          <th></th>
          <th></th>
          <th></th>
          <th></th>
        </tr>
      </tfoot>
    </table>
  </fieldset>
  
</div>