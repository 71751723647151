<div class="body-content-area tabcontent " id="link1" >
  <div class="row">
    <div class="col-lg-9">
      <div class="row">
        <div class="col-lg-1 low-padding text-center">
          <!-- <img *ngIf="this.SelectedMenu.Image!=null" src={{this.SelectedMenu.Image}} class="body-icon" alt="Icon"/> -->
        </div>
        <div class="col-lg-10 clear-padding">
          <div>
            <span class="page-title">Employee Personal Information</span>
            <small class="page-description"></small>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div>
    <fieldset class="body-form-control" > 
      <legend class="legend--">Employee Details</legend>
      <form #EmployeeAttendanceForm="ngForm">
        <div class="row">
          <div class="col-lg-4 col-body-border-right">
            <div class="form-group row">
              <label class="col-sm-3 col-form-label clear-padding-right">Employee</label>
              <div class="col-sm-9 clear-left-padding">
                <angular2-multiselect class="form-control" [data]="emp" [(ngModel)]="selectedItems" name="EmployeeId" 
                  [settings]="EmployeeSettings" (onSelect)="onItemSelect($event)" (onDeSelect)="OnItemDeSelect($event)" (onSelectAll)="onSelectAll($event)"
                  (onDeSelectAll)="onDeSelectAll($event)">
                </angular2-multiselect>
              </div>
              <div class="required-validation" *ngIf="this.EmployeeMasterService.SharedEmployees.EmployeeId == 0">Employee is required</div>
            </div>
          </div>
        </div>
      </form>    
    </fieldset>

    <fieldset *ngIf="this.EmployeeMasterService.SharedEmployees.EmployeeId > 0" class="body-form-control" > 
    <legend class="legend--">Personal Details</legend>
      <form #EmployeeDetailForm="ngForm">
        <div class="row">
          <div class="col-lg-4">
            <div class="col-body-border-right">
              <div class="form-group row">
                <label class="col-sm-4 col-form-label clear-padding-right" style=" font-weight: bold;">Employee Id</label>
                <div class="col-sm-7 clear-left-padding">
                  :&nbsp;&nbsp;{{this.EmployeeMasterService.SharedEmployees.EmployeeCode}} 
                </div>
              </div>
              <div class="form-group row">
                <label class="col-sm-4 col-form-label clear-padding-right" style=" font-weight: bold;">Father's Name</label>
                <div class="col-sm-7 clear-left-padding">
                    :&nbsp;&nbsp;{{this.EmployeeMasterService.SharedEmployees.FathersName}}
                </div>
              </div>
              <div class="form-group row">
                <label class="col-sm-4 col-form-label clear-padding-right" style=" font-weight: bold;">Company</label>
                <div class="col-sm-7 clear-left-padding">
                    :&nbsp;&nbsp;{{this.EmployeeMasterService.SharedEmployees.CompanyName}}
                </div>
              </div>
              <div class="form-group row">
                <label class="col-sm-4 col-form-label clear-padding-right" style=" font-weight: bold;">DecisionMaking</label>
                <div class="col-sm-7 clear-left-padding">
                    :&nbsp;&nbsp;{{this.EmployeeMasterService.SharedEmployees.DecisionName}}
                </div>
              </div>
              <div class="form-group row">
                <label class="col-sm-4 col-form-label clear-padding-right" style=" font-weight: bold;">Designation</label>
                <div class="col-sm-7 clear-left-padding">
                    :&nbsp;&nbsp;{{this.EmployeeMasterService.SharedEmployees.DesignationName}}
                </div> 
              </div>
              <div class="form-group row">
                <label class="col-sm-4 col-form-label clear-padding-right" style=" font-weight: bold;">Reporting Head</label>
                <div class="col-sm-7 clear-left-padding">
                  :&nbsp;&nbsp;{{this.EmployeeMasterService.SharedEmployees.ReportingHeadName}}
                </div>
              </div>
              <div class="form-group row">
                <label class="col-sm-4 col-form-label clear-padding-right" style=" font-weight: bold;">Functional Head</label>
                <div class="col-sm-7 clear-left-padding">
                  :&nbsp;&nbsp;{{this.EmployeeMasterService.SharedEmployees.FunctionalHeadName}}
                </div>
              </div>
              <div class="form-group row">
                <label class="col-sm-4 col-form-label clear-padding-right" style=" font-weight: bold;">OfferDate</label>
                <div class="col-sm-7 clear-left-padding">
                  :&nbsp;&nbsp;{{this.EmployeeMasterService.SharedEmployees.OfferDate | date : "dd-MMM-yyyy"}}
                </div>
              </div>
            </div>
          </div>
            
          <div class="col-lg-4">
            <div class="col-body-border-right">
              <div class="form-group row">
                <label class="col-sm-4 col-form-label clear-padding-right" style=" font-weight: bold;">Name</label>
                <div class="col-sm-7 clear-left-padding">
                    :&nbsp;&nbsp;{{this.EmployeeMasterService.SharedEmployees.FullName}}
                </div>
              </div>
              <div class="form-group row">
                <label class="col-sm-4 col-form-label clear-padding-right" style=" font-weight: bold;">Mother's Name</label>
                <div class="col-sm-7 clear-left-padding">
                    :&nbsp;&nbsp;{{this.EmployeeMasterService.SharedEmployees.MothersName}}
                </div>
              </div>
              <div class="form-group row">
                <label class="col-sm-4 col-form-label clear-padding-right" style=" font-weight: bold;">Branch</label>
                <div class="col-sm-7 clear-left-padding">
                    :&nbsp;&nbsp;{{this.EmployeeMasterService.SharedEmployees.BranchName}}
                </div>
              </div>
              <div class="form-group row">
                <label class="col-sm-4 col-form-label clear-padding-right" style=" font-weight: bold;">Level</label>
                <div class="col-sm-7 clear-left-padding">
                    :&nbsp;&nbsp;{{this.EmployeeMasterService.SharedEmployees.LevelName}}
                </div>
              </div>
              <div class="form-group row">
                <label class="col-sm-4 col-form-label clear-padding-right" style=" font-weight: bold;">Mother Tongue</label>
                <div class="col-sm-7 clear-left-padding">
                  :&nbsp;&nbsp;{{this.EmployeeMasterService.SharedEmployees.MotherTongue}}
                </div>
              </div>
              <div class="form-group row">
                <label class="col-sm-4 col-form-label clear-padding-right" style=" font-weight: bold;">Blood Group</label>
                <div class="col-sm-7 clear-left-padding">
                    :&nbsp;&nbsp;{{this.EmployeeMasterService.SharedEmployees.BloodGroup}}
                </div>
              </div>
              <div class="form-group row">
                <label class="col-sm-4 col-form-label clear-padding-right" style=" font-weight: bold;">Email Id</label>
                <div class="col-sm-7 clear-left-padding">
                    :&nbsp;&nbsp;{{this.EmployeeMasterService.SharedEmployees.EmailId}}
                </div>
              </div>
              <div class="form-group row">
                <label class="col-sm-4 col-form-label clear-padding-right" style=" font-weight: bold;">JoingingDate</label>
                <div class="col-sm-7 clear-left-padding">
                    :&nbsp;&nbsp;{{this.EmployeeMasterService.SharedEmployees.JoiningDate | date : "dd-MMM-yyyy"}}
                </div>
              </div>
            </div>
          </div>

          <div class="col-lg-4">
            <div class="form-group row">
              <label class="col-sm-4 col-form-label clear-padding-right" style=" font-weight: bold;">DOB</label>
              <div class="col-sm-7 clear-left-padding">
                :&nbsp;&nbsp;{{this.EmployeeMasterService.SharedEmployees.DOB | date : "dd-MMM-yyyy"}}
              </div>
            </div>
            <div *ngIf="this.EmployeeMasterService.SharedEmployees.MaritalId == 1" class="form-group row">
              <label class="col-sm-4 col-form-label clear-padding-right" style=" font-weight: bold;">AnniversaryDate</label>
              <div class="col-sm-7 clear-left-padding">
                  :&nbsp;&nbsp;{{this.EmployeeMasterService.SharedEmployees.AnniversaryDate | date : "dd-MMM-yyyy"}}
              </div>
            </div>
            <div *ngIf="this.EmployeeMasterService.SharedEmployees.MaritalId != 1" class="form-group row">
              <label class="col-sm-4 col-form-label clear-padding-right">&nbsp;</label>
              <div class="col-sm-7 clear-left-padding">
                &nbsp;
              </div>
            </div>
            <div class="form-group row">
              <label class="col-sm-4 col-form-label clear-padding-right" style=" font-weight: bold;">Department</label>
              <div class="col-sm-7 clear-left-padding">
                :&nbsp;&nbsp;{{this.EmployeeMasterService.SharedEmployees.DepartmentName}}    
              </div>
            </div>
            <div class="form-group row">
              <label class="col-sm-4 col-form-label clear-padding-right" style=" font-weight: bold;">Grade</label>
              <div class="col-sm-7 clear-left-padding">
                :&nbsp;&nbsp;{{this.EmployeeMasterService.SharedEmployees.GradeName}}
              </div>
            </div>
            <div class="form-group row">
              <label class="col-sm-4 col-form-label clear-padding-right" style=" font-weight: bold;">Gender</label>
              <div class="col-sm-7 clear-left-padding">
                  :&nbsp;&nbsp;{{this.EmployeeMasterService.SharedEmployees.GenderName}}
              </div>
            </div>
            <div class="form-group row">
              <label class="col-sm-4 col-form-label clear-padding-right" style=" font-weight: bold;">Religion</label>
              <div class="col-sm-7 clear-left-padding">
                  :&nbsp;&nbsp;{{this.EmployeeMasterService.SharedEmployees.ReligionName}}
              </div>
            </div>
            <div class="form-group row">
              <label class="col-sm-4 col-form-label clear-padding-right" style=" font-weight: bold;">Mobile No</label>
              <div class="col-sm-7 clear-left-padding">
                  :&nbsp;&nbsp;{{this.EmployeeMasterService.SharedEmployees.ContactNumber}}
              </div>
            </div>
            <div class="form-group row">
              <label class="col-sm-4 col-form-label clear-padding-right" style=" font-weight: bold;">Marital Status</label>
              <div class="col-sm-7 clear-left-padding">
                :&nbsp;&nbsp;{{this.EmployeeMasterService.SharedEmployees.MaritalName}}
              </div>
            </div>
          </div>
        </div>
      </form>    
    </fieldset>

    <fieldset *ngIf="this.EmployeeMasterService.SharedEmployees.CAddress1!=null" class="body-form-control" > 
      <legend class="legend--">Address Details</legend>
      <form #EmployeeAddressDetailForm="ngForm">
        <div class="row">
          <table class="table table-striped table-bordered text-left table-new row-border hover">
            <thead class="thead-blue text-center">
              <tr style="width: 60%;">
                <td style="width: 25%;"></td>
                <td style="width: 36;">Present</td>
                <td style="width: 36;">Permanent</td>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td style="text-align:left">House No/ Street</td>
                <td>{{this.EmployeeMasterService.SharedEmployees.CAddress1}}</td>
                <td>{{this.EmployeeMasterService.SharedEmployees.PAddress1}}</td>
              </tr>
              <tr>
                <td style="text-align:left">Address2 </td>
                <td>{{this.EmployeeMasterService.SharedEmployees.CAddress2}}</td>
                <td>{{this.EmployeeMasterService.SharedEmployees.PAddress2}}</td>
              </tr>
              <tr>
                <td style="text-align:left">Contact No </td>
                <td>{{this.EmployeeMasterService.SharedEmployees.CContactNo}}</td>
                <td>{{this.EmployeeMasterService.SharedEmployees.PContactNo}}</td>
              </tr>
              <tr>
                <td style="text-align:left">Country</td>
                <td>{{this.EmployeeMasterService.SharedEmployees.CCountry}}</td>
                <td>{{this.EmployeeMasterService.SharedEmployees.PCountry}}</td>
              </tr>
              <tr>
                <td style="text-align:left">State </td>
                <td>{{this.EmployeeMasterService.SharedEmployees.CState}}</td>
                <td>{{this.EmployeeMasterService.SharedEmployees.PState}}</td>
              </tr>
              <tr>
                <td style="text-align:left">District </td>
                <td>{{this.EmployeeMasterService.SharedEmployees.CDistrict}}</td>
                <td>{{this.EmployeeMasterService.SharedEmployees.PDistrict}}</td>
              </tr>
              <tr>
                <td style="text-align:left">City </td>
                <td>{{this.EmployeeMasterService.SharedEmployees.CCity}}</td>
                <td>{{this.EmployeeMasterService.SharedEmployees.PCity}}</td>
              </tr>
              <tr>
                <td style="text-align:left">Pin Code</td>
                <td>{{this.EmployeeMasterService.SharedEmployees.CPincode}}</td>
                <td>{{this.EmployeeMasterService.SharedEmployees.PPincode}}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </form>
    </fieldset> 

    <fieldset *ngIf="this.EmployeeMasterService.SharedEmployees.PaymentModeName!=null" class="body-form-control" > 
      <legend class="legend--">Other Information Details</legend>
      <form #EmployeeDetailForm="ngForm">
        <div class="row">
          <div class="col-lg-4">
            <div class="col-body-border-right">
              <div class="form-group row">
                <label  class="col-sm-4 col-form-label clear-padding-right" style=" font-weight: bold;">Pan No</label>
                <div class="col-sm-7 clear-left-padding">
                    :&nbsp;&nbsp;{{this.EmployeeMasterService.SharedEmployees.PANNo}}
                </div>
              </div>
              <div class="form-group row">
                <label  class="col-sm-4 col-form-label clear-padding-right" style=" font-weight: bold;">Voter Id</label>
                <div class="col-sm-7 clear-left-padding">
                    :&nbsp;&nbsp;{{this.EmployeeMasterService.SharedEmployees.VoterId}}
                </div>
              </div>
              <div class="form-group row">
                <label  class="col-sm-4 col-form-label clear-padding-right" style=" font-weight: bold;">Adhar No</label>
                <div class="col-sm-7 clear-left-padding">
                    :&nbsp;&nbsp;{{this.EmployeeMasterService.SharedEmployees.AdhaarNo}}
                </div>
              </div>
              <div class="form-group row">
                <label  class="col-sm-4 col-form-label clear-padding-right" style=" font-weight: bold;">Payment Mode</label>
                <div class="col-sm-7 clear-left-padding">
                    :&nbsp;&nbsp;{{this.EmployeeMasterService.SharedEmployees.PaymentModeName}}
                </div>
              </div>
              <div *ngIf="this.EmployeeMasterService.SharedEmployees.PaymentModeId > 1" class="form-group row">
                <label class="col-sm-4 col-form-label clear-padding-right" style=" font-weight: bold;">IFSC Code</label>
                <div class="col-sm-7 clear-left-padding">
                    :&nbsp;&nbsp;{{this.EmployeeMasterService.SharedEmployees.IFSCCode}}
                </div>
              </div>
            </div> 
          </div>
          <div class="col-lg-4">
            <div class="col-body-border-right">
              <div class="form-group row">
                <label class="col-sm-4 col-form-label clear-padding-right" style=" font-weight: bold;">EPF No</label>
                <div class="col-sm-7 clear-left-padding">
                  {{this.EmployeeMasterService.SharedEmployees.EPFNo}}
                </div>                    
              </div>
              <div class="form-group row">
                <label  class="col-sm-4 col-form-label clear-padding-right" style=" font-weight: bold;">ESI No</label>
                <div class="col-sm-7 clear-left-padding">
                  {{this.EmployeeMasterService.SharedEmployees.ESINo}}
                </div>
              </div>
              <div class="form-group row">
                <label  class="col-sm-4 col-form-label clear-padding-right" style=" font-weight: bold;">Gratuity No</label>
                <div class="col-sm-7 clear-left-padding">
                  {{this.EmployeeMasterService.SharedEmployees.GratuityNo}}
                </div>
              </div>
              <div *ngIf="this.EmployeeMasterService.SharedEmployees.PaymentModeId > 1" class="form-group row">
                <label  class="col-sm-4 col-form-label clear-padding-right" style=" font-weight: bold;">Account No</label>
                <div class="col-sm-7 clear-left-padding">
                  {{this.EmployeeMasterService.SharedEmployees.AccountNumber}}
                </div>
              </div>
              <div *ngIf="this.EmployeeMasterService.SharedEmployees.PaymentModeId > 1" class="form-group row">
                <label  class="col-sm-4 col-form-label clear-padding-right" style=" font-weight: bold;">Branch Name</label>
                <div class="col-sm-7 clear-left-padding">
                  {{this.EmployeeMasterService.SharedEmployees.BankBranchName}}
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-4">
            <div class="form-group row">
              <label  class="col-sm-4 col-form-label clear-padding-right" style=" font-weight: bold;">Pension No</label>
              <div class="col-sm-7 clear-left-padding">
                  :&nbsp;&nbsp;{{this.EmployeeMasterService.SharedEmployees.PensionNo}}
              </div>
            </div>
            <div class="form-group row">
              <label  class="col-sm-4 col-form-label clear-padding-right" style=" font-weight: bold;">Superannuation No</label>
              <div class="col-sm-7 clear-left-padding">
                  :&nbsp;&nbsp;{{this.EmployeeMasterService.SharedEmployees.SuperannuationNo}}
              </div>
            </div>
            <div class="form-group row">
              <label  class="col-sm-4 col-form-label clear-padding-right" style=" font-weight: bold;">UAN No</label>
              <div class="col-sm-7 clear-left-padding">
                  :&nbsp;&nbsp;{{this.EmployeeMasterService.SharedEmployees.UANNo}}
              </div>
            </div>  
            <div *ngIf="this.EmployeeMasterService.SharedEmployees.PaymentModeId > 1" class="form-group row">
              <label  class="col-sm-4 col-form-label clear-padding-right" style=" font-weight: bold;">Bank</label>
              <div class="col-sm-7 clear-left-padding">
                  :&nbsp;&nbsp;{{this.EmployeeMasterService.SharedEmployees.BankName}}
              </div>
            </div> 
          </div>
        </div>
      </form>
    </fieldset>

    <!-- <fieldset *ngIf="this.EmployeeMasterService.SharedEmployees.EmployeeEducation[0].DegreeName !=='undefined'" class="body-form-control" >  -->
    <fieldset *ngIf="this.employeeEducationUndefine !=='notdefinedval'" class="body-form-control" > 
      <legend class="legend--">Qualification Details</legend>
      <form #EmployeeEducationDetailForm="ngForm">
        <div lass="row" >
          <table id="tblComponent" class="table table-striped table-bordered text-left table-new row-border hover">
            <thead class="thead-blue text-center">
              <tr>
                <th style="width: 15%;">Degree </th>
                <th style="width: 15%;">Qualification</th>
                <th style="width: 15%;">InstituteName</th>
                <th style="width: 10%;">University/Board</th>
                <th style="width: 5%;">Marks/Grade(%)</th>
                <th style="width: 10%;">Division</th>
                <th style="width: 15%;">From Date</th>
                <th style="width: 15%;">To Date</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let Edu of this.EmployeeMasterService.SharedEmployees.EmployeeEducation, let i = index">
                <td>{{Edu.DegreeName}}</td>
                <td>{{Edu.DegreeCode}}</td>
                <td>{{Edu.Institute}}</td>
                <td>{{Edu.BoardUniversity}}</td>
                <td>{{Edu.MarksPercentage}}</td>
                <td>{{Edu.Division}}</td>
                <td>{{Edu.FromDate | date : "dd-MMM-yyyy"}}</td>
                <td>{{Edu.ToDate | date : "dd-MMM-yyyy"}}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </form>
    </fieldset>

    <!-- <fieldset *ngIf="this.EmployeeMasterService.SharedEmployees.EmployeeExprienceModel.length>0" class="body-form-control" >  -->
    <fieldset *ngIf="this.employeeExpriencUndefine !=='notdefinedval'" class="body-form-control" >
      <legend class="legend--">Experience Details</legend>
      <form #EmployeeExperienceDetailForm="ngForm">
        <div lass="row" >
          <table id="tblComponent" class="table table-striped table-bordered text-left table-new row-border hover">
            <thead class="thead-blue text-center">
              <tr>
                <th style="width: 25%;">Name Of Employeer</th>
                <th style="width: 25%;">Designation</th>  
                <th style="width: 25%;">Form Date</th>
                <th style="width: 25%;">To Date</th>       
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let Exp of this.EmployeeMasterService.SharedEmployees.EmployeeExprienceModel, let i = index">
                <td>{{Exp.ExperinceCompany}}</td>
                <td>{{Exp.ExperinceDesignation}}</td>
                <td>{{Exp.ExperinceFromDate | date : "dd-MMM-yyyy"}}</td>
                <td>{{Exp.ExperinceToDate | date : "dd-MMM-yyyy"}}</td>         
              </tr>
            </tbody>
          </table>
        </div>
      </form>
    </fieldset>

    <!-- <fieldset *ngIf="this.EmployeeMasterService.SharedEmployees.EmployeeFamilyDetailsModel.length>0" class="body-form-control" >  -->
    <fieldset *ngIf="this.employeeFamilyDetailsUndefine !=='notdefinedval'" class="body-form-control" >
      <legend class="legend--">Family Details</legend>
      <form #EmployeeFamilyDetailForm="ngForm">
        <div lass="row" >
          <table id="tblComponent" class="table table-striped table-bordered text-left table-new row-border hover">
            <thead class="thead-blue text-center">
              <tr>
                <th style="width: 25%;">Name</th> 
                <th style="width: 25%;">Relation </th>
                <th style="width: 25%;">Gender</th>
                <th style="width: 25%;">Date Of Birth</th>       
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let Fam of this.EmployeeMasterService.SharedEmployees.EmployeeFamilyDetailsModel, let i = index">
                <td>{{Fam.FamilyMemberName}}</td>
                <td>{{Fam.FamilyRelation}}</td>
                <td>{{Fam.FamilyGender}}</td>
                <td>{{Fam.FamilyDOB | date : "dd-MMM-yyyy"}}</td>             
              </tr>
            </tbody>
          </table>
        </div>
      </form>
    </fieldset>

    <!-- <fieldset *ngIf="this.EmployeeMasterService.SharedEmployees.EmployeeNomineeDetailModel.length>0" class="body-form-control" >  -->
    <fieldset  *ngIf="this.employeeNomineeDetailsUndefine !=='notdefinedval'" class="body-form-control" > 
      <legend class="legend--">Nominee Details</legend>
      <form #EmployeeNomineeDetailForm="ngForm">
        <div lass="row" >
          <table id="tblComponent" class="table table-striped table-bordered text-left table-new row-border hover">
            <thead class="thead-blue text-center">
              <tr>
                <th style="width: 15%;">Name</th>  
                <th style="width: 10%;">Relation </th>
                <th style="width: 15%;">Address</th>
                <th style="width: 10%;">Date Of Birth</th> 
                <th style="width: 10%;">PF Share</th>   
                <th style="width: 10%;">Gratuity Share</th>
                <th style="width: 10%;">Pesion Share</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let Nomini of this.EmployeeMasterService.SharedEmployees.EmployeeNomineeDetailModel, let i = index">
                <td>{{Nomini.NomineeName}}</td>
                <td>{{Nomini.NomineeRelation}}</td>
                <td>{{Nomini.NomineeAddress}}</td>
                <td>{{Nomini.NomineeDOB | date : "dd-MMM-yyyy"}}</td>
                <td>{{Nomini.PFSharePercentage}}</td>
                <td>{{Nomini.GratuitySharePercentage}}</td>
                <td>{{Nomini.PensionSharePercentage}}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </form>
    </fieldset>
  </div>
</div>
